export const PRODUCT_DATA = [
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6265,
        store_longitude: -74.0753,
        total_orders: 20.6,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Vegetarian, Wine, Coffee, Beer, Donut, Spacious, Caribbean, Casual, Great, Local',
        annual_dol: 84.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7194,
        store_longitude: -73.5638,
        total_orders: 129.01,
        neighborhood: '',
        keywords: 'Burger, Steak, Hamburger, Tasty, Beer, American, Casual, Blt Sandwich',
        annual_dol: 162.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7216,
        store_longitude: -74.0014,
        total_orders: 3431.66,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Wine, Delicious, Warm, Salad, Cozy, Organic, Mediterranean, Exotic, Outstanding, Turkish',
        annual_dol: 3730.25,
        description:
            'Mediterranean chow served in a funky, exposed-brick space with antiques & occasional live jazz.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -74.0094,
        total_orders: 291.13,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Wine, Delicious, Vodka, Upscale, Organic, Greek, Mediterranean, Perfect, Fresh',
        annual_dol: 297.31,
        description:
            'Greek taverna serving a high-end, seafood-focused menu in an large, chic space with an Aegean feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7963,
        store_longitude: -73.9495,
        total_orders: 235.8,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Tequila, Beer, Wine, Gluten Free, Gin, Spicy, Salad, African, Nigerian, Sweet',
        annual_dol: 963.86,
        description:
            'Spacious counter-serve highlighting African cuisine in customizable plates & bowls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7237,
        store_longitude: -74.0039,
        total_orders: 70.1,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Beer, Organic, Italian, Avocado Toast, Upscale, Russian, Modern, Latin, Authentic',
        annual_dol: 286.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.5782,
        total_orders: 24.94,
        neighborhood: '',
        keywords:
            'Burger, Steak, Lobster, Hamburger, Vegetarian, Lobster Tail, Vegan, Salad, Shrimp Cocktail, Gluten Free',
        annual_dol: 37.22,
        description:
            'Old-school eatery with a large menu of American breakfast favorites, sandwiches & entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6998,
        store_longitude: -73.916,
        total_orders: 7536.02,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Hamburger, Zombie, Vegan, Vegetarian, Salad, Beer, Urban, Wine, American',
        annual_dol: 8019.84,
        description:
            'Tiki-inspired cocktails, board games & inventive pub grub are the draw at this rustic-chic tavern.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5813,
        store_longitude: -73.8299,
        total_orders: 674.18,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords:
            'Burger, Hamburger, Chicken Pot Pie, Gourmet, Tasty, Donut, Bbq, Delicious, American, Homemade',
        annual_dol: 834.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.9778,
        total_orders: 8670.66,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Vodka, Beer, Scotch, Bourbon, Gin, Delicious, Hotel, Cozy, Sweet, Intimate',
        annual_dol: 9299.96,
        description:
            'Slick, Prohibition-style watering hole with craft cocktails & sophisticated bar snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -74.0101,
        total_orders: 35.51,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Meatloaf, Salad, Whiskey, Beer, Wine, Gluten Free, Tasty, Swiss, Trendy',
        annual_dol: 145.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7924,
        store_longitude: -73.9523,
        total_orders: 232.01,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Beer, Elegant, Delicious, American, Georgian, Perfect, Casual, Urban, Distinctive Character, Heritage',
        annual_dol: 315.84,
        description:
            'The Museum of the City of New York is a history and art museum in Manhattan, New York City, New York. It was founded by Henry Collins Brown, in 1923 to preserve and present the history of New York City, and its people.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -74.0053,
        total_orders: 8188.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Scotch, Whiskey, Hotel, Elegant, Cozy, Premium, Classy, Sophisticated, Friendly, Great',
        annual_dol: 8645.89,
        description: 'Cozy cigar bar with stogies, spirits & wines in a clubby, book-filled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -74.0042,
        total_orders: 4353.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Salad, Wine, Italian, Delicious, Cozy, Vintage, Relaxed, American',
        annual_dol: 4353.72,
        description:
            'This casual haunt offers bar snacks, steak dinners & plenty in between, plus varied wines & spirits.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7303,
        store_longitude: -74.004,
        total_orders: 6187.36,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Vodka, Vintage, Modern, Welcoming, Timeless, Innovative, Classic, Great, Casual',
        annual_dol: 6318.64,
        description:
            'Burgers & bar snacks in a retro, wood-lined watering hole, plus an innovative cocktail menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7347,
        store_longitude: -74.002,
        total_orders: 869.97,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Gluten Free, Dessert, Delicious, Coffee, Sweet, Fancy, Homemade, French, Amazing, Classic',
        annual_dol: 2921.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9897,
        total_orders: 1319.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Salad, Vegan, Spanish, Hotel, Seasonal, Cozy, Contemporary, Friendly, Classic',
        annual_dol: 1376.81,
        description:
            '"A lively, Barcelona-inspired tapas bar serving Spanish classics, seasonal specials, and curated cocktails in a vibrant and contemporary space."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.59,
        store_longitude: -73.9222,
        total_orders: 51.84,
        neighborhood: 'Gerritsen Beach',
        keywords: 'Italian, Dessert, Local',
        annual_dol: 211.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7491,
        store_longitude: -73.6207,
        total_orders: 463.34,
        neighborhood: '',
        keywords:
            'Coffee, Cheesecake, Pizza, Italian, Hotel, American, Opulent, Asian, Modern, Contemporary',
        annual_dol: 483.43,
        description:
            "Featuring sitting areas separated by partial walls, the contemporary suites include free Wi-Fi, flat-screen TVs, and desks, as well as minifridges, microwaves and coffeemakers.A hot breakfast buffet and parking are complimentary. Amenities include a fitness center and an indoor pool. There's also a convenience store, and a lobby lounge with a bar, as well as a patio offering outdoor seating and a fire pit.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8347,
        store_longitude: -73.8166,
        total_orders: 82.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'American',
        annual_dol: 134.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9902,
        total_orders: 3450.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Vegetarian, Beer, Gluten Free, Whiskey, Swiss, Bbq, American, Spicy, Casual, Local',
        annual_dol: 3523.23,
        description:
            'Late-night outpost for craft beer, cocktails & creative bar eats in a dark-wood setting with TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.9891,
        total_orders: 11.5,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hamburger, Casual, Authentic, Fine',
        annual_dol: 47.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8277,
        store_longitude: -73.7017,
        total_orders: 417.21,
        neighborhood: '',
        keywords: 'Elegant, Comfortable, Classic, Spectacular, Exceptional',
        annual_dol: 443.99,
        description: '"Yacht club in Port Washington, New York"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -74.0077,
        total_orders: 483.61,
        neighborhood: 'Hudson Square',
        keywords:
            'Steak, Tasty, Salad, Cuban, Delicious, Caribbean, Homemade, Rum, Sweet, Colorful',
        annual_dol: 949.41,
        description:
            '"Craft Cocktail and Rum Bar with Latin Caribbean/Old Cuban inspired plates designed by our internationally known Chef, Wesly Jean Simon. Join us for our $49 Bottomless Brunch every weekend!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.605,
        store_longitude: -73.9426,
        total_orders: 387.76,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Roast Beef, Italian, French, French Onion Soup, Delicious, American, Prime Rib',
        annual_dol: 404.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6339,
        store_longitude: -73.6882,
        total_orders: 370.05,
        neighborhood: '',
        keywords: 'Golfclub, Luxurious, Cozy, Phenomenal, Unique',
        annual_dol: 1512.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.5856,
        total_orders: 730.02,
        neighborhood: '',
        keywords:
            'Pizza, Kebab, Salad, Mediterranean, Luxurious, Organic, Sweet, Exquisite, High-End, Incredible',
        annual_dol: 2984.06,
        description:
            '"Park West of Westbury NY a beautiful lavish luxurious night club lounge that is also available for private events and private parties! Under new management 12/2021 Come find experience this breathtaking venue for yourself! For bookings, inquiries, and questions please email us! Parkwestlongisland@gmail.com"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7962,
        store_longitude: -73.9462,
        total_orders: 184.84,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Steak, Peruvian, Salad, Wine, Burger, Italian, Hotel, Latin, Fusion, Vibrant',
        annual_dol: 476.32,
        description:
            'Elevated Peruvian cooking in cool surrounds, plus an extensive, thoughtfully sourced wine menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7998,
        store_longitude: -73.9326,
        total_orders: 245.77,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Legendary, Premium, Fine',
        annual_dol: 1004.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.644,
        total_orders: 85.25,
        neighborhood: '',
        keywords: 'Tasty, Warm, American, Welcoming, Amazing, Great, British, Incredible, Casual',
        annual_dol: 167.13,
        description:
            'Longtime hangout with a 1950s motif offering American eats along with live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7739,
        store_longitude: -73.874,
        total_orders: 858.52,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Wine, Salad, Gourmet, Beer, Spacious, Healthy, Serene, Premium, Fresh, Contemporary',
        annual_dol: 1136.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.9048,
        total_orders: 237.53,
        neighborhood: 'Woodside',
        keywords:
            'Flavorful, Beer, Wine, Dominican, Spanish, Latin, Pinacolada, Polished, Superb, American',
        annual_dol: 256.45,
        description:
            'Festive restaurant with a patio for modern Latin & Dominican fare, plus a nightclub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6698,
        store_longitude: -73.8579,
        total_orders: 519.89,
        neighborhood: 'East New York',
        keywords:
            'Salad, Shrimp Cocktail, Steak, Mexican, Dessert, Martini, Spanish, Thai, Bbq, Latin',
        annual_dol: 938.92,
        description:
            'This landmark diner offers an extensive menu of American fare with Latin & Caribbean influences.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7723,
        store_longitude: -73.9558,
        total_orders: 26.4,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Kebab, Salad, Wine, Mediterranean, Inviting, Premium, Refreshing, Bright, Awesome',
        annual_dol: 92.47,
        description:
            '"The Blue Mezze Bar is a unique Mediterranean restaurant/bar on UES, Manhattan, with a focus on great food, equally amazing cocktails, and an inviting atmosphere! We have an extensive mezze and cocktail menu, ideal for sharing and tasting - perfect for friends, family, or that upcoming date! We formed the menu with inspiration from our friends and family at A la Turka."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7076,
        store_longitude: -73.9321,
        total_orders: 605.37,
        neighborhood: 'East Williamsburg',
        keywords:
            'Vegan, Burger, Vegetarian, Tacos, Beer, Wine, Instagrammable, Savory, American, Latin',
        annual_dol: 1186.88,
        description:
            'Vibrant venue with a patio fixing up tacos, burgers & finger foods, plus weekend brunch & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7967,
        store_longitude: -73.6703,
        total_orders: 3144.72,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Beer, Upscale, Comfortable, Casual, Mediterranean, Contemporary, Inviting, Natural',
        annual_dol: 3211.44,
        description:
            '"Cipollini Trattoria and Bar is a casual, Manhattan-style restaurant. Offering freshly prepared, satisfying meals in a comfortable, inviting atmosphere. A sizeable marble bar, perfect for socializing, welcomes people into the restaurant. Large, glass-paneled doors that are open seasonally bring the atmospheric alfresco dining into the trattoria, creating unity between the indoor and outdoor seating. During warmer months, sun umbrellas come together to provide a shady canopy and natural elements and beautiful flowers immerse diners in a Mediterranean dining experience. Cipollini has gained a reputation for its array of contemporary Italian specialties."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -74.0086,
        total_orders: 975.44,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Salad, Spicy, Green Papaya Salad, Japanese, Funky, Thai, Asian, Rum, Sweet',
        annual_dol: 1017.73,
        description:
            'Southeast Asian dishes with a Laotian emphasis served in a brick-walled, industrial-styled setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.808,
        store_longitude: -73.9291,
        total_orders: 223.98,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Beer, Mexican, Tasty, Sustainable, American, Latin, Southern, Welcoming, Casual, Vibrant',
        annual_dol: 313.78,
        description:
            'Sophisticated global fare, brunch & cocktails in a vibrant space with exposed brick & bold art.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7763,
        store_longitude: -73.9531,
        total_orders: 1035.96,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Sushi, Vegetarian, Thai, Gluten Free, Tasty, Ramen, Beer, Bbq, Organic, Spicy',
        annual_dol: 1104.82,
        description:
            'Classic Thai cuisine & cocktails served in a brick-lined space with weathered woods & a stylish bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.9214,
        total_orders: 882.9,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Burger, Steak, Salad, Beer, Ale, Irish, Delicious, American, Classic, Local',
        annual_dol: 890.84,
        description:
            'Sports bar with brick walls, red banquettes & sidewalk seating, serving burgers, wings & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9945,
        total_orders: 81.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Upscale, Italian, Chinese, Spacious, Casual, Global, Fine',
        annual_dol: 332.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9923,
        total_orders: 1010.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Pizza, Italian, Rustic, Upscale, Homemade, Seasonal, Cozy, Bruschetta, Inviting, Local',
        annual_dol: 1652.8,
        description:
            'Sleek, slightly upscale Italian spot maximizing comfort with 2 roomy dining areas & a pizza bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7526,
        store_longitude: -73.9843,
        total_orders: 87.46,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Chinese, Spicy, Sweet, Local, Casual, Fresh, Mapo Tofu',
        annual_dol: 357.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -73.8432,
        total_orders: 3609.25,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Vegan, Hamburger, Salad, Gluten Free, Beer, Wine, Organic, Bbq, Moroccan',
        annual_dol: 5704.45,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7779,
        store_longitude: -73.9543,
        total_orders: 2178.22,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Wine, French, Rustic, Cozy, Polished, Vintage, Warm, Authentic, Casual',
        annual_dol: 2207.63,
        description:
            'French bistro fare served in a typical brasserie atmosphere of dark wood & vintage posters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7773,
        store_longitude: -73.9519,
        total_orders: 2385.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tequila, Whiskey, Wine, Beer, Margarita, Salad, French Toast, Upscale, Rum, French',
        annual_dol: 2594.25,
        description:
            'Upscale American comfort food & craft cocktails are prepared in this homey frontier-chic tavern.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.774,
        store_longitude: -73.9577,
        total_orders: 853.11,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Chinese, Upscale, Dessert, Elegant, Juicy, Cozy, Healthy, Sweet, Innovative, Asian',
        annual_dol: 998.04,
        description:
            'Cozy & elegant dining space serving traditional & innovative Shanghainese dishes, including dim sum.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7765,
        store_longitude: -73.9522,
        total_orders: 20.86,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tasty, Italian, Beer, Shrimp Cocktail, Chicken Parmigiana, Romantic, Cozy, Bruschetta, Premium, Fabulous',
        annual_dol: 50.16,
        description:
            'Traditional Italian cooking served in a narrow room with exposed-brick decor & a romantic feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7261,
        store_longitude: -74.0085,
        total_orders: 22903.56,
        neighborhood: 'Hudson Square',
        keywords:
            'New-York-Style, Moroccan, Wine, Premium, Modern, Great, Lit, Chic, Rooftop, Rare',
        annual_dol: 23389.54,
        description:
            'Chic cocktail lounge where Morocco-inspired decor sets the stage for drinking & dancing all night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -74.0102,
        total_orders: 127.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Chocolate Croissant, Dessert, Coffee, Gourmet, Luxurious, French, Modern, Delicious, Picturesque, American',
        annual_dol: 519.9,
        description:
            '"Patisserie Chanson is a modern European café and eatery known for its artful and ambitious desserts and artisanal bakery offering. Open for breakfast, all-day brunch, and grab-and-go lunch, Patisserie Chanson offers freshly prepared French viennoiseries and an extensive drinks menu along with an open display of innovative gourmet pastries and piquant coffee brews. Among their exquisite baked goods are selections of the Kouign Amann, Croissant, and Ficelle. From the dessert menu, fan favorites include the Seasonal Tart and varieties of the Éclair and Marble Cake. Unmissable brunch items are the Black Truffle Grilled Cheese, Poached Egg & Salmon Croissant, and Pain Perdu, paired with refreshing cocktails, mocktails, wine, and champagne."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.6485,
        total_orders: 1398.27,
        neighborhood: '',
        keywords:
            'Vodka, Upscale, Gin, American, Modern, Contemporary, Delicious, Perfect, Casual, Fresh',
        annual_dol: 1400.73,
        description:
            'Locally sourced New American dining in renovated landmark farmhouse with a porch & taproom.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.847,
        total_orders: 15443.63,
        neighborhood: 'Flushing/Corona',
        keywords: 'Beer, Seasonal, Exceptional, Perfect, Great, Excellent',
        annual_dol: 64173.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7001,
        store_longitude: -73.9196,
        total_orders: 802.09,
        neighborhood: 'Bushwick',
        keywords: 'Vegetarian, Burger, Cozy, Casual, Natural, Chill, Great',
        annual_dol: 3022.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7309,
        store_longitude: -74.0041,
        total_orders: 536.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Steak, Tacos, Beer, Mimosa, Margarita, Dessert, Bloody Mary, Hotel, Modern',
        annual_dol: 955.61,
        description:
            'Imaginative twists on Mexican dishes, plus cocktails, presented in a modern, convivial setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6557,
        store_longitude: -73.6714,
        total_orders: 231.92,
        neighborhood: '',
        keywords:
            'Steak, Wine, Pizza, Cajun, Dessert, Italian, Meatball, Premium, Trendy, Romantic',
        annual_dol: 315.71,
        description:
            '"A casual Wine Bar and Kitchen located in the heart of the Lynbrook business district."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -74.001,
        total_orders: 1296.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Burger, Salad, Dessert, Whiskey, Scotch, Beer, Gin, Delicious, Upscale',
        annual_dol: 5479.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7345,
        store_longitude: -74,
        total_orders: 83.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chinese, Thai, Peking Duck, Asian, Martini, Ramen, Exquisite, Irresistible, Healthy, Mojito',
        annual_dol: 341.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8383,
        store_longitude: -73.8638,
        total_orders: 8.24,
        neighborhood: 'East Bronx/Park Versailles/Parkchester',
        keywords: 'Burger, American, Karaoke, Casual, Great',
        annual_dol: 22.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6348,
        store_longitude: -74.1072,
        total_orders: 13.55,
        neighborhood: 'Randall Manor',
        keywords:
            'Steak, Shrimp Cocktail, Chili Con Carne, Wine, Italian, Bbq, Upscale, German, Swiss, French Onion Soup',
        annual_dol: 47.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7239,
        store_longitude: -73.9514,
        total_orders: 4261.72,
        neighborhood: 'Greenpoint',
        keywords:
            'Chocolate Croissant, Burger, Salad, Coffee, Gourmet, Wine, Spicy, Delicious, Organic, American',
        annual_dol: 4990.98,
        description:
            'This chill Greenpoint bistro offers New American fare with an Aussie accent & brunch options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7283,
        store_longitude: -73.958,
        total_orders: 1547.25,
        neighborhood: 'Greenpoint',
        keywords: 'Cozy, Warm, Coffee, Friendly, Perfect, Unique',
        annual_dol: 2232.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9659,
        total_orders: 581.76,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Shrimp Cocktail, Italian, Delicious, Homemade, Healthy, Authentic, Outstanding, Casual, Fresh',
        annual_dol: 594.11,
        description:
            'Old-fashioned Italian cooking in a brick-walled setting run by the same family since 1955.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7667,
        store_longitude: -73.9694,
        total_orders: 39.23,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Italian, Mexican, Tasty, Dessert, Wine, Delicious, Asian, Classy, Polished',
        annual_dol: 137.38,
        description:
            'Polished destination with an urbane vibe serving classic Italian cuisine & wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5749,
        store_longitude: -73.9793,
        total_orders: 1816.53,
        neighborhood: 'Coney Island',
        keywords: 'Margarita, Beer, Mexican, Pizza, Bbq, Mojito, American, Sweet, Teriyaki, Casual',
        annual_dol: 2282.91,
        description:
            'Rollicking outdoor nightspot with live music, frozen drinks, games & a menu of BBQ & pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6749,
        store_longitude: -73.9813,
        total_orders: 1822.57,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Whiskey, Beer, Ale, Spacious, Rustic, Yummy, Perfect, Friendly, Retro',
        annual_dol: 1903.89,
        description:
            'Local haunt serving cocktails & beer in an old-school setup with dart boards & a shuffleboard table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7457,
        store_longitude: -73.9008,
        total_orders: 2757.22,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Vodka, Tequila, Nepalese, Beer, Fusion, Biryani, Casual',
        annual_dol: 7506.9,
        description: '"New York Ko Bhatti"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8095,
        store_longitude: -73.6291,
        total_orders: 227.33,
        neighborhood: '',
        keywords:
            'Lobster, Chinese, Tangy, Peking Duck, Spicy, Organic, American, Wonton Soup, Local, Authentic',
        annual_dol: 237.18,
        description:
            'Hunan cooking spices up a menu that includes Peking duck & dumplings, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8194,
        store_longitude: -73.9272,
        total_orders: 620.55,
        neighborhood: 'West Bronx',
        keywords:
            'Garlic Bread, Pizza, Italian, Vodka, Champagne, Meatball, Dessert, Bbq, Peruvian, Zesty',
        annual_dol: 661.8,
        description:
            'Vibrant locale for traditional Italian fare & pizzas, with a bar, weekend brunch & karaoke nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7772,
        store_longitude: -73.9519,
        total_orders: 1460.06,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Whiskey, Beer, Sushi, Bourbon, Coffee, Warm, Irish, Rustic, American, Awesome',
        annual_dol: 1557.67,
        description:
            'Rustic, brick-walled bar known for its whiskeys, rotating draft brews & elevated American eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7323,
        store_longitude: -74.0038,
        total_orders: 8480.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Beer, Vodka, Wine, Delicious, Funky, Bourbon, Cozy, Refined, Friendly',
        annual_dol: 8971.77,
        description:
            'Handsome second-floor bar with exposed brick, serving cocktails & Five Guys burgers from downstairs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6349,
        total_orders: 1269.84,
        neighborhood: '',
        keywords:
            'Hotdog, Beer, Ale, Whiskey, Irish, Friendly, Traditional, Awesome, Great, Excellent',
        annual_dol: 1330.51,
        description:
            'Popular, late-night watering hole featuring craft beers on tap & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7241,
        store_longitude: -73.6401,
        total_orders: 3954.25,
        neighborhood: '',
        keywords:
            'Wine, Luxurious, Glamorous, Upscale, Hotel, Flavorful, Trendy, Elegant, Delicious, Margarita',
        annual_dol: 4080.1,
        description:
            'The posh rooms have flat-screen TVs, Italian furniture, Wi-Fi (surcharge) and 24-hour room service. Upgraded suites add kitchenettes and living areas, while some feature an additional bathroom and private outdoor patios.Perks include 25,000 sq ft of event space, an indoor pool, a spa and sauna, a fitness center and an upscale steakhouse, plus a seasonal patio bar, and lounge. Pet walking and feeding services are available. Parking is free.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.66,
        store_longitude: -73.6708,
        total_orders: 87.89,
        neighborhood: '',
        keywords: 'Sweet',
        annual_dol: 100.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.735,
        store_longitude: -73.5974,
        total_orders: 499.53,
        neighborhood: '',
        keywords: 'Clean, Friendly, Cuban, Savory, Casual, Fun, Modern, American, Classic, Great',
        annual_dol: 523.9,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6509,
        store_longitude: -73.6116,
        total_orders: 356.23,
        neighborhood: '',
        keywords:
            'Pizza, Chicken Parmigiana, Salad, Vodka, Mexican, Italian, Meatball, Wine, Creamy, Caesar Salad',
        annual_dol: 436.01,
        description:
            '"Looking for a spot to enjoy appetizers, drinks or a hearty dinner? Our NOVI Lunch, Dinner and Drink Menus boast something for everyone! Having a party or need a venue for your next event? We do on & off premise catering! From pizzas to pastas to seafood, Come down to NOVI for generous portions & modest prices. Whether it is a special occasion or a weekday evening, there is something for all to enjoy at NOVI!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7814,
        store_longitude: -73.9798,
        total_orders: 4862.37,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Burger, Ale, Hamburger, Vodka, Steak, Wine, Gin, Dessert, Fried Calamari',
        annual_dol: 4881.24,
        description:
            'No-frills brewpub with a sizable craft beer lineup, a bar food menu & several TVs airing sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.775,
        store_longitude: -73.9545,
        total_orders: 9.55,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Vegan, Coffee, Burger, Vegetarian, Tasty, Salad, Wine, Pizza, Beer, Organic',
        annual_dol: 39.03,
        description:
            'Australian hangout specializing in coffee drinks, along with salads & other daytime bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.6132,
        total_orders: 91.05,
        neighborhood: '',
        keywords: 'Lobster, Salad, Sushi, Steak, Japanese, Spicy, Sashimi, Creamy, Beer, Fun',
        annual_dol: 372.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6695,
        store_longitude: -73.9866,
        total_orders: 618.89,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Coffee, Ale, Funky, Dessert, Bourbon, Spacious, Ample, Relaxed, Friendly',
        annual_dol: 676.99,
        description:
            'Dog-friendly bar & hipster hangout with a broad selection of craft beers in chill, funky environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6602,
        store_longitude: -73.9836,
        total_orders: 227.2,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords:
            'Chocolate Mousse, Salad, Italian, Meatball, Mimosa, Cozy, Asian, Premium, Seasonal, Friendly',
        annual_dol: 271.67,
        description:
            'Seasonal menus of classic Italian eats star at this warm, cozy restaurant with outside seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6702,
        store_longitude: -73.9856,
        total_orders: 62.58,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Tacos, Tequila, Burger, Beer, Margarita, Martini, Quesadillas, Enchiladas, Wine',
        annual_dol: 76.78,
        description:
            'Warm Mexican eatery with colorful murals offering tacos & enchiladas plus ample tequila & mezcal.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7708,
        store_longitude: -73.9575,
        total_orders: 16.29,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Burger, Dessert, Beer, Spicy, Belgian, Elegant, Friendly, Wonderful, Classic',
        annual_dol: 66.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5757,
        store_longitude: -73.9798,
        total_orders: 93.7,
        neighborhood: 'Coney Island',
        keywords: 'Sushi, Champagne, Fun, Cool, Casual, Great',
        annual_dol: 143.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7187,
        store_longitude: -73.8402,
        total_orders: 351.12,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Pizza, Lobster, Italian, Gluten Free, Beer, Wine, Vodka, Homemade, Amazing, Fresh',
        annual_dol: 415.05,
        description:
            'Formal setting for an Italian menu with nightly live music, plus a Sunday brunch buffet.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7772,
        store_longitude: -73.9559,
        total_orders: 99.16,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Vegetarian, Beer, Flavorful, Chicken Tikka Masala, Elegant, Contemporary, Indian, Tandoori Chicken, Friendly',
        annual_dol: 127.87,
        description:
            'Tandoor oven & vegetarian specialties in an ornate interior. Takeout & delivery offered.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6522,
        store_longitude: -73.8379,
        total_orders: 438.53,
        neighborhood: 'Jamaica/Howard Beach',
        keywords: '',
        annual_dol: 505.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7346,
        store_longitude: -74.002,
        total_orders: 1914.2,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Whiskey Sour, Beer, Roast Turkey, Whiskey, Wine, Turkey Sandwich, Margarita, Spicy, Irish',
        annual_dol: 1954.82,
        description:
            'Named after a boat, this 4-story pub boasts a fireplace & billiards along with a sizable beer menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7487,
        store_longitude: -73.642,
        total_orders: 122.85,
        neighborhood: '',
        keywords:
            'Garlic Bread, Pizza, Tasty, Vegetarian, Salad, Italian, Delicious, Dessert, Comfortable, Awesome',
        annual_dol: 502.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7362,
        store_longitude: -74.0091,
        total_orders: 87.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Italian, Gin, Polished, Natural, Casual, Complex',
        annual_dol: 359.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7842,
        store_longitude: -73.9773,
        total_orders: 66.97,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Thai, Spicy, Delicious, Asian, Hawaiian, Colorful, Traditional, Authentic, Fresh, Mojito',
        annual_dol: 80,
        description:
            'Bustling chain offering generous portions of traditional Thai dishes & colorful cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6595,
        store_longitude: -73.5433,
        total_orders: 15.93,
        neighborhood: '',
        keywords: 'Pizza, Vodka, Beer, Salad, Italian, Bruschetta, Fresh, Great, Excellent, Local',
        annual_dol: 20.54,
        description:
            'Bustling, family-oriented pizza maker turning out brick-oven pies, plus pastas & other faves.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7708,
        store_longitude: -73.917,
        total_orders: 5.98,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Burger, Salad, French Toast, Coffee, French, American, Crisp, Belgian, Hotel, Casual',
        annual_dol: 24.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7656,
        store_longitude: -73.9189,
        total_orders: 1884.83,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Burger, Mexican, Beer, Wine, Bourbon, Spanish, Premium, American, Irish',
        annual_dol: 2461.8,
        description: '"Queer Cocktail Lounge & Kitchen in Astoria, New York."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6599,
        store_longitude: -73.9806,
        total_orders: 161.63,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords:
            'Burger, Salad, Avocado Toast, Wine, American, Casual, Local, Cool, Creative, Great',
        annual_dol: 612.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -73.9396,
        total_orders: 1799.91,
        neighborhood: 'East Williamsburg',
        keywords: 'Pizza, Cozy, Spacious, Cool, Daiquiri, Casual, Local, Great',
        annual_dol: 1840.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9654,
        total_orders: 771.89,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Delicious, Cozy, Warm, Impeccable, Friendly, Intimate, Excellent, Casual, Fine',
        annual_dol: 788.27,
        description:
            'High-end Italian dining venue that lures an "in" crowd to its intimate downstairs grotto setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7676,
        store_longitude: -73.953,
        total_orders: 197.66,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Vegan, Pizza, Salad, Chicken Parmigiana, Italian, Gluten Free, Wine, Beer, Bruschetta',
        annual_dol: 206.23,
        description:
            'Quiet, candlelit trattoria featuring familiar Italian flavors & occasional live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.769,
        store_longitude: -73.9683,
        total_orders: 41.95,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: '',
        annual_dol: 54.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7664,
        store_longitude: -73.9712,
        total_orders: 46.57,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotel, Italian, Brazilian, Urban, Intimate, Traditional, Local, Excellent, Artistic, Signature',
        annual_dol: 242.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7539,
        store_longitude: -73.9894,
        total_orders: 71.91,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Pizza, Italian, Wine, Polished, Local, Casual, Great, Fine',
        annual_dol: 73.44,
        description:
            'Square thin-crust pies & Italian standards are offered in a basic, second-floor location.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.9844,
        total_orders: 3940.92,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Wine, Rooftop, Romantic, Lively, Fantastic, Incredible, Great, Polished, Classic',
        annual_dol: 4024.54,
        description:
            "from the Empire State Building.Upscale, individually decorated rooms feature floor-to-ceiling windows, designer toiletries and 42-inch flat-screen TVs, plus free Wi-Fi, minifridges and coffeemakers. Room service is available.Free passes to an off-site sports club are offered. There's a polished steakhouse serving seasonal American dishes, along with a lively lobby bar with a patio, and a rooftop bar with signature cocktails and sweeping city views.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7536,
        store_longitude: -73.9913,
        total_orders: 1553.13,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Korean, Wine, Trendy, Delicious, Spicy, Classy, Asian, Bbq, Contemporary, Friendly',
        annual_dol: 1730.6,
        description:
            'Korean BBQ & cocktails offered in lofty, contemporary surrounds with table grills & a sizable bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8311,
        store_longitude: -73.6959,
        total_orders: 171.41,
        neighborhood: '',
        keywords: 'Hotel, Unique',
        annual_dol: 700.67,
        description:
            '425-seat theater in a historic building spotlighting celebrated musicians, speakers & comedians.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.5525,
        total_orders: 4623.66,
        neighborhood: '',
        keywords: 'Burger, Zombie, Steak, Salad, Beer, Margarita, Wine, Dessert, Bbq, Bourbon',
        annual_dol: 4721.77,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.716,
        store_longitude: -73.9965,
        total_orders: 1607.33,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Lobster, Champagne, Hotel, Wine, Tequila, Chinese, Stylish, Tasty, Asian, Funky',
        annual_dol: 2921,
        description:
            'All-day Asian spot at 50 Bowery hotel offering dim sum & share plates, plus American breakfasts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7768,
        store_longitude: -73.9527,
        total_orders: 2976.38,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Salad, Sushi, Italian, Martini, Veal Parmigiana, Delicious, Cozy, Polished, Casual, Amazing',
        annual_dol: 3039.53,
        description:
            'Popular old-school Italian eatery known for classic dishes & people-watching opportunities.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7757,
        store_longitude: -73.9475,
        total_orders: 1326.42,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Tasty, Greek, Friendly, Authentic, Casual, Classic, Local',
        annual_dol: 1387.02,
        description:
            'Old-school Yorkville Italian restaurant serving classic red-sauce fare since 1982.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7425,
        store_longitude: -73.9157,
        total_orders: 11.79,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: '',
        annual_dol: 12.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6251,
        store_longitude: -74.0746,
        total_orders: 1495.22,
        neighborhood: 'Clifton',
        keywords:
            'Steak, Vegetarian, Salad, Margarita, Italian, French Toast, Mimosa, Beer, Organic, Avocado Toast',
        annual_dol: 1808.11,
        description:
            '"Located on Bay Street in Staten Island, The Richmond features New American Bistro fare with artisanal cocktails and craft beer."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6389,
        store_longitude: -73.6601,
        total_orders: 218.99,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Salad, Meatball, Wine, Delicious, Mediterranean, Robust, Fresh, Excellent',
        annual_dol: 282.39,
        description:
            '"A little bit of Italy brought to Long Island. Our robust and authentic cuisine was brought over by Italian immigrants in the late 1800s. If you’re hoping to experience Old World Italian cuisine, there’s nothing quite like Grandpa Tony’s. We are known for our hearty portions, fresh ingredients. served in all your old-world favorites. Our Chef is inspired by a traditional Italian cuisine and uses his passion for cooking to make every dish perfect."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6537,
        store_longitude: -73.867,
        total_orders: 15.27,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Juicy, Lobster, Cajun, Delicious, Romantic, Friendly, Homemade, American, Great, Excellent',
        annual_dol: 62.41,
        description:
            '"Welcome to Red Crab Juicy Seafood in East New York. Forged from the Heat of the South, our spices bring the Bold Flavors that you can expect from a Red Crab Seafood Boil. Since our first location in Florida, we have had grand visions of serving our aromatic seasonings all over the states. We are determined to bring our delicious seafood boil and career opportunities to our communities. Whether it is for parties, a romantic dinner, or even a light snack, our original homemade seasoning blend is sure to make you “Crave the Boil!”"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7316,
        store_longitude: -74.0068,
        total_orders: 736.41,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Whiskey, Beer, Pizza, Ale, Irish, American, Friendly, Authentic, Classic, Outstanding',
        annual_dol: 752.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -74.0052,
        total_orders: 48.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Wine, Meatball, Korean, Spicy, Martini, Trendy, Green Curry, American, French',
        annual_dol: 197.86,
        description:
            'Quaint restaurant offering old-school American dishes & cocktails amid Shaker-style surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.658,
        store_longitude: -73.6476,
        total_orders: 104.68,
        neighborhood: '',
        keywords:
            'Garlic Bread, Pizza, Chicken Parmigiana, Salad, Italian, Wine, Spicy, Delicious, Omelette, Polished',
        annual_dol: 119.41,
        description:
            'Neapolitan wood-fired pizza & other southern Italian dishes offered in a refined, intimate space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7966,
        store_longitude: -73.6721,
        total_orders: 847.46,
        neighborhood: '',
        keywords:
            'Steak, Vegetarian, Sushi, Japanese, Wine, Asian, Filet Mignon, Modern, Fusion, Casual',
        annual_dol: 884.2,
        description: 'Hibachi chain serving Japanese dishes grilled tableside by theatrical chefs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7172,
        store_longitude: -74.0051,
        total_orders: 3293.6,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Sushi, Hamburger, Japanese, Turkish, Chinese, Mediterranean, Asian, Casual, Modern',
        annual_dol: 15196.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.657,
        store_longitude: -74.0071,
        total_orders: 1263.23,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords:
            'Lobster Roll, Lobster, Burger, Pizza, Hamburger, Coffee, Beer, Wine, Jamaican, Rich',
        annual_dol: 1365.95,
        description:
            '"Frying Pan Brooklyn is serving up cocktails, brews, lobster rolls and more at this Industry City outpost."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7211,
        store_longitude: -74.0046,
        total_orders: 894.18,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Beer, Delicious, Warm, Italian, Crisp, Spanish, American, Natural, Classic',
        annual_dol: 1153.08,
        description:
            'A seafood-forward menu with a raw bar is served in a light-filled space with a wraparound bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6925,
        store_longitude: -74.0151,
        total_orders: 130.53,
        neighborhood: 'Governors Island',
        keywords: 'Tequila, Tacos, Mexican, Beer, Pizza, Vegan, Margarita, Tasty, Wine, Painkiller',
        annual_dol: 255.92,
        description:
            'Governors Island eatery serving casual Mexican fare in a scenic setting with beachy vibes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.951,
        total_orders: 885.58,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Lobster, Tacos, Lobster Roll, Beer, Margarita, Tasty, American, Rum, Irish',
        annual_dol: 972.33,
        description:
            'Relaxed, brick-lined eatery serving burgers, sandwiches, tacos & wings, plus cocktails & draft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.8393,
        total_orders: 95.86,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Pizza, Vodka, Italian, Salad, Spicy, Mediterranean, Casual, Authentic, Local',
        annual_dol: 109.35,
        description:
            'Rustic, yellow-walled exposed-brick trattoria with an open kitchen serving Northern Italian eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7317,
        store_longitude: -74.0037,
        total_orders: 540.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Tacos, Margarita, Nachos, Quesadillas, Tasty, Enchiladas, Spicy, Latin, Local',
        annual_dol: 973.77,
        description:
            'Potent drinks & a crowded warm-weather patio are the main draws at this longtime Mexican eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -74.005,
        total_orders: 1384.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Tequila, Salad, Beer, Coffee, Meatball, Mediterranean, Spanish, Greek, Modern',
        annual_dol: 1543.98,
        description:
            'Intimate, brick-lined Greek eatery serving both traditional & modern dishes plus wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6812,
        store_longitude: -73.6148,
        total_orders: 25.42,
        neighborhood: '',
        keywords: 'Karaoke, Beer, Wine, Caribbean, American, Latin, Urban, Friendly, Casual',
        annual_dol: 34.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6932,
        store_longitude: -73.7574,
        total_orders: 66.46,
        neighborhood: 'Jamaica/St. Albans',
        keywords: 'Cozy, Ale, Beer, Casual, Fried Shrimp, French, Latin, American',
        annual_dol: 121.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7249,
        store_longitude: -73.5506,
        total_orders: 139.91,
        neighborhood: '',
        keywords:
            'Salad, Kebab, Vegetarian, Gluten Free, Dessert, Tasty, Mediterranean, Cozy, Turkish, Creamy',
        annual_dol: 149.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7853,
        store_longitude: -73.9728,
        total_orders: 1330.04,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Steak, Tequila, Pizza, Cheesesteak, Vodka, Wine, Salad, Beer, Scotch, Nachos',
        annual_dol: 1358.26,
        description:
            'Supper club with Jazz Age-themed decor, a long cocktail list & nightly live music performances.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7894,
        store_longitude: -73.9739,
        total_orders: 569.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Italian, French, New-York-Style, Trendy, Hotel, Fried Chicken, American, Southern, Casual',
        annual_dol: 1118.4,
        description:
            'Trendy multi-level destination with exposed-brick walls serving elevated pub grub & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.716,
        store_longitude: -73.9971,
        total_orders: 19.06,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Chinese, Bbq, Peking Duck, Seasonal, Asian, Classic, Casual, Dim Sum, Authentic',
        annual_dol: 77.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7519,
        store_longitude: -73.6578,
        total_orders: 414.74,
        neighborhood: '',
        keywords: 'Modern, Casual, Great, Delicious, Signature',
        annual_dol: 424.61,
        description:
            "All-ages bowling alley featuring a weekly rock 'n' roll bowl, pub grub & league tournaments.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7309,
        store_longitude: -73.6921,
        total_orders: 5728.89,
        neighborhood: '',
        keywords:
            'Garlic Bread, Lobster, Lobster Tail, Cajun, German, Beer, Wine, Spicy, American, Thai',
        annual_dol: 6109.73,
        description:
            '"The Inn at New Hyde Park is one of the largest wedding venues on Long Island, New York, and we invite you to take a tour of our award-winning event venue. We cater to different special occasions like baby showers, birthday parties and weddings. Experience an enchanting backdrop, elegant banquet and unique event planning that suit your needs. Terraces, fountains, gardens and both indoor and outdoor chapels create a picture-perfect landscape at every turn. You and your guests will be transported back to turn of the century elegance and refinement, while being pampered by modern day luxury. You can visit us or contact us today to arrange a private tour to view The Inn’s grounds and stunning ballrooms."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7646,
        store_longitude: -73.9162,
        total_orders: 21289.25,
        neighborhood: 'Astoria',
        keywords:
            'Steak, Vodka, Margarita, Vegetarian, Champagne, Coffee, Dessert, Colombian, Cuban, Rich',
        annual_dol: 21740.97,
        description:
            'Trendy, multilevel restaurant & bar featuring classic Latin American fare, cocktails, TVs & music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7853,
        store_longitude: -73.7236,
        total_orders: 9.53,
        neighborhood: '',
        keywords: 'Cajun, Sushi, Spicy, Japanese, French, Asian, New Zealand, Great, Clean, Casual',
        annual_dol: 13.71,
        description:
            'Japanese & Continental flavors mix in a small, no-frills restaurant serving sushi, duck & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7529,
        store_longitude: -73.9838,
        total_orders: 3557.97,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Steak, Wine, Japanese, Upscale, Stylish, Luxurious, Trendy, Delicious',
        annual_dol: 3732.47,
        description:
            'Elevated Japanese cuisine whipped up in a buzzing space with elegant, Zen-inspired decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.9973,
        total_orders: 229.72,
        neighborhood: 'Little Italy',
        keywords: 'Lobster, Salad, Dessert, Wine, Martini, Italian, Warm, Beer, Cozy, Romantic',
        annual_dol: 433.31,
        description:
            'White-jacketed waiters serve Southern Italian specialties at this restaurant dating to 1902.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.702,
        store_longitude: -74.0107,
        total_orders: 1827.55,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Upscale, Korean, Hotel, Chilean, Classy, Impeccable, Great, Hip, Heritage',
        annual_dol: 5598.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.722,
        store_longitude: -74.0038,
        total_orders: 1183.22,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Burger, Pizza, Italian, Salad, Stylish, Wine, Delicious, Elegant, Phenomenal, Polished',
        annual_dol: 1234.52,
        description:
            'Offshoot of a longtime French restaurant with Italian & Provençal fare in stylish but casual digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7228,
        store_longitude: -74.0036,
        total_orders: 1217.72,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Beer, Coffee, Trendy, French, Delicious, Legendary, Warm, Traditional, Fabulous',
        annual_dol: 1245.05,
        description:
            'French bistro classics draw SoHo shoppers to this spot with huge windows that open onto the street.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7995,
        store_longitude: -73.944,
        total_orders: 1568.66,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Sushi, Hamburger, Mexican, Salad, Coffee, Spicy, Moroccan, Delicious, Mediterranean, Cozy',
        annual_dol: 3843.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -74.0053,
        total_orders: 340.45,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Lobster Roll, Wine, Beer, Coffee, Upscale, Danish, Classy, Eclectic, Contemporary',
        annual_dol: 1460.59,
        description:
            'Luxe, intimate spot for inventive, multicourse New American tasting menus & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7406,
        store_longitude: -74.0059,
        total_orders: 20015.24,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Whiskey, Hotel, Trendy, Romantic, Awesome, Rooftop, American, Classic, Great, Excellent',
        annual_dol: 20729.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7347,
        store_longitude: -74.0009,
        total_orders: 1548.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Wine, Salad, Beer, Gin, Organic, Martini, Sustainable, Relaxed, Delicious',
        annual_dol: 1618.58,
        description:
            'Basement-set American eatery that gets most of the produce for its seasonal fare from a roof garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -74.0033,
        total_orders: 751.23,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Vegan, Vegetarian, Wine, Stylish, Gin, Perfect, Modern, Casual, French, Fancy',
        annual_dol: 1935.8,
        description:
            'Stylish New American restaurant offering sharing plates, a raw bar & cocktails, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7408,
        store_longitude: -74.0047,
        total_orders: 321.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Salad, Italian, Hotel, Premium, Homemade, Unforgettable, Exceptional, Casual, Rooftop',
        annual_dol: 1315.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7058,
        store_longitude: -74.0103,
        total_orders: 673.16,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Beer, Burger, Warm, Irish, Ale, Friendly, American, Casual, Authentic, Modern',
        annual_dol: 702.35,
        description:
            'Laid-back, dimly lit pub serving bar staples & a popular house burger with handcut fries.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6056,
        store_longitude: -73.6492,
        total_orders: 1325.43,
        neighborhood: '',
        keywords: 'Wine, Cajun, Juicy, Salad, Martini, Great, Diverse, Casual',
        annual_dol: 1731.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6087,
        store_longitude: -73.6467,
        total_orders: 236.04,
        neighborhood: '',
        keywords:
            'Beer, Delicious, Spanish, Superb, Authentic, Italian, Lively, Great, Traditional, Fresh',
        annual_dol: 265.88,
        description:
            'Traditional Portuguese eats & sangria served amid a lively scene in casual digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8331,
        store_longitude: -73.8511,
        total_orders: 91.89,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Vegetarian, Comfortable, Friendly, Fun, Casual, Indian, Lively, Caribbean, Fantastic, Great',
        annual_dol: 136.55,
        description:
            'Lively bar & grill featuring hearty portions of Guyanese & West Indian cuisine, plus a happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7092,
        store_longitude: -73.8699,
        total_orders: 6148.01,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords: 'Tequila, Burger, Beer, Vodka, Margarita, Ale, Warm, Tex-Mex, American, Awesome',
        annual_dol: 6291.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5906,
        store_longitude: -73.7972,
        total_orders: 1712.87,
        neighborhood: 'Arverne by the Sea/Far Rockaway/Arverne',
        keywords: 'Tacos, Pizza, Wine, Dessert, Bbq, Beer, Tasty, Casual',
        annual_dol: 2046.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7057,
        store_longitude: -73.8942,
        total_orders: 158.15,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Friendly, Cozy, Fun, Sweet, Karaoke, Perfect, Fresh, Great, Creative',
        annual_dol: 455.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7003,
        store_longitude: -73.9076,
        total_orders: 604.26,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Wine, American, Perfect, Great, Excellent, Local, Casual, Signature',
        annual_dol: 2470.02,
        description:
            '"The premier vinyl record bar of Queens, NYC. Serving up craft beer, cocktails & analog sounds. 3000+ vinyl library & good vibes. Afro-Latino owned."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9637,
        total_orders: 1171.89,
        neighborhood: 'Lenox Hill',
        keywords: 'Lobster, Coffee, Wine, Salad, Martini, Savory, Italian, Stylish, Hotel, Belgian',
        annual_dol: 3184.81,
        description:
            'Glam hub with outdoor seats offering pastries, light fare & a seasonal dinner menu, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9577,
        total_orders: 872.51,
        neighborhood: 'Greenpoint',
        keywords: 'Lobster, Burger, Beer, Salad, Wine, Warm, Ale, Spacious, Polish, Homey',
        annual_dol: 910.34,
        description:
            'Modern hangout featuring house-brewed craft beer & elevated bar bites, with a warm, homey vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7292,
        store_longitude: -74.0037,
        total_orders: 437.07,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Steak, Wine, Salad, Shrimp Cocktail, Pizza, Upscale, Italian, Donut, Beer',
        annual_dol: 1534.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7306,
        store_longitude: -74.0066,
        total_orders: 1239.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Vegetarian, Gluten Free, Organic, Cozy, Warm, French, American, Perfect',
        annual_dol: 1321.37,
        description:
            'Neighborhood eatery with an intimate feel serving a New American menu paired with cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8454,
        store_longitude: -73.8589,
        total_orders: 167.58,
        neighborhood: 'East Bronx/Van Nest',
        keywords: 'Traditional, Great, Wonderful, Fine',
        annual_dol: 222.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8345,
        store_longitude: -73.8506,
        total_orders: 35.82,
        neighborhood: 'East Bronx/Westchester Square/Parkchester',
        keywords:
            'Tacos, Milkshake, Mexican, Vegetarian, Salad, Nachos, Quesadillas, Casual, Local, Traditional',
        annual_dol: 60.52,
        description:
            'Large portions of Mexican food are served up at this small, family-friendly restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7674,
        store_longitude: -73.9228,
        total_orders: 174.79,
        neighborhood: 'Astoria/LIC',
        keywords: 'Sushi, Salad, Coffee, Delicious, Beer, Thai, Premium, Greek, American, Fresh',
        annual_dol: 714.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -73.9577,
        total_orders: 8077.57,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Ale, Gin, Bourbon, Rum, Upscale, Hip, Fun, Friendly',
        annual_dol: 8248.96,
        description:
            'Hip bar with wood floors & tin ceilings offering creative cocktails, plus a back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8568,
        store_longitude: -73.7908,
        total_orders: 4114.08,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Italian, Caesar Salad, American, Casual, Local, Fresh, Excellent',
        annual_dol: 4223.5,
        description:
            'Low-key spot with outdoor seats on the Sound offers a variety of seafood & American comfort fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.9588,
        total_orders: 861.34,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Pizza, Italian, Meatball, Wine, Beer, Vodka, Classy, Amazing, Local, Great',
        annual_dol: 1000.26,
        description:
            'Airy, light-filled outpost serving wood-fired pizza & Italian cuisine, plus wine, beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6779,
        store_longitude: -74.017,
        total_orders: 100.65,
        neighborhood: 'Red Hook',
        keywords:
            'Pizza, Meatball, Italian, Salad, Bruschetta, Margarita, Aperol, Sweet, Local, Casual',
        annual_dol: 411.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.732,
        store_longitude: -73.9837,
        total_orders: 1368.33,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Hotdog, Beer, Irish, Ale, Ramen, Rustic, Cozy, Great, Casual, Fine',
        annual_dol: 1411.02,
        description:
            'Cozy Irish tavern offering draft beers & cocktails, plus pool tables, dart boards & sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9858,
        total_orders: 2485.37,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Beer, Upscale, American, Friendly, Perfect, Cool, Casual, Classic, Lively, Chill',
        annual_dol: 2538.1,
        description:
            'This diminutive corner watering hole with minimal decor offers a classic jukebox & happy hour deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7323,
        store_longitude: -73.9844,
        total_orders: 441.81,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Pizza, Filipino, Beer, Salad, Whiskey, Fabulous, Fun, Fantastic, Casual',
        annual_dol: 1673.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6392,
        store_longitude: -73.9685,
        total_orders: 2057.85,
        neighborhood: 'Flatbush',
        keywords: 'Wine, Whiskey, Beer, Relaxed, Irish, Casual, Premium, Cool, Great',
        annual_dol: 2249.2,
        description:
            'Compact, relaxed Irish pub serving European beers & meat pies, with soccer on TV & simple decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8045,
        store_longitude: -73.9662,
        total_orders: 742.24,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Burger, Hamburger, Pizza, Salad, Beer, Bbq, Margarita, Vodka, Tasty, American',
        annual_dol: 757.98,
        description:
            'Creatively topped burgers plus craft beers & boozy milkshakes in the light of a huge neon sign.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.832,
        store_longitude: -73.8657,
        total_orders: 2.76,
        neighborhood: 'East Bronx/Soundview',
        keywords:
            'Burger, Hamburger, Pizza, Mexican, Salad, Breakfast Burrito, Nachos, Margarita, Pinacolada, Quesadillas',
        annual_dol: 11.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.9658,
        total_orders: 467.67,
        neighborhood: 'Lenox Hill',
        keywords: 'Burger, Sushi, Pizza, Italian, Mexican, Delicious, Cozy, Warm, Asian, Classy',
        annual_dol: 487.95,
        description:
            'Intimate restaurant with warm wooden fixtures featuring traditional Northern Italian food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.7764,
        total_orders: 37.7,
        neighborhood: 'Fresh Meadows/Auburndale/Flushing',
        keywords:
            'Burger, Salad, Coffee, Roast Beef, Dessert, Wine, Club Sandwich, Caribbean, Casual, Great',
        annual_dol: 66.09,
        description:
            'Old-school diner serves hefty portions of breakfast fare, burgers & made-from-scratch desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9717,
        total_orders: 16.29,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Luxurious, Great, Clean, Casual',
        annual_dol: 66.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7604,
        store_longitude: -73.9947,
        total_orders: 71.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Beer, Champagne, Wine, Vodka, Cosmopolitan, Green Tea Shot, Bbq, Karaoke',
        annual_dol: 294.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9834,
        total_orders: 99.67,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Australian, American, Amazing, Spanish, Great, Exceptional, Excellent, Friendly, Clean',
        annual_dol: 407.42,
        description:
            "mile from the Empire State Building.The warmly decorated rooms provide Wi-Fi, flat-screen TVs and minifridges. Family rooms and suites have sofabeds, and some suites add city views.There's a 24-hour fitness room and a theater-inspired dining area. A breakfast buffet is available, as is limited parking.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7561,
        store_longitude: -73.9808,
        total_orders: 119.93,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Mexican, Tacos, Burritos, Pizza, Salad, Delicious, Local, Casual',
        annual_dol: 490.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8713,
        store_longitude: -73.8481,
        total_orders: 153.93,
        neighborhood: 'Pelham Gardens/Laconia/East Bronx',
        keywords:
            'Lobster, Salad, Caribbean, Jamaican, Daiquiri, Sweet, Fun, American, Casual, Cool',
        annual_dol: 188.56,
        description:
            'Snug, informal setup serving Caribbean & American cuisine, including jerk chicken & seafood buckets.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9939,
        total_orders: 2324.07,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Elegant, Incredible, Remarkable, Rooftop, Unique',
        annual_dol: 2646.96,
        description:
            'The Manhattan Center is a building in Midtown Manhattan, New York City. Built in 1906 and located at 311 West 34th Street, it houses Manhattan Center Studios, its Grand Ballroom, and the Hammerstein Ballroom, a performance venues.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7095,
        store_longitude: -74.0127,
        total_orders: 130.09,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Coffee, Modern, Authentic, Great',
        annual_dol: 555.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7187,
        store_longitude: -73.8386,
        total_orders: 27.67,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Cuban, Wine, Caribbean, Swiss, Fun, Vibrant, Spanish, Exotic, Friendly, Fusion',
        annual_dol: 75.33,
        description:
            'Traditional Cuban fare served with cocktails in a colorful, upbeat setting with murals & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7339,
        store_longitude: -74.0064,
        total_orders: 2809.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Mexican, South American, Texan, Gluten Free, Margarita, Dessert',
        annual_dol: 2874.53,
        description:
            'Southwestern comfort food, like chili & Frito Pie, & potent drinks served up in a kitschy setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6963,
        store_longitude: -73.7615,
        total_orders: 1729.47,
        neighborhood: 'Jamaica/St. Albans',
        keywords:
            'Delicious, Magical, Savory, Amazing, Perfect, Caribbean, Great, Fusion, American, Casual',
        annual_dol: 1882.11,
        description:
            '"The Lavish Venue is your prime destination for great drinks and amazing nightlife in Saint Albans, NY. The atmosphere may draw you in, but you’ll stay for the drinks, expertly mixed by our bartenders and served at prices that will make you feel like its happy hour every hour. Our menu has something for everyone. Our menu offers various cocktails and delicious food items, including our Rasta pasta, chicken wings, shrimp, and fish fingers."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7345,
        store_longitude: -74.0092,
        total_orders: 407.54,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Lobster, Scotch, Avocado Toast, Upscale, Rich, Italian, French, Serene, Modernist',
        annual_dol: 444.16,
        description:
            "Jean-Georges & Cedric Vongerichten mix modern American flavors in Richard Meier's modernist tower.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7855,
        store_longitude: -73.9764,
        total_orders: 4380.2,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Hamburger, Sushi, Salad, Beer, Nachos, Wine, American, Ale',
        annual_dol: 4494.91,
        description:
            'Vintage Art-deco restaurant serving American bistro fare, raw bar & cocktails, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -74.0121,
        total_orders: 117.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Lobster Roll, Burger, Salad, Tasty, Organic, Romantic, Fresh, Casual, Wonderful',
        annual_dol: 479.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7521,
        store_longitude: -73.7028,
        total_orders: 68.34,
        neighborhood: 'Glen Oaks/Bellerose Manor',
        keywords: 'Mexican, American, Casual, Fresh',
        annual_dol: 209.79,
        description: '"Authentic Mexican cuisine w full bar."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6707,
        store_longitude: -73.9819,
        total_orders: 1303.34,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Salad, Beer, Ale, Margarita, Nachos, Tasty, French, Caesar Salad, American',
        annual_dol: 1422.93,
        description:
            'Mellow neighborhood hangout for beer & pub food with outdoor seating & a pool table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7664,
        store_longitude: -73.7066,
        total_orders: 455.42,
        neighborhood: '',
        keywords:
            'Shrimp Cocktail, Golfclub, Sushi, Impeccable, Sleek, Classic, Great, Asian, Fresh',
        annual_dol: 1886.11,
        description:
            '"Located on the former site of the Vanderbilt’s private golf club, The Lake Success Golf Club offers a challenging 6,414-yard, par 70 course with well-manicured fairways and impeccable greens. In addition to golf, members enjoy a first class club house, grill room, Social Activities Center, Fitness center and more. Tennis and Pool memberships are also available. We\'re truly a home away from home."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6706,
        store_longitude: -73.9915,
        total_orders: 27.51,
        neighborhood: 'Gowanus',
        keywords:
            'Wine, Beer, Delicious, Painkiller, Spicy, Impeccable, Martini, Meatball, Comfortable, Sweet',
        annual_dol: 112.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7301,
        store_longitude: -73.9567,
        total_orders: 1637.79,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Tequila, Beer, Tasty, Wine, Delicious, Bourbon, Cozy, Polish, Spacious',
        annual_dol: 1788.37,
        description:
            'Casual pub pouring pints to a crowd of locals, with a fireplace, theme nights & trivia games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.716,
        store_longitude: -74.0154,
        total_orders: 1661.78,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Italian, Pizza, Salad, Mimosa, Beer, Bloody Mary, Ample, Casual, Traditional, Great',
        annual_dol: 1803.1,
        description:
            'Easygoing choice serving ample portions of standard Italian favorites, plus old-school pizzas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7041,
        store_longitude: -73.9332,
        total_orders: 605.65,
        neighborhood: 'East Williamsburg',
        keywords:
            'Salad, Vegetarian, Wine, Iranian, Stylish, Hotel, Mediterranean, Classy, Exceptional, Great',
        annual_dol: 2475.69,
        description: '"Persian Eatery"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7395,
        store_longitude: -74.0073,
        total_orders: 3302.6,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Rooftop, Latin, Premium, Contemporary, Great, Casual, Unique',
        annual_dol: 3534.73,
        description:
            '"SNS Bar is intended to be a hub for community, great music and a landing spot for New Yorkers and all it\'s guests, who want to have a good time."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7345,
        store_longitude: -73.615,
        total_orders: 3459.59,
        neighborhood: '',
        keywords:
            'Lasagna, Italian, Spaghetti And Meatballs, Pizza, Rich, Wine, Dessert, Upscale, Bruschetta, Polish',
        annual_dol: 3550.18,
        description:
            'Semi-upscale chain for classic Italian fare served family-style in a relaxed, polished space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.733,
        store_longitude: -74.003,
        total_orders: 4415.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Burger, Tasty, Wine, Vodka, French, Delicious, Premium, Gin, Mimosa',
        annual_dol: 4579.51,
        description:
            "Two-floor bistro for dry-aged steaks & other French fare, with a bar that's strong on absinthe.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -74.0028,
        total_orders: 11806.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Intimate, Casual, Authentic, Legendary, Local, Great',
        annual_dol: 12072.5,
        description:
            'Tiny no-frills space delivers live jazz bands & jam sessions at modest prices.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7311,
        store_longitude: -74.0065,
        total_orders: 1058.62,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Beer, Martini, Bourbon, Gin, Rum, Rich, Karaoke, Stylish, Triple Sec',
        annual_dol: 1161.14,
        description:
            'A long-running lesbian hangout for drinks, shooting pool & dancing, especially on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -74.0045,
        total_orders: 317.26,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Wine, Sustainable, Seasonal, Cozy, Modern, Playful, Retro, Casual, Colorful',
        annual_dol: 331.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7177,
        store_longitude: -73.9984,
        total_orders: 63.62,
        neighborhood: 'Little Italy',
        keywords: 'Pizza, Italian, Salad, Delicious, Warm, Crisp, Clean, Retro, Authentic, Iconic',
        annual_dol: 260.05,
        description:
            'NYC-style slices are served in a simple space with narrow standing counters for eating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9102,
        total_orders: 52.97,
        neighborhood: 'Woodside',
        keywords: 'Delicious, Friendly, Fun, Ale, Cool, Great, Excellent, Casual, Local',
        annual_dol: 216.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5798,
        store_longitude: -73.9822,
        total_orders: 915.39,
        neighborhood: 'Coney Island',
        keywords:
            'Premium, Picturesque, Vibrant, Nutritious, Fantastic, Incredible, Classic, Tradition',
        annual_dol: 3741.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.646,
        store_longitude: -73.9579,
        total_orders: 706.61,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Rich, Zombie, Amazing, French, Cultural',
        annual_dol: 2888.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6762,
        store_longitude: -73.9802,
        total_orders: 3433.06,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Beer, Margarita, Martini, Spicy, Upscale, Bourbon, Nachos, Juicy, Cozy',
        annual_dol: 3480.46,
        description:
            'This bar/lounge features librarylike decor, plus 2 bocce courts & a basement performance space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6641,
        store_longitude: -73.9841,
        total_orders: 57.56,
        neighborhood: 'Park Slope',
        keywords: 'Vegetarian, Wine, Dessert, Thai, Chinese, Beer, Spicy, Cozy, Warm, Asian',
        annual_dol: 235.28,
        description:
            'Thai standards, beer & wine served in a low-key dining room with floor-to-ceiling windows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7657,
        store_longitude: -73.9703,
        total_orders: 556.54,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Salad, Delicious, Dessert, Warm, Italian, Creamy, Healthy, Mediterranean, Perfect',
        annual_dol: 572.5,
        description:
            'Mediterranean fare & a European-style social scene in a busy bistro off Madison Avenue.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7692,
        store_longitude: -73.9629,
        total_orders: 850.16,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Steak, Salad, French, Wine, Beer, Homemade, Modern, Gracious, Filet Mignon',
        annual_dol: 871.36,
        description:
            'Traditional French cooking offered in a white-tablecloth, bi-level backdrop with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.9606,
        total_orders: 262.69,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Wine, Stylish, Delicious, Beer, Italian, Irish, Fun, Hotel, Friendly, Perfect',
        annual_dol: 1073.77,
        description:
            '"A modern Irish-American sports bar serving cold beers and delicious bar food"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6544,
        store_longitude: -73.9594,
        total_orders: 31.44,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Pizza, Mexican, Beer, Salad, Margarita, Jamaican, Wine, Rum, Caribbean, American',
        annual_dol: 145.8,
        description:
            'Caribbean classics like jerk chicken & tropical cocktails are on offer at this neighborhood spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7667,
        store_longitude: -73.9125,
        total_orders: 55.26,
        neighborhood: 'Astoria',
        keywords: 'Wine, Beer, Upscale, Dessert, Premium, Great, Lit',
        annual_dol: 225.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6976,
        store_longitude: -73.9241,
        total_orders: 92.41,
        neighborhood: 'Bushwick',
        keywords: 'Coffee, Friendly, Playful, Local, Chill, Unique',
        annual_dol: 377.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6511,
        store_longitude: -73.8696,
        total_orders: 4352.74,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Steak, Pizza, Salad, Cajun, Wine, Tasty, Caesar Salad',
        annual_dol: 4451.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8263,
        store_longitude: -73.6832,
        total_orders: 137.85,
        neighborhood: '',
        keywords:
            'Pizza, Chicken Parmigiana, Mexican, Salad, Italian, Meatball, Wine, Delicious, Juicy, Warm',
        annual_dol: 150.24,
        description:
            'Stone-fired pizza, homemade pasta & seafood specials are the draw at this laid-back Italian eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8416,
        store_longitude: -73.7123,
        total_orders: 496.89,
        neighborhood: '',
        keywords:
            'Mexican, Tequila, Steak, Vegetarian, Margarita, Wine, Peruvian, Tex-Mex, Korean, Bbq',
        annual_dol: 657.82,
        description:
            '"Continuing in the fine tradition of all our Margarita\'s Cafe restaurants, we serve the best in traditional Mexican food, along with some new and unique recipes that will make your mouth water. Our exotic drinks are legendary & you\'ll enjoy your food and drink even more with our live entertainment."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.722,
        store_longitude: -74.0043,
        total_orders: 1035.88,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Vegan, Salad, Milkshake, Tequila, Gluten Free, Coffee, Roast Beef, Margarita, Vodka',
        annual_dol: 1410.16,
        description:
            'Always-open eatery at the SoHo Grand Hotel serving up revamped diner classics with vintage vibes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.9977,
        total_orders: 175.18,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Salad, Italian, Wine, Dessert, Beer, Meatball, Martini, Trendy, Delicious',
        annual_dol: 199.82,
        description:
            '"A trendy Italian restaurant & bar located at 143 Mulberry Street in the heart of historical Little Italy NYC. Handmade Lasagne, Ravioli, Manicotti, Fettucini with fresh-made pizza and a full seating bar with happy hour specials make Sofia\'s a must-visit. Under new management effective December 1, 2021 from Chef Andrea"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7927,
        store_longitude: -73.9407,
        total_orders: 2375.54,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Mexican, Beer, Cuban, Latin, American, Spanish, Delicious, Quaint, Impeccable, Casual',
        annual_dol: 2425.95,
        description:
            'Casual eatery serving Latin dishes and cocktails in a quaint space with exposed-brick walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7016,
        store_longitude: -73.927,
        total_orders: 508.42,
        neighborhood: 'Bushwick',
        keywords: 'Tequila, Hotdog, Beer, Whiskey, Vodka, Wine, Gin, Rum, Cozy, Yummy',
        annual_dol: 594.02,
        description:
            'Local watering hole turning out beer & hot dogs in a graffiti-scrawled space with kegs for tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6575,
        store_longitude: -73.6442,
        total_orders: 38.46,
        neighborhood: '',
        keywords:
            'Italian, Delicious, Friendly, Modern, Perfect, Fusion, American, Authentic, Cool, Caribbean',
        annual_dol: 157.22,
        description: '"Come experience the finest Caribbean fusion restaurant in Rockville Centre"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6095,
        store_longitude: -73.9321,
        total_orders: 452.18,
        neighborhood: 'Marine Park',
        keywords: 'Beer, Great, Terrific, Local, Casual',
        annual_dol: 471.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6552,
        store_longitude: -73.8729,
        total_orders: 432.36,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Salad, Wine, Bbq, Caribbean, Vegetarian, French Toast, Vodka, Southern Comfort, Fusion, Spicy',
        annual_dol: 529.63,
        description:
            'Relaxed, contemporary eatery & bar offering Caribbean dishes & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.581,
        store_longitude: -73.848,
        total_orders: 229.8,
        neighborhood: 'Belle Harbor/Far Rockaway/Rockaway Park',
        keywords: 'American, Beer',
        annual_dol: 331.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -73.9971,
        total_orders: 9336.25,
        neighborhood: 'Little Italy',
        keywords:
            'Champagne, Vodka, Luxurious, Upscale, Delicious, Spicy, Italian, Cozy, Premium, Sweet',
        annual_dol: 9534.35,
        description:
            'Creative cocktails & DJ music in a gold-themed space with a velvet rope out front.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7236,
        store_longitude: -74.0032,
        total_orders: 594.51,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Steak, Italian, Wine, Beer, Rustic, French Onion Soup, French, Organic, Contemporary',
        annual_dol: 611.76,
        description:
            'SoHo outpost of the Williamsburg original with rustic Italian food in a Tuscan-countryside setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -74.0048,
        total_orders: 2332.32,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Whiskey, Burger, Vodka, Margarita, Upscale, French, Japanese, American, New Zealand',
        annual_dol: 6044.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7201,
        store_longitude: -73.9985,
        total_orders: 67.01,
        neighborhood: 'Little Italy',
        keywords: 'Salad, Coffee, French, Rustic, Casual, Local, Great, Quiche, Signature',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6559,
        store_longitude: -73.6718,
        total_orders: 2825.04,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Cheesecake, Vegetarian, Wine, Tasty, Italian, Beer, Eclectic, Sleek',
        annual_dol: 5538.71,
        description:
            'Snug, modern lounge offering prime cut steaks, seafood & vegetarian menus, plus happy-hour deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8541,
        store_longitude: -73.8541,
        total_orders: 549.2,
        neighborhood: 'Morris Park/Indian Village',
        keywords:
            'Steak, Italian, Shrimp Cocktail, Flavorful, Tangy, Traditional, Perfect, Classic, Fresh, Great',
        annual_dol: 593.04,
        description:
            'Down-home pick for traditional red-sauced Italian standbys served up in hearty portions.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7647,
        store_longitude: -73.9146,
        total_orders: 79.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Upscale, Trendy, Modern, American, Fun, Amazing, Great, Latin, Fusion, Casual',
        annual_dol: 323.3,
        description: '"Upscale Bar Lounge"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9613,
        total_orders: 21.78,
        neighborhood: 'Lenox Hill',
        keywords: 'Burger, Hamburger, Pizza, Salad, Italian, Beer, Vodka, Dessert, Wine, French',
        annual_dol: 82.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.9456,
        total_orders: 167.68,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Garlic Bread, Italian, Wine, Crisp, Romantic, Classy, Clean, Outstanding, American, Classic',
        annual_dol: 513.72,
        description:
            'High-end place offering classic Italian dishes & pastas (with gluten-free choices), plus a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -73.9382,
        total_orders: 225.49,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Beer, Mexican, Tomato Soup, Wine, Meatball, Italian, American, Seasonal, Classic, Southern',
        annual_dol: 394.42,
        description:
            'Small, casual restaurant pairing cheese-laden flights & mains with wines & craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7388,
        store_longitude: -73.9885,
        total_orders: 1473.94,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Japanese, Wine, Italian, Classy, High-End, Sukiyaki, Authentic, Impressive, Casual',
        annual_dol: 4245.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7346,
        store_longitude: -73.992,
        total_orders: 75.65,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Thai, Tasty, Delicious, Martini, Prosecco, Fresh, Casual, Mojito, Pad Thai, Great',
        annual_dol: 285.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -73.9895,
        total_orders: 74.61,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Lasagna, Italian, Wine, Classy, Friendly, Traditional, Authentic, Casual, Great, Negroni',
        annual_dol: 304.99,
        description:
            'Cozy, cash-only Italian restaurant in the East Village lined with exposed brick.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5518,
        store_longitude: -74.1508,
        total_orders: 15.23,
        neighborhood: 'Great Kills',
        keywords:
            'Steak, Tacos, Mexican, Tex-Mex, Italian, Spanish, Delicious, Asian, Authentic, Casual',
        annual_dol: 69.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6396,
        store_longitude: -73.9676,
        total_orders: 431.95,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Hamburger, Wine, Coffee, Dessert, Italian, Rustic, Peruvian, Seasonal, Delicious',
        annual_dol: 757.3,
        description:
            'New American dishes cooked up from fresh, local ingredients in a rustic setting with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.643,
        store_longitude: -73.791,
        total_orders: 377.83,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Pizza, Coffee, Turkish, American, Local, Casual',
        annual_dol: 1323.28,
        description:
            '"The Local, an original concept from HMSHost, was designed specifically for airports and marries the freshest ingredients from local farms for menus loaded with region-centric culinary creations. The Local offers both full-service and grab-and-go convenience. It embraces only the finest ingredients and décor to help create an atmosphere unlike any other that gives travelers a true taste of what each region has to offer."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7533,
        store_longitude: -73.974,
        total_orders: 2569.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Tasty, Yummy, Intimate, Casual, Great, Local, Chill',
        annual_dol: 2624.39,
        description:
            'Gay bar near Grand Central known for its outdoor terrace & separate piano bar, plus weekly events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9711,
        total_orders: 208.55,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Italian, Beer, Trendy, Iconic, Classic, Contemporary, Great, Fine',
        annual_dol: 218.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9741,
        total_orders: 69.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Steak, Pizza, Burritos, Delicious, Local, Casual, Great',
        annual_dol: 345.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9672,
        total_orders: 1498.77,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Tacos, Vodka, Sushi, Mexican, Wine, Hamburger, Salad, Delicious, Warm, Fun',
        annual_dol: 1637.1,
        description:
            'This busy pub with private event spaces is part sleek lounge & part burgers-&-wings sports bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8754,
        store_longitude: -73.597,
        total_orders: 2819.38,
        neighborhood: '',
        keywords:
            'Chocolate Croissant, Coffee, Delicious, Comfortable, Avocado Toast, Perfect, Wonderful, Great, Homemade, American',
        annual_dol: 4602.32,
        description:
            '"Good Karma. Great Coffee. Quality Connections. Obsessively curated to serve our community. Farm to cup coffee by Devocion."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.713,
        store_longitude: -74.0097,
        total_orders: 1434.74,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Tacos, Pizza, Champagne, Hotel, Irish, Perfect, Casual, American, Intimate',
        annual_dol: 1953.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6285,
        store_longitude: -74.0292,
        total_orders: 21.08,
        neighborhood: 'Bay Ridge',
        keywords: 'Pizza, Chicken Parmigiana, Italian, Salad, Polished, Perfect, Casual',
        annual_dol: 79.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7172,
        store_longitude: -73.9017,
        total_orders: 98.11,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Beer, Trendy, Wine, Mexican, Margarita, Fresh, Irish, American, Great, Swiss',
        annual_dol: 109.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7772,
        store_longitude: -73.7276,
        total_orders: 4242.94,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Vegetarian, Shrimp Cocktail, Salad, Delicious, American, Prime Rib, Pot Roast, Caesar Salad',
        annual_dol: 4354.77,
        description:
            'High-quality beef is the focus of this branch of the classic Brooklyn steakhouse. Cash-only.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.99,
        total_orders: 1468.41,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Burger, Salad, Wine, Beer, Ale, Elegant, Polished, Irish, Friendly, Casual',
        annual_dol: 1506.31,
        description:
            'Polished sports tavern with lots of TV screens & a menu of elevated American pub food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6921,
        store_longitude: -73.9866,
        total_orders: 843.43,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Burger, Shrimp Cocktail, Coffee, Wine, American, Sleek, Spacious, Casual, Classic, Great',
        annual_dol: 3447.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.9838,
        total_orders: 994.96,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Irish, Beer, Delicious, Warm, Premium, High-End, Creamy, Classic, Refined',
        annual_dol: 1091.98,
        description:
            "from the Museum of Modern Art.Elegant rooms with chic furnishings offer free Wi-Fi, flat-screen TVs and en suite, shower-only bathrooms, plus designer toiletries and safes. Studios add sofabeds and sleep up to 3 guests.There's a refined restaurant/bar, a fitness center and conference facilities. Parking and airport transfers are available for a fee.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9842,
        total_orders: 1713.67,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Cheesecake, Lobster, Lobster Roll, Salad, Steak, Dessert, Hamburger, Tasty, Italian, Creamy',
        annual_dol: 1753.19,
        description:
            'French fare, including fixed-price meals, in elegant digs with tile floors & red banquettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.775,
        store_longitude: -73.9135,
        total_orders: 2931.22,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Sushi, Salad, Wine, Japanese, Beer, Karaoke, Chinese, Spicy, Thai, Rich Finish',
        annual_dol: 5528.93,
        description:
            'Basic spot featuring a variety of sushi-roll choices, lunch specials & happy-hour beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7112,
        store_longitude: -74.0146,
        total_orders: 466.96,
        neighborhood: 'Lower Manhattan',
        keywords: 'American',
        annual_dol: 532.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7935,
        store_longitude: -73.6914,
        total_orders: 145.01,
        neighborhood: '',
        keywords: 'Italian, Wine, Pizza, Trendy, Beer, Delicious, Casual, Friendly, Fresh',
        annual_dol: 171.83,
        description:
            '"Chef-owner Sandro Acquista brings simple, quality Italian food to this casual, trendy neighborhood spot in Manhasset. The pastas are made fresh daily, the desserts are made from scratch and everything is made with the highest quality ingredients."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -74.0025,
        total_orders: 20.13,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Iranian, Moroccan, Prime Rib, Casual',
        annual_dol: 82.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7169,
        store_longitude: -74.0079,
        total_orders: 4038.7,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Wine, Gluten Free, Dessert, Italian, Upscale, Beer, French Onion Soup, French, Ale',
        annual_dol: 4131.61,
        description:
            'Lively bistro with outdoor seating serving French-American food, plus brunch & late-night bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7723,
        store_longitude: -73.5627,
        total_orders: 1323.44,
        neighborhood: '',
        keywords: 'Beer, Comfortable, Spacious, Friendly, Eclectic, Great, Traditional, Fusion',
        annual_dol: 1424.12,
        description:
            'The NYCB Theatre at Westbury is an entertainment venue located in the hamlet of Jericho, outside of Westbury, New York.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.5877,
        total_orders: 251.25,
        neighborhood: '',
        keywords:
            'Salad, Shrimp Cocktail, Hotdog, Italian, French Onion Soup, French, Creole, Greek, Homemade, Caribbean',
        annual_dol: 1027.01,
        description:
            'Elegant Caribbean eatery & catering hall offering Sunday brunch as well as tango & salsa nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -74.01,
        total_orders: 261.75,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, French, Beer, Luxurious, Japanese, Relaxed, Zen, Legendary, Casual, Perfect',
        annual_dol: 1069.97,
        description:
            '"l’abeille is a 54 seat French restaurant located at 412 Greenwich Street on a classic Tribeca cobblestone street corner. The restaurant is centered on a six-course prix-fixe menu but also provides a rotating selection of seasonal a-la-carte dishes with a strong wine and cocktail program. l’abeille is the brainchild of Chef Mitsunobu Nagae, a veteran Michelin-starred chef who has worked at multiple legendary restaurants in Tokyo, Paris, and New York. Chef Mitsu was most recently the Chef de Cuisine at Shun in Midtown Manhattan."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -74.0002,
        total_orders: 487.95,
        neighborhood: 'Lower Manhattan',
        keywords: 'Wine, Upscale, Elegant, Trendy, Delicious, Romantic, Cozy, Warm, Hotel, French',
        annual_dol: 562.25,
        description:
            'Refined French fare, such as lobster, rabbit, duck & foie gras, served in an airy, elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7215,
        store_longitude: -74.0063,
        total_orders: 4731.92,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Delicious, Trendy, Awesome, Premium, Great, Rooftop, Hip, Cool, Attentive',
        annual_dol: 5276.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7046,
        store_longitude: -74.0098,
        total_orders: 2533.69,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Vegetarian, Lobster, Pizza, Salad, Shrimp Cocktail, Italian, Rich, Wine',
        annual_dol: 2757.74,
        description:
            'Popular throwback in the historical India House with steaks, a wine cellar & an adjoining cafe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.6469,
        total_orders: 939.25,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Wine, Peruvian, Warm, Classy, Southern, Exquisite, Casual',
        annual_dol: 939.25,
        description:
            'No-nonsense but popular Italian trattoria serving up a large menu of family-style platters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6574,
        store_longitude: -73.6469,
        total_orders: 3148.18,
        neighborhood: '',
        keywords: 'Salad, Sushi, Mexican, Japanese, Tangy, Ramen, Bbq, Modern, Yummy, Casual',
        annual_dol: 4904.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.998,
        total_orders: 4276.79,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Salad, Italian, Mediterranean, Cool, Casual, Great, Recommended, Excellent',
        annual_dol: 5483.71,
        description:
            'Richly designed space for Mediterranean crudos, pastas & roasted dishes, with a cafe & bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.9926,
        total_orders: 320.96,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Relaxed, Dynamic',
        annual_dol: 476.02,
        description:
            "Bright, relaxed rooms offer free Wi-Fi, flat-screen TVs and coffeemakers.There's an informal restaurant with a bar. Other amenities include a lobby lounge and a terrace, as well as a 24-hour gym, a business center and meeting space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.6142,
        total_orders: 2098.76,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Salad, Cajun, Italian, Beer, Caesar Salad, American, Kung Pao, Warm',
        annual_dol: 2152.46,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7434,
        store_longitude: -73.6148,
        total_orders: 863.72,
        neighborhood: '',
        keywords: 'Pizza, Italian, Vodka, Perfect, Fresh, Great, Casual, Fine, Local',
        annual_dol: 883.5,
        description:
            'A Manhattan Little Italy transplant & bustling old-school spot for fresh seafood & red-sauce pasta.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7465,
        store_longitude: -73.9187,
        total_orders: 1070.06,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Burger, Pizza, Beer, Wine, Dessert, American, Upscale, Bbq, Delicious, Quaint',
        annual_dol: 1096.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -74.0084,
        total_orders: 4546.24,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Buttermilk Biscuits, Salad, Organic, American, Delicious, Casual, Perfect, Traditional, Classic',
        annual_dol: 6800.68,
        description:
            'Weekend brunch hot spot serving homestyle American eats with many locally sourced ingredients.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -74.0101,
        total_orders: 545.68,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Chocolate Cake, Cheesecake, Chicken Parmigiana, Gluten Free, Italian, Apple Pie, Tasty, Velvety, Veal Parmigiana, Impeccable',
        annual_dol: 557.26,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -74.0081,
        total_orders: 172.74,
        neighborhood: 'Hudson Square',
        keywords: 'Fun, Sweet, Great, Fresh, Artisanal, Fine, Casual, Signature',
        annual_dol: 176.4,
        description: '"Look for the chocolate..one bar will lead you to another!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7251,
        store_longitude: -74.0079,
        total_orders: 144.77,
        neighborhood: 'Hudson Square',
        keywords:
            'Burger, Hamburger, Pizza, Cheesesteak, Salad, Beer, Juicy, Asian, American, Casual',
        annual_dol: 208.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.708,
        store_longitude: -73.9216,
        total_orders: 1067.05,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Dessert, Karaoke, Chicken Wings, Fun, American, Indulgent, Awesome, Classic, Great',
        annual_dol: 1185.44,
        description:
            'Laid-back restaurant & bar specializing in chicken wings & burgers with fries, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.654,
        store_longitude: -73.6715,
        total_orders: 301.74,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Wine, Delicious, Homemade, Great, Local, Casual, Fine, Signature',
        annual_dol: 314.82,
        description:
            '"At Savino’s, all our dishes are homemade and prepared with the freshest quality meats, fish, produce and ingredients imported from Italy. Try one of our many specialty pizza’s, appetizers and entrees prepared by the most qualified kitchen staff in the neighborhood. We cater parties of all sizes in our dining room and for delivery anywhere in the local neighborhood. Our pre-designed menus allow you to plan delicious dining for your event. Simply tell us how many people you’re expecting and we’ll handle the rest. Live entertainment is available almost every week. See our calendar to find out who’s playing on Thursday and/or Saturday nights. Savino’s hires the best performers on Long Island to deliver a performance of the highest quality."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5738,
        store_longitude: -74.1157,
        total_orders: 394.79,
        neighborhood: 'New Dorp',
        keywords:
            'Salad, Italian, Wine, Shrimp Cocktail, Coffee, Delicious, Spicy, Upscale, Rich, Elegant',
        annual_dol: 403.16,
        description:
            'Italian standards & an extensive wine list are served in an elegant setting at this longtime eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7235,
        store_longitude: -73.5703,
        total_orders: 887.78,
        neighborhood: '',
        keywords: 'Beer, Burger, Whiskey, Cajun, Wine, Ale, Spicy, Korean, Dessert, Bbq',
        annual_dol: 906.62,
        description:
            'Sports-bar chain with a casual menu of burgers, ribs & a variety of brews, plus plenty of TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.613,
        store_longitude: -73.9125,
        total_orders: 155.92,
        neighborhood: 'Mill Basin',
        keywords: 'Margarita, Clean, Fun, Bbq, Authentic, Modern, Great, Casual, Bold',
        annual_dol: 159.23,
        description:
            'Family-operated bowling alley offers various arcade games, a full-service snack bar & private rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7459,
        store_longitude: -73.6,
        total_orders: 912.58,
        neighborhood: '',
        keywords:
            'Sushi, Vodka, Salad, Martini, Gluten Free, Coffee, Tasty, Japanese, Sashimi, Friendly',
        annual_dol: 1033.22,
        description: 'Longtime Japanese sushi & hibachi house with kid-friendly chef performances.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6194,
        store_longitude: -73.9331,
        total_orders: 30.36,
        neighborhood: 'Flatlands',
        keywords: 'Beer, Comfortable, Wine, Friendly, Casual, Local, American, Great',
        annual_dol: 82.82,
        description:
            'Local hangout featuring sports on TV, darts tournaments & a familiar lineup of beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6498,
        store_longitude: -73.8709,
        total_orders: 3965.61,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Salad, Steak, Hamburger, Chicken Caesar Salad, Dessert, Italian, Beer, New-York-Style, Mediterranean',
        annual_dol: 4061.09,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -74.0036,
        total_orders: 563.59,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Mexican, Tequila, Margarita, Wine, Rustic, Classic, Fun, Latin, Authentic, Traditional',
        annual_dol: 574.4,
        description:
            'This casual, rustic cantina delivers classic Mexican fare & potent margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -74.0102,
        total_orders: 2069.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Italian, Salad, Beer, Wine, Delicious, Caesar Salad, Homemade, Fresh, Authentic',
        annual_dol: 2635.89,
        description:
            'Casual pizzeria with outdoor seating on the historic, cobbled Stone Street offering thin-crust pies.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6604,
        store_longitude: -73.8404,
        total_orders: 749.83,
        neighborhood: 'Howard Beach',
        keywords:
            'Vegetarian, Pizza, Italian, Wine, Rustic, Southern, Casual, Polished, Classy, Contemporary',
        annual_dol: 782.53,
        description:
            'Classy Italian eatery with brick-oven pies, pasta dishes, a curated wine list & contemporary decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7576,
        store_longitude: -73.6398,
        total_orders: 596.09,
        neighborhood: '',
        keywords:
            'Wine, Trendy, Italian, American, Chicken Wings, Classy, Comfortable, Amazing, Great, Fresh',
        annual_dol: 621.94,
        description:
            '"Willistons\' is a restaurant and bar now open in Williston Park, NY. Come in to enjoy our creative menus, inspired cocktails and well planned collection of wines and craft beers."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7106,
        store_longitude: -73.8506,
        total_orders: 209.09,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Korean, Beer, Bbq, Asian, Clean, Fried Chicken, Natural, Premium, Fresh, Casual',
        annual_dol: 732.32,
        description:
            '"Pelicana Chicken prepares FRESH, ALL-NATURAL, NON-GMO, 100% VEGGIE-FED, USDA VERIFIED, CAGE-FREE chickens in FRESH, NEW OIL in a clean sanitized kitchen, DAILY! Our chickens are cleaned, cut, and seasoned daily to deliver the freshest, juiciest, and tastiest chicken to our customers. In addition, we offer 50 + variations of chicken, so that our customers can taste a variety and have plenty to choose from."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6108,
        store_longitude: -73.9628,
        total_orders: 8.74,
        neighborhood: 'Midwood',
        keywords: 'Wine, Beer, Hotel, Legendary, Rare, Casual, Incredible',
        annual_dol: 35.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9829,
        total_orders: 955.44,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Steak, Pizza, Salad, Tasty, Italian, Wine, Delicious, Stylish, Vodka',
        annual_dol: 2596.57,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6609,
        store_longitude: -73.629,
        total_orders: 435.95,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Vegan, Beer, Bbq, Tasty, American, Contemporary, Friendly, Casual',
        annual_dol: 445.43,
        description:
            'Stylish, contemporary restaurant serving burgers, rotisserie fare, cocktails & craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -73.535,
        total_orders: 28.33,
        neighborhood: '',
        keywords: 'American',
        annual_dol: 115.8,
        description:
            '"LAST VETERAN HALL OF EAST MEADOW, FOR HONORABLY DISCHARGED VETERANS AND THEIR FAMILIES; PUBLIC CAN RENT OUR HALL GIVE A CALL TO OIR NUMBER FOR MORE DETAILS AND RESERVE YOUR DATE NEEDS"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7063,
        store_longitude: -73.9228,
        total_orders: 748.88,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Vodka, Beer, Margarita, Signature, Great, Classic, Casual',
        annual_dol: 1268.24,
        description:
            'Patrons line up into the wee hours to snag signature artichoke pizza from this counter-service spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6994,
        store_longitude: -73.9138,
        total_orders: 214.73,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Coffee, Beer, American, Friendly, Hip, Cool, Casual, Fusion, Local',
        annual_dol: 250.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7293,
        store_longitude: -73.6357,
        total_orders: 900.7,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, Homemade, Classy, Retro, Sweet, Fantastic, Perfect',
        annual_dol: 965.98,
        description:
            'Casual, Brooklyn-based pizzeria chain serving brick-oven pies & calzones, plus wine & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.6401,
        total_orders: 58.15,
        neighborhood: '',
        keywords: 'Tacos, Steak, Mexican, Beer, Nachos, Wine, Trendy, American, Homemade, Latin',
        annual_dol: 149.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7078,
        store_longitude: -73.9318,
        total_orders: 145.49,
        neighborhood: 'East Williamsburg',
        keywords:
            'Mexican, Margarita, Nachos, Upscale, Spicy, Delicious, Cozy, Yummy, Paloma, Classy',
        annual_dol: 511.95,
        description:
            '"Bushwick Newcomer Paloma’s BK Offers a Bold New Regional Mexican Menu Expect dreamy tortillas, regional tacos, aguachiles, and so much more"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -73.6785,
        total_orders: 105.65,
        neighborhood: '',
        keywords:
            'Italian, Steak, Pizza, Upscale, Savory, Cozy, Exquisite, Authentic, Impressive, Exceptional',
        annual_dol: 225.19,
        description:
            'Family-owned restaurant open more than 30 years serves Italian cuisine in an intimate, cozy space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6536,
        store_longitude: -73.5551,
        total_orders: 101.36,
        neighborhood: '',
        keywords:
            'Burger, Steak, Salad, Italian, Beer, Wine, Margarita, Delicious, Bourbon, Homemade',
        annual_dol: 236.66,
        description:
            '"One of the best Italian American restaurants in Merrick that serves fresh ingredients and homemade desserts coupled with fine wines and crafted mixed drinks. All in effort to make your dinning experience one to be remembered."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8395,
        store_longitude: -73.8636,
        total_orders: 63.62,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Dominican, Mexican, Bbq, Caribbean, Chinese, Puerto Rican, American, Latin, Delicious, Traditional',
        annual_dol: 260.05,
        description:
            'Colorful, family-owned eatery for traditional style Dominican plates in low-key surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9843,
        total_orders: 1616.02,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Korean, Salad, Upscale, Bbq, Delicious, Contemporary, Modern, Classy, Legendary, Traditional',
        annual_dol: 1665.96,
        description:
            'Sophisticated spot for Korean BBQ, shareable plates & upscale mains, plus bar snacks & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8365,
        store_longitude: -73.7018,
        total_orders: 247.49,
        neighborhood: '',
        keywords: 'Italian, Steak, Pizza, Upscale, Cozy, Intimate, Excellent, Great, Local, Fine',
        annual_dol: 252.74,
        description:
            'Upscale fixture draws crowds with Northern Italian classics & an extensive list of daily specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7464,
        store_longitude: -73.9178,
        total_orders: 8.8,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Hotel, Irish, Fancy, Fun, Clean, Classic, Wonderful, Cool, Great, Traditional',
        annual_dol: 35.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7966,
        store_longitude: -73.92,
        total_orders: 445.37,
        neighborhood: 'Randalls Island',
        keywords: 'Vegan, American, Urban, Local, Fresh, Spectacular, Great, Tradition',
        annual_dol: 1820.54,
        description:
            '480-acre urban park features restored wetlands, 90+ athletic fields, a community farm & recreation.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8191,
        store_longitude: -73.9268,
        total_orders: 3561.74,
        neighborhood: 'West Bronx',
        keywords:
            'Cuban, Wine, Delicious, Spacious, Fun, Spanish, Caribbean, Vibrant, Amazing, Colorful',
        annual_dol: 3968.81,
        description:
            'A menu featuring classic Cuban & Latin fare in a modern setting with ample bar & patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6518,
        store_longitude: -73.8378,
        total_orders: 2334.21,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Sushi, Pizza, Salad, Mexican, Shrimp Cocktail, Beer, Japanese, Chinese, Asian, Fusion',
        annual_dol: 2499.53,
        description:
            'Hibachi dinners & Asian-fusion bites are served in a lively waterfront bistro, lounge & sushi bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9863,
        total_orders: 496.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Salad, Wine, Dessert, Beer, Japanese, Whiskey, Spanish, Spicy, Delicious',
        annual_dol: 715.1,
        description:
            'Asian fusion joint in stylish digs serving creative sushi rolls, Vietnamese noodles & bento lunches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7768,
        store_longitude: -73.9523,
        total_orders: 1434.32,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Cheesecake, Tacos, Canadian, Spicy, New-York-Style, Italian, Funky, Warm, English Muffin, Friendly',
        annual_dol: 1616.27,
        description: '"Modern American Tavern"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7333,
        store_longitude: -73.737,
        total_orders: 35.27,
        neighborhood: 'Jamaica/Bellerose Manor/Queens Village',
        keywords:
            'Burger, Tacos, Cheesesteak, Mexican, Wine, Clean, Bbq, Friendly, Philly Cheesesteak, American',
        annual_dol: 37.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.656,
        store_longitude: -74.0068,
        total_orders: 2805.55,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Sushi, Salad, Japanese, Delicious, Trendy, Thai, Sashimi, Romantic, Ramen, Fun',
        annual_dol: 3808.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7867,
        store_longitude: -73.9505,
        total_orders: 388.2,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Hotdog, Beer, Wine, Rum, Irish, Hotel, Modern, Campari, Comfortable, Local',
        annual_dol: 405.03,
        description:
            '"A modern pub serving local New York beer, cocktails, and wine in a comfortable setting, with small bites."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -74.0013,
        total_orders: 1056.42,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Steak, Chicken Parmigiana, Meatball Sub, Meatball, Italian, Salad, Wine, Spicy, Beer, Caesar Wrap',
        annual_dol: 1078.83,
        description:
            'Traditional Italian fare & craft cocktails offered in an exposed-brick dining room with a sky light.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7964,
        store_longitude: -73.9366,
        total_orders: 404.98,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Jamaican, Wine, Rum, Hip, Lit, Creative, Cool, Casual',
        annual_dol: 1486.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7768,
        store_longitude: -73.957,
        total_orders: 275.06,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Tasty, Romantic, French, Classy, Polished, Friendly, Homemade, Casual, Classic',
        annual_dol: 355.32,
        description:
            'Swanky restaurant featuring classic French staples & wine in upmarket surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6542,
        store_longitude: -73.953,
        total_orders: 11.68,
        neighborhood: 'Little Haiti',
        keywords: 'Vegan, Tequila, Tacos, Mexican, Steak, Clean, Trendy, Casual, Latin, American',
        annual_dol: 47.74,
        description: '"A Taco & Tequila Bar In Flatbush Paying Homage To Afro-Mexicans."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6746,
        store_longitude: -74.0007,
        total_orders: 341.9,
        neighborhood: 'Red Hook',
        keywords: 'Pizza, Meatball, Spicy, Wine, Modern, Classy, Fun, Seasonal, Local, Great',
        annual_dol: 508.09,
        description:
            'Brick-&-mortar offshoot of mobile eatery, serving Neapolitan-style pies, small plates & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5873,
        store_longitude: -73.7394,
        total_orders: 177.9,
        neighborhood: '',
        keywords: 'Burger, Salad, French, Fun, Friendly, Perfect, Terrific, Exceptional',
        annual_dol: 348.79,
        description:
            '""Decades of fun in the sun have taken place at Sunny Atlantic Beach Club. Our event venue, Atrium is perfect for a sunset wedding on the beach or exceptional family celebration. Long Island’s only retractable glass enclosure facing the Atlantic Ocean insures your experience continues in comfort while you enjoy a perfect view of the endless Atlantic Ocean.""',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6356,
        store_longitude: -74.1352,
        total_orders: 5.44,
        neighborhood: 'Port Richmond',
        keywords: 'Mexican, Turkish, Delicious, Premium, Casual',
        annual_dol: 22.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.675,
        store_longitude: -73.9992,
        total_orders: 516.44,
        neighborhood: 'Carroll Gardens',
        keywords: 'Wine, Beer, Vodka, Coffee, Gin, Trendy, Delicious, Prosecco, Casual, Fresh',
        annual_dol: 842.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6372,
        store_longitude: -73.929,
        total_orders: 1595.71,
        neighborhood: 'East Flatbush',
        keywords: 'Lobster, Salad, Jamaican, Caribbean, Bbq, Contemporary, Sweet, Local, Casual',
        annual_dol: 3401.14,
        description:
            'Jamaican-style seafood boils & platters, plus salads & sides, presented in a contemporary space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7235,
        store_longitude: -73.9885,
        total_orders: 1283.13,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Luxurious, Beer, Rum, Lively, Premium, Clean, Great, Hip, Fusion',
        annual_dol: 1313.01,
        description:
            'DJ-spun tunes & happy-hour deals keep patrons content at this longtime lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6435,
        store_longitude: -73.9427,
        total_orders: 38.52,
        neighborhood: 'Little Caribbean/East Flatbush',
        keywords: 'Tasty, Margarita, Savory, Delicious, Spicy, Creole, Caribbean, Casual',
        annual_dol: 104.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5443,
        store_longitude: -74.1407,
        total_orders: 1928.18,
        neighborhood: 'Great Kills',
        keywords:
            'Lobster, Wine, Vodka, Gluten Free, Spicy, Italian, Upscale, Picturesque, American, Perfect',
        annual_dol: 2098.65,
        description:
            'Iconic restaurant, bar & banquet hall with scenic views serving upscale Italian & American meals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5848,
        store_longitude: -74.0936,
        total_orders: 378.48,
        neighborhood: 'Midland Beach',
        keywords:
            'Mexican, Meatball, Delicious, Asian, Colorful, Authentic, Casual, Local, Attentive, Fine',
        annual_dol: 535.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -73.9871,
        total_orders: 204.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Beef Stroganoff, Shrimp Cocktail, Dessert, Wine, Warm, Homemade, Casual, Fresh, Great',
        annual_dol: 835.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.7692,
        total_orders: 2805.04,
        neighborhood: 'Bayside/Flushing',
        keywords: 'Steak, Hamburger, Salad, Wine, Dessert, Beer, Ale, Caesar Salad, Sleek, Modern',
        annual_dol: 2871.02,
        description:
            'Modern pool hall with 21 tables & a sleek lounge vibe with couches, a fire pit & comfort food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.9675,
        total_orders: 96.8,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Shrimp Cocktail, Pizza, Italian, Beer, Veal Parmigiana, Southern, Casual, Exquisite, Fresh',
        annual_dol: 395.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6299,
        store_longitude: -74.0286,
        total_orders: 34.87,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Wine, Cozy, Friendly, Perfect, Great, Local, Casual, Chill',
        annual_dol: 46.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.9614,
        total_orders: 652.57,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Salad, Italian, Wine, Martini, Crisp, Friendly, Latin, Seasonal, Caesar Salad, Fun',
        annual_dol: 748.17,
        description:
            'Inventive Italian cafe with small & large plates, vibrant Pop Art decor & a patio with bridge views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9681,
        total_orders: 742.31,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Lobster, Sushi, Steak, Japanese, Spicy, Modern, Premium, Local, Casual',
        annual_dol: 3034.33,
        description:
            '"Wagyu Social is created by a team of NYC Wagyu Lovers and chefs, working with importers of the best Japanese Wagyu beef. It is our mission to bring the best authentic Japanese A5 Wagyu burgers to New York City, the best city in the world. Long live NYC, long live Wagyu."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9719,
        total_orders: 1309.23,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Upscale, Stylish, Hotel, Elegant, Spacious, Cozy, Clean, Premium, Gin, Great',
        annual_dol: 1884.3,
        description:
            'Decorated in conservative colors, upscale rooms and suites have custom pillows, marble baths, kitchenettes and 24-hour room service, plus flat-screen TVs, iPod docks and Wi-Fi; some suites have private outdoor terraces.A stylish restaurant serves a modern bistro menu. There’s a 24-hour gym and a salon, as well as a business center and a clubby lounge. Valet parking is available for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7407,
        store_longitude: -73.982,
        total_orders: 641.75,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Burger, Beer, Tasty, Wine, Delicious, Ale, Irish, Homemade, American, Friendly',
        annual_dol: 686.45,
        description:
            'Genial Irish pub offering casual American & Irish food, including brunch, plus TV sports & trivia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6902,
        store_longitude: -73.954,
        total_orders: 2586.31,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vegetarian, Perfect, Casual, American, Local',
        annual_dol: 10572,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6907,
        store_longitude: -73.9695,
        total_orders: 1473.42,
        neighborhood: 'Clinton Hill',
        keywords:
            'Pizza, Italian, Sushi, Japanese, Wine, Colorful, Friendly, Fresh, Casual, Classic',
        annual_dol: 2064.18,
        description:
            'Pizza from a wood-burning brick oven plus other Italian classics at s family-owned spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9897,
        total_orders: 1253.42,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Champagne, Spicy, Exhilarating, Phenomenal, Fun, Exotic, Classy, Premium, Memorable, French',
        annual_dol: 1361.15,
        description:
            '"Over three decades ago… FlashDancers created a sensation by introducing the scintillating LAP DANCE to New York City. Today, we continue to provide pulse-pounding entertainment at the crossroads of the world, rightfully earning our reputation as the most popular, exhilarating adult gentlemen\'s club in NYC. The secret to our success: the most beautiful and friendly entertainers in Manhattan, an incredible VIP waitstaff and a phenomenal management team. FlashDancers prides itself on our consistency and reputation as the #1 gentlemen\'s club in New York and one of the most famous strip clubs in the world."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7524,
        store_longitude: -73.9996,
        total_orders: 987.77,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Coffee, Nutritious, Delicious, Australian, Healthy, Premium, Welcoming, Natural, Fresh',
        annual_dol: 3224.85,
        description:
            '"Bluestone Lane is an Australian-inspired coffee shop, café & lifestyle brand committed to providing a genuine daily escape for all our locals. We offer premium coffee and healthy and delicious eats through an always welcoming experience that embraces our ‘Aussie’ approach to life."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7535,
        store_longitude: -73.9999,
        total_orders: 2539.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Wine, Dessert, Upscale, Premium, American, Classic, Modern, Sweet, Approachable',
        annual_dol: 3167.48,
        description:
            'A modern American menu is served in an upscale setting with an open kitchen & curated artwork.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7487,
        store_longitude: -73.987,
        total_orders: 614.75,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Vegetarian, Chinese, Wine, Beer, Peking Duck, Bbq, Delicious, Creamy, Sweet, Traditional',
        annual_dol: 1584.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6568,
        store_longitude: -73.6454,
        total_orders: 126.69,
        neighborhood: '',
        keywords:
            'Garlic Bread, Salad, Pizza, Italian, Delicious, Rich, Homemade, Southern, Casual, Perfect',
        annual_dol: 143.6,
        description:
            'Italian restaurant with red-checkered tablecloths serving pasta, panini & pizza made in a coal oven.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7252,
        store_longitude: -73.5869,
        total_orders: 1648.46,
        neighborhood: '',
        keywords:
            'Hotel, Luxurious, Steak, Comfortable, Beer, Clean, Amazing, Great, Fantastic, Casual',
        annual_dol: 4035.57,
        description:
            "Unfussy rooms feature Wi-Fi access, flat-screen TVs, desks and coffeemakers; some have access to a lounge with free continental breakfast, snacks and drinks.Parking is provided at no additional cost. There's a casual restaurant, a lobby lounge and a sports bar. There's also an indoor pool, a spa, racquetball courts and a 24/7 fitness center. Other amenities consist of 17 meeting rooms, a business center and guest laundry facilities.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7051,
        store_longitude: -74.0052,
        total_orders: 3210.87,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Lobster, Steak, Vegetarian, Salad, Italian, Gluten Free, American, Delicious, Modern',
        annual_dol: 3860.71,
        description:
            'Industrial-chic New American restaurant offering wood-fired pizzas & mains, plus a river view.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.616,
        store_longitude: -73.9298,
        total_orders: 47.25,
        neighborhood: 'Marine Park/Flatlands',
        keywords:
            'Vegetarian, Vegan, Chinese, Wine, Bbq, Casual, Friendly, Asian, Yummy, Excellent',
        annual_dol: 193.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.6187,
        total_orders: 191.64,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Gourmet, Beer, Whiskey, Tasty, Dessert, American, Delicious',
        annual_dol: 783.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6284,
        store_longitude: -74.0742,
        total_orders: 445.54,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Steak, Pizza, Whiskey, Wine, Italian, Phenomenal, Crisp, American, Perfect, Innovative',
        annual_dol: 606.52,
        description:
            '"Brought to you by the Rallo Hospitality Group, Navy Pier brings elevated New American cuisine to a space unlike any other on Staten Island. From our carefully curated menu, developed by Michelin Starred Executive Chef, Josh Laurano; to our innovative cocktail and spirits program; to our sweeping views of the Verrazano Bridge, New York Harbor, the Statue of Liberty, Manhattan & Jersey City, your experience here will be one to remember."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5943,
        store_longitude: -74.0988,
        total_orders: 131.21,
        neighborhood: 'Dongan Hills/Todt Hill',
        keywords:
            'Sushi, Salad, Spicy, French Toast, Japanese, French, Rich, Ramen, Friendly, Casual',
        annual_dol: 536.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.9874,
        total_orders: 2961.15,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Hamburger, Cheesesteak, Beer, American, Nachos, Wine, Korean, Bbq',
        annual_dol: 14462.75,
        description:
            'High-end sports-bar chain with a huge menu of New American fare & an extensive list of draft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7546,
        store_longitude: -73.9897,
        total_orders: 1408.42,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Tequila, Steak, Beer, Cheesesteak, Gin, Irish, American, Homey, Warm, Classic',
        annual_dol: 1491.01,
        description:
            '"Prohibition 39 - NYC\'s Newest American Restaurant Concept featuring 1st Floor Dining, Bar & Lounge, 2nd Floor Event, Performance Space, Restaurant Lounge & Private Dining & Rooftop Speakeasy & Dining Experience. Opening Late August 2022, visit our site to sign up for updates, exclusive invitations to the pre-opening gala & information"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.993,
        total_orders: 3281.84,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Salad, Beer, Irish, Warm, Welcoming, Casual, Classic, Traditional, Local',
        annual_dol: 3351.48,
        description:
            'Drinks & comfort food in a spacious, bi-level tavern with a warm, traditional atmosphere & 3 bars.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6504,
        store_longitude: -73.8704,
        total_orders: 1474.84,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Chocolate Cake, Zombie, Steak, Hamburger, Beer, Margarita, Wine, Chicken Wings, Korean',
        annual_dol: 5556.72,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7526,
        store_longitude: -73.9851,
        total_orders: 5331.25,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Rich, Avocado Toast, Hotel, Delicious, American, Classic, Vintage, Exotic, Global, Great',
        annual_dol: 5626.91,
        description:
            'American dining & creative cocktails set against a vintage backdrop in the Refinery Hotel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7536,
        store_longitude: -73.9913,
        total_orders: 2186.43,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Beer, Salad, American, Hotel, Delicious, Vintage, Eclectic, Friendly, Casual',
        annual_dol: 3698.58,
        description:
            'Unique tri-level haunt with a vintage vibe serving elevated pub plates & original craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9969,
        total_orders: 123.32,
        neighborhood: 'Nolita',
        keywords:
            'Burger, Lobster, Lobster Roll, Tacos, Vegetarian, Gluten Free, Meatball, Tasty, Beer, Wine',
        annual_dol: 151.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.794,
        store_longitude: -73.9344,
        total_orders: 1142.52,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Salad, Meatball, Italian, Gourmet, Old Fashioned, Southern, Tradition, Classic, Fine',
        annual_dol: 1166.76,
        description:
            'VIPs fill the dining room of this 10-table Italian icon, where who you know is key to entry.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7178,
        store_longitude: -73.998,
        total_orders: 541.06,
        neighborhood: 'Little Italy',
        keywords:
            'Lasagna, Wine, Italian, Vodka, Meatball, Spicy, Elegant, Cozy, Polished, Perfect',
        annual_dol: 1214.09,
        description:
            'Cozy Little Italy mainstay featuring pasta, fish & old-world ambiance, plus a back garden patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8605,
        store_longitude: -73.6222,
        total_orders: 83.2,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Meatball, Peruvian, Warm, Polished, Comfortable, American, Phenomenal',
        annual_dol: 176.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7509,
        store_longitude: -73.9858,
        total_orders: 592.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Hotel, Modern, Caesar Salad, Comfortable, Eclectic, Vibrant, Friendly',
        annual_dol: 632.12,
        description:
            'The streamlined rooms feature free Wi-Fi, 42-inch flat-screen TVs, minifridges and coffeemakers. They also come with separate living and sleeping areas. Upgraded rooms include city views.Amenities include a fitness center, a 24/7 cafe and a cocktail bar, as well as a business center and meeting rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8195,
        store_longitude: -73.8177,
        total_orders: 1720.57,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Pizza, Shrimp Cocktail, Italian, Margarita, Delicious, Rooftop, Fun, Healthy, American, Perfect',
        annual_dol: 4200.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7057,
        store_longitude: -74.0086,
        total_orders: 457.04,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Elegant, Stylish, Luxurious, Rich, Perfect, Southern, Classic, Great, Fine, Casual',
        annual_dol: 466.73,
        description:
            '"63 Wall Street combines two of the finest buildings in Lower Manhattan to provide a New York living experience unlike any other. Nestled in the heart of Manhattan\'s historic Financial District, 63 Wall Street apartments are unique in their perfect harmony of classic form and modern construction. With our preserved historical significance and prestigious location, 63 Wall Street offers a classic and luxurious Financial District apartment rental experience."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8359,
        store_longitude: -73.701,
        total_orders: 392.3,
        neighborhood: '',
        keywords:
            'Vegetarian, Mexican, Chicken Tikka Masala, Turkish, Spicy, Delicious, Indian, Creamy, Crisp, Modern',
        annual_dol: 417.64,
        description:
            'Roomy Indian eatery with a traditional menu, known for its wide-ranging lunch buffet.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9944,
        total_orders: 27.14,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Sushi, Salad, Spicy, Delicious, Hotel, American, Greek, Asian, Sweet',
        annual_dol: 110.93,
        description:
            'Kosher sushi & small plates provided in a rooftop destination featuring a bar & city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8243,
        store_longitude: -73.6823,
        total_orders: 1982.57,
        neighborhood: '',
        keywords:
            'Burger, Steak, Salad, Chicken Caesar Salad, Swiss, Beer, Wine, Irish, French Onion Soup, French',
        annual_dol: 2024.64,
        description:
            'Easygoing Irish pub serves typical grub in a homey, denlike setting & hosts karaoke at the bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8251,
        store_longitude: -73.6825,
        total_orders: 44.18,
        neighborhood: '',
        keywords: 'Burger, Steak, Tequila, Mexican, Beer, Wine, Bbq, French, Fun, Sweet',
        annual_dol: 180.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.6126,
        total_orders: 5228.85,
        neighborhood: '',
        keywords:
            'Steak, Salad, Wine, Chilean, Spicy, Upscale, Brazilian, American, Friendly, Southern',
        annual_dol: 6968.9,
        description:
            'Upscale Brazilian chain for all-you-can-eat meat carved tableside plus an extensive salad bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6566,
        store_longitude: -73.626,
        total_orders: 2653.71,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Whiskey, Beer, Cajun, American, Relaxed, Clean, Casual, Coleslaw',
        annual_dol: 2710.02,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6653,
        store_longitude: -73.7217,
        total_orders: 162.6,
        neighborhood: '',
        keywords:
            'Salad, Lobster, Italian, Pizza, Tasty, Spicy, Delicious, Homemade, Pinacolada, Irresistible',
        annual_dol: 177.21,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6596,
        store_longitude: -73.6447,
        total_orders: 66.28,
        neighborhood: '',
        keywords: 'Beer, Martini, Jamaican, Wine, Rum, Caribbean, Spicy, Bbq, Upscale, Painkiller',
        annual_dol: 83.24,
        description:
            'This snug yet colorful little eatery is known for lively reggae nights & upmarket Caribbean fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7067,
        store_longitude: -74.009,
        total_orders: 1874.25,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hamburger, Savory, Irish, Upscale, Amazing, Local, Casual, Great, Fine, Unique',
        annual_dol: 6662.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8684,
        store_longitude: -73.6316,
        total_orders: 229.31,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Spicy, Delicious, Chinese, Sweet, Hawaiian, Japanese, Asian, Spanish',
        annual_dol: 244.55,
        description:
            '"Come and enjoy a night out with delicious Asian Fusion Restaurant at Sweet Mandarin Bistro. We offer a variety of delicious dishes, with a focus on quality and freshness. We offer an extensive sushi bar, delicious Chinese foods, entertaining hibachi tables, in addition to a great lunch menu. We will be opening up our upstairs patio for dine-in soon. Located at the heart of Glen Cove, at 18 Cottage Row, Glen Cove, NY, 11542"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8715,
        store_longitude: -73.6256,
        total_orders: 979.86,
        neighborhood: '',
        keywords: 'Italian, Salad, Wine, Pizza, Coffee, Upscale, Cozy, Premium, Clean, Friendly',
        annual_dol: 1008.37,
        description:
            'Upmarket Sicilian favorites including wines & pasta options in a homey atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7427,
        store_longitude: -73.7216,
        total_orders: 8.03,
        neighborhood: 'Glen Oaks/Bellerose Manor',
        keywords:
            'Vegetarian, Butter Chicken, Chicken Tikka Masala, Fried Rice, Indian, Tandoori Chicken, Delicious, Casual, Dal Makhani, Aloo Tikki',
        annual_dol: 32.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.815,
        total_orders: 50.16,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Steak, Salad, Vodka, Bbq, Fried Rice, Asian, Kibbeh, Eclectic, Spacious, Casual',
        annual_dol: 63,
        description:
            'Casual, spacious steakhouse offering an eclectic menu of Italian, Asian & Middle Eastern fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8245,
        store_longitude: -73.8205,
        total_orders: 48.93,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Steak, Vodka, Delicious, Latin, American, Fusion, Exotic, Lively, Casual, Sangria',
        annual_dol: 76.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8654,
        store_longitude: -73.6311,
        total_orders: 176.45,
        neighborhood: '',
        keywords:
            'Italian, Wine, Pizza, Beer, Romantic, Refined, Outstanding, Classy, Awesome, Amazing',
        annual_dol: 454.68,
        description:
            'Original Italian eatery in a family-owned chain offers refined fare in old-fashioned environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5754,
        store_longitude: -74.1051,
        total_orders: 5361.88,
        neighborhood: 'Midland Beach',
        keywords:
            'Lobster, Lobster Tail, Tasty, Gourmet, Dessert, Wine, Beer, Delicious, Coffee, Italian',
        annual_dol: 5910.25,
        description:
            'Eclectic, purple-hued cellar offering a range of classic American dishes plus cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6302,
        store_longitude: -73.7083,
        total_orders: 477.25,
        neighborhood: '',
        keywords: 'Pizza, Fun, Comfortable, Wonderful, Great, Excellent, Classic, Casual',
        annual_dol: 584.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.9863,
        total_orders: 24840.42,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Burger, Salad, Roast Beef, Wine, Legendary, Beer, Prime Rib, Crisp',
        annual_dol: 25693.85,
        description:
            'Enormous steaks & signature mutton chops served in a maze of clubby, wood-paneled rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5815,
        store_longitude: -73.83,
        total_orders: 1944.78,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords:
            'Tacos, Burger, Hotel, Urban, Innovative, American, Rooftop, Eclectic, Contemporary, Fresh',
        annual_dol: 3405.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6739,
        store_longitude: -73.6703,
        total_orders: 991.07,
        neighborhood: '',
        keywords: 'Wine, Italian, Beer, Rustic, Premium, Chic, Authentic, Diverse, Local',
        annual_dol: 1012.1,
        description:
            'Old-world Italian spot with rustic-chic decor offering entrees, small plates & a diverse wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -73.9217,
        total_orders: 593.65,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Beer, Whiskey, Margarita, Italian, Spicy, Cozy, Asian, Hip, Friendly',
        annual_dol: 736.77,
        description:
            'Hip hangout with outdoor seating fixing up beer & cocktails in a laid-back setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7873,
        store_longitude: -73.9516,
        total_orders: 941.76,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Wine, Beer, Margarita, Warm, Rum, Cozy, Phenomenal, Friendly, Perfect, Bright',
        annual_dol: 1130.84,
        description:
            'This cozy cocktail haunt features a bartop with mixed nuts suspended in epoxy & other quirky decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7408,
        store_longitude: -73.5992,
        total_orders: 410.83,
        neighborhood: '',
        keywords:
            'Luxurious, Hotel, Spacious, Fun, Sophisticated, Incredible, Great, American, Salad, Distinctive',
        annual_dol: 447.74,
        description:
            '"Distinct, luxurious, 1 & 2 bedroom luxury apartments in Nassau County on Long Island. Experience 5-star living every day, in these incredible rental residences. Open floor plans, dramatic 9\' ceilings, spa baths, sunlit rooms and walk in closets. The best of hotel style living, in the comfort of your own building. Fitness center, concierge, outdoor heated pool, parking, exclusive dining and more. Limited availability, contact us for a tour & details on this brand new rental apartments. Vanderbilt also offers short and long term stays."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -74.0059,
        total_orders: 406.77,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Upscale, Tasty, Elegant, Legendary, Impeccable, Classy, Premium, Gourmet, French',
        annual_dol: 433.5,
        description:
            'Modern European plates pair with an extensive wine list & cocktails in an elegant, open interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -74.0102,
        total_orders: 115.01,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Pizza, Delicious, Memorable, Perfect',
        annual_dol: 470.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6106,
        store_longitude: -73.6456,
        total_orders: 24.3,
        neighborhood: '',
        keywords: 'Hotel, Fun, Awesome, Western, Great, Fine, Casual, Local',
        annual_dol: 99.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9838,
        total_orders: 335.37,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Wine, Coffee, Upscale, French, Brazilian, Contemporary, Fancy, Robust, Classic',
        annual_dol: 1370.88,
        description:
            '"Ipanema is dedicated to building quality relationships, cultivated through top quality cooking and delivering one-of-a-kind dining experiences. While Ipanema sells modern interpretations of classic dishes from Portugal and Brazil, the menu combines Portuguese recipes with the zest and vibrancy of Brazilian cuisine rooted in French cooking techniques. Our “cheerful and friendly spot tastes just like Brazil & Portugal” and feels like a home “inside-of-a-restaurant.” A seasonal menu by Executive Chef Giancarlo Junyent offers sharing plates of fresh appetizers and mid courses, with a lovely finale of roasted meats and cured seafood, followed by decadent desserts and wine."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.9925,
        total_orders: 109.55,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Comfortable, Coffee, Clean, Friendly, Sleek, Modern, Great, Exceptional, Contemporary',
        annual_dol: 447.8,
        description:
            'The understated rooms offer Wi-Fi, coffeemakers and minifridges. Upgraded rooms have views of the Empire State Building.Amenities include a compact gym and a business center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8074,
        store_longitude: -73.9273,
        total_orders: 472.33,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Beer, Trendy, Rich, Mimosa, Delicious, Southern Comfort, American, Puerto Rican, Classy, Hip',
        annual_dol: 518.22,
        description:
            'Trendy restaurant dishing up Southern & Puerto Rican comfort foods, plus brunch staples & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -74.0064,
        total_orders: 533.58,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Italian, Tasty, Cozy, Gin, Warm, Beer, Rustic, Comfortable, Premium',
        annual_dol: 622.44,
        description:
            'Convivial wine bar serving its vintages & Italian small plates in cozy digs with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6557,
        store_longitude: -73.8387,
        total_orders: 1514.95,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Steak, Salad, Italian, Fried Calamari, Wine, Bbq, Beer',
        annual_dol: 1563.95,
        description:
            'Lively canteen with a big menu of seafood, raw-bar offerings, BBQ & Italian standards.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.718,
        store_longitude: -73.9982,
        total_orders: 1285.6,
        neighborhood: 'Little Italy',
        keywords:
            'Italian, Wine, Delicious, Baked Clams, Spacious, Innovative, Bruschetta, Amazing, Authentic, Traditional',
        annual_dol: 2067.47,
        description:
            'Traditional Northern Italian eatery since 1975 with veteran waiters & a back garden atrium.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7874,
        store_longitude: -73.9528,
        total_orders: 377.41,
        neighborhood: 'East Harlem/Central Park West Historic District',
        keywords:
            'Mexican, Burritos, Cuban, Cozy, Casual, Local, Authentic, Classic, Traditional, Fresh',
        annual_dol: 385.42,
        description:
            'Cozy cantina with colorful decor for Mexican street food, aguas frescas & tequila.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7169,
        store_longitude: -74.0087,
        total_orders: 6527.03,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Champagne, Wine, Beer, Bourbon, Gin, Delicious, Martini, Warm, Rum, Sleek',
        annual_dol: 6729.1,
        description:
            'Slick, Prohibition-style watering hole with craft cocktails & sophisticated bar snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7776,
        store_longitude: -73.9521,
        total_orders: 1286.18,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Tacos, Hamburger, Tasty, Beer, Nutritious, Tomato Soup, Delicious, Upscale, American',
        annual_dol: 1316.37,
        description:
            'Rustic, brick-walled bar with American eats, tacos & brunch plus craft drafts & specialty cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7029,
        store_longitude: -73.9294,
        total_orders: 1382.14,
        neighborhood: 'Bushwick',
        keywords:
            'Tacos, Tequila, Mexican, Margarita, Enchiladas, Trendy, Rich, Rum, Delicious, Hip',
        annual_dol: 1417.05,
        description:
            'Place for simple, modern Mexican food (like tacos, tortas & grilled dishes) with cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9942,
        total_orders: 643.99,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Whiskey, Wine, Beer, Irish, Salad, Vietnamese, Canadian, French, American, Eggs Benedict',
        annual_dol: 2285.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7046,
        store_longitude: -74.0098,
        total_orders: 1154.93,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Rich, Exquisite, Wonderful, Great, Intimate, Fine, Local',
        annual_dol: 4720.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7403,
        store_longitude: -74.0056,
        total_orders: 1422.39,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Wine, Beer, Martini, Asian, Elegant, Sleek, Exotic, Fusion, American, Fresh',
        annual_dol: 3115.29,
        description:
            'Asian-inspired cocktails & bites delivered from a dim sum cart are presented in an ornate space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7307,
        store_longitude: -74.0069,
        total_orders: 3217.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Sushi, Gluten Free, Japanese, Wine, Tasty, Organic, Sashimi, Spicy, Magical',
        annual_dol: 3309.68,
        description:
            'Sleek spot for modern, seasonal Japanese cuisine, including housemade tofu, plus a long sake list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7302,
        store_longitude: -74.0034,
        total_orders: 693.8,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Salad, Pizza, Tasty, Organic, Wholesome, Cuban, Dessert',
        annual_dol: 840.37,
        description:
            'Organic vegan comfort food, along with cocktails & mocktails in an intimate West Village setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7276,
        store_longitude: -73.6351,
        total_orders: 3071.53,
        neighborhood: '',
        keywords: 'Lobster, Pizza, Italian, Wine, Meatball, Spicy, Creamy, Premium, Sweet, Global',
        annual_dol: 3183.67,
        description:
            'A vast selection of wines is kept fresh by a high-tech system & served with small plates & pasta.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8396,
        store_longitude: -73.9405,
        total_orders: 1627.06,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Burger, Crab Cakes, Salad, Cornbread, Shrimp Cocktail, Bbq, Friendly, Coleslaw, Casual, Local',
        annual_dol: 1697.61,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6686,
        store_longitude: -73.9932,
        total_orders: 1825.82,
        neighborhood: 'Gowanus',
        keywords: 'Pizza, Beer, Wine, Tranquil, Relaxed, Awesome, Fresh, Friendly, Amazing, Great',
        annual_dol: 1864.56,
        description:
            'Relaxed outpost for cocktails & a rotating beer selection, plus a tranquil outdoor seating area.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6638,
        store_longitude: -73.9898,
        total_orders: 72.22,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Dessert, Russian, Sweet, Iconic, Fine, Complex, Samosas, Teriyaki',
        annual_dol: 295.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.662,
        store_longitude: -73.9925,
        total_orders: 495.93,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Hamburger, Beer, Austrian, German, Martini, Korean, Hungarian, Wine, Polish',
        annual_dol: 565.69,
        description:
            'Quirky kitchen with Eastern European-inspired comfort food, including hamburgers, plus draft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8599,
        store_longitude: -73.8929,
        total_orders: 194.04,
        neighborhood: 'Belmont/Fordham Heights/West Bronx',
        keywords:
            'Steak, Wine, Beer, Margarita, Hawaiian, Spanish, Pinacolada, Caribbean, American, Delicious',
        annual_dol: 256.88,
        description:
            'Lively, clubby destination with a global menu featuring Italian, Latin & local dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6581,
        store_longitude: -73.648,
        total_orders: 479.96,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Gluten Free, Beer, Dessert, Irish, Delicious, Relaxed, American, Friendly',
        annual_dol: 490.15,
        description:
            'Down-to-earth tavern offering a pub menu with Irish specialties, plus draft beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6121,
        store_longitude: -73.941,
        total_orders: 229.38,
        neighborhood: 'Marine Park',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Sushi, Salad, Mexican, Italian, French Onion Soup, French',
        annual_dol: 247.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6396,
        store_longitude: -73.661,
        total_orders: 409.36,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Salad, Pizza, Shrimp Scampi, Italian, Beer, Wine, Moroccan, Delicious',
        annual_dol: 1488.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8314,
        store_longitude: -73.8271,
        total_orders: 1569.95,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Lobster, Steak, Sushi, Salad, Beer, Japanese, Tasty, Sashimi, Asian, Sweet',
        annual_dol: 2562.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -73.5598,
        total_orders: 188.33,
        neighborhood: '',
        keywords: 'Steak, Coffee, Gourmet, Beer, Bbq, American, Ribeye Steak, Mead, Casual, Unique',
        annual_dol: 355.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8231,
        store_longitude: -73.8196,
        total_orders: 326.7,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Whiskey, Beer, Delicious, American, Casual, Fun, Fine',
        annual_dol: 801.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7194,
        store_longitude: -73.5647,
        total_orders: 1021.96,
        neighborhood: '',
        keywords:
            'Salad, Tacos, Mexican, Margarita, Wine, Beer, Chinese, Casual, Exotic, Traditional',
        annual_dol: 1785.44,
        description:
            'Festive eatery specializes in margaritas & Mexican classics, with occasional live mariachi music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7576,
        store_longitude: -73.993,
        total_orders: 2903.47,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Champagne, Beer, Margarita, Trendy, Stylish, Spicy, Savory, Gracious, Cointreau, Triple Sec',
        annual_dol: 2965.08,
        description:
            "Along with 28 lanes of bowling there's a dance club, billiards lounge & various package deals.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.751,
        store_longitude: -73.9832,
        total_orders: 898.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Lobster, Hamburger, Pizza, Salad, Cajun, Nachos, Lobster Bisque, Beer, Cuban',
        annual_dol: 3217.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9927,
        total_orders: 103.71,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Hotel, Spanish, Stylish, Cozy, Classy, Classic, Adventurous, Contemporary, Iconic',
        annual_dol: 423.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7535,
        store_longitude: -73.6275,
        total_orders: 419.2,
        neighborhood: '',
        keywords: 'Fun, Friendly, Perfect, Wonderful, Amazing, Great, Classic, Casual',
        annual_dol: 456.35,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8345,
        store_longitude: -73.8504,
        total_orders: 447.58,
        neighborhood: 'East Bronx/Westchester Square/Parkchester',
        keywords:
            'Mexican, Beer, Margarita, Martini, Wine, Spanish, Puerto Rican, Caribbean, American, Latin',
        annual_dol: 527.41,
        description:
            'Casual spot for Puerto Rican home cooking plus classic cocktails amid art-filled walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9828,
        total_orders: 75.65,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Upscale, Coffee, Hotel, Healthy, Lively, Romantic, Rooftop, Sleek, Classic, Chic',
        annual_dol: 573.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.6765,
        total_orders: 1004.7,
        neighborhood: '',
        keywords:
            'Beer, Ale, Cajun, Burger, Wine, Chicken Wings, Asian, Delicious, Southern, Classic',
        annual_dol: 1029.33,
        description:
            'Outpost of a local alehouse chain featuring a selection of craft beers, pub grub & wing specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.7026,
        total_orders: 102.08,
        neighborhood: 'Floral Park/Bellerose Manor',
        keywords:
            'Martini, Gin, Spicy, Delicious, Tandoori Chicken, Fusion, American, Sweet, Modern, Indian',
        annual_dol: 116.45,
        description:
            '"ABCD Indian Bistro & Bar was born out of the love of a foodie who experienced his cultural recipes in a foreign land. Enjoy different cuisines in the best Indian restaurant in queens."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7723,
        store_longitude: -73.9299,
        total_orders: 429.68,
        neighborhood: 'Astoria',
        keywords: 'Beer, Wine, Perfect, American, Great, Excellent, Fusion, Casual, Chill, Unique',
        annual_dol: 489.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -73.674,
        total_orders: 20.41,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Mexican, Wine, Tasty, American, Casual, Traditional, Great',
        annual_dol: 83.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6748,
        store_longitude: -73.976,
        total_orders: 108.78,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Beer, Salad, Gourmet, Friendly, Eclectic, Fun, Lively, Casual, Artisanal',
        annual_dol: 444.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9109,
        total_orders: 37.52,
        neighborhood: 'Astoria',
        keywords: 'Wine, Tasty, Beer, Irish, Cozy, Rum, Friendly, Great, Casual, Local',
        annual_dol: 181.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7724,
        store_longitude: -73.9605,
        total_orders: 3103.81,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Wine, Beer, French, Italian, French Onion Soup, American, Delicious, Premium, Warm',
        annual_dol: 3108.35,
        description:
            'Popular with neighborhood locals & expats, the Parisian-style eatery dishes authentic French fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9691,
        total_orders: 3923.53,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Chocolate Cake, Cheesecake, Pizza, Carrot Cake, Salad, Coffee, Wine, Shrimp Cocktail, Hotel',
        annual_dol: 4243.78,
        description:
            "Chic, classically furnished rooms have free Wi-Fi and smart TVs, as well as desks with ergonomic chairs. Upgrades add 47-inch TVs, living areas or views of Park Avenue. Luxe 1- to 3-bedroom suites feature sleek atriums, kitchens, soaking tubs and/or balconies with city views.There's a contemporary restaurant with a grill serving American cuisine and afternoon tea, plus a swanky bar and a lounge. There's also a salon, a plush spa and a fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8305,
        store_longitude: -73.8156,
        total_orders: 323.27,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'American, African, Diverse, Local',
        annual_dol: 340.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8707,
        store_longitude: -73.6177,
        total_orders: 1109.84,
        neighborhood: '',
        keywords: 'Golfclub, Classic',
        annual_dol: 1182.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7503,
        store_longitude: -73.9847,
        total_orders: 9971.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Japanese, Ramen, Spicy, Delicious, Trendy, Zen, Fresh, Casual',
        annual_dol: 10063.26,
        description:
            'Branch of a Tokyo-based chain specializing in tonkotsu ramen, griddled dishes & fried chicken.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6565,
        store_longitude: -73.6451,
        total_orders: 161.57,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Beer, Lobster, American, Wine, Casual, Creative, Fine',
        annual_dol: 180.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6121,
        store_longitude: -73.6438,
        total_orders: 302.43,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Beer, Wine, Martini, Margarita, Coffee, Delicious, Ale',
        annual_dol: 411.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9836,
        total_orders: 8996.03,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, Italian, Stylish, Hotel, French, Gourmet, Elegant, Delicious, Romantic, Inviting',
        annual_dol: 9463.98,
        description:
            'Gourmet fare from the Italian & French Riviera plus regional wines in an elegant hotel setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -73.9837,
        total_orders: 1296.55,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Irish, Fun, Friendly, Awesome, Great, Casual, Chill',
        annual_dol: 1371,
        description:
            '"Traditional Irish pub and sports bar in midtown Manhattan located at 20 West 38th Street between 5th & 6th Avenue."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.9937,
        total_orders: 2332.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Hamburger, Vegetarian, Salad, Tuna Steak, Bbq, Dessert, American, Hotel',
        annual_dol: 2548.85,
        description:
            '"Burgerology is a USDA Prime burger restaurant that features crazy milkshakes, hand cut fries, craft beer, wine, impossible sundaes and craft cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6496,
        store_longitude: -73.8717,
        total_orders: 788.62,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Steak, Burger, Lobster, Hamburger, Italian, Chicken Wings, Juicy, Creole, Sweet, Casual',
        annual_dol: 1676.34,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.752,
        store_longitude: -73.9829,
        total_orders: 330.32,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Cajun, Savory, Gourmet, Tasty, American, Organic, Friendly, Casual',
        annual_dol: 951.44,
        description:
            '"Savory is a boutique, multifaceted hospitality company based in the heart of New York City, offering a wide variety of culinary programs, specializing in office catering, events, and food and beverage operations."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.924,
        total_orders: 142.22,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Lasagna, Italian, Pizza, Salad, Coffee, Donut, Beer, Creamy, Homemade, American',
        annual_dol: 165.77,
        description:
            'Italian classics, including housemade pasta, served in a bright space with exposed-brick walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6248,
        store_longitude: -73.9176,
        total_orders: 227.77,
        neighborhood: 'Bergen Beach',
        keywords:
            'Lobster, Cajun, Salad, Lobster Bisque, Flavorful, Bbq, Creole, Fusion, Classy, Clam Chowder',
        annual_dol: 429.63,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6015,
        store_longitude: -73.656,
        total_orders: 168.88,
        neighborhood: '',
        keywords: 'Ale, Beer, Wine, Bourbon, Scotch, Local, Seasonal, Casual, Great',
        annual_dol: 180.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7051,
        store_longitude: -74.0087,
        total_orders: 70.73,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Lobster Tail, Burger, Shrimp Cocktail, Salad, Tequila, Dessert, Greek, Mediterranean, Bbq',
        annual_dol: 289.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6194,
        store_longitude: -73.9218,
        total_orders: 76.5,
        neighborhood: 'Flatlands',
        keywords: 'Steak, Pizza, Gourmet, Beer, American, Bbq, Irish, Creamy, Homemade, Classic',
        annual_dol: 312.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.9957,
        total_orders: 333.38,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Pizza, Salad, Beer, Asian, Modern, Contemporary, Casual, Local, Classic',
        annual_dol: 379.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9956,
        total_orders: 380.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Wine, Local, Casual, Great, Attentive, Chill, Unique',
        annual_dol: 1562.46,
        description:
            "Metropolitan bar with black & white art & a 'throwing range' to smash glasses & electronic items.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6204,
        store_longitude: -73.9272,
        total_orders: 110.49,
        neighborhood: 'Flatlands',
        keywords:
            'Salad, Dessert, Tasty, Mimosa, Champagne, Organic, Jamaican, Caribbean, Rustic, Creamy',
        annual_dol: 246.77,
        description:
            '"Bellyful Restaurant & Bar is an elegantly rustic Caribbean Restaurant catering to the discerning customer. We are located at 2102 Utica Avenue in Brooklyn New York. Our ambiance truly encapsulates the Pan-African spirit and culture, all private occasions can be accommodated within our mezzanine area. Our dishes contain nothing but the authentic Jamaican flavors that truly excite your taste buds. The majority of our dishes are inspired by classic and timeless Jamaican recipes. While it is guaranteed that your taste buds will come alive from dishes infused with flavors from the Caribbean; the food at Bellyful is just the beginning of your overall culinary experience."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8576,
        store_longitude: -73.6354,
        total_orders: 1824.02,
        neighborhood: '',
        keywords:
            'Elegant, Exquisite, Intimate, Perfect, Awesome, Impressive, Great, Artistic, Seasonal, Local',
        annual_dol: 2126.12,
        description:
            '"Elegant Affairs Caterers We are Long Island\'s NY Catering Company of Choice providing Full-Service Off-Premise Catering. We offer Catering Services To Social and Corporate Events, Private Parties, and Weddings in Long Island NY and nearby areas. Menu Planning & Food Styling We offer an array of seasonal local cuisine that is impressive to the eye, as well as, the palate. Party Rental Coordination. We are a team of expert organizers and are always happy to help coordinate your party rentals. Vendor Coordination In addition to bringing exuberance and style to every event we cater to — we also work well with others. Our seasoned team takes pride in handling coordinating with your vendors to ensure expectations are not only met but surpassed."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.727,
        store_longitude: -73.8223,
        total_orders: 77.89,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Champagne, Wine, Beer, Wonderful, Great, Intimate',
        annual_dol: 84.89,
        description:
            '"Queens NY. The #1 Catering Hall in Kew Gardens Hills, Queens! Exclusive Use. Book Now! Professional Service. Family Owned and Operated since 1975."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5968,
        store_longitude: -73.6541,
        total_orders: 194.88,
        neighborhood: '',
        keywords:
            'Burger, Cheesesteak, Beer, Organic, Ale, Philly Cheesesteak, Spanish, American, Irish, Homemade',
        annual_dol: 280.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5786,
        store_longitude: -73.8497,
        total_orders: 4526.54,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords:
            'Tacos, Mexican, Steak, Beer, Venezuelan, Delicious, Contemporary, Classic, Friendly, Fresh',
        annual_dol: 4722.8,
        description:
            'Cool, contemporary Mexican kitchen offering classic street food, creative cocktails & many tequilas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5796,
        store_longitude: -74.1093,
        total_orders: 726.7,
        neighborhood: 'Midland Beach',
        keywords: 'Burger, Pizza, Italian, Juicy, Beer, Wine, Bbq, Creamy, Legendary, Peruvian',
        annual_dol: 774.57,
        description:
            '"The world\'s best pizza courtesy of the legendary Di Fara Pizza from Brooklyn! Also serving a full Italian menu ala Juicy Lucia."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8306,
        store_longitude: -73.6936,
        total_orders: 51.38,
        neighborhood: '',
        keywords:
            'Salad, Steak, Upscale, Martini, Spicy, French, Thai, Cozy, Quaint, Mediterranean',
        annual_dol: 66.37,
        description:
            'Intimate & upscale New American eatery offers fixed-price & beer-pairing specials in candlelit digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8129,
        store_longitude: -73.6792,
        total_orders: 1224.01,
        neighborhood: '',
        keywords: 'Golfclub, Bbq, Fabulous, Fantastic, Terrific, Great, Complex',
        annual_dol: 1362.85,
        description:
            'The North Hempstead Country Club is a private country club in the Incorporated Village of Flower Hill, on Long Island, in New York, United States.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5842,
        store_longitude: -73.9353,
        total_orders: 300.02,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Upscale, Luxurious, American, Exquisite, Fine, Delectable',
        annual_dol: 349.99,
        description:
            '"Private Charter Yacht available for Corporate Events, Weddings, Birthday Parties, and other Celebrations."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8211,
        store_longitude: -73.8186,
        total_orders: 3361.62,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Sushi, Japanese, Cajun, Asian, Fusion, Clean, Perfect, Casual, Unpretentious, Recommended',
        annual_dol: 4005.5,
        description:
            'Unpretentious eatery where chefs cook meat & seafood on hibachi grills, plus noodles, sushi & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6082,
        store_longitude: -73.7266,
        total_orders: 239.68,
        neighborhood: '',
        keywords: 'Hotdog, Burger, Beer, Tasty, Bbq, Stylish, Elegant, Perfect, Great, Wonderful',
        annual_dol: 756.11,
        description:
            '"Breathe in the historic site… stately manor, endless acres of manicured golfing greens, yachts majestically aligned, all embraced by the gentle bay area of the Atlantic Sea."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5732,
        store_longitude: -74.1151,
        total_orders: 603.76,
        neighborhood: 'New Dorp',
        keywords:
            'Vegetarian, Salad, Shrimp Cocktail, Italian, Wine, Meatball, Turkish, Upscale, Delicious, Classy',
        annual_dol: 629.94,
        description:
            'Bustling restaurant serving upscale Italian eats in a rustic, white-tablecloth setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5779,
        store_longitude: -73.8494,
        total_orders: 475.2,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords: 'Mexican, Wine, Tasty, Filipino, Warm, American, Irish, Asian, Casual, Local',
        annual_dol: 614.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.601,
        store_longitude: -73.6561,
        total_orders: 15.84,
        neighborhood: '',
        keywords: 'Pizza, Italian, Salad, Meatball, Vodka, Hotel, Polished, Fresh, Casual, Local',
        annual_dol: 59.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -73.5649,
        total_orders: 33.12,
        neighborhood: '',
        keywords:
            'Burger, Steak, Pizza, Salad, Sushi, Roast Beef, Beer, Wine, Caesar Salad, Creamy',
        annual_dol: 135.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6653,
        store_longitude: -73.7243,
        total_orders: 4301.44,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Cajun, Spicy, Delicious, Juicy, Sweet, Fun, Clean, Perfect',
        annual_dol: 4400.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6567,
        store_longitude: -73.645,
        total_orders: 627.43,
        neighborhood: '',
        keywords:
            'Burger, Mexican, Salad, Italian, Nachos, American, Rooftop, Irish, Elegant, Casual',
        annual_dol: 698.08,
        description:
            'Quick bites & entrees of American fare at this elegant venue with private rooms & a rooftop bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8312,
        store_longitude: -73.6949,
        total_orders: 1828.48,
        neighborhood: '',
        keywords:
            'Burger, Steak, Pizza, Salad, Shrimp Cocktail, Roast Turkey, Beer, Wine, Dessert, French Onion Soup',
        annual_dol: 2172.52,
        description:
            'Long-running venue featuring Irish & American classics, including shepherd’s pie, burgers & steak.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6555,
        store_longitude: -73.6458,
        total_orders: 1234.72,
        neighborhood: '',
        keywords: 'Burger, Salad, Italian, Beer, Wine, Upscale, Thai, American, Bbq, Delicious',
        annual_dol: 1265.23,
        description:
            'Local American mainstay for creative burgers & grilled bites, with 2 bars & patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.9858,
        total_orders: 1488.98,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Whiskey, Beer, Wine, Irish, Karaoke, Homemade, Traditional, Classic',
        annual_dol: 1556.1,
        description:
            "Classic Irish bar offering pints of Guinness, shepherd's pie, bangers & mash & jukebox tunes.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7535,
        store_longitude: -73.9923,
        total_orders: 531.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Tasty, Spicy, Delicious, Wine, Chinese, Gourmet, Beer, Spacious, Asian, Exceptional',
        annual_dol: 600.18,
        description:
            'Traditional Chinese dishes by the namesake chef are served in a modern, airy 2-story space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6263,
        store_longitude: -73.7366,
        total_orders: 522.59,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Burritos, Salad, Margarita, Yummy, Delicious, Local, Casual, Perfect',
        annual_dol: 616.18,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7054,
        store_longitude: -74.0074,
        total_orders: 1567.28,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Luxurious, Hotel, Rich, Elegant, Spacious, Spectacular, Sophisticated, Urban, Global, Intimate',
        annual_dol: 6406.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -74.0079,
        total_orders: 455.76,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Wine, Stylish, Elegant, Pizza, Japanese, Sashimi, Fun, Contemporary, Friendly',
        annual_dol: 1140.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7524,
        store_longitude: -73.9846,
        total_orders: 1077.97,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Italian, Dessert, Contemporary, American, Casual, Diverse, Cool, Traditional',
        annual_dol: 1100.84,
        description: '"Modern American Cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7956,
        store_longitude: -73.6749,
        total_orders: 19588.79,
        neighborhood: '',
        keywords:
            'Upscale, Wine, Trendy, Nutritious, Japanese, Spicy, Asian, Delicious, Creamy, Modern',
        annual_dol: 20027.45,
        description:
            'A destination for upscale Asian fusion fare in snazzy modern surroundings with a booming bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -73.5618,
        total_orders: 1370.01,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Flavorful, Bbq, Wine, Bbq Ribs, Swiss',
        annual_dol: 1399.08,
        description:
            'Chophouse standards are supplied in this family-friendly outpost with an old-school vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8258,
        store_longitude: -73.8214,
        total_orders: 126.12,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Mexican, Puerto Rican, Ale, Irish, American, Innovative, Authentic, Latin, Eclectic, Clean',
        annual_dol: 411.74,
        description: '"Bar / Kitchen / Culture"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.789,
        store_longitude: -73.6514,
        total_orders: 225.16,
        neighborhood: '',
        keywords:
            'Tacos, Cheesecake, Salad, Vegetarian, Wine, Beer, Mexican, Italian, Martini, Delicious',
        annual_dol: 234.3,
        description:
            'Casual eatery offering family-style Italian staples & wines by the glass in a traditional setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6039,
        store_longitude: -73.651,
        total_orders: 2642.08,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Lobster Bisque, Shrimp Scampi, Japanese, Wine, Martini, Spanish, New Zealand, American',
        annual_dol: 2698.14,
        description:
            'Classy chophouse with a sizable bar turning out steaks, seafood & martinis in an old-school setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.986,
        total_orders: 499.2,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Healthy, Premium, Bright, Fun, Friendly, Modern, Great, Chic, American',
        annual_dol: 550.87,
        description:
            'Contemporary rooms offer free WiFi and flat-screen TVs with premium cable channels, plus desks, minifridges and coffeemakers. Suites add separate living areas, sofas and microwaves.Social spaces include a living room-style lobby and an American restaurant serving breakfast. Other amenities include an exercise room, a business center and meeting space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6647,
        store_longitude: -73.7246,
        total_orders: 3879.42,
        neighborhood: '',
        keywords:
            'Burger, Salad, Dessert, Italian, New-York-Style, Beer, French, Chicken Wings, Rich, Creamy',
        annual_dol: 3982.64,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6556,
        store_longitude: -73.6458,
        total_orders: 927.05,
        neighborhood: '',
        keywords:
            'Burger, Hawaiian, Beer, Bbq, Moscow Mule, Ale, Blue Hawaiian, Bloody Mary, Casual, Great',
        annual_dol: 967.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6121,
        store_longitude: -73.6487,
        total_orders: 338.07,
        neighborhood: '',
        keywords: 'Lobster, Warm, Fried Clams, Southern, Fresh, Casual, American, Great, Classic',
        annual_dol: 424.58,
        description:
            'Old-fashioned eatery & raw bar serving classic American seafood & steaks since the summer of 1940.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7052,
        store_longitude: -74.0073,
        total_orders: 132.96,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Spectacular, Rooftop, Rum, Great, Comfortable, Modern, Contemporary, Friendly, Fine',
        annual_dol: 543.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7072,
        store_longitude: -74.0112,
        total_orders: 790.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Coffee, Premium, Casual',
        annual_dol: 1115.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7537,
        store_longitude: -73.9857,
        total_orders: 4353.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Whiskey, Burger, Beer, Vodka, Vegetarian, Wine, Gin, Gluten Free, American, Tasty',
        annual_dol: 4598.55,
        description:
            'Popular neighborhood hangout for drinks & American pub food served in a cozy setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7957,
        store_longitude: -73.9323,
        total_orders: 1105.03,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Salad, Chicken Caesar Salad, Milkshake, Beer, Thai, Delicious, Caesar Salad, American, Asian',
        annual_dol: 1520.59,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6571,
        store_longitude: -73.6435,
        total_orders: 54.66,
        neighborhood: '',
        keywords: 'Sushi, Salad, Japanese, Spicy, Juicy, Chinese, Sashimi, Thai, Asian, Latin',
        annual_dol: 223.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6866,
        store_longitude: -73.8229,
        total_orders: 27.56,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'South American, Spicy, Bbq, Chinese, Fried Rice, Caribbean, Asian, American, Brazilian, Elegant',
        annual_dol: 112.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7239,
        store_longitude: -73.5665,
        total_orders: 419.67,
        neighborhood: '',
        keywords: 'Burger, Premium, Latin, Fun, Classic, Great, Local',
        annual_dol: 1738.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6134,
        store_longitude: -73.9123,
        total_orders: 426.32,
        neighborhood: 'Mill Basin',
        keywords:
            'Burger, Lobster, Pizza, Salad, Gourmet, Italian, Beer, Savory, American, Delicious',
        annual_dol: 474.33,
        description:
            'Cozy store offering specialty cakes & cupcakes plus imported teas, chocolates, cheeses & breads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6807,
        store_longitude: -73.7532,
        total_orders: 1747.66,
        neighborhood: 'Springfield Gardens/St. Albans',
        keywords: 'Tacos, Steak, Pizza, Jamaican, Rum, Caribbean, Indian, Great, Wonderful, Casual',
        annual_dol: 1919.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6732,
        store_longitude: -73.6734,
        total_orders: 367.62,
        neighborhood: '',
        keywords: 'Pizza, Beer, Wine, Martini, Delicious, Irish, Bbq, Premium, Fun, Classy',
        annual_dol: 458.23,
        description: '"Friendly local bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.8516,
        total_orders: 9952.6,
        neighborhood: 'Flushing/Corona',
        keywords: 'Vodka, Great, Cool, Signature, Complex',
        annual_dol: 13200,
        description:
            'The USTA Billie Jean King National Tennis Center is a stadium complex within Flushing Meadows–Corona Park in Queens, New York City, United States.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.8447,
        total_orders: 4126.52,
        neighborhood: 'Willets Point/Corona/Flushing',
        keywords:
            'Lobster, Lobster Roll, Hotdog, Tequila, Dessert, Margarita, Delicious, Hotel, Rum, Triple Sec',
        annual_dol: 22488.92,
        description:
            "Citi Field is a baseball stadium located in Flushing Meadows–Corona Park in New York City, United States. It opened in 2009 and is the home field of Major League Baseball's New York Mets. The stadium was built as a replacement for the adjacent Shea Stadium, which opened in 1964. Citi Field was designed by Populous.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8558,
        store_longitude: -73.642,
        total_orders: 4220.86,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Tacos, Lobster Tail, Sushi, Salad, Shrimp Cocktail, Gluten Free, Meatball, Spicy',
        annual_dol: 4675.04,
        description:
            'A menu of classic seafood & bistro dishes in a family-friendly setting with rustic decor & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6099,
        store_longitude: -73.9435,
        total_orders: 71.94,
        neighborhood: 'Marine Park',
        keywords: 'Beer',
        annual_dol: 294.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6563,
        store_longitude: -73.6451,
        total_orders: 8355.55,
        neighborhood: '',
        keywords:
            'Steak, Mexican, Tequila, Margarita, Dessert, Peruvian, Savory, Trendy, Spicy, Eclectic',
        annual_dol: 8734.17,
        description:
            'This festive cantina serves standard Mexican eats & takes on a nightclub vibe after dark.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.715,
        store_longitude: -73.5449,
        total_orders: 22.78,
        neighborhood: '',
        keywords: 'Local, Casual, Cool',
        annual_dol: 25.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7503,
        store_longitude: -73.9855,
        total_orders: 1138.35,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Coffee, Trendy, Delicious, Warm, Hotel, Spicy, Italian, Classy, Intimate, Perfect',
        annual_dol: 1187.7,
        description:
            'Retro haunt in Hotel Le Soleil with a New American menu, creative cocktails & a boutique coffee bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8307,
        store_longitude: -73.6863,
        total_orders: 802.42,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Vegetarian, Hamburger, Salad, Gluten Free, Organic, Beer, Sustainable, American',
        annual_dol: 2810.35,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7547,
        store_longitude: -73.9925,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Burger, Hotel, American, Perfect, Friendly, Great, Rooftop, Chic, Unique',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6649,
        store_longitude: -73.6324,
        total_orders: 100.87,
        neighborhood: '',
        keywords: 'Pizza, Italian, Wine, Delicious, Homemade, Casual, Authentic, Great',
        annual_dol: 412.34,
        description: '"Wood Fired Pizza and Italian Cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.657,
        store_longitude: -73.6421,
        total_orders: 129.53,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Gluten Free, Wine, Delicious, Elegant, Polished, Authentic, Cool, Casual',
        annual_dol: 160.17,
        description:
            'Northern Italian fare, brick-oven pizzas & wine in an intimate, upscale-casual setting with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6093,
        store_longitude: -73.9141,
        total_orders: 1146.72,
        neighborhood: 'Mill Basin',
        keywords: 'Wine, Hurricane, Robust, Amazing, Traditional, Modern',
        annual_dol: 1334.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -74.0082,
        total_orders: 586.85,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Hotel, Beer, Spacious, Global, Great, Friendly, Local',
        annual_dol: 599.3,
        description:
            "Modern, loft-style rooms feature 11-foot ceilings, oak floors and bleached wood window seats. All rooms have free Wi-Fi, iPod docks and flat-screen TVs, plus minibars. Room service is offered.Dining options include a farm-to-table restaurant, a courtyard beer garden and a cocktail bar. There's also a 24-hour fitness center and a spa, plus a business center and event space, including 5 high-tech conference rooms, a courtyard and a garden terrace.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8196,
        store_longitude: -73.8174,
        total_orders: 83.21,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Wine, Beer, Coffee, Italian, Gluten Free, Irish, Tangy, Bbq, Spicy, Cozy',
        annual_dol: 340.15,
        description:
            'Relaxed, brick-walled Irish pub & eatery serving eclectic comfort grub such as steaks, fish & wraps.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7066,
        store_longitude: -74.0081,
        total_orders: 358.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Fun, Classic, Fine, Local, Unique',
        annual_dol: 1466.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5816,
        store_longitude: -73.8303,
        total_orders: 134.44,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords: 'Pizza, French, Friendly, American, Local, Great, Casual',
        annual_dol: 153.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5971,
        store_longitude: -73.6591,
        total_orders: 794.91,
        neighborhood: '',
        keywords: 'Lobster, Salad, Delicious, Yummy, Modern, Casual, Sweet, Fun, Cool, American',
        annual_dol: 1662.1,
        description:
            'New England seafood staples & raw-bar options at a waterside haunt with a deck & fire-pit tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6255,
        store_longitude: -73.7362,
        total_orders: 30.56,
        neighborhood: '',
        keywords:
            'Vodka, Shrimp Cocktail, Dessert, Coffee, French, Pinacolada, American, Sweet, Ample, Casual',
        annual_dol: 32.52,
        description:
            'Spacious, old-school diner offering American comfort food favorites all day, plus desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6566,
        store_longitude: -73.6484,
        total_orders: 1057.82,
        neighborhood: '',
        keywords:
            'Tacos, Steak, Burger, Shrimp Cocktail, Cinnamon Roll, Wine, Vodka, Gourmet, Beer, Margarita',
        annual_dol: 1080.26,
        description:
            '"The Ainsworth is an intimate venue that features eclectic rustic decor, bringing a sophisticated twist to the classic restaurant experience. The creative, high-quality menu highlights its 24 karat gold wings and signature steaks along with an extensive craft cocktail and beer menu."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6636,
        store_longitude: -73.645,
        total_orders: 185.33,
        neighborhood: '',
        keywords: 'Salad, Italian, Chinese, Beer, Bbq, Hotel, Local, Hummus',
        annual_dol: 525.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8598,
        store_longitude: -73.6213,
        total_orders: 249.58,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Salad, Italian, Shrimp Cocktail, Welcoming, Wonderful, Great, Casual, Fine',
        annual_dol: 284.68,
        description:
            'Casual, welcoming Italian eatery, offering classics like mussels, gnocchi & housemade limoncello.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.996,
        total_orders: 1669.71,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Vegan, Burger, Coffee, Italian, Martini, Upscale, Contemporary, American, Seasonal',
        annual_dol: 1742.1,
        description:
            'Offshoot of a Russian-based chain for coffee drinks & upscale American & international fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6076,
        store_longitude: -73.943,
        total_orders: 1539.02,
        neighborhood: '',
        keywords:
            'Salad, Shrimp Cocktail, Tasty, Italian, Chinese, Shrimp Scampi, Upscale, Elegant, Warm, Filet Mignon',
        annual_dol: 1571.68,
        description:
            'Upscale Italian eatery from 1964 serving traditional fare in a warm, elegant space with a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6172,
        store_longitude: -73.7302,
        total_orders: 78.91,
        neighborhood: '',
        keywords: 'Steak, Salad, Sushi, French, Upscale, Bbq, Elegant, Prime Rib, Asian, Casual',
        annual_dol: 202.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7688,
        store_longitude: -73.7043,
        total_orders: 7.26,
        neighborhood: '',
        keywords: 'Golfclub, Friendly, Perfect, Clean, Great',
        annual_dol: 9.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7439,
        store_longitude: -73.9227,
        total_orders: 17896.04,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Wine, Whiskey, Beer, Steak, Ale, Margarita, Rustic, Cozy, Spacious, Irish',
        annual_dol: 18516.29,
        description:
            'Intimate neighborhood spot with a warm, rustic feel offering inventive cocktails & craft tap beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6877,
        store_longitude: -73.8207,
        total_orders: 86.92,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Flavorful, Beer, Delicious, Cozy, Eclectic, Adventurous, Exotic, Caribbean, Intimate, Amazing',
        annual_dol: 94.59,
        description:
            'Cozy, energetic club featuring DJs spinning West Indian & Caribbean music, a full bar & VIP areas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7509,
        store_longitude: -73.9841,
        total_orders: 583.78,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Vegan, Tasty, Vegetarian, Beer, German, Wine, Delicious, Spicy, Spacious, Fun',
        annual_dol: 609.09,
        description:
            'Hearty German eats & fine beers are served in a casual, Bavarian publike setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8323,
        store_longitude: -73.699,
        total_orders: 241.64,
        neighborhood: '',
        keywords:
            'French, Italian, Delicious, Comfortable, Homey, Friendly, Contemporary, Casual, Authentic, American',
        annual_dol: 269.05,
        description:
            'Wines from the cellar wash down traditional French plates at this bustling, homey bistro.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9924,
        total_orders: 2373.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Champagne, Rooftop, Hotel, Spacious, Beer, Mediterranean, Moroccan, Fun, American, Great',
        annual_dol: 8974.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.753,
        store_longitude: -73.989,
        total_orders: 120.4,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Tacos, Vegetarian, Mexican, Salad, Burritos, Casual, Healthy, Delicious, Friendly, Local',
        annual_dol: 512.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7781,
        store_longitude: -73.6375,
        total_orders: 287.81,
        neighborhood: '',
        keywords: 'Elegant, Clean, Exquisite, Innovative, Exceptional, Fine, Creative, Local',
        annual_dol: 300.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5815,
        store_longitude: -74.1114,
        total_orders: 195.58,
        neighborhood: 'Todt Hill/Midland Beach',
        keywords: 'Italian, Beer, Rich, Swiss, Mediterranean, Japanese, Sweet, American, Casual',
        annual_dol: 203.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6507,
        store_longitude: -73.8693,
        total_orders: 2782.79,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Steak, Burger, Hamburger, Pizza, Italian, Beer, Wine, Dessert, Chicken Wings, American',
        annual_dol: 3190.45,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6548,
        store_longitude: -73.6458,
        total_orders: 946.49,
        neighborhood: '',
        keywords:
            'Italian, Burger, Pizza, Salad, Gluten Free, Upscale, Comfortable, Casual, American, Clean',
        annual_dol: 978.47,
        description:
            '"Lenox & Park is a neighborhood Italian bistro with a casual, yet sophisticated dining experience. Full service catering for all your party needs."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7508,
        store_longitude: -73.9858,
        total_orders: 16054.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Karaoke, Tasty, Upscale, Delicious, Savory, Inviting, Fun, Exquisite, Vibrant, Premium',
        annual_dol: 22172.21,
        description:
            'Vibrant destination for cocktails, dancing & DJs, plus bar bites & private karaoke rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6899,
        store_longitude: -73.8254,
        total_orders: 282.26,
        neighborhood: 'South Richmond Hill',
        keywords: 'Casual, Spicy, Classic',
        annual_dol: 498.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8302,
        store_longitude: -73.7023,
        total_orders: 1169.74,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Salad, Mexican, Italian, Delicious, Organic, Asian, American, Spectacular',
        annual_dol: 1272.98,
        description:
            'Iconic seafood spot dating to 1905 with deck seating & bay view, plus weekly bands & mahogany bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.751,
        store_longitude: -73.9851,
        total_orders: 858.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Bourbon, Wine, Legendary, Delicious, American, Exceptional, Great, Fine, Casual',
        annual_dol: 881.24,
        description:
            "Dry-aged steak, seafood & pasta surrounded by dark mahogany in John Barrymore's former townhouse.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.657,
        store_longitude: -73.6422,
        total_orders: 781.53,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Pizza, Salad, Dairy Free, Seafood Paella, Italian, Meatball, Gluten Free',
        annual_dol: 815.42,
        description:
            'Italian-American steakhouse with pasta, wine & cocktails in a snug, rustic-chic space with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6652,
        store_longitude: -73.7213,
        total_orders: 4298.68,
        neighborhood: '',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Pizza, Salad, Cajun, Italian, Juicy, Caesar Salad, American',
        annual_dol: 4396.84,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6556,
        store_longitude: -73.6458,
        total_orders: 458.36,
        neighborhood: '',
        keywords: 'Steak, Pizza, Tasty, Italian, Wine, Champagne, Coffee, Dessert, Beer, Delicious',
        annual_dol: 864.57,
        description:
            '"Mangia Bene is an modern Italian restaurant and bar located in Rockville Centre, NY. Specializing in fresh house made pasta and artisan wood fired pizza, Mangia Bene\'s sleek and sexy feel blends a modern take on old world Italian favorites."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6035,
        store_longitude: -73.6509,
        total_orders: 808.79,
        neighborhood: '',
        keywords:
            'Italian, Wine, Classy, Greek, Healthy, Friendly, Traditional, Casual, Sangria, Sophisticated',
        annual_dol: 887.46,
        description:
            '"We are a casually sophisticated Italian restaurant serving traditional and inventive Italian Cuisine."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.706,
        store_longitude: -74.0091,
        total_orders: 5401.46,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Salad, Pizza, Italian, Whiskey, Chilean, Luxurious, Mediterranean, Romantic, Warm, Premium',
        annual_dol: 5635.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.626,
        store_longitude: -73.9275,
        total_orders: 603.28,
        neighborhood: 'Flatlands',
        keywords:
            'Salad, Bbq, Caribbean, Jamaican, French, American, Indian, Authentic, Casual, Great',
        annual_dol: 799.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8131,
        store_longitude: -73.8227,
        total_orders: 43.95,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Local, Creamy',
        annual_dol: 59.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7573,
        store_longitude: -73.8703,
        total_orders: 168.69,
        neighborhood: 'North Corona/East Elmhurst/Flushing',
        keywords:
            'Steak, Salad, Vegetarian, Chicken Caesar Salad, Shrimp Cocktail, Wine, Tasty, Dessert, Beer, Mediterranean',
        annual_dol: 689.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7483,
        store_longitude: -73.9846,
        total_orders: 2400.42,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Beer, Flavorful, Ale, American, Spicy, Tex-Mex, Traditional, Seasonal, Fresh, Local',
        annual_dol: 2789.98,
        description:
            'Branch of local brewery chain has 2 large bars & serves rotisserie chicken & other American food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -73.6028,
        total_orders: 9199.49,
        neighborhood: '',
        keywords:
            'Luxurious, Upscale, Perfect, Modern, Picturesque, Great, Remarkable, Creative, Unforgettable, Heritage',
        annual_dol: 9929.33,
        description:
            '"Renowned Long Island caterers Chateau Briand invites you to experience a truly unique setting for a wide array of events including weddings, birthday parties, family reunions and corporate retreats. Experience the remarkable lobby that boasts twin fireplaces, a baby grand piano, and a picturesque 42-foot staircase."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6556,
        store_longitude: -73.6483,
        total_orders: 340.26,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Vegan, Tequila, Milkshake, Butter Chicken, Dessert, Chinese, American',
        annual_dol: 349.98,
        description:
            'Creative burgers, over-the-top shakes & craft beers are dished out in a funky warehouse setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6036,
        store_longitude: -73.9156,
        total_orders: 3264,
        neighborhood: 'Mill Basin',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Lobster Bisque, Steak, Salad, Pizza, Bbq, Wine, Clam Chowder',
        annual_dol: 3686.96,
        description:
            'Casual seafood restaurant serving fish, lobster & steak with dinner specials & raw bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.9851,
        total_orders: 1072.02,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Burger, Mexican, Sushi, Vodka, Rustic, American, Delicious, Modern, Polished',
        annual_dol: 1094.77,
        description:
            'Street-level American bar & grill in the Empire State Building with art deco design touches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6723,
        store_longitude: -73.7508,
        total_orders: 74.3,
        neighborhood: 'Laurelton/Jamaica/Springfield Gardens',
        keywords: 'Southern, Bbq, Dutch, American',
        annual_dol: 303.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8537,
        store_longitude: -73.6192,
        total_orders: 224.81,
        neighborhood: '',
        keywords:
            'Tasty, Gluten Free, Upscale, Vegetarian, Delicious, Mexican, Friendly, French, Brazilian, Fresh',
        annual_dol: 234.55,
        description:
            '"Upscale New Brazilian Cuisine, French influenced.Charcoal Fire Grill.Offer vegetarian and gluten free options"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7305,
        store_longitude: -74.0006,
        total_orders: 14553.76,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Classic, Authentic, Casual, Hip, Great',
        annual_dol: 14875.95,
        description:
            'A variety of blues, hip-hop, folk and comedy acts take the stage at this classic Village venue.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.8836,
        total_orders: 103.78,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Beer, Colombian, Delicious, Fun, Premium, Ramen, Great, Local, Casual',
        annual_dol: 443.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6982,
        store_longitude: -73.975,
        total_orders: 966.64,
        neighborhood: 'Brooklyn Navy Yard',
        keywords: 'Rooftop, Wine, Iconic, Diverse',
        annual_dol: 3951.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7183,
        store_longitude: -73.9904,
        total_orders: 10091.05,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Japanese, Local, Casual, Sweet, Chill, Great',
        annual_dol: 10878.99,
        description:
            '"Neighborhood bar in the Lower East Side serving beer, wine, spirits, mixed drinks, and cocktails. Free shuffleboard!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.9928,
        total_orders: 13924.59,
        neighborhood: 'Bowery',
        keywords: 'Wine, Thai, Delicious, Bourbon, Gin, Cozy, Meatball, Eclectic, Classy, Fun',
        annual_dol: 16339.38,
        description:
            'Colorful nook offering Thai comfort fare in lunchbox-style to-go boxes plus a small grocery section.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9066,
        store_longitude: -73.8486,
        total_orders: 334.16,
        neighborhood: 'East Bronx/Wakefield',
        keywords: 'Elegant, Perfect, Great',
        annual_dol: 1365.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7669,
        store_longitude: -73.9862,
        total_orders: 213.49,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Pizza, Salad, Wine, Shrimp Cocktail, Italian, Upscale, Beer, Delicious, Mimosa',
        annual_dol: 261.94,
        description:
            'Airy, spacious seafood joint & wine bar specializing in small plates, oysters, crudo & charcuterie.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.822,
        store_longitude: -73.9502,
        total_orders: 834.59,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Tacos, Tequila, Mexican, Burger, Margarita, Vegetarian, Salad, Wine, Spicy, Trendy',
        annual_dol: 1572.24,
        description:
            'Innovative Mexican cuisine & cocktails are served in an earth-toned space with splashes of art.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7406,
        store_longitude: -73.9878,
        total_orders: 15.83,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Mexican, Vegetarian, Burritos, Wine, Gluten Free, French, Local, Great',
        annual_dol: 64.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6333,
        store_longitude: -74.0268,
        total_orders: 170.64,
        neighborhood: 'Bay Ridge',
        keywords: 'Burger, Steak, Pizza, Salad, Mexican, Flavorful, Tasty, Vodka, Beer, Wine',
        annual_dol: 697.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6393,
        store_longitude: -73.5828,
        total_orders: 1502.46,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Wine, Beer, Hurricane, Polished, American, Eclectic, Fun',
        annual_dol: 1751.31,
        description:
            'Nautical Mile mainstay for contemporary seafood with scenic bay views & weekend live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7662,
        store_longitude: -73.9867,
        total_orders: 28.14,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Italian, Wine, Dessert, Beer, Organic, Bruschetta, Filet Mignon, Modern, Caesar Salad',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7708,
        store_longitude: -73.9029,
        total_orders: 10.82,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Burger, Salad, Italian, Coffee, Dessert, Nachos, Spicy, Delicious, Spanish, American',
        annual_dol: 44.22,
        description:
            'Airy, brick-walled bistro & bar offering a diverse menu of American & global fare, plus breakfast.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7425,
        store_longitude: -73.997,
        total_orders: 1402.93,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Vegetarian, Italian, Dessert, Martini, Delicious, Mediterranean, Seasonal, Casual, Traditional',
        annual_dol: 1474.4,
        description:
            'Neighborhood trattoria with a patio & vast Italian wine list serving Venetian pasta & seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6808,
        store_longitude: -73.9756,
        total_orders: 4549.3,
        neighborhood: 'Park Slope',
        keywords:
            'Japanese, Wine, Delicious, Romantic, Legendary, Hotel, Beer, Comfortable, Contemporary, Fantastic',
        annual_dol: 5873.39,
        description:
            'Wood-accented contemporary izakaya offering cocktails & food with a Japanese spin.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5597,
        store_longitude: -74.197,
        total_orders: 128.42,
        neighborhood: 'Arden Heights',
        keywords: 'Pizza, Whiskey, Fun, Unique, Local, Casual',
        annual_dol: 142.88,
        description:
            'Beer, cocktails & bar snacks are served in a low-key setting with TVs, pool tables & dartboards.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7479,
        store_longitude: -74.0042,
        total_orders: 602.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Beer, Cozy, Gourmet, Hotel, Roast Beef, Mediterranean, Eclectic, Fun, Exotic',
        annual_dol: 868.38,
        description:
            'Unpretentious wine bar offering European vintages & small plates in a wood-&-brick–lined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9977,
        total_orders: 475.61,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Hamburger, Salad, Beer, Nachos, Margarita, Wine, American, Vintage',
        annual_dol: 485.7,
        description:
            'Lounge with a roadhouse vibe featuring vintage Americana & classic eats (burgers, tater tots, etc.).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7404,
        store_longitude: -73.9959,
        total_orders: 297.74,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Burger, Cozy, Rooftop, Creative, Quirky, Lit, Unique',
        annual_dol: 1217.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.687,
        store_longitude: -73.9777,
        total_orders: 24.17,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Beer, Tasty, Cozy, Classy, Classic, Traditional, Mediterranean, Intimate, Iconic',
        annual_dol: 98.8,
        description:
            'Down-to-earth, cozy gathering place serving small plates, draft beer & imported wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -73.9786,
        total_orders: 935.02,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Irish, Eclectic, Local, Casual, Great, Unique',
        annual_dol: 954.86,
        description:
            'Relaxed, brick-walled bar with a pool table, Skee-Ball & a juke box, plus frequent live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5889,
        store_longitude: -73.6458,
        total_orders: 2385.64,
        neighborhood: '',
        keywords:
            'Burger, Salad, Mexican, Italian, Asian, Quesadillas, Homemade, Clean, Fun, American',
        annual_dol: 2596.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7172,
        store_longitude: -73.9429,
        total_orders: 361.21,
        neighborhood: 'Williamsburg',
        keywords: 'Cozy, Burger, Steak, Wine, Salad, Upscale, Delicious, Trendy, Beer, Classy',
        annual_dol: 601.96,
        description:
            'Informal plates, beer & spirits in an easygoing tavern with indoor & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7116,
        store_longitude: -73.9461,
        total_orders: 1953.61,
        neighborhood: 'Williamsburg',
        keywords: 'Gluten Free, Tasty, Coffee, Spacious, Irish, Asian, Casual',
        annual_dol: 2903.17,
        description:
            'Neighborhood bar pouring beers on tap & a full line-up of booze, with a pool table & a back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6653,
        total_orders: 439.78,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Sushi, Italian, Mexican, Japanese, Crab Rangoon, Martini, Asian, Funky',
        annual_dol: 710.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7177,
        store_longitude: -73.9563,
        total_orders: 268.92,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Ale, Wine, Mead, Laid-Back, Refreshing, Spectacular, Great, Chill, Casual',
        annual_dol: 1099.24,
        description: '"Microbrewery, bar and restaurant."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7163,
        store_longitude: -73.961,
        total_orders: 1167.32,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, German, Salad, Tasty, Hungarian, Spicy, Casual, Classic, Chill, Great',
        annual_dol: 1303.74,
        description:
            'Sprawling, boisterous beer hall offering brews, schnitzel & regularly scheduled live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 3118.88,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Beer, Wine, Ramen, Gin, Spicy, American, Asian, Japanese, Sleek, Cointreau',
        annual_dol: 3301.28,
        description:
            'Busy, casual walk-in Asian outlet in a shopping mall serving noodles & ramen from the open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7201,
        store_longitude: -73.9878,
        total_orders: 2675.82,
        neighborhood: 'Lower East Side',
        keywords: 'Tacos, Wine, Beer, Martini, Trendy, Spicy, Romantic, Thai, Asian, French',
        annual_dol: 2889.02,
        description:
            'Swanky cocktail lounge known for its Asian tapas, revolving art shows & lychee martinis.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7702,
        store_longitude: -73.9765,
        total_orders: 11.26,
        neighborhood: 'Central Park West Historic District',
        keywords: 'Burger, Beer, Casual, Friendly, Great, Sangria',
        annual_dol: 46.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5452,
        store_longitude: -74.1659,
        total_orders: 314.24,
        neighborhood: 'Arden Heights',
        keywords: 'Steak, Italian, Pizza, Coffee, Upscale, Delicious, Excellent, Casual, Local',
        annual_dol: 384.37,
        description:
            'Brick-oven pizzas, Italian dishes & cocktails served in down-to-earth surrounds with a heated patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7299,
        store_longitude: -73.9809,
        total_orders: 2156.35,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Meatball, Wine, Spicy, Ale, American, Eclectic, Vintage, Casual',
        annual_dol: 2202.11,
        description:
            'Convivial hangout with vintage decor offering eclectic bar fare, cocktails & beers on tap.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7114,
        store_longitude: -73.9446,
        total_orders: 406.96,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Italian, Pizza, Wine, Trendy, Ale, Premium, Relaxed, Contemporary, Perfect, Great',
        annual_dol: 473.71,
        description:
            'Relaxed storefront serving lesser known Italian pastas & small plates plus wine & backyard seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7253,
        store_longitude: -73.9833,
        total_orders: 2088.94,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Wine, Friendly, Casual, Great, Diverse',
        annual_dol: 2133.26,
        description:
            'Buzzy, celeb-owned bar with drag & cabaret shows, plus other diverse entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 307.62,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Steak, Burger, Salad, Beer, Dessert, Chicken Wings, Ale, Tex-Mex, American, Casual',
        annual_dol: 498.74,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -73.9604,
        total_orders: 742.62,
        neighborhood: 'Williamsburg',
        keywords: 'Salad, Steak, Korean, Wine, Beer, Bbq, Organic, Japanese, Tangy, Cozy',
        annual_dol: 889.19,
        description:
            'Busy Korean-style BBQ eatery with tableside grills & veggie options in a cozy ambiance.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9566,
        total_orders: 510.31,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Salad, Breakfast Burrito, Authentic, Fresh',
        annual_dol: 2085.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9839,
        total_orders: 0,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Mexican, Lobster Bisque, American, Contemporary, Delicious, Casual, Clam Chowder, Local, Traditional',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6623,
        store_longitude: -73.9621,
        total_orders: 921.13,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords: 'Zombie, Wine, Bbq, Juicy, Cozy, American, Warm, Crisp, Spacious, Greek',
        annual_dol: 1737.45,
        description:
            'Creative bar eats & craft cocktails served in chill digs with a pool table, fireplace & patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.723,
        store_longitude: -73.9975,
        total_orders: 2636.58,
        neighborhood: 'Lower Manhattan',
        keywords: 'Champagne, Vodka, Hotel, Zombie, Beer, Gin, Rum, Pizza, Eclectic, Moscow Mule',
        annual_dol: 3014.27,
        description:
            "and 2 miles from the Empire State Building.Sophisticated rooms with high ceilings and floor-to-ceiling windows feature smart TVs, Blu-ray players, Bose systems and free Wi-Fi, plus minibars. Suites add sitting areas; upgraded suites with 1 or 2 bedrooms have separate living rooms with dining areas, and terraces. Room service is available 24/7.There's a plush restaurant with a bar and a seasonal terrace, a posh lounge and a gym, as well as a rooftop garden.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7581,
        store_longitude: -73.7874,
        total_orders: 822.92,
        neighborhood: 'Auburndale/Flushing',
        keywords: 'Burger, Sushi, Salad, Pizza, Gourmet, Korean, Wine, Spicy, Delicious, Trendy',
        annual_dol: 3680.08,
        description:
            '"THE BANDI = FIREFLY KOREAN STYLE BAR Voted New York’s Best Korean Style Bar & Foods It has always been a dream of Sara’s to open a small place where New Yorkers could relax to an evening of delicious Korean Food and fun drinks. After 20 years of working in the corporate world, she decided to pursue her passion for cooking. As such, Sara opened her first bar and restaurant in Queens to provide people with refreshing food and beverages. Sara believes in using fresh ingredients in a simple way so they bring out their own essence. Inspired by her love for foods from all over the world, she has added a Korean flare to a simple and healthy menu. With unique and appealing Soju cocktails, this establishment has a pleasant experience waiting..."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8147,
        store_longitude: -73.9594,
        total_orders: 1288.49,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Salad, Hamburger, Tasty, Wine, Bread Pudding, French Toast, Beer, French, Bbq',
        annual_dol: 1315.83,
        description:
            'American comfort fare like burgers & sandwiches in a roomy, retro diner space plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8181,
        store_longitude: -73.9608,
        total_orders: 1569.99,
        neighborhood: 'Upper Manhattan',
        keywords: 'Burger, Beer, Bbq, Tasty, American, Healthy, Fun, Friendly, Casual, Perfect',
        annual_dol: 3817.64,
        description:
            'Budget pub grub & brews are served at this bustling bar with TVs for watching sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7646,
        store_longitude: -73.992,
        total_orders: 1073.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Fun, Fancy, Mediterranean, Awesome, Israeli, Great, American, Asian',
        annual_dol: 1142.64,
        description:
            'Locavore spot from Israeli celebrity chef with a reservations-only experience & a theatrical vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9814,
        total_orders: 9529.77,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Beer, Spicy, Karaoke, New-York-Style, Wine, Warm, Irish, Gin, Rum',
        annual_dol: 9762.72,
        description:
            'Watering hole with a Prohibition theme serving craft cocktails, beer, wine & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7645,
        store_longitude: -73.9773,
        total_orders: 1643.34,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Wine, Vodka, Salad, Italian, American, Hotel, Mediterranean, Homey, Premium',
        annual_dol: 5769.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.976,
        total_orders: 669.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Pizza, Steak, Italian, Dessert, Korean, Californian, Organic, Upscale, Spicy',
        annual_dol: 683.34,
        description:
            'Stylish, art-adorned Californian eatery frequented by high-profile media personalities.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7223,
        store_longitude: -73.992,
        total_orders: 7065.8,
        neighborhood: 'Bowery',
        keywords: 'Whiskey, Beer, Wine, German, Trendy, Upscale, American, Warm, Fun, Classic',
        annual_dol: 7305.83,
        description:
            'Bar specializing in whiskey, served in a space lined with subway tiles and antique mirrors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9874,
        total_orders: 393.32,
        neighborhood: 'Lower East Side',
        keywords:
            'Tasty, Nutritious, Mexican, Delicious, Margarita, Savory, Salad, Nachos, South American, Eclectic',
        annual_dol: 491.05,
        description:
            'Local stalwart for standard Mexican fare & margaritas in a no-frills setting that keeps late hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.99,
        total_orders: 2.42,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Beer, Wine, Karaoke, Margarita, Fun, Friendly, Great, Cool, Iconic, Edgy',
        annual_dol: 9.88,
        description:
            'Storied punk rock bar & music club with an edgy backdrop of graffiti-covered walls & booths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6248,
        store_longitude: -74.0303,
        total_orders: 5.09,
        neighborhood: 'Bay Ridge',
        keywords:
            'Lasagna, Pizza, Steak, Italian, Vodka, Martini, Delicious, Homemade, Fabulous, Fresh',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7385,
        store_longitude: -74,
        total_orders: 370.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Salad, Vietnamese, Exquisite, Asian, Exotic, Fresh, Great, Excellent, Casual',
        annual_dol: 434.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 26.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Mexican, Italian, Gin, Premium, Stylish, Global, Great, Chill',
        annual_dol: 108.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7408,
        store_longitude: -73.7844,
        total_orders: 5389.15,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords: 'Steak, Burger, Tacos, Beer, Salad, Pizza, Fresh, Creamy, Dessert, Tangy',
        annual_dol: 5503.5,
        description:
            'Sports bar & grill chain famous for chicken wings served by waitresses in short shorts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7175,
        store_longitude: -73.9501,
        total_orders: 4687.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Pizza, Salad, Italian, Chinese, Phenomenal, French, American, Sweet, Local',
        annual_dol: 4901.39,
        description:
            'Relaxed, light-filled eatery with a horseshoe bar serving American plates & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.631,
        store_longitude: -74.1035,
        total_orders: 603.23,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Steak, Vegetarian, Chocolate Mousse, Tasty, Gourmet, Southern Comfort, Bourbon, Beer, Coffee, Bbq',
        annual_dol: 1156.99,
        description:
            '"Sally\'s Southern is Staten Island\'s only southern comfort and BBQ restaurant Proudly serving craft cocktails, an extensive beer and bourbon list and gourmet southern fare. Our namesake is homage to the late Sally Curtis. In her words and her spirit #Live Love Laugh and # Eat and Enjoy!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.718,
        store_longitude: -73.9525,
        total_orders: 2466.72,
        neighborhood: 'Williamsburg',
        keywords: 'Vegan, Beer, Wine, Modern, Hotel, Funky, American, Delicious, Perfect, Fresh',
        annual_dol: 2519.06,
        description:
            '"Thief, the first solo venture from John McNulty (Swine, Cocktail Kingdom Hospitality Group), has a mission to steal back the time spent away from your favorite bar. A modern take on the neighborhood bar, Thief is inspired in design and energy by the 1980\'s NYC graffiti, art and music scene. The menus feature riffs on classic cocktails including frozen drinks such as a Friesling, an extensive wine program and elevated bar food from Smoked Ham & Gruyere sandwiches to Vegan Mini Corn Dogs."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.9767,
        total_orders: 418.48,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Lobster, Hamburger, Salad, Beer, Gourmet, Wine, French Toast, American, French, Contemporary',
        annual_dol: 1710.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -73.9607,
        total_orders: 300.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Korean, Spicy, Delicious, Urban, Organic, Savory, American, Nachos, Rooftop, Sweet',
        annual_dol: 567.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.978,
        total_orders: 153.04,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Korean, Beer, Spicy, Homemade, Delicious, Bbq, Wine, Cozy, Friendly, Fun',
        annual_dol: 537.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9777,
        total_orders: 5.36,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Wine, Salad, Dessert, Delicious, Nutritious, Beer, Mediterranean, Healthy, Turkish, Modern',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.752,
        store_longitude: -73.9912,
        total_orders: 5546.56,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Stylish, Chic, Creative, Excellent, Contemporary',
        annual_dol: 5666.75,
        description:
            'Theater District.Warm, contemporary rooms feature free Wi-Fi and flat-screen TVs, marble bathrooms and minifridges. Many have city and/or skyline views, and some have balconies or terraces. Suites add living rooms. Room service is available.An airy restaurant serves international cuisine and has a dining terrace. There are several stylish lounges and bars, as well as a fitness center, and an alcove lined with digital screens with constantly shifting scenery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 1588.22,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Wine, Rich, Velvety, Hotel, Romantic, Spicy, Rum, Inviting, Premium, Charming',
        annual_dol: 1657.08,
        description:
            'Cozy, vintage-style lounge with velvet seating for elevated craft cocktails & sophisticated snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7004,
        store_longitude: -73.8102,
        total_orders: 69.98,
        neighborhood: 'Jamaica',
        keywords: 'Fun, Impeccable, Cool',
        annual_dol: 148.37,
        description:
            'Spacious, high-energy nightspot featuring dancing, DJs & live performances plus cocktails & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.9794,
        total_orders: 464.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Sushi, Italian, Delicious, Red Eye, French, Romantic, American, Innovative, Robust',
        annual_dol: 494.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7426,
        store_longitude: -74.0002,
        total_orders: 1159.72,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Tomato Soup, Mexican, French, Coffee, Beer, Margarita, Cozy, American',
        annual_dol: 1658.89,
        description:
            'Two-level restaurant serving casual French fare paired with craft cocktails, beer & Champagne.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7426,
        store_longitude: -74.0006,
        total_orders: 2229.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Tacos, Tasty, Wine, Salad, Spicy, Chinese, Innovative, American, Asian',
        annual_dol: 2303.73,
        description:
            'Hip, airy hangout with exposed-brick walls pairing craft beer with innovative bar fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7411,
        store_longitude: -74.0013,
        total_orders: 594.12,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Pizza, Flavorful, Italian, Delicious, Rich, Warm, Cozy, Romantic, Rustic',
        annual_dol: 625.42,
        description:
            'Old-school white-tablecloth Italian eatery serving homemade pastas from a wood-burning oven.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7397,
        store_longitude: -73.9962,
        total_orders: 63.16,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Tasty, Delicious, Chilean, Salad, Premium, Robust, Impressive, Perfect, Great',
        annual_dol: 114.64,
        description:
            'White-tablecloth eatery with classic Mediterranean sharing plates & mains, plus a robust wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7073,
        store_longitude: -74.0063,
        total_orders: 3112.32,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Italian, Warm, Modern, Classic, Friendly, American, Seasonal, Rooftop, Casual',
        annual_dol: 3461.81,
        description:
            "the Brooklyn Bridge.The simple, modern rooms have free Wi-Fi and bottled water, plus 42-inch flat-screen TVs, and desks with ergonomic chairs. Suites add pull-out sofas and separate living areas.There's a casual American restaurant and a rooftop bar with a seasonal terrace, as well as a 24/7 fitness room. Additional amenities include 7 meeting rooms and a business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6715,
        store_longitude: -73.9627,
        total_orders: 3611.02,
        neighborhood: '',
        keywords: 'Friendly, Great, Fresh, Excellent, Casual, Cultural, Seasonal',
        annual_dol: 14760.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7136,
        store_longitude: -73.9413,
        total_orders: 40.15,
        neighborhood: 'East Williamsburg/Williamsburg',
        keywords:
            'Vegan, Champagne, Coffee, Martini, Organic, Delicious, Seasonal, Latin, Friendly, Sweet',
        annual_dol: 72.97,
        description:
            'Quaint, family-run cafe preparing seasonal Guatemalan-inspired breakfast, lunch & dinner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.714,
        store_longitude: -73.9562,
        total_orders: 2422.67,
        neighborhood: 'Williamsburg',
        keywords:
            'Cornbread, Whiskey, Salad, Beer, Gluten Free, Bourbon, Spicy, Bbq, German, Sweet',
        annual_dol: 2434.07,
        description:
            'Popular dry-rub BBQ, beer & bourbon purveyor with an industrial, cafeteria-style decor & wall mural.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 331.95,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Lobster, Lobster Roll, Shrimp Cocktail, Salad, Gluten Free, Spicy, Martini, Avocado Toast, Organic',
        annual_dol: 1356.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7108,
        store_longitude: -73.9509,
        total_orders: 71.21,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Tacos, Mexican, Steak, Nachos, Salad, Beer, Margarita, Spicy, Friendly, Fresh',
        annual_dol: 295.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.9806,
        total_orders: 24220.94,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Wine, Japanese, Spicy, Delicious, Upscale, Trendy, Sashimi, Cozy, Spacious',
        annual_dol: 29477.85,
        description:
            'Trendy, high-end Japanese place with an izakaya-inspired menu including sushi & robata grilled fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.9256,
        total_orders: 17160.19,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Lobster, Cajun, Whiskey, Bbq, Wine, Organic, American, French',
        annual_dol: 20010.99,
        description:
            'This eatery offers twists on New American comfort fare in a contemporary updated-diner setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7469,
        store_longitude: -73.9804,
        total_orders: 1342.82,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Salad, Pizza, Italian, Wine, Delicious, Gourmet, Upscale, Warm, Ale, Mediterranean',
        annual_dol: 1385.43,
        description:
            'Decades-old Italian restaurant offering an array of traditional fare in an upscale setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7511,
        store_longitude: -73.9803,
        total_orders: 808.8,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Salad, Swiss, Irish, French, Fried Shrimp, Greek, Hotel, Greek Salad, Casual',
        annual_dol: 861.95,
        description:
            'Laid-back pub with busy weekday happy hours serves traditional Irish bar food late into the night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9241,
        total_orders: 570.1,
        neighborhood: 'Astoria/LIC',
        keywords: 'Pizza, Italian, Salad, Wine, Vodka, Delicious, Swiss, Bbq, Homemade, Yummy',
        annual_dol: 594.81,
        description:
            'Family-run local mainstay serving up crispy coal-oven pizzas plus pastas in a simple setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.987,
        total_orders: 150.05,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Luxurious, Delicious, Savory, Spacious, Impeccable, Hotel, American, Bright',
        annual_dol: 166.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.9791,
        total_orders: 16100.17,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Hamburger, French, Italian, French Onion Soup, Delicious, American, Contemporary, Whimsical, Classic',
        annual_dol: 21917.39,
        description:
            'Elevated brasserie serving contemporary takes on French classics in a whimsical multi-room space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7645,
        store_longitude: -73.9833,
        total_orders: 4415.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Scotch, Wine, Shrimp Scampi, Gluten Free, Upscale, Cosmopolitan, American',
        annual_dol: 5191.05,
        description:
            'Contemporary chophouse at Hilton Midtown with a classic menu including seafood & California wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9786,
        total_orders: 4271.26,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Tequila, Coffee, Hotel, Delicious, American, Elegant, Velvety, Spacious, Rustic',
        annual_dol: 4456.44,
        description:
            "Sleek rooms are warmly decorated with cherry and cedar wood furniture, and include minibars, flat-screen TVs and free Wi-Fi, plus work desks with ergonomic chairs; suites add full kitchens, living areas and views of Central Park.There are 2 acclaimed restaurants, plus a burger joint and a hip cocktail bar. There's also a gym, a rooftop pool, and a multilevel fitness center with racquetball and basketball courts (as well as personal trainers), plus a spa. Other amenities include a barbershop, a salon and an event ballroom.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9813,
        total_orders: 194.28,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Sushi, Lobster, Beer, Japanese, Tasty, Sashimi, Hotel, American, Sweet, Casual',
        annual_dol: 794.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.9901,
        total_orders: 593.35,
        neighborhood: 'Bowery',
        keywords:
            'Sushi, Lobster, Japanese, Impeccable, Amazing, Fantastic, Incredible, Great, Excellent, Prosecco',
        annual_dol: 605.94,
        description:
            'Intimate, moody eatery supplying set & a la carte menus of creative maki & nigiri, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7205,
        store_longitude: -73.9902,
        total_orders: 283.22,
        neighborhood: 'Bowery',
        keywords:
            'Burger, Steak, Dairy Free, Salad, Gluten Free, Meatball, Coffee, Beer, Breakfast Burrito, Wine',
        annual_dol: 639.04,
        description:
            'Hip gathering place serving seasonally-driven, global plates & cocktails, plus a happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7433,
        store_longitude: -73.9893,
        total_orders: 105.72,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Mexican, Margarita, Wine, Trendy, Spicy, Delicious, Vibrant, Sophisticated, Friendly, Authentic',
        annual_dol: 432.15,
        description:
            'Trendy counter-serve with a patio & a Mexican menu created by celebrity chef Rick Bayless.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7798,
        store_longitude: -73.8026,
        total_orders: 162.76,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Lobster, Steak, Vegetarian, Salad, Tasty, Wine, Coffee, Dessert, Apple Pie, Grilled Salmon',
        annual_dol: 173.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.9815,
        total_orders: 6095.9,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Wine, Godfather, American, Classic, Friendly, Authentic, Sweet, Awesome, Great',
        annual_dol: 6554.71,
        description:
            'Long-running, no-nonsense bar featuring a jukebox loaded with punk & an old-school photobooth.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7248,
        store_longitude: -73.9812,
        total_orders: 1148.58,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Steak, Burger, Salad, French, Wine, Upscale, Gin, Retro, Polished, Casual',
        annual_dol: 1150.05,
        description:
            'Parisian-style bistro dishes & wine served in a cozy, retro space enhanced by back garden seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7135,
        store_longitude: -73.9617,
        total_orders: 2639.33,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Tequila, Beer, Lobster, Lobster Roll, Margarita, Warm, Hot Toddy, Friendly, Awesome, Cool',
        annual_dol: 2695.33,
        description:
            'Jumping, brick-walled watering hole featuring booze, a dog-friendly policy, games & a backyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7183,
        store_longitude: -73.9521,
        total_orders: 80.26,
        neighborhood: 'Williamsburg',
        keywords:
            'Tacos, Tequila, Mexican, Margarita, Steak, Dessert, Mimosa, Upscale, Trendy, Delicious',
        annual_dol: 145.5,
        description:
            'Cool locale for tacos, ceviche & upscale Mexican dishes with micheladas & other cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6831,
        store_longitude: -73.9657,
        total_orders: 1155.09,
        neighborhood: 'Clinton Hill',
        keywords:
            'Beer, Whiskey, Irish, Bourbon, Mead, Premium, Awesome, Great, Casual, Unpretentious',
        annual_dol: 1257.04,
        description:
            'Industrial-chic bar specializing in craft beers, also offering cocktails, bar snacks & sandwiches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7131,
        store_longitude: -73.9494,
        total_orders: 123.83,
        neighborhood: 'Williamsburg',
        keywords: 'Sushi, Tacos, Salad, Wine, Spicy, Beer, Savory, Whiskey, Hawaiian, Japanese',
        annual_dol: 506.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.9776,
        total_orders: 473.16,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Hamburger, Salad, Beer, Tasty, Wine, Juicy, Upscale, French, Armenian',
        annual_dol: 852.48,
        description:
            'East Village gastropub serving American comfort food in shareable small plates, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7178,
        store_longitude: -73.9524,
        total_orders: 122.53,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Salad, French, Italian, Swiss, Modern, Savory, Delicious, Casual, Perfect',
        annual_dol: 158.01,
        description: '"Modern French restaurant in the heart of Williamsburg"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.9836,
        total_orders: 24271.51,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Sushi, Spacious, Korean, Bright, Great, Excellent, Modern, Intimate, Chic',
        annual_dol: 26570.86,
        description:
            'The minimalist, modern rooms, suites and studios provide free Wi-Fi, flat-screen TVs and coffeemakers, as well as minifridges and floor-to-ceiling windows. Unique features found in some quarters include original artwork, kitchens, balconies, a rooftop deck and/or a pull-out sofa.Breakfast at a neighboring cafe is complimentary. There are also two intimate sushi restaurants.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9766,
        total_orders: 19724.62,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Italian, Pizza, Shrimp Cocktail, Wine, Flavorful, Bourbon, Beer, Delicious, American',
        annual_dol: 20273.99,
        description:
            'Steakhouse with striking, contemporary design, plus an upmarket menu of chops & Italian fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.9829,
        total_orders: 454.72,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Pizza, Garlic Bread, Italian, Meatball, Salad, Tasty, Delicious, Homemade, Fresh, Local',
        annual_dol: 494.85,
        description:
            'Family-friendly pizzeria serving thin-crust pies alongside calzones, pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6632,
        store_longitude: -73.6989,
        total_orders: 26.47,
        neighborhood: '',
        keywords: 'French, Casual, Fresh, Hip',
        annual_dol: 108.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.985,
        total_orders: 11.39,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Modern',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.8811,
        total_orders: 72.19,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Burger, Sushi, Steak, Salad, Korean, Spanish, Asian, Healthy, Casual, Natural',
        annual_dol: 295.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6821,
        store_longitude: -73.9796,
        total_orders: 526.7,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Beer, Cozy, Relaxed, Friendly, Cool, Chic, Casual, Great, Hummus',
        annual_dol: 1229.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6819,
        store_longitude: -73.9763,
        total_orders: 625.67,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Lobster, Steak, Vegan, Beer, Wine, Juicy, Delicious, Caribbean, Warm',
        annual_dol: 1135.65,
        description:
            'Warm fixture for Caribbean cuisine including jerk chicken, guava wings & fried plantain, plus a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6807,
        store_longitude: -73.9747,
        total_orders: 474.56,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Vegetarian, Coffee, Organic, Zombie, Friendly, Modern, Casual, Ample, Cool, Sweet',
        annual_dol: 704.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7365,
        store_longitude: -73.8026,
        total_orders: 108.41,
        neighborhood: 'Fresh Meadows/Flushing/Pomonok',
        keywords:
            'Roast Turkey, Dessert, Fresh, American, Friendly, Contemporary, Traditional, Classic, Irish, Great',
        annual_dol: 407.42,
        description:
            'Alehouse in a Prohibition-era building providing contemporary American fare, including Irish nachos.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.974,
        total_orders: 592.64,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Sushi, Steak, Dessert, Spicy, Delicious, Stylish, Japanese, Thai, Gin, Chinese',
        annual_dol: 644.94,
        description:
            'Relaxed, stylish bistro serving modern takes on Asian street-food favorites, plus sake & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7809,
        store_longitude: -73.9474,
        total_orders: 88.88,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Coffee, Dessert, Hotel, Premium, Local',
        annual_dol: 363.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7225,
        store_longitude: -73.9899,
        total_orders: 11874.18,
        neighborhood: 'Bowery',
        keywords:
            'Japanese, Tasty, Contemporary, Premium, Intimate, Traditional, Casual, Attentive, Okonomiyaki, Great',
        annual_dol: 12669.37,
        description:
            'Kenta Goto (ex Pegu Club) offers cocktails & food with a Japanese spin at this contemporary izakaya.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.9877,
        total_orders: 2111.06,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Italian, Delicious, Authentic, Great, Creative, Fresh, Local, Complex, Unique',
        annual_dol: 2483.36,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7188,
        store_longitude: -73.9921,
        total_orders: 293.66,
        neighborhood: 'Bowery',
        keywords:
            'Vodka, Pizza, Champagne, Scotch, Shawarma, Mediterranean, Casual, Friendly, Modern, Cool',
        annual_dol: 717.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6928,
        store_longitude: -73.8561,
        total_orders: 33.62,
        neighborhood: 'Woodhaven',
        keywords:
            'Beer, Ecuadorian, Mexican, Coffee, Chinese, American, Latin, Awesome, Great, Casual',
        annual_dol: 86.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.639,
        store_longitude: -73.5816,
        total_orders: 21.04,
        neighborhood: '',
        keywords: 'Dominican, Memorable',
        annual_dol: 86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6874,
        store_longitude: -73.9864,
        total_orders: 1621.36,
        neighborhood: 'Boerum Hill',
        keywords: 'Burger, Tacos, Beer, Whiskey, Wine, Vegetarian, Rum, American, Cozy, Delicious',
        annual_dol: 1891.38,
        description:
            'A robust craft beer menu & typical dishes offered in a cozy venue with brick walls & tin ceilings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7407,
        store_longitude: -73.9929,
        total_orders: 563.91,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Delicious, Rich, Salad, Wine, Greek, Meatball, Outstanding, Classy, Authentic, Great',
        annual_dol: 575.87,
        description:
            'Established Greek restaurant noted for its high-end fare & civilized, atmospheric setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5423,
        store_longitude: -74.1772,
        total_orders: 541.59,
        neighborhood: 'Arden Heights',
        keywords:
            'Burger, Vegetarian, Gourmet, Gluten Free, Italian, Organic, Mediterranean, American, Homemade, Casual',
        annual_dol: 675.48,
        description:
            'Bustling local spot dishes up sizeable portions of classic diner fare & homemade Italian specialties',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.74,
        store_longitude: -73.9932,
        total_orders: 93.54,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Tequila, Upscale, Delicious, Contemporary, Casual, Intimate, Modern, Sweet',
        annual_dol: 241.03,
        description:
            'Classy, intimate venue featuring familiar Mexican dishes, plus an extensive drinks menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -73.9798,
        total_orders: 817.76,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Beer, Delicious, Warm, Comfortable, Friendly, Eclectic, Fancy, Asian, Great',
        annual_dol: 910.32,
        description:
            'Small, easygoing bar & community event space featuring seasonal cocktails & a backyard beer garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.695,
        total_orders: 51.93,
        neighborhood: '',
        keywords: 'Hamburger, Friendly',
        annual_dol: 74.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -73.9798,
        total_orders: 67.3,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Beer, Delicious, Warm, Comfortable, Friendly, Eclectic, Fancy, Traditional, Great',
        annual_dol: 275.08,
        description:
            'Small, easygoing bar & community event space featuring seasonal cocktails & a backyard beer garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 2520.91,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Rooftop, Shrimp Cocktail, Bbq, Wine, Salad, Global, Amazing, Sweet',
        annual_dol: 10304.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7291,
        store_longitude: -73.9809,
        total_orders: 40.88,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Salad, Spicy, Delicious, Japanese, Thai, Romantic, Asian, Hotel, Smoky, Sweet',
        annual_dol: 167.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.748,
        store_longitude: -73.9839,
        total_orders: 280.95,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Iconic, Spectacular, Seasonal, Casual',
        annual_dol: 393.6,
        description:
            'Club-like setting for virtually fighting zombies, slaying dragons & flying, plus a cocktail lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.808,
        store_longitude: -73.945,
        total_orders: 28501.8,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Cornbread, Salad, Wine, Dessert, Gluten Free, Beer, Stylish, Sweet, American, Southern',
        annual_dol: 29382.75,
        description:
            'Popular, stylish comfort food eatery with creative cooking from celeb chef Marcus Samuelsson.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7064,
        store_longitude: -73.8083,
        total_orders: 221.41,
        neighborhood: 'Briarwood/Jamaica',
        keywords:
            'Champagne, Margarita, Hawaiian, Rum, Caribbean, Quaint, Blue Hawaiian, Colorful, Casual',
        annual_dol: 695.96,
        description:
            'Laid-back, colorful club featuring DJs playing Caribbean dance music & island-inspired cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.9943,
        total_orders: 1719.22,
        neighborhood: 'Bowery',
        keywords:
            'Stylish, Glamorous, Hotel, Coffee, Eclectic, Fun, Playful, Perfect, Clean, Modern',
        annual_dol: 7027.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6232,
        store_longitude: -74.031,
        total_orders: 303.42,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Coffee, Beer, Italian, Cozy, Elegant, Hotel, Rustic, Comfortable, Creamy, Mediterranean',
        annual_dol: 431.05,
        description:
            'Neighborhood fixture producing whole fish & other Greek eats in a traditional taverna setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6837,
        store_longitude: -73.9671,
        total_orders: 277.1,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Hamburger, Italian, Tasty, Hotel, American, Delicious, Rum, Indian, Casual',
        annual_dol: 294.89,
        description:
            "\"VESSEL BAR & GRILL is located in the heart of downtown Brooklyn's Fulton Street at Clinton Avenue. Offering lunch, dinner and late night dining with full bar services, we're only a short cab or pleasant walk from most of Brooklyn's major attractions.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.9491,
        total_orders: 106.81,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Beer, Wine, Whiskey, Gin, Cozy, Comfortable, Impeccable, Friendly, Casual',
        annual_dol: 131.05,
        description:
            'Craft cocktails from a cozy saloon in a distillery with exposed brick & views of the machinery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6501,
        store_longitude: -73.95,
        total_orders: 75.19,
        neighborhood: 'Little Haiti',
        keywords: 'Wine, Whiskey, Beer, Hotdog, Trendy, Romantic, Cozy, Premium, American, Classy',
        annual_dol: 307.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7128,
        store_longitude: -73.9568,
        total_orders: 194.09,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Coffee, Beer, Wine, Chinese, American, Natural, Fresh, Bright, Casual',
        annual_dol: 328.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7208,
        store_longitude: -73.9554,
        total_orders: 2025.57,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotel, Elegant, Stylish, Rooftop, Coffee, Vibrant, Lively, Modern, Perfect, Great',
        annual_dol: 2633.55,
        description:
            "The contemporary rooms and suites have minibars, free Wi-Fi and flat-screen TVs. Some have city views.An elegant restaurant serves breakfast and dinner. There's a lively rooftop lounge with Manhattan skyline views, and a seasonal outdoor saltwater pool. Guests get free bike rentals; there's also on-site parking (fee).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9219,
        total_orders: 204.98,
        neighborhood: 'Astoria/LIC',
        keywords: 'Mexican, Tacos, Nachos, Gourmet, Warm, Casual, Authentic, Local, Fresh, Rare',
        annual_dol: 771.92,
        description:
            'Narrow, down-to-earth restaurant specializing in homestyle Mexican cooking, cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.9754,
        total_orders: 293.33,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Wine, Chinese, Beer, Rum, Spicy, Elegant, Warm, Premium, Asian, Fusion',
        annual_dol: 1605.55,
        description:
            'Bi-level eatery serving a Shanghainese-centric menu in an elegant setting inspired by Old Shanghai.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9836,
        total_orders: 34.09,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Lasagna, Wine, Salad, Steak, Vodka, Pizza, Italian, Ale, Bruschetta, Elegant',
        annual_dol: 139.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9836,
        total_orders: 151.44,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Thai, Coffee, Chinese, Spicy, Delicious, Ale, Fried Rice, Artisanal, Traditional',
        annual_dol: 633.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.985,
        total_orders: 1056.43,
        neighborhood: 'Lower East Side',
        keywords:
            'Wine, Seasonal, Burger, Contemporary, French, Modern, American, Global, Asian, Casual',
        annual_dol: 1212.02,
        description:
            '"Our intimate, seasonal menu of modern American fare takes cues from Chef Sam Clonts’ time in some of New York City’s best kitchens; reflecting both his appreciation for local ingredients and a global approach. To drink, Raymond Trinh’s beverage team has crafted an eclectic wine list from old and new-world selections that satisfy a variety of tastes, available by the gloss or bottle. For something stronger, a cocktail menu rooted in the American classics with modern tweaks celebrates locally produced spirits, infusions and tinctures."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8691,
        store_longitude: -73.9175,
        total_orders: 330.02,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Beer, Wine, Irish, Legendary, Cozy, Picturesque, Inviting, Amazing, Great, Cool',
        annual_dol: 351.21,
        description:
            '"The Liffy II Bar is the last of picturesque Inwood’s Old Irish bars. The inviting oxblood exterior welcomes you to a bar that features original Irish bar decor including paintings,sketches and artifacts dating back to over a century. You can grab a $10 pitcher of beer, lounge on our plush sofas, play pool or ask the bartender for board games such as chess or Jenga. Have a decadent cocktail. Watch football or baseball on any of the 4 screens. Your group can watch their favorite tv show / soccer match in the pool table area. We do not sell food, so grab a menu from the bar and order in. The unpretentious energy of the Liffy II Bar transports you back to a simpler time of greeting your neighbors and after work cheers. Hope to see you soon!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6755,
        store_longitude: -73.8124,
        total_orders: 37.75,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Steak, Champagne, Chinese, French Fries, Chicken Wings, Spicy, Fried Calamari, French, Caribbean, American',
        annual_dol: 154.3,
        description:
            '"West Indian bar & restaurant, serving the best Guyanese-Chinese authentic dishes Food Includes but is not limited to Beef Bone In Cha Chi Kai Chicken Boneless Cha Chi Kai Chicken Boneless Chicken Broccoli With Garlic Sauce Chicken Chicken In The Ruff Entree French Fries Fried Banga Mary Fried Calamari Fried Gizzard Fried Shark Garlic Chicken Garlic Chicken Wings Ginger Lamb Ginger Shrimp Ginger Soy Chunks Half Chicken Hot And Spicy Shrimp Hot Wings House Special Jerk Chicken Jerk Lamb Jerk Pork Jerk Soy Chunks Lamb Lap Chong Mixed (Chicken And Pork) Mixed Veg With Garlic Sauce Mozzarella Sticks Pepper Shrimp Pepper Steak Plantain Chips Popular Menu Items Pork Rasta Pasta Rasta Pasta Jerk Chicken Rasta Pasta Jerk Lamb Rasta Pasta Jerk Pork"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9694,
        total_orders: 116.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Wine, Delicious, Organic, Iconic, Classic, Authentic, Intimate, Contemporary, Great',
        annual_dol: 477.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6817,
        store_longitude: -73.9767,
        total_orders: 1418.06,
        neighborhood: 'Park Slope',
        keywords:
            'Beer, Wine, Irish, Warm, American, French, Spacious, Classy, Traditional, Friendly',
        annual_dol: 1465.37,
        description:
            'Irish pub fare, draft beer & cocktails served in a comfortable, warm setting with a long bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6748,
        store_longitude: -73.9718,
        total_orders: 801.73,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Salad, Beer, Vibrant, Bruschetta, Cool, Modern, American, Diverse',
        annual_dol: 856.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 142.39,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Italian, Pizza, Coffee, Delicious, Mediterranean, American, Perfect, Peking Duck, Classic',
        annual_dol: 582.05,
        description:
            'Posh Italian tasting menus & classic trattoria-style dishes crafted in an industrial-chic eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.996,
        total_orders: 1065.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hamburger, Steak, Pizza, Beer, Wine, Martini, Cozy, Green Curry, Hotel, Irish',
        annual_dol: 1178.05,
        description: 'Pub offering bar food (with lunch specials), sports on TV & happy hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -74.0013,
        total_orders: 1908.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Chicken Tikka Masala, Delicious, Elegant, Eclectic, Sleek, Indian, Modern, Classic, Casual',
        annual_dol: 2008.43,
        description:
            'Sleek destination for eclectic Indian fare with fanciful presentations, plus inventive drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6793,
        store_longitude: -73.9645,
        total_orders: 2896.69,
        neighborhood: 'Prospect Heights',
        keywords:
            'Wine, Whiskey, Coffee, Delicious, Gin, Cozy, Comfortable, Perfect, Sleek, Premium',
        annual_dol: 3022.28,
        description:
            'Local art is sold alongside cocktails & bites at this sleek haunt with a chill vibe & music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6826,
        store_longitude: -73.9612,
        total_orders: 1725.04,
        neighborhood: 'Clinton Hill',
        keywords: 'Beer, Delicious, Warm, Irish, Cozy, Classy, Relaxed, Intimate, Homey, Authentic',
        annual_dol: 1799.83,
        description:
            'Warm, cozy gathering place serving Irish comfort food with an American twist & local craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6874,
        store_longitude: -73.9571,
        total_orders: 1183.8,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Beer, Margarita, Pinacolada, Hurricane, Daiquiri, Friendly, Classic, Awesome, Great, Cool',
        annual_dol: 1235.13,
        description:
            'Watering hole with New Orleans–style frozen drinks & beers, plus pinball & a jukebox.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6184,
        store_longitude: -74.1056,
        total_orders: 49427.14,
        neighborhood: 'Silver Lake/Castleton Corners/Mid Island',
        keywords:
            'Vodka, Upscale, Rustic, Romantic, American, Italian, Picturesque, Sweet, Intimate, Natural',
        annual_dol: 54161.24,
        description:
            'Upscale American restaurant & event venue in a rustic stone house with a lakefront patio & gardens.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.613,
        store_longitude: -74.1221,
        total_orders: 3.09,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Burger, Steak, Pizza, Salad, Italian, Shrimp Cocktail, Casual',
        annual_dol: 12.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7123,
        store_longitude: -73.9664,
        total_orders: 118.5,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Burger, Steak, Salad, Dessert, Trendy, Brazilian, American, Authentic, Fun, Traditional',
        annual_dol: 148.82,
        description:
            "Rio de Janeiro's festive air comes to Brooklyn via traditional Brazilian cuisine & colorful decor.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7162,
        store_longitude: -73.9627,
        total_orders: 11989.08,
        neighborhood: 'Williamsburg',
        keywords:
            'Cheesecake, Mimosa, Delicious, Peruvian, French Toast, Uruguayan, French, Fun, Pinacolada, Rum',
        annual_dol: 15465.77,
        description:
            'Traditional Latin American dishes & cocktails served in a chic venue with private rooftop pods.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7137,
        store_longitude: -73.962,
        total_orders: 224.98,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Sushi, Salad, Spicy, Japanese, Upscale, Organic, Ramen, Asian, Fusion, Sleek',
        annual_dol: 355.2,
        description:
            'Brick walls & a display of sake bottles create a sleek setting for sushi & other Japanese dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -73.9777,
        total_orders: 16.75,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Pizza, Chicken Lollipop, Italian, Savory, Delicious, Premium, Fine',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7622,
        store_longitude: -73.9777,
        total_orders: 827.39,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Chicken Pot Pie, Shrimp Cocktail, Beer, Bbq, Wine, Irish, Spicy, French, American',
        annual_dol: 3142.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8986,
        store_longitude: -73.8676,
        total_orders: 153.31,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords: 'Beer, Irish, Awesome, Classic, Great, Casual',
        annual_dol: 205.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 789.63,
        neighborhood: 'Jackson Heights',
        keywords: 'Karaoke, Wine, Fun, Colorful, Friendly, Amazing, Great, Modern, Lit, Latin',
        annual_dol: 1046.3,
        description:
            'Dimly lit Latin gay club with happy hours, karaoke nights, cocktails & party nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6805,
        store_longitude: -73.9775,
        total_orders: 970.91,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Pizza, Beer, Dessert, Italian, Irish, Fun, Fancy, Classy, Friendly',
        annual_dol: 1991.19,
        description:
            '"A fancy cocktail bar, a low-key neighborhood microbrew hangout, and a great place for date night"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6778,
        store_longitude: -73.9727,
        total_orders: 821.94,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Lasagna, Burger, Cajun, Whiskey, Vodka, French Toast, Bbq, Delicious, French, Rum',
        annual_dol: 3096.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6006,
        store_longitude: -73.8193,
        total_orders: 41.22,
        neighborhood: 'Broad Channel/Far Rockaway',
        keywords: 'Salad, Fun, Hotel, Great, American',
        annual_dol: 48.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 262.29,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Spanish, Fun, Intimate, Awesome, Innovative, Amazing, Fantastic, Incredible, Great',
        annual_dol: 920.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5412,
        store_longitude: -74.1781,
        total_orders: 104.74,
        neighborhood: 'Woodrow',
        keywords:
            'Sushi, Salad, Tuna Steak, Steak, Pizza, Gluten Free, Beer, Tasty, Japanese, Spicy',
        annual_dol: 150.99,
        description:
            'Specialty sushi & Japanese entrees, plus beer & cocktails, served in a modern, lounge-like space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7166,
        store_longitude: -73.9512,
        total_orders: 1334.3,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Shrimp Cocktail, Italian, Wine, Fried Calamari, Classy, Retro, Casual, Traditional, Perfect',
        annual_dol: 1373.34,
        description:
            'Circa-1900 neighborhood Italian restaurant plating red-sauce standards in a retro setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7072,
        store_longitude: -74.0072,
        total_orders: 3186.14,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Italian, Pizza, Salad, Dessert, Coffee, Wine, Mimosa, Beer, Ample, Local',
        annual_dol: 3579.99,
        description:
            'Easygoing choice serving ample portions of standard Italian favorites, plus old-school pizzas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.73,
        store_longitude: -73.9809,
        total_orders: 447.5,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Karaoke, Fun, Friendly, Great, Hospitable, Excellent, Casual, Chill',
        annual_dol: 457,
        description:
            'Amateur singers take the mike at this karaoke lounge with lipstick-red walls & zebra-print seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6802,
        store_longitude: -73.9612,
        total_orders: 87.39,
        neighborhood: 'Crown Heights/Clinton Hill',
        keywords:
            'Burger, Coffee, Beer, Iranian, Tasty, American, Casual, Terrific, Middle Eastern',
        annual_dol: 357.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7068,
        store_longitude: -74.0072,
        total_orders: 90.3,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Tacos, Salad, Burritos, Australian, Delicious, Casual, Local, Recommended',
        annual_dol: 491.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7094,
        store_longitude: -73.9586,
        total_orders: 15.36,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Korean, Vegetarian, Spicy, Delicious, Bbq, Trendy, Asian, Phenomenal, Sweet, Casual',
        annual_dol: 62.78,
        description:
            'Unassuming, trendy neighborhood bar turning out cocktails, soju & Korean-fusion small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9611,
        total_orders: 3586.07,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Coffee, Wine, Rum, Classic, Chill, Casual, Great, Incredible',
        annual_dol: 4747.48,
        description:
            'Vibrant, art-filled hangout offering frozen & classic cocktails, plus draft beer & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9842,
        total_orders: 2900.67,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Burger, Hamburger, Salad, Mimosa, Beer, Coffee, Wine, American, Spicy, Prosecco',
        annual_dol: 3193.95,
        description:
            'Bi-level tavern with a rooftop beer garden offering a menu of American comfort grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.745,
        store_longitude: -73.9832,
        total_orders: 76.45,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Hamburger, Beer, Whiskey, Delicious, Warm, American, Inviting, Hotel, Awesome',
        annual_dol: 312.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8002,
        store_longitude: -73.6512,
        total_orders: 7464.69,
        neighborhood: '',
        keywords:
            'Tequila, Wine, Steak, Upscale, Dessert, Elegant, American, Crisp, Eclectic, Inviting',
        annual_dol: 7626.41,
        description:
            'Elegant, historic estate turned upscale pub featuring New American cuisine & a raw bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7502,
        store_longitude: -73.9862,
        total_orders: 3102.79,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Karaoke, Spanish, Japanese, Korean, Lively, Fun, Awesome, Friendly, Recommended, Unique',
        annual_dol: 3309.41,
        description:
            'Lively karaoke lounge with drinks, a variety of rooms & a long, frequently updated song list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.9866,
        total_orders: 472.92,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Upscale, Warm, Iconic, Welcoming, Classy, Fun, Great, Cool, Casual',
        annual_dol: 531.02,
        description:
            'Neon-hued karaoke bar serving soju, nibbles & more to folks singing privately or in the main lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6598,
        store_longitude: -73.9613,
        total_orders: 2551.53,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Meatloaf, Meatball, Coffee, Beer, Wine, Gumbo, Mediterranean, American, Cozy, Asian',
        annual_dol: 2605.67,
        description:
            'Chill neighborhood bar pouring craft cocktails & barista coffee drinks in a hip, low-key setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6537,
        store_longitude: -73.5829,
        total_orders: 111.28,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Avocado Toast, French, English Muffin, Delicious, Bruschetta, Homemade, American, Casual',
        annual_dol: 126.75,
        description:
            'Classic diner offering elevated breakfast, lunch & dinner options, plus curbside pick-up.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9807,
        total_orders: 300.36,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Gluten Free, Steak, Spicy, Delicious, Mediterranean, Japanese, Turkish, Friendly, Local, Authentic',
        annual_dol: 1509.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6881,
        store_longitude: -73.9875,
        total_orders: 17.69,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Wine, Karaoke, Intimate, Zombie, Amazing, Great, Clean, Fresh, Middle Eastern',
        annual_dol: 72.32,
        description:
            '"Welcome to The Atlantic BKLN, one of Brooklyn’s newest and most intimate live music venues. The Atlantic BKLN hosts live music performances of all genres, featuring jazz, blues, folk, rock, weekly live band karaoke nights and is available for private event rentals. Our sound system, designed and installed by Clair Global, is one of the best in the country. Our 200-capacity venue aims to enhance the guest experience with a state of the art clean air handling system that ensures fresh air is constantly circulating throughout the space. Get ready, we are so excited to share with you this amazing space!!!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.739,
        store_longitude: -73.9995,
        total_orders: 3596,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vegetarian, Salad, Wine, Beer, Ale, Upscale, Cuban, Stylish, Mediterranean',
        annual_dol: 4095.59,
        description:
            'Local ingredients meet Mediterranean flavors at this laid-back kitchen with an industrial vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9987,
        total_orders: 223.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Chinese, Wine, Margarita, Spicy, Delicious, Sweet, Hotel, Traditional, Authentic, Colorful',
        annual_dol: 391.15,
        description:
            'Unassuming Chinese restaurant serving traditional hot & cold dishes, plus dim sum & lunch specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7281,
        store_longitude: -73.9819,
        total_orders: 145.32,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Burger, Salad, Coffee, Wine, Meatball, Margarita, Martini, Delicious, Trendy, Korean',
        annual_dol: 594.04,
        description:
            'Laid-back restaurant with artworks & a patio, furnishing alcohol, charcuterie & traditional bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6785,
        store_longitude: -73.9586,
        total_orders: 2856.84,
        neighborhood: 'Crown Heights',
        keywords: 'Funky, Fancy, Fun, Casual, Great',
        annual_dol: 3046.75,
        description:
            'Neighborhood bar/performance venue offering theme nights & dance parties, plus live music & DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9514,
        total_orders: 2006.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Cajun, Beer, Whiskey, Coffee, American, Mediterranean, Retro, Bright, Amazing',
        annual_dol: 2519.57,
        description:
            'Brick-walled hangout with a central bar & dining tables, offering craft beers and snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6692,
        total_orders: 501.48,
        neighborhood: '',
        keywords:
            'Steak, Tequila, Salad, Wine, Mexican, American, Contemporary, Spicy, Irish, Polished',
        annual_dol: 524.11,
        description:
            'Casual, island-style haunt with a buzzy bar & patio offering New American fare & a broad wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -73.8104,
        total_orders: 23.22,
        neighborhood: 'Fresh Meadows/Flushing/Pomonok',
        keywords: 'Fresh, Chinese, Cool, Hotel, Retro, Polish, Local',
        annual_dol: 94.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6444,
        store_longitude: -74.0765,
        total_orders: 165.48,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Steak, Lobster, Sushi, Japanese, Turkish, Gluten Free, Sashimi, Teriyaki Salmon, Fusion, Delicious',
        annual_dol: 676.41,
        description:
            '"Wasabi Steak & Sushi is the premier dining location for Japanese cuisines. Consistently voted as a top dining destination. A clean modern décor and sophisticated interior sets the perfect atmosphere for an unparalleled dining experience. We are the perfect venue for any occasion. Join our Hibachi dining room where veteran chefs prepare spectacular displays of food right before your eyes."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.731,
        store_longitude: -73.9826,
        total_orders: 410.48,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Salad, Coffee, Delicious, Greek, American, Friendly, Fresh, Casual',
        annual_dol: 457.39,
        description:
            'Classic Greek dishes, such as calamari & souvlaki, are served in an airy space with an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.9546,
        total_orders: 63.62,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Vegan, Tasty, Upscale, Delicious, Spicy, Jamaican, Caribbean, Healthy, Rum',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7138,
        store_longitude: -73.9655,
        total_orders: 8945.26,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Salad, American, Trendy, Contemporary, Modern, Casual, Sweet, Innovative, Fun, Signature',
        annual_dol: 9139.51,
        description:
            "Atera alum's American restaurant with a marketplace, barroom & upstairs dining room & garden.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7113,
        store_longitude: -73.9658,
        total_orders: 34.81,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Green Papaya Salad, Beer, Salad, Vietnamese, Cozy, Spicy, Friendly, Fun, Papaya Salad, Casual',
        annual_dol: 39.64,
        description:
            'Classic Vietnamese fare served in a brick-lined warehouselike space with a bar & rooftop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9216,
        total_orders: 14.78,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Milkshake, Avocado Toast, Chinese, Delicious, American, Mediterranean, Modern, Retro, Fun',
        annual_dol: 60.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7671,
        store_longitude: -73.9865,
        total_orders: 585.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Vegetarian, Wine, Beer, Japanese, Delicious, Upscale, Stylish, Spacious, Asian',
        annual_dol: 1368.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.766,
        store_longitude: -73.9808,
        total_orders: 86.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Casual',
        annual_dol: 352.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9851,
        total_orders: 2541.53,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hamburger, Vodka, Salad, Wine, Beer, Russian, Irish, Perfect, Awesome, Indian',
        annual_dol: 2769.88,
        description:
            'Serving pub grub, this haunt with dark-wood decor features 15+ flat-screen TVs & a spot for darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9792,
        total_orders: 725.22,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Italian, Trendy, Spanish, Intimate, Casual, Superb',
        annual_dol: 2746.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8623,
        store_longitude: -73.9254,
        total_orders: 48.17,
        neighborhood: 'Fort George',
        keywords:
            'Steak, Lobster, Salad, Tasty, Dessert, Chinese, Delicious, Dominican, Asian, Latin',
        annual_dol: 196.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.634,
        store_longitude: -73.5819,
        total_orders: 656.07,
        neighborhood: '',
        keywords: 'Ale, Beer, Lobster, Lobster Roll, Cajun, Salad, Wine, Nachos, American, Casual',
        annual_dol: 698.18,
        description:
            'Beer comes in huge Styrofoam cups at this long-standing barroom with bras hanging from the ceiling.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8641,
        store_longitude: -73.9265,
        total_orders: 10.82,
        neighborhood: 'Fort George',
        keywords: 'Peruvian, Delicious, Casual, Sweet, Fun, Modern, Unique',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6444,
        store_longitude: -73.5753,
        total_orders: 578.46,
        neighborhood: '',
        keywords:
            'Tequila, Mexican, Margarita, Vodka, Wine, Fajitas, French, Delicious, Triple Sec, Premium',
        annual_dol: 2364.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6805,
        store_longitude: -73.9751,
        total_orders: 31.22,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Spicy, Stylish, Creamy, Jamaican, Caribbean, Rum, Chinese, Exotic, Sweet',
        annual_dol: 127.62,
        description:
            'Lively Park Slope joint near the Barclays Center serving Caribbean food & drink.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6779,
        store_longitude: -73.9724,
        total_orders: 6224.86,
        neighborhood: 'Prospect Heights',
        keywords:
            'Wine, Beer, Trendy, Sweet, Southern Comfort, Gourmet, Southern, Classy, Casual, Hip',
        annual_dol: 6494.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.9447,
        total_orders: 16.09,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Vegan, Dessert, Wine, Coffee, Vegetarian, Moroccan, Mediterranean, Israeli, Tranquil',
        annual_dol: 65.78,
        description:
            'Contemporary Mediterranean eatery with a market-driven vegetarian menu & tranquil garden patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7233,
        store_longitude: -73.9853,
        total_orders: 666.01,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Beer, Chicken Pot Pie, Spicy, Delicious, Irish, Fabulous, Sweet, American, Welcoming',
        annual_dol: 950.72,
        description:
            'Welcoming bar & grill serving Irish & American flavors in a modern setting with distinctive decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -73.9786,
        total_orders: 1569.79,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Coffee, Whiskey, Tacos, Beer, Pizza, Vietnamese, Quaint, Cozy, Lebanese, Comfortable',
        annual_dol: 1617.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7224,
        store_longitude: -73.9841,
        total_orders: 263.62,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lasagna, Italian, Wine, Delicious, Meatball, Romantic, Friendly, Sweet, Homemade, Intimate',
        annual_dol: 1077.6,
        description:
            'Italian wines are paired with small plates at this rustic, brick-walled wine bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.686,
        store_longitude: -73.9731,
        total_orders: 53.69,
        neighborhood: 'Fort Greene',
        keywords: 'Salad, Burger, Apple Pie, Donut, Korean, Wine, Spicy, Japanese, Asian, American',
        annual_dol: 75.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9798,
        total_orders: 279.15,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Upscale, Hotel, Whiskey, Bourbon, Rooftop, Margarita, Bright, Outstanding, Cozy, Classic',
        annual_dol: 1141.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -73.9945,
        total_orders: 131.11,
        neighborhood: 'Nolita',
        keywords: 'Sushi, Salad, Wine, Dessert, Beer, Spicy, Japanese, Creamy, Sashimi, Asian',
        annual_dol: 535.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7675,
        store_longitude: -73.9895,
        total_orders: 666.36,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Chocolate Cake, Salad, Flavorful, Tasty, Delicious, Upscale, Trendy, Romantic, Mediterranean, Japanese',
        annual_dol: 818.04,
        description:
            'Mediterranean-style cuisine served in a polished venue that has outdoor seating & a shisha lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.9861,
        total_orders: 83.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Chocolate Mousse, Shrimp Cocktail, Tasty, Wine, Beer, French, Lively, Casual, Terrific, Great',
        annual_dol: 355.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.718,
        store_longitude: -73.9892,
        total_orders: 852.88,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Pizza, American, Vintage, Stylish, Classic, Spacious, Ample, Local, Casual',
        annual_dol: 2197.74,
        description:
            '"12 Lanes of classic ten-pin bowling, full bar with up to 24 American craft beers on tap, full kitchen featuring brick oven pizza. Open every day."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6349,
        store_longitude: -73.6404,
        total_orders: 39.98,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Organic, Local, Casual, Classic',
        annual_dol: 255.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7758,
        store_longitude: -73.91,
        total_orders: 231.13,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Elegant, Friendly, Cool, Casual, Great, American, Amazing, Fantastic, Recommended',
        annual_dol: 463.61,
        description:
            '"The doctors and staff of Astoria Foot Care Group, P.C. located in Astoria, New York would like to welcome you to our web site. As you navigate through the site you will find a wealth of information about podiatry and treatments available. We invite you to call our office with any and all concerns you may have. At Astoria Foot Care Group, we take great pride in treating our patients with that extra bit of care and attention they deserve. From the moment you enter to the completion of the visit, you will be greeted with a sincere smile by the entire office staff. When you make an appointment at the Astoria Foot Care Group, our staff will verify your insurance coverage in advance."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.8894,
        total_orders: 33.64,
        neighborhood: 'Jackson Heights',
        keywords: 'Mexican, Tacos, Fajitas, Casual, Perfect, Great, Diverse',
        annual_dol: 199.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6753,
        store_longitude: -73.9747,
        total_orders: 467,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hamburger, Meatloaf, Tacos, Salad, Mexican, Cajun, Tequila, Jambalaya, Milkshake',
        annual_dol: 525.92,
        description:
            'Cozy, brick-clad outlet offering comfort & American dining classics, plus brunch & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -73.9806,
        total_orders: 790.18,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Korean, French, Irish, Hotel, Retro, Casual, Local, Fresh, Unique',
        annual_dol: 806.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9445,
        total_orders: 56.96,
        neighborhood: 'Williamsburg',
        keywords: 'Steak, Tacos, Mexican, Vegan, Salad, Delicious, Pinacolada, Classic, Casual',
        annual_dol: 174.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8187,
        store_longitude: -73.9153,
        total_orders: 23.9,
        neighborhood: 'Melrose/West Bronx',
        keywords: 'Mexican, Casual',
        annual_dol: 30.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7159,
        store_longitude: -73.9622,
        total_orders: 2418.67,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Tasty, Margarita, Delicious, Bourbon, Coffee, Sweet, Fun, Vibrant, Woody',
        annual_dol: 2491.37,
        description:
            'Down-home, honky-tonk saloon pairing a woody space with beer, games & live country music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9216,
        total_orders: 747.3,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Beer, Steak, Pizza, Cajun, Irish, Warm, American, Cozy, Innovative',
        annual_dol: 779.7,
        description:
            'Easygoing pub with many craft brews on tap, seasonal sidewalk seating & updated comfort eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9759,
        total_orders: 179.38,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Vegetarian, Vegan, Steak, Tacos, Mexican, Tasty, Burritos, Delicious, Cozy, Enchiladas',
        annual_dol: 209.25,
        description:
            'Burritos, tacos & other Mexican staples are paired with margaritas at this small, colorful eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7622,
        store_longitude: -73.9254,
        total_orders: 779.42,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Whiskey, Beer, Irish, Warm, Yummy, Friendly, Classic, Mint Julep, Authentic, Casual',
        annual_dol: 831.24,
        description:
            'Classic Irish pub with a snug courtyard featuring a menu with familiar fare, plus local beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.9821,
        total_orders: 3029.94,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Hotdog, Whiskey, Burger, Beer, Flavorful, Champagne, Wine, Bourbon, Gin, Cozy',
        annual_dol: 3094.23,
        description:
            'Dollars hang from the ceiling of this narrow, brick-lined watering hole for cocktails & draft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9655,
        total_orders: 500.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Salad, Wine, Champagne, Bourbon, Beer, Avocado Toast, Martini, Organic',
        annual_dol: 517.94,
        description:
            'Rustic, nautical-themed eatery offering ample seafood plus craft cocktails & a weekend tiki brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.713,
        store_longitude: -73.9674,
        total_orders: 2855.6,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Vegetarian, Wine, Beer, Italian, Martini, Gin, Trendy, Upscale, Mediterranean, Bellini',
        annual_dol: 3003.38,
        description:
            'Contemporary-chic Italian dining space dishing up elevated antipasto & handmade pasta dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.9836,
        total_orders: 367.08,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Steak, Wine, French, Classic, Iconic, Perfect, Homemade, Friendly, Great, Omelette',
        annual_dol: 1500.52,
        description:
            '"Located in the iconic Brasserie Les Halles space on Park Avenue South, Francis Staub, founder of the fabled cookware company (Staub) brings gourmand cooking to New York. A celebration of the French brasserie — restaurants loved for their casual, convivial atmosphere, ingredient-driven dishe,s and extensive Old World wine menu — La Brasserie was created with both indulgence and daily pleasure in mind."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7121,
        store_longitude: -73.963,
        total_orders: 119.89,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Awesome, Great, Casual',
        annual_dol: 490.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7439,
        store_longitude: -73.9792,
        total_orders: 50.87,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Wine, Delicious, Spicy, Spanish, Warm, Beer, Fun, Fabulous, Cozy, Sweet',
        annual_dol: 207.95,
        description:
            'Casual, brick-lined locale with a generous selection of wine, Spanish small plates & sidewalk seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -73.9833,
        total_orders: 176.4,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Upscale, Stylish, Coffee, Delicious, Hotel, Gin, Beer, Classy, Fancy, Perfect',
        annual_dol: 247.13,
        description:
            'Stylish lounge outfitted with flat-screen TVs for watching sports while sipping craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9882,
        total_orders: 3721.09,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Burger, Vegetarian, Mexican, Salad, Beer, Margarita, Dessert, Delicious, Enchiladas',
        annual_dol: 4622.47,
        description:
            'A bustling eatery serving basic Mexican fare to a lively crowd, with DJs & a big margarita menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7649,
        store_longitude: -73.9831,
        total_orders: 1242.44,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Cajun, Salad, Beer, Chicken Pot Pie, Mexican, Wine, Ale, Irish, Delicious',
        annual_dol: 1296.31,
        description:
            'Wood-lined Irish pub sprawling over 3 floors & offering bar grub & plenty of draft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.814,
        store_longitude: -73.945,
        total_orders: 1689.93,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Pizza, Beer, African, American, Intimate, Casual, Exquisite, Signature',
        annual_dol: 3348.65,
        description:
            'Gay bar with a dark, intimate vibe offering pizza & wings, plus beer & signature cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7656,
        store_longitude: -73.9877,
        total_orders: 1370.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Salad, Vegetarian, Vodka, Sushi, Whiskey, Dessert, French, Gin, Zen, Elegant',
        annual_dol: 1474.9,
        description:
            '"Ikebana Zen is a form of artwork that merges the urban life of New York with the Japanese bar through the abstract sense of ephemerality created by material, color, and light. Serving sake, craft cocktails, spirits are paired with small plates."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.988,
        total_orders: 1187.77,
        neighborhood: 'Lower East Side',
        keywords:
            'Hotel, Upscale, Luxurious, Trendy, Lobster, Stylish, Italian, Coffee, Delicious, Funky',
        annual_dol: 1421.14,
        description:
            "The minimalist rooms have floor-to-ceiling windows, slate bathrooms and complimentary Wi-Fi. Upgraded rooms include balconies, deep soaking tubs, steam showers with glass exterior walls and additional bedrooms.A hip restaurant with Mediterranean fare features outdoor dining with street views, and a sky-lit atrium. There's also a juice bar on the ground floor, as well as a rooftop event venue.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -73.985,
        total_orders: 4490.26,
        neighborhood: 'Lower East Side',
        keywords:
            'Sushi, Wine, Beer, Margarita, Trendy, Japanese, Delicious, Homemade, Sashimi, Seasonal',
        annual_dol: 4765.66,
        description:
            "Japanese izakaya serving small plates, sashimi & many sakes at a chef's bar in a modern, snug space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7765,
        store_longitude: -73.7527,
        total_orders: 519.75,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Asian, French Onion Soup, Colorful, French, Fresh, American, Unique, Great, Local, Chill',
        annual_dol: 554.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.8848,
        total_orders: 84.52,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Steak, Sushi, Salad, Delicious, Mediterranean, French, Colombian, Latin, Sangria, American',
        annual_dol: 345.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.8842,
        total_orders: 30.86,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Mexican, Burger, Pizza, Beer, Sushi, Tex-Mex, Fun, Authentic, Great, Excellent',
        annual_dol: 126.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.9939,
        total_orders: 606.16,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Rooftop, Fresh',
        annual_dol: 2283.81,
        description:
            'Empire State Building.The unfussy rooms provide flat-screen TVs, Wi-Fi, minifridges and coffeemakers. Some feature skyline views and/or sofabeds.There’s an understated restaurant with a bar. Other amenities include a 24-hour fitness center and a rooftop terrace. Breakfast is available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7285,
        store_longitude: -73.7839,
        total_orders: 3.16,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords: 'Steak, Sushi, Spicy, Japanese, Brazilian, Fresh, Mediterranean, Casual',
        annual_dol: 12.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6773,
        store_longitude: -73.9576,
        total_orders: 1024.88,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Mexican, Beer, Tasty, American, Eclectic, Vintage, Awesome, Classic, Perfect',
        annual_dol: 3861.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 2533.4,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Steak, Salad, Italian, Spanish, Stylish, Bellini, Dutch, Warm, Perfect, Casual',
        annual_dol: 6969.29,
        description:
            'Warm spot offering seafood, oysters & prime-aged beef, plus a bar & views of the Brooklyn Bridge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.631,
        store_longitude: -74.103,
        total_orders: 16.19,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Burger, Pizza, Salad, Tacos, Italian, Shrimp Cocktail, Vodka, Dessert, Bbq, Comfortable',
        annual_dol: 66.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7701,
        store_longitude: -73.9551,
        total_orders: 3481.19,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Salad, Apple Pie, Japanese, Czech, Savory, Hotel, Cozy, Ramen, Warm',
        annual_dol: 4469.17,
        description:
            'Bar inspired by the Meiji era serving craft cocktails in antique drinking vessels & Japanese fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7448,
        store_longitude: -73.987,
        total_orders: 6451.9,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Beer, Salad, Wine, Vodka, Roast Turkey, Turkey Sandwich, Dessert, Bread Pudding',
        annual_dol: 7554.27,
        description:
            'Relaxed pub with a rustic vibe & patio offering craft beers, New American small plates & sandwiches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.766,
        store_longitude: -73.9915,
        total_orders: 1002.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Tasty, Cozy, Delicious, Polished, Vibrant, Mediterranean, Cool, Awesome, Casual',
        annual_dol: 1169.55,
        description:
            'Over 30 wines by the glass are available along with homemade NY pretzels & hand-crafted cheeses.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7231,
        store_longitude: -73.9898,
        total_orders: 2570.4,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Whiskey, Lemon Meringue Pie, Wine, Irish, Lively, American, Fancy, Friendly, Traditional',
        annual_dol: 2738.07,
        description:
            'Pub by the Beerly Legal Group (The Jeffrey) offering brews, bourbons, seasonal drinks & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7165,
        store_longitude: -73.9878,
        total_orders: 165.88,
        neighborhood: 'Lower East Side',
        keywords: 'Mexican, Tequila, Beer, Steak, Bourbon, Wine, Tasty, Eclectic, Authentic, Local',
        annual_dol: 582.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6857,
        store_longitude: -73.8649,
        total_orders: 31.85,
        neighborhood: 'Jamaica/Woodhaven',
        keywords:
            'Lobster, Steak, Lobster Tail, Mexican, Dominican, Spanish, American, Latin, Teriyaki, Casual',
        annual_dol: 130.2,
        description:
            '"Hello Welcome to el Rey iv Restaurant, we are a restaurant with a great diversity of services and experiences such as billiards, event venue, bar, restaurant, arcade games and much more! If you need any information you can call us we will be happy to assist you"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7149,
        store_longitude: -73.9915,
        total_orders: 204.39,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Tequila, Beer, Steak, Ale, Margarita, Coffee, Tasty, Dessert, Trendy, Rum',
        annual_dol: 217.97,
        description:
            'Eclectic, health-conscious Californian-style fare dished in a minimalist, white-&-blond-wood space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 74,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Delicious, Dessert, Sweet, Great, Casual, Modern',
        annual_dol: 302.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9802,
        total_orders: 10.56,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Sushi, Vegetarian, Japanese, Chinese, Spicy, Asian, Delicious, Hotel, Urban, Casual',
        annual_dol: 43.19,
        description:
            'Informal spot offering a broad menu of Chinese & Japanese favorites for dine-in, takeout & delivery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.8867,
        total_orders: 5.21,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Mexican, Italian, Ecuadorian, Casual, Healthy, American, Friendly, Latin, Great',
        annual_dol: 21.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8031,
        store_longitude: -73.6463,
        total_orders: 35.57,
        neighborhood: '',
        keywords: 'Sushi, Pizza, Cajun, Spicy, Beer, Japanese, Chilean, Premium, Casual, Perfect',
        annual_dol: 96.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7586,
        store_longitude: -73.9772,
        total_orders: 1527.49,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Salad, Wine, Upscale, Mediterranean, Organic, Greek, Tranquil, Welcoming, Exquisite',
        annual_dol: 1716.57,
        description:
            'Upscale Greek-Mediterranean restaurant with a large seafood-focused menu & spacious, chic digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5849,
        store_longitude: -73.8192,
        total_orders: 64.65,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Beach/Rockaway Park',
        keywords: 'Burger, Salad, Tasty, Nachos, Wine, Bbq, Tangy, Chinese, Mediterranean, Hotel',
        annual_dol: 264.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 298.79,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Stylish, Trendy, Premium, Fun, High-End, Intimate, Rooftop, Cool, Distinct',
        annual_dol: 610.68,
        description:
            'Trendy Dream Hotel basement bar with English-accented decor & a small-batch spirit menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8675,
        store_longitude: -73.9294,
        total_orders: 20.5,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Salad, Wine, Beer, Delicious, Avocado Toast, Latin, Clean, Modern, Friendly, Contemporary',
        annual_dol: 83.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7136,
        store_longitude: -73.9343,
        total_orders: 225.98,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Gin, Rum, Funky, Fancy, Classy, Fun, Prosecco, Casual',
        annual_dol: 923.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7139,
        store_longitude: -73.9522,
        total_orders: 204.95,
        neighborhood: 'Williamsburg',
        keywords: 'Hotel, Upscale, Beer, Sleek, Vibrant, Contemporary, Great, Fresh, Local',
        annual_dol: 271.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9873,
        total_orders: 628.97,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegetarian, Gourmet, Coffee, Champagne, Tasty, Austrian, Italian, Ale, Delicious, Hotel',
        annual_dol: 1364.02,
        description:
            'Counter-serve deli with upstairs seating & sidewalk tables offering simple sandwiches & snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7099,
        store_longitude: -73.9687,
        total_orders: 66.15,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Pizza, American, Rooftop, Wine, Hotel, Southern, Classic, Casual, Complex',
        annual_dol: 190.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -73.9587,
        total_orders: 28.01,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Southern, Casual, Thai',
        annual_dol: 114.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -73.9804,
        total_orders: 40.53,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Vegetarian, Vegan, Tasty, Flavorful, Savory, Delicious, Spicy, Healthy, Asian, Friendly',
        annual_dol: 165.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7647,
        store_longitude: -73.9915,
        total_orders: 1524.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Whiskey, Wine, Mexican, Trendy, Ale, Crisp, Hotel, Lively, American',
        annual_dol: 1600.56,
        description:
            'Rotating craft beers, cocktails & elevated bar bites served amid exposed brick & retro touches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.983,
        total_orders: 449.46,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Whiskey, Tasty, American, Classic, Urban, Iconic, Fun, Traditional, Local',
        annual_dol: 1851.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7629,
        store_longitude: -73.9775,
        total_orders: 212.59,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Pizza, Vegan, Italian, Gluten Free, Hawaiian, Modern, Spicy, Contemporary, Retro, Artisanal',
        annual_dol: 266.99,
        description:
            'Wood-fired pies & other Neapolitan dishes served in a bi-level space adorned by modern art for sale.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.985,
        total_orders: 2575.16,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Wine, Vodka, Beer, Martini, Gin, Bourbon, Ale, Rum, Delicious',
        annual_dol: 2641.99,
        description:
            'Dimly lit bar with high tops & a communal table serves cocktails alongside oysters & seafood plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7221,
        store_longitude: -73.9866,
        total_orders: 874.52,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Hotel, Perfect, Authentic, Casual, Lively, Great, Eclectic',
        annual_dol: 893.08,
        description:
            "Indie headliners & up-and-comers have kept this small music venue crowded since the '90s.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7174,
        store_longitude: -73.9952,
        total_orders: 122.09,
        neighborhood: 'Bowery',
        keywords:
            'Karaoke, Chinese, Wine, Margarita, Japanese, Korean, Mojito, Asian, Modern, Great',
        annual_dol: 499.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7759,
        store_longitude: -73.9026,
        total_orders: 11.06,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Sushi, Burger, Salad, Wine, Spicy, Upscale, Trendy, Mediterranean, Sashimi, English Muffin',
        annual_dol: 45.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8729,
        store_longitude: -73.9184,
        total_orders: 37.73,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Burger, Vegan, Beer, Wine, Coffee, Spicy, Southern, Casual, Friendly, Local',
        annual_dol: 154.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.979,
        total_orders: 2257.5,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Salad, Cajun, Shrimp Scampi, Bourbon, Wine, Upscale, Legendary, Caesar Salad',
        annual_dol: 2991.29,
        description:
            'Upscale chain for aged prime beef, seafood & other traditional steakhouse fare in a clubby space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6858,
        store_longitude: -73.9885,
        total_orders: 8959.56,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Beer, Vietnamese, Cozy, Casual, American, Local, Fresh, Great',
        annual_dol: 8959.56,
        description:
            'Old-school watering hole with a 19th-century wood bar, low-key atmosphere, jukebox & pool table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7426,
        store_longitude: -73.9993,
        total_orders: 1324.34,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vegan, Beer, Wine, Martini, Margarita, Ale, Bourbon, Delicious, Spicy, Friendly',
        annual_dol: 4299.09,
        description:
            'Narrow, easygoing bar for cocktails, beers & happy hours, along with sandwiches, salads & sides.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -74.0009,
        total_orders: 1429.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Coffee, Hotel, Modern, Aesthetic, Friendly, Great',
        annual_dol: 2002.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7479,
        store_longitude: -74.0043,
        total_orders: 573.81,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Beer, Scotch, Salad, Tasty, Ale, Robust, Irish, Modern, Fresh',
        annual_dol: 1157.49,
        description:
            'Modern bar food, craft beer, cocktails & a robust Scotch menu in a laid-back space with a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.678,
        store_longitude: -73.9796,
        total_orders: 947.66,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Vegan, Vegetarian, Lasagna, Pizza, Salad, Mexican, Burritos, Nachos, Tasty',
        annual_dol: 991.06,
        description: 'Low-key cafe serving vegan, kosher & gluten-free dishes with Latin accents.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7285,
        store_longitude: -73.9789,
        total_orders: 694.86,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Pizza, Salad, Beer, Whiskey, Rustic, Cozy, Friendly, Eclectic, Artisanal, Perfect',
        annual_dol: 751.71,
        description:
            'Foosball, an eclectic jukebox & random movie screenings draw locals to this plain-Jane barroom.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.712,
        store_longitude: -73.9511,
        total_orders: 1777.18,
        neighborhood: 'Williamsburg',
        keywords:
            'Beer, American, Vintage, Classic, Eclectic, Classy, Friendly, Chic, Awesome, Great',
        annual_dol: 1816.14,
        description:
            'Vintage video games (Pac-Man, Donkey Kong, etc.) & beer lure patrons to this Williamsburg bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9854,
        total_orders: 2063.4,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Salad, Hamburger, Beer, Warm, Healthy, Nachos, Irish, Friendly, Clean',
        annual_dol: 2159.18,
        description:
            'This laid-back Irish bar offers pub grub, pints & plenty of TVs for catching the game.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7131,
        store_longitude: -73.9564,
        total_orders: 427.34,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Wine, Tasty, Bourbon, Dessert, Trendy, Iranian, Beer, Romantic, French Onion Soup',
        annual_dol: 1309.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.715,
        store_longitude: -73.959,
        total_orders: 80.47,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Beer, Rooftop, Hotel, Spicy, French, American, Bright, Delicious, Fun',
        annual_dol: 328.94,
        description:
            '"Located in the heart of Williamsburg (next to the Pod Hotel), Clinton Hall opened its fifth location with the intent to bring some Manhattan flavor to Brooklyn! With an outdoor beer garden for 200+, tons of giant games, amazing food, and our Supercraft beer program, you can\'t go wrong at Clinton Hall Williamsburg!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9847,
        total_orders: 602.94,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Salad, Italian, Wine, Chilean, Japanese, Spicy, Delicious, Asian, Modern',
        annual_dol: 1565.5,
        description:
            'Sushi, infused sakes & modern Japanese fare are served at this sleek venue with an adjacent lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9849,
        total_orders: 10200.86,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Steak, Sushi, Japanese, Tasty, Ramen, Spicy, Trendy, Creamy, Modern, Healthy',
        annual_dol: 11266.04,
        description:
            'This modern restaurant prepares updated yakitori & ramen dishes in a sophisticated space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7494,
        store_longitude: -73.9833,
        total_orders: 181.37,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Shrimp Cocktail, Wine, Spanish, Martini, Upscale, Organic, Spacious, Classy, Classic, Perfect',
        annual_dol: 197.37,
        description:
            'Longtime Spanish eatery with an old-world vibe, dressed-up waiters & a classic menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.9599,
        total_orders: 1542.43,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Steak, Salad, Wine, Beer, Delicious, French Onion Soup, Organic, French, Classy',
        annual_dol: 1575.16,
        description:
            'French-American comfort food in a bistro setting with banquette seating & a summertime garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -73.9828,
        total_orders: 90.77,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tacos, Burritos, Salad, Hotel, Western, Local, Casual, Great',
        annual_dol: 554.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9848,
        total_orders: 2399.25,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Coffee, Spacious, Great, Friendly, Contemporary',
        annual_dol: 9135,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7125,
        store_longitude: -74.0079,
        total_orders: 849.48,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Avocado Toast, Tasty, Coffee, Iconic, Rooftop, Irish, Lively, Impressive, Great, American',
        annual_dol: 2982.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5936,
        store_longitude: -73.5811,
        total_orders: 979.33,
        neighborhood: '',
        keywords:
            'Quaint, Relaxed, Fun, Ale, Fabulous, Casual, Memorable, Great, Wonderful, American',
        annual_dol: 1160.85,
        description:
            'Relaxed local venue serving seafood & American cooking with frozen cocktails & a waterside deck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.9897,
        total_orders: 1865.26,
        neighborhood: 'Lower East Side',
        keywords: 'Tasty, Salad, Delicious, Spicy, Warm, Chinese, Sweet, Asian, Perfect, Authentic',
        annual_dol: 7624.55,
        description:
            '"Right on the border with Chinatown, our speakeasy and tapas bar creates the ultimate intimate mood with classic Asian décor and warm lighting, ideal for whisking your palate into another era, among friends or in the spirit of romance. Serving an array of traditional Szechuan cuisine is crafted into tapas form with a modern twist while cocktails have been given the mixologist’s approach, infused with Chinese herbal botanical elements to create another dimension of flavor. Bite into Canton shumai, Sichuan wontons with house-made chili oil, night market noodles, crispy tofu peppered in our house spice blend, chicken or beef skewers with cumin and peppercorn, yuzu-glazed shrimp, or our absolute signature offering of thirteen-spiced tempura crab"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.9433,
        total_orders: 36.85,
        neighborhood: 'Roosevelt Island',
        keywords: 'Pizza, Beer, Hotel, Iconic, Spectacular, Contemporary, Fresh, Seasonal, Unique',
        annual_dol: 150.65,
        description:
            '"The Sanctuary is a waterfront events & wedding venue boasting sweeping views of the Manhattan skyline. Restored from a century-old stone church on the historic Roosevelt Island, the Sanctuary has a romantic, easy, and contemporary feel; worlds away from the city bustle yet conveniently in the heart of NYC. We feature both indoor and outdoor spaces to host up to 250 guests. We look forward to hosting your wedding, corporate, and other special event needs! Please also join us on our outdoor patio for our seasonal bar menu, open to the public Thursday-Sunday (subject to inclement weather or private events). No RSVPs. Follow us on Instagram @thesanctuaryri for latest announcements!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6327,
        store_longitude: -73.5816,
        total_orders: 345.42,
        neighborhood: '',
        keywords: 'Lobster, Margarita, Mexican, Clean, American, Great, Classic, Fine, Sangria',
        annual_dol: 445.44,
        description:
            'Long-running, old-school eatery on the canal featuring seafood-focused American fare & patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8691,
        store_longitude: -73.9159,
        total_orders: 254.34,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Cuban, Spicy, Trendy, Delicious, Mimosa, Cozy, Spanish, Classy, Pinacolada, Friendly',
        annual_dol: 402.41,
        description:
            'This hookah lounge with late-night hours offers a Latin menu & frequent live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6367,
        store_longitude: -73.5827,
        total_orders: 86.5,
        neighborhood: '',
        keywords: 'Memorable, Rum, Great, Signature',
        annual_dol: 353.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.714,
        store_longitude: -73.9445,
        total_orders: 1199.3,
        neighborhood: 'Williamsburg',
        keywords: 'Hotdog, Salad, Upscale, Spacious, Clean, American, Fun, Fresh, Awesome, Great',
        annual_dol: 1228.5,
        description:
            'Sleek, simple tavern with a garden patio showcasing craft cocktails & upscale bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7111,
        store_longitude: -73.9507,
        total_orders: 506.12,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Whiskey, Vodka, Coffee, Irish, Friendly, Cool, Casual, Fresh, Local, Great',
        annual_dol: 653.79,
        description:
            'Basic neighborhood watering hole with a pool table, video games & skeeball, plus a backyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6128,
        store_longitude: -74.1586,
        total_orders: 1529.4,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Burger, Salad, Beer, Wine, New-York-Style, Dessert, Savory, Ale, Bbq, Chicken Wings',
        annual_dol: 1561.85,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5862,
        store_longitude: -73.6935,
        total_orders: 518.07,
        neighborhood: '',
        keywords: 'Hamburger, Tacos, Salad, Beer, Irish, Casual, American, Local, Sweet, Coleslaw',
        annual_dol: 564.62,
        description:
            "Hopping bi-level hangout draws crowds for live bands & DJ'd tunes, plus standard American bar fare.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7254,
        store_longitude: -73.9833,
        total_orders: 89.02,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Pizza, Wine, Beer, Italian, Vietnamese, Chinese, Upscale, Modern, Indian, Perfect',
        annual_dol: 363.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6365,
        store_longitude: -74.0775,
        total_orders: 176.57,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords:
            'Burger, Lobster, Salad, Dessert, Beer, Wine, Bbq, French, Caesar Salad, Polished',
        annual_dol: 187.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6729,
        store_longitude: -73.957,
        total_orders: 1128.76,
        neighborhood: 'Crown Heights',
        keywords:
            'Pizza, Tasty, Wine, Sustainable, American, Perfect, Amazing, Quirky, Casual, Excellent',
        annual_dol: 1846.62,
        description:
            'A creative menu of American drinks & bites with a sustainable focus inside a funky-hip space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6031,
        store_longitude: -74.1741,
        total_orders: 67.73,
        neighborhood: 'Bloomfield/Mid Island',
        keywords: 'Meatball, Norwegian, Scandinavian, Fun, Amazing, Great, Heritage',
        annual_dol: 276.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6815,
        store_longitude: -73.9583,
        total_orders: 52.56,
        neighborhood: 'Clinton Hill',
        keywords:
            'Tasty, Coffee, American, Friendly, Dope, Amazing, Casual, Great, Seasonal, Hummus',
        annual_dol: 316.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.751,
        store_longitude: -73.9812,
        total_orders: 342.21,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Upscale, Italian, Elegant, Legendary, Excellent, Friendly, Rooftop, Local',
        annual_dol: 1398.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9759,
        total_orders: 71.02,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Mexican, Tacos, Salad, Burritos, Local, Casual, Premium, Healthy',
        annual_dol: 351.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9279,
        total_orders: 17.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Mexican, Pizza, Vegan, Sushi, Colombian, Spanish, American, Rustic, Delicious',
        annual_dol: 72.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7442,
        store_longitude: -73.9875,
        total_orders: 16.57,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Hotel, Fun, Exotic, Cultural, Cool, Modern, Lit, Fine, Unique',
        annual_dol: 67.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7457,
        store_longitude: -73.9932,
        total_orders: 706.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vodka, Whiskey, Wine, Beer, Pizza, Martini, Gin, Delicious, Karaoke, Eclectic',
        annual_dol: 837.51,
        description:
            '"As the first distillery in Manhattan since Prohibition, we pride ourselves in making top-shelf vodka the city can be proud of. At our bar in Chelsea, we craft artisanal cocktails that feature the vodka from our on-site distillery. With a laid back vibe and a passion for all things New York, Our/New York Vodka Bar and Distillery is the place to celebrate being in the greatest city in the world."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5942,
        store_longitude: -73.5787,
        total_orders: 148.13,
        neighborhood: '',
        keywords: 'Pizza, Tasty, Beer, Awesome, Fantastic, Great',
        annual_dol: 201.66,
        description: '"Full Bar and Food Truck. Marine Gas and Diesel. Bait Shop."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9871,
        total_orders: 352.75,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lobster, Pizza, Steak, Beer, Vodka, Italian, Spicy, Casual, Fresh, Authentic',
        annual_dol: 1441.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6316,
        store_longitude: -73.5814,
        total_orders: 10967.82,
        neighborhood: '',
        keywords: 'Vodka, Beer, Wine, Mexican, Delicious, Perfect, Lively, Fresh, Casual, Great',
        annual_dol: 16344.62,
        description:
            'Breezy, informal waterfront operation serving raw & cooked seafood bites along with tropical drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6879,
        store_longitude: -73.9836,
        total_orders: 4023.07,
        neighborhood: 'Boerum Hill/Downtown Brooklyn',
        keywords: 'Hotel, Vintage, Warm, Donut, Serene, Great, Creative, Excellent, Hip, Cool',
        annual_dol: 10933.36,
        description:
            'Featuring vintage furnishings and local artwork, the retro-inspired rooms provide flat-screen TVs, Wi-Fi and minifridges. Some add loaner guitars and vinyl record players. The 1-bedroom suites offer separate living rooms.Amenities include a colorful, hip restaurant and a 24-hour fitness center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.747,
        store_longitude: -74.0084,
        total_orders: 19794.95,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Pizza, Salad, Delicious, Luxurious, Tangy, Martini, Elegant, Spectacular',
        annual_dol: 20274.89,
        description:
            '"Pier Sixty at Chelsea Piers is an exclusive waterfront event venue in NYC. Our beautiful venue is known for our exclusive partnership with Manhattan’s market leader in fine food and service, Abigail Kirsch. Now home to some of Manhattan’s most influential and notable events, Pier Sixty offers inspiring Hudson River water views and flexible event spaces that are perfect for gala fundraisers, corporate events, meetings, weddings and bar/bat mitzvahs. Check us out today!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.68,
        store_longitude: -73.9743,
        total_orders: 869.49,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Cornbread, Salad, Bbq, Turkey Sandwich, Coleslaw, Stylish, Southern, Friendly, Tradition, Fun',
        annual_dol: 907.19,
        description:
            'Trendy, stylish spot serving Texas-style BBQ by the pound, plus sandwiches & Southern sides.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7886,
        store_longitude: -73.8135,
        total_orders: 104.33,
        neighborhood: 'Whitestone/Flushing',
        keywords: 'Burger, Rich, Beer, Karaoke, Fun, Wine, Amazing, Friendly, Clean, Greek',
        annual_dol: 152.43,
        description:
            '"American Bar & Grill.... located in the heart of Whitestone village. Clean, friendly and fun environment. Open everyday till 4am."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.672,
        store_longitude: -73.9573,
        total_orders: 4852.8,
        neighborhood: 'Crown Heights',
        keywords: 'Pizza, Italian, Tasty, Salad, Meatball, Beer, Delicious, Wine, Warm, Spacious',
        annual_dol: 5120.25,
        description:
            'Lively eatery serving Neapolitan brick-oven pizzas plus salads, creative cocktails & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.6918,
        total_orders: 107.93,
        neighborhood: '',
        keywords: 'Burger, Tacos, Pizza, Salad, Nachos, Beer, Spicy, Irish, American, Casual',
        annual_dol: 135.54,
        description:
            '"The Ugly Duckling Bar & Restaurant is now open in the west end of Long Beach, NY. We are the perfect place to watch all the games, go bottoms up, enjoy a bite to eat or shake your tail feather! Offering Outdoor Dining & Curbside Pick Up!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6432,
        store_longitude: -74.0953,
        total_orders: 2012.77,
        neighborhood: 'Mid Island/New Brighton',
        keywords: 'Tequila, Mexican, Beer, Whiskey, Steak, Wine, Tex-Mex, Spicy, Spanish, American',
        annual_dol: 2935.79,
        description:
            'Popular, lively saloon with a Southwestern menu & a big beer selection, plus live music on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7136,
        store_longitude: -73.9587,
        total_orders: 812.51,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Karaoke, Vegan, Chinese, American, Cozy, Local, Casual, Dim Sum, Excellent',
        annual_dol: 829.75,
        description: '"a cozy neighborhood restaurant and karaoke saloon"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.7259,
        total_orders: 39.98,
        neighborhood: 'Flushing/Little Neck',
        keywords: 'Hotdog, Burger, Tacos, Mexican, Salad, Burritos, Warm, Healthy, Casual, Local',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6838,
        store_longitude: -73.9687,
        total_orders: 0,
        neighborhood: 'Fort Greene/Clinton Hill',
        keywords: 'Coffee, Elegant, American, Contemporary, Ample, Classic, Casual, Fine',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.9679,
        total_orders: 717.79,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Vodka, Beer, Martini, Canadian, Spicy, Warm, Fun, Classy, Iconic',
        annual_dol: 752.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7215,
        store_longitude: -73.9586,
        total_orders: 19434.98,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Wine, Pizza, Avocado Toast, Trendy, Hotel, Dessert, South American, Organic, Modern',
        annual_dol: 20581.86,
        description:
            'Empire State Building.Featuring custom furnishings, floor-to-ceiling windows and oak floors, the polished rooms provide free Wi-Fi, flat-screen TVs and designer toiletries; some have balconies with views of Brooklyn and/or Manhattan. Suites add sitting areas.Loaner bikes and an area tuk-tuk shuttle are complimentary. Other amenities include 3 bars, a trendy restaurant and a seasonal rooftop pool, plus a seasonal pop-up restaurant and occasional live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7138,
        store_longitude: -73.966,
        total_orders: 22764.89,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Coffee, Martini, American, Hotel, Burritos, Retro, Hip, Modern',
        annual_dol: 23287.92,
        description:
            'Coffee, cocktails & American gastropub fare served in a hip cafe & bar with a grassy outdoor space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.741,
        store_longitude: -73.9818,
        total_orders: 10123.94,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Burger, Vodka, Wine, Beer, Salad, Irish, American, Sangria, Fresh, Fantastic',
        annual_dol: 10238.09,
        description:
            'Bar bites, cocktails, beer & wine are served in a casual setting with late-night hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9232,
        total_orders: 497.24,
        neighborhood: 'Astoria/LIC',
        keywords: 'Mexican, Breakfast Burrito, Ale, Delicious, Casual, Perfect, Great',
        annual_dol: 541.68,
        description:
            '"Mexican cafe & Agaveria fare offering LA street-style tacos, comfort food, and crafty agave based cocktails & spirits."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -73.9856,
        total_orders: 39.38,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Upscale, Hotel, Italian, Wine, Rich, Spacious, Gourmet, Urban, Creamy, Sophisticated',
        annual_dol: 160.98,
        description:
            'The chic rooms and suites come with free Wi-Fi, 42-inch flat-screens and iPod docks, plus minibars. Suites feature separate living areas, and plush penthouse suites add poker tables, pool tables, Jacuzzis or bars.The hotel has a 2-level brasserie with seafood and French fare, a cocktail lounge with live music, a burlesque-inspired bar and a lobby bar. Other amenities include a business center, meeting space and wedding facilities.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.9938,
        total_orders: 58.27,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Tequila, Mexican, Beer, Wine, American, Perfect, Fantastic, Amazing, Impressive, Casual',
        annual_dol: 238.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6982,
        store_longitude: -73.975,
        total_orders: 35.5,
        neighborhood: 'Brooklyn Navy Yard',
        keywords: 'Coffee, Tasty, Global, Modern, Friendly, Local, Unique',
        annual_dol: 108.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7638,
        store_longitude: -73.9894,
        total_orders: 2723.32,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Irish, Tex-Mex, Friendly, Relaxed, Iconic, Clean, Great, Excellent, Local',
        annual_dol: 2806.83,
        description:
            'Draft beers, TVs & dartboards enliven this sports pub, popular with Pittsburgh Steelers fans.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -73.9888,
        total_orders: 763.04,
        neighborhood: 'Lower East Side',
        keywords: 'Vodka, Wine, Beer, Gin, Salad, Delicious, Hotel, Fun, Hot Toddy, Perfect',
        annual_dol: 837.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7132,
        store_longitude: -73.9847,
        total_orders: 1110.93,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegetarian, Vegan, Steak, Salad, Wine, Tasty, Margarita, Turkish, Mediterranean, Delicious',
        annual_dol: 1156.28,
        description:
            'Turkish & Mediterranean fare, including meze & signature lamb dishes, in a stylish, modern setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7681,
        store_longitude: -73.9858,
        total_orders: 52.07,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Salad, Coffee, American, Omelette, Casual, Classic, Authentic, Great',
        annual_dol: 212.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9796,
        total_orders: 139.5,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Premium, Spacious, American, Awesome, Great, British, Quaint, Excellent, Traditional',
        annual_dol: 570.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9924,
        total_orders: 16.92,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Vegetarian, Mexican, Wine, Brazilian, Dessert, Delicious, Ale, Australian, Classic',
        annual_dol: 69.15,
        description:
            'Laid-back restaurant preparing classic Brazilian specialties with Minas Gerais flavors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6794,
        store_longitude: -73.9824,
        total_orders: 3972.66,
        neighborhood: 'Gowanus',
        keywords: 'Karaoke, Korean, Beer, Wine, Tasty, Salad, Bbq, Delicious, Warm, Sweet',
        annual_dol: 4493.64,
        description:
            'Korean BBQ, noodles & other staples served in a casual, warm environment with private karaoke rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.9961,
        total_orders: 3408.95,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vodka, Margarita, Martini, Upscale, Sweet, Vibrant, Intimate, Amazing, Perfect, Fresh',
        annual_dol: 3408.95,
        description:
            'Gotham Comedy Club is a venue for stand-up comedy in New York City. The comedy club is located on 208 West 23rd Street between Seventh and Eighth avenues in Chelsea. Several movies and television shows have been filmed at the club.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7124,
        store_longitude: -73.9561,
        total_orders: 800.19,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Pizza, Burger, Vodka, Italian, Salad, Wine, Spicy, Caesar Salad, Spacious, Great',
        annual_dol: 854.83,
        description:
            'Detroit-style square pizzas, Italian sandwiches & signature burger in a spacious setting with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7072,
        store_longitude: -74.0024,
        total_orders: 1039.12,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Lobster, Steak, Shrimp Cocktail, Meatball, Beer, Margarita, Wine, Bourbon, Pinacolada',
        annual_dol: 1063.67,
        description:
            'Handsome place for oysters, fried seafood & sandwiches, along with beer & fresh cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7121,
        store_longitude: -73.9438,
        total_orders: 1564.48,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Zombie, Tequila, Beer, Coffee, Ale, Cozy, Romantic, Classy, Friendly',
        annual_dol: 1597.68,
        description:
            'Bar pairing craft drinks with meat & cheese plates, plus a fireplace-equipped patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9799,
        total_orders: 1458.45,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Gracious, Intimate, Classy, Friendly, Perfect, Fun, Chic, Great, Excellent, Casual',
        annual_dol: 1554.29,
        description:
            'Relaxed, intimate cocktail lounge with comfy banquettes, creative drinks & retro music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5922,
        store_longitude: -73.5812,
        total_orders: 144.12,
        neighborhood: '',
        keywords:
            'Garlic Bread, Ale, Wine, Salad, Tasty, Bbq, Warm, American, Friendly, Brownie Sundae',
        annual_dol: 150.36,
        description:
            'Cocktails & eclectic American pub fare served in a warm tavern with 18 TVs & a large patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7141,
        store_longitude: -73.9326,
        total_orders: 634.82,
        neighborhood: 'East Williamsburg',
        keywords: 'Exotic, Casual',
        annual_dol: 648.29,
        description: '"serving up booze and boobs for over 20 years"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 1970.51,
        neighborhood: 'Astoria/LIC',
        keywords: 'Sushi, Salad, Beer, Wine, Coffee, Italian, Dominican, Trendy, Latin, Japanese',
        annual_dol: 2357.54,
        description:
            '"We are a modern restaurant and lounge serving Dominican inspired sushi and Latin-Asian fusion dishes. We open Monday to Friday at 4 PM, with an amazing happy hour called the Bad Hours from 4-7. Saturday and Sunday we open for Brunch at 11AM and we close Monday to Thursday at 2 AM and on Friday to Sunday we close at 3 AM."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9215,
        total_orders: 494.11,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords: 'Wine, Beer, Spacious, Stylish, Irish, Great, Friendly, Casual',
        annual_dol: 832.65,
        description:
            '"Boutique bar serving tapas and craft cocktails. Our spacious backyard patio is covered and heated, open year round and available for parties & private events. Sister bar to Mosaic in Astoria!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7171,
        store_longitude: -73.9587,
        total_orders: 111.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Chinese, Crab Rangoon, Bbq, Fried Rice, Spicy, Karaoke, Asian, Casual, Traditional, Dim Sum',
        annual_dol: 455.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6572,
        store_longitude: -73.6375,
        total_orders: 714.59,
        neighborhood: '',
        keywords:
            'Sushi, Vegetarian, Pizza, Spicy, Gourmet, Wine, Italian, Delicious, Japanese, Chinese',
        annual_dol: 854.6,
        description:
            'Buzzy, bustling eatery dispensing Pan-Asian plates plus wine & other drinks in a sleek setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7787,
        store_longitude: -73.6498,
        total_orders: 1187.89,
        neighborhood: '',
        keywords:
            'Mexican, Italian, Wine, Pizza, Delicious, American, Swiss, Asian, Lively, Contemporary',
        annual_dol: 1326.72,
        description:
            'Large, lively dining room with a Tuscan feel & a wide-ranging menu of Italian & American dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -74.0067,
        total_orders: 214.17,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Steak, Salad, Tasty, Wine, Coffee, French Toast, French, Organic, Mediterranean',
        annual_dol: 282.17,
        description:
            'Laid-back diner serving contemporary American comfort fare, with many organic & gluten-free options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6507,
        store_longitude: -74.0048,
        total_orders: 24.62,
        neighborhood: 'Sunset Park',
        keywords: 'Beer, Wine, Burger, Mexican, Bourbon, Rum, Korean, Bbq, Classy, Relaxed',
        annual_dol: 100.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -74.0099,
        total_orders: 2679.6,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Salad, Wine, Dessert, French, Elegant, Cozy, American, Sleek, Modern',
        annual_dol: 2942.59,
        description:
            'Casually hip hangout offers an unpretentious American menu plus craft cocktails in a snug space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -74.0104,
        total_orders: 233.94,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegan, Vegetarian, Salad, Gluten Free, Tasty, Organic, Dessert, Greek, Mediterranean, Wholesome',
        annual_dol: 821.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6572,
        store_longitude: -73.8397,
        total_orders: 39.98,
        neighborhood: 'Far Rockaway/Howard Beach',
        keywords: 'Mexican, Salad, Organic, Healthy, Casual, Local, Fresh, Recommended',
        annual_dol: 224.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7533,
        store_longitude: -73.9768,
        total_orders: 423.52,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Tacos, Burger, Beer, Spanish, Wine, Martini, Hotel, Elegant, American, Polished',
        annual_dol: 1603.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9504,
        total_orders: 207.66,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Steak, Pizza, Italian, Meatball, Wine, Vodka, Delicious, Bruschetta, Phenomenal',
        annual_dol: 848.86,
        description:
            '"Welcome to Botte UES, an Italian restaurant specializing in Roman cuisine and traditional Italian cocktails located at 1606 1st Ave, New York, NY 10028. It is our pleasure to serve you delicious, inspired Italian dishes from Rome."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7837,
        store_longitude: -73.6494,
        total_orders: 15.87,
        neighborhood: '',
        keywords: 'Burger, Sushi, Pizza, Japanese, Asian, Modern, Delicious, Casual, Local, Fusion',
        annual_dol: 97.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -74.0097,
        total_orders: 5919.57,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Burger, Lobster, Salad, Chicken Caesar Salad, Cajun, Chocolate Mousse, Chilean, Canadian, Wine',
        annual_dol: 6045.18,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7202,
        store_longitude: -73.9967,
        total_orders: 316.09,
        neighborhood: 'Little Italy',
        keywords: 'Pizza, Italian, Salad, Rich, Beer, Ale, Karaoke, Classic, Great, Excellent',
        annual_dol: 351.68,
        description:
            'Old-school venue with dark wood & checkered tablecloths offering pub food & karaoke nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6567,
        store_longitude: -74.0065,
        total_orders: 301.83,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Steak, Pizza, Bbq, Italian, Chinese, Tangy, Vietnamese, Korean, Spicy, Jamaican',
        annual_dol: 807.95,
        description:
            '"We specialize in authentic, pit-smoked meats prepared in the classic Southern technique of smoking on oak wood. We also incorporate a variety of flavor profiles that represent our local communities. Hometown also serves up a selection of traditional sides and dessert. In classic barbecue style, we offer walk-up service to our counter on a first-come, first-served basis—until we sell out of that day’s specially cooked offerings."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6644,
        store_longitude: -73.9943,
        total_orders: 48.17,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Tequila, Mexican, Vodka, Pizza, Bourbon, Rum, Hawaiian, Tasty, American, Latin',
        annual_dol: 196.9,
        description:
            '"Serving authentic cocktails, margaritas , mojitos... We have a large variety of Mezcal & Tequila that you can paired with tasty Mexican Tapas."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9035,
        total_orders: 1512.05,
        neighborhood: 'Woodside',
        keywords:
            'Beer, Tasty, Sushi, Martini, Coffee, Avocado Toast, Delicious, Irish, Jamaican, Friendly',
        annual_dol: 1672.35,
        description:
            '"Honest cocktails, Craft beer, friendly service & a thoughtfully prepared kitchen menu."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9935,
        total_orders: 307.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Lasagna, Italian, Pizza, Wine, Cozy, Classy, Homey, Fabulous, Intimate, Hip',
        annual_dol: 343.82,
        description:
            'Homey Italian venue supplying housemade pastas and other traditional fare in a brick-lined space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.9912,
        total_orders: 79.95,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Mexican, Tacos, Beer, Salad, Burritos, Wine, Delicious, Keto, Casual, Local',
        annual_dol: 326.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9935,
        total_orders: 912.66,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Gin, Beer, Russian, Clean, Rum, Cool, Casual, Awesome, Fine, Great',
        annual_dol: 1020.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.5856,
        total_orders: 1517.91,
        neighborhood: '',
        keywords: 'Pizza, Wine, Dessert, Delicious, Beer, Nachos, Tex-Mex, Asian, Bbq, Sweet',
        annual_dol: 1550.11,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6477,
        store_longitude: -73.9976,
        total_orders: 995.88,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords:
            'Karaoke, Pizza, Vegan, Spicy, Wine, Juicy, Caribbean, Memorable, Fusion, Classic',
        annual_dol: 4070.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.5879,
        total_orders: 787.83,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Tasty, Bbq, Trendy, Yummy, Caesar Salad, Classy, American',
        annual_dol: 824.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.5877,
        total_orders: 397.98,
        neighborhood: '',
        keywords: 'Beer, Warm, Intimate, Cool, Great, Cultural',
        annual_dol: 460.81,
        description:
            'Concert hall and movie theater with live shows and 3D-screenings in warm decor with plush seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7104,
        store_longitude: -73.8494,
        total_orders: 1836.85,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Lobster, Burger, Salad, Japanese, Beer, French, Thai, Bbq, Homemade, Southern',
        annual_dol: 4989.98,
        description:
            "\"Dylan's is a bar and music venue specializing in Southern infused comfort foods. Dylan's includes such delights as buttermilk fried chicken, homemade honey biscuits, shrimp & jalapeño grits, Nashville coleslaw, and smash burgers. Hungry yet?! Our goal is to provide an immersive, warm, convivial family dining experience. An oasis from the day to day grind. Dylan's provides entertainment in a variety of genre’s from Zydeco to Blues, Jazz, Country, and Rock n Roll. We want to put Forest Hills on the map as a destination spot for food, music, and entertainment.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7425,
        store_longitude: -73.9158,
        total_orders: 25.17,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: 'Cool, Casual, Exotic, Fine, Local',
        annual_dol: 41.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7762,
        store_longitude: -73.9532,
        total_orders: 7806.17,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Scotch, Whiskey, Wine, Beer, Scottish, Irish, Cozy, Premium, Warm, Comfortable',
        annual_dol: 8977.77,
        description:
            'Narrow Scottish pub with a warm vibe featuring many whiskies, tap beers & elevated bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8293,
        store_longitude: -73.9284,
        total_orders: 1990.93,
        neighborhood: 'Concourse/Highbridge/West Bronx',
        keywords:
            'Steak, Salad, Coffee, American, Traditional, Exotic, Casual, Global, Fresh, Rare',
        annual_dol: 2215.12,
        description:
            'Rock ’n’ roll-themed chain with a high-energy vibe serving burgers & American classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7147,
        store_longitude: -73.9994,
        total_orders: 2371.58,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Sushi, Wine, Japanese, Beer, Spicy, Upscale, Romantic, Classy, Friendly, Fun',
        annual_dol: 2669.4,
        description:
            'Multilevel spot offering casual Japanese izakaya fare & a reservations-only omakase sushi bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7211,
        store_longitude: -74.0117,
        total_orders: 10497.46,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Lobster, Lobster Roll, Tacos, Beer, Italian, Wine, Margarita, Delicious, Rum',
        annual_dol: 13392.94,
        description:
            'City Winery spin-off with a roof deck for simple American food & wine with river & skyline views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7353,
        store_longitude: -74.0082,
        total_orders: 427.15,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Austrian, Wine, Romantic, Impeccable, Fabulous, Cozy, Global, American, Great, British',
        annual_dol: 456.55,
        description:
            'Lauded, art-filled Austrian eatery serving dishes like salmon strudel, Bavarian pretzels & desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.9891,
        total_orders: 105.72,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Salad, Meatball, Beer, Grilled Salmon, Homemade, Stylish, Casual, Local, Coleslaw, Traditional',
        annual_dol: 139.95,
        description:
            'Longtime kosher deli & diner chain serving sandwiches & Eastern European comfort foods like goulash.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8279,
        store_longitude: -73.6652,
        total_orders: 374.83,
        neighborhood: '',
        keywords: 'Coffee, Cultural, Complex',
        annual_dol: 401.37,
        description:
            '"The Amsterdam at Harborside is the first and only true life care retirement community in Nassau County, on Long Island, for active, independent seniors. Whatever ambitions you have for retirement, The Amsterdam at Harborside lets you accomplish them all with the long-term security and peace of mind of life care. Living at The Amsterdam turns ambition into reality."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7255,
        store_longitude: -74.005,
        total_orders: 922.5,
        neighborhood: 'Hudson Square',
        keywords:
            'Burger, Salad, Italian, Tasty, Delicious, Homemade, Yummy, Polished, Sleek, Modern',
        annual_dol: 942.08,
        description:
            'Modern takes on traditional Italian cooking in an airy, wood-accented space with a marble bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -74.0047,
        total_orders: 5860.42,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Vegetarian, Salad, Mexican, Coffee, Margarita, Tasty, Burritos, Enchiladas, Healthy, Latin',
        annual_dol: 6386.96,
        description:
            'Traditional Mexican menu of classic dishes, margaritas & coffee in a low-key corner dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7751,
        store_longitude: -73.8743,
        total_orders: 119.26,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Coffee, Wine, Beer, Ale, Pizza, Dessert, Hotel, American, Casual, Local',
        annual_dol: 487.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -74.0037,
        total_orders: 2170.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Fun, Picturesque, Awesome, Friendly, Lively, Colorful, Great, Laid-Back, Local',
        annual_dol: 2259.46,
        description:
            'Longtime lesbian & gay Village hangout with a jukebox, drink specials & colorful decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7378,
        store_longitude: -74.002,
        total_orders: 15898.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Italian, Wine, Spicy, Fancy, Sleek, American, Modern, Awesome, Terrific',
        annual_dol: 16588.18,
        description:
            'Updated Italian-American classics with wine & cocktails in a marble-accented space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7354,
        store_longitude: -74.0014,
        total_orders: 2336.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Italian, Wine, Vodka, Meatball, Margarita, Martini, Bruschetta, Rustic, Triple Sec',
        annual_dol: 2605.35,
        description:
            'Rustic, buzzy trattoria from Keith McNally with Italian fare & sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 495.05,
        neighborhood: '',
        keywords:
            'Wine, Beer, Coffee, Comfortable, Healthy, Delicious, Friendly, Casual, Classic, Seasonal',
        annual_dol: 527.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -74.006,
        total_orders: 518.64,
        neighborhood: 'Hudson Square',
        keywords:
            'Italian, Hotel, Coffee, Trendy, Fabulous, American, Classic, Modern, Perfect, Fantastic',
        annual_dol: 553.12,
        description:
            "Modern rooms come with free Wi-Fi and bottled water, plus flat-screen TVs, iPod docks and coffeemakers. Suites add separate living spaces and city views.Free coffee is available in the lobby. There's an airy restaurant offering classic Italian dishes and open-air dining. Other amenities include a 24-hour exercise room, a business center and an outdoor patio.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7348,
        store_longitude: -74.0023,
        total_orders: 72.37,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Mexican, Steak, Margarita, Delicious, American, Latin, Yummy, Fun, Friendly',
        annual_dol: 295.82,
        description:
            'Buzzy Southwestern restaurant known for its margaritas & all-you-can-drink weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7249,
        store_longitude: -73.5506,
        total_orders: 1459.99,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Beer, Whiskey, Salad, Nachos, French, Irish, American, Delicious',
        annual_dol: 1472.01,
        description:
            'Bustling hangout featuring wings & American pub grub served late, plus sports on TV in casual digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.708,
        store_longitude: -74.0089,
        total_orders: 1756.36,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Wine, Gourmet, Bourbon, Italian, Upscale, French, American, Classy, Relaxed',
        annual_dol: 1951.51,
        description:
            'Set on the 60th floor, this ritzy, high-end restaurant features New American cuisine & city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8036,
        store_longitude: -73.9201,
        total_orders: 5.36,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Burger, Hamburger, Salad, Milkshake, Tasty, American, Delicious, Rum, Fresh, Friendly',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7728,
        store_longitude: -73.9164,
        total_orders: 1747.31,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Burger, Steak, Vegetarian, Tacos, Gourmet, Wine, Irish, Yummy, American, Awesome',
        annual_dol: 1884.54,
        description:
            'This tavern offers global lunch & dinner menus in a warm, classic environment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.83,
        store_longitude: -73.6481,
        total_orders: 168.27,
        neighborhood: '',
        keywords: 'Vibrant, Refreshing, Classic',
        annual_dol: 205.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.6991,
        total_orders: 9548.23,
        neighborhood: '',
        keywords:
            'Mexican, Vegan, Salad, Gluten Free, Savory, Spicy, Delicious, Upscale, Fajitas, Latin',
        annual_dol: 9750.83,
        description:
            'Upscale Mexican cantina with a full bar, "Day of the Dead" theme & signature cotton candy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7511,
        store_longitude: -73.675,
        total_orders: 27.77,
        neighborhood: '',
        keywords:
            'Lobster, Chinese, Thai, Tandoori Chicken, Bbq, Asian, Paneer Tikka, Indian, Fusion, Casual',
        annual_dol: 113.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.704,
        store_longitude: -73.8768,
        total_orders: 7.45,
        neighborhood: 'Flushing/Glendale',
        keywords:
            'Steak, Cheesesteak, Gluten Free, Tasty, Beer, Roast Beef, Korean, Bbq, American, Philly Cheesesteak',
        annual_dol: 30.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7542,
        store_longitude: -73.9899,
        total_orders: 21.05,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Pizza, Steak, Salad, Hawaiian, Beer, Dessert, Perfect, Casual, Local',
        annual_dol: 86.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6606,
        store_longitude: -73.9804,
        total_orders: 3978.54,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'Burger, Hamburger, Beer, Salad, Tasty, Organic, Spicy, Cozy, Relaxed, American',
        annual_dol: 4062.95,
        description:
            'Neighborhood craft beer bar with rotating drafts & hearty fare in a corner, window-wrapped setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5766,
        store_longitude: -73.9824,
        total_orders: 1241.71,
        neighborhood: 'Coney Island',
        keywords:
            'Cheesecake, Salad, Pizza, Italian, Dessert, Shrimp Cocktail, Delicious, Filet Mignon, Casual, Traditional',
        annual_dol: 1273.37,
        description:
            'Tuxedo-clad waiters serve old-school Italian fare at this circa-1907 banquet hall-style eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5731,
        store_longitude: -73.9812,
        total_orders: 479.36,
        neighborhood: 'Coney Island',
        keywords:
            'Steak, Lobster, Mexican, Cheesesteak, Beer, Margarita, Tasty, Tex-Mex, Casual, Natural',
        annual_dol: 1380.7,
        description:
            'Open-air eatery on the boardwalk offering casual Mexican grub, signature cocktails & ocean views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6412,
        store_longitude: -73.9561,
        total_orders: 79.05,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Wine, Caribbean, Beer, Salad, Bbq, Spectacular, Zombie, Fried Chicken, Exceptional, Great',
        annual_dol: 168.48,
        description:
            'Serving Caribbean-inspired small plates, this relaxed wine bar boasts a back patio & hosts events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7335,
        store_longitude: -73.955,
        total_orders: 5276.07,
        neighborhood: 'Greenpoint',
        keywords: 'Coffee, Wine, Beer, American, Cozy, Polish, Classy, Natural, Chic, Casual',
        annual_dol: 5388.02,
        description:
            'Cozy coffee shop with a rear garden & light bites that turns into a hip, low-key bar at night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8539,
        store_longitude: -73.8892,
        total_orders: 315.68,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Chicken Parmigiana, Flavorful, Wine, Vodka, Italian, Meatball, Bruschetta, Southern, Fabulous, Casual',
        annual_dol: 345.29,
        description:
            'Neighborhood staple & post-Yankee-game favorite with classic Italian red-sauce dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.966,
        total_orders: 277.85,
        neighborhood: 'Lenox Hill',
        keywords: 'Mexican, Tacos, Beer, Wine, Delicious, Healthy, Local, Casual, Great',
        annual_dol: 321.2,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.9862,
        total_orders: 6222.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Whiskey, Elegant, Delicious, Cozy, Italian, American, Irish, Awesome, Great, Casual',
        annual_dol: 22637.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7524,
        store_longitude: -73.9894,
        total_orders: 5209.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Trendy, Vibrant, Cozy, Urban, Funky, Rooftop, Great, Authentic, Excellent',
        annual_dol: 5319.87,
        description:
            "Compact rooms with contemporary decor and industrial accents offer free Wi-Fi, flat-screen TVs and Bluetooth speakers. Some have subway-tiled walls, in-room steel sinks and bunk beds, while suites add living rooms with sofas.Free fitness classes are offered in a gym. There's a rooftop area with a chic bar/lounge, skyline views, nightly DJs and mini-golf. Other amenities include a seafood brasserie and a vibrant lobby bar, plus a cafe and a sandwich shop.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9918,
        total_orders: 2312.43,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Wine, Stylish, Elegant, Trendy, Iconic, Sleek, Innovative, Urban, Vintage',
        annual_dol: 2722.06,
        description:
            'The polished rooms feature free Wi-Fi, flat-screen TVs and coffeemakers, in addition to minifridges. Room service is offered.Breakfast and parking are available (fee). Other amenities include a stylish, industrial-chic restaurant, a gym and a business center. A 2-story rooftop bar offers views of the city.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -73.9212,
        total_orders: 100.34,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Korean, Beer, Coffee, Spicy, Bbq, Friendly, Local, Traditional, Casual, Great',
        annual_dol: 410.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7354,
        store_longitude: -74.0058,
        total_orders: 3132.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Wine, Seasonal, Casual, Cool, Perfect, Local, Classic, Great, Fantastic',
        annual_dol: 3132.91,
        description:
            "No-frills saloon with an internal ATM, since it's cash only, & a jukebox stocked with classic rock.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7356,
        store_longitude: -74.0062,
        total_orders: 3296.17,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Tequila, Salad, Vodka, Beer, Gin, Avocado Toast, French Onion Soup, French, Danish',
        annual_dol: 3522.38,
        description:
            "Circa-1880 bar with a storied history as a writers' hangout, serving drinks & bar fare.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7398,
        store_longitude: -74.0064,
        total_orders: 2745.31,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Wine, Steak, Italian, Upscale, Delicious, Sophisticated, Gyoza, Artisanal, Great, Signature',
        annual_dol: 2826.52,
        description:
            '"Mollusca NYC is a spirited and sophisticated seafood restaurant with influences from around the world."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6656,
        store_longitude: -73.6656,
        total_orders: 67.84,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Meatball, Wine, Delicious, Local, Casual, Wonderful, Authentic, Classic',
        annual_dol: 81.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7856,
        store_longitude: -73.9767,
        total_orders: 3209.74,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Cheesesteak, Salad, Beer, Coffee, Dessert, Wine, Spicy, Ale',
        annual_dol: 3816.03,
        description:
            'Half bar, half casual American burger joint in a below-ground setting with dog photos on the wall.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7832,
        store_longitude: -73.9788,
        total_orders: 122.35,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Mexican, Beer, Wine, Salad, Spicy, Dessert, Delicious, Rum, Mediterranean',
        annual_dol: 460.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7854,
        store_longitude: -73.9769,
        total_orders: 202.51,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Ramen, Beer, Japanese, Spicy, Sukiyaki, Classic, Cozy, Local, Casual, Great',
        annual_dol: 827.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7041,
        store_longitude: -73.9306,
        total_orders: 80.01,
        neighborhood: 'East Williamsburg',
        keywords: 'Tequila, Vodka, Vegetarian, Beer, Salad, Upscale, Elegant, Rum, Vintage, French',
        annual_dol: 327.04,
        description:
            'Art deco cocktail bar with muted lighting, craft beer & a late-night menu of upscale eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6648,
        store_longitude: -73.5524,
        total_orders: 10.76,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Meatball, Coffee, Dessert, Margarita, Caesar Salad, Homemade, Fresh',
        annual_dol: 14.25,
        description: '"San Marzano Wood Fired Brick Oven Pizza & Hand Made Fresh Pasta"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -73.9578,
        total_orders: 2096.38,
        neighborhood: 'Greenpoint',
        keywords: 'Whiskey, Wine, Beer, Bourbon, Ale, Tasty, American, Premium, Eclectic, Cozy',
        annual_dol: 2140.86,
        description:
            'More than 250 American whiskeys line the bar at this corner spot also serving craft beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7734,
        store_longitude: -73.964,
        total_orders: 424.84,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Pizza, Salad, Wine, Savory, Coffee, Delicious, Upscale, Trendy, Japanese',
        annual_dol: 1731.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7263,
        store_longitude: -73.952,
        total_orders: 620.14,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Beer, Chinese, Martini, Margarita, Spicy, Vintage, Classic, Awesome, Great',
        annual_dol: 633.3,
        description: '"Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -74.0021,
        total_orders: 16533.14,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Whiskey, Salad, Avocado Toast, Spicy, Magical, Spacious, Spanish, American',
        annual_dol: 17324.76,
        description:
            'American classics, burgers, whiskey & cocktails in a neighborhood gastropub with a throwback vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6303,
        store_longitude: -74.1087,
        total_orders: 1482.51,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Steak, Mexican, Pizza, Salad, Nachos, Beer, Margarita, Tex-Mex, Delicious',
        annual_dol: 1531.33,
        description:
            'Located in a converted house, this casual spot offers Mexican fare & drinks amid 1960s decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8692,
        store_longitude: -73.8258,
        total_orders: 79.95,
        neighborhood: 'East Bronx/Baychester',
        keywords: 'Mexican, Vegan, Vegetarian, Salad, Gluten Free, Organic, Casual, Local, Healthy',
        annual_dol: 388.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 255.57,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Gluten Free, Italian, Dessert, Meatball, Wine, Delicious, Warm, Polished',
        annual_dol: 658.57,
        description:
            'Emilia-Romagna cuisine in a warm setting, plus cocktails & a robust Italian wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6944,
        store_longitude: -73.9024,
        total_orders: 639.6,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Burger, Tacos, Mexican, Hamburger, Beer, Milkshake, Salad, Spicy, Delicious, Bbq',
        annual_dol: 653.17,
        description:
            'Chill cocktail bar featuring global bites & weekend brunch amid retro-toy decor & colorful murals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7019,
        store_longitude: -73.9035,
        total_orders: 224.92,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Salad, Wine, Dessert, Ale, Spicy, Cozy, Romantic, Latin, Clean, American',
        annual_dol: 614.6,
        description:
            'Easygoing venue featuring a cocktail bar & a wood-fired grill, plus a daytime cafe with baked goods.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6993,
        store_longitude: -73.9267,
        total_orders: 456.75,
        neighborhood: 'Bushwick',
        keywords:
            'Wine, Gin, French, Vietnamese, Cozy, Delicious, Mediterranean, Seasonal, Classy, American',
        annual_dol: 489.36,
        description:
            'Seasonal French dishes, artisanal cocktails & European wines offered in a modern, casual setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7206,
        store_longitude: -74.005,
        total_orders: 795.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Whiskey, Burger, Hamburger, Beer, Salad, Irish, Upscale, French, Comfortable, Classic',
        annual_dol: 827.98,
        description:
            'No-frills tavern providing low-priced drinks along with bar food, jukebox & shuffleboard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7169,
        store_longitude: -74.0083,
        total_orders: 406.49,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Steak, Hamburger, Salad, Mexican, Tasty, Wine, Margarita, French Toast, Upscale',
        annual_dol: 424.11,
        description:
            'American bistro fare & Sunday brunch highlight this casual, convivial spot with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7172,
        store_longitude: -74.0093,
        total_orders: 516.43,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Champagne, Salad, Vegetarian, Italian, Dessert, Delicious, Ecuadorian, Cozy, Warm',
        annual_dol: 527.39,
        description:
            'French-influenced Italian fixed-price menus in an elegant, vaulted interior with Tuscan touches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7724,
        store_longitude: -73.9555,
        total_orders: 970.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Pizza, Italian, Wine, South American, Upscale, American, Classy, Authentic, Balanced',
        annual_dol: 1022.21,
        description:
            'Longtime eatery with an old-world vibe featuring pastas, fish & meats, plus a lengthy wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7723,
        store_longitude: -73.9556,
        total_orders: 978.8,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Steak, Hamburger, Salad, Beer, Dessert, Wine, Nachos, Bbq, Irish',
        annual_dol: 999.56,
        description:
            'American bar fare & an Irish pub feel along with live traditional Irish music & sports on the TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.8452,
        total_orders: 694.56,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Salad, Wine, Upscale, Organic, French, Beer, Bbq, French Onion Soup, Trendy',
        annual_dol: 740.2,
        description:
            'Local steakhouse offering grilled meats plus sides, wines & cocktails in a wood-lined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8306,
        store_longitude: -73.6898,
        total_orders: 284.96,
        neighborhood: '',
        keywords: 'Beer, Delicious, Healthy, Cozy, Irish, Perfect, Cool, Casual, American, Great',
        annual_dol: 303.91,
        description: '"Cocktail Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.855,
        total_orders: 287.57,
        neighborhood: 'Flushing/Corona',
        keywords: 'Hotel, Modern, Clean, Great',
        annual_dol: 1088.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -74.005,
        total_orders: 10033.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Champagne, Coffee, Shrimp Cocktail, Hotel, Gourmet, Trendy, Stylish, American, Retro',
        annual_dol: 10129.66,
        description:
            "Featuring chic mid-century modern decor, the hip rooms have minibars and Wi-Fi, plus smart TVs, DVD players and iPods; suites add iMacs. Guests can request an in-room goldfish.There's a cafe, a trendy lounge and an oyster bar, plus a basement jazz club serving cocktails and small plates. Other amenities include a private movie theater, a fitness room and 3 meeting rooms. Breakfast and valet parking are available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.5886,
        total_orders: 1896.78,
        neighborhood: '',
        keywords: 'Salad, Italian, Pizza, Dessert, Wine, Organic, Quaint, Warm, Modern, Friendly',
        annual_dol: 1949.67,
        description:
            'Italian fare served in large portions for over 25 years at this lively trattoria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7236,
        store_longitude: -74.0097,
        total_orders: 689.98,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Hotel, Warm, Sophisticated, Classic, Great, Chic, Excellent, Cool, French, Signature',
        annual_dol: 2820.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7724,
        store_longitude: -73.9559,
        total_orders: 208.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Mexican, Beer, Meatball, Nachos, Burritos, Salad, Quesadillas, Spicy, Trendy, Yummy',
        annual_dol: 254.62,
        description:
            'Simple Mexican tacos, burritos & quesadillas plus booze served amid contemporary decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7753,
        store_longitude: -73.9567,
        total_orders: 172.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Chinese, Spicy, Martini, Delicious, Upscale, Beer, Juicy, Bbq, Peking Duck',
        annual_dol: 175.68,
        description:
            'Taiwanese-style cooking with a pig-centric menu, plus kitschy pink decor to match the theme.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7461,
        store_longitude: -73.915,
        total_orders: 257,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: 'Coffee, Beer, Wine, Shrimp Cocktail, Quaint, Cool, Fresh, Great, Casual, Unique',
        annual_dol: 274.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8089,
        store_longitude: -73.9306,
        total_orders: 1244.27,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Burger, Pizza, Beer, Coffee, Wine, Healthy, Great, Casual, Creative, Signature',
        annual_dol: 5086.17,
        description:
            '"The Bruckner Building provides Office Space in the heart of Mott Haven. The Bruckner building is the new standard of excellence in the South Bronx. We have units sized for any type of business ranging from 1,000-20,000 SF. Feel free to contact us for office space."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7743,
        store_longitude: -73.8746,
        total_orders: 219.38,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Burger, Lobster, Lobster Roll, Pizza, Shrimp Cocktail, Salad, Beer, Coffee, Wine, Spanish',
        annual_dol: 896.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.705,
        store_longitude: -74.0088,
        total_orders: 127.24,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Lobster Tail, Burger, Shrimp Cocktail, Salad, Meatball, Greek, Mediterranean, Wine, Organic',
        annual_dol: 787.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7082,
        store_longitude: -73.6252,
        total_orders: 104.65,
        neighborhood: '',
        keywords: 'Pizza, Italian, Casual, Sweet',
        annual_dol: 394.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8267,
        store_longitude: -73.9391,
        total_orders: 81.13,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Beer, Wine, Caribbean, Delicious, Jamaican, Cozy, Friendly, Great, Traditional, Excellent',
        annual_dol: 306.45,
        description:
            '"Keeping live jazz in Harlem. Cozy atmosphere for food drink and jazz. Join us for Thursday Happy Hour, Friday Night for our Jazz Jam and again on Saturdays for our live events. Relax n Sundays with our Jazz Brunch."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6756,
        store_longitude: -73.9812,
        total_orders: 657.95,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Cajun, Spicy, Juicy, Rich, Homey, Modern, Southern',
        annual_dol: 769.41,
        description:
            'Homey spot serving bags of crab, lobster & more shaken with spices & paired with Southern sweet tea.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6659,
        store_longitude: -73.989,
        total_orders: 75.02,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Steak, Tacos, Hamburger, Mexican, Cajun, Salad, Beer, Wine, Ale',
        annual_dol: 306.65,
        description:
            'Warm hangout with a patio dishing up casual global fare from tacos to pasta, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7235,
        store_longitude: -73.9508,
        total_orders: 9081.63,
        neighborhood: 'Greenpoint',
        keywords:
            'Japanese, Dessert, Delicious, Stylish, Mediterranean, Ramen, Exotic, Classic, Global, Great',
        annual_dol: 10328.87,
        description:
            'Tucked behind a ramen restaurant, a hip spot with Japanese small plates & exotic craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7239,
        store_longitude: -73.951,
        total_orders: 1833.89,
        neighborhood: 'Greenpoint',
        keywords: 'Steak, Salad, Milkshake, Coffee, Beer, Upscale, Bbq, Friendly, Fun, Vintage',
        annual_dol: 2660.62,
        description:
            'Listening bar serving up vinyl tracks on a vintage turntable plus burgers, beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7224,
        store_longitude: -73.9498,
        total_orders: 95.91,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Burger, Tacos, Mexican, Margarita, Delicious, Wine, Trendy, Phenomenal, Ale, Organic',
        annual_dol: 392.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6147,
        store_longitude: -73.9364,
        total_orders: 99.68,
        neighborhood: 'Marine Park',
        keywords: 'Burger, Pizza, Steak, Italian, Wine, Fun, American, Great, Wonderful, Excellent',
        annual_dol: 122.57,
        description:
            '"Established in 2009 & located in the heart of Marine Park, Brooklyn NY, 3rd&7 has become the premier venue for sports, events, fun and all around happiness. We\'ve taken pride in our excellent service and wonderful patrons who visit us everyday."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5916,
        store_longitude: -74.1011,
        total_orders: 1900.67,
        neighborhood: 'Todt Hill',
        keywords: 'Wine, Dessert, Italian, Mexican, Beer, Trendy, Yummy, Classy, Comfortable, Fun',
        annual_dol: 2004.69,
        description:
            'Energetic restaurant offering Italian specialties, wines & live music in comfortable surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6559,
        store_longitude: -73.6453,
        total_orders: 1188.24,
        neighborhood: '',
        keywords: 'Burger, Beer, Cajun, Whiskey, Ale, Nachos, Bbq, American, Spicy, Delicious',
        annual_dol: 1260.47,
        description:
            'Outpost of a local alehouse chain featuring a selection of craft beers, pub grub & wing specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8227,
        store_longitude: -73.7033,
        total_orders: 989,
        neighborhood: '',
        keywords: 'Upscale, Elegant, Intimate, Spectacular, Great, Fantastic, Exceptional, Casual',
        annual_dol: 1053.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9945,
        total_orders: 419.54,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Lasagna, Pizza, Italian, Chicken Parmigiana, Wine, Beer, Delicious, Margarita, Rustic',
        annual_dol: 479.25,
        description:
            'Casual choice for rustic Sicilian cuisine paired with cocktails featuring Italian ice.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9836,
        total_orders: 127.09,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Italian, French, Mediterranean, Elegant, American, Contemporary, Premium, Modern, High-End',
        annual_dol: 144.74,
        description:
            'Theater District.The understated rooms have wood accents and marble bathrooms, as well as free Wi-Fi, flat-screen TVs and coffeemakers. Suites, all on upper floors, have kitchens and city views.A contemporary bar serves casual food and drink, while a chic restaurant offers Mediterranean fine dining. Other amenities include a luxury spa, a 24-hour fitness center and a hair salon, plus 7,500 sq ft of event space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8445,
        store_longitude: -73.6956,
        total_orders: 160.83,
        neighborhood: '',
        keywords: 'Polish, American, Wine, Cultural, Classic, Great',
        annual_dol: 197.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8288,
        store_longitude: -73.7018,
        total_orders: 40.87,
        neighborhood: '',
        keywords: 'Hotel, Intimate, Excellent',
        annual_dol: 167.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.9761,
        total_orders: 13.45,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Tacos, Salad, Wine, Coffee, Beer, Gin, Delicious, Sustainable, Eclectic',
        annual_dol: 54.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7811,
        store_longitude: -73.8412,
        total_orders: 10.12,
        neighborhood: 'College Point/Flushing',
        keywords: 'Karaoke, Beer, Chinese, Casual',
        annual_dol: 41.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6351,
        store_longitude: -74.0202,
        total_orders: 47,
        neighborhood: 'Bay Ridge',
        keywords: 'Delicious, Fresh, Great, Casual',
        annual_dol: 62.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7902,
        store_longitude: -73.9727,
        total_orders: 234.37,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Steak, Salad, Vegan, Mexican, Nachos, Burritos, Margarita, Bbq, Spanish, Casual',
        annual_dol: 574.18,
        description:
            'Bustling Mexican option serving up a menu of margaritas, tacos, nachos & giant burritos.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7431,
        store_longitude: -73.921,
        total_orders: 32.45,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Salad, Spicy, Korean, Coffee, Delicious, Bbq, Yummy, Hotel, Edgy, Mac And Cheese',
        annual_dol: 132.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7092,
        store_longitude: -73.8699,
        total_orders: 256.48,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords:
            'Sushi, Salad, Steak, Japanese, Spicy, Chinese, Casual, Traditional, Authentic, Local',
        annual_dol: 900.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7067,
        store_longitude: -73.9056,
        total_orders: 201.81,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Steak, German, Beer, Wine, Roast Beef, Fun, American, Awesome, Amazing, Wonderful',
        annual_dol: 210.56,
        description:
            'Long-standing tavern serving domestic & imported beers alongside a small menu of German pub eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.6734,
        total_orders: 41.9,
        neighborhood: '',
        keywords:
            'Burger, Sushi, Hamburger, Salad, Gourmet, Beer, Delicious, Trendy, American, Healthy',
        annual_dol: 171.26,
        description:
            'Laid-back outpost offering typical American bar cuisine alongside craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.699,
        store_longitude: -73.909,
        total_orders: 102.28,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Burger, Tacos, Steak, Salad, Vegan, Mexican, Dessert, Margarita, Delicious, Fajitas',
        annual_dol: 418.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6753,
        store_longitude: -73.9814,
        total_orders: 539.06,
        neighborhood: 'Park Slope',
        keywords:
            'Salad, Wine, Italian, Cozy, Delicious, Quaint, Comfortable, Old Fashioned, Classy, Vintage',
        annual_dol: 613.95,
        description:
            'Northern Italian mainstay with an intimate, old-world-inspired dining room & an attached wine bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6774,
        store_longitude: -73.9863,
        total_orders: 662.64,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Wine, Cozy, Friendly, Perfect, Ample, Classic, Great, Fantastic, Casual',
        annual_dol: 691.37,
        description:
            'This mellow haunt serves cocktails, brews & wines amid exposed brick walls & an old-school feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6779,
        store_longitude: -73.9859,
        total_orders: 976.18,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Ale, Hip, Friendly, Classic, Casual, Local, Great, Chill',
        annual_dol: 1018.51,
        description:
            'Wood-paneled neighborhood tavern with a chill vibe, handful of draft beers & pet-friendly policy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7254,
        store_longitude: -73.9462,
        total_orders: 1780.83,
        neighborhood: 'Greenpoint',
        keywords: 'Sushi, Beer, Cozy, Charming, Bourbon, Fun, Clean, Perfect, Colorful, Casual',
        annual_dol: 1856.35,
        description:
            'Green velvet booths & stained glass add to the charming art nouveau vibe at this chic cocktail bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7076,
        store_longitude: -73.9434,
        total_orders: 894.6,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Salad, Wine, Beer, Fun, Memorable, Great, Fine, Casual, Local',
        annual_dol: 1217.83,
        description:
            '"Eris is a restaurant, art, performance, and music event space with full bar, Funktion One sound, and an immersive, genuine experience of local artists and community."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.695,
        store_longitude: -73.9314,
        total_orders: 1274.42,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Margarita, Hotel, Ale, Fun, Vintage, Classy, Friendly, Classic',
        annual_dol: 1301.47,
        description:
            '"open 7 days - cold beer - frozen cocktails - natural wine - cheap happy hour - private patio open late"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6248,
        store_longitude: -74.132,
        total_orders: 98.46,
        neighborhood: 'Westerleigh/Mid Island',
        keywords:
            'Italian, Pizza, Wine, Beer, Delicious, Spanish, Classy, Fantastic, Great, Casual',
        annual_dol: 325.52,
        description: '"Italian Restaurant and Catering Hall"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7255,
        store_longitude: -73.9447,
        total_orders: 2328.79,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Beer, Cozy, Local, Casual, Great',
        annual_dol: 2378.2,
        description:
            'Simple pub grub & cocktails served in an old-timey setting with wood beams & stained-glass windows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7079,
        store_longitude: -73.9437,
        total_orders: 99.28,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Salad, Venezuelan, Gin, Spanish, Uruguayan, American, Farm-To-Table, Latin, Stylish, Delicious',
        annual_dol: 173.65,
        description:
            'Elevated takes on classic Venezuelan plates are served in a stylish space with an intimate vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7661,
        store_longitude: -73.957,
        total_orders: 99.73,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Mexican, Tacos, Beer, Salad, Burritos, Wine, Hotel, Western, Local, Casual',
        annual_dol: 407.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7035,
        store_longitude: -74.0118,
        total_orders: 2416.92,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Rooftop, Italian, Trendy, Coffee, Hotel, Mediterranean, Delicious, Warm, Beer',
        annual_dol: 4939.78,
        description:
            'Warm Italian restaurant with a homespun rooftop bar & city views, plus private booths & pods.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -74.0064,
        total_orders: 585.91,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Coffee, Avocado Toast, Savory, French, Australian, Delicious, American, Quaint, Hotel',
        annual_dol: 797.61,
        description:
            'Bakery/cafe branch providing sweets, French & American dishes, wines & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -74.0061,
        total_orders: 188.49,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Mexican, Rich, Italian, Seasonal, Comfortable, Fun, Casual, Greek, American',
        annual_dol: 797.7,
        description:
            '"One White Street is a neighborhood restaurant in downtown New York City from Chef Austin Johnson and Master Sommelier Dustin Wilson. Located in a historic Tribeca townhouse, One White Street is a place to feel at home. One White Street’s menu evolves with the seasons and is informed by exceptional ingredients organically grown and sourced year-round from Rigor Hill Farm in Columbia County, New York."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.5723,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Korean, Bbq, Kbbq, Asian, Wine, Fusion, Classy, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6874,
        store_longitude: -73.8205,
        total_orders: 48.26,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Delicious, Fun, Polish, Polished, Gourmet, Premium, Friendly, Caribbean, Intimate, Zen',
        annual_dol: 84.94,
        description:
            'Polished Caribbean lounge offering cocktails, light bites & hookah till the wee hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -73.9223,
        total_orders: 1035.28,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Burger, Vodka, Wine, Salad, Gin, Contemporary, American, Modern, Urban',
        annual_dol: 1095.57,
        description:
            'This modern gastropub with garden seating offers creative drinks & New American grub, plus brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7717,
        store_longitude: -73.8666,
        total_orders: 1054,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Hotdog, Burger, Pizza, Coffee, Spanish, American, Dominican, Hotel, Western, Casual',
        annual_dol: 4308.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7076,
        store_longitude: -73.9209,
        total_orders: 913.46,
        neighborhood: 'Bushwick',
        keywords: 'Hamburger, Rooftop, American, Beer, Wine, Hotel, Asian, Fusion, Awesome, Great',
        annual_dol: 1023.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7058,
        store_longitude: -73.9239,
        total_orders: 6375.5,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Tequila, Vegan, Beer, Vegetarian, Whiskey, Painkiller, Delicious, Warm, Cozy',
        annual_dol: 6510.78,
        description:
            'Cozy watering hole boasting a fireplace patio, plus cocktails, beer in cans & bottles & munchies.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7405,
        store_longitude: -74.0069,
        total_orders: 938.17,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Salad, Tasty, Gluten Free, Wine, Delicious, Trendy, Upscale, Spanish, Elegant',
        annual_dol: 992.29,
        description:
            'Upscale restaurant & bar serving seasonal Mediterranean meals prepared with flavored olive oils.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7327,
        store_longitude: -74.0036,
        total_orders: 984.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Italian, Gourmet, Beer, Wine, Savory, Delicious, Bourbon, Premium, American',
        annual_dol: 1046.2,
        description:
            'Italian eatery & wine bar for salads, meats, cheeses & larger plates, with a grocery section too.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6494,
        store_longitude: -73.6762,
        total_orders: 919.58,
        neighborhood: '',
        keywords: 'Sushi, Salad, Chilean, Wine, Japanese, Beer, Chinese, Sashimi, Ramen, Organic',
        annual_dol: 1134.39,
        description:
            'Easygoing local Japanese restaurant featuring sushi menus & hibachi dining with tableside chefs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8488,
        store_longitude: -73.8543,
        total_orders: 34.47,
        neighborhood: 'Morris Park',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Meatball, French, Delicious, Bruschetta, Great, Casual',
        annual_dol: 40.17,
        description:
            'Longtime family-owned bar & eatery offering pizza & other Italian dishes in a casual dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -74.003,
        total_orders: 8.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Vegetarian, Mexican, Salad, Chinese, Casual, Local, Eclectic, Superb, Excellent',
        annual_dol: 33.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7838,
        store_longitude: -73.9776,
        total_orders: 609.6,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Tomato Soup, Dessert, Fruity, Coffee, Velvety, Spicy, Warm, Hotel, Mediterranean',
        annual_dol: 637.94,
        description:
            'Posh chain restaurant serving American fare, including brunch, dinner & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.786,
        store_longitude: -73.9724,
        total_orders: 187.89,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Hotdog, Salad, Beer, Dessert, Mediterranean, American, French, Homemade, Swiss, Retro',
        annual_dol: 247.5,
        description:
            'Understated diner serving breakfast, meat dishes, pasta & burgers, plus beer & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.6565,
        total_orders: 29.63,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Indian, Casual, High-Protein, Authentic',
        annual_dol: 151.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -73.8458,
        total_orders: 39.98,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Mexican, Tacos, Beer, Burritos, Wine, Organic, Warm, Local, Casual, Healthy',
        annual_dol: 194.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6999,
        store_longitude: -73.9067,
        total_orders: 174.14,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Salad, Mexican, Dessert, Beer, Upscale, Ale, Spicy, French, Classy, American',
        annual_dol: 189.51,
        description:
            'Upscale restaurant featuring classic American meals, cocktails & brunch in calm, understated digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6713,
        store_longitude: -73.9777,
        total_orders: 39.98,
        neighborhood: 'Park Slope',
        keywords: 'Mexican, Tacos, Burritos, Local, Casual',
        annual_dol: 224.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.699,
        store_longitude: -73.9265,
        total_orders: 391.51,
        neighborhood: 'Bushwick',
        keywords:
            'Beer, Burger, Mexican, Wine, Tasty, Colombian, Uruguayan, Warm, Cozy, Mediterranean',
        annual_dol: 747.43,
        description:
            'Warm setting for Colombian-European dinners with Basque accents, plus beer, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6587,
        store_longitude: -73.6448,
        total_orders: 1909.07,
        neighborhood: '',
        keywords:
            'Steak, Burger, Hamburger, Salad, Mexican, Vodka, Dessert, Nutritious, Italian, Delicious',
        annual_dol: 1949.57,
        description:
            'Modern New American spot serving hearty fare & often drawing lively crowds on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6781,
        store_longitude: -73.7469,
        total_orders: 737.83,
        neighborhood: 'Laurelton/Jamaica/Springfield Gardens',
        keywords:
            'Lobster Roll, Lobster, Burger, Salad, Red Velvet Cake, Dessert, Wine, Bbq, Juicy, Caribbean',
        annual_dol: 2593.99,
        description: '"Casual dining seafood restaurant with a Caribbean flair"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6175,
        store_longitude: -73.9321,
        total_orders: 400.66,
        neighborhood: 'Marine Park',
        keywords:
            'Italian, Shrimp Cocktail, Pizza, Wine, Homemade, Rack Of Lamb, Casual, Fun, Perfect, Exceptional',
        annual_dol: 414.36,
        description:
            'White-tablecloth Italian institution serving veal, rack of lamb & seafood, plus pasta dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7536,
        store_longitude: -73.9912,
        total_orders: 460.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Champagne, Sustainable, Sweet, Perfect, Premium, Incredible, Modern',
        annual_dol: 513.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7458,
        store_longitude: -73.9151,
        total_orders: 335.76,
        neighborhood: 'LIC/Sunnyside/Woodside',
        keywords:
            'Hotdog, Tacos, Steak, Mexican, Tasty, Margarita, Spicy, Wine, Delicious, Colombian',
        annual_dol: 342.89,
        description:
            'Cozy eatery offering creative small plates, tacos & burgers, plus a bar & open-air dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6222,
        store_longitude: -73.9036,
        total_orders: 90.61,
        neighborhood: 'Bergen Beach',
        keywords: 'Cozy, Fun, Great',
        annual_dol: 94.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6739,
        store_longitude: -73.6263,
        total_orders: 1272.83,
        neighborhood: '',
        keywords: 'Beer, Golfclub, Comfortable, Ample, Great, Amazing, Seasonal, Recommended',
        annual_dol: 2155.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8189,
        store_longitude: -73.8173,
        total_orders: 580.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Perfect, Cool, Casual, Sweet, Legendary, Great, Local',
        annual_dol: 604.33,
        description: '"Bar & Lounge for Neighbors, Friends and Sports Enthusiasts"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6407,
        store_longitude: -73.6599,
        total_orders: 81.89,
        neighborhood: '',
        keywords: 'Irish, Bold, Lobster, Tasty, Fantastic, American, Mead, Great, Reuben, Friendly',
        annual_dol: 97.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7524,
        store_longitude: -73.9903,
        total_orders: 1665.83,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Salad, Tasty, Beer, Margarita, Bbq, Spicy, French Onion Soup, Mediterranean',
        annual_dol: 1701.17,
        description:
            'Vibrant pub with a large oak bar, TVs & occasional live Celtic music, serving Irish & American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6586,
        store_longitude: -73.6413,
        total_orders: 288.98,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Turkey Sandwich, Coffee, Warm, Comfortable, Clean, Fun, Welcoming, Modern',
        annual_dol: 288.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8258,
        store_longitude: -73.8215,
        total_orders: 150.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Burger, Beer, Fun, Cozy, Legendary, Awesome, Friendly, Perfect, American, Great',
        annual_dol: 719.21,
        description:
            '"We provide a cozy indoor space with an ambiance that allows friends, wanderers, and explorers to share a drink as equals. You\'ll be charmed with every sip of our professionally crafted drinks menu."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7758,
        store_longitude: -73.6334,
        total_orders: 1745.87,
        neighborhood: '',
        keywords: 'Luxurious, Opulent, Distinct',
        annual_dol: 1891.3,
        description:
            '"Unveil the curtain to Long Island’s opulent past, and discover, at the heart of exclusive Roslyn Heights, The Royalton. This colonial mansion of the early 20th century stands on 10 acres of wooded arbor and English gardens restored to a magnificence matched only by the level of grandeur of each catered affair. The Royalton is the Long Island Wedding Mansion of choice."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9982,
        total_orders: 4445.53,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Vegetarian, Vegan, Salad, Gluten Free, Meatball, Italian, French Toast, French, American',
        annual_dol: 4445.53,
        description:
            'Contemporary spot specializing in seasonal, locally sourced & mostly gluten-free comfort classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7516,
        store_longitude: -73.9874,
        total_orders: 1945.3,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Hotel, Elegant, Iconic, Fabulous, Classic, Memorable, Comfortable, Exquisite, Classy',
        annual_dol: 2070.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7256,
        store_longitude: -74.0074,
        total_orders: 133.97,
        neighborhood: 'Hudson Square',
        keywords: 'Pizza, Wine, Italian, Beer, Tasty, Homemade, Classy, Intimate, Fusion, Tiramisu',
        annual_dol: 168.25,
        description:
            'Intimate eatery offering Emilia-Romagna dishes, outdoor seating & private dining in a wine cellar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7194,
        store_longitude: -73.8423,
        total_orders: 6826.69,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Steak, Lobster, Mexican, Beer, Chili Con Carne, Tasty, Margarita, Coffee, Vodka',
        annual_dol: 13022.76,
        description:
            'Lively local restaurant serving strong margaritas & Mexican fare in a small, funky setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.776,
        store_longitude: -73.9562,
        total_orders: 753.42,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Steak, Pizza, Sushi, Salad, Meatball, Italian, Canadian, Thai, American',
        annual_dol: 1755.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5722,
        store_longitude: -74.1127,
        total_orders: 64.47,
        neighborhood: 'New Dorp',
        keywords:
            'Vegetarian, Butter Chicken, Dessert, Coffee, Delicious, Chicken Tikka Masala, Tangy, Italian, Charming, Romantic',
        annual_dol: 77.14,
        description:
            'Long-standing vibrant outpost offers familiar Indian dishes & a weekend buffet in a snazzy interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -73.7331,
        total_orders: 120.31,
        neighborhood: 'Queens Village',
        keywords: 'Steak, Chinese, Spicy, French, Fried Rice, Indian, Casual, Great',
        annual_dol: 491.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7202,
        store_longitude: -73.9968,
        total_orders: 226.29,
        neighborhood: 'Little Italy',
        keywords:
            'Garlic Bread, Cheesecake, Chicken Parmigiana, Italian, Tasty, Hotel, Friendly, Authentic, Intimate, Amazing',
        annual_dol: 246.26,
        description:
            'Hearty helpings of classic Italian fare dished up in a simple, long-running (since 1968) trattoria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7761,
        store_longitude: -73.9625,
        total_orders: 69.97,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Pizza, Vegetarian, Italian, Salad, Meatball, Tasty, Dessert, Spicy, Vodka, Fabulous',
        annual_dol: 245.08,
        description:
            'Bi-level Italian restaurant with thin-crust pizzas & homemade pastas, plus a rooftop terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7547,
        store_longitude: -73.8539,
        total_orders: 437.44,
        neighborhood: 'North Corona/Flushing/Corona',
        keywords:
            'Shrimp Cocktail, Vodka, Salad, Beer, Gluten Free, Ale, Italian, Hotel, Bbq, Homemade',
        annual_dol: 1788.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9951,
        total_orders: 1200.46,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Pizza, Vegetarian, Italian, Salad, Meatball, Dessert, Wine, Classy, Vibrant, Casual',
        annual_dol: 1285.26,
        description:
            'Traditional Italian dishes, pizzas & cocktails served in a relaxed, industrial-chic restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9995,
        total_orders: 22904.69,
        neighborhood: 'Little Italy',
        keywords:
            'Flavorful, Vegan, Upscale, Trendy, Wine, Delicious, Salad, Impeccable, Chinese, Asian',
        annual_dol: 26361.88,
        description:
            'Cocktails & Asian fusion eats are offered in this industrial-chic eatery with an atrium & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7724,
        store_longitude: -73.9555,
        total_orders: 6103.49,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Burger, Tasty, Salad, Meatball, Delicious, Italian, Spicy, Sustainable, Funky',
        annual_dol: 6312,
        description:
            'Cozy chophouse offering updated dishes & sustainable cuts, plus playful artwork & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7338,
        store_longitude: -74.0026,
        total_orders: 11716.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Colorful, Complex, Great, Casual',
        annual_dol: 11737.74,
        description:
            'A gay & straight crowd camps out at this bi-level bar for piano sing-alongs, drag revues & comedy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7312,
        store_longitude: -74.004,
        total_orders: 147.31,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Delicious, Coffee, Italian, Bright, Vibrant, American, French, Friendly',
        annual_dol: 206.37,
        description:
            'Craft cocktails are served in a swanky space with a neon sign casting a purplish glow.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7741,
        store_longitude: -73.8709,
        total_orders: 59.95,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Salad, Wine, Beer, Serene, Spacious, Casual, Bright, American',
        annual_dol: 245.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8559,
        store_longitude: -73.8677,
        total_orders: 126.08,
        neighborhood: 'East Bronx/Pelham Parkway',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Cajun, Spicy, Triple Sec, Casual, Classic, Great',
        annual_dol: 515.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.784,
        store_longitude: -73.9779,
        total_orders: 105.53,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Coffee, Fresh, Friendly',
        annual_dol: 431.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6339,
        store_longitude: -74.0099,
        total_orders: 174.43,
        neighborhood: 'Sunset Park',
        keywords: 'Karaoke, Kebab, Wine, Spicy, Clean, Classic, Wonderful, Great, Excellent, Pure',
        annual_dol: 713.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6155,
        store_longitude: -73.9937,
        total_orders: 1085.86,
        neighborhood: 'Bensonhurst',
        keywords:
            'Vegetarian, Sushi, Salad, Japanese, Spicy, Asian, Sashimi, Casual, Authentic, Excellent',
        annual_dol: 2241.8,
        description:
            'Basic Japanese eatery offering sushi & other specialties like hibachi meats in sparse surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7416,
        store_longitude: -73.6614,
        total_orders: 7969.87,
        neighborhood: '',
        keywords:
            'Tacos, Steak, Tequila, Mexican, Tasty, Beer, Margarita, Californian, Delicious, Hawaiian',
        annual_dol: 8138.98,
        description:
            '"AVO TACO is a fresh, new spot to relax and enjoy yummy deliciousness in a fun, fast-casual environment."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6544,
        store_longitude: -73.9594,
        total_orders: 325.94,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Salad, Pizza, Vegetarian, Flavorful, Wine, Margarita, Caribbean, Rum, Jamaican, Romantic',
        annual_dol: 1569.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7638,
        store_longitude: -73.9145,
        total_orders: 248.41,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, Cajun, Cajun-Style, Beer, Irish, American, Vintage, Homemade, Premium, Creole',
        annual_dol: 656.2,
        description:
            'Sleek bar dishing up raw & cooked Cajun-style seafood by the pound along craft beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8265,
        store_longitude: -73.8223,
        total_orders: 922.65,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Mexican, Beer, Shrimp Cocktail, Wine, Coffee, Dessert, American, Homemade, Modern, Legendary',
        annual_dol: 1637.58,
        description:
            'American comfort eats & craft beer served in a low-key sports bar with a backyard patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.704,
        store_longitude: -73.9191,
        total_orders: 3504.92,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Salad, Adventurous, Asian, Vibrant, Local, Great, Casual',
        annual_dol: 3875.28,
        description:
            'Vibrant bar & happy hour haunt with Asian style décor serving skewers, hot dogs & bowls til 2am.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6238,
        store_longitude: -73.9757,
        total_orders: 218.19,
        neighborhood: 'Mapleton/Midwood',
        keywords: 'Burger, Salad, Beer, French, Fancy, Relaxed, Casual, Great',
        annual_dol: 222.82,
        description:
            'Relaxed neighborhood bar with a jukebox, pool table, darts & other games, plus sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6586,
        store_longitude: -73.6452,
        total_orders: 55.11,
        neighborhood: '',
        keywords: 'American, Comfortable, Delicious, Rum, Ample, Casual',
        annual_dol: 208.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6298,
        store_longitude: -74.0742,
        total_orders: 593.14,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Pizza, Cheesecake, Salad, Italian, Coffee, Meatball, Dessert, Delicious, Modern, Sparkling',
        annual_dol: 697.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8623,
        store_longitude: -73.6231,
        total_orders: 18.26,
        neighborhood: '',
        keywords:
            'Steak, Burger, Salad, French Toast, Italian, French, Bbq, Greek, Quesadillas, American',
        annual_dol: 32.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7546,
        store_longitude: -73.9872,
        total_orders: 4238.51,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Stylish, Beer, Scotch, Canadian, Quaint, Delicious, Cozy, Spicy, Vintage',
        annual_dol: 4339.62,
        description:
            'Quaint, classy hangout with a vintage vibe presenting craft cocktails, local beers & diverse dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7509,
        store_longitude: -73.9868,
        total_orders: 88.75,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Chicken Caesar Salad, Beer, Coffee, Vodka, Tasty, Caesar Salad, American',
        annual_dol: 362.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7561,
        store_longitude: -73.9934,
        total_orders: 494.05,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Friendly',
        annual_dol: 711.5,
        description:
            'The understated rooms provide flat-screen TVs and Wi-Fi, plus coffeemakers and minifridges. Some feature city views and/or sofabeds.Amenities include 24-hour bar service and event space. Breakfast is available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.9988,
        total_orders: 20394.81,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Beer, Wine, Chinese, Vintage, Fun, Impressive, Great, Lit, Signature, Casual',
        annual_dol: 22047.91,
        description:
            'Cocktails, beer & wine served in a unique, dimly lit bar that has beer pong, dice games & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -74.0032,
        total_orders: 5065.21,
        neighborhood: 'Civic Center',
        keywords:
            'Lobster, Tasty, Martini, Stylish, Hotel, Inviting, Impeccable, Fun, Eclectic, Sleek',
        annual_dol: 5857.21,
        description:
            'Upscale, stylish nightspot at 11 Howard hotel featuring cocktails, DJs & an eclectic bar-bites menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.716,
        store_longitude: -73.9965,
        total_orders: 5317.79,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Luxurious, Hotel, Upscale, Stylish, Spacious, Vibrant, Urban, Rooftop, Lively, Sleek',
        annual_dol: 12644.6,
        description:
            'skyscraper with an observation deck.Hip rooms with original artwork and floor-to-ceiling windows feature free Wi-Fi and flat-screens, plus minibars and rainfall showers. Upgraded rooms add city views. Suites include separate living rooms with pull-out sofas. In-room dining is available.There’s a stylish rooftop bar with city views. Breakfast is offered for a fee. Other amenities include a 24/7 gym, an art gallery and a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7223,
        store_longitude: -74.0041,
        total_orders: 2204.22,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Italian, Elegant, Homemade, Perfect, Contemporary, Amazing, Casual, Great, Excellent, Fusion',
        annual_dol: 2258.1,
        description:
            'Eatery where chefs cook & serve pastas from a central kitchen, with counter seating & banquettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5791,
        store_longitude: -74.1085,
        total_orders: 19.81,
        neighborhood: 'Midland Beach',
        keywords:
            'Peruvian, Salad, Italian, Latin, Contemporary, Indian, American, Casual, Traditional, Local',
        annual_dol: 80.96,
        description:
            'Contemporary eatery offering seafood & traditional Latin American cuisine in a convivial atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.703,
        store_longitude: -74.012,
        total_orders: 360.94,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Clean, Friendly, Modern',
        annual_dol: 368.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7178,
        store_longitude: -74.0014,
        total_orders: 722.22,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Tasty, Beer, Fun, Innovative, Clean, Great, Artisanal, Unforgettable, Local, Unique',
        annual_dol: 1263.25,
        description:
            'Axe throwing sessions in a Viking-themed lounge, plus craft beers, cocktails & bar snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7221,
        store_longitude: -74.0036,
        total_orders: 1241.94,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Tequila, Wine, Whiskey, Beer, Bourbon, Hotel, Cozy, Delicious, Fancy, Classic',
        annual_dol: 1331.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7173,
        store_longitude: -74.0104,
        total_orders: 216.18,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Italian, Pizza, Salad, Upscale, Trendy, Rustic, Hotel, Bellini, Prosecco, Warm',
        annual_dol: 251.98,
        description:
            'Airy, wood-accented eatery offering classic Italian standards in warm surrounds plus patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7043,
        store_longitude: -74.0095,
        total_orders: 478.73,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Vegetarian, Pizza, Italian, Wine, Vodka, Austrian, Rustic, Mediterranean, Refined',
        annual_dol: 488.89,
        description:
            'Basement-level white-tablecloth dining with Northern Italian classics like pasta & grilled meats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6903,
        store_longitude: -73.8147,
        total_orders: 32.06,
        neighborhood: 'South Richmond Hill',
        keywords: 'Vegetarian, Wine, American, Hotel, Fun, Caribbean, Casual, Biryani, Great',
        annual_dol: 79.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7208,
        store_longitude: -73.8469,
        total_orders: 3312.06,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Beer, Mexican, Hamburger, Whiskey, Cajun, Delicious, Nachos, Warm, Legendary',
        annual_dol: 3382.34,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7456,
        store_longitude: -73.9885,
        total_orders: 1604.35,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Beer, Coffee, Trendy, Stylish, Comfortable, Lebanese, Italian, Hotel, American',
        annual_dol: 4496.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -74.0012,
        total_orders: 5907.66,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Salad, Coffee, Mexican, Beer, Pizza, Chinese, Delicious, Mediterranean, Wholesome',
        annual_dol: 6236.08,
        description:
            '"Welcome to ANA. The sunny living room of Hudson Yards perfect for taking a break from your fast-paced day. We create wholesome hand-made foods from fresh, local ingredients for you to enjoy throughout your day. Fuel up in the morning with selections from our café area including smoothies and juices made to order, fresh coffee and pastries, and daily seasonal breakfast entrées. For your afternoon escape, enjoy a slice of pizza from our brick oven, tacos, a selection of prepared salads and sandwiches, or a seasonal entree made in-house daily. ."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7665,
        store_longitude: -73.9786,
        total_orders: 4766.96,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Romantic, Hotel, American, Perfect, Yummy, Innovative, Traditional, Wonderful, Contemporary, Fine',
        annual_dol: 4973.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9875,
        total_orders: 348.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Salad, Mediterranean, Premium, Lively, Spanish, Yummy, Greek, Greek Salad, Relaxed',
        annual_dol: 416.2,
        description:
            'Relaxed wine bar featuring a Mediterranean menu served in the main room, cellar or garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.809,
        store_longitude: -73.9518,
        total_orders: 364.23,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Beer, Savory, Dessert, Legendary, Intimate, Premium, Amazing, Great, Tradition, Artisanal',
        annual_dol: 405.24,
        description:
            'Cocktail lounge with a chill vibe presenting creative concoctions in an art-filled atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.9761,
        total_orders: 55.91,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Upscale, Stylish, Modern, Great, Clean, Rooftop, Contemporary, Cultural',
        annual_dol: 228.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7171,
        store_longitude: -73.9953,
        total_orders: 1390.98,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Hotel, Rooftop, Trendy, Spectacular, Stylish, Rum, Delicious, Beer, American, Chinese',
        annual_dol: 1451.28,
        description:
            "Modern rooms have floor-to-ceiling windows, desks and free Wi-Fi, plus flat-screen TVs, minifridges and coffeemakers. Upgraded rooms and suites add living areas and city views; some have terraces. Room service is available.There's a warm Chinese eatery showing big-screen sports, a slick neon-lit ramen bar, and a breezy beer garden offering American dishes, plus a hip rooftop cocktail bar with city views. There's also a 24-hour fitness room and a business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9689,
        total_orders: 16.75,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Dessert, Beer, Donut, Japanese, Polished, Sweet, Premium, Healthy, Contemporary',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6182,
        store_longitude: -74.0304,
        total_orders: 2.81,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Salad, Mediterranean, Delicious, Homemade, Healthy, Casual, Shawerma, Local, Fresh, Middle Eastern',
        annual_dol: 11.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7419,
        store_longitude: -74.0035,
        total_orders: 7926.3,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Gluten Free, Spicy, Trendy, Asian, Chinese, Sweet, Sashimi, Premium, Thai',
        annual_dol: 8556.5,
        description:
            'Trendy nightspot serving cocktails amid a cool, Chinese-themed setting with exposed-brick walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6793,
        store_longitude: -73.9814,
        total_orders: 396.12,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Gourmet, Warm, Casual, Sumptuous, Signature',
        annual_dol: 404.53,
        description:
            'Cozy, rustic-chic lounge with a large backyard area offering signature cocktails & happy hour deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7386,
        store_longitude: -73.9935,
        total_orders: 173.81,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Italian, Casual, Perfect, Welcoming, Fine, Artisanal',
        annual_dol: 189.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7586,
        store_longitude: -73.9787,
        total_orders: 393.18,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Steak, Lobster, Lobster Roll, Burger, Dessert, Wine, French, Coffee, Spicy, Hotel',
        annual_dol: 1607.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6307,
        store_longitude: -74.1031,
        total_orders: 2653.59,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Tacos, Tequila, Steak, Mexican, Margarita, Nachos, Delicious, Fajitas, Stylish, Fun',
        annual_dol: 2709.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.984,
        total_orders: 1198.89,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegan, Burger, Cornbread, Whiskey, Champagne, Beer, Mexican, Bourbon, Cuban, Bbq',
        annual_dol: 2932.36,
        description:
            'Intimate bar offering an extensive list of cocktails that showcase classic Italian & craft bitters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7229,
        store_longitude: -73.986,
        total_orders: 3809.49,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vodka, Beer, Gin, Stylish, Ale, Instagrammable, Cozy, Spacious, Legendary, Classy',
        annual_dol: 3890.32,
        description:
            'Bi-level standby with an old-school haunt on the 1st floor & a relaxed lounge with views on the 2nd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7439,
        store_longitude: -73.9839,
        total_orders: 1307.69,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Champagne, Wine, Hotel, Coffee, Stylish, Salad, French Toast, Delicious, French, Funky',
        annual_dol: 1423.1,
        description:
            'The polished rooms come with floor-to-ceiling windows and flat-screen TVs, as well as laptop safes, minibars and Wi-Fi. Upgraded suites add furnished wraparound balconies, 4-poster beds, chandeliers and/or free-standing whirlpool tubs.Amenities include a chic rooftop restaurant and bar, plus a rooftop pool area with a cocktail lounge and daybeds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9858,
        total_orders: 653.49,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Martini, Quaint, Fun, Friendly, Legendary, Cool, Authentic, Aesthetic, Vivid',
        annual_dol: 711.16,
        description:
            'NYC offshoot of a longtime Las Vegas punk bar, best known for its bacon martinis.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6665,
        total_orders: 46.47,
        neighborhood: '',
        keywords:
            'Sushi, Vegetarian, Mexican, Wine, Japanese, Delicious, Trendy, Ramen, Healthy, Asian',
        annual_dol: 189.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7415,
        store_longitude: -73.981,
        total_orders: 11983.98,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Salad, Beer, Savory, Delicious, Trendy, Rustic, Spacious, American, Modern',
        annual_dol: 12503.57,
        description:
            'Trendy tavern with rustic-chic decor, comfort food, draft beer & classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7486,
        store_longitude: -73.9826,
        total_orders: 414.54,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Whiskey, Breakfast Burrito, Chinese, Luxurious, Spacious, Sleek, Modern, Western, Fun',
        annual_dol: 1694.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7408,
        store_longitude: -74.0047,
        total_orders: 159.48,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Lobster, Sushi, Pizza, Hamburger, Meatball, Tequila, Margarita, Thai, Cozy',
        annual_dol: 651.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -73.914,
        total_orders: 566.59,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords:
            'Burger, Steak, Salad, Wine, Nachos, Irish, French Onion Soup, French, American, ',
        annual_dol: 579.97,
        description:
            "Neighborhood corner bar with Irish leanings, including shepherd's pie & a hearty comfort-food menu.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8213,
        store_longitude: -73.9307,
        total_orders: 3459.2,
        neighborhood: 'West Bronx',
        keywords:
            'Steak, Salad, Wine, Vodka, Italian, Delicious, Shrimp Scampi, Meatball, Cozy, Comfortable',
        annual_dol: 3549.52,
        description:
            'Vibrant restaurant/bar featuring seafood & steak along with frequent live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7731,
        store_longitude: -73.9519,
        total_orders: 424.53,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Upscale, Coffee, Delicious, Salad, Warm, Baked Potato, Healthy, Spectacular, Superb, French',
        annual_dol: 485.22,
        description:
            'Serves traditional favorites like omelets, pancakes, sandwiches & salads. Delivery available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8305,
        store_longitude: -73.8493,
        total_orders: 605.45,
        neighborhood: 'East Bronx/Unionport',
        keywords: 'Beer, American, Fun, Contemporary, Great, Local',
        annual_dol: 631.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7698,
        store_longitude: -73.9543,
        total_orders: 2180.06,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Burger, Wine, Salad, Rustic, American, Comfortable, Smooth, Friendly, Casual',
        annual_dol: 2227.67,
        description:
            'This casual joint specializes in a rotating list of American draft microbrews plus hearty pub fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7653,
        store_longitude: -73.9607,
        total_orders: 117.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Italian, Delicious, Romantic, Salad, Cozy, Premium, Awesome, Friendly, Great',
        annual_dol: 153.62,
        description:
            'Local joint offering wine, Italian small plates & meal-sized salads in a warm, laid-back atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9582,
        total_orders: 75.22,
        neighborhood: 'Greenpoint',
        keywords: 'Salad, Pizza, Vegetarian, Wine, Beer, Vietnamese, Bbq, Peruvian, Spicy, Stylish',
        annual_dol: 78.48,
        description:
            'Contemporary Vietnamese cuisine served in a hip, minimalist space with lots of greenery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.646,
        store_longitude: -73.9579,
        total_orders: 1597.69,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Magical, Upscale, Opulent, Legendary, Exquisite, Memorable',
        annual_dol: 2303.14,
        description:
            "The Kings Theatre, formerly Loew's Kings Theatre, is a live performance venue in the Flatbush neighborhood of Brooklyn, New York City. Opened by Loew's Theatres as a movie palace in 1929 and closed in 1977, the theater sat empty for decades until a complete renovation was initiated in 2010.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.673,
        store_longitude: -73.9829,
        total_orders: 23.73,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Burger, Gluten Free, Beer, Vodka, Salad, Delicious, Perfect, Local, Casual',
        annual_dol: 97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.845,
        total_orders: 5248.56,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Pizza, Hamburger, Cheesecake, Sushi, Salad, Dessert, Beer, Whiskey, Bbq',
        annual_dol: 5454.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.8468,
        total_orders: 709.48,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Rich, Memorable, Iconic, Fun, Classy, Tradition, Fresh, Local',
        annual_dol: 740.24,
        description:
            'The West Side Tennis Club is a private tennis club located in Forest Hills, a neighborhood in the New York City borough of Queens. The club has 38 tennis courts in all four surfaces, a junior Olympic-size swimming pool and other amenities.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6603,
        store_longitude: -73.8307,
        total_orders: 16.22,
        neighborhood: 'Old Howard Beach/Jamaica/Howard Beach',
        keywords: 'Pizza, Tasty, Beer, Irish, Great, Casual, Local',
        annual_dol: 66.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7345,
        store_longitude: -74.0001,
        total_orders: 7806.75,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Burger, Salad, Italian, Tasty, Dessert, Bbq, Spicy, French, Caesar Salad',
        annual_dol: 7993.63,
        description:
            'Cozy chophouse offering updated dishes & sustainable cuts, plus playful artwork & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8436,
        store_longitude: -73.836,
        total_orders: 347.49,
        neighborhood: 'Pelham Bay',
        keywords: 'Pizza, Garlic Bread, Meatball, Italian, Bbq, French, Local, Fresh, Casual',
        annual_dol: 362.55,
        description:
            'Counter-serve pizzeria also offering familiar salads, hot Italian subs & oven-baked pastas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8474,
        store_longitude: -73.832,
        total_orders: 24.42,
        neighborhood: 'Pelham Bay',
        keywords: 'Beer, Karaoke, Legendary, Casual, Great',
        annual_dol: 92.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -73.9104,
        total_orders: 785.53,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Burger, Tacos, Mexican, Beer, German, Coffee, Ale, Wine, American, French',
        annual_dol: 802.2,
        description:
            '"The Seneca is a neighborhood bar/restaurant specializing in scratch-made food, classic cocktails, beer and wine. We are a favorite destination of locals for dinner, late night drinks, special events and everything in between."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8548,
        store_longitude: -73.8938,
        total_orders: 324.58,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Lobster, Lobster Tail, Salad, Wine, Chicken Wings, Thai, Bbq, Spanish, Latin, Caesar Salad',
        annual_dol: 1326.79,
        description:
            'Easygoing restaurant offering classic burgers & sushi, plus chicken wings & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8552,
        store_longitude: -73.8872,
        total_orders: 49.75,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Burger, Tacos, Sushi, Mexican, Pizza, Shrimp Cocktail, Delicious, Warm, Casual, Polished',
        annual_dol: 128.21,
        description:
            'Warm Mexican option with a polished interior offering classic dishes, mariachi music & karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.967,
        total_orders: 1040.7,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Burger, Lobster, Kebab, Shrimp Cocktail, Bbq, Wine, American, French, Spicy',
        annual_dol: 1062.78,
        description:
            'American dishes by David Burke in a handsome space with a solarium & red leather booths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9924,
        total_orders: 5329.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Whiskey, Wine, Scotch, Bourbon, Ale, Rum, Cozy, Tex-Mex, Intimate, Irish',
        annual_dol: 13236.31,
        description:
            'Tiny, dimly lit watering hole boasting an extensive selection of whiskeys & bottled beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8245,
        store_longitude: -73.8591,
        total_orders: 308.2,
        neighborhood: 'East Bronx/Soundview',
        keywords: 'Burger, Sushi, Pizza, Southern, American, Friendly, Healthy, Local, Teriyaki',
        annual_dol: 1360.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7439,
        store_longitude: -73.996,
        total_orders: 3069.87,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tacos, Steak, Cajun, Beer, Salad, Cuban, Bbq, Puerto Rican, Savory, Latin',
        annual_dol: 5437.83,
        description:
            'Modern Latin fare including Sunday brunch, served with signature cocktails in funky quarters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7355,
        store_longitude: -73.998,
        total_orders: 1082.66,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Beer, Delicious, Cozy, Iraqi, Mediterranean, Israeli, Shawarma, Syrian, Contemporary',
        annual_dol: 1359.71,
        description:
            'Middle Eastern specialties (like kibbeh, kubeh and meze) are served in a chic, contemporary setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5416,
        store_longitude: -74.1772,
        total_orders: 122.88,
        neighborhood: 'Arden Heights',
        keywords: 'Cozy, Yummy, Fun, Clean, Awesome, Friendly, Outstanding, Great, Casual',
        annual_dol: 524.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7364,
        store_longitude: -73.9973,
        total_orders: 350.21,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Margarita, Burritos, Local, Warm, Casual, Premium',
        annual_dol: 1207.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6866,
        store_longitude: -73.9747,
        total_orders: 207.56,
        neighborhood: 'Fort Greene',
        keywords: 'German, Pizza, Italian, Salad, Beer, Coffee, Wine, Organic, French, Trendy',
        annual_dol: 424.22,
        description:
            'Trendy indoor beer garden serving a menu of German food & beers at wooden tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.9551,
        total_orders: 671.42,
        neighborhood: 'Williamsburg',
        keywords:
            'Peruvian, Mexican, Sushi, Wine, Coffee, Vegan, Dessert, Upscale, Beer, Uruguayan',
        annual_dol: 2744.54,
        description:
            '"Republic Latin Fusion is a restaurant and bar based in Williamsburg, Brooklyn. Founded in 2021 by friends and co-workers who bonded over the love for hospitality. The flagship location in Williamsburg, Brooklyn serves dishes inspired from the culinary diversity of Latin America and fuses it with unique twists and flavors from Asia. Live music, elevated experiences, and a lively yet intimate atmosphere, Republic Latin Fusion takes your dining experience to the next level. Our menu has multiple options for vegan, vegetarian, and gluten-free dishes."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7249,
        store_longitude: -73.983,
        total_orders: 1394.66,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Pizza, Italian, Beer, Wine, Hotel, Stylish, Prime Rib, Polish, Fabulous, Great',
        annual_dol: 1424.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9557,
        total_orders: 16.94,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Tacos, Mexican, Beer, Margarita, Steak, Wine, Nachos, Quesadillas, Enchiladas',
        annual_dol: 167.92,
        description:
            'Longtime counter-serve joint with a large menu of Mexican classics, plus wholesale tortillas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -73.9879,
        total_orders: 166.35,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Steak, Wine, Coffee, French Toast, Beer, Mediterranean, French, Tunisian, Moroccan',
        annual_dol: 481,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -73.9794,
        total_orders: 2663.45,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Cheesesteak, Wine, Beer, Margarita, Nachos, Philly Cheesesteak, Rustic, French, American',
        annual_dol: 2663.45,
        description:
            'Reclaimed wood & weathered brick set the scene for hand-drawn cask ales, craft brews & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7138,
        store_longitude: -73.9619,
        total_orders: 2365.82,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Whiskey, Vodka, Beer, Margarita, American, Clean, Fresh, Fantastic, Great, Cool',
        annual_dol: 3627.75,
        description:
            '"A neighborhood sports bar featuring fresh cocktails along with excellent beer and whiskey selection"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.9583,
        total_orders: 2008.41,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Lobster, Lobster Roll, Steak, Salad, Tasty, Donut, Beer, Delicious, Bbq',
        annual_dol: 2051.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7208,
        store_longitude: -73.9577,
        total_orders: 388.19,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Turkish, Spanish, Savory, Russian, Modern, Casual, Local, Natural, Hip',
        annual_dol: 614.18,
        description:
            '"Bathhouse combines the vibrant, social elements of bathhouses from around the world alongside an array of treatments for the modern athlete and lifestyle enthusiast. We provide elite level treatments for those who want to look, feel and perform their very best."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.6231,
        total_orders: 961.97,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Relaxed, Contemporary, American, Casual, Authentic, Perfect, Fresh, Excellent',
        annual_dol: 1013.57,
        description:
            '"GianCarlo DiMaggio and Danny Baez are the driving force behind the storied Cassariano Italian Eatery Mineola. The freshest ingredients with the utmost service in mind are the benchmarks for the finest, award winning Italian cuisine. Driven by their strong passion for food and a deep love for their profession, these partners prepare authentic contemporary Italian cuisine while cultivating a casual, relaxed atmosphere for their patrons. Cassariano is the perfect blend of Bistro flavors and fine cuisine. We look forward to exceeding your expectations!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -74.0059,
        total_orders: 6041.32,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Hotel, Coffee, Meatball, Salad, Trendy, American, Rooftop, Iconic, Contemporary, Hip',
        annual_dol: 6231.88,
        description:
            "from the elevated High Line park.Sophisticated rooms come with free Wi-Fi and flat-screen TVs, plus high-thread-count sheets and minibars. Upgraded rooms add sitting areas and/or Juliet balconies with river or city views. Suites feature separate living rooms, and posh bi-level suites include dining areas and fireplaces.There's a trendy American restaurant, a hip lobby bar, and a rooftop terrace with a lounge and an outdoor pool, as well as a spa and a gym.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7333,
        store_longitude: -74.0023,
        total_orders: 3551.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Margarita, Legendary, Spanish, Classic, Fun, Relaxed, Great, Excellent, Casual',
        annual_dol: 3957.35,
        description:
            'Classic gay hangout offers piano bar upstairs & a basement level for dancing, music & mingling.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7318,
        store_longitude: -74.0016,
        total_orders: 1157.5,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Sushi, Asian, Chinese, Thai, Australian, Superb, Awesome, Fusion, Excellent',
        annual_dol: 1720.11,
        description: '"South East Asian small plates, good for sharing/groups"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.6345,
        total_orders: 236.34,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Beer, Margarita, Nachos, Salad, Enchiladas, Hotel, Vibrant, Modern',
        annual_dol: 302.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -74.0035,
        total_orders: 217.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Korean, Sushi, Cozy, Casual, Modern, Hotel, Local, Hip, Great',
        annual_dol: 954.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -74.0049,
        total_orders: 7.56,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Crab Cakes, Pizza, Coffee, Creamy, Yummy, Sweet, Australian, Fresh, American, Seasonal',
        annual_dol: 30.92,
        description:
            '"Tribeca’s new favorite eatery, twiggy to go offers coffee, crullers, pastries, an assortment of main courses & seasonal sides for carryout. Some of our specialties include roasted chicken, quiche, tomato tart, crab cakes & fried chicken. Ask about our catering today!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 198.29,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Steak, Burritos, Delicious, Local, Casual, Classic, Distinctive, Fine',
        annual_dol: 374.49,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7025,
        store_longitude: -73.6433,
        total_orders: 8.69,
        neighborhood: '',
        keywords: 'Karaoke, Warm, Beer, Fun, Phenomenal, Great, Casual',
        annual_dol: 35.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6955,
        store_longitude: -73.932,
        total_orders: 216.23,
        neighborhood: 'Bushwick',
        keywords:
            'Vegan, Wine, Beer, Wholesome, Vibrant, American, Classic, Fantastic, Great, Amazing',
        annual_dol: 883.88,
        description:
            '"Ornithology Jazz Club is a no-cover Jazz listening room with excellent cocktails with wholesome vegan bites with sustainability at heart. World-class live jazz with exciting events such as gallery pop-up, vegan chef pop-up, spoken word open mic and stand-up comedy"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8452,
        store_longitude: -73.9024,
        total_orders: 144.27,
        neighborhood: 'Tremont/Mount Hope/West Bronx',
        keywords: 'Lobster, Lasagna, Lobster Tail, Salad, Vegan, Cajun, Beer, Wine, Vodka, Latin',
        annual_dol: 157.01,
        description:
            'Lively destination for fish, seafood & meat dishes with a Latin twist, plus cocktails & hookah.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.808,
        store_longitude: -73.7356,
        total_orders: 6311.84,
        neighborhood: '',
        keywords:
            'Burger, Spicy, Iranian, Coffee, Delicious, Spacious, Premium, Mediterranean, Great, Local',
        annual_dol: 8592.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7539,
        store_longitude: -73.9894,
        total_orders: 872.58,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Moroccan, Vegetarian, Salad, Mediterranean, Warm, African, Classic, Hip, Casual, Authentic',
        annual_dol: 3566.83,
        description:
            'Warm eatery, covered in colorful textiles, featuring Moroccan cuisine & belly dancing some nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -74.0046,
        total_orders: 5251.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Whiskey Sour, Whiskey, Beer, Wine, Vodka, Ale, Salad, Cozy, Warm, American',
        annual_dol: 5294.31,
        description: 'This pub salutes Brooklyn with local beers & spirits plus creative pub food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7299,
        store_longitude: -74.0044,
        total_orders: 388.77,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Tacos, Mexican, Tequila, Gluten Free, Nachos, Wine, Tasty, Warm',
        annual_dol: 1254.52,
        description:
            'Contemporary Mexican spot with a colorful, all-vegan menu, plus tequila & mezcal cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7304,
        store_longitude: -74.0066,
        total_orders: 519.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Spicy, Zesty, Creamy, Cozy, Rich, Playful, Seasonal, Contemporary, Casual',
        annual_dol: 553.61,
        description:
            'A seasonal, clam-focused seafood menu presented in a cozy, brick-walled space with large windows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7906,
        store_longitude: -73.9744,
        total_orders: 4963.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Salad, Vegetarian, Bbq, Italian, Coffee, Nachos, Beer, Fried Chicken, Wine',
        annual_dol: 13488.05,
        description:
            'Long-standing BBQ operation turning out classic meat dishes, plus sides & craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7847,
        store_longitude: -73.9774,
        total_orders: 1175.29,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Whiskey, Gin, Ale, Burger, Margarita, Pizza, Belgian, Nachos, American',
        annual_dol: 1249.63,
        description:
            'Fans (especially Florida Gators) cheer their teams on TV at this tavern with pub food & beer pong.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7841,
        store_longitude: -73.9774,
        total_orders: 243.99,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Sushi, Salad, Wine, Spicy, Japanese, Trendy, Stylish, Sashimi, Gin, Asian',
        annual_dol: 249.17,
        description:
            'This stylish sushi specialist serves traditional & inventive Japanese fare amid eye-catching decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7328,
        store_longitude: -73.6831,
        total_orders: 1135.87,
        neighborhood: '',
        keywords:
            'Steak, Salad, Coffee, Italian, Beer, American, Delicious, Caesar Salad, Modern, Homemade',
        annual_dol: 1159.97,
        description:
            'Eclectic restaurant serving French, American & Italian dishes, with live music on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6435,
        store_longitude: -73.5396,
        total_orders: 335.2,
        neighborhood: '',
        keywords:
            'Vegetarian, Pizza, Italian, Wine, Shrimp Cocktail, Delicious, Ale, Relaxed, Friendly, Fun',
        annual_dol: 609.16,
        description:
            'Relaxed, enduring establishment offering family-style Italian meals, seafood & scenic bay views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9196,
        total_orders: 1075.02,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Mexican, Cuban, Spanish, Quesadillas, Fabulous, American, Amazing, Incredible, Great',
        annual_dol: 2948.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7133,
        store_longitude: -73.6055,
        total_orders: 38.11,
        neighborhood: '',
        keywords: 'Steak, Tacos, Casual',
        annual_dol: 155.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.9824,
        total_orders: 7402.91,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Gourmet, Gluten Free, Legendary, Spicy, Irish, American, French',
        annual_dol: 7612.48,
        description:
            'Landmark restaurant serving steak & seafood dishes in a formal setting since 1973.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9063,
        store_longitude: -73.9041,
        total_orders: 263.55,
        neighborhood: 'North Riverdale',
        keywords:
            'Crab Cakes, Burger, Pizza, Shrimp Scampi, Italian, Spicy, Mediterranean, Ale, Thai, American',
        annual_dol: 291.17,
        description:
            'Italian staple delivering familiar fare in comfortable environs with a neighborhood feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8944,
        store_longitude: -73.8967,
        total_orders: 78,
        neighborhood: 'Fieldston',
        keywords: 'Burger, Steak, Salad, Meatball, Beer, French, Irish, American, Latin, Casual',
        annual_dol: 83.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -73.9875,
        total_orders: 203.22,
        neighborhood: 'Lower East Side',
        keywords:
            'Flavorful, Tasty, Chicken Wings, Bbq, Lebanese, Premium, Cozy, Swiss, Sophisticated, Complex',
        annual_dol: 451.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6573,
        store_longitude: -73.9505,
        total_orders: 168.13,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Lobster, Wine, Delicious, Beer, Cozy, Sweet, Vietnamese, Fun, Caribbean, Vibrant',
        annual_dol: 687.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7404,
        store_longitude: -73.9922,
        total_orders: 5572.37,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Burger, Sushi, Steak, Salad, Japanese, Coffee, Beer, Wine, Organic, Delicious',
        annual_dol: 5754.7,
        description:
            'Elegant bistro by day offering refined & modern Japanese plates, cocktail & wine bar by night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5888,
        store_longitude: -73.8173,
        total_orders: 184.53,
        neighborhood: 'Far Rockaway/Rockaway Beach',
        keywords:
            'Vegetarian, Vegan, Salad, Flavorful, Thai, Dessert, Upscale, Delicious, Beer, Asian',
        annual_dol: 240.74,
        description:
            'Upscale Thai restaurant with a lounge offers scenic views, live music & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.9999,
        total_orders: 78.58,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegan, Vegetarian, Burger, Cheesesteak, Sushi, American, Philly Cheesesteak, Natural, Crisp, Contemporary',
        annual_dol: 321.19,
        description:
            'Easygoing bistro serving plant-based homestyle cooking amid compact surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6727,
        store_longitude: -73.9626,
        total_orders: 6035.2,
        neighborhood: 'Crown Heights',
        keywords:
            'Wine, Beer, Delicious, Martini, Premium, American, Awesome, Sweet, Amazing, Retro',
        annual_dol: 6261.49,
        description:
            'Craft cocktails & elevated bar bites are the focus at this hip, intimate hangout with a retro vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.616,
        store_longitude: -74.1387,
        total_orders: 917.92,
        neighborhood: 'Westerleigh/Mid Island',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, American, Delicious, Turkish, Homemade, Awesome, Great',
        annual_dol: 957.68,
        description:
            "Informal Italian eatery featuring familiar fare & pies, plus a hand-on kids' pizza-making class.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.9838,
        total_orders: 814.77,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Scotch, Bourbon, Beer, Upscale, Delicious, Premium, Authentic, Perfect, Intimate',
        annual_dol: 2512.19,
        description:
            'Petite St. Marks Place wine bar offering a variety of vinos paired with small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7119,
        store_longitude: -73.9999,
        total_orders: 64.41,
        neighborhood: 'Civic Center',
        keywords:
            'Vegan, Salad, Roast Turkey, Delicious, Swiss, English Muffin, Healthy, Perfect, Great, Fresh',
        annual_dol: 76.94,
        description:
            '"With over 25 years of experience in catering in New York, Deborah Miller Catering & Events helps you celebrate corporate and social events with delicious food made from fresh local ingredients. We take pride in providing NYC catering services from our state of the art kitchen, with hand-crafted cooking and exceptional service. We specialize in bar service catering, healthy catering including vegan and gluten-free menus, and lunch catering for your next corporate event. Contact us today for catering social events, weddings or corporate catering in NYC!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.9831,
        total_orders: 814.38,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Upscale, Perfect, Lively, Authentic, Awesome, Vintage, Great, Cool, Colorful, Casual',
        annual_dol: 3478.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6029,
        store_longitude: -74.1744,
        total_orders: 207.89,
        neighborhood: 'Bloomfield/Mid Island',
        keywords: 'American, Rustic, Fine',
        annual_dol: 254.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7148,
        store_longitude: -73.9616,
        total_orders: 7975.33,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Stylish, Delicious, Beer, Fresh, Bright, Perfect, Rum, Great, Fantastic',
        annual_dol: 9951.29,
        description: 'Attractive space for carefully-prepared craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7402,
        store_longitude: -73.975,
        total_orders: 5278.41,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Wine, Upscale, Beer, Rich, Californian, Tranquil, Elegant, American, Romantic',
        annual_dol: 5920.74,
        description:
            'Farm-to-table American cuisine served in an architecturally rich dining room, patio & bar area.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7576,
        store_longitude: -73.9287,
        total_orders: 69.84,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Beer, Wine, Ale, American, Traditional, Sweet, Local, Casual, Great',
        annual_dol: 136.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7372,
        store_longitude: -73.9882,
        total_orders: 3128.53,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Steak, Salad, Wine, French, Beer, Korean, American, Delicious, Caesar Salad, Traditional',
        annual_dol: 3337.71,
        description:
            "Bistro for dry-aged steaks & other French fare, with a bar that's strong on absinthe.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -73.9874,
        total_orders: 819.02,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Tasty, Salad, Korean, Italian, Seasonal, Contemporary, American, Spanish, Mediterranean, Robust',
        annual_dol: 1383.89,
        description:
            'Fashionable restaurant dishing up comfort food & cocktails with a contemporary twist.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6392,
        store_longitude: -73.9685,
        total_orders: 4266.21,
        neighborhood: 'Flatbush',
        keywords: 'Wine, Beer, Ale, Upscale, Warm, Vintage, Fun, Great, Seasonal, Casual',
        annual_dol: 5104.4,
        description:
            'Chill hideaway with a courtyard showcasing vintage guitars & live music, plus craft beers on tap.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8007,
        store_longitude: -73.9658,
        total_orders: 1847.83,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Steak, Burger, Whiskey, Beer, Mexican, Wine, Delicious, Premium, Friendly, Clean',
        annual_dol: 1865.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6466,
        store_longitude: -73.9707,
        total_orders: 1795.58,
        neighborhood: '',
        keywords: 'Pizza, Whiskey, Beer, Wine, Italian, Coffee, Delicious, Innovative, Hip, Bright',
        annual_dol: 2144.63,
        description:
            'Craft cocktails & Italian-style pizzas in innovative combos, in a hip, ornately wallpapered space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7691,
        store_longitude: -73.7741,
        total_orders: 941.39,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Pizza, Italian, Chinese, American, Warm, Friendly, Fresh, Casual, Awesome',
        annual_dol: 1213.96,
        description:
            'Intimate restaurant featuring wood-fired pizzas & traditional Italian cuisine in a warm atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9776,
        total_orders: 242.05,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Vegan, Korean, Wine, Classy, Friendly, Traditional, Iconic, Casual, Great, Complex',
        annual_dol: 989.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5837,
        store_longitude: -73.9383,
        total_orders: 106.59,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Vodka, Great',
        annual_dol: 108.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.7718,
        total_orders: 2070.74,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Wine, Spanish, Upscale, American, Delicious',
        annual_dol: 2117.65,
        description:
            'Traditional chophouse serving large steaks, classic sides & martinis in a clubby atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.8105,
        total_orders: 559.76,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Burger, Pizza, Korean, Spicy, Bbq, Asian, American, Casual, Friendly, Premium',
        annual_dol: 610.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.7715,
        total_orders: 947.15,
        neighborhood: 'Bayside',
        keywords: 'Beer, Delicious, Lively, Friendly, Bbq, Great, Fresh, Local, Casual',
        annual_dol: 3672.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9783,
        total_orders: 444.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Salad, Dessert, Tasty, Beer, Hotel, Trendy, Classy, Traditional, Classic',
        annual_dol: 1561.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7782,
        store_longitude: -73.7783,
        total_orders: 0,
        neighborhood: 'Bayside/Flushing/Bay Terrace',
        keywords:
            'Cajun, Wine, Cajun-Style, Beer, Martini, Juicy, French, Fun, Friendly, Authentic',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -74.0094,
        total_orders: 260.52,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Garlic Bread, Hamburger, Shrimp Cocktail, Beer, Wine, Juicy, Warm, American, Rum',
        annual_dol: 411.31,
        description:
            'Pub with a large selection of whiskeys & craft beers, plus a big menu that includes weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7133,
        store_longitude: -74.0106,
        total_orders: 2046.06,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Sushi, Beer, Japanese, Upscale, Hotel, Rich, Seasonal, Legendary, Classy',
        annual_dol: 8960.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.9657,
        total_orders: 224,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Gluten Free, Organic, Dessert, Mediterranean, Upscale, Greek, Healthy, Polished',
        annual_dol: 846.09,
        description:
            'Airy, upscale taverna serving a seafood-heavy Mediterranean menu, including a fixed-price lunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7103,
        store_longitude: -74.0138,
        total_orders: 937.4,
        neighborhood: 'World Trade Center/Financial District',
        keywords:
            'Steak, Lobster, Chocolate Cake, Cheesecake, Shrimp Cocktail, Chocolate Mousse, Salad, Mexican, Wine, Upscale',
        annual_dol: 957.29,
        description:
            'Upscale chain for aged prime beef, seafood & other traditional steakhouse fare in a clubby space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.743,
        store_longitude: -73.9905,
        total_orders: 3705.86,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Salad, Dessert, Elegant, Modern, Contemporary, Sleek, Indian, Seasonal, Comfortable',
        annual_dol: 3880.05,
        description:
            'Sophisticated Indian cuisine & a notable wine list offered in an elegant space with ornate accents.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.9886,
        total_orders: 5017.72,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Mexican, Tequila, Dessert, Wine, Trendy, Spicy, Delicious, Classy, Innovative, Contemporary',
        annual_dol: 5235.27,
        description:
            'Sleek Mexican spot with a big bar for locally sourced share plates, plus tequila & mezcal cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6982,
        store_longitude: -73.9952,
        total_orders: 40.78,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Luxurious, Upscale, Coffee, Elegant, Dessert, Hotel, Spectacular, Robust, High-End, Fresh',
        annual_dol: 95.07,
        description:
            '"The Watermark at Brooklyn Heights is located in the historic and beautifully renovated Leverich Towers Hotel. Independent Living, Assisted Living and Memory Care accommodations combine with award winning programming for a senior living community second to none. Members enjoy multiple dining venues, robust arts and cultural spaces, integrative health and wellbeing programs, and elegant residences with upscale design touches. Overlooking the Brooklyn Heights Promenade and connected to Manhattan by subways and ferries, The Watermark at Brooklyn Heights is your gateway to extraordinary senior living in the heart of it all. Come visit today to learn about becoming a member."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9913,
        total_orders: 356.46,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Hamburger, Steak, Vegetarian, Vegan, Gourmet, Beer, Tasty, Juicy, Wine',
        annual_dol: 364.03,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9847,
        total_orders: 1204.19,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Upscale, American, Classy, Comfortable, Delectable, Friendly',
        annual_dol: 4922.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7733,
        store_longitude: -73.9838,
        total_orders: 548.72,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Italian, Wine, Upscale, Roast Beef, Elegant, Polished, Perfect, Amazing, Iconic',
        annual_dol: 606.05,
        description:
            'Glass-walled dining room at Lincoln Center preparing upscale Italian fare in an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7759,
        store_longitude: -73.9818,
        total_orders: 45.21,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Vegetarian, Salad, Pizza, Wine, Coffee, Italian, Dessert, Organic, Delicious, Mediterranean',
        annual_dol: 184.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7459,
        store_longitude: -73.9571,
        total_orders: 16724.78,
        neighborhood: 'LIC/Hunters Point',
        keywords:
            'Sushi, Burger, Salad, Japanese, Chinese, Asian, Upscale, Vietnamese, Trendy, Thai',
        annual_dol: 17112.13,
        description:
            'Upscale Pan-Asian eatery in a high-rise restaurant with Manhattan skyline views and modern look.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 8428.64,
        neighborhood: '',
        keywords: 'Cheesecake, Salad, Tacos, Dessert, Wine, Italian, Thai, Cuban, Pot Roast, Warm',
        annual_dol: 8972.87,
        description:
            'Upmarket chain offering a wide-ranging American menu in sophisticated surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7411,
        store_longitude: -73.6405,
        total_orders: 22.23,
        neighborhood: '',
        keywords: 'Beer, Japanese, Local, American, Casual',
        annual_dol: 57.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -74.0028,
        total_orders: 2722.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Salad, Wine, Italian, Trendy, Delicious, French, Romantic, Warm, Sustainable',
        annual_dol: 2967.46,
        description:
            'Low-lit place with outdoor terrace & downstairs bar serving French classics and sustainable wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9919,
        total_orders: 1032.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Tequila, Mexican, Steak, Wine, Margarita, Coffee, Quesadillas, Spectacular, Exotic, Fresh',
        annual_dol: 1054.25,
        description:
            'Eatery offering Mexican classics, many tequilas, margaritas & beach decor near Penn Station.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7832,
        store_longitude: -73.9787,
        total_orders: 50.4,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tequila, Vodka, Whiskey, Hamburger, Beer, Salad, Margarita, Scotch, Dessert, Gin',
        annual_dol: 211.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7408,
        store_longitude: -73.6406,
        total_orders: 12.59,
        neighborhood: '',
        keywords: 'Burger, Salad, Wine, Beer, Trendy, Ale, Warm, Irish, Cozy, Healthy',
        annual_dol: 51.47,
        description:
            'Laid-back ale house offering 16 beers on tap, Irish grub & other pub fare, plus DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7053,
        store_longitude: -73.656,
        total_orders: 2.65,
        neighborhood: '',
        keywords:
            'Pizza, Beer, Delicious, American, French, Homemade, Fabulous, Classic, Cool, Great',
        annual_dol: 10.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6672,
        store_longitude: -73.9882,
        total_orders: 1010.43,
        neighborhood: 'Park Slope',
        keywords: 'Warm, Cozy, Friendly, Fun, Fresh, Eclectic, Great, Casual, Excellent, Modern',
        annual_dol: 1208.75,
        description:
            '"An eclectic and modern neighborhood bar on Park Slope\'s famed 5th Avenue serving a rotation of craft beers and wines, while hosting a variety of events and parties."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6687,
        store_longitude: -73.9904,
        total_orders: 91.1,
        neighborhood: 'Gowanus',
        keywords:
            'Mexican, Delicious, Awesome, Fresh, Contemporary, Fantastic, Great, Casual, Local, Michelada',
        annual_dol: 372.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6396,
        store_longitude: -73.952,
        total_orders: 48.53,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Pizza, Stylish, Caribbean, Exotic, Great, Casual',
        annual_dol: 198.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.774,
        store_longitude: -73.964,
        total_orders: 118.48,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Shrimp Cocktail, Vegetarian, Hotdog, Turkey Sandwich, Dessert, Coffee, French Onion Soup, French, Caesar Wrap',
        annual_dol: 386.81,
        description:
            'Omelets, burgers, Greek specialties & other diner standards are served at this all-day coffee shop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7051,
        store_longitude: -73.9201,
        total_orders: 2436.68,
        neighborhood: 'Bushwick',
        keywords: 'Hotdog, Beer, Warm, Wine, Cozy, Spacious, Fun, Awesome, Great, Quirky',
        annual_dol: 2518.43,
        description:
            'Laid-back bar with a sizable outdoor area, draft beers & quirky touches like personal USB ports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7321,
        store_longitude: -74.0051,
        total_orders: 642.53,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Lobster, Delicious, Upscale, Beer, Legendary, Gin, Premium, Sweet, Casual',
        annual_dol: 2538.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6556,
        store_longitude: -73.677,
        total_orders: 2942.12,
        neighborhood: '',
        keywords:
            'Steak, Sushi, Japanese, Stylish, Sashimi, Perfect, Traditional, Asian, Friendly, Casual',
        annual_dol: 3029.14,
        description:
            'Stylish Japanese restaurant & lounge with hibachi chefs cooking at tables & a dining room for sushi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.6361,
        total_orders: 569.68,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Italian, Vodka, Gourmet, Delicious, Trendy, Elegant, Yummy, Mediterranean',
        annual_dol: 581.77,
        description: 'Classic Italian fare in an elegant setting that includes sidewalk tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6579,
        store_longitude: -73.6649,
        total_orders: 50.95,
        neighborhood: '',
        keywords:
            'Chicken Parmigiana, Flavorful, Beer, Dessert, Meatball, Martini, Italian, French, Delicious, Creamy',
        annual_dol: 67.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7299,
        store_longitude: -74.0047,
        total_orders: 19.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Italian, Dessert, Upscale, Hotel, Homemade, Homey, Caesar Salad, Greek, Casual',
        annual_dol: 78.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -74.004,
        total_orders: 392.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Ale, French, Chinese, Delicious, Fancy, Exotic, Classic, Perfect, High-End',
        annual_dol: 507.44,
        description:
            'Les Trois Chevaux is a fine dining restaurant in New York City. Owned and run by Angie Mar, it opened in July 2021 and serves French cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -74.0044,
        total_orders: 90.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Burger, Tasty, Salad, Italian, Beer, Dessert, Wine, Cozy, Relaxed',
        annual_dol: 93.91,
        description:
            'Pizza parlor turning out thin-crust pies with creative toppings in a small, relaxed space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -73.9971,
        total_orders: 51.61,
        neighborhood: 'Little Italy',
        keywords:
            'Burger, Pizza, Salad, Vegan, Italian, Gluten Free, Dessert, Wine, Beer, Bruschetta',
        annual_dol: 210.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.668,
        store_longitude: -73.5541,
        total_orders: 8.58,
        neighborhood: '',
        keywords: 'Mexican, Margarita, Salad, Nachos, Local, Exotic, Friendly, Great',
        annual_dol: 35.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9203,
        total_orders: 3173.24,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Beer, Wine, Bbq, Nachos, Delicious, Casual, Smoky, American, Local',
        annual_dol: 3343.12,
        description:
            'This low-key gastropub with old-timey touches offers rotating taps & a menu of elevated bar food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6742,
        store_longitude: -73.9823,
        total_orders: 130.43,
        neighborhood: 'Park Slope',
        keywords:
            'Tequila, Tacos, Mexican, Spicy, Burritos, Delicious, Warm, Fajitas, American, Fun',
        annual_dol: 213.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.8874,
        total_orders: 63.62,
        neighborhood: 'Jackson Heights/Elmhurst',
        keywords: 'Tasty, Salad, Mexican, Vodka, Karaoke, Fun, Friendly, Latin, Great, Russian',
        annual_dol: 260.07,
        description:
            '"Beautiful new gay bar in Jackson Heights, Queens - where ALL are welcome. Follow us on Instagram for event updates and our weekly party and DJ schedule. Kitchen opening for small bites Spring 2022."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9738,
        total_orders: 11.39,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Chilean, Elegant, Beer, Picturesque, Perfect, Casual, American, Great, Amazing',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.739,
        store_longitude: -73.9917,
        total_orders: 730.74,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Delicious, Golfclub, Cozy, Urban, Perfect, Fun, Clean, American, Great, Dope',
        annual_dol: 746.24,
        description:
            'Indoor golf center offering lessons, Full Swing simulators, complimentary clubs & a bar with snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.9958,
        total_orders: 1107.64,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Italian, Salad, Vodka, Spicy, Warm, Creamy, Impeccable, Romantic, Cozy',
        annual_dol: 1131.14,
        description:
            "Stalwart that's been serving Northern Italian cuisine for over 20 years in a lush, old-school space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 162.79,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Italian, Pizza, Contemporary, Spacious, Fancy, Seasonal, Casual, Creative, Excellent, Great',
        annual_dol: 223.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7421,
        store_longitude: -74.0006,
        total_orders: 3542.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tequila, Mexican, Cornbread, Beer, Dairy Free, Margarita, Nachos, Spicy, Bbq, Smoky',
        annual_dol: 3618.14,
        description:
            'Mexican BBQ menus & cocktails combine at this lively, casual spin-off of a popular food truck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.9991,
        total_orders: 63.62,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vegan, Salad, Healthful, Delicious, Margarita, Spicy, Warm, Argentinian, Healthy',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7149,
        store_longitude: -73.9618,
        total_orders: 12020.49,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Steak, Sushi, Salad, Wine, Dessert, Japanese, Spicy, Delicious, Coffee, Beer',
        annual_dol: 13061.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7253,
        store_longitude: -73.9832,
        total_orders: 86.89,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Steak, Salad, Korean, Beer, Spicy, American, Trendy, Legendary, Dope',
        annual_dol: 355.17,
        description:
            'Korean-American plates are served in an intimate setting with hip hop influences.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7301,
        store_longitude: -73.9813,
        total_orders: 1053.95,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Beer, Cozy, Relaxed, Trendy, Spacious, Casual, Local, Awesome, Creative, Excellent',
        annual_dol: 1099.64,
        description:
            'Gay & lesbian mainstay in the East Village known for its jukebox & relaxed atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6072,
        store_longitude: -74.161,
        total_orders: 51.77,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Burger, Salad, Milkshake, Coffee, Tasty, Italian, Quaint, Greek, American, Omelette',
        annual_dol: 57.59,
        description:
            'An array of Greek-American comfort foods are prepared 24/7 at this spacious, time-tested throwback.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6869,
        store_longitude: -73.9632,
        total_orders: 22.74,
        neighborhood: 'Clinton Hill',
        keywords:
            'Wine, Sustainable, Funky, Vibrant, Polished, French, Natural, Contemporary, Fresh, Excellent',
        annual_dol: 92.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7121,
        store_longitude: -73.964,
        total_orders: 436.18,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Tequila, Tacos, Burger, Margarita, Bbq, American, Hip, Negroni, Crisp',
        annual_dol: 469.33,
        description:
            'American meals, craft beers & cocktails in a playful interior with graffitied walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9252,
        total_orders: 763.37,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Karaoke, Irish, Coffee, Greek, Classic, Authentic, Great, Casual',
        annual_dol: 779.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7229,
        store_longitude: -73.9957,
        total_orders: 609.69,
        neighborhood: 'Nolita',
        keywords:
            'Garlic Bread, Salad, Pizza, Chicken Parmigiana, Italian, Gourmet, Meatball, Tasty, Wine, Spicy',
        annual_dol: 622.62,
        description:
            'Chicken parm & other Italian-American classics go gourmet at this hip, diner-inspired space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.8038,
        total_orders: 155.64,
        neighborhood: 'Flushing/East Flushing',
        keywords: 'Tasty, Irish, Casual',
        annual_dol: 158.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9752,
        total_orders: 4459.81,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Tequila, Hotel, Upscale, Shrimp Cocktail, Vodka, Legendary, Margarita, Elegant, Coffee',
        annual_dol: 4573,
        description:
            "Sophisticated, airy rooms have flat-screen TVs, Wi-Fi, pillow-top mattresses and picture windows; some offer panoramic views of the park. Suites add sitting areas, wet bars, dining rooms and/or kitchens.A facilities fee covers Wi-Fi and an evening reception with drinks and snacks, plus use of the gym and the business center. There's also a fine dining restaurant with floor-to-ceiling windows and park views, and a casual eatery, as well as a refined cocktail bar with wood paneling.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.9893,
        total_orders: 834.79,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Delicious, Upscale, Beer, Irish, Fun, Awesome, Perfect, Screwdriver',
        annual_dol: 961.14,
        description:
            'Double-decker, fireplace-equipped Irish pub with an upstairs party room, bar-food menu & happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9778,
        total_orders: 311.25,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Salad, Organic, Local, Delicious, Casual, Fresh',
        annual_dol: 1272.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7152,
        store_longitude: -73.9915,
        total_orders: 999.02,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Wine, Scotch, Salad, Spicy, Warm, Cozy, Organic, Amazing, Great, Fresh',
        annual_dol: 1265.07,
        description:
            'Cozy eatery serving oysters, snacks & drinks in vintage-inspired digs with live music & DJ nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8915,
        store_longitude: -73.8977,
        total_orders: 280.57,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Mexican, Steak, Tex-Mex, Karaoke, Fajitas, Smoky, Casual, Authentic',
        annual_dol: 411.03,
        description:
            'Festive family-owned cantina with a simple decor with foods of the Southwest & mariachi & karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.594,
        store_longitude: -73.5799,
        total_orders: 393.71,
        neighborhood: '',
        keywords:
            'Lobster Roll, Lobster, Lobster Tail, Salad, Coffee, Wine, Tasty, Ale, Caesar Salad, American',
        annual_dol: 507.71,
        description:
            'Walls of windows offer waterside views at this nautical-themed outpost serving raw & cooked seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7471,
        store_longitude: -73.8874,
        total_orders: 2.82,
        neighborhood: 'Jackson Heights/Flushing/Elmhurst',
        keywords: 'Peruvian, Salad, Dessert, Tangy, French, Japanese, Casual',
        annual_dol: 11.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8677,
        store_longitude: -73.9209,
        total_orders: 1333.9,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Burger, Hamburger, Beer, Wine, Delicious, Upscale, American, Friendly, Fun, Classic',
        annual_dol: 1399.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -73.9927,
        total_orders: 2384.03,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Delicious, Cozy, Mediterranean, Homemade, Classy, Local, Baba Ghanoush, Great, Casual',
        annual_dol: 2493.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7362,
        store_longitude: -74.0007,
        total_orders: 678.43,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Vodka, Bourbon, Gin, Romantic, Upscale, Exotic, Enticing, Traditional, Sleek',
        annual_dol: 753.23,
        description:
            'Regional Indian fare is highlighted at this upscale spot with cocktails & modern murals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -74.0032,
        total_orders: 3751.21,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Coffee, Gin, Martini, Salad, Pinacolada, Lively, Sophisticated, Chic, Classic, Artisanal',
        annual_dol: 5224.46,
        description:
            'Small plates are paired with gin-based cocktails at this speakeasy-style bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7405,
        store_longitude: -73.998,
        total_orders: 3235.28,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Cheesesteak, Salad, Tasty, Wine, Gluten Free, Coffee, Dessert, Beer',
        annual_dol: 6855.87,
        description:
            'A swanky take on a diner attracting people-watchers & post-clubbers late nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7356,
        store_longitude: -74.0004,
        total_orders: 969.62,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Delicious, Beer, Italian, Cozy, Fun, Friendly, Wonderful, Cool, Great',
        annual_dol: 995.72,
        description:
            'This second-floor bar offers video games & trivia contests plus old movies on a big screen & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5414,
        store_longitude: -74.1782,
        total_orders: 106.18,
        neighborhood: 'Woodrow',
        keywords: 'Sushi, Burger, Tacos, Salad, Pizza, Dessert, Italian, Wine, Elegant, Friendly',
        annual_dol: 234.82,
        description:
            'Elegant Italian restaurant serving wine & upmarket cuisine like veal, filet mignon & pasta entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9602,
        total_orders: 350.24,
        neighborhood: 'Williamsburg',
        keywords:
            'Sushi, Breakfast Burrito, Japanese, Bbq, Mediterranean, Ramen, Rustic, Asian, Funky, Casual',
        annual_dol: 408.25,
        description:
            'This rustic Japanese restaurant with a communal table serves udon, sushi, miso & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7102,
        store_longitude: -73.9687,
        total_orders: 1131.14,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Lobster, Steak, Salad, Coffee, Italian, Vodka, Bbq, French, Thai, American',
        annual_dol: 1911.29,
        description:
            'Relaxed waterside restaurant specializing in oysters, frozen cocktails & locally sourced pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7425,
        store_longitude: -73.9844,
        total_orders: 1325.42,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Lobster Roll, Lobster, Burger, Tomato Soup, Salad, Californian, Margarita, Wine, Beer, Fruity',
        annual_dol: 1450.91,
        description:
            'Posh chain serving American fare, including brunch, dinner & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.9846,
        total_orders: 3339.19,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Tequila, Mexican, Margarita, Upscale, Gluten Free, Delicious, Hotel, Innovative, Vibrant, Premium',
        annual_dol: 3582.07,
        description:
            'Vibrant cantina serving modern Mexican cuisine, made-to-order guacamole & tequila cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7403,
        store_longitude: -73.9736,
        total_orders: 1151.17,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Upscale, Canadian, Elegant, Delicious, Swiss, Crisp, Dessert, Hotel, American',
        annual_dol: 1234.99,
        description:
            'Upscale floating eatery serving American classics in elegant surroundings with East River views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.723,
        store_longitude: -73.9506,
        total_orders: 2973.99,
        neighborhood: 'Greenpoint',
        keywords:
            'Beer, Delicious, Cozy, Friendly, Intimate, Eclectic, Quirky, Great, Traditional, Casual',
        annual_dol: 3040.41,
        description:
            'Cozy tavern with refurbished wood decor, featuring craft brews, specialty cocktails & charcuterie.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7645,
        store_longitude: -73.9701,
        total_orders: 1172.28,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Italian, Mexican, Mediterranean, Luxurious, Brazilian, Chic, Sumptuous, Rooftop, Casual',
        annual_dol: 5072.64,
        description:
            '"Casa Cruz New York is located in a stunning beaux-arts townhouse on 61st Street, between Park and Madison Avenues. Comprised of five floors, the space is inclusive of bars and dining rooms, special event spaces, and a rooftop terrace and will accommodate breakfast, lunch, dinner, and cocktails. Casa Cruz New York will display an unwavering attention to detail and unparalleled design, offering the high-level cuisine, cocktails and hospitality for which Santa Cruz Co. restaurants and bars have become known."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7489,
        store_longitude: -73.9754,
        total_orders: 202.64,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Gluten Free, Beer, Spicy, Wine, Chili Chicken, Japanese, Thai, Asian, Ramen',
        annual_dol: 261.31,
        description:
            'Asian-inspired & Japanese chain restaurant where dishes are whisked to long communal tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7698,
        store_longitude: -73.9578,
        total_orders: 82.48,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Kebab, Dessert, Wine, Elegant, Warm, Homemade, Mediterranean, Modern, Sweet',
        annual_dol: 91.97,
        description:
            'Understated restaurant with a warm atmosphere offering a traditional Persian menu & full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7534,
        store_longitude: -73.9325,
        total_orders: 108.3,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Delicious, Cozy, Bbq, Ecuadorian, Fun, Thai, Friendly, Dutch, Amazing, Latin',
        annual_dol: 362.45,
        description:
            'Casual lounge bar, night club & restaurant offering brunch & dinner menus, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7317,
        store_longitude: -73.9899,
        total_orders: 4089.2,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Upscale, Classy, Fun, Amazing, Great, Excellent, Casual',
        annual_dol: 4089.2,
        description:
            '25 billiards tables plus Ping-Pong, darts, foosball, full bar & food near Union Square.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7312,
        store_longitude: -73.9856,
        total_orders: 746.46,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Delicious, Cozy, Tangy, Fun, Friendly, Bourbon, Irish, Bbq, Lively',
        annual_dol: 935.84,
        description:
            'Lively, Southern-flavored joint with signature cocktails, craft beers & a live band on Sundays.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -73.9914,
        total_orders: 576.85,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Pizza, Vegetarian, Vegan, Italian, Gluten Free, Wine, Mediterranean, Gin, Campari, Dope',
        annual_dol: 643.25,
        description:
            'Old-world Neapolitan pizza & Italian appetizers in a lively, high-ceilinged space with soccer on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8318,
        store_longitude: -73.8661,
        total_orders: 70.57,
        neighborhood: 'East Bronx/Soundview',
        keywords:
            'Steak, Lobster, Lobster Tail, Salad, Shrimp Cocktail, Shrimp Scampi, Karaoke, Spanish, Thai, Puerto Rican',
        annual_dol: 76.91,
        description:
            'Unfussy eatery serving Puerto Rican fare & cocktails such as coquito in a warm atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7385,
        store_longitude: -73.9896,
        total_orders: 125.02,
        neighborhood: 'Midtown Manhattan',
        keywords: 'American, Ale, Contemporary, Local, Casual',
        annual_dol: 511.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 26173.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Rich, Wine, Upscale, Luxurious, Salad, Crisp, Premium, Perfect, Classic',
        annual_dol: 26728.49,
        description:
            'Opulently outfitted chain restaurant specializing in prime steaks & seafood along with fine wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.9705,
        total_orders: 253.22,
        neighborhood: 'Midtown Manhattan/Tudor City/Midtown East',
        keywords:
            'Steak, Lasagna, Lobster, Cheesecake, Lobster Bisque, Tasty, Dessert, Chilean, Beer, French',
        annual_dol: 654.79,
        description:
            'Traditional steakhouse dishes, sides & cocktails in an elevated dining room with ample foliage.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7516,
        store_longitude: -73.9727,
        total_orders: 508.39,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Wine, Beer, Spicy, Japanese, Casual, Teriyaki, Perfect, Authentic',
        annual_dol: 638.48,
        description:
            'Comfy Japanese spot with a simple dining room, sushi bar, sake & brown spirits, plus tatami alcoves.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8257,
        store_longitude: -73.9536,
        total_orders: 8187.83,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Steak, Pizza, Beer, Margarita, Cuban, Wine, Spanish, Delicious, Puerto Rican, Dominican',
        annual_dol: 13427.23,
        description:
            'Traditional Puerto Rican fare artfully presented in a lively, modern setting with happy hour & DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9709,
        total_orders: 2705.05,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Salad, Sushi, Wine, Spicy, Delicious, Beer, Shrimp Scampi, Elegant',
        annual_dol: 2799.8,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9857,
        total_orders: 920.69,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Salad, Vegan, Japanese, Spicy, Ramen, Vintage, Rum, Southern, Casual, Adventurous',
        annual_dol: 3763.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7539,
        store_longitude: -73.9999,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Delicious, Dessert, Healthy, Fresh, Casual, Classic, Local, Rooftop, Signature',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6262,
        store_longitude: -74.1763,
        total_orders: 21527.65,
        neighborhood: '',
        keywords:
            'Whiskey, Salad, Bourbon, Burger, Beer, Meatball, French Onion Soup, Fried Chicken, French, Mojito',
        annual_dol: 88117.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.744,
        store_longitude: -73.9892,
        total_orders: 3122.59,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Italian, Stylish, Coffee, Delicious, Hotel, Relaxed, Bright, Vibrant, Modern',
        annual_dol: 3188.85,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9877,
        total_orders: 30032.95,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'American, Hotel, Fabulous, Perfect, Great',
        annual_dol: 30701.5,
        description:
            'The John Golden Theatre, formerly the Theatre Masque and Masque Theater, is a Broadway theater at 252 West 45th Street in the Theater District of Midtown Manhattan in New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9898,
        total_orders: 1781.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Vegetarian, Spicy, Rich, Chinese, Thai, Exquisite, Asian, Sweet, Premium',
        annual_dol: 6712.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7384,
        store_longitude: -73.9857,
        total_orders: 617.12,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Italian, Elegant, Hotel, Rustic, Warm, Classic, Superb, Welcoming, Perfect',
        annual_dol: 2522.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -74.0014,
        total_orders: 721.09,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Italian, Wine, Cozy, Modern, Casual, Traditional',
        annual_dol: 737.89,
        description:
            'Tuscan spot offering quick-serve cafe fare by day & trattoria dishes with wine at night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6814,
        store_longitude: -73.9939,
        total_orders: 3960.54,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Tequila, Mexican, Margarita, Salad, Delicious, Relaxed, Friendly, Latin, American, Authentic',
        annual_dol: 4366.41,
        description:
            'Tequila-based craft cocktails meet Mexican tapas at this rustic-chic bar with a back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6808,
        store_longitude: -73.9969,
        total_orders: 173.29,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Italian, Wine, Martini, Bruschetta, Homemade, Creamy, Romantic, Classy, Hospitable, Exotic',
        annual_dol: 539.98,
        description:
            'Fresh Italian food dished in a brick-walled room anchored by an L-shaped wood bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7526,
        store_longitude: -73.9242,
        total_orders: 177.09,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tequila, Mexican, Upscale, Dessert, Intimate, Classy, Traditional, Contemporary, Excellent, Casual',
        annual_dol: 341.25,
        description:
            '"We\'re a contemporary Mexican restaurant & Bar located in Long Island City, recently opened our doors for Mexican food lovers that want to have a very special experience. We serve traditional recipes mixed with contemporary flavors and have a huge variety of different types of tequilas and cocktails. Come see us today!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7459,
        store_longitude: -73.89,
        total_orders: 24.66,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Fun, Turkish, Great, Modern, Casual',
        annual_dol: 100.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7547,
        store_longitude: -73.9705,
        total_orders: 533.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Wine, Flavorful, Italian, Tasty, Delicious, Cozy, Rustic, Bellini, Romantic',
        annual_dol: 621.61,
        description:
            'Northern Italian specialties highlighted in a rustic-chic restaurant offering terrace dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8034,
        store_longitude: -73.6461,
        total_orders: 372.86,
        neighborhood: '',
        keywords:
            'Kebab, Salad, Steak, Mexican, Beer, Coffee, Dessert, Japanese, Turkish, Mediterranean',
        annual_dol: 964.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6264,
        store_longitude: -74.0241,
        total_orders: 1261.1,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Coffee, Wine, French Onion Soup, French, Beer, Bbq',
        annual_dol: 1287.85,
        description:
            'Longtime restaurant & bar offering a menu of burgers & other American eats, plus outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9727,
        total_orders: 968.9,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Scandinavian, Nordic, Salad, Wine, Dessert, Danish, Meatball, French, Seasonal, Premium',
        annual_dol: 1010.91,
        description:
            'High-end Scandinavian tasting & fixed-price menus served in a minimalist Nordic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7547,
        store_longitude: -73.9665,
        total_orders: 417.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Wine, Burger, Salad, Stylish, Cozy, French, Beer, Romantic, Classy',
        annual_dol: 426.33,
        description:
            'Parisian-style bistro with outdoor tables dishing up French classics, from coq au vin to couscous.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6715,
        store_longitude: -73.9502,
        total_orders: 2870.78,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Pizza, Salad, Wine, Italian, Beer, Margarita, Painkiller, Spicy, Cozy',
        annual_dol: 2870.78,
        description:
            'Caribbean-inspired cocktails like Mai Tais, plus beer & wine, served in a retro, low-key space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.987,
        total_orders: 1178.4,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Hamburger, Vegetarian, Vegan, Salad, Gourmet, Beer, Bbq, Danish, Wine',
        annual_dol: 1203.41,
        description:
            'Contemporary chain with customizable gourmet burgers, including vegetarian, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.9854,
        total_orders: 3337.22,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Burger, Salad, Chicken Parmigiana, Gluten Free, Italian, Dessert, Shrimp Scampi, Meatball, Delicious',
        annual_dol: 3414.77,
        description:
            'Bustling eatery serving large, shareable portions of Italian comfort food in a lively setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6914,
        store_longitude: -73.9979,
        total_orders: 329.54,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Karaoke, Beer, Wine, American, Great, Casual',
        annual_dol: 336.53,
        description:
            'Longtime watering hole with a weathered bar & pool table in a nautical-themed setting near Pier 6.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9931,
        total_orders: 68.32,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Tasty, Spicy, Rustic, Seasonal, Classy, Laid-Back',
        annual_dol: 301.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7806,
        store_longitude: -73.9529,
        total_orders: 1073.82,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Steak, Salad, Chicken Parmigiana, Italian, Gluten Free, Meatball, Tasty, Shrimp Scampi, Savory',
        annual_dol: 1096.6,
        description:
            'Casual Italian fare with cocktails & wine in a contemporary, rustic setting with patterned tiles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.9811,
        total_orders: 839.32,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Upscale, Beer, Tasty, Hotel, Gourmet, Delicious, Opulent, American, Irish',
        annual_dol: 3746.16,
        description:
            '"Irish American Bar serving good quality tasty dishes. Only steps from Bryant Park and Time Square. Happy Hour daily from 11am-7pm. Great cocktails and great service."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.993,
        total_orders: 1414.05,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Champagne, Hurricane, Beer, Burger, Wine, Margarita, Mimosa, Fun, Great, Clean',
        annual_dol: 1612.97,
        description:
            'Hardware store turned buzzy nightclub with a large dance floor & DJs plus nightly live performances.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.9798,
        total_orders: 1084.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Salad, Hamburger, Dessert, French Toast, French, Delicious, English Muffin, Quaint, Homemade',
        annual_dol: 1107.34,
        description:
            'A neighborhood veteran serving classic French bistro fare in a rustic, small space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7788,
        store_longitude: -73.9784,
        total_orders: 356.4,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Wine, Dessert, Turkish, Rustic, Mediterranean, Cozy, Casual, Perfect, American',
        annual_dol: 380.09,
        description:
            'Cozy Turkish eatery serving wood-fired flat breads, seafood & meat plates, plus wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9942,
        total_orders: 2130.66,
        neighborhood: 'Nolita',
        keywords:
            'Lemon Drop, Tequila, Wine, Vodka, Tasty, Margarita, Beer, Martini, Gin, Delicious',
        annual_dol: 2223.04,
        description: 'A low-key spot for beer & drinks with benches & outdoor tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.724,
        store_longitude: -73.7263,
        total_orders: 15.65,
        neighborhood: 'Bellerose Manor',
        keywords:
            'Vegetarian, Kebab, Chinese, Juicy, Opulent, Sweet, Tandoori Chicken, Indian, Amazing, Tandoori Lamb',
        annual_dol: 63.99,
        description:
            'Indian specialties along with Chinese dishes served in an expansive space with opulent decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7251,
        store_longitude: -73.6344,
        total_orders: 1814.4,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Lobster Roll, Steak, Salad, Gluten Free, Coffee, Beer, Italian, Delicious',
        annual_dol: 1933.87,
        description:
            'Old-school bar & grill (circa 1942) with dark wood interior for homey American classics & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7369,
        store_longitude: -74.0025,
        total_orders: 2494.03,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Chicken Pot Pie, Wine, Bbq, Delicious, American, Quaint, Cozy, Premium, Timeless',
        annual_dol: 2494.03,
        description:
            'High-end renditions of American comfort fare are served with cocktails in a clubby space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7829,
        store_longitude: -73.9802,
        total_orders: 718.99,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Bbq, American, Perfect, Exquisite, Casual, Local, Great, Delectable, Diverse',
        annual_dol: 2518.17,
        description:
            'Modest comedy club presenting young & seasoned performers plus occasional big names.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.8961,
        total_orders: 101.7,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, South American, American, Premium, Fantastic, Great, Casual',
        annual_dol: 131.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7495,
        store_longitude: -73.7078,
        total_orders: 2125.77,
        neighborhood: 'Glen Oaks',
        keywords: 'Italian, Pizza, Salad, Wine, Beer, Rustic, Mediterranean, Cozy, Warm, Homemade',
        annual_dol: 2196.75,
        description:
            'Neighborhood Italian restaurant serving up housemade pastas & wood-fired pizzas in a cozy setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.5881,
        total_orders: 62.03,
        neighborhood: '',
        keywords: 'Relaxed, Friendly, Casual, Local, Great',
        annual_dol: 64.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7703,
        store_longitude: -73.9185,
        total_orders: 183.23,
        neighborhood: 'Astoria',
        keywords:
            'Beer, Salad, Greek, Spacious, Mediterranean, Premium, Fresh, Cool, Greek Salad, Authentic',
        annual_dol: 320.86,
        description:
            'Family-owned Greek place serving traditional fare in an unpretentious space with a bar & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6845,
        store_longitude: -73.6152,
        total_orders: 4.78,
        neighborhood: '',
        keywords:
            'Salad, Chicken Caesar Salad, Jamaican, Caribbean, Beer, Spicy, Caesar Salad, Exotic, Spacious, Casual',
        annual_dol: 19.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7734,
        store_longitude: -73.9167,
        total_orders: 61.3,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Pizza, Salad, Dessert, Coffee, Italian, Margarita, Cozy, Crisp, Premium, Perfect',
        annual_dol: 187.81,
        description:
            '"Only in Astoria can you bring together the best of Italian-pizza making, the best American beer, the best wines from all over the world, exceptional service with a friendly face and attitude, an affordable check, and the quickest service possible! Pizza and drinks, family and friends, and a space where all are welcome. We find joy in bringing the best things together. We are diverse team situated in a diverse neighborhood and our mission is to bring together people from the neighborhood, the greater city, and out of town guests. Food, drinks, and fun unite us. Macoletta is a family-friendly place: we love kids and will be offering pizza classes on weekend mornings."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7241,
        store_longitude: -73.9504,
        total_orders: 2224.05,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Beer, Wine, Delicious, Ale, Cozy, American, Perfect, Exceptional, Classic',
        annual_dol: 2277.52,
        description:
            'This cozy bar offers flat screen TVs for sports fans, plus classic & creative American menu options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.734,
        store_longitude: -73.9551,
        total_orders: 2130.98,
        neighborhood: 'Greenpoint',
        keywords: 'Salad, Vodka, Tequila, Gin, Chinese, Rum, Polish, Classy, Casual, Fun',
        annual_dol: 2190.09,
        description:
            'Compact restaurant serving Taiwanese cuisine & cocktails amid an easygoing atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9195,
        total_orders: 547.87,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Margarita, Salad, Mexican, Steak, Nachos, Tasty, Fajitas, Enchiladas, Ale',
        annual_dol: 571.63,
        description:
            'Low-lit Mexican spot offering a variety of margaritas & a menu of tacos, enchiladas & the like.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6789,
        store_longitude: -73.9872,
        total_orders: 3700.68,
        neighborhood: 'Gowanus',
        keywords:
            'Delicious, Fun, Playful, Fantastic, Great, Excellent, Smooth, Ethiopian, Floridian, Retro',
        annual_dol: 4325.43,
        description:
            'Shuffleboard courts draw playful crowds to this big bar with food trucks & occasional live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7052,
        store_longitude: -74.0162,
        total_orders: 988.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Luxurious, Martini, Stylish, American, Spicy, Classic, Modern, Innovative, Contemporary',
        annual_dol: 3725.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -74.0119,
        total_orders: 1223.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Salad, Beer, Wine, Irish, Warm, Fun, Welcoming, Friendly, Vibrant',
        annual_dol: 1308.52,
        description:
            'A classic Irish standby with dark wood, outdoor seating & a menu of beer & pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.724,
        store_longitude: -74.0098,
        total_orders: 335.48,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Chinese, Upscale, Hawaiian, Asian, Savory, Elegant, Turkish, Vintage, Mediterranean',
        annual_dol: 1371.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7039,
        store_longitude: -74.0129,
        total_orders: 1195.81,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Wine, Beer, Salad, Irish, Juicy, Japanese, French Onion Soup, American, French',
        annual_dol: 3265.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.9975,
        total_orders: 248.37,
        neighborhood: 'Little Italy',
        keywords:
            'Vegetarian, Italian, Shrimp Cocktail, Wine, Delicious, Spicy, Organic, Homemade, Mediterranean, Bruschetta',
        annual_dol: 290.07,
        description:
            'Traditional Italian dishes & desserts in a relaxed, white-tablecloth setting with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7211,
        store_longitude: -73.9979,
        total_orders: 848.06,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Italian, Upscale, Organic, Rich, Mediterranean, Spacious, Casual, Greek, Fresh',
        annual_dol: 3547.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7767,
        store_longitude: -73.9528,
        total_orders: 73.84,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Vegan, Salad, Pizza, Tasty, French Toast, Beer, French, Swiss, Modern, American',
        annual_dol: 82.28,
        description:
            '"Green Kitchen has proudly served the great city of New York since 1931 by providing breakfast, lunch and dinner in a casual family atmosphere. We strive to provide each one of our patrons with the utmost courtesy and consideration in order to maximize the dining experience. As part of our commitment to quality, we prepare our cuisine fresh each day using only the finest ingredients from carefully selected vendors. Our staff is always dedicated to taking exceptional care of you, our guests. We look forward to serving you soon!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9769,
        total_orders: 1913.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Pizza, Chicken Parmigiana, Italian, Wine, Beer, Spicy, French, Urban, Rooftop',
        annual_dol: 7336.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7809,
        store_longitude: -73.9585,
        total_orders: 60.24,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Italian, Salad, Burger, Pizza, Wine, Dessert, Upscale, Whiskey, Rustic, Hotel',
        annual_dol: 246.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7013,
        store_longitude: -73.9147,
        total_orders: 11787.77,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Vodka, Rum, Delicious, Hotel, Friendly, Vintage, Eclectic, Clean, Cool',
        annual_dol: 13425.46,
        description:
            'Chic cocktail bar with a vintage vibe & heated patio offering a pub menu & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6946,
        store_longitude: -73.9308,
        total_orders: 97.12,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Italian, Gourmet, Wine, Beer, Dessert, Martini, American, Cozy, Premium',
        annual_dol: 453.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7653,
        store_longitude: -73.9694,
        total_orders: 281.36,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'French, Elegant, Hotel, Comfortable, Modern, Mediterranean, Italian, Casual, Fine, American',
        annual_dol: 662,
        description:
            '"Please know that your reservation is for outdoor dining only. We Are Open for Outdoor Dining With Both Heaters and Plexiglass Dividers Between Each Table for Your Safety! Pick-Up and Delivery Available. Make Your Lunch or Dinner Reservation Now!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6971,
        store_longitude: -73.9352,
        total_orders: 457.59,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Spacious, Cozy, Friendly, Great, Classic, Casual, Local',
        annual_dol: 700.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8537,
        store_longitude: -73.7905,
        total_orders: 635.88,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Steak, Lobster Tail, Seafood Paella, Delicious, Italian, Upscale, Polished, Spanish, Latin',
        annual_dol: 952.17,
        description:
            'Waterside eatery & lounge with outdoor seating & traditional Latin American recipes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7105,
        store_longitude: -74.0164,
        total_orders: 41.9,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Local, Casual',
        annual_dol: 171.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.666,
        store_longitude: -73.806,
        total_orders: 386.71,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'American, Hotel, Premium, Contemporary',
        annual_dol: 396.86,
        description:
            "All rooms offer contemporary decor and custom-designed beds, plus flat-screen TVs with premium cable channels, microwaves, minifridges and free WiFi.A cooked-to-order breakfast is available (fee), and there's an on-site restaurant serving American cuisine, a bar and a 24/7 convenience store. There's also a 24/7 fitness center. Additional amenities include 1,200 sq ft of meeting rooms and a business center, plus a free airport shuttle.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6414,
        store_longitude: -73.7927,
        total_orders: 12259.81,
        neighborhood: 'Jamaica',
        keywords: 'Glamorous, Hotel, Tasty, Dessert, Delicious, Coffee, Salad, Fun, Sleek, Vintage',
        annual_dol: 13332.1,
        description:
            "Manor Museum and 12 miles from Rockaway Beach.Featuring soundproofed floor-to-ceiling windows, the stylish, retro rooms come with complimentary Wi-Fi and flat-screen TVs, plus minifridges, safes and fully stocked cocktail bars. Some have airport views.There's a sleek 1960s-inspired bar, a food hall with grab-and-go options, and a celebrity chef-helmed restaurant/cafe. Amenities include a 10,000-sq-ft gym, meeting space, and an outdoor pool with runway views.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9161,
        total_orders: 313.86,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Lobster, Tequila, Lobster Tail, Mexican, Beer, Margarita, Wine, Upscale, Trendy',
        annual_dol: 1329.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7277,
        store_longitude: -73.9935,
        total_orders: 1269.86,
        neighborhood: 'NoHo',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Wine, Coffee, Dessert, Organic, French, Danish, Delicious',
        annual_dol: 1296.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9896,
        total_orders: 9182.37,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Dessert, Savory, Delicious, Sweet, Beer, Japanese, Irish, Vibrant, Classic, Casual',
        annual_dol: 28236.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.764,
        store_longitude: -73.7714,
        total_orders: 12548.98,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Lobster, Cajun, Salad, Lobster Bisque, Jambalaya, Gluten Free, Italian, Bbq, Spicy',
        annual_dol: 12979.99,
        description:
            'Cajun & Southern-accented grub, plus Sunday brunch served in a lively spot with TVs & a rooftop bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.975,
        total_orders: 1518.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Coffee, Elegant, Hotel, Warm, French, Brazilian, Sophisticated, Intimate, Contemporary',
        annual_dol: 6652.51,
        description:
            '"Fasano Restaurant is a fine dining Italian restaurant in NYC. Located in the Theater District, in the heart of Midtown, the restaurant offers four distinct and elegantly decorated dining rooms. Rooted in traditional Italian gastronomy with contemporary flare, the restaurant is composed of Osteria, the front-of-house cocktail bar with a casual feel, a sophisticated Main Dining Salon, a reserved Private Dining Room, and Baretto – an intimate live music space with a jazz club ambience. Fasano welcomes private events, meetings and celebrations for up to 120 guests."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -74.0134,
        total_orders: 629.07,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Hamburger, Cheesesteak, Tasty, Beer, American, Philly Cheesesteak, Comfortable, Fantastic',
        annual_dol: 656.34,
        description:
            'Easygoing brick-lined pub serving cheesesteaks, wings & other pub fare, plus beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5941,
        store_longitude: -73.9978,
        total_orders: 189.58,
        neighborhood: '',
        keywords:
            'Lasagna, Burger, Tacos, Pizza, Sushi, Salad, Chicken Parmigiana, Meatball, Italian, Gluten Free',
        annual_dol: 201.75,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9748,
        total_orders: 154.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Trendy, Luxurious, Mediterranean, Seasonal, Homemade, Asian, Healthy, Polished, Exquisite',
        annual_dol: 204.14,
        description:
            "A Midtown power-lunch scene that's fueled by high-end Italian dining & expense accounts.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9859,
        total_orders: 5465.58,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Luxurious, Elegant, Trendy, Hotel, Crisp, Superb, Perfect, Great, Fresh, Classic',
        annual_dol: 5606.14,
        description:
            '"The Edison Ballroom is an elegant private event space in the heart of Times Square, New York City. Originally opened in the 1930’s as the Grand Ballroom of the adjacent Edison Hotel, this historical venue reopened in 2008 after a multi-million dollar renovation that preserved its original flair and art deco style. This expansive, multi-level venue is perfect for hosting any type of private event – weddings, Bar and Bat Mitzvahs, holiday parties, nonprofit events, galas, business conferences, red carpet fashion shows and more. For every event The Edison Ballroom provides its superb in-house catering prepared by celebrity chef Mina Newman."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7821,
        store_longitude: -73.9534,
        total_orders: 804.42,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Vegetarian, Salad, Pizza, Italian, Wine, Upscale, Beer, Hotel, Warm, Classy',
        annual_dol: 821.49,
        description:
            'Authentic Italian fare draws crowds to this sophisticated Upper East Side eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9912,
        total_orders: 500.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Gluten Free, Coffee, Vietnamese, Chili Chicken, Thai, Spicy, Vodka, Fried Chicken, Rich, Bbq',
        annual_dol: 511.07,
        description:
            'Sleek destination turning out Asian fusion BBQ & small plates with creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9806,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Hotel, Italian, Upscale, Great, Perfect',
        annual_dol: 96.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7305,
        store_longitude: -74.0006,
        total_orders: 12256.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Coffee, Upscale, Wholesome, Irish, Classy, Homemade, Great, Casual',
        annual_dol: 12529.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -73.994,
        total_orders: 1981.33,
        neighborhood: 'NoHo',
        keywords: 'Steak, Burger, Salad, Wine, Italian, Beer, Upscale, Scandinavian, Spicy, French',
        annual_dol: 2088.05,
        description:
            'Restaurant & lounge featuring upscale French & Italian bistro fare, plus wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7279,
        store_longitude: -74.0014,
        total_orders: 107.52,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Coffee, Beer, Wine, Scotch, Jamaican, Salad, Cuban, Caribbean, Rum, Trendy',
        annual_dol: 175.7,
        description:
            'Diner featuring retro decor, cocktails & classic Caribbean fare such as jerk chicken & curried goat.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.9905,
        total_orders: 1787.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Steak, Burger, Whiskey, Beer, American, Hotel, Vibrant, Awesome, Perfect, Great',
        annual_dol: 1828.68,
        description:
            '"Neighborhood cocktail bar, part time sports bar serving brunch, lunch and dinner!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.9866,
        total_orders: 1205.62,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Beer, Trendy, Lively, Spacious, Fun, Premium, Classy, Friendly, Great',
        annual_dol: 1240.12,
        description:
            'Spacious bar with an industrial-chic vibe, games on TV and private karaoke rooms with VIP packages.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7464,
        store_longitude: -73.9879,
        total_orders: 858.84,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Glamorous, Hotel, Opulent, Vintage, Gourmet, Fancy, Irish, Indulgent, Iconic, Exotic',
        annual_dol: 1030.24,
        description:
            '"A new hidden gem, subterranean speakeasy lounge where thieves go to unwind in a lush and opulent atmosphere rewarding themselves after an extensive heist. Evening libations crafted by elite mixologists, paired with elevated gourmet dishes, caviar and champagne, all while being captivated by live interactive performances that will transport you to a bygone era. For those with the most discerning of tastes."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8387,
        store_longitude: -73.7831,
        total_orders: 4770.45,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Chicken Parmigiana, Steak, Italian, Beer, Casual, Serene, Excellent',
        annual_dol: 5740.83,
        description:
            'Restaurant & bar with a view of Long Island Sound serving lobster, fried seafood & more since 1946.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.9416,
        total_orders: 66.39,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Sushi, Lobster Roll, Lobster, Salad, Wine, Beer, Japanese, Spicy, Trendy, Asian',
        annual_dol: 243.72,
        description:
            'Trendy restaurant preparing Japanese staples including sushi, meat dishes, noodles & rice bowls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.9533,
        total_orders: 37.27,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Steak, Salad, Cuban, Pizza, Wine, Rum, Mojito, American, Latin, Spacious',
        annual_dol: 48.06,
        description:
            'Featuring live jazz on the weekends, this casual bistro serves Cuban steak & seafood dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7925,
        store_longitude: -73.9737,
        total_orders: 83.82,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Burger, Salad, Vegetarian, Kebab, Mediterranean, Martini, French, Egyptian, Bellini',
        annual_dol: 367.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7304,
        store_longitude: -73.8636,
        total_orders: 99.52,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Kebab, Beer, Mexican, Salad, Turkish, Mediterranean, Relaxed, Fresh, Traditional, Casual',
        annual_dol: 406.81,
        description:
            'Family-owned restaurant offering traditional Turkish comfort dishes & seafood in a relaxed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6051,
        store_longitude: -73.9721,
        total_orders: 11.39,
        neighborhood: 'Gravesend',
        keywords: 'Burger, Tasty, Beer, Wine, Elegant, French, Classy, Sweet, Amazing, Great',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7456,
        store_longitude: -73.8838,
        total_orders: 10.35,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Beer, American, Local, Casual, Classic',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8688,
        store_longitude: -73.9021,
        total_orders: 19795.42,
        neighborhood: 'Kingsbridge Heights/West Bronx',
        keywords:
            'Mexican, Steak, Salad, Shrimp Cocktail, Nachos, Margarita, Tex-Mex, Fajitas, Spicy, Enchiladas',
        annual_dol: 21776,
        description:
            'Cantina serving Tex-Mex & south-of-the-border fare alongside margaritas & other colorful cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7549,
        store_longitude: -73.9733,
        total_orders: 5328.68,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Hotel, Rich, Coffee, Warm, Vibrant, Sophisticated, Awesome, Classic, Comfortable',
        annual_dol: 5353.71,
        description:
            "The warm contemporary rooms have colorful accents and ornate marble bathrooms, along with smart TVs and free Wi-Fi. Suites add pull-out sofas; some have separate living areas and balconies.There's a classic 1920s-inspired cocktail bar and a Starbucks. There's also a 24-hour fitness room and a business center, along with 3 meeting rooms.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7126,
        store_longitude: -73.7849,
        total_orders: 80.86,
        neighborhood: 'Jamaica Estates/Jamaica',
        keywords: 'Warm, Friendly, Great, Fantastic, Casual',
        annual_dol: 90.4,
        description:
            'Snug bar with a wood-clad interior like a train car featuring sports on TV, plus happy hour deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7611,
        store_longitude: -73.9667,
        total_orders: 54.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Lobster, Salad, Bourbon, Spicy, Asian, American, Seasonal, Classic, Pure, Fine',
        annual_dol: 224.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9619,
        total_orders: 178.37,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Pizza, Italian, Salad, Rich, Delicious, Sparkling, Rustic, Fun, Caesar Salad, Fantastic',
        annual_dol: 729.13,
        description:
            '"Coco Pazzeria features thin crust Pizza, Oysters and Bubbles, combining the oysters taste of minerality with the rich flavor and crunchiness of thin crust pizzas - all is topped off with the effervescence of sparkling wines. The menu also features a curated selection of Buzzetti (mini “open face” calzones), Panozzi (sandwich on rustic bread), Pastas, Salads and the house specialty – Focaccia. CHILDREN\'S POLICY: Out of our concerns for safety, space limitations and an abundance of caution, we cannot permit strollers or children under 5 in our dining room. Our limited area of passage with hot and/or heavy items being carried by our staff may cause safety concerns for our guests. we appreciate and thank your for understanding."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9862,
        total_orders: 17784.04,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Steak, Hamburger, Italian, Beer, Nachos, Coffee, Bbq, Spicy, Dessert',
        annual_dol: 18189.77,
        description:
            'Rock ’n’ roll-themed chain with a high-energy vibe serving burgers & American classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9925,
        total_orders: 352.85,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Burger, Cheesesteak, Beer, Wine, Organic, American, Philly Cheesesteak, Authentic, Clean',
        annual_dol: 1238.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5563,
        store_longitude: -73.9226,
        total_orders: 493.2,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords: 'Burger, Authentic, American, Classic, Sweet, Cool, Casual, Unique',
        annual_dol: 2417.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7534,
        store_longitude: -73.994,
        total_orders: 178.82,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Healthy, Friendly, Authentic, Natural, Signature, Rare, Unique',
        annual_dol: 186.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7466,
        store_longitude: -73.9887,
        total_orders: 3224.38,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Hotel, Trendy, Urban, Eclectic, Vibrant, Intimate, Lively, Seasonal, Rooftop, Perfect',
        annual_dol: 13180.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7239,
        store_longitude: -73.9939,
        total_orders: 4908.42,
        neighborhood: 'Nolita',
        keywords:
            'Champagne, Wine, Vegan, Beer, Dessert, Delicious, Sparkling, American, Rustic, Indulgent',
        annual_dol: 6340.22,
        description:
            'Modern takes on internationally inspired food served in rustic-chic environs with a backyard garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.995,
        total_orders: 2883.13,
        neighborhood: 'NoHo',
        keywords:
            'Korean, Japanese, Delicious, Asian, Warm, Vietnamese, Thai, French, Mediterranean, American',
        annual_dol: 3827.27,
        description:
            '"KYU a fresh concept born in Miami in 2016, now expanding to New York City. Our wood-fired Asian grill features a diverse menu and focuses on professional yet relaxed service and a commitment to green practices. Our menu is influenced by years of global travel and inspired by Japanese and Korean yakiniku (literally translated to grilled meat in Japanese). The plates are designed to be straightforward and shareable, enabling a warm social environment. A carefully curated cocktail list features variations of classic cocktails with clever Asian twists."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.883,
        total_orders: 1415.02,
        neighborhood: 'Jackson Heights',
        keywords:
            'Margarita, Mexican, Colombian, Salad, Wine, South American, Fajitas, Latin, Spanish, American',
        annual_dol: 1542.15,
        description:
            'Unfussy locale featuring Colombian & Tex-Mex specialties, plus frozen cocktails & TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7741,
        store_longitude: -73.9081,
        total_orders: 828.66,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Burger, Hamburger, Salad, Meatball, Coffee, Wine, Spicy, American, Polished',
        annual_dol: 1845.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6652,
        store_longitude: -73.9615,
        total_orders: 2072.52,
        neighborhood: 'Crown Heights',
        keywords: 'Coffee, Hotel, Elegant, Exotic',
        annual_dol: 2360.45,
        description:
            'Brooklyn Botanic Garden is a botanical garden in the borough of Brooklyn, New York City. It was founded in 1910 using land from Mount Prospect Park in central Brooklyn, adjacent to Prospect Park and the Brooklyn Museum. The 52-acre garden holds over 14,000 taxa of plants and has nearly a million visitors each year.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6999,
        store_longitude: -73.9287,
        total_orders: 360.94,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Steak, Meatball, Italian, Beer, Tasty, Trendy, Amazing, Cozy, Great',
        annual_dol: 368.6,
        description:
            'Pan pizza is served with cocktails & locally brewed beer at this laid-back neighborhood haunt.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7215,
        store_longitude: -73.9426,
        total_orders: 826.18,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Wine, Beer, Cozy, Casual, Cool',
        annual_dol: 881.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7647,
        store_longitude: -73.9708,
        total_orders: 37.53,
        neighborhood: 'Lenox Hill',
        keywords: 'Italian, Beer, Irish, American, Warm, High-End, Greek, Casual',
        annual_dol: 159.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8542,
        store_longitude: -73.884,
        total_orders: 34.91,
        neighborhood: 'Belmont/West Bronx',
        keywords: 'Dominican, Classy, Local, Casual',
        annual_dol: 142.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.839,
        store_longitude: -73.7836,
        total_orders: 15759,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Vegetarian, Cornbread, Lobster Bisque, Italian, Wine, Martini, Creamy',
        annual_dol: 16874.73,
        description:
            'Bustling, spacious family seafood spot, with big portions, booth seating & stained-glass decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6855,
        store_longitude: -74.0023,
        total_orders: 1203.06,
        neighborhood: 'Columbia Street Waterfront District',
        keywords: 'Tacos, Steak, Mexican, Salad, Margarita, Beer, Spicy, American, Casual, Classic',
        annual_dol: 1228.58,
        description:
            'Mexican food, ground-floor bar & year-round roof deck with a Manhattan view, drawing a young crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9535,
        total_orders: 1212.52,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Burger, Hamburger, Beer, Salad, Whiskey, Champagne, Wine, Hotel, Retro, Fancy',
        annual_dol: 1290.35,
        description: 'Wines & charcuterie at this bar with a comfy retro vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7293,
        store_longitude: -73.9889,
        total_orders: 136.71,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Sushi, Steak, Tasty, Japanese, Sashimi, Delicious, Modern, Casual, Intimate, Fresh',
        annual_dol: 142.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.731,
        store_longitude: -73.9886,
        total_orders: 1730.05,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Dessert, French Toast, French, Delicious, Trendy, Gin',
        annual_dol: 1805.05,
        description:
            'Trendy types gather for American eats & specialty drinks at this upbeat hangout & brunch favorite.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5427,
        store_longitude: -74.1425,
        total_orders: 22.02,
        neighborhood: 'Great Kills',
        keywords:
            'Steak, Burger, Lobster, Tacos, Sushi, Salad, Pizza, Shrimp Cocktail, Cajun, Italian',
        annual_dol: 90.02,
        description:
            'Casual eatery with a bar, deck & views of the harbor marina serving seafood, meats & pasta.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6127,
        store_longitude: -74.0653,
        total_orders: 92.81,
        neighborhood: 'Rosebank',
        keywords:
            'Burger, Beer, Tasty, Wine, Classic, Outstanding, Great, American, Cool, Wonderful',
        annual_dol: 387.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.8831,
        total_orders: 3.66,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords:
            'Mexican, Tacos, Tequila, Nachos, Salad, Margarita, Delicious, Authentic, Fresh, Casual',
        annual_dol: 27.89,
        description:
            'Compact, neighborhood eatery offering generous plates of Mexican fare in a down-to-earth atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5276,
        store_longitude: -74.2314,
        total_orders: 30.64,
        neighborhood: 'Charleston',
        keywords: 'Golfclub, Unique',
        annual_dol: 107.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 2777.82,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegan, Upscale, Italian, Organic, Pizza, Delicious, Polished, Charming, Homemade, Bellini',
        annual_dol: 2836.76,
        description:
            'Upscale scene where an international crowd tucks into Italian food chased with Bellinis.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.9782,
        total_orders: 185.58,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Beer, Wine, Irish, Awesome, Impressive, Fresh, Perfect',
        annual_dol: 841.48,
        description:
            '"Rockefeller Center Taproom serving IPAs, Stouts, Lagers, and Seltzer from our brewery in Brooklyn. Also featuring wine, snacks, and cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7986,
        store_longitude: -73.6635,
        total_orders: 5785.44,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Cajun, Italian, Wine, Beer, American, Prime Rib, Polished',
        annual_dol: 5908.2,
        description:
            'Modern steakhouse featuring meats aged on-site, seafood, traditional sides & an extensive wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8253,
        store_longitude: -73.9509,
        total_orders: 51.45,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Lasagna, Wine, Italian, Upscale, Beer, Cozy, Delicious, Premium, Meatball, Intimate',
        annual_dol: 597.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.9681,
        total_orders: 3137.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Hamburger, Dessert, American, English Muffin, Eggs Benedict, Trendy, Hotel, Bellini, Contemporary',
        annual_dol: 3339.43,
        description:
            'Trendy types gather for American eats & specialty drinks at this upbeat hangout & brunch favorite.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9666,
        total_orders: 2829.93,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Beer, Salad, Whiskey, Ale, Tuna Melt Sandwich, American, Cozy, Hip, Artisanal',
        annual_dol: 2995.96,
        description:
            'Hip spot with elevated bar food & a rotating list of 42 local & international craft beers on tap.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6262,
        store_longitude: -74.0301,
        total_orders: 451.87,
        neighborhood: 'Bay Ridge',
        keywords: 'Wine, Beer, Russian, Juicy, Outstanding, Fantastic, Friendly, Great, Casual',
        annual_dol: 1847.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.9898,
        total_orders: 5241.67,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Vegetarian, Sushi, Salad, Japanese, Gluten Free, Dessert, Bbq, Beer, Delicious',
        annual_dol: 5883.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6745,
        store_longitude: -73.9502,
        total_orders: 168.04,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Cornbread, Salad, Korean, Southern Comfort, Caesar Salad, Southern, American, Modern, Casual',
        annual_dol: 235.7,
        description:
            'Cozy, brick-lined hub with hearty familiar fare, a charming patio, craft cocktails & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9892,
        total_orders: 5486.03,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Cajun, Wine, Upscale, Caesar Salad, Ribeye Steak',
        annual_dol: 5809,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.9845,
        total_orders: 15559.29,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Tequila, Trendy, Hotel, Stylish, Eclectic, Vibrant, American, Fabulous, Rooftop, Bright',
        annual_dol: 16393.97,
        description:
            "from the nearest subway station.Featuring art by local students, the stylish rooms offer Wi-Fi access and smart TVs; some have sofas or bunk beds. The 1- and 2-bedroom suites add living rooms. Room service is available.Amenities include a polished, wood-paneled restaurant, a trendy North African eatery and a vintage-chic cocktail bar. There's also a 24-hour gym and a game room, as well as a resident artist program.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9908,
        total_orders: 1491.91,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Delicious, Karaoke, Dessert, Meatball, Legendary, Friendly, Perfect, Quirky, Great, Excellent',
        annual_dol: 1523.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7374,
        store_longitude: -73.9844,
        total_orders: 1543.32,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Wine, Beer, Whiskey, Irish, Upscale, Delicious, Ale, Classy, Awesome, Friendly',
        annual_dol: 1543.32,
        description:
            'Cavernous Irish hideaway with a large beer selection & plenty of games like shuffleboard & pool.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.9936,
        total_orders: 65.75,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Milkshake, Mexican, Tasty, Gourmet, Beer, Texan, Wine, Nachos, Delicious',
        annual_dol: 268.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.9967,
        total_orders: 1439.51,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Salad, Mexican, Nachos, Japanese, Swiss, Irish, American, Clean, Casual',
        annual_dol: 5139.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -73.9941,
        total_orders: 224.75,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Wine, French, Gourmet, Spanish, Casual, Relaxed, Creative, Great',
        annual_dol: 229.52,
        description:
            'Relaxed eatery with quirky, rustic-chic decor serving creative French dishes, charcuterie & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7459,
        store_longitude: -73.9937,
        total_orders: 2740.35,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Karaoke, Beer, Wine, American, Retro, Lively, Fancy, Fun, Classy, Sophisticated',
        annual_dol: 3215.8,
        description: 'Modern piano bar with alcove booth seating & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9897,
        total_orders: 2392.08,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Burger, Hamburger, Tomato Soup, Mexican, Salad, Beer, Italian, Wine, Irish, Spicy',
        annual_dol: 2537.34,
        description:
            'A large beer list, happy-hour deals & sports on TV are offered at this Irish pub near Penn Station.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7469,
        store_longitude: -73.991,
        total_orders: 306.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, American, Premium, Chic, Traditional, Friendly, Amazing',
        annual_dol: 320.28,
        description:
            'The warmly decorated rooms offer custom-designed beds, and flat-screen TVs with premium cable channels, plus free Wi-Fi and coffeemakers. Some rooms offer balconies with views of the Empire State Building. Suites add separate living areas.A grill restaurant offers all-day traditional American meals and open-air dining. Additional amenities include a 24-hour exercise room and a business center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7487,
        store_longitude: -73.9921,
        total_orders: 1202.14,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Lobster, Salad, Steak, Lobster Bisque, Dessert, Gourmet, Mexican, Creamy, Hotel, Sleek',
        annual_dol: 1320.62,
        description:
            'Open-all-day venue offering gourmet American menus, glossy white-on-white decor & a busy bar scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7543,
        store_longitude: -74.0029,
        total_orders: 1280.11,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Lobster Roll, Burger, Wine, Beer, Upscale, Trendy, Delicious, Spanish, Asian',
        annual_dol: 5232.68,
        description:
            'Easygoing outfit with seasonal open-air seating focusing on regional seafood dishes & comfort food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9791,
        total_orders: 141.68,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Italian, Steak, Coffee, Wine, Mediterranean, Elegant, American, Iconic, Classic, Diverse',
        annual_dol: 579.14,
        description:
            'Elegant cafe/bar offering Italian breakfasts, light meals & cocktails in an art deco interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6562,
        store_longitude: -74.0073,
        total_orders: 1846.55,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Wine, Beer, Ale, Rooftop, Casual',
        annual_dol: 7548.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.8417,
        total_orders: 963.04,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Burger, Hamburger, Meatball Sub, Meatball, Salad, Beer, Wine, Bbq, Delicious',
        annual_dol: 1004.79,
        description:
            'Hip American cafe for classic comfort foods, weekend brunch & full bar with house cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7758,
        store_longitude: -73.9571,
        total_orders: 766.78,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Italian, Wine, Delicious, Meatball, Refined, Sweet, Veal Parmigiana, Homemade, Outstanding',
        annual_dol: 783.05,
        description:
            'Refined venue offering elevated Italian fare, including homemade pastas, plus an ample wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.771,
        store_longitude: -73.9509,
        total_orders: 856.19,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Lasagna, Salad, Italian, Dessert, Cozy, Homemade, Casual, Caesar Salad, Local, Traditional',
        annual_dol: 874.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8256,
        store_longitude: -73.9189,
        total_orders: 90.3,
        neighborhood: 'Concourse Village/West Bronx',
        keywords:
            'Vegan, Vegetarian, Tacos, Mexican, Salad, Burritos, Casual, Healthy, Local, Signature',
        annual_dol: 430.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9656,
        total_orders: 372.7,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Flavorful, Italian, Wine, Upscale, Delicious, Romantic, Classy, Homemade, French, Casual',
        annual_dol: 414.67,
        description:
            'Brick-lined Italian restaurant lures locals with homestyle red-sauce dishes & an unpretentious vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7311,
        store_longitude: -73.9576,
        total_orders: 642.93,
        neighborhood: 'Greenpoint',
        keywords: 'Tequila, Mexican, Beer, Enchiladas, Delicious, Awesome, Classic, Casual, Chill',
        annual_dol: 826.12,
        description:
            'Mexican restaurant serving a classic lineup of tacos, enchiladas & tortas, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7108,
        store_longitude: -73.9352,
        total_orders: 710.24,
        neighborhood: 'East Williamsburg',
        keywords: 'Spacious, Rooftop, Delicious, Perfect, Great, Clean',
        annual_dol: 915.1,
        description:
            '"The Bogart House is a four-story building located in the heart of Williamsburg. The rooftop has some of the best views Brooklyn has to offer. This is a versatile space that can be tailored to your professional or personal needs. The space is available for promotional events, company training seminars, and retreats, celebrations, photo/video shoots, etc. Ask our team about the various services we provide in our all-inclusive bar packages. We are fully equipped with a Funktion One Sound System as well as all production equipment to cater to any event. The Manhattan, Queens, and Brooklyn Skyline featured throughout the building is a stunning backdrop that will enhance any experience at Bogart House."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6739,
        store_longitude: -73.7297,
        total_orders: 49.72,
        neighborhood: 'Jamaica/Rosedale',
        keywords:
            'Salad, Tasty, Delicious, American, Rum, Caesar Salad, Casual, Traditional, Local, Rare',
        annual_dol: 203.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8377,
        store_longitude: -73.7832,
        total_orders: 624.46,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Steak, Beer, Delicious, Fried Calamari, Bbq, Hurricane, Quaint, Homemade, Fresh',
        annual_dol: 900.19,
        description:
            'Fried, broiled & raw seafood, plus a menu of burgers, BBQ & wings, served in a no-frills setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5222,
        store_longitude: -74.2352,
        total_orders: 281.15,
        neighborhood: 'Charleston',
        keywords:
            'Italian, Pizza, Delicious, Upscale, Elegant, Chinese, Inviting, Perfect, Classic, Modern',
        annual_dol: 305.96,
        description:
            'Upscale bar & eatery offering wood-fired pizza & traditional Italian cuisine in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7206,
        store_longitude: -73.7607,
        total_orders: 25.93,
        neighborhood: 'Holliswood/Jamaica/Hollis',
        keywords:
            'Lobster, Salad, Cajun, Pizza, Spicy, Fried Rice, Friendly, Memorable, Lively, Authentic',
        annual_dol: 106,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7319,
        store_longitude: -73.9855,
        total_orders: 3867.99,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tacos, Beer, Ale, American, Hotel, Casual, Friendly, Local, Awesome, Great',
        annual_dol: 10615.43,
        description:
            '"Local Bar and Restaurant offering New American dishes, craft cocktails & beer with brunch on weekends."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7352,
        store_longitude: -73.9935,
        total_orders: 670.62,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegan, Wine, Beer, Gluten Free, Champagne, Turkish, Mediterranean, Luxurious, Eclectic, Exotic',
        annual_dol: 684.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7372,
        store_longitude: -73.8813,
        total_orders: 835.92,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Shrimp Cocktail, Beer',
        annual_dol: 872.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7304,
        store_longitude: -73.864,
        total_orders: 7.33,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Vegetarian, Salad, Chicken Tikka Masala, Chili Chicken, Upscale, Crisp, Relaxed, Friendly, Traditional, Indian',
        annual_dol: 27.63,
        description:
            'Relaxed Indian restaurant offers a wide variety of meat & vegetarian options plus a lunch buffet.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -73.9894,
        total_orders: 25.53,
        neighborhood: 'East Village/Bowery',
        keywords: 'Thai, Salad, Dessert, Hotel, Spicy, Sweet, Southern, Casual, Authentic, Local',
        annual_dol: 96.42,
        description: '"Thai Restaurant & Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.8877,
        total_orders: 144.67,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Salad, Thai, Wine, Dessert, Tasty, Green Curry, Spicy, Elegant, Asian, Bbq',
        annual_dol: 401.76,
        description:
            'Comfortable, narrow Thai restaurant doling out spicy curries, noodles & other traditional eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9689,
        total_orders: 60.62,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Sushi, Gourmet, Bbq, Irish, Homemade, Cozy, Thai, Friendly, American',
        annual_dol: 290.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8224,
        store_longitude: -73.9494,
        total_orders: 1529.33,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Burger, Tasty, Beer, Savory, Wine, Delicious, Warm, Rustic, Italian, Crisp',
        annual_dol: 1704.08,
        description:
            'Microbrews, craft cocktails & area-sourced comfort fare served in a roomy, rustic-chic space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7543,
        store_longitude: -73.966,
        total_orders: 419.11,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Tasty, Chocolate Mousse, Wine, Organic, Dessert, Vodka, Spicy, Mediterranean',
        annual_dol: 1713.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9724,
        total_orders: 10.97,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Tasty, Austrian, Margarita, Fresh, Seasonal, Casual, Great, Local, Omelette',
        annual_dol: 15.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6837,
        store_longitude: -73.9079,
        total_orders: 26.75,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Sushi, Pizza, Coffee, Vegetarian, Mexican, Beer, Danish, Thai, American',
        annual_dol: 109.35,
        description:
            'Snug bistro with outdoor seating serving up American staples, plus smoothies, cocktails & hookah.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5929,
        store_longitude: -73.9882,
        total_orders: 191.28,
        neighborhood: '',
        keywords: 'Coffee, Wine, Swiss, Caesar Wrap, Southern, Casual, Fresh, Great',
        annual_dol: 283.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.9874,
        total_orders: 568.66,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Burger, Tequila, Salad, Margarita, Coffee, Dessert, Burritos, Spicy, Delicious',
        annual_dol: 687.03,
        description: 'Mexican standards & margaritas in a colorful eatery with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9873,
        total_orders: 2525.46,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Burger, Steak, Whiskey Sour, Rooftop, Margarita, Whiskey, Nachos, American, Spacious',
        annual_dol: 2686.83,
        description:
            'Music, drinks & loungey upstairs rooms differentiate this spacious 3-level hangout.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9889,
        total_orders: 3315.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Tomato Soup, Beer, French Onion Soup, Spicy, French, Vintage, Hotel, Irish',
        annual_dol: 3386.09,
        description:
            'Live music & craft beers update this vintage neighborhood bar & grill near Times Square.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9871,
        total_orders: 406.96,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Salad, Tasty, Irish, Warm, Cozy, American, Latin, Fusion, Authentic',
        annual_dol: 439.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9892,
        total_orders: 464.1,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Healthy, Casual, Great',
        annual_dol: 484.23,
        description:
            '"Located at 689 8th Ave (@ 43rd Street), Satin Dolls has just been completely remodeled and is now New York\'s premier topless Strip Club. We feature the hottest dancers around. Satin Dolls is the best spot to watch all major sporting events on big screen TVs! If you are looking for a great time, beautiful women, and affordable VIP Rooms… look no further."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6893,
        store_longitude: -73.9924,
        total_orders: 1666.71,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Burger, Hamburger, Vegetarian, Vegan, Salad, Beer, Organic, Moroccan, Delicious, American',
        annual_dol: 1738.97,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.4997,
        store_longitude: -74.2391,
        total_orders: 455.84,
        neighborhood: 'Tottenville',
        keywords: 'Lobster, Salad, Pizza, Italian, Chinese, Wine, Beer, Casual, Exquisite, Fresh',
        annual_dol: 931.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7339,
        store_longitude: -74.0061,
        total_orders: 645.24,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Salad, Italian, Pizza, Delicious, Gin, Warm, French, Relaxed, Intimate',
        annual_dol: 2259.86,
        description:
            'Italian nibbles, cocktails & wine offered in a stylish, relaxed spot with sidewalk seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -73.6358,
        total_orders: 108.75,
        neighborhood: '',
        keywords: 'Burger, Vegetarian, Dessert, Trendy, Casual, Modern, Creative',
        annual_dol: 444.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -74.0037,
        total_orders: 185.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Italian, Wine, Peruvian, Casual, Classic, Unpretentious, Authentic, Perfect, Recommended, Lively',
        annual_dol: 363.45,
        description:
            'Casual all-day venue for espresso & aperitivi, with sidewalk seating & standing room at the bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7501,
        store_longitude: -73.6418,
        total_orders: 11.6,
        neighborhood: '',
        keywords: 'Irish, Italian, American, Champagne, Cultural, Great, Casual',
        annual_dol: 47.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8134,
        store_longitude: -73.8027,
        total_orders: 288.19,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Pizza, Greek',
        annual_dol: 328.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6008,
        store_longitude: -73.6561,
        total_orders: 0.85,
        neighborhood: '',
        keywords:
            'Coffee, Vegetarian, Salad, Burger, Tasty, Elegant, Delicious, Premium, Healthy, Casual',
        annual_dol: 3.46,
        description: '"Premium Couture Coffee Shop"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8416,
        store_longitude: -73.9397,
        total_orders: 784.25,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Vegan, Vegetarian, Wine, Beer, Italian, Tasty, Irish, Karaoke, American, Yummy',
        annual_dol: 2933.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8376,
        store_longitude: -73.942,
        total_orders: 643.26,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Tasty, Beer, Delicious, Warm, Comfortable, Inviting, Friendly, Exquisite, Sweet, Awesome',
        annual_dol: 837.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.629,
        store_longitude: -74.1187,
        total_orders: 31.84,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Roast Beef, Mexican, Italian, Delicious, Warm, American, Irish, Friendly, Sangria',
        annual_dol: 130.14,
        description:
            '"Totally renovated upbeat tavern serving, ice cold beer, speciality drinks, wine, sangria and serving comfort food specializing in roast beef artisian sandwiches!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7536,
        store_longitude: -73.8329,
        total_orders: 11.24,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Lobster, Delicious, Sweet, Natural, Sumptuous, Casual, Indian, Local, Rooftop',
        annual_dol: 45.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.673,
        store_longitude: -73.9903,
        total_orders: 335.59,
        neighborhood: 'Gowanus',
        keywords:
            'Burger, Bourbon, American, Delicious, Lively, Fresh, Great, Contemporary, Exceptional, Excellent',
        annual_dol: 912.02,
        description:
            'Contemporary kosher steakhouse in a former warehouse with a sizable patio & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.9153,
        total_orders: 607.45,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Beer, Whiskey, Wine, Irish, Trendy, Contemporary, American, Vintage, Modern',
        annual_dol: 637.3,
        description:
            'Inventive cocktails & draft beer served in a contemporary Irish pub with frequent entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5735,
        store_longitude: -73.9876,
        total_orders: 829.44,
        neighborhood: 'Coney Island',
        keywords: 'Wine, American, Glamorous, Casual, Rooftop, Innovative, Great',
        annual_dol: 1399.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6557,
        store_longitude: -73.9499,
        total_orders: 185.15,
        neighborhood: 'Prospect Lefferts Gardens/Little Haiti',
        keywords: 'Coffee, Beer, Salad, American, Healthy, Casual, Perfect, Fresh, Sweet, Shandy',
        annual_dol: 620.68,
        description:
            '"Coffee infused cocktails, fresh and healthy dishes, and coffee in Brooklyn, NY."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6653,
        store_longitude: -73.9893,
        total_orders: 114.25,
        neighborhood: 'South Slope/Park Slope',
        keywords:
            'Lobster, Pizza, Beer, Delicious, Jamaican, Caribbean, Sweet, Fun, Fusion, Rustic',
        annual_dol: 279.91,
        description:
            'Traditional Caribbean cuisine is served in this casual eatery that has terrace dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -73.9396,
        total_orders: 2158.93,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Burger, Whiskey, Beer, Bourbon, American, Hip, Casual, Great',
        annual_dol: 2284.82,
        description:
            'Hip gathering spot for burgers, wings & other pub fare with lots of whiskey & proprietary beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7501,
        store_longitude: -73.937,
        total_orders: 500.06,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Coffee, Hotel, Modern',
        annual_dol: 544.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.8819,
        total_orders: 638.9,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Salad, Pizza, Ecuadorian, Tequila Sunrise, Shrimp Cocktail, Tequila, Italian, Colombian, Pinacolada, Dominican',
        annual_dol: 1957.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6566,
        store_longitude: -73.645,
        total_orders: 166.86,
        neighborhood: '',
        keywords: 'Tequila, Tacos, Mexican, Pizza, Margarita, Steak, Cuban, Nachos, Gourmet, Salad',
        annual_dol: 223.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8307,
        store_longitude: -73.684,
        total_orders: 931.09,
        neighborhood: '',
        keywords:
            'Wine, Steak, Beer, Belgian, French Onion Soup, French, Turkish, American, Sweet, Amazing',
        annual_dol: 1267.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7528,
        store_longitude: -73.9858,
        total_orders: 941.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Cozy, Hotel, Spacious, Warm, Picturesque, Friendly, Premium, Modern, Traditional, Casual',
        annual_dol: 968.02,
        description:
            'The warm studios come with kitchens and free WiFi, as well as desks and flat-screen TVs with premium cable channels. Some offer skyline views. 1- and 2-bedroom suites add separate living areas and additional TVs.There’s free hot breakfast, a cozy restaurant and bar with a fireplace, and a 24/7 convenience store. Other amenities include a 24/7 fitness center and business center, plus 7 meeting rooms and laundry facilities.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6892,
        store_longitude: -73.816,
        total_orders: 339.86,
        neighborhood: 'South Ozone Park/South Richmond Hill',
        keywords: 'Burger, Chinese, French, Friendly, Southern, Casual, Local, Great',
        annual_dol: 384.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7464,
        store_longitude: -73.9178,
        total_orders: 119.94,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Salad, Wine, Gluten Free, Dessert, Quaint, Mimosa, Delicious, Warm, Romantic, Casual',
        annual_dol: 127.82,
        description:
            'Inventive New American comfort food & wines in a cozy, unpretentious bistro with back garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7245,
        store_longitude: -73.5403,
        total_orders: 768.81,
        neighborhood: '',
        keywords: 'Steak, Beer, Legendary, Margarita, Ale, American, Friendly, Fun, Lively, Great',
        annual_dol: 802.15,
        description:
            'Lively chain steakhouse serving American fare with a Southwestern spin amid Texas-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -74.0117,
        total_orders: 88.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Salad, Italian, Shrimp Cocktail, Pizza, Japanese, Hawaiian, Thai, Veal Parmigiana, Hotel, Caesar Salad',
        annual_dol: 362.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7051,
        store_longitude: -73.9253,
        total_orders: 52.54,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Casual, Local, Classic',
        annual_dol: 214.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -74.0075,
        total_orders: 27617.96,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Whiskey, Scotch, Upscale, Sushi, Distilled, Cozy, Warm, Refined, Premium',
        annual_dol: 56898.15,
        description:
            'Clubby, refined bar & lounge specializing in rare spirits with an eclectic menu of small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -74.004,
        total_orders: 11258.46,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Lobster, Steak, Salad, Dessert, Wine, Beer, Chinese, Fried Shrimp, Trendy',
        annual_dol: 12404.66,
        description:
            'A bi-level, 1940s gambling parlor sets an exotic backdrop for Chinese-Portuguese small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -73.5872,
        total_orders: 698.52,
        neighborhood: '',
        keywords: 'Beer, Coffee, Hotel, American, Premium, Casual, Great',
        annual_dol: 728.81,
        description:
            "Warmly decorated rooms feature free Wi-Fi and flat-screen TVs, plus microwaves, minifridges and coffeemakers. Some have pull-out sofas; suites add separate sitting areas.A casual bistro serves breakfast and dinner. There's also a lobby lounge and a furnished patio, along with an indoor pool and a fitness room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.9989,
        total_orders: 305.1,
        neighborhood: 'Little Italy',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Salad, Dessert, Apple Pie, Beer, Wine, Tasty, Delicious',
        annual_dol: 330.33,
        description:
            'Seafood spot with a New England fish-house vibe featuring a raw bar, lobster rolls & fish plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7987,
        store_longitude: -73.6999,
        total_orders: 954.89,
        neighborhood: '',
        keywords: 'Burger, Salad, Beer, Irish, Friendly, Fancy, Awesome, Clean, Perfect, Casual',
        annual_dol: 975.15,
        description:
            '"A local meeting spot for great food, drinks, sports, music, and other events."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -74.0044,
        total_orders: 1183.71,
        neighborhood: 'Hudson Square',
        keywords: 'Tasty, Hotel, Authentic, Cool, Casual, Great, Local',
        annual_dol: 1287.24,
        description:
            "The SoHo Playhouse is an Off-Broadway theatre at 15 Vandam Street in the Hudson Square area of Manhattan. The theatre opened in 1962 as the Village South Theatre with the original production of Jean Erdman's musical play The Coach with the Six Insides which was based upon James Joyce's last novel Finnegans Wake.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9935,
        total_orders: 5.16,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Lobster, Salad, Pizza, Mexican, Gourmet, Margarita, Italian, Wine, Cuban, Avocado Toast',
        annual_dol: 21.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.629,
        total_orders: 677.88,
        neighborhood: '',
        keywords: 'Golfclub, Wonderful, Great, Terrific',
        annual_dol: 830.37,
        description:
            'Wheatley Hills Golf Club, is a 110-acre country club and golf course in East Williston, New York. It was established in 1913 from land purchased from William Titus for forty eight dollars per acre. The Titus homestead was converted into the clubhouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -74.0057,
        total_orders: 3367.89,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Tasty, Wine, Dessert, French, Delicious, Martini, Spanish, Refined, Comfortable',
        annual_dol: 3625.65,
        description:
            'Contemporary bistro serving French standards & natural wines in a refined environment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -74.0016,
        total_orders: 4987.25,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Burger, Hamburger, Salad, Tequila, Beer, Wine, Tasty, French, Stylish, Prime Rib',
        annual_dol: 5203.48,
        description:
            'Sister to the Chicago original serving elevated burgers & American dishes in a stylish setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7178,
        store_longitude: -74.0072,
        total_orders: 127.23,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Upscale, Spicy, Italian, Warm, Impeccable, Cozy, American, French, Classy',
        annual_dol: 520.09,
        description:
            "Upscale yet easygoing, this no-reservations eatery serves French & Italian standbys & kids' choices.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7169,
        store_longitude: -74.0084,
        total_orders: 486.28,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Italian, Wine, Delicious, Warm, Classy, Homemade, Authentic, Intimate, Contemporary',
        annual_dol: 541.03,
        description:
            'Casual Italian spot delivering pastas & other hearty dishes in a rustic, brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.5881,
        total_orders: 90.66,
        neighborhood: '',
        keywords:
            'Burger, Salad, Hotdog, Beer, Chinese, Delicious, American, Relaxed, Casual, Coleslaw',
        annual_dol: 100.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7068,
        store_longitude: -73.923,
        total_orders: 1498.51,
        neighborhood: 'Bushwick',
        keywords:
            'Lobster Roll, Lobster, Margarita, Beer, Coffee, Spicy, Bourbon, French, Grand Marnier, American',
        annual_dol: 2077.19,
        description:
            'Place with a beachy vibe for oysters, grilled fish & other seafood, plus frozen drinks & DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.8941,
        total_orders: 14.62,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Beer, Coffee, Fun, American, Great, Diverse, Casual',
        annual_dol: 26.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7138,
        store_longitude: -73.9954,
        total_orders: 228.09,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Karaoke, Hawaiian, Chinese, Fun, Great, Casual',
        annual_dol: 237.98,
        description: '"Karaoke dive bar, cash only"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9,
        store_longitude: -73.8622,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Pizza, Wine, Apple Pie, Salad, Beer, Cobb Salad',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.866,
        store_longitude: -73.9275,
        total_orders: 636.44,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Lasagna, Garlic Bread, Pizza, Chicken Parmigiana, Italian, Gluten Free, Wine, Vodka, Beer, Tasty',
        annual_dol: 678.75,
        description:
            'Italian restaurant serving classic fare in a warm space with outdoor tables for people-watching.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7784,
        store_longitude: -73.9746,
        total_orders: 16.3,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Upscale, Hotel, Legendary, Elegant, Iconic, Spacious, American, Picturesque, Urban, Sophisticated',
        annual_dol: 66.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 720.43,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Rich, Delicious, Memorable, Iconic, Premium, Heritage, Crafted, Unique',
        annual_dol: 785.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.749,
        store_longitude: -74.0081,
        total_orders: 441.44,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Golfclub, Gin, Seasonal, Urban, Friendly, Great, Casual, Hummus, Signature',
        annual_dol: 1079.72,
        description:
            'All-weather facility with hitting stalls & a net-covered fairway jutting over the Hudson River.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6819,
        store_longitude: -73.977,
        total_orders: 19.14,
        neighborhood: 'Park Slope',
        keywords:
            'Steak, Salad, Vegan, Shrimp Cocktail, Pizza, Mexican, Gluten Free, Clean, Fried Calamari, Cuban',
        annual_dol: 188.15,
        description:
            'Spacious spot a block from the Barclays Center serving classic Latin-American dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -73.9831,
        total_orders: 1805.39,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Wine, Vietnamese, Salad, Warm, Ale, Cozy, Innovative, Modern, Bright, Lively',
        annual_dol: 2008.69,
        description:
            'Updated takes on Vietnamese staples plus cocktails are served in an elegant, understated space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -73.9496,
        total_orders: 466.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Wine, Italian, Dessert, French, French Onion Soup, Phenomenal, Filet Mignon, Cozy, Quaint',
        annual_dol: 496.15,
        description:
            'Steakhouse standards & wines served in a space with an old-school Italian vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 359.57,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Sushi, Mexican, Italian, Chinese, Japanese, Chilean, Thai, Fried Rice, Asian, Fusion',
        annual_dol: 428.48,
        description:
            'Easygoing Pan-Asian eatery in the Staten Island Mall offering sushi, dim sum & Thai curries.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6648,
        total_orders: 413.25,
        neighborhood: '',
        keywords:
            'Burger, Lobster Roll, Lobster, Salad, Cajun, Gluten Free, Beer, Warm, French, American',
        annual_dol: 440.4,
        description:
            '"Long Beach\'s premier dining & music venue. Join us daily for lunch, dinner, or drinks! Live Music! Have a party? Call us & inquire about our private party room!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.68,
        store_longitude: -73.9682,
        total_orders: 318.97,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Wine, Trendy, Delicious, Friendly, Vibrant, Lively, Fun, Mead, Intimate',
        annual_dol: 521.25,
        description:
            'Cool hangout featuring select craft beers on tap, plus 5,000 vinyl records & a lineup of DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5869,
        store_longitude: -73.6657,
        total_orders: 137.39,
        neighborhood: '',
        keywords: 'Burger, Sushi, Beer, Savory, Delicious, Coffee, Fun, Perfect, Thai, Fresh',
        annual_dol: 187.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7424,
        store_longitude: -73.9834,
        total_orders: 2906.98,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Beer, Whiskey, Wine, Salad, Bourbon, Rustic, Warm, Friendly, Casual, Spacious',
        annual_dol: 3077.06,
        description:
            'Hip, late-night bar serving up craft cocktails, draft brews & casual fare in a rustic-chic interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7434,
        store_longitude: -73.9858,
        total_orders: 519.22,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Wine, Vodka, Elegant, Hotel, Perfect, Great, Recommended, Unique',
        annual_dol: 541.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7778,
        store_longitude: -73.8732,
        total_orders: 20.45,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Burger, Pizza, Coffee, Wine, Clean, Hotel, Spacious, American',
        annual_dol: 83.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6969,
        store_longitude: -73.9068,
        total_orders: 919.59,
        neighborhood: 'Bushwick',
        keywords: 'Vegan, Milkshake, Beer, Friendly, Asian, Cool, Casual, Local, Fancy, Wonderful',
        annual_dol: 1524.36,
        description:
            'Low-key, neighborhood hangout with bar bites, old-school video games & DJ nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6237,
        store_longitude: -73.9647,
        total_orders: 232.39,
        neighborhood: 'Midwood',
        keywords: 'Wine, Vodka, Tequila, Pizza, Italian, Gin, Belgian, Rum, Stylish, Cozy',
        annual_dol: 1056.76,
        description:
            '"Kosher Authentic Italian Fine Dining Restaurant. New York’s premier restaurant for fine dining. Famous for our Veal and Artichokes, our traditional Italian Roman style cooking in a modern era provides a dining experience like none other. With our Italian ambiance, cozy yet romantic- this is the perfect setting for a night out."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7237,
        store_longitude: -74.0041,
        total_orders: 7153.63,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Upscale, Delicious, Warm, Dessert, Mexican, Spacious, Perfect, Fun, Outstanding',
        annual_dol: 7353.09,
        description:
            'Hip, laid-back stop offering Latin-inspired seafood plus a downstairs bar for cocktails & music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.9976,
        total_orders: 815.06,
        neighborhood: 'Little Italy',
        keywords:
            'Steak, Pizza, Chicken Parmigiana, Italian, Dessert, Wine, Cuban, Mediterranean, Bruschetta, Authentic',
        annual_dol: 989.72,
        description:
            'Laid-back dining turning out pastas, wood-fired pizza & seafood dishes, plus outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.5854,
        total_orders: 96.57,
        neighborhood: '',
        keywords: 'Wine, Beer, Margarita, Dessert, American, Classy, Casual, Traditional, Lit',
        annual_dol: 440.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7774,
        store_longitude: -73.9523,
        total_orders: 945.76,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tequila, Mexican, Steak, Wine, Shrimp Scampi, Spanish, Seasonal, American, Ribeye Steak, Casual',
        annual_dol: 1201.21,
        description:
            'Hip Mexican & American eatery offering seasonal dishes, signature cocktails & a bottomless brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7723,
        store_longitude: -73.956,
        total_orders: 650.96,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Burger, Beer, Margarita, Mimosa, Screwdriver, Wine, Dope, American, Delicious, Casual',
        annual_dol: 2289.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7679,
        store_longitude: -73.9592,
        total_orders: 156.57,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Champagne, Italian, Dessert, Wine, Mexican, Martini, Beer, Delicious, Warm',
        annual_dol: 174.2,
        description:
            'White-tablecloth eatery serving Italian classics dedicated to the late actor Rudolph Valentino.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6606,
        store_longitude: -73.7389,
        total_orders: 111.76,
        neighborhood: 'Jamaica/Rosedale',
        keywords: 'Whiskey, Margarita, Pizza, Ale, American, Casual, Cool, Superb, Classic, Local',
        annual_dol: 148.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5249,
        store_longitude: -74.2367,
        total_orders: 882.16,
        neighborhood: 'Charleston',
        keywords:
            'Pizza, Chicken Parmigiana, Italian, Homemade, Modern, Rum, Fresh, Fabulous, Sweet, Casual',
        annual_dol: 1108.83,
        description:
            "\"Ariana's South hosting events for every occasion, modern chic setting. Ariana's has been serving the community and beyond for over 2 decades. Weddings, engagements, sweet 16's, christenings, birthday celebrations\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -73.9405,
        total_orders: 1945.68,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Lasagna, Sushi, Vegan, Vegetarian, Wine, Dessert, Champagne, Intimate, Great, Delectable',
        annual_dol: 2120.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7342,
        store_longitude: -73.8685,
        total_orders: 183.07,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Lasagna, Gluten Free, Italian, Dessert, Pizza, Delicious, Rich, Homemade, Sweet, Irresistible',
        annual_dol: 186.96,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -73.9893,
        total_orders: 1219.43,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Steak, Upscale, American, French, Classic, Contemporary, Traditional, Dutch, Robust, Bloody Mary',
        annual_dol: 3743.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.948,
        total_orders: 664.74,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Salad, Kebab, Delicious, French, Mediterranean, Russian, Premium, Greek, American, Fresh',
        annual_dol: 740.14,
        description:
            'Splashy waterside Russian restaurant featuring an Azerbaijani-style menu, plus live entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5879,
        store_longitude: -73.9602,
        total_orders: 10.81,
        neighborhood: 'Sheepshead Bay/Gravesend',
        keywords: 'Kebab, Wine, Salad, Coffee, Vegetarian, Beer, Dessert, Delicious, Cozy, Turkish',
        annual_dol: 44.2,
        description:
            'Spacious Turkish eatery with a rustic-chic interior offering an all-day menu & an ample wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.7714,
        total_orders: 26.72,
        neighborhood: 'Bayside',
        keywords:
            'Burger, Mexican, Steak, Nachos, Fajitas, Modern, Casual, Local, Premium, Perfect',
        annual_dol: 109.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8194,
        store_longitude: -73.9442,
        total_orders: 544.1,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Salad, Wine, Cajun, Delicious, Stylish, Tranquil, Spicy, Beer, Sweet, Friendly',
        annual_dol: 720.32,
        description:
            'Stylish dining room with Continental, Cajun & traditional Southern cuisine, plus live weekend jazz.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8038,
        store_longitude: -73.6468,
        total_orders: 467.11,
        neighborhood: '',
        keywords: 'Steak, Salad, Wine, Dessert, Pizza, Tasty, Italian, Margarita, Elegant, Organic',
        annual_dol: 484.45,
        description:
            'Longtime brick-floored cafe serving Roman fare, fixed-price menus & desserts from its bakery annex.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9681,
        total_orders: 558.89,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Pizza, Gluten Free, Wine, Japanese, Champagne, Casual',
        annual_dol: 789.49,
        description:
            '"クラブげらげらは全部屋が完全個室となっており最新カラオケも完備。お一人様から団体のお客様までプライバシーを守られながらお楽しみいただけますので接待やストレス発散にもうってつけです。明朗会計で安心の料金設定。求人に力を入れているので女の子の数や質はアメリカで一番だと評判です。"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7589,
        store_longitude: -73.9894,
        total_orders: 3104.53,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Cajun, Bbq, Italian, Hotel, Southern, Classic, Tradition, Intimate, Casual, Iconic',
        annual_dol: 3170.4,
        description:
            'Classic Theater District jazz club with roomy red environs, a large bar & Cajun-influenced food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -73.991,
        total_orders: 859.3,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Tasty, Spicy, Chinese, Delicious, Organic, Asian, Ale, Thai, Eclectic, Fresh',
        annual_dol: 3016.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9819,
        total_orders: 1844.54,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Champagne, Upscale, Hotel, Luxurious, French, Elegant, Premium, Iconic, Sophisticated, Superb',
        annual_dol: 1924.51,
        description:
            "theaters of Broadway.Upscale, contemporary rooms have marble bathrooms, and feature minibars, free Wi-Fi and flat-screen TVs; minifridges and coffeemakers are available on request. Suites add separate living rooms and/or pull-out sofas; some have terraces.There's a refined, art deco-style French brasserie with a bar, plus a fitness room, a business center and valet parking (fee).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.788,
        store_longitude: -73.7276,
        total_orders: 67.49,
        neighborhood: '',
        keywords:
            'Sushi, Delicious, Warm, Wholesome, Amazing, Classy, Exceptional, Great, Fresh, Asian',
        annual_dol: 275.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7517,
        store_longitude: -74.0042,
        total_orders: 7466.68,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Casual, Tradition, Great',
        annual_dol: 7721.21,
        description: 'Dark-lit, multilevel gay leather-&-Levis bar with a roof deck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7466,
        store_longitude: -73.9915,
        total_orders: 277.3,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Upscale, Rooftop, Iconic, Modern, Healthy, Great, Gracious, Signature, Local',
        annual_dol: 295.52,
        description:
            "Offering free Wi-Fi and flat-screen TVs, the posh, modern rooms with wooden floors and padded leather headboards also have microwaves, minifridges, and room service, along with safes and coffeemakers. Some have pull-out sofas. Suites add a separate sitting area and floor-to-ceiling windows.Amenities include a mod-style lobby, a rooftop lounge, and a swanky, bistro-style restaurant and bar serving breakfast and dinner. There's also an outdoor cafe and a fitness room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7509,
        store_longitude: -74.0043,
        total_orders: 12222.42,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Pizza, Vegetarian, Salad, Rooftop, Cozy, Romantic, Warm, Seasonal, Vibrant',
        annual_dol: 13254.65,
        description:
            'Open year-round, this restaurant with a green-garden vibe serves chic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9901,
        total_orders: 1886.78,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Beer, Whiskey, Delicious, Irish, Fun, Friendly, Cool, Wonderful, Traditional, Casual',
        annual_dol: 2494.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -73.9907,
        total_orders: 79.35,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Wine, Beer, Karaoke, Whiskey, Trendy, Delicious, Hotel, Korean, Classy, Relaxed',
        annual_dol: 324.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7285,
        store_longitude: -73.9983,
        total_orders: 2048.32,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Vegetarian, Vegan, Salad, Beer, Spicy, Wine, Moroccan, Organic',
        annual_dol: 2641.39,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -74.0001,
        total_orders: 267.7,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Delicious, Beer, Romantic, Italian, Yummy, Friendly, Sensational, Bright, Amazing, Great',
        annual_dol: 1094.28,
        description:
            'A combination comedy club, music venue & sports bar with Italian food and plenty of beer thrown in.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.9931,
        total_orders: 735.17,
        neighborhood: 'NoHo',
        keywords:
            'Steak, Wine, Italian, Gourmet, Mediterranean, Rustic, Spanish, Romantic, Cozy, Warm',
        annual_dol: 767.04,
        description:
            'Upmarket, carefully sourced Mediterranean plates served in a rustic-chic space with a wine cellar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9952,
        total_orders: 445.79,
        neighborhood: 'Nolita',
        keywords: 'Lobster, Indonesian, French, Wine, Beer, Thai, Modern, Savory, Asian, Sleek',
        annual_dol: 497.1,
        description:
            'Cédric Vongerichten (son of Jean-Georges) serves French-accented Indonesian fare in a sleek setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9989,
        total_orders: 568.33,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Beer, Coffee, Vietnamese, French, Cozy, Latin, Classy, American, Friendly',
        annual_dol: 1995.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.7121,
        total_orders: 285.92,
        neighborhood: '',
        keywords: 'American, Sensational, Complex, Fresh',
        annual_dol: 1174.7,
        description:
            "The North Shore Towers and Country Club is a three-building residential cooperative located in the Glen Oaks neighborhood in the New York City borough of Queens, near the city's border with Nassau County. The complex is located next to the Long Island Jewish Medical Center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7495,
        store_longitude: -73.9475,
        total_orders: 103.85,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Trendy, Tequila, Coffee, Hotel, Vodka, Gin, Modern, Local, Clean, Rum',
        annual_dol: 391.28,
        description:
            "art museum.Minimalist mixed or single-sex dorms provide bunk beds with reading lights and lockers, as well as linens and towels. All have en suite bathrooms and free Wi-Fi. Private rooms are also available.In addition to an industrial-chic bar and cafe, there's a communal kitchen, a rooftop terrace and coin-operated laundry facilities.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6771,
        store_longitude: -74.0129,
        total_orders: 819.77,
        neighborhood: 'Red Hook',
        keywords: 'Burger, Lobster, Hamburger, Salad, Tequila, Vodka, Coffee, Gin, Cozy, Quaint',
        annual_dol: 834.1,
        description:
            'Classic cocktails pair with seasonal American fare & weekend brunch in a vintage-inspired setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -73.9924,
        total_orders: 207.74,
        neighborhood: 'NoHo',
        keywords: 'Salad, Wine, Vegetarian, Dessert, Italian, Margarita, Beer, Cozy, Spicy, Warm',
        annual_dol: 216.75,
        description:
            'Small Italian kitchen with a pasta-centric menu & varied wines in warm environs with a fireplace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7345,
        store_longitude: -73.9908,
        total_orders: 1289.19,
        neighborhood: 'Lower Manhattan',
        keywords: 'Cosmopolitan, Iconic, Fantastic, Local, Complex',
        annual_dol: 3949.65,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -73.9892,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Upscale, Hotel, Avocado Toast, Cozy, Vibrant, Fun, American, Chic, Artistic, Rare',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -73.9915,
        total_orders: 333.44,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Tacos, Tequila, Mexican, Tasty, Gourmet, Salad, Coffee, Breakfast Burrito, Funky, Tex-Mex',
        annual_dol: 388.67,
        description:
            'Hip tavern in a former garage offering gourmet tacos, Mexican beers & agave-based cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7252,
        store_longitude: -73.9906,
        total_orders: 164.66,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Salad, Sushi, Flavorful, Vodka, Delicious, Cozy, Sustainable, French, Relaxed, Fine Quality',
        annual_dol: 179.45,
        description:
            'Russian & French cuisine, plus vodka-infused offerings in a cozy venue with live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7787,
        store_longitude: -73.7763,
        total_orders: 1158.87,
        neighborhood: 'Bayside/Bay Terrace',
        keywords:
            'Steak, Chicken Alfredo, American, Classic, Asian, Casual, Fusion, Local, Fine, Lively',
        annual_dol: 1279.53,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9783,
        total_orders: 195.23,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Salad, Lobster, Steak, Upscale, Hotel, Stylish, Polished, Sleek, Modern, Retro',
        annual_dol: 798.03,
        description:
            "blocks from Rockefeller Center and the Theater District.Decorated in retro Danish-modern style, the polished rooms have free Wi-Fi, terraces and glass-tiled bathrooms, as well as minibars, flat-screen TVs and books. Suites provide additional terraces and/or loft ceilings. Room service and in-room massages are available.Freebies include access to an off-site fitness center. There's also an upscale steakhouse and a 24-hour business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5799,
        store_longitude: -73.96,
        total_orders: 85.25,
        neighborhood: 'Brighton Beach',
        keywords:
            'Salad, Coffee, Pizza, Italian, Creamy, Warm, Mediterranean, Caesar Salad, Greek, American',
        annual_dol: 149.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7135,
        store_longitude: -74.0079,
        total_orders: 1019.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Vegan, Tasty, Whiskey, Beer, Upscale, Delicious, Friendly, American, Irish',
        annual_dol: 1316.56,
        description:
            'Pub where booths have private TVs & the menu includes pasta, sandwiches & other American bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9692,
        total_orders: 1371.65,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Hotel, Burger, Irish, Spacious, Comfortable, Modern, French Onion Soup, French, Quaint',
        annual_dol: 1419.92,
        description:
            'The modern rooms feature flat-screen TVs, coffeemakers, leather headboards and Wi-Fi (surcharge). Suites add marble bathrooms, separate living areas with pull-out sofas, kitchenettes, and iPod docks. Room service is available 24/7.Guests have complimentary access to a 24-hour fitness center 3 blocks away, and there’s an eclectic Irish restaurant and pub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9748,
        total_orders: 17319.59,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Hotel, Upscale, Stylish, Italian, Elegant, Exquisite, Classy, Impeccable, Iconic',
        annual_dol: 17657.57,
        description:
            "Sophisticated rooms with 12-foot ceilings, silk wall coverings and chandeliers feature sitting areas and Italian marble bathrooms, as well as 24-hour personal butler service. All rooms come with free Wi-Fi and flat-screen HDTVs. Select suites add kitchens and formal dining rooms.There's a renowned lounge/bar, a grand restaurant and a cognac room. Other amenities include valet parking and a limousine service (both fee), plus a 24-hour exercise room and a steam room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.9708,
        total_orders: 343.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Tacos, Mexican, Tasty, Relaxed, Authentic, Fresh, Hummus, Fine, Great, Falafel',
        annual_dol: 1402.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6911,
        store_longitude: -73.997,
        total_orders: 2198.14,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Wine, Salad, Tacos, Organic, Beer, Clean, Comfortable, Classy, Exquisite, Cool',
        annual_dol: 3143.85,
        description:
            'Listening bar with deep booths & a marble-topped bar serving light bites & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.9884,
        total_orders: 38.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Salad, Italian, Bourbon, Polished, Sleek, Traditional, Creole, Casual',
        annual_dol: 157.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.9566,
        total_orders: 2478.49,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Wine, Salad, Italian, Elegant, Rustic, Romantic, Picturesque, Warm, Classy, Homey',
        annual_dol: 2531.08,
        description:
            'Sophisticated waterfront bar & restaurant crafting contemporary Italian dishes & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7206,
        store_longitude: -73.9953,
        total_orders: 11422.19,
        neighborhood: 'Nolita',
        keywords:
            'Hotel, Rich, Sleek, Rooftop, Chic, French, Great, Exceptional, Perfect, Remarkable',
        annual_dol: 14651.6,
        description:
            "Side.Streamlined rooms feature hardwood floors, large windows and free-standing bathtubs, plus complimentary Wi-Fi, minibars, flat-screen TVs and iPod docks. Some have balconies, and upper floors provide panoramic views. Room service is available.Freebies include a daily happy hour, plus bike and skateboard rentals, and passes to a nearby health club are offered. There's also a laid-back French cafe.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7227,
        store_longitude: -73.9961,
        total_orders: 953.56,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Pizza, Vegetarian, Salad, Gluten Free, Italian, Wine, Vodka, Beer, New-York-Style',
        annual_dol: 994.9,
        description:
            'Neighborhood Italian spot serving reinvented Italian-American classics & pizzas in a dark space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7498,
        store_longitude: -73.8821,
        total_orders: 872.92,
        neighborhood: 'Jackson Heights',
        keywords:
            'Lobster, Garlic Bread, Shrimp Cocktail, Cajun, Tasty, Tequila, Margarita, Wine, Zesty, Rum',
        annual_dol: 1583.61,
        description:
            'Unpretentious nook with bare-bones decor showcasing seafood boils, plates & Cajun menu items.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.728,
        store_longitude: -73.9948,
        total_orders: 612.61,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Burger, Salad, Beer, Wine, Trendy, Cozy, Greek, Fun, American, Awesome',
        annual_dol: 2504.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7714,
        store_longitude: -73.9076,
        total_orders: 27.13,
        neighborhood: 'Astoria/LIC/Ditmars Steinway/Long Island City',
        keywords: 'Sushi, Pizza, Beer, Karaoke, Premium, African, Thai, American, Perfect, Great',
        annual_dol: 110.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7753,
        store_longitude: -73.9839,
        total_orders: 675.54,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Salad, Milkshake, Shrimp Scampi, Avocado Toast, Bolivian, Sweet, Clean, Friendly, Greek',
        annual_dol: 2072.69,
        description:
            'Classic American diner with burgers, salads, entrees & more just a few blocks from Lincoln Center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.751,
        store_longitude: -74.002,
        total_orders: 5103.01,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Cajun, Wine, Salad, Hamburger, Upscale, Delicious, Cozy, Rustic, French',
        annual_dol: 5318.04,
        description:
            'Rustic-chic spot with a comfy rear patio offering Greek-style food, cocktails & house-brewed beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8506,
        store_longitude: -73.8823,
        total_orders: 1176.82,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Hotdog, Coffee, Beer, Wine, Banana Split, Southern, Coleslaw, Seasonal, Retro, Premium',
        annual_dol: 1340.31,
        description:
            'The Bronx Zoo is a zoo within Bronx Park in the Bronx, New York. It is one of the largest zoos in the United States by area and is the largest metropolitan zoo in the United States by area, comprising 265 acres of park lands and naturalistic habitats separated by the Bronx River.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7913,
        store_longitude: -73.7019,
        total_orders: 361.12,
        neighborhood: '',
        keywords: 'Sushi, Salad, Tasty, Coffee, Chinese, Martini, Spicy, Upscale, Delicious, Beer',
        annual_dol: 368.78,
        description:
            'Classic dishes like Peking duck plus a full sushi menu in a refined setting filled with antiques.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6399,
        store_longitude: -73.662,
        total_orders: 159.52,
        neighborhood: '',
        keywords: 'Beer, Casual, Friendly, Chill',
        annual_dol: 354.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -73.9972,
        total_orders: 1141.03,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Chicken Parmigiana, Italian, Margarita, Quaint, Smoky, Romantic, Relaxed, Smooth, Classic',
        annual_dol: 1190.5,
        description:
            'Neighborhood institution with traditional Italian fare in a white tablecloth setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7756,
        store_longitude: -73.5601,
        total_orders: 2787.17,
        neighborhood: '',
        keywords:
            'Cheesecake, Wine, Salad, Luxurious, Stylish, Italian, Hotel, Spicy, Organic, Korean',
        annual_dol: 2966.09,
        description:
            'The Feng Shui-inspired rooms have marble bathrooms (some with whirlpool tubs), plus minifridges, flat-screen TVs and free WiFi. Suites feature balconies, separate living rooms and/or kitchenettes.Outdoor parking is free. Amenities include a spa, an indoor pool and a hot tub, as well as a steam room, a sauna, a gym and a game room. An modern restaurant serves Italian, Asian and fusion dishes and has a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -74.0089,
        total_orders: 1352.52,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Wine, Upscale, Elegant, Modern, Indian, Adventurous, Crisp, Seasonal, Fantastic',
        annual_dol: 1391.67,
        description:
            'High-end Indian kitchen serving upscale versions of classic dishes in a modern, soaring space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7099,
        store_longitude: -73.8483,
        total_orders: 6016.17,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Sushi, Salad, Cheesesteak, Vegan, Italian, Japanese',
        annual_dol: 9276.96,
        description:
            'Corner spot with a casual vibe dishing up classic Greek cuisine, plus burgers & other American eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8325,
        store_longitude: -73.8286,
        total_orders: 253.35,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Burger, Steak, Salad, Mexican, Breakfast Burrito, Italian, French Toast, Spicy, French, American',
        annual_dol: 267.27,
        description:
            'Chrome-accented diner with a diverse menu that ranges from breakfast & desserts to steak & seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8289,
        store_longitude: -73.8243,
        total_orders: 270.06,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Dessert, Trendy, Cuban, Delicious, Classy, Urban, Latin, Casual, Dope, Local',
        annual_dol: 277.14,
        description:
            '"Casa Restaurant opened on December 2015 and is located inside the Heart of Throggs Neck, since then we have been serving quality interpretations of classic Latin dishes. Casa’s interior, designed by its owners, reflects the trendy sensibilities of the surrounding area while also paying tribute to the Bronx\'s present future. The multi-room restaurant offers the choice to observe passers-by in the glass-encased main room, or enjoy the privacy of dining and entertainment in the back room."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6586,
        store_longitude: -73.6448,
        total_orders: 935.72,
        neighborhood: '',
        keywords:
            'Upscale, Beer, Gourmet, Comfortable, Classy, Irish, Perfect, Friendly, Great, Excellent',
        annual_dol: 1002.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5999,
        store_longitude: -73.9119,
        total_orders: 134.6,
        neighborhood: '',
        keywords: 'Southern',
        annual_dol: 160.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7521,
        store_longitude: -73.985,
        total_orders: 18946.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Hotel, Vegetarian, Salad, Avocado Toast, Coffee, Dessert, Rooftop, Spacious, Premium',
        annual_dol: 20061.73,
        description:
            "Broadway theaters and 7 blocks from Grand Central Station.The retro, industrial-chic rooms feature 12-ft ceilings, windows that open, wood floors, mosaic tile bathrooms and original art. All rooms have minibars, Wi-Fi, flat-screen TVs and iPod docks. Room service is offered 24/7.There's a lobby bar, a New American restaurant and a chic rooftop cocktail lounge. There's also a fitness room, and a basement gallery is available for meetings or fashion shoots.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6897,
        store_longitude: -73.8265,
        total_orders: 2112.09,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords: 'Sushi, Burger, Pizza, Delicious, Upscale, Elegant, Sweet, Fresh, Casual',
        annual_dol: 2199.07,
        description:
            '"For more than half a century the Russo Family has built an enduring legacy of excellence in the catering industry in New York City. Today Villa Russo is Queens’ premier event venues for weddings, sweet sixteens, and other events."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.9877,
        total_orders: 2987.59,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Cheesecake, Italian, Tasty, Wine, Upscale, Delicious, Spacious, Classy, Rustic, Fun',
        annual_dol: 3251.27,
        description:
            '"NYC\'s Oldest Italian Restaurant in The Garment District: ARNO Nestled between the theater and garment districts, Arno Ristorante offers Northern Italian cuisine in a traditional, upscale setting for nearly 40 years. Just steps from Madison Square Garden and Broadway theaters, you and your guests can enjoy a delicious meal before or after the event. Join us for lighter fare at lunch time and mingle with business associates at our popular bar after work. Our menu offers classic Italian offerings including Crostini di Mozzarella and Risotto ai Frutti de Mare. Our flexible dining space and great location also make this a popular spot for private parties. We look forward to seeing you at Arno Ristorante."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7687,
        store_longitude: -73.6928,
        total_orders: 129.97,
        neighborhood: '',
        keywords: 'Golfclub, Hotel, Terrific, Diverse',
        annual_dol: 531.26,
        description:
            'Deepdale Golf Club is a private golf club in Manhasset, New York, just east of New York City on Long Island.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5702,
        store_longitude: -74.1089,
        total_orders: 283.63,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords:
            'Pizza, Burger, Steak, Meatball, Italian, Coffee, Dessert, Juicy, Wine, Delicious',
        annual_dol: 509.6,
        description: '"Neighborhood Pizzeria and Sports Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5731,
        store_longitude: -74.1366,
        total_orders: 113.36,
        neighborhood: 'Lighthouse Hill',
        keywords:
            'Cheesecake, Pizza, Salad, Italian, Dessert, Vodka, Savory, Delicious, Greek, Casual',
        annual_dol: 118.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -74.0093,
        total_orders: 300.04,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Sushi, Steak, Salad, Tasty, Vodka, Japanese, Delicious, Bbq, Warm',
        annual_dol: 1226.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7182,
        store_longitude: -73.9976,
        total_orders: 28.9,
        neighborhood: 'Little Italy',
        keywords: 'Pizza, Italian, Salad, Dessert, Wine, Premium, Fun, Awesome, Lively, American',
        annual_dol: 45.73,
        description:
            'Big portions of Italian basics served up in a throwback space lined with murals & memorabilia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7744,
        store_longitude: -73.9541,
        total_orders: 371.01,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Steak, Salad, Dessert, Shrimp Scampi, Italian, Upscale, Caesar Salad, Comfortable, American',
        annual_dol: 394.83,
        description:
            'Casual grill serving a contemporary menu including steak, oysters & fish, plus cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7707,
        store_longitude: -73.9537,
        total_orders: 134.6,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Mexican, Margarita, Warm, Spicy, Delicious, Cozy, Tex-Mex, Crisp, Comfortable, Casual',
        annual_dol: 137.45,
        description:
            'Southwestern fare chased with potent margaritas is the lure at this popular longtime eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7071,
        store_longitude: -74.0079,
        total_orders: 1439.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Tasty, Organic, Warm, Korean, Nachos, Local, French, Seasonal, Contemporary',
        annual_dol: 1502.31,
        description:
            'Warmly lit bar with creative cocktails plus craft beer, happy hour & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8309,
        store_longitude: -73.8262,
        total_orders: 220.81,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Burger, Legendary, Salad, Italian, Cuban, Beer, Karaoke, American, Swiss, Classic',
        annual_dol: 387.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6567,
        store_longitude: -73.6454,
        total_orders: 1340.74,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Flavorful, Salad, Tasty, Beer, Coffee, Dessert, Italian',
        annual_dol: 1534.37,
        description:
            'A variety of sandwiches & burgers can be paired with sides like fries & salads for a pub-style meal.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.59,
        store_longitude: -73.9222,
        total_orders: 65.93,
        neighborhood: 'Gerritsen Beach',
        keywords: 'Beer, Local',
        annual_dol: 161.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.992,
        total_orders: 403.23,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Burger, Steak, Hamburger, Pizza, Salad, Beer, Caesar Wrap, Bbq, Wine, Bourbon',
        annual_dol: 1648.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.993,
        total_orders: 74.44,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Burger, Steak, Pizza, Cajun, Salad, Gourmet, Beer, Wine, Italian, Trendy',
        annual_dol: 304.28,
        description: '"Bar & Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -74.0049,
        total_orders: 442.01,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Cajun, Spicy, Trendy, Rich, Gumbo, Salad, Pizza, Bbq, Friendly, Creole',
        annual_dol: 461.17,
        description:
            '"Filé Gumbo Bar showcases Cajun and Creole classics made bar-side, combining slow-cooked and flavor-rich roux bases with your choice of proteins, simmered in a steam kettle by our chefs for a truly personalized experience."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7045,
        store_longitude: -74.0104,
        total_orders: 3338.7,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Magical, Irish, American, Friendly, Casual',
        annual_dol: 3863.61,
        description:
            'Fantasy-inspired pop-up inside Bavaria Bierhaus offers cocktail classes to create-your-own drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7955,
        store_longitude: -73.936,
        total_orders: 86.49,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Burger, Mexican, Coffee, Beer, Dominican, Tunisian, Peruvian, Wine, Mediterranean, Bruschetta',
        annual_dol: 162.73,
        description:
            '"Creators of Harissa Hot Honey sauce & the famous Harissa Hot Honey Wings! Serving Latin-Mediterranean inspired comfort food, wine & cocktails. BARCHA is a love letter to Harlem, where we live, raise our children (twins!) & run our family business. #Latinx & #NorthAfrican Owned Pickup & Delivery available on our website!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -74.0104,
        total_orders: 122.68,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Organic, Wine, Coffee, Wholesome, Natural, Modern, Casual, Greek, Perfect',
        annual_dol: 501.47,
        description:
            'Counter-order spot for daytime Greek dishes & baked goods, with a communal table & loungey seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -73.9212,
        total_orders: 377.62,
        neighborhood: 'Bushwick',
        keywords: 'Fun, Excellent, Sophisticated, Global, Signature, Unique',
        annual_dol: 1543.57,
        description:
            '"Enjoy an immersive, theatrical experience highlighting the stories of each moon goddess through high level nouveau circus-based acts and multisensory interactive installations. The featured goddess changes with every moon phase, eventually depicting all 13 mythological moon goddesses. Acts will include unique aerial performances and sculptures as well as a mesmerizing balance act that was performed at Cirque du Soleil for 10 years, originally created by Rigolo the family of the owners of MoonRise. An extensive mixology program will feature cocktails based around the 13 goddesses and the aromatic herbs associated with each for you to enjoy. Prepare to be taken on an astrological journey!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 189.38,
        neighborhood: 'Bushwick',
        keywords:
            'Hotdog, Burger, Tacos, Mexican, Beer, Vegetarian, Margarita, Venezuelan, Flavorful, Chinese',
        annual_dol: 319.64,
        description:
            'Hip, casual eatery serving Venezuelan street food such as yuca fries, burgers & hot dogs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6946,
        store_longitude: -73.9063,
        total_orders: 34.83,
        neighborhood: 'Bushwick/Ridgewood',
        keywords:
            'Lobster, Lobster Roll, Salad, Mexican, Mimosa, Nachos, Meatball, Savory, Caesar Salad, Latin',
        annual_dol: 142.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7315,
        store_longitude: -74.0024,
        total_orders: 1606.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Whiskey, Vegetarian, Tasty, Rich, Cozy, Traditional, Warm, Creamy, Artisanal, Indian',
        annual_dol: 1679.96,
        description:
            'Cozy, red-hued outpost offering an array of British colonial Indian staples & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -74.0028,
        total_orders: 1608.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Salad, Italian, Green Papaya Salad, Upscale, Thai, Spanish, Romantic, Elegant, American',
        annual_dol: 1750.15,
        description:
            "This upscale American restaurant is known to be one of the city's most romantic.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7322,
        store_longitude: -74.0033,
        total_orders: 4970.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Flavorful, Delicious, Warm, Stylish, Impeccable, Wine, Cozy, Fun, American, Timeless',
        annual_dol: 5253.66,
        description:
            '"The Noortwyck is a new restaurant from partners Chef Andy Quinn and Sommelier Cedric Nicaise who met at Eleven Madison Park. This West Village restaurant has a timeless, new American menu focusing on New York producers and purveyors alongside a vast and fun wine list."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7406,
        store_longitude: -74.0084,
        total_orders: 319.38,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Hamburger, Steak, Vegetarian, Beer, American, Dessert, Wine, Casual, Classic',
        annual_dol: 489.74,
        description:
            '"Best Burgers + Best Cocktails. Open everyday. Located near The Whitney Museum, the High Line and Little Island."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.721,
        store_longitude: -73.6883,
        total_orders: 106.46,
        neighborhood: '',
        keywords: 'Sushi, Salad, Dessert, Japanese, Wine, Thai, Chinese, Sashimi, Asian, Fusion',
        annual_dol: 201.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -73.9922,
        total_orders: 2171.91,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Steak, Salad, Pizza, Coffee, Beer, Wine, French, Caesar Salad, American',
        annual_dol: 2217.99,
        description:
            'A roof deck with a view of Madison Square Garden across the street distinguishes this bar & cafe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.79,
        store_longitude: -73.9749,
        total_orders: 515.07,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Salad, Pizza, Gluten Free, Italian, Beer, Ale, Delicious, Warm, Elegant',
        annual_dol: 526,
        description:
            'Quaint place with a warm vibe offering a versatile menu of Northern Italian food & baked goods.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6732,
        store_longitude: -73.9898,
        total_orders: 1812.12,
        neighborhood: 'Gowanus',
        keywords: 'Wine, Whiskey, Beer, Martini, Coffee, Gin, Salad, Bourbon, Hotel, Delicious',
        annual_dol: 1812.12,
        description:
            'Tranquil, late-night neighborhood haunt for classic cocktails, light fare & TV sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6758,
        store_longitude: -73.9839,
        total_orders: 11.83,
        neighborhood: 'Park Slope',
        keywords:
            'Whiskey, Beer, Coffee, Organic, Delicious, Sweet, Paloma, Seasonal, Wonderful, Great',
        annual_dol: 38.69,
        description:
            '"We are a women and minority owned Bar, Bakery and Event Space. Serving in-house baked goods and small bites inspired by our Trinidadian and Cambodian heritages. We offer free Wi-Fi for those that need to switch up the WFH scenery. We host monthly dinner parties that showcase and honor our heritages. Most of our beers, wines and spirits are either local, minority or women owned. Swing by for coffee, cookies, cakes, cocktails and vybzzzzz."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7049,
        store_longitude: -73.9343,
        total_orders: 1147.09,
        neighborhood: 'East Williamsburg',
        keywords:
            'Pizza, Italian, American, Vietnamese, Wine, Funky, Bloody Mary, Chic, Casual, Sweet',
        annual_dol: 1250.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.707,
        store_longitude: -73.9331,
        total_orders: 1112.19,
        neighborhood: 'East Williamsburg',
        keywords: 'Pizza, Casual, Cool, Rare, Global',
        annual_dol: 1239.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6575,
        store_longitude: -73.6436,
        total_orders: 393.06,
        neighborhood: '',
        keywords: 'Burger, Steak, Cajun, Beer, Ale, American, Irish, Contemporary, Modern, Cozy',
        annual_dol: 438.8,
        description:
            'Standard bar & grill with burgers & TVs, plus a bottomless-drink brunch & ample outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5696,
        store_longitude: -74.1085,
        total_orders: 276.37,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords: 'Beer, Margarita, Delicious, Crisp, Lively, American, Casual, Classic, Local',
        annual_dol: 880.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5916,
        store_longitude: -74.1011,
        total_orders: 96.11,
        neighborhood: 'Todt Hill',
        keywords: 'Steak, Pizza, American, Ale, Irish, Trendy, Filet Mignon, Local, Casual, Great',
        annual_dol: 156.89,
        description: '"Local sports bar for the community"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.724,
        store_longitude: -73.5412,
        total_orders: 361.33,
        neighborhood: '',
        keywords: 'Whiskey, Beer, Casual',
        annual_dol: 457.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7464,
        store_longitude: -73.9178,
        total_orders: 775.69,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Chocolate Cake, Pizza, Wine, Salad, Whiskey, Beer, Tasty, Delicious, Irish, Intimate',
        annual_dol: 796.55,
        description:
            'Intimate locale offering wine, cocktails & draft beer, plus small plates, pizza & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9934,
        total_orders: 298.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Lobster, Salad, Shrimp Cocktail, Lobster Bisque, Wine, French Onion Soup, French, Tasty, Caesar Salad',
        annual_dol: 696.11,
        description:
            'Steaks & a 400-label wine list are the focus of this 2-story chophouse with clubby decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.9969,
        total_orders: 289.7,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Italian, Meatball, Prosecco, Bruschetta, Thai, Aperol, Homemade, Cozy, Casual',
        annual_dol: 1184.2,
        description:
            'Family-owned Italian eatery offering Neapolitan pizzas & pasta dishes in a cozy atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7419,
        store_longitude: -73.5944,
        total_orders: 349.33,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Margarita, Delicious, Casual, Sangria, Amazing, Great',
        annual_dol: 1316.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.8966,
        total_orders: 537.41,
        neighborhood: 'Jackson Heights/Woodside',
        keywords:
            'Beer, Gluten Free, Fun, Awesome, Premium, Retro, Cool, Wonderful, Classic, Excellent',
        annual_dol: 548.82,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8289,
        store_longitude: -73.9285,
        total_orders: 819.46,
        neighborhood: 'Concourse',
        keywords:
            'Steak, Lobster, Lobster Tail, Salad, Wine, Bbq, Japanese, Upscale, American, Savory',
        annual_dol: 933.31,
        description:
            'Year-round steakhouse in Yankee Stadium that draws Bronx Bombers fans with baseball-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.8545,
        total_orders: 266.41,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Italian, Salad, Pizza, Wine, Korean, Elegant, Quaint, French, Classy, Friendly',
        annual_dol: 277.96,
        description:
            'Family-owned Italian restaurant serving traditional fare in a homey, white-tablecloth setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7032,
        store_longitude: -73.9258,
        total_orders: 1954.44,
        neighborhood: 'Bushwick',
        keywords:
            'Vegetarian, Salad, Dessert, Coffee, Trendy, French, Delicious, Cozy, Polished, Friendly',
        annual_dol: 1995.91,
        description:
            'Modern French bistro serving up classic fare in a chic, vintagey space with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6988,
        store_longitude: -73.9172,
        total_orders: 1449.16,
        neighborhood: 'Bushwick',
        keywords:
            'Whiskey, Beer, Wine, Gluten Free, Delicious, Organic, Friendly, Fun, Cool, Casual',
        annual_dol: 1511.99,
        description:
            'Chill, narrow hangout with a jukebox & pinball offering cocktails & draft beer, plus acoustic music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7034,
        store_longitude: -74.0113,
        total_orders: 10574.27,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Pizza, Whiskey, Salad, Hotel, Upscale, Rustic, Spanish, French, Premium',
        annual_dol: 11650.95,
        description:
            'Renovated historic tavern with a George Washington link offering pub eats & live music on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7196,
        store_longitude: -74.01,
        total_orders: 4541.2,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Burger, Pizza, Italian, Salad, Beer, Coffee, Wine, Upscale, American',
        annual_dol: 4896.72,
        description:
            'Buzzy Robert De Niro-owned mainstay for innovative New American cuisine & a stellar wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7763,
        store_longitude: -73.9527,
        total_orders: 1728.13,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Cheesesteak, Pizza, Wine, Salad, Beer, Irish, Delicious, Swiss, Karaoke',
        annual_dol: 1764.79,
        description:
            'Neighborhood institution known for its social scene, cocktails & standard pub fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.9572,
        total_orders: 538.88,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Wine, Italian, Delicious, Elegant, Martini, Cozy, Polished, Homemade, Fabulous',
        annual_dol: 590.67,
        description:
            'Sophisticated Northern Italian cuisine in an elegant yet cozy eatery with rustic-chic accents.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6352,
        store_longitude: -73.9612,
        total_orders: 588.49,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Burger, Tacos, Gluten Free, Beer, Korean, Wine, Nachos, German, Delicious, Gin',
        annual_dol: 912.01,
        description:
            'Cozy gastropub serving an eclectic menu (lasagna & Korean sandwiches), plus exotic burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7003,
        store_longitude: -73.9159,
        total_orders: 497.52,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Tequila, Pizza, Rum, Bbq, Local, Eclectic, Casual, Quirky, Fun',
        annual_dol: 634.45,
        description:
            'Convivial corner tavern with an edgy vibe that presents a no-nonsense menu of beer & booze.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8203,
        store_longitude: -73.8178,
        total_orders: 1148.68,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Steak, Burger, Chocolate Cake, Dessert, Wine, Upscale, French, American, Latin, Legendary',
        annual_dol: 1748.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8273,
        store_longitude: -73.9253,
        total_orders: 2263.56,
        neighborhood: 'Concourse',
        keywords:
            'Garlic Bread, Hotdog, Steak, Cheesesteak, Italian, Beer, Flavorful, Wine, Nachos, American',
        annual_dol: 2476.07,
        description:
            'Beer & pub grub fuel the passions of Yankee fans at this venerable local bar near the stadium gates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7862,
        store_longitude: -73.9779,
        total_orders: 50.32,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Mexican, Steak, Salad, Organic, Delicious, Local, Casual, Fresh, Great',
        annual_dol: 297.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7102,
        store_longitude: -73.8483,
        total_orders: 7.97,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Peruvian, Salad, Sangria, Traditional, Sweet, Casual, Exquisite, Asian, Colorful, Authentic',
        annual_dol: 32.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7675,
        store_longitude: -73.9183,
        total_orders: 1446.41,
        neighborhood: 'Astoria/Long Island City',
        keywords:
            'Magical, Wine, Delicious, Elegant, Cozy, Trendy, Karaoke, Intimate, Friendly, Awesome',
        annual_dol: 1591.32,
        description:
            'Cozy Mediterranean-style bar with cocktails, hookah, weekend DJs, karaoke parties & bar grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6758,
        store_longitude: -73.9806,
        total_orders: 746.2,
        neighborhood: 'Park Slope',
        keywords: 'Mexican, Burger, Steak, Tequila, Salad, Beer, Nachos, Margarita, Wine, Fajitas',
        annual_dol: 843.3,
        description:
            'This small-chain outpost boasts a menu of Mexican favorites, kitschy decor & a sizable tequila menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7219,
        store_longitude: -73.9506,
        total_orders: 1013.12,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Shrimp Cocktail, Dessert, Beer, Wine, Caesar Salad, American, Vintage',
        annual_dol: 3556.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8602,
        store_longitude: -73.8908,
        total_orders: 60.76,
        neighborhood: 'Belmont/West Bronx',
        keywords: 'Mexican, Tacos, Burritos, Healthy, Local, Casual, Great',
        annual_dol: 248.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6715,
        store_longitude: -73.9907,
        total_orders: 87.55,
        neighborhood: 'Gowanus',
        keywords: 'American, Danish, Heritage, Foreign',
        annual_dol: 252.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.734,
        store_longitude: -74.0061,
        total_orders: 306.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Beef Stroganoff, Coffee, Italian, Beer, Organic, American, Sustainable, Premium, Local',
        annual_dol: 1251.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7461,
        store_longitude: -73.9151,
        total_orders: 768.31,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: 'Steak, Whiskey, Wine, Beer, Tasty, Gin, Irish, Premium, Rum, Fried Chicken',
        annual_dol: 802.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8258,
        store_longitude: -73.9198,
        total_orders: 145.09,
        neighborhood: 'Concourse Village/West Bronx',
        keywords:
            'Lobster, Salad, Cajun, Lobster Bisque, Caesar Salad, Clam Chowder, Fun, Perfect, Fiery, Bold',
        annual_dol: 593.07,
        description:
            '"Established in 2020. Born in the South where a feast means getting messy with friends, the seafood boil has been bringing people together for ages. At Hook & Reel our signature boil forks over bold flavors with a genuine, down-home feel. But eating seafood is only part of the fun. Everything tastes better elbow-to-elbow with friends, swapping stories over music, getting down and dirty and eating with your hands - all with a cold drink at the ready, \'cause that spice ain\'t no joke. We set out to share the saucy spirit of the boil with folks everywhere - testing our very own recipes, inspired by fiery Cajun home cooking. The Hook & Reel seafood boil dishes up the "reel" deal - the full backyard boil experience and flavor, with a twist."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9512,
        total_orders: 281.42,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Italian, Pizza, Wine, Coffee, Beer, Modern, Seasonal, Intimate, Sophisticated, Awesome',
        annual_dol: 321.01,
        description:
            'Sophisticated Italian dishes & select wines are presented in an intimate white-tablecloth setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.705,
        store_longitude: -73.9062,
        total_orders: 468.26,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Vegan, Stylish, Friendly, Casual, Classic, American, Great, Tempura',
        annual_dol: 488.56,
        description:
            '"Full service restaurant and tavern. Northern European and Vegan Cuisine. Horror themed. Female and veteran owned and operated."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6729,
        store_longitude: -73.9768,
        total_orders: 404.66,
        neighborhood: 'Park Slope',
        keywords:
            'Lasagna, Pizza, Italian, Meatball, Beer, Dessert, Bbq, Homemade, Comfortable, Casual',
        annual_dol: 440.37,
        description:
            'Comfortable trattoria serving Italian dishes & New York-style pizza, plus a selection of wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6694,
        store_longitude: -73.9863,
        total_orders: 563.42,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Delicious, Friendly, Awesome, Great, Classic, Casual, Local',
        annual_dol: 600.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.9099,
        total_orders: 565.87,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Salad, Beer, Delicious, Juicy, Bourbon, Bbq, Irish, Rum',
        annual_dol: 712.47,
        description:
            'Neighborhood saloon with pub grub & American entrees, plus brunch & happy hour deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7368,
        store_longitude: -74.0012,
        total_orders: 2915.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Pizza, Vegan, Salad, Applejack, Coffee, Beer, Roast Beef, Whiskey, Hotel',
        annual_dol: 10982.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5732,
        store_longitude: -73.9805,
        total_orders: 351.87,
        neighborhood: 'Coney Island',
        keywords:
            'Hotdog, Roast Beef, American, Jamaican, French, Southern, Rooftop, Seasonal, Traditional, Perfect',
        annual_dol: 453.75,
        description:
            'Enduring diner dishing up breakfast & lunch classics in an old-fashioned atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7701,
        store_longitude: -73.9678,
        total_orders: 614.27,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Wine, Phenomenal, American, Traditional, Heritage, Wonderful, Cultural, Nostalgic',
        annual_dol: 627.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7699,
        store_longitude: -73.9543,
        total_orders: 311.42,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Mexican, Tacos, Vegan, Coffee, Tex-Mex, Thai, Perfect, Awesome, Amazing, Casual',
        annual_dol: 331.88,
        description: '"Mezcaleria serving small bites and an array of Mezcals and Tequilas"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.8577,
        total_orders: 652.1,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Vegetarian, Salad, Wine, Coffee, Beer, Delicious, Friendly, Legendary, Bruschetta, Perfect',
        annual_dol: 798.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7037,
        store_longitude: -74.0129,
        total_orders: 4608.77,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Beer, American, Irish, Friendly, Reuben, Casual, Traditional, Great, Signature',
        annual_dol: 4706.56,
        description: 'This laid-back, wood-paneled gathering place serves Irish pub grub & pints.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7227,
        store_longitude: -74.0033,
        total_orders: 10005.7,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Salad, Coffee, Upscale, French, Cozy, Modern, American, Thai, Casual, Vintage',
        annual_dol: 13267.29,
        description:
            "A cozy room with 70's vibes & a menu featuring an array of modern American snacks, fare, & drinks.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7729,
        store_longitude: -73.8739,
        total_orders: 293.37,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Wine, Salad, Coffee, Delicious, Korean, Spacious, Healthy, American, Premium, Fresh',
        annual_dol: 1199.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9177,
        total_orders: 10.82,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Pinacolada, Local, Fried Rice, Casual, Great',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6789,
        store_longitude: -73.9978,
        total_orders: 3181.72,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Beer, Wine, Salad, Coffee, Cozy, Buffalo Chicken Salad, Friendly, American, Vintage',
        annual_dol: 3249.23,
        description:
            'Casual bar/restaurant serving draft beer, cocktails & hearty pub fare, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7675,
        store_longitude: -73.8665,
        total_orders: 693.96,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Hotel, Sleek, Delicious, Relaxed, Modern, American, Perfect, Friendly',
        annual_dol: 724.05,
        description:
            "Modern rooms feature flat-screen TVs, free Wi-Fi and sitting areas. Suites add separate living areas; some include whirlpool tubs and/or terraces.A 24-hour airport shuttle is complimentary. Other amenities include an indoor pool, a sauna and a fitness center. There's also a sleek bar. Parking is available (surcharge).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7519,
        store_longitude: -73.9478,
        total_orders: 1177.35,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Hotel, Rooftop, Amazing, American, Funky, Great',
        annual_dol: 1524.55,
        description:
            "A Manhattan-facing wall of windows gives every room a floor-to-ceiling skyline view. The rooms' slick industrial-chic decor has jazz-themed accents; all have rainfall showerheads, minibars, free Wi-Fi, LCD TVs and iPod docks.An underground restaurant/bar serves all 3 meals; a rooftop lounge serves cocktails at night. Amenities include a 24-hour business center, in-room fitness or spa sessions, free bicycle rentals and an hourly shuttle into Manhattan.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5867,
        store_longitude: -73.7276,
        total_orders: 106.06,
        neighborhood: '',
        keywords: 'Burger, Tacos, Fun, French, Terrific',
        annual_dol: 192.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -73.9913,
        total_orders: 5287.71,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Tequila, Wine, Beer, Bourbon, Salad, Irish, Bbq, Classic, British, Quaint',
        annual_dol: 5520.23,
        description:
            'Easygoing spot offering classic pub grub & a full bar in a whimsical setting with Victorian details.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -73.988,
        total_orders: 921.04,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Upscale, Vintage, Salad, Delicious, Premium, Great, Casual, Unique',
        annual_dol: 3233.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9912,
        total_orders: 342.96,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Tacos, Salad, Gluten Free, Margarita, Italian, Karaoke, Yummy, American, Irish, Friendly',
        annual_dol: 1401.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7923,
        store_longitude: -73.9714,
        total_orders: 552.43,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Sushi, Wine, Beer, Japanese, Sashimi, Tranquil, Casual, Elegant, Comfortable, Traditional',
        annual_dol: 678.67,
        description:
            'Modern, tranquil Japanese eatery offering sushi, sashimi & traditional cooked dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.771,
        total_orders: 80.94,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Cajun, Coffee, Dessert, Bourbon, American, Premium, Creole, Warm, Casual',
        annual_dol: 330.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6338,
        store_longitude: -74.0266,
        total_orders: 27.68,
        neighborhood: 'Bay Ridge',
        keywords:
            'Thai, Spicy, Coffee, Delicious, Asian, Traditional, Pad Thai, Authentic, Red Curry, Fresh',
        annual_dol: 113.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9706,
        total_orders: 26.24,
        neighborhood: 'Lenox Hill',
        keywords:
            'Pizza, Gourmet, Coffee, Wine, Beer, Scotch, Chinese, Mediterranean, Healthy, Casual',
        annual_dol: 214.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7578,
        store_longitude: -73.9875,
        total_orders: 1720.36,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Beer, Juicy, Bbq, Fun, Lively, Premium, Amazing, Wonderful, Great',
        annual_dol: 1756.86,
        description:
            'Huge portions of pub grub & flat-screen TV sports in a stadium-themed eatery inside Bowlmor Lanes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6979,
        store_longitude: -73.9848,
        total_orders: 193.99,
        neighborhood: 'Brooklyn Heights/Downtown Brooklyn/Bridge Plaza',
        keywords: 'Burger, Beer, Rich, Spicy, Trendy, Upscale, Bbq, African, Caribbean, Nigerian',
        annual_dol: 213.05,
        description:
            'Relaxed, upscale locale for Nigerian, Caribbean & American cuisine, plus distinctive cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6945,
        store_longitude: -73.9859,
        total_orders: 148.84,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Pizza, Shrimp Cocktail, Salad, Italian, Hawaiian, Delicious, Hotel, Fresh, Perfect, Great',
        annual_dol: 532.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.981,
        total_orders: 274.4,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Vodka, Pizza, Italian, Wine, Vegan, Gluten Free, Meatball, Rich, Delicious, Casual',
        annual_dol: 1121.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7795,
        store_longitude: -73.9503,
        total_orders: 78.63,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Mexican, Fun, Casual, Vibrant, Fresh, Local, Great, Sangria',
        annual_dol: 101.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.9857,
        total_orders: 835.22,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Karaoke, Wine, Trendy, Korean, Japanese, American, Delicious, Asian, Seasonal, Modern',
        annual_dol: 871.43,
        description:
            'Bi-level, sleek space with a full bar & private karaoke rooms also offering Korean fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7684,
        store_longitude: -73.9824,
        total_orders: 15751.22,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Cheesecake, Luxurious, Dessert, Coffee, Hotel, Spacious, Premium, Crisp, Perfect, American',
        annual_dol: 16085.43,
        description:
            "The contemporary rooms feature floor-to-ceiling windows with skyline views, as well as flat-screens, stereos and Wi-Fi (fee). They also include desks, soaking tubs and marble bathrooms. Some have Central Park or river views, and luxe suites add separate living rooms and/or Nespresso machines.Perks include a chic modern American eatery, an airy lobby lounge serving snacks, and a specialty cocktail bar. There's also a fitness center, an indoor pool and a posh spa.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9949,
        total_orders: 1436.08,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Wine, Gluten Free, Dessert, Spanish, Delicious, Sweet, Rustic, Amazing, Authentic',
        annual_dol: 1498.34,
        description: 'An array of paellas is the main draw at this rustic NoLita restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -74,
        total_orders: 261.79,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Chinese, Coffee, Peking Duck, American, Modern, Spicy, Filet Mignon, Local, Classic',
        annual_dol: 475.75,
        description:
            'Modern restaurant serving sophisticated Chinese dishes in a spacious, informal dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7262,
        store_longitude: -73.9964,
        total_orders: 226.47,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Burritos, Vegan, Organic, Local, Casual, Chic, Premium',
        annual_dol: 987.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6174,
        store_longitude: -73.9105,
        total_orders: 5.82,
        neighborhood: 'Mill Basin',
        keywords:
            'Burger, Tacos, Mexican, Sushi, Nachos, Gluten Free, Dessert, Japanese, Ale, French',
        annual_dol: 23.8,
        description: '"Authentic Mexican Taqueria"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6574,
        store_longitude: -73.6419,
        total_orders: 15.32,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Coffee, Mediterranean, Grilled Cheese Sandwich, Caesar Salad, French, American, Godfather',
        annual_dol: 62.64,
        description:
            'Classic, old-school diner offering typical American grub including omelettes, salads & burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.9802,
        total_orders: 2071.21,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Burger, Sushi, Chocolate Cake, Salad, Shrimp Cocktail, Hamburger, Vegetarian, Champagne',
        annual_dol: 2383.41,
        description:
            'Upscale, seafood-focused New American with cocktails & live music in a huge, bustling space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.9892,
        total_orders: 56.44,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Pizza, Mexican, Eclectic, Casual',
        annual_dol: 230.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.687,
        store_longitude: -73.8218,
        total_orders: 344.12,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords: 'Chinese, Asian, Caribbean, Fusion, Indian, Casual, Great',
        annual_dol: 391.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.5895,
        total_orders: 2179.19,
        neighborhood: '',
        keywords: 'Coffee, Premium',
        annual_dol: 8210.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8188,
        store_longitude: -73.8169,
        total_orders: 173.52,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Steak, Sushi, Chinese, Japanese, Pungent, Asian, American, Local, Casual, Sweet',
        annual_dol: 184.66,
        description:
            'Comfy, low-key restaurant & sushi bar offering popular Chinese, Japanese & Southeast Asian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8335,
        store_longitude: -73.8166,
        total_orders: 10.84,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Salad, Hotel',
        annual_dol: 16.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6897,
        store_longitude: -73.8275,
        total_orders: 1870.57,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords:
            'Burger, Steak, Delicious, Fried Rice, Fabulous, Caribbean, Great, Indian, Creative, Casual',
        annual_dol: 2053.61,
        description:
            '"West Indian Lounge in Richmond Hill,Queens •21+ Only w/valid ID •Hours: 6pm-3am(Closed Tuesday’s) •Dress Code Enforced •We Do Not Take Reservations"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -74.005,
        total_orders: 3040.54,
        neighborhood: '',
        keywords:
            'Steak, Burger, Delicious, Japanese, American, Classic, Exotic, Fresh, Amazing, Sangria',
        annual_dol: 3105.05,
        description:
            '"Classic Car Club is NYC’s one-of-a-kind members-only Club for those who believe in a life best lived … and lived loudly. Come race with us, rally around the world, indulge in wondrous culinary feats in our top-of-the-line restaurant or soak up the sun and sangria on our beautiful river terrace. Unlimited experience. Limited status."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -73.9969,
        total_orders: 443.67,
        neighborhood: 'Little Italy',
        keywords:
            'Bbq, Gluten Free, Dessert, Beer, Wine, Chinese, Delicious, Malaysian, Sweet, Contemporary',
        annual_dol: 592.53,
        description:
            'Roomy, contemporary spot featuring tabletop cook-your-own Chinese hot pot & BBQ, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6109,
        store_longitude: -73.9628,
        total_orders: 25.86,
        neighborhood: 'Midwood',
        keywords:
            'Vegetarian, Salad, Coffee, Gourmet, Rustic, Nachos, Chili Chicken, Comfortable, Bruschetta, Casual',
        annual_dol: 105.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7068,
        store_longitude: -73.923,
        total_orders: 1120.72,
        neighborhood: 'Bushwick',
        keywords: 'Legendary, Luxurious, Mysterious',
        annual_dol: 1445.2,
        description:
            '"The luxurious new venue from Company XIV and the home of COCKTAIL MAGIQUE, an evening of intoxicating illusions. Company XIV is thrilled to present a rarefied spectacle of supernatural, luminescent libations and revelry such as the world has never seen. Behold a phantasmagoria of miracles of tantalizing charm and splendor unfolding in your midst at COCKTAIL MAGIQUE, a choreographed mixological adventure of unprecedented majesty. Secret archives of vaudevillian arcana effloresce anew in regal brilliance at our luxurious new sanctum in the beating heart of Bushwick, Brooklyn. Alcoholic alchemy up close and personal, spritzed with the gallant wit and joie de vivre of the Belle Epoque."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7745,
        store_longitude: -73.8725,
        total_orders: 4234.91,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Burger, Tacos, Pizza, Mexican, Salad, Margarita, Coffee, Wine, Spanish, American',
        annual_dol: 14867.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -73.8451,
        total_orders: 9066.32,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Beer, Tacos, Whiskey, Cajun, Vegetarian, Upscale, Delicious, American, Irish, Fun',
        annual_dol: 9572.59,
        description:
            'Gastropub offering craft brews & lots of whiskey options, plus an upscale bar menu & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9459,
        total_orders: 703.86,
        neighborhood: 'Greenpoint',
        keywords: 'Karaoke, Beer, Wine, Fun, Great',
        annual_dol: 2651.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7489,
        store_longitude: -73.9754,
        total_orders: 74.18,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Tasty, Gluten Free, Homemade, Turkish, Fun, Premium, American, Traditional, Great',
        annual_dol: 303.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.77,
        store_longitude: -73.9577,
        total_orders: 68.2,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Wine, Beer, Cajun, Martini, Coffee, Ale, Margarita, Salad, Thai, Hotel',
        annual_dol: 238.86,
        description:
            'Creative cocktails & craft beer served with elevated sandwiches & sharable plates in a chill space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7072,
        store_longitude: -73.943,
        total_orders: 241.71,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Coffee, Salad, Dessert, Donut, Rich, Delicious, Chinese, Cozy, Korean, American',
        annual_dol: 696.2,
        description:
            'Laid-back neighborhood spot serving inventive twists on Taiwanese classics for brunch & dinner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8395,
        store_longitude: -73.7834,
        total_orders: 4190.28,
        neighborhood: 'City Island',
        keywords:
            'Lobster Roll, Lobster, Salad, Cornbread, Shrimp Cocktail, Coffee, Fruity, Italian, Delicious, Yummy',
        annual_dol: 4333.14,
        description:
            'Bustling restaurant supplying a wide range of seafood & fruity cocktails amid ocean-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6837,
        store_longitude: -73.9294,
        total_orders: 2319.55,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords: 'Burger, Sushi, Salad, Japanese, Wine, Spicy, Swiss, Trendy, Asian, Ramen',
        annual_dol: 8123.89,
        description:
            'Cozy restaurant & bar serving traditional Japanese cuisine in a brick-walled dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.85,
        store_longitude: -73.7877,
        total_orders: 118.19,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Steak, Dessert, Delicious, Organic, Fancy, Latin, Fresh, American',
        annual_dol: 418.58,
        description:
            '"Caliente\'s is a sports themed restaurant and bar. Our menu consist of handcrafted cocktails using mostly organic fruits and juices . All of our seafood is wild caught and never farmed. We are committed to bringing you only the best!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7453,
        store_longitude: -73.9276,
        total_orders: 380.46,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Burger, Beer, Champagne, Upscale, Western, Casual, Lit',
        annual_dol: 388.53,
        description:
            "\"NYC's largest upscale gentleman's club. 3 stages, 2 floors, 1 night you won't forget. VIP lounges and champagne rooms on request.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -73.9896,
        total_orders: 817.28,
        neighborhood: 'East Village/Bowery',
        keywords: 'Champagne, Gin, Rum, Intimate, Cointreau, Casual, Classic',
        annual_dol: 940.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5965,
        store_longitude: -73.976,
        total_orders: 169.47,
        neighborhood: 'Gravesend',
        keywords:
            'Steak, Sushi, Pizza, Salad, Dessert, Wine, Cosmopolitan, Delicious, Bbq, Vietnamese',
        annual_dol: 704.24,
        description:
            '"Inspired by Asian, Mediterranean and Middle Eastern cuisine, Silk Restaurant & Lounge offers an eclectic mix of authentic flavours with one thing in common: fresh, high quality ingredients. The menu includes simple, yet skillfully prepared dishes that allow diners to indulge and savour according to taste. In keeping with its cosmopolitan character, Silk is the perfect combination of international taste and local flavour; farm to table ingredients and locally caught seafood are daily menu staples. The addictive cocktail menu changes seasonally to feature local flavours like sorrel and soursop alongside a carefully curated international wine list."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9872,
        total_orders: 617.92,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Ale, Bbq, Nachos, Upscale, Comfortable, Sweet, Casual, Cool',
        annual_dol: 755.87,
        description:
            'A lively space featuring a young crowd, pub grub, drink specials and plenty of games on the tube.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9882,
        total_orders: 172.22,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Steak, Hamburger, Salad, Italian, Wine, Tasty, Beer, French Toast, French, American',
        annual_dol: 703.99,
        description:
            '"Escape to a heated, outdoor garden oasis in the heart of the East Village for an al fresco dining experience that blends an eclectic Mexican-Brazilian fusion cuisine. Join us for brunch and dinner, fresh craft cocktails and authentic hookah prepared with the finest molasses, fruit juice, and spices."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.8331,
        total_orders: 489.34,
        neighborhood: 'Flushing',
        keywords: 'Lobster, Pizza, Chinese, Peking Duck, Hotel, Asian, Fusion, Fun, Rooftop, Local',
        annual_dol: 1713.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.8339,
        total_orders: 926.16,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Hotel, Pizza, Upscale, Coffee, Rooftop, French, Awesome, Classic, Great, Relaxed',
        annual_dol: 1015.14,
        description:
            "The relaxed rooms come with separate living and sleeping areas, 42-inch flat-screen TVs and free WiFi. They also include coffeemakers and minifridges, and upgraded rooms add pull-out sofas and may have separate bedrooms.An airport shuttle is complimentary. Other amenities include an indoor pool and a fitness center. There's also a coffee and cocktails bar, a 24/7 menu and market and a business center, plus meeting space and paid parking. Breakfast is extra.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7611,
        store_longitude: -73.8302,
        total_orders: 494.4,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Karaoke, Vodka, Chinese, Asian, Classic, Modern, Clean, Urban, Friendly, Tempura',
        annual_dol: 1862.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5856,
        store_longitude: -73.9519,
        total_orders: 76.96,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Japanese, Sushi, Steak, New Zealand, Asian, Contemporary, Fusion, Cozy, Clean, Tempura',
        annual_dol: 314.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6993,
        store_longitude: -73.8308,
        total_orders: 26.14,
        neighborhood: 'Jamaica/Richmond Hill',
        keywords: 'Vegetarian, Wine, Relaxed, Clean, Spanish, Perfect, Bbq, Casual, Great, Chill',
        annual_dol: 106.85,
        description:
            'Vibrant, relaxed hookah bar pairing flavored pipe smokes with cocktails & Asian-Caribbean eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9774,
        total_orders: 172.11,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Italian, Wine, Stylish, Cozy, Classy, Iconic, Casual, Great',
        annual_dol: 763.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5879,
        store_longitude: -73.9606,
        total_orders: 7.71,
        neighborhood: 'Sheepshead Bay/Gravesend',
        keywords: 'Salad, Wine, Russian, Dessert, Beer, Upscale, French, Homemade, Elegant, Homey',
        annual_dol: 31.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8213,
        store_longitude: -73.9507,
        total_orders: 563.31,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Pizza, Vegan, Salad, Hamburger, Italian, Mexican, Vodka, Coffee, Meatball, Chinese',
        annual_dol: 587.74,
        description:
            'Bright, modern neighborhood Italian destination serving upmarket pizza, pasta & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6283,
        store_longitude: -74.0289,
        total_orders: 417.1,
        neighborhood: 'Bay Ridge',
        keywords:
            'Ale, Beer, Chicago-Style, Italian, American, Bbq, Homemade, Traditional, Local, Casual',
        annual_dol: 425.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9673,
        total_orders: 2524.72,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Salad, Tasty, Wine, Nutritious, Spicy, Japanese, Italian, Delicious, Stylish',
        annual_dol: 2848.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6213,
        store_longitude: -74.0322,
        total_orders: 82.63,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Burger, Hamburger, Salad, Mexican, Vodka, Irish, American, Cozy, Homemade, Casual',
        annual_dol: 337.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7077,
        store_longitude: -74.0135,
        total_orders: 967.4,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Steak, Salad, Chicken Caesar Salad, Margarita, Fajitas, Cuban, Swiss, Caesar Salad, American',
        annual_dol: 1096.32,
        description:
            'Low-key eatery serving traditional Mexican eats such as enchiladas, fajitas & breakfast burritos.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9874,
        total_orders: 2599.02,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Sushi, Steak, Wine, Dessert, Beer, Coffee, Ale, Trendy, Spicy, Korean',
        annual_dol: 7695.4,
        description:
            'Trendy spot with woodsy decor specialized in handmade udon noodle plates & other Japanese fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7461,
        store_longitude: -73.9891,
        total_orders: 260.14,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Hotel, Tequila, Stylish, Vodka, Rum, Gin, Rooftop, Chic, Modern, Colorful',
        annual_dol: 490.07,
        description:
            "Featuring colorful accents, the modern, intimate rooms offer free Wi-Fi and 40-inch flat-screen TVs, as well as minibars and coffeemakers. Some provide views of the Empire State Building or One World Trade Center. Upgraded rooms add bunk beds or terraces. Room service is available.Amenities include a laid-back restaurant, a lounge and a rooftop bar with a sundeck. There's also a business center with meeting rooms.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7801,
        store_longitude: -73.9807,
        total_orders: 180.88,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Wine, Dessert, Gluten Free, Mimosa, Beer, Mediterranean, Israeli, Trendy, Shawarma',
        annual_dol: 739.39,
        description:
            'Spacious bistro with an enclosed terrace dishing up traditional Mediterranean fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7285,
        store_longitude: -74.0016,
        total_orders: 538.48,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Tequila, Salad, Wine, Italian, Delicious, Spanish, Mediterranean, French, Crisp',
        annual_dol: 659.08,
        description:
            'European cuisine, with influences from the Basque Country & Vienna, presented in a lush setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.9822,
        total_orders: 231.84,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, French, Wine, Organic, Modern, American, Contemporary, Hotel, Classic, Perfect',
        annual_dol: 877.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7073,
        store_longitude: -73.9223,
        total_orders: 346.96,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Italian, Salad, Vegan, Wine, Dessert, Beer, Delicious, Stylish, Rustic',
        annual_dol: 380.94,
        description:
            'Stylish outfit serving wood-fired pies, salads & unique wines in a garage-like space with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6147,
        store_longitude: -73.9739,
        total_orders: 235.25,
        neighborhood: 'Mapleton/Midwood',
        keywords: 'Japanese, Diverse, Moroccan, Friendly, Great',
        annual_dol: 961.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.6296,
        total_orders: 491.29,
        neighborhood: '',
        keywords:
            'Steak, Cheesecake, Pizza, Gourmet, Italian, Wine, Delicious, Warm, Homemade, Classic',
        annual_dol: 898.33,
        description:
            'Neighborhood Italian standby with a warm vibe plating homemade ravioli & other red-sauce classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.6456,
        total_orders: 419.99,
        neighborhood: '',
        keywords:
            'Steak, Wine, Stylish, Italian, Impeccable, Spacious, Sleek, Outstanding, Classic, Impressive',
        annual_dol: 521.17,
        description: '"To Visit Us For a Tour, Schedule Your Appointment Today!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7836,
        store_longitude: -73.9745,
        total_orders: 1844.17,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Salad, Gluten Free, Italian, Romantic, Warm, Spacious, French, Seasonal, Lively',
        annual_dol: 1887.82,
        description:
            'Large restaurant with romantic lighting serving American fare with subtle Italian influences.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7859,
        store_longitude: -73.9765,
        total_orders: 142.01,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Coffee, Hamburger, Dessert, Organic, French, Mediterranean, Polished, Belgian, Moroccan',
        annual_dol: 580.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.866,
        store_longitude: -73.8302,
        total_orders: 123.47,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Steak, Salad, Hotdog, Coffee, Colombian, Chinese, Latin, American, Casual, Local',
        annual_dol: 504.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7104,
        store_longitude: -73.9337,
        total_orders: 146.49,
        neighborhood: 'East Williamsburg',
        keywords: 'Italian, Beer, Romantic, Perfect, Intimate, Excellent, Casual, Tzatziki',
        annual_dol: 598.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.833,
        store_longitude: -73.6256,
        total_orders: 1266.51,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Hamburger, Salad, Meatball, Italian, Scotch, French, Organic, French Onion Soup',
        annual_dol: 1322.94,
        description:
            'Homey pub serving American fare from bar plates to strip steaks in a sharp setting with patio seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6904,
        store_longitude: -73.5506,
        total_orders: 197.84,
        neighborhood: '',
        keywords:
            'Pizza, Cheesesteak, Steak, Salad, Shrimp Cocktail, Meatball, Italian, Beer, Wine, Tangy',
        annual_dol: 236.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7134,
        store_longitude: -73.9124,
        total_orders: 368.82,
        neighborhood: 'Flushing/Ridgewood/Maspeth',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Hotdog, Salad, Italian, Dessert, Spicy, Delicious',
        annual_dol: 724.06,
        description:
            'Modern, casual restaurant serving wood-fired pizzas & burgers, plus craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7413,
        store_longitude: -73.6628,
        total_orders: 5.09,
        neighborhood: '',
        keywords:
            'Sushi, Wine, Spicy, Delicious, Japanese, Friendly, Asian, Casual, Perfect, Great',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7675,
        store_longitude: -73.9184,
        total_orders: 45.36,
        neighborhood: 'Astoria/Long Island City',
        keywords:
            'Cozy, Friendly, Casual, Brazilian, Polished, Authentic, Bruschetta, Local, Great',
        annual_dol: 185.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7084,
        store_longitude: -73.938,
        total_orders: 974.18,
        neighborhood: 'East Williamsburg',
        keywords: 'Trendy, Classy, Fabulous, Classic, Amazing, Incredible, Casual, Unique',
        annual_dol: 994.85,
        description:
            'Energetic, late-night gay bar with high-tech appointments, basic furnishings & varied performances.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -73.9503,
        total_orders: 479.66,
        neighborhood: 'Greenpoint',
        keywords: 'Elegant, Coffee, Delicious, Impeccable, Premium, Outstanding, Fine',
        annual_dol: 652.96,
        description: '"Celebrate Every Occasion In Style at the Princess Manor."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7117,
        store_longitude: -73.77,
        total_orders: 20.06,
        neighborhood: 'Jamaica/Hollis',
        keywords: 'Jamaican, Caribbean, Hotel, Juicy',
        annual_dol: 81.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -73.9886,
        total_orders: 370.94,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Margarita, Delicious, Tex-Mex, Burritos, Fun, Lively, Polished, Vivid',
        annual_dol: 907.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9858,
        total_orders: 661.65,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Steak, Salad, Korean, Tasty, Polish, Spicy, Trendy, American, Elegant, Refined',
        annual_dol: 690.33,
        description:
            'This intimate locale with late hours serves up refined Korean fare in eco-chic surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7336,
        store_longitude: -73.9932,
        total_orders: 230.83,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Mexican, Steak, Tasty, Salad, Nachos, Shrimp Cocktail, Margarita, Dessert, Wine, Delicious',
        annual_dol: 998.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8008,
        store_longitude: -73.9682,
        total_orders: 2711.13,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Flavorful, Burger, Steak, Salad, Beer, Cosmopolitan, Spicy, Savory, Delicious, Martini',
        annual_dol: 2768.65,
        description:
            'Elegant, rustic-chic tavern presenting seasonally inspired takes on bar fare & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9788,
        total_orders: 3198.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Beer, Martini, Irish, Delicious, Cozy, Refined, American, Traditional, Classic',
        annual_dol: 3473.77,
        description:
            'Cozy spot with a refined vibe & traditional wood decor offering classic pub fare & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9811,
        total_orders: 1203.78,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Burger, Hamburger, Salad, Gourmet, Nachos, Dessert, American, Polished, Red Eye',
        annual_dol: 1255.97,
        description:
            'Old West-style steakhouse chain known for its burgers & chops made from bison.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9779,
        total_orders: 2688.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Pizza, Italian, Salad, Wine, Dessert, Tasty, Martini, Creamy, Organic, American',
        annual_dol: 3007.01,
        description:
            'Midtown eatery offering Italian basics & brick-oven pizza plus a vibrant happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8078,
        store_longitude: -73.946,
        total_orders: 810.26,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Delicious, Savory, Cajun, Gumbo, Fun, American, Authentic, Succulent, Daiquiri, Lit',
        annual_dol: 827.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8809,
        store_longitude: -73.5924,
        total_orders: 680.72,
        neighborhood: '',
        keywords: 'Coffee, Pizza, Wine, Salad, Italian, Beer, American, Quaint, Romantic, Sweet',
        annual_dol: 719.43,
        description:
            'Atmospheric eatery serving inventive Italian fare in an intimate, multiroom space with a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9737,
        total_orders: 108.57,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Lobster Roll, Gourmet, French, Luxurious, Elegant, Modern, Phenomenal, American, Contemporary',
        annual_dol: 110.87,
        description:
            'High-end New American restaurant/caviar specialist in a luxurious Madison Avenue setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -73.9848,
        total_orders: 4170.37,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Green Papaya Salad, Flavorful, Filipino, Thai, Spicy, Bbq, Vietnamese, Ramen, Malaysian',
        annual_dol: 4258.85,
        description:
            'Filipino-Thai-accented SE Asian fare & specialty drinks served in modern, stylish digs with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7214,
        store_longitude: -73.9878,
        total_orders: 445.61,
        neighborhood: 'Lower East Side',
        keywords: 'Fun, Comfortable, Great, Excellent, Creative, Dynamic, Casual',
        annual_dol: 464.93,
        description: '"Inspired by the Lower East Side at the turn of the century."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5931,
        store_longitude: -73.948,
        total_orders: 71.03,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Tasty, Wine, Fruity, Sushi, Delicious, Martini, Trendy, Coffee, Romantic, Italian',
        annual_dol: 308.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -73.8928,
        total_orders: 59.32,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Pizza, Salad, Tasty, Spicy, Mediterranean, Bbq, Premium, Great',
        annual_dol: 242.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7671,
        store_longitude: -73.6019,
        total_orders: 2932.61,
        neighborhood: '',
        keywords:
            'Spectacular, Premium, Bbq, Memorable, Clean, Great, Modern, Asian, Bold, Contemporary',
        annual_dol: 3166.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7806,
        store_longitude: -73.9811,
        total_orders: 1499.59,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Hotel, Wine, Beer, Cozy, Spacious, American, Friendly, Natural',
        annual_dol: 5264.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7767,
        store_longitude: -73.9788,
        total_orders: 54.64,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Meatball, Italian, Shrimp Scampi, Wine, American, Homemade, Classy, Casual, Traditional',
        annual_dol: 62.23,
        description:
            'Old-style Italian restaurant known for its meatballs, traditional dishes & vine-covered back garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8204,
        store_longitude: -73.6439,
        total_orders: 898.72,
        neighborhood: '',
        keywords:
            'Steak, Shrimp Cocktail, Gourmet, Impeccable, Luxurious, Delicious, Elegant, Perfect, Picturesque, Classic',
        annual_dol: 1023.58,
        description:
            '"Modern, Elegant, Picturesque Waterfront Wedding and Event Venue in Roslyn Harbor. Seven acres of gardens, waterways, waterfalls and fountains with our newly renovated mansion featuring artfully-designed banquet rooms and scenic landscaping that express a Jazz Age elegance, re-conceived in a contemporary style that is sleek, sophisticated, and very welcoming. The garden features two locations for outdoor ceremonies and our floating deck for open air entertainment."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8142,
        store_longitude: -73.9442,
        total_orders: 3384.09,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Salad, Beer, Nigerian, Mediterranean, American, Contemporary, Casual, Local, Classic',
        annual_dol: 3976.31,
        description:
            'Arts & performance space hosting all-ages shows & serving drinks & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7483,
        store_longitude: -73.9891,
        total_orders: 1219.77,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Golfclub, Urban, American, Clean, Classic, Cool, Awesome, Local, Casual, Signature',
        annual_dol: 1281.53,
        description:
            '"Five Iron Golf combines golf, entertainment, and good vibes to create a place where everyone can enjoy the game. Featuring TrackMan simulators, golf instructors, club fitting, and non-golf entertainment options such as Pop-A-Shot and ping pong, enjoy every amenity by reserving a simulator, booking a lesson, or playing in Five Iron\'s leagues, tournaments, and events."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7461,
        store_longitude: -73.9445,
        total_orders: 822.92,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Italian, Salad, French, American, Southern, Casual, Fabulous, Local, Excellent, Tradition',
        annual_dol: 957.76,
        description:
            'Italian & American comfort food served in a historic space with old-timey decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6479,
        store_longitude: -73.7887,
        total_orders: 1474.38,
        neighborhood: 'Jamaica',
        keywords:
            'Steak, Pizza, Salad, Coffee, Donut, Tasty, American, Delicious, New-York-Style, Martini',
        annual_dol: 1508.34,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -73.9875,
        total_orders: 4012.96,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Champagne, Vodka, Martini, Stylish, Hotel, Spicy, Distilled, Japanese, Fancy, Classy',
        annual_dol: 4168.25,
        description:
            'Stylish destination with exposed-brick walls dispensing fancy mixed drinks & refined light bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7345,
        store_longitude: -73.9923,
        total_orders: 27.57,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Vodka, Hotel, Trendy, Beer, Martini, Rooftop, Fun, Premium, Awesome',
        annual_dol: 112.71,
        description:
            'Drinking & techno, house & hip-hop dancing over 2 floors, which include a martini lounge & rooftop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8022,
        store_longitude: -73.9684,
        total_orders: 786.74,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Salad, Mexican, Tasty, American, Casual, Fresh, Local, Great, Recommended, Unique',
        annual_dol: 2761.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7327,
        store_longitude: -73.8636,
        total_orders: 39.98,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Tacos, Mexican, Lobster, Steak, Salad, Burritos, Fajitas, Rich, Delicious, Casual',
        annual_dol: 286.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6192,
        store_longitude: -74.0692,
        total_orders: 5.09,
        neighborhood: '',
        keywords: 'Sushi, Salad, Wine, Spicy, Japanese, Sashimi, Delicious, Ale, Miso Soup, Gyoza',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7649,
        store_longitude: -73.7719,
        total_orders: 891.69,
        neighborhood: 'Bayside',
        keywords:
            'Steak, Salad, Wine, Greek, Mediterranean, Polished, Friendly, Fresh, Casual, Fabulous',
        annual_dol: 916.92,
        description:
            'Airy Greek eatery with a long marble bar & white walls serving familiar Mediterranean small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.9327,
        total_orders: 56.02,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Pizza, Sushi, French, Asian, Smoky, Contemporary, Fusion, Perfect, Awesome',
        annual_dol: 229,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6233,
        store_longitude: -74.0313,
        total_orders: 920.85,
        neighborhood: 'Fort Hamilton',
        keywords: 'Lasagna, Pizza, Italian, Salad, Delicious, Rich, Warm, Organic, Quaint, Cozy',
        annual_dol: 940.39,
        description:
            'Homey candlit choice for contemporary Tuscan food from pastas to grilled & roasted meat entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9619,
        total_orders: 72.51,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Burger, Legendary, American, Wine, Classic, Luxurious, Iconic, Traditional, Superb, Sophisticated',
        annual_dol: 296.38,
        description:
            '"Our owner Joseph DeCristofaro is a born and raised Southampton local. Joe believes in supporting the community and providing the best in service and quality. An avid foodie, Joe is a world traveler who has been involved in every capacity in the restaurant industry since he was a teenager, and has extensive knowledge of cuisines from around the world."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7095,
        store_longitude: -74.0127,
        total_orders: 37.43,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Coffee, Wine, Hotel, Beer, Casual, Modern, Clean, Indian, Great',
        annual_dol: 153.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6704,
        store_longitude: -73.9298,
        total_orders: 15.9,
        neighborhood: 'Crown Heights',
        keywords:
            'Pizza, Flavorful, Cajun, Korean, Bbq, Jamaican, Rich, Caribbean, American, Friendly',
        annual_dol: 65.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6897,
        store_longitude: -73.9706,
        total_orders: 2624.41,
        neighborhood: 'Fort Greene',
        keywords: 'Tacos, Whiskey, Steak, Mexican, Wine, Beer, Salad, Dessert, Tasty, Delicious',
        annual_dol: 2989.02,
        description:
            'Latin American cooking by the Cómodo team in an airy, rustic space with a greenhouselike back room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9807,
        total_orders: 2254.43,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Garlic Bread, Burger, Steak, Cheesesteak, Tequila, Salad, Shrimp Cocktail, Wine, Beer, Irish',
        annual_dol: 2471.15,
        description:
            'Low-key watering hole with a full menu featuring traditional Irish eats & upscale entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7428,
        store_longitude: -73.9888,
        total_orders: 2486.11,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Italian, Spicy, Californian, Beer, Vibrant, Bruschetta, Crisp, Bourbon, Fresh',
        annual_dol: 2593.9,
        description:
            'Branch of the famed Italian market, offering counters, restaurants & cooking demos.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9806,
        total_orders: 319.39,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Pizza, Italian, Wine, Gluten Free, Vodka, Salad, Dessert, Meatloaf, Beer, Delicious',
        annual_dol: 340.38,
        description:
            'Sleek, low-key spot for contemporary Italian fare, wood-fired pizza & creative lunch sandwiches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.987,
        total_orders: 66.34,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'American, Hotel, Robust, Contemporary',
        annual_dol: 271.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7792,
        store_longitude: -73.7225,
        total_orders: 196.66,
        neighborhood: '',
        keywords:
            'Cheesesteak, Pizza, Steak, Wine, Beer, Philly Cheesesteak, Dessert, Great, Excellent, Unique',
        annual_dol: 803.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8047,
        store_longitude: -73.9548,
        total_orders: 2127.25,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Margarita, Beer, Salad, Luxurious, Irresistible, Fun, Sweet, Asian, Intimate',
        annual_dol: 4132.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.8039,
        total_orders: 47.07,
        neighborhood: 'Broadway–Flushing/Flushing/Murray Hill',
        keywords: 'Steak, American, Latin, Casual, Fresh, Great, Excellent',
        annual_dol: 192.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7278,
        store_longitude: -74.0028,
        total_orders: 2334.7,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Dessert, Wine, Italian, Stylish, French, Casual, Contemporary, Premium, American',
        annual_dol: 3767.77,
        description:
            'Hip, bi-level spot featuring Italian-accented New American fare, wine & art featuring boomboxes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7291,
        store_longitude: -73.9989,
        total_orders: 67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Cuban, Coffee, Rum, Spanish, Swiss, Delicious, American, Latin, Mojito',
        annual_dol: 293.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7776,
        store_longitude: -73.9784,
        total_orders: 1192.48,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Vegan, Salad, Beer, French Toast, Wine, Organic, Bbq, French, Spicy',
        annual_dol: 1217.78,
        description: '"Farm to Table restaurant. Proudly buying organic and local products."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6115,
        store_longitude: -74.01,
        total_orders: 47.38,
        neighborhood: 'Bath Beach/Bensonhurst',
        keywords:
            'Steak, Mexican, Shrimp Cocktail, Beer, Margarita, Local, Casual, Sangria, Fresh, Great',
        annual_dol: 193.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7503,
        store_longitude: -73.9872,
        total_orders: 12821.39,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Pizza, Luxurious, Upscale, Wine, Italian, Hotel, Rooftop, Trendy, Spacious, Delicious',
        annual_dol: 13619.38,
        description:
            'Spacious penthouse cocktail bar with clubby decor, light fare, indoor/outdoor seating & urban views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7163,
        store_longitude: -74.0097,
        total_orders: 1130.7,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Steak, Salad, Pizza, Italian, Delicious, Nachos, Juicy, Beer, Warm',
        annual_dol: 1154.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7227,
        store_longitude: -74.0048,
        total_orders: 5258.29,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Coffee, Trendy, Hotel, Rooftop, Delicious, Sleek, Modern, American, Fresh, Vintage',
        annual_dol: 5371.21,
        description:
            "Trade Center.Polished rooms with wood floors feature flat-screen TVs, free Wi-Fi and rainfall showers. Some rooms offer river or city views. Suites come with soaking tubs and separate living areas. Room service is available 24/7.Complimentary snacks and drinks are served in a lounge. Other amenities include a year-round rooftop bar and lounge and a seasonal outdoor rooftop pool. There's also a 24-hour gym.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7323,
        store_longitude: -74.0022,
        total_orders: 550.21,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Lobster, Lobster Tail, Delicious, Vodka, Juicy, Filipino, Sustainable, Lebanese',
        annual_dol: 586.78,
        description:
            '"Serving a variety of dishes from today\'s coolest chefs, Hungry House features modern Filipino food from Woldy Kusina, hearty salads from Pierce Abernathy, super tortas from Chile Con Miel, smashburgers from Apocalypse Burger, ice cream pints from Caffe Panna and curated local goods from The Goods Mart."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7467,
        store_longitude: -73.6432,
        total_orders: 132.75,
        neighborhood: '',
        keywords:
            'Steak, Vegetarian, Shrimp Cocktail, Wine, Italian, Delicious, Laid-Back, American, Perfect, Authentic',
        annual_dol: 283.34,
        description:
            'Classic Portuguese fare & sangria with an outdoor courtyard & weekend live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7319,
        store_longitude: -74.0015,
        total_orders: 588.68,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Mexican, Margarita, Wine, Nachos, Quesadillas, Pinacolada, Burritos, American, Latin',
        annual_dol: 759.22,
        description: 'Basic cantina feeding NYU students with burritos & other Mexican standards.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.64,
        store_longitude: -74.0158,
        total_orders: 371.49,
        neighborhood: 'Sunset Park',
        keywords: 'Pizza, Upscale, Hotel, Fun, Irish, Awesome, Ale, Great, Casual',
        annual_dol: 763.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.786,
        store_longitude: -73.9761,
        total_orders: 955.95,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Vegetarian, Coffee, Beer, Dessert, Ale, Organic, Mediterranean, Comfortable, Moroccan',
        annual_dol: 1526.77,
        description:
            'This small, contemporary eatery features Mediterranean dishes cooked in a custom clay oven.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7147,
        store_longitude: -73.8323,
        total_orders: 141.5,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Beer, Irish, Bbq, American, Awesome, Fresh, Terrific, Great, Casual',
        annual_dol: 257.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7738,
        store_longitude: -73.8748,
        total_orders: 16.75,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Coffee, American, Hotel, Innovative, Premium, British, Artisanal, Fun, Exceptional, Incredible',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6476,
        store_longitude: -74.0073,
        total_orders: 22.17,
        neighborhood: 'Sunset Park',
        keywords: 'Mexican, Tex-Mex, Turkish, Italian, Casual, Tradition, Great',
        annual_dol: 90.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7088,
        store_longitude: -73.625,
        total_orders: 93.52,
        neighborhood: '',
        keywords: 'Mexican, Wine, Chinese, French, Casual, American, Natural',
        annual_dol: 327.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -73.9509,
        total_orders: 1189.3,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Burger, Lobster, Salad, Chicken Caesar Salad, Shrimp Cocktail, Baked Clams, Beer, Wine, Martini, Caesar Salad',
        annual_dol: 2772.55,
        description:
            'Hearty, American comfort food like baked clams & unfussy drinks served on red-checkered tablecloths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7742,
        store_longitude: -73.9777,
        total_orders: 209.16,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Salad, Delicious, Coffee, Savory, Spacious, Organic, Avocado Toast, Sweet, Hungarian, Healthy',
        annual_dol: 854.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.917,
        total_orders: 279.38,
        neighborhood: 'Astoria',
        keywords: 'Wine, Pizza, Italian, Warm, Gourmet, Beer, Romantic, Refined, Friendly, Classy',
        annual_dol: 304.04,
        description:
            'Gourmet pizza & artisinal pasta paired with wine, beer & cocktails in a warm, refined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6439,
        store_longitude: -73.7824,
        total_orders: 735.73,
        neighborhood: 'Jamaica',
        keywords:
            'Burger, Pizza, Salad, Wine, Donut, Coffee, Premium, Comfortable, Great, Recommended',
        annual_dol: 767.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7266,
        store_longitude: -73.9863,
        total_orders: 3834.85,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Italian, Wine, Dessert, Peruvian, Stylish, Yummy, American, Casual, Classic',
        annual_dol: 4367.63,
        description:
            'Creative cocktails & craft beers pair with elevated bar bites like oysters at this stylish hangout.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.738,
        store_longitude: -73.9877,
        total_orders: 7427.92,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Lobster, Steak, Salad, Mexican, Upscale, Thai, English Muffin, Sustainable, Hotel',
        annual_dol: 10286.97,
        description:
            'Upscale offshoot of a Westchester restaurant with its own farm & sustainable seafood source.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7365,
        store_longitude: -73.9866,
        total_orders: 4721.42,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Chicken Parmigiana, Salad, Shrimp Cocktail, Wine, Beer, Italian, Meatball, Ale, Vintage',
        annual_dol: 4821.6,
        description:
            'Vintage bar, in continuous operation since 1864, serving burgers & Italian fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5853,
        store_longitude: -74.0937,
        total_orders: 772.89,
        neighborhood: 'Midland Beach',
        keywords:
            'Pizza, Italian, Gourmet, Flavorful, Indian, Dim Sum, Traditional, Great, Casual, Authentic',
        annual_dol: 1976.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5445,
        store_longitude: -74.1409,
        total_orders: 823.28,
        neighborhood: 'Great Kills',
        keywords: 'Shrimp Cocktail, American, Local, Great',
        annual_dol: 878.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.7716,
        total_orders: 68.1,
        neighborhood: 'Bayside',
        keywords:
            'Lobster, Lobster Tail, Cuban, Beer, Mexican, Italian, Trendy, Exotic, French, Asian',
        annual_dol: 153.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9762,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Beer, Wine, Retro, Classic, High-End, Fancy, Impressive, Great, Nostalgic',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7096,
        store_longitude: -73.8302,
        total_orders: 7198.74,
        neighborhood: 'Kew Gardens/Jamaica',
        keywords:
            'Steak, Burger, Hamburger, Lobster, Lobster Roll, Salad, Beer, Ale, French, American',
        annual_dol: 7573.01,
        description:
            'Steaks, burgers & other American comfort food served in a pub setting with a patio & TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6312,
        store_longitude: -74.0277,
        total_orders: 1012.49,
        neighborhood: 'Bay Ridge',
        keywords:
            'Salad, Vegetarian, Dessert, Beer, Spicy, Delicious, Japanese, Mediterranean, Lebanese, Warm',
        annual_dol: 1082.41,
        description:
            'Generous portions of Mediterranean-Middle Eastern cuisine come with a warm vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7604,
        store_longitude: -73.9897,
        total_orders: 811.01,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Italian, Wine, Beer, Delicious, American, French, Greek, Classy, Fancy',
        annual_dol: 961.38,
        description:
            'Easygoing spot for customizable pizza with gluten-free offerings, calzones & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6894,
        store_longitude: -73.9706,
        total_orders: 2064.77,
        neighborhood: 'Fort Greene',
        keywords:
            'Burger, Salad, Italian, Tequila, Margarita, Spicy, Rustic, Cozy, Seasonal, Perfect',
        annual_dol: 2206.01,
        description:
            'Cozy Fort Greene Italian eatery with rustic dishes, sidewalk seating & a picket-fenced back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9817,
        total_orders: 2834.51,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Elegant, Casual, Outstanding',
        annual_dol: 2897.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.781,
        store_longitude: -73.9477,
        total_orders: 6509.53,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Wine, Delicious, Rich, Upscale, Warm, Cozy, Polish, Intimate, Playful, Classic',
        annual_dol: 6647.65,
        description:
            'Upscale Indian eatery & bar serving craft cocktails & bites in cozy digs with a colonial-era vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.9894,
        total_orders: 1164.55,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Dessert, Italian, Californian, Mediterranean, Lebanese, Classy, Exquisite, Bright, Seasonal',
        annual_dol: 1465.02,
        description:
            'Seasonal, Mediterranean-influenced fare & creative cocktails showcase flowers & herbs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.9941,
        total_orders: 260.76,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Wine, Hamburger, Pizza, French, Elegant, Delicious, Magical, Hotel, Classy',
        annual_dol: 441.11,
        description:
            'French fare with cocktails & wines in a Victorian-inspired brasserie space with 2 outdoor areas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7576,
        store_longitude: -73.987,
        total_orders: 2175.96,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Wine, Beer, American, French, Vintage, Polished, Traditional, Prime Rib',
        annual_dol: 2318.95,
        description:
            '"Capturing the 1940s glamour of Hollywood and spirit of Times Square, Bacall\'s features large portions and home cooked foods eastern European cuisine from our craft kitchen."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9983,
        total_orders: 1180.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Premium, Classic, Authentic, Beer, Perfect, Fun, Excellent, Asian',
        annual_dol: 4447.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.9859,
        total_orders: 2660.39,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, American, Modern, Perfect, Amazing',
        annual_dol: 2837.24,
        description:
            '"Located at the "Crossroad of the World" on 44th and Broadway, Palladium Times Square is a modern, state-of-the-art, and versatile venue. The perfect venue for live music, corporate or private events, receptions, sit-down dinners, and more."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7795,
        store_longitude: -73.9504,
        total_orders: 32.38,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Lasagna, Salad, Chicken Parmigiana, Pizza, Italian, Veal Parmigiana, Caesar Salad, Casual, Local, Natural',
        annual_dol: 37.77,
        description:
            'Italian cuisine served in an intimate, elevated setting with exposed-brick walls & low lighting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7033,
        store_longitude: -73.9894,
        total_orders: 270.2,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords: 'Wine, Beer, Upscale, Perfect, Casual, Local, Iconic',
        annual_dol: 1104.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.602,
        store_longitude: -74.003,
        total_orders: 63.51,
        neighborhood: 'Bath Beach',
        keywords:
            'Peruvian, Salad, Vodka, Martini, Beer, Delicious, Latin, Friendly, Seasonal, American',
        annual_dol: 259.6,
        description:
            'Casual fixture offering roast chicken & other Peruvian fare, plus beer & sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7537,
        store_longitude: -74.0002,
        total_orders: 27.74,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Champagne, Vegan, Salad, Beer, Dessert, Spanish, Mediterranean, Premium, American',
        annual_dol: 113.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7281,
        store_longitude: -73.9994,
        total_orders: 937.73,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Whiskey, Wine, Beer, Bourbon, Margarita, Ale, French Onion Soup, American, French',
        annual_dol: 1014.03,
        description:
            'Sprawling British-inspired pub with leather-&-mahogany decor & an extensive draft-beer list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7704,
        store_longitude: -73.9074,
        total_orders: 458.01,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Shrimp Cocktail, Tequila, Wine, Beer, Greek, American, Mediterranean, Ribeye Steak, Traditional',
        annual_dol: 488.11,
        description:
            'White-tablecloth steakhouse where the beef is complemented by creative Greek appetizers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7354,
        store_longitude: -74.0006,
        total_orders: 1022.78,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesesteak, Beer, American, Upscale, Philly Cheesesteak, Great, Fresh, Wonderful, Creative, Casual',
        annual_dol: 1067.12,
        description:
            'Philadelphia meets Buffalo on the menu at this convivial spot famed for its cheesesteaks & wings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.6345,
        total_orders: 1981.96,
        neighborhood: '',
        keywords:
            'Vodka, Cosmopolitan, Gourmet, Upscale, Dessert, Martini, Italian, Delicious, Sustainable, Clean',
        annual_dol: 2036.27,
        description:
            'Cosmopolitan bistro serving a menu of globally inspired American seafood, meat & pasta dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7296,
        store_longitude: -74.0049,
        total_orders: 649.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Tacos, Cheesecake, Salad, Chicken Pot Pie, Beer, Dessert, Tasty, Martini, Ale',
        annual_dol: 731.55,
        description:
            'Laid-back venue whipping up brunch, burgers, tacos & finger foods, plus draft beer & cool cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7232,
        store_longitude: -73.6589,
        total_orders: 1315.28,
        neighborhood: '',
        keywords:
            'Burger, Chicken Caesar Salad, Hotel, Coffee, American, Caesar Salad, Salad, Trendy, Premium, Great',
        annual_dol: 1406.24,
        description:
            'Straightforward rooms feature free Wi-Fi, flat-screen TVs and separate living areas with pull-out sofas, plus minifridges, and tea and coffeemaking equipment.Parking is complimentary. Other amenities include a gym, an indoor pool and a cafe/bar, plus a 24-hour market, and meeting and event space. Breakfast is available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8529,
        store_longitude: -73.8277,
        total_orders: 58.41,
        neighborhood: 'Pelham Bay',
        keywords:
            'Burger, Salad, Beer, Delicious, Puerto Rican, Legendary, American, Latin, Awesome, Local',
        annual_dol: 238.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.736,
        store_longitude: -74.0009,
        total_orders: 1043.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Italian, Salad, Coffee, Wine, Beer, Ale, Avocado Toast, Rustic, Yummy',
        annual_dol: 1763.45,
        description:
            'Pizzeria from the popular sibling concept of the same name offering a range of Italian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7364,
        store_longitude: -74.006,
        total_orders: 28.94,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Wine, Organic, Dessert, Relaxed, Natural, Casual, Pure',
        annual_dol: 118.28,
        description:
            'Bright all-day outpost offering vegetarian & vegan fare, plus juices in a relaxed space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7875,
        store_longitude: -73.9763,
        total_orders: 407.02,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Beer, Chinese, Spicy, Rum, Sweet, Friendly, Relaxed, Authentic, Perfect',
        annual_dol: 442.94,
        description:
            'Spicy Sichuan dishes like dan dan noodles are heat-rated by number at this informal restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.9979,
        total_orders: 131.41,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Vegan, Chicken Parmigiana, Gluten Free, Italian, Flavorsome, Meatball, Tasty, Wine, Trendy',
        annual_dol: 339.22,
        description:
            'Bright, contemporary Italian spot with a wood-paneled atrium offering mix-&-match pasta dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7442,
        store_longitude: -73.6948,
        total_orders: 26.9,
        neighborhood: '',
        keywords: 'Burger, Salad, Pizza, Savory, Clean, Smooth, Modern, Friendly, Casual, American',
        annual_dol: 28.63,
        description:
            'A huge menu of classic diner eats in modern environs with cocktails & booth seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7017,
        store_longitude: -73.8987,
        total_orders: 690.18,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Salad, Cozy, Pizza, Burger, Wine, Ale, Beer, Warm, Welcoming, Casual',
        annual_dol: 1353.15,
        description:
            'Cocktails, beer & informal plates offered in a warm neighborhood bar with covered outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.9197,
        total_orders: 776.61,
        neighborhood: 'Astoria',
        keywords:
            'Burger, Whiskey, Irish, American, Karaoke, Jamaican, Warm, Welcoming, Classic, Lively',
        annual_dol: 831.69,
        description:
            'Comfy hangout offering classic Irish fare, burgers & beers, with TV sports & karaoke nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6632,
        store_longitude: -73.9913,
        total_orders: 1247.33,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Flavorful, Cozy, Beer, Spacious, Comfortable, Fun, Friendly, Awesome, Perfect, Homey',
        annual_dol: 1301.41,
        description:
            'Cozy bar boasting bourbons & 70 bottled beers plus a menu of grilled cheese, PB&J & other noshes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.9118,
        total_orders: 434.19,
        neighborhood: 'Astoria',
        keywords: 'Steak, Sushi, Pizza, Italian, Wine, Impeccable, Casual, Fresh, Classic, Local',
        annual_dol: 1524.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.917,
        total_orders: 62.43,
        neighborhood: 'Astoria',
        keywords:
            'Burger, Wine, Tasty, Upscale, Coffee, Delicious, Magical, Spanish, Mediterranean, Hotel',
        annual_dol: 88.03,
        description:
            '"Great drinks , best hookah , food you gonna love ! We have indoor and outdoor dining"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6157,
        total_orders: 4154.38,
        neighborhood: '',
        keywords:
            'Steak, Salad, Tequila, Pizza, French, Caesar Salad, American, Brazilian, Spicy, Martini',
        annual_dol: 4184.67,
        description:
            'Outpost of upmarket steakhouse chain known for sizzling, butter-topped beef in an elegant setting',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7373,
        store_longitude: -74.008,
        total_orders: 3307.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Fruity, Hotel, Italian, Quaint, Mediterranean, Classy, American, Intimate, Perfect',
        annual_dol: 3428.59,
        description:
            'Quaint duplex wine/cocktail bar with Mediterranean restaurant, plus fireplace & garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7408,
        store_longitude: -74.0068,
        total_orders: 252.11,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Vegetarian, Wine, Italian, Vodka, Elegant, Creme Brulee, Friendly, Fine',
        annual_dol: 1030.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7484,
        store_longitude: -73.6438,
        total_orders: 292.5,
        neighborhood: '',
        keywords:
            'Steak, Colombian, Salad, Beer, American, Latin, Spanish, Perfect, Casual, Signature',
        annual_dol: 325.43,
        description: '"Colombian cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -74.0011,
        total_orders: 273.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Burger, Italian, Wine, Mexican, Woody, American, Modern, Classy, Fancy',
        annual_dol: 1116.09,
        description: '"The Rebirth of a Greenwich Village Classic"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6276,
        store_longitude: -73.8999,
        total_orders: 49.85,
        neighborhood: 'Canarsie',
        keywords: 'Fun, Jamaican, Homemade, Great, French',
        annual_dol: 203.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7484,
        store_longitude: -73.9858,
        total_orders: 83.08,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Burger, Korean, New Zealand, Ramen, Heritage, Great',
        annual_dol: 339.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7705,
        store_longitude: -73.9201,
        total_orders: 896.1,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tequila, Wine, Margarita, Salad, Greek, Mediterranean, Organic, Stylish, Elegant, Relaxed',
        annual_dol: 1188.24,
        description:
            'Stylish restaurant serving traditional Greek dishes, seafood & cocktails in a relaxed space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6896,
        store_longitude: -73.6854,
        total_orders: 73.84,
        neighborhood: '',
        keywords: 'Steak, Peruvian, Salad, Beer, Dessert, Spicy, Delicious, Latin, Yummy, American',
        annual_dol: 278.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7097,
        store_longitude: -73.6188,
        total_orders: 10.81,
        neighborhood: '',
        keywords: 'Mexican, Spanish, Gluten Free, American, Latin, Casual',
        annual_dol: 44.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7708,
        store_longitude: -73.9202,
        total_orders: 5.36,
        neighborhood: 'Astoria/LIC',
        keywords: 'Pizza, Italian, Beer, American, Latin, Homemade, Polished, Fresh, Local, Casual',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7647,
        store_longitude: -73.9163,
        total_orders: 710.72,
        neighborhood: 'Astoria',
        keywords:
            'Steak, Tacos, Salad, Coffee, Dessert, Beer, Organic, Caesar Salad, Paloma, Outstanding',
        annual_dol: 860.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6678,
        store_longitude: -73.7983,
        total_orders: 1927.88,
        neighborhood: 'South Ozone Park',
        keywords: 'Hotel, Coffee, Sleek, Contemporary, Great',
        annual_dol: 1968.79,
        description:
            'The contemporary rooms come with 42-inch flat-screen TVs and free Wi-Fi, plus desks, minifridges and coffeemakers. Suites add separate living rooms with wet bars. Room service is available.An airport shuttle and parking are complimentary. Other amenities include a sleek restaurant, a lobby lounge/bar with floor-to-ceiling windows, and a 24-hour fitness center, as well as 7 meeting rooms and a 24/7 business center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6725,
        store_longitude: -73.9866,
        total_orders: 304.52,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Pizza, Salad, Beer, Wine, Rustic, Swiss, American, Karaoke, Thai',
        annual_dol: 1244.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.737,
        store_longitude: -74.0039,
        total_orders: 787.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Gluten Free, Vegetarian, Dessert, Upscale, Beer, Cozy, Italian, Warm, French',
        annual_dol: 1355.3,
        description:
            'Oysters, wine & Provençal cooking in a setting with warm woods, large windows & an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7364,
        store_longitude: -74.0066,
        total_orders: 269.92,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Wine, Avocado Toast, American, Italian, Spicy, Seasonal, Contemporary, Bright, Fresh',
        annual_dol: 1103.35,
        description:
            '"The Mary Lane is a seasonal New American Restaurant in the West Village, located on the cobblestone corner of Bank and Greenwich Streets, serving locally sourced food with a farm-to-table menu. At The Mary Lane, we offer our guests a curated wine list, delectable craft cocktails, and seasonally inspired dishes to be enjoyed with friends and family all while being served with the genuine warmth of Blackfoot Hospitality. Chef Price\'s signature market-based fare consists of contemporary cuisine sourced from our local purveyors."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7825,
        store_longitude: -73.9805,
        total_orders: 748.29,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Salad, Vegetarian, Italian, Wine, Dessert, Delicious, Hotel, Rustic, Bellini',
        annual_dol: 780.73,
        description:
            'Italian eatery serving thin-crust pizzas & homemade pastas amid exposed brick walls & rustic murals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7491,
        store_longitude: -73.5879,
        total_orders: 40.72,
        neighborhood: '',
        keywords: 'Pizza, Latin, Premium, Friendly, Perfect, Cool, Great, Quiche',
        annual_dol: 166.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6975,
        store_longitude: -73.9315,
        total_orders: 1029.07,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Delicious, Friendly, Cool, Urban, Great, Casual, Bloody Mary, Local',
        annual_dol: 1050.9,
        description:
            'Funky, compact dive bar with a throwback punk-rock vibe, pinball machines & a photo booth.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9712,
        total_orders: 201.73,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Wine, Chicken Caesar Salad, Vodka, French, Gracious, Caesar Salad, Premium, Exquisite, Casual',
        annual_dol: 708.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7176,
        store_longitude: -74.0125,
        total_orders: 2350.76,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Steak, Cheesecake, Chicken Parmigiana, Chocolate Cake, Shrimp Cocktail, Salad, Lobster Bisque, Italian, Wine',
        annual_dol: 3071.06,
        description:
            'Upscale steakhouse chain link known for its setting bedecked with caricatures of famous people.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.9617,
        total_orders: 1040.02,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Italian, Delicious, Cozy, Premium, Wonderful, Great, Fresh, Traditional, Fine',
        annual_dol: 1076.16,
        description:
            'Clubby neighborhood stomping ground serving Italian dishes to regular & celebrity patrons.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.9669,
        total_orders: 229.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster Roll, Lobster, Cornbread, Tomato Soup, Salad, Dessert, Wine, Apple Pie, Coffee, Italian',
        annual_dol: 247.83,
        description:
            'A wide-ranging European menu plus brunch are served in a cozy candlelit space with an ample garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7254,
        store_longitude: -73.9451,
        total_orders: 339.92,
        neighborhood: 'Greenpoint',
        keywords:
            'Pizza, Burger, Beer, Wine, Gluten Free, Karaoke, Hotel, American, Artisanal, Cozy',
        annual_dol: 1389.49,
        description:
            '"We\'re a wine bar with artisanal cheeses, charcuterie, cocktails and bangin\' pizza!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7224,
        store_longitude: -74.0035,
        total_orders: 551.03,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Chicken Parmigiana, Shrimp Cocktail, Italian, Meatball, Wine, Upscale, Mediterranean, Modern, Classic, Fresh',
        annual_dol: 587.66,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7044,
        store_longitude: -74.0105,
        total_orders: 117.2,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Quesadillas, Delicious, American, Burritos, Latin, Awesome',
        annual_dol: 171.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8072,
        store_longitude: -73.927,
        total_orders: 937.22,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Steak, Italian, Pizza, Tasty, Coffee, Casual, Classic',
        annual_dol: 1058.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -74.0046,
        total_orders: 911.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Chicago-Style, Sushi, Italian, German, Meatball, Beer, Gluten Free, Martini, Chinese',
        annual_dol: 950.92,
        description:
            'Sophisticated hangout dispensing boozy drinks & Italian plates, including Chicago-inspired pizzas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7395,
        store_longitude: -74.0082,
        total_orders: 141.51,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Coffee, Lobster, Wine, American, Contemporary, Polished, Lively, Seasonal, Spectacular, Casual',
        annual_dol: 578.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8419,
        store_longitude: -73.8372,
        total_orders: 64.24,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords: 'American',
        annual_dol: 209.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7847,
        store_longitude: -73.9733,
        total_orders: 901.22,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Vegetarian, Salad, Mexican, Nachos, Dessert, Margarita, Gluten Free, Fajitas, Spicy',
        annual_dol: 1056.81,
        description:
            'This lively Southwestern cafe dishes generous portions of grub & pairs them with large margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6366,
        store_longitude: -74.0259,
        total_orders: 748.97,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Ale, American, Karaoke, Irish, Cozy, Traditional, Casual',
        annual_dol: 830.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -73.6743,
        total_orders: 480.14,
        neighborhood: '',
        keywords: 'Burger, Steak, Lobster, Pizza, Italian, Tasty, Dessert, Swiss, Upscale, Bbq',
        annual_dol: 931.66,
        description:
            'Busy restaurant & watering hole serving steaks, seafood & burgers, plus specialty cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7009,
        store_longitude: -73.8918,
        total_orders: 6.69,
        neighborhood: 'Flushing/Glendale',
        keywords: 'Beer, Tasty, German, Irish, Fun, Great, Friendly, Fresh, Casual',
        annual_dol: 27.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.575,
        store_longitude: -73.9853,
        total_orders: 1296.51,
        neighborhood: 'Coney Island',
        keywords: 'Wine, Great',
        annual_dol: 5863.97,
        description:
            'Maimonides Park is a minor league baseball stadium on the Riegelmann Boardwalk in Coney Island, Brooklyn, New York City. The home team and primary tenant is the New York Mets-affiliated Brooklyn Cyclones of the South Atlantic League. The stadium has also hosted other teams as well.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6762,
        store_longitude: -73.9836,
        total_orders: 426.86,
        neighborhood: 'Park Slope',
        keywords:
            'Beer, Fun, Lively, Superb, Friendly, Irish, Fantastic, Great, Impressive, Attentive',
        annual_dol: 536.54,
        description:
            'Formerly a tire shop, this beer bar sports a lively vibe fueled by board games & an outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.669,
        store_longitude: -73.9896,
        total_orders: 87.91,
        neighborhood: 'Park Slope',
        keywords:
            'Steak, Salad, Dessert, Coffee, French, Authentic, Natural, Casual, Fantastic, Great',
        annual_dol: 359.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6945,
        store_longitude: -73.9306,
        total_orders: 2566.37,
        neighborhood: 'Bushwick',
        keywords:
            'Whiskey, Beer, Crisp, Smooth, Friendly, Casual, Awesome, Eclectic, Great, Hard Cider',
        annual_dol: 2620.83,
        description:
            'Low-lit bar with a chill vibe, featuring a pool table & music events, including DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7519,
        store_longitude: -73.9833,
        total_orders: 2381.49,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Beer, Upscale, Trendy, Classy, Fun, Intimate, Vivid, Casual',
        annual_dol: 2432.02,
        description:
            '"Sapphire 39 is an upscale Gentlemen\'s Club With the elegance and class of the original Upper East Side location and its sister club in Las Vegas, Sapphire 39 offers deluxe amenities, erotic programming and VIP concierge services including designer suites, couples packages, private butlers and luxury chauffeurs to satisfy every desire. In addition to its world class roster of over 8,000 talented and provocative dancers, the club hosts the hottest starts in adult entertainment, exclusive guest DJs and live music from the sultry Sirens of Sapphire Island."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6557,
        store_longitude: -74.0026,
        total_orders: 1919.68,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Karaoke, Delicious, Warm, Beer, Friendly, Awesome, Irish, Perfect, Great, Cool',
        annual_dol: 2042.91,
        description: '"A bar for the neighborhood."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.796,
        store_longitude: -73.9357,
        total_orders: 45.56,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Burger, Salad, Pizza, Vodka, Caesar Salad, Spanish, American, Ribeye Steak, Latin',
        annual_dol: 89.33,
        description:
            'Upbeat steakhouse offering an eclectic menu with seafood & global flavors, plus a lively bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7041,
        store_longitude: -74.0092,
        total_orders: 16.75,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Beer, Hamburger, Nachos, Spanish, Thai, Phenomenal, Fun, Friendly, Vibrant',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6849,
        store_longitude: -73.8673,
        total_orders: 41.54,
        neighborhood: 'Cypress Hills',
        keywords:
            'Salad, Shrimp Cocktail, Chicken Caesar Salad, Coffee, Gourmet, Italian, Dominican, Organic, Pinacolada, Spanish',
        annual_dol: 169.8,
        description:
            'Lively restaurant & bar providing elevated Pan-Latin cuisine & craft cocktails, plus entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -74.0041,
        total_orders: 2417.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Delicious, Cozy, Beer, Hotel, Italian, Fun, Awesome, Cool, Great',
        annual_dol: 2468.84,
        description:
            '"A secret speakeasy located in the heart of the West Village. A lovers den with cozy fire places, mahogany floors and crushed velvet banquettes. We serve delicious seafood and coastal fare, paired with bone cold classic martinis and craft cocktails. All while early lovers rock reggae, soul and hip hop wash over you."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 665.09,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Cuban, Margarita, Wine, Delicious, Caribbean, Ale, Rum, American',
        annual_dol: 964.88,
        description:
            'Boisterous Cuban restaurant serving island staples & cocktails in a retro-Havana setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.6401,
        total_orders: 2.32,
        neighborhood: '',
        keywords:
            'Pizza, Chicken Parmigiana, Italian, Salad, Shrimp Cocktail, Vodka, Dessert, Upscale, Spicy, American',
        annual_dol: 9.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6575,
        store_longitude: -73.6701,
        total_orders: 182.34,
        neighborhood: '',
        keywords: 'Tacos, Burger, Salad, American, Casual, Latin, Classy, Traditional',
        annual_dol: 745.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7101,
        store_longitude: -73.8994,
        total_orders: 62.83,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Wine, Vodka, Beer, Delicious, Margarita, Karaoke, Fresh, Cozy, Awesome, Italian',
        annual_dol: 106.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7705,
        store_longitude: -73.9125,
        total_orders: 2218.03,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Karaoke, Fun, Friendly, Irish, Fabulous, Casual, Great',
        annual_dol: 2493.3,
        description:
            'Upbeat gay bar offering cocktails along with karaoke, drag shows, bingo & TV viewing parties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7712,
        store_longitude: -73.9564,
        total_orders: 3520.92,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Tasty, Beer, Delicious, Nachos, Bbq, Fun, Friendly, Fancy, Clean',
        annual_dol: 3687.21,
        description:
            'Beer pong, beverage deals & TVs tuned to sports sum up the scene at this popular watering hole.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.9394,
        total_orders: 4099.21,
        neighborhood: 'East Williamsburg',
        keywords: 'Legendary, Great, Hip',
        annual_dol: 4276.94,
        description:
            'Brooklyn Steel is an 1800-capacity music venue in Brooklyn, New York, United States. In 2018 Brooklyn Steel was named one of the 10 best live music venues in America by Rolling Stone Magazine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7694,
        store_longitude: -73.9627,
        total_orders: 67.84,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vodka, Wine, Sushi, Italian, Cozy, Yummy, Authentic, Wonderful, Excellent, Casual',
        annual_dol: 277.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7312,
        store_longitude: -74.0022,
        total_orders: 236.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Dairy Free, Wine, Vodka, Shrimp Cocktail, Italian, Organic, Martini, Quaint, Cozy',
        annual_dol: 400.19,
        description:
            'Italian classics are crafted from organic ingredients in a sunwashed stucco space with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -74.0073,
        total_orders: 2994.78,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Steak, Shrimp Cocktail, Wine, Gluten Free, Beer, Spicy, American, Premium, Sleek, Rooftop',
        annual_dol: 3259.09,
        description:
            'A chic clientele samples steak & scenery in this sleek Meatpacking District setup with a roof deck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7384,
        store_longitude: -73.6522,
        total_orders: 308.04,
        neighborhood: '',
        keywords:
            'Steak, Flavorful, Beer, Bbq, Salad, Brazilian, Fantastic, Great, Excellent, Relaxed',
        annual_dol: 314.57,
        description:
            'Relaxed Portuguese barbecue specialist favored for all-you-can-eat meats carved tableside.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7374,
        store_longitude: -74.0059,
        total_orders: 16.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Champagne, Wine, Delicious, Romantic, Quaint, French, Classy, Fun, Polished, Friendly',
        annual_dol: 17.82,
        description:
            'Romantic French haunt offering a taste of the Left Bank among chandeliers & a fireplace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7296,
        store_longitude: -74.0043,
        total_orders: 15.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Steak, Sushi, Salad, Beer, Savory, Spicy, Homemade, Modern, Russian',
        annual_dol: 63.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7825,
        store_longitude: -73.9805,
        total_orders: 1680.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Upscale, Stylish, Hotel, Rich, Cozy, Warm, Italian, Comfortable, Gin, Bright',
        annual_dol: 1715.99,
        description:
            'Bright, contemporary rooms offer wood floors, flat-screen TVs and Wi-Fi (fee), plus desks, coffeemakers and custom artwork from local artists; suites add extra living space. Some rooms provide balconies with city views.Amenities include a casual Italian restaurant, a hip speakeasy-inspired bar and 2 rooftop terraces, plus a business center, and a cozy lounge with a piano, a fireplace and a library.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7018,
        store_longitude: -73.8816,
        total_orders: 1265.11,
        neighborhood: 'Flushing/Glendale',
        keywords: 'Salad, German, Beer, Russian, Homemade, Casual, Authentic, Fresh, Classic, Fine',
        annual_dol: 1365.25,
        description:
            'German beer hall with classic fare & frosty steins delivered by waitresses in Alpine costume.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6656,
        store_longitude: -73.5528,
        total_orders: 109.35,
        neighborhood: '',
        keywords:
            'Hamburger, Salad, Beer, Nachos, Irish, Bourbon, French, American, Karaoke, Casual',
        annual_dol: 254.92,
        description:
            'This laid-back hangout with a wood-paneled bar features Irish pub fare, draft beer & weekly karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7716,
        store_longitude: -73.9267,
        total_orders: 55.37,
        neighborhood: 'Astoria',
        keywords: 'Luxurious, Spacious, Romantic, Fine',
        annual_dol: 226.32,
        description:
            '"Renaissance Event Hall caters to all your event needs in a sophisticated, upscale, luxurious fashion with elegance & grace. With only 1 event at a time our award-winning interior design, is all yours, from balcony to lounge to Romanesque plaza. you will dine in the glow of alabaster lighting & soft golden marble, the warmth of Mahogany wood, & the paradox of wrought-iron railings fired so fine that they evoke gold-spun embroidery. Imagine a romantic cocktail hour on balconies and the vistas of candlelit tables 28 feet below, all set to the sounds of a baby grand piano at the top of the stairs. Add to this setting lush drapery, a spacious European-style lounge, and the world’s best culinary traditions. For parties between 50 and 300 people,"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9202,
        total_orders: 858.83,
        neighborhood: 'Astoria/LIC',
        keywords: 'Karaoke, Caribbean, Jamaican, Korean, Gourmet, Thai, Irish, Perfect, Casual',
        annual_dol: 877.06,
        description:
            'Karaoke joint with a full bar, private rooms & song selections in English, Spanish, Korean & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7039,
        store_longitude: -73.9341,
        total_orders: 707.45,
        neighborhood: 'East Williamsburg',
        keywords: 'Sushi, Wine, Dessert, Beer, Spicy, Japanese, Asian, Relaxed, Vibrant, Hip',
        annual_dol: 936.57,
        description:
            'Warm, relaxed restaurant with exposed-brick walls & a patio serving Japanese classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7711,
        store_longitude: -73.9637,
        total_orders: 1906.83,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotdog, Burger, Romantic, Perfect, American, Great, Wonderful, Classy, Iconic, Fine',
        annual_dol: 7184.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.998,
        total_orders: 46889.84,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Wine, Tasty, Chinese, Dessert, Stylish, Spicy, Beer, Trendy, Funky, Elegant',
        annual_dol: 48935.24,
        description:
            'Two-story spot in a former opera house, offering contemporary Chinese dishes & stylish touches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7766,
        store_longitude: -73.9505,
        total_orders: 228.37,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tasty, Salad, Italian, Wine, Coffee, Delicious, Cozy, Inviting, Romantic, Classy',
        annual_dol: 243.55,
        description:
            'Cozy, white-tablecloth restaurant offering Italian dishes with homemade pasta & a sizable wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7453,
        store_longitude: -73.8611,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Burger, American, Modern, Authentic, Irish, Classic, Friendly, Hotel, Local, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5542,
        store_longitude: -74.2177,
        total_orders: 319.73,
        neighborhood: 'Rossville',
        keywords: 'Burger, Nachos, Irish, Ale, American, Awesome, Fresh, Authentic, Classic, Great',
        annual_dol: 333.59,
        description: 'Old West-style saloon with tap brews & cover-free live music on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6526,
        store_longitude: -73.9759,
        total_orders: 2755.13,
        neighborhood: 'Windsor Terrace',
        keywords: 'Beer, Fun, Coffee, Awesome, Comfortable, Great, Cool, Excellent, Urban, Local',
        annual_dol: 2813.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.8596,
        total_orders: 529.59,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Burger, Salad, Dessert, Wine, Organic, Stylish, Sustainable, Trendy, Cozy, Homemade',
        annual_dol: 585.22,
        description:
            'A stylish diner serving classic American eats for brunch, dinner & late night, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8052,
        store_longitude: -73.9663,
        total_orders: 878.02,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Burger, Vegan, Tacos, Mexican, Beer, Salad, Margarita, Bbq, American, Tex-Mex',
        annual_dol: 975.12,
        description:
            'Bar grub & frozen margaritas served in a casual, multilevel space with a rooftop bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7928,
        store_longitude: -73.9717,
        total_orders: 4.98,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Wine, Coffee, Chinese, Mediterranean, Thai, Asian, Premium, Friendly, New Zealand',
        annual_dol: 20.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6913,
        store_longitude: -73.9096,
        total_orders: 504.69,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Hamburger, Beer, Cajun, Salad, Ale, Margarita, Funky, Spicy, Dominican',
        annual_dol: 1075.71,
        description:
            'Casual eats like burgers & wings, plus cocktails, beer, TV sports & happy hour in a funky bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7157,
        store_longitude: -74.01,
        total_orders: 41.44,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Tacos, Pizza, Tequila, Mexican, Salad, Margarita, Mimosa, Wine, Italian, Delicious',
        annual_dol: 169.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7411,
        store_longitude: -73.9929,
        total_orders: 11426.86,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Salad, Tasty, Wine, Meatball, Spicy, Thai, Cozy, Polished, Latin, Modern',
        annual_dol: 12637.62,
        description:
            'A two-floor cocktail lounge appointed with Indian-inspired decor is a popular special-event space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6257,
        store_longitude: -74.1552,
        total_orders: 709.09,
        neighborhood: 'Mariners Harbor/Mid Island',
        keywords:
            'Burger, Mexican, Beer, Sushi, Shrimp Cocktail, Pizza, Colombian, Wine, Peruvian, Delicious',
        annual_dol: 868.61,
        description:
            'Relaxed restaurant & bar offering traditional Colombian fare & baked goods from morning to night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7894,
        store_longitude: -73.7298,
        total_orders: 623.78,
        neighborhood: '',
        keywords:
            'Pizza, Wine, Trendy, Mexican, Salad, Coffee, Gourmet, Spanish, Fabulous, Mediterranean',
        annual_dol: 637.02,
        description:
            "Spot for imaginative Eclectic meals & tapas plates, many with foie gras from the chef's own farm.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.98,
        total_orders: 225.29,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Steak, Gluten Free, Delicious, Wine, Brazilian, Fried Chicken, Latin, Traditional, Authentic, Great',
        annual_dol: 235.06,
        description:
            'A white-table cloth spot in Little Brazil serving traditional Brazilian cuisine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9933,
        total_orders: 1027.64,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Shrimp Cocktail, Dessert, Gluten Free, Wine, French Onion Soup, Chinese, French, Creamy, Romantic',
        annual_dol: 1104.27,
        description:
            'French staples pair with piano music at this opulent restaurant, a tribute to Josephine Baker.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.78,
        store_longitude: -73.95,
        total_orders: 167.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Mexican, Margarita, Wine, Fajitas, Delicious, Casual, Authentic, Great',
        annual_dol: 215.95,
        description:
            'This family-owned outfit with a festive atmosphere serves familiar Mexican food & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6427,
        store_longitude: -73.6399,
        total_orders: 708.53,
        neighborhood: '',
        keywords:
            'Steak, Wine, Salad, French, French Onion Soup, Beer, Italian, Polished, Comfortable, Friendly',
        annual_dol: 784.05,
        description:
            'Bustling brasserie with patio dining known for classic French cooking, from mussels to steak frites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7486,
        store_longitude: -73.9873,
        total_orders: 736.12,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Lobster Tail, Lobster, Tasty, Fun, Vivid, Sweet, Impressive, Mac And Cheese, Great, American',
        annual_dol: 768.04,
        description:
            '"Hoops Cabaret & Sports Bar is NYC\'s sexiest topless sports bar located in midtown Manhattan, 1 block from MSG and Penn Station. Hoops is a sexy adult cabaret with a fun sports bar feel, featuring an impressive décor and menu with items like fried lobster tails, short-rib sliders, lemon pepper wings and some of the best mac and cheese bites in NYC."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9918,
        total_orders: 547.45,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Mexican, Coffee, Wine, South American, Dominican, Warm, Spanish, Bbq, American, Latin',
        annual_dol: 624.46,
        description:
            'Latin-American restaurant, lounge & hookah bar popular for its per-pound lunch buffet & DJ nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9874,
        total_orders: 36.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Burger, Pizza, Sushi, Hamburger, Salad, Wine, Sashimi, Hotel, American, New Zealand',
        annual_dol: 149.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7461,
        store_longitude: -73.9939,
        total_orders: 149.56,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Mexican, Tacos, Salad, Burritos, Local, Healthy, Casual, Creole, Cool',
        annual_dol: 733.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7254,
        store_longitude: -73.9977,
        total_orders: 2357.38,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Mexican, Steak, Margarita, Wine, Delicious, Tex-Mex, Seafood Paella, Enchiladas, Fajitas, American',
        annual_dol: 2357.38,
        description:
            'Mexican restaurant featuring happy hours, salsa bands & free salsa dance classes, plus a dance club.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7781,
        store_longitude: -73.9787,
        total_orders: 3633.31,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Pizza, Salad, Tasty, Italian, Gourmet, Dessert, Mexican, Vodka, Coffee, Ale',
        annual_dol: 4562.35,
        description:
            'Neighborhood standby offering classic Italian meals & weekend brunch in a cozy, casual atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5893,
        store_longitude: -73.7291,
        total_orders: 206.61,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Chicken Parmigiana, Delicious, Modern, Fun, Fresh, Casual, Traditional',
        annual_dol: 488.23,
        description:
            '"Italian Restaurant and Family-style options. Chef Giovanni of Matteo’s Bellmore and Matteo’s Howard Beach uses a more modern approach to his classics at Gio’s."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.71,
        store_longitude: -74.0166,
        total_orders: 2821.19,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Beer, Tacos, Wine, American, Warm, Delicious, Spacious, Juicy, Hip, Perfect',
        annual_dol: 2911.86,
        description:
            'Burgers, tacos & craft beer served in a hip tavern with a patio, pinball machines & board games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6776,
        store_longitude: -73.998,
        total_orders: 202.87,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Lasagna, Italian, Meatball, Wine, Delicious, Warm, American, Sweet, Ample, Awesome',
        annual_dol: 276.18,
        description:
            'Popular neighborhood Italian with Tuscan cooking served in a tiled room or out in the ample garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9882,
        total_orders: 2587.14,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Japanese, Salad, Martini, Beer, Whiskey, American, Local, Casual, Hip, Cool',
        annual_dol: 8360.9,
        description:
            'Underground sake bar offering a large list of varietals along with Japanese snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.9345,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Delicious, Bbq, French, Fresh, Great, Dutch',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8619,
        store_longitude: -73.9118,
        total_orders: 1399.46,
        neighborhood: 'Fordham Manor/University Heights/West Bronx',
        keywords:
            'Burger, Steak, Cornbread, Salad, Shrimp Cocktail, Bbq, Margarita, Spicy, Zesty, Pinacolada',
        annual_dol: 1429.16,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9665,
        total_orders: 508.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Salad, Tasty, Meatball, Delicious, Zesty, Homemade, Fresh',
        annual_dol: 520.73,
        description:
            'Family-friendly pizzeria serving thin-crust pies alongside calzones, pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7064,
        store_longitude: -74.0138,
        total_orders: 212.76,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Roast Beef, Beer, Nachos, Bbq, Irish, Philly Cheesesteak',
        annual_dol: 231.54,
        description:
            'Irish watering hole offering happy-hour scene & simple eats liked corned beef from a steam table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6876,
        store_longitude: -73.9958,
        total_orders: 1625.57,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Pizza, Spanish, Wine, American, Stylish, Latin, Polished, Casual, Great, Excellent',
        annual_dol: 3789.61,
        description:
            'Spanish tapas with Moorish & Jewish influences draw crowds to the stylish storefront restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6907,
        store_longitude: -73.9581,
        total_orders: 135.31,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Steak, Burger, Vodka, Flavorful, Dessert, Cosmopolitan, Mimosa, Gin, French Toast, Delicious',
        annual_dol: 553.08,
        description:
            'Cozy eatery dispensing American food & booze in a narrow, brick-walled space with retro touches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.738,
        store_longitude: -73.984,
        total_orders: 326.34,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Beer, Burger, Ale, Wine, German, Nachos, Delicious, Warm, Irish, Cozy',
        annual_dol: 420.83,
        description:
            'Cozy Irish joint and sports bar serving pub grub & beer in cozy, brick-walled surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5129,
        store_longitude: -74.2508,
        total_orders: 2695.34,
        neighborhood: 'Tottenville',
        keywords:
            'Steak, Wine, Champagne, Cinnamon Roll, Italian, Pizza, Coffee, Beer, Hotel, Elegant',
        annual_dol: 2833.6,
        description:
            'Tri-level mansion by the water offering an elegant setting for Italian wining & dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9882,
        total_orders: 410.31,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Wine, Coffee, Urban, Modern, Contemporary, Rooftop, Dutch, Great',
        annual_dol: 1440.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8037,
        store_longitude: -73.953,
        total_orders: 9.56,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Burger, Vegan, Salad, Coffee, Wine, Beer, Chinese, French, African, Hotel',
        annual_dol: 39.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7252,
        store_longitude: -74.0037,
        total_orders: 1787.5,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Korean, Spicy, Trendy, Delicious, Contemporary, Bbq, Modern, Fun, Traditional, Perfect',
        annual_dol: 1976.5,
        description:
            'Modern Korean eatery specializing in barbecue & mains with exposed brick walls & beamed ceilings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6571,
        store_longitude: -73.96,
        total_orders: 322.29,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Burger, Steak, Salad, Italian, Mimosa, Beer, French Toast, Avocado Toast, French, Delicious',
        annual_dol: 526.11,
        description:
            'Comfortable, neighborhood outpost featuring espresso drinks & American dishes, plus patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7766,
        store_longitude: -73.9772,
        total_orders: 3580.96,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Hamburger, Salad, Whiskey, Beer, Wine, Savory, American, Swiss, Delicious',
        annual_dol: 3979.46,
        description:
            'American cooking, a raw bar & creative cocktails offered in polished surrounds with retro touches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7509,
        store_longitude: -74.0043,
        total_orders: 46.23,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Italian, Upscale, Champagne, Wine, Trendy, Mimosa, American, Bellini, Hotel',
        annual_dol: 119.34,
        description:
            'Clubby music & a popular all-you-can-drink weekend brunch lend this Italian steakhouse a party vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -74.0025,
        total_orders: 630.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Gourmet, Hamburger, Spicy, Warm, Hotel, American, Bbq, Inviting, Contemporary',
        annual_dol: 1145.63,
        description:
            'Upscale, contemporary bistro with striking interiors, furnishing gourmet dinners, cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6215,
        store_longitude: -73.9052,
        total_orders: 31.78,
        neighborhood: 'Bergen Beach',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Chicken Parmigiana, Wine, Vodka, Italian, Sleek, American, Outstanding',
        annual_dol: 129.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7561,
        store_longitude: -73.9904,
        total_orders: 140.7,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Gluten Free, Coffee, Wine, Japanese, Martini, Zen, Upscale, Ramen',
        annual_dol: 575.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5732,
        store_longitude: -74.1152,
        total_orders: 0,
        neighborhood: 'New Dorp',
        keywords: 'Southern, Contemporary, Casual, Friendly, Creative',
        annual_dol: 0,
        description:
            '"As you walk along your path, you might find yourself at a crossroads in life. This is a path not meant to be walked alone. Crossroads Church is here for you in every step to connect with Jesus Christ in a meaningful saving relationship. We invite you to become a part of both God’s and our Crossroads Church family. Our plan is to teach you how to be spiritually strong so that you may be equipped to live out the plans & purposes that God has created you for. We do this by being a church that reflects the methods, models, and missions of Jesus Christ, as shown in God’s Word, the Bible. Crossroads is a church that creatively expresses the good news that is the Gospel, and provides a genuine & transparent environment of unity and community."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6566,
        store_longitude: -73.6454,
        total_orders: 359.45,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Gluten Free, Italian, Wine, Delicious, Creamy, Comfortable, Classy, Ample',
        annual_dol: 400.22,
        description:
            'Italian specialties & wood-fired pizza at a restaurant with a Tuscan vibe & sidewalk dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6573,
        store_longitude: -73.6459,
        total_orders: 458.28,
        neighborhood: '',
        keywords:
            'Steak, Wine, Salad, French, Italian, Gluten Free, Moroccan, American, Polished, Casual',
        annual_dol: 502.96,
        description:
            'French bistro with small plates & a large wine list served among red velvet & Moroccan tiles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6631,
        store_longitude: -73.7182,
        total_orders: 1020.34,
        neighborhood: '',
        keywords:
            'Lobster, Italian, Beer, Chicken Wings, American, Casual, Friendly, Local, Lively, Classic',
        annual_dol: 1262.48,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.836,
        store_longitude: -73.7064,
        total_orders: 198.82,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Pizza, Italian, American, Modern, Recommended, Global, Friendly, Tradition',
        annual_dol: 243.35,
        description:
            'Festive locale with New American eats & cocktails, plus a dockside tiki bar, music & marina views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7048,
        store_longitude: -74.0089,
        total_orders: 1422.58,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Roast Turkey, Cajun, Turkey Sandwich, Swiss, Whiskey, Irish, Homemade, Reuben, Russian',
        annual_dol: 1527.76,
        description:
            'Low-key Irish tavern serving pints & a full menu of pub grub to Financial District types.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5691,
        store_longitude: -74.1108,
        total_orders: 31.16,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords: 'Pizza, Wine, Beer, Meatball, Homemade, Ramen, Fun, Natural, Great, Fresh',
        annual_dol: 127.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7355,
        store_longitude: -73.9743,
        total_orders: 206.39,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Latin, Hotel, Great',
        annual_dol: 266.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.8515,
        total_orders: 35.47,
        neighborhood: 'Flushing/Corona',
        keywords: 'Superb, Amazing, Perfect, Great, Wonderful, Iconic, Dynamic',
        annual_dol: 144.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7935,
        store_longitude: -73.6938,
        total_orders: 1412.8,
        neighborhood: '',
        keywords: 'Burger, Steak, Vegetarian, Beer, Whiskey, Cajun, Wine, Irish, Relaxed, American',
        annual_dol: 1442.78,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7188,
        store_longitude: -73.8137,
        total_orders: 1276.59,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Wine, Ale, Sweet, Friendly, Fun, Great, Casual',
        annual_dol: 1303.68,
        description:
            'Relaxed, no-frills outpost offering plenty of TVs & bar grub alongside beers, cocktails & shots.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.9911,
        total_orders: 1912.19,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Burger, Mexican, Whiskey, Coffee, Wine, Tasty, Bourbon, Delicious, Nachos',
        annual_dol: 2043.9,
        description:
            '90 draft & 100 bottled beers poured in a sprawling, bi-level space with TVs & a roof deck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8307,
        store_longitude: -73.6885,
        total_orders: 1490.04,
        neighborhood: '',
        keywords: 'Burger, Steak, Lobster, Pizza, Cheesesteak, Tequila, Beer, Wine, Bbq, American',
        annual_dol: 1594.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9936,
        total_orders: 1303.98,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Wine, Pizza, Italian, Coffee, Hotel, Beer, Warm, Urban, Sustainable, Sleek',
        annual_dol: 3360.16,
        description:
            'The understated rooms provide Wi-Fi, flat-screen TVs and minifridges; some feature rainfall showers, interconnecting bedrooms, city views, and/or furnished balconies or terraces. Suites have living areas.There are loaner bikes (seasonal). Other amenities consist of a sleek restaurant/bar, a cafe, lounges, and a rooftop bar with city views. Breakfast is available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -73.9972,
        total_orders: 2707.96,
        neighborhood: 'Little Italy',
        keywords:
            'Salad, Italian, Dessert, Chicken Parmigiana, Bellini, Delicious, Rich, Godfather, Homemade, Sweet',
        annual_dol: 2825.37,
        description:
            'Classic red-sauce Italian fare can be ordered in large family-style servings at this casual eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.9024,
        total_orders: 112.79,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Steak, Upscale, Delicious, Exotic, Rooftop, Fine, Casual',
        annual_dol: 153.54,
        description:
            '"Gentlemens Club minutes from Laquardia Airport featuring beautiful entertainers, Full bar, full kitchen serving lunch Mon to Sat 12pm, we feature private VIP rooms, bottle service."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6574,
        store_longitude: -73.8392,
        total_orders: 227.58,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Italian, Coffee, Comfortable, Perfect, Premium, Sweet, Friendly, Outstanding, Amazing, Great',
        annual_dol: 237.44,
        description:
            '"The perfect venue to host your private celebration! From Weddings to Anniversaries, Sweet 16\'s and Retirement parties, and even corporate catering events, the experts at Roma View will make it special."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7413,
        store_longitude: -73.597,
        total_orders: 2444.16,
        neighborhood: '',
        keywords:
            'Steak, Burger, Pizza, Shrimp Cocktail, Wine, Italian, Spicy, Trendy, American, Delicious',
        annual_dol: 2524.14,
        description:
            'New American eatery serving brick-oven pizzas, steaks & seafood, plus a wine cellar with 500 labels.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9941,
        total_orders: 244.47,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Cheesecake, Pizza, Italian, Upscale, Stylish, Delicious, Wine, Trendy, Warm, Martini',
        annual_dol: 255.07,
        description:
            'Pizza, pasta & happy hour specials in a chic dining room with a marble bar & antique decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -73.9993,
        total_orders: 751.24,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Lobster, Lobster Roll, Martini, Beer, Coffee, Savory, Delicious, Upscale, Gin, Rum',
        annual_dol: 2637.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7231,
        store_longitude: -74.003,
        total_orders: 2574.67,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Burger, Salad, Beer, Nachos, Dessert, Wine, Homemade, American, Cozy, Friendly',
        annual_dol: 2629.3,
        description:
            'Longtime local bar (since 1972) with draft beers, pub grub & an unpretentious, friendly vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7793,
        store_longitude: -73.9547,
        total_orders: 777.93,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Beer, Pizza, Wine, Whiskey, Hotel, Fruity, Cointreau, Casual, Great',
        annual_dol: 2967.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7187,
        store_longitude: -74.0012,
        total_orders: 831.87,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Vegetarian, Coffee, Chinese, Organic, Upscale, Delicious, Asian, Contemporary, Modern, Casual',
        annual_dol: 972.48,
        description:
            'Cantonese-focused Chinese fare offered in a contemporary space with decorative wood screens.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -74.0001,
        total_orders: 2582.98,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Pizza, Wine, Salad, French Toast, Dessert, Hotel, Beer, Trendy, French Onion Soup, French',
        annual_dol: 2896.6,
        description:
            'Featuring floor-to-ceiling windows with city views, the sophisticated rooms provide minibars, free Wi-Fi and flat-screen TVs, as well as high-thread-count sheets, marble bathrooms and designer toiletries. Upgraded rooms add sitting areas, while suites provide living rooms.Amenities include a haute, terrarium-style restaurant with a bar, as well as a cocktail bar and a 24/7 fitness center. Valet parking is available for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7924,
        store_longitude: -73.9462,
        total_orders: 276.64,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Tasty, Delicious, Wine, Upscale, Cozy, Exquisite, Fun, Awesome, Friendly, Playful',
        annual_dol: 811.8,
        description:
            '"Perch is a 25 seat small plate shared dining experience with signature cocktails & a curated wine list. We are located in East Harlem NYC."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7165,
        store_longitude: -74.0079,
        total_orders: 287.92,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Mexican, Vodka, Margarita, Enchiladas, Spicy, Upscale, Contemporary, Casual, Local, Fun',
        annual_dol: 325.38,
        description:
            'Elevated Mexican cuisine in a festive dining room, plus tequila-based cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9948,
        total_orders: 351.46,
        neighborhood: 'Little Italy',
        keywords: 'Tequila, Whiskey, Vodka, Champagne, Gin, Rum, Spanish, Spicy, Hotel, American',
        annual_dol: 538.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7779,
        store_longitude: -73.9562,
        total_orders: 274.71,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Italian, Pizza, Shrimp Cocktail, Wine, Upscale, Friendly, Authentic, Welcoming, Perfect',
        annual_dol: 297.18,
        description:
            'Welcoming stalwart for fine Italian fare with paneled walls, white tablecloths & a regular crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7784,
        store_longitude: -73.9625,
        total_orders: 2915.93,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Tequila, Tequila Sunrise, Pizza, Delicious, Italian, Premium, American, Outstanding, Memorable, Great',
        annual_dol: 2996.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7571,
        store_longitude: -73.6424,
        total_orders: 646.37,
        neighborhood: '',
        keywords:
            'Burger, Steak, Shrimp Cocktail, Tasty, Bbq, Organic, American, Premium, Casual, Great',
        annual_dol: 2269.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6534,
        store_longitude: -73.8382,
        total_orders: 1794.6,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Wine, Italian, Glamorous, Elegant, Gourmet, Exquisite, Innovative, Fun, Sweet, Great',
        annual_dol: 2513.55,
        description:
            '"Tradition Transformed With a modern perspective Since we first opened our doors in 1987, Russo’s On The Bay has continually set new standards for innovative cuisine, personalized service and uncompromising attention to detail, all delivered in a stunning waterside facility with panoramic views of the bay. Distinguished by its luxe contemporary décor, world class amenities and infinitely-adaptable spaces for parties of all sizes, Russo’s is a one-of-a-kind home for once-in-a-lifetime events."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.609,
        store_longitude: -73.9327,
        total_orders: 9.49,
        neighborhood: 'Marine Park',
        keywords: 'American, Global, Local, Foreign',
        annual_dol: 38.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -73.5666,
        total_orders: 2166.98,
        neighborhood: '',
        keywords: 'Wine, Coffee, Dessert, Delicious, Classic, Great, Local',
        annual_dol: 5363.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6911,
        store_longitude: -73.8212,
        total_orders: 234.07,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords: 'Italian, Wine, Zen, Fusion, Sweet, Fried Rice, Caribbean, Great, Casual',
        annual_dol: 956.78,
        description:
            '"Follow us for all updates! IG:@ZestLoungeNY @ZestLoungeNYC TikTok: @ZestLoungeNY"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.985,
        total_orders: 852.88,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Tacos, Burger, Mexican, Beer, Vegetarian, Salad, American, Mediterranean, Hotel, Irish',
        annual_dol: 3423.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7884,
        store_longitude: -73.6401,
        total_orders: 462.65,
        neighborhood: '',
        keywords:
            'Salad, Chicken Parmigiana, Pizza, Italian, Warm, Clean, Homey, Romantic, Smooth, Caesar Salad',
        annual_dol: 482.71,
        description:
            'Long-running neighborhood go-to for red-sauce Italian fare served in a formal yet homey space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7035,
        store_longitude: -74.011,
        total_orders: 2367.11,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Tasty, Coffee, Gin, Warm, Irish, Comfortable, American, Seasonal, Amazing',
        annual_dol: 3784.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -74.0127,
        total_orders: 45484.08,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Tacos, Steak, Mexican, Tequila, Nachos, Beer, Margarita, Salad, Burritos, Trendy',
        annual_dol: 53705.06,
        description:
            'Bustling haunt for modern Mexican fare & cocktails in rustic-chic, art-decorated surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -74.008,
        total_orders: 1375.89,
        neighborhood: 'Hudson Square',
        keywords:
            'Hotel, Trendy, Phenomenal, Rooftop, Scandinavian, Picturesque, Australian, Classic, Exceptional, Great',
        annual_dol: 1467.35,
        description:
            'Contemporary rooms with a Scandinavian vibe offer complimentary Wi-Fi and flat-screens, plus Bluetooth speakers and USB charging stations. All have minifridges, desks and rainfall showers; some feature bunk beds and/or city views. Upgraded rooms add furnished terraces.Loaner bikes are offered. Amenities include a hip New American restaurant and 2 bars (1 set on the roof), plus a 24-hour market, a courtyard and co-working space. Parking and meeting facilities are available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7935,
        store_longitude: -73.941,
        total_orders: 403.75,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Wine, Gourmet, Gin, Delicious, Spanish, Quaint, Gracious, Premium, Comfortable, French',
        annual_dol: 549.63,
        description:
            '"Tastings’ mission is to cultivate special relationships, creating experiences to suit distinct needs and personality while providing hospitality, gracious service and exceptional food in a lovely setting. Tastings NYC curates a bespoke experience and works within your budget no matter what occasion, style and number of guests. Our dedicated multicultural tastemakers, culinary and service team translate their passion for food, wine, cocktails and entertaining to turn your vision into reality."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7818,
        store_longitude: -73.834,
        total_orders: 2088.78,
        neighborhood: 'College Point/Flushing',
        keywords:
            'Cajun, Wine, Salad, Beer, New-York-Style, Chicken Wings, French, Spicy, American, Latin',
        annual_dol: 2133.1,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.9573,
        total_orders: 1489.63,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Lobster Roll, Lobster, Burger, Cheesecake, Cheesesteak, Steak, Salad, Hamburger, Dessert, New-York-Style',
        annual_dol: 1527.62,
        description: 'Popular neighborhood joint serving pub grub & pints to a lively crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8152,
        store_longitude: -73.8166,
        total_orders: 21323.06,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Steak, Italian, Wine, Beer, Heritage, Signature, Great',
        annual_dol: 81054.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.924,
        total_orders: 601.73,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Beer, Ale, Wine, Irish, Casual, Quaint, Local, Spacious, Chill, Cool',
        annual_dol: 627.82,
        description:
            'This unpretentious watering hole with sports on TV & many craft beers also has a covered patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9828,
        total_orders: 17387.92,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Coffee, Clean, Authentic, Chic, Modern, Friendly, Great, Contemporary',
        annual_dol: 19803.65,
        description:
            'Modern Art.Airy rooms offer Wi-Fi, minibars, Nespresso machines, flat-screen TVs and Bluetooth speakers. Upgraded rooms add sitting areas and city views. Contemporary suites sleep up to four and have extra living space; an upgraded suite also has a balcony.A breakfast buffet is available. A chic terrace lounge with park views serves a menu of tapas and cocktails. There are also meeting rooms, a fitness center, a sauna, and a lobby lounge with a fireplace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6559,
        store_longitude: -74.0074,
        total_orders: 764.41,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Burger, Hamburger, Pizza, Beer, American, Wine, Trendy, Hotel, Casual, Local',
        annual_dol: 799.07,
        description:
            'At the Industry City food hall, this stand provides classic burgers & fries, plus milkshakes & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7165,
        store_longitude: -74.0095,
        total_orders: 4035.22,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Tequila, Lemon Meringue Pie, Sushi, Wine, Dessert, Applejack, Mimosa, Italian, Rum, Trendy',
        annual_dol: 4605.07,
        description:
            'Bold farm-to-table cuisine in candlelit, rustic-chic environs attracts romance-minded foodies.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7743,
        store_longitude: -73.9481,
        total_orders: 1216.4,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Hotdog, Tacos, Beer, Salad, Vodka, Martini, Irish, Rich, Delicious',
        annual_dol: 1376.37,
        description:
            '"The Five Lamps is a contemporary take on a classic Irish pub. We aim to provide an inviting and comfortable atmosphere where you can enjoy a pint and good conversation!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7774,
        store_longitude: -73.951,
        total_orders: 8125.39,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Mexican, Tequila, Salad, Nachos, Gourmet, Tasty, Upscale, Tex-Mex, Fajitas, Crisp',
        annual_dol: 8431.29,
        description:
            'Locals patronize this longtime neighborhood spot for its classic Mexican dishes & festive feeling.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8669,
        store_longitude: -73.6306,
        total_orders: 1083.77,
        neighborhood: '',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Seafood Paella, Vegetarian, Wine, Meatball, Italian, Beer, Mediterranean',
        annual_dol: 1168.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6911,
        store_longitude: -73.8215,
        total_orders: 483.48,
        neighborhood: 'South Richmond Hill',
        keywords:
            'Chinese, Spicy, Asian, Caribbean, Elegant, Fried Rice, Friendly, American, Brazilian, Casual',
        annual_dol: 515.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.9202,
        total_orders: 148.35,
        neighborhood: 'Astoria/Sunnyside',
        keywords:
            'Pizza, Italian, Delicious, Spicy, Creamy, Spectacular, Bruschetta, Sensational, Friendly, Vintage',
        annual_dol: 260.08,
        description:
            'Neighborhood Italian spot for wood-fired pies by a pizzaiolo from Naples, plus panini & antipasti.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.829,
        store_longitude: -73.8244,
        total_orders: 239.84,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Burger, Salad, Beer, Delicious, Savory, Nachos, Legendary, Swiss, French Onion Soup, French',
        annual_dol: 346.71,
        description: '"Steaks-Beers-Sports"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.702,
        store_longitude: -74.0107,
        total_orders: 3026.51,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Italian, Hotel, Old Fashioned, Magical, Organic, Eclectic, Modern, Classic, Sophisticated, Amazing',
        annual_dol: 8717.28,
        description:
            "National Monument and 10 miles from LaGuardia Airport.Chic rooms with mahogany furnishings have Wi-Fi, flat-screens and minibars, plus coffeemakers, and patios or river views. Suites add sitting areas; upgraded suites provide living rooms. Room service is available 24/7.Amenities consist of a swanky spa, a gym and an organic cafe. Guests have access to a private members club with a restaurant and a bar. There's also breakfast and valet parking.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7177,
        store_longitude: -73.857,
        total_orders: 374.3,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Salad, Roast Beef, Wine, German, Beer, Hungarian, Bbq, French, Delicious',
        annual_dol: 458.12,
        description:
            'Beerhouse pairing steins of imported suds with classic German fare, live bands & a roomy space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7795,
        store_longitude: -73.96,
        total_orders: 174.17,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Wine, Italian, Cozy, Warm, Inviting, Organic, Perfect, Lively, Authentic, Great',
        annual_dol: 711.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7051,
        store_longitude: -73.9247,
        total_orders: 637.43,
        neighborhood: 'Bushwick',
        keywords: 'Coffee, Wine, Beer, Delicious, Warm, Cozy, Fun, Wonderful, Perfect, Great',
        annual_dol: 1642.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.998,
        total_orders: 23982.22,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Wine, Stylish, Trendy, Delicious, Chinese, Quaint, Organic, Classy, Amazing, Great',
        annual_dol: 25050.9,
        description:
            'Mixologists in pharmacist-style garb whip up complex cocktails at this off-the-beaten path hideaway.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6367,
        store_longitude: -73.7025,
        total_orders: 1860.13,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Wine, Gourmet, Martini, Meatball, Fried Calamari, Spicy, Beer',
        annual_dol: 1899.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.6129,
        total_orders: 572.92,
        neighborhood: '',
        keywords:
            'Lasagna, Pizza, Italian, Meatball, Wine, Shrimp Scampi, Homemade, Greek, Casual, Scottish',
        annual_dol: 631.49,
        description:
            'Chic trattoria with an old-country Italian vibe presenting a huge menu of Italian favorites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6549,
        store_longitude: -73.6352,
        total_orders: 4486.55,
        neighborhood: '',
        keywords:
            'Cheesecake, Vegetarian, Salad, Wine, Dessert, Coffee, Scotch, Organic, Gin, French',
        annual_dol: 4649.97,
        description:
            'Relaxed venue with a contemporary vibe prepping comfort food like meatloaf, plus chicken & waffles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6572,
        store_longitude: -73.6446,
        total_orders: 1141.17,
        neighborhood: '',
        keywords: 'Pizza, Burger, Italian, Salad, Beer, Martini, Wine, Exceptional, Fresh, Casual',
        annual_dol: 1478.49,
        description:
            'Local-favorite spot serving thin-crust pizzas, calzones & generously portioned pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6141,
        store_longitude: -73.9272,
        total_orders: 7.51,
        neighborhood: 'Flatlands',
        keywords:
            'Steak, Roast Turkey, Shrimp Cocktail, Bbq, American, Italian, Caesar Wrap, Floridian, Filet Mignon, Casual',
        annual_dol: 30.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7948,
        store_longitude: -73.6834,
        total_orders: 487.8,
        neighborhood: '',
        keywords: 'Steak, Tacos, Salad, Wine, Dessert, Delicious, Savory, Italian, Organic, Warm',
        annual_dol: 501.16,
        description:
            'Refined spot open since 1969 featuring French cuisine & old-world ambience, plus a separate lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6392,
        store_longitude: -73.6605,
        total_orders: 1510.05,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Tacos, Beer, Delicious, French, Radiant, Fun, Clam Chowder, Bright',
        annual_dol: 1898.05,
        description:
            'Eatery & bar featuring lobster rolls, tacos & cocktails, plus live music & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.719,
        total_orders: 86.95,
        neighborhood: 'Jamaica/Bellerose Manor',
        keywords:
            'Steak, Cheesesteak, Pizza, Tasty, American, Philly Cheesesteak, French, Irish, Homemade, Reuben Sandwich',
        annual_dol: 152.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.5921,
        total_orders: 39.98,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Salad, Burritos, Warm, Healthy, Local, Premium, Casual, High-Protein',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6635,
        store_longitude: -73.7191,
        total_orders: 4041.86,
        neighborhood: '',
        keywords:
            'Burger, Tasty, Whiskey, Shrimp Cocktail, Beer, Margarita, Bourbon, Italian, Delicious, Legendary',
        annual_dol: 4127.63,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6554,
        store_longitude: -73.6459,
        total_orders: 192.31,
        neighborhood: '',
        keywords: 'Wine, Bourbon, Beer, Martini, Gin, Premium, Ample, Comfortable, Great, Intimate',
        annual_dol: 786.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8231,
        store_longitude: -73.8366,
        total_orders: 716.76,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Steak, Wine, Dessert, American, Delicious, Classic, Casual, Lively, Local, Great',
        annual_dol: 858.94,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6083,
        store_longitude: -73.9598,
        total_orders: 79.95,
        neighborhood: 'Homecrest',
        keywords: 'Mexican, Tacos, Burritos, Delicious, Organic, Local, Casual, Signature',
        annual_dol: 418.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7044,
        store_longitude: -74.01,
        total_orders: 13078.57,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Beer, Burger, Whiskey, Irish, Ale, French Toast, Smoky, American, French',
        annual_dol: 14735.98,
        description:
            'Relaxed tavern for beers & sandwiches, plus live bands, DJs & tables on the cobblestoned sidewalk.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7039,
        store_longitude: -74.0123,
        total_orders: 506.35,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tequila, Beer, Fun, Golfclub, Awesome, Perfect, Clean, Great, Dope, Urban',
        annual_dol: 678.61,
        description:
            '"With 9 indoor golf simulators, Five Iron Golf - FiDi is the perfect space to get professional golf lessons, to host your next corporate event, or to just hang out! Whether you\'re here for practice, play, or fun FiDi can provide you with a one of a kind experience including a full bar, pool table, great food, and multiple full screen TV\'s to keep you up to date with everything sports. With a few friends or on your own FiDi is the perfect space to perfect your swing!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -74.0103,
        total_orders: 5316.29,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Tequila, Steak, Margarita, Quesadillas, Dessert, Upscale, Casual, Homemade, Traditional',
        annual_dol: 5811.15,
        description:
            'Stone Street spot with a festive after-work scene, serving Mexican standards & margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7463,
        store_longitude: -73.8951,
        total_orders: 372.99,
        neighborhood: 'Jackson Heights/Woodside',
        keywords:
            'Tequila, Mexican, Pizza, Beer, Spanish, Australian, Russian, Tibetan, Dolma, Fusion',
        annual_dol: 961.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7153,
        store_longitude: -73.833,
        total_orders: 2782.84,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Steak, Tacos, Meatloaf, Cajun, Pizza, Salad, Beer, Vodka, Bbq, Thai',
        annual_dol: 2843.37,
        description:
            'Neighborhood pub/sandwich shop serving beers, burgers, wings & other comfort eats in casual digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8306,
        store_longitude: -73.6935,
        total_orders: 167.71,
        neighborhood: '',
        keywords:
            'Wine, Steak, Gluten Free, Pizza, Beer, Dessert, Delicious, Italian, Warm, Opulent',
        annual_dol: 241.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7194,
        store_longitude: -73.9969,
        total_orders: 602.42,
        neighborhood: 'Little Italy',
        keywords:
            'Tacos, Wine, Spanish, Romantic, Trendy, Gin, Delicious, Mediterranean, Rustic, Classy',
        annual_dol: 971.9,
        description:
            'Informal Spanish storefront restaurant & tapas bar featuring Galician fare with wines & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6916,
        store_longitude: -73.8126,
        total_orders: 1.23,
        neighborhood: 'South Richmond Hill',
        keywords:
            'Burger, Steak, Whiskey, Wine, Chinese, Ale, Fried Rice, Caribbean, Prime Rib, Seasonal',
        annual_dol: 5.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7115,
        store_longitude: -73.5443,
        total_orders: 2941.9,
        neighborhood: '',
        keywords: 'Burger, Steak, Meatball, Salad, Whiskey, Beer, Bourbon, Korean, French, Modern',
        annual_dol: 3471.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8729,
        store_longitude: -73.623,
        total_orders: 23.52,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Chicken Parmigiana, Spicy, Bruschetta, Homemade, Polished, Casual, Local',
        annual_dol: 96.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.716,
        store_longitude: -74.0075,
        total_orders: 213.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Rich, Upscale, Stylish, Elegant, Wine, Hotel, Polish, Premium, Awesome, Lively',
        annual_dol: 807.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -73.9968,
        total_orders: 31.73,
        neighborhood: 'Little Italy',
        keywords:
            'Cheesecake, Hotdog, Salad, Italian, Coffee, Rich, French, Hotel, Quaint, Tiramisu',
        annual_dol: 129.71,
        description:
            'Quaint bakery serving housemade gelato & other Italian treats like cannoli & tiramisu since 1891.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7452,
        store_longitude: -73.9072,
        total_orders: 332.42,
        neighborhood: 'Woodside',
        keywords: 'Burger, Steak, Beer, Dessert, Bbq, Irish, Warm, American, Omelette, Classy',
        annual_dol: 370.69,
        description:
            "Longtime neighborhood hangout dishing up burgers & Irish-leaning pub fare like shepherd's pie.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7775,
        store_longitude: -73.9518,
        total_orders: 382.03,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'German, Salad, Austrian, Beer, Wine, Cozy, Classic, Southern, Authentic, Casual',
        annual_dol: 495.55,
        description:
            'This circa-1936 Yorkville eatery boasts rib-sticking German grub & plenty of beer to wash it down.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8089,
        store_longitude: -73.9306,
        total_orders: 5841.22,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Sustainable, Great, Dessert, Intimate, Local',
        annual_dol: 14320.71,
        description:
            '"Great Performances plans, staffs and caters events for clients of all sorts and celebrations of all sizes. From small, intimate gatherings to large, elaborate productions, we bring professionalism, skill and care to each client project. Our range of capabilities includes full-service, catering, beverage service, service staffing, venue selection, design and special event production. We also operate cafés, restaurants, concessions and amenity programs for nonprofit and corporate institutions."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7749,
        store_longitude: -73.957,
        total_orders: 88.38,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Sushi, Japanese, Delicious, Sashimi, Asian, Comfortable, Fusion, Casual, Local',
        annual_dol: 361.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7811,
        store_longitude: -73.8416,
        total_orders: 68.33,
        neighborhood: 'College Point/Flushing',
        keywords: 'Meatball, Italian, Japanese, French, American, Southern, Fun, Complex, Great',
        annual_dol: 145.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6565,
        store_longitude: -73.6469,
        total_orders: 1997.31,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Vegetarian, Hamburger, Gluten Free, Beer, Organic, Dessert, Sustainable, Delicious',
        annual_dol: 2130.08,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6739,
        store_longitude: -73.6263,
        total_orders: 592.98,
        neighborhood: '',
        keywords: 'Beer, Golfclub, Seasonal, Local, Recommended, Amazing',
        annual_dol: 2459.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5988,
        store_longitude: -73.6552,
        total_orders: 424.51,
        neighborhood: '',
        keywords:
            'Flavorful, Meatball, Beer, Wine, Spanish, Italian, Fun, American, Sangria, Authentic',
        annual_dol: 594.71,
        description:
            '"Flavors of Spain in a festive casual atmosphere specializing in a wide array of tapas, seafood paella, a good selection of fine meats and flavorful sangria to satisfy all appetites."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8896,
        store_longitude: -73.6249,
        total_orders: 412.86,
        neighborhood: '',
        keywords:
            'Burger, Steak, Lobster, Pizza, Salad, Shrimp Cocktail, Flavorful, Italian, Dessert, Classy',
        annual_dol: 465.07,
        description:
            '"Executive Chef Janine Dimenna, a Glen Cove native took the town by storm with her exclusive dishes that could never be found any where else. With many honors such as the Food Critic Award, this critically acclaimed chef raised the bar for excellence. Chef Jeanine Dimenna has one of the highest honors of merit, Food Network which even recognized her talent on the TV strides Kitchen Casino."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7516,
        store_longitude: -73.9857,
        total_orders: 1014.02,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Upscale, Warm, Vivid, Hotel, Awesome, Salad, Amazing, Fun, Fantastic, Premium',
        annual_dol: 1057.98,
        description:
            '"Three floors filled with amazing ambiance, beautiful sexy entertainers, four stages with up close seating, incredible lighting and sound, large screen HD TV\'s on all floors to watch all sporting events, private vip rooms with outdoor terrace, bottle service and much much more"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7031,
        store_longitude: -74.0103,
        total_orders: 29.63,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Tacos, Mexican, Salad, Burritos, Delicious, Local, Casual, Premium, Fresh, Diverse',
        annual_dol: 121.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7188,
        store_longitude: -74.009,
        total_orders: 1208.39,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Korean, Wine, Contemporary, Modern, Innovative, Traditional, Exceptional, Bibimbap, Excellent, Scallops',
        annual_dol: 1601.17,
        description:
            'Innovative, high-end Korean fare served in elegant, contemporary digs with an extensive wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6718,
        store_longitude: -73.8572,
        total_orders: 113.4,
        neighborhood: 'The Hole/Jamaica/Howard Beach',
        keywords:
            'Lobster, Salad, Shrimp Cocktail, Cajun, Lobster Bisque, Tasty, Spicy, Creole, Clam Chowder, Perfect',
        annual_dol: 470.85,
        description:
            '"We\'re proud of our "perfect cook," just how seafood is meant to be enjoyed! Be as saucy as you want with our collection of 6 tasty recipes including our signature Hook & Reel special blend. How spicy do you like it?"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7745,
        store_longitude: -73.9544,
        total_orders: 5.21,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Lasagna, Chicken Parmigiana, Salad, Shrimp Scampi, Italian, Spicy, Homemade, Caesar Salad, Casual, Fresh',
        annual_dol: 21.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8231,
        store_longitude: -73.8366,
        total_orders: 71.02,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Casual, Warm',
        annual_dol: 320.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7005,
        store_longitude: -73.9257,
        total_orders: 314.75,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Vegan, Vegetarian, Zombie, Beer, Tasty, Italian, Margarita, American, Lively',
        annual_dol: 328.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6036,
        store_longitude: -73.6512,
        total_orders: 1228.98,
        neighborhood: '',
        keywords: 'Mexican, Wine, Beer, Italian, Spanish, American, Classy, Modern, Asian, Sangria',
        annual_dol: 1228.98,
        description:
            '"A Modern American restaurant who specializes in local seafood. We are the every day, special occasion, don\'t want to make dinner tonight restaurant!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -74.0082,
        total_orders: 944.64,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Sushi, Upscale, Flavorful, Wine, Beer, Hotel, Sustainable, Rooftop',
        annual_dol: 1191.28,
        description:
            'Prime steaks & seafood are served in a fine dining setting with a hip rooftop atrium.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5967,
        store_longitude: -73.6553,
        total_orders: 2880.04,
        neighborhood: '',
        keywords:
            'Elegant, Romantic, Picturesque, Outstanding, Fun, Great, Exceptional, Timeless, Excellent, Creative',
        annual_dol: 3161.13,
        description:
            '"Bridgeview specializes in celebrating one wedding & event at a time in our elegant waterfront estate. From the owners and managers to our helpful wait staff, you will enjoy the hospitality and personal attention that you deserve. Our creative chefs will delight you with a variety of cuisines."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8679,
        store_longitude: -73.6416,
        total_orders: 75.3,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Meatball, Salad, Mexican, Italian, Juicy, Greek',
        annual_dol: 216.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7206,
        store_longitude: -73.8454,
        total_orders: 1722.31,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Comfortable, Martini, Spacious, Fun, Classy, Cool, Amazing, Great, Excellent',
        annual_dol: 1835.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8101,
        store_longitude: -73.8035,
        total_orders: 73.09,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Burger, Hamburger, Pizza, Salad, Mexican, Beer, Italian, Dessert, Ale, American',
        annual_dol: 74.64,
        description:
            'Chill haunt offering draft beer, burgers, salads & American mains, plus live music & bridge views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8557,
        store_longitude: -73.6422,
        total_orders: 88.89,
        neighborhood: '',
        keywords:
            'Bbq, Italian, Gluten Free, Beer, American, Peruvian, Friendly, Great, Amazing, Casual',
        annual_dol: 290.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.5545,
        total_orders: 555.02,
        neighborhood: '',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Savory, Spicy, Bbq, Bourbon, Gracious, American',
        annual_dol: 1235.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7224,
        store_longitude: -74.0099,
        total_orders: 649.72,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Sushi, Sashimi, Japanese, Sweet, Seasonal, Intimate, Nostalgic, Local, Lively, Rare',
        annual_dol: 707.06,
        description:
            'Tucked under street level, this intimate spot features sushi crafted from fresh-from-Japan fish.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.718,
        store_longitude: -74.0014,
        total_orders: 242.64,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Vegetarian, Wine, Beer, Japanese, American, Friendly, Polished, Casual, Great, Amazing',
        annual_dol: 991.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7992,
        store_longitude: -73.9425,
        total_orders: 57.16,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Casual, American, Hummus',
        annual_dol: 233.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7261,
        store_longitude: -74.0085,
        total_orders: 163.6,
        neighborhood: 'Hudson Square',
        keywords:
            'Pizza, Italian, Salad, Wine, Modern, Fusion, Traditional, Classic, Exceptional, Creative',
        annual_dol: 186.62,
        description:
            'Tuscan spot offering quick-serve cafe fare by day & trattoria dishes with wine at night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -74.0102,
        total_orders: 1108.35,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Beer, Salad, Warm, Spacious, Legendary, Indulgent, Inviting, Charming, American',
        annual_dol: 1155.64,
        description:
            'Stone Street haunt with a decor of dark wood & chandeliers, offering many TVs & a standard bar menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7415,
        store_longitude: -73.5965,
        total_orders: 97.2,
        neighborhood: '',
        keywords: 'Local, Fabulous',
        annual_dol: 397.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7754,
        store_longitude: -73.9567,
        total_orders: 1419.24,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Pizza, Burger, Salad, Gluten Free, Vodka, Wine, Caesar Salad, Tasty, Casual, Delectable',
        annual_dol: 1973.64,
        description:
            '"Serving Detroit-style pizzas and an award-winning burger. Full service bar, local drafts & wine selection."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.717,
        store_longitude: -73.9995,
        total_orders: 1882.11,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Mexican, Shrimp Cocktail, Italian, Japanese, Wine, Delicious, Classy, Fusion, Casual, Fresh',
        annual_dol: 2468.69,
        description:
            'Circa-1943 Italian joint with a old-world vibe offering familiar red-sauce cooking & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -74.0111,
        total_orders: 2437.34,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Cheesesteak, Meatball, Spicy, French, Nachos, Philly Cheesesteak, Filet Mignon, Perfect',
        annual_dol: 3211.74,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.716,
        store_longitude: -74.0084,
        total_orders: 336.5,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Cajun, Champagne, Creole, Hurricane, Fun, Friendly, Polished, Exceptional, Negroni',
        annual_dol: 1375.5,
        description:
            '"Holywater is the bar we’ve always wanted to go to. Conceived over years of wild nights – in bars and on boats – Holywater celebrates the indulgences of our birthplace, New Orleans, and our adopted home, New York."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5799,
        store_longitude: -73.8375,
        total_orders: 184.81,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords: 'Wine, Pizza, Irish, Beer, Hotel, Comfortable, Perfect, Cool, Casual, Great',
        annual_dol: 206.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6604,
        store_longitude: -74.0006,
        total_orders: 5799.17,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Wine, Beer, Spacious, Cozy, Natural, American, Cool, Fantastic, Great, Fine',
        annual_dol: 6171.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -74.0103,
        total_orders: 564.67,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Lobster Roll, Burger, Dessert, Beer, Wine, Vodka, Delicious, Cozy, American',
        annual_dol: 584.44,
        description:
            'Posh chain serving American fare, including brunch, dinner & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7256,
        store_longitude: -74.0078,
        total_orders: 1049.47,
        neighborhood: 'Hudson Square',
        keywords:
            'Steak, Pizza, Italian, Meatball, Wine, Dessert, Beer, Delicious, New-York-Style, Stylish',
        annual_dol: 1286.71,
        description:
            'Pizzeria offering wood-fired pies, inventive plates & craft cocktails in a stylish interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7016,
        store_longitude: -73.9232,
        total_orders: 776.37,
        neighborhood: 'Bushwick',
        keywords:
            'Wine, Friendly, Fun, Sleek, Bright, Natural, Mediterranean, Excellent, Casual, Modern',
        annual_dol: 1087.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7105,
        store_longitude: -73.9232,
        total_orders: 1245.65,
        neighborhood: 'East Williamsburg',
        keywords: 'Warm, Memorable, Natural, Classic, Cultural, Creative, Rooftop',
        annual_dol: 1620.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7998,
        store_longitude: -73.9325,
        total_orders: 636.19,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Vegan, Local, Urban, Fresh',
        annual_dol: 2600.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7937,
        store_longitude: -73.6903,
        total_orders: 471.22,
        neighborhood: '',
        keywords:
            'Salad, Vegetarian, Pizza, Italian, Wine, Coffee, Beer, Upscale, Elegant, Impeccable',
        annual_dol: 506.13,
        description:
            'Elegant Italian bistro popular with locals for its upscale dishes & people-watching.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -74.0088,
        total_orders: 2742.56,
        neighborhood: 'Hudson Square',
        keywords: 'Hotel, Tacos, Upscale, Wine, Rich, Rooftop, Coffee, Cuban, Italian, Stylish',
        annual_dol: 2901.03,
        description:
            'Elegant, vintage-style rooms feature hardwood floors and contemporary photography, as well as flat-screen TVs, minifridges and Keurig coffeemakers. Suites add separate living spaces.Amenities consist of an exercise room and a business center, plus an Italian restaurant, and a hip rooftop cocktail bar with river views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6915,
        store_longitude: -74.0139,
        total_orders: 6144.52,
        neighborhood: 'Governors Island',
        keywords:
            'Mexican, Wine, Tex-Mex, Trendy, Modern, Seasonal, Casual, Perfect, Lively, Great',
        annual_dol: 30836.78,
        description:
            'Trendy outdoor Mexican eatery & bar with lively events & a palm-studded seating area on the bay.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6349,
        store_longitude: -73.965,
        total_orders: 837.3,
        neighborhood: 'Flatbush',
        keywords: 'Burger, Steak, Tacos, Sushi, Salad, Beer, Korean, American, Asian, Cozy',
        annual_dol: 1237.13,
        description:
            'Cozy gastropub serving an eclectic menu (lasagna & Korean sandwiches), plus exotic burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7076,
        store_longitude: -73.9209,
        total_orders: 167.23,
        neighborhood: 'Bushwick',
        keywords:
            'Salad, Italian, Meatball, Spicy, Delicious, Thai, Friendly, Sweet, Awesome, Rooftop',
        annual_dol: 480.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.8434,
        total_orders: 227.78,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Hamburger, Salad, Mexican, Pizza, Korean, Coffee, Beer, Spicy, Bbq, Asian',
        annual_dol: 931.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7276,
        store_longitude: -74.0036,
        total_orders: 2529.41,
        neighborhood: 'Hudson Square',
        keywords:
            'Lobster, Salad, Wine, Italian, French, Cozy, Mediterranean, Elegant, Classy, Fancy',
        annual_dol: 2691.78,
        description:
            'Snug, bright restaurant featuring a seasonal Southern Italian menu & unique cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -74.0078,
        total_orders: 1249.77,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Vegan, Salad, Nachos, Peruvian, Clean, Perfect, American, Gumbo, Casual, Traditional',
        annual_dol: 3606.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6663,
        store_longitude: -73.5518,
        total_orders: 1954.22,
        neighborhood: '',
        keywords:
            'Steak, Salad, Vodka, Dessert, Delicious, Bourbon, Italian, Juicy, Creamy, Irresistible',
        annual_dol: 2094.92,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7083,
        store_longitude: -73.9197,
        total_orders: 197.14,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Wine, Steak, Salad, Rustic, Beer, Cozy, Delicious, Quaint, Romantic, Vintage',
        annual_dol: 268.37,
        description:
            'Dark.& cozy, antiques-filled haunt offering live music alongside light bites & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6979,
        store_longitude: -73.9372,
        total_orders: 13281.59,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords:
            'Coffee, Wine, Vegetarian, Salad, Beer, Delicious, Romantic, American, Dope, Awesome',
        annual_dol: 13857.43,
        description:
            'Pastel-hued spot with a garden for Haitian-style cafe fare, coffee & cocktails, plus live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6677,
        store_longitude: -73.9873,
        total_orders: 255.95,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Pizza, Gin, Rum, Bourbon, British, Upscale, Irish, Traditional, Fantastic',
        annual_dol: 272.77,
        description:
            'Low-key British bar featuring small bites & imported draft beer, plus a backyard with picnic tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6837,
        store_longitude: -73.935,
        total_orders: 497.69,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Pizza, Chocolate Mousse, Italian, Spicy, Hotel, Casual, Amazing, Local, Tradition',
        annual_dol: 921.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9761,
        total_orders: 2948.58,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Vegetarian, Salad, Shrimp Cocktail, Wine, Shrimp Scampi, French, French Onion Soup, Upscale',
        annual_dol: 3011.14,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7765,
        store_longitude: -73.9568,
        total_orders: 10654.61,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Tacos, Mexican, Tequila, Steak, Wine, Italian, Ale, Delicious, Rustic, Thai',
        annual_dol: 13268.13,
        description:
            'Mexican eatery with modern takes on the cuisine & an extensive tequila list in a colorful room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7777,
        store_longitude: -73.9617,
        total_orders: 718.46,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Salad, Italian, Tasty, Wine, Upscale, Delicious, Classy, Fabulous, Superb',
        annual_dol: 857.04,
        description:
            'Sophisticated Italian bistro featuring old-world decor, classic cuisine & a massive wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7097,
        store_longitude: -73.8452,
        total_orders: 507.35,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Lasagna, Steak, Pizza, Salad, Italian, Vodka, Thai, Upscale, French',
        annual_dol: 542.96,
        description:
            'Brick-oven pizza, pasta & Mediterranean appetizers, plus grill items, in a family-friendly setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7307,
        store_longitude: -73.7426,
        total_orders: 692.09,
        neighborhood: 'Jamaica/Bellerose Manor/Queens Village',
        keywords:
            'Burger, Pizza, Italian, Flavorful, Shrimp Scampi, Delicious, Friendly, Traditional, Local, Great',
        annual_dol: 937.62,
        description:
            'Longtime locale for Italian fine dining featuring traditional dishes with housemade pastas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7754,
        store_longitude: -73.9563,
        total_orders: 562.83,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegan, Sushi, Salad, Meatball, Spicy, Wholesome, Sustainable, Healthy, Innovative, Asian',
        annual_dol: 578.96,
        description:
            '"Vegan Restaurant NYC. Full service, innovative restaurant offering the best in vegan and kosher fare with global influences on the Upper East side of Manhattan. Dine in, delivery, and takeout."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6652,
        store_longitude: -73.7249,
        total_orders: 3065.67,
        neighborhood: '',
        keywords:
            'Lobster, Pizza, Italian, Chicago-Style, Beer, Shrimp Scampi, Chicken Wings, American, Spicy, Seasonal',
        annual_dol: 3065.67,
        description:
            'Family-friendly chain outpost pairs familiar pub fare with an extensive list of house brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6568,
        store_longitude: -73.6475,
        total_orders: 679.17,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Gluten Free, Delicious, Upscale, Impeccable, Bruschetta, Fabulous, High-End, Perfect',
        annual_dol: 680.52,
        description:
            'Dimly lit, long-running Italian spot where tuxedoed waiters deliver classic fare & nightly specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7157,
        store_longitude: -73.7475,
        total_orders: 209.23,
        neighborhood: 'Jamaica/Queens Village',
        keywords: 'Upscale, Jamaican, Welcoming, Savory, Sleek, Intimate, Authentic, Casual',
        annual_dol: 446.6,
        description: '"Venue and private events"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5785,
        store_longitude: -74.1089,
        total_orders: 68.2,
        neighborhood: 'Midland Beach',
        keywords: 'Burger, Friendly, Casual, Great',
        annual_dol: 278.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -74.0031,
        total_orders: 3216.88,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Tasty, Salad, Wine, Delicious, Cozy, Mediterranean, Greek, Healthy, Lively, Robust',
        annual_dol: 4382.99,
        description:
            'Cozy option specializing in Greek classics including seafood & meat dishes, plus a robust wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8437,
        store_longitude: -73.8457,
        total_orders: 85.21,
        neighborhood: 'Westchester Square',
        keywords:
            'Sushi, Vegetarian, Shrimp Cocktail, Japanese, Spicy, Savory, Asian, Sashimi, Bbq, Fusion',
        annual_dol: 1109.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8493,
        store_longitude: -73.8533,
        total_orders: 0,
        neighborhood: 'Morris Park',
        keywords:
            'Lobster, Pizza, Steak, Salad, Shrimp Cocktail, Tasty, Italian, Lobster Bisque, Wine, Vodka',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7379,
        store_longitude: -74.0021,
        total_orders: 97.32,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Mexican, Tasty, Salad, Gluten Free, Burritos, Delicious, Tex-Mex, Creamy, Spanish',
        annual_dol: 397.8,
        description: 'Overstuffed burritos & margaritas pull a young crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7465,
        store_longitude: -73.8993,
        total_orders: 0,
        neighborhood: 'Flushing/Woodside',
        keywords:
            'Thai, Green Curry, Chinese, Asian, Pad Thai, Authentic, Bright, Casual, Great, Pad See Ew',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7883,
        store_longitude: -73.9768,
        total_orders: 783.98,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Coffee, Delicious, American, Irish, Unique',
        annual_dol: 2752.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8527,
        store_longitude: -73.8676,
        total_orders: 41.76,
        neighborhood: 'East Bronx/Pelham Parkway',
        keywords:
            'Salad, Coffee, Cuban, Delicious, Spanish, Dominican, Homemade, Latin, American, Eclectic',
        annual_dol: 48.8,
        description:
            'Latin fusion dishes & American fare like Cuban sandwiches, mofongo & eggs in modern dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6598,
        store_longitude: -73.5427,
        total_orders: 233.99,
        neighborhood: '',
        keywords:
            'Tequila, Tacos, Beer, Pizza, Mexican, Margarita, Hawaiian, Tasty, Italian, Fried Chicken',
        annual_dol: 301.74,
        description: '"Fun food and drinks for everyone!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6294,
        store_longitude: -74.1117,
        total_orders: 5.09,
        neighborhood: 'West Brighton',
        keywords:
            'Lasagna, Pizza, Italian, Coffee, Dessert, American, Karaoke, Homemade, Casual, Traditional',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6742,
        store_longitude: -73.7358,
        total_orders: 121.19,
        neighborhood: 'Laurelton/Rosedale',
        keywords:
            'Salad, Tasty, Savory, Caribbean, Jamaican, Warm, Classy, Authentic, Amazing, Great',
        annual_dol: 191.75,
        description:
            '"Nestled in the warm residential area of Laurelton, Queens there is no place better to experience beautiful cuisine, tasty people, amazing entertainment and breathtaking ambiance all in one location! // Seafood, Night Life, Caribbean Cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7701,
        store_longitude: -73.9576,
        total_orders: 448.1,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Kebab, Vegetarian, Salad, Pizza, Wine, Turkish, Beer, Dessert, Mediterranean, Delicious',
        annual_dol: 523.55,
        description:
            'Grilled fish, lamb & grape leaves, plus fixed-price lunches, in a no-frills dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.9957,
        total_orders: 184.33,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Coffee, Hotel, Friendly, Sweet, Outstanding, Iconic, Great, Local',
        annual_dol: 753.48,
        description:
            'The Stephen A. Schwarzman Building, commonly known as the Main Branch, 42nd Street Library or the New York Public Library, is the flagship building in the New York Public Library system in the Midtown Manhattan neighborhood of New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7045,
        store_longitude: -74.0104,
        total_orders: 5200.56,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Tasty, Mexican, Beer, Delicious, Vodka, Korean, Cozy, American, Modern',
        annual_dol: 5505.32,
        description:
            'Cozy, dimly lit pub known for small-batch beers & draft cocktails, plus late-night American eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8,
        store_longitude: -73.9099,
        total_orders: 31.79,
        neighborhood: 'Port Morris/West Bronx',
        keywords:
            'Burger, Salad, Delicious, Coffee, Hotel, Friendly, Greek, Fresh, Omelette, Great',
        annual_dol: 129.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7779,
        store_longitude: -73.9544,
        total_orders: 123.57,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Hotdog, Pizza, Beer, Italian, Wine, Delicious, American, French, Playful',
        annual_dol: 505.13,
        description:
            '"Playful, dimly lit restaurant. Serving satisfying shareable small plates, craft cocktails, great beer and wine. Come for the food and drinks, stay for the vibe!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9113,
        total_orders: 28.23,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Mexican, Margarita, Italian, American, Casual, Local, Whimsical, Signature',
        annual_dol: 31.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7339,
        store_longitude: -74.0021,
        total_orders: 3848.31,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Whiskey, Tasty, Martini, Delicious, Dope, Great, Casual',
        annual_dol: 3954.16,
        description:
            'Gay bar & National Historic Landmark, site of the 1969 riots that launched the gay rights movement.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -74.0029,
        total_orders: 958.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Italian, Delicious, Russian, American, Premium, Contemporary',
        annual_dol: 1023.82,
        description:
            'White-tablecloth American bistro with a clubby feel, plus ample outdoor seating & a raw bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -74.0061,
        total_orders: 2198.71,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Beer, Salad, Ale, Italian, Warm, Romantic, Irish, Spacious',
        annual_dol: 3688.31,
        description:
            'This homey pub with a fireplace & tin ceiling boasts a cut-above-the-usual American-Irish menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 23.49,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Mediterranean, Beer, Trendy, Romantic, Sangria, Classy, Intimate, Perfect',
        annual_dol: 96.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -74.0047,
        total_orders: 892.02,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Trendy, Fun, Premium, Awesome, Great, Local, Dynamic, Casual',
        annual_dol: 935.02,
        description:
            'Craft brews & bar bites from Greenpoint Beer Works served in a cavernous, industrial-chic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.634,
        total_orders: 441.75,
        neighborhood: '',
        keywords: 'German, Burger, Pizza, Salad, Beer, American, Belgian, Delicious, Clean, Local',
        annual_dol: 942.91,
        description:
            'German eatery & tavern serving traditional dishes & craft beer in a laid-back space with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7842,
        store_longitude: -73.9736,
        total_orders: 509.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Steak, Meatball, Salad, Tasty, Delicious, Mediterranean, Turkish, Greek, American',
        annual_dol: 579.83,
        description:
            'Mediterranean eats & craft cocktails served in a brick-lined, bistro-style space with a small patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7903,
        store_longitude: -73.9729,
        total_orders: 139.23,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Italian, Wine, Delicious, Romantic, Warm, Refined, Exquisite, Robust, Authentic, Classic',
        annual_dol: 243.82,
        description:
            'Refined eatery offering classic Italian dishes, seafood & steak, plus a robust wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.9945,
        total_orders: 11.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Salad, Italian, Healthy, Caesar Salad, Friendly, Contemporary, Casual, Fresh',
        annual_dol: 45.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6663,
        store_longitude: -73.5517,
        total_orders: 60.3,
        neighborhood: '',
        keywords:
            'Lobster, Sushi, Salad, Japanese, Fruity, Sashimi, Chinese, Asian, Fusion, American',
        annual_dol: 246.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7068,
        store_longitude: -73.9032,
        total_orders: 5.27,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Lobster, Steak, Spacious, Clean, Natural, Spectacular, Cool, Casual, Gyro',
        annual_dol: 21.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7693,
        store_longitude: -73.9628,
        total_orders: 1038.17,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Burger, Italian, Delicious, Rich, Wine, Upscale, Warm, Impeccable, Yummy',
        annual_dol: 1083.19,
        description:
            'Upscale Italian cuisine served in a snug space popular with Upper East Side neighbors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6944,
        store_longitude: -73.931,
        total_orders: 494.82,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Hotel, Coffee, Rooftop, Modern, American, Amazing, Great, Hip, Seasonal, Cool',
        annual_dol: 616.92,
        description:
            'Brooklyn Bridge.Modern, streamlined rooms with locally inspired art provide complimentary Wi-Fi, flat-screen TVs and designer toiletries. Some rooms add daybeds and sleep up to 3 guests.There’s a rustic-chic cafe with picnic tables and Pacific Northwest—style cuisine, and a seasonal rooftop lounge. Activities and live entertainment are offered in a laid-back event space. Bike rentals are available, and pets are welcome (surcharge).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7743,
        store_longitude: -73.9631,
        total_orders: 18428.61,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Upscale, Hotel, Legendary, Elegant, Delicious, French, Classic, Sleek, Mediterranean, Timeless',
        annual_dol: 19035.31,
        description:
            "subway.The plush rooms feature flat-screen TVs and free Wi-Fi, along with iPod docks and minibars. Upgraded rooms add skyline views, sitting areas, and/or soaking tubs and rainfall showers. Sleek suites offer separate living rooms; some have pianos, full kitchens and art collections. Room service is available 24/7.Dining options include an upscale restaurant, a vibrant bar, and the storied Cafe Carlyle. There's also an elegant spa, a hair salon and a gym.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8449,
        store_longitude: -73.91,
        total_orders: 49.53,
        neighborhood: 'West Bronx/Claremont',
        keywords: 'Beer, French, Southern, Homemade, Casual, Incredible',
        annual_dol: 262.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9453,
        total_orders: 330.35,
        neighborhood: 'Williamsburg',
        keywords: 'Whiskey, Wine, Beer, Tasty, Ale, Hotel, Delicious, Fancy, American, Friendly',
        annual_dol: 385.07,
        description:
            'Out-of-the-way East Williamsburg bar offering specialty cocktails & a speakeasy feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.73,
        store_longitude: -73.9553,
        total_orders: 55.62,
        neighborhood: 'Greenpoint',
        keywords:
            'Mexican, Tequila, Tacos, Vegan, Upscale, Delicious, Contemporary, Cozy, Modern, Classic',
        annual_dol: 354.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7791,
        store_longitude: -73.954,
        total_orders: 1234.21,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Sushi, Flavorful, Beer, Ramen, Savory, Spicy, Delicious, Japanese, Sweet, Smooth',
        annual_dol: 5493.94,
        description:
            'Sushi, teriyaki & other Japanese dishes are presented in a low-key, bi-level space with TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7745,
        store_longitude: -73.959,
        total_orders: 501.92,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Burger, Steak, Salad, Dessert, Delicious, Upscale, Bbq, Trendy, Mediterranean, Comfortable',
        annual_dol: 829.26,
        description:
            'Trendy restaurant specializing in creative cocktails & fusion kosher Mediterranean cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.655,
        store_longitude: -73.6736,
        total_orders: 419.95,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Salad, Hamburger, French Onion Soup, French, Greek, American, Greek Salad, Casual',
        annual_dol: 428.86,
        description:
            'Neighborhood American bar/eatery offering happy hours & down-home cuisine in relaxed surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6524,
        store_longitude: -73.6113,
        total_orders: 88.88,
        neighborhood: '',
        keywords: 'Burger, Pizza, Hamburger, American, Fun, Amazing, Great, Casual, Chill',
        annual_dol: 94.79,
        description:
            'Energetic spot for bowling, with party options, American food & lots of TVS, plus a lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -73.9305,
        total_orders: 15.97,
        neighborhood: 'East Williamsburg',
        keywords:
            'Lobster, Lobster Tail, Lobster Roll, Cajun, Spicy, Rich, Friendly, Awesome, Casual, Great',
        annual_dol: 65.29,
        description:
            '"BIBS UP, PHONES DOWN. IT\'S #SHAKINGTIME Dive headfirst into a bag of steaming hot seafood shaken in rich butter, an abundance of garlic, and a signature blend of Cajun spices. BUILT ON COMMUNITY: We believe in the power of food to bring us together to celebrate, laugh, and be in the moment. Fully immerse yourself in the rhythm of shelling and plucking out lumps of shellfish while the bag keeps your seafood hot and drenched in our signature sauce."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7821,
        store_longitude: -73.9806,
        total_orders: 12391.38,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Chinese, Wine, Dessert, Chili Chicken, Spicy, Korean, Thai, Cozy, Rustic, Bruschetta',
        annual_dol: 14182.55,
        description:
            'Creative dim sum & other modern, seasonal Chinese food in a cozy & rustic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7849,
        store_longitude: -73.9772,
        total_orders: 3908.67,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Steak, Whiskey, Beer, Jagermeister, Nachos, Irish, Ale, American, Friendly',
        annual_dol: 4375.58,
        description:
            'Narrow, literary-themed Irish pub whips up cocktails named after dead poets & food from a bar menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.8136,
        total_orders: 19.28,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Wine, Beer, Donut, Comfortable, Spanish, Classy, Casual, Great',
        annual_dol: 78.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7529,
        store_longitude: -73.9858,
        total_orders: 30.83,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Fun, Beer, Great',
        annual_dol: 126,
        description:
            '"Indoor Golf facility featuring private bays, instruction, and group availability."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8179,
        store_longitude: -73.5548,
        total_orders: 33.51,
        neighborhood: '',
        keywords: 'Golfclub, Hotel, Premium, Fun, Classic, Healthy',
        annual_dol: 136.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7008,
        store_longitude: -73.8994,
        total_orders: 41.09,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Vegetarian, Salad, Mexican, Beer, Wine, Nachos, Ale, Delicious, American, Homemade',
        annual_dol: 118.36,
        description:
            'Latin-Caribbean recipes served in a hip, 2-story destination with bar seating & a garden terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9574,
        total_orders: 4006.6,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Tasty, Spicy, Upscale, Cozy, French, Sweet, Mediterranean, Colorful, Turkish',
        annual_dol: 4263.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7376,
        store_longitude: -73.9537,
        total_orders: 2112.99,
        neighborhood: 'Greenpoint',
        keywords:
            'Hotel, French Toast, Coffee, Avocado Toast, French, Stylish, Organic, Modern, Rooftop, American',
        annual_dol: 2248.63,
        description:
            'Empire State Building.Featuring hardwood floors and artwork commissioned by local artists, the bright rooms have free Wi-Fi, flat-screen TVs, and tea and coffeemaking facilities, as well as balconies offering city views. Loft rooms and 1- to 2-bedroom apartments have kitchens, living rooms, and pull-out couches.Amenities include an industrial-chic restaurant with a wood bar and pendant lamps, in addition to a gym, a sauna and a steam room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.9678,
        total_orders: 163.65,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Warm, Delicious, American, Venezuelan, French, Classic, Great, Fine, Attentive',
        annual_dol: 619.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -73.9591,
        total_orders: 216.57,
        neighborhood: 'Greenpoint',
        keywords:
            'Lobster, Vegetarian, Italian, Tequila, Dessert, Wine, Delicious, Beer, Organic, Trendy',
        annual_dol: 312.2,
        description:
            'Hearty Puglia-influenced Italian fare served in a rustic-chic space that has a bar & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7542,
        store_longitude: -73.9939,
        total_orders: 946.66,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Stylish, Iconic, Coffee, Premium, Impressive, Tranquil, Modern, Casual',
        annual_dol: 1009.59,
        description:
            "Offering modern decor and custom-designed beds, the laid-back rooms have Wi-Fi (fee) and flat-screen TVs with premium channels, plus coffeemakers and minifridges. Some rooms provide skyline views. Room service is available.A casual glass-enclosed restaurant has a courtyard garden and a bar. There's also a 24/7 gym, along with 2 meeting rooms and a business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7539,
        store_longitude: -73.9999,
        total_orders: 268.7,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Coffee, Wine, Healthy, Premium, Contemporary, Modern, Classic, Casual, Fine, Global',
        annual_dol: 1098.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7755,
        store_longitude: -73.9537,
        total_orders: 3119.04,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Beer, Wine, Comfortable, American, Irish, Casual, Traditional, Eclectic, Cool, Fun',
        annual_dol: 3304.91,
        description:
            'Longtime family-owned sports bar with an old-school vibe, plus a pool table, darts & video games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7757,
        store_longitude: -73.9531,
        total_orders: 1726.68,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Salad, Beer, Bbq, Tasty, Spicy, Tex-Mex, American, Southern, Casual',
        annual_dol: 7213.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.6455,
        total_orders: 52.83,
        neighborhood: '',
        keywords: 'Salad, Beer, Brazilian, Great, Traditional, Excellent, Dutch, Casual',
        annual_dol: 215.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7161,
        store_longitude: -73.5977,
        total_orders: 291.53,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Burger, Salad, Cajun, Cajun-Style, Beer, Fried Shrimp, Gumbo, Creamy',
        annual_dol: 831.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7654,
        store_longitude: -73.9802,
        total_orders: 2613.32,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Chicken Parmigiana, Pizza, Salad, Vodka, Wine, Champagne, Italian, Beer, Meatball',
        annual_dol: 2697.25,
        description:
            'Tuscan trattoria with a bustling scene, Italian art on the walls & an antipasto bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.9874,
        total_orders: 1240.05,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Vegetarian, Vegan, Hamburger, Lobster, Lobster Roll, Whiskey, Nachos, Beer',
        annual_dol: 6408.31,
        description:
            'High-end sports-bar chain with a huge menu of New American fare & an extensive list of draft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7534,
        store_longitude: -73.9873,
        total_orders: 225.67,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Salad, Flavorful, Wine, Gluten Free, Savory, Elegant, Spicy, Martini, Thai, Asian',
        annual_dol: 1121.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7033,
        store_longitude: -74.0108,
        total_orders: 7709.98,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Whiskey, Burger, Steak, Scotch, Beer, Wine, Coffee, Irish, Ale, American',
        annual_dol: 10528.45,
        description:
            'This 2-story spot combines a lunch taproom with a parlor serving small plates & vintage cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -74.0068,
        total_orders: 3907.21,
        neighborhood: 'Hudson Square',
        keywords:
            'Vegan, Burger, Vegetarian, Salad, Coffee, Gluten Free, Wine, Avocado Toast, Bourbon, Rustic',
        annual_dol: 16099.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.5721,
        total_orders: 343.38,
        neighborhood: '',
        keywords: 'Luxurious, Elegant, Spacious, Spectacular, Outstanding, Perfect, Fine',
        annual_dol: 1121.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -74.009,
        total_orders: 6.93,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Sushi, Salad, Green Papaya Salad, Beer, Japanese, French, Ramen, Thai, Spicy',
        annual_dol: 28.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7757,
        store_longitude: -73.9535,
        total_orders: 498.85,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Beer, Salad, Comfortable, Swiss, Friendly, American, Fresh, Casual, Great, Local',
        annual_dol: 2039.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7706,
        store_longitude: -73.9538,
        total_orders: 384.01,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Salad, Wine, Beer, Japanese, Delicious, Homemade, Asian, Classy, Casual',
        annual_dol: 1569.69,
        description:
            'Traditional techniques are used at this compact local restaurant for Japanese favorites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6211,
        store_longitude: -73.7,
        total_orders: 18.13,
        neighborhood: '',
        keywords: 'Wonderful',
        annual_dol: 49.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6951,
        store_longitude: -73.9032,
        total_orders: 49.02,
        neighborhood: 'Bushwick/Flushing/Ridgewood',
        keywords:
            'Tequila, Wine, Mexican, Coffee, Rum, American, Latin, Mediterranean, Delicious, Cozy',
        annual_dol: 200.4,
        description: '"Bar Restaurant Serving great cocktails and delicious food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7047,
        store_longitude: -73.6407,
        total_orders: 7.23,
        neighborhood: '',
        keywords:
            'Lobster, Shrimp Scampi, Roast Beef, Glamorous, Exceptional, Filet Mignon, Great, Classic, Excellent, Rare',
        annual_dol: 41.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.77,
        store_longitude: -73.9337,
        total_orders: 7.55,
        neighborhood: 'Astoria',
        keywords: 'Wine, Vodka, Coffee, Beer, Italian, Hotel, Luxurious, Urban, Seasonal, Homemade',
        annual_dol: 30.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6678,
        store_longitude: -73.9838,
        total_orders: 2159.93,
        neighborhood: 'Park Slope',
        keywords: 'Whiskey, Beer, Wine, Cozy, Romantic, Quaint, Intimate, Casual, Classy, Local',
        annual_dol: 2225.47,
        description:
            'Bustling bar offering music, readings & films, plus a large selection of beers & spirits.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6705,
        store_longitude: -73.985,
        total_orders: 232.96,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Steak, Shrimp Cocktail, Mexican, Beer, Italian, Wine, Champagne, American, Colombian',
        annual_dol: 721.4,
        description:
            '"ABOVE PRIME STEAKS from SNAKE RIVER FARMS and VARLASSO SALMON HARVESTED NATURALLY, 20 BEERS ON DRAFT BEER FLIGHTS AND TASTING FAMOUS CHEF MICHAEL MCNEILLY""',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6764,
        store_longitude: -73.9867,
        total_orders: 1159.55,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Wine, Delicious, Modern, Perfect, Casual, Sweet, Local, Great, Chill',
        annual_dol: 1453.55,
        description:
            'Clever cocktails, local draft beers & bar bites offered in a laid-back, modern space with skylights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9187,
        total_orders: 1183.89,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, Beer, Delicious, Wine, Friendly, Perfect, Great, Casual, Local, Signature',
        annual_dol: 1209.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9669,
        total_orders: 249.15,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Mexican, Pizza, Salad, Beer, Nachos, Spicy, Homemade, Premium, Casual, Fresh',
        annual_dol: 271.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.9555,
        total_orders: 14492.64,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Pizza, Chocolate Cake, Italian, Mexican, Meatball, Wine, Mediterranean, American, Rustic, Asian',
        annual_dol: 15832.04,
        description:
            'Creative, modern takes on classic Italian fare plus wood-fired pizza in a stylishly rustic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -73.9597,
        total_orders: 465.65,
        neighborhood: 'Greenpoint',
        keywords: 'Cozy, Polish, Comfortable, Casual, American, Great, Unique',
        annual_dol: 643.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7085,
        store_longitude: -73.9436,
        total_orders: 187.6,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Vegetarian, Pizza, Lobster, Gluten Free, Italian, Beer, Wine, Lobster Bisque, Margarita, Organic',
        annual_dol: 340.1,
        description:
            'Rustic haunt for sourdough pizza with cocktails, beer & wine plus happy hour deals & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.991,
        total_orders: 1659.3,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Burger, Steak, Pizza, Salad, Dessert, Wine, Beer, Delicious, Juicy, French',
        annual_dol: 1731.24,
        description:
            'Drink specials & a split-level setting attract the after-work crowd to this large watering hole.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7529,
        store_longitude: -73.9838,
        total_orders: 3638.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Cheesecake, Hotel, Salad, Japanese, Mediterranean, Elegant, Organic, Fun, Modern, American',
        annual_dol: 3715.93,
        description:
            "Featuring hardwood floors, the sleek, minimalist rooms are equipped with flat-screen TVs and free Wi-Fi. Suites add separate living areas or terraces. Room service is offered 24 hours a day.There's a modern Japanese restaurant, a screening room and a fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -74.0017,
        total_orders: 1150.08,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Hotel, Champagne, Wine, Rich, Coffee, Beer, Eclectic, Sophisticated, Rooftop, American',
        annual_dol: 1447.43,
        description:
            'Empire State Building.Cozy, contemporary rooms, some with sleeping lofts, offer free Wi-Fi, flat-screen TVs and rainfall showers, as well as sitting areas, and tea and coffeemaking facilities (on request).Amenities include a casual cafe and a 24-hour gym. Area tours and excursions are available for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7537,
        store_longitude: -73.6565,
        total_orders: 171.42,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Salad, Juicy, Mediterranean, Greek, French, Yummy, Caesar Wrap, Classy',
        annual_dol: 182.68,
        description:
            'Gyros, moussaka & other familiar Greek meals round out the menu at this informal, strip-mall eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7764,
        store_longitude: -73.9566,
        total_orders: 247.67,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Tequila, Lobster, Steak, Wine, Beer, Dessert, Bourbon, Italian, Spicy, Belgian',
        annual_dol: 337.16,
        description:
            'Seafood & desserts plus variously prepared Prince Edward Island mussels in a casual setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -73.9861,
        total_orders: 26737.1,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Tequila, Wine, Beer, American, French, Elegant, Retro, Sangria, Classic, Casual',
        annual_dol: 30821.49,
        description:
            'Warmly lit bi-level industrial venue featuring elevated American cuisine, craft cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.9878,
        total_orders: 2693.59,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Burger, Lobster, Tasty, Wine, Beer, Upscale, Delicious, Elegant, Ribeye Steak',
        annual_dol: 3569.12,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7546,
        store_longitude: -73.9866,
        total_orders: 2473.84,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Tequila, Mexican, Margarita, Jambalaya, Tasty, Vegan, Bourbon, Tangy, Spicy, Delicious',
        annual_dol: 2526.34,
        description:
            'Mexican BBQ menus & cocktails combine at this lively, casual spin-off of a popular food truck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6287,
        store_longitude: -74.0741,
        total_orders: 331.36,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Burger, Steak, Vegetarian, Cajun, Italian, Wine, Beer, Turkish, Organic, Delicious',
        annual_dol: 772.48,
        description:
            "\"Wynwood Restaurant & Bar is your number 1 destination for Dinner, Night Life & Brunch on Staten Island. With a menu that is compiled of cuisines from around the world, and an atmosphere that transports you to Miami's Wynwood Walls, you'll be sure to have an experience unparalleled to any restaurant you've ever been. Hand Crafted cocktails, artisan street cuisine, the finest seafood, high end cuts of Prime steaks, friendly staff, Live music & entertainment all blend perfectly for an atmosphere that is not only sophisticated but simultaneously casual & inviting. Open 7 Days for Brunch, Dinner & Late Night, you can be sure to catch us at any time!\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6132,
        store_longitude: -73.9394,
        total_orders: 157.91,
        neighborhood: 'Marine Park',
        keywords: 'Steak, Burger, Salad, Wine, Vodka, Bourbon, Spicy, Bbq, Elegant, Crisp',
        annual_dol: 204.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7051,
        store_longitude: -74.0052,
        total_orders: 635.54,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Steak, Pizza, Salad, Meatball, Italian, Spanish, Avocado Toast, Modern, American',
        annual_dol: 2597.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8639,
        store_longitude: -73.6291,
        total_orders: 19.68,
        neighborhood: '',
        keywords: 'Vegetarian, Tequila, Vegan, Wine, Vodka, Beer, Mexican, Tasty, Homemade, Thai',
        annual_dol: 80.45,
        description:
            '"TAVA Fine Indian Cuisine is a family-owned fine Indian dining restaurant based in New York. Originated from the Persian word tāve, in Punjabi, Hindi, and Urdu, “Tava/Tawaa” means cooking pan. It is a large, convex, disc-shaped pan used primarily for cooking traditional Indian dishes such as roti, chapati, paratha, dosa, and many other types of flatbreads. Our passion for serving fresh Indian food straight from the tava inspired the concept of TAVA Fine Indian Cuisine. Our centuries-old, traditional Indian food recipes are cooked with fresh ingredients, and a unique blend of spices such as turmeric, cumin, coriander, and incorporate some modern and contemporary influences to give you the most extraordinary dining experience of your life."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6778,
        store_longitude: -73.6673,
        total_orders: 630.01,
        neighborhood: '',
        keywords:
            'Salad, Wine, Shrimp Cocktail, Gourmet, Italian, Upscale, Martini, French, Cozy, Warm',
        annual_dol: 646.08,
        description:
            'Storefront Italian eatery in a strip mall offering traditional cuisine in an upscale environment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6287,
        store_longitude: -74.0797,
        total_orders: 829.74,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Wine, Whiskey, Delicious, Romantic, Fun, Turkish, Relaxed, Great, Incredible, Mojito',
        annual_dol: 883.77,
        description:
            '"A candle it Cocktail Bar on Staten Island. Drinks and Food until 4AM Every night."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.589,
        total_orders: 561.88,
        neighborhood: '',
        keywords: 'Hotel, American, Modern, Traditional, Great, Friendly',
        annual_dol: 573.8,
        description:
            "All rooms offer traditional decor and custom-designed beds, plus flat-screen TVs, microwaves, minifridges and free WiFi. Suites add separate living areas and pull-out sofas.A buffet or cooked-to-order breakfast is available (fee), and there's an on-site restaurant serving American cuisine, as well as a low-key bar and a 24/7 pantry. There's also a fitness center and the indoor pool with a hot tub. Additional amenities include 2 meeting rooms and a business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.721,
        store_longitude: -74.0098,
        total_orders: 948.63,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Cheesecake, Wine, Savory, Trendy, Cozy, Spicy, Bbq, American, Classy, Modern',
        annual_dol: 1048.19,
        description:
            'Cozy sports bar with a large menu of American/Mexican pub food & happy-hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7187,
        store_longitude: -74.0022,
        total_orders: 79.95,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Mexican, Steak, Scandinavian, Local, Casual, Delicious',
        annual_dol: 388.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7747,
        store_longitude: -73.9538,
        total_orders: 277.39,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Wine, Italian, Stylish, Modern, Perfect, Bright, Casual, Classic, Tradition',
        annual_dol: 1133.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7066,
        store_longitude: -73.9238,
        total_orders: 22377.61,
        neighborhood: 'Bushwick',
        keywords: 'Funky, Fun, Wonderful, Friendly, Perfect, Creative, Complex, Unique',
        annual_dol: 26104.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 4.98,
        neighborhood: 'Bushwick',
        keywords: 'Tacos, Steak, Mexican, Margarita, Beer, Nachos, Wine, Dessert, Hip, Cozy',
        annual_dol: 20.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -74.0101,
        total_orders: 2786.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Burger, Salad, Crab Cakes, Wine, Dessert, American, Caesar Salad, Classic, Modern',
        annual_dol: 2943.5,
        description:
            'Modern steakhouse from Marc Forgione serving classic fare updated with NYC twists in posh surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -74.0128,
        total_orders: 329.62,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegan, Vegetarian, Salad, Wine, Pizza, Vodka, Italian, Upscale, Impeccable, Warm',
        annual_dol: 504.93,
        description:
            'Tableside presentations are offered at this upscale Italian spot with free antipasti, pastas & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5674,
        store_longitude: -74.1125,
        total_orders: 73.46,
        neighborhood: 'New Dorp/New Dorp Beach/#210',
        keywords:
            'Vegan, Vegetarian, Milkshake, Beer, Stylish, Aesthetic, Classic, Cool, Great, Creative',
        annual_dol: 300.29,
        description:
            'Stylish theater chain for new & classic films features cocktails & creative bites served seat-side.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.711,
        store_longitude: -73.8535,
        total_orders: 686.42,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Coffee, Wine, Whiskey, Italian, Mexican, Elegant, Warm, Friendly, Modern, Classic',
        annual_dol: 716.18,
        description:
            'Longtime Italian fixture for homestyle classics & fine wines served in a romantic, high-end setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8272,
        store_longitude: -73.9261,
        total_orders: 72.26,
        neighborhood: 'Concourse',
        keywords:
            'Steak, Burger, Vegan, Cheesesteak, Wine, French Toast, Beer, Margarita, Italian, Coffee',
        annual_dol: 196.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7105,
        store_longitude: -73.9232,
        total_orders: 1225.16,
        neighborhood: 'East Williamsburg',
        keywords:
            'Vietnamese, Delicious, Contemporary, Friendly, Modern, Asian, Awesome, Memorable, Classic, Cool',
        annual_dol: 1251.16,
        description:
            'Cool spot for banh mi & other Vietnamese plates with thoughtfully sourced ingredients.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.707,
        store_longitude: -73.9227,
        total_orders: 1292.8,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, French, Savory, Rum, Spacious, Yummy, Relaxed, American, Awesome',
        annual_dol: 1292.8,
        description:
            'Classic cocktails & small plates are served in a laid-back lounge with chandeliers, sofas & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8338,
        store_longitude: -73.8612,
        total_orders: 6931.87,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Burger, Italian, American, Caesar Wrap, Karaoke, Homemade, Warm, Local, Traditional, Great',
        annual_dol: 7474.9,
        description:
            'Easygoing, long-standing restaurant & lounge offering local cuisine & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.9173,
        total_orders: 110.09,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Vegan, Peruvian, Margarita, Latin, American, Mojito, Fusion, Relaxed, Chill',
        annual_dol: 234.66,
        description:
            'Relaxed bar & grill serving up traditional pub grub, burgers & Peruvian entrees, plus vegan options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6771,
        store_longitude: -73.9819,
        total_orders: 9.84,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Vegetarian, Vegan, Salad, Coffee, Italian, Beer, Wine, Dessert, American',
        annual_dol: 40.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7358,
        store_longitude: -74.0074,
        total_orders: 1716.87,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Tequila, Whiskey, Wine, Beer, Bourbon, Gin, Salad, Upscale, Rum',
        annual_dol: 1756.91,
        description:
            'Upscale bar modeled after a vintage railway car with classic cocktails and homemade mixers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7379,
        store_longitude: -74.0037,
        total_orders: 3075.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Hamburger, Beer, Whiskey, French, Ale, American, Delicious, Cozy, Casual',
        annual_dol: 3222.76,
        description:
            'Easygoing pub with a well-known West Village sibling dishing up brews & big burgers on paper plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6661,
        store_longitude: -73.6645,
        total_orders: 16.42,
        neighborhood: '',
        keywords: 'Burger, Gluten Free, American, Wine, Casual, Traditional, Great',
        annual_dol: 67.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7656,
        store_longitude: -73.9189,
        total_orders: 1359.2,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Wine, Karaoke, Vintage, Local, Great, Casual, Unique',
        annual_dol: 1418.13,
        description:
            'Laid-back bar offering indie beers, cocktails & small bites, plus occasional events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7774,
        store_longitude: -73.9509,
        total_orders: 625.78,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Italian, Tasty, Spicy, Homemade, Intimate, Casual, Authentic, Lively, Local, Excellent',
        annual_dol: 639.06,
        description:
            'This intimate neighborhood Italian restaurant featuring Roman cuisine draws a lively crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7215,
        store_longitude: -73.8488,
        total_orders: 19.5,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Sushi, Salad, Mexican, Beer, Japanese, Thai, Ale, Sashimi, Fried Rice, Ramen',
        annual_dol: 79.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -74.0095,
        total_orders: 473.66,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Hotel, Champagne, Opulent, Tequila, Cozy, French Toast, French, Chic, Intimate, Rooftop',
        annual_dol: 553.4,
        description:
            "Inspired by cabins on luxury ships, the sophisticated, cozy rooms include free Wi-Fi and flat-screen TVs, plus DVD players, iPod docks and shared bathrooms; some include bunk beds. Upgraded cabins have larger beds, private bathrooms, and/or terraces or river views. Room service is available.Guests have free use of bicycles to explore the city. An on-site restaurant serves French-Moroccan fare and a popular brunch menu, and there's an opulent Victorian-style lobby bar.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -73.6352,
        total_orders: 380.75,
        neighborhood: '',
        keywords:
            'Wine, Spanish, Italian, Dessert, Belgian, American, Delicious, Eclectic, Rustic, Fun',
        annual_dol: 467.17,
        description:
            'Hip venue pairing Italian & Spanish tapas, including meat & cheese plates, with European wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8412,
        store_longitude: -73.9376,
        total_orders: 1055.72,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Vegan, Burger, Pizza, Beer, Wine, Tasty, Ale, American, Delicious, Karaoke',
        annual_dol: 1509.88,
        description: '"Trendy draft bar offering burgers and spirits"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9013,
        total_orders: 18.74,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Steak, Wine, Dessert, Southern, French, Cozy, Croissant Sandwich, Casual, American, Tradition',
        annual_dol: 57.65,
        description:
            '"Neighborhood bar and kitchen counter offering food & drink inspired by the flavors of Southern France and Northern Italy."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6655,
        store_longitude: -73.9825,
        total_orders: 704.11,
        neighborhood: 'Park Slope',
        keywords: 'Tequila, Salad, Mexican, Dessert, Wine, Beer, Spicy, Warm, Creamy, Enchiladas',
        annual_dol: 1066.73,
        description:
            '"We come from a small desert town in southern Oaxaca where we grew up farming, caring for livestock, and selling at the colorful daily markets in the towns surrounding our home. Our mother was an expert cook and passed along not only her recipes and creativity but respect for the best ingredients available. Our Father has always had his own small Plalenque (Distillery) and produced the same Mezcales that have been made in his family for generations. One by one we settled in the NYC area, working in restaurants, bars, bakeries etc. After 25 years of working and saving we’re opening Caza Azul. Here we hope to carry on our parents passions of the best of creative Oaxacan cuisine and unique Mezcales. We hope you enjoy"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6653,
        store_longitude: -73.9898,
        total_orders: 715.59,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Burger, Salad, Beer, Ale, Chinese, Irish, Friendly, Sweet, Hospitable, Classic',
        annual_dol: 763.15,
        description:
            'Pints of ale, English football on TV & traditional pub grub give this bar a British flavor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7026,
        store_longitude: -73.9337,
        total_orders: 854.56,
        neighborhood: 'East Williamsburg',
        keywords:
            'Burger, Mexican, Salad, Nachos, Dessert, Shrimp Cocktail, Chili Con Carne, Beer, Tex-Mex, Delicious',
        annual_dol: 1108.16,
        description:
            '"Tex Mex coming soon to Bushwick. Brought to you by lifelong servers from OKC, Mexico, and NYC. We get happiness from seeing other people happy. Come and let us treat you right."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -74.01,
        total_orders: 3600.98,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Italian, Wine, Meatball, Delicious, Hotel, Impeccable, Rustic, Quaint, Urban, Premium',
        annual_dol: 3757.1,
        description: 'TriBeCa hot spot showcasing rustic Italian cuisine in a lively atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.5525,
        total_orders: 3368.53,
        neighborhood: '',
        keywords:
            'Cheesecake, Chocolate Cake, Burger, Dessert, Salad, Coffee, Wine, Italian, Chinese, Caesar Salad',
        annual_dol: 3668.45,
        description:
            'American chain restaurant offering sizable portions from a vast menu including signature cheesecake',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.702,
        store_longitude: -74.0107,
        total_orders: 46.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegan, Vegetarian, American, Delicious, British, Fun, Iconic, Great, Diverse, Unique',
        annual_dol: 191.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -74.0131,
        total_orders: 159.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Mexican, Steak, Salad, Beer, Italian, Wine, American, Organic, Casual, Local',
        annual_dol: 653.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.701,
        store_longitude: -73.914,
        total_orders: 1374.07,
        neighborhood: 'Bushwick',
        keywords: 'Tacos, Beer, Wine, Warm, Ale, Clean, Spacious, Casual, Great, Cultural',
        annual_dol: 1403.22,
        description:
            'Cash-only joint offering a big beer selection & mixed drinks in pint glasses amid old-school decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7314,
        store_longitude: -74.0047,
        total_orders: 166.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Warm, Luxurious, Delicious, Brazilian, Exotic, Friendly, Intimate, Homey, Authentic, Classic',
        annual_dol: 198.82,
        description:
            'Classic big-flavored Brazilian dishes like feijoada come out of a small kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -73.6054,
        total_orders: 198.64,
        neighborhood: '',
        keywords:
            'Vegetarian, Chicken Lollipop, Kebab, Chicken Tikka Masala, Vodka, Wine, Beer, Nutritious, Pakistani, Indian',
        annual_dol: 256,
        description:
            'Northern Indian cuisine with wine & cocktails plus lunch & Sunday dinner buffets in a refined space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -74.0069,
        total_orders: 3173.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Salad, Wine, Tequila, Breakfast Burrito, Delicious, Cozy, Warm, French',
        annual_dol: 3274.03,
        description:
            'Hot spot serving New American fare (including vegetarian-friendly choices) & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8077,
        store_longitude: -73.6577,
        total_orders: 1201.27,
        neighborhood: '',
        keywords: 'Hotel, Great',
        annual_dol: 1280.42,
        description:
            "events at the Nassau Veterans Memorial Coliseum.The warmly decorated rooms feature free Wi-Fi, flat-screen TVs, as well as minifridges, microwaves and Keurig coffeemakers. Suites add living areas with pull-out couches. Room service is available.Parking is complimentary. Other amenities include a gym, an indoor pool and a hot tub. There's also a restaurant and a bar. Breakfast is available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9932,
        total_orders: 351.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Wine, Irish, Hotel, Contemporary, Warm, American, Friendly, Casual',
        annual_dol: 1434.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -74.0088,
        total_orders: 2207.4,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Chinese, Gourmet, Trendy, Upscale, Chili Chicken, Classy, Sweet, Fresh, Filet Mignon',
        annual_dol: 2272.8,
        description:
            'Noodles, dumplings & other upscale Chinese fare served by white-jacketed waiters in posh space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.9979,
        total_orders: 343.87,
        neighborhood: 'Little Italy',
        keywords:
            'Lasagna, Salad, Italian, Wine, Flavorful, Martini, Classy, Homemade, Caesar Salad, Casual',
        annual_dol: 374.21,
        description:
            'Little Italy eatery supplying the standard red-sauce pastas & entrees along with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6504,
        store_longitude: -74.0089,
        total_orders: 31.64,
        neighborhood: 'Sunset Park',
        keywords:
            'Burger, Steak, Pizza, Mexican, Salad, Beer, Italian, Coffee, Wine, South American',
        annual_dol: 45.61,
        description:
            'Globally influenced American comfort food, including breakfast, in a cozy, brick-lined space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8103,
        store_longitude: -73.6251,
        total_orders: 2616.89,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Vegetarian, Salad, Shrimp Cocktail, Wine, French, Beer, Organic',
        annual_dol: 2672.41,
        description:
            'Parisian-inspired decor & classic bistro fare meet in an airy French eatery with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6671,
        store_longitude: -73.842,
        total_orders: 573.41,
        neighborhood: 'Lindenwood/Howard Beach',
        keywords:
            'Chicken Parmigiana, Salad, Pizza, Shrimp Cocktail, Italian, Meatball, Wine, Fried Calamari, Thai, Exquisite',
        annual_dol: 590.09,
        description:
            'Relaxed, white-tablecloth mini-chain branch offering family-style Italian favorites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5674,
        store_longitude: -74.1125,
        total_orders: 131.3,
        neighborhood: 'New Dorp/New Dorp Beach/#210',
        keywords: 'Burger, Steak, Beer, Casual, American, Local, Classic, Lively, Handcrafted',
        annual_dol: 505.51,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7178,
        store_longitude: -73.9974,
        total_orders: 16.05,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Chicken Parmigiana, Shrimp Cocktail, Meatball, Italian, Spicy, Bruschetta, Legendary, Casual, Fresh',
        annual_dol: 65.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7196,
        store_longitude: -73.9951,
        total_orders: 804.28,
        neighborhood: 'Little Italy',
        keywords: 'Tacos, Tequila, Wine, Cozy, Nachos, Friendly, Perfect, Fun, Cool, Caribbean',
        annual_dol: 3287.64,
        description:
            'Funky, tropically inspired bar serving Caribbean-style bites, along with cocktails, wine & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7458,
        store_longitude: -73.9035,
        total_orders: 130.2,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, Delicious, Gourmet, Spacious, Fun, Hotel, Clean, Fancy, Irish, Great',
        annual_dol: 213,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8277,
        store_longitude: -73.9258,
        total_orders: 9747.4,
        neighborhood: 'Concourse',
        keywords: 'Rooftop, Burger, Beer, Wine, American, Perfect, Friendly, Casual',
        annual_dol: 25774.96,
        description:
            'Baseball fans grab brews, burgers & sandwiches before & after games at nearby Yankee Stadium.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7806,
        store_longitude: -73.9585,
        total_orders: 172.32,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Salad, Wine, Organic, French, Upscale, Asian, Warm, Omelette',
        annual_dol: 704.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.71,
        store_longitude: -73.8469,
        total_orders: 144.01,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Pizza, Italian, Delicious, Elegant, Asian, Sweet, Friendly, Casual, American, Caribbean',
        annual_dol: 588.66,
        description:
            '"UNDER NEW OWNERSHIP - Chef Yvan Lemoine and Rebecca Sussman . We are excited to welcome you back to My Kitchen! 2022 event and wedding season is open and the calendar is filling up fast! Call us to view the space and book for your upcoming wedding, sweet 16, Bat/Bar Mitzvah, baby shower or birthday."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7419,
        store_longitude: -73.9188,
        total_orders: 406.9,
        neighborhood: 'LIC/Sunnyside/Flushing/Woodside',
        keywords:
            'Peruvian, Wine, Ale, Healthy, Delicious, Latin, Traditional, American, Casual, Modern',
        annual_dol: 831.64,
        description:
            'Snug, homey eatery featuring spit-roast chicken & other traditional dishes, plus wine & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7452,
        store_longitude: -73.9053,
        total_orders: 14.06,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Milkshake, Irish, American, Friendly, Casual, Fancy, Local, Fine, Diverse',
        annual_dol: 21.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7076,
        store_longitude: -73.9209,
        total_orders: 1668.43,
        neighborhood: 'Bushwick',
        keywords:
            'Salad, Thai, Dessert, Spicy, Fried Chicken, Sweet, Asian, Succulent, Authentic, Modern',
        annual_dol: 3709.82,
        description:
            'Modern Thai restaurant & bar offering up small plates (kub klaem) in an industrial-chic space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7048,
        store_longitude: -74.0067,
        total_orders: 261.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegetarian, Burger, Vegan, Steak, Salad, Flavorful, Healthful, Ale, Delicious, Clean',
        annual_dol: 313.36,
        description:
            'Fresh produce & locally sourced meats lead the list of ingredients at this popular American spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7196,
        store_longitude: -74.0041,
        total_orders: 4394.61,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Salad, Mexican, Wine, Meatball, Beer, Nachos, Organic, American, Caesar Salad',
        annual_dol: 4535.34,
        description:
            'Original cocktails & American fare with modern twists served in dimly lit digs with a central bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7187,
        store_longitude: -74.0059,
        total_orders: 424.24,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Tacos, Mexican, Japanese, Hotel, American, Latin, Authentic, Casual, Traditional, Fantastic',
        annual_dol: 563.92,
        description:
            '"Casa Carmen is the new venture of a Mexican family lead by renown chef Carmen Titita Ramirez Degollado and her grandsons. Be a part of this exciting new project to bring a taste of traditional Mexican recipes and hospitality to the heart of Tribeca."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -74.0034,
        total_orders: 831.52,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Beer, Meatball, Wine, Karaoke, Rum, Fun, Hotel, Awesome, American',
        annual_dol: 2919.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -73.9969,
        total_orders: 483.36,
        neighborhood: 'Little Italy',
        keywords:
            'Steak, Pizza, Italian, Vodka, Southern, Contemporary, Casual, Traditional, Authentic, Ample',
        annual_dol: 504.32,
        description:
            'Little Italy venue serving traditional Italian plates from the old country & offers an ample garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -74.0021,
        total_orders: 5758.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Karaoke, Trendy, Hip, Fun, Cozy, Friendly, Great, Casual',
        annual_dol: 6222.91,
        description:
            'Lounge with 18 private karaoke rooms, cocktail bar & around 80,000 songs in multiple languages.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -73.6351,
        total_orders: 809.94,
        neighborhood: '',
        keywords:
            'Lasagna, Wine, Italian, Pizza, Delicious, Bread Pudding, Roast Beef, Polished, Romantic, Classy',
        annual_dol: 863.78,
        description:
            'Traditional Italian dishes served in a sleek, contemporary dining room with a romantic atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7339,
        store_longitude: -74,
        total_orders: 2098.06,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Karaoke, Rich, Beer, Legendary, Fun, Vibrant, Awesome, Premium, Fantastic, Classic',
        annual_dol: 2567.93,
        description:
            'Tuesday night karaoke, drag spectaculars & other events drive this local, long-standing gay bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -74.0037,
        total_orders: 249.45,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Classic, American, Iconic, Irresistible, Perfect, Intimate, Unique',
        annual_dol: 1019.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7373,
        store_longitude: -74.0018,
        total_orders: 202.99,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Mediterranean, Spicy, Casual',
        annual_dol: 829.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.9186,
        total_orders: 2291.95,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Wine, Beer, Coffee, Stylish, Relaxed, Comfortable, Spicy, Jamaican, Belgian, American',
        annual_dol: 2424.12,
        description:
            'Stylish yet relaxed bar offering fine wines & 150+ craft brews, along with cheese & meat plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.713,
        store_longitude: -73.9051,
        total_orders: 134.2,
        neighborhood: 'Flushing/Ridgewood/Maspeth',
        keywords: 'Thai, Sweet, Western, Casual, Amazing, Chill',
        annual_dol: 137.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.97,
        total_orders: 784.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Salad, Pizza, Italian, Beer, Spicy, Delicious, Bruschetta, Homemade, Modern',
        annual_dol: 818.44,
        description:
            'Buzzy destination serving Italian fare & wood-fired pizza in a mellow, white-tablecloth setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7716,
        store_longitude: -73.9615,
        total_orders: 1177.42,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Tacos, Salad, Wine, Delicious, French, American, Classic, Cozy, Spectacular',
        annual_dol: 1341,
        description:
            'Seafood, steak & classic sides are the focus at this 2-level restaurant with vaulted brick ceilings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7622,
        store_longitude: -73.9599,
        total_orders: 714.51,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Japanese, Wine, Spicy, Beer, Chinese, Asian, Delicious, Thai, Fusion',
        annual_dol: 761.47,
        description:
            'Relaxed eatery with a varied menu of Asian classics, from sushi & teriyaki to pad Thai & fried rice.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6351,
        store_longitude: -73.6879,
        total_orders: 803.21,
        neighborhood: '',
        keywords: 'Golfclub, Fresh',
        annual_dol: 1457.89,
        description:
            'The Seawane Club is a private member owned golf and country club in Hewlett Harbor, New York. It contains an 18 hole golf course, 8 tennis courts, 2 pickleball courts, a basketball court, a swimming ...',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.9856,
        total_orders: 5384.74,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Beer, Wine, Salad, Chicken Caesar Salad, Tasty, Ale, Upscale, Spicy, French Onion Soup',
        annual_dol: 5434.7,
        description:
            'Airy eatery/bar offering a full menu of American & Irish fare, an extensive beer list & lots of TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.9921,
        total_orders: 2695.87,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Vodka, Burger, Irish, American, Modern, Classic, Local, Casual, Creative',
        annual_dol: 2834.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -74.0045,
        total_orders: 10489.8,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Burger, Whiskey, Pizza, Salad, Coffee, Margarita, Stylish, Elegant, Hotel',
        annual_dol: 10712.38,
        description:
            "the Empire State Building.Understated rooms feature curated minibars, free Wi-Fi and flat-screen TVs, plus Nespresso machines and guitars. Suites add separate living areas and wet bars. Goldfish, and loaner iPads, iPods and laptops are available on request. Room service is offered.Seasonal loaner bikes are complimentary. Dining options include a sophisticated restaurant with vaulted ceilings, a guest lounge, and a stylish bar. There's also an exercise room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7032,
        store_longitude: -74.0117,
        total_orders: 141.06,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Salad, Coffee, Italian, Roast Beef, Korean, American, Sustainable, Casual, Fresh',
        annual_dol: 598.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7224,
        store_longitude: -74.0095,
        total_orders: 127.63,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Wine, Vegan, Beer, Chicken Tikka Masala, Dessert, Upscale, Delicious, Chilean, Polished',
        annual_dol: 201.71,
        description:
            'Pan-Indian plates, craft cocktails & wine are served at this sprawling haunt with a wraparound bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7695,
        store_longitude: -73.8661,
        total_orders: 22418.56,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Steak, Burger, Pizza, Salad, Coffee, Wine, Beer, Hotel, American, Recommended',
        annual_dol: 22894.25,
        description:
            'LaGuardia Airport is a civil airport in East Elmhurst, Queens, New York City. Covering 680 acres as of August 24, 2022, the facility was established in 1929 and began operating as a public airport in 1939. It is named after former New York City mayor Fiorello La Guardia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -73.9528,
        total_orders: 2659.09,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Tequila, Wine, Beer, Scotch, Rustic, Cozy, Classy, Authentic, Perfect, Intimate',
        annual_dol: 2833.8,
        description:
            'Brick-walled corner watering hole that offers plenty of beers on tap, long tables & backyard garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7428,
        store_longitude: -73.9535,
        total_orders: 33.51,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Tacos, Mexican, Vegetarian, Tex-Mex, Dessert, Rustic, Casual, Classic, Great',
        annual_dol: 159.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.9506,
        total_orders: 177.12,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Burger, Lobster, Steak, American, Casual, Cool, Global, Mysterious',
        annual_dol: 314.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8008,
        store_longitude: -73.9652,
        total_orders: 1660.57,
        neighborhood: 'Manhattan Valley/UWS',
        keywords: 'Salad, Cozy, Dominican, Caribbean, Fun, Lively, Colorful, Casual, Latin, Fresh',
        annual_dol: 1894.16,
        description:
            'Dominican eats & fruit-flavored cocktails in a colorful space with a tropical ceiling mural.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7286,
        store_longitude: -73.9874,
        total_orders: 282.73,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Beer, French, Italian, Organic, American, Modern, Homemade, Seasonal, Casual',
        annual_dol: 368.55,
        description:
            'Brick-walled brasserie with an intimate feel serving updated French dishes & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.732,
        store_longitude: -73.9838,
        total_orders: 25.9,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Thai, Salad, Coffee, Green Curry, Clean, Beer, Casual, Traditional, Sophisticated, Local',
        annual_dol: 105.87,
        description:
            'A selection of traditional Thai dishes, such as curry & pad khing, served in sophisticated environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7379,
        store_longitude: -73.9878,
        total_orders: 344.57,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Mexican, Tacos, Beer, Margarita, Wine, Tex-Mex, Hotel, Trendy, Mediterranean, Chic',
        annual_dol: 442.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7495,
        store_longitude: -73.969,
        total_orders: 1271.67,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Burger, Coffee, Warm, Fabulous, Iconic, Cozy, Classic, Great, Wonderful',
        annual_dol: 1368.48,
        description:
            'Sophisticated rooms and suites feature floor-to-ceiling windows with city views, Wi-Fi (fee), flat-screen TVs and coffeemakers. Upgraded rooms and suites add sitting areas with leather chairs and iPod docks. Some rooms offer access to a club lounge (fee) with breakfast and snacks.Amenities include a contemporary American restaurant, a bar and a coffee shop, in addition to a fitness center with an indoor tennis court.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 9219.11,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Beer, Legendary, Polished, New Zealand, Bloody Mary, Perfect, Clam Chowder',
        annual_dol: 9618.82,
        description:
            'Venerated, iconic eatery serving raw oysters, pan roasts & chowders in a bustling, vaulted setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.9747,
        total_orders: 686.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Cheesesteak, Steak, Vegetarian, Salad, Vegan, Italian, Gluten Free, Coffee, Spicy, Philly Cheesesteak',
        annual_dol: 2588.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8178,
        store_longitude: -73.9422,
        total_orders: 184.74,
        neighborhood: "Harlem/Strivers' Row",
        keywords:
            'Mexican, Champagne, Beer, Salad, Upscale, Mimosa, Cozy, Rum, Delicious, American',
        annual_dol: 208.51,
        description: '"Restaurant Lounge"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9687,
        total_orders: 5499.12,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Beer, Cheesesteak, Wine, Philly Cheesesteak, American, Premium, Classic, Awesome, Authentic',
        annual_dol: 6368.37,
        description:
            'Bi-level tavern with a rotating roster of taps & many bottles plus an accompanying pub food menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9699,
        total_orders: 692.87,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Sushi, Beer, Luxurious, Hotel, Romantic, Fancy, Asian, Korean, American',
        annual_dol: 1308.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9921,
        total_orders: 944.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Steak, Vegan, Tasty, Beer, Salad, Wine, Upscale, American, Awesome',
        annual_dol: 1042.26,
        description:
            'Straightforward sports bar near Port Authority offering pub fare along with plenty of beer & TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.9865,
        total_orders: 5397.53,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, French, French Onion Soup, Organic, Fried Rice, Screwdriver, Spicy, American, Bellini, Casual',
        annual_dol: 5414.16,
        description:
            '"Brooklyn Chop House Steakhouse Times Square plays on the double meaning of the word "Chop". Chop Steak to Chop Sticks. Marrying what made him a well-known name in the industry with Philippe Chow. Stratis has taken the best of those two menus and married them with Steakhouse classics like dry-aged 30-45 day Prime Steaks including Porterhouse. For 2, NY Strip, and Bone-In Rib Eye. The game-changer and the uniqueness of the BCH menu came home with taking the staples of his father\'s Hilltop Diner such as the Pastrami, The Reuben, Philly Cheesesteak, Bacon Cheeseburger & the French Onion Soup and turning them into dumplings. This has driven the BCH concept viral on social media as the FIRST to reimagine the deli/diner sandwiches into dumplings."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9869,
        total_orders: 5191.59,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords: 'Pizza, Beer, Coffee, Wine, Ale, American, Perfect, Sophisticated, Great, Casual',
        annual_dol: 5301.75,
        description:
            'Mellow neighborhood meeting place for Dumbo locals serving wines, beers & spirits.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.702,
        store_longitude: -73.9895,
        total_orders: 815.05,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Burger, Vegetarian, Vegan, Hotdog, Salad, Breakfast Burrito, Argentinian, French Toast, Avocado Toast, Ale',
        annual_dol: 848.61,
        description:
            'Fresh produce & locally sourced meats lead the list of ingredients at this popular American spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7884,
        store_longitude: -73.7295,
        total_orders: 115.79,
        neighborhood: '',
        keywords:
            'Coffee, Beer, Salad, French Toast, Warm, Mediterranean, Welcoming, Great, French, American',
        annual_dol: 283.67,
        description:
            '"A neighborhood restaurant with a beautiful, warm and welcoming atmosphere. A favorite meeting place for friends, with a great selection of always freshly made modern Israeli, Mediterranean and Middle Eastern dishes. Rothchilds is known for both unique and familiar dishes and a menu to please every taste."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6845,
        store_longitude: -73.9922,
        total_orders: 659.66,
        neighborhood: 'Carroll Gardens/Brooklyn Heights/Cobble Hill',
        keywords:
            'Vegetarian, Tasty, Nutritious, Italian, Chicken Parmigiana, Champagne, Delicious, Organic, Friendly, Mediterranean',
        annual_dol: 759.94,
        description:
            'Mediterranean eatery serving meats, seafood & white-sauce dishes in warm, brick-walled dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7799,
        store_longitude: -73.9501,
        total_orders: 28.52,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegetarian, Salad, Wine, Tasty, Beer, Elegant, Indian, Tandoori Chicken, Casual, Eclectic',
        annual_dol: 57.8,
        description: '"FINE INDIAN CUISINE WITH SPECIAL KABOB COOKED IN SPECIAL CLAY OVEN"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6827,
        store_longitude: -73.9502,
        total_orders: 130.88,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Beer, Coffee, Intimate, Sweet, Casual',
        annual_dol: 534.98,
        description: '"Cocktails and oysters. Located in Bedstuy, Brooklyn."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8061,
        store_longitude: -73.9531,
        total_orders: 7360.51,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Mexican, Tacos, Beer, Wine, Coffee, Fish Tacos, Upscale, Delicious, Polished',
        annual_dol: 8188.33,
        description:
            'Intimate eatery featuring upscale Mexican cuisine & agave-focused, craft cocktails in a chic venue.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8521,
        store_longitude: -73.6486,
        total_orders: 1234.11,
        neighborhood: '',
        keywords: 'Wine, Fun, Wonderful, Great, Excellent',
        annual_dol: 1287.61,
        description: '"Yacht Club/Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7254,
        store_longitude: -74.003,
        total_orders: 1090.39,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Lobster, Sushi, Steak, Salad, Shrimp Cocktail, Dessert, Gluten Free, Spicy, Beer, Fried Chicken',
        annual_dol: 1113.52,
        description:
            'Iconic, late-night brasserie featuring seafood, American dishes & cocktails in a convivial setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7216,
        store_longitude: -73.9971,
        total_orders: 1122.9,
        neighborhood: 'Nolita',
        keywords:
            'Burger, Hamburger, Salad, Coffee, Cosmopolitan, Wine, Spicy, Italian, Mediterranean, Delicious',
        annual_dol: 1175.6,
        description:
            'Relaxed eatery featuring Israeli cuisine, including falafel, shawarma & hummus, plus wine & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7729,
        store_longitude: -73.8952,
        total_orders: 113.6,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City/Flushing',
        keywords:
            'Pizza, Salad, Delicious, Cozy, Warm, Perfect, Welcoming, Friendly, Sweet, Premium',
        annual_dol: 121.15,
        description:
            '28 lanes of bowling with laser light shows plus a sports bar, video game arcade & casual dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7584,
        store_longitude: -73.8923,
        total_orders: 61.74,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Burger, Salad, Steak, Wine, Ale, Beer, Tasty, Italian, Buffalo Chicken Salad, Cozy',
        annual_dol: 252.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.9905,
        total_orders: 1.23,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Vegetarian, Salad, Butter Chicken, Friendly, Tandoori Chicken, Casual, Indian, Authentic, Exotic, Local',
        annual_dol: 5.01,
        description: '"Authentic Indian Restaurant & Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -74.0062,
        total_orders: 175.09,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Pizza, Coffee, Wine, Spacious, New Zealand, Friendly, Negroni, Incredible, Classic, Casual',
        annual_dol: 715.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7728,
        store_longitude: -73.9786,
        total_orders: 3952.21,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Salad, Italian, Beer, French Toast, Rustic, French, American, Modern, Elegant',
        annual_dol: 4036.07,
        description:
            'Restored Central Park icon for American fare in an elegant setting with courtyard & garden seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7684,
        store_longitude: -73.9817,
        total_orders: 226.44,
        neighborhood: '',
        keywords: 'Hotel, Trendy, Western, Spacious, Spectacular, Clean',
        annual_dol: 925.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -73.6081,
        total_orders: 283.6,
        neighborhood: '',
        keywords:
            'Wine, Delicious, Italian, Greek, Mediterranean, Ale, Spectacular, Fabulous, Exceptional, Friendly',
        annual_dol: 311.6,
        description:
            '"Welcome to Plori Restaurant, a chic and modern eatery that offers a delicious array of Greek cuisine. Our inspiration comes from this Greek fishing village, where the people wait on the shore for the fishing boats to return. When they see the front of the boat—the plori—they know the daily catch is coming. We want to take you to that little Greek village."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5739,
        store_longitude: -74.1173,
        total_orders: 355.12,
        neighborhood: 'New Dorp',
        keywords:
            'Steak, Burger, Tacos, Tequila, Mexican, Gluten Free, Bourbon, Tex-Mex, Spicy, Bbq',
        annual_dol: 915.1,
        description:
            '"Ho\'brah: a taco joint is stylish, surf-themed restaurant and bar specializing in California-style tacos, burritos, Mexican small plates and cocktails. We thought long and hard about what we loved most about tacos. ‘Bright flavors’ and ‘fresh ingredients’ were popular choices and soon morphed into ‘healthy lifestyle, summer, sun, and surf’. We shared tales of favorite taco joints we’d visited on Vacations around the world; small taco trucks on the Mexican border in San Diego; wood-paneled surf-taco joints yards from the ocean in Florida; oversized taco emporiums in Hawaii; and one truly shanty fish shack turned taco stand on the Gulf Coast."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8246,
        store_longitude: -73.6677,
        total_orders: 504.1,
        neighborhood: '',
        keywords:
            'Golfclub, Comfortable, Fun, Memorable, Great, Terrific, Fine, Local, Recommended',
        annual_dol: 616.99,
        description:
            'Golf club featuring an 18-hole course & a 9-hole course, plus a practice area, a shop & mini-golf.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6916,
        store_longitude: -73.8207,
        total_orders: 403.34,
        neighborhood: 'South Richmond Hill',
        keywords: 'Kebab, Spicy, Dessert, Chinese, Bbq, Caribbean, Healthy, Classic, Local, Indian',
        annual_dol: 1519.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9942,
        total_orders: 1207.26,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Stylish, Hotel, Tasty, Friendly, Colorful, Mediterranean',
        annual_dol: 1343.2,
        description:
            '-minute walk from Penn Station.Streamlined rooms, some with colorful accent walls, feature free Wi-Fi and flat-screen TVs, plus microwaves, minifridges, and tea and coffeemaking equipment. Suites add terraces and sitting areas.A breakfast buffet is complimentary. Other amenities include a low-key bar, a lobby lounge and a 24/7 fitness center, plus event space. Parking is available for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8026,
        store_longitude: -73.9385,
        total_orders: 3379.61,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Vegan, Healthy, Delicious, Friendly, Natural, Hotel, Local',
        annual_dol: 8877.72,
        description: '"The Greatest Wealth... Is HEALTH."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -73.9834,
        total_orders: 90.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Burger, Beer, Pizza, Burritos, Wine, Delicious, Organic, Asian',
        annual_dol: 369.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -74.0095,
        total_orders: 4931.57,
        neighborhood: 'Hudson Square',
        keywords: 'Burger, Salad, Beer, Wine, Spicy, Funky, Ale, Rum, Cozy, Legendary',
        annual_dol: 5036.21,
        description:
            'Beers & bar bites are on the menu at this popular joint in a landmarked, circa-1817 building.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6572,
        store_longitude: -74.0065,
        total_orders: 330.85,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Wine, Beer, Pizza, Tasty, Golfclub, Chinese, French, Great, Casual',
        annual_dol: 1352.4,
        description:
            '"The Carreau Club is your home for all things pétanque in NYC! Court reservations available for groups of all sizes. INDOOR COURTS OPENING IN INDUSTRY CITY THIS OCTOBER!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7393,
        store_longitude: -74.0067,
        total_orders: 6162.64,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Lobster, Salad, Italian, French, Trendy, Vintage, American, Asian, Traditional',
        annual_dol: 6885.79,
        description:
            'Theatrical evocation of Paris featuring bistro food & high-energy atmospherics from a chic crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7439,
        store_longitude: -73.9227,
        total_orders: 150,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Sushi, Salad, Japanese, Beer, Wine, Spicy, Asian, Ramen, Casual, Local',
        annual_dol: 490.78,
        description:
            'This neighborhood stalwart for sushi & other Japanese favorites has a full bar with a sake menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7526,
        store_longitude: -73.9876,
        total_orders: 42.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Tacos, Vegan, Salad, Mexican, Flavorful, Tasty, Margarita, Beer, Organic, Spicy',
        annual_dol: 108.76,
        description:
            '"Inspired by a healthy lifestyle where beach, surf and sun mix with fresh local ingredients, Taco Dumbo offers a laid back vibe and flavorful food for all."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.5522,
        total_orders: 485.76,
        neighborhood: '',
        keywords:
            'Burger, Steak, Salad, Chocolate Cake, Shrimp Cocktail, Italian, Wine, Tasty, Beer, French',
        annual_dol: 506.82,
        description:
            '"We specialize in bar food & Italian cuisine! If you don’t believe us, just try the chicken parm or calamari! The Bar always stays open after the kitchen closes!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -73.9996,
        total_orders: 5046.13,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Burger, Whiskey, Tasty, Beer, Pizza, Hamburger, Bourbon, Delicious, Warm, Comfortable',
        annual_dol: 5227.81,
        description:
            'Local saloon chasing whiskies with pickle juice & other drinks in a laid-back space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7188,
        store_longitude: -74.0045,
        total_orders: 617.47,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Italian, Pizza, Wine, Delicious, Upscale, Warm, Rustic, Romantic, Meatball',
        annual_dol: 687.52,
        description:
            'Modern, casual restaurant & wine bar with a creative Italian menu featuring pizza, pasta & omelets.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -74.0093,
        total_orders: 278.63,
        neighborhood: 'Hudson Square',
        keywords: 'Burger, Steak, Salad, Wine, Beer, Warm, Cozy, Quaint, Comfortable, Swiss',
        annual_dol: 303.66,
        description:
            'An American menu highlighting seasonal produce served in cozy digs with salvaged wood decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7072,
        store_longitude: -73.9213,
        total_orders: 5907.61,
        neighborhood: 'Bushwick',
        keywords: 'Vodka, Whiskey, Tequila, Beer, Gin, Cozy, Perfect, Fresh, Quirky, Great',
        annual_dol: 6032.96,
        description:
            'A tattooed crew runs this corner bar serving draft beers & Mason-jar cocktails to a local crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7028,
        store_longitude: -73.9297,
        total_orders: 1953.87,
        neighborhood: 'Bushwick',
        keywords: 'Whiskey, Wine, Beer, Tasty, Pizza, Premium, Cozy, Comfortable, Great, Chill',
        annual_dol: 2079.3,
        description:
            'Whiskey flights, beer & snacks in a simple, dark bar with flat-screen TVs & a neighborhood vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6571,
        store_longitude: -73.6436,
        total_orders: 426.84,
        neighborhood: '',
        keywords:
            'Lobster, Juicy, Beer, Warm, Fried Clams, Vibrant, Friendly, Outstanding, Fresh, Great',
        annual_dol: 509.88,
        description:
            '"At Red Crab Juicy Seafood– customers can make their own seafood combo. All you have to do is choose any ½ LB (minimum two items) and there you go! You are served corn and potato with it. This offer has a selection of blue crab, shrimp, snow crab legs, crawfish, clams, green mussel, black mussel, and sausage. Everything is reasonably priced in a warm and friendly atmosphere. Our goal is to deliver an experience with an outstanding variety of seafood and vibrant energy."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8325,
        store_longitude: -73.6984,
        total_orders: 174.88,
        neighborhood: '',
        keywords:
            'Kebab, Lobster, Salad, Mediterranean, Turkish, Greek, Greek Salad, Stylish, Fusion, Yummy',
        annual_dol: 204.32,
        description:
            'Local Mediterranean chain serving kebabs, pita sandwiches, entrees & more in a stylish setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.753,
        store_longitude: -73.988,
        total_orders: 14924.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Salad, Wine, Chinese, Spicy, Asian, Romantic, Filet Mignon, Fusion, Contemporary, Dope',
        annual_dol: 15471.61,
        description: '"Asian Fusion Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.9859,
        total_orders: 2000.74,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Burger, Pizza, Salad, Beer, Asian, Clean, Irish, Robust, American, Amazing',
        annual_dol: 4664.22,
        description:
            'Pub grub is offered along with a robust selection of draft beers at this old-school, bi-level venue.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.9923,
        total_orders: 792.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Spacious, Outstanding, Superb, Memorable, Exceptional, Rooftop',
        annual_dol: 844.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8405,
        store_longitude: -73.7177,
        total_orders: 4.92,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Delicious, Bellini, Homemade, Fresh, Caesar Salad, Casual, Signature',
        annual_dol: 20.1,
        description: '"Family Owned Pizzeria Established 1959"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7338,
        store_longitude: -74.005,
        total_orders: 4373.34,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chocolate Cake, Steak, Tasty, Italian, Wine, Delicious, Warm, Trendy, Comfortable, Classy',
        annual_dol: 4466.13,
        description:
            'Italian small plates are matched by an extensive wine list at this bi-level restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.6476,
        total_orders: 442.19,
        neighborhood: '',
        keywords: 'Burger, Beer, Wine, Ale, Irish, American, Seasonal, Casual, Clean, Perfect',
        annual_dol: 470.58,
        description:
            'Local standby offering burgers, wings & other American grill fare, plus a long bar with TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7502,
        store_longitude: -73.6418,
        total_orders: 22.21,
        neighborhood: '',
        keywords: 'Local, Casual, Great',
        annual_dol: 90.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6571,
        store_longitude: -73.6716,
        total_orders: 1183.33,
        neighborhood: '',
        keywords: 'Tequila, Vodka, Beer, Pizza, Tasty, Rum, Nachos, Comfortable, Relaxed, Fun',
        annual_dol: 1472.13,
        description:
            'Basic theater with stadium-style seating & snack bar, showing new releases & discounted matinees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6563,
        store_longitude: -73.6718,
        total_orders: 22.94,
        neighborhood: '',
        keywords:
            'Garlic Bread, Burger, Pizza, Sushi, Salad, Italian, Vodka, Spicy, Homemade, Friendly',
        annual_dol: 100.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7397,
        store_longitude: -74.0078,
        total_orders: 613.91,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Steak, Italian, Wine, Tequila, Upscale, Stylish, Spicy, Bourbon, Margarita, Organic',
        annual_dol: 2509.48,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7368,
        store_longitude: -73.6129,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Salad, Tasty, Coffee, Delicious, Margarita, Italian, Martini, Perfect, Sangria, Great',
        annual_dol: 4.38,
        description:
            '"Starting November 19, the NM Cafe will be open for Dine-in for both full and quick service with limited seating available. Our menu is also available online for delivery via Postmates or pick-up via the Neiman Marcus website."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7754,
        store_longitude: -73.9533,
        total_orders: 1716.56,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Sushi, Japanese, Dessert, Sashimi, Spicy, Yummy, Asian, Fusion, Tempura, Modern',
        annual_dol: 2830.32,
        description:
            'Familiar Japanese dishes, sushi & cocktails in a modern space with a neon-lit bar & outdoor seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7572,
        store_longitude: -73.6421,
        total_orders: 792.64,
        neighborhood: '',
        keywords:
            'Wine, Vodka, Margarita, Mexican, Spanish, Sangria, Eclectic, Triple Sec, Sweet, Refined',
        annual_dol: 813.69,
        description:
            'Sophisticated restaurant offering refined Spanish dishes, including paella, along with sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7754,
        store_longitude: -73.9563,
        total_orders: 369.07,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Lemon Meringue Pie, Wine, Lobster, Tasty, Coffee, Italian, French, Exquisite, Urban, Cool',
        annual_dol: 385.07,
        description:
            'Seafood & desserts plus variously prepared Prince Edward Island mussels in a casual setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.8409,
        total_orders: 137.73,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Burger, Hamburger, Lobster, Lobster Roll, Meatball, Shrimp Cocktail, Beer, American, Cuban',
        annual_dol: 562.99,
        description:
            '"Farm to Table Restaurant. Serves Every Day: Lunch, Dinner, Wine Bar Weekend Brunch, Outdoor Heated Patio"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7705,
        store_longitude: -73.9538,
        total_orders: 509.34,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Coffee, Wine, Cozy, American, Delicious, Clean, Casual, Greek, Sophisticated',
        annual_dol: 554.29,
        description:
            '"NYC\'s most sophisticated board game cafe- with hundreds of games to play or buy, craft cocktails and milkshakes, locally roasted espresso drinks, and a menu of elevated classics."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7766,
        store_longitude: -73.9525,
        total_orders: 238.02,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Scotch, Tasty, Fun, Great, Impressive, Rooftop, Casual',
        annual_dol: 1021.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6569,
        store_longitude: -73.839,
        total_orders: 30.65,
        neighborhood: 'Far Rockaway/Jamaica/Howard Beach',
        keywords:
            'Burger, Salad, Shrimp Cocktail, Roast Beef, French, Homemade, American, Belgian, Casual, Friendly',
        annual_dol: 51.73,
        description:
            'Spacious, old-school diner with traditional American & International fare, plus early bird specials',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8667,
        store_longitude: -73.8305,
        total_orders: 706.3,
        neighborhood: 'East Bronx/Baychester',
        keywords: 'Steak, Lobster, Mexican, Delicious, Beer, Italian, Juicy, Fun, Fresh, Great',
        annual_dol: 1281.46,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8389,
        store_longitude: -73.941,
        total_orders: 1984.67,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Burger, Vegan, Salad, Beer, Tasty, Wine, Ale, American, Delicious, Rum',
        annual_dol: 2322.78,
        description:
            'Relaxed hangout for American fare plus cocktails featuring house-infused liquors, beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7201,
        store_longitude: -73.8476,
        total_orders: 10.81,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Wine, Rich, Beer, Karaoke, Armenian, Hotel, Fun, High-End, Classic, Great',
        annual_dol: 44.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8349,
        store_longitude: -73.6237,
        total_orders: 59.19,
        neighborhood: '',
        keywords: 'Salad, Sushi, Japanese, Chinese, Spicy, Sashimi, Asian, Sweet, Casual, Clean',
        annual_dol: 76.32,
        description:
            'Wide array of sushi rolls & cocktails in a simple, snug room with dark-wood tables & tatami booths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7683,
        store_longitude: -73.9212,
        total_orders: 524.08,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Cuban, Wine, Delicious, Premium, Authentic, American, Great, Excellent, Latin, Casual',
        annual_dol: 641.45,
        description: '"Cuban Fusion, Restaurant and Lounge"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6749,
        store_longitude: -73.9813,
        total_orders: 2436.81,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Vodka, Gin, Rum, Comfortable, Sweet, Vintage, Awesome, Casual, Great',
        annual_dol: 2482.78,
        description:
            "Vintage decor & '90s rock posters set an easygoing mood at this mod taproom with a summer patio.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9189,
        total_orders: 534.52,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Filipino, Wine, Irish, Bbq, Chill, Great, Local, Casual',
        annual_dol: 2184.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -73.9429,
        total_orders: 3026.18,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Vodka, Beer, Moscow Mule, Russian, Spacious, Seasonal, Sweet, Classy, Relaxed, Lively',
        annual_dol: 3090.39,
        description:
            'Industrial-chic local bar with signature cocktails, craft beers & shots, weekend DJs & late closing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7632,
        store_longitude: -73.9596,
        total_orders: 308.63,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotdog, Tequila, Mexican, Steak, Beer, Vegetarian, Margarita, Wine, Mimosa, Spicy',
        annual_dol: 315.18,
        description:
            'Lively restaurant & tequila bar serving Mexican classics & a bottomless margarita brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7711,
        store_longitude: -73.9509,
        total_orders: 547.86,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Warm, Delicious, Elegant, Quaint, Greek, Mediterranean, Charming, Outstanding, Modern',
        annual_dol: 569.31,
        description:
            'Charming Athenian-style Greek eatery with a focus on elevated, appetizer-size meze dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7811,
        store_longitude: -73.8412,
        total_orders: 27.61,
        neighborhood: 'College Point/Flushing',
        keywords: 'Sushi, Salad, Dessert, Tasty, Gin, Asian, Modern, Friendly, Great, Casual',
        annual_dol: 112.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -74.007,
        total_orders: 199.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Steak, Spicy, Delicious, Thai, Japanese, Cozy, Hotel, Asian, Homey',
        annual_dol: 212.27,
        description:
            'Cozy venue serving classic & creative sushi along with Asian fusion fare & cocktails in chic digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8441,
        store_longitude: -73.8302,
        total_orders: 23.91,
        neighborhood: 'Pelham Bay',
        keywords: 'Burger, Hamburger, Steak, Pizza, Cajun, Cajun-Style, Beer, Wine, Margarita, Bbq',
        annual_dol: 97.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.786,
        store_longitude: -73.9765,
        total_orders: 449.9,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tequila, Flavorful, Mexican, Lemon Drop, Tacos, Margarita, Organic, Homemade, Lively, Fresh',
        annual_dol: 469.41,
        description:
            '"Tavern setting for Tex-Mex options including tacos, fajitas, & a flavorful margarita selection."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6325,
        store_longitude: -74.0109,
        total_orders: 18.28,
        neighborhood: 'Sunset Park',
        keywords: 'Chinese, Fresh, Casual, Local',
        annual_dol: 74.73,
        description: '"Cantonese Style Live Seafood Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -73.9236,
        total_orders: 651.61,
        neighborhood: 'Bushwick',
        keywords:
            'Champagne, Wine, Trendy, Legendary, Delicious, Vintage, Spectacular, American, Classy, Classic',
        annual_dol: 2455.06,
        description:
            '"From Company XIV, creators of NYC\'s most spectacular burlesque shows, a fusion of circus, cabaret, opera, dance, theatre and lavish design. SEVEN SINS NUTCRACKER ROUGE QUEEN OF HEARTS “ONE OF THE BEST THINGS TO DO IN NEW YORK ON ANY OCCASION” —Forbes Théâtre XIV is a boutique venue in the heart of Graffiti Alley in Bushwick, Brooklyn, where classically trained performers also host and bartend, seating guests and pouring immersive cocktails from a curated menu which also features absinthe, champagne and a selection of treats and sweets."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7301,
        store_longitude: -74.0036,
        total_orders: 43.71,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Flavorful, Champagne, Vodka, Salad, Tasty, Coffee, Organic, Delicious, Cozy',
        annual_dol: 125.91,
        description:
            'Easygoing restaurant specializing in feijoada & other Brazilian dishes, plus cachaça cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7375,
        store_longitude: -73.9549,
        total_orders: 846.05,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Coffee, Italian, Canadian, Beer, French Toast, French, Cozy, Spacious, American',
        annual_dol: 3458.39,
        description:
            'Italian meals are provided in an intimate library setting with a bar, live music & garden seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7073,
        store_longitude: -73.9396,
        total_orders: 699.87,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Wine, Aesthetic, Awesome, Local, Casual, Great, Signature',
        annual_dol: 730.21,
        description:
            'Cocktails & bar bites in a brightly decorated space featuring burlesque shows & a heated patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7315,
        store_longitude: -73.9543,
        total_orders: 549.93,
        neighborhood: 'Greenpoint',
        keywords: 'Mexican, Tacos, Tequila, Beer, Salad, Dessert, Fajitas, Casual, Awesome, Great',
        annual_dol: 561.6,
        description:
            'Warm, casual joint serving Mexican classics from fajitas to tacos plus frozen margaritas & beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9111,
        total_orders: 1171.66,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, Wine, Spanish, Ale, Coffee, American, Western, Irish, Local, Fresh',
        annual_dol: 1196.52,
        description:
            'Featuring pinball & board games, this exposed-brick haunt offers local ciders & beers on tap.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.9072,
        total_orders: 480.98,
        neighborhood: 'Astoria/Woodside',
        keywords: 'Burger, Hamburger, Pizza, Beer, American, Irish, Warm, Casual, Local, Fresh',
        annual_dol: 501.83,
        description:
            'Laid-back hangout featuring TVs, draft brews & elevated bar bites, plus weekday happy-hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.733,
        store_longitude: -74.01,
        total_orders: 38.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster Roll, Lobster, Burger, Salad, Vodka, Chinese, Gin, Homemade, Elegant, Vintage',
        annual_dol: 180.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7859,
        store_longitude: -73.9761,
        total_orders: 1589.81,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Burger, Wine, Irish, Ale, Gin, Spectacular, Awesome, Great, Traditional',
        annual_dol: 1623.55,
        description:
            'A standard neighborhood bar with pub grub, darts, a huge menu of craft brews & a beer club.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.743,
        store_longitude: -73.6182,
        total_orders: 39.98,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Organic, Local, Casual, Healthy',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7728,
        store_longitude: -73.9158,
        total_orders: 731.29,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Czech, Beer, French, Mediterranean, American, Spacious, Casual, Traditional, Classic',
        annual_dol: 895.06,
        description:
            'Czech beer & grilled bratwurst at a huge, family-friendly beer garden full of picnic tables & trees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6924,
        store_longitude: -73.8167,
        total_orders: 20.14,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords: 'Chinese, Asian, Casual, American',
        annual_dol: 82.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7099,
        store_longitude: -73.9374,
        total_orders: 2931.45,
        neighborhood: 'East Williamsburg',
        keywords: 'Tequila, Hotdog, Beer, Wine, Karaoke, Ale, Rum, Magical, Hotel, Funky',
        annual_dol: 3019.52,
        description:
            'Local bar with craft beer & shots, Mexican-American pub food & funky decor such as velvet paintings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7679,
        store_longitude: -73.9685,
        total_orders: 642.91,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Salad, Italian, Wine, Stylish, Modern, Sleek, Comfortable, Casual, Seasonal',
        annual_dol: 670.78,
        description:
            'This petite eatery with modern decor & a bar serves Italian fare, including gluten-free options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7235,
        store_longitude: -73.9504,
        total_orders: 168.8,
        neighborhood: 'Greenpoint',
        keywords: 'Vegetarian, Coffee, Wine, Beer, Thai, Ramen, Cozy, Asian, Delicious, Roti Canai',
        annual_dol: 330.94,
        description:
            'Cozy restaurant helmed by chef Bao Bao.offering Southeast Asian comfort food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6565,
        store_longitude: -73.6484,
        total_orders: 638.52,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Tequila, Steak, Vegetarian, Beer, Margarita, Enchiladas, Fajitas, Modern',
        annual_dol: 673.09,
        description:
            'Contemporary cantina featuring Mexican staples such as tacos, fajitas & enchiladas, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6188,
        store_longitude: -73.9314,
        total_orders: 132.94,
        neighborhood: 'Flatlands',
        keywords:
            'Vegetarian, Salad, Chocolate Mousse, Vietnamese, Nutritious, Thai, Delicious, Fried Rice, Belgian, Asian',
        annual_dol: 361.95,
        description:
            'A long menu of traditional Vietnamese dishes served in an unpretentious space with modern decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9907,
        total_orders: 1865.8,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Wine, Beer, Coffee, Ale, Karaoke, American, Bbq, Irish, Perfect, Great',
        annual_dol: 1905.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9897,
        total_orders: 6388.77,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Trendy, Spanish, Stylish, Iconic, Rooftop, Bright, Amazing, Sophisticated, Classic',
        annual_dol: 6524.32,
        description:
            'Times Square, and a 9-minute walk from Bryant Park.Sleek rooms and suites offer free Wi-Fi, flat-screen TVs and minifridges. Some rooms have balconies and floor-to-ceiling windows with city views. Room service is available.There’s a stylish Spanish restaurant, plus a trendy rooftop bar with city views. Other amenities include a convenience store, a gym, meeting and event space, and a business center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6635,
        store_longitude: -73.7191,
        total_orders: 121.24,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Gourmet, Salad, Burritos, Casual, Local, Premium, Perfect, Fresh',
        annual_dol: 495.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6277,
        store_longitude: -74.0738,
        total_orders: 1359.09,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Vegetarian, Pizza, Italian, Wine, Polished, Casual, Modern, Traditional, Classic, Local',
        annual_dol: 1664.83,
        description:
            '"Pastavino - Pasta & Wine - The quintessential Italian experience, drawing inspiration from the traditional casual yet satisfying trattorias & enotecas (wine bars) dotted throughout Italy. Our menu features classic well executed antipast, a large selection of salumi & formaggi, house made pastas, and entrees. The wine program features a large wine by the glass list, and a selection of 300+ labels by the finest producers in Italy."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.708,
        store_longitude: -74.0089,
        total_orders: 716.24,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Beer, Stylish, Rooftop, Creative, Classic, Local',
        annual_dol: 2338.35,
        description:
            'Stylish theater chain for new & classic films features cocktails & creative bites served seat-side.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9929,
        total_orders: 3797.49,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Coffee, Rooftop, American, Great',
        annual_dol: 3878.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8292,
        store_longitude: -73.7021,
        total_orders: 107.93,
        neighborhood: '',
        keywords: 'Pizza, Italian, Salad, Beer, Southern, Fine',
        annual_dol: 451.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7048,
        store_longitude: -74.0175,
        total_orders: 11302.88,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Beer, Wine, Romantic, Sweet, Amazing, Perfect, Intimate, Great, Fine, Casual',
        annual_dol: 40649.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7065,
        store_longitude: -74.0158,
        total_orders: 533.67,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Italian, Coffee, Wine, Spicy, Modern, Classic, Friendly, Great, Wonderful',
        annual_dol: 556.81,
        description:
            'Compact, modern Italian eatery with a patio serving Neapolitan & pan pizzas & other familiar dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6587,
        store_longitude: -73.9964,
        total_orders: 229.37,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Salad, Greek, Bbq, Karaoke, Fun, Friendly, Retro, Greek Salad, Great, Classic',
        annual_dol: 1034.37,
        description:
            'Happening gay bar featuring DJs & dancing, plus regular events like drag shows & karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7071,
        store_longitude: -73.9221,
        total_orders: 1269.77,
        neighborhood: 'Bushwick',
        keywords: 'Cozy, Intimate, Legendary, Great, Casual, Unique',
        annual_dol: 4030.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7214,
        store_longitude: -73.8452,
        total_orders: 2664.87,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Beer, Cuban, Wine, Steak, Dessert, Caribbean, Hotel, Fun, American, Lively',
        annual_dol: 3996.06,
        description:
            'Lively restaurant & bar serving Nuevo Latino fare & cocktails in a setting with a Miami vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -73.6338,
        total_orders: 1044.13,
        neighborhood: '',
        keywords: 'Burger, Steak, Tasty, Wine, Beer, Savory, Delicious, Warm, Creamy, Bbq',
        annual_dol: 1074.52,
        description:
            'Pub with tap beers & booths serving hearty American grub like burgers & showing sports on TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7415,
        store_longitude: -74.0085,
        total_orders: 779.61,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Korean, Rich, Spacious, Classy, American, Iconic, High-End, Modern, Contemporary, Sophisticated',
        annual_dol: 2245.51,
        description:
            'Elevated Korean delicacies are served in this contemporary restaurant with a spacious terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7356,
        store_longitude: -74.0066,
        total_orders: 459.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Steak, Salad, Tasty, Wine, Delicious, Upscale, Rich, Rustic, Cozy',
        annual_dol: 1878.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.9972,
        total_orders: 35.09,
        neighborhood: 'Little Italy',
        keywords:
            'Cheesecake, Lobster, Lobster Tail, Dessert, Pizza, Wine, Italian, Coffee, Gourmet, Delicious',
        annual_dol: 49.4,
        description:
            'Classic cafe serving cannoli, gelato & other Italian desserts with espresso & wine since 1973.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.8284,
        total_orders: 418.75,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Tasty, Wine, Beer, Chinese, Delicious, Bloody Mary, Great, Local, Casual',
        annual_dol: 456.38,
        description:
            'Easygoing, low-key neighborhood bar with sports on TV alongside cocktails, beer & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.866,
        store_longitude: -73.8302,
        total_orders: 89.64,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Salad, Italian, Steak, Wine, Mexican, Beer, Chinese, Homemade, Irresistible, Iconic',
        annual_dol: 109.98,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.8295,
        total_orders: 78.01,
        neighborhood: 'Flushing/Queensboro Hill',
        keywords: 'Beer, Friendly, Cool, Awesome, Great, Excellent, Casual, Local',
        annual_dol: 95.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7369,
        store_longitude: -74.0015,
        total_orders: 16.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Italian, Rustic, Authentic, Irresistible, Casual, Great, Mouth-Watering',
        annual_dol: 68.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7333,
        store_longitude: -73.958,
        total_orders: 653.47,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Italian, Coffee, Swiss, Spicy, American, French, Delicious, Casual, Local',
        annual_dol: 822.77,
        description:
            'Mediterranean-accented New American fare served in a dim-lit atmosphere heavy on reclaimed wood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7688,
        store_longitude: -73.9555,
        total_orders: 1483.08,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Pizza, Beer, Wine, Spicy, Delicious, Margarita, Irish, Fun, Awesome',
        annual_dol: 1819.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7694,
        store_longitude: -73.9579,
        total_orders: 33.9,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Tequila, Tacos, Steak, Mexican, Beer, Margarita, Delicious, Stylish, Creamy, Vibrant',
        annual_dol: 138.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7312,
        store_longitude: -73.9546,
        total_orders: 752.24,
        neighborhood: 'Greenpoint',
        keywords:
            'Steak, Burger, Mexican, Wine, French, French Onion Soup, Dessert, American, Seasonal, Casual',
        annual_dol: 1024.03,
        description:
            'Unassuming restaurant specializing in French cuisine, including steak & seafood plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7715,
        store_longitude: -73.9591,
        total_orders: 155.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Garlic Bread, Salad, Shrimp Cocktail, French, Wine, Coffee, Organic, American, Filet Mignon',
        annual_dol: 635.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6604,
        store_longitude: -73.9911,
        total_orders: 86.98,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Pizza, Steak, Salad, Tuna Steak, Italian, Meatball, Hawaiian, Beer, Wine',
        annual_dol: 96.99,
        description:
            "\"Battle Hill Tavern is a classic Brick Oven Wood Fired Gastropub located in South Slope, Brooklyn. We offer a varied menu of traditonal and modern pizzas, share dishes, and main courses to fit most every need. Our food allows us the confidence to believe that once you've come dine with us, you'll be back again and again. We are absolutely family friendly, and have outdoor seating for people who wnat to enjoy their dinner with their furry, (or feathered), best friends. BHT is a place people call home, where they come to spend time with friends and make new ones. Please come sit with us, make yourself at home, and see why our customers don't stay strangers for long. Come visit our family, yours is always welcome. Welcome Home. B.H.T.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6662,
        store_longitude: -73.9787,
        total_orders: 86.08,
        neighborhood: 'Park Slope',
        keywords:
            'Salad, Champagne, Wine, Gluten Free, Coffee, Beer, Italian, Organic, Homemade, Meatball',
        annual_dol: 351.85,
        description:
            'Neighborhood joint serving homemade pasta & sandwiches in an unfussy space with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7434,
        store_longitude: -73.6153,
        total_orders: 1401.78,
        neighborhood: '',
        keywords:
            'Vegetarian, Steak, Wine, Flavorful, Dessert, Savory, Upscale, Stylish, Shrimp Scampi, Comfortable',
        annual_dol: 1431.53,
        description:
            'Rotating menu of seasonal American dishes alongside international wines in an upscale setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7448,
        store_longitude: -73.6051,
        total_orders: 235.64,
        neighborhood: '',
        keywords:
            'Vegetarian, Mexican, Wine, Casual, Italian, Modern, American, Contemporary, Friendly, Authentic',
        annual_dol: 432.52,
        description:
            'Updated spins on Mexican fare join craft cocktails in this chic, contemporary space with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.779,
        store_longitude: -73.96,
        total_orders: 29.3,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Wine, Italian, Pizza, Vodka, Tasty, Coffee, Ale, Hotel, Creamy, Exquisite',
        annual_dol: 119.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7711,
        store_longitude: -73.9225,
        total_orders: 16636.54,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Luxurious, Elegant, Korean, Spanish, Classic, Memorable, Friendly, Relaxed, Great, Modern',
        annual_dol: 17401.03,
        description:
            '"Astoria World Manor is the finest banquet hall in all of Queens. We provide you with professional banquet planning for your wedding, party or event with luxurious elegance at affordable prices."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6893,
        store_longitude: -73.816,
        total_orders: 29.3,
        neighborhood: 'South Ozone Park/South Richmond Hill',
        keywords: 'Burger, Chinese, Ale, Indian, Local, Caribbean, Casual, Fresh, Great, Fine',
        annual_dol: 59.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7073,
        store_longitude: -73.6752,
        total_orders: 21.18,
        neighborhood: '',
        keywords:
            'Chinese, Italian, Shrimp Lo Mein, Asian, Wonton Soup, Casual, Dim Sum, Authentic',
        annual_dol: 86.57,
        description:
            '"Hi, Everyone! We are open for In door Dining, Take out, Catering & Delivery. We Offers the finest selection of world-class Szechuan, Asian ,Seafood , Cantonese Dim Sum & Full Bar (happy hour 11:30am-6 pm Daily), Using Only The Freshest ,Best & Highest Quality Ingredients For All Of Our Customers. Please Come join us with your Friends &Family, We look forward to seeing you all soon."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6636,
        store_longitude: -73.9845,
        total_orders: 301.05,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Margarita, Dessert, Wine, Spicy, Mediterranean, American, Latin, Contemporary, Asian',
        annual_dol: 309.18,
        description:
            'Elevated Mexican cuisine in a festive dining room, plus tequila-based cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6698,
        store_longitude: -73.9955,
        total_orders: 400.78,
        neighborhood: 'Gowanus',
        keywords: 'Casual, Intimate, Recommended',
        annual_dol: 436.16,
        description:
            "Energetic nightspot offering DJ'd music & dancing in a neon-lit, industrial space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7712,
        store_longitude: -73.9594,
        total_orders: 4880.6,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Steak, Sushi, Salad, Tasty, Apple Pie, Beer, Funky, Juicy',
        annual_dol: 4984.16,
        description:
            'A comfy atmosphere attracts a laid-back neighborhood crowd to this cash-only pub famed for burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7111,
        store_longitude: -73.9453,
        total_orders: 316.56,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Tequila, Wine, Beer, Hotel, Casual',
        annual_dol: 1294.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.953,
        total_orders: 187.89,
        neighborhood: 'Greenpoint',
        keywords: 'Wine, Friendly, Traditional, Casual, Chic, Awesome, Fresh, Great',
        annual_dol: 768.04,
        description:
            'This unassuming, buzzy nightclub with chic styling features a full roster of DJs & concerts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7244,
        store_longitude: -73.9513,
        total_orders: 360.26,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Beer, Spanish, Mediterranean, Clean, Awesome, Wonderful, Latin, Great, Excellent',
        annual_dol: 430.35,
        description:
            'Barcelona-style small plates, cocktails & wines served in a sleek, exposed-brick space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -74.0022,
        total_orders: 104.45,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Dairy Free, Vegetarian, Salad, Wine, Vodka, Italian, Shrimp Cocktail, Organic, Martini, Quaint',
        annual_dol: 319.35,
        description:
            'Italian classics are crafted from organic ingredients in a sunwashed stucco space with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7006,
        store_longitude: -73.9126,
        total_orders: 18,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Coffee, Beer, Vietnamese, Dessert, Delicious, Natural, Casual, Great',
        annual_dol: 73.58,
        description:
            '"All-day bar with craft beer, coffee, natural wines, cocktails, and Vietnamese-inspired cafe/bar fare."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6476,
        store_longitude: -73.9559,
        total_orders: 184.92,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Mexican, Karaoke, Caribbean, Retro, Casual',
        annual_dol: 193.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6978,
        store_longitude: -73.937,
        total_orders: 621.15,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords:
            'Burger, Pizza, Steak, Vegetarian, Vegan, Italian, Meatball, Wine, Delicious, Cuban',
        annual_dol: 675.97,
        description:
            '"CONCRETE is a casual eatery, serving authentic Sicilian food to the Bedstuy and Bushwick neighborhoods. Happy hour drink specials, Brunch on weekends, a large, airy dine-in space, sidewalk seating. Delivery + Pickup service available."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7311,
        store_longitude: -73.9542,
        total_orders: 1197.91,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Salad, Beer, Wine, Warm, Creamy, Donut, Cozy, Swiss, Relaxed',
        annual_dol: 1297.8,
        description:
            'Refurbished wood adorns this laid-back bar serving local beers & grub such as burgers & pierogi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7153,
        store_longitude: -74.0156,
        total_orders: 422.53,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Kebab, Salad, Wine, Organic, Mediterranean, Greek, Elegant, Polished, Welcoming, Premium',
        annual_dol: 1727.16,
        description:
            '"The Ancient Greek queen of the Mediterranean was crowned with the title of Anassa, given only to unique women with goddess-like qualities. The title, similar to a “queen,” was reserved for women who exuded rare qualities and benevolent leadership. Like the Ancient Greek Anassa, we believe our restaurant stands out, representing all of the finest recipes, culinary traditions and ingredients from Greece and the Mediterranean – always standing out for our quality and attention to detail. We look forward to welcoming you to Anassa Taverna and giving you a bit of our own… royal treatment that only an Anassa can give."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.705,
        store_longitude: -73.9308,
        total_orders: 101.76,
        neighborhood: 'East Williamsburg',
        keywords: 'Wine, Beer, Sustainable, Unique',
        annual_dol: 415.97,
        description:
            '"Carroll Hall is suitable for many different types of events and we welcome them all. From weddings, corporate events and conferences to private parties, art events and pop-up events, we can accommodate you. Carroll Hall consists of two main spaces: The Warehouse and The Garden. Both spaces can be configured to host you and your guests. There is also a kitchen prep room, the garden ‘Tree House’ and a VIP area that looks out over both Warehouse and Garden. By appointment only. hello@carrollhall.com"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.657,
        store_longitude: -73.6083,
        total_orders: 2265.45,
        neighborhood: '',
        keywords:
            'Vegan, Karaoke, French Toast, French, Delicious, Irish, American, Local, Casual, Fresh',
        annual_dol: 2999.15,
        description: '"Live Music, Great food, Great Drinks"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7338,
        store_longitude: -73.9997,
        total_orders: 2446.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesecake, Pizza, Italian, Salad, Wine, Dessert, Tasty, Rich, Delicious, Margarita',
        annual_dol: 2498.66,
        description:
            'Naples meets NYC at this trattoria with thin-crust pizza, Italian wines & ample sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7339,
        store_longitude: -74.0021,
        total_orders: 682.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cozy, Picturesque, Trendy, Rustic, Fun, Legendary, Fancy, Sweet, Fantastic, Great',
        annual_dol: 858.79,
        description:
            'Modest cover charges & some major talents make this cozy downstairs music club a local stalwart.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -74.0028,
        total_orders: 3709.54,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Vegan, Gluten Free, Spicy, American, Healthy, Hotel, Contemporary, Refined, Fun',
        annual_dol: 3788.25,
        description:
            'Stylish, airy bar & restaurant presenting refined New American fare & inventive craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7404,
        store_longitude: -74.0056,
        total_orders: 537.76,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Coffee, Whiskey, Vodka, Beer, Gin, Dessert, Gourmet, Bourbon, Delicious, Warm',
        annual_dol: 860.79,
        description:
            'Chill, vintage-style spot with a patio for choice coffee, craft cocktails & gourmet light bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -74.0078,
        total_orders: 447.84,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Hotel, Delicious, Sustainable, Creative, Bbq, Sensational, Intimate, Perfect, Succulent, Modern',
        annual_dol: 609.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7755,
        store_longitude: -73.9533,
        total_orders: 22.11,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Chinese, Asian, Spicy, Casual, Authentic, Hospitable, Dan Dan, Mai Tai, Great',
        annual_dol: 90.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7888,
        store_longitude: -73.9744,
        total_orders: 474.18,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Steak, Tasty, Chocolate Mousse, Upscale, Delicious, Italian, French Toast, Premium, French',
        annual_dol: 505.7,
        description:
            'Serene joint with a classy vibe offering small plates & full meals with matching wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -73.9517,
        total_orders: 1748.85,
        neighborhood: 'Greenpoint',
        keywords: 'Quaint, Margarita, Awesome, Great, Local, Casual',
        annual_dol: 1785.96,
        description:
            'Quaint neighborhood sports bar with a selection of Midwestern canned & bottled brews, plus taps.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7631,
        store_longitude: -73.9565,
        total_orders: 438.89,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Coffee, American, Rare, Local, Great',
        annual_dol: 457.92,
        description:
            'Memorial Sloan Kettering Cancer Center is a cancer treatment and research institution in the borough of Manhattan in New York City, founded in 1884 as the New York Cancer Hospital. MSKCC is one of 52 National Cancer Institute–designated Comprehensive Cancer Centers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -73.9855,
        total_orders: 4297.1,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Rich, Opulent, Urban, Iconic, Relaxed, American, Friendly, Local',
        annual_dol: 4658.52,
        description:
            "Herald Square subway station.Polished rooms provide HDTVs and free Wi-Fi, as well as minifridges, microwaves, and tea and coffeemakers. Suites add sitting areas with pull-out sofas; some offer balconies and/or city views. Room service is available.Complimentary amenities include hot breakfast, and nightly receptions with snacks and drinks. There are 2 relaxed restaurant/bars, plus a lounge featuring a terrace and city views. There's a gym. Parking is extra.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.8449,
        total_orders: 480.57,
        neighborhood: 'Willets Point/Flushing/Corona',
        keywords: 'Gourmet, Irish, Fun, Hotel, Seasonal, Fusion, Classic, American, Unpretentious',
        annual_dol: 2214.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9857,
        total_orders: 198.68,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Western, Hotel, Beer, Perfect, Clean, Friendly, Exquisite, Modern, Great, Fantastic',
        annual_dol: 232.13,
        description:
            "Museum of Art.The modern rooms come with free WiFi, flat-screen TVs and desks. Some rooms have iPod docks, minifridges or views of the Empire State Building. Kids age 12 and under stay at no extra cost with an adult.Amenities include a lounge bar and a furnished patio. There's also a fitness center and a 24-hour business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7944,
        store_longitude: -73.6983,
        total_orders: 1349.08,
        neighborhood: '',
        keywords: 'Burger, Steak, Beer, Italian, Upscale, Wine, Spicy, Delicious, Irish, Modern',
        annual_dol: 1349.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5788,
        store_longitude: -73.8494,
        total_orders: 153.01,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords:
            'Garlic Bread, Pizza, Delicious, Italian, Wine, Homemade, Fresh, Relaxed, Casual, Local',
        annual_dol: 208.3,
        description:
            'Family-owned standby serving pizza & many Italian favorites in a relaxed atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7035,
        store_longitude: -74.0111,
        total_orders: 593.23,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Cheesesteak, Whiskey, Beer, Delicious, Philly Cheesesteak, Authentic, Friendly, Great',
        annual_dol: 2082.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7208,
        store_longitude: -74.0054,
        total_orders: 756.33,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Hotel, Relaxed, Lively, Great, Fine',
        annual_dol: 1002.17,
        description:
            "Relaxed rooms include free Wi-Fi and flat-screen TVs, along with Keurig coffeemakers, microwaves and minifridges; most provide city views. Upgraded rooms add whirlpool tubs. Room service is available.There's a lively restaurant offering wood-fired pizzas, craft beers and big-screen sports. Cooked-to-order breakfast is available for a fee. There's also a 24/7 pantry with snacks and drinks. Additional amenities include a fitness center, a business center and valet parking (fee).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7449,
        store_longitude: -73.9043,
        total_orders: 963.02,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Burger, Pizza, Beer, Dessert, Irish, Ale, Homemade, French, Bruschetta, Fun',
        annual_dol: 983.46,
        description:
            'Roomy Irish pub with an old-world, gothic-style interior & traditional bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -73.8422,
        total_orders: 257.06,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Pizza, Wine, Italian, Peruvian, Refined, Healthy, Charming, Mediterranean, Friendly',
        annual_dol: 286.43,
        description:
            'Charming hangout with a maritime vibe serving refined seafood eats, plus beer, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9897,
        total_orders: 1642.24,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Korean, American, Organic, Spicy, Wine, Contemporary, Stylish, Latin, Sleek',
        annual_dol: 1677.09,
        description:
            'Contemporary Korean bar & restaurant serving cocktails & party-friendly fare in a sleek space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7662,
        store_longitude: -73.9868,
        total_orders: 407.3,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Tasty, Salad, Wine, Delicious, Healthy, Classy, Friendly, Innovative, Perfect',
        annual_dol: 1664.92,
        description:
            'Small, colorful spot with a lively vibe serving Mexican fare, margaritas & sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8101,
        store_longitude: -73.9585,
        total_orders: 225.19,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Vegan, Wine, Gluten Free, Beer, Ethiopian, African, Spicy, Delicious, Romantic, Classy',
        annual_dol: 275.85,
        description:
            'East African eatery serves traditional Ethiopian & Eritrean fare scooped by hand with injera bread.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.9825,
        total_orders: 106.63,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Wine, Coffee, Burger, Beer, Vietnamese, Seasonal, Delicious, French, Modern',
        annual_dol: 142.92,
        description:
            '"1700 Broadway is a classic Midtown Manhattan property, now reimagined and fully modernized. Located on Broadway between 53rd and 54th streets in the heart of Midtown, 1700 Broadway offers a striking glass curtain wall, an efficient side core floorplate, and abundant views of Central Park, the Hudson River, and the Midtown skyline. The property has recently completed a dramatic transformation activating market-leading tenant amenities, including a private amenity lounge, conference center, and rooftop terrace."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5151,
        store_longitude: -74.2481,
        total_orders: 63.62,
        neighborhood: 'Tottenville',
        keywords:
            'Lobster, Pizza, Delicious, Italian, Memorable, American, Fresh, Great, Satisfying, Creative',
        annual_dol: 260.05,
        description:
            '"Paradise Island is a seafood restaurant that offers steak, seafood, dinner, and lunch in Staten Island, NY. We value our customers and strive to make your meal satisfying and delicious. Their menu comprises a selection of imported products paired with local products creative bond. Our shop also provides delivery that promotes a pleasant dining experience brought to your home. In addition, we aim to ensure you have a memorable dining experience with our restaurant, which is why we provide live music from our live band, a bar, and a lounge."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 15.37,
        neighborhood: 'Jackson Heights',
        keywords:
            'Colombian, Trendy, Delicious, Homey, Cozy, Traditional, Authentic, Terrific, Casual',
        annual_dol: 62.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6815,
        store_longitude: -73.9774,
        total_orders: 683.78,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Wine, Salad, Beer, Tasty, Karaoke, Sweet, Friendly, Intimate, Perfect',
        annual_dol: 779.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7397,
        store_longitude: -73.9925,
        total_orders: 66.96,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Korean, Upscale, Stylish, Modern, Contemporary, Fancy, High-End, Retro, Casual, Traditional',
        annual_dol: 273.72,
        description:
            'Stylish restaurant with retro decor offering upscale Korean cuisine with a modern twist.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5425,
        store_longitude: -74.1652,
        total_orders: 28.53,
        neighborhood: 'Eltingville/Annadale',
        keywords: 'Japanese, Sushi, Asian, Casual, Great',
        annual_dol: 116.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6878,
        store_longitude: -73.9704,
        total_orders: 5426.47,
        neighborhood: 'Fort Greene',
        keywords:
            'Salad, Vegan, Wine, Tasty, Beer, Mediterranean, Spicy, Japanese, Rustic, Turkish',
        annual_dol: 5541.61,
        description:
            'Mediterranean eatery serving creative tapas, brunch & sangria in a bohemian, plant-filled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7144,
        store_longitude: -73.9579,
        total_orders: 7525.03,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegan, Wine, Beer, Rustic, Impeccable, Warm, Four Horsemen, American, Delicious, Relaxed',
        annual_dol: 7732.62,
        description:
            'Open-air, brick-lined eatery serving New American dishes for brunch & dinner, plus cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.9563,
        total_orders: 5472.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Pizza, Meatball, Delicious, Avocado Toast, Stylish, Mediterranean, Impeccable, Rum, Moroccan',
        annual_dol: 15739.07,
        description:
            'Laid-back joint turning out Mediterranean Balkan dishes in a contemporary venue adorned with plants.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7147,
        store_longitude: -73.9436,
        total_orders: 203.5,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Whiskey, Beer, Wine, Canadian, Irish, Rum, English Muffin, American, Yummy',
        annual_dol: 994.46,
        description:
            'Mellow saloon with a backyard patio, popular brunch, microbrews & a lodgelike vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7453,
        store_longitude: -73.9782,
        total_orders: 4241.01,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Upscale, Wine, Delicious, Beer, Fun, Irish, Wonderful, Great, Incredible, Modern',
        annual_dol: 4819.85,
        description:
            'A full menu of pub grub, beer & cocktails is complemented by sports on TV & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9668,
        total_orders: 488.02,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Tasty, Vintage, Premium, Cool, Unique',
        annual_dol: 1994.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -73.9845,
        total_orders: 1901.15,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Ale, Beer, Wine, Irish, Traditional, Premium, Clean, Friendly, Lively, Casual',
        annual_dol: 1983.57,
        description:
            'Lively Irish tavern featuring pool tables, sports on TV & daily happy hour amid horse-racing decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7381,
        store_longitude: -73.9876,
        total_orders: 1415.65,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Steak, Burger, Salad, French, Wine, Tasty, Swiss, Beer, Classy, Traditional',
        annual_dol: 1415.65,
        description:
            'French spot open 24/7 serving bistro classics such as steaks & mussels plus wine, beer & breakfast.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8514,
        store_longitude: -73.9394,
        total_orders: 2001.36,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Burger, Pizza, Sushi, Bread Pudding, Coffee, Italian, Wine, Chinese, Upscale, Rustic',
        annual_dol: 2051.63,
        description:
            'Cocktails & upscale comfort fare, including breakfast, served in a cozy, rustic-chic tiled setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9707,
        total_orders: 12819.36,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Lobster, Lobster Roll, Shrimp Cocktail, Salad, Cajun, Gourmet, Roast Beef, Wine',
        annual_dol: 13131.32,
        description:
            'Dry-aged beef, martinis & wine served by old-school waiters draw crowds to this Midtown institution.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.9434,
        total_orders: 800.7,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Salad, Hamburger, Wine, Beer, Caesar Salad, American, Fun, Awesome, Casual',
        annual_dol: 1045.08,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6943,
        store_longitude: -73.8279,
        total_orders: 37.81,
        neighborhood: 'Richmond Hill',
        keywords: 'Wine, Ale, Rich, French, Friendly, Eclectic, American, Cool, Casual, British',
        annual_dol: 154.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8019,
        store_longitude: -73.6469,
        total_orders: 2393.74,
        neighborhood: '',
        keywords:
            'Lobster, Kebab, Shrimp Cocktail, Salad, Dessert, Coffee, Upscale, Rich, Mediterranean, Elegant',
        annual_dol: 2462.59,
        description:
            'Upmarket Greek option serving seafood, chops & veggies in an elegant space with a romantic patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7151,
        store_longitude: -74.0104,
        total_orders: 24.91,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Vegetarian, Vegan, Beer, Milkshake, Delicious, Warm, Healthy, Cozy, Casual',
        annual_dol: 101.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.9681,
        total_orders: 144,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Tacos, Burger, Vegan, Burritos, Margarita, Tex-Mex, Quesadillas, Gluten Free, Casual',
        annual_dol: 243.03,
        description:
            'Energetic Mexican joint whose big burritos & cheap margaritas are popular with students.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9862,
        total_orders: 5275.41,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Garlic Bread, Lobster, Shrimp Cocktail, Cajun, Beer, Italian, Charming, Fun, Entertaining, Casual',
        annual_dol: 5516.44,
        description:
            'Family-friendly chain for seafood & American fare served in a fishing boat-themed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7832,
        store_longitude: -73.9573,
        total_orders: 710.26,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Pizza, Salad, Italian, Shrimp Cocktail, Wine, Warm, Fun, Comfortable, Legendary, Fresh',
        annual_dol: 1740.08,
        description:
            'Comfortable restaurant spotlighting Italian cuisine, including pasta, pizza & seafood dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9843,
        total_orders: 3445.22,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Salad, Lobster Bisque, Wine, Vodka, Beer, French Onion Soup, French, Upscale',
        annual_dol: 3594.59,
        description:
            'Posh space in the AKA Times Square for steak, seafood, caviar & other high-end American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.755,
        store_longitude: -73.98,
        total_orders: 22.83,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Premium, Gourmet, Perfect, Adventurous',
        annual_dol: 93.31,
        description:
            '"Convene at 530 Fifth Avenue is a premium meeting and event space as well as professional work place in Midtown East within walking distance to Grand Central Station. As one of our largest spaces, it includes a dedicated floor of on-demand meeting spaces ranging from small huddles to large hubs and two floors of tailored, furnished work suites and membership-based work areas. 530 Fifth Avenue also includes a health and wellness center."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6913,
        store_longitude: -73.9975,
        total_orders: 0,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Pizza, Italian, Beer, Delicious, American, Fresh, Unpretentious, Casual, Unique',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7519,
        store_longitude: -73.9976,
        total_orders: 16175.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Stylish, Lively, Spicy, Elegant, Modern, Vegan, Romantic, Polished, Amazing, Mediterranean',
        annual_dol: 53321.8,
        description:
            'Stylish restaurant with an open kitchen dishing up Eastern Mediterranean bites & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.9991,
        total_orders: 2181.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Salad, Italian, Bbq, Trendy, Homemade, Contemporary, Casual, Intimate, Creative',
        annual_dol: 8917.68,
        description:
            '"The Midnight Theatre is a variety theatre, restaurant, and bar opening at Brookfield\'s Manhattan West in June 2022."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9859,
        total_orders: 224.26,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Tail, Dessert, Delicious, Shrimp Cocktail, Upscale, Tequila, Elegant, Italian, Cozy',
        annual_dol: 916.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9932,
        total_orders: 2423.85,
        neighborhood: 'NoHo',
        keywords: 'Wine, Beer, Cozy, Inviting, Friendly, Clean, Hotel, Fancy, Cool, Great',
        annual_dol: 2543.94,
        description:
            'Laid-back bar with cocktails & an extensive microbrew list that draws a tech-savvy crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6599,
        store_longitude: -73.9608,
        total_orders: 776.44,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean/Lefferts Manor Historic District',
        keywords: 'Wine, Beer, Coffee, Mexican, Hotel, French Toast, Rum, French, Cozy, Eclectic',
        annual_dol: 1815.76,
        description: '"Neighborhood bar with food"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9825,
        total_orders: 5376.07,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Salad, Wine, Milkshake, Coffee, Shrimp Alfredo Pasta, French Toast, Beer, Martini, French Onion Soup, French',
        annual_dol: 7191.76,
        description:
            '"Due to state wide mandates, we are open until 12AM but our last seating is at 11PM. Thank you, The Sugar Factory Family"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7775,
        store_longitude: -73.9786,
        total_orders: 1097.37,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Spanish, Tasty, Mediterranean, Intimate, American, Latin, Greek, Seasonal, Natural',
        annual_dol: 1144.94,
        description:
            'Eatery for shareable plates with Latin & Mediterranean flavors & drinks with housemade ingredients.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7775,
        store_longitude: -73.9835,
        total_orders: 1797.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Tasty, Dessert, Wine, French Onion Soup, Bourbon, French, Ample, Urban, Fresh',
        annual_dol: 1875.26,
        description:
            'French comfort food & a Parisian vibe have been drawing A-list types here since 1983.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7571,
        store_longitude: -73.9785,
        total_orders: 39.52,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Upscale, Wine, Golfclub, Hotel, Spacious, Beer, Perfect, Urban, Great, Hip',
        annual_dol: 77.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.9754,
        total_orders: 5906.02,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Lobster, Burger, Italian, Mead, Gracious, Fancy, Classy, Wonderful, Classic',
        annual_dol: 6066.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8178,
        store_longitude: -73.9605,
        total_orders: 3468.76,
        neighborhood: 'Upper Manhattan',
        keywords: 'Burger, Steak, Salad, Bbq, Beer, Rustic, American, Delicious, Homemade, Retro',
        annual_dol: 3542.36,
        description:
            'Barbecue chain serving Southern-style meats & draft brews in a retro setting (most have live music).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7663,
        store_longitude: -73.978,
        total_orders: 6460.42,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Luxurious, Upscale, Hotel, Elegant, Stylish, Warm, Iconic, Spectacular, Cozy, Premium',
        annual_dol: 6597.5,
        description:
            'around Columbus Circle.Elegant rooms feature smart TVs, iPod docks and Wi-Fi (fee), as well as minibars. Club-level rooms have access to a lounge with free continental breakfast and evening snacks. Suites add living areas with sofas; upgraded suites come with fireplaces and/or balconies. Room service is available 24/7.Amenities include a warm restaurant with a bar and city views, along with a spa and a gym. Valet parking is offered for a surcharge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.734,
        store_longitude: -74.0014,
        total_orders: 1031.22,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Salad, Wine, Rustic, Avocado Toast, French, Seasonal, Casual, Delicious, American',
        annual_dol: 1056.07,
        description:
            'New American fare served from breakfast to late-night in a rustic space with just a few tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7274,
        store_longitude: -73.635,
        total_orders: 3476.48,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Shrimp Cocktail, Champagne, Wine, Beer, Spicy, Delicious, Belgian',
        annual_dol: 3482.23,
        description:
            'Mussels are the main event at this Belgian bistro with lots of imported beers & a lively bar scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.747,
        store_longitude: -73.6482,
        total_orders: 174.49,
        neighborhood: '',
        keywords: 'American, Heritage, Local, Tradition, Cultural',
        annual_dol: 275.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7108,
        store_longitude: -73.9264,
        total_orders: 119692.68,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Hotel, Delicious, Awesome, Great, Intimate, Complex, Local',
        annual_dol: 125458.42,
        description:
            '"Avant Gardner is an indoor-outdoor, sprawling events complex in East Williamsburg, Brooklyn. Home of The Brooklyn Mirage, The Great Hall and The Kings Hall."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7095,
        store_longitude: -73.9332,
        total_orders: 18528.25,
        neighborhood: 'East Williamsburg',
        keywords:
            'Karaoke, Rooftop, Wine, Trendy, Fun, Vodka, Breakfast Burrito, Amazing, Classy, Great',
        annual_dol: 19390.72,
        description:
            'Industrial-chic venue of rehearsal space, art studios & rooftop bar with live music, films & snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7095,
        store_longitude: -73.6276,
        total_orders: 40.92,
        neighborhood: '',
        keywords:
            'Steak, Mexican, Salad, Roast Beef, Fajitas, American, Homemade, Local, Casual, Great',
        annual_dol: 167.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6735,
        store_longitude: -73.9915,
        total_orders: 3957.83,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Comfortable, Intimate, Casual, Sweet, Traditional, Ample, British',
        annual_dol: 4041.81,
        description:
            'Big-name acts & up-and-comers play at this big bar/performance venue set in a former warehouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5756,
        store_longitude: -73.9831,
        total_orders: 374.52,
        neighborhood: 'Coney Island',
        keywords:
            'Salad, Cajun, Coffee, Chinese, Bbq, Jamaican, French, Caribbean, Mouth-Watering, Casual',
        annual_dol: 482.45,
        description:
            'Caribbean-inspired cuisine with signature Rasta Pasta, plus a full bar & entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7674,
        store_longitude: -73.9683,
        total_orders: 1016.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Burger, Hamburger, Chocolate Croissant, Wine, Dessert, Coffee, French, Beer, Cozy',
        annual_dol: 1042.46,
        description:
            'French brasserie serving classics like moules & steak tartare in a snug setting that suggests Paris.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9582,
        total_orders: 151.41,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Delicious, American, Moscow Mule, Bright, Perfect, Hip, Casual',
        annual_dol: 661.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6549,
        store_longitude: -74.0126,
        total_orders: 183.82,
        neighborhood: 'Sunset Park',
        keywords: 'Diverse, Cultural',
        annual_dol: 415.3,
        description:
            '"We’re part of Danny Meyer’s Union Square Hospitality Group, which includes restaurants, bars, cafes, and fast-casual eateries in addition to Union Square Events\' large-scale event services, food service solutions for public and private institutions, industry consulting, and educational programming and partnerships. Founded in 2005 as Hudson Yards Catering, Union Square Events is a culinary and operations leader in the hospitality industry, partnering with a diverse portfolio of best-in-class clients. We produce one-of-a-kind catered events and unparalleled dining experiences in a variety of cultural, corporate, entertainment, and private venues throughout New York City and beyond."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -73.9946,
        total_orders: 7117.71,
        neighborhood: 'Little Italy',
        keywords:
            'Lobster, Steak, Mexican, Upscale, Beer, Italian, Elegant, Creamy, Sustainable, Spacious',
        annual_dol: 7268.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8071,
        store_longitude: -73.9284,
        total_orders: 7387.74,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Salad, Karaoke, Wine, Puerto Rican, Shrimp Cocktail, Caribbean, Spanish, Timeless, Perfect, Authentic',
        annual_dol: 8429.61,
        description:
            'Colorful locale for Puerto Rican specialties & a lounge featuring a dance floor & DJ music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7056,
        store_longitude: -74.0113,
        total_orders: 345.99,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Beer, Whiskey, Wine, Margarita, Tasty, American, Rum, Mojito, Friendly',
        annual_dol: 1414.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -74.0088,
        total_orders: 59.86,
        neighborhood: 'Hudson Square',
        keywords:
            'Tacos, Hotel, Rooftop, Cuban, Trendy, Tasty, Stylish, Delicious, Romantic, Awesome',
        annual_dol: 244.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.5607,
        total_orders: 27.51,
        neighborhood: '',
        keywords: 'Premium, Moroccan, Chill, Unique',
        annual_dol: 38.5,
        description:
            'Hangout offering hundreds of hookah flavors in plush surroundings with belly dancers some nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7159,
        store_longitude: -73.9966,
        total_orders: 935.25,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Japanese, Sushi, Urban, Hotel, Sleek, Organic, Aesthetic, Awesome, Perfect, Intimate',
        annual_dol: 3822.99,
        description:
            '"After nearly two decades of honing his skills in Japan and New York City, chef Atsushi “ATS” Kono presents the craft of Japanese yakitori at his namesake restaurant, KONO. KONO\'s discreet locale within Chinatown\'s Canal Arcade echoes the hidden streets and alleyways of Tokyo – inside, guests will be treated to a special evening of smoke and fire as they watch chef Kono prepare a multi-course, kappo-style yakitori omakase rooted in tradition and seasonality. Guests can expect to enjoy various cuts (and specialty cuts) of organic Amish chicken grilled over Binchotan hardwood charcoal."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.9884,
        total_orders: 2408.92,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Tacos, Burger, Beer, Salad, Wine, Nachos, American, Friendly, Casual, Classic',
        annual_dol: 2460.03,
        description:
            'Industrial-chic sports bar with a Pittsburgh Steelers theme, plus updated pub grub & happy hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7161,
        store_longitude: -74.0086,
        total_orders: 309.22,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Italian, Avocado Toast, Relaxed, Warm, French, Casual, Paloma, Perfect, Contemporary, Seasonal',
        annual_dol: 1165.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -74.005,
        total_orders: 919.96,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Karaoke, Steak, Sushi, Salad, Tuna Steak, Japanese, Wine, Organic, Chic, Natural',
        annual_dol: 939.47,
        description:
            'Chic karaoke lounge with 3 sizes of private rooms for shared singing, cocktails, happy hour & sushi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -73.9261,
        total_orders: 2092.6,
        neighborhood: 'East Williamsburg',
        keywords: 'Hotdog, Pizza, Hotel, Karaoke, Great, Cool, Excellent, Modern, Rooftop, Casual',
        annual_dol: 3057.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7351,
        store_longitude: -74.0006,
        total_orders: 4568.15,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Hamburger, Shrimp Cocktail, Gluten Free, Wine, Delicious, French, Trendy, Prime Rib',
        annual_dol: 4712.75,
        description:
            "Steakhouse by Brendan Sodikoff (Chicago's Au Cheval) with contemporary offerings & vintage appeal.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7404,
        store_longitude: -74.0054,
        total_orders: 5137.39,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Tacos, Tequila, Steak, Mexican, Lobster, Vegetarian, Wine, Delicious, Japanese, Vibrant',
        annual_dol: 5599.49,
        description:
            'Vibrant cantina serving modern Mexican cuisine, made-to-order guacamole & tequila cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7183,
        store_longitude: -73.6605,
        total_orders: 53.98,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Pizza, Tacos, Salad, Chicken Parmigiana, Italian, Meatball, Flavorful, Tasty',
        annual_dol: 58.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.861,
        store_longitude: -73.6405,
        total_orders: 54.27,
        neighborhood: '',
        keywords:
            'Burger, Beer, Pizza, Hamburger, Wine, Ale, Delicious, Comfortable, American, Belgian',
        annual_dol: 221.83,
        description:
            '"Garvies Point Brewery continues to push the boundaries of craft brewing through our own curiosité. We focus on offering a wide range of styles, and brew innovative beers with quality ingredients, creativity, and passion."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -73.9925,
        total_orders: 596,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Pizza, Italian, Coffee, Wine, Modern, American, Sleek, Homemade, Perfect, Fresh',
        annual_dol: 2442.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8379,
        store_longitude: -73.8607,
        total_orders: 79.72,
        neighborhood: 'East Bronx/Parkchester',
        keywords: 'Cajun, Flavorful, American, Southern, Authentic, Fun, Casual, Great',
        annual_dol: 325.87,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6625,
        store_longitude: -73.5534,
        total_orders: 646.74,
        neighborhood: '',
        keywords:
            'Steak, Burger, Chicken Parmigiana, Salad, Gluten Free, Vodka, Beer, Bourbon, Dessert, Bbq',
        annual_dol: 694.56,
        description:
            'Burgers, steak & seafood are on offer at this contemporary American grill & bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7546,
        store_longitude: -73.6881,
        total_orders: 1291.34,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Salad, Gourmet, Godfather, Yummy, Casual, Friendly, Local, Sensational',
        annual_dol: 1351.84,
        description:
            'Bustling, family-run trattoria serving homestyle Italian cuisine & pizzas, with a gourmet food shop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.694,
        store_longitude: -73.9025,
        total_orders: 200.23,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Pizza, Spicy, Delicious, Coffee, Cozy, Rum, Homemade, Amazing, Casual, Local',
        annual_dol: 515.97,
        description:
            '"COFFEE shop hours M-F 9AM-4PM BRICK OVEN PIZZA hours Mon,Thurs& Sun 4PM-10PM Fri & Sat 4PM-1030PM Tue& Wed Closed BRUNCH Sat - Sun 11am - 4pm"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7673,
        store_longitude: -73.9593,
        total_orders: 1451.36,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Garlic Bread, Lobster, Salad, Italian, Wine, Caesar Salad, American, Casual, Cobb Salad',
        annual_dol: 1517.51,
        description:
            'American comfort food & a casual environment are the lures of this veteran neighborhood eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8543,
        store_longitude: -73.8888,
        total_orders: 94.58,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Dessert, Coffee, Pizza, Cozy, Italian, Comfortable, Sweet, Rustic, Friendly, Vibrant',
        annual_dol: 231.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7656,
        store_longitude: -73.9655,
        total_orders: 27.81,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Wine, Dessert, Vegetarian, French Onion Soup, French, Cozy, Colorful, Fresh, Casual',
        annual_dol: 75.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7688,
        store_longitude: -73.9565,
        total_orders: 98.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Cheesesteak, Czech, Salad, Wine, Austrian, German, Beer, Peruvian, Hungarian',
        annual_dol: 112.11,
        description:
            'This Central European eatery in the Bohemian National Hall serves traditional fare & pilsner beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7709,
        store_longitude: -73.962,
        total_orders: 28.21,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Pizza, Sushi, Salad, Mexican, Italian, Coffee, Thai, Hotel, Organic, Asian',
        annual_dol: 115.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.9916,
        total_orders: 2118.85,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Steak, Garlic Bread, Wine, Bbq, Beer, Margarita, Asian, American, Perfect, Friendly',
        annual_dol: 2179.03,
        description:
            'Easygoing stop, located inside Penn Station, serving a large menu of American bar fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6641,
        store_longitude: -73.9841,
        total_orders: 1142.44,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Tequila, Steak, Salad, Pizza, Nachos, Beer, Dessert, Enchiladas, Paloma',
        annual_dol: 1475.78,
        description: '"Authentic Mexican cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7049,
        store_longitude: -73.9343,
        total_orders: 145.52,
        neighborhood: 'East Williamsburg',
        keywords:
            'Wine, Beer, American, Eclectic, Perfect, Intimate, Fantastic, High-End, Amazing, Wonderful',
        annual_dol: 594.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7252,
        store_longitude: -73.9925,
        total_orders: 3328.95,
        neighborhood: 'NoHo',
        keywords:
            'Whiskey, Lobster, Spacious, American, Perfect, Smooth, Fresh, Innovative, Classic, Hip',
        annual_dol: 13211.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7284,
        store_longitude: -74.0001,
        total_orders: 3657.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Salad, Gin, Crisp, Classy, Vibrant, Fresh, Colorful, Great, Casual',
        annual_dol: 3734.68,
        description:
            'Barnlike multimedia concert venue, bar & art gallery that showcases avant-garde acts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8004,
        store_longitude: -73.9549,
        total_orders: 636.57,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Wine, Pizza, Hotel, French, Premium, African, Welcoming, Perfect, Succulent, Great',
        annual_dol: 664.17,
        description: '"Cozy Queer Lounge Experience in the Heart of Harlem! Welcoming to All!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9965,
        total_orders: 1294.01,
        neighborhood: 'Nolita',
        keywords: 'Salad, Sushi, Tasty, Wine, Delicious, Thai, Chinese, Gin, Hotel, Asian',
        annual_dol: 5289.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -74.0001,
        total_orders: 2799.87,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Mexican, Tequila, Burger, Beer, Margarita, Gluten Free, Salad, Wine, Enchiladas, Upscale',
        annual_dol: 3535.12,
        description:
            'Vibrant cantina serving modern Mexican cuisine, made-to-order guacamole & tequila cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9851,
        total_orders: 3088.07,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Upscale, Italian, Elegant, Dessert, Japanese, Rooftop, High-End, Perfect, Outstanding, Modern',
        annual_dol: 3870.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7571,
        store_longitude: -73.984,
        total_orders: 1402.36,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Lobster, Salad, Wine, Roast Beef, Delicious, Elegant, Caesar Salad, Ribeye Steak',
        annual_dol: 1440.15,
        description:
            'Casual weekday counter serve for hefty burgers, pizza, sandwiches & hot dogs, plus breakfast eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9812,
        total_orders: 2120.46,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Flavorful, Salad, Gluten Free, Delicious, Wine, Warm, Organic, Sustainable, Greek, Mediterranean',
        annual_dol: 2165.45,
        description:
            'Grilled whole fish stars on a classic Greek menu at this bright, airy bistro with a long wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9862,
        total_orders: 118.79,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Garlic Bread, Burger, Lobster, Steak, Chicken Pot Pie, Pizza, Shrimp Cocktail, Salad, Beer, Margarita',
        annual_dol: 157.26,
        description:
            'Revered, 3-floor saloon with rooftop seating, private booths, a pub grub menu & a range of beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7003,
        store_longitude: -73.9875,
        total_orders: 1245.34,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Beer, Burger, Wine, Southern, Spacious, Russian, Playful, American, Rooftop, Casual',
        annual_dol: 1448.67,
        description:
            'American meals, craft beers & cocktails in a playful interior with graffitied walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.9969,
        total_orders: 917,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Pizza, Salad, Italian, Coffee, Wine, Japanese, Spanish, Bbq, Ramen',
        annual_dol: 983.13,
        description:
            'Fancy ground-floor food court housing revered vendors, plus a mix of counter & communal seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.991,
        total_orders: 37.5,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Beer, Ale, Brazilian, American, Latin, Traditional, Classic, Caipirinha',
        annual_dol: 153.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9863,
        total_orders: 37.16,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Vegan, Mexican, Pizza, Beer, Mimosa, Margarita, Japanese, Champagne, Italian',
        annual_dol: 151.88,
        description: '"A new modern restaurant with Latin twist"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6785,
        store_longitude: -73.9835,
        total_orders: 1265.8,
        neighborhood: 'Gowanus',
        keywords: 'Casual, Local, Diverse',
        annual_dol: 1320.68,
        description:
            'Performance venue in a former warehouse with diverse programming, from jazz & hip-hop to art & film.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7424,
        store_longitude: -74.0041,
        total_orders: 5896.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Lobster, Lobster Roll, Shrimp Cocktail, Gluten Free, Hotel, Sashimi, Classic, Approachable, Local',
        annual_dol: 7704.64,
        description:
            'Wagyu & dry-aged steaks plus seafood & cocktails prepped in a glamorous, stylishly appointed locale.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7398,
        store_longitude: -73.9982,
        total_orders: 322.21,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Gin, Eclectic, Casual, Israeli, Lively, Local, Great, Creative',
        annual_dol: 1317.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7703,
        store_longitude: -73.8934,
        total_orders: 37.05,
        neighborhood: 'Astoria Heights/East Elmhurst/Flushing',
        keywords:
            'Pizza, Lobster, Italian, Shrimp Cocktail, Salad, Delicious, Wine, Coffee, Awesome, Fresh',
        annual_dol: 64.72,
        description:
            '"Best Friends Antonio Roselli & Antonio Ordas blend Italy and Spain into a culinary delight that is wonderfully authentic and yet unique!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7134,
        store_longitude: -73.9515,
        total_orders: 354.51,
        neighborhood: 'Williamsburg',
        keywords:
            'Hamburger, Vegetarian, Pizza, Sushi, Dessert, Japanese, Ramen, American, Homemade, Asian',
        annual_dol: 1449.12,
        description:
            'Japanese restaurant specializing in udon dishes, served in a simple, wood-accented space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7101,
        store_longitude: -73.9635,
        total_orders: 1328.26,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Mexican, Wine, Gourmet, Trendy, Spanish, Thai, American, Eclectic, Asian, Intimate',
        annual_dol: 1356.45,
        description:
            'Cool haunt offering live music most nights, plus gourmet bar fare, creative drinks & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6216,
        store_longitude: -73.7258,
        total_orders: 256.99,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Italian, Coffee, Artisanal, American, Upscale, Premium, Casual, Local',
        annual_dol: 256.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7161,
        store_longitude: -73.9625,
        total_orders: 1615.09,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Salad, Gourmet, Beer, Dessert, Korean, Global, Vintage, Recommended',
        annual_dol: 1725.15,
        description: "Movies screened with global fare & craft cocktails served at patrons' seats.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7469,
        store_longitude: -73.9845,
        total_orders: 606.89,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Korean, Spicy, Wine, Bbq, Premium, Perfect, Traditional, Authentic, Western, Great',
        annual_dol: 752.3,
        description:
            'Late-keeping restaurant offering traditional Korean specialties & booze in smart surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7021,
        store_longitude: -73.8048,
        total_orders: 41.45,
        neighborhood: 'Jamaica',
        keywords: 'Salad, Casual, Premium, Latin, Fresh, Fabulous, American',
        annual_dol: 169.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7561,
        store_longitude: -73.9244,
        total_orders: 17760.73,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Mexican, Tequila, Tacos, Cuban, Gourmet, American, Latin, Delicious, Innovative, Spacious',
        annual_dol: 22808.17,
        description:
            'Spacious cantina dishing up gourmet tacos, ceviche & other Mexican plates, plus tequilas & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7416,
        store_longitude: -73.9809,
        total_orders: 1322.87,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Tasty, Cool, American, Traditional, Casual, Excellent, Rooftop',
        annual_dol: 5478.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -73.9816,
        total_orders: 919.24,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Sushi, Salad, Tasty, Wine, Delicious, Japanese, Warm, Impeccable, Asian, Polished',
        annual_dol: 3757.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7646,
        store_longitude: -73.9755,
        total_orders: 23804.51,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Italian, Wine, Rustic, American, Modern, Caesar Salad, Premium',
        annual_dol: 23828.31,
        description:
            'Steakhouse in a hip, industrial space known for its premium chops & housemade ice cream.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9902,
        total_orders: 3536.55,
        neighborhood: 'Lower East Side',
        keywords: 'Mexican, Beer, Margarita, Wine, Salad, Gin, Latin, Eclectic, Intimate, Friendly',
        annual_dol: 9663.47,
        description:
            'Creative Mexican cantina with an art gallery upstairs & a performance space in the basement.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7151,
        store_longitude: -73.9919,
        total_orders: 595.62,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Hotel, Legendary, American, Iconic, Elegant, Italian, Tasty, Impeccable, Asian, Fancy',
        annual_dol: 2434.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9932,
        total_orders: 4167.92,
        neighborhood: 'Bowery',
        keywords:
            'Wine, Moroccan, Luxurious, Spicy, Delicious, Hotel, Inviting, Impeccable, Sweet, Intimate',
        annual_dol: 5147.43,
        description:
            'Moroccan-themed duplex lounge offering hookahs, pillow-filled banquettes & occasional belly dancing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9888,
        total_orders: 444.52,
        neighborhood: 'Lower East Side',
        keywords: 'Pizza, Salad, Gluten Free, Japanese, Beer, Asian, Casual, Cool, Fusion, Great',
        annual_dol: 989.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6947,
        store_longitude: -73.849,
        total_orders: 51.67,
        neighborhood: 'Jamaica/Woodhaven',
        keywords: 'Hurricane, Spacious, American, Perfect, Modern, Friendly, Diverse',
        annual_dol: 211.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7358,
        store_longitude: -74.0005,
        total_orders: 2519.71,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Scotch, Irish, Creamy, Southern, Lively, Fiery, Great, Casual, Chill',
        annual_dol: 2742.35,
        description:
            'Weekends are lively at this neighborhood tavern offering Irish-accented pub food alongside the beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7359,
        store_longitude: -73.9939,
        total_orders: 466.31,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Luxurious, Hotel, Magical, Dessert, Fancy, Perfect, Urban, Great, Fine',
        annual_dol: 486.52,
        description:
            '"Manhattan Penthouse is located in Union Square. Palladian windows are located throughout the venue offering panoramic skyline views of New York City. The loft-like event space can accommodate up to 180 guests."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7374,
        store_longitude: -73.9966,
        total_orders: 43.55,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tequila, Beer, Rich, Hotel, Delicious, Gin, Spanish, Irish, Awesome, Mediterranean',
        annual_dol: 178,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8658,
        store_longitude: -73.9273,
        total_orders: 8.42,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Burger, Pizza, Salad, American, Premium, Casual, Authentic, Excellent, Attentive',
        annual_dol: 34.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -73.9881,
        total_orders: 934.38,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Steak, Burger, Bourbon, Beer, Wine, French Toast, Nachos, French, Hotel, Irish',
        annual_dol: 976.8,
        description:
            'Dark-wood sports bar featuring projection screens & 40+ TVs, plus a menu of wings, nachos & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -73.9452,
        total_orders: 465.75,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Margarita, Beer, Mexican, Nachos, French, Relaxed, Fun, Welcoming, Casual',
        annual_dol: 475.63,
        description:
            '"We are a neighborhood bar, welcoming all individuals and celebrations. In loving memory of our dear friend and mermaid Diane."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7105,
        store_longitude: -73.9635,
        total_orders: 2391.87,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Burger, Salad, Italian, Delicious, Stylish, Crisp, French, Ramen, American, Caesar Salad',
        annual_dol: 2606.77,
        description:
            'Stylish, modern bar & restaurant offering handcrafted cocktails, bar bites & global plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6719,
        store_longitude: -73.9573,
        total_orders: 22.79,
        neighborhood: 'Crown Heights',
        keywords: 'Beer, Wine, Salad, Korean, Spicy, Asian, Awesome, Aesthetic, Quirky, Fusion',
        annual_dol: 93.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.9808,
        total_orders: 2609.16,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Vegan, Cheesesteak, Bbq, Beer, Wine, Margarita, Nachos, Legendary, Healthy',
        annual_dol: 3370.45,
        description:
            'Relaxed joint for Southern-style BBQ, including pulled pork & dry-rub ribs, plus sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -73.9777,
        total_orders: 1690.51,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Wine, Salad, Beer, Trendy, Mediterranean, Romantic, Meatball, Classy, Friendly, Fun',
        annual_dol: 1802.89,
        description:
            'Creative cocktails, craft brews & Mediterranean plates served in an intimate, pared-down locale.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8278,
        store_longitude: -73.9246,
        total_orders: 472.57,
        neighborhood: 'Concourse/West Bronx',
        keywords:
            'Beer, Sushi, Hamburger, Margarita, Wine, Chinese, Local, Modern, American, Friendly',
        annual_dol: 526.96,
        description:
            'Bustling alehouse featuring draft microbrews, cocktails & pub grub in a compact, modern interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8069,
        store_longitude: -73.9534,
        total_orders: 314.89,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Mongolian, Stylish, Mimosa, Organic, Awesome, Great, Global, Bloody Mary, Mouth-Watering, Casual',
        annual_dol: 1102.86,
        description:
            '"Global Sports Bar serving you mouthwatering dishes in an impeccably designed space by the one and only James Beard Award winning chef, Serena Bass."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7561,
        store_longitude: -73.8815,
        total_orders: 41.86,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords: 'Steak, Cheesesteak, Tasty, Latin, Casual',
        annual_dol: 211.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.802,
        store_longitude: -73.9569,
        total_orders: 122.23,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Vegetarian, Organic, French, Modern, Homemade, Dutch, Casual, Great, Amazing',
        annual_dol: 505.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8493,
        store_longitude: -73.6457,
        total_orders: 128.51,
        neighborhood: '',
        keywords:
            'Pizza, Butter Chicken, Vegetarian, Salad, Flavorful, Chicken Tikka Masala, Spicy, Delicious, Bourbon, Bbq',
        annual_dol: 525.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.987,
        total_orders: 1328.03,
        neighborhood: 'Two Bridges',
        keywords:
            'Wine, Whiskey, Beer, Trendy, Cozy, Jagermeister, Delicious, Crisp, Friendly, Sweet',
        annual_dol: 1486.22,
        description:
            'Shotgun space with a crisp bar & big red booths in back for cocktails & fancy snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8045,
        store_longitude: -73.9473,
        total_orders: 1071.87,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Wine, Stylish, Steak, Delicious, Warm, Beer, Creamy, French, Friendly, Radiant',
        annual_dol: 1094.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9791,
        total_orders: 641.82,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vodka, Champagne, Russian, Rich, Vegetarian, Gluten Free, Elegant, Magical, Glamorous, Romantic',
        annual_dol: 1686.94,
        description:
            'Continental classics like borscht, caviar & vodka served in a flashy, opulent setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9842,
        total_orders: 822.63,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Vodka, Russian, Burger, Spacious, Indian, Innovative, Perfect, Creative',
        annual_dol: 1689.19,
        description:
            'The Neil Simon Theatre, originally the Alvin Theatre, is a Broadway theater at 250 West 52nd Street in the Theater District of Midtown Manhattan in New York City. Opened in 1927, the theater was designed by Herbert J. Krapp and was built for Alex A. Aarons and Vinton Freedley.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8204,
        store_longitude: -73.9591,
        total_orders: 1023.96,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Mexican, Tacos, Beer, Margarita, Chili Con Carne, Spicy, Delicious, Perfect, Sweet, Authentic',
        annual_dol: 2963.08,
        description:
            'Laid-back restaurant & bar with a terrace offering traditional Mexican cuisine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9882,
        total_orders: 1678.84,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Italian, Cozy, Karaoke, Sleek, Perfect, Casual, Rum',
        annual_dol: 1751.63,
        description:
            'Sleek, snug cocktail spot offering live acts, karaoke & drag shows to a theater-focused crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.777,
        store_longitude: -73.8954,
        total_orders: 1286.47,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords: 'Gourmet, British, Exceptional',
        annual_dol: 5258.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.9914,
        total_orders: 87.33,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Pizza, Vegan, Salad, Wine, Beer, Thai, Ramen, Vivid, Retro, Fun',
        annual_dol: 328.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -73.9927,
        total_orders: 52.23,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Spectacular, Innovative, Intimate, Modern, Unique',
        annual_dol: 91.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7391,
        store_longitude: -73.9929,
        total_orders: 4709.84,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Kebab, Salad, Tasty, Delicious, Greek, Rich, Organic, Mediterranean, Fun',
        annual_dol: 5105.85,
        description:
            'Greek restaurant from Long Island with traditional cuisine & pre-fixe lunch menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -74.0039,
        total_orders: 74.03,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Salad, Italian, Wine, Delicious, Beer, Ale, Warm, Old Fashioned, Quaint',
        annual_dol: 242.21,
        description:
            'Coal-fired Neapolitan pizza & Italian entrees feature at this quaint spot with sidewalk tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9832,
        total_orders: 1366.5,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Meatloaf, Gluten Free, Wine, Chicken Wings, Rustic, Fried Chicken, Healthy, Sweet, American',
        annual_dol: 1878.52,
        description:
            'Elevated Southern fare, barrel-aged cocktails & craft beer in a small rustic space with a market.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9781,
        total_orders: 1506.03,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Tequila, Spicy, Beer, Delicious, Mimosa, Gin, Korean, Cozy, Bbq, Nachos',
        annual_dol: 1537.98,
        description:
            'Rustic-chic eatery with global fare, TV sports, farm decor & weekend brunch with unlimited drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7526,
        store_longitude: -74.0015,
        total_orders: 400.81,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tomato Soup, Spanish, Delicious, American, Casual, Classic, Great, Chill',
        annual_dol: 6066.89,
        description:
            'Sprawling Spanish food market from José Andrés & Adrià brothers with restaurants & kiosks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7792,
        store_longitude: -73.9505,
        total_orders: 108.77,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vietnamese, Peruvian, Hotel, Red Curry, Sweet, Perfect, Western, Casual, Authentic, Pho',
        annual_dol: 444.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9819,
        total_orders: 1119.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Kebab, Steak, Glamorous, Rich, Wine, Warm, Mediterranean, Turkish, Refined, Memorable',
        annual_dol: 1957.74,
        description:
            'Contemporary venture highlighting Mediterranean flavors in a refined setting with a warm ambiance.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7674,
        store_longitude: -73.9958,
        total_orders: 1223.37,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Upscale, Exotic, Impressive, Great, Rooftop',
        annual_dol: 1276.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7223,
        store_longitude: -73.9874,
        total_orders: 163.97,
        neighborhood: 'Lower East Side',
        keywords:
            'Hotdog, Salad, Turkey Sandwich, Beer, Legendary, Juicy, Reuben Sandwich, Cozy, Classic, French',
        annual_dol: 251.18,
        description:
            'No-frills deli with theatrically cranky service serving mile-high sandwiches since 1888.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8636,
        store_longitude: -73.9263,
        total_orders: 385.3,
        neighborhood: 'Fort George',
        keywords:
            'Steak, Rooftop, Tasty, Dominican, Delicious, Spacious, Trendy, Gourmet, Wine, American',
        annual_dol: 472.6,
        description:
            'Trendy eatery & nightclub with a rooftop lounge bar, DJs & traditional Dominican eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6861,
        store_longitude: -73.9736,
        total_orders: 1019.51,
        neighborhood: 'Fort Greene',
        keywords:
            'Salad, Coffee, French, Delicious, Casual, Friendly, American, Local, Chic, Creative',
        annual_dol: 1109.49,
        description:
            'Inventive cocktails & French-inspired classics, plus small plates & brunch, served in a chic bistro.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7419,
        store_longitude: -74.0008,
        total_orders: 83.72,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Thai, Wine, Beer, Fried Rice, Delicious, Pad Thai, Classic, Casual, Local',
        annual_dol: 342.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7364,
        store_longitude: -73.9513,
        total_orders: 16.71,
        neighborhood: 'Greenpoint',
        keywords:
            'Korean, Wine, Beer, Organic, Delicious, Ale, Tangy, Sweet, Artisanal, Traditional',
        annual_dol: 68.31,
        description:
            '"Artisanal Korean rice alcohols made with organic rice, using traditional Korean Brewing methodologies"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7114,
        store_longitude: -73.9476,
        total_orders: 87.6,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Korean, Delicious, Asian, American, Natural, Casual, Fusion, Great, Unique',
        annual_dol: 104.65,
        description:
            '"Mad for Chicken offers a unique food experience. We believe food should be enjoyed in three ways-visual, smell, and taste. We are confident to serve food that will satisfy all three senses."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6011,
        store_longitude: -74.1628,
        total_orders: 7.92,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Vegetarian, Vegan, Salad, Nepalese, Chinese, Fancy, Indian, Awesome, Classic, Biryani',
        annual_dol: 32.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.741,
        store_longitude: -73.9818,
        total_orders: 1628.6,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Mexican, Tacos, Vegan, Nachos, Margarita, Organic, Casual, Friendly, Hip, Local',
        annual_dol: 1679.57,
        description:
            'Hip source for Mexican eats with frozen & popsicle cocktails in a funky, tiled space with a truck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9774,
        total_orders: 99.75,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Sushi, Steak, Salad, Wine, Upscale, Delicious, Spicy, Elegant, Classy, Fabulous',
        annual_dol: 248.35,
        description:
            'Upscale kosher steakhouse showcasing dry-aged beef, veal & sushi in an elegant, multifloor setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -73.9799,
        total_orders: 351.91,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Mexican, Beer, Gluten Free, Delicious, Italian, Fun, Mediterranean, Vibrant, Moroccan, Turkish',
        annual_dol: 826.47,
        description:
            'Fashionable restaurant with a menu of Mediterranean favorites, plus cocktails & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.97,
        total_orders: 447.48,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Italian, Champagne, Gluten Free, Upscale, Organic, Hotel, Quaint, Homemade, Modern',
        annual_dol: 498.24,
        description:
            "High-ticket Italian fare served in a busy setting that's popular with a European crowd.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5311,
        store_longitude: -74.2254,
        total_orders: 335.66,
        neighborhood: 'Charleston',
        keywords: 'Burger, Salad, American, Ale, Casual, Local, Classic, Lively',
        annual_dol: 421.75,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7237,
        store_longitude: -73.869,
        total_orders: 98.77,
        neighborhood: 'Middle Village/Rego Park/Flushing',
        keywords: 'Burger, Tasty, Delicious, Beer, Spacious, Cozy, Bbq, Irish, Friendly, Fantastic',
        annual_dol: 107.48,
        description:
            'Hearty American & global pub grub in an old-fashioned setting with sports on TV & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6427,
        store_longitude: -73.9787,
        total_orders: 34.93,
        neighborhood: 'Kensington',
        keywords: 'Steak, Burger, Bourbon, Wine, Coffee, Karaoke, Irish, Fresh, Casual, Great',
        annual_dol: 51.76,
        description:
            'Neighborhood hangout featuring pub grub & a pool table in old-school, unpretentious surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7388,
        store_longitude: -73.9885,
        total_orders: 266.39,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Lobster, Salad, Chicken Parmigiana, Shrimp Cocktail, Vegan, Italian, Wine, Upscale, Caesar Salad, Modern',
        annual_dol: 277.94,
        description:
            'High-end, elegant mainstay with white interiors & a terrace, focusing on steaks & Italian cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.9914,
        total_orders: 177.5,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Pizza, French, Premium, Relaxed, American, Ramen, Awesome, Outstanding, Cool, Casual',
        annual_dol: 177.5,
        description:
            'Tiny, tough-to-reserve eatery via David Chang offering multicourse, Asian-accented American meals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.8328,
        total_orders: 3567.41,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Pizza, Chinese, Casual, Local',
        annual_dol: 15453.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.8285,
        total_orders: 347.64,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Hotel, Rooftop, Wine, Trendy, Sophisticated, Remarkable, Sustainable, Perfect, Signature, Unique',
        annual_dol: 355.02,
        description:
            "There are basic rooms, as well as sophisticated rooms offering flat-screen TVs, Wi-Fi access, coffeemakers and rainfall showers. Some rooms have free-standing tubs or private patios. Suites add sitting areas.The trendy lounge/bar has a rooftop patio with fire pits and Manhattan views. There's also a free shuttle to an affiliated spa.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -74.0102,
        total_orders: 1754.91,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Mexican, Delicious, Classy, Exotic, Authentic, Awesome, Casual, Fine',
        annual_dol: 1858.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6799,
        store_longitude: -73.9429,
        total_orders: 953.21,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Beer, Ale, Whiskey, Bourbon, American, Hotel, Cozy, Traditional, Intimate, Western',
        annual_dol: 973.43,
        description:
            'Cozy, intimate bar offering a range of tap beers & small bites, plus chess, darts & sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7803,
        store_longitude: -73.9526,
        total_orders: 738.82,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Sushi, Lobster, Salad, Spicy, Japanese, Sashimi, Friendly, Classy, Casual, Clean',
        annual_dol: 813.39,
        description:
            'An extensive variety of sushi & rolls plus drinks from a full bar are served in a clublike setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9879,
        total_orders: 998.55,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Beer, Iconic, Classic, Southern, Excellent, Contemporary, Creative, Local',
        annual_dol: 4416.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7779,
        store_longitude: -73.9484,
        total_orders: 699.39,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Irish, Warm, Friendly, Cool, Casual, Impressive, Great',
        annual_dol: 797.78,
        description:
            'Neighborhood Irish pub open since 1957, with billiards, darts & a jukebox in a laid-back ambiance.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7576,
        store_longitude: -73.9844,
        total_orders: 1469.3,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Steak, Shrimp Cocktail, Vegetarian, Salad, Gluten Free, Beer, French, Irish, Spicy',
        annual_dol: 5158.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7382,
        store_longitude: -73.9833,
        total_orders: 10.35,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Italian, Coffee, Organic, Delicious, French, Modern, American, Prosecco, Greek',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6942,
        store_longitude: -73.961,
        total_orders: 427.58,
        neighborhood: 'Clinton Hill',
        keywords: 'Salad, Beer, Donut, Warm, Friendly, Homemade, Seasonal, Vibrant, Casual, Great',
        annual_dol: 1747.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6686,
        store_longitude: -73.9532,
        total_orders: 365.49,
        neighborhood: 'Crown Heights',
        keywords: 'Pizza, Beer, Wine, Warm, Inviting, Friendly, Phenomenal, Casual, Great, Local',
        annual_dol: 1283.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7284,
        store_longitude: -73.9993,
        total_orders: 5886.34,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Scotch, Beer, Burger, Bourbon, Coffee, Cozy, Delicious, American, Perfect, Authentic',
        annual_dol: 6141.55,
        description:
            'Both local & nationally known blues acts perform at this laid-back second-floor music club.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7225,
        store_longitude: -73.9953,
        total_orders: 102.54,
        neighborhood: 'Nolita',
        keywords:
            'Wine, Tasty, Italian, Vegetarian, Avocado Toast, Cozy, Beer, Premium, Yummy, Friendly',
        annual_dol: 564.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9606,
        total_orders: 10.81,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Margarita, Gluten Free, Martini, Iranian, Cosmopolitan, Lebanese, French, Homemade, Greek',
        annual_dol: 44.2,
        description:
            'Laid-back Persian eatery offering kebabs & grilled meat plates, plus salads & rice dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7489,
        store_longitude: -73.9408,
        total_orders: 4920.56,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Lobster, Lobster Roll, Wine, Shrimp Cocktail, Beer, Whiskey, Tasty, Salad, Gourmet, Warm',
        annual_dol: 5471.01,
        description:
            'Brick- & wood-lined space offering cafe bites, charcuterie & cocktails along with live bands & DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7035,
        store_longitude: -73.8021,
        total_orders: 1946.46,
        neighborhood: 'Jamaica',
        keywords: 'American, Hotel, Premium, New Zealand, Fun, Great',
        annual_dol: 7956.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.864,
        store_longitude: -73.8648,
        total_orders: 195.5,
        neighborhood: 'Allerton',
        keywords:
            'Lobster, Steak, Cheesesteak, Cajun, Vegan, Wine, Delicious, Fried Rice, Creamy, Trendy',
        annual_dol: 222.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.8335,
        total_orders: 12381.83,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Hotel, Champagne, Upscale, Tasty, Romantic, Rooftop, Cajun, Fun, Great, Chic',
        annual_dol: 13256.06,
        description:
            'Chic Hyatt Place rooftop venue & terrace offering craft cocktails, Taiwanese small plates & views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8731,
        store_longitude: -73.8893,
        total_orders: 7.71,
        neighborhood: 'Jerome Park/West Bronx',
        keywords:
            'Burger, Tacos, Pizza, Salad, Shrimp Cocktail, Dominican, Fried Chicken, French, American, Delicious',
        annual_dol: 31.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8273,
        store_longitude: -73.9495,
        total_orders: 59.52,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Burger, Steak, Coffee, Beer, Wine, Savory, Italian, Dessert, Delicious, Trendy',
        annual_dol: 243.29,
        description:
            'Informal bar & restaurant offering all-day dining, plus cocktails & weekly live jazz.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5197,
        store_longitude: -74.2351,
        total_orders: 291.22,
        neighborhood: 'Tottenville',
        keywords:
            'Pizza, Meatball, Italian, Gourmet, Salad, Homemade, Sweet, Fresh, Casual, Traditional',
        annual_dol: 303.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9911,
        total_orders: 2781.06,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vodka, Garlic Bread, Wine, Tasty, Upscale, Burger, Beer, Margarita, Spicy, Nachos',
        annual_dol: 3003.06,
        description:
            'College-type sports bar with TVs to watch the game, beers on tap & extensive pub food options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9849,
        total_orders: 1843.62,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Tasty, Beer, Delicious, Salad, Belgian, Fun, Friendly, Awesome, Lively',
        annual_dol: 1882.74,
        description:
            'Cafe for Belgian specialties including oysters, mussels & burgers, plus drinks, in a lively setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.511,
        store_longitude: -74.2413,
        total_orders: 713.15,
        neighborhood: 'Tottenville',
        keywords:
            'Italian, Meatball, Wine, Elegant, Quaint, Yummy, Friendly, Authentic, Outstanding, Casual',
        annual_dol: 728.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -74.0039,
        total_orders: 16688.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Scottish, Spectacular',
        annual_dol: 17080.31,
        description:
            'An interactive film-noir Macbeth experience by a UK production company in a 5-story 1930s hotel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7503,
        store_longitude: -73.9866,
        total_orders: 399.28,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Stylish, Korean, Modern, Comfortable, Cajun, Hotel, Friendly, French, French Onion Soup, Great',
        annual_dol: 513.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7796,
        store_longitude: -73.9775,
        total_orders: 1246.99,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Tequila, Beer, Wine, Scotch, Bourbon, Trendy, Fun, Classy, American, Classic',
        annual_dol: 1337.07,
        description:
            'Not-so-divey bar where board games, free candy & a fish tank come with the beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6117,
        store_longitude: -74.0098,
        total_orders: 2057.88,
        neighborhood: 'Bath Beach/Bensonhurst',
        keywords: 'Steak, Burger, Beer, Crisp, Juicy, American, Fresh, Creole, Great, Casual',
        annual_dol: 2239.08,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7765,
        store_longitude: -73.9793,
        total_orders: 878.15,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Italian, Meatball, Roast Beef, Gourmet, Beer, French, Cozy, American, Fun, Casual',
        annual_dol: 916.23,
        description:
            'Chicken parm & other Italian-American classics go gourmet at this hip, diner-inspired space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7775,
        store_longitude: -73.9836,
        total_orders: 459.28,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Steak, Italian, Salad, Wine, Bruschetta, Hotel, Caesar Salad, American, Local, Casual',
        annual_dol: 1764.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9989,
        total_orders: 4746.84,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Burger, Hamburger, Beer, Delicious, Upscale, French, American, Classy, Retro, Vintage',
        annual_dol: 5062.39,
        description:
            'Simple pub grub, beer & cocktails are served in this classic old SoHo pub, dating back to 1847.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6684,
        store_longitude: -73.9532,
        total_orders: 532.71,
        neighborhood: 'Crown Heights',
        keywords:
            'Steak, Lobster, Pizza, Salad, Cajun, Ale, Phenomenal, Creole, Classic, Impressive',
        annual_dol: 1302.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.728,
        store_longitude: -74.0009,
        total_orders: 32.69,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Coffee, Upscale, Delicious, Cozy, Avocado Toast, French Toast, Australian, Healthy, French, Bright',
        annual_dol: 34.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6394,
        store_longitude: -73.6424,
        total_orders: 46.2,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Delicious, Homemade, Asian, Comfortable, Fresh, Thai, Authentic, Classic',
        annual_dol: 188.83,
        description:
            '"Fresh And Authentic Italian. Enjoy Great Food At Home! We offer a variety of convenient take-out options pickup at the restaurant, curbside pickup or Deliver it to your Home or Business so you can enjoy our delicious pizza and Italian dishes in the comfort of your own home! Give us a call or place your order online for lunch or dinner. Call or Click Today ."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.676,
        store_longitude: -73.9991,
        total_orders: 1329.87,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Mexican, Tequila, Nachos, Pizza, Margarita, Salad, Tex-Mex, Enchiladas, Fajitas, Cointreau',
        annual_dol: 1553.77,
        description:
            'Multiple house specialties, fajita choices & margaritas amid traditional Mexican-inspired decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8625,
        store_longitude: -73.8955,
        total_orders: 89.97,
        neighborhood: 'Fordham Manor',
        keywords:
            'Pizza, Italian, Spanish, American, Latin, Local, Perfect, Classic, Casual, Great',
        annual_dol: 367.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7317,
        store_longitude: -73.9899,
        total_orders: 2522.56,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Coffee, Trendy, Hotel, Funky, Modern, Mediterranean, Playful, Perfect, Unique',
        annual_dol: 2945.71,
        description:
            'Empire State Building.Cozy, modern rooms provide flat-screen TVs, Netflix and safes, in addition to free Wi-Fi. Some rooms offer day beds; family rooms add bunk beds and sleep up to 4 guests.Amenities include a restaurant serving Mediterranean cuisine, a gym, and a cafe/bar with a terrace. Pets are welcome (surcharge). Valet parking is available (fee).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9936,
        total_orders: 4606.47,
        neighborhood: 'Bowery',
        keywords:
            'Hotel, Coffee, Trendy, Stylish, Vibrant, Sensational, Great, Friendly, Cool, Hip',
        annual_dol: 4766.09,
        description:
            "Building and 5 miles from the landmark One World Trade Center.Featuring wall-to-wall windows with city views, the snug, minimalist-chic rooms offer flat-screen TVs and free Wi-Fi, as well as minifridges and rainfall showers.Amenities include a trendy eatery, and a 24-hour bar that serves coffee and cocktails. There's also an airy, stylish lobby lounge filled with artwork and books.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7305,
        store_longitude: -73.9861,
        total_orders: 354,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Pizza, Italian, Beer, Wine, Spicy, Authentic, Classic, Casual, Fresh, Michelada',
        annual_dol: 369.34,
        description:
            'Casual chain restaurant specializing in wood-fired, Neapolitan pizza, plus classic Italian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6529,
        store_longitude: -73.9763,
        total_orders: 78.57,
        neighborhood: 'Windsor Terrace',
        keywords:
            'Rich, Delicious, Wine, Warm, Italian, Vegetarian, Cozy, Mediterranean, Healthy, Innovative',
        annual_dol: 321.18,
        description:
            'Italian-inflected cuisine & craft cocktails are showcased at this intimate, sophisticated spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7913,
        store_longitude: -73.9689,
        total_orders: 95.23,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Roast Beef, Beer, Bbq, American, Tasty, French, Casual, Sparkling',
        annual_dol: 176.51,
        description:
            'Kosher kitchen doling out burgers, light bites & boozy drinks in an informal environment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.9726,
        total_orders: 1175.48,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Italian, Wine, Caesar Salad, American, Classy, Classic, Healthy',
        annual_dol: 1398.5,
        description:
            'Steaks & Italian basics are served by an old-school crew at this circa-1932 eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7514,
        store_longitude: -73.9723,
        total_orders: 137.92,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Georgian, Wine, Legendary, Classic, Turkish, Delicious, Contemporary, Modern, Authentic, Warm',
        annual_dol: 150.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.761,
        store_longitude: -73.9729,
        total_orders: 1011.37,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Karaoke, Legendary, Perfect, Homemade, Fresh, Natural, Impressive, Comfortable',
        annual_dol: 1273.39,
        description:
            "The Friars Club is a private club in New York City. Famous for its risqué roasts, the club's membership is composed mostly of comedians and other celebrities. Founded in 1904, it is located at 57 East 55th Street, between Park Avenue and Madison Avenue, in the historic Martin Erdmann House.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -74.0035,
        total_orders: 241.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Chocolate Cake, Mexican, Dessert, Hotel, French, American, Eggs Benedict, Latin, Homemade, Asian',
        annual_dol: 591.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6312,
        store_longitude: -73.9473,
        total_orders: 893.48,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Salad, Wine, Beer, Caesar Salad, Friendly, Casual, American, Classic, Local',
        annual_dol: 1038.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7707,
        store_longitude: -73.9818,
        total_orders: 3821.65,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Champagne, Wine, Vintage, Modern, Sweet, Intimate, Cultural',
        annual_dol: 5643.91,
        description:
            'The David H. Koch Theater is a theater for ballet, modern and other forms of dance, part of the Lincoln Center, at the intersection of Columbus Avenue and 63rd Street in the Lincoln Square neighborhood of Manhattan in New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9884,
        total_orders: 4687.59,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Pizza, Beer, Cajun, Salad, Gourmet, Whiskey, Tasty, Martini, Wine',
        annual_dol: 4787.06,
        description:
            'Gastropub serving craft beer on tap, gourmet bar bites amid wide-screen TVs & a lounge with sofas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7414,
        store_longitude: -73.9917,
        total_orders: 26059.48,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Steak, Salad, Wine, Upscale, Rich, Korean, Spicy, Trendy, Savory, Juicy',
        annual_dol: 29349.1,
        description:
            'Upscale Korean steakhouse featuring tables with smokeless grills, plus an extensive wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6778,
        store_longitude: -73.9499,
        total_orders: 1319.22,
        neighborhood: 'Crown Heights',
        keywords:
            'Tasty, Tacos, Burger, Delicious, Pizza, Upscale, Creamy, Karaoke, Sweet, Bourbon',
        annual_dol: 1319.22,
        description:
            'Relaxed hangout featuring pub food, weekend brunch & live music nights amid quirky, steampunk decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7034,
        store_longitude: -73.9917,
        total_orders: 818.9,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Sushi, Pizza, Italian, Rum, American, Eclectic, Global, Perfect, Artisanal, Fresh',
        annual_dol: 2874.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8354,
        store_longitude: -73.6501,
        total_orders: 303.87,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Gluten Free, Italian, Delicious, Casual, Homemade, American, Friendly, Modern',
        annual_dol: 398.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9606,
        total_orders: 228.71,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Hotel, American, Wonderful, Traditional, Signature',
        annual_dol: 934.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6994,
        store_longitude: -73.9318,
        total_orders: 5.24,
        neighborhood: 'Bushwick',
        keywords:
            'German, Vegan, American, Margarita, Malaysian, Mediterranean, Local, Casual, Classic',
        annual_dol: 21.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.9417,
        total_orders: 421.96,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Italian, Meatball, Beer, Wine, Homemade, Artisanal, American, Casual, Local',
        annual_dol: 440.25,
        description:
            'Neapolitan pizzas & pastas are served at this Italian spot with exposed-brick walls & chic tilework.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8522,
        store_longitude: -73.789,
        total_orders: 112.44,
        neighborhood: 'City Island',
        keywords: 'Lobster, Steak, Sushi, Salad, Japanese, Wine, Spicy, Sashimi, Trendy, Modern',
        annual_dol: 786.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7333,
        store_longitude: -73.9909,
        total_orders: 3779.29,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Sushi, Upscale, Japanese, Intimate, Hip, Casual, Fantastic, Cool, Great, Omakase',
        annual_dol: 3916.9,
        description:
            'Compact, omakase-only Japanese choice for upscale sushi or more elaborate kaiseki meals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -73.8676,
        total_orders: 496.14,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Beer, Steak, South American, Gluten Free, Wine, Salad, Spicy, Upscale, Warm, Brazilian',
        annual_dol: 506.67,
        description:
            'Upscale Brazilian chain for all-you-can-eat meat carved tableside plus an extensive salad bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8056,
        store_longitude: -73.9653,
        total_orders: 1628.78,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Sushi, Wine, Coffee, Japanese, Italian, Stylish, Delicious, Asian, Hotel, Modern',
        annual_dol: 1716.77,
        description:
            '"Trendy Japanese place offering modern dishes & sushi in a lively atmosphere. The roots of SAPPS began where SHI Owner, Shih Lee used to eat as a teenager in the East Village. A no frills, authentic Japanese restaurant serving izakaya style dishes before the popular explosion of Japanese food on St. Marks Place. Fast forward to 2017, the vision starts with a determination to create a similar experience in LIC, but with a modern NYC vibe."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5354,
        store_longitude: -74.2398,
        total_orders: 29.85,
        neighborhood: 'Charleston',
        keywords: '',
        annual_dol: 43.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5837,
        store_longitude: -73.9502,
        total_orders: 723.28,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Cheesesteak, Beer, Coffee, French, Philly Cheesesteak, Wine',
        annual_dol: 795.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.7702,
        total_orders: 225.49,
        neighborhood: 'Bayside',
        keywords: 'Cajun, Bourbon, Ample, American, Cool, Chic, Great, Casual, Local, Chill',
        annual_dol: 251.07,
        description:
            'Chill neighborhood watering hole featuring regular DJs & a large back patio with a fire pit.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7675,
        store_longitude: -73.8277,
        total_orders: 246.3,
        neighborhood: 'Flushing',
        keywords: 'Complex',
        annual_dol: 928,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8874,
        store_longitude: -73.9048,
        total_orders: 221.33,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Burger, Tasty, Beer, Wine, Nachos, Irish, Warm, Homemade, Friendly, Bruschetta',
        annual_dol: 264.39,
        description:
            'Music, theater, comedy & poetry performances happen regularly at this local Irish pub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6333,
        store_longitude: -74.0269,
        total_orders: 683.87,
        neighborhood: 'Bay Ridge',
        keywords: 'Pizza, Hotdog, Beer, Wine, Awesome, Great, Fresh, Local, Casual, Chill',
        annual_dol: 720.42,
        description:
            'Low-key neighborhood joint pouring cocktails, wine & beer, with TVs & special events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.9932,
        total_orders: 520.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Vegetarian, Tequila, Mexican, Beer, Wine, Margarita, Rustic, Smooth, Friendly',
        annual_dol: 837.96,
        description:
            'An imaginative drinks list pairs with modern Mexican small plates in a chic, wood-and-brick space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.741,
        store_longitude: -73.9908,
        total_orders: 956.13,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Lobster, Lobster Roll, Vegetarian, Steak, Salad, Tasty, Gluten Free, Delicious, Japanese, Spicy',
        annual_dol: 976.42,
        description:
            'Seafood eatery with beachfront feel that has both a lobster & raw bar, plus wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9871,
        total_orders: 1922.48,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Vegetarian, Cheesesteak, Pizza, Cajun, Vodka, Dessert, Bourbon, Gin',
        annual_dol: 6749.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7248,
        store_longitude: -73.9926,
        total_orders: 1053.54,
        neighborhood: 'NoHo',
        keywords:
            'Vegetarian, Champagne, Vodka, Gluten Free, Beer, Tasty, Organic, Dessert, Upscale, Elegant',
        annual_dol: 1161.25,
        description:
            'Upscale American fare & craft cocktails presented in opulent environs with live music & burlesque.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7438,
        store_longitude: -73.9585,
        total_orders: 517.06,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Wine, Upscale, Beer, Crisp, American, Sustainable, Polished, French, Thai, Contemporary',
        annual_dol: 744.65,
        description:
            'An eclectic menu highlighting local purveyors is served in a sprawling, upscale setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -73.8946,
        total_orders: 117.32,
        neighborhood: 'Jackson Heights',
        keywords:
            'Burger, Salad, Bbq, Ale, Friendly, French, Sweet, Quesadillas, Outstanding, Casual',
        annual_dol: 147.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7267,
        store_longitude: -73.9999,
        total_orders: 3765.97,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Vodka, Wine, Beer, Coffee, Tasty, Delicious, Warm, Contemporary, American, Fusion',
        annual_dol: 4223.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7468,
        store_longitude: -73.8902,
        total_orders: 147.26,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Beer, Colombian, South American, American, Fun, Friendly, Great, Casual',
        annual_dol: 163.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7064,
        store_longitude: -74.0077,
        total_orders: 10377.25,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Dessert, Italian, American, Delicious, Spacious, Seasonal, Sidecar, Modern, Sleek',
        annual_dol: 11603.09,
        description:
            'Polished, new American restaurant with global influences inside spacious room with high ceilings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7792,
        store_longitude: -73.9567,
        total_orders: 742.76,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Salad, Dessert, Hotel, Fabulous, Classic, Fine',
        annual_dol: 990.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7809,
        store_longitude: -73.9766,
        total_orders: 310.05,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Lobster, Burger, Wine, Dessert, French, Beer, Hotel, Elegant, Casual, Fine',
        annual_dol: 310.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.734,
        store_longitude: -74.007,
        total_orders: 82.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Urban, Zen, Amazing',
        annual_dol: 336.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -74.0094,
        total_orders: 139.29,
        neighborhood: 'Hudson Square',
        keywords: 'Pizza, Sushi, Mexican, Beer, Wine, Italian, Spanish, Tasty, Latin, American',
        annual_dol: 454.76,
        description:
            'Tapas bar with traditional Spanish small plates, sangria & patio served to a Latin rock soundtrack.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8337,
        store_longitude: -73.6257,
        total_orders: 219.61,
        neighborhood: '',
        keywords: 'Italian, Pizza, Salad, Wine, Cozy, Casual, Authentic',
        annual_dol: 244.71,
        description:
            'Old-fashioned trattoria serving Italian classics in a cozy room with tin ceilings, plus an oak bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6652,
        store_longitude: -73.9894,
        total_orders: 7120.35,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Beer, Classic, Local, Casual, Great, Fantastic',
        annual_dol: 8037.9,
        description:
            'Buzzing, late-night gay bar with a dance floor & patio offering cabaret shows & classic drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7152,
        store_longitude: -74.0156,
        total_orders: 8062.04,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Hotel, Wine, Cosmopolitan, Mediterranean, Rooftop, American, Urban, Iconic, Spectacular, Contemporary',
        annual_dol: 8500.26,
        description:
            'The modern 1- and 2-bedroom suites include espresso machines, 2 flat-screen TVs and Wi-Fi (surcharge), plus curated artwork and plush spa-like bathrooms. Some rooms include river views. Upgrades suites add cosmopolitan decor, sweeping city views, dining tables and/or iMac computers.Amenities include a 24-hour fitness center, a barbershop, and a seasonal rooftop bar with a private green roof, plus a polished Mediterranean restaurant and wine bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6924,
        store_longitude: -73.9275,
        total_orders: 255.33,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Hotdog, Beer, Fun, Amazing, Perfect, Cool, Great, Creative, Casual, Chill',
        annual_dol: 272.11,
        description:
            'Hot dogs & cocktails are offered in this informal bar with arcade games & events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7038,
        store_longitude: -74.0081,
        total_orders: 8.95,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Spectacular, Perfect, Premium, Amazing, Great, Latin, Chic',
        annual_dol: 36.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -74.0043,
        total_orders: 1268.64,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vegan, Vegetarian, Beer, Bourbon, Perfect, Rum, Sleek, Refined, Casual, Great',
        annual_dol: 2126.33,
        description:
            'Breezy cantina pairing craft cocktails & beer with refined bar bites, burgers & vegan options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6576,
        store_longitude: -73.6695,
        total_orders: 72.27,
        neighborhood: '',
        keywords:
            'Salad, Chicken Caesar Salad, Dessert, Wine, Creamy, French Onion Soup, Irish, French, Swiss, Awesome',
        annual_dol: 131.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7893,
        store_longitude: -73.974,
        total_orders: 190.25,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Dessert, Wine, Mediterranean, Homemade, Turkish, American, Spectacular, Local, Fresh',
        annual_dol: 227.27,
        description:
            "This eatery's menu spans the Mediterranean, offering both meze samplers & brick-oven pizzas.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6445,
        store_longitude: -73.9201,
        total_orders: 5.32,
        neighborhood: 'East Flatbush',
        keywords: 'Ale, Fun, Spectacular, Awesome, Great, Caribbean, Perfect, Diverse',
        annual_dol: 21.73,
        description:
            '"Benthley’s Night Club 1370 Ralph avenue Brooklyn New York 11236 attracts celebrities, world Class Bands, DJ’S and partygoers from all over the glove for some of the most extravagant events the industry has to offer. The original beautiful monumental 4500 square foot destination offers an immersive party experience with extensive professional lighting, Big Screen Billboard, Several VIP Booths, Video and Laser Systems to enhance the diverse musical talent headlining the venue."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6363,
        store_longitude: -74.026,
        total_orders: 22.88,
        neighborhood: 'Bay Ridge',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Wine, Burritos, French, Casual, Comfortable, Local',
        annual_dol: 46.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6606,
        store_longitude: -73.9804,
        total_orders: 1811.9,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'Clean, Comfortable, Cool, Innovative, Great, Retro, Local, Recommended, Negroni',
        annual_dol: 1932.35,
        description:
            'Upbeat cinema with waiters serving food & drinks during the movie, plus a bar & retro video games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7686,
        store_longitude: -73.9105,
        total_orders: 90.12,
        neighborhood: 'Astoria',
        keywords: 'Burger, Spicy, Premium, American, Yummy, Hotel, Fun, Amazing, Caribbean, Great',
        annual_dol: 167.42,
        description: '"Eat • Drink • Smoke • Vibe"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6517,
        store_longitude: -73.9589,
        total_orders: 449.14,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Pizza, Wine, Rum, Caribbean, Jamaican, Trendy, American, Classy, Romantic, Latin',
        annual_dol: 580.95,
        description:
            'Warm, wood-&-brick-clad eatery & lounge offering updated Caribbean fare alongside rum drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.945,
        total_orders: 3637.21,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Margarita, Karaoke, Cozy, Opulent, Retro, Sleek, Awesome, Creative, Cool',
        annual_dol: 3714.38,
        description:
            'Creative cocktails & full bar offerings served in a sleek space with a Vegas throwback feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.9555,
        total_orders: 434.68,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vodka, Wine, Italian, Martini, Legendary, Meatball, Warm, Romantic, Homemade, Classy',
        annual_dol: 453.53,
        description:
            'Well-established traditional Italian eatery & piano bar hosted by a local restaurateur.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7093,
        store_longitude: -73.9235,
        total_orders: 560.83,
        neighborhood: 'East Williamsburg',
        keywords:
            'Chinese, Vegan, Salad, Korean, Trendy, Asian, American, Innovative, Sweet, Kung Pao',
        annual_dol: 688.13,
        description:
            'New York outpost of the San Francisco eatery serving fiery, innovative takes on Sichuan cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7026,
        store_longitude: -73.9287,
        total_orders: 1156.24,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Dessert, Bbq, Organic, American, Classic, Sweet, Fun, Casual, Great',
        annual_dol: 1206.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7322,
        store_longitude: -74.0008,
        total_orders: 8615.76,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Sushi, Peruvian, Japanese, Martini, Upscale, Delicious, Warm, Spanish, Latin, American',
        annual_dol: 10397.33,
        description:
            'Modern Japanese & Peruvian flavors presented in a warm setting with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -74.0026,
        total_orders: 133.92,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Salad, Gluten Free, Tasty, Pizza, Delicious, Organic, Warm, Comfortable, Healthy',
        annual_dol: 345.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6355,
        store_longitude: -74.0098,
        total_orders: 62.77,
        neighborhood: 'Sunset Park',
        keywords: 'Beer, Wine, Irish, Scandinavian, Hotel, Norwegian, Casual, Local, Exceptional',
        annual_dol: 73.17,
        description:
            'Opened in 1932, this local pub filled with memorabilia has a jukebox, a dart league & sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7056,
        store_longitude: -73.6562,
        total_orders: 116.68,
        neighborhood: '',
        keywords:
            'Vegan, Vegetarian, Sushi, Pizza, Organic, Healthy, Delicious, Friendly, Casual, Fresh',
        annual_dol: 544.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8219,
        store_longitude: -73.9386,
        total_orders: 928.5,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Local, Casual, Great',
        annual_dol: 3031.34,
        description: '"Follow us on IG: @legendslounge_nyc"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6679,
        store_longitude: -73.795,
        total_orders: 3490.29,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Hotel, Comfortable, Fun, Friendly, Perfect, Great, American, Chic, Caribbean, Creative',
        annual_dol: 3490.29,
        description:
            "Chic rooms offer custom-designed beds, minifridges, 37-inch flat-screen TVs and WiFi (fee). Executive rooms provide free WiFi and access to the Executive Lounge's free breakfast and evening appetizers. Suites add separate living areas and pull-out sofas.Amenities include a bistro-style restaurant serving American cuisine, a sports bar, a fitness center and an indoor pool. There's also a business center and over 6,300 sq ft of meeting space, plus a free airport shuttle.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9194,
        total_orders: 1939.14,
        neighborhood: 'Astoria/LIC',
        keywords: 'Wine, Spanish, Latin, Friendly, Fun, American, Casual, Authentic, Fusion, Local',
        annual_dol: 2689.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5733,
        store_longitude: -73.98,
        total_orders: 264.6,
        neighborhood: 'Coney Island',
        keywords:
            'Hamburger, Pizza, Beer, Coffee, Tasty, Dessert, Upscale, American, Legendary, Sweet',
        annual_dol: 341.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6749,
        store_longitude: -73.9997,
        total_orders: 26.72,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Steak, Salad, French Toast, Argentinian, French, Cozy, Avocado Toast, Trendy, Brazilian',
        annual_dol: 74.84,
        description:
            'American & Brazilian dishes, plus brunch & creative cocktails, in a trendy spot with a heated patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6814,
        store_longitude: -73.9939,
        total_orders: 288.44,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Lobster Roll, Lobster, Hamburger, Salad, Shrimp Cocktail, Wine, Grilled Salmon, Chinese, Delicious',
        annual_dol: 470.85,
        description:
            'New England-style joint with a menu of seafood classics in a sleek space with nautical touches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7349,
        store_longitude: -73.9858,
        total_orders: 2202.97,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Pizza, Italian, Salad, Wine, Meatball, Beer, Margarita, Rustic, Artisanal, Premium',
        annual_dol: 2249.72,
        description:
            'Easygoing neighborhood Italian restaurant with a separate wine bar & a wood-fired pizza oven.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6184,
        store_longitude: -74.0695,
        total_orders: 402.72,
        neighborhood: 'Rosebank',
        keywords: 'Burger, Vegetarian, Tacos, Mexican, Pizza, Salad, Beer, Vodka, Nachos, Mimosa',
        annual_dol: 458.66,
        description:
            'Petite eatery with a colorful decor & back patio serving traditional Mexican fare & Sunday brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7989,
        store_longitude: -73.967,
        total_orders: 61.16,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Flavorful, Wine, Italian, Spicy, Homemade, Healthy, Classy, Fresh, Mediterranean, Casual',
        annual_dol: 63.81,
        description:
            'Casual, Italian-Sardinian cafe with outdoor seating, serving housemade pastas, breads & seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5831,
        store_longitude: -73.9557,
        total_orders: 2694.74,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Steak, Burger, Salad, Sushi, Cheesesteak, Tuna Steak, Roast Beef, Italian, Beer, Upscale',
        annual_dol: 3245.15,
        description:
            'Hip venue with outdoor seating serving local & global bites such as burgers, pizza, sushi & crêpes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7934,
        store_longitude: -73.6438,
        total_orders: 269.06,
        neighborhood: '',
        keywords:
            'Burger, Tacos, Pizza, Sushi, Upscale, Luxurious, Elegant, Eclectic, Exquisite, American',
        annual_dol: 337.91,
        description:
            'Eclectic American plates & a buzzing taproom in a historic 1832 landmark with a swanky interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6764,
        store_longitude: -73.8439,
        total_orders: 26.71,
        neighborhood: 'Ozone Park/Jamaica',
        keywords: 'Casual, Amazing',
        annual_dol: 109.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.761,
        store_longitude: -73.9749,
        total_orders: 4518.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Beer, Wine, American, Eclectic, Iconic, Global, Fun, Fantastic, Traditional, Perfect',
        annual_dol: 4917.62,
        description:
            'Large venue with ping pong tables offering an eclectic menu of casual fare plus happy hour deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7571,
        store_longitude: -73.9684,
        total_orders: 226.27,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tequila, Mexican, Tacos, Beer, Flavorful, Vegetarian, Salad, Romantic, Fun, Artisanal',
        annual_dol: 482.98,
        description:
            '"Casual fun mexican bar and restaurant with colorful atmosphere! Street tacos as well as other Mexican favorites. Outdoor and indoor seating."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9751,
        total_orders: 1375.6,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Lobster, Salad, Pizza, Sushi, Italian, Tasty, Wine, Upscale, Elegant',
        annual_dol: 1872.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6306,
        store_longitude: -74.0283,
        total_orders: 1693.84,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Cheesecake, Pizza, Salad, Shrimp Cocktail, Coffee, Dessert, Chinese, Savory, Mediterranean',
        annual_dol: 1929.17,
        description:
            'Local Greek chain with a late-night scene doling out traditional sweets & savory dishes since 1977.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6211,
        store_longitude: -74.0292,
        total_orders: 593.98,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Sushi, Salad, Steak, Dessert, Beer, Tasty, Martini, Cosmopolitan, Japanese, Fried Rice',
        annual_dol: 751.55,
        description:
            'Entertaining hibachi chefs cook Japanese steaks tableside in a kid-friendly space with a sushi bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -74.0122,
        total_orders: 465.98,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Lobster, Legendary, Amazing, Welcoming, Excellent, Great, Friendly, Fresh, Sophisticated',
        annual_dol: 1904.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7975,
        store_longitude: -73.7372,
        total_orders: 115.81,
        neighborhood: '',
        keywords: 'Wine, Fabulous, Exquisite, Incredible, Great, Recommended, Local',
        annual_dol: 473.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6868,
        store_longitude: -73.9909,
        total_orders: 668.49,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Pizza, Italian, Delicious, Homemade, Hotel, Spacious, Fresh, Fantastic, Casual, Wonderful',
        annual_dol: 697.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.683,
        store_longitude: -73.9437,
        total_orders: 357.15,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Salad, Flavorful, Wine, Southern Comfort, Spicy, Southern, French, Casual, Asian, Caesar Salad',
        annual_dol: 1459.91,
        description:
            'Casual kitchen offering small & large plates of Southern comfort food, plus mason jar cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.9871,
        total_orders: 1268.79,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Karaoke, Beer, Korean, Sleek, Fusion, Friendly, Perfect, Great, Casual, Signature',
        annual_dol: 1301.29,
        description:
            'Sleek billiards bar with bites & a large selection of beer & cocktails made with Korean vodka.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7448,
        store_longitude: -73.9879,
        total_orders: 2348.39,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Karaoke, Korean, Japanese, Luxurious, Delicious, Fancy, Fun, Traditional, Hip, Casual',
        annual_dol: 5918.28,
        description:
            'Korean & Japanese nibbles & fancy cocktails in a cool, brick-lined bar with karaoke rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.9871,
        total_orders: 771.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Italian, Beer, Bbq, Spacious, Sleek, Fun, Hip, Friendly, Modern',
        annual_dol: 840.47,
        description:
            'Swanky bar with karaoke rooms, cocktails & an Asian-inspired menu of snacks & entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -74.002,
        total_orders: 9617.12,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, American, Global, Classic, Outstanding, Iconic, Contemporary, Amazing, Fresh, Great',
        annual_dol: 32909.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9878,
        total_orders: 159.81,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Mexican, Hotel, Western, Warm, Delicious, Local, Casual, Great',
        annual_dol: 714.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6597,
        store_longitude: -73.9604,
        total_orders: 2164.55,
        neighborhood: 'Prospect Lefferts Gardens/Lefferts Manor Historic District',
        keywords:
            'Burger, Zombie, Cajun, Beer, Whiskey, Mediterranean, Rustic, Bourbon, Club Sandwich, Blt Sandwich',
        annual_dol: 2230.57,
        description:
            'Rustic-chic bar offering elevated burgers & sandwiches, plus whiskey flights & rotating craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -74.0001,
        total_orders: 1106.95,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Spicy, Warm, American, Intimate, Casual, Classic, Great, Chill, Signature',
        annual_dol: 5209.34,
        description:
            'Laid-back watering hole serving up American food & cocktails, plus stand-up comedians.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6569,
        store_longitude: -73.7955,
        total_orders: 21121.03,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Steak, Pizza, Beer, Coffee, Donut, Tasty, American, Rum, Hotel',
        annual_dol: 21263.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.543,
        store_longitude: -74.1423,
        total_orders: 37.52,
        neighborhood: 'Great Kills',
        keywords:
            'Shrimp Cocktail, Italian, Cozy, Contemporary, Romantic, Local, Classic, Authentic, Exceptional, Great',
        annual_dol: 49.72,
        description:
            'This warmly decorated, white-tablecloth Italian eatery features upmarket entrees & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5495,
        store_longitude: -74.1506,
        total_orders: 219.62,
        neighborhood: 'Great Kills',
        keywords:
            'Elegant, Clean, Perfect, Relaxed, Friendly, Exquisite, Gourmet, Outstanding, Great, Modern',
        annual_dol: 616.2,
        description:
            '"The Grand Plaza is proud to announce that our Main Ballroom and Reception Hall redesign is now complete. Grand Plaza has been hosting and planning Staten Island\'s special occasions for over 30 years. We pride ourselves on our history of providing our guests with unmatched luxury service and an enchanting atmosphere for any memorable special occasion. The redesign provides an all-new modern décor focused on providing the next level in elevated elegance. This ensures our guests\' events will continue to provide the extravagance and splendor (exclusivity) that sets The Grand Plaza apart as the prominent Staten Island venue for decades to come."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.629,
        store_longitude: -74.0053,
        total_orders: 11.19,
        neighborhood: 'Dyker Heights',
        keywords:
            'Dessert, Shrimp Cocktail, Wine, Trendy, Rich, Romantic, Homemade, Classy, Intimate, Fresh',
        annual_dol: 45.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7278,
        store_longitude: -73.9909,
        total_orders: 2572.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tasty, Wine, Fruity, Upscale, Delicious, Warm, Cozy, Hotel, Beer, Burger',
        annual_dol: 2640.44,
        description:
            "Understated, airy rooms come with flat-screen TVs and desks, as well as free Wi-Fi. Upgraded rooms and suites add floor-to-ceiling windows, city views, separate sitting areas and/or bathrooms with heated floors. Room service is available 24/7.There's a chic restaurant with an open kitchen, as well as a garden terrace, and a clubby cafe/bar with a sidewalk patio. A penthouse event area includes a wraparound terrace.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -73.8722,
        total_orders: 2.34,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Peruvian, Colombian, Pizza, Tasty, Latin, American, Casual, Great',
        annual_dol: 9.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7515,
        store_longitude: -73.9708,
        total_orders: 840.4,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Hamburger, Karaoke, Swiss, Irish, Russian, Homemade, Traditional, Reuben, Warm',
        annual_dol: 1478.35,
        description:
            'Neighborhood bar with a late-night kitchen where locals head to croon karaoke or watch sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9796,
        total_orders: 996.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Salad, Japanese, Spicy, Sashimi, Yummy, Sweet, New Zealand, Premium, Casual',
        annual_dol: 3935,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 1563.5,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Coffee, Gluten Free, Upscale, Cozy, Modern, Exquisite, Wonderful, Casual, Fine',
        annual_dol: 6391.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7148,
        store_longitude: -74.0064,
        total_orders: 960.79,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Beer, Wine, Steak, Shrimp Cocktail, Salad, Gin, Irish, Friendly, Outstanding, Classic',
        annual_dol: 981.17,
        description:
            '"A new tavern in Tribeca that prides itself on serving quality food and drinks in a friendly atmosphere."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9684,
        total_orders: 1695.93,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tequila, Burger, Delicious, Fun, Lively, French Toast, American, French, Perfect, Great',
        annual_dol: 1756.78,
        description:
            '"Approachable cocktails, chic decor, weekend bottomless brunch, late night DJ’s, and delicious AF food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9716,
        total_orders: 719.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hotel, Wine, Italian, Elegant, Delicious, Coffee, Spectacular, Rooftop, Premium, Eclectic',
        annual_dol: 927.81,
        description:
            "Elegant, traditional rooms offer flat-screen TVs and Wi-Fi (fee), plus microwaves, minifridges and coffeemakers. Studios add kitchens, and the 1- and 2-bedroom suites feature separate living rooms with sofas. Some also have balconies. In-room massages are available (surcharge), as is 24-hour room service.Amenities include a chic Italian restaurant, a steakhouse, and a rooftop bar with a lounge. There's also a fitness center, plus free access to a local health club with an indoor pool.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5583,
        store_longitude: -73.901,
        total_orders: 134.95,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords: 'Burger, Tasty, Hotel, American, Casual, Traditional, Great',
        annual_dol: 167.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7095,
        store_longitude: -74.011,
        total_orders: 106.53,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Premium',
        annual_dol: 435.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8659,
        store_longitude: -73.8378,
        total_orders: 653.49,
        neighborhood: 'Pelham Gardens/East Bronx',
        keywords: 'Steak, Salad, Mexican, Italian, Bbq, Cuban, Spanish, Dominican, American, Latin',
        annual_dol: 1031.75,
        description:
            'Unpretentious neighborhood place serving Latin American & Italian dishes in an old-school space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6621,
        store_longitude: -73.9535,
        total_orders: 915.37,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords: 'Wine, Beer, Spicy, Rum, Hamburger, Urban, Classy, Caribbean, American, French',
        annual_dol: 956.44,
        description:
            '"Neighborhood-friendly wine and cocktail lounge serving American Comfort dishes in a comfortable yet sophisticated atmosphere."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7763,
        store_longitude: -73.9794,
        total_orders: 1351.88,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Salad, Wine, Italian, Quaint, Martini, Polished, Hotel, Homey, Friendly, Natural',
        annual_dol: 1380.56,
        description:
            "Neighborhood trattoria with traditional Italian fare that's popular before Lincoln Center shows.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.9937,
        total_orders: 2594.97,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Steak, Lobster, Beer, Wine, Delicious, Warm, Irish, Homey, Friendly',
        annual_dol: 2650.03,
        description:
            'Chill haunt with a homey feel & train memorabilia offering a raw bar, plus burgers & seafood plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8293,
        store_longitude: -73.9284,
        total_orders: 73058.58,
        neighborhood: 'Concourse/Highbridge/West Bronx',
        keywords: 'Steak, Beer, Heritage, Premium, Amazing',
        annual_dol: 165090.16,
        description:
            'Yankee Stadium is a baseball stadium located in the Bronx, New York City. It is the home field of the New York Yankees of Major League Baseball, and New York City FC of Major League Soccer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7215,
        store_longitude: -73.8485,
        total_orders: 38.99,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Sushi, Salad, Coffee, Beer, Japanese, Ale, Thai, Sashimi, Ramen, Zen',
        annual_dol: 159.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7969,
        store_longitude: -73.9381,
        total_orders: 125.13,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Lobster, Lobster Tail, Shrimp Cocktail, Dominican, Spanish, Latin, American, Caribbean, Creole',
        annual_dol: 511.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7037,
        store_longitude: -73.9266,
        total_orders: 52.02,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Beer, Wine, Dessert, Delicious, Bbq, American, Contemporary, Reuben Sandwich, Global',
        annual_dol: 219.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7911,
        store_longitude: -73.9747,
        total_orders: 0.87,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Salad, Cheesecake, Hamburger, Argentinian, Tangy, French, Spicy, Reuben Sandwich, American',
        annual_dol: 3.54,
        description:
            'This large retro-styled diner offers standard American comfort fare around the clock.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7487,
        store_longitude: -73.6848,
        total_orders: 5.09,
        neighborhood: '',
        keywords:
            'Chicken Tikka Masala, Butter Chicken, Spicy, Southern, Bbq, Homemade, Casual, Tandoori Chicken, Refined, Premium',
        annual_dol: 20.81,
        description:
            'Refined eatery serving Southern Indian dishes including hot & spicy curries, dosa & tandoori.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5756,
        store_longitude: -73.9773,
        total_orders: 578.83,
        neighborhood: 'Coney Island',
        keywords:
            'Dessert, Italian, Margarita, Mediterranean, Elegant, Tex-Mex, Casual, Modern, Polished, Seasonal',
        annual_dol: 1435.24,
        description:
            '"Experience casual dining at its very best in a luxuriously modern atmosphere with incredible views of Coney Island’s amusement park and the Atlantic Ocean. Enjoy unparalleled service and a lavish interior environment at the Teraza. Teraza provides elegant modern Mediterranean fusion cuisine using the freshest seasonal ingredients and employing classic and modern techniques. Our team brings a wealth of experience from the hospitality industry to ensure the finest dining experience possible. Newest and best new fusion Restaurants in Brooklyn Coney Island NY, Brooklyn Restaurant."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.9145,
        total_orders: 155.82,
        neighborhood: 'Astoria',
        keywords: 'Wine, Salad, Stylish, Superb, Great, Signature, Local, Unique',
        annual_dol: 225.61,
        description:
            "\"We are a Lingerie Shop and a Cocktail Bar. Here at Astoria's Secret it's a whole experience, from our Burlesque Shows to our Killer Karaokes. Let's celebrate the weekends together! Follow Us on IG, Twitter, and Tiktok @AstoriasSecret for Theme Nights, #KillerKaraoke, Burlesque Shows, and Erotic Poetry!\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6632,
        store_longitude: -73.9913,
        total_orders: 3492.29,
        neighborhood: 'South Slope/Sunset Park',
        keywords: 'Beer, Wine, Salad, Funky, Eclectic, Moscow Mule, Retro, Hip, Quirky, Friendly',
        annual_dol: 3658.2,
        description:
            'Quirky Brooklyn bar prized for divey vibes, eclectic decor & live performances in the back room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9094,
        total_orders: 60.9,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tacos, Mexican, Beer, Tasty, Margarita, Steak, Salad, Wine, Warm, Authentic',
        annual_dol: 135.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -73.9342,
        total_orders: 41.54,
        neighborhood: 'East Williamsburg',
        keywords: 'Wine, Coffee, Salad, Ale, Beer, Italian, Tasty, French, Organic, Spicy',
        annual_dol: 169.8,
        description:
            'Hip coffee bar with premium grub, craft beer & organic wine, plus live music & local art.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.9771,
        total_orders: 568.57,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Tasty, Pizza, Wine, Salad, Italian, Upscale, Delicious, Trendy, Cozy, Classy',
        annual_dol: 606.37,
        description:
            'Fresh pastas dominate a traditional Italian menu in a modern setting with a neighborhood feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.9841,
        total_orders: 1840.36,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Salad, Mexican, Wine, Irish, American, Casual, Classic, Lively, Local',
        annual_dol: 2148.68,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8191,
        store_longitude: -73.9623,
        total_orders: 350.11,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Vegan, Delicious, Friendly, Authentic, Casual, American, Lively, Traditional',
        annual_dol: 659.55,
        description:
            'Pub grub & cocktails are served at this lively open-air eatery set on a docked Vietnam-era warship.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9885,
        total_orders: 38.89,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Salad, Pizza, Beer, Japanese, Spicy, Sashimi, Yummy, Stylish, Asian',
        annual_dol: 46.46,
        description:
            'Modern sushi house featuring creative rolls, Japanese fare & cocktails in a stylish, brick interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -73.9854,
        total_orders: 105.44,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Vegetarian, Wine, Coffee, Spicy, French Toast, Bbq, Zesty, French, New-York-Style',
        annual_dol: 127.54,
        description: 'Basic American eats are served in hearty portions at this retro 24/7 diner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7183,
        store_longitude: -73.9881,
        total_orders: 160.08,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, American, Salad, Urban, Rustic, Eclectic, Caribbean, Contemporary, Asian, Sashimi',
        annual_dol: 654.37,
        description:
            '"Essex Pearl\'s mission is to showcase the beautiful cuisines of the colorful countries of Southeast Asia in a contemporary NYC setting. We invite you to explore and indulge in our eclectic seafood-centric menu. Powered by NYC\'s top seafood purveyor, Aqua Best, Executive Chef Daniel Le brings the heat to the kitchen and welcomes you to join the action via our kitchen-facing counter. Bring a friend to our open dining room, or make new ones at our lively bar. All of us at Essex Pearl are looking forward to welcoming you."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7256,
        store_longitude: -73.9997,
        total_orders: 184.48,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Beer, Pizza, Wine, Fantastic, Iconic, Cool, Hummus',
        annual_dol: 929.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6843,
        store_longitude: -73.8595,
        total_orders: 2670.1,
        neighborhood: 'Ozone Park',
        keywords: 'Pizza, Italian, Salad, Dessert, Vodka, French, Hawaiian, Bbq, Casual, Fresh',
        annual_dol: 7855.44,
        description:
            'Family-run outpost featuring generous plates of Italian fare & wood-fired pizzas, plus a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7657,
        store_longitude: -73.9838,
        total_orders: 76.92,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Burger, Mexican, Flavorful, Tasty, Vegetarian, Beer, Margarita, Dessert, Warm',
        annual_dol: 314.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7326,
        store_longitude: -73.9975,
        total_orders: 949.3,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Tasty, Trendy, Romantic, Steak, French, Classy, Premium, Fun, Serene',
        annual_dol: 970.95,
        description:
            'Serene joint with a classy vibe offering small plates & full meals with matching wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.737,
        store_longitude: -73.9985,
        total_orders: 170.7,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Italian, Wine, Warm, Spacious, Inviting, Charming, Irresistible, Premium, Intimate',
        annual_dol: 380.7,
        description:
            'Sophisticated, intimate Italian restaurant in the basement of a historical brownstone.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6801,
        store_longitude: -73.9744,
        total_orders: 72.31,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords: 'Burger, Mexican, Beer, Salad, Gourmet, Tasty, Mimosa, Spicy, Tex-Mex, Delicious',
        annual_dol: 125.19,
        description:
            'Steaks, burgers & seafood with spicy Southwestern accents served in 2-level setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7253,
        store_longitude: -73.9843,
        total_orders: 1042.17,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Karaoke, Japanese, Beer, Classic, Ale, Fun, Aesthetic, Friendly, Great, Ample',
        annual_dol: 1042.17,
        description: 'Private rooms & an extensive song list lure warblers to this karaoke bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7278,
        store_longitude: -73.9849,
        total_orders: 2529.22,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Hotdog, Burger, Vegan, Beer, French, American, Friendly, Casual, Cool, Local',
        annual_dol: 2607.61,
        description:
            'Cocktails & draft beer turned out in dark, compact quarters with an edgy vibe & pool tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6776,
        store_longitude: -73.9639,
        total_orders: 5972.72,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Wine, Gin, Tasty, Bourbon, Rum, Fun, Sweet, Hot Toddy, Cointreau',
        annual_dol: 6099.45,
        description:
            "Classic '50s-diner-style lounge with large windows featuring upscale, creative cocktails.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6824,
        store_longitude: -73.961,
        total_orders: 803.95,
        neighborhood: 'Clinton Hill',
        keywords: 'Beer, Whiskey, Wine, Vibrant, Amazing, Great, Indian, Casual, Chill, Unique',
        annual_dol: 986.42,
        description:
            'An extensive, rotating beer list & small-batch whiskey selection in a snug, low-key space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7466,
        store_longitude: -73.9821,
        total_orders: 7263.95,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Wine, Upscale, Ribeye Steak, Caesar Salad, Delicious',
        annual_dol: 7304.34,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -73.9763,
        total_orders: 36.36,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Tequila, Mexican, Warm, Spicy, Delicious, Friendly, Premium, Lively, American, Great',
        annual_dol: 148.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -73.9607,
        total_orders: 22.25,
        neighborhood: 'Williamsburg',
        keywords: 'Urban, Beer, Wine, Hip, Fancy, Quirky, Fun, Awesome, Cool',
        annual_dol: 90.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8345,
        store_longitude: -73.8291,
        total_orders: 11845.09,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords:
            'Champagne, Steak, Dessert, Margarita, Cuban, French Toast, Caribbean, Puerto Rican, French, Latin',
        annual_dol: 14115.63,
        description:
            'Restaurant & nightspot serving Puerto Rican dishes & cocktails in an airy, Caribbean-inspired space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.6838,
        total_orders: 1076.03,
        neighborhood: '',
        keywords: 'Western, Smooth, Perfect, Luxurious',
        annual_dol: 2425.89,
        description:
            '"The Stewart Manor Country Club is a beautifully renovated club and garden setting located in Western Nassau County, New York. We truly understand that everyone has different desires, needs, and budgets. Our professional event planning staff will take your ideas, tastes, likes, dislikes, colors, decorations, food choices, etc. into consideration, and give you what you want, when you want it, and how you want it. We take pride in "Doing It Your Way"!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7728,
        store_longitude: -73.873,
        total_orders: 179.64,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Burger, Spicy, Hotel',
        annual_dol: 734.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7021,
        store_longitude: -73.8556,
        total_orders: 34.8,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords: 'Tequila, Mexican, Beer, Steak, Margarita, Wine, Pizza, Salad, Fajitas, Spicy',
        annual_dol: 277.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6638,
        store_longitude: -73.9844,
        total_orders: 73.8,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Italian, Gluten Free, Beer, Martini, Delicious, Greek, Thai, Creative, Casual',
        annual_dol: 240.95,
        description:
            'Warm, snug eatery with brick-clad walls offering Italian dishes & pies with creative toppings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9668,
        total_orders: 127.53,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Italian, Meatball, Wine, Modern, Homemade, Tempting, Authentic, Classic, Perfect',
        annual_dol: 521.32,
        description:
            '"Authentic Italian ristorante where preparing dishes "a piacere" - according to your preference - is our greatest pleasure. The menu showcases our homemade and imported pastas, along with veal, fish and chicken options and, always, we offer a number of tempting daily specials. If your favorite Italian dish isn\'t on the menu or among the specials, ask for it and our chef will gladly make it. An extensive Italian wine list and full bar service featuring intriguing housemate infusions round out our gustatory attractions."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7248,
        store_longitude: -73.9478,
        total_orders: 43.78,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Hotdog, Pizza, Mexican, Beer, Spicy, Bbq, American, Spacious, Eclectic',
        annual_dol: 178.95,
        description:
            '"Jucy Lucy is a laid back gastropub featuring light bites, sandwiches, burgers, hotdogs, flatbreads and 16 draft beers and a full bar. Watch your favorite sports team or just relax and let the music take you."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.766,
        store_longitude: -73.9691,
        total_orders: 262.98,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Cosmopolitan, American, Hotel, Premium, Friendly',
        annual_dol: 268.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7795,
        store_longitude: -73.9833,
        total_orders: 0,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Kebab, Butter Chicken, Wine, Malaysian, Indian, Fusion, Diverse, Great, Casual',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.9978,
        total_orders: 191.38,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Modern, Hotel, Polish, Fine, Unique',
        annual_dol: 782.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9847,
        total_orders: 15121.35,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Gourmet, Gin, French, Juicy, Rum, Karaoke, Fun, Creative',
        annual_dol: 15580.21,
        description:
            'Bustling cocktail spot specializing in ambitious creations, plus a small menu of gourmet bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6361,
        store_longitude: -73.577,
        total_orders: 625.46,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Crab Cakes, Shrimp Cocktail, Wine, Mexican, Organic, Delicious, Yummy, Mediterranean',
        annual_dol: 714.77,
        description:
            'Quiet space with a Portugal-inspired menu & wine list, Hudson Canal views & occasional live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.678,
        store_longitude: -73.9822,
        total_orders: 1873.63,
        neighborhood: 'Park Slope',
        keywords: 'Vodka, Beer, Gin, Ale, Rum, Cozy, Hotel, Warm, Yummy, Sweet',
        annual_dol: 1958.09,
        description:
            "Intimate brick-walled haunt featuring trivia nights, happy hour deals & a rock 'n' roll jukebox.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -74.0011,
        total_orders: 130.27,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Mexican, Tacos, Beer, Burritos, Vegetarian, Salad, Wine, Hotel, Western, Casual',
        annual_dol: 593.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -74.0011,
        total_orders: 1018.27,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Salad, Gourmet, Dessert, Delicious, Hotel, Classy, Friendly, American, Perfect',
        annual_dol: 1056.31,
        description:
            'Neighborhood watering hole with aviation theme, drink specials, happy hour & pub food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 618.35,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tacos, Steak, Mexican, Wine, Rich, Hotel, Italian, Chinese, Gin, Stylish',
        annual_dol: 2018.76,
        description:
            '"Located in the basement of the Chelsea Market. CMH pays homage to the revelry and rich entertainment culture of Chelsea’s past and will showcase a diverse mix of immersive theatre and performances ranging from emerging acts to established artists and world-renowned DJ\'s that are attracted to the intimacy of the room as well as the quality sound system."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -74.0024,
        total_orders: 56.72,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, German, Wine, Trendy, Delicious, Urban, Fantastic, Amazing, Great, Local',
        annual_dol: 198.66,
        description:
            '"Milk & Hops Beer Tap & Bottle shop located in the heart of Chelsea, offers over 300 bottles selection and 10 draught lines combined with an amazing selection of small appetizes."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8272,
        store_longitude: -73.8867,
        total_orders: 75.69,
        neighborhood: 'Foxhurst',
        keywords:
            'Hamburger, Steak, Mexican, Burritos, Margarita, Spicy, Dominican, French, Delicious, American',
        annual_dol: 309.4,
        description:
            'Tacos, burritos & Mexican-inspired mains dished up in a bright locale with cocktails & basic tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5745,
        store_longitude: -74.1696,
        total_orders: 2077.96,
        neighborhood: 'New Springville/Mid Island',
        keywords: 'Steak, Burger, Cajun, Salad, Ale, Wine, Beer, Italian, Tex-Mex, French',
        annual_dol: 2168.06,
        description:
            'Casual sports-pub chain with basics from bar snacks to steaks & a large selection of tap beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6231,
        store_longitude: -73.7187,
        total_orders: 430.65,
        neighborhood: '',
        keywords:
            'Salad, Delicious, Bbq, American, Caesar Salad, Perfect, Spectacular, Authentic, Fresh, Terrific',
        annual_dol: 634.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6444,
        store_longitude: -74.0765,
        total_orders: 73.8,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Lobster Roll, Lobster, Sushi, Steak, Hamburger, Beer, Delicious, American, Fun, Ample',
        annual_dol: 301.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.9781,
        total_orders: 797.06,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Hotel, Amazing, Signature, Great, Asian',
        annual_dol: 3103.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7484,
        store_longitude: -73.9759,
        total_orders: 1530.43,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Sushi, Chocolate Cake, Salad, Beer, Bbq, Irish, Ale, Relaxed, Comfortable',
        annual_dol: 1578.01,
        description:
            'Irish pub & eatery with a recent college grad/happy-hour following for tap brews, TVs & pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9806,
        total_orders: 276.56,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Hotel, Sparkling, Relaxed, Premium, Clean, Friendly, Great',
        annual_dol: 968.62,
        description:
            'Warmly furnished rooms feature free Wi-Fi and flat-screen TVs. Upgraded rooms add views of the Empire State Building; suites offer sitting areas with pull-out sofas.Amenities include a business center and a meeting room. Pets are welcome for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7466,
        store_longitude: -73.9827,
        total_orders: 17.29,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Whiskey, Coffee, Mexican, Beer, Wine, American, Contemporary, Clean, Seasonal, Casual',
        annual_dol: 70.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.8831,
        total_orders: 635.74,
        neighborhood: 'Jackson Heights',
        keywords:
            'Salad, Colombian, Beer, Latin, American, Perfect, Mediterranean, Friendly, Great, Excellent',
        annual_dol: 692.85,
        description:
            'Showing sports on HD screens, this laid-back haunt with draft beer specializes in grilled seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7754,
        store_longitude: -73.9096,
        total_orders: 63.45,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Shrimp Cocktail, Beer, Wine, Salad, French, Gin, Mediterranean, Rum, Innovative, Fresh',
        annual_dol: 259.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7622,
        store_longitude: -73.9874,
        total_orders: 1070.4,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hamburger, Coffee, Canadian, Ale, American, Delicious, Friendly, Casual, Traditional, Classic',
        annual_dol: 1484.37,
        description:
            'Informal canine-themed restaurant/bar serving typical fare, weekend brunch & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.9893,
        total_orders: 1278.6,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Vodka, Tasty, Gin, Delicious, Rum, Hotel, Fun, Awesome, Great',
        annual_dol: 1624.93,
        description:
            '"Located in the heart of downtown Manhattan, The Orchard Room is the Lower East Side’s most exciting new bar & restaurant specializing in tasty Dim Sum bites featuring a handcrafted bar menu. House guests may enjoy their drinks nestled in one of our signature Alligator Green banquets, or from our heated outdoor bungalow–yes, our in-person DJ sets will be audible outside too. Please don\'t feed the monkeys."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6343,
        store_longitude: -73.5819,
        total_orders: 263.84,
        neighborhood: '',
        keywords: 'Caribbean, Casual',
        annual_dol: 1078.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.712,
        store_longitude: -74.0152,
        total_orders: 6,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Premium, Organic, Rooftop, Perfect, Great, Complex, Cultural',
        annual_dol: 24.52,
        description:
            '"Convene at Brookfield Place is the newest place to host large-scale events in Downtown NYC. The premium, full-service venue includes nearly 73,000 square feet of flexible meeting space and can host up to 615 people with a total of 1,000 people maximum, making it perfect for conferences, galas, trade shows, exhibits, product launches, fashion shows, and large summits. With a variety of purpose-built rooms for small, medium, and large programs, Convene is fully outfitted with built-in technology, furniture, on-site catering, and a professional hospitality team to take care of all your event needs so you can focus less on logistics and more on your guests."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7387,
        store_longitude: -73.9937,
        total_orders: 4599.8,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Steak, Sushi, Japanese, Coffee, Wine, Beer, French, Thai, Bbq, Korean',
        annual_dol: 4885.6,
        description:
            'High-end restaurant offering Japanese-style BBQ, Korean eats & cocktails in a low-lit, modern venue.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8611,
        store_longitude: -73.9204,
        total_orders: 16.92,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords: 'Dominican, Salad, Casual, Traditional',
        annual_dol: 17.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6803,
        store_longitude: -73.9677,
        total_orders: 569.7,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Friendly, Cool, Great, Excellent, Casual, Local, Unique',
        annual_dol: 928.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7099,
        store_longitude: -73.9626,
        total_orders: 9813.8,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Steak, Burger, Cheesecake, Salad, German, Chocolate Mousse, Hot Fudge Sundae, Beer, French, American',
        annual_dol: 9829.87,
        description:
            'Cash-only steak icon where old-school waiters serve aged beef in a German beer hall setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7112,
        store_longitude: -73.9501,
        total_orders: 112.15,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, French, Ale, Organic, Savory, Cozy, Delicious, Seasonal, Artisanal, Casual',
        annual_dol: 458.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7428,
        store_longitude: -73.9831,
        total_orders: 5552.17,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Sushi, Martini, Japanese, Hotel, Irish, Savory, Sashimi, American, Contemporary, Latin',
        annual_dol: 7299.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7123,
        store_longitude: -73.9644,
        total_orders: 3398.23,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Delicious, Fun, Sweet, Yummy, Eclectic, Friendly, Great, Cool, Incredible, Casual',
        annual_dol: 5547.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7653,
        store_longitude: -73.9314,
        total_orders: 566.31,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords:
            'Burger, Lobster, Hotdog, Cheesecake, Vegetarian, Pizza, Vegan, Salad, Mexican, Gourmet',
        annual_dol: 795.05,
        description:
            'Large, classic Greek diner with a massive menu, chrome accents & 24/7 opening hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7513,
        store_longitude: -73.9351,
        total_orders: 25.66,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Hotel, Friendly, Great, Recommended',
        annual_dol: 104.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7292,
        store_longitude: -73.9922,
        total_orders: 818.59,
        neighborhood: 'NoHo',
        keywords:
            'Salad, Vodka, Tasty, Chilean, Dessert, Martini, Vietnamese, Stylish, Spicy, French',
        annual_dol: 1067.2,
        description:
            'French-Vietnamese fare is served amid exotic decor of palm fronds & low light at this 80s-era spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.793,
        store_longitude: -73.9714,
        total_orders: 107.48,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Peruvian, Salad, Vegetarian, Wine, Gluten Free, Chinese, Ale, Dominican, Latin, Homemade',
        annual_dol: 114.62,
        description:
            'Casual Peruvian restaurant specializing in rotisserie chicken with homemade sauce.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8548,
        store_longitude: -73.9372,
        total_orders: 45.32,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Mexican, Steak, Margarita, Enchiladas, Fajitas, Homemade, Delicious, Friendly, Casual, Premium',
        annual_dol: 110.85,
        description:
            'Casual Mexican eatery serving classic dishes & specialty margaritas in a lively, colorful setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.7716,
        total_orders: 89.09,
        neighborhood: 'Bayside',
        keywords:
            'Cajun, Steak, Wine, Champagne, Bourbon, South American, Cuban, Italian, Spanish, Gin',
        annual_dol: 101.47,
        description:
            'Elegant hot spot showcasing fine wines floor to ceiling, with a menu of creative tapas & entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9773,
        total_orders: 40.9,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Shrimp Cocktail, Chicken Caesar Salad, Italian, Coffee, Golfclub, German, Delicious, Hotel',
        annual_dol: 179.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8776,
        store_longitude: -73.9058,
        total_orders: 30.72,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Burger, Sushi, Lobster, Lobster Tail, Steak, Chicken Parmigiana, Pizza, Gluten Free, Beer, Italian',
        annual_dol: 125.59,
        description: '"Comida latina más una cocina asiática ofreciendo sushi 100% frescos."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7981,
        store_longitude: -73.6655,
        total_orders: 1609.37,
        neighborhood: '',
        keywords:
            'Kebab, Salad, Lobster, Mexican, Gourmet, Italian, Wine, Mediterranean, Greek, Upscale',
        annual_dol: 1679.15,
        description:
            'Upscale Greek-Mediterranean restaurant with a large seafood-focused menu & spacious, chic digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6219,
        store_longitude: -74.0315,
        total_orders: 1550.85,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Burger, Lobster, Pizza, Tequila, Salad, Shrimp Cocktail, Vodka, Italian, Irish, American',
        annual_dol: 1700.96,
        description:
            'This roomy bistro has seats indoors & out, featuring an eclectic American menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7145,
        store_longitude: -74.0074,
        total_orders: 53.09,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Peruvian, Sushi, Tequila, Vodka, Mexican, Gin, Pizza, Italian, Rum, Fusion',
        annual_dol: 217,
        description:
            '"Some of the Best Sensory Experiences Happen on a Peruvian Plate. Taste the sentiments and nostalgia from a fusion of continents. Peruvian gastronomy, its influence & inclusiveness has opened the door for the marriages of many cultures & thus have ingrained themselves into the Peruvian gastronomic experience. As long as the world continues to evolve, so will the kitchen. The end of possibilities is nowhere in sight."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6685,
        store_longitude: -73.7082,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Thai, Sushi, Burger, Nutritious, Delicious, Steak, Romantic, Authentic, Fresh, Casual',
        annual_dol: 56.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9897,
        total_orders: 1597.39,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Hamburger, Beer, Cozy, French, American, Modern, Friendly, Casual, Classic',
        annual_dol: 1705.13,
        description:
            'Old-school stop catering to the pre-theater crowd with elevated American fare & classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6933,
        store_longitude: -73.9987,
        total_orders: 2919.19,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Lasagna, Chocolate Mousse, Salad, Pizza, Whiskey Sour, Italian, Coffee, Whiskey, Bourbon, Cozy',
        annual_dol: 2989.11,
        description:
            'Quaint corner joint specializing in Sardinian fare, plus cocktails & weekend brunch. Cash only.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6867,
        store_longitude: -73.9548,
        total_orders: 2546.88,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Coffee, Spicy, Fun, Nachos, Lively, Vintage, Classic, Great, Casual',
        annual_dol: 2604.53,
        description:
            'Down-to-earth local pub serving beer & cocktails amid weathered-brick walls & throwback decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7833,
        store_longitude: -73.948,
        total_orders: 989.58,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Lobster, Vegetarian, Lobster Roll, Mexican, Beer, Wine, Delicious, American, Cozy',
        annual_dol: 1296.08,
        description:
            'Portraits of standout women line the walls of this low-key spot for informal bites & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9889,
        total_orders: 1573.76,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Tacos, Shrimp Cocktail, Beer, Mexican, Coffee, Wine, Tasty, Painkiller, Irish',
        annual_dol: 5524.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.686,
        store_longitude: -73.9911,
        total_orders: 98.98,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords: 'Burger, Cajun, Cornbread, Coffee, Salad, Beer, Bourbon, Mimosa, Italian, Bbq',
        annual_dol: 121.74,
        description:
            '"Good neighbor vibes, live music Fridays with David Gagne, live music brunch Saturday & Sunday with Roz & Gem, Happy Hour, BBQ, Comfort Food, TVs for outdoor seating, cocktails and local breweries’ craft beers 🍻"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.9934,
        total_orders: 149.56,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Mexican, Pizza, Gourmet, New-York-Style, American, Delicious, Local, Casual, Healthy, Fresh',
        annual_dol: 733.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9935,
        total_orders: 36.3,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Upscale, Wine, Trendy, Legendary, Italian, Iconic, American, Memorable, Sleek',
        annual_dol: 46.81,
        description:
            "Building and a minute's walk from the nearest subway station.Relaxed rooms feature free Wi-Fi and flat-screen TVs; some have views of the city, river and/or Empire State Building. Upgraded rooms have iPod docks. The 1-bedroom suites offer minifridges, living rooms with sofabeds, and/or furnished balconies.Amenities include 3 restaurants (1 with a bar) and a gym, plus meeting and event space. Breakfast (fee) and valet parking are available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7747,
        store_longitude: -73.9127,
        total_orders: 216.54,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Burger, Steak, Hamburger, Beer, Mimosa, American, Sweet, Bellini, Bloody Mary, Prosecco',
        annual_dol: 259.08,
        description:
            'Sports bar with 40+ TVs offering elevated pub grub, a solid beer selection & weekend breakfast.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.728,
        store_longitude: -74.0002,
        total_orders: 4693.13,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lobster, Pizza, Italian, Rich, Spicy, Salad, Meatball, Vodka, Funky, Premium',
        annual_dol: 4896.61,
        description:
            'High-end takes on red-sauce fare await at this A-lister Italian restaurant in a retro-glam space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6605,
        store_longitude: -73.9508,
        total_orders: 941.96,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Gluten Free, Coffee, Organic, French Toast, Italian, French, Sweet, Fun, Casual, American',
        annual_dol: 1142.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7789,
        store_longitude: -73.9812,
        total_orders: 119.93,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tacos, Mexican, Salad, Burritos, Margarita, Organic, American, Delicious, Casual, Friendly',
        annual_dol: 551.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.8016,
        total_orders: 1338.38,
        neighborhood: 'Flushing/Murray Hill',
        keywords:
            'Coffee, Wine, Champagne, Beer, Organic, Premium, Vintage, Hip, Excellent, Rooftop',
        annual_dol: 1396.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.978,
        total_orders: 106.33,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'American, Hotel, Retro, Casual, Personalized, Signature, Fun',
        annual_dol: 115.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7677,
        store_longitude: -73.9835,
        total_orders: 775.22,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Japanese, Sashimi, Spicy, Delicious, Fusion, Asian, Ramen, Elegant, Local',
        annual_dol: 824.99,
        description:
            'Classic Japanese cuisine including an extensive sushi menu, served in a modern setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7196,
        store_longitude: -73.9905,
        total_orders: 1745.6,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Burger, Margarita, Donut, Spicy, Delicious, Stylish, Fried Chicken, Cozy, Sweet',
        annual_dol: 1782.64,
        description:
            'Trendy, snug cocktail bar serving finger foods, chicken sandwiches & creative drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7202,
        store_longitude: -73.9881,
        total_orders: 2728.57,
        neighborhood: 'Lower East Side',
        keywords: 'Wine, Beer, Whiskey, Irish, Karaoke, Upscale, Eclectic, Fancy, American, Quaint',
        annual_dol: 2786.46,
        description:
            'Unpretentious watering hole with Irish touches doling out beer, booze & daily happy-hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.9871,
        total_orders: 1564.13,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Tacos, Mexican, Tequila, Sushi, French, Delicious, Cozy, Traditional, Authentic',
        annual_dol: 2435.43,
        description:
            'Neighborhood joint for Mexican food by a native chef-owner, plus mezcals & other agave spirits.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -73.989,
        total_orders: 7937.15,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Salad, Avocado Toast, Hotel, Gluten Free, Beer, American, Caesar Salad, French, Rooftop',
        annual_dol: 9072.17,
        description:
            'Views of Downtown frame the setting at this rooftop spot serving a New American menu & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7231,
        store_longitude: -73.9942,
        total_orders: 67.01,
        neighborhood: 'Nolita',
        keywords: 'Salad, Mexican, Cuban, Swiss, Spicy, Cozy, Casual, Wonderful, Great',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8118,
        store_longitude: -73.9429,
        total_orders: 30.65,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Lobster, Steak, Mexican, Salad, Beer, Wine, Spicy, Delicious, Spanish, Cozy',
        annual_dol: 125.28,
        description:
            'Latin bistro & wine bar also providing craft beer & weekend brunch in a relaxed, brick-lined space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7464,
        store_longitude: -73.9879,
        total_orders: 7.8,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Burger, Steak, Italian, Pizza, American, Vibrant, Lively, Innovative, Fusion, Hotel',
        annual_dol: 31.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -74.0032,
        total_orders: 590.78,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Spanish, Wine, Spicy, Stylish, Romantic, Warm, Mediterranean, Greek, American',
        annual_dol: 590.78,
        description:
            "Food & wine from Spain's Mediterranean coast served in stylish surrounds with a glass-roofed garden.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.743,
        store_longitude: -73.9999,
        total_orders: 2015.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vegan, Hamburger, Vegetarian, Salad, Gluten Free, Milkshake, Beer, Organic, Delicious',
        annual_dol: 3781.9,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6804,
        store_longitude: -73.975,
        total_orders: 436.82,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Garlic Bread, Shrimp Cocktail, Salad, Cajun, Bbq, Ramen, Upscale, Creole, Caesar Salad',
        annual_dol: 465.53,
        description: '"Cajun creole cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.994,
        total_orders: 1529,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Delicious, Trendy, Clean, Ale, Friendly, Fun, Cool, Great, Excellent',
        annual_dol: 1710.79,
        description:
            'Quirky, no-frills watering hole offering beer in cans & on tap, plus weekly trivia nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6867,
        store_longitude: -73.9744,
        total_orders: 13.25,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Italian, Trendy, Hotel, French, Classy, Contemporary, Intimate, Fun, American',
        annual_dol: 54.14,
        description:
            'Intimate spot offering set menu dinners with inventive, Italian dishes sourced from local producers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.78,
        total_orders: 4.44,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords:
            'Steak, Pizza, Hamburger, Gourmet, Salad, Beer, Wine, Coffee, Turkey Sandwich, Roast Beef',
        annual_dol: 15.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6228,
        store_longitude: -73.7252,
        total_orders: 2145.33,
        neighborhood: '',
        keywords: 'Steak, Wine, Upscale, Italian, Trendy, American, Warm, Romantic, Modern, Lively',
        annual_dol: 2357.26,
        description:
            'Sophisticated, modern spot for wood-grilled steaks & seafood & a full bar in a rustic-chic interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6118,
        store_longitude: -74.1593,
        total_orders: 3010.61,
        neighborhood: 'Bulls Head/Mid Island',
        keywords: 'Burger, Steak, Salad, Beer, Nachos, Wine, Bbq, Tex-Mex, Chicken Wings, Ale',
        annual_dol: 3074.49,
        description:
            'Family-friendly chain serving classic Tex-Mex & American fare in a Southwestern-style setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6225,
        store_longitude: -73.7251,
        total_orders: 2170.07,
        neighborhood: '',
        keywords:
            'Pizza, Burger, Italian, Beer, Wine, Savory, Delicious, American, Modern, Artisanal',
        annual_dol: 2361.59,
        description:
            'Rustic-chic locale offering artisanal pizza & pasta, plus craft beers on tap, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6718,
        store_longitude: -73.9581,
        total_orders: 83.37,
        neighborhood: 'Crown Heights',
        keywords: 'Cheesecake, Mexican, Perfect, American, Casual, Excellent, Great, Wonderful',
        annual_dol: 340.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -73.9452,
        total_orders: 155.66,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotel, Cozy, Mediterranean, Intimate, American, High-End, Seasonal, Natural, Middle Eastern, Recommended',
        annual_dol: 200.73,
        description:
            'Shareable, seasonal Mediterranean dishes are served in an intimate, plant-accented space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5866,
        store_longitude: -73.7055,
        total_orders: 174.78,
        neighborhood: '',
        keywords: 'Mexican, German, Beer, American, Fun, Authentic, Outstanding, Casual, Great',
        annual_dol: 451.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7221,
        store_longitude: -73.9832,
        total_orders: 73.42,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Vegan, Tasty, Chicken Parmigiana, Wine, Coffee, Italian, Organic, French Toast, Beer, Delicious',
        annual_dol: 133.26,
        description:
            'Casual restaurant offering Italian & American fare, along with brunch, cocktails & happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7172,
        store_longitude: -73.9429,
        total_orders: 411.4,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Dessert, Savory, Homey, American, Sweet, Fun, Perfect, Awesome, Bruschetta',
        annual_dol: 1681.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5877,
        store_longitude: -74.1971,
        total_orders: 125.54,
        neighborhood: 'Travis - Chelsea/Mid Island',
        keywords:
            'Wine, Italian, Elegant, Cozy, Romantic, Polished, Friendly, Perfect, Outstanding, Casual',
        annual_dol: 513.18,
        description:
            'White tablecloths & an old-world vibe for classic Italian veal & pasta dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9565,
        total_orders: 279.95,
        neighborhood: 'Williamsburg',
        keywords:
            'Champagne, Wine, Whiskey, Tasty, Gin, Coffee, Comfortable, Rum, Intimate, Authentic',
        annual_dol: 1144.33,
        description: '"Local Neighborhood Bar. Drink, Eat, Dance."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.9251,
        total_orders: 454.56,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Bbq, Beer, Southern, Casual',
        annual_dol: 1858.09,
        description:
            'Industrial venue with Southern flair selling smoked & grilled ribs, burgers, brisket, sides & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6591,
        store_longitude: -73.6447,
        total_orders: 297.23,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Hamburger, Lobster Bisque, Beer, Spicy, Bbq, Warm, Irish, American',
        annual_dol: 310.11,
        description:
            'Straightforward watering hole offering a range of comfort eats & bar bites in roomy surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6442,
        store_longitude: -73.7037,
        total_orders: 2075.93,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Steak, Shrimp Cocktail, Wine, Fruity, Tasty, Japanese, Chinese, Mongolian',
        annual_dol: 2170.72,
        description:
            'Chic strip-mall find with creatively plated fusion dishes from across Asia & inventive cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7937,
        store_longitude: -73.6899,
        total_orders: 132.23,
        neighborhood: '',
        keywords:
            'Burger, Salad, Pizza, Shrimp Cocktail, Italian, Wine, Spicy, Delicious, Beer, Warm',
        annual_dol: 140.72,
        description:
            'Hearty Italian fare, including seafood & handmade pasta, served in a warm, relaxed space with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6832,
        store_longitude: -74.0033,
        total_orders: 1145.48,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Vegan, Gluten Free, Delicious, Warm, Elegant, American, Exceptional, Intimate, Seasonal, Local',
        annual_dol: 4682.33,
        description:
            '"Dish Food & Events is a NYC based catering and events company specializing in extraordinary weddings, corporate and social events. Dish proudly creates delicious food paired with warm hospitality."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.9235,
        total_orders: 27.2,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Upscale, Salad, French Toast, Dessert, French, Beer, Latin, Warm, Brazilian',
        annual_dol: 37.03,
        description:
            'Modern hangout with bohemian interiors, offering drinks & dinner, plus music & dancing on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.733,
        store_longitude: -73.9898,
        total_orders: 576.1,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Steak, Burger, Salad, Pizza, Wine, French, Mediterranean, Asian, Perfect, Spectacular',
        annual_dol: 673.55,
        description:
            'Prime steaks, seafood & specialty cocktails in an offbeat, mural-decorated locale.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7939,
        store_longitude: -73.9727,
        total_orders: 265.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Flavorful, Wine, Salad, Tasty, Beer, Latin, American, Intimate, Vibrant, Artisanal',
        annual_dol: 1083.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7515,
        store_longitude: -73.9718,
        total_orders: 1038.79,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, French, Japanese, Cozy, Delicious, Elegant, Modern, New Zealand, Seasonal, Casual',
        annual_dol: 1083.82,
        description:
            'Minimalist, elegant eatery on two floors serving intricate Japanese meals, including tasting menus.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7547,
        store_longitude: -73.9735,
        total_orders: 546.26,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Elegant, Martini, Warm, Iconic, Amazing, Charming, Great, Gin, Salad',
        annual_dol: 837.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8008,
        store_longitude: -73.6486,
        total_orders: 884.66,
        neighborhood: '',
        keywords:
            'Mexican, Beer, Gluten Free, Elegant, Comfortable, Classy, Perfect, American, Italian, Casual',
        annual_dol: 1114.54,
        description: '"Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9715,
        total_orders: 1915.16,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Salad, Scotch, Canadian, Wine, Shrimp Scampi, Upscale, French',
        annual_dol: 4461.48,
        description:
            'Bi-level space with plush banquettes for dining on dry-aged steaks, chops & seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6233,
        store_longitude: -74.0313,
        total_orders: 1370.96,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Tacos, Mexican, Beer, Margarita, Burritos, Stylish, Homemade, Awesome, Coleslaw, Great',
        annual_dol: 1400.04,
        description:
            'Stylish, surf-themed cafe specializing in California-style tacos, burritos & Mexican small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8036,
        store_longitude: -73.736,
        total_orders: 272.38,
        neighborhood: '',
        keywords: 'Burger, Beer, Ale, Fun, Great, Mediterranean, Friendly, Local, Casual',
        annual_dol: 310.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.969,
        total_orders: 231.15,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Japanese, Warm, French, Fried Chicken, Perfect, Traditional, Great, Fresh, Fine',
        annual_dol: 944.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9856,
        total_orders: 22888.82,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Stylish, Hotel, Spacious, Cozy, Romantic, Vibrant, Bright, Perfect, Indulgent, Clean',
        annual_dol: 23471.29,
        description:
            "Featuring floor-to-ceiling windows, some with views of Times Square, the low-key rooms include flat-screen TVs, Wi-Fi (fee) and coffeemakers. Family rooms offer connecting bedrooms. Suites add living rooms; upgraded suites come with separate living and dining areas. Room service is available.There's a bright revolving restaurant, a casual eatery and a Starbucks cafe, as well as a lounge with a terrace. Other amenities include a gym and 120,000 sq ft of event space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6881,
        store_longitude: -73.9551,
        total_orders: 599.84,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Cuban, Beer, Caribbean, Rum, Salad, Coffee, Spanish, Swiss, American, Mojito',
        annual_dol: 816.57,
        description:
            'Vibrant spot with sidewalk seats offering Cuban-style sandwiches & mains, plus draft beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.9864,
        total_orders: 1151.31,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Legendary, Friendly',
        annual_dol: 1513.9,
        description:
            'The Richard Rodgers Theatre is a Broadway theater at 226 West 46th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1925, it was designed by Herbert J. Krapp and was constructed for Irwin Chanin.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6931,
        store_longitude: -73.9675,
        total_orders: 630.99,
        neighborhood: 'Clinton Hill',
        keywords:
            'Salad, Thai Basil Chicken, Thai, Vegetarian, Spicy, Asian, Bbq, Comfortable, Friendly, Pad Thai',
        annual_dol: 671.5,
        description:
            'This simple eatery with a contemporary feel features a menu of classic Thai entrees & appetizers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7161,
        store_longitude: -73.965,
        total_orders: 1382.38,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Mexican, Whiskey, Ale, Pizza, Tasty, American, Bbq, Delicious, Friendly',
        annual_dol: 2078.51,
        description:
            'Lively, late-night hangout offering draft microbrews, cocktails & pub grub, plus lots of sports TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9911,
        total_orders: 594.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Beer, Delicious, Warm, Karaoke, Spacious, Cozy, Irish, Rustic, American, Fantastic',
        annual_dol: 647.7,
        description:
            '2-floor tavern for comforting food & drink options in a traditional wood-walled interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7577,
        store_longitude: -73.9927,
        total_orders: 136.27,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Salad, Coffee, Red Eye, Upscale, Caesar Salad, Clam Chowder, Casual',
        annual_dol: 557.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.752,
        store_longitude: -74.0024,
        total_orders: 4667.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Mexican, Lobster, Upscale, Modern, Casual, Cozy, Delicious, Comfortable, Fresh, Local',
        annual_dol: 4998.86,
        description:
            'Warm, brick-clad stop serving elevated, modern Mexican dishes alongside cocktails & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6433,
        store_longitude: -73.7823,
        total_orders: 273.99,
        neighborhood: 'Jamaica',
        keywords: 'Delicious, Ethiopian, American, Spacious, Premium, Fresh, Exceptional, Seasonal',
        annual_dol: 1120,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -73.9913,
        total_orders: 20239.58,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Carrot Cake, Butter Chicken, Wine, Dessert, Romantic, Eclectic, Modern, Indian, Traditional, Sophisticated',
        annual_dol: 20805.47,
        description:
            'Place for creative, sophisticated Indian dishes & cocktails in a colorful, banquette-lined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7335,
        store_longitude: -73.993,
        total_orders: 278.52,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegan, Sushi, Tequila, Wine, Hamburger, Mexican, Vietnamese, Italian, Chinese, Stylish',
        annual_dol: 797.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9781,
        total_orders: 1842.78,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Salad, Nachos, Beer, Irish, Delicious, Caesar Salad, Casual, Great',
        annual_dol: 1894.31,
        description:
            'Publike eatery, convenient for Grand Central commuters, with beer on tap & an Irish staff.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9737,
        total_orders: 7822.6,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hotel, Luxurious, Elegant, Vintage, French Toast, Gin, Premium, French, Classic, American',
        annual_dol: 8167.33,
        description:
            "Refined rooms come with Wi-Fi and flat-screen TVs, plus desks and marble vanities; some have views of the Manhattan skyline. Upgraded rooms feature custom-designed furniture, and suites add fully stocked bars. Room service is offered 24/7.There's a chic restaurant and a swanky, gin-themed lounge bar. Other amenities include a 24-hour business center and 15,000 sq ft of meeting space, including 2 ballrooms.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5836,
        store_longitude: -73.9414,
        total_orders: 526.68,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Beer, Salad, Donut, Turkish, Rich, Mediterranean, Premium, Authentic, Greek',
        annual_dol: 549.52,
        description:
            'Seafood is the focus of this Turkish restaurant with cocktails & mural-rich, nautical decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7654,
        store_longitude: -73.7722,
        total_orders: 99.15,
        neighborhood: 'Bayside',
        keywords:
            'Salad, Steak, Wine, Pizza, French, Bourbon, Filet Mignon, Prosecco, Chic, Casual',
        annual_dol: 348.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8176,
        store_longitude: -73.9417,
        total_orders: 931.5,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Gourmet, Delicious, African, French, Belgian, American, Friendly, Fantastic, Great, Casual',
        annual_dol: 971.89,
        description:
            'French & West African cuisine are served alongside house cocktails in a warm, dark-wood bistro.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.708,
        store_longitude: -73.8311,
        total_orders: 663.34,
        neighborhood: 'Kew Gardens',
        keywords:
            'Italian, Ale, Cozy, Friendly, Casual, Authentic, American, Local, Chill, Excellent',
        annual_dol: 677.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9675,
        total_orders: 906.62,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Hamburger, Italian, Chicken Parmigiana, Gluten Free, Delicious, Cozy, Polished, Refined, Modern',
        annual_dol: 925.86,
        description:
            'Refined Neapolitan fare from restaurateur Lello Arpaia served in sizable, contemporary quarters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9874,
        total_orders: 966.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Rich, Tasty, Bourbon, Coffee, Delicious, Hotel, Beer, Salad, Cozy, French',
        annual_dol: 1046.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7829,
        store_longitude: -73.9512,
        total_orders: 2359.38,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Beer, Ale, Burger, Wine, Salad, American, Rustic, Club Sandwich, Seasonal, Delicious',
        annual_dol: 2409.45,
        description:
            'Craft beer, seasonal ales & wine with upmarket comfort food & weekend brunch in warm, rustic digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9955,
        total_orders: 4004.24,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Beer, Mediterranean, Hotel, American, Modern, Premium, Classy, Hip',
        annual_dol: 4280.79,
        description:
            'Bright YOTEL spot with plants & wood floors for New American cuisine from breakfast to late-night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.9854,
        total_orders: 1440.48,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Coffee, Stylish, Spacious, Modern, Friendly, Contemporary, Casual, Great',
        annual_dol: 1663.56,
        description:
            "Building.Simple rooms with modern accents offer free WiFi and 50-inch flat-screens. They also have microwaves, minifridges, and tea and coffeemaking equipment. Upgraded rooms add views of Times Square.Morning tea and coffee are complimentary. There's a casual restaurant, and a bar with square views. Other amenities consist of a fitness center and a business room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -74.0025,
        total_orders: 994.65,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Lobster, Salad, Pizza, Wine, Tasty, Italian, French Onion Soup, French, Cozy, Creamy',
        annual_dol: 1094.39,
        description:
            'Old-school French dishes in classic bistro surroundings, plus sidewalk seating in the heart of SoHo.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -73.9981,
        total_orders: 822.99,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Burger, Hamburger, Steak, Salad, Mexican, Beer, Tasty, Bbq, Spicy, Upscale',
        annual_dol: 842.16,
        description:
            'Notable burgers, upscale pub eats & wine on tap in a high-energy setting with a trendy crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9973,
        total_orders: 105.11,
        neighborhood: 'Nolita',
        keywords:
            'Wine, Pizza, Italian, Coffee, Delicious, Warm, Friendly, Vintage, Amazing, Great',
        annual_dol: 119.89,
        description:
            '"As a Sicilian inspired spot, Bar Pasquale features sfizione pizza, handmade and fresh extruded pastas, small plates inspired from our teams travel around the Ionian Sea, a wine list featuring wines of Mount Etna and elsewhere as well as a speakeasy cocktail bar and private dining room in the cellar. This space spreads guests across a dining room with burgundy red leather banquets, vintage furniture, classic red sauce joint attire with an open kitchen and an outdoor patio."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7809,
        store_longitude: -73.9802,
        total_orders: 1988.75,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Mexican, Tacos, Vegan, Beer, Salad, Dessert, Wine, Californian, Enchiladas, Delicious',
        annual_dol: 2020.55,
        description:
            'Beachy setting for coastal Californian items including tacos, veggie bowls & colorful cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9798,
        total_orders: 12649.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Scotch, Champagne, Whiskey, Elegant, Beer, Hotel, Premium, Classy, Iconic, Awesome',
        annual_dol: 13128.58,
        description:
            'Patrons puff on cigars & sip single malts at this clubby, elegant smoking lounge with a fireplace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.9808,
        total_orders: 2176.39,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Spacious, Classic, Friendly, Relaxed, Great, Excellent, Exceptional, High-End, Unique',
        annual_dol: 5598.58,
        description:
            "Plush studios and 1-bedroom suites have kitchenettes, living and dining areas, and desks, as well as marble bathrooms, flat-screen TVs and Wi-Fi.There's a lounge/bar, a fitness room and a business center. Breakfast and valet parking are available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9789,
        total_orders: 4118.67,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Salad, Delicious, Stylish, Greek, Mediterranean, Elegant, Fresh, Exceptional, Filet Mignon',
        annual_dol: 4297.25,
        description:
            'Stylish Greek restaurant serving fresh seafood in a Mediterranean-style whitewashed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9789,
        total_orders: 1238.05,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Flavorful, Ramen, Vegan, Spicy, Delicious, Japanese, Ale, Gyoza, Amazing, Great',
        annual_dol: 5566.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7654,
        store_longitude: -73.9801,
        total_orders: 586.1,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Mimosa, Coffee, Modern, American, Avocado Toast, Classic, Warm, Fresh',
        annual_dol: 1251.02,
        description:
            'Farm-fresh comfort food, all-day brunch & cocktails served in an old-fashioned diner with booths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.9745,
        total_orders: 777.3,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Shrimp Cocktail, Tasty, Wine, Spicy, American, Thai, Innovative, Friendly',
        annual_dol: 3177.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6653,
        store_longitude: -73.9827,
        total_orders: 1963.85,
        neighborhood: 'Park Slope',
        keywords:
            'Wine, Salad, Coffee, Beer, Cozy, Delicious, Organic, Spacious, Friendly, Intimate',
        annual_dol: 2021.01,
        description:
            'Local Park Slope wine bar also serving cocktails & a cheese, charcuterie & small-plates menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6701,
        store_longitude: -73.9787,
        total_orders: 92.26,
        neighborhood: 'Park Slope',
        keywords: 'Vodka, Whiskey, Wine, Pizza, Beer, Italian, Dessert, Coffee, Meatball, Cozy',
        annual_dol: 94.22,
        description:
            'This cozy corner Italian spot serves straightforward pastas, entrees & brunch inside or al fresco.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6712,
        store_longitude: -73.991,
        total_orders: 468.88,
        neighborhood: 'Gowanus',
        keywords: 'Burger, Pizza, Italian, Mexican, Meatball, Gourmet, Tasty, Beer, Hawaiian, Wine',
        annual_dol: 487.73,
        description:
            'Dimly lit Italian bar/bistro that draws both neighborhood locals & Bell House show-goers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7665,
        store_longitude: -73.9127,
        total_orders: 194.2,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Fun, Premium, Yummy, Fresh, American',
        annual_dol: 380.24,
        description: '"HOOKAH LOUNGE IN ASTORIA QUEENS"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7386,
        store_longitude: -73.9887,
        total_orders: 5064.22,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Beer, Californian, American, Seasonal, Tex-Mex, Contemporary, French, Warm, Local',
        annual_dol: 5436.17,
        description:
            "Danny Meyer's Flatiron District tavern with a fixed-price-only dining room & a bustling bar area.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8029,
        store_longitude: -73.9641,
        total_orders: 556.76,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Burger, Salad, Pizza, Dessert, Coffee, Wine, Italian, Upscale, Beer, Mediterranean',
        annual_dol: 568.58,
        description:
            'Refined restaurant with outdoor seating serving thoughtfully sourced Mediterranean dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5384,
        store_longitude: -74.149,
        total_orders: 75.87,
        neighborhood: 'Great Kills',
        keywords:
            'Sushi, Vegetarian, Shrimp Cocktail, Delicious, Omelette, Casual, Fresh, American, Great, Pure',
        annual_dol: 177.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6447,
        store_longitude: -73.9225,
        total_orders: 18.63,
        neighborhood: 'East Flatbush',
        keywords: 'Flavorful, Caribbean, Delicious, Local, American, Casual, Latin',
        annual_dol: 76.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9688,
        total_orders: 562.93,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Beer, Margarita, Trendy, Tex-Mex, Upscale, Enchiladas, Funky, American, Delicious',
        annual_dol: 574.87,
        description:
            'Snug restaurant serving brunch, burgers & Tex-Mex fare in a funky space with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7151,
        store_longitude: -74.0087,
        total_orders: 331.54,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vodka, Pizza, Italian, Gluten Free, Upscale, Traditional, Authentic, Fresh, Great, Fine',
        annual_dol: 1355.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8404,
        store_longitude: -73.9218,
        total_orders: 42.51,
        neighborhood: 'Highbridge/West Bronx',
        keywords:
            'Steak, Lobster, Dessert, Delicious, Latin, Fusion, American, Great, Casual, Local',
        annual_dol: 183.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7148,
        store_longitude: -74.0096,
        total_orders: 803.59,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Wine, Upscale, Hotel, Vodka, Trendy, Homey, Spacious, Sleek, Comfortable',
        annual_dol: 3287.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.9714,
        total_orders: 130.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Vegan, Coffee, Thai, Uruguayan, French, Savory, Australian, American, Contemporary',
        annual_dol: 230.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6327,
        store_longitude: -74.0275,
        total_orders: 274.33,
        neighborhood: 'Bay Ridge',
        keywords:
            'Mexican, Burger, Nachos, Dessert, Tasty, Enchiladas, French, Casual, Sweet, Fresh',
        annual_dol: 516.15,
        description: '"Mexican bar restaurant , weekend DJ live music ."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.9857,
        total_orders: 3198.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Pizza, Chicken Parmigiana, Vodka, Beer, Italian, Wine, Meatball, Coffee, Avocado Toast, Cozy',
        annual_dol: 3491.33,
        description:
            'Wide-ranging Italian menu including steak, seafood & an antipasti bar served in a sprawling space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9878,
        total_orders: 7070.61,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Beer, Salad, Tuna Steak, Dutch, Spanish, Luxurious, Irish, Rum, American',
        annual_dol: 7304.94,
        description:
            "Sib of Tanner Smith's for beer & cocktails in a space with a long bar, tiled floors & flat-screens.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6998,
        store_longitude: -73.9921,
        total_orders: 1413.4,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Garlic Bread, Salad, Steak, Pizza, Italian, Spicy, Delicious, Cozy, Warm, Romantic',
        annual_dol: 1493.65,
        description:
            'Large portions of Italian comfort food are dished for crowds. Cash only & no reservations accepted.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7791,
        store_longitude: -73.9511,
        total_orders: 1358.3,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'French Toast, Steak, French Onion Soup, French, Wine, Beer, Gluten Free, Cozy, Modern, Omelette',
        annual_dol: 1961.59,
        description:
            "Alsatian eats & a popular brunch along with a beer list that's curated by a beer sommelier.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7868,
        store_longitude: -73.7263,
        total_orders: 237.76,
        neighborhood: '',
        keywords:
            'Hotdog, Steak, Tacos, Mexican, Beer, Colombian, Coffee, American, Dominican, Latin',
        annual_dol: 353.32,
        description:
            '"Delicious authentic Colombian food, with a fun Latin fusion twist! Find us online: Instagram: @micatucagreatneck Facebook: Mi Casa Es Tu Casa Restaurant & Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6965,
        store_longitude: -73.9845,
        total_orders: 514.67,
        neighborhood: 'Brooklyn Heights/Downtown Brooklyn/Bridge Plaza',
        keywords:
            'Hotel, Stylish, Classic, Amazing, Sophisticated, Great, Aesthetic, Perfect, Authentic, Rooftop',
        annual_dol: 2103.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7097,
        store_longitude: -74.014,
        total_orders: 449.51,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Wine, Coffee, Premium, Cozy, Comfortable, Great, Excellent, Rooftop, Modern',
        annual_dol: 475.02,
        description:
            "Street.Unfussy, cozy rooms offer free Wi-Fi, flat-screen TVs and iPod docks, plus desks and Keurig coffeemakers. Upgraded rooms add kitchenettes and dining areas.Freebies include loaner iPads, nightly wine hours, and all-day snacks and drinks in a communal lounge. There's an airy 20th-floor restaurant with floor-to-ceiling windows and a terrace, as well as a high-end steakhouse. Other amenities include a gym and a meeting room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7484,
        store_longitude: -73.9947,
        total_orders: 856.19,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Italian, Romantic, Casual, Local, Fresh',
        annual_dol: 874.36,
        description:
            'Housemade sausages & cured meats are featured at this Madison Square Garden-area Northern Italian.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6843,
        store_longitude: -73.9295,
        total_orders: 44.77,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Wine, Japanese, Burger, Salad, Stylish, Eclectic, Premium, Great, Excellent, Attentive',
        annual_dol: 183,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7302,
        store_longitude: -73.9942,
        total_orders: 1311.57,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Steak, Salad, Beer, Margarita, Martini, Juicy, American, Premium',
        annual_dol: 1343.62,
        description:
            'Subterranean sports bar with a college-keg-party vibe, pub grub & beer plus pool & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7346,
        store_longitude: -73.9879,
        total_orders: 1298.82,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Sushi, Margarita, Beer, Delicious, Cozy, Intimate, Perfect, Cool, Casual, Fresh',
        annual_dol: 1465.51,
        description:
            'Intimate cocktail bar with an easygoing vibe & hidden elements like a secluded patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7933,
        store_longitude: -73.9711,
        total_orders: 287.35,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Mexican, Tacos, Tequila, Margarita, Italian, French, Delicious, Casual, Fresh, Authentic',
        annual_dol: 351.99,
        description:
            '"Authentic Mexican food made with daily fresh ingredients. offering delicious Margaritas and cocktails with unic flavors and aromas"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.8781,
        total_orders: 19.06,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords: 'Pizza, Steak, Cuban, Vodka, Homemade, Modern, Casual, Fresh',
        annual_dol: 77.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7144,
        store_longitude: -74.0098,
        total_orders: 2711.32,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Beer, Whiskey, Irish, American, Upscale, Chill, Local, Casual, Great',
        annual_dol: 3014.78,
        description:
            'Bi-level bar serving elevated pub grub & craft beer in a rustic-chic space with sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9679,
        total_orders: 318.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Chili Chicken, Irish, Nachos, Polish, Friendly, Awesome, Mouth-Watering, Casual, Classic, Chill',
        annual_dol: 1337.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6894,
        store_longitude: -73.9861,
        total_orders: 8660.68,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Wine, Beer, Whiskey, Tasty, Delicious, Spacious, Rustic, Friendly, Creative, Great',
        annual_dol: 8844.45,
        description:
            'Rustic bar with a Catskills look offering wine, cocktails, sandwiches, charcuterie & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7867,
        store_longitude: -73.7271,
        total_orders: 240.9,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Lobster Bisque, Mexican, Gluten Free, Beer, Dessert, Wine, Spanish',
        annual_dol: 595.97,
        description:
            '"We cook seafood like rock stars, truly. There is only one way to eat fish – when its fresh! Our seafood arrives 7 days a week and is carefully selected before hitting our kitchen."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6906,
        store_longitude: -73.9951,
        total_orders: 91.54,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Pizza, Italian, Wine, Meatball, Dessert, Eclectic, Warm, Cozy, Modern, Iconic',
        annual_dol: 97.63,
        description:
            'Modern nook renowned for its Neapolitan-style pies, plus fresh pasta dishes & Italian wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6895,
        store_longitude: -73.981,
        total_orders: 740.97,
        neighborhood: 'Fort Greene/Brooklyn Heights',
        keywords:
            'Beer, Tasty, Margarita, Bourbon, Delicious, Crisp, Friendly, Casual, Lively, Classic',
        annual_dol: 2847.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.9924,
        total_orders: 889.72,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Sushi, Hamburger, Italian, Japanese, Modern, Casual, Vintage, Elegant, Local',
        annual_dol: 928.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5609,
        store_longitude: -73.9121,
        total_orders: 325.93,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords:
            'Pizza, Beer, Nutritious, Coffee, Delicious, American, Hotel, Irish, Casual, Local',
        annual_dol: 334.34,
        description:
            'Beer & pub grub served in a straightforward, Irish-style watering hole with flat-screen TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9949,
        total_orders: 2129.96,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Pizza, Beer, Irish, Nachos, Ale, American, Casual, Aesthetic',
        annual_dol: 2193.33,
        description:
            'Irish pub in the classic, casual style, offering a happy-hour scene and typical grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -74.0035,
        total_orders: 684.36,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Mexican, Hotel, Coffee, Rooftop, Martini, Trendy, Mediterranean, Bellini, Modern, Sleek',
        annual_dol: 2797.42,
        description:
            "Modern, zenlike rooms feature handmade denim bathrobes, wooden platform beds, bean bag chairs and city views, plus free Wi-Fi, flat-screen TVs and minibars. Some rooms add Japanese-style soaking tubs. Room service is available.Loaner bikes are available. There's also a sleek Mexican restaurant/bar and outdoor patio, plus a trendy rooftop eatery offering Mediterranean fare. Other amenities include 2 bars, a coffee shop and a rooftop pool.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9938,
        total_orders: 330.44,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Mexican, Tacos, Burritos, Salad, Casual, Local, Relaxed, Fancy, Recommended',
        annual_dol: 1408.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7515,
        store_longitude: -73.994,
        total_orders: 2559.77,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Hotel, Luxurious, Stylish, American, Iconic, Rooftop, Innovative, Tranquil, Exceptional, Chic',
        annual_dol: 2952.06,
        description:
            'and Times Square.Featuring floor-to-ceiling windows, the sleek, tranquil rooms come with free Wi-Fi, flat-screen TVs and minibars, as well as sitting areas, Nespresso machines and yoga equipment. The 1- and 2-bedroom suites add separate living space. All offer city or river views.A stylish 24th-floor restaurant provides a terrace. There’s also a spa and an expansive 60,000 sq ft fitness center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -73.9935,
        total_orders: 1580.45,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Vegan, Rooftop, Hotel, Upscale, Trendy, Spicy, Scotch, Fresh, Fantastic',
        annual_dol: 2868.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8494,
        store_longitude: -73.6446,
        total_orders: 308.2,
        neighborhood: '',
        keywords:
            'Lobster, Salad, Vegetarian, Pizza, Gluten Free, Italian, Wine, Coffee, Japanese, Beer',
        annual_dol: 314.74,
        description:
            'Casual New American find showcasing an eclectic menu in a modern, airy space with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7769,
        store_longitude: -73.9214,
        total_orders: 80.09,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Cheesecake, Pizza, Italian, Gluten Free, Wine, Homemade, Creme Brulee, Mediterranean, Modern, Casual',
        annual_dol: 126.45,
        description:
            'Brick-oven pizzas, pasta & other Italian eats in a casual space with a full bar & patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7263,
        store_longitude: -73.9921,
        total_orders: 11166.28,
        neighborhood: 'NoHo',
        keywords: 'Whiskey, Beer, Vodka, Tasty, Steak, Vintage, Upscale, Delicious, Warm, Irish',
        annual_dol: 11403.21,
        description:
            'Hearty, upscale pub eats, craft cocktails, beer & lots of spirits in a rustic, happening space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7676,
        store_longitude: -73.9833,
        total_orders: 7320.85,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Salad, Steak, Japanese, Fried Chicken, Hotel, Delicious, Asian, Fusion, Authentic',
        annual_dol: 17538.73,
        description:
            'Creative seafood & grilled meats plus tempura fried chicken & cocktails in a wood-paneled lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7671,
        store_longitude: -73.9865,
        total_orders: 2813.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Trendy, Karaoke, Fun, Friendly, Phenomenal, Great, Casual',
        annual_dol: 3218.99,
        description:
            'Modern, brick-lined gay bar for cocktails & entertainment from drag shows to karaoke nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 2215.22,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Rich, Hotel, Rooftop, Velvety, Awesome, Fun, Premium, Cozy, Perfect, Gourmet',
        annual_dol: 2362.48,
        description:
            'Swanky lounge with a penthouse vibe, sweeping views, DJs & a menu of cocktails & gourmet bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6982,
        store_longitude: -73.8061,
        total_orders: 15.63,
        neighborhood: 'Jamaica',
        keywords:
            'Steak, Burger, Salad, Dessert, Coffee, Delicious, French, Sangria, Authentic, Casual',
        annual_dol: 63.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9787,
        total_orders: 302.21,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Japanese, Modernist, Elegant, Vintage, Premium, Authentic, Cultural, Unique',
        annual_dol: 370.2,
        description:
            'The Nippon Club of New York City is a private social club on 57th Street in Midtown Manhattan, New York City, founded in 1905 by Jōkichi Takamine for Japanese Americans and Japanese nationals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.764,
        store_longitude: -73.9734,
        total_orders: 10.58,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Premium, American, Outstanding, Perfect, Hotel, Fancy, Fresh, Great, Casual',
        annual_dol: 43.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7162,
        store_longitude: -73.9896,
        total_orders: 555.22,
        neighborhood: 'Lower East Side',
        keywords:
            'Hotdog, Whiskey, Beer, Steak, Spicy, Margarita, Delicious, Quaint, Cozy, Premium',
        annual_dol: 605.1,
        description:
            'This intimate tavern features a full calendar of eclectic events, plus hot dogs & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7183,
        store_longitude: -73.9881,
        total_orders: 963.14,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Tacos, Wine, Tasty, Funky, Hotel, Cozy, Crisp, Awesome, Fresh',
        annual_dol: 1320.16,
        description:
            '"The Grand Delancey is open for outdoor service! Expect the same excellent draft bottled and canned craft beers you know and love alongside rare cellar selections. Plus, find a list of ciders and natural wines from our friends at Peoples Wine, plus a handful of our artisan cocktails. Our food menu is a veritable Market Line reunion with a broad selection of dishes from our buddies at Slice Joint, Schaller & Weber and Que Chevere, along with items from Pickle Guys, Nixtamal, Essex Pearl and Southeast Asia Food Group."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -73.9884,
        total_orders: 511.89,
        neighborhood: 'Lower East Side',
        keywords: 'Spicy, Bbq, Fun, Homemade, Great, Diverse, Casual, Hummus, Unique',
        annual_dol: 2092.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.7921,
        total_orders: 341.13,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Burger, Vegan, Spicy, Dessert, Wine, Beer, Korean, Romantic, Sweet, Vietnamese',
        annual_dol: 927.09,
        description:
            '"Cozy neighborhood hangout serving contemporary American fare with a Korean twist accompanied by craft beers and innovative cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.8869,
        total_orders: 6.55,
        neighborhood: 'Jackson Heights',
        keywords:
            'Burger, Delicious, Impeccable, Amazing, Colombian, Peruvian, Perfect, Wonderful, American, Great',
        annual_dol: 26.76,
        description:
            '"Modern Latin Fusion Cousine with A twist now in the heart of Jackson heights, Queens Amazing unique Cocktails, Delicious food with beautiful presentations. Fabrika is where unique experiences are fabricated!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5888,
        store_longitude: -73.8173,
        total_orders: 16221.21,
        neighborhood: 'Far Rockaway/Rockaway Beach',
        keywords:
            'Salad, Wine, Beer, Vodka, Mexican, French Toast, French, Thai, Mediterranean, Bbq',
        annual_dol: 18074.71,
        description:
            'Casual waterfront restaurant & bar offering a raw bar, American mains, a deck & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5404,
        store_longitude: -74.1771,
        total_orders: 259,
        neighborhood: 'Annadale',
        keywords:
            'Steak, Burger, Vegetarian, Pizza, Salad, Italian, Beer, American, Karaoke, Homemade',
        annual_dol: 333.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7448,
        store_longitude: -73.9833,
        total_orders: 6085.56,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Luxurious, Wine, Rooftop, Stylish, Delicious, Eclectic, Innovative, Mediterranean, Classy',
        annual_dol: 6214.69,
        description:
            "13-minute walk from events at Madison Square Garden.Airy, sophisticated rooms feature smart TVs and minibars, along with sitting areas (some with sofas). Upgraded rooms offer balconies with city views. Suites add separate living/dining rooms; an upgraded suite comes with 2 bedrooms, 2 bathrooms and 3 balconies, as well as a wet bar.There's a high-end Mediterranean restaurant and an ultra-hip underground nightclub, plus a rooftop bar with a terrace.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7116,
        store_longitude: -73.9662,
        total_orders: 611.2,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Beer, Intimate, Casual, Cool, Atmospheric, Fun, Chill, Great',
        annual_dol: 624.17,
        description:
            'Atmospheric nightclub & coffeehouse in a former garage featuring music, drinks & various events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 1754.4,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Beer, Delicious, Belgian, Sweet, American, Friendly, Amazing, Fantastic, Great',
        annual_dol: 1852.7,
        description:
            'Rustic-chic resto-bar with diverse comfort fare, original cocktails & beer plus a back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9262,
        total_orders: 82.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tequila, Tacos, Mexican, Fish Tacos, Gluten Free, Lively, Local, Casual',
        annual_dol: 407.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7188,
        store_longitude: -73.9586,
        total_orders: 167.81,
        neighborhood: 'Williamsburg',
        keywords:
            'Italian, Hotel, Legendary, Romantic, Classic, Innovative, Sleek, Fantastic, Authentic, Great',
        annual_dol: 200.78,
        description:
            'High-end pick for classic Italian cuisine, cocktails & wines in posh, sleek surrounds with a lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.9679,
        total_orders: 139.88,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Coffee, Beer, Cool, Casual, American, Laid-Back, Great',
        annual_dol: 571.8,
        description:
            '"A neighbourhood bar with board games, John Carpenter movies, a shuffleboard & a dartboard. It\'s still 1985 right?"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9687,
        total_orders: 2968.14,
        neighborhood: 'Central Park West Historic District',
        keywords:
            'Cheesecake, Salad, Tomato Soup, Dessert, Polished, Romantic, Homemade, Natural, Casual, American',
        annual_dol: 7346.57,
        description:
            'American restaurant & bar notable for its prime lakeside setting in the middle of Central Park.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8088,
        store_longitude: -73.945,
        total_orders: 1572.45,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Lobster, Delicious, Bread Pudding, Karaoke, Creamy, Bbq, Fabulous, Southern Comfort, Caribbean, Amazing',
        annual_dol: 1797.56,
        description:
            'Bi-level nightspot serving specialty cocktails plus a menu of Southern comfort food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9845,
        total_orders: 2331.96,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Trendy, Coffee, Sensational, Rooftop, Incredible, Great, Perfect, Contemporary, Unique',
        annual_dol: 2481.66,
        description:
            'Central Park.Streamlined, compact rooms offer free Wi-Fi and smart TVs, plus floor-to-ceiling windows and rainfall showerheads. They also include tablets that control the blinds, temperature and lighting.Amenities include a trendy lobby, a library, and a slick 24-hour coffee shop with grab-and-go fare. There’s also a rooftop guest-only bar, as well as a 20th-floor gym with city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7712,
        store_longitude: -73.9932,
        total_orders: 906.81,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Salad, Beer, Mimosa, Upscale, French, American, Mediterranean, Greek',
        annual_dol: 1112.63,
        description:
            'Group-friendly spot serving Greek specialties, grilled seafood, cocktails & Mediterranean wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7672,
        store_longitude: -73.9836,
        total_orders: 1222.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Salad, Dessert, Irish, American, Amazing, Great, Casual, Traditional, Chill',
        annual_dol: 1421.21,
        description:
            'Traditional American grill restaurant & sports bar showing all the big games on multiple TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.9858,
        total_orders: 461.8,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegetarian, Burger, Gourmet, Coffee, Wine, Beer, Mimosa, French Toast, Japanese, French',
        annual_dol: 671.76,
        description:
            'American bistro fare served in a hip, convivial setting with three floors, two bars & a beer garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7211,
        store_longitude: -73.988,
        total_orders: 5441.58,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Sushi, Salad, Japanese, Wine, Beer, Stylish, American, Caesar Salad, Homemade',
        annual_dol: 5935.6,
        description:
            'Chill, stylish sake bar & restaurant featuring many rice wines, plus elevated Japanese cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7211,
        store_longitude: -73.9889,
        total_orders: 950.75,
        neighborhood: 'Lower East Side',
        keywords: 'Stylish, Hotel, Cozy, Bright, Lively, Coffee, Friendly, Clean, Modern, Rooftop',
        annual_dol: 3886.36,
        description:
            'Building.Featuring city or street views, bright rooms come with complimentary Wi-Fi, flat-screen TVs and workspaces.Tea and coffee in the lobby are included. Amenities consist of a cozy lounge and a rooftop terrace offering views of the city.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.714,
        store_longitude: -73.9889,
        total_orders: 34.18,
        neighborhood: 'Two Bridges',
        keywords:
            'Burger, Vegan, Salad, Gluten Free, Coffee, Avocado Toast, Mediterranean, Shawarma, American, Omelette',
        annual_dol: 139.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6188,
        store_longitude: -74.0332,
        total_orders: 73.64,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Wine, Tasty, Pizza, Beer, Martini, Delicious, Romantic, Cozy, Friendly, American',
        annual_dol: 301.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -73.9987,
        total_orders: 1849.28,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotdog, Beer, Ale, Nachos, Awesome, Fresh, Creative, Classic, Great, Local',
        annual_dol: 6967.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -74.0046,
        total_orders: 2060.2,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Salad, Wine, Tasty, Beer, Japanese, Whiskey, Spicy, Organic, Mediterranean',
        annual_dol: 2175.89,
        description:
            'Traditional Japanese eats pair with sake & shochu in an art-adorned space with exposed-brick walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7324,
        store_longitude: -73.9987,
        total_orders: 720.81,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Burger, Lobster, Tequila, Salad, Wine, Beer, Coffee, Dessert, Bourbon, Hotel',
        annual_dol: 752.06,
        description:
            'Low-key mainstay on Washington Square offers New American menu, afternoon tea & jazz brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6858,
        store_longitude: -73.9816,
        total_orders: 380.02,
        neighborhood: 'Boerum Hill',
        keywords: 'Tasty, Coffee, Beer, Authentic, Wonderful, Great, Creative, Diverse, Local',
        annual_dol: 442.96,
        description:
            'Laughter abounds at this stand-up joint offering acts from both amateurs & seasoned pros.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6791,
        store_longitude: -73.9737,
        total_orders: 37.15,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Vegetarian, Salad, Thai, Gourmet, Gluten Free, Stylish, Martini, Bbq, Yummy, Picturesque',
        annual_dol: 151.85,
        description: 'Stylish neighborhood place specializing in basic, homestyle Siamese dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -73.9782,
        total_orders: 1116.17,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Vodka, Bourbon, Upscale, Premium, Cozy, Sophisticated, Bright, Great, Cool, Contemporary',
        annual_dol: 1441.85,
        description:
            'Hip, contemporary craft cocktail bar with upscale lounge vibe & small menu of sophisticated bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7132,
        store_longitude: -73.9577,
        total_orders: 1426.81,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Beer, Salad, Wine, Quaint, Brazilian, Homemade, Natural, Casual, Fresh',
        annual_dol: 2331.65,
        description:
            'Quaint spot featuring a menu of tapas & pintxos along with beer, natural wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7134,
        store_longitude: -73.9513,
        total_orders: 1230.44,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Coffee, Italian, Dessert, Beer, Cozy, Warm, Modern, Fabulous, Authentic',
        annual_dol: 1586.7,
        description:
            'Pinsa, a traditional flatbread, headlines a menu of Roman classics prepared in an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.613,
        store_longitude: -74.1216,
        total_orders: 5.56,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords:
            'Mexican, Tacos, Steak, Quesadillas, Turkish, Bbq, Fajitas, Rustic, Cosmopolitan, Mediterranean',
        annual_dol: 22.72,
        description: '"Authentic Mexican restaurant on Victory Boulevard in Staten Island"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7401,
        store_longitude: -73.7911,
        total_orders: 420.32,
        neighborhood: 'Fresh Meadows/Auburndale/Flushing',
        keywords: 'Fresh, Coffee, Hotel, Friendly, Great, Casual',
        annual_dol: 468,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7077,
        store_longitude: -74.0076,
        total_orders: 382.3,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Steak, Shrimp Cocktail, Chicken Pot Pie, Fried Calamari, Irish, Caesar Salad, Delicious, American',
        annual_dol: 1367.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6373,
        store_longitude: -74.0756,
        total_orders: 5.47,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords: 'Burger, French, British, Casual, Great',
        annual_dol: 22.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6244,
        store_longitude: -74.1487,
        total_orders: 7.97,
        neighborhood: 'Mid Island/Graniteville',
        keywords: 'Sushi, Steak, Salad, Pizza, Japanese, Coffee, Spicy, Chinese, Sashimi, Thai',
        annual_dol: 32.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7418,
        store_longitude: -73.9812,
        total_orders: 6900.63,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Burger, Salad, Spicy, Delicious, American, Irish, Cozy, Yummy, French, Sweet',
        annual_dol: 7438.15,
        description:
            'Hip hangout serving creative cocktails & American fare, with a private speakeasy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9762,
        total_orders: 155.02,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Turkish, Hungarian, American, Nordic, Heritage, Cultural, Foreign, Great',
        annual_dol: 161.75,
        description:
            'The Civic Club building, now the New York Estonian House, is a four-story Beaux-Arts building located at 243 East 34th Street between Second and Third Avenues in the Murray Hill neighborhood of Manhattan in New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.9795,
        total_orders: 68.11,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Pizza, French, Delicious, Casual, Local, Traditional, Great, Teriyaki',
        annual_dol: 278.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.705,
        store_longitude: -74.0097,
        total_orders: 4591.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Lobster, Cheesecake, Salad, New-York-Style, Prime Rib, American, Legendary, Southern, Classic',
        annual_dol: 16118.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7255,
        store_longitude: -74.0052,
        total_orders: 7325.29,
        neighborhood: 'Hudson Square',
        keywords:
            'Hotel, Coffee, Italian, Stylish, Urban, Premium, American, Outstanding, Refined, Intimate',
        annual_dol: 7480.72,
        description:
            "Offering floor-to-ceiling windows with city or Hudson River views, the refined rooms feature flat-screen TVs, Nespresso machines and free Wi-Fi, plus iPod docks, soaking tubs and rainfall showers. Suites add living rooms; some have whirlpool tubs.There's a chic restaurant serving contemporary American dishes, and a hip lounge/bar with a mid-century theme. Other amenities include a fitness center, a spa with a hammam, and a seasonal outdoor pool with a bar. There's also a ballroom, meeting space and valet parking.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7045,
        store_longitude: -74.0104,
        total_orders: 284.8,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Wine, Whiskey, Beer, Premium, Eclectic, Cozy, Hamburger, Picturesque, Amazing, Impressive',
        annual_dol: 1462.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7471,
        store_longitude: -73.9241,
        total_orders: 214.02,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Ale, Beer, American, Homey, Excellent, Great, Casual, Attentive',
        annual_dol: 255.66,
        description:
            'Homey firehouse-themed bar with an enclosed back patio, a hearty pub food menu & sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7081,
        store_longitude: -73.9236,
        total_orders: 1766.34,
        neighborhood: 'East Williamsburg',
        keywords:
            'Wine, Tasty, Delicious, Intimate, Fun, Awesome, Great, Excellent, Quirky, Creative',
        annual_dol: 1925.04,
        description:
            'Hip, sci-fi themed nightspot with a retro feel & neon-lit decor serving creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7175,
        store_longitude: -74.0102,
        total_orders: 1942.88,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Salad, Whiskey, Beer, Tasty, Bourbon, Spicy, Trendy, Bbq, Organic',
        annual_dol: 2027.12,
        description:
            'Mellow, unassuming neighborhood hang draws a Wall Street crowd for beers, bourbon & bar grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6529,
        store_longitude: -74.0033,
        total_orders: 584.64,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Pizza, Burger, Hotel, Fun, Perfect, Friendly, Great, Casual',
        annual_dol: 666.89,
        description:
            'Bowling alley offering an arcade, snack bar & lounge, plus leagues & birthday parties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7202,
        store_longitude: -74.0097,
        total_orders: 751.9,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Lobster, Lobster Roll, Salad, Italian, French Toast, Savory, Hawaiian, French, Hotel',
        annual_dol: 767.85,
        description:
            'Tiny bar/restaurant in a converted carriage house with vintage decor & a noteworthy bathroom.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7976,
        store_longitude: -73.9484,
        total_orders: 921.99,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Wine, Beer, Salad, Dessert, Nachos, Polished, American, Friendly, Sweet, Casual',
        annual_dol: 1027.34,
        description:
            'Vibrant, rustic-chic establishment offering New American dishes, craft cocktails & patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.5888,
        total_orders: 118.98,
        neighborhood: '',
        keywords:
            'Vegetarian, Vegan, Sushi, Salad, Kebab, Pizza, Delicious, Turkish, Mediterranean, Friendly',
        annual_dol: 157.51,
        description:
            'Kebabs & other chargrilled Middle Eastern eats served in a bare-bones strip-mall space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8406,
        store_longitude: -73.8417,
        total_orders: 1142.07,
        neighborhood: 'Westchester Square',
        keywords: 'Burger, Sushi, Salad, Beer, Spicy, Chinese, Asian, Latin, Fusion, Global',
        annual_dol: 1257.09,
        description:
            'Asian fare with global influences plus cocktails, beer & sake are served in a casual setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.8452,
        total_orders: 318.66,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Sushi, Steak, Japanese, Spicy, Sashimi, Ribeye Steak, Asian, Delicious, Classy, Fusion',
        annual_dol: 354.24,
        description:
            'Traditional Japanese restaurant known for its sushi bar & chef shows at hibachi tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7209,
        store_longitude: -73.8465,
        total_orders: 146.84,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Mexican, Salad, Gourmet, Nachos, Spicy, Delicious, Warm, American, Friendly, Latin',
        annual_dol: 161.48,
        description:
            'Hearty Mexican fare, including fajitas, tableside guacamole & enchiladas, served in a warm setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8213,
        store_longitude: -73.9307,
        total_orders: 339.56,
        neighborhood: 'West Bronx',
        keywords: 'Steak, Cajun, Bourbon, Beer, American, Classic, Casual, Lively, Local, Great',
        annual_dol: 617.94,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -74.0078,
        total_orders: 3085.31,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Burger, Beer, Dessert, American, Casual, Classic, Sweet, Rare, Great',
        annual_dol: 18682.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -74.0036,
        total_orders: 70.66,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Kebab, Salad, Butter Chicken, Mimosa, Wine, Margarita, Vodka, Nutritious',
        annual_dol: 476.13,
        description:
            'Simple spot serving American fare with Middle Eastern influences plus classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -74.0037,
        total_orders: 103.33,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesecake, Salad, Italian, Wine, Shrimp Scampi, Meatball, Beer, Delicious, Cozy, Rustic',
        annual_dol: 422.37,
        description:
            'Classic Italian pastas & hearty meat dishes pair with wine & cocktails in tiny digs with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7853,
        store_longitude: -73.9768,
        total_orders: 44.51,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Creamy, Comfortable, Sleek, Mediterranean, Casual, American, Homemade, Fine, Excellent',
        annual_dol: 181.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6667,
        store_longitude: -73.5536,
        total_orders: 1075.2,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Shrimp Cocktail, Italian, Spicy, Rustic, Polished, Healthy, Friendly, Casual',
        annual_dol: 1123.2,
        description:
            'This trattoria serves brick-oven pizzas & other traditional Italian fare in a rustic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5757,
        store_longitude: -73.9802,
        total_orders: 235.53,
        neighborhood: 'Coney Island',
        keywords: 'Cajun, Shrimp Cocktail, Authentic, Fun, Perfect, Great, Casual',
        annual_dol: 1098.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7659,
        store_longitude: -73.9695,
        total_orders: 1190.24,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Wine, Vegetarian, Tasty, French, Upscale, Beer, Grand Marnier, Hotel, Mediterranean',
        annual_dol: 1241.84,
        description:
            'Place for elegant French fare with a Moroccan touch in The Lowell hotel, with a bar & skylit garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.744,
        store_longitude: -73.9237,
        total_orders: 151.52,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Tacos, Mexican, Tequila, Beer, Flavorful, Margarita, Tasty, Salad, Dessert, Wine',
        annual_dol: 161.59,
        description:
            'A younger crowd enjoys a mix of Mexican plates & margaritas at this casual local cantina.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.5555,
        total_orders: 1148.61,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Lobster Tail, Pizza, Butter Chicken, Wine, Beer, Upscale, Swiss',
        annual_dol: 1172.98,
        description:
            'Easygoing operation with an upscale atmosphere serving classic grilled meat dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6254,
        store_longitude: -73.7131,
        total_orders: 427.52,
        neighborhood: '',
        keywords: 'Champagne, Luxurious, Premium, Classic, Signature',
        annual_dol: 847.54,
        description:
            '"The Woodmere Club is located on the South Shore of Long Island. Offering Har-Tru Tennis Courts, resort style swimming pool, full service restaurant, ladies & men\'s card rooms. We also provide catering for members and non members"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -74.0102,
        total_orders: 26771.32,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Mexican, Beer, Margarita, Salad, Wine, Authentic, Local, Casual, Fresh, Sangria',
        annual_dol: 27914.34,
        description:
            'Warm, sit-down Mexican restaurant with specialty margaritas & Southwestern decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8006,
        store_longitude: -73.9658,
        total_orders: 2512.17,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Wine, Pizza, Beer, Trendy, Italian, Delicious, Perfect, Mediterranean, Casual, Fresh',
        annual_dol: 2866.3,
        description:
            'Park eatery with patio tables & river views offering a pub menu with a global twist, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7229,
        store_longitude: -74.0048,
        total_orders: 33.68,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Sushi, Japanese, Sashimi, Asian, Intimate, Sparkling, Seasonal, Comfortable, Classic, Omakase',
        annual_dol: 137.66,
        description:
            '"Kintsugi Sushi is an intimate omakase experience offering limited seating and outdoor dining in SoHo."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8068,
        store_longitude: -73.9269,
        total_orders: 329.03,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Burger, Beer, Coffee, Margarita, Fun, Awesome, American, Asian, Fusion, Hip',
        annual_dol: 947.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7941,
        store_longitude: -73.9405,
        total_orders: 38.29,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Tacos, Mexican, Margarita, French Toast, Tasty, French, Latin, Fusion, Casual',
        annual_dol: 52.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9924,
        total_orders: 52.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Stylish, Hotel, Trendy, Wine, Spacious, Clean, Friendly, Beer, Classic, Contemporary',
        annual_dol: 213.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7764,
        store_longitude: -73.9506,
        total_orders: 2588.28,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Vegetarian, Steak, Beer, Whiskey, Coffee, Ale, Gin, Irish, Friendly, Casual',
        annual_dol: 2685.82,
        description:
            'Time-tested, inviting haunt known for its complimentary potato chips with a drink purchase.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7755,
        store_longitude: -73.9638,
        total_orders: 12491.57,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Sushi, Hotel, Pizza, Dessert, Innovative, Classic, Global, Fresh, Excellent',
        annual_dol: 13615.25,
        description:
            'Polished, art deco-inspired rooms have free Wi-Fi, custom furniture and marble bathrooms with mirrored flat-screen TVs; suites add extra living space. Some rooms and suites also come with granite bars and kitchens with designer appliances.Perks include a fitness center and a salon, and picnic baskets and personal shoppers are available. Shoeshine service and loaner bikes are complimentary, and there’s also a swanky bar and an acclaimed restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.8425,
        total_orders: 98.76,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Cajun, Shrimp Scampi, Bbq, Irish, Chinese, Spicy, French, Traditional, Casual',
        annual_dol: 403.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7772,
        store_longitude: -73.9538,
        total_orders: 1654.63,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Irish, Upscale, Cozy, Smoky, Inviting, Friendly, Classy, Casual, Local',
        annual_dol: 1730.48,
        description:
            'Cozy neighborhood gathering place featuring pints, a jukebox & an inviting atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6816,
        store_longitude: -73.8799,
        total_orders: 7.2,
        neighborhood: 'Cypress Hills',
        keywords:
            'Steak, Meatball, Dominican, Salad, Caribbean, American, Latin, Local, Casual, Great',
        annual_dol: 29.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7777,
        store_longitude: -73.9517,
        total_orders: 213.54,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Hotdog, German, Austrian, American, Wine, Dessert, Classic, Eclectic, Seasonal, Casual',
        annual_dol: 872.87,
        description:
            'Compact counter serve doling out elevated sausage sandwiches with an international twist, plus beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8276,
        store_longitude: -73.9243,
        total_orders: 59.6,
        neighborhood: 'Concourse/West Bronx',
        keywords:
            'Salad, Coffee, Dominican, Spanish, Puerto Rican, Latin, American, Local, Casual, Fine',
        annual_dol: 91.39,
        description:
            'Enduring, family-owned restaurant for Puerto Rican eats, such as pernil & mofongo, plus breakfast.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7766,
        store_longitude: -73.9554,
        total_orders: 134.02,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Belgian, Irish, Cozy, Great, Authentic, Casual',
        annual_dol: 547.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9601,
        total_orders: 17.41,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Salad, Dessert, Wine, Japanese, Warm, Impeccable, Ale, Friendly, Hotel',
        annual_dol: 71.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7296,
        store_longitude: -73.9871,
        total_orders: 1013.98,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Clean, Coffee, Crisp, Ale, Fresh, Ramen, Great, Impressive, Casual',
        annual_dol: 1041.02,
        description:
            'Easygoing pit stop with a neighborhood vibe offering beer & cocktails, plus TV sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7362,
        store_longitude: -73.9874,
        total_orders: 324.5,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Wine, Elegant, Romantic, Cozy, Quaint, Classy, Intimate, Mediterranean, Robust, Distilled',
        annual_dol: 378.54,
        description:
            'Intimate wine bar serving mostly Old World varietals plus small plates in a wood-accented space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.752,
        store_longitude: -73.9793,
        total_orders: 3908.26,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Burger, Lobster, Cheesesteak, Canadian, Lobster Bisque, Bourbon, Vodka, Norwegian, French',
        annual_dol: 3991.19,
        description:
            'Dry-aged steaks & a casual bar menu, plus raw & grilled seafood in a clubby space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7536,
        store_longitude: -73.9735,
        total_orders: 3947.63,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Wine, American, Italian, Impeccable, Premium, Modern, Legendary, Contemporary, Lively',
        annual_dol: 4231.03,
        description:
            'An upscale American spot with classic decor, including a photo collection, private rooms & roof bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.576,
        store_longitude: -73.9691,
        total_orders: 581.85,
        neighborhood: 'Coney Island',
        keywords:
            'Salad, Wine, Upscale, Delicious, Russian, French, Premium, Modern, Exquisite, Mediterranean',
        annual_dol: 758.56,
        description:
            'French-inspired New American fare is the focus of this chic eatery with a bar & sizable wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.833,
        total_orders: 288.95,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Juicy, Cajun, Delicious, Creole, Casual, Recommended',
        annual_dol: 1014.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9688,
        total_orders: 39.98,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Tacos, Salad, Burritos, Warm, Delicious, Local, Healthy, Casual',
        annual_dol: 255.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7631,
        store_longitude: -73.9964,
        total_orders: 992.23,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Salad, Steak, Chicken Caesar Salad, Wine, Vodka, Scotch, Irish, Beer, Rich',
        annual_dol: 1225.17,
        description:
            'Landmark Irish tavern, originally opened in 1868, with classic decor & a pub-grub menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9897,
        total_orders: 3055.3,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Italian, Salad, Wine, Delicious, Martini, Cozy, Innovative, Fun, Organic',
        annual_dol: 3120.13,
        description:
            '"The name Becco is derived from the Italian verb, beccare, which means to peck, nibble or savor something in a discriminating way. Lidia Bastianich & her son, Joe, opened Becco in 1993 & since then, it has been providing the New York community with outstanding service for over 20 years. Located on New York’s famed Restaurant Row in the heart of the theater district, Becco is open 7 days a week for both lunch & dinner. Becco is most famous for its’ innovative pasta tasting menu & extensive $31 wine list."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9804,
        total_orders: 2354.4,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Ramen, Flavorful, Vegan, Ale, Spicy, Trendy, Japanese, Spacious, Authentic, Great',
        annual_dol: 2816.84,
        description:
            'Japanese chain focused on ramen, with this branch showcasing kasane (pork & chicken) broth.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9881,
        total_orders: 7043.88,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Steak, Wine, Beer, Salad, Irish, Nachos, American, Delicious, French, Spacious',
        annual_dol: 7518.8,
        description:
            'Spacious 4-story Irish pub with a rooftop bar offering traditional eats, draft beer & many TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9829,
        total_orders: 150.52,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Japanese, Tasty, Sashimi, Sukiyaki, Modern, Casual, Traditional, Omakase',
        annual_dol: 615.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9813,
        total_orders: 710.9,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Upscale, Sushi, Gin, Elegant, Italian, Creamy, Premium, Intimate, Whimsical, Modern',
        annual_dol: 2905.94,
        description:
            '"Madame George is an intimate subterranean cocktail lounge with an effortlessly elegant interior. Step from the busy streets of Manhattan into a whimsical space, where luxe textures and bright accents form a striking canvas for creative cocktails and shareable bites. The first annual menu is inspired by New York City through the ages. We strive to evoke multiple senses of our guests. With eye catching presentation and aromatics a plenty, all hoping to trigger your own relationship with the city that never sleeps. From the team that brought you Valerie, New York City, this one’s for you."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6971,
        store_longitude: -73.9708,
        total_orders: 260.09,
        neighborhood: 'Clinton Hill',
        keywords: 'Upscale, Quaint, Seasonal, Casual, Traditional, Daiquiri, Vibrant, Great',
        annual_dol: 1063.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.9881,
        total_orders: 9929.24,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Korean, Vegetarian, Upscale, Stylish, Spicy, Delicious, Wine, Warm, Bbq, Friendly',
        annual_dol: 11213.37,
        description:
            "Upscale, stylish Korean eatery on skyscraper's 39th floor offers panoramic views & tabletop BBQ.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7309,
        store_longitude: -74.0041,
        total_orders: 622.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesecake, Pizza, Italian, Wine, Stylish, Delicious, Rustic, Authentic, Fresh, Casual',
        annual_dol: 635.49,
        description:
            'Naples-influenced fare & wines are served in a stylish, white-tiled room with light-wood furniture.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.6622,
        total_orders: 50.32,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Burritos, Salad, Trendy, French, Casual, Local, High-Protein, Fresh',
        annual_dol: 205.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8089,
        store_longitude: -73.8935,
        total_orders: 523.57,
        neighborhood: 'Hunts Point/West Bronx',
        keywords: 'Amazing, Perfect, Hip',
        annual_dol: 2140.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6658,
        store_longitude: -73.9823,
        total_orders: 167.27,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Pizza, Sushi, Beer, Wine, Coffee, Dessert, Spanish, Gin, Japanese',
        annual_dol: 719.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6674,
        store_longitude: -73.7982,
        total_orders: 163.18,
        neighborhood: 'South Ozone Park/Jamaica/Rochdale',
        keywords: 'Hotel, Exceptional',
        annual_dol: 170.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.9545,
        total_orders: 17779.59,
        neighborhood: 'Greenpoint',
        keywords:
            'Sushi, Vegetarian, Salad, Wine, Tasty, Coffee, Japanese, Beer, Delicious, Scandinavian',
        annual_dol: 30926.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7089,
        store_longitude: -73.9356,
        total_orders: 2515.46,
        neighborhood: 'East Williamsburg',
        keywords:
            'Beer, Cuban, Italian, Venezuelan, Trendy, Asian, Mediterranean, French, Modern, Fusion',
        annual_dol: 3734.4,
        description:
            'Modern Cuban Asian fusion fare is served in a hip space with a living wall & coastal hues.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7694,
        store_longitude: -73.9632,
        total_orders: 313.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Italian, Wine, Sweet, Casual, High-End, Distinctive',
        annual_dol: 1180.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7265,
        store_longitude: -73.948,
        total_orders: 13.43,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Casual, Great',
        annual_dol: 24.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6738,
        store_longitude: -73.7304,
        total_orders: 49.72,
        neighborhood: 'Rosedale',
        keywords: 'Upscale, Elegant, Beer, Caribbean, Rooftop, Casual',
        annual_dol: 203.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7438,
        store_longitude: -73.9536,
        total_orders: 455.79,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Pizza, Beer, American, Fried Chicken, Delicious, Classy, Casual, Local, Awesome',
        annual_dol: 465.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7452,
        store_longitude: -73.9476,
        total_orders: 2842.99,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Whiskey, Beer, American, Elegant, Comfortable, Colorful, Dutch, Fresh, Intimate, Hip',
        annual_dol: 5367.13,
        description: '"Speakeasy in the heart of Long Island City, Queens."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7371,
        store_longitude: -73.8966,
        total_orders: 130.69,
        neighborhood: 'Flushing/Woodside',
        keywords:
            'Mexican, Beer, Fruity, Spicy, Chinese, American, Elegant, Asian, Friendly, Classy',
        annual_dol: 570.74,
        description:
            'Cold noodles, pepper beef & other Sichuan favorites in a chic venue serving fruity house cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6825,
        store_longitude: -73.9935,
        total_orders: 2638.5,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Beer, Coffee, Ale, Zombie, Great, Perfect, Wonderful, Terrific, American, Relaxed',
        annual_dol: 2694.48,
        description:
            'Relaxed corner watering hole offering a rotating variety of craft beers on tap & in bottles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.7802,
        total_orders: 597.57,
        neighborhood: 'Bayside/Auburndale',
        keywords:
            'Thai, Salad, Vegetarian, Chinese, Delicious, Traditional, Massaman Curry, Healthy, Pad Thai, Spacious',
        annual_dol: 887.14,
        description:
            'Classic Thai fare in a spacious dining room with refined, contemporary decor & a circular bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6859,
        store_longitude: -73.9082,
        total_orders: 739.22,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Warm, Wine, Inviting, Friendly, Jamaican, Homey, Great, Casual',
        annual_dol: 787.8,
        description:
            'Snug, homey neighborhood bar with a warm atmosphere offering cocktails & a variety of beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6182,
        store_longitude: -74.0337,
        total_orders: 881.3,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Upscale, Bourbon, Beer, Delicious, Apple Pie, Classy, Healthy, Japanese, Classic, Welcoming',
        annual_dol: 919.51,
        description: '"Bay Ridge\'s Original Speakeasy w/ over 62 Classic Mixology Cocktails"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9806,
        total_orders: 1467.31,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Steak, Wine, Italian, Vodka, Upscale, Martini, Hotel, Delicious, Shrimp Scampi, Romantic',
        annual_dol: 1884.17,
        description:
            'Old-school elegance, including table linens, sets the stage for Italian classics, cocktails & wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6926,
        store_longitude: -73.9886,
        total_orders: 5645.61,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Hotel, American, Iconic, Sleek, Amazing, Great, Relaxed',
        annual_dol: 5645.61,
        description:
            "Sleek rooms have flat-­screen TVs, Wi-Fi (fee) and desks, plus minifridges and coffeemakers; some add pull-out sofas and/or or city views. Suites offer living areas; some include balconies and/or kitchenettes. Club quarters provide access to a lounge with free breakfast and snacks.There's a relaxed American restaurant, a bar and a snack shop, as well as an indoor pool, a spa and a fitness center. Other amenities include 26 meeting rooms and a business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.642,
        store_longitude: -73.6414,
        total_orders: 450.76,
        neighborhood: '',
        keywords:
            'Steak, Burger, Salad, Cajun, Italian, French, Bourbon, French Onion Soup, Filet Mignon, American',
        annual_dol: 464.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6916,
        store_longitude: -73.988,
        total_orders: 1530.69,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Steak, Pizza, Vodka, Italian, Martini, American, Timeless, Classic, Traditional, Perfect',
        annual_dol: 2287.18,
        description:
            'Posh, historic restaurant with retro decor, serving oysters, steaks & chops, plus wines & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6995,
        store_longitude: -73.9922,
        total_orders: 740.16,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Wine, Salad, Burger, Coffee, Dessert, Ale, Cozy, American, Friendly, Local',
        annual_dol: 772.25,
        description:
            'A diverse by-the-glass & bottle list complements the American menu at this low-key spot',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6538,
        store_longitude: -73.6275,
        total_orders: 1484.5,
        neighborhood: '',
        keywords:
            'Steak, Garlic Bread, Salad, Cheesesteak, Italian, Wine, French, Russian, Philly Cheesesteak, American',
        annual_dol: 1548.87,
        description: '"Restaurant/Sports Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.9821,
        total_orders: 1702.48,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Salad, Wine, Shrimp Cocktail, Rustic, American, French, Stylish, Hotel, Trendy',
        annual_dol: 1738.6,
        description:
            'A diverse American menu & craft cocktails in a sleek space with communal tables & a long bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 10962.25,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Zombie, Salad, Beer, Dessert, Margarita, Wine, Irish, Fun, Friendly',
        annual_dol: 11194.85,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7792,
        store_longitude: -73.9834,
        total_orders: 1514.14,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Burger, Salad, Meatloaf, Coffee, Wine, Beer, Irish, Ale, Caesar Salad, French',
        annual_dol: 1550.41,
        description:
            'Unassuming Irish-style pub offering a traditional range of ales, spirits & homestyle plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.752,
        store_longitude: -74.0012,
        total_orders: 925.54,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Vegan, Pizza, Cheesesteak, Salad, Juicy, Rustic, Greek, Casual, Fresh',
        annual_dol: 945.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8463,
        store_longitude: -73.7863,
        total_orders: 147.05,
        neighborhood: 'City Island',
        keywords:
            'Salad, Lobster, Shrimp Cocktail, Canadian, Wine, Bourbon, Tasty, Organic, Caesar Salad, Latin',
        annual_dol: 156.49,
        description:
            'Restaurant with homestyle New American fare in a space with offbeat nautical decor & a back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7351,
        store_longitude: -73.9934,
        total_orders: 306.58,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Wine, Salad, Italian, Premium, Lively, Awesome, Mediterranean, Distilled, Global',
        annual_dol: 349.17,
        description:
            'Wine bar featuring unusual vintages paired with small plates, plus classes & tastings too.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -73.9923,
        total_orders: 120.44,
        neighborhood: 'NoHo',
        keywords:
            'Salad, Tasty, Upscale, Italian, Cuban, Glamorous, Asian, Vietnamese, Thai, Innovative',
        annual_dol: 168.73,
        description:
            'Upscale Asian-inspired Cuban food is served along with handcrafted cocktails in sumptuous surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7953,
        store_longitude: -73.9697,
        total_orders: 9828.97,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Sushi, Gluten Free, Tasty, Japanese, Spicy, Trendy, Cozy, Intimate, Fun, Katsu Curry',
        annual_dol: 11445.52,
        description:
            'Lively haunt covered in stickers & street art serving Japanese comfort food, sake & shochu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.593,
        store_longitude: -74.0707,
        total_orders: 143.98,
        neighborhood: 'Arrochar',
        keywords:
            'Elegant, Serene, Impeccable, Gracious, Warm, Perfect, Comfortable, Wonderful, Great',
        annual_dol: 588.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8253,
        store_longitude: -73.9509,
        total_orders: 300.01,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Beer, Vegan, Vodka, French Toast, Avocado Toast, Ale, French, Italian, Trendy',
        annual_dol: 670.07,
        description:
            'Chicken dishes served in a country-chic restaurant with a covered terrace & happy-hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9684,
        total_orders: 1280.73,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Spanish, Dessert, Beer, Delicious, Creamy, Upscale, Elegant, Sangria, Contemporary',
        annual_dol: 1307.91,
        description:
            'Paella takes center stage on the menu of this Spanish eatery that also serves tapas & wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7543,
        store_longitude: -73.9687,
        total_orders: 656.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Burger, Tasty, Wine, French, Beer, Delicious, Cozy, Quaint, American',
        annual_dol: 685.87,
        description:
            'Cozy French eatery offering brunch staples, escargot, seafood & meat plates, plus a large wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6195,
        store_longitude: -74.0281,
        total_orders: 1557.39,
        neighborhood: 'Fort Hamilton',
        keywords: 'Beer, American, Gourmet, Lively, Awesome, Great, Sangria, Casual',
        annual_dol: 5532.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9899,
        total_orders: 548.59,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Gourmet, Italian, Casual, American, Glamorous, Friendly',
        annual_dol: 560.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7097,
        store_longitude: -74.0146,
        total_orders: 361.74,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Burger, Relaxed, Clean, Modern, Authentic, Urban, Friendly, Iconic, Great',
        annual_dol: 425.55,
        description:
            "The relaxed rooms provide flat-screens, Wi-Fi (fee) and iPod docks, plus work desks, minifridges and coffeemakers. Upgraded rooms add river views; some offer access to a club lounge with free breakfast and appetizers. Suites add separate sitting areas and pull-out sofas.Amenities include a burger joint and a laid-back bar. There's also a fitness room and a business center, along with 12,000 sq ft of meeting space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7768,
        store_longitude: -73.9778,
        total_orders: 1266.14,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Wine, Burger, Cheesesteak, Coffee, Beer, Italian, American, Cozy, Rum, Spanish',
        annual_dol: 1297.24,
        description:
            'Sophisticated yet casual, comfy locale offering wines by the glass & tapas-style Italian bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7743,
        store_longitude: -73.9798,
        total_orders: 898.13,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lasagna, Vegan, Vegetarian, Pizza, Wine, Vodka, Salad, Italian, Dessert, Martini',
        annual_dol: 937.06,
        description:
            "Homey Italian-American trattoria owned by Lady Gaga's parents & cookbook author Art Smith.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -74.0011,
        total_orders: 1366.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Italian, Wine, Salad, Romantic, Classy, Filet Mignon, Homemade, Friendly, Traditional, Casual',
        annual_dol: 1395.09,
        description:
            'Longtime Italian joint serving up traditional red-sauce standards in an old-school setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7657,
        store_longitude: -73.9875,
        total_orders: 559.05,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tacos, Whiskey, Beer, Mexican, Salad, Rum, Fireball Whiskey, Friendly, Classy, Premium',
        annual_dol: 738.78,
        description:
            'Intimate, brick-walled hangout with a bar menu, pool table, karaoke, theme parties & trivia nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7779,
        store_longitude: -73.9804,
        total_orders: 3374.8,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Japanese, Scotch, Spicy, Bourbon, Spanish, Delicious, Cozy, American, Modern',
        annual_dol: 6139.35,
        description:
            'Modern spot with a pool table for tapas & raw oysters, plus clever cocktails, many whiskeys & sake.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.9899,
        total_orders: 277.84,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Italian, Beer, Thai, Cozy, American, Casual, Great',
        annual_dol: 1135.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9826,
        total_orders: 2927.99,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Salad, Dessert, Caesar Salad, Spicy, Elegant, Classic, Heritage, Fresh, Fine',
        annual_dol: 3012.86,
        description:
            'Outpost of upmarket steakhouse chain known for sizzling, butter-topped beef in an elegant setting',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8067,
        store_longitude: -73.9647,
        total_orders: 164.32,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Sushi, Pizza, Coffee, Cozy, Bright, Fresh, Legendary, Fantastic, Diverse',
        annual_dol: 182.82,
        description:
            "Alfred Lerner Hall is the student center or students' union of Columbia University. It is named for Al Lerner, who financed part of its construction.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9803,
        total_orders: 17.81,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Beer, Wine, American, Warm, Seasonal, Rooftop, Local, Casual, Chic, Great',
        annual_dol: 72.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8789,
        store_longitude: -73.8432,
        total_orders: 77.67,
        neighborhood: 'East Bronx/Laconia',
        keywords:
            'Tasty, Nutritious, African, Delicious, Nigerian, Ghanaian, Friendly, Fresh, Casual, Great',
        annual_dol: 317.48,
        description:
            '"Fresh and bursting African food with flavor is what EazyLife Restaurant & Lounge has to offer. Serving the Bronx, New York area, we provide great-tasting African, Nigerian & Ghanaian food with the highest quality ingredients. Treat yourself today with a nutritious meal from our delicious menu. At EazyLife African Restaurant & Lounge, you are sure to enjoy the great atmosphere, friendly service, and affordable prices."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.8045,
        total_orders: 81.85,
        neighborhood: 'Flushing/East Flushing',
        keywords:
            'Beer, Sweet, Friendly, Awesome, Colorful, Wonderful, Great, Cool, American, Excellent',
        annual_dol: 191.1,
        description: '"Quaint neighborhood bar. Pleasant staff and wonderful customers!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8649,
        store_longitude: -73.9271,
        total_orders: 5.4,
        neighborhood: 'Fort George',
        keywords:
            'Salad, Shrimp Cocktail, Dominican, Mediterranean, Spanish, Latin, American, Sweet, Friendly, Casual',
        annual_dol: 22.08,
        description: '"Available for take out and outdoor dining."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7456,
        store_longitude: -73.9985,
        total_orders: 1095.76,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Salad, Tequila, Bbq, Margarita, Beer, Californian, Pinacolada, Caesar Salad, French',
        annual_dol: 1119.01,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9989,
        total_orders: 433.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Mexican, Italian, Korean, Japanese, Asian, American, Hotel, Stylish, Casual',
        annual_dol: 1907.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7333,
        store_longitude: -73.9983,
        total_orders: 94.76,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Salad, Chicken Parmigiana, Italian, French Toast, Avocado Toast, French, Legendary, Perfect, Spectacular',
        annual_dol: 221.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7131,
        store_longitude: -73.9415,
        total_orders: 1226.83,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Burger, Whiskey, Fruity, Ale, Elegant, Spacious, Irish, Rustic, Awesome',
        annual_dol: 1271.43,
        description:
            'Rustic-chic haunt with hardwood floors & tin ceilings offers New York craft beer & choice pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.7285,
        total_orders: 190.55,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Italian, Golfclub, Elegant, Mediterranean, Picturesque, Gracious, Casual, Unique',
        annual_dol: 310.72,
        description:
            'Wedding & other special events in an 1866 mansion on an elegant estate by a golf course.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.588,
        store_longitude: -74.1903,
        total_orders: 106.29,
        neighborhood: 'Travis - Chelsea/Mid Island',
        keywords:
            'Steak, Hotel, Italian, Beer, Gourmet, American, Spacious, Casual, Friendly, Colorful',
        annual_dol: 162.82,
        description:
            "Island Zoo.Relaxed, colorful rooms, some with whirlpool tubs, feature free Wi-Fi and flat-screen TVs. Suites add separate living rooms.Complimentary amenities include parking, a breakfast buffet and a ferry terminal shuttle. There's also a casual restaurant/bar, a convenience store and a gym. Coin-operated laundry facilities are available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7093,
        store_longitude: -74.0094,
        total_orders: 30.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Pizza, Italian, Martini, Rustic, Delicious, Hotel, Clean, Picturesque',
        annual_dol: 125.14,
        description:
            'Craft beer, classic cocktails & American fare served in a swanky, industrial-chic tavern.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7114,
        store_longitude: -73.9478,
        total_orders: 1503.06,
        neighborhood: 'Williamsburg',
        keywords: 'Whiskey, Scotch, Wine, Beer, Bourbon, Rustic, Gourmet, Trendy, Upscale, Irish',
        annual_dol: 1743.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -73.9893,
        total_orders: 1051.2,
        neighborhood: 'Lower East Side',
        keywords:
            'Lasagna, Pizza, Vegetarian, Italian, Vodka, Wine, Flavorful, Meatball, Tasty, Rustic',
        annual_dol: 1982.8,
        description:
            'Red gravy & red meat are the focus at this rustic Southern Italian restaurant with butcher shop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8497,
        store_longitude: -73.917,
        total_orders: 27.1,
        neighborhood: 'Morris Heights/West Bronx',
        keywords: 'Cuban, Dominican, Spanish, Italian, American, Latin, Gyro',
        annual_dol: 110.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.735,
        store_longitude: -73.5974,
        total_orders: 2714.55,
        neighborhood: '',
        keywords:
            'Cuban, Beer, Coffee, American, Exotic, Traditional, Fun, Mouth-Watering, Sangria, Awesome',
        annual_dol: 3045.61,
        description:
            'Upbeat place offering traditional Cuban cuisine & drinks, plus live music & dancing on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7909,
        store_longitude: -73.9742,
        total_orders: 394.13,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Kebab, Beer, Spicy, Wine, Warm, Lebanese, Pungent, Mediterranean, Israeli, Moroccan',
        annual_dol: 957.02,
        description:
            'Elevated Mediterranean dishes are matched by wine & craft cocktails at this upmarket restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7879,
        store_longitude: -73.971,
        total_orders: 1771.3,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Mexican, Beer, Wine, Cuban, Delicious, Gin, Italian, Bbq, Swiss, Urban',
        annual_dol: 1808.88,
        description:
            'Urban sports pub offering Latin-inspired small plates & weekend brunch along with sangria & mojitos.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.786,
        store_longitude: -73.8372,
        total_orders: 5.21,
        neighborhood: 'College Point/Flushing',
        keywords:
            'Burger, Pizza, Salad, Beer, Delicious, Bbq, Mediterranean, Homemade, Greek, Clean',
        annual_dol: 21.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6602,
        store_longitude: -73.5419,
        total_orders: 144.45,
        neighborhood: '',
        keywords: 'Wine, Bourbon, Tasty, Ale, Italian, American, Vintage, Perfect, Great, Fresh',
        annual_dol: 272.12,
        description:
            'Wine flights, cocktails & light plates offered in intimate, modern surrounds with a loungey feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.673,
        store_longitude: -73.9833,
        total_orders: 3487.25,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Lobster Bisque, Bbq, Relaxed, Comfortable, Casual, Contemporary, Friendly, Seasonal, Classy',
        annual_dol: 3562.73,
        description:
            'Relaxed New American restaurant with a commitment to using seasonal, local ingredients.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6631,
        store_longitude: -73.9913,
        total_orders: 1465.76,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Milkshake, Sushi, Mexican, Salad, Enchiladas, Fajitas, Healthy, Casual, Colorful, Traditional',
        annual_dol: 1523.44,
        description:
            'Popular spot offering homestyle Mexican plates & margaritas in compact, colorful quarters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7071,
        store_longitude: -73.9325,
        total_orders: 740.45,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Wine, Fun, Ale, Cozy, Awesome, Great, Fine, Pulled Pork, Casual',
        annual_dol: 1251.13,
        description:
            '"Outdoor and indoor seating safely distanced with room for dancing,too!! Come on by and say hello. Grab a beer/shot/cocktail or one of our famous pulled pork sandwiches We’d love to see you!!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9565,
        total_orders: 1110.49,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Rich, Mulled Wine, Cozy, Gracious, Superb, Great, Tradition, Contemporary, Unforgettable',
        annual_dol: 1134.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.731,
        store_longitude: -73.9576,
        total_orders: 2074.18,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Steak, Tacos, Salad, Beer, Margarita, Mimosa, Vodka, Tasty, French',
        annual_dol: 2664.07,
        description:
            'Relaxed watering hole with classic interiors, fixing up small & large plates, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7662,
        store_longitude: -73.956,
        total_orders: 4582.95,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Wine, Whiskey, Beer, Cozy, Delicious, Gin, Karaoke, Intimate, Premium, Fun',
        annual_dol: 4680.19,
        description:
            'Intimate, wood-lined bar for craft cocktails, beer & whiskies, plus light snacks & weekly karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.9363,
        total_orders: 66.85,
        neighborhood: 'East Williamsburg',
        keywords: 'Tasty, Friendly, Asian, Cool',
        annual_dol: 273.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7037,
        store_longitude: -73.9905,
        total_orders: 143.61,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Steak, Salad, Wine, Bourbon, Beer, Avocado Toast, Italian, Fruity, Turkish, Organic',
        annual_dol: 234.18,
        description:
            'Bi-level restaurant executing a contemporary French menu in industrial-chic digs with a plant wall.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7646,
        store_longitude: -73.9781,
        total_orders: 7232.51,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Trendy, Stylish, American, Rooftop, Iconic, Premium, Vintage, Great, Perfect',
        annual_dol: 7546.09,
        description:
            "The chic rooms include free Wi-Fi, espresso machines and 40-inch flat-screen TVs, plus high-end linens and bathrooms with walk-in showers. Suites add sitting areas, workstations and floor-to-ceiling windows with city views.Amenities include an open-air rooftop lounge offering small plates and views of Central Park, along with a trendy street-level restaurant/bar serving New American cuisine and craft cocktails. There's also a 24-hour fitness room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9871,
        total_orders: 1618.76,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Tasty, Korean, Beer, Spicy, Pizza, Savory, Margarita, Vodka, Wine, Upscale',
        annual_dol: 1653.11,
        description:
            'Upscale creative Korean small plates, cocktails & an emphasis on organic/sustainable ingredients.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7196,
        store_longitude: -73.9872,
        total_orders: 2390.3,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Upscale, American, Classy, Classic, Casual, Local, Chill, Unpretentious, Great',
        annual_dol: 2493.93,
        description:
            'Unpretentious bar with beer from a fridge, a jukebox & a 1970s wood-paneled-basement vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8457,
        store_longitude: -73.9218,
        total_orders: 6.09,
        neighborhood: 'Morris Heights/West Bronx',
        keywords: '',
        annual_dol: 24.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7172,
        store_longitude: -73.9903,
        total_orders: 17.41,
        neighborhood: 'Lower East Side',
        keywords: 'Casual, Unique, Impressive',
        annual_dol: 71.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7442,
        store_longitude: -74.0028,
        total_orders: 1450.24,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Steak, Wine, Vodka, Stylish, American, Cozy, Lively, Whimsical, Radiant',
        annual_dol: 1481.01,
        description:
            'Chill eatery serving brunch staples & local fare with a European twist, plus craft beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.9855,
        total_orders: 1174.27,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Spicy, Chinese, Salad, Stylish, Asian, Sweet, Thai, Hip, Casual, Local',
        annual_dol: 2657.8,
        description:
            'Cozy, hip spot featuring Chinese dry pot with a choice of spice level, plus appetizers & dim sum.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6831,
        store_longitude: -73.9656,
        total_orders: 2099.96,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Vegetarian, Pizza, Salad, Coffee, Organic, Fried Chicken, Mediterranean, American, Cozy',
        annual_dol: 2512.54,
        description:
            'Cozy restaurant with a dining room & a covered sidewalk area focusing on local & organic produce.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6573,
        store_longitude: -73.6475,
        total_orders: 14673.04,
        neighborhood: '',
        keywords:
            'Cajun, Tasty, Jambalaya, Mexican, Italian, Juicy, Fried Clams, Fried Rice, Fun, Asian',
        annual_dol: 21817.52,
        description:
            'Laid-back seafood restaurant offering seafood specialties, a raw bar & Cajun boils, plus steaks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7431,
        store_longitude: -73.6179,
        total_orders: 337.5,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Meatball, Tequila, Margarita, Mediterranean, Caesar Salad, Delicious, Zesty',
        annual_dol: 352.13,
        description:
            'Casual chain serving charred-crust pies, plus wings, subs & Italian plates in a modern setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5787,
        store_longitude: -73.8497,
        total_orders: 112.17,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords:
            'Burger, Salad, Nachos, Wine, Irish, English Muffin, American, Classy, Awesome, Excellent',
        annual_dol: 127.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6431,
        store_longitude: -73.6657,
        total_orders: 375.32,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Mexican, Salad, Pizza, Beer, Peruvian, Colombian, Fajitas',
        annual_dol: 735.84,
        description:
            '"Del sur specializes in making our customers enjoy many different tastes of different countries of South America while also enjoying great Cocktails. Come and visit us for a different feel of latin food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.847,
        total_orders: 112614.72,
        neighborhood: 'Flushing/Corona',
        keywords:
            'Burger, Salad, Shrimp Cocktail, Spanish, Hotel, American, Picturesque, Sweet, Casual, Traditional',
        annual_dol: 140866.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7515,
        store_longitude: -73.9837,
        total_orders: 2177.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Trendy, Stylish, Martini, Tequila, Italian, Premium, Sleek, Gin, Sophisticated',
        annual_dol: 2667.59,
        description:
            "Featuring mahogany furnishings and safari-inspired decor, the chic rooms come with Wi-Fi access and smart TVs, as well as minibars, Keurig coffeemakers and safes. Room service is available.Amenities include a sophisticated restaurant, and a swanky rooftop area with 2 bars. There's also a 24-hour gym. Valet parking is offered for a fee.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.9844,
        total_orders: 250.31,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Chinese, Salad, Cuban, Wine, Beer, Avocado Toast, Swiss, Asian, American, Fusion',
        annual_dol: 257.11,
        description:
            'Cuban-Chinese fare & intricate cocktails in a space with whitewashed walls & distressed wood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8495,
        store_longitude: -73.7872,
        total_orders: 3455.25,
        neighborhood: 'City Island',
        keywords:
            'Steak, Lobster, Lobster Roll, Chicken Parmigiana, Chilean, Italian, Jambalaya, American, Delicious, Polished',
        annual_dol: 3648.28,
        description:
            "This veteran, laid-back local eatery provides old-school surf 'n' turf fare with Italian accents.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.9401,
        total_orders: 11187.71,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Sushi, Salad, Vegetarian, Wine, Japanese, Spicy, Ale, Trendy, Sweet, Greek',
        annual_dol: 11514.03,
        description:
            'Small, trendy Japanese place offering modern dishes & sushi in a lively atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7686,
        store_longitude: -73.8676,
        total_orders: 3992.02,
        neighborhood: 'Astoria/East Elmhurst/Flushing',
        keywords: 'Hotel, American, Stylish, Premium, Excellent, Terrific, Modern',
        annual_dol: 4011.78,
        description:
            'Modern rooms offer Wi-Fi (fee) and flat-screen TVs with premium channels, plus desks and coffeemakers. Some offer airport views, and upgraded rooms have access to a lounge with free hot breakfast and evening appetizers. Suites add separate living areas with sofas.Complimentary amenities include an airport shuttle and Wi-Fi in the lobby. The property offers 2 American restaurants and a 24/7 gym, as well as 26 meeting rooms and a business center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7369,
        store_longitude: -73.9876,
        total_orders: 4905.97,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Asian, Modern, Exotic, Indian, Colorful, Vibrant, Local, Chic, Unique, Fantastic',
        annual_dol: 5336.39,
        description:
            'Updated Indian cuisine & signature cocktails are offered in an exotic setting with dangling lights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -73.9913,
        total_orders: 633.74,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Amazing, Fantastic, Great, Perfect, Intimate',
        annual_dol: 738.7,
        description:
            'The Daryl Roth Theatre is an off-Broadway performance space at 101 East 15th Street, at the northeast corner of the intersection with Union Square East, near Union Square, Manhattan, New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7393,
        store_longitude: -73.885,
        total_orders: 202.28,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Perfect, Spectacular, Unforgettable, Unique',
        annual_dol: 826.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7265,
        store_longitude: -73.9868,
        total_orders: 88.08,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegetarian, Chicken Tikka Masala, Spicy, Delicious, American, Healthy, Premium, Clean, Authentic, Fresh',
        annual_dol: 360.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8009,
        store_longitude: -73.9663,
        total_orders: 3730.42,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Mexican, Pizza, Wine, Gin, Urban, Modern, Casual, Ethiopian',
        annual_dol: 7879.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8029,
        store_longitude: -73.9642,
        total_orders: 118.43,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Burger, Flavorful, Wine, Hawaiian, Spicy, Whiskey, Meatball, Elegant, Mediterranean, Cozy',
        annual_dol: 152.71,
        description:
            'Homey haunt for Mediterranean-style small plates & mains paired with a global wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8729,
        store_longitude: -73.8783,
        total_orders: 12.27,
        neighborhood: 'Norwood/West Bronx',
        keywords:
            'Tacos, Mexican, Steak, Margarita, Dominican, American, Spicy, Delicious, Local, Casual',
        annual_dol: 50.16,
        description: '"Mexican/Dominican restaurant bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.7711,
        total_orders: 502.54,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Pizza, Hamburger, Gluten Free, Italian, Beer, Bbq, Belgian, American, Fun',
        annual_dol: 524.33,
        description:
            'A variety of sandwiches & burgers can be paired with sides like fries & salads for a pub-style meal.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.9797,
        total_orders: 1371.63,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Upscale, Urban, Perfect, Fine, Local',
        annual_dol: 1431.1,
        description:
            'Upscale quarters with Ivy League styling, plus a health club, available for members & their guests.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7521,
        store_longitude: -73.9781,
        total_orders: 1228.99,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Cheesecake, Hamburger, Tasty, Wine, Champagne, Coffee, Delicious, Upscale, New-York-Style, Belgian',
        annual_dol: 1255.06,
        description:
            'Located across from Grand Central Terminal, this American bistro bustles day & night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.6604,
        total_orders: 260.9,
        neighborhood: '',
        keywords: 'Perfect',
        annual_dol: 1119.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7598,
        store_longitude: -73.969,
        total_orders: 4867.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Japanese, Meatball, Delicious, Tranquil, Casual, Fancy, Authentic, Recommended, Incredible',
        annual_dol: 6637.04,
        description:
            'Laid-back Japanese eatery offering yakitori & kushikatsu dishes in a tranquil dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7572,
        store_longitude: -73.9677,
        total_orders: 860.88,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Chinese, Peking Duck, Ale, Spicy, Asian, Contemporary, Hotel, American, Sweet',
        annual_dol: 3518.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9669,
        total_orders: 681.2,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Salad, Wine, Italian, Dessert, Hotel, Exquisite, Fun, Perfect, Classic',
        annual_dol: 695.66,
        description:
            'Dinner & Sunday brunch, drinks & a drag show for birthdays, bachelorettes or a festive night out.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9643,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Champagne, Hotel, Wine, Luxurious, Coffee, Cozy, Chic, Fantastic, Perfect, Wonderful',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7857,
        store_longitude: -73.728,
        total_orders: 878.24,
        neighborhood: '',
        keywords:
            'Japanese, Sushi, Innovative, Chinese, Thai, Asian, Polished, Fresh, Modern, Great',
        annual_dol: 921.88,
        description:
            'Pan-Asian eatery offering Chinese, Southeast Asian & fusion dishes in an atmospheric space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7405,
        store_longitude: -73.9847,
        total_orders: 573.69,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Eclectic, Coffee, Fun',
        annual_dol: 2014,
        description:
            'The Peoples Improv Theater, also known as the PIT, is a comedy theater and training center in New York City, founded by comedian Ali Farahnakian in 2002. Shows combine improvisational comedy, sketch comedy, stand-up, theater, and variety.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7874,
        store_longitude: -73.7285,
        total_orders: 339.73,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Vegetarian, Hamburger, Coffee, Organic, Beer, Delicious, Sustainable, American',
        annual_dol: 536.38,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7779,
        store_longitude: -73.9484,
        total_orders: 537.82,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Tasty, Wine, Martini, Delicious, Mexican, Fun, Italian, Hotel, Lively',
        annual_dol: 586.14,
        description:
            'Lively, brick-walled pub pairing draft beer with hearty fare, plus brunch, happy hours & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6912,
        store_longitude: -73.9598,
        total_orders: 167.34,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Korean, Coffee, Dessert, Japanese, Spicy, Chinese, Asian, Bbq, Modern, Bibimbap',
        annual_dol: 205.32,
        description:
            'Sleek, modern coffee shop upstairs & trendy, lounge-like Korean eatery downstairs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7284,
        store_longitude: -73.9994,
        total_orders: 3879.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Tasty, Salad, Beer, Wine, Martini, Delicious, German, Nachos, Warm',
        annual_dol: 3968.51,
        description:
            'Rock & blues bands perform nightly while soccer games on satellite TV hold forth at other times.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -73.9992,
        total_orders: 2349.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lobster, Beer, Tasty, Ale, Spicy, Upscale, Funky, Rustic, American, Sustainable',
        annual_dol: 2517.35,
        description:
            'Rustic-chic, casual hangout known for American craft beers & food served amid a funky tavern vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.9904,
        total_orders: 55.48,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Wine, Salad, Beer, Spicy, Fun, Perfect, Great, Creative, Sangria, Casual',
        annual_dol: 181.14,
        description: '"Billiards, pool hall, eatery and sports bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.779,
        store_longitude: -73.9829,
        total_orders: 1019.18,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Beer, Ale, Wine, Burger, Premium, American, Irish, Great, Natural',
        annual_dol: 1086.15,
        description:
            'Cavernous, low-key taproom known for its wide variety of craft beers, plus burgers & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9801,
        total_orders: 214.75,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Wine, Beer, Upscale, Hotel, Caesar Salad, American, Casual, Urban',
        annual_dol: 877.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7776,
        store_longitude: -73.9789,
        total_orders: 152.32,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Wine, Irish, Classic, Iconic, Natural, Great, Friendly, Casual',
        annual_dol: 155.55,
        description:
            'This unassuming Irish watering hole offers pints & pub staples like burgers & wings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.565,
        store_longitude: -74.1271,
        total_orders: 301.26,
        neighborhood: 'Richmond',
        keywords:
            'Pizza, Italian, Coffee, Wine, Dessert, Tasty, German, American, French, Eclectic',
        annual_dol: 314.32,
        description:
            'Family-owned neighborhood hub featuring homestyle American fare, including pizza, seafood & steaks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.6138,
        total_orders: 4776.14,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Salad, Hamburger, Shrimp Cocktail, Dessert, Shrimp Scampi, Italian, Roast Beef, Elegant',
        annual_dol: 4933.75,
        description:
            'This Victorian manor acts as a venue for weddings & events & also houses The Polo Lounge restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7883,
        store_longitude: -73.9485,
        total_orders: 74.21,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Wine, Beer, Gluten Free, Vegetarian, Trendy, Belgian, Dessert, Delicious, Cozy, Brazilian',
        annual_dol: 259.32,
        description: '"Bar Goyana - Brazilian Vibes, Belgian Beers & Cocktails"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9205,
        total_orders: 284.99,
        neighborhood: 'Bushwick',
        keywords: 'Spacious, Japanese, Beer, Amazing, Great, Cool, Hip, Casual',
        annual_dol: 340.44,
        description:
            '"Movie theater from the early 1900s, now a spacious bar with a second level mezzanine and 70s vibes. Pool tables, cheap beers, shots and cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -74.1041,
        total_orders: 3025.87,
        neighborhood: 'Todt Hill',
        keywords:
            'Wine, Upscale, Rich, Golfclub, Perfect, Traditional, Impressive, Fresh, Contemporary, Fine',
        annual_dol: 3171.24,
        description:
            'Richmond County Country Club is a private country club in Staten Island, New York. The club includes a golf course, tennis courts, an aquatic facility, and two clubhouses. The golf course and the main clubhouse have impressive views of New York Harbor, the Atlantic Ocean, and the New Jersey highlands.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7187,
        store_longitude: -73.9974,
        total_orders: 293.85,
        neighborhood: 'Little Italy',
        keywords:
            'Garlic Bread, Cheesecake, Lobster, Tasty, Wine, Tequila, Pizza, Vodka, Beer, Martini',
        annual_dol: 390.06,
        description:
            'Comfortable option featuring housemade pasta, plus shared plates, pizza & other Italian classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7237,
        store_longitude: -74.0044,
        total_orders: 9482.94,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Pizza, Dessert, Bourbon, Premium, Latin, Outstanding, Intimate, Filet Mignon, Great, American',
        annual_dol: 10280.66,
        description:
            'Candlelit art deco bar draws smokers for its variety of cigars & cigarettes, cocktails & snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7777,
        store_longitude: -73.9546,
        total_orders: 90.3,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Mexican, Tacos, Salad, Burritos, Organic, Delicious, Local, Casual, Great',
        annual_dol: 430.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7364,
        store_longitude: -74.0061,
        total_orders: 528.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Salad, Dessert, Mediterranean, Spicy, Organic, Rustic, Moroccan, Shawarma, Relaxed',
        annual_dol: 588.41,
        description:
            'Mediterranean tapas, Moroccan-style entrees & a full bar served in a cozy, relaxed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -74.0032,
        total_orders: 1364.43,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Coffee, Burger, Avocado Toast, Italian, Cozy, Mediterranean, French, Vintage, Indulgent',
        annual_dol: 1393.38,
        description:
            'Easygoing all-day cafe & bar offering classic & inventive eats, plus wines, beers & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7338,
        store_longitude: -74.0005,
        total_orders: 551.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, French, Wine, Delicious, Classy, Cozy, Romantic, Classic, Traditional',
        annual_dol: 577.34,
        description:
            'Parisian-style cafe serving comforting French fare in a snug space with an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6266,
        store_longitude: -74.1279,
        total_orders: 198.26,
        neighborhood: 'Port Richmond/Castleton Corners',
        keywords: 'American, Irish, Traditional, Local, Casual, Friendly',
        annual_dol: 225.81,
        description: '"Tavern"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7816,
        store_longitude: -73.9793,
        total_orders: 2011.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegetarian, Delicious, Stylish, Italian, Modern, American, Mediterranean, French, Fun, Fresh',
        annual_dol: 2053.79,
        description:
            'Diverse mix of modern Mediterranean dishes in a stylish brick-&-wood space with a 1st-floor bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8357,
        store_longitude: -73.7074,
        total_orders: 30.07,
        neighborhood: '',
        keywords: '',
        annual_dol: 113.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7494,
        store_longitude: -73.7078,
        total_orders: 648.58,
        neighborhood: 'Glen Oaks',
        keywords: 'Steak, Burger, Vodka, Wine, Beer, French, American, Comfortable, Casual, Classy',
        annual_dol: 686.17,
        description:
            'Classy bar & grill serving American comfort fare & cocktails in a relaxed, wood-trimmed space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6606,
        store_longitude: -73.9904,
        total_orders: 1049.09,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Salad, Dessert, Wine, Beer, Juicy, Vietnamese, Organic, Delicious, Asian',
        annual_dol: 1143.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6647,
        store_longitude: -73.9898,
        total_orders: 1922.04,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Beer, Wine, Italian, Organic, Irish, Relaxed, Vintage, Friendly, Fun, American',
        annual_dol: 1962.82,
        description:
            'Relaxed taproom featuring vintage video games, pool & board games, plus a jukebox with classic rock.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6697,
        store_longitude: -73.9861,
        total_orders: 36.09,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Salad, Crab Cakes, Coffee, Seafood Paella, Roast Beef, French Toast, Beer, Ale, Swiss',
        annual_dol: 147.53,
        description:
            'Diner with typical genre features like an extensive menu, free coffee refills & 24/7 availability.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6361,
        store_longitude: -73.74,
        total_orders: 1101.94,
        neighborhood: 'Jamaica/Rosedale',
        keywords: 'Steak, Salad, Bourbon, Thai, American, Casual, Classic, Local, Lively',
        annual_dol: 1273.74,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7108,
        store_longitude: -73.9366,
        total_orders: 251.28,
        neighborhood: 'East Williamsburg',
        keywords: 'Complex, Creative, Recommended, Casual',
        annual_dol: 498.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7744,
        store_longitude: -73.951,
        total_orders: 446.91,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Salad, Wine, Delicious, French, Cozy, Warm, Classy, Friendly, Welcoming',
        annual_dol: 1093.09,
        description:
            'Longtime Parisian-style bistro known for its classic French eats & grown-up ambiance.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7744,
        store_longitude: -73.8732,
        total_orders: 455.32,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'American, Golfclub, Premium, Local, Great, Signature',
        annual_dol: 1598.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7758,
        store_longitude: -73.9591,
        total_orders: 67.08,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Dessert, Wine, Delicious, Elegant, Memorable, Fine',
        annual_dol: 96.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6301,
        store_longitude: -74.1082,
        total_orders: 171.43,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Pizza, Italian, Salad, Beer, Delicious, Upscale, Bruschetta, Polished, Relaxed',
        annual_dol: 225.32,
        description:
            'Relaxed locale offering traditional pizza, salads, panini, pasta & Italian mains, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9123,
        total_orders: 31.81,
        neighborhood: 'Sunnyside/Woodside',
        keywords: 'Colombian, Casual, Classic, Lit, Recommended, Cultural, Great',
        annual_dol: 130.03,
        description:
            'Neon-lit nightspot with an upbeat vibe featuring music, salsa dancing & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7311,
        store_longitude: -73.6931,
        total_orders: 761.07,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Beer, Tasty, Wine, Bbq, Ale, Delicious, Trendy, Thai',
        annual_dol: 809.93,
        description: '"Bar/restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8918,
        store_longitude: -73.8975,
        total_orders: 1702.91,
        neighborhood: 'Fieldston/West Bronx',
        keywords:
            'Steak, Burger, Upscale, Flavorful, Dessert, American, Fine Quality, Classic, Perfect, Filet Mignon',
        annual_dol: 1951.17,
        description:
            'Easygoing operation with an upscale atmosphere serving classic grilled meat dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.9769,
        total_orders: 435.73,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Steak, Chocolate Mousse, Sushi, Dessert, French Onion Soup, French, Seasonal, Cozy, Casual',
        annual_dol: 454.62,
        description:
            "French onion soup stars on this longtime bistro's menu of quiche, crêpes & other classic fare.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7667,
        store_longitude: -73.9938,
        total_orders: 136.9,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Irish, Elegant, Wonderful, Fantastic, Contemporary, Cultural, Local',
        annual_dol: 581.96,
        description:
            'Institute showcasing Irish culture via musical & theatrical performances, films, exhibits & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9866,
        total_orders: 1352.71,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Wine, Gluten Free, Dessert, Italian, Delicious, Beer, Romantic, Classy, Friendly',
        annual_dol: 1422.79,
        description:
            'Creatively sauced house-made pastas & other Italian eats in a sleek space with garden seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7657,
        store_longitude: -73.9875,
        total_orders: 503.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Vegetarian, Vegan, Hamburger, Hotdog, Salad, Meatball, French Toast, Avocado Toast',
        annual_dol: 525.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7086,
        store_longitude: -73.8319,
        total_orders: 42.74,
        neighborhood: 'Kew Gardens/Jamaica',
        keywords: 'Pizza, Italian, Tasty, Beer, Ale, Peruvian, American, Classy, Lively, Caribbean',
        annual_dol: 174.69,
        description:
            '"Fine Italian cuisine, with a flavor of culture, a classy touch, and a lively vibe in Kew Gardens, New York. Instagram: @ckewgardens"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.8864,
        total_orders: 86.57,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Tequila, Mexican, Chinese, Italian, Casual, Fresh',
        annual_dol: 353.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6281,
        store_longitude: -73.5813,
        total_orders: 63.62,
        neighborhood: '',
        keywords: 'Modern, Fresh, Fine, Signature',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5889,
        store_longitude: -73.8121,
        total_orders: 2661.48,
        neighborhood: 'Hammels/Far Rockaway/Rockaway Beach',
        keywords: 'Burger, Tacos, Pizza, Sushi, Vegan, Mexican, Beer, Margarita, Quesadillas, Rum',
        annual_dol: 3530.98,
        description:
            'Chill, rustic joint accented with surfboards featuring tacos, quesadillas & cocktails, plus a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -74.0043,
        total_orders: 751.38,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Lobster, Lobster Roll, Shrimp Cocktail, Gluten Free, Coffee, Upscale, American, Trendy',
        annual_dol: 767.33,
        description:
            'Classic art deco diner relaunched with upgraded spins on American dishes, coffee & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9977,
        total_orders: 42.76,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Pizza, Salad, Korean, Donut, Spicy, Savory, Fried Chicken, Fried Rice, Asian',
        annual_dol: 174.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7879,
        store_longitude: -73.8126,
        total_orders: 436.62,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Pizza, Italian, Shrimp Cocktail, Bruschetta, American, Relaxed, Casual, Southern, Fresh, Local',
        annual_dol: 445.89,
        description:
            'Italian eatery featuring wood-fired pizzas & classic fare in a warm, relaxed atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.737,
        store_longitude: -73.9978,
        total_orders: 9.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Tequila, Shrimp Cocktail, Spanish, Salad, Wine, Martini, Daiquiri, Hotel, Upscale',
        annual_dol: 38.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6441,
        store_longitude: -74.0769,
        total_orders: 159.92,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Steak, Shrimp Cocktail, Wine, Delicious, Beer, Italian, Turkish, French, Premium, Spectacular',
        annual_dol: 201.35,
        description:
            'Classic chophouse with a long bar serving dry-aged steaks amid skyline murals & rooftop patio views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.984,
        total_orders: 637.9,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Salad, Kebab, Coffee, Dessert, Turkish, Mediterranean, Irish, American, Relaxed',
        annual_dol: 693.84,
        description:
            'Kebabs, grilled fish & other Mediterranean staples served in a relaxed pub with sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7483,
        store_longitude: -73.9735,
        total_orders: 2434.3,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Tasty, Wine, Beer, Dessert, Margarita, Comfortable, Spacious, Friendly, Bbq',
        annual_dol: 3682.61,
        description:
            'Brick walls set the scene for American grill fare & drinks, as does a homey, polished-plank garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7438,
        store_longitude: -73.986,
        total_orders: 3189.05,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Salad, Chilean, Canadian, Shrimp Scampi, Upscale, Juicy, American',
        annual_dol: 3268.61,
        description: 'Traditional steakhouse fare & Italian-accented dishes in an upscale setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7469,
        store_longitude: -73.9855,
        total_orders: 1759.63,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Korean, Bbq, Trendy, Upscale, Sleek, Hotel, Hip, Perfect, Vibrant, Casual',
        annual_dol: 6228.54,
        description: '"Modern Korean BBQ Restaurant in Manhattan, New York"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.9576,
        total_orders: 782.26,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Beer, Wine, Champagne, Awesome, Unpretentious, Great, Casual, Authentic',
        annual_dol: 816.17,
        description:
            'Buzzing, unpretentious watering hole offering drinks with free or $1 pizza, plus games & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7416,
        store_longitude: -73.9784,
        total_orders: 235.44,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Beer, Mexican, Wine, Irish, Ale, Fantastic, Traditional, Great, Casual',
        annual_dol: 444.66,
        description:
            'Traditional Irish pub for regular live Celtic music with Guinness & other rotating drafts on tap.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.717,
        store_longitude: -73.9634,
        total_orders: 9.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Coffee, Margarita, Mediterranean, Moroccan, Hot Toddy, American, Lively, Greek, Negroni',
        annual_dol: 39.99,
        description:
            '"Simple spot serving American fare with Middle Eastern influences plus classic cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7173,
        store_longitude: -73.8358,
        total_orders: 393.61,
        neighborhood: 'Elmhurst/Forest Hills/Flushing',
        keywords: 'Burger, Beer, Bbq, Cuban, American, Spacious, Global, Great, Casual',
        annual_dol: 961.46,
        description:
            'Spacious hangout serving American BBQ, dishes with global flavors & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7745,
        store_longitude: -73.8731,
        total_orders: 213.85,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Wine, Beer, Hotel, Golfclub, Warm, Premium, Classic, Superb, Global, Great',
        annual_dol: 294.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7406,
        store_longitude: -74.0083,
        total_orders: 15288.95,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Tomato Soup, Steak, Beer, Gluten Free, Wine, Vodka, Delicious, Trendy, Italian',
        annual_dol: 15618.03,
        description:
            'Unpretentious Meatpacking District pub with an extensive beer selection & a roof deck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8484,
        store_longitude: -73.8552,
        total_orders: 741.9,
        neighborhood: 'Morris Park',
        keywords:
            'Burger, Steak, Hamburger, Pizza, Cheesesteak, Beer, Shrimp Scampi, Cuban, American, Rum',
        annual_dol: 991.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7359,
        store_longitude: -74.0013,
        total_orders: 1293.39,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Salad, Tacos, Vegan, Peruvian, Wine, Vodka, Dessert, Mimosa, Pizza',
        annual_dol: 1418.27,
        description:
            'Bright, contemporary restaurant serving Latin American fusion dishes & signature drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7385,
        store_longitude: -74.0053,
        total_orders: 194.24,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Quaint, Hotel, Cool, Casual, Rooftop, Distinct',
        annual_dol: 272.12,
        description:
            '"A private hidden gem decorated with an astonishing collection of antiques and artifacts from all over the world. Available for weddings and rehearsal dinners, photo and film shoots, product launches, corporate events, as well as gatherings and celebrations of all kinds."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.6538,
        total_orders: 5.09,
        neighborhood: '',
        keywords:
            'Lobster, Wine, Vegetarian, Vodka, Delicious, Spanish, American, Sangria, Traditional, Casual',
        annual_dol: 20.81,
        description:
            'Warmly decorated eatery offering traditional Portuguese dishes, including lobster & grilled steak.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7805,
        store_longitude: -73.9764,
        total_orders: 357.22,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Italian, Meatball, Wine, Stylish, Hotel, Modern, Bright, Classic, Recommended',
        annual_dol: 500.44,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8414,
        store_longitude: -73.5933,
        total_orders: 1062.03,
        neighborhood: '',
        keywords: 'Golfclub, Bruschetta, Classic',
        annual_dol: 1177.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7059,
        store_longitude: -73.6584,
        total_orders: 15.75,
        neighborhood: '',
        keywords: 'Ramen, Sushi, Thai, Hawaiian, Japanese, Gyoza, Asian, Tempura, Local, Casual',
        annual_dol: 17.96,
        description: '"Ramen & Poke"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9985,
        total_orders: 42.62,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Innovative, Fun, Savory',
        annual_dol: 174.23,
        description:
            '"Scoozi Events NYC is a full-service innovative company that is rewriting the narrative of catering, all things events & engagement with the professionalism & creativity we have been known for... since 1996. Our cuisine is experiential, fun & globally inspired. We offer innovate solutions for catering, immersive events & virtual experiences! Our ambition & sole purpose of Scoozi Events is to create happiness, engagement & share culture! Choose Scoozi to plan the impossible and unimaginable!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6643,
        store_longitude: -73.9802,
        total_orders: 737.95,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Italian, Salad, Meatball, Wine, Dessert, American, Prosecco, Organic, Elegant',
        annual_dol: 1128.96,
        description:
            '"Artisanal pizza and cocktails in Park Slope, Brooklyn. One block away from Prospect Park with elegant indoor and outdoor dining options."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6748,
        store_longitude: -73.9758,
        total_orders: 277.87,
        neighborhood: 'Park Slope',
        keywords:
            'Salad, Flavorful, Thai, Coffee, Stylish, Beer, Delicious, Ramen, Homemade, Modern',
        annual_dol: 1135.84,
        description:
            'Modern takes on Thai classics presented in a quaint, stylish restaurant filled with indoor plants.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6858,
        store_longitude: -73.9388,
        total_orders: 1358.57,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Margarita, Vegetarian, Spanish, Warm, American, Intimate, Classic, Casual, Incredible, Distinct',
        annual_dol: 2542.94,
        description:
            '"Lucky’s is a warm & intimate neighborhood cocktail lounge that offers a distinct spirit program accompanied by full flavored tapas in Brooklyn, NY. Our vision is to enrich the lives we touch through genuine and detailed hospitality."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9584,
        total_orders: 1197.65,
        neighborhood: 'Greenpoint',
        keywords:
            'Pizza, Vegan, Italian, Apple Pie, Gourmet, Beer, Margarita, Ale, Wholesome, Cozy',
        annual_dol: 1249.58,
        description:
            'Gourmet pizzeria where the wood-fired pies are topped with unusual, locally sourced ingredients.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7706,
        store_longitude: -73.9598,
        total_orders: 1427.4,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Steak, Cornbread, Salad, Bbq, Tasty, Margarita, French, Homemade, Caesar Salad',
        annual_dol: 1457.69,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7697,
        store_longitude: -73.9579,
        total_orders: 181.69,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Wine, Dessert, Spicy, Sashimi, Japanese, Quaint, Fusion, Ramen, Teriyaki',
        annual_dol: 222.56,
        description:
            'Japanese restaurant with a tavern vibe & bar offering a mix of sushi, rolls & kitchen-cooked plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.9776,
        total_orders: 539.51,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lasagna, Salad, Italian, Wine, Delicious, Elegant, Warm, Martini, Polished, Succulent',
        annual_dol: 609.94,
        description:
            'Polished restaurant offering Italian cuisine, including pizza, pasta & classic desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -73.9868,
        total_orders: 3035.7,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Mexican, Shrimp Cocktail, Salad, Vodka, Champagne, Meatball, Spicy, Rustic, Hotel',
        annual_dol: 3380.05,
        description:
            'A pawn shop gives way to a happening restaurant & lounge serving creative American fare & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9023,
        store_longitude: -73.8515,
        total_orders: 50.55,
        neighborhood: 'East Bronx/Wakefield',
        keywords:
            'Lobster, Lobster Tail, Steak, Wine, Ribeye Steak, Caribbean, Indian, Casual, Classic, Local',
        annual_dol: 177.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7653,
        store_longitude: -73.9831,
        total_orders: 70.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Salad, Italian, Wine, Coffee, Delicious, Cozy, Romantic, Polished, Intimate',
        annual_dol: 101.09,
        description:
            "Cozy Italian veteran that's popular pre- & post- event-going at Carnegie Hall & City Center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8572,
        store_longitude: -73.9326,
        total_orders: 1555.45,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Burger, Salad, Cajun, Peruvian, Beer, South American, Wine, Spanish, American, Delicious',
        annual_dol: 1633.65,
        description:
            'Quaint urban hangout providing burgers & other sandwiches, plus wines by the glass & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8613,
        store_longitude: -73.9211,
        total_orders: 64.83,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords: 'Tacos, Pizza, Salad, Steak, Mexican, Margarita, Wine, Hawaiian, Bbq, Spanish',
        annual_dol: 186.74,
        description:
            'Mexican & Dominican dishes presented in a colorful, ornate venue with a full bar & patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -74.0019,
        total_orders: 345.51,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Lasagna, Salad, Wine, Italian, Dessert, Vodka, Beer, Delicious, Meatball, Warm',
        annual_dol: 434.66,
        description:
            'This petite Italian restaurant showcasing creative pasta dishes also offers beer, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.679,
        store_longitude: -73.9813,
        total_orders: 174.38,
        neighborhood: 'Park Slope',
        keywords:
            'Tasty, Vegan, Salad, Ethiopian, Classy, African, Relaxed, Awesome, Traditional, Classic',
        annual_dol: 194.31,
        description:
            'Relaxed place serving traditional Ethiopian dishes like meat or vegetable stews & injera bread.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -74.0051,
        total_orders: 521.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Hotel, Friendly, Casual, Hip, Mojito, Brazilian, Negroni',
        annual_dol: 2131.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5627,
        store_longitude: -74.1919,
        total_orders: 46.59,
        neighborhood: 'Arden Heights',
        keywords: 'Burger, Ale, American, Spicy, Casual, Local, Fresh, Recommended',
        annual_dol: 190.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.738,
        store_longitude: -73.998,
        total_orders: 224.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Wine, Vodka, Organic, Flavorful, Georgian, Traditional, Friendly, Casual, Sleek',
        annual_dol: 785.83,
        description:
            'Laid-back choice for Georgian staples like khachapuri paired with natural, organic wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6424,
        store_longitude: -74.075,
        total_orders: 293.03,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Steak, Lobster, Lobster Roll, Italian, Bbq, French, American, Traditional, Perfect, Casual',
        annual_dol: 381.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6815,
        store_longitude: -73.965,
        total_orders: 349.09,
        neighborhood: 'Clinton Hill',
        keywords: 'Pizza, Bbq, Trendy, Latin, Awesome, Local, Great, Casual',
        annual_dol: 1426.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7201,
        store_longitude: -73.961,
        total_orders: 5.92,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Coffee, Gin, Italian, Hotel, Tasty, Old Fashioned, Rum, Spicy',
        annual_dol: 24.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6991,
        store_longitude: -73.9166,
        total_orders: 1303.65,
        neighborhood: 'Bushwick',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Salad, Dessert, Chinese, Tasty, Spanish, Latin, Wonton Soup',
        annual_dol: 1697.3,
        description:
            'Tiny, booth-lined venue with an informal vibe, Chinese & Spanish food & colorful cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7102,
        store_longitude: -73.9336,
        total_orders: 48.36,
        neighborhood: 'East Williamsburg',
        keywords:
            'Beer, Cornbread, Bourbon, Rustic, Bbq, American, Thai, Polished, Casual, Traditional',
        annual_dol: 197.69,
        description:
            'North Carolina-style BBQ including smoked whole hog plus brown booze & craft beer in a huge space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7395,
        store_longitude: -74.0072,
        total_orders: 1112.95,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Dessert, Avocado Toast, Organic, Peruvian, Homemade, American, Gumbo, Seasonal, Hotel, Traditional',
        annual_dol: 4549.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.688,
        store_longitude: -73.5954,
        total_orders: 12.45,
        neighborhood: '',
        keywords: 'Casual, Local, Irish',
        annual_dol: 33.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8413,
        store_longitude: -73.8427,
        total_orders: 45.34,
        neighborhood: 'Westchester Square',
        keywords: 'Beer, Jamaican, Caribbean, Local, Wonderful, Authentic, Great, Casual',
        annual_dol: 185.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6574,
        store_longitude: -73.5477,
        total_orders: 377.56,
        neighborhood: '',
        keywords:
            'Kebab, Vodka, Gluten Free, Turkish, Delicious, Mediterranean, Cozy, Intimate, Healthy, Authentic',
        annual_dol: 410.88,
        description:
            '"We love bringing our community together over delectable and healthy Persian flavors."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7722,
        store_longitude: -73.9607,
        total_orders: 2061.4,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Mimosa, Martini, Spanish, Delicious, American, Bellini',
        annual_dol: 3307.92,
        description:
            'New American bistro with a vintage copper marquee & a menu stressing oysters, burgers & martinis.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -73.9328,
        total_orders: 1530.64,
        neighborhood: 'East Williamsburg',
        keywords:
            'Wine, Cinnamon Roll, Delicious, Warm, Beer, Italian, Cozy, Inviting, Sweet, Eclectic',
        annual_dol: 1632.39,
        description:
            'Eatery in a former tailor shop serving an eclectic menu paired with cocktails, wine & local brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6448,
        store_longitude: -73.9214,
        total_orders: 272.76,
        neighborhood: 'East Flatbush',
        keywords:
            'Salad, Jamaican, Caribbean, Bbq, Beer, Legendary, Phenomenal, Local, Casual, Signature',
        annual_dol: 660.06,
        description:
            'Caribbean-inspired cuisine with signature Rasta Pasta, plus a full bar & entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7362,
        store_longitude: -73.9874,
        total_orders: 1306.64,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Upscale, Bourbon, Whiskey, Gin, Martini, Salad, Delicious, Romantic, Hotel, Spicy',
        annual_dol: 1441.09,
        description:
            'Cocktail parlor with fancy drinks & elevated bites in an upscale space with a time-traveling theme.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.8622,
        total_orders: 1216.49,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Lobster, Lobster Roll, Steak, Cajun, Tasty, Margarita, Beer, Delicious, Mojito, Casual',
        annual_dol: 2507.2,
        description:
            'Cajun-style seafood boils whipped up in a casual, brick-lined dining room with booths & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7975,
        store_longitude: -73.968,
        total_orders: 80.94,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Mexican, Steak, Nachos, Beer, Margarita, Savory, Spicy, Delicious, Local, Casual',
        annual_dol: 189.28,
        description:
            'Traditional Mexican dishes plus beer & tequila-spiked drinks served in a no-frills setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8623,
        store_longitude: -73.9113,
        total_orders: 1831.13,
        neighborhood: 'Fordham Manor/West Bronx',
        keywords:
            'Steak, Salad, Wine, Seafood Paella, Margarita, Tasty, Beer, Upscale, Spicy, Spanish',
        annual_dol: 2108.71,
        description:
            'Splashy restaurant & lounge offering Latin bites, specialty drinks, entertainment & salsa dancing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.973,
        total_orders: 1283.74,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, German, Spacious, Perfect, Lively, Clean',
        annual_dol: 5247.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9733,
        total_orders: 1379.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Salad, Upscale, Ale, American, Daiquiri, Bruschetta, Vintage, Gracious',
        annual_dol: 4862.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7584,
        store_longitude: -73.9707,
        total_orders: 2550.64,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Sushi, Salad, Wine, Italian, Margarita, Spicy, Delicious, American, Polished',
        annual_dol: 2604.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9713,
        total_orders: 720.05,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Scotch, Whiskey, Wine, Bourbon, Hotel, Dutch',
        annual_dol: 767.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.88,
        store_longitude: -73.5964,
        total_orders: 30.2,
        neighborhood: '',
        keywords:
            'Steak, Pizza, Italian, Delicious, Quaint, Comfortable, Fajitas, American, Casual, Great',
        annual_dol: 123.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6799,
        store_longitude: -73.9427,
        total_orders: 745.15,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Vegetarian, Vietnamese, Fun, Ale, Premium, Friendly, Awesome, Thai, Asian, Great',
        annual_dol: 890.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6932,
        store_longitude: -74.0012,
        total_orders: 856.95,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Steak, Burger, Salad, American, Mediterranean, Awesome, Amazing, Fantastic, Great, Wonderful',
        annual_dol: 1048.87,
        description:
            'Scenic spot at ONE°15 Brooklyn Marina serving French-inspired plates with local ingredients.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7,
        store_longitude: -73.9939,
        total_orders: 419.49,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Burger, Wine, Beer, Vodka, Dessert, Martini, Gin, Upscale, Stylish, Italian',
        annual_dol: 1714.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7841,
        store_longitude: -73.9504,
        total_orders: 409.83,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Steak, Vegetarian, Cheesesteak, Salad, Organic, Swiss, Philly Cheesesteak, Banana Split, American',
        annual_dol: 528.92,
        description:
            "Brunches, a children's menu & a doggy drinking fountain bring the crowds to this American eatery.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5279,
        store_longitude: -74.2395,
        total_orders: 90.59,
        neighborhood: 'Charleston',
        keywords:
            'Lobster, Shrimp Cocktail, Lobster Bisque, Chicken Parmigiana, Salad, Cajun, Italian, Beer, Vodka, Tasty',
        annual_dol: 102.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.9938,
        total_orders: 594.32,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Wine, Beer, Spacious, Comfortable, Clean, Hotel, Premium, Awesome, Innovative, Contemporary',
        annual_dol: 1832.54,
        description:
            '"Experience the difference of AMC amenities. From spacious rocking seats to luxury recliners, innovative menus and premium offerings like IMAX, Dolby Cinema, and Prime at AMC, AMC Theatres offers a range of ways to get more out of movies."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -74.0002,
        total_orders: 1244.92,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tequila, Whiskey, Vodka, Mexican, Tacos, Gin, Hotel, American, Latin, Irish',
        annual_dol: 1304.52,
        description:
            '"Cocktail lounge offering fun & sophisticated drinks, also serving Mexican street fair."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7262,
        store_longitude: -74.0003,
        total_orders: 777.71,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Salad, Champagne, Mimosa, Italian, Cosmopolitan, Tuna Melt Sandwich, Beer, Mediterranean, French, Prosecco',
        annual_dol: 1121.11,
        description:
            'Smoked fish, housemade bagels & other appetizing fare offered in a buzzy, vintage-style spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.661,
        store_longitude: -73.9611,
        total_orders: 213.17,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Burger, Tacos, Mexican, Salad, Nachos, Beer, Burritos, Quesadillas, Ale, Enchiladas',
        annual_dol: 871.36,
        description:
            'A variety of burritos, fajitas & other Mexican staples offered in an open, relaxed eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6667,
        store_longitude: -73.9878,
        total_orders: 42.63,
        neighborhood: 'Park Slope',
        keywords: 'Hotdog, Beer, Wine, Ale, Irish, American, Upscale, Friendly, Casual, Fantastic',
        annual_dol: 72.04,
        description:
            'Irish pub & game-day hangout offering cocktails, draft beers & darts in low-key, compact quarters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.95,
        total_orders: 5502.07,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Tequila, Mexican, Beer, Pizza, Vegetarian, Margarita, Tasty, Dessert, Spicy, Friendly',
        annual_dol: 12905.95,
        description:
            'Mexican fare, brunch & cocktails are served in this hip, industrial-chic spot that has a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7347,
        store_longitude: -73.9879,
        total_orders: 1247.57,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Beer, Tasty, Wine, Margarita, Delicious, Tangy, Buffalo Wings, Irish, Bbq',
        annual_dol: 1394.87,
        description:
            'Pub offering an American/Irish bar menu (plus weekend brunch), Guinness on tap & sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7244,
        store_longitude: -73.9915,
        total_orders: 1950.22,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Steak, Burger, Lasagna, Whiskey, Shrimp Cocktail, Wine, Upscale, Champagne, Premium, American',
        annual_dol: 2034.77,
        description:
            'Steakhouse with classic cuts in modern preparations plus pasta & more in a retro-styled setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7388,
        store_longitude: -73.9885,
        total_orders: 849.34,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Salad, Vodka, Beef Stroganoff, Wine, Dessert, Russian, Tasty, Beer, Georgian, Delicious',
        annual_dol: 886.17,
        description:
            'Old-fashioned Russian menu served in a sitting room-style space with books, lace, photos & curios.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7304,
        store_longitude: -73.9841,
        total_orders: 29.3,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Flavorful, Cajun, Coffee, Spicy, Vietnamese, Creole, Casual, Intimate, Fun',
        annual_dol: 119.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -73.9904,
        total_orders: 142.93,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Mexican, Beer, Bourbon, Dessert, Spicy, Italian, American, Delicious, Sweet, Casual',
        annual_dol: 584.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8035,
        store_longitude: -73.9637,
        total_orders: 203.99,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Italian, Wine, Coffee, Dessert, Tasty, Pizza, Classy, Homemade, Casual, Omelette',
        annual_dol: 279.49,
        description:
            'Relaxed, brick-lined standby pairing pasta, panini & other casual Italian plates with wine & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5495,
        store_longitude: -74.1506,
        total_orders: 43.68,
        neighborhood: 'Great Kills',
        keywords: 'Elegant, Luxurious, Perfect, Clean, Relaxed, Outstanding, Friendly, Great',
        annual_dol: 178.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.9798,
        total_orders: 947.89,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Legendary, Iconic, Classic, Excellent, Irish, Fancy, Cool, Casual',
        annual_dol: 3874.67,
        description: '"Cocktail bar in a storied Rockefeller Center townhouse"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.9622,
        total_orders: 330.98,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords: 'Sushi, Wine, Tasty, Chinese, Japanese, Delicious, Thai, Hotel, Asian, French',
        annual_dol: 353.98,
        description:
            'Asian-fusion fare such as sushi, Thai curries & stir-fries, plus drinks, served in a modern space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7629,
        store_longitude: -73.9714,
        total_orders: 1406.02,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Steak, Chicken Caesar Salad, Italian, Dessert, Gluten Free, Meatball, Wine, Homemade, Caesar Salad',
        annual_dol: 1546.79,
        description:
            'Upscale, industrial-chic Italian eatery with a downstairs nightclub featuring DJs & bottle service.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7026,
        store_longitude: -73.9889,
        total_orders: 2078.62,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Burger, Lasagna, Pizza, Italian, Meatball, Wine, Delicious, Stylish, Warm, Friendly',
        annual_dol: 2107,
        description:
            'Stylish restaurant serving contemporary Italian food using local & imported Italian produce.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.683,
        store_longitude: -73.9407,
        total_orders: 4585.89,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tequila, Whiskey, Wine, Beer, Trendy, Delicious, Robust, Intimate, Awesome, Great',
        annual_dol: 14779.98,
        description:
            'Seasonal mixed drinks & microbrews served in a dark, narrow bar with patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7397,
        store_longitude: -73.9888,
        total_orders: 1057.09,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Burger, Salad, Italian, Bread Pudding, Roast Beef, Meatball, Chilean, Wine, Martini, Organic',
        annual_dol: 1126.55,
        description:
            'Longtime eatery serving a New American menu along with numerous pasta dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6919,
        store_longitude: -73.9861,
        total_orders: 2578.56,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Pizza, Beer, Innovative, Sweet, Great, Excellent, Recommended, Local, Traditional, Casual',
        annual_dol: 2635.01,
        description:
            'Brewery serving its own pints plus Neapolitan pizzas & other plates in a polished, sizable space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6945,
        store_longitude: -73.986,
        total_orders: 170.25,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Tacos, Mexican, Salad, Burritos, Warm, Delicious, Healthy, Casual, Fresh, Local',
        annual_dol: 695.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -73.9852,
        total_orders: 69.61,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Mexican, Salad, Delicious, Hotel, Healthy, Local, Casual, Western, Great',
        annual_dol: 376.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -73.9984,
        total_orders: 486.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Spicy, Vegan, Vegetarian, Delicious, Savory, Beer, Chinese, Eclectic, Creamy, Cozy',
        annual_dol: 1634.17,
        description:
            'Cozy eatery with eclectic decor serving up plant-based Chinese Szechuan plates in a casual setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -74.006,
        total_orders: 1208.22,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Vegetarian, Vegan, Kebab, Tasty, Gluten Free, Italian, Spicy, Delicious, Stylish',
        annual_dol: 1260.61,
        description:
            'Chelsea Market branch of a global chain for Mediterranean stuffed pitas with a local twist.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7703,
        store_longitude: -73.9849,
        total_orders: 318.06,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'American, Perfect, Great',
        annual_dol: 353.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7427,
        store_longitude: -73.9543,
        total_orders: 280.2,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Wine, Beer, Irish, French, Friendly, Casual, American, Awesome, Great, British',
        annual_dol: 1055.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -73.8698,
        total_orders: 2638.29,
        neighborhood: 'Rego Park/Middle Village/Flushing',
        keywords:
            'Steak, Salad, Tomato Soup, Wine, Italian, Cozy, Classy, Caesar Salad, Fabulous, Modern',
        annual_dol: 2698.72,
        description:
            "Red-sauce Italian fare served in warm, modern digs with a big bar, kids' menu & free valet parking.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9736,
        total_orders: 529.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Pizza, Vegan, Italian, Apple Pie, Coffee, Mediterranean, Delicious, Stylish, Spacious',
        annual_dol: 552.4,
        description:
            'Northern Italian restaurant offering Venetian specialties & more for dinner & lunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8244,
        store_longitude: -73.9523,
        total_orders: 528.6,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Bourbon, Trendy, Modern, American, Classic, Crisp, Traditional, Scottish, Casual, Cool',
        annual_dol: 628.57,
        description:
            '"The Vale is the safe space in the neighborhood where the customer’s comfort can be achieved at any level. Authentically Scottish, with full and half pint pours, cocktails, comfort food and social snugs."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6719,
        store_longitude: -73.8428,
        total_orders: 533.83,
        neighborhood: 'Ozone Park',
        keywords: 'Delicious, Casual, Local',
        annual_dol: 733.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9704,
        total_orders: 649.19,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Tequila, Modern, Crisp, Vibrant, Authentic, Fiery, Great, Fine, Recommended',
        annual_dol: 3276.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9907,
        total_orders: 1627.8,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Tequila, Salad, Beer, Wine, Margarita, Nachos, Spicy, Warm, American',
        annual_dol: 1666.44,
        description:
            'Charming tavern with exposed brick walls, serving elevated comfort eats & signature cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6916,
        store_longitude: -73.9577,
        total_orders: 2717.37,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Burger, Tasty, Ale, Wine, American, Latin, Friendly, Retro, Southern',
        annual_dol: 3073.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6876,
        store_longitude: -73.99,
        total_orders: 3040.04,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Burger, Salad, French, Spicy, Mediterranean, Polished, Bloody Mary, American, Perfect, Vibrant',
        annual_dol: 3144.58,
        description:
            'Everyday bar in an intimate setting serving up lunch, dinner & brunch on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6897,
        store_longitude: -73.9717,
        total_orders: 527.41,
        neighborhood: 'Fort Greene',
        keywords:
            'Pizza, Italian, Coffee, Upscale, African, Comfortable, American, South African, Classy, Refreshing',
        annual_dol: 2155.89,
        description:
            '"Milanese-born Brooklyn restaurateur, Edoardo Mantelli, has long admired the Viennese-style cafes of fin-de-siècle Milan and the cultural influence they had on his native city. In Saraghina Caffé, he’s lovingly recreated the sense of grace and grandeur found in these beloved institutions, where heated exchanges about the origins of the Negroni were cooled by a refreshing Spritz (Campari, per favore, not Aperol). More than places to stop for a drink, these caffè were cultural hearts of their neighborhoods, where poets and artists would find their next inspiration while nibbling on well-aged prosciutto di Parma."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9876,
        total_orders: 395.85,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Burger, Salad, Beer, New-York-Style, Wine, Caesar Salad, Local, Recommended, Casual',
        annual_dol: 436.12,
        description:
            '"Serving Detroit-style pizzas and an award-winning burger. Full service bar, local drafts & wine selection."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9911,
        total_orders: 84.61,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Garlic Bread, Lobster, Pizza, Salad, Italian, Beer, Delicious, Margarita, Ale, Warm',
        annual_dol: 106.44,
        description:
            'Coal-fired Neapolitan pizza & Italian entrees feature at this quaint spot with sidewalk tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.686,
        store_longitude: -73.9943,
        total_orders: 17.57,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Pizza, Italian, Shrimp Cocktail, Meatball, Wine, French, Retro, Yummy, Local, Casual',
        annual_dol: 28.57,
        description:
            'Old-school red-sauce joint draws a local crowd with pizza & other Italian basics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9806,
        total_orders: 751.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Tequila, Whiskey, Beer, Wine, Vodka, Margarita, Gin, Tasty, Rum, Spicy',
        annual_dol: 1271.62,
        description:
            'Popular after-work bar that pays homage to the 1991 surfer action film that shares its name.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7085,
        store_longitude: -74.0141,
        total_orders: 214.68,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Vegetarian, Salad, Cajun, Shrimp Scampi, Dessert, French Onion Soup, Bbq, French',
        annual_dol: 771.91,
        description:
            'Ornate, wood-paneled eatery set in an 1812 former church & serving both pub grub & Chinese classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7769,
        store_longitude: -73.9794,
        total_orders: 636.48,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Italian, Beer, French Toast, French, Delicious, American, Natural, Omelette, Casual',
        annual_dol: 2234.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9873,
        total_orders: 2093.75,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Korean, Italian, Savory, Bbq, Innovative, Sweet, Memorable, Amazing, Perfect, Modern',
        annual_dol: 3014.09,
        description:
            'Offshoot of a long-standing Seoul restaurant specializing in Korean BBQ, dishes & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7453,
        store_longitude: -73.9931,
        total_orders: 421.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Sushi, Wine, Cozy, Hotel, Delicious, Italian, Intimate, French, American',
        annual_dol: 1029.9,
        description:
            "\"Enjoy dinner at the show at Chelsea Table + Stage - New York's newest hotspot for intimate dining and music. Experience some of the best touring performers in the world featured alongside emerging artists. Ever-changing culinary and entertainment lineups mean you'll never have the same night twice. The menu features American fare, curated wine, and specialty cocktails. Visit our space on the main level for post-show drinks or a cozy weeknight dinner. Experience the Fashion District's destination for the ultimate NYC night out. Check out our calendar for what's coming up at Chelsea Table + Stage.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -74.0161,
        total_orders: 1399.27,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Lobster, Lobster Roll, Steak, Salad, Mexican, Wine, Italian, Beer, Delicious, Trendy',
        annual_dol: 1522.76,
        description:
            'Relaxed eatery serving sustainable seafood plus beer, wine & cocktails in bright, beachy digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7275,
        store_longitude: -74.0006,
        total_orders: 2901.21,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Tasty, Wine, Salad, Beer, Upscale, Ale, Irish, Nachos, Fantastic',
        annual_dol: 3096.28,
        description:
            'A full menu of pub grub & lengthy beer list are complemented by sports on TV & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.9975,
        total_orders: 21.51,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Salad, Margarita, Champagne, Wine, Tasty, Prosecco, Whiskey',
        annual_dol: 87.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9766,
        total_orders: 1361.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Wine, Italian, Upscale, French Toast, Delicious, Romantic, French, Refined, Classy',
        annual_dol: 1515.55,
        description:
            'Refined versions of Italian comfort food served in a renovated townhouse with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.9889,
        total_orders: 498.05,
        neighborhood: 'Lower East Side',
        keywords: 'Vodka, Tasty, Beer, Delicious, Cool, Casual, Great',
        annual_dol: 2035.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6767,
        store_longitude: -73.9803,
        total_orders: 92.36,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Salad, Mexican, Italian, Gourmet, Upscale, Spicy, Rustic, American, Cozy',
        annual_dol: 377.53,
        description:
            '"American Italian locale, serving wood-fire pizzas, pastas, small plates, craft cocktails and much more. Family Friendly. Kitchen Open till 11pm and Pizza Open till Midnight."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7408,
        store_longitude: -74.0005,
        total_orders: 29.56,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Cheesecake, Chocolate Mousse, Vegetarian, Margarita, Spanish, Wine, Delicious, Cosmopolitan, Cozy',
        annual_dol: 40.24,
        description:
            'Long-standing option for Spanish tapas, paella & more served in a cozy cafe with an old-world feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -73.9845,
        total_orders: 351.89,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Warm, Fun, Vintage, Classic, Great, Iconic, Creative, Fine, Casual',
        annual_dol: 359.35,
        description:
            'Dark, unassuming hangout boasting a jukebox, billiards & drink specials with a laid-back vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7227,
        store_longitude: -73.9862,
        total_orders: 2817.16,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Vegan, Beer, Tomato Soup, Organic, Warm, Hotel, Friendly, Classic, Cool, Casual',
        annual_dol: 2876.94,
        description:
            'Longtime barroom with cheap drinks, crowd-pleasing jukebox & B movies projected on the walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7115,
        store_longitude: -73.9464,
        total_orders: 2219.03,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Beer, Wine, Stylish, Vintage, Irish, Clean, Sleek, Classic, Casual, Seasonal',
        annual_dol: 2459.35,
        description:
            '"Classically styled bar, restaurant and event space. Great for events, live performances, dinner, drinks and weddings. Seasonal cocktail menus and great food options."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9829,
        total_orders: 3783.08,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Beer, Classic, Colorful, Great, Aesthetic, Local, Casual',
        annual_dol: 3863.35,
        description:
            'Texas roadhouse-style bar in the East Village with biker bartenders & country music on the jukebox.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -73.9515,
        total_orders: 1898.96,
        neighborhood: 'Williamsburg',
        keywords: 'Whiskey, Beer, Cozy, Casual, Chill, Lit, Great',
        annual_dol: 1898.96,
        description:
            'A laid-back, dimly lit watering hole with cozy interior decor & a big backyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -74.0024,
        total_orders: 2629.45,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Beer, Ale, Cajun, Upscale, American, Nachos, Irish, Classic, Fresh, Great',
        annual_dol: 2746.97,
        description:
            'Beer comes in huge Styrofoam cups at this long-standing barroom with bras hanging from the ceiling.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.7487,
        total_orders: 36.98,
        neighborhood: 'Douglaston/Flushing/Little Neck',
        keywords: 'Salad, Spacious, Friendly, Fun, Clean, Golfclub, Casual',
        annual_dol: 139.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.717,
        store_longitude: -73.9567,
        total_orders: 57.73,
        neighborhood: 'Williamsburg',
        keywords:
            'Italian, Pizza, Wine, Delicious, Romantic, Bruschetta, Great, Authentic, Satisfying, Excellent',
        annual_dol: 134.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -73.9612,
        total_orders: 107.18,
        neighborhood: 'Williamsburg',
        keywords:
            'Mexican, Enchiladas, Premium, Relaxed, Vibrant, Caribbean, Sweet, Lively, Latin, Awesome',
        annual_dol: 438.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7483,
        store_longitude: -73.9846,
        total_orders: 130.27,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Casual, Wholesome',
        annual_dol: 532.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.723,
        store_longitude: -73.5755,
        total_orders: 190.54,
        neighborhood: '',
        keywords:
            'Lobster, Pizza, Shrimp Cocktail, Italian, Meatball, Wine, Homemade, Local, Friendly, Classic',
        annual_dol: 207.66,
        description:
            'Longtime local (since 1955) serving pizzas & pastas in a brick-walled space with old-world murals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8248,
        store_longitude: -73.8202,
        total_orders: 143.56,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Burger, Steak, Beer, Spicy, Wine, Nachos, Legendary, American, Awesome, Perfect',
        annual_dol: 586.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6858,
        store_longitude: -73.8284,
        total_orders: 27.64,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords:
            'Vegetarian, Chinese, French, Casual, Authentic, American, Latin, Local, Caribbean, Fresh',
        annual_dol: 112.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7962,
        store_longitude: -73.9351,
        total_orders: 118.61,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Hamburger, Beer, Wine, American, Japanese, Latin, Elegant, Dope, Fusion, Classic',
        annual_dol: 276.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6829,
        store_longitude: -73.9352,
        total_orders: 136.77,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords: 'Burger, Steak, Salad, Pizza, Vegan, Flavorful, Mimosa, Tasty, Wine, Delicious',
        annual_dol: 223.26,
        description:
            'Pan-Latin food & drinks are featured at this brick-walled eatery & bar with a back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6432,
        store_longitude: -73.7903,
        total_orders: 918.35,
        neighborhood: 'Jamaica',
        keywords:
            'Burger, Pizza, Hotel, American, Western, Friendly, Sweet, Great, Heritage, Excellent',
        annual_dol: 1453,
        description:
            'Botanical Garden.Unpretentious rooms feature complimentary Wi-Fi, iPod docks and flat-screen TVs, in addition to minifridges, coffeemakers and safes. Upgraded rooms can sleep up to 4 guests.Continental breakfast is included.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5444,
        store_longitude: -74.1406,
        total_orders: 482.82,
        neighborhood: 'Great Kills',
        keywords:
            'Burger, Chicken Caesar Salad, Salad, French Onion Soup, French, Caesar Salad, Great, Friendly',
        annual_dol: 549.9,
        description:
            '"Richmond County Yacht Club is a private, members only club. That said, we\'re a friendly group with many sail and power boaters. We\'re always interested in bringing new members into our fun, family organization. Stop by, and explore all that we have to offer the Staten Island boater."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8477,
        store_longitude: -73.9314,
        total_orders: 153.38,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Hotel, Legendary, Colorful, Vibrant, Fun, Great, Dominican, Cultural, Contemporary, Distinctive',
        annual_dol: 626.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8034,
        store_longitude: -73.9638,
        total_orders: 1958.03,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Pizza, Beer, Chinese, Romantic, Fun, Awesome, Friendly, Dope, Great, Lit',
        annual_dol: 2046.15,
        description:
            'Dimly-lit barroom attracting college crowd & locals with budget-priced drinks, darts & pool.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7534,
        store_longitude: -73.9663,
        total_orders: 6843.63,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Rooftop, Magical, Delicious, Fantastic, Iconic, Wonderful, Great, Excellent, Classic, Friendly',
        annual_dol: 6988.84,
        description:
            'Rooftop lounge at the Beekman Tower sporting plush banquettes, cocktails & East River views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8874,
        store_longitude: -73.907,
        total_orders: 1011.2,
        neighborhood: 'Fieldston/Riverdale/West Bronx',
        keywords: 'Mexican, Pizza, Italian, Mediterranean, Artisanal, Casual, Great',
        annual_dol: 4133.47,
        description: '"Artisanal Oaxacan Inspired Mezcal. Food. Culture NOW OPEN 🪅"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.7716,
        total_orders: 279.22,
        neighborhood: 'Bayside',
        keywords: 'Thai, Salad, Coffee, Asian, Spicy, Pad Thai, Friendly, Casual, Tom Yum, Local',
        annual_dol: 350.97,
        description:
            'Thai standards, such as curries & noodle dishes, served in a casual space with a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.7706,
        total_orders: 169.32,
        neighborhood: 'Bayside',
        keywords:
            'Vegetarian, Kebab, Chicken Tikka Masala, Southern, Indian, Casual, Friendly, Traditional, Retro, Classy',
        annual_dol: 200.05,
        description:
            'Colorful Indian restaurant & bar with a retro interior providing elevated entrees & a tapas menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7148,
        store_longitude: -74.0096,
        total_orders: 1679.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Burger, Wine, Savory, Trendy, Organic, Sustainable, Hotel, Comfortable, Polished',
        annual_dol: 6955.66,
        description:
            'Posh restaurant in the Smyth Hotel serving seasonal New American small & large plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -74.0076,
        total_orders: 649.21,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Chocolate Cake, Pizza, Vodka, Wine, Chicken Parmigiana, Italian, Upscale, Delicious, Meatball',
        annual_dol: 707.32,
        description:
            'Airy place serving classic & modern Italian shared plates, plus brick-oven pizzas & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7572,
        store_longitude: -73.9686,
        total_orders: 1766.55,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Salad, Upscale, Champagne, Warm, Hotel, Classic, American, Iconic, Great',
        annual_dol: 1897.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7031,
        store_longitude: -73.9915,
        total_orders: 459.84,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Tacos, Lobster, Wine, Beer, Dessert, Italian, Sustainable, Relaxed, Perfect, Sweet',
        annual_dol: 496.01,
        description:
            'Relaxed eatery serving sustainable seafood plus beer, wine & cocktails in bright, beachy digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6265,
        store_longitude: -74.1583,
        total_orders: 195.62,
        neighborhood: 'Mariners Harbor',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Salad, Spanish, Italian, Wine, Peruvian, Chinese, Dominican',
        annual_dol: 204.11,
        description:
            'Old-school standby offering a traditional Spanish menu with emphasis on lobster, shrimp & paella.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9893,
        total_orders: 6503.75,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Beer, American, Premium, Funky, Eclectic, Classy, Fantastic, Great, Casual',
        annual_dol: 6902.11,
        description:
            'Even the servers sing at this multipurpose club with a piano bar, 2 showrooms & an American eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9901,
        total_orders: 9069.39,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Tasty, Tuna Steak, Beer, Fruity, Rustic, Delicious, Friendly, Perfect',
        annual_dol: 10669.95,
        description:
            'Hip outpost with eclectic decor & low lighting serving creative pub fare with full bar & happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6804,
        store_longitude: -73.9529,
        total_orders: 579.24,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'American, Nachos, Classic, Sweet, Amazing, Great, Incredible, Casual, Chill',
        annual_dol: 648.59,
        description:
            'Chill gastropub with exposed brick walls offering cocktails & beer, plus nachos & other light fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9906,
        total_orders: 45.94,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Korean, Italian, Wine, Champagne, Gourmet, Asian, Casual, Sustainable, Local',
        annual_dol: 86.77,
        description:
            'Urbane restaurant with an open kitchen specializing in gourmet Korean hand rolls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9884,
        total_orders: 1645.65,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Lobster, Lobster Roll, Salad, Gluten Free, Beer, French Onion Soup, French, Quaint, Modern',
        annual_dol: 1693.91,
        description:
            'Two-story quaint, modern eatery turning out elevated American & international fare, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.753,
        store_longitude: -73.997,
        total_orders: 6156.01,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Tequila, Beer, Wine, Italian, Spicy, Gin, Mediterranean, Hotel, Warm',
        annual_dol: 23193.85,
        description:
            "\"Moynihan Food Hall features a curated selection of the city's best food and full-service beer, wine and cocktail outlet for guests with a one-track mind. Tempt your taste buds by exploring the different dining options Moynihan Food Hall has to offer. Grab a bite or a cocktail before heading out to MSG, pair a meal with your travel plans or grab a drink and take the edge off of the commute! An array of dining options are available: THE BAR, Alidoro, Birch Coffee, Burger Joint, Blue Bottle Coffee, Chopt, Davey's Ice Cream, E.A.K. Ramen, H&H Bagels, Jacob's Beer Biscuits Pickles, La Maison Du Chocolat, Magnolia Bakery, Maman, Naya, Pressed, Sauce Pizzeria, Starbucks, Threes Brewing, Vesuvio Bakery and Yo Yo Sushi.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7524,
        store_longitude: -73.9914,
        total_orders: 4590.51,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Steak, Cheesesteak, Salad, Wine, Tasty, Beer, Upscale, Delicious, Trendy, Gin',
        annual_dol: 4591.97,
        description:
            'Upscale pub serving modern bar fare in a space with a front bar and back dining area.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7461,
        store_longitude: -74.0007,
        total_orders: 234.54,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Hamburger, Salad, Italian, Dessert, French, Casual, Coleslaw, Fine, Great',
        annual_dol: 249.6,
        description:
            'Big portions of typical diner fare served around the clock power this night-owl mainstay.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7684,
        store_longitude: -73.9817,
        total_orders: 2439.06,
        neighborhood: '',
        keywords:
            'Vegan, Vegetarian, Sushi, Gluten Free, Avocado Toast, French, Hotel, Friendly, Casual, Asian',
        annual_dol: 2781.15,
        description:
            'Sophisticated New French eatery boasting floor-to-ceiling windows overlooking Central Park.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6325,
        store_longitude: -73.9478,
        total_orders: 1041.86,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Burger, Shrimp Cocktail, Sushi, Pizza, Bbq, Margarita, American, Homemade, Creamy, Coleslaw',
        annual_dol: 1214.42,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -74.0009,
        total_orders: 6641.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Steak, Beer, Wine, French, American, Hotel, Vintage, Classic, Friendly',
        annual_dol: 7782.07,
        description:
            'Celeb-magnet hot-ticket tavern with upper-end French bistro fare & reimagined vintage decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -74.0033,
        total_orders: 3779.09,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Salad, Cosmopolitan, Elegant, Bourbon, Warm, Mediterranean, Friendly, Authentic, Cool',
        annual_dol: 4103.77,
        description:
            'Elegant outpost of the Midtown original, with creative Mediterranean fare & private outdoor dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -73.9982,
        total_orders: 171.57,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Steak, Tequila, Cuban, Cheesesteak, Margarita, Seafood Paella, Wine, Spicy, Delicious',
        annual_dol: 182.85,
        description:
            'Traditional dishes & drinks from Brazil & Cuba served in an intimate room with tropical decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6661,
        store_longitude: -73.9565,
        total_orders: 271.13,
        neighborhood: 'Crown Heights',
        keywords: 'Mexican, Bbq, Caribbean, American, Canadian, Local, Traditional, Casual, Great',
        annual_dol: 301.88,
        description:
            'This established, casual cafe serves both traditional Caribbean & standard American cuisines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7252,
        store_longitude: -74.0022,
        total_orders: 401.45,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Wine, Italian, Delicious, Warm, Refined, Mediterranean, Friendly, Perfect, Authentic, Exceptional',
        annual_dol: 427.22,
        description:
            "Elegant, contemporary restaurant specializing in refined fare from Italy's Piedmont region.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.8221,
        total_orders: 1.95,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Burger, Vegetarian, Mexican, Coffee, Wine, Dessert, American, Delicious, Casual, Sangria',
        annual_dol: 15.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8487,
        store_longitude: -73.7872,
        total_orders: 21091.44,
        neighborhood: 'City Island',
        keywords:
            'Steak, Lobster, Salad, Shrimp Cocktail, Cajun, Italian, Tasty, Spicy, Delicious, Fried Calamari',
        annual_dol: 22642.75,
        description:
            'Go-to stalwart for ample seafood plates plus Italian fare in low-key digs with a nautical motif.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7128,
        store_longitude: -74.0165,
        total_orders: 341.38,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Lobster, Lobster Roll, Burger, Champagne, Wine, Delicious, Beer, Coffee, Romantic, Exquisite',
        annual_dol: 596.41,
        description:
            "\"MESSAGE US at 212.619.6900 Manhattan By Sail - Shearwater Classic Schooner is a New York City cruise company, providing Manhattan boat cruises of the iconic New York harbor. We offer unique sightseeing tours of New York aboard our authentic 1929 schooner, the Shearwater! If you're looking for a unique event venue, you'll find it hard to beat the atmosphere aboard our beautiful ship! Looking to round out a trip to the Big Apple? One of our NYC day cruises may be just what you're looking for! Evening cruises can make for a romantic evening, or the perfect setting for an intimate business meeting. We offer dinner cruises, private charters and NYC sightseeing cruises to fit any budget!\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5236,
        store_longitude: -74.2391,
        total_orders: 213.72,
        neighborhood: 'Charleston',
        keywords: 'Delicious, Cozy, Sweet, Fabulous, Perfect, Awesome, Chic, Modern',
        annual_dol: 873.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9803,
        total_orders: 989.66,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Greek, Upscale, Vivid, Spectacular, Mediterranean, Great, Chic, Fine',
        annual_dol: 4045.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5836,
        store_longitude: -73.9397,
        total_orders: 144.82,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Steak, Hamburger, Pizza, Salad, Chicken Parmigiana, Shrimp Cocktail, Italian, Gluten Free, Mediterranean',
        annual_dol: 416.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8241,
        store_longitude: -73.9518,
        total_orders: 47.67,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Sushi, Lobster, Cinnamon Roll, Spicy, Japanese, Fried Rice, Sashimi, Puerto Rican, Caribbean, Fusion',
        annual_dol: 194.85,
        description:
            'Easygoing standby offering sashimi & an array of creative sushi rolls, plus familiar Asian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8292,
        store_longitude: -73.9481,
        total_orders: 342.4,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Steak, Salad, Mimosa, Beer, Caribbean, Caesar Salad, American, Sweet, Bloody Mary',
        annual_dol: 478.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6301,
        store_longitude: -74.0226,
        total_orders: 1509.88,
        neighborhood: 'Bay Ridge',
        keywords:
            'Vegetarian, Salad, Pizza, Italian, Shrimp Cocktail, Wine, Delicious, Cozy, Comfortable, Classy',
        annual_dol: 1541.92,
        description:
            'Circa-1964 Bay Ridge Italian restaurant serving red-sauce pasta dishes & other standards.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9619,
        total_orders: 2932.53,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords: 'Wine, Beer, Ale, Cozy, Vintage, Friendly, Welcoming, Vibrant, Casual, American',
        annual_dol: 2994.76,
        description:
            'Cozy neighborhood bar with a vintage vibe & a selection of beer, wine & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8756,
        store_longitude: -73.5975,
        total_orders: 379.49,
        neighborhood: '',
        keywords: 'Burger, Salad, Vodka, Roast Turkey, Coffee, Dessert, Rum, Bbq, Latin, American',
        annual_dol: 417.92,
        description:
            'Bustling pub dishing up popular burgers & other American eats in a stablelike locale with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7589,
        store_longitude: -73.9627,
        total_orders: 160.19,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Kebab, Vegetarian, Salad, Pizza, Coffee, Flavorsome, Lebanese, Mediterranean, Shawarma, Hotel',
        annual_dol: 720.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9689,
        total_orders: 94.95,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Flavorful, Wine, Egyptian, Exquisite, Premium, Mediterranean, Nachos, Awesome, Amazing, American',
        annual_dol: 550.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6313,
        store_longitude: -74.0277,
        total_orders: 490.19,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Salad, Chicken Pot Pie, Italian, Nachos, Bbq, Ale, Organic, American, Cozy',
        annual_dol: 925.78,
        description:
            'Sports bar/restaurant in a renovated fire station that morphs into a dance club on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9917,
        total_orders: 6283.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotdog, Beer, Ale, American, Thai, Legendary, Hip, Local, Casual, Great',
        annual_dol: 6416.83,
        description:
            'A neighborhood staple since 1933, this tavern is known for its no-frills atmosphere & free hot dogs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6846,
        store_longitude: -73.9921,
        total_orders: 3444.23,
        neighborhood: 'Carroll Gardens/Brooklyn Heights/Cobble Hill',
        keywords: 'Steak, Burger, Salad, Wine, Coffee, Rich, Elegant, Spicy, Italian, Gin',
        annual_dol: 3596.79,
        description:
            'A long roster of classic concoctions as well as some modern twists accompany a small plates menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.644,
        store_longitude: -73.6393,
        total_orders: 341.3,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Pizza, Italian, Wine, Organic, Impeccable, Clean, Casual, Caesar Salad',
        annual_dol: 421.42,
        description:
            '"Clean Italian Eating is what we are all about, at Tutti Noi. We pride ourselves on sourcing the freshest quality ingredients, and preparing entrees using time honored recipes and methods. Our sauces and entrees are made from scratch, and we promise you\'ll taste the difference when you dine with us. From our table to yours, we welcome you to join All of Us, at Tutti Noi!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6703,
        store_longitude: -73.9506,
        total_orders: 389.79,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Delicious, Salad, Beer, Cozy, French Toast, Sweet, French, Creole, Bbq',
        annual_dol: 530.62,
        description:
            'Easygoing, brick-lined hangout serving sandwiches & tacos, plus weekend brunch & unique cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7433,
        store_longitude: -73.9885,
        total_orders: 386.25,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Mexican, Beer, Wine, Trendy, Korean, Japanese, Spicy, Ramen, Asian, Modern',
        annual_dol: 498.08,
        description:
            'Asian-inspired & Japanese chain restaurant where dishes are whisked to long communal tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7009,
        store_longitude: -73.9892,
        total_orders: 87.15,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Coffee, Beer, Wine, Gluten Free, Tasty, Avocado Toast, Australian, Delicious, Healthy, Premium',
        annual_dol: 286.41,
        description:
            '"Bluestone Lane is an Australian-inspired coffee shop, cafés & lifestyle brand committed to providing a genuine daily escape for all our locals. We offer premium coffee and healthy and delicious eats through an always welcoming experience that embraces our ‘Aussie’ approach to life."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7611,
        store_longitude: -73.9905,
        total_orders: 78.07,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Tasty, Thai, Spicy, Green Curry, Polished, Sweet, Fusion, Western, Casual',
        annual_dol: 319.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7832,
        store_longitude: -73.9531,
        total_orders: 106.81,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Wine, Italian, Beer, Upscale, Rustic, Seasonal, Cozy, Delicious, Elegant, Quaint',
        annual_dol: 113.82,
        description:
            'High-end Italian fare in a chandelier-lit dining room across from the 92nd Street Y.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5634,
        store_longitude: -73.9154,
        total_orders: 57.6,
        neighborhood: 'Breezy Point/Rockaway Point',
        keywords:
            'Burger, Lobster, Salad, Beer, French, Caesar Salad, Hotel, Asian, American, Latin',
        annual_dol: 139.69,
        description:
            'American fare & cocktails served in an unfussy bayside setting with nautical accents & deck seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7138,
        store_longitude: -73.9151,
        total_orders: 10.82,
        neighborhood: 'Flushing/Ridgewood/Maspeth',
        keywords:
            'Steak, Burger, Hamburger, Salad, Dessert, American, Grilled Salmon, Delicious, French, French Onion Soup',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9887,
        total_orders: 6338.47,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Steak, Burger, Hamburger, Salad, Pizza, Vodka, Dessert, Beer, Spicy, Delicious',
        annual_dol: 6472.96,
        description: '"We are open for indoor and outdoor dining, delivery, and pick-up daily!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8427,
        store_longitude: -73.8859,
        total_orders: 28.7,
        neighborhood: 'Tremont/West Bronx',
        keywords:
            'Steak, Salad, Hamburger, Beer, Coffee, Spanish, Dominican, Puerto Rican, Latin, American',
        annual_dol: 70.44,
        description:
            'Relaxed dining spot featuring familiar Latin American & American meat & seafood dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -74.0016,
        total_orders: 1263.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Cheesecake, Salad, Beer, Dessert, Funky, American',
        annual_dol: 1374.25,
        description:
            'A comfy atmosphere attracts a laid-back neighborhood crowd to this pub famed for burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7209,
        store_longitude: -73.8434,
        total_orders: 1071.6,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Cornbread, Bbq, Chicken Wings, Cuban, Beer, American, Spicy, Spanish, French',
        annual_dol: 1258.26,
        description:
            'Hip counter-serve eatery featuring a menu of BBQ classics, plus pomme frites & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.775,
        store_longitude: -73.9536,
        total_orders: 1623.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Sushi, Salad, Ramen, Dessert, Flavorsome, Japanese, Spicy, Organic, Asian, Homemade',
        annual_dol: 1657.92,
        description:
            'Hip Japanese place for ramen & izakaya-style small plates with an adjoining cocktail & dessert bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7357,
        store_longitude: -74.0058,
        total_orders: 2663.62,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Salad, Italian, Martini, American, French, Cozy, Eclectic, Seasonal, Delicious',
        annual_dol: 2777.55,
        description:
            'Neighborhood spot with an old-school feel serving updated takes on NYC dining staples.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8379,
        store_longitude: -73.835,
        total_orders: 0,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords: 'Lobster, Steak, Sushi, Mexican, Delicious, Bbq, Thai, Sweet, Casual, Local',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7861,
        store_longitude: -73.9726,
        total_orders: 554.77,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Coffee, Organic, French Toast, French, American, Rum, Sweet, Sangria, Traditional',
        annual_dol: 573.27,
        description:
            'Locally sourced ingredients feature in traditional American comfort foods & house-baked treats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8506,
        store_longitude: -73.8677,
        total_orders: 59.89,
        neighborhood: 'Van Nest',
        keywords: 'Casual, Great',
        annual_dol: 244.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7091,
        store_longitude: -73.907,
        total_orders: 623.93,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Wine, Cozy, Local, Casual, Great',
        annual_dol: 650.98,
        description:
            'This no-nonsense hangout with nautical decor offers a full bar with tropical drinks & a pool table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7084,
        store_longitude: -73.6825,
        total_orders: 4885.05,
        neighborhood: '',
        keywords:
            'German, Burger, Beer, French, Hungarian, Polished, Asian, Casual, Traditional, Authentic',
        annual_dol: 19968.46,
        description:
            'Authentic German cuisine, live music & festivals draw crowds to this historic eatery & beer garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.655,
        store_longitude: -73.9531,
        total_orders: 79.05,
        neighborhood: 'Prospect Lefferts Gardens/Little Haiti',
        keywords:
            'Vegetarian, Hamburger, Pizza, Korean, Wine, Delicious, Bourbon, American, Israeli, Clean',
        annual_dol: 143.66,
        description:
            'Laid-back watering hole with sports on TV & a kosher lineup of burgers, rice bowls & poutine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6542,
        store_longitude: -73.953,
        total_orders: 143.62,
        neighborhood: 'Little Haiti',
        keywords: 'Beer, Zombie, Rum, Delicious, Eclectic, Fun, Fusion, Yummy, Brazilian, Colorful',
        annual_dol: 242.67,
        description:
            'Funky, convivial bar with a courtyard garden offering unique cocktails, draft beer & finger foods.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -73.9517,
        total_orders: 803.53,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Burger, Mexican, Salad, Beer, Margarita, Nachos, Casual, Hip, Cool, Great',
        annual_dol: 2820.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6853,
        store_longitude: -73.9947,
        total_orders: 16.59,
        neighborhood: 'Cobble Hill',
        keywords:
            'Burger, Steak, Vegetarian, Meatball, Tasty, Mediterranean, Modern, Cozy, Perfect, Russian',
        annual_dol: 18.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6133,
        store_longitude: -74.0653,
        total_orders: 528.48,
        neighborhood: 'Rosebank',
        keywords:
            'Tequila, Wine, Whiskey, Italian, Dessert, Donut, Savory, Meatball, Eclectic, American',
        annual_dol: 989.24,
        description:
            'Eclectic New American cuisine is offered at this Michelin-rated bistro with cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8523,
        store_longitude: -73.9348,
        total_orders: 20.28,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Cheesecake, Burger, Chocolate Mousse, Coffee, Modern, Dessert, Beer, Dominican, American, Spanish',
        annual_dol: 82.89,
        description: '"Something nice will be added here"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.831,
        total_orders: 593.03,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Hotel, Coffee, Chinese, Stylish, Asian, Modern, Korean, Contemporary, Traditional, Rooftop',
        annual_dol: 708.41,
        description:
            'The stylish rooms with Asian-inspired decor have free WiFi, plus flat-screens, and work areas with desks and ergonomic chairs. They also have minifridges and coffeemakers. Club rooms provide access to a lounge with perks such as evening appetizers. Kids stay at no charge with an adult.An airport shuttle is complimentary. Amenities include a restaurant/bar with an Asian-fusion menu, a lobby lounge, and a rooftop terrace, as well as a gym. There are 14 meeting rooms and a 24/7 business center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8863,
        store_longitude: -73.9096,
        total_orders: 681.19,
        neighborhood: 'Riverdale/West Bronx',
        keywords:
            'Pizza, Italian, Gluten Free, Meatball, Dessert, Wine, Premium, Clean, Friendly, Fresh',
        annual_dol: 695.65,
        description:
            'New York–style pizzas & other Italian comfort fare served in a down-to-earth eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.973,
        total_orders: 18.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Polished, American, Classic, Casual, Great, Artistic',
        annual_dol: 73.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7126,
        store_longitude: -74.0082,
        total_orders: 3985.57,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Coffee, Tasty, Beer, Trendy, Martini, Delicious, Fun, Old Fashioned, Friendly',
        annual_dol: 4490.56,
        description:
            'Hip, vintage-inspired coffee & cocktail spot with a marble bar & vaulted ceilings in historic digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9704,
        total_orders: 2369.01,
        neighborhood: 'Lenox Hill',
        keywords:
            'Vegetarian, Chinese, Chilean, Flavorful, Glamorous, Asian, Luxurious, Premium, Legendary, Contemporary',
        annual_dol: 2896.72,
        description:
            'Sophisticated Beijing style cuisine near Fifth & Madison Avenues buzzing with a glamorous clientele.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6954,
        store_longitude: -73.9839,
        total_orders: 477.68,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Hotel, Stylish, Spacious, Great, Friendly, Rooftop',
        annual_dol: 556.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9807,
        total_orders: 4530.74,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Wine, Upscale, Whiskey, Beer, Ale, South American, Classy, American, Eclectic, Irish',
        annual_dol: 5412.22,
        description:
            'Vintage-style restaurant & bar offering comfort food, cocktails & an extensive whisky list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6893,
        store_longitude: -73.9689,
        total_orders: 1575.89,
        neighborhood: 'Clinton Hill',
        keywords:
            'Salad, Vegetarian, Tacos, Pizza, Coffee, Vietnamese, Delicious, Papaya Salad, Casual, Authentic',
        annual_dol: 1794.83,
        description:
            'Quaint, photo-lined cafe turning out Vietnamese classics & signature cocktails. Delivery available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.761,
        store_longitude: -73.9873,
        total_orders: 82.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Flavorful, Healthy, Juicy, Friendly, Brazilian, Traditional, Authentic, Casual',
        annual_dol: 376.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6845,
        store_longitude: -73.9502,
        total_orders: 309.61,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vodka, Beer, Gin, Rum, Coffee, Salad, Cozy, Awesome, Great, Friendly',
        annual_dol: 1265.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7087,
        store_longitude: -74.014,
        total_orders: 667.83,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Beer, Wine, Austrian, Organic, Mediterranean, Spicy, Homemade, Irish',
        annual_dol: 709.88,
        description: 'Austrian-Mediterranean food in a space with communal & banquette seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7493,
        store_longitude: -73.9842,
        total_orders: 666.05,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Champagne, Karaoke, Vodka, Beer, Martini, Cheesesteak, Gin, Steak, Rum, Vintage',
        annual_dol: 776.97,
        description:
            'Hip karaoke lounge with space-age decor, featuring private rooms, bar snacks, beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7985,
        store_longitude: -73.5963,
        total_orders: 1083.48,
        neighborhood: '',
        keywords:
            'Burger, Salad, Golfclub, Caesar Wrap, Gracious, Warm, Picturesque, Spectacular, Awesome, Memorable',
        annual_dol: 1397.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.724,
        store_longitude: -74.0031,
        total_orders: 3389.12,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Upscale, Trendy, Hotel, Stylish, Vodka, Coffee, Legendary, Italian, Premium, Sleek',
        annual_dol: 3597.28,
        description:
            "Center, an iconic skyscraper with an observation deck.Featuring Harland Miller artwork, high-thread-count sheets and marble bathrooms, the posh, warmly decorated rooms come with flat-screen TVs and minibars; some have sofas. Airy suites provide free Wi-Fi, living rooms and balconies; 1 adds a rooftop terrace and a fireplace.A haute French restaurant has a terrace. There's also a sophisticated bar with a fireplace. A seasonal rooftop bar offers city views.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.998,
        total_orders: 2774.3,
        neighborhood: 'Nolita',
        keywords:
            'Mexican, Steak, Tequila, Vegetarian, Gluten Free, American, Rich, Latin, Authentic, Friendly',
        annual_dol: 3172.72,
        description:
            'Sceney Mexican spot with no-frills taqueria, cafe & exclusive basement brasserie/tequila bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7483,
        store_longitude: -73.9555,
        total_orders: 19557.25,
        neighborhood: 'LIC/Hunters Point',
        keywords: 'Mexican, Burger, Gourmet, Peruvian, Latin, Hotel, Tasty, American, Modern, Fine',
        annual_dol: 20032.15,
        description:
            'Modern restaurant & lounge featuring cocktails, gourmet Latin-inspired eats & expansive water views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6558,
        store_longitude: -73.6719,
        total_orders: 1165.26,
        neighborhood: '',
        keywords: 'Burger, Beer, Wine, Rustic, American, Relaxed, Casual, Local, Great, Signature',
        annual_dol: 1200.52,
        description:
            'Local pub with a rustic interior offering hearty food, rotating tap beers, TVs & weekend music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7286,
        store_longitude: -73.6355,
        total_orders: 2801.38,
        neighborhood: '',
        keywords:
            'Steak, Burger, Sushi, Salad, Wine, Italian, Dessert, Champagne, Mimosa, Delicious',
        annual_dol: 3276.06,
        description:
            '"Newly opened Primehouse Steaks & Seafood is located in the heart of Garden City. Our menu features prime and aged steaks, fresh seafood, raw bar & top quality sushi. Our creative New American entrees, salads & homemade desserts round out an impressive menu that is sure to please any palate. Enjoy a great meal complimented by our friendly and professional service during our nightly dinner, weekday lunch or Sunday brunch. We also offers full service catering for all occasions with private dining areas perfect for a corporate event or momentous celebration."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -74.0039,
        total_orders: 2490.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Pizza, Italian, Gourmet, Delicious, Stylish, Trendy, French Toast, Cozy, Rustic',
        annual_dol: 2607.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -74.0088,
        total_orders: 1120.93,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Italian, Spicy, Rustic, Californian, Warm, Seasonal, American, Friendly, Casual',
        annual_dol: 1144.72,
        description:
            'Revival of an NYC standby serving vibrant Italian plates in a light-filled space with large windows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6643,
        store_longitude: -73.6599,
        total_orders: 2299.97,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Wine, Beer, French Toast, Bbq, American, French, Delicious, Relaxed',
        annual_dol: 2352.92,
        description:
            'Warm, relaxed stop for American fare like burgers, seafood & brunch staples, plus wine & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7911,
        store_longitude: -73.9741,
        total_orders: 1812.49,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Salad, Chicken Parmigiana, Pizza, Italian, Dessert, Bread Pudding, Wine, Martini, Vodka',
        annual_dol: 3195.92,
        description:
            'Low-key eatery offering a big menu of pizza & Italian classics, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7048,
        store_longitude: -73.9113,
        total_orders: 58.02,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Salad, Mexican, Beer, Dessert, Dominican, Ecuadorian, Latin',
        annual_dol: 237.16,
        description:
            'This casual eatery with a varied menu of Latin American favorites specializes in seafood dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.9173,
        total_orders: 46.09,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Spicy, Korean, Delicious, Savory, Juicy, Asian, Fried Chicken, Crisp, Bright',
        annual_dol: 188.4,
        description:
            '"Mad For Chicken has been serving up our acclaimed Korean Style Fried Chicken since 2005. Our mission is to exceed the fried chicken dining culture expectations one wing at a time, by delivering our unparalleled crisp and savory hand brushed chicken to the world."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7459,
        store_longitude: -73.9571,
        total_orders: 11080.16,
        neighborhood: 'LIC/Hunters Point',
        keywords:
            'Champagne, Wine, Lobster, Spicy, Beer, Lobster Bisque, Delicious, Creamy, Premium, American',
        annual_dol: 21503.55,
        description:
            'Cozy, chic hub with wines on tap & craft brews, plus artisanal cheeses, charcuterie & other nibbles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7235,
        store_longitude: -73.9963,
        total_orders: 97.3,
        neighborhood: 'Nolita',
        keywords: 'Burger, Sushi, Pizza, Tequila, Wine, Margarita, Spicy, Italian, Stylish, Gin',
        annual_dol: 397.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.985,
        total_orders: 676.39,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lasagna, Italian, Salad, Wine, Hotel, Ale, Classy, Southern, Traditional, Casual',
        annual_dol: 754.24,
        description:
            'Festive downstairs restaurant with an old-school setting & traditional Southern Italian cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9837,
        total_orders: 239.84,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Mexican, Shrimp Cocktail, Salad, American, Caesar Salad, Legendary, Classic, Comfortable, Great, Signature',
        annual_dol: 980.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7219,
        store_longitude: -73.9885,
        total_orders: 1848.89,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Pizza, Tasty, Irish, Organic, Cozy, Local, Relaxed, Casual',
        annual_dol: 1893.79,
        description:
            'Relaxed bar with cozy booths & mod decor popular for its generous happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -73.9894,
        total_orders: 260.34,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Gluten Free, Greek, Homemade, Sweet, American, Greek Salad, Casual, Great, Diverse',
        annual_dol: 398.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7225,
        store_longitude: -73.9926,
        total_orders: 34.51,
        neighborhood: 'Bowery',
        keywords:
            'Wine, Italian, Homemade, Friendly, Seasonal, Casual, Modern, Classic, Fresh, Great',
        annual_dol: 141.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7248,
        store_longitude: -73.9964,
        total_orders: 98.71,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Coffee, Delicious, Eclectic, Premium, Awesome, Great, Casual, Cultural',
        annual_dol: 403.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7426,
        store_longitude: -74.0002,
        total_orders: 5311.58,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Upscale, Delicious, Gin, Fun, Colorful, Playful, Rum, Asian, Bright',
        annual_dol: 5780.37,
        description:
            'Tropical takes on classic cocktails are paired with Southeast Asian & California-influenced fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.9963,
        total_orders: 211.57,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Cheesecake, Pizza, Italian, Salad, Wine, Dessert, Gourmet, Martini, Beer, Upscale',
        annual_dol: 878.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6738,
        store_longitude: -73.957,
        total_orders: 9550.32,
        neighborhood: 'Crown Heights',
        keywords:
            'Wine, Beer, Whiskey, Delicious, Spacious, Vibrant, Awesome, Friendly, Great, Fresh',
        annual_dol: 9752.97,
        description:
            'Cavernous bar with exposed brick & Prohibition-style furnishings serving craft cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7129,
        store_longitude: -73.9576,
        total_orders: 108.19,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Spicy, Delicious, Beer, Chinese, Juicy, Homemade, Fabulous, Ramen, Asian, Fresh',
        annual_dol: 147.28,
        description:
            'Low-key Williamsburg Asian restaurant with garden seating & an emphasis on dim sum.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.984,
        total_orders: 687.87,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Burger, Tacos, Beer, Mimosa, Tasty, French Toast, Warm, American, Irish, French',
        annual_dol: 694.63,
        description:
            'Old-fashioned pub offering a wide variety of beers, plus lunch & dinner menus & a late bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.9264,
        total_orders: 111.77,
        neighborhood: 'Astoria/LIC',
        keywords: 'Vegetarian, Burger, Salad, Wine, Beer, Brazilian, Casual, Local, Great',
        annual_dol: 456.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7106,
        store_longitude: -73.9653,
        total_orders: 44.04,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Burger, Steak, Salad, Beer, Coffee, Ale, Delicious, Cozy, Creamy, American',
        annual_dol: 180.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7442,
        store_longitude: -73.9857,
        total_orders: 5.54,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Salad, Kebab, Vegetarian, Coffee, Lebanese, Champagne, Tasty, Mediterranean, French, Upscale',
        annual_dol: 22.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8828,
        store_longitude: -73.6304,
        total_orders: 2713.32,
        neighborhood: '',
        keywords:
            'Steak, Beer, Hotel, Bbq, Upscale, Elegant, Ribeye Steak, Contemporary, Cool, Casual',
        annual_dol: 3192.69,
        description:
            "Elegant rooms are set in contemporary wings of the historic manor and are equipped with free Wi-Fi, flat-screen TVs, iPod docks and coffeemakers. Suites add sitting rooms, soaking tubs and minifridges.There's an upscale dining room and a casual pub, as well as a spa, indoor and outdoor pools, a hot tub, a sauna and steam rooms. A 5-mile walking trail, plus tennis, racquetball and basketball courts are also available. Conference facilities include 25,000 sq ft of meeting space and a 24-hour business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8645,
        store_longitude: -73.6316,
        total_orders: 340.28,
        neighborhood: '',
        keywords:
            'Steak, Pizza, Chicken Parmigiana, Cheesesteak, Salad, Italian, Meatball, Wine, Beer, French Onion Soup',
        annual_dol: 380.1,
        description:
            'Casual eatery featuring an eclectic menu of pizza, pasta, quesadillas, steak, Italian mains & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.9973,
        total_orders: 2818.87,
        neighborhood: 'Little Italy',
        keywords:
            'Coffee, Italian, Pizza, Delicious, Funky, Rustic, Homemade, Friendly, Fresh, Classic',
        annual_dol: 2941.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -74.0072,
        total_orders: 2849.88,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Beer, Wine, Juicy, French, Irish, American, Fabulous, Cozy, Ample',
        annual_dol: 2914.53,
        description:
            'Low-key neighborhood joint serving basic American bar food in a dark-wood-accented dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7057,
        store_longitude: -73.9218,
        total_orders: 1029.27,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Cajun, Beer, Wine, Coffee, Delicious, French, Cozy, Creole, Asian',
        annual_dol: 1076.27,
        description:
            'This small, full-service coffee shop serves cocktails & New Orleans-style Southern fare at night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7093,
        store_longitude: -73.9235,
        total_orders: 3208.34,
        neighborhood: 'East Williamsburg',
        keywords: 'Hotel, Rooftop, Beer, Coffee, Great, Creative',
        annual_dol: 3572.27,
        description:
            'Massive venue featuring several spaces (including a roof terrace) for live music & DJ nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8035,
        store_longitude: -73.9558,
        total_orders: 1845.53,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Steak, Salad, Seasonal, American, Stylish, Homemade, Cozy, Casual, Sweet',
        annual_dol: 2130.1,
        description:
            'Seasonal New American small plates & cocktails in a stylish, vintage-inspired space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6579,
        store_longitude: -73.9606,
        total_orders: 2605.98,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Vegetarian, Hotdog, Salad, Wine, Coffee, Beer, Dessert, Green Curry, Tasty, Italian',
        annual_dol: 3388,
        description:
            'Seasonal American restaurant that plays on culinary nostalgia with an open kitchen & wooden booths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9983,
        total_orders: 8100.75,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Steak, Lobster, Vegetarian, Vegan, Salad, Wine, Gluten Free, French, Delicious, Romantic',
        annual_dol: 8784.87,
        description:
            'Iconic French brasserie with steak frites, brunch & pastries in a classy space with red banquettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -74.0028,
        total_orders: 1440.91,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Wine, Italian, Dessert, Upscale, Impeccable, Classy, Amazing, Classic, Wonderful',
        annual_dol: 1546.04,
        description:
            'Upscale scene where an international crowd tucks into Italian food chased with Bellinis.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.9776,
        total_orders: 3024.12,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, French, Upscale, Contemporary, Hotel, Modern, Classy, Casual, Seasonal, Traditional',
        annual_dol: 4472.3,
        description:
            "Alain Ducasse's upscale French bistro, modeled after the Paris original, serves up classic fare.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9813,
        total_orders: 10.82,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Tacos, Mexican, Tasty, Margarita, Salad, Spicy, Delicious, Yummy, Fun, Fresh',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.82,
        store_longitude: -73.9586,
        total_orders: 220.12,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Chinese, Flavorful, Asian, Cozy, Caribbean, Urban, Hip, Premium, Fusion, Intimate',
        annual_dol: 899.78,
        description:
            'Buzzing destination with an arty urban vibe serving classic Asian comfort food, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.987,
        total_orders: 1396.32,
        neighborhood: 'Lower East Side',
        keywords: 'Steak, Salad, Wine, Upscale, Organic, Gin, French, Martini, Japanese, Sleek',
        annual_dol: 2083.81,
        description:
            'Boozy brunches, happy hours & New American dinners in a warehouse-like space with balcony seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7208,
        store_longitude: -73.9878,
        total_orders: 2015.36,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Hotdog, Vodka, Wine, Gin, Fruity, Rum, American, Elegant, Hip',
        annual_dol: 2150.7,
        description:
            'A cocktail spot with loungey seating & occasional basement shows in the former Cake Shop space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -73.9891,
        total_orders: 34.75,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Wine, Beer, Ale, Dessert, Seasonal, American, Contemporary, Casual, Artisanal',
        annual_dol: 142.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.9985,
        total_orders: 2299.18,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Italian, Intimate, Urban, Casual, Lively, Chill, Great',
        annual_dol: 2347.96,
        description:
            'Energetic gay bar offering DJs, drag shows & more in an industrial-chic atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 809.08,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Italian, Delicious, Beer, Elegant, French, Legendary, Modern, Fun, Outstanding',
        annual_dol: 3307.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7378,
        store_longitude: -73.9975,
        total_orders: 42.95,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Salad, Steak, Coffee, Friendly, Casual, Club Sandwich, Local, Mint Julep, Fresh',
        annual_dol: 175.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6836,
        store_longitude: -73.967,
        total_orders: 1464.79,
        neighborhood: 'Clinton Hill',
        keywords: 'Wine, Stylish, Cozy, Beer, Quaint, Warm, Friendly, Sweet, Casual, Great',
        annual_dol: 1495.87,
        description:
            'Cocktails, craft beer & wine served in a stylish locale with big windows & distressed-wood decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.7499,
        total_orders: 554.78,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Sushi, Salad, Steak, Tasty, Japanese, Spicy, Sashimi, Korean, Asian, Shabu-Shabu',
        annual_dol: 815.18,
        description:
            'Contemporary Asian buffet with a generous selection for a set price, including sushi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 299.14,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Lobster, Gluten Free, Seafood Paella, Mexican, Wine, Italian, French, Prosecco, Bellini, Perfect',
        annual_dol: 1222.8,
        description:
            'Casual outdoor restaurant dishing up seafood classics such as lobster rolls, plus cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7171,
        store_longitude: -73.9547,
        total_orders: 1451.06,
        neighborhood: 'Williamsburg',
        keywords: 'Steak, Tequila, Whiskey, Pizza, Salad, Beer, Margarita, Bourbon, Ale, Irish',
        annual_dol: 1565.01,
        description:
            'Utilitarian sports bar pairing whiskies & other drinks with inventive pub grub, TVs & dancing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -74.0063,
        total_orders: 7214.84,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Shrimp Cocktail, Coffee, Irish, American, Legendary, Seasonal, Western, Casual, Cool',
        annual_dol: 25471.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6807,
        store_longitude: -73.9632,
        total_orders: 20.97,
        neighborhood: 'Crown Heights/Clinton Hill',
        keywords: 'Authentic, Perfect, Casual, Cool, Classic, Eclectic',
        annual_dol: 85.73,
        description:
            "\"Manado's Jazz Lounge is Brooklyn's premier spot to vibe in our lounge with good music and drinks. We cater to a mature audience. We also feature events such as jam sessions, poetry and spoken word, karaoke, and other exciting entertainment. Our lounge is available for private events. Located in Prospect Heights, just a few blocks from the Barclay Center. Manado's Jazz Lounge presents a private lounge experience with Positive Brooklyn vibes, eclectic variety of music, and an authentic Jazz ambiance. Whether you are looking for a casual night out on the town, an exclusive event space, or an opportunity to meet with neighbors and friends, Manado's will provide the space to unwind. We look forward to your visit!\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9557,
        total_orders: 871.14,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Spacious, Fun, Friendly, Eclectic, Great, Casual',
        annual_dol: 3560.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7502,
        store_longitude: -73.9776,
        total_orders: 671.22,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Luxurious, Elegant, Hotel, Modern, Friendly, Excellent, Contemporary',
        annual_dol: 2356.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.9234,
        total_orders: 337.58,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tequila, Burger, Vodka, Wine, Coffee, Ale, Dessert, Gin, Trendy, Upscale',
        annual_dol: 352.22,
        description:
            'Greek restaurant serving up marinated rotisserie meats & whole fish in an elevated setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7106,
        store_longitude: -73.9679,
        total_orders: 360.28,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Garlic Bread, Vodka, Pizza, Wine, Salad, Italian, Beer, Korean, Meatball, American',
        annual_dol: 400.84,
        description:
            'Wood-fired pizzas, meats & veggies plus housemade pastas & other Italian fare in a warm space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6586,
        store_longitude: -73.6457,
        total_orders: 2018.97,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Lobster Bisque, Salad, Beer, French Onion Soup, French, Ale, American, Contemporary',
        annual_dol: 2071.2,
        description:
            'Steakhouse serving high-end cuts in a contemporary space with a fireplace, a bar & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6312,
        store_longitude: -73.9188,
        total_orders: 42.74,
        neighborhood: 'Bergen Beach/Flatlands',
        keywords:
            'Vegan, Coffee, Dessert, Warm, Cozy, Caribbean, Jamaican, American, Traditional, Retro',
        annual_dol: 161.83,
        description:
            "Retro diner dating back to the '70s known for a wide range of American fare served morning to night.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.565,
        total_orders: 96.54,
        neighborhood: '',
        keywords: 'Mexican, Friendly, Fun, Italian, Casual, Asian',
        annual_dol: 102.73,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8574,
        store_longitude: -73.8857,
        total_orders: 11.39,
        neighborhood: 'Belmont/West Bronx',
        keywords: 'Burger, Beer, Steak, Salad, Wine, Ale, Cozy, Eclectic, American, Sweet',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6335,
        store_longitude: -74.0066,
        total_orders: 775.1,
        neighborhood: 'Borough Park',
        keywords: 'Karaoke, Delicious, Fun, Sushi, Chinese, Retro, Asian, Premium, Casual',
        annual_dol: 1085.87,
        description:
            'This expansive karaoke bar offers Asian small plates & noodle dishes, plus bottle service.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5984,
        store_longitude: -74.0809,
        total_orders: 41.8,
        neighborhood: 'Arrochar',
        keywords:
            'Tequila, Mexican, Margarita, Spanish, Caribbean, Hotel, African, Romantic, Yummy, Casual',
        annual_dol: 170.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7274,
        store_longitude: -73.9925,
        total_orders: 3153.6,
        neighborhood: 'NoHo',
        keywords: 'Burger, Pizza, Beer, Salad, Whiskey, Irish, Cozy, Classy, Friendly, Intimate',
        annual_dol: 4825.6,
        description: 'Low-key local Irish bar featuring a wide variety of draft & bottled beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8277,
        store_longitude: -73.8502,
        total_orders: 282.99,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Burger, Coffee, Cuban, French Toast, American, French, Warm, Puerto Rican, Casual, Classic',
        annual_dol: 409.56,
        description:
            'Casual American diner offering huge menu, including breakfast, burgers & sandwiches, 24 hours a day.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.9898,
        total_orders: 1971.94,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Vegan, Tacos, Tequila, Mexican, Vegetarian, Margarita, Coffee, Wine, Nachos, Organic',
        annual_dol: 2243.57,
        description:
            'Casual, contemporary vegan spot for tacos & other Mexican plates, plus cocktails & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7279,
        store_longitude: -73.9856,
        total_orders: 135.34,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tacos, Mexican, Margarita, Vegan, Quesadillas, Spicy, Funky, Delicious, Stylish, Rich',
        annual_dol: 147.5,
        description:
            'Mexican food gets some refined twists at this stylish cantina with streetside seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.9718,
        total_orders: 484.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Wine, Flavorful, Salad, Dessert, Beer, Upscale, Trendy, Delicious, Elegant',
        annual_dol: 539.5,
        description:
            'Cozy kosher eatery offering a seasonal menu of upscale meat & seafood dishes, plus cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9719,
        total_orders: 208.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Chicken Parmigiana, Vodka, Italian, New-York-Style, Wine, Spacious, Relaxed, Fresh, Local',
        annual_dol: 217.06,
        description:
            'Local pizzeria chain serving brick-oven pies, pasta & calzones in a relaxed setting since 1933.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6858,
        store_longitude: -73.9119,
        total_orders: 388.72,
        neighborhood: 'Bushwick',
        keywords: 'Steak, Salad, Pizza, Margarita, Caesar Salad, Casual, Great',
        annual_dol: 445.85,
        description: '"Bar & grill in Brooklyn, NY."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9742,
        total_orders: 217.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Coffee, Canadian, American, Hotel, Fresh',
        annual_dol: 356.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6739,
        store_longitude: -73.9527,
        total_orders: 657.81,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Steak, Tacos, Salad, Tasty, Beer, Dessert, Spicy, Upscale, American',
        annual_dol: 657.81,
        description:
            'Cozy, chill hangout with elevated American bites & patio seating, plus sports viewing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7412,
        store_longitude: -73.9854,
        total_orders: 276.11,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Burger, Salad, Steak, Vegetarian, Tasty, Dessert, Wine, Coffee, Healthful, Beer',
        annual_dol: 356.05,
        description: 'Modern restaurant offering organic, vegetarian-focused & gluten-free menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7424,
        store_longitude: -73.9898,
        total_orders: 1799.31,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Vodka, Tasty, Wine, Gin, Stylish, New-York-Style, Delicious, Italian, Hotel, Fun',
        annual_dol: 6476.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6926,
        store_longitude: -73.9886,
        total_orders: 236.67,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Tequila, Tacos, Mexican, Beer, Wine, Peruvian, Delicious, Rustic, Fun, Friendly',
        annual_dol: 830.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7393,
        store_longitude: -73.9895,
        total_orders: 25.69,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Coffee, Australian, Perfect, Excellent, Intimate, Casual, Incredible, Local',
        annual_dol: 105,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7758,
        store_longitude: -73.91,
        total_orders: 1171.48,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Burger, Hamburger, Steak, Cajun, Gourmet, Milkshake, Bbq, Dessert, American, Jamaican',
        annual_dol: 1298.45,
        description:
            'Brick corner restaurant serving gourmet burgers & shakes, with a bar with TV screens.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -74.0001,
        total_orders: 965.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Zombie, Beer, Wine, Tasty, Meatball, Spicy, Painkiller, Breakfast Burrito, Rum, Irish',
        annual_dol: 985.71,
        description:
            'Gastropub with nautical touches, offering a seafood-focused menu & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.8847,
        total_orders: 171.74,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Coffee, Dessert, Rum, Sweet, Casual, Pure, Indian, Gyro',
        annual_dol: 721.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5241,
        store_longitude: -74.2346,
        total_orders: 370.93,
        neighborhood: 'Charleston',
        keywords:
            'Sushi, Cheesecake, Salad, Gluten Free, Wine, Japanese, Spicy, Beer, Delicious, Korean',
        annual_dol: 491.06,
        description:
            'Buzzing eatery & lounge offering Japanese dishes with a French twist, plus cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -73.988,
        total_orders: 5725.34,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Mediterranean, Greek, Shawarma, Spectacular, Friendly, Great, Fine, Signature, Hummus',
        annual_dol: 7016.57,
        description:
            '"Located in the heart of Gramercy in New York City at 20th Street and Park Avenue South, Barbounia’s atmosphere and design, which complement and attract a fashion, music and entertainment clientele, takes you off the busy streets of Manhattan and transcends you to the middle of the Mediterranean. With a spectacular open kitchen, tall arched ceilings divided by Moorish columns, large wooden communal tables, and pillowed banquettes; you feel the magnificent energy emanating from the kitchen, staff and patrons."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7364,
        store_longitude: -73.9921,
        total_orders: 6690.19,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Steak, Wine, Coffee, Upscale, Salad, Italian, Ale, Beer, American, French',
        annual_dol: 6851.6,
        description:
            'Intimate tin-ceilinged cocktail spot on a Union Square side street popular with a post-work crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6079,
        store_longitude: -73.973,
        total_orders: 48.59,
        neighborhood: 'Gravesend/Midwood',
        keywords:
            'Steak, Salad, Chilean, Peruvian, Delicious, Greek, Latin, Hotel, Premium, American',
        annual_dol: 148.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7347,
        store_longitude: -73.9931,
        total_orders: 153.88,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Gourmet, Spicy, Australian, Indian, Eclectic, Tandoori Chicken, Modern, Traditional, Unforgettable, Paneer Tikka',
        annual_dol: 628.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7293,
        store_longitude: -73.9861,
        total_orders: 85.01,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegetarian, Coffee, Wine, Beer, Vietnamese, Ale, Dessert, Meatball, Martini, Bbq',
        annual_dol: 347.48,
        description:
            'Narrow, casual spot for long-simmered pho, rice & noodle dishes, with veggie options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7312,
        store_longitude: -73.9934,
        total_orders: 39.98,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Mexican, Local, Casual, Delicious, Fresh',
        annual_dol: 255.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6823,
        store_longitude: -74.0074,
        total_orders: 11.92,
        neighborhood: 'Red Hook',
        keywords: 'Martini, Indian, Complex, Great',
        annual_dol: 341.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5878,
        store_longitude: -73.9551,
        total_orders: 676.43,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Burger, Vegan, Steak, Salad, Kebab, Coffee, Dessert, Bbq, Delicious, Rich',
        annual_dol: 753.64,
        description:
            'Spacious bi-level cafe offering New American fare, cocktails & hookahs in a contemporary interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.9816,
        total_orders: 1389.92,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Sushi, Dessert, Japanese, Delicious, Homemade, Warm, Elegant, Intimate, Fancy, Seasonal',
        annual_dol: 1482.31,
        description:
            'High-end branch of a Tokyo eatery offering omakase-only sushi in a pared-down yet warm setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8844,
        store_longitude: -73.9004,
        total_orders: 121.34,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Mexican, Burger, Salad, Organic, Healthy, Local, Casual, Innovative',
        annual_dol: 557.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7676,
        store_longitude: -73.832,
        total_orders: 3.59,
        neighborhood: 'Flushing',
        keywords:
            'Karaoke, Tasty, Korean, Chinese, Phenomenal, Cozy, Caribbean, Clean, Premium, Casual',
        annual_dol: 14.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6719,
        store_longitude: -73.8428,
        total_orders: 141.28,
        neighborhood: 'Ozone Park',
        keywords:
            'Pizza, Italian, Salad, Meatball, Vodka, Swiss, Caesar Salad, Thai, French, American',
        annual_dol: 144.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7977,
        store_longitude: -73.6674,
        total_orders: 38.11,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Pizza, Salad, Gluten Free, Mexican, Italian, Roast Beef, Delicious, French Toast',
        annual_dol: 40.65,
        description:
            'Modern eco-friendly pit stop for comfort food that hosts a varied menu in an airy, bi-level space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6896,
        store_longitude: -73.9881,
        total_orders: 3654.65,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Coffee, Hotel, American, Vibrant, Great',
        annual_dol: 3734.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6842,
        store_longitude: -73.9919,
        total_orders: 635.78,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Steak, Vegetarian, Mexican, Tequila, Argentinian, Tex-Mex, Rustic, Organic, Cuban, Thai',
        annual_dol: 780.08,
        description:
            'Inventive cocktails made with Latin spirits plus Mexican bites in a brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6857,
        store_longitude: -73.9945,
        total_orders: 301.15,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Lebanese, Beer, Thai, Legendary, Middle Eastern, Authentic, Impressive, Casual',
        annual_dol: 1231,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.74,
        store_longitude: -73.9825,
        total_orders: 382.52,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Salad, Wine, Beer, Irish, Delicious, American, Casual, Authentic, Perfect',
        annual_dol: 390.64,
        description:
            "Woodsy, old-timey Irish pub with darts & TVs serving classic entrees like shepherd's pie.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.9898,
        total_orders: 916.12,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Hamburger, Salad, Mexican, Chinese, Tasty, American, Asian, Casual, Local',
        annual_dol: 3216.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6742,
        store_longitude: -73.7097,
        total_orders: 14.55,
        neighborhood: '',
        keywords: 'Beer, Gourmet, Sweet, Fresh, Local, Casual, Great, Wonderful, Coleslaw',
        annual_dol: 42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.8935,
        total_orders: 154.68,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Wine, Beer, Ale, Irish, Cozy, Friendly, Authentic, American, Casual, Great',
        annual_dol: 194.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7537,
        store_longitude: -73.9959,
        total_orders: 673.91,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Vegetarian, Gluten Free, Beer, Wine, Relaxed, Quesadillas, Casual, American, Perfect',
        annual_dol: 809.26,
        description:
            'Sliders & other American gastropub bites served in a relaxed space with exposed brick & many TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -73.9872,
        total_orders: 3371.72,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Steak, Lobster, Burger, Shrimp Cocktail, Margarita, Upscale, French, Swiss, Premium, American',
        annual_dol: 3443.26,
        description:
            '"Welcome to Rick’s Cabaret & Steakhouse - New York City’s #1 gentlemen’s club. Rick’s Cabaret New York is conveniently located in the heart of midtown Manhattan between New York’s Empire State Building and the world famous Madison Square Garden."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -74.0041,
        total_orders: 315.35,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Hamburger, Beer, Chicken Caesar Salad, Salad, Gourmet, Milkshake, Texan, American, Caesar Salad',
        annual_dol: 351.39,
        description:
            'Creative gourmet burgers, modern sides, shakes & draft beers served in a small, casually hip space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7156,
        store_longitude: -73.9964,
        total_orders: 617.95,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Beer, Tasty, Ale, Spacious, Chinese, Bright, Amazing, Great, Asian, Casual',
        annual_dol: 2525.99,
        description:
            'Popular Chinatown eatery famed for its soup dumplings and other Shanghai dishes in a spare setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5737,
        store_longitude: -74.1176,
        total_orders: 613.83,
        neighborhood: 'New Dorp',
        keywords: 'Burger, Steak, Tequila, Sushi, Salad, Wine, Delicious, Japanese, Ale, Fun',
        annual_dol: 626.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.788,
        store_longitude: -73.6787,
        total_orders: 284.01,
        neighborhood: '',
        keywords: 'Casual, Perfect, Local, Tradition',
        annual_dol: 433.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7713,
        store_longitude: -73.9536,
        total_orders: 210.94,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Italian, Pizza, Dessert, Tasty, Turkish, Delicious, Homemade, Asian, Romantic, Classy',
        annual_dol: 229.89,
        description:
            'Modestly appointed neighborhood red-sauce Italian restaurant with an old-school feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6019,
        store_longitude: -73.6525,
        total_orders: 495.17,
        neighborhood: '',
        keywords:
            'Sushi, Steak, Tasty, Gluten Free, Dessert, Bread Pudding, Italian, Warm, Cozy, Chilean',
        annual_dol: 527.71,
        description:
            '"Your Own Private Party Space. Great for Any Occasions for Gatherings of 30 to 50 Guests. Available Monday thru Sunday for Private Parties Day or Evenings Available Sit down , Family Style Or Buffet All of our Menus are Customizable Book Your Next Party With Us! Email: david@chefs724.com Or Contact Us at: 516-867-0700 and leave your name and call back number for more information about our party and event packages."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.9212,
        total_orders: 1227.34,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Burger, Hamburger, Beer, Upscale, Wine, Ale, American, Premium, French, Fun',
        annual_dol: 1479.76,
        description:
            'Neighborhood hangout with a leafy patio serving upscale bar food, craft beers, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9942,
        total_orders: 459.09,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Wine, Italian, Seasonal, Creative, Friendly',
        annual_dol: 1876.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.9211,
        total_orders: 679.3,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Beer, Ale, Fun, Irish, Friendly, Great, Modern, Local, Casual',
        annual_dol: 708.75,
        description:
            '"Modern bar with Large Outdoor Patio available for Events. Friendly atmosphere with a wide variety of IPA’s, Draft Beers and not to mention our Cocktail menu."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6555,
        store_longitude: -73.6481,
        total_orders: 1927.72,
        neighborhood: '',
        keywords:
            'Steak, Hamburger, Vegetarian, Italian, American, Homey, Ribeye Steak, Cozy, Classic, Authentic',
        annual_dol: 2015.37,
        description:
            'Huge portions of beef including the signature skirt steak in a homey setting with a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.974,
        total_orders: 7.13,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Wine, Shrimp Cocktail, Coffee, Italian, French Onion Soup, Hotel, French, Bbq, Caesar Salad',
        annual_dol: 29.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.701,
        store_longitude: -73.9134,
        total_orders: 182.45,
        neighborhood: 'Bushwick',
        keywords: 'American, Premium',
        annual_dol: 261.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7043,
        store_longitude: -73.9283,
        total_orders: 13.25,
        neighborhood: 'Bushwick',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Mimosa, American, Latin, Michelada, Local, Sangria',
        annual_dol: 54.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7327,
        store_longitude: -74.0041,
        total_orders: 886.13,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Vegetarian, Salad, French, Coffee, Polished, Delicious, Clean, Casual, Intimate',
        annual_dol: 3355.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7412,
        store_longitude: -74.0063,
        total_orders: 1719.3,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Vegetarian, Coffee, Luxurious, Healthy, Friendly, French, Fabulous, American, Sweet, Great',
        annual_dol: 2601.77,
        description:
            'A sleek, bi-level cafe & restaurant concept operated by Lexus featuring rotating chefs & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 1168,
        neighborhood: '',
        keywords:
            'Vegan, Beer, Coffee, Healthy, Wholesome, Delicious, Polished, Natural, Classy, Handcrafted',
        annual_dol: 1973.57,
        description:
            'Relaxed, eco-chic chain serving health-conscious fare, including vegan options, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -74.0038,
        total_orders: 1347.8,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Vegan, Pizza, Gluten Free, Italian, Wine, Delicious, Cozy, Spicy, Friendly',
        annual_dol: 1490.92,
        description:
            'Italian trattoria serving traditional plates & apéritifs in a rustic, cozy space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7889,
        store_longitude: -73.9744,
        total_orders: 4282.66,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Lobster, Lobster Roll, Salad, Beer, Upscale, Ale, Thai, Sustainable, American, Hospitable',
        annual_dol: 4688.85,
        description:
            'Upscale-casual seafood spot with a Cape Cod vibe known for its lobster rolls and cheap oyster deal.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7008,
        store_longitude: -73.9083,
        total_orders: 962.6,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Cozy, Relaxed, Homey, Perfect, Cool, Lively, Eclectic, Great, Casual',
        annual_dol: 1004.33,
        description:
            'Lively hangout offering craft cocktails, draft brews & happy hour in a cozy space with game nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -73.659,
        total_orders: 1659.44,
        neighborhood: '',
        keywords:
            'Burger, Beer, Vodka, Roast Beef, Bbq, Delicious, American, Spacious, Friendly, Casual',
        annual_dol: 1694.65,
        description:
            'This sizable pool hall & sports bar provides burgers, wings & draft beer, plus DJ nights & leagues.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6979,
        store_longitude: -73.9053,
        total_orders: 1227.96,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Contemporary',
        annual_dol: 1625.65,
        description:
            '"NYC underground rock\'n\'roll club and party palace on the Bushwick/Ridgewood border! Music venue, bar, restaurant, dance club, courtyard, cocktail lounge, art gallery, performance space, cinema, and social club. Located between the Halsey L train stop and the Seneca M train stop. Follow our IG for all updates: @tveyenyc"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.589,
        store_longitude: -73.7976,
        total_orders: 82.47,
        neighborhood: 'Arverne by the Sea/Far Rockaway/Arverne',
        keywords:
            'Steak, Burger, Salad, Wine, Bbq, French, Beer, Mediterranean, Southern, Eclectic',
        annual_dol: 175.77,
        description:
            '"Bar Marseille--borrowing its name from the famed port city of Marseille in Southern France--celebrates the eclectic collection of flavors that is found along the French Riviera with cultural influences of North Africa, Italy, Greece, and Spain. The seaside restaurant, which encompasses indoor dining and outdoor patio and a rooftop deck pays tribute to Provençal cuisine paired with a robust wine and cocktail list. Bar Marseille features live music every Friday and Saturday, year round. Our roof deck opens for the summer in May."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7003,
        store_longitude: -73.9044,
        total_orders: 388.54,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Hotdog, Burger, Beer, Tequila, Wine, Ale, Swiss, American, Latin, Western',
        annual_dol: 1001.22,
        description: '"Neighborhood bar in Ridgewood. 56-53 Myrtle Avenue."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5736,
        store_longitude: -74.1159,
        total_orders: 193.47,
        neighborhood: 'New Dorp',
        keywords:
            'Tacos, Mexican, Steak, Tequila, Shrimp Cocktail, Delicious, Quesadillas, Tex-Mex, Fajitas, Enchiladas',
        annual_dol: 201.86,
        description:
            'Lively, sit-down outpost for Mexican standards like tacos, enchiladas & margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5786,
        store_longitude: -73.8493,
        total_orders: 25.69,
        neighborhood: 'Belle Harbor/Far Rockaway/Rockaway Park',
        keywords: 'Burger, Hotdog, Gourmet, French, American, Casual',
        annual_dol: 105,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6428,
        store_longitude: -73.9023,
        total_orders: 19.06,
        neighborhood: 'Canarsie',
        keywords: 'Burger, Pizza, Tacos, Steak, Italian, Beer, Wine, Japanese, American, Caribbean',
        annual_dol: 77.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6671,
        store_longitude: -73.7942,
        total_orders: 1786.96,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Coffee, Upscale, Stylish, Wine, Hotel, Spacious, Beer, Modern, Jamaican, Premium',
        annual_dol: 1824.88,
        description:
            "Contemporary-styled rooms offer free WiFi, desks with ergonomic chairs, flat-screen TVs with premium cable channels, minifridges, microwaves and coffeemakers. Suites add separate living areas and sofabeds.Social spaces include a living room-style lobby. The American restaurant serves breakfast, lunch and dinner as well as all-day drinks, including Starbucks coffee. There's also a business center with workstations and printers, and 4 meeting rooms. Other amenities include an exercise room and a free airport shuttle.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6721,
        store_longitude: -73.9769,
        total_orders: 13.13,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Mexican, Salad, Delicious, Crisp, Casual, Outstanding, Local, Great, Indian',
        annual_dol: 53.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.9591,
        total_orders: 360,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Vegetarian, Salad, Beer, Gluten Free, Mimosa, Margarita, Scotch, Gin, Ale',
        annual_dol: 504.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -73.9577,
        total_orders: 666.39,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Coffee, Gourmet, Martini, Spicy, American, Mediterranean, Paloma, Casual, Classic',
        annual_dol: 2252.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6439,
        store_longitude: -73.7818,
        total_orders: 36.9,
        neighborhood: 'Jamaica',
        keywords:
            'Wine, Luxurious, Korean, Classy, Premium, American, Sumptuous, British, Great, Casual',
        annual_dol: 150.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8541,
        store_longitude: -73.7908,
        total_orders: 1772.57,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Crab Cakes, Sushi, Shrimp Cocktail, Italian, Martini, Spicy, Wine, Spanish',
        annual_dol: 3018.14,
        description:
            'City Island classic since 1920 for seafood in a lively setting, with outdoor dining & marina views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8408,
        store_longitude: -73.7843,
        total_orders: 87.9,
        neighborhood: 'City Island',
        keywords:
            'Burger, Lobster, Steak, Cheesesteak, Pizza, Salad, Gourmet, Italian, Coffee, Delicious',
        annual_dol: 110.4,
        description:
            "\"Every memorable event starts with delicious food and great service. For more than 20 years, we've been perfecting the art of unforgettable entrées and exciting events. Whether you're in need of a first-class catering company or a banquet hall for your special day, we are here to make that day exceptional. Our staff is proud to offer each and every one of our clients a personalized catering experience. No matter what type of event you're hosting, we will work with you to plan the perfect menu & event. Our restaurant offers an extravagant menu of five-star dishes. In order to provide you with the freshest food available, many of the dishes are made with spices and vegetables grown in their rooftop garden.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -73.9877,
        total_orders: 956.05,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Wine, Gluten Free, Salad, Beer, Delicious, American, Australian, Perfect, Friendly, Fun',
        annual_dol: 997.5,
        description:
            'Lively bar & eatery serving wings, burgers & pub stables that are often plated with rubber duckies.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.801,
        store_longitude: -73.9682,
        total_orders: 2312.6,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Burger, Steak, Cheesecake, Shrimp Cocktail, Vodka, Gluten Free, Wine, Beer, Bbq, Cozy',
        annual_dol: 2412.86,
        description:
            'A cozy venue with clean sight lines & good sound featuring jazz, blues & Latin acts, plus eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5225,
        store_longitude: -74.2348,
        total_orders: 0.85,
        neighborhood: 'Charleston',
        keywords: 'Burger, Salad, Dessert, Coffee, Italian, Delicious, Casual, Fresh, Recommended',
        annual_dol: 3.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5406,
        store_longitude: -74.1472,
        total_orders: 7.94,
        neighborhood: 'Great Kills',
        keywords: 'Whiskey, Italian, Bloody Mary, Great, Friendly, Casual',
        annual_dol: 22.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -73.9892,
        total_orders: 92.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Steak, Salad, Korean, Tasty, Bbq, Wine, Beer, Spicy, French, American',
        annual_dol: 377.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5865,
        store_longitude: -73.9521,
        total_orders: 21.31,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Vegetarian, Salad, Coffee, Delicious, Homey, Russian, Homemade, Authentic, Casual, Traditional',
        annual_dol: 87.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9704,
        total_orders: 1515.82,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Shrimp Cocktail, American, Hotel, Premium, Classic, Fine, Great, Signature',
        annual_dol: 5340.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6182,
        store_longitude: -74.0301,
        total_orders: 543.51,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Burger, Steak, Pizza, Chicago-Style, Beer, American, Local, Casual, Yummy, Friendly',
        annual_dol: 783.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7795,
        store_longitude: -73.9507,
        total_orders: 6869.58,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Delicious, Creamy, Bright, Casual, Lit',
        annual_dol: 8133.41,
        description:
            'Small, pink shop for ice cream scoops also doubling as a dimly lit speakeasy with cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6937,
        store_longitude: -73.9648,
        total_orders: 108.85,
        neighborhood: 'Clinton Hill',
        keywords: 'Caribbean, Jamaican, Salad, Eclectic, Intimate, Casual',
        annual_dol: 197.97,
        description:
            'Caribbean flavors meet seafood in a brick-walled space with eclectic art & an intimate vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.692,
        store_longitude: -73.9915,
        total_orders: 2356.5,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Burger, Garlic Bread, Steak, Pizza, Cheesesteak, Salad, Beer, Wine, Nachos, Bbq',
        annual_dol: 2458.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6907,
        store_longitude: -73.9954,
        total_orders: 2564.19,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Tequila, Tasty, Cozy, Vintage, French, Perfect, Intimate, Fabulous, Cool, Exceptional',
        annual_dol: 2564.19,
        description:
            "Styled after Marie Antoinette's chambers, this cozy hideaway offers cocktails, snacks & live jazz.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.9872,
        total_orders: 526.89,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotel, Warm, Global, Vintage, Inviting, Classic, Sophisticated, Retro, Chic, Fantastic',
        annual_dol: 2464.37,
        description:
            "foot from Times Square.Stylish, theater-inspired rooms feature vintage Broadway photographs and contemporary works by local artists. All provide Wi-Fi, TVs, retro phones, and floor-to-ceiling windows with city views; some have 4-poster beds.There's a sophisticated cocktail bar, and a plush lounge with an exhibition of stage props and costumes. Breakfast is available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7851,
        store_longitude: -73.9559,
        total_orders: 83.46,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Salad, Shrimp Cocktail, Chicken Caesar Salad, French Onion Soup, French, Coffee, Savory, Elegant, Mediterranean, Warm',
        annual_dol: 86.72,
        description:
            'French specialties, desserts & a variety of wines & ports are served in a small split-level space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9918,
        total_orders: 932.07,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Mexican, Tacos, Steak, Margarita, American, Colombian, Latin, French, Casual, Authentic',
        annual_dol: 951.85,
        description:
            'South-of-the-border spot with outside seats offering basic fare & a renowned margarita happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6265,
        store_longitude: -74.162,
        total_orders: 14394,
        neighborhood: 'Mariners Harbor/Mid Island/Graniteville',
        keywords:
            'Burger, Hamburger, Beer, Gourmet, Spicy, Nachos, American, French, Fun, Entertaining',
        annual_dol: 59011.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6978,
        store_longitude: -73.972,
        total_orders: 117.04,
        neighborhood: 'Fort Greene',
        keywords:
            'Sushi, Salad, Vegetarian, Wine, Japanese, Spicy, Beer, Asian, Fusion, Mediterranean',
        annual_dol: 142.92,
        description:
            'Contemporary, timber-clad stop for Asian fusion such as omakase courses plus beer, sake & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7482,
        store_longitude: -73.9864,
        total_orders: 1748.58,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Burger, Salad, Wine, Scotch, Warm, Irish, Comfortable, Homemade, American, Casual',
        annual_dol: 1808.8,
        description:
            'Tavern beaming international sporting events & offering a menu of American grub & Irish breakfast.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7751,
        store_longitude: -73.9807,
        total_orders: 689.09,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Italian, Wine, Dessert, Delicious, Cozy, Creamy, Legendary, Classy, Bellini, Relaxed',
        annual_dol: 755.12,
        description:
            'Neighborhood stalwart serving traditional Italian dishes in casual, trattorialike environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6568,
        store_longitude: -73.6475,
        total_orders: 328.35,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Mexican, Vegetarian, Tequila, Nachos, Italian, Spicy, Upscale, Delicious',
        annual_dol: 350.18,
        description:
            'Mexican restaurant & bar serving classic meals & cocktails in a quirky space with murals & TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.618,
        store_longitude: -73.932,
        total_orders: 65.55,
        neighborhood: 'Marine Park',
        keywords: 'Beer, Clean, Ale, Local, Awesome, Casual, Great',
        annual_dol: 264.3,
        description:
            '"Jack Baileys, a local bar on Flatbush Avenue in Brooklyn, New York jackbaileysbrooklyn @jackbaileys2128"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5683,
        store_longitude: -74.1263,
        total_orders: 732.75,
        neighborhood: 'Richmond',
        keywords:
            'Chicken Parmigiana, Shrimp Cocktail, Italian, Coffee, Wine, Dessert, Delicious, Spanish, Homemade, Authentic',
        annual_dol: 1089.4,
        description:
            'Elevated family-owned establishment showcasing homemade Italian specialties, with a bar available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7535,
        store_longitude: -73.9923,
        total_orders: 2583.55,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Beer, Upscale, Healthy, French, American, Bbq, Comfortable, Irish, Perfect',
        annual_dol: 2643.17,
        description:
            'Sprawling pub popular for after-work hangouts offering happy-hour specials & upscale bar grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7547,
        store_longitude: -73.8539,
        total_orders: 432.99,
        neighborhood: 'North Corona/Flushing/Corona',
        keywords: 'Hotel, Comfortable, Clean, Amazing, Great, Friendly, Modern',
        annual_dol: 1631.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7457,
        store_longitude: -73.9882,
        total_orders: 2066.28,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Hotdog, Vegetarian, Coffee, Tasty, Scotch, Wine, Organic, Rustic, Trendy',
        annual_dol: 8875.41,
        description:
            'Contemporary, high-end British gastropub in a hip, vintage-inspired space at the Ace Hotel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.751,
        store_longitude: -74.0039,
        total_orders: 94.58,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Delicious, Perfect, Hotel, Fresh, Modern, Urban, Signature, Complex',
        annual_dol: 125.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 246.3,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Vegetarian, Burger, Sushi, Pizza, Wine, Coffee, Beer, Savory, French, Modern',
        annual_dol: 1006.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9814,
        total_orders: 1367.71,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Irish, Beer, Ale, Atmospheric, Friendly, Casual, Great, Signature, Fine, Unique',
        annual_dol: 1502.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7672,
        store_longitude: -73.9837,
        total_orders: 2150.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Champagne, Warm, Gin, Awesome, Brazilian, Great, Japanese, Excellent, Hip, Rooftop',
        annual_dol: 3507.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7669,
        store_longitude: -73.9863,
        total_orders: 670.68,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Wine, Dessert, Pizza, Vegetarian, Beer, Mediterranean, Delicious, Cozy, Bourbon',
        annual_dol: 699.76,
        description:
            'Melted cheese is predominant at this small candlelit wine bar with Mediterranean flavors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -73.9887,
        total_orders: 519.1,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Korean, Wine, Margarita, Trendy, Delicious, Hotel, Modern, Contemporary, Playful',
        annual_dol: 530.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6848,
        store_longitude: -73.8448,
        total_orders: 15.91,
        neighborhood: 'Ozone Park/Jamaica',
        keywords:
            'Burger, Pizza, Beer, Spanish, Puerto Rican, Dominican, Wine, American, Latin, Friendly',
        annual_dol: 65.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -74.0056,
        total_orders: 1047.64,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Delicious, Salad, Dessert, Coffee, Bread Pudding, Organic, Sparkling, Cuban, Awesome',
        annual_dol: 1158.53,
        description:
            'Energetic, greenmarket-driven hot spot by the High Line serving American fare plus a popular brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6857,
        store_longitude: -73.9813,
        total_orders: 35.34,
        neighborhood: 'Boerum Hill',
        keywords:
            'Salad, Wine, Martini, Beer, Meatloaf, Italian, Mexican, Chinese, Swiss, American',
        annual_dol: 144.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7405,
        store_longitude: -74.0017,
        total_orders: 9.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Wine, Vietnamese, Italian, Beer, French, American, Casual, Asian, Healthy',
        annual_dol: 40.48,
        description:
            '"Cozy Cafe With Quirky Decor Offering French-Influenced Vietnamese Fare (Pho, Noodle Salad, Banh Mi, Spring Rolls, Bao Bun...) Including an All-Day Breakfast And a Variety Of Cocktails And Wines. Health Conscious"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6129,
        store_longitude: -74.1265,
        total_orders: 160.06,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Beer, Burger, Pizza, Gluten Free, Salad, Vodka, Ale, French, Homemade, Classy',
        annual_dol: 169.9,
        description:
            'Friendly sports bar (sans garden) offers pub grub (including gluten-free) & plenty of beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.739,
        store_longitude: -73.8101,
        total_orders: 47.71,
        neighborhood: 'Fresh Meadows/Flushing/Queensboro Hill',
        keywords:
            'Steak, Tasty, Argentinian, Pizza, Fresh, Italian, Authentic, Homemade, Uruguayan, Amazing',
        annual_dol: 57.13,
        description:
            'Neighborhood bar & grill serving steaks & other Argentinean grilled specialties, plus seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.723,
        store_longitude: -73.9826,
        total_orders: 190.85,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Delicious, Mediterranean, Upscale, Indulgent, Malaysian, Refined, Caesar Salad',
        annual_dol: 780.14,
        description:
            'A fish-forward menu is served in a vibrant setting with nautical accents & raw fish on beds of ice.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.9768,
        total_orders: 6454.88,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Hamburger, Beer, Coffee, Vodka, Bbq, American, Southern, Premium, Casual',
        annual_dol: 22660.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9565,
        total_orders: 3184.44,
        neighborhood: 'Williamsburg',
        keywords:
            'Whiskey, Wine, Vodka, Tasty, Beer, Burger, Pizza, Bourbon, Delicious, Comfortable',
        annual_dol: 3252.01,
        description:
            'Liquor store offering 99 varieties of scotch, bourbon & rye whiskey, plus wine & other spirits.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9565,
        total_orders: 878.1,
        neighborhood: 'Williamsburg',
        keywords: 'Vodka, Beer, Wine, Margarita, Rum, Bbq, Spacious, Relaxed, Southern, Fancy',
        annual_dol: 916.17,
        description:
            'BBQ specialist serving ribs & brisket with shared tables & cafeteria-style ordering.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 207.45,
        neighborhood: 'Astoria/LIC',
        keywords: 'Wine, Beer, Whiskey, Upscale, Irish, Salad, Spicy, Friendly, Fun, Traditional',
        annual_dol: 213.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -73.9752,
        total_orders: 66.25,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Salad, Wine, Beer, Sashimi, Japanese, Spicy, Contemporary, Miso Soup, Sleek',
        annual_dol: 270.82,
        description:
            'Sashimi & sushi with omakase options presented in a sophisticated, minimalist-chic atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6582,
        store_longitude: -73.6477,
        total_orders: 1754.43,
        neighborhood: '',
        keywords: 'Beer, Vodka, Trendy, Delicious, Ale, Clean, Classy, Perfect, Fresh, American',
        annual_dol: 1784.46,
        description:
            '"Tap Room is your neighborhood spot for delicious house made pub fare and an ever evolving craft beer and cocktail selection. We take great pride in providing fresh, high-quality food from our very own scratch kitchen (open late night!). There is plenty to enjoy, from our famous mussels to our hand-crafted burgers. With 24 craft beers on tap and seasonal cocktail menus, we have the perfect drinks to compliment any meal. Whether having family dinner, ladies night out, or watching the big game, there is something for everyone. We are fully equipped with over 15 flat screen TVs, a state-of-the-art entertainment system, and the NFL Sunday Ticket package."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7241,
        store_longitude: -73.5638,
        total_orders: 1013.98,
        neighborhood: '',
        keywords:
            'Beer, Burger, Steak, American, Belgian, Delicious, Sangria, Classic, Local, Casual',
        annual_dol: 1035.49,
        description:
            '"Our vision for Garden Social comes from the desire to share with others the benefits of what a solid friendship can offer to life. Making time to gather and bring people that live on Long Island an experience that they can be proud to be a part of. A place to go that feels like home. We believe we have designed a venue to serve the local community and offer an atmosphere that adds to relationships and life."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7048,
        store_longitude: -74.0067,
        total_orders: 563.32,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tequila, Vodka, Whiskey, Champagne, Beer, Gin, Rum, Vintage, Sweet, Retro',
        annual_dol: 2302.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6569,
        store_longitude: -73.6415,
        total_orders: 2983.26,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Steak, Sushi, Salad, Shrimp Scampi, Italian, Spicy, Savory, Fried Clams',
        annual_dol: 3518.89,
        description:
            'Contemporary grill chain offering a seafood-centric menu, plus steaks & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.8558,
        total_orders: 2036.38,
        neighborhood: 'Flushing/Corona',
        keywords: 'Pizza, Italian, Dessert, Spacious, Perfect, Amazing, Great, Friendly, Fine',
        annual_dol: 2317.53,
        description:
            'Long-time destination for old-world red-sauce Italian fare served by waiters in tuxedos.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.9159,
        total_orders: 593.67,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Burger, Beer, Gourmet, Wine, Cozy, American, Quaint, Eclectic, Casual, Seasonal',
        annual_dol: 696.21,
        description: '"A cocktail bar with an eclectic burger and fries menu."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6514,
        store_longitude: -73.8691,
        total_orders: 112.45,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Italian, Salad, Flavorful, Bruschetta, Black Russian, Margarita, Russian, Iconic, Classic, Great',
        annual_dol: 275.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7799,
        store_longitude: -73.6496,
        total_orders: 755.99,
        neighborhood: '',
        keywords:
            'Chinese, Pizza, Coffee, Wine, American, Classy, Local, Wonton Soup, Casual, Friendly',
        annual_dol: 823.91,
        description:
            '"A family friendly restaurant that has been doing business in Roslyn Heights for almost 40 years. Provide American and Authentic Chinese food choices for take out, dine in or delivery"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7255,
        store_longitude: -74.0074,
        total_orders: 243.51,
        neighborhood: 'Hudson Square',
        keywords:
            'Wine, Beer, Salad, Cozy, Romantic, Juicy, Mediterranean, Delicious, Homemade, Local',
        annual_dol: 291.59,
        description:
            'Small haunt with a cool vibe offering regional beer & wine to go, with charcuterie & bar snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7048,
        store_longitude: -74.0145,
        total_orders: 1972.52,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Gluten Free, Beer, Delicious, Luxurious, French Onion Soup, French, Southern, American',
        annual_dol: 2246.56,
        description:
            'Upmarket venue within Battery Park featuring an expansive terrace with Statue of Liberty views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.5563,
        total_orders: 2177.22,
        neighborhood: '',
        keywords: 'Steak, Burger, Tacos, Italian, Vodka, Delicious, Juicy, Swiss, Creamy, Irish',
        annual_dol: 2342.74,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7237,
        store_longitude: -74.0097,
        total_orders: 26.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Whiskey, Beer, Dessert, Hotel, Elegant, Friendly, Greek, Cool, Contemporary',
        annual_dol: 106.72,
        description:
            '"North Bar is cocktail - wine bar located in a historic landmark in the most northern part of TriBeCa. We provide high-end cocktails, a plethora of wine, draft beer, and a contemporary twist on seafood bites and bar food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6988,
        store_longitude: -73.9171,
        total_orders: 312.68,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Ample, Great, Casual',
        annual_dol: 1178.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7307,
        store_longitude: -74.0004,
        total_orders: 6936.21,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Legendary, Spicy, American, Tangy, Premium, Intimate, Contemporary, Hip, Great',
        annual_dol: 7094.75,
        description:
            'Legendary jazz musicians take the stage at this intimate club that also serves American eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6699,
        store_longitude: -73.9581,
        total_orders: 778.99,
        neighborhood: 'Crown Heights',
        keywords: 'Beer, Pizza, Wine, Tasty, Warm, Cozy, Hotel, Friendly, Cool, Great',
        annual_dol: 906.33,
        description:
            'Down-to-earth hangout with a convivial vibe, providing craft beers on tap & classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7265,
        store_longitude: -74.0035,
        total_orders: 513.95,
        neighborhood: 'South Village/SoHo',
        keywords: 'Italian, Pizza, Wine, Delicious, Rustic, Elegant, Organic, Hotel, Yummy, Classy',
        annual_dol: 524.86,
        description:
            'Tuscan-Italian cuisine, wine & cocktails served in an elegantly appointed, whitewashed space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8404,
        store_longitude: -73.6344,
        total_orders: 59.09,
        neighborhood: '',
        keywords: 'Pizza, Dessert, Italian, Vodka, Chicken Parmigiana, Savory, Tasty, Creamy, Fine',
        annual_dol: 111.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7305,
        store_longitude: -74.0006,
        total_orders: 1074.63,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Beer, Wine, Delicious, Ale, Nachos, Upscale, American, Phenomenal, Irish',
        annual_dol: 1146.67,
        description: 'Bi-level sports bar near NYU offers long happy hours & bar food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7248,
        store_longitude: -74.0022,
        total_orders: 365.83,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Tasty, Spanish, Dessert, Steak, Mediterranean, Seasonal, Contemporary, Lively, Vibrant, Sangria',
        annual_dol: 448.86,
        description:
            'Creative Barcelona-style tapas made with market-fresh ingredients in a warm, modern space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9792,
        total_orders: 155.45,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Elegant, Spacious, Hotel, Sleek, Sustainable, Rooftop, Perfect, Fantastic, Eclectic, Modern',
        annual_dol: 507.51,
        description:
            '"With 268 seats, the elegant, intimate Weill Recital Hall is home each season to hundreds of recitals, chamber music concerts, panel discussions, and master classes. At many of these events, you’re likely to find young musicians making their New York debuts."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7051,
        store_longitude: -73.8092,
        total_orders: 149.6,
        neighborhood: 'Jamaica',
        keywords:
            'Beer, Dominican, Jamaican, Ale, Irish, American, Latin, Welcoming, Sangria, Perfect',
        annual_dol: 430.88,
        description:
            '"Local pub happily serving our many loyal regulars and welcoming all new faces passing by."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7649,
        store_longitude: -73.9915,
        total_orders: 841.61,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Tasty, Bbq, French, American, Delicious, Irish, Casual, Sweet',
        annual_dol: 9519.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7671,
        store_longitude: -73.9831,
        total_orders: 337.28,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vegan, Pizza, Beer, Wine, Coffee, Donut, Trendy, American, Awesome, Iconic',
        annual_dol: 1378.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7676,
        store_longitude: -73.9905,
        total_orders: 177.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Magical, Warm, Adventurous, Creative, Organic, Modern, Approachable, Diverse',
        annual_dol: 222.99,
        description:
            "Ars Nova is an Off-Broadway, non-profit theater in New York City's Hell's Kitchen neighborhood. Ars Nova develops and produces theater, comedy and music created by artists in the early stages of their careers.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.9856,
        total_orders: 1455.72,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Whiskey, Vodka, Martini, Gin, Sushi, Hotel, Impeccable, Lively, Vibrant',
        annual_dol: 1455.72,
        description: '"Neighborhood cocktail bar serving Spanish-style fare in a low-key setting"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.718,
        store_longitude: -73.9922,
        total_orders: 4243.58,
        neighborhood: 'Bowery',
        keywords:
            'Scotch, Spicy, Meatball, Savory, Upscale, Italian, French Onion Soup, French, Eclectic, Homemade',
        annual_dol: 4333.62,
        description:
            "Contemporary American cooking in a '70s-style space with colorful furnishings & a downstairs bar.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7139,
        store_longitude: -73.9899,
        total_orders: 6143.63,
        neighborhood: 'Two Bridges',
        keywords: 'Whiskey, Vodka, Beer, Cajun, Martini, Delicious, Chinese, French, Organic, Bbq',
        annual_dol: 6273.99,
        description:
            'Bar with palm trees, a leopard-print pool table & a small menu of oysters, borscht & dumplings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7147,
        store_longitude: -73.9909,
        total_orders: 3460.75,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Beer, Wine, Whiskey, Warm, Cozy, Cajun, Lively, Fun, Sweet, Intimate',
        annual_dol: 3610.8,
        description:
            'Hip, low-key barroom with cocktails, beer & wine in a cozy space with a pressed-tin ceiling.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8897,
        store_longitude: -73.8986,
        total_orders: 171.91,
        neighborhood: 'Fieldston/West Bronx',
        keywords:
            'Tacos, Salad, Butter Chicken, Mexican, Savory, Delicious, Cozy, Friendly, American, Casual',
        annual_dol: 255.47,
        description: '"Bar Restaurant - Urban meets Indian - Fusion Amerian/Indian"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7484,
        store_longitude: -73.9927,
        total_orders: 41.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Pizza, Upscale, Friendly, Awesome, Great, Casual, Local',
        annual_dol: 167.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.8039,
        total_orders: 14.27,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Steak, Coffee, Bbq, Spicy, Casual, Great',
        annual_dol: 58.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8649,
        store_longitude: -73.9271,
        total_orders: 131.97,
        neighborhood: 'Fort George',
        keywords:
            'Burger, Vegetarian, Salad, Dessert, Wine, Japanese, Quaint, Warm, Eclectic, Latin',
        annual_dol: 146.94,
        description:
            'Casual, warm cafe serving New American & small Latin inspired bites plus wine & craft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6873,
        store_longitude: -73.9753,
        total_orders: 3148.12,
        neighborhood: 'Fort Greene',
        keywords: 'Burger, Tacos, Beer, Wine, Delicious, Nachos, Fun, Turkish, Fancy, American',
        annual_dol: 3220.67,
        description:
            'Relaxed pub serving burgers, fried bites, tacos & wings, plus margaritas, cocktails & draft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7324,
        store_longitude: -73.9993,
        total_orders: 263.58,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Italian, Upscale, Salad, Warm, Fun, Awesome, Lively, High-End, Great',
        annual_dol: 286.84,
        description:
            "Buzzing carriage house from Joe Bastianich's hospitality group featuring high-end Italian fare.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9989,
        total_orders: 18.21,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Wine, Chinese, Painkiller, Beer, Delicious, Clean, Relaxed',
        annual_dol: 74.44,
        description:
            'Unassuming restaurant serving Chinese cuisine with a twist amid a relaxed atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7124,
        store_longitude: -73.9518,
        total_orders: 3157.35,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Steak, Pizza, Italian, Salad, Wine, Beer, Delicious, Premium, Spacious',
        annual_dol: 3793.08,
        description:
            'Rustic-chic choice for Italian fare & wine with an outdoor patio used as a beer garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -73.9536,
        total_orders: 342.21,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Coffee, Vegetarian, Wine, Beer, Breakfast Burrito, Delicious, Quaint, Premium, Hip, Retro',
        annual_dol: 441.29,
        description:
            'Quaint spot with yesteryear decor, premium coffee drinks, clever bar concoctions & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.687,
        store_longitude: -73.9622,
        total_orders: 845.95,
        neighborhood: 'Clinton Hill',
        keywords:
            'Japanese, Wine, Martini, Coffee, Tasty, Gin, Contemporary, Eclectic, Modern, B-52',
        annual_dol: 863.9,
        description:
            'Sleek, modern spot offering eclectic Japanese food, cocktails, coffee, art events & film screenings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -73.9783,
        total_orders: 88.48,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Lobster, Salad, Lobster Bisque, Wine, Beer, Funky, Thai, Creamy, Trendy, Fun',
        annual_dol: 111.31,
        description:
            'Brick-walled lounge with quirky decor made from recycled materials, plus frequent DJs & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7156,
        store_longitude: -73.9447,
        total_orders: 1.52,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Steak, Salad, Beer, American, Casual, Local, Classic, Traditional',
        annual_dol: 6.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7412,
        store_longitude: -73.9812,
        total_orders: 1925.07,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Tequila, Mexican, Vodka, Beer, Wine, Salad, Meatball, American, Hotel, Modern',
        annual_dol: 1997.79,
        description:
            'Hip, industrial-chic bar & eatery serving craft cocktails, draft beer, wine & New American eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9801,
        total_orders: 1526.91,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Stylish, Elegant, Hotel, Rich, Cozy, Classy, Delicious, Quaint, Comfortable, Coffee',
        annual_dol: 1640.08,
        description:
            "Featuring kitchens, Nespresso machines and luxe bedding, the colorful, contemporary rooms and suites have free Wi-Fi, flat-screen HDTVs and iPod sound systems. Some add terraces. Suites have sitting areas.There's a 19th-century-inspired restaurant with marble fireplaces and a terrace, serving British cuisine. There's also a wood-beamed pub, plus a speakeasy-style lounge. Free passes to a local gym are offered.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.9813,
        total_orders: 440.94,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Cheesecake, Pizza, Salad, Italian, Dessert, Wine, Meatball, Cosmopolitan, Homey, American',
        annual_dol: 469.92,
        description:
            'Plentiful portions of reliable Southern Italian comfort food served in a relaxing, homey setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9798,
        total_orders: 158.67,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Wine, Upscale, Premium, Delicious, Classy, Italian, Intimate, Eclectic, Quaint, Lively',
        annual_dol: 216,
        description:
            'Very small, lively wine bar offering a changing vino list plus pizza, salads & other nibbles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.9575,
        total_orders: 1140.57,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Vegan, Coffee, Salad, Burger, Dessert, Beer, Avocado Toast, Trendy, Elegant',
        annual_dol: 1190.03,
        description:
            'American eatery popular for brunch, with tin ceiling & zinc bar adding a vintage vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.9809,
        total_orders: 248.21,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Salad, Thai, Green Papaya Salad, Vegan, Wine, Dessert, Tasty, Green Curry, Chinese, Beer',
        annual_dol: 1014.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -73.9949,
        total_orders: 2970.12,
        neighborhood: 'NoHo',
        keywords:
            'Glamorous, Hotel, Elegant, Shrimp Cocktail, Gin, Comfortable, Premium, Iconic, Traditional, Great',
        annual_dol: 9764.62,
        description:
            'Retro, wood-paneled bar offering cocktails, wines & beer, plus snacks like oysters & beef sliders.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7113,
        store_longitude: -74.0153,
        total_orders: 2118.12,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Wine, Salad, Sushi, Coffee, Gourmet, Beer, Delicious, Avocado Toast, Hotel, Rustic',
        annual_dol: 2882.77,
        description:
            'Hudson Eats counter with open sandwiches on rustic bread & gourmet toppings plus salads & desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -74.0018,
        total_orders: 73.69,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Lobster, Lobster Roll, Mexican, Tequila, Vegetarian, Margarita, Vodka, Spicy, Upscale',
        annual_dol: 301.21,
        description:
            '"The co-owners behind Mermaid Inn — Danny Abrams and Cindy Smith — add to the Mermaid family with a new restaurant and a focus on street tacos. From chef Victor Marin’s kitchen, expect a range of tortillas filled with birria, al pastor, and vegetarian options, among others."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7206,
        store_longitude: -73.9966,
        total_orders: 83.81,
        neighborhood: 'Nolita',
        keywords:
            'Pizza, Vodka, Italian, Beer, Wine, Salad, New-York-Style, Delicious, Iconic, Fresh',
        annual_dol: 114.3,
        description:
            'Wood-fired Italian cuisine made using family recipes is served in a classic space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9975,
        total_orders: 170.15,
        neighborhood: 'Lower Manhattan',
        keywords: 'Mexican, Salad, Organic, Delicious, Healthy, Casual, Local',
        annual_dol: 695.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7158,
        store_longitude: -73.987,
        total_orders: 848.55,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegan, Pizza, Salad, Coffee, Italian, Delicious, Meatball, Warm, Impeccable, Martini',
        annual_dol: 866.55,
        description:
            'Brick-oven pizzas, sandwiches & pastas, plus coffee & a full bar, in a casual, old-world atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7657,
        store_longitude: -73.9808,
        total_orders: 585.63,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Chocolate Cake, Hotdog, Cheesecake, Tacos, Milkshake, Salad, Tasty, Delicious, Prosecco, Reuben Sandwich',
        annual_dol: 639.46,
        description:
            'Set near Carnegie Hall, this nostalgic high-end diner offers both classic & creative dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -73.993,
        total_orders: 7.26,
        neighborhood: 'Bowery',
        keywords: 'Steak, Vodka, Coffee, Fun, Casual, Wonderful, Great',
        annual_dol: 29.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9777,
        total_orders: 15.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Japanese, Spicy, Delicious, Modern, Casual, Clean, Fresh, Traditional, Tempura',
        annual_dol: 22.69,
        description:
            'Artfully presented Japanese dishes, sushi rolls & sake served in sleek, modern surrounds with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8573,
        store_longitude: -73.9321,
        total_orders: 1568.95,
        neighborhood: 'Fort George/Washington Heights',
        keywords: 'Burger, Beer, Scotch, Swiss, American, Bourbon, Peruvian, Asian, Fusion, Casual',
        annual_dol: 1673.24,
        description:
            'Large selection of craft beers & bourbons in a casual little drinking spot with pub grub & sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6339,
        store_longitude: -73.5818,
        total_orders: 102.46,
        neighborhood: '',
        keywords: 'Hurricane, Shrimp Cocktail, Rum, Awesome, Great, Friendly, Casual',
        annual_dol: 141.13,
        description:
            'Laid-back waterfront bar & grill specializing in seafood, with outdoor seating & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.74,
        store_longitude: -73.9933,
        total_orders: 1056.28,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Burger, Hamburger, Cheesecake, Lobster Bisque, Salad, Beer, Tasty, Juicy',
        annual_dol: 1102.08,
        description:
            'British-born restaurant for lobster rolls, lobsters & burgers in a big space with a happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7362,
        store_longitude: -73.9965,
        total_orders: 210.08,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Karaoke, Wine, Burger, Nachos, Contemporary, Homemade, French, Teriyaki, Great, Casual',
        annual_dol: 858.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7935,
        store_longitude: -73.813,
        total_orders: 313.38,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Garlic Bread, Lasagna, Vegetarian, Pizza, Italian, Delicious, Cozy, Homemade, Baked Ziti, Friendly',
        annual_dol: 400.42,
        description:
            'Comfy, established eatery featuring old-school Italian cuisine such as baked ziti & veal scaloppine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -74.0089,
        total_orders: 98.65,
        neighborhood: '',
        keywords:
            'Burger, Wine, Rooftop, Hotel, Salad, Filipino, Incredible, Great, Perfect, Classic',
        annual_dol: 403.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6318,
        store_longitude: -73.5818,
        total_orders: 68.08,
        neighborhood: '',
        keywords: 'Burger, Pizza, Mexican, Bbq, Ale, American, Hurricane, Thai, African, Homemade',
        annual_dol: 98.14,
        description: '"BBQ restaurant and bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6768,
        store_longitude: -73.972,
        total_orders: 3654.97,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords: 'Wine, Beer, Cozy, Rum, Friendly, Sweet, Perfect, Casual, Classic, Great',
        annual_dol: 3734.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7148,
        store_longitude: -73.9416,
        total_orders: 2255.64,
        neighborhood: 'East Williamsburg',
        keywords: 'Wine, Whiskey, Beer, Vegetarian, Gin, Cozy, Spicy, Vintage, Friendly, Classy',
        annual_dol: 2360.49,
        description:
            'Cocktails & a long whiskey list make up the menu at this moody bar created with salvaged materials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7136,
        store_longitude: -73.9576,
        total_orders: 880.34,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Tasty, Coffee, Mimosa, Cuban, Delicious, Impeccable, Mediterranean, Screwdriver, Seasonal',
        annual_dol: 1308.24,
        description:
            'Chill Mediterranean eatery with ample outdoor seating in a backyard space adorned with ivy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7175,
        store_longitude: -73.9635,
        total_orders: 275.12,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Seafood Paella, Margarita, Dominican, Puerto Rican, Spanish, Delicious, Latin, Grand Marnier, American',
        annual_dol: 518.29,
        description:
            'Laid-back establishment with colorful surrounds offering Latin American cuisine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7501,
        store_longitude: -73.9815,
        total_orders: 22.79,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Lobster, Coffee, Wine, French, Beer, Quesadillas, American, Casual, Polished',
        annual_dol: 93.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7654,
        store_longitude: -73.9759,
        total_orders: 3781.22,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Wine, Champagne, Hotel, Vodka, Luxurious, Coffee, Beer, Elegant, Gin, Peruvian',
        annual_dol: 4368.26,
        description:
            'Plush rooms feature luxe linens and marble bathrooms with soaking tubs, as well as minibars, flat-screens and Wi-Fi (fee). Most have park or city views. Suites add dining rooms and living rooms with pull-out sofas. Club-level quarters provide access to a lounge with free food, drinks and Wi-Fi. Room service is available 24/7.Dining options include a sophisticated bistro and a renowned bar overlooking Sixth Avenue, plus an ornate cocktail lounge. There’s also a high-end spa, a fitness center and event space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9841,
        total_orders: 284.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'American, Classic, Outstanding, Perfect, Great, Excellent',
        annual_dol: 1163.68,
        description:
            'The Circle in the Square Theatre is a Broadway theater at 235 West 50th Street, in the basement of Paramount Plaza, in the Midtown Manhattan neighborhood of New York City. It is one of two Broadway theaters that use a thrust stage that extends into the audience on three sides.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7182,
        store_longitude: -73.9863,
        total_orders: 46.59,
        neighborhood: 'Lower East Side',
        keywords: 'Hotel, Luxurious, Wonderful, Clean, Terrific, Signature',
        annual_dol: 176.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -74.0083,
        total_orders: 5736.54,
        neighborhood: '',
        keywords: 'Champagne, Wine, Beer, Coffee, Iconic, Classic, Wonderful, Great, Complex, Fine',
        annual_dol: 6079.59,
        description:
            'Glass-roofed boats set sail around New York Harbor for lunch/brunch/dinner cruises & dancing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.741,
        store_longitude: -73.9981,
        total_orders: 236.75,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Beer, Wine, Italian, Spicy, Delicious, Warm, Cozy, Bbq, Comfortable',
        annual_dol: 241.78,
        description:
            'Comfortable place for updated American classics with cocktails, beer & Prosecco on tap.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6784,
        store_longitude: -73.9586,
        total_orders: 1849.93,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Beer, Delicious, Spicy, Gin, Fun, Japanese, Amazing, Nachos, Friendly',
        annual_dol: 3631.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7122,
        store_longitude: -73.9574,
        total_orders: 464.87,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Wine, Salad, Beer, Ale, Bourbon, Savory, Organic, Cozy, Rich, Spacious',
        annual_dol: 506.63,
        description:
            'Beer-loving soccer fans favor this pub for its big brew selection & televised Euro football matches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 42.61,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Burger, Steak, Hotdog, Gourmet, Beer, Coffee, American, Delicious, Southern, Casual',
        annual_dol: 174.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.9838,
        total_orders: 1240.11,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Sushi, Wine, Chilean, Bbq, French Onion Soup, French, Hotel',
        annual_dol: 1266.42,
        description:
            'Prime USDA steaks & seafood, plus martinis & wine, presented in a warm, sophisticated setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -73.9815,
        total_orders: 258.77,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Karaoke, Sushi, Burger, Hawaiian, Japanese, Korean, Sashimi, American, Coleslaw, Hotel',
        annual_dol: 423.34,
        description:
            'Lively karaoke place with private party rooms, Japanese fare including sushi & late hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8535,
        store_longitude: -73.7903,
        total_orders: 652.43,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Salad, Steak, Italian, Shrimp Scampi, Beer, Hotel, Delicious, Awesome, Authentic',
        annual_dol: 750.5,
        description:
            'Old-school seafood-focused Italian cooking in a longtime spot with outdoor seating & views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7453,
        store_longitude: -73.9534,
        total_orders: 10340.31,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Salad, Mexican, Dessert, Wine, Beer, Delicious, Burritos, Cuban, Peruvian',
        annual_dol: 11776.9,
        description:
            'Casual cafe & lounge serving dishes from various Latin cuisines along with cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7514,
        store_longitude: -73.9315,
        total_orders: 14533.93,
        neighborhood: 'Astoria/LIC',
        keywords: 'Spacious',
        annual_dol: 14842.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -73.9423,
        total_orders: 364.32,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Burger, Steak, Salad, Wine, French, Casual, Bold, Great',
        annual_dol: 435.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.727,
        store_longitude: -73.9891,
        total_orders: 1563.96,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Vodka, Spicy, Warm, Delicious, Rum, Creamy, Rustic, Asian',
        annual_dol: 1597.15,
        description:
            'Artfully distressed gastropub boasting elevated, thoughtfully sourced comfort chow & craft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -73.9857,
        total_orders: 718.99,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Hotdog, Beer, Upscale, Cozy, Mediterranean, Intimate, American, Friendly, Great',
        annual_dol: 815.07,
        description:
            'Unassuming watering hole in an old tenement home with wine, craft cocktails & upscale snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -73.9936,
        total_orders: 936.23,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegan, Burger, Salad, Gluten Free, Vegetable Stirfry, Organic, Delicious, Upscale, American, Innovative',
        annual_dol: 3545.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8,
        store_longitude: -73.9684,
        total_orders: 399.29,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Salad, Shrimp Cocktail, Champagne, Wine, Gluten Free, French, Savory, Mimosa, Organic, Delicious',
        annual_dol: 1144.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6013,
        store_longitude: -74.0859,
        total_orders: 27.14,
        neighborhood: 'Todt Hill/Concord',
        keywords: 'Sweet, French, Wonderful, Timeless, Memorable',
        annual_dol: 102.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.644,
        store_longitude: -73.9294,
        total_orders: 10.46,
        neighborhood: 'Little Caribbean/East Flatbush',
        keywords: 'Karaoke, Hotel, Fun, Caribbean, Sweet, Hip, Great, Lit, Casual, Chill',
        annual_dol: 42.76,
        description:
            'Cocktails, pub fare & karaoke nights offered in a low-lit, moody space with banquette & bar seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -73.9888,
        total_orders: 34.43,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Champagne, Whiskey Sour, Spicy, Whiskey, Delicious, Warm, Beer, Gin, Thai, Sweet',
        annual_dol: 140.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -73.9886,
        total_orders: 77.62,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Trendy, Hotel, Vibrant, Bright, Iconic, Premium, Friendly, Classic, Mediterranean, Fresh',
        annual_dol: 317.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.8331,
        total_orders: 747.63,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Bbq, Local, Casual, Aesthetic',
        annual_dol: 2618.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9667,
        total_orders: 3614.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Cheesecake, Burger, Salad, Wine, Beer, Irish, New-York-Style, American, Perfect, Fun',
        annual_dol: 3733.8,
        description:
            "Intimate Irish pub with a fireplace & sports on TV serving draft beers & grub like shepherd's pie.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.962,
        total_orders: 504.79,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Lobster, Steak, Salad, French Onion Soup, Dessert, French, Wine, Spicy, Swiss, Caesar Salad',
        annual_dol: 515.5,
        description:
            'Upscale-casual French bistro in an airy townhouse setting with a terrace & a long wood bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6306,
        store_longitude: -74.0283,
        total_orders: 940.51,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Pizza, Mexican, Salad, Coffee, Shrimp Cocktail, Avocado Toast, Creamy, American, Cozy',
        annual_dol: 1214.28,
        description:
            '"Closed for vacation, back on Sept 8th! Craft food. Craft drinks. Independently owned; focused on hospitality and on the details of food and drinks."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9723,
        total_orders: 2829.85,
        neighborhood: 'Lenox Hill',
        keywords: 'Champagne, Hotel, American, Fancy, Rooftop, Mead, Perfect, Unique',
        annual_dol: 3209.08,
        description:
            "The Metropolitan Club of New York is a private social club on the Upper East Side of Manhattan in New York City. It was founded as a gentlemen's club in 1891 for men only, but it was one of the first major clubs in New York to admit women, though they still represent a minority.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6952,
        store_longitude: -73.9963,
        total_orders: 532.8,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Burger, Cajun, Beer, Italian, Vodka, Wine, Spicy, Delicious, Stylish, Mediterranean',
        annual_dol: 847.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9896,
        total_orders: 129.48,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Nutritious, Salad, Delicious, Dessert, Chinese, Spicy, Savory, Creamy, Thai, Sweet',
        annual_dol: 529.27,
        description:
            'Elevated Beijing specialties are offered in this traditional Chinese-style setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7018,
        store_longitude: -73.9956,
        total_orders: 19663.97,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Hotel, Wine, Coffee, Delicious, Sustainable, Urban, Funky, Rooftop, Great, Perfect',
        annual_dol: 20177.44,
        description:
            "Industrial-chic rooms feature free Wi-Fi, smart TVs, Nespresso machines and yoga mats; most offer river, park or Statue of Liberty views. Suites have living areas and sofabeds; some add separate lounges. An upgraded 3-bedroom apartment features a dining area, a minibar, loaner bikes and a soaking tub. Room service is available 24/7.Amenities include a hip restaurant and a seasonal rooftop bar offering city views. There's also a screening room and a gym.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9852,
        total_orders: 610.38,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Milkshake, Salad, Avocado Toast, Savory, Impeccable, Warm, French Toast, Perfect, American, French',
        annual_dol: 707.37,
        description:
            'All-American comfort food for breakfast, lunch & dinner in a nostalgic diner-style space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9899,
        total_orders: 896.15,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Burritos, Premium, Delicious, Comfortable, Casual, Iconic, Unique',
        annual_dol: 955.72,
        description:
            'Snug, brick-walled bottle shop & bar with a laid-back vibe, with panini, bratwursts & other eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6356,
        store_longitude: -73.6403,
        total_orders: 183.49,
        neighborhood: '',
        keywords:
            'Burger, Chicken Caesar Salad, Salad, Beer, Wine, Bbq, Caesar Salad, Mediterranean, American, Prime Rib',
        annual_dol: 755.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9807,
        total_orders: 1483.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Italian, Great',
        annual_dol: 1548.05,
        description:
            'The Century Association is a private social, arts, and dining club in New York City, founded in 1847. Its clubhouse is located at 7 West 43rd Street near Fifth Avenue in Midtown Manhattan. It is primarily a club for men and women with distinction in literature or the arts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9964,
        total_orders: 2189.2,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Upscale, Hotel, Elegant, Spacious, Urban, Sleek, Exquisite, Lively, Modern, Great',
        annual_dol: 8948.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6633,
        store_longitude: -73.6922,
        total_orders: 68.23,
        neighborhood: '',
        keywords:
            'Beer, Delicious, Friendly, Karaoke, Memorable, Classic, Great, Fresh, Casual, Omelette',
        annual_dol: 278.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.914,
        total_orders: 3310.76,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Cozy, Hotel, Eclectic, Great, Incredible, Cultural, Casual, Unique',
        annual_dol: 3683.56,
        description:
            'Knockdown Center is a cultural space, performance venue, and art center, located in the Maspeth neighborhood of Queens, New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.9906,
        total_orders: 98.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Delicious, Spacious, Quaint, Premium, Comfortable, Classic, Polish, Great, Modern',
        annual_dol: 403.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7533,
        store_longitude: -74.0013,
        total_orders: 346.22,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Salad, Steak, Organic, Scotch, Sustainable, Martini, Warm, Fun, Casual',
        annual_dol: 1415.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8059,
        store_longitude: -73.9541,
        total_orders: 1793.62,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Hotdog, Beer, Pizza, Coffee, Vegetarian, Bourbon, Warm, Upscale, Cozy, American',
        annual_dol: 1903.28,
        description:
            'Cozy bar pouring craft beer, bourbon & original cocktails in a narrow space with a back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8031,
        store_longitude: -73.9566,
        total_orders: 6237.2,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Southern Comfort, Wine, Bbq, Southern, Fried Chicken, Homey, Creamy, Relaxed, Comfortable',
        annual_dol: 13385.24,
        description:
            "Southern classics served in a retro setting that's relaxed & homey by day, bustling in the evening.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7661,
        store_longitude: -73.7893,
        total_orders: 174.25,
        neighborhood: 'Bayside/Auburndale/Flushing',
        keywords:
            'Italian, Pizza, Coffee, Spicy, Classy, Outstanding, Fantastic, Southern, Great, Excellent',
        annual_dol: 177.95,
        description:
            'Informal, family-owned bar/eatery with a neighborhood vibe preparing Southern Italian specialties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.791,
        store_longitude: -73.5889,
        total_orders: 691.8,
        neighborhood: '',
        keywords: 'Golfclub, Classic, Premium, Excellent, Wonderful',
        annual_dol: 826.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9885,
        total_orders: 1025.08,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Cheesesteak, Cuban, South American, Colombian, Spicy, Hawaiian, American, Swiss, Latin',
        annual_dol: 1046.83,
        description:
            'Stuffed empanadas & arepas top a menu of Latin goodies like plantain chips & guacamole served 24/7.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7645,
        store_longitude: -73.9891,
        total_orders: 1821.23,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotdog, Vodka, Thai, Russian, Cozy, Premium, Awesome, Friendly, Authentic, Great',
        annual_dol: 6861.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -73.9914,
        total_orders: 1058.72,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Sushi, Salad, Wine, Italian, Vodka, Austrian, Martini, Meatball, Bourbon, Rustic',
        annual_dol: 1081.19,
        description:
            'Chic, candlelit basement eatery serving pastas & Venetian small plates alongside Italian wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6155,
        store_longitude: -74.0341,
        total_orders: 12.97,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Mexican, Beer, Italian, Irish, Asian, Local, Casual, Traditional, Recommended, Authentic',
        annual_dol: 53.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8673,
        store_longitude: -73.9207,
        total_orders: 89.03,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Steak, Mexican, Salad, Wine, Yummy, Homemade, Traditional, Authentic, Casual, Sangria',
        annual_dol: 363.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7516,
        store_longitude: -73.9761,
        total_orders: 8750.18,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Lobster Roll, Salad, Hawaiian, Italian, French, Cuban, Caribbean, Eclectic, Sweet',
        annual_dol: 10656.86,
        description:
            "Casual clothing store featuring men's & women's apparel, plus shoes & accessories.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7386,
        store_longitude: -73.9935,
        total_orders: 2691.96,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Karaoke, Steak, Ramen, Classic, American, Welcoming, Sweet, Intimate, Fun, Cozy',
        annual_dol: 2749.08,
        description:
            '"Baby Grand has been voted NYC’s greatest karaoke bar! Come see what all the fuss is about!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7418,
        store_longitude: -73.9975,
        total_orders: 7833.24,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Salad, Burger, Irish, Friendly, Casual, Local, Classic, Great, Fine',
        annual_dol: 8081.94,
        description:
            'Classic New York-style Irish pub (since 1936) offering brews on tap & pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7407,
        store_longitude: -73.993,
        total_orders: 6019.04,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Wine, Japanese, Impeccable, Friendly, Traditional, Exquisite, Authentic, Casual, Great',
        annual_dol: 27185.46,
        description:
            'Eight-seat sushi bar devoted to high-end omakase dinners, with a separate lounge area.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6787,
        store_longitude: -73.9826,
        total_orders: 927.13,
        neighborhood: 'Gowanus',
        keywords:
            'Tacos, Vegetarian, Mexican, Texan, Tasty, Bourbon, American, Relaxed, Casual, Homemade',
        annual_dol: 1055.93,
        description:
            'Spacious, open-air bar offering craft cocktails, draft brews & Mexican plates in a hip atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7386,
        store_longitude: -73.992,
        total_orders: 811.32,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Sushi, Wine, Beer, Japanese, Delicious, Homemade, Sleek, Casual, Traditional, Authentic',
        annual_dol: 1241.53,
        description:
            'This sleek bi-level outpost of a Tokyo izakaya chain attracts expats with its Japanese home cooking.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7334,
        store_longitude: -73.8105,
        total_orders: 277.45,
        neighborhood: 'Fresh Meadows/Flushing/Pomonok',
        keywords: 'Fresh, Modern, Spicy, Friendly, Sweet, French, Casual',
        annual_dol: 277.45,
        description:
            'Late-night basement venue featuring traditional, cosmic & league bowling, casual fare & party space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7225,
        store_longitude: -73.9833,
        total_orders: 466.49,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Beer, Burger, Wine, Tasty, Irish, Vibrant, American, Australian, Great, Casual',
        annual_dol: 478.32,
        description:
            'Upbeat tavern featuring typical American food, tap brews, cocktails & multiple TVs for sports fans,',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.9837,
        total_orders: 1002.58,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Beer, Cuban, Delicious, Authentic, Classic, Casual, Amazing, Chill',
        annual_dol: 1023.86,
        description:
            'Basic barroom with pool table & jukebox considered a holdout against East Village gentrification.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.685,
        store_longitude: -73.9627,
        total_orders: 1163.68,
        neighborhood: 'Clinton Hill',
        keywords:
            'Wine, Italian, Dessert, Beer, Rustic, Cozy, Vintage, Authentic, Casual, Terrific',
        annual_dol: 1214.14,
        description:
            'A century-old former drugstore converted into a vintage space serving Northern Italian cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7113,
        store_longitude: -74.0068,
        total_orders: 338.41,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Wine, French, Rustic, Hotel, Polished, Timeless, Classic, Hospitable, Western',
        annual_dol: 1725.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7209,
        store_longitude: -73.9587,
        total_orders: 4246.52,
        neighborhood: 'Williamsburg',
        keywords: 'Australian, Hotel, Rooftop, Great, Israeli, Perfect, Excellent, Chic',
        annual_dol: 4336.62,
        description:
            'miles from the Brooklyn Bridge.Stylish, industrial-chic rooms with brass and concrete details include free Wi-Fi, flat-screen TVs, work desks and en suite bathrooms.There are 3 bars and restaurants including 1 with private dining, and another that has a rooftop terrace offering panoramic city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7137,
        store_longitude: -73.9878,
        total_orders: 62.49,
        neighborhood: 'Two Bridges',
        keywords: 'Wine, Rich, Beer, French Toast, Hotel, French, Salad, American, Global, Great',
        annual_dol: 255.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9877,
        total_orders: 265.98,
        neighborhood: 'Lower East Side',
        keywords:
            'Garlic Bread, Steak, Salad, Italian, Wine, Meatball, Coffee, Spicy, Mimosa, Rustic',
        annual_dol: 296.15,
        description:
            'Cozy wine bar & bistro serving old-world Italian fare in a brick-walled setting with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.714,
        store_longitude: -73.9899,
        total_orders: 1463.69,
        neighborhood: 'Two Bridges',
        keywords: 'Chinese, Coffee, Casual',
        annual_dol: 1527.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9919,
        total_orders: 171.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Tasty, Burger, Salad, Delicious, Wine, Italian, Sweet, Fabulous, Outstanding',
        annual_dol: 197.3,
        description:
            '"Delicious contemporary American cuisine mixed with a stellar cocktail program in the coziest of atmospheres makes The Harrow New York your destination for the perfect night out. Specialties include House-Made Lobster Pierogis, Crispy Duck and don\'t forget our Harrow Burger (one of the best in the city). Featuring sixteen specially crafted unique cocktails, along with an extensive wine list, to be enjoyed solo or paired with any of our decedent dishes. We look forward to seeing you soon."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7139,
        store_longitude: -74.007,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Steak, Wine, Champagne, Hotel, American, Stylish, Sleek, Classic, Warm, Vibrant',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -74.0016,
        total_orders: 67.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Delicious, Moroccan, Cozy, Thai, Mediterranean, Indonesian, Comfortable, Fabulous, Wonderful',
        annual_dol: 330.29,
        description:
            'Cozy, laid-back bistro with Franco-American dishes plus cooking classes & blindfolded-dining events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6383,
        store_longitude: -73.5818,
        total_orders: 12.58,
        neighborhood: '',
        keywords: 'Cuban, Chinese, American, Upscale',
        annual_dol: 72.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.735,
        store_longitude: -74,
        total_orders: 271,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Steak, Salad, Coffee, Mimosa, Wine, Champagne, French, Bellini, Cozy',
        annual_dol: 1107.75,
        description:
            'Cozy, brick-walled bistro with classic French fare, including steak frites & mussels, plus brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6768,
        store_longitude: -73.9804,
        total_orders: 1428.7,
        neighborhood: 'Park Slope',
        keywords: 'Vodka, Pizza, Wine, Beer, Upscale, Spicy, Rum, Smoky, Vietnamese, American',
        annual_dol: 1459.01,
        description:
            'Quiet, upscale bar/lounge offering meticulously mixed cocktails & bites like cheeses & charcuterie.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6836,
        store_longitude: -73.9756,
        total_orders: 1177.69,
        neighborhood: 'Fort Greene',
        keywords:
            'Burger, Hamburger, Salad, Beer, Dessert, Nachos, Bruschetta, American, Fun, Local',
        annual_dol: 4814.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 104.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Coffee, Wine, Beer, Gourmet, Organic, Italian, Savory, Sustainable, American, Artisanal',
        annual_dol: 425.4,
        description:
            'Chelsea Market is a food hall, shopping mall, office building and television production facility located in the Chelsea neighborhood of the borough of Manhattan, in New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -73.9506,
        total_orders: 1158.19,
        neighborhood: 'Williamsburg',
        keywords:
            'Peruvian, Wine, Delicious, Warm, Swiss, Fun, Venezuelan, Healthy, Amazing, Perfect',
        annual_dol: 1182.76,
        description: 'Restaurant for seasonal, shareable Peruvian dishes & Latin cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6875,
        store_longitude: -73.9624,
        total_orders: 3361.49,
        neighborhood: 'Clinton Hill',
        keywords:
            'Steak, Beer, Meatloaf, Wine, Gourmet, French Toast, Spicy, French, American, Relaxed',
        annual_dol: 3432.82,
        description:
            'Relaxed dining room serving craft beers & gourmet fare, with an attached specialty food shop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7093,
        store_longitude: -74.0068,
        total_orders: 339.17,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Beer, Organic, Delicious, Sustainable, Crisp, Friendly, American',
        annual_dol: 1187.89,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7111,
        store_longitude: -73.9508,
        total_orders: 152.18,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Beer, Wine, Margarita, Hotel, Latin, American, Triple Sec, Fusion, Traditional',
        annual_dol: 223.2,
        description:
            'Warehouse-style space offering a Latin-inspired menu, Sunday brunch & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7468,
        store_longitude: -73.9851,
        total_orders: 24688.46,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Sushi, Salad, Beer, Japanese, Korean, Bbq, Sashimi, American, Asian, Healthy',
        annual_dol: 28272.24,
        description:
            'Patrons fill up on a vast assortment of Japanese eats from sushi to cooked fare at this buffet spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7493,
        store_longitude: -73.9812,
        total_orders: 5375.79,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Outstanding, Wonderful, Unforgettable',
        annual_dol: 5876.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9245,
        total_orders: 233.83,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Mexican, Tacos, Salad, Pinacolada, Rustic, Latin, Casual, Tranquil, Relaxed',
        annual_dol: 607.05,
        description:
            'Warm, rustic Mexican restaurant serving Tulum-inspired cuisine & drinks plus sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9654,
        total_orders: 2069.98,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Wine, Whiskey, Scotch, Salad, Vegan, Peruvian, Chinese, Delicious, Spicy, Ale',
        annual_dol: 2669.33,
        description:
            '"Restaurant and bar welcomes you to our Williamsburg 66 s 2nd street. At antidote our menu is combination of sophisticated & authentic Chinese cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7493,
        store_longitude: -73.9829,
        total_orders: 44.52,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tequila, Beer, Wine, Legendary, Thai, Dessert, Korean, Asian, Cozy',
        annual_dol: 184.2,
        description:
            '"Sister bar to the legendary Murray Hill beer bar The Ginger Man, Under The Volcano was originally opened in the 80\'s in Houston TX by Robert Precious. The NYC location first started in 2000 as a groundbreaking mezcal specialty bar and is now re-open as a cozy neighborhood tavern for everyone in Murray Hill and Midtown."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.752,
        store_longitude: -73.9818,
        total_orders: 79.93,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Rooftop, Coffee, Spectacular, Healthy, Iconic, Outstanding, Modern, Excellent, Local, Unique',
        annual_dol: 326.71,
        description:
            'The Stavros Niarchos Foundation Library, popularly known as the Mid-Manhattan Library, is a branch of the New York Public Library at the southeast corner of 40th Street and Fifth Avenue in the Midtown Manhattan neighborhood of New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9805,
        total_orders: 8293.41,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Tail, Steak, Shrimp Cocktail, Lobster Bisque, Wine, Upscale, Chinese, American, Modern',
        annual_dol: 8609.93,
        description:
            'Upscale chain known for its sophisticated decor, long wine list & classic steak & seafood menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8098,
        store_longitude: -73.95,
        total_orders: 2020.42,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Lobster, Pizza, Salad, Cajun, Martini, Beer, Wine, Asian, Delicious, American',
        annual_dol: 2237.86,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7677,
        store_longitude: -73.9849,
        total_orders: 1878.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Dessert, Wine, Irish, American, Warm, Modern, Casual, Friendly',
        annual_dol: 1924.01,
        description:
            'Longtime watering hole doling out traditional Irish pub grub, beer & more in plush surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9825,
        total_orders: 1363.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Chocolate Mousse, Pizza, Italian, Spicy, Elegant, Sweet, Friendly, Entertaining, Vintage',
        annual_dol: 1422.38,
        description:
            'Vintage Neapolitan eatery near Carnegie Hall with a history of entertaining high-profile patrons.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7182,
        store_longitude: -73.9899,
        total_orders: 889.85,
        neighborhood: 'Lower East Side',
        keywords: 'Cuban, Margarita, Bbq, Pinacolada, Whimsical, Fun, Daiquiri, Incredible, Casual',
        annual_dol: 928.43,
        description: '"Neon-lit bar serving fun, whimsical cocktails with elevated comfort food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.9941,
        total_orders: 171.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Meatloaf, Salad, Vegan, Meatball, Beer, Whiskey, Wine, Rustic, Mediterranean',
        annual_dol: 700.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6297,
        store_longitude: -73.581,
        total_orders: 72.54,
        neighborhood: '',
        keywords: 'Dominican, Dessert, Outstanding, Wonderful, Great, Casual',
        annual_dol: 296.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -73.9975,
        total_orders: 801.67,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegetarian, Vegan, Pizza, Salad, Italian, Wine, Rustic, Flavorful, Organic, Casual',
        annual_dol: 1064.56,
        description:
            'Cozy, rustic Italian restaurant featuring seafood & vegetarian dishes, plus cocktails & 2 bars.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -74.0055,
        total_orders: 1625.06,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Italian, Coffee, Vodka, Wine, Creamy, Fresh, Smooth, Casual, Great',
        annual_dol: 1659.55,
        description:
            'Big slices with creamy artichoke topping & other unique twists draw crowds to this casual pie spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9773,
        total_orders: 43.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Burger, Shrimp Cocktail, Chicken Caesar Salad, Salad, Karaoke, Korean, Delicious, American, Caesar Salad',
        annual_dol: 194.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7111,
        store_longitude: -73.9482,
        total_orders: 500.96,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Beer, Upscale, Gin, American, Italian, Cozy, Homey, Classy, Fancy, Classic',
        annual_dol: 1758.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6828,
        store_longitude: -73.9644,
        total_orders: 195.94,
        neighborhood: 'Clinton Hill',
        keywords: 'Wine, Salad, Coffee, Dessert, French, Cozy, Stylish, Relaxed, American, Casual',
        annual_dol: 218.01,
        description:
            'In-house butchery and baking go into American bistro dishes in a bright, stylish space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7105,
        store_longitude: -74.0069,
        total_orders: 524.11,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Karaoke, Coffee, Hotel, Funky, Stylish, Modern, Great, Unique',
        annual_dol: 546.84,
        description:
            'Modern rooms feature high ceilings, and have free Wi-Fi, and flat-screen TVs with plug and play electrical hubs, plus coffeemakers, minifridges, and desks with ergonomic chairs. Kids age 12 and under stay at no charge with an adult.Amenities include a funky lounge with a pool table, and a loft-inspired cocktail bar serving snacks. There’s also a 24/7 convenience store and a fitness center. A business center and meeting space is available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7611,
        store_longitude: -73.9237,
        total_orders: 2963.36,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegetarian, Wine, Mexican, Beer, Dessert, Spacious, French, Latin, American, Casual',
        annual_dol: 3224.89,
        description:
            '"3308 Eats & Drinks Newest and dopest spot in Queens, serving specialty drinks and amazing food. Bar classics with a Latin twist. COME FOR THE DRINKS, STAY FOR THE FOOD."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9766,
        total_orders: 499.31,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Beer, Cozy, Karaoke, Irish, Hotel, Fun, Lively, Comfortable, Friendly, Welcoming',
        annual_dol: 520.96,
        description:
            'Neighborhood Irish pub with an all-weekend happy hour, a pool table & sports on TV (but no kitchen).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8471,
        store_longitude: -73.7862,
        total_orders: 599.04,
        neighborhood: 'City Island',
        keywords:
            'Burger, Lobster, Salad, Meatball, Coffee, Italian, American, Chinese, Juicy, Homey',
        annual_dol: 629.24,
        description:
            'Homey diner with counter stools & an old-fashioned feel dishing up everyday American plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5307,
        store_longitude: -74.2301,
        total_orders: 490.83,
        neighborhood: 'Charleston',
        keywords: 'Hotdog, Gourmet, Nachos, Fun, Complex, Unique',
        annual_dol: 582.86,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8286,
        store_longitude: -73.8813,
        total_orders: 212.47,
        neighborhood: 'East Bronx/Park Versailles/Soundview',
        keywords:
            'Steak, Mexican, Shrimp Cocktail, Gourmet, Spicy, Italian, American, Clean, Casual, Fresh',
        annual_dol: 496.87,
        description:
            'A sizable menu of Mexican dishes is offered by this casual eatery with occasional entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6081,
        store_longitude: -73.973,
        total_orders: 243.9,
        neighborhood: 'Gravesend/Midwood',
        keywords: 'Russian, Yummy, Fun, Southern, Casual, Great, Chill, Dutch',
        annual_dol: 254.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8482,
        store_longitude: -73.938,
        total_orders: 150.58,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Burger, Steak, Vegetarian, Cheesesteak, Salad, Tequila, Beer, Margarita, Wine, Mimosa',
        annual_dol: 160.24,
        description:
            'Relaxed option furnishing a diverse selection of international plates & snacks, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8848,
        store_longitude: -73.9017,
        total_orders: 788.91,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Pizza, Beer, Wine, Meatball, Salad, Italian, Ale, Margarita, Rum, Spicy',
        annual_dol: 805.65,
        description:
            'Eatery serving wood-fired pizzas & cocktails in a casual space with retro punk/hip-hop graffiti.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.617,
        store_longitude: -74.0339,
        total_orders: 1953.01,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Hamburger, Tasty, Salad, Beer, Delicious, Italian, Irish, Chicken Wings, Cozy, Awesome',
        annual_dol: 2037.69,
        description:
            'Irish bar & grill featuring authentic monastery decor, live music on weekends & late-night hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7573,
        store_longitude: -73.9735,
        total_orders: 1797.84,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Lobster Roll, Shrimp Cocktail, Steak, Tasty, Spicy, Warm, French Onion Soup, Elegant, Cozy',
        annual_dol: 1875.79,
        description:
            "Elegant New American restaurant in the former Great Hall of the church's Community House.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7646,
        store_longitude: -73.9721,
        total_orders: 1864.04,
        neighborhood: 'Lenox Hill',
        keywords: 'Clean, Elegant, Cosmopolitan, Contemporary, Tradition',
        annual_dol: 1944.86,
        description:
            'The Harmonie Club is a private social club in New York City. Founded in 1852, the club is the second oldest social club in New York. It is located at 4 East 60th Street, in a building designed by Stanford White.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.689,
        store_longitude: -73.9511,
        total_orders: 508.49,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Flavorful, Wine, Vegetarian, Mexican, Delicious, Fun, Vibrant, Perfect, Caribbean, Casual',
        annual_dol: 1381.91,
        description: '"Food served until 2am"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.9904,
        total_orders: 2024.3,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Chocolate Croissant, Dessert, Salad, Coffee, Luxurious, Modern, Classy, French, Innovative, Charming',
        annual_dol: 2217.44,
        description:
            'Artisan desserts, pastries & afternoon high tea served in a revamped Prohibition era building.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7402,
        store_longitude: -73.9893,
        total_orders: 150.68,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Italian, Japanese, Organic, Wine, Trendy, Polished, Contemporary, Spacious, Welcoming',
        annual_dol: 326.25,
        description:
            'Spacious Italy-based mozzarella bar serving pizza, pasta & small plates plus cocktails, wine & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6949,
        store_longitude: -73.9549,
        total_orders: 323.92,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Hamburger, Mexican, Beer, Coffee, Wine, Ale, Spicy, American, Warm, Cozy',
        annual_dol: 1220.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9869,
        total_orders: 130.39,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Wine, Coffee, Rich, American, Hotel, Organic, Western',
        annual_dol: 533.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -74.0012,
        total_orders: 968.81,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Salad, Italian, Tasty, Warm, Meatball, Sweet, Perfect, Friendly, Fresh',
        annual_dol: 992.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9945,
        total_orders: 935.81,
        neighborhood: 'Nolita',
        keywords:
            'Salad, Vegan, Apple Pie, Coffee, Gourmet, Delicious, Modern, Fresh, American, Incredible',
        annual_dol: 997.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.722,
        store_longitude: -73.9976,
        total_orders: 1305.25,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Salad, Wine, Dessert, Italian, Rustic, Beer, Delicious, Casual, Polished, Classy',
        annual_dol: 1332.94,
        description:
            'Emilia-Romagna cuisine in a warm setting, plus cocktails & a robust Italian wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.9985,
        total_orders: 2400.67,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Upscale, Coffee, Burger, Lobster, Beer, Hotel, Luxurious, Vodka, Dessert, Elegant',
        annual_dol: 2616.36,
        description:
            'Perennially hip SoHo fixture serving creative, upscale cuisine in sleek subterranean environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7749,
        store_longitude: -73.9086,
        total_orders: 509.34,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Lobster, Pizza, Italian, Gluten Free, Beer, American, Spicy, Contemporary, Hip, Casual',
        annual_dol: 580.99,
        description:
            '"Modern Italian Restaurant & Cocktail Bar located in the heart of Astoria. Come join us for some fresh pasta or a great cocktail. Voted one of best outdoor patios NYC, bring some friends and come sit on our tropical patio, and forget your in the city."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -74.0017,
        total_orders: 212.53,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Tequila, Spicy, Upscale, Warm, Gin, Friendly, Casual',
        annual_dol: 800.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7488,
        store_longitude: -73.8711,
        total_orders: 11.67,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Mexican, Margarita, Spanish, Peruvian, American, Latin, Local, Casual, Fresh, Amazing',
        annual_dol: 47.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -74.0008,
        total_orders: 1286.31,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Lobster, Salad, Italian, Coffee, Beer, Upscale, American, Asian, Fusion',
        annual_dol: 5276.58,
        description:
            'Buzzy option offering seafood mains & craft cocktails, plus a live DJ & happy hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -74.0035,
        total_orders: 617.63,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Champagne, Wine, Hotel, Sweet, Foreign',
        annual_dol: 2524.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6889,
        store_longitude: -73.9833,
        total_orders: 51.62,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Tacos, Sushi, Coffee, Champagne, Mimosa, Avocado Toast, Delicious, Crisp, Hot Toddy, American',
        annual_dol: 211.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7666,
        store_longitude: -73.9126,
        total_orders: 1367.4,
        neighborhood: 'Astoria/LIC',
        keywords: 'Upscale, Latin, Fusion, Chill, Casual, Great',
        annual_dol: 5589.48,
        description: '"HOOKAH LOUNGE IN ASTORIA, QUEENS -MUSIC -HOOKAH -DRINKS"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6709,
        store_longitude: -73.985,
        total_orders: 16.91,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Fun, Upscale, Wonderful, Perfect, Sangria, Local',
        annual_dol: 69.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7427,
        store_longitude: -73.9543,
        total_orders: 517.11,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Wine, Elegant, Romantic, Delicious, Premium, Classy, Beer, French, Intimate, Great',
        annual_dol: 651.08,
        description:
            'Wines-by-the-glass plus oysters, cheese plates & and live jazz at this small, atmospheric nightspot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6808,
        store_longitude: -73.9965,
        total_orders: 206.83,
        neighborhood: 'Carroll Gardens',
        keywords: 'Pizza, Tacos, Mexican, Italian, Salad, Delicious, Wine, American, Gin, Rustic',
        annual_dol: 253.77,
        description:
            'Compact restaurant offering pizza slices, negronis & beers amid an easygoing atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.9867,
        total_orders: 1934.52,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Pizza, Burger, Vodka, Italian, Wine, American, Delicious, Spacious, Wonderful, Chill',
        annual_dol: 2479.26,
        description:
            'Detroit-style square pizzas, Italian sandwiches & a signature burger in a brick-clad setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7378,
        store_longitude: -73.9917,
        total_orders: 21.51,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Flavorsome, Japanese, Spicy, Asian, Fusion, Casual, Local, Teriyaki',
        annual_dol: 87.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7338,
        store_longitude: -73.9931,
        total_orders: 186.16,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Tequila, Mexican, Tacos, Beer, Tasty, Dessert, Quesadillas, Spanish, American, Latin',
        annual_dol: 568.15,
        description:
            'Colorful, bodegalike eatery serving hearty Mexican sandwiches, taquitos & fresh margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6447,
        store_longitude: -73.9236,
        total_orders: 1372.96,
        neighborhood: 'East Flatbush',
        keywords:
            'Crab Cakes, Pizza, Mimosa, New-York-Style, Bbq, Tasty, Scotch, Caribbean, Rum, Upscale',
        annual_dol: 1684.59,
        description:
            'Stylish venue presenting creative Caribbean plates & rum-based drinks amid a festive vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7938,
        store_longitude: -73.9707,
        total_orders: 2426.07,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Hotdog, Whiskey, Beer, Scotch, Salad, Karaoke, Caribbean, French, Eclectic, Traditional',
        annual_dol: 2505.94,
        description:
            "Watering hole that's filled with locals who come for drinks, sports on TV & a big pub grub menu.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7511,
        store_longitude: -73.9757,
        total_orders: 1406.43,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Lobster, Mexican, Salad, Tomato Soup, Breakfast Burrito, Margarita, Dessert, Tex-Mex, Fajitas',
        annual_dol: 1774.04,
        description:
            'Large, bustling contemporary Mexican restaurant & bar with cocktails & popular happy-hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7534,
        store_longitude: -73.9779,
        total_orders: 662.17,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Wine, Sleek, Classic, Traditional, Daiquiri, Great, Incredible, Midori, Casual',
        annual_dol: 953.62,
        description:
            'Swanky cocktail bar at the base of an office tower serving craft libations plus elevated bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5836,
        store_longitude: -73.9405,
        total_orders: 37.58,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Lobster, Dessert, Beer, Hotel, American, Modern, High-End, Local',
        annual_dol: 41.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6124,
        store_longitude: -74.0647,
        total_orders: 460.49,
        neighborhood: 'Rosebank',
        keywords: 'Italian, Beer, American, Casual, Polished, Traditional',
        annual_dol: 551.4,
        description:
            '"We are a traditional Italian American Restaurant. Old school ambiance italian restaurant ambiance. Please follow us on Instagram @doncheechny"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6211,
        store_longitude: -74.0292,
        total_orders: 2346.67,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Lobster, Salad, Italian, Pizza, Wine, Stylish, Upscale, Trendy, Classy, Homemade',
        annual_dol: 2403.66,
        description:
            'This stylish eatery offers upscale Italian fare in a contemporary setting with garden seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.9656,
        total_orders: 1388.96,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Chocolate Cake, Cheesecake, Salad, Shrimp Cocktail, Coffee, Wine, Tasty, Juicy, Legendary',
        annual_dol: 1418.43,
        description:
            'Traditional steakhouse fare is served in an ornate setting with a separate piano room & wine cellar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9891,
        total_orders: 1945.75,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Luxurious, Glamorous, Upscale, Iconic, Fabulous, Cozy, Mediterranean, Classic, Sophisticated',
        annual_dol: 2236.65,
        description:
            'The upscale rooms feature city views and bathrooms with rainfall showerheads, plus Wi-Fi, flat-screen TVs, iPod docks and Keurig coffeemakers. Upgraded rooms add sitting areas with pull-out sofas. Suites offer floor-to-ceiling windows with skyline vistas, separate living and dining areas, and soaking tubs.Amenities include a cocktail bar, and a sophisticated restaurant by chef Todd English offering upmarket French cuisine, plus a 24-hour fitness center, a business center and 10 meeting rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9909,
        total_orders: 1527.44,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lasagna, Vegan, Salad, Gluten Free, Vegetarian, Italian, Wine, Upscale, Delicious, Margarita',
        annual_dol: 1559.85,
        description:
            'Modern Italian & Mediterranean cuisine & modern decor star at this lively, casual eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -73.9822,
        total_orders: 1054.08,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Pizza, Vegetarian, Beer, Gourmet, Margarita, Karaoke, American, Ale, Quaint',
        annual_dol: 1141.65,
        description:
            'Classic corner bar with simple eats served in old-school tavern environs with games on a few TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7794,
        store_longitude: -73.9508,
        total_orders: 1698.31,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Steak, Burger, French Toast, French, Wine, French Onion Soup, Beer, Organic, Belgian, Cozy',
        annual_dol: 1797.44,
        description:
            "Alsatian eats & a popular brunch along with a beer list that's curated by a beer sommelier.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -73.9828,
        total_orders: 1361.35,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Burger, Beer, Tequila, Vegan, Steak, Hamburger, Vegetarian, Ale, Spicy, Funky',
        annual_dol: 1467.1,
        description:
            'A wood-framed spot pouring a global variety of draft & bottled craft beers matched with bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9848,
        total_orders: 653.78,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Chocolate Mousse, Wine, Coffee, Italian, Upscale, Polished, Sparkling, Homemade, Fabulous, Vibrant',
        annual_dol: 667.65,
        description:
            'Large, vibrant pre-theater haunt serving Venetian fare like pizzas & pastas in upscale surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9881,
        total_orders: 335.24,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Italian, Pizza, Salad, American, Friendly, Authentic, Timeless, Traditional, Perfect, Fancy',
        annual_dol: 381.81,
        description:
            'Northern Italian restaurant with pasta, pizza & more in a rustic, 2-story brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7397,
        store_longitude: -73.9797,
        total_orders: 356.06,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Steak, Mexican, Beer, Italian, Wine, Margarita, Fajitas, Urban, Modern, Local',
        annual_dol: 450.14,
        description:
            'A family-owned neighborhood spot serving homestyle Mexican food in a bright-colored, muraled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9932,
        total_orders: 77.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Delicious, Awesome, Great, Complex, Cool',
        annual_dol: 318.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9885,
        total_orders: 42.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Beer, Tasty, Japanese, Spicy, Korean, Casual, Premium, Teriyaki, Perfect',
        annual_dol: 185.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.986,
        total_orders: 1295.85,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Karaoke, Chinese, Filipino, Korean, Japanese, Tasty, Iconic, Impeccable, Premium, Great',
        annual_dol: 1568.25,
        description:
            'Mod karaoke bar offering 10 private rooms, cocktails & a library of Korean, English & Chinese songs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7502,
        store_longitude: -73.9951,
        total_orders: 205.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Coffee, Hotel, Delicious, Iconic, Lively, Local',
        annual_dol: 773.45,
        description:
            "The James A. Farley Building is a mixed-use structure in Midtown Manhattan, New York City, which formerly served as the city's main United States Postal Service branch.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9924,
        total_orders: 2374.2,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Pizza, Gourmet, Wine, Upscale, American, Trendy, Delicious, Woody, Spacious',
        annual_dol: 3205.48,
        description:
            'This enormous sports bar boasts tons of TVs, upscale pub food & a woody ski-lodge look.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -73.9872,
        total_orders: 15.59,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Salad, Chinese, Korean, Ale, Asian, Hotel, Refined, Traditional, Fusion, Authentic',
        annual_dol: 63.71,
        description:
            'Refined restaurant & bar specializing in elevated takes on traditional Sichuan cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7338,
        store_longitude: -74.0047,
        total_orders: 593.75,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Upscale, Trendy, Mexican, Italian, Delicious, Comfortable, Asian, Friendly, Modern, Vintage',
        annual_dol: 1616.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7341,
        store_longitude: -74.0031,
        total_orders: 12441.59,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Whiskey, Wine, Burger, Scotch, Rum, Irish, Cozy, Delicious, American',
        annual_dol: 12720.43,
        description:
            'Locally sourced pub grub, a wide cocktail menu & weekend brunch served in a compact, homey setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6454,
        store_longitude: -74.1078,
        total_orders: 2250.65,
        neighborhood: 'Randall Manor',
        keywords:
            'Lobster, Lobster Tail, Steak, Shrimp Cocktail, Wine, Beer, Margarita, Delicious, Romantic, Turkish',
        annual_dol: 2474.81,
        description:
            'Waterfront spot serving American & Mediterranean lunch & dinner menus, plus beer, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7859,
        store_longitude: -73.9724,
        total_orders: 2707.19,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Burger, Salad, Tasty, Gluten Free, Mimosa, French, Upscale, Chinese, Homey',
        annual_dol: 3399.94,
        description:
            'Homey restaurant offering international cuisine, all-day dining & a cocktail bar in a homey space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7688,
        store_longitude: -73.9551,
        total_orders: 1199,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Wine, Dessert, Tasty, Rustic, Classy, Friendly, Modern, Classic, Unpretentious',
        annual_dol: 1227.59,
        description:
            'An old-school Italian eatery with an extensive menu & prime opportunities for people-watching.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.724,
        store_longitude: -73.9537,
        total_orders: 1327.75,
        neighborhood: 'Greenpoint',
        keywords:
            'Beer, Vodka, Rum, Gin, Rooftop, Elegant, Picturesque, Amazing, Seasonal, Classic',
        annual_dol: 1512.22,
        description:
            '"Dobbin St is a tailored event space centrally located in Williamsburg, steps away from picturesque McCarren park. A transformed Brooklyn factory that exudes a minimalist and luxury feel, boasting stunning Manhattan skyline views from the rooftop and vaulted 22 foot ceilings inside."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9947,
        total_orders: 3353.26,
        neighborhood: 'Nolita',
        keywords:
            'Pizza, Wine, Delicious, Beer, Spacious, Margarita, Clean, Perfect, Bright, Great',
        annual_dol: 3498.64,
        description:
            "Low-key hangout where fans sip cocktails on couches; on weekends there's a DJ and karaoke.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -73.9984,
        total_orders: 341.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Cornbread, Spicy, Jamaican, Caribbean, Rum, Rich, Salad, Creole, Homemade, Fun',
        annual_dol: 501.53,
        description:
            'Spicy Caribbean fare, potent rum punch & an in-house DJ playing tunes create an island vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.8815,
        total_orders: 205.91,
        neighborhood: 'Jackson Heights/Elmhurst',
        keywords:
            'Steak, Sushi, Colombian, Delicious, Pinacolada, Asian, American, Fresh, Amazing, Local',
        annual_dol: 347.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.764,
        store_longitude: -73.9734,
        total_orders: 10223.18,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Lobster, Lobster Roll, Hotel, Vodka, Salad, French Onion Soup, Elegant, Italian, French',
        annual_dol: 10548.76,
        description:
            "Offering bathrooms with gold-plated fixtures, the elegant, French-style rooms have Italian linens and sitting areas, plus flat-screen TVs, Wi-Fi (fee) and iPads; some rooms have terraces. Suites offer city views, powder rooms and butler's pantries; some are bi-level. Room service is available.Afternoon tea is offered in the famed Palm Court, which features a stained glass ceiling and lavish decor. There's also a European-inspired food hall, a storied jazz lounge and a luxe champagne bar, plus a posh spa and a gym.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.9786,
        total_orders: 11911.59,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Coffee, Salad, Hotel, Elegant, Sustainable, Warm, Seasonal, Sleek, Artisanal',
        annual_dol: 37648.63,
        description:
            "Sleek rooms offer sophisticated decor and custom-designed beds, plus marble bathrooms, flat-screen TVs and Wi-Fi (fee). Executive rooms provide access to a club lounge with free Wi-Fi, breakfast and evening appetizers. Suites add separate sitting areas.Amenities include a casual restaurant serving sandwiches and brick-oven pizzas, a lobby lounge, and a bar offering light fare. There's also a sky-lit fitness center, a business center and over 151,000 sq ft of meeting space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9883,
        total_orders: 112.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Dessert, French, Rich, Savory, Polished, Martini, Comfortable, Traditional, Authentic',
        annual_dol: 129.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.9879,
        total_orders: 4727.5,
        neighborhood: 'Lower East Side',
        keywords: 'Whiskey, Scotch, Bourbon, Beer, Margarita, Martini, Rum, Upscale, Relaxed, Cozy',
        annual_dol: 5800.1,
        description:
            'No-frills tavern with big list of scotch, whiskey & bourbon plus free peanuts to soak up the drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9907,
        total_orders: 1812.67,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegetarian, Vegan, Wine, Trendy, Delicious, Spicy, Asian, Eclectic, Classic, Casual',
        annual_dol: 2218.62,
        description:
            'Inventive vegetarian dishes & classic cocktails presented in stylish, airy surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.9844,
        total_orders: 609.06,
        neighborhood: 'Lower East Side',
        keywords:
            'Vietnamese, Vintage, Delicious, Classic, Hotel, American, Sweet, Fresh, Local, Casual',
        annual_dol: 2333.68,
        description:
            'Everyday shop serving specialty sandwiches with prosciutto & porchetta, plus crispy brussel sprouts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9921,
        total_orders: 33.43,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Argentinian, Italian, Wine, Gluten Free, Classic, Warm, Authentic, Intimate, Great',
        annual_dol: 136.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7131,
        store_longitude: -73.8728,
        total_orders: 157.47,
        neighborhood: 'Middle Village/Flushing',
        keywords:
            'Wine, Chicken Tikka Masala, Beer, Chinese, Premium, Caribbean, Traditional, Fresh, Perfect, Indian',
        annual_dol: 482.42,
        description:
            '"We offer a full bar as well as hookah in our restaurant. Please come in and see us today!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8558,
        store_longitude: -73.9333,
        total_orders: 86.87,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Lobster, Lobster Roll, Burger, Steak, Salad, Wine, Delicious, Magical, Fried Calamari, Rustic',
        annual_dol: 355.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.718,
        store_longitude: -73.9502,
        total_orders: 2772.32,
        neighborhood: 'Williamsburg',
        keywords:
            'Roast Turkey, Gin, Roast Beef, Ale, Bourbon, Swiss, Rum, Russian, American, Spicy',
        annual_dol: 2831.14,
        description:
            'Trivia contests, spelling bees & live bands lend a unique vibe to this offbeat watering hole.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.6917,
        total_orders: 27.33,
        neighborhood: '',
        keywords:
            'Cheesecake, Sushi, Vegetarian, Japanese, Asian, Chinese, Fusion, Delicious, Thai, Yummy',
        annual_dol: 111.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7064,
        store_longitude: -74.0026,
        total_orders: 4214.72,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Vegetarian, Pizza, Wine, Italian, Coffee, Gluten Free, Beer, Dessert, Hotel, Luxurious',
        annual_dol: 20037.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.9839,
        total_orders: 486.47,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Flavorful, Wine, Dessert, Beer, Vegan, Vietnamese, Delicious, Warm, Relaxed, Homey',
        annual_dol: 515.93,
        description:
            'Informal lunch counter from the Hanoi House team serving Vietnamese fare & coffee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7137,
        store_longitude: -73.9495,
        total_orders: 4147.82,
        neighborhood: 'Williamsburg',
        keywords: 'Warm, Cool, Casual, Lively, Local, Dutch, Great, American',
        annual_dol: 4147.82,
        description:
            'Gay & straight locals congregate at this bar with 2 fireplaces, a pool table & a lush outdoor space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6734,
        store_longitude: -73.9568,
        total_orders: 89.97,
        neighborhood: 'Crown Heights',
        keywords:
            'Mexican, Caribbean, Jamaican, Bbq, Romantic, American, Lively, Authentic, Local, Amazing',
        annual_dol: 367.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7495,
        store_longitude: -73.9834,
        total_orders: 51.84,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Moroccan, Wine, Rich, Lebanese, Beer, French, Mediterranean, American, Hotel',
        annual_dol: 60.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7632,
        store_longitude: -73.9277,
        total_orders: 19.49,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Bbq, Irish, Classic, Perfect, Friendly, Great, Coleslaw, Western, Casual',
        annual_dol: 79.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7252,
        store_longitude: -74.003,
        total_orders: 133.2,
        neighborhood: 'South Village/SoHo',
        keywords: 'Italian, Casual, Elegant, Indian, American, Local, Colorful, Great',
        annual_dol: 544.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7401,
        store_longitude: -73.987,
        total_orders: 8553.1,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Wine, Rich, Pizza, Beer, Trendy, Dessert, Gin, Salad, Fun, Fantastic',
        annual_dol: 8780.6,
        description: 'Modern lounge with 19 Ping-Pong courts, full bar service & dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9903,
        total_orders: 1504.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Scotch, Warm, Delicious, Irish, Quaint, Lit, Casual, Local, Great',
        annual_dol: 1579.57,
        description:
            'Intimate, neighborhood hangout serving up cocktails & brews in a dimly lit, low-key atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7578,
        store_longitude: -73.9875,
        total_orders: 3819.75,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Italian, Wine, Swiss, Caesar Salad, Homemade, American, Classy, Classic',
        annual_dol: 3908.66,
        description:
            'Caricatures of movie stars line the walls of this institution for pre- & post-theater Italian meals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.9841,
        total_orders: 3700.01,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Wine, Italian, Rooftop, Gourmet, Premium, American, Sophisticated, Superb, Modern',
        annual_dol: 3778.52,
        description:
            "Building.Featuring floor-to-ceiling windows with city views, the contemporary rooms come with free Wi-Fi and flat-screen TVs with premium channels, plus coffeemakers and minifridges. Suites add separate living rooms; some have wet bars and whirlpool tubs.Amenities include a 24/7 fitness center and a spa. There's also a buzzy bistro serving modern American fare, and a chic rooftop bar with a terrace and city views.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9919,
        total_orders: 597.91,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Wine, Irish, Fun, Spacious, Yummy, Hurricane, Cozy, American, Great',
        annual_dol: 2453.09,
        description:
            'Irish-style sports bar on 2 levels offering comfort food, brews & game viewing on giant projectors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6893,
        store_longitude: -73.9709,
        total_orders: 420.2,
        neighborhood: 'Fort Greene',
        keywords: 'Burger, Beer, Wine, Spicy, Trendy, Coffee, Rum, Delicious, Romantic, Caribbean',
        annual_dol: 460.63,
        description:
            'Warm eatery in a brownstone serving Caribbean comfort fare with jerk spices, plus rum drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.69,
        store_longitude: -73.9818,
        total_orders: 2842.37,
        neighborhood: 'Downtown Brooklyn/Fort Greene/Brooklyn Heights',
        keywords:
            'Cheesecake, Salad, Dessert, Steak, French Toast, Avocado Toast, French, Bbq, Caesar Salad, Sweet',
        annual_dol: 3685.07,
        description:
            'New York-style cheesecake is the thing to order at this essential Brooklyn diner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.9927,
        total_orders: 513.52,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Beer, Wine, Irish, Delicious, Friendly, Authentic, Impressive, Great, Cool, Casual',
        annual_dol: 1802.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9714,
        total_orders: 375.67,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Hotel, Elegant, Rich, Friendly, Perfect, Superb, Contemporary, Excellent, Tradition',
        annual_dol: 1535.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6819,
        store_longitude: -73.986,
        total_orders: 3838.25,
        neighborhood: 'Gowanus',
        keywords:
            'Vegan, Gluten Free, Coffee, Gin, Phenomenal, Cool, Awesome, Casual, Wonderful, Local',
        annual_dol: 4161.53,
        description:
            'Hi-fi record bar with food & drink offerings plus an intimate performance space & vegan cafe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.8035,
        total_orders: 45.36,
        neighborhood: 'Broadway–Flushing/Flushing/Murray Hill',
        keywords: 'Karaoke, Great, Casual',
        annual_dol: 185.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7105,
        store_longitude: -73.9584,
        total_orders: 316.95,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Pizza, Italian, Beer, Salad, Wine, American, Classy, Casual, Local, Fresh',
        annual_dol: 471,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6754,
        store_longitude: -73.963,
        total_orders: 924.75,
        neighborhood: 'Crown Heights',
        keywords: 'Beer, Wine, Tasty, Trendy, Spicy, Salad, Cozy, American, Ramen, Awesome',
        annual_dol: 1312.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -73.9839,
        total_orders: 406.15,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Fun, Lively, Eclectic, Cool, Fantastic, Authentic, Great, German, Incredible, Casual',
        annual_dol: 552.89,
        description:
            'The Pyramid Club is a nightclub in the East Village of Manhattan, New York City. After opening in 1979, the Pyramid helped define the East Village drag, gay, punk and art scenes of the 1980s. The club is located at 101 Avenue A in Manhattan.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -74.0025,
        total_orders: 109.62,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Wine, Coffee, Beer, Salad, Premium, Vintage, Authentic, Great, Excellent, Attentive',
        annual_dol: 453.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -73.9856,
        total_orders: 9633.76,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Tasty, Vegetarian, Wine, Salad, Italian, Upscale, Delicious, Gourmet, Rich, Coffee',
        annual_dol: 9940.42,
        description:
            'Handmade pastas headline a sophisticated Italian menu at this upscale locale with a cool vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.745,
        store_longitude: -73.9839,
        total_orders: 461.27,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Wine, Steak, American, Dessert, Stylish, Bruschetta, Cozy, Natural, Great, Incredible',
        annual_dol: 481.27,
        description:
            'Stylish wine bar with a patio & a menu of cheese, charcuterie, bruschetta & New American entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7401,
        store_longitude: -74.0062,
        total_orders: 6403.25,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Steak, Lobster, Tacos, Elegant, American, Yummy, Modern, Classic, Exceptional, Fantastic',
        annual_dol: 6680.87,
        description:
            'Slick, bi-level space known for celebrity sightings, creative seafood-focused food & a rooftop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -74.0052,
        total_orders: 5154.78,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Rich, Beer, Fun, Friendly, Comfortable, Awesome, Great, Excellent, Classic',
        annual_dol: 5437.63,
        description: 'A Christopher Street classic since 1972, this gay bar draws an older crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7815,
        store_longitude: -73.9723,
        total_orders: 1230.18,
        neighborhood: 'Central Park West Historic District',
        keywords:
            'Salad, Burger, Vegetarian, Gourmet, Dessert, American, Asian, Seasonal, Natural, Cultural',
        annual_dol: 1401.1,
        description:
            'The American Museum of Natural History is a natural history museum on the Upper West Side of Manhattan in New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7867,
        store_longitude: -73.9755,
        total_orders: 2551.16,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Tacos, Beer, Salad, Delicious, Irish, Ale, Fun, Lively, Friendly',
        annual_dol: 2605.29,
        description:
            'Pub with rotating drink specials and diverse eats in laid-back digs with big windows & board games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7855,
        store_longitude: -73.9727,
        total_orders: 426.96,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegan, Burger, Vegetarian, Sushi, Pizza, Salad, Tacos, Gluten Free, Vegetable Stirfry, Wine',
        annual_dol: 465.95,
        description:
            'Upmarket, plant-based dishes served with wine & cocktails, plus a weekend brunch menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7831,
        store_longitude: -73.9786,
        total_orders: 69.63,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Vodka, Beer, Chicken Parmigiana, Wine, Gourmet, Italian, American, Fresh, Casual',
        annual_dol: 261.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5902,
        store_longitude: -73.9006,
        total_orders: 10.96,
        neighborhood: '',
        keywords: 'Hotdog, Hamburger, Bbq, Southern, Sweet, Excellent, Great, Complex, Local',
        annual_dol: 44.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.697,
        store_longitude: -73.9063,
        total_orders: 407.06,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Warm, Vietnamese, Intimate, Perfect, Fun, Cool, Eclectic, Casual, Great, Unique',
        annual_dol: 434.12,
        description:
            '"We are a live music venue with an onsite Vietnamese restaurant and a patio garden bar. Come by for slushy drinks, big open skies, plants, a great sounding room with perfect sightlines, and thoughtfully eclectic live performers and djs. No age restriction to enter, 21+ with valid State ID to drink alcohol. Masks + proof of Covid vaccination (or else 72-hours-or-younger negative PCR test), required to enter."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8176,
        store_longitude: -73.6253,
        total_orders: 776.78,
        neighborhood: '',
        keywords:
            'Sushi, Gluten Free, Relaxed, Fun, Premium, Casual, Outstanding, Fresh, Excellent, Fine',
        annual_dol: 906.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7764,
        store_longitude: -73.921,
        total_orders: 260.23,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Beer, Rare, Exceptional, Local',
        annual_dol: 1063.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9144,
        total_orders: 942.52,
        neighborhood: 'Astoria/LIC',
        keywords: 'Karaoke, Mediterranean, American, Casual, Great',
        annual_dol: 3551.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9145,
        total_orders: 86.61,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Tacos, Steak, Mexican, Tasty, Fish Tacos, Delicious, Spanish, American, Fun',
        annual_dol: 354.03,
        description:
            'Energetic Latin nightclub with TV, billiards & DJs in a laid-back sports-bar environment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.8334,
        total_orders: 2870.08,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Cinnamon Roll, Upscale, Beer, Pizza, Coffee, Hotel, Tasty, Warm, American, Fabulous',
        annual_dol: 3138.55,
        description:
            'Contemporary bar & grill offering American fare such as burgers, pasta & steaks, plus a large patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6822,
        store_longitude: -73.9347,
        total_orders: 1644.21,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Burger, Salad, Spicy, Delicious, Southern Comfort, Southern, Friendly, Gumbo, Sweet, Modern',
        annual_dol: 1780.35,
        description:
            'Modern spin on Southern comfort food plus brunch & a full bar in a historic brownstone neighborhood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7375,
        store_longitude: -73.988,
        total_orders: 3983.58,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Lobster, Sushi, Dessert, Delicious, Japanese, Sashimi, Mediterranean, Exquisite, Premium, Contemporary',
        annual_dol: 5291.58,
        description:
            'Expansive eatery serving a sophisticated seafood-focused menu with global influences.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7244,
        store_longitude: -73.9879,
        total_orders: 1026.11,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Pizza, Italian, Wine, Beer, Whiskey, Ale, Bourbon, Spacious, Friendly, Casual',
        annual_dol: 1047.88,
        description:
            'A smartly curated selection of beer, bourbon & beyond draws connoisseurs to this East Village bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7327,
        store_longitude: -73.9874,
        total_orders: 6517.24,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Steak, Burger, Salad, Wine, Rustic, Beer, Upscale, Cozy, Comfortable, French',
        annual_dol: 7092.43,
        description:
            'Bustling, exposed-brick East Village tavern with separate bar & dining areas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7388,
        store_longitude: -73.9897,
        total_orders: 1094.08,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Salad, Tomato Soup, Wine, Delicious, Turkey Sandwich, Beer, Sweet, Friendly, Playful, Perfect',
        annual_dol: 1452.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5984,
        store_longitude: -74.0798,
        total_orders: 313.61,
        neighborhood: 'Arrochar/Concord',
        keywords: 'Burger, Pizza, Sushi, Italian, Casual, Local, Fresh, Classic, Fine',
        annual_dol: 328.59,
        description:
            'Local fixture cooking classic homestyle Italian dishes in a white-tablecloth space with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8052,
        store_longitude: -73.9663,
        total_orders: 653.01,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Lobster, Lobster Roll, Burger, Cajun, Chinese, Rich, Pinacolada, Creole, Southern, Modern',
        annual_dol: 743.74,
        description:
            '"BIBS UP, PHONES DOWN. IT\'S #SHAKINGTIME Dive headfirst into a bag of steaming hot seafood shaken in rich butter, an abundance of garlic, and a signature blend of Cajun spices. BUILT ON COMMUNITY: We believe in the power of food to bring us together to celebrate, laugh, and be in the moment. Fully immerse yourself in the rhythm of shelling and plucking out lumps of shellfish while the bag keeps your seafood hot and drenched in our signature sauce."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9726,
        total_orders: 3087.82,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Steak, Salad, Gluten Free, Wine, Greek, Succulent, Elegant, American, Impeccable',
        annual_dol: 3365.25,
        description:
            'Upmarket American seafood & raw-bar offerings in a swanky, spacious dining room with a fireplace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9729,
        total_orders: 1586.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Steak, Beer, Delicious, Martini, Irish, American, Perfect, Caribbean, Fantastic',
        annual_dol: 1619.94,
        description:
            'Clubby hangout with new American eats & handcrafted cocktails, plus TVs showing sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.764,
        store_longitude: -73.9708,
        total_orders: 1230,
        neighborhood: 'Lenox Hill',
        keywords:
            'Cajun, Salad, Wine, French, Organic, Elegant, Impeccable, Classy, Comfortable, Timeless',
        annual_dol: 1283.33,
        description:
            'The Cajun chicken is the most famous of the French classics offered at this large bistro.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9696,
        total_orders: 1639.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Premium, Friendly, Excellent, Fine, Modern, Casual',
        annual_dol: 1746.75,
        description:
            "Modern rooms come with free Wi-Fi, flat-screen TVs and MP3 players, as well as minifridges, microwaves and Keurig coffeemakers; some have private balconies or floor-to-ceiling windows offering city views.There's a casual restaurant, as well as a 24/7 pantry market. Other amenities include a lobby lounge, a 24-hour gym and a business center. Valet parking is available (fee).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -74.0075,
        total_orders: 6369.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Hamburger, Cajun, Salad, Jambalaya, Beer, Wine, Vodka, Upscale, Gumbo',
        annual_dol: 7478.58,
        description:
            "Spot for upscale takes on Cajun-Creole food & drink, with a look inspired by NOLA's French Quarter.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.9685,
        total_orders: 768.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Sushi, Shrimp Cocktail, Wine, Organic, Mimosa, Rustic, Sustainable, Homemade, Warm',
        annual_dol: 818.56,
        description:
            'Creative seafood dishes showcased in a townhouse with a long marble bar & vintage decorations.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8278,
        store_longitude: -73.9246,
        total_orders: 297.86,
        neighborhood: 'Concourse/West Bronx',
        keywords: 'Beer, Karaoke, Spanish, Latin, Delicious, Cool, Great, Casual',
        annual_dol: 307.34,
        description: '"Spanish Sports Bar by Yankee Stadium"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9713,
        total_orders: 1406.3,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Gluten Free, French, Sustainable, Impeccable, Refined, Polished, Modern, Classy, Italian',
        annual_dol: 5946.86,
        description:
            'A vegetable-focused menu & sustainable proteins are served in a modern greenhouse-inspired space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9745,
        total_orders: 755.38,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tacos, Mexican, Salad, Margarita, Wine, Upscale, Rich, American, Contemporary, Artisanal',
        annual_dol: 861.18,
        description:
            'Contemporary Mexican hot spot turning out innovative tacos & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.9838,
        total_orders: 3959.71,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Beer, Irish, Spicy, American, Classic, Welcoming, Traditional, Casual, Local',
        annual_dol: 4131.39,
        description:
            'Casual Irish pub & restaurant featuring an American/Irish bar menu in a wood-paneled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6909,
        store_longitude: -73.9539,
        total_orders: 1576.83,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Coffee, Organic, Comfortable, Casual, American, Local, Western, Fresh, Great, Excellent',
        annual_dol: 1745.33,
        description:
            'Casual place serving locally sourced American plates, plus clever cocktails & imported wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6316,
        store_longitude: -73.6407,
        total_orders: 71.46,
        neighborhood: '',
        keywords: 'Burger, Tacos, American, Sweet, Casual, Traditional, Excellent, Great',
        annual_dol: 218.48,
        description: '"Bar & Grill proudly serving the LGBTQ+ Community"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.982,
        total_orders: 9.71,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Hotel, Elegant, Irish, American, Welcoming, Modern, Great, Local',
        annual_dol: 11.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7307,
        store_longitude: -74.0001,
        total_orders: 1696.68,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lasagna, Chocolate Cake, Chocolate Mousse, Pizza, Wine, Italian, Dessert, Vodka, Salad, Delicious',
        annual_dol: 1831.9,
        description:
            'A lantern-lit indoor garden & live jazz in the adjoining bar complement light Italian fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7754,
        store_longitude: -73.9093,
        total_orders: 662.4,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Beer, American, Irish, Hip, Lively, Sweet, Ample, Local, Signature, Great',
        annual_dol: 813.45,
        description:
            '"Rivercrest is a new craft beer bar and restaurant, with unique cocktails that complement the already established Ditmars Boulevard bar and restaurant scene. Locally sourced produce, bread, liquor, and beer ensure the best standards and quality are provided daily. With 18 rotating tap lines designated to unique craft beers, we intend to support local breweries while still offering a broad selection of other brews and go-to favorites. If you like sports, as we do, you’re in for a treat! We have every sports package available to ensure you don’t miss a pass, dunk, putt, homer, or throw! With 3,000 square feet of space, Rivercrest has ample space indoor and outdoor to host your next party in Astoria."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -74.0017,
        total_orders: 270.35,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Steak, Dessert, Spicy, Delicious, American, Mediterranean, Cozy, Ribeye Steak, Fresh',
        annual_dol: 288.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5273,
        store_longitude: -74.2307,
        total_orders: 35404,
        neighborhood: 'Charleston',
        keywords:
            'Burger, Salad, Meatball, Beer, Gluten Free, Italian, Wine, Delicious, French, American',
        annual_dol: 62133.47,
        description:
            'Splashy space with a bar & live entertainment supplying international bites all day every day.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -73.9378,
        total_orders: 10.45,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, Salad, Italian, Meatball, Spicy, Delicious, Creamy, Cozy, Homemade, Mediterranean',
        annual_dol: 42.7,
        description:
            '"Geena’s Cucina is a casual, full service trattoria specializing in authentic, al forno pizza and other Italian specialties. From an extensive list of traditional and creative al forno pizza, to pastas, fresh salads and homemade desserts, the classic flavors of Italy are offered throughout our menu for Lunch or Dinner. The bar features a variety of hand selected liquors, domestic and imported wines, and local craft beers. Combining historic architectural elements with a clean, contemporary look, Geena’s Cucina offers cozy tables for two and community tables for socializing or larger parties. Our commitment to quality and preparation can be enjoyed at every visit. Geena\'s Cucina: where pizza is our soul"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.735,
        store_longitude: -73.9882,
        total_orders: 3076.01,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Upscale, Legendary, Spanish, Classic, Spacious, American, Organic, Perfect, Incredible, Great',
        annual_dol: 3141.28,
        description:
            'Irving Plaza is a ballroom-style music venue located within the Union Square neighborhood of Manhattan in New York City. It has been featured on the Complex City Guide list of "50 Best Concert Venues of America" in 2013.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7299,
        store_longitude: -73.8607,
        total_orders: 390.87,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Sushi, Salad, Wine, Japanese, Sashimi, Spicy, Trendy, Wonton Soup, Classy, Casual',
        annual_dol: 504.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5883,
        store_longitude: -74.0903,
        total_orders: 19.19,
        neighborhood: 'Dongan Hills',
        keywords:
            'Burger, Pizza, Salad, Italian, Delicious, Sweet, American, Fresh, Casual, Excellent',
        annual_dol: 78.43,
        description:
            'Offshoot of a longtime NYC bakery offering an array of sweets plus lunch & breakfast items.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5914,
        store_longitude: -73.9404,
        total_orders: 2962.51,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Sushi, Burger, Salad, Steak, Chinese, Spicy, Delicious, Turkish, Japanese, Thai',
        annual_dol: 3266.91,
        description:
            'Casual small-chain eatery serving a variety of Chinese fare, plus classic & creative sushi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9781,
        total_orders: 2367.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Upscale, French, Elegant, Classy, Polished, Comfortable, Traditional, Mediterranean, Classic',
        annual_dol: 8292.2,
        description:
            'Wine & French fare served in a dramatic, upscale eatery with greenery, high ceilings & city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.9788,
        total_orders: 235.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Vegan, Mexican, Flavorful, Meatball, Wine, Champagne, Dessert, American, Asian',
        annual_dol: 956.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9774,
        total_orders: 90.13,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Chocolate Cake, Salad, Pizza, Tasty, Wine, Spicy, Delicious, Apple Pie',
        annual_dol: 156.9,
        description:
            'Exposed brick, dark woods & an open kitchen set a casual backdrop for kosher steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8834,
        store_longitude: -73.9018,
        total_orders: 3.31,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Steak, Hamburger, American, Impeccable, Excellent, Great, Casual, Local, Attentive',
        annual_dol: 13.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.9685,
        total_orders: 2655.38,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Beer, Coffee, Mimosa, Nachos, American, Bellini, Quaint, Lively, Local',
        annual_dol: 2675.27,
        description:
            'Farm-to-table bistro & brunch spot serving creative comfort eats & cocktails amid a lively ambiance.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8755,
        store_longitude: -73.5996,
        total_orders: 1273.32,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Cheesesteak, Pizza, Sushi, Italian, Dessert, Wine, Swiss',
        annual_dol: 1329.89,
        description:
            'Lively American gastropub with small plates, creative entrees & an antique bar dating to the 1880s.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6219,
        store_longitude: -74.0267,
        total_orders: 45.97,
        neighborhood: 'Fort Hamilton/Bay Ridge',
        keywords:
            'Mexican, Tacos, Burritos, Salad, Delicious, Local, Premium, Casual, Fresh, Great',
        annual_dol: 249.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7368,
        store_longitude: -73.7472,
        total_orders: 34.74,
        neighborhood: 'Oakland Gardens/Bayside',
        keywords:
            'Pizza, Italian, Vodka, Wine, Dessert, Meatball, Fried Shrimp, Thai, Casual, Chic',
        annual_dol: 142.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -73.8863,
        total_orders: 123.73,
        neighborhood: 'Middle Village/Flushing',
        keywords:
            'Pizza, Italian, Meatball, Beer, Wine, Healthy, Friendly, Outstanding, Great, Casual',
        annual_dol: 505.75,
        description:
            'Pasta, grilled meats & other Italian fare, plus wine, in a laid-back outfit with a bar & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6432,
        store_longitude: -73.6342,
        total_orders: 371.07,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Vegan, Gluten Free, Flavorful, Wine, Thai, Yummy, Classy, Perfect',
        annual_dol: 410.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9819,
        total_orders: 5842.43,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Canadian, Hotel, Coffee, Cheesecake, Legendary, Classic, French, Vibrant, Great, Excellent',
        annual_dol: 5845.52,
        description:
            'on foot from Grand Central Terminal trains.Polished rooms with period furnishings offer flat-screen TVs and free Wi-Fi. Suites add separate living areas with sofabeds, and upgraded suites also have soaking tubs and minifridges.There\'s a famed restaurant that was frequented in the 1920s by a group of literary and theater figures known as "The Vicious Circle". Other amenities include a ritzy bar and event space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9867,
        total_orders: 1509.76,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Salad, Italian, Dessert, Chicken Parmigiana, Wine, Martini, Spicy, German, Warm, Polished',
        annual_dol: 1591.19,
        description:
            'Bustling Italian spot serving classic red-sauce dishes in a warm setting with a bar & exposed brick.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7046,
        store_longitude: -73.9873,
        total_orders: 856.02,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Wine, Tequila, Flavorful, Salad, Mediterranean, Upscale, Romantic, Delicious, Seasonal, Sweet',
        annual_dol: 976.44,
        description:
            'Contemporary place for wood-fired Med & Middle Eastern fare with wine, cocktails & river views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.683,
        store_longitude: -73.9503,
        total_orders: 15.18,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vodka, Beer, Martini, Coffee, Trendy, Rum, Salad, Spicy, Friendly, Bloody Mary',
        annual_dol: 54.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9943,
        total_orders: 3237.62,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Tequila, Whiskey, Vodka, Wine, Beer, Pizza, Irish, Mimosa, Upscale, Bellini',
        annual_dol: 3275.55,
        description:
            'Irish bar across from Penn Station serving Guinness & other Celtic faves to wash down the pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.9915,
        total_orders: 2546.46,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Steak, Lobster, Cheesecake, Salad, Lobster Bisque, Wine, Delicious, French, Caesar Salad, Sleek',
        annual_dol: 2704.78,
        description:
            'High-end steakhouse serving an array of meats & seafood plus wine in a simple dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7539,
        store_longitude: -74.0009,
        total_orders: 5604.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Margarita, Warm, Inviting, Fancy, Perfect, Fun, Bbq, Clean, American',
        annual_dol: 8611.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.755,
        store_longitude: -73.9986,
        total_orders: 631.74,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tequila, Whiskey, Coffee, Beer, Wine, Vegetarian, American, Stylish, Hotel, British',
        annual_dol: 672.3,
        description:
            'All-day dining on seasonal fare with craft cocktails & wine in a handsome, contemporary hotel space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.9899,
        total_orders: 3347.8,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Wine, Hotel, Italian, Steak, Stylish, Delicious, High-End, American, Classic, Chic',
        annual_dol: 3727.55,
        description:
            "Stylish modern rooms have Wi-Fi access, iPod docks and flat-screens, plus rainfall showers. Suites include pool tables, private balconies and/or hot tubs. In-room hair-styling services and 24-hour room service are available.Loaner bikes and iPads are available. There's an intimate home-style Italian eatery, and a chic bar with a fireplace and a seasonal patio. Additional amenities include a spa, an outdoor terrace with a multimedia screen, and a fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6561,
        store_longitude: -73.9598,
        total_orders: 768.63,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Vegan, Vegetarian, Salad, Wine, Roasted Chicken, Zen, French, Healthy, Spicy, Funky',
        annual_dol: 801.96,
        description:
            'Funky cafe for creative fusion eats, roasted chicken & a full bar, plus outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -74.0011,
        total_orders: 255.25,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Flavorful, Cozy, Hotel, Intimate, Great, Signature',
        annual_dol: 1043.38,
        description:
            'Cozy underground bar featuring up-&-coming comedians & open-mike events 7 nights a week.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7744,
        store_longitude: -73.9079,
        total_orders: 249.03,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Salad, Steak, Fried Calamari, Swiss, Greek, American, Greek Salad, Southern, Casual, Fresh',
        annual_dol: 1017.93,
        description:
            "Lively eatery specializing in fresh Greek seafood & sides that doesn't take reservations.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -74.0008,
        total_orders: 7.25,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Georgian, Salad, Kebab, Mediterranean, Cozy, Russian, Classy, Authentic, Fresh',
        annual_dol: 29.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8895,
        store_longitude: -73.8992,
        total_orders: 732.82,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Peruvian, Tasty, Rich, Asian, Japanese, Latin, Fusion, American, Sangria, Indian',
        annual_dol: 1512.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.9843,
        total_orders: 1005.23,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Champagne, Vodka, Whiskey, Martini, Delicious, Trendy, Rum, Hotel, Karaoke, Fun',
        annual_dol: 3907.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8091,
        store_longitude: -73.9442,
        total_orders: 117.45,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Steak, Salad, Nachos, Bbq, Caesar Salad, Gumbo, American',
        annual_dol: 515.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9825,
        total_orders: 397.77,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Italian, Delicious, Warm, Classy, Friendly, Casual, Seasonal, Southern, Fresh, Fantastic',
        annual_dol: 557.25,
        description:
            '"Beppe’s inspiration is the Tuscan countryside, where the passion for fresh, seasonal ingredients and an innate knack for true hospitality perfectly coincide. We deliver both to your table, a warm taste of Tuscany right in the heart of NYC."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9829,
        total_orders: 708.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Champagne, Margarita, Premium, Exotic, Sashimi, Great, Casual, Fine, Signature',
        annual_dol: 846.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8145,
        store_longitude: -73.9595,
        total_orders: 534.8,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Steak, Mexican, Vegetarian, Salad, Milkshake, Flavorful, Margarita, Fruity, Fajitas',
        annual_dol: 634.14,
        description:
            'Casual cantina serving a variety of Mexican dishes, from fajitas to pozole, plus fruity cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.965,
        total_orders: 82.41,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Dessert, Warm, Seasonal, Sweet, Spacious, Contemporary, Modern, American, Premium',
        annual_dol: 336.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6884,
        store_longitude: -73.98,
        total_orders: 693.74,
        neighborhood: 'Fort Greene',
        keywords:
            'Steak, Tacos, Mexican, Tequila, Salad, Burritos, French Toast, Ale, French, Trendy',
        annual_dol: 874.87,
        description:
            'Cool Mexican eatery serving enchiladas, tacos & quesadillas, plus weekend brunch & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7402,
        store_longitude: -73.9926,
        total_orders: 648.86,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Luxurious, Upscale, Glamorous, Premium, Comfortable, Vivid, Smooth, Incredible, Natural, Diverse',
        annual_dol: 699.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7411,
        store_longitude: -74.0049,
        total_orders: 89.32,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Italian, Beer, Ale, Wine, American, Hotel, Classic, Fun, Great',
        annual_dol: 380.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7233,
        store_longitude: -73.9882,
        total_orders: 1302.95,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Steak, Tacos, Salad, Gourmet, Beer, Roast Beef, Italian, Wine, Upscale',
        annual_dol: 1330.6,
        description:
            'East Village Irish bar with food & drink upstairs & dancing in the subterranean lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6128,
        store_longitude: -74.1586,
        total_orders: 935.78,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Steak, Burger, Beer, Chicken Wings, Ale, Tex-Mex, American, Creamy, Handcrafted, Casual',
        annual_dol: 1191.5,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5889,
        store_longitude: -73.6512,
        total_orders: 241.67,
        neighborhood: '',
        keywords: 'Pizza, Wine, Beer, Fun, Great, Classic, Casual, Local',
        annual_dol: 261.98,
        description:
            '"Small local pub with a classic jukebox and the coldest beer in town. Outdoor deck seating (seasonal). No kitchen, Cash only."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6131,
        store_longitude: -74.1753,
        total_orders: 5883.53,
        neighborhood: 'Bloomfield/Mid Island',
        keywords: 'Italian, Hotel, Upscale, Premium, Amazing, Chic, Great, Modern',
        annual_dol: 6387.05,
        description:
            "Rooms feature flat-screen TVs, microwaves, minifridges and free WiFi, as well as chic decor and custom-designed beds.In addition to an upscale Italian restaurant and cabaret with select evening performances, there's a modern lounge with live entertainment and a 24/7 pantry. There's also a fitness center. Other perks include a 10,000 sq ft ballroom overlooking landscaped gardens and a free Newark Liberty International Airport shuttle.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7093,
        store_longitude: -74.0057,
        total_orders: 744.77,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Steak, Chicken Pot Pie, Salad, Shrimp Cocktail, Beer, Coffee, Tasty, Irish, American',
        annual_dol: 794.28,
        description:
            'Unassuming pub with a dining room featuring an Irish-American menu, weekend brunch & weekly karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6134,
        store_longitude: -74.1007,
        total_orders: 112.53,
        neighborhood: 'Sunnyside/Mid Island',
        keywords:
            'Pizza, Italian, Gluten Free, Delicious, Meatball, Cozy, Sweet, American, Classic, Wonderful',
        annual_dol: 459.98,
        description:
            'Go-to serving traditional & original Italian fare since 1971 & hangout with a lively bar scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7183,
        store_longitude: -73.9437,
        total_orders: 34.54,
        neighborhood: 'Williamsburg',
        keywords:
            'Garlic Bread, Salad, Steak, Italian, Shrimp Cocktail, Wine, French, Vintage, Healthy, Perfect',
        annual_dol: 76.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.6969,
        total_orders: 120.1,
        neighborhood: '',
        keywords:
            'Steak, Hamburger, Beer, Wine, French Onion Soup, Spicy, French, American, Irish, Relaxed',
        annual_dol: 160.63,
        description:
            'Relaxed Irish pub featuring sandwiches & classic mains, plus patio seating & occasional live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8176,
        store_longitude: -73.9169,
        total_orders: 11.48,
        neighborhood: 'Melrose/West Bronx',
        keywords:
            'Steak, Salad, Mexican, Sushi, Ecuadorian, French, Bbq, Spanish, Latin, Delicious',
        annual_dol: 46.91,
        description:
            'No-frills corner option featuring Latin-style steaks, ribs & chicken in compact digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7495,
        store_longitude: -73.9769,
        total_orders: 2426.98,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Hotel, Wine, Stylish, Tequila, Spicy, Beer, Margarita, Amazing, Colorful, Clean',
        annual_dol: 2631.09,
        description:
            "Bright, simple rooms with free Wi-Fi offer work stations, iPod docks and flat-screen TVs, plus glass-enclosed bathrooms with rainfall showerheads. Some rooms have bunk beds.Free walking tours of the city are offered. Additional amenities include a colorful communal game room with table tennis and a fireplace, plus a stylish rooftop lounge and a seasonal bar. There's also a casual ground-floor restaurant.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.9775,
        total_orders: 4025.59,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Steak, Salad, Dessert, Japanese, Delicious, Rich, Sashimi, Opulent, Sleek',
        annual_dol: 5154.43,
        description:
            'Sleek but comfy Japanese spot serving izakaya dishes & skewers, plus sushi, sashimi & sake.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7464,
        store_longitude: -73.9852,
        total_orders: 8211.83,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Pizza, Italian, French, Meatball, Hotel, Spicy, American, Rooftop, Vintage',
        annual_dol: 9166.52,
        description:
            'Brick-walled restaurant & bar in the Arlo NoMad serving Italian-American pizzas, pastas & entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9585,
        total_orders: 2762.06,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Whiskey, Ale, Friendly, Casual, Fun, Local, Great, Excellent, Chill',
        annual_dol: 2820.67,
        description:
            'Chill watering hole pouring brews & cocktails amid throwback Brooklyn decor & neon beer signs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7442,
        store_longitude: -73.9832,
        total_orders: 213.05,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Burger, Pizza, Irish, American, Hotel, Magical, Casual, Perfect, Local',
        annual_dol: 870.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.6341,
        total_orders: 600.66,
        neighborhood: '',
        keywords:
            'Salad, Italian, Wine, Pizza, Savory, Delicious, Mediterranean, Clean, Friendly, Authentic',
        annual_dol: 626.7,
        description:
            'Italian restaurant & bar serving homestyle dishes in a setting with plush chairs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7165,
        store_longitude: -73.6442,
        total_orders: 993.62,
        neighborhood: '',
        keywords: 'Golfclub, Exquisite, Remarkable',
        annual_dol: 1161.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7318,
        store_longitude: -74.0014,
        total_orders: 1064.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tasty, Wine, Delicious, Spicy, Beer, Trendy, Korean, Karaoke, Juicy, Fun',
        annual_dol: 1133.06,
        description:
            'Trendy cocktail bar featuring creative Korean small plates & karaoke in a historical townhouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.6324,
        total_orders: 48.53,
        neighborhood: '',
        keywords:
            'Delicious, Friendly, Authentic, Homemade, Amazing, Great, Mediterranean, Local, Casual, Fine',
        annual_dol: 148.39,
        description:
            'Seafood-focused Portuguese cuisine plus sangria & other cocktails served in a down-to-earth setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7095,
        store_longitude: -73.6275,
        total_orders: 37.76,
        neighborhood: '',
        keywords: 'Steak, American, Latin, Casual, Traditional, Local',
        annual_dol: 54.43,
        description:
            '"Restaurante de almejas con una amplia selección de platos hispanos. El restaurante también organiza eventos y fiestas. Si desea reservar un evento con nosotros, llame al 51649396624 or el manager 5165674608"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6999,
        store_longitude: -73.9094,
        total_orders: 25.33,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Casual, Recommended',
        annual_dol: 103.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6749,
        store_longitude: -73.9817,
        total_orders: 1544.41,
        neighborhood: 'Park Slope',
        keywords: 'Trendy, Rum, Caribbean, Upscale, Spacious, Bbq, Lively, Innovative, Fun, Mimosa',
        annual_dol: 1684.89,
        description:
            'Updated Caribbean cooking & signature cocktails are served in a stylish, island-inspired setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.761,
        store_longitude: -73.916,
        total_orders: 368.13,
        neighborhood: 'Astoria',
        keywords: 'Vegan, Pizza, Wine, Beer, Gourmet, American, Global, Spicy, Eclectic, Diverse',
        annual_dol: 392.6,
        description:
            'Wine bar with gourmet sharing plates, pizzas, charcuterie & cheese trays & infused cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6747,
        store_longitude: -73.9814,
        total_orders: 566.53,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Hamburger, Beer, Salad, Mexican, Gourmet, Tasty, Dessert, Wine, Organic',
        annual_dol: 603.76,
        description:
            'Rustic-chic burger joint serving innovative burgers, plus craft draft beer & clever cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.723,
        store_longitude: -73.9502,
        total_orders: 254.36,
        neighborhood: 'Greenpoint',
        keywords:
            'Beer, Trendy, Polish, Delicious, Sweet, Classy, Intimate, Bbq, Authentic, Traditional',
        annual_dol: 1039.75,
        description:
            'Local outpost of a Polish concept serving pierogi & traditional plates in an easygoing setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6791,
        store_longitude: -74.011,
        total_orders: 9997.51,
        neighborhood: 'Red Hook',
        keywords: 'Burger, Lobster, Salad, Beer, French, Bbq, Trendy, American, Spacious, Ample',
        annual_dol: 10209.64,
        description:
            'Bar food, a big beer list & an ample back patio bring locals to this laid-back tavern.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7498,
        store_longitude: -73.9394,
        total_orders: 114.82,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Cheesesteak, Pizza, Salad, Beer, Italian, Nachos, Tex-Mex, Philly Cheesesteak, American',
        annual_dol: 122.45,
        description:
            'Relaxed neighborhood gathering spot for beer, classic cocktails & bar bites in a no-frills setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5463,
        store_longitude: -74.2295,
        total_orders: 196.24,
        neighborhood: 'Charleston',
        keywords: 'Mexican, Unforgettable, Great, Wonderful, High-End, Unique, Casual',
        annual_dol: 299.98,
        description: '"Staten Islands Only Gentleman\'s Club"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7275,
        store_longitude: -73.9902,
        total_orders: 370.31,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Beer, Cozy, Friendly, Classic, Great, Casual, Local',
        annual_dol: 394.65,
        description:
            'Intimate, down-to-earth bar with frequent entertainment, including DJs, movie nights & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7323,
        store_longitude: -73.9932,
        total_orders: 2214.8,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Italian, Salad, Dessert, Spicy, Cozy, Romantic, Classy, Friendly, American',
        annual_dol: 2261.79,
        description:
            'Tuscan cooking & wines await at this low-lit, flower-filled celebrity hangout.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7274,
        store_longitude: -73.9864,
        total_orders: 2348.64,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tequila, Beer, Martini, Upscale, Fun, Fancy, Friendly, Fantastic, Cool, Great',
        annual_dol: 2450.47,
        description:
            'Veteran East Village watering hole offering cheap drinks & pool in a bare-bones atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8022,
        store_longitude: -73.9642,
        total_orders: 1649.47,
        neighborhood: 'Manhattan Valley/UWS',
        keywords: 'Burger, Hotdog, Hamburger, Beer, Salad, Ale, Gin, Casual, Classic, Local',
        annual_dol: 1702.09,
        description:
            'Local bar popular among collegiate types for its daily food & drink specials such as dollar beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.646,
        store_longitude: -73.972,
        total_orders: 2388.9,
        neighborhood: 'Kensington',
        keywords: 'Zombie, Beer, Wine, Funky, Charming, Cozy, Awesome, Friendly, Clean, Colorful',
        annual_dol: 2439.59,
        description:
            'A colorful bar with a charming back patio offering beer, wine, cocktails & a few food options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5514,
        store_longitude: -74.1506,
        total_orders: 29.53,
        neighborhood: 'Great Kills',
        keywords:
            'Beer, Whiskey, Irish, Awesome, Classic, Great, Friendly, Recommended, Local, Casual',
        annual_dol: 34.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7963,
        store_longitude: -73.9709,
        total_orders: 4.69,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Steak, Italian, Wine, Beer, Classy, Innovative, Classic, Casual, Fresh, Great',
        annual_dol: 19.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5779,
        store_longitude: -73.9598,
        total_orders: 26.83,
        neighborhood: 'Brighton Beach',
        keywords: 'Wine, Beer, Trendy, Russian, Hotel, American, Classic, Friendly, Casual, Great',
        annual_dol: 109.67,
        description:
            'Simple neighborhood pool hall with a large selection of tables also provides Ping-Pong & late hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6241,
        store_longitude: -74.031,
        total_orders: 1538.44,
        neighborhood: 'Bay Ridge',
        keywords: 'Mexican, Beer, Margarita, Spanish, Salad, Casual, Friendly, Great',
        annual_dol: 1905.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9913,
        total_orders: 1508.48,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Vegan, Wine, Gluten Free, Dessert, French, Tunisian',
        annual_dol: 1873.93,
        description:
            'Pre-theater standby turning out French-Mediterranean staples in an upscale brasserie setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6933,
        store_longitude: -73.9691,
        total_orders: 2010.77,
        neighborhood: 'Clinton Hill',
        keywords: 'Burger, Steak, Vegan, Beer, Coffee, Wine, Ale, Dessert, Upscale, Irish',
        annual_dol: 2060.34,
        description:
            'Relaxed gastropub with extensive selections of upscale pub grub, entrees & local craft brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7729,
        store_longitude: -73.7333,
        total_orders: 550.51,
        neighborhood: '',
        keywords:
            'Lobster, Salad, Sushi, Hawaiian, Gluten Free, Dessert, Flavorful, Japanese, Wine, Rich',
        annual_dol: 643.2,
        description:
            'A sophisticated selection of Chinese & Japanese dishes, sushi & cocktails in elegant surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9838,
        total_orders: 1704.25,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Steak, Cuban, Beer, Wine, Margarita, Caribbean, Italian, Rum, American, Latin',
        annual_dol: 1839.87,
        description:
            'Boisterous restaurant featuring Cuban fare, cocktails & live music in a retro-Havana setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6983,
        store_longitude: -73.9751,
        total_orders: 4390.15,
        neighborhood: 'Brooklyn Navy Yard',
        keywords: 'Beer, Iconic, Spectacular, Exceptional, Cool, Complex',
        annual_dol: 5976.37,
        description:
            '"New York\'s finest events venues, showcasing spectacular Manhattan and bridge views."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6838,
        store_longitude: -73.9538,
        total_orders: 55.32,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tequila, Tacos, Burger, Beer, American, Delicious, Perfect, Modern, Amazing, Great',
        annual_dol: 226.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6856,
        store_longitude: -73.991,
        total_orders: 253.45,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Zombie, Pizza, Beer, Karaoke, American, Warm, Cozy, Modern, Classic, Friendly',
        annual_dol: 954.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7467,
        store_longitude: -73.9916,
        total_orders: 941.35,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Whiskey Sour, Greek, Salad, Whiskey, Mediterranean, Delicious, Hotel, Rooftop, Gin, Modern',
        annual_dol: 1317.53,
        description:
            'Swanky Greek restaurant inside the Hotel Hayden with city views & elaborate rope decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.9876,
        total_orders: 968.35,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Sushi, Lobster, Savory, Japanese, Spicy, Sashimi, Sustainable, Local, Casual, Omakase',
        annual_dol: 1283.11,
        description:
            'Little Japanese counter serve preparing sushi & rice bowls amid sea-blue lighting & ocean decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -73.9935,
        total_orders: 1214.77,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Vegetarian, Wine, Dessert, Bourbon, Warm, Juicy, Hotel, Bbq, Irish',
        annual_dol: 4965.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7783,
        store_longitude: -73.9804,
        total_orders: 43.27,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Salad, Wine, Beer, Spicy, Impeccable, Mediterranean, Romantic, Israeli, Polished',
        annual_dol: 176.87,
        description:
            'Mediterranean restaurant helmed by an Israeli chef, featuring an open kitchen & two stories.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7405,
        store_longitude: -73.9908,
        total_orders: 152.66,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Delicious, Wine, Italian, Elegant, Romantic, French Toast, Greek, French, Sleek',
        annual_dol: 624.03,
        description:
            'Greek restaurant serving steak, lamb chops, seafood & more in a romantic atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7223,
        store_longitude: -73.9886,
        total_orders: 7296.41,
        neighborhood: 'Lower East Side',
        keywords: 'Sushi, Hotel, Trendy, Beer, Spacious, Japanese, Bbq, Premium, Seasonal, Modern',
        annual_dol: 7676.29,
        description:
            "Sleek rooms, many with floor-to-ceiling windows, offer flat-screen TVs and wood floors. Upgraded rooms add sitting areas and/or balconies. Suites provide wet bars; upgraded suites have terraces and/or living areas. Swanky 2-bedroom or bi-level penthouses feature living rooms with dining areas, and city-view terraces.Amenities include a chic Japanese restaurant, a seasonal rooftop bar and a lively beer garden. There's a spa, a gym and a seasonal rooftop pool with a picture of Andy Warhol at the bottom.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9901,
        total_orders: 14156.59,
        neighborhood: 'Lower East Side',
        keywords:
            'Lobster, Lobster Roll, Burger, Pizza, Wine, Beer, Vodka, Whiskey, Italian, Bourbon',
        annual_dol: 14599.44,
        description:
            'Relaxed choice for drinks & New England shore favorites in shabby-chic quarters with a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8115,
        store_longitude: -73.9578,
        total_orders: 143.6,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Sushi, Steak, Japanese, Spicy, Asian, Thai, Filet Mignon, Fusion, Dope, Modern',
        annual_dol: 224.89,
        description:
            'Modern Asian-fusion restaurant offering sushi, stir-fries, noodle soups & sake, plus lunch specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9744,
        total_orders: 190.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Steak, Pizza, Salad, Italian, Vodka, Meatball, Swiss, Mediterranean, Caesar Wrap',
        annual_dol: 778.67,
        description:
            'This bright counter serve is the take-out & delivery arm of the Tuscan eatery Fresco by Scotto.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9825,
        total_orders: 52.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Sushi, Lobster, Vegetarian, Japanese, Spicy, Sashimi, Delicious, Casual, Western',
        annual_dol: 183.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -73.9981,
        total_orders: 729.49,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Italian, Mexican, Wine, Salad, Upscale, Hotel, Homemade, Polished, Local, Classic',
        annual_dol: 778.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7113,
        store_longitude: -74.0061,
        total_orders: 14017.03,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Cheesesteak, Spicy, Chinese, Delicious, Fried Rice, Warm, Peking Duck, French, Philly Cheesesteak',
        annual_dol: 14546.52,
        description:
            'Chic restaurant/bar offering American steakhouse fare, Chinese dumplings, Peking duck & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7126,
        store_longitude: -73.9567,
        total_orders: 193.44,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Hotdog, Tequila, Beer, Shrimp Cocktail, Mimosa, Wine, Ale, Warm, Cozy, Comfortable',
        annual_dol: 215.71,
        description:
            'Comfortable destination featuring local beer & Prosecco on tap, as well as Skee-Ball.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7104,
        store_longitude: -73.9552,
        total_orders: 1116.62,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Salad, Sushi, Italian, Tasty, Rustic, Artisanal, Sweet, Seasonal, Perfect, Tiramisu',
        annual_dol: 1333.87,
        description:
            'A Tuscan-styled dining room & a large, leafy garden set the stage for rustic Italian cooking.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -73.9778,
        total_orders: 1025.96,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Burger, Hamburger, Vegetarian, Mexican, Beer, American, Irish, Greek, Classic, Casual',
        annual_dol: 1079.93,
        description:
            'This neighborhood bar is known for its classic burgers & year-round patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6721,
        store_longitude: -73.9576,
        total_orders: 363.67,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Salad, Savory, Delicious, Bbq, Friendly, Southern, Fantastic, Excellent, Great',
        annual_dol: 509.47,
        description:
            'Low-key tavern dishing up BBQ & other Southern grub, plus sports on TV & occasional live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.682,
        store_longitude: -73.9584,
        total_orders: 388.47,
        neighborhood: 'Bedford-Stuyvesant/Clinton Hill',
        keywords:
            'Wine, Beer, Delicious, American, Premium, Fancy, Classic, Natural, Great, Incredible',
        annual_dol: 763.64,
        description:
            'Casual restaurant specializing in rotisserie chicken & natural wine in a wood-lined space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.9836,
        total_orders: 204.1,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Vegan, Tequila, Mexican, Vegetarian, Gluten Free, Sustainable, American, Elegant, Contemporary, Modern',
        annual_dol: 277.85,
        description:
            '"Etérea is Overthrow Hospitality\'s newest restaurant featuring an elegant Tequila and Mezcal Cocktail Bar devoted to ethical and sustainable agave spirits curated by Mixologist Sother Teague, who also helms our award-winning bar, Amor y Amargo! Executive Chef Xila Caudillo runs the Culinary Program, and it features robust plant-based, gluten-free small plates that honor her Mexican Heritage and SoCal Upbringing"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.9798,
        total_orders: 802.55,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Lobster, Lobster Roll, Japanese, Shrimp Cocktail, Hotel, Champagne, Upscale, Wine, Trendy, Elegant',
        annual_dol: 2817.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9577,
        total_orders: 1155.89,
        neighborhood: 'Williamsburg',
        keywords: 'Elegant, Beer, Spacious, Clean, Friendly, Cool, Awesome, Great, Casual, Dope',
        annual_dol: 1287.01,
        description:
            'Lively, late-night pool hall offering billiard tables, board games, pub fare & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7629,
        store_longitude: -73.9211,
        total_orders: 6.93,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords: 'Italian, Wine, Friendly, Casual, Authentic, Sweet, Local, Excellent',
        annual_dol: 28.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6834,
        store_longitude: -73.932,
        total_orders: 763.19,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Vegetarian, Wine, Salad, Beer, Rustic, American, French, Romantic, Friendly, Natural',
        annual_dol: 807.12,
        description:
            'Chic, modern hub for creative American fare including brunch & full bar featuring house cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5894,
        store_longitude: -73.7482,
        total_orders: 126.9,
        neighborhood: '',
        keywords: 'Fun, Hotel, Great, Terrific',
        annual_dol: 179.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5735,
        store_longitude: -73.9876,
        total_orders: 246.08,
        neighborhood: 'Coney Island',
        keywords:
            'Pizza, Wine, Beer, Hotel, American, Irish, Rooftop, Innovative, Vibrant, Classic',
        annual_dol: 1005.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.729,
        store_longitude: -73.9883,
        total_orders: 1785.5,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Sushi, Japanese, Sashimi, Hotel, Vintage, Ramen, Gyoza, Friendly, Adventurous, Authentic',
        annual_dol: 1859.02,
        description:
            'Japanese restaurant known for street-food-style fare & inexpensive beer, along with flashy decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7311,
        store_longitude: -73.9902,
        total_orders: 9900.74,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Salad, Dessert, Ramen, Beer, Japanese, Creme Brulee, Spicy, Modern, Smooth, Premium',
        annual_dol: 11341,
        description:
            'Ramen dishes & pork buns are the lures at this popular East Village Japanese eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7382,
        store_longitude: -73.9908,
        total_orders: 540.02,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Italian, Wine, Gluten Free, Coffee, Salad, Dessert, Bruschetta, Cozy, Rustic, Homemade',
        annual_dol: 551.48,
        description:
            'Espresso drinks & wines by the glass go with Italian small plates & baked goods at this urbane cafe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.8817,
        total_orders: 876.99,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Coffee, Wine, Donut, Latin, Perfect, American, Awesome, Great, Wonderful, Lit',
        annual_dol: 935.29,
        description:
            'Offbeat venue for live music, literary events & film screenings, with a full bar as well.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8508,
        store_longitude: -73.9329,
        total_orders: 280.8,
        neighborhood: 'Fort George/Washington Heights',
        keywords: 'Spanish, Club Sandwich, Casual, Gyro, Tradition',
        annual_dol: 1147.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -73.9892,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Pizza, Gourmet, Salad, Delicious, Fresh, American, Caribbean, Great, British',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.9804,
        total_orders: 4093.67,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Salad, Chicken Pot Pie, Beer, French Onion Soup, Irish, Swiss, French, Old Fashioned, Greek, American',
        annual_dol: 4427.35,
        description:
            'Charming Irish pub & restaurant offering American grub plus a full bar and a rooftop courtyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7573,
        store_longitude: -73.9778,
        total_orders: 860.35,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Cheesesteak, Steak, Sushi, Salad, Irish, Philly Cheesesteak, French Onion Soup, French, Vintage, American',
        annual_dol: 3059.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6836,
        store_longitude: -73.8342,
        total_orders: 30.88,
        neighborhood: 'Ozone Park/South Richmond Hill',
        keywords: 'Caribbean, Local, Casual, Attentive, Great',
        annual_dol: 126.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7586,
        store_longitude: -73.9719,
        total_orders: 2363.09,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Sushi, Japanese, Steak, Omelette, Casual, Classic, Unique, Authentic, Great',
        annual_dol: 2928.66,
        description:
            'High-end Japanese locale serving sushi & teppanyaki dishes in a sophisticated, lounge-style setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6241,
        store_longitude: -74.031,
        total_orders: 354.56,
        neighborhood: 'Bay Ridge',
        keywords: 'Lobster, Salad, Italian, Chinese, Spicy, Mongolian, Beer, Thai, Bbq, Fried Rice',
        annual_dol: 362.08,
        description:
            '"Elevated Chinese restaurant serving classic dim sum, rice and noodle dishes, traditional Cantonese specialties, and spicy Sichuan cuisine. Full bar with curated cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6298,
        store_longitude: -74.0283,
        total_orders: 152.66,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Salad, Coffee, Vodka, Dessert, Italian, Margarita, Baked Potato, Caesar Salad, American',
        annual_dol: 163.73,
        description:
            'Circa-1979 cafe serving American eats in a family-friendly space that includes a greenhouse room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9888,
        total_orders: 1631.8,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Chicken Parmigiana, Cheesecake, Shrimp Cocktail, Chilean, Whiskey, Wine, Italian, Shrimp Scampi',
        annual_dol: 1784.81,
        description:
            'Polished, retro-style restaurant serving a menu of aged steaks, cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9895,
        total_orders: 1742.43,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Salad, Dessert, Hotel, Premium, Sweet, Lively, Friendly, Classic, Great',
        annual_dol: 1954.75,
        description: 'This jazz & supper club offers live music, swing dancing & free lessons.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8044,
        store_longitude: -73.9556,
        total_orders: 2245.99,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Whiskey, Salad, Meatloaf, Wine, Beer, Mimosa, Upscale, Relaxed, American, Southern',
        annual_dol: 2940.12,
        description:
            'Relaxed spot with a casual-chic vibe offering modernized soul food classics with cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7743,
        store_longitude: -73.9132,
        total_orders: 22.15,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Pizza, Delicious, Italian, Wine, Sweet, Iconic, Great, Unforgettable, Casual, Local',
        annual_dol: 90.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.9933,
        total_orders: 3757.24,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Vodka, Wine, Ale, Meatball, Delicious, Warm, Irish, Premium, Caesar Wrap',
        annual_dol: 4008.34,
        description:
            'New American small plates & late-night cocktails set the mood at this restaurant/sports bar hybrid.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.9871,
        total_orders: 786.63,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Champagne, Wine, Korean, Beer, Trendy, Coffee, Salad, Vintage, Warm',
        annual_dol: 824.83,
        description:
            'Korean fried chicken, global plates & cocktails fuel patrons enjoying LP tunes & karaoke singers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9831,
        total_orders: 859.12,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Tacos, Beer, Delicious, Irish, Casual, Local',
        annual_dol: 876.02,
        description:
            '"Classy yet easy-going Midtown bar serving tacos and specialty made to order donuts. In the rear is the theatre like Grisly Pear Comedy Club."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6993,
        store_longitude: -73.8073,
        total_orders: 431.2,
        neighborhood: 'Jamaica',
        keywords: 'Whiskey, Coffee, Gourmet, American, Sangria, Omelette, Casual, Great',
        annual_dol: 497.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.9879,
        total_orders: 2151.01,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Whiskey, Margarita, Warm, Fun, Friendly, Western, Fantastic, Great, Casual',
        annual_dol: 2214.72,
        description:
            'Wild West-themed, cash-only gay bar with cowboy decor, country-&-western music & dancing barkeeps.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.761,
        store_longitude: -73.9798,
        total_orders: 5973,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Tail, Steak, Shrimp Cocktail, Sushi, Salad, Lobster Bisque, Pizza, Chilean, Wine',
        annual_dol: 6155.96,
        description:
            'The first NYC branch of a high-end mini-chain, serving steaks, seafood & sushi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7761,
        store_longitude: -73.6502,
        total_orders: 109.42,
        neighborhood: '',
        keywords: 'Sushi, Pizza, Tasty, Beer, Japanese, Spicy, Chilean, Chinese, Rich, Asian',
        annual_dol: 127.61,
        description:
            '"We exemplify modern Japanese dining, highlighting a refine and rich culinary experience. Our artfully presented dishes feature the freshest ingredients with inspirations to please the palate and aims for visual delight. Libations Advisor Mr. Brian Matthys brings his mastery of the cocktails and spirits to our exquisite Cocktail Bar. Under his expert direction, our bar menu will showcase sensational cocktails handcrafted with the best ingredients, quality ice and spirits. A great selection of sake, shochu, wine, and beer is also offered to compliment the cuisine. Sakaya was designed with two distinctive spaces in mind, each with its own unique character. The Dining Room: Our space also features a Sushi Bar and Hibachi room."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7621,
        store_longitude: -73.9776,
        total_orders: 5165.33,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Legendary, Elegant, Vintage, Classic, Vietnamese, Warm, Inviting, Comfortable, American',
        annual_dol: 5277.9,
        description:
            'Times Square.The posh, modern rooms have marble bathrooms, iPod docks, flat-screen TVs and Wi-Fi (surcharge in standard rooms). Suites add amenities such as separate living and dining rooms, fireplaces and/or private balconies. Room service is available.Amenities include a restaurant offering both a la carte and buffet dining, and a classic lounge, plus fitness and business centers. A daily facility fee may apply.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.9755,
        total_orders: 1324.39,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Italian, Elegant, Polished, Perfect, French, Outstanding, Awesome, Lively, Great',
        annual_dol: 3599.24,
        description:
            'Upmarket Italian fare & sizable wine list in a polished setting that includes a private event room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.808,
        store_longitude: -73.945,
        total_orders: 384.65,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Wine, Gin, Tequila, Stylish, Delicious, Vintage, Intimate, Lively, Vibrant, Lit',
        annual_dol: 1365.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8089,
        store_longitude: -73.945,
        total_orders: 278.04,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Mexican, Tacos, Margarita, Salad, Quesadillas, Fajitas, Burritos, Vegetarian, Latin',
        annual_dol: 283.93,
        description:
            'Casual Mexican joint serving up tacos & burritos alongside margaritas & beers from the bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7698,
        store_longitude: -73.9925,
        total_orders: 2899.78,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Clean, Sweet, Bbq, Fancy, Exceptional, Local',
        annual_dol: 3025.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7229,
        store_longitude: -73.9891,
        total_orders: 2351.72,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Hamburger, Margarita, Irish, Spicy, Homemade, Fun, Seasonal, Friendly, Retro',
        annual_dol: 2690.26,
        description:
            'Irish-influenced watering hole with retro 1960s decor offering whiskeys, bar bites & brunch fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9053,
        store_longitude: -73.8979,
        total_orders: 14.12,
        neighborhood: 'North Riverdale',
        keywords: 'Steak, Ale, Irish, Friendly, Great, Excellent, Local, Casual, Attentive',
        annual_dol: 57.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8649,
        store_longitude: -73.9311,
        total_orders: 120.77,
        neighborhood: 'Fort George',
        keywords:
            'Salad, Wine, French, American, Modern, Sidecar, Casual, Bloody Mary, Perfect, Fabulous',
        annual_dol: 493.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7415,
        store_longitude: -74.0053,
        total_orders: 13263.42,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Pizza, Martini, Delicious, Trendy, Bourbon, Rum, Spacious, Fancy, Great',
        annual_dol: 13544.85,
        description:
            'Seattle-based coffeehouse chain known for its signature roasts, light bites and WiFi availability.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6867,
        store_longitude: -73.9759,
        total_orders: 721.62,
        neighborhood: 'Fort Greene',
        keywords: 'Karaoke, Legendary, American, Local, Hip, Cool, Casual',
        annual_dol: 2533.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.74,
        store_longitude: -73.9932,
        total_orders: 621.94,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Wine, Upscale, Polish, Glamorous, Martini, Warm',
        annual_dol: 635.14,
        description:
            '"Upscale and casual fine dining Steakhouse. Enjoy the sound of records playing in a uniquely artistic setting while you enjoy a chef driven steakhouse menu, and thought provoking cocktails and wine list."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7327,
        store_longitude: -73.997,
        total_orders: 128.61,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Lasagna, Wine, Beer, Coffee, Martini, Elegant, Delicious, Spicy, Italian, Crisp',
        annual_dol: 242.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.9586,
        total_orders: 208.39,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Italian, Salad, Beer, Wine, Hawaiian, Dessert, Spicy, Shrimp Scampi, Hotel',
        annual_dol: 392.09,
        description:
            '"MidiCi Neapolitan Pizza is now open in Williamsburg with authentic brick oven pizza, appetizers, salads, Pasta, and desserts made with fresh, natural and mostly non-GMO ingredients in our open kitchen. Sit in a unique setting with a glass of wine or at the bar with a local craft beer."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.708,
        store_longitude: -74.0064,
        total_orders: 2591.72,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Steak, Pizza, Chicken Pot Pie, Irish, Rustic, American, Classic, Fresh, Great',
        annual_dol: 2655.25,
        description:
            '"Nestled in the Financial District, you’ll find all the signature Stout NYC favorites here on the corner of John and Gold Street. If your office is in the area, come see us for lunch or a well-deserved after-work drink. We’re excited to host bottomless brunch on the weekends. We’re also the best meeting point if you’re headed to an event at the Seaport (or leaving one)."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9609,
        total_orders: 487.42,
        neighborhood: 'Williamsburg',
        keywords: 'Sushi, Cinnamon Roll, Yummy, Sweet, Fancy, Fresh, Great',
        annual_dol: 1992.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -73.9605,
        total_orders: 112.91,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Italian, Salad, Mediterranean, Elegant, Cozy, Romantic, Meatball, Rustic, Fun',
        annual_dol: 263.64,
        description:
            'Italian fare & a deep wine list served in a rustic, candlelit setting that lends a romantic vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9281,
        total_orders: 51.62,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegan, Pizza, Tomato Soup, Salad, Gluten Free, French Toast, Belgian, American, Daiquiri, French',
        annual_dol: 211,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7219,
        store_longitude: -73.9965,
        total_orders: 4612.62,
        neighborhood: 'Nolita',
        keywords: 'Hotdog, Pizza, Beer, Tasty, Upscale, Bloody Mary, Casual, Classic, Local, Great',
        annual_dol: 4711.93,
        description:
            'Neighborhood dive bar since the 1920s draws patrons from 8AM on for basic drinks & weekend bagels.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8047,
        store_longitude: -73.9557,
        total_orders: 2829.58,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Burger, Hamburger, Beer, Bbq, Wine, Warm, Bourbon, Spacious, Eclectic, Fun',
        annual_dol: 3156.51,
        description:
            'Corner tavern/beer garden with extensive food & drink menus in a spacious indoor/outdoor setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7278,
        store_longitude: -73.9994,
        total_orders: 112.79,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Upscale, Modern, British, American, French, Dutch, Innovative, Casual, Negroni',
        annual_dol: 468.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7743,
        store_longitude: -73.9079,
        total_orders: 181.52,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Burger, Garlic Bread, Steak, Salad, Beer, Vodka, Lemon Drop, Tasty, Margarita, Martini',
        annual_dol: 651.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.887,
        total_orders: 408.33,
        neighborhood: 'Jackson Heights',
        keywords:
            'Burger, Steak, Pizza, Salad, Shrimp Cocktail, Beer, Coffee, Wine, Chinese, Fajitas',
        annual_dol: 417,
        description:
            'Rustic-chic restaurant plating egg dishes, pancakes, pasta & other comfort fare, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9783,
        total_orders: 1973.68,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Mexican, Dessert, Spicy, Stylish, Trendy, Tex-Mex, Homemade, Spacious',
        annual_dol: 7241,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9839,
        total_orders: 3797.75,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Bright, Modern, Clean, Great, Wonderful, Rooftop, Friendly, Creative, Contemporary',
        annual_dol: 3878.33,
        description:
            "Hudson River.Sleek rooms with a contemporary vibe feature Wi-Fi, flat-screen TVs and minifridges. Some offer river or Times Square views. Upgraded rooms provide sitting areas.There's a buzzy bar/restaurant with panoramic city views. Other amenities include a gym and parking.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7703,
        store_longitude: -73.9917,
        total_orders: 98.38,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Cheesesteak, Gourmet, Coffee, Beer, Italian, Ale, Hotel, Warm, Premium',
        annual_dol: 402.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7083,
        store_longitude: -74.0133,
        total_orders: 88.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Coffee, Comfortable, Contemporary',
        annual_dol: 363.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7148,
        store_longitude: -73.9909,
        total_orders: 725.23,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Wine, French, Trendy, Martini, Beer, Hotel, Cozy, Sleek, Chic, Natural',
        annual_dol: 758.19,
        description:
            'Hip wine bar offering sidewalk seating & small plates, including oysters & ricotta with honey.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.716,
        store_longitude: -73.9016,
        total_orders: 15.1,
        neighborhood: 'Flushing/Maspeth',
        keywords:
            'Burger, Hotdog, Shrimp Cocktail, Hamburger, Salad, Pizza, Fresh, French, Polish, Greek',
        annual_dol: 47.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.764,
        store_longitude: -73.9709,
        total_orders: 195.04,
        neighborhood: 'Lenox Hill',
        keywords: 'Steak, Salad, French, Premium, Fresh, Great, Fine',
        annual_dol: 797.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6915,
        store_longitude: -73.9842,
        total_orders: 8.28,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Beer, Hotel, American, Rooftop, Casual, Friendly, Great',
        annual_dol: 33.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6872,
        store_longitude: -73.8357,
        total_orders: 33.69,
        neighborhood: 'Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Burger, Steak, Cheesesteak, Chinese, Philly Cheesesteak, Caribbean, Fresh, Local, Casual, Gyro',
        annual_dol: 137.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6758,
        store_longitude: -73.818,
        total_orders: 1632.51,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Bbq, Caribbean, Karaoke, Fried Chicken, Chinese, Jamaican, American, Great, Casual, Signature',
        annual_dol: 1994.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5452,
        store_longitude: -74.1608,
        total_orders: 388.92,
        neighborhood: 'Eltingville',
        keywords:
            'Tequila, Steak, Mexican, Beer, Vodka, Gourmet, Breakfast Burrito, Dessert, Rum, Italian',
        annual_dol: 1511.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7363,
        store_longitude: -74.0007,
        total_orders: 268.46,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Meatball, Italian, Salad, Gluten Free, Dessert, Tasty, Spicy, Wine, Delicious, Gin',
        annual_dol: 880.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7418,
        store_longitude: -74.0055,
        total_orders: 229.78,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Burger, Salad, Beer, Dessert, Turkish, Savory, Hotel, Creamy, Baklava',
        annual_dol: 939.27,
        description:
            'Swanky spot for meat-centric Turkish fare from a chef with a flamboyant serving style.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7175,
        store_longitude: -73.9493,
        total_orders: 1265.16,
        neighborhood: 'Williamsburg',
        keywords: 'Tacos, Tasty, Spicy, Warm, Fish Tacos, Trendy, Bbq, Spacious, Fun, Sweet',
        annual_dol: 1348.3,
        description:
            'Chill local bar with a surf-skate theme serving draft cocktails, fish tacos & burgers alongside DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7117,
        store_longitude: -73.9619,
        total_orders: 21391.03,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Salad, Wine, Vodka, Trendy, Organic, Funky, Thai, Eclectic, Cozy, Seasonal',
        annual_dol: 21844.91,
        description:
            'Creative Latin-Asian cocktails offered in snug surrounds with whitewashed brick walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.677,
        store_longitude: -73.9689,
        total_orders: 5025.21,
        neighborhood: 'Prospect Heights',
        keywords:
            'Wine, Coffee, Upscale, Dessert, Warm, Modernist, Sweet, Welcoming, Perfect, American',
        annual_dol: 5439.15,
        description:
            'American restaurant by a chef-farmer team growing many ingredients in its backyard garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7152,
        store_longitude: -73.9506,
        total_orders: 8029.03,
        neighborhood: 'Williamsburg',
        keywords:
            'Tasty, Vegan, Chinese, Delicious, Savory, Spicy, Gin, Charming, Homemade, Friendly',
        annual_dol: 8985.57,
        description:
            'Modern Chinese haunt serving dishes made with local ingredients, including some from its own garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7244,
        store_longitude: -73.9829,
        total_orders: 1156.51,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Beer, Fun, Vibrant, Great, New Zealand, Nostalgic, Fine, Casual',
        annual_dol: 1230.75,
        description:
            "Bar with darts, Skee-Ball & nostalgic decor that reflects Alphabet City's gritty history.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6881,
        store_longitude: -73.9569,
        total_orders: 562,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tacos, Tequila, Mexican, Beer, Tasty, Wine, Delicious, Sleek, Contemporary, Casual',
        annual_dol: 648.35,
        description:
            'This contemporary bar offers cocktails, frozen drinks & a Mexican food truck in the backyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6379,
        store_longitude: -74.0798,
        total_orders: 18.74,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Sushi, Lobster, Cozy, Clean, Casual, Local, Great, Traditional',
        annual_dol: 76.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.744,
        store_longitude: -73.9871,
        total_orders: 1195.3,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Upscale, Wine, Luxurious, Tasty, Hotel, French, Classy, Refined, Warm, Legendary',
        annual_dol: 1544.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.716,
        store_longitude: -73.9623,
        total_orders: 549.2,
        neighborhood: 'Williamsburg',
        keywords: 'Sushi, Vegan, Vodka, Wine, Spicy, Trendy, Korean, Japanese, Asian, Sweet',
        annual_dol: 625.5,
        description:
            'Trendy locale offering Pan-Asian dining & weekend brunch, plus sake, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7438,
        store_longitude: -73.9828,
        total_orders: 41.79,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Burger, Steak, Salad, Coffee, Beer, American, Rooftop, Fun, Casual, Great',
        annual_dol: 170.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7578,
        store_longitude: -73.9274,
        total_orders: 123.31,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Beer, Wine, Upscale, Cozy, Rustic, Fun, American, Greek, Great',
        annual_dol: 287.47,
        description:
            'This cozy gastropub serving upscale versions of bar food, such as burgers, offers brunch some days.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.748,
        store_longitude: -73.9761,
        total_orders: 5.09,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Vegan, Vegetarian, Salad, Gluten Free, Beer, Natural, Contemporary, Authentic, Classy, Casual',
        annual_dol: 20.81,
        description:
            'Vegetarian & vegan Indian dishes are served at this contemporary eatery with a back garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8156,
        store_longitude: -73.96,
        total_orders: 5096.42,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Vegetarian, Vegan, Salad, Italian, Nachos, Beer, Armenian, Indonesian, Thai, Asian',
        annual_dol: 5670.29,
        description:
            'Cozy, wood-accented watering hole serving Southeast Asian bites plus cocktails, sake, beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.764,
        store_longitude: -73.9769,
        total_orders: 2882.63,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Steak, Dessert, Turkey Sandwich, French Onion Soup, French, Japanese, Elegant, Warm, Sashimi',
        annual_dol: 2943.8,
        description:
            'Straight-up French brasserie fare plus sushi & sashimi in a convenient business lunch locale.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 1025.95,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Pizza, Salad, Italian, Gourmet, Dessert, Trendy, Bruschetta, Hotel',
        annual_dol: 1070.43,
        description:
            'Pizza & traditional pastas plus brunch at an outpost of a local Italian chain in the Dream Hotel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 488.11,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Vegetarian, Wine, Gourmet, Dessert, Savory, Elegant, French, Premium, American, Sweet',
        annual_dol: 556.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7663,
        store_longitude: -73.9909,
        total_orders: 157.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Dessert, Delicious, Mediterranean, Modern, Lively, Perfect, Fresh, Greek',
        annual_dol: 276.26,
        description:
            'Lively spot for modern Mediterranean/Mid-Eastern eats plus fresh bread from the namesake domed oven.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7667,
        store_longitude: -73.9961,
        total_orders: 3860.37,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Spacious, Fun, Vibrant, Cozy, Italian, Premium, Great, Rooftop, Clean, Cool',
        annual_dol: 15779.91,
        description:
            '"MUSICA CLUB OPENS IN NEW YORK CITY After a successful debut in July 2020 in the Italian seaside resort town of Riccione, Musica Club is now coming to New York. Musica is born from a partnership between Giuseppe Cipriani and Italian nightlife entrepreneur Tito Pinton, owner of the historic Il Muretto club in Italy, with the idea of combining entertainment, musical performances and creativity with premier service and hospitality, and provide a great enjoyable lifestyle experience. It will be the largest venue of its kind in Manhattan, with two dance floors, two DJ booths, table service, multiple bars and a beautiful rooftop outdoor terrace. The goal is to revive the once vibrant New York club scene and become a joyous destination for an inc"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -73.9899,
        total_orders: 765.75,
        neighborhood: 'Lower East Side',
        keywords: 'Steak, Salad, Vegetarian, Wine, French, Tasty, Beer, Spanish, Cozy, Homemade',
        annual_dol: 782,
        description:
            'Specialty cocktails & European small plates offered in an old-school, Parisian-inspired bistro.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6732,
        store_longitude: -73.7238,
        total_orders: 8.19,
        neighborhood: '',
        keywords:
            'Bbq, Cozy, Fried Calamari, French, American, Belgian, Classy, Southern, Healthy, Casual',
        annual_dol: 33.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7758,
        store_longitude: -73.91,
        total_orders: 85.68,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'Brazilian, Beer, Colombian, Casual',
        annual_dol: 350.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.866,
        store_longitude: -73.9203,
        total_orders: 632.78,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Steak, Mexican, Beer, Italian, Dominican, Spanish, American, Latin, Filet Mignon, Casual',
        annual_dol: 646.2,
        description:
            'International dishes offered in a roomy, lively destination offering outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6362,
        store_longitude: -73.5823,
        total_orders: 590.6,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Beer, Wine, Ale, Caesar Salad, Hurricane, Premium, Clean',
        annual_dol: 819.31,
        description:
            '"Full service bar and grill with table/bar service and Union App ordering available."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8622,
        store_longitude: -73.9204,
        total_orders: 521.3,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Steak, Pizza, Cheesesteak, Kebab, Tequila, Italian, Mexican, Coffee, Philly Cheesesteak, Mediterranean',
        annual_dol: 2517.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6873,
        store_longitude: -73.9862,
        total_orders: 522.85,
        neighborhood: 'Boerum Hill',
        keywords:
            'Crab Cakes, Wine, Martini, Margarita, Moscow Mule, Intimate, Premium, Lit, Creative, Casual',
        annual_dol: 609.45,
        description:
            '"Deity is an all-inclusive, full-service Brooklyn Wedding and Event Space. Affordable packages offer use of our venue for ceremonies, receptions, cocktail parties, seated dinners, corporate meetings and dancing. A unique, non-traditional Wedding, Bar Mitzvah, Corporate Function and Event venue. Our stress-free, all in-house approach features catering with an executive chef, day-of coordination and event captain, full bar, curated wine list and specialty cocktails. With professional wait staff, DJ and optimized sound system, on-site furniture, linens as well as complimentary candle décor. A one-of-a-kind NYC experience conveniently located in walking distance to seven Brooklyn hotels, all major subway lines, Brooklyn Bridge Park and Barclays"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -74.0057,
        total_orders: 4847.96,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vodka, Gin, Salad, Cozy, Homemade, Classic, Cool, Casual, Great, Atmospheric',
        annual_dol: 5013.85,
        description:
            'Atmospheric tavern beneath Chelsea Market with creative cocktails, salvaged decor & DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6891,
        store_longitude: -73.9792,
        total_orders: 1863.5,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Delicious, Beer, Phenomenal, Fun, Sweet, Friendly, Amazing, Perfect, Classy',
        annual_dol: 1944.3,
        description:
            'Bar serving cocktails, local beers & elevated bar bites in an industrial-chic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6798,
        store_longitude: -73.974,
        total_orders: 429.85,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Iranian, Vodka, Delicious, Cozy, Hotel, Homemade, Authentic, Traditional, Mediterranean, Great',
        annual_dol: 619.65,
        description:
            'Modern, ingredient-driven Persian cooking in a whitewashed space with wood-beam ceilings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6747,
        store_longitude: -73.9633,
        total_orders: 284.58,
        neighborhood: 'Prospect Heights',
        keywords: 'Burger, Hamburger, Salad, Tasty, Applejack, Wine, Delicious, Trendy, Ale, Gin',
        annual_dol: 290.62,
        description:
            'Bright canteen offering creative American eats in a trendy but casual space (open late weekends).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7071,
        store_longitude: -74.0019,
        total_orders: 1513.12,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Burger, Mexican, Beer, Italian, Wine, Thai, French, Irish, Bellini, Greek',
        annual_dol: 1575.48,
        description:
            'Storied eatery & bar serving updated pub grub in an old-school setting with TVs & East River views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7101,
        store_longitude: -74.0073,
        total_orders: 901.6,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Vegetarian, Salad, Wine, Beer, Upscale, Warm, Comfortable, American, Sleek',
        annual_dol: 2010.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7156,
        store_longitude: -73.9495,
        total_orders: 18.38,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Salad, Dessert, Coffee, Martini, Wine, Bbq, Cozy, Modern, Negroni',
        annual_dol: 75.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7078,
        store_longitude: -73.9557,
        total_orders: 35.35,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Tasty, Burger, Beer, Wine, Warm, Sweet, Premium, Mediterranean, American, Perfect',
        annual_dol: 144.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7123,
        store_longitude: -73.9629,
        total_orders: 3181.94,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Wine, Coffee, Cajun, Beer, Dessert, Austrian, Cozy, Rustic, Italian, Warm',
        annual_dol: 3629.55,
        description:
            'Brunch is available every day at this casual American eatery with a rustic wood-&-brick look.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7794,
        store_longitude: -73.9504,
        total_orders: 31.29,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Whiskey Sour, Whiskey, Steak, Vodka, Beer, Chicken Pot Pie, Coffee, Ale, American, Moscow Mule',
        annual_dol: 127.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9758,
        total_orders: 1312.45,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Cajun, Beer, Gourmet, Ale, American, French, Irish, Contemporary, Seasonal',
        annual_dol: 1372.49,
        description:
            'Watering hole with a laid-back vibe offering gourmet burgers & bar fare, plus draft brews & TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.9843,
        total_orders: 859.16,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vodka, Russian, Beef Stroganoff, Shrimp Cocktail, Kebab, Salad, Tasty, Rum, Bloody Mary, Dessert',
        annual_dol: 1104.54,
        description:
            'An extensive selection of vodkas plus Russian fare & live piano music fuel this lively nightspot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.99,
        total_orders: 482.2,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Tacos, Vegetarian, Mexican, Beer, Wine, Margarita, Rustic, Delicious, Cozy',
        annual_dol: 492.43,
        description:
            'Cozy Mexican eatery with hip, Asian-accented decor serving rustic fare & a long list of tequilas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7764,
        store_longitude: -73.9789,
        total_orders: 32.69,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Kebab, Coffee, Turkish, Delicious, Magical, Cozy, Creamy, Homemade, Mediterranean, Exquisite',
        annual_dol: 69.68,
        description:
            'A traditional menu, including a popular fixed-price option, served in a richly hued dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8676,
        store_longitude: -73.93,
        total_orders: 388.15,
        neighborhood: 'Fort George/Inwood',
        keywords:
            'Burger, Lobster, Lobster Roll, Hotdog, Sushi, Vegetarian, Tasty, Bbq, American, Dope',
        annual_dol: 1117.98,
        description:
            'Casual American cafe with an Island vibe, outdoor seats & 75,000 sq. ft. of scenic waterfront.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7349,
        store_longitude: -73.9998,
        total_orders: 25076,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Salad, Beer, Martini, Wine, Daiquiri, Whimsical, Friendly, Casual, Aperol',
        annual_dol: 29615.93,
        description:
            'Cocktail lounge with a horseshoe bar, tiki-inspired dining area & jazzy, intimate downstairs space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7373,
        store_longitude: -73.9991,
        total_orders: 629.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Italian, Vegan, Gluten Free, Salad, Spicy, Avocado Toast, Trendy, Warm, Cozy',
        annual_dol: 631.33,
        description:
            'Cozy, romantic Italian eatery serving wood-fired pizza, pasta & family-style weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6862,
        store_longitude: -73.9855,
        total_orders: 1727.78,
        neighborhood: 'Boerum Hill',
        keywords: 'Coffee, Wine, Dessert, Japanese, Korean, Sweet, Chic, Natural, Intimate, Cool',
        annual_dol: 1771.35,
        description:
            '"The concept behind Café Kitsuné is to create and operate a space that marries the simplistic beauty of the small and intimate coffee bars with the style and cool of everything that Café Kitsuné undertakes. Specialty Coffee, Tea by Rishi, Matcha by Kettl, Natural Wine Bar, Spritz & The Bar at Cafe Kitsune features our extensive cocktail menu & Sake collection."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7388,
        store_longitude: -74,
        total_orders: 1174.86,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Cheesecake, Salad, Dessert, Wine, Beer, Magical, Irish, Cozy, Fantastic',
        annual_dol: 1250.28,
        description:
            'This neighborhood bar has dartboards, TVs broadcasting sports games & DJs on weekend evenings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7285,
        store_longitude: -73.7839,
        total_orders: 8.73,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords: 'Tasty, Fresh, Delicious, Chinese, Spicy, Ample, Perfect, Great, Sweet, Bbq',
        annual_dol: 35.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7299,
        store_longitude: -73.9809,
        total_orders: 751.01,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Ale, Karaoke, Fancy, Impressive, Great, Casual, Attentive, Fine',
        annual_dol: 841.66,
        description:
            'This former laundromat offers an extensive offering of draft beers in a casual, brick-lined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.9538,
        total_orders: 1121.24,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Vegetarian, Burger, Steak, Salad, Gluten Free, Tequila, Organic, Wine, Margarita, Delicious',
        annual_dol: 1712.28,
        description:
            'Out-of-the-way East Williamsburg corner restaurant offering New American fare & a raw bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6722,
        store_longitude: -73.9576,
        total_orders: 7122.18,
        neighborhood: 'Crown Heights',
        keywords:
            'Whiskey, Wine, Tasty, Karaoke, Italian, American, Premium, French, Relaxed, Perfect',
        annual_dol: 7279.39,
        description:
            'Relaxed cocktail bar with upholstered chairs, chandeliers, outdoor seating & small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6723,
        store_longitude: -73.9576,
        total_orders: 5197.24,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Salad, Vegan, Beer, Dutch, Dessert, Juicy, Casual, Classic, Local',
        annual_dol: 5438.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6813,
        store_longitude: -73.9572,
        total_orders: 2039.17,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Wine, Delicious, Margarita, Ale, Mexican, Fun, Cozy, Awesome, Friendly',
        annual_dol: 2082.44,
        description:
            'Hip, candlelit cocktail bar featuring live DJs, plus backyard seating with a ping-pong table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7253,
        store_longitude: -73.9831,
        total_orders: 4422.21,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Tasty, Spicy, French Toast, French, Thai, Martini, Perfect, Fried Rice, Latin',
        annual_dol: 6767.11,
        description:
            'International plates & tropical cocktails served in a vast locale with colorful, fashionable decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7064,
        store_longitude: -74.0026,
        total_orders: 54842.7,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Lobster, Hotel, Magical, Seasonal, Western, Creative, Casual, Robust, Great, Excellent',
        annual_dol: 69927.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.9561,
        total_orders: 112.15,
        neighborhood: 'Williamsburg',
        keywords:
            'Thai, Flavorful, Stylish, Spicy, Upscale, Japanese, Asian, Creamy, Authentic, Premium',
        annual_dol: 119.61,
        description:
            'Huge eatery with intricate woodwork & stylish bar serving upscale takes on Southeast Asian eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6726,
        store_longitude: -73.9576,
        total_orders: 10.82,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Vegan, Salad, Beer, Wine, Juicy, Cozy, American, Friendly, Wonderful',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7129,
        store_longitude: -73.9662,
        total_orders: 1425.27,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Zombie, Beer, Wine, Moscow Mule, Spacious, Classic, Great, Local, Cool, Mai Tai',
        annual_dol: 1546.54,
        description:
            'Spacious bar & music venue known for its inexpensive drinks & taco truck parked on the back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7229,
        store_longitude: -73.9559,
        total_orders: 2412.6,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, American, Pizza, Vintage, Casual, Fun, Cool, Chill',
        annual_dol: 2517.2,
        description:
            'Old-school, low-tech bowling alley with an attached barroom that hosts live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -73.9778,
        total_orders: 1501.38,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Beer, Wine, Delicious, Warm, Trendy, Coffee, Fun, Turkish, Friendly, Amazing',
        annual_dol: 1537.47,
        description:
            "Mid-Eastern eats & cocktails fuel the party while DJ's provide the soundtrack at this hookah bar.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7286,
        store_longitude: -73.6355,
        total_orders: 420.13,
        neighborhood: '',
        keywords: 'Sushi, Pizza, Salad, Wine, Italian, Japanese, Coffee, Sashimi, Zen, Spicy',
        annual_dol: 505.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7836,
        store_longitude: -73.9778,
        total_orders: 319.08,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Coffee, Beer, Burger, Wine, Trendy, Organic, Nutritious, Australian, Delicious, Healthy',
        annual_dol: 825.07,
        description:
            '"Bluestone Lane is an Australian-inspired coffee shop, cafés & lifestyle brand committed to providing a genuine daily escape for all our locals. We offer premium coffee and healthy and delicious eats through an always welcoming experience that embraces our ‘Aussie’ approach to life."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6193,
        store_longitude: -73.9237,
        total_orders: 91.3,
        neighborhood: 'Flatlands',
        keywords:
            'Dessert, Italian, Spanish, Thai, Wonderful, Caribbean, Intimate, Great, Authentic, Rare',
        annual_dol: 120.87,
        description:
            '"Servicing Brooklyn and the surrounding area since 1955. Glen Terrace offers four ballrooms ranging in capacity from as intimate as 50 guests to as grand as 300. Full service catering will allow you to customize every aspect of your special event, whether it be your Wedding or any other Social Function. Providing only the freshest and finest of quality, our culinary team offers authentic Caribbean, Italian, American, and Spanish cuisine. Glen Terrace understands that we have one chance to deliver your ultimate dream event, which is why we strive to make every event the best we’ve ever done!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8383,
        store_longitude: -73.9421,
        total_orders: 196.21,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Steak, Tacos, Shrimp Cocktail, Spanish, American, Dominican, Latin, Premium, Casual, Fine',
        annual_dol: 229.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.6794,
        total_orders: 77.32,
        neighborhood: '',
        keywords:
            'Cheesesteak, Pizza, Sushi, Beer, Spicy, Upscale, Swiss, French Onion Soup, Fun, Asian',
        annual_dol: 84.14,
        description:
            '"Repeal is Neighborhood Bar & Grill. We have an Extensive Beer Menu featuring many local Long Island Breweries, as well as Popular Craft Beers from around the Country. Our menu is Classic American bar food with a unique culinary flair. The goal of repeal is to provide our patrons and neighbors a Big city experience within walking distance of their front door."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7249,
        store_longitude: -73.6352,
        total_orders: 280.18,
        neighborhood: '',
        keywords:
            'Tacos, Steak, Mexican, Vegetarian, Vegan, Salad, Gourmet, Wine, Delicious, Healthy',
        annual_dol: 571.86,
        description:
            "\"Welcome to Guac Shop Mexican Grill! Your meal just got a whole lot more exciting. We opened up at the beginning of 2018, and our passion for what we do has pushed us to where we are today the ultimate craving satisfiers in town. Hankering for tacos? We've got 'em. Dying for a burrito? It's on the menu. Begging for a margarita? You know we've got you covered. Even those with restricted diets can find an incredible meal with us. From paleo to keto diets, macros to vegetarian, we promise to provi\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6441,
        store_longitude: -73.5398,
        total_orders: 649.46,
        neighborhood: '',
        keywords:
            'Wine, Dessert, Gluten Free, Italian, Relaxed, Polished, Friendly, Casual, Classic, Seasonal',
        annual_dol: 996.02,
        description:
            'A seafood-driven menu & cocktails in a relaxed seasonal venue with a patio overlooking the water.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9203,
        total_orders: 135.77,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Vietnamese, Trendy, Beer, Hotel, Organic, Elegant, Japanese, Classy, Asian',
        annual_dol: 144.7,
        description:
            'Pho & other familiar Vietnamese dishes, plus craft cocktails, served in a chill, urban setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6672,
        store_longitude: -73.9878,
        total_orders: 2364.17,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Whiskey, Savory, Irish, Friendly, Eclectic, Cool, Great, Local, Casual',
        annual_dol: 2414.33,
        description:
            'Park Slope local neighborhood bar with indie-rock jukebox, pinwheel machine, & patio when it’s warm.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.9145,
        total_orders: 251.99,
        neighborhood: 'Astoria',
        keywords: 'Wine, Beer, Margarita, Cozy, Exotic, Friendly, Perfect, Cool, Fusion, Classic',
        annual_dol: 1035.75,
        description:
            '"There’s virtually no cooler place than this to watch a sports game, have a drink at the bar, and indulge in some late-night food. The atmosphere is sexy and vibrant, not to mention steamy! If you have it in your budget, try the Mastermind-cocktail, which is well worth the price tag and wait. Or if you’re planning on staying up, get an "Up all night", which might just do good on its promise! This is the new hotspot for couples and singles alike."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9165,
        total_orders: 60.99,
        neighborhood: 'Astoria',
        keywords: 'Coffee, Wine, Donut, Cozy, Perfect, Casual, Retro, Signature, Great, Local',
        annual_dol: 186.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -73.9598,
        total_orders: 602.8,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Pizza, Tequila, Salad, Wine, Italian, Canadian, Spicy, Rum, American',
        annual_dol: 628.94,
        description:
            'Casual neighborhood spot with a wood-fired oven, featuring a seasonal brunch & dinner menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.9511,
        total_orders: 172.55,
        neighborhood: 'Greenpoint',
        keywords:
            'Delicious, Cajun, Fresh, Creole, British, Incredible, High-End, Signature, Casual, Mouth-Watering',
        annual_dol: 605.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7659,
        store_longitude: -73.9694,
        total_orders: 215.21,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotel, Luxurious, Elegant, Opulent, Delicious, Iconic, Martini, High-End, Amazing, Refined',
        annual_dol: 279.9,
        description:
            'The refined rooms, decorated in calming tones, have free Wi-Fi, and feature marble bathrooms, flat-screen TVs, and full kitchens or kitchenettes. The opulent suites have separate sitting areas, and some add wood-burning fireplaces, terraces and/or more bedrooms. Room service is available 24/7.Amenities include a guest-only fitness center, and an elegant restaurant that offers breakfast, brunch, afternoon tea and dinner, in addition to an extensive cocktail menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6774,
        store_longitude: -73.9985,
        total_orders: 326.09,
        neighborhood: 'Carroll Gardens',
        keywords: 'Wine, Pizza, Beer, Martini, Italian, Coffee, Ale, Cozy, Fun, Intimate',
        annual_dol: 443.91,
        description:
            'Neighborhood haunt for beer, wine & creative cocktails in an artsy, intimate space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.8761,
        total_orders: 260.29,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Salad, Steak, Shrimp Cocktail, Coffee, Mediterranean, Caesar Salad, American, Friendly, Casual, Local',
        annual_dol: 353.43,
        description:
            'Standby diner dishing up large portions of standard fare at any time of day or night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9535,
        total_orders: 167.31,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Steak, Argentinian, Cuban, Wine, Innovative, Outstanding, Authentic, Sweet, Great, Seasonal',
        annual_dol: 190.55,
        description:
            'Argentinian fare in an elevated dining room with an open kitchen & seasonal outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -73.9886,
        total_orders: 1429.83,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Whiskey, Vodka, Hotel, Premium, American, French, Traditional, Classic, Authentic, Great',
        annual_dol: 5844.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.9908,
        total_orders: 1209.25,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Tacos, Mexican, Tequila, Steak, Flavorful, Dessert, Enchiladas, Delicious, Warm, Authentic',
        annual_dol: 1234.91,
        description:
            'Elevated takes on traditional Mexican dishes & margaritas in a hip, bustling atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -73.9929,
        total_orders: 103.53,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Wine, Salad, Sushi, Italian, French, Spicy, Stylish, Romantic, Elegant, Japanese',
        annual_dol: 155.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5373,
        store_longitude: -74.1508,
        total_orders: 38650.31,
        neighborhood: 'Eltingville',
        keywords:
            'Shrimp Cocktail, Italian, Delicious, Homemade, Asian, Seasonal, Wonderful, Great, Casual, Local',
        annual_dol: 157989.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6385,
        store_longitude: -73.9683,
        total_orders: 11.17,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Pizza, Cheesesteak, Steak, Beer, Nachos, Spicy, Irish, Phenomenal, Classic',
        annual_dol: 62.4,
        description:
            'Easygoing watering hole with exposed-brick walls, classic pub grub & live music on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6971,
        store_longitude: -73.8371,
        total_orders: 33.55,
        neighborhood: 'Richmond Hill',
        keywords:
            'Lobster, Burger, Salad, Milkshake, Mexican, Flavorful, Dominican, Latin, American, Casual',
        annual_dol: 137.13,
        description:
            'Unfussy spot offering Dominican & American food from morning till night, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7996,
        store_longitude: -73.6506,
        total_orders: 1345.07,
        neighborhood: '',
        keywords: 'Burger, Steak, Lobster, Vegetarian, Tacos, Salad, Pizza, Wine, Beer, Organic',
        annual_dol: 1453.12,
        description:
            'Rustic bi-level New American cafe with outdoor deck overlooking a pond, plus weekend live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7129,
        store_longitude: -74.0094,
        total_orders: 3873.73,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Rich, Delicious, Hotel, Spanish, Premium, Greek, American',
        annual_dol: 4063.66,
        description:
            "Part of Wolfgang Puck's international steakhouse group, with a handsome dining room & lounge.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9638,
        total_orders: 2004.79,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Shrimp Cocktail, Tasty, Upscale, Irish, Legendary, French, Trendy, French Onion Soup, American',
        annual_dol: 2053.46,
        description:
            'Longtime Irish watering hole with old-school ambiance & a meat-centric menu. Proper attire a must.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7056,
        store_longitude: -73.8017,
        total_orders: 1283.16,
        neighborhood: 'Jamaica',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Bbq, Margarita, Juicy, French, Ale, Healthy',
        annual_dol: 1354.79,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9727,
        total_orders: 523.46,
        neighborhood: 'Lenox Hill',
        keywords: 'Velvety, Glamorous, Polish, Hotel, Cosmopolitan, Excellent, Fine',
        annual_dol: 557.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8733,
        store_longitude: -73.5982,
        total_orders: 105.36,
        neighborhood: '',
        keywords:
            'Tasty, Salad, Shrimp Cocktail, Delicious, Beer, Warm, Cozy, Italian, Friendly, Welcoming',
        annual_dol: 184.72,
        description:
            'Laid-back option set in a historic building with a warm wood decor & a menu of hearty American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6948,
        store_longitude: -73.9936,
        total_orders: 1565.38,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Salad, Hamburger, Pizza, Roast Turkey, Italian, Beer, Ale, Canadian, Savory, Organic',
        annual_dol: 1598.59,
        description:
            'Local watering hole with understated decor, sidewalk & back-patio seating & a modern pub-fare menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9863,
        total_orders: 668.57,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lasagna, Salad, Pizza, Italian, Meatball, Savory, Spicy, Delicious, Homemade, Irresistible',
        annual_dol: 682.76,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7392,
        store_longitude: -73.9856,
        total_orders: 4281.33,
        neighborhood: 'Midtown Manhattan/Gramercy Park/Flatiron District',
        keywords:
            'Steak, Wine, Beer, American, Prime Rib, Premium, Modern, Robust, Scallops, Natural',
        annual_dol: 6198.43,
        description:
            'Premium restaurant serving oysters, steak, seafood & desserts, plus wine & a robust cocktail list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.788,
        store_longitude: -73.7254,
        total_orders: 892.17,
        neighborhood: '',
        keywords:
            'Burger, Kebab, Steak, Coffee, Delicious, Mediterranean, African, Vibrant, Hotel, Healthy',
        annual_dol: 1116.9,
        description:
            'Adventurous kosher Persian dishes served in plentiful portions in a vibrant contemporary setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.785,
        store_longitude: -73.956,
        total_orders: 303.06,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Delicious, Italian, Warm, Elegant, Beer, Refined, Meatball, Lively, Wonderful, Fine',
        annual_dol: 519.76,
        description:
            'Refined eatery serving Italian favorites such as pasta & meat dishes, plus a brunch menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7837,
        store_longitude: -73.9506,
        total_orders: 925.7,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Burger, Steak, Hamburger, Pizza, Sushi, Beer, Ale, American, Irish, Homemade',
        annual_dol: 958.68,
        description:
            'Dark-wood-lined Irish pub with simple bar eats, draft beer and lots of TVs for sports fans.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7028,
        store_longitude: -73.9935,
        total_orders: 96.93,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Burger, Hamburger, Pizza, Beer, Roast Beef, Dessert, Wine, Bourbon, Organic, American',
        annual_dol: 176.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.9887,
        total_orders: 1129.5,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Cool, Recommended',
        annual_dol: 1203.73,
        description:
            'The Al Hirschfeld Theatre, originally the Martin Beck Theatre, is a Broadway theater at 302 West 45th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1924, it was designed by G. Albert Lansburgh in a Moorish and Byzantine style and was constructed for vaudevillian Martin Beck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8022,
        store_longitude: -73.9568,
        total_orders: 608.46,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Cheesecake, Lobster, Salad, Creamy, Rich, Cozy, New-York-Style, Bbq, Classy, Intimate',
        annual_dol: 621.37,
        description:
            'Bi-level Harlem lounge with a cozy vintage-speakeasy feel serving cocktails & small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7244,
        store_longitude: -73.9957,
        total_orders: 4634.95,
        neighborhood: 'Nolita',
        keywords:
            'Mexican, Burger, Sushi, Beer, Salad, Margarita, Gluten Free, Wine, Upscale, Spicy',
        annual_dol: 5278.89,
        description: 'Mexican bites with local ingredients, plus coffee, wines & mezcals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9924,
        total_orders: 275.27,
        neighborhood: 'NoHo',
        keywords:
            'Wine, Salad, Steak, Tasty, Coffee, Delicious, Warm, Cozy, Charming, Mediterranean',
        annual_dol: 328.83,
        description:
            'Revamped neighborhood hangout serving seafood & vegetable-focused plates in a bright, cozy space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.8909,
        total_orders: 48.21,
        neighborhood: 'Jackson Heights',
        keywords: 'Italian, Salad, Pizza, Wine, French, Classy, Latin, Greek, Brazilian, Friendly',
        annual_dol: 197.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.9899,
        total_orders: 7445.24,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Wine, Coffee, Italian, Avocado Toast, Hotel, Stylish, American, Romantic, Delicious, Contemporary',
        annual_dol: 7603.21,
        description:
            'Hotel lounge serving classic cocktails, wine and polished Italian fare in stylish digs with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6376,
        store_longitude: -74.1334,
        total_orders: 101.44,
        neighborhood: 'Port Richmond',
        keywords: 'Mexican, Salad, Coffee, Savory, Spicy, Omelette, Casual, Local, Great',
        annual_dol: 112.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7465,
        store_longitude: -73.953,
        total_orders: 3622.66,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Sushi, Salad, Wine, Beer, Japanese, Delicious, Sashimi, Ramen, Asian, Fusion',
        annual_dol: 3992.35,
        description:
            'Laid-back Japanese restaurant with exposed-brick walls offering sushi, ramen, noodles & sake.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7286,
        store_longitude: -73.9874,
        total_orders: 311.15,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Steak, Cornbread, Shrimp Cocktail, Salad, Bbq, Margarita, Martini, Caesar Salad, Zesty',
        annual_dol: 390.77,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7341,
        store_longitude: -73.8711,
        total_orders: 2753.45,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Cheesecake, Dessert, Wine, Mexican, Salad, Delicious, Beer, Chinese, Legendary, Polished',
        annual_dol: 3218.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -73.9909,
        total_orders: 1630.07,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Vegan, Pizza, Italian, Coffee, Salad, Upscale, Delicious, Ample, Vibrant, Yummy',
        annual_dol: 1901.54,
        description:
            'Upscale Italian kitchen & cocktail bar in a breezy Union Square setting with outdoor tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7266,
        store_longitude: -73.9915,
        total_orders: 2166,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Steak, Tequila, Beer, Wine, Coffee, American, Mimosa, Screwdriver, Japanese, Spacious',
        annual_dol: 2216.72,
        description:
            'Casual hang with a wide menu of American comfort food & happy-hour specials in a spacious setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7598,
        store_longitude: -73.8288,
        total_orders: 104.76,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Chinese, Spacious, Asian, Premium, Classy, Traditional, Authentic, Fresh, Pure, Fine',
        annual_dol: 138.68,
        description:
            'Spacious eatery in New World Mall offering Chinese dishes & dim sum in banquet hall-like environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9751,
        total_orders: 724.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Salad, Wine, Organic, American, Hotel, Brazilian, Spacious, Contemporary, Classic',
        annual_dol: 2959.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7536,
        store_longitude: -73.9737,
        total_orders: 146.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Butter Chicken, Chicken Tikka Masala, Dessert, Upscale, Trendy, Delicious, Polished, Traditional',
        annual_dol: 325.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8816,
        store_longitude: -73.8383,
        total_orders: 35.59,
        neighborhood: 'East Bronx/Edenwald',
        keywords:
            'Caribbean, Spicy, Jamaican, Fun, Innovative, Lively, Great, Authentic, Local, Signature',
        annual_dol: 145.5,
        description:
            '"Two spaces, One great vibe. Innovative Caribbean Restaurant, offering authentic Jamaican cuisine & fine dining options with an island flair. Exclusive signature cocktail menu featured. While the long standing Bar serves up the greatest tunes with in-house DJs, creating a lively atmosphere to enjoy drinks with a vibe. Klassique is a black-owned business. Happy Hour: Wed-Fri 4pm-8pm Seafood Thursday: Features exclusive seafood menu"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9643,
        total_orders: 1495.57,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Salad, Wine, Italian, Chinese, Upscale, Elegant, Impeccable, Trendy, Classy',
        annual_dol: 1502.22,
        description:
            'Elegant Chinese restaurant, around since 1979 and still considered to be a scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9728,
        total_orders: 1334.15,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Luxurious, Cosmopolitan, Upscale, Hotel, Warm, Polish, Impeccable, Outstanding, Premium, Global',
        annual_dol: 1385.44,
        description:
            '"CORE: is a space in time. A curated environment innovating connections and nurturing encounters and discoveries. An international community of minds, mavericks, and leaders. An epicenter of freedom and ideas. Beauty, mindfulness, ease, sparks of intelligence, pleasure. CORE: is for members only. Outstanding women and men in the most welcoming of places, surrounded by remarkable art and architecture, exceptional food, warm and impeccable service, original cultural programs and avant-garde body-care. A compellingly diverse community to enjoy life, broaden horizons, share knowledge, appreciate the present. Build the future. CORE: is about exchange. CORE: is an attitude. CORE: is a collective performance."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9717,
        total_orders: 274.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Vegan, Salad, Chicken Tikka Masala, Chinese, Gluten Free, American, Hotel, Tandoori Chicken, Indian',
        annual_dol: 1121.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7572,
        store_longitude: -73.9686,
        total_orders: 128.95,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Wine, Nepalese, Beer, Indian, Tandoori Chicken, Casual, Traditional, Biryani',
        annual_dol: 527.11,
        description:
            'Cozy, down-to-earth restaurant plating traditional Indian specialties & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -74.0095,
        total_orders: 539.68,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Vegan, Kebab, Butter Chicken, Chicken Tikka Masala, Wine, Gluten Free, Beer, Spicy, Indian',
        annual_dol: 608.74,
        description:
            'Contemporary Indian find for spicy vegetarian dishes, tandoori meats & fixed-price lunch specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5672,
        store_longitude: -73.8946,
        total_orders: 1453.08,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords:
            'Burger, Shrimp Cocktail, Salad, Vodka, Wine, Gin, French, American, Thai, Caesar Salad',
        annual_dol: 1524.11,
        description:
            'Established waterfront chophouse serving seafood & classic fare in an elegant space with NYC views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6642,
        store_longitude: -73.6981,
        total_orders: 36.06,
        neighborhood: '',
        keywords:
            'Salad, Chicken Parmigiana, Pizza, Shrimp Cocktail, Mexican, Meatball, Italian, Fried Calamari, Wine, Latin',
        annual_dol: 147.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6741,
        store_longitude: -73.9536,
        total_orders: 1166.43,
        neighborhood: 'Crown Heights',
        keywords: 'Cajun, Cornbread, Jambalaya, Pizza, Beer, Gumbo, Creole, Sweet, Southern, Local',
        annual_dol: 1299.71,
        description:
            'Country-chic Cajun kitchen serving up traditional bayou bites, craft beer & spirits.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.9862,
        total_orders: 6217.15,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Luxurious, Martini, Legendary, High-End, Coffee, Vibrant, Bright, Vodka, Rooftop',
        annual_dol: 6353.18,
        description:
            "walk from the Empire State Building.The refined rooms come with high ceilings, designer toiletries and high-thread-count sheets, as well as minibars, free Wi-Fi and flat-screen TVs. Suites add living rooms with pull-out sofas, and city views. In-room beauty treatments and 24-hour room service are available.Amenities include a chic restaurant, a posh cafe and a plush rooftop bar, as well as a cigar lounge. There's also a gym. Parking is offered for a fee.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6904,
        store_longitude: -73.9955,
        total_orders: 4503.02,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Painkiller, Elegant, Delicious, Cozy, Spacious, Relaxed, Classy, Vintage, Awesome, Classic',
        annual_dol: 4598.57,
        description:
            'Banquette-lined cocktail bar with art deco accents & a vintage sewing machine equipping the bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.9885,
        total_orders: 370.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Magical, Salad, Delicious, Homey, Amazing, Excellent, Fine, Casual',
        annual_dol: 1396.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9799,
        total_orders: 1521.55,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Steak, Wine, Beer, Salad, Italian, Upscale, Gin, Irish, French, American',
        annual_dol: 1759.23,
        description:
            'Family-run Irish-style pub offering pizza, comfort food & beer in a contemporary atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8629,
        store_longitude: -73.8341,
        total_orders: 246.05,
        neighborhood: 'Pelham Gardens/East Bronx',
        keywords:
            'Burger, Whiskey, Donut, Chinese, American, Delicious, Legendary, Authentic, Fresh, Casual',
        annual_dol: 1005.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9852,
        total_orders: 888.51,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Burger, Hamburger, Lobster Bisque, Japanese, Beer, Delicious, Casual, Local',
        annual_dol: 988.56,
        description:
            'British-born restaurant for lobster rolls, lobsters & burgers in a big space with a happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.738,
        store_longitude: -73.9836,
        total_orders: 332.3,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'German, Shrimp Cocktail, Salad, Italian, Coffee, Beer, French Onion Soup, French, Wine, Latin',
        annual_dol: 401.53,
        description:
            'Snug German restaurant & bar with an old-world vibe, known for its elaborate holiday decorations.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7489,
        store_longitude: -73.9924,
        total_orders: 431.95,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Burger, Hamburger, Vegetarian, Salad, Cajun, Cajun-Style, Milkshake, Tasty, Vietnamese, American',
        annual_dol: 473.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7266,
        store_longitude: -73.9925,
        total_orders: 4506.36,
        neighborhood: 'NoHo',
        keywords:
            'Burger, Steak, Salad, Wine, Japanese, Spicy, Delicious, Premium, Quaint, American',
        annual_dol: 4703.09,
        description:
            'Exclusive (referral-only) East Village Japanese eatery hidden behind a butcher shop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.772,
        store_longitude: -73.9909,
        total_orders: 818.85,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Wine, Beer, Gourmet, Hotel, Stylish, Italian, Upscale, Seasonal, Premium, Warm',
        annual_dol: 1914.92,
        description:
            '"Morton Williams\' debut bar featuring seasonal wines/beers on tap and mouthwatering eats."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6874,
        store_longitude: -74.0014,
        total_orders: 682.37,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Wine, Coffee, Italian, Comfortable, Warm, Romantic, Charming, Spacious, Friendly, Southern',
        annual_dol: 795.38,
        description:
            'Place for Italian food with a touch of the American South, plus bocce ball on the patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6755,
        store_longitude: -74.0175,
        total_orders: 2441.58,
        neighborhood: 'Red Hook',
        keywords: 'Modern, Perfect, Impeccable, Great, Unique',
        annual_dol: 2547.43,
        description:
            '"The Liberty Warehouse is one of the best wedding venues in New York. Book your private event or corporate party today."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6478,
        store_longitude: -73.7883,
        total_orders: 1242.76,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Steak, Hamburger, Pizza, Salad, Donut, Beer, Coffee, Wine, Dessert',
        annual_dol: 4694.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9783,
        total_orders: 12815.26,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Burger, Cheesesteak, Cheesecake, Wine, Beer, Stylish, American, Trendy, Creme Brulee',
        annual_dol: 13125.91,
        description:
            'Sophisticated chophouse chain serving steak, seafood, sandwiches & cocktails in a trendy space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8479,
        store_longitude: -73.9377,
        total_orders: 5.06,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Sushi, Salad, Pizza, Dessert, Italian, Spicy, Chinese, Sashimi, Asian, Ramen',
        annual_dol: 79.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8311,
        store_longitude: -73.9278,
        total_orders: 149.36,
        neighborhood: 'Highbridge/West Bronx',
        keywords: 'Elegant',
        annual_dol: 671.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.9925,
        total_orders: 1137.76,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Salad, Beer, Belgian, Spanish, Caesar Salad, Filet Mignon, Eclectic, Traditional, Classic',
        annual_dol: 1161.9,
        description:
            'Buzzy, narrow Belgian pub serving various moules frites & other classic eats, plus native beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.9941,
        total_orders: 2596.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Burger, Margarita, Vodka, Wine, Italian, Cointreau, Homemade, Casual, Authentic',
        annual_dol: 3003.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6901,
        store_longitude: -73.9935,
        total_orders: 444.13,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Wine, Beer, Trendy, French Toast, Lebanese, Modernist, French, Delicious, American, Mediterranean',
        annual_dol: 469.98,
        description:
            'American & Middle Eastern cooking styles meld at this modern spot with old- and new-world wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9917,
        total_orders: 1493.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Salad, French Toast, Roast Beef, Italian, French, French Onion Soup, Organic, Cozy, American',
        annual_dol: 1563.03,
        description:
            'Comfort food with breakfast, Greek & Italian options in a warm, traditional diner that has a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6984,
        store_longitude: -73.9934,
        total_orders: 66.12,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Coffee, Wine, French Toast, Salad, Italian, French, Avocado Toast, Breakfast Burrito, Homey, Casual',
        annual_dol: 190.44,
        description:
            'Hip, homey coffee shop serving light fare & pastries along with local wines & beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9879,
        total_orders: 122.34,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Beer, Whiskey, Irish, Korean, Hotel, American, Sleek, Casual, Intimate',
        annual_dol: 500.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7734,
        store_longitude: -73.9065,
        total_orders: 2032.64,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Burger, Irish, Friendly, Outstanding, American, Fresh, Great, Excellent, Casual, Local',
        annual_dol: 2109.56,
        description:
            '"Bar and Restaurant serving craft beer, cocktails and offering a new food menu including brunch on the weekends."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -74.0023,
        total_orders: 761.48,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Dessert, Tasty, Upscale, French, Gluten Free, Elegant, Cozy, Vintage, Hotel, Seasonal',
        annual_dol: 794.5,
        description:
            'Upscale bakery with Parisian origins, specializing in French macarons in a number of flavors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -74.0005,
        total_orders: 71.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Bbq, Spicy, Nachos, American, Egyptian, Relaxed, Fresh, Great, Casual',
        annual_dol: 293.86,
        description:
            'Raucous hookah lounge with a simple setup offering drinks & a simple menu of bar food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9882,
        total_orders: 8996.66,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegan, Vegetarian, Sushi, Salad, Thai, Crisp, Delicious, Friendly, Casual, Innovative',
        annual_dol: 22004.95,
        description:
            '"Experience our plant-based Asian-inspired menu at PLANTA Queen, New York. Located in the heart of NoMad, PLANTA Queen opened to create delicious sushi, noodles, dumplings, cocktails and more vegan Asian-inspired food that promotes environmental sustainability. Under executive chef David Lee, PLANTA Queen aims to fill a void in the market of upscale, full-service, plant-based dining options. Our vision is to celebrate innovative cuisine in a beautiful environment through the power of plants. Come join us for date night or for a gathering with friends or family and enjoy some of the best food, music, and vibes in NoMad."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.9884,
        total_orders: 1765.82,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Burger, Beer, Flavorful, Wine, Rooftop, Glamorous, Spacious, Bright, Fun, Mediterranean',
        annual_dol: 7218.1,
        description:
            'Fashionable lounge with a glass roof offering brunch, snacks & light meals, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -74.0051,
        total_orders: 3922.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Whiskey, Vodka, Wine, Bourbon, Martini, Hamburger, Trendy, Rum, Rich, Delicious',
        annual_dol: 4135.36,
        description:
            'Bar from Danny Meyer offering Southern-inspired cocktails & small bites in a handsome space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7502,
        store_longitude: -73.9905,
        total_orders: 2429.14,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Tasty, Wine, Beer, Nachos, Delicious, Juicy, Ale',
        annual_dol: 8537.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9775,
        total_orders: 184.21,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegan, Tacos, Vegetarian, Mexican, Salad, Margarita, Gluten Free, Tasty, Nachos, Dessert',
        annual_dol: 311.61,
        description:
            '"Taco Dumbo 31 West 52nd Street is a market fresh taqueria and margarita bar wrapped in surf & sun vibes."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.9762,
        total_orders: 3088.71,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Vegetarian, Salad, Premium, Iconic, Fine',
        annual_dol: 6943.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9872,
        total_orders: 1275.59,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Steak, Dessert, Gourmet, Wine, Upscale, Mediterranean, Brazilian, Authentic, Traditional',
        annual_dol: 1320.73,
        description:
            'A parade of servers toting skewered meats and a large salad bar star at this set-price rodizio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9877,
        total_orders: 99.01,
        neighborhood: 'Lower East Side',
        keywords: 'Burger, Steak, Salad, Beer, Gourmet, Milkshake, Tasty, Upscale, Fun, Fresh',
        annual_dol: 404.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7209,
        store_longitude: -73.9872,
        total_orders: 89.94,
        neighborhood: 'Lower East Side',
        keywords:
            'Tacos, Mexican, Steak, Tequila, Nachos, Margarita, Tex-Mex, Wine, Dominican, American',
        annual_dol: 280.88,
        description:
            'Cheerful corner eatery serving a wide array of Mexican & Dominican dishes, plus fruit shakes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9666,
        total_orders: 42.72,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Vegetarian, Shrimp Cocktail, Japanese, Gourmet, Wine, Upscale, Dessert, Australian, Authentic',
        annual_dol: 174.64,
        description:
            'Upscale restaurant with authentic Kobe, Wagyu & prime beef, seafood, cocktails & a long wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7037,
        store_longitude: -73.7988,
        total_orders: 39.98,
        neighborhood: 'Jamaica',
        keywords: 'Mexican, Tacos, Burritos, Salad, Organic, Local, Casual, Warm',
        annual_dol: 224.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9871,
        total_orders: 3372.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Fun, Great, Excellent, Classic, Casual',
        annual_dol: 3930.69,
        description:
            'Buzzing hot spot catering to gay men with drag shows & live entertainment, plus classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7034,
        store_longitude: -73.8146,
        total_orders: 31.47,
        neighborhood: 'Jamaica',
        keywords: 'Hotel',
        annual_dol: 128.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8073,
        store_longitude: -73.9448,
        total_orders: 155.55,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Mexican, Tacos, Warm, Healthy, Clean, Casual, Local, Fresh, Great, Signature',
        annual_dol: 697.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6875,
        store_longitude: -73.8503,
        total_orders: 641.23,
        neighborhood: 'Ozone Park',
        keywords:
            'Cajun, Salad, Juicy, Friendly, Authentic, Natural, Latin, Fresh, American, Creole',
        annual_dol: 1467.29,
        description: '"Cajun seafood & Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5451,
        store_longitude: -74.1654,
        total_orders: 453.26,
        neighborhood: 'Eltingville/Arden Heights',
        keywords:
            'Burger, Salad, Coffee, Wine, Irish, American, Seasonal, Traditional, Fresh, Classic',
        annual_dol: 495.87,
        description:
            'Upbeat tavern offering Irish & American comfort fare & spirits, plus live music & outside seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.687,
        store_longitude: -73.975,
        total_orders: 333.77,
        neighborhood: 'Fort Greene',
        keywords:
            'Beer, Relaxed, Delicious, Friendly, Hip, Casual, Eclectic, American, Local, Great',
        annual_dol: 1364.34,
        description:
            'Neighborhood watering hole offering upmarket pub eats alongside DJ tunes & relaxed ambiance.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.792,
        store_longitude: -73.8096,
        total_orders: 83.33,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Italian, Turkish, Healthy, Greek, Friendly, Natural, Tandoori Chicken, Casual, Great, Indian',
        annual_dol: 340.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.9779,
        total_orders: 14179.57,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Sushi, Beer, Wine, Friendly, Fun, Japanese, Fusion, Lively, Casual, Awesome',
        annual_dol: 14248.25,
        description: '"Studio 151/ Ichi Go Ichi"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7072,
        store_longitude: -74.0025,
        total_orders: 1014.38,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Beer, Tasty, Wine, Ale, Fresh, Comfortable, Cozy, Fabulous, Italian, Fun',
        annual_dol: 1035.9,
        description:
            'Neighborhood watering hole with craft ales, sandwiches & snacks, plus daily happy hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6768,
        store_longitude: -73.9637,
        total_orders: 209.26,
        neighborhood: 'Prospect Heights/Crown Heights',
        keywords:
            'Tacos, Mexican, Tequila, Margarita, French Toast, Coffee, French, Enchiladas, American, Trendy',
        annual_dol: 513.52,
        description:
            'Bronx-born spot for both homey & inventive Mexican dishes along with sophisticated drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6373,
        store_longitude: -74.0756,
        total_orders: 341.69,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords:
            'Pizza, Italian, Wine, Stylish, Upscale, Beer, Delicious, Classy, Romantic, Modern',
        annual_dol: 631.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5862,
        store_longitude: -73.6941,
        total_orders: 10.25,
        neighborhood: '',
        keywords:
            'Tasty, Wine, Delicious, Salad, Margarita, Beer, Hamburger, Spacious, Fun, Vibrant',
        annual_dol: 41.89,
        description:
            'American fare & cocktails served in a vibrant multi-room setting with a patio & dance floor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7632,
        store_longitude: -73.9284,
        total_orders: 212.04,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Whiskey, Cozy, Elegant, Romantic, Beer, South American, Intimate, Latin, Casual',
        annual_dol: 864.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6573,
        store_longitude: -73.6467,
        total_orders: 8203.43,
        neighborhood: '',
        keywords:
            'Lobster, Sushi, Japanese, Wine, Delicious, Trendy, Asian, Fusion, Innovative, Teriyaki',
        annual_dol: 9497.8,
        description:
            'Trendy pick doling out innovative sushi rolls plus hibachi & teriyaki in buzzy, illuminated digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6579,
        store_longitude: -73.648,
        total_orders: 286.88,
        neighborhood: '',
        keywords:
            'Beer, Delicious, Phenomenal, Fun, Irish, Great, Exceptional, Excellent, Casual, Chill',
        annual_dol: 322.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7468,
        store_longitude: -73.6198,
        total_orders: 53.83,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Salad, Cajun, Cajun-Style, Italian, Juicy, Delicious, Creole, Friendly',
        annual_dol: 220.02,
        description: '"Delicious seafood at market price!!! Grand opening 7/30/22"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.9869,
        total_orders: 3033.66,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Tasty, Spicy, Delicious, Coffee, Italian, Mediterranean, Ramen, Hotel, Clean, American',
        annual_dol: 3235.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7139,
        store_longitude: -73.559,
        total_orders: 2370.15,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Chicken Parmigiana, Shrimp Cocktail, Salad, Lobster Bisque, Champagne, Wine, American, Elegant',
        annual_dol: 6128.71,
        description:
            'Eisenhower Park, formerly known as Salisbury Park, is a public park in East Meadow, New York bordered by Hempstead Turnpike on the south and Old Country Road on the north.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6145,
        store_longitude: -73.6445,
        total_orders: 1788.49,
        neighborhood: '',
        keywords:
            'Steak, Burger, Hamburger, Delicious, Juicy, Phenomenal, American, Fresh, Casual, Local',
        annual_dol: 1922.24,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6402,
        store_longitude: -73.6997,
        total_orders: 633.25,
        neighborhood: '',
        keywords: 'Sushi, Salad, Spicy, Wine, Delicious, Japanese, Elegant, Asian, Sashimi, Thai',
        annual_dol: 665.45,
        description:
            'Sleek eatery with a sushi bar preparing creative Asian-fusion dishes in a convivial atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7201,
        store_longitude: -73.9963,
        total_orders: 3383.73,
        neighborhood: 'Little Italy',
        keywords:
            'Vegan, Salad, Gluten Free, Wine, Organic, Mediterranean, Friendly, Contemporary, Shawarma, Israeli',
        annual_dol: 3564.68,
        description:
            'Playful Mediterranean fare with Israeli influences & health-conscious ethos in a plant-filled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -73.9306,
        total_orders: 7482.79,
        neighborhood: 'East Williamsburg',
        keywords:
            'Burger, Beer, Dessert, Funky, Puerto Rican, Delicious, Latin, American, Friendly, Casual',
        annual_dol: 7645.54,
        description:
            'Puerto Rican comfort foods & cocktails are offered in a funky setting with industrial elements.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -73.9903,
        total_orders: 1558.85,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Upscale, Legendary, American, Classic, Modern, Traditional',
        annual_dol: 1749.78,
        description:
            'Upscale offshoot of the storied steakhouse serving an updated menu in a chic, modern setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -74.0041,
        total_orders: 611.94,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Tequila, Mexican, Margarita, Rich, Fajitas, Rustic, Fun, Eclectic, Friendly, Creme Brulee',
        annual_dol: 1316.37,
        description:
            'An imaginative drink list pairs with modern Mexican small plates in a rustic-chic space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7158,
        store_longitude: -73.9966,
        total_orders: 412.44,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Sushi, Burger, Wine, Beer, Coffee, Karaoke, Spicy, Trendy, Japanese, Funky',
        annual_dol: 961.5,
        description: 'Minimalist design Japanese restaurant, sushi bar & sake lounge in Chinatown.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.705,
        store_longitude: -73.923,
        total_orders: 1659.73,
        neighborhood: 'Bushwick',
        keywords: 'Tasty, Beer, Karaoke, Rich, Friendly, Cool, Amazing, Great, Casual, Local',
        annual_dol: 1694.94,
        description:
            'Artsy hangout for cocktails & pub grub, plus live music, film screenings & fundraising events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5816,
        store_longitude: -73.838,
        total_orders: 67.01,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, French Toast, Beer, French, American, Perfect, Phenomenal, Casual',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6588,
        store_longitude: -73.6712,
        total_orders: 256.9,
        neighborhood: '',
        keywords: '',
        annual_dol: 272.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7404,
        store_longitude: -74.0054,
        total_orders: 4320.34,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Burger, Champagne, Delicious, Wine, Spicy, Beer, Creamy, Cozy, Fun, Crisp',
        annual_dol: 11128.92,
        description:
            'Foosball, board games & pool meet cocktails & beer at this low-frills basement lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6194,
        store_longitude: -73.9181,
        total_orders: 24.77,
        neighborhood: 'Flatlands',
        keywords:
            'Sushi, Salad, Japanese, Chinese, Delicious, Bbq, Asian, Fried Rice, Friendly, Fusion',
        annual_dol: 101.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6535,
        store_longitude: -73.5573,
        total_orders: 1747.16,
        neighborhood: '',
        keywords: 'Sushi, Burger, Salad, Pizza, Japanese, Spicy, Creamy, Chinese, Sashimi, Thai',
        annual_dol: 1900.06,
        description:
            'Sleek eatery with a sushi bar preparing creative Asian-fusion dishes in a convivial atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7793,
        store_longitude: -73.9739,
        total_orders: 1008.61,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Beer, Italian, Elegant, Meatball, Mediterranean, Friendly, Vibrant, Handcrafted, Traditional',
        annual_dol: 1077.89,
        description:
            "Venetian-inspired appetizers & handcrafted pastas in the New-York Historical Society's airy cafe.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6424,
        store_longitude: -74.0165,
        total_orders: 38.33,
        neighborhood: 'Sunset Park',
        keywords:
            'Irish, Vintage, African, American, Amazing, Great, Excellent, Friendly, Local, Fine',
        annual_dol: 125.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7082,
        store_longitude: -74.0108,
        total_orders: 4027.07,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Sushi, Gluten Free, Wine, Upscale, Delicious, American, Refined, Classic',
        annual_dol: 4394.8,
        description:
            'Outpost of the upscale steakhouse chain offers classic American fare & a clubby, refined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6677,
        store_longitude: -73.7946,
        total_orders: 361.51,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'Coffee, Hotel, Caribbean, American, Great',
        annual_dol: 1536.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6701,
        store_longitude: -73.9787,
        total_orders: 163.43,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Beer, Coffee, Ale, American, Refined, Prosecco, Sweet, Great, Rooftop',
        annual_dol: 333.56,
        description:
            'Refined bar/lounge serving high-end cocktails, beer & wine, plus light bites, in tasteful environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7031,
        store_longitude: -73.9334,
        total_orders: 460.08,
        neighborhood: 'East Williamsburg',
        keywords:
            'Salad, Burger, Mexican, Italian, Organic, Caesar Salad, French, Funky, Delicious, Cozy',
        annual_dol: 471.33,
        description:
            'Hearty New American dishes & some Mexican & Italian eats in a cozy spot with local art on the walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6946,
        store_longitude: -73.9107,
        total_orders: 605.5,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Tasty, Fun, Hip, Classic, Great, Daiquiri, Casual',
        annual_dol: 622.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7073,
        store_longitude: -73.9419,
        total_orders: 136.04,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Burger, Hamburger, Vegan, Meatloaf, Salad, Tomato Soup, Delicious, French, American, Grilled Cheese Sandwich',
        annual_dol: 317.64,
        description:
            'Cozy, exposed-brick spot serving comfort food featuring local ingredients, with vegan options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5896,
        store_longitude: -74.1015,
        total_orders: 2275.61,
        neighborhood: 'Todt Hill',
        keywords:
            'Sushi, Salad, Steak, Dessert, Japanese, Sashimi, Homemade, Casual, Friendly, Teriyaki',
        annual_dol: 2367.32,
        description:
            '"We are proud to be a locally owned, family friendly restaurant. Inspired by generations of family recipes, Sakana Sushi & Hibachi Steakhouse has a vast menu of culinary delights, utilizing the freshest ingredients to bring homemade dishes straight to your table. Our mission is to provide Staten Island with the best quality ingredients, and the love for Japanese food. We invite you to join us and enjoy the food that excites us and keeps us passionate. Find us when looking for hibachi restaurant in Staten Island, Sushi Restaurant in Staten Island, NY and Japanese Restaurant."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7859,
        store_longitude: -73.6222,
        total_orders: 102.3,
        neighborhood: '',
        keywords:
            'Flavorful, Pizza, Salad, Spicy, Shrimp Cocktail, Gluten Free, Chicken Tikka Masala, Delicious, Beer, Modern',
        annual_dol: 385.42,
        description: '"NOW OPEN"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7158,
        store_longitude: -74.0086,
        total_orders: 2177,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Whiskey, Beer, Salad, Hotel, Comfortable, Classy, Classic, Cool, Fantastic, Hot Toddy',
        annual_dol: 7813.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.723,
        store_longitude: -74.003,
        total_orders: 129.79,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Flavorful, Wine, Beer, Coffee, Tasty, Dessert, American, Classic, Cool, Great',
        annual_dol: 491.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7757,
        store_longitude: -73.9536,
        total_orders: 1022.75,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegan, Lobster, Steak, Vegetarian, Salad, Chocolate Mousse, Wine, Lobster Bisque, Coffee, Italian',
        annual_dol: 2445.39,
        description:
            'Cozy, elegant bistro specializing in Mediterranean-inspired seafood, steak & vegetarian bowls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7214,
        store_longitude: -73.8452,
        total_orders: 424.86,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Salad, Vodka, Beer, Spicy, Delicious, Bbq, French, Asian, Greek',
        annual_dol: 462.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.8391,
        total_orders: 33.42,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Tasty, Wine, Cuban, Delicious, Organic, Latin, Colombian, Perfect, American, Fresh',
        annual_dol: 109.1,
        description:
            'Casual eatery & bar serving dishes from various Latin cuisines alongside beer, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8083,
        store_longitude: -73.9304,
        total_orders: 172.6,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Burger, Steak, Salad, Pizza, Meatball, Nachos, Beer, Delicious, Upscale, Cuban',
        annual_dol: 310.12,
        description:
            'Upscale pub grub, steaks & local brews in a laid-back barroom space, plus a bustling weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.721,
        store_longitude: -74.0044,
        total_orders: 1458.82,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Hotel, Wine, Coffee, Upscale, Whiskey, Trendy, American, Modern, Chic, Rooftop',
        annual_dol: 1618.29,
        description:
            'miles from the 9/11 Memorial Museum.Sophisticated rooms with custom-designed beds offer flat-screen TVs, Wi-Fi (fee) and coffeemakers. Club rooms have skyline views, free Wi-Fi, and access to a 21st-floor lounge and rooftop terrace offering complimentary breakfast and evening appetizers. Suites add separate living rooms.Amenities include a lobby bar/grill, a lounge and a coffee shop. There are 24/7 fitness and business centers, along with 2 meeting rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -73.9975,
        total_orders: 84.86,
        neighborhood: 'Little Italy',
        keywords:
            'Lobster, Salad, Pizza, Chicken Parmigiana, Shrimp Cocktail, Italian, Gluten Free, Wine, Beer, Delicious',
        annual_dol: 103.95,
        description:
            'Cozy red-sauce Italian spot in Little Italy does all the classics in rustic, wood-beamed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7209,
        store_longitude: -74.0048,
        total_orders: 606.73,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Cheesecake, Delicious, Italian, Elegant, Warm, Cozy, Perfect, Japanese, Authentic, Modern',
        annual_dol: 633.03,
        description:
            'Easygoing, bi-level restaurant with outdoor seating serving modern Tuscan dishes & Italian wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7942,
        store_longitude: -73.94,
        total_orders: 187.83,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Burger, Lobster, Lobster Roll, Pizza, Beer, Coffee, Spanish, American, Wholesome, Local',
        annual_dol: 279.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6993,
        store_longitude: -73.912,
        total_orders: 223.86,
        neighborhood: 'Bushwick',
        keywords:
            'Lobster Roll, Lobster, Sushi, Salad, Japanese, Organic, Asian, Ramen, Fusion, Healthy',
        annual_dol: 730.86,
        description: '"sushi, Asian Fusion and Spanish."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -73.5648,
        total_orders: 58.2,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Gluten Free, Japanese, Beer, Savory, Chinese, Sashimi, Asian, Fusion',
        annual_dol: 237.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 4827.88,
        neighborhood: '',
        keywords:
            'Steak, Burger, Salad, Wine, Italian, Beer, Upscale, American, Phenomenal, Ribeye Steak',
        annual_dol: 4936.1,
        description:
            'Outpost of the upscale steakhouse chain offering classic American fare & a clubby, refined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.6348,
        total_orders: 111.86,
        neighborhood: '',
        keywords:
            'Salad, Burger, Wine, Dessert, Italian, French Onion Soup, French, Delicious, Caesar Salad, American',
        annual_dol: 176.99,
        description:
            'Neighborhood bistro with brick walls, booths, sidewalk seating & New American standards.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8522,
        store_longitude: -73.8291,
        total_orders: 5239.91,
        neighborhood: 'Pelham Bay',
        keywords: 'Beer, Margarita, Rum, Premium, Mouth-Watering, Rooftop, Cool',
        annual_dol: 8541.11,
        description:
            'Brick walls, neon lighting & TV sports are some highlights of this hookah lounge & cocktail bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.642,
        store_longitude: -73.6052,
        total_orders: 204.18,
        neighborhood: '',
        keywords: 'Irish, American, Local, Classic, Casual',
        annual_dol: 208.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6368,
        store_longitude: -73.6002,
        total_orders: 436.3,
        neighborhood: '',
        keywords:
            'Burger, Steak, Garlic Bread, Shrimp Cocktail, Beer, Wine, Italian, Bourbon, Bbq, Ale',
        annual_dol: 455.22,
        description:
            '"Sonny\'s Canal House provides dine in, delivery, and catering services to the Baldwin, NY area."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7861,
        store_longitude: -73.9726,
        total_orders: 636.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Salad, Ramen, American, Casual, Omelette, Southern, Local, Fresh, Traditional',
        annual_dol: 678.38,
        description:
            'Locally sourced ingredients feature in traditional American comfort foods & house-baked treats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7846,
        store_longitude: -73.9771,
        total_orders: 2356.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Salad, Tacos, Pizza, Delicious, Juicy, Beer, Irish, Ale, Bruschetta',
        annual_dol: 2406.34,
        description:
            'Low-key eatery & bar with TVs, 2 floors & a modern American & Irish menu plus a few outdoor seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7842,
        store_longitude: -73.9736,
        total_orders: 263.86,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Chinese, Stylish, Delicious, Sweet, Friendly, Asian, Authentic, Traditional, Perfect',
        annual_dol: 434.89,
        description:
            '"Tri Dim West transports you from the Upper West Side to China with our delicious authentic dishes made with the highest quality ingredients. Tri Dim West carries a variety of traditional Chinese dishes from Dim Sum to Jelly fish salad, as well as our famous Pecking Duck, there\'s something for everyone. Whether a first date, large party, business lunch, or just catching up, Tri Dim West is the perfect venue for all your dining needs. With our stylish decor matched with friendly staff and of course amazing food you\'ll be sure to leave elated. Tri Dim West is a second location to popular sister restaurant Tri Dim Shanghai. Some of our customers favorite items are Soup Dumplings, DongPo Pork, and of course Pecking Duck!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7831,
        store_longitude: -73.9786,
        total_orders: 40.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Lobster, Italian, American, Rooftop, Bright, Natural, Casual, Rare, Great, Cultural',
        annual_dol: 58.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5927,
        store_longitude: -73.9253,
        total_orders: 7.8,
        neighborhood: '',
        keywords: 'Burger, Tacos, American, Irish, Fresh, Casual, Great',
        annual_dol: 31.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7354,
        store_longitude: -73.6749,
        total_orders: 631.63,
        neighborhood: '',
        keywords:
            'Steak, Burger, Hamburger, Dessert, Delicious, American, Casual, Classic, Handcrafted, Lively',
        annual_dol: 811.68,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5757,
        store_longitude: -73.9799,
        total_orders: 315.89,
        neighborhood: 'Coney Island',
        keywords: 'Salad, Wine, American, Spicy, Casual, Local, Traditional, Great',
        annual_dol: 386.96,
        description: '"Bar and Grill"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9187,
        total_orders: 577.77,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Trendy, Modern, Mediterranean, Casual, Greek, Sleek, Local, Cool, Traditional',
        annual_dol: 673.99,
        description:
            'Traditional seafood & lamb dishes get updated takes in a casual Greek tavern with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7704,
        store_longitude: -73.9155,
        total_orders: 37.58,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords: 'Pizza, Beer, Italian, Meatball, Trendy, Warm, Fresh, Clean, Perfect, Great',
        annual_dol: 87.19,
        description:
            'Trendy pizzeria & lounge serving craft cocktails, an array of pies & other bites in warm quarters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9143,
        total_orders: 1763,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tequila, Tacos, Whiskey, Vodka, Champagne, Beer, Steak, Salad, Bourbon, Cuban',
        annual_dol: 5148.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9407,
        total_orders: 2195.08,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Ale, Wine, Pizza, Relaxed, Sweet, Fun, Cool, Casual, Great',
        annual_dol: 2290.25,
        description:
            'A long list of brews, pub grub & a weekend beer brunch fuel the festive vibes at this relaxed bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6964,
        store_longitude: -73.9298,
        total_orders: 7677.61,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Whiskey, Gourmet, Gin, Cozy, Bourbon, Warm, Inviting, Relaxed',
        annual_dol: 8165.62,
        description:
            'Relaxed watering hole with a cozy, nostalgic vibe offering gourmet grilled cheese & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -73.958,
        total_orders: 414.16,
        neighborhood: 'Greenpoint',
        keywords:
            'Polished, Spacious, Hotel, Clean, Authentic, Friendly, Vibrant, Modern, Amazing, Excellent',
        annual_dol: 548.3,
        description:
            'Featuring wood floors and contemporary artwork, the polished suites provide complimentary Wi-Fi, flat-screen TVs and iPhone docks, plus minifridges and coffeemakers. Upgraded suites add kitchenettes, living areas, terraces and/or marble bathrooms.Amenities include a restaurant/bar, a fitness room and a sauna. An area shuttle is available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7352,
        store_longitude: -73.6605,
        total_orders: 300.62,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Nachos, Salad, Tex-Mex, Dessert, Burritos, Quesadillas, Fajitas, Casual',
        annual_dol: 378.5,
        description:
            "\"Gonzo's Mexican Grill is one of the finest garden city restaurants in the area! When you go into Gonzo's, you will see photos of the owner, Joseph Gonzalez posing with such super celebrities as Whoopi Goldberg and Robin Williams, but, when you get to the one of him and Albert Einstein, it occurs to you that there is something chronologically wrong. If you know Joe, you would realize that this is just one manifestation of his quirkiness. Gonzo's customers also enjoy the jovial atmosphere that is a result of Joe's outgoing personality. Come try anything from tacos, fajitas, and burritos to quesadillas, wings, and ensalades! We also have cervezas, sangria, margaritas, and don't forget to try our queso!\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7336,
        store_longitude: -73.9997,
        total_orders: 110.06,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Mexican, Tacos, Salad, Beer, Burritos, Wine, Casual, Local, Warm, Classy',
        annual_dol: 572.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7209,
        store_longitude: -73.8463,
        total_orders: 3068.78,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Instagrammable, Steak, Beer, Wine, Trendy, Latin, Fusion, Global, Aesthetic, American',
        annual_dol: 3272.09,
        description:
            'Trendy spot with a floral theme offering cocktails & a global menu of sharing plates & mains.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8194,
        store_longitude: -73.6008,
        total_orders: 206.31,
        neighborhood: '',
        keywords: 'Legendary, Hotel, Exceptional, Premium, Great, Cultural, Unique',
        annual_dol: 219.55,
        description:
            'Concert hall on LIU Post campus hosts a variety of music, dance & theater productions.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6502,
        store_longitude: -73.8374,
        total_orders: 272.37,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Wine, Steak, Cheesecake, Italian, Upscale, Dessert, Delicious, Elegant, Warm, Exquisite',
        annual_dol: 323.94,
        description:
            'This complex includes an Italian restaurant, wine cellar & lounge, plus views of Jamaica Bay.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7877,
        store_longitude: -73.954,
        total_orders: 41.48,
        neighborhood: 'East Harlem/Central Park West Historic District',
        keywords: 'Coffee, Salad, Wine, Italian, Dessert, Delicious, Gin, Beer, Cozy, Phenomenal',
        annual_dol: 169.56,
        description:
            'Chill, cozy espresso bar offering pastries & sandwiches, along with wine & Italian cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.657,
        store_longitude: -74.002,
        total_orders: 10.35,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Coffee, Wine, Casual, Chill',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.787,
        store_longitude: -73.978,
        total_orders: 1515.68,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Salad, Pizza, Shrimp Cocktail, Beer, French Onion Soup, Caesar Salad, French, American, Delicious',
        annual_dol: 1653.31,
        description:
            'Retro-chic eatery offering French dip sandwiches, steaks & other American bites & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6684,
        store_longitude: -73.5543,
        total_orders: 146.4,
        neighborhood: '',
        keywords: 'Coffee, Apple Pie, Pizza, Gourmet, Creme Brulee, Gumbo, Lively, Casual',
        annual_dol: 163.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -73.6738,
        total_orders: 23.83,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Salad, Chicken Caesar Salad, Caesar Salad, Casual, Local, Fabulous, Classic, Great',
        annual_dol: 33.38,
        description:
            'Italian restaurant serving pizza, subs, pasta & classic entrees in a relax, mural-filled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6687,
        store_longitude: -73.985,
        total_orders: 1472.9,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Hamburger, Salad, Beer, Nachos, Bbq, Tex-Mex, Ale, American, French',
        annual_dol: 1504.15,
        description:
            'A lengthy list of beer & spirits, plus burgers, pool & board games, are the draws at this bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.949,
        total_orders: 17392.72,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Vegetarian, Wine, Vodka, Fruity, Peruvian, Mediterranean, Lebanese, Eclectic, Global',
        annual_dol: 35796.99,
        description:
            'Lively restaurant serving global cuisine & cocktails amid plant-filled, industrial-chic decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6977,
        store_longitude: -73.9272,
        total_orders: 5648.44,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Margarita, Beer, Whimsical, Dope, Perfect, Urban, Awesome, Wonderful, Lit',
        annual_dol: 6044.63,
        description:
            'Cool, dimly lit bar featuring DJs, a photo booth & a rotating menu of astrology-inspired cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8395,
        store_longitude: -73.905,
        total_orders: 27.28,
        neighborhood: 'Morrisania/West Bronx/Claremont',
        keywords: 'Beer, Golfclub, Dominican, Delicious, Casual, Classic, Great',
        annual_dol: 123.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8331,
        store_longitude: -73.8515,
        total_orders: 643.38,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Sushi, Salad, Vegan, Steak, Seafood Paella, Cuban, Mexican, Rum, Warm, Dominican',
        annual_dol: 657.03,
        description:
            'Easygoing stop dispensing traditional Cuban dishes & mojitos in lively & upbeat surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -73.6837,
        total_orders: 122.27,
        neighborhood: '',
        keywords: 'Beer, Karaoke, Wine, Cozy, American, Sweet, Classy, Awesome, Local, Great',
        annual_dol: 221.4,
        description:
            '"Home sweet gnome. One of Long Island\'s favorite bars with cozy beer garden, serving a variety of beers, local crafts, liquor, wine, seltzers, and bar food. For entertainment, we have seven large screen HDTVs with sports, digital jukebox, arcade machine, video poker, and table hockey. We have daily specials and events: - Tuesday Game Night - Wednesday Karaoke - Friday DJ - Saturday DJ - and regularly rotating live bands & artists!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8137,
        store_longitude: -73.8852,
        total_orders: 23.5,
        neighborhood: 'Hunts Point/West Bronx',
        keywords: 'Exotic, American, Great, Casual',
        annual_dol: 96.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6696,
        store_longitude: -73.9829,
        total_orders: 823.77,
        neighborhood: 'Park Slope',
        keywords:
            'Vegan, Mexican, Salad, Coffee, Fajitas, Enchiladas, American, Latin, Delicious, Caesar Salad',
        annual_dol: 917.21,
        description:
            'Classic Mexican fare, sangria & more come in casual, colorful digs at this low-key corner standby.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6301,
        store_longitude: -74.1403,
        total_orders: 458.95,
        neighborhood: 'Port Richmond',
        keywords:
            'Pizza, Meatball, Salad, Italian, Margarita, Dessert, Homemade, Fresh, Sangria, Casual',
        annual_dol: 520.9,
        description:
            'Family-owned mainstay producing thin-crust pizza plus fried calamari, subs & more in no-frills digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7684,
        store_longitude: -73.9557,
        total_orders: 572.67,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Sushi, Salad, Wine, Beer, Coffee, Delicious, Casual, American, Perfect',
        annual_dol: 2157.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7071,
        store_longitude: -73.9474,
        total_orders: 1313.05,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Beer, Trendy, Fun, Lively, Relaxed, Vibrant, Eclectic, Great, Iconic, Casual',
        annual_dol: 1340.91,
        description:
            'Laid-back cocktail bar with live jazz, small plates, craft beers & a garden patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7674,
        store_longitude: -73.9596,
        total_orders: 176.42,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Gluten Free, Mexican, Mimosa, Beer, Gourmet, Tasty, Milkshake, Delicious',
        annual_dol: 216.46,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7706,
        store_longitude: -73.9265,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Japanese, Wine, Trendy, Cozy, Spacious, Friendly, Latin, Bright, Local',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7292,
        store_longitude: -73.9896,
        total_orders: 1816.82,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Salad, Wine, Delicious, American, Friendly, Fun, Vintage, Clean',
        annual_dol: 2069.23,
        description:
            'A spin-off of a Williamsburg bar, this hang has vintage video games (Pac-Man, Donkey Kong) & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8504,
        store_longitude: -73.9332,
        total_orders: 604.88,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Steak, Tasty, Beer, Delicious, Dominican, Caribbean, American, Latin, Lively, Great',
        annual_dol: 717.06,
        description:
            'Lively hot spot for Dominican fare, including a variety of mofongo, plus cocktails & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8015,
        store_longitude: -73.9647,
        total_orders: 11.39,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Thai, Salad, Spicy, Delicious, Martini, Green Curry, Latin, Authentic, Dominican, Caribbean',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.7683,
        total_orders: 179.84,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Salad, Italian, Shrimp Cocktail, Pizza, Coffee, Rich, Homemade, Casual, Traditional, Fresh',
        annual_dol: 225.86,
        description:
            'Stately Italian dining room offering a menu of traditional entrees, pastas & seafood, plus a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7631,
        store_longitude: -73.9687,
        total_orders: 1883.09,
        neighborhood: 'Lenox Hill',
        keywords:
            'Steak, Salad, Pizza, Wine, Dessert, Upscale, Margarita, Martini, Irish, Polished',
        annual_dol: 1923.04,
        description:
            'A classic steakhouse menu, plus inventive New American entrees & a raw bar, in sleek quarters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7576,
        store_longitude: -73.9707,
        total_orders: 586,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Lobster, Cheesecake, Salad, Japanese, Wine, Beer, Fusion, Delicious, Asian',
        annual_dol: 792.1,
        description:
            'Modern, colorful kitchen & bar offering sushi, poke & Japanese noodle dishes, plus daily happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7604,
        store_longitude: -73.9734,
        total_orders: 1015.7,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Salad, Flavorful, Dessert, Meatball, Delicious, Greek, Mediterranean, Upscale, Warm',
        annual_dol: 1015.7,
        description:
            'Chic Greek venue offering updated specialties & seafood priced by the pound, in an airy setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9691,
        total_orders: 1113.52,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Flavorful, Chinese, Salad, Upscale, Dessert, Rich, Gourmet, Trendy, Elegant, Spicy',
        annual_dol: 1137.14,
        description:
            'Classic, upscale Chinese eatery since 1971 serving traditional dishes in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7561,
        store_longitude: -73.9653,
        total_orders: 95.14,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Wine, Italian, Upscale, Hotel, American, Stylish, Delicious, Contemporary, Southern',
        annual_dol: 110.9,
        description:
            'This stylish throwback watering hole features beer, cocktails & a menu of upscale pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.748,
        store_longitude: -73.9858,
        total_orders: 546.98,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Steak, Salad, Beer, Rustic, Irish, Clean, Refreshing, Fun, Asian, Fresh',
        annual_dol: 671.13,
        description: '"Let the good time begins here. Good Food. Good Drinks. Good Friends."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7431,
        store_longitude: -73.9909,
        total_orders: 1663.05,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Hotel, Trendy, Bourbon, Cozy, Rooftop, Amazing, Intimate, Greek, Modern, Edgy',
        annual_dol: 1783.11,
        description:
            "Modern rooms with industrial-chic decor and graffiti-inspired artwork offer free Wi-Fi, flat-screen TVs, coffeemakers and marble bathrooms. Upgraded rooms and suites add balconies.There's a Greek restaurant by celebrity chef Emeril Lagasse, and an indoor/outdoor penthouse bar. Other amenities include passes to the nearby Equinox gym",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.996,
        total_orders: 12782.94,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Mexican, Upscale, Sustainable, Warm, Hotel, Italian, American, Fun, Perfect',
        annual_dol: 13139.88,
        description:
            'Local produce, Berkshire pork chops & fresh fish star on the New American menu at this hotel eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6889,
        store_longitude: -73.9552,
        total_orders: 288.75,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Burger, Steak, Lobster, Tequila, Whiskey, Beer, Scotch, Wine, Bourbon, Ale',
        annual_dol: 488.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9875,
        total_orders: 1178.2,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'American, Wine, Prosecco, Modern, Sweet',
        annual_dol: 4816.08,
        description:
            'The Lyric Theatre is a Broadway theater at 214 West 43rd Street in the Theater District of Midtown Manhattan in New York City. Opened in 1998, the theater was designed by Richard Lewis Blinder of Beyer Blinder Belle, in collaboration with Peter Kofman, for Garth Drabinsky and his company Livent.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6845,
        store_longitude: -73.9917,
        total_orders: 52.94,
        neighborhood: 'Carroll Gardens/Brooklyn Heights',
        keywords:
            'Steak, Vegan, Pizza, Wine, Tasty, French, German, French Onion Soup, Swiss, Trendy',
        annual_dol: 216.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7764,
        store_longitude: -73.9102,
        total_orders: 4998.64,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Coffee, Wine, Salad, Beer, Upscale, Mimosa, Screwdriver, Trendy, American, Spacious',
        annual_dol: 7539.4,
        description:
            'Spacious cafe featuring New American fare, coffee drinks & Wi-Fi in rustic-chic surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7302,
        store_longitude: -74.0005,
        total_orders: 16448.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Hamburger, Cheesecake, Kebab, Pizza, Coffee, Italian, Margarita, American, Cozy',
        annual_dol: 16797.59,
        description:
            'Longtime joint above the Comedy Cellar with Middle Eastern fare, Chaplin films & chalkboard tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.7898,
        total_orders: 71.04,
        neighborhood: 'Auburndale/Flushing',
        keywords:
            'Vegetarian, Coffee, Chinese, Irish, Bourbon, Fun, Mediterranean, Friendly, Awesome, American',
        annual_dol: 83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7025,
        store_longitude: -73.9267,
        total_orders: 1956.37,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Margarita, Wine, Rum, Dominican, Hotel, American, Latin, Urban, Casual',
        annual_dol: 1956.37,
        description:
            'Funky, plant-filled nightclub in a warehouse with a dance hall, DJs & Latin American nibbles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7306,
        store_longitude: -74.0042,
        total_orders: 1392.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tequila, Beer, Vodka, Wine, Scotch, Bourbon, Ale, Gin, Delicious, Rum',
        annual_dol: 1421.99,
        description:
            'Sports bar offering TVs & pool in a space adorned with a fireplace & stained-glass windows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8475,
        store_longitude: -73.8319,
        total_orders: 38.34,
        neighborhood: 'Pelham Bay',
        keywords: 'Vegetarian, Italian, Friendly, Casual, Fun, Great',
        annual_dol: 156.74,
        description: '"Open for Dine in"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7065,
        store_longitude: -73.6266,
        total_orders: 120.97,
        neighborhood: '',
        keywords: 'Clean, Friendly, Casual, Fresh, Local, Classic, Attentive, Indian',
        annual_dol: 494.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8543,
        store_longitude: -73.8884,
        total_orders: 1065.94,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Steak, Pizza, Salad, Italian, Wine, Dessert, Coffee, New-York-Style, American, Homemade',
        annual_dol: 1538.02,
        description:
            'Large family-owned Neapolitan-Italian restaurant serving red-sauce fare since 1919.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9609,
        total_orders: 3369.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Salad, Beer, Wine, Tasty, Upscale, Warm, Delicious, Juicy, Hotel, Bbq',
        annual_dol: 3447.27,
        description:
            'Craft beers, cocktails & New American bar bites feature at this hip hangout with pingpong & pinball.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7172,
        store_longitude: -74.0126,
        total_orders: 115.87,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Trendy, Hotel, Healthy, Perfect, Homemade, Global, Great, Local, Casual, Diverse',
        annual_dol: 315.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7706,
        store_longitude: -73.9572,
        total_orders: 194.73,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Cheesecake, Salad, Pizza, Vegetarian, Chocolate Mousse, Italian, Gluten Free, Wine, Delicious, Classy',
        annual_dol: 236.87,
        description:
            'Classic Italian brick-walled cafe serving pastas & more, with plenty of outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6917,
        store_longitude: -73.9395,
        total_orders: 43.17,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Savory, Hotel, Sweet, Cool, Nachos, Great, Casual',
        annual_dol: 176.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9934,
        total_orders: 57397.57,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Whiskey, Irish, Spectacular, Hotel, Fun, Iconic, Great, Excellent, Friendly, Rooftop',
        annual_dol: 59004.16,
        description:
            'Madison Square Garden, colloquially known as The Garden or by its initials MSG, is a multi-purpose indoor arena in New York City. It is located in Midtown Manhattan between Seventh and Eighth avenues from 31st to 33rd Street, above Pennsylvania Station.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6725,
        store_longitude: -73.9833,
        total_orders: 536.01,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Wine, Ale, Hotel, Irish, Awesome, Local, Casual, Great, Artisanal',
        annual_dol: 656.59,
        description:
            'Hard-to-find beers turn up at this laid-back tavern with dim lighting & an appealing patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.9173,
        total_orders: 235.83,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Beer, Wine, Irish, Karaoke, American, Classic, Perfect, Great',
        annual_dol: 246.06,
        description:
            'Longtime neighborhood Irish bar offering pub grub in comfy digs, plus karaoke, a pool table & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9186,
        total_orders: 346.8,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegetarian, Burger, Salad, Tasty, Coffee, Wine, Dessert, Delicious, Warm, Healthy',
        annual_dol: 364.23,
        description:
            'Cozy, urban, neighborhood bar/eatery offering inventive comfort food, a tapas menu & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9193,
        total_orders: 813.96,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, South American, Colombian, American, Latin, Mojito, Casual, Authentic, Great',
        annual_dol: 1154.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7703,
        store_longitude: -73.8704,
        total_orders: 467.88,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Hotel, Vibrant, Colorful, Contemporary, Hip',
        annual_dol: 497.91,
        description:
            "Queens Museum.Bright, streamlined rooms come with free Wi-Fi and flat-screen TVs. Upgraded rooms sleep up to 4 guests.There's a colorful bistro and an informal bar. Breakfast is available for a fee. Pets are welcome (fee).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.9427,
        total_orders: 5140.7,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Zen, Armenian, Casual, Urban',
        annual_dol: 5481.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7468,
        store_longitude: -73.9455,
        total_orders: 13.33,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Coffee, Tasty, Peruvian, Juicy, Delicious, Bbq, Fried Rice, Latin, American, Natural',
        annual_dol: 54.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6755,
        store_longitude: -74.0156,
        total_orders: 49.01,
        neighborhood: 'Red Hook',
        keywords: 'Beer, Delicious, American, Relaxed, Casual, Handcrafted, Great, Local',
        annual_dol: 126.3,
        description:
            '"Brewery and tasting room specializing in handcrafted ales, lagers and Foeder beers."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7254,
        store_longitude: -73.9919,
        total_orders: 2891.09,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Sushi, Delicious, Salad, Wine, Japanese, Beer, Amazing, Creative, Intimate, Relaxed',
        annual_dol: 5361.12,
        description:
            'Intimate setting for creative seasonal plates served in tasting menus & à la carte.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.9885,
        total_orders: 999.8,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Lobster Roll, Lobster, Chocolate Mousse, Wine, Dessert, Vodka, Beer, Gin, Upscale, Warm',
        annual_dol: 1114.04,
        description:
            'Upscale-casual seafood spot with a Cape Cod vibe known for its lobster rolls and cheap oyster deal.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7279,
        store_longitude: -73.9856,
        total_orders: 1105.67,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tacos, Mexican, Milkshake, Margarita, Tasty, Burritos, American, Cozy, Latin, Sweet',
        annual_dol: 1129.13,
        description:
            'This cozy venue offers tacos, burritos & other low-key Mexican eats, plus beers & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7266,
        store_longitude: -73.9863,
        total_orders: 139.03,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Salad, Coffee, Italian, Martini, Californian, Trendy, American, Casual, Local',
        annual_dol: 340.05,
        description: '"East Village bar and restaurant."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7343,
        store_longitude: -73.8647,
        total_orders: 1396.84,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Burger, Cornbread, Salad, Bbq, Margarita, Californian, American, Yummy, Coleslaw, Friendly',
        annual_dol: 1457.4,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -73.8663,
        total_orders: 530.05,
        neighborhood: 'Rego Park/Elmhurst/Flushing',
        keywords: 'Wine, Scotch, Tasty, Beer, Delicious, Savory, Hotel, American, Premium, Relaxed',
        annual_dol: 2615.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7322,
        store_longitude: -73.9853,
        total_orders: 225.99,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Upscale, Perfect, Rooftop, Casual, Creative',
        annual_dol: 257.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5756,
        store_longitude: -73.9613,
        total_orders: 442.02,
        neighborhood: 'Brighton Beach',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Italian, Roast Beef, Russian, Delicious, Yummy, Spanish, Relaxed',
        annual_dol: 481.73,
        description:
            'Vegas-style floor shows are paired with Russian eats at this glitzy nightclub with boardwalk tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7539,
        store_longitude: -73.9775,
        total_orders: 8803.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Coffee, Delicious, Hotel, Classic, Great, Western, Wonderful, Excellent, Casual',
        annual_dol: 8837.41,
        description:
            'The Yale Club of New York City, commonly called The Yale Club, is a private club in Midtown Manhattan, New York City. Its membership is restricted almost entirely to alumni and faculty of Yale University. The Yale Club has a worldwide membership of over 11,000.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9781,
        total_orders: 1403.63,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Upscale, French, Warm, Spanish, Phenomenal, Contemporary, Innovative, Seasonal, Traditional',
        annual_dol: 5034.33,
        description:
            'Wine & French fare served in a dramatic, upscale eatery with greenery, high ceilings & city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7662,
        store_longitude: -73.7722,
        total_orders: 72.28,
        neighborhood: 'Bayside',
        keywords:
            'Vegetarian, Salad, Vodka, Stylish, Delicious, Upscale, Trendy, Elegant, Romantic, Mediterranean',
        annual_dol: 78.77,
        description:
            'Compact eatery offering Greek dishes & sandwiches, plus soups & sides in a casual ambiance.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7106,
        store_longitude: -74.0095,
        total_orders: 20843.13,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Lobster, Salad, Spicy, Wine, Japanese, Creamy, Innovative, Seasonal, Superb',
        annual_dol: 22043.32,
        description:
            'Famed Japanese-Peruvian fare in a landmark space with an artful lounge & downstairs dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7371,
        store_longitude: -73.9846,
        total_orders: 961.9,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Burger, Beer, Vodka, Ale, Nachos, French, Quesadillas, Irish, American, Local',
        annual_dol: 982.31,
        description:
            'This neighborhood pub draws sports fans to watch games, play darts & shoot pool.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6908,
        store_longitude: -73.9837,
        total_orders: 1346.87,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Lobster, Salad, Cajun, Lobster Bisque, Wine, Beer, Bbq, Ale, Korean, Creole',
        annual_dol: 2992.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9849,
        total_orders: 1180.47,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, American, Modern, Premium, Rooftop, Artisanal, Contemporary, Bold Flavor, Vibrant, Exceptional',
        annual_dol: 1205.51,
        description:
            'Contemporary eatery with new renditions of steakhouse favorites in a roomy, industrial-chic space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 2006.93,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Pizza, Hamburger, Salad, American, Casual, Friendly, Local, Classic, Lively',
        annual_dol: 2320.88,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9846,
        total_orders: 2113.68,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Dessert, Modern, Delicious, Hotel, Russian, American, White Russian',
        annual_dol: 7578.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7513,
        store_longitude: -73.991,
        total_orders: 1184.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Steak, Vegetarian, Tasty, American, Irish, Fun, Iconic, Rooftop, Local, Casual',
        annual_dol: 1367.77,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.751,
        store_longitude: -74.0026,
        total_orders: 590.95,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Wine, Italian, Pizza, Cozy, Quaint, Beer, Romantic, Friendly, French',
        annual_dol: 763.5,
        description:
            'Cozy locale with a patio serving Italian fare for breakfast, lunch & dinner, plus happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6277,
        store_longitude: -74.0138,
        total_orders: 216.23,
        neighborhood: 'Dyker Heights',
        keywords: 'Pizza, Beer, Wine, Irish, Warm, Relaxed, Casual, Southern, Great, Local',
        annual_dol: 230.44,
        description:
            'Draft beer & cocktails served in a down-to-earth sports bar with games on TV & a warm vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7296,
        store_longitude: -74.001,
        total_orders: 5592.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Hotel, Casual, Welcoming, Local, Great',
        annual_dol: 6519.22,
        description:
            'Clubby pub where beer & burgers are served to folks watching TV sports, live comedians or karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7214,
        store_longitude: -73.9953,
        total_orders: 1263.78,
        neighborhood: 'Nolita',
        keywords:
            'Wine, Salad, Vegetarian, Dessert, Mediterranean, Beer, Delicious, Rustic, Spicy, Premium',
        annual_dol: 1620,
        description:
            'Restaurant & wine bar serving Mediterranean small plates & entrees in a rustic, brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -73.998,
        total_orders: 2625.68,
        neighborhood: 'Little Italy',
        keywords:
            'Lasagna, Lobster, Pizza, Chicken Parmigiana, Italian, Gluten Free, Wine, Whiskey, Vodka, Meatball',
        annual_dol: 2705.38,
        description:
            'Traditional Italian eatery offering the standard red-sauce fare in an old-school setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7176,
        store_longitude: -74.0053,
        total_orders: 136.46,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Sushi, Cozy, Intimate, Japanese, Refined, Traditional, Casual, Seasonal, Rare, Cool',
        annual_dol: 557.79,
        description:
            'Refined Japanese spot with a 12-seat counter for omakase sushi & kaiseki dinners.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7059,
        store_longitude: -73.9226,
        total_orders: 449.62,
        neighborhood: 'Bushwick',
        keywords:
            'Kebab, Salad, Tequila, Margarita, Mediterranean, Chicken Shawarma Plate, Delicious, Cozy, Israeli, Shawarma',
        annual_dol: 712.57,
        description:
            'Mediterranean mezze & mains are served in a quirky space with a relaxed vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7051,
        store_longitude: -73.9274,
        total_orders: 360.94,
        neighborhood: 'East Williamsburg',
        keywords:
            'Wine, Beer, Warm, Delicious, Rustic, Organic, Natural, Perfect, Seasonal, Casual',
        annual_dol: 1475.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7755,
        store_longitude: -73.9532,
        total_orders: 10912.44,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Whiskey, Beer, Salad, Wine, Delicious, Upscale, Mimosa, Warm, Irish',
        annual_dol: 11151.14,
        description:
            'Old-fashioned cocktails & a hefty beer & whiskey list, set amid reclaimed wood & vintage wallpaper.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7214,
        store_longitude: -73.8452,
        total_orders: 1911.35,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Salad, Lobster, Sushi, Japanese, Tasty, Chinese, Thai, Asian, Delicious, Greek',
        annual_dol: 1956.59,
        description:
            'This contemporary Asian-fusion spot boasts creative cuisine, plus a nightclub vibe & a sushi menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7026,
        store_longitude: -73.9172,
        total_orders: 604.83,
        neighborhood: 'Bushwick',
        keywords:
            'Pizza, Vegetarian, Salad, Coffee, Wine, Organic, Cozy, Artisanal, Natural, Perfect',
        annual_dol: 631.06,
        description:
            'Comfy pizzeria serving wood-fired pies with a sourdough crust, plus natural wines & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7404,
        store_longitude: -74.0056,
        total_orders: 214.1,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Coffee, Vodka, Beer, Gin, Dessert, Gourmet, Bourbon, Delicious, Warm, Sweet',
        annual_dol: 748.08,
        description:
            'Chill, vintage-style spot with a patio for choice coffee, craft cocktails & gourmet light bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7209,
        store_longitude: -74.0054,
        total_orders: 27.59,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Steak, Whiskey Sour, Beer, Salad, Vodka, Whiskey, Coffee, Wine, Martini',
        annual_dol: 112.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7857,
        store_longitude: -73.6487,
        total_orders: 57.89,
        neighborhood: '',
        keywords:
            'Tacos, Kebab, Salad, Wine, Iranian, Turkish, Mediterranean, Homemade, Classy, Healthy',
        annual_dol: 74.65,
        description:
            'Laid-back Persian eatery offering kebabs & grilled meat plates, plus salads & rice dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6252,
        store_longitude: -74.0746,
        total_orders: 13.56,
        neighborhood: 'Clifton',
        keywords: 'Pizza, Salad, Dessert, French, Caesar Salad, Casual, American, Friendly',
        annual_dol: 55.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6481,
        store_longitude: -73.6885,
        total_orders: 25.04,
        neighborhood: '',
        keywords:
            'Burger, Wine, Beer, Avocado Toast, Ale, Irish, Belgian, Warm, French Toast, Cozy',
        annual_dol: 45.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -73.9129,
        total_orders: 242,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Tasty, Comfortable, Eclectic, Great, Terrific, Casual, Local',
        annual_dol: 911.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7309,
        store_longitude: -73.6935,
        total_orders: 1491.68,
        neighborhood: '',
        keywords:
            'Steak, Burger, Chicken Parmigiana, Salad, Meatball, Wine, Beer, French, Mediterranean, American',
        annual_dol: 1556.35,
        description:
            'New American fare is presented in spacious, modern surroundings at this family-friendly restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9165,
        total_orders: 485.29,
        neighborhood: 'Astoria',
        keywords: 'Tacos, Steak, Mexican, Rich, Trendy, Ale, Vibrant, Authentic, Lively, Fun',
        annual_dol: 3394.85,
        description:
            '"Inspired by our roots, we want to share with you one of the greatest riches that we have: our gastronomy. With original recipes from our ancestors, seasoned with the love and the charisma of our people, we’ve come to New York to stay. La Tiendita wants to bring you the experience of savoring legitimate Mexican food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6713,
        store_longitude: -73.9843,
        total_orders: 862.95,
        neighborhood: 'Park Slope',
        keywords: 'Tequila, Beer, Ale, Wine, American, Friendly, Casual, Local, Classic, Great',
        annual_dol: 926.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.9197,
        total_orders: 180.24,
        neighborhood: 'Astoria',
        keywords:
            'Burger, Warm, Coffee, Creamy, Bbq, Irish, Lively, Mediterranean, Welcoming, Friendly',
        annual_dol: 180.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.9575,
        total_orders: 969.58,
        neighborhood: 'Greenpoint',
        keywords:
            'Salad, Chicken Caesar Salad, Hotdog, Wine, Dessert, Italian, French, Impeccable, American, Classy',
        annual_dol: 1001.89,
        description:
            'American restaurant & bar with Canadian-inspired offerings, including a raw bar & seasonal plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7082,
        store_longitude: -73.9432,
        total_orders: 2009.45,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Beer, Delicious, Dessert, Japanese, Fun, Robust, Natural, Wonderful, Modern, Perfect',
        annual_dol: 2052.08,
        description:
            'Chill neighborhood tavern with small plates & brunch service, plus many craft beers & a dance floor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7638,
        store_longitude: -73.9677,
        total_orders: 561.98,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Tasty, Italian, Upscale, Wine, Coffee, Delicious, Elegant, Impeccable, Classy',
        annual_dol: 573.9,
        description:
            'An Italian menu of traditional dishes like pasta turned out in an intimate, classy space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8541,
        store_longitude: -73.8872,
        total_orders: 15.04,
        neighborhood: 'Little Italy/Belmont',
        keywords: 'Italian, Salad, Wine, Cozy, Greek, Classy, Casual, Local, Great',
        annual_dol: 61.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6836,
        store_longitude: -73.9924,
        total_orders: 690.27,
        neighborhood: 'Carroll Gardens',
        keywords: 'Burger, Vodka, Wine, Pizza, Beer, Salad, Bourbon, Gin, Italian, Delicious',
        annual_dol: 939.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6888,
        store_longitude: -74.0217,
        total_orders: 438.74,
        neighborhood: 'Governors Island',
        keywords: 'Hotel, Beer, Vintage, Exotic, Spacious, Cool, Unique',
        annual_dol: 551.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6441,
        store_longitude: -73.7804,
        total_orders: 5526.95,
        neighborhood: 'Jamaica',
        keywords: 'Pizza, American, Fresh, Great',
        annual_dol: 5782.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7343,
        store_longitude: -73.9923,
        total_orders: 1005.67,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Italian, Wine, Tasty, Savory, Spicy, Mongolian, Chinese, Asian, Polished, Kung Pao',
        annual_dol: 1027.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7949,
        store_longitude: -73.9658,
        total_orders: 94.96,
        neighborhood: 'Manhattan Valley/UWS',
        keywords: 'Mexican, Tacos, Burritos, Salad, Organic, Local, Casual',
        annual_dol: 388.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8286,
        store_longitude: -73.9485,
        total_orders: 6852.49,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Burger, Hamburger, Vegan, Salad, Beer, Margarita, Spicy, Creamy, American, Cozy',
        annual_dol: 7717.17,
        description:
            'Cozy pub offering a huge selection of craft beers on tap plus spirits & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.824,
        store_longitude: -73.9494,
        total_orders: 331.91,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Tequila, Whiskey, Beer, Vodka, Margarita, Bourbon, Gin, Salad, Mimosa, American',
        annual_dol: 469.7,
        description:
            'Bar with a contemporary look, focusing on craft beers, whiskeys, on-tap wines & small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6291,
        store_longitude: -74.0274,
        total_orders: 72.86,
        neighborhood: 'Bay Ridge',
        keywords: 'American, Beer, Foreign',
        annual_dol: 91.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7792,
        store_longitude: -73.9508,
        total_orders: 166.04,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tacos, Steak, Mexican, Tequila, Salad, Nachos, Margarita, Enchiladas, Fajitas, Delicious',
        annual_dol: 301.74,
        description:
            '"WE ADDED NEW HOURS OPEN FOR BREAKFAST 10:00 AM Monday through Friday. BAR OPEN UNTIL 2 AM Thursday through Saturday"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6893,
        store_longitude: -73.9713,
        total_orders: 4317.89,
        neighborhood: 'Fort Greene',
        keywords: 'Whiskey, Vodka, Beer, Scotch, Bourbon, Tasty, Ale, Rum, Delicious, Cozy',
        annual_dol: 4409.51,
        description:
            'Mixologist-made cocktails in a tile-lined bar hidden behind a speakeasy-style unmarked door.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9917,
        total_orders: 174.76,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Vegetarian, Salad, Mexican, Gluten Free, Canadian, Rustic, Peruvian, Ecuadorian, Mimosa',
        annual_dol: 450.32,
        description:
            'Latin American fare in rustic-chic digs with Mexican tiles, repurposed Brazilian wood & marble bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9965,
        total_orders: 4246.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Stylish, Modern, Caesar Salad, Premium, Contemporary, Fresh, Classic, Tradition',
        annual_dol: 4735.27,
        description:
            'Stylish nightspot featuring live DJ events & a lounge for cocktails & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9929,
        total_orders: 673.29,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Wine, Karaoke, Fun, Bbq, Intimate, Quirky, Perfect, Great, Casual',
        annual_dol: 673.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5197,
        store_longitude: -74.2351,
        total_orders: 1479.42,
        neighborhood: 'Tottenville',
        keywords:
            'Steak, Salad, Sushi, Japanese, Thai, Asian, Contemporary, Fusion, Polished, Casual',
        annual_dol: 1526.46,
        description:
            'Contemporary sushi house & hibachi grill offering Chinese, Thai & Japanese menu items.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6932,
        store_longitude: -73.9672,
        total_orders: 303.81,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Salad, Pizza, Shrimp Cocktail, Tasty, Italian, Dessert, Wine, Vodka, Gin',
        annual_dol: 551.44,
        description:
            'Warm Italian joint making pizza, pasta & risotto, plus fritto misto, burrata salad & oysters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9865,
        total_orders: 119.46,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Wine, Trendy, Beer, Coffee, Stylish, French, Friendly, Unique',
        annual_dol: 488.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7085,
        store_longitude: -74.0136,
        total_orders: 32.62,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Steak, Burger, Hamburger, Pizza, Salad, Vodka, Nachos, Japanese, Bbq, Martini',
        annual_dol: 133.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7479,
        store_longitude: -73.9946,
        total_orders: 11846.59,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Korean, Casual, Premium',
        annual_dol: 12097.95,
        description:
            'DJs & bottle service keep the party going at this bi-level club with a high-wattage sound system.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7537,
        store_longitude: -73.9959,
        total_orders: 2709.61,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Lobster, Burger, Lobster Tail, Shrimp Cocktail, Wine, Beer, Spanish, Delicious, Australian',
        annual_dol: 2769.07,
        description:
            'Traditional chophouse serving large steaks, classic sides & martinis in a clubby atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9995,
        total_orders: 10.35,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Tasty, Delicious, Elegant, Clean, Exceptional',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7278,
        store_longitude: -74.0011,
        total_orders: 13749.36,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Japanese, Modern, Exotic, Local, Retro, Casual, Sophisticated, Nostalgic, Serene, Cool',
        annual_dol: 16014,
        description:
            'Japanese-inspired craft cocktails & comfort dishes are the draw at this low-lit, sophisticated bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6629,
        store_longitude: -73.6918,
        total_orders: 11.04,
        neighborhood: '',
        keywords: 'Salad, Pizza, Italian, Mexican, Beer, Asian, Clean, Friendly, Casual, Sweet',
        annual_dol: 27.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.975,
        total_orders: 12462.08,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Luxurious, Elegant, Hotel, Rich, Salad, Organic, Vibrant, Premium, Sleek, Polished',
        annual_dol: 12789.25,
        description:
            "The elegant rooms offer marble bathrooms, free Wi-Fi and desks, plus flat-screen TVs with DVD players. Upgraded rooms include city views, fireplaces and soaking tubs. Suites add living areas and complimentary chauffeur service. Room service is available.There's a polished fine-dining restaurant, and a chic rooftop bar with a terrace and city views, as well as a cocktail bar, and a lounge serving afternoon tea. Other amenities include a fitness center, and a sleek spa with an indoor pool.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9884,
        total_orders: 1191.6,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Martini, Rum, American, French, Sweet, Local, Perfect, Aperol, Great',
        annual_dol: 1296.77,
        description:
            'Sports bar with pub food, tap beers, cocktails & multiple TVs, serving till 4 AM nightly.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7228,
        store_longitude: -73.9891,
        total_orders: 63.88,
        neighborhood: 'Bowery',
        keywords:
            'Flavorful, Salad, Tacos, Quaint, Trendy, Mediterranean, Rustic, Healthy, Polished, Fun',
        annual_dol: 224.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7684,
        store_longitude: -73.7485,
        total_orders: 225.79,
        neighborhood: 'Douglaston/Flushing/Little Neck',
        keywords:
            'Lasagna, Lobster, Pizza, Italian, Salad, Dessert, Wine, Upscale, Caesar Salad, Fantastic',
        annual_dol: 327.71,
        description:
            'Upscale destination serving Northern Italian cuisine & fine wines in an elegant, old-world setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8978,
        store_longitude: -73.867,
        total_orders: 16.21,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords: 'Tasty, Cozy, Friendly, Irish, Casual, Local, Asian, Excellent, Latin, Unique',
        annual_dol: 66.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6871,
        store_longitude: -73.8356,
        total_orders: 16.56,
        neighborhood: 'Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Nachos, Beer, Philly Cheesesteak, Sustainable, Local, Casual',
        annual_dol: 67.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7107,
        store_longitude: -73.9591,
        total_orders: 583.22,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Wine, Ale, Donut, Four Horsemen, Delicious, American, Funky, Perfect, Fresh, Great',
        annual_dol: 792.74,
        description:
            'A young crowd populates this pork-focused American eatery in an older Williamsburg neighborhood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5791,
        store_longitude: -74.1637,
        total_orders: 1597.89,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Lasagna, Pizza, Lobster, Steak, Vodka, Beer, Italian, Wine, Meatball, Ribeye Steak',
        annual_dol: 1716.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7081,
        store_longitude: -74.0052,
        total_orders: 883.4,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Wine, Salad, Shrimp Cocktail, Ale, Beer, Margarita, Homemade, Irish, Cozy, American',
        annual_dol: 1159.99,
        description:
            'A full menu of American eats & plenty of beer is on tap at this Irish-style tavern.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8039,
        store_longitude: -73.9324,
        total_orders: 1637.34,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Sushi, Lobster, Salad, Lobster Tail, Dessert, Tasty, Spicy, Japanese, Chilean, Delicious',
        annual_dol: 1690.16,
        description:
            'Japanese sushi specialist with a sleek interior & a 2-for-1 drink deal available every evening.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.9776,
        total_orders: 937.94,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Hamburger, Steak, Cheesesteak, Salad, French Toast, French, American, Omelette, Fine Quality',
        annual_dol: 1000.29,
        description:
            'This casual American eatery, known for its burgers, also serves breakfast, Tex-Mex fare & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7495,
        store_longitude: -73.9828,
        total_orders: 1592.63,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Steak, Hotdog, Salad, Beer, Ale, Irish, Vintage, Spacious, American, Eclectic',
        annual_dol: 6390.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.711,
        store_longitude: -73.9649,
        total_orders: 815.03,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Beer, Romantic, Spacious, Friendly, Classic, Fun, Cool, Great, Casual, Incredible',
        annual_dol: 850.37,
        description:
            'No-frills barroom with cheap drinks, exposed-brick walls, a pool table & a spacious patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -73.9843,
        total_orders: 74.58,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Burger, Beer, Nachos, American, Friendly, Innovative, Casual, Great',
        annual_dol: 304.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6854,
        store_longitude: -73.773,
        total_orders: 72.91,
        neighborhood: 'Jamaica/Addisleigh Park/St. Albans',
        keywords:
            'Lobster, Jamaican, Caribbean, Beer, Rum, Friendly, Authentic, Excellent, Local, Tradition',
        annual_dol: 274.72,
        description: '"Restaurant and Lounge"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7439,
        store_longitude: -73.985,
        total_orders: 94.69,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Sushi, Steak, Japanese, Chinese, Korean, Asian, Fusion, Local, Casual, Rooftop',
        annual_dol: 387.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7425,
        store_longitude: -73.953,
        total_orders: 200.36,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Hamburger, Salad, Bbq, Spicy, American, Polished, Casual, Local, Perfect, Bloody Mary',
        annual_dol: 234.09,
        description:
            'Seasonal American dishes & local beers served in an industrial, urban-chic restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9636,
        total_orders: 3144.22,
        neighborhood: 'Lenox Hill',
        keywords:
            'Burger, Hamburger, Mexican, Salad, Beer, Vodka, American, Brazilian, Fun, Friendly',
        annual_dol: 4039.25,
        description:
            'Dating back to 1937, this dive bar is in a new space with its original, restored neon sign & booths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6968,
        store_longitude: -73.9352,
        total_orders: 467.14,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Hotel, Vodka, Beer, Spectacular, Fantastic, Smooth',
        annual_dol: 1345.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5228,
        store_longitude: -74.2348,
        total_orders: 21.78,
        neighborhood: 'Charleston',
        keywords: 'Dessert, Pizza, Savory, Upscale, Mexican, Italian, Delectable, Casual',
        annual_dol: 125.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -73.9863,
        total_orders: 306.71,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Wine, Legendary, Fun, Great',
        annual_dol: 313.22,
        description:
            'Outpost of a festive saloon chain with female bartenders, bartop dancing & body shots.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7306,
        store_longitude: -73.986,
        total_orders: 152.95,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Salad, Bread Pudding, Dessert, Italian, Tasty, Champagne, Mediterranean, Relaxed, Friendly',
        annual_dol: 156.19,
        description:
            'Casual Mediterranean-Italian dining in a low-key setting with sidewalk seating in summer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5978,
        store_longitude: -74.0834,
        total_orders: 255.45,
        neighborhood: 'Arrochar',
        keywords:
            'Italian, Delicious, Upscale, Mediterranean, Turkish, Sweet, Friendly, Hurricane, Filet Mignon, Authentic',
        annual_dol: 347.74,
        description:
            'Upscale seafood-focused Italian fare & opulent, palazzo-inspired decor in a tiny strip mall.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.8676,
        total_orders: 1150.04,
        neighborhood: 'Middle Village/Flushing/Rego Park',
        keywords: 'Burger, Sushi, Salad, Wine, Beer, Mediterranean, French, Fresh, Casual, Great',
        annual_dol: 1228.18,
        description:
            'Family-run seafood standby offering daily fresh-caught-fish specials plus sushi & a raw bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.9446,
        total_orders: 1333.4,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Coffee, Dessert, Turkish, Mediterranean, American, Contemporary, Polished, Casual, Hummus, Gyro',
        annual_dol: 1580.48,
        description:
            'Stylish, contemporary restaurant & lounge offering Turkish cuisine, specialty cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.9723,
        total_orders: 728.75,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Italian, Trendy, Cozy, Polished, Homemade, Authentic, Traditional, Classic, Excellent',
        annual_dol: 760.35,
        description:
            'Intimate, low-key restaurant offering traditional Italian dishes for lunch, dinner & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8694,
        store_longitude: -73.9033,
        total_orders: 330.27,
        neighborhood: 'Fordham Manor/Kingsbridge Heights/West Bronx',
        keywords: 'Burger, Steak, Tacos, Pizza, Salad, Italian, Beer, Chinese, Dominican, Wine',
        annual_dol: 631.97,
        description:
            'Rotisserie chicken, stewed meats & other Latin staples are the draw at this casual kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7664,
        store_longitude: -73.7723,
        total_orders: 92.6,
        neighborhood: 'Bayside',
        keywords:
            'Salad, Dessert, Mediterranean, Upscale, Delicious, Greek, Modern, Fresh, Greek Salad, Relaxed',
        annual_dol: 107.93,
        description:
            'Relaxed mom-&-pop eatery executing traditional Greek options including seafood specialties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7654,
        store_longitude: -73.9772,
        total_orders: 933.31,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Burger, Salad, Cajun, Shrimp Cocktail, Italian, Wine, Canadian, Upscale, Coffee',
        annual_dol: 960.5,
        description:
            'Classic American & Italian steak & seafood dishes served in a cozy, upscale atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -73.9889,
        total_orders: 432.19,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Steak, Mexican, Margarita, Spanish, Fresh, Awesome, Authentic, Amazing, Fine',
        annual_dol: 1655.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9914,
        total_orders: 4557.53,
        neighborhood: 'Bowery',
        keywords:
            'Wine, Whiskey, Scotch, Bourbon, Beer, Trendy, Intimate, Relaxed, Classy, Italian',
        annual_dol: 5495.03,
        description:
            'Innovative, creative cocktails & classic concoctions mixed up in a trendy industrial-style space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8275,
        store_longitude: -73.95,
        total_orders: 1272.68,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Sushi, Crab Cakes, Salad, Wine, Beer, Japanese, Sashimi, Dominican, Latin, Sweet',
        annual_dol: 1316.61,
        description: '"Latin Japanese Fusion"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6654,
        store_longitude: -73.9897,
        total_orders: 433.66,
        neighborhood: 'South Slope/Park Slope',
        keywords:
            'Burger, Salad, Pizza, French Toast, Sidecar, French, Thai, American, Gumbo, Asian',
        annual_dol: 532.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -73.956,
        total_orders: 765.66,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Beer, Seasonal, Local',
        annual_dol: 798.86,
        description:
            '"Gamehaus New York is El Grupo SN’s newest two floor indoor/outdoor sports bar and multi-functional venue nestled in the heart of Long Island City. Gamehaus features a dozen large-screen TVs and the largest LED video wall in LIC for your favorite live sports and pay per view events. Come play popular arcade and table games while enjoying our wide variety of seasonal craft and local beers, cocktails, mocktails and comfort food favorites."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -73.9892,
        total_orders: 579.73,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Vegetarian, Italian, Meatball, Dessert, Wine, Homemade, Modern, Bright, Casual',
        annual_dol: 592.03,
        description:
            'Italian eatery with Roman dishes like homemade pastas, wine & a full bar in a modern setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5969,
        store_longitude: -74.0851,
        total_orders: 259.88,
        neighborhood: 'Arrochar',
        keywords:
            'Pizza, Salad, Italian, Shrimp Cocktail, Delicious, Warm, Comfortable, Fresh, Casual, Wonderful',
        annual_dol: 265.4,
        description:
            'Down-to-earth Italian outfit serving traditional Sicilian dishes & specials, plus desserts & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5979,
        store_longitude: -74.073,
        total_orders: 174.34,
        neighborhood: 'Concord',
        keywords:
            'Sushi, Shrimp Cocktail, Hawaiian, Chinese, Japanese, Asian, Delicious, Local, Casual, Clean',
        annual_dol: 336.21,
        description:
            'Casual strip-mall joint offering familiar Chinese cooking, plus sushi & other Japanese dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.7765,
        total_orders: 30.48,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Vegetarian, Steak, Vegan, Cheesesteak, Salad, Roast Turkey, Italian, Nachos, Beer',
        annual_dol: 49.7,
        description:
            'Unpretentious outfit with a large menu of American & Mediterranean staples, plus breakfast.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7621,
        store_longitude: -73.9744,
        total_orders: 427.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Italian, Upscale, Martini, Dessert, Trendy, Beer, Elegant, Swiss, Classy',
        annual_dol: 446.08,
        description:
            'Upscale Italian restaurant & bar inside the namesake store offering fine dining, brunch & wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.9659,
        total_orders: 264.33,
        neighborhood: 'Lenox Hill',
        keywords:
            'Pizza, Vegetarian, Vegan, Chicken Parmigiana, Salad, Italian, Vodka, Wine, Margarita, Tasty',
        annual_dol: 269.94,
        description:
            'Informal pizza parlor offering brick-oven pies along with salads, pastas & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6886,
        store_longitude: -73.9896,
        total_orders: 499.25,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Burger, Hamburger, Hotdog, Beer, Wine, American, French, Delicious, Quaint, Crisp',
        annual_dol: 509.85,
        description:
            'Basic restaurant boasting artisanal-style burgers & hot dogs, plus a bar with signature cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9864,
        total_orders: 6302.88,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Burger, Shrimp Cocktail, Pizza, Cajun, Margarita, Beer, American, Local, Casual',
        annual_dol: 6462.98,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7393,
        store_longitude: -73.9867,
        total_orders: 2055.08,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Coffee, Beer, German, Eclectic, Warm, Welcoming, Modern, Seasonal, Inspiring',
        annual_dol: 2658.68,
        description:
            "Fotografiska New York is a branch of the Swedish photography museum Fotografiska in Gramercy Park, Manhattan, New York City. The museum's home is the Church Missions House, a six-story, 45,000-square-foot Renaissance Revival landmark. It opened in December 2019.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6442,
        store_longitude: -73.6392,
        total_orders: 201.61,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Sushi, Cajun, Lobster Bisque, Tasty, Japanese, Ramen, Delicious, Fried Shrimp',
        annual_dol: 277.27,
        description:
            '"The Angle - Hibachi Seafood Ramen Bar offers delicious dining and takeout to Oceanside, NY. The Angle - Hibachi Seafood Bar is a cornerstone in the Oceanside community and has been recognized for its outstanding Cajun seafood, hibachi cooking and teppanyaki steak cuisine, excellent service and friendly staff. Our restaurant is known for its modern interpretation of classic dishes and its insistence on only using high quality fresh ingredients. We accept all kinds of banquet, party and catering booking services。"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7414,
        store_longitude: -73.9929,
        total_orders: 5971.51,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Dessert, French, Delicious, Authentic, Traditional, Casual, Hip, Local, Delectable, Lit',
        annual_dol: 6098.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7431,
        store_longitude: -73.9908,
        total_orders: 477.25,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Burger, Gluten Free, Beer, Organic, Polished, Perfect, Premium, Modern, Casual, Rare',
        annual_dol: 1827.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.685,
        store_longitude: -73.9914,
        total_orders: 291.38,
        neighborhood: 'Boerum Hill/Carroll Gardens/Brooklyn Heights',
        keywords:
            'Pizza, Italian, Wine, Beer, Delicious, Mediterranean, Refined, Relaxed, Welcoming, Artisanal',
        annual_dol: 445.89,
        description:
            'Refined Italian spot with an old-world vibe, featuring wood-fired pizza, happy hour & a wine cellar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7005,
        store_longitude: -73.9884,
        total_orders: 53.66,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Vegan, Vegetarian, Tequila, Mexican, Salad, Beer, Margarita, Chinese, Trendy, Spicy',
        annual_dol: 219.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.9908,
        total_orders: 455.07,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Beer, Wine, Delicious, Funky, Eclectic, Vibrant, Cozy, Lively, Chinese, Refreshing',
        annual_dol: 637.52,
        description:
            'Laid-back haunt serving an eclectic menu of Asian-inspired plates plus craft cocktails, beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7715,
        store_longitude: -73.9819,
        total_orders: 2921.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lasagna, Lobster, Pizza, Chocolate Mousse, Salad, Italian, Dessert, Martini, Vodka, Meatball',
        annual_dol: 3037.88,
        description:
            'Sophisticated, wood-paneled institution with a patio for thin-crust pizzas & classic Italian mains.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9773,
        total_orders: 13680.7,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Hotel, Luxurious, French Toast, Coffee, Elegant, French, Rich, Organic, Premium',
        annual_dol: 14417.49,
        description:
            'Polished rooms with stylish, French-inspired decor offer free Wi-Fi, flat-screens, wireless sound systems and minibars. Suites add separate living areas. Upgraded suites include free breakfast and drinks.A palatial restaurant serves modern French cuisine. There’s also an elegant bar with a vaulted ceiling. Afternoon tea is available. Other amenities include a spa, a gym and an indoor pool, as well as a business center, event space and chauffeured car service.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9761,
        total_orders: 2373.32,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Modern, Contemporary, American, Burger, Salad, French, Casual, Seasonal, Rooftop, Fine',
        annual_dol: 9701.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -73.9935,
        total_orders: 1417.84,
        neighborhood: 'Bowery',
        keywords:
            'Wine, Tequila, Hotel, Beer, Classy, Homey, Intimate, Wonderful, Great, Incredible',
        annual_dol: 1479.32,
        description:
            'The Bowery Ballroom is a New York City live-music venue located at 6 Delancey Street in the neighborhood of Bowery in Manhattan. The Bowery Ballroom holds something of a cult status among musicians as well as audiences. Rolling Stone magazine has awarded it #1 Best Club in America.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.9895,
        total_orders: 7884.89,
        neighborhood: 'Lower East Side',
        keywords: 'Whiskey, Wine, Beer, Scotch, Martini, Bourbon, Coffee, Irish, Delicious, Sleek',
        annual_dol: 8138.2,
        description:
            'Customers can sip pints at a long copper bar & play pool at this Irish pub with a downstairs lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.993,
        total_orders: 3280.3,
        neighborhood: 'Bowery',
        keywords: 'Beer, German, Whiskey, Ale, Martini, French, Dessert, Crisp, Cointreau, Sangria',
        annual_dol: 3788.17,
        description:
            'Lively tavern featuring German brews & cuisine such as bratwurst & schnitzel, plus an outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 610.93,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Flavorful, Thai, Steak, Wine, Elegant, Spicy, Delicious, Green Curry, Traditional',
        annual_dol: 1057.25,
        description:
            'Manhattan outpost of a Queens staple offering spicy curries, noodles & other traditional Thai eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6819,
        store_longitude: -73.986,
        total_orders: 1402.88,
        neighborhood: 'Gowanus',
        keywords: 'Delicious, Bbq, Healthy, Perfect, Classic, Premium, Great, Fine, Western',
        annual_dol: 5281.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5455,
        store_longitude: -74.16,
        total_orders: 383.01,
        neighborhood: 'Eltingville',
        keywords:
            'Lobster, Sushi, Chinese, Delicious, Fried Rice, Turkish, Asian, Polished, Spacious, Classy',
        annual_dol: 392.73,
        description:
            'Everyday Chinese chow & dim sum in spacious surroundings with a seating capacity of 800.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5847,
        store_longitude: -73.8169,
        total_orders: 274.16,
        neighborhood: 'Far Rockaway/Rockaway Beach',
        keywords:
            'Beer, Wine, Hamburger, Irish, Iconic, Awesome, Classic, Comfortable, Great, Fresh',
        annual_dol: 497.02,
        description:
            "\"Iconic Irish Pub in Rockaway Beach serving the BEST Piña Coladas in the universe. For the past 80 years, Connolly's has been more than a bar, it's a community. It's a place where friends and family have gathered to make memories and cherish the history and culture of our beloved beach town. We welcome ALL to come enjoy a drink with us and get a sense of why Rockaway Beach is the greatest place on earth.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.735,
        store_longitude: -73.9999,
        total_orders: 433.87,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Warm, Rich, Cozy, Fun, Italian, Sweet, Vibrant, French Toast, Classy, Homemade',
        annual_dol: 709.02,
        description:
            '"A neighborhood restaurant in the West Village of Manhattan by Chef Christian Rowan"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.713,
        store_longitude: -73.958,
        total_orders: 3285.36,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Casual, Great',
        annual_dol: 3355.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7125,
        store_longitude: -73.9489,
        total_orders: 740.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Vegetarian, Gluten Free, Delicious, Lebanese, Inviting, Friendly, Mediterranean, Fun, Moroccan',
        annual_dol: 1810.77,
        description:
            'Bright Middle Eastern restaurant featuring classic sharing plates, mains & desserts, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7276,
        store_longitude: -73.9851,
        total_orders: 4785.16,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Lobster, Lobster Roll, Steak, Wine, Beer, Scotch, Margarita, Warm, Trendy, Cozy',
        annual_dol: 4886.7,
        description:
            'By the Wayland team, this tile-floored space offers a raw bar, sandwiches & seasonal cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.9839,
        total_orders: 990.4,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Beer, Tomato Soup, Salad, Bourbon, Trendy, Rustic, Fun, American, Awesome',
        annual_dol: 1279.38,
        description:
            'This cozy, rustic watering hole features creative takes on pub grub, craft beer on tap & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5883,
        store_longitude: -73.6599,
        total_orders: 314.71,
        neighborhood: '',
        keywords:
            'Margarita, Sushi, Mexican, Beer, Coffee, Cuban, Tasty, Italian, American, Sangria',
        annual_dol: 395.57,
        description:
            '"The best in Mexican Cuisine & exotic drinks. With a name like Margarita\'s Cafe, you have to have great margaritas and we do. We offer live Mariachi bands several days a week. Besides our great lunch and dinner menus, we offer in house catering for special events or just because. From the traditional to new Mexican inspired dishes, Margarita\'s Cafe is the place to bring family, friends and acquaintances."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.9813,
        total_orders: 39.04,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Wine, Beer, Coffee, Trendy, Delicious, Lively, American, Cool, Great, Creative',
        annual_dol: 159.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9273,
        total_orders: 638.43,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Whiskey, Beer, Irish, Fun, Lively, Friendly, Classy, American, Perfect, Excellent',
        annual_dol: 695.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.711,
        store_longitude: -73.9677,
        total_orders: 63.28,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Tequila, Tacos, Mexican, Margarita, Premium, Intimate, Friendly, Authentic, Classic, Lively',
        annual_dol: 258.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7484,
        store_longitude: -73.9392,
        total_orders: 116.82,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Steak, Salad, Nachos, Beer, Peruvian, Chinese, American, Seasonal, Irish',
        annual_dol: 477.5,
        description:
            '"A seasonal, market driven restaurant by Chef Dan Kluger of Loring Place with menu inspired by American diners and simple comfort food opening in the heart of Long Island City, Winter 2020"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6847,
        store_longitude: -74.0026,
        total_orders: 178.84,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Pizza, Italian, Wine, Dessert, Delicious, Homemade, Cozy, Modern, Comfortable, Seasonal',
        annual_dol: 213.98,
        description:
            '"A Modern Italian Eatery, a neighborhood friendly comfortable atmosphere. Delicious food, craft cocktails, craft beer, and great wine list! Brick oven cooking and homemade pizzas and pastas."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.642,
        store_longitude: -73.933,
        total_orders: 74.12,
        neighborhood: 'Little Caribbean/East Flatbush',
        keywords:
            'Dessert, Delicious, Shrimp Cocktail, Karaoke, Stylish, Steak, Salad, Caribbean, Vivid, Quaint',
        annual_dol: 117.27,
        description:
            'Relaxed locale serving Caribbean-inspired New American cuisine, plus brunch & late-night drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5887,
        store_longitude: -74.0952,
        total_orders: 749.25,
        neighborhood: 'Dongan Hills',
        keywords: 'Pizza, Beer, Wine, Delicious, Perfect, Classic, Great, Casual',
        annual_dol: 765.15,
        description:
            'Family-run neighborhood pub with photos on the wall serving a range of pizza & brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8011,
        store_longitude: -73.9676,
        total_orders: 1071.25,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Cuban, Delicious, Rum, Spanish, Spacious, Fun, Colorful, Perfect, Fresh, American',
        annual_dol: 1522.59,
        description:
            'Nuevo Latino food, including a festive brunch, served in a colorful space in the Excelsior Hotel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8864,
        store_longitude: -73.9099,
        total_orders: 812.84,
        neighborhood: 'Riverdale/West Bronx',
        keywords:
            'Tacos, Steak, Mexican, Salad, Shrimp Cocktail, Savory, Fajitas, Enchiladas, Spanish, Latin',
        annual_dol: 848.08,
        description:
            'Narrow, relaxed restaurant offering homestyle Mexican dishes, along with margaritas & imported beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7537,
        store_longitude: -73.9784,
        total_orders: 164.23,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Upscale, Hotel, Traditional, Classic, Great, Modern, Casual',
        annual_dol: 503.16,
        description:
            'Swanky cocktail bar at the base of an office tower serving craft libations plus elevated bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9668,
        total_orders: 411.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Spicy, Delicious, Chinese, Upscale, Savory, Asian, Fun, Sweet, Amazing, Authentic',
        annual_dol: 428.81,
        description:
            'Bright, white-tablecloth Sichuan restaurant offering a wide-ranging menu & a small bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7598,
        store_longitude: -73.976,
        total_orders: 1449.5,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Dessert, Glamorous, Elegant, Sushi, Delicious, Shrimp Cocktail, French, Romantic, Coffee, Hotel',
        annual_dol: 1512.35,
        description:
            'Longtime restaurant serving time-honored French delicacies in a flower-arrangement-filled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.691,
        store_longitude: -73.9051,
        total_orders: 77.26,
        neighborhood: 'Bushwick',
        keywords:
            'Beer, Karaoke, Margarita, Funky, Fancy, Seasonal, Friendly, Urban, Awesome, Classic',
        annual_dol: 315.82,
        description: '"Tiny cocktail space for community and creativity."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6284,
        store_longitude: -74.0289,
        total_orders: 569.42,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Whiskey, Wine, Irish, Classic, American, Friendly, Great, Casual',
        annual_dol: 581.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6172,
        store_longitude: -74.0308,
        total_orders: 705.03,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Sushi, Vegetarian, Wine, Japanese, Spicy, Trendy, French, Opulent, Impeccable, Classy',
        annual_dol: 741.19,
        description:
            'Buzzing eatery & lounge offering Japanese dishes with a French twist, plus cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9704,
        total_orders: 241.2,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hamburger, Upscale, Comfortable, American, Friendly, Casual, Local, Great',
        annual_dol: 985.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7572,
        store_longitude: -73.9679,
        total_orders: 2550.96,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Sushi, Japanese, Delicious, French, Cozy, Homemade, Authentic, Shabu-Shabu',
        annual_dol: 2781.92,
        description:
            "This minimalist Japanese eatery features chef's tasting menus, hot pot, sushi & seasonal entrees.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7836,
        store_longitude: -73.9507,
        total_orders: 833.65,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Hamburger, Salad, Cajun, Gluten Free, Beer, Shrimp Scampi, Delicious, Mediterranean, Irish',
        annual_dol: 1021.18,
        description:
            '"O’Flanagan’s Bar and Grill is the Upper East Side’s friendliest neighborhood pub serving a wide variety of fantastic food! With a full service menu, we offer traditional Irish fare, including Sheperd’s Pie and an Irish Breakfast, as well as our famous Flanny’s Wings. We also serve American favorites including a Bacon Bleu Burger, New York Sirloin, and a Bottomless Brunch Menu on weekends! Let us host your special event, private party, family gathering, dinner party, or corporate event in our accommodating event space, always available for rentals."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6933,
        store_longitude: -73.9705,
        total_orders: 411.98,
        neighborhood: 'Fort Greene/Clinton Hill',
        keywords:
            'Wine, Italian, Flavorful, Chinese, Charming, Modern, Natural, Perfect, Casual, Traditional',
        annual_dol: 458.37,
        description:
            'Modern Italian tavern with a charming patio serving traditional handmade pastas, cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6901,
        store_longitude: -73.9826,
        total_orders: 1291.97,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Chinese, Tasty, Beer, Spicy, Stylish, Spacious, Authentic, Dan Dan, Casual, Great',
        annual_dol: 1347.99,
        description:
            'Spicy Sichuan dishes like dan dan noodles are heat-rated by number at this informal restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6907,
        store_longitude: -73.9956,
        total_orders: 54.6,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Sushi, Wine, Romantic, Crisp, Turkish, Outstanding, Wonderful, Great, Fine, Heritage',
        annual_dol: 223.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9816,
        total_orders: 1084.69,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Peruvian, Wine, Japanese, Upscale, Spicy, Sashimi, Elegant, Sleek',
        annual_dol: 1182.18,
        description:
            'Upscale setting for Nikkei cuisine – Japanese-Peruvian offerings including ceviche & sushi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5144,
        store_longitude: -74.2359,
        total_orders: 42.22,
        neighborhood: 'Tottenville',
        keywords: 'Dessert, Awesome, Friendly, Great, Fusion, Casual, Local, Unique',
        annual_dol: 56.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7101,
        store_longitude: -74.014,
        total_orders: 4230.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Vegetarian, Pizza, Cajun, Italian, Roast Beef, Irish, French Onion Soup, French',
        annual_dol: 5202.51,
        description:
            'Both locals & visitors come for cold pints plus burgers, wings & bar food in a neighborhood spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7255,
        store_longitude: -73.9926,
        total_orders: 4932.62,
        neighborhood: 'NoHo',
        keywords:
            'Pizza, Wine, Rich, Beer, Delicious, Margarita, Italian, Elegant, Spectacular, Eclectic',
        annual_dol: 5037.28,
        description:
            'This dimly-lit bar with a basement dance floor specializes in craft beer and wines by the glass.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9947,
        total_orders: 229.93,
        neighborhood: 'Nolita',
        keywords:
            'Coffee, Trendy, Warm, Refined, Friendly, American, Bright, Australian, Urban, Casual',
        annual_dol: 866.31,
        description:
            'Health-focused & progressive all-day dining room in a trendy space designed by Tali Roth Designs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5868,
        store_longitude: -73.7141,
        total_orders: 117.62,
        neighborhood: '',
        keywords: 'Wine, Beer, Fun, Clean, Organic, Terrific',
        annual_dol: 159.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -73.9921,
        total_orders: 1796.62,
        neighborhood: 'NoHo',
        keywords: 'Wine, Beer, Hotel, Cozy, Modern, American, Casual, Awesome, Fine, Diverse',
        annual_dol: 1834.74,
        description:
            "Joe's Pub, one of the six performance spaces within The Public Theater, is a music venue and restaurant that hosts live performances across genres and arts, ranging from cabaret to modern dance to world music. It is located at 425 Lafayette Street near Astor Place in Manhattan, New York City.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -73.9859,
        total_orders: 5054.79,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Whiskey, Beer, Salad, Karaoke, Breakfast Burrito, Hotel, Irish, Vibrant, Triple Sec',
        annual_dol: 6035.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7375,
        store_longitude: -73.8775,
        total_orders: 108.15,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Beer, Wine, Friendly, French, Caribbean, Casual, American, Great',
        annual_dol: 132.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5885,
        store_longitude: -74.09,
        total_orders: 265.73,
        neighborhood: 'Dongan Hills',
        keywords:
            'Steak, Vegetarian, Mexican, Tequila, Margarita, Tasty, Italian, American, Delicious, Casual',
        annual_dol: 715.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.583,
        store_longitude: -74.0965,
        total_orders: 210.75,
        neighborhood: 'Midland Beach',
        keywords:
            'Garlic Bread, Cheesecake, Shrimp Cocktail, Italian, Delicious, Upscale, Casual, Bright, Traditional, Local',
        annual_dol: 219.89,
        description:
            'Big & bright upscale restaurant with a full bar dishing up traditional Italian cooking.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.8278,
        total_orders: 16952.07,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Burger, Steak, Tasty, Comfortable, Fun, Fancy, Cool, Casual, American',
        annual_dol: 24773.72,
        description:
            'Comfortable restaurant & bar specializing in oysters & cocktails, plus burgers with fries.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7511,
        store_longitude: -73.9727,
        total_orders: 1398.59,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Gluten Free, Beer, Japanese, Ramen, Warm, Asian, Gracious, Prosecco, Casual',
        annual_dol: 1474.35,
        description:
            'Kyoto-style handmade soba & yakitori, with seats at the bar to watch the pros work.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9768,
        total_orders: 5529.14,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hotel, Upscale, Stylish, Elegant, Coffee, Vintage, Classic, Homey, Rooftop, Contemporary',
        annual_dol: 19410.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8274,
        store_longitude: -73.9501,
        total_orders: 270.87,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Vegan, Beer, Bourbon, Roast Beef, Seasonal, American, Irish, Healthy, Handcrafted, Casual',
        annual_dol: 1140.05,
        description:
            '"A farm-to-bar neighborhood corner featuring a seasonal menu of handcrafted cocktails and a curated selection of local craft beer."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9684,
        total_orders: 369.22,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Garlic Bread, Lobster, Italian, Wine, Beer, Upscale, Homemade, Homey, Excellent, Great',
        annual_dol: 624.57,
        description:
            'Large portions of Italian fish & pasta dishes in a homey space with patio dining.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6874,
        store_longitude: -73.9061,
        total_orders: 63.68,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Cozy, Casual, Cool, Creative',
        annual_dol: 163.81,
        description:
            'Pub grub, cocktails & more served at this straightforward venue with live music & a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6534,
        store_longitude: -73.6262,
        total_orders: 987.66,
        neighborhood: '',
        keywords: 'Italian, Pizza, Salad, Wine, Delicious, Awesome, Local, Fine',
        annual_dol: 1014.5,
        description:
            'No-nonsense but popular Italian trattoria serving up a large menu of family-style platters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7427,
        store_longitude: -73.992,
        total_orders: 1082.21,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Burger, Steak, Salad, Wine, Beer, Spicy, Nachos, Bbq, Irish, Sweet',
        annual_dol: 4277.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7405,
        store_longitude: -73.9908,
        total_orders: 344.64,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Mexican, Delicious, Greek, Mediterranean, Hotel, Rustic, Amazing, Local, Great',
        annual_dol: 1408.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6896,
        store_longitude: -73.9695,
        total_orders: 714.43,
        neighborhood: 'Clinton Hill',
        keywords:
            'Wine, Beer, Italian, Organic, Mediterranean, American, Sustainable, Quaint, Homey, Classy',
        annual_dol: 778.61,
        description:
            'Hip, vintage-chic neighborhood spot preparing locally sourced New American small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9941,
        total_orders: 234.52,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Beer, Whiskey, Cozy, Friendly, Perfect, Tex-Mex, Awesome, American, Great, Casual',
        annual_dol: 382.41,
        description:
            'This casual joint specializes in a rotating list of American draft microbrews plus hearty pub fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8467,
        store_longitude: -73.636,
        total_orders: 206.08,
        neighborhood: '',
        keywords:
            'Vegan, Burger, Dairy Free, Mexican, Whiskey, Beer, Pizza, Vodka, Gluten Free, Coffee',
        annual_dol: 210.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9862,
        total_orders: 2151.42,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Burger, Beer, Korean, Wine, Karaoke, Fried Chicken, Juicy, Vintage, Ramen, Fried Rice',
        annual_dol: 2359.28,
        description:
            'Korean fried chicken, sides & beer towers in a buzzy space with a DJ spinning vintage vinyl.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7687,
        store_longitude: -73.9555,
        total_orders: 31.8,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Gluten Free, Wine, Dessert, Chinese, Beer, Spicy, Elegant, Comfortable, Asian',
        annual_dol: 53.86,
        description:
            'Cantonese joint whose dim sum provides a Chinatown-style dining experience on the Upper East Side.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7253,
        store_longitude: -73.9515,
        total_orders: 10.91,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Hamburger, Kebab, Salad, Italian, Beer, Margarita, Hungarian, Polish, Delicious',
        annual_dol: 44.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6451,
        store_longitude: -73.7811,
        total_orders: 2985.61,
        neighborhood: 'Jamaica',
        keywords:
            'American, Wine, Czech, Hotel, Spacious, Caribbean, Premium, Global, Modern, Comfortable',
        annual_dol: 3048.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5296,
        store_longitude: -74.2303,
        total_orders: 176,
        neighborhood: 'Charleston',
        keywords:
            'Pizza, Italian, Salad, Meatball, Delicious, Homemade, Fresh, Casual, Local, Great',
        annual_dol: 179.74,
        description:
            '"Named after their grandmother, Millie’s is the brainchild of two brothers seeking to share their passion for Italian food and celebration of the Italian culture with their local community. Millie’s of Staten Island will be a neighborhood destination joining the original Morristown, NJ location. Millie’s of Staten Island’s features include a custom, 22-seat bar and three garage doors that open to 2,500 square feet of outdoor dining space. Millie’s serves delicious, coal- and Neapolitan wood-fired artisan pizzas and meatballs based on Vince and Brandon’s grandmother Millie’s recipes. The coal oven burns over 650+ degrees and can produce pies in five minutes. The Neapolitan wood oven, hand built by Italy’s celebrated Ferrara family,"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9219,
        total_orders: 605.41,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Salad, Shrimp Cocktail, Greek, Greek Salad, Latin, Fusion, Casual, Great',
        annual_dol: 618.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -73.9881,
        total_orders: 2063.04,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Beer, Irish, Ale, Turkey Sandwich, Swiss, Friendly, Colorful, Great, Perfect',
        annual_dol: 2114.77,
        description:
            'Low-key Irish watering hole featuring billiards, darts & a simple outdoor seating area.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7345,
        store_longitude: -73.9937,
        total_orders: 1566.73,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Lobster, Salad, Shrimp Cocktail, Vodka, Wine, Margarita, Beer, Gin, Caesar Salad',
        annual_dol: 3652.45,
        description:
            'Swanky steakhouse with a raw bar boasting walls covered with photos of old-Hollywood stars.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -73.9878,
        total_orders: 313.27,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Salad, Wine, Vodka, Italian, French, Impeccable, American, Warm, Contemporary',
        annual_dol: 1277.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7286,
        store_longitude: -73.9923,
        total_orders: 102.83,
        neighborhood: 'NoHo',
        keywords:
            'Sushi, Salad, Korean, Delicious, Japanese, American, Authentic, Casual, Classic, Fantastic',
        annual_dol: 420.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7348,
        store_longitude: -73.9859,
        total_orders: 10.89,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Wine, Kebab, Georgian, Dessert, Tacos, Beer, Tasty, Seasonal, Glamorous, Creamy',
        annual_dol: 44.51,
        description:
            'Casual eatery with a locally sourced menu of seasonal Georgian dishes, plus wine & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8782,
        store_longitude: -73.9033,
        total_orders: 976.45,
        neighborhood: 'Kingsbridge Heights/West Bronx',
        keywords:
            'Burger, Beer, Martini, Cuban, Delicious, Cozy, Healthy, Friendly, Homemade, Dope',
        annual_dol: 1018.79,
        description:
            'Exposed beams & graffiti-covered walls give this bar with a modern American menu a cool edge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.8347,
        total_orders: 1530.58,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Wine, Hotel, Dessert, Chinese, Delicious, Beer, Asian, Bbq, Clean, Ample',
        annual_dol: 1846.37,
        description:
            '"纽约首家中式斗酒馆，主打中式调酒，6-28度，水果味、花朵酒、牛奶酒. 总有一款适合你."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.891,
        store_longitude: -73.8503,
        total_orders: 24.32,
        neighborhood: 'East Bronx/Wakefield',
        keywords: 'Caribbean, Casual, Great',
        annual_dol: 99.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8178,
        store_longitude: -73.9422,
        total_orders: 292.97,
        neighborhood: "Harlem/Strivers' Row",
        keywords:
            'Cosmopolitan, Elegant, Beer, Fun, Perfect, Friendly, Karaoke, Chic, Classic, Great',
        annual_dol: 359.47,
        description:
            'Chic nightspot offering specialty cocktails, wine, beer & snacks in modern surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7149,
        store_longitude: -74.0068,
        total_orders: 59.93,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Pizza, Italian, Wine, Salad, Vodka, Martini, Tasty, Delicious, Old Fashioned, Bruschetta',
        annual_dol: 66.68,
        description:
            'Simple Italian spot with a bar up front & a menu of pastas, wood-fired pizzas & other classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6333,
        store_longitude: -74.0269,
        total_orders: 108.23,
        neighborhood: 'Bay Ridge',
        keywords: 'Mexican, Relaxed, Charming, Asian, Latin, Casual, Premium, Modern, Sweet, Local',
        annual_dol: 132.47,
        description:
            'Relaxed standby with a charming patio for classic & modern Mexican dishes, plus original cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7598,
        store_longitude: -73.9838,
        total_orders: 3689.3,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Steak, Hotel, Vodka, Upscale, Beer, Salad, Coffee, Australian, Vintage, Rooftop',
        annual_dol: 16054.53,
        description:
            'walk away.Polished rooms and suites feature TVs, Wi-Fi and espresso machines, plus en suite bathrooms with rainfall showers. Some have balconies, free-standing tubs and/or separate living areas.Amenities include a steakhouse, a restaurant/bar and a rooftop cocktail lounge, plus a coffee kiosk, a gift shop, and meeting and event space. Breakfast, loaner guitars, and fitness and yoga classes are available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7808,
        store_longitude: -73.9494,
        total_orders: 1236.55,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Coffee, Wine, Beer, Tasty, Funky, Cozy, Friendly, Classy, American, Sweet',
        annual_dol: 1647.35,
        description:
            "Funky hangout that offers cappuccinos by day, craft beers by night & also has fondue & s'mores.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6387,
        store_longitude: -73.6423,
        total_orders: 720.88,
        neighborhood: '',
        keywords: 'Healthy, Perfect, Natural',
        annual_dol: 740.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7577,
        store_longitude: -73.9823,
        total_orders: 1317.76,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegetarian, Chinese, Indian, Upscale, Casual, Traditional, Spacious, Fancy, Authentic, Creative',
        annual_dol: 1453.49,
        description:
            'Spacious Indian eatery drawing theater-goers with fixed-priced pre-theater deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9837,
        total_orders: 501.01,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Beer, Whiskey, Friendly, Iconic, Excellent, Casual, Signature, Chill',
        annual_dol: 2073.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6813,
        store_longitude: -73.7114,
        total_orders: 13.97,
        neighborhood: '',
        keywords:
            'Burger, Steak, Cheesesteak, Roast Beef, Beer, Bbq, Wine, American, Caribbean, Classic',
        annual_dol: 57.1,
        description:
            'Lively neighborhood sports bar with pub grub, darts & football memorabilia on the walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -74.0047,
        total_orders: 1517.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Incredible, Rooftop, Modern, Perfect, Unique',
        annual_dol: 1550.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7694,
        store_longitude: -73.983,
        total_orders: 268.34,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Hamburger, Pizza, Vegetarian, Italian, Gluten Free, Meatball, Wine, Polish, Elegant, Comfortable',
        annual_dol: 1096.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.9975,
        total_orders: 143.44,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Vegan, Burger, Vegetarian, Lobster, Lobster Roll, Wine, Gluten Free, Coffee, Beer, Vietnamese',
        annual_dol: 418.82,
        description:
            'Cozy cafe with quirky decor offering French-influenced Vietnamese fare, plus cocktails & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9096,
        total_orders: 978.17,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Sushi, Wine, Tasty, Japanese, Thai, Fusion, Sweet, Asian, Fabulous, Chic',
        annual_dol: 1025.7,
        description:
            'A chic, modern eatery serving inventive sushi & Japanese dishes, plus lunch specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7762,
        store_longitude: -73.9152,
        total_orders: 162.31,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'Wine, Beer, Cool, Casual, American, Great',
        annual_dol: 806.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8044,
        store_longitude: -73.9476,
        total_orders: 134.91,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Pizza, Wine, Vegan, Salad, Vodka, Coffee, Italian, Delicious, Meatball, Warm',
        annual_dol: 300.87,
        description:
            'Stylish, relaxed Italian neighborhood cafe in Lower Harlem known for its desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7316,
        store_longitude: -73.6115,
        total_orders: 2767.15,
        neighborhood: '',
        keywords:
            'Hurricane, Steak, Chicken Wings, Salad, Burger, Coffee, Caribbean, Beer, American, Spicy',
        annual_dol: 4962.73,
        description:
            'Tropical-themed chain offering spiced-to-order wings with an array of sauces, pub grub & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -74.0059,
        total_orders: 2619.7,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Steak, Burger, Salad, American, Beer, Delicious, Hotel, French, Perfect, Clean',
        annual_dol: 2675.28,
        description:
            'Elevated American comfort food is served at this eatery, bar, boutique lounge & beer garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7299,
        store_longitude: -74.0103,
        total_orders: 4486.53,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Delicious, Fabulous, Memorable, Iconic, Awesome, Incredible, Fantastic, Premium, Wonderful, Great',
        annual_dol: 18339.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8392,
        store_longitude: -73.8593,
        total_orders: 430.99,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Cajun, Lobster, Flavorful, Lobster Bisque, French, Pinacolada, Fun, Authentic, Casual',
        annual_dol: 618.62,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6296,
        store_longitude: -74.1108,
        total_orders: 1737,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Nachos, Bbq, Turkish, Irish, French, Delicious, American, Homemade, Friendly',
        annual_dol: 1773.85,
        description:
            'Snug, old-fashioned pub known for simple pub grub, especially half-pound burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8384,
        store_longitude: -73.9413,
        total_orders: 207.7,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Steak, Burger, Mexican, Salad, Beer, Bbq, Cuban, Wine, Fried Rice, American',
        annual_dol: 537.95,
        description: '"Restaurant and Lounge"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7875,
        store_longitude: -73.9764,
        total_orders: 2058.79,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegetarian, Salad, Margarita, Vodka, Martini, Delicious, Spicy, Coffee, Funky, Romantic',
        annual_dol: 2075.7,
        description:
            'Bi-level eatery with Greek Island vibes, exposed beam wood ceilings, & seafood-forward menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8297,
        store_longitude: -73.7022,
        total_orders: 11.39,
        neighborhood: '',
        keywords: 'Shrimp Cocktail, Chinese, French, Premium, Great',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7022,
        store_longitude: -73.6608,
        total_orders: 46.83,
        neighborhood: '',
        keywords: 'Coffee, Wine, Italian, Beer, Gourmet, American, Mediterranean, Smooth, Casual',
        annual_dol: 191.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9171,
        total_orders: 68.04,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Kebab, Beer, Classic, Authentic, Spectacular, Fusion, Fantastic, Casual',
        annual_dol: 278.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6747,
        store_longitude: -73.9757,
        total_orders: 199.72,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hotdog, Pizza, Steak, Cheesesteak, Beer, Swiss, American, Sustainable, Indian',
        annual_dol: 391.56,
        description: '"Neighborhood Bar with a Pro-Wrestling Theme"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9538,
        total_orders: 707.86,
        neighborhood: 'Greenpoint',
        keywords: 'Wine, Beer, Gourmet, Stylish, Italian, Spacious, French, Lively, Fun, Robust',
        annual_dol: 2168.63,
        description:
            'Airy, stylish locale filled with plants, offering brunch fare, dinner plates & a robust wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7326,
        store_longitude: -73.9548,
        total_orders: 901.48,
        neighborhood: 'Greenpoint',
        keywords:
            'Sushi, Wine, Beer, Delicious, Yummy, Japanese, Sashimi, Friendly, Healthy, Asian',
        annual_dol: 929.55,
        description:
            'Understated Japanese fixture dishing up sushi amid dark wood furnishings & Japan-inspired flair.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6803,
        store_longitude: -73.9946,
        total_orders: 11727.27,
        neighborhood: 'Carroll Gardens',
        keywords: 'Whiskey, Wine, Beer, Vintage, Martini, Italian, Tasty, Classic, Friendly, Yummy',
        annual_dol: 11976.1,
        description:
            'Former Italian social club transformed into a speakeasylike bar serving classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6834,
        store_longitude: -73.9999,
        total_orders: 68.35,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Hotdog, Burger, Tequila, Mexican, Beer, Coffee, Margarita, Wine, Tex-Mex, Breakfast Burrito',
        annual_dol: 79.85,
        description:
            'Mexican dishes, bistro classics & cocktails served in a chill hangout with sidewalk tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8514,
        store_longitude: -73.9395,
        total_orders: 568.48,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Garlic Bread, Wine, Pizza, Italian, Rustic, Mediterranean, Casual, Southern, Classy, Spanish',
        annual_dol: 583.04,
        description:
            'Little hideaway for Southern Italian specialties & diverse wines served amid a warm, intimate vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -74.093,
        total_orders: 194.56,
        neighborhood: 'Midland Beach',
        keywords: 'Sushi, Chinese, Japanese, Spicy, Asian, Bbq, Yummy, Wonton Soup, Fusion, Local',
        annual_dol: 228.32,
        description:
            'Enduring, old-world fixture for fine Chinese & Japanese dining, plus a full bar & specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -73.9867,
        total_orders: 1821.42,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Margarita, Martini, Upscale, Spicy, Rustic, Cozy, Vintage, American',
        annual_dol: 1930.45,
        description:
            'Happening spot for local craft beer, wine, cocktails & a retro, old-timey atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5911,
        store_longitude: -73.9739,
        total_orders: 231.96,
        neighborhood: 'Gravesend',
        keywords:
            'Burger, Hamburger, Salad, Delicious, Swiss, Beer, Friendly, Casual, Classic, Unique',
        annual_dol: 296.59,
        description:
            'Pool hall & sports bar offering leagues, beer buckets & a casual menu of sandwiches & sides.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9729,
        total_orders: 308.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Wine, Upscale, Coffee, Beer, Trendy, Burger, Martini, Gin, Stylish',
        annual_dol: 1081.73,
        description:
            'The polished, contemporary studios feature flat-screen TVs and free Wi-Fi, plus minibars, wet bars and kitchenettes. Upgraded suites add separate living and sleeping areas, as well as terraces with city views.Free continental breakfast is served in a stylish lounge, along with light snacks throughout the day. Other amenities include an American-style bistro and a 24-hour fitness center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6792,
        store_longitude: -73.8956,
        total_orders: 556.93,
        neighborhood: 'Cypress Hills',
        keywords:
            'Delicious, Dominican, Rum, Caribbean, Sweet, Fried Calamari, Perfect, Latin, American, Fresh',
        annual_dol: 1604.13,
        description:
            'Upmarket Latin American cuisine served in a chill environment with flat-screen TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7604,
        store_longitude: -73.9654,
        total_orders: 770.55,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Rich, Beer, Italian, Elegant, Vintage, Outstanding, Great, Terrific, Intimate',
        annual_dol: 2705.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6326,
        store_longitude: -74.0271,
        total_orders: 605.85,
        neighborhood: 'Bay Ridge',
        keywords:
            'Vegetarian, Pizza, Italian, Tasty, Delicious, Cozy, Czech, Comfortable, Homemade, Friendly',
        annual_dol: 622.61,
        description:
            "Neighborhood Italian restaurant that's been serving up classic pastas & pizzas since 1953.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.9893,
        total_orders: 3443.86,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Sushi, Flavorful, Tasty, Spicy, Meatball, Japanese, Sashimi, Spanish, Friendly, Sweet',
        annual_dol: 4277.97,
        description:
            'Cool, low-key joint for Japanese fare such as sashimi, tempura & skewers, plus a long sake list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8598,
        store_longitude: -73.8429,
        total_orders: 218.63,
        neighborhood: 'Pelham Gardens/East Bronx',
        keywords:
            'Salad, Steak, Cajun, Italian, Wine, Martini, Beer, Bourbon, Mediterranean, Rustic',
        annual_dol: 893.7,
        description:
            '"Nestled in the beautiful Pelham Gardens area of the Bronx along Eastchester Road sits our uniquely designed restaurant Misto Restaurant and Bar. Misto, meaning “Mixed” in Italian, is a little slice of old Europe infused with a New York City vibe and feel. We invite you to come and enjoy an unforgettable evening of spectacular food, wine, and music. Enjoy a Bourbon or Martini at our chic, sexy bar and settle into our inviting and rustic dining area to indulge in a mixture of the finest of culinary delights. Executive Chef Michael Mazzei created a “Mixed,” Mediterranean-focused, fusion menu to match the feel of the restaurant."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9861,
        total_orders: 1464.92,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Italian, Salad, Coffee, Wine, Champagne, Prosecco, Classy, Southern, Casual, Authentic',
        annual_dol: 1506.67,
        description:
            'Hearty Southern Italian food served at a pre-theater-appropriate pace in an Old World setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.9872,
        total_orders: 1728.94,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Legendary, American, Fabulous, Vibrant, Premium, Rooftop, Latin, Cool, Local',
        annual_dol: 6069.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.996,
        total_orders: 488.26,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotel, Stylish, High-End, Spacious, Coffee, Premium, Rooftop, Amazing, French, Modern',
        annual_dol: 556.1,
        description:
            'Modern Art.Sleek rooms and suites with high ceilings have free Wi-Fi, oversized windows and minibars; some offer soaking tubs. Suites add separate living areas and floor-to-ceiling windows. A penthouse suite provides a dining area and a rooftop terrace. Room service is available.Amenities include a stylish restaurant featuring a locally sourced, seasonal menu, a buzzy rooftop lounge/bar, a spa and a fitness room. Meeting spaces are also available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9878,
        total_orders: 797.42,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Italian, Hotel, Southern, Intimate, Fun, Perfect, Great, American, Unique',
        annual_dol: 888.54,
        description:
            'The New Amsterdam Theatre is a Broadway theater on 214 West 42nd Street, at the southern end of Times Square, in the Theater District of Manhattan in New York City. One of the oldest surviving Broadway venues, the New Amsterdam was built from 1902 to 1903 to designs by Herts & Tallant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7245,
        store_longitude: -73.9953,
        total_orders: 499.96,
        neighborhood: 'Nolita',
        keywords: 'Italian, Dessert, Rich, Turkey Sandwich, American, Playful, Iconic, Casual',
        annual_dol: 521.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.8708,
        total_orders: 48.76,
        neighborhood: 'North Corona/Jackson Heights/Flushing',
        keywords: 'Mexican, Tacos, Casual, Delicious, Friendly, Premium, Authentic, Fresh, Classic',
        annual_dol: 54.33,
        description:
            '"Established in 1997, Puebla Seafood is a Mexican family owned business which first started as a small Bodega providing Mexican products for our neighbors in Jackson Heights,Queens. Puebla Seafood later turned its establishment into a restaurant to provide authentic and classic Mexican and Seafood cuisines to the public. We serve daily food specials. Give us a call and check out our offerings 718-672-3556 Puebla Seafood is where you will be best served and be a apart of the Mexican culture."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7498,
        store_longitude: -73.995,
        total_orders: 66.54,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Wine, Beer, Spicy, French, Irish, Nachos, Sweet, Friendly, Lively',
        annual_dol: 108.75,
        description:
            'Casual, dimly lit bar & hookah lounge offering special cocktails, bar bites & European fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -74.0039,
        total_orders: 83.38,
        neighborhood: 'Midtown Manhattan/Hudson Yards',
        keywords: 'Hotel, Legendary, Fancy, Fresh, Signature, Local',
        annual_dol: 340.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9876,
        total_orders: 21.65,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Pizza, Italian, Wine, Dessert, Beer, Delicious, Mediterranean, Friendly, Fabulous, Exceptional',
        annual_dol: 88.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7714,
        store_longitude: -73.9837,
        total_orders: 3661.62,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Tomato Soup, Salad, Wine, Shrimp Cocktail, Italian, American, Warm, Contemporary, Perfect, Spectacular',
        annual_dol: 3820.38,
        description:
            'Pre-curtain & intermission service with fine dining meals & a full bar under the chandelier.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7797,
        store_longitude: -73.9769,
        total_orders: 1336.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Vegetarian, Wine, Salad, Chicken Lollipop, Luxurious, Delicious, American, French, Spacious',
        annual_dol: 1663.34,
        description:
            'A modern American menu with Mediterranean influences is served in a sprawling, wood-accented space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7057,
        store_longitude: -74.0076,
        total_orders: 1702.43,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tequila, Burger, Vodka, Beer, Cajun, Margarita, Martini, Salad, Austrian, Irish',
        annual_dol: 1753.44,
        description:
            'Classic, homey drinking establishment with a 101-year-old interior bar imported from Ireland.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8675,
        store_longitude: -73.6306,
        total_orders: 855.54,
        neighborhood: '',
        keywords: 'Tequila, Mexican, Steak, Tasty, Pizza, Tex-Mex, Salad, Latin, American, Bright',
        annual_dol: 1103.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7456,
        store_longitude: -73.9059,
        total_orders: 466.19,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, Delicious, Warm, Ale, Coffee, Fun, Friendly, Amazing, Clean, Irish',
        annual_dol: 510.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.8529,
        total_orders: 101.72,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Lasagna, Wine, Tequila, Tequila Sunrise, Delicious, Vodka, Steak, Pizza, Italian, Whiskey',
        annual_dol: 142.5,
        description:
            'Homestyle Italian eats supplied in a cozy, local spot with French doors that open onto the street.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7202,
        store_longitude: -73.9987,
        total_orders: 1310.04,
        neighborhood: 'Little Italy',
        keywords:
            'Karaoke, Korean, Beer, Chinese, Quaint, Amazing, Intimate, Great, Perfect, Excellent',
        annual_dol: 1366.83,
        description:
            'Tiny karaoke bar & art gallery featuring a long song list & periodic acoustic-guitar accompaniment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7159,
        store_longitude: -73.9965,
        total_orders: 17549.16,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Sushi, Japanese, Asian, Local, Intimate, Sleek, Rare, Seasonal, Authentic, Cultural',
        annual_dol: 33856.79,
        description:
            "Intimate, wood-accented venue for curated omakase sushi meals served at a chef's counter.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.5525,
        total_orders: 1378.19,
        neighborhood: '',
        keywords: 'Sushi, Salad, Wine, Beer, Spicy, Chinese, Korean, Asian, Sweet, Seasonal',
        annual_dol: 4838.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7196,
        store_longitude: -73.9977,
        total_orders: 1824.82,
        neighborhood: 'Little Italy',
        keywords: 'Burger, Steak, Salad, Wine, Beer, Italian, Delicious, Elegant, Irish, American',
        annual_dol: 1867.29,
        description:
            'New American dishes, oysters & cocktails offered in an intimate, tavern-style atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7542,
        store_longitude: -73.9771,
        total_orders: 171.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Carrot Cake, Wine, Beer, Italian, French, Moroccan, Elegant, American, Classic, Casual',
        annual_dol: 764.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5671,
        store_longitude: -74.1129,
        total_orders: 11.82,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords: 'Mexican, Burger, Pizza, Spanish, Salad, Casual',
        annual_dol: 82.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7831,
        store_longitude: -73.9749,
        total_orders: 854.5,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Garlic Bread, Steak, Pizza, Vegan, Salad, Chicago-Style, Golfclub, Beer, Dessert',
        annual_dol: 891.55,
        description:
            'Family-friendly chain serving Chicago-style pizza along with pasta, steaks & burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.787,
        store_longitude: -73.978,
        total_orders: 320.59,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Steak, Kebab, Sushi, Vegan, Salad, Gluten Free, Beer, Whiskey Sour',
        annual_dol: 327.39,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6411,
        store_longitude: -74.0147,
        total_orders: 285.41,
        neighborhood: 'Sunset Park',
        keywords:
            'Vegetarian, Steak, Mexican, Flavorful, Margarita, Bbq, Delicious, Healthy, Enchiladas, Friendly',
        annual_dol: 1075.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6186,
        store_longitude: -73.9324,
        total_orders: 8.97,
        neighborhood: 'Marine Park/Flatlands',
        keywords:
            'Mexican, Steak, American, Contemporary, Modern, Casual, Local, Delicious, Friendly, Fresh',
        annual_dol: 36.66,
        description: '"Mexican restaurant & bar located in south Brooklyn."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7928,
        store_longitude: -73.9407,
        total_orders: 24.49,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Tacos, Mexican, Steak, Salad, Tasty, Nachos, Wine, Quesadillas, Spicy, Spanish',
        annual_dol: 92.28,
        description: '"Las Panteras Negras offers traditional Mexican food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8065,
        store_longitude: -73.9263,
        total_orders: 81.22,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Beer, Burger, Steak, Canadian, Vegan, Ale, Swiss, American, Cuban, Local',
        annual_dol: 110.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7078,
        store_longitude: -73.8693,
        total_orders: 254.84,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords: 'Pizza, Salad, Italian, Beer, Wine, Hawaiian, Spicy, Tex-Mex, Delicious, Bbq',
        annual_dol: 306.89,
        description:
            'Chain eatery serving up inventive pizzas, plus pastas & salads in a modern setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9656,
        total_orders: 955.67,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegan, Steak, Organic, French, Quaint, Elegant, Warm, Friendly, Farm-To-Table, Casual',
        annual_dol: 975.95,
        description:
            'Elegant townhouse restaurant by Jean-Georges Vongerichten with a seasonal French menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7733,
        store_longitude: -73.9645,
        total_orders: 521.82,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Salad, Dessert, Wine, Elegant, Meatball, Warm, Sophisticated, Modern, Perfect',
        annual_dol: 532.89,
        description:
            'Sophisticated Italian cuisine is served in an elegant modern setting perfect for people-watching.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7708,
        store_longitude: -73.954,
        total_orders: 968.97,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Irish, Bourbon, Cozy, Fancy, Urban, Dope, Local, Cool, Great, Casual',
        annual_dol: 1921.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8238,
        store_longitude: -73.9132,
        total_orders: 589.66,
        neighborhood: 'Melrose',
        keywords: 'Pizza, Italian, Vegetarian, Wine, Hotel, Cozy, Casual, Local, Great',
        annual_dol: 780.63,
        description:
            'Cozy spot resembling an old port tavern offering Italian fare, local draft beers & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.778,
        store_longitude: -73.9559,
        total_orders: 48.53,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Meatball, Italian, Coffee, Homey, Cozy, Warm, American, Quaint, Comfortable, Mediterranean',
        annual_dol: 52.01,
        description:
            'Laid-back venture turning out comfort food classics in a warm, brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7951,
        store_longitude: -73.6986,
        total_orders: 7.25,
        neighborhood: '',
        keywords:
            'Steak, Salad, Lebanese, Mediterranean, Spanish, Greek, Israeli, Ribeye Steak, Casual, Authentic',
        annual_dol: 29.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -73.9972,
        total_orders: 5.36,
        neighborhood: 'Little Italy',
        keywords: 'Wine, Pizza, Italian, Martini, Beer, Salad, Gin, Bellini, Awesome, Mimosa',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 1338.92,
        neighborhood: '',
        keywords: 'Steak, Burger, Rustic, Beer, Italian, American, Warm, Casual, Classic, Inviting',
        annual_dol: 1396.97,
        description:
            'American dishes with local, seasonal ingredients prepared in farmhouse-chic digs at Roosevelt Field.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -74.0053,
        total_orders: 445.79,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Mexican, Tequila, Beer, Salad, Margarita, Italian, American, Fajitas, Latin',
        annual_dol: 475.42,
        description:
            'Branch of a casual, hip mini-chain for classic Mexican eats & margaritas, plus happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.786,
        store_longitude: -73.9764,
        total_orders: 2034.31,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Peruvian, Shrimp Cocktail, Wine, Dessert, Flavorful, Chinese, Cuban, Spanish, Fried Rice',
        annual_dol: 2077.48,
        description:
            '"For over 30 years Flor de Mayo Restaurant has served our wonderful Upper West Side neighbors and the surrounding area our delightful combination of Peruvian and Chinese cuisine. As one of the few remaining Chino-Latino restaurants in NYC, Flor de Mayo provides selections crafted by our chef\'s skillful touch, seasoned with unique herbs and spices from both cultures. Accompany our flavorful dishes with fascinating signature cocktails from our fully-stocked bar and enjoy our sophisticated service and cozy atmosphere. Please come join us at Flor de Mayo and experience this wonder for yourself!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -73.9033,
        total_orders: 124.49,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Pizza, Italian, Mongolian, Coffee, Comfortable, Premium, Irresistible',
        annual_dol: 174.4,
        description:
            'Long-standing eatery featuring hearty plates of classic Italian cuisine in a comfortable atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7015,
        store_longitude: -73.9052,
        total_orders: 279.6,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Cozy, Delicious, Friendly, Intimate, Awesome, Casual, Great, Seasonal, Local, Fresh',
        annual_dol: 912.84,
        description:
            'Live music, DJs, craft beer, cocktails & pop-up dining in a neighborhood bar with a laid-back vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6435,
        store_longitude: -73.5395,
        total_orders: 190.32,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Lobster Tail, Salad, Italian, Delicious, Thai, American, Contemporary',
        annual_dol: 257.71,
        description:
            'Contemporary white-tablecloth restaurant & bar offering classic seafood & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.9204,
        total_orders: 469.6,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Sushi, Salad, Japanese, Chinese, Asian, Rooftop, Delicious, American, Modern, Fusion',
        annual_dol: 561.86,
        description:
            'Specialty sushi, Asian fusion fare & drinks served in modern digs with a rooftop patio & city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.983,
        total_orders: 6007.45,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Wine, Upscale, Luxurious, French, Legendary, Classy, Premium, Refined, Classic, Exceptional',
        annual_dol: 7696.73,
        description:
            "Elite French restaurant offers chef Eric Ripert's refined seafood, expert service & luxurious decor.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8142,
        store_longitude: -73.9598,
        total_orders: 45.88,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Pizza, Italian, Vegetarian, Wine, Coffee, Champagne, Meatball, Prosecco, Warm, Cozy',
        annual_dol: 187.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.809,
        store_longitude: -73.9593,
        total_orders: 103.76,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Kebab, Vegetarian, Shrimp Cocktail, Mediterranean, Greek, Delicious, Rustic, Upscale, Warm',
        annual_dol: 231.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9885,
        total_orders: 2645.02,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Wine, Beer, Hotel, Cozy, Rum, Intimate, Legendary, French, Classic',
        annual_dol: 2947.25,
        description:
            'Intimate venue presenting singer-songwriters & new artists for free or a nominal charge, plus a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.715,
        store_longitude: -73.9912,
        total_orders: 2799.27,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Burger, Coffee, Beer, Vodka, Upscale, Elegant, Banana Split, American, French, Retro',
        annual_dol: 2920.64,
        description:
            'State-of-the-art cinema with an upscale American eatery & two bars evokes old-time Hollywood haunts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7137,
        store_longitude: -73.996,
        total_orders: 966.66,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Wine, Chinese, Spicy, Peking Duck, Rich, Homemade, Juicy, Upscale, Yummy, Legendary',
        annual_dol: 1823.33,
        description:
            "A legendary Chinese eatery from the '80s is revived in a three-story space with decorous rooms.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.866,
        store_longitude: -73.9276,
        total_orders: 163.83,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Tacos, Sushi, Vegetarian, Pizza, Mexican, Gourmet, Margarita, Tasty, Tex-Mex, Italian',
        annual_dol: 365.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8539,
        store_longitude: -73.9306,
        total_orders: 1469.99,
        neighborhood: 'Fort George/Washington Heights',
        keywords: 'Vodka, Wine, Italian, Pizza, Martini, Mexican, Peruvian, Rum, Delicious, Latin',
        annual_dol: 1602.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8648,
        store_longitude: -73.9283,
        total_orders: 10.79,
        neighborhood: 'Fort George',
        keywords:
            'Peruvian, Margarita, Delicious, Cozy, Yummy, Sweet, Relaxed, Friendly, Laid-Back, Latin',
        annual_dol: 44.12,
        description:
            '"Latin American Cuisine with an emphasis on Peruvian Cuisine. Retazitos also features an elevated Cocktail Program in a relaxed laid back setting."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6325,
        store_longitude: -73.5816,
        total_orders: 56.83,
        neighborhood: '',
        keywords: 'Salad, Pizza, Shrimp Cocktail, Chinese, American, Casual, Local, Classic',
        annual_dol: 232.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6796,
        store_longitude: -73.8862,
        total_orders: 3.31,
        neighborhood: 'Cypress Hills',
        keywords: 'Elegant, Casual',
        annual_dol: 13.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6779,
        store_longitude: -73.9797,
        total_orders: 101.62,
        neighborhood: 'Park Slope',
        keywords:
            'Wine, Tacos, Vegan, French Toast, Beer, Vietnamese, Coffee, French, Upscale, Dessert',
        annual_dol: 165.7,
        description:
            'Casual, low-key restaurant with updated Vietnamese fare plus an ambitious drink menu & a back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.9508,
        total_orders: 994.36,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Burger, Pizza, Beer, Wine, Tasty, Coffee, Margarita, Irish, Spicy, Karaoke',
        annual_dol: 1015.46,
        description:
            '"Local neighborhood Irish pub in Williamsburg, Brooklyn on Grand Street off the corner of Union Avenue. Comfortable and cozy setting serving cocktails, wine, craft beer and comfort food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.73,
        store_longitude: -73.982,
        total_orders: 1558.36,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Vodka, Beer, Martini, Gin, Spicy, Hungarian, Legendary, Cozy, Classy',
        annual_dol: 1625.92,
        description:
            'This small East Village watering hole is best known for its fireplace & signature Rolo shots.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7139,
        store_longitude: -73.9526,
        total_orders: 2608.73,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Wine, Delicious, Spacious, Fun, Lively, Cool, Great, Aesthetic, Fresh',
        annual_dol: 2721.83,
        description:
            'Hard-rocking joint with jukebox tunes, old-school signs, big beers, pinball & outdoor grill grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.714,
        store_longitude: -73.9604,
        total_orders: 194.77,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Pizza, Salad, Wine, Italian, Beer, Savory, Delicious, Romantic, Relaxed, American',
        annual_dol: 248.13,
        description:
            'Notable handmade pastas, mozzarella & wood-oven pizzas are offered in a relaxed space with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.9507,
        total_orders: 105.38,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Tequila, Steak, Shrimp Cocktail, Pizza, Chinese, Spanish, Pinacolada, American, Latin, Asian',
        annual_dol: 287.49,
        description:
            'Spartan corner diner fixing up typical Chinese dishes & Spanish bites, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7108,
        store_longitude: -73.9514,
        total_orders: 568.7,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Hamburger, Mexican, Pizza, Beer, Ale, American, Dominican, French, Latin, Upscale',
        annual_dol: 716.03,
        description:
            'Cocktails, draft beer & pub grub in a laid-back hangout with large windows & exposed-brick walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6309,
        store_longitude: -74.1016,
        total_orders: 472.36,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Burger, Steak, Sushi, Chicken Parmigiana, Savory, Irish, Delicious, French Onion Soup, French, American',
        annual_dol: 498.02,
        description:
            'Relaxed, time-tested haunt for burgers, seafood & steak dishes, plus a bar with TVs showing sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9839,
        total_orders: 114.64,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tequila, Tacos, Mexican, Beer, Margarita, Flavorful, Vegan, Tasty, Dessert, Hotel',
        annual_dol: 145.98,
        description:
            'Contemporary Mexican hot spot turning out innovative tacos & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5756,
        store_longitude: -74.1695,
        total_orders: 368.94,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Vegetarian, Chinese, Italian, French, Asian, American, Exotic, Classic, Fine, Great',
        annual_dol: 420.84,
        description:
            'Chinese-American chow & Polynesian fare served with exotic Tiki cocktails in a shopping center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7114,
        store_longitude: -73.951,
        total_orders: 7.58,
        neighborhood: 'Williamsburg',
        keywords:
            'Mexican, Steak, Beer, Shrimp Cocktail, Margarita, Wine, Dessert, Spicy, French, American',
        annual_dol: 31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7486,
        store_longitude: -73.9779,
        total_orders: 820.77,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Burger, Steak, Hamburger, Salad, Mexican, Bbq, Wine, Organic, French, American',
        annual_dol: 3355.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -73.9661,
        total_orders: 255.81,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Ale, Juicy, Spacious, Sleek, Sweet, Creative, Iconic, Great, Classic',
        annual_dol: 1128.55,
        description:
            '"Other Half Domino Park, on the waterfront and across from the park in Williamsburg, Brooklyn, is our pilot brewery and taproom. Here, you\'ll find our brewers making small batch and experimental beers while sampling 15+ draft lines and even more cans from our other breweries in Brooklyn, DC and the Finger Lakes. We have indoor and outdoor seating, plus signature cocktails, a full bar, and non-alcoholic options."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.9771,
        total_orders: 664.55,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Chinese, Spicy, Asian, Traditional, Authentic, Casual, Excellent, Incredible, Dim Sum',
        annual_dol: 836.72,
        description:
            "A Shanghainese chef's place for soup dumplings & traditional plates in a brick-walled setting.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.9297,
        total_orders: 37.88,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Tequila, Beer, Spanish, Dope, Latin, Sweet, Casual',
        annual_dol: 44.25,
        description: '"Sports bar with a billiards table"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7482,
        store_longitude: -73.9398,
        total_orders: 687.53,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Wine, Sushi, Beer, Hotel, Spanish, Trendy, Dutch, Relaxed, Contemporary, American',
        annual_dol: 736.02,
        description:
            "Relaxed rooms provide free Wi-Fi, flat-screen TVs and minifridges, as well as desks with ergonomic chairs. Suites add separate living areas, and terraces with city views.Light meals are served in a trendy cocktail bar that features regular live acoustic performances. There's also a gym and a snack shop (both open 24/7).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6749,
        store_longitude: -74.016,
        total_orders: 1165.93,
        neighborhood: 'Red Hook',
        keywords:
            'Beer, Bbq, Rustic, American, Korean, Southern, Classic, Local, Casual, Authentic',
        annual_dol: 1506.12,
        description:
            'Rustic counter-service choice featuring slow smoked meats, craft beer & live music on weekends',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7265,
        store_longitude: -73.9915,
        total_orders: 1390.76,
        neighborhood: 'East Village/Bowery',
        keywords: 'Tasty, Funky, Lively, Fun, Colorful, Fancy, Superb, Friendly, Great, Intimate',
        annual_dol: 1732.14,
        description:
            '"Enjoy Bowery\'s most colorful outdoor covered patio seating for Happy Hour food & drinks Tuesday - Friday from 5:30 to 7:30. Or book a dinner reservation for a more elevated food and drink experience inside for an intimate seating arrangement inside our plush neon disco vibes. But perhaps what Short Stories is arguably most known for, is being the best kept secret for celebrating any occasion on a Friday and Saturday as we transition into a bumping late night jaunt starting at 11pm. Light up fountains of large format bottle service cocktails flow with good vibes, and even better memories as everyone bops along to your favorite song. Long story short, you\'re always going to remember the good times you had a Short Stories."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9872,
        total_orders: 968.96,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Whiskey, Beer, Wine, Bourbon, Salad, Meatball, Avocado Toast, Ale, Spicy',
        annual_dol: 1058.91,
        description:
            'Bar focusing on whiskeys (region by region), along with barrel-aged cocktails & late-night bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -73.9866,
        total_orders: 3520.89,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Salad, Greek, Caesar Salad, Club Sandwich, Cozy, American, Perfect, Traditional, Tradition',
        annual_dol: 3606.83,
        description:
            'The Players is a private social club founded in New York City by the noted 19th-century Shakespearean actor Edwin Booth. In 1888, Booth purchased an 1847 mansion at 16 Gramercy Park, reserved an upper floor for his residence, and turned the rest into a clubhouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7347,
        store_longitude: -73.988,
        total_orders: 1881.1,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Burger, Beer, Wine, Ale, Upscale, Warm, Cozy, French, Friendly, American',
        annual_dol: 1962.66,
        description:
            'This old-timey tavern with a brick & wood interior has upscale bar bites, draft brews & a cool vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8322,
        store_longitude: -73.8649,
        total_orders: 689.25,
        neighborhood: 'East Bronx/Soundview',
        keywords:
            'Steak, Tequila, Sushi, Salad, Spanish, Gluten Free, American, Puerto Rican, Latin, Modern',
        annual_dol: 726.39,
        description:
            'Spanish-style dining on steaks & Latin dishes in a space with live jazz some nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7384,
        store_longitude: -73.9912,
        total_orders: 449.86,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tequila, Mexican, Tacos, Beer, Margarita, Wine, Salad, Upscale, Organic, Delicious',
        annual_dol: 1466.22,
        description:
            'Modern Mexican chain eatery known for tableside guacamole, creative margaritas & chic surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7303,
        store_longitude: -73.8638,
        total_orders: 397.21,
        neighborhood: 'Rego Park/Flushing',
        keywords: 'Lobster, Sushi, Salad, Japanese, Spicy, Beer, Sashimi, Asian, Fusion, Wonderful',
        annual_dol: 505.99,
        description:
            'Special rolls & lunch bento boxes are served in a dark setting with a room for private events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8014,
        store_longitude: -73.9652,
        total_orders: 177.69,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Thai, Gluten Free, Vegan, Delicious, Spicy, Green Curry, Crisp, Friendly, Authentic, Exotic',
        annual_dol: 191.01,
        description:
            'Thai staples & more exotic specials in a Bangkok street market-themed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5235,
        store_longitude: -74.2159,
        total_orders: 62.37,
        neighborhood: "Prince's Bay",
        keywords: 'Champagne, Beer, Steak, Tasty, Delicious, Bbq, Awesome, Great, Global, Chill',
        annual_dol: 254.95,
        description:
            '"Live sports with surround sound, live bands and DJs, karaoke, freshly prepared food, and the same great service for over a decade at Hot Shotz Sports Bar!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8734,
        store_longitude: -73.867,
        total_orders: 64.21,
        neighborhood: 'East Bronx/Williamsbridge/Olinville',
        keywords: 'Karaoke, Beer, Friendly, Cool, Great, Casual',
        annual_dol: 241.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8829,
        store_longitude: -73.9017,
        total_orders: 39.26,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Lobster, Sushi, Salad, Cajun, Spicy, Delicious, Crab Rangoon, Thai, Ramen, Fried Shrimp',
        annual_dol: 160.47,
        description:
            '"Baby Crab serves delicious seafood boils, burgers and rolls. Bringing the freshest seafood straight from the sea to NYC! 🦀🙏"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8257,
        store_longitude: -73.9434,
        total_orders: 102.76,
        neighborhood: 'Sugar Hill/Harlem',
        keywords: 'Hamburger, Beer, Chinese, Wine, Asian, Classy, Local, Casual, Great, Dim Sum',
        annual_dol: 420.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9699,
        total_orders: 617.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Lobster, Lobster Roll, Savory, Spicy, Delicious, Warm, Japanese, Beer, Sashimi',
        annual_dol: 644.24,
        description:
            'Casual sushi house with a lofty feel offering Japanese mains & premium rolls along with beer & sake.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.9685,
        total_orders: 1781.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Whiskey, Kebab, Beer, Wine, Bourbon, Delicious, Hotel, American, Authentic, Perfect',
        annual_dol: 6266.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -73.7458,
        total_orders: 39.15,
        neighborhood: 'Oakland Gardens/Bayside',
        keywords: 'Steak, Hamburger, Pizza, Beer, Wine, Irish, Bbq, French, Delicious, Hotel',
        annual_dol: 96.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6677,
        store_longitude: -73.7076,
        total_orders: 3.32,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Tacos, Salad, Coffee, Beer, Casual, Classic',
        annual_dol: 13.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.703,
        store_longitude: -73.9945,
        total_orders: 2135.4,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Lobster, Lasagna, Lobster Tail, Salad, Dessert, Wine, Gourmet, Delicious, Elegant, Warm',
        annual_dol: 2227.99,
        description:
            'Landmark eatery, newly renovated, offering a New American menu & stunning views of Manhattan.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7424,
        store_longitude: -73.992,
        total_orders: 4505.46,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Steak, Burger, Lobster, Hamburger, Tasty, Mexican, Salad, Spicy, Wine, Juicy',
        annual_dol: 4748.04,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6946,
        store_longitude: -73.994,
        total_orders: 297.56,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Vegetarian, Steak, Pizza, Italian, Shrimp Cocktail, Coffee, Delicious, Cozy, Relaxed, Homemade',
        annual_dol: 747.53,
        description:
            'One of a group of relaxed Italian eateries, this place lets diners mix and match pastas and sauces.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7412,
        store_longitude: -73.9923,
        total_orders: 166.67,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Elegant, Organic, Exquisite, Great, Casual, Fine, Quirky, Heritage, Signature',
        annual_dol: 681.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6844,
        store_longitude: -73.9918,
        total_orders: 1057.77,
        neighborhood: 'Carroll Gardens/Brooklyn Heights',
        keywords: 'Mexican, Tacos, Steak, Nachos, Tex-Mex, American, Rich, Latin, Authentic, Local',
        annual_dol: 1328.45,
        description:
            'Tacos in housemade tortillas & other Mexican bites are offered in a colorful, art-accented space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6621,
        store_longitude: -73.951,
        total_orders: 759.73,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords: 'Wine, Beer, French, Classy, American, Awesome, Great, Fine, Casual, Signature',
        annual_dol: 799.68,
        description:
            'Wine, draft beer & cocktails, plus sloppy Joes in an old-fashioned haunt offering live jazz music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7469,
        store_longitude: -73.8893,
        total_orders: 87.57,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords:
            'Salad, Steak, Ecuadorian, Coffee, Spanish, Latin, American, Fresh, Casual, Excellent',
        annual_dol: 104.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7517,
        store_longitude: -73.9906,
        total_orders: 287.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Steak, Burger, Salad, Wine, Vodka, Beer, Coffee, Irish, Delicious, American',
        annual_dol: 438.82,
        description:
            'Modest hangout offering draft beer & TV sports, plus pub food & Irish & American entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.9884,
        total_orders: 248,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vodka, Chill, Awesome, Casual, Friendly, Terrific',
        annual_dol: 1013.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8103,
        store_longitude: -73.9499,
        total_orders: 1500.15,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Stylish, Hotel, Vibrant, Cajun, Friendly, Coffee, Fantastic, Great, Hip',
        annual_dol: 1566.71,
        description:
            'Hip rooms have free Wi-Fi, 42-inch flat-screen TVs, coffeemakers and minifridges, plus bathrooms with walk-in showers. Kids stay at no extra cost with an adult.Amenities include a trendy, 24-hour self-serve cafe and bar serving Cajun fare, as well as a 24/7 fitness room, and a courtyard with seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7714,
        store_longitude: -73.7347,
        total_orders: 21.11,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Cajun, Salad, Chicken Caesar Salad, Italian, Bourbon, Spicy, American, Caesar Salad, Fresh, Outstanding',
        annual_dol: 24.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.9682,
        total_orders: 522.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Coffee, Upscale',
        annual_dol: 574.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9825,
        total_orders: 119.91,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Wine, Premium, Warm, Relaxed, Organic, Perfect, Great, Modern, Fine, Casual',
        annual_dol: 490.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7263,
        store_longitude: -73.7907,
        total_orders: 742,
        neighborhood: 'Fresh Meadows/Utopia/Flushing',
        keywords: 'Steak, Sushi, Salad, Japanese, Spicy, Healthy, Modern, Thai, Fresh, Asian',
        annual_dol: 790.57,
        description:
            'Chic, modern venue featuring creative sushi, classic Japanese entrees & meat grilled tableside.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7456,
        store_longitude: -74.0022,
        total_orders: 650.57,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Vegan, Mexican, Tasty, Wine, Margarita, Delicious, Creamy, Enchiladas, Spacious',
        annual_dol: 709.02,
        description:
            'Elevated Mexican cuisine in a festive dining room, plus tequila-based cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7387,
        store_longitude: -73.9938,
        total_orders: 379.13,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Italian, Salad, Wine, Delicious, Rich, Martini, Margarita, Classy, Fun, Modern',
        annual_dol: 422.14,
        description:
            'Pasta made with roe or sea urchin meets Caesar salads & grilled items at this high-ceilinged eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7392,
        store_longitude: -74.0015,
        total_orders: 299.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Vodka, Tequila, Salad, Martini, Margarita, Italian, Gin, Mediterranean, Sweet',
        annual_dol: 1223.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7712,
        store_longitude: -73.7349,
        total_orders: 2741.2,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Italian, Delicious, Spicy, Wine, Fun, Amazing, Fresh, Traditional, Rooftop, Rare',
        annual_dol: 2825.02,
        description:
            'Local mainstay for traditional Italian cooking in an old-fashioned setting with a rooftop garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6863,
        store_longitude: -73.9678,
        total_orders: 1073,
        neighborhood: 'Clinton Hill',
        keywords:
            'Lasagna, Burger, Mexican, Beer, Gluten Free, Italian, Wine, Rustic, Warm, Quaint',
        annual_dol: 1095.76,
        description:
            'Quaint neighborhood Italian trattoria, serving locally sourced dishes in intimate, rustic environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9287,
        total_orders: 465.86,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Beer, Burger, Whiskey, Ale, Coffee, Spicy, Salad, Buffalo Chicken Salad, French, Awesome',
        annual_dol: 556.49,
        description:
            'Relaxed taproom hosting weekend DJs & serving numerous whiskeys, jumbo cocktails & pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.74,
        store_longitude: -73.9791,
        total_orders: 128.88,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Vegetarian, Pizza, Salad, Italian, Meatball, Wine, Beer, Dessert, Homemade',
        annual_dol: 166.19,
        description:
            'Vibrant kitchen doling out brick-oven pizzas & Italian standards such as pasta & veal marsala.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6455,
        store_longitude: -73.9127,
        total_orders: 3763.38,
        neighborhood: 'Canarsie',
        keywords:
            'Lobster, Pizza, Salad, Champagne, Delicious, Whiskey, Jamaican, Irish, Caribbean, Fun',
        annual_dol: 8088.64,
        description:
            '"ALWAYS A GREAT TIME AT FLAVA II\'S FOOD / COCKTAILS / LATE NIGHT / FUN Schedule a special event with us! Memorable experience. Birthday Parties / Engagements / Personal Life Events / Graduations / Charity Events / Late Night / Bachelorette Parties / Bachelor Parties / Girls night out / Outdoor & indoor dining available."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7842,
        store_longitude: -73.9778,
        total_orders: 5465.43,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Whiskey, Beer, Coffee, Delicious, Japanese, Gin, Ale, Gracious',
        annual_dol: 5988.76,
        description:
            'Unassuming tavern featuring Scottish whiskeys & traditional bites such as steak & kidney pie.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8692,
        store_longitude: -73.8254,
        total_orders: 2791.03,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Burger, Cornbread, Steak, Shrimp Cocktail, Bbq, Mimosa, Tasty, Margarita, French, Friendly',
        annual_dol: 3109.95,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8341,
        store_longitude: -73.8533,
        total_orders: 138.01,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Tasty, Hamburger, Delicious, Elegant, Wine, Mimosa, Friendly, Superb, Spanish, American',
        annual_dol: 169.34,
        description:
            'Neighborhood lounge featuring DJs, hookah pipes & cocktails, plus a menu of American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6988,
        store_longitude: -73.6521,
        total_orders: 33.32,
        neighborhood: '',
        keywords:
            'Pizza, Vegetarian, Italian, Shrimp Cocktail, Flavorful, Shrimp Scampi, Rich, Casual, Classic, Excellent',
        annual_dol: 136.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8547,
        store_longitude: -73.8885,
        total_orders: 1681.1,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Pizza, Italian, Wine, Warm, Crisp, Outstanding, Impressive, Fresh, Authentic, Cool',
        annual_dol: 1686.26,
        description:
            'Traditional Italian food & drink served in a warm setting with an old-world vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.73,
        store_longitude: -73.9551,
        total_orders: 90.96,
        neighborhood: 'Greenpoint',
        keywords:
            'Kebab, Polish, Beer, Ale, Delicious, Rustic, Authentic, Traditional, Fresh, Casual',
        annual_dol: 126.55,
        description:
            'Waitresses in traditional dress serve Polish standards at this eatery with a rustic farmhouse feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7666,
        store_longitude: -73.9185,
        total_orders: 219.1,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Lobster, Lobster Bisque, Margarita, Wine, Beer, Bourbon, Rum, Warm, Cozy, Creole',
        annual_dol: 508.07,
        description:
            'Cozy seafood grill & raw oyster bar specializing in updated classic dishes, cocktails & craft ales.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.766,
        store_longitude: -73.9152,
        total_orders: 1558.21,
        neighborhood: 'Astoria/LIC',
        keywords: 'Irish, Fun, Tasty, Hip, Great, Traditional, Casual',
        annual_dol: 1558.21,
        description:
            'Corner Irish bar with a traditional feel featuring draft beers, sports on TV, darts & trivia nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7684,
        store_longitude: -73.6914,
        total_orders: 2012.09,
        neighborhood: '',
        keywords: 'Golfclub, Luxurious, Picturesque, Terrific, Fresh, Diverse',
        annual_dol: 2240.33,
        description:
            'North Hills Country Club is a country club which was relocated in the 1960s to North Hills, New York, United States. The club was founded in 1927 at the current location of Douglaston Park. The golf course in North Hills was designed by Robert Trent Jones in 1961.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7221,
        store_longitude: -74.0035,
        total_orders: 1510.12,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Italian, Delicious, Authentic, Perfect, Homemade, Iconic, Casual, Southern, Local',
        annual_dol: 6691.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7161,
        store_longitude: -73.9995,
        total_orders: 472.62,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Sushi, Vegetarian, Japanese, Wine, Beer, Karaoke, Trendy, Spicy, Sashimi, Bbq',
        annual_dol: 502.95,
        description:
            'Trendy spot serving Hokkaido-style dishes, sushi & tabletop BBQ in a loungey atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5717,
        store_longitude: -74.1117,
        total_orders: 172405.79,
        neighborhood: 'New Dorp',
        keywords: 'Swiss, Whiskey, Superb, Great, Casual, Unique',
        annual_dol: 323961.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5815,
        store_longitude: -74.1114,
        total_orders: 506.5,
        neighborhood: 'Todt Hill/Midland Beach',
        keywords:
            'Salad, Vegetarian, Sushi, Chilean, Organic, French, Japanese, Trendy, Sashimi, Asian',
        annual_dol: 528.46,
        description:
            'Buzzing eatery & lounge offering Japanese dishes with a French twist, plus cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7041,
        store_longitude: -74.0104,
        total_orders: 3857.52,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Cornbread, Tasty, Beer, Bourbon, Wine, Dessert, Delicious, Bbq, American, Cozy',
        annual_dol: 4229.74,
        description:
            'Lively spot with exposed brick & outdoor seating, pairing eclectic BBQ with cocktails & craft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.5607,
        total_orders: 678.95,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Exquisite, Perfect, Friendly, Lively, Great, Traditional, Local, Fine',
        annual_dol: 701.07,
        description:
            'Inviting, down-home trattoria serving up traditional Italian cuisine for more than 30 years.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.5868,
        total_orders: 3278.93,
        neighborhood: '',
        keywords:
            'Burger, Steak, Vegetarian, Cheesecake, Cajun, Salad, Whiskey, Wine, Beer, Gluten Free',
        annual_dol: 3372.88,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -74.0063,
        total_orders: 2762.58,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Whiskey, Beer, Wine, Distilled, American, Savory, Cozy, Delicious, Crisp, Spacious',
        annual_dol: 3657.29,
        description:
            'Hip gastropub with an open kitchen offering creative pub fare & cocktails in a landmark building.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7196,
        store_longitude: -73.995,
        total_orders: 2243.48,
        neighborhood: 'Little Italy',
        keywords:
            'Beer, Mexican, Steak, Coffee, Green Curry, Mimosa, Bbq, French Toast, Rustic, American',
        annual_dol: 2969.6,
        description:
            'Seasonal & reserve brews go with gastropub dinner & weekend brunch in hip, rustic-chic surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7255,
        store_longitude: -74.0052,
        total_orders: 1096.17,
        neighborhood: 'Hudson Square',
        keywords:
            'Tequila, Vodka, Gin, Hotel, Stylish, American, Warm, Japanese, Grand Marnier, Contemporary',
        annual_dol: 2824.66,
        description:
            'Sophisticated restaurant specializing in seafood dishes amid contemporary surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7418,
        store_longitude: -73.5935,
        total_orders: 7957.63,
        neighborhood: '',
        keywords:
            'Salad, Tequila, Cheesecake, Cajun, Chicken Caesar Salad, Mexican, Wine, Beer, Nachos, Tex-Mex',
        annual_dol: 8126.48,
        description:
            'Family-friendly chain serving classic Tex-Mex & American fare in a Southwestern-style setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7267,
        store_longitude: -74.0058,
        total_orders: 818.61,
        neighborhood: 'Hudson Square',
        keywords: 'Burger, Steak, Pizza, Tequila, Salad, Italian, Spicy, Coffee, Wine, Hawaiian',
        annual_dol: 3346.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7771,
        store_longitude: -73.9536,
        total_orders: 2910.1,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Lively, Fun, Intimate, Awesome, Friendly, Great, Excellent, Casual',
        annual_dol: 2921.44,
        description:
            'A piano player accompanies lively staffers & patrons belting out show tunes & other favorite songs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7722,
        store_longitude: -73.9556,
        total_orders: 2460.44,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Italian, Meatball, Wine, Delicious, Warm, Rustic, Romantic, Relaxed, Lively, Great',
        annual_dol: 2529.93,
        description:
            'This cozy, rustic spot with a patio & back garden draws lively crowds for small plates & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7715,
        store_longitude: -73.9562,
        total_orders: 671.5,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Spanish, Wine, Seafood Paella, Seasonal, Contemporary, Delicious, Sangria, Intimate, Lively',
        annual_dol: 747.11,
        description:
            'Creative Barcelona-style tapas made with market-fresh ingredients in a warm, modern space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9129,
        total_orders: 7.45,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords:
            'Burger, Vegetarian, Garlic Bread, Cajun, Mexican, Coffee, Roast Beef, Margarita, Bbq, French',
        annual_dol: 30.46,
        description:
            'Easygoing corner diner serving breakfast-to-dinner comfort food, including vegetarian options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7022,
        store_longitude: -73.9196,
        total_orders: 1276.89,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Vegetarian, Beer, Vodka, Italian, Local, Mai Tai, Awesome',
        annual_dol: 1463.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6563,
        store_longitude: -73.6625,
        total_orders: 690.83,
        neighborhood: '',
        keywords:
            'Wine, Vodka, Steak, Italian, Beer, French, Premium, Innovative, Traditional, Outstanding',
        annual_dol: 714.81,
        description:
            'White-tablecloth restaurant with simple decor serving Italian plates with a modern twist.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7349,
        store_longitude: -74.0063,
        total_orders: 838.41,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Hamburger, Coffee, Beer, Salad, Wine, Organic, American, French, Cozy',
        annual_dol: 978.03,
        description:
            'Cozy spot with patio dining featuring farm-to-table, seasonal cuisine with global influences.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7346,
        store_longitude: -74.0017,
        total_orders: 3180.73,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Hotdog, Delicious, Juicy, Warm, Friendly, French, Fun, Hip, Casual',
        annual_dol: 3263.79,
        description:
            "Known as the city's oldest gay bar, this standby also serves burgers to its mature male regulars.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.734,
        store_longitude: -74.0014,
        total_orders: 1333.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Lobster Roll, Burger, Shrimp Cocktail, Martini, Gin, French, Homey, Cozy, Croissant Sandwich',
        annual_dol: 1362.04,
        description:
            'This homey American eatery is notable for its oyster bar, creative Caesar salads & Bloody Marys.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7375,
        store_longitude: -74.0051,
        total_orders: 363.48,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Flavorful, Tasty, Wine, Coffee, Delicious, Beer, Healthy, Ethiopian, Spacious',
        annual_dol: 1485.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7326,
        store_longitude: -74.0017,
        total_orders: 1058.13,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Beer, Vodka, Tasty, Chicken Caesar Salad, Wine, Salad, Ale, Delicious, French Toast',
        annual_dol: 1104.01,
        description:
            'Underground bar serving up wings, burgers & more, along with drink specials & sports on the TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7391,
        store_longitude: -74.0056,
        total_orders: 21.31,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Tasty, Coffee, French Toast, Cozy, Avocado Toast, French, Friendly, Australian, Phenomenal',
        annual_dol: 87.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6374,
        store_longitude: -73.7453,
        total_orders: 316.51,
        neighborhood: 'Jamaica/Rosedale',
        keywords: 'Burger, Steak, Salad, Beer, Bbq, American, Caesar Salad, Warm, Relaxed, Casual',
        annual_dol: 330.24,
        description:
            'Relaxed bayside tavern with outdoor seats offering American fare including many seafood offerings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7054,
        store_longitude: -73.9327,
        total_orders: 1710.54,
        neighborhood: 'East Williamsburg',
        keywords: 'Vegan, Beer, Champagne, Coffee, Fruity, Ale, Czech, Karaoke, Spicy, American',
        annual_dol: 1812.9,
        description:
            'Bar & music venue in a former casket factory, with vegan-friendly snacks, cocktails & draft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6979,
        store_longitude: -73.928,
        total_orders: 748.64,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Tasty, Trendy, Classy, Latin, Authentic, Fresh, Great, Excellent',
        annual_dol: 797.84,
        description:
            'Dark, trendy nightspot with a tropical accent pouring craft cocktails to fuel the DJ dance party.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7049,
        store_longitude: -73.9278,
        total_orders: 784.83,
        neighborhood: 'East Williamsburg',
        keywords:
            'Vegan, Vegetarian, Wine, Coffee, Beer, Ethiopian, Friendly, African, Casual, Classy',
        annual_dol: 837,
        description:
            'Bustling outpost provides Ethiopian vegan fare, cocktails & regular events in a low-lit dining room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7342,
        store_longitude: -74.0064,
        total_orders: 33419.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Salad, Japanese, Spicy, Wine, Funky, Stylish, Whimsical, American, Retro',
        annual_dol: 38536.71,
        description:
            'Cool, bi-level bar serving izakaya fare & traditional American bites, plus whimsical cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.736,
        store_longitude: -74.0061,
        total_orders: 220.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Pizza, Mimosa, Seafood Paella, Spicy, Delicious, Bbq, Cozy, Romantic, American',
        annual_dol: 572.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.593,
        total_orders: 320.79,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Meatball, Caesar Salad, Spacious, Friendly, Authentic, Outstanding, Fine',
        annual_dol: 342.12,
        description:
            'Longtime Northern Italian restaurant serving old-school specialties in an old-world setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7698,
        store_longitude: -73.9518,
        total_orders: 63.62,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Wine, Coffee, Dessert, Avocado Toast, Savory, Organic, French, Swiss, Irresistible',
        annual_dol: 260.05,
        description:
            'Unassuming bistro with an adjacent cafe/grocery furnishes light French bites for breakfast & lunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.835,
        store_longitude: -73.8544,
        total_orders: 17.68,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Chocolate Cake, Shrimp Cocktail, Margarita, Cuban, Coffee, Dominican, Puerto Rican, American, Casual, Local',
        annual_dol: 72.27,
        description:
            '"Restaurant serving Authentic Puerto Rican Cuisine, Full Bar, Catering for all Events Available"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7072,
        store_longitude: -73.6746,
        total_orders: 886.52,
        neighborhood: '',
        keywords:
            'Burger, Steak, Shrimp Cocktail, Beer, Wine, Delicious, Roast Beef, Italian, Juicy, Irish',
        annual_dol: 957.87,
        description:
            'Diverse Irish & American comfort food & 100+ beers from around the world served in a relaxed space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7126,
        store_longitude: -73.9059,
        total_orders: 275.23,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Burger, Vegetarian, Tacos, Mexican, Margarita, Flavorsome, Fajitas, Delicious, Cozy, Casual',
        annual_dol: 792.73,
        description:
            '"La Casita Mexicana is the new king in town of classic Mexican cuisine. In addition to the warm, flavorsome dishes and refreshing drinks, the newly renovated interior provides a colorful, modern atmosphere. A visit here is sure to leave you with a vivid impression and an unforgettable experience!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7045,
        store_longitude: -73.6375,
        total_orders: 9.74,
        neighborhood: '',
        keywords: 'Steak, Coffee, Local, Casual',
        annual_dol: 39.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6778,
        store_longitude: -73.9845,
        total_orders: 1319.58,
        neighborhood: 'Gowanus',
        keywords:
            'Steak, Bbq, Gluten Free, Delicious, American, Homemade, Friendly, Retro, Perfect, Southern',
        annual_dol: 1347.58,
        description:
            'Barbecue chain serving Southern-style meats & draft brews in a retro setting (most have live music).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6606,
        store_longitude: -73.994,
        total_orders: 49.41,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Steak, Mexican, Kebab, Salad, Dessert, Coffee, French Toast, Delicious, Trendy, French',
        annual_dol: 201.98,
        description:
            '"Mexican Cuisine with a touch of new. We Serve Brunch, Lunch & Dinner. Bar Onsite. All Margaritas And Cocktails Are made with Fresh fruit Juices.Outdoor Seating Available"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7721,
        store_longitude: -73.965,
        total_orders: 437.22,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Chocolate Cake, Burger, Sushi, Coffee, Mexican, Italian, Wine, Delicious, Elegant, Asian',
        annual_dol: 456.18,
        description:
            'Classy eatery presenting elevated Italian entrees & pastas with gluten-free options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6975,
        store_longitude: -73.9317,
        total_orders: 760.18,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Fun, Upscale, Margarita, Awesome, Amazing, Friendly, Great, Cool, Urban',
        annual_dol: 1095.84,
        description:
            'Chill, tiki-themed gay bar with picnic tables serves frozen drinks & cocktails plus happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -73.9229,
        total_orders: 604.95,
        neighborhood: 'Bushwick',
        keywords:
            'Vegetarian, Lobster, Pizza, Italian, Dessert, Japanese, Californian, Rustic, Delicious, Upscale',
        annual_dol: 674.58,
        description:
            'Rustic Italian place serving housemade pastas & wood-fired dishes in a renovated warehouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8293,
        store_longitude: -73.8198,
        total_orders: 63.62,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Italian, Elegant, Exquisite, American, Classic, Memorable, Great, Attentive, Teriyaki',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7328,
        store_longitude: -74.0031,
        total_orders: 1548.84,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cajun, Beer, Milkshake, French Onion Soup, French, Fun, Classic, Fresh, Chic, Great',
        annual_dol: 5488.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7281,
        store_longitude: -73.6019,
        total_orders: 1183.73,
        neighborhood: '',
        keywords: 'Wine, American, Perfect, Great, Excellent',
        annual_dol: 1293.17,
        description:
            "The Cradle of Aviation Museum is an aerospace museum located in Garden City, New York on Long Island, established to commemorate Long Island's part in the history of aviation.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.735,
        store_longitude: -74.0081,
        total_orders: 1998.9,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Wine, American, Cozy, Awesome, Perfect, Iconic, Great, Local, Casual',
        annual_dol: 3913.86,
        description: 'Dark, cozy bar with a hideaway vibe and lengthy beer & wine lists.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7854,
        store_longitude: -73.9727,
        total_orders: 1237.23,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Mexican, Beer, Wine, Spanish, Tasty, Upscale, Dominican, Peruvian, Puerto Rican, Italian',
        annual_dol: 2342.22,
        description:
            'Genteel tavern with a patio offering a fusion of Spanish & Caribbean cuisine, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6296,
        store_longitude: -74.1123,
        total_orders: 673.72,
        neighborhood: 'West Brighton',
        keywords:
            'Lobster, Steak, Salad, Shrimp Cocktail, Mexican, Champagne, Dessert, Lobster Bisque, Vodka, Martini',
        annual_dol: 736.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7706,
        store_longitude: -73.9265,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Wine, Beer, Gourmet, Pizza, Mediterranean, Greek, Upscale, Delicious, Spacious, Contemporary',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7067,
        store_longitude: -73.6681,
        total_orders: 16.12,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Shrimp Cocktail, Beer, Wine, Homemade, Classy, Clean, Casual, Local',
        annual_dol: 65.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7521,
        store_longitude: -73.7028,
        total_orders: 55.01,
        neighborhood: 'Glen Oaks/Bellerose Manor',
        keywords:
            'Traditional, Savory, Indian, Southern, Authentic, Casual, Superb, Aloo Tikki, Local, Fine',
        annual_dol: 224.86,
        description:
            'Easygoing Indian restaurant with traditional fare, such as biryanis, tandoori dishes & kebabs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6774,
        store_longitude: -73.9863,
        total_orders: 852.07,
        neighborhood: 'Gowanus',
        keywords:
            'Tacos, Mexican, Pizza, Margarita, Wine, Spanish, Upscale, Delicious, Sparkling, Awesome',
        annual_dol: 1206.18,
        description:
            'Mexican spot with a patio specializing in Oaxacan plates (including house tortillas) & mezcals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.724,
        store_longitude: -73.9507,
        total_orders: 5661.46,
        neighborhood: 'Greenpoint',
        keywords: 'Sushi, Mexican, Wine, Cuban, Japanese, Hotel, American, Homey, Ramen, Homemade',
        annual_dol: 6273.35,
        description:
            'Hip cocktail bar mixing up specialty cocktails, plus an international food menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9656,
        total_orders: 1953.41,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Burger, Hamburger, Shrimp Cocktail, Chicken Pot Pie, Cheesecake, Crab Cakes, Turkey Sandwich, French, Homey',
        annual_dol: 1996.35,
        description:
            'Homey institution for steaks & other straightforward classics in vintage, dark-wood quarters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8477,
        store_longitude: -73.6904,
        total_orders: 2023.78,
        neighborhood: '',
        keywords: 'Golfclub, American, Friendly, Great, Exceptional',
        annual_dol: 2402.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7158,
        store_longitude: -74.0086,
        total_orders: 1155.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Pizza, Vegetarian, Chicken Parmigiana, Italian, Gourmet, Wine, Beer, Bourbon, Luxurious',
        annual_dol: 1179.66,
        description:
            'Thin-crust pizza & homemade pasta headline the menu at this tri-level space with exposed brick.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7201,
        store_longitude: -74.0037,
        total_orders: 49.79,
        neighborhood: 'Tribeca/Canal Street',
        keywords: 'Pizza, Italian, Beer, Coffee, Wine, Upscale, Cozy, Homemade, Fresh, Classy',
        annual_dol: 203.53,
        description:
            'Brick-oven pizzas, sandwiches & pastas, plus coffee & a full bar, in a casual, old-world atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7227,
        store_longitude: -74.0073,
        total_orders: 273.82,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Gourmet, Impeccable, Innovative, Fine, Cultural, Unique',
        annual_dol: 311.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -74.0025,
        total_orders: 62.39,
        neighborhood: '',
        keywords:
            'Steak, Italian, Bbq, Stylish, Sweet, Awesome, Amazing, Fresh, Urban, Sophisticated',
        annual_dol: 260.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7068,
        store_longitude: -73.9235,
        total_orders: 2743.49,
        neighborhood: 'Bushwick',
        keywords: 'Hotdog, Beer, Karaoke, Coffee, Funky, Fun, Relaxed, Awesome, Great, Casual',
        annual_dol: 2864.99,
        description:
            "Cozy, funky venue that's equal parts coffee shop, bar, performance space & yoga studio.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7249,
        store_longitude: -73.6433,
        total_orders: 226.49,
        neighborhood: '',
        keywords: 'Golfclub, Delicious, Great, Casual',
        annual_dol: 346.95,
        description:
            'The Garden City Golf Club is a private golf course in Garden City, New York. The club was founded in 1899, and is also known as the "Garden City Men\'s Club" or simply the "Men\'s Club" to distinguish it from the Garden City Country Club, and Cherry Valley Club, all of which are located in Garden City, NY.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7841,
        store_longitude: -73.9774,
        total_orders: 605.54,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Salad, Italian, Beer, Delicious, Ale, Karaoke, Fun, Casual, American',
        annual_dol: 700.26,
        description:
            '"Funky Western dive bar. Come in for good vibes and arcades! Pinball, Buck Hunt, Photobooth. M - Open Mic @ 8pm T - Trivia @ 8pm W - Karaoke @ 8pm F - Live Honky Tonk @ 9pm S - Disco Rodeo @ 10pm"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.713,
        store_longitude: -73.6768,
        total_orders: 64.26,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Shrimp Cocktail, Wine, Caesar Salad, Comfortable, Casual, Fresh, Authentic',
        annual_dol: 149.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.658,
        total_orders: 26.61,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Steak, Cheesesteak, Beer, Philly Cheesesteak, American, Classic, Friendly, Casual',
        annual_dol: 108.78,
        description:
            'Specializing in chicken wings, burgers & classic bar snacks, with industrial decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6575,
        store_longitude: -73.5477,
        total_orders: 10.35,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Japanese, Wine, Spicy, Sashimi, Beer, Delicious, Filet Mignon, Fusion',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6579,
        store_longitude: -73.9829,
        total_orders: 22.78,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'American, Heritage',
        annual_dol: 93.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6965,
        store_longitude: -73.9349,
        total_orders: 823.45,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Pizza, Beer, Whiskey Sour, Whiskey, Fun, American, Great, Classic, Casual',
        annual_dol: 2237.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6852,
        store_longitude: -73.9383,
        total_orders: 187.19,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Lasagna, Pizza, Vegan, Vegetarian, Salad, Gluten Free, Coffee, Wine, Dessert, Beer',
        annual_dol: 262.24,
        description:
            'Neapolitan pizzas, cocktails & craft beer turned out in a simple, modern space with counter seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7699,
        store_longitude: -73.9577,
        total_orders: 1989.03,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Tasty, Thai, Stylish, Salad, Gluten Free, Spicy, Delicious, Upscale, Romantic, Chinese',
        annual_dol: 2031.24,
        description:
            'Stylish restaurant & bar serving upscale Thai street food in a low-lit space with colorful lanterns.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6548,
        store_longitude: -73.6752,
        total_orders: 208.42,
        neighborhood: '',
        keywords: 'Burger, Salad, Swiss, Beer, Irish, Spicy, Reuben, Casual, Classic, Great',
        annual_dol: 217.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -74.0036,
        total_orders: 1931.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Warm, Legendary, Fun, Friendly, Iconic, Amazing, Great, Quirky, Casual',
        annual_dol: 1972.57,
        description:
            'Live bebop, rhythm & blues, Dixieland jazz & more are presented at this circa-1937 tavern.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.736,
        store_longitude: -74.0016,
        total_orders: 1959.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Legendary, Iconic, Premium, Classic, Superb, Amazing, Authentic, Great, Excellent, Friendly',
        annual_dol: 1959.11,
        description:
            "From iconic 1960s gigs by John Coltrane to who's playing here now, this club lives up to its name.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8335,
        store_longitude: -73.8287,
        total_orders: 140.57,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords:
            'Burger, Tasty, Trendy, Legendary, American, Awesome, Amazing, Fresh, Great, Excellent',
        annual_dol: 382.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7868,
        store_longitude: -73.9755,
        total_orders: 147.42,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Gin, Delicious, Trendy, Rum, Painkiller, Hurricane, Fried Chicken, Irish, Pinacolada',
        annual_dol: 212.72,
        description:
            'Tiki staples & frozen drinks plus fried chicken & spam sandwiches are served in a vibrant space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8692,
        store_longitude: -73.8252,
        total_orders: 542.05,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Steak, Vegetarian, Lobster, Hamburger, Beer, Italian, Savory, American, Casual, Fabulous',
        annual_dol: 1144.35,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6479,
        store_longitude: -74.0077,
        total_orders: 5628.94,
        neighborhood: 'Sunset Park',
        keywords:
            'Tacos, Mexican, Vegetarian, Nachos, Margarita, Enchiladas, Fajitas, Delicious, Cozy, Smoky',
        annual_dol: 6293.02,
        description:
            'Traditional menu of tacos, tamales & tostadas served in a simple space; BYOB.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7888,
        store_longitude: -73.9744,
        total_orders: 256.56,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Pizza, Italian, Sushi, Salad, Meatball, Beer, Wine, Spicy, Rustic, Casual',
        annual_dol: 966.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7708,
        store_longitude: -73.9202,
        total_orders: 14.28,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Italian, Swiss, Homemade, Delicious, Mediterranean, American, Modern, Premium, Authentic',
        annual_dol: 58.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6964,
        store_longitude: -73.9004,
        total_orders: 53.35,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Burger, Steak, Pizza, Beer, Bbq, Cuban, Spanish, Wine, Avocado Toast, Ale',
        annual_dol: 218.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7159,
        store_longitude: -74.0151,
        total_orders: 256.57,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Sushi, Gluten Free, Spicy, Japanese, Chinese, Fried Rice, Asian, Thai, Modern, Fusion',
        annual_dol: 330.86,
        description:
            'Polished eatery offering elevated Pan-Asian plates, including sushi, fried rice & noodle bowls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8544,
        store_longitude: -73.8864,
        total_orders: 2213.75,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Vegetarian, Pizza, Italian, Shrimp Cocktail, Meatball, Wine, Delicious, French, Cozy, Polished',
        annual_dol: 3387.61,
        description:
            'Cozy trattoria known for its brick-oven thin-crust pies & other homestyle Italian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -74.0018,
        total_orders: 1477.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Italian, Wine, Beer, Fun, American, Heritage, Great, Tradition',
        annual_dol: 1508.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.8822,
        total_orders: 37.45,
        neighborhood: 'Jackson Heights',
        keywords:
            'Salad, Colombian, Peruvian, Dessert, Japanese, Thai, Delicious, Hotel, American, Local',
        annual_dol: 153.09,
        description: '"Colombian American Inspired Cuisine Serving Smoked Meats and Drinks"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9977,
        total_orders: 636.12,
        neighborhood: 'Nolita',
        keywords:
            'Tequila, Mexican, Tasty, Upscale, Spanish, Delicious, American, Innovative, Latin, Authentic',
        annual_dol: 2600.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6952,
        store_longitude: -73.9964,
        total_orders: 18.29,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Salad, Korean, Spicy, Sweet, American, High-End, Casual, Great, Unique, Chill',
        annual_dol: 20.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7646,
        store_longitude: -73.9809,
        total_orders: 2230.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Beer, Gourmet, Sleek, Sophisticated, Comfortable, Great, Terrific, Cool',
        annual_dol: 2326.83,
        description:
            "subway station.Sleek rooms with plush furnishings have Wi-Fi (fee), 46-inch flat-screen TVs and iPod docks; upper floors provide city views. Minifridges and coffeemakers are available on request.There's a grab-and-go cafe, and a hip lounge with mixology classes and regular live music. Other amenities include a 24-hour fitness center and meeting/event space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -73.9877,
        total_orders: 350.51,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Burger, Wine, Italian, Beer, Margarita, Mediterranean, Warm, Rustic, Southern',
        annual_dol: 365.71,
        description:
            'An Italian menu with a creative slant is served in a rustic dining room with an outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -74,
        total_orders: 7819.87,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Steak, Tacos, Vegetarian, Mexican, Nachos, Beer, Cuban, Coffee, Dessert',
        annual_dol: 10112.15,
        description:
            '24/7 luncheonette dishing out Pan-Latin comfort fare in a casual, colorful setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5517,
        store_longitude: -74.2016,
        total_orders: 2107.45,
        neighborhood: 'Rossville',
        keywords: 'Golfclub, Awesome, Memorable, Seasonal',
        annual_dol: 2250.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7407,
        store_longitude: -73.993,
        total_orders: 2172.82,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Pizza, Spicy, Beer, Bbq, Fun, Friendly, Relaxed, Perfect, Great, Casual',
        annual_dol: 2428.68,
        description:
            'Popular 2-level gay sports bar with big TVs, pool tables, brick-oven pizza, drink specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7812,
        store_longitude: -73.8029,
        total_orders: 224.47,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Sushi, Italian, Beer, Meatball, Japanese, French, American, Cozy, Homemade, Classy',
        annual_dol: 440.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6819,
        store_longitude: -73.9764,
        total_orders: 34.66,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Italian, Vegetarian, Salad, Gluten Free, Dessert, Wine, Homemade, Modern, Healthy',
        annual_dol: 51.51,
        description:
            'Buzzy trattoria offering Italian eats such as homemade pasta & pizza, plus a full bar with sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7127,
        store_longitude: -73.9571,
        total_orders: 476.53,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Pizza, Meatball, Chinese, Funky, Wine, Asian, Fun, Great, Casual, Signature',
        annual_dol: 1555.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6635,
        total_orders: 103.15,
        neighborhood: '',
        keywords:
            'Burger, Garlic Bread, Hamburger, Pizza, Sushi, Beer, Dominican, American, Perfect, Casual',
        annual_dol: 113,
        description:
            'Pub grub with full bar including a large beer selection, served in a simple space with lots of TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5889,
        store_longitude: -73.6451,
        total_orders: 88.88,
        neighborhood: '',
        keywords:
            'Kebab, Salad, Steak, Pizza, Tequila, Mexican, Vegetarian, Greek, Italian, Mediterranean',
        annual_dol: 363.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.648,
        store_longitude: -74.0863,
        total_orders: 33.89,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Greek, Egyptian, Elegant, Unique, Heritage, Fabulous',
        annual_dol: 138.52,
        description:
            '"EXPERIENCE A NEW ‘SO AMAZING!℠’ TRADITION: The Pavilion on the Terrace® is a style reminiscent of elegant craftsmanship with beautiful landscaping. Our historic Greek revival building built in 1835 has been magnificently renovated, and is the oldest and most unique structure in the St. George/New Brighton Historic District. The temple fronted two-story building has returned to its 19th century elegance with an interior which now houses a fabulous ​banquet hall, dining rooms, bars, and bridal suite. Our dedicated and experienced banquet hall staff will attend to every detail and assure a memory that you will always cherish--making your event not only Special but ALWAYS: SO AMAZING!℠"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7513,
        store_longitude: -73.9806,
        total_orders: 50.32,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tacos, Beer, Burritos, Salad, Wine, Hotel, Western, Delicious, Local',
        annual_dol: 542.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.9798,
        total_orders: 1339.96,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Japanese, Sushi, Hotel, Gourmet, Exquisite, Polished, High-End, Authentic, Fresh, Traditional',
        annual_dol: 1684.27,
        description:
            "The Kitano hotel's eatery offers Japanese fare & ancient Kaiseki cuisine tasting menus.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7448,
        store_longitude: -73.9842,
        total_orders: 423.73,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords:
            'Wine, Hotel, Coffee, Italian, Legendary, Eclectic, Rustic, Vibrant, Fantastic, Great',
        annual_dol: 451.58,
        description:
            'Casual-chic rooms have free Wi-Fi, flat-screen TVs, Italian linens and modern bathrooms; suites add sitting areas and side tables. Some rooms also have slate-tiled bathrooms and iPhone docks.Perks include an exercise room, a business center, and a contemporary Italian restaurant and lounge with craft cocktails. Parking is available at a nearby garage for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7106,
        store_longitude: -73.9657,
        total_orders: 585.04,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Wine, Trendy, Delicious, Gourmet, Cozy, Rustic, Friendly, Natural, Mediterranean, Modern',
        annual_dol: 1507.57,
        description:
            'Cozy Williamsburg spot featuring nibbles with a farm-fresh emphasis, plus wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.987,
        total_orders: 127.91,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Fun, Bright, Exceptional',
        annual_dol: 142.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7457,
        store_longitude: -73.9755,
        total_orders: 4.9,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Vegetarian, Steak, Roast Turkey, Coffee, French Toast, Swiss, French, French Onion Soup, Fried Chicken, American',
        annual_dol: 11.39,
        description:
            'Basic diner with 24/7 availability & a huge menu spanning breakfast basics to dinner entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -74.0027,
        total_orders: 202.14,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Fun, Premium, Great, Perfect, Friendly, Rooftop, Distinct',
        annual_dol: 290.83,
        description:
            'Colorful, multi-tiered dance club featuring high ceilings, LED screens & plenty of night owls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9811,
        total_orders: 6295.9,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Burger, Whiskey, Wine, French, Elegant, American, Warm, Polished, Cozy',
        annual_dol: 6526.56,
        description:
            'Polished French restaurant in an expansive art nouveau–style space with an elegant heated atrium.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9897,
        total_orders: 150.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Thai, Wine, Beer, Trendy, Bbq, Asian, Modern, American, Fusion, Classic',
        annual_dol: 160.56,
        description:
            'This Thai restaurant offers classic fare in a neon-lit, dark-wood space with a futuristic-chic vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.9871,
        total_orders: 3883.06,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Coffee, Authentic, Contemporary, Casual, Timeless, Fun, Classic, Lit, Great',
        annual_dol: 4051.42,
        description:
            'Hideaway with a Prohibition-esque vibe, offering cocktails in teacups and beer in paper bags.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7205,
        store_longitude: -73.9887,
        total_orders: 142.15,
        neighborhood: 'Lower East Side',
        keywords: 'Hamburger, Pizza, Cajun, Tequila, Mexican, Beer, Bbq, Wine, Chinese, Spicy',
        annual_dol: 499.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9888,
        total_orders: 3119.53,
        neighborhood: 'Lower East Side',
        keywords:
            'Wine, Beer, Martini, Eclectic, Vibrant, Lively, Classy, Retro, Fabulous, Spectacular',
        annual_dol: 3185.72,
        description:
            'Burlesque theater & lounge where patrons can watch variety shows from the main floor or mezzanine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9929,
        total_orders: 66.86,
        neighborhood: 'Bowery',
        keywords:
            'Steak, Salad, Dessert, Upscale, Mediterranean, Greek, Spacious, Polished, Homemade, Great',
        annual_dol: 281.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7632,
        store_longitude: -73.9681,
        total_orders: 46.74,
        neighborhood: 'Lenox Hill',
        keywords: 'Wine, French, Coffee, Thai, American, Casual, Hip, Intimate, Authentic, Fine',
        annual_dol: 191.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6764,
        store_longitude: -73.8218,
        total_orders: 20.89,
        neighborhood: 'South Ozone Park',
        keywords: 'Flavorful, Rich, Delicious, Upscale, Coffee, Fun, Premium, Friendly, Chill',
        annual_dol: 85.41,
        description:
            "\"Whether you're looking to relax on a weeknight or celebrate your next big event with the hottest DJ's in Queens, you've reached the right place!\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 426.63,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Rooftop, Hotel, Upscale, Champagne, Delicious, Spicy, Iconic, Tasty, Premium, Exceptional',
        annual_dol: 1547.86,
        description:
            'Posh hotel cocktail lounge providing comfy outdoor seating, DJ music & skyscraper views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9553,
        total_orders: 1940.46,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Beer, Vodka, Whiskey, Wine, Delicious, Dessert, Warm, Fun, Whimsical',
        annual_dol: 2095.84,
        description:
            'Domestic beers & 50 cocktails inspired by each U.S. state in a warm bar setting with a few TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6755,
        store_longitude: -73.956,
        total_orders: 1730.81,
        neighborhood: 'Crown Heights',
        keywords: 'Tequila, Vodka, Wine, Scotch, Gin, Rum, Delicious, Upscale, Cozy, Ramen',
        annual_dol: 1767.54,
        description:
            'Hip, cozy neighborhood bar offering craft cocktails, beer, wine & a backyard patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.706,
        store_longitude: -74.0049,
        total_orders: 89.38,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Italian, Comfortable, Amazing, Friendly, Great, Modern, Edgy',
        annual_dol: 338.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.928,
        total_orders: 5502.06,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Burger, Vegan, Pizza, Homemade, Friendly, Cool, Fresh, Fantastic, Great, Classic',
        annual_dol: 6413.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -73.9581,
        total_orders: 6696.1,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Lobster, Hotel, Shrimp Cocktail, Upscale, Romantic, Funky, Premium, Innovative, Intimate',
        annual_dol: 6838.18,
        description:
            'Popular Williamsburg bar serving cocktails in an upscale setting that recalls old New York.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -73.9617,
        total_orders: 574.7,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Beer, Trendy, Upscale, Japanese, Spicy, American, Delicious, Modern, Asian',
        annual_dol: 2349.17,
        description:
            '"Tonchin Brooklyn is a modern Japanese restaurant featuring a full a la carte menu grounded in traditional flavors, with a New American twist. Our extensive natural wine list and signature ramen anchor our journey as we explore the intersection between Tokyo and Brooklyn, uncovering new ways of preparing fresh local food in the Japanese spirit. We take to heart Tonchin\'s philosophy of bringing people together through extraordinary experiences, and are excited to discover the new, the fresh, and the seasonal with you."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.723,
        store_longitude: -73.9536,
        total_orders: 900.78,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords: 'Beer, Mexican, Sushi, German, Cozy, Casual, Chill, Fantastic, Great, Unique',
        annual_dol: 939.83,
        description:
            'Beer hall serving craft brews in a 6,000-sq.-ft. industrial setting with communal tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6441,
        store_longitude: -73.7822,
        total_orders: 6.34,
        neighborhood: 'Jamaica',
        keywords:
            'Swiss, Modern, Premium, Fine Quality, Lebanese, Fresh, Legendary, Cool, Seasonal, Fine',
        annual_dol: 25.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9211,
        total_orders: 460.79,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Gourmet, Beer, Nachos, Thai, Trendy, Asian, American, Fun, Sweet',
        annual_dol: 512.68,
        description:
            'Bustling sports bar offering American comfort food & many beers on tap, plus DJs & bocce ball.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.736,
        store_longitude: -73.934,
        total_orders: 1032.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Meatball, Spicy, Irish, Martini, American, Eclectic, High-End, Artisanal, Fresh, Great',
        annual_dol: 1054.54,
        description:
            'Unfussy watering hole offering hearty, internationally inspired pub grub & draft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6292,
        store_longitude: -74.0056,
        total_orders: 562.55,
        neighborhood: 'Dyker Heights',
        keywords:
            'Steak, Salad, Italian, Wine, Elegant, Cozy, Ribeye Steak, Classy, Premium, Homemade',
        annual_dol: 612.2,
        description:
            'Traditional trattoria showcases red-sauce Italian classics & offers a fixed-price menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6814,
        store_longitude: -74.0061,
        total_orders: 52.1,
        neighborhood: 'Red Hook',
        keywords: 'Modern, Friendly',
        annual_dol: 212.99,
        description:
            '"Shell’s Loft is a Daylight Film & Photo Studio, Wedding Venue, and Private & Corporate Event Space in Brooklyn, NYC. We\'re known for our huge plant collection + prop rentals!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6836,
        store_longitude: -73.9951,
        total_orders: 25.69,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Pizza, Italian, Dairy Free, Beer, Wine, Delicious, Fried Shrimp, Modern, British, Amazing',
        annual_dol: 105.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7265,
        store_longitude: -73.9869,
        total_orders: 1208.38,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Italian, Delicious, Dessert, Pizza, Swiss, Cozy, Mediterranean, Polished, Relaxed',
        annual_dol: 1718.14,
        description:
            'Relaxed haunt inspired by the Adriatic Sea serving coastal bites & wine in a wood-accented space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7266,
        store_longitude: -73.9862,
        total_orders: 277.64,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Lasagna, Salad, Italian, Wine, Tasty, Mediterranean, Rustic, Cozy, Delicious, Creamy',
        annual_dol: 289.68,
        description:
            'Cozy eatery with exposed brick walls, serving elevated Italian entrees, including housemade pasta.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8058,
        store_longitude: -73.9659,
        total_orders: 2469.35,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Steak, Burger, Chocolate Croissant, Salad, Italian, Wine, French, French Onion Soup, Organic, American',
        annual_dol: 2594.33,
        description:
            "Wine & cuisine from France's Loire Valley region in a bistro-style space with patio seating.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.9732,
        total_orders: 3852.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Whiskey, Beer, Cozy, Upscale, Friendly, Fun, Irish, Lively, Perfect',
        annual_dol: 3963.7,
        description:
            'Dimly lit multi-level bar with couches where TVs, video games & a pool table divert drinkers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.8292,
        total_orders: 2735.54,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Karaoke, Beer, Chinese, Luxurious, Fancy, Classy, Great, Excellent, Signature, Complex',
        annual_dol: 4079.78,
        description:
            'Flashy karaoke complex with a nightclub vibe, featuring private rooms, snacks & bottle service.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.9777,
        total_orders: 1741.8,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Whiskey, Wine, Beer, Southern Comfort, Southern, Tasty, Ale, Spicy, Casual, Coleslaw',
        annual_dol: 6114.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.9479,
        total_orders: 486.43,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Vegetarian, Vegan, Salad, Turkey Sandwich, Organic, Wine, Coffee, Gluten Free, Turkish, Georgian',
        annual_dol: 1757.33,
        description: '"We offer authentic Georgian cuisine and natural wines."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.972,
        total_orders: 78.05,
        neighborhood: 'Midtown Manhattan/Tudor City/Murray Hill',
        keywords: 'Hotel, Modern, Elegant, Classic, Great, Local',
        annual_dol: 106.07,
        description:
            'The Museum of Modern Art.Relaxed rooms with an elegant vibe come with flat-screen TVs, sitting areas and coffeemakers, as well as Wi-Fi (surcharge). Suites add separate living space; some provide furnished terraces or balconies with city views.Amenities include a laid-back restaurant, a down-to-earth lobby cafe and a gym, plus meeting and event space. Valet parking and breakfast are available for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.8337,
        total_orders: 133.89,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Beer, Wine, Asian, Chinese, Delicious, Classy, Casual, Local, Dim Sum',
        annual_dol: 242.72,
        description:
            'Bustling venue serving a standard selection of dim sum in a large, traditionally decorated space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.802,
        store_longitude: -73.6515,
        total_orders: 1773.61,
        neighborhood: '',
        keywords: 'Delicious, Savory, Gourmet, Exquisite, Chic',
        annual_dol: 1973.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7978,
        store_longitude: -73.6655,
        total_orders: 1359.98,
        neighborhood: '',
        keywords:
            'Italian, Delicious, Comfortable, Homemade, Perfect, Fancy, Traditional, Classic, Great, Fine',
        annual_dol: 1468.07,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6219,
        store_longitude: -74.0319,
        total_orders: 480.27,
        neighborhood: 'Fort Hamilton',
        keywords: 'Burger, Steak, Tacos, Sushi, Spicy, Delicious, Bbq, Caesar Wrap, Irish, Awesome',
        annual_dol: 485.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6801,
        store_longitude: -73.9417,
        total_orders: 399.13,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Burger, Pizza, Tasty, Salad, Delicious, Ale, Perfect, Lively, Excellent, American',
        annual_dol: 815.77,
        description:
            'Lively sports bar offering comfort food, cocktails & happy hours, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -73.9833,
        total_orders: 2165.25,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Vegetarian, Hamburger, Coffee, Beer, Whiskey, Irish, American, Casual, Friendly',
        annual_dol: 2275.92,
        description:
            'Irish pub serving burgers & pints in a setting with sawdust on the floor & a wood-burning fireplace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.9839,
        total_orders: 547.56,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Spanish, Stylish, Fun, Edgy, Cool, Clean',
        annual_dol: 683.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -74.0039,
        total_orders: 5065.86,
        neighborhood: 'Midtown Manhattan/Hudson Yards',
        keywords: 'Delicious, Premium, Impeccable, Gourmet, Great, Local',
        annual_dol: 6065.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9972,
        total_orders: 2021.52,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Salad, Delicious, Greek, Prosecco, Modern, American, Fresh, Traditional, Local',
        annual_dol: 6599.8,
        description:
            '"Kyma, the popular Greek Taverna with locations in Roslyn, Long Island and in the Flatiron district is expanding in New York City with its new location: Kyma Hudson Yards, scheduled to open mid October 2021. With Executive Chef George Pagonis at its helm (Top Chef Boston alum, Anassa Taverna), who executes traditional Greek dishes with modern flair, Kyma. The restaurant features 200 seats including two private rooms, a large circular bar with a cocktail program by Konstantinos Chantzis and outdoor seating."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6116,
        store_longitude: -74.0101,
        total_orders: 277.21,
        neighborhood: 'Bath Beach/Bensonhurst',
        keywords:
            'Steak, Italian, Salad, Wine, Ribeye Steak, Australian, Classic, Casual, Great, Wonderful',
        annual_dol: 316.2,
        description:
            'Long-standing Italian eatery known for homestyle classics & a long wine list, plus live opera music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7237,
        store_longitude: -74.0097,
        total_orders: 846.39,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Tequila, Mexican, Salad, Italian, Vodka, Argentinian, Gluten Free, Spanish, Gin',
        annual_dol: 864.35,
        description:
            'Argentinian bistro with Italian influences serves regional wines in rustic whitewashed space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7174,
        store_longitude: -74.0105,
        total_orders: 350.81,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Pizza, Italian, Meatball, Delicious, Trendy, Homemade, Bruschetta, Authentic, Fresh',
        annual_dol: 367.53,
        description:
            'Veal, meats & pastas highlight this Tuscan-inspired, wood-accented space with high-ceilings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7786,
        store_longitude: -73.9564,
        total_orders: 2709.38,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Hotdog, Pizza, Beer, Irish, Cozy, Fun, Relaxed, Friendly, Awesome, Cool',
        annual_dol: 2770.18,
        description:
            'Simple, long-standing Irish tavern with Seattle sports teams on TV, a pool table & free Wi-Fi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.813,
        store_longitude: -73.9287,
        total_orders: 56.79,
        neighborhood: 'Mott Haven',
        keywords:
            'Hotel, High-End, Funky, Comfortable, Relaxed, Great, Rooftop, Excellent, Latin, Fusion',
        annual_dol: 232.13,
        description:
            "Hip rooms feature Wi-Fi, flat-screen TVs and minifridges, as well as tea and coffeemaking facilities. Some have bunk beds. Room service is available.A restaurant features funky Pop Art-style decor. There's also a bar, a fitness room and a breakfast buffet, plus valet parking.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.995,
        total_orders: 1515.08,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Salad, Beer, Irish, Zesty, Friendly, Authentic, Casual, Great',
        annual_dol: 1624.97,
        description:
            'For decades, this corner bar near Penn Station has been drawing pints & plating simple pub fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.9824,
        total_orders: 220.66,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Vegetarian, Sushi, Salad, Italian, Japanese, Spicy, Sashimi, Hotel, African',
        annual_dol: 831.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.9899,
        total_orders: 969.83,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Milkshake, Wine, Tasty, French Toast, Beer, Ale, Mimosa, Avocado Toast, French',
        annual_dol: 1358.23,
        description:
            'Comfort food, cocktails & shakes, plus all-day brunch served in a happening hot spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -73.9873,
        total_orders: 6185.44,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Butter Chicken, Spicy, Delicious, Dessert, Cozy, Sweet, Gourmet, Fried Chicken, Eclectic',
        annual_dol: 6453.62,
        description:
            'Rustic-chic eatery with American comfort food, such as chicken & waffles, plus cocktails & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6924,
        store_longitude: -73.8588,
        total_orders: 232.16,
        neighborhood: 'Jamaica/Woodhaven',
        keywords:
            'Steak, Salad, Mexican, Wine, Beer, Dessert, Fried Calamari, Organic, Latin, Spanish',
        annual_dol: 253.02,
        description: '"Latin Fusion Cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9001,
        store_longitude: -73.8675,
        total_orders: 55.58,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords:
            'Pizza, Beer, Salad, Chili Con Carne, German, Ale, Irish, Western, Casual, Cultural',
        annual_dol: 150.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.9867,
        total_orders: 8.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Burger, Cheesecake, Tasty, Korean, Beer, Spicy, Delicious, American, Wine, Karaoke',
        annual_dol: 33.88,
        description:
            'Modern, dimly lit eatery offering Korean-American small plates plus cocktails, soju & makgeolli.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7741,
        store_longitude: -73.9081,
        total_orders: 10.73,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Pizza, Cheesecake, Italian, Salad, Dessert, Elegant, Sweet, Casual',
        annual_dol: 43.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6813,
        store_longitude: -73.9757,
        total_orders: 107.57,
        neighborhood: 'Park Slope',
        keywords: 'Chinese, Korean, Japanese, Asian, Bao Buns, Ramen, Casual, Local, Healthy',
        annual_dol: 439.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6857,
        store_longitude: -73.9862,
        total_orders: 2239.35,
        neighborhood: 'Boerum Hill',
        keywords: 'Pizza, Vegetarian, Salad, Italian, Swiss, Beer, Gin, Spicy, Cozy, Elegant',
        annual_dol: 2383.1,
        description:
            'Farm-to-table Northern Italian fare is served all day in this compact, lumber-lined restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6781,
        store_longitude: -73.9791,
        total_orders: 521.62,
        neighborhood: 'Park Slope',
        keywords:
            'Steak, Mexican, Colombian, Gluten Free, Spicy, Delicious, Latin, Warm, Dominican, American',
        annual_dol: 657.89,
        description:
            'Popular eatery offering Pan-Latin cuisine, tropical cocktails & colorful, artsy decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6403,
        store_longitude: -74.0756,
        total_orders: 845.45,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Burger, Salad, Dessert, Gluten Free, Tasty, Spanish, Yummy, Homemade, Sashimi, American',
        annual_dol: 1158.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7077,
        store_longitude: -74.0071,
        total_orders: 509.5,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegetarian, Wine, Salad, Italian, Hamburger, Dessert, Coffee, Flavorful, Avocado Toast, Beer',
        annual_dol: 658.16,
        description:
            'Sleek outpost with warm leather & wood decor offering Tuscan fare & a long list of Italian wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.9802,
        total_orders: 3563.21,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Tequila, Beer, Pizza, Tasty, Upscale, Fun, Lively, Fresh, Creative, Great',
        annual_dol: 3563.21,
        description:
            'Daytime haircuts & drinks in the front room morph into a lively back-room cocktail bar at night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7169,
        store_longitude: -73.9507,
        total_orders: 167.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegan, Wine, Salad, Coffee, Delicious, Roast Turkey, Cozy, Turkey Sandwich, Clean, Sweet',
        annual_dol: 631.62,
        description:
            'Innovative salads, bowls & sandwiches served in chill digs with a leafy backyard patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -73.9804,
        total_orders: 687.65,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Burger, Tacos, Pizza, Vegan, Gluten Free, Breakfast Burrito, Gourmet, Beer, Lemon Drop, Nachos',
        annual_dol: 764.01,
        description:
            '"An upscale sports lounge and event space with elevated food. Focused on Charity and Sports. Amenities include: A private loft with its own bar, a porch style patio, 30 Flat Screen TV\'s, and Home Made Infused Liquors."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7468,
        store_longitude: -73.9824,
        total_orders: 5147.57,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Hamburger, Salad, Sushi, Mexican, Dessert, Margarita, Tasty, American, Asian',
        annual_dol: 5340.21,
        description:
            '"The Ainsworth Midtown is a multipurpose space comprised of a full-scale restaurant, bar, lounge and event area. Ainsworth Midtown offers a new take on classic American fare favorites, signature cocktails, and visually stimulating décor, culminating in the ultimate drinking and dining experience."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.711,
        store_longitude: -73.965,
        total_orders: 803.68,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Tacos, Mexican, Steak, Tequila, Upscale, Tex-Mex, Delicious, Warm, Quaint, Fresh',
        annual_dol: 961.58,
        description:
            'Contemporary, upscale takes on Mexican classics in an unpretentious venue with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.9836,
        total_orders: 2245.35,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Burger, Gluten Free, Beer, Tasty, Dessert, Bbq, Hotel, American, Southern, Healthy',
        annual_dol: 8459.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9285,
        total_orders: 864.77,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Fun, Awesome, Premium, Perfect, Cool, Dutch',
        annual_dol: 902.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 6332.79,
        neighborhood: 'Bushwick',
        keywords:
            'Kebab, Beer, Wine, Turkish, Chinese, Mediterranean, Delicious, Fun, American, Seasonal',
        annual_dol: 7212.61,
        description:
            'Revival of a longtime Wisconsin supper club serving Middle Eastern dishes in an eccentric setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7321,
        store_longitude: -74.0012,
        total_orders: 2039.02,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Ale, Chinese, Irish, Picturesque, Friendly, Casual, Tradition, Great',
        annual_dol: 2082.28,
        description:
            'Casual spot where students & other Village denizens relax over a pint, poetry or live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.6368,
        total_orders: 66.97,
        neighborhood: '',
        keywords: 'Sushi, Salad, Vegan, Tasty, Spicy, Japanese, Delicious, Creamy, Chinese, Zen',
        annual_dol: 72.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7321,
        store_longitude: -73.8494,
        total_orders: 235.74,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Lobster, Salad, Sushi, Japanese, Upscale, Spicy, Asian, Warm, Bbq, Polished',
        annual_dol: 963.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.768,
        store_longitude: -73.9207,
        total_orders: 1757.52,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Sushi, Mexican, Beer, Thai, Japanese, Pinacolada, Latin, Puerto Rican, Screwdriver',
        annual_dol: 7138.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8537,
        store_longitude: -73.8885,
        total_orders: 449.4,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Burger, Italian, Delicious, Savory, Wine, Classy, Vibrant, Perfect, Exceptional, Great',
        annual_dol: 479.27,
        description:
            'Chef Roberto Paciullo’s destination Italian eatery serves Salerno specialties in old-style setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7662,
        store_longitude: -73.9626,
        total_orders: 1036.76,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Beer, Wine, American, Upscale, Cozy, Local, Classic, Casual, Diverse, Great',
        annual_dol: 1036.76,
        description:
            'Cozy local watering hole & back patio offering diverse American fare, beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.857,
        store_longitude: -73.7906,
        total_orders: 44.69,
        neighborhood: 'City Island',
        keywords: 'Garlic Bread, Lobster, Italian, Shrimp Scampi, Delicious, Casual',
        annual_dol: 111.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6623,
        store_longitude: -73.8062,
        total_orders: 7562.18,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Sushi, Pizza, Wine, Donut, Coffee, Delicious, Mediterranean, French, Organic, Sweet',
        annual_dol: 7722.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5223,
        store_longitude: -74.2391,
        total_orders: 162.52,
        neighborhood: 'Charleston',
        keywords:
            'Mexican, Spanish, Italian, Spicy, Chilean, Delicious, Mediterranean, Dominican, American, Lively',
        annual_dol: 631.18,
        description:
            '"Welcome to Lava! A Fusion Restaurant and Lounge located in Staten Island. We welcome you to enjoy our carefully crafted menu. Join us at the bar while our mixologist designs you the most perfect cocktail! Come out on the dance floor Thursday-Saturday for our after hours party! Delicious food, Outstanding ambiance, and attentive service is what makes Lava your new go to spot!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5964,
        store_longitude: -73.9777,
        total_orders: 585.63,
        neighborhood: 'Gravesend',
        keywords:
            'Tequila, Sushi, Wine, Vodka, Tasty, Shrimp Cocktail, Martini, Delicious, Italian, Warm',
        annual_dol: 623.22,
        description:
            'Low-lit nightspot with a warm vibe for cocktails, hookahs & American fare, plus belly dancers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8541,
        store_longitude: -73.8718,
        total_orders: 48.57,
        neighborhood: '',
        keywords:
            'Lobster, Chinese, Jamaican, Caribbean, Flavorful, Bbq, Sweet, Local, Casual, Tempura',
        annual_dol: 198.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7291,
        store_longitude: -73.9853,
        total_orders: 201.28,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Sushi, Japanese, Beer, Modern, Seasonal, Casual, Omelette, Local, Rooftop',
        annual_dol: 822.78,
        description:
            'Japanese tasting menus highlighting ingredients of the season are presented in an intimate setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8475,
        store_longitude: -73.9384,
        total_orders: 839.34,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Vegan, Steak, Wine, Italian, Savory, American, Spanish, Peking Duck, Natural, Latin',
        annual_dol: 1331.88,
        description:
            'Inventive cocktails & Caribbean-inspired vegan small plates served in sleek, dimly lit surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6098,
        store_longitude: -74.0632,
        total_orders: 59.21,
        neighborhood: 'Rosebank',
        keywords: 'Beer, Wine, American, Welcoming, Fun, Casual, Cool, Local, Sangria, Attentive',
        annual_dol: 167.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9729,
        total_orders: 1121.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Flavorsome, Italian, Salad, Wine, Upscale, Delicious, Elegant, Meatball, Hotel',
        annual_dol: 1285.81,
        description:
            'Swanky bar/eatery at the Bernic Hotel with an old-world vibe & traditional Northern Italian entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7515,
        store_longitude: -73.9708,
        total_orders: 1538.7,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Italian, Coffee, Danish, American, Elegant, Classic',
        annual_dol: 5439.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8844,
        store_longitude: -73.9004,
        total_orders: 2525.61,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'French, Beer, New-York-Style, Chicken Wings, Traditional, Sweet, Local, Gyro',
        annual_dol: 2579.2,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8236,
        store_longitude: -73.9522,
        total_orders: 98.86,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Steak, Wine, Salad, Bourbon, Cuban, Beer, Savory, Delicious, Hotel, Premium',
        annual_dol: 182.32,
        description:
            '"Cozy place with a great crafted wine selections, good beers, savory cocktails and a appealing food menu. Convenient access near the 1 train. Plus the convenience of the excellent cigar lounge Harlem Cigar Room next door. Perfect for a date night."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.9751,
        total_orders: 494.45,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Lobster, Salad, Pizza, Italian, Dessert, Quaint, Homemade, Hotel, Modern',
        annual_dol: 518.83,
        description:
            'Popular family-run Tuscan restaurant serving lasagna, pizza & more in a quaint setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9684,
        total_orders: 1636.39,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Steak, Korean, Beer, Organic, Fried Chicken, Ribeye Steak, Wine, Asian, Fusion',
        annual_dol: 1831.91,
        description:
            'Sleek, casual haunt serving Korean fried chicken & other traditional eats, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6127,
        store_longitude: -74.0339,
        total_orders: 2886.84,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Pizza, Italian, Salad, Gluten Free, Dessert, Delicious, American, Perfect, Authentic, Casual',
        annual_dol: 3459.05,
        description:
            'This colorful, chandelier-lit bistro offers classic Italian cuisine & seafood, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9661,
        total_orders: 181.13,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Beer, Friendly, Casual, Lively, Local, Great, Rooftop',
        annual_dol: 836.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.9692,
        total_orders: 100.21,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Beer, Spicy, Margarita, Delicious, Irish, Fun, Smoky, American, Friendly',
        annual_dol: 409.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.9946,
        total_orders: 1560.61,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Flavorful, Premium, Sophisticated, Fantastic, Great, Fresh, Creative, Contemporary',
        annual_dol: 1714.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9802,
        total_orders: 1336.75,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Vegetarian, Vegan, Tasty, Chinese, Delicious, Spicy, Friendly, Asian, Casual, Local',
        annual_dol: 1424.59,
        description:
            'Cozy, hip spot featuring Chinese dry pot with a choice of spice level, plus appetizers & dim sum.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9846,
        total_orders: 2620.63,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Salad, Tacos, Cajun, Bbq, Beer, Italian, Southern, Creole, Friendly, Sweet',
        annual_dol: 2676.24,
        description:
            'Long-standing BBQ operation turning out classic meat dishes, plus sides & craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6979,
        store_longitude: -73.9917,
        total_orders: 622.88,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Roast Turkey, Milkshake, Canadian, Apple Pie, Bbq, Swiss',
        annual_dol: 737.69,
        description:
            'Family-owned diner at the foot of the Brooklyn Bridge offers standard fare from pies to omelettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7584,
        store_longitude: -73.9929,
        total_orders: 1854.96,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Salad, Italian, Vodka, Hotel, Trendy, Spicy, Modern, Homemade, Chic, Classic',
        annual_dol: 6511.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8631,
        store_longitude: -73.8436,
        total_orders: 189.18,
        neighborhood: 'Pelham Gardens/East Bronx',
        keywords:
            'Lobster, Lobster Tail, Steak, Salad, Shrimp Cocktail, Italian, Pizza, Romantic, Casual, Sweet',
        annual_dol: 198.83,
        description:
            'No-frills, family restaurant focuses on large portions of classic Italian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6828,
        store_longitude: -73.9382,
        total_orders: 539.79,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Vegan, Vegetarian, Salad, Chicken Pot Pie, Coffee, French Toast, French, Delicious, Bbq, Cuban',
        annual_dol: 588.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -74.0009,
        total_orders: 3166.95,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Gluten Free, Bbq, Delicious, Homemade, American, Classic, Perfect, Authentic, Great',
        annual_dol: 3234.15,
        description:
            'Beers & bar snacks are offered along with sports on 17 screens at this collegiate watering hole.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7884,
        store_longitude: -73.7294,
        total_orders: 14.46,
        neighborhood: '',
        keywords: 'Burger, Vegan, Mexican, Trendy, Bbq, Chinese, Wine, Vietnamese, Fun, Thai',
        annual_dol: 211.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7738,
        store_longitude: -73.9844,
        total_orders: 80.91,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Tequila, Vodka, Salad, Coffee, Dessert, Stylish, Margarita, Italian, Delicious',
        annual_dol: 94.31,
        description:
            "Warm, stylish venue inside Lincoln Center's Film Society dishing up Italian fare, cocktails & wine.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.632,
        store_longitude: -73.9478,
        total_orders: 50.32,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Mexican, Tacos, Beer, Burritos, Salad, Wine, Delicious, Local, Casual, Recommended',
        annual_dol: 205.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8029,
        store_longitude: -73.9567,
        total_orders: 155.26,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Tequila, Mexican, Tacos, Beer, Margarita, Steak, Wine, Pizza, Burritos, Rooftop',
        annual_dol: 421.94,
        description:
            'Casual Mexican joint serving up tacos & burritos along with drinks from the bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7668,
        store_longitude: -73.7899,
        total_orders: 103.95,
        neighborhood: 'Bayside/Auburndale/Flushing',
        keywords:
            'Pizza, Meatball, Italian, Shrimp Cocktail, Vodka, Dessert, Coffee, Delicious, Fresh, Casual',
        annual_dol: 128.54,
        description:
            'No-frills pizzeria with many styles of pie, plus a menu of Italian entrees, pasta, heros & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9894,
        total_orders: 821.85,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Salad, Tasty, French, Wine, French Onion Soup, Beer, Delicious, Magical',
        annual_dol: 839.29,
        description:
            'Longtime French bistro serving classic fare from fixed-price menus in an old-world, rustic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9901,
        total_orders: 820.94,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Flavorful, Savory, Caribbean, Sweet, Cuban, Jamaican, Phenomenal, Friendly, Polished, Hotel',
        annual_dol: 851.97,
        description:
            '"Escape the madness of Midtown Manhattan at Jasmine\'s Caribbean Cuisine. Enjoy delectable authentic dishes, flavorful drinks, and great music."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7398,
        store_longitude: -73.9879,
        total_orders: 2060.48,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Korean, Steak, Delicious, Wine, Polished, American, Modern, Exquisite, Classic, Impressive',
        annual_dol: 2952,
        description:
            'Modern setting for multicourse Korean tasting menus featuring wood-fired dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.9991,
        total_orders: 40.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Spacious, Awesome, Amazing, Rooftop, Gracious, Signature',
        annual_dol: 66.37,
        description:
            '"Life Time Sky is more than a gym, it’s an athletic country club. Life Time has something for everyone: an expansive fitness floor, unlimited studio classes, basketball courts, eucalyptus steam rooms, and indoor and outdoor pools. Receive unlimited group training and individualized attention when you choose a Signature Membership. Or get started at home with our Digital Membership, featuring virtual training programs, a library of on-demand class videos and award-winning health and wellness content. Build your membership today."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6896,
        store_longitude: -73.9693,
        total_orders: 1555.41,
        neighborhood: 'Clinton Hill',
        keywords: 'Steak, Salad, Wine, French, Beer, Delicious, Irish, American, Fun, Fresh',
        annual_dol: 1588.41,
        description:
            'Vintage-looking saloon with a large draft beer selection & an expansive menu of bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9913,
        total_orders: 512.89,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Mexican, Pizza, Shrimp Cocktail, Japanese, Korean, Savory, Bbq, American, Asian',
        annual_dol: 2096.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.989,
        total_orders: 230.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vegetarian, Steak, Lobster, Pizza, Chinese, Wine, Beer, Spicy, Delicious, Asian',
        annual_dol: 940.85,
        description:
            'Easygoing restaurant serving an array of dim sum favorites, along with classic Chinese entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.982,
        total_orders: 775.83,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Trendy, Stylish, Elegant, Classic, American, French, Serene, Great',
        annual_dol: 1078.03,
        description:
            "Classic rooms feature soundproof windows, marble baths and high-end linens, plus free Wi-Fi, minibars and flat-screen TVs. Upgraded rooms add turndown service and desks. Suites provide whirlpool baths, additional TVs and living rooms.There's a lively, upscale restaurant serving a new French menu, and a stylish wood-paneled cocktail salon offering small plates. There's also a 24-hour fitness center, in-room massages or yoga, and a business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6905,
        store_longitude: -73.9833,
        total_orders: 226.74,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Wine, Beer, Gourmet, Italian, Dessert, Donut, Delicious, Hotel, Pakistani, Awesome',
        annual_dol: 926.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6775,
        store_longitude: -73.9827,
        total_orders: 214.21,
        neighborhood: 'Park Slope',
        keywords:
            'Italian, Gluten Free, Wine, Spicy, Delicious, Asian, Intimate, Paloma, Awesome, Fusion',
        annual_dol: 262.83,
        description:
            'Serves traditional Sicilian dishes, cured meats & cheeses, plus weekend brunch & Italian wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6782,
        store_longitude: -73.9834,
        total_orders: 1007.14,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Wine, Ale, Cozy, Casual, Vibrant, Awesome, Great, Unpretentious',
        annual_dol: 1050.81,
        description:
            'Unpretentious heavy-metal bar featuring live music, high-energy bartenders & bar-top dancers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6783,
        store_longitude: -73.9731,
        total_orders: 962.67,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Salad, Mexican, Vegetarian, Tasty, Margarita, Wine, Burritos, Tex-Mex, Delicious, Warm',
        annual_dol: 1200.95,
        description:
            'Burritos & margaritas fuel a festive scene at this Park Slope spot with ample outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.9961,
        total_orders: 1681.2,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Steak, Japanese, Sashimi, Spicy, Tasty, Teriyaki, Seasonal, Tempura, Premium',
        annual_dol: 1938.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -73.9975,
        total_orders: 111.47,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Sushi, Salad, Tasty, Gourmet, Wine, Beer, Thai, Malaysian, Delicious, Rich',
        annual_dol: 143.99,
        description:
            'Casual restaurant with a full Japanese menu, including sushi, plus some Malaysian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7118,
        store_longitude: -73.9514,
        total_orders: 821.65,
        neighborhood: 'Williamsburg',
        keywords: 'Coffee, Wine, Velvety, Beer, Rich, Creamy, Hotel, Comfortable, Rum, French',
        annual_dol: 843.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7126,
        store_longitude: -73.9561,
        total_orders: 22.79,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, French Toast, German, French, Swiss, Modern, Creamy, American, Delicious, Classic',
        annual_dol: 93.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7131,
        store_longitude: -73.9574,
        total_orders: 16.73,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Steak, Wine, Californian, Beer, Organic, American, Premium, Natural, Smooth',
        annual_dol: 68.4,
        description:
            'Wine bar from James Murphy of LCD Soundsystem serving small plates in snug digs with light woodwork.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7139,
        store_longitude: -73.9603,
        total_orders: 10.56,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Thai, Salad, Gourmet, Spicy, Asian, Zen, Traditional, Pad Thai, Classic, Papaya Salad',
        annual_dol: 43.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.744,
        store_longitude: -73.9871,
        total_orders: 129.27,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Hotel, Champagne, Wine, Australian, Bright, Gin, Classic, Modern, Great, Excellent',
        annual_dol: 528.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9817,
        total_orders: 3879.1,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Lobster, Gourmet, Chinese, Spicy, Wine, Wonton Soup, Refined, Classy, Kung Pao, Traditional',
        annual_dol: 4146.01,
        description:
            'Refined option for acclaimed Sichuan fare in a vintage-inspired atmosphere, plus delivery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7208,
        store_longitude: -73.9578,
        total_orders: 1586.48,
        neighborhood: 'Williamsburg',
        keywords: 'Luxurious, Fun, Fancy, Vintage, Excellent, Local, Casual',
        annual_dol: 1938.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7167,
        store_longitude: -73.9654,
        total_orders: 43.82,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Beer, Coffee, Wine, Rum, Fun, Irresistible, Cool, Casual, Michelada',
        annual_dol: 179.13,
        description:
            '"Cactus shop by day, Mexican cantina by night. It takes getting lost to find your spirit. A place where culture and flavors collide, The Cactus Shop brings a little piece of Mexico to Williamsburg. From the elevated street food to the disarming decor and irresistible ambiance, The Cactus Shop is sure to set the tone for a new era of blended venues that blur the lines between bar, bistro, and boutique. Find out more about the grand opening of The Cactus Shop on our website."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7748,
        store_longitude: -73.9537,
        total_orders: 1012.14,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Cozy, Fun, Intimate, Perfect, Great, Casual, Local',
        annual_dol: 1103.08,
        description:
            'The Comic Strip Live is the oldest stand-up comedy showcase club in New York City, located at 1568 Second Avenue.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7458,
        store_longitude: -73.9035,
        total_orders: 86.32,
        neighborhood: 'Flushing/Woodside',
        keywords:
            'Sushi, Japanese, Spicy, Ramen, Modern, Gyoza, Traditional, Casual, Tempura, Perfect',
        annual_dol: 96.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8477,
        store_longitude: -73.8446,
        total_orders: 944.69,
        neighborhood: 'Morris Park/East Bronx',
        keywords: 'Italian, Hotel, Trendy, Elegant, Modern, Great, Friendly, Caribbean',
        annual_dol: 985.65,
        description:
            "Stylish, elegant suites feature kitchens, and separate living areas with pull-out sofas, as well as free Wi-Fi, flat-screen TVs and desks.Complimentary perks include a breakfast buffet and parking. There's a trendy lobby with a modern fireplace, plus a fitness center, and a patio featuring a fire pit.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7337,
        store_longitude: -74.0025,
        total_orders: 278.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Tequila, Tacos, Beer, Margarita, Nachos, Quesadillas, Dessert, American, Latin',
        annual_dol: 302.44,
        description:
            'Low-lit Mexican cantina known for inventive fare, creative drinks & happy-hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7256,
        store_longitude: -73.6343,
        total_orders: 274.88,
        neighborhood: '',
        keywords:
            'Tasty, Gluten Free, Sushi, Mexican, Chinese, Delicious, Upscale, Italian, Elegant, Clean',
        annual_dol: 315.33,
        description:
            "Upscale specialties like Peking duck are artfully presented in a mirrored, '80s-era setting.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7877,
        store_longitude: -73.9767,
        total_orders: 1785.4,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Coffee, French Toast, Wine, French, Beer, Italian, Dessert, American, Vintage',
        annual_dol: 1914.62,
        description:
            'Part of a local cafe chain offering drinks, coffee, dessert & French bistro fare 24/7.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8415,
        store_longitude: -73.9412,
        total_orders: 317.02,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Dessert, Ramen, Perfect, Awesome, Great, Fresh, Intimate, Fine, Local',
        annual_dol: 366.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7843,
        store_longitude: -73.9777,
        total_orders: 894.81,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Salad, Beer, Warm, Ale, Fun, Nachos, Friendly, Bbq, Fancy, Irish',
        annual_dol: 954.3,
        description:
            'Basic bar along the Amsterdam Avenue strip frequented by a young crowd for beer & foosball.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.745,
        store_longitude: -73.9069,
        total_orders: 10.81,
        neighborhood: '',
        keywords: 'German, Spanish, Colombian, Local, Casual',
        annual_dol: 44.2,
        description:
            '"Bar para mirar todo tipo de deportes ,escuchar musica ,y pasar un momento grato con familiares y amistades"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7677,
        store_longitude: -73.9594,
        total_orders: 846.35,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Flavorful, Vodka, Italian, Stylish, Upscale, Martini, Spicy, French, Prosecco',
        annual_dol: 883.04,
        description:
            'Italian staples including housemade pasta dishes served in a casual but stylish space with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9694,
        total_orders: 1276.13,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Chinese, Golfclub, Georgian, Coffee, Cosmopolitan, Friendly, Casual',
        annual_dol: 1331.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9577,
        total_orders: 229.47,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Pizza, Italian, Salad, Wine, Dessert, Ale, Modern, Legendary, Awesome, Fresh',
        annual_dol: 258.66,
        description:
            'Informal pizza parlor offering brick-oven pies along with salads, pastas & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7381,
        store_longitude: -73.8862,
        total_orders: 8994.88,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Romantic, Rich, Exotic, Premium, Casual, Signature',
        annual_dol: 9185.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7344,
        store_longitude: -73.9936,
        total_orders: 262.19,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegetarian, Wine, Organic, Dessert, French, American, Casual, Modern, Delicious, Seasonal',
        annual_dol: 273.56,
        description:
            "A New American menu based on farmer's market ingredients served in a modern, woodsy restaurant.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6748,
        store_longitude: -73.9997,
        total_orders: 185.78,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Pizza, Steak, Italian, Wine, Meatball, Beer, Trendy, Prime Rib, Cozy, Ribeye Steak',
        annual_dol: 759.42,
        description: '"Italian restaurant with brick oven pizza , backyard and full bar."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7708,
        store_longitude: -73.8358,
        total_orders: 650.21,
        neighborhood: 'Flushing',
        keywords: 'Clean, Comfortable, Fun, Great, Premium, Fresh, Classic, Nachos',
        annual_dol: 723.42,
        description:
            '"See movies the way they are meant to be seen with Showcase Cinemas. Now showing Black Panther Wakanda Forever, The Menu, She Said, & more. Advanced bookings now open for Avatar The Way of Water Showcase Cinemas Holiday Special - $5 bonus concessions gift card with $25 gift card purchase"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8005,
        store_longitude: -73.6485,
        total_orders: 265.58,
        neighborhood: '',
        keywords:
            'Vegetarian, Steak, Cheesesteak, Salad, Wine, Mexican, Beer, Shrimp Cocktail, Organic, Ale',
        annual_dol: 277.09,
        description:
            'Area-sourced New American cuisine served in a fashionable setting with a waterside patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7113,
        store_longitude: -74.0105,
        total_orders: 1422.86,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Luxurious, Cosmopolitan, Hotel, Elegant, Fabulous, Amazing, American, Excellent, Modern, Chic',
        annual_dol: 1526.91,
        description:
            "National September 11 Memorial & Museum and 9 minutes' walk from Wall Street.Warmly furnished rooms, some with skyline views, offer flat-screen TVs and Wi-Fi and coffeemakers. Suites add sitting areas with pull-out sofas. Room service is available.Amenities include an elegant restaurant with a cocktail lounge, as well as a fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8592,
        store_longitude: -73.5916,
        total_orders: 426.75,
        neighborhood: '',
        keywords: 'Burger, American',
        annual_dol: 455.12,
        description:
            'Piping Rock Club is a country club in Matinecock, New York. It falls within the ZIP Code boundaries of Locust Valley, New York.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6205,
        store_longitude: -74.0321,
        total_orders: 64.78,
        neighborhood: 'Fort Hamilton',
        keywords: 'Pizza, Shrimp Cocktail, Fun, Healthy, Local, Casual, American, Great',
        annual_dol: 332.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7813,
        store_longitude: -73.9491,
        total_orders: 1847.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Wine, Vodka, Beer, Steak, Salad, Avocado Toast, Spicy, Irish, Cozy, Rustic',
        annual_dol: 1847.47,
        description:
            'British pub fare with inventive twists, plus craft beers & cocktails, served in cozy, rustic digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6737,
        store_longitude: -73.9503,
        total_orders: 2449.93,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Vodka, Rum, Sweet, Friendly, Fun, Vintage, Perfect, Cool, Colorful',
        annual_dol: 2667.45,
        description:
            'Casual spot for tiki drinks & frozen cocktails with colorful garnishes, plus a few bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7034,
        store_longitude: -73.9917,
        total_orders: 6133.53,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Italian, Hotel, Mediterranean, Modern, Classic, American, Authentic, Outstanding, Casual, Recommended',
        annual_dol: 6319.84,
        description:
            'Wood-fired pizzas, pastas & other Italian dishes are complemented by cocktails & waterfront views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9845,
        total_orders: 2989.38,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Glamorous, Hotel, Polished, Premium, Superb, Urban, Great, Modern, Chic, Friendly',
        annual_dol: 3118.99,
        description:
            "The polished rooms have Wi-Fi (fee), flat-screen TVs with premium channels, and desks with ergonomic chairs, plus minifridges and coffeemakers. Club rooms and suites come with access to a lounge serving free breakfast and snacks. Suites also add separate living rooms with pull-out sofas.There's a chic restaurant and a lounge with views of Times Square. Other amenities include an exercise room, a business center and 5 meeting rooms. Parking is available for a fee.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7401,
        store_longitude: -73.9825,
        total_orders: 444.07,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Hotdog, Vegan, Salad, Beer, Wine, Tasty, Whiskey, Delicious, Irish, Friendly',
        annual_dol: 472.57,
        description:
            'Chill, UK-inspired tavern featuring classic British comfort grub, beer & a small grocery store.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6624,
        store_longitude: -73.6993,
        total_orders: 53.92,
        neighborhood: '',
        keywords: 'Burger, Beer, Friendly, Local, Premium, Casual, Great',
        annual_dol: 75.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9925,
        total_orders: 25767.59,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Wine, Italian, Bourbon, Rooftop, Sophisticated, Lively, Bbq, Casual',
        annual_dol: 60184.83,
        description:
            "walk from the Empire State Building.Polished rooms provide free Wi-Fi, flat-screens, and tea and coffeemakers; upgraded rooms add city views. Suites have separate living rooms, plus minifridges. Room service is available.Breakfast is offered for a fee. There's a casual Italian restaurant, an airy lobby lounge, and a 24-hour gym. A rooftop terrace with a lounge features city views. A ballroom and 7,326 sq ft of meeting space are available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -73.9931,
        total_orders: 474.48,
        neighborhood: 'Bowery',
        keywords:
            'Tequila, Champagne, Mexican, Flavorful, Margarita, Savory, Delicious, Nachos, Fun, Exotic',
        annual_dol: 596.9,
        description:
            '"Located in the Lower East Side, Tampico Tequila bar brings to life the sentiments of travel by presenting fun takes on traditional cocktails and a menu inspired by both Mexican taquerias and NYC food trucks."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.9915,
        total_orders: 1462.2,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Hotdog, Cheesesteak, Beer, Martini, Tasty, Bbq, Philly Cheesesteak, American, Fun',
        annual_dol: 4847.3,
        description:
            'Budget pub grub & brews are served at this bustling bar with TVs for watching sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9881,
        total_orders: 596.43,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Shrimp Cocktail, South American, Flavorful, Wine, Upscale, Organic, American, Ribeye Steak, Latin',
        annual_dol: 694.2,
        description:
            'Upscale place for South American steaks & other Latin dishes, with a patio & sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9818,
        total_orders: 591.75,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Burger, Salad, Wine, Beer, French, Irish, American, Eclectic, Healthy',
        annual_dol: 2240.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7631,
        store_longitude: -73.98,
        total_orders: 2679.73,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Glamorous, Iconic, Fabulous, Perfect, Chill, Heritage, Artistic, Mouth-Watering',
        annual_dol: 2857.87,
        description:
            '"Welcome to Ziegfeld Ballroom, New York City’s premier special events venue. Conjuring the elegance and grandeur of the 1920s movie palaces that once lined Broadway and Seventh Avenue, Ziegfeld Ballroom is a Manhattan mecca for society galas and corporate events. Our Art Deco-inspired space is as iconic as the historic theatre from which it takes its name: both steeped in heritage and equipped with cutting edge technology, Ziegfeld Ballroom is the intersection of glamorous days-gone-by and of-the-moment modernity."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7328,
        store_longitude: -73.9972,
        total_orders: 17399.34,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Whiskey, Wine, Beer, Bourbon, Tasty, Gin, Stylish, Delicious, American, Classy',
        annual_dol: 18652.03,
        description:
            'Atmospheric watering hole serving cocktails & nibbles in a sultry, sophisticated setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7869,
        store_longitude: -73.814,
        total_orders: 497.01,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Salad, Shrimp Cocktail, Italian, Chinese, Friendly, Fresh, Amazing, Wonderful, Caribbean, Southern',
        annual_dol: 624.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6791,
        store_longitude: -73.9737,
        total_orders: 506.35,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Burger, Salad, Nachos, Funky, Homemade, Sweet, Bruschetta, Fantastic, Excellent, Great',
        annual_dol: 1907.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.9958,
        total_orders: 128.76,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Salad, Coffee, Savory, Korean, Delicious, Spicy, Yummy, Sweet, Fun, Perfect',
        annual_dol: 526.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.6923,
        total_orders: 660.57,
        neighborhood: '',
        keywords:
            'Tacos, Tequila, Mexican, Steak, Vegetarian, Salad, Nachos, Margarita, Cuban, Mimosa',
        annual_dol: 803.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6308,
        store_longitude: -74.1021,
        total_orders: 11.39,
        neighborhood: 'Mid Island/West Brighton',
        keywords: 'Salad, Wine, Tasty, Italian, Mexican, Beer, Asian, American, Classic, Casual',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7115,
        store_longitude: -73.9491,
        total_orders: 5.36,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Italian, Flavorful, Salad, Meatball, Vodka, Beer, Delicious, Wine, Shrimp Scampi',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9244,
        total_orders: 851,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Cheesecake, Chocolate Cake, Pizza, Salad, Steak, Coffee, Wine, Dessert, Beer, Savory',
        annual_dol: 956.94,
        description:
            'Local Greek chain with a late-night scene doling out traditional sweets & savory dishes since 1977.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9862,
        total_orders: 79.09,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, Vodka, Beer, Coffee, Martini, Margarita, Delicious, Hotel, Wholesome, Clean',
        annual_dol: 323.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.9789,
        total_orders: 8846.65,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Coffee, Salad, Pizza, Hotel, Elegant, Sustainable, Eclectic, Innovative, Iconic',
        annual_dol: 34494.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9063,
        store_longitude: -73.9041,
        total_orders: 590.88,
        neighborhood: 'North Riverdale',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Whiskey, Mexican, Italian, French, Dessert, French Onion Soup, American',
        annual_dol: 621.04,
        description:
            'Established Irish bar & eatery with a warm, old-world vibe providing steak, fish & Sunday brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.9871,
        total_orders: 1152.45,
        neighborhood: 'Lower East Side',
        keywords:
            'Mexican, Salad, Beer, Nachos, Italian, Delicious, Japanese, Elegant, American, Greek',
        annual_dol: 1200.68,
        description:
            'Stalactites hang from the ceiling at this cavelike basement dance club with hookahs at the bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7182,
        store_longitude: -73.9899,
        total_orders: 192.91,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegan, Cheesecake, Wine, Pizza, Vegetarian, Delicious, American, Premium, Vibrant, Memorable',
        annual_dol: 677.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.693,
        store_longitude: -73.9703,
        total_orders: 50.32,
        neighborhood: 'Clinton Hill',
        keywords: 'Mexican, Tacos, Salad, Organic, Casual, Healthy, Local, High-Protein, Signature',
        annual_dol: 328.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.632,
        store_longitude: -73.5815,
        total_orders: 151.05,
        neighborhood: '',
        keywords:
            'Burger, Cheesecake, Dessert, Delicious, Bourbon, Fun, Hurricane, Clean, Fresh, Southern',
        annual_dol: 462.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7328,
        store_longitude: -73.9973,
        total_orders: 787.75,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Cheesecake, Burger, Salad, Vegetarian, Wine, Italian, Trendy, Upscale, Delicious, Seasonal',
        annual_dol: 1201.8,
        description:
            'Upscale-casual American restaurant for seasonal, wood-fired dishes, handmade pastas & pizzas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6786,
        store_longitude: -73.9787,
        total_orders: 265.73,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Meatball, Vegan, Greek, Flavorful, Wine, Mediterranean, Prosecco, American, Fusion',
        annual_dol: 394.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8238,
        store_longitude: -73.8947,
        total_orders: 21.63,
        neighborhood: 'Foxhurst',
        keywords: 'Mexican, Dominican, Dessert, Creamy, American, Casual, Latin, Authentic',
        annual_dol: 88.4,
        description: '"Bar & Grill Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7182,
        store_longitude: -73.9452,
        total_orders: 2401.15,
        neighborhood: 'Williamsburg',
        keywords: 'Whiskey, Beer, Premium, American, Tasty, Awesome, Great, Authentic',
        annual_dol: 2424.95,
        description:
            'Comfy, stand-up spot serving brown spirits & cocktails with grilled cheese & snacks until late.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.6922,
        total_orders: 131.64,
        neighborhood: '',
        keywords: 'Hamburger, American, Casual, Traditional, Great',
        annual_dol: 165.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7064,
        store_longitude: -74.0031,
        total_orders: 15511.12,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Italian, Dessert, Rich, American, Memorable, Iconic, Fresh, Fine, Casual',
        annual_dol: 63404.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6799,
        store_longitude: -73.9683,
        total_orders: 689.21,
        neighborhood: 'Prospect Heights',
        keywords:
            'Burger, Garlic Bread, Meatloaf, Salad, Mexican, Shrimp Cocktail, Beer, Wine, Italian, American',
        annual_dol: 734.99,
        description:
            "\"Patti Ann’s is the latest neighborhood restaurant from chef-owner Greg Baxtrom (of acclaimed Olmsted and Maison Yaki). Chef Greg marries the nostalgic Midwestern comfort food of his childhood with the fine-dining techniques learned throughout his career to offer a menu that's totally different from his other restaurants. At Patti Ann's (an homage to Greg's mother, Patti Ann) you'll find everything from a Blooming Onion (served with fancy ranch dressing) to Baked Mostaccioli on the menu. In addition, there is a Bakery and General Store attached which houses your favorite farmer's market staples along with fresh baked bread, pastries, and coffee.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7438,
        store_longitude: -73.9848,
        total_orders: 1747.93,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Burger, Tacos, Meatball, Beer, Salad, Bourbon, Juicy, French, American, Caesar Salad',
        annual_dol: 1790.61,
        description:
            'Snug, busy bar offering finger foods, burgers & tacos, plus cocktails, beer & a variety of whiskies.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9236,
        total_orders: 3085.51,
        neighborhood: 'Astoria/LIC',
        keywords: 'Wine, Italian, Trendy, Fun, Intimate, Friendly, Awesome, Great, Casual',
        annual_dol: 3823.78,
        description:
            'Festive gay bar featuring DJs, go-go dancers & drag shows, plus movie & game nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.745,
        store_longitude: -73.9851,
        total_orders: 3905.02,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Pizza, Korean, Wine, Instagrammable, American, Asian, Hotel, Impeccable, French, Seasonal',
        annual_dol: 4213.81,
        description:
            'Intimate eatery spotlighting reimagined provisions with a Korean twist, plus a large wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -73.9821,
        total_orders: 686.82,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Beer, Gourmet, Irish, Korean, Bbq, Homemade, Thai, Traditional, British, Local',
        annual_dol: 705.33,
        description:
            'Old-world taproom offering bar food & craft beers, plus a gourmet market with Irish & British goods.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9296,
        total_orders: 780.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Champagne, Wine, Sushi, Beer, Eclectic, Latin, Intimate, Fusion, Exquisite, Fun',
        annual_dol: 3188.74,
        description:
            'DJs spin eclectic grooves at this colorful, Middle-Eastern themed cocktail & hookah lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9807,
        total_orders: 4651.81,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Champagne, Warm, Cozy, Fun, Friendly, French, Cool, Irish, Fresh, Great',
        annual_dol: 4764.42,
        description:
            'Lounge with a contemporary feel dishing up sandwiches & pub food for a Midtown after-work crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.978,
        total_orders: 290.98,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Elegant, High-End, Iconic, Premium, Classic, Sophisticated, Great, Perfect, Exceptional',
        annual_dol: 317.13,
        description:
            '"Convene at 75 Rockefeller Plaza is a premium meeting and event space located in the iconic Rockefeller Center in Midtown Manhattan. This location offers on-demand meeting, event and flexible workspace powered by state-of-the-art technology and best-in-class hospitality. Beautifully-designed and inspired by the original art deco style of the building, it incorporates modern accents to evoke an intimate and elegant setting to work and meet."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7194,
        store_longitude: -73.9935,
        total_orders: 6557.69,
        neighborhood: 'Bowery',
        keywords: 'Wine, Sweet, Cozy, Beer, Perfect, Lively, Great, Lit, Casual, Unique',
        annual_dol: 6698.32,
        description:
            'Music & assorted knickknacks enliven this underground, off-the-beaten-path barroom.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7227,
        store_longitude: -73.9889,
        total_orders: 55.4,
        neighborhood: 'Lower East Side',
        keywords:
            'Cuban, Dessert, Homemade, Colombian, American, Swiss, Fusion, Fancy, Local, Casual',
        annual_dol: 363.31,
        description:
            '"Mi Salsa Kitchen offers authentic Cuban Cuisine and Latin Cuisine, great drinks and friendly staff. Live Music Tuesday to Sunday. Great for groups."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6813,
        store_longitude: -73.977,
        total_orders: 1098.33,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Beer, Whiskey, Wine, Delicious, Coffee, Spacious, Yummy, Homemade, Fun',
        annual_dol: 1470.44,
        description:
            'Comfy tavern offering a full bar, craft beer on tap & pub grub, with multiple TVs for sports fans.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7321,
        store_longitude: -73.9997,
        total_orders: 76.14,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegan, Vegetarian, Wine, Beer, Gluten Free, Elegant, American, Friendly, Casual, Premium',
        annual_dol: 82.98,
        description:
            'Locally sourced, seasonal ingredients abound on the American menu served at this townhouse-set spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7112,
        store_longitude: -73.9475,
        total_orders: 3739.44,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Vodka, Whiskey, Wine, Beer, Ale, Upscale, Great, Chill',
        annual_dol: 3858.9,
        description:
            'Ironically named bar featuring cheap drinks, a photo booth & a six-hole mini-golf course.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7253,
        store_longitude: -73.9843,
        total_orders: 1465.03,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Wine, Whiskey, Beer, Trendy, Spicy, Thai, Mediterranean, Classy, Homemade',
        annual_dol: 1903.08,
        description:
            'This gypsy-themed lounge hosts a global roster of performers, ranging from jazz & funk to hip-hop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7078,
        store_longitude: -73.9557,
        total_orders: 2216.6,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Iranian, Tasty, Trendy, Delicious, Moroccan, Modern, Perfect, Mediterranean, Authentic, Cool',
        annual_dol: 2362.26,
        description:
            'Iranian-style small plates & signature cocktails in a trendy tapas bar with a rear garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.642,
        store_longitude: -74.0774,
        total_orders: 710.17,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Hotel, Comfortable, Great, Incredible, Cultural, Complex, Local',
        annual_dol: 756.84,
        description:
            'The St. George Theatre is a performing arts venue, picture palace, and office complex at 35 Hyatt Street in St. George, on the North Shore of Staten Island, New York City. The 2,800-seat St. George Theatre was built for Staten Island theater operator Solomon Brill and opened on December 4, 1929.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.921,
        total_orders: 5074.19,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Beer, Mediterranean, Homemade, Local, Traditional, Casual, Greek, Middle Eastern, Great',
        annual_dol: 8613.44,
        description:
            'This bakery-cafe serving Balkan specialties is known for its phyllo pastries & ethnic entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.957,
        total_orders: 431.23,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Coffee, Burger, Wine, Beer, Italian, Australian, American, Hotel, Modern, Eclectic',
        annual_dol: 1762.74,
        description:
            '"An approachable dining destination in an elevated environment. Serving speciality coffee and brunch dishes inspired by Australian coastal towns through the day and a cocktail driven dinner destination at night, Isla & Co. draws influences from Australia, Europe and South East Asia"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7744,
        store_longitude: -73.964,
        total_orders: 757.47,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Dessert, Upscale, French, Hotel, Warm, Premium, Eclectic, Exquisite, Seasonal',
        annual_dol: 2659.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9603,
        total_orders: 43.36,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Beer, Dessert, Chicken Tikka Masala, Urban, Contemporary, Tandoori Chicken, Modern, Aloo Gobi, Indian',
        annual_dol: 88.62,
        description: 'Modern spot with an enclosed patio for regional Indian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7033,
        store_longitude: -73.9427,
        total_orders: 141.27,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Coffee, Beer, Wine, American, Fun, Lively, Fantastic, Creative, Cool, Great',
        annual_dol: 197.91,
        description:
            '"Rise Radio is a bar café, restaurant, event space and live-streaming platform. We are a community hub & creative platform dedicated to the arts, culture, entertainment, wellness, food & more."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6621,
        store_longitude: -73.7353,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Burger, Lobster, Tacos, Bbq, Delicious, Rum, Moscow Mule',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6756,
        store_longitude: -74.0169,
        total_orders: 7317.16,
        neighborhood: 'Red Hook',
        keywords:
            'Wine, Quaint, Friendly, Awesome, Cool, Fantastic, Amazing, Great, Western, Quirky',
        annual_dol: 7472.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7494,
        store_longitude: -73.9551,
        total_orders: 1904.79,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Steak, Hotdog, Beer, Italian, Wine, American, Dutch, Contemporary, Pita Bread',
        annual_dol: 2275.38,
        description:
            'This unpretentious, compact waterside joint offers a simple menu of burgers, hot dogs & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6498,
        store_longitude: -73.7832,
        total_orders: 309.7,
        neighborhood: 'Jamaica',
        keywords: 'Lobster, Pizza, Wine, Salad, Beer, Martini, Rich, Hotel, Upscale, Rum',
        annual_dol: 1265.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7343,
        store_longitude: -73.8682,
        total_orders: 2872.43,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Steak, Burger, Bourbon, Italian, Beer, Bbq, Margarita, American, Juicy, Legendary',
        annual_dol: 2965.71,
        description:
            'Casual steakhouse chain known for grilled beef & other American dishes in a ranch-style space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7378,
        store_longitude: -73.9917,
        total_orders: 196.03,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Italian, Korean, Cozy, Homemade, Homey, Bruschetta, Casual, American, Local, Natural',
        annual_dol: 204.53,
        description:
            'Pasta is made fresh all day at this easygoing Italian eatery preparing traditional plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6396,
        store_longitude: -73.9676,
        total_orders: 3280.9,
        neighborhood: 'Flatbush',
        keywords: 'Tequila, Whiskey, Beer, Wine, American, Classic, Friendly, Amazing, Cool, Great',
        annual_dol: 3350.51,
        description:
            'Quirky bar tucked behind a florist shop pouring a selection of bourbons in a space with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8793,
        store_longitude: -73.8432,
        total_orders: 146.71,
        neighborhood: 'East Bronx/Edenwald',
        keywords: 'Tasty, Caribbean, Jamaican, Friendly, Local, Casual, Great',
        annual_dol: 359.43,
        description: '"Caribbean Cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9729,
        total_orders: 2326.91,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegan, Vegetarian, Dairy Free, Salad, Shrimp Cocktail, Gluten Free, Beer, Organic, Tasty, American',
        annual_dol: 2427.79,
        description:
            'Sleek-but-casual bistro offering up hearty New American fare & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.9677,
        total_orders: 181.76,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Champagne, Wine, Tequila, Upscale, Margarita, Bourbon, Elegant, Hotel, Spacious, Rum',
        annual_dol: 788.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5838,
        store_longitude: -73.949,
        total_orders: 184.23,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Salad, Shrimp Cocktail, Tasty, Coffee, Italian, Mediterranean, Delicious, Turkish, Authentic, Traditional',
        annual_dol: 204.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9723,
        total_orders: 16267.12,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Wine, Upscale, Rich, Salad, Dessert, Elegant, Trendy, Legendary, Warm',
        annual_dol: 17616.7,
        description:
            'Steakhouse in an iconic Seagram Building setting with midcentury style, classic dishes & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.9682,
        total_orders: 2878.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Chinese, Peking Duck, Fiery, Ramen, Luxurious, Elegant, Contemporary, Chic, Artisanal',
        annual_dol: 3278.12,
        description:
            'Fine-dining destination for Northern Chinese plates set in an elegant Art Deco-inspired space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9631,
        total_orders: 323.59,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Pizza, Flavorful, Japanese, Asian, Cozy, Sashimi, Modern, Hotel',
        annual_dol: 496.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.971,
        total_orders: 1150.25,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Wine, Upscale, Dessert, Italian, Premium, Classic, Exceptional, Great',
        annual_dol: 1280.42,
        description:
            'Warm, white-tablecloth eatery offering a kosher menu with Italian influences in wood-hued environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6901,
        store_longitude: -73.9067,
        total_orders: 2984.46,
        neighborhood: 'Bushwick',
        keywords:
            'Hamburger, Coffee, Tasty, Beer, Breakfast Burrito, Warm, American, French, Great, Casual',
        annual_dol: 3078.05,
        description:
            'Modern, low-key spot for coffee, updated comfort food, draft beer, cocktails & an artsy patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9919,
        total_orders: 1688.38,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Steak, Beer, Whiskey, Wine, Irish, American, Hurricane, Fun, Yummy',
        annual_dol: 2414.34,
        description:
            'Irish-style sports bar on 2 levels offering comfort food, brews & game viewing on giant projectors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9809,
        total_orders: 55.26,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Hotel, Legendary, Vintage, Classic, Spanish, Perfect, Exceptional, Authentic, Urban, Chic',
        annual_dol: 231.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9873,
        total_orders: 620.11,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Italian, Korean, Bbq, Wine, Modern, Fusion, Innovative, Friendly, Memorable, Perfect',
        annual_dol: 2534.82,
        description:
            'Offshoot of a long-standing Seoul restaurant specializing in Korean BBQ, dishes & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7736,
        store_longitude: -73.984,
        total_orders: 74.99,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Hotel, American, Rich, Irish, Seasonal, Dynamic, Fantastic, Cultural, Complex',
        annual_dol: 306.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6351,
        store_longitude: -73.9612,
        total_orders: 1630.1,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Rusty Nail, Beer, Korean, Wine, Ramen, Asian, Fusion, Awesome, Hip, Great',
        annual_dol: 2102.09,
        description:
            'Hip tavern with a tin ceiling & backlit bar, plus draft beers & a casual menu of quick bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9164,
        total_orders: 238.26,
        neighborhood: 'Bushwick',
        keywords: 'Caribbean, Rum, Jamaican, Warm, Cozy, Healthy, American, Fusion, Fresh, Casual',
        annual_dol: 284.22,
        description:
            'Casual hangout offering Caribbean plates, rum cocktails & frozen drinks, plus a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7299,
        store_longitude: -74.0103,
        total_orders: 1434,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Champagne, Wine, Beer, Hotel, Crisp, Casual, Polish, Modern, American, Incredible',
        annual_dol: 4681.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6484,
        store_longitude: -74.0071,
        total_orders: 0,
        neighborhood: 'Sunset Park',
        keywords:
            'Hamburger, Salad, Italian, Cuban, Dominican, Spanish, Thai, American, Latin, Casual',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.664,
        store_longitude: -73.5521,
        total_orders: 1386.5,
        neighborhood: '',
        keywords: 'Burger, Pizza, Italian, Sushi, Salad, Mexican, Delicious, Beer, Asian, Fun',
        annual_dol: 1461.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7032,
        store_longitude: -73.9032,
        total_orders: 323.23,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Vegan, Wine, Cheesesteak, Beer, Steak, Coffee, Cornbread, Salad, Ale, Pizza',
        annual_dol: 832.92,
        description:
            'Mellow cafe offering local beer, organic wine, cheese plates, charcuterie & Sunday brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6632,
        store_longitude: -73.9849,
        total_orders: 3218.69,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Pizza, Hamburger, American, Beer, Wine, Modern, Thai, Casual, Delicious, Local',
        annual_dol: 3286.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.9701,
        total_orders: 732.44,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Sushi, Wine, French, Cozy, Delicious, Comfortable, Modern, Casual, Welcoming',
        annual_dol: 779.45,
        description:
            'Revival of a longtime restaurant for traditional French fare in a setting with dark woods & mirrors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7007,
        store_longitude: -73.9301,
        total_orders: 476.69,
        neighborhood: 'Bushwick',
        keywords: 'Hotdog, Beer, Delicious, American, Cozy, Hot Toddy, Urban, Awesome, Great, Fine',
        annual_dol: 542.92,
        description:
            'Chill local tavern & happy-hour spot featuring a roomy back patio, hot dogs & a popcorn machine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6978,
        store_longitude: -73.9271,
        total_orders: 178.28,
        neighborhood: 'Bushwick',
        keywords:
            'Vegetarian, Salad, Coffee, Wine, Italian, English Muffin, French, Bbq, American, Homemade',
        annual_dol: 323.2,
        description:
            'Eclectic New American spot for creative eats, espresso & craft cocktails in chic surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7013,
        store_longitude: -73.8868,
        total_orders: 71.29,
        neighborhood: 'Flushing/Glendale',
        keywords:
            'Steak, Hamburger, Vegan, Karaoke, Chinese, Cosmopolitan, Moscow Mule, Gin, Hotel, Caipirinha',
        annual_dol: 268.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7629,
        store_longitude: -73.984,
        total_orders: 6798.34,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegan, Pizza, Gluten Free, Flavorful, Beer, Wine, Avocado Toast, Salad, Tex-Mex, Warm',
        annual_dol: 6837.04,
        description:
            'Hip, brick-lined locale with vintage accents for creative cocktails, modern pub plates & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7655,
        store_longitude: -73.9873,
        total_orders: 24417.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tacos, Mexican, Beer, Tasty, Gourmet, Spicy, Delicious, American, Friendly, Spacious',
        annual_dol: 29387.04,
        description:
            'Spacious cantina dishing up gourmet tacos, ceviche & other Mexican plates, plus tequilas & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8087,
        store_longitude: -73.9445,
        total_orders: 1832.62,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Meatloaf, Southern Comfort, Southern, Delicious, Casual, African, Sweet, American, Authentic',
        annual_dol: 1871.5,
        description:
            'Harlem landmark serving generous helpings of Southern comfort food since 1962.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6902,
        store_longitude: -73.8636,
        total_orders: 648.67,
        neighborhood: 'Jamaica/Woodhaven',
        keywords: 'Burger, Dessert, Beer, Quaint, Ale, Cozy, Clean, Friendly, Casual, Bbq',
        annual_dol: 710.31,
        description:
            'This unpretentious watering hole open since 1829 has been featured in several films.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7221,
        store_longitude: -73.9884,
        total_orders: 15409.99,
        neighborhood: 'Lower East Side',
        keywords: 'Sushi, Wine, Beer, Spicy, Trendy, Ale, Delicious, Meatball, Japanese, Hotel',
        annual_dol: 16172.18,
        description:
            'Vast Japanese menu of sushi & small plates plus sake & cocktails in the trendy Sixty LES Hotel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.9856,
        total_orders: 1250.74,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Tequila, Vodka, Whiskey, Beer, Lemon Drop, Scotch, Gin, Green Tea Shot, Rum, Upscale',
        annual_dol: 1332.93,
        description:
            '"Drawing inspiration from the creative energy and vivid history of downtown Manhattan, Writing On The Wall is a restaurant and dance lounge in the Lower East Side."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -73.9885,
        total_orders: 364.96,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Steak, Vegetarian, Pizza, Salad, Chicken Parmigiana, Tasty, Italian, Wine, Spicy',
        annual_dol: 397.17,
        description:
            'Corner Italian destination known for its thin-crust pizzas, homemade pastas & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6884,
        store_longitude: -73.983,
        total_orders: 0,
        neighborhood: 'Downtown Brooklyn',
        keywords:
            'Korean, Shrimp Cocktail, Beer, Wine, Delicious, Bourbon, Elegant, Bbq, Asian, Fun',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5791,
        store_longitude: -74.1636,
        total_orders: 1687.37,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Steak, Burger, Salad, Gourmet, Delicious, Beer, Juicy, French, French Onion Soup, Ale',
        annual_dol: 1922.16,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6823,
        store_longitude: -73.963,
        total_orders: 68.88,
        neighborhood: 'Clinton Hill',
        keywords:
            'Vodka, Steak, Nigerian, Spicy, African, Fusion, Modern, Authentic, Mojito, Lively',
        annual_dol: 124.86,
        description:
            'Compact eatery with a candlelit dining room & lively bar specializing in aromatic Nigerian cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7224,
        store_longitude: -73.9829,
        total_orders: 726.08,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster, Vegan, Beer, Spanish, French Toast, Mediterranean, Legendary, French, Elegant, Innovative',
        annual_dol: 849.96,
        description:
            'This casual tapas spot serves house sangria & occasional live music with its Spanish plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.6884,
        total_orders: 8645.42,
        neighborhood: '',
        keywords:
            'Steak, Tacos, Salad, Wine, Beer, South American, French Toast, Trendy, Delicious, French',
        annual_dol: 8916.19,
        description:
            'Vibrant local watering hole with sidewalk seating fixing up American-style tapas & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6801,
        store_longitude: -73.9682,
        total_orders: 6896.44,
        neighborhood: 'Prospect Heights',
        keywords:
            'Mexican, Tacos, Tequila, Margarita, Trendy, Breakfast Burrito, Stylish, Homey, Contemporary, Innovative',
        annual_dol: 7368.31,
        description:
            'Tacos, Mexican small plates & shareable main dishes in an energetic setting with communal seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7243,
        store_longitude: -73.9785,
        total_orders: 117.55,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Hotdog, Burger, Mexican, Shrimp Cocktail, Tasty, American, Vibrant, Fun, Prime Rib, Casual',
        annual_dol: 128.11,
        description: '"Cocktails fun jammers nice folks"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.722,
        store_longitude: -73.9597,
        total_orders: 1417.26,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Japanese, Dessert, Jamaican, Caribbean, Asian, Rum, Modern, Classy, Premium',
        annual_dol: 2394.74,
        description:
            'Hip, bohemian restaurant serving specialty cocktails & traditional Caribbean cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 2714.81,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Sushi, Shrimp Cocktail, Delicious, Thai, Romantic, Modern, Fun, American, Classic',
        annual_dol: 3016.56,
        description:
            'Grilled meats, seafood & English breakfast in a sleek, contemporary space with a bar & big windows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9852,
        total_orders: 2019.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Wine, Beer, Hotel, Friendly, Intimate, Classic, Great',
        annual_dol: 2152.18,
        description:
            "The Eugene O'Neill Theatre, previously the Forrest Theatre and the Coronet Theatre, is a Broadway theater at 230 West 49th Street in the Theater District of Midtown Manhattan in New York City. The theater was designed by Herbert J. Krapp and was constructed for the Shubert brothers.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7638,
        store_longitude: -73.9885,
        total_orders: 482.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tacos, Mexican, Lobster, Beer, Margarita, Wine, Upscale, Quesadillas, Fajitas, Bbq',
        annual_dol: 525.87,
        description:
            'Unexpected ingredients like lobster appear in the upscale Mexican dishes at this cool locale.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9774,
        total_orders: 1292.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Sushi, Salad, Milkshake, Japanese, Tasty, Whiskey, Upscale, Hotel, Modern',
        annual_dol: 5281.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6818,
        store_longitude: -73.9762,
        total_orders: 473.57,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Garlic Bread, Chicken Parmigiana, Salad, Vodka, Beer, Italian, Wine, Dessert, Veal Parmigiana',
        annual_dol: 484.97,
        description:
            'Local pizzeria chain serving brick-oven pies, pasta & calzones in a relaxed setting since 1933.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7556,
        store_longitude: -73.9791,
        total_orders: 772.95,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Gluten Free, Wine, Upscale, Delicious, Elegant, Hotel, Martini',
        annual_dol: 3159.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9839,
        total_orders: 6867.29,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Hotdog, Delicious, Friendly, Iconic, Cool, Great, Casual, Tradition, Signature, Unique',
        annual_dol: 21412.68,
        description:
            'Patrons who enter through a phone booth in Crif Dogs next door sip novel cocktails in a dark space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.678,
        store_longitude: -73.964,
        total_orders: 1315.31,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Wine, Gourmet, Friendly, Irish, Casual, Great',
        annual_dol: 1372.34,
        description:
            'Down-to-earth hangout featuring sports on TV, pub grub & a backyard area with a pool table & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5942,
        store_longitude: -74.1867,
        total_orders: 651.8,
        neighborhood: 'Travis - Chelsea/Mid Island',
        keywords:
            'Steak, Garlic Bread, Burger, Shrimp Cocktail, Salad, Tequila, Bourbon, Italian, Ale, Spicy',
        annual_dol: 742.36,
        description:
            'Old-school steakhouse offering meats, seafood & pastas in a warm, paneled space with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9771,
        total_orders: 26.19,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Mexican, Shrimp Cocktail, Danish, Caribbean, Rum, Spicy, American, Latin, Casual',
        annual_dol: 107.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7493,
        store_longitude: -73.9774,
        total_orders: 4421.44,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Burger, Salad, Beer, Italian, Nachos, Cozy, Irish, Hotel, French, Homemade',
        annual_dol: 4614.11,
        description:
            'Midtown residents and Grand Central commuters frequent this sports bar for casual beers & pub food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9843,
        total_orders: 628.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords:
            'Burger, Beer, Irish, Hotel, Crisp, Homemade, Friendly, Traditional, Classic, Authentic',
        annual_dol: 3813.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7718,
        store_longitude: -73.9533,
        total_orders: 817.3,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Salad, Beer, Wine, Dessert, Bbq, Relaxed, Casual, American',
        annual_dol: 853.89,
        description:
            'Pub grub & several kinds of wings dominate the menu, plus budget beers & plenty of games on the TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7748,
        store_longitude: -73.9571,
        total_orders: 264.86,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Lasagna, Salad, Dessert, Italian, Chicken Parmigiana, Delicious, Elegant, Cozy, Polished, Homemade',
        annual_dol: 349.48,
        description: 'Straightforward Italian fare from a longtime local standby.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7353,
        store_longitude: -74.0031,
        total_orders: 1863.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Steak, Lasagna, Italian, Chilean, Meatball, Mediterranean, French, Greek, American',
        annual_dol: 1905.95,
        description:
            'Coastal fare from Italy & France is well represented at this busy bistro with sidewalk tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -74.0055,
        total_orders: 1031.3,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Wine, Beer, Stylish, Chinese, Delicious, French, Asian, Contemporary, Refined',
        annual_dol: 1231.95,
        description:
            'French techniques meet Chinese & Himalayan flavors at this refined eatery inside a minimalist space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8419,
        store_longitude: -73.8434,
        total_orders: 37.36,
        neighborhood: 'Westchester Square',
        keywords: 'Pizza, Beer, Tasty, Nachos, Delicious, Latin, Cool, Great, Casual, Local',
        annual_dol: 91.38,
        description: '"Best grown and sexy local neighborhood bar and grill lounge...."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7892,
        store_longitude: -73.97,
        total_orders: 474.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Chocolate Mousse, Italian, Chicken Parmigiana, Dessert, Wine, Juicy, Outstanding, Iconic, Excellent, Casual',
        annual_dol: 497.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8344,
        store_longitude: -73.9411,
        total_orders: 16.38,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Burger, Mexican, Coffee, Chinese, Japanese, American, Delicious, Dominican, Latin, Authentic',
        annual_dol: 66.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.6992,
        total_orders: 10.46,
        neighborhood: '',
        keywords:
            'Cheesecake, Lemon Meringue Pie, Salad, Hamburger, Tasty, Dessert, Danish Pastry, Delicious, Danish, Gin',
        annual_dol: 42.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7314,
        store_longitude: -73.958,
        total_orders: 1324.66,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Whiskey, Upscale, Cozy, Friendly, Classy, Casual, Perfect, Great, Chill',
        annual_dol: 1382.09,
        description:
            'The jukebox plays country tunes in this hip, laid-back hangout with an exposed-brick interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7327,
        store_longitude: -73.8636,
        total_orders: 1363.83,
        neighborhood: 'Rego Park/Flushing',
        keywords: 'Ale, Beer, Steak, Lobster, Tangy, American, Warm, Delicious, Casual, Local',
        annual_dol: 5640.67,
        description:
            'Casual sports-pub chain with basics from bar snacks to steaks & a large selection of tap beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5416,
        store_longitude: -74.1469,
        total_orders: 3631.63,
        neighborhood: 'Great Kills',
        keywords:
            'Lobster, Pizza, Salad, Italian, Vodka, Meatball, Delicious, Caesar Salad, Warm, Casual',
        annual_dol: 3789.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7924,
        store_longitude: -73.9738,
        total_orders: 490.95,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Hamburger, Salad, Meatball, Italian, Coffee, Dessert, Wine, Prosecco, Casual',
        annual_dol: 586.47,
        description:
            'Tiny joint featuring a menu of pizza, burgers, gyros & other hearty fare plus sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7324,
        store_longitude: -73.9945,
        total_orders: 506.3,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Italian, Upscale, Romantic, Classy, Comfortable, Homey, Amazing, Intimate, Wonderful',
        annual_dol: 528.25,
        description:
            'A roaring fireplace sets the scene for homey red-sauce pasta & entrees served by bow-tied waiters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -73.9857,
        total_orders: 109.9,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Beer, Warm, Juicy, Intimate, Asian, Contemporary, American, Bright, Casual',
        annual_dol: 449.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.8899,
        total_orders: 118.67,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Salad, Dominican, Mediterranean, American, Premium, Fabulous, Great, Excellent, Casual',
        annual_dol: 166.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7394,
        store_longitude: -73.8843,
        total_orders: 97.81,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Premium, Awesome, Sophisticated, Clean, Great, Latin, Casual',
        annual_dol: 399.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -73.9779,
        total_orders: 10632.78,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Vodka, Rich, Martini, Margarita, Gin, Salad, Iconic, Classy, Premium',
        annual_dol: 11017.8,
        description:
            "Classic cocktails & light fare in the ornate Grand Central offices of a 1920's mogul.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5756,
        store_longitude: -73.9628,
        total_orders: 204.09,
        neighborhood: 'Brighton Beach',
        keywords:
            'Salad, Russian, Georgian, Delicious, Homemade, American, Premium, Casual, Authentic, Spectacular',
        annual_dol: 217.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8827,
        store_longitude: -73.8989,
        total_orders: 320.5,
        neighborhood: 'Kingsbridge Heights/West Bronx',
        keywords:
            'Pizza, Gourmet, Mexican, Wine, Delicious, Trendy, Bbq, Dominican, Irish, American',
        annual_dol: 531.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7526,
        store_longitude: -73.9744,
        total_orders: 3177.46,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Irish, Elegant, Coffee, Gin, Warm, Georgian, Lively, Classic, Polished',
        annual_dol: 3346.97,
        description:
            "Polished rooms feature a mixture of classic and modern furnishings, and offer free Wi-Fi and flat-screen TVs, plus pillow-top mattresses and coffeemakers. Suites add sitting areas, and an upgraded suite has a separate living room, a marble fireplace and a rooftop terrace. Room service is available 24/7.Access to a nearby fitness center is complimentary. There's a cozy, traditional Irish pub with a patio.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.9445,
        total_orders: 24.28,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Steak, Lobster, Tasty, Dessert, Delicious, Tuna Steak, Lobster Bisque, Shrimp Scampi, Comfortable, Mediterranean',
        annual_dol: 42.56,
        description:
            'Roomy waterside cafe a fireplace & patio for comfort food, cocktails & housemade ice cream.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9615,
        total_orders: 4176.87,
        neighborhood: 'Midtown Manhattan/Sutton Place',
        keywords: 'Phenomenal, Spanish, Amazing, Awesome, Great, Premium, Contemporary, Local',
        annual_dol: 4373.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9775,
        total_orders: 559.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tasty, Rich, Wine, Upscale, Pizza, Delicious, Elegant, Dessert, Sustainable, Organic',
        annual_dol: 1826.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6893,
        store_longitude: -73.9693,
        total_orders: 6672.52,
        neighborhood: 'Clinton Hill',
        keywords:
            'Vegetarian, Italian, Salad, Beer, Caesar Salad, American, Outstanding, Romantic, Fun, Great',
        annual_dol: 6961.82,
        description:
            'This tin-ceilinged bistro offers upmarket pub fare & charcuterie along with DJ shows & late hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -73.9851,
        total_orders: 7220.51,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Mexican, Avocado Toast, Hurricane, Hotel, Delicious, Chinese, Wholesome, American, Cool, Casual',
        annual_dol: 25348.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6894,
        store_longitude: -73.9927,
        total_orders: 10.35,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Mexican, Tacos, Salad, Burritos, Organic, Warm, Local, Sparkling, Casual, Great',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.99,
        total_orders: 774.66,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Sushi, Japanese, Sashimi, Whiskey, Modern, Seasonal, Hotel, Creamy, Local, Traditional',
        annual_dol: 3194.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.9966,
        total_orders: 91.52,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Fun, Smooth, Casual, Vivid',
        annual_dol: 374.11,
        description:
            '"Spearmint Rhino Gentlemen\'s Club NYC is now open! 5 floors of entertainment from around the globe. Come see what everyone is talking about!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9824,
        total_orders: 130.19,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Tacos, Flavorful, Salad, Mexican, Tasty, Margarita, Trendy, Delicious, Healthy, Classy',
        annual_dol: 290.34,
        description:
            '"Inspired by a healthy lifestyle where beach, surf and sun mix with fresh local ingredients, Taco Dumbo offers a laid back vibe and flavorful food for all."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9989,
        total_orders: 1753.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Pizza, Beer, Vodka, Upscale, Wine, American, Rum, Modern, Premium',
        annual_dol: 2100.01,
        description:
            'Beer hall offering a large craft-brew menu in a brick-walled, TV-equipped space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -73.9964,
        total_orders: 523.33,
        neighborhood: 'Nolita',
        keywords:
            'Tasty, Vegan, Italian, Sushi, Coffee, Delicious, Upscale, Elegant, Exquisite, Healthy',
        annual_dol: 987.12,
        description:
            'SoHo outpost of a small chain of cafes offering modern Milanese cuisine, bar bites & Italian wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7768,
        store_longitude: -73.9778,
        total_orders: 240.07,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Pizza, Salad, Wine, Beer, Italian, Gluten Free, Hotel, Organic, American, Seasonal',
        annual_dol: 981.34,
        description:
            'Easygoing outlet serving elevated diner & deli offerings in a woodsy venue with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6638,
        store_longitude: -73.6986,
        total_orders: 14.72,
        neighborhood: '',
        keywords:
            'Wine, Coffee, Avocado Toast, Cozy, Relaxed, Perfect, Caribbean, Casual, American, Local',
        annual_dol: 60.16,
        description:
            '"Standard Pour is a relaxed café and wine bar located in Valley Stream, LI serving specialty coffee drinks, cocktails and cozy fare made-to-order every day. Perfect for wine date night, afterwork happy hour or weekend brunch with friends."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9807,
        total_orders: 307.78,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Salad, Italian, Wine, Dessert, Roast Turkey, Elegant, Spacious, Polished, Mediterranean',
        annual_dol: 886.49,
        description:
            'Traditional Italian cuisine & cocktails offered in a spacious venue with chic, polished decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.9784,
        total_orders: 1932.78,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Gourmet, Dessert, Vegan, Hotel, Upscale, Indian, Contemporary, Fusion, Cozy',
        annual_dol: 2059.8,
        description:
            'High-end Indian restaurant featuring a menu of creative meals, plus a robust wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7656,
        store_longitude: -73.9826,
        total_orders: 9.73,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Gourmet, Salad, Chinese, Spicy, Asian, Healthy, Authentic, Casual, Great',
        annual_dol: 39.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9743,
        total_orders: 1776.96,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Russian, Urban, Iconic, Timeless, Complex',
        annual_dol: 7263.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.989,
        total_orders: 273.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Tasty, Friendly, Fun, English Muffin, Great, Casual, American',
        annual_dol: 1119.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -73.9882,
        total_orders: 1340.23,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Tasty, Beer, Shrimp Cocktail, Upscale, Delicious, Premium, French, Friendly, Classic',
        annual_dol: 1526.43,
        description: 'Neighborhood hangout for beer, creative cocktails & off-the-grill fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8985,
        store_longitude: -73.8672,
        total_orders: 980.25,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords:
            'Burger, Steak, Salad, Pizza, Wine, Beer, Irish, Delicious, Caesar Salad, American',
        annual_dol: 1166.02,
        description:
            'Standard bar grub & pints of Guinness in a sprawling, TV-equipped space with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7223,
        store_longitude: -73.9886,
        total_orders: 1766.18,
        neighborhood: 'Lower East Side',
        keywords: 'Rooftop, Hotel, Wine, Bright, Stylish, Trendy, Japanese, Perfect, Classy, Great',
        annual_dol: 4319.9,
        description:
            "Stylish 7th-floor locale with creative cocktails, plush pink booths, a terrace & DJ'ed music.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7824,
        store_longitude: -73.7947,
        total_orders: 230.16,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Salad, Shrimp Cocktail, Chicken Parmigiana, Pizza, Chocolate Mousse, Vodka, Wine, Italian, Tasty, Southern',
        annual_dol: 240.14,
        description:
            'Warm, casual venue offering Northern & Southern Italian fare, including pasta, veal & seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7385,
        store_longitude: -74.0021,
        total_orders: 895.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Lobster Roll, Steak, Pizza, Coffee, Italian, Vodka, Martini, Ale, American',
        annual_dol: 920.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5866,
        store_longitude: -73.804,
        total_orders: 181.87,
        neighborhood: 'Hammels/Far Rockaway/Arverne',
        keywords:
            'Lobster, Lobster Roll, Italian, Beer, American, Sensational, Casual, Cool, Signature',
        annual_dol: 197.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7391,
        store_longitude: -73.9937,
        total_orders: 359.82,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Delicious, Trendy, Upscale, Italian, Romantic, Classy, Sweet, Southern, Great',
        annual_dol: 375.42,
        description:
            'Chic space for Southern Italian seafood, pastas & wine, with banquette & communal seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -73.9618,
        total_orders: 1173.52,
        neighborhood: 'Williamsburg',
        keywords: 'Canadian, Wine, Beer, American, Legendary, Clean, Exceptional, Cool',
        annual_dol: 1285.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7119,
        store_longitude: -73.9441,
        total_orders: 1703.38,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Vodka, Wine, Burger, Margarita, Coffee, Ale, Bourbon, Rustic, Spicy',
        annual_dol: 1896.59,
        description:
            'Popular, rustic bar serving draft beer, wine & cocktails, plus weekend DJs & dancing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9832,
        total_orders: 391.37,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Whiskey, Beer, Canadian, Mimosa, Friendly, Fun, Eclectic, Quirky, Casual, Lively',
        annual_dol: 471.38,
        description:
            'Quirky haunt in unassuming digs lures locals with eclectic tunes & straightforward drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6718,
        store_longitude: -73.9577,
        total_orders: 134.31,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Pizza, Salad, Italian, Dessert, Wine, Warm, Modern, Seasonal, Casual',
        annual_dol: 140.13,
        description:
            'Unique Italian dishes with local ingredients served in a warm space with a long marble bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7242,
        store_longitude: -73.9825,
        total_orders: 56.76,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Italian, Wine, Dessert, Pizza, Rustic, Cozy, Classy, Crisp, Intimate, Casual',
        annual_dol: 61.86,
        description: 'Unassuming East Village Tuscan restaurant with a cozy, brick-walled setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.9279,
        total_orders: 298.77,
        neighborhood: 'Astoria/LIC',
        keywords: 'Cuban, Mexican, Coffee, Dessert, Karaoke, Rum, Italian, American, Fusion, Latin',
        annual_dol: 357.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7223,
        store_longitude: -73.9593,
        total_orders: 1529.18,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Ale, Gluten Free, Irish, American, Warm, Delicious, Sweet, Casual',
        annual_dol: 1620.85,
        description:
            'Easygoing, industrial pub featuring rugby & soccer games on TV, plus craft beers & tavern eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -73.9835,
        total_orders: 33.49,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Pizza, Beer, Wine, Salad, Premium, Delicious, Phenomenal, Thai, Tiramisu, Great',
        annual_dol: 177.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7145,
        store_longitude: -73.9652,
        total_orders: 1225.55,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Vegan, Salad, Vegetarian, Coffee, Gluten Free, Beer, Delicious, Rustic, Mediterranean, Israeli',
        annual_dol: 1583.13,
        description:
            'Middle Eastern dishes (including standout hummus) featured in a laid-back, rustic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -73.9846,
        total_orders: 877.2,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Pizza, Steak, Italian, Wine, Rustic, Beer, Hotel, Modern, Delicious, Polished',
        annual_dol: 3585.72,
        description:
            'Cracker-thin pizza & other modern Italian fare in a polished hotel dining room with an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9773,
        total_orders: 84.74,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Chicken Tikka Masala, Gluten Free, Tangy, Fiery, Southern, Tandoori Chicken, Spacious, Paneer Tikka, Indian',
        annual_dol: 207.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.9866,
        total_orders: 341.48,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Karaoke, Cajun, Trendy, Korean, Delicious, Organic, Fun, Premium, Awesome, Fresh',
        annual_dol: 1286.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9295,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Steak, Lobster, Wine, Upscale, Beer, Trendy, Healthy, Perfect, Latin, American',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7647,
        store_longitude: -73.9767,
        total_orders: 7623.19,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, American, Urban, Awesome, Rooftop, Natural, Fresh, Excellent, Seasonal, Personalized',
        annual_dol: 7801.35,
        description:
            "The rustic-chic rooms and suites have reclaimed wood accents and come with free Wi-Fi, flat-screen TVs and tablets with personalized apps. They also offer 24/7 room service, Nespresso machines and yoga mats. In-room spa treatments can also be arranged for a fee.Amenities include a farm-to-table restaurant with picnic baskets available to go, a complimentary snack bag upon arrival, and a bar. There's also a lobby farm stand, and a fitness room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.9854,
        total_orders: 4214.4,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegan, Vegetarian, Wine, Shrimp Cocktail, Beer, Gin, Spicy, Irish, Phenomenal, Traditional',
        annual_dol: 4434.53,
        description:
            'Easygoing spot offering classic pub grub & a full bar in a whimsical setting with Victorian details.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7629,
        store_longitude: -73.9841,
        total_orders: 627.69,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Wine, Cuban, Margarita, Gourmet, Stylish, Spicy, Delicious, Warm, Luxurious, Sweet',
        annual_dol: 627.69,
        description:
            'Cuban cooking & live Cuban music fuel the festive old-Havana vibe at this circa-1963 spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7039,
        store_longitude: -74.009,
        total_orders: 470.16,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster Roll, Lobster, Burger, Tacos, Beer, Margarita, Wine, Coffee, Spicy, Vodka',
        annual_dol: 524.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7175,
        store_longitude: -73.9903,
        total_orders: 3658.16,
        neighborhood: 'Lower East Side',
        keywords: 'Wine, Beer, Tasty, Rich, Caribbean, Rum, Salad, French, Intimate, Sweet',
        annual_dol: 4264.04,
        description:
            'Unique cocktails & bar bites such as arepas & oxtail rice bowls served in intimate surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9947,
        total_orders: 127.84,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Vegetarian, Salad, Organic, Savory, Delicious, Healthy, Avocado Toast, Thai, Sweet',
        annual_dol: 522.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8675,
        store_longitude: -73.9294,
        total_orders: 37.07,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Steak, Salad, Margarita, Latin, Dominican, American, Casual, Intimate, Local, Great',
        annual_dol: 151.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -73.9957,
        total_orders: 284.56,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Cheesecake, Salad, Wine, Champagne, Delicious, Italian, Warm, Cozy, Organic, Sweet',
        annual_dol: 480.82,
        description:
            'Fresh-made pastas and homestyle meat dishes are the hallmark of this budget Northern Italian spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7266,
        store_longitude: -73.7899,
        total_orders: 242.78,
        neighborhood: 'Fresh Meadows/Utopia/Flushing',
        keywords:
            'Vodka, Pizza, Gluten Free, Chicken Parmigiana, Italian, Meatball, Homemade, Fresh, Authentic, Casual',
        annual_dol: 251,
        description:
            'Family-owned spot serves hefty portions of Italian classics & signature house-made tiramisu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7442,
        store_longitude: -74.0065,
        total_orders: 799.79,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Pizza, Salad, Beer, Whiskey, Wine, American, Mediterranean, Delicious, Sangria',
        annual_dol: 3269.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.741,
        store_longitude: -74.0018,
        total_orders: 444.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Rich, Delicious, Caribbean, Iconic, Memorable, Heritage',
        annual_dol: 663.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7263,
        store_longitude: -73.9836,
        total_orders: 5107.78,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Mexican, Coffee, Beer, Organic, Bbq, Delicious',
        annual_dol: 7946.07,
        description:
            'Creative Pan-Latin fare served in a lively space with open-window seating & a popular happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6733,
        store_longitude: -73.9627,
        total_orders: 880.2,
        neighborhood: 'Crown Heights',
        keywords:
            'Wine, Beer, Dessert, Delicious, Seasonal, French, Intimate, Sleek, Spectacular, Contemporary',
        annual_dol: 918.36,
        description:
            'Seasonal, produce-driven dishes set in an atrium with an open kitchen, right next to Prospect Park.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -73.9806,
        total_orders: 411.69,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Korean, Asian, Awesome, Wonderful, American, Fusion, Incredible, Cool, Hip',
        annual_dol: 1445.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -73.9832,
        total_orders: 4642.81,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Rooftop, Hotel, Spectacular, Trendy, Italian, Mediterranean, Fantastic, Bellini, Dutch, Great',
        annual_dol: 19685.59,
        description:
            'Trendy rooftop bar offering a covered bar & skyline views, plus cocktails & international bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9745,
        total_orders: 2396.12,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Mexican, Tequila, Cornbread, Vegan, Margarita, Wine, Tex-Mex, Fajitas, Delicious, Romantic',
        annual_dol: 2572.54,
        description:
            'Intimate, old-school Mexican restaurant, open since 1959, offering traditional cuisine & margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9723,
        total_orders: 347.21,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Vegan, Coffee, Mexican, Beer, Delicious, American, Urban, Friendly, Fresh, Natural',
        annual_dol: 772.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7495,
        store_longitude: -73.9835,
        total_orders: 930.04,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Beer, Irish, Jagermeister, Cozy, Lively, Friendly, Comfortable, Atmospheric, Bright, Clean',
        annual_dol: 991.86,
        description:
            'Cocktails are served along with bottled & draft beer at this old-school Irish pub & sports bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -73.9574,
        total_orders: 6152.37,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Delicious, Sweet, Juicy, Ale, Crisp, Gourmet, Southern Comfort, Classy, Southern',
        annual_dol: 6419.12,
        description:
            'Gourmet chicken & waffles star at this hip, down-home Southern comfort-food resource with cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7453,
        store_longitude: -73.9786,
        total_orders: 92.94,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Tequila, Mexican, Burger, Steak, Beer, Margarita, Wine, Dessert, Martini, Enchiladas',
        annual_dol: 116.72,
        description:
            'Mexican kitchen & tequila bar in an easygoing, contemporary environment with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.9587,
        total_orders: 1.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Vegan, Lobster, Salad, Italian, Vodka, Shrimp Scampi, French, Homemade, Cozy',
        annual_dol: 5.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.9845,
        total_orders: 805.5,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Korean, Stylish, Wine, Savory, Bbq, Classy, Friendly, Japanese, Authentic, Traditional',
        annual_dol: 876.59,
        description:
            'Stylish & minimalist Korean barbecue restaurant serving with traditional tableware & utensils.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9819,
        total_orders: 10.85,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Salad, Rustic, Beer, Modern, Mediterranean, Homey, Casual, Local, Succulent, Unique',
        annual_dol: 44.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7676,
        store_longitude: -73.9833,
        total_orders: 1619.74,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Champagne, Wine, Sushi, Beer, Romantic, Whimsical, Fun, American, Amazing, Classic',
        annual_dol: 2162.24,
        description:
            'Cheery cocktail bar & bistro with stylish, genteel decor, plus live jazz & DJ nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9806,
        total_orders: 854.65,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Luxurious, Wine, Hotel, Spacious, Coffee, Premium, Italian, Gin, Amazing, Lively',
        annual_dol: 872.79,
        description:
            "The chic suites have Italian linens, 2 flat-screen TVs and complimentary Wi-Fi. Upgraded suites provide additional space; some add full kitchens, posh living rooms, city views and/or atriums. Room service is available 24/7.There's a 24-hour fitness center, as well as a sophisticated bar serving French-influenced fare.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9825,
        total_orders: 755.05,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Italian, Wine, Coffee, Iconic, Classic, Comfortable, Fantastic, Great, Authentic',
        annual_dol: 771.07,
        description:
            'subway lines are within a 3-block walk.Stylish rooms have free Wi-Fi, Bose radios and flat-screen TVs, plus minifridges and marble bathrooms. Suites add separate sitting areas.A modern Italian lounge serves wine and spirits. Other amenities include a 24-hour fitness room, access to a nearby pool and spa (fee), 5 meeting rooms and complimentary morning car service to Wall Street.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.975,
        total_orders: 2429.35,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Tequila, Italian, Rustic, French, Legendary, American, Classic, Polished, Iconic',
        annual_dol: 2480.9,
        description:
            '"Cucina8 ½ offers an elevated refined rustic Italian cuisine. Located in the heart of Midtown New York, home of the former Brasserie 8 1/2. Chef John Villa’s menu features a variety of dishes that are inspired by New York & Italian ingredients and culinary traditions."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9818,
        total_orders: 637.12,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Pizza, Salad, Chicken Parmigiana, Italian, Tasty, Wine, Fried Calamari, Casual, Great, Local',
        annual_dol: 742.64,
        description:
            'Besides big portions of pasta in a variety of sauces, this no-frills eatery serves pizza & panini.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9858,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Hotel, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7191,
        store_longitude: -73.9917,
        total_orders: 283.16,
        neighborhood: 'Bowery',
        keywords: 'Wine, Coffee, Salad, Dessert, Organic, Beer, French, Savory, Cozy, Romantic',
        annual_dol: 346.57,
        description:
            'Rustic-chic French cafe serving traditional fare including brunch with wine & classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6697,
        store_longitude: -73.588,
        total_orders: 506.49,
        neighborhood: '',
        keywords: 'Italian, Pizza, Great, Wonderful, Fresh, Friendly, Fine',
        annual_dol: 519.17,
        description:
            '"Family owned and operated Italian Pizza and Restaurant. Full Catering drop off and on premises. Full Bar in dining area. Pizza delivery"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.739,
        store_longitude: -74.0012,
        total_orders: 1337.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Beer, Mimosa, Wine, Italian, Bbq, American, Cozy, Coleslaw, Bloody Mary',
        annual_dol: 1640.93,
        description:
            'Old-fashioned pub offering a wide variety of beers, plus lunch & dinner menus & a late bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -73.9847,
        total_orders: 17224.81,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Gin, Beer, Austrian, Shrimp Cocktail, Rustic, Fun, Premium, Superb, Intimate',
        annual_dol: 19032.64,
        description:
            'Bartenders in bow ties & suspenders recall the speakeasy era at this dark, moody cocktail lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7118,
        store_longitude: -73.9572,
        total_orders: 905.5,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Awesome, Spectacular, Superb, Amazing, Great, Friendly, Clean, Cool',
        annual_dol: 924.71,
        description: '"Dive bar with a great Pinball selection"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7256,
        store_longitude: -73.9837,
        total_orders: 2914.45,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Cinnamon Roll, Wine, Vodka, Salad, Cajun, Beer, Coffee, Martini, Tasty, Savory',
        annual_dol: 4464.43,
        description:
            'Vibrant bar & restaurant with outdoor seating & an old-school vibe serving mixed drinks & meals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7125,
        store_longitude: -73.9574,
        total_orders: 175.22,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Tequila, Vodka, Gin, Rum, Spacious, Fun, Fantastic, Casual',
        annual_dol: 195.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7178,
        store_longitude: -73.9465,
        total_orders: 7.47,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Chinese, American, Fiery, Sophisticated, Natural, Casual, Unique, Cool, Dim Sum',
        annual_dol: 30.55,
        description:
            'Elevated Chinese dishes, cocktails & wines offered in a sophisticated restaurant with a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6384,
        store_longitude: -74.076,
        total_orders: 148.15,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Burger, Hamburger, Salad, Tasty, Bbq, French, Eclectic, Fresh, Fine',
        annual_dol: 605.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.9853,
        total_orders: 717.16,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Vegetarian, Korean, Bbq, Beer, Karaoke, Rich, Rich Aroma, Prime Rib, Casual, Classic',
        annual_dol: 754.86,
        description:
            'This branch of a South Korea-based chain offers table-grilled Korean BBQ, plus noodles & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7424,
        store_longitude: -73.9824,
        total_orders: 210.68,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vegetarian, Kebab, Chicken Tikka Masala, Wine, Spicy, Southern, Indian, Paneer Tikka, Casual, Saag Paneer',
        annual_dol: 240.77,
        description:
            'Modern decor meets Bollywood art at this Indian hot spot known for kebabs & dum biryani rice pots.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -73.9815,
        total_orders: 85.6,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Steak, Salad, Tranquil, Spicy, Hotel, American, Casual, Sweet, Cobb Salad',
        annual_dol: 349.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7346,
        store_longitude: -74.0029,
        total_orders: 430.52,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Salad, Wine, Dessert, Italian, French, Modern, Caesar Salad, Cozy, American',
        annual_dol: 1701.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7416,
        store_longitude: -74.0073,
        total_orders: 39.3,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Pizza, Vodka, Martini, Italian, Gin, Premium, Amazing, Iconic, Perfect, Colorful',
        annual_dol: 160.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7334,
        store_longitude: -74.005,
        total_orders: 184.92,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lasagna, Salad, Sushi, Italian, Wine, Delicious, Cozy, Romantic, French, Classy',
        annual_dol: 328.17,
        description:
            'Tuscan-style fare, wines & cocktails lie in store at this compact, romantic restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5657,
        store_longitude: -74.1005,
        total_orders: 44.09,
        neighborhood: 'New Dorp Beach',
        keywords:
            'Burger, Pizza, Tequila, Wine, Italian, Upscale, Meatball, Chilean, American, Romantic',
        annual_dol: 83.26,
        description:
            '"Serving Staten Island with an elevated Italian American cuisine! From flatbreads to steaks & seafood, there\'s something for everyone to enjoy!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7119,
        store_longitude: -73.6094,
        total_orders: 29.74,
        neighborhood: '',
        keywords: 'Steak, Salad, Spanish, Dominican, American, Latin, Local, Casual, Premium',
        annual_dol: 103.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7711,
        store_longitude: -73.9565,
        total_orders: 733.72,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Steak, Meatball, Salad, Beer, Mimosa, Coffee, American, Rum',
        annual_dol: 780.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7521,
        store_longitude: -73.9821,
        total_orders: 7.35,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Salad, Wine, Organic, Latin, Chic, American, Traditional, Great, Complex',
        annual_dol: 179.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8477,
        store_longitude: -73.8446,
        total_orders: 174.2,
        neighborhood: 'Morris Park/East Bronx',
        keywords: 'Spicy, Salad, Beer, Delicious, Margarita, Crisp, Thai, Sweet, Bbq, Lively',
        annual_dol: 736.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7901,
        store_longitude: -73.9734,
        total_orders: 1243.54,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Sushi, Japanese, Vegetarian, Roast Beef, Bbq, Spicy, Casual, Premium, Friendly',
        annual_dol: 2343.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8529,
        store_longitude: -73.8276,
        total_orders: 24.84,
        neighborhood: 'Pelham Bay',
        keywords:
            'Garlic Bread, Salad, Pizza, Italian, Shrimp Cocktail, Meatball, Casual, Local, Wonderful',
        annual_dol: 101.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7254,
        store_longitude: -73.9464,
        total_orders: 2929.49,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Tacos, Meatball, Nachos, Delicious, Cozy, Sweet, Amazing, Great, Local',
        annual_dol: 8064.68,
        description:
            'Easygoing hangout with nostalgic accents serving beers, along with burgers, hot dogs & fries.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.698,
        store_longitude: -73.9252,
        total_orders: 4489.12,
        neighborhood: 'Bushwick',
        keywords:
            'Coffee, Whiskey, Wine, Burger, Cozy, Irish, American, Friendly, Yummy, Handcrafted',
        annual_dol: 4684.31,
        description:
            'Chill, cozy haunt for espresso, cocktails & light bites, plus late hours & a pool table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7304,
        store_longitude: -73.954,
        total_orders: 2299.97,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Upscale, Clean, Friendly, Perfect, Casual, Wonderful, Hip, Creative',
        annual_dol: 3487.74,
        description: '"Your friendly neighborhood bar! Serving Cold Beer & Mixed Drinks daily!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9657,
        total_orders: 1.76,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Hamburger, Salad, Tuna Melt Sandwich, American, Casual, Seasonal, Whimsical, Local, Ample',
        annual_dol: 33.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.814,
        store_longitude: -73.9617,
        total_orders: 128.62,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Burger, Delicious, Spacious, Outstanding, Great, Cultural',
        annual_dol: 131.35,
        description:
            'International House New York, also known as I-House, is a private, independent, non-profit residence and program center for postgraduate students, research scholars, trainees, and interns, located at 500 Riverside Drive in Morningside Heights, Manhattan, New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9881,
        total_orders: 5.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Whiskey, Wine, Irish, Cozy, Casual',
        annual_dol: 22.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9877,
        total_orders: 423.46,
        neighborhood: 'Lower East Side',
        keywords: 'Sushi, Japanese, Delicious, Warm, Cozy, Polish, Greek, Crisp, Casual, Creative',
        annual_dol: 1595.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9843,
        total_orders: 43.32,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Sushi, Shrimp Cocktail, Beer, Wine, Italian, Bbq, American, Fresh, Excellent, Rooftop',
        annual_dol: 70.79,
        description:
            'The Hudson Theatre is a Broadway theater at 139–141 West 44th Street, between Seventh Avenue and Sixth Avenue, in the Theater District of Midtown Manhattan in New York City. One of the oldest surviving Broadway venues, the Hudson was built from 1902 to 1903. The exterior was designed by J. B.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7523,
        store_longitude: -73.9921,
        total_orders: 25.93,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Karaoke, Luxurious, Spicy, Burger, Delicious, Bbq, Korean, American, Chic, Intimate',
        annual_dol: 57.74,
        description:
            'Spacious, high-style karaoke bar with private rooms, a lounge area, Asian snacks & bottle service.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8661,
        store_longitude: -73.9277,
        total_orders: 364.3,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Sushi, Steak, Vegetarian, Wine, Japanese, Spicy, Sashimi, Delicious, Caribbean, Dominican',
        annual_dol: 405.62,
        description:
            'Late-night sushi house offers unique rolls, Japanese-Mexican fusion fare, cocktails & patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8167,
        store_longitude: -73.9579,
        total_orders: 74.43,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Salad, Wine, Delicious, Spanish, Southern, Israeli, Casual, Local, Authentic, Modern',
        annual_dol: 304.26,
        description:
            '"Acclaimed chef, author, autism activist and TV personality, Franklin Becker, has assembled some of the best culinary talent to create Manhattanville Market. His steadfast commitment to loyalty and inclusion is one reason his dedicated team has become more like family. Our food hall opens for breakfast at 8am and offers 4 fast casual food concepts; The Botanist, Shai, Benny Casanova and Butterfunk Biscuit. So, we welcome you to the table. Pull up a chair and join us!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6528,
        store_longitude: -73.5875,
        total_orders: 0.82,
        neighborhood: '',
        keywords:
            'Mexican, Salad, Italian, Dominican, Peruvian, Delicious, Spanish, Caribbean, Latin, American',
        annual_dol: 3.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7662,
        store_longitude: -73.9868,
        total_orders: 309.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Steak, Mexican, Salad, Tasty, Margarita, Delicious, Thai, Phenomenal, Fun',
        annual_dol: 1265.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7401,
        store_longitude: -73.9961,
        total_orders: 130.65,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Italian, Wine, Beer, Coffee, Martini, Trendy, Upscale, Meatball, Delicious, Romantic',
        annual_dol: 183.04,
        description:
            'Warm neighborhood restaurant & bar presenting seasonally driven Italian dishes from an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.74,
        store_longitude: -73.9975,
        total_orders: 198.46,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Coffee, Vibrant, Thai, Dynamic, Perfect, Diverse, Cultural',
        annual_dol: 747.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.9962,
        total_orders: 66.34,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Salad, Tasty, Wine, Vodka, Delicious, Beer, Mimosa, French, Mediterranean',
        annual_dol: 271.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.709,
        store_longitude: -73.9555,
        total_orders: 1952.91,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Cheesecake, Burger, Salad, Vegan, Dessert, Japanese, Wine, Delicious, Ramen, Asian',
        annual_dol: 2159.36,
        description:
            'Jewish & Japanese flavors fused together in creative small plates offered in a small, low-key space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6815,
        store_longitude: -73.9559,
        total_orders: 1232.52,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Tequila, Whiskey, Vodka, Wine, Coffee, Tasty, Cozy, American, Friendly',
        annual_dol: 1285.96,
        description:
            'Diverse craft beer, cocktails & regional wine served in a snug, low-key space with pinball machines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -74.0026,
        total_orders: 345.87,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Lasagna, Wine, Gluten Free, Mexican, Italian, Bruschetta, Contemporary, Bellini, Comfortable, Casual',
        annual_dol: 411,
        description:
            '"OSTERIA DEL PORTO IS A CONTEMPORARY NEW YORK ITALIAN WINE BAR RECENTLY OPENED IN THE HISTORIC SOUTH STREET SEAPORT DISTRICT OF LOWER MANHATTAN. OUR HOPE IS TO BRING A RELAXED, COMFORTABLE & TRULY MEMORABLE EXPERIENCE TO OUR GUESTS, PRESENTING A FRESH APPROACH TO THE FAMILIAR LANDSCAPE OF ITALIAN WINE & CUISINE"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.977,
        total_orders: 289.64,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Argentinian, Meatball, French Toast, American, French, Wine, Upscale, Thai, Ribeye Steak',
        annual_dol: 302.19,
        description:
            'Neighborhood bistro with New American fare, cocktails & craft beers in a bright, brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.9798,
        total_orders: 158.77,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Kebab, Salad, Dairy Free, Vegan, Vegetarian, Flavorful, Coffee, Mediterranean, Turkish, Rich',
        annual_dol: 354.08,
        description:
            'Down-to-earth cafe offering hummus & other traditional Mediterranean specialties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7216,
        store_longitude: -73.9585,
        total_orders: 1620.14,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Japanese, Hotel, Fabulous, Awesome, Ramen, Modern, Intimate, Rooftop, Hip',
        annual_dol: 6104.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -73.9777,
        total_orders: 906.36,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Steak, Hamburger, Beer, Wine, Mediterranean, Phenomenal, Cozy, American, Perfect',
        annual_dol: 1096.88,
        description:
            '"Be invited into a world most only read about. Cozy up to the bar worn-in by years of unbelievable tales (and the cocktails that made them). Order yourself a finely crafted beverage, and become a part of the party. Or grab a seat in the lush garden -- the perfect and slightly hidden setting for a bottle of wine (or three) amongst friends both old and new. Part French, part Uptown, all Aunt Bernie\'s Bar and Jardin. Party Venue -- book Corporate Events, Cocktail Parties, Private Event space"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.9831,
        total_orders: 66.98,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vegetarian, Tequila, Wine, Vodka, Butter Chicken, Margarita, Gin, Upscale, Homemade, Prosecco',
        annual_dol: 131.14,
        description:
            'Upscale restaurant featuring imaginative Indian fare & a full bar in a warm, modern setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7276,
        store_longitude: -74.0001,
        total_orders: 724.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Pizza, Italian, Wine, Warm, Casual, Fun, Hip, Unpretentious, Traditional',
        annual_dol: 740.12,
        description: 'Small Italian eatery serving Roman fare alongside an all-Italian wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7703,
        store_longitude: -73.7934,
        total_orders: 138.47,
        neighborhood: 'Flushing/Murray Hill',
        keywords:
            'Margarita, Mexican, Dessert, Coffee, Peruvian, Delicious, Hotel, Chinese, Latin, American',
        annual_dol: 344.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.9975,
        total_orders: 43.2,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Burger, Vegan, Steak, Vegetarian, Gluten Free, Butter Chicken, Coffee, Tasty, Cozy, Healthy',
        annual_dol: 371.4,
        description:
            'Lively bistro executing American-Mediterranean cooking & classic cocktails in understated digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7266,
        store_longitude: -74.0021,
        total_orders: 753.95,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Cornbread, Salad, Shrimp Cocktail, Turkey Sandwich, Wine, Beer, Dutch, American, Mediterranean',
        annual_dol: 786.64,
        description:
            'Regional American fare (including oysters and much-hyped fried chicken) in a lively, modern space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -74.0019,
        total_orders: 28.23,
        neighborhood: 'South Village/SoHo',
        keywords: 'Chinese, Steak, Peking Duck, Wine, Organic, Daiquiri, Spicy, Fried Rice, Casual',
        annual_dol: 115.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7211,
        store_longitude: -73.9833,
        total_orders: 1634.03,
        neighborhood: 'Lower East Side',
        keywords: 'Champagne, Beer, Funky, Eclectic, Classy, Fun, Lively, Perfect, Cool, Classic',
        annual_dol: 1704.88,
        description:
            'An eclectic roster of local electric & acoustic acts play at this funky East Village mainstay.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.9784,
        total_orders: 2392.95,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Upscale, Wine, Pizza, Modern, Contemporary, Asian, Beer, French, Polished, Authentic',
        annual_dol: 10446.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8658,
        store_longitude: -73.9274,
        total_orders: 300.19,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords: 'Mexican, Tacos, Steak, Vodka, Nachos, Margarita, Wine, American, Latin, Trendy',
        annual_dol: 580.35,
        description:
            'Late-night Mexican grill & agave bar offering classic eats, happy hour, live music & outdoor tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8399,
        store_longitude: -73.9165,
        total_orders: 4.8,
        neighborhood: 'Mount Eden/West Bronx',
        keywords:
            'Flavorful, Mexican, South American, Delicious, Dominican, Spanish, Friendly, Caribbean, American, Clean',
        annual_dol: 19.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7412,
        store_longitude: -74.0012,
        total_orders: 65.17,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Chocolate Cake, Chocolate Mousse, Dessert, Steak, Homey, Yummy, Mediterranean, Lively, American, Fun',
        annual_dol: 72.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6804,
        store_longitude: -73.9744,
        total_orders: 1668.11,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords: 'Wine, Beer, Vodka, Stylish, Elegant, Cozy, Sweet, Creamy, Vintage, Bright',
        annual_dol: 1817.98,
        description:
            'Cozy-chic bar for cocktails (plus wines & New York beers) with oysters, cheeses & small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6874,
        store_longitude: -73.9824,
        total_orders: 235.95,
        neighborhood: 'Boerum Hill',
        keywords:
            'Burger, Steak, Meatloaf, Mexican, Bbq, Russian, Homemade, Casual, Authentic, Fresh',
        annual_dol: 964.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7407,
        store_longitude: -73.9956,
        total_orders: 13.15,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Upscale, Gin, Vintage, Asian, Modern, Ramen, Great, Fine',
        annual_dol: 53.75,
        description:
            'Upscale, family-owned store offering one-of-a-kind furnishings & vintage jewelry from Europe & Asia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Sangria, Rooftop, Seasonal, Perfect, Latin, Fine, Hummus',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -73.9598,
        total_orders: 3064.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Kebab, Vegetarian, Moroccan, Wine, Spicy, Tunisian, Organic, Mediterranean, French',
        annual_dol: 3393.45,
        description:
            'Buzzy outfit serving traditional Moroccan tagines, couscous & more in comfy yet charming quarters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7083,
        store_longitude: -74.0015,
        total_orders: 962.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Lobster, Salad, Chilean, Canadian, French, French Onion Soup, Delicious, Elegant',
        annual_dol: 1004.15,
        description:
            'Tucked-away locale serving steaks, chops & seafood dishes in white-tablecloth surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7279,
        store_longitude: -73.9793,
        total_orders: 836.29,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Vegan, Wine, Italian, Vodka, Warm, Ramen, Asian',
        annual_dol: 1138.45,
        description:
            'Raw oysters & seafood bites with Asian influences are served with craft cocktails in a narrow space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7058,
        store_longitude: -74.0052,
        total_orders: 278.15,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Luxurious, Cosmopolitan, Spacious, Sleek, Urban, Classic, Bright, Modern, Clean',
        annual_dol: 290.21,
        description:
            'The sleek rooms offer flat-screen TVs, free Wi-Fi, and minifridges, as well as tea and coffeemaking facilities, desks and sitting areas. Some have city views.Amenities include a gym and a hip lounge. Breakfast is available (fee), and is served in a modern dining room that has city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6801,
        store_longitude: -73.9682,
        total_orders: 518.35,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Martini, Wine, Phenomenal, Casual',
        annual_dol: 747.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -74.0045,
        total_orders: 1069.29,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegetarian, Shrimp Cocktail, Gluten Free, Beer, American, Polished, Casual, Friendly, Classic, Local',
        annual_dol: 3855.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.678,
        store_longitude: -73.9686,
        total_orders: 681.68,
        neighborhood: 'Prospect Heights',
        keywords: 'Burger, Wine, Beer, Spicy, Italian, Ale, French, Fun, Asian, Seasonal',
        annual_dol: 711.23,
        description:
            'Simple dinner & brunch menu of re-conceived American classics in hipster-friendly surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7433,
        store_longitude: -73.9771,
        total_orders: 227.4,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Beer, Tasty, Wine, Spacious, Friendly, Cool, Amazing, Great, Casual, Local',
        annual_dol: 929.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -73.9832,
        total_orders: 2993.27,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Wine, Salad, Beer, Hotel, Mediterranean, Funky, Eclectic, Classy, Legendary, Urban',
        annual_dol: 3058.75,
        description:
            'Classy yet funky concert venue with diverse acts offers table service in theater & bar in front.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7744,
        store_longitude: -73.964,
        total_orders: 48.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Luxurious, Upscale, Hotel, Spacious, Exquisite, Premium, Perfect, Rooftop, Modern, Fine',
        annual_dol: 197.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.694,
        store_longitude: -73.9459,
        total_orders: 336.56,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Salad, Dessert, Tasty, Crab Rangoon, Ramen, Asian, Caribbean, Friendly, French, Dope',
        annual_dol: 485.17,
        description:
            'Plant-filled corner haunt serving up fusion plates & house cocktails in a relaxed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7668,
        store_longitude: -73.9625,
        total_orders: 110.06,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Mexican, Beer, Salad, Healthy, Local, Casual, Premium, Authentic, Indian, Unique',
        annual_dol: 449.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -73.9908,
        total_orders: 10705.84,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Burger, Steak, Salad, Korean, Beer, Wine, Spicy, Ale, Bbq, Gin',
        annual_dol: 11669,
        description: 'Clubby Korean gastropub & lounge with a selection of small dishes & 2 bars.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.828,
        store_longitude: -73.8808,
        total_orders: 38.1,
        neighborhood: 'East Bronx/Soundview',
        keywords: 'Mexican, Caribbean, Casual, Eclectic',
        annual_dol: 155.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9883,
        total_orders: 1727.63,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Premium, Fun, Irish, Cool, Latin, Great, Casual',
        annual_dol: 1764.28,
        description:
            'Busy bar on first floor with a dark, smaller lounge above, where a DJ keeps the dancing going.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7324,
        store_longitude: -73.988,
        total_orders: 569.48,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Beer, Wine, New-York-Style, Cozy, Charming, Fun, Lively, Perfect, Cool, Outstanding',
        annual_dol: 1395.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5398,
        store_longitude: -74.1484,
        total_orders: 105.15,
        neighborhood: 'Great Kills',
        keywords: 'Italian, Pizza, Turkish, Upscale, Delicious, Local, Casual, Great, Diverse',
        annual_dol: 165.81,
        description:
            'Upscale bar & grill with outdoor patio featuring Italian entrees, pizza, pasta & housemade desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5973,
        store_longitude: -74.0768,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Vodka, Salad, Dessert, Italian, Delicious, Gourmet, Warm, Comfortable, Sweet, French',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7529,
        store_longitude: -73.9727,
        total_orders: 1630.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Steak, Salad, Beer, Irish, Club Sandwich, Romantic, Friendly, Perfect, Casual',
        annual_dol: 2265.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7517,
        store_longitude: -73.9722,
        total_orders: 1878.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Beer, Hamburger, American, Margarita, Tasty, Karaoke, Classic, Hip, Iconic',
        annual_dol: 1918.86,
        description:
            'Classic cartoon art hangs on the walls & local teams play on big screen TVs at this Turtle Bay pub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.833,
        total_orders: 30.58,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Chinese, Beef Chow Fun, Japanese, Congee, Local, Fun, Dim Sum, Casual',
        annual_dol: 142.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.9734,
        total_orders: 4704.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Sushi, Salad, Lobster Bisque, Martini, Hotel, American, Polished, Retro',
        annual_dol: 4803.83,
        description:
            'Retro murals & swanky furnishings fill this exclusive American eatery with a powerful clientele.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.9665,
        total_orders: 982.23,
        neighborhood: 'Lenox Hill',
        keywords: 'Hamburger, Upscale, Delicious, Premium, Iconic, Perfect, Casual',
        annual_dol: 1071.98,
        description:
            '"On any given day, you could catch one of the world’s top stylists, a celebrity designer or your favorite influencer creating content in our first-of-its-kind photo and video studio, situated on our 59th Street flagship’s second floor. Oh, and did we mention it’s connected to a world-class bar and restaurant? (Translation: You can take in all the behind-the-scenes action of a Bloomingdale’s photo shoot while also enjoying a delicious cocktail.) Stop by on your next shopping trip—you never know what (or who) you might see."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6948,
        store_longitude: -73.9528,
        total_orders: 505.2,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Romantic, Comfortable, Delicious, Intimate, Casual, Great, Local',
        annual_dol: 738.02,
        description:
            '"Local bar in BedStuy serving beer, wine and cocktails. Backyard open. Dogs are welcome."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9904,
        total_orders: 186.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Pizza, Wine, Relaxed, Friendly, Yummy, Pinacolada, Casual, Fun, American',
        annual_dol: 432.13,
        description:
            'Burgers, wings, fries & other pub grub is served at this relaxed sports bar spotlighting craft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7487,
        store_longitude: -74.0033,
        total_orders: 2425.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Chinese, Spicy, Savory, Bbq, Asian, Thai, Eclectic, Fusion, Casual',
        annual_dol: 2643.67,
        description:
            'Casual Pan-Asian dining within a stylishly streamlined interior or out on the wood-plank patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7274,
        store_longitude: -74.0003,
        total_orders: 573.3,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Sushi, Salad, Shrimp Cocktail, Italian, Wine, Beer, Shrimp Scampi, Margarita, Fried Calamari',
        annual_dol: 585.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7254,
        store_longitude: -74.0034,
        total_orders: 1504.32,
        neighborhood: 'South Village/SoHo',
        keywords: 'Pizza, Beer, Coffee, Wine, American, Casual, Bright, Great, Traditional',
        annual_dol: 1583.17,
        description:
            'Intimate, brick-walled watering hole with comfy booths offering beer, wine & a tavern menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9871,
        total_orders: 32.53,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Flavorful, Vodka, Wine, Martini, Ale, Delicious, Greek, Phenomenal, Homemade',
        annual_dol: 88.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7215,
        store_longitude: -73.9846,
        total_orders: 116.42,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Salad, Tequila, Beer, Margarita, Fried Chicken, Irish, Australian, American, Warm',
        annual_dol: 407.74,
        description:
            'Laid-back restaurant & bar offering elevated pub grub & aviator-themed cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7649,
        store_longitude: -73.9959,
        total_orders: 190.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Tequila, Rooftop, American, Nachos, Modern, Salad, Latin, Innovative, Fun',
        annual_dol: 780.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5257,
        store_longitude: -74.2009,
        total_orders: 51.82,
        neighborhood: "Prince's Bay",
        keywords:
            'Pizza, Salad, Italian, Gourmet, Delicious, Modern, Bright, Great, British, Casual',
        annual_dol: 211.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6821,
        store_longitude: -73.9796,
        total_orders: 161.39,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Ale, American, Lively, Authentic, Classic, Cool, Great, Wonderful, Casual',
        annual_dol: 611.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7356,
        store_longitude: -73.9979,
        total_orders: 552.77,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Burger, Tacos, Wine, Coffee, Beer, Chinese, Ale, American, Asian, Classic',
        annual_dol: 2259.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -73.9842,
        total_orders: 769.55,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Pizza, Vodka, Italian, Salad, Wine, Delicious, Shrimp Scampi, Pesto Pasta, Fresh, Great',
        annual_dol: 769.55,
        description:
            'Casual Staten Island offspring featuring house-specialty thin-crust pies, pasta & Italian entrees',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.717,
        store_longitude: -73.9501,
        total_orders: 2099.71,
        neighborhood: 'Williamsburg',
        keywords: 'Coffee, Tasty, Wine, Beer, Stylish, Spicy, Martini, Delicious, Savory, Italian',
        annual_dol: 2234.5,
        description:
            'Stylish cafe by day & restaurant-meets-cocktail bar by night offers cocktails & sharable plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6731,
        store_longitude: -73.9573,
        total_orders: 801.48,
        neighborhood: 'Crown Heights',
        keywords:
            'Pizza, Salad, Italian, Chicken Parmigiana, Vodka, Wine, Meatball, Beer, Delicious, Cozy',
        annual_dol: 833.32,
        description:
            'This hip Italian joint offers a full menu of entrees plus design-your-own meals of handmade pasta.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7073,
        store_longitude: -74.0023,
        total_orders: 698.74,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Italian, Delicious, Warm, Sweet, Friendly, Bellini, Casual, Fresh, Local, Great',
        annual_dol: 729.04,
        description:
            'Elevated Italian fare in a rustic-chic setting accented by exposed brick & reclaimed wood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7056,
        store_longitude: -74.0045,
        total_orders: 2055.33,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Beer, Wine, Margarita, German, Gourmet, American, Magical, Warm, Modern',
        annual_dol: 2268.19,
        description:
            'This stylish, open-air cocktail spot overlooking the East River features burgers & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.6979,
        total_orders: 347.07,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Hamburger, Pizza, Vegetarian, Margarita, Cuban, American, Irish, Casual',
        annual_dol: 497.03,
        description:
            'Hut decorated with surfboards, offering seasonal beers on tap, casual Tex-Mex food & Sunday brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.9775,
        total_orders: 19.26,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Cajun, Cheesesteak, Wine, Beer, French, French Onion Soup, Philly Cheesesteak',
        annual_dol: 78.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7494,
        store_longitude: -73.9832,
        total_orders: 1368.87,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Salad, Beer, Wine, Irish, Warm, Comfortable, Friendly, Casual, Fantastic',
        annual_dol: 1417.07,
        description:
            'Standard Irish pub with Guinness on draft, fish & chips on the menu & plenty of drink deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.9257,
        total_orders: 103.6,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Beer, Wine, Karaoke, Rich, American, Delicious, Traditional, Local, Great, Casual',
        annual_dol: 115.58,
        description:
            'Local hangout steps from the N train, offering drink specials, bar food & lots of TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.983,
        total_orders: 1235.14,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Beer, Mexican, Wine, Bourbon, Bbq, Irish, American, Contemporary, Intimate, Friendly',
        annual_dol: 1289.54,
        description:
            '"5th& MAD located in Midtown Manhattan is a bar, restaurant and so much more. A bi-level space that lends itself perfectly to hosting events for groups of 10 to 600. INSIDER at 5th& MAD is our new restaurant taking center stage on the main floor. Head Chef Carlos Cruz and his team have put together a menu that is perfect for sharing. We have two full-service bars. The 40-foot bar on the main floor marries the casual bar vibe that runs throughout with a casual dining experience while The Liquor Rooms on the mezzanine level is the perfect intimate gathering place for an after work catch up, pre-show cocktails, that secret rendezvous or a late night party!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7249,
        store_longitude: -73.9479,
        total_orders: 771.38,
        neighborhood: 'Greenpoint',
        keywords:
            'Steak, Tasty, Seafood Paella, Phenomenal, Friendly, Fun, Casual, Local, Great, Unique',
        annual_dol: 886.63,
        description:
            'Industrial-style venue featuring grilled meats, seafood & other Portuguese classics, plus sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -73.9596,
        total_orders: 1056.78,
        neighborhood: 'Greenpoint',
        keywords:
            'Lobster, Spanish, Mexican, Martini, Wine, Italian, Polish, Perfect, Fresh, Great',
        annual_dol: 4319.76,
        description:
            '"A Spanish seafood bar, serving cocktails, wine, sherry, raw bar, and conservas."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.691,
        store_longitude: -73.9249,
        total_orders: 1167.49,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Burger, Beer, Mexican, Vodka, Cool, Great, Campari, Casual',
        annual_dol: 1298.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6976,
        store_longitude: -73.9361,
        total_orders: 1457.74,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Steak, Hotdog, Beer, Coffee, American, Organic, Modern, Casual, Local, Fresh',
        annual_dol: 1520.94,
        description:
            'Cool, brick-lined haunt for modern American comfort food & craft cocktails, plus espresso drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -73.9464,
        total_orders: 395.34,
        neighborhood: 'Williamsburg',
        keywords: 'Shrimp Cocktail, Great, Casual',
        annual_dol: 523.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6918,
        store_longitude: -73.9429,
        total_orders: 16.76,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Salad, Dessert, Venezuelan, Colombian, Dominican, Delicious, Trendy, Latin, Sweet, American',
        annual_dol: 26.46,
        description:
            'This family-run Venezuelan eatery serves arepas, empanadas & exotic juices in a no-frills setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7312,
        store_longitude: -73.9889,
        total_orders: 243.79,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Vegan, Gluten Free, Vegetarian, Tasty, Italian, Beer, Asian, Tex-Mex, Healthy, Classy',
        annual_dol: 996.54,
        description:
            '"TLK (Tiger Lily Kitchen) is a female owned NYC restaurant and bar that offers healthy gluten free Asian dining with vegan options."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8514,
        store_longitude: -73.9397,
        total_orders: 1212.3,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords: 'Burger, Sushi, Pizza, Vegan, Salad, Mexican, Beer, Italian, Wine, Peruvian',
        annual_dol: 1264.86,
        description:
            'Eclectic establishment with an Irish theme, many beers & a menu of pub grub & New American entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7936,
        store_longitude: -73.9723,
        total_orders: 345.93,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Burger, Vegetarian, Salad, Roast Turkey, Mexican, Turkey Sandwich, Canadian, Coffee, Roast Beef',
        annual_dol: 368.66,
        description:
            'Retro kitschy decor sets the scene for traditional diner fare from egg creams to pork chops.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8045,
        store_longitude: -73.9668,
        total_orders: 110.89,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Mexican, Local, Casual, Healthy, Recommended',
        annual_dol: 575.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.801,
        store_longitude: -73.9618,
        total_orders: 118.06,
        neighborhood: 'Manhattan Valley/UWS',
        keywords: 'Italian, Pizza, Delicious, Wine, Smoky, Authentic, Fresh, Traditional, Casual',
        annual_dol: 148.27,
        description:
            'Casual, earthy Italian restaurant offering wood-fired pizza, wine & spirits & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7537,
        store_longitude: -73.9681,
        total_orders: 3713.75,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Japanese, Upscale, Casual, Chill, Fun, Great',
        annual_dol: 4006.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6139,
        store_longitude: -74.033,
        total_orders: 275.08,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Pizza, Italian, Vegetarian, Gourmet, Dessert, Delicious, Fresh, Polished, Sophisticated, Awesome',
        annual_dol: 287.01,
        description:
            'Sophisticated Italian-American eatery serving coal-fired pizzas, baked sandwiches & wings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9688,
        total_orders: 884.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Tasty, Trendy, Delicious, Mexican, Warm, Romantic, Salad, Cozy, Inviting',
        annual_dol: 964.17,
        description:
            'Woody wine bar with Old World & California wines, plus simple eats & an all-you-can-drink brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9844,
        total_orders: 24132.87,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Elegant, Wine, Urban, Modern, Great, Rooftop, Friendly, Bold, Cool',
        annual_dol: 27167.68,
        description:
            "Understated rooms with floor-to-ceiling windows provide Wi-Fi, HDTVs and minibars. Upgraded rooms have city views. Suites add sofas, furnished balconies and/or interconnecting bedrooms. Room service is offered 24/7.There's an elegant bar, and a restaurant with a rooftop terrace overlooking Times Square. Other amenities include an entertainment venue and a 24-hour gym. Valet parking is available, as is breakfast.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7414,
        store_longitude: -73.9928,
        total_orders: 22242.17,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Burger, Upscale, Wine, Nachos, Spicy, American, Spacious, Shawarma, Modern',
        annual_dol: 22258.25,
        description:
            'Spacious sports bar featuring creative cocktails & pub fare with a twist, plus frequent DJ music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.779,
        store_longitude: -73.9507,
        total_orders: 740.43,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Wine, Delicious, Irish, Fun, Friendly, Wonderful, Great, Casual, Local',
        annual_dol: 806.95,
        description:
            'Veteran watering hole offering sports on TV, darts & more in a relaxed, no-frills setup.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8727,
        store_longitude: -73.8542,
        total_orders: 473.09,
        neighborhood: 'East Bronx/Williamsbridge/Laconia',
        keywords:
            'Steak, Chicken Parmigiana, Cajun, Shrimp Cocktail, Argentinian, Bbq, Tasty, Bbq Ribs, French, American',
        annual_dol: 597.81,
        description:
            'Comfort food, cocktails & desserts served in a classic chrome & mirror diner setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6271,
        store_longitude: -73.6403,
        total_orders: 538.99,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Salad, Cajun, Fried Clams, Cajun-Style, Bbq, Beer, Fried Shrimp',
        annual_dol: 1546.52,
        description:
            'Unpretentious, energetic seafood eatery specializing in crabs, oysters, shrimp & other shellfish.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9895,
        total_orders: 16.71,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Spanish, Italian, Trendy, Delicious, Filet Mignon, Casual, Local, Classic',
        annual_dol: 68.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7546,
        store_longitude: -73.9988,
        total_orders: 1644.41,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Wine, Italian, Comfortable, Beer, Clean, Friendly, Modern, Great, Local',
        annual_dol: 2118.7,
        description:
            'The relaxed, airy rooms provide free Wi-Fi, flat-screen TVs, minifridges, and tea and coffeemakers. Suites add sofabeds and/or living rooms. Some offer city views.Amenities include a restaurant/bar, a 24-hour fitness center and coin-operated laundry facilities. Breakfast is available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.9863,
        total_orders: 1324.49,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Rooftop, Hotel, Wine, Beer, Adventurous, Romantic, Awesome, Amazing, Great, Perfect',
        annual_dol: 5414.09,
        description:
            '"Very casual rooftop bar with an amazing view of the Empire State Building. K-32 has one of the best craft beer selection along with wide selection of wines & spirits and we also offer bar food"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6907,
        store_longitude: -73.9158,
        total_orders: 117.22,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Coffee, Ale, Wine, Upscale, Casual, Chill, American, Friendly',
        annual_dol: 479.15,
        description: '"Local bar. Drinks and snacks."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8384,
        store_longitude: -73.783,
        total_orders: 368.09,
        neighborhood: 'City Island',
        keywords: 'Great, Diverse',
        annual_dol: 400.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.675,
        store_longitude: -74.0163,
        total_orders: 753.4,
        neighborhood: 'Red Hook',
        keywords:
            'Lobster, Lobster Roll, Shrimp Cocktail, Wine, Beer, Spicy, Sweet, Perfect, Relaxed, Great',
        annual_dol: 1025.62,
        description:
            'Relaxed seafood shack offering crab & raw oysters, plus outdoor seating with water views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7486,
        store_longitude: -73.9427,
        total_orders: 12.44,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Wine, Coffee, Salad, Elegant, Crisp, Italian, Hotel, Amazing, Fresh, Great',
        annual_dol: 50.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7339,
        store_longitude: -73.9911,
        total_orders: 5676.87,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Chocolate Cake, Cheesecake, Steak, Vegetarian, Gluten Free, Dessert, Coffee, Italian, Savory',
        annual_dol: 5797.33,
        description:
            'Retailer offering chocolate & related novelties, including seasonal & special-occasion gifts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7319,
        store_longitude: -73.9964,
        total_orders: 1229.55,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Tequila, Whiskey, Vodka, Gin, Italian, Rich, Rum, Warm, Cozy',
        annual_dol: 1282.86,
        description:
            'Old-fashioned restaurant offering Italian-inspired meals & cocktails, plus happy-hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -73.9944,
        total_orders: 88.8,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Chinese, American, Modern, Local, Casual, Authentic, Artistic',
        annual_dol: 362.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7308,
        store_longitude: -73.9858,
        total_orders: 580.07,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegan, Pizza, Italian, Gluten Free, Wine, Meatball, Beer, Casual, Traditional, Friendly',
        annual_dol: 605.22,
        description:
            'Classic red-sauce specialties and vegan versions are served at this vintage-1908 Italian eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9889,
        total_orders: 141.83,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Sushi, Salad, Wine, Coffee, Japanese, Beer, Sashimi, Spicy, Casual, Local',
        annual_dol: 534.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -73.9859,
        total_orders: 10.82,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Korean, Pizza, Mexican, Norwegian, Coffee, Turkey Sandwich, Cozy, Upscale, Modern, Casual',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8508,
        store_longitude: -73.9325,
        total_orders: 139.26,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Mexican, Tacos, Delicious, Premium, Authentic, Casual, Omelette, Great, Incredible',
        annual_dol: 158.85,
        description:
            'Low-key eatery offering familiar Mexican eats, especially tacos, plus margaritas & sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7533,
        store_longitude: -73.9689,
        total_orders: 2349.96,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Karaoke, Fun, Great, Perfect, Casual',
        annual_dol: 2809.45,
        description:
            'A selection of rooms, a huge song list & a full drinks menu adds to the fun at this Karaoke palace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.9773,
        total_orders: 5055.94,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Italian, Tasty, Salad, Upscale, Elegant, Hotel, Bellini, Premium, Classic, Fresh',
        annual_dol: 5275.15,
        description:
            'Upscale scene where an international crowd tucks into Italian food chased with Bellinis.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.7715,
        total_orders: 7.88,
        neighborhood: 'Bayside',
        keywords: 'Korean, Beer, Trendy, Wine, Spicy, Bbq, Eclectic, Peruvian, Japanese, Asian',
        annual_dol: 37.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.8335,
        total_orders: 502.8,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Steak, Lobster, Salad, Wine, Swiss, French, Hotel, Premium, Asian, Fresh',
        annual_dol: 2055.28,
        description:
            '"Prime Met Steakhouse is the newest Rooftop + Lounge experience in Flushing 12th floor of the new Renaissance New York Flushing Hotel at Tangram"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9746,
        total_orders: 3019.4,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Lobster, Steak, Salad, Shrimp Cocktail, Italian, Coffee, Wine, Martini, Delicious',
        annual_dol: 3165.32,
        description:
            'Handsome American restaurant by designer Ralph Lauren, serving classic dishes, bar bites & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.715,
        store_longitude: -74.0084,
        total_orders: 2112.93,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Hotdog, Burger, Beer, Whiskey, Wine, Fun, Irish, Friendly, Perfect, Classy',
        annual_dol: 2613.67,
        description:
            'Bar with a honky-tonk feel, country tunes on the jukebox & female bartenders.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -74.0094,
        total_orders: 367.29,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Beer, Italian, Casual',
        annual_dol: 433.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.9692,
        total_orders: 235.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Beer, Zombie, Salad, Margarita, Spicy, Irish, Fun, Smoky, Sweet',
        annual_dol: 361.66,
        description:
            'Beer hall devoted to craft brews on tap, plus a range of burgers, shareable bites & loaded waffles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6766,
        store_longitude: -73.6824,
        total_orders: 568.18,
        neighborhood: '',
        keywords: 'Pizza, Italian, Wine, Rustic, Casual, Southern, Local, Authentic, Chic, Fresh',
        annual_dol: 697.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5833,
        store_longitude: -73.9865,
        total_orders: 120.3,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Salad, Burritos, Delicious, Warm, Local, Casual, Signature',
        annual_dol: 553.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7431,
        store_longitude: -73.9895,
        total_orders: 2022.24,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Tequila, Chocolate Cake, Tacos, Vodka, Fried Catfish, Beer, Margarita, Wine, Martini, Tasty',
        annual_dol: 2069.06,
        description:
            'Luxury sports bar co-owned by hip-hop mogul Jay-Z, with huge plasma TVs & multiple private lounges.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9911,
        total_orders: 343.43,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords: 'Flavorful, Tasty, Beer, Vietnamese, Coffee, Stylish, Thai, Latin, Sweet, Classy',
        annual_dol: 1293.93,
        description:
            'Stylish option serving banh mi sandwiches, pho, noodles & meat dishes, plus smoothies & iced coffee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6948,
        store_longitude: -73.953,
        total_orders: 3232.95,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Beer, Spacious, Sweet, American, Vintage, Japanese, Great, Cool, Excellent, Traditional',
        annual_dol: 3989.19,
        description:
            'Cocktails & beer offered in a narrow, humble space with vintage furniture & backyard picnic tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7387,
        store_longitude: -73.9835,
        total_orders: 1671.75,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Beer, Ale, Bourbon, Relaxed, Delicious, Hotel, Friendly, American, Moscow Mule, Casual',
        annual_dol: 1707.23,
        description:
            'Relaxed, dimly-lit destination for handcrafted cocktails, plus brews on tap & New American bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7384,
        store_longitude: -73.9857,
        total_orders: 9897.47,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Wine, Hotel, Rich, Upscale, Trendy, Apple Pie, Romantic, Premium, Rum, Gin',
        annual_dol: 34745.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6955,
        store_longitude: -73.9964,
        total_orders: 596.59,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Fun, Great',
        annual_dol: 670.65,
        description:
            'Established members-only squash club also offering indoor tennis courts, dining & a fitness center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.741,
        store_longitude: -73.9903,
        total_orders: 498.49,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Salad, Swiss, Russian, Delicious, Vintage, Classic, Reuben, Casual, Fresh, Great',
        annual_dol: 2109.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9916,
        total_orders: 189.91,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Salad, Margarita, Burritos, Delicious, Local, Casual',
        annual_dol: 772.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7702,
        store_longitude: -73.9095,
        total_orders: 147.32,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'Cheesecake, Trendy, French, Premium, Greek, Great, Casual',
        annual_dol: 173.27,
        description:
            'Comfy hookah lounge with a full bar, lots of TVs, pool tables, patio, Greek eats & trendy decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7253,
        store_longitude: -74.0038,
        total_orders: 828.05,
        neighborhood: 'South Village/SoHo',
        keywords: 'Wine, Exquisite, Healthy, Perfect, Great, Fresh, Fine, Falafel, Scallops',
        annual_dol: 3119.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7793,
        store_longitude: -73.9776,
        total_orders: 1278.07,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Pizza, Italian, Gluten Free, Meatball, Bellini, Bruschetta, Delicious, Homemade, Artisanal',
        annual_dol: 1401.63,
        description:
            'Longtime Italian eatery with a pasta-centric menu & casual quarters complete with sidewalk seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8028,
        store_longitude: -73.9564,
        total_orders: 81.5,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Steak, Vegan, Hamburger, Salad, Tasty, Wine, Beer, Italian, French Toast',
        annual_dol: 137.87,
        description:
            'Corner tavern/beer garden with extensive food & drink menus in a spacious indoor/outdoor setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.9819,
        total_orders: 1556.56,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Champagne, Wine, Upscale, Sparkling, Romantic, Delicious, Whiskey, Premium, Fun, Cozy',
        annual_dol: 1624.05,
        description:
            'Upscale bar dedicated to champagne, with a candlelit lounge, fireplaces & weekly live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.985,
        total_orders: 3401.73,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Wine, Japanese, Trendy, Spicy, Cozy, Friendly, Sweet, Casual, Local',
        annual_dol: 3631.49,
        description:
            'Cozy, brick-walled Japanese eatery & sake bar offering yakitori, noodles & set-price party menus.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8123,
        store_longitude: -73.9515,
        total_orders: 3137.69,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Wine, French Toast, Coffee, French, Rustic, Beer',
        annual_dol: 3522.73,
        description:
            'French bistro classics, including breakfasts, in a light-filled space with exposed-brick walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7629,
        store_longitude: -73.986,
        total_orders: 1379.13,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Tasty, Italian, French Toast, Stylish, Thai, French, Dutch, Spectacular, American',
        annual_dol: 1421.8,
        description:
            'Stylish eatery serving elevated food & high-concept cocktails, plus seating in a train car replica.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9835,
        total_orders: 1741.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Mexican, Steak, Tex-Mex, Tasty, Spectacular, Latin, Mojito, Perfect, Rooftop, Great',
        annual_dol: 1816.64,
        description:
            'Lounge, Latin-style club & Mexican eatery over 3 floors at one Theater District address.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7646,
        store_longitude: -73.9825,
        total_orders: 17.21,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Applejack, Salad, Steak, Shrimp Cocktail, Wine, French Toast, Tasty, Swiss, French, Thai',
        annual_dol: 34.73,
        description:
            'Old-style diner with a classic menu, situated near Theater District venues & Midtown hotels.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -73.9872,
        total_orders: 2113.56,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Ale, Spacious, Dominican, Ample, Fancy, Casual, Laid-Back, Lit',
        annual_dol: 2205.19,
        description: 'Casual bar with a film-noir vibe, draft beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.722,
        store_longitude: -73.9931,
        total_orders: 8245.19,
        neighborhood: 'Bowery',
        keywords: 'Hotel, Wine, Beer, Gin, French, Cozy, Rooftop, Juicy, Warm, Crisp',
        annual_dol: 9826.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.721,
        store_longitude: -73.9898,
        total_orders: 2629.35,
        neighborhood: 'Lower East Side',
        keywords:
            'Whiskey, Elegant, Cozy, Intimate, Perfect, Awesome, Sidecar, Fantastic, Casual, Local',
        annual_dol: 3980.56,
        description:
            'Serves hundreds of whiskeys & other liquors in a lean space with stand-up & sit-down bars.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9106,
        store_longitude: -73.8968,
        total_orders: 32,
        neighborhood: 'North Riverdale',
        keywords:
            'Wine, Delicious, Spanish, American, Latin, Fresh, Great, Traditional, Casual, Attentive',
        annual_dol: 130.81,
        description:
            '"Best of Both Worlds, Spanglish Restaurant Group brings traditional Spanish and American Dishes to the Bronx. Located on the Border of Bronx /Yonkers"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7654,
        store_longitude: -73.9759,
        total_orders: 61.33,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Luxurious, Hotel, Stylish, Iconic, Dessert, Premium, Healthy, Innovative, Vibrant',
        annual_dol: 250.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -74.0006,
        total_orders: 2132.31,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Salad, Dessert, Gin, Beer, Homemade, Sweet, Modern, Casual, Rare, Classic',
        annual_dol: 2224.76,
        description:
            'Popular neighborhood watering hole welcomes regulars with a pool table & jukebox till the wee hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7083,
        store_longitude: -74.0017,
        total_orders: 246.64,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Thai, Salad, Tasty, Steak, Vegan, Organic, Traditional, Cozy, Authentic, Contemporary',
        annual_dol: 448.76,
        description:
            '"We have a cozy indoor "Thai Garden" dining room and heated patios and delivery to all Downtown Manhattan! Located in the historic district of South Street Seaport, on the corner of Peck Slip and Water Street, THAI Farm Kitchen Seaport is an organic authentic Thai restaurant! We offer Thai Food Delivery in all of Downtown New York. We cook the best TOM YUM, TOM KHA soup. Authentic Green Papaya Salad, PHAD THAI, and Green Curry. And many more Thai dishes! Thai Farm Kitchen Seaport offers organic, authentic, and traditional Thai cuisine! We are using the highest quality ingredients, organic produce, and characterized by bold flavors and typical Thai “farm to table” serving style! Best Thai Restaurant in New York!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7157,
        store_longitude: -73.9444,
        total_orders: 1181.9,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Wine, Beer, Mediterranean, Creamy, Delicious, American, Casual, Welcoming, Caribbean',
        annual_dol: 1771.7,
        description:
            'American joint serving a hearty, vegetable-focused menu plus beer & wine in a tiny space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.714,
        store_longitude: -73.9609,
        total_orders: 181.73,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Whiskey Sour, Steak, Flavorful, Whiskey, Wine, Beer, Salad, Martini, Four Horsemen, Mediterranean',
        annual_dol: 318.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7252,
        store_longitude: -73.983,
        total_orders: 984.14,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Salad, Argentinian, Wine, Delicious, Brazilian, Authentic, Fun, Sangria, Excellent',
        annual_dol: 1071,
        description:
            'Skirt steak, fries & plenty of chimichurri sauce are washed down with wines, including many malbecs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 39.98,
        neighborhood: 'New Springville/Mid Island',
        keywords: 'Mexican, Warm, Delicious, Local, Healthy, Casual, Perfect',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7457,
        store_longitude: -73.9847,
        total_orders: 681.92,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Pizza, Korean, Spicy, Delicious, Chinese, Asian, American, Hotel, Bibimbap, Casual',
        annual_dol: 2787.46,
        description:
            '"PALPAL, a lively space with an open Wok-station, features a variety of Korean dishes with Asian touch, energy and heat from the Wok."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.807,
        store_longitude: -73.9276,
        total_orders: 2336.52,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Mexican, Salad, Turkey Sandwich, Beer, Bbq, Ale, American, Pulled Pork, Coleslaw, Southern',
        annual_dol: 4543.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7204,
        store_longitude: -74.0054,
        total_orders: 1127.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Cheesecake, Cheesesteak, Burger, Pizza, Steak, Mexican, Salad, Whiskey, Beer, Wine',
        annual_dol: 1417.96,
        description:
            'Loftlike tavern & bar offering burgers & pizzas, many beers on tap & TVs to watch a game.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -73.9944,
        total_orders: 52.42,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Steak, Coffee, Salad, Green Curry, Bbq, French Toast, Red Eye, French, Rooftop',
        annual_dol: 214.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7194,
        store_longitude: -73.8423,
        total_orders: 13753.57,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Steak, Wine, Lebanese, Cozy, Memorable, Great, Baba Ghanouj, Casual',
        annual_dol: 14453.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7577,
        store_longitude: -73.7854,
        total_orders: 2361.39,
        neighborhood: 'Auburndale/Flushing',
        keywords:
            'Cheesecake, Pizza, Salad, Coffee, French Toast, French, Greek, Baklava, Casual, Fun',
        annual_dol: 3126.16,
        description:
            'Long-running Greek cafe known for pastries, coffee & cocktails in casual digs with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7459,
        store_longitude: -73.9566,
        total_orders: 1047.7,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Tacos, Steak, Mexican, Tequila, Salad, Margarita, Chili Con Carne, Wine, Spanish',
        annual_dol: 1193.26,
        description:
            'Old-country Mexican dishes in rustic-chic quarters featuring Spanish tiling & exposed beams.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7263,
        store_longitude: -74.0022,
        total_orders: 28.14,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Pizza, Salad, Gluten Free, Delicious, Cozy, Mediterranean, Hotel, Bright, Casual, Approachable',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7255,
        store_longitude: -73.9983,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Pizza, Italian, Creative, Perfect',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.8874,
        total_orders: 181.13,
        neighborhood: 'Jackson Heights/Elmhurst',
        keywords: 'Mexican, Karaoke, Colombian, Friendly, Premium, Casual, Great, Diverse',
        annual_dol: 206.61,
        description:
            'Old-school, Latin-flared gay bar known for cocktails, happy hours, karaoke nights & drag shows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7211,
        store_longitude: -73.9935,
        total_orders: 686.29,
        neighborhood: 'Bowery',
        keywords:
            'Italian, Steak, Pizza, Wine, Beer, French, Delicious, Premium, American, Intimate',
        annual_dol: 781.64,
        description:
            '"ENOTECA ☆ BIRRERIA ☆ WINE BAR ☆ BEER GARDEN ☆ WOOD FIRED PIZZA ☆ PRIVATE EVENTS"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9851,
        total_orders: 1355.94,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegetarian, Vegan, Chinese, Wine, Delicious, Stylish, Homemade, Perfect, Traditional, Spectacular',
        annual_dol: 2037.64,
        description:
            'Modern Chinese haunt serving dishes made with local ingredients, including some from its own garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7136,
        store_longitude: -73.997,
        total_orders: 21.26,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Chinese, Upscale, Spacious, Yummy, Sweet, Classic, Kung Pao, Great, Local, Fine',
        annual_dol: 86.9,
        description:
            'Massive, bustling Cantonese mainstay ferrying a wide variety of dim sum via fast-moving carts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9771,
        total_orders: 5775,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Vegetarian, Salad, Vodka, Wine, Beer, South American, Chilean',
        annual_dol: 5902.18,
        description:
            'Upscale Brazilian chain for all-you-can-eat meat carved tableside plus an extensive salad bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.9869,
        total_orders: 2578.76,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Gourmet, Delicious, Stylish, Organic, Healthy, Eclectic, Authentic, French',
        annual_dol: 2633.47,
        description:
            'Relaxed, stylish American eatery for sandwiches, salads & breakfast classics, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9796,
        total_orders: 421.05,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Hamburger, Salad, Gourmet, Beer, Wine, Fruity, Trendy, French, Hotel',
        annual_dol: 439.31,
        description:
            'Creative gourmet burgers, modern sides, shakes & draft beers served in a small, casually hip space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7649,
        store_longitude: -73.979,
        total_orders: 163.42,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Wine, Elegant, Fun, Great',
        annual_dol: 182.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8084,
        store_longitude: -73.9597,
        total_orders: 435.62,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Salad, Steak, Tomato Soup, Gluten Free, Beer, Creamy, American, Asian, Greek, Cozy',
        annual_dol: 1529.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.8944,
        total_orders: 38.3,
        neighborhood: 'Jackson Heights',
        keywords: 'Vegetarian, Coffee, American, Modern, Vintage, Casual, Retro, Diverse',
        annual_dol: 81.64,
        description:
            'Casual diner with a vintage vibe & a sizable American menu featuring sandwiches, pasta & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8636,
        store_longitude: -73.9265,
        total_orders: 29.38,
        neighborhood: 'Fort George',
        keywords:
            'Salad, Chicken Caesar Salad, Dominican, Caesar Salad, Latin, American, Casual, Local, Authentic',
        annual_dol: 120.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6843,
        store_longitude: -73.9774,
        total_orders: 1581,
        neighborhood: 'Fort Greene/Park Slope',
        keywords:
            'Hamburger, Beer, Coffee, Chicken Wings, American, Delicious, Casual, Local, Classic, Lively',
        annual_dol: 1788.41,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6843,
        store_longitude: -73.9774,
        total_orders: 3966.16,
        neighborhood: 'Fort Greene/Park Slope',
        keywords:
            'Burger, Hamburger, Beer, New-York-Style, Dessert, Chicken Wings, French, Asian, Traditional, Sweet',
        annual_dol: 4065.63,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7419,
        store_longitude: -73.9974,
        total_orders: 4725.36,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Meatloaf, Wine, Margarita, Vodka, Naan Bread, American, Lively, Fun, Fresh',
        annual_dol: 4770.41,
        description:
            'Fashionable restaurant with American comfort food, a lively bar & a heavily gay clientele.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 295.56,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Lobster Roll, Salad, Wine, Beer, Spanish, Clam Chowder, Shawarma, Casual, Friendly',
        annual_dol: 1208.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6805,
        store_longitude: -73.9751,
        total_orders: 19.81,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hamburger, Salad, Beer, Bbq, Margarita, Wine, Caesar Salad, Delicious, Casual',
        annual_dol: 42.28,
        description:
            '"#1 Best Burger Joint in NYC Zagat 2017 & 2018, #1 Burger TripAdvisor, Craft Beer & Full Liquor, Cocktails. Chef made burgers."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -74.0052,
        total_orders: 5.09,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tacos, Mexican, Beer, Margarita, Shrimp Cocktail, Casual, Local, Michelada, Signature, Great',
        annual_dol: 72.81,
        description:
            'Casual, bustling counter serve offering Mexican seafood, a raw bar, beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6358,
        store_longitude: -73.7298,
        total_orders: 617.46,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Gourmet, French, Israeli, Premium, Spectacular, Traditional, Hummus, Creative',
        annual_dol: 795.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7239,
        store_longitude: -73.9853,
        total_orders: 145.17,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Vegan, Burger, Salad, Coffee, Wine, Gourmet, Organic, Beer, Delicious, Quaint',
        annual_dol: 272.77,
        description:
            'Mediterranean mains, vegan cakes & wine in a romantic eatery with eclectic decor & outdoor tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7139,
        store_longitude: -73.9501,
        total_orders: 7.55,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Magical, Spanish, Spicy, Creamy, Meatball, Rustic, Authentic, Excellent, Fine',
        annual_dol: 30.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6706,
        total_orders: 336.45,
        neighborhood: '',
        keywords:
            'Steak, Tacos, Hamburger, Beer, Wine, Nachos, French Onion Soup, French, Tangy, American',
        annual_dol: 499.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7653,
        store_longitude: -73.9841,
        total_orders: 3313.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Relaxed, Fun, Friendly, Irish, Amazing, Great, Casual, Dope, Unique',
        annual_dol: 3604.22,
        description:
            'Bar where over 100 shots with names like the Monica Lewinsky are on offer amid sultry decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9882,
        total_orders: 8899.65,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Wine, Luxurious, Upscale, Hotel, Beer, Vodka, Trendy, Margarita, Vibrant',
        annual_dol: 9576.99,
        description:
            "On the Hotel Indigo's 15th floor, this posh spot with terraces serves elevated bar food & cocktails.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7224,
        store_longitude: -73.9921,
        total_orders: 1547.44,
        neighborhood: 'Bowery',
        keywords: 'Tequila, Whiskey, Wine, Beer, Irish, Swiss, American, Prosecco, Sweet, Bright',
        annual_dol: 1580.27,
        description:
            'Creative cocktails, craft beer & American small plates served in an old-school space with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9853,
        total_orders: 768.17,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Steak, Salad, Wine, Italian, Beer, French Toast, French, Stylish, Delicious',
        annual_dol: 901.29,
        description:
            'American plates, unique cocktails & rotating craft beers served in casual digs with a funky mural.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.9914,
        total_orders: 504.88,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Wine, Chinese, Hotel, Spectacular, Fun, Comfortable, Sweet, Ramen, Friendly, Casual',
        annual_dol: 515.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7216,
        store_longitude: -73.9883,
        total_orders: 423.67,
        neighborhood: 'Lower East Side',
        keywords:
            'Sushi, Pizza, Hamburger, Gluten Free, Italian, Vietnamese, Spicy, Fried Rice, Thai, Fusion',
        annual_dol: 1731.82,
        description:
            'Easygoing outfit with outdoor seating serving bahn mi, noodle soup & other Vietnamese comfort food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9687,
        total_orders: 5.36,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Kebab, Beer, Salad, Pizza, Turkish, Dessert, Mediterranean, Friendly, Casual, Local',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.8893,
        total_orders: 28.14,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Casual, Latin, Friendly, Local',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6461,
        store_longitude: -73.583,
        total_orders: 10.94,
        neighborhood: '',
        keywords: 'Whiskey Sour, Whiskey, Pizza, Italian, Golfclub, American, Upscale, Casual',
        annual_dol: 44.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5365,
        store_longitude: -74.1517,
        total_orders: 92.08,
        neighborhood: 'Eltingville',
        keywords: 'Rich, Whiskey, Fabulous, Irish, Great, Casual',
        annual_dol: 107.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.747,
        store_longitude: -74.0084,
        total_orders: 97.22,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Hotel, Golfclub, Beer, Magical, Cozy, Welcoming, Spectacular, Friendly',
        annual_dol: 397.39,
        description:
            'Figure-skating lessons & ice-hockey games at a year-around ice rink with gear rentals & a snack bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7139,
        store_longitude: -73.9557,
        total_orders: 13663.37,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Beer, Vodka, Gourmet, Gin, Hurricane, Spicy, Nachos, Pinacolada, French',
        annual_dol: 13663.37,
        description:
            'Fried chicken, biscuits & other Southern dishes in a low-key saloon where you order at the bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6783,
        store_longitude: -73.9688,
        total_orders: 996.11,
        neighborhood: 'Prospect Heights',
        keywords:
            'Pizza, Salad, Italian, Wine, Beer, Gluten Free, American, Stylish, Sustainable, Contemporary',
        annual_dol: 1062.1,
        description:
            'Stylish eatery with an Italian-American menu based on local ingredients, plus a leafy back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7117,
        store_longitude: -73.9416,
        total_orders: 966.31,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Tequila, Stylish, Fun, Bright, Great, Prosecco, Classic, Fresh, Modern',
        annual_dol: 1282.95,
        description: '"Cocktail Bar and Lounge"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7108,
        store_longitude: -74.008,
        total_orders: 1489.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Hotel, Coffee, Playful, Awesome, Retro, Classic, Cool, Great, Hip',
        annual_dol: 1554.49,
        description:
            'Sleek, wood-paneled rooms come with free Wi-Fi Bang and Olufsen speakers and flat-screen TVs. Upgraded rooms add city views and writing desks.Amenities include a co-working space/buzzy cocktail lounge with retro arcade games, and a fitness center. Breakfast is available for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.984,
        total_orders: 4104.47,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Spicy, Smoky, Relaxed, Irish, Friendly, Lively, French, Awesome',
        annual_dol: 4282.43,
        description:
            "East Villagers down discounted cocktails on a lively patio during this pub's daytime happy hour.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6452,
        store_longitude: -74.1045,
        total_orders: 762,
        neighborhood: 'Mid Island/Randall Manor',
        keywords:
            'Delicious, Chinese, Elegant, Picturesque, Perfect, Artisanal, Great, Seasonal, Cultural, Biryani',
        annual_dol: 1863.78,
        description:
            '"One of New York City’s most unique wedding venues is Celebrate at Snug Harbor, a Cultural Center and Botanical Garden in Staten Island. With magnificent architecture, lush botanical gardens and artisanal cuisine. The lush 83 acre campus is home to several picturesque gardens including New York City’s only Chinese Scholar Garden as well as a Tuscan Garden, Rose Garden, Hidden Garden and White Garden. The gardens are the perfect setting for a ceremony al fresco."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -74.0074,
        total_orders: 522.94,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Cheesecake, Salad, Wine, Coffee, Italian, Upscale, Delicious, Elegant, Organic, Cozy',
        annual_dol: 544.47,
        description: 'Upscale, stone-walled trattoria specializing in traditional Italian recipes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5862,
        store_longitude: -73.6922,
        total_orders: 403.71,
        neighborhood: '',
        keywords:
            'Burger, Cheesesteak, Bbq, Beer, Bourbon, American, Friendly, Fun, Perfect, Great',
        annual_dol: 421.21,
        description:
            'Casual canteen & buzzy billiard bar serving up large portions of slow-cooked BBQ & beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7401,
        store_longitude: -73.7912,
        total_orders: 15.25,
        neighborhood: 'Fresh Meadows/Auburndale/Flushing',
        keywords: 'Fresh, Delicious, Sushi, Pizza, Friendly, Great, Hotel, Thai, Casual, Local',
        annual_dol: 62.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7461,
        store_longitude: -73.9834,
        total_orders: 1234.46,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Upscale, Trendy, Sushi, Gourmet, Urban, Premium, Rooftop, Intimate, Unique',
        annual_dol: 1260.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7178,
        store_longitude: -73.9589,
        total_orders: 295.15,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Steak, Salad, Margarita, Italian, Painkiller, Delicious, Pinacolada, Rum, Daiquiri',
        annual_dol: 368.13,
        description:
            'Seafood eatery where sandy floors, tropical drinks & a lively back patio lend a festive mood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7343,
        store_longitude: -74.0035,
        total_orders: 1575.57,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Beer, Upscale, American, Premium, Fun, Homemade, Amazing, French, Cool',
        annual_dol: 1675.45,
        description:
            'Cocktails & elevated bar fare in a smart setting with plush booths & big screens to watch the game.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8378,
        store_longitude: -73.8345,
        total_orders: 1306.87,
        neighborhood: 'Schuylerville/Pelham Bay/#5719',
        keywords: 'Wine, Salad, Beer, Mexican, Vegetarian, Cuban, Rum, Delicious, Elegant, Warm',
        annual_dol: 1334.6,
        description:
            'Intimate restaurant & mojito lounge serving classic & creative Cuban fare in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7326,
        store_longitude: -74.0096,
        total_orders: 606.08,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Italian, Meatball Sub, Meatball, Italian Sub, Beer, Cozy, Fun, Lively, Friendly, Urban',
        annual_dol: 675.33,
        description:
            "There's an anything-goes vibe at this well-worn haunt, where weekends attract a lively crowd.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7991,
        store_longitude: -73.943,
        total_orders: 35.88,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Kebab, Wine, Salad, Moroccan, Mediterranean, Chinese, Perfect, Friendly, Awesome, Cool',
        annual_dol: 146.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7069,
        store_longitude: -73.6658,
        total_orders: 478.15,
        neighborhood: '',
        keywords:
            'Lobster, Salad, Cajun, Beer, Delicious, Juicy, Fried Shrimp, Welcoming, Authentic, Casual',
        annual_dol: 923.8,
        description:
            '"Crafty Crab offers the freshest seafood and most authentic recipes in the area. We’re cooking up crab, crawfish, calamari, lobster, oysters, and mussels in the kitchen, made fresh daily! Offering the Best Quality Seafood Crafty Crab offers the freshest seafood and most authentic recipes in the area. When you walk into our restaurant, you’ll immediately experience Southern charm and a friendly, welcoming atmosphere to make you feel at home. Whether you’re stopping in for a business lunch, family dinner, date night, or a night out with friends, you’ve come to the perfect spot. Delicious home-style seafood with generous portions is what you can expect with every meal."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7654,
        store_longitude: -73.9178,
        total_orders: 808.84,
        neighborhood: 'Astoria',
        keywords:
            'Burger, Pizza, Salad, Hamburger, Coffee, Dessert, Delicious, Avocado Toast, Latin, Greek',
        annual_dol: 1814.46,
        description:
            'Bustling hot spot with a loungey vibe offers Greek bites plus cocktails & popular sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.771,
        store_longitude: -73.9565,
        total_orders: 4031.54,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Karaoke, Beer, Tasty, Irish, French, Greek, Local, Great, Cool, Casual',
        annual_dol: 4129.97,
        description:
            'Laid-back Irish pub with 100-foot-long bar featuring karaoke, DJs & floor space for dancing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7152,
        store_longitude: -74.0156,
        total_orders: 704.29,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Italian, Wine, Greek, Mediterranean, Hotel, Innovative, Welcoming, High-End, Great, Fresh',
        annual_dol: 2878.9,
        description:
            '"The Ancient Greek queen of the Mediterranean was crowned with the title of Anassa, given only to unique women with goddess-like qualities. The title, similar to a “queen,” was reserved for women who exuded rare qualities and benevolent leadership. Like the Ancient Greek Anassa, we believe our restaurant stands out, representing all of the finest recipes, culinary traditions and ingredients from Greece and the Mediterranean – always standing out for our quality and attention to detail. We look forward to welcoming you to Anassa Taverna and giving you a bit of our own… royal treatment that only an Anassa can give."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8577,
        store_longitude: -73.8863,
        total_orders: 1104.56,
        neighborhood: 'Belmont/West Bronx',
        keywords: 'Burger, Hamburger, Steak, Tacos, Pizza, Salad, Meatball, Beer, Wine, American',
        annual_dol: 1476.33,
        description:
            'Nachos, wings, pasta, ribs & cocktails served in an unpretentious bar with a neighborhood vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -73.9434,
        total_orders: 460.77,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Salad, Chinese, Beer, Karaoke, Korean, American, Cozy, Delicious, Contemporary, Southern',
        annual_dol: 460.77,
        description:
            'Casual spot for contemporary Taiwanese-American cooking, including buns, noodles & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8601,
        store_longitude: -73.8909,
        total_orders: 1515.49,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Steak, Burger, Mexican, Beer, Bourbon, Dessert, American, Delicious, Latin, Casual',
        annual_dol: 1796.15,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -73.9376,
        total_orders: 195.88,
        neighborhood: 'East Williamsburg',
        keywords: 'Vegan, Coffee, Salad, Casual, Authentic, Dan Dan',
        annual_dol: 800.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8529,
        store_longitude: -73.7895,
        total_orders: 241.77,
        neighborhood: 'City Island',
        keywords:
            'Vegetarian, Steak, Tasty, Nutritious, Beer, Organic, Delicious, Trendy, Innovative, American',
        annual_dol: 623,
        description:
            'This compact, modern-rustic tavern with a patio offers innovative comfort food, craft beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6775,
        store_longitude: -73.9981,
        total_orders: 33.15,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Wine, Pizza, Italian, German, Salad, Coffee, Flavorful, French Toast, French, Premium',
        annual_dol: 135.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.657,
        store_longitude: -73.7955,
        total_orders: 4695.56,
        neighborhood: 'Jamaica',
        keywords: 'Coffee, American, British, Global, Premium, Exceptional, Unique',
        annual_dol: 15329.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9881,
        total_orders: 5694.65,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Coffee, Japanese, Beer, Elegant, Western, Sweet, Casual, Lively, Chill',
        annual_dol: 6814.02,
        description:
            'Snazzy, compact Japanese cafe showcasing high-end coffee & sandwiches, plus sake & beer at night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5515,
        store_longitude: -74.1495,
        total_orders: 695.15,
        neighborhood: 'Great Kills',
        keywords:
            'Chocolate Cake, Italian, Delicious, Polished, Awesome, Perfect, Great, Fine, Casual',
        annual_dol: 760.74,
        description: '"Italian, Fine Dining, Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8515,
        store_longitude: -73.9349,
        total_orders: 958.9,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Burger, Mexican, Beer, American, Delicious, Friendly, Casual, Great, Fusion, Signature',
        annual_dol: 2578.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.7714,
        total_orders: 873.24,
        neighborhood: 'Bayside',
        keywords:
            'Tequila, Tequila Sunrise, Spanish, Wine, Mexican, Delicious, Sangria, Great, Excellent, Relaxed',
        annual_dol: 1188.76,
        description:
            'Relaxed, informal eatery featuring Spanish small plates & paella, plus wine & sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5771,
        store_longitude: -73.9524,
        total_orders: 847.54,
        neighborhood: 'Manhattan Beach',
        keywords:
            'Lobster, Wine, Beer, Whiskey, Bourbon, Delicious, Cozy, Elegant, Comfortable, American',
        annual_dol: 1937.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8864,
        store_longitude: -73.9099,
        total_orders: 255.29,
        neighborhood: 'Riverdale/West Bronx',
        keywords: 'Sushi, Salad, Dessert, Wine, Japanese, Delicious, Chilean, Rich, Asian, Ramen',
        annual_dol: 347.53,
        description:
            '"Palace of Japan offers delicious dining, takeout and delivery to Bronx, NY. Palace of Japan is a cornerstone in the Bronx community and has been recognized for its outstanding Sushi cuisine, excellent service and friendly staff. Our Sushi restaurant is known for its modern interpretation of classic dishes and its insistence on only using high quality fresh ingredients."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7541,
        store_longitude: -73.9662,
        total_orders: 2014.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Wine, Coffee, Italian, Upscale, Mexican, French Onion Soup, French, Savory, Caesar Salad',
        annual_dol: 2115.19,
        description:
            'Relaxed restaurant serving American cuisine with French touches in modern surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9653,
        total_orders: 143.88,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Salad, Pizza, Coffee, Meatball, Savory, Mediterranean, Greek, American, Bloody Mary',
        annual_dol: 167.71,
        description:
            'Airy-chic cafe specializing in American & Greek breakfast, lunch & dinner fare, plus coffee & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6293,
        store_longitude: -74.0229,
        total_orders: 592.9,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Coffee, Tasty, Ale, American, Traditional, Casual, Great, Terrific, Local',
        annual_dol: 659.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7044,
        store_longitude: -73.7979,
        total_orders: 1303.5,
        neighborhood: 'Jamaica',
        keywords: 'Cajun, Flavorful, Juicy, Creole, Authentic, Fun, Perfect, Casual, Signature',
        annual_dol: 1770.6,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -74.0032,
        total_orders: 654.92,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Pizza, Italian, Salad, Dessert, Trendy, Caesar Salad, Modern, Classy, Casual, Handcrafted',
        annual_dol: 800.8,
        description:
            'Naples-style handcrafted pizzas, pasta & panini, served with Italian wines, under the High Line.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7469,
        store_longitude: -73.9865,
        total_orders: 1078.5,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Wine, Fruity, Dessert, Elegant, Martini, Beer, Creamy, Comfortable, Mediterranean, Friendly',
        annual_dol: 1125.26,
        description:
            'French-Med bar & restaurant offering nearly 100 wines & champagnes, chocolates, cheeses & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.9804,
        total_orders: 1427.88,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Steak, Pizza, Salad, Wine, Beer, Coffee, Irish, Nachos, Delicious, Warm',
        annual_dol: 1517.67,
        description:
            'Sprawling, multi-level Irish bar & grill serving steak, sandwiches & traditional dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7542,
        store_longitude: -73.9847,
        total_orders: 178.44,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster Roll, Lobster, Cheesecake, Salad, Dessert, Champagne, New-York-Style, Mexican, Delicious, Beer',
        annual_dol: 338.04,
        description:
            'High-end harbor cruise where sit-down dinner, drinks & dancing come with views of the city.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6745,
        store_longitude: -73.9502,
        total_orders: 317.75,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Vegan, Dessert, Tasty, Trendy, Delicious, Upscale, Caribbean, Cozy, Romantic',
        annual_dol: 375.45,
        description:
            '"Located in the Heart of Crown Heights, Savvy Biatro & Bar was inspired by the diverse cultural diaspora of our American and Caribbean ancestral roots. Savvy offers a a fusion of crave worthy dishes in a vibrant & contemporary environment."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.9088,
        total_orders: 289.34,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Steak, Burger, Vegan, Vegetarian, Hamburger, Tequila, Beer, Gluten Free, Seafood Paella, Wine',
        annual_dol: 367.72,
        description:
            'The menu of Australian dishes at this relaxed eatery features many gluten-free & veggie options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9956,
        total_orders: 2279.69,
        neighborhood: 'Nolita',
        keywords: 'Thai, American, Classic, Casual, Sensational, Local, Sweet, Fine, Great',
        annual_dol: 4469.51,
        description:
            'Classic Thai dishes & drinks by a lauded restaurant group in a kitschy-cool, diner-style setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 280.31,
        neighborhood: 'Jackson Heights',
        keywords: 'Spicy, Baked Potato, Creamy, Fun, Great, Latin, American, Casual',
        annual_dol: 312.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -74.0025,
        total_orders: 3345.26,
        neighborhood: 'South Village/SoHo',
        keywords: 'Sushi, Salad, Tasty, Japanese, Cozy, Friendly, Premium, Fun, Fresh, Classic',
        annual_dol: 12371.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7471,
        store_longitude: -73.8902,
        total_orders: 184.13,
        neighborhood: 'Jackson Heights',
        keywords: 'Mexican, Ecuadorian, Trendy, American, Casual, Global, Traditional',
        annual_dol: 188.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.8811,
        total_orders: 63.89,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Hotdog, Burger, Beer, Hamburger, Pizza, Cheesesteak, Ale, Philly Cheesesteak, Bbq, Upscale',
        annual_dol: 209.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7216,
        store_longitude: -73.9958,
        total_orders: 196.69,
        neighborhood: 'Nolita',
        keywords:
            'Pizza, Gluten Free, Beer, Meatball, Wine, Salad, Delicious, Smoky, Premium, Fresh',
        annual_dol: 804.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5814,
        store_longitude: -73.8378,
        total_orders: 46.07,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords:
            'Lobster, Lobster Roll, Pizza, Salad, Spicy, English Muffin, American, Omelette, Farm-To-Table, Casual',
        annual_dol: 188.33,
        description: '"Fine Dining - Authentic Farm to Table Cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7047,
        store_longitude: -74.0168,
        total_orders: 789.79,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Italian, Dessert, Wine, Bruschetta, Asian, Classy, Indian',
        annual_dol: 943.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -74.0075,
        total_orders: 1056.65,
        neighborhood: 'Hudson Square',
        keywords:
            'Burger, Steak, Salad, Carrot Cake, Vegan, Breakfast Burrito, French Toast, French, Avocado Toast, American',
        annual_dol: 1296.48,
        description:
            'Unassuming American eatery serving market-oriented menu with an emphasis on unique vegetable dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7935,
        store_longitude: -73.9406,
        total_orders: 10386.09,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Burger, Rich, Dessert, American, Spanish, Warm, Traditional, Modern, Homemade',
        annual_dol: 10841.15,
        description:
            'Steaks, chops, seafood & drinks in a bustling, urban space with an open kitchen & DJ entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -73.6403,
        total_orders: 23.91,
        neighborhood: '',
        keywords:
            'Burger, Beer, Salad, American, Caesar Wrap, Irish, Caesar Salad, Local, Perfect, Authentic',
        annual_dol: 97.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.6559,
        total_orders: 7.23,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Coffee, American, Veal Parmigiana, Casual, Local, Classy',
        annual_dol: 30.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7833,
        store_longitude: -73.9784,
        total_orders: 2056.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Wine, Salad, Dessert, French, Beer, Organic, Mediterranean, Elegant, Crisp',
        annual_dol: 2172.5,
        description:
            'Bustling, French-inspired brasserie with a Riviera vibe, outdoor seating & a curated wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.6465,
        total_orders: 5.98,
        neighborhood: '',
        keywords:
            'Flavorful, Steak, Coffee, Fun, American, Cool, Casual, Southern, Local, Excellent',
        annual_dol: 24.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7666,
        store_longitude: -73.9203,
        total_orders: 10.82,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tequila, Mexican, Burger, Vodka, Champagne, Flavorful, Breakfast Burrito, Tex-Mex, Thai, American',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.692,
        store_longitude: -73.8344,
        total_orders: 28.14,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords:
            'Salad, Shrimp Cocktail, Hotel, Delicious, Casual, Greek, Local, American, Western',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6606,
        store_longitude: -73.9904,
        total_orders: 81.72,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Steak, Cheesesteak, Salad, Delicious, Spicy, French, French Onion Soup, American, Sweet, Rare',
        annual_dol: 334.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7427,
        store_longitude: -73.952,
        total_orders: 239.42,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Italian, Dessert, Cozy, Homey, Southern, Homemade, Casual, Farm-To-Table, Authentic',
        annual_dol: 254.8,
        description:
            'A fireplace adds a homey touch to this longtime Italian joint with a basic menu & brick-oven pizzas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -73.9896,
        total_orders: 3692.37,
        neighborhood: 'East Village/Bowery',
        keywords: 'Vodka, Russian, Moscow Mule, Fun, Casual',
        annual_dol: 3770.71,
        description:
            'Readings & publication parties are regular events at this tiny Soviet-themed watering hole.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -73.9896,
        total_orders: 2183.39,
        neighborhood: 'East Village/Bowery',
        keywords: 'Fun, Lively, Amazing, Memorable, Great, Excellent, Classic, Cool, Diverse',
        annual_dol: 2367.28,
        description:
            'Lively bar & candlelit showroom serving classic drinks & cocktails with shows 7 nights a week.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -73.9895,
        total_orders: 421.7,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Burger, Mexican, Tequila, Mimosa, Margarita, Grasshopper, Spicy, Funky, Contemporary, Modern',
        annual_dol: 458.91,
        description:
            'Modern Mexican joint putting a creative spin on traditional eats & drinks in a dark, funky interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7929,
        store_longitude: -73.9721,
        total_orders: 205.64,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Hotdog, Tacos, Mexican, Tequila, Steak, Margarita, Gluten Free, Quesadillas, American, Delicious',
        annual_dol: 258.31,
        description:
            'Mexican street food served in a laid-back eatery with decor inspired by the 1986 FIFA World Cup.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6168,
        store_longitude: -74.0683,
        total_orders: 874.67,
        neighborhood: 'Rosebank',
        keywords:
            'Cajun, Burger, Cajun-Style, Cheesecake, Jambalaya, Beer, Wine, Italian, Mexican, Delicious',
        annual_dol: 990.06,
        description:
            'New Orleans Cajun cooking & strong drinks in a festive space that channels the French quarter.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5923,
        store_longitude: -74.0871,
        total_orders: 5.54,
        neighborhood: 'Dongan Hills',
        keywords: 'Burger, Steak, Italian, Savory, Rustic, Spicy, American, Homemade, Fancy, Local',
        annual_dol: 22.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7414,
        store_longitude: -73.9874,
        total_orders: 7546.28,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Hotel, Stylish, Elegant, Iconic, Modern, Premium, Sophisticated, Perfect, Great, Cool',
        annual_dol: 8032.5,
        description:
            "The sophisticated rooms feature free Wi-Fi, flat-screen TVs, minibars and rainfall showers. Studios have entrance foyers, and expansive suites include vaulted ceilings and living areas. Penthouse suites add kitchens, plus separate living and dining areas. Most quarters offer park, city and/or Empire State Building views. Room service is available 24/7.Amenities include a posh restaurant and 2 swanky bars. There's also a 24-hour gym, a luxe spa and event space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9838,
        total_orders: 2489.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Irish, Beer, Burger, American, Traditional, Authentic, Awesome, Amazing, Pulled Pork, Great',
        annual_dol: 2760.34,
        description:
            '"Irish bar and restaurant in the heart of Times Square .2 bars 2 floors with additional outdoor covered seating .live music Thursday Friday Saturday and Sunday nights"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -73.9912,
        total_orders: 257.7,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Cheesecake, Chicken Parmigiana, Italian, Beer, Tasty, Wine, Ale, Fried Chicken, Fresh',
        annual_dol: 276.27,
        description:
            'Airy, casual eatery offering super-thin-crust pizza & basic Italian dishes plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7578,
        store_longitude: -73.9836,
        total_orders: 740.36,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Stylish, Wine, Spacious, Modern, Premium, Contemporary, Global, Classic, Clean',
        annual_dol: 3018.08,
        description:
            'The chic rooms and suites have Wi-Fi, minibars and yoga mats. All feature pop art and marble bathrooms; some have balconies.Amenities include a 24-hour fitness center, valet parking (fee), free loaner bikes, and a lobby lounge decorated with New York photography.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9843,
        total_orders: 591.72,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Vegetarian, Pizza, Salad, Wine, Beer, Dessert, Upscale, Trendy, Spicy',
        annual_dol: 621.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6907,
        store_longitude: -73.9966,
        total_orders: 2179.49,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Burger, Mexican, Beer, Wine, Upscale, Warm, Elegant, American, French, Cozy',
        annual_dol: 2225.73,
        description:
            'Atmospheric watering hole serving up cocktails, beer, wine & more in simple, retro surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6905,
        store_longitude: -73.9833,
        total_orders: 212.69,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Burger, Beer, Modern, Casual, Fresh, Classic, Fine',
        annual_dol: 801.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9855,
        total_orders: 677.57,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Stylish, Hotel, Inviting, Innovative, Bright, Lively, Premium, Great, Perfect, Modern',
        annual_dol: 691.95,
        description:
            "Chic, compact rooms come with smart TVs, Nespresso machines and minibars, as well as premium linens and free Wi-Fi. Upgraded rooms add city views; some have pull-out sofas. Room service is available.Daily sangria and access to a nearby fitness center are complimentary. There's also a hip tapas/cocktail bar. Off-site parking is available for a surcharge.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9897,
        total_orders: 13128.61,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Tequila, Whiskey, Wine, Scotch, Beer, Margarita, Gin, Ale, Irish, Chinese',
        annual_dol: 14084.36,
        description:
            'Elaborately designed, Victorian-style bistro with a long marble bar for cocktails & American bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9918,
        total_orders: 1423.92,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Lobster, Beer, Upscale, Irish, Elegant, American, Friendly, Perfect, Classic, Casual',
        annual_dol: 1467.54,
        description:
            'This large, elegant Irish bar one block from Penn Station features a big menu of elevated pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7725,
        store_longitude: -73.9812,
        total_orders: 2147.91,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Salad, Lobster, Sushi, Steak, Shrimp Cocktail, Vegan, Italian, Japanese, Mediterranean, Caesar Salad',
        annual_dol: 2713.53,
        description:
            'A lively crowd gathers at this fish house for the extensive seafood menu & stylish setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7291,
        store_longitude: -74.0008,
        total_orders: 215.26,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Chinese, Wine, Spicy, Asian, Exotic, Traditional, Fun, Authentic, Casual',
        annual_dol: 879.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9943,
        total_orders: 429.79,
        neighborhood: 'Nolita',
        keywords: 'Beer, Wine, Thai, Margarita, Spicy, Ale, Eclectic, American, Modern, Fun',
        annual_dol: 1508.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7622,
        store_longitude: -73.8042,
        total_orders: 91.53,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Vodka, Wine, Steak, Kebab, Beer, Thai, Gin, Asian, Authentic, Laid-Back',
        annual_dol: 374.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7063,
        store_longitude: -73.9227,
        total_orders: 565.91,
        neighborhood: 'Bushwick/Flushing',
        keywords: 'Coffee, Beer, Spicy, Vietnamese, Upscale, Thai, Asian, Trendy, Japanese, Ramen',
        annual_dol: 749.2,
        description:
            'Japanese, ramen & Thai menus from three different chefs are served in a hip, bi-level space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7244,
        store_longitude: -73.5446,
        total_orders: 23.86,
        neighborhood: '',
        keywords: 'Burger, French Toast, Beer, French, Omelette, Casual, Classic, Excellent',
        annual_dol: 97.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6509,
        store_longitude: -73.611,
        total_orders: 9.7,
        neighborhood: '',
        keywords:
            'Beer, Burger, Irish, Wine, Lively, Fabulous, American, Refreshing, Great, Casual',
        annual_dol: 39.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7106,
        store_longitude: -73.9228,
        total_orders: 0,
        neighborhood: 'East Williamsburg',
        keywords:
            'Tequila, Wine, Margarita, Martini, Vietnamese, Spicy, Paloma, Intimate, Casual, Local',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.7968,
        total_orders: 271.94,
        neighborhood: 'Broadway–Flushing/Flushing/Murray Hill',
        keywords: 'Wine, Clean, Casual, Irish, American',
        annual_dol: 309.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -73.9993,
        total_orders: 2257.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Tasty, Delicious, Savory, Beer, Cozy, Quaint, Rustic, Italian, Fun',
        annual_dol: 2305.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -74.0161,
        total_orders: 32.97,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Champagne, Wine, Beer, Magical, Gumbo, Korean, Spectacular, Vibrant',
        annual_dol: 94.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.8786,
        total_orders: 49.78,
        neighborhood: 'Jackson Heights/East Elmhurst',
        keywords:
            'Margarita, Beer, Spanish, Seafood Paella, Latin, American, Daiquiri, Fusion, Casual',
        annual_dol: 58.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9825,
        total_orders: 2600.54,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Upscale, Stylish, Hotel, Delicious, Vintage, Peruvian, Healthy, Irish, American, Premium',
        annual_dol: 2711.61,
        description:
            'Contemporary rooms offer free Wi-Fi, desks with ergonomic chairs, flat-screen TVs with premium cable channels and iPod docks, plus minifridges and Keurig coffeemakers.Social spaces include a living room-style lobby and a sundeck. The American bistro serves breakfast and dinner as well as all-day drinks, including Starbucks coffee. There’s also a business center with workstations and printers, and 7 meeting rooms. Other amenities include an exercise room and a convenience store.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9851,
        total_orders: 1884.65,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Pizza, Italian, Gluten Free, Wine, Dessert, Hotel, French, Delicious, Cozy',
        annual_dol: 1924.63,
        description:
            'Thin-crust pizza & homemade pasta head the menu at this colorful eatery with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7125,
        store_longitude: -73.9942,
        total_orders: 2272.49,
        neighborhood: 'Two Bridges',
        keywords: 'Wine, Karaoke, Beer, Margarita, Spicy, Hotel, Fun, Fancy, Vintage, Friendly',
        annual_dol: 2371.02,
        description:
            'Unassuming watering hole featuring Chinese-inspired cocktails & bar bites, plus a jukebox.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.9548,
        total_orders: 1834.29,
        neighborhood: 'Roosevelt Island',
        keywords:
            'Hotel, Elegant, Beer, Spectacular, Fancy, Bright, Polished, Modern, Colorful, Great',
        annual_dol: 5619.64,
        description:
            "and 4 miles from the Museum of Modern Art.Featuring city views, the polished rooms have designer toiletries, Wi-Fi, HDTVs and minifridges, plus modern artwork and elegant black-and-white images. Some overlook the river. Suites offer living areas, and a colorful upgraded suite provides a soaking tub and a sofabed.An airy restaurant features floor-to-ceiling windows, and walls lined with bookshelves. There's also a 24-hour gym. Breakfast is available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.9918,
        total_orders: 636.5,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Mexican, Vegan, Tacos, Steak, Nachos, Margarita, Enchiladas, Dessert, Fajitas, Authentic',
        annual_dol: 693.68,
        description:
            'Laid-back restaurant dishing up Mexican cuisine & cocktails amid a vibrant interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8135,
        store_longitude: -73.9447,
        total_orders: 260.43,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'American, Premium, Casual, Great',
        annual_dol: 327.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.688,
        store_longitude: -73.9791,
        total_orders: 2005.4,
        neighborhood: 'Fort Greene',
        keywords: 'Beer, Wine, Upscale, American, Eclectic, Classic, Ample, Chic, Casual, Fine',
        annual_dol: 2055.61,
        description:
            'Gotham West spin-off featuring an array of Brooklyn-based food & drink vendors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7386,
        store_longitude: -73.9943,
        total_orders: 71.11,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Vegan, Vegetarian, Salad, Beer, Dessert, Healthy, Mediterranean, Casual, Delicious, Syrian',
        annual_dol: 273.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7388,
        store_longitude: -73.9956,
        total_orders: 507,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Salad, Shrimp Cocktail, Pizza, Wine, French Toast, Beer, French, Rich, American, Greek',
        annual_dol: 594.07,
        description:
            'Corner diner offering 24/7 fare in basic digs with a Hollywood Hills mural & swivel counter chairs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.743,
        store_longitude: -74,
        total_orders: 34.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Kebab, Vegetarian, Wine, Beer, Mediterranean, Ale, Lebanese, Shawarma, Tasty, Stylish',
        annual_dol: 47.5,
        description:
            'Low-key brick-walled cafe with an open kitchen offering modern Middle Eastern classics & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.958,
        total_orders: 252.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Mexican, Coffee, Organic, Trendy, Breakfast Burrito, Spicy, Delicious, American, Southern',
        annual_dol: 310.15,
        description:
            'Cafe focusing on everything egg, including creative sandwiches & fancy Benedicts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6764,
        store_longitude: -73.9623,
        total_orders: 5468.19,
        neighborhood: 'Crown Heights',
        keywords: 'Steak, Mexican, Tacos, Burritos, Coffee, Homemade, Casual, Yummy, Fresh, Great',
        annual_dol: 5707.59,
        description:
            'Down-to-earth fixture serving up a variety of tacos, enchiladas, burritos & other Mexican staples.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7064,
        store_longitude: -74.0026,
        total_orders: 7169.5,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Lobster, Burger, Sushi, Dessert, Italian, Wine, Beer, French, Swiss, Modern',
        annual_dol: 8629.91,
        description:
            'Waterfront restaurant preparing a modern menu of wild-caught seafood paired with seasonal cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -74.0075,
        total_orders: 237.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lasagna, Salad, Wine, Gluten Free, Italian, Tasty, Vodka, Gin, Delicious, Artisanal',
        annual_dol: 248.21,
        description:
            'This white-tiled Italian bar offers small plates & higher-end entrees to go with wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7219,
        store_longitude: -73.9802,
        total_orders: 203.64,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Vodka, Tasty, Trendy, Funky, Fabulous, Awesome, Classy, Ample, Friendly, Chic',
        annual_dol: 832.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.9794,
        total_orders: 37.9,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Salad, Mexican, Mimosa, Beer, Coffee, Dessert, Wine, Greek, Organic, Screwdriver',
        annual_dol: 154.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9774,
        total_orders: 467.41,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Ramen, Beer, Wine, Spicy, Spanish, Japanese, Dan Dan, Unforgettable, Local, Casual',
        annual_dol: 508.66,
        description:
            'Ramen spot by Masaharu Morimoto with communal tables for Japanese noodles, appetizers & sakes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7421,
        store_longitude: -73.9834,
        total_orders: 255.13,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Pizza, Italian, Vegan, Beer, Delicious, Latin, Traditional, Local, Fine',
        annual_dol: 402.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9215,
        total_orders: 2528.33,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords:
            'Burger, Hamburger, Milkshake, Beer, Vodka, Spicy, Delicious, Organic, Ale, French',
        annual_dol: 2879.59,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7528,
        store_longitude: -73.9829,
        total_orders: 14853.16,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Salad, Vegan, Chicken Caesar Salad, Upscale, French Toast, Elegant, Delicious, French, Caesar Salad',
        annual_dol: 15530.9,
        description:
            'American eatery situated on a prime patch of park real estate behind the New York Public Library.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8293,
        store_longitude: -73.7018,
        total_orders: 97.98,
        neighborhood: '',
        keywords:
            'Delicious, Upscale, Stylish, Italian, Fun, Bright, Perfect, American, Exceptional, Excellent',
        annual_dol: 420.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9935,
        total_orders: 206.61,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Whiskey, Comfortable, Iconic, Friendly, Great, Local, Dutch, Casual',
        annual_dol: 274.26,
        description:
            'Humble hangout turning out cocktails & draft beer in narrow, brick-lined digs with a jukebox.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8074,
        store_longitude: -73.9273,
        total_orders: 214.35,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Mexican, Salad, Pizza, Mimosa, Coffee, Dominican, Puerto Rican, Caribbean, Rich, Delicious',
        annual_dol: 876.2,
        description:
            'Outpost of a Puerto Rico–based mini-chain serving chocolate-infused cocktails & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7174,
        store_longitude: -73.9986,
        total_orders: 33.94,
        neighborhood: 'Little Italy',
        keywords: 'Burger, Hamburger, Pizza, Salad, Italian, Chinese, Asian, Cozy, Yummy, Casual',
        annual_dol: 138.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7584,
        store_longitude: -73.5877,
        total_orders: 42.12,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Vegetarian, Dessert, Wine, Shrimp Cocktail, Beer, American, Casual, Fresh',
        annual_dol: 172.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7206,
        store_longitude: -73.8464,
        total_orders: 5149.52,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Steak, Mexican, Beer, Margarita, Peruvian, Cuban, Bbq, Wine, Avocado Toast',
        annual_dol: 5767.36,
        description:
            'Latin American dishes & cocktails served in a restaurant & bar that has an easygoing ambiance.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6634,
        store_longitude: -73.9842,
        total_orders: 43.92,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Coffee, French, Avocado Toast, Delicious, Romantic, Fresh, Casual, Local',
        annual_dol: 47.86,
        description:
            'Small, casual spot for light French breakfast & lunch fare, plus coffee & snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8767,
        store_longitude: -73.8795,
        total_orders: 116.22,
        neighborhood: 'Norwood/West Bronx',
        keywords: 'Mexican, Dominican, American, Latin, Casual, Friendly, Fun, Traditional, Great',
        annual_dol: 139.05,
        description:
            'Informal restaurant offering a menu of traditional Dominican dishes as well as a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7919,
        store_longitude: -73.9721,
        total_orders: 43.39,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Pizza, Gourmet, Bbq, Wine, French, Ale, American, Delicious',
        annual_dol: 66.47,
        description:
            'A wide-ranging menu, live music & sidewalk cafe seats lure diners to this glatt kosher steakhouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7511,
        store_longitude: -73.9748,
        total_orders: 3883.71,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Salad, Wine, Upscale, American, Refined, Classic, Fresh',
        annual_dol: 4045.79,
        description:
            'Outpost of the upscale steakhouse chain offers classic American fare & a clubby, refined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5837,
        store_longitude: -73.9502,
        total_orders: 37.72,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Salad, Pizza, Beer, Savory, Turkish, French, Russian, Spicy, Delicious, Hotel',
        annual_dol: 186.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8169,
        store_longitude: -73.9429,
        total_orders: 61.54,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Mexican, Tacos, Vintage, Burger, Wine, Legendary, American, Classic, Premium',
        annual_dol: 164.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.713,
        store_longitude: -74.0097,
        total_orders: 1268.99,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Champagne, Intimate, Friendly, Casual, Modern, American, Exceptional, Dynamic, Unique',
        annual_dol: 2871.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9622,
        total_orders: 16.45,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Pizza, American, Spacious, Aesthetic, Serene, Friendly, Classic, Iconic, Exceptional, Contemporary',
        annual_dol: 44.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7604,
        store_longitude: -73.9703,
        total_orders: 278.59,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Upscale, Elegant, Impeccable, Modernist, Mediterranean, Romantic, Homemade, Classic, Sophisticated',
        annual_dol: 297.11,
        description:
            'This sophisticated spot draws a crowd for its Northern Italian menu served on white linens.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7145,
        store_longitude: -74.0062,
        total_orders: 115.57,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Mexican, Salad, Organic, Casual, Local, Premium, Classic',
        annual_dol: 532.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7577,
        store_longitude: -73.9678,
        total_orders: 267.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Stylish, Elegant, Exquisite, Premium, Perfect, Sweet, Chic, Unique',
        annual_dol: 505.78,
        description:
            '"Centrally located in the heart of Manhattan, the newly designed SPACE54 offers the ideal setting for your next big celebration or corporate event. A chic and stylish event venue, SPACE54 features a state-of-the-art sound system, intelligent lighting, and everything that’s required to make your party a premier multimedia experience! With 4500 sq. ft. of newly designed event space, including an exquisite 19’ marble bar in the cocktail area and another in the main room, Space54 is a perfect place to host your next special event!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6905,
        store_longitude: -73.9833,
        total_orders: 1723.39,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Sushi, Steak, Salad, Pizza, Tasty, Spicy, Hawaiian, Korean, Delicious, Polish',
        annual_dol: 1917.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6889,
        store_longitude: -73.9888,
        total_orders: 555.24,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Wine, Hotel, Trendy, Coffee, Gourmet, Vibrant, Eclectic, Premium, Cozy, Fun',
        annual_dol: 640.55,
        description:
            "The airy, loft-style rooms have free Wi-Fi, desks, minibars, TVs and cork floors; suites add sitting areas, coffee tables and music systems. Some suites come with bunk beds, murals painted by local artists and lounging hammocks.A surcharge covers continental breakfast, loaner bikes, and use of the 24-hour gym and business center. There's also a trendy bar and lounge with cocktails and Spanish-style fare.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7793,
        store_longitude: -73.9505,
        total_orders: 456.32,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Hamburger, Salad, Dessert, Beer, Margarita, Martini, Irish, Caesar Wrap, Thai',
        annual_dol: 486.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6378,
        store_longitude: -73.6351,
        total_orders: 238.99,
        neighborhood: '',
        keywords: 'Delicious, Tasty, Impeccable, Awesome, Outstanding, Superb, Hotel',
        annual_dol: 278.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7649,
        store_longitude: -73.9959,
        total_orders: 1737.04,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Rooftop, American, Modern, Amazing, Authentic, Friendly, Cool, Great, Local',
        annual_dol: 2581.34,
        description:
            'Nightclub & concert venue in a former horse stable with industrial decor, VIP areas & a balcony.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6934,
        store_longitude: -73.9673,
        total_orders: 579.56,
        neighborhood: 'Clinton Hill',
        keywords: 'Tacos, Pizza, Korean, Chinese, American, Casual, Fun, Great',
        annual_dol: 753.23,
        description: '"Korean Restaurant LGBTQIA+ Good food, good friends, and good times."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.9817,
        total_orders: 997.66,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Salad, Woody, Spectacular, Memorable, Iconic, Great, Excellent, Fresh, Heritage, Negroni',
        annual_dol: 1042.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7792,
        store_longitude: -73.9449,
        total_orders: 518.21,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Steak, Pizza, Meatball, Beer, Wine, Dessert, Spicy, American, French',
        annual_dol: 724.62,
        description:
            'Seasonal New American cuisine, cocktails & weekend brunch served in a relaxed restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7706,
        store_longitude: -73.8904,
        total_orders: 154.44,
        neighborhood: 'Astoria Heights/East Elmhurst/Flushing',
        keywords:
            'Garlic Bread, Pizza, Vodka, Wine, Italian, Chicken Parmigiana, Trendy, Delicious, Romantic, Healthy',
        annual_dol: 204.46,
        description:
            'Laid-back, modern Italian kitchen making sandwiches & pasta & selling pizzas whole or by the slice.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7546,
        store_longitude: -73.8926,
        total_orders: 350.56,
        neighborhood: 'Jackson Heights',
        keywords:
            'Salad, Italian, Dessert, Gourmet, Wine, Polished, Spacious, Clean, Serene, American',
        annual_dol: 358,
        description:
            'A husband-&-wife team runs this eatery that offers Italian classics in serene dark-wood environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7284,
        store_longitude: -73.9993,
        total_orders: 630.77,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Hotel, Beer, Cozy, Delicious, Comfortable, Intimate, Classic, Fantastic, Great',
        annual_dol: 718.41,
        description:
            'Since 1961, many famous artist have played at this iconic music club with nightly shows & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7279,
        store_longitude: -73.9993,
        total_orders: 316.17,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vegetarian, Moroccan, Salad, French, Exotic, Casual, Fun, Local, Hip, Vibrant',
        annual_dol: 1292.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7778,
        store_longitude: -73.9783,
        total_orders: 14223.75,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Sushi, Salad, Dessert, Japanese, Spicy, Delicious, Asian, Creamy, Casual, Teriyaki',
        annual_dol: 17190.08,
        description:
            'Generous portions of sushi & other Japanese fare in a modest setting with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7793,
        store_longitude: -73.9877,
        total_orders: 587.28,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Hotdog, Vegetarian, Salad, Italian, Spicy, Juicy, American, Homemade, Casual',
        annual_dol: 777.48,
        description:
            'Casual American dining with scenic views, open May to mid-October in Riverside Park South.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6498,
        store_longitude: -73.7828,
        total_orders: 444.99,
        neighborhood: 'Jamaica',
        keywords: 'Vegan, Burger, Coffee, British, Comfortable, Polish, Cool',
        annual_dol: 500.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.9355,
        total_orders: 1513.62,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Burger, Hotel, Garlic Bread, Coffee, Beer, Urban, Meatball, Ale, Premium, Modern',
        annual_dol: 1552.61,
        description:
            "Park.Featuring free Wi-Fi, the chic, understated rooms have flat-screen TVs and designer bedding. Upgraded rooms add balconies or patios with skyline views. A top-floor suite has a sitting area and floor-to-ceiling windows.Amenities include a modern cafe with a terrace and garden views, plus a low-key lobby lounge, and a rooftop bar offering panoramic city views. There's also a business center. Parking is available, and pets are welcome for a fee.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6853,
        store_longitude: -74.0004,
        total_orders: 1655.69,
        neighborhood: 'Cobble Hill',
        keywords:
            'Burger, Beer, Tasty, Upscale, Japanese, Cozy, Spanish, Mediterranean, Asian, American',
        annual_dol: 1741.29,
        description:
            'Eclectic Pan-Asian & American comfort dishes & cocktails in a stylish, intimate space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7482,
        store_longitude: -73.9414,
        total_orders: 488.87,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Chicken Caesar Salad, Salad, Coffee, Hotel, Cozy, Modern, Comfortable, Caesar Salad, Awesome',
        annual_dol: 510.07,
        description:
            'Straightforward rooms have free Wi-Fi and flat-screen TVs, as well as pull-out sofas, minifridges and coffeemaking facilities. Upgraded rooms offer city views, and suites add separate living areas. Room service is available 24/7.A laid-back dining room that has modern wall art. There’s also a cozy bar, plus a food mart and a fitness center (both open 24/7). Parking is available for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7541,
        store_longitude: -73.9494,
        total_orders: 1073.86,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Lobster, Sushi, Salad, Vegan, Hawaiian, Fruity, Hotel, Vodka, Upscale, Japanese',
        annual_dol: 2194.79,
        description:
            'An upscale rooftop hotel lounge with cocktails, Asian-fusion fare & views of the Manhattan skyline.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.9421,
        total_orders: 131.04,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Italian, Dessert, Meatball, Gourmet, Wine, Bruschetta, Creamy, Homemade, Spacious',
        annual_dol: 142.81,
        description:
            'Cheerful, laid-back Italian restaurant with outdoor tables serving gourmet pizzas & homemade pastas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7279,
        store_longitude: -73.9857,
        total_orders: 7244.01,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Pizza, Beer, Wine, Upscale, Elegant, Rum, Awesome, Iconic, Great',
        annual_dol: 7397.72,
        description:
            'Once a classic dive, this completely redone space now has a swanky look, food & big-time drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7305,
        store_longitude: -73.9949,
        total_orders: 10902.84,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Whiskey, Wine, Salad, Bourbon, Beer, Chicken Pot Pie, Margarita, Gourmet, Gluten Free, Rustic',
        annual_dol: 11529.4,
        description:
            'Gourmet burgers & other modern American fare plus craft beer & bourbon in a casual tavern setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7351,
        store_longitude: -73.9932,
        total_orders: 229.23,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Tasty, Salad, Delicious, Asian, Spectacular, Great, Fusion, Casual, Rooftop',
        annual_dol: 937,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8027,
        store_longitude: -73.968,
        total_orders: 386.58,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Burger, Vegan, Pizza, Italian, Wine, Vodka, Avocado Toast, French Toast, Delicious, Cozy',
        annual_dol: 403.34,
        description:
            'Unfussy Italian eatery offering pasta, pizza & meat dishes, plus weekend brunch, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9149,
        total_orders: 4.92,
        neighborhood: '',
        keywords:
            'Coffee, Tasty, Beer, Margarita, Karaoke, Spacious, Clean, Friendly, Awesome, Great',
        annual_dol: 20.1,
        description:
            '"This sexy bar/lounge is full of surprises! During the day we play all sports. We serve all types of beers. During the night it becomes more low key and private. Private events are held often! We love catering private parties."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8471,
        store_longitude: -73.938,
        total_orders: 77.31,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Tacos, Steak, Cuban, Mexican, Beer, Pizza, Dominican, Caribbean, Wine, Coffee',
        annual_dol: 316.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.8319,
        total_orders: 60901.96,
        neighborhood: 'Flushing',
        keywords: 'Hotel, Stylish, Modern, Great',
        annual_dol: 194519.58,
        description:
            "matches at the Arthur Ashe Stadium. It's 4 miles from LaGuardia Airport.Refined rooms feature Wi-Fi, flat-screen TVs, and tea and coffeemaking facilities, as well as sitting areas.An airport shuttle is complimentary. Other amenities consist of a restaurant/bar and a fitness room, plus electric charging stations. Breakfast and valet parking are available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7868,
        store_longitude: -73.7923,
        total_orders: 767.98,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Italian, Upscale, Pizza, Delicious, Glamorous, Mediterranean, Elegant, Seasonal, Classic, Traditional',
        annual_dol: 818.45,
        description:
            'Elegant eatery offering seasonal Italian cuisine in a converted mansion with patio dining & gardens.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.885,
        store_longitude: -73.9015,
        total_orders: 21.24,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Shrimp Cocktail, Italian, Roast Beef, Japanese, Asian, Pinacolada',
        annual_dol: 45.14,
        description:
            'Old-school diner with vintage booths serving a varied menu ranging from burgers to breakfasts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8135,
        store_longitude: -73.9447,
        total_orders: 1003.57,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'French Toast, Italian, French, Avocado Toast, Californian, Delicious, African, American, Casual, Polished',
        annual_dol: 1024.86,
        description:
            'Handsome, brick-&-wood clad outpost offering a range of New American, French & African flavors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9712,
        total_orders: 147.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Hamburger, Mexican, Breakfast Burrito, Tasty, Mediterranean, Moroccan, Caesar Salad, Luxurious, Contemporary',
        annual_dol: 555.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7438,
        store_longitude: -73.9877,
        total_orders: 4416.09,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Tacos, Tequila, Cornbread, Mexican, Cajun, Vegetarian, Salad, Margarita, Nachos, Bourbon',
        annual_dol: 4509.79,
        description:
            'Mexican BBQ menus & cocktails combine at this lively, casual spin-off of a popular food truck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6893,
        store_longitude: -73.9916,
        total_orders: 1417.4,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords: 'Wine, Beer, Whiskey, Scotch, Ale, Bourbon, Hotel, Smooth, Friendly, Juicy',
        annual_dol: 1480.24,
        description:
            'Craft beer, darts & a laid-back atmosphere bring regulars to this neighborhood pub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -73.9852,
        total_orders: 2593.34,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Japanese, Hotel, Modern, Perfect, Great, Terrific, American, Intimate',
        annual_dol: 2648.37,
        description:
            'The Gramercy Theatre is a music venue in New York City. It is located in the Gramercy neighborhood of Manhattan, on 127 East 23rd Street. Built in 1937 as the Gramercy Park Theatre, it is owned and operated by Live Nation as one of their two concert halls in New York City, the other being the nearby Irving Plaza.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7549,
        store_longitude: -73.9838,
        total_orders: 328.12,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Salad, Wine, Delicious, Recommended, Classic',
        annual_dol: 1341.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -73.9809,
        total_orders: 1936.53,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Salad, Chicken Caesar Salad, Fun, Caesar Salad, Amazing, Great, Excellent, Classic, Local',
        annual_dol: 2020.49,
        description:
            'Pro stand-up comedians perform on a red-brick-backed stage at this longtime club with a snug bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6886,
        store_longitude: -73.9508,
        total_orders: 2129.22,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Burger, Steak, Vegetarian, Tequila, Breakfast Burrito, Coffee, Beer, Nachos, Bbq, Spicy',
        annual_dol: 2227.47,
        description:
            'Mellow neighborhood joint featuring sandwiches, bar bites & cocktails, plus sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7539,
        store_longitude: -73.9999,
        total_orders: 11732.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Whiskey, Beer, Tasty, Spanish, Spacious, Chinese, Vibrant, American, Asian',
        annual_dol: 13860.92,
        description:
            'Asian-inspired eatery on the 5th floor of Hudson Yards with an oval-shaped bar & large wine wall.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -74.0005,
        total_orders: 972.95,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Ale, Beer, Tasty, Organic, Delicious, Friendly, Cool, Great, Casual, Excellent',
        annual_dol: 993.59,
        description:
            'Super-casual underground bar offering beer & diversions such as satellite sports, pool & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -73.9994,
        total_orders: 285.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Beer, Gin, Ale, Irish, Delicious, Cozy, Japanese, Fun, Casual',
        annual_dol: 305.72,
        description:
            'Classic Irish pub with exposed wood & a variety of draft beers, plus a pool table & dartboards.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9978,
        total_orders: 163.98,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Lobster, Champagne, Wine, Salad, Dessert, Vodka, Coffee, Beer, Lobster Bisque, Trendy',
        annual_dol: 171.09,
        description:
            'Casual Swiss spot with European rail-station-restaurant vibe & a hidden late-night space in back.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6415,
        store_longitude: -73.7786,
        total_orders: 371.42,
        neighborhood: 'Jamaica',
        keywords: 'Vegan, Hotel, Caribbean, Rooftop, Casual, Great, Attentive',
        annual_dol: 1518.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6452,
        store_longitude: -73.9703,
        total_orders: 685.72,
        neighborhood: 'Kensington',
        keywords:
            'Steak, Tacos, Austrian, Wine, Salad, Delicious, Beer, German, Romantic, South American',
        annual_dol: 745.32,
        description:
            'Austrian-influenced dishes & cocktails, plus beer & wine on tap offered in vintage-cool surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7914,
        store_longitude: -73.9688,
        total_orders: 217.76,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tequila, Mexican, Beer, Champagne, Nachos, Gluten Free, Eclectic, Authentic, Signature, Casual',
        annual_dol: 309.17,
        description: '"Serving Southwestern Mexican cuisine and bottomless brunch every weekend!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7311,
        store_longitude: -73.9856,
        total_orders: 2233.58,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Ale, Beer, Wine, Vintage, Seasonal, Friendly, Irish, Casual, Bright, Great',
        annual_dol: 2280.97,
        description:
            'Easygoing watering hole with a seasonal beer list where the vintage jukebox sets the mood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9887,
        total_orders: 42.1,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Hamburger, Tacos, Salad, Fish Tacos, Roast Beef, Tuna Melt Sandwich, Reuben Sandwich, American, Upscale',
        annual_dol: 66.61,
        description:
            'Breakfast, burgers & everything in between are listed on the long menu at this retro diner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7517,
        store_longitude: -73.9723,
        total_orders: 1920.34,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Salad, Shrimp Cocktail, Tasty, Wine, Upscale, Canadian, Delicious, Stylish',
        annual_dol: 2003.15,
        description:
            'Upscale, family-owned eatery featuring dry-aged steaks, chops & seafood dishes in a warm setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -73.9727,
        total_orders: 3219.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Salad, Delicious, Beer, Gin, Perfect, Irish, American, Authentic, Moscow Mule',
        annual_dol: 3320.45,
        description:
            'Multilevel Irish-style pub with numerous beers on tap, daily specials & an outdoor rooftop bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 4908.88,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Vegetarian, Wine, Upscale, Elegant, American, Modern, Classic, Casual',
        annual_dol: 5168.29,
        description:
            'Vast, elegant upscale restaurant featuring innovative American seafood & an extensive wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8184,
        store_longitude: -73.6434,
        total_orders: 1251.97,
        neighborhood: '',
        keywords:
            'Burger, Golfclub, French, French Onion Soup, Luxurious, Fresh, Great, Local, Fine',
        annual_dol: 1460.47,
        description:
            'Engineers Country Club is a historic country club located in Roslyn Harbor, New York, on the historic Gold Coast on the north shore of Long Island.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -74.0076,
        total_orders: 364.69,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Sushi, Luxurious, Japanese, Upscale, Zen, Cozy, Phenomenal, Serene, Italian, Spectacular',
        annual_dol: 1257.22,
        description:
            '"Bar icca offers seasonal Japanese inspired cocktails and small bites. icca Omakase and Bar icca is now open and accepting reservations through TOCK."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6781,
        store_longitude: -73.9388,
        total_orders: 7.35,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vegan, Beer, Wine, Quaint, Casual, Friendly, American',
        annual_dol: 30.06,
        description:
            '"Acoustik Garden is a quaint indoor & outdoor lounge, bar, grill space in Brooklyn. A styled space where you can host an array of events! We are located conveniently on Atlantic Avenue in Bedford-Stuyvesant! Suya & Jollofrice contains ( Peanut & Tomatoes ). You must be 21+ w/valid ID to gain entrance. LGBTQ FRIENDLY. ALL EXITS ARE FINAL! ***Allergy Alert*** Please be advised that food prepared here may contain these ingredients or traces of these ingredients: Milk, Eggs, Wheat, Gluten, Peanuts, Honey, Tomatoes, Soybeans, Tree Nuts, Fish and Shellfish."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7408,
        store_longitude: -73.9913,
        total_orders: 1703.66,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, Delicious, Margarita, Warm, Homemade, Sweet, Succulent',
        annual_dol: 1756.65,
        description:
            'Traditional Italian food & drink served in a warm setting with an old-world vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9901,
        total_orders: 2954.39,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Stylish, Luxurious, Trendy, Rich, Classy, Garlic Bread, Modern, French, Friendly, Wonderful',
        annual_dol: 3090.84,
        description:
            'Gay dance club with with DJs on 2 floors, plus lounge rooms to mingle in & an outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9836,
        total_orders: 12506.15,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Tequila, Shrimp Scampi, Wine, Vodka, Coffee',
        annual_dol: 12707.32,
        description:
            "Sceney spot that's both a chic lounge with DJs & a modern steakhouse serving prime cuts & seafood.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9898,
        total_orders: 13049.1,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Whiskey, Wine, Bourbon, Beer, Elegant, Spicy, Italian, Vintage, Delicious, Premium',
        annual_dol: 14631.17,
        description:
            'Classy nightspot with a vintage vibe housing a collection of 750+ brown spirits & live jazz shows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.9935,
        total_orders: 20.25,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Margarita, Coffee, Hotel, Fun, Great, Casual',
        annual_dol: 82.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9882,
        total_orders: 375.03,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Wine, Salad, Italian, French, Hotel, Classy, Polished, Comfortable, Belgian',
        annual_dol: 1533,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8046,
        store_longitude: -73.952,
        total_orders: 8034.49,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Wine, Vodka, Dessert, Salad, Cornbread, Peruvian, Romantic, Hotel, Warm, Crisp',
        annual_dol: 8512.62,
        description:
            "Minton's Playhouse is a jazz club and bar located on the first floor of the Cecil Hotel at 210 West 118th Street in Harlem, Manhattan, New York City. It is a registered trademark of Housing and Services, Inc. a New York City nonprofit provider of supportive housing.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -73.8747,
        total_orders: 286.66,
        neighborhood: 'North Corona/Jackson Heights/Flushing',
        keywords:
            'Steak, Ecuadorian, South American, Wine, Beer, Margarita, Latin, French, American, Spanish',
        annual_dol: 507.1,
        description:
            'Traditional Ecuadorian restaurant morphs into a late-night club on weekends with dancing & karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7286,
        store_longitude: -73.9998,
        total_orders: 497.25,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Coffee, Australian, Trendy, Avocado Toast, Delicious, Perfect, Natural, Fresh, Fantastic, Casual',
        annual_dol: 1351.35,
        description:
            '"Modern Australian breakfast and lunch, paired with beautiful coffee and a smile."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.778,
        store_longitude: -73.9217,
        total_orders: 169.03,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Wine, Beer, Awesome, Sweet, Great, Traditional, Local, Casual, Unique',
        annual_dol: 689.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9885,
        total_orders: 67.01,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'American, Italian, Hotel, Southern, Classic, Intimate, Great',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -74.0021,
        total_orders: 4223.88,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Beer, American, Wine, Gin, Hotel, Classic, Innovative, Creative, Casual, Sumptuous',
        annual_dol: 4703.01,
        description:
            'From Danny Meyer, this bar in the lobby of an arts center serves up inventive cocktails & bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7463,
        store_longitude: -73.9864,
        total_orders: 334.45,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Beer, Wine, Delicious, Korean, Karaoke, Hotel, Asian, Retro, Fusion, Creative',
        annual_dol: 483.99,
        description:
            '"Turntable Chicken Rock is a beloved KOREATOWN destination where its signature Best Korean fried chicken, unique retro music playing everyday. Located at 290 5th Ave, New York, NY 10001. The effect is delectable, elevating musical appetites while providing more conventional nourishment in the form of robustly flavored fare."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6829,
        store_longitude: -73.9229,
        total_orders: 86.34,
        neighborhood: 'Bedford-Stuyvesant/Ocean Hill',
        keywords:
            'Burger, Tasty, Delicious, Donut, American, French, Exquisite, Hotel, Fantastic, Modern',
        annual_dol: 352.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6467,
        store_longitude: -73.7855,
        total_orders: 1892.68,
        neighborhood: 'Jamaica',
        keywords: 'Wine, Champagne, Coffee, Spanish, Hotel, Donut, British, Premium, Exotic, Local',
        annual_dol: 2018.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6493,
        store_longitude: -73.7948,
        total_orders: 197.45,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Pizza, Mexican, Beer, Italian, Salad, Martini, Wine, Meatball, Upscale',
        annual_dol: 693.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6664,
        store_longitude: -73.7454,
        total_orders: 19.5,
        neighborhood: 'Laurelton/Jamaica/Springfield Gardens',
        keywords:
            'Burger, Vegetarian, Flavorful, Delicious, Upscale, Comfortable, Elegant, Jamaican, Clean, Caribbean',
        annual_dol: 79.69,
        description:
            'Traditional Caribbean cuisine prepared in a casual, upscale space with DJs & a lounge vibe at night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -73.9895,
        total_orders: 5257.65,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Tasty, Wine, Beer, Martini, French, Fancy, Club Sandwich',
        annual_dol: 5446.76,
        description:
            'Old New York is preserved at this tavern with a marble & mahogany bar, tin ceiling & dumbwaiter.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7326,
        store_longitude: -73.985,
        total_orders: 437.22,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Lobster, Lobster Roll, Hamburger, Beer, Organic, Delicious, American, Dope, Kung Pao, Relaxed',
        annual_dol: 498.55,
        description:
            'Low-key fish house & bar dishing up seafood & more in snug, industrial digs with nautical accents.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7311,
        store_longitude: -73.9837,
        total_orders: 489.06,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tacos, Tequila, Mexican, Vegetarian, Margarita, Nachos, American, Dope, Delicious, Casual',
        annual_dol: 544.94,
        description:
            '"Hermana NYC is a brand new concept restaurant in the East Village. We are a tequila bar serving fresh, authentic Mexican cuisine and beyond with a full tequila menu and live jazz every night."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7262,
        store_longitude: -73.9898,
        total_orders: 127.85,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Italian, Champagne, Dessert, Vietnamese, Vintage, Seasonal, Casual',
        annual_dol: 298.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8022,
        store_longitude: -73.9647,
        total_orders: 2209.31,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Burger, Steak, Beer, Whiskey, Wine, Margarita, Dessert, Bbq, American, Irish',
        annual_dol: 2427.13,
        description:
            '"Amity Hall is a modern American craft beer and sports destination best known for its diverse selection, handcrafted cocktails and lively atmosphere. Named after the once known Amity Street in the heart of Greenwich Village, Amity Hall was established in 2009 with the opening of its flagship location at 80 West 3rd Street. Open seven days a week with two locations conveniently located in NYC, Amity Hall features lunch, dinner and weekend brunch, and offers the perfect venue for hosting special events or watching a game."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7956,
        store_longitude: -73.9708,
        total_orders: 319.5,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Vegetarian, Kebab, Wine, Pakistani, Upscale, Delicious, Indian, Modern, Clean, Casual',
        annual_dol: 333.35,
        description:
            'Classic Indian fare including slow-cooked Awadhi dishes plus wine & tea in a stylish, modern space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5495,
        store_longitude: -74.1506,
        total_orders: 859.96,
        neighborhood: 'Great Kills',
        keywords:
            'Sushi, Salad, Gluten Free, Japanese, Delicious, Sashimi, Bbq, Asian, Sweet, Modern',
        annual_dol: 1036.11,
        description:
            'Entertaining hibachi chefs cook Japanese steaks tableside in a kid-friendly space with a sushi bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5894,
        store_longitude: -74.0894,
        total_orders: 378.86,
        neighborhood: 'Dongan Hills',
        keywords: 'Delicious, Pizza, Fun, Beer, Memorable, Casual, Signature',
        annual_dol: 381.77,
        description:
            'Bowling alley featuring 48 lanes, plus a snack bar with pizza & a laser maze.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7239,
        store_longitude: -73.8691,
        total_orders: 17.29,
        neighborhood: 'Rego Park/Middle Village/Flushing',
        keywords:
            'Salad, French, Colombian, Latin, Sangria, Sweet, Friendly, American, Casual, Mojito',
        annual_dol: 42.49,
        description:
            'This straightforward neighborhood diner offers up ample plates of Colombian comfort chow.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.975,
        total_orders: 7360.55,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Lobster Roll, Lobster, Chocolate Cake, Burger, Shrimp Cocktail, Shrimp Scampi, Martini, Fried Calamari, Beer, Wine',
        annual_dol: 7704.41,
        description:
            'Cavernous, deco-styled restaurant, best known for its lively happy hour, grilled seafood & oysters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.9695,
        total_orders: 531.14,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Salad, Italian, Vintage, Homemade, Traditional, Spacious, Casual, Fresh, Local',
        annual_dol: 554.17,
        description:
            'Traditional Italian dishes & wines in a contemporary, white tablecloth setting with outdoor tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.9479,
        total_orders: 44.08,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Salad, Steak, Wine, Champagne, Gourmet, French, Caesar Salad, Classy, Healthy, Casual',
        annual_dol: 180.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8294,
        store_longitude: -73.9486,
        total_orders: 2090.51,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Bourbon, Whiskey, Comfortable, Rustic, Cozy, Trendy, Beer, Friendly, Casual, Intimate',
        annual_dol: 2294.24,
        description:
            'Cozy neighborhood watering hole offering bourbon, beer & cocktails along with happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9855,
        total_orders: 1931.29,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Vegetarian, Scotch, Wine, Gluten Free, Beer, French Toast, French, Trendy',
        annual_dol: 2015.03,
        description:
            'Craft cocktails & American fare in an elegant, multi-level space with Old New York-inspired decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.849,
        store_longitude: -73.6452,
        total_orders: 934.34,
        neighborhood: '',
        keywords: 'Pizza, Salad, Beer, Margarita, Ale, American, Casual, Friendly, Local, Classic',
        annual_dol: 996.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.589,
        store_longitude: -74.1016,
        total_orders: 580.71,
        neighborhood: 'Todt Hill/Midland Beach',
        keywords:
            'Cheesecake, Salad, Mexican, Italian, German, Nachos, Rich, American, Eclectic, Asian',
        annual_dol: 623.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7883,
        store_longitude: -73.9536,
        total_orders: 1484.75,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Italian, Pizza, Gluten Free, Wine, French, Spicy, Fancy, Authentic, Classic',
        annual_dol: 1867.83,
        description:
            'Family-owned trattoria serving pizzas, pastas & higher-end Italian dishes with a Sicilian accent.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8268,
        store_longitude: -73.9263,
        total_orders: 456.68,
        neighborhood: 'Concourse',
        keywords: 'Pizza, Tasty, Comfortable, Casual, Awesome, Local, Great',
        annual_dol: 520.13,
        description:
            'Bronx sports bar is popular spot for gathering before Yankees home games, the only times it is open.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.6408,
        total_orders: 332.88,
        neighborhood: '',
        keywords:
            'Tequila, Margarita, Salad, Mexican, Dessert, Wine, Bbq, Yummy, Triple Sec, Exotic',
        annual_dol: 494.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7342,
        store_longitude: -74.0064,
        total_orders: 14371.41,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chinese, Peking Duck, Gluten Free, Homemade, Cozy, Sidecar, Asian, Fusion, Fabulous, Local',
        annual_dol: 17903.72,
        description:
            'Tiny restaurant under RedFarm for peking duck prix fixe meals plus a bar with drinks & Asian bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7341,
        store_longitude: -74.0031,
        total_orders: 205.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Steak, Tequila, Mexican, Beer, Salad, Margarita, Wine, Gluten Free, Spicy',
        annual_dol: 263.96,
        description:
            'Contemporary Mexican hot spot turning out innovative tacos & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7406,
        store_longitude: -74.0084,
        total_orders: 242.92,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Vegan, Salad, Coffee, Warm, Donut, Healthy, Japanese, Fresh, Intimate, Casual',
        annual_dol: 992.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6785,
        store_longitude: -73.6143,
        total_orders: 12.61,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Salad, Caribbean, Sweet, Casual, Local, American, Premium, Traditional',
        annual_dol: 51.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7352,
        store_longitude: -74.0092,
        total_orders: 950.77,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Zombie, Mexican, Beer, Hawaiian, Delicious, Upscale, Fun, Perfect, Western',
        annual_dol: 5139.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -74.0037,
        total_orders: 562.14,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Steak, Salad, Bourbon, Margarita, Italian, Organic, Seasonal, French, American',
        annual_dol: 599.51,
        description:
            'Bustling New American eatery with huge windows & an emphasis on farm-fresh fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8654,
        store_longitude: -73.8299,
        total_orders: 528.17,
        neighborhood: 'East Bronx/Baychester',
        keywords: 'Burger, Lobster, Cajun, Steak, Salad, Pizza, Flavorful, Wine, Beer, Friendly',
        annual_dol: 739.93,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings.Begin your voyage with our diverse food destinations, offering a wide variety of different choices. You can simply select your choices of meat, seafood, vegetables and what you\'ll get is a culinary creation that will make you feel like you\'re down in New Orleans. Our food quality, friendly service and cleanliness will exceed your expectations. We look forward to serving you!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7835,
        store_longitude: -73.9796,
        total_orders: 623.49,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Italian, Australian, American, Modern, Local, Friendly, Fresh, Great, Fine',
        annual_dol: 2622.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7673,
        store_longitude: -73.9212,
        total_orders: 2442.81,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Steak, Mexican, Beer, Salad, Wine, Coffee, Breakfast Burrito, Ale, Delicious, French Toast',
        annual_dol: 2494.64,
        description: '"Authentic & Modern Mexican-Fusion Cusines"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9466,
        total_orders: 2819.13,
        neighborhood: 'Greenpoint',
        keywords:
            'Tacos, Mexican, Beer, Wine, Tomato Soup, Tex-Mex, Premium, Paloma, Moscow Mule, Awesome',
        annual_dol: 2922.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7687,
        store_longitude: -73.9556,
        total_orders: 1444.74,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Salad, Sushi, Steak, Wine, Coffee, Beer, Ale, Irish, Delicious, Caesar Salad',
        annual_dol: 1536.96,
        description:
            'Longtime Upper East Side Irish bar & restaurant with a low-key feel & a grown-up following.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7647,
        store_longitude: -73.9197,
        total_orders: 11490.88,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Beer, Tasty, Steak, Salad, Wine, Delicious, Italian, Warm, Sweet',
        annual_dol: 12040.38,
        description:
            'A lively Irish pub with a hip vibe, brick-&-beam interior, a garden and locally sourced food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6452,
        store_longitude: -73.9579,
        total_orders: 1241.15,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Margarita, Delicious, Great, Creative, Traditional, Unique, Chill',
        annual_dol: 1271.55,
        description:
            'Popular neighborhood bar & tattoo shop featuring creative cocktails & brews in a laid-back setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6678,
        store_longitude: -73.9872,
        total_orders: 2268.8,
        neighborhood: 'Park Slope',
        keywords: 'Tequila, Wine, Vodka, Beer, Gin, Ale, French Onion Soup, Cozy, Vintage, French',
        annual_dol: 2419.62,
        description:
            'Old-school haunt doling out beer, cocktails & bar snacks in relaxed environs with vintage decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.673,
        store_longitude: -73.9903,
        total_orders: 334.28,
        neighborhood: 'Gowanus',
        keywords:
            'Wine, Beer, Salad, Bourbon, Turkish, Mediterranean, Spanish, Delicious, Casual, Wonderful',
        annual_dol: 1366.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6834,
        store_longitude: -73.9956,
        total_orders: 617.26,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Italian, Dessert, Wine, Meatball, Beer, Delicious, Bruschetta, Homemade, Romantic',
        annual_dol: 673.75,
        description:
            'Serves Northern Italian food with an emphasis on housemade pastas & seasonal ingredients.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6853,
        store_longitude: -74.0027,
        total_orders: 356.72,
        neighborhood: 'Columbia Street Waterfront District',
        keywords: 'Friendly, Warm, Casual, Hotel, Mediterranean, Classic, Great, Chill',
        annual_dol: 1252.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7341,
        store_longitude: -73.8711,
        total_orders: 1790.19,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Steak, Hamburger, Beer, Wine, Coffee, Italian, American, Delicious, Casual, Classic',
        annual_dol: 1947.03,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -73.9866,
        total_orders: 2179.4,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'American, Hotel, Fancy, Fine, Local, Creative',
        annual_dol: 2225.64,
        description:
            'The National Arts Club is a 501 nonprofit and members club on Gramercy Park, Manhattan, New York City. It was founded in 1898 by Charles DeKay, an art and literary critic of the New York Times to "stimulate, foster, and promote public interest in the arts and to educate the American people in the fine arts".',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7319,
        store_longitude: -73.9943,
        total_orders: 2825.89,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Lobster, Burger, Steak, Shrimp Cocktail, Lobster Bisque, French, French Onion Soup, Japanese, Bbq, Delicious',
        annual_dol: 2885.85,
        description:
            'Warm, clubby spot serves up live jazz & a classic American menu, heavy on fish & steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8547,
        store_longitude: -73.9372,
        total_orders: 506.1,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords: 'Burger, Steak, Tacos, Salad, Beer, Bbq, Peruvian, Wine, Quesadillas, American',
        annual_dol: 551.57,
        description:
            'Laid-back restaurant & bar serving nachos, wings & burgers, with take-out & delivery available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6849,
        store_longitude: -74.0029,
        total_orders: 13.46,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Vegetarian, Coffee, Mediterranean, Shawarma, Casual, Hummus, Fresh, Great, Middle Eastern, Diverse',
        annual_dol: 31.43,
        description:
            'Rustic-chic bar & eatery presenting a diverse menu of Mediterranean tapas & entrees, plus delivery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5514,
        store_longitude: -74.1492,
        total_orders: 30.86,
        neighborhood: 'Great Kills',
        keywords: 'Burger, Pizza, Salad, Baked Potato, Nachos, Irish, Bbq, Funky, Ale, Homemade',
        annual_dol: 52.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6477,
        store_longitude: -73.7783,
        total_orders: 32.6,
        neighborhood: 'Jamaica',
        keywords: 'Italian, Pizza, Donut, Wine, American, Modern, Premium, Western',
        annual_dol: 133.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9791,
        total_orders: 186.5,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Sushi, Sweet, Polish, Innovative, Comfortable, Iconic, Rooftop',
        annual_dol: 762.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.972,
        total_orders: 53.49,
        neighborhood: 'Midtown Manhattan/Tudor City/Midtown East',
        keywords:
            'Mexican, Burger, Beer, Wine, American, Irish, Delicious, Western, Modern, Casual',
        annual_dol: 218.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5838,
        store_longitude: -73.9363,
        total_orders: 128.72,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Sushi, Wine, Salad, Beer, Upscale, Great, Local, Chill',
        annual_dol: 157.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7638,
        store_longitude: -73.8317,
        total_orders: 654.66,
        neighborhood: 'Flushing',
        keywords:
            'Hamburger, Coffee, Hotel, Contemporary, Classic, Timeless, Gyoza, Casual, Dim Sum',
        annual_dol: 2676.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.8296,
        total_orders: 972.72,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Salad, Pizza, Wine, Gourmet, Beer, Vietnamese, Chinese, Luxurious, Clean, Modern',
        annual_dol: 3384.83,
        description:
            'Gourmet treatments of Chinese & other Asian cuisines served in a luxurious space with a waterfall.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.9682,
        total_orders: 6822.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Lobster Roll, Lobster, Cheesesteak, Hamburger, Tomato Soup, Salad, Shrimp Cocktail, Beer, Creamy',
        annual_dol: 6966.82,
        description:
            'Burgers & other American comfort food in down-to-earth digs with a lively atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7126,
        store_longitude: -74.0125,
        total_orders: 8616.86,
        neighborhood: 'World Trade Center/Financial District',
        keywords:
            'Dessert, Salad, Rich, Magical, Sweet, Contemporary, Modern, Western, Unforgettable, Seasonal',
        annual_dol: 9208.29,
        description:
            'Observatory located on floors 100-102 of One World Trade Center, with exhibits & restaurants.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9712,
        total_orders: 5530.02,
        neighborhood: 'Lenox Hill',
        keywords:
            'Wine, Beer, Upscale, Spacious, Chilean, Vibrant, Mediterranean, Greek, Classic, Authentic',
        annual_dol: 6150.95,
        description:
            'Upscale Greek seafood eatery with fresh fish by the pound, villa decor & expansive outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.9686,
        total_orders: 3571.58,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Burger, Shrimp Cocktail, Salad, Cajun, Chilean, Wine, Ribeye Steak, Caesar Salad',
        annual_dol: 3660.41,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8019,
        store_longitude: -73.736,
        total_orders: 39.76,
        neighborhood: '',
        keywords:
            'Italian, Burger, Salad, Shrimp Cocktail, Elegant, Fantastic, Great, Exotic, Fresh, Fine',
        annual_dol: 162.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.761,
        store_longitude: -73.975,
        total_orders: 113.37,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Salad, Greek, Mediterranean, Polished, Awesome, Greek Salad, American, Amazing, Wonderful',
        annual_dol: 463.41,
        description: '"Event Venue & Lounge located on the 5th Avenue in Manhattan"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.995,
        total_orders: 2620.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Mexican, Beer, Warm, Quesadillas, Perfect, Fabulous, Casual, Great',
        annual_dol: 2740.89,
        description:
            'Buzzing gay bar with weekend DJ sets offering pizzas, quesadillas & Sunday drag brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.688,
        store_longitude: -73.9881,
        total_orders: 701.24,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords:
            'Steak, Burger, Salad, Tequila, Champagne, Wine, Canadian, French Toast, Dessert, French',
        annual_dol: 747.85,
        description:
            'Trendy bistro features creative French-American meals in a stylish, sleek venue with patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7578,
        store_longitude: -73.9836,
        total_orders: 15783.75,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Margarita, Trendy, Spicy, Delicious, American, Intimate, Vibrant, Latin',
        annual_dol: 19042.1,
        description:
            'Lively restaurant with colorful decor, offering typical Mexican dishes, cocktails & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9813,
        total_orders: 315.95,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Upscale, Hotel, Wine, American, Great, Comfortable, Excellent, Modern',
        annual_dol: 322.65,
        description:
            "Modern rooms come with complimentary Wi-Fi, flat-screen TVs and iPod docks, as well as coffeemakers, and desks with ergonomic chairs. Some have microwaves and minifridges, and suites add pull-out sofas. Apartments include kitchenettes. Exercise equipment is available.Freebies include loaner iPads, evening wine hours, and all-day snacks and drinks in a lounge. There's also a laid-back American restaurant, an exercise room and a business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7549,
        store_longitude: -73.9859,
        total_orders: 414.54,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Wine, Beer, Japanese, Delicious, Quaint, Cozy, Homemade, Asian',
        annual_dol: 1694.51,
        description:
            'Sleek, refined spot serving Japanese home-cooking faves, such as yakitori, soba & hot pots.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7395,
        store_longitude: -73.9891,
        total_orders: 2055.97,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Salad, French, Mimosa, Korean, Modern, Contemporary, Asian, Savory, American, Fusion',
        annual_dol: 2298.02,
        description:
            'Creative takes on classic French plates are paired with craft cocktails & burlesque performances.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.9877,
        total_orders: 2230.72,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Sushi, Gluten Free, Japanese, Elegant, Innovative, Fun, Fabulous, Sleek, Creative, Polished',
        annual_dol: 2230.72,
        description:
            'Sleek chain with a full bar known for classic & creative rolls & other Japanese fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6915,
        store_longitude: -73.9845,
        total_orders: 152.62,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Hotel, Stylish, Rooftop, Great, Modern, Comfortable',
        annual_dol: 178.03,
        description:
            'Modern rooms have 42-inch flat-screen TVs, coffeemakers and Wi-Fi (fee), plus work areas with desks and ergonomic chairs. Suites adds separate living areas, while club rooms provide access to a lounge offering complimentary continental breakfast, all-day snacks and evening appetizers.Amenities include a Mexican-American restaurant and a rooftop bar, plus an indoor pool and a 24/7 fitness center. There’s also a business center and 4,300 sq ft of meeting space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9831,
        total_orders: 120.49,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Intimate, Perfect, Wonderful, Fantastic, Traditional',
        annual_dol: 155.38,
        description:
            '"Convene at 117 West 46th Street, our largest dedicated event venue in New York City. Large enough to fit groups of up to 500 seated participants but much more intimate than a convention center, this full-service event facility redefines sophistication and elegance and is ideal for those seeking "corporate-meets-social" receptions and plated dinners within the Times Square and Rockefeller Center area. As one of Convene\'s flagship properties, this space offers "white-box" design capability, giving professional meeting planners the ability to highly customize the space with multiple sponsors and corporate branding opportunities - from digital video walls to physical rooms and spaces."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.9893,
        total_orders: 50.27,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Steak, Vegetarian, Tasty, Dessert, Beer, Spanish, Delicious, Sangria, Warm',
        annual_dol: 144.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6614,
        store_longitude: -73.7029,
        total_orders: 112.35,
        neighborhood: '',
        keywords: 'Hotel, Cool, Amazing, Great, Casual',
        annual_dol: 459.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.9933,
        total_orders: 2318.05,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Hamburger, Vegetarian, Salad, Ale, French Onion Soup, French, Irish, American',
        annual_dol: 2432.19,
        description:
            'Large Irish bar decorated with dark wood & racing memorabilia and serving standard pub food & brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7463,
        store_longitude: -73.9888,
        total_orders: 1862.42,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Delicious, Golfclub, Hotel, Fun, Gourmet, Premium, Amazing, Great, Cool, Seasonal',
        annual_dol: 7612.95,
        description:
            '"Tucked beneath the streets of New York, be transported to a bucolic 1920s English country golf club featuring stunning crazy golf courses, craft cocktails, and gourmet street food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.995,
        total_orders: 1109.71,
        neighborhood: 'Nolita',
        keywords:
            'Burger, Vegan, Kebab, Spicy, Quaint, Trendy, Mediterranean, Yummy, Friendly, Eclectic',
        annual_dol: 1111.11,
        description:
            'The eclectic menu has a Mediterranean slant at this small, airy bistro with booths & a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -74.0001,
        total_orders: 1336.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Champagne, Wine, Beer, Delicious, Pizza, Rum, Fun, Awesome, Amazing, Sweet',
        annual_dol: 1394.32,
        description:
            'A twenty-something crowd frequents this dimly lit lounge with crimson bordello decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7743,
        store_longitude: -73.9081,
        total_orders: 11.06,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'Pizza, Salad, Beer, Wine, Gourmet, American, Vintage, Seasonal, Casual, Local',
        annual_dol: 45.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7483,
        store_longitude: -73.876,
        total_orders: 51.72,
        neighborhood: 'North Corona/Jackson Heights/Flushing/Elmhurst',
        keywords:
            'Steak, Peruvian, Shrimp Cocktail, Italian, Ecuadorian, Cuban, Karaoke, Fusion, Sweet, Omelette',
        annual_dol: 211.4,
        description:
            '"RESTAURANT ECUATORIANO ESPECIALIDAD COSTA Y SIERRA SERVICIO DE BAR KARAOKE AMBIENTE FAMILIAR"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6905,
        store_longitude: -73.9845,
        total_orders: 0,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Salad, Vegan, Tasty, Jamaican, Caribbean, Rum, Awesome, Great, Local, Signature',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7509,
        store_longitude: -73.9365,
        total_orders: 791.8,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Hotel, Tasty, Clean, Warm, Modern, Friendly, Great, Rooftop',
        annual_dol: 826.13,
        description:
            "Warm rooms feature free Wi-Fi and 46-inch flat-screen TVs, as well as microwaves, minifridges and coffeemakers. All have desks and rainfall showerheads; upgraded rooms add pull-out sofas.There's a bright, modern bar and grill, while a snack pantry, a gym and a business center are all available 24/7. Other amenities include a rooftop terrace and coin-operated laundry facilities. Valet parking is available for a charge.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7452,
        store_longitude: -73.9535,
        total_orders: 592.61,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Tacos, Steak, Tuna Steak, Gluten Free, Wine, Beer, Dessert, Spicy, Bbq, Cozy',
        annual_dol: 612.45,
        description:
            'This bar decorated with reclaimed wood offers American & Irish food plus a selection of craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6821,
        store_longitude: -73.9958,
        total_orders: 305.66,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Steak, Pizza, Cheesesteak, Italian, Salad, Meatball, Wine, Flavorful, Margarita, Mojito',
        annual_dol: 340.08,
        description:
            'Understated, brick-walled wine bar with an Italian shared-plates menu & brick-oven thin-crust pizza.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9871,
        total_orders: 323.17,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Sushi, Beer, Spicy, Japanese, Warm, Yummy, Crisp, Asian, Classy, Premium',
        annual_dol: 377.58,
        description:
            'Japanese restaurant specializing in handrolls & set menus amid minimalist surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.9887,
        total_orders: 1658.49,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Kebab, Tasty, Salad, Beer, Warm, Mediterranean, Rustic, Israeli, American',
        annual_dol: 1696.86,
        description:
            'Informal Middle Eastern restaurant also serving American eats in cozy, rustic digs with a piano.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -73.9873,
        total_orders: 2316.37,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Sushi, Burger, Flavorful, Beer, Wine, Spicy, Delicious, Jamaican, Caribbean, Thai',
        annual_dol: 2416.8,
        description:
            'Rustic-chic destination for contemporary craft cocktails & a limited menu of creative bar food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7322,
        store_longitude: -73.9882,
        total_orders: 485.14,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Spicy, Beer, Delicious, Wine, Gin, Chinese, Fiery, Spacious, Bbq, Sweet',
        annual_dol: 506.17,
        description:
            'Spicy Sichuan dishes like dan dan noodles are heat-rated by number at this informal restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9861,
        total_orders: 107.07,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Hotdog, Beer, Wine, Scotch, Gin, Bourbon, Spanish, Rum, Sweet, Lively',
        annual_dol: 437.66,
        description:
            'Spanish eatery offering Basque meals in a wood-clad room & finger foods at a bright bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8873,
        store_longitude: -73.907,
        total_orders: 3449.99,
        neighborhood: 'Fieldston/Riverdale/West Bronx',
        keywords: 'Sushi, Dessert, Wine, Salad, Delicious, Spicy, Mexican, Creamy, Spanish, Classy',
        annual_dol: 3523.19,
        description:
            'Convivial restaurant & bar for Pan-Latin small plates, creative cocktails & live-music nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.7702,
        total_orders: 300.86,
        neighborhood: 'Bayside',
        keywords: 'Thai, Sushi, Steak, Warm, Friendly, Exotic, Relaxed, Sweet, Modern, Authentic',
        annual_dol: 358.11,
        description:
            'Thai fare with a modern twist awaits at this colorful spot with turquoise walls & warm wood accents.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 1979.72,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Luxurious, Champagne, Wine, Upscale, Pizza, Tasty, Phenomenal, Trendy, Premium, Exquisite',
        annual_dol: 2021.72,
        description:
            'Plush lounge with creative cocktails, upscale American small plates & occasional celeb sightings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.752,
        store_longitude: -73.9697,
        total_orders: 2625.74,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Salad, Sushi, Japanese, Wine, Champagne, Tasty, Casual, American, Local, Fusion',
        annual_dol: 2681.46,
        description:
            'Intimate Japanese eatery & piano lounge specializing in sushi bowls, plus beer, wine & sake.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8284,
        store_longitude: -73.9486,
        total_orders: 18565.11,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Vegetarian, Cheesecake, Wine, Tasty, Beer, Meatball, Bourbon, Delicious, Cozy, Comfortable',
        annual_dol: 19181.25,
        description:
            'Craft cocktails & bar bites are offered in a low-lit, subterranean setting with a cozy vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8244,
        store_longitude: -73.9487,
        total_orders: 86.73,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Steak, Pizza, Italian, Meatball, Wine, Delicious, Ribeye Steak, Cozy, Warm',
        annual_dol: 223.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7581,
        store_longitude: -73.9881,
        total_orders: 72.9,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Tacos, Italian, Hotel, American, Legendary, Dutch, Contemporary, Perfect',
        annual_dol: 96.51,
        description:
            "The St. James Theatre, originally Erlanger's Theatre, is a Broadway theater at 246 West 44th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1927, it was designed by Warren and Wetmore in a neo-Georgian style and was constructed for A. L. Erlanger.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -74.0011,
        total_orders: 6224.63,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Salad, Wine, Gourmet, Upscale, Stylish, Mediterranean, Greek, Spectacular, Amazing',
        annual_dol: 6807.59,
        description:
            'Stylish Greek restaurant serving fresh seafood in a Mediterranean-style whitewashed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7471,
        store_longitude: -73.9868,
        total_orders: 516.17,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'African, Hotel, Pizza, Global, Caribbean, Legendary, Dessert, American, Stylish, Fusion',
        annual_dol: 2109.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7715,
        store_longitude: -73.9819,
        total_orders: 3034.08,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Wine, French, Rustic, Relaxed, Casual, Friendly, Yummy, Seasonal, Traditional',
        annual_dol: 3241.13,
        description:
            'Relaxed Parisian-style dining via chef Daniel Boulud with standout charcuterie & wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.9985,
        total_orders: 119.07,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Coffee, Luxurious, Hotel, Rich, Vodka, Funky, Chinese, Modernist, Premium, Bellini',
        annual_dol: 162.09,
        description:
            "or 2-block walk.Rooms have a modernist loft-like decor with high-ceilings, large windows, free Wi-Fi and HDTVs; many have hardwood floors, balconies, kitchenettes and/or oversized bathtubs.The bi-level Mercer Kitchen restaurant features chef Jean-Georges Vongerichten's inventive American food; it also provides 24-hour room service. The 24-hour concierges can arrange business services, limousines, health club passes or in-room spa treatments.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7235,
        store_longitude: -73.9885,
        total_orders: 2696.1,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Hamburger, Tequila, Beer, Boilermaker, Ale, Wine, American, Tasty, Moscow Mule',
        annual_dol: 3777.07,
        description:
            'Retro-style bar with leather banquettes, serving kitschy drinks, beer cocktails & burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7313,
        store_longitude: -73.9889,
        total_orders: 1950.59,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Burger, Gourmet, American, Swiss, Upscale, Premium, Classic, Sophisticated, Local, Signature',
        annual_dol: 2333.83,
        description:
            'Sports bar boasting lots of TVs, upscale pub food, cocktails & a woodsy, ski-lodge look.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7305,
        store_longitude: -73.9898,
        total_orders: 1273.61,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Steak, Tequila, Wine, Salad, Spicy, Scotch, Beer, Margarita, Juicy, Irish',
        annual_dol: 4798.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7247,
        store_longitude: -73.9921,
        total_orders: 236.06,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Tequila, Mexican, Margarita, Martini, Upscale, Brazilian, Artisanal, Traditional, Southern, Casual',
        annual_dol: 964.92,
        description:
            '"Chef Francisco Blanco brings the flavors of traditional Oaxacan cuisine with an elevated twist. IXTA is a celebration of ancient Mexican heritage and culture."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7696,
        store_longitude: -73.7737,
        total_orders: 1815.08,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Tequila Sunrise, Tequila, Tacos, Mexican, Steak, Margarita, Wine, Flavorful, Shrimp Cocktail, Salad',
        annual_dol: 2070.41,
        description:
            'Colorful restaurant with traditional Mexican offerings & fresh guacamole, plus live entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7151,
        store_longitude: -74.0103,
        total_orders: 2007.47,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Whiskey, Beer, Upscale, Mediterranean, Casual, Homey, Fun, Classic, Awesome',
        annual_dol: 2083.24,
        description: '"Cocktail Bar and Mediterranean Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.9661,
        total_orders: 2413.69,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Delicious, Mediterranean, Upscale, Greek, Spacious, Perfect, Fresh, Casual, Wonderful',
        annual_dol: 2741.5,
        description:
            'Upscale Greek dining with a seafood focus & full bar in a chic & spacious warehouse-style setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9689,
        total_orders: 585.3,
        neighborhood: 'Lenox Hill',
        keywords:
            'Vegetarian, Italian, Wine, Cozy, Bruschetta, Classy, Casual, Modern, Traditional, Authentic',
        annual_dol: 819.97,
        description: '"info@piccolacucinagroup.com"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7553,
        store_longitude: -73.9649,
        total_orders: 563.73,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, French, French Onion Soup, Wine, Dessert, Swiss, Margarita, Mediterranean, Caesar Salad',
        annual_dol: 789.76,
        description:
            'Classic French cuisine appeals to neighborhood locals & U.N. types alike at this cozy bistro.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9902,
        total_orders: 2979.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Hamburger, Vegan, Salad, Organic, Beer, Rustic, Sustainable, Moroccan, Trendy',
        annual_dol: 3394.66,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7561,
        store_longitude: -73.9807,
        total_orders: 1110.79,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Beer, Wine, Mexican, Margarita, Hotel, Delicious, Cozy, Spicy, Elegant, Comfortable',
        annual_dol: 1199.65,
        description:
            '"Join us at The Dawson Restaurant & Bar, the newest addition to the neighborhood located in the heart of midtown off 5th Ave. close to shopping, Rockefeller Centre, Radio City and Times Square. We are a family-owned Bar & Restaurant serving delicious fare, craft beers & libations. Our elegant bi-level venue is roomy with a large bar and comfortable seating. Stop by with friends afterwork for a quick bite and a cocktail. For Private Parties, offer a variety of party packages menus custom made for your next special event, featuring private dining rooms to entertain your guests; ample space to accommodate large parties all with a dedicated wait-staff by your side and to make your event memorable. For party inquiries, call us at 646-370-466"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7426,
        store_longitude: -73.9849,
        total_orders: 6380.03,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Steak, Burger, Salad, Sushi, Shrimp Cocktail, Chicago-Style, Tasty, Wine, Californian, Spicy',
        annual_dol: 6658.08,
        description:
            'Upscale chain eatery serving burgers, steaks & other American fare in stylish, spacious surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7483,
        store_longitude: -73.9905,
        total_orders: 5107.79,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Steak, Pizza, Beer, Salad, Coffee, Wine, Margarita, Dessert, Bourbon',
        annual_dol: 5241.73,
        description:
            'American restaurant with comfort fare, a raw bar & cocktails in a space with red-leather banquettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.9892,
        total_orders: 14039.66,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Hotel, Wine, Coffee, Beer, Modern, Polished, American, Contemporary, Classic, Rooftop',
        annual_dol: 14554.87,
        description:
            'walking from Madison Square Park.Featuring floor-to-ceiling windows and contemporary furnishings, the industrial-chic rooms come with free Wi-Fi, smart radios and flat-screen TVs. Upgraded rooms add balconies and/or Empire State Building views. Suites offer living areas.Amenities include a modern restaurant, a coffee shop and a polished lobby bar, as well as a patio and an airy rooftop cocktail lounge offering city views. Pets are welcome for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.9886,
        total_orders: 1930.3,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Irish, Rich, American, Cozy, Nachos, Rooftop, Casual, Friendly',
        annual_dol: 6830.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7241,
        store_longitude: -73.9959,
        total_orders: 3018.96,
        neighborhood: 'Nolita',
        keywords: 'Vegetarian, Trendy, Awesome, Incredible, Creative, Casual',
        annual_dol: 3254.22,
        description:
            'Artful nightspot featuring a vegetarian supper club, a bar & a moody space for live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7287,
        store_longitude: -73.9998,
        total_orders: 2767.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Pizza, Meatball, Italian, Beer, Wine, Shrimp Scampi, French Toast, Delicious',
        annual_dol: 2874.32,
        description:
            'Evocative gastropub & wine bar pairing its drinks with Italian-influenced fare in comfortable digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6701,
        store_longitude: -73.9584,
        total_orders: 1064.9,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Hamburger, Beer, Tasty, Wine, Spicy, Upscale, American, Vintage, Homemade',
        annual_dol: 1111.07,
        description:
            'Rustic-chic tavern in an old grocery store, serving creative pub grub with cocktails & craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7709,
        store_longitude: -73.9813,
        total_orders: 14927.52,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Rich, Hotel, Glamorous, Wine, Mexican, Trendy, Legendary, Delicious, Iconic, Margarita',
        annual_dol: 15315.76,
        description:
            "Central Park.Chic, modern rooms feature Italian linens, animal prints and eclectic artwork, plus flat-screen TVs, minibars and designer toiletries. Suites offer separate living areas. Wi-Fi is available (fee).There's a trendy lobby bar, a rooftop lounge and a relaxed seafood restaurant, along with a fitness room and a seasonal rooftop pool. Meeting and event spaces are also available. Parking is offered for a fee.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.9555,
        total_orders: 140.01,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Mexican, Beer, Shrimp Cocktail, Coffee, Dessert, American, Urban, Classic, Authentic, Great',
        annual_dol: 609.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -73.9276,
        total_orders: 279.24,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Coffee, Hotel, Spacious, Bright, Clean, Fusion, Lit, Rooftop, Hip, Casual',
        annual_dol: 1166.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7274,
        store_longitude: -73.9865,
        total_orders: 2899.82,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Whiskey, Vodka, Upscale, Beer, Delicious, Martini, Sushi, Warm, Classy',
        annual_dol: 13047.92,
        description:
            '"Lounge and bar offering beer, wine & liquor in a setting of exposed brick walls"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -73.987,
        total_orders: 1819.04,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Tequila, Vodka, Wine, Whiskey, Beer, Gin, Martini, Margarita, Tasty, Upscale',
        annual_dol: 1897.91,
        description:
            'Intimate, amber-lit martini bar in the basement of a townhouse with a bamboo garden & nightly DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.8672,
        total_orders: 448.77,
        neighborhood: 'Middle Village/Rego Park/Flushing',
        keywords:
            'Sushi, Shrimp Cocktail, Wine, Martini, Irish, Trendy, Cozy, Warm, Homemade, Asian',
        annual_dol: 1631.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5976,
        store_longitude: -74.063,
        total_orders: 221.23,
        neighborhood: 'Arrochar/Fort Wadsworth',
        keywords:
            'Burger, Pizza, Italian, Gluten Free, Flavorful, Wine, Rustic, Classy, Charming, American',
        annual_dol: 257.87,
        description:
            'Charming Italian mainstay dating back to 1921 serving rustic fare in a white-tablecloth setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5466,
        store_longitude: -74.138,
        total_orders: 424.89,
        neighborhood: 'Great Kills',
        keywords: 'Hurricane, Great, Fancy, Clean, Friendly',
        annual_dol: 483.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8871,
        store_longitude: -73.9045,
        total_orders: 112.03,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Vodka, Beer, American, Irish, Comfortable, Dope, Casual, Great, Excellent',
        annual_dol: 457.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.761,
        store_longitude: -73.9698,
        total_orders: 82.65,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Spacious, Sleek, High-End, Modern, Great, Clean',
        annual_dol: 337.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5605,
        store_longitude: -73.9108,
        total_orders: 133.47,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords: 'Local',
        annual_dol: 363.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.688,
        store_longitude: -73.9866,
        total_orders: 2324.21,
        neighborhood: 'Boerum Hill',
        keywords:
            'Salad, Wine, Stylish, Delicious, Cozy, Rustic, Mediterranean, Healthy, Friendly, Fabulous',
        annual_dol: 2922.54,
        description:
            'Persian-inspired eats & unique craft cocktails offered in a stylish, rustic-chic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9866,
        total_orders: 4236.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Salad, Wine, Italian, Organic, Warm, Crisp, Relaxed, American, Contemporary',
        annual_dol: 4290.25,
        description:
            'Relaxed New American bistro with fixed-price pre-theater meals available in a contemporary space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9898,
        total_orders: 977.28,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Gluten Free, Italian, Warm, Homemade, Seasonal, Friendly, Classy, Authentic, Fabulous, Fresh',
        annual_dol: 998.01,
        description:
            'Italian eatery offering Roman-style pastas, meats & more in a cozy, exposed-brick townhouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7573,
        store_longitude: -73.9862,
        total_orders: 17638.53,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Garlic Bread, Pizza, Chicken Parmigiana, Italian, Meatball, Vodka, Tasty, Elegant, Contemporary, Rooftop',
        annual_dol: 18180.12,
        description:
            'Low-key eatery offering a big menu of pizza & Italian classics, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -74.006,
        total_orders: 78.4,
        neighborhood: 'Civic Center',
        keywords:
            'Rich, Delicious, American, Coffee, Korean, Iconic, Premium, Fun, Hotel, Memorable',
        annual_dol: 320.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8034,
        store_longitude: -73.9559,
        total_orders: 1899.67,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Coffee, Sushi, Irish, Cozy, Modern, Laid-Back, Casual, Great',
        annual_dol: 2020.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7211,
        store_longitude: -73.9946,
        total_orders: 6585.08,
        neighborhood: 'Nolita',
        keywords: 'Wine, Upscale, Sweet, Friendly, Perfect, Fun, Classy, Cool, Great, Excellent',
        annual_dol: 6733.27,
        description:
            'DJs spin on weekends at this sleek, candle-lit nook attracting a varied crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7768,
        store_longitude: -73.9791,
        total_orders: 23920.68,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Steak, Burger, Mexican, Salad, Tasty, Margarita, Wine, Delicious, Fajitas, American',
        annual_dol: 25053.35,
        description:
            'Cozy neighborhood standby where Southwestern cuisine is accompanied by potent margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7482,
        store_longitude: -73.9929,
        total_orders: 10510.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Steak, Whiskey, Chocolate Mousse, Tasty, Beer, Wine, Legendary, Delicious, Nachos, Irish',
        annual_dol: 13769.96,
        description: 'Sports bar near Madison Square Garden serving drinks & pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -74.0046,
        total_orders: 260.39,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Luxurious, Iconic, Exceptional, Perfect, Urban, Modern, Natural, Unique',
        annual_dol: 419.62,
        description:
            '"Event space in the heart of Chelsea, weddings, product launch, bar/bat mitzvah, corporate events, photo shoots and more."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7236,
        store_longitude: -73.9406,
        total_orders: 15.16,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Beer, French, Italian, Dessert, Shrimp Scampi, American, Impeccable, Lively, Casual',
        annual_dol: 61.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5555,
        store_longitude: -74.214,
        total_orders: 89.72,
        neighborhood: 'Rossville',
        keywords: 'Fabulous, Awesome, Iconic, Great, Wonderful, Unique, Casual',
        annual_dol: 133.2,
        description: '"Trendy Catering Hall"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6453,
        store_longitude: -73.7825,
        total_orders: 456.33,
        neighborhood: 'Jamaica',
        keywords: 'Wine, Coffee, Martini, Upscale, Creative, Fine',
        annual_dol: 519.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6449,
        store_longitude: -73.7861,
        total_orders: 177.48,
        neighborhood: 'Jamaica',
        keywords: 'Pizza, Wine, Salad, Donut, Beer, Hotel, Premium, Swiss, Ample, Cool',
        annual_dol: 725.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8035,
        store_longitude: -73.9637,
        total_orders: 628.97,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Pizza, Vegetarian, Chicken Parmigiana, Salad, Italian, Gluten Free, Wine, Delicious, American, Casual',
        annual_dol: 647.27,
        description:
            'Thin crust pizzas & classic red sauce Italian fare at a casual neighborhood stalwart.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.9397,
        total_orders: 105.44,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Meatball, Italian, French, Fresh, Traditional, Great, Relaxed, Casual, Authentic',
        annual_dol: 370.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6046,
        store_longitude: -73.9698,
        total_orders: 54.96,
        neighborhood: 'Gravesend',
        keywords:
            'Pizza, Italian, Salad, Mexican, Coffee, Wine, Stylish, Asian, Premium, Irresistible',
        annual_dol: 92.3,
        description:
            'Stylish choice with an intimate bar for elevated kosher Italian cuisine & select imported wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6666,
        store_longitude: -73.9853,
        total_orders: 30.51,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Pizza, Italian, Gluten Free, Salad, Beer, Spicy, Wine, Avocado Toast, Warm',
        annual_dol: 124.71,
        description:
            'Sourdough-crust pizza with unique toppings offered in a nostalgic setup with sidewalk seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7526,
        store_longitude: -73.9724,
        total_orders: 777.73,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Sushi, Organic, Japanese, Upscale, Spicy, Elegant, French, Warm, Homemade',
        annual_dol: 827.65,
        description:
            'Japanese izakaya eatery where the emphasis is on robata grilling & sake drinking.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.9755,
        total_orders: 27.94,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Modern, Hotel, Beer, Contemporary, Perfect, Premium, Classic, Iconic, Creative',
        annual_dol: 114.19,
        description:
            '"Convene at 237 Park Avenue is a signature meetings and events "venue of the future," according to EventMB. Unlike any other conference center in New York City, this location is purpose-built for professional meeting planners who are looking to host executive retreats, corporate off-sites and private evening receptions. The unique venue features include a built-in fireplace, curated art, bronze bar taps, video walls, and plush lounge seating - all of which evoke intimacy and warmth. For those who seek a loft-style space in Midtown combined with the premium amenities and hospitality services of a conference venue, Convene at 237 Park Avenue will ensure that your participants leave impressed."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7807,
        store_longitude: -73.9494,
        total_orders: 1281.84,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tequila, Mexican, Tacos, Margarita, Flavorful, Pizza, Dessert, Delicious, Mojito, Authentic',
        annual_dol: 1354.74,
        description: '"Taqueria Mexicana"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6793,
        store_longitude: -73.9494,
        total_orders: 82.18,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Burger, Beer, Shrimp Cocktail, Margarita, Bbq, Upscale, Rum, African, Caribbean, Spacious',
        annual_dol: 335.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.728,
        store_longitude: -74.0022,
        total_orders: 11487.38,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Pizza, Salad, Chicken Parmigiana, Italian, Beer, Dessert, Martini, Spicy, Trendy',
        annual_dol: 11735.81,
        description:
            'Legendary circa-1915 cafe serving small plates & cocktails in an updated historic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -74.0161,
        total_orders: 7435.86,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Steak, Burger, Vegetarian, Cheesesteak, Mexican, Wine, Upscale, Italian, Stylish, French',
        annual_dol: 7618.45,
        description:
            'Sophisticated chophouse chain serving steak, seafood, sandwiches & cocktails in a lively setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.8842,
        total_orders: 913.07,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Salad, Meatball, Italian, South American, Bruschetta, Creme Brulee, American, Eclectic, Trendy, Creamy',
        annual_dol: 952.66,
        description:
            'Relaxing, brick-lined bar & eatery serves up filling Italian dishes & lunch specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7802,
        store_longitude: -73.9798,
        total_orders: 542.06,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegetarian, Lobster, Steak, Salad, Sushi, Tuna Steak, Italian, Wine, Rustic, Organic',
        annual_dol: 2042.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -73.9861,
        total_orders: 2682.74,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Vegetarian, Tasty, Korean, Beer, Spicy, Wine, Trendy, Ale, Japanese, Bbq',
        annual_dol: 3058.87,
        description:
            'Glossy lounge serving updated versions of Korean & Japanese street foods with cocktails & DJ tracks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9162,
        total_orders: 501.04,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Whiskey, Karaoke, Irish, Local, Cozy, Relaxed, Friendly, Fun, Western',
        annual_dol: 570.65,
        description:
            'Laid-back bar offering 20+ rotating beers on tap in a nothing-fancy setting with a back garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6831,
        store_longitude: -73.9927,
        total_orders: 4056.31,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Wine, Luxurious, Romantic, Cozy, Premium, Rum, Sweet, Fantastic, Perfect, Classic',
        annual_dol: 4143.8,
        description:
            'Low-lit lounge featuring craft cocktails amid bordello-inspired decor in a one-time massage parlor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.737,
        store_longitude: -73.9916,
        total_orders: 3678.15,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Sushi, Japanese, Wine, Spicy, Homemade, Artisanal, Sukiyaki, Premium, Spacious, Casual',
        annual_dol: 4043.06,
        description:
            'Outpost of a Japanese noodle chain offering housemade udon plus sushi & tempura in spacious digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9812,
        total_orders: 4946.99,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Lobster Tail, Sushi, Shrimp Cocktail, Salad, Wine, Upscale, Caesar Salad, American',
        annual_dol: 5127.68,
        description:
            'Outpost of the upscale steakhouse chain offers classic American fare & a clubby, refined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.772,
        total_orders: 851.24,
        neighborhood: 'Bayside',
        keywords: 'Salad, Steak, Korean, Nachos, Spicy, Delicious, Asian, Beer, Yummy, American',
        annual_dol: 890.47,
        description:
            '"We first opened our doors in 2006, in Flushing NY and we’ve been flapping our wings ever since. Our chicken has impacted our community so much so that we’ve expanded to our newest location on Bell Blvd Bayside where we will continue to provide superior food and service."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7787,
        store_longitude: -73.7765,
        total_orders: 484.61,
        neighborhood: 'Bayside/Bay Terrace',
        keywords: 'Tacos, Steak, Mexican, Gluten Free, Margarita, Wine, Hawaiian, Rum, Yummy, Fun',
        annual_dol: 1980.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8223,
        store_longitude: -73.9499,
        total_orders: 207.84,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Burger, Sushi, Salad, Beer, Coffee, Savory, Japanese, Spicy, Wine, Karaoke',
        annual_dol: 221.5,
        description:
            'Craft beer, pub grub & weekend brunch served in an urban-chic sports bar with karaoke nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9747,
        total_orders: 8738.82,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Chicken Caesar Salad, French Onion Soup, French, Irish, Caesar Salad, Filet Mignon, Hotel, Classic, Vibrant',
        annual_dol: 8993.99,
        description:
            'Bi-level French brasserie with a popular brunch, a vibrant bar scene & live opera Saturday nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.744,
        store_longitude: -73.9899,
        total_orders: 7498.84,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegetarian, Salad, Beer, Bourbon, Bbq, Legendary, Homemade, Sweet, Vibrant, Local',
        annual_dol: 7657.96,
        description:
            'Texas BBQ & beer served in a rustic-chic, cafeteria-style setup, with regular live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6941,
        store_longitude: -73.9618,
        total_orders: 143.09,
        neighborhood: 'Clinton Hill',
        keywords:
            'Hotdog, Tequila, Burger, Beer, Whiskey, Wine, Margarita, Delicious, Tranquil, Classy',
        annual_dol: 155.94,
        description:
            'Mellow hangout with craft cocktails, draft beer, local art & a tranquil garden patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6926,
        store_longitude: -73.9886,
        total_orders: 522.12,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Salad, Vegetarian, Organic, Delicious, Creamy, Crisp, Mediterranean, Healthy, Perfect, Casual',
        annual_dol: 2134.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7546,
        store_longitude: -73.9811,
        total_orders: 961.83,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Hotel, Beer, Fun, Awesome, Friendly, Latin, Great, Classic, Casual',
        annual_dol: 3376.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6951,
        store_longitude: -73.9961,
        total_orders: 203.26,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Pizza, Gluten Free, Tasty, Beer, Italian, Wine, Dessert, Warm, Spacious, Cozy',
        annual_dol: 207.57,
        description:
            'Thin-crust, brick-oven pizza with a gluten-free option, plus tap beers in an airy, minimalist space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7683,
        store_longitude: -73.9857,
        total_orders: 1373.44,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Italian, Coffee, Meatball, Elegant, Homemade, Authentic, Classic, Casual, Sophisticated',
        annual_dol: 1402.59,
        description:
            'Restaurant offering dishes from Puglia, including seafood crudo & pastas, plus Italian wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.984,
        total_orders: 1256.5,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Cheesecake, Hamburger, Pizza, Salad, Dessert, Wine, Bbq, Avocado Toast, Trendy',
        annual_dol: 1403.26,
        description:
            'Cheesecakes are the star at this Brooklyn-themed locale with deli & diner fare, BBQ & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.764,
        store_longitude: -73.9878,
        total_orders: 527.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Italian, Wine, Shrimp Scampi, Mediterranean, Bruschetta, African, Rustic, Premium, Spacious',
        annual_dol: 550.38,
        description:
            'A rustic space of wood beams & slate floors sets the scene for wines & Italian small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8086,
        store_longitude: -73.9521,
        total_orders: 155.18,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Tasty, Salad, Delicious, Beer, Turkish, Mediterranean, Healthy, Spectacular, Outstanding, Natural',
        annual_dol: 650.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7576,
        store_longitude: -73.9707,
        total_orders: 302.74,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Sushi, Japanese, Coffee, Sashimi, Quaint, Homemade, Sukiyaki, Casual, Fusion',
        annual_dol: 322.86,
        description:
            'Longtime Japanese spot for traditional fare like sushi & soba served in a serene setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7225,
        store_longitude: -73.9899,
        total_orders: 2372.57,
        neighborhood: 'Bowery',
        keywords: 'Stylish, Cozy, Warm, Intimate, Eclectic, Vintage, Hip, Fun, Perfect, Cool',
        annual_dol: 2422.91,
        description:
            'Cozy, hip nightspot with eclectic, vintage styling featuring diverse dance music & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7699,
        store_longitude: -73.9099,
        total_orders: 2.05,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'French, Mediterranean, Casual, Local, American, Omelette, Greek, Cool, Chill',
        annual_dol: 8.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6383,
        store_longitude: -73.5818,
        total_orders: 127.85,
        neighborhood: '',
        keywords: '',
        annual_dol: 447.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9874,
        total_orders: 16.35,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Wine, Beer, Gin, Indian, Delicious, Paneer Tikka, Baingan Bharta, Casual, Local',
        annual_dol: 66.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.749,
        store_longitude: -74.0081,
        total_orders: 1887.76,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Italian, Intimate, Rooftop, Creative, Natural, Great, Fresh, Diverse, Complex, Casual',
        annual_dol: 2013.25,
        description:
            'Pier59 Studios is a multimedia fashion studio, located at Chelsea Piers in New York City. At 110,000 square feet, it is considered to be the largest commercial photography/multimedia studio in the world.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.9961,
        total_orders: 1671.38,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Whiskey, Beer, Wine, Delicious, Spicy, Warm, Bourbon, Cozy, Friendly',
        annual_dol: 1748.6,
        description:
            '"With over 300 bottles of whiskey and sprits we know how to pour your favorite drink. Combine that with an authentic Guinness pour and an update on classic pub food it\'s easy to call this your new local."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6807,
        store_longitude: -73.9778,
        total_orders: 276.69,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Delicious, Cozy, American, Great, Excellent, Casual',
        annual_dol: 743.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7471,
        store_longitude: -74.0084,
        total_orders: 185.55,
        neighborhood: '',
        keywords:
            'Pizza, Coffee, Golfclub, Gin, Organic, Premium, Indulgent, Radiant, Fun, Sophisticated',
        annual_dol: 758.46,
        description:
            'Chelsea Piers is a series of piers in Chelsea, on the West Side of Manhattan in New York City. Located to the west of the West Side Highway and Hudson River Park and to the east of the Hudson River, ...',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6781,
        store_longitude: -73.9795,
        total_orders: 11.14,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Gluten Free, Salad, Italian, Delicious, Nachos, Bruschetta, Sweet, Fresh, American',
        annual_dol: 45.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9786,
        total_orders: 714.74,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Rum, Sweet, Chinese, Friendly, Fun, Traditional, Fantastic, Great, Casual, Signature',
        annual_dol: 858.43,
        description:
            'Rock bands, a Polynesian-esque setting & cocktails enliven this offbeat tiki bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6661,
        total_orders: 393.16,
        neighborhood: '',
        keywords:
            'Vegetarian, Wine, Beer, Organic, Meatball, American, Trendy, Modern, Traditional, Seasonal',
        annual_dol: 401.5,
        description:
            'Colorful, arty local mainstay serving creative apps & other American fare plus brick-oven pizzas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6734,
        store_longitude: -73.9568,
        total_orders: 1054.85,
        neighborhood: 'Crown Heights',
        keywords:
            'Vegan, Vegetarian, Pizza, Organic, Beer, Savory, Ethiopian, Delicious, Zesty, Natural',
        annual_dol: 2068.12,
        description:
            'Plant-based Ethiopian cuisine is served in a colorful brick-walled space covered in murals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.585,
        store_longitude: -73.6521,
        total_orders: 271.9,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Sushi, Hotel, Tasty, Mediterranean, Sashimi, Comfortable, Outstanding, Great',
        annual_dol: 309.68,
        description:
            'Airy rooms with hardwood floors feature free Wi-Fi and flat-screen TVs. Suites add seating areas or separate living rooms with futons or pull-out sofas. Some suites have extra bedrooms or whirlpool tubs.Amenities include a formal Mediterranean restaurant and an informal bar/grill, plus a surf shop and event space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7173,
        store_longitude: -73.9551,
        total_orders: 2372.64,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Whiskey, Stylish, Romantic, Gourmet, Delicious, Warm, Irish, Urban',
        annual_dol: 6103.41,
        description:
            'This stylish tasting room of a local wine producer also offers beer & a menu of gourmet nibbles.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6769,
        store_longitude: -73.972,
        total_orders: 119.83,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Mexican, Tacos, Salad, Burritos, Warm, Healthy, Wholesome, Local, Casual, High-Protein',
        annual_dol: 612.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.745,
        store_longitude: -73.9832,
        total_orders: 386.74,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Hamburger, Salad, Sushi, Mimosa, Champagne, American, French, Bellini, Stylish',
        annual_dol: 2269.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -73.9771,
        total_orders: 57.42,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Salad, Roast Beef, Homemade, Stylish, Casual, Coleslaw, Classic, Incredible',
        annual_dol: 63.93,
        description:
            'Pastrami, matzo ball soup & other Jewish deli fare are found at this updated diner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.988,
        total_orders: 2031.48,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Hamburger, Meatball, Beer, Bbq, American, Wine, Thai, Retro, Classic',
        annual_dol: 2850.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.9751,
        total_orders: 1900.67,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Italian, Wine, Delicious, Rich, Cozy, Tranquil, Comfortable, Exquisite, Innovative, Friendly',
        annual_dol: 1944.02,
        description:
            'Tranquil old-school Italian spot with black-tie waiters, favored by regulars for special occasions.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.9836,
        total_orders: 1294.95,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Salad, Cheesesteak, French Toast, French, Philly Cheesesteak, Belgian, Caesar Salad, American, Retro, Reuben',
        annual_dol: 1370.07,
        description:
            "Multi-level '50s-themed diner with singing servers & an extensive menu of American classics.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7156,
        store_longitude: -73.9937,
        total_orders: 1493.32,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Sushi, Wine, American, Organic, Japanese, Hotel, Legendary, Healthy, Local, Traditional',
        annual_dol: 6104.2,
        description:
            '"Atmospheric sushi & omakase restaurant with specialty cocktails, sake, and wine."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.8804,
        total_orders: 31.81,
        neighborhood: 'Jackson Heights',
        keywords:
            'Colombian, Salad, American, Latin, Fusion, Traditional, Modern, Innovative, Casual',
        annual_dol: 130.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7425,
        store_longitude: -73.9992,
        total_orders: 1329.74,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Flavorful, Spanish, Wine, Gluten Free, Rich, Delicious, Vegan, Creamy, Smoky, Sweet',
        annual_dol: 1464.96,
        description:
            'Stylish, brick-walled Spanish eatery specializing in paella, with tapas & paired beverages.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -74.0054,
        total_orders: 109.11,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Classic, New Zealand, Premium, Hip, Casual, Great',
        annual_dol: 446.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7087,
        store_longitude: -73.9579,
        total_orders: 1272.19,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Whiskey, Wine, Beer, Upscale, Fun, Friendly, Awesome, Amazing, Great, Fine',
        annual_dol: 1327.35,
        description:
            'South Williamsburg heavy-metal bar, with horror/biker memorabilia & a unique jukebox.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6789,
        store_longitude: -73.9682,
        total_orders: 5078.05,
        neighborhood: 'Prospect Heights',
        keywords: 'Burger, Beer, Whiskey, Wine, Coffee, Texan, Bourbon, Rum, Karaoke, Nachos',
        annual_dol: 5346.3,
        description:
            'A sprawling Western-themed honky-tonk saloon serving Southern-style bar food & featuring live bands.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.687,
        store_longitude: -73.9622,
        total_orders: 64.35,
        neighborhood: 'Clinton Hill',
        keywords:
            'Vegetarian, Mexican, Salad, Coffee, Casual, Healthy, Friendly, Local, Vibrant, Great',
        annual_dol: 263.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7106,
        store_longitude: -73.9591,
        total_orders: 40.37,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Steak, Beer, Dominican, Bbq, Wine, Spanish, American, Latin, Premium, Traditional',
        annual_dol: 98.59,
        description:
            'A menu of traditional Dominican fare & drinks in an upbeat setting with exposed brick.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.9736,
        total_orders: 228.16,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Spanish, Shrimp Cocktail, Delicious, Sweet, Cozy, Spectacular, Classy, Friendly, Hotel, Sangria',
        annual_dol: 279.49,
        description:
            'Neighborhood spot that has been serving up Spanish classics in its cozy space since 1977.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -73.963,
        total_orders: 497.72,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Salad, Meatball, Italian, Wine, Beer, Thai, Creamy, Prosecco, Modern',
        annual_dol: 591.2,
        description:
            'Industrial-chic digs & skyline murals are the backdrop for an Italian cafe, coffeehouse & bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.929,
        total_orders: 5.36,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Salad, Wine, Bourbon, Savory, Chinese, Polished, American, Modern, Perfect',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7205,
        store_longitude: -73.9883,
        total_orders: 11083.49,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Rooftop, Hotel, American, Australian, Spectacular, Premium, Hip, Creative, Cool',
        annual_dol: 11374.83,
        description:
            '"Soho House\'s second property in New York, Ludlow House is a private members\' club located in a former gold-leaf factory and one-time funeral home on the Lower East Side. It has four floors of club space with a kitchen, two bars, a member event space and a rooftop restaurant."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7206,
        store_longitude: -73.9845,
        total_orders: 268.55,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Jamaican, Caribbean, Rum, Chilean, Colorful, Fantastic, Creative, Great, Unique',
        annual_dol: 332.46,
        description:
            'Funky, colorful venue offering creative Caribbean classics, plus desserts, rum & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9905,
        total_orders: 57.65,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Vegan, Mexican, Tacos, Tequila, Vegetarian, Margarita, Nachos, Spicy, Warm, Contemporary',
        annual_dol: 477.45,
        description:
            'Contemporary Mexican spot with a colorful, all-vegan menu, plus tequila & mezcal cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9851,
        total_orders: 7580.63,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Salad, Lobster Bisque, Wine, Ribeye Steak, Caesar Salad, American, High-End, Welcoming',
        annual_dol: 8008.74,
        description:
            'Storied chain serving high-end steakhouse fare, seafood & diverse wines in clubby surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.809,
        store_longitude: -73.9449,
        total_orders: 2087.93,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Pizza, Vegetarian, Italian, Wine, Margarita, Dutch, Ethiopian, Relaxed, Casual, Fusion',
        annual_dol: 2202.51,
        description:
            'Relaxed Italian spot with wood-fired pizzas, Italian entrees & craft cocktails, plus patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9842,
        total_orders: 8814.39,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Irish, Bbq, American, Vintage, Warm, Classic, Hip, Comfortable, Casual, Friendly',
        annual_dol: 9312.56,
        description:
            'Warm haunt featuring American fare in a wood-paneled dining room adorned with Broadway memorabilia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7427,
        store_longitude: -74.0039,
        total_orders: 316.12,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Steak, Sushi, Salad, Gluten Free, Wine, Dessert, Japanese, Vodka, Chinese',
        annual_dol: 1107.17,
        description:
            'This enormous Pan-Asian eatery is known for a huge Quan Yin centerpiece & a trendy crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7113,
        store_longitude: -73.9494,
        total_orders: 1956.14,
        neighborhood: 'Williamsburg',
        keywords: 'Canadian, Whiskey, Wine, Beer, Ale, Awesome, Friendly, Great, Casual',
        annual_dol: 2014.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -73.9446,
        total_orders: 462.86,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Steak, Cheesesteak, Vegetarian, Vegan, Whiskey, Vodka, Beer, Wine, Gourmet',
        annual_dol: 742.15,
        description:
            'Chill neighborhood watering hole crafting gourmet takes on traditional pub grub & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.708,
        store_longitude: -74.0078,
        total_orders: 3392.52,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Steak, Fried Chicken, Japanese, Sashimi, Cozy, Miso Soup, Traditional, Tempura, Classic',
        annual_dol: 3539.61,
        description:
            'Japanese seafood, hot entrees & signature fried chicken plated in a sophisticated atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7274,
        store_longitude: -73.9844,
        total_orders: 615.85,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Dessert, Wine, Moroccan, French Toast, French, Mediterranean, Delicious, Organic, Lebanese',
        annual_dol: 645.1,
        description:
            'Classic Moroccan flavors & outdoor seating draw a bohemian crowd to this neighborhood standby.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7236,
        store_longitude: -73.9855,
        total_orders: 16.75,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Hamburger, Salad, Gourmet, Coffee, Wine, American, Warm, Cozy, French',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6078,
        store_longitude: -74.1627,
        total_orders: 26.92,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Sushi, Steak, Japanese, Sweet, Teriyaki Salmon, Friendly, Teriyaki, Local, Fresh, Casual',
        annual_dol: 110.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 1010.11,
        neighborhood: 'Astoria/LIC',
        keywords: 'Shrimp Cocktail, Chinese, Cuban, Beer, Bbq, Casual, Fresh, Great',
        annual_dol: 1053.91,
        description: 'Longtime, no-frills diner serving up a mix of Cuban & Chinese fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9256,
        total_orders: 5.8,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tacos, Tequila, Mexican, Steak, Sushi, Nachos, Vodka, Latin, Exotic, Modern',
        annual_dol: 23.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9738,
        total_orders: 820.84,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Lobster, Dessert, Elegant, Delicious, Martini, Cozy, Polished, French, Picturesque',
        annual_dol: 875.41,
        description:
            "Bergdorf Goodman's 7th-floor cafe offers salads & sandwiches, afternoon tea & Central Park views.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7629,
        store_longitude: -73.9879,
        total_orders: 302.64,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Mexican, Salad, Vegan, Margarita, Dessert, Tasty, Champagne, Burritos, Korean',
        annual_dol: 741.44,
        description:
            'Overstuffed burritos and margaritas pull in a lively crowd, as does the outdoor seating in summer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6041,
        store_longitude: -74.0069,
        total_orders: 5.69,
        neighborhood: 'Bath Beach',
        keywords: 'Mexican, Margarita, Homemade, Casual, Sweet, Mojito',
        annual_dol: 23.24,
        description:
            '"We offer full bar services with hot & cold finger food, happy hour everyday from 5pm-9pm. We can host private events with full open bar and food!! Contact us to: paraisobar1752@gmail.com"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -73.9904,
        total_orders: 21.62,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegan, Coffee, Chicken Pot Pie, Nachos, American, Creamy, Cozy, Perfect, Sweet, Casual',
        annual_dol: 88.39,
        description:
            'Neighborhood coffeehouse serving eggs, granola, sandwiches & salads in a homey, laid-back setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.8841,
        total_orders: 9.75,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Steak, Mexican, Karaoke, Wine, Casual, Homemade, Authentic, Unforgettable, Fresh, Signature',
        annual_dol: 429.84,
        description:
            '"El Maguey offers the best in Mexican cuisine. Located in Jackson Heights, Queens we are the Mexican experience. Our dishes are built around the the idea that food should be authentic. Whether in the mood for a fish taco or one of our signature dishes, El Maguey ensures an unforgettable experience found only in Mexico - right in the heart of Queens! While you\'re here, don\'t forget to grab our signature cocktail -El Maguey at the bar or out in the side street seating area. We offer Karaoke nights, and are open LATE!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8672,
        store_longitude: -73.9172,
        total_orders: 149.77,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Tacos, Mexican, Salad, Nachos, Tasty, Fajitas, Latin, Dominican, Caesar Salad, American',
        annual_dol: 612.21,
        description:
            'Mexican dishes with a twist presented in a cool, casual setting with a warm atmosphere & full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6284,
        store_longitude: -74.0292,
        total_orders: 0,
        neighborhood: 'Bay Ridge',
        keywords:
            'Coffee, Ale, Modern, Tasty, Hawaiian, American, Friendly, Great, Casual, Wonderful',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9966,
        total_orders: 3349.87,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Spanish, Dessert, Wine, Salad, Tasty, Hotel, Classy, Spacious, Fabulous',
        annual_dol: 13693.15,
        description:
            'This longtime eatery offers large portions of paella & other traditional Spanish dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7062,
        store_longitude: -74.0035,
        total_orders: 2997.06,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Vegetarian, Gluten Free, Korean, Spicy, Asian, Ramen, French, Casual, Seasonal, Modern',
        annual_dol: 3336.58,
        description:
            'Pork dishes are prominently featured at this bustling Asian eatery from chef David Chang.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6869,
        store_longitude: -73.9631,
        total_orders: 625.41,
        neighborhood: 'Clinton Hill',
        keywords: 'Vegan, Tacos, Wine, Beer, Mexican, Tasty, Upscale, Natural, Perfect, Classy',
        annual_dol: 728.99,
        description:
            'Hip haunt with an all-vegan menu of Mexican plates & sweet treats plus cocktails & craft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6223,
        store_longitude: -74.0967,
        total_orders: 97.86,
        neighborhood: 'Silver Lake/Mid Island',
        keywords: 'Delicious',
        annual_dol: 319.48,
        description:
            'Easygoing, compact golf course with hilly & flat holes, a restaurant with a patio & event space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7253,
        store_longitude: -73.9843,
        total_orders: 88.74,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Thai, Spicy, Green Papaya Salad, Delicious, Polished, Irresistible, Traditional, Papaya Salad, Authentic',
        annual_dol: 271.88,
        description:
            'Smartly decorated Thai restaurant presenting regional Isan cuisine via shareable plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -73.9519,
        total_orders: 56.84,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Coffee, Mimosa, Funky, Israeli, Mediterranean, Cozy, Friendly, Perfect, Outstanding',
        annual_dol: 232.36,
        description:
            'Light-filled cafe with reclaimed accents & global-accented comfort food morning-early evening.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7778,
        store_longitude: -73.7798,
        total_orders: 62.08,
        neighborhood: 'Bayside/Bay Terrace',
        keywords:
            'Lobster, Sushi, Salad, Pizza, Gluten Free, Japanese, Wine, Sashimi, Spicy, Asian',
        annual_dol: 253.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9674,
        total_orders: 1429.89,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Shrimp Cocktail, Italian, Mexican, Asian, American, Southern, Local, Casual, Traditional, Lively',
        annual_dol: 1531.2,
        description:
            'Neighborhood Italian restaurant with a lively local bar scene, piano player & sing-along crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9715,
        total_orders: 7573.26,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Steak, Sushi, Salad, Vegetarian, Dessert, Wine, Gluten Free, Vodka, Chinese',
        annual_dol: 7733.95,
        description:
            'This enormous, clublike Pan-Asian eatery is known for a giant Buddha centerpiece & beautiful crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.971,
        total_orders: 50.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Tacos, Beer, Burritos, Wine, Homemade, Local, Casual, Healthy, Premium',
        annual_dol: 236.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9843,
        total_orders: 4478.68,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Tacos, Burger, Beer, Tasty, Irish, Bourbon, Bbq, Hotel, Delicious, Vintage',
        annual_dol: 4818.2,
        description:
            'Clubby Irish gastropub with an extensive beer menu featuring craft, domestic & international brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7402,
        store_longitude: -73.9889,
        total_orders: 3126.27,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Vegetarian, Wine, Whiskey, Coffee, Beer, Rustic, Italian, Comfortable, French, Californian',
        annual_dol: 4358.08,
        description:
            'This lively bistro serving French comfort food in a rustic setting is popular for brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6919,
        store_longitude: -73.9714,
        total_orders: 466.36,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Magical, Delicious, Chinese, Sustainable, Cozy, Italian, Organic, Premium, Relaxed',
        annual_dol: 634.87,
        description:
            'Relaxed wine bar with a zero waste goal serving tapas-style bites paired with natural wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6913,
        store_longitude: -73.9918,
        total_orders: 346.56,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Lasagna, Italian, Gluten Free, Pizza, Wine, Dessert, Upscale, Elegant, Ramen, Intimate',
        annual_dol: 1305.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9874,
        total_orders: 4379.84,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Hotel, Wine, Elegant, Cozy, Comfortable, Whiskey, Friendly, Classic, Great, Fantastic',
        annual_dol: 17655.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7452,
        store_longitude: -73.9883,
        total_orders: 3945.16,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Champagne, Wine, Hotel, Elegant, Delicious, Coffee, Legendary, Mediterranean, Australian, Iconic',
        annual_dol: 16126.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -74.0034,
        total_orders: 3075.24,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Burger, Dessert, Tasty, Delicious, Awesome, Excellent, Great, Classic, American',
        annual_dol: 4522.37,
        description:
            'Comfy eatery set in a four-story townhouse offering American plates & twists on classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.748,
        store_longitude: -73.8924,
        total_orders: 322.49,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Wine, Beer, Irish, Ale, Rustic, Awesome, Friendly, Authentic, Great, Local',
        annual_dol: 451.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7802,
        store_longitude: -73.9802,
        total_orders: 24.13,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Vegetarian, Italian, Coffee, Wine, Meatball, Beer, Organic, Bruschetta, Cozy',
        annual_dol: 69.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.994,
        total_orders: 9.03,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Cajun, Salad, Korean, Spicy, Delicious, Asian, Fried Chicken, French, Sweet',
        annual_dol: 36.91,
        description: '"Korean Fried Chicken"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6289,
        store_longitude: -73.9471,
        total_orders: 43.11,
        neighborhood: 'Midwood/Flatlands',
        keywords: 'Burger, Vegan, Tacos, Salad, Vegetarian, Dessert, Wine, Juicy, Coffee, Spicy',
        annual_dol: 176.2,
        description:
            '"A Juice Bar by day , Restaurant & Lounge by night. BEST of both worlds. We have a variety of fresh smoothies/juices and delicious Haitian American Dishes. We host events with live Djs providing great vibes on a nightly basis."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9838,
        total_orders: 17297.87,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Lobster Bisque, Wine, French Onion Soup, French, Legendary, Gin, Filet Mignon',
        annual_dol: 17297.87,
        description:
            'Iconic steakhouse offering classic cuts & raw bar items in renovated digs with red banquettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9838,
        total_orders: 277.36,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Cuban, Wine, Delicious, Salad, Fun, Rum, Welcoming, Sweet, Vibrant, Lively',
        annual_dol: 303.55,
        description:
            'A lively spot serving classic Cuban cuisine, with live music and hand-rolled cigars on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7695,
        store_longitude: -73.9885,
        total_orders: 202.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Beer, Wine, Spanish, Greek, Tex-Mex, Mediterranean, Local, Casual, Spacious',
        annual_dol: 225.59,
        description:
            'Classic Greek comfort eats served in a casual bistro setting with gingham tablecloths & tile floors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6919,
        store_longitude: -73.9259,
        total_orders: 68.23,
        neighborhood: 'Bushwick',
        keywords:
            'Coffee, Turkey Sandwich, Cozy, Homemade, Latin, Fun, Local, Casual, Great, Unique',
        annual_dol: 151.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -73.9549,
        total_orders: 699.65,
        neighborhood: 'Greenpoint',
        keywords:
            'Mexican, Salad, Canadian, Avocado Toast, American, Delicious, Vintage, Seasonal, Casual, Local',
        annual_dol: 714.49,
        description:
            'Seasonal cocktails pair with American plates in a bustling space with vintage touches & a small bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.733,
        store_longitude: -73.9596,
        total_orders: 2040.71,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Vodka, Wine, Ale, Coffee, Rum, Italian, American, Quaint, Sweet',
        annual_dol: 2378.71,
        description:
            'Gastropub-style small plates, house cocktails & brews in a quaint corner tavern with a tin ceiling.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6777,
        store_longitude: -73.998,
        total_orders: 456.52,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Vodka, Beer, Shrimp Cocktail, Coffee, Mimosa, Italian, Belgian, American, Seasonal',
        annual_dol: 880.89,
        description:
            "Circa-1890 bar, Brooklyn's oldest, featuring cocktails, beer, American dishes & outdoor tables.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.9537,
        total_orders: 297.97,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Salad, Italian, Meatball, Wine, Organic, Bruschetta, Polished, Classy, Authentic',
        annual_dol: 441.93,
        description:
            'Polished bistro & bar offering a menu of traditional Italian entrees, pasta & brick-oven pizza.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6786,
        store_longitude: -74.0026,
        total_orders: 552.99,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Lobster Roll, Lobster, Vodka, Vegetarian, Beer, Scotch, Rusty Nail, Instagrammable, Spicy, Upscale',
        annual_dol: 2260.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6468,
        store_longitude: -73.7841,
        total_orders: 3033.28,
        neighborhood: 'Jamaica',
        keywords: 'Flavorful, Wine, Salad, Burger, Steak, Beer, Martini, Donut, Warm, Hotel',
        annual_dol: 3097.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7035,
        store_longitude: -73.8021,
        total_orders: 27506.19,
        neighborhood: 'Jamaica',
        keywords: 'Steak, Italian, American, Asian, Traditional, Local',
        annual_dol: 29271.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.586,
        store_longitude: -73.7185,
        total_orders: 45.18,
        neighborhood: '',
        keywords: 'Classic, Great',
        annual_dol: 89.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.97,
        total_orders: 19.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Kebab, Salad, Pizza, Turkish, Mediterranean, Spicy, Premium, Traditional, Casual, Authentic',
        annual_dol: 28.6,
        description:
            'This bustling neighborhood Turkish spot dishes traditional food in basic environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7153,
        store_longitude: -74.009,
        total_orders: 3498.9,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Steak, Hamburger, Tacos, Vegetarian, Salad, Beer, Nachos, Chicken Wings, Wine',
        annual_dol: 3687.3,
        description:
            'Rustic sports bar with an enormous selection of beer, plus bar eats like ribs & wings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.9654,
        total_orders: 195.79,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Salad, Italian, Wine, Meatball, Upscale, Beer, Delicious, Cozy, Comfortable',
        annual_dol: 504.52,
        description:
            'Intimate Italian restaurant with upscale fare, many wines, desserts, and a fixed-price lunch option.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7375,
        store_longitude: -73.9813,
        total_orders: 1720.77,
        neighborhood: '',
        keywords:
            'Sushi, Spicy, Delicious, Korean, Premium, Japanese, Sashimi, Bbq, Fun, Fine Quality',
        annual_dol: 7033.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9859,
        total_orders: 2181.46,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegetarian, Wine, Gourmet, Gluten Free, Dessert, Margarita, Hotel, Organic, Delicious, Comfortable',
        annual_dol: 2233.48,
        description:
            'All-day spot in the Hotel Edison specializing in seasonal, mostly gluten-free comfort classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9929,
        total_orders: 1710.25,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hamburger, Gluten Free, Wine, Delicious, Beer, Dessert, Warm, American, Friendly, Wonderful',
        annual_dol: 1746.54,
        description:
            'Warm New American eatery with a bar & basement cabaret, known for drawing theatergoers & performers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7,
        store_longitude: -73.9938,
        total_orders: 1836.63,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Burger, Wine, Vodka, Gin, Martini, Ale, Stylish, Cozy, Warm, American',
        annual_dol: 1875.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7411,
        store_longitude: -73.993,
        total_orders: 6794.05,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Pizza, Burger, Wine, Upscale, Vodka, Beer, Gin, Karaoke, Elegant, Mediterranean',
        annual_dol: 6950.45,
        description:
            'Swanky, multi-level nightspot featuring pool tables, cocktails & a menu of upscale pizza & burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6871,
        store_longitude: -73.9904,
        total_orders: 1247.54,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords: 'Burger, Beer, Wine, Vietnamese, French, American, Cozy, Eclectic, Casual, Sweet',
        annual_dol: 1698.29,
        description:
            'An eclectic mix of comfort foods, from curry to Cincinnati-style spaghetti, served in a cozy space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7412,
        store_longitude: -73.9852,
        total_orders: 1204.12,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Coffee, Donut, Iconic, Timeless, Exquisite, Lively, Great, Seasonal, Cultural, Casual',
        annual_dol: 1256.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.9859,
        total_orders: 3017.01,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Salad, Vegetarian, Tasty, Korean, Wine, Spicy, Delicious, Spanish, Bbq, Modern',
        annual_dol: 3017.01,
        description:
            'Hip, contemporary Korean gastropub serving thoughtfully sourced small plates & soju cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7001,
        store_longitude: -73.9457,
        total_orders: 322.4,
        neighborhood: 'Bedford-Stuyvesant/Williamsburg',
        keywords: 'Coffee, Wine, Mexican, Beer, Scotch, Italian, American, Caribbean, Cozy, Homey',
        annual_dol: 870.17,
        description:
            'Seasonal dishes served in a hip, modern restaurant & wine bar that has a covered terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7667,
        store_longitude: -73.9687,
        total_orders: 640.21,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotel, Upscale, Luxurious, Premium, Classic, American, Sophisticated, Great, Organic, Modern',
        annual_dol: 2701.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5351,
        store_longitude: -74.237,
        total_orders: 21.45,
        neighborhood: 'Charleston',
        keywords:
            'Wine, Tasty, Dessert, American, Indulgent, Bright, Casual, Sangria, Great, Excellent',
        annual_dol: 87.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7258,
        store_longitude: -73.9917,
        total_orders: 10298.25,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Tequila, Wine, Beer, Margarita, Delicious, Hotel, Ale, Eclectic, Awesome, Classy',
        annual_dol: 10744.74,
        description:
            'Bi-level nightspot offering an eclectic musical mix, including live rock & DJ-led dance parties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7307,
        store_longitude: -73.986,
        total_orders: 309.34,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Italian, Wine, Dessert, Beer, Meatball, Delicious, Homey, Homemade, Innovative, Authentic',
        annual_dol: 421.11,
        description:
            'This Italian trattoria features a menu of Roman specialties, plus Italian wines & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6051,
        store_longitude: -73.9803,
        total_orders: 16.75,
        neighborhood: 'Gravesend',
        keywords:
            'Sushi, Salad, Meatloaf, Pizza, Peruvian, Tasty, Spanish, French, Casual, Premium',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7969,
        store_longitude: -73.9698,
        total_orders: 21.33,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Vegetarian, Salad, Flavorful, Beer, Contemporary, Tandoori Chicken, Casual, Indian, Retro, Cool',
        annual_dol: 87.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7074,
        store_longitude: -73.8353,
        total_orders: 15.92,
        neighborhood: 'Kew Gardens/Jamaica/Richmond Hill',
        keywords:
            'Mexican, Pizza, Wine, Beer, Gourmet, Italian, Spanish, Delicious, Peruvian, Elegant',
        annual_dol: 65.07,
        description:
            '"La Sala is an elegant Wine Bar & Dining Room focused on Gourmet Cuisine. We offer a full-liquor bar, curated Wine Menu & delicious flavored-sangrias in a classy, modern & chic space here in the Forest Hills,/Kew Gardens area."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9622,
        total_orders: 2256.68,
        neighborhood: 'Lenox Hill',
        keywords: 'Upscale, Premium, Perfect, Delicious, American, Cool, Casual',
        annual_dol: 2304.56,
        description:
            '"THE UPSCALE GENTLEMEN\'S CLUB When you are looking for the best in adult entertainment in NYC, World Famous Sapphire New York is your only choice. We now have 2 locations to chose from: Sapphire (Upper East Side) and the brand new Sapphire 39 (Midtown). Which ever location you choose to visit, Sapphire New York will pamper you with provocative dancers, full bottle service and an extensive list of wines and champagnes. We also offer our complimentary concierge services with VIP amenities! Sapphire New York is always featuring the sexiest Adult Stars every Thursday Night at Sapphire 39 plus Friday and Saturdays at Sapphire UES."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9673,
        total_orders: 977.15,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Salad, Mexican, Sweet, Nachos, Perfect, Casual, Greek, American, Great, Local',
        annual_dol: 1180.12,
        description: '"Sweet Annie\'s is a bar & eatery coming soon to New York City."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9666,
        total_orders: 27.22,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Chicken Tikka Masala, Beer, Wine, Chilean, Homemade, Tandoori Chicken, Greek, Impeccable, Indian',
        annual_dol: 111.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6986,
        store_longitude: -73.9926,
        total_orders: 1019.04,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Lobster, Salad, Wine, Gourmet, Beer, Ale, Warm, Seasonal, Southern, Caesar Salad',
        annual_dol: 1063.23,
        description:
            'Inventive New American cuisine featuring seasonal ingredients, including wild game, since 1973.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7394,
        store_longitude: -73.9888,
        total_orders: 2054.8,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Steak, Burger, Vegan, Salad, Beer, Wine, Organic, American, Rustic, Upscale',
        annual_dol: 2143.89,
        description:
            'Upscale American fare & classic cocktails served in sophisticated environs with shabby-chic flair.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7414,
        store_longitude: -73.9874,
        total_orders: 4064.65,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Hotel, Steak, Dessert, Elegant, Vintage, Contemporary, French, American, Modern',
        annual_dol: 4325.57,
        description:
            'High-end American fare in a posh, clubby setting with dining rooms & a lounge with a pool table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.637,
        store_longitude: -73.6591,
        total_orders: 99.02,
        neighborhood: '',
        keywords: 'Delicious, Cajun, Warm, Friendly, French, American, Creole, Great, Casual',
        annual_dol: 445.84,
        description:
            '"Michael\'s Porthole is now Jessie\'s on the Water. New Ownership, New Management and New Chef! Come Dine with us and enjoy Live Music, Paint Nights, Comedy shows and much more."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9919,
        total_orders: 430.09,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Vegetarian, Salad, Chicken Parmigiana, Pizza, Italian, Gourmet, Coffee, Meatball, Wine',
        annual_dol: 501.37,
        description:
            'Family-owned go-to serving traditional Italian recipes in cozy rooms adorned with tin ceilings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7428,
        store_longitude: -73.9888,
        total_orders: 3044.02,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Italian, Rooftop, Tasty, Delicious, Rustic, Vibrant, American, Fun, Iconic, Seasonal',
        annual_dol: 3312.67,
        description:
            'Scenic rooftop setting for elevated Italian cuisine, house brewed ales & high-end imported beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7034,
        store_longitude: -73.9917,
        total_orders: 5598.24,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Burger, Thai, Innovative, American, Great, Aesthetic, Incredible, Local, Casual, Cultural',
        annual_dol: 6228.61,
        description:
            'Bi-level waterfront hangout showcasing a curated lineup of local food & drink plus cultural events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6851,
        store_longitude: -73.9913,
        total_orders: 436.67,
        neighborhood: 'Boerum Hill/Carroll Gardens/Brooklyn Heights',
        keywords: 'Pizza, Chinese, German, Upscale, Asian, Impeccable, Thai, Warm, Casual, Fresh',
        annual_dol: 455.6,
        description: '"We are Bringing the best food and drinks to Brooklyn!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -73.9902,
        total_orders: 1382.65,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Beer, Polish, Fantastic, Great, Incredible, Casual',
        annual_dol: 1411.99,
        description:
            'An underground pool hall with 19 billiards tables, a full bar, leagues & dozens of TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.599,
        store_longitude: -73.9893,
        total_orders: 293.63,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Lobster Tail, Tuna Steak, Shrimp Scampi, Tasty, Wine, Delicious, Spanish, Casual',
        annual_dol: 299.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7448,
        store_longitude: -73.9879,
        total_orders: 108.56,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Sushi, Lobster, Salad, Vegan, Japanese, Spicy, Healthy, Fun, Bright, Fresh',
        annual_dol: 367.65,
        description:
            'Little Japanese counter serve preparing sushi & rice bowls amid sea-blue lighting & ocean decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.8827,
        total_orders: 40.76,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Burger, Steak, Hotdog, Hamburger, Vegetarian, Mexican, Hawaiian, Wine, Spanish, American',
        annual_dol: 79.91,
        description:
            'Latin American lounge & restaurant offering live entertainment & TV sports in relaxed surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.67,
        store_longitude: -73.9585,
        total_orders: 132.94,
        neighborhood: 'Crown Heights',
        keywords: 'Scotch, Mexican, Gourmet, Dessert, Gin, Delicious, Hotel, Sweet, Scottish, Cool',
        annual_dol: 500.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8081,
        store_longitude: -73.9525,
        total_orders: 4480.56,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Steak, Salad, Tasty, Butter Chicken, Dessert, Italian, Funky, French, Healthy',
        annual_dol: 10995.31,
        description:
            'This roomy, funky joint with a central bar, big booths & patio serves Caribbean food & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7656,
        store_longitude: -73.9826,
        total_orders: 219.3,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Italian, Amazing, Great, Southern, Excellent, Friendly',
        annual_dol: 233.55,
        description:
            'from Broadway theaters. Set in a renovated tenement-style building, the wood-trimmed lobby has Old World details like a marble fireplace.Simply decorated rooms have marble bathrooms, minifridges, free WiFi and LCD TVs.The downstairs restaurant and bar serves southern Italian food and pizza.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.983,
        total_orders: 70.02,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Stylish, Hotel, Sleek, Bright, Modern, Urban, Lively, Perfect, Fun, Classic',
        annual_dol: 321.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9833,
        total_orders: 1258.36,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Tequila, Salad, Wine, Beer, Delicious, Stylish, Vietnamese, Bbq, Swiss',
        annual_dol: 1800.07,
        description:
            'New American bites, specialty cocktails & craft beers served in stylish environs with a long bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9837,
        total_orders: 2305.88,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Vodka, Dessert, Coffee, Italian, Martini, Caesar Salad, American',
        annual_dol: 2513.06,
        description:
            'Supper club with an American menu & classic cocktails featuring 3 cabaret shows per night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9905,
        total_orders: 1160.83,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Salad, French, American, Modern, Thai, Comfortable, Casual, Australian, Classic',
        annual_dol: 1424.31,
        description: '"Gala Dinners, daily"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.9773,
        total_orders: 107.05,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Lasagna, Salad, Italian, Wine, Rustic, Warm, Southern, Authentic, Cool, Local',
        annual_dol: 437.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6775,
        store_longitude: -73.8287,
        total_orders: 10320.22,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'Vodka, Burger, Hotel, Fun, Fantastic, Great',
        annual_dol: 10328.17,
        description:
            '"Located near JFK Airport, the casino has over 6,500 games, including electronic blackjack, roulette, craps, baccarat, video poker, and thousands of slots."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6391,
        store_longitude: -73.582,
        total_orders: 324.58,
        neighborhood: '',
        keywords: 'Burger, Mexican, Ale, Tasty, Hurricane, Local, Casual',
        annual_dol: 387.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7453,
        store_longitude: -73.999,
        total_orders: 944.68,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Salad, Gluten Free, Wine, Spicy, Delicious, Japanese, Thai, Sashimi, Hotel',
        annual_dol: 964.72,
        description:
            'Chelsea Japanese hangout with sushi bar, lunch specials & free salad bar for in-house diners.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -73.9959,
        total_orders: 1223.23,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Burger, Lobster, Salad, Vegetarian, Wine, French, Elegant, Mediterranean, Warm',
        annual_dol: 1276.26,
        description:
            'Mediterranean-accented French dishes served in a bright corner space with an elegant cottage feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6885,
        store_longitude: -73.9829,
        total_orders: 186.37,
        neighborhood: 'Downtown Brooklyn',
        keywords:
            'Korean, Spicy, Dessert, Delicious, Nachos, Sweet, Bbq, Friendly, Authentic, Casual',
        annual_dol: 761.81,
        description: '"Authentic Korean recipes since 2009"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6683,
        total_orders: 1320.66,
        neighborhood: '',
        keywords: 'Burger, Steak, Vegan, Pizza, Italian, Beer, Wine, Ale, American, Southern',
        annual_dol: 1350.17,
        description:
            'Roomy hangout serving American fare such as brick oven pizzas & burgers, plus tap brews & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7113,
        store_longitude: -74.0068,
        total_orders: 213.22,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegetarian, Upscale, Rich, Stylish, Trendy, Hotel, Coffee, Elegant, Organic, Romantic',
        annual_dol: 274.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.9785,
        total_orders: 798.61,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Tequila, Wine, Beer, Tasty, Hotel, French, Phenomenal, Paloma, Traditional, Signature',
        annual_dol: 875.25,
        description:
            '"The Cabinet offers a meticulously curated selection of agave distillates from all over Mexico, as well as cocktails, beer and wine, and bar snacks."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6764,
        store_longitude: -73.9561,
        total_orders: 151.68,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Beer, Friendly, Fun, Natural, Great, Casual',
        annual_dol: 622.89,
        description:
            '"Neighborhood bar serving a budget-friendly selection of craft beer, natural wines and cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.9776,
        total_orders: 528.26,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Wine, Beer, Salad, Dessert, Mediterranean, Comfortable, Friendly, Perfect, American',
        annual_dol: 834.04,
        description:
            'Low-key bar specializing in craft beers plus Mediterranean bites in a dimly lit corner setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 5363.94,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Burger, Cheesecake, Salad, Shrimp Cocktail, Wine, Dessert, Upscale, Savory, Elegant',
        annual_dol: 5752.78,
        description:
            'Upscale steakhouse in Time Warner Center offering the genre standards with Central Park views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -73.991,
        total_orders: 486.86,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Steak, Tacos, Margarita, Nachos, Breakfast Burrito, Spicy, Martini, Quesadillas, French, Funky',
        annual_dol: 1990.13,
        description:
            'Funky taverna lures locals with drinks & simple American-Mediterranean dishes for brunch & dinner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7278,
        store_longitude: -74.0004,
        total_orders: 41.37,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Delicious, Elegant, French, Romantic, Exquisite, Intimate, Perfect, Casual, Cool',
        annual_dol: 169.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6355,
        store_longitude: -73.5821,
        total_orders: 462.58,
        neighborhood: '',
        keywords: 'Steak, Wine, Warm, Inviting, American, Casual, Fresh, Great, Fantastic, Local',
        annual_dol: 647.44,
        description:
            'Steaks, seafood & cocktails served in warm environs with dark-wood wainscoting & a water-view deck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6763,
        store_longitude: -73.8216,
        total_orders: 38.71,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Chinese, Caribbean, Fried Rice, Bbq, Indian, Classic, Outstanding, Cultural, Great, Casual',
        annual_dol: 158.25,
        description:
            '"Guyanese, West Indian , Chinese comfort dishes, Discover Caribbean Fest, a Caribbean restaurant serving the best in Caribbean cuisine. Visit our restaurant today!, order online, and get your food, fast. or dining in 116-14 Rockaway Blvd, South Ozone Park New York 11420."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7387,
        store_longitude: -73.9944,
        total_orders: 2717.44,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Whiskey, Bourbon, Gin, Stylish, Cozy, Delicious, Intimate, Seasonal, American, Casual',
        annual_dol: 2835.26,
        description:
            'A semi-secret entrance (ring bell) leads to this plush lounge crafting Prohibition-era cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.553,
        store_longitude: -74.1919,
        total_orders: 303.55,
        neighborhood: 'Rossville',
        keywords: 'Sushi, Lobster, Salad, Mexican, Japanese, Spicy, Greek, Asian, Miso Soup, Local',
        annual_dol: 372.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -73.8093,
        total_orders: 8.52,
        neighborhood: 'Fresh Meadows/Flushing/Kew Gardens Hills',
        keywords: 'Beer, Ale, Wine, American, Relaxed, Fresh, Casual, Perfect, Great',
        annual_dol: 133.25,
        description:
            'Old-school sports bar with a relaxed vibe offering darts, a pool table & jukebox music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5295,
        store_longitude: -74.1617,
        total_orders: 98.06,
        neighborhood: 'Annadale/Southeast Annadale',
        keywords: 'Italian, Casual, Great',
        annual_dol: 400.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6873,
        store_longitude: -73.9749,
        total_orders: 19.85,
        neighborhood: 'Fort Greene',
        keywords:
            'Burger, Hamburger, Delicious, Chinese, American, Cozy, Mediterranean, Polished, Friendly, Awesome',
        annual_dol: 81.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6237,
        store_longitude: -74.1488,
        total_orders: 448.07,
        neighborhood: 'Mid Island/Graniteville',
        keywords:
            'Salad, Ramen, Japanese, Delicious, Homemade, Warm, Asian, Healthy, Fusion, Sweet',
        annual_dol: 738.19,
        description:
            '"Authentic japanese ramen made with bone broth that has been cooked for at least 8 hours. Also serving yakitori, bubble teas, and assorted sake and beers."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -73.9551,
        total_orders: 1707.83,
        neighborhood: 'Williamsburg',
        keywords:
            'Scotch, Whiskey, Beer, Irish, Cozy, Scottish, Moscow Mule, Friendly, Awesome, Cool',
        annual_dol: 3351.75,
        description:
            'Cozy Irish/Scottish bar offering a broad choice of whiskeys, plus imported beer, bar bites & pool.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -73.9567,
        total_orders: 1593.85,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Tacos, Beer, Whiskey, Relaxed, Cozy, American, Casual, Vintage, Delicious',
        annual_dol: 1687.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -73.9609,
        total_orders: 4318.88,
        neighborhood: 'Williamsburg',
        keywords: 'Lobster, Pizza, Spicy, Delicious, Mexican, Italian, Warm, Upscale, Creamy, Cozy',
        annual_dol: 4506.72,
        description:
            '"A French style cafe with Latin roots, Cafe Rosarito is a local spot serving simple amazing dishes which use only the highest quality ingredients"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7467,
        store_longitude: -73.9828,
        total_orders: 125.95,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Sushi, Salad, Steak, Vegetarian, Beer, Japanese, Spicy, Sashimi, Delicious, Seasonal',
        annual_dol: 213.06,
        description:
            'Relaxed neighborhood eatery serving Japanese favorites like sushi rolls, sashimi & tempura.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9782,
        total_orders: 254.93,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Pizza, Vodka, Gourmet, Meatball, Beer, Chinese, Wine, Tasty, Spicy, Polish',
        annual_dol: 368.16,
        description:
            '"If you\'re looking for authentic Szechuan food in town, look no further than Mazu Szechuan Cuisine. A Chinese restaurant in New York, NY, we specialize in Chinese food and homemade dim sum. Whether you want to dine in, grab take out, or deliver, our menu offers something for everyone to enjoy."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.8783,
        total_orders: 19.85,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords:
            'Mexican, Tacos, Beer, Hamburger, Enchiladas, Colombian, Hotel, Urban, Fusion, Vibrant',
        annual_dol: 109.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.9761,
        total_orders: 21403.68,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Lobster, Vegetarian, Vegan, Pizza, Salad, Gluten Free, Japanese, Eclectic, Legendary',
        annual_dol: 22980.99,
        description:
            'Sleek Japanese-Peruvian spot from Nobu Matsuhisa frequented by business types & celebs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7638,
        store_longitude: -73.9887,
        total_orders: 34.83,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Lobster Roll, Cajun, Delicious, Cajun-Style, Wine, Margarita, Thai, Fun, Bbq',
        annual_dol: 81.2,
        description:
            'Seafood spot serving boils, lobster rolls & fried fish platters plus nautically themed cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9922,
        total_orders: 6718.52,
        neighborhood: 'Bowery',
        keywords: 'Wine, Vodka, Trendy, Glamorous, Gin, Rum, Premium, Classy, Cozy, Asian',
        annual_dol: 7486.21,
        description:
            'Unique & exclusive club features nightly shows that combine music, theater, burlesque & erotica.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7673,
        store_longitude: -73.9803,
        total_orders: 114.78,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Wine, Rich, Intimate, Timeless, Casual, Signature, Unique',
        annual_dol: 160.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9783,
        total_orders: 97.68,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Comfortable',
        annual_dol: 399.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7062,
        store_longitude: -73.8315,
        total_orders: 10.82,
        neighborhood: 'Kew Gardens/Jamaica',
        keywords:
            'Steak, Salad, Hotdog, Peruvian, South American, Dessert, French, Latin, Dominican, American',
        annual_dol: 44.22,
        description:
            'Rotisserie chicken, Peruvian/Latin dishes & sangria in a festive-casual space with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6812,
        store_longitude: -73.9752,
        total_orders: 705.19,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Tequila, Mexican, Beer, Cornbread, Margarita, Wine, Tex-Mex, Rum, American, Spicy',
        annual_dol: 729.38,
        description:
            '"Step into Brooklyn’s modern day cantina. Chef-driven street inspired Mexican food. 75 of Mexico’s most prized tequila’s and mezcal’s along with hand crafted cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.9996,
        total_orders: 311.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Mexican, Tequila, Beer, Margarita, Nachos, Tasty, Ale, Enchiladas, Delicious, Homemade',
        annual_dol: 325.5,
        description:
            'Mexican joint offering tacos, creative dishes & tequilas in a colorful space with street art.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6796,
        store_longitude: -73.9821,
        total_orders: 1793.66,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Coffee, Ale, Wine, American, Local, Casual, Approachable, Great',
        annual_dol: 1911.53,
        description:
            'Bar serving draft beers by its own brewery, along with other craft brews & food by pop-up eateries.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6736,
        store_longitude: -73.9571,
        total_orders: 3180.67,
        neighborhood: 'Crown Heights',
        keywords:
            'Mexican, Tequila, Tacos, Beer, Margarita, Wine, Enchiladas, Burritos, Delicious, Sweet',
        annual_dol: 3919.78,
        description:
            'Mexican fare along with a selection of tequilas, margaritas & other specialty cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7162,
        store_longitude: -73.9518,
        total_orders: 6818.22,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Quaint, Hotel, Friendly, Intimate, Innovative, Cool, Great, Casual',
        annual_dol: 6962.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.961,
        total_orders: 1231.52,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Beer, Wine, Irish, Cozy, Awesome, Friendly, Amazing, Classic, Great, Traditional',
        annual_dol: 1302.92,
        description:
            'A rare Scottish bar in Williamsburg, with hot toddies, televised soccer games & a back garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6755,
        store_longitude: -73.9638,
        total_orders: 2479.92,
        neighborhood: 'Prospect Heights',
        keywords:
            'Pizza, Beer, Wine, Cozy, Spacious, Homemade, Perfect, Wonderful, Creative, Great',
        annual_dol: 2653.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6763,
        store_longitude: -73.9631,
        total_orders: 1062.21,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Beer, Martini, Modern, Spacious, Cozy, Warm, Classy, Natural, Relaxed',
        annual_dol: 1792.78,
        description:
            'Relaxed gathering place spotlighting cool cocktails, draft beer & wine, plus snacks & light bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.73,
        store_longitude: -73.9832,
        total_orders: 989.31,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Garlic Bread, Pizza, Salad, Wine, Italian, Delicious, Upscale, Warm, Funky, Rustic',
        annual_dol: 1010.3,
        description:
            'Upscale Tuscan-American restaurant in a warm setting with an open kitchen & a large wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.9838,
        total_orders: 916.3,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegetarian, Delicious, Perfect, Vintage, Moroccan, Awesome, Cool, Aesthetic, Great, Iconic',
        annual_dol: 1409.57,
        description:
            'A hookah bar with a full menu of Middle Eastern favorites in a vibrantly decorated space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7156,
        store_longitude: -73.9444,
        total_orders: 150.07,
        neighborhood: 'Williamsburg',
        keywords:
            'Mexican, Beer, Margarita, Wine, Trendy, Rustic, Delicious, Classy, Sweet, Authentic',
        annual_dol: 156.58,
        description:
            'Traditional Mexican dishes served on rustic communal tables, plus a huge selection of tequila.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7533,
        store_longitude: -73.9808,
        total_orders: 151.58,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Tacos, Mexican, Salad, Margarita, Burritos, Coffee, Baked Potato, American, French, Spicy',
        annual_dol: 619.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7416,
        store_longitude: -73.9809,
        total_orders: 907.78,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Sushi, Japanese, Chinese, Fried Rice, Asian, Hotel, Fusion, Filet Mignon, Perfect',
        annual_dol: 1050.76,
        description:
            'Upbeat, bi-level Japanese restaurant & bar featuring hibachi tables with performing chefs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8539,
        store_longitude: -73.889,
        total_orders: 36.42,
        neighborhood: 'Little Italy/Belmont',
        keywords: 'Wine, Beer, Warm, Italian, Fun, Relaxed, Colorful, Authentic, Comforting, Cool',
        annual_dol: 105.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -73.9532,
        total_orders: 2774.39,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Salad, Sweet, Friendly, Korean, French, Bbq, Creole, Southern, Cool, Casual',
        annual_dol: 2894.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6658,
        store_longitude: -73.9202,
        total_orders: 83.17,
        neighborhood: 'Brownsville',
        keywords:
            'Pizza, Vegan, Italian, Chinese, Bbq, Margarita, Pinacolada, Fresh, Fun, Friendly',
        annual_dol: 339.96,
        description:
            '"Villains Hideout is the only pop culture, comic book pizza bar dedicated to fandom from the Villains perspective! Located in the Brownsville section of Brooklyn, Villains Hideout offers a geek bar space for all fans."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9489,
        total_orders: 301.51,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Salad, Shrimp Cocktail, Wine, Italian, Coffee, Classy, Friendly, Sweet, Casual, Southern',
        annual_dol: 311.06,
        description: 'Straightforward Italian cooking from this family-run eatery open since 1977.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5538,
        store_longitude: -74.2177,
        total_orders: 181.8,
        neighborhood: 'Rossville/Charleston',
        keywords: 'Salad, Shrimp Scampi, Italian, Mediterranean, Bruschetta, Cozy, Casual',
        annual_dol: 202.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7278,
        store_longitude: -73.991,
        total_orders: 868.78,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Beer, Rustic, Ale, Cozy, Irish, Friendly, Casual, Classy, Laid-Back',
        annual_dol: 887.21,
        description:
            'Rustic neighborhood watering hole with a laid-back Irish vibe & frequent drink specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7324,
        store_longitude: -73.9875,
        total_orders: 5199.6,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Thai, Salad, Dessert, Trendy, Delicious, Green Curry, Beer, Classy, Fun, Intimate',
        annual_dol: 8785.74,
        description:
            'Intimate space with al fresco dining offering Thai noodles & soups, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7936,
        store_longitude: -73.9722,
        total_orders: 402.11,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Shrimp Cocktail, Steak, Chinese, Cuban, Fried Rice, Spanish, Omelette, Latin, Casual, Cultural',
        annual_dol: 1643.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.695,
        store_longitude: -73.8451,
        total_orders: 677.92,
        neighborhood: 'Richmond Hill',
        keywords: 'Clean, Fun, Awesome, Great, Caribbean, Casual',
        annual_dol: 737.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.9479,
        total_orders: 162.79,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Lobster, Steak, Sushi, Salad, Japanese, Coffee, Chinese, Russian, Delicious',
        annual_dol: 443.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8191,
        store_longitude: -73.9375,
        total_orders: 97.15,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Wine, Hotel, Italian, Cozy, Stylish, Casual, Classic, Polish, Global',
        annual_dol: 366.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.9704,
        total_orders: 1624.12,
        neighborhood: 'Lenox Hill',
        keywords:
            'Vodka, Rich, Beer, Elegant, Italian, Delicious, Romantic, Chinese, Creamy, Wholesome',
        annual_dol: 1658.58,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9683,
        total_orders: 163.31,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Steak, Salad, Korean, American, Irish, Caesar Salad, Impeccable, Cozy, Hotel',
        annual_dol: 472.21,
        description:
            '"Neighborhood gastropub, with a global perspective on food and drink and a visual esthetic that blends contemporary sports bar with Old World tavern. The menu present pub favorites and Eastern-European accented specialties, think the likes of sausages, schnitzel and goulash. Uncommonly extensive selections of flatbreads and burgers attract many fans. And Side Door\'s weekday happy hour program is one of the city\'s most generously timed. from 11:30 a.m. to 8 p.m."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7085,
        store_longitude: -74.0146,
        total_orders: 3066.06,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Wine, Dessert, Gourmet, Hawaiian, Tasty, Japanese, Whiskey, Upscale, Delicious',
        annual_dol: 5942.73,
        description:
            'Minimalist counter-service place offering gourmet poke bowls fusing Hawaiian & Japanese flavors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7447,
        store_longitude: -73.9877,
        total_orders: 315.47,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Tasty, Pizza, Salad, Italian, Wine, Delicious, Tequila, Elegant, Mediterranean, Classy',
        annual_dol: 385.2,
        description:
            'Rustic spot offering artisanal pasta made with prime olive oil & Italian-accented cocktails & wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7471,
        store_longitude: -73.9858,
        total_orders: 459.59,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Pizza, Salad, Beer, Wine, Upscale, Korean, Ale, Delicious, Karaoke, Bbq',
        annual_dol: 469.34,
        description:
            'Hopping sports-centric hangout with Korean fusion bar bites, multiple TVs, beer pong & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7536,
        store_longitude: -73.9945,
        total_orders: 437.15,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Wine, Trendy, Hotel, Spanish, Spacious, Vibrant, Bright, Sleek, Fun, Lively',
        annual_dol: 456.1,
        description:
            "Bright modern rooms sleep up to 8 guests, and have reclaimed wood floors, free Wi-Fi, flat-screen TVs and iPod docks. Some rooms have game consoles, bunk beds, or fitness rooms with workout equipment.An airy lobby bistro serves breakfast, tapas and cocktails. There's a 24-hour fitness center, along with 5 meeting rooms, including a seasonal rooftop terrace.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.987,
        total_orders: 1202.15,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Mexican, Korean, Karaoke, Beer, American, Eclectic, Asian, Fusion, Modern, Irish',
        annual_dol: 1254.27,
        description:
            'Buzzing, late-night lounge serving Korean fusion dishes, beer & cocktails in a club-like atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7419,
        store_longitude: -73.987,
        total_orders: 2201.25,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Vegan, Vegetarian, Wine, Gluten Free, American, Upscale, Contemporary, Perfect, Friendly, Local',
        annual_dol: 2637.22,
        description:
            'Upscale American tasting menus from chef Daniel Humm served in a high-ceilinged art deco space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6895,
        store_longitude: -73.9724,
        total_orders: 2258.28,
        neighborhood: 'Fort Greene',
        keywords:
            'Delicious, Warm, Cozy, Impeccable, Mediterranean, Amazing, Outstanding, Contemporary, Great, Israeli',
        annual_dol: 2632.31,
        description:
            'Chef-owned spot for contemporary Israeli & Mediterranean food & cocktails, with backyard seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 2578.25,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Spacious, Iconic, Classic, Great, Excellent, American',
        annual_dol: 2632.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6888,
        store_longitude: -73.993,
        total_orders: 1023.67,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Salad, Vegetarian, Thai, Dessert, Wine, Margarita, Tasty, Crab Rangoon, Beer, Organic',
        annual_dol: 1253.95,
        description:
            'Narrow, easygoing neighborhood standby dishing up Thai favorites from noodles to spring rolls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9946,
        total_orders: 381.61,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Peruvian, Salad, Wine, Juicy, Delicious, Latin, Homemade, Premium, Sangria, American',
        annual_dol: 389.7,
        description:
            'Casual Peruvian restaurant specializing in rotisserie chicken with homemade sauce.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7748,
        store_longitude: -73.9138,
        total_orders: 13519.15,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Burger, Vegan, Beer, Coffee, Wine, Upscale, Delicious, Warm, American, Cozy',
        annual_dol: 14388.88,
        description: 'Cozy brick-and-wood gastropub with cocktails & upscale pub fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7622,
        store_longitude: -73.9599,
        total_orders: 477.91,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Salad, Coffee, Beer, Wine, Irish, French, American, Bloody Mary',
        annual_dol: 488.05,
        description:
            '"Irregulars is a pub serving up the classics on the historic corner of 63rd and 1st."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7675,
        store_longitude: -73.9705,
        total_orders: 727.57,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Hotdog, Coffee, Italian, Dessert, American, Modern, Phenomenal, Authentic',
        annual_dol: 985.63,
        description:
            'Central Park eatery open during zoo hours with indoor & outdoor seating, plus light cafe fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6979,
        store_longitude: -73.927,
        total_orders: 171.77,
        neighborhood: 'Bushwick',
        keywords: 'Perfect, Jamaican, Great, Casual, Local',
        annual_dol: 702.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.9582,
        total_orders: 4288.76,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Coffee, Donut, Japanese, Ramen, Delicious, American, Sashimi, Contemporary, Perfect, Natural',
        annual_dol: 4884.6,
        description:
            'Casual, contemporary Izakaya-style Japanese restaurant featuring noodles, sake & craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8378,
        store_longitude: -73.7827,
        total_orders: 32442.83,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Wine, Beer, Hawaiian, Dessert, Ale, Pinacolada, American, Daiquiri',
        annual_dol: 37116.51,
        description:
            'Basic, long-running venue with outdoor seating serving fried & steamed seafood offerings. Cash only.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6791,
        store_longitude: -74.011,
        total_orders: 1670.59,
        neighborhood: 'Red Hook',
        keywords:
            'Tacos, Mexican, Salad, Vegetarian, Margarita, Gluten Free, Wine, Delicious, Spicy, Spanish',
        annual_dol: 1790.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.522,
        store_longitude: -74.2352,
        total_orders: 202.32,
        neighborhood: 'Charleston',
        keywords:
            'Mexican, Steak, Salad, Nachos, Gourmet, Chinese, Spanish, Delicious, American, Spacious',
        annual_dol: 300.06,
        description:
            'Snazzy cantina & lively nightspot serving Mexican fare & cocktails with dancing & a DJ Thurs-Sat.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -73.9906,
        total_orders: 3712.05,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Thai, Salad, Flavorful, Wine, Stylish, Coffee, Spicy, Chinese, Ale, Beer',
        annual_dol: 3790.82,
        description:
            'Traditional & unique Thai fare served in a stylish space with dark-wood accents & ornate fixtures.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6189,
        store_longitude: -74.0694,
        total_orders: 216.42,
        neighborhood: 'Rosebank',
        keywords:
            'Steak, Tacos, Tequila, Mexican, Gourmet, Wine, Burritos, Coffee, Turkish, Tex-Mex',
        annual_dol: 267.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8817,
        store_longitude: -73.8828,
        total_orders: 9.92,
        neighborhood: 'Kingsbridge Heights/Norwood',
        keywords:
            'Vegan, Vegetarian, Tacos, Mexican, Coffee, Tasty, French Toast, French, Casual, Latin',
        annual_dol: 40.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -73.8901,
        total_orders: 35.9,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Pizza, Tasty, Beer, Stylish, Cozy, Vietnamese, Irish, Latin, Perfect, Great',
        annual_dol: 108.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9775,
        total_orders: 4060.84,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Coffee, Dessert, Spicy, Avocado Toast, Warm, Organic, Caesar Salad',
        annual_dol: 22570.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.7601,
        total_orders: 18.49,
        neighborhood: 'Bayside',
        keywords: 'Hotel, Coffee, Modern, Fresh',
        annual_dol: 75.58,
        description:
            "closest bus stop is a minute's walk from the hotel, and LaGuardia Airport is 8.6 miles away.Warm, modern rooms have free WiFi, plus coffeemakers, minifridges and flat-screen TVs. Studios add pull-out sofas. Room service is available (limited hours).Freebies include continental breakfast, parking, and all-day tea and coffee in the lobby. An on-site restaurant with a Spanish-influenced menu serves 3 meals a day. There's also a ballroom and 5 meeting rooms.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.9685,
        total_orders: 104.54,
        neighborhood: 'Lenox Hill',
        keywords:
            'Chocolate Cake, Tasty, Wine, Italian, Delicious, Elegant, Sustainable, Mediterranean, Healthy, Fun',
        annual_dol: 116.4,
        description:
            "Seasonal Mediterranean fare that's locally sourced served in a sleek space near Bloomie's.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6905,
        store_longitude: -73.9833,
        total_orders: 7461.89,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Beer, Burger, Milkshake, Gin, Thai, Stylish, Phenomenal, Classic, Creative, Local',
        annual_dol: 7785.42,
        description:
            'Stylish theater chain for new & classic films features cocktails & creative bites served seat-side.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.781,
        store_longitude: -73.9489,
        total_orders: 1839.8,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Wine, Beer, Ale, Irish, Inviting, Intimate, Friendly, Fun, Homey, Great',
        annual_dol: 1903.7,
        description:
            'Tiny, inviting Irish hideaway lures locals with Guinness, happy-hour deals & darts & trivia nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9869,
        total_orders: 462.91,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Burger, Salad, Coffee, Pizza, Delicious, Meatball, Quaint, Healthy, French, Mediterranean',
        annual_dol: 989.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7864,
        store_longitude: -73.7294,
        total_orders: 460.75,
        neighborhood: '',
        keywords: 'Wine, Hotel, Italian, Amazing, Great, Fancy, Beer, Comfortable, Asian, Fusion',
        annual_dol: 491.02,
        description:
            'miles from LaGuardia Airport.The plush, airy rooms and suites with 1920s accents feature room service and free WiFi, plus minibars, flat-screen TVs and coffeemakers; some also have kitchenettes, balconies and/or Jacuzzis.Amenities include an exercise room (and passes to a nearby full-service gym) and an outdoor patio and garden. An on-site French-American restaurant, bar and lounge with an extensive wine list serves 3 meals and Sunday brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7778,
        store_longitude: -73.9489,
        total_orders: 13.89,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegetarian, Vegan, Salad, Mexican, Nachos, Wine, Gluten Free, Chinese, Trendy, Casual',
        annual_dol: 56.79,
        description:
            'Warm, brick-walled local eatery offering Mexican favorites plus happy hours & margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.985,
        total_orders: 402.21,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Lobster, Burger, Hotel, Modern, Great',
        annual_dol: 1411.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.9928,
        total_orders: 857.78,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Delicious, Dessert, Upscale, Greek, Spectacular, Superb, Wonderful, Fresh, Great',
        annual_dol: 955.79,
        description:
            'Upscale Greek seafood specialist with a pre-theater set menu appealing to Carnegie Hall-goers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.708,
        store_longitude: -74.0146,
        total_orders: 1598.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Beer, Vodka, Salad, Gourmet, German, Caesar Salad, Irish, American, Hip',
        annual_dol: 1782.61,
        description:
            'Hip beer hall & courtyard garden with numerous crafts on tap, creative gastropub food & Ping-Pong.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.9871,
        total_orders: 7669.67,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Korean, Spicy, Delicious, Wine, Trendy, Phenomenal, Spanish, Innovative, Bbq, Classy',
        annual_dol: 10104.21,
        description:
            'Hip setting for chef-driven Korean fusion plates with signature cocktails & a wine corkage fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7502,
        store_longitude: -73.9863,
        total_orders: 6101.59,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Wine, Beer, Japanese, Sashimi, Zen, Delicious, Moscow Mule, Ramen, Asian',
        annual_dol: 7020.63,
        description:
            'Late-night izakaya for soba salad, sushi, yakitori & other Japanese fare in a spartan-looking space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.9854,
        total_orders: 1186.32,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Hamburger, Beer, Wine, Irish, Legendary, Bbq, Thai, Spectacular, Iconic, Traditional',
        annual_dol: 1252.13,
        description:
            'Soccer fans converge at this popular sports bar housing TVs, easy pub grub & cocktails, beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.745,
        store_longitude: -73.9886,
        total_orders: 8243.06,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Rich, Hotel, Rooftop, Spacious, Stylish, American, Classic, Great, Fresh, Comfortable',
        annual_dol: 16706.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9951,
        total_orders: 8070.36,
        neighborhood: 'Nolita',
        keywords: 'Salad, Beer, Spicy, Quaint, Creamy, Perfect, Fun, Bright, Casual, American',
        annual_dol: 16861.56,
        description:
            'Bespoke cocktails, bar snacks & booze-filled slushies keep this neighborhood lounge bustling.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6482,
        store_longitude: -73.7801,
        total_orders: 1750.98,
        neighborhood: 'Jamaica',
        keywords:
            'Burger, Steak, Hamburger, Pizza, Salad, Donut, Beer, Wine, Dessert, Buffalo Chicken Salad',
        annual_dol: 7157.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -73.9888,
        total_orders: 5492.38,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Tasty, Spicy, Trendy, Wine, Savory, Hokkien Mee, Asian, Vibrant, Colorful, Fun',
        annual_dol: 7436.38,
        description:
            'Singaporean street food takes center stage in a polished setting with colorful accents.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7353,
        store_longitude: -73.9883,
        total_orders: 14040.07,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Steak, Pizza, Champagne, Mimosa, Irish, Venezuelan, Bellini, Delicious, American, Daiquiri',
        annual_dol: 17153.71,
        description:
            'Basement comedy venue plus upstairs restaurant serving dressed-up American comfort fare & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6053,
        store_longitude: -73.9828,
        total_orders: 19.83,
        neighborhood: 'Gravesend',
        keywords: 'Beer, Local, Friendly, Casual, Adventurous',
        annual_dol: 57.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7374,
        store_longitude: -73.9877,
        total_orders: 55.57,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Steak, Vegetarian, Pizza, Mexican, Beer, Tasty, Delicious, Wine, American',
        annual_dol: 227.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7274,
        store_longitude: -73.856,
        total_orders: 43.84,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Mexican, Tequila, Steak, Enchiladas, American, Latin, Delicious, Authentic, Classic, Friendly',
        annual_dol: 179.2,
        description: '"Lime & Salt Bar Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5867,
        store_longitude: -73.9291,
        total_orders: 2922.63,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Burger, Beer, Whiskey, Salad, American, Fun, Iconic, Classic, Fantastic, Great',
        annual_dol: 2984.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8872,
        store_longitude: -73.9073,
        total_orders: 2197.95,
        neighborhood: 'Riverdale/West Bronx',
        keywords: 'Burger, Vegetarian, Tacos, Salad, Beer, Dessert, Bbq, Ale, Chicken Wings, Swiss',
        annual_dol: 2248.07,
        description:
            'Pub favorites, draft brews & cocktails served in a wood-&-brick space with sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7354,
        store_longitude: -73.9968,
        total_orders: 99.11,
        neighborhood: '',
        keywords:
            'Vegan, Coffee, Cosmopolitan, Hotel, American, Perfect, Contemporary, Cool, Iconic, Great',
        annual_dol: 405.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9982,
        total_orders: 1266,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Wine, Beer, Ale, Irish, Fun, Eclectic, Vintage, Friendly, Classic, Fantastic',
        annual_dol: 1292.86,
        description:
            'Neighborhood bar with no-nonsense attitude featuring vintage photos & records on the wall.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7494,
        store_longitude: -73.9957,
        total_orders: 1011.95,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Pizza, Beer, Ale, Irish, Upscale, Great, Casual, Attentive',
        annual_dol: 1079.22,
        description:
            'Small, low-key neighborhood joint with draft beer & bar snacks, plus darts, a pool table & jukebox.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.9934,
        total_orders: 505.43,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Intimate, Classy, Mediterranean, Fun, Classic, Casual, Great, Lit, Unique',
        annual_dol: 917.4,
        description:
            'Intimate, dimly lit haunt with velvet seats offering cocktails & tapas in a laid-back atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7067,
        store_longitude: -74.0128,
        total_orders: 257.16,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Chinese, American, Hotel, Crab Rangoon, Casual, Authentic, Lit, Bright, Exquisite, Dim Sum',
        annual_dol: 902.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9859,
        total_orders: 3365.27,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Wine, Vodka, Hotel, Legendary, Rum, Classic, Russian, Cozy, Impressive, Fantastic',
        annual_dol: 3436.68,
        description:
            'This swanky, candlelit, wood-paneled piano bar draws the well-heeled for classic cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6762,
        store_longitude: -73.9495,
        total_orders: 1517.55,
        neighborhood: 'Crown Heights',
        keywords:
            'Beer, Delicious, Stylish, Wine, Painkiller, Jamaican, Rum, Friendly, Wonderful, Great',
        annual_dol: 1745.34,
        description:
            'Snug neighborhood bar offering a range of elevated tropical cocktails & draft brews in stylish digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.9886,
        total_orders: 5683.58,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Upscale, Great, Creative, Unforgettable, Signature',
        annual_dol: 5842.9,
        description:
            "the Empire State Building.Warm rooms feature custom-designed beds, minibars and Wi-Fi (fee), plus 47-inch flat-screen TVs, iPod docks and coffeemakers; some offer skyline views. Suites add separate living areas and pull-out sofas. An upgraded suite has a dining area, a dishwasher and a Bose home-theater system. Room service is available 24/7.There's an upscale restaurant and a laid-back lounge serving light snacks, plus a 24-hour fitness room.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9851,
        total_orders: 1062.63,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Cheesecake, Salad, Bread Pudding, Shrimp Cocktail, Beer, Irish, American, Casual, Fancy',
        annual_dol: 1108.7,
        description:
            'Mellow tavern showcasing Irish & American eats plus beer & live music in a warm, casual atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9808,
        total_orders: 45.14,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Italian, Pizza, Beer, Delicious, Authentic, Traditional, Casual, Local, Creative, Fine',
        annual_dol: 184.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7581,
        store_longitude: -73.9882,
        total_orders: 34.53,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Chinese, Brazilian, Comfortable, Classic, Modern, Superb, Local, Exceptional, Casual, Great',
        annual_dol: 150.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.9916,
        total_orders: 145.26,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Wine, Italian, Trendy, Delicious, Beer, Bruschetta, Friendly, Fun, Modern',
        annual_dol: 593.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.883,
        total_orders: 1272.68,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Peruvian, Vodka, Wine, Beer, French, Latin, Spanish, Fusion, American, Sangria',
        annual_dol: 1356.31,
        description:
            'Bar serving piscos & Peruvian tapas, including ceviches, empanadas & tamales, in industrial digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7581,
        store_longitude: -73.7874,
        total_orders: 45.52,
        neighborhood: 'Auburndale/Flushing',
        keywords:
            'Vegetarian, Mexican, Salad, Coffee, Margarita, Wine, Quesadillas, Colombian, Casual, American',
        annual_dol: 51.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6781,
        store_longitude: -74.0118,
        total_orders: 1580.02,
        neighborhood: 'Red Hook',
        keywords:
            'Burger, Lobster, Salad, Pizza, Shrimp Cocktail, Beer, Wine, French Onion Soup, French, American',
        annual_dol: 1887.42,
        description:
            'Classic American plates inspired by iconic NYC haunts are served in a warm, wood-accented space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9513,
        total_orders: 865.51,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Hotdog, Peruvian, Seafood Paella, French, Latin, American, Intimate, Elegant, Traditional, Authentic',
        annual_dol: 922.39,
        description:
            'This eatery & cocktail spot with elegant decor specializes in Peruvian dishes & pisco cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5957,
        store_longitude: -73.9736,
        total_orders: 531.24,
        neighborhood: 'Gravesend',
        keywords:
            'Shrimp Cocktail, Wine, Italian, Delicious, French, Nachos, Premium, Southern, American, Casual',
        annual_dol: 620.63,
        description:
            'Cave-themed restaurant/hookah lounge for Italian-American eats & wines, plus cocktails & desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6457,
        store_longitude: -73.9729,
        total_orders: 110.56,
        neighborhood: 'Kensington',
        keywords:
            'Salad, Meatball, Moroccan, Savory, Delicious, Mediterranean, Premium, Friendly, Casual, Great',
        annual_dol: 388.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.8805,
        total_orders: 13.98,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords: 'Pizza, Bbq, Bruschetta, Intimate, Fresh, Great, Local',
        annual_dol: 57.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7541,
        store_longitude: -73.9773,
        total_orders: 2093.6,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Wine, Gluten Free, Organic, Mediterranean, Greek, Premium, Exquisite, Lively, Memorable',
        annual_dol: 2138.02,
        description:
            'Greek seafood & a deep wine list headline at this airy spot with sandstone pillars.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 1889.26,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Pizza, Vodka, Italian, Beer, Salad, Meatball, Gin, Rustic, Rum, American',
        annual_dol: 2015.67,
        description:
            'Artisanal pizzas & Italian fare, offered in chic digs for dine-in with cocktails or quick take-out.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6951,
        store_longitude: -73.8418,
        total_orders: 120.85,
        neighborhood: 'Richmond Hill',
        keywords: 'Burger, Fun, Premium, Cool, American, Amazing, Great, Local, Casual, Gyro',
        annual_dol: 126.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7676,
        store_longitude: -73.78,
        total_orders: 11.39,
        neighborhood: 'Bayside/Flushing',
        keywords: 'Tequila Sunrise, Tequila, Greek, Irish, Casual',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9653,
        total_orders: 2261.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Beer, Whiskey, Irish, Delicious, Authentic, Creative, Great, Casual',
        annual_dol: 2366.34,
        description:
            'Retro-hip tavern serving Irish beer, whiskey & cocktails along with hearty pub grub & happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9751,
        total_orders: 3171.15,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hotel, Upscale, Stylish, Elegant, Martini, Spacious, Warm, Premium, Sleek, Mediterranean',
        annual_dol: 3268.48,
        description:
            "Elegant rooms come with marble bathrooms, flat-screens and Wi-Fi (fee). Suites add audio systems; some also have pull-out sofas, living rooms and/or soaking tubs. Upgraded suites have terraces with city views. In-room fitness kits and massages are available, as is 24-hour room service.Amenities include a casual grill restaurant and a bar with city views. There's also a 24-hour gym, a business center and event space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.995,
        total_orders: 815.28,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Hamburger, Salad, Shrimp Cocktail, Chilean, Italian, Shrimp Scampi, Bbq, Caesar Salad, American',
        annual_dol: 854.41,
        description:
            'Unpretentious neighborhood steak & seafood restaurant with a vibe that blends modern & traditional.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -73.9836,
        total_orders: 2072.04,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Steak, Cheesesteak, Beer, Bbq, Spicy, Nachos, Irish, Ale, Philly Cheesesteak, Gumbo',
        annual_dol: 2194.38,
        description:
            'Old-school (since 1886) Irish pub with a fireplace featuring Guinness & other drafts, plus pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6896,
        store_longitude: -73.9553,
        total_orders: 267.58,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Burger, Rich, Wine, Fancy, Delicious, Classy, French, American, Korean, Retro',
        annual_dol: 546.14,
        description:
            'Retro hangout serving American comfort eats & signature cocktails in a cheery space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9948,
        total_orders: 915.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Lobster, Beer, Gluten Free, Dessert, Mediterranean, Moroccan, Spicy, Warm, Organic',
        annual_dol: 955.59,
        description:
            'Mediterranean small plates & Moroccan-inspired entrees in a warm space with a bar & sidewalk tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7709,
        store_longitude: -73.9813,
        total_orders: 5027.27,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Cheesecake, Hamburger, Tomato Soup, Salad, Chicken Pot Pie, Shrimp Cocktail, Beer, Whiskey, Wine',
        annual_dol: 5173.39,
        description:
            'Burgers & other American comfort food in down-to-earth digs with a lively atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7742,
        store_longitude: -73.9129,
        total_orders: 996.28,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Burger, Hamburger, Vegetarian, Beer, Spicy, Organic, Delicious, Sustainable, American, Clean',
        annual_dol: 1318.95,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.61,
        store_longitude: -73.9218,
        total_orders: 641.32,
        neighborhood: 'Mill Basin',
        keywords: 'Mexican, Tacos, Burritos, Coffee, Local, Casual, Healthy, Premium, Cool',
        annual_dol: 2251.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6568,
        store_longitude: -73.7003,
        total_orders: 91.05,
        neighborhood: '',
        keywords:
            'Steak, Sushi, Japanese, Sashimi, Legendary, Delicious, Perfect, Casual, Omakase, Fun',
        annual_dol: 372.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7348,
        store_longitude: -74.0029,
        total_orders: 736.27,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Garlic Bread, Wine, Spanish, Margarita, Champagne, Dessert, Martini, Seafood Paella, Gourmet',
        annual_dol: 753.32,
        description:
            'Seafood paella plus sangria & garlic bread are delivered by courtly waiters at this Village standby.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7342,
        store_longitude: -74.0066,
        total_orders: 1547.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Salad, Beer, Ale, Bourbon, Gin, Mimosa, Irish, French, Prosecco',
        annual_dol: 1580.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7374,
        store_longitude: -74.0041,
        total_orders: 1108.87,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Shrimp Cocktail, Gluten Free, Wine, Upscale, French, French Onion Soup, Savory, Avocado Toast, Cozy',
        annual_dol: 1132.4,
        description:
            'Bright neighborhood bistro whose Franco-American offerings include weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8588,
        store_longitude: -73.6466,
        total_orders: 12.15,
        neighborhood: '',
        keywords: 'Elegant, Luxurious, Sweet, Fresh, Casual',
        annual_dol: 49.68,
        description:
            '"Eastern Star Cruises is now conveniently located in Glen Cove, NY. If you are planning a corporate event, wedding, rehearsal dinner, surprise party, sweet sixteen, or any other special occasion, let us take your event up a notch. We can help make your party unique. Call us to find out how!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6103,
        store_longitude: -73.9627,
        total_orders: 50.65,
        neighborhood: 'Midwood',
        keywords: 'Steak, Salad, Wine, Tasty, Flavorful, Peruvian, Dessert, Beer, Bbq, French',
        annual_dol: 207.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7844,
        store_longitude: -73.9739,
        total_orders: 691.66,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegan, Gluten Free, Wine, Organic, Trendy, Sustainable, Italian, Vietnamese, Rustic, Cozy',
        annual_dol: 706.34,
        description:
            'Health-minded meals featuring veggies, fish & fowl to suit everyone from vegans to carnivores.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6397,
        store_longitude: -73.6073,
        total_orders: 24.3,
        neighborhood: '',
        keywords:
            'Jamaican, Caribbean, French, Dutch, Fancy, Sweet, Local, Authentic, Western, Casual',
        annual_dol: 99.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6901,
        store_longitude: -73.8144,
        total_orders: 1.8,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords: 'Delicious, Relaxed, Casual, Indian, Caribbean, Spectacular',
        annual_dol: 7.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7087,
        store_longitude: -73.8631,
        total_orders: 258.28,
        neighborhood: 'Flushing/Glendale',
        keywords: 'Burger, Pizza, Coffee, Wine, Italian, Dessert, Margarita, Cozy, Modern, Premium',
        annual_dol: 879.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7149,
        store_longitude: -74.0161,
        total_orders: 5828.7,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Tacos, Mexican, Tequila, Margarita, Nachos, Quesadillas, Enchiladas, Korean, Bbq, American',
        annual_dol: 6285.9,
        description:
            'Modern takes on Mexican favorites served in a flashy space with vibrant colors & a lively bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7653,
        store_longitude: -73.9719,
        total_orders: 8989.71,
        neighborhood: 'Lenox Hill',
        keywords:
            'Chocolate Cake, Salad, Hotel, Coffee, Rich, Elegant, Warm, French Onion Soup, Vintage, Turkish',
        annual_dol: 9185.42,
        description:
            "The elegant rooms feature free Wi-Fi and Turkish marble bathrooms, as well as Bose sound systems, flat-screen TVs and designer toiletries; some have views of the park. Suites include living rooms and soaking tubs; some have city views. Upgraded suites offer personal butler service.Amenities include a fine-dining American restaurant displaying contemporary artwork, and 2 lounges (1 with frequent live jazz) serving afternoon tea, light meals and evening cocktails. There's also a business center and a fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6342,
        store_longitude: -74.1357,
        total_orders: 62.19,
        neighborhood: 'Port Richmond',
        keywords: 'Italian, Pizza, Excellent, Casual',
        annual_dol: 122.25,
        description: '"Italian Restaurant and Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7002,
        store_longitude: -73.9294,
        total_orders: 911.74,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Delicious, Funky, Awesome, Lively, Great, Classic, Dope, Fine',
        annual_dol: 1035.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.9454,
        total_orders: 391.54,
        neighborhood: 'Williamsburg',
        keywords: 'Tasty, Pizza, Delicious, Wine, Beer, Yummy, Clean, Friendly, Awesome, Relaxed',
        annual_dol: 1600.5,
        description:
            '"A year-round tropical escape in Brooklyn! Enjoy tasty eats & delicious drinks, inspired by exotic flavors that trace the equator! Open lunchtime til late-night, every night!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6578,
        store_longitude: -73.9794,
        total_orders: 108.81,
        neighborhood: 'Windsor Terrace',
        keywords:
            'Vodka, Steak, Beer, Tasty, Delicious, Nachos, American, Baked Potato, Friendly, Outstanding',
        annual_dol: 183.65,
        description:
            '"Rhythm & Booze Sports Bar and Grill is a casual dining destination conveniently located in the heart of Windsor Terrace Brooklyn. We offer delicious lunch, dinner and weekend brunch menus which includes daily specials. We are a sports bar restaurant that strives to make your every visit better than the last. Our pledge is to provide outstanding food along with excellent service at an unbeatable value. Rhythm & Booze features include a fully stocked bar, internet jukebox, free Wi-Fi and 14 HD TV\'s with all the hottest sports packages that will definitely satisfy your sports cravings. If your in the neighborhood stop in for a bite to eat or a cold beverage. No reservation needed, walk-ins are always welcome. We hope to see you soon!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.675,
        store_longitude: -73.9771,
        total_orders: 250.94,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Italian, Casual, Authentic',
        annual_dol: 565.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6973,
        store_longitude: -73.9347,
        total_orders: 133.4,
        neighborhood: 'Bushwick',
        keywords:
            'Steak, Burger, Vegan, Beer, American, Jamaican, Natural, Casual, Fantastic, Great',
        annual_dol: 545.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6402,
        store_longitude: -73.6627,
        total_orders: 203.05,
        neighborhood: '',
        keywords: 'Lobster, Sushi, Pizza, Mexican, Beer, Wine, Spicy, American, Classy, Amazing',
        annual_dol: 711.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5797,
        store_longitude: -73.8373,
        total_orders: 20.75,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords:
            'Burger, Lobster, Steak, Sushi, Salad, Shrimp Scampi, Beer, Wine, Coffee, French Toast',
        annual_dol: 84.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7229,
        store_longitude: -74.0061,
        total_orders: 10593.31,
        neighborhood: 'Hudson Square',
        keywords:
            'Mexican, Tasty, Modern, Romantic, Perfect, Friendly, Cool, Casual, Exotic, Fabulous',
        annual_dol: 37355.3,
        description:
            'Tulum-inspired tropical jungle setting for craft cocktails & seasonal fare, with seating for 400+.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6597,
        store_longitude: -73.9954,
        total_orders: 375.69,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Tacos, Mexican, Beer, Fun, Authentic, Cool, Great, Casual, Signature',
        annual_dol: 1210.69,
        description:
            'Tacos, tostadas & chips, plus beer & spirits presented in an outdoor open space with picnic tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.554,
        total_orders: 95.4,
        neighborhood: '',
        keywords:
            'Salad, Italian, Hotel, Homemade, Irresistible, Iconic, Classic, Fresh, Excellent, Casual',
        annual_dol: 97.43,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7193,
        store_longitude: -74.0053,
        total_orders: 109.71,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Wine, Salad, Vodka, Beer, Dessert, Ale, Italian, Delicious, Organic',
        annual_dol: 448.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7902,
        store_longitude: -73.9429,
        total_orders: 73.72,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Mexican, Shrimp Cocktail, South American, Trendy, Spicy, American, Casual, Fresh, Authentic',
        annual_dol: 301.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7479,
        store_longitude: -73.5527,
        total_orders: 137.31,
        neighborhood: '',
        keywords:
            'Lasagna, Italian, Shrimp Cocktail, Pizza, Wine, Beer, Classic, Fresh, Amazing, Seasonal',
        annual_dol: 224.14,
        description:
            "Veteran neighborhood Italian eatery that's family-run & located in modest strip-mall digs.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.5739,
        total_orders: 1209.56,
        neighborhood: '',
        keywords: 'Salad, Italian, Warm, Southern, Casual, Vibrant, Classic, Excellent',
        annual_dol: 1470.39,
        description:
            'Easygoing destination with a warm vibe offering hearty servings of old-school Italian fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7723,
        store_longitude: -73.956,
        total_orders: 116.96,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Wine, Salad, Italian, Upscale, Thai, Delicious, Beer, Cozy, Romantic, Asian',
        annual_dol: 232.66,
        description:
            'A large selection of by-the-glass wines & Italian small plates makes this a popular spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -74.0027,
        total_orders: 1046.84,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Ale, Coffee, Beer, Wine, Burger, Salad, French Onion Soup, American, Rum, Tangy',
        annual_dol: 1069.05,
        description:
            'Popular tavern in 19th-century carriage house offers craft beers, pub grub & extended happy hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7283,
        store_longitude: -74.0052,
        total_orders: 180.5,
        neighborhood: 'Hudson Square',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Casual',
        annual_dol: 799.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9833,
        total_orders: 48.11,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Lobster, Pizza, Salad, Wine, Beer, Latin, American, Fun, Casual, Awesome',
        annual_dol: 196.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8424,
        store_longitude: -73.7077,
        total_orders: 2.56,
        neighborhood: '',
        keywords:
            'Steak, Kebab, Cheesesteak, Meatball, Turkish, Mediterranean, Gyro, Excellent, Casual, Falafel',
        annual_dol: 10.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.751,
        store_longitude: -73.978,
        total_orders: 14.06,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'French Toast, Coffee, French, New-York-Style, Rooftop, Perfect, Impressive, Incredible, Fine, Casual',
        annual_dol: 57.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6385,
        store_longitude: -74.0178,
        total_orders: 144.98,
        neighborhood: 'Sunset Park',
        keywords: 'Beer, Wine, Fun, Adventurous, Irish, Friendly, German, Awesome, Great, Casual',
        annual_dol: 169.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7079,
        store_longitude: -73.6755,
        total_orders: 69.46,
        neighborhood: '',
        keywords: 'Burger, Tacos, Sushi, Pizza, Delicious, Caribbean, Creole, Perfect, Authentic',
        annual_dol: 72.47,
        description:
            '"Catering available 7 days. Authentic Haitian Cuisine. Full Bar. Dine in, take out, delivery apps."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7692,
        store_longitude: -73.9649,
        total_orders: 3427.31,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Elegant',
        annual_dol: 3562.5,
        description:
            'The Union Club of the City of New York is a private social club in New York City that was founded in 1836. The clubhouse is located at 101 East 69th Street on the corner of Park Avenue, in a landmark building designed by Delano & Aldrich that opened on August 28, 1933.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6851,
        store_longitude: -73.9297,
        total_orders: 3098.11,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Friendly, Casual, Cool, Natural, Fun, Hip, American, Great, Chill',
        annual_dol: 3163.85,
        description: '"Dive Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7669,
        store_longitude: -73.9124,
        total_orders: 33.15,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Irish, Tasty, Wine, Karaoke, Vibrant, Fabulous, Wonderful, Authentic, Friendly, Great',
        annual_dol: 135.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.988,
        total_orders: 141.49,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lobster, Pizza, Beer, Italian, Mexican, Wine, Whiskey, Scotch, Southern, Asian',
        annual_dol: 578.34,
        description:
            'Tiny option serving up Italian wines paired with small bites in a brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.9742,
        total_orders: 389.4,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Steak, Burger, Pizza, Italian, Scotch, Hotel, Upscale, American, Champagne, Rum',
        annual_dol: 1591.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7194,
        store_longitude: -73.9851,
        total_orders: 1294.92,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Tasty, Spicy, Warm, Cozy, Intimate, Wonderful, Great, Excellent, Lit',
        annual_dol: 1322.39,
        description:
            'A hidden door downstairs opens to this 1920s-style speakeasy with creative cocktails in luxe decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.721,
        store_longitude: -73.9877,
        total_orders: 2354.61,
        neighborhood: 'Lower East Side',
        keywords: 'Burger, Wine, Beer, French, Homemade, Classy, Nachos, Clean, Casual, Sweet',
        annual_dol: 2422.65,
        description:
            'Hipsters pack this 2-level bar/music venue to hear live indie acts or dance to DJ sets upstairs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6922,
        store_longitude: -73.8629,
        total_orders: 306.95,
        neighborhood: 'Woodhaven',
        keywords: 'Irish, Comfortable, Casual, Local',
        annual_dol: 328.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8653,
        store_longitude: -73.9269,
        total_orders: 15.61,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Tequila Sunrise, Tequila, Shrimp Cocktail, Steak, Dominican, Caribbean, American, Latin, Premium, Traditional',
        annual_dol: 63.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7112,
        store_longitude: -74.0146,
        total_orders: 66.78,
        neighborhood: 'Lower Manhattan',
        keywords: 'Steak, Burger, Canadian, Wine, Salad, Gourmet, Pizza, Dessert, Italian, Rich',
        annual_dol: 272.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7772,
        store_longitude: -73.7936,
        total_orders: 345.67,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Pizza, Salad, Italian, Chicken Parmigiana, Meatball, Bruschetta, Relaxed, Casual, Friendly, Local',
        annual_dol: 358.24,
        description:
            'Longtime Italian restaurant offering classic pizzas & pastas, plus a full bar, in a relaxed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9944,
        total_orders: 709.44,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Sushi, Japanese, Asian, Casual, Omakase',
        annual_dol: 2899.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7066,
        store_longitude: -74.0028,
        total_orders: 5223.57,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Pizza, Salad, Mexican, Margarita, Italian, Chinese, French, American, Seasonal, Casual',
        annual_dol: 21420.99,
        description:
            'Acclaimed food hall with restaurants including Chinese, seafood, French & vegan, plus a market.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7128,
        store_longitude: -73.9443,
        total_orders: 2170.77,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Steak, Vegan, Tequila, Margarita, Green Curry, Thai, Beer, Spicy, Trendy',
        annual_dol: 2216.83,
        description:
            'Cozy Thai standby with a big menu of traditional & new dishes, plus a full bar & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7174,
        store_longitude: -73.958,
        total_orders: 316.78,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Coffee, Wine, Hip, Casual, Local, Signature, Fine',
        annual_dol: 420.4,
        description:
            'Bustling Mexican restaurant well-located on Bedford with margs & eats in a hip space with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6374,
        store_longitude: -74.0759,
        total_orders: 583.32,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords:
            'Steak, Burger, Salad, Cheesesteak, Bbq, Beer, Tex-Mex, French, American, Southern',
        annual_dol: 3019.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6032,
        store_longitude: -74.1626,
        total_orders: 150.79,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Burger, Japanese, Italian, Gluten Free, Chinese, Ramen, Thai, Asian, British, Classic',
        annual_dol: 157.33,
        description:
            'Comfortable, roomy option featuring Chinese & Japanese fare, including sushi, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.733,
        store_longitude: -73.9809,
        total_orders: 69.61,
        neighborhood: 'Midtown Manhattan/Stuyvesant Town-Peter Cooper Village',
        keywords: 'Mexican, Tacos, Burritos, Hotel, Western, Organic, Local, Casual, Delicious',
        annual_dol: 284.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.9813,
        total_orders: 1165.05,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Burger, Pizza, Vegetarian, Tomato Soup, Beer, Gluten Free, Wine, Nachos, Martini, Irish',
        annual_dol: 1177.55,
        description:
            'Andy Warhol-themed haunt serving cocktails, eats & brunch in a convivial ambiance w/outside tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9759,
        total_orders: 20.69,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Pizza, Garlic Bread, Salad, Beer, Bbq, Wine, American, Bruschetta, Fresh, Local',
        annual_dol: 84.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -74.0082,
        total_orders: 8178.26,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Rooftop, Meatball, Hotel, Spectacular, Salad, Beer, Dessert, American, Trendy, Innovative',
        annual_dol: 8919.3,
        description:
            '"Tribeca360°is a newly built 30,000sqf loft-like venue space located in the trendy downtown neighborhood of Tribeca, New York City. 360°’s modern and minimalist interior boasts over-sized windows that offer spectacular views of the city and create a priceless backdrop for your event. Our innovative use of lighting and unique walls can be easily adapted to create a personalized atmosphere."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.792,
        store_longitude: -73.9526,
        total_orders: 1788.33,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Italian, Legendary, Impeccable, Exquisite, Superb, Cultural',
        annual_dol: 2039.89,
        description:
            '"Exquisite food, impeccable service and unparalleled attention to detail create the foundation of why Sterling Affair is the Tri-States preeminent catering & event specialists."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8108,
        store_longitude: -73.6285,
        total_orders: 531.34,
        neighborhood: '',
        keywords:
            'Wine, Beer, Coffee, Delicious, Fabulous, Swiss, Homemade, Perfect, American, Classic',
        annual_dol: 592.5,
        description:
            'Landmark pub offering draft beer & American fare with TVs & classic brick- & wood-accented decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7773,
        store_longitude: -73.957,
        total_orders: 122.12,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Pizza, Salad, Italian, Meatball, Beer, Gin, Cozy, Quaint, Homemade, Caesar Salad',
        annual_dol: 132.9,
        description:
            '"At L’Osteria, we strive to constantly come up with plates that will surprise and excite your taste buds. Our dishes are inspired by the beauty of Italy, but also stand-out as something all their own. With the changing of the seasons, our menu will change to match what is at the peak for bringing you the freshest ingredients. Plus, our homemade pasta is unlike anything you’ve had before. Our commitment is to providing the very best food, in a cozy environment. This is the neighborhood spot you’ve been waiting for, to enjoy quality drinks and incredible food with the people you love. Be sure to stop in and see what we’re cooking up each night, as the daily specials are sure to offer something new every time you visit."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7844,
        store_longitude: -73.8456,
        total_orders: 24.38,
        neighborhood: 'College Point/Flushing',
        keywords:
            'Burger, Sushi, Beer, Cuban, Spanish, Dominican, Caribbean, Latin, American, Sweet',
        annual_dol: 99.66,
        description:
            '"Mr dre Kitchen Bar, We serve all kinds of alcoholic beverages, sushi and fusion food. If you are looking for an environment where you can release stress, You Just Found the Right Place."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.6644,
        total_orders: 2904.65,
        neighborhood: '',
        keywords: 'Golfclub, Elegant, Delicious, Sophisticated, Casual, Great',
        annual_dol: 3112.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -73.6352,
        total_orders: 329.12,
        neighborhood: '',
        keywords:
            'Sushi, Steak, Mexican, Beer, Japanese, Italian, Contemporary, Teriyaki, Cozy, Premium',
        annual_dol: 440.61,
        description:
            'Typical Japanese eats such as teriyaki & sushi served amid contemporary decor with splashy lighting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7763,
        store_longitude: -73.9556,
        total_orders: 1783.99,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Hamburger, Salad, Nachos, Bbq, Irish, Buffalo Chicken Salad, Mediterranean, French, Caesar Salad',
        annual_dol: 1851.34,
        description:
            'Homey, local bar with an Irish slant, sports on TV & a comfort-food menu headlined by hot wings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7747,
        store_longitude: -73.9635,
        total_orders: 639.59,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Sushi, Tacos, Tasty, Japanese, Beer, Spicy, Sashimi, Peking Duck, New Zealand',
        annual_dol: 653.16,
        description:
            'Subterranean fine-dining restaurant by acclaimed chef Masa Takayama for sushi & Japanese fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8189,
        store_longitude: -73.928,
        total_orders: 3593.47,
        neighborhood: 'West Bronx',
        keywords: 'Steak, Burger, Salad, Nachos, Beer, American, Casual, Traditional, Great',
        annual_dol: 3743.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7343,
        store_longitude: -74.0064,
        total_orders: 47.79,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Chinese, Hotel, Southern, Fun, Incredible, Classic',
        annual_dol: 195.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6334,
        store_longitude: -74.0094,
        total_orders: 365.43,
        neighborhood: 'Sunset Park',
        keywords:
            'Tequila, Whiskey, Scandinavian, Danish, Bourbon, Margarita, Bbq, American, Friendly, Excellent',
        annual_dol: 373.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7674,
        store_longitude: -73.9212,
        total_orders: 5672.2,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Irish, Whiskey, Beer, Karaoke, Lively, Diverse, Impressive, Great, Local, Casual',
        annual_dol: 6583.31,
        description:
            'Down-to-earth tavern offering pints of beer, shots, live music & an outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.7026,
        total_orders: 63.26,
        neighborhood: 'Floral Park/Bellerose Manor',
        keywords: 'Vietnamese, Salad, Upscale, Asian, Casual, Local, Friendly, Pho, Fresh, Dim Sum',
        annual_dol: 75.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7192,
        store_longitude: -74.0061,
        total_orders: 10.35,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Hotel, Iconic, Spacious, Impressive, Cultural',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7368,
        store_longitude: -74.0012,
        total_orders: 1747.8,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Cheesesteak, Beer, Whiskey, Tasty, Delicious, Philly Cheesesteak, Rum, Friendly',
        annual_dol: 6734.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6981,
        store_longitude: -73.934,
        total_orders: 806.84,
        neighborhood: 'Bushwick',
        keywords:
            'Pizza, Beer, Cozy, Friendly, American, Perfect, Lively, Great, Local, Traditional',
        annual_dol: 878.05,
        description:
            'Cozy neighborhood bar with late hours & a chill atmosphere offering drink specials & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7376,
        store_longitude: -73.9912,
        total_orders: 1209.76,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Tasty, Salad, Wine, Vegan, Thai, Beer, Dessert, Spicy, Malaysian, Classy',
        annual_dol: 1501.76,
        description:
            'Southeast Asian eatery with exposed brick serving fare from Thailand, Malaysia, Singapore & beyond.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.734,
        store_longitude: -73.9889,
        total_orders: 147.28,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Mexican, Salad, Italian, Organic, Delicious, Warm, Local, Casual, Great',
        annual_dol: 693.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6396,
        store_longitude: -73.9864,
        total_orders: 630.31,
        neighborhood: 'Borough Park',
        keywords: 'Steak, Sushi, Salad, Wine, Upscale, Stylish, Korean, Trendy, Bbq, Warm',
        annual_dol: 1945.46,
        description:
            'Upscale kosher destination presenting steaks & creative sushi in a stylish bi-level space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5539,
        store_longitude: -74.1449,
        total_orders: 663.98,
        neighborhood: 'Great Kills',
        keywords:
            'Salad, Italian, Delicious, Warm, Polished, Friendly, Casual, Great, Classic, Fine',
        annual_dol: 703.44,
        description:
            'Classic Italian meals & steaks in a spacious, old-school dining room with live entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8861,
        store_longitude: -73.9072,
        total_orders: 8501.31,
        neighborhood: 'Riverdale/West Bronx',
        keywords:
            'Mexican, Tacos, Tequila, Steak, Margarita, Gourmet, Dessert, Tex-Mex, Enchiladas, Spanish',
        annual_dol: 13039.83,
        description:
            'Trendy restaurant & bar serving gourmet Mexican dishes, craft cocktails & mezcal in a quirky space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.9728,
        total_orders: 3973.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Sushi, Champagne, Wine, Chilean, Coffee, Upscale, Greek',
        annual_dol: 4057.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7517,
        store_longitude: -73.9707,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Rich, Upscale, Hotel, Trendy, Delicious, Vibrant, Iconic, Fantastic, Sophisticated, Incredible',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5855,
        store_longitude: -73.9518,
        total_orders: 4.35,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Salad, Delicious, Juicy, Pizza, Spectacular, Friendly, Exceptional, Great, Fried Shrimp, Authentic',
        annual_dol: 17.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.816,
        store_longitude: -73.9398,
        total_orders: 112.13,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Tacos, Lobster Roll, Lobster, Mexican, Jamaican, American, Premium, French, Great',
        annual_dol: 148.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7131,
        store_longitude: -74.0077,
        total_orders: 574.27,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Beer, Tasty, Upscale, Comfortable, Indulgent, Friendly, Vibrant, Natural, Perfect',
        annual_dol: 599.8,
        description:
            '"Tribeca Social is a beautiful new specialty cocktail bar and lounge. The space is multi-dimensional, transforming daily from an upscale sports bar to a lounge with light bites and cocktails, to an intimate, curated events space. Tribeca Social offers a vibrant and elevated atmosphere, where guests can catch a game on one of the 70 inch gold televisions that surround the bar. The TVs are gold framed, and are converted to tasteful art displays during the evening hours."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9697,
        total_orders: 83.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Steak, Tasty, Cajun, Delicious, Luxurious, Asian, Comfortable, Premium, Fresh',
        annual_dol: 151.16,
        description:
            'Seafood specialty restaurant featuring all-you-can-eat meals in a laid-back atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.717,
        store_longitude: -73.808,
        total_orders: 183.01,
        neighborhood: 'Jamaica/Kew Gardens Hills/Hillcrest',
        keywords:
            'Burger, Steak, Chicken Parmigiana, Salad, Turkey Sandwich, Roast Beef, Italian, Wine, Beer, Swiss',
        annual_dol: 748.08,
        description:
            '"Supreme Diner offers perfect comfort food created with healthy ingredients. We\'re passionate about delicious food, premier customer service, and guaranteed value. Our food is upscale for diner standards and of the highest quality. Our staff is family, employing full-time Executive Chefs, experienced line/prep cooks, experienced servers, and management. Come taste the difference. Also full bar available for indoor dinning, beer, wine and cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7584,
        store_longitude: -73.983,
        total_orders: 18801.78,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Luxurious, Wine, Rooftop, Stylish, Delicious, Sushi, Phenomenal, Tasty, Warm',
        annual_dol: 19929.65,
        description:
            "The sophisticated, contemporary rooms feature flat-screen TVs, minibars and free Wi-Fi, along with iPod docks and rainfall showerheads. Suites add separate living areas. Room service is available.Complimentary continental breakfast is provided, and there's a chic restaurant, a stylish rooftop cocktail lounge, an intimate sushi restaurant and a fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6907,
        store_longitude: -73.9955,
        total_orders: 2542.98,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Beer, Cozy, Comfortable, Ale, Hotel, Friendly, Spacious, Casual, Fun, Vintage',
        annual_dol: 2542.98,
        description:
            'Drinks & beer cheese snacks in a spacious taproom with distressed vintage furniture & a bocce court.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5151,
        store_longitude: -74.2481,
        total_orders: 560,
        neighborhood: 'Tottenville',
        keywords:
            'Burger, Steak, Gourmet, Italian, Dessert, American, Eclectic, Casual, Romantic, Great',
        annual_dol: 1180.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6734,
        store_longitude: -73.9539,
        total_orders: 186.01,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Salad, French Toast, Coffee, French, Wine, African, Casual, Exotic, Comfortable',
        annual_dol: 194.08,
        description:
            'Comfortable outpost for French-Senegalese cuisine, including brunch & a weekly fixed-price option.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9807,
        total_orders: 98.06,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Steak, Brazilian, Tasty, Traditional, Polished, Caipirinha, Authentic, Local, Filet Mignon, Casual',
        annual_dol: 106.71,
        description:
            'Longtime Brazilian restaurant serving up grilled meats, stews & strong drinks in a simple setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7584,
        store_longitude: -73.9853,
        total_orders: 2792.6,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Tequila, Vodka, Burger, Margarita, Salad, Tasty, Dessert, Glamorous, Grand Marnier, American',
        annual_dol: 7282.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.9859,
        total_orders: 74.45,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Turkey Sandwich, Roast Turkey, Ale, American, Casual, Clean, Friendly, Classic, Attentive, Amazing',
        annual_dol: 304.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7098,
        store_longitude: -74.0129,
        total_orders: 410.96,
        neighborhood: 'World Trade Center/Financial District',
        keywords: 'Italian, Pizza, Meatball, Wine, Beer, Vibrant, Perfect, Great, Casual, Local',
        annual_dol: 468.06,
        description:
            'Bread-themed branch of the famed Italian market, offering counters, restaurants & cooking demos.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7099,
        store_longitude: -74.0118,
        total_orders: 26.19,
        neighborhood: 'World Trade Center/Financial District',
        keywords:
            'Steak, Beer, Italian, Gourmet, Peruvian, Korean, Fried Chicken, Cuban, Club Sandwich, Contemporary',
        annual_dol: 107.04,
        description:
            'Rustic-industrial food hall with a variety of popular counter-serve eateries, produce stalls & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.9974,
        total_orders: 558.93,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Friendly, Premium, Great, Hip, Unique',
        annual_dol: 1824.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7712,
        store_longitude: -73.9878,
        total_orders: 46.23,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Pizza, Vegetarian, Salad, Italian, Vodka, Caesar Salad, Spicy, Hotel, Casual',
        annual_dol: 188.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7284,
        store_longitude: -73.9993,
        total_orders: 1169.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Hamburger, Margarita, Wine, Canadian, Karaoke, Bbq, Rum, Mimosa, Hurricane',
        annual_dol: 1206.7,
        description:
            'Live bands, beer pong tables & drink specials amp up the party vibe at this watering hole near NYU.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.8783,
        total_orders: 375.97,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Mexican, American, Casual, Latin, Local, Authentic, Unique',
        annual_dol: 484.08,
        description:
            '"True to the Mexican flavor and honoring the original recipes is the key of our success. Nothing compares to the authentic flavor of home, so we keep those traditions in place here, where you can enjoy the rainbow of flavors of the Authentic Mexican cuisine dishes. From Pechuga Asada to a flavor-filled Molcajete dish, our Specialties will make you come b We strive every day to serve you with a big smile and a unique flavor in every order we put in your hands. You, our customers, are the sole purpose of our existence, therefore, all our efforts are focused on give you the best possible culinary experience in our restaurant. Our team is trained and prepared to make even a lunch an special event for your eyes and stomach. "Mi Casa es Su Casa"."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9959,
        total_orders: 702.74,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegetarian, Salad, Hamburger, Gluten Free, Beer, Wine, Avocado Toast, Organic, Savory, Australian',
        annual_dol: 2549.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7334,
        store_longitude: -74.0053,
        total_orders: 2175.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Beer, Friendly, Casual, Cool, African, American, Great, Fusion, Diverse',
        annual_dol: 2335.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -74.0056,
        total_orders: 900.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Burger, Tacos, Beer, Wine, Champagne, Coffee, Spicy, Martini, Trendy',
        annual_dol: 939.32,
        description:
            'Philly spin-off providing housemade breads & pastries, elevated sandwiches, coffee & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7416,
        store_longitude: -73.6397,
        total_orders: 940.62,
        neighborhood: '',
        keywords: 'Burger, Steak, Salad, Beer, Gourmet, Wine, Ale, Danish, Old Fashioned, Irish',
        annual_dol: 960.58,
        description:
            'Traditional American cooking & modern bar eats are served in a vintage saloon setting with booths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6477,
        store_longitude: -74.0074,
        total_orders: 45.92,
        neighborhood: 'Sunset Park',
        keywords:
            'Tacos, Mexican, Thai, Premium, Authentic, Superb, Outstanding, Fresh, Cool, Great',
        annual_dol: 193.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.6403,
        total_orders: 54.73,
        neighborhood: '',
        keywords: 'Pizza, Mexican, Italian, Casual, Asian, Bruschetta, Fresh',
        annual_dol: 62.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8442,
        store_longitude: -73.9392,
        total_orders: 41.79,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Vegan, Cuban, Coffee, Delicious, Dominican, Spacious, American, Latin, Hip, Club Sandwich',
        annual_dol: 92.92,
        description:
            'Corner spot with hip decor offering Cuban plates with international influences, served all day.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7019,
        store_longitude: -73.8794,
        total_orders: 423.01,
        neighborhood: 'Flushing/Glendale',
        keywords:
            'Hamburger, Steak, Pizza, Salad, Cheesesteak, Beer, Bbq, Ale, French, Philly Cheesesteak',
        annual_dol: 442.62,
        description:
            'Bar food, brunch & dinner entrees draw neighbors to this casual pub with sports-bar ambience.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7664,
        store_longitude: -73.7066,
        total_orders: 826.41,
        neighborhood: '',
        keywords: 'Burger, Sushi, Golfclub, Impeccable, Great, Classic, Fresh',
        annual_dol: 1311.95,
        description:
            '"Located on the former site of the Vanderbilt’s private golf club, The Lake Success Golf Club offers a challenging 6,414-yard, par 70 course with well-manicured fairways and impeccable greens. In addition to golf, members enjoy a first class club house, grill room, Social Activities Center, Fitness center and more. Tennis and Pool memberships are also available. We\'re truly a home away from home."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7032,
        store_longitude: -73.9322,
        total_orders: 497.74,
        neighborhood: 'Bushwick/East Williamsburg',
        keywords:
            'Vegetarian, Sushi, Mexican, Organic, American, Delicious, Casual, Local, Eclectic, Sweet',
        annual_dol: 554.61,
        description:
            'Inventive craft cocktails, including milk punches, plus eclectic fare in an indoor-outdoor setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9643,
        total_orders: 1916.66,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Salad, Italian, Dessert, Shrimp Scampi, Wine, Meatball, Vodka, Delicious, Warm',
        annual_dol: 1957.33,
        description:
            'Bustling eatery serving large, shareable portions of Italian comfort food in a lively setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7298,
        store_longitude: -73.9574,
        total_orders: 3434.33,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Margarita, Trendy, Rich, Warm, Cozy, Phenomenal, Eclectic, Friendly',
        annual_dol: 3662.63,
        description:
            'A tap beer selection is poured in a room with apothecary cabinets, brick walls & wide plank floors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7521,
        store_longitude: -73.985,
        total_orders: 107.59,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Hotel, Whiskey, Scotch, Beer, Stylish, Rooftop, American, Awesome, Superb',
        annual_dol: 439.78,
        description:
            'Posh, wood-lined lobby bar inside the Refinery Hotel featuring classic cocktails & live jazz.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9938,
        total_orders: 313.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Tacos, Mexican, Steak, Salad, Nachos, Beer, Tasty, Burritos, Rustic, Sparkling',
        annual_dol: 342.94,
        description:
            'Longtime counter-serve joint with a large menu of Mexican classics, plus wholesale tortillas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.8603,
        total_orders: 444.93,
        neighborhood: 'North Corona/East Elmhurst/Flushing/Corona',
        keywords:
            'Steak, Vegetarian, Salad, Argentinian, Beer, French, Dessert, Brazilian, Japanese, Warm',
        annual_dol: 474.5,
        description:
            'Besides all-you-can-eat meat, this airy Brazilian steakhouse offers a buffet, salad bar & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.5796,
        total_orders: 473.95,
        neighborhood: '',
        keywords:
            'Burger, Lasagna, Salad, Pizza, Chicken Parmigiana, Italian, Chicago-Style, Wine, Organic, Delicious',
        annual_dol: 490.75,
        description:
            'Cozy, relaxed dining room serving Italian standards from pasta to seafood plates plus wine & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -74.0086,
        total_orders: 1384.1,
        neighborhood: 'Hudson Square',
        keywords: 'Burger, Beer, Wine, Trendy, Delicious, Urban, American, Fun, Classy, Hip',
        annual_dol: 1384.1,
        description:
            'Nodding to the East End, this bar has seaside touches to go with cocktails, oysters & small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.588,
        total_orders: 189.69,
        neighborhood: '',
        keywords:
            'Burger, Tacos, Salad, Wine, Delicious, Italian, Elegant, Legendary, Exquisite, Friendly',
        annual_dol: 514.29,
        description:
            '"Monroe\'s is a family owned and operated restaurant serving fresh and delicious food in Westbury, NY. We use only the best ingredients and recipes for all of our dishes, serving our customers quality that they can taste in every bite. Packed with flavor and cooked with the utmost care and dedication to authenticity, you are sure to love everything about our cuisine."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7035,
        store_longitude: -73.9263,
        total_orders: 2037.72,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Cozy, Lively, Cool, Bright, Great, Prosecco, Fine, Casual',
        annual_dol: 2080.95,
        description:
            'Unpretentious, cozy bar with leather booths offering beers on draft, cocktails & shot specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7251,
        store_longitude: -73.9925,
        total_orders: 1833.28,
        neighborhood: 'NoHo',
        keywords:
            'Pizza, Vodka, Tasty, Rustic, Gin, Delicious, Rum, Spacious, Friendly, Contemporary',
        annual_dol: 2038.81,
        description:
            'Hip, rustic bar serving cocktails & pizza in a brick-lined space with Edison bulbs & TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8023,
        store_longitude: -73.9535,
        total_orders: 87.84,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tasty, Pizza, Italian, Salad, Wine, Champagne, Spicy, Delicious, Mediterranean, Hotel',
        annual_dol: 359.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8087,
        store_longitude: -73.9451,
        total_orders: 7168.49,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Burger, Tasty, Champagne, Upscale, Organic, Sweet, Polished, Fun, Caesar Salad',
        annual_dol: 8696.29,
        description:
            'Harlem restaurant/bar with upscale pub menu, craft beers, outdoor seating & special DJ nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.9888,
        total_orders: 1408.12,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Wine, Delicious, Friendly, Rum, Fun, Wonderful, Retro, Great',
        annual_dol: 1469.17,
        description:
            'Happening saloon with a retro athletic-club atmosphere that includes boxing & wrestling memorabilia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7659,
        store_longitude: -73.9874,
        total_orders: 784.2,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Beer, Urban, Tex-Mex, Rustic, Robust, American, Mead, Friendly, Homemade, Awesome',
        annual_dol: 800.84,
        description:
            'Low-key, urban-rustic tavern offering a robust menu of microbrews on tap & by the bottle.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7637,
        store_longitude: -73.989,
        total_orders: 339.47,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Tequila, Tacos, Mexican, Vodka, Margarita, Wine, Salad, Pizza, Gin, Tasty',
        annual_dol: 364.55,
        description:
            'Chill Mexican venue offering classic fare, craft cocktails & happy-hour specials in comfy digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7479,
        store_longitude: -74.0042,
        total_orders: 613.66,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Lobster, Lobster Roll, Wine, Tasty, Vodka, Beer, Upscale, Gin, Romantic, Rum',
        annual_dol: 2003.46,
        description: '"We are reopening on MONDAY, OCTOBER 11, 2021!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6813,
        store_longitude: -73.9774,
        total_orders: 1034.32,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Salad, Beer, Cozy, Delicious, Comfortable, Rustic, Friendly, Sweet, Cool',
        annual_dol: 1056.27,
        description:
            'All-day bar/eatery serving gastropub fare in a wood-&-brick-lined interior & sunlit back garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6815,
        store_longitude: -73.9796,
        total_orders: 23.8,
        neighborhood: 'Park Slope',
        keywords: 'Beer, American, Spicy, Casual, Traditional, Diverse',
        annual_dol: 97.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7394,
        store_longitude: -73.7863,
        total_orders: 1385.68,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords:
            'Salad, Mexican, Bourbon, Meatball, Fresh, Thai, Lively, Asian, French, French Onion Soup',
        annual_dol: 1656.58,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6423,
        store_longitude: -74.0767,
        total_orders: 665.91,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Burger, Pizza, Beer, Delicious, Italian, Crisp, Cozy, Relaxed, Clean, Friendly',
        annual_dol: 696.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9836,
        total_orders: 4787.01,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords:
            'Thai, Tasty, Stylish, Salad, Elegant, Wine, Vegan, Friendly, Traditional, Authentic',
        annual_dol: 4999.64,
        description:
            'Traditional Thai cuisine & cocktails served in a modern space with a stylish bar & golden accents.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.717,
        store_longitude: -73.959,
        total_orders: 1313.19,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Wine, French, Homemade, Polished, Fresh, Perfect, Seasonal, Casual, American',
        annual_dol: 1370.12,
        description:
            'French bistro fare served in a Williamsburg space with an indoor "garden" room & a roof deck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9565,
        total_orders: 824.99,
        neighborhood: 'Williamsburg',
        keywords:
            'Whiskey, Tasty, Wine, Stylish, Delicious, Spacious, Czech, Authentic, American, Cool',
        annual_dol: 3108.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7067,
        store_longitude: -74.0097,
        total_orders: 118.44,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Pizza, Salad, Wine, Irish, Superb, Amazing, Exotic, Iconic, Global, Aperol',
        annual_dol: 484.14,
        description:
            '40 Wall Street, also known as the Trump Building, is a 927-foot-tall neo-Gothic skyscraper on Wall Street between Nassau and William streets in the Financial District of Manhattan in New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7715,
        store_longitude: -73.9561,
        total_orders: 618.93,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Garlic Bread, Hamburger, Meatball Sub, Meatball, Salad, Italian, Gluten Free, Dessert, Beer',
        annual_dol: 632.06,
        description:
            'Branch of a hip, ingredient-focused local chain offering inventive meatball sandwiches & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.705,
        store_longitude: -73.9201,
        total_orders: 156.54,
        neighborhood: 'Bushwick',
        keywords: 'Steak, Tacos, Hamburger, Mexican, Vegan, Beer, Tasty, Quesadillas, Spicy, Cozy',
        annual_dol: 769.89,
        description:
            'Buzzing eatery serving Mexican fare in a cozy & colorful dining room or an outdoor garden space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -74.0058,
        total_orders: 487.7,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Italian, German, Organic, Modern, Mediterranean, Israeli, Kibbeh, Middle Eastern, Casual, Fine',
        annual_dol: 1712.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -73.6352,
        total_orders: 137.73,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Pizza, Japanese, Gourmet, Chinese, Fusion, Asian, Miso Soup, Friendly',
        annual_dol: 249.68,
        description:
            'Pair of sushi/hibachi restaurants known for their fresh fish, bento boxes, sakes & wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8361,
        store_longitude: -73.8405,
        total_orders: 511.62,
        neighborhood: 'Westchester Square',
        keywords:
            'Burger, Salad, Cajun, Italian, Delicious, Mediterranean, Spanish, Spacious, Puerto Rican, Friendly',
        annual_dol: 659.76,
        description:
            'Hip Puerto Rican restaurant & bar doling out brick oven-baked pizzas, empanadas & specialty drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6975,
        store_longitude: -73.6667,
        total_orders: 188.46,
        neighborhood: '',
        keywords:
            'Coffee, Beer, Martini, Dessert, Trendy, Italian, French, Classy, Impeccable, Homemade',
        annual_dol: 710.07,
        description:
            '"Focused around quality food, drinks, and conversation. Our ever-changing tapas menu is designed around seasonal ingredients, as are our cocktail, beer, dessert, and coffee selections."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7149,
        store_longitude: -74.0161,
        total_orders: 2657.71,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Beer, Wine, Bourbon, Salad, Upscale, American, Spicy, Bbq, Warm, Spacious',
        annual_dol: 2774.67,
        description:
            "Danny Meyer's spacious barbecue joint matches its signature ribs with deep beer & bourbon lists.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7086,
        store_longitude: -73.9364,
        total_orders: 442.6,
        neighborhood: 'East Williamsburg',
        keywords: 'Rum, Caribbean, Awesome, Perfect, American, Great, Excellent, Casual',
        annual_dol: 651.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7235,
        store_longitude: -73.9508,
        total_orders: 167.97,
        neighborhood: 'Greenpoint',
        keywords: 'Polish, Beer, Homemade, Great, Casual',
        annual_dol: 228.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6933,
        store_longitude: -73.9693,
        total_orders: 6.63,
        neighborhood: 'Clinton Hill',
        keywords: 'Mexican, Beer, Cajun, Wine, Vintage, Healthy, Local, Intimate, Casual, Great',
        annual_dol: 18.04,
        description: '"Serving freshest seafood boil tossed in Louisiana style Cajun sauced bags"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7684,
        store_longitude: -73.9872,
        total_orders: 2165.04,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotel, Gluten Free, Spacious, French Toast, Beer, French, Fantastic, Amazing, Classic, Great',
        annual_dol: 8118.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9823,
        total_orders: 404.12,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Sushi, Pizza, Wine, Italian, Cajun, Chinese, Beer, Stylish, Urban, Fun',
        annual_dol: 446.68,
        description:
            'Rustic-chic tavern featuring small plates of Italian nibbles, panini, wine, beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.9755,
        total_orders: 456.21,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Steak, Salad, Tomato Soup, Dessert, Wine, Italian, Californian, Beer',
        annual_dol: 486.54,
        description:
            'Posh chain serving American fare, including brunch, dinner & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9,
        store_longitude: -73.8622,
        total_orders: 121.16,
        neighborhood: '',
        keywords:
            'Jamaican, Caribbean, Chinese, Champagne, Dominican, Legendary, Traditional, Authentic, Great, Teriyaki',
        annual_dol: 495.27,
        description:
            'Late-night counter-serve fixture offering a wide selection of seafood meals in a no-frills interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9048,
        store_longitude: -73.897,
        total_orders: 94.58,
        neighborhood: 'North Riverdale',
        keywords:
            'Burger, Hamburger, Steak, Tacos, Cheesesteak, Salad, Cajun, Beer, Bbq, Margarita',
        annual_dol: 154.57,
        description:
            'Rustic spot dispensing hearty American comfort grub, especially creative spins on classic burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.8805,
        total_orders: 90.67,
        neighborhood: 'Jackson Heights',
        keywords: 'Mexican, Tacos, Salad, Burritos, Local, Casual, Fresh, Classic',
        annual_dol: 370.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.9791,
        total_orders: 45.03,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Gourmet, Coffee, Hotel, American, Wonderful, Fine',
        annual_dol: 184.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8683,
        store_longitude: -73.9187,
        total_orders: 22.24,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Mexican, Mimosa, Italian, Dominican, American, Local, Casual, Seasonal, Relaxed, Delicious',
        annual_dol: 90.92,
        description:
            'A Dominican-influenced menu pairs with cocktails & hookah in a relaxed space with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8657,
        store_longitude: -73.9273,
        total_orders: 49.32,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Cheesesteak, Steak, Milkshake, Coffee, Wine, Italian, Tasty, American, Warm, Reuben Sandwich',
        annual_dol: 185.84,
        description:
            'Laid-back, brick-lined cafe with espresso, craft beers, brunch & a New American food menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 2553.04,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Lobster, Salad, Mexican, Italian, Wine, Chinese, Thai, Fried Rice, Hotel',
        annual_dol: 2685.46,
        description:
            'Downtown outpost of the buzzy uptown original serving Beijing-style fare in subterranean hotel digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6063,
        store_longitude: -73.8198,
        total_orders: 92.99,
        neighborhood: 'Broad Channel/Far Rockaway',
        keywords: 'Wine, American, Hotel, Fun, Sweet, Great, Excellent, Casual',
        annual_dol: 107.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6286,
        store_longitude: -74.0884,
        total_orders: 1638.82,
        neighborhood: 'Silver Lake',
        keywords: 'Italian, Cultural, Beer, Exquisite, Vibrant, American',
        annual_dol: 1774.42,
        description:
            'Casa Belvedere is a cultural center devoted to Italian studies, located at 79 Howard Avenue, Grymes Hill, Staten Island, New York City. The mansion was constructed in 1908, and is an Italian Renaissance style building with Arts and Crafts detailing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6779,
        store_longitude: -73.9686,
        total_orders: 1598.63,
        neighborhood: 'Prospect Heights',
        keywords: 'Pizza, Salad, Casual, Fresh, American, Great',
        annual_dol: 6265.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7106,
        store_longitude: -73.9533,
        total_orders: 691.62,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Vegan, Vegetarian, Burger, Sushi, Pizza, Mexican, Wine, American, Casual, Latin',
        annual_dol: 2895.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.724,
        store_longitude: -73.9838,
        total_orders: 343.52,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Pizza, Meatball, Mimosa, Beer, Coffee, Apple Pie, American, Rustic, Delicious',
        annual_dol: 366.09,
        description:
            'Quirky, dimly lit, rustic-chic eatery serving eclectic comfort food for happy hour, brunch & dinner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6797,
        store_longitude: -73.9565,
        total_orders: 534.05,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Wine, Delicious, Juicy, Rum, Great, Incredible, Whimsical, Ample, Pinacolada, Signature',
        annual_dol: 1373.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.9844,
        total_orders: 2974.46,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vodka, Beer, Tasty, Gluten Free, Ale, Gin, Meatball, British, Sweet, Traditional',
        annual_dol: 3037.58,
        description:
            'This bi-level watering hole with an English theme has British entrees, bar snacks & imported beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -73.9784,
        total_orders: 4842.03,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Salad, Tomato Soup, Meatball, Beer, Wine, French Onion Soup, Upscale, Caesar Salad, American',
        annual_dol: 5037.16,
        description:
            'Upscale bar on the edge of Murray Hill with a self-service tap system & hearty American eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.9217,
        total_orders: 1525.97,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Thai, Beer, Wine, Greek, Stylish, Delicious, Contemporary, Friendly, Awesome',
        annual_dol: 1563.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.722,
        store_longitude: -73.958,
        total_orders: 88.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Hotel, Trendy, Stylish, Margarita, Fabulous, Spanish, American, Rooftop, Outstanding',
        annual_dol: 361.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7686,
        store_longitude: -73.9333,
        total_orders: 144.74,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Mexican, Salad, Pizza, Breakfast Burrito, French Toast, Modern, French, Spanish, Latin',
        annual_dol: 321.51,
        description:
            'Laid-back establishment with old-world accents, supplying cocktails & modern Mexican fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7354,
        store_longitude: -74.008,
        total_orders: 440.52,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Austrian, Wine, Coffee, Salad, German, French, Impeccable, Refined, Global, Modern',
        annual_dol: 657.32,
        description:
            'Modern Austrian fare (Wiener schnitzel, goulash, etc.) is on the plates & fine art is on the walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.9508,
        total_orders: 585.53,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Burger, Salad, Wine, Italian, French, Beer, Organic, Delicious, Cozy',
        annual_dol: 623.11,
        description:
            'French bistro & wine bar in brick-lined surrounds with a carefully sourced menu & a romantic vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7393,
        store_longitude: -73.9202,
        total_orders: 371.37,
        neighborhood: 'Sunnyside/Woodside',
        keywords: 'Beer, Whiskey, Tasty, Wine, Ale, Warm, Homey, Bbq, Awesome, Perfect',
        annual_dol: 410,
        description:
            'Long-running, unpretentious watering hole with a patio offering billiards, darts & games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7735,
        store_longitude: -73.9559,
        total_orders: 1076.72,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Salad, Wine, Champagne, Canadian, Spicy, Asian, American, Modern, Intimate',
        annual_dol: 1149.03,
        description:
            'Global cuisine from pork sliders to chicken satay served in a modern restaurant with a lively bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7835,
        store_longitude: -73.9787,
        total_orders: 359.14,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Beer, Whiskey, Wine, Martini, Irish, Delicious, Relaxed, Comfortable, Seasonal, Global',
        annual_dol: 471.78,
        description:
            'Circa-1933 Upper West Side throwback bar with linoleum, wood paneling & an old-school neon sign.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8353,
        store_longitude: -73.9436,
        total_orders: 885.11,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Peruvian, Wine, Beer, Coffee, Upscale, Delicious, Latin, Sangria, Casual, Great',
        annual_dol: 903.89,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7751,
        store_longitude: -73.9565,
        total_orders: 273.99,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Wine, Vegetarian, Gourmet, Dessert, American, Romantic, Cozy, Iconic, High-End, Sweet',
        annual_dol: 1116.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -74.0026,
        total_orders: 183.84,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Salad, Hotel, American, Fun, Cool, Casual, Quirky, Classic, Great',
        annual_dol: 751.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.688,
        store_longitude: -73.9273,
        total_orders: 1974.22,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Beer, Awesome, Great, Incredible, Excellent, American, Cool, Casual',
        annual_dol: 2103.95,
        description:
            '"Hand-Crafted cocktails Artisan Brews Scratch Kitchen Sandwiches Hot Nights, Cool Jazz Neighborhood Hospitality"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8573,
        store_longitude: -73.8863,
        total_orders: 373.55,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Lasagna, Steak, Lobster, Pizza, Chicken Parmigiana, Salad, Shrimp Cocktail, Italian, Wine, Vodka',
        annual_dol: 425.44,
        description:
            'Brick-oven pies & classic Italian dishes served in a relaxed, roomy eatery & wine bar with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -74.0055,
        total_orders: 398.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Gluten Free, Rustic, Wine, Italian, French Toast, Cozy, Trendy, French',
        annual_dol: 493.65,
        description:
            'Snug choice offering brunch, burgers & hearty meat dishes, plus a large wine list & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7352,
        store_longitude: -74.0066,
        total_orders: 229.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Dessert, Wine, Tasty, Italian, Vodka, Martini, Delicious, Cozy, Warm',
        annual_dol: 243.82,
        description:
            'Vibrant outfit with exposed bricks & a wood ceiling serving Italian classics & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.734,
        store_longitude: -74.0081,
        total_orders: 1102.07,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Flavorful, Luxurious, Rich, Upscale, Delicious, Wine, Romantic, Opulent, Savory, Egyptian',
        annual_dol: 1587.16,
        description:
            'Plush decor & entertainment from a belly dancer complement the exotic menu & specialty cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.6415,
        total_orders: 376.93,
        neighborhood: '',
        keywords:
            'Chocolate Cake, Cheesecake, Steak, Carrot Cake, Flavorful, Wine, Savory, Delicious, Beer, Premium',
        annual_dol: 410.8,
        description:
            'Portuguese rodizio offering sizzling grilled-meat skewers & other traditional fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -74.0012,
        total_orders: 173.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vodka, Salad, Mexican, Italian, Shrimp Scampi, Chinese, Cuban, Irish, Romantic, Cozy',
        annual_dol: 607.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7859,
        store_longitude: -73.9724,
        total_orders: 1435.74,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Lasagna, Pizza, Steak, Italian, Wine, Meatball, Coffee, Beer, Mimosa, Delicious',
        annual_dol: 1632.42,
        description:
            'Rustic Italian eatery serving small plates & pizza in a main-floor wine bar or a cozy upstairs room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7839,
        store_longitude: -73.9743,
        total_orders: 522.65,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Sushi, Steak, Vegetarian, Tasty, Wine, Beer, Delicious, Trendy, Stylish, Japanese',
        annual_dol: 533.74,
        description:
            'Stylish eatery for sushi, dim sum, teppanyaki & contemporary Asian entrees, plus daily happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7713,
        store_longitude: -73.9537,
        total_orders: 8.09,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Thai, Spicy, Wine, Meatball, Martini, Beer, Crab Rangoon, Delicious, Asian',
        annual_dol: 33.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8074,
        store_longitude: -73.9273,
        total_orders: 89.81,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Mexican, Salad, Pizza, Mimosa, Coffee, Dominican, Puerto Rican, Caribbean, Rich, Delicious',
        annual_dol: 367.13,
        description:
            'Outpost of a Puerto Rico–based mini-chain serving chocolate-infused cocktails & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6658,
        store_longitude: -73.9889,
        total_orders: 387.31,
        neighborhood: 'Park Slope',
        keywords: 'Steak, Wine, Burger, Pizza, Beer, Salad, Italian, Delicious, French, American',
        annual_dol: 487.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6357,
        store_longitude: -73.9633,
        total_orders: 1527.34,
        neighborhood: 'Flatbush',
        keywords: 'Burger, Beer, Spicy, Warm, Bbq, Cozy, Friendly, American, Asian, Casual',
        annual_dol: 1662.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6713,
        store_longitude: -73.9843,
        total_orders: 254.47,
        neighborhood: 'Park Slope',
        keywords: 'Tequila, Vodka, Beer, Gin, Rum, Fun, Friendly, Casual, Cool, Awesome',
        annual_dol: 1040.19,
        description:
            'Casual lesbian bar with a neighborly feel thanks to happy hours, a pool table & an outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8677,
        store_longitude: -73.8832,
        total_orders: 64.53,
        neighborhood: 'Jerome Park/West Bronx',
        keywords: 'Gourmet, Dominican, Spanish, American, Latin, Local, Casual, Sweet, Great',
        annual_dol: 263.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8608,
        store_longitude: -73.8892,
        total_orders: 162.87,
        neighborhood: 'Belmont/Fordham Manor',
        keywords: '',
        annual_dol: 221.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7276,
        store_longitude: -73.9603,
        total_orders: 174.89,
        neighborhood: 'Greenpoint',
        keywords: 'Unique, Classic, Friendly',
        annual_dol: 714.88,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7185,
        store_longitude: -74.009,
        total_orders: 3689.18,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Italian, Pizza, Salad, Wine, Whiskey, Beer, American, Casual, Classic, Fresh',
        annual_dol: 3831.76,
        description:
            'Laid-back, 1945-era corner pub with bar bites & Alidoro Italian sandwiches for lunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.5877,
        total_orders: 316.51,
        neighborhood: '',
        keywords: 'Steak, Salad, Pizza, Italian, Savory, Seasonal, Casual, Fine',
        annual_dol: 338.72,
        description:
            'White-tablecloth mainstay for Northern Italian dishes & fine wines with live piano on the weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.9952,
        total_orders: 1658.45,
        neighborhood: 'Little Italy',
        keywords:
            'Burger, Steak, Vegetarian, Sushi, Salad, Fruity, Nachos, Spicy, Homemade, Friendly',
        annual_dol: 6607.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9864,
        total_orders: 7258.74,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Ramen, Spicy, Japanese, Stylish, Lively, Classic, Fresh, Casual, Great, Chill',
        annual_dol: 7632.05,
        description:
            'Japanese small plates, ramen & upmarket sake are dispatched in this simple but stylish outpost.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.9848,
        total_orders: 187.63,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Steak, Cheesecake, Roast Turkey, Turkey Sandwich, Roast Beef, Italian, Swiss, Martini, American',
        annual_dol: 766.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7657,
        store_longitude: -73.9909,
        total_orders: 735.59,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Asian, Delicious, Romantic, Innovative, Modern, Homemade, American, Outstanding',
        annual_dol: 1200.76,
        description:
            'Stylish, dimly lit wine bar with open kitchen & an Italian-leaning menu, including homemade pasta.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7649,
        store_longitude: -73.983,
        total_orders: 919.12,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Tacos, Mexican, Steak, Beer, Tasty, Cheesesteak, Wine, Delicious, Trendy, Warm',
        annual_dol: 958.97,
        description:
            'Bi-level locale with ingredient-driven Mexican cuisine plus cocktails highlighting mezcal & tequila.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7674,
        store_longitude: -73.9896,
        total_orders: 13594.63,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Vegetarian, Mexican, Pizza, Gluten Free, Margarita, Dessert, Savory, Delicious, Cozy',
        annual_dol: 14539.54,
        description:
            'Casual Mexican restaurant offering homestyle fare & a full bar with lots of margarita options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7615,
        store_longitude: -73.9847,
        total_orders: 748.81,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Salad, Italian, Wine, Tasty, Japanese, Chilean, Spicy, Delicious, Asian',
        annual_dol: 1464.41,
        description:
            'Sushi, infused sakes & modern Japanese fare are served at this sleek venue with an adjacent lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -73.9925,
        total_orders: 3122.94,
        neighborhood: 'Bowery',
        keywords: 'Pizza, Cajun, Beer, Wine, Funky, American, Creole, Modern, Vintage, Classy',
        annual_dol: 3822.34,
        description:
            'Funky hangout serving New Orleans-inspired bites & drinks, plus beats in its basement music lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5922,
        store_longitude: -73.5834,
        total_orders: 905.4,
        neighborhood: '',
        keywords: 'Burger, Pizza, Salad, Italian, Coffee, Wine, Ale, Homemade, Casual, Classy',
        annual_dol: 1385.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6894,
        store_longitude: -73.9919,
        total_orders: 12.22,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords: 'Steak, Roast Beef, Fine, Great, Delectable, Personalized',
        annual_dol: 49.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 275.62,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Italian, Trendy, Elegant, Coffee, Vibrant, Iconic, Rooftop, Classic',
        annual_dol: 520.54,
        description:
            'The colorful rooms with designer decor feature flat-screen TVs, DVD players, blue-light desks and free Wi-Fi. Suites add either separate living and dining areas, or living areas and extra bathrooms.There are 3 flashy restaurants and bars, including an indoor/outdoor, multi-level rooftop lounge, a cocktail bar and a Fellini-inspired Italian eatery. Guests receive complimentary passes to a nearby fitness center. Parking is available for a surcharge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6859,
        store_longitude: -73.8648,
        total_orders: 37.02,
        neighborhood: 'Jamaica/Woodhaven',
        keywords:
            'Burger, Lobster, Dessert, French Toast, French, American, Omelette, Casual, Sweet, Classic',
        annual_dol: 151.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -74.0089,
        total_orders: 14105.64,
        neighborhood: '',
        keywords: 'Wine, Pizza, Burger, Coffee, Beer, Salad, Spacious, Urban, Fun, Premium',
        annual_dol: 25815.24,
        description:
            '"City Winery, founded in 2008 by Michael Dorf, is a music venue, winery, restaurant, wine bar and private event space located at Pier 57 on the Hudson River in New York, NY. We have excellent views of the Little Island NYC and are nearby the Whitney Museum, Chelsea Market, and the High Line. We specialize in a variety of wines, but always with an eye toward handcrafted cocktails and food. Our location delivers a unique culinary and cultural experience to urban wine enthusiasts passionate about music. We\'re a venue for events—a place for happy hours, family reunions, and birthday parties. We host live events most nights from concerts to comedy shows as well."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7322,
        store_longitude: -73.9999,
        total_orders: 613.02,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Beer, Delicious, Hotel, Clean, Iconic, Latin, Great, Casual, Artistic',
        annual_dol: 668.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6869,
        store_longitude: -73.9748,
        total_orders: 538.27,
        neighborhood: 'Fort Greene',
        keywords: 'Wine, Italian, Intimate, Casual, Seasonal, American, Cool, Unpretentious, Great',
        annual_dol: 2028.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6787,
        store_longitude: -73.9791,
        total_orders: 699.63,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Vegetarian, Margarita, Spanish, American, Latin, Modern, Spicy, Mediterranean, Casual',
        annual_dol: 954.83,
        description:
            'Spanish & Latin American plates, sangria & cocktails in a colorful little space with a happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6868,
        store_longitude: -73.849,
        total_orders: 25.22,
        neighborhood: 'Ozone Park/Jamaica',
        keywords: 'Japanese, Irish, Recommended, Casual, Great',
        annual_dol: 103.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7141,
        store_longitude: -73.9614,
        total_orders: 3853.77,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Wine, Cozy, Warm, Friendly, Casual, Classic, Seasonal, Fresh, Great, Unique',
        annual_dol: 3858.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6132,
        store_longitude: -74.1223,
        total_orders: 183.36,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Sushi, Steak, Pizza, Japanese, Wine, Spicy, Delicious, Beer, Sashimi, Romantic',
        annual_dol: 204,
        description: '"Japanese cuisine-Sushi and Ramen House with Full Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7105,
        store_longitude: -73.9635,
        total_orders: 130.1,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Pizza, Mexican, Salad, Beer, Wine, Italian, Ale, Delicious, American, Friendly',
        annual_dol: 140.53,
        description:
            'Casual restaurant featuring artisanal, Neapolitan-style thin-crust pizzas, plus wine & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6788,
        store_longitude: -73.9717,
        total_orders: 280.87,
        neighborhood: 'Prospect Heights',
        keywords:
            'Burger, Wine, Dessert, French Toast, Californian, French, Romantic, Classy, American, Fabulous',
        annual_dol: 393.49,
        description:
            'Seasonal American fare & unique cocktails served in a modern-vintage Brooklyn space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5862,
        store_longitude: -73.6867,
        total_orders: 111.14,
        neighborhood: '',
        keywords:
            'Burger, Salad, Italian, Beer, Wine, Vodka, Rich, Homemade, Spectacular, Caesar Salad',
        annual_dol: 132.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7465,
        store_longitude: -73.9836,
        total_orders: 1624.4,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Korean, Wine, Spicy, Tasty, Beer, Bbq, Indulgent, Contemporary, Fiery, Exquisite',
        annual_dol: 1714.15,
        description:
            'Korean cuisine & tabletop BBQ, plus international wines, in a stylish, contemporary setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6785,
        store_longitude: -73.7795,
        total_orders: 731.14,
        neighborhood: 'South Jamaica/Jamaica/Addisleigh Park',
        keywords:
            'Tasty, Jamaican, Delicious, Caribbean, Elegant, Upscale, Wine, Healthy, Polished, Great',
        annual_dol: 843.9,
        description:
            'Upscale establishment turning out jerk chicken, oxtail, escovitch & other Jamaican specialties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9801,
        total_orders: 758.35,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Tequila, Wine, Beer, Scotch, Gin, Rich, Tasty, Stylish, Hotel, Rum',
        annual_dol: 3099.87,
        description:
            'Bespoke & craft cocktail bar with a chic retro library vibe within The William hotel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -73.9846,
        total_orders: 878.9,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Hamburger, Whiskey, Salad, Beer, Wine, Upscale, Casual, Rum, American, Delicious',
        annual_dol: 2283.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9851,
        total_orders: 1802.38,
        neighborhood: 'Lower East Side',
        keywords: 'Rooftop, Tacos, Wine, Beer, Margarita, Trendy, Premium, Great, Polish, Casual',
        annual_dol: 1840.62,
        description:
            'Nightlife spot includes a plush lounge, canopied roof deck & basement performance space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9783,
        total_orders: 2402.69,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Turkish, Wine, Beer, Hotel, Polished, Exceptional, Casual, Fine, Great',
        annual_dol: 2785.68,
        description:
            'Swanky spot for meat-centric Turkish fare from a chef with a flamboyant serving style.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.766,
        store_longitude: -73.9877,
        total_orders: 429.3,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lasagna, Wine, Italian, Tasty, Salad, Pizza, Vodka, Beer, Cosmopolitan, Rich',
        annual_dol: 488.94,
        description:
            'Cozy option serving shared plates, pasta & other Italian classics, plus brunch & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 20.65,
        neighborhood: 'Jackson Heights',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Colombian, Coffee, French, Latin, American, Delicious, Perfect',
        annual_dol: 42.15,
        description:
            'Family-owned Colombian diner/bakery serving filling food 24/7 in an old-time setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -73.991,
        total_orders: 241.49,
        neighborhood: 'Lower East Side',
        keywords: 'Vegan, Vodka, Mexican, Coffee, Colombian, Spicy, Bbq, American, Homemade, Latin',
        annual_dol: 281.71,
        description:
            'Casual restaurant serving 40+ kinds of empanadas in a narrow, colorful dining room until late night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7722,
        store_longitude: -73.9915,
        total_orders: 33.45,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Tacos, Mexican, Beer, Vegetarian, Margarita, Wine, American, Contemporary, Artisanal, Casual',
        annual_dol: 136.72,
        description:
            'Contemporary Mexican hot spot turning out innovative tacos & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7392,
        store_longitude: -74.0017,
        total_orders: 445.69,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tequila, Luxurious, Comfortable, Relaxed, Perfect, Premium, Cool, Hip, High-End',
        annual_dol: 1821.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -74.0054,
        total_orders: 1492.83,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Luxurious, Hotel, Fancy, Fun, Premium, Great, Casual',
        annual_dol: 5624.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8638,
        store_longitude: -73.925,
        total_orders: 20.99,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Burger, Whiskey, American, Hotel, Casual, Fantastic, Great, Incredible, Creative',
        annual_dol: 85.82,
        description: '"Whiskey & Burger Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.741,
        store_longitude: -74.0018,
        total_orders: 160.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Delicious, Rich, Salad, Quaint, Sweet, Memorable, Iconic, Great, Fresh',
        annual_dol: 654.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7081,
        store_longitude: -74.0013,
        total_orders: 1930.95,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tasty, Coffee, Scotch, Dessert, Innovative, Classic, Excellent, Casual, Local',
        annual_dol: 2502.11,
        description:
            'Intimate, narrow eatery serving breakfast by day, plus spirits & innovative cocktails by night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6632,
        total_orders: 677.68,
        neighborhood: '',
        keywords: 'Steak, Salad, Tasty, Italian, Pizza, Wine, Delicious, Yummy, Homemade, Fun',
        annual_dol: 873.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -73.7897,
        total_orders: 2598.1,
        neighborhood: 'Fresh Meadows/Utopia/Flushing',
        keywords: 'Steak, Sushi, Salad, Tasty, Japanese, Fresh, Sashimi, Asian, Classic, Relaxed',
        annual_dol: 2956.51,
        description:
            'Relaxed outpost featuring chefs executing familiar hibachi fare, plus classic Asian menu & rolls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7093,
        store_longitude: -74.0057,
        total_orders: 242.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Vegan, Vegetarian, Pizza, Salad, Italian, Ale, American, Homemade, Casual',
        annual_dol: 283.24,
        description:
            'Neapolitan wood-fired pizzas with vegan & gluten-free options doled out in a shoebox-sized space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6126,
        store_longitude: -74.1305,
        total_orders: 30.25,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Pizza, Burger, Italian, Salad, Meatball, Vodka, Beer, Wine, Dessert, American',
        annual_dol: 123.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7107,
        store_longitude: -73.9687,
        total_orders: 1222.05,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Vodka, Wine, Italian, Salad, Meatball, Spectacular, Sweet, Intimate, Memorable, Perfect',
        annual_dol: 1247.98,
        description:
            'Traditional Italian fare offered in a formal setting with panoramic Manhattan skyline views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7433,
        store_longitude: -73.9796,
        total_orders: 2958.35,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Hamburger, Salad, Tequila, Gluten Free, Beer, Wine, Dessert, Upscale, French',
        annual_dol: 3021.13,
        description:
            'Set in a 1920s bank building, this stylish lounge serves elevated bar food & custom cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -73.9556,
        total_orders: 2270.08,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Salad, Beer, Upscale, Rustic, Juicy, Delicious, Casual, Classy',
        annual_dol: 2441.52,
        description:
            'A schoolhouse-styled front room & a dark lounge offer distinct spaces for American food & drink.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.9617,
        total_orders: 477.96,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Wine, Dessert, Salad, Avocado Toast, French Toast, French, Cozy, Mediterranean, Rum, Awesome',
        annual_dol: 742.47,
        description:
            'Oysters, cocktails & small plates in a New Orleans-styled setting with an atmospheric garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7267,
        store_longitude: -73.9575,
        total_orders: 88.89,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Gourmet, Ale, Fun, Bright, Perfect, Great, Excellent, Cool',
        annual_dol: 363.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6827,
        store_longitude: -73.9934,
        total_orders: 850.97,
        neighborhood: 'Carroll Gardens',
        keywords: 'Burger, Hotdog, Cornbread, Steak, Beer, Bbq, Spicy, Upscale, Cozy, Classy',
        annual_dol: 890.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6798,
        store_longitude: -73.9888,
        total_orders: 1869.55,
        neighborhood: 'Gowanus',
        keywords:
            'Modern, Stylish, Rooftop, Contemporary, Perfect, Retro, Classic, Chic, Wonderful, Unique',
        annual_dol: 2334.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5869,
        store_longitude: -73.7154,
        total_orders: 131.58,
        neighborhood: '',
        keywords: 'Beer, Local, Fun, Chic',
        annual_dol: 171.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -73.9863,
        total_orders: 3436.36,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Martini, Fun, Friendly, Cool, Great, Casual, Hip',
        annual_dol: 3540.32,
        description:
            'Nightclub chain featuring martinis, DJs & dancing in a retro-salon setting. Most offer manicures.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7386,
        store_longitude: -73.9888,
        total_orders: 2719.91,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tasty, Salad, Butter Chicken, Wine, Upscale, Delicious, Elegant, Trendy, Gin, Modern',
        annual_dol: 6047.84,
        description:
            'Trendy restaurant spotlighting reimagined Indian dishes & desserts, plus cocktails, wine & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5993,
        store_longitude: -73.9662,
        total_orders: 11329.16,
        neighborhood: 'Gravesend',
        keywords: 'Luxurious, Dessert',
        annual_dol: 12057.42,
        description:
            'Congregation Shaare Zion is an Orthodox Sephardic synagogue located at 2030 Ocean Parkway in Brooklyn, New York. Shaare Zion typically has an estimated 1,500 worshipers who attend its services Fridays and Saturdays for Shabbat making it one of the largest Sephardic synagogues in North America.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5889,
        store_longitude: -73.974,
        total_orders: 29.22,
        neighborhood: 'Gravesend',
        keywords: 'Vodka, Tasty, Pizza, Gin, Salad, Rum, Juicy, Premium, Sweet, Perfect',
        annual_dol: 31.84,
        description:
            'Old-school alley offering 32 lanes, cosmic bowling & weekly specials, plus a snack counter & bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6051,
        store_longitude: -73.9803,
        total_orders: 2142.81,
        neighborhood: 'Gravesend',
        keywords:
            'Burger, Steak, Kebab, Pizza, Salad, Turkish, Mediterranean, Wine, Australian, Casual',
        annual_dol: 6080.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7352,
        store_longitude: -73.9827,
        total_orders: 217.93,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Pizza, Beer, Wine, Delicious, Elegant, Upscale, Friendly, Sweet, Fun, American',
        annual_dol: 890.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5472,
        store_longitude: -74.1575,
        total_orders: 185.08,
        neighborhood: 'Great Kills',
        keywords:
            'Pizza, Vegetarian, Italian, Gluten Free, Delicious, Organic, Creamy, Polished, Homemade, Sweet',
        annual_dol: 259.29,
        description:
            'Pasta, brick-oven pizza & family-style dinners served in a roomy space with villalike decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5926,
        store_longitude: -74.068,
        total_orders: 157.93,
        neighborhood: 'Arrochar',
        keywords:
            'Salad, Steak, Lobster, Italian, Coffee, Savory, Mediterranean, Caesar Salad, Turkish, Russian',
        annual_dol: 193.04,
        description:
            'Festive, opulently decorated restaurant serving Russian & Mediterranean cuisine, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5858,
        store_longitude: -73.9516,
        total_orders: 2801.35,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Steak, Hamburger, Chicken Parmigiana, Cheesesteak, Roast Beef, Italian, Wine, Nachos, Austrian, Coffee',
        annual_dol: 2940,
        description:
            'Unassuming neighborhood joint offering steaks, burgers, pasta & other standards in casual surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8863,
        store_longitude: -73.9072,
        total_orders: 1514.9,
        neighborhood: 'Riverdale/West Bronx',
        keywords:
            'Steak, Sushi, Pizza, Wine, Latin, Swiss, American, Polished, Phenomenal, Caribbean',
        annual_dol: 1580.58,
        description: '"Latin American Bar / Restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 255.62,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster Roll, Lobster, Salad, Hamburger, Italian, Hotel, Organic, Rustic, Spacious, Casual',
        annual_dol: 1044.88,
        description:
            'Seafood restaurant serving Maine-style lobster rolls with toasted buns, plus clam chowder.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.9708,
        total_orders: 6126.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Vegetarian, Japanese, Dessert, Bbq, Wine, Beer, Delicious, Bellini, Elegant',
        annual_dol: 6682.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9694,
        total_orders: 493.68,
        neighborhood: 'Lenox Hill',
        keywords:
            'Whiskey Sour, Whiskey, New-York-Style, Elegant, Sweet, Friendly, Classic, Casual, Great',
        annual_dol: 2018,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9684,
        total_orders: 905.46,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Dessert, Wine, Trendy, Japanese, Warm, Cozy, Asian, Classy',
        annual_dol: 924.67,
        description:
            'Contemporary Japanese BBQ featuring table grills for various meats, plus sake & specialty cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9622,
        total_orders: 171.84,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Steak, Kebab, Salad, Tasty, Wine, Gluten Free, Greek, Mediterranean, American, Homemade',
        annual_dol: 191.19,
        description:
            'Corner spot serving small & large plates of traditional Greek meat, seafood & vegetable dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9679,
        total_orders: 529.77,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegan, Tacos, Vegetarian, Mexican, Beer, Margarita, Delicious, Friendly, Shawarma, Casual',
        annual_dol: 648.95,
        description:
            'Bi-level haunt for creative tacos with house-pressed tortillas plus mezcal margaritas & flights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.9741,
        total_orders: 1245.4,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Vegetarian, Whiskey, Salad, Wine, Beer, Italian, Mimosa, Delicious',
        annual_dol: 1299.39,
        description:
            'Traditional American lunch restaurant in the Trump Tower also offering Sunday brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7261,
        store_longitude: -73.8773,
        total_orders: 212.92,
        neighborhood: 'Middle Village/Flushing',
        keywords: 'Burger, Pizza, Beer, Italian, Lively, Fresh, Caribbean, Great, Local, Casual',
        annual_dol: 226.58,
        description:
            '"We serve great food and specialty cocktails! Our beer menu is amazing! If you’re in or near Queens or staying near LaGuardia or JFK come on down for pub style burgers, chicken wings, steaks, salads, ice cold beer and a whole lot more. And if you don’t feel like sitting inside, you can enjoy our outdoor beer garden!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.9686,
        total_orders: 21.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tequila, Mexican, Beer, Flavorful, Nachos, Bbq, Homemade, Fun, Vibrant, Artisanal',
        annual_dol: 86.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7463,
        store_longitude: -73.9908,
        total_orders: 6326.78,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Italian, Coffee, Cozy, Karaoke, Spectacular, Modern, Contemporary, Sophisticated, Fun',
        annual_dol: 6461.02,
        description:
            "restaurants and karaoke bars in Korea Town.Bright rooms with an industrial-chic vibe feature free Wi-Fi and flat-screen TVs. Some have bunk beds, and/or floor-to-ceiling windows with city views. Suites add separate living rooms.There's a hip cafe, a sophisticated Italian restaurant and a bar. A swanky cocktail lounge on the 35th floor has panoramic city views. Other amenities include meeting space and a 24-hour gym.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6917,
        store_longitude: -73.9845,
        total_orders: 3778.04,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Lobster, Rooftop, Steak, Shrimp Cocktail, Tacos, Hotel, Beer, Japanese, Salad, Margarita',
        annual_dol: 3957.22,
        description:
            'Minimalist-chic rooftop lounge offering Asian-inspired bar eats, cocktails & beer, plus city views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7584,
        store_longitude: -73.9929,
        total_orders: 501.08,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Italian, Comfortable, Fun, Great, Clean',
        annual_dol: 1754.94,
        description:
            "Square and 14 minutes on foot from events at storied Madison Square Garden.Snug, streamlined rooms provide free Wi-Fi and flat-screen TVs, as well as desks and safes. Some rooms feature bunk beds; apartments have kitchens and separate living rooms.There's a laid-back tiki bar/restaurant and a terrace.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.9838,
        total_orders: 5167.6,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Shrimp Cocktail, Salad, Beer, Wine, Upscale, Irish, Delicious, American',
        annual_dol: 5294.54,
        description:
            'Multilevel Irish-style pub with numerous beers on tap, daily specials & an outdoor rooftop bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5096,
        store_longitude: -74.2471,
        total_orders: 31.4,
        neighborhood: 'Tottenville',
        keywords: 'Steak, Rich, Sweet, Casual',
        annual_dol: 128.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -74.0071,
        total_orders: 115.62,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Italian, Pizza, Beer, Polish, Hotel, Dominican, Chinese, Luxurious, Playful, Polished',
        annual_dol: 472.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7176,
        store_longitude: -73.7352,
        total_orders: 3281.16,
        neighborhood: 'Jamaica/Queens Village',
        keywords:
            'Salad, Vegan, Vegetarian, Mimosa, Champagne, Elegant, Sweet, Friendly, Sangria, Fun',
        annual_dol: 3742.73,
        description:
            "\"Historic Antun's is a leading Event Venue, Planning, and Catering Service in Queens, NY, that provides beautiful event venues and the highest quality event catering services in Queens and Brooklyn. Our specialties are wedding receptions, Sweet 16 parties, baby showers, fundraisers, bridal showers, and many types of corporate events. A few of the benefits you'll love by hosting your special event at Antun's include a free on-site event coordinator, six elegant banquet halls for 60 to 800 guests, we treat you like family with friendly 5-star service, our great good and extensive menu choices, and free onsite parking for you and your guests.\"",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7734,
        store_longitude: -73.9911,
        total_orders: 254.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Luxurious, Wine, Glamorous, Italian, Bellini, Sleek, Sophisticated, Pizza, Fantastic, Classic',
        annual_dol: 1039.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6225,
        store_longitude: -73.965,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Burger, Sushi, Tacos, Spicy, Ale, Clean, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7352,
        store_longitude: -74.0066,
        total_orders: 741.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Burger, Salad, Champagne, Wine, Upscale, Delicious, Warm, Avocado Toast, Rustic',
        annual_dol: 948.55,
        description:
            "Ingredients from farmer's markets appear in refined New American preparations at this airy eatery.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7324,
        store_longitude: -73.6385,
        total_orders: 290.55,
        neighborhood: '',
        keywords:
            'Cornbread, Flavorful, Gluten Free, Beer, Wine, Italian, German, Bbq, Korean, American',
        annual_dol: 303.02,
        description:
            'Slow-cooked BBQ & street food, plus draft beer & cocktails, served in bustling, informal surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7844,
        store_longitude: -73.9776,
        total_orders: 1245.48,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegan, Vegetarian, Salad, Wine, Coffee, Dessert, Martini, Peruvian, Beer, Stylish',
        annual_dol: 4066.21,
        description:
            'Rice dishes, noodles & soups, plus cocktails & wine presented in a stylish setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7894,
        store_longitude: -73.974,
        total_orders: 490.06,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Ale, Welcoming, Clean, Perfect, Irish, Great, Casual',
        annual_dol: 1124.7,
        description:
            '"A Welcoming Upper West Side Pub serving Craft Beer, Cocktails, Wines & Light Bites."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7079,
        store_longitude: -73.8979,
        total_orders: 85.31,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Tacos, Mexican, Tasty, Steak, Beer, Wine, Delicious, Shrimp Cocktail, Fresh, Tex-Mex',
        annual_dol: 111.72,
        description:
            'Casual Mexican restaurant specializing in classics like tacos, quesadillas & burritos.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6558,
        store_longitude: -73.5499,
        total_orders: 10.35,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Warm, Casual, Healthy, Classic',
        annual_dol: 164.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -73.9522,
        total_orders: 1231.3,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Steak, Sushi, Japanese, Spicy, Sashimi, Ramen, Ribeye Steak, Asian, Cozy, Contemporary',
        annual_dol: 1257.42,
        description:
            'Cozy Japanese eatery with rustic-chic decor offering a contemporary menu of sushi, yakitori & ramen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6886,
        store_longitude: -73.9419,
        total_orders: 185.51,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Delicious, Beer, Fun, Sweet, Friendly, Clean, Awesome, Classy, Cool',
        annual_dol: 758.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9549,
        total_orders: 339.8,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Pizza, Salad, Mediterranean, Organic, Elegant, Premium, Sophisticated, Clam Chowder, Fresh, Hummus',
        annual_dol: 369.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7031,
        store_longitude: -73.9334,
        total_orders: 127.14,
        neighborhood: 'East Williamsburg',
        keywords:
            'Hamburger, Salad, Coffee, Beer, Upscale, American, Asian, Delicious, Friendly, Hip',
        annual_dol: 332.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.647,
        store_longitude: -73.791,
        total_orders: 248.25,
        neighborhood: 'Rosedale',
        keywords: 'Coffee, Pizza, Martini, Hotel, French, Modern, Sleek, Casual, Cool, Rooftop',
        annual_dol: 412.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7342,
        store_longitude: -73.9939,
        total_orders: 729.36,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Stylish, Warm, Salad, Gin, Comfortable, Sweet, Relaxed, Korean, Polished',
        annual_dol: 778.51,
        description:
            'A West Village fixture still serving standout New American plates in a stylish yet relaxed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5878,
        store_longitude: -73.9545,
        total_orders: 194.27,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Salad, Coffee, Vodka, French, Hotel, Casual, Local',
        annual_dol: 794.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7537,
        store_longitude: -73.9782,
        total_orders: 792.39,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Pizza, Japanese, Wine, Serene, Authentic, Intimate, Casual, Impressive, Iconic',
        annual_dol: 3239.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9694,
        total_orders: 1196.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Sushi, Japanese, Sashimi, Spicy, Ramen, Delicious, Modern, Fusion, Teriyaki',
        annual_dol: 4890.84,
        description:
            'Unassuming restaurant furnishing everyday Japanese eats such as sushi, tempura & gyoza.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7155,
        store_longitude: -74.009,
        total_orders: 2050.55,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Stylish, Hotel, Clean, Perfect, Vibrant, Lively, Vintage, Great, Italian, Contemporary',
        annual_dol: 2094.05,
        description:
            "The cozy, stylish rooms, some with exposed brick walls, have flat-screen TVs and free Wi-Fi. A family suite sleeps up to 5 people. Room service is offered.Free walking tours are offered. There's a lively Italian eatery with original artwork, along with a 24-hour fitness room. Valet parking is available for a surcharge.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7573,
        store_longitude: -73.9682,
        total_orders: 76.88,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Beer, Wine, Fun, Casual, American, Amazing, Great, Diverse, Unique',
        annual_dol: 139.55,
        description:
            'Graffiti covers most surfaces in this funky, low-lit watering hole with a punk-rock vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9947,
        total_orders: 266.12,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords: 'Burger, Steak, Vegetarian, Cajun, Gourmet, Beer, Wine, Spicy, Bbq, Elegant',
        annual_dol: 283.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6801,
        store_longitude: -73.9466,
        total_orders: 840.77,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Hamburger, American, Casual, Traditional, Great',
        annual_dol: 996.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7849,
        store_longitude: -73.9557,
        total_orders: 1315.33,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Lobster, Lobster Roll, Burger, Vegetarian, Salad, Tomato Soup, Mimosa, Italian, Margarita, Coffee',
        annual_dol: 1343.24,
        description:
            'A neighborhood spot serving seafood & American-style meats & pastas, with a yacht-club style decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9881,
        total_orders: 1320.22,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Wine, Beer, Stylish, Vodka, Elegant, Moscow Mule, Rum, Sleek, Urban',
        annual_dol: 3077.76,
        description:
            "on foot from Times Square.Featuring floor-to-ceiling windows and New York-inspired murals, the 647 bright rooms come with Wi-Fi, flat-screen TVs and minifridges. Upgraded rooms include pull-out sofas, while suites add whirlpool tubs; some have separate living areas. Club-level quarters offer access to a private lounge with free drinks and snacks.There's a sleek restaurant, a bar and a casual breakfast eatery, as well as a 24-hour gym.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6893,
        store_longitude: -73.9549,
        total_orders: 2120.56,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tacos, Mexican, Beer, Vegetarian, Vodka, Tasty, Filipino, Painkiller, Tex-Mex, American',
        annual_dol: 2275.28,
        description:
            'Filipino food goes Tex-Mex at this eatery serving tacos & colorful fishbowl drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6223,
        store_longitude: -74.1698,
        total_orders: 45.52,
        neighborhood: '',
        keywords:
            'Steak, Burger, Cheesesteak, Chicken Parmigiana, Crisp, Bbq, Fresh, Pot Roast, French, Philly Cheesesteak',
        annual_dol: 186.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6865,
        store_longitude: -73.9444,
        total_orders: 597.56,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Pizza, Italian, Beer, Wine, Spicy, Delicious, American, Classy, Negroni, Intimate',
        annual_dol: 862.24,
        description:
            'Intimate eatery with a backyard serving pinsa, Roman-style pizza, plus Roman antipasti & Negronis.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9907,
        total_orders: 107.32,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Ale, American, Gourmet, Cozy, Intimate, Casual, Great, Signature',
        annual_dol: 116.51,
        description:
            '"Innovative cocktail bar with small plates located in an intimate environment in the heart of Hell’s Kitchen."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7273,
        store_longitude: -74.0002,
        total_orders: 495.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chocolate Cake, Burger, Salad, Champagne, Tequila, Wine, Dessert, Beer, Avocado Toast, French Toast',
        annual_dol: 517.14,
        description:
            'A casual space sets the scene for American meat & seafood staples, plus a busy weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9096,
        total_orders: 139.72,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Beer, Hotdog, Mexican, Burger, Margarita, Salad, Wine, Cuban, Latin, Colombian',
        annual_dol: 155.68,
        description: '"Authentic Home-style Latin Fusion"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.985,
        total_orders: 16223.05,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Mexican, Tequila, Tacos, Margarita, Gluten Free, Upscale, American, Classy, Healthy, Latin',
        annual_dol: 21326.77,
        description:
            'Modern Mexican dishes with a healthy helping of tradition served in a festive atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.814,
        store_longitude: -73.9599,
        total_orders: 513.72,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Garlic Bread, Wine, Vegetarian, Vodka, Italian, Coffee, Beer, Nutritious, Delicious, Homemade',
        annual_dol: 741.1,
        description:
            'Basic Italian fare in a homey, semi-subterranean space with live jazz on Sundays.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7716,
        store_longitude: -73.9939,
        total_orders: 291.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Elegant, Beer, Luxurious, Martini, Comfortable, Clean, Great, Excellent, Fresh',
        annual_dol: 1190.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.977,
        total_orders: 1472.68,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Georgian, Homey, Classic, Seasonal, Fun, Intimate, Great',
        annual_dol: 1572.07,
        description:
            "The Women's National Republican Club is the oldest private club for Republican women in the United States, and was founded by Henrietta Wells Livermore in 1921.The club grew out of the earlier women's suffrage movement in New York which led to the Nineteenth Amendment.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7647,
        store_longitude: -73.9778,
        total_orders: 206.1,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Pizza, Italian, Flavorful, Tasty, Wine, Dessert, Homemade, Bbq, Sweet, Fresh',
        annual_dol: 215.04,
        description:
            'Family-friendly pizzeria serving thin-crust pies alongside calzones, pastas & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9561,
        total_orders: 84.47,
        neighborhood: 'Roosevelt Island',
        keywords: 'Pizza, Salad, Wine, Beer, Delicious, Cozy, Friendly, Modern, Premium, Casual',
        annual_dol: 345.29,
        description:
            'Cornell Tech is a technology, business, law, and design campus of Cornell University located on Roosevelt Island in Manhattan, New York City. It includes the Jacobs Technion-Cornell Institute, a joint academic venture between Cornell and the Technion – Israel Institute of Technology.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7327,
        store_longitude: -73.9978,
        total_orders: 874.54,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Upscale, Steak, Romantic, Hotel, Spicy, Italian, Friendly, Mediterranean, Eclectic',
        annual_dol: 907.01,
        description:
            'An extensive list of wines by the bottle & by the glass are available at this Village refuge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -73.9966,
        total_orders: 2773.23,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Burger, Hotel, Coffee, Meatball, Luxurious, Mediterranean, Stylish, Premium, French',
        annual_dol: 3067.09,
        description:
            "and a 5-minute walk from a subway station.Compact, refined rooms feature ornate moldings, stylish designer furniture and wood floors. They come with flat-screen TVs and Wi-Fi, along with minibars and marble bathrooms. A suite adds a separate living area and a terrace.Breakfast is available. There's a posh Mediterranean restaurant, a polished cocktail bar and a hip cafe.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7421,
        store_longitude: -74.0037,
        total_orders: 4051.17,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Steak, Rich, Upscale, Chinese, Romantic, Perfect, Modern, Classic, Casual',
        annual_dol: 4461.66,
        description:
            'Modern takes on classic cocktails are served in a 1920s-inspired space with DJ booths & chandeliers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 22861.85,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Mexican, Wine, Delicious, Glamorous, Japanese, Chinese, Elegant, Peking Duck, Thai',
        annual_dol: 23737.39,
        description:
            'Huge, lavishly decorated restaurant offering a menu of Asian dishes & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6871,
        store_longitude: -73.9784,
        total_orders: 547.51,
        neighborhood: 'Fort Greene',
        keywords:
            'Italian, Pizza, Wine, Coffee, Stylish, Homemade, Hotel, Warm, Handcrafted, Friendly',
        annual_dol: 1229.6,
        description:
            'Easygoing restaurant with a stylish ambiance dishing up pizza & pasta, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9808,
        total_orders: 4138.57,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Tacos, Beer, Delicious, Cozy, Homey, Friendly, Intimate, Premium, Cool, Retro',
        annual_dol: 4318.01,
        description:
            'Resembling a posh, retro apartment, this bar serves seasonal cocktails, wines & canned beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.714,
        store_longitude: -73.9485,
        total_orders: 481.13,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Tacos, Salad, Beer, Bbq, American, Southern, Casual, Friendly',
        annual_dol: 524.36,
        description:
            'Resto-bar with a dark-wood interior & patio for Southern dishes, burgers & craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -74.0075,
        total_orders: 296.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Tequila, Tacos, Mexican, Gluten Free, Delicious, Fun, Breakfast Burrito, Awesome, Friendly, American',
        annual_dol: 1253.4,
        description:
            '"Mezcali is the brainchild of two best friends from Southern California who grew up skateboarding and satisfying their afternoon hunger pangs with Cali-Mex food. Some of our best memories were shared over a plate of carne asada fries and a couple shots of mezcal. We built Mezcali in homage to our experiences growing up in South Cali. While we can’t recreate Los Angeles in Downtown New York, we have done our best to bring you a slice of our love for everything Cali-Mex, but in the heart of Manhattan."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7249,
        store_longitude: -73.9785,
        total_orders: 255.25,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Hotel, Classic, Friendly, Great, American, Homemade, Mediterranean, Creative, Laid-Back',
        annual_dol: 833.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -73.9565,
        total_orders: 392.61,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Coffee, Argentinian, Italian, Peruvian, Rum, American, Four Horsemen, Spanish, French',
        annual_dol: 493.49,
        description:
            '"Lamonte is located in Brooklyn, New York, right off the L train, at the Bedford stop, open 7 days a week, from early coffee till late night cocktails. Cafe, Restaurant, Bar."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.631,
        store_longitude: -74.1033,
        total_orders: 1441.13,
        neighborhood: 'Mid Island/West Brighton',
        keywords: 'Burger, Lobster, Lobster Roll, Salad, Mexican, Beer, Tasty, Wine, Zesty, Irish',
        annual_dol: 1471.71,
        description:
            'Creatively sauced wings highlight the elevated American pub-grub menu at this laid-back sports bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7129,
        store_longitude: -73.9578,
        total_orders: 120.28,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Wine, Italian, Delicious, Bourbon, Friendly, Southern, Sleek, Casual, Fresh, Excellent',
        annual_dol: 453.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7507,
        store_longitude: -73.9817,
        total_orders: 777.72,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Tacos, Mexican, Salad, Vegetarian, Tequila, Upscale, Casual, Homemade, Delicious, Papaya Salad',
        annual_dol: 1023.15,
        description:
            'Convivial hangout dishing up finger foods, tacos & burritos, plus margaritas & a tequila list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7483,
        store_longitude: -73.9767,
        total_orders: 819.61,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Tacos, Mexican, Steak, Salad, Margarita, Beer, Nachos, Chili Con Carne, Savory, Tex-Mex',
        annual_dol: 819.61,
        description:
            'Margaritas & Tex-Mex fare fuel an often bustling scene at this joint with seasonal outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7205,
        store_longitude: -73.9551,
        total_orders: 2060.71,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Margarita, Ale, Classic, Local, Great, Casual',
        annual_dol: 2224.11,
        description: 'Unassuming bar for beer & pitchers of cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7504,
        store_longitude: -73.9817,
        total_orders: 872.09,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Dessert, Chinese, Juicy, Spicy, Yummy, Classy, Wonton Soup, Casual, Local, Great',
        annual_dol: 977.51,
        description:
            'Chinese spot offering a Shanghai-influenced menu with dumplings, noodles, pork buns & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7163,
        store_longitude: -73.96,
        total_orders: 130.27,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Tacos, Burritos, Organic, Healthy, Delicious, Casual, Local',
        annual_dol: 655.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7026,
        store_longitude: -73.913,
        total_orders: 258.89,
        neighborhood: 'Bushwick',
        keywords:
            'Coffee, Cozy, Spacious, Chinese, Perfect, Cool, Great, Local, Unpretentious, Unique',
        annual_dol: 551.8,
        description: '"Cafe/Bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -74.0068,
        total_orders: 1327.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Wine, Champagne, Delicious, Cozy, Rustic, Perfect, French, Friendly',
        annual_dol: 1400.72,
        description:
            'Warmly lit bar & American eatery with a brief menu of oysters, steaks & other hearty fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6476,
        store_longitude: -73.6053,
        total_orders: 3680.43,
        neighborhood: '',
        keywords:
            'Pizza, Vegetarian, Salad, Upscale, American, Exquisite, Fabulous, Picturesque, Outstanding, Rooftop',
        annual_dol: 3855.99,
        description:
            'Upscale country-style weddings & events place next to a lake offering multiple spaces & menus.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8112,
        store_longitude: -73.8353,
        total_orders: 765.17,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Burger, Salad, Fun, Spectacular, Exceptional, Great, Handcrafted, Cool, Iconic, Seasonal',
        annual_dol: 868.77,
        description:
            '"NYC’s only tournament-quality course takes advantage of dramatic and spectacular views of the Manhattan skyline, East River, and Whitestone & Throggs Neck Bridges. It was handcrafted at every angle and exceeds the vision that was outlined by not only Donald Trump, but by Jack Nicklaus, the City of New York and every golf rater that has visited the property since it has grown in. Centrally located just minutes from midtown Manhattan, Long Island, Connecticut, Westchester, and New Jersey, Trump Golf Links at Ferry Point is also NYC’s newest waterfront event venue offering breathtaking 360 degree views of the iconic NYC skyline, Whitestone & Throgs Neck bridges. This unique venue is exactly what you’ve been looking for."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7834,
        store_longitude: -73.978,
        total_orders: 719.04,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Beer, Bourbon, Clean, Hotel, Fun, Great, Casual',
        annual_dol: 2524.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.847,
        store_longitude: -73.8318,
        total_orders: 5.36,
        neighborhood: 'Pelham Bay',
        keywords: 'Burger, Steak, Vegan, Pizza, Italian, French, American, Healthy, Zesty, Casual',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7035,
        store_longitude: -73.6347,
        total_orders: 1186.22,
        neighborhood: '',
        keywords: 'Golfclub, Local, Diverse',
        annual_dol: 1319.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6965,
        store_longitude: -73.9039,
        total_orders: 1594.66,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Ale, Mimosa, Relaxed, Spacious, Natural, Local, Casual, Amazing, Unique',
        annual_dol: 7491.39,
        description:
            'Gathering place offering local brews & bites in a relaxed setting with a beer garden & greenhouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -73.9943,
        total_orders: 412.52,
        neighborhood: 'Nolita',
        keywords: 'Steak, Salad, Tasty, Thai, Organic, Spicy, Funky, Asian, American, Mojito',
        annual_dol: 518.52,
        description:
            'Budget-friendly Thai fare & drinks fuel a happening crowd at this hip spot with red-leather booths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7267,
        store_longitude: -74.0024,
        total_orders: 489.65,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, French, Italian, Cozy, Stylish, Modern, Playful, American, Awesome, Traditional',
        annual_dol: 558.54,
        description:
            'French fare gets a creative, modern makeover at this stylish bistro with floor-to-ceiling windows.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7186,
        store_longitude: -73.9904,
        total_orders: 453.44,
        neighborhood: 'Lower East Side',
        keywords:
            'Chinese, Tasty, Spicy, Sushi, Beef Chow Fun, Delicious, Congee, Spacious, Fried Rice, Fun',
        annual_dol: 483.24,
        description:
            'Colorful group-friendly spot serving the namesake rice porridge plus a huge menu of Cantonese faves.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.9895,
        total_orders: 6462.28,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Burger, French Onion Soup, French, Upscale, Hotel, Luxurious, Artisanal, Casual, Local',
        annual_dol: 6742.47,
        description:
            'Upscale, exclusive basement lounge in a small basement space attracting a hip, in-the-know crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7202,
        store_longitude: -73.9893,
        total_orders: 3076.1,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Tacos, Wine, Filipino, Beer, Margarita, Japanese, Asian, American, Eclectic',
        annual_dol: 7523.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9758,
        total_orders: 3146.09,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Salad, Chinese, Spicy, Upscale, Elegant, Vintage, Refined, Polished, Traditional',
        annual_dol: 6176.28,
        description:
            'Refined Chinese restaurant serving dim sum, noodles, seafood & meat plates, plus unique cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9844,
        total_orders: 674.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Steak, Chicken Pot Pie, Gluten Free, Coffee, Irish, Delicious, French, American, Friendly',
        annual_dol: 2757.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6738,
        store_longitude: -73.8396,
        total_orders: 2.61,
        neighborhood: 'Ozone Park/Jamaica',
        keywords: 'Wine, Comfortable, Friendly, Fun, Chinese, Awesome, Cool, Great, Thai, French',
        annual_dol: 10.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.686,
        store_longitude: -73.9943,
        total_orders: 0,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Vegetarian, Italian, Chicken Tikka Masala, Beer, Tasty, Indian, Spicy, Casual, Local, Perfect',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7114,
        store_longitude: -73.7907,
        total_orders: 28.76,
        neighborhood: 'Jamaica',
        keywords: 'Lobster, Salad, Pizza, Bbq, Bbq Ribs, Caribbean, Fine',
        annual_dol: 117.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9956,
        total_orders: 1611.17,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Luxurious, Chinese, Hotel, Delicious, Opulent, Japanese, Beer, Premium, Intimate',
        annual_dol: 6756.42,
        description:
            '"Located in the luxurious Chelsea area of Manhattan, Sushi By Bou Chelsea is a modern sushi speakeasy, boasting an intimate 9-seat omakase experience, hidden away behind a bespoke Espresso Bar and Floral Boutique. This high-end experience is timed, with 30-minute and 60-minute options available. Guests are invited to arrive early or stay late, and enjoy one of our craft cocktails, imported sakes, or Japanese beers, while admiring our luxe 1920\'s themed decor. Sushi By Bou Chelsea draws its design inspiration from The Roaring 20\'s, with opulent finishes, lavish surroundings, and classic speakeasy cocktails. Allow the gold detailing and crystal adornments to inspire a trip to a different era."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -74.0028,
        total_orders: 11.39,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Italian, Steak, Vodka, Beer, Dessert, Gin, Organic, Hotel, Bruschetta',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.714,
        store_longitude: -73.9488,
        total_orders: 1884.87,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Karaoke, Mimosa, Bloody Mary, Upscale, Exotic, Great, Chill, Casual',
        annual_dol: 1966.59,
        description:
            'Budget drinks come with free pizza as well as karaoke, pool & Skee-Ball at this no-frills bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7292,
        store_longitude: -73.981,
        total_orders: 2754.56,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Tacos, Tequila, Mexican, Burger, Steak, Beer, Margarita, Salad, Burritos, Fajitas',
        annual_dol: 2892.29,
        description:
            'Mexican cantina with standards like burritos & fajitas, plenty of tequila & a buzzy brunch scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -73.9777,
        total_orders: 1430.15,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Steak, Chicken Pot Pie, Vegetarian, Meatball, Mimosa, Gluten Free, Beer, Coffee, Ale',
        annual_dol: 1470.84,
        description:
            'Old-fashioned pub offering a wide variety of beers, plus lunch & dinner menus & a late bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7176,
        store_longitude: -73.9586,
        total_orders: 2909.58,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Tacos, Salad, Whiskey, Beer, Tasty, Ale, French, Creamy, American',
        annual_dol: 2971.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7659,
        store_longitude: -73.9695,
        total_orders: 5900.42,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Pizza, Wine, Champagne, Shrimp Cocktail, Spicy, Martini, Upscale, Premium, Woody, American',
        annual_dol: 6062.47,
        description:
            'Luxe cigar bar with leather chairs, food & cocktail menus & a strict dress code.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7081,
        store_longitude: -73.9401,
        total_orders: 2891.98,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Beer, Romantic, Warm, Quaint, French Toast, Karaoke, Rustic, French, Yummy, Homey',
        annual_dol: 3153.71,
        description:
            'Quaint, rustic pub with intricate wood decor offering microbrews, mixed drinks & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7276,
        store_longitude: -73.9544,
        total_orders: 225.85,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Casual, Irish, Polish, Great',
        annual_dol: 257.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8512,
        store_longitude: -73.7887,
        total_orders: 63078.85,
        neighborhood: 'City Island',
        keywords: 'Lobster, Pizza, Savory, Delicious, Nachos, Bbq, Fun, French, Awesome, American',
        annual_dol: 67856.86,
        description:
            'Spacious, family-friendly spot offering up arcade games, seafood dishes, drinks & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.681,
        store_longitude: -74.0089,
        total_orders: 256.07,
        neighborhood: 'Red Hook',
        keywords:
            'Tequila, Martini, Tasty, Cozy, Intimate, Friendly, Cool, Classic, Casual, Laid-Back',
        annual_dol: 291.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8536,
        store_longitude: -73.79,
        total_orders: 51.86,
        neighborhood: 'City Island',
        keywords: 'American, Local',
        annual_dol: 58.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.9893,
        total_orders: 13463.05,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tequila, Champagne, Japanese, Vodka, Stylish, Beer, Hotel, Romantic, Rum, Exotic',
        annual_dol: 17553.37,
        description:
            'Speakeasy-style bar in the East Village offering exotic cocktails in a cool, hard-to-find space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7321,
        store_longitude: -73.9846,
        total_orders: 2701.96,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Beer, Margarita, Cozy, Comfortable, Perfect, Fun, Casual, Great, Unpretentious, Diverse',
        annual_dol: 2759.29,
        description: 'Low-ceilinged gay bar with a subterranean feel, diverse beer menu & jukebox.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7236,
        store_longitude: -73.9884,
        total_orders: 1170.21,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Pizza, Italian, Wine, Beer, Meatball, Quaint, Cozy, Healthy, Friendly, Casual',
        annual_dol: 1275.34,
        description:
            'Old-school joint offering wood-fired pizza & other casual Italian eats, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7343,
        store_longitude: -73.9946,
        total_orders: 139.11,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'American, Vintage, Elegant, Fine, Cultural',
        annual_dol: 568.64,
        description:
            'The Salmagundi Club, sometimes referred to as the Salmagundi Art Club, is a fine arts center founded in 1871 in the Greenwich Village section of Manhattan, New York City. Since 1917, it has been located at 47 Fifth Avenue. As of 2021, its membership roster totals roughly 1,100 members.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8059,
        store_longitude: -73.9657,
        total_orders: 561.6,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Vegetarian, Hamburger, Organic, Korean, American, Stylish, Sustainable, Casual, Seasonal',
        annual_dol: 573.52,
        description:
            'Cafe with healthy emphasis on organic New American fare plus vegetarian choices & a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8002,
        store_longitude: -73.9683,
        total_orders: 2571.06,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Sushi, Wine, Beer, Cozy, Friendly, Casual, Fancy, Classic',
        annual_dol: 2655.49,
        description:
            'This laid-back haunt with a pool table & dart board boasts a happy hour that lasts for 7 hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5233,
        store_longitude: -74.2347,
        total_orders: 128.52,
        neighborhood: 'Charleston',
        keywords:
            'Vegetarian, Italian, Turkish, Lebanese, Syrian, Mediterranean, Shawarma, French, Traditional, Russian',
        annual_dol: 525.35,
        description:
            'Spacious Syrian restaurant serving a traditional menu with halal & vegetarian options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.7677,
        total_orders: 111.78,
        neighborhood: 'Bayside',
        keywords:
            'Salad, Italian, Mediterranean, Bbq, Greek, Upscale, Homemade, Relaxed, Casual, Seasonal',
        annual_dol: 133.53,
        description:
            'Relaxed eatery with a seasonal patio & frequent live music, offering seafood & Greek fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.587,
        store_longitude: -73.9525,
        total_orders: 216.98,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Sushi, Martini, Japanese, Spicy, Upscale, Beer, Asian, Sashimi, Fusion, Sweet',
        annual_dol: 886.92,
        description:
            'Artful Japanese fusion & sushi by a Kyoto-trained chef served in a sleek, modern space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.701,
        store_longitude: -73.8312,
        total_orders: 10.35,
        neighborhood: 'Kew Gardens/Jamaica/Richmond Hill',
        keywords: 'Unique',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8019,
        store_longitude: -73.6528,
        total_orders: 1178.93,
        neighborhood: '',
        keywords:
            'Hotel, Coffee, Mexican, Flavorful, American, Seasonal, Quaint, Contemporary, Fresh, Great',
        annual_dol: 1270.08,
        description:
            'Quaint rooms with floral decor offer free Wi-Fi, plus coffeemakers, marble bathrooms and TVs with in-room movies. Suites add boldly painted accent walls, canopy beds and sitting areas with fireplaces.Perks include a free hot breakfast buffet and all-day coffee in the lobby, and self-parking. The hotel also has a gym, a business center and a ballroom with 14-foot ceilings, European-inspired architecture and hand-painted walls murals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6826,
        store_longitude: -73.8361,
        total_orders: 16.26,
        neighborhood: 'Ozone Park/Jamaica/South Richmond Hill',
        keywords: 'Salad, Beer, Spicy, Clean, Bbq, Casual, Spanish, Excellent, Great, American',
        annual_dol: 66.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7574,
        store_longitude: -73.9742,
        total_orders: 141,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Tacos, Burritos, Salad, Delicious, Local, Casual',
        annual_dol: 732.71,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -73.9872,
        total_orders: 5550.95,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Pizza, Italian, Meatball, Wine, Bread Pudding, Beer, Coffee, Vodka',
        annual_dol: 5556.24,
        description:
            'Locally sourced, upscale American cuisine served in a barn-inspired setting with a chef’s table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9953,
        total_orders: 721.78,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Cozy, Friendly, Irish, Casual, Local, Dutch, Great',
        annual_dol: 737.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.982,
        total_orders: 2612.94,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, Upscale, Cosmopolitan, Stylish, Hotel, Elegant, Tequila, Coffee, Spacious, Innovative',
        annual_dol: 9339.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7548,
        store_longitude: -73.9857,
        total_orders: 935.37,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Korean, Asian, Premium, Vibrant, Healthy, American, Amazing, Classic, Great, Unique',
        annual_dol: 3276,
        description:
            'Vibrant nightclub hosting DJs, live music performances & special events with a Korean focus.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6946,
        store_longitude: -73.9831,
        total_orders: 137.73,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Burger, Pizza, Chicken Parmigiana, Vegan, Salad, Italian, Gluten Free, Beer, Wine, Bruschetta',
        annual_dol: 150.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9893,
        total_orders: 4719.15,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Kebab, Meatball, Beer, Spicy, Delicious, Wine, Mediterranean, Organic, Turkish',
        annual_dol: 4913.12,
        description:
            'Bi-level bistro in a former flower shop offering modern Mediterranean fare & a hookah menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.9916,
        total_orders: 1797.44,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Pizza, American, Hotel, Fresh, Perfect, Iconic, Classic, Lit, Local',
        annual_dol: 1835.58,
        description:
            'Laid-back, late-night hangout offering craft beers on tap, bar snacks, pinball machines & pool.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7749,
        store_longitude: -73.9086,
        total_orders: 7329.6,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Sushi, Tasty, Beer, Wine, Smooth, Fried Chicken, Friendly, Innovative, Casual, Modern',
        annual_dol: 7590.33,
        description:
            'Snug pub pairing craft beer & cocktails with innovative bar bites, plus DJ nights & Sunday brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7049,
        store_longitude: -73.6438,
        total_orders: 129.89,
        neighborhood: '',
        keywords: 'Sushi, Clean, Premium, Cool, Great',
        annual_dol: 530.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -73.9892,
        total_orders: 1605.89,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Beer, Donut, Seasonal, American, Delicious, Local, Crafted, Wonderful, Great',
        annual_dol: 1639.96,
        description:
            "Chef Tom Colicchio's refined, farm-fresh American fare served in an elegantly understated space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7303,
        store_longitude: -73.9944,
        total_orders: 527.55,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Lobster, Lobster Roll, Shrimp Cocktail, Salad, Cajun, Tequila, Margarita, Beer, Tasty, Vodka',
        annual_dol: 608.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7383,
        store_longitude: -73.9896,
        total_orders: 2869.1,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Vegan, Vegetarian, Mexican, Margarita, Organic, Italian, Delicious, Spicy, Tex-Mex, French',
        annual_dol: 4393.53,
        description:
            '"abc Cocina & Michelin star Chef Jean-Georges Vongerichten welcome you to our modern global exchange celebrating local craft and international culture, a fusion of tradition and innovation uniting yesterday and tomorrow. Dedicated to whole food and inventive fare, our ever-changing offerings are sourced from boutique producers with an emphasis on holistic development and environmental consciousness."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7989,
        store_longitude: -73.9629,
        total_orders: 4653.17,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Beer, Cosmopolitan, Gin, Fun, Welcoming, Friendly, Comfortable, Timeless, Fantastic, Great',
        annual_dol: 4751.9,
        description:
            'Local brews & craft cocktails offered in an industrial-chic watering hole with a welcoming vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7511,
        store_longitude: -73.9727,
        total_orders: 3073.62,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Salad, Dessert, Wine, Japanese, Beer, Fried Chicken, Sashimi, Homemade, Cozy',
        annual_dol: 3583.97,
        description:
            'Japanese small plates & a deep sake list in a blond-wood izakaya hidden beneath an office building.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7542,
        store_longitude: -73.973,
        total_orders: 3528.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Hamburger, Beer, Wine, Irish, American, Modern, Cozy, Casual, Comfortable',
        annual_dol: 3776.24,
        description:
            'Roomy, Irish-style tavern with many draft beers & classic pub fare, plus brunch on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.968,
        total_orders: 959.67,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Cajun, Salad, French, Beer, Homemade, Contemporary, Caesar Salad, Casual, Friendly, Local',
        annual_dol: 1414.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9716,
        total_orders: 1994.41,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Wine, Gluten Free, Hotel, Elegant, Vibrant, Classic, Great, Rooftop, Casual',
        annual_dol: 2043.64,
        description:
            'Italian restaurant in the Kimberly Hotel serving classic fare including many gluten-free options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9694,
        total_orders: 3379.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Tacos, Steak, Peruvian, Wine, Tasty, Upscale, Martini, Fajitas, Delicious',
        annual_dol: 4388.21,
        description:
            '"Mexican Peruvian Fusion restaurant offering a creative menu, complementing fresh ingredients with flavors from around the world. Chef Arias eclectic yet harmonious cuisine brings a dynamic perspective to classic techniques such as Birria Tacos, Causa Rellena & Peruvian Ceviche."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7572,
        store_longitude: -73.9685,
        total_orders: 292.18,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Thai, Vietnamese, Beer, Chinese, Dessert, Spicy, Ramen, Bbq, New Zealand, Asian',
        annual_dol: 322.6,
        description:
            'Sleek destination turning out Asian fusion BBQ & small plates with creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6166,
        store_longitude: -74.0311,
        total_orders: 626.27,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Wine, French, Tasty, Ale, Beer, French Onion Soup, Caesar Salad',
        annual_dol: 650.73,
        description:
            'Old-fashioned steakhouse serves prime cuts, pasta & sandwiches in a classy space with a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7149,
        store_longitude: -74.0109,
        total_orders: 11.39,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Hotel, Elegant, French, Intimate, Sweet, Contemporary, Casual, Great, Terrific',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9879,
        total_orders: 631.1,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Pizza, Italian, Phenomenal, Vintage, Southern, Classic, Perfect, Exotic, Amazing',
        annual_dol: 1008.16,
        description:
            'The Nederlander Theatre is a Broadway theater at 208 West 41st Street in the Theater District of Midtown Manhattan in New York City. Opened in 1921, it was designed by William Neil Smith for theatrical operator Walter C. Jordan.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7842,
        store_longitude: -73.7123,
        total_orders: 1681.57,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Chocolate Cake, Shrimp Cocktail, Chicago-Style, Lobster Bisque, Meatball, Salad, Wine, Upscale',
        annual_dol: 1721.71,
        description:
            'Upscale chain for aged prime beef, seafood & other traditional steakhouse fare in a clubby space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7034,
        store_longitude: -73.9917,
        total_orders: 7253.11,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Italian, Mediterranean, Rooftop, Cozy, American, Modern, Classic, Seasonal, Great, Incredible',
        annual_dol: 7510.06,
        description:
            '"Soho House\'s newest New York property, DUMBO House is located in Empire Stores on the Brooklyn waterfront and features panoramic views of the Brooklyn Bridge, East River and the Manhattan skyline. It has a full floor of club space with a library and restaurant, an outdoor terrace and a rooftop pool."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7501,
        store_longitude: -73.9862,
        total_orders: 1196.47,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Wine, Upscale, Rooftop, Fun, Beer, Colorful, Fantastic, Comfortable, Great',
        annual_dol: 1464.42,
        description:
            "The upscale rooms have marble bathrooms, flat-screen TVs, minifridges and free Wi-Fi. Upgraded rooms are set on higher floors and add workstations and coffeemakers. Room service is available.Free perks include access to a fitness center and continental breakfast. There's also a rooftop terrace with a seasonal bar, a lobby bar and cocktail lounge, and a colorful restaurant featuring milkshakes.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7508,
        store_longitude: -73.9897,
        total_orders: 740.41,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Beer, American, Wine, Delicious, Bbq, Modern, Perfect, Comfortable, Irish',
        annual_dol: 761.27,
        description:
            'Burgers, beer & other refreshments power this casual spot at the Herald Square department store.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7528,
        store_longitude: -74.0009,
        total_orders: 289.99,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Korean, American, Modern, Western, Casual, Great',
        annual_dol: 1185.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7532,
        store_longitude: -73.9967,
        total_orders: 803.82,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Irish, Cozy, Friendly, Classic, Local, Casual, Impressive, Great',
        annual_dol: 915.5,
        description:
            "Cozy, dark-wood Irish pub over 2 levels with a broad comfort-food menu, including shepherd's pie.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.778,
        store_longitude: -73.9805,
        total_orders: 1329.11,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Beer, Vintage, Fabulous, Classic, Amazing, Great, Perfect',
        annual_dol: 1412.67,
        description:
            "The Triad Theater, formerly known as Palsson's Supper Club, Steve McGraw's, and Stage 72, is a cabaret-style performing arts venue located on West 72nd Street on New York's Upper West Side.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.776,
        store_longitude: -73.9818,
        total_orders: 10.35,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Beer, Sushi, Salad, Martini, Coffee, Italian, Delicious, Seasonal, Tandoori Chicken',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7307,
        store_longitude: -74.0001,
        total_orders: 2889.76,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesecake, Salad, Burger, Dessert, Wine, Champagne, Beer, Nachos, Funky, French',
        annual_dol: 2975.78,
        description:
            'House bands back up visiting performers at this nightlife outlet for live funk, soul & R&B.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7496,
        store_longitude: -73.9901,
        total_orders: 265.87,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Iconic, Premium',
        annual_dol: 502.13,
        description: '"Digital art museum, event venue and bar"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -73.999,
        total_orders: 1210.07,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Italian, Sushi, Cozy, Traditional, Classic, Authentic, Unforgettable, Fine',
        annual_dol: 1262.53,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7291,
        store_longitude: -73.9989,
        total_orders: 1286.03,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cuban, Steak, Coffee, Rum, Caribbean, Spanish, Delicious, Romantic, Swiss, Mojito',
        annual_dol: 1966.15,
        description:
            'Classic dishes such as roast pig & ropa vieja are boosted by live son music & hand-rolled cigars.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7291,
        store_longitude: -74.0003,
        total_orders: 23.5,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chocolate Cake, Pizza, Gluten Free, Steak, Italian, Dessert, Wine, Coffee, Savory, Beer',
        annual_dol: 44.05,
        description:
            'Gluten-free versions of classic Italian dishes offered in a warm, intimate atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 1999.7,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Sushi, Wine, Stylish, Japanese, Elegant, Asian, Sweet, Legendary, Friendly, Premium',
        annual_dol: 2042.13,
        description:
            'Japanese restaurant offering a high-end fixed-price sushi menu in modern, elegant surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.9848,
        total_orders: 8025.32,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Thai, Perfect, Greek, Awesome, Iconic, Great, Unforgettable',
        annual_dol: 10330.19,
        description:
            'The Gershwin Theatre is a Broadway theater at 222 West 51st Street, on the second floor of the Paramount Plaza office building, in the Midtown Manhattan neighborhood of New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.9769,
        total_orders: 3017.54,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Chocolate Cake, Shrimp Cocktail, Meatball, Spicy, Upscale, Legendary, American, Classic, Traditional',
        annual_dol: 12635.6,
        description:
            'Upscale former speakeasy serving classic American fare in an old New York setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7227,
        store_longitude: -73.9918,
        total_orders: 14499.55,
        neighborhood: 'Bowery',
        keywords:
            'Hotel, Trendy, Vodka, Peruvian, Sophisticated, Chic, Rooftop, Great, Authentic, Modern',
        annual_dol: 16156.34,
        description:
            'Minimalist rooms feature floor-to-ceiling windows and wood floors, and offer smart TVs, Wi-Fi, USB ports and Bluetooth speakers. Upgraded rooms have city views and pull-out sofas, while suites add separate living and dining areas.Freebies include wellness classes, cookies and drinks. There are 2 sophisticated restaurants by chef Jean-Georges Vongerichten, plus a trendy rooftop bar with skyline views, and a chic lobby lounge. There’s also a casual cafe and market, and dramatic event spaces.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7202,
        store_longitude: -73.9875,
        total_orders: 2324.61,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Steak, Cornbread, Pizza, Salad, Dessert, Upscale, American, Caesar Salad, Australian',
        annual_dol: 2448,
        description:
            'Hearty, New American comfort food in a vintage setting complete with a DJ & bustling bar scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7646,
        store_longitude: -73.9825,
        total_orders: 2.82,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Applejack, Cheesecake, Wine, Beer, French Toast, French, American, Delicious, Omelette, Fresh',
        annual_dol: 11.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7583,
        store_longitude: -73.9715,
        total_orders: 82.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Pizza, Delicious, French, Hotel, Rum, Modern, Contemporary, Serene, Classic',
        annual_dol: 335.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.643,
        store_longitude: -73.5744,
        total_orders: 5.09,
        neighborhood: '',
        keywords: 'Mexican, Beer, Wine, Spanish, Dessert, Dominican, Cozy, Sweet, Fun, Sangria',
        annual_dol: 20.81,
        description:
            '"Spanish Tapas Bar and Restaurant in Long Island. Specializing in paellas, tapas, and charcuterie boards accompanied with an extensive wine and cocktail list. Outdoor seating are with an outdoor bar located on a marina. Perfect for summer"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7426,
        store_longitude: -74.0006,
        total_orders: 2353.96,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Beer, Friendly, Warm, Welcoming, Casual, Great, Chill, Unpretentious',
        annual_dol: 2809.3,
        description:
            'Gay bar with many TVs tuned to sports, plus a pool table & a basement lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -74.002,
        total_orders: 1672,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Beer, Juicy, Upscale, Fun, Legendary, Premium, Wonderful, American, Great',
        annual_dol: 1707.48,
        description:
            'Lounge/bowling alley has a clublike vibe with posh decor, bar, menu of upscale American classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.543,
        store_longitude: -74.1641,
        total_orders: 957.76,
        neighborhood: 'Eltingville',
        keywords:
            'Pizza, Italian, Vegetarian, Delicious, Upscale, Fresh, Friendly, Terrific, Authentic, Casual',
        annual_dol: 1056.2,
        description:
            'Coal-fired pizza shares the menu with baked sandwiches & salads at this brick-walled Italian spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -73.9425,
        total_orders: 1593.85,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Wine, Trendy, Delicious, Cozy, Italian, Rustic, Spacious, Fun, Hospitable',
        annual_dol: 1734.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 7968.42,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Rooftop, Wine, Salad, Amazing, Martini, Romantic, Incredible, Fresh, Great, Intimate',
        annual_dol: 25856.91,
        description:
            '"Pier 17 features unmatched views of the Brooklyn Bridge, Statue of Liberty, and the city skyline."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7076,
        store_longitude: -74.0018,
        total_orders: 509.61,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Salad, Pizza, Tasty, Wine, Italian, French, French Onion Soup, Stylish, Rich',
        annual_dol: 555.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9821,
        total_orders: 8776.55,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Wine, Upscale, Steak, Coffee, Classy, Romantic, Cozy, Gin, Classic, Intimate',
        annual_dol: 11012.37,
        description:
            'Richly furnished setting for hard-to-find spirits, raw-bar items, live jazz & classic American food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9256,
        total_orders: 454.08,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Mexican, Beer, Salad, Jamaican, American, Local, Signature, Chill, Great',
        annual_dol: 483.92,
        description:
            'Chill local pub offering various craft beers on tap plus trivia nights, karaoke, live music & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9294,
        total_orders: 265.18,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Tacos, Salad, Kebab, Mexican, Tequila, Dessert, Bbq, Latin, Fresh',
        annual_dol: 333.04,
        description: '"Mexican Bar Restaurant in Astoria, NY"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9781,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Salad, Tacos, Coffee, Vietnamese, Ale, Chinese, Thai, Rum, Seasonal, Pho',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7653,
        store_longitude: -73.9778,
        total_orders: 539.85,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Champagne, Coffee, Delicious, Impeccable, Refined, Healthy, Mediterranean, Greek, Classy',
        annual_dol: 575.74,
        description:
            'Traditional Greek seafood & other classics in refined preparations served in sleek, minimalist digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7619,
        store_longitude: -73.9866,
        total_orders: 2216.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Beer, Flavorful, Tasty, Turkish, Delicious, Nachos, Friendly, Refreshing, American, Handcrafted',
        annual_dol: 2263.76,
        description:
            'Happening tavern drawing a pre-theater crowd for its selection of draft beers & elevated pub fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9924,
        total_orders: 825.64,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Fabulous, Irish, Great, Incredible, Casual',
        annual_dol: 868.47,
        description:
            '"NYCs newest LGBTQIA Bar, events ranging from Jazz to drag to dance parties, Happy Hour Daily 4pm-7pm,"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9887,
        total_orders: 2232.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Mexican, Beer, Warm, American, Spacious, Rooftop, Awesome, Comfortable, Amazing',
        annual_dol: 5212.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -73.9926,
        total_orders: 180.45,
        neighborhood: 'Bowery',
        keywords: 'Vegan, Burger, Vegetarian, Pizza, Sushi, Salad, Gourmet, Beer, Wine, Mexican',
        annual_dol: 873.38,
        description:
            'Easygoing outfit offering a creative menu of plant-based dishes, plus craft ales & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -73.9905,
        total_orders: 118.04,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Coffee, Vegan, Wine, Sushi, Vegetarian, Salad, Beer, Spicy, Cozy, Friendly',
        annual_dol: 321.39,
        description:
            'Comfy counter-serve outpost offering coffee drinks, pastries, light fare & sandwiches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9686,
        total_orders: 129.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Tequila, Steak, Margarita, Delicious, Impeccable, American, Fun, Lively, Authentic',
        annual_dol: 250.13,
        description:
            'Modern Mexican chain eatery known for tableside guacamole, creative margaritas & chic surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9858,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Hotel, Classic, Modern',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7023,
        store_longitude: -73.811,
        total_orders: 4.55,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Steak, Tasty, Hawaiian, American, Latin, Casual, Sweet',
        annual_dol: 18.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.82,
        store_longitude: -73.9586,
        total_orders: 53.69,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Rum, Jamaican, Caribbean, American, Sweet, Ample, Great, Perfect, Casual',
        annual_dol: 219.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.9062,
        store_longitude: -73.9041,
        total_orders: 5.36,
        neighborhood: 'North Riverdale',
        keywords:
            'Steak, Wine, Seafood Paella, Beer, Coffee, Latin, Margarita, American, Elegant, Mojito',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7379,
        store_longitude: -74.0017,
        total_orders: 1125.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tequila, Ale, Homey, Lively, Classic, Fantastic, Great, Colorful, Casual, Chill',
        annual_dol: 1174.54,
        description:
            'Neighborhood dive bar with daily shot specials & jukebox, bumper stickers & film-truck permits.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5402,
        store_longitude: -74.177,
        total_orders: 303.85,
        neighborhood: 'Annadale',
        keywords: 'Salad, Chicken Parmigiana, Italian, Homemade, Casual, Fresh, Fine',
        annual_dol: 409.72,
        description:
            '"La Fontana has been serving Staten Island fine Italian food since 1983. We are open for dinner 7 days a week with nightly specials. We host all type of afternoon gatherings, up to 70 guests, by reservation only. You can host any type of special occasions, celebration and even bereavement lunches. Our Pre Fixe special is available Monday - Thursday as well."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7349,
        store_longitude: -73.9999,
        total_orders: 320.56,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Italian, Beer, Dessert, Warm, Spacious, Rooftop, Fresh, Seasonal, Great',
        annual_dol: 826.04,
        description:
            'Airy hot spot with a rooftop farm for seasonal Italian dishes, house-made pastas & notable wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7225,
        store_longitude: -73.957,
        total_orders: 13270.39,
        neighborhood: 'Greenpoint',
        keywords:
            'Lobster, Pizza, Italian, Wine, Spicy, Delicious, Hotel, Romantic, Southern, Spacious',
        annual_dol: 13777.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7181,
        store_longitude: -73.96,
        total_orders: 785.1,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Salad, Pizza, Thai, Japanese, Green Curry, Wine, Spicy, Beer, Savory',
        annual_dol: 855.64,
        description:
            'Familiar Thai standards accompanied by pulsing music, flashy decor & even a pond.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.741,
        store_longitude: -73.9814,
        total_orders: 340.9,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Vegan, Vegetarian, Pizza, Steak, Salad, Gluten Free, Wine, Italian, Dessert, Vodka',
        annual_dol: 348.13,
        description:
            'Intimate locale furnishing familiar Italian cuisine, including vegan, kosher & gluten-free dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.9278,
        total_orders: 340.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Garlic Bread, Salad, Italian, Pizza, Wine, Cozy, Rustic, Delicious, Bruschetta, Prosecco',
        annual_dol: 397.98,
        description:
            'Cosy nook whipping up traditional Roman specialties, plus wines, cocktails & patio seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7734,
        store_longitude: -73.9895,
        total_orders: 24.57,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Pizza, Salad, Meatball, Gluten Free, Juicy, Hotel, Casual, Clean, Fresh, Local',
        annual_dol: 100.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7733,
        store_longitude: -73.9832,
        total_orders: 1146.38,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Wine, Salad, Coffee, Italian, Delicious, Belgian, Clean, Fresh, Fine, Dynamic',
        annual_dol: 4024.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9872,
        total_orders: 3804.74,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tasty, Wine, Beer, Japanese, Meatball, Polished, Authentic, Outstanding, Casual, Sophisticated',
        annual_dol: 4281.41,
        description:
            "Sophisticated Japanese eatery offering charcoal-grilled skewers & sake, plus a chef's-choice option.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7638,
        store_longitude: -73.9889,
        total_orders: 35.19,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Kebab, Burger, Coffee, Greek, Tangy, American, Georgian, Delicious, Tzatziki, Local',
        annual_dol: 143.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8149,
        store_longitude: -73.9443,
        total_orders: 278.4,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Beer, Tasty, American, French, Jamaican, Cool, Great, Casual',
        annual_dol: 901.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.9898,
        total_orders: 95.16,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Tasty, Thai, Spicy, Delicious, Green Curry, Asian, Fried Rice, Modern, Casual',
        annual_dol: 388.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8647,
        store_longitude: -73.9285,
        total_orders: 287.12,
        neighborhood: 'Fort George',
        keywords: 'Steak, Tacos, Mexican, Wine, Bbq, American, Delicious, Latin, Classy, Fusion',
        annual_dol: 293.21,
        description:
            'Latin American fare from tacos to empanadas, served in a bright, rustic-chic space with live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7394,
        store_longitude: -74.0014,
        total_orders: 762.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Cosmopolitan, Rich, Elegant, Legendary, Fun, Sweet, British, Great, Authentic, Cool',
        annual_dol: 1482.49,
        description:
            '"Norwood is a private members only club for professionals & patrons of the creative arts."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6773,
        store_longitude: -73.9554,
        total_orders: 149.47,
        neighborhood: 'Crown Heights',
        keywords:
            'Lobster, Lobster Roll, Tacos, Cornbread, Vegan, Gluten Free, Margarita, Spicy, Delicious, Juicy',
        annual_dol: 178.98,
        description:
            'Small joint dishing up crab in full plates with sides, as well as in rolls, tacos & sliders.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6677,
        total_orders: 1088.55,
        neighborhood: '',
        keywords: 'Steak, Wine, Beer, Gourmet, American, Tasty, Modern, Fresh, Great, Excellent',
        annual_dol: 1111.65,
        description:
            'American fare like gourmet burgers & seafood served in a sizable space with a patio & sky-lit bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5841,
        store_longitude: -73.6871,
        total_orders: 1562.66,
        neighborhood: '',
        keywords: 'Burger, Lobster, Salad, Sushi, Hamburger, Pizza, Flavorful, Tasty, Nachos, Wine',
        annual_dol: 2225.21,
        description: '"Eclectic seafood"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9803,
        total_orders: 381.5,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Italian, Wine, Pizza, Delicious, Gin, Homemade, Authentic, Casual, Classic, Fresh',
        annual_dol: 389.6,
        description:
            "Cuisine from northern Italy's Emilia region inspires the menu at this rustic, patio-equipped eatery.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7283,
        store_longitude: -73.9821,
        total_orders: 1112.01,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Rich, Eclectic, Intimate, Cultural',
        annual_dol: 1135.6,
        description:
            'Intimate, multi-bar locale featuring nightly live music, plus dance parties & comedy nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7177,
        store_longitude: -73.9581,
        total_orders: 92.99,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Irish, Bourbon, Fun, Hip, Seasonal, Quirky, Lit, Local, Casual',
        annual_dol: 380.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7116,
        store_longitude: -73.943,
        total_orders: 293.57,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Pizza, Tasty, Beer, Relaxed, Friendly, Great, Casual, Diverse',
        annual_dol: 1200,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 1348.74,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Upscale, Elegant, Dessert, Savory, Steak, Spacious, Wholesome, Italian, Ale',
        annual_dol: 1377.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7176,
        store_longitude: -73.9582,
        total_orders: 45.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Vegetarian, Hamburger, Salad, Coffee, Polish, American, Greek, Casual, Healthy',
        annual_dol: 184.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7423,
        store_longitude: -73.9829,
        total_orders: 39.54,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vegetarian, Kebab, Chicken Tikka Masala, Chinese, Martini, Spicy, Indian, Delicious, Casual, Paneer Tikka',
        annual_dol: 161.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8359,
        store_longitude: -73.8331,
        total_orders: 480.13,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords:
            'Wine, Beer, Coffee, Salad, Cuban, Delicious, Caribbean, Organic, Dominican, Latin',
        annual_dol: 490.31,
        description:
            'Festive restaurant offering contemporary Latin & Dominican cuisine plus cocktails & sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6561,
        store_longitude: -73.6108,
        total_orders: 731.38,
        neighborhood: '',
        keywords: 'Wine, Beer, Dessert, American, Lively, Classic, Casual, Amazing, Great, Local',
        annual_dol: 937.67,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7352,
        store_longitude: -73.6605,
        total_orders: 457.01,
        neighborhood: '',
        keywords: 'Burger, Pizza, Beer, Wine, Irish, German, American, Traditional, Awesome, Great',
        annual_dol: 487.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6109,
        store_longitude: -73.9789,
        total_orders: 551.01,
        neighborhood: 'Bensonhurst',
        keywords:
            'Salad, Chicken Parmigiana, Shrimp Cocktail, Italian, Wine, Savory, Comfortable, Casual, Sangria, Authentic',
        annual_dol: 562.7,
        description:
            'Old-school Italian eatery & bar prepping classic Neapolitan cuisine & housemade sangria since 1930.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6298,
        store_longitude: -74.1096,
        total_orders: 143.14,
        neighborhood: 'West Brighton',
        keywords: 'Italian, Pizza, Asian, Homemade, Classy, Authentic, Casual, Fresh, Great',
        annual_dol: 585.1,
        description:
            '"Meatballs have been our signature, staple dish — it was kind of the spark behind the original location. We’ll have home-made pasta like fettuccine with prosciutto. We’ll have a homemade black and white fettuccine with squid ink, mussels, clams, shrimp and calamari in a fresh tomato sauce."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7418,
        store_longitude: -73.6618,
        total_orders: 2223.54,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Lobster Tail, Pizza, Mimosa, Dessert, Margarita, Wine, Spicy, Bellini',
        annual_dol: 2545.01,
        description:
            'Bar & grill serving creative American comfort fare in a relaxed setting with big-screen TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7673,
        store_longitude: -73.9213,
        total_orders: 1678.29,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Beer, Irish, American, Local, Friendly, Casual, Great',
        annual_dol: 1826.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7096,
        store_longitude: -73.8986,
        total_orders: 79.82,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Lasagna, Steak, Burger, Wine, Pizza, Salad, Vodka, Italian, Velvety, Delicious',
        annual_dol: 205.69,
        description:
            '"Rustico offers a wide range of both traditional and non-traditional Italian dishes; crisp-crusted, hand-tossed pizzas, topped with fresh velvety mozzarella and basil; fresh salmon, beautifully seasoned and grilled to taste – Rustico offers a thoughtfully-prepared dish for any palate."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7435,
        store_longitude: -73.6928,
        total_orders: 89.67,
        neighborhood: '',
        keywords: 'Burger, Beer, Coffee, Wine, Delicious, Ale, Healthy, American, Great, Casual',
        annual_dol: 231.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6937,
        store_longitude: -73.9458,
        total_orders: 264.5,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Wine, Tasty, Coffee, Delicious, Friendly, Natural, American, Fabulous, Traditional, Great',
        annual_dol: 1081.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6959,
        store_longitude: -73.9334,
        total_orders: 4028.19,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords:
            'Friendly, Perfect, Vibrant, Ample, Great, Fine, Local, Pinacolada, Casual, Signature',
        annual_dol: 4113.66,
        description:
            'Straightforward hangout featuring tropical-themed drinks & ample outdoor patio space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7368,
        store_longitude: -73.9552,
        total_orders: 292.31,
        neighborhood: 'Greenpoint',
        keywords: 'Wine, Upscale, Fun, Bbq, Awesome, Classy, Latin, Great, Lit, Coleslaw',
        annual_dol: 954.33,
        description:
            "Darkly lit bar & music venue on Greenpoint's northern end hosting metal bands & their enthusiasts.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.575,
        store_longitude: -73.9853,
        total_orders: 107.41,
        neighborhood: 'Coney Island',
        keywords: 'Hotdog, Wine, Fun, Fabulous, Picturesque',
        annual_dol: 530.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7464,
        store_longitude: -73.6171,
        total_orders: 907.72,
        neighborhood: '',
        keywords:
            'Salad, Vegetarian, Wine, Dessert, Elegant, Delicious, Cozy, Rustic, Impeccable, Warm',
        annual_dol: 1113.56,
        description:
            'Located in a strip mall, this bistro serves inventive entrees & cocktails plus fixed-price specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6556,
        store_longitude: -73.6458,
        total_orders: 445.71,
        neighborhood: '',
        keywords: 'Whiskey, Wine, Italian, Hamburger, Premium, American, Great',
        annual_dol: 1093.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7116,
        store_longitude: -73.5565,
        total_orders: 36.16,
        neighborhood: '',
        keywords:
            'Whiskey, Tasty, Gluten Free, Comfortable, Homey, Traditional, Great, Casual, Fantastic',
        annual_dol: 147.8,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7122,
        store_longitude: -73.7309,
        total_orders: 12.16,
        neighborhood: 'Jamaica/Queens Village',
        keywords: 'Burger, Salad, Hamburger, Italian, Coffee, Beer, Bbq, Creamy, French, Greek',
        annual_dol: 37.24,
        description:
            '"Neighborhood bar walking distance to the new UBS Arena, home of the New York Islanders. Great drinks and Great times."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7755,
        store_longitude: -73.9118,
        total_orders: 348.81,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Mexican, Tequila, Beer, Hamburger, Salad, Wine, Dessert, Upscale, Spicy, Latin',
        annual_dol: 420.69,
        description:
            'Margaritas & Southwestern entrees in a spacious, adobe-style space with an outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7205,
        store_longitude: -73.9952,
        total_orders: 75.57,
        neighborhood: 'Nolita',
        keywords:
            'Salad, Vegetarian, Wine, Mexican, French Toast, French, Organic, Korean, Japanese, Mediterranean',
        annual_dol: 97.45,
        description:
            'Cafe focusing on everything egg, including creative sandwiches & fancy Benedicts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -74.0016,
        total_orders: 70.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Wine, Italian, Relaxed, Fun, Casual, Modern, Playful, Traditional, Classic',
        annual_dol: 296.71,
        description:
            'Relaxed seafood eatery offering hearty mains, small plates & classic cocktails, plus a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.9975,
        total_orders: 59.16,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Garlic Bread, Wine, Beer, Irish, Roast Beef, Hotel, Friendly, Traditional, Perfect, Casual',
        annual_dol: 241.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9972,
        total_orders: 164.67,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Classy, Casual, Fine, Great',
        annual_dol: 673.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7705,
        store_longitude: -73.9902,
        total_orders: 760.08,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Pizza, Italian, Hotel, Coffee, Beer, Cozy, Premium, Healthy, American',
        annual_dol: 776.21,
        description:
            '"Fancy a bite to eat or thirsting for a drink? Bar 58 has bar bites, cocktails, wine, beer, and more. Happy Hour daily 5pm-7pm. Cozy Hotel Bar with amazingly friendly staff."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7661,
        store_longitude: -73.9799,
        total_orders: 2956.78,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hamburger, Beer, Wine, Coffee, Ale, Bbq, Delicious, Irish, American, Legendary',
        annual_dol: 3019.52,
        description:
            'Bustling, historic & traditional Irish watering hole with a diverse crowd & a an old-world vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.9894,
        total_orders: 987.95,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Classy, Vibrant, Great, Traditional, Signature, Unique, Casual',
        annual_dol: 4044.69,
        description:
            '"VERS is a new kind of gay bar serving craft cocktails, elevated bar snacks, and unique entertainment in a beautifully designed space in the Hell\'s Kitchen."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6766,
        store_longitude: -73.8233,
        total_orders: 19.37,
        neighborhood: 'South Ozone Park',
        keywords: 'Creamy, Bbq, Friendly, Homemade, Casual, Fresh',
        annual_dol: 79.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -73.9979,
        total_orders: 220.47,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Coffee, Sweet, Fun, Bright, Hotel, Natural, Signature',
        annual_dol: 635.02,
        description:
            'Museum of Ice Cream is a company that develops and operates interactive retail experiences, or "selfie museums", in the United States and Singapore. These exhibits, typically hosted in storefronts, are ice-cream and candy-themed, with bright colors.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7442,
        store_longitude: -73.9942,
        total_orders: 2114.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Beer, Wine, American, Vintage, Classic, Fun, Sweet, Great, Local',
        annual_dol: 2114.98,
        description:
            'A spin-off of a Williamsburg bar, this hang has vintage video games (Pac-Man, Donkey Kong) & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7097,
        store_longitude: -74.0094,
        total_orders: 3400.62,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Whiskey, Beer, Burger, Steak, Wine, Ale, Tasty, Martini, Upscale, American',
        annual_dol: 3507.97,
        description:
            'Craft beer, classic cocktails & American fare served in a swanky, industrial-chic tavern.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -73.9842,
        total_orders: 1440.46,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegan, Vegetarian, Delicious, Warm, Spicy, Trendy, Coffee, Cozy, Painkiller, Rustic',
        annual_dol: 1482.75,
        description:
            'Rustic-chic spot for inventive bar food, craft cocktails & live music, plus monthly comedy nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.674,
        store_longitude: -73.9632,
        total_orders: 199.62,
        neighborhood: 'Prospect Heights',
        keywords: 'Pizza, Tequila, Italian, Salad, Beer, Wine, Tasty, Cozy, Classy, Romantic',
        annual_dol: 212.73,
        description:
            'Elevated pizzas, salads, charcuterie & cocktails served in a low-lit space with a big, leafy patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6829,
        store_longitude: -73.9612,
        total_orders: 30.85,
        neighborhood: 'Clinton Hill',
        keywords:
            'Steak, Flavorful, Wine, Delicious, Rustic, French, American, Outstanding, Amazing, Great',
        annual_dol: 126.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7111,
        store_longitude: -73.9517,
        total_orders: 264.72,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Hamburger, Salad, Tequila, Beer, Bbq, Vodka, Juicy, French, American',
        annual_dol: 479.91,
        description:
            'Casual neighborhood brasserie serving local craft beers, noteworthy burgers, sandwiches & sides.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7238,
        store_longitude: -73.9788,
        total_orders: 147.22,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster, Salad, Austrian, French Toast, Beer, Caesar Wrap, French, Stylish, Caesar Salad, Asian',
        annual_dol: 601.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7417,
        store_longitude: -73.9825,
        total_orders: 179.07,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Beer, Cozy, Elegant, Quaint, Hotel, Perfect, Casual, Incredible',
        annual_dol: 731.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -73.9599,
        total_orders: 1331.84,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Wine, Friendly, Perfect, Cool, Awesome, American, Great, Casual, Local',
        annual_dol: 2335.02,
        description:
            'Airy, old-school joint luring locals with a chill vibe, happy-hour deals, billiards & pinball.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7183,
        store_longitude: -73.9576,
        total_orders: 928.56,
        neighborhood: 'Williamsburg',
        keywords:
            'Garlic Bread, Burger, Hamburger, Salad, Meatball, Italian, Dessert, Wine, Beer, Vodka',
        annual_dol: 968.82,
        description:
            'Branch of a hip, ingredient-focused local chain offering inventive meatball sandwiches & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6404,
        store_longitude: -73.6632,
        total_orders: 99.61,
        neighborhood: '',
        keywords: 'American',
        annual_dol: 407.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7039,
        store_longitude: -74.0129,
        total_orders: 206.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, American, Modern, Iconic, Perfect, Great, Casual',
        annual_dol: 944.12,
        description:
            'minute stroll from One World Trade Center.Warm rooms include custom-designed beds, 49-inch flat-screen TVs and complimentary Wi-Fi. Suites add separate living areas. Some rooms and suites offer river or city views.A casual restaurant serves a breakfast buffet and all-day a la carte meals. Additional amenities include a gym, a meeting room and a 24-hour business center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -73.8445,
        total_orders: 1009.37,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Tacos, Beer, New-York-Style, Bbq, Smoky, Southern, Comfortable, Local, Traditional, Great',
        annual_dol: 3543.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.9543,
        total_orders: 150.78,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Pizza, Italian, Salad, Gourmet, Wine, Delicious, Rustic, Homemade, Fun, Friendly',
        annual_dol: 157.32,
        description:
            'Gourmet wood-fired pizzas, wine & espresso served in a snug, rustic space with sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7722,
        store_longitude: -73.9557,
        total_orders: 1069.93,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Vegan, Pizza, Gluten Free, Salad, Wine, Italian, Gourmet, Beer, Delicious, Warm',
        annual_dol: 2494.27,
        description:
            '"Uva Next Door is Uva\'s little sister restaurant. A cocktail-forward, lively establishment serving Italian favorites. From pizza, to pasta, to unexpected delights!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8274,
        store_longitude: -73.9257,
        total_orders: 2282.37,
        neighborhood: 'Concourse',
        keywords:
            'Cheesecake, Salad, Wine, Coffee, Beer, Roast Beef, German, Legendary, American, Southern',
        annual_dol: 9421.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9609,
        total_orders: 622.64,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Wine, Coffee, Upscale, Spicy, Avocado Toast, Delicious, American, Eclectic, Sleek',
        annual_dol: 1178.95,
        description:
            'Swanky bar with a speakeasy vibe, offering creative cocktails, eclectic bar eats, DJs & cigarettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -73.9563,
        total_orders: 3340.61,
        neighborhood: 'Greenpoint',
        keywords: 'Salad, Spicy, Stylish, Mediterranean, Italian, Rustic, Cool, Fresh, Casual, Hip',
        annual_dol: 4105.48,
        description:
            'Mediterranean fare & cocktails served in a former glass factory with rustic-cool decor, bar & patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7659,
        store_longitude: -73.9716,
        total_orders: 1099.93,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Hotel, Legendary, Bright, Vibrant, Rooftop, Friendly, Creative, Fine',
        annual_dol: 1123.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7389,
        store_longitude: -73.9896,
        total_orders: 141.21,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Dessert, French, Delicious, Modern, American, Fresh, Seasonal, Classic, Wonderful',
        annual_dol: 495.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.653,
        store_longitude: -73.9759,
        total_orders: 277.87,
        neighborhood: 'Windsor Terrace',
        keywords:
            'Burger, Lobster, Lobster Roll, Pizza, Salad, Italian, Dessert, French Onion Soup, Wine, Beer',
        annual_dol: 616.62,
        description:
            'French cuisine & pizza from a brick oven in a casual, wood-accented space, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9802,
        total_orders: 20894.56,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotdog, Beer, Legendary, American, Spectacular, Great',
        annual_dol: 25594.98,
        description:
            'Radio City Music Hall is an entertainment venue and theater at 1260 Avenue of the Americas, within Rockefeller Center, in the Midtown Manhattan neighborhood of New York City. Nicknamed "The Showplace of the Nation", it is the headquarters for the Rockettes, the precision dance company.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.8299,
        total_orders: 2954.24,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Japanese, Sushi, Bbq, Korean, Beer, Asian, Spicy, Ramen, Sukiyaki, Gyoza',
        annual_dol: 3569.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.8335,
        total_orders: 576.04,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Vegetarian, Beer, Savory, Trendy, Delicious, Luxurious, Spacious, Smooth, Karaoke, Perfect',
        annual_dol: 2354.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9775,
        total_orders: 2343.1,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Beer, Juicy, Delicious, American, Fun, Sweet, Friendly, Coleslaw',
        annual_dol: 2444.69,
        description:
            'Big, bustling burger spot also known for disco fries (with gravy & cheese), beer & alcoholic shakes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6643,
        store_longitude: -73.698,
        total_orders: 88.4,
        neighborhood: '',
        keywords:
            'Burger, Salad, Upscale, Italian, Relaxed, Hotel, Delicious, Warm, Friendly, Casual',
        annual_dol: 240.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6219,
        store_longitude: -74.0319,
        total_orders: 1212.95,
        neighborhood: 'Fort Hamilton',
        keywords: 'Steak, Lobster, Salad, Shrimp Cocktail, Coffee, Wine, Dessert, Beer, Ale, Bbq',
        annual_dol: 1241.85,
        description:
            'Long-standing eatery with a cozy, nostalgic feel serving classic American dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6292,
        store_longitude: -74.0226,
        total_orders: 688.04,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Pizza, Beer, Salad, Tasty, Vodka, Savory, Chicken Wings, Bourbon, American',
        annual_dol: 796.83,
        description:
            'Unfussy watering hole boasting standard bar bites, a large beer list, DJ nights & lots of TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.9752,
        total_orders: 12725.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Luxurious, Champagne, Upscale, Hotel, Opulent, Spectacular, Iconic, Irresistible, Sophisticated, Amazing',
        annual_dol: 14063.87,
        description:
            "The plush rooms provide free Wi-Fi and flat-screen TVs, along with luxe linens and designer toiletries. Suites add furnished terraces with sweeping Manhattan views and dining tables. Room service is available.There's a French-influenced bakery and a wood-paneled bar, plus an upscale eatery and a cocktail lounge. Other amenities include a spa and a fitness center with steam rooms.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7129,
        store_longitude: -74.0097,
        total_orders: 854.82,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Champagne, Hotel, Beer, Warm, Cozy, Polished, Lively, Iconic, Great',
        annual_dol: 1076.28,
        description:
            'The chic rooms and suites feature free Wi-Fi, smart TVs, Nespresso machines and Bose speakers. All come with bars, living areas, and marble bathrooms with glass-enclosed showers. Some have terraces. Room service is available 24/7.A polished steak restaurant/bar by renowned chef Wolfgang Puck is on-site. Breakfast is extra. Other amenities include a heated indoor lap pool and a posh spa. A gym and a business center are open 24 hours. Valet parking is available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9748,
        total_orders: 709.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Tasty, Wine, Clean, Sashimi, Upscale, Japanese, Authentic, Traditional, Casual',
        annual_dol: 807.55,
        description:
            'Sushi & sashimi served amid traditional decor in an upscale restaurant popular for business lunches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6226,
        store_longitude: -74.0253,
        total_orders: 230.37,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Friendly, Great, Puerto Rican, Casual',
        annual_dol: 867.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9759,
        total_orders: 993.91,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Lobster, Steak, Salad, Gluten Free, Mexican, Spicy, Champagne, Wine, Italian',
        annual_dol: 1022.28,
        description:
            'Upscale restaurant & bar serving seasonal Mediterranean meals prepared with flavored olive oils.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.966,
        total_orders: 129.65,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Vegan, Wine, Salad, Healthy, Lebanese, Beer, Dessert, Savory, Clean, Gracious',
        annual_dol: 135.27,
        description:
            'Accommodating to large parties, healthy & vegan Mediterranean dishes are served family-style.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9875,
        total_orders: 1297.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lasagna, Meatball, Italian, Gourmet, Dessert, Spicy, Wine, Margarita, Prosecco, American',
        annual_dol: 1325.11,
        description:
            'Branch of a hip, ingredient-focused local chain offering inventive meatball sandwiches & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7693,
        store_longitude: -73.9886,
        total_orders: 178.25,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Green Curry, Thai, Beer, Mediterranean, Latin, American, Casual, Coleslaw',
        annual_dol: 824.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8108,
        store_longitude: -73.9435,
        total_orders: 49.38,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Pizza, Tacos, Tasty, Dessert, Premium, Lively, Authentic, Great, Modern, Sangria',
        annual_dol: 189.02,
        description:
            'Stylish, modern locale featuring flavored hookahs, updated comfort food, cocktails & hip hop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7367,
        store_longitude: -73.9871,
        total_orders: 182.8,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Tacos, Tequila, Mexican, Steak, Beer, Margarita, Pizza, Tex-Mex, Enchiladas, Trendy',
        annual_dol: 194.82,
        description:
            'Lively joint serving classic Tex-Mex grub & margaritas in a colorful space with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9528,
        total_orders: 388.44,
        neighborhood: 'Roosevelt Island',
        keywords:
            'Burger, Tasty, Chicken Pot Pie, Wine, Salad, Rich, Delicious, Beer, Italian, Warm',
        annual_dol: 732.68,
        description:
            'Bustling hangout offering Irish & American entrees, plus draft beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7516,
        store_longitude: -73.6025,
        total_orders: 54.2,
        neighborhood: '',
        keywords:
            'Salad, Wine, Shrimp Cocktail, Italian, Beer, Cozy, Crisp, Friendly, Casual, Authentic',
        annual_dol: 221.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7867,
        store_longitude: -73.6397,
        total_orders: 362.68,
        neighborhood: '',
        keywords: 'Steak, Salad, Meatloaf, Wine, Gluten Free, Dessert, Italian, Organic, Warm, Bbq',
        annual_dol: 396.45,
        description:
            'Bustling local go-to for generous portions of New American standards & early-bird deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7999,
        store_longitude: -73.7009,
        total_orders: 932.19,
        neighborhood: '',
        keywords: 'Burger, Steak, Pizza, Beer, Wine, American, Irish, Modern, Greek, Outstanding',
        annual_dol: 1001.93,
        description:
            'Time-tested, storefront tavern with rustic-chic decor, pub grub & craft beer, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5961,
        store_longitude: -74.0951,
        total_orders: 55.47,
        neighborhood: 'Dongan Hills/Todt Hill',
        keywords:
            'Pizza, Steak, Vegetarian, Salad, Chicken Caesar Salad, Italian, Flavorful, Wine, Beer, Dessert',
        annual_dol: 226.76,
        description:
            'Traditional pizzas, pastas & other Italian favorites served in a warm, laid-back setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5658,
        store_longitude: -74.1006,
        total_orders: 25.16,
        neighborhood: 'New Dorp Beach',
        keywords:
            'Burger, Hotdog, Pizza, Tequila, Beer, Turkish, Delicious, American, Mediterranean, Friendly',
        annual_dol: 29.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6999,
        store_longitude: -73.9162,
        total_orders: 80.55,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Vegan, Beer, Chinese, Tasty, Urban, Modern, Retro, Fun, Local',
        annual_dol: 282.12,
        description:
            "Retro pizza place for modern pies & craft beer in a kitschy space decorated with '80s memorabilia.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6098,
        store_longitude: -74.063,
        total_orders: 19.3,
        neighborhood: 'Rosebank',
        keywords:
            'Delicious, Cajun, Dessert, Fun, Welcoming, Cool, Great, Excellent, Local, Casual',
        annual_dol: 78.91,
        description: '"Locally owned and operated"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7284,
        store_longitude: -74.0051,
        total_orders: 1088.64,
        neighborhood: 'Hudson Square',
        keywords: 'Salad, Tasty, Coffee, Wine, Cuban, Delicious, Legendary, Thai, Bbq, Vintage',
        annual_dol: 1226.15,
        description:
            "SOB's is a live world music venue and restaurant in the Hudson Square neighborhood of Manhattan. S.O.B.’s is an abbreviation of Sounds of Brazil. Larry Gold started SOBs in June 1982, and he currently still owns the space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8355,
        store_longitude: -73.9441,
        total_orders: 1462.05,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Burger, Cheesecake, Salad, Beer, Coffee, Dessert, Tasty, Ale, Bourbon, Bbq',
        annual_dol: 1525.44,
        description:
            'Burgers, flatbreads & weekend brunch in a jumping spot with sports on TV, plus cocktails & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7847,
        store_longitude: -73.977,
        total_orders: 59.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Kebab, Salad, Dessert, Beer, Mediterranean, Israeli, Shawarma, French, Middle Eastern, Casual',
        annual_dol: 77.86,
        description:
            '"Gazala’s Restaurant is the creation of Chef/Owner Gazala Halabi, who celebrates her culinary heritage as an Israeli Druze."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7127,
        store_longitude: -73.6073,
        total_orders: 124.91,
        neighborhood: '',
        keywords: 'Irish, Great, Casual',
        annual_dol: 510.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7673,
        store_longitude: -73.9202,
        total_orders: 805.73,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Beer, Wine, American, Casual, Local, Fusion, Classic, Exotic',
        annual_dol: 840.66,
        description:
            'Modern, upbeat tavern with an outdoor courtyard offering pub grub, cocktails & rotating craft brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6602,
        store_longitude: -73.9804,
        total_orders: 797.76,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'Beer, Irish, Casual, Perfect, Local, Wonderful',
        annual_dol: 797.76,
        description:
            'Longtime neighborhood watering hole is no frills & no food with a history dating back to 1933.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6645,
        store_longitude: -73.9805,
        total_orders: 522.05,
        neighborhood: 'Park Slope',
        keywords: 'Lobster, Tasty, Champagne, Wine, Salad, Coffee, Pizza, Spicy, Warm, Italian',
        annual_dol: 775.8,
        description:
            'Relaxed choice with a warm vibe serving breakfast sandwiches & classic Italian meals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6774,
        store_longitude: -73.9863,
        total_orders: 400.35,
        neighborhood: 'Gowanus',
        keywords: 'Lobster, Burger, Pizza, Salad, Dessert, Coffee, Beer, Wine, Rustic, American',
        annual_dol: 530.02,
        description:
            'Lobster rolls, a raw bar, daily catches & a happy hour attract seafood lovers to this rustic eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7696,
        store_longitude: -73.9545,
        total_orders: 1520.19,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Salad, Turkey Sandwich, Gourmet, Vodka, Roast Turkey, Gin, Casual, Traditional, Perfect',
        annual_dol: 1893.41,
        description:
            'Old-school eatery serving blintzes, knishes & other Jewish fare, plus a variety of sandwiches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7242,
        store_longitude: -73.9513,
        total_orders: 249.06,
        neighborhood: 'Greenpoint',
        keywords:
            'Tequila, Tacos, Mexican, Steak, Beer, Margarita, Salad, Coffee, Burritos, Quesadillas',
        annual_dol: 254.34,
        description:
            'Simple Mexican tacos, burritos & quesadillas plus booze served amid contemporary decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8481,
        store_longitude: -73.8316,
        total_orders: 802.18,
        neighborhood: 'Pelham Bay',
        keywords:
            'Steak, Vodka, Salad, Beer, Turkey Sandwich, Latin, Creamy, Spanish, Fried Rice, American',
        annual_dol: 3022.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7712,
        store_longitude: -73.9517,
        total_orders: 31.74,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Spicy, Casual, Signature',
        annual_dol: 129.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6637,
        store_longitude: -73.6079,
        total_orders: 14.71,
        neighborhood: '',
        keywords:
            'Lobster, Vegan, Pizza, Vegetarian, Tacos, Gluten Free, Casual, Latin, Hip, Great',
        annual_dol: 60.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6129,
        store_longitude: -73.9894,
        total_orders: 89.98,
        neighborhood: 'Bensonhurst',
        keywords: 'Coffee, Beer, Friendly, Casual, Great, Excellent, Signature',
        annual_dol: 367.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7079,
        store_longitude: -73.679,
        total_orders: 471.98,
        neighborhood: '',
        keywords:
            'Burger, Shrimp Cocktail, Bbq, Tangy, Karaoke, Ale, Homemade, Local, Casual, Great',
        annual_dol: 503.36,
        description:
            'Hearty pub eats from burgers to entrees served in a casual, upbeat setting with weekend live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7517,
        store_longitude: -73.9887,
        total_orders: 15.55,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Sushi, Vegetarian, Spicy, Margarita, Beer, Japanese, Ramen, Delicious, Zen, Sashimi',
        annual_dol: 63.57,
        description:
            'Simple, booth-lined eatery with a range of Japanese dishes, from sashimi to noodle soups & teriyaki.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.826,
        store_longitude: -73.9389,
        total_orders: 268.06,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Pizza, Lasagna, Italian, Beer, Coffee, Chinese, Delicious, Homemade, African, French',
        annual_dol: 547.12,
        description: '"A Harlem Pizza shop."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6551,
        store_longitude: -73.9599,
        total_orders: 5056.88,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Zombie, Beer, Wine, Rum, Caribbean, Ale, Fruity, Delicious, Hip, Fun',
        annual_dol: 5561.53,
        description:
            'Bamboo accents this tiki bar specializing in fruity mixed drinks featuring Caribbean rum.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7635,
        store_longitude: -73.9138,
        total_orders: 328.51,
        neighborhood: 'Astoria/LIC',
        keywords: 'Pizza, Beer, Bbq, Delicious, American, Fancy, Relaxed, Great, Casual, Local',
        annual_dol: 335.48,
        description:
            'Relaxed neighborhood saloon featuring craft beers, bar bites & theme-night specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6631,
        store_longitude: -73.9845,
        total_orders: 253.41,
        neighborhood: 'South Slope/Park Slope',
        keywords:
            'Salad, Rich, Seasonal, Asian, Contemporary, Sophisticated, Local, Casual, Global, Indian',
        annual_dol: 1035.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6981,
        store_longitude: -73.9369,
        total_orders: 132.95,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Whiskey, Tasty, Ale, Bourbon, Pizza, Cozy, Mead, Organic',
        annual_dol: 192.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7686,
        store_longitude: -73.9694,
        total_orders: 739.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Cosmopolitan, Elegant, French, Lively',
        annual_dol: 755.12,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6981,
        store_longitude: -73.937,
        total_orders: 262.56,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Coffee, Cozy, Casual, Hotel',
        annual_dol: 313.64,
        description:
            '"A cozy, neighborhood sports bar with two pool tables, darts, and drink specials."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7694,
        store_longitude: -73.9581,
        total_orders: 67.15,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Tacos, Tequila, Mexican, Steak, Beer, Margarita, Tex-Mex, Ale, Fajitas, Delicious',
        annual_dol: 131.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.94,
        total_orders: 13678.02,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Dutch, Stylish, Local, Classic, Casual, Intimate, Great, Fine, Signature',
        annual_dol: 14075.49,
        description:
            'Classic & newly invented craft cocktails served with hand-cut ice in a dark, throwback-style space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7752,
        store_longitude: -73.9568,
        total_orders: 65.5,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Pizza, Burger, Italian, Wine, Dessert, Vodka, Tasty, American, Rustic, Hotel',
        annual_dol: 97.14,
        description:
            'Brick-oven pizzas & Italian staples in a family-friendly setting with seasonal outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7397,
        store_longitude: -74.0082,
        total_orders: 509.36,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Italian, Salad, Gluten Free, Delicious, Friendly, Fun, Ample, Cool, Casual, Vibrant',
        annual_dol: 2262.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8261,
        store_longitude: -73.8196,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Steak, Pizza, Italian, Salad, Wine, Delicious, American, Classic, Authentic, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7849,
        store_longitude: -73.9826,
        total_orders: 681.25,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, French, Casual, American, Crisp, Homemade, Seasonal, Hummus, Fun, Great',
        annual_dol: 4370.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6764,
        store_longitude: -73.5576,
        total_orders: 642.51,
        neighborhood: '',
        keywords:
            'Whiskey, Beer, Bourbon, Tacos, Burger, Bbq, American, Irish, Delicious, Homemade',
        annual_dol: 1165.98,
        description:
            '"Fun, atmospheric gastropub featuring burgers, wings, grilled cheeses, wraps & delicious apps. Craft cocktails & craft beers from local breweries. Trivia nights & Happy hour 12-7pm every day. To make a reservation or book a private party, call 516-544-4797"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6907,
        store_longitude: -73.5498,
        total_orders: 23.8,
        neighborhood: '',
        keywords: 'Beer, Comfortable, Friendly, Hotel, Casual, Fresh, Classic, Great, Local',
        annual_dol: 97.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7029,
        store_longitude: -73.9014,
        total_orders: 270.8,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Vegan, Vegetarian, Burger, Salad, Coffee, Creamy, Friendly, Casual, French, Cool',
        annual_dol: 1106.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9165,
        total_orders: 585.55,
        neighborhood: 'Astoria',
        keywords: 'Steak, Burger, Hamburger, Salad, Organic, Bbq, Wine, American, Artisanal, Cozy',
        annual_dol: 616.77,
        description:
            'Grass-fed meat & organic chicken are barbecued or sold by the pound at this eatery/butcher shop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6618,
        store_longitude: -73.9932,
        total_orders: 2038.68,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Cajun, Coffee, Spicy, Austrian, Rum, American, Caribbean, African, Asian, Creole',
        annual_dol: 2085.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.9202,
        total_orders: 21.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Mexican, Tequila, Salad, Margarita, Dessert, Delicious, Sweet, Western, Casual',
        annual_dol: 88.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6398,
        store_longitude: -73.7429,
        total_orders: 240.52,
        neighborhood: 'Rosedale',
        keywords:
            'Lobster, Beer, Fried Calamari, Naan Bread, Delicious, Rum, Polish, Moscow Mule, Friendly, Outstanding',
        annual_dol: 920.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6978,
        store_longitude: -73.931,
        total_orders: 36.9,
        neighborhood: 'Bushwick',
        keywords:
            'Coffee, Delicious, Thai, Seasonal, Creative, Casual, Local, Great, Cultural, Unique',
        annual_dol: 150.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.625,
        store_longitude: -74.1432,
        total_orders: 14.99,
        neighborhood: 'Port Richmond',
        keywords: 'French Toast, French, Omelette, Casual',
        annual_dol: 49.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7069,
        store_longitude: -73.9223,
        total_orders: 797.25,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Karaoke, Premium, Fancy, Great, Cool, Signature',
        annual_dol: 3258.91,
        description:
            '"Craft libations, curated wines & beer, dj nights, enjoy the elevated vibes & dance the night away 🔥"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7034,
        store_longitude: -73.9319,
        total_orders: 708.79,
        neighborhood: 'East Williamsburg',
        keywords:
            'Salad, Wine, Peruvian, Uruguayan, Italian, Delicious, Venezuelan, Cozy, Rustic, Latin',
        annual_dol: 992.97,
        description:
            '"Uruguayan Bistro Bar, mixing Latin American, Italian & Spanish flavors in rustic chic space ."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7346,
        store_longitude: -73.6139,
        total_orders: 3.42,
        neighborhood: '',
        keywords:
            'Vegetarian, Chinese, Thai, Contemporary, Indian, Traditional, Rooftop, Friendly, Cool, Great',
        annual_dol: 14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7771,
        store_longitude: -73.9525,
        total_orders: 15.36,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Mexican, Beer, Salad, Nachos, Thai, Bbq, Casual, Classy, Local, Fresh',
        annual_dol: 124.21,
        description: '"Mexican restaurant"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.6463,
        total_orders: 6.67,
        neighborhood: '',
        keywords: 'Garlic Bread, Salad, French, Irish, Latin, Blt Sandwich, Casual',
        annual_dol: 27.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -73.6847,
        total_orders: 20.96,
        neighborhood: '',
        keywords: 'Pizza, Steak, Italian, Vodka, Salad, Beer, Homemade, French, Fresh, Casual',
        annual_dol: 23.34,
        description:
            'Family-friendly eatery offering a big menu of pizzas & Northern Italian dishes, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6668,
        store_longitude: -73.9846,
        total_orders: 688.51,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Italian, Tasty, Wine, Organic, Bruschetta, Classy',
        annual_dol: 708.83,
        description:
            'Pasta, panini & pizza paired with wines & cocktails is the formula at this mellow local hangout.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8544,
        store_longitude: -73.8888,
        total_orders: 4550.01,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Italian, Pizza, Shrimp Cocktail, Wine, Coffee, Tangy, Warm, Vibrant, Traditional, Fresh',
        annual_dol: 4600.23,
        description:
            'Cozy, unpretentious neighborhood restaurant serving classic red-sauce Italian fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7328,
        store_longitude: -73.9545,
        total_orders: 1470.02,
        neighborhood: 'Greenpoint',
        keywords:
            'Steak, Delicious, Creamy, Asian, Thai, Innovative, Friendly, Sweet, Amazing, Authentic',
        annual_dol: 1742,
        description: '"Share Asia: Experience through cocktails & food"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7706,
        store_longitude: -73.9597,
        total_orders: 34.19,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Coffee, Wine, Canadian, Dessert, Beer, French Toast, Swiss, French Onion Soup, French',
        annual_dol: 47.9,
        description:
            'Pancakes & omelets, plus burgers, shakes & salads, with lines for weekend brunch. Cash only.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7696,
        store_longitude: -73.9549,
        total_orders: 125.26,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Pizza, Salad, Italian, Bruschetta, Caesar Salad, Homemade, Smoky, Local, Casual, Authentic',
        annual_dol: 282.72,
        description:
            'Casual chain restaurant specializing in wood-fired, Neapolitan pizza, plus classic Italian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7084,
        store_longitude: -73.9377,
        total_orders: 442.98,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Wine, Warm, Bbq, Casual, Amazing, Great, Unique',
        annual_dol: 1810.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6841,
        store_longitude: -73.9925,
        total_orders: 1028.01,
        neighborhood: 'Carroll Gardens/Cobble Hill',
        keywords:
            'Sushi, Beer, Comfortable, Italian, Friendly, Sweet, Casual, Irish, Local, American',
        annual_dol: 2547.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7809,
        store_longitude: -73.9806,
        total_orders: 1553.98,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Beer, Trendy, Delicious, Cozy, Fun, Yummy, Comfortable, Irish, Friendly',
        annual_dol: 1592.26,
        description:
            'This cool hot spot with a cozy retro vibe showcases inventive cocktails & global small plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6184,
        store_longitude: -73.7545,
        total_orders: 641.57,
        neighborhood: 'Far Rockaway',
        keywords: 'Fabulous, Fresh, Premium',
        annual_dol: 766.39,
        description:
            'Inwood Country Club is a private Golf, Tennis & Beach Club in Inwood, New York, located adjacent to Jamaica Bay and just southeast of John F. Kennedy International Airport. Originally established as nine-hole course in 1901, it is one of the oldest golf courses on Long Island.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8146,
        store_longitude: -73.9594,
        total_orders: 3042.05,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Coffee, Tasty, Beer, Vegetarian, Trendy, Avocado Toast, Healthy, Premium, American, Clean',
        annual_dol: 3238.43,
        description:
            'Hip venue for craft cocktails, beer & light fare at night & cafe bites, coffee & smoothies by day.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7673,
        store_longitude: -73.9838,
        total_orders: 2999.98,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Burger, Vegetarian, Hamburger, Gluten Free, Organic, Beer, Sustainable, Trendy, Clean',
        annual_dol: 3416.77,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -73.9854,
        total_orders: 504.41,
        neighborhood: 'Two Bridges',
        keywords:
            'Wine, Beer, Spanish, Dessert, Savory, Romantic, Trendy, Coffee, Upscale, Dominican',
        annual_dol: 1461.45,
        description:
            'A daily-changing menu of pintxos, mains & desserts is served in a relaxed space with globe lights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.99,
        total_orders: 10.93,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Salad, Dessert, Wine, Meatball, Magical, Homey, Homemade, Amazing, Excellent',
        annual_dol: 44.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7658,
        store_longitude: -73.9823,
        total_orders: 149.93,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Mexican, Sushi, Pizza, Salad, Hotel, Western, Local, Casual, Fresh, Classic',
        annual_dol: 674.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6775,
        store_longitude: -73.8287,
        total_orders: 562.96,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'Burger, Vodka, Hotel, Western, Fancy, Vibrant',
        annual_dol: 2301.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6759,
        store_longitude: -73.8194,
        total_orders: 372.43,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Nachos, Swiss, Philly Cheesesteak, Classic, Great, Casual',
        annual_dol: 1522.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6855,
        store_longitude: -73.9816,
        total_orders: 2086.98,
        neighborhood: 'Boerum Hill',
        keywords: 'Burger, Tequila, Salad, Beer, Whiskey, Spicy, Bbq, Cozy, Irish, American',
        annual_dol: 2152.84,
        description:
            'Rustic-chic bar offering cocktails, craft brews & American comfort bites in cozy, candle-lit digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7392,
        store_longitude: -74.0015,
        total_orders: 12117.28,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Hotdog, Cheesesteak, Beer, Swiss, Irish, Philly Cheesesteak, Ale, French',
        annual_dol: 12488.87,
        description:
            'Time-tested pub serving burgers, sandwiches & bar bites, plus draft beer, cocktails & sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7429,
        store_longitude: -74.0037,
        total_orders: 28.94,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Vodka, Mimosa, Green Papaya Salad, Vietnamese, Thai, Bellini, Papaya Salad, Bloody Mary, Asian',
        annual_dol: 109.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7337,
        store_longitude: -73.9982,
        total_orders: 1066.48,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Italian, Tasty, Wine, Premium, Polished, Fabulous, Amazing, Great, Seasonal',
        annual_dol: 3743.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7462,
        store_longitude: -73.9976,
        total_orders: 112.32,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Mexican, Italian, Tasty, Beer, Salad, Chinese, Crisp, Smoky, Premium',
        annual_dol: 459.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7111,
        store_longitude: -73.948,
        total_orders: 465.87,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Steak, Gluten Free, American, Casual, Cool, Awesome, Diverse',
        annual_dol: 522.57,
        description:
            'A diverse menu of steaks, entrees & snacks served in a clubby atmosphere with Skee-Ball.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7127,
        store_longitude: -73.9562,
        total_orders: 30121.15,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Upscale, Bourbon, Elegant, Cozy, Comfortable, Quaint, Delicious, Clean, Perfect',
        annual_dol: 32575.64,
        description:
            'Funky bar with frequent live music, an outdoor area & a downstairs restaurant with a compact menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 2268.8,
        neighborhood: 'New Springville/Mid Island',
        keywords: 'Burger, Zombie, Hamburger, Beer, Margarita, Hawaiian, Wine, Ale, Fun, American',
        annual_dol: 2316.94,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.9779,
        total_orders: 7567.48,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Beer, Sushi, Fun, Awesome, Eclectic, Amazing, Turkish, Great, Cool, Classic',
        annual_dol: 7912.73,
        description:
            'Cool, compact live-music venue with a unique center-of-the-room stage & its own record label.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7142,
        store_longitude: -73.954,
        total_orders: 1031.47,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Old Fashioned, Intimate, Perfect, Cool, Fresh, Great, Daiquiri, Casual, Signature',
        annual_dol: 1149.33,
        description:
            'Cool watering hole with outdoor seats doling out snacks, signature cocktails & draft beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7081,
        store_longitude: -74.002,
        total_orders: 714.9,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Tacos, Lobster, Mexican, Beer, Vegetarian, Gluten Free, Vodka, Wine, New-York-Style',
        annual_dol: 777.99,
        description:
            'Updated pub grub, cocktails & NY craft brews served in a bi-level venue with brick-lined walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7174,
        store_longitude: -73.9562,
        total_orders: 76.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Beer, Cozy, Quaint, Friendly, Casual, Authentic, Mediterranean, Perfect, Lit, Local',
        annual_dol: 312.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7225,
        store_longitude: -73.9899,
        total_orders: 125.48,
        neighborhood: 'Bowery',
        keywords: 'Upscale, Karaoke, Hotel, Great, Hip, Cool, Casual',
        annual_dol: 512.91,
        description:
            'Snug nightclub with cocktails, hip-hop music & DJs, open late Wednesday–Saturday.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9905,
        total_orders: 2633.15,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Salad, Coffee, Gluten Free, Wine, Avocado Toast, Delicious, Australian, Bbq, Hotel',
        annual_dol: 2726.36,
        description:
            'Chic all-day cafe & bar serving locally sourced American bites with an Australian twist.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -73.9935,
        total_orders: 2691.75,
        neighborhood: 'Bowery',
        keywords:
            'Tasty, Burger, Champagne, Wine, Whiskey, Trendy, Elegant, Coffee, Rustic, Friendly',
        annual_dol: 2748.87,
        description:
            'Rustic-chic hangout with late hours & a full raw bar, plus French-inspired small plates & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8893,
        store_longitude: -73.9024,
        total_orders: 72.55,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Pizza, Savory, Nutritious, Excellent, Distinct',
        annual_dol: 296.56,
        description:
            'Manhattan College is a private, Catholic, liberal arts university in the Bronx, New York City. Originally established in 1853 by the Brothers of the Christian Schools as an academy for day students, it ...',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8979,
        store_longitude: -73.8674,
        total_orders: 46.86,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords:
            'Wine, Beer, Irish, Tranquil, Elegant, Friendly, Asian, Casual, Traditional, Unique',
        annual_dol: 60.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7654,
        store_longitude: -73.9873,
        total_orders: 2181.56,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Beer, Wine, Gourmet, Tomato Soup, American, Seasonal, Classy, Local, Casual',
        annual_dol: 2303.42,
        description:
            'Cavernous, rustic-chic hangout with rotating craft-beer taps & small-batch whiskeys plus late hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7681,
        store_longitude: -73.9843,
        total_orders: 2509.12,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Hamburger, Vegan, Tequila, Gourmet, Tasty, Hotel, Delicious, Modern, Polished',
        annual_dol: 10256.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7684,
        store_longitude: -73.9856,
        total_orders: 29.87,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Pizza, Swiss, American, Greek, Thai, Mediterranean, Healthy, Homey, Fresh',
        annual_dol: 56.38,
        description:
            'No-frills diner serving up a vast menu of homey Greek & American fare around the clock.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9834,
        total_orders: 158.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Beer, Caesar Salad, American, Delicious, Casual, Classic, Natural',
        annual_dol: 646.95,
        description:
            '"#1 Best Burger Joint in NYC Zagat 2017 & 2018, #1 Burger NYC TripAdvisor. Craft Beer & Full Liquor, Craft Cocktails. Chef made burgers. Late night, DJ, Live Music"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8893,
        store_longitude: -73.9024,
        total_orders: 201.03,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Pizza, Coffee, Savory, Hotel, Asian, Casual, Western, Local',
        annual_dol: 821.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7431,
        store_longitude: -74.0073,
        total_orders: 2287.41,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Lobster, Sushi, Pizza, Italian, Japanese, Scotch, Sashimi, Upscale, Asian',
        annual_dol: 9407.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7391,
        store_longitude: -74.0013,
        total_orders: 363.22,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Wine, Ale, Gluten Free, Whiskey, Tasty, Italian, Warm, Woody, American',
        annual_dol: 1484.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7394,
        store_longitude: -74.0013,
        total_orders: 764.13,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Spanish, Pizza, Wine, Romantic, American, Homey, Sweet, Omelette, Traditional, Classic',
        annual_dol: 2080.44,
        description:
            'Laid-back hangout below the Spanish Benevolent Society, serving traditional Spanish food & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5599,
        store_longitude: -74.1698,
        total_orders: 71.22,
        neighborhood: 'Arden Heights',
        keywords: 'Fun, Great',
        annual_dol: 232.53,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7281,
        store_longitude: -73.9814,
        total_orders: 4386.84,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Vodka, Beer, Gin, Ale, Rum, Irish, Classic, Tradition, Bright, Great',
        annual_dol: 4639.3,
        description:
            'This neighborhood Irish pub is big with Liverpool fans & it hosts occasional live music & poetry.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.71,
        store_longitude: -74.0089,
        total_orders: 4477.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Whiskey, Beer, Irish, French Onion Soup, French, Caesar Salad, Prosecco, American',
        annual_dol: 4612.16,
        description:
            'Inviting Irish pub with an American twist offering hearty bar food, draft beer, TVs & a game room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7079,
        store_longitude: -74.0015,
        total_orders: 1549.26,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Italian, Delicious, Bellini, Fabulous, Gracious, Awesome, Fantastic, Great, Excellent',
        annual_dol: 1663.74,
        description:
            'Fashionable rooms with contemporary decor feature smart TVs, Italian linens and rainfall showers. Open-plan, 1- to 3-bedroom suites offer bridge and river views. Most have furnished terraces.A posh restaurant serves Italian fare, while a slick bar offers cocktails. Other amenities include a gym, personal training and babysitting service.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.715,
        store_longitude: -73.9443,
        total_orders: 437.41,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Pizza, Italian, Mexican, Chicken Parmigiana, American, Veal Parmigiana, Local, Casual, Classic',
        annual_dol: 456.38,
        description:
            'Thin-crust pizza, pasta & other familiar Italian dishes are served at this neighborhood eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7082,
        store_longitude: -74.0057,
        total_orders: 1066.78,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Coffee, Wine, Tasty, Trendy, Martini, Australian, American, Superb, Awesome, Perfect',
        annual_dol: 1727.71,
        description:
            '"Serves speciality coffee, Aussie brunch favorites and a seasonally charged dinner menu alongside an extensive wine and seasonal cocktail list"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.715,
        store_longitude: -73.959,
        total_orders: 267.05,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotel, Spacious, Trendy, Coffee, Cozy, Comfortable, Awesome, Fun, Clean, Friendly',
        annual_dol: 1091.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7098,
        store_longitude: -74.0065,
        total_orders: 119.93,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Burritos, Chinese, Local, Casual, Delicious, Healthy',
        annual_dol: 704.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7244,
        store_longitude: -73.9789,
        total_orders: 284.35,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Tacos, Mexican, Salad, Vodka, Margarita, Bourbon, Upscale, Rum, Spicy, Sweet',
        annual_dol: 632.26,
        description:
            '"We are Gringo-Mex since \'86. Our irresistable tacos and boozy margaritas have kept the locals coming back for over three decades. We pride ourselves on keeping it real... real food, real people, real New York."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6764,
        store_longitude: -73.9633,
        total_orders: 86.18,
        neighborhood: 'Crown Heights',
        keywords: 'Beer, Comfortable, Intimate, Great, Local, Signature',
        annual_dol: 352.28,
        description:
            '"Dorsettbk is a venue bar lounge . Located in the heart of Brooklyns museum garden district just minutes away from Manhattan. A lofty feel with white walls and high ceiling. The Intimate gathering space is a great Space for friends and family to gather you can have meetings showers engagement birthday parties . We pride ourselves on ensuring our customers are comfortable while having a great time. So book us for a Private. event or just stop by"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9219,
        total_orders: 1334.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Wine, Bourbon, Casual, Retro, Fresh, Dope, Local, Great, Signature',
        annual_dol: 1391.93,
        description:
            'Brick-lined, Asbury Park-themed joint with a patio for craft beer, cocktails, hot dogs & pinball.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9828,
        total_orders: 229.36,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Chic',
        annual_dol: 937.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.747,
        store_longitude: -73.974,
        total_orders: 478.53,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Hamburger, Salad, Beer, Dessert, Wine, Swiss, Delicious, American, Legendary',
        annual_dol: 545.85,
        description:
            'Legendary firefighter themed sports bar serving American fare & pub grub, plus all-day happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7516,
        store_longitude: -73.6025,
        total_orders: 385.07,
        neighborhood: '',
        keywords:
            'Salad, Shrimp Cocktail, Wine, Dessert, Italian, Martini, Beer, Rustic, Crisp, American',
        annual_dol: 549.85,
        description:
            'Traditional Italian cooking served in an intimate setting with a fireplace & rustic wood beams.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.837,
        store_longitude: -73.7022,
        total_orders: 88.58,
        neighborhood: '',
        keywords:
            'Sushi, Burger, Peruvian, Wine, Dessert, Japanese, Martini, Spicy, Beer, Delicious',
        annual_dol: 362.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9945,
        total_orders: 553.83,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lobster, Salad, Wine, Vodka, Italian, Mexican, Beer, Upscale, Velvety, Chinese',
        annual_dol: 616.19,
        description:
            'Quaint, white-tablecloth Italian spot with an emphasis on regional specialties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9491,
        total_orders: 6242.38,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Lobster, Sushi, Hotel, Vegan, South American, Sashimi, Spicy, Rooftop, Asian, American',
        annual_dol: 16113.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5235,
        store_longitude: -74.2347,
        total_orders: 36.11,
        neighborhood: 'Charleston',
        keywords: 'Pizza, Steak, Salad, Italian, Wine, Beer, American, Classy, Sweet, Casual',
        annual_dol: 39.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7282,
        store_longitude: -73.9914,
        total_orders: 3421.94,
        neighborhood: 'NoHo/East Village',
        keywords: 'Sushi, Steak, Japanese, Dessert, Wine, Vodka, Beer, Bbq, Juicy, Spicy',
        annual_dol: 4735.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7363,
        store_longitude: -73.9852,
        total_orders: 1035.66,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Tacos, Steak, Tequila, Mexican, Beer, Margarita, Spicy, Delicious, Tex-Mex, Enchiladas',
        annual_dol: 1057.64,
        description: '"Authentic Mexican restaurant with awesome tacos and great fresh Margaritas"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7533,
        store_longitude: -73.9344,
        total_orders: 10,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Mexican, Margarita, Hotel, Dessert, Warm, Classic, Authentic, Club Sandwich, Casual, Local',
        annual_dol: 40.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.986,
        total_orders: 11.72,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Sushi, Salad, Filipino, Beer, Wine, Warm, Asian, Cozy, Fresh, Lively',
        annual_dol: 47.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5837,
        store_longitude: -73.9502,
        total_orders: 341.68,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Kebab, Salad, Turkish, Bbq, Spicy, Mediterranean, Greek, Local, Russian, Baba Ghanoush',
        annual_dol: 1389.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.9783,
        total_orders: 3552.87,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hamburger, Vegetarian, Cajun, Tasty, Irish, Casual, Traditional, Perfect, Classic, Rooftop',
        annual_dol: 3706.91,
        description:
            'Pub with Irish-style decor & several beers on tap along with a full menu & frequent live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.8312,
        total_orders: 301.59,
        neighborhood: 'Flushing',
        keywords: 'Sushi, Salad, Trendy, Japanese, Beer, Ale, Sleek, Premium, High-End, Casual',
        annual_dol: 1232.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8555,
        store_longitude: -73.938,
        total_orders: 90.18,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Cheesecake, Steak, Tasty, Dessert, Hawaiian, Delicious, Warm, Italian, Spanish, Welcoming',
        annual_dol: 368.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.847,
        store_longitude: -73.938,
        total_orders: 41.43,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Steak, Milkshake, Peruvian, Flavorful, Dessert, Tasty, Coffee, Chinese, Cozy, Pinacolada',
        annual_dol: 119.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.9658,
        total_orders: 5828.7,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Upscale, Elegant, Tasty, Cozy, Premium, Classy, Relaxed, Great, Fine',
        annual_dol: 5987.26,
        description:
            'Gay cocktail lounge with 3 bars (including a piano room with nightly performances) & a dress code.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6255,
        store_longitude: -74.03,
        total_orders: 2023.44,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Mimosa, Bbq, Coffee, Spicy, Mediterranean, Ribeye Steak',
        annual_dol: 2023.44,
        description:
            'Mellow bar & restaurant offering Middle Eastern fare as well as a hookah lounge & tented back patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7457,
        store_longitude: -73.9882,
        total_orders: 13551.07,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Hotel, Vodka, Coffee, Gin, Stylish, Chinese, Salad, Vintage, Classy, Nachos',
        annual_dol: 13691.03,
        description:
            "Garden and 7 minutes on foot from the Empire State Building.Compact, stylish rooms with vintage decor offer minibars, free Wi-Fi and flat-screen TVs. Upgraded rooms add original artwork, fridges and/or turntables; some also have sitting areas. Airy suites feature living rooms, wood floors and street views; some come with claw-foot tubs. Room service is available.There's a buzzy lobby lounge, a chic restaurant and an oyster bar, plus an art gallery.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9877,
        total_orders: 1739.82,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Vegetarian, Vegan, Salad, Wine, Lebanese, Beer, Mediterranean, Upscale',
        annual_dol: 1855.48,
        description:
            'Creative Lebanese sharing plates, entrees & cocktails served up in a trendy, modern space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9953,
        total_orders: 860.22,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Beer, Karaoke, Irish, American, Authentic, Perfect, Great, Excellent, Fine, Casual',
        annual_dol: 3241.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6185,
        store_longitude: -74.0212,
        total_orders: 1129.73,
        neighborhood: 'Dyker Heights',
        keywords: 'Spanish, Golfclub',
        annual_dol: 1209.68,
        description:
            'The Dyker Beach Park and Golf Course is a public park and a municipal, 18-hole, championship golf course in the southernmost part of Dyker Heights, Brooklyn, in New York City, United States.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7602,
        store_longitude: -73.9886,
        total_orders: 325.63,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Steak, Italian, Wine, Upscale, Elegant, Opulent, Romantic, Fabulous, Excellent',
        annual_dol: 338.71,
        description:
            'Elegant Northern Italian trattoria with scenic garden patio serving an upscale menu since 1906.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.99,
        total_orders: 1283.84,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Irish, Phenomenal, Friendly, Comfortable, Welcoming, Natural, Classic, Local, Great',
        annual_dol: 1311.08,
        description:
            'Comfortable Irish haunt draws neighborhood locals with darts & a welcoming vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6946,
        store_longitude: -73.9932,
        total_orders: 175.64,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Lasagna, Shrimp Cocktail, Italian, Wine, Delicious, Cozy, Homemade, Friendly, Perfect, Casual',
        annual_dol: 717.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7146,
        store_longitude: -74.0161,
        total_orders: 703.5,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Pizza, Chicken Parmigiana, Italian, Gourmet, Rich, Ramen, French, Polished, American, Authentic',
        annual_dol: 734,
        description:
            'Chicken parm & other Italian-American classics go gourmet at this hip, diner-inspired space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7407,
        store_longitude: -74.0079,
        total_orders: 12920.3,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Steak, Burger, Wine, Champagne, Salad, Hotel, Beer, Tasty, Trendy, Rooftop',
        annual_dol: 13505.4,
        description:
            "from bustling nightlife in the Meatpacking District.Featuring floor-to-ceiling windows with river or city views, the streamlined rooms have minibars, free Wi-Fi and flat-screen TVs. Suites add soaking tubs and/or living rooms. Room service is available 24/7.There's a New American steakhouse, a cafe and a beer garden, plus 2 rooftop bars, including a nightclub. A hip cafe has a seasonal ice-skating rink and an art gallery. Off-site parking is offered (fee).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7334,
        store_longitude: -74.0062,
        total_orders: 36702.82,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Cozy, Legendary, Rum, Premium, Classic, Heritage, Casual, Daiquiri',
        annual_dol: 38487.15,
        description: 'Inventive drinks are poured at this retro-looking, Prohibition-style bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7301,
        store_longitude: -74.005,
        total_orders: 20917.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Whiskey Sour, Wine, Whiskey, Tasty, Beer, Gin, Perfect, Handcrafted, Fun, Classic',
        annual_dol: 32728.84,
        description:
            'Underground den of mixology featuring a vast menu of handcrafted cocktails in a snug space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -74.0039,
        total_orders: 355.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Upscale, French, Elegant, American, Delicious, Natural, Seasonal, Fun, Casual',
        annual_dol: 435.46,
        description:
            'Casual haunt for European-inspired bistro fare paired with natural wine & seasonal cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -74.004,
        total_orders: 157.64,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Wine, Delicious, Warm, Beer, Tranquil, Inviting, Asian, Friendly, Perfect',
        annual_dol: 175.52,
        description:
            '"Ama Raw Bar West Village is a unique Asian influenced raw bar that offers fresh seafood, craft cocktails, sake & wine in a warm inviting neighborhood restaurant and bar. We are known for our amazing $1 oyster happy hours, cocktail specials, & our menu that lets you taste a variety of bao buns & other vegetarian and seafood dishes that draw from the great flavors found all around Asia. Host your next Holiday party, charity event, or birthday party with us, and we will craft a personalized menu perfect for any affair."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7746,
        store_longitude: -73.8711,
        total_orders: 787.62,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Burger, Italian, Wine, Beer, Hotel, Innovative, Fancy, High-End, Entertaining, American',
        annual_dol: 2765.01,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8488,
        store_longitude: -73.8621,
        total_orders: 1790.21,
        neighborhood: 'Morris Park/Van Nest',
        keywords:
            'Steak, Salad, Chicken Parmigiana, Italian, Meatball, Dessert, Wine, Thai, Hotel, Phenomenal',
        annual_dol: 2489.03,
        description:
            'Longtime Italian eatery featuring a raw bar & classic entrees, including steak & seafood dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -73.907,
        total_orders: 1502.42,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Cozy, Fun, Friendly, Awesome, Eclectic, Cool, Perfect, Great, Casual',
        annual_dol: 1602.3,
        description:
            'Loaded fries, sandwiches, cocktails & beer in an easygoing bar with offbeat decor & a pool table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6931,
        store_longitude: -73.9006,
        total_orders: 1117.93,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords:
            'Burger, Vegan, Salad, Japanese, Beer, Wine, Friendly, Awesome, Caesar Salad, Aesthetic',
        annual_dol: 2305.33,
        description:
            'Cool, mod taproom with clever bar bites & local beers, plus a big backyard & late-night DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6773,
        store_longitude: -73.986,
        total_orders: 409.26,
        neighborhood: 'Gowanus',
        keywords:
            'Salad, Whiskey, Coffee, Delicious, Brownie Sundae, Romantic, Seasonal, American, Contemporary, Casual',
        annual_dol: 1672.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7396,
        store_longitude: -74.0065,
        total_orders: 576.78,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Lasagna, Steak, Vegetarian, Lobster, Pizza, Italian, Gourmet, Wine, Delicious',
        annual_dol: 615.13,
        description:
            'Thin-crust pizza & homemade pasta head the menu at this exposed-brick space in a 4-story townhouse.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7385,
        store_longitude: -74.0035,
        total_orders: 5048.68,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Beer, Ale, Martini, Cozy, Rum, Comfortable, Spicy, Seasonal',
        annual_dol: 5488.66,
        description:
            'Village mainstay with comfortable, dimly lit back room offers cocktails & bar food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7351,
        store_longitude: -74.0061,
        total_orders: 2203.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Italian, Tasty, Salad, Wine, Dessert, Gluten Free, Stylish, Trendy, Martini',
        annual_dol: 3647.4,
        description:
            'Celebrated, stylish seafood & grill restaurant offshoot with an aperitivo bar & a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.735,
        store_longitude: -74.0004,
        total_orders: 1380.02,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Tacos, Hamburger, Salad, Wine, Spicy, Upscale, Delicious, Trendy, French',
        annual_dol: 1878.64,
        description:
            'A menu of classic American bistro dishes & cocktails in a traditional dining room, fit for groups.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7219,
        store_longitude: -73.6414,
        total_orders: 63.62,
        neighborhood: '',
        keywords: 'Golfclub, Clean, Friendly, Perfect, Local',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7102,
        store_longitude: -73.8647,
        total_orders: 833.18,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords:
            'Burger, Pizza, Steak, Sushi, Shrimp Cocktail, Beer, Nachos, Flavorful, Irish, Wine',
        annual_dol: 895.54,
        description:
            'Roomy local tavern with a wide menu of tap beers offering pub eats, darts, karaoke & trivia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7046,
        store_longitude: -73.9111,
        total_orders: 3453.05,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Wine, Vegan, Beer, Trendy, Ale, Italian, Polish, Cozy, Polished, Chinese',
        annual_dol: 3526.32,
        description:
            'Polished neighborhood bar with craft beer & international snacks, plus vintage pinball machines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.694,
        store_longitude: -73.683,
        total_orders: 672.51,
        neighborhood: '',
        keywords: 'Salad, Upscale, Perfect, Fresh, Local',
        annual_dol: 724.81,
        description:
            '"WEDDING VENUE: We specialize in Weddings and perfect them by hosting them one at a time providing the perfect setting of exclusivity. Our guests receive the ultimate treatment upon arrival with the intuitive service, and the award winning cuisine offered. Our goal is that every single guest dining with us will have the ultimate dining experience."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7083,
        store_longitude: -73.8978,
        total_orders: 102.08,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Steak, Burger, Tacos, Mexican, Coffee, Margarita, Cozy, Fresh, Classic, Casual',
        annual_dol: 417.27,
        description: '"Fine Mexican dining in the heart of Ridgewood"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6739,
        store_longitude: -73.9826,
        total_orders: 311.69,
        neighborhood: 'Park Slope',
        keywords:
            'Steak, Mexican, Coffee, Margarita, Spicy, Trendy, Delicious, American, Phenomenal, Clean',
        annual_dol: 477.46,
        description:
            '"With more than 10 years serving the Windsor Terrace community and more than 25 years in the restaurant business, we are happy to announce the opening of our new location in Park Slope, Brooklyn. We serve Breakfast All day, Lunch and Dinner. Come in and grab a bite. Open 8 am to 10 pm daily!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6596,
        store_longitude: -73.9878,
        total_orders: 1856.12,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Hotdog, Beer, Cheesesteak, Salad, Wine, Philly Cheesesteak, Rustic, Relaxed, Spacious',
        annual_dol: 2186.35,
        description:
            'A former gas station houses this 13,000-sq.-ft. indoor/outdoor pub with food, games & a fireplace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6615,
        store_longitude: -73.9931,
        total_orders: 379.49,
        neighborhood: 'South Slope/Sunset Park',
        keywords: 'Pizza, Beer, Trendy, Karaoke, Welcoming, Perfect, Great, Incredible, Casual',
        annual_dol: 443.38,
        description:
            '"Brooklyn’s Best Happy Hour!! Buy 1, Get 1 Free 3-7 Daily. Pool, Huge Backyard, Pinball and A Vibe Welcoming All Who Want To Enjoy This Incredible Experience!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6708,
        store_longitude: -73.9852,
        total_orders: 138.46,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Spacious, Spectacular, Fun, Great, Incredible, Classic, Modern',
        annual_dol: 825.98,
        description:
            '"Mixology Bar and Tapas Restaurant. Open late. Great for large groups, semi-private and private events as well. We are a speakeasy grown over by nature, with inventive cocktails, fun small plates and a ten-foot living wall. Reach out to info@manahospitalitygroup.com for bookings and more info."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.719,
        store_longitude: -73.9433,
        total_orders: 3358.67,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Vegetarian, Coffee, Savory, Beer, Margarita, Peruvian, Italian, Delicious, Cozy',
        annual_dol: 3672.11,
        description:
            'Comfy neighborhood haunt offering Mediterranean food & cocktails in a stylish, intimate atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7678,
        store_longitude: -73.9593,
        total_orders: 284.25,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Steak, Wine, Salad, French Toast, French, Elegant, Juicy, Polished, Fun, Sweet',
        annual_dol: 516.57,
        description:
            'Narrow, warm-colored bistro dishing up classic French fare in a relaxed, colorful space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7336,
        store_longitude: -73.9584,
        total_orders: 1605.26,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Steak, Scotch, Delicious, American, Lively, Great, Excellent, Urban, Intimate',
        annual_dol: 1674.86,
        description: '"New American Cocktail Bar & Bistro"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8542,
        store_longitude: -73.8889,
        total_orders: 430.56,
        neighborhood: 'Little Italy/Belmont',
        keywords: 'Vodka, Pizza, Beer, Italian, Quaint, Warm, Homemade, American, Friendly, Casual',
        annual_dol: 450.7,
        description:
            'Italian standby cooking hearty red-gravy standards in an understated setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8677,
        store_longitude: -73.8792,
        total_orders: 4965.42,
        neighborhood: '',
        keywords: 'Southern, Seasonal, Sweet, American, Local, Urban, Iconic, Modern, Chill',
        annual_dol: 6804.44,
        description:
            'The New York Botanical Garden is a botanical garden at Bronx Park in the Bronx, New York City. Established in 1891, it is located on a 250-acre site that contains a landscape with over one million living ...',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.781,
        store_longitude: -73.9764,
        total_orders: 108.14,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Mexican, Vegan, Margarita, Enchiladas, Western, Casual, Fresh, Vibrant, Colorful',
        annual_dol: 507.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7843,
        store_longitude: -73.9777,
        total_orders: 254.14,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Tequila, Sushi, Lobster, Wine, Vodka, Salad, Dessert, Italian, Spicy, Organic',
        annual_dol: 289.45,
        description:
            'A modern yet rustic space for seafood dishes, a raw bar, cocktails & an oyster happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.831,
        store_longitude: -73.8506,
        total_orders: 5.7,
        neighborhood: 'East Bronx/Unionport',
        keywords: 'Spanish, Dominican, Puerto Rican, Caribbean, Tasty, Clean, Casual',
        annual_dol: 23.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6961,
        store_longitude: -73.9047,
        total_orders: 3413.4,
        neighborhood: 'Bushwick/Flushing/Ridgewood',
        keywords:
            'Burger, Tasty, Beer, Breakfast Burrito, Swiss, Cozy, French, Modern, Vintage, Friendly',
        annual_dol: 3555.24,
        description:
            'Old-school watering hole with pool & pinball, velvet paintings, beer signs & similar kitschy decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6523,
        store_longitude: -73.9495,
        total_orders: 65.57,
        neighborhood: 'Little Haiti',
        keywords:
            'Vegan, Vegetarian, Burger, Lobster, Hamburger, Lobster Roll, Wine, Trendy, American, Delicious',
        annual_dol: 247.06,
        description:
            'Relaxed vegan eatery serving plant-based burgers, breakfast sandwiches & sides, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6754,
        store_longitude: -73.981,
        total_orders: 1444.77,
        neighborhood: 'Park Slope',
        keywords:
            'Korean, Delicious, Playful, Asian, Healthy, Contemporary, Fresh, Casual, Awesome, Fantastic',
        annual_dol: 1645.5,
        description: 'Playful takes on Korean classics & seafood in cozy, white-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7049,
        store_longitude: -73.933,
        total_orders: 1232.98,
        neighborhood: 'East Williamsburg',
        keywords:
            'Burger, Beer, Wine, Delicious, Stylish, Spacious, American, Awesome, Sweet, Fantastic',
        annual_dol: 1404.58,
        description:
            'Movie theater showing recent classics, plus a restaurant & bar serving American fare & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7225,
        store_longitude: -73.9483,
        total_orders: 604.41,
        neighborhood: 'Greenpoint',
        keywords: 'Polish, Hotel, Retro, Local, Casual',
        annual_dol: 688.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7317,
        store_longitude: -73.9577,
        total_orders: 96.21,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Margarita, Salad, Martini, American, Sweet, Caesar Salad, Perfect, Classic, Fantastic',
        annual_dol: 393.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6299,
        store_longitude: -74.0769,
        total_orders: 121.31,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Steak, Pizza, Chicken Parmigiana, Salad, Shrimp Cocktail, Italian, Meatball, Wine, Vodka, Margarita',
        annual_dol: 424.85,
        description:
            '"The Twisted Vineyard is meant to be an experience, from start to finish. Upon walking in you will be greeted with a smile and warm regards. We may ask you how you are feeling or what you’re in the mood to eat that particular night. Refresh yourself with some ice water and take a look at the menu. Any minute one of our servers will be by to let you know our specials for the evening. Have a healthy salad with cold crisp lettuce or some crunchy fried calamari. Enjoy a hot and cheesy classic pizza, or maybe a creamy vodka chicken parmigiana over delicious pasta. The portions are large and the price is fair but most importantly the service and food are outstanding!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8145,
        store_longitude: -73.8147,
        total_orders: 1206.51,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Elegant, Dessert, Exquisite, Perfect, Incredible, Iconic, Pure, Fresh, Fine, Signature',
        annual_dol: 1885.39,
        description:
            '"When you’re searching for a wedding venue in New York City, think of Marina Del Rey. We are the premiere New York City event venue for weddings, celebrations, corporate events, and more. For over 30 years, our family-owned business has provided incredible service and fine cuisine in a stunning location. Family-owned and experienced, we have served Westchester County, Queens, Nassau County, Yonkers & Manhattan, NY; and the surrounding areas for more than 30 years. Contact us today to get started or to book a tour of our beautiful venue."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7042,
        store_longitude: -74.0118,
        total_orders: 8098.07,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Beer, Salad, Ale, Irish, Delicious, Nachos, Innovative, Modern, Relaxed',
        annual_dol: 8314.51,
        description:
            'Multi-level hangout offering elevated pub fare, craft cocktails & brunch in a relaxed atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -74.004,
        total_orders: 2167.42,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vodka, Delicious, Gluten Free, Margarita, Romantic, Elegant, Salad, Martini, Gin, Italian',
        annual_dol: 2274.72,
        description:
            'Restaurant serving Mediterranean fare in a cozy, bi-level space with chandeliers & antique decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7453,
        store_longitude: -73.5921,
        total_orders: 596.68,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Steak, Japanese, Filet Mignon, Casual, Gyoza, Local, Fresh, Signature',
        annual_dol: 654.53,
        description: 'Hibachi chain serving Japanese dishes grilled tableside by theatrical chefs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -74.0018,
        total_orders: 554.35,
        neighborhood: 'SoHo/Canal Street',
        keywords:
            'Glamorous, Upscale, Delicious, Spectacular, Incredible, Aesthetic, Rooftop, Casual',
        annual_dol: 1022.26,
        description:
            'Upscale resource for furniture, decorative accessories, lighting, tableware & textiles, plus a cafe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6238,
        store_longitude: -73.9647,
        total_orders: 68.54,
        neighborhood: 'Midwood',
        keywords:
            'Steak, Burger, Sushi, Salad, Dessert, Coffee, Bbq, French, Mediterranean, Creme Brulee',
        annual_dol: 303.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5801,
        store_longitude: -73.8366,
        total_orders: 167.31,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords:
            'Pizza, Steak, Italian, Dessert, American, Delicious, Homemade, Impeccable, Modern, Ribeye Steak',
        annual_dol: 422.1,
        description:
            '"Rocco\'s Of Roc Beach Authentic Italian Cuisine Is A Family Run Operation Possessing Over 100 Years Of Combined Restaurant Experience. Whether It Be Our Pasta, Our Bread, Or Our Pizza, Rather Than Import Our Ingredients, Our Italian Born Executive Chef And His Hand Picked Team Prepare Every Component Of The Process By Hand In Our Kitchen Everyday From Scratch. The Blueprint Is To Deliver Fun, Music And Good Times Using A Modern Aesthetic Vibe, Impeccable Service, Gracious Hospitality And Delicious Food Using Old World Authentic Italian Traditions. We Remain The Only Coal Brick Oven Pizza In Rockaway, And One Of The Select Handful In All Of New York. Welcome To The Family Sincerely, The Rocco\'s Familia"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.569,
        store_longitude: -74.1109,
        total_orders: 279.98,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords:
            'Lobster, Lobster Tail, Salad, Lobster Bisque, Cajun, Wine, Beer, Delicious, Creole, Yummy',
        annual_dol: 298.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7058,
        store_longitude: -74.0116,
        total_orders: 2459.1,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Cajun, Wine, Sushi, Upscale, Dessert, Polished, American, Modern, Classic',
        annual_dol: 2840.23,
        description:
            'High-end kosher steakhouse with a wine room & French-Asian menu that includes sushi & Wagyu beef.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7412,
        store_longitude: -73.5976,
        total_orders: 879.61,
        neighborhood: '',
        keywords: 'Steak, Burger, Bbq, Beer, American, Casual',
        annual_dol: 1566.47,
        description:
            'Outlet of a BBQ chain serving wood-smoked meats & sides in an American West-themed space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7233,
        store_longitude: -74.0036,
        total_orders: 188.39,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Lasagna, Italian, Dessert, Delicious, Hawaiian, Elegant, Mediterranean, Spectacular, Bruschetta, Perfect',
        annual_dol: 512.91,
        description:
            '"Founded in 1926 in Milan, Italy 🇮🇹 BiCE Cucina is like no other restaurant with its authentic Italian Culinary experience and elegant European atmosphere to match."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 448.34,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Steak, Beer, Homey, Comfortable, Awesome, Amazing, Great, Casual, Local',
        annual_dol: 1832.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.745,
        store_longitude: -73.6045,
        total_orders: 4176.9,
        neighborhood: '',
        keywords:
            'Wine, Italian, Pizza, Beer, Mexican, Bruschetta, Shrimp Scampi, Premium, Contemporary, Seasonal',
        annual_dol: 4358,
        description:
            'Relaxed wine bar serving Italian tapas, pizza & pasta in a chic, contemporary setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -74.006,
        total_orders: 505.14,
        neighborhood: 'Civic Center',
        keywords:
            'Wine, French, American, Italian, Classy, Quaint, Casual, Polished, Intimate, Charming',
        annual_dol: 956.24,
        description:
            'Popular, quaint bistro offering a French small-plates menu at breakfast, lunch & dinner (till 2 AM).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -74.0035,
        total_orders: 4002.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cosmopolitan, Scotch, Martini, Fun, Fabulous, American, Authentic, Great, Casual, Lit',
        annual_dol: 4088.63,
        description:
            'This historic piano bar draws a crowd of gay locals & musical-theater performers in dimly lit digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7296,
        store_longitude: -74.0042,
        total_orders: 981.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Tasty, Pizza, Italian, Dessert, Stylish, Whiskey, Scotch, Beer, Gin',
        annual_dol: 1024.23,
        description:
            '"Italian restaurant serving grilled entrees, fresh pasta dishes, and thin-crust Roman-style pizza with a wonderful selection of Italian wines and cocktails"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8697,
        store_longitude: -73.8271,
        total_orders: 10525.29,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Burger, Steak, Mimosa, Cuban, French, Puerto Rican, Trendy, American, Latin, Creamy',
        annual_dol: 10594.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7748,
        store_longitude: -73.9511,
        total_orders: 3387.49,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Wine, Flavorful, Tasty, Italian, Whiskey, Delicious, Scotch, Martini, Romantic, Beer',
        annual_dol: 4732.34,
        description:
            'Hip choice with a romantic vibe offering Italian small plates & mains, plus a large array of wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9013,
        total_orders: 4813.96,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Whiskey Sour, Whiskey, Pizza, Wine, Beer, Italian, Romantic, Fajitas, American, Shrimp Scampi',
        annual_dol: 4813.96,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.6602,
        total_orders: 268.35,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Salad, Italian, Wine, Beer, Spicy, Asian, Romantic, Casual',
        annual_dol: 653.14,
        description:
            'Hearty seafood & red-sauce Italian fare is served with complimentary cotton candy for dessert.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.967,
        total_orders: 12.96,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Sushi, Salad, Gluten Free, Wine, Beer, Coffee, Martini, Spicy, Chinese, Delicious',
        annual_dol: 86.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7622,
        store_longitude: -73.9659,
        total_orders: 604.63,
        neighborhood: 'Lenox Hill',
        keywords:
            'Steak, Salad, Coffee, Upscale, Luxurious, Avocado Toast, Mediterranean, Savory, Elegant, Opulent',
        annual_dol: 2987.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6961,
        store_longitude: -73.934,
        total_orders: 153.01,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Wine, Fun, Natural, Contemporary, Great, Fine, Casual',
        annual_dol: 187.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6833,
        store_longitude: -73.9956,
        total_orders: 343.04,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Vegetarian, Pizza, Italian, Coffee, Wine, Dessert, Meatball, Beer, Delicious, Impeccable',
        annual_dol: 883.95,
        description:
            'Refined spot crafting classic & contemporary Italian dishes, from rabbit pasta to grilled octopus.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6825,
        store_longitude: -73.9931,
        total_orders: 1352.73,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Hotdog, Hamburger, Steak, Beer, Spicy, Delicious, Awesome, Perfect, Great',
        annual_dol: 1381.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.9233,
        total_orders: 912.6,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegan, Pizza, Garlic Bread, Salad, Gluten Free, Beer, New-York-Style, French, Western, Casual',
        annual_dol: 952.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7285,
        store_longitude: -73.9871,
        total_orders: 290.71,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tequila, Wine, Vodka, Romantic, Gin, Delicious, Rum, French, Fun, Moroccan',
        annual_dol: 303.31,
        description:
            '"French Bistro with small plates to share, wine and delicious cocktails, Speakeasy after dinner hours... Live music Tuesday and Wednesday"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9734,
        total_orders: 1528.37,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, German, Tequila, Beer, Wine, Margarita, New Zealand, Classic, Authentic, Tradition',
        annual_dol: 1678.54,
        description:
            'Costumed barmaids serve imported German beers & meaty eats to customers seated at communal tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9795,
        total_orders: 2885.45,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Hotel, Rooftop, Classy, Cozy, Great, Wonderful, American, Signature, Cool',
        annual_dol: 2946.67,
        description:
            "District.Cozy rooms feature minibars, books and themed art, plus free Wi-Fi, cable TV and DVD players Upgraded rooms add city views, terraces, curated libraries and/or pull-out sofas. Room service is available.There's an American bistro and a rooftop cocktail lounge, along with 3 meeting rooms. Breakfast is available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5839,
        store_longitude: -73.948,
        total_orders: 256.17,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Delicious, Shrimp Cocktail, Elegant, Greek, Mediterranean, Outstanding, Friendly, Classy, Colorful, Great',
        annual_dol: 267.27,
        description:
            'This upscale-casual Greek restaurant with a full bar & sidewalk seats specializes in seafood dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.8335,
        total_orders: 111.43,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Wine, Chinese, Asian, Fancy, American, Friendly, Polished, Casual, Australian, Local',
        annual_dol: 259.77,
        description:
            'Polished Chinese restaurant offering Sichuan-Huaiyang fusion cuisine with meat & seafood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7873,
        store_longitude: -73.7912,
        total_orders: 33.57,
        neighborhood: 'Bayside',
        keywords: 'Chill, American',
        annual_dol: 43.29,
        description:
            'Popular 18-hole golf course with a big putting green, plus a chill clubhouse serving American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.784,
        store_longitude: -73.95,
        total_orders: 1132.52,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Steak, Pizza, Chicken Parmigiana, Salad, Gourmet, Dessert, Beer, Nachos, Cuban',
        annual_dol: 1132.52,
        description:
            'Classic pub grub like burgers & wings with gourmet twists & cocktails in an industrial-chic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7595,
        store_longitude: -73.9926,
        total_orders: 14233.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Tomato Soup, Salad, Wine, Beer, Spicy, Thai, Eclectic, American, Fun',
        annual_dol: 14329.29,
        description:
            'Intimate, brick-walled eatery & bar with handcrafted cocktails, eclectic small plates & a courtyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7845,
        store_longitude: -73.956,
        total_orders: 199.99,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Pizza, Italian, Salad, Gluten Free, Fun, Caesar Salad, Perfect, Great, Classic, Fine',
        annual_dol: 817.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7026,
        store_longitude: -73.9813,
        total_orders: 1128.02,
        neighborhood: 'Vinegar Hill/Brooklyn Heights',
        keywords:
            'Chocolate Cake, Beer, Vodka, Wine, Tasty, Delicious, American, Mediterranean, Seasonal, Quirky',
        annual_dol: 1256.91,
        description:
            'Seasonal New American cooking served in a cozy, salvaged-decor setting with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9849,
        total_orders: 5.74,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Beer, Spicy, Delicious, Rich, Crisp, Friendly, American, Modern, Perfect',
        annual_dol: 108.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.728,
        store_longitude: -73.8922,
        total_orders: 141.38,
        neighborhood: 'Flushing/Maspeth',
        keywords:
            'Steak, Burger, Salad, Wine, Shrimp Scampi, Beer, Irish, Creamy, Caesar Salad, Spacious',
        annual_dol: 147.51,
        description:
            'Laid-back eatery & bar with tavern surroundings, TV sports, brunch & live music on weekends.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8023,
        store_longitude: -73.9533,
        total_orders: 278.91,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Steak, Wine, Tequila, Italian, Beer, Bruschetta, Homemade, Ribeye Steak, Artisanal',
        annual_dol: 379.69,
        description:
            'Laid-back restaurant dishing up homestyle Italian classics such as spaghetti & lasagna.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8009,
        store_longitude: -73.9585,
        total_orders: 1249.45,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Coffee, Relaxed, Perfect, Greek, Bright, Greek Salad, Omelette, Casual, Great',
        annual_dol: 1275.96,
        description:
            'Relaxed hangout offering coffee, bagels, pastries, panini & salads, plus cocktails & sidewalk seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7214,
        store_longitude: -73.9954,
        total_orders: 1178.08,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Tasty, Wine, Italian, Beer, Healthy, Crisp, Comfortable, Authentic, Homey',
        annual_dol: 1178.08,
        description:
            'Brick walls & bookshelves lend character to this homey spot serving Italian fare & global wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7284,
        store_longitude: -73.9993,
        total_orders: 1383.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Ale, Wine, Funky, American, Classic, Great, Amazing, Casual, Chill',
        annual_dol: 1383.58,
        description:
            'More than 300 international brews await at this long-standing, cash-only pub with pewlike seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9999,
        total_orders: 1497.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Bourbon, Whiskey, Burger, French, Japanese, Contemporary, Rum, Traditional, Classic, Artisanal',
        annual_dol: 1745.28,
        description:
            'Flagship location of an artisanal ice cream shop with expanded menu of sweet treats plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6346,
        store_longitude: -73.9497,
        total_orders: 181.91,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Flavorful, Tasty, Upscale, Delicious, French, Caribbean, Authentic, Traditional, Contemporary, Exceptional',
        annual_dol: 743.59,
        description:
            'Upbeat destination with a contemporary vibe serving comfort food & mixed drinks, plus hookah.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.753,
        store_longitude: -74.0011,
        total_orders: 5845.02,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'American, Wine, Beer, Modern, Elegant, British, Cozy, Classic, Warm, Perfect',
        annual_dol: 6455.69,
        description:
            'Grilled meats, veggies & seafood with British flair in a light-filled space with a bar, plus brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -74.0034,
        total_orders: 1548.23,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Wine, Gluten Free, Italian, Delicious, Beer, Organic, Warm, Bruschetta, Spacious',
        annual_dol: 1615.35,
        description:
            'Tuscan cooking & boutique wines served in a setting that includes a spacious year-round garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9948,
        total_orders: 204.72,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Burger, Hamburger, Cheesesteak, Bbq, Salad, Gluten Free, Japanese, Beer, Chinese, American',
        annual_dol: 873.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7338,
        store_longitude: -74.0026,
        total_orders: 1160.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Rich, Cozy, Irish, Classic, Iconic, Contemporary, Great, Fresh, Casual',
        annual_dol: 1688.16,
        description:
            'This circa-1950 Village standard with a literary past offers drinks, games, & welcomes Packers fans.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8452,
        store_longitude: -73.845,
        total_orders: 46.11,
        neighborhood: 'Westchester Square',
        keywords: 'Lobster, Steak, Salad, Dessert, Wine, Italian, Beer, Polished, American, Casual',
        annual_dol: 188.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7372,
        store_longitude: -74.004,
        total_orders: 417.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Wine, Organic, Vodka, Sustainable, Gin, Casual, Excellent, Fine, Creative',
        annual_dol: 1464.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7329,
        store_longitude: -74.0035,
        total_orders: 1357.54,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Vodka, Cozy, Fun, Friendly, Perfect, Casual, Amazing, Great, Local',
        annual_dol: 2554.17,
        description:
            'Buzzing gay bar offering beer, cocktails, drag shows, happy-hour drink specials & dancing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.6345,
        total_orders: 257.11,
        neighborhood: '',
        keywords:
            'Vegetarian, Pizza, Cajun, Bbq, Coffee, Beer, Chicken Wings, Yummy, Casual, Authentic',
        annual_dol: 525.49,
        description:
            'Hip counter-serve eatery featuring a menu of BBQ classics, plus pomme frites & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.973,
        total_orders: 22.73,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Lobster, Salad, Lobster Bisque, Wine, Perfect, Casual, Iconic',
        annual_dol: 92.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -73.9925,
        total_orders: 1661.37,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Coffee, Mexican, Beer, Salad, American, Classic, Iconic, Casual, Cool',
        annual_dol: 6791.12,
        description:
            '"The Landing and Office Hours anchor hospitality in the new Penn District which also includes a collection of amenities that redefine how we work, live, and enjoy. As you journey through PENN 1, you will find Office Hours nestled off the lobby with access to 34th Street, or meander up the Social Stairs where you will find The Landing, the full service bar and restaurant."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8388,
        store_longitude: -73.9375,
        total_orders: 4.88,
        neighborhood: 'Upper Manhattan/Washington Heights',
        keywords: 'Pizza, Tasty, Upscale, Perfect, Casual, Rooftop, Local, Chill',
        annual_dol: 82.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6195,
        store_longitude: -73.9164,
        total_orders: 4.14,
        neighborhood: 'Mill Basin/Old Mill Basin',
        keywords:
            'Burger, Beer, Mexican, Vodka, American, Friendly, Classic, Bloody Mary, Fresh, Casual',
        annual_dol: 16.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7323,
        store_longitude: -73.6875,
        total_orders: 568.69,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Chicken Parmigiana, Italian, Meatball, Vodka, Beer, Retro, Casual, Local',
        annual_dol: 595.36,
        description:
            'Long-running small chain with retro flair for pizza & other Italian comfort fare, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.694,
        store_longitude: -73.9025,
        total_orders: 122.27,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Pizza, Vegan, Coffee, Beer, Delicious, Cajun, Dessert, Cozy, Rum, Perfect',
        annual_dol: 499.8,
        description:
            '"COFFEE shop hours M-F 9AM-4PM BRICK OVEN PIZZA hours Mon,Thurs& Sun 4PM-10PM Fri & Sat 4PM-1030PM Tue& Wed Closed BRUNCH Sat - Sun 11am - 4pm"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6884,
        store_longitude: -73.6859,
        total_orders: 30.98,
        neighborhood: '',
        keywords:
            'Pizza, Burger, Salad, Milkshake, Italian, Baked Ziti, Relaxed, Comfortable, Fresh, Friendly',
        annual_dol: 40.39,
        description:
            'Creatively topped pizzas, baked pasta & sandwiches are served in a relaxed space with a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6609,
        store_longitude: -73.9937,
        total_orders: 5539.94,
        neighborhood: 'South Slope/Sunset Park',
        keywords: 'Burger, Beer, Vodka, Wine, Delicious, Bourbon, Rum, Fun, Crisp, Awesome',
        annual_dol: 5666.17,
        description:
            'Welcoming marine-themed haunt features hearty pub grub, craft cocktails & a garden with a koi pond.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6605,
        store_longitude: -73.9945,
        total_orders: 291.15,
        neighborhood: 'South Slope/Sunset Park',
        keywords: 'Wine, Pizza, Beer, Gin, Vietnamese, Warm, Sustainable, Thai, Eclectic, Friendly',
        annual_dol: 1190.12,
        description:
            'Convivial bar turning out cocktails in a warm, narrow space with a foosball table & board games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9489,
        total_orders: 1720.11,
        neighborhood: 'Greenpoint',
        keywords:
            'Steak, Salad, Chicken Caesar Salad, Shrimp Cocktail, Gourmet, Wine, Dessert, Shrimp Scampi, French, French Onion Soup',
        annual_dol: 2069.5,
        description:
            'High-end steakhouse boasting gourmet seafood & fine wines in an old-fashioned, elegant venue.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7651,
        store_longitude: -73.9685,
        total_orders: 482.37,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Cosmopolitan, Georgian, American, Gracious, Delicious, Perfect, Tradition, Fine, Cultural',
        annual_dol: 514.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7669,
        store_longitude: -73.968,
        total_orders: 2388.2,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Cosmopolitan, Vodka, Elegant, Hotel, Opulent, Warm, French, Gracious, Refined',
        annual_dol: 2647.82,
        description:
            "Daniel Boulud's elegant French flagship where jackets are required & expense accounts come in handy.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7622,
        store_longitude: -73.9661,
        total_orders: 55.64,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Milkshake, Dessert, Delicious, Italian, Spicy, Nachos, Sweet, French, Rustic',
        annual_dol: 232.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.9985,
        total_orders: 0,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotel, Champagne, Luxurious, Opulent, French, American, Rooftop, Asian, Brazilian, Rare',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7415,
        store_longitude: -73.5899,
        total_orders: 1072.27,
        neighborhood: '',
        keywords:
            'Salad, Sushi, Pizza, Hamburger, Wine, Beer, Chicken Wings, Bourbon, Tex-Mex, Thai',
        annual_dol: 1301.19,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7987,
        store_longitude: -73.9418,
        total_orders: 4739.08,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Tacos, Burger, Mexican, Steak, Beer, Nachos, Tasty, Dessert, Enchiladas, Fajitas',
        annual_dol: 6182.89,
        description:
            'Casual, chef-owned stop for Mexican tacos, ceviche, larger plates with sides & aguas frescas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.5888,
        total_orders: 1146.53,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Beer, Salad, Bbq, Wine, Juicy, Tex-Mex, Hurricane, Fun',
        annual_dol: 1407.29,
        description:
            'Casual sports bar & grill with 20 different canned beers, happy hours & pub grub such as burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7445,
        store_longitude: -73.9533,
        total_orders: 11.39,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Salad, Thai, Green Curry, Spicy, Asian, Beer, Massaman Curry, Traditional, Fusion, Colorful',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7366,
        store_longitude: -73.987,
        total_orders: 637.89,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Vegetarian, Italian, Gluten Free, Wine, Meatball, Delicious, Friendly, Clean, Traditional, Recommended',
        annual_dol: 651.42,
        description:
            'Local institution with an old-world vibe & Italian standards, plus popular fixed-price options.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.9917,
        total_orders: 14189.4,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Chocolate Croissant, Burger, Pizza, Italian, Wine, Coffee, Beer, Avocado Toast, Trendy, Meatball',
        annual_dol: 15126.62,
        description:
            'Stylish Bowery Hotel eatery serving up Italian fare & drinks, plus outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7385,
        store_longitude: -73.99,
        total_orders: 905.57,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Wine, American, Spacious, Premium, Clean, Modern, Innovative, Great, Local',
        annual_dol: 1007.54,
        description:
            '"Experience the difference of AMC amenities. From spacious rocking seats to luxury recliners, innovative menus and premium offerings like IMAX, Dolby Cinema, and Prime at AMC, AMC Theatres offers a range of ways to get more out of movies."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7248,
        store_longitude: -73.9876,
        total_orders: 141.63,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tequila, Vodka, Champagne, Beer, Gin, Delicious, Rum, Premium, Classy, Fun',
        annual_dol: 578.95,
        description:
            '"After 21 years, Karma has closed. New local owners are stepping up to the plate. With such enthusiasm we are happy to announce that Karma Sutra NYC will open soon. We will be the only venue on the Lower East Side to maintain its roots and history. Combining the old Karma with the old Sutra to bring to you the New - Karma Sutra NYC. On behalf of everyone at Karma, we thank the community, our neighbors, our patrons, and our employees for the years of support and wish everyone the best of health and happiness in the future. Thank you for being a part of our family."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5541,
        store_longitude: -74.1449,
        total_orders: 110.51,
        neighborhood: 'Great Kills',
        keywords:
            'Sushi, Salad, Japanese, Sashimi, Delicious, Asian, Fusion, Teriyaki, Fresh, Authentic',
        annual_dol: 159.31,
        description:
            'Diners pluck sushi & maki rolls from a moving conveyor belt at this Japan-based chain outpost.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.9798,
        total_orders: 5258.66,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Lobster, Salad, Lobster Bisque, Italian, Bourbon, Champagne, French, French Onion Soup, Hotel',
        annual_dol: 5370.24,
        description:
            'Dry-aged steaks & a casual bar menu, plus raw & grilled seafood in a clubby space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8829,
        store_longitude: -73.9017,
        total_orders: 1226.01,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Lobster, Lobster Tail, Sushi, Steak, Salad, Japanese, Delicious, Asian, Filet Mignon, Modern',
        annual_dol: 1912.65,
        description:
            '"Sushi Hours: Monday - Friday 12PM - 3:30PM, 4:30PM - 9PM Saturday & Sunday 12PM - 9PM Sushi Dine-in Hours: Monday - Friday 12PM - 2:45PM, 4:30PM - 9PM Saturday & Sunday 12PM - 9PM Hibachi Dine-in Hours: Monday - Thursday 4:30PM - 9PM Friday 4:30PM - 9:30PM Saturday & Sunday 12PM - 9PM Dine-in, take out and delivery starts at 12PM every day."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7519,
        store_longitude: -73.9739,
        total_orders: 665.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Sushi, Beer, Wine, Irish, Karaoke, Ale, Fun, Polish, Amazing',
        annual_dol: 2521.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 75.31,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Dairy Free, Coffee, Friendly, Natural, Fresh, American, Local, Fine, Great',
        annual_dol: 307.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7023,
        store_longitude: -73.8205,
        total_orders: 20.55,
        neighborhood: 'Jamaica/Richmond Hill',
        keywords: 'Chicken Parmigiana, Dominican, Margarita, Sidecar, Casual, Perfect',
        annual_dol: 33.62,
        description:
            '"Perfect little get away from home . This is your second home . Come enjoy good cocktails & good music while partaking in small bites . Perfect to watch any sports game , go out with a co worker, colleague or even a calm relaxing date . Welcome to CasaNYC . Mi Casa Su Casa ."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9775,
        total_orders: 832.6,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Steak, Dessert, Tasty, French, Polish, Casual, Western, Chic, Gimlet',
        annual_dol: 3403.39,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9659,
        total_orders: 261.65,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Vegan, Butter Naan, Chicken Tikka Masala, Spicy, Delicious, Eclectic, Indian, Modern, Sleek',
        annual_dol: 278.44,
        description:
            'A wide-ranging Indian menu runs the gamut from lassis & samosas to spicy curry & vindaloo dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7147,
        store_longitude: -74.0105,
        total_orders: 330.77,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Gluten Free, Salad, Korean, Tasty, Wine, Karaoke, Bbq, Asian, Polished, Kbbq',
        annual_dol: 359.96,
        description:
            'Buzzy hangout featuring Korean BBQ at table grills, plus colorfully lit karaoke rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8406,
        store_longitude: -73.9182,
        total_orders: 617.03,
        neighborhood: 'Mount Eden/West Bronx',
        keywords:
            'Beer, Vegan, Italian, American, Dominican, Asian, Phenomenal, Latin, Fusion, Iconic',
        annual_dol: 643.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9866,
        total_orders: 104.93,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Italian, Impeccable, American, Authentic, Hotel, Casual, Fun',
        annual_dol: 166.02,
        description:
            '"Fun, casual, Southern Italian dining and bar with inventive cocktails in an upbeat environment. Neapolitan Pizza, full spritz and cocktail menu, pastas, shareable appetizers right in the heart of NYC\'s Times Square on West 47th Street."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7781,
        store_longitude: -73.9487,
        total_orders: 207.34,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegetarian, Vegan, Mexican, Salad, Nachos, Margarita, Wine, Gluten Free, Fajitas, Trendy',
        annual_dol: 507.96,
        description:
            'Warm, brick-walled local eatery offering Mexican favorites plus happy hours & margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7874,
        store_longitude: -73.9542,
        total_orders: 145.06,
        neighborhood: 'East Harlem/Central Park West Historic District',
        keywords:
            'Burger, Vegetarian, Salad, Coffee, Italian, Swiss, French, Cuban, Casual, Friendly',
        annual_dol: 151.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -74.0062,
        total_orders: 735.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Spectacular, Premium, Rooftop, Bold, Friendly',
        annual_dol: 3006.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.6481,
        total_orders: 15.64,
        neighborhood: '',
        keywords:
            'Vegetarian, Italian, Delicious, Perfect, Ample, Casual, Intimate, Fresh, Traditional, Contemporary',
        annual_dol: 31.93,
        description: '"Authentic Italian cuisine with Inspirations from the south of Italy"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7741,
        store_longitude: -73.9081,
        total_orders: 2246.76,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Burger, Whiskey, Beer, Vodka, Wine, Bourbon, American, Australian, Delicious',
        annual_dol: 3457.35,
        description:
            'Seasonal New American comfort fare, craft beer & cocktails are served in a hip, wood-paneled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.8849,
        total_orders: 673.73,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Salad, Pizza, Shrimp Cocktail, Italian, Chicken Alfredo, Wine, Latin, American, Fusion, Chic',
        annual_dol: 750.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5276,
        store_longitude: -74.2314,
        total_orders: 760.21,
        neighborhood: 'Charleston',
        keywords:
            'Champagne, Italian, Trendy, Beer, Fabulous, Exquisite, Modern, Amazing, Great, Terrific',
        annual_dol: 819.9,
        description:
            '"Luce is a lifestyle. A modern mix of fine Italian cuisine, atmosphere and culture. Let us share our passion with you while we light the way to an exquisite dining experience."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5868,
        store_longitude: -73.7294,
        total_orders: 52.17,
        neighborhood: '',
        keywords: 'Hotel, French, Healthy, Swiss, Fun, Fantastic, Casual, Terrific, Great, Local',
        annual_dol: 69.07,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7366,
        store_longitude: -73.985,
        total_orders: 120.62,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Tequila, Wine, Vodka, Vegan, Beer, Tasty, Italian, Organic, Mediterranean, Rum',
        annual_dol: 203.81,
        description:
            'Health-conscious Greek specialties served in a polished eatery that has a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.9917,
        total_orders: 550.88,
        neighborhood: 'East Village/Bowery',
        keywords: 'Italian, Wine, Salad, Beer, Trendy, Spicy, Hotel, Classy, Innovative, Fun',
        annual_dol: 591.68,
        description:
            'Casual but hip Italian spot serving a pasta-centric menu plus cocktails and lots of wine from Italy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5934,
        store_longitude: -74.0871,
        total_orders: 602.88,
        neighborhood: 'Dongan Hills',
        keywords:
            'Italian, Meatball, Wine, Warm, Mediterranean, Polished, Awesome, Great, Excellent, Casual',
        annual_dol: 708.78,
        description:
            'Central Italian fare & many wines served in classic, polished surrounds with a warm vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5985,
        store_longitude: -74.0656,
        total_orders: 40.07,
        neighborhood: 'Arrochar',
        keywords:
            'Lasagna, Italian, Salad, Wine, Flavorful, Traditional, Authentic, Casual, Local, Fresh',
        annual_dol: 163.8,
        description:
            'Restaurant & bar with traditional Italian pasta, seafood & meat dishes plus international wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7292,
        store_longitude: -73.9922,
        total_orders: 52.19,
        neighborhood: 'NoHo',
        keywords: 'Cool, Classy, Casual, Great',
        annual_dol: 213.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7337,
        store_longitude: -73.9932,
        total_orders: 24.58,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Thai, Tasty, Green Curry, Spicy, Coffee, Hotel, Lively, Vibrant, Fabulous, Sweet',
        annual_dol: 100.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6397,
        store_longitude: -73.9289,
        total_orders: 123.34,
        neighborhood: 'East Flatbush',
        keywords:
            'Flavorful, Stylish, Upscale, Caribbean, Delicious, Elegant, Jamaican, Sweet, Vibrant, Entertaining',
        annual_dol: 504.19,
        description:
            'Vibrant option with stylish decor offering goat curry, jerk chicken & other Caribbean staples.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.7718,
        total_orders: 114.82,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Italian, Gluten Free, Meatball, Wine, Cozy, Romantic, Classy, Friendly, Intimate, Southern',
        annual_dol: 129.56,
        description:
            'Intimate option featuring Northern & Southern Italian fare with weekly live jazz & blues nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7611,
        store_longitude: -73.9632,
        total_orders: 1962.97,
        neighborhood: 'Lenox Hill',
        keywords: 'Beer, Burger, Ale, Salad, Wine, German, American, Irish, Rustic, Belgian',
        annual_dol: 2115.39,
        description:
            'Craft coffee, cocktails & brews are offered at this cool, rustic bar with sandwiches & snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7564,
        store_longitude: -73.9676,
        total_orders: 1677.57,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Stylish, Flavorful, American, Premium, Funky, Vintage, Cozy, Savory, Friendly',
        annual_dol: 3607.13,
        description:
            'Funky brasserie serving creative cocktails, sharing plates & mains in a cozy, vintage-style space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.9803,
        total_orders: 4089.87,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Steak, Burger, Hamburger, Beer, Wine, Martini, Dessert, Irish, French Onion Soup, Gin',
        annual_dol: 4247.11,
        description:
            'Convivial pub with a warm vibe offering Irish & American bites, plus many draft beers. Open late.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.687,
        store_longitude: -73.9939,
        total_orders: 1679.6,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Wine, Beer, Italian, Romantic, Lively, Fancy, Classy, Impressive, Bloody Mary, Great',
        annual_dol: 1715.24,
        description:
            'Craft cocktails & beer, plus snacks like Pop Tarts & local jerky, in a compact, dimly lit space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6974,
        store_longitude: -73.9675,
        total_orders: 235.43,
        neighborhood: 'Clinton Hill',
        keywords:
            'Pizza, Italian, Gluten Free, Beer, Delicious, Mediterranean, Spacious, Bright, Classy, Sweet',
        annual_dol: 240.43,
        description:
            'Bright restaurant showcasing pizza & other Italian staples plus drinks in large, casual surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7596,
        store_longitude: -73.9842,
        total_orders: 639.06,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Beer, Hotel, Friendly, Fun, Casual, Local, American, Great, Fine',
        annual_dol: 1004.78,
        description:
            'Easygoing comedy club offering a lineup of shows, including a family option, plus a full bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.9853,
        total_orders: 6448.08,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Lobster, Sushi, Mexican, Salad, Mimosa, Margarita, Coffee, Upscale, Hotel',
        annual_dol: 6894.11,
        description:
            'Upscale pre-theater seafood & sushi served in a trendy setting with a glass-enclosed bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6916,
        store_longitude: -73.9846,
        total_orders: 615.67,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Hotel, Margarita, Beer, Chic, Modern, Great, Clean, Hip',
        annual_dol: 2411.02,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9897,
        total_orders: 689.59,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Dessert, Spicy, Japanese, Sashimi, Delicious, Seasonal, Innovative, Casual, Sleek',
        annual_dol: 925.05,
        description:
            'Evenings-only destination offering sophisticated preparations of sushi in a wood-lined setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7794,
        store_longitude: -73.9773,
        total_orders: 3172,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Coffee, Sushi, Salad, Wine, Steak, Italian, Beer, Tasty, Cozy, Mediterranean',
        annual_dol: 3773.02,
        description:
            'Chill, cozy espresso bar offering pastries & sandwiches, along with wine & Italian cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9924,
        total_orders: 2791.68,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Stylish, Beer, Friendly, Great, Local, Casual',
        annual_dol: 9800.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7509,
        store_longitude: -74.0043,
        total_orders: 68.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vodka, Sushi, Pizza, Champagne, Salad, Beer, Cosmopolitan, Upscale, French',
        annual_dol: 301.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.9909,
        total_orders: 425.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Lobster, Steak, Lobster Roll, Burger, Sushi, Lobster Bisque, Cajun, Creole, Premium, Sustainable',
        annual_dol: 1752.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7113,
        store_longitude: -74.0153,
        total_orders: 16693.51,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Vegan, Steak, Wine, Salad, Bourbon, Coffee, Upscale, French, Italian, Beer',
        annual_dol: 23878.11,
        description:
            'This massive French food hall has upscale restaurants, markets & stations for prepared foods.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -74.0033,
        total_orders: 260.83,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Pizza, Italian, Dessert, Wine, Delicious, Polish, Romantic, Polished, Classy, Friendly',
        annual_dol: 304.27,
        description:
            'Polished destination with an urbane vibe serving classic Italian cuisine & wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7229,
        store_longitude: -73.9957,
        total_orders: 347.46,
        neighborhood: 'Nolita',
        keywords:
            'Kebab, Tasty, Mexican, French Toast, German, French, Avocado Toast, Mediterranean, Delicious, Creole',
        annual_dol: 1312.42,
        description:
            '"Local 92 - Soho is a restaurant serving fresh and delicious food in New York, NY. We use only the best ingredients and recipes for all of our dishes, serving our customers quality that they can taste in every bite. Packed with flavor and cooked with the utmost care and dedication to authenticity, you are sure to love everything about our cuisine. In addition, we make sure that our customers are served exceptional quality food at an affordable price."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.9576,
        total_orders: 0,
        neighborhood: 'Greenpoint',
        keywords: 'Vibrant, Perfect, Authentic, Complex, Unique',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8735,
        store_longitude: -73.8083,
        total_orders: 1062.32,
        neighborhood: '',
        keywords: 'Golfclub, American, Hotel, Unique',
        annual_dol: 1207.87,
        description:
            'Sprawling, 36-hole course with practice facilities, a pro shop & a snack bar, plus frequent events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7438,
        store_longitude: -73.956,
        total_orders: 339.14,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Mexican, Shrimp Cocktail, Dessert, Peruvian, Latin, Delicious, Polished, Urban, Refined, Casual',
        annual_dol: 873.91,
        description:
            'Casual cantina serving up refined Mexican classics & cocktails amid concrete floors & mod seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7931,
        store_longitude: -73.9712,
        total_orders: 75.71,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Steak, Shrimp Cocktail, Pizza, Cajun, Korean, Asian, Trendy',
        annual_dol: 231.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5978,
        store_longitude: -73.9611,
        total_orders: 19.32,
        neighborhood: 'Sheepshead Bay/Gravesend/Homecrest',
        keywords:
            'Burger, Kebab, Salad, Pizza, Turkish, Mediterranean, Exquisite, Outstanding, Classic, Authentic',
        annual_dol: 78.99,
        description:
            '"Join us for an exquisite Mediterranean journey. Our chefs’ team has put together a menu that reflects the balance, diversity, and abundance of Turkish food culture as a way of celebrating the flavors and textures of our cuisine."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6898,
        store_longitude: -73.8438,
        total_orders: 46.08,
        neighborhood: 'Ozone Park/Jamaica/Richmond Hill',
        keywords:
            'Caribbean, Fried Chicken, Fried Rice, Karaoke, Jamaican, Ramen, Fusion, Wine, Modern, Fresh',
        annual_dol: 51.27,
        description:
            'Generous homestyle dishes are complemented by craft cocktails at this energetic Caribbean eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9741,
        total_orders: 6013.15,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Steak, Scotch, Hotel, Upscale, American, French, Premium, Iconic, Hip, Great',
        annual_dol: 6273.87,
        description:
            "Understated rooms with city views offer flat-screen TVs and Wi-Fi (fee), plus sitting areas, coffeemakers and rainfall showers. Suites add separate living areas, and an upgraded suite has a whirlpool tub and a Bose speaker system. Room service is available.There's a hip New American restaurant with a bar and floor-to-ceiling windows. Other amenities include a 24/7 business center, 2 ballrooms and 10 meeting rooms, along with a gym. Pets are welcome (fee).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.77,
        total_orders: 18.5,
        neighborhood: 'Bayside/Flushing',
        keywords: 'Pizza, Italian, Gluten Free, Martini, Beer, Delicious, Casual, Fresh',
        annual_dol: 23.25,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7653,
        store_longitude: -73.979,
        total_orders: 4003.25,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Apple Pie, Hotel, Italian, Luxurious, Elegant, Premium, Southern, Sleek, Chic, Natural',
        annual_dol: 4093.91,
        description:
            "Posh, airy rooms feature free Wi-Fi and flat-screen TVs, plus minibars, minifridges and Nespresso machines. They also have rainfall showers, deep-soaking tubs and designer Italian linens. Suites add living rooms and panoramic city views; upgraded suites include complimentary breakfast and minibars, as well as dining rooms and whirlpool tubs.There's an elegant restaurant and a cocktail lounge. Other amenities include a chic spa, an indoor pool and a fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9663,
        total_orders: 59.84,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Chicken Tikka Masala, Gluten Free, Chinese, Spicy, Creamy, Crisp, Indian, Casual',
        annual_dol: 244.59,
        description: '"Authentic Indian Restaurant."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6856,
        store_longitude: -73.9149,
        total_orders: 3044.61,
        neighborhood: 'Bushwick/Ocean Hill',
        keywords: 'Cajun, Beer, Margarita, Luxurious, Cuban, Hotel, Rum, French, Bbq, Vibrant',
        annual_dol: 5331.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6427,
        store_longitude: -73.6399,
        total_orders: 559.5,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Beer, Spicy, Pot Roast, Delicious, Organic, Artisanal, Lively, Contemporary',
        annual_dol: 596.7,
        description:
            'Airy, contemporary gastropub offering farm-to-table comfort food, house cocktails & a garden patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7586,
        store_longitude: -73.9886,
        total_orders: 774.36,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Wine, Cosmopolitan, Trendy, Coffee, Beer, Pizza, Margarita, Urban, Warm',
        annual_dol: 921.69,
        description:
            "Streamlined rooms feature flat-screen TVs, iPod docks and Wi-Fi. Upgraded rooms offer skyline views, while suites add separate living rooms. Upgraded suites have wet bars and kitchenettes.There's a chic cafe/lounge with a digital art gallery, and a trendy food court, as well as an ice cream shop, a convenience store and a hip salon. There's also a gym and a fitness studio with indoor cycling.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7409,
        store_longitude: -73.9884,
        total_orders: 796.1,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Tacos, Tequila, Mexican, Beer, Tasty, Coffee, Spicy, Spanish, Burritos, Quesadillas',
        annual_dol: 2999.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7794,
        store_longitude: -73.9497,
        total_orders: 384.92,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Hamburger, Upscale, Beer, Stylish, Romantic, Premium, American, Robust, Comfortable, Perfect',
        annual_dol: 410.51,
        description:
            'Swanky interior design & dim lighting make this intimate lounge a date-friendly spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7029,
        store_longitude: -73.9938,
        total_orders: 143.01,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords: 'Burger, Steak, Pizza, Salad, Italian, Wine, Bbq, Mediterranean, Chinese, Cozy',
        annual_dol: 166.82,
        description:
            'This spot offers an international menu beneath the Brooklyn Bridge with a wine list & outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6248,
        store_longitude: -74.1471,
        total_orders: 75.82,
        neighborhood: 'Mariners Harbor/Mid Island/Graniteville',
        keywords:
            'Lobster, Salad, Cajun, Steak, Lobster Bisque, Spanish, Caesar Salad, Clam Chowder, Fiery, Fun',
        annual_dol: 132.93,
        description:
            '"Born in the South where a feast means getting messy with friends, the seafood boil has been bringing people together for ages. At Hook & Reel our signature boil forks over bold flavors with a genuine, down-home feel. But eating seafood is only part of the fun. Everything tastes better elbow-to-elbow with friends, swapping stories over music, getting down and dirty and eating with your hands – all with a cold drink at the ready, ‘cause that spice ain’t no joke. We set out to share the saucy spirit of the boil with folks everywhere – testing our very own recipes, inspired by fiery Cajun home cooking. The Hook & Reel seafood boil dishes up the “reel” deal – the full backyard boil experience and flavor, with a twist."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7506,
        store_longitude: -73.9981,
        total_orders: 518.13,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Beer, Martini, Ale, Irish, Homemade, American, Cozy, Relaxed, Clean',
        annual_dol: 542.57,
        description:
            'Relaxed hangout with a wood-paneled bar offering American & Irish pub fare, plus craft beer on tap.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.75,
        store_longitude: -73.9899,
        total_orders: 5203.87,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Beer, Wine, Irish, Ale, Rustic, American, Classic, Traditional, Sweet',
        annual_dol: 5314.29,
        description:
            'Bi-level bar with long beer list (yes, many stouts) & TVs near Penn Station & Madison Square Garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9878,
        total_orders: 12819.48,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Pizza, Rooftop, Meatball, Vegetarian, Wine, Rum, Vodka, Triple Sec, Bellini, Global',
        annual_dol: 13091.49,
        description:
            'Chic penthouse bar & lounge featuring a rooftop garden & live music, plus global bites & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -74.0023,
        total_orders: 175.27,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Mexican, Argentinian, Wine, South American, Italian, Rich, Juicy, American, Spanish, Latin',
        annual_dol: 182.87,
        description:
            'Modern renditions of Argentine classics plus craft cocktails served in an intimate atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8426,
        store_longitude: -73.8857,
        total_orders: 10.56,
        neighborhood: 'Tremont/West Bronx',
        keywords: 'Burger, Pizza, Salad, Gourmet, Southern, Quesadillas, Local',
        annual_dol: 43.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7769,
        store_longitude: -73.9214,
        total_orders: 360.66,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Wine, Shrimp Cocktail, Upscale, Tasty, Delicious, Polished, Fine Quality, Cozy, Latin',
        annual_dol: 385.21,
        description:
            'Simple but polished restaurant with a patio serving prime sirloin, filet mignon & other meat dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7078,
        store_longitude: -73.9444,
        total_orders: 538.29,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Japanese, Coffee, Delicious, Asian, Sashimi, Caesar Salad',
        annual_dol: 1550.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9655,
        total_orders: 262.98,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Coffee, Dessert, French Toast, Avocado Toast, Upscale, French, Mediterranean',
        annual_dol: 280.46,
        description:
            'Informal option for all-day American eats & a full bar amid funky furnishings, plus sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8543,
        store_longitude: -73.8888,
        total_orders: 109.8,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Lasagna, Salad, Italian, Meatball, Delicious, Classy, Sweet, Fabulous, Southern, Excellent',
        annual_dol: 145.36,
        description:
            'Unfussy, decades-old Italian eatery serving up family-style pastas & entrees to communal tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7299,
        store_longitude: -73.9576,
        total_orders: 58.8,
        neighborhood: 'Greenpoint',
        keywords:
            'Upscale, Italian, Seasonal, American, French, Casual, Elegant, Mediterranean, Warm, Eclectic',
        annual_dol: 205.95,
        description: '"Dinner, drinks, brunch all week & takeout window."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6774,
        store_longitude: -73.9847,
        total_orders: 4.54,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Relaxed, Friendly, Casual, Phenomenal, Great',
        annual_dol: 18.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6822,
        store_longitude: -73.9958,
        total_orders: 838.25,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Vegetarian, Salad, Italian, Gluten Free, Wine, Meatball, Delicious, Upscale, Polished, Romantic',
        annual_dol: 874.59,
        description:
            'Neighborhood institution serving old-school, Sunday-dinner-style Italian with an old Brooklyn feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6825,
        store_longitude: -73.9931,
        total_orders: 3241.13,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Zombie, Vodka, Beer, Margarita, Tasty, Upscale, Exotic, Urban, Spacious, Stylish',
        annual_dol: 3427.54,
        description:
            'Pacific-Island decor sets the mood for exotic cocktails at this kitschy tiki bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.711,
        store_longitude: -74.0163,
        total_orders: 907.66,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Wine, Delicious, Mediterranean, Picturesque, Friendly, Polished, American, Fresh, Casual, Traditional',
        annual_dol: 994.5,
        description:
            'Mediterranean dishes & seafood in an airy restaurant with Hudson River views, plus 2 terraces.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.683,
        store_longitude: -73.9928,
        total_orders: 473.85,
        neighborhood: 'Carroll Gardens',
        keywords: 'Wine, Canadian, Coffee, Salad, Tasty, French, Cozy, Romantic, Classy, Sleek',
        annual_dol: 610.53,
        description:
            'Sleek eatery with a casual vibe serving French inspired fare alongside a deep wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9861,
        total_orders: 6685.83,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Upscale, Gin, Thai, Fun, Fried Chicken, Rum, Premium, Playful',
        annual_dol: 6975.71,
        description:
            'Craft cocktails with playful names are paired with elevated bites in a chic space with a red room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6012,
        store_longitude: -73.9767,
        total_orders: 40.75,
        neighborhood: 'Gravesend',
        keywords:
            'Salad, Sweet, Fun, Friendly, Awesome, Chinese, Adventurous, Great, Excellent, Casual',
        annual_dol: 172.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7516,
        store_longitude: -73.9789,
        total_orders: 4497.93,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Beer, Dessert, French, Rustic, Warm, American',
        annual_dol: 4705.46,
        description:
            'Upbeat alehouse featuring a wide range of draft brews & classic pub grub in a rustic-chic interior.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9831,
        total_orders: 4442.86,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Steak, Vegetarian, Wine, Salad, Tasty, Coffee, Beer, Rustic, Hotel, Italian',
        annual_dol: 5881.76,
        description:
            "The Orion is a skyscraper located at 350 West 42nd Street between Eighth and Ninth Avenues in the Hell's Kitchen or Clinton neighborhood of Manhattan, New York City, in the U.S. state of New York.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.881,
        store_longitude: -73.903,
        total_orders: 178.4,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Lobster, Spicy, Delicious, Cozy, Polished, Sleek, Perfect, Casual, Fun, Premium',
        annual_dol: 186.14,
        description:
            'Innovative Latin American plates & select wines are served in a sleek space with white banquettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7708,
        store_longitude: -73.8333,
        total_orders: 32.37,
        neighborhood: 'Flushing/Mitchell-Linden',
        keywords: 'Warm, Elegant, Perfect, Inviting, Fun, Ample, Casual, Classic, Local',
        annual_dol: 196.65,
        description:
            'Old-school, 48-lane bowling alley with a pro shop, party space, food services & cash-only policy.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.9704,
        total_orders: 671.19,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Wine, Tasty, Coffee, French, Champagne, American, Impeccable, Delicious',
        annual_dol: 734.77,
        description:
            'High-end chophouse offering prime meats & classic sides in sophisticated surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9728,
        total_orders: 729.01,
        neighborhood: 'Lenox Hill',
        keywords:
            'Hotel, Stylish, Elegant, Fabulous, Impeccable, Iconic, Timeless, Classic, Premium, Great',
        annual_dol: 760.62,
        description:
            "The elegant rooms have marble bathrooms and hand-carved wooden furniture, as well as flat-screen TVs, iPod docks and free Wi-Fi. Suites add kitchenettes.Amenities include a fitness center, a barbershop and a hair salon, plus multiple high-end shops, and a restaurant that's a replica of the famed Harry’s Bar in Venice, Italy.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6746,
        store_longitude: -73.9443,
        total_orders: 64.66,
        neighborhood: 'Crown Heights',
        keywords: 'Sushi, Beer, Meatball, Coffee, Spicy, Delicious, Funky, Thai, Fun, Asian',
        annual_dol: 281.15,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7554,
        store_longitude: -73.9811,
        total_orders: 4519.93,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Cheesesteak, Salad, Wine, Spicy, Caesar Salad, Filet Mignon, Modern, Fine, Attentive',
        annual_dol: 4615.84,
        description:
            'Swanky steakhouse with a raw bar boasting walls covered with photos of old-Hollywood stars.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7815,
        store_longitude: -73.9488,
        total_orders: 593.07,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Wine, Fun, Awesome, Great, Excellent, Local, Casual, Chill',
        annual_dol: 618.78,
        description:
            'Enduring neighborhood sports bar offering grills to cook your own meat, plus a pool table & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7803,
        store_longitude: -73.9502,
        total_orders: 15.9,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Mexican, Tequila, Tacos, Margarita, Hip, Local, Paloma, Fresh, Casual, Authentic',
        annual_dol: 65.01,
        description:
            'Branch of a casual, hip mini-chain for classic Mexican eats & margaritas, plus happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9898,
        total_orders: 19.79,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Wine, Coffee, Beer, Elegant, Warm, Funky, Hotel, Fun, Latin, Fresh',
        annual_dol: 86.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7508,
        store_longitude: -73.9897,
        total_orders: 1243.53,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Pizza, Italian, Meatball, Spicy, Wine, Elegant, Modern, Perfect, Authentic, Classic',
        annual_dol: 1277.73,
        description:
            "Elegant Italian fare & creative wood-fired pizzas in an airy setting on the 6th floor of Macy's.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9946,
        total_orders: 1926.25,
        neighborhood: 'Nolita',
        keywords: 'Whiskey, Beer, Fun, Fancy, Authentic, Casual, Great, Local',
        annual_dol: 1926.25,
        description:
            'Historic local watering hole offering beers & chips plus a jukebox, Christmas lights & memorabilia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8045,
        store_longitude: -73.9558,
        total_orders: 1840.98,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Chocolate Croissant, Salad, Milkshake, Wine, Coffee, Tasty, Organic, Lebanese, Rustic, Chicken Shawarma Plate',
        annual_dol: 1920.8,
        description:
            'This hip cafe & bar offers Middle Eastern eats such as shawarma & falafel, plus live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7732,
        store_longitude: -73.9784,
        total_orders: 1795.21,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Lasagna, Steak, Italian, Wine, Upscale, Hotel, Cozy, Quaint, Classy, Fancy',
        annual_dol: 1914.54,
        description:
            'A well-heeled crowd enjoys updated Southern Italian fare in a space decorated with historic murals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.767,
        store_longitude: -73.9862,
        total_orders: 739.8,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Salad, Mexican, Meatball, Coffee, Beer, Dessert, American, Belgian',
        annual_dol: 788.98,
        description:
            'An eclectic, Southwestern menu pairs with roadhouse-like decor at this casual spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9838,
        total_orders: 6675.67,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Upscale, African, Bourbon, French Toast, Beer, Nigerian, Global, French, Ethiopian, Fusion',
        annual_dol: 8010.13,
        description:
            'Swanky, chandelier-lit restaurant & cocktail spot serving creative international fusion bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.981,
        total_orders: 423.65,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Pizza, Irish, Gluten Free, American, Local, Cozy, Casual, Great',
        annual_dol: 1838.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8094,
        store_longitude: -73.9446,
        total_orders: 429.32,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Steak, Pizza, Sushi, Tequila, Mexican, Italian, Coffee, Rich, Fried Chicken',
        annual_dol: 576.68,
        description:
            'Chic hangout with sidewalk seats serving Senegalese & American soul food, plus French pastries.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9838,
        total_orders: 357.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Casual, Hip, Vivid',
        annual_dol: 372.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7659,
        store_longitude: -73.9923,
        total_orders: 326.4,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vegan, Vegetarian, Salad, Pizza, Wine, Italian, Dessert, Organic, Beer, Cozy',
        annual_dol: 441.67,
        description:
            'Locals are lured to the Italian fare served at this brightly lit corner cafe with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7642,
        store_longitude: -73.9833,
        total_orders: 1320.67,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Karaoke, Vodka, Nachos, Hotel, Funky, Salad, Iconic, Fun, Colorful, Excellent',
        annual_dol: 1470.47,
        description:
            'Cocktails served in a cool, neon-lit space with a funky bar area, pub fare & private karaoke rooms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.592,
        store_longitude: -73.5817,
        total_orders: 748.75,
        neighborhood: '',
        keywords: 'Burger, Sushi, Tacos, Pizza, Salad, Ale, Warm, Quaint, American, Modern',
        annual_dol: 781.22,
        description:
            'Neighborhood haunt serving steak, seasonal American fare & pub grub in a traditional atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8617,
        store_longitude: -73.925,
        total_orders: 21.26,
        neighborhood: 'Fort George',
        keywords:
            'Burger, Hamburger, Warm, Spanish, American, Latin, Perfect, Casual, Sangria, Great',
        annual_dol: 69.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7403,
        store_longitude: -73.9939,
        total_orders: 499.97,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Steak, Spanish, Dessert, Warm, Contemporary, Classic, Seasonal, Vibrant, Lively',
        annual_dol: 532.83,
        description:
            'Barcelona-style tapas & a deep menu of Spanish wines can be shared in this compact, busy spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -74.0015,
        total_orders: 558.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Meatball, Italian, Tasty, Dessert, Spicy, Wine, American, Seasonal, Yummy',
        annual_dol: 945.56,
        description:
            'Buzzy outfit serving a variety of house-ground meatballs, sauces & sides plus ice cream sandwiches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6811,
        store_longitude: -73.9771,
        total_orders: 19.83,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Meatball, Vodka, American, Asian, Creamy, Creative, Casual, Great',
        annual_dol: 81.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7203,
        store_longitude: -73.8093,
        total_orders: 31.08,
        neighborhood: 'Fresh Meadows/Flushing/Kew Gardens Hills',
        keywords:
            'Sushi, Lobster, Salad, Vegetarian, Zen, Thai, Japanese, Delicious, Creamy, Chinese',
        annual_dol: 60.93,
        description:
            'Contemporary eatery with a loungelike ambiance providing sushi as well as Thai & Japanese plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7149,
        store_longitude: -73.9516,
        total_orders: 23033.59,
        neighborhood: 'Williamsburg',
        keywords: 'Tacos, Hotel, Upscale, Gin, Casual, Great, Gimlet, Chill',
        annual_dol: 23755.76,
        description:
            'Popular bar set in a former pool supply store with live music & a taco truck in the backyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7239,
        store_longitude: -73.9788,
        total_orders: 4217.5,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Whiskey, Wine, Bourbon, Beer, Vintage, Fun, Classic, Intimate, Innovative, Wonderful',
        annual_dol: 4445.49,
        description:
            'Subdued 1970s-style bar with plush couches, a disco ball & waterbed, serving innovative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.6979,
        total_orders: 775.85,
        neighborhood: '',
        keywords: 'Hamburger, Beer, Wine, Coffee, Bbq, Cozy, Mimosa, Delicious, Swiss, American',
        annual_dol: 792.31,
        description:
            'Local haunt with a vintage vibe serving homestyle American fare, craft brews & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7229,
        store_longitude: -73.983,
        total_orders: 77.46,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Steak, Sushi, Vegan, Mexican, Meatball, American, Latin, Asian, Casual, Yummy',
        annual_dol: 271.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5791,
        store_longitude: -74.1636,
        total_orders: 80.07,
        neighborhood: 'New Springville/Mid Island',
        keywords: 'Burger, Tequila, Pizza, Sushi, Whiskey, Vodka, Gin, Rum, Japanese, Delicious',
        annual_dol: 327.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7415,
        store_longitude: -73.9784,
        total_orders: 263.46,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Korean, Wine, Ale, Modern, Intimate, Bbq, Premium, Friendly, Kimchi Fried Rice, Fried Rice',
        annual_dol: 307.82,
        description:
            '"Sam Sunny is a new intimate Korean restaurant located in the center of Kips Bay, Manhattan. We pride ourselves to serve authentic Korean food with a modern twist in NYC. Large hip restaurant with over 80 seats with bar, booths, and tables."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7457,
        store_longitude: -73.9842,
        total_orders: 764.81,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Champagne, Hotel, Luxurious, Stylish, Elegant, Coffee, Premium, Vibrant, Urban, Great',
        annual_dol: 2881.54,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6814,
        store_longitude: -74.0043,
        total_orders: 3481.24,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Burger, Vegetarian, Mexican, Beer, Whiskey, Wine, Tasty, Breakfast Burrito, American, African',
        annual_dol: 3858.67,
        description:
            'This watering hole housed in a historic space offers a menu of elevated pub grub & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6822,
        store_longitude: -73.9291,
        total_orders: 3207.62,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords: 'Burger, Hamburger, Dessert, French, Spicy, Wine, Funky, American, Yummy, Casual',
        annual_dol: 3275.68,
        description:
            'Venerable option favored for French bistro fare & happy hours served in hip surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6823,
        store_longitude: -73.9291,
        total_orders: 1000.76,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Tequila Sunrise, Tequila, Zombie, Margarita, Rum, French, American, Cointreau, Cozy, Casual',
        annual_dol: 1021.99,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7404,
        store_longitude: -73.9304,
        total_orders: 380.45,
        neighborhood: 'Astoria/LIC',
        keywords: 'Salad, Caesar Salad, Greek, Greek Salad, Unforgettable, Cobb Salad',
        annual_dol: 491.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7517,
        store_longitude: -73.9394,
        total_orders: 97.69,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Pizza, Salad, Italian, Shrimp Cocktail, Wine, Beer, Premium, Modern, Fun, Seasonal',
        annual_dol: 399.34,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7353,
        store_longitude: -73.8749,
        total_orders: 2998.16,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Pizza, Salad, Gluten Free, Beer, Wine, Caesar Salad, Creamy',
        annual_dol: 3006.18,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7354,
        store_longitude: -73.983,
        total_orders: 169.88,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Italian, Salad, Dessert, Trendy, Delicious, Spacious, Polished, Vintage, Classy',
        annual_dol: 203.26,
        description:
            'Cozy, traditional Italian restaurant with housemade pasta, risotto & hearty mains, plus drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7351,
        store_longitude: -73.9831,
        total_orders: 45.54,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Vegetarian, Salad, Butter Chicken, Chicken Tikka Masala, Chili Chicken, Indian, Rich, Casual, Paneer Tikka, Premium',
        annual_dol: 52.33,
        description:
            'Typical Indian dishes like samosas, Malabar chicken & pakora are offered at this low-key locale.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.539,
        store_longitude: -74.1485,
        total_orders: 103.24,
        neighborhood: 'Great Kills',
        keywords:
            'Pizza, Italian, Wine, Beer, Classy, Exceptional, Great, Excellent, Local, Casual',
        annual_dol: 114.36,
        description:
            '"Italian Home Cooking Brick Oven Pizza Over Sized Portions of exceptional food at great prices. We have a full bar and an expansive outdoor dining area"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8848,
        store_longitude: -73.9,
        total_orders: 4162.7,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Burger, Hamburger, Beer, Spicy, Bbq, Wine, Warm, Ale, Crisp, Delicious',
        annual_dol: 4162.7,
        description:
            'Burgers, wings & hearty sandwiches washed down with a selection of craft beers in a casual saloon.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6248,
        store_longitude: -74.0307,
        total_orders: 992.89,
        neighborhood: 'Bay Ridge',
        keywords:
            'Italian, Coffee, Beer, Organic, Elegant, Impeccable, Authentic, Fun, Classic, Modern',
        annual_dol: 1520.94,
        description:
            '"Baci Ristorante is a new Italian Restaurant located in Brooklyn, we pride ourselves on providing Authentic Italian Food to our customers. Come check us out and you won\'t be disappointed! Baci!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6929,
        store_longitude: -74.0017,
        total_orders: 5119.8,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Pizza, Rooftop, Italian, Tasty, Beer, Wine, Trendy, Awesome, Prosecco, Great',
        annual_dol: 6135.65,
        description:
            'This trendy pizzeria with a rooftop patio offers wood-fired pies plus sandwiches & artisanal sodas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7592,
        store_longitude: -73.9853,
        total_orders: 6917.75,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Tequila, Sushi, Pizza, Hotel, Stylish, Delicious, Beer, Nutritious, Spacious, Wholesome',
        annual_dol: 7070.24,
        description:
            "Art.Polished rooms with high-thread-count linens feature Wi-Fi (fee), flat-screen TVs and DVD players, plus minibars and coffeemakers. Upgraded rooms add views of Times Square, and suites have living rooms with pull-out sofas. Room service is available 24/7.There's a 2-story Mexican restaurant with a tequila bar, a plush eatery serving sushi and cocktails, and a vibrant Times Square-inspired lounge with DJs. There's also a gym. Pets are welcome for a fee.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -74.0011,
        total_orders: 1477.68,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Burger, Hotdog, Steak, Hamburger, Gourmet, Vodka, Spicy, Nachos, Bbq, Delicious',
        annual_dol: 2148.54,
        description: 'Modern eatery featuring gourmet hot dogs & burgers, plus sides & milkshakes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.9872,
        total_orders: 2730.6,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Steak, Salad, Irish, Homemade, American, Romantic, Fun, Casual, Classic',
        annual_dol: 2788.54,
        description:
            'Boisterous Irish sports bar with DJs at night & a menu of American & Irish pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7581,
        store_longitude: -73.9863,
        total_orders: 12480.34,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Amazing, Awesome, Iconic, Great',
        annual_dol: 15428.1,
        description:
            "The Minskoff Theatre is a Broadway theater on the third floor of the One Astor Plaza office building in the Theater District of Midtown Manhattan in New York City. Opened in 1973, it is operated by the Nederlander Organization and is named after Sam Minskoff and Sons, the building's developers.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7581,
        store_longitude: -73.9883,
        total_orders: 1959.89,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Garlic Bread, Pizza, Salad, Dessert, Italian, Meatball, Wine, Creamy, Beer, Margarita',
        annual_dol: 2135.97,
        description:
            'Famous circa-1929 Village pizzeria slings trademark thin-crust pies in a soaring Midtown space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9865,
        total_orders: 327.16,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Lasagna, Vegan, Vegetarian, Salad, Tasty, Coffee, Dessert, Wine, Bbq',
        annual_dol: 341.91,
        description:
            'Place for vegan plates & bar snacks with style, donating all of its profits to charity.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6899,
        store_longitude: -73.9868,
        total_orders: 602.07,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Crab Cakes, Burger, Cornbread, Steak, Shrimp Cocktail, Salad, Bbq, Margarita, Beer, Korean',
        annual_dol: 686.76,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9898,
        total_orders: 650.72,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Steak, Tequila, Cornbread, Beer, Rich, Bbq, Southern, Great, Fresh, Casual',
        annual_dol: 2342.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6884,
        store_longitude: -73.9547,
        total_orders: 266.22,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Burger, Hamburger, Tasty, French, Warm, American, Old Fashioned, Friendly, Perfect, Casual',
        annual_dol: 383.39,
        description:
            'Straightforward cafe with a take-out option doling out classic burgers, hot dogs, fries & wings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6761,
        store_longitude: -73.9417,
        total_orders: 548.31,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Whiskey, Vodka, Beer, Bourbon, Rum, Awesome, Iconic, Great, Cool',
        annual_dol: 746.42,
        description:
            '"Kingston Public House is Brooklyn’s most curious, cocktail bar. A unique spot to meet your friends for drinks and great music. KPH features great wines, rose (on tap), craft beers, scotch, whiskey, gin, vodka & unique cocktails. See you soon! As a new addition to the neighborhood! Kingston Public House is conveniently located in the iconic Brooklyn neighborhood of Crown Heights, just 2.4 mi of Barclays Center and within close proximity to major transportation."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7525,
        store_longitude: -73.9935,
        total_orders: 5869.93,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Pizza, Salad, Italian, Wine, Upscale, Margarita, Delicious, Hotel, Classy',
        annual_dol: 5998.89,
        description:
            'Pasta, thin-crust pizza & Italian entrees served amid art deco accents inside the New Yorker Hotel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7477,
        store_longitude: -73.9865,
        total_orders: 337.58,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Korean, Salad, Bbq, Spicy, Kbbq, Authentic, Perfect, Local, Unpretentious, Amazing',
        annual_dol: 375.59,
        description:
            'Always-open Korean BBQ mainstay distinguishing itself with charcoal-fueled grills.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7497,
        store_longitude: -73.986,
        total_orders: 1325.5,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Shrimp Cocktail, Crab Cakes, Beer, Wine, Italian, Irish, French, Hotel, American',
        annual_dol: 4653.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7449,
        store_longitude: -73.994,
        total_orders: 28.46,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Beer, Upscale, Warm, Delicious, Creamy, Friendly, American, Exotic, Classic',
        annual_dol: 116.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -73.9862,
        total_orders: 66.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Korean, Spicy, Beer, Karaoke, Sweet, Fun, Bbq, Fresh, Perfect, Casual',
        annual_dol: 270.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7289,
        store_longitude: -73.9995,
        total_orders: 9330.87,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Margarita, Mimosa, Vegetarian, Spicy, Enchiladas, American, Latin, Contemporary, Healthy',
        annual_dol: 12306.98,
        description:
            'NoHo outpost of a small chain known for inventive Mexican fare & margaritas, plus a vegetarian menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7666,
        store_longitude: -73.9655,
        total_orders: 583.33,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Cosmopolitan, Wonderful, Local',
        annual_dol: 608.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7699,
        store_longitude: -73.9666,
        total_orders: 700.62,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Salad, Cajun, Wine, French, Thai, Organic, Stylish, Caesar Salad, Classic',
        annual_dol: 715.49,
        description:
            'French bistro with Parisian ambiance, a stylish crowd, closely spaced tables & classic fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7613,
        store_longitude: -73.9609,
        total_orders: 73.41,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Kebab, Salad, Turkish, Mediterranean, Korean, Greek, Delicious, Hotel, Greek Salad',
        annual_dol: 300.09,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7449,
        store_longitude: -73.9532,
        total_orders: 567.11,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Lasagna, Coffee, Dessert, Warm, Bruschetta, American, Yummy, Bright, Seasonal, Modern',
        annual_dol: 842.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6761,
        store_longitude: -73.9991,
        total_orders: 924.5,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Wine, Whiskey, Scotch, Beer, Vegetarian, Coffee, Margarita, Bourbon, Japanese, French Onion Soup',
        annual_dol: 1540.45,
        description:
            'Clever cocktails, a huge whiskey & spirits selection & small plates in a narrow, laid-back space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6043,
        store_longitude: -73.9745,
        total_orders: 279.88,
        neighborhood: 'Gravesend',
        keywords:
            'Coffee, Pizza, Wine, Mexican, Italian, American, Clean, Friendly, Casual, Indian',
        annual_dol: 391.72,
        description:
            '"Come join us for coffee, cocktails, wine and beer. Weekends come dance to live Dj music. Our friendly atmosphere will make you feel at home."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7947,
        store_longitude: -73.9659,
        total_orders: 4366.13,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Burger, Vegan, Hamburger, Salad, Gluten Free, Organic, Beer, Sustainable, Delicious, Healthy',
        annual_dol: 7135.01,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5504,
        store_longitude: -74.1513,
        total_orders: 68.13,
        neighborhood: 'Great Kills',
        keywords: 'Beer, Swiss, Ale, American, Classic, Great, Casual',
        annual_dol: 278.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7585,
        store_longitude: -73.9832,
        total_orders: 4349.53,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Beer, Wine, Tasty, American, Relaxed, Classic, Irish, Traditional, Friendly, Smooth',
        annual_dol: 5102.56,
        description:
            'Relaxed neighborhood hangout specializing in hamburgers, plus traditional pub food, brunch & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7601,
        store_longitude: -73.989,
        total_orders: 11758.34,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Hamburger, Meatloaf, Salad, Shrimp Cocktail, Wine, Beer, Upscale, Delicious',
        annual_dol: 12075.43,
        description:
            'Snug, classic tavern attracting Broadway patrons & actors with American comfort food & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6317,
        store_longitude: -74.1469,
        total_orders: 317.02,
        neighborhood: 'Mariners Harbor',
        keywords:
            'Salad, Vegetarian, Pizza, Italian, Flavorful, Avocado Toast, Trendy, Homemade, Warm, Homey',
        annual_dol: 347.85,
        description:
            'Italian family-style eatery & bar with a warm ambiance serving pizza alongside Sicilian specialties.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6866,
        store_longitude: -73.994,
        total_orders: 398.37,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Tequila, Tacos, Mexican, Steak, Margarita, Tex-Mex, Breakfast Burrito, Delicious, American, Fresh',
        annual_dol: 443.89,
        description:
            'Tex-Mex standards and potent margaritas draw locals to this Southwestern spot with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.986,
        total_orders: 7834.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Rooftop, Hotel, Wine, Tasty, Delicious, Beer, Comfortable, Relaxed, Refined, Iconic',
        annual_dol: 8685.51,
        description:
            'Hip lounge with modern decor offering craft beer, wine & cocktails, plus skyscraper views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7459,
        store_longitude: -73.9935,
        total_orders: 1483.7,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Whiskey, Ale, Wine, Tequila, Beer, Vodka, Rustic, Gin, Upscale, Stylish',
        annual_dol: 1528.44,
        description:
            'Stylish tavern lined with bookshelves dispensing American pub fare with nostalgic flair.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7535,
        store_longitude: -73.999,
        total_orders: 1907.8,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Spanish, Spicy, Stylish, Mediterranean, Italian, Homey, Elegant, Fancy, Southern, Casual',
        annual_dol: 7237.44,
        description:
            'Airy restaurant featuring elevated Spanish dishes & tapas amid stylish surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7801,
        store_longitude: -73.9803,
        total_orders: 1695.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Thai, Garlic Bread, Dessert, Beer, Chinese, Green Curry, Chilean, Hotel, Pad Thai, Red Curry',
        annual_dol: 1731.07,
        description:
            'Curries & street food in an urbane, wood-lined restaurant with wood carvings & a bohemian vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7771,
        store_longitude: -73.9777,
        total_orders: 4671.84,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Wine, Vegetarian, Salad, Italian, Gourmet, Dessert, Beer, Delicious, Japanese',
        annual_dol: 4990.31,
        description:
            'Lively corner spot offering an expansive wine list, classic cocktails & gourmet Italian bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.985,
        total_orders: 314.45,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegetarian, Salad, Vegan, Butter Chicken, Chicken Tikka Masala, Wine, Tangy, Spicy, Indian, Impeccable',
        annual_dol: 1285.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7661,
        store_longitude: -73.9798,
        total_orders: 42.83,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Pizza, Steak, Salad, Dessert, Tasty, American, Organic, Greek, Omelette',
        annual_dol: 175.08,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.818,
        store_longitude: -73.9608,
        total_orders: 225.52,
        neighborhood: 'Upper Manhattan',
        keywords:
            'Cuban, Dominican, Jamaican, Wine, American, Czech, Fusion, Latin, Traditional, Iconic',
        annual_dol: 943.14,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7212,
        store_longitude: -73.9883,
        total_orders: 749.41,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Lively, Fresh, Great, Eclectic, Classic, Incredible, Casual, Chill',
        annual_dol: 781.9,
        description:
            "Up-&-coming acts are the focus of this rock music bar that's also home to live-band karaoke.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7199,
        store_longitude: -73.9887,
        total_orders: 2849.4,
        neighborhood: 'Lower East Side',
        keywords: 'Cajun, Vegan, Salad, Beer, Martini, Tangy, Delicious, Italian, Hotel, Cozy',
        annual_dol: 2972.94,
        description:
            'Relaxed gastropub offering cocktails & eclectic nibbles with an Asian twist in a cozy atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8426,
        store_longitude: -73.9238,
        total_orders: 14.82,
        neighborhood: 'Highbridge/West Bronx',
        keywords: 'Steak, Mongolian, Ramen, Latin, Casual, Pure, Gyoza',
        annual_dol: 60.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9839,
        total_orders: 37.49,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Sushi, Salad, Shrimp Scampi, Asian, American, Filet Mignon, Casual, Excellent, Local',
        annual_dol: 153.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7466,
        store_longitude: -73.9914,
        total_orders: 60.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Cosmopolitan, Hotel, Friendly, Clean, Delicious, American',
        annual_dol: 78.12,
        description:
            "Understated rooms offer free Wi-Fi and custom-designed beds, plus flat-screen TVs, microwaves, minifridges, and desks with ergonomic chairs. Some have city views.A cooked-to-order and buffet breakfast is available (fee), and there's an American restaurant, a bar and a 24/7 pantry market. Other amenities include a fitness room and a business center. Parking is available (surcharge).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6775,
        store_longitude: -73.8287,
        total_orders: 2945.2,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Burger, Cheesecake, Pizza, Milkshake, Salad, Beer, Gourmet, Dessert, Tasty, Savory',
        annual_dol: 10271.44,
        description:
            'Cheerful, kitschy all-day eatery offering hearty burgers, pizza & pasta, plus salads & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6194,
        store_longitude: -74.0278,
        total_orders: 60.1,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Sushi, Tacos, Kebab, Steak, Pizza, Shrimp Cocktail, Italian, Warm, Mediterranean, Cozy',
        annual_dol: 245.67,
        description:
            '"Elegant restaurant with excellent service and charming atmosphere. offering fusion cuisine and signature drinks."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5445,
        store_longitude: -74.1624,
        total_orders: 2798.81,
        neighborhood: 'Eltingville',
        keywords:
            'Burger, Steak, Tacos, Lobster, Pizza, Salad, Gourmet, Beer, Lobster Bisque, Wine',
        annual_dol: 2820.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -73.9974,
        total_orders: 1155.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Wine, French, Polished, Cozy, Classy, Relaxed, Warm, Perfect, Friendly',
        annual_dol: 1420.13,
        description:
            'Bistro serving French fare in a cozy interior & on a sidewalk patio plus cocktails at the bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6867,
        store_longitude: -73.9758,
        total_orders: 247.78,
        neighborhood: 'Fort Greene',
        keywords: 'Kebab, Salad, Pizza, German, Turkish, Wine, Mediterranean, Thai, Baklava, Cozy',
        annual_dol: 351.77,
        description:
            'Mediterranean-accented Turkish cuisine with an emphasis on seafood served in a spacious setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -73.9882,
        total_orders: 2690.84,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Steak, Wine, Flavorful, French, Delicious, Classy, Cozy, Quaint, Traditional, Perfect',
        annual_dol: 2810.7,
        description:
            'Longtime East Village eatery showcasing classic French bistro cooking in a Parisian-style room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.9784,
        total_orders: 179.87,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Sushi, Whiskey, Vodka, Trendy, Cozy, Classy, Polish, Perfect, Classic, Lively',
        annual_dol: 735.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.9861,
        total_orders: 850,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Steak, Burger, Cheesesteak, Vegetarian, Pizza, Salad, Beer, Authentic, Homemade, Fresh',
        annual_dol: 868.03,
        description:
            '"Authentic cheesesteaks. Specialty cheesesteaks. Signature roast pork and chicken cutlet sandwiches with homemade broccoli rabe and Cooper sharp american. Bread shipped in from Philly and baked in-house every hour. Killer wings, salads, and fries. Craft beer on tap. Craftier cocktails. Televisions. Sports. Do we have your attention yet? Welcome to Olde City Cheesesteaks & Brew. Philly native and Olde City founder Evan Stein has been serving up cheesesteaks in NYC since 2005 with his original concept Shorty’s. Evan has moved on to launch this truly unique brand. Each item on the menu is delicious. The beer selection is carefully curated with local breweries from PA and NY."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7102,
        store_longitude: -73.9641,
        total_orders: 220.76,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Lobster, Salad, Vegetarian, Italian, Martini, Creamy, French, Polished, Contemporary, American',
        annual_dol: 557.56,
        description:
            'Convivial brasserie with an open kitchen serving up European-inspired dishes & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7205,
        store_longitude: -73.8462,
        total_orders: 2256.26,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Beer, Wine, Coffee, Avocado Toast, Bbq, Irish, Upscale, Thai, Latin',
        annual_dol: 3585.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7751,
        store_longitude: -73.948,
        total_orders: 804.46,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Irish, Rich, Friendly, Traditional, Casual, Great, Local, Chill',
        annual_dol: 2626.37,
        description:
            'This old-timey neighborhood tavern with a long bar & tin ceiling offers draft beer, pinball & darts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7772,
        store_longitude: -73.9489,
        total_orders: 561.34,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Cheesesteak, Beer, Meatball, Ale, Bbq, Irish, American, Thai, Homemade',
        annual_dol: 591.32,
        description:
            'Dark-wood-lined Irish pub with simple bar eats, draft beer and lots of TVs for sports fans.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7098,
        store_longitude: -73.5914,
        total_orders: 16.51,
        neighborhood: '',
        keywords: 'Burger, Pizza, Tequila, Steak, Beer, Wine, Karaoke, Rum, Local, Casual',
        annual_dol: 19.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7364,
        store_longitude: -74.0078,
        total_orders: 1006.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Wine, Beer, Gourmet, Japanese, French, Classy, Perfect, Casual, Cool',
        annual_dol: 1092.72,
        description:
            'This no-frills burger & beer joint becomes a lively & often crowded party scene after the sun sets.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7406,
        store_longitude: -73.638,
        total_orders: 524.65,
        neighborhood: '',
        keywords:
            'Flavorful, Wine, Rich, Delicious, Shrimp Cocktail, Coffee, Upscale, Italian, Cozy, Eclectic',
        annual_dol: 579.78,
        description:
            'Upscale seafood dishes & choice steaks featured along with global wines in an 1862 building.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7245,
        store_longitude: -73.6487,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Steak, Hurricane, Hawaiian, French Onion Soup, Beer, Hotel, Swiss, American, French, Seasonal',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9194,
        total_orders: 67.47,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Mexican, American, Modern, Spanish, Salad, Colombian, Latin, Karaoke, Homemade, Casual',
        annual_dol: 194.34,
        description:
            '"The restaurant offers dishes such as tacos-including pork, beef, chicken and vegetable tacos, red snapper, crab cakes, homemade guacamole, and quinoa salad with balsamic shrimp. At night, weekday karaoke days and DJs. with salsa nights on weekends."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8564,
        store_longitude: -73.8908,
        total_orders: 41.81,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Steak, Margarita, Dominican, Spanish, Puerto Rican, Tasty, American, Latin, Casual',
        annual_dol: 62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7063,
        store_longitude: -73.9432,
        total_orders: 1365.47,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Burger, Delicious, Warm, Sweet, Relaxed, Hot Toddy, Homey, Cool, Fresh, Casual',
        annual_dol: 1394.44,
        description:
            'Dim lighting & subway tiled-walls create a cool vibe at this eatery known for its burgers & big bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7695,
        store_longitude: -73.9576,
        total_orders: 335.35,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Peruvian, Wine, Salad, Dessert, Delicious, Vibrant, Classic, Casual, Awesome, Diverse',
        annual_dol: 381.65,
        description:
            'Sit-down branch of a fast casual mini-chain serving Peruvian fare with a focus on ceviche.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 67.54,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Hotdog, Steak, Hamburger, Vegetarian, Vegan, Mexican, Beer, Margarita, Venezuelan',
        annual_dol: 276.09,
        description:
            'Hip, casual eatery serving Venezuelan street food such as yuca fries, burgers & hot dogs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.732,
        store_longitude: -74.0015,
        total_orders: 923.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Mexican, Margarita, Vodka, Wine, Spanish, Salad, Peruvian, Enchiladas, Fajitas',
        annual_dol: 1059.19,
        description:
            'Paella & sangria mingle with enchiladas & margaritas at this lively East Village landmark.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7399,
        store_longitude: -73.6033,
        total_orders: 411.5,
        neighborhood: '',
        keywords: 'Italian, Wine, Gourmet, Hotel, Signature, Unique',
        annual_dol: 750.96,
        description:
            '"Life Time Garden City is more than a gym, it’s an athletic country club. Life Time has something for everyone: an expansive fitness floor, unlimited studio classes, basketball courts, eucalyptus steam rooms, and indoor and outdoor pools. Receive unlimited group training and individualized attention when you choose a Signature Membership. Or get started at home with our Digital Membership, featuring virtual training programs, a library of on-demand class videos and award-winning health and wellness content. Build your membership today."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8693,
        store_longitude: -73.8267,
        total_orders: 1630.37,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Pizza, Cajun, Beer, Delicious, Clam Chowder, American, Classy',
        annual_dol: 1669.03,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7867,
        store_longitude: -73.9756,
        total_orders: 1418.9,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Beer, Salad, Fried Chicken, Delicious, American, Southern, Fun, Natural',
        annual_dol: 1480.42,
        description:
            'Southern bar & eatery focused on fried chicken biscuit sandwiches, housemade pickles & beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7865,
        store_longitude: -73.9725,
        total_orders: 297.17,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'French, French Onion Soup, Delicious, White Russian, Casual, Russian, Authentic, Classic, Fine, Great',
        annual_dol: 1043.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.5539,
        total_orders: 21.45,
        neighborhood: '',
        keywords: 'Pizza, Burger, Italian, Beer, Sushi, Salad, American, Bbq, Wine, Premium',
        annual_dol: 138.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8478,
        store_longitude: -73.8567,
        total_orders: 19.02,
        neighborhood: 'Morris Park',
        keywords:
            'Pizza, Chicken Parmigiana, Meatball, Vodka, Italian, Beer, Wine, Veal Parmigiana, Organic, Creamy',
        annual_dol: 54.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7378,
        store_longitude: -74.0023,
        total_orders: 30.81,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lobster, Wine, Coffee, Bourbon, French, American, Quaint, Yummy, Hip, Intimate',
        annual_dol: 125.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5735,
        store_longitude: -73.981,
        total_orders: 1017.58,
        neighborhood: 'Coney Island',
        keywords: 'Casual, Hotel',
        annual_dol: 5026.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6787,
        store_longitude: -73.9861,
        total_orders: 251.5,
        neighborhood: 'Gowanus',
        keywords:
            'Italian, Wine, Spicy, Warm, Seasonal, Casual, Authentic, Classic, Fantastic, Great',
        annual_dol: 268.03,
        description:
            'In a redone auto-body shop, this Italian place offers wood-fired Italian fare & housemade pastas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8084,
        store_longitude: -73.7405,
        total_orders: 237.29,
        neighborhood: '',
        keywords: 'Zombie, Pizza, Premium, Hotel, Great, Fun, Asian',
        annual_dol: 388.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7485,
        store_longitude: -73.6176,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Sushi, Steak, Salad, Wine, Japanese, Beer, Premium, Friendly, Local, Excellent',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7512,
        store_longitude: -73.9849,
        total_orders: 1684.82,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Rooftop, Upscale, Hotel, Wine, Tasty, Whiskey, Fantastic, Beer, Great, Intimate',
        annual_dol: 1720.57,
        description:
            'Intimate rooftop lounge at the Strand Hotel featuring dramatic views, cocktails & retractable roof.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7436,
        store_longitude: -73.9198,
        total_orders: 186.7,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Whiskey, Beer, Irish, Ale, Salad, Fancy, Comfortable, Nachos, Friendly, Amazing',
        annual_dol: 202.32,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9978,
        total_orders: 981.87,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Whiskey, Mexican, Stylish, Spicy, Hamburger, Italian, American, French, Polished',
        annual_dol: 1721.44,
        description:
            'Glitzy, arena-size restaurant from the storied ex-Knicks star with globally inflected American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7402,
        store_longitude: -73.9238,
        total_orders: 148,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Pizza, Tasty, Wine, Beer, Spicy, Polish, Fun, Awesome, Great, Crafted',
        annual_dol: 696.96,
        description: '"Whiskey Wine Bar and Crafted Cocktails"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8563,
        store_longitude: -73.7046,
        total_orders: 1303.66,
        neighborhood: '',
        keywords: 'Golfclub, Italian, Rich, Fine',
        annual_dol: 1423.66,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6248,
        store_longitude: -74.0748,
        total_orders: 440.33,
        neighborhood: 'Clifton',
        keywords: 'Wine, Delicious, Italian, Warm, Cozy, Fun, Premium, Awesome, Amazing, Meatball',
        annual_dol: 481.86,
        description:
            '"Italian restaurant rated number #1 in Staten Island by the Advance Bibb Gourmand Michelin rated 2019-2020-2021-2022 Top 25 Wine Bar “Time Out” Magazine Wine Spectator Award 2020-2021-2022 Best outdoor setting in Staten Island by Al Fresco NYC"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6141,
        store_longitude: -73.713,
        total_orders: 547.28,
        neighborhood: '',
        keywords: 'Golfclub, Amazing',
        annual_dol: 648.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -74.0075,
        total_orders: 1324.26,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Japanese, Beer, Asian, Chinese, Modern, Thai, Fried Rice, Rustic, Fusion',
        annual_dol: 3512.03,
        description:
            '"The Buddha-Bar New York City, is ideally located within the industrial-turned-lifestyle heart of New York’s TriBeCa neighborhood. The restaurant, bar, and lounge sit within a landmarked historic building in a vaulted two-story space with a meticulous, opulent modern design perfectly aligned with the rustic beams and bricks of the original structure. The return of a custom, 16-foot, glass-hewn Buddha sculpture, music, and energy from the resident DJ, globally-inspired Asian Fusion cuisine, and the famed vibe of Buddha-Bar to New York City."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7041,
        store_longitude: -74.0103,
        total_orders: 1524.71,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Salad, Pizza, Beer, Vodka, Spicy, French, American, Relaxed, Omelette',
        annual_dol: 1560.67,
        description:
            "Sports on big screen TVs, draft beer & pub food in a historic building that's stood since 1603.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.777,
        store_longitude: -73.9526,
        total_orders: 1863.55,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Beer, Funky, Spanish, Wine, Fun, Greek, American, Awesome, Great',
        annual_dol: 1863.55,
        description:
            'Funky 1970s-themed haunt with bar bites & specialty cocktails, plus go-go dancers & DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.8153,
        total_orders: 7.06,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Steak, Burger, Mexican, Bbq, Coffee, Cuban, Dominican, Swiss, American, Latin',
        annual_dol: 28.87,
        description:
            'Large, easygoing locale featuring Caribbean & Latin American dishes such as mofongo & pernil.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7542,
        store_longitude: -73.99,
        total_orders: 830.77,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Hamburger, Salad, Bread Pudding, Mexican, Beer, Milkshake, Margarita, Dessert, Tasty',
        annual_dol: 933.7,
        description:
            'Cozy, easygoing wood-&-brick eatery with hefty specialty burgers & craft beers on tap.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6554,
        store_longitude: -73.6455,
        total_orders: 194.01,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Rustic, French Toast, French, American, Delicious, Crisp, Casual',
        annual_dol: 271.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7839,
        store_longitude: -73.6634,
        total_orders: 294.97,
        neighborhood: '',
        keywords: 'Traditional, Delicious, Cool, Local, Modern',
        annual_dol: 385.22,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7467,
        store_longitude: -73.6202,
        total_orders: 3987.09,
        neighborhood: '',
        keywords:
            'Sushi, Vegetarian, Salad, Gluten Free, Wine, Japanese, Chinese, Delicious, Sashimi, Stylish',
        annual_dol: 4208.92,
        description:
            'Sushi & teppanyaki offered in a buzzy setting with a long stylish bar & a room with hibachi tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.921,
        total_orders: 600.74,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Burger, Beer, American, Wine, Spicy, Warm, Cozy, Quaint, Seasonal, Casual',
        annual_dol: 720.1,
        description:
            'Warm, wood-hued eatery offering craft beers & cocktails, plus elevated pub grub & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7906,
        store_longitude: -73.9477,
        total_orders: 744.15,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Peruvian, Salad, Gourmet, Shrimp Scampi, Tasty, Latin, Yummy, Casual, American, Authentic',
        annual_dol: 776.41,
        description:
            'Casual spot offering a range of gourmet pizzas & Peruvian staples, plus pasta & lunch specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7251,
        store_longitude: -73.5478,
        total_orders: 1058.69,
        neighborhood: '',
        keywords:
            'Vegan, Steak, Wine, Vegetarian, Tasty, Savory, Organic, Irish, Upscale, Caribbean',
        annual_dol: 2363.8,
        description:
            '"NK Restaurant (Nuvo Kitchen), an Eighteen 04 Restaurant Group venture, is a unique fusion twist on traditional Caribbean cuisine inspired by multicultural backgrounds all infused by Rum! - Dress code is business/casual. **Sports caps, bandanas, hoodies or beanies are not allowed in the restaurant. Coat check is complimentary."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9928,
        total_orders: 601.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Coffee, Chic, Contemporary, Friendly, Local',
        annual_dol: 651.34,
        description:
            "Sleek, simple rooms have noise-reducing windows, plus free Wi-Fi, flat-screen TVs and coffeemakers. Most have minibars, and higher floors provide city views. Upgraded options add designer coffeemakers, iPod docks and 24/7 lobby beverages. Room service is available.Complimentary amenities include happy-hour hors d'oeuvres for guests, and access to a nearby gym. There's also a lobby bar serving morning breakfast and evening cocktails.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.754,
        store_longitude: -73.9887,
        total_orders: 8421.32,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Whiskey, Upscale, Rooftop, Vodka, Beer, Spectacular, Flavorful, Gin, American',
        annual_dol: 8786.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.985,
        total_orders: 392.58,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Salad, Vegan, Vegetarian, Cuban, Dessert, Margarita, Gluten Free, Savory, Martini, Spicy',
        annual_dol: 1240.32,
        description:
            'A bar pours mojitos up front & a brick-lined dining room in the back serves traditional Cuban fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7515,
        store_longitude: -73.9837,
        total_orders: 205.53,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'South American, Hotel, Paloma, Mexican, Trendy, Stylish, Chic, Latin, Southern, Bbq',
        annual_dol: 840.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6566,
        store_longitude: -73.6454,
        total_orders: 5.32,
        neighborhood: '',
        keywords:
            'Burger, Mexican, Salad, Italian, Savory, Spanish, American, Latin, Homemade, Casual',
        annual_dol: 21.73,
        description:
            '"We are a family owned restaurant specializing in cuisine that is Spanish and a Latin-American fusion. Stop by and try our specialty Rotisserie Chicken, infamous Duck (Confit de Pato), an authentic Paella, or our savory Empanadas! ⚠️HAPPY HOUR⚠️ 2-for-1 drink special per customer every day from 4pm-7pm! With your choice if any Beer, Sangria and house drinks are included. Our 2-for-1 policy is each customer can buy one get the next round free. *Kitchen closes at 9 PM*"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8254,
        store_longitude: -73.8146,
        total_orders: 20.39,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: '',
        annual_dol: 83.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6753,
        store_longitude: -73.6693,
        total_orders: 595.5,
        neighborhood: '',
        keywords:
            'Steak, Burger, Garlic Bread, Swiss, Irish, Tangy, Quaint, American, Authentic, Fancy',
        annual_dol: 608.14,
        description:
            'Perennial pub with wooden booths & a calm vibe offering Irish & American fare plus live Irish music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6578,
        store_longitude: -73.6476,
        total_orders: 2397.49,
        neighborhood: '',
        keywords:
            'Tequila Sunrise, Tequila, Mexican, Tacos, Beer, Vegan, Asian, American, Delicious, Fusion',
        annual_dol: 2798.17,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6292,
        store_longitude: -74.0797,
        total_orders: 1745.66,
        neighborhood: 'Stapleton Heights',
        keywords: 'Burger, Tasty, Beer, Salad, Delicious, Fun, Vintage, Friendly, Irish, Retro',
        annual_dol: 1806.46,
        description:
            'Retro gastropub offering comfort food with a twist & ample craft beers on tap, plus Skee-Ball & TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8102,
        store_longitude: -73.6972,
        total_orders: 1144.29,
        neighborhood: '',
        keywords: 'Golfclub',
        annual_dol: 1245.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7543,
        store_longitude: -73.989,
        total_orders: 292.45,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Trendy, Upscale, Romantic, Spacious, Innovative, Premium, Classy, Awesome, American',
        annual_dol: 1195.43,
        description:
            'Spacious, trendy hub with an industrial-chic feel, pairing comfort food with creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7542,
        store_longitude: -73.9341,
        total_orders: 421.13,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Burger, Beer, Vodka, Delicious, Creamy, Friendly, Dutch, Awesome, Casual, Great',
        annual_dol: 557.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -73.9367,
        total_orders: 1468.84,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Salad, Mexican, Delicious, Spicy, Beer, Bbq, Homemade, Fresh, Great, Casual',
        annual_dol: 1648.73,
        description:
            'Counter-service BBQ spot serving Kansas City-style meats along with sides, salads, beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7346,
        store_longitude: -73.9886,
        total_orders: 5785.12,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Burger, Pizza, Mexican, Gluten Free, American, Mediterranean, Ale, Urban, Hummus, Casual',
        annual_dol: 10946.3,
        description:
            'Sports bar & lounge adding slick decor & craft cocktails to the usual mix of beer, pub grub & TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6403,
        store_longitude: -73.9669,
        total_orders: 5373.58,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Steak, Hamburger, Salad, Beer, Coffee, Wine, Delicious, Stylish, Fried Chicken',
        annual_dol: 5671.17,
        description:
            'Wine bar serves select vintages & small plates in a stylish space with brick walls & reclaimed wood.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.551,
        store_longitude: -74.1504,
        total_orders: 42.32,
        neighborhood: 'Great Kills',
        keywords:
            'Burger, Mexican, Sushi, Pizza, Salad, Shrimp Cocktail, Beer, Wine, Delicious, Casual',
        annual_dol: 173,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.7699,
        total_orders: 1208.13,
        neighborhood: 'Bayside',
        keywords:
            'Burger, Shrimp Cocktail, Japanese, Dessert, Modern, American, Casual, Relaxed, Fancy, Fantastic',
        annual_dol: 1233.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.7607,
        total_orders: 792.7,
        neighborhood: 'Bayside',
        keywords:
            'Lobster, Tasty, Spanish, Dessert, Wine, Italian, Mediterranean, Hotel, Elegant, Greek',
        annual_dol: 851.24,
        description:
            'Neighborhood spot serving Spanish cuisine & sangria in a posh white-tablecloth setting since 1967.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7517,
        store_longitude: -73.9723,
        total_orders: 349.95,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Trendy, Coffee, Relaxed, Great',
        annual_dol: 365.12,
        description:
            "Trendy rooms have a relaxed vibe and LED mood lighting, and feature beds with eucalyptus sheets, plus fitness facilities such as yoga mats, resistance bands and exercise balls. All are equipped with free Wi-Fi and 40-inch flat-screen TVs, as well as minifridges and coffeemakers.There's a modern, health-focused restaurant and bar; breakfast is offered for a fee. There's also a fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8666,
        store_longitude: -73.9205,
        total_orders: 85.95,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Steak, Spanish, Tasty, Delicious, Dominican, Friendly, Traditional, Perfect, Sangria, Casual',
        annual_dol: 110.74,
        description:
            '"Authentic Spanish Tapas Restaurant offering a variety of tapas along with other traditional Spanish dishes such as our Paellas, a full bar, desserts, happy hour on weekdays and daily specials."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9848,
        total_orders: 3111.52,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Bourbon, Legendary, Gin, Delicious, Rum, Upscale, Irish, Classic, Iconic',
        annual_dol: 3188.54,
        description:
            'Narrow, old-school Times Square bar with boxing memorabilia covering the walls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7582,
        store_longitude: -73.9807,
        total_orders: 4869.18,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Sushi, Salad, Wine, Beer, Spicy, Japanese, Creamy, Ramen, Sashimi, Homemade',
        annual_dol: 5042.64,
        description:
            '"- WHO WE ARE -The taste and ambiance we provide is elicit, forgotten elegance---noodle craftsman\'s hospitality.TsuruTonTanTsuru---The sound of slurping noodlesTon---The sound of kneading and shaping udonTan---The sound of cutting udonThese three sounds embody the spirit of our finest, handmade udon.Our wish is to be a place for everyone to gather, where our artisanal big bowls of udon bring excitement, surprise and smiles to all of our guests. Along with our great hospitality and service, we strive to create a pleasurable experience for our guests by providing various selections of Japanese food for all."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -73.9911,
        total_orders: 395.56,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Wine, Beer, Delicious, Japanese, Upscale, Warm, Elegant, Ramen, Contemporary',
        annual_dol: 524.13,
        description:
            'Japanese cooking served in an airy, contemporary restaurant with colorful lanterns & outdoor seats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7217,
        store_longitude: -73.9944,
        total_orders: 1889.87,
        neighborhood: 'Nolita',
        keywords: 'Wine, Italian, Vodka, Tasty, Pizza, Rich, Delicious, Stylish, Romantic, Warm',
        annual_dol: 2015.5,
        description:
            'Wood-fired Italian dishes & wines are served in a rustic yet sophisticated space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.747,
        store_longitude: -73.9874,
        total_orders: 780.51,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Hotel, Wine, Rooftop, Coffee, Spicy, Premium, Fun, Fantastic, Great, Chic',
        annual_dol: 810.88,
        description:
            "The warm, modern rooms have flat-screen TVs and Wi-Fi. Some have minifridges, premium cable channels and coffeemakers. Suites add separate living spaces with sofa-beds.Amenities include a lobby bar, a restaurant and a rooftop lounge. There's also a 24-hour fitness center and a business center. Parking and breakfast are available.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9923,
        total_orders: 179.76,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Salad, Hotel, Delicious, Italian, Beer, Mediterranean, Comfortable, Refined, Vibrant',
        annual_dol: 284.42,
        description:
            'Internationally inspired dishes & cocktails offered in upbeat surrounds with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7268,
        store_longitude: -73.9887,
        total_orders: 1437.6,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Salad, Pizza, Italian, Wine, Casual, Authentic, Southern, Traditional, Classic',
        annual_dol: 1468.11,
        description:
            'Homestyle Italian fare is served at this quaint, classic restaurant with outdoor seating. Cash only.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7278,
        store_longitude: -73.988,
        total_orders: 113.3,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Lasagna, Salad, Italian, Wine, Gluten Free, Beer, French, Classy, Friendly, Perfect',
        annual_dol: 463.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5761,
        store_longitude: -74.0829,
        total_orders: 1823.87,
        neighborhood: 'Midland Beach',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Tasty, Juicy, Savory, Bbq, Delicious, Southern',
        annual_dol: 2496.13,
        description:
            '"The most authentic Central Texas-style barbecue in the NYC area! Award-winning brisket and ribs, delicious and savory pulled pork, burgers to die for - and so much more!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8027,
        store_longitude: -73.9643,
        total_orders: 3885.11,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Whiskey, Scotch, American, Gin, Martini, Cozy, Intimate, Fusion, Traditional, Retro',
        annual_dol: 4092.66,
        description:
            'Snug watering hole offering a large selection of American whiskeys, plus simple tavern fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8004,
        store_longitude: -73.9683,
        total_orders: 348.68,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Vegetarian, Burger, Steak, Pizza, Chicken Parmigiana, Italian, Wine, French, Filet Mignon, Seasonal',
        annual_dol: 371.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8852,
        store_longitude: -73.9003,
        total_orders: 783.38,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Beer, Irish, Karaoke, Ale, Adventurous, Fun, Friendly, Urban, Great, Local',
        annual_dol: 817.34,
        description:
            'Long-running Irish pub with standard grub, sports on TVs, pool tables, darts & a neighborhood vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7529,
        store_longitude: -73.972,
        total_orders: 6928.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Shrimp Cocktail, Wine, Tasty, Dessert, Coffee, Scottish, Classic, Iconic, Fresh',
        annual_dol: 6982.13,
        description:
            'Select meats, seafood, salads & a deep wine list offered in a clubby atmosphere, open since 1966.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7573,
        store_longitude: -73.9765,
        total_orders: 786.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Tasty, Wine, Italian, Upscale, Delicious, Homemade, Perfect, Robust, Fabulous',
        annual_dol: 1101.9,
        description:
            'Upscale eatery offering Northern Italian cuisine, weekend brunch & a robust wine list.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7498,
        store_longitude: -73.9754,
        total_orders: 1751.66,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Sushi, Tequila, Salad, Mexican, Mimosa, Dessert, Wine, Beer, Spicy, Thai',
        annual_dol: 6307.79,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.7703,
        total_orders: 93.12,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Beer, Dessert, Ale, Cuban, Warm, Yummy, Eclectic, Contemporary, American',
        annual_dol: 317.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.9795,
        total_orders: 165.81,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Pizza, French, Mediterranean, Thai, Rum, Seasonal, Modern, Casual, Classic',
        annual_dol: 944.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7573,
        store_longitude: -73.9766,
        total_orders: 136.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Pizza, Italian, Hotel, New Zealand, Vibrant, Southern, Fantastic, Casual, Wonderful',
        annual_dol: 141.98,
        description:
            '"Casa Limone near Rockefeller Center, Radio City Music Hall, Sack Fifth Avenue and Times Square"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8236,
        store_longitude: -73.9522,
        total_orders: 3811.75,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Mexican, Whiskey, Wine, Beer, Ramen, Italian, Japanese, Tasty, Asian, Classy',
        annual_dol: 3892.63,
        description:
            'Japanese option for several varieties of ramen, plus snacks, a raw bar & sophisticated cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7621,
        store_longitude: -73.9716,
        total_orders: 1514.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Wine, Beer, Martini, Gin, Premium, Spacious, Western, Classic, Iconic',
        annual_dol: 5706.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.969,
        total_orders: 54.46,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Butter Chicken, Chicken Tikka Masala, Chili Chicken, Upscale, Trendy, Delicious, Polished, Indian',
        annual_dol: 222.62,
        description:
            '"Upscale Restaurant famous for delicious Indian cuisine. Darbar Grill is located near luxury hotels & NYC attractions. Romantic & Friendly; groups gathering over dinner is often what you see late at dinner time."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7797,
        store_longitude: -73.9471,
        total_orders: 27.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Italian, Salad, Wine, American, Modern, Authentic, Excellent',
        annual_dol: 118.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.683,
        store_longitude: -73.9436,
        total_orders: 975.49,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tequila, Beer, Tasty, Rum, Trendy, Painkiller, Caribbean, Jamaican, Fun, Relaxed',
        annual_dol: 1017.79,
        description:
            'Relaxed watering hole offering tropical drinks, plus live reggae music, dancing & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9806,
        total_orders: 88.08,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Thai, Salad, Dessert, Green Curry, Chinese, Pad Thai, Casual, Authentic, Tom Yum, Local',
        annual_dol: 360.04,
        description: '"Taste all four regions of Thai cuisine, in one place."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7492,
        store_longitude: -73.9937,
        total_orders: 9214.48,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Whiskey, Beer, American, Burger, Bourbon, Wine, Gluten Free, Tasty, Irish, Premium',
        annual_dol: 9624.45,
        description:
            'Elegant version of a Southern bourbon & burger bar with clever small plates & an upstairs lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.9931,
        total_orders: 615.83,
        neighborhood: 'NoHo',
        keywords: 'Garlic Bread, Pizza, Salad, Wine, Italian, Tasty, Delicious, Spicy, Vodka, Warm',
        annual_dol: 628.9,
        description:
            'Updated Italian dishes plus cocktails & housemade limoncello in spacious, low-key digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6613,
        store_longitude: -73.9534,
        total_orders: 900.42,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Salad, Vegetarian, Ramen, Beer, Flavorful, Fried Chicken, Japanese, Spicy, Caesar Salad, Caribbean',
        annual_dol: 1078.6,
        description:
            'Casual stop for classic & smoked ramen bowls plus other comfort foods like fried chicken & burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9669,
        total_orders: 117.55,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Salad, Milkshake, Organic, American, French, Classic, Fresh, Local, Casual',
        annual_dol: 125.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7362,
        store_longitude: -73.9554,
        total_orders: 1665.76,
        neighborhood: 'Greenpoint',
        keywords:
            'Salad, Wine, Italian, Steak, Pizza, Delicious, Warm, Romantic, Classy, Irresistible',
        annual_dol: 1737.99,
        description:
            'Offering casual Italian fare & craft cocktails at communal tables, plus frequent live musical acts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6834,
        store_longitude: -73.9989,
        total_orders: 646.77,
        neighborhood: 'Carroll Gardens',
        keywords: 'Steak, Burger, Salad, Wine, Dessert, Beer, Delicious, German, French, Polished',
        annual_dol: 674.81,
        description:
            "Alsatian eatery serving dishes like spaetzle & tarte flambée along with the region's wine & beer.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7474,
        store_longitude: -73.956,
        total_orders: 2190.06,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Hotdog, Beer, Wine, American, Vintage, Spacious, Fun, Retro, Awesome, Great',
        annual_dol: 2285.01,
        description:
            'Old-school bowling alley with an attached barroom that offers craft beer & hot dogs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7303,
        store_longitude: -73.8638,
        total_orders: 1911.34,
        neighborhood: 'Rego Park/Flushing',
        keywords: 'Burger, Steak, Cheesesteak, Salad, Tasty, Bbq, Nachos, Beer, Margarita, Juicy',
        annual_dol: 1959.23,
        description:
            'Tropical-themed lounge serving festive cocktails & pub food in a brick-walled space with hookahs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.585,
        store_longitude: -73.9268,
        total_orders: 229.59,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Lobster, Tasty, Martini, Juicy, Bbq, Caribbean, Perfect, Fresh, Great, Casual',
        annual_dol: 661.28,
        description:
            'Waterside seafood joint offering up Maryland-style all-you-can-eat crab meals, clams & more.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.7716,
        total_orders: 143.3,
        neighborhood: 'Bayside',
        keywords: 'Steak, Bourbon, Premium, Nachos, Fun, Friendly, Great, Attentive',
        annual_dol: 152.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7549,
        store_longitude: -73.9717,
        total_orders: 296.79,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegan, Vegetarian, Pizza, Coffee, Mexican, Wine, Gourmet, American, Trendy, Hotel',
        annual_dol: 1578.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7524,
        store_longitude: -73.9779,
        total_orders: 10.17,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Dessert, Scandinavian, Wine, Beer, Nordic, Danish, Upscale, American, Bright',
        annual_dol: 41.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.67,
        store_longitude: -73.8423,
        total_orders: 977.61,
        neighborhood: 'Ozone Park',
        keywords:
            'Mexican, Tequila, Beer, Spanish, American, Latin, Yummy, Homemade, Polished, Friendly',
        annual_dol: 1095.91,
        description: '"Modern Mexican kitchen"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9731,
        total_orders: 1706.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lasagna, Cheesecake, Pizza, Flavorful, Italian, Delicious, Polished, Bellini, Cozy',
        annual_dol: 1783.81,
        description:
            'Classic Italian fare is served in a casual, homey setting that has a bustling lunch scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7027,
        store_longitude: -73.9295,
        total_orders: 415.18,
        neighborhood: 'Bushwick',
        keywords: 'Vegan, Wine, Whiskey, Tasty, Cozy, Classy, Casual, Great, Local, Chill',
        annual_dol: 677,
        description:
            'Laid-back neighborhood hangout featuring bar bites & local draft beer, plus a lineup of live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8854,
        store_longitude: -73.5994,
        total_orders: 986.55,
        neighborhood: '',
        keywords: 'Tequila, Rich, Golfclub, Pizza',
        annual_dol: 1075.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7634,
        store_longitude: -73.9705,
        total_orders: 268.31,
        neighborhood: 'Lenox Hill',
        keywords: 'Scotch, Wine, Beer, American, Premium, Innovative, Excellent',
        annual_dol: 941.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9897,
        total_orders: 1297.09,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Margarita, Salad, Beer, Italian, Savory, South American, French Toast, Upscale, Spanish',
        annual_dol: 1440.47,
        description:
            'Eclectic tapas, charcuterie & mains, plus wine & cocktails served in a cozy, rustic-chic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9808,
        total_orders: 10757.27,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Burger, Gin, Organic, Delicious, Spanish, American, French, Seasonal, Asian, Contemporary',
        annual_dol: 11480.54,
        description:
            'Elevated American eats pair with cocktails in a retro-inspired space with brass & tile accents.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7416,
        store_longitude: -73.9851,
        total_orders: 4253.65,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Salad, Pizza, Wine, Tasty, Italian, Beer, Californian, Spicy, Cozy, Rustic',
        annual_dol: 4441.1,
        description:
            'Spacious, casually chic brasserie with hearty dishes featuring Californian & Italian influences.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6923,
        store_longitude: -73.9695,
        total_orders: 338.52,
        neighborhood: 'Clinton Hill',
        keywords: 'Global, American, Local, Casual, Great, Unique',
        annual_dol: 921.66,
        description:
            '"Brooklyn Music Kitchen is Brooklyn’s newest music infusion giving local and global artists the opportunity to perform, record and stream their live performances from a fully loaded sound and video stage."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7026,
        store_longitude: -73.9865,
        total_orders: 446.16,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords: 'Burger, Beer, Coffee, Wine, American, Modern, Rooftop, Aesthetic, Casual, Great',
        annual_dol: 1846.47,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9867,
        total_orders: 362.13,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Spanish, Intimate, Amazing, Iconic, Clean, New Zealand',
        annual_dol: 748.56,
        description:
            'The Lena Horne Theatre is a Broadway theater at 256 West 47th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1926, it was designed by Herbert J. Krapp in a Spanish Revival style and was constructed for Irwin Chanin.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6856,
        store_longitude: -73.991,
        total_orders: 95.58,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Vodka, Salad, Beer, Coffee, Wine, German, Dessert, Jamaican, Spicy, French',
        annual_dol: 312.05,
        description:
            'Brick- & wood-accented restaurant & bar for German specialties & beers, plus a large backyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.657,
        store_longitude: -73.96,
        total_orders: 965.46,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Zombie, Hamburger, Beer, American, Wine, Asian, Local, Casual, Spacious, Traditional',
        annual_dol: 985.94,
        description:
            'Neighborhood watering hole offering nibbles, brews & event nights in a laid-back atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7262,
        store_longitude: -74.0021,
        total_orders: 2830.91,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Steak, Jambalaya, Wine, Bread Pudding, Dessert, Coffee, Italian, Martini, Hawaiian',
        annual_dol: 2930.33,
        description:
            'Classic bistro dating to the 1970s with a bustling Parisian atmosphere & a serious French menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7248,
        store_longitude: -73.9926,
        total_orders: 2268.44,
        neighborhood: 'NoHo',
        keywords: 'Burger, Tacos, Steak, Beer, Salad, Nachos, Ale, Irish, Bourbon, Delicious',
        annual_dol: 2681.28,
        description:
            'Big, brick-walled bar with a lengthy beer lineup, standard pub grub & abundant soccer matches on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7715,
        store_longitude: -73.9819,
        total_orders: 3359.79,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Lobster, Burger, Salad, Tomato Soup, Dessert, Wine, Vodka, Italian, Spicy, Gin',
        annual_dol: 3583.13,
        description:
            'Lively place for classic American fare & specialty cocktails in spacious digs with a hopping bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7763,
        store_longitude: -73.9794,
        total_orders: 992.68,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Lobster Roll, Steak, Shrimp Cocktail, Wine, Coffee, Martini, Italian, French Onion Soup, Delicious',
        annual_dol: 1050.01,
        description:
            'French bistro fare like duck a l’orange & steak frites in a warm setup with an old-fashioned feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7785,
        store_longitude: -73.9782,
        total_orders: 276.65,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Sushi, Japanese, Spicy, Elegant, Local, Modern, Relaxed, Fresh, Casual, Traditional',
        annual_dol: 295.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7549,
        store_longitude: -73.988,
        total_orders: 7060.1,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Caribbean, Rooftop, Southern, Great, Excellent, Nachos',
        annual_dol: 18192.86,
        description:
            'Featuring rainfall showers and soundproof windows, the airy, island-inspired rooms provide flat-screen TVs and Wi-Fi. All offer minifridges and Keurig coffeemakers. Some add city views and/or sofabeds.There’s a heated rooftop pool and a fitness center. Other amenities include 2 restaurants, 2 bars and a convenience store.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8381,
        store_longitude: -73.7182,
        total_orders: 463.1,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Italian, Mediterranean, Turkish, Fabulous, Sophisticated, Excellent, Chic, Incredible',
        annual_dol: 1892.99,
        description:
            '"A stunning Italian/Mediterranean waterfront restaurant & bar , featuring an Oyster Bar, Wood fire Oven, and incredible craft cocktails. Beautiful panoramic views of the sunset."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7501,
        store_longitude: -73.9849,
        total_orders: 1794.57,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Cheesesteak, Beer, Spicy, Philly Cheesesteak, Mimosa, German, Nachos, Irish, American',
        annual_dol: 1899.64,
        description:
            'Hip & airy wood-clad beer hall offering numerous crafts on tap & creative gastropub food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.756,
        store_longitude: -73.9942,
        total_orders: 43.35,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Meatball, Shrimp Cocktail, Margarita, Italian, Wine, Upscale, American, Spanish',
        annual_dol: 101.23,
        description:
            'Seasonal American dishes & locally sourced products showcased in a rustic-chic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6571,
        store_longitude: -73.6431,
        total_orders: 1834.39,
        neighborhood: '',
        keywords:
            'Beer, Mexican, Wine, Trendy, Upscale, Delicious, Italian, Spanish, Latin, American',
        annual_dol: 1873.32,
        description: '"A Latin experience on the island!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6322,
        store_longitude: -73.9337,
        total_orders: 618.12,
        neighborhood: 'Flatlands/East Flatbush',
        keywords: 'Wine, Spicy, Coffee, Funky, Margarita, Delicious, Juicy, Beer, Cozy, Asian',
        annual_dol: 943.41,
        description:
            'Cozy restaurant offering Caribbean & Asian fare, plus outdoor seats & tropical cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.9837,
        total_orders: 4690.75,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Lobster, Lobster Tail, Vegan, Salad, American, South African, Warm, Cozy, African',
        annual_dol: 5222.84,
        description:
            'Outdoor lounge at Bryant Park offering laid-back seating & a basic menu of American fare & drinks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.656,
        store_longitude: -73.6451,
        total_orders: 61.8,
        neighborhood: '',
        keywords: 'Sushi, Salad, Japanese, Spicy, Asian, Friendly, Casual, Tempura, Great',
        annual_dol: 232.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7578,
        store_longitude: -73.9994,
        total_orders: 1048.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Salad, Upscale, French, Premium, Fun, Friendly, Exotic, Great, Fine',
        annual_dol: 1093.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7677,
        store_longitude: -73.9824,
        total_orders: 412.6,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Burger, Organic, Italian, Upscale, Wine, American, Modern, Trendy, French',
        annual_dol: 430.48,
        description:
            'New American fare & city views in an arty space on the top-floor of the Museum of Arts & Design.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7656,
        store_longitude: -73.9839,
        total_orders: 1740.65,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Pizza, Mexican, Salad, Italian, Beer, Whiskey, Wine, Irish, Delicious',
        annual_dol: 1817.62,
        description:
            'Lively Irish bar with brick walls & sports on TV serving standard pub grub, draft beers & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.761,
        store_longitude: -73.9854,
        total_orders: 414.83,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, Thai, Quaint, Cozy, Friendly, Refreshing, Casual',
        annual_dol: 1695.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9771,
        total_orders: 74.91,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Chinese, Dessert, Spicy, Peking Duck, Ramen, Sweet, Mediterranean, Mojito, Perfect, Authentic',
        annual_dol: 79.83,
        description:
            'Shanghainese dishes feature at this subdued Chinese spot convenient to Carnegie Hall & City Center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6832,
        store_longitude: -73.9736,
        total_orders: 572.96,
        neighborhood: 'Fort Greene',
        keywords:
            'Lobster, Lobster Tail, Burger, Wine, Caribbean, Bourbon, Jamaican, Yummy, Friendly, Relaxed',
        annual_dol: 1335.71,
        description:
            'Casual recipes with Jamaican flair whipped up in a relaxed spot with a colorful bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7343,
        store_longitude: -73.9978,
        total_orders: 823.91,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Tasty, Wine, Delicious, Beer, Warm, Spanish, Phenomenal, Charming, Picturesque, Fabulous',
        annual_dol: 1010.92,
        description:
            'A vast array of small plates & wines by the glass plus cocktails are served in a low-lit space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7437,
        store_longitude: -73.9944,
        total_orders: 662.13,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tequila, Mexican, Upscale, Fun, Hotel, Lively, Premium, Iconic, Great, Friendly',
        annual_dol: 1298.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7262,
        store_longitude: -73.9833,
        total_orders: 7051.52,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Hotdog, Salad, Upscale, Cool, Casual, Awesome, Great, Excellent, Chill',
        annual_dol: 7201.14,
        description:
            'This longtime watering hole on a busy Alphabet City corner features DJ-spun tunes & a photo booth.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7257,
        store_longitude: -73.984,
        total_orders: 4736.48,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Scotch, Gin, Martini, Italian, Delicious, Cozy, Legendary, Friendly, Intimate, Classic',
        annual_dol: 4881.34,
        description:
            'Intimate bar offering an extensive list of cocktails that showcase classic Italian & craft bitters.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.723,
        store_longitude: -73.9853,
        total_orders: 1284.31,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Italian, Pizza, Wine, Coffee, Cozy, Rustic, Delicious, Homey, Casual, Iconic',
        annual_dol: 1311.56,
        description:
            'Italian comfort food matches the rustic setting at this cash-only trattoria with an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7279,
        store_longitude: -73.9793,
        total_orders: 0,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Coffee, Italian, Comfortable, Relaxed, Karaoke, Sweet, Vintage, Perfect, Casual',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -73.99,
        total_orders: 462.93,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Whiskey, Beer, Spacious, Hotel, Bright, American, Innovative, Natural, Perfect, Rooftop',
        annual_dol: 1744.19,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7648,
        store_longitude: -73.9915,
        total_orders: 2905.9,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Spicy, Wine, Bbq, Crisp, Friendly, Relaxed, Western, Outstanding',
        annual_dol: 2905.9,
        description:
            'Laid-back tavern with a Western motif serving Texas-inspired pub grub such as Frito pie.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7641,
        store_longitude: -73.9831,
        total_orders: 3186.36,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Beer, Gluten Free, Bourbon, Irish, Bbq, American, Nachos, Homemade, Classic, Southern',
        annual_dol: 3447.25,
        description:
            'Sports bar with more than 30 draft brews, lots of flat-screen TVs & a second-floor outdoor deck.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7649,
        store_longitude: -73.984,
        total_orders: 3477.79,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Karaoke, Japanese, Korean, Upscale, Fun, Great, Casual, Chill',
        annual_dol: 3739.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9909,
        total_orders: 33.77,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Dessert, Wine, Beer, Japanese, Avocado Toast, Delicious, Ramen, Contemporary, Exceptional',
        annual_dol: 138.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6397,
        store_longitude: -73.5818,
        total_orders: 204.74,
        neighborhood: '',
        keywords: 'Mexican, Italian, Chilean, Modern, Casual, Chill, Great',
        annual_dol: 475.25,
        description:
            '"Nautical Mile eatery served with a variety of Italian and Mexican specialties and stunning views of the water. Join us for Lunch Mon-Fri 11:00am-3:00pm or our Happy Hour Mon-Fri 3:00pm-7:00pm. We look forward to your visit."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.866,
        store_longitude: -73.9198,
        total_orders: 5.06,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Salad, Pizza, Roast Beef, Latin, Caribbean, Healthy, American, Fantastic, Great, Casual',
        annual_dol: 20.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7455,
        store_longitude: -73.9992,
        total_orders: 252.31,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Vegan, Lobster, Salad, Pizza, Gluten Free, Tasty, Italian, Wine, Homemade',
        annual_dol: 287.36,
        description:
            'Sophisticated, airy outpost featuring Neapolitan-style pies, pasta & other classic Italian dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7413,
        store_longitude: -74.0049,
        total_orders: 4258.83,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Cheesesteak, Salad, Japanese, Dessert, Wine, Legendary, American, Classic',
        annual_dol: 4349.19,
        description:
            'Old-world relic (1868-era) serving huge, pricey steaks in a classic, dark-wood setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7349,
        store_longitude: -73.9976,
        total_orders: 1725.75,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Mexican, Vegetarian, Tequila, Wine, Margarita, Coffee, Nachos, Tasty, French',
        annual_dol: 2003.93,
        description:
            'Neighborhood joint for Mexican food by a native chef-owner, plus mezcals & other agave spirits.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.9831,
        total_orders: 2375.94,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Tequila, Upscale, Beer, Margarita, Pizza, Nachos, Vibrant, Perfect, Lively, Awesome',
        annual_dol: 3158.6,
        description: '"Upscale sports bar in East Village, NY."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7175,
        store_longitude: -73.9562,
        total_orders: 308.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, French, Trendy, Beer, Romantic, Moroccan, Classy, Delicious, Intimate, Casual',
        annual_dol: 324.43,
        description: '"Nice Atmosphere restaurant, serving French food, nice Crafted Cocktails"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6795,
        store_longitude: -73.9883,
        total_orders: 5.36,
        neighborhood: 'Gowanus',
        keywords:
            'Peruvian, Salad, Steak, Wine, Dessert, South American, Cozy, Sweet, Friendly, Casual',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7424,
        store_longitude: -73.9804,
        total_orders: 1657.21,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Rooftop, American, Upscale, Wholesome, Awesome, Fantastic, Fun, Great, Aesthetic, Traditional',
        annual_dol: 1692.38,
        description:
            'Chill tri-level eatery/bar with rooftop dining & Empire State Building views, serving mains & wings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9597,
        total_orders: 280.96,
        neighborhood: 'Williamsburg',
        keywords:
            'Spacious, Coffee, Beer, Awesome, Fantastic, Amazing, Bright, Great, Rooftop, Intimate',
        annual_dol: 1148.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.727,
        store_longitude: -73.9948,
        total_orders: 9152.94,
        neighborhood: 'NoHo',
        keywords:
            'Sushi, Italian, Hotel, Eclectic, American, High-End, Fun, Modern, Comfortable, Iconic',
        annual_dol: 17015.06,
        description:
            '"Zero Bond is a twenty thousand square foot private membership club in the heart of Noho. The club will have something for every member at every hour: days filled with comfortable workspaces and conference rooms focused on productivity for an individual or a group, and nights alive with multiple restaurants and bars, private event space, and curated exclusive experiences."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7269,
        store_longitude: -73.9944,
        total_orders: 18183.38,
        neighborhood: 'NoHo',
        keywords: 'Sushi, Salad, Japanese, Italian, Korean, Spicy, Creamy, Trendy, Warm, Sweet',
        annual_dol: 19931.4,
        description:
            'High-end sushi & Japanese dishes in a chic, trendy atmosphere with a well-heeled crowd.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7305,
        store_longitude: -74.0011,
        total_orders: 477.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lobster, Tequila, Italian, Wine, Beer, Meatball, Upscale, Ale, Trendy, Elegant',
        annual_dol: 654.93,
        description:
            'Warm, brick-walled venue for comforting Italian plates including chilled seafood & pasta.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7286,
        store_longitude: -74.0019,
        total_orders: 785.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Italian, Wine, Shrimp Cocktail, Impeccable, Vintage, Quaint, Homemade, Romantic, Traditional',
        annual_dol: 839.95,
        description:
            'Open since 1976, this family-run fixture with vintage charm serves homemade pasta & classic entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6942,
        store_longitude: -73.9917,
        total_orders: 164.88,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Mexican, Tacos, Burritos, Pizza, Salad, Vietnamese, Warm, Local, Casual',
        annual_dol: 260.87,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7206,
        store_longitude: -73.9845,
        total_orders: 25.37,
        neighborhood: 'Lower East Side',
        keywords:
            'Ale, Delicious, Comfortable, Friendly, Premium, Sweet, Asian, Traditional, Casual, Great',
        annual_dol: 103.7,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8143,
        store_longitude: -73.9484,
        total_orders: 603.9,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Lobster, Lobster Tail, Dessert, Delicious, Fresh, Ample, Southern',
        annual_dol: 2275.29,
        description:
            'Laid-back counter serving up ample plates of fresh seafood, fried fish & homestyle Southern fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9843,
        total_orders: 1238.74,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Vodka, Salad, Russian, Chinese, Martini, Tangy, Hotel, Zesty, Seasonal, Dolma',
        annual_dol: 1290.24,
        description:
            'Russian staples & infused vodkas are served at this longtime eatery that hosts live piano music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9843,
        total_orders: 286.06,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Italian, Wine, Coffee, Romantic, Caesar Salad, Perfect, Wonderful, Great, Fresh',
        annual_dol: 338,
        description:
            'Old-school dining room where theatergoers gather before a show for a Northern Italian meal.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.9891,
        total_orders: 9063.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Mexican, Salad, Beer, Nachos, Margarita, Wine, Vietnamese, Upscale, American',
        annual_dol: 10579.58,
        description:
            'Convivial watering hole featuring nachos, pizza & American bar fare, plus sports on TV.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7663,
        store_longitude: -73.9814,
        total_orders: 3329.51,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Wine, Italian, Coffee, Beer, Bruschetta, Friendly, Contemporary, Modern, Casual',
        annual_dol: 4181.5,
        description:
            'Contemporary restaurant serving Italian-inspired fare with influences from the Pacific Northwest.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7664,
        store_longitude: -73.9866,
        total_orders: 635.33,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Meatball, Organic, Delicious, Polished, Modern, Traditional, Authentic, Casual',
        annual_dol: 717.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9774,
        total_orders: 1684.74,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Salad, Wine, Whiskey, Italian, Modernist, Hotel, American, French, Irish',
        annual_dol: 2503.62,
        description:
            'French/New American fare in a modernist space with garden views at the Museum of Modern Art.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.76,
        store_longitude: -73.9888,
        total_orders: 0,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Italian, Wine, Rustic, Cozy, Elegant, Warm, Charming, Perfect, Intimate',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.8848,
        total_orders: 31.81,
        neighborhood: 'Jackson Heights',
        keywords:
            'Steak, Pizza, Hamburger, Lobster, Colombian, Delicious, Irish, Stylish, Modern, Indian',
        annual_dol: 130.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7334,
        store_longitude: -73.9986,
        total_orders: 225.28,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Sushi, Vegetarian, Upscale, Trendy, Hotel, French, Mediterranean, Seasonal, Intimate',
        annual_dol: 250.83,
        description:
            'Buzzy, intimate spot offering a daily changing menu of seasonal Israeli plates & absinthe cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7392,
        store_longitude: -74.0008,
        total_orders: 967.3,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Cajun, Lobster, Cajun-Style, Juicy, Wine, Delicious, Beer, Creole, Relaxed',
        annual_dol: 1530.44,
        description:
            'Casual, hands-on joint for Cajun crawfish, shrimp & lobster with a choice of seasonings & spices.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.715,
        store_longitude: -73.9584,
        total_orders: 210.57,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Tequila, Salad, Whiskey, Margarita, Triple Sec, Zesty, Greek, Fresh, Sweet',
        annual_dol: 737.48,
        description:
            'Down-to-earth restaurant offering deep dish & thin crust pizza, plus garlic bread.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6835,
        store_longitude: -73.9675,
        total_orders: 78.14,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Vegan, Italian, Beer, Organic, French Toast, Trendy',
        annual_dol: 226.11,
        description:
            'Stark yet intimate candelit eatery with classic & other Italian meals, including homemade pastas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7197,
        store_longitude: -73.956,
        total_orders: 2088.56,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Salad, Beer, Tasty, Juicy, Rustic, American, Eclectic, Casual',
        annual_dol: 2088.56,
        description:
            'American pub with a globally inspired menu served in an old-time setting with an eclectic feel.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.722,
        store_longitude: -73.9575,
        total_orders: 2689.68,
        neighborhood: 'Williamsburg',
        keywords: 'Vegan, Vegetarian, Fun, Hotel, Local, Fantastic, Hummus, Unforgettable, Crafted',
        annual_dol: 2746.75,
        description:
            'Genre-defying bowling alley in Williamsburg with high-tech lanes, live tunes & food by Blue Ribbon.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.9257,
        total_orders: 14.28,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Mexican, Coffee, Colombian, American, Latin, Hotel, Casual, Western, Local, Sweet',
        annual_dol: 58.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9789,
        total_orders: 4022.63,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Champagne, Russian, Elegant, Legendary, American, Classic, Contemporary, Rooftop, Complex',
        annual_dol: 4297.43,
        description:
            'The Rainbow Room is a private event space on the 65th floor of 30 Rockefeller Plaza at Rockefeller Center in Midtown Manhattan, New York City. Run by Tishman Speyer, it is among the highest venues in New York City. The Rainbow Room serves classic and contemporary American cuisine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8042,
        store_longitude: -73.9484,
        total_orders: 112.93,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Steak, Tequila, Mexican, Margarita, Delicious, Polished, American, Latin, Perfect, Friendly',
        annual_dol: 192.54,
        description:
            'Unassuming, colorful restaurant serving Mexican food favorites like burritos, plus margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.9985,
        total_orders: 1629.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Salad, Roast Turkey, Wine, Beer, Mimosa, Italian, Ale, Crisp, Sweet',
        annual_dol: 1711.55,
        description:
            'A collegiate crowd bellies up to the antique pine bar to drink from a vast beer list & eat pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.8815,
        total_orders: 2087.31,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Steak, Shrimp Cocktail, Argentinian, Juicy, Relaxed, Brazilian, Traditional, Friendly, Casual, Eclectic',
        annual_dol: 2396.77,
        description:
            'Relaxed eatery offering skirt steak, empanadas & other Argentinean dishes, plus sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7524,
        store_longitude: -73.896,
        total_orders: 283.49,
        neighborhood: 'Jackson Heights',
        keywords:
            'Chicken Parmigiana, Nachos, Upscale, Quesadillas, American, Fun, Refreshing, Exquisite, Awesome, Great',
        annual_dol: 308.51,
        description:
            'Vast bar featuring pool, ping pong, air hockey & foosball tables, plus an ample, diverse menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9885,
        total_orders: 1253.32,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Rustic, Clean, Casual, American, Local, Sweet, Indian, Fine, Great',
        annual_dol: 1333.78,
        description:
            'Laid-back, exposed-brick haunt offering an extensive beer selection & menu of pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8117,
        store_longitude: -73.9503,
        total_orders: 2765.68,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Burger, Steak, Hamburger, Salad, Bbq, Tasty, Upscale, American, Casual, Local',
        annual_dol: 3924.78,
        description:
            'Ribs, wings, burgers & frozen margaritas are the specialties of this neighborhood restaurant/bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6945,
        store_longitude: -73.8095,
        total_orders: 420.43,
        neighborhood: 'Jamaica',
        keywords:
            'Steak, Chicken Parmigiana, Salad, Spanish, French, Homemade, Cozy, Warm, Traditional, Authentic',
        annual_dol: 467.77,
        description:
            'Traditional Portuguese cuisine served in roomy surrounds with mosaics, marble arches & a cozy bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8068,
        store_longitude: -73.9591,
        total_orders: 380.71,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Vegan, Whiskey, Salad, Dessert, Elegant, Premium, Perfect, African, Modern, Great',
        annual_dol: 434.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7631,
        store_longitude: -73.9853,
        total_orders: 90.67,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Beer, Pizza, Burritos, Wine, Ramen, Delicious, Local, Casual',
        annual_dol: 554.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7151,
        store_longitude: -73.9921,
        total_orders: 672.41,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Karaoke, Martini, Spacious, Ale, Friendly, Awesome, Clean, Wonderful, Cool, Great',
        annual_dol: 704.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7171,
        store_longitude: -73.9953,
        total_orders: 1447,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Rooftop, Tequila, Hotel, Beer, Trendy, Spectacular, Cozy, Modern, Great, Friendly',
        annual_dol: 1612.34,
        description:
            'Bi-level lounge atop the Wyndham Garden Hotel, featuring spirits & panoramic views of NYC.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 362.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Burger, Wine, Italian, Beer, Delicious, French, Vietnamese, Chinese, Quaint',
        annual_dol: 1503.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5845,
        store_longitude: -73.6666,
        total_orders: 1588.44,
        neighborhood: '',
        keywords:
            'Steak, Hotel, Tomato Soup, Salad, Shrimp Cocktail, Gourmet, Elegant, French Onion Soup, French, Organic',
        annual_dol: 1692.82,
        description:
            'Modern rooms have Internet access (surcharge) and elegant furniture, plus limestone bathrooms with spa showers, 37-inch flat-screen TVs, 24-hour room service and minifridges; suites add living rooms and wet bars, plus beachfront or city views.Perks include a seasonal, heated rooftop pool and chic lounge area, a private tunnel to the beach, a fitness center with ocean views, a grand event ballroom, and dining and nightlife options, including a gourmet eatery and a bar/lounge, both with ocean views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7115,
        store_longitude: -73.9469,
        total_orders: 475.86,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Vegetarian, Vegan, Tacos, Salad, Mexican, Coffee, Wine, Beer, Organic',
        annual_dol: 568.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9854,
        total_orders: 25.76,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Hawaiian, Bread Pudding, Wine, Tasty, Chinese, Spicy, Cozy, Asian, Fried Rice, Modern',
        annual_dol: 105.31,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7493,
        store_longitude: -73.9791,
        total_orders: 1508.05,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Salad, Italian, Wine, Meatball, Tangy, Elegant, Tranquil, Classy, Vintage',
        annual_dol: 1608.3,
        description:
            'Vintage Northern Italian eatery with tuxedoed waiters, live piano nightly & opera on Saturday night.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7478,
        store_longitude: -73.9848,
        total_orders: 224.25,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Pizza, Vegan, Salad, Gluten Free, Organic, Modern, Prime Rib, French, Innovative, Fabulous',
        annual_dol: 916.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6665,
        store_longitude: -73.7801,
        total_orders: 140.57,
        neighborhood: 'Jamaica/Addisleigh Park',
        keywords: 'Hotel, Friendly, Authentic, Excellent, Local',
        annual_dol: 574.62,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6285,
        store_longitude: -74.0166,
        total_orders: 314.37,
        neighborhood: 'Dyker Heights',
        keywords: 'Italian, Bruschetta, Casual, Local',
        annual_dol: 1184.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7652,
        store_longitude: -73.983,
        total_orders: 3931.09,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Pizza, Salad, Japanese, Dessert, Beer, Ramen, Sashimi, Irish, Asian',
        annual_dol: 4334.84,
        description:
            'Casual venue serving classic Japanese cooking, including udon, pork cutlets & curry.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7644,
        store_longitude: -73.9868,
        total_orders: 6706.87,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Cozy, Authentic, Casual, Amazing, Great, Recommended',
        annual_dol: 6849.18,
        description:
            'Spacious, industrial-chic gay bar with a bustling after-work scene fueled by a nightly happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.987,
        total_orders: 1313.2,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Wine, Red Eye, Fantastic, Incredible, Casual',
        annual_dol: 1399.49,
        description:
            'Happening gay nightclub featuring DJs, drag shows & stand-up comedy in bi-level digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7168,
        store_longitude: -74.008,
        total_orders: 3527.89,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Salad, Cozy, Rustic, Warm, French, Romantic, Healthy, Homemade, Casual, Modern',
        annual_dol: 3694.96,
        description:
            'Historical 1810 bi-level townhouse showcases New American fare in a dimly lit, rustic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7219,
        store_longitude: -73.9922,
        total_orders: 0,
        neighborhood: 'Bowery',
        keywords: 'American, High-End, Vintage, Bright, Casual, Omakase',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7557,
        store_longitude: -73.8861,
        total_orders: 99.88,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Mexican, South American, Wine, Colombian, Latin, Tasty, American, Fusion, Spanish, Lively',
        annual_dol: 143.99,
        description:
            'Lively wine bar pairing vintages with Colombian small plates, plus Spanish meats & cheeses.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7632,
        store_longitude: -73.9839,
        total_orders: 466.89,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Vodka, Russian, Latin, Perfect, Indian, Authentic',
        annual_dol: 1768.03,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8671,
        store_longitude: -73.9226,
        total_orders: 313.29,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Burger, Steak, Beer, Argentinian, Stylish, Bbq, Dessert, Tex-Mex, Thai, Clean',
        annual_dol: 319.93,
        description:
            'Sports bar & restaurant serving American fare in a sizable, modern-rustic space with a beer garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8643,
        store_longitude: -73.9267,
        total_orders: 94.92,
        neighborhood: 'Fort George',
        keywords: 'Tequila, Tacos, Steak, Mexican, Lobster, Beer, Wine, Delicious, Trendy, Spanish',
        annual_dol: 101.01,
        description:
            'Energetic sports bar dishing out Mexican & American pub standards alongside cocktails & beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 592.46,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Salad, Pizza, Tequila, Gluten Free, Vodka, Beer, Margarita, Italian',
        annual_dol: 618.14,
        description:
            'Breakfast & dinner are also served at this upscale American luncheonette in Chelsea Market.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7128,
        store_longitude: -73.9575,
        total_orders: 347.93,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Sushi, Vegan, Vegetarian, Mexican, Gluten Free, Beer, Vodka, Italian, Japanese, Ramen',
        annual_dol: 375.15,
        description:
            'Japanese tapas bar serving sushi, sake & cocktails in a hip setting with a bi-level patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.9533,
        total_orders: 5392.43,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Tacos, Hamburger, Salad, Mexican, Nachos, Margarita, Quesadillas, Wine, Rum, Asian',
        annual_dol: 5876.92,
        description:
            'Upbeat eatery & bar featuring classic & fusion Mexican dishes, plus beer, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7201,
        store_longitude: -73.9617,
        total_orders: 137.3,
        neighborhood: 'Williamsburg',
        keywords: 'Vegan, Vegetarian, Mexican, Tacos, Tequila, Margarita, Casual, Local, Fresh',
        annual_dol: 562.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9817,
        total_orders: 399.63,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Australian, Hotel, Wine, Trendy, Mediterranean, High-End, Elegant, Rooftop, Turkish, Chic',
        annual_dol: 450.69,
        description:
            "Chic rooms provide free Wi-Fi, smart TVs and iPads, plus minibars and marble bathrooms. Suites add wet bars; upgraded 1- and 2-bedroom suites have living rooms. Studios and 1- to 2-bedroom apartments offer kitchens with washer/dryers. Luxe penthouses are also available. Some units have balconies.Continental breakfast is included. There's an elegant bar, a spa and a hammam, plus an indoor pool and a 24-hour gym. Pets are welcome (surcharge).",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7215,
        store_longitude: -73.9585,
        total_orders: 1580,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Coffee, Delicious, Rum, Premium, Sweet, Fantastic, Perfect, Intimate, Great',
        annual_dol: 5952.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7805,
        store_longitude: -73.9812,
        total_orders: 6016.27,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Hotel, Beer, Ale, Fun, Awesome, Perfect, Intimate, Great, Local',
        annual_dol: 6016.27,
        description:
            'The Beacon Theatre is an entertainment venue at 2124 Broadway, adjacent to the Hotel Beacon, on the Upper West Side of Manhattan in New York City. Opened in 1929, the Beacon Theatre was developed by Samuel "Roxy" Rothafel and built as a movie palace, with 2,894 seats across three levels.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.783,
        store_longitude: -73.9528,
        total_orders: 50.33,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Salad, Pizza, French, Fun, Global, Fresh, Great, Cultural',
        annual_dol: 68.52,
        description:
            '92nd Street Y, New York is a cultural and community center located on the Upper East Side of Manhattan in New York City, at the corner of East 92nd Street and Lexington Avenue.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7628,
        store_longitude: -73.9869,
        total_orders: 843.48,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Vegetarian, Italian, Gluten Free, Beer, Wine, Dessert, Homemade, Seasonal, Relaxed',
        annual_dol: 880.05,
        description:
            'Relaxed venue with seasonal cocktails, Italian craft beers & classic Neapolitan pizza.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 1521.36,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Dessert, Beer, Spectacular, Premium, Serene, Amazing, French, Intimate, Great',
        annual_dol: 1966.47,
        description:
            "Chef Thomas Keller's New American restaurant offers luxe fixed-price menus, with Central Park views.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7187,
        store_longitude: -73.9894,
        total_orders: 2558.66,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Burger, Rooftop, Salad, Luxurious, French Toast, Hotel, Organic, Spectacular, French',
        annual_dol: 2612.95,
        description:
            'Cool, multi-level venue with a chandelier-lit restaurant & lively rooftop lounge with skyline views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.664,
        store_longitude: -73.9544,
        total_orders: 255.25,
        neighborhood: 'Crown Heights',
        keywords: 'Zombie, Vintage, Hotel, Yummy, Fun, Awesome, Amazing, Great, Western, Local',
        annual_dol: 1043.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7294,
        store_longitude: -74.0008,
        total_orders: 34.93,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Italian, Pizza, Delicious, Organic, Intimate, Fresh, Traditional, Classic, Tradition',
        annual_dol: 142.79,
        description:
            'Narrow Italian eatery with counter-service offering organic pasta, housemade sauces & classic sides.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7623,
        store_longitude: -73.9827,
        total_orders: 127.23,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Vegetarian, Margarita, Wine, Beer, Sustainable, Healthy, Relaxed, American',
        annual_dol: 520.09,
        description:
            'Relaxed eatery serving sustainable seafood plus beer, wine & cocktails in bright, beachy digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6833,
        store_longitude: -73.9761,
        total_orders: 31321.09,
        neighborhood: 'Prospect Heights',
        keywords: 'Wine, Italian, Sweet, Fun, Classic, Great, Casual',
        annual_dol: 32403.96,
        description:
            "Barclays Center is a multi-purpose indoor arena in the New York City borough of Brooklyn. The arena is home to the Brooklyn Nets of the National Basketball Association and the New York Liberty of the Women's National Basketball Association.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7361,
        store_longitude: -73.9965,
        total_orders: 2119.64,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Hotel, Champagne, Wine, Upscale, Elegant, Vintage, Iconic, Warm, Exquisite, Coffee',
        annual_dol: 2211.54,
        description:
            "Sophisticated, art deco style rooms offer free Wi-Fi and flat-screen TVs, as well as vintage rotary phones and custom ebony beds. Suites add sitting areas; some have furnished terraces. Room service is available 24/7.Amenities include a retro-chic bar, a polished restaurant with plush booths, and a refined guest lounge featuring a fireplace. There's also a 24-hour fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7331,
        store_longitude: -73.9981,
        total_orders: 3458.56,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, French, Spicy, Classy, Aesthetic, Creative, Cool, Great, Casual, Dynamic',
        annual_dol: 3688.42,
        description:
            'Creative craft cocktails & snacks in a petite brick-walled space with some banquette seats & stools.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6812,
        store_longitude: -73.977,
        total_orders: 170.1,
        neighborhood: 'Park Slope',
        keywords:
            'Chocolate Cake, Milkshake, Wine, Dessert, Coffee, Beer, Vegan, Tasty, Banana Split, Italian',
        annual_dol: 695.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 556.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Italian, Wine, Delicious, Incredible, Wonderful, Great, Casual',
        annual_dol: 2273.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7109,
        store_longitude: -73.9503,
        total_orders: 1786.08,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Whiskey, Wine, Beer, Coffee, Ale, Irish Car Bomb, Tasty, Irish, Timeless, American',
        annual_dol: 1823.97,
        description:
            'Airy sports bar with cabinlike wooden walls & booths, multiple TVs, beer, cocktails & food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7133,
        store_longitude: -73.9356,
        total_orders: 3316.29,
        neighborhood: 'East Williamsburg',
        keywords:
            'Tequila, Mexican, Tacos, Margarita, Rustic, Fun, Latin, Caribbean, Awesome, Lively',
        annual_dol: 10566.05,
        description:
            'Lively Williamsburg bar/restaurant with Mexican-Dominican dishes & a party atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6835,
        store_longitude: -73.9662,
        total_orders: 429.82,
        neighborhood: 'Clinton Hill',
        keywords: 'Pizza, Burger, Vodka, Sushi, Beer, Meatball, Wine, Gourmet, Italian, Rustic',
        annual_dol: 478.93,
        description:
            'This cozy, gourmet eatery serves wood-fired Neapolitan pizzas, rustic small plates & pastas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7505,
        store_longitude: -73.9792,
        total_orders: 2167.84,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Tasty, Pizza, Coffee, Salad, Delicious, Trendy, Wholesome, Roast Beef, Healthy, Polished',
        annual_dol: 2213.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7443,
        store_longitude: -73.9829,
        total_orders: 351.53,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords:
            'Korean, Upscale, Luxurious, Steak, Innovative, Comfortable, Fancy, Martini, Superb, Classic',
        annual_dol: 410.08,
        description:
            "Upscale Korean restaurant offering a chef's tasting menu with beverage pairings in chic environs.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7614,
        store_longitude: -73.9245,
        total_orders: 1240.7,
        neighborhood: 'Astoria/LIC',
        keywords: 'Champagne, Beer, Bourbon, Fun, Casual, Great, Chill',
        annual_dol: 1323.18,
        description:
            'Large sports bar with TVs for watching the game plus numerous billiards & ping-pong tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7744,
        store_longitude: -73.9079,
        total_orders: 220.6,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Wine, Spicy, Cosmopolitan, Mediterranean, Homemade, Greek, Bellini',
        annual_dol: 901.75,
        description:
            '"Lagano Bar & Restaurant is where you can feel and taste various flavors, from International cuisines like tapas, Cocktails, and wine bars with banquet hall. Our food is inspired by the quality and passion of culinary experts from all over the globe, from all the creative people who ever work and share knowledge with our Executive Chef and Owner Mr. Lagano Celic."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.888,
        total_orders: 61.83,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Lobster, Steak, Pizza, Spanish, Wine, Delicious, Spacious, Fusion, Sangria, Wonderful',
        annual_dol: 103.96,
        description:
            'Spacious Spanish mainstay featuring standards, tapas & cocktails plus guitar music Saturday nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9905,
        total_orders: 140.33,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Vegetarian, Tasty, Thai, Gluten Free, Dessert, Wine, Vietnamese, Spicy, Cozy',
        annual_dol: 146.41,
        description:
            'Intimate restaurant serving traditional Thai in a space with bright decor & exposed brick.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.9895,
        total_orders: 100.82,
        neighborhood: 'Lower East Side',
        keywords:
            'Coffee, American, Legendary, Salad, Classic, Eclectic, Retro, Chic, Signature, Fantastic',
        annual_dol: 509.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7223,
        store_longitude: -73.99,
        total_orders: 493.26,
        neighborhood: 'Bowery',
        keywords: 'Cozy, Latin, Casual, Awesome, Unique',
        annual_dol: 2016.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.594,
        store_longitude: -73.5801,
        total_orders: 178.04,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Garlic Bread, Burger, Sushi, Hotdog, Shrimp Cocktail, Salad, Hamburger, Lobster Bisque',
        annual_dol: 270.3,
        description:
            'Casual seafood restaurant with a raw bar, sushi & fried sandwiches, plus waterfront tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 2310.1,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Wine, Upscale, Elegant, Cosmopolitan, Delicious, Premium, Martini, Sophisticated, Inviting',
        annual_dol: 2359.12,
        description:
            'Upscale lounge for cocktails, bites & views from the fourth floor of the Time Warner Center.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7625,
        store_longitude: -73.9753,
        total_orders: 7851.99,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Rich, Hotel, Stylish, Elegant, Funky, Vibrant, Fun, Australian, American, Colorful',
        annual_dol: 8066.17,
        description:
            "Hip rooms with an English-country vibe feature floor-to-ceiling windows, free Wi-Fi and flat-screen TVs, along with minibars; some have terraces and/or sitting areas. The 1- to 3-bedroom suites add living rooms and 2nd bathrooms. Upgraded suites provide kitchens; butler service is offered. Room service is available 24/7.There's a stylish restaurant with vibrant decor. Other amenities include a formal lounge, a terrace and a movie theater, plus a gym and an art-filled lobby.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9759,
        total_orders: 2665.58,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Sushi, Salad, Steak, Wine, Japanese, Beer, Asian, Bbq, Fun, Cool',
        annual_dol: 2737.78,
        description: 'Hibachi chain serving Japanese dishes grilled tableside by theatrical chefs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.849,
        store_longitude: -73.6452,
        total_orders: 12.13,
        neighborhood: '',
        keywords:
            'Salad, Wine, Italian, Warm, Clean, Perfect, Exquisite, Casual, Handcrafted, Awesome',
        annual_dol: 49.6,
        description:
            '"Tavern 227 is a casual, Brooklyn-inspired, restaurant and bar located on the North Shore of Long Island. Upon entering, you will see the focal point of the establishment – our wood handcrafted bar, finished with Italian Carrara marble. The newly restored space was designed with local artists to create a venue that is perfect for dining, having a drink, or hosting that special event. Our Italian-American menu is prepared with only the finest sourced ingredients and complemented by a curated selection of wines, cocktails and cordials. At Tavern 227, we strive to exceed expectations by delivering high quality ingredients and warm hospitality for a delightful dining experience."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.87,
        store_longitude: -73.9154,
        total_orders: 1454.77,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Burger, Steak, Salad, Mexican, Cuban, Dessert, Nachos, Tasty, Spanish, Cozy',
        annual_dol: 1697.04,
        description:
            'Intimate, low-lit restaurant offering classic Cuban meals, cocktails & hookahs in a vibrant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7316,
        store_longitude: -74.0004,
        total_orders: 36.37,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Cheesecake, Wine, Salad, Italian, Coffee, Shrimp Cocktail, Beer, Impeccable, Healthy, Romantic',
        annual_dol: 148.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7405,
        store_longitude: -73.9924,
        total_orders: 14604.34,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Salad, Chinese, Coffee, Elegant, Japanese, Asian, Cozy, Eclectic, Spectacular',
        annual_dol: 14927.63,
        description:
            'Glitzy restaurant in the former Limelight space offering modern Chinese fare in a multiroom setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7264,
        store_longitude: -73.9835,
        total_orders: 1274.26,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Chicken Pot Pie, Irish, American, Classic, Fantastic, Casual, Great, Cool',
        annual_dol: 1485.31,
        description:
            'Irish watering hole dispensing pints & classic pub grub in a snug, patio-equipped setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6773,
        store_longitude: -73.9728,
        total_orders: 6839.1,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords: 'Flavorful, Tasty, Beer, Relaxed, Fun, Casual, Great, American',
        annual_dol: 6984.21,
        description:
            'Relaxed sports bar featuring pool, ping pong, bowling & air hockey, plus casual American fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9862,
        total_orders: 1563.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Steak, Burger, Hamburger, Vegetarian, Salad, Meatball, Scotch, Applejack, Beer, Wine',
        annual_dol: 1608.95,
        description:
            'Open space with cast-iron fixtures & old machinery offering American classics & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7131,
        store_longitude: -73.9582,
        total_orders: 7117.44,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Vodka, Friendly, Welcoming, Cool, Perfect, Casual, Fresh, Great, Amazing',
        annual_dol: 7268.46,
        description:
            'Nothing-fancy corner tavern serving beer & other drinks in a narrow space with outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7066,
        store_longitude: -74.0033,
        total_orders: 1639.66,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Pizza, Burger, Upscale, Luxurious, Wine, Gourmet, Spicy, Beer, Premium, Fantastic',
        annual_dol: 1674.45,
        description:
            'Upscale cinema chain with plush seating, gourmet light bites & cocktails, plus in-theater service.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7709,
        store_longitude: -73.7355,
        total_orders: 97.48,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Gluten Free, Salad, Wine, Delicious, Trendy, Thai, Upscale, Italian, Romantic, Cozy',
        annual_dol: 264.92,
        description:
            '"Serving delicious and authentic Thai cuisine with a cozy and immersive atmosphere that will transport you to the heart of Thailand"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6764,
        store_longitude: -73.9633,
        total_orders: 694.5,
        neighborhood: 'Crown Heights',
        keywords: 'Tequila, Vodka, Beer, Margarita, Gin, Rum, Karaoke, Sweet, Triple Sec, Awesome',
        annual_dol: 2641.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6879,
        store_longitude: -73.9633,
        total_orders: 23.02,
        neighborhood: 'Clinton Hill',
        keywords:
            'Vegan, Vegetarian, Salad, Coffee, Beer, Margarita, Cuban, Nachos, Bourbon, Delicious',
        annual_dol: 94.12,
        description:
            'Coffee & vegan sandwiches & pastries served in a bright, contemporary cafe with a grocery shop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7202,
        store_longitude: -73.9554,
        total_orders: 6542.23,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotdog, Beer, Whiskey, Gin, Tasty, Warm, Laid-Back, Friendly, Homemade, Bloody Mary',
        annual_dol: 7054.33,
        description:
            'Dimly lit saloon with an extensive whiskey & beer list, plus grub that includes occasional BBQ.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7467,
        store_longitude: -73.9799,
        total_orders: 46.88,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Wine, Steak, Beer, Salad, Dessert, Mediterranean, Premium, American, Intimate, Global',
        annual_dol: 191.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.771,
        store_longitude: -73.962,
        total_orders: 3795.47,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Dessert, Warm, Cozy, Classy, Premium, Great, Terrific, Rare',
        annual_dol: 4024.34,
        description:
            'Cocktails & bar bites in a classy watering hole with palm tree wallpaper & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7707,
        store_longitude: -73.9572,
        total_orders: 971.5,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Thai, Gluten Free, Coffee, Wine, Chinese, Organic, Trendy, Ale, Crab Rangoon',
        annual_dol: 1058.79,
        description:
            'Bright, contemporary-style dining spot serving refined Thai curries & stir-fries.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9582,
        total_orders: 72.56,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Hotel, Burger, Wine, Donut, Premium, Delicious, Classic, Rooftop, Contemporary',
        annual_dol: 296.61,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7236,
        store_longitude: -73.9519,
        total_orders: 146.84,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords: 'Savory, Cool, Salad, Premium, Shrimp Cocktail, Casual, American',
        annual_dol: 194.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6875,
        store_longitude: -74.0013,
        total_orders: 530.69,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Beer, Margarita, Spicy, Spacious, Relaxed, Friendly, Amazing, Great, Excellent, Local',
        annual_dol: 666.49,
        description: 'Relaxed hangout specializing in craft beers on tap & in bottles & cans.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8411,
        store_longitude: -73.7858,
        total_orders: 108,
        neighborhood: 'City Island',
        keywords: 'Lobster, Crab Cakes, Salad, Spicy, Coffee, Beer, Hotel, Greek, Homemade, Fun',
        annual_dol: 168.49,
        description:
            '"City Island Yacht Club (CIYC) has been a haven for sailors for more than 100 years. Located in the Bronx on Long Island Sound, CIYC is a thriving community of enthusiastic sailors and a gathering place for those who enjoy time on the water or those who aspire to spend more time on the water! CIYC members hail from Manhattan, Brooklyn, Queens, the Bronx, Westchester County, Long Island and New Jersey. They are both expert and casual racers, weekend cruisers and day sailors. After a breezy afternoon on the boat they enjoy socializing in our waterfront restaurant and pub, admiring the dramatic sunset backdrop. The Galley Restaurant and pub is open to public May through October most Wednesdays and Weekends. Reservations Recommended."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5868,
        store_longitude: -73.7147,
        total_orders: 9038.75,
        neighborhood: '',
        keywords: 'Hotel, Relaxed, Sweet, Perfect, Great, Unique',
        annual_dol: 10087.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5231,
        store_longitude: -74.2394,
        total_orders: 504.49,
        neighborhood: 'Charleston',
        keywords:
            'Tacos, Lobster, Mexican, Burritos, Tex-Mex, Gluten Free, Homemade, Coleslaw, Casual, Classic',
        annual_dol: 1900.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7319,
        store_longitude: -73.9897,
        total_orders: 7550.19,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Pizza, Rooftop, Global, Exceptional, Clean, Excellent, Local',
        annual_dol: 8101.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7285,
        store_longitude: -73.9849,
        total_orders: 359.68,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Pizza, Italian, Meatball, Delicious, Hotel, Fun, Iconic, Great',
        annual_dol: 1262.69,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.733,
        store_longitude: -73.9861,
        total_orders: 2084.02,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Trendy, Gin, Beer, Delicious, Belgian, Friendly, Moroccan, Awesome, Perfect',
        annual_dol: 2222.56,
        description:
            'British-style pub & eatery providing elevated bar bites along with gin cocktails & U.K. brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8026,
        store_longitude: -73.9643,
        total_orders: 24.15,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Karaoke, Stylish, Wine, Quaint, Friendly, Fun, Eclectic, Casual, Modern, Great',
        annual_dol: 42.33,
        description:
            'A small, friendly gay lounge with a neighborhood vibe & weekly events like drag-queen karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8503,
        store_longitude: -73.9314,
        total_orders: 7.71,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Lobster, Beer, Coffee, Vodka, Shrimp Cocktail, Wine, Delicious, Intimate, Quirky, Cool',
        annual_dol: 31.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6947,
        store_longitude: -73.9108,
        total_orders: 7.06,
        neighborhood: 'Bushwick',
        keywords: 'Milkshake, Latin, Dominican, American, Natural, Casual, Local, Fresh',
        annual_dol: 28.87,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7594,
        store_longitude: -73.9826,
        total_orders: 425.65,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Mexican, Beer, Burritos, Wine, Local, Casual, Delicious, Healthy',
        annual_dol: 2169.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8286,
        store_longitude: -73.9485,
        total_orders: 4525.2,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Vegan, Steak, Cheesesteak, Tomato Soup, Gluten Free, Tasty, Bourbon, English Muffin, Gin',
        annual_dol: 10067.46,
        description:
            'Eclectic eats, craft cider & brews served up in a lively tavern with quirky decor & pinball games.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7609,
        store_longitude: -73.9687,
        total_orders: 75.09,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Gluten Free, Wine, Upscale, Organic, Contemporary, Modern, Casual, Classic, Iconic',
        annual_dol: 306.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7544,
        store_longitude: -73.9631,
        total_orders: 285.71,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Luxurious, Warm, Exquisite, American, Classic, French, Awesome, Fresh, Traditional, Contemporary',
        annual_dol: 291.77,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7575,
        store_longitude: -73.9859,
        total_orders: 1860.41,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Cheesecake, Chocolate Mousse, Dessert, Tacos, Italian, Hungarian, Delicious, Avocado Toast, Bbq, Thai',
        annual_dol: 2220.08,
        description:
            'Cheesecakes are the star at this Brooklyn-themed locale with deli fare, BBQ, a full bar & patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6903,
        store_longitude: -73.9963,
        total_orders: 8559.14,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Burger, Hamburger, Salad, Beer, Wine, Ale, Martini, Gin, French, Delicious',
        annual_dol: 8930.24,
        description:
            'Hip Cobble Hill pub serving grass-fed burgers, salads, sandwiches & sophisticated cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6897,
        store_longitude: -73.9709,
        total_orders: 6233.88,
        neighborhood: 'Fort Greene',
        keywords:
            'Lobster, Burger, Sushi, Salad, Steak, Italian, Wine, Japanese, Mediterranean, Chinese',
        annual_dol: 6678.19,
        description:
            'Mediterranean-Italian locale serving pastas, small plates & wood-fired dishes, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7835,
        store_longitude: -73.9508,
        total_orders: 428.24,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Salad, Dessert, Delicious, Wine, Warm, Mediterranean, Greek, Polished, Sleek',
        annual_dol: 466.72,
        description:
            'Upbeat Greek eatery serving an array of fish dishes & small plates in sleek digs with stone arches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7836,
        store_longitude: -73.9507,
        total_orders: 148.38,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Sushi, Salad, Japanese, Martini, Sashimi, Asian, Delicious, Teriyaki, Tranquil, Local',
        annual_dol: 162.66,
        description:
            'Tranquil find with dark-wood decor offering a sushi selection plus cooked Japanese dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7484,
        store_longitude: -73.9945,
        total_orders: 1502.7,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Burger, Vegan, Beer, Spicy, Ale, American, Irish, Cozy, Superb',
        annual_dol: 1587.87,
        description:
            'Cool, laid-back spot with a long bar for elevated pub fare & a rotating array of craft draft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7468,
        store_longitude: -73.9862,
        total_orders: 3774.73,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Vodka, Wine, Korean, Spicy, Spanish, American, Cozy, Bbq, Modern, Asian',
        annual_dol: 3925.22,
        description:
            'Stylish, rustic-chic gastropub turning out modern Korean bites & cocktails, plus weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7481,
        store_longitude: -73.9863,
        total_orders: 3526.39,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Korean, Wine, Upscale, Beer, Savory, Japanese, Bbq, Ramen, Perfect, Amazing',
        annual_dol: 3679.28,
        description:
            'Tabletop grills cook up choice cuts at this hip, upscale, Korean-inspired restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7484,
        store_longitude: -73.9967,
        total_orders: 643.62,
        neighborhood: '',
        keywords: 'Coffee, Perfect, Great, Local, Foreign',
        annual_dol: 2630.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6742,
        store_longitude: -73.9823,
        total_orders: 11.24,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Steak, Hamburger, Salad, Beer, Canadian, Bbq, Spicy, Cuban, Wine',
        annual_dol: 45.96,
        description:
            'Popular diner offering a lineup of short-order dishes like burgers, wings & salads.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.9898,
        total_orders: 1853.53,
        neighborhood: 'East Village/Bowery',
        keywords: 'Vegan, Pizza, Salad, Gluten Free, Italian, Wine, Dessert, Beer, Mimosa, Organic',
        annual_dol: 2111.04,
        description:
            'Vegan pizzeria for wood-fired pies with vibrant toppings, plus small plates & nut-based cheeses.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7386,
        store_longitude: -73.9889,
        total_orders: 475.79,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Wine, Flavorful, Bourbon, Lebanese, Trendy, Stylish, Rich, Delicious, Warm',
        annual_dol: 530.15,
        description:
            'Stylish setting for Middle Eastern small plates & shareable dishes, plus breads & desserts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7293,
        store_longitude: -73.9898,
        total_orders: 431.02,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Wine, Tasty, Local, Casual, Classic, Fresh, Great',
        annual_dol: 1623.95,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6393,
        store_longitude: -73.9684,
        total_orders: 2524.77,
        neighborhood: 'Flatbush',
        keywords:
            'Pizza, Italian, Salad, Breakfast Burrito, Whiskey, Delicious, Homemade, Cozy, Sweet, French',
        annual_dol: 3508.91,
        description:
            'Italian spot serving pizza, from-scratch pasta & cocktails in a cozy, wood-lined space with a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7519,
        store_longitude: -73.9738,
        total_orders: 278.02,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Wine, Japanese, Chinese, Thai, Beer, Asian, Spicy, Sashimi',
        annual_dol: 350.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.781,
        store_longitude: -73.7757,
        total_orders: 2257.49,
        neighborhood: 'Bayside/Bay Terrace',
        keywords:
            'Steak, Burger, Hamburger, Tasty, Delicious, Beer, Juicy, Margarita, American, Australian',
        annual_dol: 2407.43,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9795,
        total_orders: 1472.61,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Salad, Wine, Shrimp Cocktail, Mexican, Avocado Toast, Mimosa, Hotel, Mediterranean, American',
        annual_dol: 1503.85,
        description:
            'Bistro/wine bar in the Library Hotel serving American fare in a spare high-ceilinged space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9815,
        total_orders: 3550.84,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Burger, Lobster, Pizza, Salad, Shrimp Cocktail, Wine, Margarita, Delicious, American',
        annual_dol: 3626.18,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.874,
        store_longitude: -73.9091,
        total_orders: 662.08,
        neighborhood: 'Marble Hill/West Bronx',
        keywords:
            'Steak, Burger, Salad, Dessert, Wine, Chicken Wings, Delicious, French, Warm, French Onion Soup',
        annual_dol: 899.14,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5836,
        store_longitude: -73.9414,
        total_orders: 1287.83,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Coffee, French, Turkish, Savory, American, Mediterranean, Comfortable, Healthy, Modern, Friendly',
        annual_dol: 1513.37,
        description:
            'Mediterranean dishes are served at this waterfront destination with modern decor & outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7563,
        store_longitude: -73.9716,
        total_orders: 5126.71,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Wine, Rooftop, Elegant, Iconic, Premium, Awesome, Eclectic, Great, Thai',
        annual_dol: 5235.49,
        description:
            'All-season rooftop lounge serving cocktails along with views of Midtown & the Chrysler Building.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7577,
        store_longitude: -73.9749,
        total_orders: 4240.9,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Wine, Beer, Salad, Dessert, Martini, American, Glamorous, Opulent, Prime Rib',
        annual_dol: 4416.45,
        description:
            '"Simon Oren, Dudi Sasson and Chef James Tracey bring midtown Manhattan a glamorous bold American restaurant. A raw bar features every crustacean imaginable, and servers stroll through the dining room brandishing custom gridiron carts preparing items tableside. The menu circles the globe offering Chef Tracey’s seasonal cuisine alongside an extensive selection of Prime Dry-Aged Steaks, and Roast Prime Rib of Beef. Designed by Scott Kester and Dudi Sasson, the two-floor lofty space is comfortable and lavish with an expansive bar and seating for upwards of 200 guests; perfect for large group dining, with private rooms for business and social events."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9652,
        total_orders: 1617.72,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Shrimp Cocktail, Salad, Burger, Beer, Upscale, Elegant, Cozy, Rustic, Warm',
        annual_dol: 1718.82,
        description:
            'Rustic wine bar offering cheeses, charcuterie & upscale American entrees in a brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9694,
        total_orders: 31.16,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Beer, Fun, Sweet, Cool, Great, Casual',
        annual_dol: 127.37,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7385,
        store_longitude: -73.9832,
        total_orders: 99.31,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Vegetarian, Coffee, Beer, French Toast, Upscale, French, Cozy, Delicious, Warm',
        annual_dol: 163.3,
        description: 'Seasonal American home-cooking is served in a homey brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9874,
        total_orders: 100.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Ethiopian, Intimate',
        annual_dol: 412.81,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7524,
        store_longitude: -73.9956,
        total_orders: 552.92,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Hotel, Rooftop, Premium, Great, Retro, Friendly, Seasonal, Casual',
        annual_dol: 601.72,
        description:
            "Warmly decorated rooms offer free Wi-Fi, desks and coffeemakers, plus flat-screen TVs with premium cable channels. Suites add separate living areas with pull-out sofas, microwaves and minifridges; some have scenic city views.A hot breakfast buffet is complimentary. There's also a casual rooftop bar serving drinks and light fare. Other amenities include a 24/7 fitness room and a convenience store, in addition to a business center, meeting rooms and coin-operated laundry facilities.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7266,
        store_longitude: -73.9926,
        total_orders: 798.74,
        neighborhood: 'NoHo',
        keywords:
            'Wine, Italian, Avocado Toast, Mediterranean, Warm, Rustic, Welcoming, Authentic, Artisanal, Fantastic',
        annual_dol: 849.61,
        description:
            'Upmarket, carefully sourced Mediterranean plates served in a rustic-chic space with a wine cellar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7794,
        store_longitude: -73.9808,
        total_orders: 593.97,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Lasagna, Vegetarian, Wine, Italian, Gluten Free, Coffee, Dessert, Tasty, Cozy, Bruschetta',
        annual_dol: 606.57,
        description:
            'Small neighborhood salumi shop & restaurant, with Tuscan small plates, wine & cured meats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6649,
        store_longitude: -73.6991,
        total_orders: 44.03,
        neighborhood: '',
        keywords: 'Steak, Salad, Colombian, Dominican, Spanish, Casual, Awesome',
        annual_dol: 93.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7218,
        store_longitude: -73.9873,
        total_orders: 10883.54,
        neighborhood: 'Lower East Side',
        keywords:
            'French, Rich, Hotel, Eclectic, Classic, Australian, Modern, American, Lively, Timeless',
        annual_dol: 11808.03,
        description:
            'Classic French bistro fare with flair in a cool brasserielike space with chandeliers & banquettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.72,
        store_longitude: -73.9848,
        total_orders: 12355.23,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Whiskey, Salad, Gin, Irish, Warm, Organic, Eclectic, Sweet, Friendly',
        annual_dol: 12868.26,
        description:
            'Neighborhood taproom with barn-wood tables & a marble bar serving beer, whiskey & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9887,
        total_orders: 84.21,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Sushi, Wine, Beer, Upscale, Italian, Japanese, Romantic, Innovative, Comfortable',
        annual_dol: 344.21,
        description: '"Japanese-style Western Kitchen and Bar."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7675,
        store_longitude: -73.981,
        total_orders: 3152.39,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Lobster, Pizza, Salad, Italian, Delicious, Funky, Wine, Outstanding, Modern, Lively',
        annual_dol: 3301.35,
        description:
            'High-end Italian seafood & housemade pastas in a chic Central Park South setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7656,
        store_longitude: -73.9876,
        total_orders: 222.36,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Italian, Tasty, Authentic, Great, Sumptuous, Casual',
        annual_dol: 494.43,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7149,
        store_longitude: -73.9914,
        total_orders: 229.67,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Lobster, Wine, Beer, Martini, Dessert, Spanish, Mediterranean, Warm, Sweet, Fun',
        annual_dol: 449.69,
        description:
            'Coastal Spain & Portugal inspire the cooking at this crisp oyster bar & seafood specialist.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7527,
        store_longitude: -74.0073,
        total_orders: 131.75,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Urban, Warm, Adventurous, Chic, Diverse',
        annual_dol: 538.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6311,
        store_longitude: -73.5817,
        total_orders: 100.16,
        neighborhood: '',
        keywords: 'Steak, Sushi, Wine, Friendly, Hurricane, Fresh, Casual, Great',
        annual_dol: 409.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7444,
        store_longitude: -73.9981,
        total_orders: 3751.62,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Legendary, Friendly, Casual, Great, Attentive, Chill',
        annual_dol: 3751.62,
        description:
            'Venerable gay bar & lounge with a small stage that hosts drag shows & other events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.688,
        store_longitude: -73.986,
        total_orders: 1542.09,
        neighborhood: 'Boerum Hill',
        keywords:
            'Shrimp Cocktail, Sustainable, Delicious, Inviting, Seasonal, Perfect, Casual, Innovative, Laid-Back, Great',
        annual_dol: 1574.81,
        description:
            'Sophisticated cocktails, craft beers & raw-bar items including oysters offered in an inviting space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.9992,
        total_orders: 394.33,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Pizza, Wine, Dessert, Chinese, Asian, Relaxed, Bright, Casual, Colorful',
        annual_dol: 1016.13,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7438,
        store_longitude: -73.9945,
        total_orders: 228.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Whiskey Sour, Whiskey, French Toast, French, Shrimp Scampi, Tasty, Avocado Toast, Greek, American',
        annual_dol: 589.35,
        description:
            'This classic diner is open 24-hours & offers an array of traditional American food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7131,
        store_longitude: -73.959,
        total_orders: 2161.54,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Lobster, Shrimp Cocktail, Peruvian, Tasty, South American, Wine, Delicious, Uruguayan, American, Traditional',
        annual_dol: 2280.09,
        description:
            'Evocative, low-lit wine bar pairing by-the-glass & bottled options with South American bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6749,
        store_longitude: -73.9566,
        total_orders: 3238.52,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Salad, Pizza, Mexican, Beer, Coffee, Wine, Upscale, Cuban, Trendy',
        annual_dol: 3312.18,
        description:
            'Spacious New American restaurant serving trendy eats & less-expected items in vintage-inspired digs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7195,
        store_longitude: -73.9557,
        total_orders: 1259.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Beer, Hamburger, Tequila, Ale, Whiskey, Cheesecake, Salad, Vodka, Nachos',
        annual_dol: 1286.05,
        description:
            'Williamsburg bar known for its rotating inventory of hard-to-find draft & bottled brews.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6204,
        store_longitude: -74.1238,
        total_orders: 73.27,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords:
            'Burger, Tasty, Italian, American, Local, Yummy, Eclectic, Fancy, Casual, Colorful',
        annual_dol: 401.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6814,
        store_longitude: -73.9559,
        total_orders: 429.51,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Wine, Pizza, Mediterranean, American, Cozy, Intimate, Turkish, Classic, Awesome, Natural',
        annual_dol: 811.18,
        description:
            'A changing menu of Mediterranean cuisine & drinks served in a cozy setting with an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: null,
        store_longitude: null,
        total_orders: 69.78,
        neighborhood: '',
        keywords: 'Pizza, Beer, Wine, Russian, Nachos, American, Casual, Local, Excellent',
        annual_dol: 285.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9228,
        total_orders: 10479.17,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegan, Tasty, Beer, Hawaiian, Bbq, Awesome, Colorful, Inspiring, Friendly, Sweet',
        annual_dol: 12752.14,
        description:
            'Breezy corner spot offering colorful cocktails, beers & drink-friendly fare with a happy hour.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7184,
        store_longitude: -73.9591,
        total_orders: 72.42,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Pizza, Salad, Lobster Bisque, Italian, Margarita, Delicious, Trendy, Greek, Homemade',
        annual_dol: 88.71,
        description:
            'Restaurant & bar with outdoor picnic table seating open for breakfast, lunch, dinner & brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7632,
        store_longitude: -73.9778,
        total_orders: 6595.44,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Whiskey, Beer, Bourbon, Cozy, Fun, Lively, Friendly, Rum, Perfect, Authentic',
        annual_dol: 6776.9,
        description:
            'Dimly lit triplex sports bar/lounge drawing a crowd with drink specials & free popcorn.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.763,
        store_longitude: -73.9804,
        total_orders: 2129.17,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Vegetarian, Beer, Irish, Friendly, Recommended, Casual, Great',
        annual_dol: 2702.82,
        description:
            'Established tavern offering cocktails, draft brews, pub grub & sports TV in a laid-back atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8035,
        store_longitude: -73.9536,
        total_orders: 15.78,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Jamaican, Beer, Sushi, Wine, American',
        annual_dol: 64.49,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6895,
        store_longitude: -73.9732,
        total_orders: 13491.3,
        neighborhood: 'Fort Greene',
        keywords: 'Burger, Salad, Steak, Tequila, Wine, Italian, Whiskey, Japanese, Gin, Organic',
        annual_dol: 14461.42,
        description:
            'Hipster-friendly American eatery in Fort Greene, spun off from the Williamsburg original.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6823,
        store_longitude: -73.98,
        total_orders: 995.14,
        neighborhood: 'Boerum Hill',
        keywords:
            'Beer, Ale, Upscale, Belgian, Friendly, Amazing, Great, Excellent, Cool, Bloody Mary',
        annual_dol: 1016.26,
        description:
            'A basic neighborhood bar with a twist: this no-frills spot has a big craft beer offering & a patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 4063.58,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Burger, Pizza, Sushi, Chicken Parmigiana, Salad, Vodka, Spicy, Delicious, Ale, Turkish',
        annual_dol: 4164.47,
        description:
            '"Airy, industrial-chic hangout offering sushi, pizza & varied bar fare, plus craft brews & cocktails."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7263,
        store_longitude: -73.9774,
        total_orders: 89.74,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Mexican, Margarita, Rum, Rustic, Vintage, Wholesome, Classy, Japanese, Casual',
        annual_dol: 366.83,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7249,
        store_longitude: -73.9785,
        total_orders: 777.41,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Pizza, Salad, Whiskey, Gin, Chinese, Stylish, Casual, American, Friendly, Perfect',
        annual_dol: 1901.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.9853,
        total_orders: 647.4,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Korean, Mexican, Bbq, Pizza, Sushi, Coffee, Beer, Kbbq, Ramen, Spicy',
        annual_dol: 1673.28,
        description:
            'Generous plates of homestyle Korean barbecue are served at this bustling, informal eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7446,
        store_longitude: -73.9868,
        total_orders: 2801.65,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Steak, Burger, Salad, Vodka, Caesar Salad, American, Bloody Mary, Cozy, Prosecco, Modern',
        annual_dol: 9835.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8051,
        store_longitude: -73.9547,
        total_orders: 1695.65,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Burger, Tasty, Salad, Italian, Wine, Delicious, Beer, Mimosa, Warm, Creamy',
        annual_dol: 1695.65,
        description:
            'The bottomless-mimosa brunch is a draw at this sleek, warm Italian eatery on a Lower Harlem corner.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6812,
        store_longitude: -73.9365,
        total_orders: 2483.96,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Burger, Salad, Dessert, Coffee, Gourmet, Wine, Beer, Mediterranean, Healthy, Friendly',
        annual_dol: 3187.98,
        description:
            'Indoor-outdoor bar in Prospect Park offering burgers, hot dogs & fries along with beer & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7552,
        store_longitude: -73.8865,
        total_orders: 179.65,
        neighborhood: 'Jackson Heights',
        keywords:
            'Mexican, Margarita, Wine, Fajitas, American, Warm, Homemade, Latin, Natural, Authentic',
        annual_dol: 216.24,
        description:
            'Hearty Mexican fare, including fajitas, tableside guacamole & enchiladas, served in a warm setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7246,
        store_longitude: -73.9946,
        total_orders: 493.72,
        neighborhood: 'Nolita',
        keywords:
            'Salad, Dessert, Wine, Mexican, Italian, Delicious, Stylish, French, Polished, Friendly',
        annual_dol: 1509.47,
        description:
            'Stylish restaurant serving contemporary cuisine in an intimate, informal setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7222,
        store_longitude: -73.9965,
        total_orders: 773.43,
        neighborhood: 'Nolita',
        keywords:
            'Wine, Coffee, Beer, Margarita, Delicious, Hotel, Sweet, Great, Terrific, Classic',
        annual_dol: 3504.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7242,
        store_longitude: -73.9946,
        total_orders: 388.99,
        neighborhood: 'Nolita',
        keywords: 'Pizza, Italian, Vegan, Wine, Beer, Dessert, Warm, Casual, Authentic, Local',
        annual_dol: 585.35,
        description:
            'Cozy, vintage-inspired cafe serving Italian fare ranging from snacks & entrees to gelato & espresso.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8378,
        store_longitude: -73.9225,
        total_orders: 7.99,
        neighborhood: 'Highbridge/West Bronx',
        keywords: 'Beer, Wine, American, British, Great, Amazing, Casual',
        annual_dol: 32.67,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9786,
        total_orders: 74.18,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Trendy, Premium, American, Global, Southern, Fantastic, Personalized, Indian',
        annual_dol: 303.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6293,
        store_longitude: -73.581,
        total_orders: 3131.68,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Garlic Bread, Lobster Bisque, Wine, Beer, Bourbon, Crisp, Lively, American',
        annual_dol: 3836.17,
        description:
            'Lively marina haunt with a raw bar & basic American menu, plus a sandy patio, music & a dance floor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 198.34,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vegan, Vegetarian, Salad, Wine, Coffee, Beer, Organic, Rustic, Bourbon, Swiss',
        annual_dol: 285.91,
        description:
            'Locally sourced, upscale American cuisine served in a barn-inspired setting with a chef’s table.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7394,
        store_longitude: -74.002,
        total_orders: 2489.69,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Whiskey, Beer, Wine, Sushi, Irish, Salad, Ale, Chinese, Upscale, Cozy',
        annual_dol: 2542.52,
        description:
            'Cozy, country-style Irish pub offering cocktails, whiskey & locally sourced beer, plus comfort food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.787,
        store_longitude: -73.816,
        total_orders: 372.79,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Salad, Pizza, Italian, Mediterranean, Homemade, Clean, Casual, Friendly, Traditional',
        annual_dol: 397.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5859,
        store_longitude: -73.694,
        total_orders: 425.31,
        neighborhood: '',
        keywords:
            'Burger, Vodka, Irish, Spicy, Rum, Delicious, Relaxed, Casual, Friendly, Intimate',
        annual_dol: 487.61,
        description:
            'Irish & American pub fare in a relaxed setting with sidewalk tables, sports on TV & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.725,
        store_longitude: -73.9776,
        total_orders: 3269.08,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Champagne, Wine, Tasty, Beer, Margarita, Coffee, Savory, Warm, Rum, Fun',
        annual_dol: 3723.25,
        description:
            'Cutting-edge cocktails are served in a no-frills setting at this laid-back barroom.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7104,
        store_longitude: -73.9632,
        total_orders: 1179.02,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Steak, Vegan, Scotch, Wine, Vodka, Salad, Four Horsemen, Stylish, Impeccable, Spanish',
        annual_dol: 1408.4,
        description:
            'Mediterranean-influenced New American dishes & craft cocktails in casually hip digs with a bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7101,
        store_longitude: -73.9625,
        total_orders: 2045.66,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Hotel, Spectacular, Premium, Iconic, Perfect, Diverse',
        annual_dol: 2229.46,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7099,
        store_longitude: -73.9627,
        total_orders: 374.67,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Vodka, Elegant, Coffee, Beer, Gin, Martini, Spacious, Premium, Sweet',
        annual_dol: 398.72,
        description: 'Cocktails & cigars are featured at this Williamsburg lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7064,
        store_longitude: -74.0026,
        total_orders: 1257.04,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Vodka, Wine, Beer, Karaoke, Gin, Delicious, Creamy, Playful, Seasonal, Japanese',
        annual_dol: 5138.38,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6805,
        store_longitude: -73.9624,
        total_orders: 481.67,
        neighborhood: 'Crown Heights/Clinton Hill',
        keywords: 'Caribbean, Premium, Local, Casual, Great',
        annual_dol: 1968.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7114,
        store_longitude: -73.9477,
        total_orders: 30.47,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Vegetarian, Salad, Peruvian, Delicious, Casual, Fresh, Excellent, Wonderful, Great',
        annual_dol: 124.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7255,
        store_longitude: -73.978,
        total_orders: 4.54,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Hamburger, Tasty, Cozy, Brazilian, Latin, Friendly, American, Lively, Fusion',
        annual_dol: 18.57,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.722,
        store_longitude: -73.958,
        total_orders: 16191.42,
        neighborhood: 'Williamsburg',
        keywords:
            'French, Shrimp Cocktail, Wine, Hotel, Upscale, Four Horsemen, Classy, Romantic, Asian, American',
        annual_dol: 16807.79,
        description:
            'Sprawling spot at the Wythe Hotel serving French fare, wine & cocktails in an easygoing setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7163,
        store_longitude: -73.9618,
        total_orders: 3759.04,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Whiskey, Coffee, Relaxed, Friendly, Awesome, Cool, Great, Fresh, Casual',
        annual_dol: 3759.04,
        description:
            'Relaxed watering hole with beer, booze, hot dogs & snacks to fuel pool, darts & board game playing.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7111,
        store_longitude: -73.9651,
        total_orders: 637.44,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Friendly, Casual, Intimate, Fun, Authentic, Latin, Local, Fresh, Lively, American',
        annual_dol: 665.08,
        description:
            'DJs, live global music & tropical cocktails inspire dancing & socializing at this lively lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7456,
        store_longitude: -73.9796,
        total_orders: 216.17,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Sushi, Lobster, Salad, Gluten Free, Japanese, Beer, French, Sweet, Friendly, Asian',
        annual_dol: 819.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7624,
        store_longitude: -73.9848,
        total_orders: 191.27,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Salad, Italian, Gluten Free, Delicious, Mediterranean, Cozy, Polished, Caesar Salad, Casual, Classic',
        annual_dol: 584.77,
        description:
            'Meaty fare from Brescia shares menu space with seafood from Amalfi in an airy, high-ceiling room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7219,
        store_longitude: -73.9922,
        total_orders: 5443.32,
        neighborhood: 'Bowery',
        keywords: 'Wine, Bourbon, Gin, Dessert, Rustic, American, Cozy, French, Crisp, Modern',
        annual_dol: 5724.12,
        description:
            'American fare, craft cocktails & Colonial tavern decor draws a hip crowd to this hidden-away spot.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7179,
        store_longitude: -73.9927,
        total_orders: 309.49,
        neighborhood: 'Bowery',
        keywords: 'Thai, Steak, Tequila, Wine, Beer, Martini, Spicy, Delicious, Fried Rice, Asian',
        annual_dol: 370.29,
        description:
            'Relaxed setting for Thai skewers, small plates & mains, plus beer, wine & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7215,
        store_longitude: -73.9924,
        total_orders: 3691.99,
        neighborhood: 'Bowery',
        keywords: 'Burger, Beer, Whiskey, Wine, Thai, Trendy, Israeli, Asian, Friendly, Sweet',
        annual_dol: 4410.28,
        description:
            'Watering hole with a dive bar vibe, disco ball & a pool table, serving beer & craft cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.861,
        store_longitude: -73.9259,
        total_orders: 46.37,
        neighborhood: 'Fort George',
        keywords: 'Salad, Burger, Bbq, Fried Rice, French, Latin, Asian, Dope, Sweet, American',
        annual_dol: 189.53,
        description: '"The best food, drinks, and ambience in the area."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.743,
        store_longitude: -73.9966,
        total_orders: 390.04,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Beer, American, Salad, Hotel, Classic, Intimate, Modern, Excellent, Fine',
        annual_dol: 588.18,
        description:
            'Intimate hangout with a lounge vibe & sidewalk tables serving American fare & drinks until late.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.787,
        store_longitude: -73.8074,
        total_orders: 685.52,
        neighborhood: 'Whitestone/Flushing/Beechhurst',
        keywords: 'Italian, Wine, Pizza, Warm, Asian, Wonderful, Great, Casual, Local',
        annual_dol: 700.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7374,
        store_longitude: -73.9994,
        total_orders: 425.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Italian, Thai, Canadian, Homemade, Relaxed, Friendly, Mediterranean, Fun, Casual',
        annual_dol: 443.82,
        description:
            'Mussels & frites are the main event at this hopping seafood eatery also featuring homemade donuts.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.777,
        store_longitude: -73.8177,
        total_orders: 29.53,
        neighborhood: 'Whitestone/Flushing',
        keywords: 'Pizza, Beer, Wine, Legendary, Awesome, Classic, Local, Casual',
        annual_dol: 80.55,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7148,
        store_longitude: -73.9446,
        total_orders: 621.89,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Beer, American, Casual, Local, Friendly, Chill, Outstanding, Great',
        annual_dol: 1015.16,
        description:
            'This chill neighborhood pub is known for its selection of burgers & craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7115,
        store_longitude: -73.9468,
        total_orders: 1165.91,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Coffee, Upscale, Irish, American, Southern, Friendly, Perfect, Casual, Awesome',
        annual_dol: 1165.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6761,
        store_longitude: -73.9719,
        total_orders: 72.28,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Sushi, Salad, Italian, Wine, Delicious, Refined, Homemade, Classy, Amazing',
        annual_dol: 295.44,
        description:
            'Refined setting for rustic, seasonal Italian dishes plus biodynamic & reserve wines.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5886,
        store_longitude: -73.6837,
        total_orders: 103.11,
        neighborhood: '',
        keywords: 'Pizza, Foreign, American',
        annual_dol: 153.23,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9858,
        total_orders: 336.15,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Beer, Wine, Coffee, Hamburger, Ale, Irish, Delicious, Eclectic, Fun, Friendly',
        annual_dol: 392.13,
        description:
            'Sports fans (especially hockey fanatics) flock to this classic Irish pub to watch the game.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.742,
        store_longitude: -73.9781,
        total_orders: 1054.51,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Whiskey, Beer, Irish, Warm, Rum, Hotel, Friendly, Classy, Homey, Great',
        annual_dol: 1290.59,
        description:
            'Homey pub with a working fireplace offering Irish-American eats, draft beer & whiskey.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6843,
        store_longitude: -73.9385,
        total_orders: 688.64,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Pizza, French Toast, Warm, French, Thai, Bright, Omelette, Global, Creative, Casual',
        annual_dol: 864.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.9225,
        total_orders: 2770.27,
        neighborhood: 'Astoria/LIC',
        keywords: 'Salad, Chicken Alfredo, Buffalo Chicken Salad, Beer, American, Casual, Classic',
        annual_dol: 3009.73,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7995,
        store_longitude: -73.9624,
        total_orders: 6565.53,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Mexican, Tacos, Beer, Margarita, Trendy, Casual, Classy, Intimate, Modern, Local',
        annual_dol: 8531.69,
        description:
            'Chill choice for Mexican cuisine with a vibrantly decorated space & a petite patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7374,
        store_longitude: -73.8827,
        total_orders: 226.58,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Lasagna, Coffee, Salad, Mexican, Flavorful, American, Healthy, Ample, Greek, Authentic',
        annual_dol: 236.41,
        description:
            'Classic 24/7 diner with an extensive menu spanning typical greasy-spoon specialties to Greek dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7531,
        store_longitude: -73.973,
        total_orders: 65.29,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Incredible, Excellent, Tranquil, Natural, Unique, Signature',
        annual_dol: 266.9,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.825,
        store_longitude: -73.9518,
        total_orders: 1535.66,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Vegan, Pizza, Coffee, Margarita, Delicious, Spanish, Organic, Elegant, Spacious',
        annual_dol: 2820.2,
        description:
            '"The Dermatology Specialists (TDS) is NYC\'s largest dermatology group with 25+ locations across New York City, Brooklyn, Queens, Bronx and Long Island. Our offices are open 7 days a week until 7pm and we accept most health insurance plans. We combine the sophistication of cutting-edge medicine with the careful attention and intimacy of a down-to-earth, neighborhood doctor. Patients experience personalized care that reflects the TDS philosophy of bringing authenticity and comfort into traditionally clinical medical spaces."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.9728,
        total_orders: 2875.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Whiskey, Sushi, Beer, Coffee, Hotel, Stylish, Premium, Natural, Contemporary',
        annual_dol: 10095.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7954,
        store_longitude: -73.7419,
        total_orders: 1238.89,
        neighborhood: '',
        keywords: 'Premium, Great, Healthy, Indian',
        annual_dol: 1348.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6705,
        store_longitude: -73.894,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Whiskey, Wine, Vodka, Beer, Pizza, Gin, Hotel, Tex-Mex, Upscale, American',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.516,
        store_longitude: -74.2335,
        total_orders: 141.67,
        neighborhood: 'Tottenville',
        keywords: 'Burger, Pizza, Salad, Mexican, Wine, French, American, Fresh, Casual, Great',
        annual_dol: 144.68,
        description: '"Neighborhood Bar & Grill"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6857,
        store_longitude: -73.9909,
        total_orders: 3009.3,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Zombie, Whiskey, Beer, Irish, French, American, Casual, Bright, Friendly',
        annual_dol: 3073.16,
        description:
            'Dark, no-nonsense tavern pouring basic drinks & playing tunes from the jukebox.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.758,
        store_longitude: -73.984,
        total_orders: 1146.09,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Wine, French, Fried Rice, Prime Rib, Filet Mignon, Perfect, Friendly',
        annual_dol: 1185.75,
        description:
            'Prime steaks headline a French-influenced menu at this kosher restaurant & butcher shop.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7824,
        store_longitude: -73.9536,
        total_orders: 942.99,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Steak, Hamburger, Salad, Pizza, Italian, Dessert, Beer, Upscale, French Onion Soup',
        annual_dol: 1155.13,
        description:
            'Understated eatery turning out Italian & American dishes, from red-sauce standards to grilled steak.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.9897,
        total_orders: 961.34,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Tomato Soup, Spicy, Nachos, Sweet, Spacious, Bold, Local, Casual, Great',
        annual_dol: 987.32,
        description:
            'Spacious stock market-themed bar offering dozens of draft and bottled beers plus basic pub grub.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9896,
        total_orders: 120.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Steak, Salad, Mexican, Beer, Bourbon, Mimosa, Bbq, Delicious, Swiss',
        annual_dol: 492.36,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7875,
        store_longitude: -73.7286,
        total_orders: 225.95,
        neighborhood: '',
        keywords:
            'Sushi, Japanese, Salad, Gluten Free, Shrimp Cocktail, Asian, Chinese, Organic, Spicy, Fusion',
        annual_dol: 277.91,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9886,
        total_orders: 1823.69,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Cornbread, Shrimp Cocktail, Salad, Bbq, Margarita, Champagne, Pinacolada, French',
        annual_dol: 1902.76,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6941,
        store_longitude: -74.0006,
        total_orders: 33.34,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Burger, Coffee, Salad, Beer, Roast Turkey, Avocado Toast, American, Casual, Fresh, Cool',
        annual_dol: 136.29,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7566,
        store_longitude: -73.9845,
        total_orders: 497.66,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Italian, American, Classic, Contemporary, Great, Modern, Fine, Recommended',
        annual_dol: 2034.25,
        description:
            "Luxury rooms with butler services, Italian linens and modern furnishings also feature TVs, Blu-ray players and free Wi-Fi. Suites add separate living areas, private terraces and/or whirlpool tubs.Dining options include a storied, ultra-chic restaurant/bar. There's also a renowned spa (surcharge) with an indoor saltwater pool and a 24-hour fitness center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7792,
        store_longitude: -73.9834,
        total_orders: 2106.39,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Steak, Vegan, Vegetarian, Cajun, Beer, Chilean, Mimosa, Trendy, French, American',
        annual_dol: 2197.79,
        description:
            'Southern-accented American cooking & live music events in a brownstone. Owned by Ashford & Simpson.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7726,
        store_longitude: -73.9808,
        total_orders: 2196.42,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Vegetarian, Tasty, Gluten Free, Gourmet, Dessert, Chinese, Upscale, Trendy, Elegant',
        annual_dol: 2252.43,
        description:
            'Classic, upscale Chinese eatery since 1971 serving traditional dishes in an elegant setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.825,
        store_longitude: -73.5886,
        total_orders: 1050.61,
        neighborhood: '',
        keywords:
            'Champagne, Wine, Stylish, Elegant, Romantic, Delicious, Vintage, Organic, Traditional, Classic',
        annual_dol: 1200.44,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6636,
        store_longitude: -73.9537,
        total_orders: 634.64,
        neighborhood: 'Little Caribbean',
        keywords: 'Burger, Pizza, Beer, American, Wine, Israeli, Modern, Healthy, Local, Casual',
        annual_dol: 924.63,
        description:
            'Laid-back eatery with a patio serving diverse small plates, mains & desserts, plus cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7734,
        store_longitude: -73.9117,
        total_orders: 353.05,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Wine, Beer, Irish, Awesome, Mediterranean, Great, Perfect, Greek, Indian, Casual',
        annual_dol: 1081.63,
        description:
            'Beer, wine & cocktails served in an old-school pub with sports on TV, live music & trivia nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7753,
        store_longitude: -73.9145,
        total_orders: 591.14,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Salad, Wine, Delicious, Greek, Spacious, Cozy, Mediterranean, Premium, Fresh',
        annual_dol: 629.09,
        description:
            'Long-running Greek taverna known for fresh grilled fish served in spacious quarters with a garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5225,
        store_longitude: -74.2394,
        total_orders: 783.89,
        neighborhood: 'Charleston',
        keywords: 'Italian, Pizza, Coffee, Dessert, Margarita, Casual, Traditional, Classic',
        annual_dol: 800.52,
        description:
            'Classic bakery with an attached traditional Italian restaurant for homestyle pastas & meat entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7454,
        store_longitude: -73.9534,
        total_orders: 1825.52,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Hamburger, Mexican, Beer, Salad, Margarita, Bourbon, American, Trendy, Upscale',
        annual_dol: 1833.27,
        description:
            'Hip bar & eatery offering small plates, sandwiches & brunch, plus craft beers & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.679,
        store_longitude: -73.9908,
        total_orders: 668,
        neighborhood: 'Gowanus',
        keywords:
            'Tequila, Wine, Beer, Scotch, Bourbon, Salad, Innovative, Latin, Awesome, Friendly',
        annual_dol: 1021.16,
        description:
            'Distressed materials add urban flair to this hip bar with a compact beverage list & creative plates.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6285,
        store_longitude: -74.0063,
        total_orders: 29.01,
        neighborhood: 'Dyker Heights',
        keywords: 'Pizza, Salad, Chicken Parmigiana, Italian, Caprese Salad, Casual',
        annual_dol: 118.59,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7377,
        store_longitude: -73.9878,
        total_orders: 833.93,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Beer, Wine, Bourbon, Tasty, Apple Pie, Italian, American, French, Contemporary',
        annual_dol: 2148.91,
        description:
            "Danny Meyer's first restaurant serves seasonal New American fare in a casually refined setting.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7993,
        store_longitude: -73.9687,
        total_orders: 10.82,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Vegetarian, Kebab, Thai, Naan Bread, Indian, Contemporary, Traditional, Casual, Dal Makhani, Saag Paneer',
        annual_dol: 44.22,
        description:
            'Traditional Indian cooking offered in an airy dining room or via popular take-out meal deals.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8792,
        store_longitude: -73.5846,
        total_orders: 152.76,
        neighborhood: '',
        keywords:
            'Crab Cakes, Salad, Wine, Beer, Italian, Mediterranean, Bbq, Cozy, French, American',
        annual_dol: 299.5,
        description:
            'Longtime spot for refined French & New American cuisine in a romantic, cozy room with a fireplace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7568,
        store_longitude: -73.9696,
        total_orders: 1070.2,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Whiskey, Cuban, Japanese, American, Lively, Amazing, Ramen, Ample, Great',
        annual_dol: 4149.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6788,
        store_longitude: -73.8877,
        total_orders: 33.92,
        neighborhood: 'Cypress Hills',
        keywords: 'Steak, Mexican, Nachos, Dominican, Spanish, Caribbean, Local, Casual, Vibrant',
        annual_dol: 138.66,
        description:
            'Vibrant kitchen for Dominican specialties, plus a covered patio, a bar & live music.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6866,
        store_longitude: -73.9547,
        total_orders: 2306.04,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Wine, Beer, Margarita, Bourbon, Warm, Cozy, Carrot Cake, Intimate, Relaxed, Classy',
        annual_dol: 2306.04,
        description:
            'Intimate, hideaway bar offering cocktails & brews, plus a fireplace in a dimly lit, relaxed setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6838,
        store_longitude: -73.9438,
        total_orders: 655.72,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Tequila, Wine, Beer, Gin, Rustic, Fun, Natural, Great, Casual, Rare',
        annual_dol: 669.63,
        description:
            '"Queer space for all. Cocktails, natural wine, beer, non-alcoholic drinks.Good food."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7606,
        store_longitude: -73.9953,
        total_orders: 1384.02,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Delicious, Glamorous, Spectacular, Creative',
        annual_dol: 1506.16,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7591,
        store_longitude: -73.9886,
        total_orders: 201.42,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Margarita, Beer, Fajitas, Delicious, Seasonal, Casual, Wonderful, Great',
        annual_dol: 707.1,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7093,
        store_longitude: -74.0138,
        total_orders: 791.32,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Wine, Vegan, Beer, Delicious, Hotel, Austrian, Innovative, Italian, Friendly, Mediterranean',
        annual_dol: 4486.05,
        description:
            '"We are a greek medeteranian inspired restaurant.,with two levels ,can accommodate over 150 people.,at one time. two private rooms, first floor bar restaurant ,second floor event and restaurant space"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7097,
        store_longitude: -74.0146,
        total_orders: 2803.04,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Hamburger, Pizza, Beer, Nachos, Spicy, Wine, American, Delicious, Hotel',
        annual_dol: 2869.07,
        description:
            'Burgers & a long beer list offered in a classic diner-style room with gingham tablecloths.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7499,
        store_longitude: -73.9855,
        total_orders: 4138.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Pizza, Steak, Chicken Pot Pie, Chicken Caesar Salad, Dessert, Beer, Wine, Bbq',
        annual_dol: 4295.69,
        description:
            'Dozens of TVs screening sports set the tone at this Irish pub/eatery near the Empire State Building.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7469,
        store_longitude: -73.9971,
        total_orders: 452.04,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Salad, Beer, American, French, Vintage, Caesar Salad, Eclectic, Contemporary',
        annual_dol: 461.64,
        description:
            'Neighborhood tavern with an industrial vibe & sports on TV, with craft beer & elevated bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7756,
        store_longitude: -73.9102,
        total_orders: 1286.39,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Wine, Beer, Delicious, Irish, Refined, Premium, Classic, Fun, Croissant Sandwich, Great',
        annual_dol: 1313.68,
        description: 'This formerly hidden speakeasy-style bar serves refined cocktails & snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6596,
        store_longitude: -73.9507,
        total_orders: 763.68,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Pizza, Italian, Dessert, Magical, Asian, Warm, Legendary, Contemporary, Traditional, Authentic',
        annual_dol: 961.52,
        description:
            'Eatery offering pinsa (Roman flatbreads) plus Italian dishes & Negronis in a brick-walled space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7154,
        store_longitude: -74.0173,
        total_orders: 6858.29,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Burger, Steak, Tomato Soup, Salad, Chicken Pot Pie, Shrimp Cocktail, Italian, Beer, Wine, Swiss',
        annual_dol: 7435.39,
        description:
            'Burgers & other American comfort food in down-to-earth digs with a lively atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7747,
        store_longitude: -73.9089,
        total_orders: 166.23,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Peruvian, Margarita, Spicy, Warm, Delicious, Colombian, Latin, Authentic, Contemporary, American',
        annual_dol: 325.91,
        description:
            '"Rosatoro Restaurant & Pisco Bar is contemporary restaurant brining authentic flavors of the Peruvian cuisine to the world. From spicy ceviches to Pisco Sours, Rosatoro aims to provide a warm experience to all foodies and travelers."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8104,
        store_longitude: -73.9512,
        total_orders: 1162.08,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Burger, Beer, Tasty, Wine, Great',
        annual_dol: 4750.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7636,
        store_longitude: -73.9868,
        total_orders: 161.16,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Salad, Southern, Thai, Casual, Local',
        annual_dol: 658.78,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7631,
        store_longitude: -73.9865,
        total_orders: 333.05,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Japanese, Wine, Sashimi, Tasty, Omakase, Casual, Tempura',
        annual_dol: 1361.42,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7282,
        store_longitude: -73.9996,
        total_orders: 21.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Kebab, Wine, Vegetarian, Ale, Shrimp Cocktail, Homemade, Contemporary, Tandoori Chicken, Indian, Modern',
        annual_dol: 88.21,
        description:
            'Casual, contemporary Indian spot offering a wide-ranging menu & craft cocktails, plus delivery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.865,
        store_longitude: -73.9279,
        total_orders: 951.84,
        neighborhood: 'Fort George',
        keywords: 'Hamburger, Beer, American, Bbq, Upscale, Relaxed, Casual, Local, Great',
        annual_dol: 972.03,
        description:
            'Relaxed pub with industrial decor serving craft brews, upscale American bar fare & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7351,
        store_longitude: -73.9972,
        total_orders: 586.81,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Salad, Chicken Parmigiana, Italian, Martini, French, Caesar Salad, Casual, Local, Fresh',
        annual_dol: 652.88,
        description:
            'Waiters in red coats & relish trays set the tone at this enduring Italian dining institution.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7364,
        store_longitude: -73.9973,
        total_orders: 1492.4,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Hamburger, Salad, Pizza, Coffee, Wine, Moroccan, Dessert, French, Flavorful, Mimosa',
        annual_dol: 1528.14,
        description:
            'A lively spot serving classic bistro fare with Moroccan influences (couscous, tagines & such).',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -74.003,
        total_orders: 306.82,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Chinese, Cuban, Seafood Paella, Impeccable, Puerto Rican, Vibrant, Asian, Fusion, Yummy',
        annual_dol: 327.22,
        description:
            'Asian-Cuban fare & unique craft cocktails served in roomy, relaxed surrounds with big potted palms.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6881,
        store_longitude: -73.9569,
        total_orders: 10455.79,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Casual, Friendly, Hip, Rare, Classic, Unpretentious',
        annual_dol: 11200.24,
        description:
            'Buzzy LGBTQ+ watering hole offering cocktails, plus live music, art shows & other events.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6835,
        store_longitude: -73.9661,
        total_orders: 1829.25,
        neighborhood: 'Clinton Hill',
        keywords: 'Beer, Wine, Upscale, Irish, American, Vintage, Classy, Casual, Fun, Lit',
        annual_dol: 1873.22,
        description:
            'Vintage cocktails, craft beer & wine are offered in a dimly lit room with a dark-wood bar & couches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9799,
        total_orders: 2058.84,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster Roll, Lobster, Salad, Wine, Beer, Coffee, Spicy, Delicious, Warm, Quaint',
        annual_dol: 2195.71,
        description:
            'Minimalist outpost for fresh & cured seafood small plates & sandwiches, plus craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7274,
        store_longitude: -73.9852,
        total_orders: 842.12,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegetarian, Coffee, Beer, Avocado Toast, Delicious, Healthy, American, Casual, Local, Great',
        annual_dol: 897.46,
        description:
            'Place for sandwiches, salads & breakfast with a healthy spin, plus juices & cold-brew coffee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -73.9839,
        total_orders: 5660.88,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Hotdog, Burger, Steak, Beer, Wine, American, Funky, Sleek, Classic, Casual',
        annual_dol: 11268.82,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7286,
        store_longitude: -73.9844,
        total_orders: 131.23,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Mexican, Tacos, Tequila, Salad, Margarita, Pizza, Burritos, Tex-Mex, Pinacolada, Latin',
        annual_dol: 347.49,
        description:
            'Unfussy Mexican nook whipping up hard & soft tacos, plus tamales, burritos & other traditional eats.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7472,
        store_longitude: -73.9772,
        total_orders: 867,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Ramen, Beer, Wine, Spicy, Hotel, Japanese, Great, Authentic, Relaxed, Casual',
        annual_dol: 1118.03,
        description:
            'Relaxed eatery specializing in tonkotsu ramen, complemented by skewers, sake & shōchū.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.9219,
        total_orders: 24.84,
        neighborhood: 'Astoria/LIC',
        keywords: 'Vegetarian, Thai, Chinese, Clean, Asian, Friendly, Casual, Authentic, Pad Thai',
        annual_dol: 101.56,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 42.7691,
        store_longitude: -78.7688,
        total_orders: 20.63,
        neighborhood: '',
        keywords: 'Upscale, Unforgettable, High-End, American, Unique',
        annual_dol: 84.34,
        description:
            '"At T Squared TEES & TAPS, our goal is to help you create unforgettable experiences with friends and family. This inaugural location in Orchard Park, New York features 3 main attractions... Beautiful Lounge Area and Outdoor Patio Indoor Golf Simulators Indoor and Outdoor Putting Greens"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.8302,
        total_orders: 1785.09,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Vegetarian, Vegan, Chinese, Spicy, Japanese, Ramen, Stylish, Asian, Shabu-Shabu, Friendly',
        annual_dol: 1822.97,
        description:
            'Meats, seafood, noodles & veggies are cooked in tableside hot pots at this stylish Japanese outfit.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6935,
        store_longitude: -73.8294,
        total_orders: 2.61,
        neighborhood: 'Richmond Hill/South Richmond Hill',
        keywords:
            'Vegetarian, Butter Chicken, Indian, Tandoori Chicken, Dal Makhani, Authentic, Casual, Local, Excellent, Great',
        annual_dol: 10.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.757,
        store_longitude: -73.9681,
        total_orders: 309.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Vegetarian, Chinese, Peking Duck, Flavorful, Spicy, Ramen, Grand Marnier, Filet Mignon, Fresh',
        annual_dol: 318.26,
        description:
            'Its namesake, carved tableside with fanfare, is the specialty of this Chinese restaurant.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6315,
        store_longitude: -74.0216,
        total_orders: 119.09,
        neighborhood: 'Bay Ridge',
        keywords: 'Comfortable, Casual, Friendly, Fun, Local, Great',
        annual_dol: 145.76,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7589,
        store_longitude: -73.9731,
        total_orders: 1132.33,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Glamorous, Elegant, Comfortable, Premium, Sidecar, Mead',
        annual_dol: 1213.7,
        description:
            'The Racquet and Tennis Club, familiarly known as the R&T, is a private social and athletic club at 370 Park Avenue, between East 52nd and 53rd Streets in Midtown Manhattan, New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7605,
        store_longitude: -73.99,
        total_orders: 541.53,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Ramen Noodles, Vegetarian, Vegan, Ramen, Whiskey, Beer, Japanese, Savory, Spicy, Juicy',
        annual_dol: 948.32,
        description:
            'Modern ramen spot with Japanese-style noodle bowls & sake in a big interior with a warm vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7845,
        store_longitude: -73.956,
        total_orders: 97,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Mexican, Pizza, Dessert, American, Hotel, Delicious, Memorable, Classic, Fresh, Great',
        annual_dol: 371.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7597,
        store_longitude: -73.9918,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Steak, Burger, Cheesesteak, Pizza, Beer, Wine, Spicy, Organic, American, Fresh',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7562,
        store_longitude: -73.9849,
        total_orders: 118.39,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Sleek, Fun, Awesome, Great, Excellent, American, Iconic, Casual, Clam Chowder',
        annual_dol: 483.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7248,
        store_longitude: -73.9983,
        total_orders: 3389.62,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Lobster, Lobster Roll, Burger, Sushi, Shrimp Cocktail, Salad, Vegetarian, Vegan, Gluten Free, Beer',
        annual_dol: 3421.77,
        description:
            'Upscale fish restaurant famous for its luxury yachtlike interior & hopping bar scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7302,
        store_longitude: -74.0005,
        total_orders: 1792.15,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Wine, Rum, Classy, Fun, Awesome, Fresh, Cool, Wonderful, Great',
        annual_dol: 1840.96,
        description:
            'Energetic house bands play at former hangout of Bob Dylan, Jimi Hendrix, Allen Ginsberg & others.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7758,
        store_longitude: -73.9151,
        total_orders: 3345.29,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Beer, Wine, Hotel, Irish, Friendly, Local, Casual, Great',
        annual_dol: 3416.27,
        description:
            'Chill, vintage-style haunt with craft cocktails & local draft beer, plus weekly live music & DJs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7521,
        store_longitude: -74.0081,
        total_orders: 1449.3,
        neighborhood: '',
        keywords:
            'Lobster Roll, Lobster, Vegetarian, Delicious, Magical, Organic, Sweet, Fun, Perfect, American',
        annual_dol: 1821.7,
        description:
            'From May to October this docked Coast Guard lightship is home to a vibrant bar scene.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7476,
        store_longitude: -73.9869,
        total_orders: 287.21,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Vegetarian, Korean, Gluten Free, Karaoke, Trendy, Modern, Casual, Perfect, Hip, Fusion',
        annual_dol: 879.92,
        description:
            'Trendy Koreatown bar & eatery offering inventive Korean fusion eats, soju cocktails & DJ nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7741,
        store_longitude: -73.7118,
        total_orders: 1162.44,
        neighborhood: '',
        keywords: 'Fresh, Outstanding, Fun, Great, Friendly, Golfclub',
        annual_dol: 1356.03,
        description:
            'Fresh Meadow Country Club is a country club with a golf course in the eastern United States, located on Long Island in Lake Success, New York, its home since 1946. The club opened in the New York City borough of Queens in 1923, with a golf course designed by noted course architect A. W.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7031,
        store_longitude: -73.908,
        total_orders: 6.1,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Burger, Mexican, Beer, Coffee, Wine, French, Casual, Classic, Spectacular',
        annual_dol: 24.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.753,
        store_longitude: -73.9225,
        total_orders: 16.84,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Hamburger, Kebab, Salad, Tasty, Colombian, Ale, Spicy, Fajitas, American',
        annual_dol: 68.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7355,
        store_longitude: -73.9858,
        total_orders: 438.01,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Beer, Wine, Delicious, Hamburger, Ale, Irish, Nachos, Cozy, Vintage, Polished',
        annual_dol: 487.33,
        description:
            'This contemporary hangout with an Irish theme features sports on TV & a menu of wings & burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7538,
        store_longitude: -73.9746,
        total_orders: 31.22,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Comfortable, Casual, Cool, Dynamic',
        annual_dol: 127.6,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7558,
        store_longitude: -73.767,
        total_orders: 211.47,
        neighborhood: 'Bayside Hills/Bayside',
        keywords: 'Karaoke, Wine, Beer, Fun, Clean, Great, Casual',
        annual_dol: 864.41,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.803,
        store_longitude: -73.6463,
        total_orders: 842.34,
        neighborhood: '',
        keywords: 'Tequila, Mexican, Tacos, Beer, Rich, Wine, Tex-Mex, Warm, American, Crisp',
        annual_dol: 893.04,
        description:
            'Sophisticated spot serving inventive takes on Mexican classics in a dramatically decorated room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9711,
        total_orders: 925.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Tacos, Vegan, Salad, Coffee, Beer, Mediterranean, Casual, Fresh, Approachable',
        annual_dol: 3021.89,
        description:
            '"A FINE CASUAL SEAFOOD KITCHEN Inspired by the open air seafood markets around the globe. At Pesce lulu, the best seafood meal experiences are approachable and available to all, recipes from around the globe right here for us in NYC - fine, fast and casually. Fish / shrimp tacos, mahi sandwiches, seafood platters, fresh salads, vegan options. We are located at 601 Lexington Ave inside the Hugh Market Plaza of the Citigroup Center Building at 53rd Street."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6176,
        store_longitude: -74.0294,
        total_orders: 674.12,
        neighborhood: 'Fort Hamilton',
        keywords: 'Hotdog, Burger, Beer, Salad, Wine, Bbq, Chicken Wings, American, Warm, Irish',
        annual_dol: 1092.5,
        description:
            'Homey pub with a year-round heated beer garden serving craft brews, sausages & updated comfort food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -73.9009,
        total_orders: 2935.62,
        neighborhood: 'Flushing/Maspeth',
        keywords:
            'Burger, Cheesecake, Vegetarian, Steak, Wine, Dessert, French Onion Soup, French, Irish, American',
        annual_dol: 3072.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9941,
        total_orders: 1173.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Beer, Wine, Delicious, Warm, Cozy, Irish, Welcoming, Awesome, Legendary',
        annual_dol: 1223.72,
        description:
            'This wood-paneled bar offers craft beer, cocktails, plus wings, pizza & sandwiches.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7579,
        store_longitude: -73.9838,
        total_orders: 607.13,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Salad, Lobster Bisque, Wine, Vodka, Delicious, American, Yummy, Contemporary',
        annual_dol: 1075.76,
        description: '"Steak and Seafood"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7811,
        store_longitude: -73.719,
        total_orders: 2621.38,
        neighborhood: '',
        keywords:
            'Glamorous, Elegant, Impeccable, Superb, Fun, Romantic, Great, Perfect, Excellent, Fresh',
        annual_dol: 2735.04,
        description:
            '"Our Banquet Hall facilities include breathtaking decor, glamorous rooms, superb cuisine — all at a posh locale that is sure to impress your guests."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6857,
        store_longitude: -73.9945,
        total_orders: 674.09,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Vegetarian, Steak, Vegan, Salad, Ethiopian, Margarita, Rich, African, Warm, Casual',
        annual_dol: 750,
        description:
            'Ethiopian fare, including rich stews, injera bread & vegan options served in warm surroundings.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7632,
        store_longitude: -73.9788,
        total_orders: 299.5,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Vegan, Vegetarian, Gluten Free, Beer, Chili Chicken, Whiskey, Korean, Japanese, Spicy',
        annual_dol: 445.07,
        description:
            'Asian-inspired & Japanese chain restaurant where dishes are whisked to long communal tables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7565,
        store_longitude: -73.9843,
        total_orders: 0,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Rich, Fabulous, Italian, Romantic, Beer, Great, Excellent, Modern, Fine',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9911,
        total_orders: 182.03,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Wine, Meatball, Irish, Modern, Sweet, Iconic, Nostalgic, Casual',
        annual_dol: 744.06,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7449,
        store_longitude: -73.9931,
        total_orders: 410.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Modern, Perfect, Gourmet, Creative',
        annual_dol: 709.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7475,
        store_longitude: -73.9866,
        total_orders: 62.01,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Korean, Bbq, Spicy, Delicious, Premium, Casual, Authentic, Great',
        annual_dol: 253.48,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.765,
        store_longitude: -73.9609,
        total_orders: 607.66,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Vegan, Pizza, Tasty, Fruity, Salad, Mediterranean, Homemade, Authentic, Spanish',
        annual_dol: 662.26,
        description:
            'Dependable Italian spot for pizza & pasta with outdoor seating for Second Avenue people-watching.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6841,
        store_longitude: -73.9385,
        total_orders: 3972.01,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Zombie, Beer, Wine, Spanish, Innovative, Fun, Awesome, Urban, Sophisticated, Great',
        annual_dol: 4322.57,
        description:
            'Sophisticated cocktails, beer & wine in an artfully designed space with happy-hour specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6841,
        store_longitude: -74.0025,
        total_orders: 173.9,
        neighborhood: 'Columbia Street Waterfront District',
        keywords: 'Italian, Inviting, Warm, Casual, American, Local, Recommended, Classic, Great',
        annual_dol: 710.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.676,
        store_longitude: -74.0142,
        total_orders: 800.6,
        neighborhood: 'Red Hook',
        keywords:
            'Burger, Steak, Lobster, Salad, Delicious, Cozy, Korean, Warm, French, Comfortable',
        annual_dol: 853.83,
        description:
            'This small Red Hook eatery features Korean-accented eats & a cozy back garden.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.521,
        store_longitude: -74.2369,
        total_orders: 59.87,
        neighborhood: 'Tottenville/Charleston',
        keywords: 'Clean, Modern, Friendly, Great, Fine, Unique',
        annual_dol: 91.52,
        description:
            'Modern skating rink catering to both young & old with a bar, adult DJ nights, inflatables & arcade.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6407,
        store_longitude: -73.7869,
        total_orders: 127.52,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Pizza, Italian, Coffee, Beer, Wine, Donut, Meatball, Martini, Upscale',
        annual_dol: 480.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7303,
        store_longitude: -73.9836,
        total_orders: 53.28,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Filipino, Spicy, Playful, Bright, Casual, Great',
        annual_dol: 89.54,
        description:
            'Filipino eats presented in a tropical diner that has weekly no-plates, no-silverware nights.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7325,
        store_longitude: -73.988,
        total_orders: 304.5,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Tacos, Chocolate Cake, Mexican, Beer, Texan, Margarita, Wine, Vegan, Tex-Mex, Vegetarian',
        annual_dol: 331.86,
        description:
            'Humble Tex-Mex restaurant fixing up tacos, tortas & chalupas, plus margaritas & creative cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.989,
        total_orders: 491.22,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Fun, Awesome, Wonderful, Great, Lit, Casual',
        annual_dol: 1265.81,
        description:
            'A longtime hole-in-the-wall gay bar with theme nights featuring DJs & go-go boys.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7391,
        store_longitude: -73.9892,
        total_orders: 374.74,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Italian, Dessert, Upscale, Cuban, Rustic, Romantic, Classy, Homemade, Intimate',
        annual_dol: 416.94,
        description:
            'Italian eatery highlighting handmade pasta, traditional meat & fish dishes plus local vegetables.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7981,
        store_longitude: -73.9689,
        total_orders: 447.8,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Mexican, Tacos, Margarita, Nachos, Martini, Organic, Spicy, Mojito, Casual, Keto',
        annual_dol: 477.56,
        description:
            '"Street Taco on Broadway will be our second location! Please visit our original location, 358 3rd Ave. until we open. Follow us on Instagram for the latest updates! @streettacony"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7305,
        store_longitude: -73.8599,
        total_orders: 91.95,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Kebab, Burger, Georgian, Russian, Mediterranean, Spacious, Asian, Casual, Middle Eastern, Fine',
        annual_dol: 264.33,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7589,
        store_longitude: -73.7755,
        total_orders: 430.96,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Vegan, Salad, Dessert, Beer, Upscale, Italian, Mediterranean, Delicious, Contemporary, Spanish',
        annual_dol: 449.65,
        description:
            'Warmly decorated high-end eatery offers patio dining & a menu of Mediterranean-American entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7518,
        store_longitude: -73.9767,
        total_orders: 7060.56,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Iconic, Upscale, Western, Stylish, Great',
        annual_dol: 26676.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.983,
        total_orders: 1818.8,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, French, Luxurious, Smoky, Sophisticated, Chic, Scandinavian, Classic, Vibrant, Heritage',
        annual_dol: 1857.39,
        description:
            'Sophisticated French cooking presented in chic, airy environs with salvaged-wood beams.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7425,
        store_longitude: -73.9866,
        total_orders: 706.13,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Salad, Steak, Pizza, Italian, Wine, Tequila, Upscale, Prosecco, Belgian, Homey',
        annual_dol: 1362.72,
        description:
            'Upscale restaurant with a terrace serving homestyle Italian fare, along with deli & diner classics.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7603,
        store_longitude: -73.9892,
        total_orders: 482.1,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tequila, Salad, Mexican, Margarita, Dessert, Irish, American, Caesar Salad, Friendly, Authentic',
        annual_dol: 605.47,
        description:
            'Tex-Mex meets Irish cuisine at this bi-level hangout with TVs for watching sports.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7851,
        store_longitude: -73.9559,
        total_orders: 100.67,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Sushi, Flavorful, Spicy, Japanese, Upscale, Casual, Wonderful, Local, Miso Soup, Excellent',
        annual_dol: 411.5,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6939,
        store_longitude: -73.9606,
        total_orders: 568.04,
        neighborhood: 'Bedford-Stuyvesant/Clinton Hill',
        keywords: 'Burger, Steak, Vegan, Salad, Beer, Cuban, Dessert, Tasty, American, Hotel',
        annual_dol: 2321.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5531,
        store_longitude: -73.933,
        total_orders: 325.57,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords: 'Hotel, Casual, Natural, American, Traditional',
        annual_dol: 419.11,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9898,
        total_orders: 17583.86,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Coffee, Delicious, Intimate, Cool, Memorable, Lit, Great, Creative, Casual, Unique',
        annual_dol: 18347.89,
        description:
            'Low-lit bar behind a hidden door in Patent Coffee, serving cocktails with an electric theme.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7509,
        store_longitude: -73.9975,
        total_orders: 332.69,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Wine, Champagne, Hotel, Golfclub, Fun, Intimate, Rooftop, Friendly, Fine, Casual',
        annual_dol: 1359.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7662,
        store_longitude: -73.9655,
        total_orders: 2612.13,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Coffee, Trendy, Martini, Delicious, Organic, Rustic, Spanish, French, Classy',
        annual_dol: 2667.56,
        description:
            'Farm-to-table American restaurant with private dining rooms located inside a historic brownstone.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7662,
        store_longitude: -73.9559,
        total_orders: 1556.17,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vodka, Beer, Nutritious, Delicious, Chili Con Carne, Quaint, Irish, Cozy, Clean, French Onion Soup',
        annual_dol: 1645.45,
        description:
            "Watering hole with Guinness & pub grub that's popular with medical staff from nearby hospitals.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7647,
        store_longitude: -73.9667,
        total_orders: 267.98,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Hotel, Rum, Classic, Premium, Great, Hip, Excellent, Local, Unique',
        annual_dol: 273.66,
        description:
            'Hip museum exhibiting illustrative works providing live drawing sessions & an on-site eatery.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6738,
        store_longitude: -73.999,
        total_orders: 21.18,
        neighborhood: 'Carroll Gardens',
        keywords: 'Beer, Pizza, Modern, Awesome, Local, Great, Excellent, Diverse',
        annual_dol: 86.58,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7334,
        store_longitude: -73.9901,
        total_orders: 2526.6,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Upscale, Trendy, Hotel, Premium, Friendly, Amazing, Great, Excellent, American, Contemporary',
        annual_dol: 2672.69,
        description:
            "Airy rooms feature 37-inch flat-screen TVs, iPod docks and WiFi (surcharge), as well as desks and coffeemakers. Some rooms have private terraces. Suites add separate living areas.There's an American brasserie and a contemporary cocktail lounge. Other amenities include an exercise room, complimentary bike rentals, and free WiFi in public areas.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -73.9903,
        total_orders: 3147.49,
        neighborhood: 'East Village/Bowery',
        keywords: 'Whiskey, Wine, Coffee, Beer, Rum, Hotel, Comfortable, Sweet, Fancy, Casual',
        annual_dol: 3299.94,
        description:
            'Small, casual bar with DJs, tattoo-inspired art in the bathrooms & old wine boxes on the ceiling.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5901,
        store_longitude: -74.0666,
        total_orders: 1077.46,
        neighborhood: 'Arrochar/South Beach',
        keywords: 'Clean, Perfect, Natural, Great, Unique',
        annual_dol: 1148.27,
        description:
            '"Easily accessible from all five boroughs, New Jersey & Long Island, The Vanderbilt @ South beach has been designed to offer the latest in technological and comfort amenities within stunning surroundings…. With dramatic ocean views, relaxing natural acoustics, and truly magnificent vista of the Verrazano Narrows Bridge, our facility is the perfect setting for any occasion you are planning! Unlike any other location in the New York City area, Staten Islands only oceanfront ballroom also give you the option to take our pristine elegance and professional white glove service outdoors!"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7952,
        store_longitude: -73.9698,
        total_orders: 292.05,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Mexican, Tacos, Steak, Salad, Beer, Quesadillas, Funky, Warm, Casual, Comfortable',
        annual_dol: 714.32,
        description:
            'Classic Mexican fare with weekday happy hour & taco specials in a bright, cantina-style setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7295,
        store_longitude: -73.8617,
        total_orders: 0,
        neighborhood: 'Rego Park/Flushing',
        keywords: 'Salad, Sushi, Thai, Dessert, Chinese, Korean, Asian, Fusion, Sashimi, Delicious',
        annual_dol: 0,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.7702,
        total_orders: 424.77,
        neighborhood: 'Bayside',
        keywords: 'Burger, Steak, Cajun, Irish, Bourbon, American, Ale, Juicy, Fancy, Traditional',
        annual_dol: 561.63,
        description:
            'American & Irish meals are served in a simple, traditional dining room with a large, separate bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7084,
        store_longitude: -74.0141,
        total_orders: 1147.65,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Pizza, Italian, Chicken Parmigiana, Meatball, Beer, Wine, American, Delicious, Artisanal',
        annual_dol: 1197.4,
        description:
            'Pizzeria & bar specializing in pies & pastas, plus barrel-aged cocktails, wines & beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9693,
        total_orders: 1674.27,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Beer, Wine, Spicy, Irish, Warm, Japanese, Bbq, American, Ramen, Classic',
        annual_dol: 1709.8,
        description:
            'Traditional, wood-lined Irish pub supplying draft beer & classic American comfort food.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7608,
        store_longitude: -73.9684,
        total_orders: 277.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tasty, Vietnamese, Upscale, French, Elegant, Romantic, Legendary, Adventurous, Lively, Sweet',
        annual_dol: 1151.04,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7626,
        store_longitude: -73.982,
        total_orders: 7033.2,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Wine, Beer, Italian, Modern, American, Friendly, Signature, Pure',
        annual_dol: 7267.87,
        description:
            "Rockefeller Center.Refined rooms have flat-screen TVs and Wi-Fi (fee); some offer pull-out sofas. Upgraded rooms add city views, and suites feature separate living areas. Club-level quarters provide access to a lounge serving complimentary continental breakfast and snacks. Room service is available.There's a casual, modern restaurant serving breakfast and lunch, as well as a library bar and a Starbucks cafe. Other amenities include a fitness center and 61,808 sq ft of event space.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9753,
        total_orders: 24.07,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Steak, Hamburger, Shrimp Cocktail, Coffee, Bourbon, Wine, French Toast, Dessert, Avocado Toast',
        annual_dol: 26.2,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9928,
        total_orders: 730.48,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Rum, Fabulous, Eclectic, Perfect, Great, Creative, Friendly, Pure, New Zealand',
        annual_dol: 2985.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7213,
        store_longitude: -73.9934,
        total_orders: 1662.95,
        neighborhood: 'Bowery',
        keywords: 'Chinese, Peking Duck, Congee, Playful, Classic, Dim Sum, Casual',
        annual_dol: 6863.3,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7207,
        store_longitude: -73.9873,
        total_orders: 270.8,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Mexican, Tacos, Margarita, Chinese, Tasty, French Toast, French, Delicious, Triple Sec',
        annual_dol: 1106.93,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7762,
        store_longitude: -73.9815,
        total_orders: 246.56,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Pizza, Vegetarian, Italian, Gluten Free, Rich, Vodka, Beer, Crisp, Rustic',
        annual_dol: 257.25,
        description:
            '"Updated Traditional Pizzeria, offering slices, pies, pastas, salads, desserts and more..."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7763,
        store_longitude: -73.9799,
        total_orders: 174.91,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Sushi, Tasty, Wine, Stylish, Trendy, Japanese, Thai, Comfortable, Asian, Friendly',
        annual_dol: 225.94,
        description:
            'Pan-Asian & Japanese fusion cuisine in a warm, brick-lined space with lunch specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7091,
        store_longitude: -74.0134,
        total_orders: 1203.03,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Delicious, Hotel, Spacious, Amazing, Innovative, Premium, Excellent, Unique',
        annual_dol: 4572.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7612,
        store_longitude: -73.984,
        total_orders: 2140.97,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Salad, Burger, Dessert, Wine, Delicious, Hotel, Fun, Caesar Salad, Caribbean, Club Sandwich',
        annual_dol: 2262.91,
        description:
            'Carolines on Broadway is a venue for stand-up comedy situated at 750 Seventh Avenue, on Broadway between 49th and 50th Streets, in the Midtown Manhattan neighborhood of New York City.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7645,
        store_longitude: -73.9833,
        total_orders: 32.59,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Hotel, Coffee, Sleek, Iconic, Vibrant, Unique',
        annual_dol: 141.89,
        description:
            "Straightforward rooms offer flat-screen TVs, microwaves and free Wi-Fi. Upgraded rooms add minifridges and pull-out sofas. Some suites include terraces with skyline views.There's a sleek bar and a 24/7 convenience store, in addition to a restaurant serving steak and seafood. Other amenities include a 24/7 fitness center, 2 meeting rooms and a business center.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7631,
        store_longitude: -73.9883,
        total_orders: 577.47,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vegan, Vegetarian, Gluten Free, Korean, Casual, Friendly, Asian, Dynamic',
        annual_dol: 1666.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9757,
        total_orders: 696.36,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Tasty, Chinese, Spicy, Delicious, Authentic, Wonton Soup, Classic, Congee, Casual',
        annual_dol: 1892.49,
        description:
            'Easygoing mini-chain stop serving an array of dim sum favorites, along with classic Chinese entrees.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6387,
        store_longitude: -73.5831,
        total_orders: 46.8,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Shrimp Cocktail, Mexican, Dominican, Flavorful, Contemporary, Casual, Friendly, Fresh',
        annual_dol: 191.3,
        description:
            'Contemporary haunt for seafood favorites like ceviche & lobster boils, plus live music & a terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7079,
        store_longitude: -74.001,
        total_orders: 1781.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tacos, Mexican, Beer, Margarita, Delicious, Rum, Tex-Mex, Cozy, Sweet, Fun',
        annual_dol: 1841.05,
        description:
            'Casual restaurant with offbeat nautical decor serving Southwestern faves, some with seafood twists.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5882,
        store_longitude: -73.6635,
        total_orders: 160.39,
        neighborhood: '',
        keywords:
            'Burger, Mexican, Cuban, Dessert, Delicious, Spanish, American, Fresh, Casual, Local',
        annual_dol: 198.38,
        description:
            '"Come taste what you\'ve been missing at the new El Malecon De Cuba Restaurant in Long Beach, NY."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7143,
        store_longitude: -73.9496,
        total_orders: 31.61,
        neighborhood: 'Williamsburg',
        keywords:
            'Gluten Free, Delicious, Italian, Mediterranean, Casual, Fresh, Hummus, American, Middle Eastern, Pita Bread',
        annual_dol: 44.28,
        description:
            'Compact Mediterranean eatery serving shareable plates & brunch in an easygoing setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7418,
        store_longitude: -73.9812,
        total_orders: 224.4,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Salad, Turkish, Vegetarian, Dessert, Wine, Mediterranean, Classy, Hummus, Falafel, Fresh',
        annual_dol: 261.57,
        description:
            'Turkish food (including a bountiful brunch) served in a sleek, crimson-hued room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7587,
        store_longitude: -73.9278,
        total_orders: 177.1,
        neighborhood: 'Astoria/LIC/Flushing',
        keywords: 'Pizza, Italian, Wine, Homemade, Modern, Casual, Local, Recommended, Traditional',
        annual_dol: 723.92,
        description: '"ITALIAN PASTA BAR & PIZZA"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7424,
        store_longitude: -73.9829,
        total_orders: 36.43,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vegetarian, Butter Chicken, Chicken Tikka Masala, Indian, Relaxed, Biryani, British, Creamy, Casual, Authentic',
        annual_dol: 173.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7627,
        store_longitude: -73.9771,
        total_orders: 1902.49,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Italian, Irish, Chinese, Spicy, Karaoke, Eclectic',
        annual_dol: 1987.92,
        description:
            'Easygoing watering hole serving eclectic pub food & a few Irish dishes, plus mainstream beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7665,
        store_longitude: -73.9946,
        total_orders: 89.22,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Delicious, Asian, Traditional, Caribbean, Casual, Nostalgic',
        annual_dol: 128.61,
        description:
            '"There is no other event space like Casa Venue Space, where you’re inspired to think more freely, work more collaboratively, and explore ideas more creatively. Relax and enjoy as Casa Venue becomes your personal private party and corporate event venue. Sync your presentation or favorite films to the largest TVs on the market. Showcase your brand or style on our custom-designed 50-foot display wall. Primed to host your next meeting, product launch or social event- whatever your need, Casa Venue is molded to spark innovation and allow creativity to thrive."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7205,
        store_longitude: -73.9893,
        total_orders: 188.86,
        neighborhood: 'Lower East Side',
        keywords:
            'Garlic Bread, Vegetarian, Pizza, Salad, Italian, Beer, Mimosa, Gin, Bellini, Prosecco',
        annual_dol: 771.98,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.9933,
        total_orders: 144.74,
        neighborhood: 'Bowery',
        keywords:
            'Lobster, Lobster Roll, Burger, Shrimp Cocktail, Salad, Cajun, Tasty, Fried Calamari, Beer, Spicy',
        annual_dol: 186.65,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8665,
        store_longitude: -73.9282,
        total_orders: 617.19,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Steak, Wine, Canadian, Delicious, Spanish, Trendy, French Toast, Dominican, French, Premium',
        annual_dol: 688.22,
        description:
            'Festive restaurant & lounge offering Latin & Dominican cuisine, plus cocktails & sangria.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6388,
        store_longitude: -73.5831,
        total_orders: 426.4,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Champagne, Wine, Beer, Delicious, Elegant, Hurricane, Classy, American',
        annual_dol: 454.75,
        description:
            'Fine-dining spot along the Nautical Mile serving traditional American steakhouse fare with a view.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8571,
        store_longitude: -73.9323,
        total_orders: 736.24,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Burger, Steak, Mexican, Salad, French, American, Latin, Homemade, Casual, Authentic',
        annual_dol: 1903.75,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 291.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Sushi, Hotel, Japanese, Beer, Sashimi, French, Rooftop, High-End, Fusion',
        annual_dol: 407.94,
        description:
            "Loft-style rooms have minifridges, Wi-Fi access and flat-screen TVs with iPod docks. Suites add extra living space, HDTV projectors and surround-sound systems. Some suites have landscaped terraces with hot tubs.Perks include a year-round glass-bottom outdoor pool with a “beach club” and late-night hours, a 24-hour fitness center, hotel bikes and boutique shopping in the lobby. There's also a rooftop lounge and a fusion restaurant serving Japanese and French cuisines.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9553,
        total_orders: 1441.8,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Spanish, Mediterranean, Peruvian, Dominican, Spacious, Whimsical, Memorable, Classic, Welcoming',
        annual_dol: 1569.05,
        description:
            'Eatery in a 19th-century building serving classic Spanish fare & tapas, with a bar & a roomy patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7069,
        store_longitude: -74.0035,
        total_orders: 22650.3,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Tasty, Wine, Salad, Delicious, Mimosa, Trendy, Phenomenal, Spacious, Rum, Innovative',
        annual_dol: 26622.71,
        description:
            "Atera alum's American restaurant with a marketplace, barroom & upstairs dining room & garden.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6402,
        store_longitude: -74.0758,
        total_orders: 122.54,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Garlic Bread, Pizza, Flavorful, Gluten Free, Tasty, Salad, Italian, Meatball, Shrimp Cocktail, Beer',
        annual_dol: 135.64,
        description:
            'Pizza parlor with a patio serving thin-crust pies & other Italian-American eats, plus craft beers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7422,
        store_longitude: -73.9846,
        total_orders: 401.76,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Pizza, Italian, Wine, Tequila, Vodka, Rustic, Hotel, Margarita, Tex-Mex, Cozy',
        annual_dol: 492.14,
        description:
            'This Italian restaurant tucked beneath the Hotel Giraffe offers modern takes on pizza & pasta.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7221,
        store_longitude: -73.959,
        total_orders: 2275.51,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Wine, Coffee, Bbq, Hotel, Israeli, Sustainable, Gracious, Premium, Perfect',
        annual_dol: 2535.52,
        description:
            'Buzzy nightspot featuring rotating DJs, a dance floor & a full bar serving cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6745,
        store_longitude: -73.9502,
        total_orders: 299.99,
        neighborhood: 'Crown Heights',
        keywords: 'Cajun, Salad, Gumbo, Bbq, Lively, Creole, Sweet, Caribbean, Hospitable, Thai',
        annual_dol: 1050.67,
        description:
            '"Nostrand Social is a lively, music-forward Creole restaurant and supper club in Crown Heights, Brooklyn, NY. We embody an ethos centered on movement, growth, and transformation. We feature a wide range of live music from jazz to blues, to R&B, to rock, and to soul. Our Brooklyn events feature living legends, new artists, and members of the community whose musical expression we aim to highlight. On top of that, we present an excellent menu highlighting Creole food and Caribbean classics, from gumbo to rasta pasta, with a new twist. We also serve bottomless brunch in Brooklyn, senior luncheons and often feature stand-up comedy that connects our Crown Heights community."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7522,
        store_longitude: -73.9918,
        total_orders: 21.02,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Gourmet, Wine, Beer, Warm, Exquisite, Memorable',
        annual_dol: 85.92,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6759,
        store_longitude: -73.816,
        total_orders: 26.71,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Burger, Vegetarian, Wine, French, American, Local, Casual, Awesome, Colorful, Great',
        annual_dol: 65.55,
        description:
            'Colorful lights, DJ sounds & drinks set the tone for this energetic haunt with drink specials.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7448,
        store_longitude: -73.9974,
        total_orders: 976.83,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Milkshake, Mexican, Gourmet, Hotel, American, Stylish, Latin, Clean, Great',
        annual_dol: 2659.54,
        description:
            'Movie theater chain, offering state-of-the-art sound & projection technology.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7461,
        store_longitude: -74.0014,
        total_orders: 1125.68,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Steak, Pizza, Shrimp Cocktail, Wine, Breakfast Burrito, Italian, Green Curry, Warm, Thai',
        annual_dol: 1174.48,
        description: 'Pub offering bar food (with lunch specials), sports on TV & happy hours.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7401,
        store_longitude: -74.0015,
        total_orders: 140.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Whiskey, Beer, Wine, Gin, Irish, Classy, Great, Local, Laid-Back, Casual',
        annual_dol: 574.73,
        description:
            '"Gadfly Bar is a laid back local spot to grab a drink and a bite with friends. Solid beer and whiskey list. Happy hour 3-6 PM weekdays. Great vibes every day."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7439,
        store_longitude: -73.9928,
        total_orders: 726.31,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Hotel, Italian, Coffee, Urban, Polished, Modern, Perfect, Great, Cool',
        annual_dol: 2736.52,
        description:
            'Market.The modern rooms, some featuring wall beds, have Wi-Fi, smart TVs and minifridges. Many offer city views; some include bunk beds. Room service is available.Amenities consist of a polished Italian bar/restaurant, a coffee bar and a gym.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6812,
        store_longitude: -73.9774,
        total_orders: 1639.01,
        neighborhood: 'Park Slope',
        keywords:
            'Whiskey, Beer, Wine, Bourbon, Canadian, Rich, Vintage, Irish, Picturesque, Classy',
        annual_dol: 2231.21,
        description:
            'Neighborhood taproom with a large, varied craft-beer selection, plus vintage games & trivia.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6227,
        store_longitude: -73.7226,
        total_orders: 118.84,
        neighborhood: '',
        keywords:
            'Steak, Pizza, Gourmet, Turkey Sandwich, Gluten Free, Roast Beef, Bbq, American, Delicious, Ample',
        annual_dol: 129.51,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7075,
        store_longitude: -74.0019,
        total_orders: 405.97,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Wine, Salad, Italian, Mexican, Delicious, Ale, French, Southern, Asian, Classic',
        annual_dol: 828.23,
        description: '"Upscale Mexican Cuisine"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7265,
        store_longitude: -73.9859,
        total_orders: 1860.65,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Warm, Comfortable, Cool, American, Casual, Amazing, Great, Fantastic',
        annual_dol: 1984.34,
        description:
            'Small, spare East barroom drawing locals who like their drinking with no pretensions.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8148,
        store_longitude: -73.9034,
        total_orders: 47.88,
        neighborhood: 'Woodstock/West Bronx',
        keywords:
            'Steak, Mexican, Shrimp Cocktail, Margarita, Delicious, Spanish, American, Cozy, Latin, Relaxed',
        annual_dol: 75.75,
        description:
            'This small, relaxed eatery features a menu of Mexican & Central American dishes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6795,
        store_longitude: -73.968,
        total_orders: 326.12,
        neighborhood: 'Prospect Heights',
        keywords: 'Korean, Beer, Organic, Trendy, Contemporary, Modern, Bbq, Casual, Classy, Yummy',
        annual_dol: 409.92,
        description:
            'Restaurant for contemporary Korean dishes made with organic ingredients, plus cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7227,
        store_longitude: -73.9862,
        total_orders: 2175.81,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Korean, Modern, Seasonal, Casual, Cool, Fun, Lively, Edgy, Creative',
        annual_dol: 2270.14,
        description:
            'Beer, wine & inventive cocktails fixed up in a buzzy bar with live DJs & an edgy vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.604,
        store_longitude: -74.1623,
        total_orders: 33093.33,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Steak, Burger, Cheesecake, Salad, Vegetarian, Pizza, Wine, Italian, Delicious, Beer',
        annual_dol: 135274.63,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.688,
        store_longitude: -73.9625,
        total_orders: 355.45,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Upscale, Stylish, Picturesque, Thai, American, Rum, Awesome, Southern, Classic',
        annual_dol: 372.28,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7586,
        store_longitude: -73.9245,
        total_orders: 1273.22,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Lobster, Salad, Wine, Beer, Warm, Mediterranean, Sweet, Creole, Vintage, Intimate',
        annual_dol: 1387.61,
        description:
            'Warm eatery & bar featuring craft cocktails & raw oysters in comfy digs with a vintage vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7607,
        store_longitude: -73.9229,
        total_orders: 642.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Vegan, Steak, Vegetarian, Vodka, Chicken Pot Pie, Beer, Champagne, Delicious, Trendy',
        annual_dol: 670.49,
        description:
            'Hip bridge-&-tunnel-themed restaurant serving American fare, brunch, craft beers & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9549,
        total_orders: 1473.79,
        neighborhood: 'Greenpoint',
        keywords: 'Vegan, Beer, Salad, German, Rooftop, Belgian, Fun, Awesome, Amazing, Refreshing',
        annual_dol: 1678.54,
        description:
            'A European beer list & an expansive rooftop are the draws at this German-accented barroom.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.746,
        store_longitude: -73.9528,
        total_orders: 665.51,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Italian, Spicy, Organic, Warm, Homemade, Friendly, Artisanal, Sweet, Authentic',
        annual_dol: 759.45,
        description:
            'Old-world Italian cafe with a warm vibe offering homemade pasta dishes, pizzas & house-made gelato.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8497,
        store_longitude: -73.7887,
        total_orders: 185.02,
        neighborhood: 'City Island',
        keywords: 'Homemade, Cozy, Eclectic, Local, Great, Unique',
        annual_dol: 210.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6586,
        store_longitude: -73.7812,
        total_orders: 1026.27,
        neighborhood: 'Jamaica',
        keywords: 'American, Hotel, Premium, Great',
        annual_dol: 1436.4,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8008,
        store_longitude: -73.9653,
        total_orders: 2486.04,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Burger, Salad, Vegetarian, Wine, Beer, Breakfast Burrito, Delicious, Hotel, Irish, Caesar Salad',
        annual_dol: 2833.33,
        description:
            'A playful craft cocktail list is paired with elevated, global snacks & mains in a casual setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5886,
        store_longitude: -74.0901,
        total_orders: 33.43,
        neighborhood: 'Dongan Hills',
        keywords: 'Salad, Italian, Excellent, Casual',
        annual_dol: 136.64,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -73.9848,
        total_orders: 1831.99,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Vegan, Hamburger, Mexican, Beer, Gluten Free, Margarita, Wine, Delicious, Thai',
        annual_dol: 1927.25,
        description:
            'Hot spot for craft cocktails, modern Mexican eats & live music in an industrial-chic setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7271,
        store_longitude: -73.9881,
        total_orders: 2923.65,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Flavorful, Salad, Vegan, Coffee, Funky, Mexican, Fun, Spectacular, Cool, Great',
        annual_dol: 3141.48,
        description:
            "Inspired by Tim Burton's movies, this whimsical, goth hangout serves American fare & cocktails.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7275,
        store_longitude: -73.9866,
        total_orders: 1060.44,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vodka, Beer, Relaxed, Wonderful, Friendly, Great, Intimate, Vintage, Iconic, Creative',
        annual_dol: 1128.51,
        description:
            'Intimate bar & gathering place featuring a red lighting scheme & a relaxed atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5438,
        store_longitude: -74.2143,
        total_orders: 107.45,
        neighborhood: 'Rossville',
        keywords: 'Burger, Steak, Pizza, Salad, Bbq, Beer, Juicy, Tex-Mex, Irish, American',
        annual_dol: 439.24,
        description:
            '"Open for Business spring of 2021, Ultimate destination for family fun and great BBQ on Staten Island! party room, outdoor dining, With over 100 craft beers a crazy happy hour DaddyO’s BBQ will be an exciting, fun and festive place to be and watch every ufc, nfl ,nhl and every other sporting event. #bbq #restaurant #nyc #statenisland #nearme #food #bar #sportsbar #nj #familystyle #foodnearme #happyhour #lunch #sportsnearme"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8282,
        store_longitude: -73.85,
        total_orders: 35.24,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Spacious, Warm, Delicious, Healthy, Fresh, Great, Swiss, Intimate, Latin, Casual',
        annual_dol: 144.05,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7577,
        store_longitude: -73.978,
        total_orders: 394.86,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Burger, Steak, Cheesecake, Cajun, Whiskey, Beer, Italian, Japanese, Wine, Dessert',
        annual_dol: 1882.26,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.8314,
        total_orders: 66.23,
        neighborhood: 'Flushing',
        keywords:
            'Salad, Pizza, Vegetarian, Italian, Gluten Free, Wine, Hotel, Friendly, Casual, Welcoming',
        annual_dol: 115.7,
        description:
            'Welcoming Italian restaurant & bar offering breakfast, lunch & dinner in a contemporary setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7991,
        store_longitude: -73.6501,
        total_orders: 867.97,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Wine, Beer, Polished, Trendy, Caesar Salad',
        annual_dol: 893.13,
        description:
            'Family-run landmark has been serving seafood & steak in a traditional setting for over 55 years.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7569,
        store_longitude: -73.9719,
        total_orders: 1250.05,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Stylish, Iconic, Friendly, Recommended',
        annual_dol: 4733.68,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6233,
        store_longitude: -74.0313,
        total_orders: 1033.97,
        neighborhood: 'Fort Hamilton',
        keywords: 'Burger, Cornbread, Beer, Italian, Buffalo Wings, Tasty, Bbq, Korean, Ale, Tangy',
        annual_dol: 1078.8,
        description:
            'Fans of Buffalo wings and Rangers hockey congregate at this all-American sports bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7633,
        store_longitude: -73.9685,
        total_orders: 6347.28,
        neighborhood: 'Lenox Hill',
        keywords:
            'Vegetarian, Whiskey, Beer, Coffee, Wine, Tomato Soup, Gluten Free, American, Stylish, Martini',
        annual_dol: 6631.26,
        description:
            'Stylish, sophisticated bistro with imaginative New American plates, craft beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6758,
        store_longitude: -73.9414,
        total_orders: 177.04,
        neighborhood: 'Crown Heights',
        keywords:
            'Hamburger, Dessert, Upscale, Delicious, Bourbon, Polished, Relaxed, Welcoming, Outstanding, Fresh',
        annual_dol: 540.18,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6755,
        store_longitude: -73.9501,
        total_orders: 2177.86,
        neighborhood: 'Crown Heights',
        keywords: 'Pizza, Beer, Wine, Ale, Belgian, Spacious, Comfortable, Relaxed, Fun, Sweet',
        annual_dol: 2224.07,
        description:
            'Convivial neighborhood hangout featuring imported & craft beers on tap & an airy backyard.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7392,
        store_longitude: -73.9865,
        total_orders: 1483.3,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Lobster, Wine, Upscale, Italian, Elegant, Warm, Sparkling, Classy, Innovative, Friendly',
        annual_dol: 1652.79,
        description:
            'Casual Northern Italian restaurant in a cozy, warm space with sidewalk seating available.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7639,
        store_longitude: -73.9973,
        total_orders: 1378.31,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Chicken Lollipop, Salad, Rooftop, French, Homemade, Fantastic, Incredible, Hip, Unforgettable, Casual',
        annual_dol: 1585.53,
        description:
            "Two-level, DJ'ed club featuring plush interiors, a garden & a year-round roof deck with river views.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.762,
        store_longitude: -73.9935,
        total_orders: 184.54,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Gluten Free, Korean, Kebab, Wine, Pizza, Delicious, Spicy, Elegant, Warm, Martini',
        annual_dol: 452.85,
        description:
            'Seasonal ingredients shine on a multicourse Korean tasting menu served in a sleek space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7236,
        store_longitude: -73.9968,
        total_orders: 246.08,
        neighborhood: 'Lower Manhattan',
        keywords: 'Burger, Steak, Hamburger, Vegan, Tomato Soup, Salad, Beer, Gourmet, Tasty, Wine',
        annual_dol: 267.8,
        description:
            'Counter service fare like burgers, fries & beer served in a bright space with an outdoor patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.747,
        store_longitude: -73.9562,
        total_orders: 398.3,
        neighborhood: 'LIC/Hunters Point',
        keywords:
            'Burger, Coffee, Vegan, Carrot Cake, Wine, Beer, Meatball, Trendy, French, Delicious',
        annual_dol: 406.75,
        description:
            'Coffee & espresso drinks for connoisseurs at a casual cafe with a trendy atmosphere.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7272,
        store_longitude: -74.0026,
        total_orders: 1214.97,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Kebab, Beer, Wine, Mediterranean, Tunisian, French, Stylish, Shawarma, Cozy',
        annual_dol: 1295.73,
        description:
            'Place for contemporary Eastern Mediterranean food, wine & cocktails in a stylish setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -73.9929,
        total_orders: 5994.96,
        neighborhood: 'NoHo',
        keywords: 'Thai, Wine, Tasty, Salad, Dessert, Beer, Polished, Asian, Colorful, Vibrant',
        annual_dol: 6286.86,
        description:
            'Eatery by chef-brothers from Thailand for shareable Thai seafood plates with cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9953,
        total_orders: 1666.6,
        neighborhood: 'Nolita',
        keywords:
            'Pizza, Italian, Gluten Free, Beer, Wine, Spicy, Rustic, Homemade, Sweet, Friendly',
        annual_dol: 1701.96,
        description:
            'Wood-fired pizzas & homemade pastas highlight this rustic Italian with a skylight in the back room.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7265,
        store_longitude: -73.9935,
        total_orders: 2100.42,
        neighborhood: 'NoHo',
        keywords:
            'Coffee, Wine, Avocado Toast, Trendy, Rustic, Savory, Cozy, Mediterranean, Seasonal, Picturesque',
        annual_dol: 2513.1,
        description:
            'This laid-back cafe serves Mediterranean & American fare from a rustic basement locale.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.721,
        store_longitude: -73.9946,
        total_orders: 82.49,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Tasty, Gluten Free, Salad, Italian, Delicious, Spicy, Quaint, Cozy, Japanese',
        annual_dol: 91.91,
        description:
            'Cozy bar/eatery with a pink motif inspired by 1980s design, serving Italian fare & weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7261,
        store_longitude: -73.9954,
        total_orders: 437.87,
        neighborhood: 'NoHo',
        keywords: 'Spacious, Casual',
        annual_dol: 1679.52,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7473,
        store_longitude: -73.9862,
        total_orders: 2492.54,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Korean, Trendy, Modern, Amazing, Perfect, Lit, Unique, Cool, Fine',
        annual_dol: 2545.43,
        description:
            'Snacks & cocktails in a sleek, colorfully lit bar with 10 private rooms for group karaoke.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7451,
        store_longitude: -73.9897,
        total_orders: 431.04,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Salad, Steak, Tasty, Wine, Fruity, Italian, Dessert, Savory, Warm, Cozy',
        annual_dol: 459.36,
        description:
            'Classy-casual Italian osteria centering around its namesake mushroom, plus pastas, meats & wine.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.5901,
        store_longitude: -74.0666,
        total_orders: 674.94,
        neighborhood: 'Arrochar/South Beach',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Italian, French, Upscale, Rustic, American, Stylish, Spacious',
        annual_dol: 942.29,
        description:
            'Spacious upscale South Beach steak & seafood (with raw bar, sushi) destination offers ocean views.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8026,
        store_longitude: -73.968,
        total_orders: 97.82,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Sushi, Mexican, Salad, Beer, Japanese, Thai, Asian, Sashimi, American, Casual',
        annual_dol: 399.86,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7259,
        store_longitude: -73.9898,
        total_orders: 1748.62,
        neighborhood: 'East Village/Bowery',
        keywords: 'Whiskey, Beer, Wine, Vodka, Margarita, Martini, Ale, Gin, Vegetarian, Irish',
        annual_dol: 1785.72,
        description:
            'Draft beer, cocktails & American bar plates in a laid-back pub setting with rustic decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7319,
        store_longitude: -73.9855,
        total_orders: 1232.12,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Margarita, Vegetarian, Martini, Spicy, Salad, Ale, Gin, Spanish, Cozy',
        annual_dol: 2181.23,
        description:
            'Easygoing pub serving craft beers & cocktails, plus sharing plates, mains & bar bites.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7336,
        store_longitude: -73.9882,
        total_orders: 231.69,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Steak, Salad, Mexican, Gluten Free, Gourmet, Tasty, Beer, Spicy',
        annual_dol: 334,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7567,
        store_longitude: -73.9139,
        total_orders: 39.21,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Burger, Shrimp Cocktail, Wine, Trendy, Thai, Romantic, Cozy, Colombian, Latin',
        annual_dol: 113.17,
        description:
            'Latin American fare served in a vibrant restaurant with a bar, a lounge & a tropical-style terrace.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7234,
        store_longitude: -73.8688,
        total_orders: 10.73,
        neighborhood: 'Middle Village/Flushing/Rego Park',
        keywords:
            'Burger, Tacos, Steak, Salad, Mexican, Nachos, Margarita, Fajitas, Spanish, Latin',
        annual_dol: 43.84,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8173,
        store_longitude: -73.942,
        total_orders: 1156.98,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Beer, Wine, Whiskey, Rustic, Delicious, Mimosa, Casual, Dope, Cool, Creative',
        annual_dol: 1348.6,
        description:
            'Rustic bar & music venue with charming, old-timey decor, craft cocktails & creative snacks.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7062,
        store_longitude: -73.8315,
        total_orders: 3070.49,
        neighborhood: 'Kew Gardens/Jamaica',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Beer, Bbq, Swiss, Ale, Caesar Salad, American, Stylish',
        annual_dol: 3070.49,
        description:
            'Stylish bar & grill offering multiple screens for game-watching, plus a Mexican-American food menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7545,
        store_longitude: -73.9686,
        total_orders: 1519.18,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Steak, Crab Cakes, Salad, Beer, Vodka, Wine, Spicy, Irish, American',
        annual_dol: 1970.98,
        description:
            'Two-level restaurant & bar with projection screens, traditional Irish breakfast & fixed-price menu.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8413,
        store_longitude: -73.9172,
        total_orders: 566.28,
        neighborhood: 'Mount Eden/West Bronx',
        keywords: 'Tacos, Mexican, Tequila, Sushi, Margarita, Latin, Premium, Lively, Casual, Cool',
        annual_dol: 586.97,
        description:
            "Buzzing nightspot & bar with a variety of live & DJ'd Latin beats in a no-frills setting.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7571,
        store_longitude: -73.9683,
        total_orders: 953.17,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tasty, Dessert, Japanese, Spicy, Ramen, Delicious, Stylish, Ale, Asian, Katsu Curry',
        annual_dol: 1037.3,
        description:
            'Snug, stylish eatery specializing in its namesake noodle soup, plus other Japanese staples.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6775,
        store_longitude: -73.9357,
        total_orders: 228.29,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Salad, Nachos, Delicious, Southern, Smooth, Casual, Sweet, Premium, Local',
        annual_dol: 305.39,
        description:
            'This neighborhood hangout features a menu of Southern entrees, plus cocktails & live entertainment.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7088,
        store_longitude: -74.0111,
        total_orders: 1396.81,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Wine, Gluten Free, Champagne, Beer, Irish, Prosecco, Caesar Salad, American',
        annual_dol: 1426.45,
        description:
            'American eatery set in a converted bank vault with original vault doors dating to 1904.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7441,
        store_longitude: -73.99,
        total_orders: 2575.53,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Gin, Beer, Glamorous, Spacious, Healthy, Premium, Innovative, Seasonal, Classic, Sumptuous',
        annual_dol: 2824.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6992,
        store_longitude: -73.9924,
        total_orders: 1590.11,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Burger, Beer, Cajun, Cajun-Style, Ale, Wine, American, Homemade, Casual, Local',
        annual_dol: 1632.32,
        description:
            'Wash down comforting pub grub like pot pies & burgers with micro brews in a casual setting.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6991,
        store_longitude: -73.9924,
        total_orders: 1611.09,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Pizza, Italian, Wine, Tasty, Dessert, Upscale, Stylish, Delicious, Beer, Elegant',
        annual_dol: 1697.92,
        description:
            'This neighborhood eatery offers seasonal Italian fare & craft cocktails in stylish bistro environs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6905,
        store_longitude: -73.9948,
        total_orders: 315.38,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Pizza, Salad, Italian, Beer, Dessert, Wine, Spicy, Organic, Artisanal, Relaxed',
        annual_dol: 336.11,
        description:
            'Elevated thin-crust pies served in casual, brick-lined digs with a full bar & sidewalk seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.759,
        store_longitude: -73.9856,
        total_orders: 693.32,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Italian, Hotel, Dutch',
        annual_dol: 963.96,
        description:
            'The Lunt-Fontanne Theatre, originally the Globe Theatre, is a Broadway theater at 205 West 46th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1910, the Lunt-Fontanne Theatre was designed by Carrère and Hastings in the Beaux-Arts style for Charles Dillingham.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8766,
        store_longitude: -73.8464,
        total_orders: 114.33,
        neighborhood: 'East Bronx/Laconia',
        keywords:
            'Steak, Vegetarian, Meatball, Italian, Spanish, Hotel, Southern, Casual, Tradition, Caribbean',
        annual_dol: 310.72,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7618,
        store_longitude: -73.9937,
        total_orders: 31.12,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Salad, Beer, Spicy, Delicious, Ethiopian, Warm, Cozy, African, Casual',
        annual_dol: 58.7,
        description:
            'Spicy stews made from family recipes & eaten by hand with flat bread are served amid African decor.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.771,
        store_longitude: -73.9833,
        total_orders: 466.12,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Tequila, Tacos, Mexican, Steak, Flavorful, Tasty, Beer, Margarita, Wine, Stylish',
        annual_dol: 1399.71,
        description:
            'Modern Mexican chain eatery known for tableside guacamole, creative margaritas & chic surrounds.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7282,
        store_longitude: -74.0007,
        total_orders: 229.28,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Rich, French, Romantic, Martini, Elegant, Cozy, Classy, Quaint, American',
        annual_dol: 234.15,
        description:
            'Elaborate French classics are served in this chic, contemporary space with banquette & bar seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7297,
        store_longitude: -74.0005,
        total_orders: 16813.59,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Scotch, Bourbon, Margarita, Tasty, Perfect, Cool, Casual, Sophisticated, Wonderful, Creative',
        annual_dol: 22943.84,
        description:
            'Dimly lit cocktail lounge offering sophisticated drinks plus snacks in a space with banquettes.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8068,
        store_longitude: -73.9538,
        total_orders: 887.29,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Wine, Vodka, Delicious, Caesar Salad, Southern, Sweet, Seasonal, Casual, Omelette',
        annual_dol: 2043.32,
        description:
            'Casual eatery serving an American comfort food menu in a modern setting with an easygoing vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7704,
        store_longitude: -73.7929,
        total_orders: 135.32,
        neighborhood: 'Bayside/Flushing/Auburndale',
        keywords: 'Spanish, Cool, Authentic, Awesome, Local, American, Casual, Great, Excellent',
        annual_dol: 157.85,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7288,
        store_longitude: -74.0007,
        total_orders: 124.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Delicious, Ale, Italian, Chinese, Whimsical, Healthy, Fun, Seasonal, Lively',
        annual_dol: 507.36,
        description:
            '"A fun spot full of whimsy serving classic home cooked dishes. Sister Restaurant to Nat\'s on Bank."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7666,
        store_longitude: -73.9821,
        total_orders: 643.95,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster Roll, Lobster, Coffee, American, Sleek, Innovative, Local, Recommended, Excellent, Great',
        annual_dol: 671.87,
        description:
            '"The health and safety of our employees and community is our priority. When visiting our restaurant you will see increased sanitation, face coverings and gloves for all employees, traffic flow management and social distancing measures. We are working hard to keep our communities safe and can\'t wait to serve you. In compliance with local mandate, proof of vaccination is required prior to dining indoors at this location."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7643,
        store_longitude: -73.9797,
        total_orders: 173.53,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Vodka, Wine, Coffee, Martini, Greek, Spicy, American, Greek Salad',
        annual_dol: 177.21,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7187,
        store_longitude: -73.987,
        total_orders: 4930.2,
        neighborhood: 'Lower East Side',
        keywords: 'Cozy, Friendly, Beer, Hotel, Sweet, Classy, Fun, Perfect, Casual, Awesome',
        annual_dol: 5062.07,
        description:
            "'50s pinup model Bettie Page-themed bar with specialty cocktails & weekly burlesque shows.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7216,
        store_longitude: -73.9882,
        total_orders: 5964.08,
        neighborhood: 'Lower East Side',
        keywords: 'Burger, Steak, Salad, Vodka, Wine, Beer, Coffee, Mimosa, Delicious, Trendy',
        annual_dol: 6824.62,
        description:
            'Cocktails, beer & DJs are the draw at this casual, narrow, art-filled space with mezzanine lounge.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7198,
        store_longitude: -73.9849,
        total_orders: 324.86,
        neighborhood: 'Lower East Side',
        keywords: 'Wine, Cozy, Beer, Spacious, Fun, Natural, Perfect, Creative, Great, Incredible',
        annual_dol: 1223.97,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7189,
        store_longitude: -73.9884,
        total_orders: 316.19,
        neighborhood: 'Lower East Side',
        keywords: 'Pizza, Beer, Karaoke, Wine, Asian, Fun, Seasonal, Sweet, Perfect, Casual',
        annual_dol: 1292.48,
        description:
            'Energetic tavern offering pool tables, ping pong & live comedy, plus charcuterie plates & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7617,
        store_longitude: -73.9667,
        total_orders: 71.34,
        neighborhood: 'Lenox Hill',
        keywords:
            'Burger, Sushi, Pizza, Coffee, Beer, Japanese, Sashimi, Contemporary, Casual, Vintage',
        annual_dol: 291.63,
        description:
            'Restaurant featuring a menu of contemporary Japanese entrees, noodle dishes, soups & sushi.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7758,
        store_longitude: -73.9103,
        total_orders: 57.28,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Cajun, Burger, Mexican, Beer, French, Southern, Wine, Creole, Screwdriver',
        annual_dol: 117.99,
        description:
            'Down-to-earth eatery with rustic-chic decor serving updated Southern classics in a mellow space.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7433,
        store_longitude: -73.9997,
        total_orders: 356.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Pizza, Italian, Gluten Free, Wine, Vegetarian, Vegan, Vodka, Meatball, Mediterranean',
        annual_dol: 435.51,
        description:
            'Lasagna specialist serving meat, seafood & vegetarian versions plus other Italian standards.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7801,
        store_longitude: -73.8022,
        total_orders: 276.41,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords: 'Hotel, American, Friendly, Casual, Lively, Hip, Great',
        annual_dol: 282.27,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7468,
        store_longitude: -74.0083,
        total_orders: 337.69,
        neighborhood: '',
        keywords:
            'Champagne, Wine, Beer, Romantic, Elegant, Tasty, American, Lively, Classic, Iconic',
        annual_dol: 1380.35,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6869,
        store_longitude: -73.9786,
        total_orders: 166.56,
        neighborhood: 'Fort Greene',
        keywords: 'Wine, Beer, Coffee, Gin, Bourbon, American, Asian, Paloma, Chic, Natural',
        annual_dol: 232.69,
        description:
            'The Center for Fiction, originally called the New York Mercantile Library, is a not-for-profit organization in New York City, with offices at 15 Lafayette Avenue in Fort Greene, Brooklyn.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6776,
        store_longitude: -73.9689,
        total_orders: 3774.3,
        neighborhood: 'Prospect Heights',
        keywords:
            'Burger, Wine, Japanese, Martini, Margarita, French, Trendy, Korean, Delicious, Asian',
        annual_dol: 4293.7,
        description:
            'Classical French cooking meets Japanese flavors at this relaxed yakitori spot with a backyard patio.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.612,
        store_longitude: -74.1325,
        total_orders: 799.41,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Mexican, Margarita, Wine, Fajitas, Spicy, Casual, Delicious, Authentic, Sangria',
        annual_dol: 871.23,
        description:
            'Cheerful cantina offering Mexican dishes such as fajitas & tamales, plus covered outdoor seating.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7188,
        store_longitude: -73.9544,
        total_orders: 1344.06,
        neighborhood: 'Williamsburg',
        keywords:
            'Sushi, Vegan, Gluten Free, Japanese, French, Trendy, Spicy, Delicious, Sashimi, Glamorous',
        annual_dol: 1433.81,
        description:
            'Buzzing eatery & lounge offering Japanese dishes with a French twist, plus cocktails & beer.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7144,
        store_longitude: -73.9616,
        total_orders: 1321.14,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Burger, Coffee, Mimosa, Martini, Wine, Australian, American, French, Classic, Iconic',
        annual_dol: 1349.17,
        description:
            '"Serves specialty coffee, Aussie brunch favorites and a seasonally charged dinner menu alongside an extensive wine and seasonal cocktail list"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7231,
        store_longitude: -73.956,
        total_orders: 1004.16,
        neighborhood: 'Greenpoint',
        keywords:
            'Thai, Upscale, Phenomenal, French, Sophisticated, Traditional, Playful, Vibrant, Authentic, Fresh',
        annual_dol: 1047.7,
        description: '"A sophisticated Thai restaurant serving historic dishes inspired."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.745,
        store_longitude: -73.9838,
        total_orders: 2176.31,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Burger, Beer, Cajun, Bourbon, Mimosa, Bbq, Wine, American, Warm, Homemade',
        annual_dol: 2235.26,
        description:
            'Barbecue, beer & bourbon feature at this spot with a sports bar-like complement of widescreen TVs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7226,
        store_longitude: -73.9549,
        total_orders: 834.94,
        neighborhood: 'Greenpoint',
        keywords: 'Wine, Golfclub, Beer, Thai, Casual, Fine',
        annual_dol: 3412.94,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6798,
        store_longitude: -73.9895,
        total_orders: 5616.56,
        neighborhood: 'Gowanus',
        keywords:
            'Burger, Meatball Sub, Italian, Meatball, Beer, Bbq, Wine, Coleslaw, Seasonal, Friendly',
        annual_dol: 6396.88,
        description:
            'Laid-back outdoor bar & eatery offering trays of pit-smoked BBQ, plus draft beer & cocktails.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6814,
        store_longitude: -73.9116,
        total_orders: 563.87,
        neighborhood: 'Bedford-Stuyvesant/Ocean Hill',
        keywords: 'Japanese, Casual, Fun, Retro, Rum, Great',
        annual_dol: 1314.51,
        description:
            '"Roller disco inspired bar, restaurant, arcade and venue. THEMED BAR :: no skating rink or skate rentals. Food is available through Mr. Taco which is on site; please visit their page for hours and information."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.676,
        store_longitude: -73.9991,
        total_orders: 793.04,
        neighborhood: 'Carroll Gardens',
        keywords: 'Lasagna, Burger, Steak, Salad, Beer, Vodka, Gin, Fried Chicken, Spicy, French',
        annual_dol: 809.86,
        description:
            'Quaint eatery offering a hearty American comfort-food menu & a popular weekend brunch.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7372,
        store_longitude: -73.9921,
        total_orders: 106.91,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Fun, Amazing, Great',
        annual_dol: 168.08,
        description:
            '"The only Group Fitness Studio with a Full Liquor Bar. The Newest Craze from Europe now in Union Square."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7276,
        store_longitude: -73.9857,
        total_orders: 551.3,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Whiskey, Scotch, Wine, Beer, Irish, Funky, Comfortable, Intimate, Traditional, Hip',
        annual_dol: 589.08,
        description:
            'A former speakeasy during Prohibition, this funky bar is now an absinthe specialist.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7332,
        store_longitude: -73.9936,
        total_orders: 2300.61,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Burger, Hamburger, Salad, Beer, Wine, Ale, Crisp, Relaxed, Friendly, Classy',
        annual_dol: 2354.74,
        description:
            'Reasonably priced pub grub plus a pool table & TV sports keep regulars happy at this relaxed bar.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8457,
        store_longitude: -73.9362,
        total_orders: 782.5,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Mexican, Wine, Jamaican, Spanish, American, Mediterranean, Sweet, Latin, Greek, Lively',
        annual_dol: 1362.86,
        description:
            'Classic Mediterranean dishes & drinks, focused on on seafood in a lively space with an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7979,
        store_longitude: -73.9691,
        total_orders: 1392.33,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Tequila, Beer, Pizza, Wine, Ale, Gourmet, American, Cozy, Iconic, Artisanal',
        annual_dol: 1500.44,
        description:
            'Unpretentious hangout focused on bourbon, tequila & scotch, plus beer to take out in growlers.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.753,
        store_longitude: -73.981,
        total_orders: 2759.18,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Wine, Mexican, Delicious, Hotel, Spacious, French Toast, Clean, Friendly, French, Lively',
        annual_dol: 2893.52,
        description:
            "Offering free minibars and Wi-Fi, the streamlined rooms have floor-to-ceiling windows, and marble bathrooms with rainfall showers, plus 42-inch flat-screen TVs and iPod docks. Suites add kitchenettes with coffeemakers and microwaves; some have private balconies. In-room spa services are available (fee).The hotel hosts free wine hours. There's a cellar bar offering shared plates and hand-crafted cocktails, along with a lively American restaurant serving locally sourced dishes. There's also a gym.",
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.68,
        store_longitude: -73.8407,
        total_orders: 1203.71,
        neighborhood: 'Ozone Park',
        keywords:
            'Vegetarian, Burger, Pizza, French, Korean, American, Premium, Modern, Perfect, Classic',
        annual_dol: 1280.98,
        description:
            '"Newly Renovated Modern Bowling Center,Glow Bowling,Restaurant,Bar,Lounge,Billiards,Pool,Arcade,Adult/Kids Birthday Parties.Corporate Events,Team Building,Fundraiser,Camp/School trips,Daily Specials,Live D.J., Wait Service,Parking,64 Lanes,Big Screen Projectors"',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7572,
        store_longitude: -73.9679,
        total_orders: 9057.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Salad, Japanese, Wine, Cozy, Legendary, American, Rare, Casual, Perfect, Great',
        annual_dol: 9659.16,
        description:
            'Low-key bar & grill featuring Japanese bites & live jazz in a cozy setting with a speakeasy vibe.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7559,
        store_longitude: -73.9655,
        total_orders: 1990.38,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Salad, Wine, Avocado Toast, Irish, Ale, Gin, Organic, American, Warm',
        annual_dol: 2055.71,
        description:
            'Long-standing Irish pub serving typical grub in a warm, homey atmosphere lit by Tiffany-style lamps.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7551,
        store_longitude: -73.9682,
        total_orders: 1660.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Salad, Italian, Wine, Meatball, French Toast, Chinese, Organic, Stylish, Delicious',
        annual_dol: 1660.24,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7599,
        store_longitude: -73.9913,
        total_orders: 1271.14,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Pizza, Gluten Free, Flavorful, Italian, Healthful, Green Curry, Apple Pie, Rustic, Delicious, Comfortable',
        annual_dol: 1400.24,
        description:
            'Comfortable restaurant highlighting classic Italian cuisine, including pizza, pasta & soup.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7026,
        store_longitude: -73.9874,
        total_orders: 5594.81,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Salad, Wine, Organic, Italian, Breakfast Burrito, Sustainable, Funky, Cozy, Mediterranean, Friendly',
        annual_dol: 5713.52,
        description:
            'Local, seasonal Mediterranean fare is offered in a converted warehouse space with an open kitchen.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7555,
        store_longitude: -73.9814,
        total_orders: 4851.51,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Rooftop, Creative, Unique',
        annual_dol: 4954.45,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7593,
        store_longitude: -73.9865,
        total_orders: 2604.24,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Wine, Beer, Hotel, Delicious, Fun, Eclectic, Classy, Caribbean, Iconic, Great',
        annual_dol: 2672.65,
        description:
            'Sony Corporation venue for diverse live music shows featuring dining, a full bar & high-tech sound.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7604,
        store_longitude: -73.9897,
        total_orders: 6016.6,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Tasty, Pizza, Wine, Delicious, Japanese, Trendy, Cozy, Asian, Friendly',
        annual_dol: 8593.49,
        description:
            'Cozy, brick-walled Japanese eatery & sake bar offering yakitori, noodles & set-price party menus.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.6639,
        store_longitude: -73.6982,
        total_orders: 69.54,
        neighborhood: '',
        keywords: 'Burger, Cajun, Cajun-Style, Awesome, Casual',
        annual_dol: 284.24,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7458,
        store_longitude: -73.9923,
        total_orders: 2089.06,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Trendy, Spacious, Sleek, Modern, Polished, Italian, American, Sophisticated, Comfortable',
        annual_dol: 2095.91,
        description:
            'The sleek, modern rooms feature free Wi-Fi and minibars, as well as flat-screen TVs, Nespresso machines and floor-to-ceiling windows; some have city views. Suites offer living rooms and views of the Empire State Building; upgraded suites add furnished terraces. Room service is offered.Amenities include a sophisticated Italian restaurant/bar, a polished lobby lounge, and a 24-hour fitness center with a terrace. Pets are accepted for a fee.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7714,
        store_longitude: -73.9837,
        total_orders: 180.94,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Champagne, Coffee, Salad, Dessert, Ale, French Toast, Belgian, French, American',
        annual_dol: 188.78,
        description:
            'The Metropolitan Opera House is an opera house located on Broadway at Lincoln Square on the Upper West Side of Manhattan in New York City. Part of Lincoln Center, the theater was designed by Wallace K. Harrison. It opened in 1966, replacing the original 1883 Metropolitan Opera House at Broadway and 39th Street.',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.8043,
        store_longitude: -73.9483,
        total_orders: 1860.94,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Tequila, Wine, Steak, Margarita, Trendy, Delicious, Warm, Cozy, Rum, French',
        annual_dol: 1984.65,
        description:
            '"Archer & Goat is a vibrant restaurant offering creative Latin American and South Asian inspired fusion fare. Open air outdoor and indoor dining available. Featured in Eater NY Dining Guide 2021 Best Brunch Spots. Located in the garden level of a brownstone in the beautiful Mount Morris Park Historic District in Harlem, New York City. Featured in the Wall Street Journal, CNN International, Eater, The Infatuation, Forbes, Bloomberg News."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.7468,
        store_longitude: -73.8902,
        total_orders: 987.97,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Pizza, Spanish, Beer, Wine, Chinese, Hotel, Western, Premium, Eclectic, Casual',
        annual_dol: 1076.74,
        description: '',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK ORIGINAL',
        store_latitude: 40.726,
        store_longitude: -73.9922,
        total_orders: 535.75,
        neighborhood: 'NoHo',
        keywords:
            'Mexican, Tequila, Tacos, Vietnamese, Vegan, American, Latin, Fusion, Asian, Impeccable',
        annual_dol: 905.25,
        description:
            '"Vietnamese fusion featuring a Latin spirits and cocktail program. Kitchen open late night and brunch on the weekends."',
        dol_per_unit: 21.35,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6265,
        store_longitude: -74.0753,
        total_orders: 20.6,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Vegetarian, Wine, Coffee, Beer, Donut, Spacious, Caribbean, Casual, Great, Local',
        annual_dol: 84.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7194,
        store_longitude: -73.5638,
        total_orders: 129.01,
        neighborhood: '',
        keywords: 'Burger, Steak, Hamburger, Tasty, Beer, American, Casual, Blt Sandwich',
        annual_dol: 162.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7216,
        store_longitude: -74.0014,
        total_orders: 3431.66,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Wine, Delicious, Warm, Salad, Cozy, Organic, Mediterranean, Exotic, Outstanding, Turkish',
        annual_dol: 3730.25,
        description:
            'Mediterranean chow served in a funky, exposed-brick space with antiques & occasional live jazz.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -74.0094,
        total_orders: 291.13,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Wine, Delicious, Vodka, Upscale, Organic, Greek, Mediterranean, Perfect, Fresh',
        annual_dol: 297.31,
        description:
            'Greek taverna serving a high-end, seafood-focused menu in an large, chic space with an Aegean feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7963,
        store_longitude: -73.9495,
        total_orders: 235.8,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Tequila, Beer, Wine, Gluten Free, Gin, Spicy, Salad, African, Nigerian, Sweet',
        annual_dol: 963.86,
        description:
            'Spacious counter-serve highlighting African cuisine in customizable plates & bowls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7237,
        store_longitude: -74.0039,
        total_orders: 70.1,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Beer, Organic, Italian, Avocado Toast, Upscale, Russian, Modern, Latin, Authentic',
        annual_dol: 286.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.5782,
        total_orders: 24.94,
        neighborhood: '',
        keywords:
            'Burger, Steak, Lobster, Hamburger, Vegetarian, Lobster Tail, Vegan, Salad, Shrimp Cocktail, Gluten Free',
        annual_dol: 37.22,
        description:
            'Old-school eatery with a large menu of American breakfast favorites, sandwiches & entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6998,
        store_longitude: -73.916,
        total_orders: 7536.02,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Hamburger, Zombie, Vegan, Vegetarian, Salad, Beer, Urban, Wine, American',
        annual_dol: 8019.84,
        description:
            'Tiki-inspired cocktails, board games & inventive pub grub are the draw at this rustic-chic tavern.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5813,
        store_longitude: -73.8299,
        total_orders: 674.18,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords:
            'Burger, Hamburger, Chicken Pot Pie, Gourmet, Tasty, Donut, Bbq, Delicious, American, Homemade',
        annual_dol: 834.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.9778,
        total_orders: 8670.66,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Vodka, Beer, Scotch, Bourbon, Gin, Delicious, Hotel, Cozy, Sweet, Intimate',
        annual_dol: 9299.96,
        description:
            'Slick, Prohibition-style watering hole with craft cocktails & sophisticated bar snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -74.0101,
        total_orders: 35.51,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Meatloaf, Salad, Whiskey, Beer, Wine, Gluten Free, Tasty, Swiss, Trendy',
        annual_dol: 145.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7924,
        store_longitude: -73.9523,
        total_orders: 232.01,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Beer, Elegant, Delicious, American, Georgian, Perfect, Casual, Urban, Distinctive Character, Heritage',
        annual_dol: 315.84,
        description:
            'The Museum of the City of New York is a history and art museum in Manhattan, New York City, New York. It was founded by Henry Collins Brown, in 1923 to preserve and present the history of New York City, and its people.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -74.0053,
        total_orders: 8188.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Scotch, Whiskey, Hotel, Elegant, Cozy, Premium, Classy, Sophisticated, Friendly, Great',
        annual_dol: 8645.89,
        description: 'Cozy cigar bar with stogies, spirits & wines in a clubby, book-filled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -74.0042,
        total_orders: 4353.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Salad, Wine, Italian, Delicious, Cozy, Vintage, Relaxed, American',
        annual_dol: 4353.72,
        description:
            'This casual haunt offers bar snacks, steak dinners & plenty in between, plus varied wines & spirits.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7303,
        store_longitude: -74.004,
        total_orders: 6187.36,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Vodka, Vintage, Modern, Welcoming, Timeless, Innovative, Classic, Great, Casual',
        annual_dol: 6318.64,
        description:
            'Burgers & bar snacks in a retro, wood-lined watering hole, plus an innovative cocktail menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7347,
        store_longitude: -74.002,
        total_orders: 869.97,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Gluten Free, Dessert, Delicious, Coffee, Sweet, Fancy, Homemade, French, Amazing, Classic',
        annual_dol: 2921.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9897,
        total_orders: 1319.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Salad, Vegan, Spanish, Hotel, Seasonal, Cozy, Contemporary, Friendly, Classic',
        annual_dol: 1376.81,
        description:
            '"A lively, Barcelona-inspired tapas bar serving Spanish classics, seasonal specials, and curated cocktails in a vibrant and contemporary space."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.59,
        store_longitude: -73.9222,
        total_orders: 51.84,
        neighborhood: 'Gerritsen Beach',
        keywords: 'Italian, Dessert, Local',
        annual_dol: 211.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7491,
        store_longitude: -73.6207,
        total_orders: 463.34,
        neighborhood: '',
        keywords:
            'Coffee, Cheesecake, Pizza, Italian, Hotel, American, Opulent, Asian, Modern, Contemporary',
        annual_dol: 483.43,
        description:
            "Featuring sitting areas separated by partial walls, the contemporary suites include free Wi-Fi, flat-screen TVs, and desks, as well as minifridges, microwaves and coffeemakers.A hot breakfast buffet and parking are complimentary. Amenities include a fitness center and an indoor pool. There's also a convenience store, and a lobby lounge with a bar, as well as a patio offering outdoor seating and a fire pit.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8347,
        store_longitude: -73.8166,
        total_orders: 82.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'American',
        annual_dol: 134.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9902,
        total_orders: 3450.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Vegetarian, Beer, Gluten Free, Whiskey, Swiss, Bbq, American, Spicy, Casual, Local',
        annual_dol: 3523.23,
        description:
            'Late-night outpost for craft beer, cocktails & creative bar eats in a dark-wood setting with TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.9891,
        total_orders: 11.5,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hamburger, Casual, Authentic, Fine',
        annual_dol: 47.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8277,
        store_longitude: -73.7017,
        total_orders: 417.21,
        neighborhood: '',
        keywords: 'Elegant, Comfortable, Classic, Spectacular, Exceptional',
        annual_dol: 443.99,
        description: '"Yacht club in Port Washington, New York"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -74.0077,
        total_orders: 483.61,
        neighborhood: 'Hudson Square',
        keywords:
            'Steak, Tasty, Salad, Cuban, Delicious, Caribbean, Homemade, Rum, Sweet, Colorful',
        annual_dol: 949.41,
        description:
            '"Craft Cocktail and Rum Bar with Latin Caribbean/Old Cuban inspired plates designed by our internationally known Chef, Wesly Jean Simon. Join us for our $49 Bottomless Brunch every weekend!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.605,
        store_longitude: -73.9426,
        total_orders: 387.76,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Roast Beef, Italian, French, French Onion Soup, Delicious, American, Prime Rib',
        annual_dol: 404.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6339,
        store_longitude: -73.6882,
        total_orders: 370.05,
        neighborhood: '',
        keywords: 'Golfclub, Luxurious, Cozy, Phenomenal, Unique',
        annual_dol: 1512.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.5856,
        total_orders: 730.02,
        neighborhood: '',
        keywords:
            'Pizza, Kebab, Salad, Mediterranean, Luxurious, Organic, Sweet, Exquisite, High-End, Incredible',
        annual_dol: 2984.06,
        description:
            '"Park West of Westbury NY a beautiful lavish luxurious night club lounge that is also available for private events and private parties! Under new management 12/2021 Come find experience this breathtaking venue for yourself! For bookings, inquiries, and questions please email us! Parkwestlongisland@gmail.com"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7962,
        store_longitude: -73.9462,
        total_orders: 184.84,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Steak, Peruvian, Salad, Wine, Burger, Italian, Hotel, Latin, Fusion, Vibrant',
        annual_dol: 476.32,
        description:
            'Elevated Peruvian cooking in cool surrounds, plus an extensive, thoughtfully sourced wine menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7998,
        store_longitude: -73.9326,
        total_orders: 245.77,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Legendary, Premium, Fine',
        annual_dol: 1004.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.644,
        total_orders: 85.25,
        neighborhood: '',
        keywords: 'Tasty, Warm, American, Welcoming, Amazing, Great, British, Incredible, Casual',
        annual_dol: 167.13,
        description:
            'Longtime hangout with a 1950s motif offering American eats along with live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7739,
        store_longitude: -73.874,
        total_orders: 858.52,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Wine, Salad, Gourmet, Beer, Spacious, Healthy, Serene, Premium, Fresh, Contemporary',
        annual_dol: 1136.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.9048,
        total_orders: 237.53,
        neighborhood: 'Woodside',
        keywords:
            'Flavorful, Beer, Wine, Dominican, Spanish, Latin, Pinacolada, Polished, Superb, American',
        annual_dol: 256.45,
        description:
            'Festive restaurant with a patio for modern Latin & Dominican fare, plus a nightclub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6698,
        store_longitude: -73.8579,
        total_orders: 519.89,
        neighborhood: 'East New York',
        keywords:
            'Salad, Shrimp Cocktail, Steak, Mexican, Dessert, Martini, Spanish, Thai, Bbq, Latin',
        annual_dol: 938.92,
        description:
            'This landmark diner offers an extensive menu of American fare with Latin & Caribbean influences.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7723,
        store_longitude: -73.9558,
        total_orders: 26.4,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Kebab, Salad, Wine, Mediterranean, Inviting, Premium, Refreshing, Bright, Awesome',
        annual_dol: 92.47,
        description:
            '"The Blue Mezze Bar is a unique Mediterranean restaurant/bar on UES, Manhattan, with a focus on great food, equally amazing cocktails, and an inviting atmosphere! We have an extensive mezze and cocktail menu, ideal for sharing and tasting - perfect for friends, family, or that upcoming date! We formed the menu with inspiration from our friends and family at A la Turka."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7076,
        store_longitude: -73.9321,
        total_orders: 605.37,
        neighborhood: 'East Williamsburg',
        keywords:
            'Vegan, Burger, Vegetarian, Tacos, Beer, Wine, Instagrammable, Savory, American, Latin',
        annual_dol: 1186.88,
        description:
            'Vibrant venue with a patio fixing up tacos, burgers & finger foods, plus weekend brunch & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7967,
        store_longitude: -73.6703,
        total_orders: 3144.72,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Beer, Upscale, Comfortable, Casual, Mediterranean, Contemporary, Inviting, Natural',
        annual_dol: 3211.44,
        description:
            '"Cipollini Trattoria and Bar is a casual, Manhattan-style restaurant. Offering freshly prepared, satisfying meals in a comfortable, inviting atmosphere. A sizeable marble bar, perfect for socializing, welcomes people into the restaurant. Large, glass-paneled doors that are open seasonally bring the atmospheric alfresco dining into the trattoria, creating unity between the indoor and outdoor seating. During warmer months, sun umbrellas come together to provide a shady canopy and natural elements and beautiful flowers immerse diners in a Mediterranean dining experience. Cipollini has gained a reputation for its array of contemporary Italian specialties."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -74.0086,
        total_orders: 975.44,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Salad, Spicy, Green Papaya Salad, Japanese, Funky, Thai, Asian, Rum, Sweet',
        annual_dol: 1017.73,
        description:
            'Southeast Asian dishes with a Laotian emphasis served in a brick-walled, industrial-styled setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.808,
        store_longitude: -73.9291,
        total_orders: 223.98,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Beer, Mexican, Tasty, Sustainable, American, Latin, Southern, Welcoming, Casual, Vibrant',
        annual_dol: 313.78,
        description:
            'Sophisticated global fare, brunch & cocktails in a vibrant space with exposed brick & bold art.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7763,
        store_longitude: -73.9531,
        total_orders: 1035.96,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Sushi, Vegetarian, Thai, Gluten Free, Tasty, Ramen, Beer, Bbq, Organic, Spicy',
        annual_dol: 1104.82,
        description:
            'Classic Thai cuisine & cocktails served in a brick-lined space with weathered woods & a stylish bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.9214,
        total_orders: 882.9,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Burger, Steak, Salad, Beer, Ale, Irish, Delicious, American, Classic, Local',
        annual_dol: 890.84,
        description:
            'Sports bar with brick walls, red banquettes & sidewalk seating, serving burgers, wings & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9945,
        total_orders: 81.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Upscale, Italian, Chinese, Spacious, Casual, Global, Fine',
        annual_dol: 332.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9923,
        total_orders: 1010.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Pizza, Italian, Rustic, Upscale, Homemade, Seasonal, Cozy, Bruschetta, Inviting, Local',
        annual_dol: 1652.8,
        description:
            'Sleek, slightly upscale Italian spot maximizing comfort with 2 roomy dining areas & a pizza bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7526,
        store_longitude: -73.9843,
        total_orders: 87.46,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Chinese, Spicy, Sweet, Local, Casual, Fresh, Mapo Tofu',
        annual_dol: 357.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -73.8432,
        total_orders: 3609.25,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Vegan, Hamburger, Salad, Gluten Free, Beer, Wine, Organic, Bbq, Moroccan',
        annual_dol: 5704.45,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7779,
        store_longitude: -73.9543,
        total_orders: 2178.22,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Wine, French, Rustic, Cozy, Polished, Vintage, Warm, Authentic, Casual',
        annual_dol: 2207.63,
        description:
            'French bistro fare served in a typical brasserie atmosphere of dark wood & vintage posters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7773,
        store_longitude: -73.9519,
        total_orders: 2385.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tequila, Whiskey, Wine, Beer, Margarita, Salad, French Toast, Upscale, Rum, French',
        annual_dol: 2594.25,
        description:
            'Upscale American comfort food & craft cocktails are prepared in this homey frontier-chic tavern.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.774,
        store_longitude: -73.9577,
        total_orders: 853.11,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Chinese, Upscale, Dessert, Elegant, Juicy, Cozy, Healthy, Sweet, Innovative, Asian',
        annual_dol: 998.04,
        description:
            'Cozy & elegant dining space serving traditional & innovative Shanghainese dishes, including dim sum.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7765,
        store_longitude: -73.9522,
        total_orders: 20.86,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tasty, Italian, Beer, Shrimp Cocktail, Chicken Parmigiana, Romantic, Cozy, Bruschetta, Premium, Fabulous',
        annual_dol: 50.16,
        description:
            'Traditional Italian cooking served in a narrow room with exposed-brick decor & a romantic feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7261,
        store_longitude: -74.0085,
        total_orders: 22903.56,
        neighborhood: 'Hudson Square',
        keywords:
            'New-York-Style, Moroccan, Wine, Premium, Modern, Great, Lit, Chic, Rooftop, Rare',
        annual_dol: 23389.54,
        description:
            'Chic cocktail lounge where Morocco-inspired decor sets the stage for drinking & dancing all night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -74.0102,
        total_orders: 127.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Chocolate Croissant, Dessert, Coffee, Gourmet, Luxurious, French, Modern, Delicious, Picturesque, American',
        annual_dol: 519.9,
        description:
            '"Patisserie Chanson is a modern European café and eatery known for its artful and ambitious desserts and artisanal bakery offering. Open for breakfast, all-day brunch, and grab-and-go lunch, Patisserie Chanson offers freshly prepared French viennoiseries and an extensive drinks menu along with an open display of innovative gourmet pastries and piquant coffee brews. Among their exquisite baked goods are selections of the Kouign Amann, Croissant, and Ficelle. From the dessert menu, fan favorites include the Seasonal Tart and varieties of the Éclair and Marble Cake. Unmissable brunch items are the Black Truffle Grilled Cheese, Poached Egg & Salmon Croissant, and Pain Perdu, paired with refreshing cocktails, mocktails, wine, and champagne."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.6485,
        total_orders: 1398.27,
        neighborhood: '',
        keywords:
            'Vodka, Upscale, Gin, American, Modern, Contemporary, Delicious, Perfect, Casual, Fresh',
        annual_dol: 1400.73,
        description:
            'Locally sourced New American dining in renovated landmark farmhouse with a porch & taproom.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.847,
        total_orders: 15443.63,
        neighborhood: 'Flushing/Corona',
        keywords: 'Beer, Seasonal, Exceptional, Perfect, Great, Excellent',
        annual_dol: 64173.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7001,
        store_longitude: -73.9196,
        total_orders: 802.09,
        neighborhood: 'Bushwick',
        keywords: 'Vegetarian, Burger, Cozy, Casual, Natural, Chill, Great',
        annual_dol: 3022.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7309,
        store_longitude: -74.0041,
        total_orders: 536.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Steak, Tacos, Beer, Mimosa, Margarita, Dessert, Bloody Mary, Hotel, Modern',
        annual_dol: 955.61,
        description:
            'Imaginative twists on Mexican dishes, plus cocktails, presented in a modern, convivial setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6557,
        store_longitude: -73.6714,
        total_orders: 231.92,
        neighborhood: '',
        keywords:
            'Steak, Wine, Pizza, Cajun, Dessert, Italian, Meatball, Premium, Trendy, Romantic',
        annual_dol: 315.71,
        description:
            '"A casual Wine Bar and Kitchen located in the heart of the Lynbrook business district."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -74.001,
        total_orders: 1296.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Burger, Salad, Dessert, Whiskey, Scotch, Beer, Gin, Delicious, Upscale',
        annual_dol: 5479.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7345,
        store_longitude: -74,
        total_orders: 83.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chinese, Thai, Peking Duck, Asian, Martini, Ramen, Exquisite, Irresistible, Healthy, Mojito',
        annual_dol: 341.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8383,
        store_longitude: -73.8638,
        total_orders: 8.24,
        neighborhood: 'East Bronx/Park Versailles/Parkchester',
        keywords: 'Burger, American, Karaoke, Casual, Great',
        annual_dol: 22.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6348,
        store_longitude: -74.1072,
        total_orders: 13.55,
        neighborhood: 'Randall Manor',
        keywords:
            'Steak, Shrimp Cocktail, Chili Con Carne, Wine, Italian, Bbq, Upscale, German, Swiss, French Onion Soup',
        annual_dol: 47.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7239,
        store_longitude: -73.9514,
        total_orders: 4261.72,
        neighborhood: 'Greenpoint',
        keywords:
            'Chocolate Croissant, Burger, Salad, Coffee, Gourmet, Wine, Spicy, Delicious, Organic, American',
        annual_dol: 4990.98,
        description:
            'This chill Greenpoint bistro offers New American fare with an Aussie accent & brunch options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7283,
        store_longitude: -73.958,
        total_orders: 1547.25,
        neighborhood: 'Greenpoint',
        keywords: 'Cozy, Warm, Coffee, Friendly, Perfect, Unique',
        annual_dol: 2232.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9659,
        total_orders: 581.76,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Shrimp Cocktail, Italian, Delicious, Homemade, Healthy, Authentic, Outstanding, Casual, Fresh',
        annual_dol: 594.11,
        description:
            'Old-fashioned Italian cooking in a brick-walled setting run by the same family since 1955.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7667,
        store_longitude: -73.9694,
        total_orders: 39.23,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Italian, Mexican, Tasty, Dessert, Wine, Delicious, Asian, Classy, Polished',
        annual_dol: 137.38,
        description:
            'Polished destination with an urbane vibe serving classic Italian cuisine & wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5749,
        store_longitude: -73.9793,
        total_orders: 1816.53,
        neighborhood: 'Coney Island',
        keywords: 'Margarita, Beer, Mexican, Pizza, Bbq, Mojito, American, Sweet, Teriyaki, Casual',
        annual_dol: 2282.91,
        description:
            'Rollicking outdoor nightspot with live music, frozen drinks, games & a menu of BBQ & pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6749,
        store_longitude: -73.9813,
        total_orders: 1822.57,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Whiskey, Beer, Ale, Spacious, Rustic, Yummy, Perfect, Friendly, Retro',
        annual_dol: 1903.89,
        description:
            'Local haunt serving cocktails & beer in an old-school setup with dart boards & a shuffleboard table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7457,
        store_longitude: -73.9008,
        total_orders: 2757.22,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Vodka, Tequila, Nepalese, Beer, Fusion, Biryani, Casual',
        annual_dol: 7506.9,
        description: '"New York Ko Bhatti"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8095,
        store_longitude: -73.6291,
        total_orders: 227.33,
        neighborhood: '',
        keywords:
            'Lobster, Chinese, Tangy, Peking Duck, Spicy, Organic, American, Wonton Soup, Local, Authentic',
        annual_dol: 237.18,
        description:
            'Hunan cooking spices up a menu that includes Peking duck & dumplings, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8194,
        store_longitude: -73.9272,
        total_orders: 620.55,
        neighborhood: 'West Bronx',
        keywords:
            'Garlic Bread, Pizza, Italian, Vodka, Champagne, Meatball, Dessert, Bbq, Peruvian, Zesty',
        annual_dol: 661.8,
        description:
            'Vibrant locale for traditional Italian fare & pizzas, with a bar, weekend brunch & karaoke nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7772,
        store_longitude: -73.9519,
        total_orders: 1460.06,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Whiskey, Beer, Sushi, Bourbon, Coffee, Warm, Irish, Rustic, American, Awesome',
        annual_dol: 1557.67,
        description:
            'Rustic, brick-walled bar known for its whiskeys, rotating draft brews & elevated American eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7323,
        store_longitude: -74.0038,
        total_orders: 8480.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Beer, Vodka, Wine, Delicious, Funky, Bourbon, Cozy, Refined, Friendly',
        annual_dol: 8971.77,
        description:
            'Handsome second-floor bar with exposed brick, serving cocktails & Five Guys burgers from downstairs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6349,
        total_orders: 1269.84,
        neighborhood: '',
        keywords:
            'Hotdog, Beer, Ale, Whiskey, Irish, Friendly, Traditional, Awesome, Great, Excellent',
        annual_dol: 1330.51,
        description:
            'Popular, late-night watering hole featuring craft beers on tap & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7241,
        store_longitude: -73.6401,
        total_orders: 3954.25,
        neighborhood: '',
        keywords:
            'Wine, Luxurious, Glamorous, Upscale, Hotel, Flavorful, Trendy, Elegant, Delicious, Margarita',
        annual_dol: 4080.1,
        description:
            'The posh rooms have flat-screen TVs, Italian furniture, Wi-Fi (surcharge) and 24-hour room service. Upgraded suites add kitchenettes and living areas, while some feature an additional bathroom and private outdoor patios.Perks include 25,000 sq ft of event space, an indoor pool, a spa and sauna, a fitness center and an upscale steakhouse, plus a seasonal patio bar, and lounge. Pet walking and feeding services are available. Parking is free.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.66,
        store_longitude: -73.6708,
        total_orders: 87.89,
        neighborhood: '',
        keywords: 'Sweet',
        annual_dol: 100.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.735,
        store_longitude: -73.5974,
        total_orders: 499.53,
        neighborhood: '',
        keywords: 'Clean, Friendly, Cuban, Savory, Casual, Fun, Modern, American, Classic, Great',
        annual_dol: 523.9,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6509,
        store_longitude: -73.6116,
        total_orders: 356.23,
        neighborhood: '',
        keywords:
            'Pizza, Chicken Parmigiana, Salad, Vodka, Mexican, Italian, Meatball, Wine, Creamy, Caesar Salad',
        annual_dol: 436.01,
        description:
            '"Looking for a spot to enjoy appetizers, drinks or a hearty dinner? Our NOVI Lunch, Dinner and Drink Menus boast something for everyone! Having a party or need a venue for your next event? We do on & off premise catering! From pizzas to pastas to seafood, Come down to NOVI for generous portions & modest prices. Whether it is a special occasion or a weekday evening, there is something for all to enjoy at NOVI!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7814,
        store_longitude: -73.9798,
        total_orders: 4862.37,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Burger, Ale, Hamburger, Vodka, Steak, Wine, Gin, Dessert, Fried Calamari',
        annual_dol: 4881.24,
        description:
            'No-frills brewpub with a sizable craft beer lineup, a bar food menu & several TVs airing sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.775,
        store_longitude: -73.9545,
        total_orders: 9.55,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Vegan, Coffee, Burger, Vegetarian, Tasty, Salad, Wine, Pizza, Beer, Organic',
        annual_dol: 39.03,
        description:
            'Australian hangout specializing in coffee drinks, along with salads & other daytime bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.6132,
        total_orders: 91.05,
        neighborhood: '',
        keywords: 'Lobster, Salad, Sushi, Steak, Japanese, Spicy, Sashimi, Creamy, Beer, Fun',
        annual_dol: 372.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6695,
        store_longitude: -73.9866,
        total_orders: 618.89,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Coffee, Ale, Funky, Dessert, Bourbon, Spacious, Ample, Relaxed, Friendly',
        annual_dol: 676.99,
        description:
            'Dog-friendly bar & hipster hangout with a broad selection of craft beers in chill, funky environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6602,
        store_longitude: -73.9836,
        total_orders: 227.2,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords:
            'Chocolate Mousse, Salad, Italian, Meatball, Mimosa, Cozy, Asian, Premium, Seasonal, Friendly',
        annual_dol: 271.67,
        description:
            'Seasonal menus of classic Italian eats star at this warm, cozy restaurant with outside seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6702,
        store_longitude: -73.9856,
        total_orders: 62.58,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Tacos, Tequila, Burger, Beer, Margarita, Martini, Quesadillas, Enchiladas, Wine',
        annual_dol: 76.78,
        description:
            'Warm Mexican eatery with colorful murals offering tacos & enchiladas plus ample tequila & mezcal.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7708,
        store_longitude: -73.9575,
        total_orders: 16.29,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Burger, Dessert, Beer, Spicy, Belgian, Elegant, Friendly, Wonderful, Classic',
        annual_dol: 66.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5757,
        store_longitude: -73.9798,
        total_orders: 93.7,
        neighborhood: 'Coney Island',
        keywords: 'Sushi, Champagne, Fun, Cool, Casual, Great',
        annual_dol: 143.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7187,
        store_longitude: -73.8402,
        total_orders: 351.12,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Pizza, Lobster, Italian, Gluten Free, Beer, Wine, Vodka, Homemade, Amazing, Fresh',
        annual_dol: 415.05,
        description:
            'Formal setting for an Italian menu with nightly live music, plus a Sunday brunch buffet.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7772,
        store_longitude: -73.9559,
        total_orders: 99.16,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Vegetarian, Beer, Flavorful, Chicken Tikka Masala, Elegant, Contemporary, Indian, Tandoori Chicken, Friendly',
        annual_dol: 127.87,
        description:
            'Tandoor oven & vegetarian specialties in an ornate interior. Takeout & delivery offered.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6522,
        store_longitude: -73.8379,
        total_orders: 438.53,
        neighborhood: 'Jamaica/Howard Beach',
        keywords: '',
        annual_dol: 505.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7346,
        store_longitude: -74.002,
        total_orders: 1914.2,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Whiskey Sour, Beer, Roast Turkey, Whiskey, Wine, Turkey Sandwich, Margarita, Spicy, Irish',
        annual_dol: 1954.82,
        description:
            'Named after a boat, this 4-story pub boasts a fireplace & billiards along with a sizable beer menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7487,
        store_longitude: -73.642,
        total_orders: 122.85,
        neighborhood: '',
        keywords:
            'Garlic Bread, Pizza, Tasty, Vegetarian, Salad, Italian, Delicious, Dessert, Comfortable, Awesome',
        annual_dol: 502.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7362,
        store_longitude: -74.0091,
        total_orders: 87.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Italian, Gin, Polished, Natural, Casual, Complex',
        annual_dol: 359.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7842,
        store_longitude: -73.9773,
        total_orders: 66.97,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Thai, Spicy, Delicious, Asian, Hawaiian, Colorful, Traditional, Authentic, Fresh, Mojito',
        annual_dol: 80,
        description:
            'Bustling chain offering generous portions of traditional Thai dishes & colorful cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6595,
        store_longitude: -73.5433,
        total_orders: 15.93,
        neighborhood: '',
        keywords: 'Pizza, Vodka, Beer, Salad, Italian, Bruschetta, Fresh, Great, Excellent, Local',
        annual_dol: 20.54,
        description:
            'Bustling, family-oriented pizza maker turning out brick-oven pies, plus pastas & other faves.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7708,
        store_longitude: -73.917,
        total_orders: 5.98,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Burger, Salad, French Toast, Coffee, French, American, Crisp, Belgian, Hotel, Casual',
        annual_dol: 24.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7656,
        store_longitude: -73.9189,
        total_orders: 1884.83,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Burger, Mexican, Beer, Wine, Bourbon, Spanish, Premium, American, Irish',
        annual_dol: 2461.8,
        description: '"Queer Cocktail Lounge & Kitchen in Astoria, New York."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6599,
        store_longitude: -73.9806,
        total_orders: 161.63,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords:
            'Burger, Salad, Avocado Toast, Wine, American, Casual, Local, Cool, Creative, Great',
        annual_dol: 612.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -73.9396,
        total_orders: 1799.91,
        neighborhood: 'East Williamsburg',
        keywords: 'Pizza, Cozy, Spacious, Cool, Daiquiri, Casual, Local, Great',
        annual_dol: 1840.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9654,
        total_orders: 771.89,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Delicious, Cozy, Warm, Impeccable, Friendly, Intimate, Excellent, Casual, Fine',
        annual_dol: 788.27,
        description:
            'High-end Italian dining venue that lures an "in" crowd to its intimate downstairs grotto setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7676,
        store_longitude: -73.953,
        total_orders: 197.66,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Vegan, Pizza, Salad, Chicken Parmigiana, Italian, Gluten Free, Wine, Beer, Bruschetta',
        annual_dol: 206.23,
        description:
            'Quiet, candlelit trattoria featuring familiar Italian flavors & occasional live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.769,
        store_longitude: -73.9683,
        total_orders: 41.95,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: '',
        annual_dol: 54.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7664,
        store_longitude: -73.9712,
        total_orders: 46.57,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotel, Italian, Brazilian, Urban, Intimate, Traditional, Local, Excellent, Artistic, Signature',
        annual_dol: 242.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7539,
        store_longitude: -73.9894,
        total_orders: 71.91,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Pizza, Italian, Wine, Polished, Local, Casual, Great, Fine',
        annual_dol: 73.44,
        description:
            'Square thin-crust pies & Italian standards are offered in a basic, second-floor location.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.9844,
        total_orders: 3940.92,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Wine, Rooftop, Romantic, Lively, Fantastic, Incredible, Great, Polished, Classic',
        annual_dol: 4024.54,
        description:
            "from the Empire State Building.Upscale, individually decorated rooms feature floor-to-ceiling windows, designer toiletries and 42-inch flat-screen TVs, plus free Wi-Fi, minifridges and coffeemakers. Room service is available.Free passes to an off-site sports club are offered. There's a polished steakhouse serving seasonal American dishes, along with a lively lobby bar with a patio, and a rooftop bar with signature cocktails and sweeping city views.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7536,
        store_longitude: -73.9913,
        total_orders: 1553.13,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Korean, Wine, Trendy, Delicious, Spicy, Classy, Asian, Bbq, Contemporary, Friendly',
        annual_dol: 1730.6,
        description:
            'Korean BBQ & cocktails offered in lofty, contemporary surrounds with table grills & a sizable bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8311,
        store_longitude: -73.6959,
        total_orders: 171.41,
        neighborhood: '',
        keywords: 'Hotel, Unique',
        annual_dol: 700.67,
        description:
            '425-seat theater in a historic building spotlighting celebrated musicians, speakers & comedians.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.5525,
        total_orders: 4623.66,
        neighborhood: '',
        keywords: 'Burger, Zombie, Steak, Salad, Beer, Margarita, Wine, Dessert, Bbq, Bourbon',
        annual_dol: 4721.77,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.716,
        store_longitude: -73.9965,
        total_orders: 1607.33,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Lobster, Champagne, Hotel, Wine, Tequila, Chinese, Stylish, Tasty, Asian, Funky',
        annual_dol: 2921,
        description:
            'All-day Asian spot at 50 Bowery hotel offering dim sum & share plates, plus American breakfasts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7768,
        store_longitude: -73.9527,
        total_orders: 2976.38,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Salad, Sushi, Italian, Martini, Veal Parmigiana, Delicious, Cozy, Polished, Casual, Amazing',
        annual_dol: 3039.53,
        description:
            'Popular old-school Italian eatery known for classic dishes & people-watching opportunities.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7757,
        store_longitude: -73.9475,
        total_orders: 1326.42,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Tasty, Greek, Friendly, Authentic, Casual, Classic, Local',
        annual_dol: 1387.02,
        description:
            'Old-school Yorkville Italian restaurant serving classic red-sauce fare since 1982.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7425,
        store_longitude: -73.9157,
        total_orders: 11.79,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: '',
        annual_dol: 12.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6251,
        store_longitude: -74.0746,
        total_orders: 1495.22,
        neighborhood: 'Clifton',
        keywords:
            'Steak, Vegetarian, Salad, Margarita, Italian, French Toast, Mimosa, Beer, Organic, Avocado Toast',
        annual_dol: 1808.11,
        description:
            '"Located on Bay Street in Staten Island, The Richmond features New American Bistro fare with artisanal cocktails and craft beer."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6389,
        store_longitude: -73.6601,
        total_orders: 218.99,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Salad, Meatball, Wine, Delicious, Mediterranean, Robust, Fresh, Excellent',
        annual_dol: 282.39,
        description:
            '"A little bit of Italy brought to Long Island. Our robust and authentic cuisine was brought over by Italian immigrants in the late 1800s. If you’re hoping to experience Old World Italian cuisine, there’s nothing quite like Grandpa Tony’s. We are known for our hearty portions, fresh ingredients. served in all your old-world favorites. Our Chef is inspired by a traditional Italian cuisine and uses his passion for cooking to make every dish perfect."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6537,
        store_longitude: -73.867,
        total_orders: 15.27,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Juicy, Lobster, Cajun, Delicious, Romantic, Friendly, Homemade, American, Great, Excellent',
        annual_dol: 62.41,
        description:
            '"Welcome to Red Crab Juicy Seafood in East New York. Forged from the Heat of the South, our spices bring the Bold Flavors that you can expect from a Red Crab Seafood Boil. Since our first location in Florida, we have had grand visions of serving our aromatic seasonings all over the states. We are determined to bring our delicious seafood boil and career opportunities to our communities. Whether it is for parties, a romantic dinner, or even a light snack, our original homemade seasoning blend is sure to make you “Crave the Boil!”"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7316,
        store_longitude: -74.0068,
        total_orders: 736.41,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Whiskey, Beer, Pizza, Ale, Irish, American, Friendly, Authentic, Classic, Outstanding',
        annual_dol: 752.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -74.0052,
        total_orders: 48.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Wine, Meatball, Korean, Spicy, Martini, Trendy, Green Curry, American, French',
        annual_dol: 197.86,
        description:
            'Quaint restaurant offering old-school American dishes & cocktails amid Shaker-style surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.658,
        store_longitude: -73.6476,
        total_orders: 104.68,
        neighborhood: '',
        keywords:
            'Garlic Bread, Pizza, Chicken Parmigiana, Salad, Italian, Wine, Spicy, Delicious, Omelette, Polished',
        annual_dol: 119.41,
        description:
            'Neapolitan wood-fired pizza & other southern Italian dishes offered in a refined, intimate space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7966,
        store_longitude: -73.6721,
        total_orders: 847.46,
        neighborhood: '',
        keywords:
            'Steak, Vegetarian, Sushi, Japanese, Wine, Asian, Filet Mignon, Modern, Fusion, Casual',
        annual_dol: 884.2,
        description: 'Hibachi chain serving Japanese dishes grilled tableside by theatrical chefs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7172,
        store_longitude: -74.0051,
        total_orders: 3293.6,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Sushi, Hamburger, Japanese, Turkish, Chinese, Mediterranean, Asian, Casual, Modern',
        annual_dol: 15196.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.657,
        store_longitude: -74.0071,
        total_orders: 1263.23,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords:
            'Lobster Roll, Lobster, Burger, Pizza, Hamburger, Coffee, Beer, Wine, Jamaican, Rich',
        annual_dol: 1365.95,
        description:
            '"Frying Pan Brooklyn is serving up cocktails, brews, lobster rolls and more at this Industry City outpost."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7211,
        store_longitude: -74.0046,
        total_orders: 894.18,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Beer, Delicious, Warm, Italian, Crisp, Spanish, American, Natural, Classic',
        annual_dol: 1153.08,
        description:
            'A seafood-forward menu with a raw bar is served in a light-filled space with a wraparound bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6925,
        store_longitude: -74.0151,
        total_orders: 130.53,
        neighborhood: 'Governors Island',
        keywords: 'Tequila, Tacos, Mexican, Beer, Pizza, Vegan, Margarita, Tasty, Wine, Painkiller',
        annual_dol: 255.92,
        description:
            'Governors Island eatery serving casual Mexican fare in a scenic setting with beachy vibes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.951,
        total_orders: 885.58,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Lobster, Tacos, Lobster Roll, Beer, Margarita, Tasty, American, Rum, Irish',
        annual_dol: 972.33,
        description:
            'Relaxed, brick-lined eatery serving burgers, sandwiches, tacos & wings, plus cocktails & draft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.8393,
        total_orders: 95.86,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Pizza, Vodka, Italian, Salad, Spicy, Mediterranean, Casual, Authentic, Local',
        annual_dol: 109.35,
        description:
            'Rustic, yellow-walled exposed-brick trattoria with an open kitchen serving Northern Italian eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7317,
        store_longitude: -74.0037,
        total_orders: 540.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Tacos, Margarita, Nachos, Quesadillas, Tasty, Enchiladas, Spicy, Latin, Local',
        annual_dol: 973.77,
        description:
            'Potent drinks & a crowded warm-weather patio are the main draws at this longtime Mexican eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -74.005,
        total_orders: 1384.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Tequila, Salad, Beer, Coffee, Meatball, Mediterranean, Spanish, Greek, Modern',
        annual_dol: 1543.98,
        description:
            'Intimate, brick-lined Greek eatery serving both traditional & modern dishes plus wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6812,
        store_longitude: -73.6148,
        total_orders: 25.42,
        neighborhood: '',
        keywords: 'Karaoke, Beer, Wine, Caribbean, American, Latin, Urban, Friendly, Casual',
        annual_dol: 34.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6932,
        store_longitude: -73.7574,
        total_orders: 66.46,
        neighborhood: 'Jamaica/St. Albans',
        keywords: 'Cozy, Ale, Beer, Casual, Fried Shrimp, French, Latin, American',
        annual_dol: 121.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7249,
        store_longitude: -73.5506,
        total_orders: 139.91,
        neighborhood: '',
        keywords:
            'Salad, Kebab, Vegetarian, Gluten Free, Dessert, Tasty, Mediterranean, Cozy, Turkish, Creamy',
        annual_dol: 149.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7853,
        store_longitude: -73.9728,
        total_orders: 1330.04,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Steak, Tequila, Pizza, Cheesesteak, Vodka, Wine, Salad, Beer, Scotch, Nachos',
        annual_dol: 1358.26,
        description:
            'Supper club with Jazz Age-themed decor, a long cocktail list & nightly live music performances.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7894,
        store_longitude: -73.9739,
        total_orders: 569.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Italian, French, New-York-Style, Trendy, Hotel, Fried Chicken, American, Southern, Casual',
        annual_dol: 1118.4,
        description:
            'Trendy multi-level destination with exposed-brick walls serving elevated pub grub & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.716,
        store_longitude: -73.9971,
        total_orders: 19.06,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Chinese, Bbq, Peking Duck, Seasonal, Asian, Classic, Casual, Dim Sum, Authentic',
        annual_dol: 77.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7519,
        store_longitude: -73.6578,
        total_orders: 414.74,
        neighborhood: '',
        keywords: 'Modern, Casual, Great, Delicious, Signature',
        annual_dol: 424.61,
        description:
            "All-ages bowling alley featuring a weekly rock 'n' roll bowl, pub grub & league tournaments.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7309,
        store_longitude: -73.6921,
        total_orders: 5728.89,
        neighborhood: '',
        keywords:
            'Garlic Bread, Lobster, Lobster Tail, Cajun, German, Beer, Wine, Spicy, American, Thai',
        annual_dol: 6109.73,
        description:
            '"The Inn at New Hyde Park is one of the largest wedding venues on Long Island, New York, and we invite you to take a tour of our award-winning event venue. We cater to different special occasions like baby showers, birthday parties and weddings. Experience an enchanting backdrop, elegant banquet and unique event planning that suit your needs. Terraces, fountains, gardens and both indoor and outdoor chapels create a picture-perfect landscape at every turn. You and your guests will be transported back to turn of the century elegance and refinement, while being pampered by modern day luxury. You can visit us or contact us today to arrange a private tour to view The Inn’s grounds and stunning ballrooms."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7646,
        store_longitude: -73.9162,
        total_orders: 21289.25,
        neighborhood: 'Astoria',
        keywords:
            'Steak, Vodka, Margarita, Vegetarian, Champagne, Coffee, Dessert, Colombian, Cuban, Rich',
        annual_dol: 21740.97,
        description:
            'Trendy, multilevel restaurant & bar featuring classic Latin American fare, cocktails, TVs & music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7853,
        store_longitude: -73.7236,
        total_orders: 9.53,
        neighborhood: '',
        keywords: 'Cajun, Sushi, Spicy, Japanese, French, Asian, New Zealand, Great, Clean, Casual',
        annual_dol: 13.71,
        description:
            'Japanese & Continental flavors mix in a small, no-frills restaurant serving sushi, duck & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7529,
        store_longitude: -73.9838,
        total_orders: 3557.97,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Steak, Wine, Japanese, Upscale, Stylish, Luxurious, Trendy, Delicious',
        annual_dol: 3732.47,
        description:
            'Elevated Japanese cuisine whipped up in a buzzing space with elegant, Zen-inspired decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.9973,
        total_orders: 229.72,
        neighborhood: 'Little Italy',
        keywords: 'Lobster, Salad, Dessert, Wine, Martini, Italian, Warm, Beer, Cozy, Romantic',
        annual_dol: 433.31,
        description:
            'White-jacketed waiters serve Southern Italian specialties at this restaurant dating to 1902.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.702,
        store_longitude: -74.0107,
        total_orders: 1827.55,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Upscale, Korean, Hotel, Chilean, Classy, Impeccable, Great, Hip, Heritage',
        annual_dol: 5598.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.722,
        store_longitude: -74.0038,
        total_orders: 1183.22,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Burger, Pizza, Italian, Salad, Stylish, Wine, Delicious, Elegant, Phenomenal, Polished',
        annual_dol: 1234.52,
        description:
            'Offshoot of a longtime French restaurant with Italian & Provençal fare in stylish but casual digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7228,
        store_longitude: -74.0036,
        total_orders: 1217.72,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Beer, Coffee, Trendy, French, Delicious, Legendary, Warm, Traditional, Fabulous',
        annual_dol: 1245.05,
        description:
            'French bistro classics draw SoHo shoppers to this spot with huge windows that open onto the street.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7995,
        store_longitude: -73.944,
        total_orders: 1568.66,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Sushi, Hamburger, Mexican, Salad, Coffee, Spicy, Moroccan, Delicious, Mediterranean, Cozy',
        annual_dol: 3843.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -74.0053,
        total_orders: 340.45,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Lobster Roll, Wine, Beer, Coffee, Upscale, Danish, Classy, Eclectic, Contemporary',
        annual_dol: 1460.59,
        description:
            'Luxe, intimate spot for inventive, multicourse New American tasting menus & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7406,
        store_longitude: -74.0059,
        total_orders: 20015.24,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Whiskey, Hotel, Trendy, Romantic, Awesome, Rooftop, American, Classic, Great, Excellent',
        annual_dol: 20729.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7347,
        store_longitude: -74.0009,
        total_orders: 1548.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Wine, Salad, Beer, Gin, Organic, Martini, Sustainable, Relaxed, Delicious',
        annual_dol: 1618.58,
        description:
            'Basement-set American eatery that gets most of the produce for its seasonal fare from a roof garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -74.0033,
        total_orders: 751.23,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Vegan, Vegetarian, Wine, Stylish, Gin, Perfect, Modern, Casual, French, Fancy',
        annual_dol: 1935.8,
        description:
            'Stylish New American restaurant offering sharing plates, a raw bar & cocktails, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7408,
        store_longitude: -74.0047,
        total_orders: 321.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Salad, Italian, Hotel, Premium, Homemade, Unforgettable, Exceptional, Casual, Rooftop',
        annual_dol: 1315.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7058,
        store_longitude: -74.0103,
        total_orders: 673.16,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Beer, Burger, Warm, Irish, Ale, Friendly, American, Casual, Authentic, Modern',
        annual_dol: 702.35,
        description:
            'Laid-back, dimly lit pub serving bar staples & a popular house burger with handcut fries.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6056,
        store_longitude: -73.6492,
        total_orders: 1325.43,
        neighborhood: '',
        keywords: 'Wine, Cajun, Juicy, Salad, Martini, Great, Diverse, Casual',
        annual_dol: 1731.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6087,
        store_longitude: -73.6467,
        total_orders: 236.04,
        neighborhood: '',
        keywords:
            'Beer, Delicious, Spanish, Superb, Authentic, Italian, Lively, Great, Traditional, Fresh',
        annual_dol: 265.88,
        description:
            'Traditional Portuguese eats & sangria served amid a lively scene in casual digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8331,
        store_longitude: -73.8511,
        total_orders: 91.89,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Vegetarian, Comfortable, Friendly, Fun, Casual, Indian, Lively, Caribbean, Fantastic, Great',
        annual_dol: 136.55,
        description:
            'Lively bar & grill featuring hearty portions of Guyanese & West Indian cuisine, plus a happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7092,
        store_longitude: -73.8699,
        total_orders: 6148.01,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords: 'Tequila, Burger, Beer, Vodka, Margarita, Ale, Warm, Tex-Mex, American, Awesome',
        annual_dol: 6291.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5906,
        store_longitude: -73.7972,
        total_orders: 1712.87,
        neighborhood: 'Arverne by the Sea/Far Rockaway/Arverne',
        keywords: 'Tacos, Pizza, Wine, Dessert, Bbq, Beer, Tasty, Casual',
        annual_dol: 2046.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7057,
        store_longitude: -73.8942,
        total_orders: 158.15,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Friendly, Cozy, Fun, Sweet, Karaoke, Perfect, Fresh, Great, Creative',
        annual_dol: 455.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7003,
        store_longitude: -73.9076,
        total_orders: 604.26,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Wine, American, Perfect, Great, Excellent, Local, Casual, Signature',
        annual_dol: 2470.02,
        description:
            '"The premier vinyl record bar of Queens, NYC. Serving up craft beer, cocktails & analog sounds. 3000+ vinyl library & good vibes. Afro-Latino owned."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9637,
        total_orders: 1171.89,
        neighborhood: 'Lenox Hill',
        keywords: 'Lobster, Coffee, Wine, Salad, Martini, Savory, Italian, Stylish, Hotel, Belgian',
        annual_dol: 3184.81,
        description:
            'Glam hub with outdoor seats offering pastries, light fare & a seasonal dinner menu, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9577,
        total_orders: 872.51,
        neighborhood: 'Greenpoint',
        keywords: 'Lobster, Burger, Beer, Salad, Wine, Warm, Ale, Spacious, Polish, Homey',
        annual_dol: 910.34,
        description:
            'Modern hangout featuring house-brewed craft beer & elevated bar bites, with a warm, homey vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7292,
        store_longitude: -74.0037,
        total_orders: 437.07,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Steak, Wine, Salad, Shrimp Cocktail, Pizza, Upscale, Italian, Donut, Beer',
        annual_dol: 1534.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7306,
        store_longitude: -74.0066,
        total_orders: 1239.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Vegetarian, Gluten Free, Organic, Cozy, Warm, French, American, Perfect',
        annual_dol: 1321.37,
        description:
            'Neighborhood eatery with an intimate feel serving a New American menu paired with cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8454,
        store_longitude: -73.8589,
        total_orders: 167.58,
        neighborhood: 'East Bronx/Van Nest',
        keywords: 'Traditional, Great, Wonderful, Fine',
        annual_dol: 222.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8345,
        store_longitude: -73.8506,
        total_orders: 35.82,
        neighborhood: 'East Bronx/Westchester Square/Parkchester',
        keywords:
            'Tacos, Milkshake, Mexican, Vegetarian, Salad, Nachos, Quesadillas, Casual, Local, Traditional',
        annual_dol: 60.52,
        description:
            'Large portions of Mexican food are served up at this small, family-friendly restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7674,
        store_longitude: -73.9228,
        total_orders: 174.79,
        neighborhood: 'Astoria/LIC',
        keywords: 'Sushi, Salad, Coffee, Delicious, Beer, Thai, Premium, Greek, American, Fresh',
        annual_dol: 714.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -73.9577,
        total_orders: 8077.57,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Ale, Gin, Bourbon, Rum, Upscale, Hip, Fun, Friendly',
        annual_dol: 8248.96,
        description:
            'Hip bar with wood floors & tin ceilings offering creative cocktails, plus a back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8568,
        store_longitude: -73.7908,
        total_orders: 4114.08,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Italian, Caesar Salad, American, Casual, Local, Fresh, Excellent',
        annual_dol: 4223.5,
        description:
            'Low-key spot with outdoor seats on the Sound offers a variety of seafood & American comfort fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.9588,
        total_orders: 861.34,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Pizza, Italian, Meatball, Wine, Beer, Vodka, Classy, Amazing, Local, Great',
        annual_dol: 1000.26,
        description:
            'Airy, light-filled outpost serving wood-fired pizza & Italian cuisine, plus wine, beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6779,
        store_longitude: -74.017,
        total_orders: 100.65,
        neighborhood: 'Red Hook',
        keywords:
            'Pizza, Meatball, Italian, Salad, Bruschetta, Margarita, Aperol, Sweet, Local, Casual',
        annual_dol: 411.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.732,
        store_longitude: -73.9837,
        total_orders: 1368.33,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Hotdog, Beer, Irish, Ale, Ramen, Rustic, Cozy, Great, Casual, Fine',
        annual_dol: 1411.02,
        description:
            'Cozy Irish tavern offering draft beers & cocktails, plus pool tables, dart boards & sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9858,
        total_orders: 2485.37,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Beer, Upscale, American, Friendly, Perfect, Cool, Casual, Classic, Lively, Chill',
        annual_dol: 2538.1,
        description:
            'This diminutive corner watering hole with minimal decor offers a classic jukebox & happy hour deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7323,
        store_longitude: -73.9844,
        total_orders: 441.81,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Pizza, Filipino, Beer, Salad, Whiskey, Fabulous, Fun, Fantastic, Casual',
        annual_dol: 1673.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6392,
        store_longitude: -73.9685,
        total_orders: 2057.85,
        neighborhood: 'Flatbush',
        keywords: 'Wine, Whiskey, Beer, Relaxed, Irish, Casual, Premium, Cool, Great',
        annual_dol: 2249.2,
        description:
            'Compact, relaxed Irish pub serving European beers & meat pies, with soccer on TV & simple decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8045,
        store_longitude: -73.9662,
        total_orders: 742.24,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Burger, Hamburger, Pizza, Salad, Beer, Bbq, Margarita, Vodka, Tasty, American',
        annual_dol: 757.98,
        description:
            'Creatively topped burgers plus craft beers & boozy milkshakes in the light of a huge neon sign.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.832,
        store_longitude: -73.8657,
        total_orders: 2.76,
        neighborhood: 'East Bronx/Soundview',
        keywords:
            'Burger, Hamburger, Pizza, Mexican, Salad, Breakfast Burrito, Nachos, Margarita, Pinacolada, Quesadillas',
        annual_dol: 11.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.9658,
        total_orders: 467.67,
        neighborhood: 'Lenox Hill',
        keywords: 'Burger, Sushi, Pizza, Italian, Mexican, Delicious, Cozy, Warm, Asian, Classy',
        annual_dol: 487.95,
        description:
            'Intimate restaurant with warm wooden fixtures featuring traditional Northern Italian food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.7764,
        total_orders: 37.7,
        neighborhood: 'Fresh Meadows/Auburndale/Flushing',
        keywords:
            'Burger, Salad, Coffee, Roast Beef, Dessert, Wine, Club Sandwich, Caribbean, Casual, Great',
        annual_dol: 66.09,
        description:
            'Old-school diner serves hefty portions of breakfast fare, burgers & made-from-scratch desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9717,
        total_orders: 16.29,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Luxurious, Great, Clean, Casual',
        annual_dol: 66.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7604,
        store_longitude: -73.9947,
        total_orders: 71.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Beer, Champagne, Wine, Vodka, Cosmopolitan, Green Tea Shot, Bbq, Karaoke',
        annual_dol: 294.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9834,
        total_orders: 99.67,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Australian, American, Amazing, Spanish, Great, Exceptional, Excellent, Friendly, Clean',
        annual_dol: 407.42,
        description:
            "mile from the Empire State Building.The warmly decorated rooms provide Wi-Fi, flat-screen TVs and minifridges. Family rooms and suites have sofabeds, and some suites add city views.There's a 24-hour fitness room and a theater-inspired dining area. A breakfast buffet is available, as is limited parking.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7561,
        store_longitude: -73.9808,
        total_orders: 119.93,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Mexican, Tacos, Burritos, Pizza, Salad, Delicious, Local, Casual',
        annual_dol: 490.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8713,
        store_longitude: -73.8481,
        total_orders: 153.93,
        neighborhood: 'Pelham Gardens/Laconia/East Bronx',
        keywords:
            'Lobster, Salad, Caribbean, Jamaican, Daiquiri, Sweet, Fun, American, Casual, Cool',
        annual_dol: 188.56,
        description:
            'Snug, informal setup serving Caribbean & American cuisine, including jerk chicken & seafood buckets.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9939,
        total_orders: 2324.07,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Elegant, Incredible, Remarkable, Rooftop, Unique',
        annual_dol: 2646.96,
        description:
            'The Manhattan Center is a building in Midtown Manhattan, New York City. Built in 1906 and located at 311 West 34th Street, it houses Manhattan Center Studios, its Grand Ballroom, and the Hammerstein Ballroom, a performance venues.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7095,
        store_longitude: -74.0127,
        total_orders: 130.09,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Coffee, Modern, Authentic, Great',
        annual_dol: 555.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7187,
        store_longitude: -73.8386,
        total_orders: 27.67,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Cuban, Wine, Caribbean, Swiss, Fun, Vibrant, Spanish, Exotic, Friendly, Fusion',
        annual_dol: 75.33,
        description:
            'Traditional Cuban fare served with cocktails in a colorful, upbeat setting with murals & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7339,
        store_longitude: -74.0064,
        total_orders: 2809.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Mexican, South American, Texan, Gluten Free, Margarita, Dessert',
        annual_dol: 2874.53,
        description:
            'Southwestern comfort food, like chili & Frito Pie, & potent drinks served up in a kitschy setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6963,
        store_longitude: -73.7615,
        total_orders: 1729.47,
        neighborhood: 'Jamaica/St. Albans',
        keywords:
            'Delicious, Magical, Savory, Amazing, Perfect, Caribbean, Great, Fusion, American, Casual',
        annual_dol: 1882.11,
        description:
            '"The Lavish Venue is your prime destination for great drinks and amazing nightlife in Saint Albans, NY. The atmosphere may draw you in, but you’ll stay for the drinks, expertly mixed by our bartenders and served at prices that will make you feel like its happy hour every hour. Our menu has something for everyone. Our menu offers various cocktails and delicious food items, including our Rasta pasta, chicken wings, shrimp, and fish fingers."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7345,
        store_longitude: -74.0092,
        total_orders: 407.54,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Lobster, Scotch, Avocado Toast, Upscale, Rich, Italian, French, Serene, Modernist',
        annual_dol: 444.16,
        description:
            "Jean-Georges & Cedric Vongerichten mix modern American flavors in Richard Meier's modernist tower.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7855,
        store_longitude: -73.9764,
        total_orders: 4380.2,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Hamburger, Sushi, Salad, Beer, Nachos, Wine, American, Ale',
        annual_dol: 4494.91,
        description:
            'Vintage Art-deco restaurant serving American bistro fare, raw bar & cocktails, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -74.0121,
        total_orders: 117.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Lobster Roll, Burger, Salad, Tasty, Organic, Romantic, Fresh, Casual, Wonderful',
        annual_dol: 479.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7521,
        store_longitude: -73.7028,
        total_orders: 68.34,
        neighborhood: 'Glen Oaks/Bellerose Manor',
        keywords: 'Mexican, American, Casual, Fresh',
        annual_dol: 209.79,
        description: '"Authentic Mexican cuisine w full bar."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6707,
        store_longitude: -73.9819,
        total_orders: 1303.34,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Salad, Beer, Ale, Margarita, Nachos, Tasty, French, Caesar Salad, American',
        annual_dol: 1422.93,
        description:
            'Mellow neighborhood hangout for beer & pub food with outdoor seating & a pool table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7664,
        store_longitude: -73.7066,
        total_orders: 455.42,
        neighborhood: '',
        keywords:
            'Shrimp Cocktail, Golfclub, Sushi, Impeccable, Sleek, Classic, Great, Asian, Fresh',
        annual_dol: 1886.11,
        description:
            '"Located on the former site of the Vanderbilt’s private golf club, The Lake Success Golf Club offers a challenging 6,414-yard, par 70 course with well-manicured fairways and impeccable greens. In addition to golf, members enjoy a first class club house, grill room, Social Activities Center, Fitness center and more. Tennis and Pool memberships are also available. We\'re truly a home away from home."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6706,
        store_longitude: -73.9915,
        total_orders: 27.51,
        neighborhood: 'Gowanus',
        keywords:
            'Wine, Beer, Delicious, Painkiller, Spicy, Impeccable, Martini, Meatball, Comfortable, Sweet',
        annual_dol: 112.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7301,
        store_longitude: -73.9567,
        total_orders: 1637.79,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Tequila, Beer, Tasty, Wine, Delicious, Bourbon, Cozy, Polish, Spacious',
        annual_dol: 1788.37,
        description:
            'Casual pub pouring pints to a crowd of locals, with a fireplace, theme nights & trivia games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.716,
        store_longitude: -74.0154,
        total_orders: 1661.78,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Italian, Pizza, Salad, Mimosa, Beer, Bloody Mary, Ample, Casual, Traditional, Great',
        annual_dol: 1803.1,
        description:
            'Easygoing choice serving ample portions of standard Italian favorites, plus old-school pizzas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7041,
        store_longitude: -73.9332,
        total_orders: 605.65,
        neighborhood: 'East Williamsburg',
        keywords:
            'Salad, Vegetarian, Wine, Iranian, Stylish, Hotel, Mediterranean, Classy, Exceptional, Great',
        annual_dol: 2475.69,
        description: '"Persian Eatery"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7395,
        store_longitude: -74.0073,
        total_orders: 3302.6,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Rooftop, Latin, Premium, Contemporary, Great, Casual, Unique',
        annual_dol: 3534.73,
        description:
            '"SNS Bar is intended to be a hub for community, great music and a landing spot for New Yorkers and all it\'s guests, who want to have a good time."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7345,
        store_longitude: -73.615,
        total_orders: 3459.59,
        neighborhood: '',
        keywords:
            'Lasagna, Italian, Spaghetti And Meatballs, Pizza, Rich, Wine, Dessert, Upscale, Bruschetta, Polish',
        annual_dol: 3550.18,
        description:
            'Semi-upscale chain for classic Italian fare served family-style in a relaxed, polished space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.733,
        store_longitude: -74.003,
        total_orders: 4415.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Burger, Tasty, Wine, Vodka, French, Delicious, Premium, Gin, Mimosa',
        annual_dol: 4579.51,
        description:
            "Two-floor bistro for dry-aged steaks & other French fare, with a bar that's strong on absinthe.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -74.0028,
        total_orders: 11806.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Intimate, Casual, Authentic, Legendary, Local, Great',
        annual_dol: 12072.5,
        description:
            'Tiny no-frills space delivers live jazz bands & jam sessions at modest prices.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7311,
        store_longitude: -74.0065,
        total_orders: 1058.62,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Beer, Martini, Bourbon, Gin, Rum, Rich, Karaoke, Stylish, Triple Sec',
        annual_dol: 1161.14,
        description:
            'A long-running lesbian hangout for drinks, shooting pool & dancing, especially on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -74.0045,
        total_orders: 317.26,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Wine, Sustainable, Seasonal, Cozy, Modern, Playful, Retro, Casual, Colorful',
        annual_dol: 331.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7177,
        store_longitude: -73.9984,
        total_orders: 63.62,
        neighborhood: 'Little Italy',
        keywords: 'Pizza, Italian, Salad, Delicious, Warm, Crisp, Clean, Retro, Authentic, Iconic',
        annual_dol: 260.05,
        description:
            'NYC-style slices are served in a simple space with narrow standing counters for eating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9102,
        total_orders: 52.97,
        neighborhood: 'Woodside',
        keywords: 'Delicious, Friendly, Fun, Ale, Cool, Great, Excellent, Casual, Local',
        annual_dol: 216.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5798,
        store_longitude: -73.9822,
        total_orders: 915.39,
        neighborhood: 'Coney Island',
        keywords:
            'Premium, Picturesque, Vibrant, Nutritious, Fantastic, Incredible, Classic, Tradition',
        annual_dol: 3741.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.646,
        store_longitude: -73.9579,
        total_orders: 706.61,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Rich, Zombie, Amazing, French, Cultural',
        annual_dol: 2888.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6762,
        store_longitude: -73.9802,
        total_orders: 3433.06,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Beer, Margarita, Martini, Spicy, Upscale, Bourbon, Nachos, Juicy, Cozy',
        annual_dol: 3480.46,
        description:
            'This bar/lounge features librarylike decor, plus 2 bocce courts & a basement performance space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6641,
        store_longitude: -73.9841,
        total_orders: 57.56,
        neighborhood: 'Park Slope',
        keywords: 'Vegetarian, Wine, Dessert, Thai, Chinese, Beer, Spicy, Cozy, Warm, Asian',
        annual_dol: 235.28,
        description:
            'Thai standards, beer & wine served in a low-key dining room with floor-to-ceiling windows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7657,
        store_longitude: -73.9703,
        total_orders: 556.54,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Salad, Delicious, Dessert, Warm, Italian, Creamy, Healthy, Mediterranean, Perfect',
        annual_dol: 572.5,
        description:
            'Mediterranean fare & a European-style social scene in a busy bistro off Madison Avenue.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7692,
        store_longitude: -73.9629,
        total_orders: 850.16,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Steak, Salad, French, Wine, Beer, Homemade, Modern, Gracious, Filet Mignon',
        annual_dol: 871.36,
        description:
            'Traditional French cooking offered in a white-tablecloth, bi-level backdrop with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.9606,
        total_orders: 262.69,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Wine, Stylish, Delicious, Beer, Italian, Irish, Fun, Hotel, Friendly, Perfect',
        annual_dol: 1073.77,
        description:
            '"A modern Irish-American sports bar serving cold beers and delicious bar food"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6544,
        store_longitude: -73.9594,
        total_orders: 31.44,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Pizza, Mexican, Beer, Salad, Margarita, Jamaican, Wine, Rum, Caribbean, American',
        annual_dol: 145.8,
        description:
            'Caribbean classics like jerk chicken & tropical cocktails are on offer at this neighborhood spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7667,
        store_longitude: -73.9125,
        total_orders: 55.26,
        neighborhood: 'Astoria',
        keywords: 'Wine, Beer, Upscale, Dessert, Premium, Great, Lit',
        annual_dol: 225.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6976,
        store_longitude: -73.9241,
        total_orders: 92.41,
        neighborhood: 'Bushwick',
        keywords: 'Coffee, Friendly, Playful, Local, Chill, Unique',
        annual_dol: 377.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6511,
        store_longitude: -73.8696,
        total_orders: 4352.74,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Steak, Pizza, Salad, Cajun, Wine, Tasty, Caesar Salad',
        annual_dol: 4451.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8263,
        store_longitude: -73.6832,
        total_orders: 137.85,
        neighborhood: '',
        keywords:
            'Pizza, Chicken Parmigiana, Mexican, Salad, Italian, Meatball, Wine, Delicious, Juicy, Warm',
        annual_dol: 150.24,
        description:
            'Stone-fired pizza, homemade pasta & seafood specials are the draw at this laid-back Italian eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8416,
        store_longitude: -73.7123,
        total_orders: 496.89,
        neighborhood: '',
        keywords:
            'Mexican, Tequila, Steak, Vegetarian, Margarita, Wine, Peruvian, Tex-Mex, Korean, Bbq',
        annual_dol: 657.82,
        description:
            '"Continuing in the fine tradition of all our Margarita\'s Cafe restaurants, we serve the best in traditional Mexican food, along with some new and unique recipes that will make your mouth water. Our exotic drinks are legendary & you\'ll enjoy your food and drink even more with our live entertainment."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.722,
        store_longitude: -74.0043,
        total_orders: 1035.88,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Vegan, Salad, Milkshake, Tequila, Gluten Free, Coffee, Roast Beef, Margarita, Vodka',
        annual_dol: 1410.16,
        description:
            'Always-open eatery at the SoHo Grand Hotel serving up revamped diner classics with vintage vibes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.9977,
        total_orders: 175.18,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Salad, Italian, Wine, Dessert, Beer, Meatball, Martini, Trendy, Delicious',
        annual_dol: 199.82,
        description:
            '"A trendy Italian restaurant & bar located at 143 Mulberry Street in the heart of historical Little Italy NYC. Handmade Lasagne, Ravioli, Manicotti, Fettucini with fresh-made pizza and a full seating bar with happy hour specials make Sofia\'s a must-visit. Under new management effective December 1, 2021 from Chef Andrea"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7927,
        store_longitude: -73.9407,
        total_orders: 2375.54,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Mexican, Beer, Cuban, Latin, American, Spanish, Delicious, Quaint, Impeccable, Casual',
        annual_dol: 2425.95,
        description:
            'Casual eatery serving Latin dishes and cocktails in a quaint space with exposed-brick walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7016,
        store_longitude: -73.927,
        total_orders: 508.42,
        neighborhood: 'Bushwick',
        keywords: 'Tequila, Hotdog, Beer, Whiskey, Vodka, Wine, Gin, Rum, Cozy, Yummy',
        annual_dol: 594.02,
        description:
            'Local watering hole turning out beer & hot dogs in a graffiti-scrawled space with kegs for tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6575,
        store_longitude: -73.6442,
        total_orders: 38.46,
        neighborhood: '',
        keywords:
            'Italian, Delicious, Friendly, Modern, Perfect, Fusion, American, Authentic, Cool, Caribbean',
        annual_dol: 157.22,
        description: '"Come experience the finest Caribbean fusion restaurant in Rockville Centre"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6095,
        store_longitude: -73.9321,
        total_orders: 452.18,
        neighborhood: 'Marine Park',
        keywords: 'Beer, Great, Terrific, Local, Casual',
        annual_dol: 471.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6552,
        store_longitude: -73.8729,
        total_orders: 432.36,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Salad, Wine, Bbq, Caribbean, Vegetarian, French Toast, Vodka, Southern Comfort, Fusion, Spicy',
        annual_dol: 529.63,
        description:
            'Relaxed, contemporary eatery & bar offering Caribbean dishes & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.581,
        store_longitude: -73.848,
        total_orders: 229.8,
        neighborhood: 'Belle Harbor/Far Rockaway/Rockaway Park',
        keywords: 'American, Beer',
        annual_dol: 331.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -73.9971,
        total_orders: 9336.25,
        neighborhood: 'Little Italy',
        keywords:
            'Champagne, Vodka, Luxurious, Upscale, Delicious, Spicy, Italian, Cozy, Premium, Sweet',
        annual_dol: 9534.35,
        description:
            'Creative cocktails & DJ music in a gold-themed space with a velvet rope out front.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7236,
        store_longitude: -74.0032,
        total_orders: 594.51,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Steak, Italian, Wine, Beer, Rustic, French Onion Soup, French, Organic, Contemporary',
        annual_dol: 611.76,
        description:
            'SoHo outpost of the Williamsburg original with rustic Italian food in a Tuscan-countryside setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -74.0048,
        total_orders: 2332.32,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Whiskey, Burger, Vodka, Margarita, Upscale, French, Japanese, American, New Zealand',
        annual_dol: 6044.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7201,
        store_longitude: -73.9985,
        total_orders: 67.01,
        neighborhood: 'Little Italy',
        keywords: 'Salad, Coffee, French, Rustic, Casual, Local, Great, Quiche, Signature',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6559,
        store_longitude: -73.6718,
        total_orders: 2825.04,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Cheesecake, Vegetarian, Wine, Tasty, Italian, Beer, Eclectic, Sleek',
        annual_dol: 5538.71,
        description:
            'Snug, modern lounge offering prime cut steaks, seafood & vegetarian menus, plus happy-hour deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8541,
        store_longitude: -73.8541,
        total_orders: 549.2,
        neighborhood: 'Morris Park/Indian Village',
        keywords:
            'Steak, Italian, Shrimp Cocktail, Flavorful, Tangy, Traditional, Perfect, Classic, Fresh, Great',
        annual_dol: 593.04,
        description:
            'Down-home pick for traditional red-sauced Italian standbys served up in hearty portions.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7647,
        store_longitude: -73.9146,
        total_orders: 79.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Upscale, Trendy, Modern, American, Fun, Amazing, Great, Latin, Fusion, Casual',
        annual_dol: 323.3,
        description: '"Upscale Bar Lounge"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9613,
        total_orders: 21.78,
        neighborhood: 'Lenox Hill',
        keywords: 'Burger, Hamburger, Pizza, Salad, Italian, Beer, Vodka, Dessert, Wine, French',
        annual_dol: 82.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.9456,
        total_orders: 167.68,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Garlic Bread, Italian, Wine, Crisp, Romantic, Classy, Clean, Outstanding, American, Classic',
        annual_dol: 513.72,
        description:
            'High-end place offering classic Italian dishes & pastas (with gluten-free choices), plus a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -73.9382,
        total_orders: 225.49,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Beer, Mexican, Tomato Soup, Wine, Meatball, Italian, American, Seasonal, Classic, Southern',
        annual_dol: 394.42,
        description:
            'Small, casual restaurant pairing cheese-laden flights & mains with wines & craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7388,
        store_longitude: -73.9885,
        total_orders: 1473.94,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Japanese, Wine, Italian, Classy, High-End, Sukiyaki, Authentic, Impressive, Casual',
        annual_dol: 4245.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7346,
        store_longitude: -73.992,
        total_orders: 75.65,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Thai, Tasty, Delicious, Martini, Prosecco, Fresh, Casual, Mojito, Pad Thai, Great',
        annual_dol: 285.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -73.9895,
        total_orders: 74.61,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Lasagna, Italian, Wine, Classy, Friendly, Traditional, Authentic, Casual, Great, Negroni',
        annual_dol: 304.99,
        description:
            'Cozy, cash-only Italian restaurant in the East Village lined with exposed brick.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5518,
        store_longitude: -74.1508,
        total_orders: 15.23,
        neighborhood: 'Great Kills',
        keywords:
            'Steak, Tacos, Mexican, Tex-Mex, Italian, Spanish, Delicious, Asian, Authentic, Casual',
        annual_dol: 69.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6396,
        store_longitude: -73.9676,
        total_orders: 431.95,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Hamburger, Wine, Coffee, Dessert, Italian, Rustic, Peruvian, Seasonal, Delicious',
        annual_dol: 757.3,
        description:
            'New American dishes cooked up from fresh, local ingredients in a rustic setting with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.643,
        store_longitude: -73.791,
        total_orders: 377.83,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Pizza, Coffee, Turkish, American, Local, Casual',
        annual_dol: 1323.28,
        description:
            '"The Local, an original concept from HMSHost, was designed specifically for airports and marries the freshest ingredients from local farms for menus loaded with region-centric culinary creations. The Local offers both full-service and grab-and-go convenience. It embraces only the finest ingredients and décor to help create an atmosphere unlike any other that gives travelers a true taste of what each region has to offer."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7533,
        store_longitude: -73.974,
        total_orders: 2569.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Tasty, Yummy, Intimate, Casual, Great, Local, Chill',
        annual_dol: 2624.39,
        description:
            'Gay bar near Grand Central known for its outdoor terrace & separate piano bar, plus weekly events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9711,
        total_orders: 208.55,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Italian, Beer, Trendy, Iconic, Classic, Contemporary, Great, Fine',
        annual_dol: 218.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9741,
        total_orders: 69.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Steak, Pizza, Burritos, Delicious, Local, Casual, Great',
        annual_dol: 345.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9672,
        total_orders: 1498.77,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Tacos, Vodka, Sushi, Mexican, Wine, Hamburger, Salad, Delicious, Warm, Fun',
        annual_dol: 1637.1,
        description:
            'This busy pub with private event spaces is part sleek lounge & part burgers-&-wings sports bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8754,
        store_longitude: -73.597,
        total_orders: 2819.38,
        neighborhood: '',
        keywords:
            'Chocolate Croissant, Coffee, Delicious, Comfortable, Avocado Toast, Perfect, Wonderful, Great, Homemade, American',
        annual_dol: 4602.32,
        description:
            '"Good Karma. Great Coffee. Quality Connections. Obsessively curated to serve our community. Farm to cup coffee by Devocion."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.713,
        store_longitude: -74.0097,
        total_orders: 1434.74,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Tacos, Pizza, Champagne, Hotel, Irish, Perfect, Casual, American, Intimate',
        annual_dol: 1953.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6285,
        store_longitude: -74.0292,
        total_orders: 21.08,
        neighborhood: 'Bay Ridge',
        keywords: 'Pizza, Chicken Parmigiana, Italian, Salad, Polished, Perfect, Casual',
        annual_dol: 79.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7172,
        store_longitude: -73.9017,
        total_orders: 98.11,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Beer, Trendy, Wine, Mexican, Margarita, Fresh, Irish, American, Great, Swiss',
        annual_dol: 109.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7772,
        store_longitude: -73.7276,
        total_orders: 4242.94,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Vegetarian, Shrimp Cocktail, Salad, Delicious, American, Prime Rib, Pot Roast, Caesar Salad',
        annual_dol: 4354.77,
        description:
            'High-quality beef is the focus of this branch of the classic Brooklyn steakhouse. Cash-only.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.99,
        total_orders: 1468.41,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Burger, Salad, Wine, Beer, Ale, Elegant, Polished, Irish, Friendly, Casual',
        annual_dol: 1506.31,
        description:
            'Polished sports tavern with lots of TV screens & a menu of elevated American pub food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6921,
        store_longitude: -73.9866,
        total_orders: 843.43,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Burger, Shrimp Cocktail, Coffee, Wine, American, Sleek, Spacious, Casual, Classic, Great',
        annual_dol: 3447.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.9838,
        total_orders: 994.96,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Irish, Beer, Delicious, Warm, Premium, High-End, Creamy, Classic, Refined',
        annual_dol: 1091.98,
        description:
            "from the Museum of Modern Art.Elegant rooms with chic furnishings offer free Wi-Fi, flat-screen TVs and en suite, shower-only bathrooms, plus designer toiletries and safes. Studios add sofabeds and sleep up to 3 guests.There's a refined restaurant/bar, a fitness center and conference facilities. Parking and airport transfers are available for a fee.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9842,
        total_orders: 1713.67,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Cheesecake, Lobster, Lobster Roll, Salad, Steak, Dessert, Hamburger, Tasty, Italian, Creamy',
        annual_dol: 1753.19,
        description:
            'French fare, including fixed-price meals, in elegant digs with tile floors & red banquettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.775,
        store_longitude: -73.9135,
        total_orders: 2931.22,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Sushi, Salad, Wine, Japanese, Beer, Karaoke, Chinese, Spicy, Thai, Rich Finish',
        annual_dol: 5528.93,
        description:
            'Basic spot featuring a variety of sushi-roll choices, lunch specials & happy-hour beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7112,
        store_longitude: -74.0146,
        total_orders: 466.96,
        neighborhood: 'Lower Manhattan',
        keywords: 'American',
        annual_dol: 532.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7935,
        store_longitude: -73.6914,
        total_orders: 145.01,
        neighborhood: '',
        keywords: 'Italian, Wine, Pizza, Trendy, Beer, Delicious, Casual, Friendly, Fresh',
        annual_dol: 171.83,
        description:
            '"Chef-owner Sandro Acquista brings simple, quality Italian food to this casual, trendy neighborhood spot in Manhasset. The pastas are made fresh daily, the desserts are made from scratch and everything is made with the highest quality ingredients."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -74.0025,
        total_orders: 20.13,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Iranian, Moroccan, Prime Rib, Casual',
        annual_dol: 82.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7169,
        store_longitude: -74.0079,
        total_orders: 4038.7,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Wine, Gluten Free, Dessert, Italian, Upscale, Beer, French Onion Soup, French, Ale',
        annual_dol: 4131.61,
        description:
            'Lively bistro with outdoor seating serving French-American food, plus brunch & late-night bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7723,
        store_longitude: -73.5627,
        total_orders: 1323.44,
        neighborhood: '',
        keywords: 'Beer, Comfortable, Spacious, Friendly, Eclectic, Great, Traditional, Fusion',
        annual_dol: 1424.12,
        description:
            'The NYCB Theatre at Westbury is an entertainment venue located in the hamlet of Jericho, outside of Westbury, New York.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.5877,
        total_orders: 251.25,
        neighborhood: '',
        keywords:
            'Salad, Shrimp Cocktail, Hotdog, Italian, French Onion Soup, French, Creole, Greek, Homemade, Caribbean',
        annual_dol: 1027.01,
        description:
            'Elegant Caribbean eatery & catering hall offering Sunday brunch as well as tango & salsa nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -74.01,
        total_orders: 261.75,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, French, Beer, Luxurious, Japanese, Relaxed, Zen, Legendary, Casual, Perfect',
        annual_dol: 1069.97,
        description:
            '"l’abeille is a 54 seat French restaurant located at 412 Greenwich Street on a classic Tribeca cobblestone street corner. The restaurant is centered on a six-course prix-fixe menu but also provides a rotating selection of seasonal a-la-carte dishes with a strong wine and cocktail program. l’abeille is the brainchild of Chef Mitsunobu Nagae, a veteran Michelin-starred chef who has worked at multiple legendary restaurants in Tokyo, Paris, and New York. Chef Mitsu was most recently the Chef de Cuisine at Shun in Midtown Manhattan."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -74.0002,
        total_orders: 487.95,
        neighborhood: 'Lower Manhattan',
        keywords: 'Wine, Upscale, Elegant, Trendy, Delicious, Romantic, Cozy, Warm, Hotel, French',
        annual_dol: 562.25,
        description:
            'Refined French fare, such as lobster, rabbit, duck & foie gras, served in an airy, elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7215,
        store_longitude: -74.0063,
        total_orders: 4731.92,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Delicious, Trendy, Awesome, Premium, Great, Rooftop, Hip, Cool, Attentive',
        annual_dol: 5276.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7046,
        store_longitude: -74.0098,
        total_orders: 2533.69,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Vegetarian, Lobster, Pizza, Salad, Shrimp Cocktail, Italian, Rich, Wine',
        annual_dol: 2757.74,
        description:
            'Popular throwback in the historical India House with steaks, a wine cellar & an adjoining cafe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.6469,
        total_orders: 939.25,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Wine, Peruvian, Warm, Classy, Southern, Exquisite, Casual',
        annual_dol: 939.25,
        description:
            'No-nonsense but popular Italian trattoria serving up a large menu of family-style platters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6574,
        store_longitude: -73.6469,
        total_orders: 3148.18,
        neighborhood: '',
        keywords: 'Salad, Sushi, Mexican, Japanese, Tangy, Ramen, Bbq, Modern, Yummy, Casual',
        annual_dol: 4904.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.998,
        total_orders: 4276.79,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Salad, Italian, Mediterranean, Cool, Casual, Great, Recommended, Excellent',
        annual_dol: 5483.71,
        description:
            'Richly designed space for Mediterranean crudos, pastas & roasted dishes, with a cafe & bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.9926,
        total_orders: 320.96,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Relaxed, Dynamic',
        annual_dol: 476.02,
        description:
            "Bright, relaxed rooms offer free Wi-Fi, flat-screen TVs and coffeemakers.There's an informal restaurant with a bar. Other amenities include a lobby lounge and a terrace, as well as a 24-hour gym, a business center and meeting space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.6142,
        total_orders: 2098.76,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Salad, Cajun, Italian, Beer, Caesar Salad, American, Kung Pao, Warm',
        annual_dol: 2152.46,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7434,
        store_longitude: -73.6148,
        total_orders: 863.72,
        neighborhood: '',
        keywords: 'Pizza, Italian, Vodka, Perfect, Fresh, Great, Casual, Fine, Local',
        annual_dol: 883.5,
        description:
            'A Manhattan Little Italy transplant & bustling old-school spot for fresh seafood & red-sauce pasta.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7465,
        store_longitude: -73.9187,
        total_orders: 1070.06,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Burger, Pizza, Beer, Wine, Dessert, American, Upscale, Bbq, Delicious, Quaint',
        annual_dol: 1096.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -74.0084,
        total_orders: 4546.24,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Buttermilk Biscuits, Salad, Organic, American, Delicious, Casual, Perfect, Traditional, Classic',
        annual_dol: 6800.68,
        description:
            'Weekend brunch hot spot serving homestyle American eats with many locally sourced ingredients.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -74.0101,
        total_orders: 545.68,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Chocolate Cake, Cheesecake, Chicken Parmigiana, Gluten Free, Italian, Apple Pie, Tasty, Velvety, Veal Parmigiana, Impeccable',
        annual_dol: 557.26,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -74.0081,
        total_orders: 172.74,
        neighborhood: 'Hudson Square',
        keywords: 'Fun, Sweet, Great, Fresh, Artisanal, Fine, Casual, Signature',
        annual_dol: 176.4,
        description: '"Look for the chocolate..one bar will lead you to another!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7251,
        store_longitude: -74.0079,
        total_orders: 144.77,
        neighborhood: 'Hudson Square',
        keywords:
            'Burger, Hamburger, Pizza, Cheesesteak, Salad, Beer, Juicy, Asian, American, Casual',
        annual_dol: 208.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.708,
        store_longitude: -73.9216,
        total_orders: 1067.05,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Dessert, Karaoke, Chicken Wings, Fun, American, Indulgent, Awesome, Classic, Great',
        annual_dol: 1185.44,
        description:
            'Laid-back restaurant & bar specializing in chicken wings & burgers with fries, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.654,
        store_longitude: -73.6715,
        total_orders: 301.74,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Wine, Delicious, Homemade, Great, Local, Casual, Fine, Signature',
        annual_dol: 314.82,
        description:
            '"At Savino’s, all our dishes are homemade and prepared with the freshest quality meats, fish, produce and ingredients imported from Italy. Try one of our many specialty pizza’s, appetizers and entrees prepared by the most qualified kitchen staff in the neighborhood. We cater parties of all sizes in our dining room and for delivery anywhere in the local neighborhood. Our pre-designed menus allow you to plan delicious dining for your event. Simply tell us how many people you’re expecting and we’ll handle the rest. Live entertainment is available almost every week. See our calendar to find out who’s playing on Thursday and/or Saturday nights. Savino’s hires the best performers on Long Island to deliver a performance of the highest quality."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5738,
        store_longitude: -74.1157,
        total_orders: 394.79,
        neighborhood: 'New Dorp',
        keywords:
            'Salad, Italian, Wine, Shrimp Cocktail, Coffee, Delicious, Spicy, Upscale, Rich, Elegant',
        annual_dol: 403.16,
        description:
            'Italian standards & an extensive wine list are served in an elegant setting at this longtime eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7235,
        store_longitude: -73.5703,
        total_orders: 887.78,
        neighborhood: '',
        keywords: 'Beer, Burger, Whiskey, Cajun, Wine, Ale, Spicy, Korean, Dessert, Bbq',
        annual_dol: 906.62,
        description:
            'Sports-bar chain with a casual menu of burgers, ribs & a variety of brews, plus plenty of TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.613,
        store_longitude: -73.9125,
        total_orders: 155.92,
        neighborhood: 'Mill Basin',
        keywords: 'Margarita, Clean, Fun, Bbq, Authentic, Modern, Great, Casual, Bold',
        annual_dol: 159.23,
        description:
            'Family-operated bowling alley offers various arcade games, a full-service snack bar & private rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7459,
        store_longitude: -73.6,
        total_orders: 912.58,
        neighborhood: '',
        keywords:
            'Sushi, Vodka, Salad, Martini, Gluten Free, Coffee, Tasty, Japanese, Sashimi, Friendly',
        annual_dol: 1033.22,
        description: 'Longtime Japanese sushi & hibachi house with kid-friendly chef performances.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6194,
        store_longitude: -73.9331,
        total_orders: 30.36,
        neighborhood: 'Flatlands',
        keywords: 'Beer, Comfortable, Wine, Friendly, Casual, Local, American, Great',
        annual_dol: 82.82,
        description:
            'Local hangout featuring sports on TV, darts tournaments & a familiar lineup of beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6498,
        store_longitude: -73.8709,
        total_orders: 3965.61,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Salad, Steak, Hamburger, Chicken Caesar Salad, Dessert, Italian, Beer, New-York-Style, Mediterranean',
        annual_dol: 4061.09,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -74.0036,
        total_orders: 563.59,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Mexican, Tequila, Margarita, Wine, Rustic, Classic, Fun, Latin, Authentic, Traditional',
        annual_dol: 574.4,
        description:
            'This casual, rustic cantina delivers classic Mexican fare & potent margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -74.0102,
        total_orders: 2069.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Italian, Salad, Beer, Wine, Delicious, Caesar Salad, Homemade, Fresh, Authentic',
        annual_dol: 2635.89,
        description:
            'Casual pizzeria with outdoor seating on the historic, cobbled Stone Street offering thin-crust pies.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6604,
        store_longitude: -73.8404,
        total_orders: 749.83,
        neighborhood: 'Howard Beach',
        keywords:
            'Vegetarian, Pizza, Italian, Wine, Rustic, Southern, Casual, Polished, Classy, Contemporary',
        annual_dol: 782.53,
        description:
            'Classy Italian eatery with brick-oven pies, pasta dishes, a curated wine list & contemporary decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7576,
        store_longitude: -73.6398,
        total_orders: 596.09,
        neighborhood: '',
        keywords:
            'Wine, Trendy, Italian, American, Chicken Wings, Classy, Comfortable, Amazing, Great, Fresh',
        annual_dol: 621.94,
        description:
            '"Willistons\' is a restaurant and bar now open in Williston Park, NY. Come in to enjoy our creative menus, inspired cocktails and well planned collection of wines and craft beers."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7106,
        store_longitude: -73.8506,
        total_orders: 209.09,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Korean, Beer, Bbq, Asian, Clean, Fried Chicken, Natural, Premium, Fresh, Casual',
        annual_dol: 732.32,
        description:
            '"Pelicana Chicken prepares FRESH, ALL-NATURAL, NON-GMO, 100% VEGGIE-FED, USDA VERIFIED, CAGE-FREE chickens in FRESH, NEW OIL in a clean sanitized kitchen, DAILY! Our chickens are cleaned, cut, and seasoned daily to deliver the freshest, juiciest, and tastiest chicken to our customers. In addition, we offer 50 + variations of chicken, so that our customers can taste a variety and have plenty to choose from."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6108,
        store_longitude: -73.9628,
        total_orders: 8.74,
        neighborhood: 'Midwood',
        keywords: 'Wine, Beer, Hotel, Legendary, Rare, Casual, Incredible',
        annual_dol: 35.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9829,
        total_orders: 955.44,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Steak, Pizza, Salad, Tasty, Italian, Wine, Delicious, Stylish, Vodka',
        annual_dol: 2596.57,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6609,
        store_longitude: -73.629,
        total_orders: 435.95,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Vegan, Beer, Bbq, Tasty, American, Contemporary, Friendly, Casual',
        annual_dol: 445.43,
        description:
            'Stylish, contemporary restaurant serving burgers, rotisserie fare, cocktails & craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -73.535,
        total_orders: 28.33,
        neighborhood: '',
        keywords: 'American',
        annual_dol: 115.8,
        description:
            '"LAST VETERAN HALL OF EAST MEADOW, FOR HONORABLY DISCHARGED VETERANS AND THEIR FAMILIES; PUBLIC CAN RENT OUR HALL GIVE A CALL TO OIR NUMBER FOR MORE DETAILS AND RESERVE YOUR DATE NEEDS"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7063,
        store_longitude: -73.9228,
        total_orders: 748.88,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Vodka, Beer, Margarita, Signature, Great, Classic, Casual',
        annual_dol: 1268.24,
        description:
            'Patrons line up into the wee hours to snag signature artichoke pizza from this counter-service spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6994,
        store_longitude: -73.9138,
        total_orders: 214.73,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Coffee, Beer, American, Friendly, Hip, Cool, Casual, Fusion, Local',
        annual_dol: 250.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7293,
        store_longitude: -73.6357,
        total_orders: 900.7,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, Homemade, Classy, Retro, Sweet, Fantastic, Perfect',
        annual_dol: 965.98,
        description:
            'Casual, Brooklyn-based pizzeria chain serving brick-oven pies & calzones, plus wine & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.6401,
        total_orders: 58.15,
        neighborhood: '',
        keywords: 'Tacos, Steak, Mexican, Beer, Nachos, Wine, Trendy, American, Homemade, Latin',
        annual_dol: 149.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7078,
        store_longitude: -73.9318,
        total_orders: 145.49,
        neighborhood: 'East Williamsburg',
        keywords:
            'Mexican, Margarita, Nachos, Upscale, Spicy, Delicious, Cozy, Yummy, Paloma, Classy',
        annual_dol: 511.95,
        description:
            '"Bushwick Newcomer Paloma’s BK Offers a Bold New Regional Mexican Menu Expect dreamy tortillas, regional tacos, aguachiles, and so much more"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -73.6785,
        total_orders: 105.65,
        neighborhood: '',
        keywords:
            'Italian, Steak, Pizza, Upscale, Savory, Cozy, Exquisite, Authentic, Impressive, Exceptional',
        annual_dol: 225.19,
        description:
            'Family-owned restaurant open more than 30 years serves Italian cuisine in an intimate, cozy space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6536,
        store_longitude: -73.5551,
        total_orders: 101.36,
        neighborhood: '',
        keywords:
            'Burger, Steak, Salad, Italian, Beer, Wine, Margarita, Delicious, Bourbon, Homemade',
        annual_dol: 236.66,
        description:
            '"One of the best Italian American restaurants in Merrick that serves fresh ingredients and homemade desserts coupled with fine wines and crafted mixed drinks. All in effort to make your dinning experience one to be remembered."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8395,
        store_longitude: -73.8636,
        total_orders: 63.62,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Dominican, Mexican, Bbq, Caribbean, Chinese, Puerto Rican, American, Latin, Delicious, Traditional',
        annual_dol: 260.05,
        description:
            'Colorful, family-owned eatery for traditional style Dominican plates in low-key surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9843,
        total_orders: 1616.02,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Korean, Salad, Upscale, Bbq, Delicious, Contemporary, Modern, Classy, Legendary, Traditional',
        annual_dol: 1665.96,
        description:
            'Sophisticated spot for Korean BBQ, shareable plates & upscale mains, plus bar snacks & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8365,
        store_longitude: -73.7018,
        total_orders: 247.49,
        neighborhood: '',
        keywords: 'Italian, Steak, Pizza, Upscale, Cozy, Intimate, Excellent, Great, Local, Fine',
        annual_dol: 252.74,
        description:
            'Upscale fixture draws crowds with Northern Italian classics & an extensive list of daily specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7464,
        store_longitude: -73.9178,
        total_orders: 8.8,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Hotel, Irish, Fancy, Fun, Clean, Classic, Wonderful, Cool, Great, Traditional',
        annual_dol: 35.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7966,
        store_longitude: -73.92,
        total_orders: 445.37,
        neighborhood: 'Randalls Island',
        keywords: 'Vegan, American, Urban, Local, Fresh, Spectacular, Great, Tradition',
        annual_dol: 1820.54,
        description:
            '480-acre urban park features restored wetlands, 90+ athletic fields, a community farm & recreation.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8191,
        store_longitude: -73.9268,
        total_orders: 3561.74,
        neighborhood: 'West Bronx',
        keywords:
            'Cuban, Wine, Delicious, Spacious, Fun, Spanish, Caribbean, Vibrant, Amazing, Colorful',
        annual_dol: 3968.81,
        description:
            'A menu featuring classic Cuban & Latin fare in a modern setting with ample bar & patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6518,
        store_longitude: -73.8378,
        total_orders: 2334.21,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Sushi, Pizza, Salad, Mexican, Shrimp Cocktail, Beer, Japanese, Chinese, Asian, Fusion',
        annual_dol: 2499.53,
        description:
            'Hibachi dinners & Asian-fusion bites are served in a lively waterfront bistro, lounge & sushi bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9863,
        total_orders: 496.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Salad, Wine, Dessert, Beer, Japanese, Whiskey, Spanish, Spicy, Delicious',
        annual_dol: 715.1,
        description:
            'Asian fusion joint in stylish digs serving creative sushi rolls, Vietnamese noodles & bento lunches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7768,
        store_longitude: -73.9523,
        total_orders: 1434.32,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Cheesecake, Tacos, Canadian, Spicy, New-York-Style, Italian, Funky, Warm, English Muffin, Friendly',
        annual_dol: 1616.27,
        description: '"Modern American Tavern"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7333,
        store_longitude: -73.737,
        total_orders: 35.27,
        neighborhood: 'Jamaica/Bellerose Manor/Queens Village',
        keywords:
            'Burger, Tacos, Cheesesteak, Mexican, Wine, Clean, Bbq, Friendly, Philly Cheesesteak, American',
        annual_dol: 37.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.656,
        store_longitude: -74.0068,
        total_orders: 2805.55,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Sushi, Salad, Japanese, Delicious, Trendy, Thai, Sashimi, Romantic, Ramen, Fun',
        annual_dol: 3808.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7867,
        store_longitude: -73.9505,
        total_orders: 388.2,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Hotdog, Beer, Wine, Rum, Irish, Hotel, Modern, Campari, Comfortable, Local',
        annual_dol: 405.03,
        description:
            '"A modern pub serving local New York beer, cocktails, and wine in a comfortable setting, with small bites."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -74.0013,
        total_orders: 1056.42,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Steak, Chicken Parmigiana, Meatball Sub, Meatball, Italian, Salad, Wine, Spicy, Beer, Caesar Wrap',
        annual_dol: 1078.83,
        description:
            'Traditional Italian fare & craft cocktails offered in an exposed-brick dining room with a sky light.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7964,
        store_longitude: -73.9366,
        total_orders: 404.98,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Jamaican, Wine, Rum, Hip, Lit, Creative, Cool, Casual',
        annual_dol: 1486.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7768,
        store_longitude: -73.957,
        total_orders: 275.06,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Tasty, Romantic, French, Classy, Polished, Friendly, Homemade, Casual, Classic',
        annual_dol: 355.32,
        description:
            'Swanky restaurant featuring classic French staples & wine in upmarket surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6542,
        store_longitude: -73.953,
        total_orders: 11.68,
        neighborhood: 'Little Haiti',
        keywords: 'Vegan, Tequila, Tacos, Mexican, Steak, Clean, Trendy, Casual, Latin, American',
        annual_dol: 47.74,
        description: '"A Taco & Tequila Bar In Flatbush Paying Homage To Afro-Mexicans."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6746,
        store_longitude: -74.0007,
        total_orders: 341.9,
        neighborhood: 'Red Hook',
        keywords: 'Pizza, Meatball, Spicy, Wine, Modern, Classy, Fun, Seasonal, Local, Great',
        annual_dol: 508.09,
        description:
            'Brick-&-mortar offshoot of mobile eatery, serving Neapolitan-style pies, small plates & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5873,
        store_longitude: -73.7394,
        total_orders: 177.9,
        neighborhood: '',
        keywords: 'Burger, Salad, French, Fun, Friendly, Perfect, Terrific, Exceptional',
        annual_dol: 348.79,
        description:
            '""Decades of fun in the sun have taken place at Sunny Atlantic Beach Club. Our event venue, Atrium is perfect for a sunset wedding on the beach or exceptional family celebration. Long Island’s only retractable glass enclosure facing the Atlantic Ocean insures your experience continues in comfort while you enjoy a perfect view of the endless Atlantic Ocean.""',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6356,
        store_longitude: -74.1352,
        total_orders: 5.44,
        neighborhood: 'Port Richmond',
        keywords: 'Mexican, Turkish, Delicious, Premium, Casual',
        annual_dol: 22.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.675,
        store_longitude: -73.9992,
        total_orders: 516.44,
        neighborhood: 'Carroll Gardens',
        keywords: 'Wine, Beer, Vodka, Coffee, Gin, Trendy, Delicious, Prosecco, Casual, Fresh',
        annual_dol: 842.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6372,
        store_longitude: -73.929,
        total_orders: 1595.71,
        neighborhood: 'East Flatbush',
        keywords: 'Lobster, Salad, Jamaican, Caribbean, Bbq, Contemporary, Sweet, Local, Casual',
        annual_dol: 3401.14,
        description:
            'Jamaican-style seafood boils & platters, plus salads & sides, presented in a contemporary space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7235,
        store_longitude: -73.9885,
        total_orders: 1283.13,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Luxurious, Beer, Rum, Lively, Premium, Clean, Great, Hip, Fusion',
        annual_dol: 1313.01,
        description:
            'DJ-spun tunes & happy-hour deals keep patrons content at this longtime lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6435,
        store_longitude: -73.9427,
        total_orders: 38.52,
        neighborhood: 'Little Caribbean/East Flatbush',
        keywords: 'Tasty, Margarita, Savory, Delicious, Spicy, Creole, Caribbean, Casual',
        annual_dol: 104.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5443,
        store_longitude: -74.1407,
        total_orders: 1928.18,
        neighborhood: 'Great Kills',
        keywords:
            'Lobster, Wine, Vodka, Gluten Free, Spicy, Italian, Upscale, Picturesque, American, Perfect',
        annual_dol: 2098.65,
        description:
            'Iconic restaurant, bar & banquet hall with scenic views serving upscale Italian & American meals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5848,
        store_longitude: -74.0936,
        total_orders: 378.48,
        neighborhood: 'Midland Beach',
        keywords:
            'Mexican, Meatball, Delicious, Asian, Colorful, Authentic, Casual, Local, Attentive, Fine',
        annual_dol: 535.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -73.9871,
        total_orders: 204.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Beef Stroganoff, Shrimp Cocktail, Dessert, Wine, Warm, Homemade, Casual, Fresh, Great',
        annual_dol: 835.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.7692,
        total_orders: 2805.04,
        neighborhood: 'Bayside/Flushing',
        keywords: 'Steak, Hamburger, Salad, Wine, Dessert, Beer, Ale, Caesar Salad, Sleek, Modern',
        annual_dol: 2871.02,
        description:
            'Modern pool hall with 21 tables & a sleek lounge vibe with couches, a fire pit & comfort food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.9675,
        total_orders: 96.8,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Shrimp Cocktail, Pizza, Italian, Beer, Veal Parmigiana, Southern, Casual, Exquisite, Fresh',
        annual_dol: 395.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6299,
        store_longitude: -74.0286,
        total_orders: 34.87,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Wine, Cozy, Friendly, Perfect, Great, Local, Casual, Chill',
        annual_dol: 46.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.9614,
        total_orders: 652.57,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Salad, Italian, Wine, Martini, Crisp, Friendly, Latin, Seasonal, Caesar Salad, Fun',
        annual_dol: 748.17,
        description:
            'Inventive Italian cafe with small & large plates, vibrant Pop Art decor & a patio with bridge views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9681,
        total_orders: 742.31,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Lobster, Sushi, Steak, Japanese, Spicy, Modern, Premium, Local, Casual',
        annual_dol: 3034.33,
        description:
            '"Wagyu Social is created by a team of NYC Wagyu Lovers and chefs, working with importers of the best Japanese Wagyu beef. It is our mission to bring the best authentic Japanese A5 Wagyu burgers to New York City, the best city in the world. Long live NYC, long live Wagyu."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9719,
        total_orders: 1309.23,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Upscale, Stylish, Hotel, Elegant, Spacious, Cozy, Clean, Premium, Gin, Great',
        annual_dol: 1884.3,
        description:
            'Decorated in conservative colors, upscale rooms and suites have custom pillows, marble baths, kitchenettes and 24-hour room service, plus flat-screen TVs, iPod docks and Wi-Fi; some suites have private outdoor terraces.A stylish restaurant serves a modern bistro menu. There’s a 24-hour gym and a salon, as well as a business center and a clubby lounge. Valet parking is available for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7407,
        store_longitude: -73.982,
        total_orders: 641.75,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Burger, Beer, Tasty, Wine, Delicious, Ale, Irish, Homemade, American, Friendly',
        annual_dol: 686.45,
        description:
            'Genial Irish pub offering casual American & Irish food, including brunch, plus TV sports & trivia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6902,
        store_longitude: -73.954,
        total_orders: 2586.31,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vegetarian, Perfect, Casual, American, Local',
        annual_dol: 10572,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6907,
        store_longitude: -73.9695,
        total_orders: 1473.42,
        neighborhood: 'Clinton Hill',
        keywords:
            'Pizza, Italian, Sushi, Japanese, Wine, Colorful, Friendly, Fresh, Casual, Classic',
        annual_dol: 2064.18,
        description:
            'Pizza from a wood-burning brick oven plus other Italian classics at s family-owned spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9897,
        total_orders: 1253.42,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Champagne, Spicy, Exhilarating, Phenomenal, Fun, Exotic, Classy, Premium, Memorable, French',
        annual_dol: 1361.15,
        description:
            '"Over three decades ago… FlashDancers created a sensation by introducing the scintillating LAP DANCE to New York City. Today, we continue to provide pulse-pounding entertainment at the crossroads of the world, rightfully earning our reputation as the most popular, exhilarating adult gentlemen\'s club in NYC. The secret to our success: the most beautiful and friendly entertainers in Manhattan, an incredible VIP waitstaff and a phenomenal management team. FlashDancers prides itself on our consistency and reputation as the #1 gentlemen\'s club in New York and one of the most famous strip clubs in the world."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7524,
        store_longitude: -73.9996,
        total_orders: 987.77,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Coffee, Nutritious, Delicious, Australian, Healthy, Premium, Welcoming, Natural, Fresh',
        annual_dol: 3224.85,
        description:
            '"Bluestone Lane is an Australian-inspired coffee shop, café & lifestyle brand committed to providing a genuine daily escape for all our locals. We offer premium coffee and healthy and delicious eats through an always welcoming experience that embraces our ‘Aussie’ approach to life."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7535,
        store_longitude: -73.9999,
        total_orders: 2539.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Wine, Dessert, Upscale, Premium, American, Classic, Modern, Sweet, Approachable',
        annual_dol: 3167.48,
        description:
            'A modern American menu is served in an upscale setting with an open kitchen & curated artwork.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7487,
        store_longitude: -73.987,
        total_orders: 614.75,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Vegetarian, Chinese, Wine, Beer, Peking Duck, Bbq, Delicious, Creamy, Sweet, Traditional',
        annual_dol: 1584.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6568,
        store_longitude: -73.6454,
        total_orders: 126.69,
        neighborhood: '',
        keywords:
            'Garlic Bread, Salad, Pizza, Italian, Delicious, Rich, Homemade, Southern, Casual, Perfect',
        annual_dol: 143.6,
        description:
            'Italian restaurant with red-checkered tablecloths serving pasta, panini & pizza made in a coal oven.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7252,
        store_longitude: -73.5869,
        total_orders: 1648.46,
        neighborhood: '',
        keywords:
            'Hotel, Luxurious, Steak, Comfortable, Beer, Clean, Amazing, Great, Fantastic, Casual',
        annual_dol: 4035.57,
        description:
            "Unfussy rooms feature Wi-Fi access, flat-screen TVs, desks and coffeemakers; some have access to a lounge with free continental breakfast, snacks and drinks.Parking is provided at no additional cost. There's a casual restaurant, a lobby lounge and a sports bar. There's also an indoor pool, a spa, racquetball courts and a 24/7 fitness center. Other amenities consist of 17 meeting rooms, a business center and guest laundry facilities.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7051,
        store_longitude: -74.0052,
        total_orders: 3210.87,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Lobster, Steak, Vegetarian, Salad, Italian, Gluten Free, American, Delicious, Modern',
        annual_dol: 3860.71,
        description:
            'Industrial-chic New American restaurant offering wood-fired pizzas & mains, plus a river view.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.616,
        store_longitude: -73.9298,
        total_orders: 47.25,
        neighborhood: 'Marine Park/Flatlands',
        keywords:
            'Vegetarian, Vegan, Chinese, Wine, Bbq, Casual, Friendly, Asian, Yummy, Excellent',
        annual_dol: 193.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.6187,
        total_orders: 191.64,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Gourmet, Beer, Whiskey, Tasty, Dessert, American, Delicious',
        annual_dol: 783.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6284,
        store_longitude: -74.0742,
        total_orders: 445.54,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Steak, Pizza, Whiskey, Wine, Italian, Phenomenal, Crisp, American, Perfect, Innovative',
        annual_dol: 606.52,
        description:
            '"Brought to you by the Rallo Hospitality Group, Navy Pier brings elevated New American cuisine to a space unlike any other on Staten Island. From our carefully curated menu, developed by Michelin Starred Executive Chef, Josh Laurano; to our innovative cocktail and spirits program; to our sweeping views of the Verrazano Bridge, New York Harbor, the Statue of Liberty, Manhattan & Jersey City, your experience here will be one to remember."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5943,
        store_longitude: -74.0988,
        total_orders: 131.21,
        neighborhood: 'Dongan Hills/Todt Hill',
        keywords:
            'Sushi, Salad, Spicy, French Toast, Japanese, French, Rich, Ramen, Friendly, Casual',
        annual_dol: 536.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.9874,
        total_orders: 2961.15,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Hamburger, Cheesesteak, Beer, American, Nachos, Wine, Korean, Bbq',
        annual_dol: 14462.75,
        description:
            'High-end sports-bar chain with a huge menu of New American fare & an extensive list of draft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7546,
        store_longitude: -73.9897,
        total_orders: 1408.42,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Tequila, Steak, Beer, Cheesesteak, Gin, Irish, American, Homey, Warm, Classic',
        annual_dol: 1491.01,
        description:
            '"Prohibition 39 - NYC\'s Newest American Restaurant Concept featuring 1st Floor Dining, Bar & Lounge, 2nd Floor Event, Performance Space, Restaurant Lounge & Private Dining & Rooftop Speakeasy & Dining Experience. Opening Late August 2022, visit our site to sign up for updates, exclusive invitations to the pre-opening gala & information"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.993,
        total_orders: 3281.84,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Salad, Beer, Irish, Warm, Welcoming, Casual, Classic, Traditional, Local',
        annual_dol: 3351.48,
        description:
            'Drinks & comfort food in a spacious, bi-level tavern with a warm, traditional atmosphere & 3 bars.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6504,
        store_longitude: -73.8704,
        total_orders: 1474.84,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Chocolate Cake, Zombie, Steak, Hamburger, Beer, Margarita, Wine, Chicken Wings, Korean',
        annual_dol: 5556.72,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7526,
        store_longitude: -73.9851,
        total_orders: 5331.25,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Rich, Avocado Toast, Hotel, Delicious, American, Classic, Vintage, Exotic, Global, Great',
        annual_dol: 5626.91,
        description:
            'American dining & creative cocktails set against a vintage backdrop in the Refinery Hotel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7536,
        store_longitude: -73.9913,
        total_orders: 2186.43,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Beer, Salad, American, Hotel, Delicious, Vintage, Eclectic, Friendly, Casual',
        annual_dol: 3698.58,
        description:
            'Unique tri-level haunt with a vintage vibe serving elevated pub plates & original craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9969,
        total_orders: 123.32,
        neighborhood: 'Nolita',
        keywords:
            'Burger, Lobster, Lobster Roll, Tacos, Vegetarian, Gluten Free, Meatball, Tasty, Beer, Wine',
        annual_dol: 151.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.794,
        store_longitude: -73.9344,
        total_orders: 1142.52,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Salad, Meatball, Italian, Gourmet, Old Fashioned, Southern, Tradition, Classic, Fine',
        annual_dol: 1166.76,
        description:
            'VIPs fill the dining room of this 10-table Italian icon, where who you know is key to entry.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7178,
        store_longitude: -73.998,
        total_orders: 541.06,
        neighborhood: 'Little Italy',
        keywords:
            'Lasagna, Wine, Italian, Vodka, Meatball, Spicy, Elegant, Cozy, Polished, Perfect',
        annual_dol: 1214.09,
        description:
            'Cozy Little Italy mainstay featuring pasta, fish & old-world ambiance, plus a back garden patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8605,
        store_longitude: -73.6222,
        total_orders: 83.2,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Meatball, Peruvian, Warm, Polished, Comfortable, American, Phenomenal',
        annual_dol: 176.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7509,
        store_longitude: -73.9858,
        total_orders: 592.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Hotel, Modern, Caesar Salad, Comfortable, Eclectic, Vibrant, Friendly',
        annual_dol: 632.12,
        description:
            'The streamlined rooms feature free Wi-Fi, 42-inch flat-screen TVs, minifridges and coffeemakers. They also come with separate living and sleeping areas. Upgraded rooms include city views.Amenities include a fitness center, a 24/7 cafe and a cocktail bar, as well as a business center and meeting rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8195,
        store_longitude: -73.8177,
        total_orders: 1720.57,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Pizza, Shrimp Cocktail, Italian, Margarita, Delicious, Rooftop, Fun, Healthy, American, Perfect',
        annual_dol: 4200.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7057,
        store_longitude: -74.0086,
        total_orders: 457.04,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Elegant, Stylish, Luxurious, Rich, Perfect, Southern, Classic, Great, Fine, Casual',
        annual_dol: 466.73,
        description:
            '"63 Wall Street combines two of the finest buildings in Lower Manhattan to provide a New York living experience unlike any other. Nestled in the heart of Manhattan\'s historic Financial District, 63 Wall Street apartments are unique in their perfect harmony of classic form and modern construction. With our preserved historical significance and prestigious location, 63 Wall Street offers a classic and luxurious Financial District apartment rental experience."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8359,
        store_longitude: -73.701,
        total_orders: 392.3,
        neighborhood: '',
        keywords:
            'Vegetarian, Mexican, Chicken Tikka Masala, Turkish, Spicy, Delicious, Indian, Creamy, Crisp, Modern',
        annual_dol: 417.64,
        description:
            'Roomy Indian eatery with a traditional menu, known for its wide-ranging lunch buffet.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9944,
        total_orders: 27.14,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Sushi, Salad, Spicy, Delicious, Hotel, American, Greek, Asian, Sweet',
        annual_dol: 110.93,
        description:
            'Kosher sushi & small plates provided in a rooftop destination featuring a bar & city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8243,
        store_longitude: -73.6823,
        total_orders: 1982.57,
        neighborhood: '',
        keywords:
            'Burger, Steak, Salad, Chicken Caesar Salad, Swiss, Beer, Wine, Irish, French Onion Soup, French',
        annual_dol: 2024.64,
        description:
            'Easygoing Irish pub serves typical grub in a homey, denlike setting & hosts karaoke at the bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8251,
        store_longitude: -73.6825,
        total_orders: 44.18,
        neighborhood: '',
        keywords: 'Burger, Steak, Tequila, Mexican, Beer, Wine, Bbq, French, Fun, Sweet',
        annual_dol: 180.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.6126,
        total_orders: 5228.85,
        neighborhood: '',
        keywords:
            'Steak, Salad, Wine, Chilean, Spicy, Upscale, Brazilian, American, Friendly, Southern',
        annual_dol: 6968.9,
        description:
            'Upscale Brazilian chain for all-you-can-eat meat carved tableside plus an extensive salad bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6566,
        store_longitude: -73.626,
        total_orders: 2653.71,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Whiskey, Beer, Cajun, American, Relaxed, Clean, Casual, Coleslaw',
        annual_dol: 2710.02,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6653,
        store_longitude: -73.7217,
        total_orders: 162.6,
        neighborhood: '',
        keywords:
            'Salad, Lobster, Italian, Pizza, Tasty, Spicy, Delicious, Homemade, Pinacolada, Irresistible',
        annual_dol: 177.21,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6596,
        store_longitude: -73.6447,
        total_orders: 66.28,
        neighborhood: '',
        keywords: 'Beer, Martini, Jamaican, Wine, Rum, Caribbean, Spicy, Bbq, Upscale, Painkiller',
        annual_dol: 83.24,
        description:
            'This snug yet colorful little eatery is known for lively reggae nights & upmarket Caribbean fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7067,
        store_longitude: -74.009,
        total_orders: 1874.25,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hamburger, Savory, Irish, Upscale, Amazing, Local, Casual, Great, Fine, Unique',
        annual_dol: 6662.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8684,
        store_longitude: -73.6316,
        total_orders: 229.31,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Spicy, Delicious, Chinese, Sweet, Hawaiian, Japanese, Asian, Spanish',
        annual_dol: 244.55,
        description:
            '"Come and enjoy a night out with delicious Asian Fusion Restaurant at Sweet Mandarin Bistro. We offer a variety of delicious dishes, with a focus on quality and freshness. We offer an extensive sushi bar, delicious Chinese foods, entertaining hibachi tables, in addition to a great lunch menu. We will be opening up our upstairs patio for dine-in soon. Located at the heart of Glen Cove, at 18 Cottage Row, Glen Cove, NY, 11542"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8715,
        store_longitude: -73.6256,
        total_orders: 979.86,
        neighborhood: '',
        keywords: 'Italian, Salad, Wine, Pizza, Coffee, Upscale, Cozy, Premium, Clean, Friendly',
        annual_dol: 1008.37,
        description:
            'Upmarket Sicilian favorites including wines & pasta options in a homey atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7427,
        store_longitude: -73.7216,
        total_orders: 8.03,
        neighborhood: 'Glen Oaks/Bellerose Manor',
        keywords:
            'Vegetarian, Butter Chicken, Chicken Tikka Masala, Fried Rice, Indian, Tandoori Chicken, Delicious, Casual, Dal Makhani, Aloo Tikki',
        annual_dol: 32.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.815,
        total_orders: 50.16,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Steak, Salad, Vodka, Bbq, Fried Rice, Asian, Kibbeh, Eclectic, Spacious, Casual',
        annual_dol: 63,
        description:
            'Casual, spacious steakhouse offering an eclectic menu of Italian, Asian & Middle Eastern fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8245,
        store_longitude: -73.8205,
        total_orders: 48.93,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Steak, Vodka, Delicious, Latin, American, Fusion, Exotic, Lively, Casual, Sangria',
        annual_dol: 76.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8654,
        store_longitude: -73.6311,
        total_orders: 176.45,
        neighborhood: '',
        keywords:
            'Italian, Wine, Pizza, Beer, Romantic, Refined, Outstanding, Classy, Awesome, Amazing',
        annual_dol: 454.68,
        description:
            'Original Italian eatery in a family-owned chain offers refined fare in old-fashioned environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5754,
        store_longitude: -74.1051,
        total_orders: 5361.88,
        neighborhood: 'Midland Beach',
        keywords:
            'Lobster, Lobster Tail, Tasty, Gourmet, Dessert, Wine, Beer, Delicious, Coffee, Italian',
        annual_dol: 5910.25,
        description:
            'Eclectic, purple-hued cellar offering a range of classic American dishes plus cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6302,
        store_longitude: -73.7083,
        total_orders: 477.25,
        neighborhood: '',
        keywords: 'Pizza, Fun, Comfortable, Wonderful, Great, Excellent, Classic, Casual',
        annual_dol: 584.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.9863,
        total_orders: 24840.42,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Burger, Salad, Roast Beef, Wine, Legendary, Beer, Prime Rib, Crisp',
        annual_dol: 25693.85,
        description:
            'Enormous steaks & signature mutton chops served in a maze of clubby, wood-paneled rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5815,
        store_longitude: -73.83,
        total_orders: 1944.78,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords:
            'Tacos, Burger, Hotel, Urban, Innovative, American, Rooftop, Eclectic, Contemporary, Fresh',
        annual_dol: 3405.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6739,
        store_longitude: -73.6703,
        total_orders: 991.07,
        neighborhood: '',
        keywords: 'Wine, Italian, Beer, Rustic, Premium, Chic, Authentic, Diverse, Local',
        annual_dol: 1012.1,
        description:
            'Old-world Italian spot with rustic-chic decor offering entrees, small plates & a diverse wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -73.9217,
        total_orders: 593.65,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Beer, Whiskey, Margarita, Italian, Spicy, Cozy, Asian, Hip, Friendly',
        annual_dol: 736.77,
        description:
            'Hip hangout with outdoor seating fixing up beer & cocktails in a laid-back setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7873,
        store_longitude: -73.9516,
        total_orders: 941.76,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Wine, Beer, Margarita, Warm, Rum, Cozy, Phenomenal, Friendly, Perfect, Bright',
        annual_dol: 1130.84,
        description:
            'This cozy cocktail haunt features a bartop with mixed nuts suspended in epoxy & other quirky decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7408,
        store_longitude: -73.5992,
        total_orders: 410.83,
        neighborhood: '',
        keywords:
            'Luxurious, Hotel, Spacious, Fun, Sophisticated, Incredible, Great, American, Salad, Distinctive',
        annual_dol: 447.74,
        description:
            '"Distinct, luxurious, 1 & 2 bedroom luxury apartments in Nassau County on Long Island. Experience 5-star living every day, in these incredible rental residences. Open floor plans, dramatic 9\' ceilings, spa baths, sunlit rooms and walk in closets. The best of hotel style living, in the comfort of your own building. Fitness center, concierge, outdoor heated pool, parking, exclusive dining and more. Limited availability, contact us for a tour & details on this brand new rental apartments. Vanderbilt also offers short and long term stays."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -74.0059,
        total_orders: 406.77,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Upscale, Tasty, Elegant, Legendary, Impeccable, Classy, Premium, Gourmet, French',
        annual_dol: 433.5,
        description:
            'Modern European plates pair with an extensive wine list & cocktails in an elegant, open interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -74.0102,
        total_orders: 115.01,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Pizza, Delicious, Memorable, Perfect',
        annual_dol: 470.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6106,
        store_longitude: -73.6456,
        total_orders: 24.3,
        neighborhood: '',
        keywords: 'Hotel, Fun, Awesome, Western, Great, Fine, Casual, Local',
        annual_dol: 99.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9838,
        total_orders: 335.37,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Wine, Coffee, Upscale, French, Brazilian, Contemporary, Fancy, Robust, Classic',
        annual_dol: 1370.88,
        description:
            '"Ipanema is dedicated to building quality relationships, cultivated through top quality cooking and delivering one-of-a-kind dining experiences. While Ipanema sells modern interpretations of classic dishes from Portugal and Brazil, the menu combines Portuguese recipes with the zest and vibrancy of Brazilian cuisine rooted in French cooking techniques. Our “cheerful and friendly spot tastes just like Brazil & Portugal” and feels like a home “inside-of-a-restaurant.” A seasonal menu by Executive Chef Giancarlo Junyent offers sharing plates of fresh appetizers and mid courses, with a lovely finale of roasted meats and cured seafood, followed by decadent desserts and wine."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.9925,
        total_orders: 109.55,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Comfortable, Coffee, Clean, Friendly, Sleek, Modern, Great, Exceptional, Contemporary',
        annual_dol: 447.8,
        description:
            'The understated rooms offer Wi-Fi, coffeemakers and minifridges. Upgraded rooms have views of the Empire State Building.Amenities include a compact gym and a business center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8074,
        store_longitude: -73.9273,
        total_orders: 472.33,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Beer, Trendy, Rich, Mimosa, Delicious, Southern Comfort, American, Puerto Rican, Classy, Hip',
        annual_dol: 518.22,
        description:
            'Trendy restaurant dishing up Southern & Puerto Rican comfort foods, plus brunch staples & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -74.0064,
        total_orders: 533.58,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Italian, Tasty, Cozy, Gin, Warm, Beer, Rustic, Comfortable, Premium',
        annual_dol: 622.44,
        description:
            'Convivial wine bar serving its vintages & Italian small plates in cozy digs with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6557,
        store_longitude: -73.8387,
        total_orders: 1514.95,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Steak, Salad, Italian, Fried Calamari, Wine, Bbq, Beer',
        annual_dol: 1563.95,
        description:
            'Lively canteen with a big menu of seafood, raw-bar offerings, BBQ & Italian standards.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.718,
        store_longitude: -73.9982,
        total_orders: 1285.6,
        neighborhood: 'Little Italy',
        keywords:
            'Italian, Wine, Delicious, Baked Clams, Spacious, Innovative, Bruschetta, Amazing, Authentic, Traditional',
        annual_dol: 2067.47,
        description:
            'Traditional Northern Italian eatery since 1975 with veteran waiters & a back garden atrium.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7874,
        store_longitude: -73.9528,
        total_orders: 377.41,
        neighborhood: 'East Harlem/Central Park West Historic District',
        keywords:
            'Mexican, Burritos, Cuban, Cozy, Casual, Local, Authentic, Classic, Traditional, Fresh',
        annual_dol: 385.42,
        description:
            'Cozy cantina with colorful decor for Mexican street food, aguas frescas & tequila.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7169,
        store_longitude: -74.0087,
        total_orders: 6527.03,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Champagne, Wine, Beer, Bourbon, Gin, Delicious, Martini, Warm, Rum, Sleek',
        annual_dol: 6729.1,
        description:
            'Slick, Prohibition-style watering hole with craft cocktails & sophisticated bar snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7776,
        store_longitude: -73.9521,
        total_orders: 1286.18,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Tacos, Hamburger, Tasty, Beer, Nutritious, Tomato Soup, Delicious, Upscale, American',
        annual_dol: 1316.37,
        description:
            'Rustic, brick-walled bar with American eats, tacos & brunch plus craft drafts & specialty cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7029,
        store_longitude: -73.9294,
        total_orders: 1382.14,
        neighborhood: 'Bushwick',
        keywords:
            'Tacos, Tequila, Mexican, Margarita, Enchiladas, Trendy, Rich, Rum, Delicious, Hip',
        annual_dol: 1417.05,
        description:
            'Place for simple, modern Mexican food (like tacos, tortas & grilled dishes) with cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9942,
        total_orders: 643.99,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Whiskey, Wine, Beer, Irish, Salad, Vietnamese, Canadian, French, American, Eggs Benedict',
        annual_dol: 2285.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7046,
        store_longitude: -74.0098,
        total_orders: 1154.93,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Rich, Exquisite, Wonderful, Great, Intimate, Fine, Local',
        annual_dol: 4720.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7403,
        store_longitude: -74.0056,
        total_orders: 1422.39,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Wine, Beer, Martini, Asian, Elegant, Sleek, Exotic, Fusion, American, Fresh',
        annual_dol: 3115.29,
        description:
            'Asian-inspired cocktails & bites delivered from a dim sum cart are presented in an ornate space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7307,
        store_longitude: -74.0069,
        total_orders: 3217.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Sushi, Gluten Free, Japanese, Wine, Tasty, Organic, Sashimi, Spicy, Magical',
        annual_dol: 3309.68,
        description:
            'Sleek spot for modern, seasonal Japanese cuisine, including housemade tofu, plus a long sake list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7302,
        store_longitude: -74.0034,
        total_orders: 693.8,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Salad, Pizza, Tasty, Organic, Wholesome, Cuban, Dessert',
        annual_dol: 840.37,
        description:
            'Organic vegan comfort food, along with cocktails & mocktails in an intimate West Village setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7276,
        store_longitude: -73.6351,
        total_orders: 3071.53,
        neighborhood: '',
        keywords: 'Lobster, Pizza, Italian, Wine, Meatball, Spicy, Creamy, Premium, Sweet, Global',
        annual_dol: 3183.67,
        description:
            'A vast selection of wines is kept fresh by a high-tech system & served with small plates & pasta.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8396,
        store_longitude: -73.9405,
        total_orders: 1627.06,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Burger, Crab Cakes, Salad, Cornbread, Shrimp Cocktail, Bbq, Friendly, Coleslaw, Casual, Local',
        annual_dol: 1697.61,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6686,
        store_longitude: -73.9932,
        total_orders: 1825.82,
        neighborhood: 'Gowanus',
        keywords: 'Pizza, Beer, Wine, Tranquil, Relaxed, Awesome, Fresh, Friendly, Amazing, Great',
        annual_dol: 1864.56,
        description:
            'Relaxed outpost for cocktails & a rotating beer selection, plus a tranquil outdoor seating area.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6638,
        store_longitude: -73.9898,
        total_orders: 72.22,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Dessert, Russian, Sweet, Iconic, Fine, Complex, Samosas, Teriyaki',
        annual_dol: 295.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.662,
        store_longitude: -73.9925,
        total_orders: 495.93,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Hamburger, Beer, Austrian, German, Martini, Korean, Hungarian, Wine, Polish',
        annual_dol: 565.69,
        description:
            'Quirky kitchen with Eastern European-inspired comfort food, including hamburgers, plus draft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8599,
        store_longitude: -73.8929,
        total_orders: 194.04,
        neighborhood: 'Belmont/Fordham Heights/West Bronx',
        keywords:
            'Steak, Wine, Beer, Margarita, Hawaiian, Spanish, Pinacolada, Caribbean, American, Delicious',
        annual_dol: 256.88,
        description:
            'Lively, clubby destination with a global menu featuring Italian, Latin & local dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6581,
        store_longitude: -73.648,
        total_orders: 479.96,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Gluten Free, Beer, Dessert, Irish, Delicious, Relaxed, American, Friendly',
        annual_dol: 490.15,
        description:
            'Down-to-earth tavern offering a pub menu with Irish specialties, plus draft beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6121,
        store_longitude: -73.941,
        total_orders: 229.38,
        neighborhood: 'Marine Park',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Sushi, Salad, Mexican, Italian, French Onion Soup, French',
        annual_dol: 247.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6396,
        store_longitude: -73.661,
        total_orders: 409.36,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Salad, Pizza, Shrimp Scampi, Italian, Beer, Wine, Moroccan, Delicious',
        annual_dol: 1488.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8314,
        store_longitude: -73.8271,
        total_orders: 1569.95,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Lobster, Steak, Sushi, Salad, Beer, Japanese, Tasty, Sashimi, Asian, Sweet',
        annual_dol: 2562.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -73.5598,
        total_orders: 188.33,
        neighborhood: '',
        keywords: 'Steak, Coffee, Gourmet, Beer, Bbq, American, Ribeye Steak, Mead, Casual, Unique',
        annual_dol: 355.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8231,
        store_longitude: -73.8196,
        total_orders: 326.7,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Whiskey, Beer, Delicious, American, Casual, Fun, Fine',
        annual_dol: 801.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7194,
        store_longitude: -73.5647,
        total_orders: 1021.96,
        neighborhood: '',
        keywords:
            'Salad, Tacos, Mexican, Margarita, Wine, Beer, Chinese, Casual, Exotic, Traditional',
        annual_dol: 1785.44,
        description:
            'Festive eatery specializes in margaritas & Mexican classics, with occasional live mariachi music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7576,
        store_longitude: -73.993,
        total_orders: 2903.47,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Champagne, Beer, Margarita, Trendy, Stylish, Spicy, Savory, Gracious, Cointreau, Triple Sec',
        annual_dol: 2965.08,
        description:
            "Along with 28 lanes of bowling there's a dance club, billiards lounge & various package deals.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.751,
        store_longitude: -73.9832,
        total_orders: 898.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Lobster, Hamburger, Pizza, Salad, Cajun, Nachos, Lobster Bisque, Beer, Cuban',
        annual_dol: 3217.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9927,
        total_orders: 103.71,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Hotel, Spanish, Stylish, Cozy, Classy, Classic, Adventurous, Contemporary, Iconic',
        annual_dol: 423.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7535,
        store_longitude: -73.6275,
        total_orders: 419.2,
        neighborhood: '',
        keywords: 'Fun, Friendly, Perfect, Wonderful, Amazing, Great, Classic, Casual',
        annual_dol: 456.35,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8345,
        store_longitude: -73.8504,
        total_orders: 447.58,
        neighborhood: 'East Bronx/Westchester Square/Parkchester',
        keywords:
            'Mexican, Beer, Margarita, Martini, Wine, Spanish, Puerto Rican, Caribbean, American, Latin',
        annual_dol: 527.41,
        description:
            'Casual spot for Puerto Rican home cooking plus classic cocktails amid art-filled walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9828,
        total_orders: 75.65,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Upscale, Coffee, Hotel, Healthy, Lively, Romantic, Rooftop, Sleek, Classic, Chic',
        annual_dol: 573.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.6765,
        total_orders: 1004.7,
        neighborhood: '',
        keywords:
            'Beer, Ale, Cajun, Burger, Wine, Chicken Wings, Asian, Delicious, Southern, Classic',
        annual_dol: 1029.33,
        description:
            'Outpost of a local alehouse chain featuring a selection of craft beers, pub grub & wing specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.7026,
        total_orders: 102.08,
        neighborhood: 'Floral Park/Bellerose Manor',
        keywords:
            'Martini, Gin, Spicy, Delicious, Tandoori Chicken, Fusion, American, Sweet, Modern, Indian',
        annual_dol: 116.45,
        description:
            '"ABCD Indian Bistro & Bar was born out of the love of a foodie who experienced his cultural recipes in a foreign land. Enjoy different cuisines in the best Indian restaurant in queens."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7723,
        store_longitude: -73.9299,
        total_orders: 429.68,
        neighborhood: 'Astoria',
        keywords: 'Beer, Wine, Perfect, American, Great, Excellent, Fusion, Casual, Chill, Unique',
        annual_dol: 489.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -73.674,
        total_orders: 20.41,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Mexican, Wine, Tasty, American, Casual, Traditional, Great',
        annual_dol: 83.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6748,
        store_longitude: -73.976,
        total_orders: 108.78,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Beer, Salad, Gourmet, Friendly, Eclectic, Fun, Lively, Casual, Artisanal',
        annual_dol: 444.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9109,
        total_orders: 37.52,
        neighborhood: 'Astoria',
        keywords: 'Wine, Tasty, Beer, Irish, Cozy, Rum, Friendly, Great, Casual, Local',
        annual_dol: 181.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7724,
        store_longitude: -73.9605,
        total_orders: 3103.81,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Wine, Beer, French, Italian, French Onion Soup, American, Delicious, Premium, Warm',
        annual_dol: 3108.35,
        description:
            'Popular with neighborhood locals & expats, the Parisian-style eatery dishes authentic French fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9691,
        total_orders: 3923.53,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Chocolate Cake, Cheesecake, Pizza, Carrot Cake, Salad, Coffee, Wine, Shrimp Cocktail, Hotel',
        annual_dol: 4243.78,
        description:
            "Chic, classically furnished rooms have free Wi-Fi and smart TVs, as well as desks with ergonomic chairs. Upgrades add 47-inch TVs, living areas or views of Park Avenue. Luxe 1- to 3-bedroom suites feature sleek atriums, kitchens, soaking tubs and/or balconies with city views.There's a contemporary restaurant with a grill serving American cuisine and afternoon tea, plus a swanky bar and a lounge. There's also a salon, a plush spa and a fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8305,
        store_longitude: -73.8156,
        total_orders: 323.27,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'American, African, Diverse, Local',
        annual_dol: 340.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8707,
        store_longitude: -73.6177,
        total_orders: 1109.84,
        neighborhood: '',
        keywords: 'Golfclub, Classic',
        annual_dol: 1182.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7503,
        store_longitude: -73.9847,
        total_orders: 9971.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Japanese, Ramen, Spicy, Delicious, Trendy, Zen, Fresh, Casual',
        annual_dol: 10063.26,
        description:
            'Branch of a Tokyo-based chain specializing in tonkotsu ramen, griddled dishes & fried chicken.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6565,
        store_longitude: -73.6451,
        total_orders: 161.57,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Beer, Lobster, American, Wine, Casual, Creative, Fine',
        annual_dol: 180.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6121,
        store_longitude: -73.6438,
        total_orders: 302.43,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Beer, Wine, Martini, Margarita, Coffee, Delicious, Ale',
        annual_dol: 411.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9836,
        total_orders: 8996.03,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, Italian, Stylish, Hotel, French, Gourmet, Elegant, Delicious, Romantic, Inviting',
        annual_dol: 9463.98,
        description:
            'Gourmet fare from the Italian & French Riviera plus regional wines in an elegant hotel setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -73.9837,
        total_orders: 1296.55,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Irish, Fun, Friendly, Awesome, Great, Casual, Chill',
        annual_dol: 1371,
        description:
            '"Traditional Irish pub and sports bar in midtown Manhattan located at 20 West 38th Street between 5th & 6th Avenue."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.9937,
        total_orders: 2332.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Hamburger, Vegetarian, Salad, Tuna Steak, Bbq, Dessert, American, Hotel',
        annual_dol: 2548.85,
        description:
            '"Burgerology is a USDA Prime burger restaurant that features crazy milkshakes, hand cut fries, craft beer, wine, impossible sundaes and craft cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6496,
        store_longitude: -73.8717,
        total_orders: 788.62,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Steak, Burger, Lobster, Hamburger, Italian, Chicken Wings, Juicy, Creole, Sweet, Casual',
        annual_dol: 1676.34,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.752,
        store_longitude: -73.9829,
        total_orders: 330.32,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Cajun, Savory, Gourmet, Tasty, American, Organic, Friendly, Casual',
        annual_dol: 951.44,
        description:
            '"Savory is a boutique, multifaceted hospitality company based in the heart of New York City, offering a wide variety of culinary programs, specializing in office catering, events, and food and beverage operations."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.924,
        total_orders: 142.22,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Lasagna, Italian, Pizza, Salad, Coffee, Donut, Beer, Creamy, Homemade, American',
        annual_dol: 165.77,
        description:
            'Italian classics, including housemade pasta, served in a bright space with exposed-brick walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6248,
        store_longitude: -73.9176,
        total_orders: 227.77,
        neighborhood: 'Bergen Beach',
        keywords:
            'Lobster, Cajun, Salad, Lobster Bisque, Flavorful, Bbq, Creole, Fusion, Classy, Clam Chowder',
        annual_dol: 429.63,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6015,
        store_longitude: -73.656,
        total_orders: 168.88,
        neighborhood: '',
        keywords: 'Ale, Beer, Wine, Bourbon, Scotch, Local, Seasonal, Casual, Great',
        annual_dol: 180.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7051,
        store_longitude: -74.0087,
        total_orders: 70.73,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Lobster Tail, Burger, Shrimp Cocktail, Salad, Tequila, Dessert, Greek, Mediterranean, Bbq',
        annual_dol: 289.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6194,
        store_longitude: -73.9218,
        total_orders: 76.5,
        neighborhood: 'Flatlands',
        keywords: 'Steak, Pizza, Gourmet, Beer, American, Bbq, Irish, Creamy, Homemade, Classic',
        annual_dol: 312.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.9957,
        total_orders: 333.38,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Pizza, Salad, Beer, Asian, Modern, Contemporary, Casual, Local, Classic',
        annual_dol: 379.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9956,
        total_orders: 380.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Wine, Local, Casual, Great, Attentive, Chill, Unique',
        annual_dol: 1562.46,
        description:
            "Metropolitan bar with black & white art & a 'throwing range' to smash glasses & electronic items.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6204,
        store_longitude: -73.9272,
        total_orders: 110.49,
        neighborhood: 'Flatlands',
        keywords:
            'Salad, Dessert, Tasty, Mimosa, Champagne, Organic, Jamaican, Caribbean, Rustic, Creamy',
        annual_dol: 246.77,
        description:
            '"Bellyful Restaurant & Bar is an elegantly rustic Caribbean Restaurant catering to the discerning customer. We are located at 2102 Utica Avenue in Brooklyn New York. Our ambiance truly encapsulates the Pan-African spirit and culture, all private occasions can be accommodated within our mezzanine area. Our dishes contain nothing but the authentic Jamaican flavors that truly excite your taste buds. The majority of our dishes are inspired by classic and timeless Jamaican recipes. While it is guaranteed that your taste buds will come alive from dishes infused with flavors from the Caribbean; the food at Bellyful is just the beginning of your overall culinary experience."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8576,
        store_longitude: -73.6354,
        total_orders: 1824.02,
        neighborhood: '',
        keywords:
            'Elegant, Exquisite, Intimate, Perfect, Awesome, Impressive, Great, Artistic, Seasonal, Local',
        annual_dol: 2126.12,
        description:
            '"Elegant Affairs Caterers We are Long Island\'s NY Catering Company of Choice providing Full-Service Off-Premise Catering. We offer Catering Services To Social and Corporate Events, Private Parties, and Weddings in Long Island NY and nearby areas. Menu Planning & Food Styling We offer an array of seasonal local cuisine that is impressive to the eye, as well as, the palate. Party Rental Coordination. We are a team of expert organizers and are always happy to help coordinate your party rentals. Vendor Coordination In addition to bringing exuberance and style to every event we cater to — we also work well with others. Our seasoned team takes pride in handling coordinating with your vendors to ensure expectations are not only met but surpassed."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.727,
        store_longitude: -73.8223,
        total_orders: 77.89,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Champagne, Wine, Beer, Wonderful, Great, Intimate',
        annual_dol: 84.89,
        description:
            '"Queens NY. The #1 Catering Hall in Kew Gardens Hills, Queens! Exclusive Use. Book Now! Professional Service. Family Owned and Operated since 1975."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5968,
        store_longitude: -73.6541,
        total_orders: 194.88,
        neighborhood: '',
        keywords:
            'Burger, Cheesesteak, Beer, Organic, Ale, Philly Cheesesteak, Spanish, American, Irish, Homemade',
        annual_dol: 280.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5786,
        store_longitude: -73.8497,
        total_orders: 4526.54,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords:
            'Tacos, Mexican, Steak, Beer, Venezuelan, Delicious, Contemporary, Classic, Friendly, Fresh',
        annual_dol: 4722.8,
        description:
            'Cool, contemporary Mexican kitchen offering classic street food, creative cocktails & many tequilas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5796,
        store_longitude: -74.1093,
        total_orders: 726.7,
        neighborhood: 'Midland Beach',
        keywords: 'Burger, Pizza, Italian, Juicy, Beer, Wine, Bbq, Creamy, Legendary, Peruvian',
        annual_dol: 774.57,
        description:
            '"The world\'s best pizza courtesy of the legendary Di Fara Pizza from Brooklyn! Also serving a full Italian menu ala Juicy Lucia."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8306,
        store_longitude: -73.6936,
        total_orders: 51.38,
        neighborhood: '',
        keywords:
            'Salad, Steak, Upscale, Martini, Spicy, French, Thai, Cozy, Quaint, Mediterranean',
        annual_dol: 66.37,
        description:
            'Intimate & upscale New American eatery offers fixed-price & beer-pairing specials in candlelit digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8129,
        store_longitude: -73.6792,
        total_orders: 1224.01,
        neighborhood: '',
        keywords: 'Golfclub, Bbq, Fabulous, Fantastic, Terrific, Great, Complex',
        annual_dol: 1362.85,
        description:
            'The North Hempstead Country Club is a private country club in the Incorporated Village of Flower Hill, on Long Island, in New York, United States.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5842,
        store_longitude: -73.9353,
        total_orders: 300.02,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Upscale, Luxurious, American, Exquisite, Fine, Delectable',
        annual_dol: 349.99,
        description:
            '"Private Charter Yacht available for Corporate Events, Weddings, Birthday Parties, and other Celebrations."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8211,
        store_longitude: -73.8186,
        total_orders: 3361.62,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Sushi, Japanese, Cajun, Asian, Fusion, Clean, Perfect, Casual, Unpretentious, Recommended',
        annual_dol: 4005.5,
        description:
            'Unpretentious eatery where chefs cook meat & seafood on hibachi grills, plus noodles, sushi & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6082,
        store_longitude: -73.7266,
        total_orders: 239.68,
        neighborhood: '',
        keywords: 'Hotdog, Burger, Beer, Tasty, Bbq, Stylish, Elegant, Perfect, Great, Wonderful',
        annual_dol: 756.11,
        description:
            '"Breathe in the historic site… stately manor, endless acres of manicured golfing greens, yachts majestically aligned, all embraced by the gentle bay area of the Atlantic Sea."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5732,
        store_longitude: -74.1151,
        total_orders: 603.76,
        neighborhood: 'New Dorp',
        keywords:
            'Vegetarian, Salad, Shrimp Cocktail, Italian, Wine, Meatball, Turkish, Upscale, Delicious, Classy',
        annual_dol: 629.94,
        description:
            'Bustling restaurant serving upscale Italian eats in a rustic, white-tablecloth setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5779,
        store_longitude: -73.8494,
        total_orders: 475.2,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords: 'Mexican, Wine, Tasty, Filipino, Warm, American, Irish, Asian, Casual, Local',
        annual_dol: 614.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.601,
        store_longitude: -73.6561,
        total_orders: 15.84,
        neighborhood: '',
        keywords: 'Pizza, Italian, Salad, Meatball, Vodka, Hotel, Polished, Fresh, Casual, Local',
        annual_dol: 59.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -73.5649,
        total_orders: 33.12,
        neighborhood: '',
        keywords:
            'Burger, Steak, Pizza, Salad, Sushi, Roast Beef, Beer, Wine, Caesar Salad, Creamy',
        annual_dol: 135.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6653,
        store_longitude: -73.7243,
        total_orders: 4301.44,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Cajun, Spicy, Delicious, Juicy, Sweet, Fun, Clean, Perfect',
        annual_dol: 4400.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6567,
        store_longitude: -73.645,
        total_orders: 627.43,
        neighborhood: '',
        keywords:
            'Burger, Mexican, Salad, Italian, Nachos, American, Rooftop, Irish, Elegant, Casual',
        annual_dol: 698.08,
        description:
            'Quick bites & entrees of American fare at this elegant venue with private rooms & a rooftop bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8312,
        store_longitude: -73.6949,
        total_orders: 1828.48,
        neighborhood: '',
        keywords:
            'Burger, Steak, Pizza, Salad, Shrimp Cocktail, Roast Turkey, Beer, Wine, Dessert, French Onion Soup',
        annual_dol: 2172.52,
        description:
            'Long-running venue featuring Irish & American classics, including shepherd’s pie, burgers & steak.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6555,
        store_longitude: -73.6458,
        total_orders: 1234.72,
        neighborhood: '',
        keywords: 'Burger, Salad, Italian, Beer, Wine, Upscale, Thai, American, Bbq, Delicious',
        annual_dol: 1265.23,
        description:
            'Local American mainstay for creative burgers & grilled bites, with 2 bars & patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.9858,
        total_orders: 1488.98,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Whiskey, Beer, Wine, Irish, Karaoke, Homemade, Traditional, Classic',
        annual_dol: 1556.1,
        description:
            "Classic Irish bar offering pints of Guinness, shepherd's pie, bangers & mash & jukebox tunes.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7535,
        store_longitude: -73.9923,
        total_orders: 531.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Tasty, Spicy, Delicious, Wine, Chinese, Gourmet, Beer, Spacious, Asian, Exceptional',
        annual_dol: 600.18,
        description:
            'Traditional Chinese dishes by the namesake chef are served in a modern, airy 2-story space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6263,
        store_longitude: -73.7366,
        total_orders: 522.59,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Burritos, Salad, Margarita, Yummy, Delicious, Local, Casual, Perfect',
        annual_dol: 616.18,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7054,
        store_longitude: -74.0074,
        total_orders: 1567.28,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Luxurious, Hotel, Rich, Elegant, Spacious, Spectacular, Sophisticated, Urban, Global, Intimate',
        annual_dol: 6406.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -74.0079,
        total_orders: 455.76,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Wine, Stylish, Elegant, Pizza, Japanese, Sashimi, Fun, Contemporary, Friendly',
        annual_dol: 1140.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7524,
        store_longitude: -73.9846,
        total_orders: 1077.97,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Italian, Dessert, Contemporary, American, Casual, Diverse, Cool, Traditional',
        annual_dol: 1100.84,
        description: '"Modern American Cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7956,
        store_longitude: -73.6749,
        total_orders: 19588.79,
        neighborhood: '',
        keywords:
            'Upscale, Wine, Trendy, Nutritious, Japanese, Spicy, Asian, Delicious, Creamy, Modern',
        annual_dol: 20027.45,
        description:
            'A destination for upscale Asian fusion fare in snazzy modern surroundings with a booming bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -73.5618,
        total_orders: 1370.01,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Flavorful, Bbq, Wine, Bbq Ribs, Swiss',
        annual_dol: 1399.08,
        description:
            'Chophouse standards are supplied in this family-friendly outpost with an old-school vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8258,
        store_longitude: -73.8214,
        total_orders: 126.12,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Mexican, Puerto Rican, Ale, Irish, American, Innovative, Authentic, Latin, Eclectic, Clean',
        annual_dol: 411.74,
        description: '"Bar / Kitchen / Culture"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.789,
        store_longitude: -73.6514,
        total_orders: 225.16,
        neighborhood: '',
        keywords:
            'Tacos, Cheesecake, Salad, Vegetarian, Wine, Beer, Mexican, Italian, Martini, Delicious',
        annual_dol: 234.3,
        description:
            'Casual eatery offering family-style Italian staples & wines by the glass in a traditional setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6039,
        store_longitude: -73.651,
        total_orders: 2642.08,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Lobster Bisque, Shrimp Scampi, Japanese, Wine, Martini, Spanish, New Zealand, American',
        annual_dol: 2698.14,
        description:
            'Classy chophouse with a sizable bar turning out steaks, seafood & martinis in an old-school setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.986,
        total_orders: 499.2,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Healthy, Premium, Bright, Fun, Friendly, Modern, Great, Chic, American',
        annual_dol: 550.87,
        description:
            'Contemporary rooms offer free WiFi and flat-screen TVs with premium cable channels, plus desks, minifridges and coffeemakers. Suites add separate living areas, sofas and microwaves.Social spaces include a living room-style lobby and an American restaurant serving breakfast. Other amenities include an exercise room, a business center and meeting space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6647,
        store_longitude: -73.7246,
        total_orders: 3879.42,
        neighborhood: '',
        keywords:
            'Burger, Salad, Dessert, Italian, New-York-Style, Beer, French, Chicken Wings, Rich, Creamy',
        annual_dol: 3982.64,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6556,
        store_longitude: -73.6458,
        total_orders: 927.05,
        neighborhood: '',
        keywords:
            'Burger, Hawaiian, Beer, Bbq, Moscow Mule, Ale, Blue Hawaiian, Bloody Mary, Casual, Great',
        annual_dol: 967.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6121,
        store_longitude: -73.6487,
        total_orders: 338.07,
        neighborhood: '',
        keywords: 'Lobster, Warm, Fried Clams, Southern, Fresh, Casual, American, Great, Classic',
        annual_dol: 424.58,
        description:
            'Old-fashioned eatery & raw bar serving classic American seafood & steaks since the summer of 1940.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7052,
        store_longitude: -74.0073,
        total_orders: 132.96,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Spectacular, Rooftop, Rum, Great, Comfortable, Modern, Contemporary, Friendly, Fine',
        annual_dol: 543.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7072,
        store_longitude: -74.0112,
        total_orders: 790.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Coffee, Premium, Casual',
        annual_dol: 1115.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7537,
        store_longitude: -73.9857,
        total_orders: 4353.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Whiskey, Burger, Beer, Vodka, Vegetarian, Wine, Gin, Gluten Free, American, Tasty',
        annual_dol: 4598.55,
        description:
            'Popular neighborhood hangout for drinks & American pub food served in a cozy setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7957,
        store_longitude: -73.9323,
        total_orders: 1105.03,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Salad, Chicken Caesar Salad, Milkshake, Beer, Thai, Delicious, Caesar Salad, American, Asian',
        annual_dol: 1520.59,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6571,
        store_longitude: -73.6435,
        total_orders: 54.66,
        neighborhood: '',
        keywords: 'Sushi, Salad, Japanese, Spicy, Juicy, Chinese, Sashimi, Thai, Asian, Latin',
        annual_dol: 223.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6866,
        store_longitude: -73.8229,
        total_orders: 27.56,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'South American, Spicy, Bbq, Chinese, Fried Rice, Caribbean, Asian, American, Brazilian, Elegant',
        annual_dol: 112.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7239,
        store_longitude: -73.5665,
        total_orders: 419.67,
        neighborhood: '',
        keywords: 'Burger, Premium, Latin, Fun, Classic, Great, Local',
        annual_dol: 1738.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6134,
        store_longitude: -73.9123,
        total_orders: 426.32,
        neighborhood: 'Mill Basin',
        keywords:
            'Burger, Lobster, Pizza, Salad, Gourmet, Italian, Beer, Savory, American, Delicious',
        annual_dol: 474.33,
        description:
            'Cozy store offering specialty cakes & cupcakes plus imported teas, chocolates, cheeses & breads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6807,
        store_longitude: -73.7532,
        total_orders: 1747.66,
        neighborhood: 'Springfield Gardens/St. Albans',
        keywords: 'Tacos, Steak, Pizza, Jamaican, Rum, Caribbean, Indian, Great, Wonderful, Casual',
        annual_dol: 1919.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6732,
        store_longitude: -73.6734,
        total_orders: 367.62,
        neighborhood: '',
        keywords: 'Pizza, Beer, Wine, Martini, Delicious, Irish, Bbq, Premium, Fun, Classy',
        annual_dol: 458.23,
        description: '"Friendly local bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.8516,
        total_orders: 9952.6,
        neighborhood: 'Flushing/Corona',
        keywords: 'Vodka, Great, Cool, Signature, Complex',
        annual_dol: 13200,
        description:
            'The USTA Billie Jean King National Tennis Center is a stadium complex within Flushing Meadows–Corona Park in Queens, New York City, United States.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.8447,
        total_orders: 4126.52,
        neighborhood: 'Willets Point/Corona/Flushing',
        keywords:
            'Lobster, Lobster Roll, Hotdog, Tequila, Dessert, Margarita, Delicious, Hotel, Rum, Triple Sec',
        annual_dol: 22488.92,
        description:
            "Citi Field is a baseball stadium located in Flushing Meadows–Corona Park in New York City, United States. It opened in 2009 and is the home field of Major League Baseball's New York Mets. The stadium was built as a replacement for the adjacent Shea Stadium, which opened in 1964. Citi Field was designed by Populous.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8558,
        store_longitude: -73.642,
        total_orders: 4220.86,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Tacos, Lobster Tail, Sushi, Salad, Shrimp Cocktail, Gluten Free, Meatball, Spicy',
        annual_dol: 4675.04,
        description:
            'A menu of classic seafood & bistro dishes in a family-friendly setting with rustic decor & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6099,
        store_longitude: -73.9435,
        total_orders: 71.94,
        neighborhood: 'Marine Park',
        keywords: 'Beer',
        annual_dol: 294.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6563,
        store_longitude: -73.6451,
        total_orders: 8355.55,
        neighborhood: '',
        keywords:
            'Steak, Mexican, Tequila, Margarita, Dessert, Peruvian, Savory, Trendy, Spicy, Eclectic',
        annual_dol: 8734.17,
        description:
            'This festive cantina serves standard Mexican eats & takes on a nightclub vibe after dark.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.715,
        store_longitude: -73.5449,
        total_orders: 22.78,
        neighborhood: '',
        keywords: 'Local, Casual, Cool',
        annual_dol: 25.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7503,
        store_longitude: -73.9855,
        total_orders: 1138.35,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Coffee, Trendy, Delicious, Warm, Hotel, Spicy, Italian, Classy, Intimate, Perfect',
        annual_dol: 1187.7,
        description:
            'Retro haunt in Hotel Le Soleil with a New American menu, creative cocktails & a boutique coffee bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8307,
        store_longitude: -73.6863,
        total_orders: 802.42,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Vegetarian, Hamburger, Salad, Gluten Free, Organic, Beer, Sustainable, American',
        annual_dol: 2810.35,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7547,
        store_longitude: -73.9925,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Burger, Hotel, American, Perfect, Friendly, Great, Rooftop, Chic, Unique',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6649,
        store_longitude: -73.6324,
        total_orders: 100.87,
        neighborhood: '',
        keywords: 'Pizza, Italian, Wine, Delicious, Homemade, Casual, Authentic, Great',
        annual_dol: 412.34,
        description: '"Wood Fired Pizza and Italian Cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.657,
        store_longitude: -73.6421,
        total_orders: 129.53,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Gluten Free, Wine, Delicious, Elegant, Polished, Authentic, Cool, Casual',
        annual_dol: 160.17,
        description:
            'Northern Italian fare, brick-oven pizzas & wine in an intimate, upscale-casual setting with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6093,
        store_longitude: -73.9141,
        total_orders: 1146.72,
        neighborhood: 'Mill Basin',
        keywords: 'Wine, Hurricane, Robust, Amazing, Traditional, Modern',
        annual_dol: 1334.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -74.0082,
        total_orders: 586.85,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Hotel, Beer, Spacious, Global, Great, Friendly, Local',
        annual_dol: 599.3,
        description:
            "Modern, loft-style rooms feature 11-foot ceilings, oak floors and bleached wood window seats. All rooms have free Wi-Fi, iPod docks and flat-screen TVs, plus minibars. Room service is offered.Dining options include a farm-to-table restaurant, a courtyard beer garden and a cocktail bar. There's also a 24-hour fitness center and a spa, plus a business center and event space, including 5 high-tech conference rooms, a courtyard and a garden terrace.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8196,
        store_longitude: -73.8174,
        total_orders: 83.21,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Wine, Beer, Coffee, Italian, Gluten Free, Irish, Tangy, Bbq, Spicy, Cozy',
        annual_dol: 340.15,
        description:
            'Relaxed, brick-walled Irish pub & eatery serving eclectic comfort grub such as steaks, fish & wraps.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7066,
        store_longitude: -74.0081,
        total_orders: 358.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Fun, Classic, Fine, Local, Unique',
        annual_dol: 1466.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5816,
        store_longitude: -73.8303,
        total_orders: 134.44,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords: 'Pizza, French, Friendly, American, Local, Great, Casual',
        annual_dol: 153.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5971,
        store_longitude: -73.6591,
        total_orders: 794.91,
        neighborhood: '',
        keywords: 'Lobster, Salad, Delicious, Yummy, Modern, Casual, Sweet, Fun, Cool, American',
        annual_dol: 1662.1,
        description:
            'New England seafood staples & raw-bar options at a waterside haunt with a deck & fire-pit tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6255,
        store_longitude: -73.7362,
        total_orders: 30.56,
        neighborhood: '',
        keywords:
            'Vodka, Shrimp Cocktail, Dessert, Coffee, French, Pinacolada, American, Sweet, Ample, Casual',
        annual_dol: 32.52,
        description:
            'Spacious, old-school diner offering American comfort food favorites all day, plus desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6566,
        store_longitude: -73.6484,
        total_orders: 1057.82,
        neighborhood: '',
        keywords:
            'Tacos, Steak, Burger, Shrimp Cocktail, Cinnamon Roll, Wine, Vodka, Gourmet, Beer, Margarita',
        annual_dol: 1080.26,
        description:
            '"The Ainsworth is an intimate venue that features eclectic rustic decor, bringing a sophisticated twist to the classic restaurant experience. The creative, high-quality menu highlights its 24 karat gold wings and signature steaks along with an extensive craft cocktail and beer menu."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6636,
        store_longitude: -73.645,
        total_orders: 185.33,
        neighborhood: '',
        keywords: 'Salad, Italian, Chinese, Beer, Bbq, Hotel, Local, Hummus',
        annual_dol: 525.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8598,
        store_longitude: -73.6213,
        total_orders: 249.58,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Salad, Italian, Shrimp Cocktail, Welcoming, Wonderful, Great, Casual, Fine',
        annual_dol: 284.68,
        description:
            'Casual, welcoming Italian eatery, offering classics like mussels, gnocchi & housemade limoncello.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.996,
        total_orders: 1669.71,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Vegan, Burger, Coffee, Italian, Martini, Upscale, Contemporary, American, Seasonal',
        annual_dol: 1742.1,
        description:
            'Offshoot of a Russian-based chain for coffee drinks & upscale American & international fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6076,
        store_longitude: -73.943,
        total_orders: 1539.02,
        neighborhood: '',
        keywords:
            'Salad, Shrimp Cocktail, Tasty, Italian, Chinese, Shrimp Scampi, Upscale, Elegant, Warm, Filet Mignon',
        annual_dol: 1571.68,
        description:
            'Upscale Italian eatery from 1964 serving traditional fare in a warm, elegant space with a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6172,
        store_longitude: -73.7302,
        total_orders: 78.91,
        neighborhood: '',
        keywords: 'Steak, Salad, Sushi, French, Upscale, Bbq, Elegant, Prime Rib, Asian, Casual',
        annual_dol: 202.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7688,
        store_longitude: -73.7043,
        total_orders: 7.26,
        neighborhood: '',
        keywords: 'Golfclub, Friendly, Perfect, Clean, Great',
        annual_dol: 9.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7439,
        store_longitude: -73.9227,
        total_orders: 17896.04,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Wine, Whiskey, Beer, Steak, Ale, Margarita, Rustic, Cozy, Spacious, Irish',
        annual_dol: 18516.29,
        description:
            'Intimate neighborhood spot with a warm, rustic feel offering inventive cocktails & craft tap beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6877,
        store_longitude: -73.8207,
        total_orders: 86.92,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Flavorful, Beer, Delicious, Cozy, Eclectic, Adventurous, Exotic, Caribbean, Intimate, Amazing',
        annual_dol: 94.59,
        description:
            'Cozy, energetic club featuring DJs spinning West Indian & Caribbean music, a full bar & VIP areas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7509,
        store_longitude: -73.9841,
        total_orders: 583.78,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Vegan, Tasty, Vegetarian, Beer, German, Wine, Delicious, Spicy, Spacious, Fun',
        annual_dol: 609.09,
        description:
            'Hearty German eats & fine beers are served in a casual, Bavarian publike setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8323,
        store_longitude: -73.699,
        total_orders: 241.64,
        neighborhood: '',
        keywords:
            'French, Italian, Delicious, Comfortable, Homey, Friendly, Contemporary, Casual, Authentic, American',
        annual_dol: 269.05,
        description:
            'Wines from the cellar wash down traditional French plates at this bustling, homey bistro.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9924,
        total_orders: 2373.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Champagne, Rooftop, Hotel, Spacious, Beer, Mediterranean, Moroccan, Fun, American, Great',
        annual_dol: 8974.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.753,
        store_longitude: -73.989,
        total_orders: 120.4,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Tacos, Vegetarian, Mexican, Salad, Burritos, Casual, Healthy, Delicious, Friendly, Local',
        annual_dol: 512.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7781,
        store_longitude: -73.6375,
        total_orders: 287.81,
        neighborhood: '',
        keywords: 'Elegant, Clean, Exquisite, Innovative, Exceptional, Fine, Creative, Local',
        annual_dol: 300.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5815,
        store_longitude: -74.1114,
        total_orders: 195.58,
        neighborhood: 'Todt Hill/Midland Beach',
        keywords: 'Italian, Beer, Rich, Swiss, Mediterranean, Japanese, Sweet, American, Casual',
        annual_dol: 203.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6507,
        store_longitude: -73.8693,
        total_orders: 2782.79,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Steak, Burger, Hamburger, Pizza, Italian, Beer, Wine, Dessert, Chicken Wings, American',
        annual_dol: 3190.45,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6548,
        store_longitude: -73.6458,
        total_orders: 946.49,
        neighborhood: '',
        keywords:
            'Italian, Burger, Pizza, Salad, Gluten Free, Upscale, Comfortable, Casual, American, Clean',
        annual_dol: 978.47,
        description:
            '"Lenox & Park is a neighborhood Italian bistro with a casual, yet sophisticated dining experience. Full service catering for all your party needs."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7508,
        store_longitude: -73.9858,
        total_orders: 16054.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Karaoke, Tasty, Upscale, Delicious, Savory, Inviting, Fun, Exquisite, Vibrant, Premium',
        annual_dol: 22172.21,
        description:
            'Vibrant destination for cocktails, dancing & DJs, plus bar bites & private karaoke rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6899,
        store_longitude: -73.8254,
        total_orders: 282.26,
        neighborhood: 'South Richmond Hill',
        keywords: 'Casual, Spicy, Classic',
        annual_dol: 498.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8302,
        store_longitude: -73.7023,
        total_orders: 1169.74,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Salad, Mexican, Italian, Delicious, Organic, Asian, American, Spectacular',
        annual_dol: 1272.98,
        description:
            'Iconic seafood spot dating to 1905 with deck seating & bay view, plus weekly bands & mahogany bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.751,
        store_longitude: -73.9851,
        total_orders: 858.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Bourbon, Wine, Legendary, Delicious, American, Exceptional, Great, Fine, Casual',
        annual_dol: 881.24,
        description:
            "Dry-aged steak, seafood & pasta surrounded by dark mahogany in John Barrymore's former townhouse.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.657,
        store_longitude: -73.6422,
        total_orders: 781.53,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Pizza, Salad, Dairy Free, Seafood Paella, Italian, Meatball, Gluten Free',
        annual_dol: 815.42,
        description:
            'Italian-American steakhouse with pasta, wine & cocktails in a snug, rustic-chic space with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6652,
        store_longitude: -73.7213,
        total_orders: 4298.68,
        neighborhood: '',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Pizza, Salad, Cajun, Italian, Juicy, Caesar Salad, American',
        annual_dol: 4396.84,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6556,
        store_longitude: -73.6458,
        total_orders: 458.36,
        neighborhood: '',
        keywords: 'Steak, Pizza, Tasty, Italian, Wine, Champagne, Coffee, Dessert, Beer, Delicious',
        annual_dol: 864.57,
        description:
            '"Mangia Bene is an modern Italian restaurant and bar located in Rockville Centre, NY. Specializing in fresh house made pasta and artisan wood fired pizza, Mangia Bene\'s sleek and sexy feel blends a modern take on old world Italian favorites."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6035,
        store_longitude: -73.6509,
        total_orders: 808.79,
        neighborhood: '',
        keywords:
            'Italian, Wine, Classy, Greek, Healthy, Friendly, Traditional, Casual, Sangria, Sophisticated',
        annual_dol: 887.46,
        description:
            '"We are a casually sophisticated Italian restaurant serving traditional and inventive Italian Cuisine."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.706,
        store_longitude: -74.0091,
        total_orders: 5401.46,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Salad, Pizza, Italian, Whiskey, Chilean, Luxurious, Mediterranean, Romantic, Warm, Premium',
        annual_dol: 5635.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.626,
        store_longitude: -73.9275,
        total_orders: 603.28,
        neighborhood: 'Flatlands',
        keywords:
            'Salad, Bbq, Caribbean, Jamaican, French, American, Indian, Authentic, Casual, Great',
        annual_dol: 799.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8131,
        store_longitude: -73.8227,
        total_orders: 43.95,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Local, Creamy',
        annual_dol: 59.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7573,
        store_longitude: -73.8703,
        total_orders: 168.69,
        neighborhood: 'North Corona/East Elmhurst/Flushing',
        keywords:
            'Steak, Salad, Vegetarian, Chicken Caesar Salad, Shrimp Cocktail, Wine, Tasty, Dessert, Beer, Mediterranean',
        annual_dol: 689.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7483,
        store_longitude: -73.9846,
        total_orders: 2400.42,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Beer, Flavorful, Ale, American, Spicy, Tex-Mex, Traditional, Seasonal, Fresh, Local',
        annual_dol: 2789.98,
        description:
            'Branch of local brewery chain has 2 large bars & serves rotisserie chicken & other American food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -73.6028,
        total_orders: 9199.49,
        neighborhood: '',
        keywords:
            'Luxurious, Upscale, Perfect, Modern, Picturesque, Great, Remarkable, Creative, Unforgettable, Heritage',
        annual_dol: 9929.33,
        description:
            '"Renowned Long Island caterers Chateau Briand invites you to experience a truly unique setting for a wide array of events including weddings, birthday parties, family reunions and corporate retreats. Experience the remarkable lobby that boasts twin fireplaces, a baby grand piano, and a picturesque 42-foot staircase."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6556,
        store_longitude: -73.6483,
        total_orders: 340.26,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Vegan, Tequila, Milkshake, Butter Chicken, Dessert, Chinese, American',
        annual_dol: 349.98,
        description:
            'Creative burgers, over-the-top shakes & craft beers are dished out in a funky warehouse setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6036,
        store_longitude: -73.9156,
        total_orders: 3264,
        neighborhood: 'Mill Basin',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Lobster Bisque, Steak, Salad, Pizza, Bbq, Wine, Clam Chowder',
        annual_dol: 3686.96,
        description:
            'Casual seafood restaurant serving fish, lobster & steak with dinner specials & raw bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.9851,
        total_orders: 1072.02,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Burger, Mexican, Sushi, Vodka, Rustic, American, Delicious, Modern, Polished',
        annual_dol: 1094.77,
        description:
            'Street-level American bar & grill in the Empire State Building with art deco design touches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6723,
        store_longitude: -73.7508,
        total_orders: 74.3,
        neighborhood: 'Laurelton/Jamaica/Springfield Gardens',
        keywords: 'Southern, Bbq, Dutch, American',
        annual_dol: 303.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8537,
        store_longitude: -73.6192,
        total_orders: 224.81,
        neighborhood: '',
        keywords:
            'Tasty, Gluten Free, Upscale, Vegetarian, Delicious, Mexican, Friendly, French, Brazilian, Fresh',
        annual_dol: 234.55,
        description:
            '"Upscale New Brazilian Cuisine, French influenced.Charcoal Fire Grill.Offer vegetarian and gluten free options"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7305,
        store_longitude: -74.0006,
        total_orders: 14553.76,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Classic, Authentic, Casual, Hip, Great',
        annual_dol: 14875.95,
        description:
            'A variety of blues, hip-hop, folk and comedy acts take the stage at this classic Village venue.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.8836,
        total_orders: 103.78,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Beer, Colombian, Delicious, Fun, Premium, Ramen, Great, Local, Casual',
        annual_dol: 443.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6982,
        store_longitude: -73.975,
        total_orders: 966.64,
        neighborhood: 'Brooklyn Navy Yard',
        keywords: 'Rooftop, Wine, Iconic, Diverse',
        annual_dol: 3951.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7183,
        store_longitude: -73.9904,
        total_orders: 10091.05,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Japanese, Local, Casual, Sweet, Chill, Great',
        annual_dol: 10878.99,
        description:
            '"Neighborhood bar in the Lower East Side serving beer, wine, spirits, mixed drinks, and cocktails. Free shuffleboard!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.9928,
        total_orders: 13924.59,
        neighborhood: 'Bowery',
        keywords: 'Wine, Thai, Delicious, Bourbon, Gin, Cozy, Meatball, Eclectic, Classy, Fun',
        annual_dol: 16339.38,
        description:
            'Colorful nook offering Thai comfort fare in lunchbox-style to-go boxes plus a small grocery section.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9066,
        store_longitude: -73.8486,
        total_orders: 334.16,
        neighborhood: 'East Bronx/Wakefield',
        keywords: 'Elegant, Perfect, Great',
        annual_dol: 1365.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7669,
        store_longitude: -73.9862,
        total_orders: 213.49,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Pizza, Salad, Wine, Shrimp Cocktail, Italian, Upscale, Beer, Delicious, Mimosa',
        annual_dol: 261.94,
        description:
            'Airy, spacious seafood joint & wine bar specializing in small plates, oysters, crudo & charcuterie.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.822,
        store_longitude: -73.9502,
        total_orders: 834.59,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Tacos, Tequila, Mexican, Burger, Margarita, Vegetarian, Salad, Wine, Spicy, Trendy',
        annual_dol: 1572.24,
        description:
            'Innovative Mexican cuisine & cocktails are served in an earth-toned space with splashes of art.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7406,
        store_longitude: -73.9878,
        total_orders: 15.83,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Mexican, Vegetarian, Burritos, Wine, Gluten Free, French, Local, Great',
        annual_dol: 64.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6333,
        store_longitude: -74.0268,
        total_orders: 170.64,
        neighborhood: 'Bay Ridge',
        keywords: 'Burger, Steak, Pizza, Salad, Mexican, Flavorful, Tasty, Vodka, Beer, Wine',
        annual_dol: 697.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6393,
        store_longitude: -73.5828,
        total_orders: 1502.46,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Wine, Beer, Hurricane, Polished, American, Eclectic, Fun',
        annual_dol: 1751.31,
        description:
            'Nautical Mile mainstay for contemporary seafood with scenic bay views & weekend live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7662,
        store_longitude: -73.9867,
        total_orders: 28.14,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Italian, Wine, Dessert, Beer, Organic, Bruschetta, Filet Mignon, Modern, Caesar Salad',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7708,
        store_longitude: -73.9029,
        total_orders: 10.82,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Burger, Salad, Italian, Coffee, Dessert, Nachos, Spicy, Delicious, Spanish, American',
        annual_dol: 44.22,
        description:
            'Airy, brick-walled bistro & bar offering a diverse menu of American & global fare, plus breakfast.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7425,
        store_longitude: -73.997,
        total_orders: 1402.93,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Vegetarian, Italian, Dessert, Martini, Delicious, Mediterranean, Seasonal, Casual, Traditional',
        annual_dol: 1474.4,
        description:
            'Neighborhood trattoria with a patio & vast Italian wine list serving Venetian pasta & seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6808,
        store_longitude: -73.9756,
        total_orders: 4549.3,
        neighborhood: 'Park Slope',
        keywords:
            'Japanese, Wine, Delicious, Romantic, Legendary, Hotel, Beer, Comfortable, Contemporary, Fantastic',
        annual_dol: 5873.39,
        description:
            'Wood-accented contemporary izakaya offering cocktails & food with a Japanese spin.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5597,
        store_longitude: -74.197,
        total_orders: 128.42,
        neighborhood: 'Arden Heights',
        keywords: 'Pizza, Whiskey, Fun, Unique, Local, Casual',
        annual_dol: 142.88,
        description:
            'Beer, cocktails & bar snacks are served in a low-key setting with TVs, pool tables & dartboards.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7479,
        store_longitude: -74.0042,
        total_orders: 602.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Beer, Cozy, Gourmet, Hotel, Roast Beef, Mediterranean, Eclectic, Fun, Exotic',
        annual_dol: 868.38,
        description:
            'Unpretentious wine bar offering European vintages & small plates in a wood-&-brick–lined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9977,
        total_orders: 475.61,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Hamburger, Salad, Beer, Nachos, Margarita, Wine, American, Vintage',
        annual_dol: 485.7,
        description:
            'Lounge with a roadhouse vibe featuring vintage Americana & classic eats (burgers, tater tots, etc.).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7404,
        store_longitude: -73.9959,
        total_orders: 297.74,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Burger, Cozy, Rooftop, Creative, Quirky, Lit, Unique',
        annual_dol: 1217.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.687,
        store_longitude: -73.9777,
        total_orders: 24.17,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Beer, Tasty, Cozy, Classy, Classic, Traditional, Mediterranean, Intimate, Iconic',
        annual_dol: 98.8,
        description:
            'Down-to-earth, cozy gathering place serving small plates, draft beer & imported wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -73.9786,
        total_orders: 935.02,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Irish, Eclectic, Local, Casual, Great, Unique',
        annual_dol: 954.86,
        description:
            'Relaxed, brick-walled bar with a pool table, Skee-Ball & a juke box, plus frequent live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5889,
        store_longitude: -73.6458,
        total_orders: 2385.64,
        neighborhood: '',
        keywords:
            'Burger, Salad, Mexican, Italian, Asian, Quesadillas, Homemade, Clean, Fun, American',
        annual_dol: 2596.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7172,
        store_longitude: -73.9429,
        total_orders: 361.21,
        neighborhood: 'Williamsburg',
        keywords: 'Cozy, Burger, Steak, Wine, Salad, Upscale, Delicious, Trendy, Beer, Classy',
        annual_dol: 601.96,
        description:
            'Informal plates, beer & spirits in an easygoing tavern with indoor & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7116,
        store_longitude: -73.9461,
        total_orders: 1953.61,
        neighborhood: 'Williamsburg',
        keywords: 'Gluten Free, Tasty, Coffee, Spacious, Irish, Asian, Casual',
        annual_dol: 2903.17,
        description:
            'Neighborhood bar pouring beers on tap & a full line-up of booze, with a pool table & a back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6653,
        total_orders: 439.78,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Sushi, Italian, Mexican, Japanese, Crab Rangoon, Martini, Asian, Funky',
        annual_dol: 710.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7177,
        store_longitude: -73.9563,
        total_orders: 268.92,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Ale, Wine, Mead, Laid-Back, Refreshing, Spectacular, Great, Chill, Casual',
        annual_dol: 1099.24,
        description: '"Microbrewery, bar and restaurant."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7163,
        store_longitude: -73.961,
        total_orders: 1167.32,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, German, Salad, Tasty, Hungarian, Spicy, Casual, Classic, Chill, Great',
        annual_dol: 1303.74,
        description:
            'Sprawling, boisterous beer hall offering brews, schnitzel & regularly scheduled live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 3118.88,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Beer, Wine, Ramen, Gin, Spicy, American, Asian, Japanese, Sleek, Cointreau',
        annual_dol: 3301.28,
        description:
            'Busy, casual walk-in Asian outlet in a shopping mall serving noodles & ramen from the open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7201,
        store_longitude: -73.9878,
        total_orders: 2675.82,
        neighborhood: 'Lower East Side',
        keywords: 'Tacos, Wine, Beer, Martini, Trendy, Spicy, Romantic, Thai, Asian, French',
        annual_dol: 2889.02,
        description:
            'Swanky cocktail lounge known for its Asian tapas, revolving art shows & lychee martinis.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7702,
        store_longitude: -73.9765,
        total_orders: 11.26,
        neighborhood: 'Central Park West Historic District',
        keywords: 'Burger, Beer, Casual, Friendly, Great, Sangria',
        annual_dol: 46.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5452,
        store_longitude: -74.1659,
        total_orders: 314.24,
        neighborhood: 'Arden Heights',
        keywords: 'Steak, Italian, Pizza, Coffee, Upscale, Delicious, Excellent, Casual, Local',
        annual_dol: 384.37,
        description:
            'Brick-oven pizzas, Italian dishes & cocktails served in down-to-earth surrounds with a heated patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7299,
        store_longitude: -73.9809,
        total_orders: 2156.35,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Meatball, Wine, Spicy, Ale, American, Eclectic, Vintage, Casual',
        annual_dol: 2202.11,
        description:
            'Convivial hangout with vintage decor offering eclectic bar fare, cocktails & beers on tap.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7114,
        store_longitude: -73.9446,
        total_orders: 406.96,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Italian, Pizza, Wine, Trendy, Ale, Premium, Relaxed, Contemporary, Perfect, Great',
        annual_dol: 473.71,
        description:
            'Relaxed storefront serving lesser known Italian pastas & small plates plus wine & backyard seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7253,
        store_longitude: -73.9833,
        total_orders: 2088.94,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Wine, Friendly, Casual, Great, Diverse',
        annual_dol: 2133.26,
        description:
            'Buzzy, celeb-owned bar with drag & cabaret shows, plus other diverse entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 307.62,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Steak, Burger, Salad, Beer, Dessert, Chicken Wings, Ale, Tex-Mex, American, Casual',
        annual_dol: 498.74,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -73.9604,
        total_orders: 742.62,
        neighborhood: 'Williamsburg',
        keywords: 'Salad, Steak, Korean, Wine, Beer, Bbq, Organic, Japanese, Tangy, Cozy',
        annual_dol: 889.19,
        description:
            'Busy Korean-style BBQ eatery with tableside grills & veggie options in a cozy ambiance.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9566,
        total_orders: 510.31,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Salad, Breakfast Burrito, Authentic, Fresh',
        annual_dol: 2085.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9839,
        total_orders: 0,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Mexican, Lobster Bisque, American, Contemporary, Delicious, Casual, Clam Chowder, Local, Traditional',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6623,
        store_longitude: -73.9621,
        total_orders: 921.13,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords: 'Zombie, Wine, Bbq, Juicy, Cozy, American, Warm, Crisp, Spacious, Greek',
        annual_dol: 1737.45,
        description:
            'Creative bar eats & craft cocktails served in chill digs with a pool table, fireplace & patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.723,
        store_longitude: -73.9975,
        total_orders: 2636.58,
        neighborhood: 'Lower Manhattan',
        keywords: 'Champagne, Vodka, Hotel, Zombie, Beer, Gin, Rum, Pizza, Eclectic, Moscow Mule',
        annual_dol: 3014.27,
        description:
            "and 2 miles from the Empire State Building.Sophisticated rooms with high ceilings and floor-to-ceiling windows feature smart TVs, Blu-ray players, Bose systems and free Wi-Fi, plus minibars. Suites add sitting areas; upgraded suites with 1 or 2 bedrooms have separate living rooms with dining areas, and terraces. Room service is available 24/7.There's a plush restaurant with a bar and a seasonal terrace, a posh lounge and a gym, as well as a rooftop garden.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7581,
        store_longitude: -73.7874,
        total_orders: 822.92,
        neighborhood: 'Auburndale/Flushing',
        keywords: 'Burger, Sushi, Salad, Pizza, Gourmet, Korean, Wine, Spicy, Delicious, Trendy',
        annual_dol: 3680.08,
        description:
            '"THE BANDI = FIREFLY KOREAN STYLE BAR Voted New York’s Best Korean Style Bar & Foods It has always been a dream of Sara’s to open a small place where New Yorkers could relax to an evening of delicious Korean Food and fun drinks. After 20 years of working in the corporate world, she decided to pursue her passion for cooking. As such, Sara opened her first bar and restaurant in Queens to provide people with refreshing food and beverages. Sara believes in using fresh ingredients in a simple way so they bring out their own essence. Inspired by her love for foods from all over the world, she has added a Korean flare to a simple and healthy menu. With unique and appealing Soju cocktails, this establishment has a pleasant experience waiting..."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8147,
        store_longitude: -73.9594,
        total_orders: 1288.49,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Salad, Hamburger, Tasty, Wine, Bread Pudding, French Toast, Beer, French, Bbq',
        annual_dol: 1315.83,
        description:
            'American comfort fare like burgers & sandwiches in a roomy, retro diner space plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8181,
        store_longitude: -73.9608,
        total_orders: 1569.99,
        neighborhood: 'Upper Manhattan',
        keywords: 'Burger, Beer, Bbq, Tasty, American, Healthy, Fun, Friendly, Casual, Perfect',
        annual_dol: 3817.64,
        description:
            'Budget pub grub & brews are served at this bustling bar with TVs for watching sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7646,
        store_longitude: -73.992,
        total_orders: 1073.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Fun, Fancy, Mediterranean, Awesome, Israeli, Great, American, Asian',
        annual_dol: 1142.64,
        description:
            'Locavore spot from Israeli celebrity chef with a reservations-only experience & a theatrical vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9814,
        total_orders: 9529.77,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Beer, Spicy, Karaoke, New-York-Style, Wine, Warm, Irish, Gin, Rum',
        annual_dol: 9762.72,
        description:
            'Watering hole with a Prohibition theme serving craft cocktails, beer, wine & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7645,
        store_longitude: -73.9773,
        total_orders: 1643.34,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Wine, Vodka, Salad, Italian, American, Hotel, Mediterranean, Homey, Premium',
        annual_dol: 5769.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.976,
        total_orders: 669.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Pizza, Steak, Italian, Dessert, Korean, Californian, Organic, Upscale, Spicy',
        annual_dol: 683.34,
        description:
            'Stylish, art-adorned Californian eatery frequented by high-profile media personalities.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7223,
        store_longitude: -73.992,
        total_orders: 7065.8,
        neighborhood: 'Bowery',
        keywords: 'Whiskey, Beer, Wine, German, Trendy, Upscale, American, Warm, Fun, Classic',
        annual_dol: 7305.83,
        description:
            'Bar specializing in whiskey, served in a space lined with subway tiles and antique mirrors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9874,
        total_orders: 393.32,
        neighborhood: 'Lower East Side',
        keywords:
            'Tasty, Nutritious, Mexican, Delicious, Margarita, Savory, Salad, Nachos, South American, Eclectic',
        annual_dol: 491.05,
        description:
            'Local stalwart for standard Mexican fare & margaritas in a no-frills setting that keeps late hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.99,
        total_orders: 2.42,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Beer, Wine, Karaoke, Margarita, Fun, Friendly, Great, Cool, Iconic, Edgy',
        annual_dol: 9.88,
        description:
            'Storied punk rock bar & music club with an edgy backdrop of graffiti-covered walls & booths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6248,
        store_longitude: -74.0303,
        total_orders: 5.09,
        neighborhood: 'Bay Ridge',
        keywords:
            'Lasagna, Pizza, Steak, Italian, Vodka, Martini, Delicious, Homemade, Fabulous, Fresh',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7385,
        store_longitude: -74,
        total_orders: 370.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Salad, Vietnamese, Exquisite, Asian, Exotic, Fresh, Great, Excellent, Casual',
        annual_dol: 434.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 26.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Mexican, Italian, Gin, Premium, Stylish, Global, Great, Chill',
        annual_dol: 108.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7408,
        store_longitude: -73.7844,
        total_orders: 5389.15,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords: 'Steak, Burger, Tacos, Beer, Salad, Pizza, Fresh, Creamy, Dessert, Tangy',
        annual_dol: 5503.5,
        description:
            'Sports bar & grill chain famous for chicken wings served by waitresses in short shorts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7175,
        store_longitude: -73.9501,
        total_orders: 4687.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Pizza, Salad, Italian, Chinese, Phenomenal, French, American, Sweet, Local',
        annual_dol: 4901.39,
        description:
            'Relaxed, light-filled eatery with a horseshoe bar serving American plates & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.631,
        store_longitude: -74.1035,
        total_orders: 603.23,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Steak, Vegetarian, Chocolate Mousse, Tasty, Gourmet, Southern Comfort, Bourbon, Beer, Coffee, Bbq',
        annual_dol: 1156.99,
        description:
            '"Sally\'s Southern is Staten Island\'s only southern comfort and BBQ restaurant Proudly serving craft cocktails, an extensive beer and bourbon list and gourmet southern fare. Our namesake is homage to the late Sally Curtis. In her words and her spirit #Live Love Laugh and # Eat and Enjoy!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.718,
        store_longitude: -73.9525,
        total_orders: 2466.72,
        neighborhood: 'Williamsburg',
        keywords: 'Vegan, Beer, Wine, Modern, Hotel, Funky, American, Delicious, Perfect, Fresh',
        annual_dol: 2519.06,
        description:
            '"Thief, the first solo venture from John McNulty (Swine, Cocktail Kingdom Hospitality Group), has a mission to steal back the time spent away from your favorite bar. A modern take on the neighborhood bar, Thief is inspired in design and energy by the 1980\'s NYC graffiti, art and music scene. The menus feature riffs on classic cocktails including frozen drinks such as a Friesling, an extensive wine program and elevated bar food from Smoked Ham & Gruyere sandwiches to Vegan Mini Corn Dogs."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.9767,
        total_orders: 418.48,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Lobster, Hamburger, Salad, Beer, Gourmet, Wine, French Toast, American, French, Contemporary',
        annual_dol: 1710.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -73.9607,
        total_orders: 300.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Korean, Spicy, Delicious, Urban, Organic, Savory, American, Nachos, Rooftop, Sweet',
        annual_dol: 567.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.978,
        total_orders: 153.04,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Korean, Beer, Spicy, Homemade, Delicious, Bbq, Wine, Cozy, Friendly, Fun',
        annual_dol: 537.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9777,
        total_orders: 5.36,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Wine, Salad, Dessert, Delicious, Nutritious, Beer, Mediterranean, Healthy, Turkish, Modern',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.752,
        store_longitude: -73.9912,
        total_orders: 5546.56,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Stylish, Chic, Creative, Excellent, Contemporary',
        annual_dol: 5666.75,
        description:
            'Theater District.Warm, contemporary rooms feature free Wi-Fi and flat-screen TVs, marble bathrooms and minifridges. Many have city and/or skyline views, and some have balconies or terraces. Suites add living rooms. Room service is available.An airy restaurant serves international cuisine and has a dining terrace. There are several stylish lounges and bars, as well as a fitness center, and an alcove lined with digital screens with constantly shifting scenery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 1588.22,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Wine, Rich, Velvety, Hotel, Romantic, Spicy, Rum, Inviting, Premium, Charming',
        annual_dol: 1657.08,
        description:
            'Cozy, vintage-style lounge with velvet seating for elevated craft cocktails & sophisticated snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7004,
        store_longitude: -73.8102,
        total_orders: 69.98,
        neighborhood: 'Jamaica',
        keywords: 'Fun, Impeccable, Cool',
        annual_dol: 148.37,
        description:
            'Spacious, high-energy nightspot featuring dancing, DJs & live performances plus cocktails & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.9794,
        total_orders: 464.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Sushi, Italian, Delicious, Red Eye, French, Romantic, American, Innovative, Robust',
        annual_dol: 494.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7426,
        store_longitude: -74.0002,
        total_orders: 1159.72,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Tomato Soup, Mexican, French, Coffee, Beer, Margarita, Cozy, American',
        annual_dol: 1658.89,
        description:
            'Two-level restaurant serving casual French fare paired with craft cocktails, beer & Champagne.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7426,
        store_longitude: -74.0006,
        total_orders: 2229.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Tacos, Tasty, Wine, Salad, Spicy, Chinese, Innovative, American, Asian',
        annual_dol: 2303.73,
        description:
            'Hip, airy hangout with exposed-brick walls pairing craft beer with innovative bar fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7411,
        store_longitude: -74.0013,
        total_orders: 594.12,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Pizza, Flavorful, Italian, Delicious, Rich, Warm, Cozy, Romantic, Rustic',
        annual_dol: 625.42,
        description:
            'Old-school white-tablecloth Italian eatery serving homemade pastas from a wood-burning oven.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7397,
        store_longitude: -73.9962,
        total_orders: 63.16,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Tasty, Delicious, Chilean, Salad, Premium, Robust, Impressive, Perfect, Great',
        annual_dol: 114.64,
        description:
            'White-tablecloth eatery with classic Mediterranean sharing plates & mains, plus a robust wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7073,
        store_longitude: -74.0063,
        total_orders: 3112.32,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Italian, Warm, Modern, Classic, Friendly, American, Seasonal, Rooftop, Casual',
        annual_dol: 3461.81,
        description:
            "the Brooklyn Bridge.The simple, modern rooms have free Wi-Fi and bottled water, plus 42-inch flat-screen TVs, and desks with ergonomic chairs. Suites add pull-out sofas and separate living areas.There's a casual American restaurant and a rooftop bar with a seasonal terrace, as well as a 24/7 fitness room. Additional amenities include 7 meeting rooms and a business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6715,
        store_longitude: -73.9627,
        total_orders: 3611.02,
        neighborhood: '',
        keywords: 'Friendly, Great, Fresh, Excellent, Casual, Cultural, Seasonal',
        annual_dol: 14760.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7136,
        store_longitude: -73.9413,
        total_orders: 40.15,
        neighborhood: 'East Williamsburg/Williamsburg',
        keywords:
            'Vegan, Champagne, Coffee, Martini, Organic, Delicious, Seasonal, Latin, Friendly, Sweet',
        annual_dol: 72.97,
        description:
            'Quaint, family-run cafe preparing seasonal Guatemalan-inspired breakfast, lunch & dinner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.714,
        store_longitude: -73.9562,
        total_orders: 2422.67,
        neighborhood: 'Williamsburg',
        keywords:
            'Cornbread, Whiskey, Salad, Beer, Gluten Free, Bourbon, Spicy, Bbq, German, Sweet',
        annual_dol: 2434.07,
        description:
            'Popular dry-rub BBQ, beer & bourbon purveyor with an industrial, cafeteria-style decor & wall mural.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 331.95,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Lobster, Lobster Roll, Shrimp Cocktail, Salad, Gluten Free, Spicy, Martini, Avocado Toast, Organic',
        annual_dol: 1356.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7108,
        store_longitude: -73.9509,
        total_orders: 71.21,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Tacos, Mexican, Steak, Nachos, Salad, Beer, Margarita, Spicy, Friendly, Fresh',
        annual_dol: 295.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.9806,
        total_orders: 24220.94,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Wine, Japanese, Spicy, Delicious, Upscale, Trendy, Sashimi, Cozy, Spacious',
        annual_dol: 29477.85,
        description:
            'Trendy, high-end Japanese place with an izakaya-inspired menu including sushi & robata grilled fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.9256,
        total_orders: 17160.19,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Lobster, Cajun, Whiskey, Bbq, Wine, Organic, American, French',
        annual_dol: 20010.99,
        description:
            'This eatery offers twists on New American comfort fare in a contemporary updated-diner setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7469,
        store_longitude: -73.9804,
        total_orders: 1342.82,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Salad, Pizza, Italian, Wine, Delicious, Gourmet, Upscale, Warm, Ale, Mediterranean',
        annual_dol: 1385.43,
        description:
            'Decades-old Italian restaurant offering an array of traditional fare in an upscale setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7511,
        store_longitude: -73.9803,
        total_orders: 808.8,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Salad, Swiss, Irish, French, Fried Shrimp, Greek, Hotel, Greek Salad, Casual',
        annual_dol: 861.95,
        description:
            'Laid-back pub with busy weekday happy hours serves traditional Irish bar food late into the night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9241,
        total_orders: 570.1,
        neighborhood: 'Astoria/LIC',
        keywords: 'Pizza, Italian, Salad, Wine, Vodka, Delicious, Swiss, Bbq, Homemade, Yummy',
        annual_dol: 594.81,
        description:
            'Family-run local mainstay serving up crispy coal-oven pizzas plus pastas in a simple setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.987,
        total_orders: 150.05,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Luxurious, Delicious, Savory, Spacious, Impeccable, Hotel, American, Bright',
        annual_dol: 166.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.9791,
        total_orders: 16100.17,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Hamburger, French, Italian, French Onion Soup, Delicious, American, Contemporary, Whimsical, Classic',
        annual_dol: 21917.39,
        description:
            'Elevated brasserie serving contemporary takes on French classics in a whimsical multi-room space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7645,
        store_longitude: -73.9833,
        total_orders: 4415.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Scotch, Wine, Shrimp Scampi, Gluten Free, Upscale, Cosmopolitan, American',
        annual_dol: 5191.05,
        description:
            'Contemporary chophouse at Hilton Midtown with a classic menu including seafood & California wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9786,
        total_orders: 4271.26,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Tequila, Coffee, Hotel, Delicious, American, Elegant, Velvety, Spacious, Rustic',
        annual_dol: 4456.44,
        description:
            "Sleek rooms are warmly decorated with cherry and cedar wood furniture, and include minibars, flat-screen TVs and free Wi-Fi, plus work desks with ergonomic chairs; suites add full kitchens, living areas and views of Central Park.There are 2 acclaimed restaurants, plus a burger joint and a hip cocktail bar. There's also a gym, a rooftop pool, and a multilevel fitness center with racquetball and basketball courts (as well as personal trainers), plus a spa. Other amenities include a barbershop, a salon and an event ballroom.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9813,
        total_orders: 194.28,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Sushi, Lobster, Beer, Japanese, Tasty, Sashimi, Hotel, American, Sweet, Casual',
        annual_dol: 794.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.9901,
        total_orders: 593.35,
        neighborhood: 'Bowery',
        keywords:
            'Sushi, Lobster, Japanese, Impeccable, Amazing, Fantastic, Incredible, Great, Excellent, Prosecco',
        annual_dol: 605.94,
        description:
            'Intimate, moody eatery supplying set & a la carte menus of creative maki & nigiri, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7205,
        store_longitude: -73.9902,
        total_orders: 283.22,
        neighborhood: 'Bowery',
        keywords:
            'Burger, Steak, Dairy Free, Salad, Gluten Free, Meatball, Coffee, Beer, Breakfast Burrito, Wine',
        annual_dol: 639.04,
        description:
            'Hip gathering place serving seasonally-driven, global plates & cocktails, plus a happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7433,
        store_longitude: -73.9893,
        total_orders: 105.72,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Mexican, Margarita, Wine, Trendy, Spicy, Delicious, Vibrant, Sophisticated, Friendly, Authentic',
        annual_dol: 432.15,
        description:
            'Trendy counter-serve with a patio & a Mexican menu created by celebrity chef Rick Bayless.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7798,
        store_longitude: -73.8026,
        total_orders: 162.76,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Lobster, Steak, Vegetarian, Salad, Tasty, Wine, Coffee, Dessert, Apple Pie, Grilled Salmon',
        annual_dol: 173.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.9815,
        total_orders: 6095.9,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Wine, Godfather, American, Classic, Friendly, Authentic, Sweet, Awesome, Great',
        annual_dol: 6554.71,
        description:
            'Long-running, no-nonsense bar featuring a jukebox loaded with punk & an old-school photobooth.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7248,
        store_longitude: -73.9812,
        total_orders: 1148.58,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Steak, Burger, Salad, French, Wine, Upscale, Gin, Retro, Polished, Casual',
        annual_dol: 1150.05,
        description:
            'Parisian-style bistro dishes & wine served in a cozy, retro space enhanced by back garden seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7135,
        store_longitude: -73.9617,
        total_orders: 2639.33,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Tequila, Beer, Lobster, Lobster Roll, Margarita, Warm, Hot Toddy, Friendly, Awesome, Cool',
        annual_dol: 2695.33,
        description:
            'Jumping, brick-walled watering hole featuring booze, a dog-friendly policy, games & a backyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7183,
        store_longitude: -73.9521,
        total_orders: 80.26,
        neighborhood: 'Williamsburg',
        keywords:
            'Tacos, Tequila, Mexican, Margarita, Steak, Dessert, Mimosa, Upscale, Trendy, Delicious',
        annual_dol: 145.5,
        description:
            'Cool locale for tacos, ceviche & upscale Mexican dishes with micheladas & other cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6831,
        store_longitude: -73.9657,
        total_orders: 1155.09,
        neighborhood: 'Clinton Hill',
        keywords:
            'Beer, Whiskey, Irish, Bourbon, Mead, Premium, Awesome, Great, Casual, Unpretentious',
        annual_dol: 1257.04,
        description:
            'Industrial-chic bar specializing in craft beers, also offering cocktails, bar snacks & sandwiches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7131,
        store_longitude: -73.9494,
        total_orders: 123.83,
        neighborhood: 'Williamsburg',
        keywords: 'Sushi, Tacos, Salad, Wine, Spicy, Beer, Savory, Whiskey, Hawaiian, Japanese',
        annual_dol: 506.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.9776,
        total_orders: 473.16,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Hamburger, Salad, Beer, Tasty, Wine, Juicy, Upscale, French, Armenian',
        annual_dol: 852.48,
        description:
            'East Village gastropub serving American comfort food in shareable small plates, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7178,
        store_longitude: -73.9524,
        total_orders: 122.53,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Salad, French, Italian, Swiss, Modern, Savory, Delicious, Casual, Perfect',
        annual_dol: 158.01,
        description: '"Modern French restaurant in the heart of Williamsburg"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.9836,
        total_orders: 24271.51,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Sushi, Spacious, Korean, Bright, Great, Excellent, Modern, Intimate, Chic',
        annual_dol: 26570.86,
        description:
            'The minimalist, modern rooms, suites and studios provide free Wi-Fi, flat-screen TVs and coffeemakers, as well as minifridges and floor-to-ceiling windows. Unique features found in some quarters include original artwork, kitchens, balconies, a rooftop deck and/or a pull-out sofa.Breakfast at a neighboring cafe is complimentary. There are also two intimate sushi restaurants.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9766,
        total_orders: 19724.62,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Italian, Pizza, Shrimp Cocktail, Wine, Flavorful, Bourbon, Beer, Delicious, American',
        annual_dol: 20273.99,
        description:
            'Steakhouse with striking, contemporary design, plus an upmarket menu of chops & Italian fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.9829,
        total_orders: 454.72,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Pizza, Garlic Bread, Italian, Meatball, Salad, Tasty, Delicious, Homemade, Fresh, Local',
        annual_dol: 494.85,
        description:
            'Family-friendly pizzeria serving thin-crust pies alongside calzones, pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6632,
        store_longitude: -73.6989,
        total_orders: 26.47,
        neighborhood: '',
        keywords: 'French, Casual, Fresh, Hip',
        annual_dol: 108.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.985,
        total_orders: 11.39,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Modern',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.8811,
        total_orders: 72.19,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Burger, Sushi, Steak, Salad, Korean, Spanish, Asian, Healthy, Casual, Natural',
        annual_dol: 295.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6821,
        store_longitude: -73.9796,
        total_orders: 526.7,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Beer, Cozy, Relaxed, Friendly, Cool, Chic, Casual, Great, Hummus',
        annual_dol: 1229.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6819,
        store_longitude: -73.9763,
        total_orders: 625.67,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Lobster, Steak, Vegan, Beer, Wine, Juicy, Delicious, Caribbean, Warm',
        annual_dol: 1135.65,
        description:
            'Warm fixture for Caribbean cuisine including jerk chicken, guava wings & fried plantain, plus a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6807,
        store_longitude: -73.9747,
        total_orders: 474.56,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Vegetarian, Coffee, Organic, Zombie, Friendly, Modern, Casual, Ample, Cool, Sweet',
        annual_dol: 704.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7365,
        store_longitude: -73.8026,
        total_orders: 108.41,
        neighborhood: 'Fresh Meadows/Flushing/Pomonok',
        keywords:
            'Roast Turkey, Dessert, Fresh, American, Friendly, Contemporary, Traditional, Classic, Irish, Great',
        annual_dol: 407.42,
        description:
            'Alehouse in a Prohibition-era building providing contemporary American fare, including Irish nachos.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.974,
        total_orders: 592.64,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Sushi, Steak, Dessert, Spicy, Delicious, Stylish, Japanese, Thai, Gin, Chinese',
        annual_dol: 644.94,
        description:
            'Relaxed, stylish bistro serving modern takes on Asian street-food favorites, plus sake & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7809,
        store_longitude: -73.9474,
        total_orders: 88.88,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Coffee, Dessert, Hotel, Premium, Local',
        annual_dol: 363.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7225,
        store_longitude: -73.9899,
        total_orders: 11874.18,
        neighborhood: 'Bowery',
        keywords:
            'Japanese, Tasty, Contemporary, Premium, Intimate, Traditional, Casual, Attentive, Okonomiyaki, Great',
        annual_dol: 12669.37,
        description:
            'Kenta Goto (ex Pegu Club) offers cocktails & food with a Japanese spin at this contemporary izakaya.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.9877,
        total_orders: 2111.06,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Italian, Delicious, Authentic, Great, Creative, Fresh, Local, Complex, Unique',
        annual_dol: 2483.36,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7188,
        store_longitude: -73.9921,
        total_orders: 293.66,
        neighborhood: 'Bowery',
        keywords:
            'Vodka, Pizza, Champagne, Scotch, Shawarma, Mediterranean, Casual, Friendly, Modern, Cool',
        annual_dol: 717.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6928,
        store_longitude: -73.8561,
        total_orders: 33.62,
        neighborhood: 'Woodhaven',
        keywords:
            'Beer, Ecuadorian, Mexican, Coffee, Chinese, American, Latin, Awesome, Great, Casual',
        annual_dol: 86.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.639,
        store_longitude: -73.5816,
        total_orders: 21.04,
        neighborhood: '',
        keywords: 'Dominican, Memorable',
        annual_dol: 86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6874,
        store_longitude: -73.9864,
        total_orders: 1621.36,
        neighborhood: 'Boerum Hill',
        keywords: 'Burger, Tacos, Beer, Whiskey, Wine, Vegetarian, Rum, American, Cozy, Delicious',
        annual_dol: 1891.38,
        description:
            'A robust craft beer menu & typical dishes offered in a cozy venue with brick walls & tin ceilings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7407,
        store_longitude: -73.9929,
        total_orders: 563.91,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Delicious, Rich, Salad, Wine, Greek, Meatball, Outstanding, Classy, Authentic, Great',
        annual_dol: 575.87,
        description:
            'Established Greek restaurant noted for its high-end fare & civilized, atmospheric setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5423,
        store_longitude: -74.1772,
        total_orders: 541.59,
        neighborhood: 'Arden Heights',
        keywords:
            'Burger, Vegetarian, Gourmet, Gluten Free, Italian, Organic, Mediterranean, American, Homemade, Casual',
        annual_dol: 675.48,
        description:
            'Bustling local spot dishes up sizeable portions of classic diner fare & homemade Italian specialties',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.74,
        store_longitude: -73.9932,
        total_orders: 93.54,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Tequila, Upscale, Delicious, Contemporary, Casual, Intimate, Modern, Sweet',
        annual_dol: 241.03,
        description:
            'Classy, intimate venue featuring familiar Mexican dishes, plus an extensive drinks menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -73.9798,
        total_orders: 817.76,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Beer, Delicious, Warm, Comfortable, Friendly, Eclectic, Fancy, Asian, Great',
        annual_dol: 910.32,
        description:
            'Small, easygoing bar & community event space featuring seasonal cocktails & a backyard beer garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.695,
        total_orders: 51.93,
        neighborhood: '',
        keywords: 'Hamburger, Friendly',
        annual_dol: 74.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -73.9798,
        total_orders: 67.3,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Beer, Delicious, Warm, Comfortable, Friendly, Eclectic, Fancy, Traditional, Great',
        annual_dol: 275.08,
        description:
            'Small, easygoing bar & community event space featuring seasonal cocktails & a backyard beer garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 2520.91,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Rooftop, Shrimp Cocktail, Bbq, Wine, Salad, Global, Amazing, Sweet',
        annual_dol: 10304.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7291,
        store_longitude: -73.9809,
        total_orders: 40.88,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Salad, Spicy, Delicious, Japanese, Thai, Romantic, Asian, Hotel, Smoky, Sweet',
        annual_dol: 167.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.748,
        store_longitude: -73.9839,
        total_orders: 280.95,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Iconic, Spectacular, Seasonal, Casual',
        annual_dol: 393.6,
        description:
            'Club-like setting for virtually fighting zombies, slaying dragons & flying, plus a cocktail lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.808,
        store_longitude: -73.945,
        total_orders: 28501.8,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Cornbread, Salad, Wine, Dessert, Gluten Free, Beer, Stylish, Sweet, American, Southern',
        annual_dol: 29382.75,
        description:
            'Popular, stylish comfort food eatery with creative cooking from celeb chef Marcus Samuelsson.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7064,
        store_longitude: -73.8083,
        total_orders: 221.41,
        neighborhood: 'Briarwood/Jamaica',
        keywords:
            'Champagne, Margarita, Hawaiian, Rum, Caribbean, Quaint, Blue Hawaiian, Colorful, Casual',
        annual_dol: 695.96,
        description:
            'Laid-back, colorful club featuring DJs playing Caribbean dance music & island-inspired cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.9943,
        total_orders: 1719.22,
        neighborhood: 'Bowery',
        keywords:
            'Stylish, Glamorous, Hotel, Coffee, Eclectic, Fun, Playful, Perfect, Clean, Modern',
        annual_dol: 7027.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6232,
        store_longitude: -74.031,
        total_orders: 303.42,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Coffee, Beer, Italian, Cozy, Elegant, Hotel, Rustic, Comfortable, Creamy, Mediterranean',
        annual_dol: 431.05,
        description:
            'Neighborhood fixture producing whole fish & other Greek eats in a traditional taverna setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6837,
        store_longitude: -73.9671,
        total_orders: 277.1,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Hamburger, Italian, Tasty, Hotel, American, Delicious, Rum, Indian, Casual',
        annual_dol: 294.89,
        description:
            "\"VESSEL BAR & GRILL is located in the heart of downtown Brooklyn's Fulton Street at Clinton Avenue. Offering lunch, dinner and late night dining with full bar services, we're only a short cab or pleasant walk from most of Brooklyn's major attractions.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.9491,
        total_orders: 106.81,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Beer, Wine, Whiskey, Gin, Cozy, Comfortable, Impeccable, Friendly, Casual',
        annual_dol: 131.05,
        description:
            'Craft cocktails from a cozy saloon in a distillery with exposed brick & views of the machinery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6501,
        store_longitude: -73.95,
        total_orders: 75.19,
        neighborhood: 'Little Haiti',
        keywords: 'Wine, Whiskey, Beer, Hotdog, Trendy, Romantic, Cozy, Premium, American, Classy',
        annual_dol: 307.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7128,
        store_longitude: -73.9568,
        total_orders: 194.09,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Coffee, Beer, Wine, Chinese, American, Natural, Fresh, Bright, Casual',
        annual_dol: 328.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7208,
        store_longitude: -73.9554,
        total_orders: 2025.57,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotel, Elegant, Stylish, Rooftop, Coffee, Vibrant, Lively, Modern, Perfect, Great',
        annual_dol: 2633.55,
        description:
            "The contemporary rooms and suites have minibars, free Wi-Fi and flat-screen TVs. Some have city views.An elegant restaurant serves breakfast and dinner. There's a lively rooftop lounge with Manhattan skyline views, and a seasonal outdoor saltwater pool. Guests get free bike rentals; there's also on-site parking (fee).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9219,
        total_orders: 204.98,
        neighborhood: 'Astoria/LIC',
        keywords: 'Mexican, Tacos, Nachos, Gourmet, Warm, Casual, Authentic, Local, Fresh, Rare',
        annual_dol: 771.92,
        description:
            'Narrow, down-to-earth restaurant specializing in homestyle Mexican cooking, cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.9754,
        total_orders: 293.33,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Wine, Chinese, Beer, Rum, Spicy, Elegant, Warm, Premium, Asian, Fusion',
        annual_dol: 1605.55,
        description:
            'Bi-level eatery serving a Shanghainese-centric menu in an elegant setting inspired by Old Shanghai.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9836,
        total_orders: 34.09,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Lasagna, Wine, Salad, Steak, Vodka, Pizza, Italian, Ale, Bruschetta, Elegant',
        annual_dol: 139.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9836,
        total_orders: 151.44,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Thai, Coffee, Chinese, Spicy, Delicious, Ale, Fried Rice, Artisanal, Traditional',
        annual_dol: 633.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.985,
        total_orders: 1056.43,
        neighborhood: 'Lower East Side',
        keywords:
            'Wine, Seasonal, Burger, Contemporary, French, Modern, American, Global, Asian, Casual',
        annual_dol: 1212.02,
        description:
            '"Our intimate, seasonal menu of modern American fare takes cues from Chef Sam Clonts’ time in some of New York City’s best kitchens; reflecting both his appreciation for local ingredients and a global approach. To drink, Raymond Trinh’s beverage team has crafted an eclectic wine list from old and new-world selections that satisfy a variety of tastes, available by the gloss or bottle. For something stronger, a cocktail menu rooted in the American classics with modern tweaks celebrates locally produced spirits, infusions and tinctures."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8691,
        store_longitude: -73.9175,
        total_orders: 330.02,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Beer, Wine, Irish, Legendary, Cozy, Picturesque, Inviting, Amazing, Great, Cool',
        annual_dol: 351.21,
        description:
            '"The Liffy II Bar is the last of picturesque Inwood’s Old Irish bars. The inviting oxblood exterior welcomes you to a bar that features original Irish bar decor including paintings,sketches and artifacts dating back to over a century. You can grab a $10 pitcher of beer, lounge on our plush sofas, play pool or ask the bartender for board games such as chess or Jenga. Have a decadent cocktail. Watch football or baseball on any of the 4 screens. Your group can watch their favorite tv show / soccer match in the pool table area. We do not sell food, so grab a menu from the bar and order in. The unpretentious energy of the Liffy II Bar transports you back to a simpler time of greeting your neighbors and after work cheers. Hope to see you soon!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6755,
        store_longitude: -73.8124,
        total_orders: 37.75,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Steak, Champagne, Chinese, French Fries, Chicken Wings, Spicy, Fried Calamari, French, Caribbean, American',
        annual_dol: 154.3,
        description:
            '"West Indian bar & restaurant, serving the best Guyanese-Chinese authentic dishes Food Includes but is not limited to Beef Bone In Cha Chi Kai Chicken Boneless Cha Chi Kai Chicken Boneless Chicken Broccoli With Garlic Sauce Chicken Chicken In The Ruff Entree French Fries Fried Banga Mary Fried Calamari Fried Gizzard Fried Shark Garlic Chicken Garlic Chicken Wings Ginger Lamb Ginger Shrimp Ginger Soy Chunks Half Chicken Hot And Spicy Shrimp Hot Wings House Special Jerk Chicken Jerk Lamb Jerk Pork Jerk Soy Chunks Lamb Lap Chong Mixed (Chicken And Pork) Mixed Veg With Garlic Sauce Mozzarella Sticks Pepper Shrimp Pepper Steak Plantain Chips Popular Menu Items Pork Rasta Pasta Rasta Pasta Jerk Chicken Rasta Pasta Jerk Lamb Rasta Pasta Jerk Pork"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9694,
        total_orders: 116.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Wine, Delicious, Organic, Iconic, Classic, Authentic, Intimate, Contemporary, Great',
        annual_dol: 477.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6817,
        store_longitude: -73.9767,
        total_orders: 1418.06,
        neighborhood: 'Park Slope',
        keywords:
            'Beer, Wine, Irish, Warm, American, French, Spacious, Classy, Traditional, Friendly',
        annual_dol: 1465.37,
        description:
            'Irish pub fare, draft beer & cocktails served in a comfortable, warm setting with a long bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6748,
        store_longitude: -73.9718,
        total_orders: 801.73,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Salad, Beer, Vibrant, Bruschetta, Cool, Modern, American, Diverse',
        annual_dol: 856.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 142.39,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Italian, Pizza, Coffee, Delicious, Mediterranean, American, Perfect, Peking Duck, Classic',
        annual_dol: 582.05,
        description:
            'Posh Italian tasting menus & classic trattoria-style dishes crafted in an industrial-chic eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.996,
        total_orders: 1065.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hamburger, Steak, Pizza, Beer, Wine, Martini, Cozy, Green Curry, Hotel, Irish',
        annual_dol: 1178.05,
        description: 'Pub offering bar food (with lunch specials), sports on TV & happy hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -74.0013,
        total_orders: 1908.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Chicken Tikka Masala, Delicious, Elegant, Eclectic, Sleek, Indian, Modern, Classic, Casual',
        annual_dol: 2008.43,
        description:
            'Sleek destination for eclectic Indian fare with fanciful presentations, plus inventive drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6793,
        store_longitude: -73.9645,
        total_orders: 2896.69,
        neighborhood: 'Prospect Heights',
        keywords:
            'Wine, Whiskey, Coffee, Delicious, Gin, Cozy, Comfortable, Perfect, Sleek, Premium',
        annual_dol: 3022.28,
        description:
            'Local art is sold alongside cocktails & bites at this sleek haunt with a chill vibe & music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6826,
        store_longitude: -73.9612,
        total_orders: 1725.04,
        neighborhood: 'Clinton Hill',
        keywords: 'Beer, Delicious, Warm, Irish, Cozy, Classy, Relaxed, Intimate, Homey, Authentic',
        annual_dol: 1799.83,
        description:
            'Warm, cozy gathering place serving Irish comfort food with an American twist & local craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6874,
        store_longitude: -73.9571,
        total_orders: 1183.8,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Beer, Margarita, Pinacolada, Hurricane, Daiquiri, Friendly, Classic, Awesome, Great, Cool',
        annual_dol: 1235.13,
        description:
            'Watering hole with New Orleans–style frozen drinks & beers, plus pinball & a jukebox.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6184,
        store_longitude: -74.1056,
        total_orders: 49427.14,
        neighborhood: 'Silver Lake/Castleton Corners/Mid Island',
        keywords:
            'Vodka, Upscale, Rustic, Romantic, American, Italian, Picturesque, Sweet, Intimate, Natural',
        annual_dol: 54161.24,
        description:
            'Upscale American restaurant & event venue in a rustic stone house with a lakefront patio & gardens.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.613,
        store_longitude: -74.1221,
        total_orders: 3.09,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Burger, Steak, Pizza, Salad, Italian, Shrimp Cocktail, Casual',
        annual_dol: 12.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7123,
        store_longitude: -73.9664,
        total_orders: 118.5,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Burger, Steak, Salad, Dessert, Trendy, Brazilian, American, Authentic, Fun, Traditional',
        annual_dol: 148.82,
        description:
            "Rio de Janeiro's festive air comes to Brooklyn via traditional Brazilian cuisine & colorful decor.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7162,
        store_longitude: -73.9627,
        total_orders: 11989.08,
        neighborhood: 'Williamsburg',
        keywords:
            'Cheesecake, Mimosa, Delicious, Peruvian, French Toast, Uruguayan, French, Fun, Pinacolada, Rum',
        annual_dol: 15465.77,
        description:
            'Traditional Latin American dishes & cocktails served in a chic venue with private rooftop pods.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7137,
        store_longitude: -73.962,
        total_orders: 224.98,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Sushi, Salad, Spicy, Japanese, Upscale, Organic, Ramen, Asian, Fusion, Sleek',
        annual_dol: 355.2,
        description:
            'Brick walls & a display of sake bottles create a sleek setting for sushi & other Japanese dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -73.9777,
        total_orders: 16.75,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Pizza, Chicken Lollipop, Italian, Savory, Delicious, Premium, Fine',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7622,
        store_longitude: -73.9777,
        total_orders: 827.39,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Chicken Pot Pie, Shrimp Cocktail, Beer, Bbq, Wine, Irish, Spicy, French, American',
        annual_dol: 3142.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8986,
        store_longitude: -73.8676,
        total_orders: 153.31,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords: 'Beer, Irish, Awesome, Classic, Great, Casual',
        annual_dol: 205.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 789.63,
        neighborhood: 'Jackson Heights',
        keywords: 'Karaoke, Wine, Fun, Colorful, Friendly, Amazing, Great, Modern, Lit, Latin',
        annual_dol: 1046.3,
        description:
            'Dimly lit Latin gay club with happy hours, karaoke nights, cocktails & party nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6805,
        store_longitude: -73.9775,
        total_orders: 970.91,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Pizza, Beer, Dessert, Italian, Irish, Fun, Fancy, Classy, Friendly',
        annual_dol: 1991.19,
        description:
            '"A fancy cocktail bar, a low-key neighborhood microbrew hangout, and a great place for date night"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6778,
        store_longitude: -73.9727,
        total_orders: 821.94,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Lasagna, Burger, Cajun, Whiskey, Vodka, French Toast, Bbq, Delicious, French, Rum',
        annual_dol: 3096.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6006,
        store_longitude: -73.8193,
        total_orders: 41.22,
        neighborhood: 'Broad Channel/Far Rockaway',
        keywords: 'Salad, Fun, Hotel, Great, American',
        annual_dol: 48.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 262.29,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Spanish, Fun, Intimate, Awesome, Innovative, Amazing, Fantastic, Incredible, Great',
        annual_dol: 920.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5412,
        store_longitude: -74.1781,
        total_orders: 104.74,
        neighborhood: 'Woodrow',
        keywords:
            'Sushi, Salad, Tuna Steak, Steak, Pizza, Gluten Free, Beer, Tasty, Japanese, Spicy',
        annual_dol: 150.99,
        description:
            'Specialty sushi & Japanese entrees, plus beer & cocktails, served in a modern, lounge-like space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7166,
        store_longitude: -73.9512,
        total_orders: 1334.3,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Shrimp Cocktail, Italian, Wine, Fried Calamari, Classy, Retro, Casual, Traditional, Perfect',
        annual_dol: 1373.34,
        description:
            'Circa-1900 neighborhood Italian restaurant plating red-sauce standards in a retro setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7072,
        store_longitude: -74.0072,
        total_orders: 3186.14,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Italian, Pizza, Salad, Dessert, Coffee, Wine, Mimosa, Beer, Ample, Local',
        annual_dol: 3579.99,
        description:
            'Easygoing choice serving ample portions of standard Italian favorites, plus old-school pizzas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.73,
        store_longitude: -73.9809,
        total_orders: 447.5,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Karaoke, Fun, Friendly, Great, Hospitable, Excellent, Casual, Chill',
        annual_dol: 457,
        description:
            'Amateur singers take the mike at this karaoke lounge with lipstick-red walls & zebra-print seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6802,
        store_longitude: -73.9612,
        total_orders: 87.39,
        neighborhood: 'Crown Heights/Clinton Hill',
        keywords:
            'Burger, Coffee, Beer, Iranian, Tasty, American, Casual, Terrific, Middle Eastern',
        annual_dol: 357.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7068,
        store_longitude: -74.0072,
        total_orders: 90.3,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Tacos, Salad, Burritos, Australian, Delicious, Casual, Local, Recommended',
        annual_dol: 491.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7094,
        store_longitude: -73.9586,
        total_orders: 15.36,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Korean, Vegetarian, Spicy, Delicious, Bbq, Trendy, Asian, Phenomenal, Sweet, Casual',
        annual_dol: 62.78,
        description:
            'Unassuming, trendy neighborhood bar turning out cocktails, soju & Korean-fusion small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9611,
        total_orders: 3586.07,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Coffee, Wine, Rum, Classic, Chill, Casual, Great, Incredible',
        annual_dol: 4747.48,
        description:
            'Vibrant, art-filled hangout offering frozen & classic cocktails, plus draft beer & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9842,
        total_orders: 2900.67,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Burger, Hamburger, Salad, Mimosa, Beer, Coffee, Wine, American, Spicy, Prosecco',
        annual_dol: 3193.95,
        description:
            'Bi-level tavern with a rooftop beer garden offering a menu of American comfort grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.745,
        store_longitude: -73.9832,
        total_orders: 76.45,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Hamburger, Beer, Whiskey, Delicious, Warm, American, Inviting, Hotel, Awesome',
        annual_dol: 312.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8002,
        store_longitude: -73.6512,
        total_orders: 7464.69,
        neighborhood: '',
        keywords:
            'Tequila, Wine, Steak, Upscale, Dessert, Elegant, American, Crisp, Eclectic, Inviting',
        annual_dol: 7626.41,
        description:
            'Elegant, historic estate turned upscale pub featuring New American cuisine & a raw bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7502,
        store_longitude: -73.9862,
        total_orders: 3102.79,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Karaoke, Spanish, Japanese, Korean, Lively, Fun, Awesome, Friendly, Recommended, Unique',
        annual_dol: 3309.41,
        description:
            'Lively karaoke lounge with drinks, a variety of rooms & a long, frequently updated song list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.9866,
        total_orders: 472.92,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Upscale, Warm, Iconic, Welcoming, Classy, Fun, Great, Cool, Casual',
        annual_dol: 531.02,
        description:
            'Neon-hued karaoke bar serving soju, nibbles & more to folks singing privately or in the main lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6598,
        store_longitude: -73.9613,
        total_orders: 2551.53,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Meatloaf, Meatball, Coffee, Beer, Wine, Gumbo, Mediterranean, American, Cozy, Asian',
        annual_dol: 2605.67,
        description:
            'Chill neighborhood bar pouring craft cocktails & barista coffee drinks in a hip, low-key setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6537,
        store_longitude: -73.5829,
        total_orders: 111.28,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Avocado Toast, French, English Muffin, Delicious, Bruschetta, Homemade, American, Casual',
        annual_dol: 126.75,
        description:
            'Classic diner offering elevated breakfast, lunch & dinner options, plus curbside pick-up.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9807,
        total_orders: 300.36,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Gluten Free, Steak, Spicy, Delicious, Mediterranean, Japanese, Turkish, Friendly, Local, Authentic',
        annual_dol: 1509.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6881,
        store_longitude: -73.9875,
        total_orders: 17.69,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Wine, Karaoke, Intimate, Zombie, Amazing, Great, Clean, Fresh, Middle Eastern',
        annual_dol: 72.32,
        description:
            '"Welcome to The Atlantic BKLN, one of Brooklyn’s newest and most intimate live music venues. The Atlantic BKLN hosts live music performances of all genres, featuring jazz, blues, folk, rock, weekly live band karaoke nights and is available for private event rentals. Our sound system, designed and installed by Clair Global, is one of the best in the country. Our 200-capacity venue aims to enhance the guest experience with a state of the art clean air handling system that ensures fresh air is constantly circulating throughout the space. Get ready, we are so excited to share with you this amazing space!!!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.739,
        store_longitude: -73.9995,
        total_orders: 3596,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vegetarian, Salad, Wine, Beer, Ale, Upscale, Cuban, Stylish, Mediterranean',
        annual_dol: 4095.59,
        description:
            'Local ingredients meet Mediterranean flavors at this laid-back kitchen with an industrial vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9987,
        total_orders: 223.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Chinese, Wine, Margarita, Spicy, Delicious, Sweet, Hotel, Traditional, Authentic, Colorful',
        annual_dol: 391.15,
        description:
            'Unassuming Chinese restaurant serving traditional hot & cold dishes, plus dim sum & lunch specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7281,
        store_longitude: -73.9819,
        total_orders: 145.32,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Burger, Salad, Coffee, Wine, Meatball, Margarita, Martini, Delicious, Trendy, Korean',
        annual_dol: 594.04,
        description:
            'Laid-back restaurant with artworks & a patio, furnishing alcohol, charcuterie & traditional bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6785,
        store_longitude: -73.9586,
        total_orders: 2856.84,
        neighborhood: 'Crown Heights',
        keywords: 'Funky, Fancy, Fun, Casual, Great',
        annual_dol: 3046.75,
        description:
            'Neighborhood bar/performance venue offering theme nights & dance parties, plus live music & DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9514,
        total_orders: 2006.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Cajun, Beer, Whiskey, Coffee, American, Mediterranean, Retro, Bright, Amazing',
        annual_dol: 2519.57,
        description:
            'Brick-walled hangout with a central bar & dining tables, offering craft beers and snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6692,
        total_orders: 501.48,
        neighborhood: '',
        keywords:
            'Steak, Tequila, Salad, Wine, Mexican, American, Contemporary, Spicy, Irish, Polished',
        annual_dol: 524.11,
        description:
            'Casual, island-style haunt with a buzzy bar & patio offering New American fare & a broad wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -73.8104,
        total_orders: 23.22,
        neighborhood: 'Fresh Meadows/Flushing/Pomonok',
        keywords: 'Fresh, Chinese, Cool, Hotel, Retro, Polish, Local',
        annual_dol: 94.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6444,
        store_longitude: -74.0765,
        total_orders: 165.48,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Steak, Lobster, Sushi, Japanese, Turkish, Gluten Free, Sashimi, Teriyaki Salmon, Fusion, Delicious',
        annual_dol: 676.41,
        description:
            '"Wasabi Steak & Sushi is the premier dining location for Japanese cuisines. Consistently voted as a top dining destination. A clean modern décor and sophisticated interior sets the perfect atmosphere for an unparalleled dining experience. We are the perfect venue for any occasion. Join our Hibachi dining room where veteran chefs prepare spectacular displays of food right before your eyes."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.731,
        store_longitude: -73.9826,
        total_orders: 410.48,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Salad, Coffee, Delicious, Greek, American, Friendly, Fresh, Casual',
        annual_dol: 457.39,
        description:
            'Classic Greek dishes, such as calamari & souvlaki, are served in an airy space with an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.9546,
        total_orders: 63.62,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Vegan, Tasty, Upscale, Delicious, Spicy, Jamaican, Caribbean, Healthy, Rum',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7138,
        store_longitude: -73.9655,
        total_orders: 8945.26,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Salad, American, Trendy, Contemporary, Modern, Casual, Sweet, Innovative, Fun, Signature',
        annual_dol: 9139.51,
        description:
            "Atera alum's American restaurant with a marketplace, barroom & upstairs dining room & garden.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7113,
        store_longitude: -73.9658,
        total_orders: 34.81,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Green Papaya Salad, Beer, Salad, Vietnamese, Cozy, Spicy, Friendly, Fun, Papaya Salad, Casual',
        annual_dol: 39.64,
        description:
            'Classic Vietnamese fare served in a brick-lined warehouselike space with a bar & rooftop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9216,
        total_orders: 14.78,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Milkshake, Avocado Toast, Chinese, Delicious, American, Mediterranean, Modern, Retro, Fun',
        annual_dol: 60.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7671,
        store_longitude: -73.9865,
        total_orders: 585.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Vegetarian, Wine, Beer, Japanese, Delicious, Upscale, Stylish, Spacious, Asian',
        annual_dol: 1368.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.766,
        store_longitude: -73.9808,
        total_orders: 86.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Casual',
        annual_dol: 352.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9851,
        total_orders: 2541.53,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hamburger, Vodka, Salad, Wine, Beer, Russian, Irish, Perfect, Awesome, Indian',
        annual_dol: 2769.88,
        description:
            'Serving pub grub, this haunt with dark-wood decor features 15+ flat-screen TVs & a spot for darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9792,
        total_orders: 725.22,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Italian, Trendy, Spanish, Intimate, Casual, Superb',
        annual_dol: 2746.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8623,
        store_longitude: -73.9254,
        total_orders: 48.17,
        neighborhood: 'Fort George',
        keywords:
            'Steak, Lobster, Salad, Tasty, Dessert, Chinese, Delicious, Dominican, Asian, Latin',
        annual_dol: 196.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.634,
        store_longitude: -73.5819,
        total_orders: 656.07,
        neighborhood: '',
        keywords: 'Ale, Beer, Lobster, Lobster Roll, Cajun, Salad, Wine, Nachos, American, Casual',
        annual_dol: 698.18,
        description:
            'Beer comes in huge Styrofoam cups at this long-standing barroom with bras hanging from the ceiling.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8641,
        store_longitude: -73.9265,
        total_orders: 10.82,
        neighborhood: 'Fort George',
        keywords: 'Peruvian, Delicious, Casual, Sweet, Fun, Modern, Unique',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6444,
        store_longitude: -73.5753,
        total_orders: 578.46,
        neighborhood: '',
        keywords:
            'Tequila, Mexican, Margarita, Vodka, Wine, Fajitas, French, Delicious, Triple Sec, Premium',
        annual_dol: 2364.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6805,
        store_longitude: -73.9751,
        total_orders: 31.22,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Spicy, Stylish, Creamy, Jamaican, Caribbean, Rum, Chinese, Exotic, Sweet',
        annual_dol: 127.62,
        description:
            'Lively Park Slope joint near the Barclays Center serving Caribbean food & drink.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6779,
        store_longitude: -73.9724,
        total_orders: 6224.86,
        neighborhood: 'Prospect Heights',
        keywords:
            'Wine, Beer, Trendy, Sweet, Southern Comfort, Gourmet, Southern, Classy, Casual, Hip',
        annual_dol: 6494.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.9447,
        total_orders: 16.09,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Vegan, Dessert, Wine, Coffee, Vegetarian, Moroccan, Mediterranean, Israeli, Tranquil',
        annual_dol: 65.78,
        description:
            'Contemporary Mediterranean eatery with a market-driven vegetarian menu & tranquil garden patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7233,
        store_longitude: -73.9853,
        total_orders: 666.01,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Beer, Chicken Pot Pie, Spicy, Delicious, Irish, Fabulous, Sweet, American, Welcoming',
        annual_dol: 950.72,
        description:
            'Welcoming bar & grill serving Irish & American flavors in a modern setting with distinctive decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -73.9786,
        total_orders: 1569.79,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Coffee, Whiskey, Tacos, Beer, Pizza, Vietnamese, Quaint, Cozy, Lebanese, Comfortable',
        annual_dol: 1617.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7224,
        store_longitude: -73.9841,
        total_orders: 263.62,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lasagna, Italian, Wine, Delicious, Meatball, Romantic, Friendly, Sweet, Homemade, Intimate',
        annual_dol: 1077.6,
        description:
            'Italian wines are paired with small plates at this rustic, brick-walled wine bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.686,
        store_longitude: -73.9731,
        total_orders: 53.69,
        neighborhood: 'Fort Greene',
        keywords: 'Salad, Burger, Apple Pie, Donut, Korean, Wine, Spicy, Japanese, Asian, American',
        annual_dol: 75.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9798,
        total_orders: 279.15,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Upscale, Hotel, Whiskey, Bourbon, Rooftop, Margarita, Bright, Outstanding, Cozy, Classic',
        annual_dol: 1141.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -73.9945,
        total_orders: 131.11,
        neighborhood: 'Nolita',
        keywords: 'Sushi, Salad, Wine, Dessert, Beer, Spicy, Japanese, Creamy, Sashimi, Asian',
        annual_dol: 535.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7675,
        store_longitude: -73.9895,
        total_orders: 666.36,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Chocolate Cake, Salad, Flavorful, Tasty, Delicious, Upscale, Trendy, Romantic, Mediterranean, Japanese',
        annual_dol: 818.04,
        description:
            'Mediterranean-style cuisine served in a polished venue that has outdoor seating & a shisha lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.9861,
        total_orders: 83.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Chocolate Mousse, Shrimp Cocktail, Tasty, Wine, Beer, French, Lively, Casual, Terrific, Great',
        annual_dol: 355.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.718,
        store_longitude: -73.9892,
        total_orders: 852.88,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Pizza, American, Vintage, Stylish, Classic, Spacious, Ample, Local, Casual',
        annual_dol: 2197.74,
        description:
            '"12 Lanes of classic ten-pin bowling, full bar with up to 24 American craft beers on tap, full kitchen featuring brick oven pizza. Open every day."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6349,
        store_longitude: -73.6404,
        total_orders: 39.98,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Organic, Local, Casual, Classic',
        annual_dol: 255.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7758,
        store_longitude: -73.91,
        total_orders: 231.13,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Elegant, Friendly, Cool, Casual, Great, American, Amazing, Fantastic, Recommended',
        annual_dol: 463.61,
        description:
            '"The doctors and staff of Astoria Foot Care Group, P.C. located in Astoria, New York would like to welcome you to our web site. As you navigate through the site you will find a wealth of information about podiatry and treatments available. We invite you to call our office with any and all concerns you may have. At Astoria Foot Care Group, we take great pride in treating our patients with that extra bit of care and attention they deserve. From the moment you enter to the completion of the visit, you will be greeted with a sincere smile by the entire office staff. When you make an appointment at the Astoria Foot Care Group, our staff will verify your insurance coverage in advance."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.8894,
        total_orders: 33.64,
        neighborhood: 'Jackson Heights',
        keywords: 'Mexican, Tacos, Fajitas, Casual, Perfect, Great, Diverse',
        annual_dol: 199.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6753,
        store_longitude: -73.9747,
        total_orders: 467,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hamburger, Meatloaf, Tacos, Salad, Mexican, Cajun, Tequila, Jambalaya, Milkshake',
        annual_dol: 525.92,
        description:
            'Cozy, brick-clad outlet offering comfort & American dining classics, plus brunch & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -73.9806,
        total_orders: 790.18,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Korean, French, Irish, Hotel, Retro, Casual, Local, Fresh, Unique',
        annual_dol: 806.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9445,
        total_orders: 56.96,
        neighborhood: 'Williamsburg',
        keywords: 'Steak, Tacos, Mexican, Vegan, Salad, Delicious, Pinacolada, Classic, Casual',
        annual_dol: 174.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8187,
        store_longitude: -73.9153,
        total_orders: 23.9,
        neighborhood: 'Melrose/West Bronx',
        keywords: 'Mexican, Casual',
        annual_dol: 30.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7159,
        store_longitude: -73.9622,
        total_orders: 2418.67,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Tasty, Margarita, Delicious, Bourbon, Coffee, Sweet, Fun, Vibrant, Woody',
        annual_dol: 2491.37,
        description:
            'Down-home, honky-tonk saloon pairing a woody space with beer, games & live country music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9216,
        total_orders: 747.3,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Beer, Steak, Pizza, Cajun, Irish, Warm, American, Cozy, Innovative',
        annual_dol: 779.7,
        description:
            'Easygoing pub with many craft brews on tap, seasonal sidewalk seating & updated comfort eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9759,
        total_orders: 179.38,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Vegetarian, Vegan, Steak, Tacos, Mexican, Tasty, Burritos, Delicious, Cozy, Enchiladas',
        annual_dol: 209.25,
        description:
            'Burritos, tacos & other Mexican staples are paired with margaritas at this small, colorful eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7622,
        store_longitude: -73.9254,
        total_orders: 779.42,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Whiskey, Beer, Irish, Warm, Yummy, Friendly, Classic, Mint Julep, Authentic, Casual',
        annual_dol: 831.24,
        description:
            'Classic Irish pub with a snug courtyard featuring a menu with familiar fare, plus local beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.9821,
        total_orders: 3029.94,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Hotdog, Whiskey, Burger, Beer, Flavorful, Champagne, Wine, Bourbon, Gin, Cozy',
        annual_dol: 3094.23,
        description:
            'Dollars hang from the ceiling of this narrow, brick-lined watering hole for cocktails & draft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9655,
        total_orders: 500.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Salad, Wine, Champagne, Bourbon, Beer, Avocado Toast, Martini, Organic',
        annual_dol: 517.94,
        description:
            'Rustic, nautical-themed eatery offering ample seafood plus craft cocktails & a weekend tiki brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.713,
        store_longitude: -73.9674,
        total_orders: 2855.6,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Vegetarian, Wine, Beer, Italian, Martini, Gin, Trendy, Upscale, Mediterranean, Bellini',
        annual_dol: 3003.38,
        description:
            'Contemporary-chic Italian dining space dishing up elevated antipasto & handmade pasta dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.9836,
        total_orders: 367.08,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Steak, Wine, French, Classic, Iconic, Perfect, Homemade, Friendly, Great, Omelette',
        annual_dol: 1500.52,
        description:
            '"Located in the iconic Brasserie Les Halles space on Park Avenue South, Francis Staub, founder of the fabled cookware company (Staub) brings gourmand cooking to New York. A celebration of the French brasserie — restaurants loved for their casual, convivial atmosphere, ingredient-driven dishe,s and extensive Old World wine menu — La Brasserie was created with both indulgence and daily pleasure in mind."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7121,
        store_longitude: -73.963,
        total_orders: 119.89,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Awesome, Great, Casual',
        annual_dol: 490.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7439,
        store_longitude: -73.9792,
        total_orders: 50.87,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Wine, Delicious, Spicy, Spanish, Warm, Beer, Fun, Fabulous, Cozy, Sweet',
        annual_dol: 207.95,
        description:
            'Casual, brick-lined locale with a generous selection of wine, Spanish small plates & sidewalk seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -73.9833,
        total_orders: 176.4,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Upscale, Stylish, Coffee, Delicious, Hotel, Gin, Beer, Classy, Fancy, Perfect',
        annual_dol: 247.13,
        description:
            'Stylish lounge outfitted with flat-screen TVs for watching sports while sipping craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9882,
        total_orders: 3721.09,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Burger, Vegetarian, Mexican, Salad, Beer, Margarita, Dessert, Delicious, Enchiladas',
        annual_dol: 4622.47,
        description:
            'A bustling eatery serving basic Mexican fare to a lively crowd, with DJs & a big margarita menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7649,
        store_longitude: -73.9831,
        total_orders: 1242.44,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Cajun, Salad, Beer, Chicken Pot Pie, Mexican, Wine, Ale, Irish, Delicious',
        annual_dol: 1296.31,
        description:
            'Wood-lined Irish pub sprawling over 3 floors & offering bar grub & plenty of draft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.814,
        store_longitude: -73.945,
        total_orders: 1689.93,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Pizza, Beer, African, American, Intimate, Casual, Exquisite, Signature',
        annual_dol: 3348.65,
        description:
            'Gay bar with a dark, intimate vibe offering pizza & wings, plus beer & signature cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7656,
        store_longitude: -73.9877,
        total_orders: 1370.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Salad, Vegetarian, Vodka, Sushi, Whiskey, Dessert, French, Gin, Zen, Elegant',
        annual_dol: 1474.9,
        description:
            '"Ikebana Zen is a form of artwork that merges the urban life of New York with the Japanese bar through the abstract sense of ephemerality created by material, color, and light. Serving sake, craft cocktails, spirits are paired with small plates."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.988,
        total_orders: 1187.77,
        neighborhood: 'Lower East Side',
        keywords:
            'Hotel, Upscale, Luxurious, Trendy, Lobster, Stylish, Italian, Coffee, Delicious, Funky',
        annual_dol: 1421.14,
        description:
            "The minimalist rooms have floor-to-ceiling windows, slate bathrooms and complimentary Wi-Fi. Upgraded rooms include balconies, deep soaking tubs, steam showers with glass exterior walls and additional bedrooms.A hip restaurant with Mediterranean fare features outdoor dining with street views, and a sky-lit atrium. There's also a juice bar on the ground floor, as well as a rooftop event venue.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -73.985,
        total_orders: 4490.26,
        neighborhood: 'Lower East Side',
        keywords:
            'Sushi, Wine, Beer, Margarita, Trendy, Japanese, Delicious, Homemade, Sashimi, Seasonal',
        annual_dol: 4765.66,
        description:
            "Japanese izakaya serving small plates, sashimi & many sakes at a chef's bar in a modern, snug space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7765,
        store_longitude: -73.7527,
        total_orders: 519.75,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Asian, French Onion Soup, Colorful, French, Fresh, American, Unique, Great, Local, Chill',
        annual_dol: 554.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.8848,
        total_orders: 84.52,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Steak, Sushi, Salad, Delicious, Mediterranean, French, Colombian, Latin, Sangria, American',
        annual_dol: 345.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.8842,
        total_orders: 30.86,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Mexican, Burger, Pizza, Beer, Sushi, Tex-Mex, Fun, Authentic, Great, Excellent',
        annual_dol: 126.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.9939,
        total_orders: 606.16,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Rooftop, Fresh',
        annual_dol: 2283.81,
        description:
            'Empire State Building.The unfussy rooms provide flat-screen TVs, Wi-Fi, minifridges and coffeemakers. Some feature skyline views and/or sofabeds.There’s an understated restaurant with a bar. Other amenities include a 24-hour fitness center and a rooftop terrace. Breakfast is available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7285,
        store_longitude: -73.7839,
        total_orders: 3.16,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords: 'Steak, Sushi, Spicy, Japanese, Brazilian, Fresh, Mediterranean, Casual',
        annual_dol: 12.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6773,
        store_longitude: -73.9576,
        total_orders: 1024.88,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Mexican, Beer, Tasty, American, Eclectic, Vintage, Awesome, Classic, Perfect',
        annual_dol: 3861.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 2533.4,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Steak, Salad, Italian, Spanish, Stylish, Bellini, Dutch, Warm, Perfect, Casual',
        annual_dol: 6969.29,
        description:
            'Warm spot offering seafood, oysters & prime-aged beef, plus a bar & views of the Brooklyn Bridge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.631,
        store_longitude: -74.103,
        total_orders: 16.19,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Burger, Pizza, Salad, Tacos, Italian, Shrimp Cocktail, Vodka, Dessert, Bbq, Comfortable',
        annual_dol: 66.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7701,
        store_longitude: -73.9551,
        total_orders: 3481.19,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Salad, Apple Pie, Japanese, Czech, Savory, Hotel, Cozy, Ramen, Warm',
        annual_dol: 4469.17,
        description:
            'Bar inspired by the Meiji era serving craft cocktails in antique drinking vessels & Japanese fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7448,
        store_longitude: -73.987,
        total_orders: 6451.9,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Beer, Salad, Wine, Vodka, Roast Turkey, Turkey Sandwich, Dessert, Bread Pudding',
        annual_dol: 7554.27,
        description:
            'Relaxed pub with a rustic vibe & patio offering craft beers, New American small plates & sandwiches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.766,
        store_longitude: -73.9915,
        total_orders: 1002.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Tasty, Cozy, Delicious, Polished, Vibrant, Mediterranean, Cool, Awesome, Casual',
        annual_dol: 1169.55,
        description:
            'Over 30 wines by the glass are available along with homemade NY pretzels & hand-crafted cheeses.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7231,
        store_longitude: -73.9898,
        total_orders: 2570.4,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Whiskey, Lemon Meringue Pie, Wine, Irish, Lively, American, Fancy, Friendly, Traditional',
        annual_dol: 2738.07,
        description:
            'Pub by the Beerly Legal Group (The Jeffrey) offering brews, bourbons, seasonal drinks & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7165,
        store_longitude: -73.9878,
        total_orders: 165.88,
        neighborhood: 'Lower East Side',
        keywords: 'Mexican, Tequila, Beer, Steak, Bourbon, Wine, Tasty, Eclectic, Authentic, Local',
        annual_dol: 582.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6857,
        store_longitude: -73.8649,
        total_orders: 31.85,
        neighborhood: 'Jamaica/Woodhaven',
        keywords:
            'Lobster, Steak, Lobster Tail, Mexican, Dominican, Spanish, American, Latin, Teriyaki, Casual',
        annual_dol: 130.2,
        description:
            '"Hello Welcome to el Rey iv Restaurant, we are a restaurant with a great diversity of services and experiences such as billiards, event venue, bar, restaurant, arcade games and much more! If you need any information you can call us we will be happy to assist you"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7149,
        store_longitude: -73.9915,
        total_orders: 204.39,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Tequila, Beer, Steak, Ale, Margarita, Coffee, Tasty, Dessert, Trendy, Rum',
        annual_dol: 217.97,
        description:
            'Eclectic, health-conscious Californian-style fare dished in a minimalist, white-&-blond-wood space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 74,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Delicious, Dessert, Sweet, Great, Casual, Modern',
        annual_dol: 302.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9802,
        total_orders: 10.56,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Sushi, Vegetarian, Japanese, Chinese, Spicy, Asian, Delicious, Hotel, Urban, Casual',
        annual_dol: 43.19,
        description:
            'Informal spot offering a broad menu of Chinese & Japanese favorites for dine-in, takeout & delivery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.8867,
        total_orders: 5.21,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Mexican, Italian, Ecuadorian, Casual, Healthy, American, Friendly, Latin, Great',
        annual_dol: 21.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8031,
        store_longitude: -73.6463,
        total_orders: 35.57,
        neighborhood: '',
        keywords: 'Sushi, Pizza, Cajun, Spicy, Beer, Japanese, Chilean, Premium, Casual, Perfect',
        annual_dol: 96.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7586,
        store_longitude: -73.9772,
        total_orders: 1527.49,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Salad, Wine, Upscale, Mediterranean, Organic, Greek, Tranquil, Welcoming, Exquisite',
        annual_dol: 1716.57,
        description:
            'Upscale Greek-Mediterranean restaurant with a large seafood-focused menu & spacious, chic digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5849,
        store_longitude: -73.8192,
        total_orders: 64.65,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Beach/Rockaway Park',
        keywords: 'Burger, Salad, Tasty, Nachos, Wine, Bbq, Tangy, Chinese, Mediterranean, Hotel',
        annual_dol: 264.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 298.79,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Stylish, Trendy, Premium, Fun, High-End, Intimate, Rooftop, Cool, Distinct',
        annual_dol: 610.68,
        description:
            'Trendy Dream Hotel basement bar with English-accented decor & a small-batch spirit menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8675,
        store_longitude: -73.9294,
        total_orders: 20.5,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Salad, Wine, Beer, Delicious, Avocado Toast, Latin, Clean, Modern, Friendly, Contemporary',
        annual_dol: 83.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7136,
        store_longitude: -73.9343,
        total_orders: 225.98,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Gin, Rum, Funky, Fancy, Classy, Fun, Prosecco, Casual',
        annual_dol: 923.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7139,
        store_longitude: -73.9522,
        total_orders: 204.95,
        neighborhood: 'Williamsburg',
        keywords: 'Hotel, Upscale, Beer, Sleek, Vibrant, Contemporary, Great, Fresh, Local',
        annual_dol: 271.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9873,
        total_orders: 628.97,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegetarian, Gourmet, Coffee, Champagne, Tasty, Austrian, Italian, Ale, Delicious, Hotel',
        annual_dol: 1364.02,
        description:
            'Counter-serve deli with upstairs seating & sidewalk tables offering simple sandwiches & snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7099,
        store_longitude: -73.9687,
        total_orders: 66.15,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Pizza, American, Rooftop, Wine, Hotel, Southern, Classic, Casual, Complex',
        annual_dol: 190.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -73.9587,
        total_orders: 28.01,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Southern, Casual, Thai',
        annual_dol: 114.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -73.9804,
        total_orders: 40.53,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Vegetarian, Vegan, Tasty, Flavorful, Savory, Delicious, Spicy, Healthy, Asian, Friendly',
        annual_dol: 165.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7647,
        store_longitude: -73.9915,
        total_orders: 1524.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Whiskey, Wine, Mexican, Trendy, Ale, Crisp, Hotel, Lively, American',
        annual_dol: 1600.56,
        description:
            'Rotating craft beers, cocktails & elevated bar bites served amid exposed brick & retro touches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.983,
        total_orders: 449.46,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Whiskey, Tasty, American, Classic, Urban, Iconic, Fun, Traditional, Local',
        annual_dol: 1851.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7629,
        store_longitude: -73.9775,
        total_orders: 212.59,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Pizza, Vegan, Italian, Gluten Free, Hawaiian, Modern, Spicy, Contemporary, Retro, Artisanal',
        annual_dol: 266.99,
        description:
            'Wood-fired pies & other Neapolitan dishes served in a bi-level space adorned by modern art for sale.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.985,
        total_orders: 2575.16,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Wine, Vodka, Beer, Martini, Gin, Bourbon, Ale, Rum, Delicious',
        annual_dol: 2641.99,
        description:
            'Dimly lit bar with high tops & a communal table serves cocktails alongside oysters & seafood plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7221,
        store_longitude: -73.9866,
        total_orders: 874.52,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Hotel, Perfect, Authentic, Casual, Lively, Great, Eclectic',
        annual_dol: 893.08,
        description:
            "Indie headliners & up-and-comers have kept this small music venue crowded since the '90s.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7174,
        store_longitude: -73.9952,
        total_orders: 122.09,
        neighborhood: 'Bowery',
        keywords:
            'Karaoke, Chinese, Wine, Margarita, Japanese, Korean, Mojito, Asian, Modern, Great',
        annual_dol: 499.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7759,
        store_longitude: -73.9026,
        total_orders: 11.06,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Sushi, Burger, Salad, Wine, Spicy, Upscale, Trendy, Mediterranean, Sashimi, English Muffin',
        annual_dol: 45.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8729,
        store_longitude: -73.9184,
        total_orders: 37.73,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Burger, Vegan, Beer, Wine, Coffee, Spicy, Southern, Casual, Friendly, Local',
        annual_dol: 154.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.979,
        total_orders: 2257.5,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Salad, Cajun, Shrimp Scampi, Bourbon, Wine, Upscale, Legendary, Caesar Salad',
        annual_dol: 2991.29,
        description:
            'Upscale chain for aged prime beef, seafood & other traditional steakhouse fare in a clubby space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6858,
        store_longitude: -73.9885,
        total_orders: 8959.56,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Beer, Vietnamese, Cozy, Casual, American, Local, Fresh, Great',
        annual_dol: 8959.56,
        description:
            'Old-school watering hole with a 19th-century wood bar, low-key atmosphere, jukebox & pool table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7426,
        store_longitude: -73.9993,
        total_orders: 1324.34,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vegan, Beer, Wine, Martini, Margarita, Ale, Bourbon, Delicious, Spicy, Friendly',
        annual_dol: 4299.09,
        description:
            'Narrow, easygoing bar for cocktails, beers & happy hours, along with sandwiches, salads & sides.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -74.0009,
        total_orders: 1429.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Coffee, Hotel, Modern, Aesthetic, Friendly, Great',
        annual_dol: 2002.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7479,
        store_longitude: -74.0043,
        total_orders: 573.81,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Beer, Scotch, Salad, Tasty, Ale, Robust, Irish, Modern, Fresh',
        annual_dol: 1157.49,
        description:
            'Modern bar food, craft beer, cocktails & a robust Scotch menu in a laid-back space with a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.678,
        store_longitude: -73.9796,
        total_orders: 947.66,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Vegan, Vegetarian, Lasagna, Pizza, Salad, Mexican, Burritos, Nachos, Tasty',
        annual_dol: 991.06,
        description: 'Low-key cafe serving vegan, kosher & gluten-free dishes with Latin accents.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7285,
        store_longitude: -73.9789,
        total_orders: 694.86,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Pizza, Salad, Beer, Whiskey, Rustic, Cozy, Friendly, Eclectic, Artisanal, Perfect',
        annual_dol: 751.71,
        description:
            'Foosball, an eclectic jukebox & random movie screenings draw locals to this plain-Jane barroom.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.712,
        store_longitude: -73.9511,
        total_orders: 1777.18,
        neighborhood: 'Williamsburg',
        keywords:
            'Beer, American, Vintage, Classic, Eclectic, Classy, Friendly, Chic, Awesome, Great',
        annual_dol: 1816.14,
        description:
            'Vintage video games (Pac-Man, Donkey Kong, etc.) & beer lure patrons to this Williamsburg bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9854,
        total_orders: 2063.4,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Salad, Hamburger, Beer, Warm, Healthy, Nachos, Irish, Friendly, Clean',
        annual_dol: 2159.18,
        description:
            'This laid-back Irish bar offers pub grub, pints & plenty of TVs for catching the game.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7131,
        store_longitude: -73.9564,
        total_orders: 427.34,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Wine, Tasty, Bourbon, Dessert, Trendy, Iranian, Beer, Romantic, French Onion Soup',
        annual_dol: 1309.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.715,
        store_longitude: -73.959,
        total_orders: 80.47,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Beer, Rooftop, Hotel, Spicy, French, American, Bright, Delicious, Fun',
        annual_dol: 328.94,
        description:
            '"Located in the heart of Williamsburg (next to the Pod Hotel), Clinton Hall opened its fifth location with the intent to bring some Manhattan flavor to Brooklyn! With an outdoor beer garden for 200+, tons of giant games, amazing food, and our Supercraft beer program, you can\'t go wrong at Clinton Hall Williamsburg!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9847,
        total_orders: 602.94,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Salad, Italian, Wine, Chilean, Japanese, Spicy, Delicious, Asian, Modern',
        annual_dol: 1565.5,
        description:
            'Sushi, infused sakes & modern Japanese fare are served at this sleek venue with an adjacent lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9849,
        total_orders: 10200.86,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Steak, Sushi, Japanese, Tasty, Ramen, Spicy, Trendy, Creamy, Modern, Healthy',
        annual_dol: 11266.04,
        description:
            'This modern restaurant prepares updated yakitori & ramen dishes in a sophisticated space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7494,
        store_longitude: -73.9833,
        total_orders: 181.37,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Shrimp Cocktail, Wine, Spanish, Martini, Upscale, Organic, Spacious, Classy, Classic, Perfect',
        annual_dol: 197.37,
        description:
            'Longtime Spanish eatery with an old-world vibe, dressed-up waiters & a classic menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.9599,
        total_orders: 1542.43,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Steak, Salad, Wine, Beer, Delicious, French Onion Soup, Organic, French, Classy',
        annual_dol: 1575.16,
        description:
            'French-American comfort food in a bistro setting with banquette seating & a summertime garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -73.9828,
        total_orders: 90.77,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tacos, Burritos, Salad, Hotel, Western, Local, Casual, Great',
        annual_dol: 554.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9848,
        total_orders: 2399.25,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Coffee, Spacious, Great, Friendly, Contemporary',
        annual_dol: 9135,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7125,
        store_longitude: -74.0079,
        total_orders: 849.48,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Avocado Toast, Tasty, Coffee, Iconic, Rooftop, Irish, Lively, Impressive, Great, American',
        annual_dol: 2982.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5936,
        store_longitude: -73.5811,
        total_orders: 979.33,
        neighborhood: '',
        keywords:
            'Quaint, Relaxed, Fun, Ale, Fabulous, Casual, Memorable, Great, Wonderful, American',
        annual_dol: 1160.85,
        description:
            'Relaxed local venue serving seafood & American cooking with frozen cocktails & a waterside deck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.9897,
        total_orders: 1865.26,
        neighborhood: 'Lower East Side',
        keywords: 'Tasty, Salad, Delicious, Spicy, Warm, Chinese, Sweet, Asian, Perfect, Authentic',
        annual_dol: 7624.55,
        description:
            '"Right on the border with Chinatown, our speakeasy and tapas bar creates the ultimate intimate mood with classic Asian décor and warm lighting, ideal for whisking your palate into another era, among friends or in the spirit of romance. Serving an array of traditional Szechuan cuisine is crafted into tapas form with a modern twist while cocktails have been given the mixologist’s approach, infused with Chinese herbal botanical elements to create another dimension of flavor. Bite into Canton shumai, Sichuan wontons with house-made chili oil, night market noodles, crispy tofu peppered in our house spice blend, chicken or beef skewers with cumin and peppercorn, yuzu-glazed shrimp, or our absolute signature offering of thirteen-spiced tempura crab"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.9433,
        total_orders: 36.85,
        neighborhood: 'Roosevelt Island',
        keywords: 'Pizza, Beer, Hotel, Iconic, Spectacular, Contemporary, Fresh, Seasonal, Unique',
        annual_dol: 150.65,
        description:
            '"The Sanctuary is a waterfront events & wedding venue boasting sweeping views of the Manhattan skyline. Restored from a century-old stone church on the historic Roosevelt Island, the Sanctuary has a romantic, easy, and contemporary feel; worlds away from the city bustle yet conveniently in the heart of NYC. We feature both indoor and outdoor spaces to host up to 250 guests. We look forward to hosting your wedding, corporate, and other special event needs! Please also join us on our outdoor patio for our seasonal bar menu, open to the public Thursday-Sunday (subject to inclement weather or private events). No RSVPs. Follow us on Instagram @thesanctuaryri for latest announcements!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6327,
        store_longitude: -73.5816,
        total_orders: 345.42,
        neighborhood: '',
        keywords: 'Lobster, Margarita, Mexican, Clean, American, Great, Classic, Fine, Sangria',
        annual_dol: 445.44,
        description:
            'Long-running, old-school eatery on the canal featuring seafood-focused American fare & patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8691,
        store_longitude: -73.9159,
        total_orders: 254.34,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Cuban, Spicy, Trendy, Delicious, Mimosa, Cozy, Spanish, Classy, Pinacolada, Friendly',
        annual_dol: 402.41,
        description:
            'This hookah lounge with late-night hours offers a Latin menu & frequent live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6367,
        store_longitude: -73.5827,
        total_orders: 86.5,
        neighborhood: '',
        keywords: 'Memorable, Rum, Great, Signature',
        annual_dol: 353.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.714,
        store_longitude: -73.9445,
        total_orders: 1199.3,
        neighborhood: 'Williamsburg',
        keywords: 'Hotdog, Salad, Upscale, Spacious, Clean, American, Fun, Fresh, Awesome, Great',
        annual_dol: 1228.5,
        description:
            'Sleek, simple tavern with a garden patio showcasing craft cocktails & upscale bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7111,
        store_longitude: -73.9507,
        total_orders: 506.12,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Whiskey, Vodka, Coffee, Irish, Friendly, Cool, Casual, Fresh, Local, Great',
        annual_dol: 653.79,
        description:
            'Basic neighborhood watering hole with a pool table, video games & skeeball, plus a backyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6128,
        store_longitude: -74.1586,
        total_orders: 1529.4,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Burger, Salad, Beer, Wine, New-York-Style, Dessert, Savory, Ale, Bbq, Chicken Wings',
        annual_dol: 1561.85,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5862,
        store_longitude: -73.6935,
        total_orders: 518.07,
        neighborhood: '',
        keywords: 'Hamburger, Tacos, Salad, Beer, Irish, Casual, American, Local, Sweet, Coleslaw',
        annual_dol: 564.62,
        description:
            "Hopping bi-level hangout draws crowds for live bands & DJ'd tunes, plus standard American bar fare.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7254,
        store_longitude: -73.9833,
        total_orders: 89.02,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Pizza, Wine, Beer, Italian, Vietnamese, Chinese, Upscale, Modern, Indian, Perfect',
        annual_dol: 363.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6365,
        store_longitude: -74.0775,
        total_orders: 176.57,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords:
            'Burger, Lobster, Salad, Dessert, Beer, Wine, Bbq, French, Caesar Salad, Polished',
        annual_dol: 187.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6729,
        store_longitude: -73.957,
        total_orders: 1128.76,
        neighborhood: 'Crown Heights',
        keywords:
            'Pizza, Tasty, Wine, Sustainable, American, Perfect, Amazing, Quirky, Casual, Excellent',
        annual_dol: 1846.62,
        description:
            'A creative menu of American drinks & bites with a sustainable focus inside a funky-hip space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6031,
        store_longitude: -74.1741,
        total_orders: 67.73,
        neighborhood: 'Bloomfield/Mid Island',
        keywords: 'Meatball, Norwegian, Scandinavian, Fun, Amazing, Great, Heritage',
        annual_dol: 276.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6815,
        store_longitude: -73.9583,
        total_orders: 52.56,
        neighborhood: 'Clinton Hill',
        keywords:
            'Tasty, Coffee, American, Friendly, Dope, Amazing, Casual, Great, Seasonal, Hummus',
        annual_dol: 316.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.751,
        store_longitude: -73.9812,
        total_orders: 342.21,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Upscale, Italian, Elegant, Legendary, Excellent, Friendly, Rooftop, Local',
        annual_dol: 1398.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9759,
        total_orders: 71.02,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Mexican, Tacos, Salad, Burritos, Local, Casual, Premium, Healthy',
        annual_dol: 351.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9279,
        total_orders: 17.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Mexican, Pizza, Vegan, Sushi, Colombian, Spanish, American, Rustic, Delicious',
        annual_dol: 72.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7442,
        store_longitude: -73.9875,
        total_orders: 16.57,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Hotel, Fun, Exotic, Cultural, Cool, Modern, Lit, Fine, Unique',
        annual_dol: 67.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7457,
        store_longitude: -73.9932,
        total_orders: 706.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vodka, Whiskey, Wine, Beer, Pizza, Martini, Gin, Delicious, Karaoke, Eclectic',
        annual_dol: 837.51,
        description:
            '"As the first distillery in Manhattan since Prohibition, we pride ourselves in making top-shelf vodka the city can be proud of. At our bar in Chelsea, we craft artisanal cocktails that feature the vodka from our on-site distillery. With a laid back vibe and a passion for all things New York, Our/New York Vodka Bar and Distillery is the place to celebrate being in the greatest city in the world."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5942,
        store_longitude: -73.5787,
        total_orders: 148.13,
        neighborhood: '',
        keywords: 'Pizza, Tasty, Beer, Awesome, Fantastic, Great',
        annual_dol: 201.66,
        description: '"Full Bar and Food Truck. Marine Gas and Diesel. Bait Shop."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9871,
        total_orders: 352.75,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lobster, Pizza, Steak, Beer, Vodka, Italian, Spicy, Casual, Fresh, Authentic',
        annual_dol: 1441.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6316,
        store_longitude: -73.5814,
        total_orders: 10967.82,
        neighborhood: '',
        keywords: 'Vodka, Beer, Wine, Mexican, Delicious, Perfect, Lively, Fresh, Casual, Great',
        annual_dol: 16344.62,
        description:
            'Breezy, informal waterfront operation serving raw & cooked seafood bites along with tropical drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6879,
        store_longitude: -73.9836,
        total_orders: 4023.07,
        neighborhood: 'Boerum Hill/Downtown Brooklyn',
        keywords: 'Hotel, Vintage, Warm, Donut, Serene, Great, Creative, Excellent, Hip, Cool',
        annual_dol: 10933.36,
        description:
            'Featuring vintage furnishings and local artwork, the retro-inspired rooms provide flat-screen TVs, Wi-Fi and minifridges. Some add loaner guitars and vinyl record players. The 1-bedroom suites offer separate living rooms.Amenities include a colorful, hip restaurant and a 24-hour fitness center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.747,
        store_longitude: -74.0084,
        total_orders: 19794.95,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Pizza, Salad, Delicious, Luxurious, Tangy, Martini, Elegant, Spectacular',
        annual_dol: 20274.89,
        description:
            '"Pier Sixty at Chelsea Piers is an exclusive waterfront event venue in NYC. Our beautiful venue is known for our exclusive partnership with Manhattan’s market leader in fine food and service, Abigail Kirsch. Now home to some of Manhattan’s most influential and notable events, Pier Sixty offers inspiring Hudson River water views and flexible event spaces that are perfect for gala fundraisers, corporate events, meetings, weddings and bar/bat mitzvahs. Check us out today!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.68,
        store_longitude: -73.9743,
        total_orders: 869.49,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Cornbread, Salad, Bbq, Turkey Sandwich, Coleslaw, Stylish, Southern, Friendly, Tradition, Fun',
        annual_dol: 907.19,
        description:
            'Trendy, stylish spot serving Texas-style BBQ by the pound, plus sandwiches & Southern sides.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7886,
        store_longitude: -73.8135,
        total_orders: 104.33,
        neighborhood: 'Whitestone/Flushing',
        keywords: 'Burger, Rich, Beer, Karaoke, Fun, Wine, Amazing, Friendly, Clean, Greek',
        annual_dol: 152.43,
        description:
            '"American Bar & Grill.... located in the heart of Whitestone village. Clean, friendly and fun environment. Open everyday till 4am."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.672,
        store_longitude: -73.9573,
        total_orders: 4852.8,
        neighborhood: 'Crown Heights',
        keywords: 'Pizza, Italian, Tasty, Salad, Meatball, Beer, Delicious, Wine, Warm, Spacious',
        annual_dol: 5120.25,
        description:
            'Lively eatery serving Neapolitan brick-oven pizzas plus salads, creative cocktails & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.6918,
        total_orders: 107.93,
        neighborhood: '',
        keywords: 'Burger, Tacos, Pizza, Salad, Nachos, Beer, Spicy, Irish, American, Casual',
        annual_dol: 135.54,
        description:
            '"The Ugly Duckling Bar & Restaurant is now open in the west end of Long Beach, NY. We are the perfect place to watch all the games, go bottoms up, enjoy a bite to eat or shake your tail feather! Offering Outdoor Dining & Curbside Pick Up!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6432,
        store_longitude: -74.0953,
        total_orders: 2012.77,
        neighborhood: 'Mid Island/New Brighton',
        keywords: 'Tequila, Mexican, Beer, Whiskey, Steak, Wine, Tex-Mex, Spicy, Spanish, American',
        annual_dol: 2935.79,
        description:
            'Popular, lively saloon with a Southwestern menu & a big beer selection, plus live music on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7136,
        store_longitude: -73.9587,
        total_orders: 812.51,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Karaoke, Vegan, Chinese, American, Cozy, Local, Casual, Dim Sum, Excellent',
        annual_dol: 829.75,
        description: '"a cozy neighborhood restaurant and karaoke saloon"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.7259,
        total_orders: 39.98,
        neighborhood: 'Flushing/Little Neck',
        keywords: 'Hotdog, Burger, Tacos, Mexican, Salad, Burritos, Warm, Healthy, Casual, Local',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6838,
        store_longitude: -73.9687,
        total_orders: 0,
        neighborhood: 'Fort Greene/Clinton Hill',
        keywords: 'Coffee, Elegant, American, Contemporary, Ample, Classic, Casual, Fine',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.9679,
        total_orders: 717.79,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Vodka, Beer, Martini, Canadian, Spicy, Warm, Fun, Classy, Iconic',
        annual_dol: 752.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7215,
        store_longitude: -73.9586,
        total_orders: 19434.98,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Wine, Pizza, Avocado Toast, Trendy, Hotel, Dessert, South American, Organic, Modern',
        annual_dol: 20581.86,
        description:
            'Empire State Building.Featuring custom furnishings, floor-to-ceiling windows and oak floors, the polished rooms provide free Wi-Fi, flat-screen TVs and designer toiletries; some have balconies with views of Brooklyn and/or Manhattan. Suites add sitting areas.Loaner bikes and an area tuk-tuk shuttle are complimentary. Other amenities include 3 bars, a trendy restaurant and a seasonal rooftop pool, plus a seasonal pop-up restaurant and occasional live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7138,
        store_longitude: -73.966,
        total_orders: 22764.89,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Coffee, Martini, American, Hotel, Burritos, Retro, Hip, Modern',
        annual_dol: 23287.92,
        description:
            'Coffee, cocktails & American gastropub fare served in a hip cafe & bar with a grassy outdoor space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.741,
        store_longitude: -73.9818,
        total_orders: 10123.94,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Burger, Vodka, Wine, Beer, Salad, Irish, American, Sangria, Fresh, Fantastic',
        annual_dol: 10238.09,
        description:
            'Bar bites, cocktails, beer & wine are served in a casual setting with late-night hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9232,
        total_orders: 497.24,
        neighborhood: 'Astoria/LIC',
        keywords: 'Mexican, Breakfast Burrito, Ale, Delicious, Casual, Perfect, Great',
        annual_dol: 541.68,
        description:
            '"Mexican cafe & Agaveria fare offering LA street-style tacos, comfort food, and crafty agave based cocktails & spirits."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -73.9856,
        total_orders: 39.38,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Upscale, Hotel, Italian, Wine, Rich, Spacious, Gourmet, Urban, Creamy, Sophisticated',
        annual_dol: 160.98,
        description:
            'The chic rooms and suites come with free Wi-Fi, 42-inch flat-screens and iPod docks, plus minibars. Suites feature separate living areas, and plush penthouse suites add poker tables, pool tables, Jacuzzis or bars.The hotel has a 2-level brasserie with seafood and French fare, a cocktail lounge with live music, a burlesque-inspired bar and a lobby bar. Other amenities include a business center, meeting space and wedding facilities.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.9938,
        total_orders: 58.27,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Tequila, Mexican, Beer, Wine, American, Perfect, Fantastic, Amazing, Impressive, Casual',
        annual_dol: 238.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6982,
        store_longitude: -73.975,
        total_orders: 35.5,
        neighborhood: 'Brooklyn Navy Yard',
        keywords: 'Coffee, Tasty, Global, Modern, Friendly, Local, Unique',
        annual_dol: 108.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7638,
        store_longitude: -73.9894,
        total_orders: 2723.32,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Irish, Tex-Mex, Friendly, Relaxed, Iconic, Clean, Great, Excellent, Local',
        annual_dol: 2806.83,
        description:
            'Draft beers, TVs & dartboards enliven this sports pub, popular with Pittsburgh Steelers fans.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -73.9888,
        total_orders: 763.04,
        neighborhood: 'Lower East Side',
        keywords: 'Vodka, Wine, Beer, Gin, Salad, Delicious, Hotel, Fun, Hot Toddy, Perfect',
        annual_dol: 837.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7132,
        store_longitude: -73.9847,
        total_orders: 1110.93,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegetarian, Vegan, Steak, Salad, Wine, Tasty, Margarita, Turkish, Mediterranean, Delicious',
        annual_dol: 1156.28,
        description:
            'Turkish & Mediterranean fare, including meze & signature lamb dishes, in a stylish, modern setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7681,
        store_longitude: -73.9858,
        total_orders: 52.07,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Salad, Coffee, American, Omelette, Casual, Classic, Authentic, Great',
        annual_dol: 212.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9796,
        total_orders: 139.5,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Premium, Spacious, American, Awesome, Great, British, Quaint, Excellent, Traditional',
        annual_dol: 570.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9924,
        total_orders: 16.92,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Vegetarian, Mexican, Wine, Brazilian, Dessert, Delicious, Ale, Australian, Classic',
        annual_dol: 69.15,
        description:
            'Laid-back restaurant preparing classic Brazilian specialties with Minas Gerais flavors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6794,
        store_longitude: -73.9824,
        total_orders: 3972.66,
        neighborhood: 'Gowanus',
        keywords: 'Karaoke, Korean, Beer, Wine, Tasty, Salad, Bbq, Delicious, Warm, Sweet',
        annual_dol: 4493.64,
        description:
            'Korean BBQ, noodles & other staples served in a casual, warm environment with private karaoke rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.9961,
        total_orders: 3408.95,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vodka, Margarita, Martini, Upscale, Sweet, Vibrant, Intimate, Amazing, Perfect, Fresh',
        annual_dol: 3408.95,
        description:
            'Gotham Comedy Club is a venue for stand-up comedy in New York City. The comedy club is located on 208 West 23rd Street between Seventh and Eighth avenues in Chelsea. Several movies and television shows have been filmed at the club.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7124,
        store_longitude: -73.9561,
        total_orders: 800.19,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Pizza, Burger, Vodka, Italian, Salad, Wine, Spicy, Caesar Salad, Spacious, Great',
        annual_dol: 854.83,
        description:
            'Detroit-style square pizzas, Italian sandwiches & signature burger in a spacious setting with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7072,
        store_longitude: -74.0024,
        total_orders: 1039.12,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Lobster, Steak, Shrimp Cocktail, Meatball, Beer, Margarita, Wine, Bourbon, Pinacolada',
        annual_dol: 1063.67,
        description:
            'Handsome place for oysters, fried seafood & sandwiches, along with beer & fresh cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7121,
        store_longitude: -73.9438,
        total_orders: 1564.48,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Zombie, Tequila, Beer, Coffee, Ale, Cozy, Romantic, Classy, Friendly',
        annual_dol: 1597.68,
        description:
            'Bar pairing craft drinks with meat & cheese plates, plus a fireplace-equipped patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9799,
        total_orders: 1458.45,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Gracious, Intimate, Classy, Friendly, Perfect, Fun, Chic, Great, Excellent, Casual',
        annual_dol: 1554.29,
        description:
            'Relaxed, intimate cocktail lounge with comfy banquettes, creative drinks & retro music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5922,
        store_longitude: -73.5812,
        total_orders: 144.12,
        neighborhood: '',
        keywords:
            'Garlic Bread, Ale, Wine, Salad, Tasty, Bbq, Warm, American, Friendly, Brownie Sundae',
        annual_dol: 150.36,
        description:
            'Cocktails & eclectic American pub fare served in a warm tavern with 18 TVs & a large patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7141,
        store_longitude: -73.9326,
        total_orders: 634.82,
        neighborhood: 'East Williamsburg',
        keywords: 'Exotic, Casual',
        annual_dol: 648.29,
        description: '"serving up booze and boobs for over 20 years"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 1970.51,
        neighborhood: 'Astoria/LIC',
        keywords: 'Sushi, Salad, Beer, Wine, Coffee, Italian, Dominican, Trendy, Latin, Japanese',
        annual_dol: 2357.54,
        description:
            '"We are a modern restaurant and lounge serving Dominican inspired sushi and Latin-Asian fusion dishes. We open Monday to Friday at 4 PM, with an amazing happy hour called the Bad Hours from 4-7. Saturday and Sunday we open for Brunch at 11AM and we close Monday to Thursday at 2 AM and on Friday to Sunday we close at 3 AM."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9215,
        total_orders: 494.11,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords: 'Wine, Beer, Spacious, Stylish, Irish, Great, Friendly, Casual',
        annual_dol: 832.65,
        description:
            '"Boutique bar serving tapas and craft cocktails. Our spacious backyard patio is covered and heated, open year round and available for parties & private events. Sister bar to Mosaic in Astoria!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7171,
        store_longitude: -73.9587,
        total_orders: 111.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Chinese, Crab Rangoon, Bbq, Fried Rice, Spicy, Karaoke, Asian, Casual, Traditional, Dim Sum',
        annual_dol: 455.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6572,
        store_longitude: -73.6375,
        total_orders: 714.59,
        neighborhood: '',
        keywords:
            'Sushi, Vegetarian, Pizza, Spicy, Gourmet, Wine, Italian, Delicious, Japanese, Chinese',
        annual_dol: 854.6,
        description:
            'Buzzy, bustling eatery dispensing Pan-Asian plates plus wine & other drinks in a sleek setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7787,
        store_longitude: -73.6498,
        total_orders: 1187.89,
        neighborhood: '',
        keywords:
            'Mexican, Italian, Wine, Pizza, Delicious, American, Swiss, Asian, Lively, Contemporary',
        annual_dol: 1326.72,
        description:
            'Large, lively dining room with a Tuscan feel & a wide-ranging menu of Italian & American dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -74.0067,
        total_orders: 214.17,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Steak, Salad, Tasty, Wine, Coffee, French Toast, French, Organic, Mediterranean',
        annual_dol: 282.17,
        description:
            'Laid-back diner serving contemporary American comfort fare, with many organic & gluten-free options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6507,
        store_longitude: -74.0048,
        total_orders: 24.62,
        neighborhood: 'Sunset Park',
        keywords: 'Beer, Wine, Burger, Mexican, Bourbon, Rum, Korean, Bbq, Classy, Relaxed',
        annual_dol: 100.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -74.0099,
        total_orders: 2679.6,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Salad, Wine, Dessert, French, Elegant, Cozy, American, Sleek, Modern',
        annual_dol: 2942.59,
        description:
            'Casually hip hangout offers an unpretentious American menu plus craft cocktails in a snug space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -74.0104,
        total_orders: 233.94,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegan, Vegetarian, Salad, Gluten Free, Tasty, Organic, Dessert, Greek, Mediterranean, Wholesome',
        annual_dol: 821.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6572,
        store_longitude: -73.8397,
        total_orders: 39.98,
        neighborhood: 'Far Rockaway/Howard Beach',
        keywords: 'Mexican, Salad, Organic, Healthy, Casual, Local, Fresh, Recommended',
        annual_dol: 224.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7533,
        store_longitude: -73.9768,
        total_orders: 423.52,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Tacos, Burger, Beer, Spanish, Wine, Martini, Hotel, Elegant, American, Polished',
        annual_dol: 1603.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9504,
        total_orders: 207.66,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Steak, Pizza, Italian, Meatball, Wine, Vodka, Delicious, Bruschetta, Phenomenal',
        annual_dol: 848.86,
        description:
            '"Welcome to Botte UES, an Italian restaurant specializing in Roman cuisine and traditional Italian cocktails located at 1606 1st Ave, New York, NY 10028. It is our pleasure to serve you delicious, inspired Italian dishes from Rome."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7837,
        store_longitude: -73.6494,
        total_orders: 15.87,
        neighborhood: '',
        keywords: 'Burger, Sushi, Pizza, Japanese, Asian, Modern, Delicious, Casual, Local, Fusion',
        annual_dol: 97.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -74.0097,
        total_orders: 5919.57,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Burger, Lobster, Salad, Chicken Caesar Salad, Cajun, Chocolate Mousse, Chilean, Canadian, Wine',
        annual_dol: 6045.18,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7202,
        store_longitude: -73.9967,
        total_orders: 316.09,
        neighborhood: 'Little Italy',
        keywords: 'Pizza, Italian, Salad, Rich, Beer, Ale, Karaoke, Classic, Great, Excellent',
        annual_dol: 351.68,
        description:
            'Old-school venue with dark wood & checkered tablecloths offering pub food & karaoke nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6567,
        store_longitude: -74.0065,
        total_orders: 301.83,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Steak, Pizza, Bbq, Italian, Chinese, Tangy, Vietnamese, Korean, Spicy, Jamaican',
        annual_dol: 807.95,
        description:
            '"We specialize in authentic, pit-smoked meats prepared in the classic Southern technique of smoking on oak wood. We also incorporate a variety of flavor profiles that represent our local communities. Hometown also serves up a selection of traditional sides and dessert. In classic barbecue style, we offer walk-up service to our counter on a first-come, first-served basis—until we sell out of that day’s specially cooked offerings."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6644,
        store_longitude: -73.9943,
        total_orders: 48.17,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Tequila, Mexican, Vodka, Pizza, Bourbon, Rum, Hawaiian, Tasty, American, Latin',
        annual_dol: 196.9,
        description:
            '"Serving authentic cocktails, margaritas , mojitos... We have a large variety of Mezcal & Tequila that you can paired with tasty Mexican Tapas."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9035,
        total_orders: 1512.05,
        neighborhood: 'Woodside',
        keywords:
            'Beer, Tasty, Sushi, Martini, Coffee, Avocado Toast, Delicious, Irish, Jamaican, Friendly',
        annual_dol: 1672.35,
        description:
            '"Honest cocktails, Craft beer, friendly service & a thoughtfully prepared kitchen menu."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9935,
        total_orders: 307.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Lasagna, Italian, Pizza, Wine, Cozy, Classy, Homey, Fabulous, Intimate, Hip',
        annual_dol: 343.82,
        description:
            'Homey Italian venue supplying housemade pastas and other traditional fare in a brick-lined space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.9912,
        total_orders: 79.95,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Mexican, Tacos, Beer, Salad, Burritos, Wine, Delicious, Keto, Casual, Local',
        annual_dol: 326.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9935,
        total_orders: 912.66,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Gin, Beer, Russian, Clean, Rum, Cool, Casual, Awesome, Fine, Great',
        annual_dol: 1020.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.5856,
        total_orders: 1517.91,
        neighborhood: '',
        keywords: 'Pizza, Wine, Dessert, Delicious, Beer, Nachos, Tex-Mex, Asian, Bbq, Sweet',
        annual_dol: 1550.11,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6477,
        store_longitude: -73.9976,
        total_orders: 995.88,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords:
            'Karaoke, Pizza, Vegan, Spicy, Wine, Juicy, Caribbean, Memorable, Fusion, Classic',
        annual_dol: 4070.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.5879,
        total_orders: 787.83,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Tasty, Bbq, Trendy, Yummy, Caesar Salad, Classy, American',
        annual_dol: 824.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.5877,
        total_orders: 397.98,
        neighborhood: '',
        keywords: 'Beer, Warm, Intimate, Cool, Great, Cultural',
        annual_dol: 460.81,
        description:
            'Concert hall and movie theater with live shows and 3D-screenings in warm decor with plush seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7104,
        store_longitude: -73.8494,
        total_orders: 1836.85,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Lobster, Burger, Salad, Japanese, Beer, French, Thai, Bbq, Homemade, Southern',
        annual_dol: 4989.98,
        description:
            "\"Dylan's is a bar and music venue specializing in Southern infused comfort foods. Dylan's includes such delights as buttermilk fried chicken, homemade honey biscuits, shrimp & jalapeño grits, Nashville coleslaw, and smash burgers. Hungry yet?! Our goal is to provide an immersive, warm, convivial family dining experience. An oasis from the day to day grind. Dylan's provides entertainment in a variety of genre’s from Zydeco to Blues, Jazz, Country, and Rock n Roll. We want to put Forest Hills on the map as a destination spot for food, music, and entertainment.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7425,
        store_longitude: -73.9158,
        total_orders: 25.17,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: 'Cool, Casual, Exotic, Fine, Local',
        annual_dol: 41.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7762,
        store_longitude: -73.9532,
        total_orders: 7806.17,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Scotch, Whiskey, Wine, Beer, Scottish, Irish, Cozy, Premium, Warm, Comfortable',
        annual_dol: 8977.77,
        description:
            'Narrow Scottish pub with a warm vibe featuring many whiskies, tap beers & elevated bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8293,
        store_longitude: -73.9284,
        total_orders: 1990.93,
        neighborhood: 'Concourse/Highbridge/West Bronx',
        keywords:
            'Steak, Salad, Coffee, American, Traditional, Exotic, Casual, Global, Fresh, Rare',
        annual_dol: 2215.12,
        description:
            'Rock ’n’ roll-themed chain with a high-energy vibe serving burgers & American classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7147,
        store_longitude: -73.9994,
        total_orders: 2371.58,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Sushi, Wine, Japanese, Beer, Spicy, Upscale, Romantic, Classy, Friendly, Fun',
        annual_dol: 2669.4,
        description:
            'Multilevel spot offering casual Japanese izakaya fare & a reservations-only omakase sushi bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7211,
        store_longitude: -74.0117,
        total_orders: 10497.46,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Lobster, Lobster Roll, Tacos, Beer, Italian, Wine, Margarita, Delicious, Rum',
        annual_dol: 13392.94,
        description:
            'City Winery spin-off with a roof deck for simple American food & wine with river & skyline views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7353,
        store_longitude: -74.0082,
        total_orders: 427.15,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Austrian, Wine, Romantic, Impeccable, Fabulous, Cozy, Global, American, Great, British',
        annual_dol: 456.55,
        description:
            'Lauded, art-filled Austrian eatery serving dishes like salmon strudel, Bavarian pretzels & desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.9891,
        total_orders: 105.72,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Salad, Meatball, Beer, Grilled Salmon, Homemade, Stylish, Casual, Local, Coleslaw, Traditional',
        annual_dol: 139.95,
        description:
            'Longtime kosher deli & diner chain serving sandwiches & Eastern European comfort foods like goulash.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8279,
        store_longitude: -73.6652,
        total_orders: 374.83,
        neighborhood: '',
        keywords: 'Coffee, Cultural, Complex',
        annual_dol: 401.37,
        description:
            '"The Amsterdam at Harborside is the first and only true life care retirement community in Nassau County, on Long Island, for active, independent seniors. Whatever ambitions you have for retirement, The Amsterdam at Harborside lets you accomplish them all with the long-term security and peace of mind of life care. Living at The Amsterdam turns ambition into reality."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7255,
        store_longitude: -74.005,
        total_orders: 922.5,
        neighborhood: 'Hudson Square',
        keywords:
            'Burger, Salad, Italian, Tasty, Delicious, Homemade, Yummy, Polished, Sleek, Modern',
        annual_dol: 942.08,
        description:
            'Modern takes on traditional Italian cooking in an airy, wood-accented space with a marble bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -74.0047,
        total_orders: 5860.42,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Vegetarian, Salad, Mexican, Coffee, Margarita, Tasty, Burritos, Enchiladas, Healthy, Latin',
        annual_dol: 6386.96,
        description:
            'Traditional Mexican menu of classic dishes, margaritas & coffee in a low-key corner dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7751,
        store_longitude: -73.8743,
        total_orders: 119.26,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Coffee, Wine, Beer, Ale, Pizza, Dessert, Hotel, American, Casual, Local',
        annual_dol: 487.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -74.0037,
        total_orders: 2170.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Fun, Picturesque, Awesome, Friendly, Lively, Colorful, Great, Laid-Back, Local',
        annual_dol: 2259.46,
        description:
            'Longtime lesbian & gay Village hangout with a jukebox, drink specials & colorful decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7378,
        store_longitude: -74.002,
        total_orders: 15898.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Italian, Wine, Spicy, Fancy, Sleek, American, Modern, Awesome, Terrific',
        annual_dol: 16588.18,
        description:
            'Updated Italian-American classics with wine & cocktails in a marble-accented space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7354,
        store_longitude: -74.0014,
        total_orders: 2336.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Italian, Wine, Vodka, Meatball, Margarita, Martini, Bruschetta, Rustic, Triple Sec',
        annual_dol: 2605.35,
        description:
            'Rustic, buzzy trattoria from Keith McNally with Italian fare & sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 495.05,
        neighborhood: '',
        keywords:
            'Wine, Beer, Coffee, Comfortable, Healthy, Delicious, Friendly, Casual, Classic, Seasonal',
        annual_dol: 527.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -74.006,
        total_orders: 518.64,
        neighborhood: 'Hudson Square',
        keywords:
            'Italian, Hotel, Coffee, Trendy, Fabulous, American, Classic, Modern, Perfect, Fantastic',
        annual_dol: 553.12,
        description:
            "Modern rooms come with free Wi-Fi and bottled water, plus flat-screen TVs, iPod docks and coffeemakers. Suites add separate living spaces and city views.Free coffee is available in the lobby. There's an airy restaurant offering classic Italian dishes and open-air dining. Other amenities include a 24-hour exercise room, a business center and an outdoor patio.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7348,
        store_longitude: -74.0023,
        total_orders: 72.37,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Mexican, Steak, Margarita, Delicious, American, Latin, Yummy, Fun, Friendly',
        annual_dol: 295.82,
        description:
            'Buzzy Southwestern restaurant known for its margaritas & all-you-can-drink weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7249,
        store_longitude: -73.5506,
        total_orders: 1459.99,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Beer, Whiskey, Salad, Nachos, French, Irish, American, Delicious',
        annual_dol: 1472.01,
        description:
            'Bustling hangout featuring wings & American pub grub served late, plus sports on TV in casual digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.708,
        store_longitude: -74.0089,
        total_orders: 1756.36,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Wine, Gourmet, Bourbon, Italian, Upscale, French, American, Classy, Relaxed',
        annual_dol: 1951.51,
        description:
            'Set on the 60th floor, this ritzy, high-end restaurant features New American cuisine & city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8036,
        store_longitude: -73.9201,
        total_orders: 5.36,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Burger, Hamburger, Salad, Milkshake, Tasty, American, Delicious, Rum, Fresh, Friendly',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7728,
        store_longitude: -73.9164,
        total_orders: 1747.31,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Burger, Steak, Vegetarian, Tacos, Gourmet, Wine, Irish, Yummy, American, Awesome',
        annual_dol: 1884.54,
        description:
            'This tavern offers global lunch & dinner menus in a warm, classic environment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.83,
        store_longitude: -73.6481,
        total_orders: 168.27,
        neighborhood: '',
        keywords: 'Vibrant, Refreshing, Classic',
        annual_dol: 205.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.6991,
        total_orders: 9548.23,
        neighborhood: '',
        keywords:
            'Mexican, Vegan, Salad, Gluten Free, Savory, Spicy, Delicious, Upscale, Fajitas, Latin',
        annual_dol: 9750.83,
        description:
            'Upscale Mexican cantina with a full bar, "Day of the Dead" theme & signature cotton candy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7511,
        store_longitude: -73.675,
        total_orders: 27.77,
        neighborhood: '',
        keywords:
            'Lobster, Chinese, Thai, Tandoori Chicken, Bbq, Asian, Paneer Tikka, Indian, Fusion, Casual',
        annual_dol: 113.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.704,
        store_longitude: -73.8768,
        total_orders: 7.45,
        neighborhood: 'Flushing/Glendale',
        keywords:
            'Steak, Cheesesteak, Gluten Free, Tasty, Beer, Roast Beef, Korean, Bbq, American, Philly Cheesesteak',
        annual_dol: 30.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7542,
        store_longitude: -73.9899,
        total_orders: 21.05,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Pizza, Steak, Salad, Hawaiian, Beer, Dessert, Perfect, Casual, Local',
        annual_dol: 86.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6606,
        store_longitude: -73.9804,
        total_orders: 3978.54,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'Burger, Hamburger, Beer, Salad, Tasty, Organic, Spicy, Cozy, Relaxed, American',
        annual_dol: 4062.95,
        description:
            'Neighborhood craft beer bar with rotating drafts & hearty fare in a corner, window-wrapped setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5766,
        store_longitude: -73.9824,
        total_orders: 1241.71,
        neighborhood: 'Coney Island',
        keywords:
            'Cheesecake, Salad, Pizza, Italian, Dessert, Shrimp Cocktail, Delicious, Filet Mignon, Casual, Traditional',
        annual_dol: 1273.37,
        description:
            'Tuxedo-clad waiters serve old-school Italian fare at this circa-1907 banquet hall-style eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5731,
        store_longitude: -73.9812,
        total_orders: 479.36,
        neighborhood: 'Coney Island',
        keywords:
            'Steak, Lobster, Mexican, Cheesesteak, Beer, Margarita, Tasty, Tex-Mex, Casual, Natural',
        annual_dol: 1380.7,
        description:
            'Open-air eatery on the boardwalk offering casual Mexican grub, signature cocktails & ocean views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6412,
        store_longitude: -73.9561,
        total_orders: 79.05,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Wine, Caribbean, Beer, Salad, Bbq, Spectacular, Zombie, Fried Chicken, Exceptional, Great',
        annual_dol: 168.48,
        description:
            'Serving Caribbean-inspired small plates, this relaxed wine bar boasts a back patio & hosts events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7335,
        store_longitude: -73.955,
        total_orders: 5276.07,
        neighborhood: 'Greenpoint',
        keywords: 'Coffee, Wine, Beer, American, Cozy, Polish, Classy, Natural, Chic, Casual',
        annual_dol: 5388.02,
        description:
            'Cozy coffee shop with a rear garden & light bites that turns into a hip, low-key bar at night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8539,
        store_longitude: -73.8892,
        total_orders: 315.68,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Chicken Parmigiana, Flavorful, Wine, Vodka, Italian, Meatball, Bruschetta, Southern, Fabulous, Casual',
        annual_dol: 345.29,
        description:
            'Neighborhood staple & post-Yankee-game favorite with classic Italian red-sauce dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.966,
        total_orders: 277.85,
        neighborhood: 'Lenox Hill',
        keywords: 'Mexican, Tacos, Beer, Wine, Delicious, Healthy, Local, Casual, Great',
        annual_dol: 321.2,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.9862,
        total_orders: 6222.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Whiskey, Elegant, Delicious, Cozy, Italian, American, Irish, Awesome, Great, Casual',
        annual_dol: 22637.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7524,
        store_longitude: -73.9894,
        total_orders: 5209.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Trendy, Vibrant, Cozy, Urban, Funky, Rooftop, Great, Authentic, Excellent',
        annual_dol: 5319.87,
        description:
            "Compact rooms with contemporary decor and industrial accents offer free Wi-Fi, flat-screen TVs and Bluetooth speakers. Some have subway-tiled walls, in-room steel sinks and bunk beds, while suites add living rooms with sofas.Free fitness classes are offered in a gym. There's a rooftop area with a chic bar/lounge, skyline views, nightly DJs and mini-golf. Other amenities include a seafood brasserie and a vibrant lobby bar, plus a cafe and a sandwich shop.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9918,
        total_orders: 2312.43,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Wine, Stylish, Elegant, Trendy, Iconic, Sleek, Innovative, Urban, Vintage',
        annual_dol: 2722.06,
        description:
            'The polished rooms feature free Wi-Fi, flat-screen TVs and coffeemakers, in addition to minifridges. Room service is offered.Breakfast and parking are available (fee). Other amenities include a stylish, industrial-chic restaurant, a gym and a business center. A 2-story rooftop bar offers views of the city.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -73.9212,
        total_orders: 100.34,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Korean, Beer, Coffee, Spicy, Bbq, Friendly, Local, Traditional, Casual, Great',
        annual_dol: 410.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7354,
        store_longitude: -74.0058,
        total_orders: 3132.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Wine, Seasonal, Casual, Cool, Perfect, Local, Classic, Great, Fantastic',
        annual_dol: 3132.91,
        description:
            "No-frills saloon with an internal ATM, since it's cash only, & a jukebox stocked with classic rock.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7356,
        store_longitude: -74.0062,
        total_orders: 3296.17,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Tequila, Salad, Vodka, Beer, Gin, Avocado Toast, French Onion Soup, French, Danish',
        annual_dol: 3522.38,
        description:
            "Circa-1880 bar with a storied history as a writers' hangout, serving drinks & bar fare.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7398,
        store_longitude: -74.0064,
        total_orders: 2745.31,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Wine, Steak, Italian, Upscale, Delicious, Sophisticated, Gyoza, Artisanal, Great, Signature',
        annual_dol: 2826.52,
        description:
            '"Mollusca NYC is a spirited and sophisticated seafood restaurant with influences from around the world."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6656,
        store_longitude: -73.6656,
        total_orders: 67.84,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Meatball, Wine, Delicious, Local, Casual, Wonderful, Authentic, Classic',
        annual_dol: 81.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7856,
        store_longitude: -73.9767,
        total_orders: 3209.74,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Cheesesteak, Salad, Beer, Coffee, Dessert, Wine, Spicy, Ale',
        annual_dol: 3816.03,
        description:
            'Half bar, half casual American burger joint in a below-ground setting with dog photos on the wall.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7832,
        store_longitude: -73.9788,
        total_orders: 122.35,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Mexican, Beer, Wine, Salad, Spicy, Dessert, Delicious, Rum, Mediterranean',
        annual_dol: 460.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7854,
        store_longitude: -73.9769,
        total_orders: 202.51,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Ramen, Beer, Japanese, Spicy, Sukiyaki, Classic, Cozy, Local, Casual, Great',
        annual_dol: 827.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7041,
        store_longitude: -73.9306,
        total_orders: 80.01,
        neighborhood: 'East Williamsburg',
        keywords: 'Tequila, Vodka, Vegetarian, Beer, Salad, Upscale, Elegant, Rum, Vintage, French',
        annual_dol: 327.04,
        description:
            'Art deco cocktail bar with muted lighting, craft beer & a late-night menu of upscale eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6648,
        store_longitude: -73.5524,
        total_orders: 10.76,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Meatball, Coffee, Dessert, Margarita, Caesar Salad, Homemade, Fresh',
        annual_dol: 14.25,
        description: '"San Marzano Wood Fired Brick Oven Pizza & Hand Made Fresh Pasta"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -73.9578,
        total_orders: 2096.38,
        neighborhood: 'Greenpoint',
        keywords: 'Whiskey, Wine, Beer, Bourbon, Ale, Tasty, American, Premium, Eclectic, Cozy',
        annual_dol: 2140.86,
        description:
            'More than 250 American whiskeys line the bar at this corner spot also serving craft beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7734,
        store_longitude: -73.964,
        total_orders: 424.84,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Pizza, Salad, Wine, Savory, Coffee, Delicious, Upscale, Trendy, Japanese',
        annual_dol: 1731.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7263,
        store_longitude: -73.952,
        total_orders: 620.14,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Beer, Chinese, Martini, Margarita, Spicy, Vintage, Classic, Awesome, Great',
        annual_dol: 633.3,
        description: '"Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -74.0021,
        total_orders: 16533.14,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Whiskey, Salad, Avocado Toast, Spicy, Magical, Spacious, Spanish, American',
        annual_dol: 17324.76,
        description:
            'American classics, burgers, whiskey & cocktails in a neighborhood gastropub with a throwback vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6303,
        store_longitude: -74.1087,
        total_orders: 1482.51,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Steak, Mexican, Pizza, Salad, Nachos, Beer, Margarita, Tex-Mex, Delicious',
        annual_dol: 1531.33,
        description:
            'Located in a converted house, this casual spot offers Mexican fare & drinks amid 1960s decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8692,
        store_longitude: -73.8258,
        total_orders: 79.95,
        neighborhood: 'East Bronx/Baychester',
        keywords: 'Mexican, Vegan, Vegetarian, Salad, Gluten Free, Organic, Casual, Local, Healthy',
        annual_dol: 388.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 255.57,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Gluten Free, Italian, Dessert, Meatball, Wine, Delicious, Warm, Polished',
        annual_dol: 658.57,
        description:
            'Emilia-Romagna cuisine in a warm setting, plus cocktails & a robust Italian wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6944,
        store_longitude: -73.9024,
        total_orders: 639.6,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Burger, Tacos, Mexican, Hamburger, Beer, Milkshake, Salad, Spicy, Delicious, Bbq',
        annual_dol: 653.17,
        description:
            'Chill cocktail bar featuring global bites & weekend brunch amid retro-toy decor & colorful murals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7019,
        store_longitude: -73.9035,
        total_orders: 224.92,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Salad, Wine, Dessert, Ale, Spicy, Cozy, Romantic, Latin, Clean, American',
        annual_dol: 614.6,
        description:
            'Easygoing venue featuring a cocktail bar & a wood-fired grill, plus a daytime cafe with baked goods.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6993,
        store_longitude: -73.9267,
        total_orders: 456.75,
        neighborhood: 'Bushwick',
        keywords:
            'Wine, Gin, French, Vietnamese, Cozy, Delicious, Mediterranean, Seasonal, Classy, American',
        annual_dol: 489.36,
        description:
            'Seasonal French dishes, artisanal cocktails & European wines offered in a modern, casual setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7206,
        store_longitude: -74.005,
        total_orders: 795.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Whiskey, Burger, Hamburger, Beer, Salad, Irish, Upscale, French, Comfortable, Classic',
        annual_dol: 827.98,
        description:
            'No-frills tavern providing low-priced drinks along with bar food, jukebox & shuffleboard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7169,
        store_longitude: -74.0083,
        total_orders: 406.49,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Steak, Hamburger, Salad, Mexican, Tasty, Wine, Margarita, French Toast, Upscale',
        annual_dol: 424.11,
        description:
            'American bistro fare & Sunday brunch highlight this casual, convivial spot with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7172,
        store_longitude: -74.0093,
        total_orders: 516.43,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Champagne, Salad, Vegetarian, Italian, Dessert, Delicious, Ecuadorian, Cozy, Warm',
        annual_dol: 527.39,
        description:
            'French-influenced Italian fixed-price menus in an elegant, vaulted interior with Tuscan touches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7724,
        store_longitude: -73.9555,
        total_orders: 970.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Pizza, Italian, Wine, South American, Upscale, American, Classy, Authentic, Balanced',
        annual_dol: 1022.21,
        description:
            'Longtime eatery with an old-world vibe featuring pastas, fish & meats, plus a lengthy wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7723,
        store_longitude: -73.9556,
        total_orders: 978.8,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Steak, Hamburger, Salad, Beer, Dessert, Wine, Nachos, Bbq, Irish',
        annual_dol: 999.56,
        description:
            'American bar fare & an Irish pub feel along with live traditional Irish music & sports on the TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.8452,
        total_orders: 694.56,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Salad, Wine, Upscale, Organic, French, Beer, Bbq, French Onion Soup, Trendy',
        annual_dol: 740.2,
        description:
            'Local steakhouse offering grilled meats plus sides, wines & cocktails in a wood-lined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8306,
        store_longitude: -73.6898,
        total_orders: 284.96,
        neighborhood: '',
        keywords: 'Beer, Delicious, Healthy, Cozy, Irish, Perfect, Cool, Casual, American, Great',
        annual_dol: 303.91,
        description: '"Cocktail Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.855,
        total_orders: 287.57,
        neighborhood: 'Flushing/Corona',
        keywords: 'Hotel, Modern, Clean, Great',
        annual_dol: 1088.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -74.005,
        total_orders: 10033.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Champagne, Coffee, Shrimp Cocktail, Hotel, Gourmet, Trendy, Stylish, American, Retro',
        annual_dol: 10129.66,
        description:
            "Featuring chic mid-century modern decor, the hip rooms have minibars and Wi-Fi, plus smart TVs, DVD players and iPods; suites add iMacs. Guests can request an in-room goldfish.There's a cafe, a trendy lounge and an oyster bar, plus a basement jazz club serving cocktails and small plates. Other amenities include a private movie theater, a fitness room and 3 meeting rooms. Breakfast and valet parking are available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.5886,
        total_orders: 1896.78,
        neighborhood: '',
        keywords: 'Salad, Italian, Pizza, Dessert, Wine, Organic, Quaint, Warm, Modern, Friendly',
        annual_dol: 1949.67,
        description:
            'Italian fare served in large portions for over 25 years at this lively trattoria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7236,
        store_longitude: -74.0097,
        total_orders: 689.98,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Hotel, Warm, Sophisticated, Classic, Great, Chic, Excellent, Cool, French, Signature',
        annual_dol: 2820.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7724,
        store_longitude: -73.9559,
        total_orders: 208.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Mexican, Beer, Meatball, Nachos, Burritos, Salad, Quesadillas, Spicy, Trendy, Yummy',
        annual_dol: 254.62,
        description:
            'Simple Mexican tacos, burritos & quesadillas plus booze served amid contemporary decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7753,
        store_longitude: -73.9567,
        total_orders: 172.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Chinese, Spicy, Martini, Delicious, Upscale, Beer, Juicy, Bbq, Peking Duck',
        annual_dol: 175.68,
        description:
            'Taiwanese-style cooking with a pig-centric menu, plus kitschy pink decor to match the theme.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7461,
        store_longitude: -73.915,
        total_orders: 257,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: 'Coffee, Beer, Wine, Shrimp Cocktail, Quaint, Cool, Fresh, Great, Casual, Unique',
        annual_dol: 274.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8089,
        store_longitude: -73.9306,
        total_orders: 1244.27,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Burger, Pizza, Beer, Coffee, Wine, Healthy, Great, Casual, Creative, Signature',
        annual_dol: 5086.17,
        description:
            '"The Bruckner Building provides Office Space in the heart of Mott Haven. The Bruckner building is the new standard of excellence in the South Bronx. We have units sized for any type of business ranging from 1,000-20,000 SF. Feel free to contact us for office space."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7743,
        store_longitude: -73.8746,
        total_orders: 219.38,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Burger, Lobster, Lobster Roll, Pizza, Shrimp Cocktail, Salad, Beer, Coffee, Wine, Spanish',
        annual_dol: 896.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.705,
        store_longitude: -74.0088,
        total_orders: 127.24,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Lobster Tail, Burger, Shrimp Cocktail, Salad, Meatball, Greek, Mediterranean, Wine, Organic',
        annual_dol: 787.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7082,
        store_longitude: -73.6252,
        total_orders: 104.65,
        neighborhood: '',
        keywords: 'Pizza, Italian, Casual, Sweet',
        annual_dol: 394.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8267,
        store_longitude: -73.9391,
        total_orders: 81.13,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Beer, Wine, Caribbean, Delicious, Jamaican, Cozy, Friendly, Great, Traditional, Excellent',
        annual_dol: 306.45,
        description:
            '"Keeping live jazz in Harlem. Cozy atmosphere for food drink and jazz. Join us for Thursday Happy Hour, Friday Night for our Jazz Jam and again on Saturdays for our live events. Relax n Sundays with our Jazz Brunch."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6756,
        store_longitude: -73.9812,
        total_orders: 657.95,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Cajun, Spicy, Juicy, Rich, Homey, Modern, Southern',
        annual_dol: 769.41,
        description:
            'Homey spot serving bags of crab, lobster & more shaken with spices & paired with Southern sweet tea.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6659,
        store_longitude: -73.989,
        total_orders: 75.02,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Steak, Tacos, Hamburger, Mexican, Cajun, Salad, Beer, Wine, Ale',
        annual_dol: 306.65,
        description:
            'Warm hangout with a patio dishing up casual global fare from tacos to pasta, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7235,
        store_longitude: -73.9508,
        total_orders: 9081.63,
        neighborhood: 'Greenpoint',
        keywords:
            'Japanese, Dessert, Delicious, Stylish, Mediterranean, Ramen, Exotic, Classic, Global, Great',
        annual_dol: 10328.87,
        description:
            'Tucked behind a ramen restaurant, a hip spot with Japanese small plates & exotic craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7239,
        store_longitude: -73.951,
        total_orders: 1833.89,
        neighborhood: 'Greenpoint',
        keywords: 'Steak, Salad, Milkshake, Coffee, Beer, Upscale, Bbq, Friendly, Fun, Vintage',
        annual_dol: 2660.62,
        description:
            'Listening bar serving up vinyl tracks on a vintage turntable plus burgers, beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7224,
        store_longitude: -73.9498,
        total_orders: 95.91,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Burger, Tacos, Mexican, Margarita, Delicious, Wine, Trendy, Phenomenal, Ale, Organic',
        annual_dol: 392.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6147,
        store_longitude: -73.9364,
        total_orders: 99.68,
        neighborhood: 'Marine Park',
        keywords: 'Burger, Pizza, Steak, Italian, Wine, Fun, American, Great, Wonderful, Excellent',
        annual_dol: 122.57,
        description:
            '"Established in 2009 & located in the heart of Marine Park, Brooklyn NY, 3rd&7 has become the premier venue for sports, events, fun and all around happiness. We\'ve taken pride in our excellent service and wonderful patrons who visit us everyday."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5916,
        store_longitude: -74.1011,
        total_orders: 1900.67,
        neighborhood: 'Todt Hill',
        keywords: 'Wine, Dessert, Italian, Mexican, Beer, Trendy, Yummy, Classy, Comfortable, Fun',
        annual_dol: 2004.69,
        description:
            'Energetic restaurant offering Italian specialties, wines & live music in comfortable surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6559,
        store_longitude: -73.6453,
        total_orders: 1188.24,
        neighborhood: '',
        keywords: 'Burger, Beer, Cajun, Whiskey, Ale, Nachos, Bbq, American, Spicy, Delicious',
        annual_dol: 1260.47,
        description:
            'Outpost of a local alehouse chain featuring a selection of craft beers, pub grub & wing specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8227,
        store_longitude: -73.7033,
        total_orders: 989,
        neighborhood: '',
        keywords: 'Upscale, Elegant, Intimate, Spectacular, Great, Fantastic, Exceptional, Casual',
        annual_dol: 1053.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9945,
        total_orders: 419.54,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Lasagna, Pizza, Italian, Chicken Parmigiana, Wine, Beer, Delicious, Margarita, Rustic',
        annual_dol: 479.25,
        description:
            'Casual choice for rustic Sicilian cuisine paired with cocktails featuring Italian ice.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9836,
        total_orders: 127.09,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Italian, French, Mediterranean, Elegant, American, Contemporary, Premium, Modern, High-End',
        annual_dol: 144.74,
        description:
            'Theater District.The understated rooms have wood accents and marble bathrooms, as well as free Wi-Fi, flat-screen TVs and coffeemakers. Suites, all on upper floors, have kitchens and city views.A contemporary bar serves casual food and drink, while a chic restaurant offers Mediterranean fine dining. Other amenities include a luxury spa, a 24-hour fitness center and a hair salon, plus 7,500 sq ft of event space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8445,
        store_longitude: -73.6956,
        total_orders: 160.83,
        neighborhood: '',
        keywords: 'Polish, American, Wine, Cultural, Classic, Great',
        annual_dol: 197.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8288,
        store_longitude: -73.7018,
        total_orders: 40.87,
        neighborhood: '',
        keywords: 'Hotel, Intimate, Excellent',
        annual_dol: 167.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.9761,
        total_orders: 13.45,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Tacos, Salad, Wine, Coffee, Beer, Gin, Delicious, Sustainable, Eclectic',
        annual_dol: 54.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7811,
        store_longitude: -73.8412,
        total_orders: 10.12,
        neighborhood: 'College Point/Flushing',
        keywords: 'Karaoke, Beer, Chinese, Casual',
        annual_dol: 41.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6351,
        store_longitude: -74.0202,
        total_orders: 47,
        neighborhood: 'Bay Ridge',
        keywords: 'Delicious, Fresh, Great, Casual',
        annual_dol: 62.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7902,
        store_longitude: -73.9727,
        total_orders: 234.37,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Steak, Salad, Vegan, Mexican, Nachos, Burritos, Margarita, Bbq, Spanish, Casual',
        annual_dol: 574.18,
        description:
            'Bustling Mexican option serving up a menu of margaritas, tacos, nachos & giant burritos.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7431,
        store_longitude: -73.921,
        total_orders: 32.45,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Salad, Spicy, Korean, Coffee, Delicious, Bbq, Yummy, Hotel, Edgy, Mac And Cheese',
        annual_dol: 132.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7092,
        store_longitude: -73.8699,
        total_orders: 256.48,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords:
            'Sushi, Salad, Steak, Japanese, Spicy, Chinese, Casual, Traditional, Authentic, Local',
        annual_dol: 900.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7067,
        store_longitude: -73.9056,
        total_orders: 201.81,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Steak, German, Beer, Wine, Roast Beef, Fun, American, Awesome, Amazing, Wonderful',
        annual_dol: 210.56,
        description:
            'Long-standing tavern serving domestic & imported beers alongside a small menu of German pub eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.6734,
        total_orders: 41.9,
        neighborhood: '',
        keywords:
            'Burger, Sushi, Hamburger, Salad, Gourmet, Beer, Delicious, Trendy, American, Healthy',
        annual_dol: 171.26,
        description:
            'Laid-back outpost offering typical American bar cuisine alongside craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.699,
        store_longitude: -73.909,
        total_orders: 102.28,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Burger, Tacos, Steak, Salad, Vegan, Mexican, Dessert, Margarita, Delicious, Fajitas',
        annual_dol: 418.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6753,
        store_longitude: -73.9814,
        total_orders: 539.06,
        neighborhood: 'Park Slope',
        keywords:
            'Salad, Wine, Italian, Cozy, Delicious, Quaint, Comfortable, Old Fashioned, Classy, Vintage',
        annual_dol: 613.95,
        description:
            'Northern Italian mainstay with an intimate, old-world-inspired dining room & an attached wine bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6774,
        store_longitude: -73.9863,
        total_orders: 662.64,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Wine, Cozy, Friendly, Perfect, Ample, Classic, Great, Fantastic, Casual',
        annual_dol: 691.37,
        description:
            'This mellow haunt serves cocktails, brews & wines amid exposed brick walls & an old-school feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6779,
        store_longitude: -73.9859,
        total_orders: 976.18,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Ale, Hip, Friendly, Classic, Casual, Local, Great, Chill',
        annual_dol: 1018.51,
        description:
            'Wood-paneled neighborhood tavern with a chill vibe, handful of draft beers & pet-friendly policy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7254,
        store_longitude: -73.9462,
        total_orders: 1780.83,
        neighborhood: 'Greenpoint',
        keywords: 'Sushi, Beer, Cozy, Charming, Bourbon, Fun, Clean, Perfect, Colorful, Casual',
        annual_dol: 1856.35,
        description:
            'Green velvet booths & stained glass add to the charming art nouveau vibe at this chic cocktail bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7076,
        store_longitude: -73.9434,
        total_orders: 894.6,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Salad, Wine, Beer, Fun, Memorable, Great, Fine, Casual, Local',
        annual_dol: 1217.83,
        description:
            '"Eris is a restaurant, art, performance, and music event space with full bar, Funktion One sound, and an immersive, genuine experience of local artists and community."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.695,
        store_longitude: -73.9314,
        total_orders: 1274.42,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Margarita, Hotel, Ale, Fun, Vintage, Classy, Friendly, Classic',
        annual_dol: 1301.47,
        description:
            '"open 7 days - cold beer - frozen cocktails - natural wine - cheap happy hour - private patio open late"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6248,
        store_longitude: -74.132,
        total_orders: 98.46,
        neighborhood: 'Westerleigh/Mid Island',
        keywords:
            'Italian, Pizza, Wine, Beer, Delicious, Spanish, Classy, Fantastic, Great, Casual',
        annual_dol: 325.52,
        description: '"Italian Restaurant and Catering Hall"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7255,
        store_longitude: -73.9447,
        total_orders: 2328.79,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Beer, Cozy, Local, Casual, Great',
        annual_dol: 2378.2,
        description:
            'Simple pub grub & cocktails served in an old-timey setting with wood beams & stained-glass windows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7079,
        store_longitude: -73.9437,
        total_orders: 99.28,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Salad, Venezuelan, Gin, Spanish, Uruguayan, American, Farm-To-Table, Latin, Stylish, Delicious',
        annual_dol: 173.65,
        description:
            'Elevated takes on classic Venezuelan plates are served in a stylish space with an intimate vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7661,
        store_longitude: -73.957,
        total_orders: 99.73,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Mexican, Tacos, Beer, Salad, Burritos, Wine, Hotel, Western, Local, Casual',
        annual_dol: 407.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7035,
        store_longitude: -74.0118,
        total_orders: 2416.92,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Rooftop, Italian, Trendy, Coffee, Hotel, Mediterranean, Delicious, Warm, Beer',
        annual_dol: 4939.78,
        description:
            'Warm Italian restaurant with a homespun rooftop bar & city views, plus private booths & pods.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -74.0064,
        total_orders: 585.91,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Coffee, Avocado Toast, Savory, French, Australian, Delicious, American, Quaint, Hotel',
        annual_dol: 797.61,
        description:
            'Bakery/cafe branch providing sweets, French & American dishes, wines & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -74.0061,
        total_orders: 188.49,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Mexican, Rich, Italian, Seasonal, Comfortable, Fun, Casual, Greek, American',
        annual_dol: 797.7,
        description:
            '"One White Street is a neighborhood restaurant in downtown New York City from Chef Austin Johnson and Master Sommelier Dustin Wilson. Located in a historic Tribeca townhouse, One White Street is a place to feel at home. One White Street’s menu evolves with the seasons and is informed by exceptional ingredients organically grown and sourced year-round from Rigor Hill Farm in Columbia County, New York."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.5723,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Korean, Bbq, Kbbq, Asian, Wine, Fusion, Classy, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6874,
        store_longitude: -73.8205,
        total_orders: 48.26,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Delicious, Fun, Polish, Polished, Gourmet, Premium, Friendly, Caribbean, Intimate, Zen',
        annual_dol: 84.94,
        description:
            'Polished Caribbean lounge offering cocktails, light bites & hookah till the wee hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -73.9223,
        total_orders: 1035.28,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Burger, Vodka, Wine, Salad, Gin, Contemporary, American, Modern, Urban',
        annual_dol: 1095.57,
        description:
            'This modern gastropub with garden seating offers creative drinks & New American grub, plus brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7717,
        store_longitude: -73.8666,
        total_orders: 1054,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Hotdog, Burger, Pizza, Coffee, Spanish, American, Dominican, Hotel, Western, Casual',
        annual_dol: 4308.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7076,
        store_longitude: -73.9209,
        total_orders: 913.46,
        neighborhood: 'Bushwick',
        keywords: 'Hamburger, Rooftop, American, Beer, Wine, Hotel, Asian, Fusion, Awesome, Great',
        annual_dol: 1023.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7058,
        store_longitude: -73.9239,
        total_orders: 6375.5,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Tequila, Vegan, Beer, Vegetarian, Whiskey, Painkiller, Delicious, Warm, Cozy',
        annual_dol: 6510.78,
        description:
            'Cozy watering hole boasting a fireplace patio, plus cocktails, beer in cans & bottles & munchies.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7405,
        store_longitude: -74.0069,
        total_orders: 938.17,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Salad, Tasty, Gluten Free, Wine, Delicious, Trendy, Upscale, Spanish, Elegant',
        annual_dol: 992.29,
        description:
            'Upscale restaurant & bar serving seasonal Mediterranean meals prepared with flavored olive oils.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7327,
        store_longitude: -74.0036,
        total_orders: 984.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Italian, Gourmet, Beer, Wine, Savory, Delicious, Bourbon, Premium, American',
        annual_dol: 1046.2,
        description:
            'Italian eatery & wine bar for salads, meats, cheeses & larger plates, with a grocery section too.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6494,
        store_longitude: -73.6762,
        total_orders: 919.58,
        neighborhood: '',
        keywords: 'Sushi, Salad, Chilean, Wine, Japanese, Beer, Chinese, Sashimi, Ramen, Organic',
        annual_dol: 1134.39,
        description:
            'Easygoing local Japanese restaurant featuring sushi menus & hibachi dining with tableside chefs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8488,
        store_longitude: -73.8543,
        total_orders: 34.47,
        neighborhood: 'Morris Park',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Meatball, French, Delicious, Bruschetta, Great, Casual',
        annual_dol: 40.17,
        description:
            'Longtime family-owned bar & eatery offering pizza & other Italian dishes in a casual dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -74.003,
        total_orders: 8.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Vegetarian, Mexican, Salad, Chinese, Casual, Local, Eclectic, Superb, Excellent',
        annual_dol: 33.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7838,
        store_longitude: -73.9776,
        total_orders: 609.6,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Tomato Soup, Dessert, Fruity, Coffee, Velvety, Spicy, Warm, Hotel, Mediterranean',
        annual_dol: 637.94,
        description:
            'Posh chain restaurant serving American fare, including brunch, dinner & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.786,
        store_longitude: -73.9724,
        total_orders: 187.89,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Hotdog, Salad, Beer, Dessert, Mediterranean, American, French, Homemade, Swiss, Retro',
        annual_dol: 247.5,
        description:
            'Understated diner serving breakfast, meat dishes, pasta & burgers, plus beer & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.6565,
        total_orders: 29.63,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Indian, Casual, High-Protein, Authentic',
        annual_dol: 151.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -73.8458,
        total_orders: 39.98,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Mexican, Tacos, Beer, Burritos, Wine, Organic, Warm, Local, Casual, Healthy',
        annual_dol: 194.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6999,
        store_longitude: -73.9067,
        total_orders: 174.14,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Salad, Mexican, Dessert, Beer, Upscale, Ale, Spicy, French, Classy, American',
        annual_dol: 189.51,
        description:
            'Upscale restaurant featuring classic American meals, cocktails & brunch in calm, understated digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6713,
        store_longitude: -73.9777,
        total_orders: 39.98,
        neighborhood: 'Park Slope',
        keywords: 'Mexican, Tacos, Burritos, Local, Casual',
        annual_dol: 224.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.699,
        store_longitude: -73.9265,
        total_orders: 391.51,
        neighborhood: 'Bushwick',
        keywords:
            'Beer, Burger, Mexican, Wine, Tasty, Colombian, Uruguayan, Warm, Cozy, Mediterranean',
        annual_dol: 747.43,
        description:
            'Warm setting for Colombian-European dinners with Basque accents, plus beer, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6587,
        store_longitude: -73.6448,
        total_orders: 1909.07,
        neighborhood: '',
        keywords:
            'Steak, Burger, Hamburger, Salad, Mexican, Vodka, Dessert, Nutritious, Italian, Delicious',
        annual_dol: 1949.57,
        description:
            'Modern New American spot serving hearty fare & often drawing lively crowds on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6781,
        store_longitude: -73.7469,
        total_orders: 737.83,
        neighborhood: 'Laurelton/Jamaica/Springfield Gardens',
        keywords:
            'Lobster Roll, Lobster, Burger, Salad, Red Velvet Cake, Dessert, Wine, Bbq, Juicy, Caribbean',
        annual_dol: 2593.99,
        description: '"Casual dining seafood restaurant with a Caribbean flair"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6175,
        store_longitude: -73.9321,
        total_orders: 400.66,
        neighborhood: 'Marine Park',
        keywords:
            'Italian, Shrimp Cocktail, Pizza, Wine, Homemade, Rack Of Lamb, Casual, Fun, Perfect, Exceptional',
        annual_dol: 414.36,
        description:
            'White-tablecloth Italian institution serving veal, rack of lamb & seafood, plus pasta dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7536,
        store_longitude: -73.9912,
        total_orders: 460.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Champagne, Sustainable, Sweet, Perfect, Premium, Incredible, Modern',
        annual_dol: 513.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7458,
        store_longitude: -73.9151,
        total_orders: 335.76,
        neighborhood: 'LIC/Sunnyside/Woodside',
        keywords:
            'Hotdog, Tacos, Steak, Mexican, Tasty, Margarita, Spicy, Wine, Delicious, Colombian',
        annual_dol: 342.89,
        description:
            'Cozy eatery offering creative small plates, tacos & burgers, plus a bar & open-air dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6222,
        store_longitude: -73.9036,
        total_orders: 90.61,
        neighborhood: 'Bergen Beach',
        keywords: 'Cozy, Fun, Great',
        annual_dol: 94.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6739,
        store_longitude: -73.6263,
        total_orders: 1272.83,
        neighborhood: '',
        keywords: 'Beer, Golfclub, Comfortable, Ample, Great, Amazing, Seasonal, Recommended',
        annual_dol: 2155.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8189,
        store_longitude: -73.8173,
        total_orders: 580.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Perfect, Cool, Casual, Sweet, Legendary, Great, Local',
        annual_dol: 604.33,
        description: '"Bar & Lounge for Neighbors, Friends and Sports Enthusiasts"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6407,
        store_longitude: -73.6599,
        total_orders: 81.89,
        neighborhood: '',
        keywords: 'Irish, Bold, Lobster, Tasty, Fantastic, American, Mead, Great, Reuben, Friendly',
        annual_dol: 97.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7524,
        store_longitude: -73.9903,
        total_orders: 1665.83,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Salad, Tasty, Beer, Margarita, Bbq, Spicy, French Onion Soup, Mediterranean',
        annual_dol: 1701.17,
        description:
            'Vibrant pub with a large oak bar, TVs & occasional live Celtic music, serving Irish & American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6586,
        store_longitude: -73.6413,
        total_orders: 288.98,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Turkey Sandwich, Coffee, Warm, Comfortable, Clean, Fun, Welcoming, Modern',
        annual_dol: 288.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8258,
        store_longitude: -73.8215,
        total_orders: 150.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Burger, Beer, Fun, Cozy, Legendary, Awesome, Friendly, Perfect, American, Great',
        annual_dol: 719.21,
        description:
            '"We provide a cozy indoor space with an ambiance that allows friends, wanderers, and explorers to share a drink as equals. You\'ll be charmed with every sip of our professionally crafted drinks menu."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7758,
        store_longitude: -73.6334,
        total_orders: 1745.87,
        neighborhood: '',
        keywords: 'Luxurious, Opulent, Distinct',
        annual_dol: 1891.3,
        description:
            '"Unveil the curtain to Long Island’s opulent past, and discover, at the heart of exclusive Roslyn Heights, The Royalton. This colonial mansion of the early 20th century stands on 10 acres of wooded arbor and English gardens restored to a magnificence matched only by the level of grandeur of each catered affair. The Royalton is the Long Island Wedding Mansion of choice."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9982,
        total_orders: 4445.53,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Vegetarian, Vegan, Salad, Gluten Free, Meatball, Italian, French Toast, French, American',
        annual_dol: 4445.53,
        description:
            'Contemporary spot specializing in seasonal, locally sourced & mostly gluten-free comfort classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7516,
        store_longitude: -73.9874,
        total_orders: 1945.3,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Hotel, Elegant, Iconic, Fabulous, Classic, Memorable, Comfortable, Exquisite, Classy',
        annual_dol: 2070.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7256,
        store_longitude: -74.0074,
        total_orders: 133.97,
        neighborhood: 'Hudson Square',
        keywords: 'Pizza, Wine, Italian, Beer, Tasty, Homemade, Classy, Intimate, Fusion, Tiramisu',
        annual_dol: 168.25,
        description:
            'Intimate eatery offering Emilia-Romagna dishes, outdoor seating & private dining in a wine cellar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7194,
        store_longitude: -73.8423,
        total_orders: 6826.69,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Steak, Lobster, Mexican, Beer, Chili Con Carne, Tasty, Margarita, Coffee, Vodka',
        annual_dol: 13022.76,
        description:
            'Lively local restaurant serving strong margaritas & Mexican fare in a small, funky setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.776,
        store_longitude: -73.9562,
        total_orders: 753.42,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Steak, Pizza, Sushi, Salad, Meatball, Italian, Canadian, Thai, American',
        annual_dol: 1755.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5722,
        store_longitude: -74.1127,
        total_orders: 64.47,
        neighborhood: 'New Dorp',
        keywords:
            'Vegetarian, Butter Chicken, Dessert, Coffee, Delicious, Chicken Tikka Masala, Tangy, Italian, Charming, Romantic',
        annual_dol: 77.14,
        description:
            'Long-standing vibrant outpost offers familiar Indian dishes & a weekend buffet in a snazzy interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -73.7331,
        total_orders: 120.31,
        neighborhood: 'Queens Village',
        keywords: 'Steak, Chinese, Spicy, French, Fried Rice, Indian, Casual, Great',
        annual_dol: 491.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7202,
        store_longitude: -73.9968,
        total_orders: 226.29,
        neighborhood: 'Little Italy',
        keywords:
            'Garlic Bread, Cheesecake, Chicken Parmigiana, Italian, Tasty, Hotel, Friendly, Authentic, Intimate, Amazing',
        annual_dol: 246.26,
        description:
            'Hearty helpings of classic Italian fare dished up in a simple, long-running (since 1968) trattoria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7761,
        store_longitude: -73.9625,
        total_orders: 69.97,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Pizza, Vegetarian, Italian, Salad, Meatball, Tasty, Dessert, Spicy, Vodka, Fabulous',
        annual_dol: 245.08,
        description:
            'Bi-level Italian restaurant with thin-crust pizzas & homemade pastas, plus a rooftop terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7547,
        store_longitude: -73.8539,
        total_orders: 437.44,
        neighborhood: 'North Corona/Flushing/Corona',
        keywords:
            'Shrimp Cocktail, Vodka, Salad, Beer, Gluten Free, Ale, Italian, Hotel, Bbq, Homemade',
        annual_dol: 1788.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9951,
        total_orders: 1200.46,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Pizza, Vegetarian, Italian, Salad, Meatball, Dessert, Wine, Classy, Vibrant, Casual',
        annual_dol: 1285.26,
        description:
            'Traditional Italian dishes, pizzas & cocktails served in a relaxed, industrial-chic restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9995,
        total_orders: 22904.69,
        neighborhood: 'Little Italy',
        keywords:
            'Flavorful, Vegan, Upscale, Trendy, Wine, Delicious, Salad, Impeccable, Chinese, Asian',
        annual_dol: 26361.88,
        description:
            'Cocktails & Asian fusion eats are offered in this industrial-chic eatery with an atrium & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7724,
        store_longitude: -73.9555,
        total_orders: 6103.49,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Burger, Tasty, Salad, Meatball, Delicious, Italian, Spicy, Sustainable, Funky',
        annual_dol: 6312,
        description:
            'Cozy chophouse offering updated dishes & sustainable cuts, plus playful artwork & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7338,
        store_longitude: -74.0026,
        total_orders: 11716.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Colorful, Complex, Great, Casual',
        annual_dol: 11737.74,
        description:
            'A gay & straight crowd camps out at this bi-level bar for piano sing-alongs, drag revues & comedy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7312,
        store_longitude: -74.004,
        total_orders: 147.31,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Delicious, Coffee, Italian, Bright, Vibrant, American, French, Friendly',
        annual_dol: 206.37,
        description:
            'Craft cocktails are served in a swanky space with a neon sign casting a purplish glow.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7741,
        store_longitude: -73.8709,
        total_orders: 59.95,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Salad, Wine, Beer, Serene, Spacious, Casual, Bright, American',
        annual_dol: 245.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8559,
        store_longitude: -73.8677,
        total_orders: 126.08,
        neighborhood: 'East Bronx/Pelham Parkway',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Cajun, Spicy, Triple Sec, Casual, Classic, Great',
        annual_dol: 515.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.784,
        store_longitude: -73.9779,
        total_orders: 105.53,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Coffee, Fresh, Friendly',
        annual_dol: 431.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6339,
        store_longitude: -74.0099,
        total_orders: 174.43,
        neighborhood: 'Sunset Park',
        keywords: 'Karaoke, Kebab, Wine, Spicy, Clean, Classic, Wonderful, Great, Excellent, Pure',
        annual_dol: 713.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6155,
        store_longitude: -73.9937,
        total_orders: 1085.86,
        neighborhood: 'Bensonhurst',
        keywords:
            'Vegetarian, Sushi, Salad, Japanese, Spicy, Asian, Sashimi, Casual, Authentic, Excellent',
        annual_dol: 2241.8,
        description:
            'Basic Japanese eatery offering sushi & other specialties like hibachi meats in sparse surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7416,
        store_longitude: -73.6614,
        total_orders: 7969.87,
        neighborhood: '',
        keywords:
            'Tacos, Steak, Tequila, Mexican, Tasty, Beer, Margarita, Californian, Delicious, Hawaiian',
        annual_dol: 8138.98,
        description:
            '"AVO TACO is a fresh, new spot to relax and enjoy yummy deliciousness in a fun, fast-casual environment."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6544,
        store_longitude: -73.9594,
        total_orders: 325.94,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Salad, Pizza, Vegetarian, Flavorful, Wine, Margarita, Caribbean, Rum, Jamaican, Romantic',
        annual_dol: 1569.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7638,
        store_longitude: -73.9145,
        total_orders: 248.41,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, Cajun, Cajun-Style, Beer, Irish, American, Vintage, Homemade, Premium, Creole',
        annual_dol: 656.2,
        description:
            'Sleek bar dishing up raw & cooked Cajun-style seafood by the pound along craft beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8265,
        store_longitude: -73.8223,
        total_orders: 922.65,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Mexican, Beer, Shrimp Cocktail, Wine, Coffee, Dessert, American, Homemade, Modern, Legendary',
        annual_dol: 1637.58,
        description:
            'American comfort eats & craft beer served in a low-key sports bar with a backyard patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.704,
        store_longitude: -73.9191,
        total_orders: 3504.92,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Salad, Adventurous, Asian, Vibrant, Local, Great, Casual',
        annual_dol: 3875.28,
        description:
            'Vibrant bar & happy hour haunt with Asian style décor serving skewers, hot dogs & bowls til 2am.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6238,
        store_longitude: -73.9757,
        total_orders: 218.19,
        neighborhood: 'Mapleton/Midwood',
        keywords: 'Burger, Salad, Beer, French, Fancy, Relaxed, Casual, Great',
        annual_dol: 222.82,
        description:
            'Relaxed neighborhood bar with a jukebox, pool table, darts & other games, plus sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6586,
        store_longitude: -73.6452,
        total_orders: 55.11,
        neighborhood: '',
        keywords: 'American, Comfortable, Delicious, Rum, Ample, Casual',
        annual_dol: 208.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6298,
        store_longitude: -74.0742,
        total_orders: 593.14,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Pizza, Cheesecake, Salad, Italian, Coffee, Meatball, Dessert, Delicious, Modern, Sparkling',
        annual_dol: 697.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8623,
        store_longitude: -73.6231,
        total_orders: 18.26,
        neighborhood: '',
        keywords:
            'Steak, Burger, Salad, French Toast, Italian, French, Bbq, Greek, Quesadillas, American',
        annual_dol: 32.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7546,
        store_longitude: -73.9872,
        total_orders: 4238.51,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Stylish, Beer, Scotch, Canadian, Quaint, Delicious, Cozy, Spicy, Vintage',
        annual_dol: 4339.62,
        description:
            'Quaint, classy hangout with a vintage vibe presenting craft cocktails, local beers & diverse dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7509,
        store_longitude: -73.9868,
        total_orders: 88.75,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Chicken Caesar Salad, Beer, Coffee, Vodka, Tasty, Caesar Salad, American',
        annual_dol: 362.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7561,
        store_longitude: -73.9934,
        total_orders: 494.05,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Friendly',
        annual_dol: 711.5,
        description:
            'The understated rooms provide flat-screen TVs and Wi-Fi, plus coffeemakers and minifridges. Some feature city views and/or sofabeds.Amenities include 24-hour bar service and event space. Breakfast is available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.9988,
        total_orders: 20394.81,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Beer, Wine, Chinese, Vintage, Fun, Impressive, Great, Lit, Signature, Casual',
        annual_dol: 22047.91,
        description:
            'Cocktails, beer & wine served in a unique, dimly lit bar that has beer pong, dice games & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -74.0032,
        total_orders: 5065.21,
        neighborhood: 'Civic Center',
        keywords:
            'Lobster, Tasty, Martini, Stylish, Hotel, Inviting, Impeccable, Fun, Eclectic, Sleek',
        annual_dol: 5857.21,
        description:
            'Upscale, stylish nightspot at 11 Howard hotel featuring cocktails, DJs & an eclectic bar-bites menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.716,
        store_longitude: -73.9965,
        total_orders: 5317.79,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Luxurious, Hotel, Upscale, Stylish, Spacious, Vibrant, Urban, Rooftop, Lively, Sleek',
        annual_dol: 12644.6,
        description:
            'skyscraper with an observation deck.Hip rooms with original artwork and floor-to-ceiling windows feature free Wi-Fi and flat-screens, plus minibars and rainfall showers. Upgraded rooms add city views. Suites include separate living rooms with pull-out sofas. In-room dining is available.There’s a stylish rooftop bar with city views. Breakfast is offered for a fee. Other amenities include a 24/7 gym, an art gallery and a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7223,
        store_longitude: -74.0041,
        total_orders: 2204.22,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Italian, Elegant, Homemade, Perfect, Contemporary, Amazing, Casual, Great, Excellent, Fusion',
        annual_dol: 2258.1,
        description:
            'Eatery where chefs cook & serve pastas from a central kitchen, with counter seating & banquettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5791,
        store_longitude: -74.1085,
        total_orders: 19.81,
        neighborhood: 'Midland Beach',
        keywords:
            'Peruvian, Salad, Italian, Latin, Contemporary, Indian, American, Casual, Traditional, Local',
        annual_dol: 80.96,
        description:
            'Contemporary eatery offering seafood & traditional Latin American cuisine in a convivial atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.703,
        store_longitude: -74.012,
        total_orders: 360.94,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Clean, Friendly, Modern',
        annual_dol: 368.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7178,
        store_longitude: -74.0014,
        total_orders: 722.22,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Tasty, Beer, Fun, Innovative, Clean, Great, Artisanal, Unforgettable, Local, Unique',
        annual_dol: 1263.25,
        description:
            'Axe throwing sessions in a Viking-themed lounge, plus craft beers, cocktails & bar snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7221,
        store_longitude: -74.0036,
        total_orders: 1241.94,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Tequila, Wine, Whiskey, Beer, Bourbon, Hotel, Cozy, Delicious, Fancy, Classic',
        annual_dol: 1331.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7173,
        store_longitude: -74.0104,
        total_orders: 216.18,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Italian, Pizza, Salad, Upscale, Trendy, Rustic, Hotel, Bellini, Prosecco, Warm',
        annual_dol: 251.98,
        description:
            'Airy, wood-accented eatery offering classic Italian standards in warm surrounds plus patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7043,
        store_longitude: -74.0095,
        total_orders: 478.73,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Vegetarian, Pizza, Italian, Wine, Vodka, Austrian, Rustic, Mediterranean, Refined',
        annual_dol: 488.89,
        description:
            'Basement-level white-tablecloth dining with Northern Italian classics like pasta & grilled meats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6903,
        store_longitude: -73.8147,
        total_orders: 32.06,
        neighborhood: 'South Richmond Hill',
        keywords: 'Vegetarian, Wine, American, Hotel, Fun, Caribbean, Casual, Biryani, Great',
        annual_dol: 79.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7208,
        store_longitude: -73.8469,
        total_orders: 3312.06,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Beer, Mexican, Hamburger, Whiskey, Cajun, Delicious, Nachos, Warm, Legendary',
        annual_dol: 3382.34,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7456,
        store_longitude: -73.9885,
        total_orders: 1604.35,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Beer, Coffee, Trendy, Stylish, Comfortable, Lebanese, Italian, Hotel, American',
        annual_dol: 4496.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -74.0012,
        total_orders: 5907.66,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Salad, Coffee, Mexican, Beer, Pizza, Chinese, Delicious, Mediterranean, Wholesome',
        annual_dol: 6236.08,
        description:
            '"Welcome to ANA. The sunny living room of Hudson Yards perfect for taking a break from your fast-paced day. We create wholesome hand-made foods from fresh, local ingredients for you to enjoy throughout your day. Fuel up in the morning with selections from our café area including smoothies and juices made to order, fresh coffee and pastries, and daily seasonal breakfast entrées. For your afternoon escape, enjoy a slice of pizza from our brick oven, tacos, a selection of prepared salads and sandwiches, or a seasonal entree made in-house daily. ."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7665,
        store_longitude: -73.9786,
        total_orders: 4766.96,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Romantic, Hotel, American, Perfect, Yummy, Innovative, Traditional, Wonderful, Contemporary, Fine',
        annual_dol: 4973.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9875,
        total_orders: 348.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Salad, Mediterranean, Premium, Lively, Spanish, Yummy, Greek, Greek Salad, Relaxed',
        annual_dol: 416.2,
        description:
            'Relaxed wine bar featuring a Mediterranean menu served in the main room, cellar or garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.809,
        store_longitude: -73.9518,
        total_orders: 364.23,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Beer, Savory, Dessert, Legendary, Intimate, Premium, Amazing, Great, Tradition, Artisanal',
        annual_dol: 405.24,
        description:
            'Cocktail lounge with a chill vibe presenting creative concoctions in an art-filled atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.9761,
        total_orders: 55.91,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Upscale, Stylish, Modern, Great, Clean, Rooftop, Contemporary, Cultural',
        annual_dol: 228.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7171,
        store_longitude: -73.9953,
        total_orders: 1390.98,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Hotel, Rooftop, Trendy, Spectacular, Stylish, Rum, Delicious, Beer, American, Chinese',
        annual_dol: 1451.28,
        description:
            "Modern rooms have floor-to-ceiling windows, desks and free Wi-Fi, plus flat-screen TVs, minifridges and coffeemakers. Upgraded rooms and suites add living areas and city views; some have terraces. Room service is available.There's a warm Chinese eatery showing big-screen sports, a slick neon-lit ramen bar, and a breezy beer garden offering American dishes, plus a hip rooftop cocktail bar with city views. There's also a 24-hour fitness room and a business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9689,
        total_orders: 16.75,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Dessert, Beer, Donut, Japanese, Polished, Sweet, Premium, Healthy, Contemporary',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6182,
        store_longitude: -74.0304,
        total_orders: 2.81,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Salad, Mediterranean, Delicious, Homemade, Healthy, Casual, Shawerma, Local, Fresh, Middle Eastern',
        annual_dol: 11.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7419,
        store_longitude: -74.0035,
        total_orders: 7926.3,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Gluten Free, Spicy, Trendy, Asian, Chinese, Sweet, Sashimi, Premium, Thai',
        annual_dol: 8556.5,
        description:
            'Trendy nightspot serving cocktails amid a cool, Chinese-themed setting with exposed-brick walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6793,
        store_longitude: -73.9814,
        total_orders: 396.12,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Gourmet, Warm, Casual, Sumptuous, Signature',
        annual_dol: 404.53,
        description:
            'Cozy, rustic-chic lounge with a large backyard area offering signature cocktails & happy hour deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7386,
        store_longitude: -73.9935,
        total_orders: 173.81,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Italian, Casual, Perfect, Welcoming, Fine, Artisanal',
        annual_dol: 189.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7586,
        store_longitude: -73.9787,
        total_orders: 393.18,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Steak, Lobster, Lobster Roll, Burger, Dessert, Wine, French, Coffee, Spicy, Hotel',
        annual_dol: 1607.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6307,
        store_longitude: -74.1031,
        total_orders: 2653.59,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Tacos, Tequila, Steak, Mexican, Margarita, Nachos, Delicious, Fajitas, Stylish, Fun',
        annual_dol: 2709.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.984,
        total_orders: 1198.89,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegan, Burger, Cornbread, Whiskey, Champagne, Beer, Mexican, Bourbon, Cuban, Bbq',
        annual_dol: 2932.36,
        description:
            'Intimate bar offering an extensive list of cocktails that showcase classic Italian & craft bitters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7229,
        store_longitude: -73.986,
        total_orders: 3809.49,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vodka, Beer, Gin, Stylish, Ale, Instagrammable, Cozy, Spacious, Legendary, Classy',
        annual_dol: 3890.32,
        description:
            'Bi-level standby with an old-school haunt on the 1st floor & a relaxed lounge with views on the 2nd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7439,
        store_longitude: -73.9839,
        total_orders: 1307.69,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Champagne, Wine, Hotel, Coffee, Stylish, Salad, French Toast, Delicious, French, Funky',
        annual_dol: 1423.1,
        description:
            'The polished rooms come with floor-to-ceiling windows and flat-screen TVs, as well as laptop safes, minibars and Wi-Fi. Upgraded suites add furnished wraparound balconies, 4-poster beds, chandeliers and/or free-standing whirlpool tubs.Amenities include a chic rooftop restaurant and bar, plus a rooftop pool area with a cocktail lounge and daybeds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9858,
        total_orders: 653.49,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Martini, Quaint, Fun, Friendly, Legendary, Cool, Authentic, Aesthetic, Vivid',
        annual_dol: 711.16,
        description:
            'NYC offshoot of a longtime Las Vegas punk bar, best known for its bacon martinis.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6665,
        total_orders: 46.47,
        neighborhood: '',
        keywords:
            'Sushi, Vegetarian, Mexican, Wine, Japanese, Delicious, Trendy, Ramen, Healthy, Asian',
        annual_dol: 189.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7415,
        store_longitude: -73.981,
        total_orders: 11983.98,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Salad, Beer, Savory, Delicious, Trendy, Rustic, Spacious, American, Modern',
        annual_dol: 12503.57,
        description:
            'Trendy tavern with rustic-chic decor, comfort food, draft beer & classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7486,
        store_longitude: -73.9826,
        total_orders: 414.54,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Whiskey, Breakfast Burrito, Chinese, Luxurious, Spacious, Sleek, Modern, Western, Fun',
        annual_dol: 1694.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7408,
        store_longitude: -74.0047,
        total_orders: 159.48,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Lobster, Sushi, Pizza, Hamburger, Meatball, Tequila, Margarita, Thai, Cozy',
        annual_dol: 651.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -73.914,
        total_orders: 566.59,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords:
            'Burger, Steak, Salad, Wine, Nachos, Irish, French Onion Soup, French, American, ',
        annual_dol: 579.97,
        description:
            "Neighborhood corner bar with Irish leanings, including shepherd's pie & a hearty comfort-food menu.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8213,
        store_longitude: -73.9307,
        total_orders: 3459.2,
        neighborhood: 'West Bronx',
        keywords:
            'Steak, Salad, Wine, Vodka, Italian, Delicious, Shrimp Scampi, Meatball, Cozy, Comfortable',
        annual_dol: 3549.52,
        description:
            'Vibrant restaurant/bar featuring seafood & steak along with frequent live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7731,
        store_longitude: -73.9519,
        total_orders: 424.53,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Upscale, Coffee, Delicious, Salad, Warm, Baked Potato, Healthy, Spectacular, Superb, French',
        annual_dol: 485.22,
        description:
            'Serves traditional favorites like omelets, pancakes, sandwiches & salads. Delivery available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8305,
        store_longitude: -73.8493,
        total_orders: 605.45,
        neighborhood: 'East Bronx/Unionport',
        keywords: 'Beer, American, Fun, Contemporary, Great, Local',
        annual_dol: 631.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7698,
        store_longitude: -73.9543,
        total_orders: 2180.06,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Burger, Wine, Salad, Rustic, American, Comfortable, Smooth, Friendly, Casual',
        annual_dol: 2227.67,
        description:
            'This casual joint specializes in a rotating list of American draft microbrews plus hearty pub fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7653,
        store_longitude: -73.9607,
        total_orders: 117.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Italian, Delicious, Romantic, Salad, Cozy, Premium, Awesome, Friendly, Great',
        annual_dol: 153.62,
        description:
            'Local joint offering wine, Italian small plates & meal-sized salads in a warm, laid-back atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9582,
        total_orders: 75.22,
        neighborhood: 'Greenpoint',
        keywords: 'Salad, Pizza, Vegetarian, Wine, Beer, Vietnamese, Bbq, Peruvian, Spicy, Stylish',
        annual_dol: 78.48,
        description:
            'Contemporary Vietnamese cuisine served in a hip, minimalist space with lots of greenery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.646,
        store_longitude: -73.9579,
        total_orders: 1597.69,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Magical, Upscale, Opulent, Legendary, Exquisite, Memorable',
        annual_dol: 2303.14,
        description:
            "The Kings Theatre, formerly Loew's Kings Theatre, is a live performance venue in the Flatbush neighborhood of Brooklyn, New York City. Opened by Loew's Theatres as a movie palace in 1929 and closed in 1977, the theater sat empty for decades until a complete renovation was initiated in 2010.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.673,
        store_longitude: -73.9829,
        total_orders: 23.73,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Burger, Gluten Free, Beer, Vodka, Salad, Delicious, Perfect, Local, Casual',
        annual_dol: 97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.845,
        total_orders: 5248.56,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Pizza, Hamburger, Cheesecake, Sushi, Salad, Dessert, Beer, Whiskey, Bbq',
        annual_dol: 5454.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.8468,
        total_orders: 709.48,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Rich, Memorable, Iconic, Fun, Classy, Tradition, Fresh, Local',
        annual_dol: 740.24,
        description:
            'The West Side Tennis Club is a private tennis club located in Forest Hills, a neighborhood in the New York City borough of Queens. The club has 38 tennis courts in all four surfaces, a junior Olympic-size swimming pool and other amenities.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6603,
        store_longitude: -73.8307,
        total_orders: 16.22,
        neighborhood: 'Old Howard Beach/Jamaica/Howard Beach',
        keywords: 'Pizza, Tasty, Beer, Irish, Great, Casual, Local',
        annual_dol: 66.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7345,
        store_longitude: -74.0001,
        total_orders: 7806.75,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Burger, Salad, Italian, Tasty, Dessert, Bbq, Spicy, French, Caesar Salad',
        annual_dol: 7993.63,
        description:
            'Cozy chophouse offering updated dishes & sustainable cuts, plus playful artwork & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8436,
        store_longitude: -73.836,
        total_orders: 347.49,
        neighborhood: 'Pelham Bay',
        keywords: 'Pizza, Garlic Bread, Meatball, Italian, Bbq, French, Local, Fresh, Casual',
        annual_dol: 362.55,
        description:
            'Counter-serve pizzeria also offering familiar salads, hot Italian subs & oven-baked pastas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8474,
        store_longitude: -73.832,
        total_orders: 24.42,
        neighborhood: 'Pelham Bay',
        keywords: 'Beer, Karaoke, Legendary, Casual, Great',
        annual_dol: 92.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -73.9104,
        total_orders: 785.53,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Burger, Tacos, Mexican, Beer, German, Coffee, Ale, Wine, American, French',
        annual_dol: 802.2,
        description:
            '"The Seneca is a neighborhood bar/restaurant specializing in scratch-made food, classic cocktails, beer and wine. We are a favorite destination of locals for dinner, late night drinks, special events and everything in between."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8548,
        store_longitude: -73.8938,
        total_orders: 324.58,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Lobster, Lobster Tail, Salad, Wine, Chicken Wings, Thai, Bbq, Spanish, Latin, Caesar Salad',
        annual_dol: 1326.79,
        description:
            'Easygoing restaurant offering classic burgers & sushi, plus chicken wings & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8552,
        store_longitude: -73.8872,
        total_orders: 49.75,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Burger, Tacos, Sushi, Mexican, Pizza, Shrimp Cocktail, Delicious, Warm, Casual, Polished',
        annual_dol: 128.21,
        description:
            'Warm Mexican option with a polished interior offering classic dishes, mariachi music & karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.967,
        total_orders: 1040.7,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Burger, Lobster, Kebab, Shrimp Cocktail, Bbq, Wine, American, French, Spicy',
        annual_dol: 1062.78,
        description:
            'American dishes by David Burke in a handsome space with a solarium & red leather booths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9924,
        total_orders: 5329.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Whiskey, Wine, Scotch, Bourbon, Ale, Rum, Cozy, Tex-Mex, Intimate, Irish',
        annual_dol: 13236.31,
        description:
            'Tiny, dimly lit watering hole boasting an extensive selection of whiskeys & bottled beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8245,
        store_longitude: -73.8591,
        total_orders: 308.2,
        neighborhood: 'East Bronx/Soundview',
        keywords: 'Burger, Sushi, Pizza, Southern, American, Friendly, Healthy, Local, Teriyaki',
        annual_dol: 1360.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7439,
        store_longitude: -73.996,
        total_orders: 3069.87,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tacos, Steak, Cajun, Beer, Salad, Cuban, Bbq, Puerto Rican, Savory, Latin',
        annual_dol: 5437.83,
        description:
            'Modern Latin fare including Sunday brunch, served with signature cocktails in funky quarters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7355,
        store_longitude: -73.998,
        total_orders: 1082.66,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Beer, Delicious, Cozy, Iraqi, Mediterranean, Israeli, Shawarma, Syrian, Contemporary',
        annual_dol: 1359.71,
        description:
            'Middle Eastern specialties (like kibbeh, kubeh and meze) are served in a chic, contemporary setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5416,
        store_longitude: -74.1772,
        total_orders: 122.88,
        neighborhood: 'Arden Heights',
        keywords: 'Cozy, Yummy, Fun, Clean, Awesome, Friendly, Outstanding, Great, Casual',
        annual_dol: 524.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7364,
        store_longitude: -73.9973,
        total_orders: 350.21,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Margarita, Burritos, Local, Warm, Casual, Premium',
        annual_dol: 1207.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6866,
        store_longitude: -73.9747,
        total_orders: 207.56,
        neighborhood: 'Fort Greene',
        keywords: 'German, Pizza, Italian, Salad, Beer, Coffee, Wine, Organic, French, Trendy',
        annual_dol: 424.22,
        description:
            'Trendy indoor beer garden serving a menu of German food & beers at wooden tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.9551,
        total_orders: 671.42,
        neighborhood: 'Williamsburg',
        keywords:
            'Peruvian, Mexican, Sushi, Wine, Coffee, Vegan, Dessert, Upscale, Beer, Uruguayan',
        annual_dol: 2744.54,
        description:
            '"Republic Latin Fusion is a restaurant and bar based in Williamsburg, Brooklyn. Founded in 2021 by friends and co-workers who bonded over the love for hospitality. The flagship location in Williamsburg, Brooklyn serves dishes inspired from the culinary diversity of Latin America and fuses it with unique twists and flavors from Asia. Live music, elevated experiences, and a lively yet intimate atmosphere, Republic Latin Fusion takes your dining experience to the next level. Our menu has multiple options for vegan, vegetarian, and gluten-free dishes."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7249,
        store_longitude: -73.983,
        total_orders: 1394.66,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Pizza, Italian, Beer, Wine, Hotel, Stylish, Prime Rib, Polish, Fabulous, Great',
        annual_dol: 1424.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9557,
        total_orders: 16.94,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Tacos, Mexican, Beer, Margarita, Steak, Wine, Nachos, Quesadillas, Enchiladas',
        annual_dol: 167.92,
        description:
            'Longtime counter-serve joint with a large menu of Mexican classics, plus wholesale tortillas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -73.9879,
        total_orders: 166.35,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Steak, Wine, Coffee, French Toast, Beer, Mediterranean, French, Tunisian, Moroccan',
        annual_dol: 481,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -73.9794,
        total_orders: 2663.45,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Cheesesteak, Wine, Beer, Margarita, Nachos, Philly Cheesesteak, Rustic, French, American',
        annual_dol: 2663.45,
        description:
            'Reclaimed wood & weathered brick set the scene for hand-drawn cask ales, craft brews & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7138,
        store_longitude: -73.9619,
        total_orders: 2365.82,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Whiskey, Vodka, Beer, Margarita, American, Clean, Fresh, Fantastic, Great, Cool',
        annual_dol: 3627.75,
        description:
            '"A neighborhood sports bar featuring fresh cocktails along with excellent beer and whiskey selection"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.9583,
        total_orders: 2008.41,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Lobster, Lobster Roll, Steak, Salad, Tasty, Donut, Beer, Delicious, Bbq',
        annual_dol: 2051.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7208,
        store_longitude: -73.9577,
        total_orders: 388.19,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Turkish, Spanish, Savory, Russian, Modern, Casual, Local, Natural, Hip',
        annual_dol: 614.18,
        description:
            '"Bathhouse combines the vibrant, social elements of bathhouses from around the world alongside an array of treatments for the modern athlete and lifestyle enthusiast. We provide elite level treatments for those who want to look, feel and perform their very best."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.6231,
        total_orders: 961.97,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Relaxed, Contemporary, American, Casual, Authentic, Perfect, Fresh, Excellent',
        annual_dol: 1013.57,
        description:
            '"GianCarlo DiMaggio and Danny Baez are the driving force behind the storied Cassariano Italian Eatery Mineola. The freshest ingredients with the utmost service in mind are the benchmarks for the finest, award winning Italian cuisine. Driven by their strong passion for food and a deep love for their profession, these partners prepare authentic contemporary Italian cuisine while cultivating a casual, relaxed atmosphere for their patrons. Cassariano is the perfect blend of Bistro flavors and fine cuisine. We look forward to exceeding your expectations!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -74.0059,
        total_orders: 6041.32,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Hotel, Coffee, Meatball, Salad, Trendy, American, Rooftop, Iconic, Contemporary, Hip',
        annual_dol: 6231.88,
        description:
            "from the elevated High Line park.Sophisticated rooms come with free Wi-Fi and flat-screen TVs, plus high-thread-count sheets and minibars. Upgraded rooms add sitting areas and/or Juliet balconies with river or city views. Suites feature separate living rooms, and posh bi-level suites include dining areas and fireplaces.There's a trendy American restaurant, a hip lobby bar, and a rooftop terrace with a lounge and an outdoor pool, as well as a spa and a gym.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7333,
        store_longitude: -74.0023,
        total_orders: 3551.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Margarita, Legendary, Spanish, Classic, Fun, Relaxed, Great, Excellent, Casual',
        annual_dol: 3957.35,
        description:
            'Classic gay hangout offers piano bar upstairs & a basement level for dancing, music & mingling.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7318,
        store_longitude: -74.0016,
        total_orders: 1157.5,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Sushi, Asian, Chinese, Thai, Australian, Superb, Awesome, Fusion, Excellent',
        annual_dol: 1720.11,
        description: '"South East Asian small plates, good for sharing/groups"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.6345,
        total_orders: 236.34,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Beer, Margarita, Nachos, Salad, Enchiladas, Hotel, Vibrant, Modern',
        annual_dol: 302.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -74.0035,
        total_orders: 217.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Korean, Sushi, Cozy, Casual, Modern, Hotel, Local, Hip, Great',
        annual_dol: 954.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -74.0049,
        total_orders: 7.56,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Crab Cakes, Pizza, Coffee, Creamy, Yummy, Sweet, Australian, Fresh, American, Seasonal',
        annual_dol: 30.92,
        description:
            '"Tribeca’s new favorite eatery, twiggy to go offers coffee, crullers, pastries, an assortment of main courses & seasonal sides for carryout. Some of our specialties include roasted chicken, quiche, tomato tart, crab cakes & fried chicken. Ask about our catering today!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 198.29,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Steak, Burritos, Delicious, Local, Casual, Classic, Distinctive, Fine',
        annual_dol: 374.49,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7025,
        store_longitude: -73.6433,
        total_orders: 8.69,
        neighborhood: '',
        keywords: 'Karaoke, Warm, Beer, Fun, Phenomenal, Great, Casual',
        annual_dol: 35.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6955,
        store_longitude: -73.932,
        total_orders: 216.23,
        neighborhood: 'Bushwick',
        keywords:
            'Vegan, Wine, Beer, Wholesome, Vibrant, American, Classic, Fantastic, Great, Amazing',
        annual_dol: 883.88,
        description:
            '"Ornithology Jazz Club is a no-cover Jazz listening room with excellent cocktails with wholesome vegan bites with sustainability at heart. World-class live jazz with exciting events such as gallery pop-up, vegan chef pop-up, spoken word open mic and stand-up comedy"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8452,
        store_longitude: -73.9024,
        total_orders: 144.27,
        neighborhood: 'Tremont/Mount Hope/West Bronx',
        keywords: 'Lobster, Lasagna, Lobster Tail, Salad, Vegan, Cajun, Beer, Wine, Vodka, Latin',
        annual_dol: 157.01,
        description:
            'Lively destination for fish, seafood & meat dishes with a Latin twist, plus cocktails & hookah.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.808,
        store_longitude: -73.7356,
        total_orders: 6311.84,
        neighborhood: '',
        keywords:
            'Burger, Spicy, Iranian, Coffee, Delicious, Spacious, Premium, Mediterranean, Great, Local',
        annual_dol: 8592.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7539,
        store_longitude: -73.9894,
        total_orders: 872.58,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Moroccan, Vegetarian, Salad, Mediterranean, Warm, African, Classic, Hip, Casual, Authentic',
        annual_dol: 3566.83,
        description:
            'Warm eatery, covered in colorful textiles, featuring Moroccan cuisine & belly dancing some nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -74.0046,
        total_orders: 5251.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Whiskey Sour, Whiskey, Beer, Wine, Vodka, Ale, Salad, Cozy, Warm, American',
        annual_dol: 5294.31,
        description: 'This pub salutes Brooklyn with local beers & spirits plus creative pub food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7299,
        store_longitude: -74.0044,
        total_orders: 388.77,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Tacos, Mexican, Tequila, Gluten Free, Nachos, Wine, Tasty, Warm',
        annual_dol: 1254.52,
        description:
            'Contemporary Mexican spot with a colorful, all-vegan menu, plus tequila & mezcal cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7304,
        store_longitude: -74.0066,
        total_orders: 519.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Spicy, Zesty, Creamy, Cozy, Rich, Playful, Seasonal, Contemporary, Casual',
        annual_dol: 553.61,
        description:
            'A seasonal, clam-focused seafood menu presented in a cozy, brick-walled space with large windows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7906,
        store_longitude: -73.9744,
        total_orders: 4963.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Salad, Vegetarian, Bbq, Italian, Coffee, Nachos, Beer, Fried Chicken, Wine',
        annual_dol: 13488.05,
        description:
            'Long-standing BBQ operation turning out classic meat dishes, plus sides & craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7847,
        store_longitude: -73.9774,
        total_orders: 1175.29,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Whiskey, Gin, Ale, Burger, Margarita, Pizza, Belgian, Nachos, American',
        annual_dol: 1249.63,
        description:
            'Fans (especially Florida Gators) cheer their teams on TV at this tavern with pub food & beer pong.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7841,
        store_longitude: -73.9774,
        total_orders: 243.99,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Sushi, Salad, Wine, Spicy, Japanese, Trendy, Stylish, Sashimi, Gin, Asian',
        annual_dol: 249.17,
        description:
            'This stylish sushi specialist serves traditional & inventive Japanese fare amid eye-catching decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7328,
        store_longitude: -73.6831,
        total_orders: 1135.87,
        neighborhood: '',
        keywords:
            'Steak, Salad, Coffee, Italian, Beer, American, Delicious, Caesar Salad, Modern, Homemade',
        annual_dol: 1159.97,
        description:
            'Eclectic restaurant serving French, American & Italian dishes, with live music on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6435,
        store_longitude: -73.5396,
        total_orders: 335.2,
        neighborhood: '',
        keywords:
            'Vegetarian, Pizza, Italian, Wine, Shrimp Cocktail, Delicious, Ale, Relaxed, Friendly, Fun',
        annual_dol: 609.16,
        description:
            'Relaxed, enduring establishment offering family-style Italian meals, seafood & scenic bay views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9196,
        total_orders: 1075.02,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Mexican, Cuban, Spanish, Quesadillas, Fabulous, American, Amazing, Incredible, Great',
        annual_dol: 2948.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7133,
        store_longitude: -73.6055,
        total_orders: 38.11,
        neighborhood: '',
        keywords: 'Steak, Tacos, Casual',
        annual_dol: 155.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.9824,
        total_orders: 7402.91,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Gourmet, Gluten Free, Legendary, Spicy, Irish, American, French',
        annual_dol: 7612.48,
        description:
            'Landmark restaurant serving steak & seafood dishes in a formal setting since 1973.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9063,
        store_longitude: -73.9041,
        total_orders: 263.55,
        neighborhood: 'North Riverdale',
        keywords:
            'Crab Cakes, Burger, Pizza, Shrimp Scampi, Italian, Spicy, Mediterranean, Ale, Thai, American',
        annual_dol: 291.17,
        description:
            'Italian staple delivering familiar fare in comfortable environs with a neighborhood feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8944,
        store_longitude: -73.8967,
        total_orders: 78,
        neighborhood: 'Fieldston',
        keywords: 'Burger, Steak, Salad, Meatball, Beer, French, Irish, American, Latin, Casual',
        annual_dol: 83.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -73.9875,
        total_orders: 203.22,
        neighborhood: 'Lower East Side',
        keywords:
            'Flavorful, Tasty, Chicken Wings, Bbq, Lebanese, Premium, Cozy, Swiss, Sophisticated, Complex',
        annual_dol: 451.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6573,
        store_longitude: -73.9505,
        total_orders: 168.13,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Lobster, Wine, Delicious, Beer, Cozy, Sweet, Vietnamese, Fun, Caribbean, Vibrant',
        annual_dol: 687.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7404,
        store_longitude: -73.9922,
        total_orders: 5572.37,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Burger, Sushi, Steak, Salad, Japanese, Coffee, Beer, Wine, Organic, Delicious',
        annual_dol: 5754.7,
        description:
            'Elegant bistro by day offering refined & modern Japanese plates, cocktail & wine bar by night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5888,
        store_longitude: -73.8173,
        total_orders: 184.53,
        neighborhood: 'Far Rockaway/Rockaway Beach',
        keywords:
            'Vegetarian, Vegan, Salad, Flavorful, Thai, Dessert, Upscale, Delicious, Beer, Asian',
        annual_dol: 240.74,
        description:
            'Upscale Thai restaurant with a lounge offers scenic views, live music & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.9999,
        total_orders: 78.58,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegan, Vegetarian, Burger, Cheesesteak, Sushi, American, Philly Cheesesteak, Natural, Crisp, Contemporary',
        annual_dol: 321.19,
        description:
            'Easygoing bistro serving plant-based homestyle cooking amid compact surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6727,
        store_longitude: -73.9626,
        total_orders: 6035.2,
        neighborhood: 'Crown Heights',
        keywords:
            'Wine, Beer, Delicious, Martini, Premium, American, Awesome, Sweet, Amazing, Retro',
        annual_dol: 6261.49,
        description:
            'Craft cocktails & elevated bar bites are the focus at this hip, intimate hangout with a retro vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.616,
        store_longitude: -74.1387,
        total_orders: 917.92,
        neighborhood: 'Westerleigh/Mid Island',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, American, Delicious, Turkish, Homemade, Awesome, Great',
        annual_dol: 957.68,
        description:
            "Informal Italian eatery featuring familiar fare & pies, plus a hand-on kids' pizza-making class.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.9838,
        total_orders: 814.77,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Scotch, Bourbon, Beer, Upscale, Delicious, Premium, Authentic, Perfect, Intimate',
        annual_dol: 2512.19,
        description:
            'Petite St. Marks Place wine bar offering a variety of vinos paired with small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7119,
        store_longitude: -73.9999,
        total_orders: 64.41,
        neighborhood: 'Civic Center',
        keywords:
            'Vegan, Salad, Roast Turkey, Delicious, Swiss, English Muffin, Healthy, Perfect, Great, Fresh',
        annual_dol: 76.94,
        description:
            '"With over 25 years of experience in catering in New York, Deborah Miller Catering & Events helps you celebrate corporate and social events with delicious food made from fresh local ingredients. We take pride in providing NYC catering services from our state of the art kitchen, with hand-crafted cooking and exceptional service. We specialize in bar service catering, healthy catering including vegan and gluten-free menus, and lunch catering for your next corporate event. Contact us today for catering social events, weddings or corporate catering in NYC!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.9831,
        total_orders: 814.38,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Upscale, Perfect, Lively, Authentic, Awesome, Vintage, Great, Cool, Colorful, Casual',
        annual_dol: 3478.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6029,
        store_longitude: -74.1744,
        total_orders: 207.89,
        neighborhood: 'Bloomfield/Mid Island',
        keywords: 'American, Rustic, Fine',
        annual_dol: 254.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7148,
        store_longitude: -73.9616,
        total_orders: 7975.33,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Stylish, Delicious, Beer, Fresh, Bright, Perfect, Rum, Great, Fantastic',
        annual_dol: 9951.29,
        description: 'Attractive space for carefully-prepared craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7402,
        store_longitude: -73.975,
        total_orders: 5278.41,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Wine, Upscale, Beer, Rich, Californian, Tranquil, Elegant, American, Romantic',
        annual_dol: 5920.74,
        description:
            'Farm-to-table American cuisine served in an architecturally rich dining room, patio & bar area.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7576,
        store_longitude: -73.9287,
        total_orders: 69.84,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Beer, Wine, Ale, American, Traditional, Sweet, Local, Casual, Great',
        annual_dol: 136.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7372,
        store_longitude: -73.9882,
        total_orders: 3128.53,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Steak, Salad, Wine, French, Beer, Korean, American, Delicious, Caesar Salad, Traditional',
        annual_dol: 3337.71,
        description:
            "Bistro for dry-aged steaks & other French fare, with a bar that's strong on absinthe.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -73.9874,
        total_orders: 819.02,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Tasty, Salad, Korean, Italian, Seasonal, Contemporary, American, Spanish, Mediterranean, Robust',
        annual_dol: 1383.89,
        description:
            'Fashionable restaurant dishing up comfort food & cocktails with a contemporary twist.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6392,
        store_longitude: -73.9685,
        total_orders: 4266.21,
        neighborhood: 'Flatbush',
        keywords: 'Wine, Beer, Ale, Upscale, Warm, Vintage, Fun, Great, Seasonal, Casual',
        annual_dol: 5104.4,
        description:
            'Chill hideaway with a courtyard showcasing vintage guitars & live music, plus craft beers on tap.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8007,
        store_longitude: -73.9658,
        total_orders: 1847.83,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Steak, Burger, Whiskey, Beer, Mexican, Wine, Delicious, Premium, Friendly, Clean',
        annual_dol: 1865.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6466,
        store_longitude: -73.9707,
        total_orders: 1795.58,
        neighborhood: '',
        keywords: 'Pizza, Whiskey, Beer, Wine, Italian, Coffee, Delicious, Innovative, Hip, Bright',
        annual_dol: 2144.63,
        description:
            'Craft cocktails & Italian-style pizzas in innovative combos, in a hip, ornately wallpapered space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7691,
        store_longitude: -73.7741,
        total_orders: 941.39,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Pizza, Italian, Chinese, American, Warm, Friendly, Fresh, Casual, Awesome',
        annual_dol: 1213.96,
        description:
            'Intimate restaurant featuring wood-fired pizzas & traditional Italian cuisine in a warm atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9776,
        total_orders: 242.05,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Vegan, Korean, Wine, Classy, Friendly, Traditional, Iconic, Casual, Great, Complex',
        annual_dol: 989.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5837,
        store_longitude: -73.9383,
        total_orders: 106.59,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Vodka, Great',
        annual_dol: 108.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.7718,
        total_orders: 2070.74,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Wine, Spanish, Upscale, American, Delicious',
        annual_dol: 2117.65,
        description:
            'Traditional chophouse serving large steaks, classic sides & martinis in a clubby atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.8105,
        total_orders: 559.76,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Burger, Pizza, Korean, Spicy, Bbq, Asian, American, Casual, Friendly, Premium',
        annual_dol: 610.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.7715,
        total_orders: 947.15,
        neighborhood: 'Bayside',
        keywords: 'Beer, Delicious, Lively, Friendly, Bbq, Great, Fresh, Local, Casual',
        annual_dol: 3672.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9783,
        total_orders: 444.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Salad, Dessert, Tasty, Beer, Hotel, Trendy, Classy, Traditional, Classic',
        annual_dol: 1561.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7782,
        store_longitude: -73.7783,
        total_orders: 0,
        neighborhood: 'Bayside/Flushing/Bay Terrace',
        keywords:
            'Cajun, Wine, Cajun-Style, Beer, Martini, Juicy, French, Fun, Friendly, Authentic',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -74.0094,
        total_orders: 260.52,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Garlic Bread, Hamburger, Shrimp Cocktail, Beer, Wine, Juicy, Warm, American, Rum',
        annual_dol: 411.31,
        description:
            'Pub with a large selection of whiskeys & craft beers, plus a big menu that includes weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7133,
        store_longitude: -74.0106,
        total_orders: 2046.06,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Sushi, Beer, Japanese, Upscale, Hotel, Rich, Seasonal, Legendary, Classy',
        annual_dol: 8960.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.9657,
        total_orders: 224,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Gluten Free, Organic, Dessert, Mediterranean, Upscale, Greek, Healthy, Polished',
        annual_dol: 846.09,
        description:
            'Airy, upscale taverna serving a seafood-heavy Mediterranean menu, including a fixed-price lunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7103,
        store_longitude: -74.0138,
        total_orders: 937.4,
        neighborhood: 'World Trade Center/Financial District',
        keywords:
            'Steak, Lobster, Chocolate Cake, Cheesecake, Shrimp Cocktail, Chocolate Mousse, Salad, Mexican, Wine, Upscale',
        annual_dol: 957.29,
        description:
            'Upscale chain for aged prime beef, seafood & other traditional steakhouse fare in a clubby space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.743,
        store_longitude: -73.9905,
        total_orders: 3705.86,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Salad, Dessert, Elegant, Modern, Contemporary, Sleek, Indian, Seasonal, Comfortable',
        annual_dol: 3880.05,
        description:
            'Sophisticated Indian cuisine & a notable wine list offered in an elegant space with ornate accents.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.9886,
        total_orders: 5017.72,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Mexican, Tequila, Dessert, Wine, Trendy, Spicy, Delicious, Classy, Innovative, Contemporary',
        annual_dol: 5235.27,
        description:
            'Sleek Mexican spot with a big bar for locally sourced share plates, plus tequila & mezcal cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6982,
        store_longitude: -73.9952,
        total_orders: 40.78,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Luxurious, Upscale, Coffee, Elegant, Dessert, Hotel, Spectacular, Robust, High-End, Fresh',
        annual_dol: 95.07,
        description:
            '"The Watermark at Brooklyn Heights is located in the historic and beautifully renovated Leverich Towers Hotel. Independent Living, Assisted Living and Memory Care accommodations combine with award winning programming for a senior living community second to none. Members enjoy multiple dining venues, robust arts and cultural spaces, integrative health and wellbeing programs, and elegant residences with upscale design touches. Overlooking the Brooklyn Heights Promenade and connected to Manhattan by subways and ferries, The Watermark at Brooklyn Heights is your gateway to extraordinary senior living in the heart of it all. Come visit today to learn about becoming a member."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9913,
        total_orders: 356.46,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Hamburger, Steak, Vegetarian, Vegan, Gourmet, Beer, Tasty, Juicy, Wine',
        annual_dol: 364.03,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9847,
        total_orders: 1204.19,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Upscale, American, Classy, Comfortable, Delectable, Friendly',
        annual_dol: 4922.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7733,
        store_longitude: -73.9838,
        total_orders: 548.72,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Italian, Wine, Upscale, Roast Beef, Elegant, Polished, Perfect, Amazing, Iconic',
        annual_dol: 606.05,
        description:
            'Glass-walled dining room at Lincoln Center preparing upscale Italian fare in an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7759,
        store_longitude: -73.9818,
        total_orders: 45.21,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Vegetarian, Salad, Pizza, Wine, Coffee, Italian, Dessert, Organic, Delicious, Mediterranean',
        annual_dol: 184.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7459,
        store_longitude: -73.9571,
        total_orders: 16724.78,
        neighborhood: 'LIC/Hunters Point',
        keywords:
            'Sushi, Burger, Salad, Japanese, Chinese, Asian, Upscale, Vietnamese, Trendy, Thai',
        annual_dol: 17112.13,
        description:
            'Upscale Pan-Asian eatery in a high-rise restaurant with Manhattan skyline views and modern look.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 8428.64,
        neighborhood: '',
        keywords: 'Cheesecake, Salad, Tacos, Dessert, Wine, Italian, Thai, Cuban, Pot Roast, Warm',
        annual_dol: 8972.87,
        description:
            'Upmarket chain offering a wide-ranging American menu in sophisticated surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7411,
        store_longitude: -73.6405,
        total_orders: 22.23,
        neighborhood: '',
        keywords: 'Beer, Japanese, Local, American, Casual',
        annual_dol: 57.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -74.0028,
        total_orders: 2722.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Salad, Wine, Italian, Trendy, Delicious, French, Romantic, Warm, Sustainable',
        annual_dol: 2967.46,
        description:
            'Low-lit place with outdoor terrace & downstairs bar serving French classics and sustainable wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9919,
        total_orders: 1032.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Tequila, Mexican, Steak, Wine, Margarita, Coffee, Quesadillas, Spectacular, Exotic, Fresh',
        annual_dol: 1054.25,
        description:
            'Eatery offering Mexican classics, many tequilas, margaritas & beach decor near Penn Station.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7832,
        store_longitude: -73.9787,
        total_orders: 50.4,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tequila, Vodka, Whiskey, Hamburger, Beer, Salad, Margarita, Scotch, Dessert, Gin',
        annual_dol: 211.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7408,
        store_longitude: -73.6406,
        total_orders: 12.59,
        neighborhood: '',
        keywords: 'Burger, Salad, Wine, Beer, Trendy, Ale, Warm, Irish, Cozy, Healthy',
        annual_dol: 51.47,
        description:
            'Laid-back ale house offering 16 beers on tap, Irish grub & other pub fare, plus DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7053,
        store_longitude: -73.656,
        total_orders: 2.65,
        neighborhood: '',
        keywords:
            'Pizza, Beer, Delicious, American, French, Homemade, Fabulous, Classic, Cool, Great',
        annual_dol: 10.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6672,
        store_longitude: -73.9882,
        total_orders: 1010.43,
        neighborhood: 'Park Slope',
        keywords: 'Warm, Cozy, Friendly, Fun, Fresh, Eclectic, Great, Casual, Excellent, Modern',
        annual_dol: 1208.75,
        description:
            '"An eclectic and modern neighborhood bar on Park Slope\'s famed 5th Avenue serving a rotation of craft beers and wines, while hosting a variety of events and parties."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6687,
        store_longitude: -73.9904,
        total_orders: 91.1,
        neighborhood: 'Gowanus',
        keywords:
            'Mexican, Delicious, Awesome, Fresh, Contemporary, Fantastic, Great, Casual, Local, Michelada',
        annual_dol: 372.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6396,
        store_longitude: -73.952,
        total_orders: 48.53,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Pizza, Stylish, Caribbean, Exotic, Great, Casual',
        annual_dol: 198.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.774,
        store_longitude: -73.964,
        total_orders: 118.48,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Shrimp Cocktail, Vegetarian, Hotdog, Turkey Sandwich, Dessert, Coffee, French Onion Soup, French, Caesar Wrap',
        annual_dol: 386.81,
        description:
            'Omelets, burgers, Greek specialties & other diner standards are served at this all-day coffee shop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7051,
        store_longitude: -73.9201,
        total_orders: 2436.68,
        neighborhood: 'Bushwick',
        keywords: 'Hotdog, Beer, Warm, Wine, Cozy, Spacious, Fun, Awesome, Great, Quirky',
        annual_dol: 2518.43,
        description:
            'Laid-back bar with a sizable outdoor area, draft beers & quirky touches like personal USB ports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7321,
        store_longitude: -74.0051,
        total_orders: 642.53,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Lobster, Delicious, Upscale, Beer, Legendary, Gin, Premium, Sweet, Casual',
        annual_dol: 2538.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6556,
        store_longitude: -73.677,
        total_orders: 2942.12,
        neighborhood: '',
        keywords:
            'Steak, Sushi, Japanese, Stylish, Sashimi, Perfect, Traditional, Asian, Friendly, Casual',
        annual_dol: 3029.14,
        description:
            'Stylish Japanese restaurant & lounge with hibachi chefs cooking at tables & a dining room for sushi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.6361,
        total_orders: 569.68,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Italian, Vodka, Gourmet, Delicious, Trendy, Elegant, Yummy, Mediterranean',
        annual_dol: 581.77,
        description: 'Classic Italian fare in an elegant setting that includes sidewalk tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6579,
        store_longitude: -73.6649,
        total_orders: 50.95,
        neighborhood: '',
        keywords:
            'Chicken Parmigiana, Flavorful, Beer, Dessert, Meatball, Martini, Italian, French, Delicious, Creamy',
        annual_dol: 67.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7299,
        store_longitude: -74.0047,
        total_orders: 19.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Italian, Dessert, Upscale, Hotel, Homemade, Homey, Caesar Salad, Greek, Casual',
        annual_dol: 78.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -74.004,
        total_orders: 392.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Ale, French, Chinese, Delicious, Fancy, Exotic, Classic, Perfect, High-End',
        annual_dol: 507.44,
        description:
            'Les Trois Chevaux is a fine dining restaurant in New York City. Owned and run by Angie Mar, it opened in July 2021 and serves French cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -74.0044,
        total_orders: 90.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Burger, Tasty, Salad, Italian, Beer, Dessert, Wine, Cozy, Relaxed',
        annual_dol: 93.91,
        description:
            'Pizza parlor turning out thin-crust pies with creative toppings in a small, relaxed space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -73.9971,
        total_orders: 51.61,
        neighborhood: 'Little Italy',
        keywords:
            'Burger, Pizza, Salad, Vegan, Italian, Gluten Free, Dessert, Wine, Beer, Bruschetta',
        annual_dol: 210.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.668,
        store_longitude: -73.5541,
        total_orders: 8.58,
        neighborhood: '',
        keywords: 'Mexican, Margarita, Salad, Nachos, Local, Exotic, Friendly, Great',
        annual_dol: 35.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9203,
        total_orders: 3173.24,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Beer, Wine, Bbq, Nachos, Delicious, Casual, Smoky, American, Local',
        annual_dol: 3343.12,
        description:
            'This low-key gastropub with old-timey touches offers rotating taps & a menu of elevated bar food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6742,
        store_longitude: -73.9823,
        total_orders: 130.43,
        neighborhood: 'Park Slope',
        keywords:
            'Tequila, Tacos, Mexican, Spicy, Burritos, Delicious, Warm, Fajitas, American, Fun',
        annual_dol: 213.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.8874,
        total_orders: 63.62,
        neighborhood: 'Jackson Heights/Elmhurst',
        keywords: 'Tasty, Salad, Mexican, Vodka, Karaoke, Fun, Friendly, Latin, Great, Russian',
        annual_dol: 260.07,
        description:
            '"Beautiful new gay bar in Jackson Heights, Queens - where ALL are welcome. Follow us on Instagram for event updates and our weekly party and DJ schedule. Kitchen opening for small bites Spring 2022."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9738,
        total_orders: 11.39,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Chilean, Elegant, Beer, Picturesque, Perfect, Casual, American, Great, Amazing',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.739,
        store_longitude: -73.9917,
        total_orders: 730.74,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Delicious, Golfclub, Cozy, Urban, Perfect, Fun, Clean, American, Great, Dope',
        annual_dol: 746.24,
        description:
            'Indoor golf center offering lessons, Full Swing simulators, complimentary clubs & a bar with snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.9958,
        total_orders: 1107.64,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Italian, Salad, Vodka, Spicy, Warm, Creamy, Impeccable, Romantic, Cozy',
        annual_dol: 1131.14,
        description:
            "Stalwart that's been serving Northern Italian cuisine for over 20 years in a lush, old-school space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 162.79,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Italian, Pizza, Contemporary, Spacious, Fancy, Seasonal, Casual, Creative, Excellent, Great',
        annual_dol: 223.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7421,
        store_longitude: -74.0006,
        total_orders: 3542.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tequila, Mexican, Cornbread, Beer, Dairy Free, Margarita, Nachos, Spicy, Bbq, Smoky',
        annual_dol: 3618.14,
        description:
            'Mexican BBQ menus & cocktails combine at this lively, casual spin-off of a popular food truck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.9991,
        total_orders: 63.62,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vegan, Salad, Healthful, Delicious, Margarita, Spicy, Warm, Argentinian, Healthy',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7149,
        store_longitude: -73.9618,
        total_orders: 12020.49,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Steak, Sushi, Salad, Wine, Dessert, Japanese, Spicy, Delicious, Coffee, Beer',
        annual_dol: 13061.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7253,
        store_longitude: -73.9832,
        total_orders: 86.89,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Steak, Salad, Korean, Beer, Spicy, American, Trendy, Legendary, Dope',
        annual_dol: 355.17,
        description:
            'Korean-American plates are served in an intimate setting with hip hop influences.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7301,
        store_longitude: -73.9813,
        total_orders: 1053.95,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Beer, Cozy, Relaxed, Trendy, Spacious, Casual, Local, Awesome, Creative, Excellent',
        annual_dol: 1099.64,
        description:
            'Gay & lesbian mainstay in the East Village known for its jukebox & relaxed atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6072,
        store_longitude: -74.161,
        total_orders: 51.77,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Burger, Salad, Milkshake, Coffee, Tasty, Italian, Quaint, Greek, American, Omelette',
        annual_dol: 57.59,
        description:
            'An array of Greek-American comfort foods are prepared 24/7 at this spacious, time-tested throwback.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6869,
        store_longitude: -73.9632,
        total_orders: 22.74,
        neighborhood: 'Clinton Hill',
        keywords:
            'Wine, Sustainable, Funky, Vibrant, Polished, French, Natural, Contemporary, Fresh, Excellent',
        annual_dol: 92.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7121,
        store_longitude: -73.964,
        total_orders: 436.18,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Tequila, Tacos, Burger, Margarita, Bbq, American, Hip, Negroni, Crisp',
        annual_dol: 469.33,
        description:
            'American meals, craft beers & cocktails in a playful interior with graffitied walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9252,
        total_orders: 763.37,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Karaoke, Irish, Coffee, Greek, Classic, Authentic, Great, Casual',
        annual_dol: 779.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7229,
        store_longitude: -73.9957,
        total_orders: 609.69,
        neighborhood: 'Nolita',
        keywords:
            'Garlic Bread, Salad, Pizza, Chicken Parmigiana, Italian, Gourmet, Meatball, Tasty, Wine, Spicy',
        annual_dol: 622.62,
        description:
            'Chicken parm & other Italian-American classics go gourmet at this hip, diner-inspired space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.8038,
        total_orders: 155.64,
        neighborhood: 'Flushing/East Flushing',
        keywords: 'Tasty, Irish, Casual',
        annual_dol: 158.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9752,
        total_orders: 4459.81,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Tequila, Hotel, Upscale, Shrimp Cocktail, Vodka, Legendary, Margarita, Elegant, Coffee',
        annual_dol: 4573,
        description:
            "Sophisticated, airy rooms have flat-screen TVs, Wi-Fi, pillow-top mattresses and picture windows; some offer panoramic views of the park. Suites add sitting areas, wet bars, dining rooms and/or kitchens.A facilities fee covers Wi-Fi and an evening reception with drinks and snacks, plus use of the gym and the business center. There's also a fine dining restaurant with floor-to-ceiling windows and park views, and a casual eatery, as well as a refined cocktail bar with wood paneling.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.9893,
        total_orders: 834.79,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Delicious, Upscale, Beer, Irish, Fun, Awesome, Perfect, Screwdriver',
        annual_dol: 961.14,
        description:
            'Double-decker, fireplace-equipped Irish pub with an upstairs party room, bar-food menu & happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9778,
        total_orders: 311.25,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Salad, Organic, Local, Delicious, Casual, Fresh',
        annual_dol: 1272.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7152,
        store_longitude: -73.9915,
        total_orders: 999.02,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Wine, Scotch, Salad, Spicy, Warm, Cozy, Organic, Amazing, Great, Fresh',
        annual_dol: 1265.07,
        description:
            'Cozy eatery serving oysters, snacks & drinks in vintage-inspired digs with live music & DJ nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8915,
        store_longitude: -73.8977,
        total_orders: 280.57,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Mexican, Steak, Tex-Mex, Karaoke, Fajitas, Smoky, Casual, Authentic',
        annual_dol: 411.03,
        description:
            'Festive family-owned cantina with a simple decor with foods of the Southwest & mariachi & karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.594,
        store_longitude: -73.5799,
        total_orders: 393.71,
        neighborhood: '',
        keywords:
            'Lobster Roll, Lobster, Lobster Tail, Salad, Coffee, Wine, Tasty, Ale, Caesar Salad, American',
        annual_dol: 507.71,
        description:
            'Walls of windows offer waterside views at this nautical-themed outpost serving raw & cooked seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7471,
        store_longitude: -73.8874,
        total_orders: 2.82,
        neighborhood: 'Jackson Heights/Flushing/Elmhurst',
        keywords: 'Peruvian, Salad, Dessert, Tangy, French, Japanese, Casual',
        annual_dol: 11.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8677,
        store_longitude: -73.9209,
        total_orders: 1333.9,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Burger, Hamburger, Beer, Wine, Delicious, Upscale, American, Friendly, Fun, Classic',
        annual_dol: 1399.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -73.9927,
        total_orders: 2384.03,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Delicious, Cozy, Mediterranean, Homemade, Classy, Local, Baba Ghanoush, Great, Casual',
        annual_dol: 2493.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7362,
        store_longitude: -74.0007,
        total_orders: 678.43,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Vodka, Bourbon, Gin, Romantic, Upscale, Exotic, Enticing, Traditional, Sleek',
        annual_dol: 753.23,
        description:
            'Regional Indian fare is highlighted at this upscale spot with cocktails & modern murals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -74.0032,
        total_orders: 3751.21,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Coffee, Gin, Martini, Salad, Pinacolada, Lively, Sophisticated, Chic, Classic, Artisanal',
        annual_dol: 5224.46,
        description:
            'Small plates are paired with gin-based cocktails at this speakeasy-style bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7405,
        store_longitude: -73.998,
        total_orders: 3235.28,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Cheesesteak, Salad, Tasty, Wine, Gluten Free, Coffee, Dessert, Beer',
        annual_dol: 6855.87,
        description:
            'A swanky take on a diner attracting people-watchers & post-clubbers late nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7356,
        store_longitude: -74.0004,
        total_orders: 969.62,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Delicious, Beer, Italian, Cozy, Fun, Friendly, Wonderful, Cool, Great',
        annual_dol: 995.72,
        description:
            'This second-floor bar offers video games & trivia contests plus old movies on a big screen & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5414,
        store_longitude: -74.1782,
        total_orders: 106.18,
        neighborhood: 'Woodrow',
        keywords: 'Sushi, Burger, Tacos, Salad, Pizza, Dessert, Italian, Wine, Elegant, Friendly',
        annual_dol: 234.82,
        description:
            'Elegant Italian restaurant serving wine & upmarket cuisine like veal, filet mignon & pasta entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9602,
        total_orders: 350.24,
        neighborhood: 'Williamsburg',
        keywords:
            'Sushi, Breakfast Burrito, Japanese, Bbq, Mediterranean, Ramen, Rustic, Asian, Funky, Casual',
        annual_dol: 408.25,
        description:
            'This rustic Japanese restaurant with a communal table serves udon, sushi, miso & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7102,
        store_longitude: -73.9687,
        total_orders: 1131.14,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Lobster, Steak, Salad, Coffee, Italian, Vodka, Bbq, French, Thai, American',
        annual_dol: 1911.29,
        description:
            'Relaxed waterside restaurant specializing in oysters, frozen cocktails & locally sourced pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7425,
        store_longitude: -73.9844,
        total_orders: 1325.42,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Lobster Roll, Lobster, Burger, Tomato Soup, Salad, Californian, Margarita, Wine, Beer, Fruity',
        annual_dol: 1450.91,
        description:
            'Posh chain serving American fare, including brunch, dinner & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.9846,
        total_orders: 3339.19,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Tequila, Mexican, Margarita, Upscale, Gluten Free, Delicious, Hotel, Innovative, Vibrant, Premium',
        annual_dol: 3582.07,
        description:
            'Vibrant cantina serving modern Mexican cuisine, made-to-order guacamole & tequila cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7403,
        store_longitude: -73.9736,
        total_orders: 1151.17,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Upscale, Canadian, Elegant, Delicious, Swiss, Crisp, Dessert, Hotel, American',
        annual_dol: 1234.99,
        description:
            'Upscale floating eatery serving American classics in elegant surroundings with East River views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.723,
        store_longitude: -73.9506,
        total_orders: 2973.99,
        neighborhood: 'Greenpoint',
        keywords:
            'Beer, Delicious, Cozy, Friendly, Intimate, Eclectic, Quirky, Great, Traditional, Casual',
        annual_dol: 3040.41,
        description:
            'Cozy tavern with refurbished wood decor, featuring craft brews, specialty cocktails & charcuterie.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7645,
        store_longitude: -73.9701,
        total_orders: 1172.28,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Italian, Mexican, Mediterranean, Luxurious, Brazilian, Chic, Sumptuous, Rooftop, Casual',
        annual_dol: 5072.64,
        description:
            '"Casa Cruz New York is located in a stunning beaux-arts townhouse on 61st Street, between Park and Madison Avenues. Comprised of five floors, the space is inclusive of bars and dining rooms, special event spaces, and a rooftop terrace and will accommodate breakfast, lunch, dinner, and cocktails. Casa Cruz New York will display an unwavering attention to detail and unparalleled design, offering the high-level cuisine, cocktails and hospitality for which Santa Cruz Co. restaurants and bars have become known."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7489,
        store_longitude: -73.9754,
        total_orders: 202.64,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Gluten Free, Beer, Spicy, Wine, Chili Chicken, Japanese, Thai, Asian, Ramen',
        annual_dol: 261.31,
        description:
            'Asian-inspired & Japanese chain restaurant where dishes are whisked to long communal tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7698,
        store_longitude: -73.9578,
        total_orders: 82.48,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Kebab, Dessert, Wine, Elegant, Warm, Homemade, Mediterranean, Modern, Sweet',
        annual_dol: 91.97,
        description:
            'Understated restaurant with a warm atmosphere offering a traditional Persian menu & full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7534,
        store_longitude: -73.9325,
        total_orders: 108.3,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Delicious, Cozy, Bbq, Ecuadorian, Fun, Thai, Friendly, Dutch, Amazing, Latin',
        annual_dol: 362.45,
        description:
            'Casual lounge bar, night club & restaurant offering brunch & dinner menus, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7317,
        store_longitude: -73.9899,
        total_orders: 4089.2,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Upscale, Classy, Fun, Amazing, Great, Excellent, Casual',
        annual_dol: 4089.2,
        description:
            '25 billiards tables plus Ping-Pong, darts, foosball, full bar & food near Union Square.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7312,
        store_longitude: -73.9856,
        total_orders: 746.46,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Delicious, Cozy, Tangy, Fun, Friendly, Bourbon, Irish, Bbq, Lively',
        annual_dol: 935.84,
        description:
            'Lively, Southern-flavored joint with signature cocktails, craft beers & a live band on Sundays.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -73.9914,
        total_orders: 576.85,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Pizza, Vegetarian, Vegan, Italian, Gluten Free, Wine, Mediterranean, Gin, Campari, Dope',
        annual_dol: 643.25,
        description:
            'Old-world Neapolitan pizza & Italian appetizers in a lively, high-ceilinged space with soccer on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8318,
        store_longitude: -73.8661,
        total_orders: 70.57,
        neighborhood: 'East Bronx/Soundview',
        keywords:
            'Steak, Lobster, Lobster Tail, Salad, Shrimp Cocktail, Shrimp Scampi, Karaoke, Spanish, Thai, Puerto Rican',
        annual_dol: 76.91,
        description:
            'Unfussy eatery serving Puerto Rican fare & cocktails such as coquito in a warm atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7385,
        store_longitude: -73.9896,
        total_orders: 125.02,
        neighborhood: 'Midtown Manhattan',
        keywords: 'American, Ale, Contemporary, Local, Casual',
        annual_dol: 511.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 26173.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Rich, Wine, Upscale, Luxurious, Salad, Crisp, Premium, Perfect, Classic',
        annual_dol: 26728.49,
        description:
            'Opulently outfitted chain restaurant specializing in prime steaks & seafood along with fine wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.9705,
        total_orders: 253.22,
        neighborhood: 'Midtown Manhattan/Tudor City/Midtown East',
        keywords:
            'Steak, Lasagna, Lobster, Cheesecake, Lobster Bisque, Tasty, Dessert, Chilean, Beer, French',
        annual_dol: 654.79,
        description:
            'Traditional steakhouse dishes, sides & cocktails in an elevated dining room with ample foliage.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7516,
        store_longitude: -73.9727,
        total_orders: 508.39,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Wine, Beer, Spicy, Japanese, Casual, Teriyaki, Perfect, Authentic',
        annual_dol: 638.48,
        description:
            'Comfy Japanese spot with a simple dining room, sushi bar, sake & brown spirits, plus tatami alcoves.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8257,
        store_longitude: -73.9536,
        total_orders: 8187.83,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Steak, Pizza, Beer, Margarita, Cuban, Wine, Spanish, Delicious, Puerto Rican, Dominican',
        annual_dol: 13427.23,
        description:
            'Traditional Puerto Rican fare artfully presented in a lively, modern setting with happy hour & DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9709,
        total_orders: 2705.05,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Salad, Sushi, Wine, Spicy, Delicious, Beer, Shrimp Scampi, Elegant',
        annual_dol: 2799.8,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9857,
        total_orders: 920.69,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Salad, Vegan, Japanese, Spicy, Ramen, Vintage, Rum, Southern, Casual, Adventurous',
        annual_dol: 3763.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7539,
        store_longitude: -73.9999,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Delicious, Dessert, Healthy, Fresh, Casual, Classic, Local, Rooftop, Signature',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6262,
        store_longitude: -74.1763,
        total_orders: 21527.65,
        neighborhood: '',
        keywords:
            'Whiskey, Salad, Bourbon, Burger, Beer, Meatball, French Onion Soup, Fried Chicken, French, Mojito',
        annual_dol: 88117.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.744,
        store_longitude: -73.9892,
        total_orders: 3122.59,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Italian, Stylish, Coffee, Delicious, Hotel, Relaxed, Bright, Vibrant, Modern',
        annual_dol: 3188.85,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9877,
        total_orders: 30032.95,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'American, Hotel, Fabulous, Perfect, Great',
        annual_dol: 30701.5,
        description:
            'The John Golden Theatre, formerly the Theatre Masque and Masque Theater, is a Broadway theater at 252 West 45th Street in the Theater District of Midtown Manhattan in New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9898,
        total_orders: 1781.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Vegetarian, Spicy, Rich, Chinese, Thai, Exquisite, Asian, Sweet, Premium',
        annual_dol: 6712.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7384,
        store_longitude: -73.9857,
        total_orders: 617.12,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Italian, Elegant, Hotel, Rustic, Warm, Classic, Superb, Welcoming, Perfect',
        annual_dol: 2522.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -74.0014,
        total_orders: 721.09,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Italian, Wine, Cozy, Modern, Casual, Traditional',
        annual_dol: 737.89,
        description:
            'Tuscan spot offering quick-serve cafe fare by day & trattoria dishes with wine at night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6814,
        store_longitude: -73.9939,
        total_orders: 3960.54,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Tequila, Mexican, Margarita, Salad, Delicious, Relaxed, Friendly, Latin, American, Authentic',
        annual_dol: 4366.41,
        description:
            'Tequila-based craft cocktails meet Mexican tapas at this rustic-chic bar with a back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6808,
        store_longitude: -73.9969,
        total_orders: 173.29,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Italian, Wine, Martini, Bruschetta, Homemade, Creamy, Romantic, Classy, Hospitable, Exotic',
        annual_dol: 539.98,
        description:
            'Fresh Italian food dished in a brick-walled room anchored by an L-shaped wood bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7526,
        store_longitude: -73.9242,
        total_orders: 177.09,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tequila, Mexican, Upscale, Dessert, Intimate, Classy, Traditional, Contemporary, Excellent, Casual',
        annual_dol: 341.25,
        description:
            '"We\'re a contemporary Mexican restaurant & Bar located in Long Island City, recently opened our doors for Mexican food lovers that want to have a very special experience. We serve traditional recipes mixed with contemporary flavors and have a huge variety of different types of tequilas and cocktails. Come see us today!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7459,
        store_longitude: -73.89,
        total_orders: 24.66,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Fun, Turkish, Great, Modern, Casual',
        annual_dol: 100.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7547,
        store_longitude: -73.9705,
        total_orders: 533.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Wine, Flavorful, Italian, Tasty, Delicious, Cozy, Rustic, Bellini, Romantic',
        annual_dol: 621.61,
        description:
            'Northern Italian specialties highlighted in a rustic-chic restaurant offering terrace dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8034,
        store_longitude: -73.6461,
        total_orders: 372.86,
        neighborhood: '',
        keywords:
            'Kebab, Salad, Steak, Mexican, Beer, Coffee, Dessert, Japanese, Turkish, Mediterranean',
        annual_dol: 964.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6264,
        store_longitude: -74.0241,
        total_orders: 1261.1,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Coffee, Wine, French Onion Soup, French, Beer, Bbq',
        annual_dol: 1287.85,
        description:
            'Longtime restaurant & bar offering a menu of burgers & other American eats, plus outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9727,
        total_orders: 968.9,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Scandinavian, Nordic, Salad, Wine, Dessert, Danish, Meatball, French, Seasonal, Premium',
        annual_dol: 1010.91,
        description:
            'High-end Scandinavian tasting & fixed-price menus served in a minimalist Nordic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7547,
        store_longitude: -73.9665,
        total_orders: 417.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Wine, Burger, Salad, Stylish, Cozy, French, Beer, Romantic, Classy',
        annual_dol: 426.33,
        description:
            'Parisian-style bistro with outdoor tables dishing up French classics, from coq au vin to couscous.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6715,
        store_longitude: -73.9502,
        total_orders: 2870.78,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Pizza, Salad, Wine, Italian, Beer, Margarita, Painkiller, Spicy, Cozy',
        annual_dol: 2870.78,
        description:
            'Caribbean-inspired cocktails like Mai Tais, plus beer & wine, served in a retro, low-key space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.987,
        total_orders: 1178.4,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Hamburger, Vegetarian, Vegan, Salad, Gourmet, Beer, Bbq, Danish, Wine',
        annual_dol: 1203.41,
        description:
            'Contemporary chain with customizable gourmet burgers, including vegetarian, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.9854,
        total_orders: 3337.22,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Burger, Salad, Chicken Parmigiana, Gluten Free, Italian, Dessert, Shrimp Scampi, Meatball, Delicious',
        annual_dol: 3414.77,
        description:
            'Bustling eatery serving large, shareable portions of Italian comfort food in a lively setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6914,
        store_longitude: -73.9979,
        total_orders: 329.54,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Karaoke, Beer, Wine, American, Great, Casual',
        annual_dol: 336.53,
        description:
            'Longtime watering hole with a weathered bar & pool table in a nautical-themed setting near Pier 6.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9931,
        total_orders: 68.32,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Tasty, Spicy, Rustic, Seasonal, Classy, Laid-Back',
        annual_dol: 301.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7806,
        store_longitude: -73.9529,
        total_orders: 1073.82,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Steak, Salad, Chicken Parmigiana, Italian, Gluten Free, Meatball, Tasty, Shrimp Scampi, Savory',
        annual_dol: 1096.6,
        description:
            'Casual Italian fare with cocktails & wine in a contemporary, rustic setting with patterned tiles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.9811,
        total_orders: 839.32,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Upscale, Beer, Tasty, Hotel, Gourmet, Delicious, Opulent, American, Irish',
        annual_dol: 3746.16,
        description:
            '"Irish American Bar serving good quality tasty dishes. Only steps from Bryant Park and Time Square. Happy Hour daily from 11am-7pm. Great cocktails and great service."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.993,
        total_orders: 1414.05,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Champagne, Hurricane, Beer, Burger, Wine, Margarita, Mimosa, Fun, Great, Clean',
        annual_dol: 1612.97,
        description:
            'Hardware store turned buzzy nightclub with a large dance floor & DJs plus nightly live performances.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.9798,
        total_orders: 1084.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Salad, Hamburger, Dessert, French Toast, French, Delicious, English Muffin, Quaint, Homemade',
        annual_dol: 1107.34,
        description:
            'A neighborhood veteran serving classic French bistro fare in a rustic, small space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7788,
        store_longitude: -73.9784,
        total_orders: 356.4,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Wine, Dessert, Turkish, Rustic, Mediterranean, Cozy, Casual, Perfect, American',
        annual_dol: 380.09,
        description:
            'Cozy Turkish eatery serving wood-fired flat breads, seafood & meat plates, plus wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9942,
        total_orders: 2130.66,
        neighborhood: 'Nolita',
        keywords:
            'Lemon Drop, Tequila, Wine, Vodka, Tasty, Margarita, Beer, Martini, Gin, Delicious',
        annual_dol: 2223.04,
        description: 'A low-key spot for beer & drinks with benches & outdoor tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.724,
        store_longitude: -73.7263,
        total_orders: 15.65,
        neighborhood: 'Bellerose Manor',
        keywords:
            'Vegetarian, Kebab, Chinese, Juicy, Opulent, Sweet, Tandoori Chicken, Indian, Amazing, Tandoori Lamb',
        annual_dol: 63.99,
        description:
            'Indian specialties along with Chinese dishes served in an expansive space with opulent decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7251,
        store_longitude: -73.6344,
        total_orders: 1814.4,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Lobster Roll, Steak, Salad, Gluten Free, Coffee, Beer, Italian, Delicious',
        annual_dol: 1933.87,
        description:
            'Old-school bar & grill (circa 1942) with dark wood interior for homey American classics & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7369,
        store_longitude: -74.0025,
        total_orders: 2494.03,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Chicken Pot Pie, Wine, Bbq, Delicious, American, Quaint, Cozy, Premium, Timeless',
        annual_dol: 2494.03,
        description:
            'High-end renditions of American comfort fare are served with cocktails in a clubby space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7829,
        store_longitude: -73.9802,
        total_orders: 718.99,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Bbq, American, Perfect, Exquisite, Casual, Local, Great, Delectable, Diverse',
        annual_dol: 2518.17,
        description:
            'Modest comedy club presenting young & seasoned performers plus occasional big names.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.8961,
        total_orders: 101.7,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, South American, American, Premium, Fantastic, Great, Casual',
        annual_dol: 131.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7495,
        store_longitude: -73.7078,
        total_orders: 2125.77,
        neighborhood: 'Glen Oaks',
        keywords: 'Italian, Pizza, Salad, Wine, Beer, Rustic, Mediterranean, Cozy, Warm, Homemade',
        annual_dol: 2196.75,
        description:
            'Neighborhood Italian restaurant serving up housemade pastas & wood-fired pizzas in a cozy setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.5881,
        total_orders: 62.03,
        neighborhood: '',
        keywords: 'Relaxed, Friendly, Casual, Local, Great',
        annual_dol: 64.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7703,
        store_longitude: -73.9185,
        total_orders: 183.23,
        neighborhood: 'Astoria',
        keywords:
            'Beer, Salad, Greek, Spacious, Mediterranean, Premium, Fresh, Cool, Greek Salad, Authentic',
        annual_dol: 320.86,
        description:
            'Family-owned Greek place serving traditional fare in an unpretentious space with a bar & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6845,
        store_longitude: -73.6152,
        total_orders: 4.78,
        neighborhood: '',
        keywords:
            'Salad, Chicken Caesar Salad, Jamaican, Caribbean, Beer, Spicy, Caesar Salad, Exotic, Spacious, Casual',
        annual_dol: 19.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7734,
        store_longitude: -73.9167,
        total_orders: 61.3,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Pizza, Salad, Dessert, Coffee, Italian, Margarita, Cozy, Crisp, Premium, Perfect',
        annual_dol: 187.81,
        description:
            '"Only in Astoria can you bring together the best of Italian-pizza making, the best American beer, the best wines from all over the world, exceptional service with a friendly face and attitude, an affordable check, and the quickest service possible! Pizza and drinks, family and friends, and a space where all are welcome. We find joy in bringing the best things together. We are diverse team situated in a diverse neighborhood and our mission is to bring together people from the neighborhood, the greater city, and out of town guests. Food, drinks, and fun unite us. Macoletta is a family-friendly place: we love kids and will be offering pizza classes on weekend mornings."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7241,
        store_longitude: -73.9504,
        total_orders: 2224.05,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Beer, Wine, Delicious, Ale, Cozy, American, Perfect, Exceptional, Classic',
        annual_dol: 2277.52,
        description:
            'This cozy bar offers flat screen TVs for sports fans, plus classic & creative American menu options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.734,
        store_longitude: -73.9551,
        total_orders: 2130.98,
        neighborhood: 'Greenpoint',
        keywords: 'Salad, Vodka, Tequila, Gin, Chinese, Rum, Polish, Classy, Casual, Fun',
        annual_dol: 2190.09,
        description:
            'Compact restaurant serving Taiwanese cuisine & cocktails amid an easygoing atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9195,
        total_orders: 547.87,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Margarita, Salad, Mexican, Steak, Nachos, Tasty, Fajitas, Enchiladas, Ale',
        annual_dol: 571.63,
        description:
            'Low-lit Mexican spot offering a variety of margaritas & a menu of tacos, enchiladas & the like.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6789,
        store_longitude: -73.9872,
        total_orders: 3700.68,
        neighborhood: 'Gowanus',
        keywords:
            'Delicious, Fun, Playful, Fantastic, Great, Excellent, Smooth, Ethiopian, Floridian, Retro',
        annual_dol: 4325.43,
        description:
            'Shuffleboard courts draw playful crowds to this big bar with food trucks & occasional live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7052,
        store_longitude: -74.0162,
        total_orders: 988.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Luxurious, Martini, Stylish, American, Spicy, Classic, Modern, Innovative, Contemporary',
        annual_dol: 3725.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -74.0119,
        total_orders: 1223.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Salad, Beer, Wine, Irish, Warm, Fun, Welcoming, Friendly, Vibrant',
        annual_dol: 1308.52,
        description:
            'A classic Irish standby with dark wood, outdoor seating & a menu of beer & pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.724,
        store_longitude: -74.0098,
        total_orders: 335.48,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Chinese, Upscale, Hawaiian, Asian, Savory, Elegant, Turkish, Vintage, Mediterranean',
        annual_dol: 1371.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7039,
        store_longitude: -74.0129,
        total_orders: 1195.81,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Wine, Beer, Salad, Irish, Juicy, Japanese, French Onion Soup, American, French',
        annual_dol: 3265.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.9975,
        total_orders: 248.37,
        neighborhood: 'Little Italy',
        keywords:
            'Vegetarian, Italian, Shrimp Cocktail, Wine, Delicious, Spicy, Organic, Homemade, Mediterranean, Bruschetta',
        annual_dol: 290.07,
        description:
            'Traditional Italian dishes & desserts in a relaxed, white-tablecloth setting with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7211,
        store_longitude: -73.9979,
        total_orders: 848.06,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Italian, Upscale, Organic, Rich, Mediterranean, Spacious, Casual, Greek, Fresh',
        annual_dol: 3547.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7767,
        store_longitude: -73.9528,
        total_orders: 73.84,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Vegan, Salad, Pizza, Tasty, French Toast, Beer, French, Swiss, Modern, American',
        annual_dol: 82.28,
        description:
            '"Green Kitchen has proudly served the great city of New York since 1931 by providing breakfast, lunch and dinner in a casual family atmosphere. We strive to provide each one of our patrons with the utmost courtesy and consideration in order to maximize the dining experience. As part of our commitment to quality, we prepare our cuisine fresh each day using only the finest ingredients from carefully selected vendors. Our staff is always dedicated to taking exceptional care of you, our guests. We look forward to serving you soon!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9769,
        total_orders: 1913.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Pizza, Chicken Parmigiana, Italian, Wine, Beer, Spicy, French, Urban, Rooftop',
        annual_dol: 7336.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7809,
        store_longitude: -73.9585,
        total_orders: 60.24,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Italian, Salad, Burger, Pizza, Wine, Dessert, Upscale, Whiskey, Rustic, Hotel',
        annual_dol: 246.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7013,
        store_longitude: -73.9147,
        total_orders: 11787.77,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Vodka, Rum, Delicious, Hotel, Friendly, Vintage, Eclectic, Clean, Cool',
        annual_dol: 13425.46,
        description:
            'Chic cocktail bar with a vintage vibe & heated patio offering a pub menu & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6946,
        store_longitude: -73.9308,
        total_orders: 97.12,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Italian, Gourmet, Wine, Beer, Dessert, Martini, American, Cozy, Premium',
        annual_dol: 453.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7653,
        store_longitude: -73.9694,
        total_orders: 281.36,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'French, Elegant, Hotel, Comfortable, Modern, Mediterranean, Italian, Casual, Fine, American',
        annual_dol: 662,
        description:
            '"Please know that your reservation is for outdoor dining only. We Are Open for Outdoor Dining With Both Heaters and Plexiglass Dividers Between Each Table for Your Safety! Pick-Up and Delivery Available. Make Your Lunch or Dinner Reservation Now!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6971,
        store_longitude: -73.9352,
        total_orders: 457.59,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Spacious, Cozy, Friendly, Great, Classic, Casual, Local',
        annual_dol: 700.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8537,
        store_longitude: -73.7905,
        total_orders: 635.88,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Steak, Lobster Tail, Seafood Paella, Delicious, Italian, Upscale, Polished, Spanish, Latin',
        annual_dol: 952.17,
        description:
            'Waterside eatery & lounge with outdoor seating & traditional Latin American recipes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7105,
        store_longitude: -74.0164,
        total_orders: 41.9,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Local, Casual',
        annual_dol: 171.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.666,
        store_longitude: -73.806,
        total_orders: 386.71,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'American, Hotel, Premium, Contemporary',
        annual_dol: 396.86,
        description:
            "All rooms offer contemporary decor and custom-designed beds, plus flat-screen TVs with premium cable channels, microwaves, minifridges and free WiFi.A cooked-to-order breakfast is available (fee), and there's an on-site restaurant serving American cuisine, a bar and a 24/7 convenience store. There's also a 24/7 fitness center. Additional amenities include 1,200 sq ft of meeting rooms and a business center, plus a free airport shuttle.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6414,
        store_longitude: -73.7927,
        total_orders: 12259.81,
        neighborhood: 'Jamaica',
        keywords: 'Glamorous, Hotel, Tasty, Dessert, Delicious, Coffee, Salad, Fun, Sleek, Vintage',
        annual_dol: 13332.1,
        description:
            "Manor Museum and 12 miles from Rockaway Beach.Featuring soundproofed floor-to-ceiling windows, the stylish, retro rooms come with complimentary Wi-Fi and flat-screen TVs, plus minifridges, safes and fully stocked cocktail bars. Some have airport views.There's a sleek 1960s-inspired bar, a food hall with grab-and-go options, and a celebrity chef-helmed restaurant/cafe. Amenities include a 10,000-sq-ft gym, meeting space, and an outdoor pool with runway views.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9161,
        total_orders: 313.86,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Lobster, Tequila, Lobster Tail, Mexican, Beer, Margarita, Wine, Upscale, Trendy',
        annual_dol: 1329.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7277,
        store_longitude: -73.9935,
        total_orders: 1269.86,
        neighborhood: 'NoHo',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Wine, Coffee, Dessert, Organic, French, Danish, Delicious',
        annual_dol: 1296.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9896,
        total_orders: 9182.37,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Dessert, Savory, Delicious, Sweet, Beer, Japanese, Irish, Vibrant, Classic, Casual',
        annual_dol: 28236.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.764,
        store_longitude: -73.7714,
        total_orders: 12548.98,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Lobster, Cajun, Salad, Lobster Bisque, Jambalaya, Gluten Free, Italian, Bbq, Spicy',
        annual_dol: 12979.99,
        description:
            'Cajun & Southern-accented grub, plus Sunday brunch served in a lively spot with TVs & a rooftop bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.975,
        total_orders: 1518.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Coffee, Elegant, Hotel, Warm, French, Brazilian, Sophisticated, Intimate, Contemporary',
        annual_dol: 6652.51,
        description:
            '"Fasano Restaurant is a fine dining Italian restaurant in NYC. Located in the Theater District, in the heart of Midtown, the restaurant offers four distinct and elegantly decorated dining rooms. Rooted in traditional Italian gastronomy with contemporary flare, the restaurant is composed of Osteria, the front-of-house cocktail bar with a casual feel, a sophisticated Main Dining Salon, a reserved Private Dining Room, and Baretto – an intimate live music space with a jazz club ambience. Fasano welcomes private events, meetings and celebrations for up to 120 guests."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -74.0134,
        total_orders: 629.07,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Hamburger, Cheesesteak, Tasty, Beer, American, Philly Cheesesteak, Comfortable, Fantastic',
        annual_dol: 656.34,
        description:
            'Easygoing brick-lined pub serving cheesesteaks, wings & other pub fare, plus beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5941,
        store_longitude: -73.9978,
        total_orders: 189.58,
        neighborhood: '',
        keywords:
            'Lasagna, Burger, Tacos, Pizza, Sushi, Salad, Chicken Parmigiana, Meatball, Italian, Gluten Free',
        annual_dol: 201.75,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9748,
        total_orders: 154.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Trendy, Luxurious, Mediterranean, Seasonal, Homemade, Asian, Healthy, Polished, Exquisite',
        annual_dol: 204.14,
        description:
            "A Midtown power-lunch scene that's fueled by high-end Italian dining & expense accounts.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9859,
        total_orders: 5465.58,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Luxurious, Elegant, Trendy, Hotel, Crisp, Superb, Perfect, Great, Fresh, Classic',
        annual_dol: 5606.14,
        description:
            '"The Edison Ballroom is an elegant private event space in the heart of Times Square, New York City. Originally opened in the 1930’s as the Grand Ballroom of the adjacent Edison Hotel, this historical venue reopened in 2008 after a multi-million dollar renovation that preserved its original flair and art deco style. This expansive, multi-level venue is perfect for hosting any type of private event – weddings, Bar and Bat Mitzvahs, holiday parties, nonprofit events, galas, business conferences, red carpet fashion shows and more. For every event The Edison Ballroom provides its superb in-house catering prepared by celebrity chef Mina Newman."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7821,
        store_longitude: -73.9534,
        total_orders: 804.42,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Vegetarian, Salad, Pizza, Italian, Wine, Upscale, Beer, Hotel, Warm, Classy',
        annual_dol: 821.49,
        description:
            'Authentic Italian fare draws crowds to this sophisticated Upper East Side eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9912,
        total_orders: 500.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Gluten Free, Coffee, Vietnamese, Chili Chicken, Thai, Spicy, Vodka, Fried Chicken, Rich, Bbq',
        annual_dol: 511.07,
        description:
            'Sleek destination turning out Asian fusion BBQ & small plates with creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9806,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Hotel, Italian, Upscale, Great, Perfect',
        annual_dol: 96.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7305,
        store_longitude: -74.0006,
        total_orders: 12256.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Coffee, Upscale, Wholesome, Irish, Classy, Homemade, Great, Casual',
        annual_dol: 12529.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -73.994,
        total_orders: 1981.33,
        neighborhood: 'NoHo',
        keywords: 'Steak, Burger, Salad, Wine, Italian, Beer, Upscale, Scandinavian, Spicy, French',
        annual_dol: 2088.05,
        description:
            'Restaurant & lounge featuring upscale French & Italian bistro fare, plus wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7279,
        store_longitude: -74.0014,
        total_orders: 107.52,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Coffee, Beer, Wine, Scotch, Jamaican, Salad, Cuban, Caribbean, Rum, Trendy',
        annual_dol: 175.7,
        description:
            'Diner featuring retro decor, cocktails & classic Caribbean fare such as jerk chicken & curried goat.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.9905,
        total_orders: 1787.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Steak, Burger, Whiskey, Beer, American, Hotel, Vibrant, Awesome, Perfect, Great',
        annual_dol: 1828.68,
        description:
            '"Neighborhood cocktail bar, part time sports bar serving brunch, lunch and dinner!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.9866,
        total_orders: 1205.62,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Beer, Trendy, Lively, Spacious, Fun, Premium, Classy, Friendly, Great',
        annual_dol: 1240.12,
        description:
            'Spacious bar with an industrial-chic vibe, games on TV and private karaoke rooms with VIP packages.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7464,
        store_longitude: -73.9879,
        total_orders: 858.84,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Glamorous, Hotel, Opulent, Vintage, Gourmet, Fancy, Irish, Indulgent, Iconic, Exotic',
        annual_dol: 1030.24,
        description:
            '"A new hidden gem, subterranean speakeasy lounge where thieves go to unwind in a lush and opulent atmosphere rewarding themselves after an extensive heist. Evening libations crafted by elite mixologists, paired with elevated gourmet dishes, caviar and champagne, all while being captivated by live interactive performances that will transport you to a bygone era. For those with the most discerning of tastes."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8387,
        store_longitude: -73.7831,
        total_orders: 4770.45,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Chicken Parmigiana, Steak, Italian, Beer, Casual, Serene, Excellent',
        annual_dol: 5740.83,
        description:
            'Restaurant & bar with a view of Long Island Sound serving lobster, fried seafood & more since 1946.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.9416,
        total_orders: 66.39,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Sushi, Lobster Roll, Lobster, Salad, Wine, Beer, Japanese, Spicy, Trendy, Asian',
        annual_dol: 243.72,
        description:
            'Trendy restaurant preparing Japanese staples including sushi, meat dishes, noodles & rice bowls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.9533,
        total_orders: 37.27,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Steak, Salad, Cuban, Pizza, Wine, Rum, Mojito, American, Latin, Spacious',
        annual_dol: 48.06,
        description:
            'Featuring live jazz on the weekends, this casual bistro serves Cuban steak & seafood dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7925,
        store_longitude: -73.9737,
        total_orders: 83.82,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Burger, Salad, Vegetarian, Kebab, Mediterranean, Martini, French, Egyptian, Bellini',
        annual_dol: 367.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7304,
        store_longitude: -73.8636,
        total_orders: 99.52,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Kebab, Beer, Mexican, Salad, Turkish, Mediterranean, Relaxed, Fresh, Traditional, Casual',
        annual_dol: 406.81,
        description:
            'Family-owned restaurant offering traditional Turkish comfort dishes & seafood in a relaxed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6051,
        store_longitude: -73.9721,
        total_orders: 11.39,
        neighborhood: 'Gravesend',
        keywords: 'Burger, Tasty, Beer, Wine, Elegant, French, Classy, Sweet, Amazing, Great',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7456,
        store_longitude: -73.8838,
        total_orders: 10.35,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Beer, American, Local, Casual, Classic',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8688,
        store_longitude: -73.9021,
        total_orders: 19795.42,
        neighborhood: 'Kingsbridge Heights/West Bronx',
        keywords:
            'Mexican, Steak, Salad, Shrimp Cocktail, Nachos, Margarita, Tex-Mex, Fajitas, Spicy, Enchiladas',
        annual_dol: 21776,
        description:
            'Cantina serving Tex-Mex & south-of-the-border fare alongside margaritas & other colorful cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7549,
        store_longitude: -73.9733,
        total_orders: 5328.68,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Hotel, Rich, Coffee, Warm, Vibrant, Sophisticated, Awesome, Classic, Comfortable',
        annual_dol: 5353.71,
        description:
            "The warm contemporary rooms have colorful accents and ornate marble bathrooms, along with smart TVs and free Wi-Fi. Suites add pull-out sofas; some have separate living areas and balconies.There's a classic 1920s-inspired cocktail bar and a Starbucks. There's also a 24-hour fitness room and a business center, along with 3 meeting rooms.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7126,
        store_longitude: -73.7849,
        total_orders: 80.86,
        neighborhood: 'Jamaica Estates/Jamaica',
        keywords: 'Warm, Friendly, Great, Fantastic, Casual',
        annual_dol: 90.4,
        description:
            'Snug bar with a wood-clad interior like a train car featuring sports on TV, plus happy hour deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7611,
        store_longitude: -73.9667,
        total_orders: 54.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Lobster, Salad, Bourbon, Spicy, Asian, American, Seasonal, Classic, Pure, Fine',
        annual_dol: 224.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9619,
        total_orders: 178.37,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Pizza, Italian, Salad, Rich, Delicious, Sparkling, Rustic, Fun, Caesar Salad, Fantastic',
        annual_dol: 729.13,
        description:
            '"Coco Pazzeria features thin crust Pizza, Oysters and Bubbles, combining the oysters taste of minerality with the rich flavor and crunchiness of thin crust pizzas - all is topped off with the effervescence of sparkling wines. The menu also features a curated selection of Buzzetti (mini “open face” calzones), Panozzi (sandwich on rustic bread), Pastas, Salads and the house specialty – Focaccia. CHILDREN\'S POLICY: Out of our concerns for safety, space limitations and an abundance of caution, we cannot permit strollers or children under 5 in our dining room. Our limited area of passage with hot and/or heavy items being carried by our staff may cause safety concerns for our guests. we appreciate and thank your for understanding."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9862,
        total_orders: 17784.04,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Steak, Hamburger, Italian, Beer, Nachos, Coffee, Bbq, Spicy, Dessert',
        annual_dol: 18189.77,
        description:
            'Rock ’n’ roll-themed chain with a high-energy vibe serving burgers & American classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9925,
        total_orders: 352.85,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Burger, Cheesesteak, Beer, Wine, Organic, American, Philly Cheesesteak, Authentic, Clean',
        annual_dol: 1238.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5563,
        store_longitude: -73.9226,
        total_orders: 493.2,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords: 'Burger, Authentic, American, Classic, Sweet, Cool, Casual, Unique',
        annual_dol: 2417.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7534,
        store_longitude: -73.994,
        total_orders: 178.82,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Healthy, Friendly, Authentic, Natural, Signature, Rare, Unique',
        annual_dol: 186.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7466,
        store_longitude: -73.9887,
        total_orders: 3224.38,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Hotel, Trendy, Urban, Eclectic, Vibrant, Intimate, Lively, Seasonal, Rooftop, Perfect',
        annual_dol: 13180.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7239,
        store_longitude: -73.9939,
        total_orders: 4908.42,
        neighborhood: 'Nolita',
        keywords:
            'Champagne, Wine, Vegan, Beer, Dessert, Delicious, Sparkling, American, Rustic, Indulgent',
        annual_dol: 6340.22,
        description:
            'Modern takes on internationally inspired food served in rustic-chic environs with a backyard garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.995,
        total_orders: 2883.13,
        neighborhood: 'NoHo',
        keywords:
            'Korean, Japanese, Delicious, Asian, Warm, Vietnamese, Thai, French, Mediterranean, American',
        annual_dol: 3827.27,
        description:
            '"KYU a fresh concept born in Miami in 2016, now expanding to New York City. Our wood-fired Asian grill features a diverse menu and focuses on professional yet relaxed service and a commitment to green practices. Our menu is influenced by years of global travel and inspired by Japanese and Korean yakiniku (literally translated to grilled meat in Japanese). The plates are designed to be straightforward and shareable, enabling a warm social environment. A carefully curated cocktail list features variations of classic cocktails with clever Asian twists."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.883,
        total_orders: 1415.02,
        neighborhood: 'Jackson Heights',
        keywords:
            'Margarita, Mexican, Colombian, Salad, Wine, South American, Fajitas, Latin, Spanish, American',
        annual_dol: 1542.15,
        description:
            'Unfussy locale featuring Colombian & Tex-Mex specialties, plus frozen cocktails & TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7741,
        store_longitude: -73.9081,
        total_orders: 828.66,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Burger, Hamburger, Salad, Meatball, Coffee, Wine, Spicy, American, Polished',
        annual_dol: 1845.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6652,
        store_longitude: -73.9615,
        total_orders: 2072.52,
        neighborhood: 'Crown Heights',
        keywords: 'Coffee, Hotel, Elegant, Exotic',
        annual_dol: 2360.45,
        description:
            'Brooklyn Botanic Garden is a botanical garden in the borough of Brooklyn, New York City. It was founded in 1910 using land from Mount Prospect Park in central Brooklyn, adjacent to Prospect Park and the Brooklyn Museum. The 52-acre garden holds over 14,000 taxa of plants and has nearly a million visitors each year.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6999,
        store_longitude: -73.9287,
        total_orders: 360.94,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Steak, Meatball, Italian, Beer, Tasty, Trendy, Amazing, Cozy, Great',
        annual_dol: 368.6,
        description:
            'Pan pizza is served with cocktails & locally brewed beer at this laid-back neighborhood haunt.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7215,
        store_longitude: -73.9426,
        total_orders: 826.18,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Wine, Beer, Cozy, Casual, Cool',
        annual_dol: 881.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7647,
        store_longitude: -73.9708,
        total_orders: 37.53,
        neighborhood: 'Lenox Hill',
        keywords: 'Italian, Beer, Irish, American, Warm, High-End, Greek, Casual',
        annual_dol: 159.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8542,
        store_longitude: -73.884,
        total_orders: 34.91,
        neighborhood: 'Belmont/West Bronx',
        keywords: 'Dominican, Classy, Local, Casual',
        annual_dol: 142.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.839,
        store_longitude: -73.7836,
        total_orders: 15759,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Vegetarian, Cornbread, Lobster Bisque, Italian, Wine, Martini, Creamy',
        annual_dol: 16874.73,
        description:
            'Bustling, spacious family seafood spot, with big portions, booth seating & stained-glass decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6855,
        store_longitude: -74.0023,
        total_orders: 1203.06,
        neighborhood: 'Columbia Street Waterfront District',
        keywords: 'Tacos, Steak, Mexican, Salad, Margarita, Beer, Spicy, American, Casual, Classic',
        annual_dol: 1228.58,
        description:
            'Mexican food, ground-floor bar & year-round roof deck with a Manhattan view, drawing a young crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9535,
        total_orders: 1212.52,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Burger, Hamburger, Beer, Salad, Whiskey, Champagne, Wine, Hotel, Retro, Fancy',
        annual_dol: 1290.35,
        description: 'Wines & charcuterie at this bar with a comfy retro vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7293,
        store_longitude: -73.9889,
        total_orders: 136.71,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Sushi, Steak, Tasty, Japanese, Sashimi, Delicious, Modern, Casual, Intimate, Fresh',
        annual_dol: 142.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.731,
        store_longitude: -73.9886,
        total_orders: 1730.05,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Dessert, French Toast, French, Delicious, Trendy, Gin',
        annual_dol: 1805.05,
        description:
            'Trendy types gather for American eats & specialty drinks at this upbeat hangout & brunch favorite.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5427,
        store_longitude: -74.1425,
        total_orders: 22.02,
        neighborhood: 'Great Kills',
        keywords:
            'Steak, Burger, Lobster, Tacos, Sushi, Salad, Pizza, Shrimp Cocktail, Cajun, Italian',
        annual_dol: 90.02,
        description:
            'Casual eatery with a bar, deck & views of the harbor marina serving seafood, meats & pasta.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6127,
        store_longitude: -74.0653,
        total_orders: 92.81,
        neighborhood: 'Rosebank',
        keywords:
            'Burger, Beer, Tasty, Wine, Classic, Outstanding, Great, American, Cool, Wonderful',
        annual_dol: 387.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.8831,
        total_orders: 3.66,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords:
            'Mexican, Tacos, Tequila, Nachos, Salad, Margarita, Delicious, Authentic, Fresh, Casual',
        annual_dol: 27.89,
        description:
            'Compact, neighborhood eatery offering generous plates of Mexican fare in a down-to-earth atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5276,
        store_longitude: -74.2314,
        total_orders: 30.64,
        neighborhood: 'Charleston',
        keywords: 'Golfclub, Unique',
        annual_dol: 107.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 2777.82,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegan, Upscale, Italian, Organic, Pizza, Delicious, Polished, Charming, Homemade, Bellini',
        annual_dol: 2836.76,
        description:
            'Upscale scene where an international crowd tucks into Italian food chased with Bellinis.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.9782,
        total_orders: 185.58,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Beer, Wine, Irish, Awesome, Impressive, Fresh, Perfect',
        annual_dol: 841.48,
        description:
            '"Rockefeller Center Taproom serving IPAs, Stouts, Lagers, and Seltzer from our brewery in Brooklyn. Also featuring wine, snacks, and cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7986,
        store_longitude: -73.6635,
        total_orders: 5785.44,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Cajun, Italian, Wine, Beer, American, Prime Rib, Polished',
        annual_dol: 5908.2,
        description:
            'Modern steakhouse featuring meats aged on-site, seafood, traditional sides & an extensive wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8253,
        store_longitude: -73.9509,
        total_orders: 51.45,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Lasagna, Wine, Italian, Upscale, Beer, Cozy, Delicious, Premium, Meatball, Intimate',
        annual_dol: 597.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.9681,
        total_orders: 3137.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Hamburger, Dessert, American, English Muffin, Eggs Benedict, Trendy, Hotel, Bellini, Contemporary',
        annual_dol: 3339.43,
        description:
            'Trendy types gather for American eats & specialty drinks at this upbeat hangout & brunch favorite.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9666,
        total_orders: 2829.93,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Beer, Salad, Whiskey, Ale, Tuna Melt Sandwich, American, Cozy, Hip, Artisanal',
        annual_dol: 2995.96,
        description:
            'Hip spot with elevated bar food & a rotating list of 42 local & international craft beers on tap.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6262,
        store_longitude: -74.0301,
        total_orders: 451.87,
        neighborhood: 'Bay Ridge',
        keywords: 'Wine, Beer, Russian, Juicy, Outstanding, Fantastic, Friendly, Great, Casual',
        annual_dol: 1847.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.9898,
        total_orders: 5241.67,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Vegetarian, Sushi, Salad, Japanese, Gluten Free, Dessert, Bbq, Beer, Delicious',
        annual_dol: 5883.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6745,
        store_longitude: -73.9502,
        total_orders: 168.04,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Cornbread, Salad, Korean, Southern Comfort, Caesar Salad, Southern, American, Modern, Casual',
        annual_dol: 235.7,
        description:
            'Cozy, brick-lined hub with hearty familiar fare, a charming patio, craft cocktails & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9892,
        total_orders: 5486.03,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Cajun, Wine, Upscale, Caesar Salad, Ribeye Steak',
        annual_dol: 5809,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.9845,
        total_orders: 15559.29,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Tequila, Trendy, Hotel, Stylish, Eclectic, Vibrant, American, Fabulous, Rooftop, Bright',
        annual_dol: 16393.97,
        description:
            "from the nearest subway station.Featuring art by local students, the stylish rooms offer Wi-Fi access and smart TVs; some have sofas or bunk beds. The 1- and 2-bedroom suites add living rooms. Room service is available.Amenities include a polished, wood-paneled restaurant, a trendy North African eatery and a vintage-chic cocktail bar. There's also a 24-hour gym and a game room, as well as a resident artist program.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9908,
        total_orders: 1491.91,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Delicious, Karaoke, Dessert, Meatball, Legendary, Friendly, Perfect, Quirky, Great, Excellent',
        annual_dol: 1523.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7374,
        store_longitude: -73.9844,
        total_orders: 1543.32,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Wine, Beer, Whiskey, Irish, Upscale, Delicious, Ale, Classy, Awesome, Friendly',
        annual_dol: 1543.32,
        description:
            'Cavernous Irish hideaway with a large beer selection & plenty of games like shuffleboard & pool.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.9936,
        total_orders: 65.75,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Milkshake, Mexican, Tasty, Gourmet, Beer, Texan, Wine, Nachos, Delicious',
        annual_dol: 268.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.9967,
        total_orders: 1439.51,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Salad, Mexican, Nachos, Japanese, Swiss, Irish, American, Clean, Casual',
        annual_dol: 5139.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -73.9941,
        total_orders: 224.75,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Wine, French, Gourmet, Spanish, Casual, Relaxed, Creative, Great',
        annual_dol: 229.52,
        description:
            'Relaxed eatery with quirky, rustic-chic decor serving creative French dishes, charcuterie & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7459,
        store_longitude: -73.9937,
        total_orders: 2740.35,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Karaoke, Beer, Wine, American, Retro, Lively, Fancy, Fun, Classy, Sophisticated',
        annual_dol: 3215.8,
        description: 'Modern piano bar with alcove booth seating & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9897,
        total_orders: 2392.08,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Burger, Hamburger, Tomato Soup, Mexican, Salad, Beer, Italian, Wine, Irish, Spicy',
        annual_dol: 2537.34,
        description:
            'A large beer list, happy-hour deals & sports on TV are offered at this Irish pub near Penn Station.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7469,
        store_longitude: -73.991,
        total_orders: 306.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, American, Premium, Chic, Traditional, Friendly, Amazing',
        annual_dol: 320.28,
        description:
            'The warmly decorated rooms offer custom-designed beds, and flat-screen TVs with premium cable channels, plus free Wi-Fi and coffeemakers. Some rooms offer balconies with views of the Empire State Building. Suites add separate living areas.A grill restaurant offers all-day traditional American meals and open-air dining. Additional amenities include a 24-hour exercise room and a business center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7487,
        store_longitude: -73.9921,
        total_orders: 1202.14,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Lobster, Salad, Steak, Lobster Bisque, Dessert, Gourmet, Mexican, Creamy, Hotel, Sleek',
        annual_dol: 1320.62,
        description:
            'Open-all-day venue offering gourmet American menus, glossy white-on-white decor & a busy bar scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7543,
        store_longitude: -74.0029,
        total_orders: 1280.11,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Lobster Roll, Burger, Wine, Beer, Upscale, Trendy, Delicious, Spanish, Asian',
        annual_dol: 5232.68,
        description:
            'Easygoing outfit with seasonal open-air seating focusing on regional seafood dishes & comfort food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9791,
        total_orders: 141.68,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Italian, Steak, Coffee, Wine, Mediterranean, Elegant, American, Iconic, Classic, Diverse',
        annual_dol: 579.14,
        description:
            'Elegant cafe/bar offering Italian breakfasts, light meals & cocktails in an art deco interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6562,
        store_longitude: -74.0073,
        total_orders: 1846.55,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Wine, Beer, Ale, Rooftop, Casual',
        annual_dol: 7548.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.8417,
        total_orders: 963.04,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Burger, Hamburger, Meatball Sub, Meatball, Salad, Beer, Wine, Bbq, Delicious',
        annual_dol: 1004.79,
        description:
            'Hip American cafe for classic comfort foods, weekend brunch & full bar with house cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7758,
        store_longitude: -73.9571,
        total_orders: 766.78,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Italian, Wine, Delicious, Meatball, Refined, Sweet, Veal Parmigiana, Homemade, Outstanding',
        annual_dol: 783.05,
        description:
            'Refined venue offering elevated Italian fare, including homemade pastas, plus an ample wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.771,
        store_longitude: -73.9509,
        total_orders: 856.19,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Lasagna, Salad, Italian, Dessert, Cozy, Homemade, Casual, Caesar Salad, Local, Traditional',
        annual_dol: 874.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8256,
        store_longitude: -73.9189,
        total_orders: 90.3,
        neighborhood: 'Concourse Village/West Bronx',
        keywords:
            'Vegan, Vegetarian, Tacos, Mexican, Salad, Burritos, Casual, Healthy, Local, Signature',
        annual_dol: 430.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9656,
        total_orders: 372.7,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Flavorful, Italian, Wine, Upscale, Delicious, Romantic, Classy, Homemade, French, Casual',
        annual_dol: 414.67,
        description:
            'Brick-lined Italian restaurant lures locals with homestyle red-sauce dishes & an unpretentious vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7311,
        store_longitude: -73.9576,
        total_orders: 642.93,
        neighborhood: 'Greenpoint',
        keywords: 'Tequila, Mexican, Beer, Enchiladas, Delicious, Awesome, Classic, Casual, Chill',
        annual_dol: 826.12,
        description:
            'Mexican restaurant serving a classic lineup of tacos, enchiladas & tortas, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7108,
        store_longitude: -73.9352,
        total_orders: 710.24,
        neighborhood: 'East Williamsburg',
        keywords: 'Spacious, Rooftop, Delicious, Perfect, Great, Clean',
        annual_dol: 915.1,
        description:
            '"The Bogart House is a four-story building located in the heart of Williamsburg. The rooftop has some of the best views Brooklyn has to offer. This is a versatile space that can be tailored to your professional or personal needs. The space is available for promotional events, company training seminars, and retreats, celebrations, photo/video shoots, etc. Ask our team about the various services we provide in our all-inclusive bar packages. We are fully equipped with a Funktion One Sound System as well as all production equipment to cater to any event. The Manhattan, Queens, and Brooklyn Skyline featured throughout the building is a stunning backdrop that will enhance any experience at Bogart House."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6739,
        store_longitude: -73.7297,
        total_orders: 49.72,
        neighborhood: 'Jamaica/Rosedale',
        keywords:
            'Salad, Tasty, Delicious, American, Rum, Caesar Salad, Casual, Traditional, Local, Rare',
        annual_dol: 203.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8377,
        store_longitude: -73.7832,
        total_orders: 624.46,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Steak, Beer, Delicious, Fried Calamari, Bbq, Hurricane, Quaint, Homemade, Fresh',
        annual_dol: 900.19,
        description:
            'Fried, broiled & raw seafood, plus a menu of burgers, BBQ & wings, served in a no-frills setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5222,
        store_longitude: -74.2352,
        total_orders: 281.15,
        neighborhood: 'Charleston',
        keywords:
            'Italian, Pizza, Delicious, Upscale, Elegant, Chinese, Inviting, Perfect, Classic, Modern',
        annual_dol: 305.96,
        description:
            'Upscale bar & eatery offering wood-fired pizza & traditional Italian cuisine in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7206,
        store_longitude: -73.7607,
        total_orders: 25.93,
        neighborhood: 'Holliswood/Jamaica/Hollis',
        keywords:
            'Lobster, Salad, Cajun, Pizza, Spicy, Fried Rice, Friendly, Memorable, Lively, Authentic',
        annual_dol: 106,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7319,
        store_longitude: -73.9855,
        total_orders: 3867.99,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tacos, Beer, Ale, American, Hotel, Casual, Friendly, Local, Awesome, Great',
        annual_dol: 10615.43,
        description:
            '"Local Bar and Restaurant offering New American dishes, craft cocktails & beer with brunch on weekends."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7352,
        store_longitude: -73.9935,
        total_orders: 670.62,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegan, Wine, Beer, Gluten Free, Champagne, Turkish, Mediterranean, Luxurious, Eclectic, Exotic',
        annual_dol: 684.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7372,
        store_longitude: -73.8813,
        total_orders: 835.92,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Shrimp Cocktail, Beer',
        annual_dol: 872.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7304,
        store_longitude: -73.864,
        total_orders: 7.33,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Vegetarian, Salad, Chicken Tikka Masala, Chili Chicken, Upscale, Crisp, Relaxed, Friendly, Traditional, Indian',
        annual_dol: 27.63,
        description:
            'Relaxed Indian restaurant offers a wide variety of meat & vegetarian options plus a lunch buffet.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -73.9894,
        total_orders: 25.53,
        neighborhood: 'East Village/Bowery',
        keywords: 'Thai, Salad, Dessert, Hotel, Spicy, Sweet, Southern, Casual, Authentic, Local',
        annual_dol: 96.42,
        description: '"Thai Restaurant & Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.8877,
        total_orders: 144.67,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Salad, Thai, Wine, Dessert, Tasty, Green Curry, Spicy, Elegant, Asian, Bbq',
        annual_dol: 401.76,
        description:
            'Comfortable, narrow Thai restaurant doling out spicy curries, noodles & other traditional eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9689,
        total_orders: 60.62,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Sushi, Gourmet, Bbq, Irish, Homemade, Cozy, Thai, Friendly, American',
        annual_dol: 290.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8224,
        store_longitude: -73.9494,
        total_orders: 1529.33,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Burger, Tasty, Beer, Savory, Wine, Delicious, Warm, Rustic, Italian, Crisp',
        annual_dol: 1704.08,
        description:
            'Microbrews, craft cocktails & area-sourced comfort fare served in a roomy, rustic-chic space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7543,
        store_longitude: -73.966,
        total_orders: 419.11,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Tasty, Chocolate Mousse, Wine, Organic, Dessert, Vodka, Spicy, Mediterranean',
        annual_dol: 1713.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9724,
        total_orders: 10.97,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Tasty, Austrian, Margarita, Fresh, Seasonal, Casual, Great, Local, Omelette',
        annual_dol: 15.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6837,
        store_longitude: -73.9079,
        total_orders: 26.75,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Sushi, Pizza, Coffee, Vegetarian, Mexican, Beer, Danish, Thai, American',
        annual_dol: 109.35,
        description:
            'Snug bistro with outdoor seating serving up American staples, plus smoothies, cocktails & hookah.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5929,
        store_longitude: -73.9882,
        total_orders: 191.28,
        neighborhood: '',
        keywords: 'Coffee, Wine, Swiss, Caesar Wrap, Southern, Casual, Fresh, Great',
        annual_dol: 283.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.9874,
        total_orders: 568.66,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Burger, Tequila, Salad, Margarita, Coffee, Dessert, Burritos, Spicy, Delicious',
        annual_dol: 687.03,
        description: 'Mexican standards & margaritas in a colorful eatery with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9873,
        total_orders: 2525.46,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Burger, Steak, Whiskey Sour, Rooftop, Margarita, Whiskey, Nachos, American, Spacious',
        annual_dol: 2686.83,
        description:
            'Music, drinks & loungey upstairs rooms differentiate this spacious 3-level hangout.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9889,
        total_orders: 3315.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Tomato Soup, Beer, French Onion Soup, Spicy, French, Vintage, Hotel, Irish',
        annual_dol: 3386.09,
        description:
            'Live music & craft beers update this vintage neighborhood bar & grill near Times Square.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9871,
        total_orders: 406.96,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Salad, Tasty, Irish, Warm, Cozy, American, Latin, Fusion, Authentic',
        annual_dol: 439.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9892,
        total_orders: 464.1,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Healthy, Casual, Great',
        annual_dol: 484.23,
        description:
            '"Located at 689 8th Ave (@ 43rd Street), Satin Dolls has just been completely remodeled and is now New York\'s premier topless Strip Club. We feature the hottest dancers around. Satin Dolls is the best spot to watch all major sporting events on big screen TVs! If you are looking for a great time, beautiful women, and affordable VIP Rooms… look no further."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6893,
        store_longitude: -73.9924,
        total_orders: 1666.71,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Burger, Hamburger, Vegetarian, Vegan, Salad, Beer, Organic, Moroccan, Delicious, American',
        annual_dol: 1738.97,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.4997,
        store_longitude: -74.2391,
        total_orders: 455.84,
        neighborhood: 'Tottenville',
        keywords: 'Lobster, Salad, Pizza, Italian, Chinese, Wine, Beer, Casual, Exquisite, Fresh',
        annual_dol: 931.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7339,
        store_longitude: -74.0061,
        total_orders: 645.24,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Salad, Italian, Pizza, Delicious, Gin, Warm, French, Relaxed, Intimate',
        annual_dol: 2259.86,
        description:
            'Italian nibbles, cocktails & wine offered in a stylish, relaxed spot with sidewalk seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -73.6358,
        total_orders: 108.75,
        neighborhood: '',
        keywords: 'Burger, Vegetarian, Dessert, Trendy, Casual, Modern, Creative',
        annual_dol: 444.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -74.0037,
        total_orders: 185.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Italian, Wine, Peruvian, Casual, Classic, Unpretentious, Authentic, Perfect, Recommended, Lively',
        annual_dol: 363.45,
        description:
            'Casual all-day venue for espresso & aperitivi, with sidewalk seating & standing room at the bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7501,
        store_longitude: -73.6418,
        total_orders: 11.6,
        neighborhood: '',
        keywords: 'Irish, Italian, American, Champagne, Cultural, Great, Casual',
        annual_dol: 47.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8134,
        store_longitude: -73.8027,
        total_orders: 288.19,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Pizza, Greek',
        annual_dol: 328.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6008,
        store_longitude: -73.6561,
        total_orders: 0.85,
        neighborhood: '',
        keywords:
            'Coffee, Vegetarian, Salad, Burger, Tasty, Elegant, Delicious, Premium, Healthy, Casual',
        annual_dol: 3.46,
        description: '"Premium Couture Coffee Shop"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8416,
        store_longitude: -73.9397,
        total_orders: 784.25,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Vegan, Vegetarian, Wine, Beer, Italian, Tasty, Irish, Karaoke, American, Yummy',
        annual_dol: 2933.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8376,
        store_longitude: -73.942,
        total_orders: 643.26,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Tasty, Beer, Delicious, Warm, Comfortable, Inviting, Friendly, Exquisite, Sweet, Awesome',
        annual_dol: 837.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.629,
        store_longitude: -74.1187,
        total_orders: 31.84,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Roast Beef, Mexican, Italian, Delicious, Warm, American, Irish, Friendly, Sangria',
        annual_dol: 130.14,
        description:
            '"Totally renovated upbeat tavern serving, ice cold beer, speciality drinks, wine, sangria and serving comfort food specializing in roast beef artisian sandwiches!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7536,
        store_longitude: -73.8329,
        total_orders: 11.24,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Lobster, Delicious, Sweet, Natural, Sumptuous, Casual, Indian, Local, Rooftop',
        annual_dol: 45.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.673,
        store_longitude: -73.9903,
        total_orders: 335.59,
        neighborhood: 'Gowanus',
        keywords:
            'Burger, Bourbon, American, Delicious, Lively, Fresh, Great, Contemporary, Exceptional, Excellent',
        annual_dol: 912.02,
        description:
            'Contemporary kosher steakhouse in a former warehouse with a sizable patio & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.9153,
        total_orders: 607.45,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Beer, Whiskey, Wine, Irish, Trendy, Contemporary, American, Vintage, Modern',
        annual_dol: 637.3,
        description:
            'Inventive cocktails & draft beer served in a contemporary Irish pub with frequent entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5735,
        store_longitude: -73.9876,
        total_orders: 829.44,
        neighborhood: 'Coney Island',
        keywords: 'Wine, American, Glamorous, Casual, Rooftop, Innovative, Great',
        annual_dol: 1399.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6557,
        store_longitude: -73.9499,
        total_orders: 185.15,
        neighborhood: 'Prospect Lefferts Gardens/Little Haiti',
        keywords: 'Coffee, Beer, Salad, American, Healthy, Casual, Perfect, Fresh, Sweet, Shandy',
        annual_dol: 620.68,
        description:
            '"Coffee infused cocktails, fresh and healthy dishes, and coffee in Brooklyn, NY."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6653,
        store_longitude: -73.9893,
        total_orders: 114.25,
        neighborhood: 'South Slope/Park Slope',
        keywords:
            'Lobster, Pizza, Beer, Delicious, Jamaican, Caribbean, Sweet, Fun, Fusion, Rustic',
        annual_dol: 279.91,
        description:
            'Traditional Caribbean cuisine is served in this casual eatery that has terrace dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -73.9396,
        total_orders: 2158.93,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Burger, Whiskey, Beer, Bourbon, American, Hip, Casual, Great',
        annual_dol: 2284.82,
        description:
            'Hip gathering spot for burgers, wings & other pub fare with lots of whiskey & proprietary beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7501,
        store_longitude: -73.937,
        total_orders: 500.06,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Coffee, Hotel, Modern',
        annual_dol: 544.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.8819,
        total_orders: 638.9,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Salad, Pizza, Ecuadorian, Tequila Sunrise, Shrimp Cocktail, Tequila, Italian, Colombian, Pinacolada, Dominican',
        annual_dol: 1957.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6566,
        store_longitude: -73.645,
        total_orders: 166.86,
        neighborhood: '',
        keywords: 'Tequila, Tacos, Mexican, Pizza, Margarita, Steak, Cuban, Nachos, Gourmet, Salad',
        annual_dol: 223.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8307,
        store_longitude: -73.684,
        total_orders: 931.09,
        neighborhood: '',
        keywords:
            'Wine, Steak, Beer, Belgian, French Onion Soup, French, Turkish, American, Sweet, Amazing',
        annual_dol: 1267.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7528,
        store_longitude: -73.9858,
        total_orders: 941.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Cozy, Hotel, Spacious, Warm, Picturesque, Friendly, Premium, Modern, Traditional, Casual',
        annual_dol: 968.02,
        description:
            'The warm studios come with kitchens and free WiFi, as well as desks and flat-screen TVs with premium cable channels. Some offer skyline views. 1- and 2-bedroom suites add separate living areas and additional TVs.There’s free hot breakfast, a cozy restaurant and bar with a fireplace, and a 24/7 convenience store. Other amenities include a 24/7 fitness center and business center, plus 7 meeting rooms and laundry facilities.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6892,
        store_longitude: -73.816,
        total_orders: 339.86,
        neighborhood: 'South Ozone Park/South Richmond Hill',
        keywords: 'Burger, Chinese, French, Friendly, Southern, Casual, Local, Great',
        annual_dol: 384.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7464,
        store_longitude: -73.9178,
        total_orders: 119.94,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Salad, Wine, Gluten Free, Dessert, Quaint, Mimosa, Delicious, Warm, Romantic, Casual',
        annual_dol: 127.82,
        description:
            'Inventive New American comfort food & wines in a cozy, unpretentious bistro with back garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7245,
        store_longitude: -73.5403,
        total_orders: 768.81,
        neighborhood: '',
        keywords: 'Steak, Beer, Legendary, Margarita, Ale, American, Friendly, Fun, Lively, Great',
        annual_dol: 802.15,
        description:
            'Lively chain steakhouse serving American fare with a Southwestern spin amid Texas-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -74.0117,
        total_orders: 88.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Salad, Italian, Shrimp Cocktail, Pizza, Japanese, Hawaiian, Thai, Veal Parmigiana, Hotel, Caesar Salad',
        annual_dol: 362.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7051,
        store_longitude: -73.9253,
        total_orders: 52.54,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Casual, Local, Classic',
        annual_dol: 214.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -74.0075,
        total_orders: 27617.96,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Whiskey, Scotch, Upscale, Sushi, Distilled, Cozy, Warm, Refined, Premium',
        annual_dol: 56898.15,
        description:
            'Clubby, refined bar & lounge specializing in rare spirits with an eclectic menu of small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -74.004,
        total_orders: 11258.46,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Lobster, Steak, Salad, Dessert, Wine, Beer, Chinese, Fried Shrimp, Trendy',
        annual_dol: 12404.66,
        description:
            'A bi-level, 1940s gambling parlor sets an exotic backdrop for Chinese-Portuguese small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -73.5872,
        total_orders: 698.52,
        neighborhood: '',
        keywords: 'Beer, Coffee, Hotel, American, Premium, Casual, Great',
        annual_dol: 728.81,
        description:
            "Warmly decorated rooms feature free Wi-Fi and flat-screen TVs, plus microwaves, minifridges and coffeemakers. Some have pull-out sofas; suites add separate sitting areas.A casual bistro serves breakfast and dinner. There's also a lobby lounge and a furnished patio, along with an indoor pool and a fitness room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.9989,
        total_orders: 305.1,
        neighborhood: 'Little Italy',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Salad, Dessert, Apple Pie, Beer, Wine, Tasty, Delicious',
        annual_dol: 330.33,
        description:
            'Seafood spot with a New England fish-house vibe featuring a raw bar, lobster rolls & fish plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7987,
        store_longitude: -73.6999,
        total_orders: 954.89,
        neighborhood: '',
        keywords: 'Burger, Salad, Beer, Irish, Friendly, Fancy, Awesome, Clean, Perfect, Casual',
        annual_dol: 975.15,
        description:
            '"A local meeting spot for great food, drinks, sports, music, and other events."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -74.0044,
        total_orders: 1183.71,
        neighborhood: 'Hudson Square',
        keywords: 'Tasty, Hotel, Authentic, Cool, Casual, Great, Local',
        annual_dol: 1287.24,
        description:
            "The SoHo Playhouse is an Off-Broadway theatre at 15 Vandam Street in the Hudson Square area of Manhattan. The theatre opened in 1962 as the Village South Theatre with the original production of Jean Erdman's musical play The Coach with the Six Insides which was based upon James Joyce's last novel Finnegans Wake.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9935,
        total_orders: 5.16,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Lobster, Salad, Pizza, Mexican, Gourmet, Margarita, Italian, Wine, Cuban, Avocado Toast',
        annual_dol: 21.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.629,
        total_orders: 677.88,
        neighborhood: '',
        keywords: 'Golfclub, Wonderful, Great, Terrific',
        annual_dol: 830.37,
        description:
            'Wheatley Hills Golf Club, is a 110-acre country club and golf course in East Williston, New York. It was established in 1913 from land purchased from William Titus for forty eight dollars per acre. The Titus homestead was converted into the clubhouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -74.0057,
        total_orders: 3367.89,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Tasty, Wine, Dessert, French, Delicious, Martini, Spanish, Refined, Comfortable',
        annual_dol: 3625.65,
        description:
            'Contemporary bistro serving French standards & natural wines in a refined environment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -74.0016,
        total_orders: 4987.25,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Burger, Hamburger, Salad, Tequila, Beer, Wine, Tasty, French, Stylish, Prime Rib',
        annual_dol: 5203.48,
        description:
            'Sister to the Chicago original serving elevated burgers & American dishes in a stylish setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7178,
        store_longitude: -74.0072,
        total_orders: 127.23,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Upscale, Spicy, Italian, Warm, Impeccable, Cozy, American, French, Classy',
        annual_dol: 520.09,
        description:
            "Upscale yet easygoing, this no-reservations eatery serves French & Italian standbys & kids' choices.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7169,
        store_longitude: -74.0084,
        total_orders: 486.28,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Italian, Wine, Delicious, Warm, Classy, Homemade, Authentic, Intimate, Contemporary',
        annual_dol: 541.03,
        description:
            'Casual Italian spot delivering pastas & other hearty dishes in a rustic, brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.5881,
        total_orders: 90.66,
        neighborhood: '',
        keywords:
            'Burger, Salad, Hotdog, Beer, Chinese, Delicious, American, Relaxed, Casual, Coleslaw',
        annual_dol: 100.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7068,
        store_longitude: -73.923,
        total_orders: 1498.51,
        neighborhood: 'Bushwick',
        keywords:
            'Lobster Roll, Lobster, Margarita, Beer, Coffee, Spicy, Bourbon, French, Grand Marnier, American',
        annual_dol: 2077.19,
        description:
            'Place with a beachy vibe for oysters, grilled fish & other seafood, plus frozen drinks & DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.8941,
        total_orders: 14.62,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Beer, Coffee, Fun, American, Great, Diverse, Casual',
        annual_dol: 26.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7138,
        store_longitude: -73.9954,
        total_orders: 228.09,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Karaoke, Hawaiian, Chinese, Fun, Great, Casual',
        annual_dol: 237.98,
        description: '"Karaoke dive bar, cash only"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9,
        store_longitude: -73.8622,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Pizza, Wine, Apple Pie, Salad, Beer, Cobb Salad',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.866,
        store_longitude: -73.9275,
        total_orders: 636.44,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Lasagna, Garlic Bread, Pizza, Chicken Parmigiana, Italian, Gluten Free, Wine, Vodka, Beer, Tasty',
        annual_dol: 678.75,
        description:
            'Italian restaurant serving classic fare in a warm space with outdoor tables for people-watching.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7784,
        store_longitude: -73.9746,
        total_orders: 16.3,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Upscale, Hotel, Legendary, Elegant, Iconic, Spacious, American, Picturesque, Urban, Sophisticated',
        annual_dol: 66.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 720.43,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Rich, Delicious, Memorable, Iconic, Premium, Heritage, Crafted, Unique',
        annual_dol: 785.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.749,
        store_longitude: -74.0081,
        total_orders: 441.44,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Golfclub, Gin, Seasonal, Urban, Friendly, Great, Casual, Hummus, Signature',
        annual_dol: 1079.72,
        description:
            'All-weather facility with hitting stalls & a net-covered fairway jutting over the Hudson River.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6819,
        store_longitude: -73.977,
        total_orders: 19.14,
        neighborhood: 'Park Slope',
        keywords:
            'Steak, Salad, Vegan, Shrimp Cocktail, Pizza, Mexican, Gluten Free, Clean, Fried Calamari, Cuban',
        annual_dol: 188.15,
        description:
            'Spacious spot a block from the Barclays Center serving classic Latin-American dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -73.9831,
        total_orders: 1805.39,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Wine, Vietnamese, Salad, Warm, Ale, Cozy, Innovative, Modern, Bright, Lively',
        annual_dol: 2008.69,
        description:
            'Updated takes on Vietnamese staples plus cocktails are served in an elegant, understated space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -73.9496,
        total_orders: 466.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Wine, Italian, Dessert, French, French Onion Soup, Phenomenal, Filet Mignon, Cozy, Quaint',
        annual_dol: 496.15,
        description:
            'Steakhouse standards & wines served in a space with an old-school Italian vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 359.57,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Sushi, Mexican, Italian, Chinese, Japanese, Chilean, Thai, Fried Rice, Asian, Fusion',
        annual_dol: 428.48,
        description:
            'Easygoing Pan-Asian eatery in the Staten Island Mall offering sushi, dim sum & Thai curries.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6648,
        total_orders: 413.25,
        neighborhood: '',
        keywords:
            'Burger, Lobster Roll, Lobster, Salad, Cajun, Gluten Free, Beer, Warm, French, American',
        annual_dol: 440.4,
        description:
            '"Long Beach\'s premier dining & music venue. Join us daily for lunch, dinner, or drinks! Live Music! Have a party? Call us & inquire about our private party room!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.68,
        store_longitude: -73.9682,
        total_orders: 318.97,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Wine, Trendy, Delicious, Friendly, Vibrant, Lively, Fun, Mead, Intimate',
        annual_dol: 521.25,
        description:
            'Cool hangout featuring select craft beers on tap, plus 5,000 vinyl records & a lineup of DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5869,
        store_longitude: -73.6657,
        total_orders: 137.39,
        neighborhood: '',
        keywords: 'Burger, Sushi, Beer, Savory, Delicious, Coffee, Fun, Perfect, Thai, Fresh',
        annual_dol: 187.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7424,
        store_longitude: -73.9834,
        total_orders: 2906.98,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Beer, Whiskey, Wine, Salad, Bourbon, Rustic, Warm, Friendly, Casual, Spacious',
        annual_dol: 3077.06,
        description:
            'Hip, late-night bar serving up craft cocktails, draft brews & casual fare in a rustic-chic interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7434,
        store_longitude: -73.9858,
        total_orders: 519.22,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Wine, Vodka, Elegant, Hotel, Perfect, Great, Recommended, Unique',
        annual_dol: 541.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7778,
        store_longitude: -73.8732,
        total_orders: 20.45,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Burger, Pizza, Coffee, Wine, Clean, Hotel, Spacious, American',
        annual_dol: 83.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6969,
        store_longitude: -73.9068,
        total_orders: 919.59,
        neighborhood: 'Bushwick',
        keywords: 'Vegan, Milkshake, Beer, Friendly, Asian, Cool, Casual, Local, Fancy, Wonderful',
        annual_dol: 1524.36,
        description:
            'Low-key, neighborhood hangout with bar bites, old-school video games & DJ nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6237,
        store_longitude: -73.9647,
        total_orders: 232.39,
        neighborhood: 'Midwood',
        keywords: 'Wine, Vodka, Tequila, Pizza, Italian, Gin, Belgian, Rum, Stylish, Cozy',
        annual_dol: 1056.76,
        description:
            '"Kosher Authentic Italian Fine Dining Restaurant. New York’s premier restaurant for fine dining. Famous for our Veal and Artichokes, our traditional Italian Roman style cooking in a modern era provides a dining experience like none other. With our Italian ambiance, cozy yet romantic- this is the perfect setting for a night out."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7237,
        store_longitude: -74.0041,
        total_orders: 7153.63,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Upscale, Delicious, Warm, Dessert, Mexican, Spacious, Perfect, Fun, Outstanding',
        annual_dol: 7353.09,
        description:
            'Hip, laid-back stop offering Latin-inspired seafood plus a downstairs bar for cocktails & music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.9976,
        total_orders: 815.06,
        neighborhood: 'Little Italy',
        keywords:
            'Steak, Pizza, Chicken Parmigiana, Italian, Dessert, Wine, Cuban, Mediterranean, Bruschetta, Authentic',
        annual_dol: 989.72,
        description:
            'Laid-back dining turning out pastas, wood-fired pizza & seafood dishes, plus outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.5854,
        total_orders: 96.57,
        neighborhood: '',
        keywords: 'Wine, Beer, Margarita, Dessert, American, Classy, Casual, Traditional, Lit',
        annual_dol: 440.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7774,
        store_longitude: -73.9523,
        total_orders: 945.76,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tequila, Mexican, Steak, Wine, Shrimp Scampi, Spanish, Seasonal, American, Ribeye Steak, Casual',
        annual_dol: 1201.21,
        description:
            'Hip Mexican & American eatery offering seasonal dishes, signature cocktails & a bottomless brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7723,
        store_longitude: -73.956,
        total_orders: 650.96,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Burger, Beer, Margarita, Mimosa, Screwdriver, Wine, Dope, American, Delicious, Casual',
        annual_dol: 2289.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7679,
        store_longitude: -73.9592,
        total_orders: 156.57,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Champagne, Italian, Dessert, Wine, Mexican, Martini, Beer, Delicious, Warm',
        annual_dol: 174.2,
        description:
            'White-tablecloth eatery serving Italian classics dedicated to the late actor Rudolph Valentino.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6606,
        store_longitude: -73.7389,
        total_orders: 111.76,
        neighborhood: 'Jamaica/Rosedale',
        keywords: 'Whiskey, Margarita, Pizza, Ale, American, Casual, Cool, Superb, Classic, Local',
        annual_dol: 148.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5249,
        store_longitude: -74.2367,
        total_orders: 882.16,
        neighborhood: 'Charleston',
        keywords:
            'Pizza, Chicken Parmigiana, Italian, Homemade, Modern, Rum, Fresh, Fabulous, Sweet, Casual',
        annual_dol: 1108.83,
        description:
            "\"Ariana's South hosting events for every occasion, modern chic setting. Ariana's has been serving the community and beyond for over 2 decades. Weddings, engagements, sweet 16's, christenings, birthday celebrations\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -73.9405,
        total_orders: 1945.68,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Lasagna, Sushi, Vegan, Vegetarian, Wine, Dessert, Champagne, Intimate, Great, Delectable',
        annual_dol: 2120.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7342,
        store_longitude: -73.8685,
        total_orders: 183.07,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Lasagna, Gluten Free, Italian, Dessert, Pizza, Delicious, Rich, Homemade, Sweet, Irresistible',
        annual_dol: 186.96,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -73.9893,
        total_orders: 1219.43,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Steak, Upscale, American, French, Classic, Contemporary, Traditional, Dutch, Robust, Bloody Mary',
        annual_dol: 3743.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.948,
        total_orders: 664.74,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Salad, Kebab, Delicious, French, Mediterranean, Russian, Premium, Greek, American, Fresh',
        annual_dol: 740.14,
        description:
            'Splashy waterside Russian restaurant featuring an Azerbaijani-style menu, plus live entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5879,
        store_longitude: -73.9602,
        total_orders: 10.81,
        neighborhood: 'Sheepshead Bay/Gravesend',
        keywords: 'Kebab, Wine, Salad, Coffee, Vegetarian, Beer, Dessert, Delicious, Cozy, Turkish',
        annual_dol: 44.2,
        description:
            'Spacious Turkish eatery with a rustic-chic interior offering an all-day menu & an ample wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.7714,
        total_orders: 26.72,
        neighborhood: 'Bayside',
        keywords:
            'Burger, Mexican, Steak, Nachos, Fajitas, Modern, Casual, Local, Premium, Perfect',
        annual_dol: 109.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8194,
        store_longitude: -73.9442,
        total_orders: 544.1,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Salad, Wine, Cajun, Delicious, Stylish, Tranquil, Spicy, Beer, Sweet, Friendly',
        annual_dol: 720.32,
        description:
            'Stylish dining room with Continental, Cajun & traditional Southern cuisine, plus live weekend jazz.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8038,
        store_longitude: -73.6468,
        total_orders: 467.11,
        neighborhood: '',
        keywords: 'Steak, Salad, Wine, Dessert, Pizza, Tasty, Italian, Margarita, Elegant, Organic',
        annual_dol: 484.45,
        description:
            'Longtime brick-floored cafe serving Roman fare, fixed-price menus & desserts from its bakery annex.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9681,
        total_orders: 558.89,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Pizza, Gluten Free, Wine, Japanese, Champagne, Casual',
        annual_dol: 789.49,
        description:
            '"クラブげらげらは全部屋が完全個室となっており最新カラオケも完備。お一人様から団体のお客様までプライバシーを守られながらお楽しみいただけますので接待やストレス発散にもうってつけです。明朗会計で安心の料金設定。求人に力を入れているので女の子の数や質はアメリカで一番だと評判です。"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7589,
        store_longitude: -73.9894,
        total_orders: 3104.53,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Cajun, Bbq, Italian, Hotel, Southern, Classic, Tradition, Intimate, Casual, Iconic',
        annual_dol: 3170.4,
        description:
            'Classic Theater District jazz club with roomy red environs, a large bar & Cajun-influenced food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -73.991,
        total_orders: 859.3,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Tasty, Spicy, Chinese, Delicious, Organic, Asian, Ale, Thai, Eclectic, Fresh',
        annual_dol: 3016.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9819,
        total_orders: 1844.54,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Champagne, Upscale, Hotel, Luxurious, French, Elegant, Premium, Iconic, Sophisticated, Superb',
        annual_dol: 1924.51,
        description:
            "theaters of Broadway.Upscale, contemporary rooms have marble bathrooms, and feature minibars, free Wi-Fi and flat-screen TVs; minifridges and coffeemakers are available on request. Suites add separate living rooms and/or pull-out sofas; some have terraces.There's a refined, art deco-style French brasserie with a bar, plus a fitness room, a business center and valet parking (fee).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.788,
        store_longitude: -73.7276,
        total_orders: 67.49,
        neighborhood: '',
        keywords:
            'Sushi, Delicious, Warm, Wholesome, Amazing, Classy, Exceptional, Great, Fresh, Asian',
        annual_dol: 275.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7517,
        store_longitude: -74.0042,
        total_orders: 7466.68,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Casual, Tradition, Great',
        annual_dol: 7721.21,
        description: 'Dark-lit, multilevel gay leather-&-Levis bar with a roof deck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7466,
        store_longitude: -73.9915,
        total_orders: 277.3,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Upscale, Rooftop, Iconic, Modern, Healthy, Great, Gracious, Signature, Local',
        annual_dol: 295.52,
        description:
            "Offering free Wi-Fi and flat-screen TVs, the posh, modern rooms with wooden floors and padded leather headboards also have microwaves, minifridges, and room service, along with safes and coffeemakers. Some have pull-out sofas. Suites add a separate sitting area and floor-to-ceiling windows.Amenities include a mod-style lobby, a rooftop lounge, and a swanky, bistro-style restaurant and bar serving breakfast and dinner. There's also an outdoor cafe and a fitness room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7509,
        store_longitude: -74.0043,
        total_orders: 12222.42,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Pizza, Vegetarian, Salad, Rooftop, Cozy, Romantic, Warm, Seasonal, Vibrant',
        annual_dol: 13254.65,
        description:
            'Open year-round, this restaurant with a green-garden vibe serves chic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9901,
        total_orders: 1886.78,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Beer, Whiskey, Delicious, Irish, Fun, Friendly, Cool, Wonderful, Traditional, Casual',
        annual_dol: 2494.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -73.9907,
        total_orders: 79.35,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Wine, Beer, Karaoke, Whiskey, Trendy, Delicious, Hotel, Korean, Classy, Relaxed',
        annual_dol: 324.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7285,
        store_longitude: -73.9983,
        total_orders: 2048.32,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Vegetarian, Vegan, Salad, Beer, Spicy, Wine, Moroccan, Organic',
        annual_dol: 2641.39,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -74.0001,
        total_orders: 267.7,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Delicious, Beer, Romantic, Italian, Yummy, Friendly, Sensational, Bright, Amazing, Great',
        annual_dol: 1094.28,
        description:
            'A combination comedy club, music venue & sports bar with Italian food and plenty of beer thrown in.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.9931,
        total_orders: 735.17,
        neighborhood: 'NoHo',
        keywords:
            'Steak, Wine, Italian, Gourmet, Mediterranean, Rustic, Spanish, Romantic, Cozy, Warm',
        annual_dol: 767.04,
        description:
            'Upmarket, carefully sourced Mediterranean plates served in a rustic-chic space with a wine cellar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9952,
        total_orders: 445.79,
        neighborhood: 'Nolita',
        keywords: 'Lobster, Indonesian, French, Wine, Beer, Thai, Modern, Savory, Asian, Sleek',
        annual_dol: 497.1,
        description:
            'Cédric Vongerichten (son of Jean-Georges) serves French-accented Indonesian fare in a sleek setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9989,
        total_orders: 568.33,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Beer, Coffee, Vietnamese, French, Cozy, Latin, Classy, American, Friendly',
        annual_dol: 1995.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.7121,
        total_orders: 285.92,
        neighborhood: '',
        keywords: 'American, Sensational, Complex, Fresh',
        annual_dol: 1174.7,
        description:
            "The North Shore Towers and Country Club is a three-building residential cooperative located in the Glen Oaks neighborhood in the New York City borough of Queens, near the city's border with Nassau County. The complex is located next to the Long Island Jewish Medical Center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7495,
        store_longitude: -73.9475,
        total_orders: 103.85,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Trendy, Tequila, Coffee, Hotel, Vodka, Gin, Modern, Local, Clean, Rum',
        annual_dol: 391.28,
        description:
            "art museum.Minimalist mixed or single-sex dorms provide bunk beds with reading lights and lockers, as well as linens and towels. All have en suite bathrooms and free Wi-Fi. Private rooms are also available.In addition to an industrial-chic bar and cafe, there's a communal kitchen, a rooftop terrace and coin-operated laundry facilities.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6771,
        store_longitude: -74.0129,
        total_orders: 819.77,
        neighborhood: 'Red Hook',
        keywords: 'Burger, Lobster, Hamburger, Salad, Tequila, Vodka, Coffee, Gin, Cozy, Quaint',
        annual_dol: 834.1,
        description:
            'Classic cocktails pair with seasonal American fare & weekend brunch in a vintage-inspired setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -73.9924,
        total_orders: 207.74,
        neighborhood: 'NoHo',
        keywords: 'Salad, Wine, Vegetarian, Dessert, Italian, Margarita, Beer, Cozy, Spicy, Warm',
        annual_dol: 216.75,
        description:
            'Small Italian kitchen with a pasta-centric menu & varied wines in warm environs with a fireplace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7345,
        store_longitude: -73.9908,
        total_orders: 1289.19,
        neighborhood: 'Lower Manhattan',
        keywords: 'Cosmopolitan, Iconic, Fantastic, Local, Complex',
        annual_dol: 3949.65,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -73.9892,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Upscale, Hotel, Avocado Toast, Cozy, Vibrant, Fun, American, Chic, Artistic, Rare',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -73.9915,
        total_orders: 333.44,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Tacos, Tequila, Mexican, Tasty, Gourmet, Salad, Coffee, Breakfast Burrito, Funky, Tex-Mex',
        annual_dol: 388.67,
        description:
            'Hip tavern in a former garage offering gourmet tacos, Mexican beers & agave-based cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7252,
        store_longitude: -73.9906,
        total_orders: 164.66,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Salad, Sushi, Flavorful, Vodka, Delicious, Cozy, Sustainable, French, Relaxed, Fine Quality',
        annual_dol: 179.45,
        description:
            'Russian & French cuisine, plus vodka-infused offerings in a cozy venue with live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7787,
        store_longitude: -73.7763,
        total_orders: 1158.87,
        neighborhood: 'Bayside/Bay Terrace',
        keywords:
            'Steak, Chicken Alfredo, American, Classic, Asian, Casual, Fusion, Local, Fine, Lively',
        annual_dol: 1279.53,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9783,
        total_orders: 195.23,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Salad, Lobster, Steak, Upscale, Hotel, Stylish, Polished, Sleek, Modern, Retro',
        annual_dol: 798.03,
        description:
            "blocks from Rockefeller Center and the Theater District.Decorated in retro Danish-modern style, the polished rooms have free Wi-Fi, terraces and glass-tiled bathrooms, as well as minibars, flat-screen TVs and books. Suites provide additional terraces and/or loft ceilings. Room service and in-room massages are available.Freebies include access to an off-site fitness center. There's also an upscale steakhouse and a 24-hour business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5799,
        store_longitude: -73.96,
        total_orders: 85.25,
        neighborhood: 'Brighton Beach',
        keywords:
            'Salad, Coffee, Pizza, Italian, Creamy, Warm, Mediterranean, Caesar Salad, Greek, American',
        annual_dol: 149.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7135,
        store_longitude: -74.0079,
        total_orders: 1019.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Vegan, Tasty, Whiskey, Beer, Upscale, Delicious, Friendly, American, Irish',
        annual_dol: 1316.56,
        description:
            'Pub where booths have private TVs & the menu includes pasta, sandwiches & other American bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9692,
        total_orders: 1371.65,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Hotel, Burger, Irish, Spacious, Comfortable, Modern, French Onion Soup, French, Quaint',
        annual_dol: 1419.92,
        description:
            'The modern rooms feature flat-screen TVs, coffeemakers, leather headboards and Wi-Fi (surcharge). Suites add marble bathrooms, separate living areas with pull-out sofas, kitchenettes, and iPod docks. Room service is available 24/7.Guests have complimentary access to a 24-hour fitness center 3 blocks away, and there’s an eclectic Irish restaurant and pub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9748,
        total_orders: 17319.59,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Hotel, Upscale, Stylish, Italian, Elegant, Exquisite, Classy, Impeccable, Iconic',
        annual_dol: 17657.57,
        description:
            "Sophisticated rooms with 12-foot ceilings, silk wall coverings and chandeliers feature sitting areas and Italian marble bathrooms, as well as 24-hour personal butler service. All rooms come with free Wi-Fi and flat-screen HDTVs. Select suites add kitchens and formal dining rooms.There's a renowned lounge/bar, a grand restaurant and a cognac room. Other amenities include valet parking and a limousine service (both fee), plus a 24-hour exercise room and a steam room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.9708,
        total_orders: 343.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Tacos, Mexican, Tasty, Relaxed, Authentic, Fresh, Hummus, Fine, Great, Falafel',
        annual_dol: 1402.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6911,
        store_longitude: -73.997,
        total_orders: 2198.14,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Wine, Salad, Tacos, Organic, Beer, Clean, Comfortable, Classy, Exquisite, Cool',
        annual_dol: 3143.85,
        description:
            'Listening bar with deep booths & a marble-topped bar serving light bites & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.9884,
        total_orders: 38.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Salad, Italian, Bourbon, Polished, Sleek, Traditional, Creole, Casual',
        annual_dol: 157.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.9566,
        total_orders: 2478.49,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Wine, Salad, Italian, Elegant, Rustic, Romantic, Picturesque, Warm, Classy, Homey',
        annual_dol: 2531.08,
        description:
            'Sophisticated waterfront bar & restaurant crafting contemporary Italian dishes & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7206,
        store_longitude: -73.9953,
        total_orders: 11422.19,
        neighborhood: 'Nolita',
        keywords:
            'Hotel, Rich, Sleek, Rooftop, Chic, French, Great, Exceptional, Perfect, Remarkable',
        annual_dol: 14651.6,
        description:
            "Side.Streamlined rooms feature hardwood floors, large windows and free-standing bathtubs, plus complimentary Wi-Fi, minibars, flat-screen TVs and iPod docks. Some have balconies, and upper floors provide panoramic views. Room service is available.Freebies include a daily happy hour, plus bike and skateboard rentals, and passes to a nearby health club are offered. There's also a laid-back French cafe.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7227,
        store_longitude: -73.9961,
        total_orders: 953.56,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Pizza, Vegetarian, Salad, Gluten Free, Italian, Wine, Vodka, Beer, New-York-Style',
        annual_dol: 994.9,
        description:
            'Neighborhood Italian spot serving reinvented Italian-American classics & pizzas in a dark space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7498,
        store_longitude: -73.8821,
        total_orders: 872.92,
        neighborhood: 'Jackson Heights',
        keywords:
            'Lobster, Garlic Bread, Shrimp Cocktail, Cajun, Tasty, Tequila, Margarita, Wine, Zesty, Rum',
        annual_dol: 1583.61,
        description:
            'Unpretentious nook with bare-bones decor showcasing seafood boils, plates & Cajun menu items.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.728,
        store_longitude: -73.9948,
        total_orders: 612.61,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Burger, Salad, Beer, Wine, Trendy, Cozy, Greek, Fun, American, Awesome',
        annual_dol: 2504.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7714,
        store_longitude: -73.9076,
        total_orders: 27.13,
        neighborhood: 'Astoria/LIC/Ditmars Steinway/Long Island City',
        keywords: 'Sushi, Pizza, Beer, Karaoke, Premium, African, Thai, American, Perfect, Great',
        annual_dol: 110.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7753,
        store_longitude: -73.9839,
        total_orders: 675.54,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Salad, Milkshake, Shrimp Scampi, Avocado Toast, Bolivian, Sweet, Clean, Friendly, Greek',
        annual_dol: 2072.69,
        description:
            'Classic American diner with burgers, salads, entrees & more just a few blocks from Lincoln Center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.751,
        store_longitude: -74.002,
        total_orders: 5103.01,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Cajun, Wine, Salad, Hamburger, Upscale, Delicious, Cozy, Rustic, French',
        annual_dol: 5318.04,
        description:
            'Rustic-chic spot with a comfy rear patio offering Greek-style food, cocktails & house-brewed beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8506,
        store_longitude: -73.8823,
        total_orders: 1176.82,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Hotdog, Coffee, Beer, Wine, Banana Split, Southern, Coleslaw, Seasonal, Retro, Premium',
        annual_dol: 1340.31,
        description:
            'The Bronx Zoo is a zoo within Bronx Park in the Bronx, New York. It is one of the largest zoos in the United States by area and is the largest metropolitan zoo in the United States by area, comprising 265 acres of park lands and naturalistic habitats separated by the Bronx River.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7913,
        store_longitude: -73.7019,
        total_orders: 361.12,
        neighborhood: '',
        keywords: 'Sushi, Salad, Tasty, Coffee, Chinese, Martini, Spicy, Upscale, Delicious, Beer',
        annual_dol: 368.78,
        description:
            'Classic dishes like Peking duck plus a full sushi menu in a refined setting filled with antiques.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6399,
        store_longitude: -73.662,
        total_orders: 159.52,
        neighborhood: '',
        keywords: 'Beer, Casual, Friendly, Chill',
        annual_dol: 354.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -73.9972,
        total_orders: 1141.03,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Chicken Parmigiana, Italian, Margarita, Quaint, Smoky, Romantic, Relaxed, Smooth, Classic',
        annual_dol: 1190.5,
        description:
            'Neighborhood institution with traditional Italian fare in a white tablecloth setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7756,
        store_longitude: -73.5601,
        total_orders: 2787.17,
        neighborhood: '',
        keywords:
            'Cheesecake, Wine, Salad, Luxurious, Stylish, Italian, Hotel, Spicy, Organic, Korean',
        annual_dol: 2966.09,
        description:
            'The Feng Shui-inspired rooms have marble bathrooms (some with whirlpool tubs), plus minifridges, flat-screen TVs and free WiFi. Suites feature balconies, separate living rooms and/or kitchenettes.Outdoor parking is free. Amenities include a spa, an indoor pool and a hot tub, as well as a steam room, a sauna, a gym and a game room. An modern restaurant serves Italian, Asian and fusion dishes and has a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -74.0089,
        total_orders: 1352.52,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Wine, Upscale, Elegant, Modern, Indian, Adventurous, Crisp, Seasonal, Fantastic',
        annual_dol: 1391.67,
        description:
            'High-end Indian kitchen serving upscale versions of classic dishes in a modern, soaring space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7099,
        store_longitude: -73.8483,
        total_orders: 6016.17,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Sushi, Salad, Cheesesteak, Vegan, Italian, Japanese',
        annual_dol: 9276.96,
        description:
            'Corner spot with a casual vibe dishing up classic Greek cuisine, plus burgers & other American eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8325,
        store_longitude: -73.8286,
        total_orders: 253.35,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Burger, Steak, Salad, Mexican, Breakfast Burrito, Italian, French Toast, Spicy, French, American',
        annual_dol: 267.27,
        description:
            'Chrome-accented diner with a diverse menu that ranges from breakfast & desserts to steak & seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8289,
        store_longitude: -73.8243,
        total_orders: 270.06,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Dessert, Trendy, Cuban, Delicious, Classy, Urban, Latin, Casual, Dope, Local',
        annual_dol: 277.14,
        description:
            '"Casa Restaurant opened on December 2015 and is located inside the Heart of Throggs Neck, since then we have been serving quality interpretations of classic Latin dishes. Casa’s interior, designed by its owners, reflects the trendy sensibilities of the surrounding area while also paying tribute to the Bronx\'s present future. The multi-room restaurant offers the choice to observe passers-by in the glass-encased main room, or enjoy the privacy of dining and entertainment in the back room."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6586,
        store_longitude: -73.6448,
        total_orders: 935.72,
        neighborhood: '',
        keywords:
            'Upscale, Beer, Gourmet, Comfortable, Classy, Irish, Perfect, Friendly, Great, Excellent',
        annual_dol: 1002.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5999,
        store_longitude: -73.9119,
        total_orders: 134.6,
        neighborhood: '',
        keywords: 'Southern',
        annual_dol: 160.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7521,
        store_longitude: -73.985,
        total_orders: 18946.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Hotel, Vegetarian, Salad, Avocado Toast, Coffee, Dessert, Rooftop, Spacious, Premium',
        annual_dol: 20061.73,
        description:
            "Broadway theaters and 7 blocks from Grand Central Station.The retro, industrial-chic rooms feature 12-ft ceilings, windows that open, wood floors, mosaic tile bathrooms and original art. All rooms have minibars, Wi-Fi, flat-screen TVs and iPod docks. Room service is offered 24/7.There's a lobby bar, a New American restaurant and a chic rooftop cocktail lounge. There's also a fitness room, and a basement gallery is available for meetings or fashion shoots.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6897,
        store_longitude: -73.8265,
        total_orders: 2112.09,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords: 'Sushi, Burger, Pizza, Delicious, Upscale, Elegant, Sweet, Fresh, Casual',
        annual_dol: 2199.07,
        description:
            '"For more than half a century the Russo Family has built an enduring legacy of excellence in the catering industry in New York City. Today Villa Russo is Queens’ premier event venues for weddings, sweet sixteens, and other events."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.9877,
        total_orders: 2987.59,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Cheesecake, Italian, Tasty, Wine, Upscale, Delicious, Spacious, Classy, Rustic, Fun',
        annual_dol: 3251.27,
        description:
            '"NYC\'s Oldest Italian Restaurant in The Garment District: ARNO Nestled between the theater and garment districts, Arno Ristorante offers Northern Italian cuisine in a traditional, upscale setting for nearly 40 years. Just steps from Madison Square Garden and Broadway theaters, you and your guests can enjoy a delicious meal before or after the event. Join us for lighter fare at lunch time and mingle with business associates at our popular bar after work. Our menu offers classic Italian offerings including Crostini di Mozzarella and Risotto ai Frutti de Mare. Our flexible dining space and great location also make this a popular spot for private parties. We look forward to seeing you at Arno Ristorante."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7687,
        store_longitude: -73.6928,
        total_orders: 129.97,
        neighborhood: '',
        keywords: 'Golfclub, Hotel, Terrific, Diverse',
        annual_dol: 531.26,
        description:
            'Deepdale Golf Club is a private golf club in Manhasset, New York, just east of New York City on Long Island.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5702,
        store_longitude: -74.1089,
        total_orders: 283.63,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords:
            'Pizza, Burger, Steak, Meatball, Italian, Coffee, Dessert, Juicy, Wine, Delicious',
        annual_dol: 509.6,
        description: '"Neighborhood Pizzeria and Sports Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5731,
        store_longitude: -74.1366,
        total_orders: 113.36,
        neighborhood: 'Lighthouse Hill',
        keywords:
            'Cheesecake, Pizza, Salad, Italian, Dessert, Vodka, Savory, Delicious, Greek, Casual',
        annual_dol: 118.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -74.0093,
        total_orders: 300.04,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Sushi, Steak, Salad, Tasty, Vodka, Japanese, Delicious, Bbq, Warm',
        annual_dol: 1226.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7182,
        store_longitude: -73.9976,
        total_orders: 28.9,
        neighborhood: 'Little Italy',
        keywords: 'Pizza, Italian, Salad, Dessert, Wine, Premium, Fun, Awesome, Lively, American',
        annual_dol: 45.73,
        description:
            'Big portions of Italian basics served up in a throwback space lined with murals & memorabilia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7744,
        store_longitude: -73.9541,
        total_orders: 371.01,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Steak, Salad, Dessert, Shrimp Scampi, Italian, Upscale, Caesar Salad, Comfortable, American',
        annual_dol: 394.83,
        description:
            'Casual grill serving a contemporary menu including steak, oysters & fish, plus cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7707,
        store_longitude: -73.9537,
        total_orders: 134.6,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Mexican, Margarita, Warm, Spicy, Delicious, Cozy, Tex-Mex, Crisp, Comfortable, Casual',
        annual_dol: 137.45,
        description:
            'Southwestern fare chased with potent margaritas is the lure at this popular longtime eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7071,
        store_longitude: -74.0079,
        total_orders: 1439.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Tasty, Organic, Warm, Korean, Nachos, Local, French, Seasonal, Contemporary',
        annual_dol: 1502.31,
        description:
            'Warmly lit bar with creative cocktails plus craft beer, happy hour & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8309,
        store_longitude: -73.8262,
        total_orders: 220.81,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Burger, Legendary, Salad, Italian, Cuban, Beer, Karaoke, American, Swiss, Classic',
        annual_dol: 387.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6567,
        store_longitude: -73.6454,
        total_orders: 1340.74,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Flavorful, Salad, Tasty, Beer, Coffee, Dessert, Italian',
        annual_dol: 1534.37,
        description:
            'A variety of sandwiches & burgers can be paired with sides like fries & salads for a pub-style meal.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.59,
        store_longitude: -73.9222,
        total_orders: 65.93,
        neighborhood: 'Gerritsen Beach',
        keywords: 'Beer, Local',
        annual_dol: 161.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.992,
        total_orders: 403.23,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Burger, Steak, Hamburger, Pizza, Salad, Beer, Caesar Wrap, Bbq, Wine, Bourbon',
        annual_dol: 1648.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.993,
        total_orders: 74.44,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Burger, Steak, Pizza, Cajun, Salad, Gourmet, Beer, Wine, Italian, Trendy',
        annual_dol: 304.28,
        description: '"Bar & Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -74.0049,
        total_orders: 442.01,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Cajun, Spicy, Trendy, Rich, Gumbo, Salad, Pizza, Bbq, Friendly, Creole',
        annual_dol: 461.17,
        description:
            '"Filé Gumbo Bar showcases Cajun and Creole classics made bar-side, combining slow-cooked and flavor-rich roux bases with your choice of proteins, simmered in a steam kettle by our chefs for a truly personalized experience."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7045,
        store_longitude: -74.0104,
        total_orders: 3338.7,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Magical, Irish, American, Friendly, Casual',
        annual_dol: 3863.61,
        description:
            'Fantasy-inspired pop-up inside Bavaria Bierhaus offers cocktail classes to create-your-own drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7955,
        store_longitude: -73.936,
        total_orders: 86.49,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Burger, Mexican, Coffee, Beer, Dominican, Tunisian, Peruvian, Wine, Mediterranean, Bruschetta',
        annual_dol: 162.73,
        description:
            '"Creators of Harissa Hot Honey sauce & the famous Harissa Hot Honey Wings! Serving Latin-Mediterranean inspired comfort food, wine & cocktails. BARCHA is a love letter to Harlem, where we live, raise our children (twins!) & run our family business. #Latinx & #NorthAfrican Owned Pickup & Delivery available on our website!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -74.0104,
        total_orders: 122.68,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Organic, Wine, Coffee, Wholesome, Natural, Modern, Casual, Greek, Perfect',
        annual_dol: 501.47,
        description:
            'Counter-order spot for daytime Greek dishes & baked goods, with a communal table & loungey seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -73.9212,
        total_orders: 377.62,
        neighborhood: 'Bushwick',
        keywords: 'Fun, Excellent, Sophisticated, Global, Signature, Unique',
        annual_dol: 1543.57,
        description:
            '"Enjoy an immersive, theatrical experience highlighting the stories of each moon goddess through high level nouveau circus-based acts and multisensory interactive installations. The featured goddess changes with every moon phase, eventually depicting all 13 mythological moon goddesses. Acts will include unique aerial performances and sculptures as well as a mesmerizing balance act that was performed at Cirque du Soleil for 10 years, originally created by Rigolo the family of the owners of MoonRise. An extensive mixology program will feature cocktails based around the 13 goddesses and the aromatic herbs associated with each for you to enjoy. Prepare to be taken on an astrological journey!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 189.38,
        neighborhood: 'Bushwick',
        keywords:
            'Hotdog, Burger, Tacos, Mexican, Beer, Vegetarian, Margarita, Venezuelan, Flavorful, Chinese',
        annual_dol: 319.64,
        description:
            'Hip, casual eatery serving Venezuelan street food such as yuca fries, burgers & hot dogs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6946,
        store_longitude: -73.9063,
        total_orders: 34.83,
        neighborhood: 'Bushwick/Ridgewood',
        keywords:
            'Lobster, Lobster Roll, Salad, Mexican, Mimosa, Nachos, Meatball, Savory, Caesar Salad, Latin',
        annual_dol: 142.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7315,
        store_longitude: -74.0024,
        total_orders: 1606.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Whiskey, Vegetarian, Tasty, Rich, Cozy, Traditional, Warm, Creamy, Artisanal, Indian',
        annual_dol: 1679.96,
        description:
            'Cozy, red-hued outpost offering an array of British colonial Indian staples & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -74.0028,
        total_orders: 1608.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Salad, Italian, Green Papaya Salad, Upscale, Thai, Spanish, Romantic, Elegant, American',
        annual_dol: 1750.15,
        description:
            "This upscale American restaurant is known to be one of the city's most romantic.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7322,
        store_longitude: -74.0033,
        total_orders: 4970.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Flavorful, Delicious, Warm, Stylish, Impeccable, Wine, Cozy, Fun, American, Timeless',
        annual_dol: 5253.66,
        description:
            '"The Noortwyck is a new restaurant from partners Chef Andy Quinn and Sommelier Cedric Nicaise who met at Eleven Madison Park. This West Village restaurant has a timeless, new American menu focusing on New York producers and purveyors alongside a vast and fun wine list."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7406,
        store_longitude: -74.0084,
        total_orders: 319.38,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Hamburger, Steak, Vegetarian, Beer, American, Dessert, Wine, Casual, Classic',
        annual_dol: 489.74,
        description:
            '"Best Burgers + Best Cocktails. Open everyday. Located near The Whitney Museum, the High Line and Little Island."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.721,
        store_longitude: -73.6883,
        total_orders: 106.46,
        neighborhood: '',
        keywords: 'Sushi, Salad, Dessert, Japanese, Wine, Thai, Chinese, Sashimi, Asian, Fusion',
        annual_dol: 201.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -73.9922,
        total_orders: 2171.91,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Steak, Salad, Pizza, Coffee, Beer, Wine, French, Caesar Salad, American',
        annual_dol: 2217.99,
        description:
            'A roof deck with a view of Madison Square Garden across the street distinguishes this bar & cafe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.79,
        store_longitude: -73.9749,
        total_orders: 515.07,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Salad, Pizza, Gluten Free, Italian, Beer, Ale, Delicious, Warm, Elegant',
        annual_dol: 526,
        description:
            'Quaint place with a warm vibe offering a versatile menu of Northern Italian food & baked goods.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6732,
        store_longitude: -73.9898,
        total_orders: 1812.12,
        neighborhood: 'Gowanus',
        keywords: 'Wine, Whiskey, Beer, Martini, Coffee, Gin, Salad, Bourbon, Hotel, Delicious',
        annual_dol: 1812.12,
        description:
            'Tranquil, late-night neighborhood haunt for classic cocktails, light fare & TV sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6758,
        store_longitude: -73.9839,
        total_orders: 11.83,
        neighborhood: 'Park Slope',
        keywords:
            'Whiskey, Beer, Coffee, Organic, Delicious, Sweet, Paloma, Seasonal, Wonderful, Great',
        annual_dol: 38.69,
        description:
            '"We are a women and minority owned Bar, Bakery and Event Space. Serving in-house baked goods and small bites inspired by our Trinidadian and Cambodian heritages. We offer free Wi-Fi for those that need to switch up the WFH scenery. We host monthly dinner parties that showcase and honor our heritages. Most of our beers, wines and spirits are either local, minority or women owned. Swing by for coffee, cookies, cakes, cocktails and vybzzzzz."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7049,
        store_longitude: -73.9343,
        total_orders: 1147.09,
        neighborhood: 'East Williamsburg',
        keywords:
            'Pizza, Italian, American, Vietnamese, Wine, Funky, Bloody Mary, Chic, Casual, Sweet',
        annual_dol: 1250.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.707,
        store_longitude: -73.9331,
        total_orders: 1112.19,
        neighborhood: 'East Williamsburg',
        keywords: 'Pizza, Casual, Cool, Rare, Global',
        annual_dol: 1239.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6575,
        store_longitude: -73.6436,
        total_orders: 393.06,
        neighborhood: '',
        keywords: 'Burger, Steak, Cajun, Beer, Ale, American, Irish, Contemporary, Modern, Cozy',
        annual_dol: 438.8,
        description:
            'Standard bar & grill with burgers & TVs, plus a bottomless-drink brunch & ample outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5696,
        store_longitude: -74.1085,
        total_orders: 276.37,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords: 'Beer, Margarita, Delicious, Crisp, Lively, American, Casual, Classic, Local',
        annual_dol: 880.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5916,
        store_longitude: -74.1011,
        total_orders: 96.11,
        neighborhood: 'Todt Hill',
        keywords: 'Steak, Pizza, American, Ale, Irish, Trendy, Filet Mignon, Local, Casual, Great',
        annual_dol: 156.89,
        description: '"Local sports bar for the community"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.724,
        store_longitude: -73.5412,
        total_orders: 361.33,
        neighborhood: '',
        keywords: 'Whiskey, Beer, Casual',
        annual_dol: 457.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7464,
        store_longitude: -73.9178,
        total_orders: 775.69,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Chocolate Cake, Pizza, Wine, Salad, Whiskey, Beer, Tasty, Delicious, Irish, Intimate',
        annual_dol: 796.55,
        description:
            'Intimate locale offering wine, cocktails & draft beer, plus small plates, pizza & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9934,
        total_orders: 298.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Lobster, Salad, Shrimp Cocktail, Lobster Bisque, Wine, French Onion Soup, French, Tasty, Caesar Salad',
        annual_dol: 696.11,
        description:
            'Steaks & a 400-label wine list are the focus of this 2-story chophouse with clubby decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.9969,
        total_orders: 289.7,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Italian, Meatball, Prosecco, Bruschetta, Thai, Aperol, Homemade, Cozy, Casual',
        annual_dol: 1184.2,
        description:
            'Family-owned Italian eatery offering Neapolitan pizzas & pasta dishes in a cozy atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7419,
        store_longitude: -73.5944,
        total_orders: 349.33,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Margarita, Delicious, Casual, Sangria, Amazing, Great',
        annual_dol: 1316.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.8966,
        total_orders: 537.41,
        neighborhood: 'Jackson Heights/Woodside',
        keywords:
            'Beer, Gluten Free, Fun, Awesome, Premium, Retro, Cool, Wonderful, Classic, Excellent',
        annual_dol: 548.82,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8289,
        store_longitude: -73.9285,
        total_orders: 819.46,
        neighborhood: 'Concourse',
        keywords:
            'Steak, Lobster, Lobster Tail, Salad, Wine, Bbq, Japanese, Upscale, American, Savory',
        annual_dol: 933.31,
        description:
            'Year-round steakhouse in Yankee Stadium that draws Bronx Bombers fans with baseball-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.8545,
        total_orders: 266.41,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Italian, Salad, Pizza, Wine, Korean, Elegant, Quaint, French, Classy, Friendly',
        annual_dol: 277.96,
        description:
            'Family-owned Italian restaurant serving traditional fare in a homey, white-tablecloth setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7032,
        store_longitude: -73.9258,
        total_orders: 1954.44,
        neighborhood: 'Bushwick',
        keywords:
            'Vegetarian, Salad, Dessert, Coffee, Trendy, French, Delicious, Cozy, Polished, Friendly',
        annual_dol: 1995.91,
        description:
            'Modern French bistro serving up classic fare in a chic, vintagey space with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6988,
        store_longitude: -73.9172,
        total_orders: 1449.16,
        neighborhood: 'Bushwick',
        keywords:
            'Whiskey, Beer, Wine, Gluten Free, Delicious, Organic, Friendly, Fun, Cool, Casual',
        annual_dol: 1511.99,
        description:
            'Chill, narrow hangout with a jukebox & pinball offering cocktails & draft beer, plus acoustic music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7034,
        store_longitude: -74.0113,
        total_orders: 10574.27,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Pizza, Whiskey, Salad, Hotel, Upscale, Rustic, Spanish, French, Premium',
        annual_dol: 11650.95,
        description:
            'Renovated historic tavern with a George Washington link offering pub eats & live music on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7196,
        store_longitude: -74.01,
        total_orders: 4541.2,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Burger, Pizza, Italian, Salad, Beer, Coffee, Wine, Upscale, American',
        annual_dol: 4896.72,
        description:
            'Buzzy Robert De Niro-owned mainstay for innovative New American cuisine & a stellar wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7763,
        store_longitude: -73.9527,
        total_orders: 1728.13,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Cheesesteak, Pizza, Wine, Salad, Beer, Irish, Delicious, Swiss, Karaoke',
        annual_dol: 1764.79,
        description:
            'Neighborhood institution known for its social scene, cocktails & standard pub fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.9572,
        total_orders: 538.88,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Wine, Italian, Delicious, Elegant, Martini, Cozy, Polished, Homemade, Fabulous',
        annual_dol: 590.67,
        description:
            'Sophisticated Northern Italian cuisine in an elegant yet cozy eatery with rustic-chic accents.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6352,
        store_longitude: -73.9612,
        total_orders: 588.49,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Burger, Tacos, Gluten Free, Beer, Korean, Wine, Nachos, German, Delicious, Gin',
        annual_dol: 912.01,
        description:
            'Cozy gastropub serving an eclectic menu (lasagna & Korean sandwiches), plus exotic burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7003,
        store_longitude: -73.9159,
        total_orders: 497.52,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Tequila, Pizza, Rum, Bbq, Local, Eclectic, Casual, Quirky, Fun',
        annual_dol: 634.45,
        description:
            'Convivial corner tavern with an edgy vibe that presents a no-nonsense menu of beer & booze.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8203,
        store_longitude: -73.8178,
        total_orders: 1148.68,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Steak, Burger, Chocolate Cake, Dessert, Wine, Upscale, French, American, Latin, Legendary',
        annual_dol: 1748.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8273,
        store_longitude: -73.9253,
        total_orders: 2263.56,
        neighborhood: 'Concourse',
        keywords:
            'Garlic Bread, Hotdog, Steak, Cheesesteak, Italian, Beer, Flavorful, Wine, Nachos, American',
        annual_dol: 2476.07,
        description:
            'Beer & pub grub fuel the passions of Yankee fans at this venerable local bar near the stadium gates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7862,
        store_longitude: -73.9779,
        total_orders: 50.32,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Mexican, Steak, Salad, Organic, Delicious, Local, Casual, Fresh, Great',
        annual_dol: 297.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7102,
        store_longitude: -73.8483,
        total_orders: 7.97,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Peruvian, Salad, Sangria, Traditional, Sweet, Casual, Exquisite, Asian, Colorful, Authentic',
        annual_dol: 32.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7675,
        store_longitude: -73.9183,
        total_orders: 1446.41,
        neighborhood: 'Astoria/Long Island City',
        keywords:
            'Magical, Wine, Delicious, Elegant, Cozy, Trendy, Karaoke, Intimate, Friendly, Awesome',
        annual_dol: 1591.32,
        description:
            'Cozy Mediterranean-style bar with cocktails, hookah, weekend DJs, karaoke parties & bar grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6758,
        store_longitude: -73.9806,
        total_orders: 746.2,
        neighborhood: 'Park Slope',
        keywords: 'Mexican, Burger, Steak, Tequila, Salad, Beer, Nachos, Margarita, Wine, Fajitas',
        annual_dol: 843.3,
        description:
            'This small-chain outpost boasts a menu of Mexican favorites, kitschy decor & a sizable tequila menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7219,
        store_longitude: -73.9506,
        total_orders: 1013.12,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Shrimp Cocktail, Dessert, Beer, Wine, Caesar Salad, American, Vintage',
        annual_dol: 3556.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8602,
        store_longitude: -73.8908,
        total_orders: 60.76,
        neighborhood: 'Belmont/West Bronx',
        keywords: 'Mexican, Tacos, Burritos, Healthy, Local, Casual, Great',
        annual_dol: 248.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6715,
        store_longitude: -73.9907,
        total_orders: 87.55,
        neighborhood: 'Gowanus',
        keywords: 'American, Danish, Heritage, Foreign',
        annual_dol: 252.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.734,
        store_longitude: -74.0061,
        total_orders: 306.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Beef Stroganoff, Coffee, Italian, Beer, Organic, American, Sustainable, Premium, Local',
        annual_dol: 1251.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7461,
        store_longitude: -73.9151,
        total_orders: 768.31,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: 'Steak, Whiskey, Wine, Beer, Tasty, Gin, Irish, Premium, Rum, Fried Chicken',
        annual_dol: 802.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8258,
        store_longitude: -73.9198,
        total_orders: 145.09,
        neighborhood: 'Concourse Village/West Bronx',
        keywords:
            'Lobster, Salad, Cajun, Lobster Bisque, Caesar Salad, Clam Chowder, Fun, Perfect, Fiery, Bold',
        annual_dol: 593.07,
        description:
            '"Established in 2020. Born in the South where a feast means getting messy with friends, the seafood boil has been bringing people together for ages. At Hook & Reel our signature boil forks over bold flavors with a genuine, down-home feel. But eating seafood is only part of the fun. Everything tastes better elbow-to-elbow with friends, swapping stories over music, getting down and dirty and eating with your hands - all with a cold drink at the ready, \'cause that spice ain\'t no joke. We set out to share the saucy spirit of the boil with folks everywhere - testing our very own recipes, inspired by fiery Cajun home cooking. The Hook & Reel seafood boil dishes up the "reel" deal - the full backyard boil experience and flavor, with a twist."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9512,
        total_orders: 281.42,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Italian, Pizza, Wine, Coffee, Beer, Modern, Seasonal, Intimate, Sophisticated, Awesome',
        annual_dol: 321.01,
        description:
            'Sophisticated Italian dishes & select wines are presented in an intimate white-tablecloth setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.705,
        store_longitude: -73.9062,
        total_orders: 468.26,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Vegan, Stylish, Friendly, Casual, Classic, American, Great, Tempura',
        annual_dol: 488.56,
        description:
            '"Full service restaurant and tavern. Northern European and Vegan Cuisine. Horror themed. Female and veteran owned and operated."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6729,
        store_longitude: -73.9768,
        total_orders: 404.66,
        neighborhood: 'Park Slope',
        keywords:
            'Lasagna, Pizza, Italian, Meatball, Beer, Dessert, Bbq, Homemade, Comfortable, Casual',
        annual_dol: 440.37,
        description:
            'Comfortable trattoria serving Italian dishes & New York-style pizza, plus a selection of wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6694,
        store_longitude: -73.9863,
        total_orders: 563.42,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Delicious, Friendly, Awesome, Great, Classic, Casual, Local',
        annual_dol: 600.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.9099,
        total_orders: 565.87,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Salad, Beer, Delicious, Juicy, Bourbon, Bbq, Irish, Rum',
        annual_dol: 712.47,
        description:
            'Neighborhood saloon with pub grub & American entrees, plus brunch & happy hour deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7368,
        store_longitude: -74.0012,
        total_orders: 2915.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Pizza, Vegan, Salad, Applejack, Coffee, Beer, Roast Beef, Whiskey, Hotel',
        annual_dol: 10982.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5732,
        store_longitude: -73.9805,
        total_orders: 351.87,
        neighborhood: 'Coney Island',
        keywords:
            'Hotdog, Roast Beef, American, Jamaican, French, Southern, Rooftop, Seasonal, Traditional, Perfect',
        annual_dol: 453.75,
        description:
            'Enduring diner dishing up breakfast & lunch classics in an old-fashioned atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7701,
        store_longitude: -73.9678,
        total_orders: 614.27,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Wine, Phenomenal, American, Traditional, Heritage, Wonderful, Cultural, Nostalgic',
        annual_dol: 627.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7699,
        store_longitude: -73.9543,
        total_orders: 311.42,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Mexican, Tacos, Vegan, Coffee, Tex-Mex, Thai, Perfect, Awesome, Amazing, Casual',
        annual_dol: 331.88,
        description: '"Mezcaleria serving small bites and an array of Mezcals and Tequilas"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.8577,
        total_orders: 652.1,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Vegetarian, Salad, Wine, Coffee, Beer, Delicious, Friendly, Legendary, Bruschetta, Perfect',
        annual_dol: 798.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7037,
        store_longitude: -74.0129,
        total_orders: 4608.77,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Beer, American, Irish, Friendly, Reuben, Casual, Traditional, Great, Signature',
        annual_dol: 4706.56,
        description: 'This laid-back, wood-paneled gathering place serves Irish pub grub & pints.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7227,
        store_longitude: -74.0033,
        total_orders: 10005.7,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Salad, Coffee, Upscale, French, Cozy, Modern, American, Thai, Casual, Vintage',
        annual_dol: 13267.29,
        description:
            "A cozy room with 70's vibes & a menu featuring an array of modern American snacks, fare, & drinks.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7729,
        store_longitude: -73.8739,
        total_orders: 293.37,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Wine, Salad, Coffee, Delicious, Korean, Spacious, Healthy, American, Premium, Fresh',
        annual_dol: 1199.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9177,
        total_orders: 10.82,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Pinacolada, Local, Fried Rice, Casual, Great',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6789,
        store_longitude: -73.9978,
        total_orders: 3181.72,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Beer, Wine, Salad, Coffee, Cozy, Buffalo Chicken Salad, Friendly, American, Vintage',
        annual_dol: 3249.23,
        description:
            'Casual bar/restaurant serving draft beer, cocktails & hearty pub fare, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7675,
        store_longitude: -73.8665,
        total_orders: 693.96,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Hotel, Sleek, Delicious, Relaxed, Modern, American, Perfect, Friendly',
        annual_dol: 724.05,
        description:
            "Modern rooms feature flat-screen TVs, free Wi-Fi and sitting areas. Suites add separate living areas; some include whirlpool tubs and/or terraces.A 24-hour airport shuttle is complimentary. Other amenities include an indoor pool, a sauna and a fitness center. There's also a sleek bar. Parking is available (surcharge).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7519,
        store_longitude: -73.9478,
        total_orders: 1177.35,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Hotel, Rooftop, Amazing, American, Funky, Great',
        annual_dol: 1524.55,
        description:
            "A Manhattan-facing wall of windows gives every room a floor-to-ceiling skyline view. The rooms' slick industrial-chic decor has jazz-themed accents; all have rainfall showerheads, minibars, free Wi-Fi, LCD TVs and iPod docks.An underground restaurant/bar serves all 3 meals; a rooftop lounge serves cocktails at night. Amenities include a 24-hour business center, in-room fitness or spa sessions, free bicycle rentals and an hourly shuttle into Manhattan.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5867,
        store_longitude: -73.7276,
        total_orders: 106.06,
        neighborhood: '',
        keywords: 'Burger, Tacos, Fun, French, Terrific',
        annual_dol: 192.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -73.9913,
        total_orders: 5287.71,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Tequila, Wine, Beer, Bourbon, Salad, Irish, Bbq, Classic, British, Quaint',
        annual_dol: 5520.23,
        description:
            'Easygoing spot offering classic pub grub & a full bar in a whimsical setting with Victorian details.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -73.988,
        total_orders: 921.04,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Upscale, Vintage, Salad, Delicious, Premium, Great, Casual, Unique',
        annual_dol: 3233.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9912,
        total_orders: 342.96,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Tacos, Salad, Gluten Free, Margarita, Italian, Karaoke, Yummy, American, Irish, Friendly',
        annual_dol: 1401.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7923,
        store_longitude: -73.9714,
        total_orders: 552.43,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Sushi, Wine, Beer, Japanese, Sashimi, Tranquil, Casual, Elegant, Comfortable, Traditional',
        annual_dol: 678.67,
        description:
            'Modern, tranquil Japanese eatery offering sushi, sashimi & traditional cooked dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.771,
        total_orders: 80.94,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Cajun, Coffee, Dessert, Bourbon, American, Premium, Creole, Warm, Casual',
        annual_dol: 330.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6338,
        store_longitude: -74.0266,
        total_orders: 27.68,
        neighborhood: 'Bay Ridge',
        keywords:
            'Thai, Spicy, Coffee, Delicious, Asian, Traditional, Pad Thai, Authentic, Red Curry, Fresh',
        annual_dol: 113.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9706,
        total_orders: 26.24,
        neighborhood: 'Lenox Hill',
        keywords:
            'Pizza, Gourmet, Coffee, Wine, Beer, Scotch, Chinese, Mediterranean, Healthy, Casual',
        annual_dol: 214.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7578,
        store_longitude: -73.9875,
        total_orders: 1720.36,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Beer, Juicy, Bbq, Fun, Lively, Premium, Amazing, Wonderful, Great',
        annual_dol: 1756.86,
        description:
            'Huge portions of pub grub & flat-screen TV sports in a stadium-themed eatery inside Bowlmor Lanes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6979,
        store_longitude: -73.9848,
        total_orders: 193.99,
        neighborhood: 'Brooklyn Heights/Downtown Brooklyn/Bridge Plaza',
        keywords: 'Burger, Beer, Rich, Spicy, Trendy, Upscale, Bbq, African, Caribbean, Nigerian',
        annual_dol: 213.05,
        description:
            'Relaxed, upscale locale for Nigerian, Caribbean & American cuisine, plus distinctive cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6945,
        store_longitude: -73.9859,
        total_orders: 148.84,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Pizza, Shrimp Cocktail, Salad, Italian, Hawaiian, Delicious, Hotel, Fresh, Perfect, Great',
        annual_dol: 532.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.981,
        total_orders: 274.4,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Vodka, Pizza, Italian, Wine, Vegan, Gluten Free, Meatball, Rich, Delicious, Casual',
        annual_dol: 1121.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7795,
        store_longitude: -73.9503,
        total_orders: 78.63,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Mexican, Fun, Casual, Vibrant, Fresh, Local, Great, Sangria',
        annual_dol: 101.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.9857,
        total_orders: 835.22,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Karaoke, Wine, Trendy, Korean, Japanese, American, Delicious, Asian, Seasonal, Modern',
        annual_dol: 871.43,
        description:
            'Bi-level, sleek space with a full bar & private karaoke rooms also offering Korean fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7684,
        store_longitude: -73.9824,
        total_orders: 15751.22,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Cheesecake, Luxurious, Dessert, Coffee, Hotel, Spacious, Premium, Crisp, Perfect, American',
        annual_dol: 16085.43,
        description:
            "The contemporary rooms feature floor-to-ceiling windows with skyline views, as well as flat-screens, stereos and Wi-Fi (fee). They also include desks, soaking tubs and marble bathrooms. Some have Central Park or river views, and luxe suites add separate living rooms and/or Nespresso machines.Perks include a chic modern American eatery, an airy lobby lounge serving snacks, and a specialty cocktail bar. There's also a fitness center, an indoor pool and a posh spa.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9949,
        total_orders: 1436.08,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Wine, Gluten Free, Dessert, Spanish, Delicious, Sweet, Rustic, Amazing, Authentic',
        annual_dol: 1498.34,
        description: 'An array of paellas is the main draw at this rustic NoLita restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -74,
        total_orders: 261.79,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Chinese, Coffee, Peking Duck, American, Modern, Spicy, Filet Mignon, Local, Classic',
        annual_dol: 475.75,
        description:
            'Modern restaurant serving sophisticated Chinese dishes in a spacious, informal dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7262,
        store_longitude: -73.9964,
        total_orders: 226.47,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Burritos, Vegan, Organic, Local, Casual, Chic, Premium',
        annual_dol: 987.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6174,
        store_longitude: -73.9105,
        total_orders: 5.82,
        neighborhood: 'Mill Basin',
        keywords:
            'Burger, Tacos, Mexican, Sushi, Nachos, Gluten Free, Dessert, Japanese, Ale, French',
        annual_dol: 23.8,
        description: '"Authentic Mexican Taqueria"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6574,
        store_longitude: -73.6419,
        total_orders: 15.32,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Coffee, Mediterranean, Grilled Cheese Sandwich, Caesar Salad, French, American, Godfather',
        annual_dol: 62.64,
        description:
            'Classic, old-school diner offering typical American grub including omelettes, salads & burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.9802,
        total_orders: 2071.21,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Burger, Sushi, Chocolate Cake, Salad, Shrimp Cocktail, Hamburger, Vegetarian, Champagne',
        annual_dol: 2383.41,
        description:
            'Upscale, seafood-focused New American with cocktails & live music in a huge, bustling space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.9892,
        total_orders: 56.44,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Pizza, Mexican, Eclectic, Casual',
        annual_dol: 230.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.687,
        store_longitude: -73.8218,
        total_orders: 344.12,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords: 'Chinese, Asian, Caribbean, Fusion, Indian, Casual, Great',
        annual_dol: 391.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.5895,
        total_orders: 2179.19,
        neighborhood: '',
        keywords: 'Coffee, Premium',
        annual_dol: 8210.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8188,
        store_longitude: -73.8169,
        total_orders: 173.52,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Steak, Sushi, Chinese, Japanese, Pungent, Asian, American, Local, Casual, Sweet',
        annual_dol: 184.66,
        description:
            'Comfy, low-key restaurant & sushi bar offering popular Chinese, Japanese & Southeast Asian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8335,
        store_longitude: -73.8166,
        total_orders: 10.84,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Salad, Hotel',
        annual_dol: 16.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6897,
        store_longitude: -73.8275,
        total_orders: 1870.57,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords:
            'Burger, Steak, Delicious, Fried Rice, Fabulous, Caribbean, Great, Indian, Creative, Casual',
        annual_dol: 2053.61,
        description:
            '"West Indian Lounge in Richmond Hill,Queens •21+ Only w/valid ID •Hours: 6pm-3am(Closed Tuesday’s) •Dress Code Enforced •We Do Not Take Reservations"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -74.005,
        total_orders: 3040.54,
        neighborhood: '',
        keywords:
            'Steak, Burger, Delicious, Japanese, American, Classic, Exotic, Fresh, Amazing, Sangria',
        annual_dol: 3105.05,
        description:
            '"Classic Car Club is NYC’s one-of-a-kind members-only Club for those who believe in a life best lived … and lived loudly. Come race with us, rally around the world, indulge in wondrous culinary feats in our top-of-the-line restaurant or soak up the sun and sangria on our beautiful river terrace. Unlimited experience. Limited status."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -73.9969,
        total_orders: 443.67,
        neighborhood: 'Little Italy',
        keywords:
            'Bbq, Gluten Free, Dessert, Beer, Wine, Chinese, Delicious, Malaysian, Sweet, Contemporary',
        annual_dol: 592.53,
        description:
            'Roomy, contemporary spot featuring tabletop cook-your-own Chinese hot pot & BBQ, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6109,
        store_longitude: -73.9628,
        total_orders: 25.86,
        neighborhood: 'Midwood',
        keywords:
            'Vegetarian, Salad, Coffee, Gourmet, Rustic, Nachos, Chili Chicken, Comfortable, Bruschetta, Casual',
        annual_dol: 105.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7068,
        store_longitude: -73.923,
        total_orders: 1120.72,
        neighborhood: 'Bushwick',
        keywords: 'Legendary, Luxurious, Mysterious',
        annual_dol: 1445.2,
        description:
            '"The luxurious new venue from Company XIV and the home of COCKTAIL MAGIQUE, an evening of intoxicating illusions. Company XIV is thrilled to present a rarefied spectacle of supernatural, luminescent libations and revelry such as the world has never seen. Behold a phantasmagoria of miracles of tantalizing charm and splendor unfolding in your midst at COCKTAIL MAGIQUE, a choreographed mixological adventure of unprecedented majesty. Secret archives of vaudevillian arcana effloresce anew in regal brilliance at our luxurious new sanctum in the beating heart of Bushwick, Brooklyn. Alcoholic alchemy up close and personal, spritzed with the gallant wit and joie de vivre of the Belle Epoque."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7745,
        store_longitude: -73.8725,
        total_orders: 4234.91,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Burger, Tacos, Pizza, Mexican, Salad, Margarita, Coffee, Wine, Spanish, American',
        annual_dol: 14867.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -73.8451,
        total_orders: 9066.32,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Beer, Tacos, Whiskey, Cajun, Vegetarian, Upscale, Delicious, American, Irish, Fun',
        annual_dol: 9572.59,
        description:
            'Gastropub offering craft brews & lots of whiskey options, plus an upscale bar menu & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9459,
        total_orders: 703.86,
        neighborhood: 'Greenpoint',
        keywords: 'Karaoke, Beer, Wine, Fun, Great',
        annual_dol: 2651.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7489,
        store_longitude: -73.9754,
        total_orders: 74.18,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Tasty, Gluten Free, Homemade, Turkish, Fun, Premium, American, Traditional, Great',
        annual_dol: 303.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.77,
        store_longitude: -73.9577,
        total_orders: 68.2,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Wine, Beer, Cajun, Martini, Coffee, Ale, Margarita, Salad, Thai, Hotel',
        annual_dol: 238.86,
        description:
            'Creative cocktails & craft beer served with elevated sandwiches & sharable plates in a chill space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7072,
        store_longitude: -73.943,
        total_orders: 241.71,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Coffee, Salad, Dessert, Donut, Rich, Delicious, Chinese, Cozy, Korean, American',
        annual_dol: 696.2,
        description:
            'Laid-back neighborhood spot serving inventive twists on Taiwanese classics for brunch & dinner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8395,
        store_longitude: -73.7834,
        total_orders: 4190.28,
        neighborhood: 'City Island',
        keywords:
            'Lobster Roll, Lobster, Salad, Cornbread, Shrimp Cocktail, Coffee, Fruity, Italian, Delicious, Yummy',
        annual_dol: 4333.14,
        description:
            'Bustling restaurant supplying a wide range of seafood & fruity cocktails amid ocean-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6837,
        store_longitude: -73.9294,
        total_orders: 2319.55,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords: 'Burger, Sushi, Salad, Japanese, Wine, Spicy, Swiss, Trendy, Asian, Ramen',
        annual_dol: 8123.89,
        description:
            'Cozy restaurant & bar serving traditional Japanese cuisine in a brick-walled dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.85,
        store_longitude: -73.7877,
        total_orders: 118.19,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Steak, Dessert, Delicious, Organic, Fancy, Latin, Fresh, American',
        annual_dol: 418.58,
        description:
            '"Caliente\'s is a sports themed restaurant and bar. Our menu consist of handcrafted cocktails using mostly organic fruits and juices . All of our seafood is wild caught and never farmed. We are committed to bringing you only the best!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7453,
        store_longitude: -73.9276,
        total_orders: 380.46,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Burger, Beer, Champagne, Upscale, Western, Casual, Lit',
        annual_dol: 388.53,
        description:
            "\"NYC's largest upscale gentleman's club. 3 stages, 2 floors, 1 night you won't forget. VIP lounges and champagne rooms on request.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -73.9896,
        total_orders: 817.28,
        neighborhood: 'East Village/Bowery',
        keywords: 'Champagne, Gin, Rum, Intimate, Cointreau, Casual, Classic',
        annual_dol: 940.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5965,
        store_longitude: -73.976,
        total_orders: 169.47,
        neighborhood: 'Gravesend',
        keywords:
            'Steak, Sushi, Pizza, Salad, Dessert, Wine, Cosmopolitan, Delicious, Bbq, Vietnamese',
        annual_dol: 704.24,
        description:
            '"Inspired by Asian, Mediterranean and Middle Eastern cuisine, Silk Restaurant & Lounge offers an eclectic mix of authentic flavours with one thing in common: fresh, high quality ingredients. The menu includes simple, yet skillfully prepared dishes that allow diners to indulge and savour according to taste. In keeping with its cosmopolitan character, Silk is the perfect combination of international taste and local flavour; farm to table ingredients and locally caught seafood are daily menu staples. The addictive cocktail menu changes seasonally to feature local flavours like sorrel and soursop alongside a carefully curated international wine list."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9872,
        total_orders: 617.92,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Ale, Bbq, Nachos, Upscale, Comfortable, Sweet, Casual, Cool',
        annual_dol: 755.87,
        description:
            'A lively space featuring a young crowd, pub grub, drink specials and plenty of games on the tube.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9882,
        total_orders: 172.22,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Steak, Hamburger, Salad, Italian, Wine, Tasty, Beer, French Toast, French, American',
        annual_dol: 703.99,
        description:
            '"Escape to a heated, outdoor garden oasis in the heart of the East Village for an al fresco dining experience that blends an eclectic Mexican-Brazilian fusion cuisine. Join us for brunch and dinner, fresh craft cocktails and authentic hookah prepared with the finest molasses, fruit juice, and spices."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.8331,
        total_orders: 489.34,
        neighborhood: 'Flushing',
        keywords: 'Lobster, Pizza, Chinese, Peking Duck, Hotel, Asian, Fusion, Fun, Rooftop, Local',
        annual_dol: 1713.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.8339,
        total_orders: 926.16,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Hotel, Pizza, Upscale, Coffee, Rooftop, French, Awesome, Classic, Great, Relaxed',
        annual_dol: 1015.14,
        description:
            "The relaxed rooms come with separate living and sleeping areas, 42-inch flat-screen TVs and free WiFi. They also include coffeemakers and minifridges, and upgraded rooms add pull-out sofas and may have separate bedrooms.An airport shuttle is complimentary. Other amenities include an indoor pool and a fitness center. There's also a coffee and cocktails bar, a 24/7 menu and market and a business center, plus meeting space and paid parking. Breakfast is extra.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7611,
        store_longitude: -73.8302,
        total_orders: 494.4,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Karaoke, Vodka, Chinese, Asian, Classic, Modern, Clean, Urban, Friendly, Tempura',
        annual_dol: 1862.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5856,
        store_longitude: -73.9519,
        total_orders: 76.96,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Japanese, Sushi, Steak, New Zealand, Asian, Contemporary, Fusion, Cozy, Clean, Tempura',
        annual_dol: 314.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6993,
        store_longitude: -73.8308,
        total_orders: 26.14,
        neighborhood: 'Jamaica/Richmond Hill',
        keywords: 'Vegetarian, Wine, Relaxed, Clean, Spanish, Perfect, Bbq, Casual, Great, Chill',
        annual_dol: 106.85,
        description:
            'Vibrant, relaxed hookah bar pairing flavored pipe smokes with cocktails & Asian-Caribbean eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9774,
        total_orders: 172.11,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Italian, Wine, Stylish, Cozy, Classy, Iconic, Casual, Great',
        annual_dol: 763.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5879,
        store_longitude: -73.9606,
        total_orders: 7.71,
        neighborhood: 'Sheepshead Bay/Gravesend',
        keywords: 'Salad, Wine, Russian, Dessert, Beer, Upscale, French, Homemade, Elegant, Homey',
        annual_dol: 31.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8213,
        store_longitude: -73.9507,
        total_orders: 563.31,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Pizza, Vegan, Salad, Hamburger, Italian, Mexican, Vodka, Coffee, Meatball, Chinese',
        annual_dol: 587.74,
        description:
            'Bright, modern neighborhood Italian destination serving upmarket pizza, pasta & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6283,
        store_longitude: -74.0289,
        total_orders: 417.1,
        neighborhood: 'Bay Ridge',
        keywords:
            'Ale, Beer, Chicago-Style, Italian, American, Bbq, Homemade, Traditional, Local, Casual',
        annual_dol: 425.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9673,
        total_orders: 2524.72,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Salad, Tasty, Wine, Nutritious, Spicy, Japanese, Italian, Delicious, Stylish',
        annual_dol: 2848.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6213,
        store_longitude: -74.0322,
        total_orders: 82.63,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Burger, Hamburger, Salad, Mexican, Vodka, Irish, American, Cozy, Homemade, Casual',
        annual_dol: 337.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7077,
        store_longitude: -74.0135,
        total_orders: 967.4,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Steak, Salad, Chicken Caesar Salad, Margarita, Fajitas, Cuban, Swiss, Caesar Salad, American',
        annual_dol: 1096.32,
        description:
            'Low-key eatery serving traditional Mexican eats such as enchiladas, fajitas & breakfast burritos.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9874,
        total_orders: 2599.02,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Sushi, Steak, Wine, Dessert, Beer, Coffee, Ale, Trendy, Spicy, Korean',
        annual_dol: 7695.4,
        description:
            'Trendy spot with woodsy decor specialized in handmade udon noodle plates & other Japanese fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7461,
        store_longitude: -73.9891,
        total_orders: 260.14,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Hotel, Tequila, Stylish, Vodka, Rum, Gin, Rooftop, Chic, Modern, Colorful',
        annual_dol: 490.07,
        description:
            "Featuring colorful accents, the modern, intimate rooms offer free Wi-Fi and 40-inch flat-screen TVs, as well as minibars and coffeemakers. Some provide views of the Empire State Building or One World Trade Center. Upgraded rooms add bunk beds or terraces. Room service is available.Amenities include a laid-back restaurant, a lounge and a rooftop bar with a sundeck. There's also a business center with meeting rooms.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7801,
        store_longitude: -73.9807,
        total_orders: 180.88,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Wine, Dessert, Gluten Free, Mimosa, Beer, Mediterranean, Israeli, Trendy, Shawarma',
        annual_dol: 739.39,
        description:
            'Spacious bistro with an enclosed terrace dishing up traditional Mediterranean fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7285,
        store_longitude: -74.0016,
        total_orders: 538.48,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Tequila, Salad, Wine, Italian, Delicious, Spanish, Mediterranean, French, Crisp',
        annual_dol: 659.08,
        description:
            'European cuisine, with influences from the Basque Country & Vienna, presented in a lush setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.9822,
        total_orders: 231.84,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, French, Wine, Organic, Modern, American, Contemporary, Hotel, Classic, Perfect',
        annual_dol: 877.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7073,
        store_longitude: -73.9223,
        total_orders: 346.96,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Italian, Salad, Vegan, Wine, Dessert, Beer, Delicious, Stylish, Rustic',
        annual_dol: 380.94,
        description:
            'Stylish outfit serving wood-fired pies, salads & unique wines in a garage-like space with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6147,
        store_longitude: -73.9739,
        total_orders: 235.25,
        neighborhood: 'Mapleton/Midwood',
        keywords: 'Japanese, Diverse, Moroccan, Friendly, Great',
        annual_dol: 961.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.6296,
        total_orders: 491.29,
        neighborhood: '',
        keywords:
            'Steak, Cheesecake, Pizza, Gourmet, Italian, Wine, Delicious, Warm, Homemade, Classic',
        annual_dol: 898.33,
        description:
            'Neighborhood Italian standby with a warm vibe plating homemade ravioli & other red-sauce classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.6456,
        total_orders: 419.99,
        neighborhood: '',
        keywords:
            'Steak, Wine, Stylish, Italian, Impeccable, Spacious, Sleek, Outstanding, Classic, Impressive',
        annual_dol: 521.17,
        description: '"To Visit Us For a Tour, Schedule Your Appointment Today!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7836,
        store_longitude: -73.9745,
        total_orders: 1844.17,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Salad, Gluten Free, Italian, Romantic, Warm, Spacious, French, Seasonal, Lively',
        annual_dol: 1887.82,
        description:
            'Large restaurant with romantic lighting serving American fare with subtle Italian influences.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7859,
        store_longitude: -73.9765,
        total_orders: 142.01,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Coffee, Hamburger, Dessert, Organic, French, Mediterranean, Polished, Belgian, Moroccan',
        annual_dol: 580.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.866,
        store_longitude: -73.8302,
        total_orders: 123.47,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Steak, Salad, Hotdog, Coffee, Colombian, Chinese, Latin, American, Casual, Local',
        annual_dol: 504.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7104,
        store_longitude: -73.9337,
        total_orders: 146.49,
        neighborhood: 'East Williamsburg',
        keywords: 'Italian, Beer, Romantic, Perfect, Intimate, Excellent, Casual, Tzatziki',
        annual_dol: 598.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.833,
        store_longitude: -73.6256,
        total_orders: 1266.51,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Hamburger, Salad, Meatball, Italian, Scotch, French, Organic, French Onion Soup',
        annual_dol: 1322.94,
        description:
            'Homey pub serving American fare from bar plates to strip steaks in a sharp setting with patio seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6904,
        store_longitude: -73.5506,
        total_orders: 197.84,
        neighborhood: '',
        keywords:
            'Pizza, Cheesesteak, Steak, Salad, Shrimp Cocktail, Meatball, Italian, Beer, Wine, Tangy',
        annual_dol: 236.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7134,
        store_longitude: -73.9124,
        total_orders: 368.82,
        neighborhood: 'Flushing/Ridgewood/Maspeth',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Hotdog, Salad, Italian, Dessert, Spicy, Delicious',
        annual_dol: 724.06,
        description:
            'Modern, casual restaurant serving wood-fired pizzas & burgers, plus craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7413,
        store_longitude: -73.6628,
        total_orders: 5.09,
        neighborhood: '',
        keywords:
            'Sushi, Wine, Spicy, Delicious, Japanese, Friendly, Asian, Casual, Perfect, Great',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7675,
        store_longitude: -73.9184,
        total_orders: 45.36,
        neighborhood: 'Astoria/Long Island City',
        keywords:
            'Cozy, Friendly, Casual, Brazilian, Polished, Authentic, Bruschetta, Local, Great',
        annual_dol: 185.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7084,
        store_longitude: -73.938,
        total_orders: 974.18,
        neighborhood: 'East Williamsburg',
        keywords: 'Trendy, Classy, Fabulous, Classic, Amazing, Incredible, Casual, Unique',
        annual_dol: 994.85,
        description:
            'Energetic, late-night gay bar with high-tech appointments, basic furnishings & varied performances.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -73.9503,
        total_orders: 479.66,
        neighborhood: 'Greenpoint',
        keywords: 'Elegant, Coffee, Delicious, Impeccable, Premium, Outstanding, Fine',
        annual_dol: 652.96,
        description: '"Celebrate Every Occasion In Style at the Princess Manor."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7117,
        store_longitude: -73.77,
        total_orders: 20.06,
        neighborhood: 'Jamaica/Hollis',
        keywords: 'Jamaican, Caribbean, Hotel, Juicy',
        annual_dol: 81.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -73.9886,
        total_orders: 370.94,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Margarita, Delicious, Tex-Mex, Burritos, Fun, Lively, Polished, Vivid',
        annual_dol: 907.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9858,
        total_orders: 661.65,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Steak, Salad, Korean, Tasty, Polish, Spicy, Trendy, American, Elegant, Refined',
        annual_dol: 690.33,
        description:
            'This intimate locale with late hours serves up refined Korean fare in eco-chic surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7336,
        store_longitude: -73.9932,
        total_orders: 230.83,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Mexican, Steak, Tasty, Salad, Nachos, Shrimp Cocktail, Margarita, Dessert, Wine, Delicious',
        annual_dol: 998.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8008,
        store_longitude: -73.9682,
        total_orders: 2711.13,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Flavorful, Burger, Steak, Salad, Beer, Cosmopolitan, Spicy, Savory, Delicious, Martini',
        annual_dol: 2768.65,
        description:
            'Elegant, rustic-chic tavern presenting seasonally inspired takes on bar fare & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9788,
        total_orders: 3198.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Beer, Martini, Irish, Delicious, Cozy, Refined, American, Traditional, Classic',
        annual_dol: 3473.77,
        description:
            'Cozy spot with a refined vibe & traditional wood decor offering classic pub fare & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9811,
        total_orders: 1203.78,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Burger, Hamburger, Salad, Gourmet, Nachos, Dessert, American, Polished, Red Eye',
        annual_dol: 1255.97,
        description:
            'Old West-style steakhouse chain known for its burgers & chops made from bison.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9779,
        total_orders: 2688.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Pizza, Italian, Salad, Wine, Dessert, Tasty, Martini, Creamy, Organic, American',
        annual_dol: 3007.01,
        description:
            'Midtown eatery offering Italian basics & brick-oven pizza plus a vibrant happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8078,
        store_longitude: -73.946,
        total_orders: 810.26,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Delicious, Savory, Cajun, Gumbo, Fun, American, Authentic, Succulent, Daiquiri, Lit',
        annual_dol: 827.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8809,
        store_longitude: -73.5924,
        total_orders: 680.72,
        neighborhood: '',
        keywords: 'Coffee, Pizza, Wine, Salad, Italian, Beer, American, Quaint, Romantic, Sweet',
        annual_dol: 719.43,
        description:
            'Atmospheric eatery serving inventive Italian fare in an intimate, multiroom space with a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9737,
        total_orders: 108.57,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Lobster Roll, Gourmet, French, Luxurious, Elegant, Modern, Phenomenal, American, Contemporary',
        annual_dol: 110.87,
        description:
            'High-end New American restaurant/caviar specialist in a luxurious Madison Avenue setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -73.9848,
        total_orders: 4170.37,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Green Papaya Salad, Flavorful, Filipino, Thai, Spicy, Bbq, Vietnamese, Ramen, Malaysian',
        annual_dol: 4258.85,
        description:
            'Filipino-Thai-accented SE Asian fare & specialty drinks served in modern, stylish digs with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7214,
        store_longitude: -73.9878,
        total_orders: 445.61,
        neighborhood: 'Lower East Side',
        keywords: 'Fun, Comfortable, Great, Excellent, Creative, Dynamic, Casual',
        annual_dol: 464.93,
        description: '"Inspired by the Lower East Side at the turn of the century."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5931,
        store_longitude: -73.948,
        total_orders: 71.03,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Tasty, Wine, Fruity, Sushi, Delicious, Martini, Trendy, Coffee, Romantic, Italian',
        annual_dol: 308.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -73.8928,
        total_orders: 59.32,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Pizza, Salad, Tasty, Spicy, Mediterranean, Bbq, Premium, Great',
        annual_dol: 242.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7671,
        store_longitude: -73.6019,
        total_orders: 2932.61,
        neighborhood: '',
        keywords:
            'Spectacular, Premium, Bbq, Memorable, Clean, Great, Modern, Asian, Bold, Contemporary',
        annual_dol: 3166.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7806,
        store_longitude: -73.9811,
        total_orders: 1499.59,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Hotel, Wine, Beer, Cozy, Spacious, American, Friendly, Natural',
        annual_dol: 5264.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7767,
        store_longitude: -73.9788,
        total_orders: 54.64,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Meatball, Italian, Shrimp Scampi, Wine, American, Homemade, Classy, Casual, Traditional',
        annual_dol: 62.23,
        description:
            'Old-style Italian restaurant known for its meatballs, traditional dishes & vine-covered back garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8204,
        store_longitude: -73.6439,
        total_orders: 898.72,
        neighborhood: '',
        keywords:
            'Steak, Shrimp Cocktail, Gourmet, Impeccable, Luxurious, Delicious, Elegant, Perfect, Picturesque, Classic',
        annual_dol: 1023.58,
        description:
            '"Modern, Elegant, Picturesque Waterfront Wedding and Event Venue in Roslyn Harbor. Seven acres of gardens, waterways, waterfalls and fountains with our newly renovated mansion featuring artfully-designed banquet rooms and scenic landscaping that express a Jazz Age elegance, re-conceived in a contemporary style that is sleek, sophisticated, and very welcoming. The garden features two locations for outdoor ceremonies and our floating deck for open air entertainment."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8142,
        store_longitude: -73.9442,
        total_orders: 3384.09,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Salad, Beer, Nigerian, Mediterranean, American, Contemporary, Casual, Local, Classic',
        annual_dol: 3976.31,
        description:
            'Arts & performance space hosting all-ages shows & serving drinks & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7483,
        store_longitude: -73.9891,
        total_orders: 1219.77,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Golfclub, Urban, American, Clean, Classic, Cool, Awesome, Local, Casual, Signature',
        annual_dol: 1281.53,
        description:
            '"Five Iron Golf combines golf, entertainment, and good vibes to create a place where everyone can enjoy the game. Featuring TrackMan simulators, golf instructors, club fitting, and non-golf entertainment options such as Pop-A-Shot and ping pong, enjoy every amenity by reserving a simulator, booking a lesson, or playing in Five Iron\'s leagues, tournaments, and events."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7461,
        store_longitude: -73.9445,
        total_orders: 822.92,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Italian, Salad, French, American, Southern, Casual, Fabulous, Local, Excellent, Tradition',
        annual_dol: 957.76,
        description:
            'Italian & American comfort food served in a historic space with old-timey decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6479,
        store_longitude: -73.7887,
        total_orders: 1474.38,
        neighborhood: 'Jamaica',
        keywords:
            'Steak, Pizza, Salad, Coffee, Donut, Tasty, American, Delicious, New-York-Style, Martini',
        annual_dol: 1508.34,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -73.9875,
        total_orders: 4012.96,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Champagne, Vodka, Martini, Stylish, Hotel, Spicy, Distilled, Japanese, Fancy, Classy',
        annual_dol: 4168.25,
        description:
            'Stylish destination with exposed-brick walls dispensing fancy mixed drinks & refined light bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7345,
        store_longitude: -73.9923,
        total_orders: 27.57,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Vodka, Hotel, Trendy, Beer, Martini, Rooftop, Fun, Premium, Awesome',
        annual_dol: 112.71,
        description:
            'Drinking & techno, house & hip-hop dancing over 2 floors, which include a martini lounge & rooftop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8022,
        store_longitude: -73.9684,
        total_orders: 786.74,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Salad, Mexican, Tasty, American, Casual, Fresh, Local, Great, Recommended, Unique',
        annual_dol: 2761.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7327,
        store_longitude: -73.8636,
        total_orders: 39.98,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Tacos, Mexican, Lobster, Steak, Salad, Burritos, Fajitas, Rich, Delicious, Casual',
        annual_dol: 286.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6192,
        store_longitude: -74.0692,
        total_orders: 5.09,
        neighborhood: '',
        keywords: 'Sushi, Salad, Wine, Spicy, Japanese, Sashimi, Delicious, Ale, Miso Soup, Gyoza',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7649,
        store_longitude: -73.7719,
        total_orders: 891.69,
        neighborhood: 'Bayside',
        keywords:
            'Steak, Salad, Wine, Greek, Mediterranean, Polished, Friendly, Fresh, Casual, Fabulous',
        annual_dol: 916.92,
        description:
            'Airy Greek eatery with a long marble bar & white walls serving familiar Mediterranean small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.9327,
        total_orders: 56.02,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Pizza, Sushi, French, Asian, Smoky, Contemporary, Fusion, Perfect, Awesome',
        annual_dol: 229,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6233,
        store_longitude: -74.0313,
        total_orders: 920.85,
        neighborhood: 'Fort Hamilton',
        keywords: 'Lasagna, Pizza, Italian, Salad, Delicious, Rich, Warm, Organic, Quaint, Cozy',
        annual_dol: 940.39,
        description:
            'Homey candlit choice for contemporary Tuscan food from pastas to grilled & roasted meat entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9619,
        total_orders: 72.51,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Burger, Legendary, American, Wine, Classic, Luxurious, Iconic, Traditional, Superb, Sophisticated',
        annual_dol: 296.38,
        description:
            '"Our owner Joseph DeCristofaro is a born and raised Southampton local. Joe believes in supporting the community and providing the best in service and quality. An avid foodie, Joe is a world traveler who has been involved in every capacity in the restaurant industry since he was a teenager, and has extensive knowledge of cuisines from around the world."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7095,
        store_longitude: -74.0127,
        total_orders: 37.43,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Coffee, Wine, Hotel, Beer, Casual, Modern, Clean, Indian, Great',
        annual_dol: 153.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6704,
        store_longitude: -73.9298,
        total_orders: 15.9,
        neighborhood: 'Crown Heights',
        keywords:
            'Pizza, Flavorful, Cajun, Korean, Bbq, Jamaican, Rich, Caribbean, American, Friendly',
        annual_dol: 65.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6897,
        store_longitude: -73.9706,
        total_orders: 2624.41,
        neighborhood: 'Fort Greene',
        keywords: 'Tacos, Whiskey, Steak, Mexican, Wine, Beer, Salad, Dessert, Tasty, Delicious',
        annual_dol: 2989.02,
        description:
            'Latin American cooking by the Cómodo team in an airy, rustic space with a greenhouselike back room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9807,
        total_orders: 2254.43,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Garlic Bread, Burger, Steak, Cheesesteak, Tequila, Salad, Shrimp Cocktail, Wine, Beer, Irish',
        annual_dol: 2471.15,
        description:
            'Low-key watering hole with a full menu featuring traditional Irish eats & upscale entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7428,
        store_longitude: -73.9888,
        total_orders: 2486.11,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Italian, Spicy, Californian, Beer, Vibrant, Bruschetta, Crisp, Bourbon, Fresh',
        annual_dol: 2593.9,
        description:
            'Branch of the famed Italian market, offering counters, restaurants & cooking demos.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9806,
        total_orders: 319.39,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Pizza, Italian, Wine, Gluten Free, Vodka, Salad, Dessert, Meatloaf, Beer, Delicious',
        annual_dol: 340.38,
        description:
            'Sleek, low-key spot for contemporary Italian fare, wood-fired pizza & creative lunch sandwiches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.987,
        total_orders: 66.34,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'American, Hotel, Robust, Contemporary',
        annual_dol: 271.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7792,
        store_longitude: -73.7225,
        total_orders: 196.66,
        neighborhood: '',
        keywords:
            'Cheesesteak, Pizza, Steak, Wine, Beer, Philly Cheesesteak, Dessert, Great, Excellent, Unique',
        annual_dol: 803.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8047,
        store_longitude: -73.9548,
        total_orders: 2127.25,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Margarita, Beer, Salad, Luxurious, Irresistible, Fun, Sweet, Asian, Intimate',
        annual_dol: 4132.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.8039,
        total_orders: 47.07,
        neighborhood: 'Broadway–Flushing/Flushing/Murray Hill',
        keywords: 'Steak, American, Latin, Casual, Fresh, Great, Excellent',
        annual_dol: 192.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7278,
        store_longitude: -74.0028,
        total_orders: 2334.7,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Dessert, Wine, Italian, Stylish, French, Casual, Contemporary, Premium, American',
        annual_dol: 3767.77,
        description:
            'Hip, bi-level spot featuring Italian-accented New American fare, wine & art featuring boomboxes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7291,
        store_longitude: -73.9989,
        total_orders: 67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Cuban, Coffee, Rum, Spanish, Swiss, Delicious, American, Latin, Mojito',
        annual_dol: 293.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7776,
        store_longitude: -73.9784,
        total_orders: 1192.48,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Vegan, Salad, Beer, French Toast, Wine, Organic, Bbq, French, Spicy',
        annual_dol: 1217.78,
        description: '"Farm to Table restaurant. Proudly buying organic and local products."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6115,
        store_longitude: -74.01,
        total_orders: 47.38,
        neighborhood: 'Bath Beach/Bensonhurst',
        keywords:
            'Steak, Mexican, Shrimp Cocktail, Beer, Margarita, Local, Casual, Sangria, Fresh, Great',
        annual_dol: 193.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7503,
        store_longitude: -73.9872,
        total_orders: 12821.39,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Pizza, Luxurious, Upscale, Wine, Italian, Hotel, Rooftop, Trendy, Spacious, Delicious',
        annual_dol: 13619.38,
        description:
            'Spacious penthouse cocktail bar with clubby decor, light fare, indoor/outdoor seating & urban views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7163,
        store_longitude: -74.0097,
        total_orders: 1130.7,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Steak, Salad, Pizza, Italian, Delicious, Nachos, Juicy, Beer, Warm',
        annual_dol: 1154.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7227,
        store_longitude: -74.0048,
        total_orders: 5258.29,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Coffee, Trendy, Hotel, Rooftop, Delicious, Sleek, Modern, American, Fresh, Vintage',
        annual_dol: 5371.21,
        description:
            "Trade Center.Polished rooms with wood floors feature flat-screen TVs, free Wi-Fi and rainfall showers. Some rooms offer river or city views. Suites come with soaking tubs and separate living areas. Room service is available 24/7.Complimentary snacks and drinks are served in a lounge. Other amenities include a year-round rooftop bar and lounge and a seasonal outdoor rooftop pool. There's also a 24-hour gym.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7323,
        store_longitude: -74.0022,
        total_orders: 550.21,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Lobster, Lobster Tail, Delicious, Vodka, Juicy, Filipino, Sustainable, Lebanese',
        annual_dol: 586.78,
        description:
            '"Serving a variety of dishes from today\'s coolest chefs, Hungry House features modern Filipino food from Woldy Kusina, hearty salads from Pierce Abernathy, super tortas from Chile Con Miel, smashburgers from Apocalypse Burger, ice cream pints from Caffe Panna and curated local goods from The Goods Mart."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7467,
        store_longitude: -73.6432,
        total_orders: 132.75,
        neighborhood: '',
        keywords:
            'Steak, Vegetarian, Shrimp Cocktail, Wine, Italian, Delicious, Laid-Back, American, Perfect, Authentic',
        annual_dol: 283.34,
        description:
            'Classic Portuguese fare & sangria with an outdoor courtyard & weekend live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7319,
        store_longitude: -74.0015,
        total_orders: 588.68,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Mexican, Margarita, Wine, Nachos, Quesadillas, Pinacolada, Burritos, American, Latin',
        annual_dol: 759.22,
        description: 'Basic cantina feeding NYU students with burritos & other Mexican standards.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.64,
        store_longitude: -74.0158,
        total_orders: 371.49,
        neighborhood: 'Sunset Park',
        keywords: 'Pizza, Upscale, Hotel, Fun, Irish, Awesome, Ale, Great, Casual',
        annual_dol: 763.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.786,
        store_longitude: -73.9761,
        total_orders: 955.95,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Vegetarian, Coffee, Beer, Dessert, Ale, Organic, Mediterranean, Comfortable, Moroccan',
        annual_dol: 1526.77,
        description:
            'This small, contemporary eatery features Mediterranean dishes cooked in a custom clay oven.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7147,
        store_longitude: -73.8323,
        total_orders: 141.5,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Beer, Irish, Bbq, American, Awesome, Fresh, Terrific, Great, Casual',
        annual_dol: 257.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7738,
        store_longitude: -73.8748,
        total_orders: 16.75,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Coffee, American, Hotel, Innovative, Premium, British, Artisanal, Fun, Exceptional, Incredible',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6476,
        store_longitude: -74.0073,
        total_orders: 22.17,
        neighborhood: 'Sunset Park',
        keywords: 'Mexican, Tex-Mex, Turkish, Italian, Casual, Tradition, Great',
        annual_dol: 90.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7088,
        store_longitude: -73.625,
        total_orders: 93.52,
        neighborhood: '',
        keywords: 'Mexican, Wine, Chinese, French, Casual, American, Natural',
        annual_dol: 327.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -73.9509,
        total_orders: 1189.3,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Burger, Lobster, Salad, Chicken Caesar Salad, Shrimp Cocktail, Baked Clams, Beer, Wine, Martini, Caesar Salad',
        annual_dol: 2772.55,
        description:
            'Hearty, American comfort food like baked clams & unfussy drinks served on red-checkered tablecloths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7742,
        store_longitude: -73.9777,
        total_orders: 209.16,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Salad, Delicious, Coffee, Savory, Spacious, Organic, Avocado Toast, Sweet, Hungarian, Healthy',
        annual_dol: 854.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.917,
        total_orders: 279.38,
        neighborhood: 'Astoria',
        keywords: 'Wine, Pizza, Italian, Warm, Gourmet, Beer, Romantic, Refined, Friendly, Classy',
        annual_dol: 304.04,
        description:
            'Gourmet pizza & artisinal pasta paired with wine, beer & cocktails in a warm, refined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6439,
        store_longitude: -73.7824,
        total_orders: 735.73,
        neighborhood: 'Jamaica',
        keywords:
            'Burger, Pizza, Salad, Wine, Donut, Coffee, Premium, Comfortable, Great, Recommended',
        annual_dol: 767.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7266,
        store_longitude: -73.9863,
        total_orders: 3834.85,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Italian, Wine, Dessert, Peruvian, Stylish, Yummy, American, Casual, Classic',
        annual_dol: 4367.63,
        description:
            'Creative cocktails & craft beers pair with elevated bar bites like oysters at this stylish hangout.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.738,
        store_longitude: -73.9877,
        total_orders: 7427.92,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Lobster, Steak, Salad, Mexican, Upscale, Thai, English Muffin, Sustainable, Hotel',
        annual_dol: 10286.97,
        description:
            'Upscale offshoot of a Westchester restaurant with its own farm & sustainable seafood source.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7365,
        store_longitude: -73.9866,
        total_orders: 4721.42,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Chicken Parmigiana, Salad, Shrimp Cocktail, Wine, Beer, Italian, Meatball, Ale, Vintage',
        annual_dol: 4821.6,
        description:
            'Vintage bar, in continuous operation since 1864, serving burgers & Italian fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5853,
        store_longitude: -74.0937,
        total_orders: 772.89,
        neighborhood: 'Midland Beach',
        keywords:
            'Pizza, Italian, Gourmet, Flavorful, Indian, Dim Sum, Traditional, Great, Casual, Authentic',
        annual_dol: 1976.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5445,
        store_longitude: -74.1409,
        total_orders: 823.28,
        neighborhood: 'Great Kills',
        keywords: 'Shrimp Cocktail, American, Local, Great',
        annual_dol: 878.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.7716,
        total_orders: 68.1,
        neighborhood: 'Bayside',
        keywords:
            'Lobster, Lobster Tail, Cuban, Beer, Mexican, Italian, Trendy, Exotic, French, Asian',
        annual_dol: 153.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9762,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Beer, Wine, Retro, Classic, High-End, Fancy, Impressive, Great, Nostalgic',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7096,
        store_longitude: -73.8302,
        total_orders: 7198.74,
        neighborhood: 'Kew Gardens/Jamaica',
        keywords:
            'Steak, Burger, Hamburger, Lobster, Lobster Roll, Salad, Beer, Ale, French, American',
        annual_dol: 7573.01,
        description:
            'Steaks, burgers & other American comfort food served in a pub setting with a patio & TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6312,
        store_longitude: -74.0277,
        total_orders: 1012.49,
        neighborhood: 'Bay Ridge',
        keywords:
            'Salad, Vegetarian, Dessert, Beer, Spicy, Delicious, Japanese, Mediterranean, Lebanese, Warm',
        annual_dol: 1082.41,
        description:
            'Generous portions of Mediterranean-Middle Eastern cuisine come with a warm vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7604,
        store_longitude: -73.9897,
        total_orders: 811.01,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Italian, Wine, Beer, Delicious, American, French, Greek, Classy, Fancy',
        annual_dol: 961.38,
        description:
            'Easygoing spot for customizable pizza with gluten-free offerings, calzones & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6894,
        store_longitude: -73.9706,
        total_orders: 2064.77,
        neighborhood: 'Fort Greene',
        keywords:
            'Burger, Salad, Italian, Tequila, Margarita, Spicy, Rustic, Cozy, Seasonal, Perfect',
        annual_dol: 2206.01,
        description:
            'Cozy Fort Greene Italian eatery with rustic dishes, sidewalk seating & a picket-fenced back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9817,
        total_orders: 2834.51,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Elegant, Casual, Outstanding',
        annual_dol: 2897.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.781,
        store_longitude: -73.9477,
        total_orders: 6509.53,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Wine, Delicious, Rich, Upscale, Warm, Cozy, Polish, Intimate, Playful, Classic',
        annual_dol: 6647.65,
        description:
            'Upscale Indian eatery & bar serving craft cocktails & bites in cozy digs with a colonial-era vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.9894,
        total_orders: 1164.55,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Dessert, Italian, Californian, Mediterranean, Lebanese, Classy, Exquisite, Bright, Seasonal',
        annual_dol: 1465.02,
        description:
            'Seasonal, Mediterranean-influenced fare & creative cocktails showcase flowers & herbs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.9941,
        total_orders: 260.76,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Wine, Hamburger, Pizza, French, Elegant, Delicious, Magical, Hotel, Classy',
        annual_dol: 441.11,
        description:
            'French fare with cocktails & wines in a Victorian-inspired brasserie space with 2 outdoor areas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7576,
        store_longitude: -73.987,
        total_orders: 2175.96,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Wine, Beer, American, French, Vintage, Polished, Traditional, Prime Rib',
        annual_dol: 2318.95,
        description:
            '"Capturing the 1940s glamour of Hollywood and spirit of Times Square, Bacall\'s features large portions and home cooked foods eastern European cuisine from our craft kitchen."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9983,
        total_orders: 1180.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Premium, Classic, Authentic, Beer, Perfect, Fun, Excellent, Asian',
        annual_dol: 4447.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.9859,
        total_orders: 2660.39,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, American, Modern, Perfect, Amazing',
        annual_dol: 2837.24,
        description:
            '"Located at the "Crossroad of the World" on 44th and Broadway, Palladium Times Square is a modern, state-of-the-art, and versatile venue. The perfect venue for live music, corporate or private events, receptions, sit-down dinners, and more."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7795,
        store_longitude: -73.9504,
        total_orders: 32.38,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Lasagna, Salad, Chicken Parmigiana, Pizza, Italian, Veal Parmigiana, Caesar Salad, Casual, Local, Natural',
        annual_dol: 37.77,
        description:
            'Italian cuisine served in an intimate, elevated setting with exposed-brick walls & low lighting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7033,
        store_longitude: -73.9894,
        total_orders: 270.2,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords: 'Wine, Beer, Upscale, Perfect, Casual, Local, Iconic',
        annual_dol: 1104.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.602,
        store_longitude: -74.003,
        total_orders: 63.51,
        neighborhood: 'Bath Beach',
        keywords:
            'Peruvian, Salad, Vodka, Martini, Beer, Delicious, Latin, Friendly, Seasonal, American',
        annual_dol: 259.6,
        description:
            'Casual fixture offering roast chicken & other Peruvian fare, plus beer & sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7537,
        store_longitude: -74.0002,
        total_orders: 27.74,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Champagne, Vegan, Salad, Beer, Dessert, Spanish, Mediterranean, Premium, American',
        annual_dol: 113.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7281,
        store_longitude: -73.9994,
        total_orders: 937.73,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Whiskey, Wine, Beer, Bourbon, Margarita, Ale, French Onion Soup, American, French',
        annual_dol: 1014.03,
        description:
            'Sprawling British-inspired pub with leather-&-mahogany decor & an extensive draft-beer list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7704,
        store_longitude: -73.9074,
        total_orders: 458.01,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Shrimp Cocktail, Tequila, Wine, Beer, Greek, American, Mediterranean, Ribeye Steak, Traditional',
        annual_dol: 488.11,
        description:
            'White-tablecloth steakhouse where the beef is complemented by creative Greek appetizers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7354,
        store_longitude: -74.0006,
        total_orders: 1022.78,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesesteak, Beer, American, Upscale, Philly Cheesesteak, Great, Fresh, Wonderful, Creative, Casual',
        annual_dol: 1067.12,
        description:
            'Philadelphia meets Buffalo on the menu at this convivial spot famed for its cheesesteaks & wings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.6345,
        total_orders: 1981.96,
        neighborhood: '',
        keywords:
            'Vodka, Cosmopolitan, Gourmet, Upscale, Dessert, Martini, Italian, Delicious, Sustainable, Clean',
        annual_dol: 2036.27,
        description:
            'Cosmopolitan bistro serving a menu of globally inspired American seafood, meat & pasta dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7296,
        store_longitude: -74.0049,
        total_orders: 649.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Tacos, Cheesecake, Salad, Chicken Pot Pie, Beer, Dessert, Tasty, Martini, Ale',
        annual_dol: 731.55,
        description:
            'Laid-back venue whipping up brunch, burgers, tacos & finger foods, plus draft beer & cool cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7232,
        store_longitude: -73.6589,
        total_orders: 1315.28,
        neighborhood: '',
        keywords:
            'Burger, Chicken Caesar Salad, Hotel, Coffee, American, Caesar Salad, Salad, Trendy, Premium, Great',
        annual_dol: 1406.24,
        description:
            'Straightforward rooms feature free Wi-Fi, flat-screen TVs and separate living areas with pull-out sofas, plus minifridges, and tea and coffeemaking equipment.Parking is complimentary. Other amenities include a gym, an indoor pool and a cafe/bar, plus a 24-hour market, and meeting and event space. Breakfast is available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8529,
        store_longitude: -73.8277,
        total_orders: 58.41,
        neighborhood: 'Pelham Bay',
        keywords:
            'Burger, Salad, Beer, Delicious, Puerto Rican, Legendary, American, Latin, Awesome, Local',
        annual_dol: 238.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.736,
        store_longitude: -74.0009,
        total_orders: 1043.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Italian, Salad, Coffee, Wine, Beer, Ale, Avocado Toast, Rustic, Yummy',
        annual_dol: 1763.45,
        description:
            'Pizzeria from the popular sibling concept of the same name offering a range of Italian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7364,
        store_longitude: -74.006,
        total_orders: 28.94,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Wine, Organic, Dessert, Relaxed, Natural, Casual, Pure',
        annual_dol: 118.28,
        description:
            'Bright all-day outpost offering vegetarian & vegan fare, plus juices in a relaxed space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7875,
        store_longitude: -73.9763,
        total_orders: 407.02,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Beer, Chinese, Spicy, Rum, Sweet, Friendly, Relaxed, Authentic, Perfect',
        annual_dol: 442.94,
        description:
            'Spicy Sichuan dishes like dan dan noodles are heat-rated by number at this informal restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.9979,
        total_orders: 131.41,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Vegan, Chicken Parmigiana, Gluten Free, Italian, Flavorsome, Meatball, Tasty, Wine, Trendy',
        annual_dol: 339.22,
        description:
            'Bright, contemporary Italian spot with a wood-paneled atrium offering mix-&-match pasta dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7442,
        store_longitude: -73.6948,
        total_orders: 26.9,
        neighborhood: '',
        keywords: 'Burger, Salad, Pizza, Savory, Clean, Smooth, Modern, Friendly, Casual, American',
        annual_dol: 28.63,
        description:
            'A huge menu of classic diner eats in modern environs with cocktails & booth seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7017,
        store_longitude: -73.8987,
        total_orders: 690.18,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Salad, Cozy, Pizza, Burger, Wine, Ale, Beer, Warm, Welcoming, Casual',
        annual_dol: 1353.15,
        description:
            'Cocktails, beer & informal plates offered in a warm neighborhood bar with covered outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.9197,
        total_orders: 776.61,
        neighborhood: 'Astoria',
        keywords:
            'Burger, Whiskey, Irish, American, Karaoke, Jamaican, Warm, Welcoming, Classic, Lively',
        annual_dol: 831.69,
        description:
            'Comfy hangout offering classic Irish fare, burgers & beers, with TV sports & karaoke nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6632,
        store_longitude: -73.9913,
        total_orders: 1247.33,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Flavorful, Cozy, Beer, Spacious, Comfortable, Fun, Friendly, Awesome, Perfect, Homey',
        annual_dol: 1301.41,
        description:
            'Cozy bar boasting bourbons & 70 bottled beers plus a menu of grilled cheese, PB&J & other noshes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.9118,
        total_orders: 434.19,
        neighborhood: 'Astoria',
        keywords: 'Steak, Sushi, Pizza, Italian, Wine, Impeccable, Casual, Fresh, Classic, Local',
        annual_dol: 1524.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.917,
        total_orders: 62.43,
        neighborhood: 'Astoria',
        keywords:
            'Burger, Wine, Tasty, Upscale, Coffee, Delicious, Magical, Spanish, Mediterranean, Hotel',
        annual_dol: 88.03,
        description:
            '"Great drinks , best hookah , food you gonna love ! We have indoor and outdoor dining"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6157,
        total_orders: 4154.38,
        neighborhood: '',
        keywords:
            'Steak, Salad, Tequila, Pizza, French, Caesar Salad, American, Brazilian, Spicy, Martini',
        annual_dol: 4184.67,
        description:
            'Outpost of upmarket steakhouse chain known for sizzling, butter-topped beef in an elegant setting',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7373,
        store_longitude: -74.008,
        total_orders: 3307.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Fruity, Hotel, Italian, Quaint, Mediterranean, Classy, American, Intimate, Perfect',
        annual_dol: 3428.59,
        description:
            'Quaint duplex wine/cocktail bar with Mediterranean restaurant, plus fireplace & garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7408,
        store_longitude: -74.0068,
        total_orders: 252.11,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Vegetarian, Wine, Italian, Vodka, Elegant, Creme Brulee, Friendly, Fine',
        annual_dol: 1030.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7484,
        store_longitude: -73.6438,
        total_orders: 292.5,
        neighborhood: '',
        keywords:
            'Steak, Colombian, Salad, Beer, American, Latin, Spanish, Perfect, Casual, Signature',
        annual_dol: 325.43,
        description: '"Colombian cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -74.0011,
        total_orders: 273.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Burger, Italian, Wine, Mexican, Woody, American, Modern, Classy, Fancy',
        annual_dol: 1116.09,
        description: '"The Rebirth of a Greenwich Village Classic"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6276,
        store_longitude: -73.8999,
        total_orders: 49.85,
        neighborhood: 'Canarsie',
        keywords: 'Fun, Jamaican, Homemade, Great, French',
        annual_dol: 203.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7484,
        store_longitude: -73.9858,
        total_orders: 83.08,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Burger, Korean, New Zealand, Ramen, Heritage, Great',
        annual_dol: 339.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7705,
        store_longitude: -73.9201,
        total_orders: 896.1,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tequila, Wine, Margarita, Salad, Greek, Mediterranean, Organic, Stylish, Elegant, Relaxed',
        annual_dol: 1188.24,
        description:
            'Stylish restaurant serving traditional Greek dishes, seafood & cocktails in a relaxed space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6896,
        store_longitude: -73.6854,
        total_orders: 73.84,
        neighborhood: '',
        keywords: 'Steak, Peruvian, Salad, Beer, Dessert, Spicy, Delicious, Latin, Yummy, American',
        annual_dol: 278.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7097,
        store_longitude: -73.6188,
        total_orders: 10.81,
        neighborhood: '',
        keywords: 'Mexican, Spanish, Gluten Free, American, Latin, Casual',
        annual_dol: 44.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7708,
        store_longitude: -73.9202,
        total_orders: 5.36,
        neighborhood: 'Astoria/LIC',
        keywords: 'Pizza, Italian, Beer, American, Latin, Homemade, Polished, Fresh, Local, Casual',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7647,
        store_longitude: -73.9163,
        total_orders: 710.72,
        neighborhood: 'Astoria',
        keywords:
            'Steak, Tacos, Salad, Coffee, Dessert, Beer, Organic, Caesar Salad, Paloma, Outstanding',
        annual_dol: 860.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6678,
        store_longitude: -73.7983,
        total_orders: 1927.88,
        neighborhood: 'South Ozone Park',
        keywords: 'Hotel, Coffee, Sleek, Contemporary, Great',
        annual_dol: 1968.79,
        description:
            'The contemporary rooms come with 42-inch flat-screen TVs and free Wi-Fi, plus desks, minifridges and coffeemakers. Suites add separate living rooms with wet bars. Room service is available.An airport shuttle and parking are complimentary. Other amenities include a sleek restaurant, a lobby lounge/bar with floor-to-ceiling windows, and a 24-hour fitness center, as well as 7 meeting rooms and a 24/7 business center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6725,
        store_longitude: -73.9866,
        total_orders: 304.52,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Pizza, Salad, Beer, Wine, Rustic, Swiss, American, Karaoke, Thai',
        annual_dol: 1244.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.737,
        store_longitude: -74.0039,
        total_orders: 787.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Gluten Free, Vegetarian, Dessert, Upscale, Beer, Cozy, Italian, Warm, French',
        annual_dol: 1355.3,
        description:
            'Oysters, wine & Provençal cooking in a setting with warm woods, large windows & an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7364,
        store_longitude: -74.0066,
        total_orders: 269.92,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Wine, Avocado Toast, American, Italian, Spicy, Seasonal, Contemporary, Bright, Fresh',
        annual_dol: 1103.35,
        description:
            '"The Mary Lane is a seasonal New American Restaurant in the West Village, located on the cobblestone corner of Bank and Greenwich Streets, serving locally sourced food with a farm-to-table menu. At The Mary Lane, we offer our guests a curated wine list, delectable craft cocktails, and seasonally inspired dishes to be enjoyed with friends and family all while being served with the genuine warmth of Blackfoot Hospitality. Chef Price\'s signature market-based fare consists of contemporary cuisine sourced from our local purveyors."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7825,
        store_longitude: -73.9805,
        total_orders: 748.29,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Salad, Vegetarian, Italian, Wine, Dessert, Delicious, Hotel, Rustic, Bellini',
        annual_dol: 780.73,
        description:
            'Italian eatery serving thin-crust pizzas & homemade pastas amid exposed brick walls & rustic murals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7491,
        store_longitude: -73.5879,
        total_orders: 40.72,
        neighborhood: '',
        keywords: 'Pizza, Latin, Premium, Friendly, Perfect, Cool, Great, Quiche',
        annual_dol: 166.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6975,
        store_longitude: -73.9315,
        total_orders: 1029.07,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Delicious, Friendly, Cool, Urban, Great, Casual, Bloody Mary, Local',
        annual_dol: 1050.9,
        description:
            'Funky, compact dive bar with a throwback punk-rock vibe, pinball machines & a photo booth.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9712,
        total_orders: 201.73,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Wine, Chicken Caesar Salad, Vodka, French, Gracious, Caesar Salad, Premium, Exquisite, Casual',
        annual_dol: 708.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7176,
        store_longitude: -74.0125,
        total_orders: 2350.76,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Steak, Cheesecake, Chicken Parmigiana, Chocolate Cake, Shrimp Cocktail, Salad, Lobster Bisque, Italian, Wine',
        annual_dol: 3071.06,
        description:
            'Upscale steakhouse chain link known for its setting bedecked with caricatures of famous people.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.9617,
        total_orders: 1040.02,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Italian, Delicious, Cozy, Premium, Wonderful, Great, Fresh, Traditional, Fine',
        annual_dol: 1076.16,
        description:
            'Clubby neighborhood stomping ground serving Italian dishes to regular & celebrity patrons.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.9669,
        total_orders: 229.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster Roll, Lobster, Cornbread, Tomato Soup, Salad, Dessert, Wine, Apple Pie, Coffee, Italian',
        annual_dol: 247.83,
        description:
            'A wide-ranging European menu plus brunch are served in a cozy candlelit space with an ample garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7254,
        store_longitude: -73.9451,
        total_orders: 339.92,
        neighborhood: 'Greenpoint',
        keywords:
            'Pizza, Burger, Beer, Wine, Gluten Free, Karaoke, Hotel, American, Artisanal, Cozy',
        annual_dol: 1389.49,
        description:
            '"We\'re a wine bar with artisanal cheeses, charcuterie, cocktails and bangin\' pizza!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7224,
        store_longitude: -74.0035,
        total_orders: 551.03,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Chicken Parmigiana, Shrimp Cocktail, Italian, Meatball, Wine, Upscale, Mediterranean, Modern, Classic, Fresh',
        annual_dol: 587.66,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7044,
        store_longitude: -74.0105,
        total_orders: 117.2,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Quesadillas, Delicious, American, Burritos, Latin, Awesome',
        annual_dol: 171.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8072,
        store_longitude: -73.927,
        total_orders: 937.22,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Steak, Italian, Pizza, Tasty, Coffee, Casual, Classic',
        annual_dol: 1058.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -74.0046,
        total_orders: 911.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Chicago-Style, Sushi, Italian, German, Meatball, Beer, Gluten Free, Martini, Chinese',
        annual_dol: 950.92,
        description:
            'Sophisticated hangout dispensing boozy drinks & Italian plates, including Chicago-inspired pizzas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7395,
        store_longitude: -74.0082,
        total_orders: 141.51,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Coffee, Lobster, Wine, American, Contemporary, Polished, Lively, Seasonal, Spectacular, Casual',
        annual_dol: 578.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8419,
        store_longitude: -73.8372,
        total_orders: 64.24,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords: 'American',
        annual_dol: 209.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7847,
        store_longitude: -73.9733,
        total_orders: 901.22,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Vegetarian, Salad, Mexican, Nachos, Dessert, Margarita, Gluten Free, Fajitas, Spicy',
        annual_dol: 1056.81,
        description:
            'This lively Southwestern cafe dishes generous portions of grub & pairs them with large margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6366,
        store_longitude: -74.0259,
        total_orders: 748.97,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Ale, American, Karaoke, Irish, Cozy, Traditional, Casual',
        annual_dol: 830.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -73.6743,
        total_orders: 480.14,
        neighborhood: '',
        keywords: 'Burger, Steak, Lobster, Pizza, Italian, Tasty, Dessert, Swiss, Upscale, Bbq',
        annual_dol: 931.66,
        description:
            'Busy restaurant & watering hole serving steaks, seafood & burgers, plus specialty cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7009,
        store_longitude: -73.8918,
        total_orders: 6.69,
        neighborhood: 'Flushing/Glendale',
        keywords: 'Beer, Tasty, German, Irish, Fun, Great, Friendly, Fresh, Casual',
        annual_dol: 27.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.575,
        store_longitude: -73.9853,
        total_orders: 1296.51,
        neighborhood: 'Coney Island',
        keywords: 'Wine, Great',
        annual_dol: 5863.97,
        description:
            'Maimonides Park is a minor league baseball stadium on the Riegelmann Boardwalk in Coney Island, Brooklyn, New York City. The home team and primary tenant is the New York Mets-affiliated Brooklyn Cyclones of the South Atlantic League. The stadium has also hosted other teams as well.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6762,
        store_longitude: -73.9836,
        total_orders: 426.86,
        neighborhood: 'Park Slope',
        keywords:
            'Beer, Fun, Lively, Superb, Friendly, Irish, Fantastic, Great, Impressive, Attentive',
        annual_dol: 536.54,
        description:
            'Formerly a tire shop, this beer bar sports a lively vibe fueled by board games & an outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.669,
        store_longitude: -73.9896,
        total_orders: 87.91,
        neighborhood: 'Park Slope',
        keywords:
            'Steak, Salad, Dessert, Coffee, French, Authentic, Natural, Casual, Fantastic, Great',
        annual_dol: 359.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6945,
        store_longitude: -73.9306,
        total_orders: 2566.37,
        neighborhood: 'Bushwick',
        keywords:
            'Whiskey, Beer, Crisp, Smooth, Friendly, Casual, Awesome, Eclectic, Great, Hard Cider',
        annual_dol: 2620.83,
        description:
            'Low-lit bar with a chill vibe, featuring a pool table & music events, including DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7519,
        store_longitude: -73.9833,
        total_orders: 2381.49,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Beer, Upscale, Trendy, Classy, Fun, Intimate, Vivid, Casual',
        annual_dol: 2432.02,
        description:
            '"Sapphire 39 is an upscale Gentlemen\'s Club With the elegance and class of the original Upper East Side location and its sister club in Las Vegas, Sapphire 39 offers deluxe amenities, erotic programming and VIP concierge services including designer suites, couples packages, private butlers and luxury chauffeurs to satisfy every desire. In addition to its world class roster of over 8,000 talented and provocative dancers, the club hosts the hottest starts in adult entertainment, exclusive guest DJs and live music from the sultry Sirens of Sapphire Island."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6557,
        store_longitude: -74.0026,
        total_orders: 1919.68,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Karaoke, Delicious, Warm, Beer, Friendly, Awesome, Irish, Perfect, Great, Cool',
        annual_dol: 2042.91,
        description: '"A bar for the neighborhood."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.796,
        store_longitude: -73.9357,
        total_orders: 45.56,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Burger, Salad, Pizza, Vodka, Caesar Salad, Spanish, American, Ribeye Steak, Latin',
        annual_dol: 89.33,
        description:
            'Upbeat steakhouse offering an eclectic menu with seafood & global flavors, plus a lively bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7041,
        store_longitude: -74.0092,
        total_orders: 16.75,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Beer, Hamburger, Nachos, Spanish, Thai, Phenomenal, Fun, Friendly, Vibrant',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6849,
        store_longitude: -73.8673,
        total_orders: 41.54,
        neighborhood: 'Cypress Hills',
        keywords:
            'Salad, Shrimp Cocktail, Chicken Caesar Salad, Coffee, Gourmet, Italian, Dominican, Organic, Pinacolada, Spanish',
        annual_dol: 169.8,
        description:
            'Lively restaurant & bar providing elevated Pan-Latin cuisine & craft cocktails, plus entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -74.0041,
        total_orders: 2417.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Delicious, Cozy, Beer, Hotel, Italian, Fun, Awesome, Cool, Great',
        annual_dol: 2468.84,
        description:
            '"A secret speakeasy located in the heart of the West Village. A lovers den with cozy fire places, mahogany floors and crushed velvet banquettes. We serve delicious seafood and coastal fare, paired with bone cold classic martinis and craft cocktails. All while early lovers rock reggae, soul and hip hop wash over you."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 665.09,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Cuban, Margarita, Wine, Delicious, Caribbean, Ale, Rum, American',
        annual_dol: 964.88,
        description:
            'Boisterous Cuban restaurant serving island staples & cocktails in a retro-Havana setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.6401,
        total_orders: 2.32,
        neighborhood: '',
        keywords:
            'Pizza, Chicken Parmigiana, Italian, Salad, Shrimp Cocktail, Vodka, Dessert, Upscale, Spicy, American',
        annual_dol: 9.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6575,
        store_longitude: -73.6701,
        total_orders: 182.34,
        neighborhood: '',
        keywords: 'Tacos, Burger, Salad, American, Casual, Latin, Classy, Traditional',
        annual_dol: 745.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7101,
        store_longitude: -73.8994,
        total_orders: 62.83,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Wine, Vodka, Beer, Delicious, Margarita, Karaoke, Fresh, Cozy, Awesome, Italian',
        annual_dol: 106.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7705,
        store_longitude: -73.9125,
        total_orders: 2218.03,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Karaoke, Fun, Friendly, Irish, Fabulous, Casual, Great',
        annual_dol: 2493.3,
        description:
            'Upbeat gay bar offering cocktails along with karaoke, drag shows, bingo & TV viewing parties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7712,
        store_longitude: -73.9564,
        total_orders: 3520.92,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Tasty, Beer, Delicious, Nachos, Bbq, Fun, Friendly, Fancy, Clean',
        annual_dol: 3687.21,
        description:
            'Beer pong, beverage deals & TVs tuned to sports sum up the scene at this popular watering hole.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.9394,
        total_orders: 4099.21,
        neighborhood: 'East Williamsburg',
        keywords: 'Legendary, Great, Hip',
        annual_dol: 4276.94,
        description:
            'Brooklyn Steel is an 1800-capacity music venue in Brooklyn, New York, United States. In 2018 Brooklyn Steel was named one of the 10 best live music venues in America by Rolling Stone Magazine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7694,
        store_longitude: -73.9627,
        total_orders: 67.84,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vodka, Wine, Sushi, Italian, Cozy, Yummy, Authentic, Wonderful, Excellent, Casual',
        annual_dol: 277.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7312,
        store_longitude: -74.0022,
        total_orders: 236.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Dairy Free, Wine, Vodka, Shrimp Cocktail, Italian, Organic, Martini, Quaint, Cozy',
        annual_dol: 400.19,
        description:
            'Italian classics are crafted from organic ingredients in a sunwashed stucco space with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -74.0073,
        total_orders: 2994.78,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Steak, Shrimp Cocktail, Wine, Gluten Free, Beer, Spicy, American, Premium, Sleek, Rooftop',
        annual_dol: 3259.09,
        description:
            'A chic clientele samples steak & scenery in this sleek Meatpacking District setup with a roof deck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7384,
        store_longitude: -73.6522,
        total_orders: 308.04,
        neighborhood: '',
        keywords:
            'Steak, Flavorful, Beer, Bbq, Salad, Brazilian, Fantastic, Great, Excellent, Relaxed',
        annual_dol: 314.57,
        description:
            'Relaxed Portuguese barbecue specialist favored for all-you-can-eat meats carved tableside.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7374,
        store_longitude: -74.0059,
        total_orders: 16.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Champagne, Wine, Delicious, Romantic, Quaint, French, Classy, Fun, Polished, Friendly',
        annual_dol: 17.82,
        description:
            'Romantic French haunt offering a taste of the Left Bank among chandeliers & a fireplace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7296,
        store_longitude: -74.0043,
        total_orders: 15.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Steak, Sushi, Salad, Beer, Savory, Spicy, Homemade, Modern, Russian',
        annual_dol: 63.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7825,
        store_longitude: -73.9805,
        total_orders: 1680.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Upscale, Stylish, Hotel, Rich, Cozy, Warm, Italian, Comfortable, Gin, Bright',
        annual_dol: 1715.99,
        description:
            'Bright, contemporary rooms offer wood floors, flat-screen TVs and Wi-Fi (fee), plus desks, coffeemakers and custom artwork from local artists; suites add extra living space. Some rooms provide balconies with city views.Amenities include a casual Italian restaurant, a hip speakeasy-inspired bar and 2 rooftop terraces, plus a business center, and a cozy lounge with a piano, a fireplace and a library.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7018,
        store_longitude: -73.8816,
        total_orders: 1265.11,
        neighborhood: 'Flushing/Glendale',
        keywords: 'Salad, German, Beer, Russian, Homemade, Casual, Authentic, Fresh, Classic, Fine',
        annual_dol: 1365.25,
        description:
            'German beer hall with classic fare & frosty steins delivered by waitresses in Alpine costume.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6656,
        store_longitude: -73.5528,
        total_orders: 109.35,
        neighborhood: '',
        keywords:
            'Hamburger, Salad, Beer, Nachos, Irish, Bourbon, French, American, Karaoke, Casual',
        annual_dol: 254.92,
        description:
            'This laid-back hangout with a wood-paneled bar features Irish pub fare, draft beer & weekly karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7716,
        store_longitude: -73.9267,
        total_orders: 55.37,
        neighborhood: 'Astoria',
        keywords: 'Luxurious, Spacious, Romantic, Fine',
        annual_dol: 226.32,
        description:
            '"Renaissance Event Hall caters to all your event needs in a sophisticated, upscale, luxurious fashion with elegance & grace. With only 1 event at a time our award-winning interior design, is all yours, from balcony to lounge to Romanesque plaza. you will dine in the glow of alabaster lighting & soft golden marble, the warmth of Mahogany wood, & the paradox of wrought-iron railings fired so fine that they evoke gold-spun embroidery. Imagine a romantic cocktail hour on balconies and the vistas of candlelit tables 28 feet below, all set to the sounds of a baby grand piano at the top of the stairs. Add to this setting lush drapery, a spacious European-style lounge, and the world’s best culinary traditions. For parties between 50 and 300 people,"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9202,
        total_orders: 858.83,
        neighborhood: 'Astoria/LIC',
        keywords: 'Karaoke, Caribbean, Jamaican, Korean, Gourmet, Thai, Irish, Perfect, Casual',
        annual_dol: 877.06,
        description:
            'Karaoke joint with a full bar, private rooms & song selections in English, Spanish, Korean & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7039,
        store_longitude: -73.9341,
        total_orders: 707.45,
        neighborhood: 'East Williamsburg',
        keywords: 'Sushi, Wine, Dessert, Beer, Spicy, Japanese, Asian, Relaxed, Vibrant, Hip',
        annual_dol: 936.57,
        description:
            'Warm, relaxed restaurant with exposed-brick walls & a patio serving Japanese classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7711,
        store_longitude: -73.9637,
        total_orders: 1906.83,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotdog, Burger, Romantic, Perfect, American, Great, Wonderful, Classy, Iconic, Fine',
        annual_dol: 7184.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.998,
        total_orders: 46889.84,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Wine, Tasty, Chinese, Dessert, Stylish, Spicy, Beer, Trendy, Funky, Elegant',
        annual_dol: 48935.24,
        description:
            'Two-story spot in a former opera house, offering contemporary Chinese dishes & stylish touches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7766,
        store_longitude: -73.9505,
        total_orders: 228.37,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tasty, Salad, Italian, Wine, Coffee, Delicious, Cozy, Inviting, Romantic, Classy',
        annual_dol: 243.55,
        description:
            'Cozy, white-tablecloth restaurant offering Italian dishes with homemade pasta & a sizable wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7453,
        store_longitude: -73.8611,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Burger, American, Modern, Authentic, Irish, Classic, Friendly, Hotel, Local, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5542,
        store_longitude: -74.2177,
        total_orders: 319.73,
        neighborhood: 'Rossville',
        keywords: 'Burger, Nachos, Irish, Ale, American, Awesome, Fresh, Authentic, Classic, Great',
        annual_dol: 333.59,
        description: 'Old West-style saloon with tap brews & cover-free live music on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6526,
        store_longitude: -73.9759,
        total_orders: 2755.13,
        neighborhood: 'Windsor Terrace',
        keywords: 'Beer, Fun, Coffee, Awesome, Comfortable, Great, Cool, Excellent, Urban, Local',
        annual_dol: 2813.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.8596,
        total_orders: 529.59,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Burger, Salad, Dessert, Wine, Organic, Stylish, Sustainable, Trendy, Cozy, Homemade',
        annual_dol: 585.22,
        description:
            'A stylish diner serving classic American eats for brunch, dinner & late night, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8052,
        store_longitude: -73.9663,
        total_orders: 878.02,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Burger, Vegan, Tacos, Mexican, Beer, Salad, Margarita, Bbq, American, Tex-Mex',
        annual_dol: 975.12,
        description:
            'Bar grub & frozen margaritas served in a casual, multilevel space with a rooftop bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7928,
        store_longitude: -73.9717,
        total_orders: 4.98,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Wine, Coffee, Chinese, Mediterranean, Thai, Asian, Premium, Friendly, New Zealand',
        annual_dol: 20.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6913,
        store_longitude: -73.9096,
        total_orders: 504.69,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Hamburger, Beer, Cajun, Salad, Ale, Margarita, Funky, Spicy, Dominican',
        annual_dol: 1075.71,
        description:
            'Casual eats like burgers & wings, plus cocktails, beer, TV sports & happy hour in a funky bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7157,
        store_longitude: -74.01,
        total_orders: 41.44,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Tacos, Pizza, Tequila, Mexican, Salad, Margarita, Mimosa, Wine, Italian, Delicious',
        annual_dol: 169.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7411,
        store_longitude: -73.9929,
        total_orders: 11426.86,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Salad, Tasty, Wine, Meatball, Spicy, Thai, Cozy, Polished, Latin, Modern',
        annual_dol: 12637.62,
        description:
            'A two-floor cocktail lounge appointed with Indian-inspired decor is a popular special-event space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6257,
        store_longitude: -74.1552,
        total_orders: 709.09,
        neighborhood: 'Mariners Harbor/Mid Island',
        keywords:
            'Burger, Mexican, Beer, Sushi, Shrimp Cocktail, Pizza, Colombian, Wine, Peruvian, Delicious',
        annual_dol: 868.61,
        description:
            'Relaxed restaurant & bar offering traditional Colombian fare & baked goods from morning to night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7894,
        store_longitude: -73.7298,
        total_orders: 623.78,
        neighborhood: '',
        keywords:
            'Pizza, Wine, Trendy, Mexican, Salad, Coffee, Gourmet, Spanish, Fabulous, Mediterranean',
        annual_dol: 637.02,
        description:
            "Spot for imaginative Eclectic meals & tapas plates, many with foie gras from the chef's own farm.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.98,
        total_orders: 225.29,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Steak, Gluten Free, Delicious, Wine, Brazilian, Fried Chicken, Latin, Traditional, Authentic, Great',
        annual_dol: 235.06,
        description:
            'A white-table cloth spot in Little Brazil serving traditional Brazilian cuisine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9933,
        total_orders: 1027.64,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Shrimp Cocktail, Dessert, Gluten Free, Wine, French Onion Soup, Chinese, French, Creamy, Romantic',
        annual_dol: 1104.27,
        description:
            'French staples pair with piano music at this opulent restaurant, a tribute to Josephine Baker.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.78,
        store_longitude: -73.95,
        total_orders: 167.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Mexican, Margarita, Wine, Fajitas, Delicious, Casual, Authentic, Great',
        annual_dol: 215.95,
        description:
            'This family-owned outfit with a festive atmosphere serves familiar Mexican food & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6427,
        store_longitude: -73.6399,
        total_orders: 708.53,
        neighborhood: '',
        keywords:
            'Steak, Wine, Salad, French, French Onion Soup, Beer, Italian, Polished, Comfortable, Friendly',
        annual_dol: 784.05,
        description:
            'Bustling brasserie with patio dining known for classic French cooking, from mussels to steak frites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7486,
        store_longitude: -73.9873,
        total_orders: 736.12,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Lobster Tail, Lobster, Tasty, Fun, Vivid, Sweet, Impressive, Mac And Cheese, Great, American',
        annual_dol: 768.04,
        description:
            '"Hoops Cabaret & Sports Bar is NYC\'s sexiest topless sports bar located in midtown Manhattan, 1 block from MSG and Penn Station. Hoops is a sexy adult cabaret with a fun sports bar feel, featuring an impressive décor and menu with items like fried lobster tails, short-rib sliders, lemon pepper wings and some of the best mac and cheese bites in NYC."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9918,
        total_orders: 547.45,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Mexican, Coffee, Wine, South American, Dominican, Warm, Spanish, Bbq, American, Latin',
        annual_dol: 624.46,
        description:
            'Latin-American restaurant, lounge & hookah bar popular for its per-pound lunch buffet & DJ nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9874,
        total_orders: 36.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Burger, Pizza, Sushi, Hamburger, Salad, Wine, Sashimi, Hotel, American, New Zealand',
        annual_dol: 149.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7461,
        store_longitude: -73.9939,
        total_orders: 149.56,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Mexican, Tacos, Salad, Burritos, Local, Healthy, Casual, Creole, Cool',
        annual_dol: 733.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7254,
        store_longitude: -73.9977,
        total_orders: 2357.38,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Mexican, Steak, Margarita, Wine, Delicious, Tex-Mex, Seafood Paella, Enchiladas, Fajitas, American',
        annual_dol: 2357.38,
        description:
            'Mexican restaurant featuring happy hours, salsa bands & free salsa dance classes, plus a dance club.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7781,
        store_longitude: -73.9787,
        total_orders: 3633.31,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Pizza, Salad, Tasty, Italian, Gourmet, Dessert, Mexican, Vodka, Coffee, Ale',
        annual_dol: 4562.35,
        description:
            'Neighborhood standby offering classic Italian meals & weekend brunch in a cozy, casual atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5893,
        store_longitude: -73.7291,
        total_orders: 206.61,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Chicken Parmigiana, Delicious, Modern, Fun, Fresh, Casual, Traditional',
        annual_dol: 488.23,
        description:
            '"Italian Restaurant and Family-style options. Chef Giovanni of Matteo’s Bellmore and Matteo’s Howard Beach uses a more modern approach to his classics at Gio’s."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.71,
        store_longitude: -74.0166,
        total_orders: 2821.19,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Beer, Tacos, Wine, American, Warm, Delicious, Spacious, Juicy, Hip, Perfect',
        annual_dol: 2911.86,
        description:
            'Burgers, tacos & craft beer served in a hip tavern with a patio, pinball machines & board games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6776,
        store_longitude: -73.998,
        total_orders: 202.87,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Lasagna, Italian, Meatball, Wine, Delicious, Warm, American, Sweet, Ample, Awesome',
        annual_dol: 276.18,
        description:
            'Popular neighborhood Italian with Tuscan cooking served in a tiled room or out in the ample garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9882,
        total_orders: 2587.14,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Japanese, Salad, Martini, Beer, Whiskey, American, Local, Casual, Hip, Cool',
        annual_dol: 8360.9,
        description:
            'Underground sake bar offering a large list of varietals along with Japanese snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.9345,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Delicious, Bbq, French, Fresh, Great, Dutch',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8619,
        store_longitude: -73.9118,
        total_orders: 1399.46,
        neighborhood: 'Fordham Manor/University Heights/West Bronx',
        keywords:
            'Burger, Steak, Cornbread, Salad, Shrimp Cocktail, Bbq, Margarita, Spicy, Zesty, Pinacolada',
        annual_dol: 1429.16,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9665,
        total_orders: 508.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Salad, Tasty, Meatball, Delicious, Zesty, Homemade, Fresh',
        annual_dol: 520.73,
        description:
            'Family-friendly pizzeria serving thin-crust pies alongside calzones, pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7064,
        store_longitude: -74.0138,
        total_orders: 212.76,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Roast Beef, Beer, Nachos, Bbq, Irish, Philly Cheesesteak',
        annual_dol: 231.54,
        description:
            'Irish watering hole offering happy-hour scene & simple eats liked corned beef from a steam table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6876,
        store_longitude: -73.9958,
        total_orders: 1625.57,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Pizza, Spanish, Wine, American, Stylish, Latin, Polished, Casual, Great, Excellent',
        annual_dol: 3789.61,
        description:
            'Spanish tapas with Moorish & Jewish influences draw crowds to the stylish storefront restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6907,
        store_longitude: -73.9581,
        total_orders: 135.31,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Steak, Burger, Vodka, Flavorful, Dessert, Cosmopolitan, Mimosa, Gin, French Toast, Delicious',
        annual_dol: 553.08,
        description:
            'Cozy eatery dispensing American food & booze in a narrow, brick-walled space with retro touches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.738,
        store_longitude: -73.984,
        total_orders: 326.34,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Beer, Burger, Ale, Wine, German, Nachos, Delicious, Warm, Irish, Cozy',
        annual_dol: 420.83,
        description:
            'Cozy Irish joint and sports bar serving pub grub & beer in cozy, brick-walled surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5129,
        store_longitude: -74.2508,
        total_orders: 2695.34,
        neighborhood: 'Tottenville',
        keywords:
            'Steak, Wine, Champagne, Cinnamon Roll, Italian, Pizza, Coffee, Beer, Hotel, Elegant',
        annual_dol: 2833.6,
        description:
            'Tri-level mansion by the water offering an elegant setting for Italian wining & dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9882,
        total_orders: 410.31,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Wine, Coffee, Urban, Modern, Contemporary, Rooftop, Dutch, Great',
        annual_dol: 1440.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8037,
        store_longitude: -73.953,
        total_orders: 9.56,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Burger, Vegan, Salad, Coffee, Wine, Beer, Chinese, French, African, Hotel',
        annual_dol: 39.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7252,
        store_longitude: -74.0037,
        total_orders: 1787.5,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Korean, Spicy, Trendy, Delicious, Contemporary, Bbq, Modern, Fun, Traditional, Perfect',
        annual_dol: 1976.5,
        description:
            'Modern Korean eatery specializing in barbecue & mains with exposed brick walls & beamed ceilings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6571,
        store_longitude: -73.96,
        total_orders: 322.29,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Burger, Steak, Salad, Italian, Mimosa, Beer, French Toast, Avocado Toast, French, Delicious',
        annual_dol: 526.11,
        description:
            'Comfortable, neighborhood outpost featuring espresso drinks & American dishes, plus patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7766,
        store_longitude: -73.9772,
        total_orders: 3580.96,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Hamburger, Salad, Whiskey, Beer, Wine, Savory, American, Swiss, Delicious',
        annual_dol: 3979.46,
        description:
            'American cooking, a raw bar & creative cocktails offered in polished surrounds with retro touches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7509,
        store_longitude: -74.0043,
        total_orders: 46.23,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Italian, Upscale, Champagne, Wine, Trendy, Mimosa, American, Bellini, Hotel',
        annual_dol: 119.34,
        description:
            'Clubby music & a popular all-you-can-drink weekend brunch lend this Italian steakhouse a party vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -74.0025,
        total_orders: 630.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Gourmet, Hamburger, Spicy, Warm, Hotel, American, Bbq, Inviting, Contemporary',
        annual_dol: 1145.63,
        description:
            'Upscale, contemporary bistro with striking interiors, furnishing gourmet dinners, cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6215,
        store_longitude: -73.9052,
        total_orders: 31.78,
        neighborhood: 'Bergen Beach',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Chicken Parmigiana, Wine, Vodka, Italian, Sleek, American, Outstanding',
        annual_dol: 129.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7561,
        store_longitude: -73.9904,
        total_orders: 140.7,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Gluten Free, Coffee, Wine, Japanese, Martini, Zen, Upscale, Ramen',
        annual_dol: 575.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5732,
        store_longitude: -74.1152,
        total_orders: 0,
        neighborhood: 'New Dorp',
        keywords: 'Southern, Contemporary, Casual, Friendly, Creative',
        annual_dol: 0,
        description:
            '"As you walk along your path, you might find yourself at a crossroads in life. This is a path not meant to be walked alone. Crossroads Church is here for you in every step to connect with Jesus Christ in a meaningful saving relationship. We invite you to become a part of both God’s and our Crossroads Church family. Our plan is to teach you how to be spiritually strong so that you may be equipped to live out the plans & purposes that God has created you for. We do this by being a church that reflects the methods, models, and missions of Jesus Christ, as shown in God’s Word, the Bible. Crossroads is a church that creatively expresses the good news that is the Gospel, and provides a genuine & transparent environment of unity and community."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6566,
        store_longitude: -73.6454,
        total_orders: 359.45,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Gluten Free, Italian, Wine, Delicious, Creamy, Comfortable, Classy, Ample',
        annual_dol: 400.22,
        description:
            'Italian specialties & wood-fired pizza at a restaurant with a Tuscan vibe & sidewalk dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6573,
        store_longitude: -73.6459,
        total_orders: 458.28,
        neighborhood: '',
        keywords:
            'Steak, Wine, Salad, French, Italian, Gluten Free, Moroccan, American, Polished, Casual',
        annual_dol: 502.96,
        description:
            'French bistro with small plates & a large wine list served among red velvet & Moroccan tiles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6631,
        store_longitude: -73.7182,
        total_orders: 1020.34,
        neighborhood: '',
        keywords:
            'Lobster, Italian, Beer, Chicken Wings, American, Casual, Friendly, Local, Lively, Classic',
        annual_dol: 1262.48,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.836,
        store_longitude: -73.7064,
        total_orders: 198.82,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Pizza, Italian, American, Modern, Recommended, Global, Friendly, Tradition',
        annual_dol: 243.35,
        description:
            'Festive locale with New American eats & cocktails, plus a dockside tiki bar, music & marina views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7048,
        store_longitude: -74.0089,
        total_orders: 1422.58,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Roast Turkey, Cajun, Turkey Sandwich, Swiss, Whiskey, Irish, Homemade, Reuben, Russian',
        annual_dol: 1527.76,
        description:
            'Low-key Irish tavern serving pints & a full menu of pub grub to Financial District types.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5691,
        store_longitude: -74.1108,
        total_orders: 31.16,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords: 'Pizza, Wine, Beer, Meatball, Homemade, Ramen, Fun, Natural, Great, Fresh',
        annual_dol: 127.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7355,
        store_longitude: -73.9743,
        total_orders: 206.39,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Latin, Hotel, Great',
        annual_dol: 266.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.8515,
        total_orders: 35.47,
        neighborhood: 'Flushing/Corona',
        keywords: 'Superb, Amazing, Perfect, Great, Wonderful, Iconic, Dynamic',
        annual_dol: 144.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7935,
        store_longitude: -73.6938,
        total_orders: 1412.8,
        neighborhood: '',
        keywords: 'Burger, Steak, Vegetarian, Beer, Whiskey, Cajun, Wine, Irish, Relaxed, American',
        annual_dol: 1442.78,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7188,
        store_longitude: -73.8137,
        total_orders: 1276.59,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Wine, Ale, Sweet, Friendly, Fun, Great, Casual',
        annual_dol: 1303.68,
        description:
            'Relaxed, no-frills outpost offering plenty of TVs & bar grub alongside beers, cocktails & shots.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.9911,
        total_orders: 1912.19,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Burger, Mexican, Whiskey, Coffee, Wine, Tasty, Bourbon, Delicious, Nachos',
        annual_dol: 2043.9,
        description:
            '90 draft & 100 bottled beers poured in a sprawling, bi-level space with TVs & a roof deck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8307,
        store_longitude: -73.6885,
        total_orders: 1490.04,
        neighborhood: '',
        keywords: 'Burger, Steak, Lobster, Pizza, Cheesesteak, Tequila, Beer, Wine, Bbq, American',
        annual_dol: 1594.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9936,
        total_orders: 1303.98,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Wine, Pizza, Italian, Coffee, Hotel, Beer, Warm, Urban, Sustainable, Sleek',
        annual_dol: 3360.16,
        description:
            'The understated rooms provide Wi-Fi, flat-screen TVs and minifridges; some feature rainfall showers, interconnecting bedrooms, city views, and/or furnished balconies or terraces. Suites have living areas.There are loaner bikes (seasonal). Other amenities consist of a sleek restaurant/bar, a cafe, lounges, and a rooftop bar with city views. Breakfast is available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -73.9972,
        total_orders: 2707.96,
        neighborhood: 'Little Italy',
        keywords:
            'Salad, Italian, Dessert, Chicken Parmigiana, Bellini, Delicious, Rich, Godfather, Homemade, Sweet',
        annual_dol: 2825.37,
        description:
            'Classic red-sauce Italian fare can be ordered in large family-style servings at this casual eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.9024,
        total_orders: 112.79,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Steak, Upscale, Delicious, Exotic, Rooftop, Fine, Casual',
        annual_dol: 153.54,
        description:
            '"Gentlemens Club minutes from Laquardia Airport featuring beautiful entertainers, Full bar, full kitchen serving lunch Mon to Sat 12pm, we feature private VIP rooms, bottle service."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6574,
        store_longitude: -73.8392,
        total_orders: 227.58,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Italian, Coffee, Comfortable, Perfect, Premium, Sweet, Friendly, Outstanding, Amazing, Great',
        annual_dol: 237.44,
        description:
            '"The perfect venue to host your private celebration! From Weddings to Anniversaries, Sweet 16\'s and Retirement parties, and even corporate catering events, the experts at Roma View will make it special."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7413,
        store_longitude: -73.597,
        total_orders: 2444.16,
        neighborhood: '',
        keywords:
            'Steak, Burger, Pizza, Shrimp Cocktail, Wine, Italian, Spicy, Trendy, American, Delicious',
        annual_dol: 2524.14,
        description:
            'New American eatery serving brick-oven pizzas, steaks & seafood, plus a wine cellar with 500 labels.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9941,
        total_orders: 244.47,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Cheesecake, Pizza, Italian, Upscale, Stylish, Delicious, Wine, Trendy, Warm, Martini',
        annual_dol: 255.07,
        description:
            'Pizza, pasta & happy hour specials in a chic dining room with a marble bar & antique decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -73.9993,
        total_orders: 751.24,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Lobster, Lobster Roll, Martini, Beer, Coffee, Savory, Delicious, Upscale, Gin, Rum',
        annual_dol: 2637.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7231,
        store_longitude: -74.003,
        total_orders: 2574.67,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Burger, Salad, Beer, Nachos, Dessert, Wine, Homemade, American, Cozy, Friendly',
        annual_dol: 2629.3,
        description:
            'Longtime local bar (since 1972) with draft beers, pub grub & an unpretentious, friendly vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7793,
        store_longitude: -73.9547,
        total_orders: 777.93,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Beer, Pizza, Wine, Whiskey, Hotel, Fruity, Cointreau, Casual, Great',
        annual_dol: 2967.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7187,
        store_longitude: -74.0012,
        total_orders: 831.87,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Vegetarian, Coffee, Chinese, Organic, Upscale, Delicious, Asian, Contemporary, Modern, Casual',
        annual_dol: 972.48,
        description:
            'Cantonese-focused Chinese fare offered in a contemporary space with decorative wood screens.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -74.0001,
        total_orders: 2582.98,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Pizza, Wine, Salad, French Toast, Dessert, Hotel, Beer, Trendy, French Onion Soup, French',
        annual_dol: 2896.6,
        description:
            'Featuring floor-to-ceiling windows with city views, the sophisticated rooms provide minibars, free Wi-Fi and flat-screen TVs, as well as high-thread-count sheets, marble bathrooms and designer toiletries. Upgraded rooms add sitting areas, while suites provide living rooms.Amenities include a haute, terrarium-style restaurant with a bar, as well as a cocktail bar and a 24/7 fitness center. Valet parking is available for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7924,
        store_longitude: -73.9462,
        total_orders: 276.64,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Tasty, Delicious, Wine, Upscale, Cozy, Exquisite, Fun, Awesome, Friendly, Playful',
        annual_dol: 811.8,
        description:
            '"Perch is a 25 seat small plate shared dining experience with signature cocktails & a curated wine list. We are located in East Harlem NYC."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7165,
        store_longitude: -74.0079,
        total_orders: 287.92,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Mexican, Vodka, Margarita, Enchiladas, Spicy, Upscale, Contemporary, Casual, Local, Fun',
        annual_dol: 325.38,
        description:
            'Elevated Mexican cuisine in a festive dining room, plus tequila-based cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9948,
        total_orders: 351.46,
        neighborhood: 'Little Italy',
        keywords: 'Tequila, Whiskey, Vodka, Champagne, Gin, Rum, Spanish, Spicy, Hotel, American',
        annual_dol: 538.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7779,
        store_longitude: -73.9562,
        total_orders: 274.71,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Italian, Pizza, Shrimp Cocktail, Wine, Upscale, Friendly, Authentic, Welcoming, Perfect',
        annual_dol: 297.18,
        description:
            'Welcoming stalwart for fine Italian fare with paneled walls, white tablecloths & a regular crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7784,
        store_longitude: -73.9625,
        total_orders: 2915.93,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Tequila, Tequila Sunrise, Pizza, Delicious, Italian, Premium, American, Outstanding, Memorable, Great',
        annual_dol: 2996.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7571,
        store_longitude: -73.6424,
        total_orders: 646.37,
        neighborhood: '',
        keywords:
            'Burger, Steak, Shrimp Cocktail, Tasty, Bbq, Organic, American, Premium, Casual, Great',
        annual_dol: 2269.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6534,
        store_longitude: -73.8382,
        total_orders: 1794.6,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Wine, Italian, Glamorous, Elegant, Gourmet, Exquisite, Innovative, Fun, Sweet, Great',
        annual_dol: 2513.55,
        description:
            '"Tradition Transformed With a modern perspective Since we first opened our doors in 1987, Russo’s On The Bay has continually set new standards for innovative cuisine, personalized service and uncompromising attention to detail, all delivered in a stunning waterside facility with panoramic views of the bay. Distinguished by its luxe contemporary décor, world class amenities and infinitely-adaptable spaces for parties of all sizes, Russo’s is a one-of-a-kind home for once-in-a-lifetime events."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.609,
        store_longitude: -73.9327,
        total_orders: 9.49,
        neighborhood: 'Marine Park',
        keywords: 'American, Global, Local, Foreign',
        annual_dol: 38.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -73.5666,
        total_orders: 2166.98,
        neighborhood: '',
        keywords: 'Wine, Coffee, Dessert, Delicious, Classic, Great, Local',
        annual_dol: 5363.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6911,
        store_longitude: -73.8212,
        total_orders: 234.07,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords: 'Italian, Wine, Zen, Fusion, Sweet, Fried Rice, Caribbean, Great, Casual',
        annual_dol: 956.78,
        description:
            '"Follow us for all updates! IG:@ZestLoungeNY @ZestLoungeNYC TikTok: @ZestLoungeNY"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.985,
        total_orders: 852.88,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Tacos, Burger, Mexican, Beer, Vegetarian, Salad, American, Mediterranean, Hotel, Irish',
        annual_dol: 3423.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7884,
        store_longitude: -73.6401,
        total_orders: 462.65,
        neighborhood: '',
        keywords:
            'Salad, Chicken Parmigiana, Pizza, Italian, Warm, Clean, Homey, Romantic, Smooth, Caesar Salad',
        annual_dol: 482.71,
        description:
            'Long-running neighborhood go-to for red-sauce Italian fare served in a formal yet homey space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7035,
        store_longitude: -74.011,
        total_orders: 2367.11,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Tasty, Coffee, Gin, Warm, Irish, Comfortable, American, Seasonal, Amazing',
        annual_dol: 3784.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -74.0127,
        total_orders: 45484.08,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Tacos, Steak, Mexican, Tequila, Nachos, Beer, Margarita, Salad, Burritos, Trendy',
        annual_dol: 53705.06,
        description:
            'Bustling haunt for modern Mexican fare & cocktails in rustic-chic, art-decorated surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -74.008,
        total_orders: 1375.89,
        neighborhood: 'Hudson Square',
        keywords:
            'Hotel, Trendy, Phenomenal, Rooftop, Scandinavian, Picturesque, Australian, Classic, Exceptional, Great',
        annual_dol: 1467.35,
        description:
            'Contemporary rooms with a Scandinavian vibe offer complimentary Wi-Fi and flat-screens, plus Bluetooth speakers and USB charging stations. All have minifridges, desks and rainfall showers; some feature bunk beds and/or city views. Upgraded rooms add furnished terraces.Loaner bikes are offered. Amenities include a hip New American restaurant and 2 bars (1 set on the roof), plus a 24-hour market, a courtyard and co-working space. Parking and meeting facilities are available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7935,
        store_longitude: -73.941,
        total_orders: 403.75,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Wine, Gourmet, Gin, Delicious, Spanish, Quaint, Gracious, Premium, Comfortable, French',
        annual_dol: 549.63,
        description:
            '"Tastings’ mission is to cultivate special relationships, creating experiences to suit distinct needs and personality while providing hospitality, gracious service and exceptional food in a lovely setting. Tastings NYC curates a bespoke experience and works within your budget no matter what occasion, style and number of guests. Our dedicated multicultural tastemakers, culinary and service team translate their passion for food, wine, cocktails and entertaining to turn your vision into reality."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7818,
        store_longitude: -73.834,
        total_orders: 2088.78,
        neighborhood: 'College Point/Flushing',
        keywords:
            'Cajun, Wine, Salad, Beer, New-York-Style, Chicken Wings, French, Spicy, American, Latin',
        annual_dol: 2133.1,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.9573,
        total_orders: 1489.63,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Lobster Roll, Lobster, Burger, Cheesecake, Cheesesteak, Steak, Salad, Hamburger, Dessert, New-York-Style',
        annual_dol: 1527.62,
        description: 'Popular neighborhood joint serving pub grub & pints to a lively crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8152,
        store_longitude: -73.8166,
        total_orders: 21323.06,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Steak, Italian, Wine, Beer, Heritage, Signature, Great',
        annual_dol: 81054.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.924,
        total_orders: 601.73,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Beer, Ale, Wine, Irish, Casual, Quaint, Local, Spacious, Chill, Cool',
        annual_dol: 627.82,
        description:
            'This unpretentious watering hole with sports on TV & many craft beers also has a covered patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9828,
        total_orders: 17387.92,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Coffee, Clean, Authentic, Chic, Modern, Friendly, Great, Contemporary',
        annual_dol: 19803.65,
        description:
            'Modern Art.Airy rooms offer Wi-Fi, minibars, Nespresso machines, flat-screen TVs and Bluetooth speakers. Upgraded rooms add sitting areas and city views. Contemporary suites sleep up to four and have extra living space; an upgraded suite also has a balcony.A breakfast buffet is available. A chic terrace lounge with park views serves a menu of tapas and cocktails. There are also meeting rooms, a fitness center, a sauna, and a lobby lounge with a fireplace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6559,
        store_longitude: -74.0074,
        total_orders: 764.41,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Burger, Hamburger, Pizza, Beer, American, Wine, Trendy, Hotel, Casual, Local',
        annual_dol: 799.07,
        description:
            'At the Industry City food hall, this stand provides classic burgers & fries, plus milkshakes & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7165,
        store_longitude: -74.0095,
        total_orders: 4035.22,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Tequila, Lemon Meringue Pie, Sushi, Wine, Dessert, Applejack, Mimosa, Italian, Rum, Trendy',
        annual_dol: 4605.07,
        description:
            'Bold farm-to-table cuisine in candlelit, rustic-chic environs attracts romance-minded foodies.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7743,
        store_longitude: -73.9481,
        total_orders: 1216.4,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Hotdog, Tacos, Beer, Salad, Vodka, Martini, Irish, Rich, Delicious',
        annual_dol: 1376.37,
        description:
            '"The Five Lamps is a contemporary take on a classic Irish pub. We aim to provide an inviting and comfortable atmosphere where you can enjoy a pint and good conversation!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7774,
        store_longitude: -73.951,
        total_orders: 8125.39,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Mexican, Tequila, Salad, Nachos, Gourmet, Tasty, Upscale, Tex-Mex, Fajitas, Crisp',
        annual_dol: 8431.29,
        description:
            'Locals patronize this longtime neighborhood spot for its classic Mexican dishes & festive feeling.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8669,
        store_longitude: -73.6306,
        total_orders: 1083.77,
        neighborhood: '',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Seafood Paella, Vegetarian, Wine, Meatball, Italian, Beer, Mediterranean',
        annual_dol: 1168.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6911,
        store_longitude: -73.8215,
        total_orders: 483.48,
        neighborhood: 'South Richmond Hill',
        keywords:
            'Chinese, Spicy, Asian, Caribbean, Elegant, Fried Rice, Friendly, American, Brazilian, Casual',
        annual_dol: 515.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.9202,
        total_orders: 148.35,
        neighborhood: 'Astoria/Sunnyside',
        keywords:
            'Pizza, Italian, Delicious, Spicy, Creamy, Spectacular, Bruschetta, Sensational, Friendly, Vintage',
        annual_dol: 260.08,
        description:
            'Neighborhood Italian spot for wood-fired pies by a pizzaiolo from Naples, plus panini & antipasti.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.829,
        store_longitude: -73.8244,
        total_orders: 239.84,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Burger, Salad, Beer, Delicious, Savory, Nachos, Legendary, Swiss, French Onion Soup, French',
        annual_dol: 346.71,
        description: '"Steaks-Beers-Sports"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.702,
        store_longitude: -74.0107,
        total_orders: 3026.51,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Italian, Hotel, Old Fashioned, Magical, Organic, Eclectic, Modern, Classic, Sophisticated, Amazing',
        annual_dol: 8717.28,
        description:
            "National Monument and 10 miles from LaGuardia Airport.Chic rooms with mahogany furnishings have Wi-Fi, flat-screens and minibars, plus coffeemakers, and patios or river views. Suites add sitting areas; upgraded suites provide living rooms. Room service is available 24/7.Amenities consist of a swanky spa, a gym and an organic cafe. Guests have access to a private members club with a restaurant and a bar. There's also breakfast and valet parking.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7177,
        store_longitude: -73.857,
        total_orders: 374.3,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Salad, Roast Beef, Wine, German, Beer, Hungarian, Bbq, French, Delicious',
        annual_dol: 458.12,
        description:
            'Beerhouse pairing steins of imported suds with classic German fare, live bands & a roomy space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7795,
        store_longitude: -73.96,
        total_orders: 174.17,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Wine, Italian, Cozy, Warm, Inviting, Organic, Perfect, Lively, Authentic, Great',
        annual_dol: 711.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7051,
        store_longitude: -73.9247,
        total_orders: 637.43,
        neighborhood: 'Bushwick',
        keywords: 'Coffee, Wine, Beer, Delicious, Warm, Cozy, Fun, Wonderful, Perfect, Great',
        annual_dol: 1642.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.998,
        total_orders: 23982.22,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Wine, Stylish, Trendy, Delicious, Chinese, Quaint, Organic, Classy, Amazing, Great',
        annual_dol: 25050.9,
        description:
            'Mixologists in pharmacist-style garb whip up complex cocktails at this off-the-beaten path hideaway.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6367,
        store_longitude: -73.7025,
        total_orders: 1860.13,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Wine, Gourmet, Martini, Meatball, Fried Calamari, Spicy, Beer',
        annual_dol: 1899.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.6129,
        total_orders: 572.92,
        neighborhood: '',
        keywords:
            'Lasagna, Pizza, Italian, Meatball, Wine, Shrimp Scampi, Homemade, Greek, Casual, Scottish',
        annual_dol: 631.49,
        description:
            'Chic trattoria with an old-country Italian vibe presenting a huge menu of Italian favorites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6549,
        store_longitude: -73.6352,
        total_orders: 4486.55,
        neighborhood: '',
        keywords:
            'Cheesecake, Vegetarian, Salad, Wine, Dessert, Coffee, Scotch, Organic, Gin, French',
        annual_dol: 4649.97,
        description:
            'Relaxed venue with a contemporary vibe prepping comfort food like meatloaf, plus chicken & waffles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6572,
        store_longitude: -73.6446,
        total_orders: 1141.17,
        neighborhood: '',
        keywords: 'Pizza, Burger, Italian, Salad, Beer, Martini, Wine, Exceptional, Fresh, Casual',
        annual_dol: 1478.49,
        description:
            'Local-favorite spot serving thin-crust pizzas, calzones & generously portioned pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6141,
        store_longitude: -73.9272,
        total_orders: 7.51,
        neighborhood: 'Flatlands',
        keywords:
            'Steak, Roast Turkey, Shrimp Cocktail, Bbq, American, Italian, Caesar Wrap, Floridian, Filet Mignon, Casual',
        annual_dol: 30.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7948,
        store_longitude: -73.6834,
        total_orders: 487.8,
        neighborhood: '',
        keywords: 'Steak, Tacos, Salad, Wine, Dessert, Delicious, Savory, Italian, Organic, Warm',
        annual_dol: 501.16,
        description:
            'Refined spot open since 1969 featuring French cuisine & old-world ambience, plus a separate lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6392,
        store_longitude: -73.6605,
        total_orders: 1510.05,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Tacos, Beer, Delicious, French, Radiant, Fun, Clam Chowder, Bright',
        annual_dol: 1898.05,
        description:
            'Eatery & bar featuring lobster rolls, tacos & cocktails, plus live music & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.719,
        total_orders: 86.95,
        neighborhood: 'Jamaica/Bellerose Manor',
        keywords:
            'Steak, Cheesesteak, Pizza, Tasty, American, Philly Cheesesteak, French, Irish, Homemade, Reuben Sandwich',
        annual_dol: 152.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.5921,
        total_orders: 39.98,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Salad, Burritos, Warm, Healthy, Local, Premium, Casual, High-Protein',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6635,
        store_longitude: -73.7191,
        total_orders: 4041.86,
        neighborhood: '',
        keywords:
            'Burger, Tasty, Whiskey, Shrimp Cocktail, Beer, Margarita, Bourbon, Italian, Delicious, Legendary',
        annual_dol: 4127.63,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6554,
        store_longitude: -73.6459,
        total_orders: 192.31,
        neighborhood: '',
        keywords: 'Wine, Bourbon, Beer, Martini, Gin, Premium, Ample, Comfortable, Great, Intimate',
        annual_dol: 786.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8231,
        store_longitude: -73.8366,
        total_orders: 716.76,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Steak, Wine, Dessert, American, Delicious, Classic, Casual, Lively, Local, Great',
        annual_dol: 858.94,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6083,
        store_longitude: -73.9598,
        total_orders: 79.95,
        neighborhood: 'Homecrest',
        keywords: 'Mexican, Tacos, Burritos, Delicious, Organic, Local, Casual, Signature',
        annual_dol: 418.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7044,
        store_longitude: -74.01,
        total_orders: 13078.57,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Beer, Burger, Whiskey, Irish, Ale, French Toast, Smoky, American, French',
        annual_dol: 14735.98,
        description:
            'Relaxed tavern for beers & sandwiches, plus live bands, DJs & tables on the cobblestoned sidewalk.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7039,
        store_longitude: -74.0123,
        total_orders: 506.35,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tequila, Beer, Fun, Golfclub, Awesome, Perfect, Clean, Great, Dope, Urban',
        annual_dol: 678.61,
        description:
            '"With 9 indoor golf simulators, Five Iron Golf - FiDi is the perfect space to get professional golf lessons, to host your next corporate event, or to just hang out! Whether you\'re here for practice, play, or fun FiDi can provide you with a one of a kind experience including a full bar, pool table, great food, and multiple full screen TV\'s to keep you up to date with everything sports. With a few friends or on your own FiDi is the perfect space to perfect your swing!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -74.0103,
        total_orders: 5316.29,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Tequila, Steak, Margarita, Quesadillas, Dessert, Upscale, Casual, Homemade, Traditional',
        annual_dol: 5811.15,
        description:
            'Stone Street spot with a festive after-work scene, serving Mexican standards & margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7463,
        store_longitude: -73.8951,
        total_orders: 372.99,
        neighborhood: 'Jackson Heights/Woodside',
        keywords:
            'Tequila, Mexican, Pizza, Beer, Spanish, Australian, Russian, Tibetan, Dolma, Fusion',
        annual_dol: 961.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7153,
        store_longitude: -73.833,
        total_orders: 2782.84,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Steak, Tacos, Meatloaf, Cajun, Pizza, Salad, Beer, Vodka, Bbq, Thai',
        annual_dol: 2843.37,
        description:
            'Neighborhood pub/sandwich shop serving beers, burgers, wings & other comfort eats in casual digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8306,
        store_longitude: -73.6935,
        total_orders: 167.71,
        neighborhood: '',
        keywords:
            'Wine, Steak, Gluten Free, Pizza, Beer, Dessert, Delicious, Italian, Warm, Opulent',
        annual_dol: 241.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7194,
        store_longitude: -73.9969,
        total_orders: 602.42,
        neighborhood: 'Little Italy',
        keywords:
            'Tacos, Wine, Spanish, Romantic, Trendy, Gin, Delicious, Mediterranean, Rustic, Classy',
        annual_dol: 971.9,
        description:
            'Informal Spanish storefront restaurant & tapas bar featuring Galician fare with wines & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6916,
        store_longitude: -73.8126,
        total_orders: 1.23,
        neighborhood: 'South Richmond Hill',
        keywords:
            'Burger, Steak, Whiskey, Wine, Chinese, Ale, Fried Rice, Caribbean, Prime Rib, Seasonal',
        annual_dol: 5.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7115,
        store_longitude: -73.5443,
        total_orders: 2941.9,
        neighborhood: '',
        keywords: 'Burger, Steak, Meatball, Salad, Whiskey, Beer, Bourbon, Korean, French, Modern',
        annual_dol: 3471.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8729,
        store_longitude: -73.623,
        total_orders: 23.52,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Chicken Parmigiana, Spicy, Bruschetta, Homemade, Polished, Casual, Local',
        annual_dol: 96.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.716,
        store_longitude: -74.0075,
        total_orders: 213.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Rich, Upscale, Stylish, Elegant, Wine, Hotel, Polish, Premium, Awesome, Lively',
        annual_dol: 807.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -73.9968,
        total_orders: 31.73,
        neighborhood: 'Little Italy',
        keywords:
            'Cheesecake, Hotdog, Salad, Italian, Coffee, Rich, French, Hotel, Quaint, Tiramisu',
        annual_dol: 129.71,
        description:
            'Quaint bakery serving housemade gelato & other Italian treats like cannoli & tiramisu since 1891.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7452,
        store_longitude: -73.9072,
        total_orders: 332.42,
        neighborhood: 'Woodside',
        keywords: 'Burger, Steak, Beer, Dessert, Bbq, Irish, Warm, American, Omelette, Classy',
        annual_dol: 370.69,
        description:
            "Longtime neighborhood hangout dishing up burgers & Irish-leaning pub fare like shepherd's pie.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7775,
        store_longitude: -73.9518,
        total_orders: 382.03,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'German, Salad, Austrian, Beer, Wine, Cozy, Classic, Southern, Authentic, Casual',
        annual_dol: 495.55,
        description:
            'This circa-1936 Yorkville eatery boasts rib-sticking German grub & plenty of beer to wash it down.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8089,
        store_longitude: -73.9306,
        total_orders: 5841.22,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Sustainable, Great, Dessert, Intimate, Local',
        annual_dol: 14320.71,
        description:
            '"Great Performances plans, staffs and caters events for clients of all sorts and celebrations of all sizes. From small, intimate gatherings to large, elaborate productions, we bring professionalism, skill and care to each client project. Our range of capabilities includes full-service, catering, beverage service, service staffing, venue selection, design and special event production. We also operate cafés, restaurants, concessions and amenity programs for nonprofit and corporate institutions."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7749,
        store_longitude: -73.957,
        total_orders: 88.38,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Sushi, Japanese, Delicious, Sashimi, Asian, Comfortable, Fusion, Casual, Local',
        annual_dol: 361.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7811,
        store_longitude: -73.8416,
        total_orders: 68.33,
        neighborhood: 'College Point/Flushing',
        keywords: 'Meatball, Italian, Japanese, French, American, Southern, Fun, Complex, Great',
        annual_dol: 145.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6565,
        store_longitude: -73.6469,
        total_orders: 1997.31,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Vegetarian, Hamburger, Gluten Free, Beer, Organic, Dessert, Sustainable, Delicious',
        annual_dol: 2130.08,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6739,
        store_longitude: -73.6263,
        total_orders: 592.98,
        neighborhood: '',
        keywords: 'Beer, Golfclub, Seasonal, Local, Recommended, Amazing',
        annual_dol: 2459.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5988,
        store_longitude: -73.6552,
        total_orders: 424.51,
        neighborhood: '',
        keywords:
            'Flavorful, Meatball, Beer, Wine, Spanish, Italian, Fun, American, Sangria, Authentic',
        annual_dol: 594.71,
        description:
            '"Flavors of Spain in a festive casual atmosphere specializing in a wide array of tapas, seafood paella, a good selection of fine meats and flavorful sangria to satisfy all appetites."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8896,
        store_longitude: -73.6249,
        total_orders: 412.86,
        neighborhood: '',
        keywords:
            'Burger, Steak, Lobster, Pizza, Salad, Shrimp Cocktail, Flavorful, Italian, Dessert, Classy',
        annual_dol: 465.07,
        description:
            '"Executive Chef Janine Dimenna, a Glen Cove native took the town by storm with her exclusive dishes that could never be found any where else. With many honors such as the Food Critic Award, this critically acclaimed chef raised the bar for excellence. Chef Jeanine Dimenna has one of the highest honors of merit, Food Network which even recognized her talent on the TV strides Kitchen Casino."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7516,
        store_longitude: -73.9857,
        total_orders: 1014.02,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Upscale, Warm, Vivid, Hotel, Awesome, Salad, Amazing, Fun, Fantastic, Premium',
        annual_dol: 1057.98,
        description:
            '"Three floors filled with amazing ambiance, beautiful sexy entertainers, four stages with up close seating, incredible lighting and sound, large screen HD TV\'s on all floors to watch all sporting events, private vip rooms with outdoor terrace, bottle service and much much more"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7031,
        store_longitude: -74.0103,
        total_orders: 29.63,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Tacos, Mexican, Salad, Burritos, Delicious, Local, Casual, Premium, Fresh, Diverse',
        annual_dol: 121.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7188,
        store_longitude: -74.009,
        total_orders: 1208.39,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Korean, Wine, Contemporary, Modern, Innovative, Traditional, Exceptional, Bibimbap, Excellent, Scallops',
        annual_dol: 1601.17,
        description:
            'Innovative, high-end Korean fare served in elegant, contemporary digs with an extensive wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6718,
        store_longitude: -73.8572,
        total_orders: 113.4,
        neighborhood: 'The Hole/Jamaica/Howard Beach',
        keywords:
            'Lobster, Salad, Shrimp Cocktail, Cajun, Lobster Bisque, Tasty, Spicy, Creole, Clam Chowder, Perfect',
        annual_dol: 470.85,
        description:
            '"We\'re proud of our "perfect cook," just how seafood is meant to be enjoyed! Be as saucy as you want with our collection of 6 tasty recipes including our signature Hook & Reel special blend. How spicy do you like it?"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7745,
        store_longitude: -73.9544,
        total_orders: 5.21,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Lasagna, Chicken Parmigiana, Salad, Shrimp Scampi, Italian, Spicy, Homemade, Caesar Salad, Casual, Fresh',
        annual_dol: 21.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8231,
        store_longitude: -73.8366,
        total_orders: 71.02,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Casual, Warm',
        annual_dol: 320.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7005,
        store_longitude: -73.9257,
        total_orders: 314.75,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Vegan, Vegetarian, Zombie, Beer, Tasty, Italian, Margarita, American, Lively',
        annual_dol: 328.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6036,
        store_longitude: -73.6512,
        total_orders: 1228.98,
        neighborhood: '',
        keywords: 'Mexican, Wine, Beer, Italian, Spanish, American, Classy, Modern, Asian, Sangria',
        annual_dol: 1228.98,
        description:
            '"A Modern American restaurant who specializes in local seafood. We are the every day, special occasion, don\'t want to make dinner tonight restaurant!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -74.0082,
        total_orders: 944.64,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Sushi, Upscale, Flavorful, Wine, Beer, Hotel, Sustainable, Rooftop',
        annual_dol: 1191.28,
        description:
            'Prime steaks & seafood are served in a fine dining setting with a hip rooftop atrium.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5967,
        store_longitude: -73.6553,
        total_orders: 2880.04,
        neighborhood: '',
        keywords:
            'Elegant, Romantic, Picturesque, Outstanding, Fun, Great, Exceptional, Timeless, Excellent, Creative',
        annual_dol: 3161.13,
        description:
            '"Bridgeview specializes in celebrating one wedding & event at a time in our elegant waterfront estate. From the owners and managers to our helpful wait staff, you will enjoy the hospitality and personal attention that you deserve. Our creative chefs will delight you with a variety of cuisines."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8679,
        store_longitude: -73.6416,
        total_orders: 75.3,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Meatball, Salad, Mexican, Italian, Juicy, Greek',
        annual_dol: 216.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7206,
        store_longitude: -73.8454,
        total_orders: 1722.31,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Comfortable, Martini, Spacious, Fun, Classy, Cool, Amazing, Great, Excellent',
        annual_dol: 1835.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8101,
        store_longitude: -73.8035,
        total_orders: 73.09,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Burger, Hamburger, Pizza, Salad, Mexican, Beer, Italian, Dessert, Ale, American',
        annual_dol: 74.64,
        description:
            'Chill haunt offering draft beer, burgers, salads & American mains, plus live music & bridge views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8557,
        store_longitude: -73.6422,
        total_orders: 88.89,
        neighborhood: '',
        keywords:
            'Bbq, Italian, Gluten Free, Beer, American, Peruvian, Friendly, Great, Amazing, Casual',
        annual_dol: 290.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.5545,
        total_orders: 555.02,
        neighborhood: '',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Savory, Spicy, Bbq, Bourbon, Gracious, American',
        annual_dol: 1235.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7224,
        store_longitude: -74.0099,
        total_orders: 649.72,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Sushi, Sashimi, Japanese, Sweet, Seasonal, Intimate, Nostalgic, Local, Lively, Rare',
        annual_dol: 707.06,
        description:
            'Tucked under street level, this intimate spot features sushi crafted from fresh-from-Japan fish.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.718,
        store_longitude: -74.0014,
        total_orders: 242.64,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Vegetarian, Wine, Beer, Japanese, American, Friendly, Polished, Casual, Great, Amazing',
        annual_dol: 991.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7992,
        store_longitude: -73.9425,
        total_orders: 57.16,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Casual, American, Hummus',
        annual_dol: 233.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7261,
        store_longitude: -74.0085,
        total_orders: 163.6,
        neighborhood: 'Hudson Square',
        keywords:
            'Pizza, Italian, Salad, Wine, Modern, Fusion, Traditional, Classic, Exceptional, Creative',
        annual_dol: 186.62,
        description:
            'Tuscan spot offering quick-serve cafe fare by day & trattoria dishes with wine at night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -74.0102,
        total_orders: 1108.35,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Beer, Salad, Warm, Spacious, Legendary, Indulgent, Inviting, Charming, American',
        annual_dol: 1155.64,
        description:
            'Stone Street haunt with a decor of dark wood & chandeliers, offering many TVs & a standard bar menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7415,
        store_longitude: -73.5965,
        total_orders: 97.2,
        neighborhood: '',
        keywords: 'Local, Fabulous',
        annual_dol: 397.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7754,
        store_longitude: -73.9567,
        total_orders: 1419.24,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Pizza, Burger, Salad, Gluten Free, Vodka, Wine, Caesar Salad, Tasty, Casual, Delectable',
        annual_dol: 1973.64,
        description:
            '"Serving Detroit-style pizzas and an award-winning burger. Full service bar, local drafts & wine selection."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.717,
        store_longitude: -73.9995,
        total_orders: 1882.11,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Mexican, Shrimp Cocktail, Italian, Japanese, Wine, Delicious, Classy, Fusion, Casual, Fresh',
        annual_dol: 2468.69,
        description:
            'Circa-1943 Italian joint with a old-world vibe offering familiar red-sauce cooking & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -74.0111,
        total_orders: 2437.34,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Cheesesteak, Meatball, Spicy, French, Nachos, Philly Cheesesteak, Filet Mignon, Perfect',
        annual_dol: 3211.74,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.716,
        store_longitude: -74.0084,
        total_orders: 336.5,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Cajun, Champagne, Creole, Hurricane, Fun, Friendly, Polished, Exceptional, Negroni',
        annual_dol: 1375.5,
        description:
            '"Holywater is the bar we’ve always wanted to go to. Conceived over years of wild nights – in bars and on boats – Holywater celebrates the indulgences of our birthplace, New Orleans, and our adopted home, New York."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5799,
        store_longitude: -73.8375,
        total_orders: 184.81,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords: 'Wine, Pizza, Irish, Beer, Hotel, Comfortable, Perfect, Cool, Casual, Great',
        annual_dol: 206.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6604,
        store_longitude: -74.0006,
        total_orders: 5799.17,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Wine, Beer, Spacious, Cozy, Natural, American, Cool, Fantastic, Great, Fine',
        annual_dol: 6171.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -74.0103,
        total_orders: 564.67,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Lobster Roll, Burger, Dessert, Beer, Wine, Vodka, Delicious, Cozy, American',
        annual_dol: 584.44,
        description:
            'Posh chain serving American fare, including brunch, dinner & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7256,
        store_longitude: -74.0078,
        total_orders: 1049.47,
        neighborhood: 'Hudson Square',
        keywords:
            'Steak, Pizza, Italian, Meatball, Wine, Dessert, Beer, Delicious, New-York-Style, Stylish',
        annual_dol: 1286.71,
        description:
            'Pizzeria offering wood-fired pies, inventive plates & craft cocktails in a stylish interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7016,
        store_longitude: -73.9232,
        total_orders: 776.37,
        neighborhood: 'Bushwick',
        keywords:
            'Wine, Friendly, Fun, Sleek, Bright, Natural, Mediterranean, Excellent, Casual, Modern',
        annual_dol: 1087.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7105,
        store_longitude: -73.9232,
        total_orders: 1245.65,
        neighborhood: 'East Williamsburg',
        keywords: 'Warm, Memorable, Natural, Classic, Cultural, Creative, Rooftop',
        annual_dol: 1620.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7998,
        store_longitude: -73.9325,
        total_orders: 636.19,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Vegan, Local, Urban, Fresh',
        annual_dol: 2600.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7937,
        store_longitude: -73.6903,
        total_orders: 471.22,
        neighborhood: '',
        keywords:
            'Salad, Vegetarian, Pizza, Italian, Wine, Coffee, Beer, Upscale, Elegant, Impeccable',
        annual_dol: 506.13,
        description:
            'Elegant Italian bistro popular with locals for its upscale dishes & people-watching.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -74.0088,
        total_orders: 2742.56,
        neighborhood: 'Hudson Square',
        keywords: 'Hotel, Tacos, Upscale, Wine, Rich, Rooftop, Coffee, Cuban, Italian, Stylish',
        annual_dol: 2901.03,
        description:
            'Elegant, vintage-style rooms feature hardwood floors and contemporary photography, as well as flat-screen TVs, minifridges and Keurig coffeemakers. Suites add separate living spaces.Amenities consist of an exercise room and a business center, plus an Italian restaurant, and a hip rooftop cocktail bar with river views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6915,
        store_longitude: -74.0139,
        total_orders: 6144.52,
        neighborhood: 'Governors Island',
        keywords:
            'Mexican, Wine, Tex-Mex, Trendy, Modern, Seasonal, Casual, Perfect, Lively, Great',
        annual_dol: 30836.78,
        description:
            'Trendy outdoor Mexican eatery & bar with lively events & a palm-studded seating area on the bay.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6349,
        store_longitude: -73.965,
        total_orders: 837.3,
        neighborhood: 'Flatbush',
        keywords: 'Burger, Steak, Tacos, Sushi, Salad, Beer, Korean, American, Asian, Cozy',
        annual_dol: 1237.13,
        description:
            'Cozy gastropub serving an eclectic menu (lasagna & Korean sandwiches), plus exotic burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7076,
        store_longitude: -73.9209,
        total_orders: 167.23,
        neighborhood: 'Bushwick',
        keywords:
            'Salad, Italian, Meatball, Spicy, Delicious, Thai, Friendly, Sweet, Awesome, Rooftop',
        annual_dol: 480.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.8434,
        total_orders: 227.78,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Hamburger, Salad, Mexican, Pizza, Korean, Coffee, Beer, Spicy, Bbq, Asian',
        annual_dol: 931.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7276,
        store_longitude: -74.0036,
        total_orders: 2529.41,
        neighborhood: 'Hudson Square',
        keywords:
            'Lobster, Salad, Wine, Italian, French, Cozy, Mediterranean, Elegant, Classy, Fancy',
        annual_dol: 2691.78,
        description:
            'Snug, bright restaurant featuring a seasonal Southern Italian menu & unique cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -74.0078,
        total_orders: 1249.77,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Vegan, Salad, Nachos, Peruvian, Clean, Perfect, American, Gumbo, Casual, Traditional',
        annual_dol: 3606.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6663,
        store_longitude: -73.5518,
        total_orders: 1954.22,
        neighborhood: '',
        keywords:
            'Steak, Salad, Vodka, Dessert, Delicious, Bourbon, Italian, Juicy, Creamy, Irresistible',
        annual_dol: 2094.92,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7083,
        store_longitude: -73.9197,
        total_orders: 197.14,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Wine, Steak, Salad, Rustic, Beer, Cozy, Delicious, Quaint, Romantic, Vintage',
        annual_dol: 268.37,
        description:
            'Dark.& cozy, antiques-filled haunt offering live music alongside light bites & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6979,
        store_longitude: -73.9372,
        total_orders: 13281.59,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords:
            'Coffee, Wine, Vegetarian, Salad, Beer, Delicious, Romantic, American, Dope, Awesome',
        annual_dol: 13857.43,
        description:
            'Pastel-hued spot with a garden for Haitian-style cafe fare, coffee & cocktails, plus live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6677,
        store_longitude: -73.9873,
        total_orders: 255.95,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Pizza, Gin, Rum, Bourbon, British, Upscale, Irish, Traditional, Fantastic',
        annual_dol: 272.77,
        description:
            'Low-key British bar featuring small bites & imported draft beer, plus a backyard with picnic tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6837,
        store_longitude: -73.935,
        total_orders: 497.69,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Pizza, Chocolate Mousse, Italian, Spicy, Hotel, Casual, Amazing, Local, Tradition',
        annual_dol: 921.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9761,
        total_orders: 2948.58,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Vegetarian, Salad, Shrimp Cocktail, Wine, Shrimp Scampi, French, French Onion Soup, Upscale',
        annual_dol: 3011.14,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7765,
        store_longitude: -73.9568,
        total_orders: 10654.61,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Tacos, Mexican, Tequila, Steak, Wine, Italian, Ale, Delicious, Rustic, Thai',
        annual_dol: 13268.13,
        description:
            'Mexican eatery with modern takes on the cuisine & an extensive tequila list in a colorful room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7777,
        store_longitude: -73.9617,
        total_orders: 718.46,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Salad, Italian, Tasty, Wine, Upscale, Delicious, Classy, Fabulous, Superb',
        annual_dol: 857.04,
        description:
            'Sophisticated Italian bistro featuring old-world decor, classic cuisine & a massive wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7097,
        store_longitude: -73.8452,
        total_orders: 507.35,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Lasagna, Steak, Pizza, Salad, Italian, Vodka, Thai, Upscale, French',
        annual_dol: 542.96,
        description:
            'Brick-oven pizza, pasta & Mediterranean appetizers, plus grill items, in a family-friendly setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7307,
        store_longitude: -73.7426,
        total_orders: 692.09,
        neighborhood: 'Jamaica/Bellerose Manor/Queens Village',
        keywords:
            'Burger, Pizza, Italian, Flavorful, Shrimp Scampi, Delicious, Friendly, Traditional, Local, Great',
        annual_dol: 937.62,
        description:
            'Longtime locale for Italian fine dining featuring traditional dishes with housemade pastas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7754,
        store_longitude: -73.9563,
        total_orders: 562.83,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegan, Sushi, Salad, Meatball, Spicy, Wholesome, Sustainable, Healthy, Innovative, Asian',
        annual_dol: 578.96,
        description:
            '"Vegan Restaurant NYC. Full service, innovative restaurant offering the best in vegan and kosher fare with global influences on the Upper East side of Manhattan. Dine in, delivery, and takeout."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6652,
        store_longitude: -73.7249,
        total_orders: 3065.67,
        neighborhood: '',
        keywords:
            'Lobster, Pizza, Italian, Chicago-Style, Beer, Shrimp Scampi, Chicken Wings, American, Spicy, Seasonal',
        annual_dol: 3065.67,
        description:
            'Family-friendly chain outpost pairs familiar pub fare with an extensive list of house brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6568,
        store_longitude: -73.6475,
        total_orders: 679.17,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Gluten Free, Delicious, Upscale, Impeccable, Bruschetta, Fabulous, High-End, Perfect',
        annual_dol: 680.52,
        description:
            'Dimly lit, long-running Italian spot where tuxedoed waiters deliver classic fare & nightly specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7157,
        store_longitude: -73.7475,
        total_orders: 209.23,
        neighborhood: 'Jamaica/Queens Village',
        keywords: 'Upscale, Jamaican, Welcoming, Savory, Sleek, Intimate, Authentic, Casual',
        annual_dol: 446.6,
        description: '"Venue and private events"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5785,
        store_longitude: -74.1089,
        total_orders: 68.2,
        neighborhood: 'Midland Beach',
        keywords: 'Burger, Friendly, Casual, Great',
        annual_dol: 278.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -74.0031,
        total_orders: 3216.88,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Tasty, Salad, Wine, Delicious, Cozy, Mediterranean, Greek, Healthy, Lively, Robust',
        annual_dol: 4382.99,
        description:
            'Cozy option specializing in Greek classics including seafood & meat dishes, plus a robust wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8437,
        store_longitude: -73.8457,
        total_orders: 85.21,
        neighborhood: 'Westchester Square',
        keywords:
            'Sushi, Vegetarian, Shrimp Cocktail, Japanese, Spicy, Savory, Asian, Sashimi, Bbq, Fusion',
        annual_dol: 1109.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8493,
        store_longitude: -73.8533,
        total_orders: 0,
        neighborhood: 'Morris Park',
        keywords:
            'Lobster, Pizza, Steak, Salad, Shrimp Cocktail, Tasty, Italian, Lobster Bisque, Wine, Vodka',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7379,
        store_longitude: -74.0021,
        total_orders: 97.32,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Mexican, Tasty, Salad, Gluten Free, Burritos, Delicious, Tex-Mex, Creamy, Spanish',
        annual_dol: 397.8,
        description: 'Overstuffed burritos & margaritas pull a young crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7465,
        store_longitude: -73.8993,
        total_orders: 0,
        neighborhood: 'Flushing/Woodside',
        keywords:
            'Thai, Green Curry, Chinese, Asian, Pad Thai, Authentic, Bright, Casual, Great, Pad See Ew',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7883,
        store_longitude: -73.9768,
        total_orders: 783.98,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Coffee, Delicious, American, Irish, Unique',
        annual_dol: 2752.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8527,
        store_longitude: -73.8676,
        total_orders: 41.76,
        neighborhood: 'East Bronx/Pelham Parkway',
        keywords:
            'Salad, Coffee, Cuban, Delicious, Spanish, Dominican, Homemade, Latin, American, Eclectic',
        annual_dol: 48.8,
        description:
            'Latin fusion dishes & American fare like Cuban sandwiches, mofongo & eggs in modern dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6598,
        store_longitude: -73.5427,
        total_orders: 233.99,
        neighborhood: '',
        keywords:
            'Tequila, Tacos, Beer, Pizza, Mexican, Margarita, Hawaiian, Tasty, Italian, Fried Chicken',
        annual_dol: 301.74,
        description: '"Fun food and drinks for everyone!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6294,
        store_longitude: -74.1117,
        total_orders: 5.09,
        neighborhood: 'West Brighton',
        keywords:
            'Lasagna, Pizza, Italian, Coffee, Dessert, American, Karaoke, Homemade, Casual, Traditional',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6742,
        store_longitude: -73.7358,
        total_orders: 121.19,
        neighborhood: 'Laurelton/Rosedale',
        keywords:
            'Salad, Tasty, Savory, Caribbean, Jamaican, Warm, Classy, Authentic, Amazing, Great',
        annual_dol: 191.75,
        description:
            '"Nestled in the warm residential area of Laurelton, Queens there is no place better to experience beautiful cuisine, tasty people, amazing entertainment and breathtaking ambiance all in one location! // Seafood, Night Life, Caribbean Cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7701,
        store_longitude: -73.9576,
        total_orders: 448.1,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Kebab, Vegetarian, Salad, Pizza, Wine, Turkish, Beer, Dessert, Mediterranean, Delicious',
        annual_dol: 523.55,
        description:
            'Grilled fish, lamb & grape leaves, plus fixed-price lunches, in a no-frills dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.9957,
        total_orders: 184.33,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Coffee, Hotel, Friendly, Sweet, Outstanding, Iconic, Great, Local',
        annual_dol: 753.48,
        description:
            'The Stephen A. Schwarzman Building, commonly known as the Main Branch, 42nd Street Library or the New York Public Library, is the flagship building in the New York Public Library system in the Midtown Manhattan neighborhood of New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7045,
        store_longitude: -74.0104,
        total_orders: 5200.56,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Tasty, Mexican, Beer, Delicious, Vodka, Korean, Cozy, American, Modern',
        annual_dol: 5505.32,
        description:
            'Cozy, dimly lit pub known for small-batch beers & draft cocktails, plus late-night American eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8,
        store_longitude: -73.9099,
        total_orders: 31.79,
        neighborhood: 'Port Morris/West Bronx',
        keywords:
            'Burger, Salad, Delicious, Coffee, Hotel, Friendly, Greek, Fresh, Omelette, Great',
        annual_dol: 129.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7779,
        store_longitude: -73.9544,
        total_orders: 123.57,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Hotdog, Pizza, Beer, Italian, Wine, Delicious, American, French, Playful',
        annual_dol: 505.13,
        description:
            '"Playful, dimly lit restaurant. Serving satisfying shareable small plates, craft cocktails, great beer and wine. Come for the food and drinks, stay for the vibe!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9113,
        total_orders: 28.23,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Mexican, Margarita, Italian, American, Casual, Local, Whimsical, Signature',
        annual_dol: 31.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7339,
        store_longitude: -74.0021,
        total_orders: 3848.31,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Whiskey, Tasty, Martini, Delicious, Dope, Great, Casual',
        annual_dol: 3954.16,
        description:
            'Gay bar & National Historic Landmark, site of the 1969 riots that launched the gay rights movement.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -74.0029,
        total_orders: 958.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Italian, Delicious, Russian, American, Premium, Contemporary',
        annual_dol: 1023.82,
        description:
            'White-tablecloth American bistro with a clubby feel, plus ample outdoor seating & a raw bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -74.0061,
        total_orders: 2198.71,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Beer, Salad, Ale, Italian, Warm, Romantic, Irish, Spacious',
        annual_dol: 3688.31,
        description:
            'This homey pub with a fireplace & tin ceiling boasts a cut-above-the-usual American-Irish menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 23.49,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Mediterranean, Beer, Trendy, Romantic, Sangria, Classy, Intimate, Perfect',
        annual_dol: 96.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -74.0047,
        total_orders: 892.02,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Trendy, Fun, Premium, Awesome, Great, Local, Dynamic, Casual',
        annual_dol: 935.02,
        description:
            'Craft brews & bar bites from Greenpoint Beer Works served in a cavernous, industrial-chic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.634,
        total_orders: 441.75,
        neighborhood: '',
        keywords: 'German, Burger, Pizza, Salad, Beer, American, Belgian, Delicious, Clean, Local',
        annual_dol: 942.91,
        description:
            'German eatery & tavern serving traditional dishes & craft beer in a laid-back space with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7842,
        store_longitude: -73.9736,
        total_orders: 509.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Steak, Meatball, Salad, Tasty, Delicious, Mediterranean, Turkish, Greek, American',
        annual_dol: 579.83,
        description:
            'Mediterranean eats & craft cocktails served in a brick-lined, bistro-style space with a small patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7903,
        store_longitude: -73.9729,
        total_orders: 139.23,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Italian, Wine, Delicious, Romantic, Warm, Refined, Exquisite, Robust, Authentic, Classic',
        annual_dol: 243.82,
        description:
            'Refined eatery offering classic Italian dishes, seafood & steak, plus a robust wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.9945,
        total_orders: 11.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Salad, Italian, Healthy, Caesar Salad, Friendly, Contemporary, Casual, Fresh',
        annual_dol: 45.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6663,
        store_longitude: -73.5517,
        total_orders: 60.3,
        neighborhood: '',
        keywords:
            'Lobster, Sushi, Salad, Japanese, Fruity, Sashimi, Chinese, Asian, Fusion, American',
        annual_dol: 246.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7068,
        store_longitude: -73.9032,
        total_orders: 5.27,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Lobster, Steak, Spacious, Clean, Natural, Spectacular, Cool, Casual, Gyro',
        annual_dol: 21.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7693,
        store_longitude: -73.9628,
        total_orders: 1038.17,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Burger, Italian, Delicious, Rich, Wine, Upscale, Warm, Impeccable, Yummy',
        annual_dol: 1083.19,
        description:
            'Upscale Italian cuisine served in a snug space popular with Upper East Side neighbors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6944,
        store_longitude: -73.931,
        total_orders: 494.82,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Hotel, Coffee, Rooftop, Modern, American, Amazing, Great, Hip, Seasonal, Cool',
        annual_dol: 616.92,
        description:
            'Brooklyn Bridge.Modern, streamlined rooms with locally inspired art provide complimentary Wi-Fi, flat-screen TVs and designer toiletries. Some rooms add daybeds and sleep up to 3 guests.There’s a rustic-chic cafe with picnic tables and Pacific Northwest—style cuisine, and a seasonal rooftop lounge. Activities and live entertainment are offered in a laid-back event space. Bike rentals are available, and pets are welcome (surcharge).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7743,
        store_longitude: -73.9631,
        total_orders: 18428.61,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Upscale, Hotel, Legendary, Elegant, Delicious, French, Classic, Sleek, Mediterranean, Timeless',
        annual_dol: 19035.31,
        description:
            "subway.The plush rooms feature flat-screen TVs and free Wi-Fi, along with iPod docks and minibars. Upgraded rooms add skyline views, sitting areas, and/or soaking tubs and rainfall showers. Sleek suites offer separate living rooms; some have pianos, full kitchens and art collections. Room service is available 24/7.Dining options include an upscale restaurant, a vibrant bar, and the storied Cafe Carlyle. There's also an elegant spa, a hair salon and a gym.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8449,
        store_longitude: -73.91,
        total_orders: 49.53,
        neighborhood: 'West Bronx/Claremont',
        keywords: 'Beer, French, Southern, Homemade, Casual, Incredible',
        annual_dol: 262.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9453,
        total_orders: 330.35,
        neighborhood: 'Williamsburg',
        keywords: 'Whiskey, Wine, Beer, Tasty, Ale, Hotel, Delicious, Fancy, American, Friendly',
        annual_dol: 385.07,
        description:
            'Out-of-the-way East Williamsburg bar offering specialty cocktails & a speakeasy feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.73,
        store_longitude: -73.9553,
        total_orders: 55.62,
        neighborhood: 'Greenpoint',
        keywords:
            'Mexican, Tequila, Tacos, Vegan, Upscale, Delicious, Contemporary, Cozy, Modern, Classic',
        annual_dol: 354.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7791,
        store_longitude: -73.954,
        total_orders: 1234.21,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Sushi, Flavorful, Beer, Ramen, Savory, Spicy, Delicious, Japanese, Sweet, Smooth',
        annual_dol: 5493.94,
        description:
            'Sushi, teriyaki & other Japanese dishes are presented in a low-key, bi-level space with TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7745,
        store_longitude: -73.959,
        total_orders: 501.92,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Burger, Steak, Salad, Dessert, Delicious, Upscale, Bbq, Trendy, Mediterranean, Comfortable',
        annual_dol: 829.26,
        description:
            'Trendy restaurant specializing in creative cocktails & fusion kosher Mediterranean cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.655,
        store_longitude: -73.6736,
        total_orders: 419.95,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Salad, Hamburger, French Onion Soup, French, Greek, American, Greek Salad, Casual',
        annual_dol: 428.86,
        description:
            'Neighborhood American bar/eatery offering happy hours & down-home cuisine in relaxed surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6524,
        store_longitude: -73.6113,
        total_orders: 88.88,
        neighborhood: '',
        keywords: 'Burger, Pizza, Hamburger, American, Fun, Amazing, Great, Casual, Chill',
        annual_dol: 94.79,
        description:
            'Energetic spot for bowling, with party options, American food & lots of TVS, plus a lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -73.9305,
        total_orders: 15.97,
        neighborhood: 'East Williamsburg',
        keywords:
            'Lobster, Lobster Tail, Lobster Roll, Cajun, Spicy, Rich, Friendly, Awesome, Casual, Great',
        annual_dol: 65.29,
        description:
            '"BIBS UP, PHONES DOWN. IT\'S #SHAKINGTIME Dive headfirst into a bag of steaming hot seafood shaken in rich butter, an abundance of garlic, and a signature blend of Cajun spices. BUILT ON COMMUNITY: We believe in the power of food to bring us together to celebrate, laugh, and be in the moment. Fully immerse yourself in the rhythm of shelling and plucking out lumps of shellfish while the bag keeps your seafood hot and drenched in our signature sauce."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7821,
        store_longitude: -73.9806,
        total_orders: 12391.38,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Chinese, Wine, Dessert, Chili Chicken, Spicy, Korean, Thai, Cozy, Rustic, Bruschetta',
        annual_dol: 14182.55,
        description:
            'Creative dim sum & other modern, seasonal Chinese food in a cozy & rustic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7849,
        store_longitude: -73.9772,
        total_orders: 3908.67,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Steak, Whiskey, Beer, Jagermeister, Nachos, Irish, Ale, American, Friendly',
        annual_dol: 4375.58,
        description:
            'Narrow, literary-themed Irish pub whips up cocktails named after dead poets & food from a bar menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.8136,
        total_orders: 19.28,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Wine, Beer, Donut, Comfortable, Spanish, Classy, Casual, Great',
        annual_dol: 78.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7529,
        store_longitude: -73.9858,
        total_orders: 30.83,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Fun, Beer, Great',
        annual_dol: 126,
        description:
            '"Indoor Golf facility featuring private bays, instruction, and group availability."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8179,
        store_longitude: -73.5548,
        total_orders: 33.51,
        neighborhood: '',
        keywords: 'Golfclub, Hotel, Premium, Fun, Classic, Healthy',
        annual_dol: 136.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7008,
        store_longitude: -73.8994,
        total_orders: 41.09,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Vegetarian, Salad, Mexican, Beer, Wine, Nachos, Ale, Delicious, American, Homemade',
        annual_dol: 118.36,
        description:
            'Latin-Caribbean recipes served in a hip, 2-story destination with bar seating & a garden terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9574,
        total_orders: 4006.6,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Tasty, Spicy, Upscale, Cozy, French, Sweet, Mediterranean, Colorful, Turkish',
        annual_dol: 4263.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7376,
        store_longitude: -73.9537,
        total_orders: 2112.99,
        neighborhood: 'Greenpoint',
        keywords:
            'Hotel, French Toast, Coffee, Avocado Toast, French, Stylish, Organic, Modern, Rooftop, American',
        annual_dol: 2248.63,
        description:
            'Empire State Building.Featuring hardwood floors and artwork commissioned by local artists, the bright rooms have free Wi-Fi, flat-screen TVs, and tea and coffeemaking facilities, as well as balconies offering city views. Loft rooms and 1- to 2-bedroom apartments have kitchens, living rooms, and pull-out couches.Amenities include an industrial-chic restaurant with a wood bar and pendant lamps, in addition to a gym, a sauna and a steam room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.9678,
        total_orders: 163.65,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Warm, Delicious, American, Venezuelan, French, Classic, Great, Fine, Attentive',
        annual_dol: 619.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -73.9591,
        total_orders: 216.57,
        neighborhood: 'Greenpoint',
        keywords:
            'Lobster, Vegetarian, Italian, Tequila, Dessert, Wine, Delicious, Beer, Organic, Trendy',
        annual_dol: 312.2,
        description:
            'Hearty Puglia-influenced Italian fare served in a rustic-chic space that has a bar & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7542,
        store_longitude: -73.9939,
        total_orders: 946.66,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Stylish, Iconic, Coffee, Premium, Impressive, Tranquil, Modern, Casual',
        annual_dol: 1009.59,
        description:
            "Offering modern decor and custom-designed beds, the laid-back rooms have Wi-Fi (fee) and flat-screen TVs with premium channels, plus coffeemakers and minifridges. Some rooms provide skyline views. Room service is available.A casual glass-enclosed restaurant has a courtyard garden and a bar. There's also a 24/7 gym, along with 2 meeting rooms and a business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7539,
        store_longitude: -73.9999,
        total_orders: 268.7,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Coffee, Wine, Healthy, Premium, Contemporary, Modern, Classic, Casual, Fine, Global',
        annual_dol: 1098.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7755,
        store_longitude: -73.9537,
        total_orders: 3119.04,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Beer, Wine, Comfortable, American, Irish, Casual, Traditional, Eclectic, Cool, Fun',
        annual_dol: 3304.91,
        description:
            'Longtime family-owned sports bar with an old-school vibe, plus a pool table, darts & video games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7757,
        store_longitude: -73.9531,
        total_orders: 1726.68,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Salad, Beer, Bbq, Tasty, Spicy, Tex-Mex, American, Southern, Casual',
        annual_dol: 7213.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.6455,
        total_orders: 52.83,
        neighborhood: '',
        keywords: 'Salad, Beer, Brazilian, Great, Traditional, Excellent, Dutch, Casual',
        annual_dol: 215.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7161,
        store_longitude: -73.5977,
        total_orders: 291.53,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Burger, Salad, Cajun, Cajun-Style, Beer, Fried Shrimp, Gumbo, Creamy',
        annual_dol: 831.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7654,
        store_longitude: -73.9802,
        total_orders: 2613.32,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Chicken Parmigiana, Pizza, Salad, Vodka, Wine, Champagne, Italian, Beer, Meatball',
        annual_dol: 2697.25,
        description:
            'Tuscan trattoria with a bustling scene, Italian art on the walls & an antipasto bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.9874,
        total_orders: 1240.05,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Vegetarian, Vegan, Hamburger, Lobster, Lobster Roll, Whiskey, Nachos, Beer',
        annual_dol: 6408.31,
        description:
            'High-end sports-bar chain with a huge menu of New American fare & an extensive list of draft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7534,
        store_longitude: -73.9873,
        total_orders: 225.67,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Salad, Flavorful, Wine, Gluten Free, Savory, Elegant, Spicy, Martini, Thai, Asian',
        annual_dol: 1121.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7033,
        store_longitude: -74.0108,
        total_orders: 7709.98,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Whiskey, Burger, Steak, Scotch, Beer, Wine, Coffee, Irish, Ale, American',
        annual_dol: 10528.45,
        description:
            'This 2-story spot combines a lunch taproom with a parlor serving small plates & vintage cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -74.0068,
        total_orders: 3907.21,
        neighborhood: 'Hudson Square',
        keywords:
            'Vegan, Burger, Vegetarian, Salad, Coffee, Gluten Free, Wine, Avocado Toast, Bourbon, Rustic',
        annual_dol: 16099.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.5721,
        total_orders: 343.38,
        neighborhood: '',
        keywords: 'Luxurious, Elegant, Spacious, Spectacular, Outstanding, Perfect, Fine',
        annual_dol: 1121.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -74.009,
        total_orders: 6.93,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Sushi, Salad, Green Papaya Salad, Beer, Japanese, French, Ramen, Thai, Spicy',
        annual_dol: 28.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7757,
        store_longitude: -73.9535,
        total_orders: 498.85,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Beer, Salad, Comfortable, Swiss, Friendly, American, Fresh, Casual, Great, Local',
        annual_dol: 2039.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7706,
        store_longitude: -73.9538,
        total_orders: 384.01,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Salad, Wine, Beer, Japanese, Delicious, Homemade, Asian, Classy, Casual',
        annual_dol: 1569.69,
        description:
            'Traditional techniques are used at this compact local restaurant for Japanese favorites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6211,
        store_longitude: -73.7,
        total_orders: 18.13,
        neighborhood: '',
        keywords: 'Wonderful',
        annual_dol: 49.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6951,
        store_longitude: -73.9032,
        total_orders: 49.02,
        neighborhood: 'Bushwick/Flushing/Ridgewood',
        keywords:
            'Tequila, Wine, Mexican, Coffee, Rum, American, Latin, Mediterranean, Delicious, Cozy',
        annual_dol: 200.4,
        description: '"Bar Restaurant Serving great cocktails and delicious food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7047,
        store_longitude: -73.6407,
        total_orders: 7.23,
        neighborhood: '',
        keywords:
            'Lobster, Shrimp Scampi, Roast Beef, Glamorous, Exceptional, Filet Mignon, Great, Classic, Excellent, Rare',
        annual_dol: 41.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.77,
        store_longitude: -73.9337,
        total_orders: 7.55,
        neighborhood: 'Astoria',
        keywords: 'Wine, Vodka, Coffee, Beer, Italian, Hotel, Luxurious, Urban, Seasonal, Homemade',
        annual_dol: 30.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6678,
        store_longitude: -73.9838,
        total_orders: 2159.93,
        neighborhood: 'Park Slope',
        keywords: 'Whiskey, Beer, Wine, Cozy, Romantic, Quaint, Intimate, Casual, Classy, Local',
        annual_dol: 2225.47,
        description:
            'Bustling bar offering music, readings & films, plus a large selection of beers & spirits.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6705,
        store_longitude: -73.985,
        total_orders: 232.96,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Steak, Shrimp Cocktail, Mexican, Beer, Italian, Wine, Champagne, American, Colombian',
        annual_dol: 721.4,
        description:
            '"ABOVE PRIME STEAKS from SNAKE RIVER FARMS and VARLASSO SALMON HARVESTED NATURALLY, 20 BEERS ON DRAFT BEER FLIGHTS AND TASTING FAMOUS CHEF MICHAEL MCNEILLY""',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6764,
        store_longitude: -73.9867,
        total_orders: 1159.55,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Wine, Delicious, Modern, Perfect, Casual, Sweet, Local, Great, Chill',
        annual_dol: 1453.55,
        description:
            'Clever cocktails, local draft beers & bar bites offered in a laid-back, modern space with skylights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9187,
        total_orders: 1183.89,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, Beer, Delicious, Wine, Friendly, Perfect, Great, Casual, Local, Signature',
        annual_dol: 1209.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9669,
        total_orders: 249.15,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Mexican, Pizza, Salad, Beer, Nachos, Spicy, Homemade, Premium, Casual, Fresh',
        annual_dol: 271.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.9555,
        total_orders: 14492.64,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Pizza, Chocolate Cake, Italian, Mexican, Meatball, Wine, Mediterranean, American, Rustic, Asian',
        annual_dol: 15832.04,
        description:
            'Creative, modern takes on classic Italian fare plus wood-fired pizza in a stylishly rustic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -73.9597,
        total_orders: 465.65,
        neighborhood: 'Greenpoint',
        keywords: 'Cozy, Polish, Comfortable, Casual, American, Great, Unique',
        annual_dol: 643.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7085,
        store_longitude: -73.9436,
        total_orders: 187.6,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Vegetarian, Pizza, Lobster, Gluten Free, Italian, Beer, Wine, Lobster Bisque, Margarita, Organic',
        annual_dol: 340.1,
        description:
            'Rustic haunt for sourdough pizza with cocktails, beer & wine plus happy hour deals & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.991,
        total_orders: 1659.3,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Burger, Steak, Pizza, Salad, Dessert, Wine, Beer, Delicious, Juicy, French',
        annual_dol: 1731.24,
        description:
            'Drink specials & a split-level setting attract the after-work crowd to this large watering hole.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7529,
        store_longitude: -73.9838,
        total_orders: 3638.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Cheesecake, Hotel, Salad, Japanese, Mediterranean, Elegant, Organic, Fun, Modern, American',
        annual_dol: 3715.93,
        description:
            "Featuring hardwood floors, the sleek, minimalist rooms are equipped with flat-screen TVs and free Wi-Fi. Suites add separate living areas or terraces. Room service is offered 24 hours a day.There's a modern Japanese restaurant, a screening room and a fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -74.0017,
        total_orders: 1150.08,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Hotel, Champagne, Wine, Rich, Coffee, Beer, Eclectic, Sophisticated, Rooftop, American',
        annual_dol: 1447.43,
        description:
            'Empire State Building.Cozy, contemporary rooms, some with sleeping lofts, offer free Wi-Fi, flat-screen TVs and rainfall showers, as well as sitting areas, and tea and coffeemaking facilities (on request).Amenities include a casual cafe and a 24-hour gym. Area tours and excursions are available for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7537,
        store_longitude: -73.6565,
        total_orders: 171.42,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Salad, Juicy, Mediterranean, Greek, French, Yummy, Caesar Wrap, Classy',
        annual_dol: 182.68,
        description:
            'Gyros, moussaka & other familiar Greek meals round out the menu at this informal, strip-mall eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7764,
        store_longitude: -73.9566,
        total_orders: 247.67,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Tequila, Lobster, Steak, Wine, Beer, Dessert, Bourbon, Italian, Spicy, Belgian',
        annual_dol: 337.16,
        description:
            'Seafood & desserts plus variously prepared Prince Edward Island mussels in a casual setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -73.9861,
        total_orders: 26737.1,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Tequila, Wine, Beer, American, French, Elegant, Retro, Sangria, Classic, Casual',
        annual_dol: 30821.49,
        description:
            'Warmly lit bi-level industrial venue featuring elevated American cuisine, craft cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.9878,
        total_orders: 2693.59,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Burger, Lobster, Tasty, Wine, Beer, Upscale, Delicious, Elegant, Ribeye Steak',
        annual_dol: 3569.12,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7546,
        store_longitude: -73.9866,
        total_orders: 2473.84,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Tequila, Mexican, Margarita, Jambalaya, Tasty, Vegan, Bourbon, Tangy, Spicy, Delicious',
        annual_dol: 2526.34,
        description:
            'Mexican BBQ menus & cocktails combine at this lively, casual spin-off of a popular food truck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6287,
        store_longitude: -74.0741,
        total_orders: 331.36,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Burger, Steak, Vegetarian, Cajun, Italian, Wine, Beer, Turkish, Organic, Delicious',
        annual_dol: 772.48,
        description:
            "\"Wynwood Restaurant & Bar is your number 1 destination for Dinner, Night Life & Brunch on Staten Island. With a menu that is compiled of cuisines from around the world, and an atmosphere that transports you to Miami's Wynwood Walls, you'll be sure to have an experience unparalleled to any restaurant you've ever been. Hand Crafted cocktails, artisan street cuisine, the finest seafood, high end cuts of Prime steaks, friendly staff, Live music & entertainment all blend perfectly for an atmosphere that is not only sophisticated but simultaneously casual & inviting. Open 7 Days for Brunch, Dinner & Late Night, you can be sure to catch us at any time!\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6132,
        store_longitude: -73.9394,
        total_orders: 157.91,
        neighborhood: 'Marine Park',
        keywords: 'Steak, Burger, Salad, Wine, Vodka, Bourbon, Spicy, Bbq, Elegant, Crisp',
        annual_dol: 204.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7051,
        store_longitude: -74.0052,
        total_orders: 635.54,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Steak, Pizza, Salad, Meatball, Italian, Spanish, Avocado Toast, Modern, American',
        annual_dol: 2597.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8639,
        store_longitude: -73.6291,
        total_orders: 19.68,
        neighborhood: '',
        keywords: 'Vegetarian, Tequila, Vegan, Wine, Vodka, Beer, Mexican, Tasty, Homemade, Thai',
        annual_dol: 80.45,
        description:
            '"TAVA Fine Indian Cuisine is a family-owned fine Indian dining restaurant based in New York. Originated from the Persian word tāve, in Punjabi, Hindi, and Urdu, “Tava/Tawaa” means cooking pan. It is a large, convex, disc-shaped pan used primarily for cooking traditional Indian dishes such as roti, chapati, paratha, dosa, and many other types of flatbreads. Our passion for serving fresh Indian food straight from the tava inspired the concept of TAVA Fine Indian Cuisine. Our centuries-old, traditional Indian food recipes are cooked with fresh ingredients, and a unique blend of spices such as turmeric, cumin, coriander, and incorporate some modern and contemporary influences to give you the most extraordinary dining experience of your life."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6778,
        store_longitude: -73.6673,
        total_orders: 630.01,
        neighborhood: '',
        keywords:
            'Salad, Wine, Shrimp Cocktail, Gourmet, Italian, Upscale, Martini, French, Cozy, Warm',
        annual_dol: 646.08,
        description:
            'Storefront Italian eatery in a strip mall offering traditional cuisine in an upscale environment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6287,
        store_longitude: -74.0797,
        total_orders: 829.74,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Wine, Whiskey, Delicious, Romantic, Fun, Turkish, Relaxed, Great, Incredible, Mojito',
        annual_dol: 883.77,
        description:
            '"A candle it Cocktail Bar on Staten Island. Drinks and Food until 4AM Every night."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.589,
        total_orders: 561.88,
        neighborhood: '',
        keywords: 'Hotel, American, Modern, Traditional, Great, Friendly',
        annual_dol: 573.8,
        description:
            "All rooms offer traditional decor and custom-designed beds, plus flat-screen TVs, microwaves, minifridges and free WiFi. Suites add separate living areas and pull-out sofas.A buffet or cooked-to-order breakfast is available (fee), and there's an on-site restaurant serving American cuisine, as well as a low-key bar and a 24/7 pantry. There's also a fitness center and the indoor pool with a hot tub. Additional amenities include 2 meeting rooms and a business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.721,
        store_longitude: -74.0098,
        total_orders: 948.63,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Cheesecake, Wine, Savory, Trendy, Cozy, Spicy, Bbq, American, Classy, Modern',
        annual_dol: 1048.19,
        description:
            'Cozy sports bar with a large menu of American/Mexican pub food & happy-hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7187,
        store_longitude: -74.0022,
        total_orders: 79.95,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Mexican, Steak, Scandinavian, Local, Casual, Delicious',
        annual_dol: 388.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7747,
        store_longitude: -73.9538,
        total_orders: 277.39,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Wine, Italian, Stylish, Modern, Perfect, Bright, Casual, Classic, Tradition',
        annual_dol: 1133.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7066,
        store_longitude: -73.9238,
        total_orders: 22377.61,
        neighborhood: 'Bushwick',
        keywords: 'Funky, Fun, Wonderful, Friendly, Perfect, Creative, Complex, Unique',
        annual_dol: 26104.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 4.98,
        neighborhood: 'Bushwick',
        keywords: 'Tacos, Steak, Mexican, Margarita, Beer, Nachos, Wine, Dessert, Hip, Cozy',
        annual_dol: 20.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -74.0101,
        total_orders: 2786.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Burger, Salad, Crab Cakes, Wine, Dessert, American, Caesar Salad, Classic, Modern',
        annual_dol: 2943.5,
        description:
            'Modern steakhouse from Marc Forgione serving classic fare updated with NYC twists in posh surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -74.0128,
        total_orders: 329.62,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegan, Vegetarian, Salad, Wine, Pizza, Vodka, Italian, Upscale, Impeccable, Warm',
        annual_dol: 504.93,
        description:
            'Tableside presentations are offered at this upscale Italian spot with free antipasti, pastas & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5674,
        store_longitude: -74.1125,
        total_orders: 73.46,
        neighborhood: 'New Dorp/New Dorp Beach/#210',
        keywords:
            'Vegan, Vegetarian, Milkshake, Beer, Stylish, Aesthetic, Classic, Cool, Great, Creative',
        annual_dol: 300.29,
        description:
            'Stylish theater chain for new & classic films features cocktails & creative bites served seat-side.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.711,
        store_longitude: -73.8535,
        total_orders: 686.42,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Coffee, Wine, Whiskey, Italian, Mexican, Elegant, Warm, Friendly, Modern, Classic',
        annual_dol: 716.18,
        description:
            'Longtime Italian fixture for homestyle classics & fine wines served in a romantic, high-end setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8272,
        store_longitude: -73.9261,
        total_orders: 72.26,
        neighborhood: 'Concourse',
        keywords:
            'Steak, Burger, Vegan, Cheesesteak, Wine, French Toast, Beer, Margarita, Italian, Coffee',
        annual_dol: 196.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7105,
        store_longitude: -73.9232,
        total_orders: 1225.16,
        neighborhood: 'East Williamsburg',
        keywords:
            'Vietnamese, Delicious, Contemporary, Friendly, Modern, Asian, Awesome, Memorable, Classic, Cool',
        annual_dol: 1251.16,
        description:
            'Cool spot for banh mi & other Vietnamese plates with thoughtfully sourced ingredients.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.707,
        store_longitude: -73.9227,
        total_orders: 1292.8,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, French, Savory, Rum, Spacious, Yummy, Relaxed, American, Awesome',
        annual_dol: 1292.8,
        description:
            'Classic cocktails & small plates are served in a laid-back lounge with chandeliers, sofas & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8338,
        store_longitude: -73.8612,
        total_orders: 6931.87,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Burger, Italian, American, Caesar Wrap, Karaoke, Homemade, Warm, Local, Traditional, Great',
        annual_dol: 7474.9,
        description:
            'Easygoing, long-standing restaurant & lounge offering local cuisine & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.9173,
        total_orders: 110.09,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Vegan, Peruvian, Margarita, Latin, American, Mojito, Fusion, Relaxed, Chill',
        annual_dol: 234.66,
        description:
            'Relaxed bar & grill serving up traditional pub grub, burgers & Peruvian entrees, plus vegan options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6771,
        store_longitude: -73.9819,
        total_orders: 9.84,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Vegetarian, Vegan, Salad, Coffee, Italian, Beer, Wine, Dessert, American',
        annual_dol: 40.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7358,
        store_longitude: -74.0074,
        total_orders: 1716.87,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Tequila, Whiskey, Wine, Beer, Bourbon, Gin, Salad, Upscale, Rum',
        annual_dol: 1756.91,
        description:
            'Upscale bar modeled after a vintage railway car with classic cocktails and homemade mixers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7379,
        store_longitude: -74.0037,
        total_orders: 3075.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Hamburger, Beer, Whiskey, French, Ale, American, Delicious, Cozy, Casual',
        annual_dol: 3222.76,
        description:
            'Easygoing pub with a well-known West Village sibling dishing up brews & big burgers on paper plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6661,
        store_longitude: -73.6645,
        total_orders: 16.42,
        neighborhood: '',
        keywords: 'Burger, Gluten Free, American, Wine, Casual, Traditional, Great',
        annual_dol: 67.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7656,
        store_longitude: -73.9189,
        total_orders: 1359.2,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Wine, Karaoke, Vintage, Local, Great, Casual, Unique',
        annual_dol: 1418.13,
        description:
            'Laid-back bar offering indie beers, cocktails & small bites, plus occasional events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7774,
        store_longitude: -73.9509,
        total_orders: 625.78,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Italian, Tasty, Spicy, Homemade, Intimate, Casual, Authentic, Lively, Local, Excellent',
        annual_dol: 639.06,
        description:
            'This intimate neighborhood Italian restaurant featuring Roman cuisine draws a lively crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7215,
        store_longitude: -73.8488,
        total_orders: 19.5,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Sushi, Salad, Mexican, Beer, Japanese, Thai, Ale, Sashimi, Fried Rice, Ramen',
        annual_dol: 79.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -74.0095,
        total_orders: 473.66,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Hotel, Champagne, Opulent, Tequila, Cozy, French Toast, French, Chic, Intimate, Rooftop',
        annual_dol: 553.4,
        description:
            "Inspired by cabins on luxury ships, the sophisticated, cozy rooms include free Wi-Fi and flat-screen TVs, plus DVD players, iPod docks and shared bathrooms; some include bunk beds. Upgraded cabins have larger beds, private bathrooms, and/or terraces or river views. Room service is available.Guests have free use of bicycles to explore the city. An on-site restaurant serves French-Moroccan fare and a popular brunch menu, and there's an opulent Victorian-style lobby bar.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -73.6352,
        total_orders: 380.75,
        neighborhood: '',
        keywords:
            'Wine, Spanish, Italian, Dessert, Belgian, American, Delicious, Eclectic, Rustic, Fun',
        annual_dol: 467.17,
        description:
            'Hip venue pairing Italian & Spanish tapas, including meat & cheese plates, with European wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8412,
        store_longitude: -73.9376,
        total_orders: 1055.72,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Vegan, Burger, Pizza, Beer, Wine, Tasty, Ale, American, Delicious, Karaoke',
        annual_dol: 1509.88,
        description: '"Trendy draft bar offering burgers and spirits"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9013,
        total_orders: 18.74,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Steak, Wine, Dessert, Southern, French, Cozy, Croissant Sandwich, Casual, American, Tradition',
        annual_dol: 57.65,
        description:
            '"Neighborhood bar and kitchen counter offering food & drink inspired by the flavors of Southern France and Northern Italy."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6655,
        store_longitude: -73.9825,
        total_orders: 704.11,
        neighborhood: 'Park Slope',
        keywords: 'Tequila, Salad, Mexican, Dessert, Wine, Beer, Spicy, Warm, Creamy, Enchiladas',
        annual_dol: 1066.73,
        description:
            '"We come from a small desert town in southern Oaxaca where we grew up farming, caring for livestock, and selling at the colorful daily markets in the towns surrounding our home. Our mother was an expert cook and passed along not only her recipes and creativity but respect for the best ingredients available. Our Father has always had his own small Plalenque (Distillery) and produced the same Mezcales that have been made in his family for generations. One by one we settled in the NYC area, working in restaurants, bars, bakeries etc. After 25 years of working and saving we’re opening Caza Azul. Here we hope to carry on our parents passions of the best of creative Oaxacan cuisine and unique Mezcales. We hope you enjoy"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6653,
        store_longitude: -73.9898,
        total_orders: 715.59,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Burger, Salad, Beer, Ale, Chinese, Irish, Friendly, Sweet, Hospitable, Classic',
        annual_dol: 763.15,
        description:
            'Pints of ale, English football on TV & traditional pub grub give this bar a British flavor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7026,
        store_longitude: -73.9337,
        total_orders: 854.56,
        neighborhood: 'East Williamsburg',
        keywords:
            'Burger, Mexican, Salad, Nachos, Dessert, Shrimp Cocktail, Chili Con Carne, Beer, Tex-Mex, Delicious',
        annual_dol: 1108.16,
        description:
            '"Tex Mex coming soon to Bushwick. Brought to you by lifelong servers from OKC, Mexico, and NYC. We get happiness from seeing other people happy. Come and let us treat you right."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -74.01,
        total_orders: 3600.98,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Italian, Wine, Meatball, Delicious, Hotel, Impeccable, Rustic, Quaint, Urban, Premium',
        annual_dol: 3757.1,
        description: 'TriBeCa hot spot showcasing rustic Italian cuisine in a lively atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.5525,
        total_orders: 3368.53,
        neighborhood: '',
        keywords:
            'Cheesecake, Chocolate Cake, Burger, Dessert, Salad, Coffee, Wine, Italian, Chinese, Caesar Salad',
        annual_dol: 3668.45,
        description:
            'American chain restaurant offering sizable portions from a vast menu including signature cheesecake',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.702,
        store_longitude: -74.0107,
        total_orders: 46.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegan, Vegetarian, American, Delicious, British, Fun, Iconic, Great, Diverse, Unique',
        annual_dol: 191.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -74.0131,
        total_orders: 159.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Mexican, Steak, Salad, Beer, Italian, Wine, American, Organic, Casual, Local',
        annual_dol: 653.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.701,
        store_longitude: -73.914,
        total_orders: 1374.07,
        neighborhood: 'Bushwick',
        keywords: 'Tacos, Beer, Wine, Warm, Ale, Clean, Spacious, Casual, Great, Cultural',
        annual_dol: 1403.22,
        description:
            'Cash-only joint offering a big beer selection & mixed drinks in pint glasses amid old-school decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7314,
        store_longitude: -74.0047,
        total_orders: 166.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Warm, Luxurious, Delicious, Brazilian, Exotic, Friendly, Intimate, Homey, Authentic, Classic',
        annual_dol: 198.82,
        description:
            'Classic big-flavored Brazilian dishes like feijoada come out of a small kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -73.6054,
        total_orders: 198.64,
        neighborhood: '',
        keywords:
            'Vegetarian, Chicken Lollipop, Kebab, Chicken Tikka Masala, Vodka, Wine, Beer, Nutritious, Pakistani, Indian',
        annual_dol: 256,
        description:
            'Northern Indian cuisine with wine & cocktails plus lunch & Sunday dinner buffets in a refined space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -74.0069,
        total_orders: 3173.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Salad, Wine, Tequila, Breakfast Burrito, Delicious, Cozy, Warm, French',
        annual_dol: 3274.03,
        description:
            'Hot spot serving New American fare (including vegetarian-friendly choices) & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8077,
        store_longitude: -73.6577,
        total_orders: 1201.27,
        neighborhood: '',
        keywords: 'Hotel, Great',
        annual_dol: 1280.42,
        description:
            "events at the Nassau Veterans Memorial Coliseum.The warmly decorated rooms feature free Wi-Fi, flat-screen TVs, as well as minifridges, microwaves and Keurig coffeemakers. Suites add living areas with pull-out couches. Room service is available.Parking is complimentary. Other amenities include a gym, an indoor pool and a hot tub. There's also a restaurant and a bar. Breakfast is available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9932,
        total_orders: 351.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Wine, Irish, Hotel, Contemporary, Warm, American, Friendly, Casual',
        annual_dol: 1434.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -74.0088,
        total_orders: 2207.4,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Chinese, Gourmet, Trendy, Upscale, Chili Chicken, Classy, Sweet, Fresh, Filet Mignon',
        annual_dol: 2272.8,
        description:
            'Noodles, dumplings & other upscale Chinese fare served by white-jacketed waiters in posh space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.9979,
        total_orders: 343.87,
        neighborhood: 'Little Italy',
        keywords:
            'Lasagna, Salad, Italian, Wine, Flavorful, Martini, Classy, Homemade, Caesar Salad, Casual',
        annual_dol: 374.21,
        description:
            'Little Italy eatery supplying the standard red-sauce pastas & entrees along with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6504,
        store_longitude: -74.0089,
        total_orders: 31.64,
        neighborhood: 'Sunset Park',
        keywords:
            'Burger, Steak, Pizza, Mexican, Salad, Beer, Italian, Coffee, Wine, South American',
        annual_dol: 45.61,
        description:
            'Globally influenced American comfort food, including breakfast, in a cozy, brick-lined space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8103,
        store_longitude: -73.6251,
        total_orders: 2616.89,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Vegetarian, Salad, Shrimp Cocktail, Wine, French, Beer, Organic',
        annual_dol: 2672.41,
        description:
            'Parisian-inspired decor & classic bistro fare meet in an airy French eatery with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6671,
        store_longitude: -73.842,
        total_orders: 573.41,
        neighborhood: 'Lindenwood/Howard Beach',
        keywords:
            'Chicken Parmigiana, Salad, Pizza, Shrimp Cocktail, Italian, Meatball, Wine, Fried Calamari, Thai, Exquisite',
        annual_dol: 590.09,
        description:
            'Relaxed, white-tablecloth mini-chain branch offering family-style Italian favorites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5674,
        store_longitude: -74.1125,
        total_orders: 131.3,
        neighborhood: 'New Dorp/New Dorp Beach/#210',
        keywords: 'Burger, Steak, Beer, Casual, American, Local, Classic, Lively, Handcrafted',
        annual_dol: 505.51,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7178,
        store_longitude: -73.9974,
        total_orders: 16.05,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Chicken Parmigiana, Shrimp Cocktail, Meatball, Italian, Spicy, Bruschetta, Legendary, Casual, Fresh',
        annual_dol: 65.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7196,
        store_longitude: -73.9951,
        total_orders: 804.28,
        neighborhood: 'Little Italy',
        keywords: 'Tacos, Tequila, Wine, Cozy, Nachos, Friendly, Perfect, Fun, Cool, Caribbean',
        annual_dol: 3287.64,
        description:
            'Funky, tropically inspired bar serving Caribbean-style bites, along with cocktails, wine & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7458,
        store_longitude: -73.9035,
        total_orders: 130.2,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, Delicious, Gourmet, Spacious, Fun, Hotel, Clean, Fancy, Irish, Great',
        annual_dol: 213,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8277,
        store_longitude: -73.9258,
        total_orders: 9747.4,
        neighborhood: 'Concourse',
        keywords: 'Rooftop, Burger, Beer, Wine, American, Perfect, Friendly, Casual',
        annual_dol: 25774.96,
        description:
            'Baseball fans grab brews, burgers & sandwiches before & after games at nearby Yankee Stadium.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7806,
        store_longitude: -73.9585,
        total_orders: 172.32,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Salad, Wine, Organic, French, Upscale, Asian, Warm, Omelette',
        annual_dol: 704.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.71,
        store_longitude: -73.8469,
        total_orders: 144.01,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Pizza, Italian, Delicious, Elegant, Asian, Sweet, Friendly, Casual, American, Caribbean',
        annual_dol: 588.66,
        description:
            '"UNDER NEW OWNERSHIP - Chef Yvan Lemoine and Rebecca Sussman . We are excited to welcome you back to My Kitchen! 2022 event and wedding season is open and the calendar is filling up fast! Call us to view the space and book for your upcoming wedding, sweet 16, Bat/Bar Mitzvah, baby shower or birthday."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7419,
        store_longitude: -73.9188,
        total_orders: 406.9,
        neighborhood: 'LIC/Sunnyside/Flushing/Woodside',
        keywords:
            'Peruvian, Wine, Ale, Healthy, Delicious, Latin, Traditional, American, Casual, Modern',
        annual_dol: 831.64,
        description:
            'Snug, homey eatery featuring spit-roast chicken & other traditional dishes, plus wine & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7452,
        store_longitude: -73.9053,
        total_orders: 14.06,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Milkshake, Irish, American, Friendly, Casual, Fancy, Local, Fine, Diverse',
        annual_dol: 21.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7076,
        store_longitude: -73.9209,
        total_orders: 1668.43,
        neighborhood: 'Bushwick',
        keywords:
            'Salad, Thai, Dessert, Spicy, Fried Chicken, Sweet, Asian, Succulent, Authentic, Modern',
        annual_dol: 3709.82,
        description:
            'Modern Thai restaurant & bar offering up small plates (kub klaem) in an industrial-chic space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7048,
        store_longitude: -74.0067,
        total_orders: 261.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegetarian, Burger, Vegan, Steak, Salad, Flavorful, Healthful, Ale, Delicious, Clean',
        annual_dol: 313.36,
        description:
            'Fresh produce & locally sourced meats lead the list of ingredients at this popular American spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7196,
        store_longitude: -74.0041,
        total_orders: 4394.61,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Salad, Mexican, Wine, Meatball, Beer, Nachos, Organic, American, Caesar Salad',
        annual_dol: 4535.34,
        description:
            'Original cocktails & American fare with modern twists served in dimly lit digs with a central bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7187,
        store_longitude: -74.0059,
        total_orders: 424.24,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Tacos, Mexican, Japanese, Hotel, American, Latin, Authentic, Casual, Traditional, Fantastic',
        annual_dol: 563.92,
        description:
            '"Casa Carmen is the new venture of a Mexican family lead by renown chef Carmen Titita Ramirez Degollado and her grandsons. Be a part of this exciting new project to bring a taste of traditional Mexican recipes and hospitality to the heart of Tribeca."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -74.0034,
        total_orders: 831.52,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Beer, Meatball, Wine, Karaoke, Rum, Fun, Hotel, Awesome, American',
        annual_dol: 2919.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -73.9969,
        total_orders: 483.36,
        neighborhood: 'Little Italy',
        keywords:
            'Steak, Pizza, Italian, Vodka, Southern, Contemporary, Casual, Traditional, Authentic, Ample',
        annual_dol: 504.32,
        description:
            'Little Italy venue serving traditional Italian plates from the old country & offers an ample garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -74.0021,
        total_orders: 5758.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Karaoke, Trendy, Hip, Fun, Cozy, Friendly, Great, Casual',
        annual_dol: 6222.91,
        description:
            'Lounge with 18 private karaoke rooms, cocktail bar & around 80,000 songs in multiple languages.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -73.6351,
        total_orders: 809.94,
        neighborhood: '',
        keywords:
            'Lasagna, Wine, Italian, Pizza, Delicious, Bread Pudding, Roast Beef, Polished, Romantic, Classy',
        annual_dol: 863.78,
        description:
            'Traditional Italian dishes served in a sleek, contemporary dining room with a romantic atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7339,
        store_longitude: -74,
        total_orders: 2098.06,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Karaoke, Rich, Beer, Legendary, Fun, Vibrant, Awesome, Premium, Fantastic, Classic',
        annual_dol: 2567.93,
        description:
            'Tuesday night karaoke, drag spectaculars & other events drive this local, long-standing gay bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -74.0037,
        total_orders: 249.45,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Classic, American, Iconic, Irresistible, Perfect, Intimate, Unique',
        annual_dol: 1019.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7373,
        store_longitude: -74.0018,
        total_orders: 202.99,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Mediterranean, Spicy, Casual',
        annual_dol: 829.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.9186,
        total_orders: 2291.95,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Wine, Beer, Coffee, Stylish, Relaxed, Comfortable, Spicy, Jamaican, Belgian, American',
        annual_dol: 2424.12,
        description:
            'Stylish yet relaxed bar offering fine wines & 150+ craft brews, along with cheese & meat plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.713,
        store_longitude: -73.9051,
        total_orders: 134.2,
        neighborhood: 'Flushing/Ridgewood/Maspeth',
        keywords: 'Thai, Sweet, Western, Casual, Amazing, Chill',
        annual_dol: 137.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.97,
        total_orders: 784.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Salad, Pizza, Italian, Beer, Spicy, Delicious, Bruschetta, Homemade, Modern',
        annual_dol: 818.44,
        description:
            'Buzzy destination serving Italian fare & wood-fired pizza in a mellow, white-tablecloth setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7716,
        store_longitude: -73.9615,
        total_orders: 1177.42,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Tacos, Salad, Wine, Delicious, French, American, Classic, Cozy, Spectacular',
        annual_dol: 1341,
        description:
            'Seafood, steak & classic sides are the focus at this 2-level restaurant with vaulted brick ceilings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7622,
        store_longitude: -73.9599,
        total_orders: 714.51,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Japanese, Wine, Spicy, Beer, Chinese, Asian, Delicious, Thai, Fusion',
        annual_dol: 761.47,
        description:
            'Relaxed eatery with a varied menu of Asian classics, from sushi & teriyaki to pad Thai & fried rice.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6351,
        store_longitude: -73.6879,
        total_orders: 803.21,
        neighborhood: '',
        keywords: 'Golfclub, Fresh',
        annual_dol: 1457.89,
        description:
            'The Seawane Club is a private member owned golf and country club in Hewlett Harbor, New York. It contains an 18 hole golf course, 8 tennis courts, 2 pickleball courts, a basketball court, a swimming ...',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.9856,
        total_orders: 5384.74,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Beer, Wine, Salad, Chicken Caesar Salad, Tasty, Ale, Upscale, Spicy, French Onion Soup',
        annual_dol: 5434.7,
        description:
            'Airy eatery/bar offering a full menu of American & Irish fare, an extensive beer list & lots of TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.9921,
        total_orders: 2695.87,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Vodka, Burger, Irish, American, Modern, Classic, Local, Casual, Creative',
        annual_dol: 2834.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -74.0045,
        total_orders: 10489.8,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Burger, Whiskey, Pizza, Salad, Coffee, Margarita, Stylish, Elegant, Hotel',
        annual_dol: 10712.38,
        description:
            "the Empire State Building.Understated rooms feature curated minibars, free Wi-Fi and flat-screen TVs, plus Nespresso machines and guitars. Suites add separate living areas and wet bars. Goldfish, and loaner iPads, iPods and laptops are available on request. Room service is offered.Seasonal loaner bikes are complimentary. Dining options include a sophisticated restaurant with vaulted ceilings, a guest lounge, and a stylish bar. There's also an exercise room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7032,
        store_longitude: -74.0117,
        total_orders: 141.06,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Salad, Coffee, Italian, Roast Beef, Korean, American, Sustainable, Casual, Fresh',
        annual_dol: 598.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7224,
        store_longitude: -74.0095,
        total_orders: 127.63,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Wine, Vegan, Beer, Chicken Tikka Masala, Dessert, Upscale, Delicious, Chilean, Polished',
        annual_dol: 201.71,
        description:
            'Pan-Indian plates, craft cocktails & wine are served at this sprawling haunt with a wraparound bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7695,
        store_longitude: -73.8661,
        total_orders: 22418.56,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Steak, Burger, Pizza, Salad, Coffee, Wine, Beer, Hotel, American, Recommended',
        annual_dol: 22894.25,
        description:
            'LaGuardia Airport is a civil airport in East Elmhurst, Queens, New York City. Covering 680 acres as of August 24, 2022, the facility was established in 1929 and began operating as a public airport in 1939. It is named after former New York City mayor Fiorello La Guardia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -73.9528,
        total_orders: 2659.09,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Tequila, Wine, Beer, Scotch, Rustic, Cozy, Classy, Authentic, Perfect, Intimate',
        annual_dol: 2833.8,
        description:
            'Brick-walled corner watering hole that offers plenty of beers on tap, long tables & backyard garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7428,
        store_longitude: -73.9535,
        total_orders: 33.51,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Tacos, Mexican, Vegetarian, Tex-Mex, Dessert, Rustic, Casual, Classic, Great',
        annual_dol: 159.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.9506,
        total_orders: 177.12,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Burger, Lobster, Steak, American, Casual, Cool, Global, Mysterious',
        annual_dol: 314.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8008,
        store_longitude: -73.9652,
        total_orders: 1660.57,
        neighborhood: 'Manhattan Valley/UWS',
        keywords: 'Salad, Cozy, Dominican, Caribbean, Fun, Lively, Colorful, Casual, Latin, Fresh',
        annual_dol: 1894.16,
        description:
            'Dominican eats & fruit-flavored cocktails in a colorful space with a tropical ceiling mural.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7286,
        store_longitude: -73.9874,
        total_orders: 282.73,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Beer, French, Italian, Organic, American, Modern, Homemade, Seasonal, Casual',
        annual_dol: 368.55,
        description:
            'Brick-walled brasserie with an intimate feel serving updated French dishes & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.732,
        store_longitude: -73.9838,
        total_orders: 25.9,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Thai, Salad, Coffee, Green Curry, Clean, Beer, Casual, Traditional, Sophisticated, Local',
        annual_dol: 105.87,
        description:
            'A selection of traditional Thai dishes, such as curry & pad khing, served in sophisticated environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7379,
        store_longitude: -73.9878,
        total_orders: 344.57,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Mexican, Tacos, Beer, Margarita, Wine, Tex-Mex, Hotel, Trendy, Mediterranean, Chic',
        annual_dol: 442.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7495,
        store_longitude: -73.969,
        total_orders: 1271.67,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Burger, Coffee, Warm, Fabulous, Iconic, Cozy, Classic, Great, Wonderful',
        annual_dol: 1368.48,
        description:
            'Sophisticated rooms and suites feature floor-to-ceiling windows with city views, Wi-Fi (fee), flat-screen TVs and coffeemakers. Upgraded rooms and suites add sitting areas with leather chairs and iPod docks. Some rooms offer access to a club lounge (fee) with breakfast and snacks.Amenities include a contemporary American restaurant, a bar and a coffee shop, in addition to a fitness center with an indoor tennis court.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 9219.11,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Beer, Legendary, Polished, New Zealand, Bloody Mary, Perfect, Clam Chowder',
        annual_dol: 9618.82,
        description:
            'Venerated, iconic eatery serving raw oysters, pan roasts & chowders in a bustling, vaulted setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.9747,
        total_orders: 686.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Cheesesteak, Steak, Vegetarian, Salad, Vegan, Italian, Gluten Free, Coffee, Spicy, Philly Cheesesteak',
        annual_dol: 2588.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8178,
        store_longitude: -73.9422,
        total_orders: 184.74,
        neighborhood: "Harlem/Strivers' Row",
        keywords:
            'Mexican, Champagne, Beer, Salad, Upscale, Mimosa, Cozy, Rum, Delicious, American',
        annual_dol: 208.51,
        description: '"Restaurant Lounge"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9687,
        total_orders: 5499.12,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Beer, Cheesesteak, Wine, Philly Cheesesteak, American, Premium, Classic, Awesome, Authentic',
        annual_dol: 6368.37,
        description:
            'Bi-level tavern with a rotating roster of taps & many bottles plus an accompanying pub food menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9699,
        total_orders: 692.87,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Sushi, Beer, Luxurious, Hotel, Romantic, Fancy, Asian, Korean, American',
        annual_dol: 1308.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9921,
        total_orders: 944.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Steak, Vegan, Tasty, Beer, Salad, Wine, Upscale, American, Awesome',
        annual_dol: 1042.26,
        description:
            'Straightforward sports bar near Port Authority offering pub fare along with plenty of beer & TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.9865,
        total_orders: 5397.53,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, French, French Onion Soup, Organic, Fried Rice, Screwdriver, Spicy, American, Bellini, Casual',
        annual_dol: 5414.16,
        description:
            '"Brooklyn Chop House Steakhouse Times Square plays on the double meaning of the word "Chop". Chop Steak to Chop Sticks. Marrying what made him a well-known name in the industry with Philippe Chow. Stratis has taken the best of those two menus and married them with Steakhouse classics like dry-aged 30-45 day Prime Steaks including Porterhouse. For 2, NY Strip, and Bone-In Rib Eye. The game-changer and the uniqueness of the BCH menu came home with taking the staples of his father\'s Hilltop Diner such as the Pastrami, The Reuben, Philly Cheesesteak, Bacon Cheeseburger & the French Onion Soup and turning them into dumplings. This has driven the BCH concept viral on social media as the FIRST to reimagine the deli/diner sandwiches into dumplings."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9869,
        total_orders: 5191.59,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords: 'Pizza, Beer, Coffee, Wine, Ale, American, Perfect, Sophisticated, Great, Casual',
        annual_dol: 5301.75,
        description:
            'Mellow neighborhood meeting place for Dumbo locals serving wines, beers & spirits.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.702,
        store_longitude: -73.9895,
        total_orders: 815.05,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Burger, Vegetarian, Vegan, Hotdog, Salad, Breakfast Burrito, Argentinian, French Toast, Avocado Toast, Ale',
        annual_dol: 848.61,
        description:
            'Fresh produce & locally sourced meats lead the list of ingredients at this popular American spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7884,
        store_longitude: -73.7295,
        total_orders: 115.79,
        neighborhood: '',
        keywords:
            'Coffee, Beer, Salad, French Toast, Warm, Mediterranean, Welcoming, Great, French, American',
        annual_dol: 283.67,
        description:
            '"A neighborhood restaurant with a beautiful, warm and welcoming atmosphere. A favorite meeting place for friends, with a great selection of always freshly made modern Israeli, Mediterranean and Middle Eastern dishes. Rothchilds is known for both unique and familiar dishes and a menu to please every taste."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6845,
        store_longitude: -73.9922,
        total_orders: 659.66,
        neighborhood: 'Carroll Gardens/Brooklyn Heights/Cobble Hill',
        keywords:
            'Vegetarian, Tasty, Nutritious, Italian, Chicken Parmigiana, Champagne, Delicious, Organic, Friendly, Mediterranean',
        annual_dol: 759.94,
        description:
            'Mediterranean eatery serving meats, seafood & white-sauce dishes in warm, brick-walled dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7799,
        store_longitude: -73.9501,
        total_orders: 28.52,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegetarian, Salad, Wine, Tasty, Beer, Elegant, Indian, Tandoori Chicken, Casual, Eclectic',
        annual_dol: 57.8,
        description: '"FINE INDIAN CUISINE WITH SPECIAL KABOB COOKED IN SPECIAL CLAY OVEN"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6827,
        store_longitude: -73.9502,
        total_orders: 130.88,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Beer, Coffee, Intimate, Sweet, Casual',
        annual_dol: 534.98,
        description: '"Cocktails and oysters. Located in Bedstuy, Brooklyn."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8061,
        store_longitude: -73.9531,
        total_orders: 7360.51,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Mexican, Tacos, Beer, Wine, Coffee, Fish Tacos, Upscale, Delicious, Polished',
        annual_dol: 8188.33,
        description:
            'Intimate eatery featuring upscale Mexican cuisine & agave-focused, craft cocktails in a chic venue.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8521,
        store_longitude: -73.6486,
        total_orders: 1234.11,
        neighborhood: '',
        keywords: 'Wine, Fun, Wonderful, Great, Excellent',
        annual_dol: 1287.61,
        description: '"Yacht Club/Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7254,
        store_longitude: -74.003,
        total_orders: 1090.39,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Lobster, Sushi, Steak, Salad, Shrimp Cocktail, Dessert, Gluten Free, Spicy, Beer, Fried Chicken',
        annual_dol: 1113.52,
        description:
            'Iconic, late-night brasserie featuring seafood, American dishes & cocktails in a convivial setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7216,
        store_longitude: -73.9971,
        total_orders: 1122.9,
        neighborhood: 'Nolita',
        keywords:
            'Burger, Hamburger, Salad, Coffee, Cosmopolitan, Wine, Spicy, Italian, Mediterranean, Delicious',
        annual_dol: 1175.6,
        description:
            'Relaxed eatery featuring Israeli cuisine, including falafel, shawarma & hummus, plus wine & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7729,
        store_longitude: -73.8952,
        total_orders: 113.6,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City/Flushing',
        keywords:
            'Pizza, Salad, Delicious, Cozy, Warm, Perfect, Welcoming, Friendly, Sweet, Premium',
        annual_dol: 121.15,
        description:
            '28 lanes of bowling with laser light shows plus a sports bar, video game arcade & casual dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7584,
        store_longitude: -73.8923,
        total_orders: 61.74,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Burger, Salad, Steak, Wine, Ale, Beer, Tasty, Italian, Buffalo Chicken Salad, Cozy',
        annual_dol: 252.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.9905,
        total_orders: 1.23,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Vegetarian, Salad, Butter Chicken, Friendly, Tandoori Chicken, Casual, Indian, Authentic, Exotic, Local',
        annual_dol: 5.01,
        description: '"Authentic Indian Restaurant & Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -74.0062,
        total_orders: 175.09,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Pizza, Coffee, Wine, Spacious, New Zealand, Friendly, Negroni, Incredible, Classic, Casual',
        annual_dol: 715.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7728,
        store_longitude: -73.9786,
        total_orders: 3952.21,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Salad, Italian, Beer, French Toast, Rustic, French, American, Modern, Elegant',
        annual_dol: 4036.07,
        description:
            'Restored Central Park icon for American fare in an elegant setting with courtyard & garden seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7684,
        store_longitude: -73.9817,
        total_orders: 226.44,
        neighborhood: '',
        keywords: 'Hotel, Trendy, Western, Spacious, Spectacular, Clean',
        annual_dol: 925.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -73.6081,
        total_orders: 283.6,
        neighborhood: '',
        keywords:
            'Wine, Delicious, Italian, Greek, Mediterranean, Ale, Spectacular, Fabulous, Exceptional, Friendly',
        annual_dol: 311.6,
        description:
            '"Welcome to Plori Restaurant, a chic and modern eatery that offers a delicious array of Greek cuisine. Our inspiration comes from this Greek fishing village, where the people wait on the shore for the fishing boats to return. When they see the front of the boat—the plori—they know the daily catch is coming. We want to take you to that little Greek village."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5739,
        store_longitude: -74.1173,
        total_orders: 355.12,
        neighborhood: 'New Dorp',
        keywords:
            'Steak, Burger, Tacos, Tequila, Mexican, Gluten Free, Bourbon, Tex-Mex, Spicy, Bbq',
        annual_dol: 915.1,
        description:
            '"Ho\'brah: a taco joint is stylish, surf-themed restaurant and bar specializing in California-style tacos, burritos, Mexican small plates and cocktails. We thought long and hard about what we loved most about tacos. ‘Bright flavors’ and ‘fresh ingredients’ were popular choices and soon morphed into ‘healthy lifestyle, summer, sun, and surf’. We shared tales of favorite taco joints we’d visited on Vacations around the world; small taco trucks on the Mexican border in San Diego; wood-paneled surf-taco joints yards from the ocean in Florida; oversized taco emporiums in Hawaii; and one truly shanty fish shack turned taco stand on the Gulf Coast."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8246,
        store_longitude: -73.6677,
        total_orders: 504.1,
        neighborhood: '',
        keywords:
            'Golfclub, Comfortable, Fun, Memorable, Great, Terrific, Fine, Local, Recommended',
        annual_dol: 616.99,
        description:
            'Golf club featuring an 18-hole course & a 9-hole course, plus a practice area, a shop & mini-golf.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6916,
        store_longitude: -73.8207,
        total_orders: 403.34,
        neighborhood: 'South Richmond Hill',
        keywords: 'Kebab, Spicy, Dessert, Chinese, Bbq, Caribbean, Healthy, Classic, Local, Indian',
        annual_dol: 1519.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9942,
        total_orders: 1207.26,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Stylish, Hotel, Tasty, Friendly, Colorful, Mediterranean',
        annual_dol: 1343.2,
        description:
            '-minute walk from Penn Station.Streamlined rooms, some with colorful accent walls, feature free Wi-Fi and flat-screen TVs, plus microwaves, minifridges, and tea and coffeemaking equipment. Suites add terraces and sitting areas.A breakfast buffet is complimentary. Other amenities include a low-key bar, a lobby lounge and a 24/7 fitness center, plus event space. Parking is available for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8026,
        store_longitude: -73.9385,
        total_orders: 3379.61,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Vegan, Healthy, Delicious, Friendly, Natural, Hotel, Local',
        annual_dol: 8877.72,
        description: '"The Greatest Wealth... Is HEALTH."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -73.9834,
        total_orders: 90.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Burger, Beer, Pizza, Burritos, Wine, Delicious, Organic, Asian',
        annual_dol: 369.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -74.0095,
        total_orders: 4931.57,
        neighborhood: 'Hudson Square',
        keywords: 'Burger, Salad, Beer, Wine, Spicy, Funky, Ale, Rum, Cozy, Legendary',
        annual_dol: 5036.21,
        description:
            'Beers & bar bites are on the menu at this popular joint in a landmarked, circa-1817 building.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6572,
        store_longitude: -74.0065,
        total_orders: 330.85,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Wine, Beer, Pizza, Tasty, Golfclub, Chinese, French, Great, Casual',
        annual_dol: 1352.4,
        description:
            '"The Carreau Club is your home for all things pétanque in NYC! Court reservations available for groups of all sizes. INDOOR COURTS OPENING IN INDUSTRY CITY THIS OCTOBER!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7393,
        store_longitude: -74.0067,
        total_orders: 6162.64,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Lobster, Salad, Italian, French, Trendy, Vintage, American, Asian, Traditional',
        annual_dol: 6885.79,
        description:
            'Theatrical evocation of Paris featuring bistro food & high-energy atmospherics from a chic crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7439,
        store_longitude: -73.9227,
        total_orders: 150,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Sushi, Salad, Japanese, Beer, Wine, Spicy, Asian, Ramen, Casual, Local',
        annual_dol: 490.78,
        description:
            'This neighborhood stalwart for sushi & other Japanese favorites has a full bar with a sake menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7526,
        store_longitude: -73.9876,
        total_orders: 42.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Tacos, Vegan, Salad, Mexican, Flavorful, Tasty, Margarita, Beer, Organic, Spicy',
        annual_dol: 108.76,
        description:
            '"Inspired by a healthy lifestyle where beach, surf and sun mix with fresh local ingredients, Taco Dumbo offers a laid back vibe and flavorful food for all."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.5522,
        total_orders: 485.76,
        neighborhood: '',
        keywords:
            'Burger, Steak, Salad, Chocolate Cake, Shrimp Cocktail, Italian, Wine, Tasty, Beer, French',
        annual_dol: 506.82,
        description:
            '"We specialize in bar food & Italian cuisine! If you don’t believe us, just try the chicken parm or calamari! The Bar always stays open after the kitchen closes!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -73.9996,
        total_orders: 5046.13,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Burger, Whiskey, Tasty, Beer, Pizza, Hamburger, Bourbon, Delicious, Warm, Comfortable',
        annual_dol: 5227.81,
        description:
            'Local saloon chasing whiskies with pickle juice & other drinks in a laid-back space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7188,
        store_longitude: -74.0045,
        total_orders: 617.47,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Italian, Pizza, Wine, Delicious, Upscale, Warm, Rustic, Romantic, Meatball',
        annual_dol: 687.52,
        description:
            'Modern, casual restaurant & wine bar with a creative Italian menu featuring pizza, pasta & omelets.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -74.0093,
        total_orders: 278.63,
        neighborhood: 'Hudson Square',
        keywords: 'Burger, Steak, Salad, Wine, Beer, Warm, Cozy, Quaint, Comfortable, Swiss',
        annual_dol: 303.66,
        description:
            'An American menu highlighting seasonal produce served in cozy digs with salvaged wood decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7072,
        store_longitude: -73.9213,
        total_orders: 5907.61,
        neighborhood: 'Bushwick',
        keywords: 'Vodka, Whiskey, Tequila, Beer, Gin, Cozy, Perfect, Fresh, Quirky, Great',
        annual_dol: 6032.96,
        description:
            'A tattooed crew runs this corner bar serving draft beers & Mason-jar cocktails to a local crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7028,
        store_longitude: -73.9297,
        total_orders: 1953.87,
        neighborhood: 'Bushwick',
        keywords: 'Whiskey, Wine, Beer, Tasty, Pizza, Premium, Cozy, Comfortable, Great, Chill',
        annual_dol: 2079.3,
        description:
            'Whiskey flights, beer & snacks in a simple, dark bar with flat-screen TVs & a neighborhood vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6571,
        store_longitude: -73.6436,
        total_orders: 426.84,
        neighborhood: '',
        keywords:
            'Lobster, Juicy, Beer, Warm, Fried Clams, Vibrant, Friendly, Outstanding, Fresh, Great',
        annual_dol: 509.88,
        description:
            '"At Red Crab Juicy Seafood– customers can make their own seafood combo. All you have to do is choose any ½ LB (minimum two items) and there you go! You are served corn and potato with it. This offer has a selection of blue crab, shrimp, snow crab legs, crawfish, clams, green mussel, black mussel, and sausage. Everything is reasonably priced in a warm and friendly atmosphere. Our goal is to deliver an experience with an outstanding variety of seafood and vibrant energy."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8325,
        store_longitude: -73.6984,
        total_orders: 174.88,
        neighborhood: '',
        keywords:
            'Kebab, Lobster, Salad, Mediterranean, Turkish, Greek, Greek Salad, Stylish, Fusion, Yummy',
        annual_dol: 204.32,
        description:
            'Local Mediterranean chain serving kebabs, pita sandwiches, entrees & more in a stylish setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.753,
        store_longitude: -73.988,
        total_orders: 14924.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Salad, Wine, Chinese, Spicy, Asian, Romantic, Filet Mignon, Fusion, Contemporary, Dope',
        annual_dol: 15471.61,
        description: '"Asian Fusion Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.9859,
        total_orders: 2000.74,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Burger, Pizza, Salad, Beer, Asian, Clean, Irish, Robust, American, Amazing',
        annual_dol: 4664.22,
        description:
            'Pub grub is offered along with a robust selection of draft beers at this old-school, bi-level venue.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.9923,
        total_orders: 792.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Spacious, Outstanding, Superb, Memorable, Exceptional, Rooftop',
        annual_dol: 844.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8405,
        store_longitude: -73.7177,
        total_orders: 4.92,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Delicious, Bellini, Homemade, Fresh, Caesar Salad, Casual, Signature',
        annual_dol: 20.1,
        description: '"Family Owned Pizzeria Established 1959"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7338,
        store_longitude: -74.005,
        total_orders: 4373.34,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chocolate Cake, Steak, Tasty, Italian, Wine, Delicious, Warm, Trendy, Comfortable, Classy',
        annual_dol: 4466.13,
        description:
            'Italian small plates are matched by an extensive wine list at this bi-level restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.6476,
        total_orders: 442.19,
        neighborhood: '',
        keywords: 'Burger, Beer, Wine, Ale, Irish, American, Seasonal, Casual, Clean, Perfect',
        annual_dol: 470.58,
        description:
            'Local standby offering burgers, wings & other American grill fare, plus a long bar with TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7502,
        store_longitude: -73.6418,
        total_orders: 22.21,
        neighborhood: '',
        keywords: 'Local, Casual, Great',
        annual_dol: 90.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6571,
        store_longitude: -73.6716,
        total_orders: 1183.33,
        neighborhood: '',
        keywords: 'Tequila, Vodka, Beer, Pizza, Tasty, Rum, Nachos, Comfortable, Relaxed, Fun',
        annual_dol: 1472.13,
        description:
            'Basic theater with stadium-style seating & snack bar, showing new releases & discounted matinees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6563,
        store_longitude: -73.6718,
        total_orders: 22.94,
        neighborhood: '',
        keywords:
            'Garlic Bread, Burger, Pizza, Sushi, Salad, Italian, Vodka, Spicy, Homemade, Friendly',
        annual_dol: 100.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7397,
        store_longitude: -74.0078,
        total_orders: 613.91,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Steak, Italian, Wine, Tequila, Upscale, Stylish, Spicy, Bourbon, Margarita, Organic',
        annual_dol: 2509.48,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7368,
        store_longitude: -73.6129,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Salad, Tasty, Coffee, Delicious, Margarita, Italian, Martini, Perfect, Sangria, Great',
        annual_dol: 4.38,
        description:
            '"Starting November 19, the NM Cafe will be open for Dine-in for both full and quick service with limited seating available. Our menu is also available online for delivery via Postmates or pick-up via the Neiman Marcus website."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7754,
        store_longitude: -73.9533,
        total_orders: 1716.56,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Sushi, Japanese, Dessert, Sashimi, Spicy, Yummy, Asian, Fusion, Tempura, Modern',
        annual_dol: 2830.32,
        description:
            'Familiar Japanese dishes, sushi & cocktails in a modern space with a neon-lit bar & outdoor seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7572,
        store_longitude: -73.6421,
        total_orders: 792.64,
        neighborhood: '',
        keywords:
            'Wine, Vodka, Margarita, Mexican, Spanish, Sangria, Eclectic, Triple Sec, Sweet, Refined',
        annual_dol: 813.69,
        description:
            'Sophisticated restaurant offering refined Spanish dishes, including paella, along with sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7754,
        store_longitude: -73.9563,
        total_orders: 369.07,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Lemon Meringue Pie, Wine, Lobster, Tasty, Coffee, Italian, French, Exquisite, Urban, Cool',
        annual_dol: 385.07,
        description:
            'Seafood & desserts plus variously prepared Prince Edward Island mussels in a casual setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.8409,
        total_orders: 137.73,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Burger, Hamburger, Lobster, Lobster Roll, Meatball, Shrimp Cocktail, Beer, American, Cuban',
        annual_dol: 562.99,
        description:
            '"Farm to Table Restaurant. Serves Every Day: Lunch, Dinner, Wine Bar Weekend Brunch, Outdoor Heated Patio"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7705,
        store_longitude: -73.9538,
        total_orders: 509.34,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Coffee, Wine, Cozy, American, Delicious, Clean, Casual, Greek, Sophisticated',
        annual_dol: 554.29,
        description:
            '"NYC\'s most sophisticated board game cafe- with hundreds of games to play or buy, craft cocktails and milkshakes, locally roasted espresso drinks, and a menu of elevated classics."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7766,
        store_longitude: -73.9525,
        total_orders: 238.02,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Scotch, Tasty, Fun, Great, Impressive, Rooftop, Casual',
        annual_dol: 1021.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6569,
        store_longitude: -73.839,
        total_orders: 30.65,
        neighborhood: 'Far Rockaway/Jamaica/Howard Beach',
        keywords:
            'Burger, Salad, Shrimp Cocktail, Roast Beef, French, Homemade, American, Belgian, Casual, Friendly',
        annual_dol: 51.73,
        description:
            'Spacious, old-school diner with traditional American & International fare, plus early bird specials',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8667,
        store_longitude: -73.8305,
        total_orders: 706.3,
        neighborhood: 'East Bronx/Baychester',
        keywords: 'Steak, Lobster, Mexican, Delicious, Beer, Italian, Juicy, Fun, Fresh, Great',
        annual_dol: 1281.46,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8389,
        store_longitude: -73.941,
        total_orders: 1984.67,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Burger, Vegan, Salad, Beer, Tasty, Wine, Ale, American, Delicious, Rum',
        annual_dol: 2322.78,
        description:
            'Relaxed hangout for American fare plus cocktails featuring house-infused liquors, beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7201,
        store_longitude: -73.8476,
        total_orders: 10.81,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Wine, Rich, Beer, Karaoke, Armenian, Hotel, Fun, High-End, Classic, Great',
        annual_dol: 44.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8349,
        store_longitude: -73.6237,
        total_orders: 59.19,
        neighborhood: '',
        keywords: 'Salad, Sushi, Japanese, Chinese, Spicy, Sashimi, Asian, Sweet, Casual, Clean',
        annual_dol: 76.32,
        description:
            'Wide array of sushi rolls & cocktails in a simple, snug room with dark-wood tables & tatami booths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7683,
        store_longitude: -73.9212,
        total_orders: 524.08,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Cuban, Wine, Delicious, Premium, Authentic, American, Great, Excellent, Latin, Casual',
        annual_dol: 641.45,
        description: '"Cuban Fusion, Restaurant and Lounge"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6749,
        store_longitude: -73.9813,
        total_orders: 2436.81,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Vodka, Gin, Rum, Comfortable, Sweet, Vintage, Awesome, Casual, Great',
        annual_dol: 2482.78,
        description:
            "Vintage decor & '90s rock posters set an easygoing mood at this mod taproom with a summer patio.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9189,
        total_orders: 534.52,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Filipino, Wine, Irish, Bbq, Chill, Great, Local, Casual',
        annual_dol: 2184.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -73.9429,
        total_orders: 3026.18,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Vodka, Beer, Moscow Mule, Russian, Spacious, Seasonal, Sweet, Classy, Relaxed, Lively',
        annual_dol: 3090.39,
        description:
            'Industrial-chic local bar with signature cocktails, craft beers & shots, weekend DJs & late closing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7632,
        store_longitude: -73.9596,
        total_orders: 308.63,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotdog, Tequila, Mexican, Steak, Beer, Vegetarian, Margarita, Wine, Mimosa, Spicy',
        annual_dol: 315.18,
        description:
            'Lively restaurant & tequila bar serving Mexican classics & a bottomless margarita brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7711,
        store_longitude: -73.9509,
        total_orders: 547.86,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Warm, Delicious, Elegant, Quaint, Greek, Mediterranean, Charming, Outstanding, Modern',
        annual_dol: 569.31,
        description:
            'Charming Athenian-style Greek eatery with a focus on elevated, appetizer-size meze dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7811,
        store_longitude: -73.8412,
        total_orders: 27.61,
        neighborhood: 'College Point/Flushing',
        keywords: 'Sushi, Salad, Dessert, Tasty, Gin, Asian, Modern, Friendly, Great, Casual',
        annual_dol: 112.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -74.007,
        total_orders: 199.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Steak, Spicy, Delicious, Thai, Japanese, Cozy, Hotel, Asian, Homey',
        annual_dol: 212.27,
        description:
            'Cozy venue serving classic & creative sushi along with Asian fusion fare & cocktails in chic digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8441,
        store_longitude: -73.8302,
        total_orders: 23.91,
        neighborhood: 'Pelham Bay',
        keywords: 'Burger, Hamburger, Steak, Pizza, Cajun, Cajun-Style, Beer, Wine, Margarita, Bbq',
        annual_dol: 97.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.786,
        store_longitude: -73.9765,
        total_orders: 449.9,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tequila, Flavorful, Mexican, Lemon Drop, Tacos, Margarita, Organic, Homemade, Lively, Fresh',
        annual_dol: 469.41,
        description:
            '"Tavern setting for Tex-Mex options including tacos, fajitas, & a flavorful margarita selection."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6325,
        store_longitude: -74.0109,
        total_orders: 18.28,
        neighborhood: 'Sunset Park',
        keywords: 'Chinese, Fresh, Casual, Local',
        annual_dol: 74.73,
        description: '"Cantonese Style Live Seafood Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -73.9236,
        total_orders: 651.61,
        neighborhood: 'Bushwick',
        keywords:
            'Champagne, Wine, Trendy, Legendary, Delicious, Vintage, Spectacular, American, Classy, Classic',
        annual_dol: 2455.06,
        description:
            '"From Company XIV, creators of NYC\'s most spectacular burlesque shows, a fusion of circus, cabaret, opera, dance, theatre and lavish design. SEVEN SINS NUTCRACKER ROUGE QUEEN OF HEARTS “ONE OF THE BEST THINGS TO DO IN NEW YORK ON ANY OCCASION” —Forbes Théâtre XIV is a boutique venue in the heart of Graffiti Alley in Bushwick, Brooklyn, where classically trained performers also host and bartend, seating guests and pouring immersive cocktails from a curated menu which also features absinthe, champagne and a selection of treats and sweets."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7301,
        store_longitude: -74.0036,
        total_orders: 43.71,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Flavorful, Champagne, Vodka, Salad, Tasty, Coffee, Organic, Delicious, Cozy',
        annual_dol: 125.91,
        description:
            'Easygoing restaurant specializing in feijoada & other Brazilian dishes, plus cachaça cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7375,
        store_longitude: -73.9549,
        total_orders: 846.05,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Coffee, Italian, Canadian, Beer, French Toast, French, Cozy, Spacious, American',
        annual_dol: 3458.39,
        description:
            'Italian meals are provided in an intimate library setting with a bar, live music & garden seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7073,
        store_longitude: -73.9396,
        total_orders: 699.87,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Wine, Aesthetic, Awesome, Local, Casual, Great, Signature',
        annual_dol: 730.21,
        description:
            'Cocktails & bar bites in a brightly decorated space featuring burlesque shows & a heated patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7315,
        store_longitude: -73.9543,
        total_orders: 549.93,
        neighborhood: 'Greenpoint',
        keywords: 'Mexican, Tacos, Tequila, Beer, Salad, Dessert, Fajitas, Casual, Awesome, Great',
        annual_dol: 561.6,
        description:
            'Warm, casual joint serving Mexican classics from fajitas to tacos plus frozen margaritas & beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9111,
        total_orders: 1171.66,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, Wine, Spanish, Ale, Coffee, American, Western, Irish, Local, Fresh',
        annual_dol: 1196.52,
        description:
            'Featuring pinball & board games, this exposed-brick haunt offers local ciders & beers on tap.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.9072,
        total_orders: 480.98,
        neighborhood: 'Astoria/Woodside',
        keywords: 'Burger, Hamburger, Pizza, Beer, American, Irish, Warm, Casual, Local, Fresh',
        annual_dol: 501.83,
        description:
            'Laid-back hangout featuring TVs, draft brews & elevated bar bites, plus weekday happy-hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.733,
        store_longitude: -74.01,
        total_orders: 38.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster Roll, Lobster, Burger, Salad, Vodka, Chinese, Gin, Homemade, Elegant, Vintage',
        annual_dol: 180.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7859,
        store_longitude: -73.9761,
        total_orders: 1589.81,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Burger, Wine, Irish, Ale, Gin, Spectacular, Awesome, Great, Traditional',
        annual_dol: 1623.55,
        description:
            'A standard neighborhood bar with pub grub, darts, a huge menu of craft brews & a beer club.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.743,
        store_longitude: -73.6182,
        total_orders: 39.98,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Organic, Local, Casual, Healthy',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7728,
        store_longitude: -73.9158,
        total_orders: 731.29,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Czech, Beer, French, Mediterranean, American, Spacious, Casual, Traditional, Classic',
        annual_dol: 895.06,
        description:
            'Czech beer & grilled bratwurst at a huge, family-friendly beer garden full of picnic tables & trees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6924,
        store_longitude: -73.8167,
        total_orders: 20.14,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords: 'Chinese, Asian, Casual, American',
        annual_dol: 82.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7099,
        store_longitude: -73.9374,
        total_orders: 2931.45,
        neighborhood: 'East Williamsburg',
        keywords: 'Tequila, Hotdog, Beer, Wine, Karaoke, Ale, Rum, Magical, Hotel, Funky',
        annual_dol: 3019.52,
        description:
            'Local bar with craft beer & shots, Mexican-American pub food & funky decor such as velvet paintings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7679,
        store_longitude: -73.9685,
        total_orders: 642.91,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Salad, Italian, Wine, Stylish, Modern, Sleek, Comfortable, Casual, Seasonal',
        annual_dol: 670.78,
        description:
            'This petite eatery with modern decor & a bar serves Italian fare, including gluten-free options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7235,
        store_longitude: -73.9504,
        total_orders: 168.8,
        neighborhood: 'Greenpoint',
        keywords: 'Vegetarian, Coffee, Wine, Beer, Thai, Ramen, Cozy, Asian, Delicious, Roti Canai',
        annual_dol: 330.94,
        description:
            'Cozy restaurant helmed by chef Bao Bao.offering Southeast Asian comfort food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6565,
        store_longitude: -73.6484,
        total_orders: 638.52,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Tequila, Steak, Vegetarian, Beer, Margarita, Enchiladas, Fajitas, Modern',
        annual_dol: 673.09,
        description:
            'Contemporary cantina featuring Mexican staples such as tacos, fajitas & enchiladas, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6188,
        store_longitude: -73.9314,
        total_orders: 132.94,
        neighborhood: 'Flatlands',
        keywords:
            'Vegetarian, Salad, Chocolate Mousse, Vietnamese, Nutritious, Thai, Delicious, Fried Rice, Belgian, Asian',
        annual_dol: 361.95,
        description:
            'A long menu of traditional Vietnamese dishes served in an unpretentious space with modern decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9907,
        total_orders: 1865.8,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Wine, Beer, Coffee, Ale, Karaoke, American, Bbq, Irish, Perfect, Great',
        annual_dol: 1905.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9897,
        total_orders: 6388.77,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Trendy, Spanish, Stylish, Iconic, Rooftop, Bright, Amazing, Sophisticated, Classic',
        annual_dol: 6524.32,
        description:
            'Times Square, and a 9-minute walk from Bryant Park.Sleek rooms and suites offer free Wi-Fi, flat-screen TVs and minifridges. Some rooms have balconies and floor-to-ceiling windows with city views. Room service is available.There’s a stylish Spanish restaurant, plus a trendy rooftop bar with city views. Other amenities include a convenience store, a gym, meeting and event space, and a business center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6635,
        store_longitude: -73.7191,
        total_orders: 121.24,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Gourmet, Salad, Burritos, Casual, Local, Premium, Perfect, Fresh',
        annual_dol: 495.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6277,
        store_longitude: -74.0738,
        total_orders: 1359.09,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Vegetarian, Pizza, Italian, Wine, Polished, Casual, Modern, Traditional, Classic, Local',
        annual_dol: 1664.83,
        description:
            '"Pastavino - Pasta & Wine - The quintessential Italian experience, drawing inspiration from the traditional casual yet satisfying trattorias & enotecas (wine bars) dotted throughout Italy. Our menu features classic well executed antipast, a large selection of salumi & formaggi, house made pastas, and entrees. The wine program features a large wine by the glass list, and a selection of 300+ labels by the finest producers in Italy."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.708,
        store_longitude: -74.0089,
        total_orders: 716.24,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Beer, Stylish, Rooftop, Creative, Classic, Local',
        annual_dol: 2338.35,
        description:
            'Stylish theater chain for new & classic films features cocktails & creative bites served seat-side.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9929,
        total_orders: 3797.49,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Coffee, Rooftop, American, Great',
        annual_dol: 3878.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8292,
        store_longitude: -73.7021,
        total_orders: 107.93,
        neighborhood: '',
        keywords: 'Pizza, Italian, Salad, Beer, Southern, Fine',
        annual_dol: 451.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7048,
        store_longitude: -74.0175,
        total_orders: 11302.88,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Beer, Wine, Romantic, Sweet, Amazing, Perfect, Intimate, Great, Fine, Casual',
        annual_dol: 40649.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7065,
        store_longitude: -74.0158,
        total_orders: 533.67,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Italian, Coffee, Wine, Spicy, Modern, Classic, Friendly, Great, Wonderful',
        annual_dol: 556.81,
        description:
            'Compact, modern Italian eatery with a patio serving Neapolitan & pan pizzas & other familiar dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6587,
        store_longitude: -73.9964,
        total_orders: 229.37,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Salad, Greek, Bbq, Karaoke, Fun, Friendly, Retro, Greek Salad, Great, Classic',
        annual_dol: 1034.37,
        description:
            'Happening gay bar featuring DJs & dancing, plus regular events like drag shows & karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7071,
        store_longitude: -73.9221,
        total_orders: 1269.77,
        neighborhood: 'Bushwick',
        keywords: 'Cozy, Intimate, Legendary, Great, Casual, Unique',
        annual_dol: 4030.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7214,
        store_longitude: -73.8452,
        total_orders: 2664.87,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Beer, Cuban, Wine, Steak, Dessert, Caribbean, Hotel, Fun, American, Lively',
        annual_dol: 3996.06,
        description:
            'Lively restaurant & bar serving Nuevo Latino fare & cocktails in a setting with a Miami vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -73.6338,
        total_orders: 1044.13,
        neighborhood: '',
        keywords: 'Burger, Steak, Tasty, Wine, Beer, Savory, Delicious, Warm, Creamy, Bbq',
        annual_dol: 1074.52,
        description:
            'Pub with tap beers & booths serving hearty American grub like burgers & showing sports on TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7415,
        store_longitude: -74.0085,
        total_orders: 779.61,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Korean, Rich, Spacious, Classy, American, Iconic, High-End, Modern, Contemporary, Sophisticated',
        annual_dol: 2245.51,
        description:
            'Elevated Korean delicacies are served in this contemporary restaurant with a spacious terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7356,
        store_longitude: -74.0066,
        total_orders: 459.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Steak, Salad, Tasty, Wine, Delicious, Upscale, Rich, Rustic, Cozy',
        annual_dol: 1878.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.9972,
        total_orders: 35.09,
        neighborhood: 'Little Italy',
        keywords:
            'Cheesecake, Lobster, Lobster Tail, Dessert, Pizza, Wine, Italian, Coffee, Gourmet, Delicious',
        annual_dol: 49.4,
        description:
            'Classic cafe serving cannoli, gelato & other Italian desserts with espresso & wine since 1973.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.8284,
        total_orders: 418.75,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Tasty, Wine, Beer, Chinese, Delicious, Bloody Mary, Great, Local, Casual',
        annual_dol: 456.38,
        description:
            'Easygoing, low-key neighborhood bar with sports on TV alongside cocktails, beer & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.866,
        store_longitude: -73.8302,
        total_orders: 89.64,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Salad, Italian, Steak, Wine, Mexican, Beer, Chinese, Homemade, Irresistible, Iconic',
        annual_dol: 109.98,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.8295,
        total_orders: 78.01,
        neighborhood: 'Flushing/Queensboro Hill',
        keywords: 'Beer, Friendly, Cool, Awesome, Great, Excellent, Casual, Local',
        annual_dol: 95.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7369,
        store_longitude: -74.0015,
        total_orders: 16.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Italian, Rustic, Authentic, Irresistible, Casual, Great, Mouth-Watering',
        annual_dol: 68.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7333,
        store_longitude: -73.958,
        total_orders: 653.47,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Italian, Coffee, Swiss, Spicy, American, French, Delicious, Casual, Local',
        annual_dol: 822.77,
        description:
            'Mediterranean-accented New American fare served in a dim-lit atmosphere heavy on reclaimed wood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7688,
        store_longitude: -73.9555,
        total_orders: 1483.08,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Pizza, Beer, Wine, Spicy, Delicious, Margarita, Irish, Fun, Awesome',
        annual_dol: 1819.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7694,
        store_longitude: -73.9579,
        total_orders: 33.9,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Tequila, Tacos, Steak, Mexican, Beer, Margarita, Delicious, Stylish, Creamy, Vibrant',
        annual_dol: 138.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7312,
        store_longitude: -73.9546,
        total_orders: 752.24,
        neighborhood: 'Greenpoint',
        keywords:
            'Steak, Burger, Mexican, Wine, French, French Onion Soup, Dessert, American, Seasonal, Casual',
        annual_dol: 1024.03,
        description:
            'Unassuming restaurant specializing in French cuisine, including steak & seafood plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7715,
        store_longitude: -73.9591,
        total_orders: 155.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Garlic Bread, Salad, Shrimp Cocktail, French, Wine, Coffee, Organic, American, Filet Mignon',
        annual_dol: 635.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6604,
        store_longitude: -73.9911,
        total_orders: 86.98,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Pizza, Steak, Salad, Tuna Steak, Italian, Meatball, Hawaiian, Beer, Wine',
        annual_dol: 96.99,
        description:
            "\"Battle Hill Tavern is a classic Brick Oven Wood Fired Gastropub located in South Slope, Brooklyn. We offer a varied menu of traditonal and modern pizzas, share dishes, and main courses to fit most every need. Our food allows us the confidence to believe that once you've come dine with us, you'll be back again and again. We are absolutely family friendly, and have outdoor seating for people who wnat to enjoy their dinner with their furry, (or feathered), best friends. BHT is a place people call home, where they come to spend time with friends and make new ones. Please come sit with us, make yourself at home, and see why our customers don't stay strangers for long. Come visit our family, yours is always welcome. Welcome Home. B.H.T.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6662,
        store_longitude: -73.9787,
        total_orders: 86.08,
        neighborhood: 'Park Slope',
        keywords:
            'Salad, Champagne, Wine, Gluten Free, Coffee, Beer, Italian, Organic, Homemade, Meatball',
        annual_dol: 351.85,
        description:
            'Neighborhood joint serving homemade pasta & sandwiches in an unfussy space with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7434,
        store_longitude: -73.6153,
        total_orders: 1401.78,
        neighborhood: '',
        keywords:
            'Vegetarian, Steak, Wine, Flavorful, Dessert, Savory, Upscale, Stylish, Shrimp Scampi, Comfortable',
        annual_dol: 1431.53,
        description:
            'Rotating menu of seasonal American dishes alongside international wines in an upscale setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7448,
        store_longitude: -73.6051,
        total_orders: 235.64,
        neighborhood: '',
        keywords:
            'Vegetarian, Mexican, Wine, Casual, Italian, Modern, American, Contemporary, Friendly, Authentic',
        annual_dol: 432.52,
        description:
            'Updated spins on Mexican fare join craft cocktails in this chic, contemporary space with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.779,
        store_longitude: -73.96,
        total_orders: 29.3,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Wine, Italian, Pizza, Vodka, Tasty, Coffee, Ale, Hotel, Creamy, Exquisite',
        annual_dol: 119.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7711,
        store_longitude: -73.9225,
        total_orders: 16636.54,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Luxurious, Elegant, Korean, Spanish, Classic, Memorable, Friendly, Relaxed, Great, Modern',
        annual_dol: 17401.03,
        description:
            '"Astoria World Manor is the finest banquet hall in all of Queens. We provide you with professional banquet planning for your wedding, party or event with luxurious elegance at affordable prices."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6893,
        store_longitude: -73.816,
        total_orders: 29.3,
        neighborhood: 'South Ozone Park/South Richmond Hill',
        keywords: 'Burger, Chinese, Ale, Indian, Local, Caribbean, Casual, Fresh, Great, Fine',
        annual_dol: 59.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7073,
        store_longitude: -73.6752,
        total_orders: 21.18,
        neighborhood: '',
        keywords:
            'Chinese, Italian, Shrimp Lo Mein, Asian, Wonton Soup, Casual, Dim Sum, Authentic',
        annual_dol: 86.57,
        description:
            '"Hi, Everyone! We are open for In door Dining, Take out, Catering & Delivery. We Offers the finest selection of world-class Szechuan, Asian ,Seafood , Cantonese Dim Sum & Full Bar (happy hour 11:30am-6 pm Daily), Using Only The Freshest ,Best & Highest Quality Ingredients For All Of Our Customers. Please Come join us with your Friends &Family, We look forward to seeing you all soon."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6636,
        store_longitude: -73.9845,
        total_orders: 301.05,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Margarita, Dessert, Wine, Spicy, Mediterranean, American, Latin, Contemporary, Asian',
        annual_dol: 309.18,
        description:
            'Elevated Mexican cuisine in a festive dining room, plus tequila-based cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6698,
        store_longitude: -73.9955,
        total_orders: 400.78,
        neighborhood: 'Gowanus',
        keywords: 'Casual, Intimate, Recommended',
        annual_dol: 436.16,
        description:
            "Energetic nightspot offering DJ'd music & dancing in a neon-lit, industrial space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7712,
        store_longitude: -73.9594,
        total_orders: 4880.6,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Steak, Sushi, Salad, Tasty, Apple Pie, Beer, Funky, Juicy',
        annual_dol: 4984.16,
        description:
            'A comfy atmosphere attracts a laid-back neighborhood crowd to this cash-only pub famed for burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7111,
        store_longitude: -73.9453,
        total_orders: 316.56,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Tequila, Wine, Beer, Hotel, Casual',
        annual_dol: 1294.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.953,
        total_orders: 187.89,
        neighborhood: 'Greenpoint',
        keywords: 'Wine, Friendly, Traditional, Casual, Chic, Awesome, Fresh, Great',
        annual_dol: 768.04,
        description:
            'This unassuming, buzzy nightclub with chic styling features a full roster of DJs & concerts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7244,
        store_longitude: -73.9513,
        total_orders: 360.26,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Beer, Spanish, Mediterranean, Clean, Awesome, Wonderful, Latin, Great, Excellent',
        annual_dol: 430.35,
        description:
            'Barcelona-style small plates, cocktails & wines served in a sleek, exposed-brick space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -74.0022,
        total_orders: 104.45,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Dairy Free, Vegetarian, Salad, Wine, Vodka, Italian, Shrimp Cocktail, Organic, Martini, Quaint',
        annual_dol: 319.35,
        description:
            'Italian classics are crafted from organic ingredients in a sunwashed stucco space with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7006,
        store_longitude: -73.9126,
        total_orders: 18,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Coffee, Beer, Vietnamese, Dessert, Delicious, Natural, Casual, Great',
        annual_dol: 73.58,
        description:
            '"All-day bar with craft beer, coffee, natural wines, cocktails, and Vietnamese-inspired cafe/bar fare."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6476,
        store_longitude: -73.9559,
        total_orders: 184.92,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Mexican, Karaoke, Caribbean, Retro, Casual',
        annual_dol: 193.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6978,
        store_longitude: -73.937,
        total_orders: 621.15,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords:
            'Burger, Pizza, Steak, Vegetarian, Vegan, Italian, Meatball, Wine, Delicious, Cuban',
        annual_dol: 675.97,
        description:
            '"CONCRETE is a casual eatery, serving authentic Sicilian food to the Bedstuy and Bushwick neighborhoods. Happy hour drink specials, Brunch on weekends, a large, airy dine-in space, sidewalk seating. Delivery + Pickup service available."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7311,
        store_longitude: -73.9542,
        total_orders: 1197.91,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Salad, Beer, Wine, Warm, Creamy, Donut, Cozy, Swiss, Relaxed',
        annual_dol: 1297.8,
        description:
            'Refurbished wood adorns this laid-back bar serving local beers & grub such as burgers & pierogi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7153,
        store_longitude: -74.0156,
        total_orders: 422.53,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Kebab, Salad, Wine, Organic, Mediterranean, Greek, Elegant, Polished, Welcoming, Premium',
        annual_dol: 1727.16,
        description:
            '"The Ancient Greek queen of the Mediterranean was crowned with the title of Anassa, given only to unique women with goddess-like qualities. The title, similar to a “queen,” was reserved for women who exuded rare qualities and benevolent leadership. Like the Ancient Greek Anassa, we believe our restaurant stands out, representing all of the finest recipes, culinary traditions and ingredients from Greece and the Mediterranean – always standing out for our quality and attention to detail. We look forward to welcoming you to Anassa Taverna and giving you a bit of our own… royal treatment that only an Anassa can give."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.705,
        store_longitude: -73.9308,
        total_orders: 101.76,
        neighborhood: 'East Williamsburg',
        keywords: 'Wine, Beer, Sustainable, Unique',
        annual_dol: 415.97,
        description:
            '"Carroll Hall is suitable for many different types of events and we welcome them all. From weddings, corporate events and conferences to private parties, art events and pop-up events, we can accommodate you. Carroll Hall consists of two main spaces: The Warehouse and The Garden. Both spaces can be configured to host you and your guests. There is also a kitchen prep room, the garden ‘Tree House’ and a VIP area that looks out over both Warehouse and Garden. By appointment only. hello@carrollhall.com"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.657,
        store_longitude: -73.6083,
        total_orders: 2265.45,
        neighborhood: '',
        keywords:
            'Vegan, Karaoke, French Toast, French, Delicious, Irish, American, Local, Casual, Fresh',
        annual_dol: 2999.15,
        description: '"Live Music, Great food, Great Drinks"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7338,
        store_longitude: -73.9997,
        total_orders: 2446.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesecake, Pizza, Italian, Salad, Wine, Dessert, Tasty, Rich, Delicious, Margarita',
        annual_dol: 2498.66,
        description:
            'Naples meets NYC at this trattoria with thin-crust pizza, Italian wines & ample sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7339,
        store_longitude: -74.0021,
        total_orders: 682.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cozy, Picturesque, Trendy, Rustic, Fun, Legendary, Fancy, Sweet, Fantastic, Great',
        annual_dol: 858.79,
        description:
            'Modest cover charges & some major talents make this cozy downstairs music club a local stalwart.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -74.0028,
        total_orders: 3709.54,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Vegan, Gluten Free, Spicy, American, Healthy, Hotel, Contemporary, Refined, Fun',
        annual_dol: 3788.25,
        description:
            'Stylish, airy bar & restaurant presenting refined New American fare & inventive craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7404,
        store_longitude: -74.0056,
        total_orders: 537.76,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Coffee, Whiskey, Vodka, Beer, Gin, Dessert, Gourmet, Bourbon, Delicious, Warm',
        annual_dol: 860.79,
        description:
            'Chill, vintage-style spot with a patio for choice coffee, craft cocktails & gourmet light bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -74.0078,
        total_orders: 447.84,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Hotel, Delicious, Sustainable, Creative, Bbq, Sensational, Intimate, Perfect, Succulent, Modern',
        annual_dol: 609.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7755,
        store_longitude: -73.9533,
        total_orders: 22.11,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Chinese, Asian, Spicy, Casual, Authentic, Hospitable, Dan Dan, Mai Tai, Great',
        annual_dol: 90.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7888,
        store_longitude: -73.9744,
        total_orders: 474.18,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Steak, Tasty, Chocolate Mousse, Upscale, Delicious, Italian, French Toast, Premium, French',
        annual_dol: 505.7,
        description:
            'Serene joint with a classy vibe offering small plates & full meals with matching wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -73.9517,
        total_orders: 1748.85,
        neighborhood: 'Greenpoint',
        keywords: 'Quaint, Margarita, Awesome, Great, Local, Casual',
        annual_dol: 1785.96,
        description:
            'Quaint neighborhood sports bar with a selection of Midwestern canned & bottled brews, plus taps.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7631,
        store_longitude: -73.9565,
        total_orders: 438.89,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Coffee, American, Rare, Local, Great',
        annual_dol: 457.92,
        description:
            'Memorial Sloan Kettering Cancer Center is a cancer treatment and research institution in the borough of Manhattan in New York City, founded in 1884 as the New York Cancer Hospital. MSKCC is one of 52 National Cancer Institute–designated Comprehensive Cancer Centers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -73.9855,
        total_orders: 4297.1,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Rich, Opulent, Urban, Iconic, Relaxed, American, Friendly, Local',
        annual_dol: 4658.52,
        description:
            "Herald Square subway station.Polished rooms provide HDTVs and free Wi-Fi, as well as minifridges, microwaves, and tea and coffeemakers. Suites add sitting areas with pull-out sofas; some offer balconies and/or city views. Room service is available.Complimentary amenities include hot breakfast, and nightly receptions with snacks and drinks. There are 2 relaxed restaurant/bars, plus a lounge featuring a terrace and city views. There's a gym. Parking is extra.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.8449,
        total_orders: 480.57,
        neighborhood: 'Willets Point/Flushing/Corona',
        keywords: 'Gourmet, Irish, Fun, Hotel, Seasonal, Fusion, Classic, American, Unpretentious',
        annual_dol: 2214.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9857,
        total_orders: 198.68,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Western, Hotel, Beer, Perfect, Clean, Friendly, Exquisite, Modern, Great, Fantastic',
        annual_dol: 232.13,
        description:
            "Museum of Art.The modern rooms come with free WiFi, flat-screen TVs and desks. Some rooms have iPod docks, minifridges or views of the Empire State Building. Kids age 12 and under stay at no extra cost with an adult.Amenities include a lounge bar and a furnished patio. There's also a fitness center and a 24-hour business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7944,
        store_longitude: -73.6983,
        total_orders: 1349.08,
        neighborhood: '',
        keywords: 'Burger, Steak, Beer, Italian, Upscale, Wine, Spicy, Delicious, Irish, Modern',
        annual_dol: 1349.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5788,
        store_longitude: -73.8494,
        total_orders: 153.01,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords:
            'Garlic Bread, Pizza, Delicious, Italian, Wine, Homemade, Fresh, Relaxed, Casual, Local',
        annual_dol: 208.3,
        description:
            'Family-owned standby serving pizza & many Italian favorites in a relaxed atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7035,
        store_longitude: -74.0111,
        total_orders: 593.23,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Cheesesteak, Whiskey, Beer, Delicious, Philly Cheesesteak, Authentic, Friendly, Great',
        annual_dol: 2082.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7208,
        store_longitude: -74.0054,
        total_orders: 756.33,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Hotel, Relaxed, Lively, Great, Fine',
        annual_dol: 1002.17,
        description:
            "Relaxed rooms include free Wi-Fi and flat-screen TVs, along with Keurig coffeemakers, microwaves and minifridges; most provide city views. Upgraded rooms add whirlpool tubs. Room service is available.There's a lively restaurant offering wood-fired pizzas, craft beers and big-screen sports. Cooked-to-order breakfast is available for a fee. There's also a 24/7 pantry with snacks and drinks. Additional amenities include a fitness center, a business center and valet parking (fee).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7449,
        store_longitude: -73.9043,
        total_orders: 963.02,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Burger, Pizza, Beer, Dessert, Irish, Ale, Homemade, French, Bruschetta, Fun',
        annual_dol: 983.46,
        description:
            'Roomy Irish pub with an old-world, gothic-style interior & traditional bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -73.8422,
        total_orders: 257.06,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Pizza, Wine, Italian, Peruvian, Refined, Healthy, Charming, Mediterranean, Friendly',
        annual_dol: 286.43,
        description:
            'Charming hangout with a maritime vibe serving refined seafood eats, plus beer, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9897,
        total_orders: 1642.24,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Korean, American, Organic, Spicy, Wine, Contemporary, Stylish, Latin, Sleek',
        annual_dol: 1677.09,
        description:
            'Contemporary Korean bar & restaurant serving cocktails & party-friendly fare in a sleek space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7662,
        store_longitude: -73.9868,
        total_orders: 407.3,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Tasty, Salad, Wine, Delicious, Healthy, Classy, Friendly, Innovative, Perfect',
        annual_dol: 1664.92,
        description:
            'Small, colorful spot with a lively vibe serving Mexican fare, margaritas & sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8101,
        store_longitude: -73.9585,
        total_orders: 225.19,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Vegan, Wine, Gluten Free, Beer, Ethiopian, African, Spicy, Delicious, Romantic, Classy',
        annual_dol: 275.85,
        description:
            'East African eatery serves traditional Ethiopian & Eritrean fare scooped by hand with injera bread.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.9825,
        total_orders: 106.63,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Wine, Coffee, Burger, Beer, Vietnamese, Seasonal, Delicious, French, Modern',
        annual_dol: 142.92,
        description:
            '"1700 Broadway is a classic Midtown Manhattan property, now reimagined and fully modernized. Located on Broadway between 53rd and 54th streets in the heart of Midtown, 1700 Broadway offers a striking glass curtain wall, an efficient side core floorplate, and abundant views of Central Park, the Hudson River, and the Midtown skyline. The property has recently completed a dramatic transformation activating market-leading tenant amenities, including a private amenity lounge, conference center, and rooftop terrace."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5151,
        store_longitude: -74.2481,
        total_orders: 63.62,
        neighborhood: 'Tottenville',
        keywords:
            'Lobster, Pizza, Delicious, Italian, Memorable, American, Fresh, Great, Satisfying, Creative',
        annual_dol: 260.05,
        description:
            '"Paradise Island is a seafood restaurant that offers steak, seafood, dinner, and lunch in Staten Island, NY. We value our customers and strive to make your meal satisfying and delicious. Their menu comprises a selection of imported products paired with local products creative bond. Our shop also provides delivery that promotes a pleasant dining experience brought to your home. In addition, we aim to ensure you have a memorable dining experience with our restaurant, which is why we provide live music from our live band, a bar, and a lounge."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 15.37,
        neighborhood: 'Jackson Heights',
        keywords:
            'Colombian, Trendy, Delicious, Homey, Cozy, Traditional, Authentic, Terrific, Casual',
        annual_dol: 62.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6815,
        store_longitude: -73.9774,
        total_orders: 683.78,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Wine, Salad, Beer, Tasty, Karaoke, Sweet, Friendly, Intimate, Perfect',
        annual_dol: 779.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7397,
        store_longitude: -73.9925,
        total_orders: 66.96,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Korean, Upscale, Stylish, Modern, Contemporary, Fancy, High-End, Retro, Casual, Traditional',
        annual_dol: 273.72,
        description:
            'Stylish restaurant with retro decor offering upscale Korean cuisine with a modern twist.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5425,
        store_longitude: -74.1652,
        total_orders: 28.53,
        neighborhood: 'Eltingville/Annadale',
        keywords: 'Japanese, Sushi, Asian, Casual, Great',
        annual_dol: 116.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6878,
        store_longitude: -73.9704,
        total_orders: 5426.47,
        neighborhood: 'Fort Greene',
        keywords:
            'Salad, Vegan, Wine, Tasty, Beer, Mediterranean, Spicy, Japanese, Rustic, Turkish',
        annual_dol: 5541.61,
        description:
            'Mediterranean eatery serving creative tapas, brunch & sangria in a bohemian, plant-filled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7144,
        store_longitude: -73.9579,
        total_orders: 7525.03,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegan, Wine, Beer, Rustic, Impeccable, Warm, Four Horsemen, American, Delicious, Relaxed',
        annual_dol: 7732.62,
        description:
            'Open-air, brick-lined eatery serving New American dishes for brunch & dinner, plus cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.9563,
        total_orders: 5472.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Pizza, Meatball, Delicious, Avocado Toast, Stylish, Mediterranean, Impeccable, Rum, Moroccan',
        annual_dol: 15739.07,
        description:
            'Laid-back joint turning out Mediterranean Balkan dishes in a contemporary venue adorned with plants.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7147,
        store_longitude: -73.9436,
        total_orders: 203.5,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Whiskey, Beer, Wine, Canadian, Irish, Rum, English Muffin, American, Yummy',
        annual_dol: 994.46,
        description:
            'Mellow saloon with a backyard patio, popular brunch, microbrews & a lodgelike vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7453,
        store_longitude: -73.9782,
        total_orders: 4241.01,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Upscale, Wine, Delicious, Beer, Fun, Irish, Wonderful, Great, Incredible, Modern',
        annual_dol: 4819.85,
        description:
            'A full menu of pub grub, beer & cocktails is complemented by sports on TV & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9668,
        total_orders: 488.02,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Tasty, Vintage, Premium, Cool, Unique',
        annual_dol: 1994.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -73.9845,
        total_orders: 1901.15,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Ale, Beer, Wine, Irish, Traditional, Premium, Clean, Friendly, Lively, Casual',
        annual_dol: 1983.57,
        description:
            'Lively Irish tavern featuring pool tables, sports on TV & daily happy hour amid horse-racing decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7381,
        store_longitude: -73.9876,
        total_orders: 1415.65,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Steak, Burger, Salad, French, Wine, Tasty, Swiss, Beer, Classy, Traditional',
        annual_dol: 1415.65,
        description:
            'French spot open 24/7 serving bistro classics such as steaks & mussels plus wine, beer & breakfast.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8514,
        store_longitude: -73.9394,
        total_orders: 2001.36,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Burger, Pizza, Sushi, Bread Pudding, Coffee, Italian, Wine, Chinese, Upscale, Rustic',
        annual_dol: 2051.63,
        description:
            'Cocktails & upscale comfort fare, including breakfast, served in a cozy, rustic-chic tiled setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9707,
        total_orders: 12819.36,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Lobster, Lobster Roll, Shrimp Cocktail, Salad, Cajun, Gourmet, Roast Beef, Wine',
        annual_dol: 13131.32,
        description:
            'Dry-aged beef, martinis & wine served by old-school waiters draw crowds to this Midtown institution.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.9434,
        total_orders: 800.7,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Salad, Hamburger, Wine, Beer, Caesar Salad, American, Fun, Awesome, Casual',
        annual_dol: 1045.08,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6943,
        store_longitude: -73.8279,
        total_orders: 37.81,
        neighborhood: 'Richmond Hill',
        keywords: 'Wine, Ale, Rich, French, Friendly, Eclectic, American, Cool, Casual, British',
        annual_dol: 154.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8019,
        store_longitude: -73.6469,
        total_orders: 2393.74,
        neighborhood: '',
        keywords:
            'Lobster, Kebab, Shrimp Cocktail, Salad, Dessert, Coffee, Upscale, Rich, Mediterranean, Elegant',
        annual_dol: 2462.59,
        description:
            'Upmarket Greek option serving seafood, chops & veggies in an elegant space with a romantic patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7151,
        store_longitude: -74.0104,
        total_orders: 24.91,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Vegetarian, Vegan, Beer, Milkshake, Delicious, Warm, Healthy, Cozy, Casual',
        annual_dol: 101.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.9681,
        total_orders: 144,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Tacos, Burger, Vegan, Burritos, Margarita, Tex-Mex, Quesadillas, Gluten Free, Casual',
        annual_dol: 243.03,
        description:
            'Energetic Mexican joint whose big burritos & cheap margaritas are popular with students.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9862,
        total_orders: 5275.41,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Garlic Bread, Lobster, Shrimp Cocktail, Cajun, Beer, Italian, Charming, Fun, Entertaining, Casual',
        annual_dol: 5516.44,
        description:
            'Family-friendly chain for seafood & American fare served in a fishing boat-themed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7832,
        store_longitude: -73.9573,
        total_orders: 710.26,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Pizza, Salad, Italian, Shrimp Cocktail, Wine, Warm, Fun, Comfortable, Legendary, Fresh',
        annual_dol: 1740.08,
        description:
            'Comfortable restaurant spotlighting Italian cuisine, including pasta, pizza & seafood dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9843,
        total_orders: 3445.22,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Salad, Lobster Bisque, Wine, Vodka, Beer, French Onion Soup, French, Upscale',
        annual_dol: 3594.59,
        description:
            'Posh space in the AKA Times Square for steak, seafood, caviar & other high-end American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.755,
        store_longitude: -73.98,
        total_orders: 22.83,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Premium, Gourmet, Perfect, Adventurous',
        annual_dol: 93.31,
        description:
            '"Convene at 530 Fifth Avenue is a premium meeting and event space as well as professional work place in Midtown East within walking distance to Grand Central Station. As one of our largest spaces, it includes a dedicated floor of on-demand meeting spaces ranging from small huddles to large hubs and two floors of tailored, furnished work suites and membership-based work areas. 530 Fifth Avenue also includes a health and wellness center."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6913,
        store_longitude: -73.9975,
        total_orders: 0,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Pizza, Italian, Beer, Delicious, American, Fresh, Unpretentious, Casual, Unique',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7519,
        store_longitude: -73.9976,
        total_orders: 16175.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Stylish, Lively, Spicy, Elegant, Modern, Vegan, Romantic, Polished, Amazing, Mediterranean',
        annual_dol: 53321.8,
        description:
            'Stylish restaurant with an open kitchen dishing up Eastern Mediterranean bites & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.9991,
        total_orders: 2181.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Salad, Italian, Bbq, Trendy, Homemade, Contemporary, Casual, Intimate, Creative',
        annual_dol: 8917.68,
        description:
            '"The Midnight Theatre is a variety theatre, restaurant, and bar opening at Brookfield\'s Manhattan West in June 2022."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9859,
        total_orders: 224.26,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Tail, Dessert, Delicious, Shrimp Cocktail, Upscale, Tequila, Elegant, Italian, Cozy',
        annual_dol: 916.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9932,
        total_orders: 2423.85,
        neighborhood: 'NoHo',
        keywords: 'Wine, Beer, Cozy, Inviting, Friendly, Clean, Hotel, Fancy, Cool, Great',
        annual_dol: 2543.94,
        description:
            'Laid-back bar with cocktails & an extensive microbrew list that draws a tech-savvy crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6599,
        store_longitude: -73.9608,
        total_orders: 776.44,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean/Lefferts Manor Historic District',
        keywords: 'Wine, Beer, Coffee, Mexican, Hotel, French Toast, Rum, French, Cozy, Eclectic',
        annual_dol: 1815.76,
        description: '"Neighborhood bar with food"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9825,
        total_orders: 5376.07,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Salad, Wine, Milkshake, Coffee, Shrimp Alfredo Pasta, French Toast, Beer, Martini, French Onion Soup, French',
        annual_dol: 7191.76,
        description:
            '"Due to state wide mandates, we are open until 12AM but our last seating is at 11PM. Thank you, The Sugar Factory Family"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7775,
        store_longitude: -73.9786,
        total_orders: 1097.37,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Spanish, Tasty, Mediterranean, Intimate, American, Latin, Greek, Seasonal, Natural',
        annual_dol: 1144.94,
        description:
            'Eatery for shareable plates with Latin & Mediterranean flavors & drinks with housemade ingredients.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7775,
        store_longitude: -73.9835,
        total_orders: 1797.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Tasty, Dessert, Wine, French Onion Soup, Bourbon, French, Ample, Urban, Fresh',
        annual_dol: 1875.26,
        description:
            'French comfort food & a Parisian vibe have been drawing A-list types here since 1983.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7571,
        store_longitude: -73.9785,
        total_orders: 39.52,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Upscale, Wine, Golfclub, Hotel, Spacious, Beer, Perfect, Urban, Great, Hip',
        annual_dol: 77.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.9754,
        total_orders: 5906.02,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Lobster, Burger, Italian, Mead, Gracious, Fancy, Classy, Wonderful, Classic',
        annual_dol: 6066.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8178,
        store_longitude: -73.9605,
        total_orders: 3468.76,
        neighborhood: 'Upper Manhattan',
        keywords: 'Burger, Steak, Salad, Bbq, Beer, Rustic, American, Delicious, Homemade, Retro',
        annual_dol: 3542.36,
        description:
            'Barbecue chain serving Southern-style meats & draft brews in a retro setting (most have live music).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7663,
        store_longitude: -73.978,
        total_orders: 6460.42,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Luxurious, Upscale, Hotel, Elegant, Stylish, Warm, Iconic, Spectacular, Cozy, Premium',
        annual_dol: 6597.5,
        description:
            'around Columbus Circle.Elegant rooms feature smart TVs, iPod docks and Wi-Fi (fee), as well as minibars. Club-level rooms have access to a lounge with free continental breakfast and evening snacks. Suites add living areas with sofas; upgraded suites come with fireplaces and/or balconies. Room service is available 24/7.Amenities include a warm restaurant with a bar and city views, along with a spa and a gym. Valet parking is offered for a surcharge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.734,
        store_longitude: -74.0014,
        total_orders: 1031.22,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Salad, Wine, Rustic, Avocado Toast, French, Seasonal, Casual, Delicious, American',
        annual_dol: 1056.07,
        description:
            'New American fare served from breakfast to late-night in a rustic space with just a few tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7274,
        store_longitude: -73.635,
        total_orders: 3476.48,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Shrimp Cocktail, Champagne, Wine, Beer, Spicy, Delicious, Belgian',
        annual_dol: 3482.23,
        description:
            'Mussels are the main event at this Belgian bistro with lots of imported beers & a lively bar scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.747,
        store_longitude: -73.6482,
        total_orders: 174.49,
        neighborhood: '',
        keywords: 'American, Heritage, Local, Tradition, Cultural',
        annual_dol: 275.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7108,
        store_longitude: -73.9264,
        total_orders: 119692.68,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Hotel, Delicious, Awesome, Great, Intimate, Complex, Local',
        annual_dol: 125458.42,
        description:
            '"Avant Gardner is an indoor-outdoor, sprawling events complex in East Williamsburg, Brooklyn. Home of The Brooklyn Mirage, The Great Hall and The Kings Hall."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7095,
        store_longitude: -73.9332,
        total_orders: 18528.25,
        neighborhood: 'East Williamsburg',
        keywords:
            'Karaoke, Rooftop, Wine, Trendy, Fun, Vodka, Breakfast Burrito, Amazing, Classy, Great',
        annual_dol: 19390.72,
        description:
            'Industrial-chic venue of rehearsal space, art studios & rooftop bar with live music, films & snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7095,
        store_longitude: -73.6276,
        total_orders: 40.92,
        neighborhood: '',
        keywords:
            'Steak, Mexican, Salad, Roast Beef, Fajitas, American, Homemade, Local, Casual, Great',
        annual_dol: 167.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6735,
        store_longitude: -73.9915,
        total_orders: 3957.83,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Comfortable, Intimate, Casual, Sweet, Traditional, Ample, British',
        annual_dol: 4041.81,
        description:
            'Big-name acts & up-and-comers play at this big bar/performance venue set in a former warehouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5756,
        store_longitude: -73.9831,
        total_orders: 374.52,
        neighborhood: 'Coney Island',
        keywords:
            'Salad, Cajun, Coffee, Chinese, Bbq, Jamaican, French, Caribbean, Mouth-Watering, Casual',
        annual_dol: 482.45,
        description:
            'Caribbean-inspired cuisine with signature Rasta Pasta, plus a full bar & entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7674,
        store_longitude: -73.9683,
        total_orders: 1016.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Burger, Hamburger, Chocolate Croissant, Wine, Dessert, Coffee, French, Beer, Cozy',
        annual_dol: 1042.46,
        description:
            'French brasserie serving classics like moules & steak tartare in a snug setting that suggests Paris.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9582,
        total_orders: 151.41,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Delicious, American, Moscow Mule, Bright, Perfect, Hip, Casual',
        annual_dol: 661.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6549,
        store_longitude: -74.0126,
        total_orders: 183.82,
        neighborhood: 'Sunset Park',
        keywords: 'Diverse, Cultural',
        annual_dol: 415.3,
        description:
            '"We’re part of Danny Meyer’s Union Square Hospitality Group, which includes restaurants, bars, cafes, and fast-casual eateries in addition to Union Square Events\' large-scale event services, food service solutions for public and private institutions, industry consulting, and educational programming and partnerships. Founded in 2005 as Hudson Yards Catering, Union Square Events is a culinary and operations leader in the hospitality industry, partnering with a diverse portfolio of best-in-class clients. We produce one-of-a-kind catered events and unparalleled dining experiences in a variety of cultural, corporate, entertainment, and private venues throughout New York City and beyond."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -73.9946,
        total_orders: 7117.71,
        neighborhood: 'Little Italy',
        keywords:
            'Lobster, Steak, Mexican, Upscale, Beer, Italian, Elegant, Creamy, Sustainable, Spacious',
        annual_dol: 7268.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8071,
        store_longitude: -73.9284,
        total_orders: 7387.74,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Salad, Karaoke, Wine, Puerto Rican, Shrimp Cocktail, Caribbean, Spanish, Timeless, Perfect, Authentic',
        annual_dol: 8429.61,
        description:
            'Colorful locale for Puerto Rican specialties & a lounge featuring a dance floor & DJ music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7056,
        store_longitude: -74.0113,
        total_orders: 345.99,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Beer, Whiskey, Wine, Margarita, Tasty, American, Rum, Mojito, Friendly',
        annual_dol: 1414.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -74.0088,
        total_orders: 59.86,
        neighborhood: 'Hudson Square',
        keywords:
            'Tacos, Hotel, Rooftop, Cuban, Trendy, Tasty, Stylish, Delicious, Romantic, Awesome',
        annual_dol: 244.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.5607,
        total_orders: 27.51,
        neighborhood: '',
        keywords: 'Premium, Moroccan, Chill, Unique',
        annual_dol: 38.5,
        description:
            'Hangout offering hundreds of hookah flavors in plush surroundings with belly dancers some nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7159,
        store_longitude: -73.9966,
        total_orders: 935.25,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Japanese, Sushi, Urban, Hotel, Sleek, Organic, Aesthetic, Awesome, Perfect, Intimate',
        annual_dol: 3822.99,
        description:
            '"After nearly two decades of honing his skills in Japan and New York City, chef Atsushi “ATS” Kono presents the craft of Japanese yakitori at his namesake restaurant, KONO. KONO\'s discreet locale within Chinatown\'s Canal Arcade echoes the hidden streets and alleyways of Tokyo – inside, guests will be treated to a special evening of smoke and fire as they watch chef Kono prepare a multi-course, kappo-style yakitori omakase rooted in tradition and seasonality. Guests can expect to enjoy various cuts (and specialty cuts) of organic Amish chicken grilled over Binchotan hardwood charcoal."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.9884,
        total_orders: 2408.92,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Tacos, Burger, Beer, Salad, Wine, Nachos, American, Friendly, Casual, Classic',
        annual_dol: 2460.03,
        description:
            'Industrial-chic sports bar with a Pittsburgh Steelers theme, plus updated pub grub & happy hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7161,
        store_longitude: -74.0086,
        total_orders: 309.22,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Italian, Avocado Toast, Relaxed, Warm, French, Casual, Paloma, Perfect, Contemporary, Seasonal',
        annual_dol: 1165.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -74.005,
        total_orders: 919.96,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Karaoke, Steak, Sushi, Salad, Tuna Steak, Japanese, Wine, Organic, Chic, Natural',
        annual_dol: 939.47,
        description:
            'Chic karaoke lounge with 3 sizes of private rooms for shared singing, cocktails, happy hour & sushi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -73.9261,
        total_orders: 2092.6,
        neighborhood: 'East Williamsburg',
        keywords: 'Hotdog, Pizza, Hotel, Karaoke, Great, Cool, Excellent, Modern, Rooftop, Casual',
        annual_dol: 3057.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7351,
        store_longitude: -74.0006,
        total_orders: 4568.15,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Hamburger, Shrimp Cocktail, Gluten Free, Wine, Delicious, French, Trendy, Prime Rib',
        annual_dol: 4712.75,
        description:
            "Steakhouse by Brendan Sodikoff (Chicago's Au Cheval) with contemporary offerings & vintage appeal.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7404,
        store_longitude: -74.0054,
        total_orders: 5137.39,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Tacos, Tequila, Steak, Mexican, Lobster, Vegetarian, Wine, Delicious, Japanese, Vibrant',
        annual_dol: 5599.49,
        description:
            'Vibrant cantina serving modern Mexican cuisine, made-to-order guacamole & tequila cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7183,
        store_longitude: -73.6605,
        total_orders: 53.98,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Pizza, Tacos, Salad, Chicken Parmigiana, Italian, Meatball, Flavorful, Tasty',
        annual_dol: 58.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.861,
        store_longitude: -73.6405,
        total_orders: 54.27,
        neighborhood: '',
        keywords:
            'Burger, Beer, Pizza, Hamburger, Wine, Ale, Delicious, Comfortable, American, Belgian',
        annual_dol: 221.83,
        description:
            '"Garvies Point Brewery continues to push the boundaries of craft brewing through our own curiosité. We focus on offering a wide range of styles, and brew innovative beers with quality ingredients, creativity, and passion."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -73.9925,
        total_orders: 596,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Pizza, Italian, Coffee, Wine, Modern, American, Sleek, Homemade, Perfect, Fresh',
        annual_dol: 2442.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8379,
        store_longitude: -73.8607,
        total_orders: 79.72,
        neighborhood: 'East Bronx/Parkchester',
        keywords: 'Cajun, Flavorful, American, Southern, Authentic, Fun, Casual, Great',
        annual_dol: 325.87,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6625,
        store_longitude: -73.5534,
        total_orders: 646.74,
        neighborhood: '',
        keywords:
            'Steak, Burger, Chicken Parmigiana, Salad, Gluten Free, Vodka, Beer, Bourbon, Dessert, Bbq',
        annual_dol: 694.56,
        description:
            'Burgers, steak & seafood are on offer at this contemporary American grill & bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7546,
        store_longitude: -73.6881,
        total_orders: 1291.34,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Salad, Gourmet, Godfather, Yummy, Casual, Friendly, Local, Sensational',
        annual_dol: 1351.84,
        description:
            'Bustling, family-run trattoria serving homestyle Italian cuisine & pizzas, with a gourmet food shop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.694,
        store_longitude: -73.9025,
        total_orders: 200.23,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Pizza, Spicy, Delicious, Coffee, Cozy, Rum, Homemade, Amazing, Casual, Local',
        annual_dol: 515.97,
        description:
            '"COFFEE shop hours M-F 9AM-4PM BRICK OVEN PIZZA hours Mon,Thurs& Sun 4PM-10PM Fri & Sat 4PM-1030PM Tue& Wed Closed BRUNCH Sat - Sun 11am - 4pm"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7673,
        store_longitude: -73.9593,
        total_orders: 1451.36,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Garlic Bread, Lobster, Salad, Italian, Wine, Caesar Salad, American, Casual, Cobb Salad',
        annual_dol: 1517.51,
        description:
            'American comfort food & a casual environment are the lures of this veteran neighborhood eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8543,
        store_longitude: -73.8888,
        total_orders: 94.58,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Dessert, Coffee, Pizza, Cozy, Italian, Comfortable, Sweet, Rustic, Friendly, Vibrant',
        annual_dol: 231.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7656,
        store_longitude: -73.9655,
        total_orders: 27.81,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Wine, Dessert, Vegetarian, French Onion Soup, French, Cozy, Colorful, Fresh, Casual',
        annual_dol: 75.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7688,
        store_longitude: -73.9565,
        total_orders: 98.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Cheesesteak, Czech, Salad, Wine, Austrian, German, Beer, Peruvian, Hungarian',
        annual_dol: 112.11,
        description:
            'This Central European eatery in the Bohemian National Hall serves traditional fare & pilsner beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7709,
        store_longitude: -73.962,
        total_orders: 28.21,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Pizza, Sushi, Salad, Mexican, Italian, Coffee, Thai, Hotel, Organic, Asian',
        annual_dol: 115.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.9916,
        total_orders: 2118.85,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Steak, Garlic Bread, Wine, Bbq, Beer, Margarita, Asian, American, Perfect, Friendly',
        annual_dol: 2179.03,
        description:
            'Easygoing stop, located inside Penn Station, serving a large menu of American bar fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6641,
        store_longitude: -73.9841,
        total_orders: 1142.44,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Tequila, Steak, Salad, Pizza, Nachos, Beer, Dessert, Enchiladas, Paloma',
        annual_dol: 1475.78,
        description: '"Authentic Mexican cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7049,
        store_longitude: -73.9343,
        total_orders: 145.52,
        neighborhood: 'East Williamsburg',
        keywords:
            'Wine, Beer, American, Eclectic, Perfect, Intimate, Fantastic, High-End, Amazing, Wonderful',
        annual_dol: 594.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7252,
        store_longitude: -73.9925,
        total_orders: 3328.95,
        neighborhood: 'NoHo',
        keywords:
            'Whiskey, Lobster, Spacious, American, Perfect, Smooth, Fresh, Innovative, Classic, Hip',
        annual_dol: 13211.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7284,
        store_longitude: -74.0001,
        total_orders: 3657.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Salad, Gin, Crisp, Classy, Vibrant, Fresh, Colorful, Great, Casual',
        annual_dol: 3734.68,
        description:
            'Barnlike multimedia concert venue, bar & art gallery that showcases avant-garde acts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8004,
        store_longitude: -73.9549,
        total_orders: 636.57,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Wine, Pizza, Hotel, French, Premium, African, Welcoming, Perfect, Succulent, Great',
        annual_dol: 664.17,
        description: '"Cozy Queer Lounge Experience in the Heart of Harlem! Welcoming to All!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9965,
        total_orders: 1294.01,
        neighborhood: 'Nolita',
        keywords: 'Salad, Sushi, Tasty, Wine, Delicious, Thai, Chinese, Gin, Hotel, Asian',
        annual_dol: 5289.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -74.0001,
        total_orders: 2799.87,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Mexican, Tequila, Burger, Beer, Margarita, Gluten Free, Salad, Wine, Enchiladas, Upscale',
        annual_dol: 3535.12,
        description:
            'Vibrant cantina serving modern Mexican cuisine, made-to-order guacamole & tequila cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9851,
        total_orders: 3088.07,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Upscale, Italian, Elegant, Dessert, Japanese, Rooftop, High-End, Perfect, Outstanding, Modern',
        annual_dol: 3870.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7571,
        store_longitude: -73.984,
        total_orders: 1402.36,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Lobster, Salad, Wine, Roast Beef, Delicious, Elegant, Caesar Salad, Ribeye Steak',
        annual_dol: 1440.15,
        description:
            'Casual weekday counter serve for hefty burgers, pizza, sandwiches & hot dogs, plus breakfast eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9812,
        total_orders: 2120.46,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Flavorful, Salad, Gluten Free, Delicious, Wine, Warm, Organic, Sustainable, Greek, Mediterranean',
        annual_dol: 2165.45,
        description:
            'Grilled whole fish stars on a classic Greek menu at this bright, airy bistro with a long wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9862,
        total_orders: 118.79,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Garlic Bread, Burger, Lobster, Steak, Chicken Pot Pie, Pizza, Shrimp Cocktail, Salad, Beer, Margarita',
        annual_dol: 157.26,
        description:
            'Revered, 3-floor saloon with rooftop seating, private booths, a pub grub menu & a range of beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7003,
        store_longitude: -73.9875,
        total_orders: 1245.34,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Beer, Burger, Wine, Southern, Spacious, Russian, Playful, American, Rooftop, Casual',
        annual_dol: 1448.67,
        description:
            'American meals, craft beers & cocktails in a playful interior with graffitied walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.9969,
        total_orders: 917,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Pizza, Salad, Italian, Coffee, Wine, Japanese, Spanish, Bbq, Ramen',
        annual_dol: 983.13,
        description:
            'Fancy ground-floor food court housing revered vendors, plus a mix of counter & communal seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.991,
        total_orders: 37.5,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Beer, Ale, Brazilian, American, Latin, Traditional, Classic, Caipirinha',
        annual_dol: 153.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9863,
        total_orders: 37.16,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Vegan, Mexican, Pizza, Beer, Mimosa, Margarita, Japanese, Champagne, Italian',
        annual_dol: 151.88,
        description: '"A new modern restaurant with Latin twist"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6785,
        store_longitude: -73.9835,
        total_orders: 1265.8,
        neighborhood: 'Gowanus',
        keywords: 'Casual, Local, Diverse',
        annual_dol: 1320.68,
        description:
            'Performance venue in a former warehouse with diverse programming, from jazz & hip-hop to art & film.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7424,
        store_longitude: -74.0041,
        total_orders: 5896.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Lobster, Lobster Roll, Shrimp Cocktail, Gluten Free, Hotel, Sashimi, Classic, Approachable, Local',
        annual_dol: 7704.64,
        description:
            'Wagyu & dry-aged steaks plus seafood & cocktails prepped in a glamorous, stylishly appointed locale.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7398,
        store_longitude: -73.9982,
        total_orders: 322.21,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Gin, Eclectic, Casual, Israeli, Lively, Local, Great, Creative',
        annual_dol: 1317.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7703,
        store_longitude: -73.8934,
        total_orders: 37.05,
        neighborhood: 'Astoria Heights/East Elmhurst/Flushing',
        keywords:
            'Pizza, Lobster, Italian, Shrimp Cocktail, Salad, Delicious, Wine, Coffee, Awesome, Fresh',
        annual_dol: 64.72,
        description:
            '"Best Friends Antonio Roselli & Antonio Ordas blend Italy and Spain into a culinary delight that is wonderfully authentic and yet unique!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7134,
        store_longitude: -73.9515,
        total_orders: 354.51,
        neighborhood: 'Williamsburg',
        keywords:
            'Hamburger, Vegetarian, Pizza, Sushi, Dessert, Japanese, Ramen, American, Homemade, Asian',
        annual_dol: 1449.12,
        description:
            'Japanese restaurant specializing in udon dishes, served in a simple, wood-accented space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7101,
        store_longitude: -73.9635,
        total_orders: 1328.26,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Mexican, Wine, Gourmet, Trendy, Spanish, Thai, American, Eclectic, Asian, Intimate',
        annual_dol: 1356.45,
        description:
            'Cool haunt offering live music most nights, plus gourmet bar fare, creative drinks & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6216,
        store_longitude: -73.7258,
        total_orders: 256.99,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Italian, Coffee, Artisanal, American, Upscale, Premium, Casual, Local',
        annual_dol: 256.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7161,
        store_longitude: -73.9625,
        total_orders: 1615.09,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Salad, Gourmet, Beer, Dessert, Korean, Global, Vintage, Recommended',
        annual_dol: 1725.15,
        description: "Movies screened with global fare & craft cocktails served at patrons' seats.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7469,
        store_longitude: -73.9845,
        total_orders: 606.89,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Korean, Spicy, Wine, Bbq, Premium, Perfect, Traditional, Authentic, Western, Great',
        annual_dol: 752.3,
        description:
            'Late-keeping restaurant offering traditional Korean specialties & booze in smart surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7021,
        store_longitude: -73.8048,
        total_orders: 41.45,
        neighborhood: 'Jamaica',
        keywords: 'Salad, Casual, Premium, Latin, Fresh, Fabulous, American',
        annual_dol: 169.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7561,
        store_longitude: -73.9244,
        total_orders: 17760.73,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Mexican, Tequila, Tacos, Cuban, Gourmet, American, Latin, Delicious, Innovative, Spacious',
        annual_dol: 22808.17,
        description:
            'Spacious cantina dishing up gourmet tacos, ceviche & other Mexican plates, plus tequilas & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7416,
        store_longitude: -73.9809,
        total_orders: 1322.87,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Tasty, Cool, American, Traditional, Casual, Excellent, Rooftop',
        annual_dol: 5478.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -73.9816,
        total_orders: 919.24,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Sushi, Salad, Tasty, Wine, Delicious, Japanese, Warm, Impeccable, Asian, Polished',
        annual_dol: 3757.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7646,
        store_longitude: -73.9755,
        total_orders: 23804.51,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Italian, Wine, Rustic, American, Modern, Caesar Salad, Premium',
        annual_dol: 23828.31,
        description:
            'Steakhouse in a hip, industrial space known for its premium chops & housemade ice cream.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9902,
        total_orders: 3536.55,
        neighborhood: 'Lower East Side',
        keywords: 'Mexican, Beer, Margarita, Wine, Salad, Gin, Latin, Eclectic, Intimate, Friendly',
        annual_dol: 9663.47,
        description:
            'Creative Mexican cantina with an art gallery upstairs & a performance space in the basement.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7151,
        store_longitude: -73.9919,
        total_orders: 595.62,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Hotel, Legendary, American, Iconic, Elegant, Italian, Tasty, Impeccable, Asian, Fancy',
        annual_dol: 2434.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9932,
        total_orders: 4167.92,
        neighborhood: 'Bowery',
        keywords:
            'Wine, Moroccan, Luxurious, Spicy, Delicious, Hotel, Inviting, Impeccable, Sweet, Intimate',
        annual_dol: 5147.43,
        description:
            'Moroccan-themed duplex lounge offering hookahs, pillow-filled banquettes & occasional belly dancing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9888,
        total_orders: 444.52,
        neighborhood: 'Lower East Side',
        keywords: 'Pizza, Salad, Gluten Free, Japanese, Beer, Asian, Casual, Cool, Fusion, Great',
        annual_dol: 989.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6947,
        store_longitude: -73.849,
        total_orders: 51.67,
        neighborhood: 'Jamaica/Woodhaven',
        keywords: 'Hurricane, Spacious, American, Perfect, Modern, Friendly, Diverse',
        annual_dol: 211.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7358,
        store_longitude: -74.0005,
        total_orders: 2519.71,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Scotch, Irish, Creamy, Southern, Lively, Fiery, Great, Casual, Chill',
        annual_dol: 2742.35,
        description:
            'Weekends are lively at this neighborhood tavern offering Irish-accented pub food alongside the beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7359,
        store_longitude: -73.9939,
        total_orders: 466.31,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Luxurious, Hotel, Magical, Dessert, Fancy, Perfect, Urban, Great, Fine',
        annual_dol: 486.52,
        description:
            '"Manhattan Penthouse is located in Union Square. Palladian windows are located throughout the venue offering panoramic skyline views of New York City. The loft-like event space can accommodate up to 180 guests."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7374,
        store_longitude: -73.9966,
        total_orders: 43.55,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tequila, Beer, Rich, Hotel, Delicious, Gin, Spanish, Irish, Awesome, Mediterranean',
        annual_dol: 178,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8658,
        store_longitude: -73.9273,
        total_orders: 8.42,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Burger, Pizza, Salad, American, Premium, Casual, Authentic, Excellent, Attentive',
        annual_dol: 34.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -73.9881,
        total_orders: 934.38,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Steak, Burger, Bourbon, Beer, Wine, French Toast, Nachos, French, Hotel, Irish',
        annual_dol: 976.8,
        description:
            'Dark-wood sports bar featuring projection screens & 40+ TVs, plus a menu of wings, nachos & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -73.9452,
        total_orders: 465.75,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Margarita, Beer, Mexican, Nachos, French, Relaxed, Fun, Welcoming, Casual',
        annual_dol: 475.63,
        description:
            '"We are a neighborhood bar, welcoming all individuals and celebrations. In loving memory of our dear friend and mermaid Diane."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7105,
        store_longitude: -73.9635,
        total_orders: 2391.87,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Burger, Salad, Italian, Delicious, Stylish, Crisp, French, Ramen, American, Caesar Salad',
        annual_dol: 2606.77,
        description:
            'Stylish, modern bar & restaurant offering handcrafted cocktails, bar bites & global plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6719,
        store_longitude: -73.9573,
        total_orders: 22.79,
        neighborhood: 'Crown Heights',
        keywords: 'Beer, Wine, Salad, Korean, Spicy, Asian, Awesome, Aesthetic, Quirky, Fusion',
        annual_dol: 93.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.9808,
        total_orders: 2609.16,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Vegan, Cheesesteak, Bbq, Beer, Wine, Margarita, Nachos, Legendary, Healthy',
        annual_dol: 3370.45,
        description:
            'Relaxed joint for Southern-style BBQ, including pulled pork & dry-rub ribs, plus sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -73.9777,
        total_orders: 1690.51,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Wine, Salad, Beer, Trendy, Mediterranean, Romantic, Meatball, Classy, Friendly, Fun',
        annual_dol: 1802.89,
        description:
            'Creative cocktails, craft brews & Mediterranean plates served in an intimate, pared-down locale.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8278,
        store_longitude: -73.9246,
        total_orders: 472.57,
        neighborhood: 'Concourse/West Bronx',
        keywords:
            'Beer, Sushi, Hamburger, Margarita, Wine, Chinese, Local, Modern, American, Friendly',
        annual_dol: 526.96,
        description:
            'Bustling alehouse featuring draft microbrews, cocktails & pub grub in a compact, modern interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8069,
        store_longitude: -73.9534,
        total_orders: 314.89,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Mongolian, Stylish, Mimosa, Organic, Awesome, Great, Global, Bloody Mary, Mouth-Watering, Casual',
        annual_dol: 1102.86,
        description:
            '"Global Sports Bar serving you mouthwatering dishes in an impeccably designed space by the one and only James Beard Award winning chef, Serena Bass."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7561,
        store_longitude: -73.8815,
        total_orders: 41.86,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords: 'Steak, Cheesesteak, Tasty, Latin, Casual',
        annual_dol: 211.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.802,
        store_longitude: -73.9569,
        total_orders: 122.23,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Vegetarian, Organic, French, Modern, Homemade, Dutch, Casual, Great, Amazing',
        annual_dol: 505.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8493,
        store_longitude: -73.6457,
        total_orders: 128.51,
        neighborhood: '',
        keywords:
            'Pizza, Butter Chicken, Vegetarian, Salad, Flavorful, Chicken Tikka Masala, Spicy, Delicious, Bourbon, Bbq',
        annual_dol: 525.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.987,
        total_orders: 1328.03,
        neighborhood: 'Two Bridges',
        keywords:
            'Wine, Whiskey, Beer, Trendy, Cozy, Jagermeister, Delicious, Crisp, Friendly, Sweet',
        annual_dol: 1486.22,
        description:
            'Shotgun space with a crisp bar & big red booths in back for cocktails & fancy snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8045,
        store_longitude: -73.9473,
        total_orders: 1071.87,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Wine, Stylish, Steak, Delicious, Warm, Beer, Creamy, French, Friendly, Radiant',
        annual_dol: 1094.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9791,
        total_orders: 641.82,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vodka, Champagne, Russian, Rich, Vegetarian, Gluten Free, Elegant, Magical, Glamorous, Romantic',
        annual_dol: 1686.94,
        description:
            'Continental classics like borscht, caviar & vodka served in a flashy, opulent setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9842,
        total_orders: 822.63,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Vodka, Russian, Burger, Spacious, Indian, Innovative, Perfect, Creative',
        annual_dol: 1689.19,
        description:
            'The Neil Simon Theatre, originally the Alvin Theatre, is a Broadway theater at 250 West 52nd Street in the Theater District of Midtown Manhattan in New York City. Opened in 1927, the theater was designed by Herbert J. Krapp and was built for Alex A. Aarons and Vinton Freedley.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8204,
        store_longitude: -73.9591,
        total_orders: 1023.96,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Mexican, Tacos, Beer, Margarita, Chili Con Carne, Spicy, Delicious, Perfect, Sweet, Authentic',
        annual_dol: 2963.08,
        description:
            'Laid-back restaurant & bar with a terrace offering traditional Mexican cuisine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9882,
        total_orders: 1678.84,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Italian, Cozy, Karaoke, Sleek, Perfect, Casual, Rum',
        annual_dol: 1751.63,
        description:
            'Sleek, snug cocktail spot offering live acts, karaoke & drag shows to a theater-focused crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.777,
        store_longitude: -73.8954,
        total_orders: 1286.47,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords: 'Gourmet, British, Exceptional',
        annual_dol: 5258.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.9914,
        total_orders: 87.33,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Pizza, Vegan, Salad, Wine, Beer, Thai, Ramen, Vivid, Retro, Fun',
        annual_dol: 328.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -73.9927,
        total_orders: 52.23,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Spectacular, Innovative, Intimate, Modern, Unique',
        annual_dol: 91.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7391,
        store_longitude: -73.9929,
        total_orders: 4709.84,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Kebab, Salad, Tasty, Delicious, Greek, Rich, Organic, Mediterranean, Fun',
        annual_dol: 5105.85,
        description:
            'Greek restaurant from Long Island with traditional cuisine & pre-fixe lunch menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -74.0039,
        total_orders: 74.03,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Salad, Italian, Wine, Delicious, Beer, Ale, Warm, Old Fashioned, Quaint',
        annual_dol: 242.21,
        description:
            'Coal-fired Neapolitan pizza & Italian entrees feature at this quaint spot with sidewalk tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9832,
        total_orders: 1366.5,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Meatloaf, Gluten Free, Wine, Chicken Wings, Rustic, Fried Chicken, Healthy, Sweet, American',
        annual_dol: 1878.52,
        description:
            'Elevated Southern fare, barrel-aged cocktails & craft beer in a small rustic space with a market.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9781,
        total_orders: 1506.03,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Tequila, Spicy, Beer, Delicious, Mimosa, Gin, Korean, Cozy, Bbq, Nachos',
        annual_dol: 1537.98,
        description:
            'Rustic-chic eatery with global fare, TV sports, farm decor & weekend brunch with unlimited drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7526,
        store_longitude: -74.0015,
        total_orders: 400.81,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tomato Soup, Spanish, Delicious, American, Casual, Classic, Great, Chill',
        annual_dol: 6066.89,
        description:
            'Sprawling Spanish food market from José Andrés & Adrià brothers with restaurants & kiosks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7792,
        store_longitude: -73.9505,
        total_orders: 108.77,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vietnamese, Peruvian, Hotel, Red Curry, Sweet, Perfect, Western, Casual, Authentic, Pho',
        annual_dol: 444.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9819,
        total_orders: 1119.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Kebab, Steak, Glamorous, Rich, Wine, Warm, Mediterranean, Turkish, Refined, Memorable',
        annual_dol: 1957.74,
        description:
            'Contemporary venture highlighting Mediterranean flavors in a refined setting with a warm ambiance.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7674,
        store_longitude: -73.9958,
        total_orders: 1223.37,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Upscale, Exotic, Impressive, Great, Rooftop',
        annual_dol: 1276.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7223,
        store_longitude: -73.9874,
        total_orders: 163.97,
        neighborhood: 'Lower East Side',
        keywords:
            'Hotdog, Salad, Turkey Sandwich, Beer, Legendary, Juicy, Reuben Sandwich, Cozy, Classic, French',
        annual_dol: 251.18,
        description:
            'No-frills deli with theatrically cranky service serving mile-high sandwiches since 1888.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8636,
        store_longitude: -73.9263,
        total_orders: 385.3,
        neighborhood: 'Fort George',
        keywords:
            'Steak, Rooftop, Tasty, Dominican, Delicious, Spacious, Trendy, Gourmet, Wine, American',
        annual_dol: 472.6,
        description:
            'Trendy eatery & nightclub with a rooftop lounge bar, DJs & traditional Dominican eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6861,
        store_longitude: -73.9736,
        total_orders: 1019.51,
        neighborhood: 'Fort Greene',
        keywords:
            'Salad, Coffee, French, Delicious, Casual, Friendly, American, Local, Chic, Creative',
        annual_dol: 1109.49,
        description:
            'Inventive cocktails & French-inspired classics, plus small plates & brunch, served in a chic bistro.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7419,
        store_longitude: -74.0008,
        total_orders: 83.72,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Thai, Wine, Beer, Fried Rice, Delicious, Pad Thai, Classic, Casual, Local',
        annual_dol: 342.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7364,
        store_longitude: -73.9513,
        total_orders: 16.71,
        neighborhood: 'Greenpoint',
        keywords:
            'Korean, Wine, Beer, Organic, Delicious, Ale, Tangy, Sweet, Artisanal, Traditional',
        annual_dol: 68.31,
        description:
            '"Artisanal Korean rice alcohols made with organic rice, using traditional Korean Brewing methodologies"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7114,
        store_longitude: -73.9476,
        total_orders: 87.6,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Korean, Delicious, Asian, American, Natural, Casual, Fusion, Great, Unique',
        annual_dol: 104.65,
        description:
            '"Mad for Chicken offers a unique food experience. We believe food should be enjoyed in three ways-visual, smell, and taste. We are confident to serve food that will satisfy all three senses."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6011,
        store_longitude: -74.1628,
        total_orders: 7.92,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Vegetarian, Vegan, Salad, Nepalese, Chinese, Fancy, Indian, Awesome, Classic, Biryani',
        annual_dol: 32.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.741,
        store_longitude: -73.9818,
        total_orders: 1628.6,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Mexican, Tacos, Vegan, Nachos, Margarita, Organic, Casual, Friendly, Hip, Local',
        annual_dol: 1679.57,
        description:
            'Hip source for Mexican eats with frozen & popsicle cocktails in a funky, tiled space with a truck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9774,
        total_orders: 99.75,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Sushi, Steak, Salad, Wine, Upscale, Delicious, Spicy, Elegant, Classy, Fabulous',
        annual_dol: 248.35,
        description:
            'Upscale kosher steakhouse showcasing dry-aged beef, veal & sushi in an elegant, multifloor setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -73.9799,
        total_orders: 351.91,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Mexican, Beer, Gluten Free, Delicious, Italian, Fun, Mediterranean, Vibrant, Moroccan, Turkish',
        annual_dol: 826.47,
        description:
            'Fashionable restaurant with a menu of Mediterranean favorites, plus cocktails & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.97,
        total_orders: 447.48,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Italian, Champagne, Gluten Free, Upscale, Organic, Hotel, Quaint, Homemade, Modern',
        annual_dol: 498.24,
        description:
            "High-ticket Italian fare served in a busy setting that's popular with a European crowd.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5311,
        store_longitude: -74.2254,
        total_orders: 335.66,
        neighborhood: 'Charleston',
        keywords: 'Burger, Salad, American, Ale, Casual, Local, Classic, Lively',
        annual_dol: 421.75,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7237,
        store_longitude: -73.869,
        total_orders: 98.77,
        neighborhood: 'Middle Village/Rego Park/Flushing',
        keywords: 'Burger, Tasty, Delicious, Beer, Spacious, Cozy, Bbq, Irish, Friendly, Fantastic',
        annual_dol: 107.48,
        description:
            'Hearty American & global pub grub in an old-fashioned setting with sports on TV & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6427,
        store_longitude: -73.9787,
        total_orders: 34.93,
        neighborhood: 'Kensington',
        keywords: 'Steak, Burger, Bourbon, Wine, Coffee, Karaoke, Irish, Fresh, Casual, Great',
        annual_dol: 51.76,
        description:
            'Neighborhood hangout featuring pub grub & a pool table in old-school, unpretentious surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7388,
        store_longitude: -73.9885,
        total_orders: 266.39,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Lobster, Salad, Chicken Parmigiana, Shrimp Cocktail, Vegan, Italian, Wine, Upscale, Caesar Salad, Modern',
        annual_dol: 277.94,
        description:
            'High-end, elegant mainstay with white interiors & a terrace, focusing on steaks & Italian cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.9914,
        total_orders: 177.5,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Pizza, French, Premium, Relaxed, American, Ramen, Awesome, Outstanding, Cool, Casual',
        annual_dol: 177.5,
        description:
            'Tiny, tough-to-reserve eatery via David Chang offering multicourse, Asian-accented American meals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.8328,
        total_orders: 3567.41,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Pizza, Chinese, Casual, Local',
        annual_dol: 15453.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.8285,
        total_orders: 347.64,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Hotel, Rooftop, Wine, Trendy, Sophisticated, Remarkable, Sustainable, Perfect, Signature, Unique',
        annual_dol: 355.02,
        description:
            "There are basic rooms, as well as sophisticated rooms offering flat-screen TVs, Wi-Fi access, coffeemakers and rainfall showers. Some rooms have free-standing tubs or private patios. Suites add sitting areas.The trendy lounge/bar has a rooftop patio with fire pits and Manhattan views. There's also a free shuttle to an affiliated spa.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -74.0102,
        total_orders: 1754.91,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Mexican, Delicious, Classy, Exotic, Authentic, Awesome, Casual, Fine',
        annual_dol: 1858.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6799,
        store_longitude: -73.9429,
        total_orders: 953.21,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Beer, Ale, Whiskey, Bourbon, American, Hotel, Cozy, Traditional, Intimate, Western',
        annual_dol: 973.43,
        description:
            'Cozy, intimate bar offering a range of tap beers & small bites, plus chess, darts & sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7803,
        store_longitude: -73.9526,
        total_orders: 738.82,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Sushi, Lobster, Salad, Spicy, Japanese, Sashimi, Friendly, Classy, Casual, Clean',
        annual_dol: 813.39,
        description:
            'An extensive variety of sushi & rolls plus drinks from a full bar are served in a clublike setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9879,
        total_orders: 998.55,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Beer, Iconic, Classic, Southern, Excellent, Contemporary, Creative, Local',
        annual_dol: 4416.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7779,
        store_longitude: -73.9484,
        total_orders: 699.39,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Irish, Warm, Friendly, Cool, Casual, Impressive, Great',
        annual_dol: 797.78,
        description:
            'Neighborhood Irish pub open since 1957, with billiards, darts & a jukebox in a laid-back ambiance.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7576,
        store_longitude: -73.9844,
        total_orders: 1469.3,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Steak, Shrimp Cocktail, Vegetarian, Salad, Gluten Free, Beer, French, Irish, Spicy',
        annual_dol: 5158.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7382,
        store_longitude: -73.9833,
        total_orders: 10.35,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Italian, Coffee, Organic, Delicious, French, Modern, American, Prosecco, Greek',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6942,
        store_longitude: -73.961,
        total_orders: 427.58,
        neighborhood: 'Clinton Hill',
        keywords: 'Salad, Beer, Donut, Warm, Friendly, Homemade, Seasonal, Vibrant, Casual, Great',
        annual_dol: 1747.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6686,
        store_longitude: -73.9532,
        total_orders: 365.49,
        neighborhood: 'Crown Heights',
        keywords: 'Pizza, Beer, Wine, Warm, Inviting, Friendly, Phenomenal, Casual, Great, Local',
        annual_dol: 1283.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7284,
        store_longitude: -73.9993,
        total_orders: 5886.34,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Scotch, Beer, Burger, Bourbon, Coffee, Cozy, Delicious, American, Perfect, Authentic',
        annual_dol: 6141.55,
        description:
            'Both local & nationally known blues acts perform at this laid-back second-floor music club.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7225,
        store_longitude: -73.9953,
        total_orders: 102.54,
        neighborhood: 'Nolita',
        keywords:
            'Wine, Tasty, Italian, Vegetarian, Avocado Toast, Cozy, Beer, Premium, Yummy, Friendly',
        annual_dol: 564.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9606,
        total_orders: 10.81,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Margarita, Gluten Free, Martini, Iranian, Cosmopolitan, Lebanese, French, Homemade, Greek',
        annual_dol: 44.2,
        description:
            'Laid-back Persian eatery offering kebabs & grilled meat plates, plus salads & rice dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7489,
        store_longitude: -73.9408,
        total_orders: 4920.56,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Lobster, Lobster Roll, Wine, Shrimp Cocktail, Beer, Whiskey, Tasty, Salad, Gourmet, Warm',
        annual_dol: 5471.01,
        description:
            'Brick- & wood-lined space offering cafe bites, charcuterie & cocktails along with live bands & DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7035,
        store_longitude: -73.8021,
        total_orders: 1946.46,
        neighborhood: 'Jamaica',
        keywords: 'American, Hotel, Premium, New Zealand, Fun, Great',
        annual_dol: 7956.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.864,
        store_longitude: -73.8648,
        total_orders: 195.5,
        neighborhood: 'Allerton',
        keywords:
            'Lobster, Steak, Cheesesteak, Cajun, Vegan, Wine, Delicious, Fried Rice, Creamy, Trendy',
        annual_dol: 222.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.8335,
        total_orders: 12381.83,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Hotel, Champagne, Upscale, Tasty, Romantic, Rooftop, Cajun, Fun, Great, Chic',
        annual_dol: 13256.06,
        description:
            'Chic Hyatt Place rooftop venue & terrace offering craft cocktails, Taiwanese small plates & views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8731,
        store_longitude: -73.8893,
        total_orders: 7.71,
        neighborhood: 'Jerome Park/West Bronx',
        keywords:
            'Burger, Tacos, Pizza, Salad, Shrimp Cocktail, Dominican, Fried Chicken, French, American, Delicious',
        annual_dol: 31.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8273,
        store_longitude: -73.9495,
        total_orders: 59.52,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Burger, Steak, Coffee, Beer, Wine, Savory, Italian, Dessert, Delicious, Trendy',
        annual_dol: 243.29,
        description:
            'Informal bar & restaurant offering all-day dining, plus cocktails & weekly live jazz.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5197,
        store_longitude: -74.2351,
        total_orders: 291.22,
        neighborhood: 'Tottenville',
        keywords:
            'Pizza, Meatball, Italian, Gourmet, Salad, Homemade, Sweet, Fresh, Casual, Traditional',
        annual_dol: 303.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9911,
        total_orders: 2781.06,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vodka, Garlic Bread, Wine, Tasty, Upscale, Burger, Beer, Margarita, Spicy, Nachos',
        annual_dol: 3003.06,
        description:
            'College-type sports bar with TVs to watch the game, beers on tap & extensive pub food options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9849,
        total_orders: 1843.62,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Tasty, Beer, Delicious, Salad, Belgian, Fun, Friendly, Awesome, Lively',
        annual_dol: 1882.74,
        description:
            'Cafe for Belgian specialties including oysters, mussels & burgers, plus drinks, in a lively setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.511,
        store_longitude: -74.2413,
        total_orders: 713.15,
        neighborhood: 'Tottenville',
        keywords:
            'Italian, Meatball, Wine, Elegant, Quaint, Yummy, Friendly, Authentic, Outstanding, Casual',
        annual_dol: 728.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -74.0039,
        total_orders: 16688.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Scottish, Spectacular',
        annual_dol: 17080.31,
        description:
            'An interactive film-noir Macbeth experience by a UK production company in a 5-story 1930s hotel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7503,
        store_longitude: -73.9866,
        total_orders: 399.28,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Stylish, Korean, Modern, Comfortable, Cajun, Hotel, Friendly, French, French Onion Soup, Great',
        annual_dol: 513.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7796,
        store_longitude: -73.9775,
        total_orders: 1246.99,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Tequila, Beer, Wine, Scotch, Bourbon, Trendy, Fun, Classy, American, Classic',
        annual_dol: 1337.07,
        description:
            'Not-so-divey bar where board games, free candy & a fish tank come with the beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6117,
        store_longitude: -74.0098,
        total_orders: 2057.88,
        neighborhood: 'Bath Beach/Bensonhurst',
        keywords: 'Steak, Burger, Beer, Crisp, Juicy, American, Fresh, Creole, Great, Casual',
        annual_dol: 2239.08,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7765,
        store_longitude: -73.9793,
        total_orders: 878.15,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Italian, Meatball, Roast Beef, Gourmet, Beer, French, Cozy, American, Fun, Casual',
        annual_dol: 916.23,
        description:
            'Chicken parm & other Italian-American classics go gourmet at this hip, diner-inspired space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7775,
        store_longitude: -73.9836,
        total_orders: 459.28,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Steak, Italian, Salad, Wine, Bruschetta, Hotel, Caesar Salad, American, Local, Casual',
        annual_dol: 1764.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9989,
        total_orders: 4746.84,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Burger, Hamburger, Beer, Delicious, Upscale, French, American, Classy, Retro, Vintage',
        annual_dol: 5062.39,
        description:
            'Simple pub grub, beer & cocktails are served in this classic old SoHo pub, dating back to 1847.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6684,
        store_longitude: -73.9532,
        total_orders: 532.71,
        neighborhood: 'Crown Heights',
        keywords:
            'Steak, Lobster, Pizza, Salad, Cajun, Ale, Phenomenal, Creole, Classic, Impressive',
        annual_dol: 1302.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.728,
        store_longitude: -74.0009,
        total_orders: 32.69,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Coffee, Upscale, Delicious, Cozy, Avocado Toast, French Toast, Australian, Healthy, French, Bright',
        annual_dol: 34.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6394,
        store_longitude: -73.6424,
        total_orders: 46.2,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Delicious, Homemade, Asian, Comfortable, Fresh, Thai, Authentic, Classic',
        annual_dol: 188.83,
        description:
            '"Fresh And Authentic Italian. Enjoy Great Food At Home! We offer a variety of convenient take-out options pickup at the restaurant, curbside pickup or Deliver it to your Home or Business so you can enjoy our delicious pizza and Italian dishes in the comfort of your own home! Give us a call or place your order online for lunch or dinner. Call or Click Today ."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.676,
        store_longitude: -73.9991,
        total_orders: 1329.87,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Mexican, Tequila, Nachos, Pizza, Margarita, Salad, Tex-Mex, Enchiladas, Fajitas, Cointreau',
        annual_dol: 1553.77,
        description:
            'Multiple house specialties, fajita choices & margaritas amid traditional Mexican-inspired decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8625,
        store_longitude: -73.8955,
        total_orders: 89.97,
        neighborhood: 'Fordham Manor',
        keywords:
            'Pizza, Italian, Spanish, American, Latin, Local, Perfect, Classic, Casual, Great',
        annual_dol: 367.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7317,
        store_longitude: -73.9899,
        total_orders: 2522.56,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Coffee, Trendy, Hotel, Funky, Modern, Mediterranean, Playful, Perfect, Unique',
        annual_dol: 2945.71,
        description:
            'Empire State Building.Cozy, modern rooms provide flat-screen TVs, Netflix and safes, in addition to free Wi-Fi. Some rooms offer day beds; family rooms add bunk beds and sleep up to 4 guests.Amenities include a restaurant serving Mediterranean cuisine, a gym, and a cafe/bar with a terrace. Pets are welcome (surcharge). Valet parking is available (fee).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9936,
        total_orders: 4606.47,
        neighborhood: 'Bowery',
        keywords:
            'Hotel, Coffee, Trendy, Stylish, Vibrant, Sensational, Great, Friendly, Cool, Hip',
        annual_dol: 4766.09,
        description:
            "Building and 5 miles from the landmark One World Trade Center.Featuring wall-to-wall windows with city views, the snug, minimalist-chic rooms offer flat-screen TVs and free Wi-Fi, as well as minifridges and rainfall showers.Amenities include a trendy eatery, and a 24-hour bar that serves coffee and cocktails. There's also an airy, stylish lobby lounge filled with artwork and books.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7305,
        store_longitude: -73.9861,
        total_orders: 354,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Pizza, Italian, Beer, Wine, Spicy, Authentic, Classic, Casual, Fresh, Michelada',
        annual_dol: 369.34,
        description:
            'Casual chain restaurant specializing in wood-fired, Neapolitan pizza, plus classic Italian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6529,
        store_longitude: -73.9763,
        total_orders: 78.57,
        neighborhood: 'Windsor Terrace',
        keywords:
            'Rich, Delicious, Wine, Warm, Italian, Vegetarian, Cozy, Mediterranean, Healthy, Innovative',
        annual_dol: 321.18,
        description:
            'Italian-inflected cuisine & craft cocktails are showcased at this intimate, sophisticated spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7913,
        store_longitude: -73.9689,
        total_orders: 95.23,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Roast Beef, Beer, Bbq, American, Tasty, French, Casual, Sparkling',
        annual_dol: 176.51,
        description:
            'Kosher kitchen doling out burgers, light bites & boozy drinks in an informal environment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.9726,
        total_orders: 1175.48,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Italian, Wine, Caesar Salad, American, Classy, Classic, Healthy',
        annual_dol: 1398.5,
        description:
            'Steaks & Italian basics are served by an old-school crew at this circa-1932 eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7514,
        store_longitude: -73.9723,
        total_orders: 137.92,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Georgian, Wine, Legendary, Classic, Turkish, Delicious, Contemporary, Modern, Authentic, Warm',
        annual_dol: 150.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.761,
        store_longitude: -73.9729,
        total_orders: 1011.37,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Karaoke, Legendary, Perfect, Homemade, Fresh, Natural, Impressive, Comfortable',
        annual_dol: 1273.39,
        description:
            "The Friars Club is a private club in New York City. Famous for its risqué roasts, the club's membership is composed mostly of comedians and other celebrities. Founded in 1904, it is located at 57 East 55th Street, between Park Avenue and Madison Avenue, in the historic Martin Erdmann House.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -74.0035,
        total_orders: 241.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Chocolate Cake, Mexican, Dessert, Hotel, French, American, Eggs Benedict, Latin, Homemade, Asian',
        annual_dol: 591.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6312,
        store_longitude: -73.9473,
        total_orders: 893.48,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Salad, Wine, Beer, Caesar Salad, Friendly, Casual, American, Classic, Local',
        annual_dol: 1038.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7707,
        store_longitude: -73.9818,
        total_orders: 3821.65,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Champagne, Wine, Vintage, Modern, Sweet, Intimate, Cultural',
        annual_dol: 5643.91,
        description:
            'The David H. Koch Theater is a theater for ballet, modern and other forms of dance, part of the Lincoln Center, at the intersection of Columbus Avenue and 63rd Street in the Lincoln Square neighborhood of Manhattan in New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9884,
        total_orders: 4687.59,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Pizza, Beer, Cajun, Salad, Gourmet, Whiskey, Tasty, Martini, Wine',
        annual_dol: 4787.06,
        description:
            'Gastropub serving craft beer on tap, gourmet bar bites amid wide-screen TVs & a lounge with sofas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7414,
        store_longitude: -73.9917,
        total_orders: 26059.48,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Steak, Salad, Wine, Upscale, Rich, Korean, Spicy, Trendy, Savory, Juicy',
        annual_dol: 29349.1,
        description:
            'Upscale Korean steakhouse featuring tables with smokeless grills, plus an extensive wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6778,
        store_longitude: -73.9499,
        total_orders: 1319.22,
        neighborhood: 'Crown Heights',
        keywords:
            'Tasty, Tacos, Burger, Delicious, Pizza, Upscale, Creamy, Karaoke, Sweet, Bourbon',
        annual_dol: 1319.22,
        description:
            'Relaxed hangout featuring pub food, weekend brunch & live music nights amid quirky, steampunk decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7034,
        store_longitude: -73.9917,
        total_orders: 818.9,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Sushi, Pizza, Italian, Rum, American, Eclectic, Global, Perfect, Artisanal, Fresh',
        annual_dol: 2874.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8354,
        store_longitude: -73.6501,
        total_orders: 303.87,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Gluten Free, Italian, Delicious, Casual, Homemade, American, Friendly, Modern',
        annual_dol: 398.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9606,
        total_orders: 228.71,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Hotel, American, Wonderful, Traditional, Signature',
        annual_dol: 934.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6994,
        store_longitude: -73.9318,
        total_orders: 5.24,
        neighborhood: 'Bushwick',
        keywords:
            'German, Vegan, American, Margarita, Malaysian, Mediterranean, Local, Casual, Classic',
        annual_dol: 21.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.9417,
        total_orders: 421.96,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Italian, Meatball, Beer, Wine, Homemade, Artisanal, American, Casual, Local',
        annual_dol: 440.25,
        description:
            'Neapolitan pizzas & pastas are served at this Italian spot with exposed-brick walls & chic tilework.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8522,
        store_longitude: -73.789,
        total_orders: 112.44,
        neighborhood: 'City Island',
        keywords: 'Lobster, Steak, Sushi, Salad, Japanese, Wine, Spicy, Sashimi, Trendy, Modern',
        annual_dol: 786.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7333,
        store_longitude: -73.9909,
        total_orders: 3779.29,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Sushi, Upscale, Japanese, Intimate, Hip, Casual, Fantastic, Cool, Great, Omakase',
        annual_dol: 3916.9,
        description:
            'Compact, omakase-only Japanese choice for upscale sushi or more elaborate kaiseki meals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -73.8676,
        total_orders: 496.14,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Beer, Steak, South American, Gluten Free, Wine, Salad, Spicy, Upscale, Warm, Brazilian',
        annual_dol: 506.67,
        description:
            'Upscale Brazilian chain for all-you-can-eat meat carved tableside plus an extensive salad bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8056,
        store_longitude: -73.9653,
        total_orders: 1628.78,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Sushi, Wine, Coffee, Japanese, Italian, Stylish, Delicious, Asian, Hotel, Modern',
        annual_dol: 1716.77,
        description:
            '"Trendy Japanese place offering modern dishes & sushi in a lively atmosphere. The roots of SAPPS began where SHI Owner, Shih Lee used to eat as a teenager in the East Village. A no frills, authentic Japanese restaurant serving izakaya style dishes before the popular explosion of Japanese food on St. Marks Place. Fast forward to 2017, the vision starts with a determination to create a similar experience in LIC, but with a modern NYC vibe."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5354,
        store_longitude: -74.2398,
        total_orders: 29.85,
        neighborhood: 'Charleston',
        keywords: '',
        annual_dol: 43.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5837,
        store_longitude: -73.9502,
        total_orders: 723.28,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Cheesesteak, Beer, Coffee, French, Philly Cheesesteak, Wine',
        annual_dol: 795.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.7702,
        total_orders: 225.49,
        neighborhood: 'Bayside',
        keywords: 'Cajun, Bourbon, Ample, American, Cool, Chic, Great, Casual, Local, Chill',
        annual_dol: 251.07,
        description:
            'Chill neighborhood watering hole featuring regular DJs & a large back patio with a fire pit.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7675,
        store_longitude: -73.8277,
        total_orders: 246.3,
        neighborhood: 'Flushing',
        keywords: 'Complex',
        annual_dol: 928,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8874,
        store_longitude: -73.9048,
        total_orders: 221.33,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Burger, Tasty, Beer, Wine, Nachos, Irish, Warm, Homemade, Friendly, Bruschetta',
        annual_dol: 264.39,
        description:
            'Music, theater, comedy & poetry performances happen regularly at this local Irish pub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6333,
        store_longitude: -74.0269,
        total_orders: 683.87,
        neighborhood: 'Bay Ridge',
        keywords: 'Pizza, Hotdog, Beer, Wine, Awesome, Great, Fresh, Local, Casual, Chill',
        annual_dol: 720.42,
        description:
            'Low-key neighborhood joint pouring cocktails, wine & beer, with TVs & special events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.9932,
        total_orders: 520.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Vegetarian, Tequila, Mexican, Beer, Wine, Margarita, Rustic, Smooth, Friendly',
        annual_dol: 837.96,
        description:
            'An imaginative drinks list pairs with modern Mexican small plates in a chic, wood-and-brick space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.741,
        store_longitude: -73.9908,
        total_orders: 956.13,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Lobster, Lobster Roll, Vegetarian, Steak, Salad, Tasty, Gluten Free, Delicious, Japanese, Spicy',
        annual_dol: 976.42,
        description:
            'Seafood eatery with beachfront feel that has both a lobster & raw bar, plus wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9871,
        total_orders: 1922.48,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Vegetarian, Cheesesteak, Pizza, Cajun, Vodka, Dessert, Bourbon, Gin',
        annual_dol: 6749.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7248,
        store_longitude: -73.9926,
        total_orders: 1053.54,
        neighborhood: 'NoHo',
        keywords:
            'Vegetarian, Champagne, Vodka, Gluten Free, Beer, Tasty, Organic, Dessert, Upscale, Elegant',
        annual_dol: 1161.25,
        description:
            'Upscale American fare & craft cocktails presented in opulent environs with live music & burlesque.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7438,
        store_longitude: -73.9585,
        total_orders: 517.06,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Wine, Upscale, Beer, Crisp, American, Sustainable, Polished, French, Thai, Contemporary',
        annual_dol: 744.65,
        description:
            'An eclectic menu highlighting local purveyors is served in a sprawling, upscale setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -73.8946,
        total_orders: 117.32,
        neighborhood: 'Jackson Heights',
        keywords:
            'Burger, Salad, Bbq, Ale, Friendly, French, Sweet, Quesadillas, Outstanding, Casual',
        annual_dol: 147.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7267,
        store_longitude: -73.9999,
        total_orders: 3765.97,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Vodka, Wine, Beer, Coffee, Tasty, Delicious, Warm, Contemporary, American, Fusion',
        annual_dol: 4223.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7468,
        store_longitude: -73.8902,
        total_orders: 147.26,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Beer, Colombian, South American, American, Fun, Friendly, Great, Casual',
        annual_dol: 163.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7064,
        store_longitude: -74.0077,
        total_orders: 10377.25,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Dessert, Italian, American, Delicious, Spacious, Seasonal, Sidecar, Modern, Sleek',
        annual_dol: 11603.09,
        description:
            'Polished, new American restaurant with global influences inside spacious room with high ceilings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7792,
        store_longitude: -73.9567,
        total_orders: 742.76,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Salad, Dessert, Hotel, Fabulous, Classic, Fine',
        annual_dol: 990.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7809,
        store_longitude: -73.9766,
        total_orders: 310.05,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Lobster, Burger, Wine, Dessert, French, Beer, Hotel, Elegant, Casual, Fine',
        annual_dol: 310.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.734,
        store_longitude: -74.007,
        total_orders: 82.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Urban, Zen, Amazing',
        annual_dol: 336.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -74.0094,
        total_orders: 139.29,
        neighborhood: 'Hudson Square',
        keywords: 'Pizza, Sushi, Mexican, Beer, Wine, Italian, Spanish, Tasty, Latin, American',
        annual_dol: 454.76,
        description:
            'Tapas bar with traditional Spanish small plates, sangria & patio served to a Latin rock soundtrack.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8337,
        store_longitude: -73.6257,
        total_orders: 219.61,
        neighborhood: '',
        keywords: 'Italian, Pizza, Salad, Wine, Cozy, Casual, Authentic',
        annual_dol: 244.71,
        description:
            'Old-fashioned trattoria serving Italian classics in a cozy room with tin ceilings, plus an oak bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6652,
        store_longitude: -73.9894,
        total_orders: 7120.35,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Beer, Classic, Local, Casual, Great, Fantastic',
        annual_dol: 8037.9,
        description:
            'Buzzing, late-night gay bar with a dance floor & patio offering cabaret shows & classic drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7152,
        store_longitude: -74.0156,
        total_orders: 8062.04,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Hotel, Wine, Cosmopolitan, Mediterranean, Rooftop, American, Urban, Iconic, Spectacular, Contemporary',
        annual_dol: 8500.26,
        description:
            'The modern 1- and 2-bedroom suites include espresso machines, 2 flat-screen TVs and Wi-Fi (surcharge), plus curated artwork and plush spa-like bathrooms. Some rooms include river views. Upgrades suites add cosmopolitan decor, sweeping city views, dining tables and/or iMac computers.Amenities include a 24-hour fitness center, a barbershop, and a seasonal rooftop bar with a private green roof, plus a polished Mediterranean restaurant and wine bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6924,
        store_longitude: -73.9275,
        total_orders: 255.33,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Hotdog, Beer, Fun, Amazing, Perfect, Cool, Great, Creative, Casual, Chill',
        annual_dol: 272.11,
        description:
            'Hot dogs & cocktails are offered in this informal bar with arcade games & events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7038,
        store_longitude: -74.0081,
        total_orders: 8.95,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Spectacular, Perfect, Premium, Amazing, Great, Latin, Chic',
        annual_dol: 36.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -74.0043,
        total_orders: 1268.64,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vegan, Vegetarian, Beer, Bourbon, Perfect, Rum, Sleek, Refined, Casual, Great',
        annual_dol: 2126.33,
        description:
            'Breezy cantina pairing craft cocktails & beer with refined bar bites, burgers & vegan options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6576,
        store_longitude: -73.6695,
        total_orders: 72.27,
        neighborhood: '',
        keywords:
            'Salad, Chicken Caesar Salad, Dessert, Wine, Creamy, French Onion Soup, Irish, French, Swiss, Awesome',
        annual_dol: 131.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7893,
        store_longitude: -73.974,
        total_orders: 190.25,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Dessert, Wine, Mediterranean, Homemade, Turkish, American, Spectacular, Local, Fresh',
        annual_dol: 227.27,
        description:
            "This eatery's menu spans the Mediterranean, offering both meze samplers & brick-oven pizzas.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6445,
        store_longitude: -73.9201,
        total_orders: 5.32,
        neighborhood: 'East Flatbush',
        keywords: 'Ale, Fun, Spectacular, Awesome, Great, Caribbean, Perfect, Diverse',
        annual_dol: 21.73,
        description:
            '"Benthley’s Night Club 1370 Ralph avenue Brooklyn New York 11236 attracts celebrities, world Class Bands, DJ’S and partygoers from all over the glove for some of the most extravagant events the industry has to offer. The original beautiful monumental 4500 square foot destination offers an immersive party experience with extensive professional lighting, Big Screen Billboard, Several VIP Booths, Video and Laser Systems to enhance the diverse musical talent headlining the venue."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6363,
        store_longitude: -74.026,
        total_orders: 22.88,
        neighborhood: 'Bay Ridge',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Wine, Burritos, French, Casual, Comfortable, Local',
        annual_dol: 46.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6606,
        store_longitude: -73.9804,
        total_orders: 1811.9,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'Clean, Comfortable, Cool, Innovative, Great, Retro, Local, Recommended, Negroni',
        annual_dol: 1932.35,
        description:
            'Upbeat cinema with waiters serving food & drinks during the movie, plus a bar & retro video games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7686,
        store_longitude: -73.9105,
        total_orders: 90.12,
        neighborhood: 'Astoria',
        keywords: 'Burger, Spicy, Premium, American, Yummy, Hotel, Fun, Amazing, Caribbean, Great',
        annual_dol: 167.42,
        description: '"Eat • Drink • Smoke • Vibe"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6517,
        store_longitude: -73.9589,
        total_orders: 449.14,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Pizza, Wine, Rum, Caribbean, Jamaican, Trendy, American, Classy, Romantic, Latin',
        annual_dol: 580.95,
        description:
            'Warm, wood-&-brick-clad eatery & lounge offering updated Caribbean fare alongside rum drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.945,
        total_orders: 3637.21,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Margarita, Karaoke, Cozy, Opulent, Retro, Sleek, Awesome, Creative, Cool',
        annual_dol: 3714.38,
        description:
            'Creative cocktails & full bar offerings served in a sleek space with a Vegas throwback feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.9555,
        total_orders: 434.68,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vodka, Wine, Italian, Martini, Legendary, Meatball, Warm, Romantic, Homemade, Classy',
        annual_dol: 453.53,
        description:
            'Well-established traditional Italian eatery & piano bar hosted by a local restaurateur.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7093,
        store_longitude: -73.9235,
        total_orders: 560.83,
        neighborhood: 'East Williamsburg',
        keywords:
            'Chinese, Vegan, Salad, Korean, Trendy, Asian, American, Innovative, Sweet, Kung Pao',
        annual_dol: 688.13,
        description:
            'New York outpost of the San Francisco eatery serving fiery, innovative takes on Sichuan cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7026,
        store_longitude: -73.9287,
        total_orders: 1156.24,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Dessert, Bbq, Organic, American, Classic, Sweet, Fun, Casual, Great',
        annual_dol: 1206.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7322,
        store_longitude: -74.0008,
        total_orders: 8615.76,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Sushi, Peruvian, Japanese, Martini, Upscale, Delicious, Warm, Spanish, Latin, American',
        annual_dol: 10397.33,
        description:
            'Modern Japanese & Peruvian flavors presented in a warm setting with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -74.0026,
        total_orders: 133.92,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Salad, Gluten Free, Tasty, Pizza, Delicious, Organic, Warm, Comfortable, Healthy',
        annual_dol: 345.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6355,
        store_longitude: -74.0098,
        total_orders: 62.77,
        neighborhood: 'Sunset Park',
        keywords: 'Beer, Wine, Irish, Scandinavian, Hotel, Norwegian, Casual, Local, Exceptional',
        annual_dol: 73.17,
        description:
            'Opened in 1932, this local pub filled with memorabilia has a jukebox, a dart league & sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7056,
        store_longitude: -73.6562,
        total_orders: 116.68,
        neighborhood: '',
        keywords:
            'Vegan, Vegetarian, Sushi, Pizza, Organic, Healthy, Delicious, Friendly, Casual, Fresh',
        annual_dol: 544.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8219,
        store_longitude: -73.9386,
        total_orders: 928.5,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Local, Casual, Great',
        annual_dol: 3031.34,
        description: '"Follow us on IG: @legendslounge_nyc"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6679,
        store_longitude: -73.795,
        total_orders: 3490.29,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Hotel, Comfortable, Fun, Friendly, Perfect, Great, American, Chic, Caribbean, Creative',
        annual_dol: 3490.29,
        description:
            "Chic rooms offer custom-designed beds, minifridges, 37-inch flat-screen TVs and WiFi (fee). Executive rooms provide free WiFi and access to the Executive Lounge's free breakfast and evening appetizers. Suites add separate living areas and pull-out sofas.Amenities include a bistro-style restaurant serving American cuisine, a sports bar, a fitness center and an indoor pool. There's also a business center and over 6,300 sq ft of meeting space, plus a free airport shuttle.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9194,
        total_orders: 1939.14,
        neighborhood: 'Astoria/LIC',
        keywords: 'Wine, Spanish, Latin, Friendly, Fun, American, Casual, Authentic, Fusion, Local',
        annual_dol: 2689.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5733,
        store_longitude: -73.98,
        total_orders: 264.6,
        neighborhood: 'Coney Island',
        keywords:
            'Hamburger, Pizza, Beer, Coffee, Tasty, Dessert, Upscale, American, Legendary, Sweet',
        annual_dol: 341.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6749,
        store_longitude: -73.9997,
        total_orders: 26.72,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Steak, Salad, French Toast, Argentinian, French, Cozy, Avocado Toast, Trendy, Brazilian',
        annual_dol: 74.84,
        description:
            'American & Brazilian dishes, plus brunch & creative cocktails, in a trendy spot with a heated patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6814,
        store_longitude: -73.9939,
        total_orders: 288.44,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Lobster Roll, Lobster, Hamburger, Salad, Shrimp Cocktail, Wine, Grilled Salmon, Chinese, Delicious',
        annual_dol: 470.85,
        description:
            'New England-style joint with a menu of seafood classics in a sleek space with nautical touches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7349,
        store_longitude: -73.9858,
        total_orders: 2202.97,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Pizza, Italian, Salad, Wine, Meatball, Beer, Margarita, Rustic, Artisanal, Premium',
        annual_dol: 2249.72,
        description:
            'Easygoing neighborhood Italian restaurant with a separate wine bar & a wood-fired pizza oven.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6184,
        store_longitude: -74.0695,
        total_orders: 402.72,
        neighborhood: 'Rosebank',
        keywords: 'Burger, Vegetarian, Tacos, Mexican, Pizza, Salad, Beer, Vodka, Nachos, Mimosa',
        annual_dol: 458.66,
        description:
            'Petite eatery with a colorful decor & back patio serving traditional Mexican fare & Sunday brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7989,
        store_longitude: -73.967,
        total_orders: 61.16,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Flavorful, Wine, Italian, Spicy, Homemade, Healthy, Classy, Fresh, Mediterranean, Casual',
        annual_dol: 63.81,
        description:
            'Casual, Italian-Sardinian cafe with outdoor seating, serving housemade pastas, breads & seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5831,
        store_longitude: -73.9557,
        total_orders: 2694.74,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Steak, Burger, Salad, Sushi, Cheesesteak, Tuna Steak, Roast Beef, Italian, Beer, Upscale',
        annual_dol: 3245.15,
        description:
            'Hip venue with outdoor seating serving local & global bites such as burgers, pizza, sushi & crêpes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7934,
        store_longitude: -73.6438,
        total_orders: 269.06,
        neighborhood: '',
        keywords:
            'Burger, Tacos, Pizza, Sushi, Upscale, Luxurious, Elegant, Eclectic, Exquisite, American',
        annual_dol: 337.91,
        description:
            'Eclectic American plates & a buzzing taproom in a historic 1832 landmark with a swanky interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6764,
        store_longitude: -73.8439,
        total_orders: 26.71,
        neighborhood: 'Ozone Park/Jamaica',
        keywords: 'Casual, Amazing',
        annual_dol: 109.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.761,
        store_longitude: -73.9749,
        total_orders: 4518.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Beer, Wine, American, Eclectic, Iconic, Global, Fun, Fantastic, Traditional, Perfect',
        annual_dol: 4917.62,
        description:
            'Large venue with ping pong tables offering an eclectic menu of casual fare plus happy hour deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7571,
        store_longitude: -73.9684,
        total_orders: 226.27,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tequila, Mexican, Tacos, Beer, Flavorful, Vegetarian, Salad, Romantic, Fun, Artisanal',
        annual_dol: 482.98,
        description:
            '"Casual fun mexican bar and restaurant with colorful atmosphere! Street tacos as well as other Mexican favorites. Outdoor and indoor seating."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9751,
        total_orders: 1375.6,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Lobster, Salad, Pizza, Sushi, Italian, Tasty, Wine, Upscale, Elegant',
        annual_dol: 1872.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6306,
        store_longitude: -74.0283,
        total_orders: 1693.84,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Cheesecake, Pizza, Salad, Shrimp Cocktail, Coffee, Dessert, Chinese, Savory, Mediterranean',
        annual_dol: 1929.17,
        description:
            'Local Greek chain with a late-night scene doling out traditional sweets & savory dishes since 1977.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6211,
        store_longitude: -74.0292,
        total_orders: 593.98,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Sushi, Salad, Steak, Dessert, Beer, Tasty, Martini, Cosmopolitan, Japanese, Fried Rice',
        annual_dol: 751.55,
        description:
            'Entertaining hibachi chefs cook Japanese steaks tableside in a kid-friendly space with a sushi bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -74.0122,
        total_orders: 465.98,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Lobster, Legendary, Amazing, Welcoming, Excellent, Great, Friendly, Fresh, Sophisticated',
        annual_dol: 1904.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7975,
        store_longitude: -73.7372,
        total_orders: 115.81,
        neighborhood: '',
        keywords: 'Wine, Fabulous, Exquisite, Incredible, Great, Recommended, Local',
        annual_dol: 473.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6868,
        store_longitude: -73.9909,
        total_orders: 668.49,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Pizza, Italian, Delicious, Homemade, Hotel, Spacious, Fresh, Fantastic, Casual, Wonderful',
        annual_dol: 697.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.683,
        store_longitude: -73.9437,
        total_orders: 357.15,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Salad, Flavorful, Wine, Southern Comfort, Spicy, Southern, French, Casual, Asian, Caesar Salad',
        annual_dol: 1459.91,
        description:
            'Casual kitchen offering small & large plates of Southern comfort food, plus mason jar cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.9871,
        total_orders: 1268.79,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Karaoke, Beer, Korean, Sleek, Fusion, Friendly, Perfect, Great, Casual, Signature',
        annual_dol: 1301.29,
        description:
            'Sleek billiards bar with bites & a large selection of beer & cocktails made with Korean vodka.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7448,
        store_longitude: -73.9879,
        total_orders: 2348.39,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Karaoke, Korean, Japanese, Luxurious, Delicious, Fancy, Fun, Traditional, Hip, Casual',
        annual_dol: 5918.28,
        description:
            'Korean & Japanese nibbles & fancy cocktails in a cool, brick-lined bar with karaoke rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.9871,
        total_orders: 771.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Italian, Beer, Bbq, Spacious, Sleek, Fun, Hip, Friendly, Modern',
        annual_dol: 840.47,
        description:
            'Swanky bar with karaoke rooms, cocktails & an Asian-inspired menu of snacks & entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -74.002,
        total_orders: 9617.12,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, American, Global, Classic, Outstanding, Iconic, Contemporary, Amazing, Fresh, Great',
        annual_dol: 32909.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9878,
        total_orders: 159.81,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Mexican, Hotel, Western, Warm, Delicious, Local, Casual, Great',
        annual_dol: 714.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6597,
        store_longitude: -73.9604,
        total_orders: 2164.55,
        neighborhood: 'Prospect Lefferts Gardens/Lefferts Manor Historic District',
        keywords:
            'Burger, Zombie, Cajun, Beer, Whiskey, Mediterranean, Rustic, Bourbon, Club Sandwich, Blt Sandwich',
        annual_dol: 2230.57,
        description:
            'Rustic-chic bar offering elevated burgers & sandwiches, plus whiskey flights & rotating craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -74.0001,
        total_orders: 1106.95,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Spicy, Warm, American, Intimate, Casual, Classic, Great, Chill, Signature',
        annual_dol: 5209.34,
        description:
            'Laid-back watering hole serving up American food & cocktails, plus stand-up comedians.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6569,
        store_longitude: -73.7955,
        total_orders: 21121.03,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Steak, Pizza, Beer, Coffee, Donut, Tasty, American, Rum, Hotel',
        annual_dol: 21263.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.543,
        store_longitude: -74.1423,
        total_orders: 37.52,
        neighborhood: 'Great Kills',
        keywords:
            'Shrimp Cocktail, Italian, Cozy, Contemporary, Romantic, Local, Classic, Authentic, Exceptional, Great',
        annual_dol: 49.72,
        description:
            'This warmly decorated, white-tablecloth Italian eatery features upmarket entrees & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5495,
        store_longitude: -74.1506,
        total_orders: 219.62,
        neighborhood: 'Great Kills',
        keywords:
            'Elegant, Clean, Perfect, Relaxed, Friendly, Exquisite, Gourmet, Outstanding, Great, Modern',
        annual_dol: 616.2,
        description:
            '"The Grand Plaza is proud to announce that our Main Ballroom and Reception Hall redesign is now complete. Grand Plaza has been hosting and planning Staten Island\'s special occasions for over 30 years. We pride ourselves on our history of providing our guests with unmatched luxury service and an enchanting atmosphere for any memorable special occasion. The redesign provides an all-new modern décor focused on providing the next level in elevated elegance. This ensures our guests\' events will continue to provide the extravagance and splendor (exclusivity) that sets The Grand Plaza apart as the prominent Staten Island venue for decades to come."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.629,
        store_longitude: -74.0053,
        total_orders: 11.19,
        neighborhood: 'Dyker Heights',
        keywords:
            'Dessert, Shrimp Cocktail, Wine, Trendy, Rich, Romantic, Homemade, Classy, Intimate, Fresh',
        annual_dol: 45.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7278,
        store_longitude: -73.9909,
        total_orders: 2572.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tasty, Wine, Fruity, Upscale, Delicious, Warm, Cozy, Hotel, Beer, Burger',
        annual_dol: 2640.44,
        description:
            "Understated, airy rooms come with flat-screen TVs and desks, as well as free Wi-Fi. Upgraded rooms and suites add floor-to-ceiling windows, city views, separate sitting areas and/or bathrooms with heated floors. Room service is available 24/7.There's a chic restaurant with an open kitchen, as well as a garden terrace, and a clubby cafe/bar with a sidewalk patio. A penthouse event area includes a wraparound terrace.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -73.8722,
        total_orders: 2.34,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Peruvian, Colombian, Pizza, Tasty, Latin, American, Casual, Great',
        annual_dol: 9.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7515,
        store_longitude: -73.9708,
        total_orders: 840.4,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Hamburger, Karaoke, Swiss, Irish, Russian, Homemade, Traditional, Reuben, Warm',
        annual_dol: 1478.35,
        description:
            'Neighborhood bar with a late-night kitchen where locals head to croon karaoke or watch sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9796,
        total_orders: 996.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Salad, Japanese, Spicy, Sashimi, Yummy, Sweet, New Zealand, Premium, Casual',
        annual_dol: 3935,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 1563.5,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Coffee, Gluten Free, Upscale, Cozy, Modern, Exquisite, Wonderful, Casual, Fine',
        annual_dol: 6391.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7148,
        store_longitude: -74.0064,
        total_orders: 960.79,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Beer, Wine, Steak, Shrimp Cocktail, Salad, Gin, Irish, Friendly, Outstanding, Classic',
        annual_dol: 981.17,
        description:
            '"A new tavern in Tribeca that prides itself on serving quality food and drinks in a friendly atmosphere."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9684,
        total_orders: 1695.93,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tequila, Burger, Delicious, Fun, Lively, French Toast, American, French, Perfect, Great',
        annual_dol: 1756.78,
        description:
            '"Approachable cocktails, chic decor, weekend bottomless brunch, late night DJ’s, and delicious AF food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9716,
        total_orders: 719.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hotel, Wine, Italian, Elegant, Delicious, Coffee, Spectacular, Rooftop, Premium, Eclectic',
        annual_dol: 927.81,
        description:
            "Elegant, traditional rooms offer flat-screen TVs and Wi-Fi (fee), plus microwaves, minifridges and coffeemakers. Studios add kitchens, and the 1- and 2-bedroom suites feature separate living rooms with sofas. Some also have balconies. In-room massages are available (surcharge), as is 24-hour room service.Amenities include a chic Italian restaurant, a steakhouse, and a rooftop bar with a lounge. There's also a fitness center, plus free access to a local health club with an indoor pool.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5583,
        store_longitude: -73.901,
        total_orders: 134.95,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords: 'Burger, Tasty, Hotel, American, Casual, Traditional, Great',
        annual_dol: 167.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7095,
        store_longitude: -74.011,
        total_orders: 106.53,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Premium',
        annual_dol: 435.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8659,
        store_longitude: -73.8378,
        total_orders: 653.49,
        neighborhood: 'Pelham Gardens/East Bronx',
        keywords: 'Steak, Salad, Mexican, Italian, Bbq, Cuban, Spanish, Dominican, American, Latin',
        annual_dol: 1031.75,
        description:
            'Unpretentious neighborhood place serving Latin American & Italian dishes in an old-school space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6621,
        store_longitude: -73.9535,
        total_orders: 915.37,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords: 'Wine, Beer, Spicy, Rum, Hamburger, Urban, Classy, Caribbean, American, French',
        annual_dol: 956.44,
        description:
            '"Neighborhood-friendly wine and cocktail lounge serving American Comfort dishes in a comfortable yet sophisticated atmosphere."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7763,
        store_longitude: -73.9794,
        total_orders: 1351.88,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Salad, Wine, Italian, Quaint, Martini, Polished, Hotel, Homey, Friendly, Natural',
        annual_dol: 1380.56,
        description:
            "Neighborhood trattoria with traditional Italian fare that's popular before Lincoln Center shows.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.9937,
        total_orders: 2594.97,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Steak, Lobster, Beer, Wine, Delicious, Warm, Irish, Homey, Friendly',
        annual_dol: 2650.03,
        description:
            'Chill haunt with a homey feel & train memorabilia offering a raw bar, plus burgers & seafood plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8293,
        store_longitude: -73.9284,
        total_orders: 73058.58,
        neighborhood: 'Concourse/Highbridge/West Bronx',
        keywords: 'Steak, Beer, Heritage, Premium, Amazing',
        annual_dol: 165090.16,
        description:
            'Yankee Stadium is a baseball stadium located in the Bronx, New York City. It is the home field of the New York Yankees of Major League Baseball, and New York City FC of Major League Soccer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7215,
        store_longitude: -73.8485,
        total_orders: 38.99,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Sushi, Salad, Coffee, Beer, Japanese, Ale, Thai, Sashimi, Ramen, Zen',
        annual_dol: 159.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7969,
        store_longitude: -73.9381,
        total_orders: 125.13,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Lobster, Lobster Tail, Shrimp Cocktail, Dominican, Spanish, Latin, American, Caribbean, Creole',
        annual_dol: 511.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7037,
        store_longitude: -73.9266,
        total_orders: 52.02,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Beer, Wine, Dessert, Delicious, Bbq, American, Contemporary, Reuben Sandwich, Global',
        annual_dol: 219.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7911,
        store_longitude: -73.9747,
        total_orders: 0.87,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Salad, Cheesecake, Hamburger, Argentinian, Tangy, French, Spicy, Reuben Sandwich, American',
        annual_dol: 3.54,
        description:
            'This large retro-styled diner offers standard American comfort fare around the clock.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7487,
        store_longitude: -73.6848,
        total_orders: 5.09,
        neighborhood: '',
        keywords:
            'Chicken Tikka Masala, Butter Chicken, Spicy, Southern, Bbq, Homemade, Casual, Tandoori Chicken, Refined, Premium',
        annual_dol: 20.81,
        description:
            'Refined eatery serving Southern Indian dishes including hot & spicy curries, dosa & tandoori.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5756,
        store_longitude: -73.9773,
        total_orders: 578.83,
        neighborhood: 'Coney Island',
        keywords:
            'Dessert, Italian, Margarita, Mediterranean, Elegant, Tex-Mex, Casual, Modern, Polished, Seasonal',
        annual_dol: 1435.24,
        description:
            '"Experience casual dining at its very best in a luxuriously modern atmosphere with incredible views of Coney Island’s amusement park and the Atlantic Ocean. Enjoy unparalleled service and a lavish interior environment at the Teraza. Teraza provides elegant modern Mediterranean fusion cuisine using the freshest seasonal ingredients and employing classic and modern techniques. Our team brings a wealth of experience from the hospitality industry to ensure the finest dining experience possible. Newest and best new fusion Restaurants in Brooklyn Coney Island NY, Brooklyn Restaurant."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.9145,
        total_orders: 155.82,
        neighborhood: 'Astoria',
        keywords: 'Wine, Salad, Stylish, Superb, Great, Signature, Local, Unique',
        annual_dol: 225.61,
        description:
            "\"We are a Lingerie Shop and a Cocktail Bar. Here at Astoria's Secret it's a whole experience, from our Burlesque Shows to our Killer Karaokes. Let's celebrate the weekends together! Follow Us on IG, Twitter, and Tiktok @AstoriasSecret for Theme Nights, #KillerKaraoke, Burlesque Shows, and Erotic Poetry!\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6632,
        store_longitude: -73.9913,
        total_orders: 3492.29,
        neighborhood: 'South Slope/Sunset Park',
        keywords: 'Beer, Wine, Salad, Funky, Eclectic, Moscow Mule, Retro, Hip, Quirky, Friendly',
        annual_dol: 3658.2,
        description:
            'Quirky Brooklyn bar prized for divey vibes, eclectic decor & live performances in the back room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9094,
        total_orders: 60.9,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tacos, Mexican, Beer, Tasty, Margarita, Steak, Salad, Wine, Warm, Authentic',
        annual_dol: 135.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -73.9342,
        total_orders: 41.54,
        neighborhood: 'East Williamsburg',
        keywords: 'Wine, Coffee, Salad, Ale, Beer, Italian, Tasty, French, Organic, Spicy',
        annual_dol: 169.8,
        description:
            'Hip coffee bar with premium grub, craft beer & organic wine, plus live music & local art.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.9771,
        total_orders: 568.57,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Tasty, Pizza, Wine, Salad, Italian, Upscale, Delicious, Trendy, Cozy, Classy',
        annual_dol: 606.37,
        description:
            'Fresh pastas dominate a traditional Italian menu in a modern setting with a neighborhood feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.9841,
        total_orders: 1840.36,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Salad, Mexican, Wine, Irish, American, Casual, Classic, Lively, Local',
        annual_dol: 2148.68,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8191,
        store_longitude: -73.9623,
        total_orders: 350.11,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Vegan, Delicious, Friendly, Authentic, Casual, American, Lively, Traditional',
        annual_dol: 659.55,
        description:
            'Pub grub & cocktails are served at this lively open-air eatery set on a docked Vietnam-era warship.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9885,
        total_orders: 38.89,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Salad, Pizza, Beer, Japanese, Spicy, Sashimi, Yummy, Stylish, Asian',
        annual_dol: 46.46,
        description:
            'Modern sushi house featuring creative rolls, Japanese fare & cocktails in a stylish, brick interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -73.9854,
        total_orders: 105.44,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Vegetarian, Wine, Coffee, Spicy, French Toast, Bbq, Zesty, French, New-York-Style',
        annual_dol: 127.54,
        description: 'Basic American eats are served in hearty portions at this retro 24/7 diner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7183,
        store_longitude: -73.9881,
        total_orders: 160.08,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, American, Salad, Urban, Rustic, Eclectic, Caribbean, Contemporary, Asian, Sashimi',
        annual_dol: 654.37,
        description:
            '"Essex Pearl\'s mission is to showcase the beautiful cuisines of the colorful countries of Southeast Asia in a contemporary NYC setting. We invite you to explore and indulge in our eclectic seafood-centric menu. Powered by NYC\'s top seafood purveyor, Aqua Best, Executive Chef Daniel Le brings the heat to the kitchen and welcomes you to join the action via our kitchen-facing counter. Bring a friend to our open dining room, or make new ones at our lively bar. All of us at Essex Pearl are looking forward to welcoming you."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7256,
        store_longitude: -73.9997,
        total_orders: 184.48,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Beer, Pizza, Wine, Fantastic, Iconic, Cool, Hummus',
        annual_dol: 929.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6843,
        store_longitude: -73.8595,
        total_orders: 2670.1,
        neighborhood: 'Ozone Park',
        keywords: 'Pizza, Italian, Salad, Dessert, Vodka, French, Hawaiian, Bbq, Casual, Fresh',
        annual_dol: 7855.44,
        description:
            'Family-run outpost featuring generous plates of Italian fare & wood-fired pizzas, plus a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7657,
        store_longitude: -73.9838,
        total_orders: 76.92,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Burger, Mexican, Flavorful, Tasty, Vegetarian, Beer, Margarita, Dessert, Warm',
        annual_dol: 314.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7326,
        store_longitude: -73.9975,
        total_orders: 949.3,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Tasty, Trendy, Romantic, Steak, French, Classy, Premium, Fun, Serene',
        annual_dol: 970.95,
        description:
            'Serene joint with a classy vibe offering small plates & full meals with matching wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.737,
        store_longitude: -73.9985,
        total_orders: 170.7,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Italian, Wine, Warm, Spacious, Inviting, Charming, Irresistible, Premium, Intimate',
        annual_dol: 380.7,
        description:
            'Sophisticated, intimate Italian restaurant in the basement of a historical brownstone.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6801,
        store_longitude: -73.9744,
        total_orders: 72.31,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords: 'Burger, Mexican, Beer, Salad, Gourmet, Tasty, Mimosa, Spicy, Tex-Mex, Delicious',
        annual_dol: 125.19,
        description:
            'Steaks, burgers & seafood with spicy Southwestern accents served in 2-level setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7253,
        store_longitude: -73.9843,
        total_orders: 1042.17,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Karaoke, Japanese, Beer, Classic, Ale, Fun, Aesthetic, Friendly, Great, Ample',
        annual_dol: 1042.17,
        description: 'Private rooms & an extensive song list lure warblers to this karaoke bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7278,
        store_longitude: -73.9849,
        total_orders: 2529.22,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Hotdog, Burger, Vegan, Beer, French, American, Friendly, Casual, Cool, Local',
        annual_dol: 2607.61,
        description:
            'Cocktails & draft beer turned out in dark, compact quarters with an edgy vibe & pool tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6776,
        store_longitude: -73.9639,
        total_orders: 5972.72,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Wine, Gin, Tasty, Bourbon, Rum, Fun, Sweet, Hot Toddy, Cointreau',
        annual_dol: 6099.45,
        description:
            "Classic '50s-diner-style lounge with large windows featuring upscale, creative cocktails.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6824,
        store_longitude: -73.961,
        total_orders: 803.95,
        neighborhood: 'Clinton Hill',
        keywords: 'Beer, Whiskey, Wine, Vibrant, Amazing, Great, Indian, Casual, Chill, Unique',
        annual_dol: 986.42,
        description:
            'An extensive, rotating beer list & small-batch whiskey selection in a snug, low-key space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7466,
        store_longitude: -73.9821,
        total_orders: 7263.95,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Wine, Upscale, Ribeye Steak, Caesar Salad, Delicious',
        annual_dol: 7304.34,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -73.9763,
        total_orders: 36.36,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Tequila, Mexican, Warm, Spicy, Delicious, Friendly, Premium, Lively, American, Great',
        annual_dol: 148.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -73.9607,
        total_orders: 22.25,
        neighborhood: 'Williamsburg',
        keywords: 'Urban, Beer, Wine, Hip, Fancy, Quirky, Fun, Awesome, Cool',
        annual_dol: 90.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8345,
        store_longitude: -73.8291,
        total_orders: 11845.09,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords:
            'Champagne, Steak, Dessert, Margarita, Cuban, French Toast, Caribbean, Puerto Rican, French, Latin',
        annual_dol: 14115.63,
        description:
            'Restaurant & nightspot serving Puerto Rican dishes & cocktails in an airy, Caribbean-inspired space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.6838,
        total_orders: 1076.03,
        neighborhood: '',
        keywords: 'Western, Smooth, Perfect, Luxurious',
        annual_dol: 2425.89,
        description:
            '"The Stewart Manor Country Club is a beautifully renovated club and garden setting located in Western Nassau County, New York. We truly understand that everyone has different desires, needs, and budgets. Our professional event planning staff will take your ideas, tastes, likes, dislikes, colors, decorations, food choices, etc. into consideration, and give you what you want, when you want it, and how you want it. We take pride in "Doing It Your Way"!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7728,
        store_longitude: -73.873,
        total_orders: 179.64,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Burger, Spicy, Hotel',
        annual_dol: 734.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7021,
        store_longitude: -73.8556,
        total_orders: 34.8,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords: 'Tequila, Mexican, Beer, Steak, Margarita, Wine, Pizza, Salad, Fajitas, Spicy',
        annual_dol: 277.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6638,
        store_longitude: -73.9844,
        total_orders: 73.8,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Italian, Gluten Free, Beer, Martini, Delicious, Greek, Thai, Creative, Casual',
        annual_dol: 240.95,
        description:
            'Warm, snug eatery with brick-clad walls offering Italian dishes & pies with creative toppings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9668,
        total_orders: 127.53,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Italian, Meatball, Wine, Modern, Homemade, Tempting, Authentic, Classic, Perfect',
        annual_dol: 521.32,
        description:
            '"Authentic Italian ristorante where preparing dishes "a piacere" - according to your preference - is our greatest pleasure. The menu showcases our homemade and imported pastas, along with veal, fish and chicken options and, always, we offer a number of tempting daily specials. If your favorite Italian dish isn\'t on the menu or among the specials, ask for it and our chef will gladly make it. An extensive Italian wine list and full bar service featuring intriguing housemate infusions round out our gustatory attractions."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7248,
        store_longitude: -73.9478,
        total_orders: 43.78,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Hotdog, Pizza, Mexican, Beer, Spicy, Bbq, American, Spacious, Eclectic',
        annual_dol: 178.95,
        description:
            '"Jucy Lucy is a laid back gastropub featuring light bites, sandwiches, burgers, hotdogs, flatbreads and 16 draft beers and a full bar. Watch your favorite sports team or just relax and let the music take you."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.766,
        store_longitude: -73.9691,
        total_orders: 262.98,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Cosmopolitan, American, Hotel, Premium, Friendly',
        annual_dol: 268.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7795,
        store_longitude: -73.9833,
        total_orders: 0,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Kebab, Butter Chicken, Wine, Malaysian, Indian, Fusion, Diverse, Great, Casual',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.9978,
        total_orders: 191.38,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Modern, Hotel, Polish, Fine, Unique',
        annual_dol: 782.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9847,
        total_orders: 15121.35,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Gourmet, Gin, French, Juicy, Rum, Karaoke, Fun, Creative',
        annual_dol: 15580.21,
        description:
            'Bustling cocktail spot specializing in ambitious creations, plus a small menu of gourmet bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6361,
        store_longitude: -73.577,
        total_orders: 625.46,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Crab Cakes, Shrimp Cocktail, Wine, Mexican, Organic, Delicious, Yummy, Mediterranean',
        annual_dol: 714.77,
        description:
            'Quiet space with a Portugal-inspired menu & wine list, Hudson Canal views & occasional live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.678,
        store_longitude: -73.9822,
        total_orders: 1873.63,
        neighborhood: 'Park Slope',
        keywords: 'Vodka, Beer, Gin, Ale, Rum, Cozy, Hotel, Warm, Yummy, Sweet',
        annual_dol: 1958.09,
        description:
            "Intimate brick-walled haunt featuring trivia nights, happy hour deals & a rock 'n' roll jukebox.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -74.0011,
        total_orders: 130.27,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Mexican, Tacos, Beer, Burritos, Vegetarian, Salad, Wine, Hotel, Western, Casual',
        annual_dol: 593.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -74.0011,
        total_orders: 1018.27,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Salad, Gourmet, Dessert, Delicious, Hotel, Classy, Friendly, American, Perfect',
        annual_dol: 1056.31,
        description:
            'Neighborhood watering hole with aviation theme, drink specials, happy hour & pub food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 618.35,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tacos, Steak, Mexican, Wine, Rich, Hotel, Italian, Chinese, Gin, Stylish',
        annual_dol: 2018.76,
        description:
            '"Located in the basement of the Chelsea Market. CMH pays homage to the revelry and rich entertainment culture of Chelsea’s past and will showcase a diverse mix of immersive theatre and performances ranging from emerging acts to established artists and world-renowned DJ\'s that are attracted to the intimacy of the room as well as the quality sound system."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -74.0024,
        total_orders: 56.72,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, German, Wine, Trendy, Delicious, Urban, Fantastic, Amazing, Great, Local',
        annual_dol: 198.66,
        description:
            '"Milk & Hops Beer Tap & Bottle shop located in the heart of Chelsea, offers over 300 bottles selection and 10 draught lines combined with an amazing selection of small appetizes."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8272,
        store_longitude: -73.8867,
        total_orders: 75.69,
        neighborhood: 'Foxhurst',
        keywords:
            'Hamburger, Steak, Mexican, Burritos, Margarita, Spicy, Dominican, French, Delicious, American',
        annual_dol: 309.4,
        description:
            'Tacos, burritos & Mexican-inspired mains dished up in a bright locale with cocktails & basic tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5745,
        store_longitude: -74.1696,
        total_orders: 2077.96,
        neighborhood: 'New Springville/Mid Island',
        keywords: 'Steak, Burger, Cajun, Salad, Ale, Wine, Beer, Italian, Tex-Mex, French',
        annual_dol: 2168.06,
        description:
            'Casual sports-pub chain with basics from bar snacks to steaks & a large selection of tap beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6231,
        store_longitude: -73.7187,
        total_orders: 430.65,
        neighborhood: '',
        keywords:
            'Salad, Delicious, Bbq, American, Caesar Salad, Perfect, Spectacular, Authentic, Fresh, Terrific',
        annual_dol: 634.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6444,
        store_longitude: -74.0765,
        total_orders: 73.8,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Lobster Roll, Lobster, Sushi, Steak, Hamburger, Beer, Delicious, American, Fun, Ample',
        annual_dol: 301.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.9781,
        total_orders: 797.06,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Hotel, Amazing, Signature, Great, Asian',
        annual_dol: 3103.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7484,
        store_longitude: -73.9759,
        total_orders: 1530.43,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Sushi, Chocolate Cake, Salad, Beer, Bbq, Irish, Ale, Relaxed, Comfortable',
        annual_dol: 1578.01,
        description:
            'Irish pub & eatery with a recent college grad/happy-hour following for tap brews, TVs & pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9806,
        total_orders: 276.56,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Hotel, Sparkling, Relaxed, Premium, Clean, Friendly, Great',
        annual_dol: 968.62,
        description:
            'Warmly furnished rooms feature free Wi-Fi and flat-screen TVs. Upgraded rooms add views of the Empire State Building; suites offer sitting areas with pull-out sofas.Amenities include a business center and a meeting room. Pets are welcome for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7466,
        store_longitude: -73.9827,
        total_orders: 17.29,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Whiskey, Coffee, Mexican, Beer, Wine, American, Contemporary, Clean, Seasonal, Casual',
        annual_dol: 70.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.8831,
        total_orders: 635.74,
        neighborhood: 'Jackson Heights',
        keywords:
            'Salad, Colombian, Beer, Latin, American, Perfect, Mediterranean, Friendly, Great, Excellent',
        annual_dol: 692.85,
        description:
            'Showing sports on HD screens, this laid-back haunt with draft beer specializes in grilled seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7754,
        store_longitude: -73.9096,
        total_orders: 63.45,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Shrimp Cocktail, Beer, Wine, Salad, French, Gin, Mediterranean, Rum, Innovative, Fresh',
        annual_dol: 259.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7622,
        store_longitude: -73.9874,
        total_orders: 1070.4,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hamburger, Coffee, Canadian, Ale, American, Delicious, Friendly, Casual, Traditional, Classic',
        annual_dol: 1484.37,
        description:
            'Informal canine-themed restaurant/bar serving typical fare, weekend brunch & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.9893,
        total_orders: 1278.6,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Vodka, Tasty, Gin, Delicious, Rum, Hotel, Fun, Awesome, Great',
        annual_dol: 1624.93,
        description:
            '"Located in the heart of downtown Manhattan, The Orchard Room is the Lower East Side’s most exciting new bar & restaurant specializing in tasty Dim Sum bites featuring a handcrafted bar menu. House guests may enjoy their drinks nestled in one of our signature Alligator Green banquets, or from our heated outdoor bungalow–yes, our in-person DJ sets will be audible outside too. Please don\'t feed the monkeys."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6343,
        store_longitude: -73.5819,
        total_orders: 263.84,
        neighborhood: '',
        keywords: 'Caribbean, Casual',
        annual_dol: 1078.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.712,
        store_longitude: -74.0152,
        total_orders: 6,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Premium, Organic, Rooftop, Perfect, Great, Complex, Cultural',
        annual_dol: 24.52,
        description:
            '"Convene at Brookfield Place is the newest place to host large-scale events in Downtown NYC. The premium, full-service venue includes nearly 73,000 square feet of flexible meeting space and can host up to 615 people with a total of 1,000 people maximum, making it perfect for conferences, galas, trade shows, exhibits, product launches, fashion shows, and large summits. With a variety of purpose-built rooms for small, medium, and large programs, Convene is fully outfitted with built-in technology, furniture, on-site catering, and a professional hospitality team to take care of all your event needs so you can focus less on logistics and more on your guests."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7387,
        store_longitude: -73.9937,
        total_orders: 4599.8,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Steak, Sushi, Japanese, Coffee, Wine, Beer, French, Thai, Bbq, Korean',
        annual_dol: 4885.6,
        description:
            'High-end restaurant offering Japanese-style BBQ, Korean eats & cocktails in a low-lit, modern venue.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8611,
        store_longitude: -73.9204,
        total_orders: 16.92,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords: 'Dominican, Salad, Casual, Traditional',
        annual_dol: 17.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6803,
        store_longitude: -73.9677,
        total_orders: 569.7,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Friendly, Cool, Great, Excellent, Casual, Local, Unique',
        annual_dol: 928.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7099,
        store_longitude: -73.9626,
        total_orders: 9813.8,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Steak, Burger, Cheesecake, Salad, German, Chocolate Mousse, Hot Fudge Sundae, Beer, French, American',
        annual_dol: 9829.87,
        description:
            'Cash-only steak icon where old-school waiters serve aged beef in a German beer hall setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7112,
        store_longitude: -73.9501,
        total_orders: 112.15,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, French, Ale, Organic, Savory, Cozy, Delicious, Seasonal, Artisanal, Casual',
        annual_dol: 458.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7428,
        store_longitude: -73.9831,
        total_orders: 5552.17,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Sushi, Martini, Japanese, Hotel, Irish, Savory, Sashimi, American, Contemporary, Latin',
        annual_dol: 7299.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7123,
        store_longitude: -73.9644,
        total_orders: 3398.23,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Delicious, Fun, Sweet, Yummy, Eclectic, Friendly, Great, Cool, Incredible, Casual',
        annual_dol: 5547.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7653,
        store_longitude: -73.9314,
        total_orders: 566.31,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords:
            'Burger, Lobster, Hotdog, Cheesecake, Vegetarian, Pizza, Vegan, Salad, Mexican, Gourmet',
        annual_dol: 795.05,
        description:
            'Large, classic Greek diner with a massive menu, chrome accents & 24/7 opening hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7513,
        store_longitude: -73.9351,
        total_orders: 25.66,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Hotel, Friendly, Great, Recommended',
        annual_dol: 104.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7292,
        store_longitude: -73.9922,
        total_orders: 818.59,
        neighborhood: 'NoHo',
        keywords:
            'Salad, Vodka, Tasty, Chilean, Dessert, Martini, Vietnamese, Stylish, Spicy, French',
        annual_dol: 1067.2,
        description:
            'French-Vietnamese fare is served amid exotic decor of palm fronds & low light at this 80s-era spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.793,
        store_longitude: -73.9714,
        total_orders: 107.48,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Peruvian, Salad, Vegetarian, Wine, Gluten Free, Chinese, Ale, Dominican, Latin, Homemade',
        annual_dol: 114.62,
        description:
            'Casual Peruvian restaurant specializing in rotisserie chicken with homemade sauce.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8548,
        store_longitude: -73.9372,
        total_orders: 45.32,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Mexican, Steak, Margarita, Enchiladas, Fajitas, Homemade, Delicious, Friendly, Casual, Premium',
        annual_dol: 110.85,
        description:
            'Casual Mexican eatery serving classic dishes & specialty margaritas in a lively, colorful setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.7716,
        total_orders: 89.09,
        neighborhood: 'Bayside',
        keywords:
            'Cajun, Steak, Wine, Champagne, Bourbon, South American, Cuban, Italian, Spanish, Gin',
        annual_dol: 101.47,
        description:
            'Elegant hot spot showcasing fine wines floor to ceiling, with a menu of creative tapas & entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9773,
        total_orders: 40.9,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Shrimp Cocktail, Chicken Caesar Salad, Italian, Coffee, Golfclub, German, Delicious, Hotel',
        annual_dol: 179.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8776,
        store_longitude: -73.9058,
        total_orders: 30.72,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Burger, Sushi, Lobster, Lobster Tail, Steak, Chicken Parmigiana, Pizza, Gluten Free, Beer, Italian',
        annual_dol: 125.59,
        description: '"Comida latina más una cocina asiática ofreciendo sushi 100% frescos."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7981,
        store_longitude: -73.6655,
        total_orders: 1609.37,
        neighborhood: '',
        keywords:
            'Kebab, Salad, Lobster, Mexican, Gourmet, Italian, Wine, Mediterranean, Greek, Upscale',
        annual_dol: 1679.15,
        description:
            'Upscale Greek-Mediterranean restaurant with a large seafood-focused menu & spacious, chic digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6219,
        store_longitude: -74.0315,
        total_orders: 1550.85,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Burger, Lobster, Pizza, Tequila, Salad, Shrimp Cocktail, Vodka, Italian, Irish, American',
        annual_dol: 1700.96,
        description:
            'This roomy bistro has seats indoors & out, featuring an eclectic American menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7145,
        store_longitude: -74.0074,
        total_orders: 53.09,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Peruvian, Sushi, Tequila, Vodka, Mexican, Gin, Pizza, Italian, Rum, Fusion',
        annual_dol: 217,
        description:
            '"Some of the Best Sensory Experiences Happen on a Peruvian Plate. Taste the sentiments and nostalgia from a fusion of continents. Peruvian gastronomy, its influence & inclusiveness has opened the door for the marriages of many cultures & thus have ingrained themselves into the Peruvian gastronomic experience. As long as the world continues to evolve, so will the kitchen. The end of possibilities is nowhere in sight."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6685,
        store_longitude: -73.7082,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Thai, Sushi, Burger, Nutritious, Delicious, Steak, Romantic, Authentic, Fresh, Casual',
        annual_dol: 56.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9897,
        total_orders: 1597.39,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Hamburger, Beer, Cozy, French, American, Modern, Friendly, Casual, Classic',
        annual_dol: 1705.13,
        description:
            'Old-school stop catering to the pre-theater crowd with elevated American fare & classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6933,
        store_longitude: -73.9987,
        total_orders: 2919.19,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Lasagna, Chocolate Mousse, Salad, Pizza, Whiskey Sour, Italian, Coffee, Whiskey, Bourbon, Cozy',
        annual_dol: 2989.11,
        description:
            'Quaint corner joint specializing in Sardinian fare, plus cocktails & weekend brunch. Cash only.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6867,
        store_longitude: -73.9548,
        total_orders: 2546.88,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Coffee, Spicy, Fun, Nachos, Lively, Vintage, Classic, Great, Casual',
        annual_dol: 2604.53,
        description:
            'Down-to-earth local pub serving beer & cocktails amid weathered-brick walls & throwback decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7833,
        store_longitude: -73.948,
        total_orders: 989.58,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Lobster, Vegetarian, Lobster Roll, Mexican, Beer, Wine, Delicious, American, Cozy',
        annual_dol: 1296.08,
        description:
            'Portraits of standout women line the walls of this low-key spot for informal bites & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9889,
        total_orders: 1573.76,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Tacos, Shrimp Cocktail, Beer, Mexican, Coffee, Wine, Tasty, Painkiller, Irish',
        annual_dol: 5524.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.686,
        store_longitude: -73.9911,
        total_orders: 98.98,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords: 'Burger, Cajun, Cornbread, Coffee, Salad, Beer, Bourbon, Mimosa, Italian, Bbq',
        annual_dol: 121.74,
        description:
            '"Good neighbor vibes, live music Fridays with David Gagne, live music brunch Saturday & Sunday with Roz & Gem, Happy Hour, BBQ, Comfort Food, TVs for outdoor seating, cocktails and local breweries’ craft beers 🍻"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.9934,
        total_orders: 149.56,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Mexican, Pizza, Gourmet, New-York-Style, American, Delicious, Local, Casual, Healthy, Fresh',
        annual_dol: 733.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9935,
        total_orders: 36.3,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Upscale, Wine, Trendy, Legendary, Italian, Iconic, American, Memorable, Sleek',
        annual_dol: 46.81,
        description:
            "Building and a minute's walk from the nearest subway station.Relaxed rooms feature free Wi-Fi and flat-screen TVs; some have views of the city, river and/or Empire State Building. Upgraded rooms have iPod docks. The 1-bedroom suites offer minifridges, living rooms with sofabeds, and/or furnished balconies.Amenities include 3 restaurants (1 with a bar) and a gym, plus meeting and event space. Breakfast (fee) and valet parking are available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7747,
        store_longitude: -73.9127,
        total_orders: 216.54,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Burger, Steak, Hamburger, Beer, Mimosa, American, Sweet, Bellini, Bloody Mary, Prosecco',
        annual_dol: 259.08,
        description:
            'Sports bar with 40+ TVs offering elevated pub grub, a solid beer selection & weekend breakfast.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.728,
        store_longitude: -74.0002,
        total_orders: 4693.13,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lobster, Pizza, Italian, Rich, Spicy, Salad, Meatball, Vodka, Funky, Premium',
        annual_dol: 4896.61,
        description:
            'High-end takes on red-sauce fare await at this A-lister Italian restaurant in a retro-glam space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6605,
        store_longitude: -73.9508,
        total_orders: 941.96,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Gluten Free, Coffee, Organic, French Toast, Italian, French, Sweet, Fun, Casual, American',
        annual_dol: 1142.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7789,
        store_longitude: -73.9812,
        total_orders: 119.93,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tacos, Mexican, Salad, Burritos, Margarita, Organic, American, Delicious, Casual, Friendly',
        annual_dol: 551.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.8016,
        total_orders: 1338.38,
        neighborhood: 'Flushing/Murray Hill',
        keywords:
            'Coffee, Wine, Champagne, Beer, Organic, Premium, Vintage, Hip, Excellent, Rooftop',
        annual_dol: 1396.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.978,
        total_orders: 106.33,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'American, Hotel, Retro, Casual, Personalized, Signature, Fun',
        annual_dol: 115.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7677,
        store_longitude: -73.9835,
        total_orders: 775.22,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Japanese, Sashimi, Spicy, Delicious, Fusion, Asian, Ramen, Elegant, Local',
        annual_dol: 824.99,
        description:
            'Classic Japanese cuisine including an extensive sushi menu, served in a modern setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7196,
        store_longitude: -73.9905,
        total_orders: 1745.6,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Burger, Margarita, Donut, Spicy, Delicious, Stylish, Fried Chicken, Cozy, Sweet',
        annual_dol: 1782.64,
        description:
            'Trendy, snug cocktail bar serving finger foods, chicken sandwiches & creative drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7202,
        store_longitude: -73.9881,
        total_orders: 2728.57,
        neighborhood: 'Lower East Side',
        keywords: 'Wine, Beer, Whiskey, Irish, Karaoke, Upscale, Eclectic, Fancy, American, Quaint',
        annual_dol: 2786.46,
        description:
            'Unpretentious watering hole with Irish touches doling out beer, booze & daily happy-hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.9871,
        total_orders: 1564.13,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Tacos, Mexican, Tequila, Sushi, French, Delicious, Cozy, Traditional, Authentic',
        annual_dol: 2435.43,
        description:
            'Neighborhood joint for Mexican food by a native chef-owner, plus mezcals & other agave spirits.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -73.989,
        total_orders: 7937.15,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Salad, Avocado Toast, Hotel, Gluten Free, Beer, American, Caesar Salad, French, Rooftop',
        annual_dol: 9072.17,
        description:
            'Views of Downtown frame the setting at this rooftop spot serving a New American menu & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7231,
        store_longitude: -73.9942,
        total_orders: 67.01,
        neighborhood: 'Nolita',
        keywords: 'Salad, Mexican, Cuban, Swiss, Spicy, Cozy, Casual, Wonderful, Great',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8118,
        store_longitude: -73.9429,
        total_orders: 30.65,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Lobster, Steak, Mexican, Salad, Beer, Wine, Spicy, Delicious, Spanish, Cozy',
        annual_dol: 125.28,
        description:
            'Latin bistro & wine bar also providing craft beer & weekend brunch in a relaxed, brick-lined space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7464,
        store_longitude: -73.9879,
        total_orders: 7.8,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Burger, Steak, Italian, Pizza, American, Vibrant, Lively, Innovative, Fusion, Hotel',
        annual_dol: 31.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -74.0032,
        total_orders: 590.78,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Spanish, Wine, Spicy, Stylish, Romantic, Warm, Mediterranean, Greek, American',
        annual_dol: 590.78,
        description:
            "Food & wine from Spain's Mediterranean coast served in stylish surrounds with a glass-roofed garden.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.743,
        store_longitude: -73.9999,
        total_orders: 2015.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vegan, Hamburger, Vegetarian, Salad, Gluten Free, Milkshake, Beer, Organic, Delicious',
        annual_dol: 3781.9,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6804,
        store_longitude: -73.975,
        total_orders: 436.82,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Garlic Bread, Shrimp Cocktail, Salad, Cajun, Bbq, Ramen, Upscale, Creole, Caesar Salad',
        annual_dol: 465.53,
        description: '"Cajun creole cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.994,
        total_orders: 1529,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Delicious, Trendy, Clean, Ale, Friendly, Fun, Cool, Great, Excellent',
        annual_dol: 1710.79,
        description:
            'Quirky, no-frills watering hole offering beer in cans & on tap, plus weekly trivia nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6867,
        store_longitude: -73.9744,
        total_orders: 13.25,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Italian, Trendy, Hotel, French, Classy, Contemporary, Intimate, Fun, American',
        annual_dol: 54.14,
        description:
            'Intimate spot offering set menu dinners with inventive, Italian dishes sourced from local producers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.78,
        total_orders: 4.44,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords:
            'Steak, Pizza, Hamburger, Gourmet, Salad, Beer, Wine, Coffee, Turkey Sandwich, Roast Beef',
        annual_dol: 15.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6228,
        store_longitude: -73.7252,
        total_orders: 2145.33,
        neighborhood: '',
        keywords: 'Steak, Wine, Upscale, Italian, Trendy, American, Warm, Romantic, Modern, Lively',
        annual_dol: 2357.26,
        description:
            'Sophisticated, modern spot for wood-grilled steaks & seafood & a full bar in a rustic-chic interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6118,
        store_longitude: -74.1593,
        total_orders: 3010.61,
        neighborhood: 'Bulls Head/Mid Island',
        keywords: 'Burger, Steak, Salad, Beer, Nachos, Wine, Bbq, Tex-Mex, Chicken Wings, Ale',
        annual_dol: 3074.49,
        description:
            'Family-friendly chain serving classic Tex-Mex & American fare in a Southwestern-style setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6225,
        store_longitude: -73.7251,
        total_orders: 2170.07,
        neighborhood: '',
        keywords:
            'Pizza, Burger, Italian, Beer, Wine, Savory, Delicious, American, Modern, Artisanal',
        annual_dol: 2361.59,
        description:
            'Rustic-chic locale offering artisanal pizza & pasta, plus craft beers on tap, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6718,
        store_longitude: -73.9581,
        total_orders: 83.37,
        neighborhood: 'Crown Heights',
        keywords: 'Cheesecake, Mexican, Perfect, American, Casual, Excellent, Great, Wonderful',
        annual_dol: 340.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -73.9452,
        total_orders: 155.66,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotel, Cozy, Mediterranean, Intimate, American, High-End, Seasonal, Natural, Middle Eastern, Recommended',
        annual_dol: 200.73,
        description:
            'Shareable, seasonal Mediterranean dishes are served in an intimate, plant-accented space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5866,
        store_longitude: -73.7055,
        total_orders: 174.78,
        neighborhood: '',
        keywords: 'Mexican, German, Beer, American, Fun, Authentic, Outstanding, Casual, Great',
        annual_dol: 451.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7221,
        store_longitude: -73.9832,
        total_orders: 73.42,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Vegan, Tasty, Chicken Parmigiana, Wine, Coffee, Italian, Organic, French Toast, Beer, Delicious',
        annual_dol: 133.26,
        description:
            'Casual restaurant offering Italian & American fare, along with brunch, cocktails & happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7172,
        store_longitude: -73.9429,
        total_orders: 411.4,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Dessert, Savory, Homey, American, Sweet, Fun, Perfect, Awesome, Bruschetta',
        annual_dol: 1681.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5877,
        store_longitude: -74.1971,
        total_orders: 125.54,
        neighborhood: 'Travis - Chelsea/Mid Island',
        keywords:
            'Wine, Italian, Elegant, Cozy, Romantic, Polished, Friendly, Perfect, Outstanding, Casual',
        annual_dol: 513.18,
        description:
            'White tablecloths & an old-world vibe for classic Italian veal & pasta dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9565,
        total_orders: 279.95,
        neighborhood: 'Williamsburg',
        keywords:
            'Champagne, Wine, Whiskey, Tasty, Gin, Coffee, Comfortable, Rum, Intimate, Authentic',
        annual_dol: 1144.33,
        description: '"Local Neighborhood Bar. Drink, Eat, Dance."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.9251,
        total_orders: 454.56,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Bbq, Beer, Southern, Casual',
        annual_dol: 1858.09,
        description:
            'Industrial venue with Southern flair selling smoked & grilled ribs, burgers, brisket, sides & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6591,
        store_longitude: -73.6447,
        total_orders: 297.23,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Hamburger, Lobster Bisque, Beer, Spicy, Bbq, Warm, Irish, American',
        annual_dol: 310.11,
        description:
            'Straightforward watering hole offering a range of comfort eats & bar bites in roomy surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6442,
        store_longitude: -73.7037,
        total_orders: 2075.93,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Steak, Shrimp Cocktail, Wine, Fruity, Tasty, Japanese, Chinese, Mongolian',
        annual_dol: 2170.72,
        description:
            'Chic strip-mall find with creatively plated fusion dishes from across Asia & inventive cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7937,
        store_longitude: -73.6899,
        total_orders: 132.23,
        neighborhood: '',
        keywords:
            'Burger, Salad, Pizza, Shrimp Cocktail, Italian, Wine, Spicy, Delicious, Beer, Warm',
        annual_dol: 140.72,
        description:
            'Hearty Italian fare, including seafood & handmade pasta, served in a warm, relaxed space with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6832,
        store_longitude: -74.0033,
        total_orders: 1145.48,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Vegan, Gluten Free, Delicious, Warm, Elegant, American, Exceptional, Intimate, Seasonal, Local',
        annual_dol: 4682.33,
        description:
            '"Dish Food & Events is a NYC based catering and events company specializing in extraordinary weddings, corporate and social events. Dish proudly creates delicious food paired with warm hospitality."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.9235,
        total_orders: 27.2,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Upscale, Salad, French Toast, Dessert, French, Beer, Latin, Warm, Brazilian',
        annual_dol: 37.03,
        description:
            'Modern hangout with bohemian interiors, offering drinks & dinner, plus music & dancing on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.733,
        store_longitude: -73.9898,
        total_orders: 576.1,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Steak, Burger, Salad, Pizza, Wine, French, Mediterranean, Asian, Perfect, Spectacular',
        annual_dol: 673.55,
        description:
            'Prime steaks, seafood & specialty cocktails in an offbeat, mural-decorated locale.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7939,
        store_longitude: -73.9727,
        total_orders: 265.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Flavorful, Wine, Salad, Tasty, Beer, Latin, American, Intimate, Vibrant, Artisanal',
        annual_dol: 1083.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7515,
        store_longitude: -73.9718,
        total_orders: 1038.79,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, French, Japanese, Cozy, Delicious, Elegant, Modern, New Zealand, Seasonal, Casual',
        annual_dol: 1083.82,
        description:
            'Minimalist, elegant eatery on two floors serving intricate Japanese meals, including tasting menus.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7547,
        store_longitude: -73.9735,
        total_orders: 546.26,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Elegant, Martini, Warm, Iconic, Amazing, Charming, Great, Gin, Salad',
        annual_dol: 837.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8008,
        store_longitude: -73.6486,
        total_orders: 884.66,
        neighborhood: '',
        keywords:
            'Mexican, Beer, Gluten Free, Elegant, Comfortable, Classy, Perfect, American, Italian, Casual',
        annual_dol: 1114.54,
        description: '"Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9715,
        total_orders: 1915.16,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Salad, Scotch, Canadian, Wine, Shrimp Scampi, Upscale, French',
        annual_dol: 4461.48,
        description:
            'Bi-level space with plush banquettes for dining on dry-aged steaks, chops & seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6233,
        store_longitude: -74.0313,
        total_orders: 1370.96,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Tacos, Mexican, Beer, Margarita, Burritos, Stylish, Homemade, Awesome, Coleslaw, Great',
        annual_dol: 1400.04,
        description:
            'Stylish, surf-themed cafe specializing in California-style tacos, burritos & Mexican small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8036,
        store_longitude: -73.736,
        total_orders: 272.38,
        neighborhood: '',
        keywords: 'Burger, Beer, Ale, Fun, Great, Mediterranean, Friendly, Local, Casual',
        annual_dol: 310.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.969,
        total_orders: 231.15,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Japanese, Warm, French, Fried Chicken, Perfect, Traditional, Great, Fresh, Fine',
        annual_dol: 944.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9856,
        total_orders: 22888.82,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Stylish, Hotel, Spacious, Cozy, Romantic, Vibrant, Bright, Perfect, Indulgent, Clean',
        annual_dol: 23471.29,
        description:
            "Featuring floor-to-ceiling windows, some with views of Times Square, the low-key rooms include flat-screen TVs, Wi-Fi (fee) and coffeemakers. Family rooms offer connecting bedrooms. Suites add living rooms; upgraded suites come with separate living and dining areas. Room service is available.There's a bright revolving restaurant, a casual eatery and a Starbucks cafe, as well as a lounge with a terrace. Other amenities include a gym and 120,000 sq ft of event space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6881,
        store_longitude: -73.9551,
        total_orders: 599.84,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Cuban, Beer, Caribbean, Rum, Salad, Coffee, Spanish, Swiss, American, Mojito',
        annual_dol: 816.57,
        description:
            'Vibrant spot with sidewalk seats offering Cuban-style sandwiches & mains, plus draft beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.9864,
        total_orders: 1151.31,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Legendary, Friendly',
        annual_dol: 1513.9,
        description:
            'The Richard Rodgers Theatre is a Broadway theater at 226 West 46th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1925, it was designed by Herbert J. Krapp and was constructed for Irwin Chanin.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6931,
        store_longitude: -73.9675,
        total_orders: 630.99,
        neighborhood: 'Clinton Hill',
        keywords:
            'Salad, Thai Basil Chicken, Thai, Vegetarian, Spicy, Asian, Bbq, Comfortable, Friendly, Pad Thai',
        annual_dol: 671.5,
        description:
            'This simple eatery with a contemporary feel features a menu of classic Thai entrees & appetizers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7161,
        store_longitude: -73.965,
        total_orders: 1382.38,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Mexican, Whiskey, Ale, Pizza, Tasty, American, Bbq, Delicious, Friendly',
        annual_dol: 2078.51,
        description:
            'Lively, late-night hangout offering draft microbrews, cocktails & pub grub, plus lots of sports TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9911,
        total_orders: 594.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Beer, Delicious, Warm, Karaoke, Spacious, Cozy, Irish, Rustic, American, Fantastic',
        annual_dol: 647.7,
        description:
            '2-floor tavern for comforting food & drink options in a traditional wood-walled interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7577,
        store_longitude: -73.9927,
        total_orders: 136.27,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Salad, Coffee, Red Eye, Upscale, Caesar Salad, Clam Chowder, Casual',
        annual_dol: 557.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.752,
        store_longitude: -74.0024,
        total_orders: 4667.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Mexican, Lobster, Upscale, Modern, Casual, Cozy, Delicious, Comfortable, Fresh, Local',
        annual_dol: 4998.86,
        description:
            'Warm, brick-clad stop serving elevated, modern Mexican dishes alongside cocktails & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6433,
        store_longitude: -73.7823,
        total_orders: 273.99,
        neighborhood: 'Jamaica',
        keywords: 'Delicious, Ethiopian, American, Spacious, Premium, Fresh, Exceptional, Seasonal',
        annual_dol: 1120,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -73.9913,
        total_orders: 20239.58,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Carrot Cake, Butter Chicken, Wine, Dessert, Romantic, Eclectic, Modern, Indian, Traditional, Sophisticated',
        annual_dol: 20805.47,
        description:
            'Place for creative, sophisticated Indian dishes & cocktails in a colorful, banquette-lined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7335,
        store_longitude: -73.993,
        total_orders: 278.52,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegan, Sushi, Tequila, Wine, Hamburger, Mexican, Vietnamese, Italian, Chinese, Stylish',
        annual_dol: 797.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9781,
        total_orders: 1842.78,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Salad, Nachos, Beer, Irish, Delicious, Caesar Salad, Casual, Great',
        annual_dol: 1894.31,
        description:
            'Publike eatery, convenient for Grand Central commuters, with beer on tap & an Irish staff.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9737,
        total_orders: 7822.6,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hotel, Luxurious, Elegant, Vintage, French Toast, Gin, Premium, French, Classic, American',
        annual_dol: 8167.33,
        description:
            "Refined rooms come with Wi-Fi and flat-screen TVs, plus desks and marble vanities; some have views of the Manhattan skyline. Upgraded rooms feature custom-designed furniture, and suites add fully stocked bars. Room service is offered 24/7.There's a chic restaurant and a swanky, gin-themed lounge bar. Other amenities include a 24-hour business center and 15,000 sq ft of meeting space, including 2 ballrooms.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5836,
        store_longitude: -73.9414,
        total_orders: 526.68,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Beer, Salad, Donut, Turkish, Rich, Mediterranean, Premium, Authentic, Greek',
        annual_dol: 549.52,
        description:
            'Seafood is the focus of this Turkish restaurant with cocktails & mural-rich, nautical decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7654,
        store_longitude: -73.7722,
        total_orders: 99.15,
        neighborhood: 'Bayside',
        keywords:
            'Salad, Steak, Wine, Pizza, French, Bourbon, Filet Mignon, Prosecco, Chic, Casual',
        annual_dol: 348.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8176,
        store_longitude: -73.9417,
        total_orders: 931.5,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Gourmet, Delicious, African, French, Belgian, American, Friendly, Fantastic, Great, Casual',
        annual_dol: 971.89,
        description:
            'French & West African cuisine are served alongside house cocktails in a warm, dark-wood bistro.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.708,
        store_longitude: -73.8311,
        total_orders: 663.34,
        neighborhood: 'Kew Gardens',
        keywords:
            'Italian, Ale, Cozy, Friendly, Casual, Authentic, American, Local, Chill, Excellent',
        annual_dol: 677.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9675,
        total_orders: 906.62,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Hamburger, Italian, Chicken Parmigiana, Gluten Free, Delicious, Cozy, Polished, Refined, Modern',
        annual_dol: 925.86,
        description:
            'Refined Neapolitan fare from restaurateur Lello Arpaia served in sizable, contemporary quarters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9874,
        total_orders: 966.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Rich, Tasty, Bourbon, Coffee, Delicious, Hotel, Beer, Salad, Cozy, French',
        annual_dol: 1046.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7829,
        store_longitude: -73.9512,
        total_orders: 2359.38,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Beer, Ale, Burger, Wine, Salad, American, Rustic, Club Sandwich, Seasonal, Delicious',
        annual_dol: 2409.45,
        description:
            'Craft beer, seasonal ales & wine with upmarket comfort food & weekend brunch in warm, rustic digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9955,
        total_orders: 4004.24,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Beer, Mediterranean, Hotel, American, Modern, Premium, Classy, Hip',
        annual_dol: 4280.79,
        description:
            'Bright YOTEL spot with plants & wood floors for New American cuisine from breakfast to late-night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.9854,
        total_orders: 1440.48,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Coffee, Stylish, Spacious, Modern, Friendly, Contemporary, Casual, Great',
        annual_dol: 1663.56,
        description:
            "Building.Simple rooms with modern accents offer free WiFi and 50-inch flat-screens. They also have microwaves, minifridges, and tea and coffeemaking equipment. Upgraded rooms add views of Times Square.Morning tea and coffee are complimentary. There's a casual restaurant, and a bar with square views. Other amenities consist of a fitness center and a business room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -74.0025,
        total_orders: 994.65,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Lobster, Salad, Pizza, Wine, Tasty, Italian, French Onion Soup, French, Cozy, Creamy',
        annual_dol: 1094.39,
        description:
            'Old-school French dishes in classic bistro surroundings, plus sidewalk seating in the heart of SoHo.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -73.9981,
        total_orders: 822.99,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Burger, Hamburger, Steak, Salad, Mexican, Beer, Tasty, Bbq, Spicy, Upscale',
        annual_dol: 842.16,
        description:
            'Notable burgers, upscale pub eats & wine on tap in a high-energy setting with a trendy crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9973,
        total_orders: 105.11,
        neighborhood: 'Nolita',
        keywords:
            'Wine, Pizza, Italian, Coffee, Delicious, Warm, Friendly, Vintage, Amazing, Great',
        annual_dol: 119.89,
        description:
            '"As a Sicilian inspired spot, Bar Pasquale features sfizione pizza, handmade and fresh extruded pastas, small plates inspired from our teams travel around the Ionian Sea, a wine list featuring wines of Mount Etna and elsewhere as well as a speakeasy cocktail bar and private dining room in the cellar. This space spreads guests across a dining room with burgundy red leather banquets, vintage furniture, classic red sauce joint attire with an open kitchen and an outdoor patio."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7809,
        store_longitude: -73.9802,
        total_orders: 1988.75,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Mexican, Tacos, Vegan, Beer, Salad, Dessert, Wine, Californian, Enchiladas, Delicious',
        annual_dol: 2020.55,
        description:
            'Beachy setting for coastal Californian items including tacos, veggie bowls & colorful cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9798,
        total_orders: 12649.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Scotch, Champagne, Whiskey, Elegant, Beer, Hotel, Premium, Classy, Iconic, Awesome',
        annual_dol: 13128.58,
        description:
            'Patrons puff on cigars & sip single malts at this clubby, elegant smoking lounge with a fireplace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.9808,
        total_orders: 2176.39,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Spacious, Classic, Friendly, Relaxed, Great, Excellent, Exceptional, High-End, Unique',
        annual_dol: 5598.58,
        description:
            "Plush studios and 1-bedroom suites have kitchenettes, living and dining areas, and desks, as well as marble bathrooms, flat-screen TVs and Wi-Fi.There's a lounge/bar, a fitness room and a business center. Breakfast and valet parking are available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9789,
        total_orders: 4118.67,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Salad, Delicious, Stylish, Greek, Mediterranean, Elegant, Fresh, Exceptional, Filet Mignon',
        annual_dol: 4297.25,
        description:
            'Stylish Greek restaurant serving fresh seafood in a Mediterranean-style whitewashed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9789,
        total_orders: 1238.05,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Flavorful, Ramen, Vegan, Spicy, Delicious, Japanese, Ale, Gyoza, Amazing, Great',
        annual_dol: 5566.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7654,
        store_longitude: -73.9801,
        total_orders: 586.1,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Mimosa, Coffee, Modern, American, Avocado Toast, Classic, Warm, Fresh',
        annual_dol: 1251.02,
        description:
            'Farm-fresh comfort food, all-day brunch & cocktails served in an old-fashioned diner with booths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.9745,
        total_orders: 777.3,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Shrimp Cocktail, Tasty, Wine, Spicy, American, Thai, Innovative, Friendly',
        annual_dol: 3177.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6653,
        store_longitude: -73.9827,
        total_orders: 1963.85,
        neighborhood: 'Park Slope',
        keywords:
            'Wine, Salad, Coffee, Beer, Cozy, Delicious, Organic, Spacious, Friendly, Intimate',
        annual_dol: 2021.01,
        description:
            'Local Park Slope wine bar also serving cocktails & a cheese, charcuterie & small-plates menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6701,
        store_longitude: -73.9787,
        total_orders: 92.26,
        neighborhood: 'Park Slope',
        keywords: 'Vodka, Whiskey, Wine, Pizza, Beer, Italian, Dessert, Coffee, Meatball, Cozy',
        annual_dol: 94.22,
        description:
            'This cozy corner Italian spot serves straightforward pastas, entrees & brunch inside or al fresco.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6712,
        store_longitude: -73.991,
        total_orders: 468.88,
        neighborhood: 'Gowanus',
        keywords: 'Burger, Pizza, Italian, Mexican, Meatball, Gourmet, Tasty, Beer, Hawaiian, Wine',
        annual_dol: 487.73,
        description:
            'Dimly lit Italian bar/bistro that draws both neighborhood locals & Bell House show-goers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7665,
        store_longitude: -73.9127,
        total_orders: 194.2,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Fun, Premium, Yummy, Fresh, American',
        annual_dol: 380.24,
        description: '"HOOKAH LOUNGE IN ASTORIA QUEENS"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7386,
        store_longitude: -73.9887,
        total_orders: 5064.22,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Beer, Californian, American, Seasonal, Tex-Mex, Contemporary, French, Warm, Local',
        annual_dol: 5436.17,
        description:
            "Danny Meyer's Flatiron District tavern with a fixed-price-only dining room & a bustling bar area.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8029,
        store_longitude: -73.9641,
        total_orders: 556.76,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Burger, Salad, Pizza, Dessert, Coffee, Wine, Italian, Upscale, Beer, Mediterranean',
        annual_dol: 568.58,
        description:
            'Refined restaurant with outdoor seating serving thoughtfully sourced Mediterranean dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5384,
        store_longitude: -74.149,
        total_orders: 75.87,
        neighborhood: 'Great Kills',
        keywords:
            'Sushi, Vegetarian, Shrimp Cocktail, Delicious, Omelette, Casual, Fresh, American, Great, Pure',
        annual_dol: 177.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6447,
        store_longitude: -73.9225,
        total_orders: 18.63,
        neighborhood: 'East Flatbush',
        keywords: 'Flavorful, Caribbean, Delicious, Local, American, Casual, Latin',
        annual_dol: 76.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9688,
        total_orders: 562.93,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Beer, Margarita, Trendy, Tex-Mex, Upscale, Enchiladas, Funky, American, Delicious',
        annual_dol: 574.87,
        description:
            'Snug restaurant serving brunch, burgers & Tex-Mex fare in a funky space with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7151,
        store_longitude: -74.0087,
        total_orders: 331.54,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vodka, Pizza, Italian, Gluten Free, Upscale, Traditional, Authentic, Fresh, Great, Fine',
        annual_dol: 1355.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8404,
        store_longitude: -73.9218,
        total_orders: 42.51,
        neighborhood: 'Highbridge/West Bronx',
        keywords:
            'Steak, Lobster, Dessert, Delicious, Latin, Fusion, American, Great, Casual, Local',
        annual_dol: 183.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7148,
        store_longitude: -74.0096,
        total_orders: 803.59,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Wine, Upscale, Hotel, Vodka, Trendy, Homey, Spacious, Sleek, Comfortable',
        annual_dol: 3287.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.9714,
        total_orders: 130.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Vegan, Coffee, Thai, Uruguayan, French, Savory, Australian, American, Contemporary',
        annual_dol: 230.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6327,
        store_longitude: -74.0275,
        total_orders: 274.33,
        neighborhood: 'Bay Ridge',
        keywords:
            'Mexican, Burger, Nachos, Dessert, Tasty, Enchiladas, French, Casual, Sweet, Fresh',
        annual_dol: 516.15,
        description: '"Mexican bar restaurant , weekend DJ live music ."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.9857,
        total_orders: 3198.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Pizza, Chicken Parmigiana, Vodka, Beer, Italian, Wine, Meatball, Coffee, Avocado Toast, Cozy',
        annual_dol: 3491.33,
        description:
            'Wide-ranging Italian menu including steak, seafood & an antipasti bar served in a sprawling space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9878,
        total_orders: 7070.61,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Beer, Salad, Tuna Steak, Dutch, Spanish, Luxurious, Irish, Rum, American',
        annual_dol: 7304.94,
        description:
            "Sib of Tanner Smith's for beer & cocktails in a space with a long bar, tiled floors & flat-screens.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6998,
        store_longitude: -73.9921,
        total_orders: 1413.4,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Garlic Bread, Salad, Steak, Pizza, Italian, Spicy, Delicious, Cozy, Warm, Romantic',
        annual_dol: 1493.65,
        description:
            'Large portions of Italian comfort food are dished for crowds. Cash only & no reservations accepted.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7791,
        store_longitude: -73.9511,
        total_orders: 1358.3,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'French Toast, Steak, French Onion Soup, French, Wine, Beer, Gluten Free, Cozy, Modern, Omelette',
        annual_dol: 1961.59,
        description:
            "Alsatian eats & a popular brunch along with a beer list that's curated by a beer sommelier.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7868,
        store_longitude: -73.7263,
        total_orders: 237.76,
        neighborhood: '',
        keywords:
            'Hotdog, Steak, Tacos, Mexican, Beer, Colombian, Coffee, American, Dominican, Latin',
        annual_dol: 353.32,
        description:
            '"Delicious authentic Colombian food, with a fun Latin fusion twist! Find us online: Instagram: @micatucagreatneck Facebook: Mi Casa Es Tu Casa Restaurant & Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6965,
        store_longitude: -73.9845,
        total_orders: 514.67,
        neighborhood: 'Brooklyn Heights/Downtown Brooklyn/Bridge Plaza',
        keywords:
            'Hotel, Stylish, Classic, Amazing, Sophisticated, Great, Aesthetic, Perfect, Authentic, Rooftop',
        annual_dol: 2103.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7097,
        store_longitude: -74.014,
        total_orders: 449.51,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Wine, Coffee, Premium, Cozy, Comfortable, Great, Excellent, Rooftop, Modern',
        annual_dol: 475.02,
        description:
            "Street.Unfussy, cozy rooms offer free Wi-Fi, flat-screen TVs and iPod docks, plus desks and Keurig coffeemakers. Upgraded rooms add kitchenettes and dining areas.Freebies include loaner iPads, nightly wine hours, and all-day snacks and drinks in a communal lounge. There's an airy 20th-floor restaurant with floor-to-ceiling windows and a terrace, as well as a high-end steakhouse. Other amenities include a gym and a meeting room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7484,
        store_longitude: -73.9947,
        total_orders: 856.19,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Italian, Romantic, Casual, Local, Fresh',
        annual_dol: 874.36,
        description:
            'Housemade sausages & cured meats are featured at this Madison Square Garden-area Northern Italian.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6843,
        store_longitude: -73.9295,
        total_orders: 44.77,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Wine, Japanese, Burger, Salad, Stylish, Eclectic, Premium, Great, Excellent, Attentive',
        annual_dol: 183,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7302,
        store_longitude: -73.9942,
        total_orders: 1311.57,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Steak, Salad, Beer, Margarita, Martini, Juicy, American, Premium',
        annual_dol: 1343.62,
        description:
            'Subterranean sports bar with a college-keg-party vibe, pub grub & beer plus pool & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7346,
        store_longitude: -73.9879,
        total_orders: 1298.82,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Sushi, Margarita, Beer, Delicious, Cozy, Intimate, Perfect, Cool, Casual, Fresh',
        annual_dol: 1465.51,
        description:
            'Intimate cocktail bar with an easygoing vibe & hidden elements like a secluded patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7933,
        store_longitude: -73.9711,
        total_orders: 287.35,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Mexican, Tacos, Tequila, Margarita, Italian, French, Delicious, Casual, Fresh, Authentic',
        annual_dol: 351.99,
        description:
            '"Authentic Mexican food made with daily fresh ingredients. offering delicious Margaritas and cocktails with unic flavors and aromas"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.8781,
        total_orders: 19.06,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords: 'Pizza, Steak, Cuban, Vodka, Homemade, Modern, Casual, Fresh',
        annual_dol: 77.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7144,
        store_longitude: -74.0098,
        total_orders: 2711.32,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Beer, Whiskey, Irish, American, Upscale, Chill, Local, Casual, Great',
        annual_dol: 3014.78,
        description:
            'Bi-level bar serving elevated pub grub & craft beer in a rustic-chic space with sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9679,
        total_orders: 318.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Chili Chicken, Irish, Nachos, Polish, Friendly, Awesome, Mouth-Watering, Casual, Classic, Chill',
        annual_dol: 1337.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6894,
        store_longitude: -73.9861,
        total_orders: 8660.68,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Wine, Beer, Whiskey, Tasty, Delicious, Spacious, Rustic, Friendly, Creative, Great',
        annual_dol: 8844.45,
        description:
            'Rustic bar with a Catskills look offering wine, cocktails, sandwiches, charcuterie & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7867,
        store_longitude: -73.7271,
        total_orders: 240.9,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Lobster Bisque, Mexican, Gluten Free, Beer, Dessert, Wine, Spanish',
        annual_dol: 595.97,
        description:
            '"We cook seafood like rock stars, truly. There is only one way to eat fish – when its fresh! Our seafood arrives 7 days a week and is carefully selected before hitting our kitchen."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6906,
        store_longitude: -73.9951,
        total_orders: 91.54,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Pizza, Italian, Wine, Meatball, Dessert, Eclectic, Warm, Cozy, Modern, Iconic',
        annual_dol: 97.63,
        description:
            'Modern nook renowned for its Neapolitan-style pies, plus fresh pasta dishes & Italian wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6895,
        store_longitude: -73.981,
        total_orders: 740.97,
        neighborhood: 'Fort Greene/Brooklyn Heights',
        keywords:
            'Beer, Tasty, Margarita, Bourbon, Delicious, Crisp, Friendly, Casual, Lively, Classic',
        annual_dol: 2847.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.9924,
        total_orders: 889.72,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Sushi, Hamburger, Italian, Japanese, Modern, Casual, Vintage, Elegant, Local',
        annual_dol: 928.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5609,
        store_longitude: -73.9121,
        total_orders: 325.93,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords:
            'Pizza, Beer, Nutritious, Coffee, Delicious, American, Hotel, Irish, Casual, Local',
        annual_dol: 334.34,
        description:
            'Beer & pub grub served in a straightforward, Irish-style watering hole with flat-screen TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9949,
        total_orders: 2129.96,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Pizza, Beer, Irish, Nachos, Ale, American, Casual, Aesthetic',
        annual_dol: 2193.33,
        description:
            'Irish pub in the classic, casual style, offering a happy-hour scene and typical grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -74.0035,
        total_orders: 684.36,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Mexican, Hotel, Coffee, Rooftop, Martini, Trendy, Mediterranean, Bellini, Modern, Sleek',
        annual_dol: 2797.42,
        description:
            "Modern, zenlike rooms feature handmade denim bathrobes, wooden platform beds, bean bag chairs and city views, plus free Wi-Fi, flat-screen TVs and minibars. Some rooms add Japanese-style soaking tubs. Room service is available.Loaner bikes are available. There's also a sleek Mexican restaurant/bar and outdoor patio, plus a trendy rooftop eatery offering Mediterranean fare. Other amenities include 2 bars, a coffee shop and a rooftop pool.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9938,
        total_orders: 330.44,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Mexican, Tacos, Burritos, Salad, Casual, Local, Relaxed, Fancy, Recommended',
        annual_dol: 1408.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7515,
        store_longitude: -73.994,
        total_orders: 2559.77,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Hotel, Luxurious, Stylish, American, Iconic, Rooftop, Innovative, Tranquil, Exceptional, Chic',
        annual_dol: 2952.06,
        description:
            'and Times Square.Featuring floor-to-ceiling windows, the sleek, tranquil rooms come with free Wi-Fi, flat-screen TVs and minibars, as well as sitting areas, Nespresso machines and yoga equipment. The 1- and 2-bedroom suites add separate living space. All offer city or river views.A stylish 24th-floor restaurant provides a terrace. There’s also a spa and an expansive 60,000 sq ft fitness center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -73.9935,
        total_orders: 1580.45,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Vegan, Rooftop, Hotel, Upscale, Trendy, Spicy, Scotch, Fresh, Fantastic',
        annual_dol: 2868.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8494,
        store_longitude: -73.6446,
        total_orders: 308.2,
        neighborhood: '',
        keywords:
            'Lobster, Salad, Vegetarian, Pizza, Gluten Free, Italian, Wine, Coffee, Japanese, Beer',
        annual_dol: 314.74,
        description:
            'Casual New American find showcasing an eclectic menu in a modern, airy space with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7769,
        store_longitude: -73.9214,
        total_orders: 80.09,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Cheesecake, Pizza, Italian, Gluten Free, Wine, Homemade, Creme Brulee, Mediterranean, Modern, Casual',
        annual_dol: 126.45,
        description:
            'Brick-oven pizzas, pasta & other Italian eats in a casual space with a full bar & patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7263,
        store_longitude: -73.9921,
        total_orders: 11166.28,
        neighborhood: 'NoHo',
        keywords: 'Whiskey, Beer, Vodka, Tasty, Steak, Vintage, Upscale, Delicious, Warm, Irish',
        annual_dol: 11403.21,
        description:
            'Hearty, upscale pub eats, craft cocktails, beer & lots of spirits in a rustic, happening space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7676,
        store_longitude: -73.9833,
        total_orders: 7320.85,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Salad, Steak, Japanese, Fried Chicken, Hotel, Delicious, Asian, Fusion, Authentic',
        annual_dol: 17538.73,
        description:
            'Creative seafood & grilled meats plus tempura fried chicken & cocktails in a wood-paneled lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7671,
        store_longitude: -73.9865,
        total_orders: 2813.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Trendy, Karaoke, Fun, Friendly, Phenomenal, Great, Casual',
        annual_dol: 3218.99,
        description:
            'Modern, brick-lined gay bar for cocktails & entertainment from drag shows to karaoke nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 2215.22,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Rich, Hotel, Rooftop, Velvety, Awesome, Fun, Premium, Cozy, Perfect, Gourmet',
        annual_dol: 2362.48,
        description:
            'Swanky lounge with a penthouse vibe, sweeping views, DJs & a menu of cocktails & gourmet bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6982,
        store_longitude: -73.8061,
        total_orders: 15.63,
        neighborhood: 'Jamaica',
        keywords:
            'Steak, Burger, Salad, Dessert, Coffee, Delicious, French, Sangria, Authentic, Casual',
        annual_dol: 63.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9787,
        total_orders: 302.21,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Japanese, Modernist, Elegant, Vintage, Premium, Authentic, Cultural, Unique',
        annual_dol: 370.2,
        description:
            'The Nippon Club of New York City is a private social club on 57th Street in Midtown Manhattan, New York City, founded in 1905 by Jōkichi Takamine for Japanese Americans and Japanese nationals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.764,
        store_longitude: -73.9734,
        total_orders: 10.58,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Premium, American, Outstanding, Perfect, Hotel, Fancy, Fresh, Great, Casual',
        annual_dol: 43.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7162,
        store_longitude: -73.9896,
        total_orders: 555.22,
        neighborhood: 'Lower East Side',
        keywords:
            'Hotdog, Whiskey, Beer, Steak, Spicy, Margarita, Delicious, Quaint, Cozy, Premium',
        annual_dol: 605.1,
        description:
            'This intimate tavern features a full calendar of eclectic events, plus hot dogs & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7183,
        store_longitude: -73.9881,
        total_orders: 963.14,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Tacos, Wine, Tasty, Funky, Hotel, Cozy, Crisp, Awesome, Fresh',
        annual_dol: 1320.16,
        description:
            '"The Grand Delancey is open for outdoor service! Expect the same excellent draft bottled and canned craft beers you know and love alongside rare cellar selections. Plus, find a list of ciders and natural wines from our friends at Peoples Wine, plus a handful of our artisan cocktails. Our food menu is a veritable Market Line reunion with a broad selection of dishes from our buddies at Slice Joint, Schaller & Weber and Que Chevere, along with items from Pickle Guys, Nixtamal, Essex Pearl and Southeast Asia Food Group."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -73.9884,
        total_orders: 511.89,
        neighborhood: 'Lower East Side',
        keywords: 'Spicy, Bbq, Fun, Homemade, Great, Diverse, Casual, Hummus, Unique',
        annual_dol: 2092.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.7921,
        total_orders: 341.13,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Burger, Vegan, Spicy, Dessert, Wine, Beer, Korean, Romantic, Sweet, Vietnamese',
        annual_dol: 927.09,
        description:
            '"Cozy neighborhood hangout serving contemporary American fare with a Korean twist accompanied by craft beers and innovative cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.8869,
        total_orders: 6.55,
        neighborhood: 'Jackson Heights',
        keywords:
            'Burger, Delicious, Impeccable, Amazing, Colombian, Peruvian, Perfect, Wonderful, American, Great',
        annual_dol: 26.76,
        description:
            '"Modern Latin Fusion Cousine with A twist now in the heart of Jackson heights, Queens Amazing unique Cocktails, Delicious food with beautiful presentations. Fabrika is where unique experiences are fabricated!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5888,
        store_longitude: -73.8173,
        total_orders: 16221.21,
        neighborhood: 'Far Rockaway/Rockaway Beach',
        keywords:
            'Salad, Wine, Beer, Vodka, Mexican, French Toast, French, Thai, Mediterranean, Bbq',
        annual_dol: 18074.71,
        description:
            'Casual waterfront restaurant & bar offering a raw bar, American mains, a deck & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5404,
        store_longitude: -74.1771,
        total_orders: 259,
        neighborhood: 'Annadale',
        keywords:
            'Steak, Burger, Vegetarian, Pizza, Salad, Italian, Beer, American, Karaoke, Homemade',
        annual_dol: 333.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7448,
        store_longitude: -73.9833,
        total_orders: 6085.56,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Luxurious, Wine, Rooftop, Stylish, Delicious, Eclectic, Innovative, Mediterranean, Classy',
        annual_dol: 6214.69,
        description:
            "13-minute walk from events at Madison Square Garden.Airy, sophisticated rooms feature smart TVs and minibars, along with sitting areas (some with sofas). Upgraded rooms offer balconies with city views. Suites add separate living/dining rooms; an upgraded suite comes with 2 bedrooms, 2 bathrooms and 3 balconies, as well as a wet bar.There's a high-end Mediterranean restaurant and an ultra-hip underground nightclub, plus a rooftop bar with a terrace.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7116,
        store_longitude: -73.9662,
        total_orders: 611.2,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Beer, Intimate, Casual, Cool, Atmospheric, Fun, Chill, Great',
        annual_dol: 624.17,
        description:
            'Atmospheric nightclub & coffeehouse in a former garage featuring music, drinks & various events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 1754.4,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Beer, Delicious, Belgian, Sweet, American, Friendly, Amazing, Fantastic, Great',
        annual_dol: 1852.7,
        description:
            'Rustic-chic resto-bar with diverse comfort fare, original cocktails & beer plus a back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9262,
        total_orders: 82.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tequila, Tacos, Mexican, Fish Tacos, Gluten Free, Lively, Local, Casual',
        annual_dol: 407.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7188,
        store_longitude: -73.9586,
        total_orders: 167.81,
        neighborhood: 'Williamsburg',
        keywords:
            'Italian, Hotel, Legendary, Romantic, Classic, Innovative, Sleek, Fantastic, Authentic, Great',
        annual_dol: 200.78,
        description:
            'High-end pick for classic Italian cuisine, cocktails & wines in posh, sleek surrounds with a lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.9679,
        total_orders: 139.88,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Coffee, Beer, Cool, Casual, American, Laid-Back, Great',
        annual_dol: 571.8,
        description:
            '"A neighbourhood bar with board games, John Carpenter movies, a shuffleboard & a dartboard. It\'s still 1985 right?"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9687,
        total_orders: 2968.14,
        neighborhood: 'Central Park West Historic District',
        keywords:
            'Cheesecake, Salad, Tomato Soup, Dessert, Polished, Romantic, Homemade, Natural, Casual, American',
        annual_dol: 7346.57,
        description:
            'American restaurant & bar notable for its prime lakeside setting in the middle of Central Park.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8088,
        store_longitude: -73.945,
        total_orders: 1572.45,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Lobster, Delicious, Bread Pudding, Karaoke, Creamy, Bbq, Fabulous, Southern Comfort, Caribbean, Amazing',
        annual_dol: 1797.56,
        description:
            'Bi-level nightspot serving specialty cocktails plus a menu of Southern comfort food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9845,
        total_orders: 2331.96,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Trendy, Coffee, Sensational, Rooftop, Incredible, Great, Perfect, Contemporary, Unique',
        annual_dol: 2481.66,
        description:
            'Central Park.Streamlined, compact rooms offer free Wi-Fi and smart TVs, plus floor-to-ceiling windows and rainfall showerheads. They also include tablets that control the blinds, temperature and lighting.Amenities include a trendy lobby, a library, and a slick 24-hour coffee shop with grab-and-go fare. There’s also a rooftop guest-only bar, as well as a 20th-floor gym with city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7712,
        store_longitude: -73.9932,
        total_orders: 906.81,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Salad, Beer, Mimosa, Upscale, French, American, Mediterranean, Greek',
        annual_dol: 1112.63,
        description:
            'Group-friendly spot serving Greek specialties, grilled seafood, cocktails & Mediterranean wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7672,
        store_longitude: -73.9836,
        total_orders: 1222.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Salad, Dessert, Irish, American, Amazing, Great, Casual, Traditional, Chill',
        annual_dol: 1421.21,
        description:
            'Traditional American grill restaurant & sports bar showing all the big games on multiple TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.9858,
        total_orders: 461.8,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegetarian, Burger, Gourmet, Coffee, Wine, Beer, Mimosa, French Toast, Japanese, French',
        annual_dol: 671.76,
        description:
            'American bistro fare served in a hip, convivial setting with three floors, two bars & a beer garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7211,
        store_longitude: -73.988,
        total_orders: 5441.58,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Sushi, Salad, Japanese, Wine, Beer, Stylish, American, Caesar Salad, Homemade',
        annual_dol: 5935.6,
        description:
            'Chill, stylish sake bar & restaurant featuring many rice wines, plus elevated Japanese cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7211,
        store_longitude: -73.9889,
        total_orders: 950.75,
        neighborhood: 'Lower East Side',
        keywords: 'Stylish, Hotel, Cozy, Bright, Lively, Coffee, Friendly, Clean, Modern, Rooftop',
        annual_dol: 3886.36,
        description:
            'Building.Featuring city or street views, bright rooms come with complimentary Wi-Fi, flat-screen TVs and workspaces.Tea and coffee in the lobby are included. Amenities consist of a cozy lounge and a rooftop terrace offering views of the city.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.714,
        store_longitude: -73.9889,
        total_orders: 34.18,
        neighborhood: 'Two Bridges',
        keywords:
            'Burger, Vegan, Salad, Gluten Free, Coffee, Avocado Toast, Mediterranean, Shawarma, American, Omelette',
        annual_dol: 139.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6188,
        store_longitude: -74.0332,
        total_orders: 73.64,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Wine, Tasty, Pizza, Beer, Martini, Delicious, Romantic, Cozy, Friendly, American',
        annual_dol: 301.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -73.9987,
        total_orders: 1849.28,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotdog, Beer, Ale, Nachos, Awesome, Fresh, Creative, Classic, Great, Local',
        annual_dol: 6967.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -74.0046,
        total_orders: 2060.2,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Salad, Wine, Tasty, Beer, Japanese, Whiskey, Spicy, Organic, Mediterranean',
        annual_dol: 2175.89,
        description:
            'Traditional Japanese eats pair with sake & shochu in an art-adorned space with exposed-brick walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7324,
        store_longitude: -73.9987,
        total_orders: 720.81,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Burger, Lobster, Tequila, Salad, Wine, Beer, Coffee, Dessert, Bourbon, Hotel',
        annual_dol: 752.06,
        description:
            'Low-key mainstay on Washington Square offers New American menu, afternoon tea & jazz brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6858,
        store_longitude: -73.9816,
        total_orders: 380.02,
        neighborhood: 'Boerum Hill',
        keywords: 'Tasty, Coffee, Beer, Authentic, Wonderful, Great, Creative, Diverse, Local',
        annual_dol: 442.96,
        description:
            'Laughter abounds at this stand-up joint offering acts from both amateurs & seasoned pros.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6791,
        store_longitude: -73.9737,
        total_orders: 37.15,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Vegetarian, Salad, Thai, Gourmet, Gluten Free, Stylish, Martini, Bbq, Yummy, Picturesque',
        annual_dol: 151.85,
        description: 'Stylish neighborhood place specializing in basic, homestyle Siamese dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -73.9782,
        total_orders: 1116.17,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Vodka, Bourbon, Upscale, Premium, Cozy, Sophisticated, Bright, Great, Cool, Contemporary',
        annual_dol: 1441.85,
        description:
            'Hip, contemporary craft cocktail bar with upscale lounge vibe & small menu of sophisticated bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7132,
        store_longitude: -73.9577,
        total_orders: 1426.81,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Beer, Salad, Wine, Quaint, Brazilian, Homemade, Natural, Casual, Fresh',
        annual_dol: 2331.65,
        description:
            'Quaint spot featuring a menu of tapas & pintxos along with beer, natural wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7134,
        store_longitude: -73.9513,
        total_orders: 1230.44,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Coffee, Italian, Dessert, Beer, Cozy, Warm, Modern, Fabulous, Authentic',
        annual_dol: 1586.7,
        description:
            'Pinsa, a traditional flatbread, headlines a menu of Roman classics prepared in an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.613,
        store_longitude: -74.1216,
        total_orders: 5.56,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords:
            'Mexican, Tacos, Steak, Quesadillas, Turkish, Bbq, Fajitas, Rustic, Cosmopolitan, Mediterranean',
        annual_dol: 22.72,
        description: '"Authentic Mexican restaurant on Victory Boulevard in Staten Island"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7401,
        store_longitude: -73.7911,
        total_orders: 420.32,
        neighborhood: 'Fresh Meadows/Auburndale/Flushing',
        keywords: 'Fresh, Coffee, Hotel, Friendly, Great, Casual',
        annual_dol: 468,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7077,
        store_longitude: -74.0076,
        total_orders: 382.3,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Steak, Shrimp Cocktail, Chicken Pot Pie, Fried Calamari, Irish, Caesar Salad, Delicious, American',
        annual_dol: 1367.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6373,
        store_longitude: -74.0756,
        total_orders: 5.47,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords: 'Burger, French, British, Casual, Great',
        annual_dol: 22.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6244,
        store_longitude: -74.1487,
        total_orders: 7.97,
        neighborhood: 'Mid Island/Graniteville',
        keywords: 'Sushi, Steak, Salad, Pizza, Japanese, Coffee, Spicy, Chinese, Sashimi, Thai',
        annual_dol: 32.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7418,
        store_longitude: -73.9812,
        total_orders: 6900.63,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Burger, Salad, Spicy, Delicious, American, Irish, Cozy, Yummy, French, Sweet',
        annual_dol: 7438.15,
        description:
            'Hip hangout serving creative cocktails & American fare, with a private speakeasy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9762,
        total_orders: 155.02,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Turkish, Hungarian, American, Nordic, Heritage, Cultural, Foreign, Great',
        annual_dol: 161.75,
        description:
            'The Civic Club building, now the New York Estonian House, is a four-story Beaux-Arts building located at 243 East 34th Street between Second and Third Avenues in the Murray Hill neighborhood of Manhattan in New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.9795,
        total_orders: 68.11,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Pizza, French, Delicious, Casual, Local, Traditional, Great, Teriyaki',
        annual_dol: 278.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.705,
        store_longitude: -74.0097,
        total_orders: 4591.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Lobster, Cheesecake, Salad, New-York-Style, Prime Rib, American, Legendary, Southern, Classic',
        annual_dol: 16118.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7255,
        store_longitude: -74.0052,
        total_orders: 7325.29,
        neighborhood: 'Hudson Square',
        keywords:
            'Hotel, Coffee, Italian, Stylish, Urban, Premium, American, Outstanding, Refined, Intimate',
        annual_dol: 7480.72,
        description:
            "Offering floor-to-ceiling windows with city or Hudson River views, the refined rooms feature flat-screen TVs, Nespresso machines and free Wi-Fi, plus iPod docks, soaking tubs and rainfall showers. Suites add living rooms; some have whirlpool tubs.There's a chic restaurant serving contemporary American dishes, and a hip lounge/bar with a mid-century theme. Other amenities include a fitness center, a spa with a hammam, and a seasonal outdoor pool with a bar. There's also a ballroom, meeting space and valet parking.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7045,
        store_longitude: -74.0104,
        total_orders: 284.8,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Wine, Whiskey, Beer, Premium, Eclectic, Cozy, Hamburger, Picturesque, Amazing, Impressive',
        annual_dol: 1462.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7471,
        store_longitude: -73.9241,
        total_orders: 214.02,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Ale, Beer, American, Homey, Excellent, Great, Casual, Attentive',
        annual_dol: 255.66,
        description:
            'Homey firehouse-themed bar with an enclosed back patio, a hearty pub food menu & sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7081,
        store_longitude: -73.9236,
        total_orders: 1766.34,
        neighborhood: 'East Williamsburg',
        keywords:
            'Wine, Tasty, Delicious, Intimate, Fun, Awesome, Great, Excellent, Quirky, Creative',
        annual_dol: 1925.04,
        description:
            'Hip, sci-fi themed nightspot with a retro feel & neon-lit decor serving creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7175,
        store_longitude: -74.0102,
        total_orders: 1942.88,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Salad, Whiskey, Beer, Tasty, Bourbon, Spicy, Trendy, Bbq, Organic',
        annual_dol: 2027.12,
        description:
            'Mellow, unassuming neighborhood hang draws a Wall Street crowd for beers, bourbon & bar grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6529,
        store_longitude: -74.0033,
        total_orders: 584.64,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Pizza, Burger, Hotel, Fun, Perfect, Friendly, Great, Casual',
        annual_dol: 666.89,
        description:
            'Bowling alley offering an arcade, snack bar & lounge, plus leagues & birthday parties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7202,
        store_longitude: -74.0097,
        total_orders: 751.9,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Lobster, Lobster Roll, Salad, Italian, French Toast, Savory, Hawaiian, French, Hotel',
        annual_dol: 767.85,
        description:
            'Tiny bar/restaurant in a converted carriage house with vintage decor & a noteworthy bathroom.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7976,
        store_longitude: -73.9484,
        total_orders: 921.99,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Wine, Beer, Salad, Dessert, Nachos, Polished, American, Friendly, Sweet, Casual',
        annual_dol: 1027.34,
        description:
            'Vibrant, rustic-chic establishment offering New American dishes, craft cocktails & patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.5888,
        total_orders: 118.98,
        neighborhood: '',
        keywords:
            'Vegetarian, Vegan, Sushi, Salad, Kebab, Pizza, Delicious, Turkish, Mediterranean, Friendly',
        annual_dol: 157.51,
        description:
            'Kebabs & other chargrilled Middle Eastern eats served in a bare-bones strip-mall space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8406,
        store_longitude: -73.8417,
        total_orders: 1142.07,
        neighborhood: 'Westchester Square',
        keywords: 'Burger, Sushi, Salad, Beer, Spicy, Chinese, Asian, Latin, Fusion, Global',
        annual_dol: 1257.09,
        description:
            'Asian fare with global influences plus cocktails, beer & sake are served in a casual setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.8452,
        total_orders: 318.66,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Sushi, Steak, Japanese, Spicy, Sashimi, Ribeye Steak, Asian, Delicious, Classy, Fusion',
        annual_dol: 354.24,
        description:
            'Traditional Japanese restaurant known for its sushi bar & chef shows at hibachi tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7209,
        store_longitude: -73.8465,
        total_orders: 146.84,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Mexican, Salad, Gourmet, Nachos, Spicy, Delicious, Warm, American, Friendly, Latin',
        annual_dol: 161.48,
        description:
            'Hearty Mexican fare, including fajitas, tableside guacamole & enchiladas, served in a warm setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8213,
        store_longitude: -73.9307,
        total_orders: 339.56,
        neighborhood: 'West Bronx',
        keywords: 'Steak, Cajun, Bourbon, Beer, American, Classic, Casual, Lively, Local, Great',
        annual_dol: 617.94,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -74.0078,
        total_orders: 3085.31,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Burger, Beer, Dessert, American, Casual, Classic, Sweet, Rare, Great',
        annual_dol: 18682.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -74.0036,
        total_orders: 70.66,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Kebab, Salad, Butter Chicken, Mimosa, Wine, Margarita, Vodka, Nutritious',
        annual_dol: 476.13,
        description:
            'Simple spot serving American fare with Middle Eastern influences plus classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -74.0037,
        total_orders: 103.33,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesecake, Salad, Italian, Wine, Shrimp Scampi, Meatball, Beer, Delicious, Cozy, Rustic',
        annual_dol: 422.37,
        description:
            'Classic Italian pastas & hearty meat dishes pair with wine & cocktails in tiny digs with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7853,
        store_longitude: -73.9768,
        total_orders: 44.51,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Creamy, Comfortable, Sleek, Mediterranean, Casual, American, Homemade, Fine, Excellent',
        annual_dol: 181.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6667,
        store_longitude: -73.5536,
        total_orders: 1075.2,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Shrimp Cocktail, Italian, Spicy, Rustic, Polished, Healthy, Friendly, Casual',
        annual_dol: 1123.2,
        description:
            'This trattoria serves brick-oven pizzas & other traditional Italian fare in a rustic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5757,
        store_longitude: -73.9802,
        total_orders: 235.53,
        neighborhood: 'Coney Island',
        keywords: 'Cajun, Shrimp Cocktail, Authentic, Fun, Perfect, Great, Casual',
        annual_dol: 1098.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7659,
        store_longitude: -73.9695,
        total_orders: 1190.24,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Wine, Vegetarian, Tasty, French, Upscale, Beer, Grand Marnier, Hotel, Mediterranean',
        annual_dol: 1241.84,
        description:
            'Place for elegant French fare with a Moroccan touch in The Lowell hotel, with a bar & skylit garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.744,
        store_longitude: -73.9237,
        total_orders: 151.52,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Tacos, Mexican, Tequila, Beer, Flavorful, Margarita, Tasty, Salad, Dessert, Wine',
        annual_dol: 161.59,
        description:
            'A younger crowd enjoys a mix of Mexican plates & margaritas at this casual local cantina.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.5555,
        total_orders: 1148.61,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Lobster Tail, Pizza, Butter Chicken, Wine, Beer, Upscale, Swiss',
        annual_dol: 1172.98,
        description:
            'Easygoing operation with an upscale atmosphere serving classic grilled meat dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6254,
        store_longitude: -73.7131,
        total_orders: 427.52,
        neighborhood: '',
        keywords: 'Champagne, Luxurious, Premium, Classic, Signature',
        annual_dol: 847.54,
        description:
            '"The Woodmere Club is located on the South Shore of Long Island. Offering Har-Tru Tennis Courts, resort style swimming pool, full service restaurant, ladies & men\'s card rooms. We also provide catering for members and non members"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -74.0102,
        total_orders: 26771.32,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Mexican, Beer, Margarita, Salad, Wine, Authentic, Local, Casual, Fresh, Sangria',
        annual_dol: 27914.34,
        description:
            'Warm, sit-down Mexican restaurant with specialty margaritas & Southwestern decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8006,
        store_longitude: -73.9658,
        total_orders: 2512.17,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Wine, Pizza, Beer, Trendy, Italian, Delicious, Perfect, Mediterranean, Casual, Fresh',
        annual_dol: 2866.3,
        description:
            'Park eatery with patio tables & river views offering a pub menu with a global twist, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7229,
        store_longitude: -74.0048,
        total_orders: 33.68,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Sushi, Japanese, Sashimi, Asian, Intimate, Sparkling, Seasonal, Comfortable, Classic, Omakase',
        annual_dol: 137.66,
        description:
            '"Kintsugi Sushi is an intimate omakase experience offering limited seating and outdoor dining in SoHo."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8068,
        store_longitude: -73.9269,
        total_orders: 329.03,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Burger, Beer, Coffee, Margarita, Fun, Awesome, American, Asian, Fusion, Hip',
        annual_dol: 947.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7941,
        store_longitude: -73.9405,
        total_orders: 38.29,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Tacos, Mexican, Margarita, French Toast, Tasty, French, Latin, Fusion, Casual',
        annual_dol: 52.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9924,
        total_orders: 52.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Stylish, Hotel, Trendy, Wine, Spacious, Clean, Friendly, Beer, Classic, Contemporary',
        annual_dol: 213.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7764,
        store_longitude: -73.9506,
        total_orders: 2588.28,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Vegetarian, Steak, Beer, Whiskey, Coffee, Ale, Gin, Irish, Friendly, Casual',
        annual_dol: 2685.82,
        description:
            'Time-tested, inviting haunt known for its complimentary potato chips with a drink purchase.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7755,
        store_longitude: -73.9638,
        total_orders: 12491.57,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Sushi, Hotel, Pizza, Dessert, Innovative, Classic, Global, Fresh, Excellent',
        annual_dol: 13615.25,
        description:
            'Polished, art deco-inspired rooms have free Wi-Fi, custom furniture and marble bathrooms with mirrored flat-screen TVs; suites add extra living space. Some rooms and suites also come with granite bars and kitchens with designer appliances.Perks include a fitness center and a salon, and picnic baskets and personal shoppers are available. Shoeshine service and loaner bikes are complimentary, and there’s also a swanky bar and an acclaimed restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.8425,
        total_orders: 98.76,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Cajun, Shrimp Scampi, Bbq, Irish, Chinese, Spicy, French, Traditional, Casual',
        annual_dol: 403.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7772,
        store_longitude: -73.9538,
        total_orders: 1654.63,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Irish, Upscale, Cozy, Smoky, Inviting, Friendly, Classy, Casual, Local',
        annual_dol: 1730.48,
        description:
            'Cozy neighborhood gathering place featuring pints, a jukebox & an inviting atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6816,
        store_longitude: -73.8799,
        total_orders: 7.2,
        neighborhood: 'Cypress Hills',
        keywords:
            'Steak, Meatball, Dominican, Salad, Caribbean, American, Latin, Local, Casual, Great',
        annual_dol: 29.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7777,
        store_longitude: -73.9517,
        total_orders: 213.54,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Hotdog, German, Austrian, American, Wine, Dessert, Classic, Eclectic, Seasonal, Casual',
        annual_dol: 872.87,
        description:
            'Compact counter serve doling out elevated sausage sandwiches with an international twist, plus beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8276,
        store_longitude: -73.9243,
        total_orders: 59.6,
        neighborhood: 'Concourse/West Bronx',
        keywords:
            'Salad, Coffee, Dominican, Spanish, Puerto Rican, Latin, American, Local, Casual, Fine',
        annual_dol: 91.39,
        description:
            'Enduring, family-owned restaurant for Puerto Rican eats, such as pernil & mofongo, plus breakfast.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7766,
        store_longitude: -73.9554,
        total_orders: 134.02,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Belgian, Irish, Cozy, Great, Authentic, Casual',
        annual_dol: 547.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9601,
        total_orders: 17.41,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Salad, Dessert, Wine, Japanese, Warm, Impeccable, Ale, Friendly, Hotel',
        annual_dol: 71.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7296,
        store_longitude: -73.9871,
        total_orders: 1013.98,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Clean, Coffee, Crisp, Ale, Fresh, Ramen, Great, Impressive, Casual',
        annual_dol: 1041.02,
        description:
            'Easygoing pit stop with a neighborhood vibe offering beer & cocktails, plus TV sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7362,
        store_longitude: -73.9874,
        total_orders: 324.5,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Wine, Elegant, Romantic, Cozy, Quaint, Classy, Intimate, Mediterranean, Robust, Distilled',
        annual_dol: 378.54,
        description:
            'Intimate wine bar serving mostly Old World varietals plus small plates in a wood-accented space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.752,
        store_longitude: -73.9793,
        total_orders: 3908.26,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Burger, Lobster, Cheesesteak, Canadian, Lobster Bisque, Bourbon, Vodka, Norwegian, French',
        annual_dol: 3991.19,
        description:
            'Dry-aged steaks & a casual bar menu, plus raw & grilled seafood in a clubby space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7536,
        store_longitude: -73.9735,
        total_orders: 3947.63,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Wine, American, Italian, Impeccable, Premium, Modern, Legendary, Contemporary, Lively',
        annual_dol: 4231.03,
        description:
            'An upscale American spot with classic decor, including a photo collection, private rooms & roof bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.576,
        store_longitude: -73.9691,
        total_orders: 581.85,
        neighborhood: 'Coney Island',
        keywords:
            'Salad, Wine, Upscale, Delicious, Russian, French, Premium, Modern, Exquisite, Mediterranean',
        annual_dol: 758.56,
        description:
            'French-inspired New American fare is the focus of this chic eatery with a bar & sizable wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.833,
        total_orders: 288.95,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Juicy, Cajun, Delicious, Creole, Casual, Recommended',
        annual_dol: 1014.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9688,
        total_orders: 39.98,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Tacos, Salad, Burritos, Warm, Delicious, Local, Healthy, Casual',
        annual_dol: 255.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7631,
        store_longitude: -73.9964,
        total_orders: 992.23,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Salad, Steak, Chicken Caesar Salad, Wine, Vodka, Scotch, Irish, Beer, Rich',
        annual_dol: 1225.17,
        description:
            'Landmark Irish tavern, originally opened in 1868, with classic decor & a pub-grub menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9897,
        total_orders: 3055.3,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Italian, Salad, Wine, Delicious, Martini, Cozy, Innovative, Fun, Organic',
        annual_dol: 3120.13,
        description:
            '"The name Becco is derived from the Italian verb, beccare, which means to peck, nibble or savor something in a discriminating way. Lidia Bastianich & her son, Joe, opened Becco in 1993 & since then, it has been providing the New York community with outstanding service for over 20 years. Located on New York’s famed Restaurant Row in the heart of the theater district, Becco is open 7 days a week for both lunch & dinner. Becco is most famous for its’ innovative pasta tasting menu & extensive $31 wine list."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9804,
        total_orders: 2354.4,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Ramen, Flavorful, Vegan, Ale, Spicy, Trendy, Japanese, Spacious, Authentic, Great',
        annual_dol: 2816.84,
        description:
            'Japanese chain focused on ramen, with this branch showcasing kasane (pork & chicken) broth.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9881,
        total_orders: 7043.88,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Steak, Wine, Beer, Salad, Irish, Nachos, American, Delicious, French, Spacious',
        annual_dol: 7518.8,
        description:
            'Spacious 4-story Irish pub with a rooftop bar offering traditional eats, draft beer & many TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9829,
        total_orders: 150.52,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Japanese, Tasty, Sashimi, Sukiyaki, Modern, Casual, Traditional, Omakase',
        annual_dol: 615.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9813,
        total_orders: 710.9,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Upscale, Sushi, Gin, Elegant, Italian, Creamy, Premium, Intimate, Whimsical, Modern',
        annual_dol: 2905.94,
        description:
            '"Madame George is an intimate subterranean cocktail lounge with an effortlessly elegant interior. Step from the busy streets of Manhattan into a whimsical space, where luxe textures and bright accents form a striking canvas for creative cocktails and shareable bites. The first annual menu is inspired by New York City through the ages. We strive to evoke multiple senses of our guests. With eye catching presentation and aromatics a plenty, all hoping to trigger your own relationship with the city that never sleeps. From the team that brought you Valerie, New York City, this one’s for you."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6971,
        store_longitude: -73.9708,
        total_orders: 260.09,
        neighborhood: 'Clinton Hill',
        keywords: 'Upscale, Quaint, Seasonal, Casual, Traditional, Daiquiri, Vibrant, Great',
        annual_dol: 1063.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.9881,
        total_orders: 9929.24,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Korean, Vegetarian, Upscale, Stylish, Spicy, Delicious, Wine, Warm, Bbq, Friendly',
        annual_dol: 11213.37,
        description:
            "Upscale, stylish Korean eatery on skyscraper's 39th floor offers panoramic views & tabletop BBQ.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7309,
        store_longitude: -74.0041,
        total_orders: 622.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesecake, Pizza, Italian, Wine, Stylish, Delicious, Rustic, Authentic, Fresh, Casual',
        annual_dol: 635.49,
        description:
            'Naples-influenced fare & wines are served in a stylish, white-tiled room with light-wood furniture.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.6622,
        total_orders: 50.32,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Burritos, Salad, Trendy, French, Casual, Local, High-Protein, Fresh',
        annual_dol: 205.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8089,
        store_longitude: -73.8935,
        total_orders: 523.57,
        neighborhood: 'Hunts Point/West Bronx',
        keywords: 'Amazing, Perfect, Hip',
        annual_dol: 2140.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6658,
        store_longitude: -73.9823,
        total_orders: 167.27,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Pizza, Sushi, Beer, Wine, Coffee, Dessert, Spanish, Gin, Japanese',
        annual_dol: 719.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6674,
        store_longitude: -73.7982,
        total_orders: 163.18,
        neighborhood: 'South Ozone Park/Jamaica/Rochdale',
        keywords: 'Hotel, Exceptional',
        annual_dol: 170.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.9545,
        total_orders: 17779.59,
        neighborhood: 'Greenpoint',
        keywords:
            'Sushi, Vegetarian, Salad, Wine, Tasty, Coffee, Japanese, Beer, Delicious, Scandinavian',
        annual_dol: 30926.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7089,
        store_longitude: -73.9356,
        total_orders: 2515.46,
        neighborhood: 'East Williamsburg',
        keywords:
            'Beer, Cuban, Italian, Venezuelan, Trendy, Asian, Mediterranean, French, Modern, Fusion',
        annual_dol: 3734.4,
        description:
            'Modern Cuban Asian fusion fare is served in a hip space with a living wall & coastal hues.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7694,
        store_longitude: -73.9632,
        total_orders: 313.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Italian, Wine, Sweet, Casual, High-End, Distinctive',
        annual_dol: 1180.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7265,
        store_longitude: -73.948,
        total_orders: 13.43,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Casual, Great',
        annual_dol: 24.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6738,
        store_longitude: -73.7304,
        total_orders: 49.72,
        neighborhood: 'Rosedale',
        keywords: 'Upscale, Elegant, Beer, Caribbean, Rooftop, Casual',
        annual_dol: 203.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7438,
        store_longitude: -73.9536,
        total_orders: 455.79,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Pizza, Beer, American, Fried Chicken, Delicious, Classy, Casual, Local, Awesome',
        annual_dol: 465.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7452,
        store_longitude: -73.9476,
        total_orders: 2842.99,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Whiskey, Beer, American, Elegant, Comfortable, Colorful, Dutch, Fresh, Intimate, Hip',
        annual_dol: 5367.13,
        description: '"Speakeasy in the heart of Long Island City, Queens."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7371,
        store_longitude: -73.8966,
        total_orders: 130.69,
        neighborhood: 'Flushing/Woodside',
        keywords:
            'Mexican, Beer, Fruity, Spicy, Chinese, American, Elegant, Asian, Friendly, Classy',
        annual_dol: 570.74,
        description:
            'Cold noodles, pepper beef & other Sichuan favorites in a chic venue serving fruity house cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6825,
        store_longitude: -73.9935,
        total_orders: 2638.5,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Beer, Coffee, Ale, Zombie, Great, Perfect, Wonderful, Terrific, American, Relaxed',
        annual_dol: 2694.48,
        description:
            'Relaxed corner watering hole offering a rotating variety of craft beers on tap & in bottles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.7802,
        total_orders: 597.57,
        neighborhood: 'Bayside/Auburndale',
        keywords:
            'Thai, Salad, Vegetarian, Chinese, Delicious, Traditional, Massaman Curry, Healthy, Pad Thai, Spacious',
        annual_dol: 887.14,
        description:
            'Classic Thai fare in a spacious dining room with refined, contemporary decor & a circular bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6859,
        store_longitude: -73.9082,
        total_orders: 739.22,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Warm, Wine, Inviting, Friendly, Jamaican, Homey, Great, Casual',
        annual_dol: 787.8,
        description:
            'Snug, homey neighborhood bar with a warm atmosphere offering cocktails & a variety of beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6182,
        store_longitude: -74.0337,
        total_orders: 881.3,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Upscale, Bourbon, Beer, Delicious, Apple Pie, Classy, Healthy, Japanese, Classic, Welcoming',
        annual_dol: 919.51,
        description: '"Bay Ridge\'s Original Speakeasy w/ over 62 Classic Mixology Cocktails"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9806,
        total_orders: 1467.31,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Steak, Wine, Italian, Vodka, Upscale, Martini, Hotel, Delicious, Shrimp Scampi, Romantic',
        annual_dol: 1884.17,
        description:
            'Old-school elegance, including table linens, sets the stage for Italian classics, cocktails & wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6926,
        store_longitude: -73.9886,
        total_orders: 5645.61,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Hotel, American, Iconic, Sleek, Amazing, Great, Relaxed',
        annual_dol: 5645.61,
        description:
            "Sleek rooms have flat-­screen TVs, Wi-Fi (fee) and desks, plus minifridges and coffeemakers; some add pull-out sofas and/or or city views. Suites offer living areas; some include balconies and/or kitchenettes. Club quarters provide access to a lounge with free breakfast and snacks.There's a relaxed American restaurant, a bar and a snack shop, as well as an indoor pool, a spa and a fitness center. Other amenities include 26 meeting rooms and a business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.642,
        store_longitude: -73.6414,
        total_orders: 450.76,
        neighborhood: '',
        keywords:
            'Steak, Burger, Salad, Cajun, Italian, French, Bourbon, French Onion Soup, Filet Mignon, American',
        annual_dol: 464.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6916,
        store_longitude: -73.988,
        total_orders: 1530.69,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Steak, Pizza, Vodka, Italian, Martini, American, Timeless, Classic, Traditional, Perfect',
        annual_dol: 2287.18,
        description:
            'Posh, historic restaurant with retro decor, serving oysters, steaks & chops, plus wines & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6995,
        store_longitude: -73.9922,
        total_orders: 740.16,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Wine, Salad, Burger, Coffee, Dessert, Ale, Cozy, American, Friendly, Local',
        annual_dol: 772.25,
        description:
            'A diverse by-the-glass & bottle list complements the American menu at this low-key spot',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6538,
        store_longitude: -73.6275,
        total_orders: 1484.5,
        neighborhood: '',
        keywords:
            'Steak, Garlic Bread, Salad, Cheesesteak, Italian, Wine, French, Russian, Philly Cheesesteak, American',
        annual_dol: 1548.87,
        description: '"Restaurant/Sports Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.9821,
        total_orders: 1702.48,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Salad, Wine, Shrimp Cocktail, Rustic, American, French, Stylish, Hotel, Trendy',
        annual_dol: 1738.6,
        description:
            'A diverse American menu & craft cocktails in a sleek space with communal tables & a long bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 10962.25,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Zombie, Salad, Beer, Dessert, Margarita, Wine, Irish, Fun, Friendly',
        annual_dol: 11194.85,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7792,
        store_longitude: -73.9834,
        total_orders: 1514.14,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Burger, Salad, Meatloaf, Coffee, Wine, Beer, Irish, Ale, Caesar Salad, French',
        annual_dol: 1550.41,
        description:
            'Unassuming Irish-style pub offering a traditional range of ales, spirits & homestyle plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.752,
        store_longitude: -74.0012,
        total_orders: 925.54,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Vegan, Pizza, Cheesesteak, Salad, Juicy, Rustic, Greek, Casual, Fresh',
        annual_dol: 945.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8463,
        store_longitude: -73.7863,
        total_orders: 147.05,
        neighborhood: 'City Island',
        keywords:
            'Salad, Lobster, Shrimp Cocktail, Canadian, Wine, Bourbon, Tasty, Organic, Caesar Salad, Latin',
        annual_dol: 156.49,
        description:
            'Restaurant with homestyle New American fare in a space with offbeat nautical decor & a back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7351,
        store_longitude: -73.9934,
        total_orders: 306.58,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Wine, Salad, Italian, Premium, Lively, Awesome, Mediterranean, Distilled, Global',
        annual_dol: 349.17,
        description:
            'Wine bar featuring unusual vintages paired with small plates, plus classes & tastings too.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -73.9923,
        total_orders: 120.44,
        neighborhood: 'NoHo',
        keywords:
            'Salad, Tasty, Upscale, Italian, Cuban, Glamorous, Asian, Vietnamese, Thai, Innovative',
        annual_dol: 168.73,
        description:
            'Upscale Asian-inspired Cuban food is served along with handcrafted cocktails in sumptuous surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7953,
        store_longitude: -73.9697,
        total_orders: 9828.97,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Sushi, Gluten Free, Tasty, Japanese, Spicy, Trendy, Cozy, Intimate, Fun, Katsu Curry',
        annual_dol: 11445.52,
        description:
            'Lively haunt covered in stickers & street art serving Japanese comfort food, sake & shochu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.593,
        store_longitude: -74.0707,
        total_orders: 143.98,
        neighborhood: 'Arrochar',
        keywords:
            'Elegant, Serene, Impeccable, Gracious, Warm, Perfect, Comfortable, Wonderful, Great',
        annual_dol: 588.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8253,
        store_longitude: -73.9509,
        total_orders: 300.01,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Beer, Vegan, Vodka, French Toast, Avocado Toast, Ale, French, Italian, Trendy',
        annual_dol: 670.07,
        description:
            'Chicken dishes served in a country-chic restaurant with a covered terrace & happy-hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9684,
        total_orders: 1280.73,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Spanish, Dessert, Beer, Delicious, Creamy, Upscale, Elegant, Sangria, Contemporary',
        annual_dol: 1307.91,
        description:
            'Paella takes center stage on the menu of this Spanish eatery that also serves tapas & wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7543,
        store_longitude: -73.9687,
        total_orders: 656.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Burger, Tasty, Wine, French, Beer, Delicious, Cozy, Quaint, American',
        annual_dol: 685.87,
        description:
            'Cozy French eatery offering brunch staples, escargot, seafood & meat plates, plus a large wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6195,
        store_longitude: -74.0281,
        total_orders: 1557.39,
        neighborhood: 'Fort Hamilton',
        keywords: 'Beer, American, Gourmet, Lively, Awesome, Great, Sangria, Casual',
        annual_dol: 5532.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9899,
        total_orders: 548.59,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Gourmet, Italian, Casual, American, Glamorous, Friendly',
        annual_dol: 560.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7097,
        store_longitude: -74.0146,
        total_orders: 361.74,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Burger, Relaxed, Clean, Modern, Authentic, Urban, Friendly, Iconic, Great',
        annual_dol: 425.55,
        description:
            "The relaxed rooms provide flat-screens, Wi-Fi (fee) and iPod docks, plus work desks, minifridges and coffeemakers. Upgraded rooms add river views; some offer access to a club lounge with free breakfast and appetizers. Suites add separate sitting areas and pull-out sofas.Amenities include a burger joint and a laid-back bar. There's also a fitness room and a business center, along with 12,000 sq ft of meeting space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7768,
        store_longitude: -73.9778,
        total_orders: 1266.14,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Wine, Burger, Cheesesteak, Coffee, Beer, Italian, American, Cozy, Rum, Spanish',
        annual_dol: 1297.24,
        description:
            'Sophisticated yet casual, comfy locale offering wines by the glass & tapas-style Italian bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7743,
        store_longitude: -73.9798,
        total_orders: 898.13,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lasagna, Vegan, Vegetarian, Pizza, Wine, Vodka, Salad, Italian, Dessert, Martini',
        annual_dol: 937.06,
        description:
            "Homey Italian-American trattoria owned by Lady Gaga's parents & cookbook author Art Smith.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -74.0011,
        total_orders: 1366.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Italian, Wine, Salad, Romantic, Classy, Filet Mignon, Homemade, Friendly, Traditional, Casual',
        annual_dol: 1395.09,
        description:
            'Longtime Italian joint serving up traditional red-sauce standards in an old-school setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7657,
        store_longitude: -73.9875,
        total_orders: 559.05,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tacos, Whiskey, Beer, Mexican, Salad, Rum, Fireball Whiskey, Friendly, Classy, Premium',
        annual_dol: 738.78,
        description:
            'Intimate, brick-walled hangout with a bar menu, pool table, karaoke, theme parties & trivia nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7779,
        store_longitude: -73.9804,
        total_orders: 3374.8,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Japanese, Scotch, Spicy, Bourbon, Spanish, Delicious, Cozy, American, Modern',
        annual_dol: 6139.35,
        description:
            'Modern spot with a pool table for tapas & raw oysters, plus clever cocktails, many whiskeys & sake.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.9899,
        total_orders: 277.84,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Italian, Beer, Thai, Cozy, American, Casual, Great',
        annual_dol: 1135.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9826,
        total_orders: 2927.99,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Salad, Dessert, Caesar Salad, Spicy, Elegant, Classic, Heritage, Fresh, Fine',
        annual_dol: 3012.86,
        description:
            'Outpost of upmarket steakhouse chain known for sizzling, butter-topped beef in an elegant setting',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8067,
        store_longitude: -73.9647,
        total_orders: 164.32,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Sushi, Pizza, Coffee, Cozy, Bright, Fresh, Legendary, Fantastic, Diverse',
        annual_dol: 182.82,
        description:
            "Alfred Lerner Hall is the student center or students' union of Columbia University. It is named for Al Lerner, who financed part of its construction.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9803,
        total_orders: 17.81,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Beer, Wine, American, Warm, Seasonal, Rooftop, Local, Casual, Chic, Great',
        annual_dol: 72.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8789,
        store_longitude: -73.8432,
        total_orders: 77.67,
        neighborhood: 'East Bronx/Laconia',
        keywords:
            'Tasty, Nutritious, African, Delicious, Nigerian, Ghanaian, Friendly, Fresh, Casual, Great',
        annual_dol: 317.48,
        description:
            '"Fresh and bursting African food with flavor is what EazyLife Restaurant & Lounge has to offer. Serving the Bronx, New York area, we provide great-tasting African, Nigerian & Ghanaian food with the highest quality ingredients. Treat yourself today with a nutritious meal from our delicious menu. At EazyLife African Restaurant & Lounge, you are sure to enjoy the great atmosphere, friendly service, and affordable prices."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.8045,
        total_orders: 81.85,
        neighborhood: 'Flushing/East Flushing',
        keywords:
            'Beer, Sweet, Friendly, Awesome, Colorful, Wonderful, Great, Cool, American, Excellent',
        annual_dol: 191.1,
        description: '"Quaint neighborhood bar. Pleasant staff and wonderful customers!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8649,
        store_longitude: -73.9271,
        total_orders: 5.4,
        neighborhood: 'Fort George',
        keywords:
            'Salad, Shrimp Cocktail, Dominican, Mediterranean, Spanish, Latin, American, Sweet, Friendly, Casual',
        annual_dol: 22.08,
        description: '"Available for take out and outdoor dining."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7456,
        store_longitude: -73.9985,
        total_orders: 1095.76,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Salad, Tequila, Bbq, Margarita, Beer, Californian, Pinacolada, Caesar Salad, French',
        annual_dol: 1119.01,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9989,
        total_orders: 433.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Mexican, Italian, Korean, Japanese, Asian, American, Hotel, Stylish, Casual',
        annual_dol: 1907.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7333,
        store_longitude: -73.9983,
        total_orders: 94.76,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Salad, Chicken Parmigiana, Italian, French Toast, Avocado Toast, French, Legendary, Perfect, Spectacular',
        annual_dol: 221.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7131,
        store_longitude: -73.9415,
        total_orders: 1226.83,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Burger, Whiskey, Fruity, Ale, Elegant, Spacious, Irish, Rustic, Awesome',
        annual_dol: 1271.43,
        description:
            'Rustic-chic haunt with hardwood floors & tin ceilings offers New York craft beer & choice pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.7285,
        total_orders: 190.55,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Italian, Golfclub, Elegant, Mediterranean, Picturesque, Gracious, Casual, Unique',
        annual_dol: 310.72,
        description:
            'Wedding & other special events in an 1866 mansion on an elegant estate by a golf course.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.588,
        store_longitude: -74.1903,
        total_orders: 106.29,
        neighborhood: 'Travis - Chelsea/Mid Island',
        keywords:
            'Steak, Hotel, Italian, Beer, Gourmet, American, Spacious, Casual, Friendly, Colorful',
        annual_dol: 162.82,
        description:
            "Island Zoo.Relaxed, colorful rooms, some with whirlpool tubs, feature free Wi-Fi and flat-screen TVs. Suites add separate living rooms.Complimentary amenities include parking, a breakfast buffet and a ferry terminal shuttle. There's also a casual restaurant/bar, a convenience store and a gym. Coin-operated laundry facilities are available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7093,
        store_longitude: -74.0094,
        total_orders: 30.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Pizza, Italian, Martini, Rustic, Delicious, Hotel, Clean, Picturesque',
        annual_dol: 125.14,
        description:
            'Craft beer, classic cocktails & American fare served in a swanky, industrial-chic tavern.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7114,
        store_longitude: -73.9478,
        total_orders: 1503.06,
        neighborhood: 'Williamsburg',
        keywords: 'Whiskey, Scotch, Wine, Beer, Bourbon, Rustic, Gourmet, Trendy, Upscale, Irish',
        annual_dol: 1743.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -73.9893,
        total_orders: 1051.2,
        neighborhood: 'Lower East Side',
        keywords:
            'Lasagna, Pizza, Vegetarian, Italian, Vodka, Wine, Flavorful, Meatball, Tasty, Rustic',
        annual_dol: 1982.8,
        description:
            'Red gravy & red meat are the focus at this rustic Southern Italian restaurant with butcher shop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8497,
        store_longitude: -73.917,
        total_orders: 27.1,
        neighborhood: 'Morris Heights/West Bronx',
        keywords: 'Cuban, Dominican, Spanish, Italian, American, Latin, Gyro',
        annual_dol: 110.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.735,
        store_longitude: -73.5974,
        total_orders: 2714.55,
        neighborhood: '',
        keywords:
            'Cuban, Beer, Coffee, American, Exotic, Traditional, Fun, Mouth-Watering, Sangria, Awesome',
        annual_dol: 3045.61,
        description:
            'Upbeat place offering traditional Cuban cuisine & drinks, plus live music & dancing on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7909,
        store_longitude: -73.9742,
        total_orders: 394.13,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Kebab, Beer, Spicy, Wine, Warm, Lebanese, Pungent, Mediterranean, Israeli, Moroccan',
        annual_dol: 957.02,
        description:
            'Elevated Mediterranean dishes are matched by wine & craft cocktails at this upmarket restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7879,
        store_longitude: -73.971,
        total_orders: 1771.3,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Mexican, Beer, Wine, Cuban, Delicious, Gin, Italian, Bbq, Swiss, Urban',
        annual_dol: 1808.88,
        description:
            'Urban sports pub offering Latin-inspired small plates & weekend brunch along with sangria & mojitos.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.786,
        store_longitude: -73.8372,
        total_orders: 5.21,
        neighborhood: 'College Point/Flushing',
        keywords:
            'Burger, Pizza, Salad, Beer, Delicious, Bbq, Mediterranean, Homemade, Greek, Clean',
        annual_dol: 21.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6602,
        store_longitude: -73.5419,
        total_orders: 144.45,
        neighborhood: '',
        keywords: 'Wine, Bourbon, Tasty, Ale, Italian, American, Vintage, Perfect, Great, Fresh',
        annual_dol: 272.12,
        description:
            'Wine flights, cocktails & light plates offered in intimate, modern surrounds with a loungey feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.673,
        store_longitude: -73.9833,
        total_orders: 3487.25,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Lobster Bisque, Bbq, Relaxed, Comfortable, Casual, Contemporary, Friendly, Seasonal, Classy',
        annual_dol: 3562.73,
        description:
            'Relaxed New American restaurant with a commitment to using seasonal, local ingredients.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6631,
        store_longitude: -73.9913,
        total_orders: 1465.76,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Milkshake, Sushi, Mexican, Salad, Enchiladas, Fajitas, Healthy, Casual, Colorful, Traditional',
        annual_dol: 1523.44,
        description:
            'Popular spot offering homestyle Mexican plates & margaritas in compact, colorful quarters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7071,
        store_longitude: -73.9325,
        total_orders: 740.45,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Wine, Fun, Ale, Cozy, Awesome, Great, Fine, Pulled Pork, Casual',
        annual_dol: 1251.13,
        description:
            '"Outdoor and indoor seating safely distanced with room for dancing,too!! Come on by and say hello. Grab a beer/shot/cocktail or one of our famous pulled pork sandwiches We’d love to see you!!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9565,
        total_orders: 1110.49,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Rich, Mulled Wine, Cozy, Gracious, Superb, Great, Tradition, Contemporary, Unforgettable',
        annual_dol: 1134.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.731,
        store_longitude: -73.9576,
        total_orders: 2074.18,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Steak, Tacos, Salad, Beer, Margarita, Mimosa, Vodka, Tasty, French',
        annual_dol: 2664.07,
        description:
            'Relaxed watering hole with classic interiors, fixing up small & large plates, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7662,
        store_longitude: -73.956,
        total_orders: 4582.95,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Wine, Whiskey, Beer, Cozy, Delicious, Gin, Karaoke, Intimate, Premium, Fun',
        annual_dol: 4680.19,
        description:
            'Intimate, wood-lined bar for craft cocktails, beer & whiskies, plus light snacks & weekly karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.9363,
        total_orders: 66.85,
        neighborhood: 'East Williamsburg',
        keywords: 'Tasty, Friendly, Asian, Cool',
        annual_dol: 273.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7037,
        store_longitude: -73.9905,
        total_orders: 143.61,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Steak, Salad, Wine, Bourbon, Beer, Avocado Toast, Italian, Fruity, Turkish, Organic',
        annual_dol: 234.18,
        description:
            'Bi-level restaurant executing a contemporary French menu in industrial-chic digs with a plant wall.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7646,
        store_longitude: -73.9781,
        total_orders: 7232.51,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Trendy, Stylish, American, Rooftop, Iconic, Premium, Vintage, Great, Perfect',
        annual_dol: 7546.09,
        description:
            "The chic rooms include free Wi-Fi, espresso machines and 40-inch flat-screen TVs, plus high-end linens and bathrooms with walk-in showers. Suites add sitting areas, workstations and floor-to-ceiling windows with city views.Amenities include an open-air rooftop lounge offering small plates and views of Central Park, along with a trendy street-level restaurant/bar serving New American cuisine and craft cocktails. There's also a 24-hour fitness room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9871,
        total_orders: 1618.76,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Tasty, Korean, Beer, Spicy, Pizza, Savory, Margarita, Vodka, Wine, Upscale',
        annual_dol: 1653.11,
        description:
            'Upscale creative Korean small plates, cocktails & an emphasis on organic/sustainable ingredients.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7196,
        store_longitude: -73.9872,
        total_orders: 2390.3,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Upscale, American, Classy, Classic, Casual, Local, Chill, Unpretentious, Great',
        annual_dol: 2493.93,
        description:
            'Unpretentious bar with beer from a fridge, a jukebox & a 1970s wood-paneled-basement vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8457,
        store_longitude: -73.9218,
        total_orders: 6.09,
        neighborhood: 'Morris Heights/West Bronx',
        keywords: '',
        annual_dol: 24.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7172,
        store_longitude: -73.9903,
        total_orders: 17.41,
        neighborhood: 'Lower East Side',
        keywords: 'Casual, Unique, Impressive',
        annual_dol: 71.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7442,
        store_longitude: -74.0028,
        total_orders: 1450.24,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Steak, Wine, Vodka, Stylish, American, Cozy, Lively, Whimsical, Radiant',
        annual_dol: 1481.01,
        description:
            'Chill eatery serving brunch staples & local fare with a European twist, plus craft beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.9855,
        total_orders: 1174.27,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Spicy, Chinese, Salad, Stylish, Asian, Sweet, Thai, Hip, Casual, Local',
        annual_dol: 2657.8,
        description:
            'Cozy, hip spot featuring Chinese dry pot with a choice of spice level, plus appetizers & dim sum.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6831,
        store_longitude: -73.9656,
        total_orders: 2099.96,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Vegetarian, Pizza, Salad, Coffee, Organic, Fried Chicken, Mediterranean, American, Cozy',
        annual_dol: 2512.54,
        description:
            'Cozy restaurant with a dining room & a covered sidewalk area focusing on local & organic produce.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6573,
        store_longitude: -73.6475,
        total_orders: 14673.04,
        neighborhood: '',
        keywords:
            'Cajun, Tasty, Jambalaya, Mexican, Italian, Juicy, Fried Clams, Fried Rice, Fun, Asian',
        annual_dol: 21817.52,
        description:
            'Laid-back seafood restaurant offering seafood specialties, a raw bar & Cajun boils, plus steaks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7431,
        store_longitude: -73.6179,
        total_orders: 337.5,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Meatball, Tequila, Margarita, Mediterranean, Caesar Salad, Delicious, Zesty',
        annual_dol: 352.13,
        description:
            'Casual chain serving charred-crust pies, plus wings, subs & Italian plates in a modern setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5787,
        store_longitude: -73.8497,
        total_orders: 112.17,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords:
            'Burger, Salad, Nachos, Wine, Irish, English Muffin, American, Classy, Awesome, Excellent',
        annual_dol: 127.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6431,
        store_longitude: -73.6657,
        total_orders: 375.32,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Mexican, Salad, Pizza, Beer, Peruvian, Colombian, Fajitas',
        annual_dol: 735.84,
        description:
            '"Del sur specializes in making our customers enjoy many different tastes of different countries of South America while also enjoying great Cocktails. Come and visit us for a different feel of latin food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.847,
        total_orders: 112614.72,
        neighborhood: 'Flushing/Corona',
        keywords:
            'Burger, Salad, Shrimp Cocktail, Spanish, Hotel, American, Picturesque, Sweet, Casual, Traditional',
        annual_dol: 140866.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7515,
        store_longitude: -73.9837,
        total_orders: 2177.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Trendy, Stylish, Martini, Tequila, Italian, Premium, Sleek, Gin, Sophisticated',
        annual_dol: 2667.59,
        description:
            "Featuring mahogany furnishings and safari-inspired decor, the chic rooms come with Wi-Fi access and smart TVs, as well as minibars, Keurig coffeemakers and safes. Room service is available.Amenities include a sophisticated restaurant, and a swanky rooftop area with 2 bars. There's also a 24-hour gym. Valet parking is offered for a fee.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.9844,
        total_orders: 250.31,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Chinese, Salad, Cuban, Wine, Beer, Avocado Toast, Swiss, Asian, American, Fusion',
        annual_dol: 257.11,
        description:
            'Cuban-Chinese fare & intricate cocktails in a space with whitewashed walls & distressed wood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8495,
        store_longitude: -73.7872,
        total_orders: 3455.25,
        neighborhood: 'City Island',
        keywords:
            'Steak, Lobster, Lobster Roll, Chicken Parmigiana, Chilean, Italian, Jambalaya, American, Delicious, Polished',
        annual_dol: 3648.28,
        description:
            "This veteran, laid-back local eatery provides old-school surf 'n' turf fare with Italian accents.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.9401,
        total_orders: 11187.71,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Sushi, Salad, Vegetarian, Wine, Japanese, Spicy, Ale, Trendy, Sweet, Greek',
        annual_dol: 11514.03,
        description:
            'Small, trendy Japanese place offering modern dishes & sushi in a lively atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7686,
        store_longitude: -73.8676,
        total_orders: 3992.02,
        neighborhood: 'Astoria/East Elmhurst/Flushing',
        keywords: 'Hotel, American, Stylish, Premium, Excellent, Terrific, Modern',
        annual_dol: 4011.78,
        description:
            'Modern rooms offer Wi-Fi (fee) and flat-screen TVs with premium channels, plus desks and coffeemakers. Some offer airport views, and upgraded rooms have access to a lounge with free hot breakfast and evening appetizers. Suites add separate living areas with sofas.Complimentary amenities include an airport shuttle and Wi-Fi in the lobby. The property offers 2 American restaurants and a 24/7 gym, as well as 26 meeting rooms and a business center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7369,
        store_longitude: -73.9876,
        total_orders: 4905.97,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Asian, Modern, Exotic, Indian, Colorful, Vibrant, Local, Chic, Unique, Fantastic',
        annual_dol: 5336.39,
        description:
            'Updated Indian cuisine & signature cocktails are offered in an exotic setting with dangling lights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -73.9913,
        total_orders: 633.74,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Amazing, Fantastic, Great, Perfect, Intimate',
        annual_dol: 738.7,
        description:
            'The Daryl Roth Theatre is an off-Broadway performance space at 101 East 15th Street, at the northeast corner of the intersection with Union Square East, near Union Square, Manhattan, New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7393,
        store_longitude: -73.885,
        total_orders: 202.28,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Perfect, Spectacular, Unforgettable, Unique',
        annual_dol: 826.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7265,
        store_longitude: -73.9868,
        total_orders: 88.08,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegetarian, Chicken Tikka Masala, Spicy, Delicious, American, Healthy, Premium, Clean, Authentic, Fresh',
        annual_dol: 360.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8009,
        store_longitude: -73.9663,
        total_orders: 3730.42,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Mexican, Pizza, Wine, Gin, Urban, Modern, Casual, Ethiopian',
        annual_dol: 7879.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8029,
        store_longitude: -73.9642,
        total_orders: 118.43,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Burger, Flavorful, Wine, Hawaiian, Spicy, Whiskey, Meatball, Elegant, Mediterranean, Cozy',
        annual_dol: 152.71,
        description:
            'Homey haunt for Mediterranean-style small plates & mains paired with a global wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8729,
        store_longitude: -73.8783,
        total_orders: 12.27,
        neighborhood: 'Norwood/West Bronx',
        keywords:
            'Tacos, Mexican, Steak, Margarita, Dominican, American, Spicy, Delicious, Local, Casual',
        annual_dol: 50.16,
        description: '"Mexican/Dominican restaurant bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.7711,
        total_orders: 502.54,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Pizza, Hamburger, Gluten Free, Italian, Beer, Bbq, Belgian, American, Fun',
        annual_dol: 524.33,
        description:
            'A variety of sandwiches & burgers can be paired with sides like fries & salads for a pub-style meal.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.9797,
        total_orders: 1371.63,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Upscale, Urban, Perfect, Fine, Local',
        annual_dol: 1431.1,
        description:
            'Upscale quarters with Ivy League styling, plus a health club, available for members & their guests.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7521,
        store_longitude: -73.9781,
        total_orders: 1228.99,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Cheesecake, Hamburger, Tasty, Wine, Champagne, Coffee, Delicious, Upscale, New-York-Style, Belgian',
        annual_dol: 1255.06,
        description:
            'Located across from Grand Central Terminal, this American bistro bustles day & night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.6604,
        total_orders: 260.9,
        neighborhood: '',
        keywords: 'Perfect',
        annual_dol: 1119.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7598,
        store_longitude: -73.969,
        total_orders: 4867.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Japanese, Meatball, Delicious, Tranquil, Casual, Fancy, Authentic, Recommended, Incredible',
        annual_dol: 6637.04,
        description:
            'Laid-back Japanese eatery offering yakitori & kushikatsu dishes in a tranquil dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7572,
        store_longitude: -73.9677,
        total_orders: 860.88,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Chinese, Peking Duck, Ale, Spicy, Asian, Contemporary, Hotel, American, Sweet',
        annual_dol: 3518.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9669,
        total_orders: 681.2,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Salad, Wine, Italian, Dessert, Hotel, Exquisite, Fun, Perfect, Classic',
        annual_dol: 695.66,
        description:
            'Dinner & Sunday brunch, drinks & a drag show for birthdays, bachelorettes or a festive night out.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9643,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Champagne, Hotel, Wine, Luxurious, Coffee, Cozy, Chic, Fantastic, Perfect, Wonderful',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7857,
        store_longitude: -73.728,
        total_orders: 878.24,
        neighborhood: '',
        keywords:
            'Japanese, Sushi, Innovative, Chinese, Thai, Asian, Polished, Fresh, Modern, Great',
        annual_dol: 921.88,
        description:
            'Pan-Asian eatery offering Chinese, Southeast Asian & fusion dishes in an atmospheric space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7405,
        store_longitude: -73.9847,
        total_orders: 573.69,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Eclectic, Coffee, Fun',
        annual_dol: 2014,
        description:
            'The Peoples Improv Theater, also known as the PIT, is a comedy theater and training center in New York City, founded by comedian Ali Farahnakian in 2002. Shows combine improvisational comedy, sketch comedy, stand-up, theater, and variety.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7874,
        store_longitude: -73.7285,
        total_orders: 339.73,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Vegetarian, Hamburger, Coffee, Organic, Beer, Delicious, Sustainable, American',
        annual_dol: 536.38,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7779,
        store_longitude: -73.9484,
        total_orders: 537.82,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Tasty, Wine, Martini, Delicious, Mexican, Fun, Italian, Hotel, Lively',
        annual_dol: 586.14,
        description:
            'Lively, brick-walled pub pairing draft beer with hearty fare, plus brunch, happy hours & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6912,
        store_longitude: -73.9598,
        total_orders: 167.34,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Korean, Coffee, Dessert, Japanese, Spicy, Chinese, Asian, Bbq, Modern, Bibimbap',
        annual_dol: 205.32,
        description:
            'Sleek, modern coffee shop upstairs & trendy, lounge-like Korean eatery downstairs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7284,
        store_longitude: -73.9994,
        total_orders: 3879.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Tasty, Salad, Beer, Wine, Martini, Delicious, German, Nachos, Warm',
        annual_dol: 3968.51,
        description:
            'Rock & blues bands perform nightly while soccer games on satellite TV hold forth at other times.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -73.9992,
        total_orders: 2349.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lobster, Beer, Tasty, Ale, Spicy, Upscale, Funky, Rustic, American, Sustainable',
        annual_dol: 2517.35,
        description:
            'Rustic-chic, casual hangout known for American craft beers & food served amid a funky tavern vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.9904,
        total_orders: 55.48,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Wine, Salad, Beer, Spicy, Fun, Perfect, Great, Creative, Sangria, Casual',
        annual_dol: 181.14,
        description: '"Billiards, pool hall, eatery and sports bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.779,
        store_longitude: -73.9829,
        total_orders: 1019.18,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Beer, Ale, Wine, Burger, Premium, American, Irish, Great, Natural',
        annual_dol: 1086.15,
        description:
            'Cavernous, low-key taproom known for its wide variety of craft beers, plus burgers & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9801,
        total_orders: 214.75,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Wine, Beer, Upscale, Hotel, Caesar Salad, American, Casual, Urban',
        annual_dol: 877.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7776,
        store_longitude: -73.9789,
        total_orders: 152.32,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Wine, Irish, Classic, Iconic, Natural, Great, Friendly, Casual',
        annual_dol: 155.55,
        description:
            'This unassuming Irish watering hole offers pints & pub staples like burgers & wings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.565,
        store_longitude: -74.1271,
        total_orders: 301.26,
        neighborhood: 'Richmond',
        keywords:
            'Pizza, Italian, Coffee, Wine, Dessert, Tasty, German, American, French, Eclectic',
        annual_dol: 314.32,
        description:
            'Family-owned neighborhood hub featuring homestyle American fare, including pizza, seafood & steaks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.6138,
        total_orders: 4776.14,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Salad, Hamburger, Shrimp Cocktail, Dessert, Shrimp Scampi, Italian, Roast Beef, Elegant',
        annual_dol: 4933.75,
        description:
            'This Victorian manor acts as a venue for weddings & events & also houses The Polo Lounge restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7883,
        store_longitude: -73.9485,
        total_orders: 74.21,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Wine, Beer, Gluten Free, Vegetarian, Trendy, Belgian, Dessert, Delicious, Cozy, Brazilian',
        annual_dol: 259.32,
        description: '"Bar Goyana - Brazilian Vibes, Belgian Beers & Cocktails"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9205,
        total_orders: 284.99,
        neighborhood: 'Bushwick',
        keywords: 'Spacious, Japanese, Beer, Amazing, Great, Cool, Hip, Casual',
        annual_dol: 340.44,
        description:
            '"Movie theater from the early 1900s, now a spacious bar with a second level mezzanine and 70s vibes. Pool tables, cheap beers, shots and cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -74.1041,
        total_orders: 3025.87,
        neighborhood: 'Todt Hill',
        keywords:
            'Wine, Upscale, Rich, Golfclub, Perfect, Traditional, Impressive, Fresh, Contemporary, Fine',
        annual_dol: 3171.24,
        description:
            'Richmond County Country Club is a private country club in Staten Island, New York. The club includes a golf course, tennis courts, an aquatic facility, and two clubhouses. The golf course and the main clubhouse have impressive views of New York Harbor, the Atlantic Ocean, and the New Jersey highlands.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7187,
        store_longitude: -73.9974,
        total_orders: 293.85,
        neighborhood: 'Little Italy',
        keywords:
            'Garlic Bread, Cheesecake, Lobster, Tasty, Wine, Tequila, Pizza, Vodka, Beer, Martini',
        annual_dol: 390.06,
        description:
            'Comfortable option featuring housemade pasta, plus shared plates, pizza & other Italian classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7237,
        store_longitude: -74.0044,
        total_orders: 9482.94,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Pizza, Dessert, Bourbon, Premium, Latin, Outstanding, Intimate, Filet Mignon, Great, American',
        annual_dol: 10280.66,
        description:
            'Candlelit art deco bar draws smokers for its variety of cigars & cigarettes, cocktails & snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7777,
        store_longitude: -73.9546,
        total_orders: 90.3,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Mexican, Tacos, Salad, Burritos, Organic, Delicious, Local, Casual, Great',
        annual_dol: 430.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7364,
        store_longitude: -74.0061,
        total_orders: 528.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Salad, Dessert, Mediterranean, Spicy, Organic, Rustic, Moroccan, Shawarma, Relaxed',
        annual_dol: 588.41,
        description:
            'Mediterranean tapas, Moroccan-style entrees & a full bar served in a cozy, relaxed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -74.0032,
        total_orders: 1364.43,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Coffee, Burger, Avocado Toast, Italian, Cozy, Mediterranean, French, Vintage, Indulgent',
        annual_dol: 1393.38,
        description:
            'Easygoing all-day cafe & bar offering classic & inventive eats, plus wines, beers & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7338,
        store_longitude: -74.0005,
        total_orders: 551.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, French, Wine, Delicious, Classy, Cozy, Romantic, Classic, Traditional',
        annual_dol: 577.34,
        description:
            'Parisian-style cafe serving comforting French fare in a snug space with an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6266,
        store_longitude: -74.1279,
        total_orders: 198.26,
        neighborhood: 'Port Richmond/Castleton Corners',
        keywords: 'American, Irish, Traditional, Local, Casual, Friendly',
        annual_dol: 225.81,
        description: '"Tavern"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7816,
        store_longitude: -73.9793,
        total_orders: 2011.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegetarian, Delicious, Stylish, Italian, Modern, American, Mediterranean, French, Fun, Fresh',
        annual_dol: 2053.79,
        description:
            'Diverse mix of modern Mediterranean dishes in a stylish brick-&-wood space with a 1st-floor bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8357,
        store_longitude: -73.7074,
        total_orders: 30.07,
        neighborhood: '',
        keywords: '',
        annual_dol: 113.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7494,
        store_longitude: -73.7078,
        total_orders: 648.58,
        neighborhood: 'Glen Oaks',
        keywords: 'Steak, Burger, Vodka, Wine, Beer, French, American, Comfortable, Casual, Classy',
        annual_dol: 686.17,
        description:
            'Classy bar & grill serving American comfort fare & cocktails in a relaxed, wood-trimmed space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6606,
        store_longitude: -73.9904,
        total_orders: 1049.09,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Salad, Dessert, Wine, Beer, Juicy, Vietnamese, Organic, Delicious, Asian',
        annual_dol: 1143.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6647,
        store_longitude: -73.9898,
        total_orders: 1922.04,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Beer, Wine, Italian, Organic, Irish, Relaxed, Vintage, Friendly, Fun, American',
        annual_dol: 1962.82,
        description:
            'Relaxed taproom featuring vintage video games, pool & board games, plus a jukebox with classic rock.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6697,
        store_longitude: -73.9861,
        total_orders: 36.09,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Salad, Crab Cakes, Coffee, Seafood Paella, Roast Beef, French Toast, Beer, Ale, Swiss',
        annual_dol: 147.53,
        description:
            'Diner with typical genre features like an extensive menu, free coffee refills & 24/7 availability.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6361,
        store_longitude: -73.74,
        total_orders: 1101.94,
        neighborhood: 'Jamaica/Rosedale',
        keywords: 'Steak, Salad, Bourbon, Thai, American, Casual, Classic, Local, Lively',
        annual_dol: 1273.74,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7108,
        store_longitude: -73.9366,
        total_orders: 251.28,
        neighborhood: 'East Williamsburg',
        keywords: 'Complex, Creative, Recommended, Casual',
        annual_dol: 498.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7744,
        store_longitude: -73.951,
        total_orders: 446.91,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Salad, Wine, Delicious, French, Cozy, Warm, Classy, Friendly, Welcoming',
        annual_dol: 1093.09,
        description:
            'Longtime Parisian-style bistro known for its classic French eats & grown-up ambiance.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7744,
        store_longitude: -73.8732,
        total_orders: 455.32,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'American, Golfclub, Premium, Local, Great, Signature',
        annual_dol: 1598.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7758,
        store_longitude: -73.9591,
        total_orders: 67.08,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Dessert, Wine, Delicious, Elegant, Memorable, Fine',
        annual_dol: 96.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6301,
        store_longitude: -74.1082,
        total_orders: 171.43,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Pizza, Italian, Salad, Beer, Delicious, Upscale, Bruschetta, Polished, Relaxed',
        annual_dol: 225.32,
        description:
            'Relaxed locale offering traditional pizza, salads, panini, pasta & Italian mains, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9123,
        total_orders: 31.81,
        neighborhood: 'Sunnyside/Woodside',
        keywords: 'Colombian, Casual, Classic, Lit, Recommended, Cultural, Great',
        annual_dol: 130.03,
        description:
            'Neon-lit nightspot with an upbeat vibe featuring music, salsa dancing & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7311,
        store_longitude: -73.6931,
        total_orders: 761.07,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Beer, Tasty, Wine, Bbq, Ale, Delicious, Trendy, Thai',
        annual_dol: 809.93,
        description: '"Bar/restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8918,
        store_longitude: -73.8975,
        total_orders: 1702.91,
        neighborhood: 'Fieldston/West Bronx',
        keywords:
            'Steak, Burger, Upscale, Flavorful, Dessert, American, Fine Quality, Classic, Perfect, Filet Mignon',
        annual_dol: 1951.17,
        description:
            'Easygoing operation with an upscale atmosphere serving classic grilled meat dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.9769,
        total_orders: 435.73,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Steak, Chocolate Mousse, Sushi, Dessert, French Onion Soup, French, Seasonal, Cozy, Casual',
        annual_dol: 454.62,
        description:
            "French onion soup stars on this longtime bistro's menu of quiche, crêpes & other classic fare.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7667,
        store_longitude: -73.9938,
        total_orders: 136.9,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Irish, Elegant, Wonderful, Fantastic, Contemporary, Cultural, Local',
        annual_dol: 581.96,
        description:
            'Institute showcasing Irish culture via musical & theatrical performances, films, exhibits & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9866,
        total_orders: 1352.71,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Wine, Gluten Free, Dessert, Italian, Delicious, Beer, Romantic, Classy, Friendly',
        annual_dol: 1422.79,
        description:
            'Creatively sauced house-made pastas & other Italian eats in a sleek space with garden seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7657,
        store_longitude: -73.9875,
        total_orders: 503.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Vegetarian, Vegan, Hamburger, Hotdog, Salad, Meatball, French Toast, Avocado Toast',
        annual_dol: 525.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7086,
        store_longitude: -73.8319,
        total_orders: 42.74,
        neighborhood: 'Kew Gardens/Jamaica',
        keywords: 'Pizza, Italian, Tasty, Beer, Ale, Peruvian, American, Classy, Lively, Caribbean',
        annual_dol: 174.69,
        description:
            '"Fine Italian cuisine, with a flavor of culture, a classy touch, and a lively vibe in Kew Gardens, New York. Instagram: @ckewgardens"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.8864,
        total_orders: 86.57,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Tequila, Mexican, Chinese, Italian, Casual, Fresh',
        annual_dol: 353.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6281,
        store_longitude: -73.5813,
        total_orders: 63.62,
        neighborhood: '',
        keywords: 'Modern, Fresh, Fine, Signature',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5889,
        store_longitude: -73.8121,
        total_orders: 2661.48,
        neighborhood: 'Hammels/Far Rockaway/Rockaway Beach',
        keywords: 'Burger, Tacos, Pizza, Sushi, Vegan, Mexican, Beer, Margarita, Quesadillas, Rum',
        annual_dol: 3530.98,
        description:
            'Chill, rustic joint accented with surfboards featuring tacos, quesadillas & cocktails, plus a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -74.0043,
        total_orders: 751.38,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Lobster, Lobster Roll, Shrimp Cocktail, Gluten Free, Coffee, Upscale, American, Trendy',
        annual_dol: 767.33,
        description:
            'Classic art deco diner relaunched with upgraded spins on American dishes, coffee & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9977,
        total_orders: 42.76,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Pizza, Salad, Korean, Donut, Spicy, Savory, Fried Chicken, Fried Rice, Asian',
        annual_dol: 174.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7879,
        store_longitude: -73.8126,
        total_orders: 436.62,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Pizza, Italian, Shrimp Cocktail, Bruschetta, American, Relaxed, Casual, Southern, Fresh, Local',
        annual_dol: 445.89,
        description:
            'Italian eatery featuring wood-fired pizzas & classic fare in a warm, relaxed atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.737,
        store_longitude: -73.9978,
        total_orders: 9.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Tequila, Shrimp Cocktail, Spanish, Salad, Wine, Martini, Daiquiri, Hotel, Upscale',
        annual_dol: 38.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6441,
        store_longitude: -74.0769,
        total_orders: 159.92,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Steak, Shrimp Cocktail, Wine, Delicious, Beer, Italian, Turkish, French, Premium, Spectacular',
        annual_dol: 201.35,
        description:
            'Classic chophouse with a long bar serving dry-aged steaks amid skyline murals & rooftop patio views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.984,
        total_orders: 637.9,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Salad, Kebab, Coffee, Dessert, Turkish, Mediterranean, Irish, American, Relaxed',
        annual_dol: 693.84,
        description:
            'Kebabs, grilled fish & other Mediterranean staples served in a relaxed pub with sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7483,
        store_longitude: -73.9735,
        total_orders: 2434.3,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Tasty, Wine, Beer, Dessert, Margarita, Comfortable, Spacious, Friendly, Bbq',
        annual_dol: 3682.61,
        description:
            'Brick walls set the scene for American grill fare & drinks, as does a homey, polished-plank garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7438,
        store_longitude: -73.986,
        total_orders: 3189.05,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Salad, Chilean, Canadian, Shrimp Scampi, Upscale, Juicy, American',
        annual_dol: 3268.61,
        description: 'Traditional steakhouse fare & Italian-accented dishes in an upscale setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7469,
        store_longitude: -73.9855,
        total_orders: 1759.63,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Korean, Bbq, Trendy, Upscale, Sleek, Hotel, Hip, Perfect, Vibrant, Casual',
        annual_dol: 6228.54,
        description: '"Modern Korean BBQ Restaurant in Manhattan, New York"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.9576,
        total_orders: 782.26,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Beer, Wine, Champagne, Awesome, Unpretentious, Great, Casual, Authentic',
        annual_dol: 816.17,
        description:
            'Buzzing, unpretentious watering hole offering drinks with free or $1 pizza, plus games & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7416,
        store_longitude: -73.9784,
        total_orders: 235.44,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Beer, Mexican, Wine, Irish, Ale, Fantastic, Traditional, Great, Casual',
        annual_dol: 444.66,
        description:
            'Traditional Irish pub for regular live Celtic music with Guinness & other rotating drafts on tap.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.717,
        store_longitude: -73.9634,
        total_orders: 9.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Coffee, Margarita, Mediterranean, Moroccan, Hot Toddy, American, Lively, Greek, Negroni',
        annual_dol: 39.99,
        description:
            '"Simple spot serving American fare with Middle Eastern influences plus classic cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7173,
        store_longitude: -73.8358,
        total_orders: 393.61,
        neighborhood: 'Elmhurst/Forest Hills/Flushing',
        keywords: 'Burger, Beer, Bbq, Cuban, American, Spacious, Global, Great, Casual',
        annual_dol: 961.46,
        description:
            'Spacious hangout serving American BBQ, dishes with global flavors & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7745,
        store_longitude: -73.8731,
        total_orders: 213.85,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Wine, Beer, Hotel, Golfclub, Warm, Premium, Classic, Superb, Global, Great',
        annual_dol: 294.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7406,
        store_longitude: -74.0083,
        total_orders: 15288.95,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Tomato Soup, Steak, Beer, Gluten Free, Wine, Vodka, Delicious, Trendy, Italian',
        annual_dol: 15618.03,
        description:
            'Unpretentious Meatpacking District pub with an extensive beer selection & a roof deck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8484,
        store_longitude: -73.8552,
        total_orders: 741.9,
        neighborhood: 'Morris Park',
        keywords:
            'Burger, Steak, Hamburger, Pizza, Cheesesteak, Beer, Shrimp Scampi, Cuban, American, Rum',
        annual_dol: 991.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7359,
        store_longitude: -74.0013,
        total_orders: 1293.39,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Salad, Tacos, Vegan, Peruvian, Wine, Vodka, Dessert, Mimosa, Pizza',
        annual_dol: 1418.27,
        description:
            'Bright, contemporary restaurant serving Latin American fusion dishes & signature drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7385,
        store_longitude: -74.0053,
        total_orders: 194.24,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Quaint, Hotel, Cool, Casual, Rooftop, Distinct',
        annual_dol: 272.12,
        description:
            '"A private hidden gem decorated with an astonishing collection of antiques and artifacts from all over the world. Available for weddings and rehearsal dinners, photo and film shoots, product launches, corporate events, as well as gatherings and celebrations of all kinds."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.6538,
        total_orders: 5.09,
        neighborhood: '',
        keywords:
            'Lobster, Wine, Vegetarian, Vodka, Delicious, Spanish, American, Sangria, Traditional, Casual',
        annual_dol: 20.81,
        description:
            'Warmly decorated eatery offering traditional Portuguese dishes, including lobster & grilled steak.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7805,
        store_longitude: -73.9764,
        total_orders: 357.22,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Italian, Meatball, Wine, Stylish, Hotel, Modern, Bright, Classic, Recommended',
        annual_dol: 500.44,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8414,
        store_longitude: -73.5933,
        total_orders: 1062.03,
        neighborhood: '',
        keywords: 'Golfclub, Bruschetta, Classic',
        annual_dol: 1177.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7059,
        store_longitude: -73.6584,
        total_orders: 15.75,
        neighborhood: '',
        keywords: 'Ramen, Sushi, Thai, Hawaiian, Japanese, Gyoza, Asian, Tempura, Local, Casual',
        annual_dol: 17.96,
        description: '"Ramen & Poke"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9985,
        total_orders: 42.62,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Innovative, Fun, Savory',
        annual_dol: 174.23,
        description:
            '"Scoozi Events NYC is a full-service innovative company that is rewriting the narrative of catering, all things events & engagement with the professionalism & creativity we have been known for... since 1996. Our cuisine is experiential, fun & globally inspired. We offer innovate solutions for catering, immersive events & virtual experiences! Our ambition & sole purpose of Scoozi Events is to create happiness, engagement & share culture! Choose Scoozi to plan the impossible and unimaginable!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6643,
        store_longitude: -73.9802,
        total_orders: 737.95,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Italian, Salad, Meatball, Wine, Dessert, American, Prosecco, Organic, Elegant',
        annual_dol: 1128.96,
        description:
            '"Artisanal pizza and cocktails in Park Slope, Brooklyn. One block away from Prospect Park with elegant indoor and outdoor dining options."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6748,
        store_longitude: -73.9758,
        total_orders: 277.87,
        neighborhood: 'Park Slope',
        keywords:
            'Salad, Flavorful, Thai, Coffee, Stylish, Beer, Delicious, Ramen, Homemade, Modern',
        annual_dol: 1135.84,
        description:
            'Modern takes on Thai classics presented in a quaint, stylish restaurant filled with indoor plants.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6858,
        store_longitude: -73.9388,
        total_orders: 1358.57,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Margarita, Vegetarian, Spanish, Warm, American, Intimate, Classic, Casual, Incredible, Distinct',
        annual_dol: 2542.94,
        description:
            '"Lucky’s is a warm & intimate neighborhood cocktail lounge that offers a distinct spirit program accompanied by full flavored tapas in Brooklyn, NY. Our vision is to enrich the lives we touch through genuine and detailed hospitality."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9584,
        total_orders: 1197.65,
        neighborhood: 'Greenpoint',
        keywords:
            'Pizza, Vegan, Italian, Apple Pie, Gourmet, Beer, Margarita, Ale, Wholesome, Cozy',
        annual_dol: 1249.58,
        description:
            'Gourmet pizzeria where the wood-fired pies are topped with unusual, locally sourced ingredients.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7706,
        store_longitude: -73.9598,
        total_orders: 1427.4,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Steak, Cornbread, Salad, Bbq, Tasty, Margarita, French, Homemade, Caesar Salad',
        annual_dol: 1457.69,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7697,
        store_longitude: -73.9579,
        total_orders: 181.69,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Wine, Dessert, Spicy, Sashimi, Japanese, Quaint, Fusion, Ramen, Teriyaki',
        annual_dol: 222.56,
        description:
            'Japanese restaurant with a tavern vibe & bar offering a mix of sushi, rolls & kitchen-cooked plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.9776,
        total_orders: 539.51,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lasagna, Salad, Italian, Wine, Delicious, Elegant, Warm, Martini, Polished, Succulent',
        annual_dol: 609.94,
        description:
            'Polished restaurant offering Italian cuisine, including pizza, pasta & classic desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -73.9868,
        total_orders: 3035.7,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Mexican, Shrimp Cocktail, Salad, Vodka, Champagne, Meatball, Spicy, Rustic, Hotel',
        annual_dol: 3380.05,
        description:
            'A pawn shop gives way to a happening restaurant & lounge serving creative American fare & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9023,
        store_longitude: -73.8515,
        total_orders: 50.55,
        neighborhood: 'East Bronx/Wakefield',
        keywords:
            'Lobster, Lobster Tail, Steak, Wine, Ribeye Steak, Caribbean, Indian, Casual, Classic, Local',
        annual_dol: 177.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7653,
        store_longitude: -73.9831,
        total_orders: 70.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Salad, Italian, Wine, Coffee, Delicious, Cozy, Romantic, Polished, Intimate',
        annual_dol: 101.09,
        description:
            "Cozy Italian veteran that's popular pre- & post- event-going at Carnegie Hall & City Center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8572,
        store_longitude: -73.9326,
        total_orders: 1555.45,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Burger, Salad, Cajun, Peruvian, Beer, South American, Wine, Spanish, American, Delicious',
        annual_dol: 1633.65,
        description:
            'Quaint urban hangout providing burgers & other sandwiches, plus wines by the glass & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8613,
        store_longitude: -73.9211,
        total_orders: 64.83,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords: 'Tacos, Pizza, Salad, Steak, Mexican, Margarita, Wine, Hawaiian, Bbq, Spanish',
        annual_dol: 186.74,
        description:
            'Mexican & Dominican dishes presented in a colorful, ornate venue with a full bar & patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -74.0019,
        total_orders: 345.51,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Lasagna, Salad, Wine, Italian, Dessert, Vodka, Beer, Delicious, Meatball, Warm',
        annual_dol: 434.66,
        description:
            'This petite Italian restaurant showcasing creative pasta dishes also offers beer, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.679,
        store_longitude: -73.9813,
        total_orders: 174.38,
        neighborhood: 'Park Slope',
        keywords:
            'Tasty, Vegan, Salad, Ethiopian, Classy, African, Relaxed, Awesome, Traditional, Classic',
        annual_dol: 194.31,
        description:
            'Relaxed place serving traditional Ethiopian dishes like meat or vegetable stews & injera bread.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -74.0051,
        total_orders: 521.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Hotel, Friendly, Casual, Hip, Mojito, Brazilian, Negroni',
        annual_dol: 2131.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5627,
        store_longitude: -74.1919,
        total_orders: 46.59,
        neighborhood: 'Arden Heights',
        keywords: 'Burger, Ale, American, Spicy, Casual, Local, Fresh, Recommended',
        annual_dol: 190.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.738,
        store_longitude: -73.998,
        total_orders: 224.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Wine, Vodka, Organic, Flavorful, Georgian, Traditional, Friendly, Casual, Sleek',
        annual_dol: 785.83,
        description:
            'Laid-back choice for Georgian staples like khachapuri paired with natural, organic wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6424,
        store_longitude: -74.075,
        total_orders: 293.03,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Steak, Lobster, Lobster Roll, Italian, Bbq, French, American, Traditional, Perfect, Casual',
        annual_dol: 381.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6815,
        store_longitude: -73.965,
        total_orders: 349.09,
        neighborhood: 'Clinton Hill',
        keywords: 'Pizza, Bbq, Trendy, Latin, Awesome, Local, Great, Casual',
        annual_dol: 1426.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7201,
        store_longitude: -73.961,
        total_orders: 5.92,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Coffee, Gin, Italian, Hotel, Tasty, Old Fashioned, Rum, Spicy',
        annual_dol: 24.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6991,
        store_longitude: -73.9166,
        total_orders: 1303.65,
        neighborhood: 'Bushwick',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Salad, Dessert, Chinese, Tasty, Spanish, Latin, Wonton Soup',
        annual_dol: 1697.3,
        description:
            'Tiny, booth-lined venue with an informal vibe, Chinese & Spanish food & colorful cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7102,
        store_longitude: -73.9336,
        total_orders: 48.36,
        neighborhood: 'East Williamsburg',
        keywords:
            'Beer, Cornbread, Bourbon, Rustic, Bbq, American, Thai, Polished, Casual, Traditional',
        annual_dol: 197.69,
        description:
            'North Carolina-style BBQ including smoked whole hog plus brown booze & craft beer in a huge space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7395,
        store_longitude: -74.0072,
        total_orders: 1112.95,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Dessert, Avocado Toast, Organic, Peruvian, Homemade, American, Gumbo, Seasonal, Hotel, Traditional',
        annual_dol: 4549.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.688,
        store_longitude: -73.5954,
        total_orders: 12.45,
        neighborhood: '',
        keywords: 'Casual, Local, Irish',
        annual_dol: 33.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8413,
        store_longitude: -73.8427,
        total_orders: 45.34,
        neighborhood: 'Westchester Square',
        keywords: 'Beer, Jamaican, Caribbean, Local, Wonderful, Authentic, Great, Casual',
        annual_dol: 185.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6574,
        store_longitude: -73.5477,
        total_orders: 377.56,
        neighborhood: '',
        keywords:
            'Kebab, Vodka, Gluten Free, Turkish, Delicious, Mediterranean, Cozy, Intimate, Healthy, Authentic',
        annual_dol: 410.88,
        description:
            '"We love bringing our community together over delectable and healthy Persian flavors."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7722,
        store_longitude: -73.9607,
        total_orders: 2061.4,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Mimosa, Martini, Spanish, Delicious, American, Bellini',
        annual_dol: 3307.92,
        description:
            'New American bistro with a vintage copper marquee & a menu stressing oysters, burgers & martinis.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -73.9328,
        total_orders: 1530.64,
        neighborhood: 'East Williamsburg',
        keywords:
            'Wine, Cinnamon Roll, Delicious, Warm, Beer, Italian, Cozy, Inviting, Sweet, Eclectic',
        annual_dol: 1632.39,
        description:
            'Eatery in a former tailor shop serving an eclectic menu paired with cocktails, wine & local brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6448,
        store_longitude: -73.9214,
        total_orders: 272.76,
        neighborhood: 'East Flatbush',
        keywords:
            'Salad, Jamaican, Caribbean, Bbq, Beer, Legendary, Phenomenal, Local, Casual, Signature',
        annual_dol: 660.06,
        description:
            'Caribbean-inspired cuisine with signature Rasta Pasta, plus a full bar & entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7362,
        store_longitude: -73.9874,
        total_orders: 1306.64,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Upscale, Bourbon, Whiskey, Gin, Martini, Salad, Delicious, Romantic, Hotel, Spicy',
        annual_dol: 1441.09,
        description:
            'Cocktail parlor with fancy drinks & elevated bites in an upscale space with a time-traveling theme.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.8622,
        total_orders: 1216.49,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Lobster, Lobster Roll, Steak, Cajun, Tasty, Margarita, Beer, Delicious, Mojito, Casual',
        annual_dol: 2507.2,
        description:
            'Cajun-style seafood boils whipped up in a casual, brick-lined dining room with booths & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7975,
        store_longitude: -73.968,
        total_orders: 80.94,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Mexican, Steak, Nachos, Beer, Margarita, Savory, Spicy, Delicious, Local, Casual',
        annual_dol: 189.28,
        description:
            'Traditional Mexican dishes plus beer & tequila-spiked drinks served in a no-frills setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8623,
        store_longitude: -73.9113,
        total_orders: 1831.13,
        neighborhood: 'Fordham Manor/West Bronx',
        keywords:
            'Steak, Salad, Wine, Seafood Paella, Margarita, Tasty, Beer, Upscale, Spicy, Spanish',
        annual_dol: 2108.71,
        description:
            'Splashy restaurant & lounge offering Latin bites, specialty drinks, entertainment & salsa dancing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.973,
        total_orders: 1283.74,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, German, Spacious, Perfect, Lively, Clean',
        annual_dol: 5247.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9733,
        total_orders: 1379.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Salad, Upscale, Ale, American, Daiquiri, Bruschetta, Vintage, Gracious',
        annual_dol: 4862.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7584,
        store_longitude: -73.9707,
        total_orders: 2550.64,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Sushi, Salad, Wine, Italian, Margarita, Spicy, Delicious, American, Polished',
        annual_dol: 2604.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9713,
        total_orders: 720.05,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Scotch, Whiskey, Wine, Bourbon, Hotel, Dutch',
        annual_dol: 767.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.88,
        store_longitude: -73.5964,
        total_orders: 30.2,
        neighborhood: '',
        keywords:
            'Steak, Pizza, Italian, Delicious, Quaint, Comfortable, Fajitas, American, Casual, Great',
        annual_dol: 123.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6799,
        store_longitude: -73.9427,
        total_orders: 745.15,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Vegetarian, Vietnamese, Fun, Ale, Premium, Friendly, Awesome, Thai, Asian, Great',
        annual_dol: 890.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6932,
        store_longitude: -74.0012,
        total_orders: 856.95,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Steak, Burger, Salad, American, Mediterranean, Awesome, Amazing, Fantastic, Great, Wonderful',
        annual_dol: 1048.87,
        description:
            'Scenic spot at ONE°15 Brooklyn Marina serving French-inspired plates with local ingredients.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7,
        store_longitude: -73.9939,
        total_orders: 419.49,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Burger, Wine, Beer, Vodka, Dessert, Martini, Gin, Upscale, Stylish, Italian',
        annual_dol: 1714.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7841,
        store_longitude: -73.9504,
        total_orders: 409.83,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Steak, Vegetarian, Cheesesteak, Salad, Organic, Swiss, Philly Cheesesteak, Banana Split, American',
        annual_dol: 528.92,
        description:
            "Brunches, a children's menu & a doggy drinking fountain bring the crowds to this American eatery.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5279,
        store_longitude: -74.2395,
        total_orders: 90.59,
        neighborhood: 'Charleston',
        keywords:
            'Lobster, Shrimp Cocktail, Lobster Bisque, Chicken Parmigiana, Salad, Cajun, Italian, Beer, Vodka, Tasty',
        annual_dol: 102.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.9938,
        total_orders: 594.32,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Wine, Beer, Spacious, Comfortable, Clean, Hotel, Premium, Awesome, Innovative, Contemporary',
        annual_dol: 1832.54,
        description:
            '"Experience the difference of AMC amenities. From spacious rocking seats to luxury recliners, innovative menus and premium offerings like IMAX, Dolby Cinema, and Prime at AMC, AMC Theatres offers a range of ways to get more out of movies."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -74.0002,
        total_orders: 1244.92,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tequila, Whiskey, Vodka, Mexican, Tacos, Gin, Hotel, American, Latin, Irish',
        annual_dol: 1304.52,
        description:
            '"Cocktail lounge offering fun & sophisticated drinks, also serving Mexican street fair."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7262,
        store_longitude: -74.0003,
        total_orders: 777.71,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Salad, Champagne, Mimosa, Italian, Cosmopolitan, Tuna Melt Sandwich, Beer, Mediterranean, French, Prosecco',
        annual_dol: 1121.11,
        description:
            'Smoked fish, housemade bagels & other appetizing fare offered in a buzzy, vintage-style spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.661,
        store_longitude: -73.9611,
        total_orders: 213.17,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Burger, Tacos, Mexican, Salad, Nachos, Beer, Burritos, Quesadillas, Ale, Enchiladas',
        annual_dol: 871.36,
        description:
            'A variety of burritos, fajitas & other Mexican staples offered in an open, relaxed eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6667,
        store_longitude: -73.9878,
        total_orders: 42.63,
        neighborhood: 'Park Slope',
        keywords: 'Hotdog, Beer, Wine, Ale, Irish, American, Upscale, Friendly, Casual, Fantastic',
        annual_dol: 72.04,
        description:
            'Irish pub & game-day hangout offering cocktails, draft beers & darts in low-key, compact quarters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.95,
        total_orders: 5502.07,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Tequila, Mexican, Beer, Pizza, Vegetarian, Margarita, Tasty, Dessert, Spicy, Friendly',
        annual_dol: 12905.95,
        description:
            'Mexican fare, brunch & cocktails are served in this hip, industrial-chic spot that has a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7347,
        store_longitude: -73.9879,
        total_orders: 1247.57,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Beer, Tasty, Wine, Margarita, Delicious, Tangy, Buffalo Wings, Irish, Bbq',
        annual_dol: 1394.87,
        description:
            'Pub offering an American/Irish bar menu (plus weekend brunch), Guinness on tap & sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7244,
        store_longitude: -73.9915,
        total_orders: 1950.22,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Steak, Burger, Lasagna, Whiskey, Shrimp Cocktail, Wine, Upscale, Champagne, Premium, American',
        annual_dol: 2034.77,
        description:
            'Steakhouse with classic cuts in modern preparations plus pasta & more in a retro-styled setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7388,
        store_longitude: -73.9885,
        total_orders: 849.34,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Salad, Vodka, Beef Stroganoff, Wine, Dessert, Russian, Tasty, Beer, Georgian, Delicious',
        annual_dol: 886.17,
        description:
            'Old-fashioned Russian menu served in a sitting room-style space with books, lace, photos & curios.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7304,
        store_longitude: -73.9841,
        total_orders: 29.3,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Flavorful, Cajun, Coffee, Spicy, Vietnamese, Creole, Casual, Intimate, Fun',
        annual_dol: 119.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -73.9904,
        total_orders: 142.93,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Mexican, Beer, Bourbon, Dessert, Spicy, Italian, American, Delicious, Sweet, Casual',
        annual_dol: 584.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8035,
        store_longitude: -73.9637,
        total_orders: 203.99,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Italian, Wine, Coffee, Dessert, Tasty, Pizza, Classy, Homemade, Casual, Omelette',
        annual_dol: 279.49,
        description:
            'Relaxed, brick-lined standby pairing pasta, panini & other casual Italian plates with wine & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5495,
        store_longitude: -74.1506,
        total_orders: 43.68,
        neighborhood: 'Great Kills',
        keywords: 'Elegant, Luxurious, Perfect, Clean, Relaxed, Outstanding, Friendly, Great',
        annual_dol: 178.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.9798,
        total_orders: 947.89,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Legendary, Iconic, Classic, Excellent, Irish, Fancy, Cool, Casual',
        annual_dol: 3874.67,
        description: '"Cocktail bar in a storied Rockefeller Center townhouse"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.9622,
        total_orders: 330.98,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords: 'Sushi, Wine, Tasty, Chinese, Japanese, Delicious, Thai, Hotel, Asian, French',
        annual_dol: 353.98,
        description:
            'Asian-fusion fare such as sushi, Thai curries & stir-fries, plus drinks, served in a modern space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7629,
        store_longitude: -73.9714,
        total_orders: 1406.02,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Steak, Chicken Caesar Salad, Italian, Dessert, Gluten Free, Meatball, Wine, Homemade, Caesar Salad',
        annual_dol: 1546.79,
        description:
            'Upscale, industrial-chic Italian eatery with a downstairs nightclub featuring DJs & bottle service.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7026,
        store_longitude: -73.9889,
        total_orders: 2078.62,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Burger, Lasagna, Pizza, Italian, Meatball, Wine, Delicious, Stylish, Warm, Friendly',
        annual_dol: 2107,
        description:
            'Stylish restaurant serving contemporary Italian food using local & imported Italian produce.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.683,
        store_longitude: -73.9407,
        total_orders: 4585.89,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tequila, Whiskey, Wine, Beer, Trendy, Delicious, Robust, Intimate, Awesome, Great',
        annual_dol: 14779.98,
        description:
            'Seasonal mixed drinks & microbrews served in a dark, narrow bar with patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7397,
        store_longitude: -73.9888,
        total_orders: 1057.09,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Burger, Salad, Italian, Bread Pudding, Roast Beef, Meatball, Chilean, Wine, Martini, Organic',
        annual_dol: 1126.55,
        description:
            'Longtime eatery serving a New American menu along with numerous pasta dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6919,
        store_longitude: -73.9861,
        total_orders: 2578.56,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Pizza, Beer, Innovative, Sweet, Great, Excellent, Recommended, Local, Traditional, Casual',
        annual_dol: 2635.01,
        description:
            'Brewery serving its own pints plus Neapolitan pizzas & other plates in a polished, sizable space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6945,
        store_longitude: -73.986,
        total_orders: 170.25,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Tacos, Mexican, Salad, Burritos, Warm, Delicious, Healthy, Casual, Fresh, Local',
        annual_dol: 695.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -73.9852,
        total_orders: 69.61,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Mexican, Salad, Delicious, Hotel, Healthy, Local, Casual, Western, Great',
        annual_dol: 376.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -73.9984,
        total_orders: 486.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Spicy, Vegan, Vegetarian, Delicious, Savory, Beer, Chinese, Eclectic, Creamy, Cozy',
        annual_dol: 1634.17,
        description:
            'Cozy eatery with eclectic decor serving up plant-based Chinese Szechuan plates in a casual setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -74.006,
        total_orders: 1208.22,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Vegetarian, Vegan, Kebab, Tasty, Gluten Free, Italian, Spicy, Delicious, Stylish',
        annual_dol: 1260.61,
        description:
            'Chelsea Market branch of a global chain for Mediterranean stuffed pitas with a local twist.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7703,
        store_longitude: -73.9849,
        total_orders: 318.06,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'American, Perfect, Great',
        annual_dol: 353.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7427,
        store_longitude: -73.9543,
        total_orders: 280.2,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Wine, Beer, Irish, French, Friendly, Casual, American, Awesome, Great, British',
        annual_dol: 1055.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -73.8698,
        total_orders: 2638.29,
        neighborhood: 'Rego Park/Middle Village/Flushing',
        keywords:
            'Steak, Salad, Tomato Soup, Wine, Italian, Cozy, Classy, Caesar Salad, Fabulous, Modern',
        annual_dol: 2698.72,
        description:
            "Red-sauce Italian fare served in warm, modern digs with a big bar, kids' menu & free valet parking.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9736,
        total_orders: 529.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Pizza, Vegan, Italian, Apple Pie, Coffee, Mediterranean, Delicious, Stylish, Spacious',
        annual_dol: 552.4,
        description:
            'Northern Italian restaurant offering Venetian specialties & more for dinner & lunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8244,
        store_longitude: -73.9523,
        total_orders: 528.6,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Bourbon, Trendy, Modern, American, Classic, Crisp, Traditional, Scottish, Casual, Cool',
        annual_dol: 628.57,
        description:
            '"The Vale is the safe space in the neighborhood where the customer’s comfort can be achieved at any level. Authentically Scottish, with full and half pint pours, cocktails, comfort food and social snugs."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6719,
        store_longitude: -73.8428,
        total_orders: 533.83,
        neighborhood: 'Ozone Park',
        keywords: 'Delicious, Casual, Local',
        annual_dol: 733.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9704,
        total_orders: 649.19,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Tequila, Modern, Crisp, Vibrant, Authentic, Fiery, Great, Fine, Recommended',
        annual_dol: 3276.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9907,
        total_orders: 1627.8,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Tequila, Salad, Beer, Wine, Margarita, Nachos, Spicy, Warm, American',
        annual_dol: 1666.44,
        description:
            'Charming tavern with exposed brick walls, serving elevated comfort eats & signature cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6916,
        store_longitude: -73.9577,
        total_orders: 2717.37,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Burger, Tasty, Ale, Wine, American, Latin, Friendly, Retro, Southern',
        annual_dol: 3073.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6876,
        store_longitude: -73.99,
        total_orders: 3040.04,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Burger, Salad, French, Spicy, Mediterranean, Polished, Bloody Mary, American, Perfect, Vibrant',
        annual_dol: 3144.58,
        description:
            'Everyday bar in an intimate setting serving up lunch, dinner & brunch on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6897,
        store_longitude: -73.9717,
        total_orders: 527.41,
        neighborhood: 'Fort Greene',
        keywords:
            'Pizza, Italian, Coffee, Upscale, African, Comfortable, American, South African, Classy, Refreshing',
        annual_dol: 2155.89,
        description:
            '"Milanese-born Brooklyn restaurateur, Edoardo Mantelli, has long admired the Viennese-style cafes of fin-de-siècle Milan and the cultural influence they had on his native city. In Saraghina Caffé, he’s lovingly recreated the sense of grace and grandeur found in these beloved institutions, where heated exchanges about the origins of the Negroni were cooled by a refreshing Spritz (Campari, per favore, not Aperol). More than places to stop for a drink, these caffè were cultural hearts of their neighborhoods, where poets and artists would find their next inspiration while nibbling on well-aged prosciutto di Parma."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9876,
        total_orders: 395.85,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Burger, Salad, Beer, New-York-Style, Wine, Caesar Salad, Local, Recommended, Casual',
        annual_dol: 436.12,
        description:
            '"Serving Detroit-style pizzas and an award-winning burger. Full service bar, local drafts & wine selection."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9911,
        total_orders: 84.61,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Garlic Bread, Lobster, Pizza, Salad, Italian, Beer, Delicious, Margarita, Ale, Warm',
        annual_dol: 106.44,
        description:
            'Coal-fired Neapolitan pizza & Italian entrees feature at this quaint spot with sidewalk tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.686,
        store_longitude: -73.9943,
        total_orders: 17.57,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Pizza, Italian, Shrimp Cocktail, Meatball, Wine, French, Retro, Yummy, Local, Casual',
        annual_dol: 28.57,
        description:
            'Old-school red-sauce joint draws a local crowd with pizza & other Italian basics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9806,
        total_orders: 751.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Tequila, Whiskey, Beer, Wine, Vodka, Margarita, Gin, Tasty, Rum, Spicy',
        annual_dol: 1271.62,
        description:
            'Popular after-work bar that pays homage to the 1991 surfer action film that shares its name.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7085,
        store_longitude: -74.0141,
        total_orders: 214.68,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Vegetarian, Salad, Cajun, Shrimp Scampi, Dessert, French Onion Soup, Bbq, French',
        annual_dol: 771.91,
        description:
            'Ornate, wood-paneled eatery set in an 1812 former church & serving both pub grub & Chinese classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7769,
        store_longitude: -73.9794,
        total_orders: 636.48,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Italian, Beer, French Toast, French, Delicious, American, Natural, Omelette, Casual',
        annual_dol: 2234.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9873,
        total_orders: 2093.75,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Korean, Italian, Savory, Bbq, Innovative, Sweet, Memorable, Amazing, Perfect, Modern',
        annual_dol: 3014.09,
        description:
            'Offshoot of a long-standing Seoul restaurant specializing in Korean BBQ, dishes & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7453,
        store_longitude: -73.9931,
        total_orders: 421.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Sushi, Wine, Cozy, Hotel, Delicious, Italian, Intimate, French, American',
        annual_dol: 1029.9,
        description:
            "\"Enjoy dinner at the show at Chelsea Table + Stage - New York's newest hotspot for intimate dining and music. Experience some of the best touring performers in the world featured alongside emerging artists. Ever-changing culinary and entertainment lineups mean you'll never have the same night twice. The menu features American fare, curated wine, and specialty cocktails. Visit our space on the main level for post-show drinks or a cozy weeknight dinner. Experience the Fashion District's destination for the ultimate NYC night out. Check out our calendar for what's coming up at Chelsea Table + Stage.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -74.0161,
        total_orders: 1399.27,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Lobster, Lobster Roll, Steak, Salad, Mexican, Wine, Italian, Beer, Delicious, Trendy',
        annual_dol: 1522.76,
        description:
            'Relaxed eatery serving sustainable seafood plus beer, wine & cocktails in bright, beachy digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7275,
        store_longitude: -74.0006,
        total_orders: 2901.21,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Tasty, Wine, Salad, Beer, Upscale, Ale, Irish, Nachos, Fantastic',
        annual_dol: 3096.28,
        description:
            'A full menu of pub grub & lengthy beer list are complemented by sports on TV & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.9975,
        total_orders: 21.51,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Salad, Margarita, Champagne, Wine, Tasty, Prosecco, Whiskey',
        annual_dol: 87.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9766,
        total_orders: 1361.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Wine, Italian, Upscale, French Toast, Delicious, Romantic, French, Refined, Classy',
        annual_dol: 1515.55,
        description:
            'Refined versions of Italian comfort food served in a renovated townhouse with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.9889,
        total_orders: 498.05,
        neighborhood: 'Lower East Side',
        keywords: 'Vodka, Tasty, Beer, Delicious, Cool, Casual, Great',
        annual_dol: 2035.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6767,
        store_longitude: -73.9803,
        total_orders: 92.36,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Salad, Mexican, Italian, Gourmet, Upscale, Spicy, Rustic, American, Cozy',
        annual_dol: 377.53,
        description:
            '"American Italian locale, serving wood-fire pizzas, pastas, small plates, craft cocktails and much more. Family Friendly. Kitchen Open till 11pm and Pizza Open till Midnight."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7408,
        store_longitude: -74.0005,
        total_orders: 29.56,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Cheesecake, Chocolate Mousse, Vegetarian, Margarita, Spanish, Wine, Delicious, Cosmopolitan, Cozy',
        annual_dol: 40.24,
        description:
            'Long-standing option for Spanish tapas, paella & more served in a cozy cafe with an old-world feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -73.9845,
        total_orders: 351.89,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Warm, Fun, Vintage, Classic, Great, Iconic, Creative, Fine, Casual',
        annual_dol: 359.35,
        description:
            'Dark, unassuming hangout boasting a jukebox, billiards & drink specials with a laid-back vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7227,
        store_longitude: -73.9862,
        total_orders: 2817.16,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Vegan, Beer, Tomato Soup, Organic, Warm, Hotel, Friendly, Classic, Cool, Casual',
        annual_dol: 2876.94,
        description:
            'Longtime barroom with cheap drinks, crowd-pleasing jukebox & B movies projected on the walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7115,
        store_longitude: -73.9464,
        total_orders: 2219.03,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Beer, Wine, Stylish, Vintage, Irish, Clean, Sleek, Classic, Casual, Seasonal',
        annual_dol: 2459.35,
        description:
            '"Classically styled bar, restaurant and event space. Great for events, live performances, dinner, drinks and weddings. Seasonal cocktail menus and great food options."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9829,
        total_orders: 3783.08,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Beer, Classic, Colorful, Great, Aesthetic, Local, Casual',
        annual_dol: 3863.35,
        description:
            'Texas roadhouse-style bar in the East Village with biker bartenders & country music on the jukebox.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -73.9515,
        total_orders: 1898.96,
        neighborhood: 'Williamsburg',
        keywords: 'Whiskey, Beer, Cozy, Casual, Chill, Lit, Great',
        annual_dol: 1898.96,
        description:
            'A laid-back, dimly lit watering hole with cozy interior decor & a big backyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -74.0024,
        total_orders: 2629.45,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Beer, Ale, Cajun, Upscale, American, Nachos, Irish, Classic, Fresh, Great',
        annual_dol: 2746.97,
        description:
            'Beer comes in huge Styrofoam cups at this long-standing barroom with bras hanging from the ceiling.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.7487,
        total_orders: 36.98,
        neighborhood: 'Douglaston/Flushing/Little Neck',
        keywords: 'Salad, Spacious, Friendly, Fun, Clean, Golfclub, Casual',
        annual_dol: 139.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.717,
        store_longitude: -73.9567,
        total_orders: 57.73,
        neighborhood: 'Williamsburg',
        keywords:
            'Italian, Pizza, Wine, Delicious, Romantic, Bruschetta, Great, Authentic, Satisfying, Excellent',
        annual_dol: 134.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -73.9612,
        total_orders: 107.18,
        neighborhood: 'Williamsburg',
        keywords:
            'Mexican, Enchiladas, Premium, Relaxed, Vibrant, Caribbean, Sweet, Lively, Latin, Awesome',
        annual_dol: 438.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7483,
        store_longitude: -73.9846,
        total_orders: 130.27,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Casual, Wholesome',
        annual_dol: 532.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.723,
        store_longitude: -73.5755,
        total_orders: 190.54,
        neighborhood: '',
        keywords:
            'Lobster, Pizza, Shrimp Cocktail, Italian, Meatball, Wine, Homemade, Local, Friendly, Classic',
        annual_dol: 207.66,
        description:
            'Longtime local (since 1955) serving pizzas & pastas in a brick-walled space with old-world murals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8248,
        store_longitude: -73.8202,
        total_orders: 143.56,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Burger, Steak, Beer, Spicy, Wine, Nachos, Legendary, American, Awesome, Perfect',
        annual_dol: 586.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6858,
        store_longitude: -73.8284,
        total_orders: 27.64,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords:
            'Vegetarian, Chinese, French, Casual, Authentic, American, Latin, Local, Caribbean, Fresh',
        annual_dol: 112.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7962,
        store_longitude: -73.9351,
        total_orders: 118.61,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Hamburger, Beer, Wine, American, Japanese, Latin, Elegant, Dope, Fusion, Classic',
        annual_dol: 276.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6829,
        store_longitude: -73.9352,
        total_orders: 136.77,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords: 'Burger, Steak, Salad, Pizza, Vegan, Flavorful, Mimosa, Tasty, Wine, Delicious',
        annual_dol: 223.26,
        description:
            'Pan-Latin food & drinks are featured at this brick-walled eatery & bar with a back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6432,
        store_longitude: -73.7903,
        total_orders: 918.35,
        neighborhood: 'Jamaica',
        keywords:
            'Burger, Pizza, Hotel, American, Western, Friendly, Sweet, Great, Heritage, Excellent',
        annual_dol: 1453,
        description:
            'Botanical Garden.Unpretentious rooms feature complimentary Wi-Fi, iPod docks and flat-screen TVs, in addition to minifridges, coffeemakers and safes. Upgraded rooms can sleep up to 4 guests.Continental breakfast is included.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5444,
        store_longitude: -74.1406,
        total_orders: 482.82,
        neighborhood: 'Great Kills',
        keywords:
            'Burger, Chicken Caesar Salad, Salad, French Onion Soup, French, Caesar Salad, Great, Friendly',
        annual_dol: 549.9,
        description:
            '"Richmond County Yacht Club is a private, members only club. That said, we\'re a friendly group with many sail and power boaters. We\'re always interested in bringing new members into our fun, family organization. Stop by, and explore all that we have to offer the Staten Island boater."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8477,
        store_longitude: -73.9314,
        total_orders: 153.38,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Hotel, Legendary, Colorful, Vibrant, Fun, Great, Dominican, Cultural, Contemporary, Distinctive',
        annual_dol: 626.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8034,
        store_longitude: -73.9638,
        total_orders: 1958.03,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Pizza, Beer, Chinese, Romantic, Fun, Awesome, Friendly, Dope, Great, Lit',
        annual_dol: 2046.15,
        description:
            'Dimly-lit barroom attracting college crowd & locals with budget-priced drinks, darts & pool.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7534,
        store_longitude: -73.9663,
        total_orders: 6843.63,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Rooftop, Magical, Delicious, Fantastic, Iconic, Wonderful, Great, Excellent, Classic, Friendly',
        annual_dol: 6988.84,
        description:
            'Rooftop lounge at the Beekman Tower sporting plush banquettes, cocktails & East River views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8874,
        store_longitude: -73.907,
        total_orders: 1011.2,
        neighborhood: 'Fieldston/Riverdale/West Bronx',
        keywords: 'Mexican, Pizza, Italian, Mediterranean, Artisanal, Casual, Great',
        annual_dol: 4133.47,
        description: '"Artisanal Oaxacan Inspired Mezcal. Food. Culture NOW OPEN 🪅"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.7716,
        total_orders: 279.22,
        neighborhood: 'Bayside',
        keywords: 'Thai, Salad, Coffee, Asian, Spicy, Pad Thai, Friendly, Casual, Tom Yum, Local',
        annual_dol: 350.97,
        description:
            'Thai standards, such as curries & noodle dishes, served in a casual space with a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.7706,
        total_orders: 169.32,
        neighborhood: 'Bayside',
        keywords:
            'Vegetarian, Kebab, Chicken Tikka Masala, Southern, Indian, Casual, Friendly, Traditional, Retro, Classy',
        annual_dol: 200.05,
        description:
            'Colorful Indian restaurant & bar with a retro interior providing elevated entrees & a tapas menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7148,
        store_longitude: -74.0096,
        total_orders: 1679.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Burger, Wine, Savory, Trendy, Organic, Sustainable, Hotel, Comfortable, Polished',
        annual_dol: 6955.66,
        description:
            'Posh restaurant in the Smyth Hotel serving seasonal New American small & large plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -74.0076,
        total_orders: 649.21,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Chocolate Cake, Pizza, Vodka, Wine, Chicken Parmigiana, Italian, Upscale, Delicious, Meatball',
        annual_dol: 707.32,
        description:
            'Airy place serving classic & modern Italian shared plates, plus brick-oven pizzas & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7572,
        store_longitude: -73.9686,
        total_orders: 1766.55,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Salad, Upscale, Champagne, Warm, Hotel, Classic, American, Iconic, Great',
        annual_dol: 1897.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7031,
        store_longitude: -73.9915,
        total_orders: 459.84,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Tacos, Lobster, Wine, Beer, Dessert, Italian, Sustainable, Relaxed, Perfect, Sweet',
        annual_dol: 496.01,
        description:
            'Relaxed eatery serving sustainable seafood plus beer, wine & cocktails in bright, beachy digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6265,
        store_longitude: -74.1583,
        total_orders: 195.62,
        neighborhood: 'Mariners Harbor',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Salad, Spanish, Italian, Wine, Peruvian, Chinese, Dominican',
        annual_dol: 204.11,
        description:
            'Old-school standby offering a traditional Spanish menu with emphasis on lobster, shrimp & paella.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9893,
        total_orders: 6503.75,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Beer, American, Premium, Funky, Eclectic, Classy, Fantastic, Great, Casual',
        annual_dol: 6902.11,
        description:
            'Even the servers sing at this multipurpose club with a piano bar, 2 showrooms & an American eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9901,
        total_orders: 9069.39,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Tasty, Tuna Steak, Beer, Fruity, Rustic, Delicious, Friendly, Perfect',
        annual_dol: 10669.95,
        description:
            'Hip outpost with eclectic decor & low lighting serving creative pub fare with full bar & happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6804,
        store_longitude: -73.9529,
        total_orders: 579.24,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'American, Nachos, Classic, Sweet, Amazing, Great, Incredible, Casual, Chill',
        annual_dol: 648.59,
        description:
            'Chill gastropub with exposed brick walls offering cocktails & beer, plus nachos & other light fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9906,
        total_orders: 45.94,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Korean, Italian, Wine, Champagne, Gourmet, Asian, Casual, Sustainable, Local',
        annual_dol: 86.77,
        description:
            'Urbane restaurant with an open kitchen specializing in gourmet Korean hand rolls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9884,
        total_orders: 1645.65,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Lobster, Lobster Roll, Salad, Gluten Free, Beer, French Onion Soup, French, Quaint, Modern',
        annual_dol: 1693.91,
        description:
            'Two-story quaint, modern eatery turning out elevated American & international fare, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.753,
        store_longitude: -73.997,
        total_orders: 6156.01,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Tequila, Beer, Wine, Italian, Spicy, Gin, Mediterranean, Hotel, Warm',
        annual_dol: 23193.85,
        description:
            "\"Moynihan Food Hall features a curated selection of the city's best food and full-service beer, wine and cocktail outlet for guests with a one-track mind. Tempt your taste buds by exploring the different dining options Moynihan Food Hall has to offer. Grab a bite or a cocktail before heading out to MSG, pair a meal with your travel plans or grab a drink and take the edge off of the commute! An array of dining options are available: THE BAR, Alidoro, Birch Coffee, Burger Joint, Blue Bottle Coffee, Chopt, Davey's Ice Cream, E.A.K. Ramen, H&H Bagels, Jacob's Beer Biscuits Pickles, La Maison Du Chocolat, Magnolia Bakery, Maman, Naya, Pressed, Sauce Pizzeria, Starbucks, Threes Brewing, Vesuvio Bakery and Yo Yo Sushi.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7524,
        store_longitude: -73.9914,
        total_orders: 4590.51,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Steak, Cheesesteak, Salad, Wine, Tasty, Beer, Upscale, Delicious, Trendy, Gin',
        annual_dol: 4591.97,
        description:
            'Upscale pub serving modern bar fare in a space with a front bar and back dining area.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7461,
        store_longitude: -74.0007,
        total_orders: 234.54,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Hamburger, Salad, Italian, Dessert, French, Casual, Coleslaw, Fine, Great',
        annual_dol: 249.6,
        description:
            'Big portions of typical diner fare served around the clock power this night-owl mainstay.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7684,
        store_longitude: -73.9817,
        total_orders: 2439.06,
        neighborhood: '',
        keywords:
            'Vegan, Vegetarian, Sushi, Gluten Free, Avocado Toast, French, Hotel, Friendly, Casual, Asian',
        annual_dol: 2781.15,
        description:
            'Sophisticated New French eatery boasting floor-to-ceiling windows overlooking Central Park.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6325,
        store_longitude: -73.9478,
        total_orders: 1041.86,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Burger, Shrimp Cocktail, Sushi, Pizza, Bbq, Margarita, American, Homemade, Creamy, Coleslaw',
        annual_dol: 1214.42,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -74.0009,
        total_orders: 6641.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Steak, Beer, Wine, French, American, Hotel, Vintage, Classic, Friendly',
        annual_dol: 7782.07,
        description:
            'Celeb-magnet hot-ticket tavern with upper-end French bistro fare & reimagined vintage decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -74.0033,
        total_orders: 3779.09,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Salad, Cosmopolitan, Elegant, Bourbon, Warm, Mediterranean, Friendly, Authentic, Cool',
        annual_dol: 4103.77,
        description:
            'Elegant outpost of the Midtown original, with creative Mediterranean fare & private outdoor dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -73.9982,
        total_orders: 171.57,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Steak, Tequila, Cuban, Cheesesteak, Margarita, Seafood Paella, Wine, Spicy, Delicious',
        annual_dol: 182.85,
        description:
            'Traditional dishes & drinks from Brazil & Cuba served in an intimate room with tropical decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6661,
        store_longitude: -73.9565,
        total_orders: 271.13,
        neighborhood: 'Crown Heights',
        keywords: 'Mexican, Bbq, Caribbean, American, Canadian, Local, Traditional, Casual, Great',
        annual_dol: 301.88,
        description:
            'This established, casual cafe serves both traditional Caribbean & standard American cuisines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7252,
        store_longitude: -74.0022,
        total_orders: 401.45,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Wine, Italian, Delicious, Warm, Refined, Mediterranean, Friendly, Perfect, Authentic, Exceptional',
        annual_dol: 427.22,
        description:
            "Elegant, contemporary restaurant specializing in refined fare from Italy's Piedmont region.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.8221,
        total_orders: 1.95,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Burger, Vegetarian, Mexican, Coffee, Wine, Dessert, American, Delicious, Casual, Sangria',
        annual_dol: 15.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8487,
        store_longitude: -73.7872,
        total_orders: 21091.44,
        neighborhood: 'City Island',
        keywords:
            'Steak, Lobster, Salad, Shrimp Cocktail, Cajun, Italian, Tasty, Spicy, Delicious, Fried Calamari',
        annual_dol: 22642.75,
        description:
            'Go-to stalwart for ample seafood plates plus Italian fare in low-key digs with a nautical motif.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7128,
        store_longitude: -74.0165,
        total_orders: 341.38,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Lobster, Lobster Roll, Burger, Champagne, Wine, Delicious, Beer, Coffee, Romantic, Exquisite',
        annual_dol: 596.41,
        description:
            "\"MESSAGE US at 212.619.6900 Manhattan By Sail - Shearwater Classic Schooner is a New York City cruise company, providing Manhattan boat cruises of the iconic New York harbor. We offer unique sightseeing tours of New York aboard our authentic 1929 schooner, the Shearwater! If you're looking for a unique event venue, you'll find it hard to beat the atmosphere aboard our beautiful ship! Looking to round out a trip to the Big Apple? One of our NYC day cruises may be just what you're looking for! Evening cruises can make for a romantic evening, or the perfect setting for an intimate business meeting. We offer dinner cruises, private charters and NYC sightseeing cruises to fit any budget!\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5236,
        store_longitude: -74.2391,
        total_orders: 213.72,
        neighborhood: 'Charleston',
        keywords: 'Delicious, Cozy, Sweet, Fabulous, Perfect, Awesome, Chic, Modern',
        annual_dol: 873.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9803,
        total_orders: 989.66,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Greek, Upscale, Vivid, Spectacular, Mediterranean, Great, Chic, Fine',
        annual_dol: 4045.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5836,
        store_longitude: -73.9397,
        total_orders: 144.82,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Steak, Hamburger, Pizza, Salad, Chicken Parmigiana, Shrimp Cocktail, Italian, Gluten Free, Mediterranean',
        annual_dol: 416.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8241,
        store_longitude: -73.9518,
        total_orders: 47.67,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Sushi, Lobster, Cinnamon Roll, Spicy, Japanese, Fried Rice, Sashimi, Puerto Rican, Caribbean, Fusion',
        annual_dol: 194.85,
        description:
            'Easygoing standby offering sashimi & an array of creative sushi rolls, plus familiar Asian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8292,
        store_longitude: -73.9481,
        total_orders: 342.4,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Steak, Salad, Mimosa, Beer, Caribbean, Caesar Salad, American, Sweet, Bloody Mary',
        annual_dol: 478.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6301,
        store_longitude: -74.0226,
        total_orders: 1509.88,
        neighborhood: 'Bay Ridge',
        keywords:
            'Vegetarian, Salad, Pizza, Italian, Shrimp Cocktail, Wine, Delicious, Cozy, Comfortable, Classy',
        annual_dol: 1541.92,
        description:
            'Circa-1964 Bay Ridge Italian restaurant serving red-sauce pasta dishes & other standards.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9619,
        total_orders: 2932.53,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords: 'Wine, Beer, Ale, Cozy, Vintage, Friendly, Welcoming, Vibrant, Casual, American',
        annual_dol: 2994.76,
        description:
            'Cozy neighborhood bar with a vintage vibe & a selection of beer, wine & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8756,
        store_longitude: -73.5975,
        total_orders: 379.49,
        neighborhood: '',
        keywords: 'Burger, Salad, Vodka, Roast Turkey, Coffee, Dessert, Rum, Bbq, Latin, American',
        annual_dol: 417.92,
        description:
            'Bustling pub dishing up popular burgers & other American eats in a stablelike locale with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7589,
        store_longitude: -73.9627,
        total_orders: 160.19,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Kebab, Vegetarian, Salad, Pizza, Coffee, Flavorsome, Lebanese, Mediterranean, Shawarma, Hotel',
        annual_dol: 720.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9689,
        total_orders: 94.95,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Flavorful, Wine, Egyptian, Exquisite, Premium, Mediterranean, Nachos, Awesome, Amazing, American',
        annual_dol: 550.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6313,
        store_longitude: -74.0277,
        total_orders: 490.19,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Salad, Chicken Pot Pie, Italian, Nachos, Bbq, Ale, Organic, American, Cozy',
        annual_dol: 925.78,
        description:
            'Sports bar/restaurant in a renovated fire station that morphs into a dance club on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9917,
        total_orders: 6283.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotdog, Beer, Ale, American, Thai, Legendary, Hip, Local, Casual, Great',
        annual_dol: 6416.83,
        description:
            'A neighborhood staple since 1933, this tavern is known for its no-frills atmosphere & free hot dogs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6846,
        store_longitude: -73.9921,
        total_orders: 3444.23,
        neighborhood: 'Carroll Gardens/Brooklyn Heights/Cobble Hill',
        keywords: 'Steak, Burger, Salad, Wine, Coffee, Rich, Elegant, Spicy, Italian, Gin',
        annual_dol: 3596.79,
        description:
            'A long roster of classic concoctions as well as some modern twists accompany a small plates menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.644,
        store_longitude: -73.6393,
        total_orders: 341.3,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Pizza, Italian, Wine, Organic, Impeccable, Clean, Casual, Caesar Salad',
        annual_dol: 421.42,
        description:
            '"Clean Italian Eating is what we are all about, at Tutti Noi. We pride ourselves on sourcing the freshest quality ingredients, and preparing entrees using time honored recipes and methods. Our sauces and entrees are made from scratch, and we promise you\'ll taste the difference when you dine with us. From our table to yours, we welcome you to join All of Us, at Tutti Noi!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6703,
        store_longitude: -73.9506,
        total_orders: 389.79,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Delicious, Salad, Beer, Cozy, French Toast, Sweet, French, Creole, Bbq',
        annual_dol: 530.62,
        description:
            'Easygoing, brick-lined hangout serving sandwiches & tacos, plus weekend brunch & unique cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7433,
        store_longitude: -73.9885,
        total_orders: 386.25,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Mexican, Beer, Wine, Trendy, Korean, Japanese, Spicy, Ramen, Asian, Modern',
        annual_dol: 498.08,
        description:
            'Asian-inspired & Japanese chain restaurant where dishes are whisked to long communal tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7009,
        store_longitude: -73.9892,
        total_orders: 87.15,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Coffee, Beer, Wine, Gluten Free, Tasty, Avocado Toast, Australian, Delicious, Healthy, Premium',
        annual_dol: 286.41,
        description:
            '"Bluestone Lane is an Australian-inspired coffee shop, cafés & lifestyle brand committed to providing a genuine daily escape for all our locals. We offer premium coffee and healthy and delicious eats through an always welcoming experience that embraces our ‘Aussie’ approach to life."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7611,
        store_longitude: -73.9905,
        total_orders: 78.07,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Tasty, Thai, Spicy, Green Curry, Polished, Sweet, Fusion, Western, Casual',
        annual_dol: 319.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7832,
        store_longitude: -73.9531,
        total_orders: 106.81,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Wine, Italian, Beer, Upscale, Rustic, Seasonal, Cozy, Delicious, Elegant, Quaint',
        annual_dol: 113.82,
        description:
            'High-end Italian fare in a chandelier-lit dining room across from the 92nd Street Y.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5634,
        store_longitude: -73.9154,
        total_orders: 57.6,
        neighborhood: 'Breezy Point/Rockaway Point',
        keywords:
            'Burger, Lobster, Salad, Beer, French, Caesar Salad, Hotel, Asian, American, Latin',
        annual_dol: 139.69,
        description:
            'American fare & cocktails served in an unfussy bayside setting with nautical accents & deck seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7138,
        store_longitude: -73.9151,
        total_orders: 10.82,
        neighborhood: 'Flushing/Ridgewood/Maspeth',
        keywords:
            'Steak, Burger, Hamburger, Salad, Dessert, American, Grilled Salmon, Delicious, French, French Onion Soup',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9887,
        total_orders: 6338.47,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Steak, Burger, Hamburger, Salad, Pizza, Vodka, Dessert, Beer, Spicy, Delicious',
        annual_dol: 6472.96,
        description: '"We are open for indoor and outdoor dining, delivery, and pick-up daily!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8427,
        store_longitude: -73.8859,
        total_orders: 28.7,
        neighborhood: 'Tremont/West Bronx',
        keywords:
            'Steak, Salad, Hamburger, Beer, Coffee, Spanish, Dominican, Puerto Rican, Latin, American',
        annual_dol: 70.44,
        description:
            'Relaxed dining spot featuring familiar Latin American & American meat & seafood dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -74.0016,
        total_orders: 1263.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Cheesecake, Salad, Beer, Dessert, Funky, American',
        annual_dol: 1374.25,
        description:
            'A comfy atmosphere attracts a laid-back neighborhood crowd to this pub famed for burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7209,
        store_longitude: -73.8434,
        total_orders: 1071.6,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Cornbread, Bbq, Chicken Wings, Cuban, Beer, American, Spicy, Spanish, French',
        annual_dol: 1258.26,
        description:
            'Hip counter-serve eatery featuring a menu of BBQ classics, plus pomme frites & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.775,
        store_longitude: -73.9536,
        total_orders: 1623.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Sushi, Salad, Ramen, Dessert, Flavorsome, Japanese, Spicy, Organic, Asian, Homemade',
        annual_dol: 1657.92,
        description:
            'Hip Japanese place for ramen & izakaya-style small plates with an adjoining cocktail & dessert bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7357,
        store_longitude: -74.0058,
        total_orders: 2663.62,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Salad, Italian, Martini, American, French, Cozy, Eclectic, Seasonal, Delicious',
        annual_dol: 2777.55,
        description:
            'Neighborhood spot with an old-school feel serving updated takes on NYC dining staples.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8379,
        store_longitude: -73.835,
        total_orders: 0,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords: 'Lobster, Steak, Sushi, Mexican, Delicious, Bbq, Thai, Sweet, Casual, Local',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7861,
        store_longitude: -73.9726,
        total_orders: 554.77,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Coffee, Organic, French Toast, French, American, Rum, Sweet, Sangria, Traditional',
        annual_dol: 573.27,
        description:
            'Locally sourced ingredients feature in traditional American comfort foods & house-baked treats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8506,
        store_longitude: -73.8677,
        total_orders: 59.89,
        neighborhood: 'Van Nest',
        keywords: 'Casual, Great',
        annual_dol: 244.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7091,
        store_longitude: -73.907,
        total_orders: 623.93,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Wine, Cozy, Local, Casual, Great',
        annual_dol: 650.98,
        description:
            'This no-nonsense hangout with nautical decor offers a full bar with tropical drinks & a pool table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7084,
        store_longitude: -73.6825,
        total_orders: 4885.05,
        neighborhood: '',
        keywords:
            'German, Burger, Beer, French, Hungarian, Polished, Asian, Casual, Traditional, Authentic',
        annual_dol: 19968.46,
        description:
            'Authentic German cuisine, live music & festivals draw crowds to this historic eatery & beer garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.655,
        store_longitude: -73.9531,
        total_orders: 79.05,
        neighborhood: 'Prospect Lefferts Gardens/Little Haiti',
        keywords:
            'Vegetarian, Hamburger, Pizza, Korean, Wine, Delicious, Bourbon, American, Israeli, Clean',
        annual_dol: 143.66,
        description:
            'Laid-back watering hole with sports on TV & a kosher lineup of burgers, rice bowls & poutine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6542,
        store_longitude: -73.953,
        total_orders: 143.62,
        neighborhood: 'Little Haiti',
        keywords: 'Beer, Zombie, Rum, Delicious, Eclectic, Fun, Fusion, Yummy, Brazilian, Colorful',
        annual_dol: 242.67,
        description:
            'Funky, convivial bar with a courtyard garden offering unique cocktails, draft beer & finger foods.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -73.9517,
        total_orders: 803.53,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Burger, Mexican, Salad, Beer, Margarita, Nachos, Casual, Hip, Cool, Great',
        annual_dol: 2820.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6853,
        store_longitude: -73.9947,
        total_orders: 16.59,
        neighborhood: 'Cobble Hill',
        keywords:
            'Burger, Steak, Vegetarian, Meatball, Tasty, Mediterranean, Modern, Cozy, Perfect, Russian',
        annual_dol: 18.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6133,
        store_longitude: -74.0653,
        total_orders: 528.48,
        neighborhood: 'Rosebank',
        keywords:
            'Tequila, Wine, Whiskey, Italian, Dessert, Donut, Savory, Meatball, Eclectic, American',
        annual_dol: 989.24,
        description:
            'Eclectic New American cuisine is offered at this Michelin-rated bistro with cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8523,
        store_longitude: -73.9348,
        total_orders: 20.28,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Cheesecake, Burger, Chocolate Mousse, Coffee, Modern, Dessert, Beer, Dominican, American, Spanish',
        annual_dol: 82.89,
        description: '"Something nice will be added here"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.831,
        total_orders: 593.03,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Hotel, Coffee, Chinese, Stylish, Asian, Modern, Korean, Contemporary, Traditional, Rooftop',
        annual_dol: 708.41,
        description:
            'The stylish rooms with Asian-inspired decor have free WiFi, plus flat-screens, and work areas with desks and ergonomic chairs. They also have minifridges and coffeemakers. Club rooms provide access to a lounge with perks such as evening appetizers. Kids stay at no charge with an adult.An airport shuttle is complimentary. Amenities include a restaurant/bar with an Asian-fusion menu, a lobby lounge, and a rooftop terrace, as well as a gym. There are 14 meeting rooms and a 24/7 business center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8863,
        store_longitude: -73.9096,
        total_orders: 681.19,
        neighborhood: 'Riverdale/West Bronx',
        keywords:
            'Pizza, Italian, Gluten Free, Meatball, Dessert, Wine, Premium, Clean, Friendly, Fresh',
        annual_dol: 695.65,
        description:
            'New York–style pizzas & other Italian comfort fare served in a down-to-earth eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.973,
        total_orders: 18.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Polished, American, Classic, Casual, Great, Artistic',
        annual_dol: 73.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7126,
        store_longitude: -74.0082,
        total_orders: 3985.57,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Coffee, Tasty, Beer, Trendy, Martini, Delicious, Fun, Old Fashioned, Friendly',
        annual_dol: 4490.56,
        description:
            'Hip, vintage-inspired coffee & cocktail spot with a marble bar & vaulted ceilings in historic digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9704,
        total_orders: 2369.01,
        neighborhood: 'Lenox Hill',
        keywords:
            'Vegetarian, Chinese, Chilean, Flavorful, Glamorous, Asian, Luxurious, Premium, Legendary, Contemporary',
        annual_dol: 2896.72,
        description:
            'Sophisticated Beijing style cuisine near Fifth & Madison Avenues buzzing with a glamorous clientele.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6954,
        store_longitude: -73.9839,
        total_orders: 477.68,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Hotel, Stylish, Spacious, Great, Friendly, Rooftop',
        annual_dol: 556.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9807,
        total_orders: 4530.74,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Wine, Upscale, Whiskey, Beer, Ale, South American, Classy, American, Eclectic, Irish',
        annual_dol: 5412.22,
        description:
            'Vintage-style restaurant & bar offering comfort food, cocktails & an extensive whisky list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6893,
        store_longitude: -73.9689,
        total_orders: 1575.89,
        neighborhood: 'Clinton Hill',
        keywords:
            'Salad, Vegetarian, Tacos, Pizza, Coffee, Vietnamese, Delicious, Papaya Salad, Casual, Authentic',
        annual_dol: 1794.83,
        description:
            'Quaint, photo-lined cafe turning out Vietnamese classics & signature cocktails. Delivery available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.761,
        store_longitude: -73.9873,
        total_orders: 82.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Flavorful, Healthy, Juicy, Friendly, Brazilian, Traditional, Authentic, Casual',
        annual_dol: 376.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6845,
        store_longitude: -73.9502,
        total_orders: 309.61,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vodka, Beer, Gin, Rum, Coffee, Salad, Cozy, Awesome, Great, Friendly',
        annual_dol: 1265.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7087,
        store_longitude: -74.014,
        total_orders: 667.83,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Beer, Wine, Austrian, Organic, Mediterranean, Spicy, Homemade, Irish',
        annual_dol: 709.88,
        description: 'Austrian-Mediterranean food in a space with communal & banquette seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7493,
        store_longitude: -73.9842,
        total_orders: 666.05,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Champagne, Karaoke, Vodka, Beer, Martini, Cheesesteak, Gin, Steak, Rum, Vintage',
        annual_dol: 776.97,
        description:
            'Hip karaoke lounge with space-age decor, featuring private rooms, bar snacks, beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7985,
        store_longitude: -73.5963,
        total_orders: 1083.48,
        neighborhood: '',
        keywords:
            'Burger, Salad, Golfclub, Caesar Wrap, Gracious, Warm, Picturesque, Spectacular, Awesome, Memorable',
        annual_dol: 1397.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.724,
        store_longitude: -74.0031,
        total_orders: 3389.12,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Upscale, Trendy, Hotel, Stylish, Vodka, Coffee, Legendary, Italian, Premium, Sleek',
        annual_dol: 3597.28,
        description:
            "Center, an iconic skyscraper with an observation deck.Featuring Harland Miller artwork, high-thread-count sheets and marble bathrooms, the posh, warmly decorated rooms come with flat-screen TVs and minibars; some have sofas. Airy suites provide free Wi-Fi, living rooms and balconies; 1 adds a rooftop terrace and a fireplace.A haute French restaurant has a terrace. There's also a sophisticated bar with a fireplace. A seasonal rooftop bar offers city views.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.998,
        total_orders: 2774.3,
        neighborhood: 'Nolita',
        keywords:
            'Mexican, Steak, Tequila, Vegetarian, Gluten Free, American, Rich, Latin, Authentic, Friendly',
        annual_dol: 3172.72,
        description:
            'Sceney Mexican spot with no-frills taqueria, cafe & exclusive basement brasserie/tequila bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7483,
        store_longitude: -73.9555,
        total_orders: 19557.25,
        neighborhood: 'LIC/Hunters Point',
        keywords: 'Mexican, Burger, Gourmet, Peruvian, Latin, Hotel, Tasty, American, Modern, Fine',
        annual_dol: 20032.15,
        description:
            'Modern restaurant & lounge featuring cocktails, gourmet Latin-inspired eats & expansive water views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6558,
        store_longitude: -73.6719,
        total_orders: 1165.26,
        neighborhood: '',
        keywords: 'Burger, Beer, Wine, Rustic, American, Relaxed, Casual, Local, Great, Signature',
        annual_dol: 1200.52,
        description:
            'Local pub with a rustic interior offering hearty food, rotating tap beers, TVs & weekend music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7286,
        store_longitude: -73.6355,
        total_orders: 2801.38,
        neighborhood: '',
        keywords:
            'Steak, Burger, Sushi, Salad, Wine, Italian, Dessert, Champagne, Mimosa, Delicious',
        annual_dol: 3276.06,
        description:
            '"Newly opened Primehouse Steaks & Seafood is located in the heart of Garden City. Our menu features prime and aged steaks, fresh seafood, raw bar & top quality sushi. Our creative New American entrees, salads & homemade desserts round out an impressive menu that is sure to please any palate. Enjoy a great meal complimented by our friendly and professional service during our nightly dinner, weekday lunch or Sunday brunch. We also offers full service catering for all occasions with private dining areas perfect for a corporate event or momentous celebration."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -74.0039,
        total_orders: 2490.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Pizza, Italian, Gourmet, Delicious, Stylish, Trendy, French Toast, Cozy, Rustic',
        annual_dol: 2607.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -74.0088,
        total_orders: 1120.93,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Italian, Spicy, Rustic, Californian, Warm, Seasonal, American, Friendly, Casual',
        annual_dol: 1144.72,
        description:
            'Revival of an NYC standby serving vibrant Italian plates in a light-filled space with large windows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6643,
        store_longitude: -73.6599,
        total_orders: 2299.97,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Wine, Beer, French Toast, Bbq, American, French, Delicious, Relaxed',
        annual_dol: 2352.92,
        description:
            'Warm, relaxed stop for American fare like burgers, seafood & brunch staples, plus wine & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7911,
        store_longitude: -73.9741,
        total_orders: 1812.49,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Salad, Chicken Parmigiana, Pizza, Italian, Dessert, Bread Pudding, Wine, Martini, Vodka',
        annual_dol: 3195.92,
        description:
            'Low-key eatery offering a big menu of pizza & Italian classics, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7048,
        store_longitude: -73.9113,
        total_orders: 58.02,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Salad, Mexican, Beer, Dessert, Dominican, Ecuadorian, Latin',
        annual_dol: 237.16,
        description:
            'This casual eatery with a varied menu of Latin American favorites specializes in seafood dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.9173,
        total_orders: 46.09,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Spicy, Korean, Delicious, Savory, Juicy, Asian, Fried Chicken, Crisp, Bright',
        annual_dol: 188.4,
        description:
            '"Mad For Chicken has been serving up our acclaimed Korean Style Fried Chicken since 2005. Our mission is to exceed the fried chicken dining culture expectations one wing at a time, by delivering our unparalleled crisp and savory hand brushed chicken to the world."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7459,
        store_longitude: -73.9571,
        total_orders: 11080.16,
        neighborhood: 'LIC/Hunters Point',
        keywords:
            'Champagne, Wine, Lobster, Spicy, Beer, Lobster Bisque, Delicious, Creamy, Premium, American',
        annual_dol: 21503.55,
        description:
            'Cozy, chic hub with wines on tap & craft brews, plus artisanal cheeses, charcuterie & other nibbles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7235,
        store_longitude: -73.9963,
        total_orders: 97.3,
        neighborhood: 'Nolita',
        keywords: 'Burger, Sushi, Pizza, Tequila, Wine, Margarita, Spicy, Italian, Stylish, Gin',
        annual_dol: 397.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.985,
        total_orders: 676.39,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lasagna, Italian, Salad, Wine, Hotel, Ale, Classy, Southern, Traditional, Casual',
        annual_dol: 754.24,
        description:
            'Festive downstairs restaurant with an old-school setting & traditional Southern Italian cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9837,
        total_orders: 239.84,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Mexican, Shrimp Cocktail, Salad, American, Caesar Salad, Legendary, Classic, Comfortable, Great, Signature',
        annual_dol: 980.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7219,
        store_longitude: -73.9885,
        total_orders: 1848.89,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Pizza, Tasty, Irish, Organic, Cozy, Local, Relaxed, Casual',
        annual_dol: 1893.79,
        description:
            'Relaxed bar with cozy booths & mod decor popular for its generous happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -73.9894,
        total_orders: 260.34,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Gluten Free, Greek, Homemade, Sweet, American, Greek Salad, Casual, Great, Diverse',
        annual_dol: 398.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7225,
        store_longitude: -73.9926,
        total_orders: 34.51,
        neighborhood: 'Bowery',
        keywords:
            'Wine, Italian, Homemade, Friendly, Seasonal, Casual, Modern, Classic, Fresh, Great',
        annual_dol: 141.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7248,
        store_longitude: -73.9964,
        total_orders: 98.71,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Coffee, Delicious, Eclectic, Premium, Awesome, Great, Casual, Cultural',
        annual_dol: 403.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7426,
        store_longitude: -74.0002,
        total_orders: 5311.58,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Upscale, Delicious, Gin, Fun, Colorful, Playful, Rum, Asian, Bright',
        annual_dol: 5780.37,
        description:
            'Tropical takes on classic cocktails are paired with Southeast Asian & California-influenced fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.9963,
        total_orders: 211.57,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Cheesecake, Pizza, Italian, Salad, Wine, Dessert, Gourmet, Martini, Beer, Upscale',
        annual_dol: 878.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6738,
        store_longitude: -73.957,
        total_orders: 9550.32,
        neighborhood: 'Crown Heights',
        keywords:
            'Wine, Beer, Whiskey, Delicious, Spacious, Vibrant, Awesome, Friendly, Great, Fresh',
        annual_dol: 9752.97,
        description:
            'Cavernous bar with exposed brick & Prohibition-style furnishings serving craft cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7129,
        store_longitude: -73.9576,
        total_orders: 108.19,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Spicy, Delicious, Beer, Chinese, Juicy, Homemade, Fabulous, Ramen, Asian, Fresh',
        annual_dol: 147.28,
        description:
            'Low-key Williamsburg Asian restaurant with garden seating & an emphasis on dim sum.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.984,
        total_orders: 687.87,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Burger, Tacos, Beer, Mimosa, Tasty, French Toast, Warm, American, Irish, French',
        annual_dol: 694.63,
        description:
            'Old-fashioned pub offering a wide variety of beers, plus lunch & dinner menus & a late bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.9264,
        total_orders: 111.77,
        neighborhood: 'Astoria/LIC',
        keywords: 'Vegetarian, Burger, Salad, Wine, Beer, Brazilian, Casual, Local, Great',
        annual_dol: 456.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7106,
        store_longitude: -73.9653,
        total_orders: 44.04,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Burger, Steak, Salad, Beer, Coffee, Ale, Delicious, Cozy, Creamy, American',
        annual_dol: 180.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7442,
        store_longitude: -73.9857,
        total_orders: 5.54,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Salad, Kebab, Vegetarian, Coffee, Lebanese, Champagne, Tasty, Mediterranean, French, Upscale',
        annual_dol: 22.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8828,
        store_longitude: -73.6304,
        total_orders: 2713.32,
        neighborhood: '',
        keywords:
            'Steak, Beer, Hotel, Bbq, Upscale, Elegant, Ribeye Steak, Contemporary, Cool, Casual',
        annual_dol: 3192.69,
        description:
            "Elegant rooms are set in contemporary wings of the historic manor and are equipped with free Wi-Fi, flat-screen TVs, iPod docks and coffeemakers. Suites add sitting rooms, soaking tubs and minifridges.There's an upscale dining room and a casual pub, as well as a spa, indoor and outdoor pools, a hot tub, a sauna and steam rooms. A 5-mile walking trail, plus tennis, racquetball and basketball courts are also available. Conference facilities include 25,000 sq ft of meeting space and a 24-hour business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8645,
        store_longitude: -73.6316,
        total_orders: 340.28,
        neighborhood: '',
        keywords:
            'Steak, Pizza, Chicken Parmigiana, Cheesesteak, Salad, Italian, Meatball, Wine, Beer, French Onion Soup',
        annual_dol: 380.1,
        description:
            'Casual eatery featuring an eclectic menu of pizza, pasta, quesadillas, steak, Italian mains & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.9973,
        total_orders: 2818.87,
        neighborhood: 'Little Italy',
        keywords:
            'Coffee, Italian, Pizza, Delicious, Funky, Rustic, Homemade, Friendly, Fresh, Classic',
        annual_dol: 2941.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -74.0072,
        total_orders: 2849.88,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Beer, Wine, Juicy, French, Irish, American, Fabulous, Cozy, Ample',
        annual_dol: 2914.53,
        description:
            'Low-key neighborhood joint serving basic American bar food in a dark-wood-accented dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7057,
        store_longitude: -73.9218,
        total_orders: 1029.27,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Cajun, Beer, Wine, Coffee, Delicious, French, Cozy, Creole, Asian',
        annual_dol: 1076.27,
        description:
            'This small, full-service coffee shop serves cocktails & New Orleans-style Southern fare at night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7093,
        store_longitude: -73.9235,
        total_orders: 3208.34,
        neighborhood: 'East Williamsburg',
        keywords: 'Hotel, Rooftop, Beer, Coffee, Great, Creative',
        annual_dol: 3572.27,
        description:
            'Massive venue featuring several spaces (including a roof terrace) for live music & DJ nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8035,
        store_longitude: -73.9558,
        total_orders: 1845.53,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Steak, Salad, Seasonal, American, Stylish, Homemade, Cozy, Casual, Sweet',
        annual_dol: 2130.1,
        description:
            'Seasonal New American small plates & cocktails in a stylish, vintage-inspired space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6579,
        store_longitude: -73.9606,
        total_orders: 2605.98,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Vegetarian, Hotdog, Salad, Wine, Coffee, Beer, Dessert, Green Curry, Tasty, Italian',
        annual_dol: 3388,
        description:
            'Seasonal American restaurant that plays on culinary nostalgia with an open kitchen & wooden booths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9983,
        total_orders: 8100.75,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Steak, Lobster, Vegetarian, Vegan, Salad, Wine, Gluten Free, French, Delicious, Romantic',
        annual_dol: 8784.87,
        description:
            'Iconic French brasserie with steak frites, brunch & pastries in a classy space with red banquettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -74.0028,
        total_orders: 1440.91,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Wine, Italian, Dessert, Upscale, Impeccable, Classy, Amazing, Classic, Wonderful',
        annual_dol: 1546.04,
        description:
            'Upscale scene where an international crowd tucks into Italian food chased with Bellinis.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.9776,
        total_orders: 3024.12,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, French, Upscale, Contemporary, Hotel, Modern, Classy, Casual, Seasonal, Traditional',
        annual_dol: 4472.3,
        description:
            "Alain Ducasse's upscale French bistro, modeled after the Paris original, serves up classic fare.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9813,
        total_orders: 10.82,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Tacos, Mexican, Tasty, Margarita, Salad, Spicy, Delicious, Yummy, Fun, Fresh',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.82,
        store_longitude: -73.9586,
        total_orders: 220.12,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Chinese, Flavorful, Asian, Cozy, Caribbean, Urban, Hip, Premium, Fusion, Intimate',
        annual_dol: 899.78,
        description:
            'Buzzing destination with an arty urban vibe serving classic Asian comfort food, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.987,
        total_orders: 1396.32,
        neighborhood: 'Lower East Side',
        keywords: 'Steak, Salad, Wine, Upscale, Organic, Gin, French, Martini, Japanese, Sleek',
        annual_dol: 2083.81,
        description:
            'Boozy brunches, happy hours & New American dinners in a warehouse-like space with balcony seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7208,
        store_longitude: -73.9878,
        total_orders: 2015.36,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Hotdog, Vodka, Wine, Gin, Fruity, Rum, American, Elegant, Hip',
        annual_dol: 2150.7,
        description:
            'A cocktail spot with loungey seating & occasional basement shows in the former Cake Shop space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -73.9891,
        total_orders: 34.75,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Wine, Beer, Ale, Dessert, Seasonal, American, Contemporary, Casual, Artisanal',
        annual_dol: 142.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.9985,
        total_orders: 2299.18,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Italian, Intimate, Urban, Casual, Lively, Chill, Great',
        annual_dol: 2347.96,
        description:
            'Energetic gay bar offering DJs, drag shows & more in an industrial-chic atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 809.08,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Italian, Delicious, Beer, Elegant, French, Legendary, Modern, Fun, Outstanding',
        annual_dol: 3307.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7378,
        store_longitude: -73.9975,
        total_orders: 42.95,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Salad, Steak, Coffee, Friendly, Casual, Club Sandwich, Local, Mint Julep, Fresh',
        annual_dol: 175.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6836,
        store_longitude: -73.967,
        total_orders: 1464.79,
        neighborhood: 'Clinton Hill',
        keywords: 'Wine, Stylish, Cozy, Beer, Quaint, Warm, Friendly, Sweet, Casual, Great',
        annual_dol: 1495.87,
        description:
            'Cocktails, craft beer & wine served in a stylish locale with big windows & distressed-wood decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.7499,
        total_orders: 554.78,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Sushi, Salad, Steak, Tasty, Japanese, Spicy, Sashimi, Korean, Asian, Shabu-Shabu',
        annual_dol: 815.18,
        description:
            'Contemporary Asian buffet with a generous selection for a set price, including sushi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 299.14,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Lobster, Gluten Free, Seafood Paella, Mexican, Wine, Italian, French, Prosecco, Bellini, Perfect',
        annual_dol: 1222.8,
        description:
            'Casual outdoor restaurant dishing up seafood classics such as lobster rolls, plus cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7171,
        store_longitude: -73.9547,
        total_orders: 1451.06,
        neighborhood: 'Williamsburg',
        keywords: 'Steak, Tequila, Whiskey, Pizza, Salad, Beer, Margarita, Bourbon, Ale, Irish',
        annual_dol: 1565.01,
        description:
            'Utilitarian sports bar pairing whiskies & other drinks with inventive pub grub, TVs & dancing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -74.0063,
        total_orders: 7214.84,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Shrimp Cocktail, Coffee, Irish, American, Legendary, Seasonal, Western, Casual, Cool',
        annual_dol: 25471.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6807,
        store_longitude: -73.9632,
        total_orders: 20.97,
        neighborhood: 'Crown Heights/Clinton Hill',
        keywords: 'Authentic, Perfect, Casual, Cool, Classic, Eclectic',
        annual_dol: 85.73,
        description:
            "\"Manado's Jazz Lounge is Brooklyn's premier spot to vibe in our lounge with good music and drinks. We cater to a mature audience. We also feature events such as jam sessions, poetry and spoken word, karaoke, and other exciting entertainment. Our lounge is available for private events. Located in Prospect Heights, just a few blocks from the Barclay Center. Manado's Jazz Lounge presents a private lounge experience with Positive Brooklyn vibes, eclectic variety of music, and an authentic Jazz ambiance. Whether you are looking for a casual night out on the town, an exclusive event space, or an opportunity to meet with neighbors and friends, Manado's will provide the space to unwind. We look forward to your visit!\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9557,
        total_orders: 871.14,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Spacious, Fun, Friendly, Eclectic, Great, Casual',
        annual_dol: 3560.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7502,
        store_longitude: -73.9776,
        total_orders: 671.22,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Luxurious, Elegant, Hotel, Modern, Friendly, Excellent, Contemporary',
        annual_dol: 2356.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.9234,
        total_orders: 337.58,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tequila, Burger, Vodka, Wine, Coffee, Ale, Dessert, Gin, Trendy, Upscale',
        annual_dol: 352.22,
        description:
            'Greek restaurant serving up marinated rotisserie meats & whole fish in an elevated setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7106,
        store_longitude: -73.9679,
        total_orders: 360.28,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Garlic Bread, Vodka, Pizza, Wine, Salad, Italian, Beer, Korean, Meatball, American',
        annual_dol: 400.84,
        description:
            'Wood-fired pizzas, meats & veggies plus housemade pastas & other Italian fare in a warm space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6586,
        store_longitude: -73.6457,
        total_orders: 2018.97,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Lobster Bisque, Salad, Beer, French Onion Soup, French, Ale, American, Contemporary',
        annual_dol: 2071.2,
        description:
            'Steakhouse serving high-end cuts in a contemporary space with a fireplace, a bar & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6312,
        store_longitude: -73.9188,
        total_orders: 42.74,
        neighborhood: 'Bergen Beach/Flatlands',
        keywords:
            'Vegan, Coffee, Dessert, Warm, Cozy, Caribbean, Jamaican, American, Traditional, Retro',
        annual_dol: 161.83,
        description:
            "Retro diner dating back to the '70s known for a wide range of American fare served morning to night.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.565,
        total_orders: 96.54,
        neighborhood: '',
        keywords: 'Mexican, Friendly, Fun, Italian, Casual, Asian',
        annual_dol: 102.73,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8574,
        store_longitude: -73.8857,
        total_orders: 11.39,
        neighborhood: 'Belmont/West Bronx',
        keywords: 'Burger, Beer, Steak, Salad, Wine, Ale, Cozy, Eclectic, American, Sweet',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6335,
        store_longitude: -74.0066,
        total_orders: 775.1,
        neighborhood: 'Borough Park',
        keywords: 'Karaoke, Delicious, Fun, Sushi, Chinese, Retro, Asian, Premium, Casual',
        annual_dol: 1085.87,
        description:
            'This expansive karaoke bar offers Asian small plates & noodle dishes, plus bottle service.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5984,
        store_longitude: -74.0809,
        total_orders: 41.8,
        neighborhood: 'Arrochar',
        keywords:
            'Tequila, Mexican, Margarita, Spanish, Caribbean, Hotel, African, Romantic, Yummy, Casual',
        annual_dol: 170.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7274,
        store_longitude: -73.9925,
        total_orders: 3153.6,
        neighborhood: 'NoHo',
        keywords: 'Burger, Pizza, Beer, Salad, Whiskey, Irish, Cozy, Classy, Friendly, Intimate',
        annual_dol: 4825.6,
        description: 'Low-key local Irish bar featuring a wide variety of draft & bottled beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8277,
        store_longitude: -73.8502,
        total_orders: 282.99,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Burger, Coffee, Cuban, French Toast, American, French, Warm, Puerto Rican, Casual, Classic',
        annual_dol: 409.56,
        description:
            'Casual American diner offering huge menu, including breakfast, burgers & sandwiches, 24 hours a day.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.9898,
        total_orders: 1971.94,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Vegan, Tacos, Tequila, Mexican, Vegetarian, Margarita, Coffee, Wine, Nachos, Organic',
        annual_dol: 2243.57,
        description:
            'Casual, contemporary vegan spot for tacos & other Mexican plates, plus cocktails & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7279,
        store_longitude: -73.9856,
        total_orders: 135.34,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tacos, Mexican, Margarita, Vegan, Quesadillas, Spicy, Funky, Delicious, Stylish, Rich',
        annual_dol: 147.5,
        description:
            'Mexican food gets some refined twists at this stylish cantina with streetside seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.9718,
        total_orders: 484.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Wine, Flavorful, Salad, Dessert, Beer, Upscale, Trendy, Delicious, Elegant',
        annual_dol: 539.5,
        description:
            'Cozy kosher eatery offering a seasonal menu of upscale meat & seafood dishes, plus cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9719,
        total_orders: 208.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Chicken Parmigiana, Vodka, Italian, New-York-Style, Wine, Spacious, Relaxed, Fresh, Local',
        annual_dol: 217.06,
        description:
            'Local pizzeria chain serving brick-oven pies, pasta & calzones in a relaxed setting since 1933.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6858,
        store_longitude: -73.9119,
        total_orders: 388.72,
        neighborhood: 'Bushwick',
        keywords: 'Steak, Salad, Pizza, Margarita, Caesar Salad, Casual, Great',
        annual_dol: 445.85,
        description: '"Bar & grill in Brooklyn, NY."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9742,
        total_orders: 217.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Coffee, Canadian, American, Hotel, Fresh',
        annual_dol: 356.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6739,
        store_longitude: -73.9527,
        total_orders: 657.81,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Steak, Tacos, Salad, Tasty, Beer, Dessert, Spicy, Upscale, American',
        annual_dol: 657.81,
        description:
            'Cozy, chill hangout with elevated American bites & patio seating, plus sports viewing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7412,
        store_longitude: -73.9854,
        total_orders: 276.11,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Burger, Salad, Steak, Vegetarian, Tasty, Dessert, Wine, Coffee, Healthful, Beer',
        annual_dol: 356.05,
        description: 'Modern restaurant offering organic, vegetarian-focused & gluten-free menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7424,
        store_longitude: -73.9898,
        total_orders: 1799.31,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Vodka, Tasty, Wine, Gin, Stylish, New-York-Style, Delicious, Italian, Hotel, Fun',
        annual_dol: 6476.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6926,
        store_longitude: -73.9886,
        total_orders: 236.67,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Tequila, Tacos, Mexican, Beer, Wine, Peruvian, Delicious, Rustic, Fun, Friendly',
        annual_dol: 830.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7393,
        store_longitude: -73.9895,
        total_orders: 25.69,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Coffee, Australian, Perfect, Excellent, Intimate, Casual, Incredible, Local',
        annual_dol: 105,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7758,
        store_longitude: -73.91,
        total_orders: 1171.48,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Burger, Hamburger, Steak, Cajun, Gourmet, Milkshake, Bbq, Dessert, American, Jamaican',
        annual_dol: 1298.45,
        description:
            'Brick corner restaurant serving gourmet burgers & shakes, with a bar with TV screens.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -74.0001,
        total_orders: 965.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Zombie, Beer, Wine, Tasty, Meatball, Spicy, Painkiller, Breakfast Burrito, Rum, Irish',
        annual_dol: 985.71,
        description:
            'Gastropub with nautical touches, offering a seafood-focused menu & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.8847,
        total_orders: 171.74,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Coffee, Dessert, Rum, Sweet, Casual, Pure, Indian, Gyro',
        annual_dol: 721.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5241,
        store_longitude: -74.2346,
        total_orders: 370.93,
        neighborhood: 'Charleston',
        keywords:
            'Sushi, Cheesecake, Salad, Gluten Free, Wine, Japanese, Spicy, Beer, Delicious, Korean',
        annual_dol: 491.06,
        description:
            'Buzzing eatery & lounge offering Japanese dishes with a French twist, plus cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -73.988,
        total_orders: 5725.34,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Mediterranean, Greek, Shawarma, Spectacular, Friendly, Great, Fine, Signature, Hummus',
        annual_dol: 7016.57,
        description:
            '"Located in the heart of Gramercy in New York City at 20th Street and Park Avenue South, Barbounia’s atmosphere and design, which complement and attract a fashion, music and entertainment clientele, takes you off the busy streets of Manhattan and transcends you to the middle of the Mediterranean. With a spectacular open kitchen, tall arched ceilings divided by Moorish columns, large wooden communal tables, and pillowed banquettes; you feel the magnificent energy emanating from the kitchen, staff and patrons."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7364,
        store_longitude: -73.9921,
        total_orders: 6690.19,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Steak, Wine, Coffee, Upscale, Salad, Italian, Ale, Beer, American, French',
        annual_dol: 6851.6,
        description:
            'Intimate tin-ceilinged cocktail spot on a Union Square side street popular with a post-work crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6079,
        store_longitude: -73.973,
        total_orders: 48.59,
        neighborhood: 'Gravesend/Midwood',
        keywords:
            'Steak, Salad, Chilean, Peruvian, Delicious, Greek, Latin, Hotel, Premium, American',
        annual_dol: 148.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7347,
        store_longitude: -73.9931,
        total_orders: 153.88,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Gourmet, Spicy, Australian, Indian, Eclectic, Tandoori Chicken, Modern, Traditional, Unforgettable, Paneer Tikka',
        annual_dol: 628.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7293,
        store_longitude: -73.9861,
        total_orders: 85.01,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegetarian, Coffee, Wine, Beer, Vietnamese, Ale, Dessert, Meatball, Martini, Bbq',
        annual_dol: 347.48,
        description:
            'Narrow, casual spot for long-simmered pho, rice & noodle dishes, with veggie options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7312,
        store_longitude: -73.9934,
        total_orders: 39.98,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Mexican, Local, Casual, Delicious, Fresh',
        annual_dol: 255.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6823,
        store_longitude: -74.0074,
        total_orders: 11.92,
        neighborhood: 'Red Hook',
        keywords: 'Martini, Indian, Complex, Great',
        annual_dol: 341.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5878,
        store_longitude: -73.9551,
        total_orders: 676.43,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Burger, Vegan, Steak, Salad, Kebab, Coffee, Dessert, Bbq, Delicious, Rich',
        annual_dol: 753.64,
        description:
            'Spacious bi-level cafe offering New American fare, cocktails & hookahs in a contemporary interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.9816,
        total_orders: 1389.92,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Sushi, Dessert, Japanese, Delicious, Homemade, Warm, Elegant, Intimate, Fancy, Seasonal',
        annual_dol: 1482.31,
        description:
            'High-end branch of a Tokyo eatery offering omakase-only sushi in a pared-down yet warm setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8844,
        store_longitude: -73.9004,
        total_orders: 121.34,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Mexican, Burger, Salad, Organic, Healthy, Local, Casual, Innovative',
        annual_dol: 557.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7676,
        store_longitude: -73.832,
        total_orders: 3.59,
        neighborhood: 'Flushing',
        keywords:
            'Karaoke, Tasty, Korean, Chinese, Phenomenal, Cozy, Caribbean, Clean, Premium, Casual',
        annual_dol: 14.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6719,
        store_longitude: -73.8428,
        total_orders: 141.28,
        neighborhood: 'Ozone Park',
        keywords:
            'Pizza, Italian, Salad, Meatball, Vodka, Swiss, Caesar Salad, Thai, French, American',
        annual_dol: 144.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7977,
        store_longitude: -73.6674,
        total_orders: 38.11,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Pizza, Salad, Gluten Free, Mexican, Italian, Roast Beef, Delicious, French Toast',
        annual_dol: 40.65,
        description:
            'Modern eco-friendly pit stop for comfort food that hosts a varied menu in an airy, bi-level space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6896,
        store_longitude: -73.9881,
        total_orders: 3654.65,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Coffee, Hotel, American, Vibrant, Great',
        annual_dol: 3734.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6842,
        store_longitude: -73.9919,
        total_orders: 635.78,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Steak, Vegetarian, Mexican, Tequila, Argentinian, Tex-Mex, Rustic, Organic, Cuban, Thai',
        annual_dol: 780.08,
        description:
            'Inventive cocktails made with Latin spirits plus Mexican bites in a brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6857,
        store_longitude: -73.9945,
        total_orders: 301.15,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Lebanese, Beer, Thai, Legendary, Middle Eastern, Authentic, Impressive, Casual',
        annual_dol: 1231,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.74,
        store_longitude: -73.9825,
        total_orders: 382.52,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Salad, Wine, Beer, Irish, Delicious, American, Casual, Authentic, Perfect',
        annual_dol: 390.64,
        description:
            "Woodsy, old-timey Irish pub with darts & TVs serving classic entrees like shepherd's pie.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.9898,
        total_orders: 916.12,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Hamburger, Salad, Mexican, Chinese, Tasty, American, Asian, Casual, Local',
        annual_dol: 3216.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6742,
        store_longitude: -73.7097,
        total_orders: 14.55,
        neighborhood: '',
        keywords: 'Beer, Gourmet, Sweet, Fresh, Local, Casual, Great, Wonderful, Coleslaw',
        annual_dol: 42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.8935,
        total_orders: 154.68,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Wine, Beer, Ale, Irish, Cozy, Friendly, Authentic, American, Casual, Great',
        annual_dol: 194.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7537,
        store_longitude: -73.9959,
        total_orders: 673.91,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Vegetarian, Gluten Free, Beer, Wine, Relaxed, Quesadillas, Casual, American, Perfect',
        annual_dol: 809.26,
        description:
            'Sliders & other American gastropub bites served in a relaxed space with exposed brick & many TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -73.9872,
        total_orders: 3371.72,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Steak, Lobster, Burger, Shrimp Cocktail, Margarita, Upscale, French, Swiss, Premium, American',
        annual_dol: 3443.26,
        description:
            '"Welcome to Rick’s Cabaret & Steakhouse - New York City’s #1 gentlemen’s club. Rick’s Cabaret New York is conveniently located in the heart of midtown Manhattan between New York’s Empire State Building and the world famous Madison Square Garden."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -74.0041,
        total_orders: 315.35,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Hamburger, Beer, Chicken Caesar Salad, Salad, Gourmet, Milkshake, Texan, American, Caesar Salad',
        annual_dol: 351.39,
        description:
            'Creative gourmet burgers, modern sides, shakes & draft beers served in a small, casually hip space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7156,
        store_longitude: -73.9964,
        total_orders: 617.95,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Beer, Tasty, Ale, Spacious, Chinese, Bright, Amazing, Great, Asian, Casual',
        annual_dol: 2525.99,
        description:
            'Popular Chinatown eatery famed for its soup dumplings and other Shanghai dishes in a spare setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5737,
        store_longitude: -74.1176,
        total_orders: 613.83,
        neighborhood: 'New Dorp',
        keywords: 'Burger, Steak, Tequila, Sushi, Salad, Wine, Delicious, Japanese, Ale, Fun',
        annual_dol: 626.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.788,
        store_longitude: -73.6787,
        total_orders: 284.01,
        neighborhood: '',
        keywords: 'Casual, Perfect, Local, Tradition',
        annual_dol: 433.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7713,
        store_longitude: -73.9536,
        total_orders: 210.94,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Italian, Pizza, Dessert, Tasty, Turkish, Delicious, Homemade, Asian, Romantic, Classy',
        annual_dol: 229.89,
        description:
            'Modestly appointed neighborhood red-sauce Italian restaurant with an old-school feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6019,
        store_longitude: -73.6525,
        total_orders: 495.17,
        neighborhood: '',
        keywords:
            'Sushi, Steak, Tasty, Gluten Free, Dessert, Bread Pudding, Italian, Warm, Cozy, Chilean',
        annual_dol: 527.71,
        description:
            '"Your Own Private Party Space. Great for Any Occasions for Gatherings of 30 to 50 Guests. Available Monday thru Sunday for Private Parties Day or Evenings Available Sit down , Family Style Or Buffet All of our Menus are Customizable Book Your Next Party With Us! Email: david@chefs724.com Or Contact Us at: 516-867-0700 and leave your name and call back number for more information about our party and event packages."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.9212,
        total_orders: 1227.34,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Burger, Hamburger, Beer, Upscale, Wine, Ale, American, Premium, French, Fun',
        annual_dol: 1479.76,
        description:
            'Neighborhood hangout with a leafy patio serving upscale bar food, craft beers, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9942,
        total_orders: 459.09,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Wine, Italian, Seasonal, Creative, Friendly',
        annual_dol: 1876.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.9211,
        total_orders: 679.3,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Beer, Ale, Fun, Irish, Friendly, Great, Modern, Local, Casual',
        annual_dol: 708.75,
        description:
            '"Modern bar with Large Outdoor Patio available for Events. Friendly atmosphere with a wide variety of IPA’s, Draft Beers and not to mention our Cocktail menu."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6555,
        store_longitude: -73.6481,
        total_orders: 1927.72,
        neighborhood: '',
        keywords:
            'Steak, Hamburger, Vegetarian, Italian, American, Homey, Ribeye Steak, Cozy, Classic, Authentic',
        annual_dol: 2015.37,
        description:
            'Huge portions of beef including the signature skirt steak in a homey setting with a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.974,
        total_orders: 7.13,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Wine, Shrimp Cocktail, Coffee, Italian, French Onion Soup, Hotel, French, Bbq, Caesar Salad',
        annual_dol: 29.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.701,
        store_longitude: -73.9134,
        total_orders: 182.45,
        neighborhood: 'Bushwick',
        keywords: 'American, Premium',
        annual_dol: 261.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7043,
        store_longitude: -73.9283,
        total_orders: 13.25,
        neighborhood: 'Bushwick',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Mimosa, American, Latin, Michelada, Local, Sangria',
        annual_dol: 54.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7327,
        store_longitude: -74.0041,
        total_orders: 886.13,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Vegetarian, Salad, French, Coffee, Polished, Delicious, Clean, Casual, Intimate',
        annual_dol: 3355.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7412,
        store_longitude: -74.0063,
        total_orders: 1719.3,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Vegetarian, Coffee, Luxurious, Healthy, Friendly, French, Fabulous, American, Sweet, Great',
        annual_dol: 2601.77,
        description:
            'A sleek, bi-level cafe & restaurant concept operated by Lexus featuring rotating chefs & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 1168,
        neighborhood: '',
        keywords:
            'Vegan, Beer, Coffee, Healthy, Wholesome, Delicious, Polished, Natural, Classy, Handcrafted',
        annual_dol: 1973.57,
        description:
            'Relaxed, eco-chic chain serving health-conscious fare, including vegan options, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -74.0038,
        total_orders: 1347.8,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Vegan, Pizza, Gluten Free, Italian, Wine, Delicious, Cozy, Spicy, Friendly',
        annual_dol: 1490.92,
        description:
            'Italian trattoria serving traditional plates & apéritifs in a rustic, cozy space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7889,
        store_longitude: -73.9744,
        total_orders: 4282.66,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Lobster, Lobster Roll, Salad, Beer, Upscale, Ale, Thai, Sustainable, American, Hospitable',
        annual_dol: 4688.85,
        description:
            'Upscale-casual seafood spot with a Cape Cod vibe known for its lobster rolls and cheap oyster deal.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7008,
        store_longitude: -73.9083,
        total_orders: 962.6,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Cozy, Relaxed, Homey, Perfect, Cool, Lively, Eclectic, Great, Casual',
        annual_dol: 1004.33,
        description:
            'Lively hangout offering craft cocktails, draft brews & happy hour in a cozy space with game nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -73.659,
        total_orders: 1659.44,
        neighborhood: '',
        keywords:
            'Burger, Beer, Vodka, Roast Beef, Bbq, Delicious, American, Spacious, Friendly, Casual',
        annual_dol: 1694.65,
        description:
            'This sizable pool hall & sports bar provides burgers, wings & draft beer, plus DJ nights & leagues.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6979,
        store_longitude: -73.9053,
        total_orders: 1227.96,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Contemporary',
        annual_dol: 1625.65,
        description:
            '"NYC underground rock\'n\'roll club and party palace on the Bushwick/Ridgewood border! Music venue, bar, restaurant, dance club, courtyard, cocktail lounge, art gallery, performance space, cinema, and social club. Located between the Halsey L train stop and the Seneca M train stop. Follow our IG for all updates: @tveyenyc"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.589,
        store_longitude: -73.7976,
        total_orders: 82.47,
        neighborhood: 'Arverne by the Sea/Far Rockaway/Arverne',
        keywords:
            'Steak, Burger, Salad, Wine, Bbq, French, Beer, Mediterranean, Southern, Eclectic',
        annual_dol: 175.77,
        description:
            '"Bar Marseille--borrowing its name from the famed port city of Marseille in Southern France--celebrates the eclectic collection of flavors that is found along the French Riviera with cultural influences of North Africa, Italy, Greece, and Spain. The seaside restaurant, which encompasses indoor dining and outdoor patio and a rooftop deck pays tribute to Provençal cuisine paired with a robust wine and cocktail list. Bar Marseille features live music every Friday and Saturday, year round. Our roof deck opens for the summer in May."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7003,
        store_longitude: -73.9044,
        total_orders: 388.54,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Hotdog, Burger, Beer, Tequila, Wine, Ale, Swiss, American, Latin, Western',
        annual_dol: 1001.22,
        description: '"Neighborhood bar in Ridgewood. 56-53 Myrtle Avenue."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5736,
        store_longitude: -74.1159,
        total_orders: 193.47,
        neighborhood: 'New Dorp',
        keywords:
            'Tacos, Mexican, Steak, Tequila, Shrimp Cocktail, Delicious, Quesadillas, Tex-Mex, Fajitas, Enchiladas',
        annual_dol: 201.86,
        description:
            'Lively, sit-down outpost for Mexican standards like tacos, enchiladas & margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5786,
        store_longitude: -73.8493,
        total_orders: 25.69,
        neighborhood: 'Belle Harbor/Far Rockaway/Rockaway Park',
        keywords: 'Burger, Hotdog, Gourmet, French, American, Casual',
        annual_dol: 105,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6428,
        store_longitude: -73.9023,
        total_orders: 19.06,
        neighborhood: 'Canarsie',
        keywords: 'Burger, Pizza, Tacos, Steak, Italian, Beer, Wine, Japanese, American, Caribbean',
        annual_dol: 77.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6671,
        store_longitude: -73.7942,
        total_orders: 1786.96,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Coffee, Upscale, Stylish, Wine, Hotel, Spacious, Beer, Modern, Jamaican, Premium',
        annual_dol: 1824.88,
        description:
            "Contemporary-styled rooms offer free WiFi, desks with ergonomic chairs, flat-screen TVs with premium cable channels, minifridges, microwaves and coffeemakers. Suites add separate living areas and sofabeds.Social spaces include a living room-style lobby. The American restaurant serves breakfast, lunch and dinner as well as all-day drinks, including Starbucks coffee. There's also a business center with workstations and printers, and 4 meeting rooms. Other amenities include an exercise room and a free airport shuttle.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6721,
        store_longitude: -73.9769,
        total_orders: 13.13,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Mexican, Salad, Delicious, Crisp, Casual, Outstanding, Local, Great, Indian',
        annual_dol: 53.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.9591,
        total_orders: 360,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Vegetarian, Salad, Beer, Gluten Free, Mimosa, Margarita, Scotch, Gin, Ale',
        annual_dol: 504.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -73.9577,
        total_orders: 666.39,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Coffee, Gourmet, Martini, Spicy, American, Mediterranean, Paloma, Casual, Classic',
        annual_dol: 2252.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6439,
        store_longitude: -73.7818,
        total_orders: 36.9,
        neighborhood: 'Jamaica',
        keywords:
            'Wine, Luxurious, Korean, Classy, Premium, American, Sumptuous, British, Great, Casual',
        annual_dol: 150.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8541,
        store_longitude: -73.7908,
        total_orders: 1772.57,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Crab Cakes, Sushi, Shrimp Cocktail, Italian, Martini, Spicy, Wine, Spanish',
        annual_dol: 3018.14,
        description:
            'City Island classic since 1920 for seafood in a lively setting, with outdoor dining & marina views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8408,
        store_longitude: -73.7843,
        total_orders: 87.9,
        neighborhood: 'City Island',
        keywords:
            'Burger, Lobster, Steak, Cheesesteak, Pizza, Salad, Gourmet, Italian, Coffee, Delicious',
        annual_dol: 110.4,
        description:
            "\"Every memorable event starts with delicious food and great service. For more than 20 years, we've been perfecting the art of unforgettable entrées and exciting events. Whether you're in need of a first-class catering company or a banquet hall for your special day, we are here to make that day exceptional. Our staff is proud to offer each and every one of our clients a personalized catering experience. No matter what type of event you're hosting, we will work with you to plan the perfect menu & event. Our restaurant offers an extravagant menu of five-star dishes. In order to provide you with the freshest food available, many of the dishes are made with spices and vegetables grown in their rooftop garden.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -73.9877,
        total_orders: 956.05,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Wine, Gluten Free, Salad, Beer, Delicious, American, Australian, Perfect, Friendly, Fun',
        annual_dol: 997.5,
        description:
            'Lively bar & eatery serving wings, burgers & pub stables that are often plated with rubber duckies.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.801,
        store_longitude: -73.9682,
        total_orders: 2312.6,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Burger, Steak, Cheesecake, Shrimp Cocktail, Vodka, Gluten Free, Wine, Beer, Bbq, Cozy',
        annual_dol: 2412.86,
        description:
            'A cozy venue with clean sight lines & good sound featuring jazz, blues & Latin acts, plus eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5225,
        store_longitude: -74.2348,
        total_orders: 0.85,
        neighborhood: 'Charleston',
        keywords: 'Burger, Salad, Dessert, Coffee, Italian, Delicious, Casual, Fresh, Recommended',
        annual_dol: 3.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5406,
        store_longitude: -74.1472,
        total_orders: 7.94,
        neighborhood: 'Great Kills',
        keywords: 'Whiskey, Italian, Bloody Mary, Great, Friendly, Casual',
        annual_dol: 22.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -73.9892,
        total_orders: 92.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Steak, Salad, Korean, Tasty, Bbq, Wine, Beer, Spicy, French, American',
        annual_dol: 377.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5865,
        store_longitude: -73.9521,
        total_orders: 21.31,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Vegetarian, Salad, Coffee, Delicious, Homey, Russian, Homemade, Authentic, Casual, Traditional',
        annual_dol: 87.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9704,
        total_orders: 1515.82,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Shrimp Cocktail, American, Hotel, Premium, Classic, Fine, Great, Signature',
        annual_dol: 5340.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6182,
        store_longitude: -74.0301,
        total_orders: 543.51,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Burger, Steak, Pizza, Chicago-Style, Beer, American, Local, Casual, Yummy, Friendly',
        annual_dol: 783.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7795,
        store_longitude: -73.9507,
        total_orders: 6869.58,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Delicious, Creamy, Bright, Casual, Lit',
        annual_dol: 8133.41,
        description:
            'Small, pink shop for ice cream scoops also doubling as a dimly lit speakeasy with cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6937,
        store_longitude: -73.9648,
        total_orders: 108.85,
        neighborhood: 'Clinton Hill',
        keywords: 'Caribbean, Jamaican, Salad, Eclectic, Intimate, Casual',
        annual_dol: 197.97,
        description:
            'Caribbean flavors meet seafood in a brick-walled space with eclectic art & an intimate vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.692,
        store_longitude: -73.9915,
        total_orders: 2356.5,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Burger, Garlic Bread, Steak, Pizza, Cheesesteak, Salad, Beer, Wine, Nachos, Bbq',
        annual_dol: 2458.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6907,
        store_longitude: -73.9954,
        total_orders: 2564.19,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Tequila, Tasty, Cozy, Vintage, French, Perfect, Intimate, Fabulous, Cool, Exceptional',
        annual_dol: 2564.19,
        description:
            "Styled after Marie Antoinette's chambers, this cozy hideaway offers cocktails, snacks & live jazz.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.9872,
        total_orders: 526.89,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotel, Warm, Global, Vintage, Inviting, Classic, Sophisticated, Retro, Chic, Fantastic',
        annual_dol: 2464.37,
        description:
            "foot from Times Square.Stylish, theater-inspired rooms feature vintage Broadway photographs and contemporary works by local artists. All provide Wi-Fi, TVs, retro phones, and floor-to-ceiling windows with city views; some have 4-poster beds.There's a sophisticated cocktail bar, and a plush lounge with an exhibition of stage props and costumes. Breakfast is available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7851,
        store_longitude: -73.9559,
        total_orders: 83.46,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Salad, Shrimp Cocktail, Chicken Caesar Salad, French Onion Soup, French, Coffee, Savory, Elegant, Mediterranean, Warm',
        annual_dol: 86.72,
        description:
            'French specialties, desserts & a variety of wines & ports are served in a small split-level space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9918,
        total_orders: 932.07,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Mexican, Tacos, Steak, Margarita, American, Colombian, Latin, French, Casual, Authentic',
        annual_dol: 951.85,
        description:
            'South-of-the-border spot with outside seats offering basic fare & a renowned margarita happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6265,
        store_longitude: -74.162,
        total_orders: 14394,
        neighborhood: 'Mariners Harbor/Mid Island/Graniteville',
        keywords:
            'Burger, Hamburger, Beer, Gourmet, Spicy, Nachos, American, French, Fun, Entertaining',
        annual_dol: 59011.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6978,
        store_longitude: -73.972,
        total_orders: 117.04,
        neighborhood: 'Fort Greene',
        keywords:
            'Sushi, Salad, Vegetarian, Wine, Japanese, Spicy, Beer, Asian, Fusion, Mediterranean',
        annual_dol: 142.92,
        description:
            'Contemporary, timber-clad stop for Asian fusion such as omakase courses plus beer, sake & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7482,
        store_longitude: -73.9864,
        total_orders: 1748.58,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Burger, Salad, Wine, Scotch, Warm, Irish, Comfortable, Homemade, American, Casual',
        annual_dol: 1808.8,
        description:
            'Tavern beaming international sporting events & offering a menu of American grub & Irish breakfast.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7751,
        store_longitude: -73.9807,
        total_orders: 689.09,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Italian, Wine, Dessert, Delicious, Cozy, Creamy, Legendary, Classy, Bellini, Relaxed',
        annual_dol: 755.12,
        description:
            'Neighborhood stalwart serving traditional Italian dishes in casual, trattorialike environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6568,
        store_longitude: -73.6475,
        total_orders: 328.35,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Mexican, Vegetarian, Tequila, Nachos, Italian, Spicy, Upscale, Delicious',
        annual_dol: 350.18,
        description:
            'Mexican restaurant & bar serving classic meals & cocktails in a quirky space with murals & TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.618,
        store_longitude: -73.932,
        total_orders: 65.55,
        neighborhood: 'Marine Park',
        keywords: 'Beer, Clean, Ale, Local, Awesome, Casual, Great',
        annual_dol: 264.3,
        description:
            '"Jack Baileys, a local bar on Flatbush Avenue in Brooklyn, New York jackbaileysbrooklyn @jackbaileys2128"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5683,
        store_longitude: -74.1263,
        total_orders: 732.75,
        neighborhood: 'Richmond',
        keywords:
            'Chicken Parmigiana, Shrimp Cocktail, Italian, Coffee, Wine, Dessert, Delicious, Spanish, Homemade, Authentic',
        annual_dol: 1089.4,
        description:
            'Elevated family-owned establishment showcasing homemade Italian specialties, with a bar available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7535,
        store_longitude: -73.9923,
        total_orders: 2583.55,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Beer, Upscale, Healthy, French, American, Bbq, Comfortable, Irish, Perfect',
        annual_dol: 2643.17,
        description:
            'Sprawling pub popular for after-work hangouts offering happy-hour specials & upscale bar grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7547,
        store_longitude: -73.8539,
        total_orders: 432.99,
        neighborhood: 'North Corona/Flushing/Corona',
        keywords: 'Hotel, Comfortable, Clean, Amazing, Great, Friendly, Modern',
        annual_dol: 1631.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7457,
        store_longitude: -73.9882,
        total_orders: 2066.28,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Hotdog, Vegetarian, Coffee, Tasty, Scotch, Wine, Organic, Rustic, Trendy',
        annual_dol: 8875.41,
        description:
            'Contemporary, high-end British gastropub in a hip, vintage-inspired space at the Ace Hotel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.751,
        store_longitude: -74.0039,
        total_orders: 94.58,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Delicious, Perfect, Hotel, Fresh, Modern, Urban, Signature, Complex',
        annual_dol: 125.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 246.3,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Vegetarian, Burger, Sushi, Pizza, Wine, Coffee, Beer, Savory, French, Modern',
        annual_dol: 1006.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9814,
        total_orders: 1367.71,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Irish, Beer, Ale, Atmospheric, Friendly, Casual, Great, Signature, Fine, Unique',
        annual_dol: 1502.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7672,
        store_longitude: -73.9837,
        total_orders: 2150.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Champagne, Warm, Gin, Awesome, Brazilian, Great, Japanese, Excellent, Hip, Rooftop',
        annual_dol: 3507.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7669,
        store_longitude: -73.9863,
        total_orders: 670.68,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Wine, Dessert, Pizza, Vegetarian, Beer, Mediterranean, Delicious, Cozy, Bourbon',
        annual_dol: 699.76,
        description:
            'Melted cheese is predominant at this small candlelit wine bar with Mediterranean flavors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -73.9887,
        total_orders: 519.1,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Korean, Wine, Margarita, Trendy, Delicious, Hotel, Modern, Contemporary, Playful',
        annual_dol: 530.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6848,
        store_longitude: -73.8448,
        total_orders: 15.91,
        neighborhood: 'Ozone Park/Jamaica',
        keywords:
            'Burger, Pizza, Beer, Spanish, Puerto Rican, Dominican, Wine, American, Latin, Friendly',
        annual_dol: 65.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -74.0056,
        total_orders: 1047.64,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Delicious, Salad, Dessert, Coffee, Bread Pudding, Organic, Sparkling, Cuban, Awesome',
        annual_dol: 1158.53,
        description:
            'Energetic, greenmarket-driven hot spot by the High Line serving American fare plus a popular brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6857,
        store_longitude: -73.9813,
        total_orders: 35.34,
        neighborhood: 'Boerum Hill',
        keywords:
            'Salad, Wine, Martini, Beer, Meatloaf, Italian, Mexican, Chinese, Swiss, American',
        annual_dol: 144.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7405,
        store_longitude: -74.0017,
        total_orders: 9.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Wine, Vietnamese, Italian, Beer, French, American, Casual, Asian, Healthy',
        annual_dol: 40.48,
        description:
            '"Cozy Cafe With Quirky Decor Offering French-Influenced Vietnamese Fare (Pho, Noodle Salad, Banh Mi, Spring Rolls, Bao Bun...) Including an All-Day Breakfast And a Variety Of Cocktails And Wines. Health Conscious"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6129,
        store_longitude: -74.1265,
        total_orders: 160.06,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Beer, Burger, Pizza, Gluten Free, Salad, Vodka, Ale, French, Homemade, Classy',
        annual_dol: 169.9,
        description:
            'Friendly sports bar (sans garden) offers pub grub (including gluten-free) & plenty of beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.739,
        store_longitude: -73.8101,
        total_orders: 47.71,
        neighborhood: 'Fresh Meadows/Flushing/Queensboro Hill',
        keywords:
            'Steak, Tasty, Argentinian, Pizza, Fresh, Italian, Authentic, Homemade, Uruguayan, Amazing',
        annual_dol: 57.13,
        description:
            'Neighborhood bar & grill serving steaks & other Argentinean grilled specialties, plus seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.723,
        store_longitude: -73.9826,
        total_orders: 190.85,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Delicious, Mediterranean, Upscale, Indulgent, Malaysian, Refined, Caesar Salad',
        annual_dol: 780.14,
        description:
            'A fish-forward menu is served in a vibrant setting with nautical accents & raw fish on beds of ice.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.9768,
        total_orders: 6454.88,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Hamburger, Beer, Coffee, Vodka, Bbq, American, Southern, Premium, Casual',
        annual_dol: 22660.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9565,
        total_orders: 3184.44,
        neighborhood: 'Williamsburg',
        keywords:
            'Whiskey, Wine, Vodka, Tasty, Beer, Burger, Pizza, Bourbon, Delicious, Comfortable',
        annual_dol: 3252.01,
        description:
            'Liquor store offering 99 varieties of scotch, bourbon & rye whiskey, plus wine & other spirits.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9565,
        total_orders: 878.1,
        neighborhood: 'Williamsburg',
        keywords: 'Vodka, Beer, Wine, Margarita, Rum, Bbq, Spacious, Relaxed, Southern, Fancy',
        annual_dol: 916.17,
        description:
            'BBQ specialist serving ribs & brisket with shared tables & cafeteria-style ordering.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 207.45,
        neighborhood: 'Astoria/LIC',
        keywords: 'Wine, Beer, Whiskey, Upscale, Irish, Salad, Spicy, Friendly, Fun, Traditional',
        annual_dol: 213.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -73.9752,
        total_orders: 66.25,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Salad, Wine, Beer, Sashimi, Japanese, Spicy, Contemporary, Miso Soup, Sleek',
        annual_dol: 270.82,
        description:
            'Sashimi & sushi with omakase options presented in a sophisticated, minimalist-chic atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6582,
        store_longitude: -73.6477,
        total_orders: 1754.43,
        neighborhood: '',
        keywords: 'Beer, Vodka, Trendy, Delicious, Ale, Clean, Classy, Perfect, Fresh, American',
        annual_dol: 1784.46,
        description:
            '"Tap Room is your neighborhood spot for delicious house made pub fare and an ever evolving craft beer and cocktail selection. We take great pride in providing fresh, high-quality food from our very own scratch kitchen (open late night!). There is plenty to enjoy, from our famous mussels to our hand-crafted burgers. With 24 craft beers on tap and seasonal cocktail menus, we have the perfect drinks to compliment any meal. Whether having family dinner, ladies night out, or watching the big game, there is something for everyone. We are fully equipped with over 15 flat screen TVs, a state-of-the-art entertainment system, and the NFL Sunday Ticket package."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7241,
        store_longitude: -73.5638,
        total_orders: 1013.98,
        neighborhood: '',
        keywords:
            'Beer, Burger, Steak, American, Belgian, Delicious, Sangria, Classic, Local, Casual',
        annual_dol: 1035.49,
        description:
            '"Our vision for Garden Social comes from the desire to share with others the benefits of what a solid friendship can offer to life. Making time to gather and bring people that live on Long Island an experience that they can be proud to be a part of. A place to go that feels like home. We believe we have designed a venue to serve the local community and offer an atmosphere that adds to relationships and life."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7048,
        store_longitude: -74.0067,
        total_orders: 563.32,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tequila, Vodka, Whiskey, Champagne, Beer, Gin, Rum, Vintage, Sweet, Retro',
        annual_dol: 2302.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6569,
        store_longitude: -73.6415,
        total_orders: 2983.26,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Steak, Sushi, Salad, Shrimp Scampi, Italian, Spicy, Savory, Fried Clams',
        annual_dol: 3518.89,
        description:
            'Contemporary grill chain offering a seafood-centric menu, plus steaks & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.8558,
        total_orders: 2036.38,
        neighborhood: 'Flushing/Corona',
        keywords: 'Pizza, Italian, Dessert, Spacious, Perfect, Amazing, Great, Friendly, Fine',
        annual_dol: 2317.53,
        description:
            'Long-time destination for old-world red-sauce Italian fare served by waiters in tuxedos.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.9159,
        total_orders: 593.67,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Burger, Beer, Gourmet, Wine, Cozy, American, Quaint, Eclectic, Casual, Seasonal',
        annual_dol: 696.21,
        description: '"A cocktail bar with an eclectic burger and fries menu."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6514,
        store_longitude: -73.8691,
        total_orders: 112.45,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Italian, Salad, Flavorful, Bruschetta, Black Russian, Margarita, Russian, Iconic, Classic, Great',
        annual_dol: 275.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7799,
        store_longitude: -73.6496,
        total_orders: 755.99,
        neighborhood: '',
        keywords:
            'Chinese, Pizza, Coffee, Wine, American, Classy, Local, Wonton Soup, Casual, Friendly',
        annual_dol: 823.91,
        description:
            '"A family friendly restaurant that has been doing business in Roslyn Heights for almost 40 years. Provide American and Authentic Chinese food choices for take out, dine in or delivery"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7255,
        store_longitude: -74.0074,
        total_orders: 243.51,
        neighborhood: 'Hudson Square',
        keywords:
            'Wine, Beer, Salad, Cozy, Romantic, Juicy, Mediterranean, Delicious, Homemade, Local',
        annual_dol: 291.59,
        description:
            'Small haunt with a cool vibe offering regional beer & wine to go, with charcuterie & bar snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7048,
        store_longitude: -74.0145,
        total_orders: 1972.52,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Gluten Free, Beer, Delicious, Luxurious, French Onion Soup, French, Southern, American',
        annual_dol: 2246.56,
        description:
            'Upmarket venue within Battery Park featuring an expansive terrace with Statue of Liberty views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.5563,
        total_orders: 2177.22,
        neighborhood: '',
        keywords: 'Steak, Burger, Tacos, Italian, Vodka, Delicious, Juicy, Swiss, Creamy, Irish',
        annual_dol: 2342.74,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7237,
        store_longitude: -74.0097,
        total_orders: 26.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Whiskey, Beer, Dessert, Hotel, Elegant, Friendly, Greek, Cool, Contemporary',
        annual_dol: 106.72,
        description:
            '"North Bar is cocktail - wine bar located in a historic landmark in the most northern part of TriBeCa. We provide high-end cocktails, a plethora of wine, draft beer, and a contemporary twist on seafood bites and bar food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6988,
        store_longitude: -73.9171,
        total_orders: 312.68,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Ample, Great, Casual',
        annual_dol: 1178.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7307,
        store_longitude: -74.0004,
        total_orders: 6936.21,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Legendary, Spicy, American, Tangy, Premium, Intimate, Contemporary, Hip, Great',
        annual_dol: 7094.75,
        description:
            'Legendary jazz musicians take the stage at this intimate club that also serves American eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6699,
        store_longitude: -73.9581,
        total_orders: 778.99,
        neighborhood: 'Crown Heights',
        keywords: 'Beer, Pizza, Wine, Tasty, Warm, Cozy, Hotel, Friendly, Cool, Great',
        annual_dol: 906.33,
        description:
            'Down-to-earth hangout with a convivial vibe, providing craft beers on tap & classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7265,
        store_longitude: -74.0035,
        total_orders: 513.95,
        neighborhood: 'South Village/SoHo',
        keywords: 'Italian, Pizza, Wine, Delicious, Rustic, Elegant, Organic, Hotel, Yummy, Classy',
        annual_dol: 524.86,
        description:
            'Tuscan-Italian cuisine, wine & cocktails served in an elegantly appointed, whitewashed space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8404,
        store_longitude: -73.6344,
        total_orders: 59.09,
        neighborhood: '',
        keywords: 'Pizza, Dessert, Italian, Vodka, Chicken Parmigiana, Savory, Tasty, Creamy, Fine',
        annual_dol: 111.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7305,
        store_longitude: -74.0006,
        total_orders: 1074.63,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Beer, Wine, Delicious, Ale, Nachos, Upscale, American, Phenomenal, Irish',
        annual_dol: 1146.67,
        description: 'Bi-level sports bar near NYU offers long happy hours & bar food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7248,
        store_longitude: -74.0022,
        total_orders: 365.83,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Tasty, Spanish, Dessert, Steak, Mediterranean, Seasonal, Contemporary, Lively, Vibrant, Sangria',
        annual_dol: 448.86,
        description:
            'Creative Barcelona-style tapas made with market-fresh ingredients in a warm, modern space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9792,
        total_orders: 155.45,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Elegant, Spacious, Hotel, Sleek, Sustainable, Rooftop, Perfect, Fantastic, Eclectic, Modern',
        annual_dol: 507.51,
        description:
            '"With 268 seats, the elegant, intimate Weill Recital Hall is home each season to hundreds of recitals, chamber music concerts, panel discussions, and master classes. At many of these events, you’re likely to find young musicians making their New York debuts."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7051,
        store_longitude: -73.8092,
        total_orders: 149.6,
        neighborhood: 'Jamaica',
        keywords:
            'Beer, Dominican, Jamaican, Ale, Irish, American, Latin, Welcoming, Sangria, Perfect',
        annual_dol: 430.88,
        description:
            '"Local pub happily serving our many loyal regulars and welcoming all new faces passing by."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7649,
        store_longitude: -73.9915,
        total_orders: 841.61,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Tasty, Bbq, French, American, Delicious, Irish, Casual, Sweet',
        annual_dol: 9519.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7671,
        store_longitude: -73.9831,
        total_orders: 337.28,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vegan, Pizza, Beer, Wine, Coffee, Donut, Trendy, American, Awesome, Iconic',
        annual_dol: 1378.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7676,
        store_longitude: -73.9905,
        total_orders: 177.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Magical, Warm, Adventurous, Creative, Organic, Modern, Approachable, Diverse',
        annual_dol: 222.99,
        description:
            "Ars Nova is an Off-Broadway, non-profit theater in New York City's Hell's Kitchen neighborhood. Ars Nova develops and produces theater, comedy and music created by artists in the early stages of their careers.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.9856,
        total_orders: 1455.72,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Whiskey, Vodka, Martini, Gin, Sushi, Hotel, Impeccable, Lively, Vibrant',
        annual_dol: 1455.72,
        description: '"Neighborhood cocktail bar serving Spanish-style fare in a low-key setting"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.718,
        store_longitude: -73.9922,
        total_orders: 4243.58,
        neighborhood: 'Bowery',
        keywords:
            'Scotch, Spicy, Meatball, Savory, Upscale, Italian, French Onion Soup, French, Eclectic, Homemade',
        annual_dol: 4333.62,
        description:
            "Contemporary American cooking in a '70s-style space with colorful furnishings & a downstairs bar.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7139,
        store_longitude: -73.9899,
        total_orders: 6143.63,
        neighborhood: 'Two Bridges',
        keywords: 'Whiskey, Vodka, Beer, Cajun, Martini, Delicious, Chinese, French, Organic, Bbq',
        annual_dol: 6273.99,
        description:
            'Bar with palm trees, a leopard-print pool table & a small menu of oysters, borscht & dumplings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7147,
        store_longitude: -73.9909,
        total_orders: 3460.75,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Beer, Wine, Whiskey, Warm, Cozy, Cajun, Lively, Fun, Sweet, Intimate',
        annual_dol: 3610.8,
        description:
            'Hip, low-key barroom with cocktails, beer & wine in a cozy space with a pressed-tin ceiling.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8897,
        store_longitude: -73.8986,
        total_orders: 171.91,
        neighborhood: 'Fieldston/West Bronx',
        keywords:
            'Tacos, Salad, Butter Chicken, Mexican, Savory, Delicious, Cozy, Friendly, American, Casual',
        annual_dol: 255.47,
        description: '"Bar Restaurant - Urban meets Indian - Fusion Amerian/Indian"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7484,
        store_longitude: -73.9927,
        total_orders: 41.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Pizza, Upscale, Friendly, Awesome, Great, Casual, Local',
        annual_dol: 167.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.8039,
        total_orders: 14.27,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Steak, Coffee, Bbq, Spicy, Casual, Great',
        annual_dol: 58.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8649,
        store_longitude: -73.9271,
        total_orders: 131.97,
        neighborhood: 'Fort George',
        keywords:
            'Burger, Vegetarian, Salad, Dessert, Wine, Japanese, Quaint, Warm, Eclectic, Latin',
        annual_dol: 146.94,
        description:
            'Casual, warm cafe serving New American & small Latin inspired bites plus wine & craft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6873,
        store_longitude: -73.9753,
        total_orders: 3148.12,
        neighborhood: 'Fort Greene',
        keywords: 'Burger, Tacos, Beer, Wine, Delicious, Nachos, Fun, Turkish, Fancy, American',
        annual_dol: 3220.67,
        description:
            'Relaxed pub serving burgers, fried bites, tacos & wings, plus margaritas, cocktails & draft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7324,
        store_longitude: -73.9993,
        total_orders: 263.58,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Italian, Upscale, Salad, Warm, Fun, Awesome, Lively, High-End, Great',
        annual_dol: 286.84,
        description:
            "Buzzing carriage house from Joe Bastianich's hospitality group featuring high-end Italian fare.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9989,
        total_orders: 18.21,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Wine, Chinese, Painkiller, Beer, Delicious, Clean, Relaxed',
        annual_dol: 74.44,
        description:
            'Unassuming restaurant serving Chinese cuisine with a twist amid a relaxed atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7124,
        store_longitude: -73.9518,
        total_orders: 3157.35,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Steak, Pizza, Italian, Salad, Wine, Beer, Delicious, Premium, Spacious',
        annual_dol: 3793.08,
        description:
            'Rustic-chic choice for Italian fare & wine with an outdoor patio used as a beer garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -73.9536,
        total_orders: 342.21,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Coffee, Vegetarian, Wine, Beer, Breakfast Burrito, Delicious, Quaint, Premium, Hip, Retro',
        annual_dol: 441.29,
        description:
            'Quaint spot with yesteryear decor, premium coffee drinks, clever bar concoctions & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.687,
        store_longitude: -73.9622,
        total_orders: 845.95,
        neighborhood: 'Clinton Hill',
        keywords:
            'Japanese, Wine, Martini, Coffee, Tasty, Gin, Contemporary, Eclectic, Modern, B-52',
        annual_dol: 863.9,
        description:
            'Sleek, modern spot offering eclectic Japanese food, cocktails, coffee, art events & film screenings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -73.9783,
        total_orders: 88.48,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Lobster, Salad, Lobster Bisque, Wine, Beer, Funky, Thai, Creamy, Trendy, Fun',
        annual_dol: 111.31,
        description:
            'Brick-walled lounge with quirky decor made from recycled materials, plus frequent DJs & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7156,
        store_longitude: -73.9447,
        total_orders: 1.52,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Steak, Salad, Beer, American, Casual, Local, Classic, Traditional',
        annual_dol: 6.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7412,
        store_longitude: -73.9812,
        total_orders: 1925.07,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Tequila, Mexican, Vodka, Beer, Wine, Salad, Meatball, American, Hotel, Modern',
        annual_dol: 1997.79,
        description:
            'Hip, industrial-chic bar & eatery serving craft cocktails, draft beer, wine & New American eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9801,
        total_orders: 1526.91,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Stylish, Elegant, Hotel, Rich, Cozy, Classy, Delicious, Quaint, Comfortable, Coffee',
        annual_dol: 1640.08,
        description:
            "Featuring kitchens, Nespresso machines and luxe bedding, the colorful, contemporary rooms and suites have free Wi-Fi, flat-screen HDTVs and iPod sound systems. Some add terraces. Suites have sitting areas.There's a 19th-century-inspired restaurant with marble fireplaces and a terrace, serving British cuisine. There's also a wood-beamed pub, plus a speakeasy-style lounge. Free passes to a local gym are offered.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.9813,
        total_orders: 440.94,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Cheesecake, Pizza, Salad, Italian, Dessert, Wine, Meatball, Cosmopolitan, Homey, American',
        annual_dol: 469.92,
        description:
            'Plentiful portions of reliable Southern Italian comfort food served in a relaxing, homey setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9798,
        total_orders: 158.67,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Wine, Upscale, Premium, Delicious, Classy, Italian, Intimate, Eclectic, Quaint, Lively',
        annual_dol: 216,
        description:
            'Very small, lively wine bar offering a changing vino list plus pizza, salads & other nibbles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.9575,
        total_orders: 1140.57,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Vegan, Coffee, Salad, Burger, Dessert, Beer, Avocado Toast, Trendy, Elegant',
        annual_dol: 1190.03,
        description:
            'American eatery popular for brunch, with tin ceiling & zinc bar adding a vintage vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.9809,
        total_orders: 248.21,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Salad, Thai, Green Papaya Salad, Vegan, Wine, Dessert, Tasty, Green Curry, Chinese, Beer',
        annual_dol: 1014.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -73.9949,
        total_orders: 2970.12,
        neighborhood: 'NoHo',
        keywords:
            'Glamorous, Hotel, Elegant, Shrimp Cocktail, Gin, Comfortable, Premium, Iconic, Traditional, Great',
        annual_dol: 9764.62,
        description:
            'Retro, wood-paneled bar offering cocktails, wines & beer, plus snacks like oysters & beef sliders.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7113,
        store_longitude: -74.0153,
        total_orders: 2118.12,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Wine, Salad, Sushi, Coffee, Gourmet, Beer, Delicious, Avocado Toast, Hotel, Rustic',
        annual_dol: 2882.77,
        description:
            'Hudson Eats counter with open sandwiches on rustic bread & gourmet toppings plus salads & desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -74.0018,
        total_orders: 73.69,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Lobster, Lobster Roll, Mexican, Tequila, Vegetarian, Margarita, Vodka, Spicy, Upscale',
        annual_dol: 301.21,
        description:
            '"The co-owners behind Mermaid Inn — Danny Abrams and Cindy Smith — add to the Mermaid family with a new restaurant and a focus on street tacos. From chef Victor Marin’s kitchen, expect a range of tortillas filled with birria, al pastor, and vegetarian options, among others."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7206,
        store_longitude: -73.9966,
        total_orders: 83.81,
        neighborhood: 'Nolita',
        keywords:
            'Pizza, Vodka, Italian, Beer, Wine, Salad, New-York-Style, Delicious, Iconic, Fresh',
        annual_dol: 114.3,
        description:
            'Wood-fired Italian cuisine made using family recipes is served in a classic space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9975,
        total_orders: 170.15,
        neighborhood: 'Lower Manhattan',
        keywords: 'Mexican, Salad, Organic, Delicious, Healthy, Casual, Local',
        annual_dol: 695.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7158,
        store_longitude: -73.987,
        total_orders: 848.55,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegan, Pizza, Salad, Coffee, Italian, Delicious, Meatball, Warm, Impeccable, Martini',
        annual_dol: 866.55,
        description:
            'Brick-oven pizzas, sandwiches & pastas, plus coffee & a full bar, in a casual, old-world atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7657,
        store_longitude: -73.9808,
        total_orders: 585.63,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Chocolate Cake, Hotdog, Cheesecake, Tacos, Milkshake, Salad, Tasty, Delicious, Prosecco, Reuben Sandwich',
        annual_dol: 639.46,
        description:
            'Set near Carnegie Hall, this nostalgic high-end diner offers both classic & creative dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -73.993,
        total_orders: 7.26,
        neighborhood: 'Bowery',
        keywords: 'Steak, Vodka, Coffee, Fun, Casual, Wonderful, Great',
        annual_dol: 29.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9777,
        total_orders: 15.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Japanese, Spicy, Delicious, Modern, Casual, Clean, Fresh, Traditional, Tempura',
        annual_dol: 22.69,
        description:
            'Artfully presented Japanese dishes, sushi rolls & sake served in sleek, modern surrounds with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8573,
        store_longitude: -73.9321,
        total_orders: 1568.95,
        neighborhood: 'Fort George/Washington Heights',
        keywords: 'Burger, Beer, Scotch, Swiss, American, Bourbon, Peruvian, Asian, Fusion, Casual',
        annual_dol: 1673.24,
        description:
            'Large selection of craft beers & bourbons in a casual little drinking spot with pub grub & sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6339,
        store_longitude: -73.5818,
        total_orders: 102.46,
        neighborhood: '',
        keywords: 'Hurricane, Shrimp Cocktail, Rum, Awesome, Great, Friendly, Casual',
        annual_dol: 141.13,
        description:
            'Laid-back waterfront bar & grill specializing in seafood, with outdoor seating & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.74,
        store_longitude: -73.9933,
        total_orders: 1056.28,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Burger, Hamburger, Cheesecake, Lobster Bisque, Salad, Beer, Tasty, Juicy',
        annual_dol: 1102.08,
        description:
            'British-born restaurant for lobster rolls, lobsters & burgers in a big space with a happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7362,
        store_longitude: -73.9965,
        total_orders: 210.08,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Karaoke, Wine, Burger, Nachos, Contemporary, Homemade, French, Teriyaki, Great, Casual',
        annual_dol: 858.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7935,
        store_longitude: -73.813,
        total_orders: 313.38,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Garlic Bread, Lasagna, Vegetarian, Pizza, Italian, Delicious, Cozy, Homemade, Baked Ziti, Friendly',
        annual_dol: 400.42,
        description:
            'Comfy, established eatery featuring old-school Italian cuisine such as baked ziti & veal scaloppine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -74.0089,
        total_orders: 98.65,
        neighborhood: '',
        keywords:
            'Burger, Wine, Rooftop, Hotel, Salad, Filipino, Incredible, Great, Perfect, Classic',
        annual_dol: 403.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6318,
        store_longitude: -73.5818,
        total_orders: 68.08,
        neighborhood: '',
        keywords: 'Burger, Pizza, Mexican, Bbq, Ale, American, Hurricane, Thai, African, Homemade',
        annual_dol: 98.14,
        description: '"BBQ restaurant and bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6768,
        store_longitude: -73.972,
        total_orders: 3654.97,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords: 'Wine, Beer, Cozy, Rum, Friendly, Sweet, Perfect, Casual, Classic, Great',
        annual_dol: 3734.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7148,
        store_longitude: -73.9416,
        total_orders: 2255.64,
        neighborhood: 'East Williamsburg',
        keywords: 'Wine, Whiskey, Beer, Vegetarian, Gin, Cozy, Spicy, Vintage, Friendly, Classy',
        annual_dol: 2360.49,
        description:
            'Cocktails & a long whiskey list make up the menu at this moody bar created with salvaged materials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7136,
        store_longitude: -73.9576,
        total_orders: 880.34,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Tasty, Coffee, Mimosa, Cuban, Delicious, Impeccable, Mediterranean, Screwdriver, Seasonal',
        annual_dol: 1308.24,
        description:
            'Chill Mediterranean eatery with ample outdoor seating in a backyard space adorned with ivy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7175,
        store_longitude: -73.9635,
        total_orders: 275.12,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Seafood Paella, Margarita, Dominican, Puerto Rican, Spanish, Delicious, Latin, Grand Marnier, American',
        annual_dol: 518.29,
        description:
            'Laid-back establishment with colorful surrounds offering Latin American cuisine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7501,
        store_longitude: -73.9815,
        total_orders: 22.79,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Lobster, Coffee, Wine, French, Beer, Quesadillas, American, Casual, Polished',
        annual_dol: 93.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7654,
        store_longitude: -73.9759,
        total_orders: 3781.22,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Wine, Champagne, Hotel, Vodka, Luxurious, Coffee, Beer, Elegant, Gin, Peruvian',
        annual_dol: 4368.26,
        description:
            'Plush rooms feature luxe linens and marble bathrooms with soaking tubs, as well as minibars, flat-screens and Wi-Fi (fee). Most have park or city views. Suites add dining rooms and living rooms with pull-out sofas. Club-level quarters provide access to a lounge with free food, drinks and Wi-Fi. Room service is available 24/7.Dining options include a sophisticated bistro and a renowned bar overlooking Sixth Avenue, plus an ornate cocktail lounge. There’s also a high-end spa, a fitness center and event space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9841,
        total_orders: 284.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'American, Classic, Outstanding, Perfect, Great, Excellent',
        annual_dol: 1163.68,
        description:
            'The Circle in the Square Theatre is a Broadway theater at 235 West 50th Street, in the basement of Paramount Plaza, in the Midtown Manhattan neighborhood of New York City. It is one of two Broadway theaters that use a thrust stage that extends into the audience on three sides.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7182,
        store_longitude: -73.9863,
        total_orders: 46.59,
        neighborhood: 'Lower East Side',
        keywords: 'Hotel, Luxurious, Wonderful, Clean, Terrific, Signature',
        annual_dol: 176.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -74.0083,
        total_orders: 5736.54,
        neighborhood: '',
        keywords: 'Champagne, Wine, Beer, Coffee, Iconic, Classic, Wonderful, Great, Complex, Fine',
        annual_dol: 6079.59,
        description:
            'Glass-roofed boats set sail around New York Harbor for lunch/brunch/dinner cruises & dancing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.741,
        store_longitude: -73.9981,
        total_orders: 236.75,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Beer, Wine, Italian, Spicy, Delicious, Warm, Cozy, Bbq, Comfortable',
        annual_dol: 241.78,
        description:
            'Comfortable place for updated American classics with cocktails, beer & Prosecco on tap.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6784,
        store_longitude: -73.9586,
        total_orders: 1849.93,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Beer, Delicious, Spicy, Gin, Fun, Japanese, Amazing, Nachos, Friendly',
        annual_dol: 3631.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7122,
        store_longitude: -73.9574,
        total_orders: 464.87,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Wine, Salad, Beer, Ale, Bourbon, Savory, Organic, Cozy, Rich, Spacious',
        annual_dol: 506.63,
        description:
            'Beer-loving soccer fans favor this pub for its big brew selection & televised Euro football matches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 42.61,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Burger, Steak, Hotdog, Gourmet, Beer, Coffee, American, Delicious, Southern, Casual',
        annual_dol: 174.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.9838,
        total_orders: 1240.11,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Sushi, Wine, Chilean, Bbq, French Onion Soup, French, Hotel',
        annual_dol: 1266.42,
        description:
            'Prime USDA steaks & seafood, plus martinis & wine, presented in a warm, sophisticated setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -73.9815,
        total_orders: 258.77,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Karaoke, Sushi, Burger, Hawaiian, Japanese, Korean, Sashimi, American, Coleslaw, Hotel',
        annual_dol: 423.34,
        description:
            'Lively karaoke place with private party rooms, Japanese fare including sushi & late hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8535,
        store_longitude: -73.7903,
        total_orders: 652.43,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Salad, Steak, Italian, Shrimp Scampi, Beer, Hotel, Delicious, Awesome, Authentic',
        annual_dol: 750.5,
        description:
            'Old-school seafood-focused Italian cooking in a longtime spot with outdoor seating & views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7453,
        store_longitude: -73.9534,
        total_orders: 10340.31,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Salad, Mexican, Dessert, Wine, Beer, Delicious, Burritos, Cuban, Peruvian',
        annual_dol: 11776.9,
        description:
            'Casual cafe & lounge serving dishes from various Latin cuisines along with cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7514,
        store_longitude: -73.9315,
        total_orders: 14533.93,
        neighborhood: 'Astoria/LIC',
        keywords: 'Spacious',
        annual_dol: 14842.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -73.9423,
        total_orders: 364.32,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Burger, Steak, Salad, Wine, French, Casual, Bold, Great',
        annual_dol: 435.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.727,
        store_longitude: -73.9891,
        total_orders: 1563.96,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Vodka, Spicy, Warm, Delicious, Rum, Creamy, Rustic, Asian',
        annual_dol: 1597.15,
        description:
            'Artfully distressed gastropub boasting elevated, thoughtfully sourced comfort chow & craft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -73.9857,
        total_orders: 718.99,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Hotdog, Beer, Upscale, Cozy, Mediterranean, Intimate, American, Friendly, Great',
        annual_dol: 815.07,
        description:
            'Unassuming watering hole in an old tenement home with wine, craft cocktails & upscale snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -73.9936,
        total_orders: 936.23,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegan, Burger, Salad, Gluten Free, Vegetable Stirfry, Organic, Delicious, Upscale, American, Innovative',
        annual_dol: 3545.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8,
        store_longitude: -73.9684,
        total_orders: 399.29,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Salad, Shrimp Cocktail, Champagne, Wine, Gluten Free, French, Savory, Mimosa, Organic, Delicious',
        annual_dol: 1144.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6013,
        store_longitude: -74.0859,
        total_orders: 27.14,
        neighborhood: 'Todt Hill/Concord',
        keywords: 'Sweet, French, Wonderful, Timeless, Memorable',
        annual_dol: 102.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.644,
        store_longitude: -73.9294,
        total_orders: 10.46,
        neighborhood: 'Little Caribbean/East Flatbush',
        keywords: 'Karaoke, Hotel, Fun, Caribbean, Sweet, Hip, Great, Lit, Casual, Chill',
        annual_dol: 42.76,
        description:
            'Cocktails, pub fare & karaoke nights offered in a low-lit, moody space with banquette & bar seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -73.9888,
        total_orders: 34.43,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Champagne, Whiskey Sour, Spicy, Whiskey, Delicious, Warm, Beer, Gin, Thai, Sweet',
        annual_dol: 140.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -73.9886,
        total_orders: 77.62,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Trendy, Hotel, Vibrant, Bright, Iconic, Premium, Friendly, Classic, Mediterranean, Fresh',
        annual_dol: 317.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.8331,
        total_orders: 747.63,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Bbq, Local, Casual, Aesthetic',
        annual_dol: 2618.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9667,
        total_orders: 3614.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Cheesecake, Burger, Salad, Wine, Beer, Irish, New-York-Style, American, Perfect, Fun',
        annual_dol: 3733.8,
        description:
            "Intimate Irish pub with a fireplace & sports on TV serving draft beers & grub like shepherd's pie.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.962,
        total_orders: 504.79,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Lobster, Steak, Salad, French Onion Soup, Dessert, French, Wine, Spicy, Swiss, Caesar Salad',
        annual_dol: 515.5,
        description:
            'Upscale-casual French bistro in an airy townhouse setting with a terrace & a long wood bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6306,
        store_longitude: -74.0283,
        total_orders: 940.51,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Pizza, Mexican, Salad, Coffee, Shrimp Cocktail, Avocado Toast, Creamy, American, Cozy',
        annual_dol: 1214.28,
        description:
            '"Closed for vacation, back on Sept 8th! Craft food. Craft drinks. Independently owned; focused on hospitality and on the details of food and drinks."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9723,
        total_orders: 2829.85,
        neighborhood: 'Lenox Hill',
        keywords: 'Champagne, Hotel, American, Fancy, Rooftop, Mead, Perfect, Unique',
        annual_dol: 3209.08,
        description:
            "The Metropolitan Club of New York is a private social club on the Upper East Side of Manhattan in New York City. It was founded as a gentlemen's club in 1891 for men only, but it was one of the first major clubs in New York to admit women, though they still represent a minority.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6952,
        store_longitude: -73.9963,
        total_orders: 532.8,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Burger, Cajun, Beer, Italian, Vodka, Wine, Spicy, Delicious, Stylish, Mediterranean',
        annual_dol: 847.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9896,
        total_orders: 129.48,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Nutritious, Salad, Delicious, Dessert, Chinese, Spicy, Savory, Creamy, Thai, Sweet',
        annual_dol: 529.27,
        description:
            'Elevated Beijing specialties are offered in this traditional Chinese-style setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7018,
        store_longitude: -73.9956,
        total_orders: 19663.97,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Hotel, Wine, Coffee, Delicious, Sustainable, Urban, Funky, Rooftop, Great, Perfect',
        annual_dol: 20177.44,
        description:
            "Industrial-chic rooms feature free Wi-Fi, smart TVs, Nespresso machines and yoga mats; most offer river, park or Statue of Liberty views. Suites have living areas and sofabeds; some add separate lounges. An upgraded 3-bedroom apartment features a dining area, a minibar, loaner bikes and a soaking tub. Room service is available 24/7.Amenities include a hip restaurant and a seasonal rooftop bar offering city views. There's also a screening room and a gym.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9852,
        total_orders: 610.38,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Milkshake, Salad, Avocado Toast, Savory, Impeccable, Warm, French Toast, Perfect, American, French',
        annual_dol: 707.37,
        description:
            'All-American comfort food for breakfast, lunch & dinner in a nostalgic diner-style space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9899,
        total_orders: 896.15,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Burritos, Premium, Delicious, Comfortable, Casual, Iconic, Unique',
        annual_dol: 955.72,
        description:
            'Snug, brick-walled bottle shop & bar with a laid-back vibe, with panini, bratwursts & other eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6356,
        store_longitude: -73.6403,
        total_orders: 183.49,
        neighborhood: '',
        keywords:
            'Burger, Chicken Caesar Salad, Salad, Beer, Wine, Bbq, Caesar Salad, Mediterranean, American, Prime Rib',
        annual_dol: 755.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9807,
        total_orders: 1483.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Italian, Great',
        annual_dol: 1548.05,
        description:
            'The Century Association is a private social, arts, and dining club in New York City, founded in 1847. Its clubhouse is located at 7 West 43rd Street near Fifth Avenue in Midtown Manhattan. It is primarily a club for men and women with distinction in literature or the arts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9964,
        total_orders: 2189.2,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Upscale, Hotel, Elegant, Spacious, Urban, Sleek, Exquisite, Lively, Modern, Great',
        annual_dol: 8948.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6633,
        store_longitude: -73.6922,
        total_orders: 68.23,
        neighborhood: '',
        keywords:
            'Beer, Delicious, Friendly, Karaoke, Memorable, Classic, Great, Fresh, Casual, Omelette',
        annual_dol: 278.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.914,
        total_orders: 3310.76,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Cozy, Hotel, Eclectic, Great, Incredible, Cultural, Casual, Unique',
        annual_dol: 3683.56,
        description:
            'Knockdown Center is a cultural space, performance venue, and art center, located in the Maspeth neighborhood of Queens, New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.9906,
        total_orders: 98.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Delicious, Spacious, Quaint, Premium, Comfortable, Classic, Polish, Great, Modern',
        annual_dol: 403.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7533,
        store_longitude: -74.0013,
        total_orders: 346.22,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Salad, Steak, Organic, Scotch, Sustainable, Martini, Warm, Fun, Casual',
        annual_dol: 1415.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8059,
        store_longitude: -73.9541,
        total_orders: 1793.62,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Hotdog, Beer, Pizza, Coffee, Vegetarian, Bourbon, Warm, Upscale, Cozy, American',
        annual_dol: 1903.28,
        description:
            'Cozy bar pouring craft beer, bourbon & original cocktails in a narrow space with a back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8031,
        store_longitude: -73.9566,
        total_orders: 6237.2,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Southern Comfort, Wine, Bbq, Southern, Fried Chicken, Homey, Creamy, Relaxed, Comfortable',
        annual_dol: 13385.24,
        description:
            "Southern classics served in a retro setting that's relaxed & homey by day, bustling in the evening.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7661,
        store_longitude: -73.7893,
        total_orders: 174.25,
        neighborhood: 'Bayside/Auburndale/Flushing',
        keywords:
            'Italian, Pizza, Coffee, Spicy, Classy, Outstanding, Fantastic, Southern, Great, Excellent',
        annual_dol: 177.95,
        description:
            'Informal, family-owned bar/eatery with a neighborhood vibe preparing Southern Italian specialties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.791,
        store_longitude: -73.5889,
        total_orders: 691.8,
        neighborhood: '',
        keywords: 'Golfclub, Classic, Premium, Excellent, Wonderful',
        annual_dol: 826.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9885,
        total_orders: 1025.08,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Cheesesteak, Cuban, South American, Colombian, Spicy, Hawaiian, American, Swiss, Latin',
        annual_dol: 1046.83,
        description:
            'Stuffed empanadas & arepas top a menu of Latin goodies like plantain chips & guacamole served 24/7.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7645,
        store_longitude: -73.9891,
        total_orders: 1821.23,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotdog, Vodka, Thai, Russian, Cozy, Premium, Awesome, Friendly, Authentic, Great',
        annual_dol: 6861.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -73.9914,
        total_orders: 1058.72,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Sushi, Salad, Wine, Italian, Vodka, Austrian, Martini, Meatball, Bourbon, Rustic',
        annual_dol: 1081.19,
        description:
            'Chic, candlelit basement eatery serving pastas & Venetian small plates alongside Italian wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6155,
        store_longitude: -74.0341,
        total_orders: 12.97,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Mexican, Beer, Italian, Irish, Asian, Local, Casual, Traditional, Recommended, Authentic',
        annual_dol: 53.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8673,
        store_longitude: -73.9207,
        total_orders: 89.03,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Steak, Mexican, Salad, Wine, Yummy, Homemade, Traditional, Authentic, Casual, Sangria',
        annual_dol: 363.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7516,
        store_longitude: -73.9761,
        total_orders: 8750.18,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Lobster Roll, Salad, Hawaiian, Italian, French, Cuban, Caribbean, Eclectic, Sweet',
        annual_dol: 10656.86,
        description:
            "Casual clothing store featuring men's & women's apparel, plus shoes & accessories.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7386,
        store_longitude: -73.9935,
        total_orders: 2691.96,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Karaoke, Steak, Ramen, Classic, American, Welcoming, Sweet, Intimate, Fun, Cozy',
        annual_dol: 2749.08,
        description:
            '"Baby Grand has been voted NYC’s greatest karaoke bar! Come see what all the fuss is about!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7418,
        store_longitude: -73.9975,
        total_orders: 7833.24,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Salad, Burger, Irish, Friendly, Casual, Local, Classic, Great, Fine',
        annual_dol: 8081.94,
        description:
            'Classic New York-style Irish pub (since 1936) offering brews on tap & pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7407,
        store_longitude: -73.993,
        total_orders: 6019.04,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Wine, Japanese, Impeccable, Friendly, Traditional, Exquisite, Authentic, Casual, Great',
        annual_dol: 27185.46,
        description:
            'Eight-seat sushi bar devoted to high-end omakase dinners, with a separate lounge area.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6787,
        store_longitude: -73.9826,
        total_orders: 927.13,
        neighborhood: 'Gowanus',
        keywords:
            'Tacos, Vegetarian, Mexican, Texan, Tasty, Bourbon, American, Relaxed, Casual, Homemade',
        annual_dol: 1055.93,
        description:
            'Spacious, open-air bar offering craft cocktails, draft brews & Mexican plates in a hip atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7386,
        store_longitude: -73.992,
        total_orders: 811.32,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Sushi, Wine, Beer, Japanese, Delicious, Homemade, Sleek, Casual, Traditional, Authentic',
        annual_dol: 1241.53,
        description:
            'This sleek bi-level outpost of a Tokyo izakaya chain attracts expats with its Japanese home cooking.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7334,
        store_longitude: -73.8105,
        total_orders: 277.45,
        neighborhood: 'Fresh Meadows/Flushing/Pomonok',
        keywords: 'Fresh, Modern, Spicy, Friendly, Sweet, French, Casual',
        annual_dol: 277.45,
        description:
            'Late-night basement venue featuring traditional, cosmic & league bowling, casual fare & party space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7225,
        store_longitude: -73.9833,
        total_orders: 466.49,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Beer, Burger, Wine, Tasty, Irish, Vibrant, American, Australian, Great, Casual',
        annual_dol: 478.32,
        description:
            'Upbeat tavern featuring typical American food, tap brews, cocktails & multiple TVs for sports fans,',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.9837,
        total_orders: 1002.58,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Beer, Cuban, Delicious, Authentic, Classic, Casual, Amazing, Chill',
        annual_dol: 1023.86,
        description:
            'Basic barroom with pool table & jukebox considered a holdout against East Village gentrification.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.685,
        store_longitude: -73.9627,
        total_orders: 1163.68,
        neighborhood: 'Clinton Hill',
        keywords:
            'Wine, Italian, Dessert, Beer, Rustic, Cozy, Vintage, Authentic, Casual, Terrific',
        annual_dol: 1214.14,
        description:
            'A century-old former drugstore converted into a vintage space serving Northern Italian cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7113,
        store_longitude: -74.0068,
        total_orders: 338.41,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Wine, French, Rustic, Hotel, Polished, Timeless, Classic, Hospitable, Western',
        annual_dol: 1725.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7209,
        store_longitude: -73.9587,
        total_orders: 4246.52,
        neighborhood: 'Williamsburg',
        keywords: 'Australian, Hotel, Rooftop, Great, Israeli, Perfect, Excellent, Chic',
        annual_dol: 4336.62,
        description:
            'miles from the Brooklyn Bridge.Stylish, industrial-chic rooms with brass and concrete details include free Wi-Fi, flat-screen TVs, work desks and en suite bathrooms.There are 3 bars and restaurants including 1 with private dining, and another that has a rooftop terrace offering panoramic city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7137,
        store_longitude: -73.9878,
        total_orders: 62.49,
        neighborhood: 'Two Bridges',
        keywords: 'Wine, Rich, Beer, French Toast, Hotel, French, Salad, American, Global, Great',
        annual_dol: 255.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9877,
        total_orders: 265.98,
        neighborhood: 'Lower East Side',
        keywords:
            'Garlic Bread, Steak, Salad, Italian, Wine, Meatball, Coffee, Spicy, Mimosa, Rustic',
        annual_dol: 296.15,
        description:
            'Cozy wine bar & bistro serving old-world Italian fare in a brick-walled setting with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.714,
        store_longitude: -73.9899,
        total_orders: 1463.69,
        neighborhood: 'Two Bridges',
        keywords: 'Chinese, Coffee, Casual',
        annual_dol: 1527.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9919,
        total_orders: 171.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Tasty, Burger, Salad, Delicious, Wine, Italian, Sweet, Fabulous, Outstanding',
        annual_dol: 197.3,
        description:
            '"Delicious contemporary American cuisine mixed with a stellar cocktail program in the coziest of atmospheres makes The Harrow New York your destination for the perfect night out. Specialties include House-Made Lobster Pierogis, Crispy Duck and don\'t forget our Harrow Burger (one of the best in the city). Featuring sixteen specially crafted unique cocktails, along with an extensive wine list, to be enjoyed solo or paired with any of our decedent dishes. We look forward to seeing you soon."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7139,
        store_longitude: -74.007,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Steak, Wine, Champagne, Hotel, American, Stylish, Sleek, Classic, Warm, Vibrant',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -74.0016,
        total_orders: 67.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Delicious, Moroccan, Cozy, Thai, Mediterranean, Indonesian, Comfortable, Fabulous, Wonderful',
        annual_dol: 330.29,
        description:
            'Cozy, laid-back bistro with Franco-American dishes plus cooking classes & blindfolded-dining events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6383,
        store_longitude: -73.5818,
        total_orders: 12.58,
        neighborhood: '',
        keywords: 'Cuban, Chinese, American, Upscale',
        annual_dol: 72.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.735,
        store_longitude: -74,
        total_orders: 271,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Steak, Salad, Coffee, Mimosa, Wine, Champagne, French, Bellini, Cozy',
        annual_dol: 1107.75,
        description:
            'Cozy, brick-walled bistro with classic French fare, including steak frites & mussels, plus brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6768,
        store_longitude: -73.9804,
        total_orders: 1428.7,
        neighborhood: 'Park Slope',
        keywords: 'Vodka, Pizza, Wine, Beer, Upscale, Spicy, Rum, Smoky, Vietnamese, American',
        annual_dol: 1459.01,
        description:
            'Quiet, upscale bar/lounge offering meticulously mixed cocktails & bites like cheeses & charcuterie.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6836,
        store_longitude: -73.9756,
        total_orders: 1177.69,
        neighborhood: 'Fort Greene',
        keywords:
            'Burger, Hamburger, Salad, Beer, Dessert, Nachos, Bruschetta, American, Fun, Local',
        annual_dol: 4814.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 104.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Coffee, Wine, Beer, Gourmet, Organic, Italian, Savory, Sustainable, American, Artisanal',
        annual_dol: 425.4,
        description:
            'Chelsea Market is a food hall, shopping mall, office building and television production facility located in the Chelsea neighborhood of the borough of Manhattan, in New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -73.9506,
        total_orders: 1158.19,
        neighborhood: 'Williamsburg',
        keywords:
            'Peruvian, Wine, Delicious, Warm, Swiss, Fun, Venezuelan, Healthy, Amazing, Perfect',
        annual_dol: 1182.76,
        description: 'Restaurant for seasonal, shareable Peruvian dishes & Latin cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6875,
        store_longitude: -73.9624,
        total_orders: 3361.49,
        neighborhood: 'Clinton Hill',
        keywords:
            'Steak, Beer, Meatloaf, Wine, Gourmet, French Toast, Spicy, French, American, Relaxed',
        annual_dol: 3432.82,
        description:
            'Relaxed dining room serving craft beers & gourmet fare, with an attached specialty food shop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7093,
        store_longitude: -74.0068,
        total_orders: 339.17,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Beer, Organic, Delicious, Sustainable, Crisp, Friendly, American',
        annual_dol: 1187.89,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7111,
        store_longitude: -73.9508,
        total_orders: 152.18,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Beer, Wine, Margarita, Hotel, Latin, American, Triple Sec, Fusion, Traditional',
        annual_dol: 223.2,
        description:
            'Warehouse-style space offering a Latin-inspired menu, Sunday brunch & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7468,
        store_longitude: -73.9851,
        total_orders: 24688.46,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Sushi, Salad, Beer, Japanese, Korean, Bbq, Sashimi, American, Asian, Healthy',
        annual_dol: 28272.24,
        description:
            'Patrons fill up on a vast assortment of Japanese eats from sushi to cooked fare at this buffet spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7493,
        store_longitude: -73.9812,
        total_orders: 5375.79,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Outstanding, Wonderful, Unforgettable',
        annual_dol: 5876.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9245,
        total_orders: 233.83,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Mexican, Tacos, Salad, Pinacolada, Rustic, Latin, Casual, Tranquil, Relaxed',
        annual_dol: 607.05,
        description:
            'Warm, rustic Mexican restaurant serving Tulum-inspired cuisine & drinks plus sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9654,
        total_orders: 2069.98,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Wine, Whiskey, Scotch, Salad, Vegan, Peruvian, Chinese, Delicious, Spicy, Ale',
        annual_dol: 2669.33,
        description:
            '"Restaurant and bar welcomes you to our Williamsburg 66 s 2nd street. At antidote our menu is combination of sophisticated & authentic Chinese cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7493,
        store_longitude: -73.9829,
        total_orders: 44.52,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tequila, Beer, Wine, Legendary, Thai, Dessert, Korean, Asian, Cozy',
        annual_dol: 184.2,
        description:
            '"Sister bar to the legendary Murray Hill beer bar The Ginger Man, Under The Volcano was originally opened in the 80\'s in Houston TX by Robert Precious. The NYC location first started in 2000 as a groundbreaking mezcal specialty bar and is now re-open as a cozy neighborhood tavern for everyone in Murray Hill and Midtown."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.752,
        store_longitude: -73.9818,
        total_orders: 79.93,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Rooftop, Coffee, Spectacular, Healthy, Iconic, Outstanding, Modern, Excellent, Local, Unique',
        annual_dol: 326.71,
        description:
            'The Stavros Niarchos Foundation Library, popularly known as the Mid-Manhattan Library, is a branch of the New York Public Library at the southeast corner of 40th Street and Fifth Avenue in the Midtown Manhattan neighborhood of New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9805,
        total_orders: 8293.41,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Tail, Steak, Shrimp Cocktail, Lobster Bisque, Wine, Upscale, Chinese, American, Modern',
        annual_dol: 8609.93,
        description:
            'Upscale chain known for its sophisticated decor, long wine list & classic steak & seafood menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8098,
        store_longitude: -73.95,
        total_orders: 2020.42,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Lobster, Pizza, Salad, Cajun, Martini, Beer, Wine, Asian, Delicious, American',
        annual_dol: 2237.86,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7677,
        store_longitude: -73.9849,
        total_orders: 1878.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Dessert, Wine, Irish, American, Warm, Modern, Casual, Friendly',
        annual_dol: 1924.01,
        description:
            'Longtime watering hole doling out traditional Irish pub grub, beer & more in plush surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9825,
        total_orders: 1363.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Chocolate Mousse, Pizza, Italian, Spicy, Elegant, Sweet, Friendly, Entertaining, Vintage',
        annual_dol: 1422.38,
        description:
            'Vintage Neapolitan eatery near Carnegie Hall with a history of entertaining high-profile patrons.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7182,
        store_longitude: -73.9899,
        total_orders: 889.85,
        neighborhood: 'Lower East Side',
        keywords: 'Cuban, Margarita, Bbq, Pinacolada, Whimsical, Fun, Daiquiri, Incredible, Casual',
        annual_dol: 928.43,
        description: '"Neon-lit bar serving fun, whimsical cocktails with elevated comfort food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.9941,
        total_orders: 171.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Meatloaf, Salad, Vegan, Meatball, Beer, Whiskey, Wine, Rustic, Mediterranean',
        annual_dol: 700.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6297,
        store_longitude: -73.581,
        total_orders: 72.54,
        neighborhood: '',
        keywords: 'Dominican, Dessert, Outstanding, Wonderful, Great, Casual',
        annual_dol: 296.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -73.9975,
        total_orders: 801.67,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegetarian, Vegan, Pizza, Salad, Italian, Wine, Rustic, Flavorful, Organic, Casual',
        annual_dol: 1064.56,
        description:
            'Cozy, rustic Italian restaurant featuring seafood & vegetarian dishes, plus cocktails & 2 bars.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -74.0055,
        total_orders: 1625.06,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Italian, Coffee, Vodka, Wine, Creamy, Fresh, Smooth, Casual, Great',
        annual_dol: 1659.55,
        description:
            'Big slices with creamy artichoke topping & other unique twists draw crowds to this casual pie spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9773,
        total_orders: 43.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Burger, Shrimp Cocktail, Chicken Caesar Salad, Salad, Karaoke, Korean, Delicious, American, Caesar Salad',
        annual_dol: 194.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7111,
        store_longitude: -73.9482,
        total_orders: 500.96,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Beer, Upscale, Gin, American, Italian, Cozy, Homey, Classy, Fancy, Classic',
        annual_dol: 1758.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6828,
        store_longitude: -73.9644,
        total_orders: 195.94,
        neighborhood: 'Clinton Hill',
        keywords: 'Wine, Salad, Coffee, Dessert, French, Cozy, Stylish, Relaxed, American, Casual',
        annual_dol: 218.01,
        description:
            'In-house butchery and baking go into American bistro dishes in a bright, stylish space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7105,
        store_longitude: -74.0069,
        total_orders: 524.11,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Karaoke, Coffee, Hotel, Funky, Stylish, Modern, Great, Unique',
        annual_dol: 546.84,
        description:
            'Modern rooms feature high ceilings, and have free Wi-Fi, and flat-screen TVs with plug and play electrical hubs, plus coffeemakers, minifridges, and desks with ergonomic chairs. Kids age 12 and under stay at no charge with an adult.Amenities include a funky lounge with a pool table, and a loft-inspired cocktail bar serving snacks. There’s also a 24/7 convenience store and a fitness center. A business center and meeting space is available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7611,
        store_longitude: -73.9237,
        total_orders: 2963.36,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegetarian, Wine, Mexican, Beer, Dessert, Spacious, French, Latin, American, Casual',
        annual_dol: 3224.89,
        description:
            '"3308 Eats & Drinks Newest and dopest spot in Queens, serving specialty drinks and amazing food. Bar classics with a Latin twist. COME FOR THE DRINKS, STAY FOR THE FOOD."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9766,
        total_orders: 499.31,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Beer, Cozy, Karaoke, Irish, Hotel, Fun, Lively, Comfortable, Friendly, Welcoming',
        annual_dol: 520.96,
        description:
            'Neighborhood Irish pub with an all-weekend happy hour, a pool table & sports on TV (but no kitchen).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8471,
        store_longitude: -73.7862,
        total_orders: 599.04,
        neighborhood: 'City Island',
        keywords:
            'Burger, Lobster, Salad, Meatball, Coffee, Italian, American, Chinese, Juicy, Homey',
        annual_dol: 629.24,
        description:
            'Homey diner with counter stools & an old-fashioned feel dishing up everyday American plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5307,
        store_longitude: -74.2301,
        total_orders: 490.83,
        neighborhood: 'Charleston',
        keywords: 'Hotdog, Gourmet, Nachos, Fun, Complex, Unique',
        annual_dol: 582.86,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8286,
        store_longitude: -73.8813,
        total_orders: 212.47,
        neighborhood: 'East Bronx/Park Versailles/Soundview',
        keywords:
            'Steak, Mexican, Shrimp Cocktail, Gourmet, Spicy, Italian, American, Clean, Casual, Fresh',
        annual_dol: 496.87,
        description:
            'A sizable menu of Mexican dishes is offered by this casual eatery with occasional entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6081,
        store_longitude: -73.973,
        total_orders: 243.9,
        neighborhood: 'Gravesend/Midwood',
        keywords: 'Russian, Yummy, Fun, Southern, Casual, Great, Chill, Dutch',
        annual_dol: 254.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8482,
        store_longitude: -73.938,
        total_orders: 150.58,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Burger, Steak, Vegetarian, Cheesesteak, Salad, Tequila, Beer, Margarita, Wine, Mimosa',
        annual_dol: 160.24,
        description:
            'Relaxed option furnishing a diverse selection of international plates & snacks, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8848,
        store_longitude: -73.9017,
        total_orders: 788.91,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Pizza, Beer, Wine, Meatball, Salad, Italian, Ale, Margarita, Rum, Spicy',
        annual_dol: 805.65,
        description:
            'Eatery serving wood-fired pizzas & cocktails in a casual space with retro punk/hip-hop graffiti.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.617,
        store_longitude: -74.0339,
        total_orders: 1953.01,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Hamburger, Tasty, Salad, Beer, Delicious, Italian, Irish, Chicken Wings, Cozy, Awesome',
        annual_dol: 2037.69,
        description:
            'Irish bar & grill featuring authentic monastery decor, live music on weekends & late-night hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7573,
        store_longitude: -73.9735,
        total_orders: 1797.84,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Lobster Roll, Shrimp Cocktail, Steak, Tasty, Spicy, Warm, French Onion Soup, Elegant, Cozy',
        annual_dol: 1875.79,
        description:
            "Elegant New American restaurant in the former Great Hall of the church's Community House.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7646,
        store_longitude: -73.9721,
        total_orders: 1864.04,
        neighborhood: 'Lenox Hill',
        keywords: 'Clean, Elegant, Cosmopolitan, Contemporary, Tradition',
        annual_dol: 1944.86,
        description:
            'The Harmonie Club is a private social club in New York City. Founded in 1852, the club is the second oldest social club in New York. It is located at 4 East 60th Street, in a building designed by Stanford White.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.689,
        store_longitude: -73.9511,
        total_orders: 508.49,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Flavorful, Wine, Vegetarian, Mexican, Delicious, Fun, Vibrant, Perfect, Caribbean, Casual',
        annual_dol: 1381.91,
        description: '"Food served until 2am"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.9904,
        total_orders: 2024.3,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Chocolate Croissant, Dessert, Salad, Coffee, Luxurious, Modern, Classy, French, Innovative, Charming',
        annual_dol: 2217.44,
        description:
            'Artisan desserts, pastries & afternoon high tea served in a revamped Prohibition era building.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7402,
        store_longitude: -73.9893,
        total_orders: 150.68,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Italian, Japanese, Organic, Wine, Trendy, Polished, Contemporary, Spacious, Welcoming',
        annual_dol: 326.25,
        description:
            'Spacious Italy-based mozzarella bar serving pizza, pasta & small plates plus cocktails, wine & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6949,
        store_longitude: -73.9549,
        total_orders: 323.92,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Hamburger, Mexican, Beer, Coffee, Wine, Ale, Spicy, American, Warm, Cozy',
        annual_dol: 1220.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9869,
        total_orders: 130.39,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Wine, Coffee, Rich, American, Hotel, Organic, Western',
        annual_dol: 533.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -74.0012,
        total_orders: 968.81,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Salad, Italian, Tasty, Warm, Meatball, Sweet, Perfect, Friendly, Fresh',
        annual_dol: 992.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9945,
        total_orders: 935.81,
        neighborhood: 'Nolita',
        keywords:
            'Salad, Vegan, Apple Pie, Coffee, Gourmet, Delicious, Modern, Fresh, American, Incredible',
        annual_dol: 997.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.722,
        store_longitude: -73.9976,
        total_orders: 1305.25,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Salad, Wine, Dessert, Italian, Rustic, Beer, Delicious, Casual, Polished, Classy',
        annual_dol: 1332.94,
        description:
            'Emilia-Romagna cuisine in a warm setting, plus cocktails & a robust Italian wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.9985,
        total_orders: 2400.67,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Upscale, Coffee, Burger, Lobster, Beer, Hotel, Luxurious, Vodka, Dessert, Elegant',
        annual_dol: 2616.36,
        description:
            'Perennially hip SoHo fixture serving creative, upscale cuisine in sleek subterranean environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7749,
        store_longitude: -73.9086,
        total_orders: 509.34,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Lobster, Pizza, Italian, Gluten Free, Beer, American, Spicy, Contemporary, Hip, Casual',
        annual_dol: 580.99,
        description:
            '"Modern Italian Restaurant & Cocktail Bar located in the heart of Astoria. Come join us for some fresh pasta or a great cocktail. Voted one of best outdoor patios NYC, bring some friends and come sit on our tropical patio, and forget your in the city."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -74.0017,
        total_orders: 212.53,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Tequila, Spicy, Upscale, Warm, Gin, Friendly, Casual',
        annual_dol: 800.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7488,
        store_longitude: -73.8711,
        total_orders: 11.67,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Mexican, Margarita, Spanish, Peruvian, American, Latin, Local, Casual, Fresh, Amazing',
        annual_dol: 47.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -74.0008,
        total_orders: 1286.31,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Lobster, Salad, Italian, Coffee, Beer, Upscale, American, Asian, Fusion',
        annual_dol: 5276.58,
        description:
            'Buzzy option offering seafood mains & craft cocktails, plus a live DJ & happy hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -74.0035,
        total_orders: 617.63,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Champagne, Wine, Hotel, Sweet, Foreign',
        annual_dol: 2524.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6889,
        store_longitude: -73.9833,
        total_orders: 51.62,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Tacos, Sushi, Coffee, Champagne, Mimosa, Avocado Toast, Delicious, Crisp, Hot Toddy, American',
        annual_dol: 211.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7666,
        store_longitude: -73.9126,
        total_orders: 1367.4,
        neighborhood: 'Astoria/LIC',
        keywords: 'Upscale, Latin, Fusion, Chill, Casual, Great',
        annual_dol: 5589.48,
        description: '"HOOKAH LOUNGE IN ASTORIA, QUEENS -MUSIC -HOOKAH -DRINKS"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6709,
        store_longitude: -73.985,
        total_orders: 16.91,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Fun, Upscale, Wonderful, Perfect, Sangria, Local',
        annual_dol: 69.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7427,
        store_longitude: -73.9543,
        total_orders: 517.11,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Wine, Elegant, Romantic, Delicious, Premium, Classy, Beer, French, Intimate, Great',
        annual_dol: 651.08,
        description:
            'Wines-by-the-glass plus oysters, cheese plates & and live jazz at this small, atmospheric nightspot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6808,
        store_longitude: -73.9965,
        total_orders: 206.83,
        neighborhood: 'Carroll Gardens',
        keywords: 'Pizza, Tacos, Mexican, Italian, Salad, Delicious, Wine, American, Gin, Rustic',
        annual_dol: 253.77,
        description:
            'Compact restaurant offering pizza slices, negronis & beers amid an easygoing atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.9867,
        total_orders: 1934.52,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Pizza, Burger, Vodka, Italian, Wine, American, Delicious, Spacious, Wonderful, Chill',
        annual_dol: 2479.26,
        description:
            'Detroit-style square pizzas, Italian sandwiches & a signature burger in a brick-clad setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7378,
        store_longitude: -73.9917,
        total_orders: 21.51,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Flavorsome, Japanese, Spicy, Asian, Fusion, Casual, Local, Teriyaki',
        annual_dol: 87.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7338,
        store_longitude: -73.9931,
        total_orders: 186.16,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Tequila, Mexican, Tacos, Beer, Tasty, Dessert, Quesadillas, Spanish, American, Latin',
        annual_dol: 568.15,
        description:
            'Colorful, bodegalike eatery serving hearty Mexican sandwiches, taquitos & fresh margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6447,
        store_longitude: -73.9236,
        total_orders: 1372.96,
        neighborhood: 'East Flatbush',
        keywords:
            'Crab Cakes, Pizza, Mimosa, New-York-Style, Bbq, Tasty, Scotch, Caribbean, Rum, Upscale',
        annual_dol: 1684.59,
        description:
            'Stylish venue presenting creative Caribbean plates & rum-based drinks amid a festive vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7938,
        store_longitude: -73.9707,
        total_orders: 2426.07,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Hotdog, Whiskey, Beer, Scotch, Salad, Karaoke, Caribbean, French, Eclectic, Traditional',
        annual_dol: 2505.94,
        description:
            "Watering hole that's filled with locals who come for drinks, sports on TV & a big pub grub menu.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7511,
        store_longitude: -73.9757,
        total_orders: 1406.43,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Lobster, Mexican, Salad, Tomato Soup, Breakfast Burrito, Margarita, Dessert, Tex-Mex, Fajitas',
        annual_dol: 1774.04,
        description:
            'Large, bustling contemporary Mexican restaurant & bar with cocktails & popular happy-hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7534,
        store_longitude: -73.9779,
        total_orders: 662.17,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Wine, Sleek, Classic, Traditional, Daiquiri, Great, Incredible, Midori, Casual',
        annual_dol: 953.62,
        description:
            'Swanky cocktail bar at the base of an office tower serving craft libations plus elevated bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5836,
        store_longitude: -73.9405,
        total_orders: 37.58,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Lobster, Dessert, Beer, Hotel, American, Modern, High-End, Local',
        annual_dol: 41.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6124,
        store_longitude: -74.0647,
        total_orders: 460.49,
        neighborhood: 'Rosebank',
        keywords: 'Italian, Beer, American, Casual, Polished, Traditional',
        annual_dol: 551.4,
        description:
            '"We are a traditional Italian American Restaurant. Old school ambiance italian restaurant ambiance. Please follow us on Instagram @doncheechny"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6211,
        store_longitude: -74.0292,
        total_orders: 2346.67,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Lobster, Salad, Italian, Pizza, Wine, Stylish, Upscale, Trendy, Classy, Homemade',
        annual_dol: 2403.66,
        description:
            'This stylish eatery offers upscale Italian fare in a contemporary setting with garden seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.9656,
        total_orders: 1388.96,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Chocolate Cake, Cheesecake, Salad, Shrimp Cocktail, Coffee, Wine, Tasty, Juicy, Legendary',
        annual_dol: 1418.43,
        description:
            'Traditional steakhouse fare is served in an ornate setting with a separate piano room & wine cellar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9891,
        total_orders: 1945.75,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Luxurious, Glamorous, Upscale, Iconic, Fabulous, Cozy, Mediterranean, Classic, Sophisticated',
        annual_dol: 2236.65,
        description:
            'The upscale rooms feature city views and bathrooms with rainfall showerheads, plus Wi-Fi, flat-screen TVs, iPod docks and Keurig coffeemakers. Upgraded rooms add sitting areas with pull-out sofas. Suites offer floor-to-ceiling windows with skyline vistas, separate living and dining areas, and soaking tubs.Amenities include a cocktail bar, and a sophisticated restaurant by chef Todd English offering upmarket French cuisine, plus a 24-hour fitness center, a business center and 10 meeting rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9909,
        total_orders: 1527.44,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lasagna, Vegan, Salad, Gluten Free, Vegetarian, Italian, Wine, Upscale, Delicious, Margarita',
        annual_dol: 1559.85,
        description:
            'Modern Italian & Mediterranean cuisine & modern decor star at this lively, casual eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -73.9822,
        total_orders: 1054.08,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Pizza, Vegetarian, Beer, Gourmet, Margarita, Karaoke, American, Ale, Quaint',
        annual_dol: 1141.65,
        description:
            'Classic corner bar with simple eats served in old-school tavern environs with games on a few TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7794,
        store_longitude: -73.9508,
        total_orders: 1698.31,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Steak, Burger, French Toast, French, Wine, French Onion Soup, Beer, Organic, Belgian, Cozy',
        annual_dol: 1797.44,
        description:
            "Alsatian eats & a popular brunch along with a beer list that's curated by a beer sommelier.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -73.9828,
        total_orders: 1361.35,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Burger, Beer, Tequila, Vegan, Steak, Hamburger, Vegetarian, Ale, Spicy, Funky',
        annual_dol: 1467.1,
        description:
            'A wood-framed spot pouring a global variety of draft & bottled craft beers matched with bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9848,
        total_orders: 653.78,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Chocolate Mousse, Wine, Coffee, Italian, Upscale, Polished, Sparkling, Homemade, Fabulous, Vibrant',
        annual_dol: 667.65,
        description:
            'Large, vibrant pre-theater haunt serving Venetian fare like pizzas & pastas in upscale surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9881,
        total_orders: 335.24,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Italian, Pizza, Salad, American, Friendly, Authentic, Timeless, Traditional, Perfect, Fancy',
        annual_dol: 381.81,
        description:
            'Northern Italian restaurant with pasta, pizza & more in a rustic, 2-story brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7397,
        store_longitude: -73.9797,
        total_orders: 356.06,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Steak, Mexican, Beer, Italian, Wine, Margarita, Fajitas, Urban, Modern, Local',
        annual_dol: 450.14,
        description:
            'A family-owned neighborhood spot serving homestyle Mexican food in a bright-colored, muraled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9932,
        total_orders: 77.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Delicious, Awesome, Great, Complex, Cool',
        annual_dol: 318.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9885,
        total_orders: 42.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Beer, Tasty, Japanese, Spicy, Korean, Casual, Premium, Teriyaki, Perfect',
        annual_dol: 185.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.986,
        total_orders: 1295.85,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Karaoke, Chinese, Filipino, Korean, Japanese, Tasty, Iconic, Impeccable, Premium, Great',
        annual_dol: 1568.25,
        description:
            'Mod karaoke bar offering 10 private rooms, cocktails & a library of Korean, English & Chinese songs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7502,
        store_longitude: -73.9951,
        total_orders: 205.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Coffee, Hotel, Delicious, Iconic, Lively, Local',
        annual_dol: 773.45,
        description:
            "The James A. Farley Building is a mixed-use structure in Midtown Manhattan, New York City, which formerly served as the city's main United States Postal Service branch.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9924,
        total_orders: 2374.2,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Pizza, Gourmet, Wine, Upscale, American, Trendy, Delicious, Woody, Spacious',
        annual_dol: 3205.48,
        description:
            'This enormous sports bar boasts tons of TVs, upscale pub food & a woody ski-lodge look.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -73.9872,
        total_orders: 15.59,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Salad, Chinese, Korean, Ale, Asian, Hotel, Refined, Traditional, Fusion, Authentic',
        annual_dol: 63.71,
        description:
            'Refined restaurant & bar specializing in elevated takes on traditional Sichuan cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7338,
        store_longitude: -74.0047,
        total_orders: 593.75,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Upscale, Trendy, Mexican, Italian, Delicious, Comfortable, Asian, Friendly, Modern, Vintage',
        annual_dol: 1616.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7341,
        store_longitude: -74.0031,
        total_orders: 12441.59,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Whiskey, Wine, Burger, Scotch, Rum, Irish, Cozy, Delicious, American',
        annual_dol: 12720.43,
        description:
            'Locally sourced pub grub, a wide cocktail menu & weekend brunch served in a compact, homey setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6454,
        store_longitude: -74.1078,
        total_orders: 2250.65,
        neighborhood: 'Randall Manor',
        keywords:
            'Lobster, Lobster Tail, Steak, Shrimp Cocktail, Wine, Beer, Margarita, Delicious, Romantic, Turkish',
        annual_dol: 2474.81,
        description:
            'Waterfront spot serving American & Mediterranean lunch & dinner menus, plus beer, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7859,
        store_longitude: -73.9724,
        total_orders: 2707.19,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Burger, Salad, Tasty, Gluten Free, Mimosa, French, Upscale, Chinese, Homey',
        annual_dol: 3399.94,
        description:
            'Homey restaurant offering international cuisine, all-day dining & a cocktail bar in a homey space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7688,
        store_longitude: -73.9551,
        total_orders: 1199,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Wine, Dessert, Tasty, Rustic, Classy, Friendly, Modern, Classic, Unpretentious',
        annual_dol: 1227.59,
        description:
            'An old-school Italian eatery with an extensive menu & prime opportunities for people-watching.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.724,
        store_longitude: -73.9537,
        total_orders: 1327.75,
        neighborhood: 'Greenpoint',
        keywords:
            'Beer, Vodka, Rum, Gin, Rooftop, Elegant, Picturesque, Amazing, Seasonal, Classic',
        annual_dol: 1512.22,
        description:
            '"Dobbin St is a tailored event space centrally located in Williamsburg, steps away from picturesque McCarren park. A transformed Brooklyn factory that exudes a minimalist and luxury feel, boasting stunning Manhattan skyline views from the rooftop and vaulted 22 foot ceilings inside."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9947,
        total_orders: 3353.26,
        neighborhood: 'Nolita',
        keywords:
            'Pizza, Wine, Delicious, Beer, Spacious, Margarita, Clean, Perfect, Bright, Great',
        annual_dol: 3498.64,
        description:
            "Low-key hangout where fans sip cocktails on couches; on weekends there's a DJ and karaoke.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -73.9984,
        total_orders: 341.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Cornbread, Spicy, Jamaican, Caribbean, Rum, Rich, Salad, Creole, Homemade, Fun',
        annual_dol: 501.53,
        description:
            'Spicy Caribbean fare, potent rum punch & an in-house DJ playing tunes create an island vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.8815,
        total_orders: 205.91,
        neighborhood: 'Jackson Heights/Elmhurst',
        keywords:
            'Steak, Sushi, Colombian, Delicious, Pinacolada, Asian, American, Fresh, Amazing, Local',
        annual_dol: 347.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.764,
        store_longitude: -73.9734,
        total_orders: 10223.18,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Lobster, Lobster Roll, Hotel, Vodka, Salad, French Onion Soup, Elegant, Italian, French',
        annual_dol: 10548.76,
        description:
            "Offering bathrooms with gold-plated fixtures, the elegant, French-style rooms have Italian linens and sitting areas, plus flat-screen TVs, Wi-Fi (fee) and iPads; some rooms have terraces. Suites offer city views, powder rooms and butler's pantries; some are bi-level. Room service is available.Afternoon tea is offered in the famed Palm Court, which features a stained glass ceiling and lavish decor. There's also a European-inspired food hall, a storied jazz lounge and a luxe champagne bar, plus a posh spa and a gym.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.9786,
        total_orders: 11911.59,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Coffee, Salad, Hotel, Elegant, Sustainable, Warm, Seasonal, Sleek, Artisanal',
        annual_dol: 37648.63,
        description:
            "Sleek rooms offer sophisticated decor and custom-designed beds, plus marble bathrooms, flat-screen TVs and Wi-Fi (fee). Executive rooms provide access to a club lounge with free Wi-Fi, breakfast and evening appetizers. Suites add separate sitting areas.Amenities include a casual restaurant serving sandwiches and brick-oven pizzas, a lobby lounge, and a bar offering light fare. There's also a sky-lit fitness center, a business center and over 151,000 sq ft of meeting space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9883,
        total_orders: 112.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Dessert, French, Rich, Savory, Polished, Martini, Comfortable, Traditional, Authentic',
        annual_dol: 129.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.9879,
        total_orders: 4727.5,
        neighborhood: 'Lower East Side',
        keywords: 'Whiskey, Scotch, Bourbon, Beer, Margarita, Martini, Rum, Upscale, Relaxed, Cozy',
        annual_dol: 5800.1,
        description:
            'No-frills tavern with big list of scotch, whiskey & bourbon plus free peanuts to soak up the drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9907,
        total_orders: 1812.67,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegetarian, Vegan, Wine, Trendy, Delicious, Spicy, Asian, Eclectic, Classic, Casual',
        annual_dol: 2218.62,
        description:
            'Inventive vegetarian dishes & classic cocktails presented in stylish, airy surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.9844,
        total_orders: 609.06,
        neighborhood: 'Lower East Side',
        keywords:
            'Vietnamese, Vintage, Delicious, Classic, Hotel, American, Sweet, Fresh, Local, Casual',
        annual_dol: 2333.68,
        description:
            'Everyday shop serving specialty sandwiches with prosciutto & porchetta, plus crispy brussel sprouts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9921,
        total_orders: 33.43,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Argentinian, Italian, Wine, Gluten Free, Classic, Warm, Authentic, Intimate, Great',
        annual_dol: 136.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7131,
        store_longitude: -73.8728,
        total_orders: 157.47,
        neighborhood: 'Middle Village/Flushing',
        keywords:
            'Wine, Chicken Tikka Masala, Beer, Chinese, Premium, Caribbean, Traditional, Fresh, Perfect, Indian',
        annual_dol: 482.42,
        description:
            '"We offer a full bar as well as hookah in our restaurant. Please come in and see us today!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8558,
        store_longitude: -73.9333,
        total_orders: 86.87,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Lobster, Lobster Roll, Burger, Steak, Salad, Wine, Delicious, Magical, Fried Calamari, Rustic',
        annual_dol: 355.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.718,
        store_longitude: -73.9502,
        total_orders: 2772.32,
        neighborhood: 'Williamsburg',
        keywords:
            'Roast Turkey, Gin, Roast Beef, Ale, Bourbon, Swiss, Rum, Russian, American, Spicy',
        annual_dol: 2831.14,
        description:
            'Trivia contests, spelling bees & live bands lend a unique vibe to this offbeat watering hole.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.6917,
        total_orders: 27.33,
        neighborhood: '',
        keywords:
            'Cheesecake, Sushi, Vegetarian, Japanese, Asian, Chinese, Fusion, Delicious, Thai, Yummy',
        annual_dol: 111.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7064,
        store_longitude: -74.0026,
        total_orders: 4214.72,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Vegetarian, Pizza, Wine, Italian, Coffee, Gluten Free, Beer, Dessert, Hotel, Luxurious',
        annual_dol: 20037.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.9839,
        total_orders: 486.47,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Flavorful, Wine, Dessert, Beer, Vegan, Vietnamese, Delicious, Warm, Relaxed, Homey',
        annual_dol: 515.93,
        description:
            'Informal lunch counter from the Hanoi House team serving Vietnamese fare & coffee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7137,
        store_longitude: -73.9495,
        total_orders: 4147.82,
        neighborhood: 'Williamsburg',
        keywords: 'Warm, Cool, Casual, Lively, Local, Dutch, Great, American',
        annual_dol: 4147.82,
        description:
            'Gay & straight locals congregate at this bar with 2 fireplaces, a pool table & a lush outdoor space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6734,
        store_longitude: -73.9568,
        total_orders: 89.97,
        neighborhood: 'Crown Heights',
        keywords:
            'Mexican, Caribbean, Jamaican, Bbq, Romantic, American, Lively, Authentic, Local, Amazing',
        annual_dol: 367.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7495,
        store_longitude: -73.9834,
        total_orders: 51.84,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Moroccan, Wine, Rich, Lebanese, Beer, French, Mediterranean, American, Hotel',
        annual_dol: 60.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7632,
        store_longitude: -73.9277,
        total_orders: 19.49,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Bbq, Irish, Classic, Perfect, Friendly, Great, Coleslaw, Western, Casual',
        annual_dol: 79.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7252,
        store_longitude: -74.003,
        total_orders: 133.2,
        neighborhood: 'South Village/SoHo',
        keywords: 'Italian, Casual, Elegant, Indian, American, Local, Colorful, Great',
        annual_dol: 544.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7401,
        store_longitude: -73.987,
        total_orders: 8553.1,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Wine, Rich, Pizza, Beer, Trendy, Dessert, Gin, Salad, Fun, Fantastic',
        annual_dol: 8780.6,
        description: 'Modern lounge with 19 Ping-Pong courts, full bar service & dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9903,
        total_orders: 1504.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Scotch, Warm, Delicious, Irish, Quaint, Lit, Casual, Local, Great',
        annual_dol: 1579.57,
        description:
            'Intimate, neighborhood hangout serving up cocktails & brews in a dimly lit, low-key atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7578,
        store_longitude: -73.9875,
        total_orders: 3819.75,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Italian, Wine, Swiss, Caesar Salad, Homemade, American, Classy, Classic',
        annual_dol: 3908.66,
        description:
            'Caricatures of movie stars line the walls of this institution for pre- & post-theater Italian meals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.9841,
        total_orders: 3700.01,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Wine, Italian, Rooftop, Gourmet, Premium, American, Sophisticated, Superb, Modern',
        annual_dol: 3778.52,
        description:
            "Building.Featuring floor-to-ceiling windows with city views, the contemporary rooms come with free Wi-Fi and flat-screen TVs with premium channels, plus coffeemakers and minifridges. Suites add separate living rooms; some have wet bars and whirlpool tubs.Amenities include a 24/7 fitness center and a spa. There's also a buzzy bistro serving modern American fare, and a chic rooftop bar with a terrace and city views.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9919,
        total_orders: 597.91,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Wine, Irish, Fun, Spacious, Yummy, Hurricane, Cozy, American, Great',
        annual_dol: 2453.09,
        description:
            'Irish-style sports bar on 2 levels offering comfort food, brews & game viewing on giant projectors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6893,
        store_longitude: -73.9709,
        total_orders: 420.2,
        neighborhood: 'Fort Greene',
        keywords: 'Burger, Beer, Wine, Spicy, Trendy, Coffee, Rum, Delicious, Romantic, Caribbean',
        annual_dol: 460.63,
        description:
            'Warm eatery in a brownstone serving Caribbean comfort fare with jerk spices, plus rum drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.69,
        store_longitude: -73.9818,
        total_orders: 2842.37,
        neighborhood: 'Downtown Brooklyn/Fort Greene/Brooklyn Heights',
        keywords:
            'Cheesecake, Salad, Dessert, Steak, French Toast, Avocado Toast, French, Bbq, Caesar Salad, Sweet',
        annual_dol: 3685.07,
        description:
            'New York-style cheesecake is the thing to order at this essential Brooklyn diner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.9927,
        total_orders: 513.52,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Beer, Wine, Irish, Delicious, Friendly, Authentic, Impressive, Great, Cool, Casual',
        annual_dol: 1802.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9714,
        total_orders: 375.67,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Hotel, Elegant, Rich, Friendly, Perfect, Superb, Contemporary, Excellent, Tradition',
        annual_dol: 1535.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6819,
        store_longitude: -73.986,
        total_orders: 3838.25,
        neighborhood: 'Gowanus',
        keywords:
            'Vegan, Gluten Free, Coffee, Gin, Phenomenal, Cool, Awesome, Casual, Wonderful, Local',
        annual_dol: 4161.53,
        description:
            'Hi-fi record bar with food & drink offerings plus an intimate performance space & vegan cafe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.8035,
        total_orders: 45.36,
        neighborhood: 'Broadway–Flushing/Flushing/Murray Hill',
        keywords: 'Karaoke, Great, Casual',
        annual_dol: 185.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7105,
        store_longitude: -73.9584,
        total_orders: 316.95,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Pizza, Italian, Beer, Salad, Wine, American, Classy, Casual, Local, Fresh',
        annual_dol: 471,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6754,
        store_longitude: -73.963,
        total_orders: 924.75,
        neighborhood: 'Crown Heights',
        keywords: 'Beer, Wine, Tasty, Trendy, Spicy, Salad, Cozy, American, Ramen, Awesome',
        annual_dol: 1312.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -73.9839,
        total_orders: 406.15,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Fun, Lively, Eclectic, Cool, Fantastic, Authentic, Great, German, Incredible, Casual',
        annual_dol: 552.89,
        description:
            'The Pyramid Club is a nightclub in the East Village of Manhattan, New York City. After opening in 1979, the Pyramid helped define the East Village drag, gay, punk and art scenes of the 1980s. The club is located at 101 Avenue A in Manhattan.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -74.0025,
        total_orders: 109.62,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Wine, Coffee, Beer, Salad, Premium, Vintage, Authentic, Great, Excellent, Attentive',
        annual_dol: 453.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -73.9856,
        total_orders: 9633.76,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Tasty, Vegetarian, Wine, Salad, Italian, Upscale, Delicious, Gourmet, Rich, Coffee',
        annual_dol: 9940.42,
        description:
            'Handmade pastas headline a sophisticated Italian menu at this upscale locale with a cool vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.745,
        store_longitude: -73.9839,
        total_orders: 461.27,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Wine, Steak, American, Dessert, Stylish, Bruschetta, Cozy, Natural, Great, Incredible',
        annual_dol: 481.27,
        description:
            'Stylish wine bar with a patio & a menu of cheese, charcuterie, bruschetta & New American entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7401,
        store_longitude: -74.0062,
        total_orders: 6403.25,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Steak, Lobster, Tacos, Elegant, American, Yummy, Modern, Classic, Exceptional, Fantastic',
        annual_dol: 6680.87,
        description:
            'Slick, bi-level space known for celebrity sightings, creative seafood-focused food & a rooftop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -74.0052,
        total_orders: 5154.78,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Rich, Beer, Fun, Friendly, Comfortable, Awesome, Great, Excellent, Classic',
        annual_dol: 5437.63,
        description: 'A Christopher Street classic since 1972, this gay bar draws an older crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7815,
        store_longitude: -73.9723,
        total_orders: 1230.18,
        neighborhood: 'Central Park West Historic District',
        keywords:
            'Salad, Burger, Vegetarian, Gourmet, Dessert, American, Asian, Seasonal, Natural, Cultural',
        annual_dol: 1401.1,
        description:
            'The American Museum of Natural History is a natural history museum on the Upper West Side of Manhattan in New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7867,
        store_longitude: -73.9755,
        total_orders: 2551.16,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Tacos, Beer, Salad, Delicious, Irish, Ale, Fun, Lively, Friendly',
        annual_dol: 2605.29,
        description:
            'Pub with rotating drink specials and diverse eats in laid-back digs with big windows & board games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7855,
        store_longitude: -73.9727,
        total_orders: 426.96,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegan, Burger, Vegetarian, Sushi, Pizza, Salad, Tacos, Gluten Free, Vegetable Stirfry, Wine',
        annual_dol: 465.95,
        description:
            'Upmarket, plant-based dishes served with wine & cocktails, plus a weekend brunch menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7831,
        store_longitude: -73.9786,
        total_orders: 69.63,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Vodka, Beer, Chicken Parmigiana, Wine, Gourmet, Italian, American, Fresh, Casual',
        annual_dol: 261.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5902,
        store_longitude: -73.9006,
        total_orders: 10.96,
        neighborhood: '',
        keywords: 'Hotdog, Hamburger, Bbq, Southern, Sweet, Excellent, Great, Complex, Local',
        annual_dol: 44.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.697,
        store_longitude: -73.9063,
        total_orders: 407.06,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Warm, Vietnamese, Intimate, Perfect, Fun, Cool, Eclectic, Casual, Great, Unique',
        annual_dol: 434.12,
        description:
            '"We are a live music venue with an onsite Vietnamese restaurant and a patio garden bar. Come by for slushy drinks, big open skies, plants, a great sounding room with perfect sightlines, and thoughtfully eclectic live performers and djs. No age restriction to enter, 21+ with valid State ID to drink alcohol. Masks + proof of Covid vaccination (or else 72-hours-or-younger negative PCR test), required to enter."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8176,
        store_longitude: -73.6253,
        total_orders: 776.78,
        neighborhood: '',
        keywords:
            'Sushi, Gluten Free, Relaxed, Fun, Premium, Casual, Outstanding, Fresh, Excellent, Fine',
        annual_dol: 906.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7764,
        store_longitude: -73.921,
        total_orders: 260.23,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Beer, Rare, Exceptional, Local',
        annual_dol: 1063.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9144,
        total_orders: 942.52,
        neighborhood: 'Astoria/LIC',
        keywords: 'Karaoke, Mediterranean, American, Casual, Great',
        annual_dol: 3551.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9145,
        total_orders: 86.61,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Tacos, Steak, Mexican, Tasty, Fish Tacos, Delicious, Spanish, American, Fun',
        annual_dol: 354.03,
        description:
            'Energetic Latin nightclub with TV, billiards & DJs in a laid-back sports-bar environment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.8334,
        total_orders: 2870.08,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Cinnamon Roll, Upscale, Beer, Pizza, Coffee, Hotel, Tasty, Warm, American, Fabulous',
        annual_dol: 3138.55,
        description:
            'Contemporary bar & grill offering American fare such as burgers, pasta & steaks, plus a large patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6822,
        store_longitude: -73.9347,
        total_orders: 1644.21,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Burger, Salad, Spicy, Delicious, Southern Comfort, Southern, Friendly, Gumbo, Sweet, Modern',
        annual_dol: 1780.35,
        description:
            'Modern spin on Southern comfort food plus brunch & a full bar in a historic brownstone neighborhood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7375,
        store_longitude: -73.988,
        total_orders: 3983.58,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Lobster, Sushi, Dessert, Delicious, Japanese, Sashimi, Mediterranean, Exquisite, Premium, Contemporary',
        annual_dol: 5291.58,
        description:
            'Expansive eatery serving a sophisticated seafood-focused menu with global influences.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7244,
        store_longitude: -73.9879,
        total_orders: 1026.11,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Pizza, Italian, Wine, Beer, Whiskey, Ale, Bourbon, Spacious, Friendly, Casual',
        annual_dol: 1047.88,
        description:
            'A smartly curated selection of beer, bourbon & beyond draws connoisseurs to this East Village bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7327,
        store_longitude: -73.9874,
        total_orders: 6517.24,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Steak, Burger, Salad, Wine, Rustic, Beer, Upscale, Cozy, Comfortable, French',
        annual_dol: 7092.43,
        description:
            'Bustling, exposed-brick East Village tavern with separate bar & dining areas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7388,
        store_longitude: -73.9897,
        total_orders: 1094.08,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Salad, Tomato Soup, Wine, Delicious, Turkey Sandwich, Beer, Sweet, Friendly, Playful, Perfect',
        annual_dol: 1452.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5984,
        store_longitude: -74.0798,
        total_orders: 313.61,
        neighborhood: 'Arrochar/Concord',
        keywords: 'Burger, Pizza, Sushi, Italian, Casual, Local, Fresh, Classic, Fine',
        annual_dol: 328.59,
        description:
            'Local fixture cooking classic homestyle Italian dishes in a white-tablecloth space with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8052,
        store_longitude: -73.9663,
        total_orders: 653.01,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Lobster, Lobster Roll, Burger, Cajun, Chinese, Rich, Pinacolada, Creole, Southern, Modern',
        annual_dol: 743.74,
        description:
            '"BIBS UP, PHONES DOWN. IT\'S #SHAKINGTIME Dive headfirst into a bag of steaming hot seafood shaken in rich butter, an abundance of garlic, and a signature blend of Cajun spices. BUILT ON COMMUNITY: We believe in the power of food to bring us together to celebrate, laugh, and be in the moment. Fully immerse yourself in the rhythm of shelling and plucking out lumps of shellfish while the bag keeps your seafood hot and drenched in our signature sauce."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9726,
        total_orders: 3087.82,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Steak, Salad, Gluten Free, Wine, Greek, Succulent, Elegant, American, Impeccable',
        annual_dol: 3365.25,
        description:
            'Upmarket American seafood & raw-bar offerings in a swanky, spacious dining room with a fireplace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9729,
        total_orders: 1586.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Steak, Beer, Delicious, Martini, Irish, American, Perfect, Caribbean, Fantastic',
        annual_dol: 1619.94,
        description:
            'Clubby hangout with new American eats & handcrafted cocktails, plus TVs showing sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.764,
        store_longitude: -73.9708,
        total_orders: 1230,
        neighborhood: 'Lenox Hill',
        keywords:
            'Cajun, Salad, Wine, French, Organic, Elegant, Impeccable, Classy, Comfortable, Timeless',
        annual_dol: 1283.33,
        description:
            'The Cajun chicken is the most famous of the French classics offered at this large bistro.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9696,
        total_orders: 1639.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Premium, Friendly, Excellent, Fine, Modern, Casual',
        annual_dol: 1746.75,
        description:
            "Modern rooms come with free Wi-Fi, flat-screen TVs and MP3 players, as well as minifridges, microwaves and Keurig coffeemakers; some have private balconies or floor-to-ceiling windows offering city views.There's a casual restaurant, as well as a 24/7 pantry market. Other amenities include a lobby lounge, a 24-hour gym and a business center. Valet parking is available (fee).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -74.0075,
        total_orders: 6369.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Hamburger, Cajun, Salad, Jambalaya, Beer, Wine, Vodka, Upscale, Gumbo',
        annual_dol: 7478.58,
        description:
            "Spot for upscale takes on Cajun-Creole food & drink, with a look inspired by NOLA's French Quarter.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.9685,
        total_orders: 768.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Sushi, Shrimp Cocktail, Wine, Organic, Mimosa, Rustic, Sustainable, Homemade, Warm',
        annual_dol: 818.56,
        description:
            'Creative seafood dishes showcased in a townhouse with a long marble bar & vintage decorations.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8278,
        store_longitude: -73.9246,
        total_orders: 297.86,
        neighborhood: 'Concourse/West Bronx',
        keywords: 'Beer, Karaoke, Spanish, Latin, Delicious, Cool, Great, Casual',
        annual_dol: 307.34,
        description: '"Spanish Sports Bar by Yankee Stadium"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9713,
        total_orders: 1406.3,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Gluten Free, French, Sustainable, Impeccable, Refined, Polished, Modern, Classy, Italian',
        annual_dol: 5946.86,
        description:
            'A vegetable-focused menu & sustainable proteins are served in a modern greenhouse-inspired space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9745,
        total_orders: 755.38,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tacos, Mexican, Salad, Margarita, Wine, Upscale, Rich, American, Contemporary, Artisanal',
        annual_dol: 861.18,
        description:
            'Contemporary Mexican hot spot turning out innovative tacos & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.9838,
        total_orders: 3959.71,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Beer, Irish, Spicy, American, Classic, Welcoming, Traditional, Casual, Local',
        annual_dol: 4131.39,
        description:
            'Casual Irish pub & restaurant featuring an American/Irish bar menu in a wood-paneled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6909,
        store_longitude: -73.9539,
        total_orders: 1576.83,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Coffee, Organic, Comfortable, Casual, American, Local, Western, Fresh, Great, Excellent',
        annual_dol: 1745.33,
        description:
            'Casual place serving locally sourced American plates, plus clever cocktails & imported wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6316,
        store_longitude: -73.6407,
        total_orders: 71.46,
        neighborhood: '',
        keywords: 'Burger, Tacos, American, Sweet, Casual, Traditional, Excellent, Great',
        annual_dol: 218.48,
        description: '"Bar & Grill proudly serving the LGBTQ+ Community"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.982,
        total_orders: 9.71,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Hotel, Elegant, Irish, American, Welcoming, Modern, Great, Local',
        annual_dol: 11.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7307,
        store_longitude: -74.0001,
        total_orders: 1696.68,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lasagna, Chocolate Cake, Chocolate Mousse, Pizza, Wine, Italian, Dessert, Vodka, Salad, Delicious',
        annual_dol: 1831.9,
        description:
            'A lantern-lit indoor garden & live jazz in the adjoining bar complement light Italian fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7754,
        store_longitude: -73.9093,
        total_orders: 662.4,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Beer, American, Irish, Hip, Lively, Sweet, Ample, Local, Signature, Great',
        annual_dol: 813.45,
        description:
            '"Rivercrest is a new craft beer bar and restaurant, with unique cocktails that complement the already established Ditmars Boulevard bar and restaurant scene. Locally sourced produce, bread, liquor, and beer ensure the best standards and quality are provided daily. With 18 rotating tap lines designated to unique craft beers, we intend to support local breweries while still offering a broad selection of other brews and go-to favorites. If you like sports, as we do, you’re in for a treat! We have every sports package available to ensure you don’t miss a pass, dunk, putt, homer, or throw! With 3,000 square feet of space, Rivercrest has ample space indoor and outdoor to host your next party in Astoria."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -74.0017,
        total_orders: 270.35,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Steak, Dessert, Spicy, Delicious, American, Mediterranean, Cozy, Ribeye Steak, Fresh',
        annual_dol: 288.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5273,
        store_longitude: -74.2307,
        total_orders: 35404,
        neighborhood: 'Charleston',
        keywords:
            'Burger, Salad, Meatball, Beer, Gluten Free, Italian, Wine, Delicious, French, American',
        annual_dol: 62133.47,
        description:
            'Splashy space with a bar & live entertainment supplying international bites all day every day.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -73.9378,
        total_orders: 10.45,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, Salad, Italian, Meatball, Spicy, Delicious, Creamy, Cozy, Homemade, Mediterranean',
        annual_dol: 42.7,
        description:
            '"Geena’s Cucina is a casual, full service trattoria specializing in authentic, al forno pizza and other Italian specialties. From an extensive list of traditional and creative al forno pizza, to pastas, fresh salads and homemade desserts, the classic flavors of Italy are offered throughout our menu for Lunch or Dinner. The bar features a variety of hand selected liquors, domestic and imported wines, and local craft beers. Combining historic architectural elements with a clean, contemporary look, Geena’s Cucina offers cozy tables for two and community tables for socializing or larger parties. Our commitment to quality and preparation can be enjoyed at every visit. Geena\'s Cucina: where pizza is our soul"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.735,
        store_longitude: -73.9882,
        total_orders: 3076.01,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Upscale, Legendary, Spanish, Classic, Spacious, American, Organic, Perfect, Incredible, Great',
        annual_dol: 3141.28,
        description:
            'Irving Plaza is a ballroom-style music venue located within the Union Square neighborhood of Manhattan in New York City. It has been featured on the Complex City Guide list of "50 Best Concert Venues of America" in 2013.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7299,
        store_longitude: -73.8607,
        total_orders: 390.87,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Sushi, Salad, Wine, Japanese, Sashimi, Spicy, Trendy, Wonton Soup, Classy, Casual',
        annual_dol: 504.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5883,
        store_longitude: -74.0903,
        total_orders: 19.19,
        neighborhood: 'Dongan Hills',
        keywords:
            'Burger, Pizza, Salad, Italian, Delicious, Sweet, American, Fresh, Casual, Excellent',
        annual_dol: 78.43,
        description:
            'Offshoot of a longtime NYC bakery offering an array of sweets plus lunch & breakfast items.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5914,
        store_longitude: -73.9404,
        total_orders: 2962.51,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Sushi, Burger, Salad, Steak, Chinese, Spicy, Delicious, Turkish, Japanese, Thai',
        annual_dol: 3266.91,
        description:
            'Casual small-chain eatery serving a variety of Chinese fare, plus classic & creative sushi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9781,
        total_orders: 2367.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Upscale, French, Elegant, Classy, Polished, Comfortable, Traditional, Mediterranean, Classic',
        annual_dol: 8292.2,
        description:
            'Wine & French fare served in a dramatic, upscale eatery with greenery, high ceilings & city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.9788,
        total_orders: 235.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Vegan, Mexican, Flavorful, Meatball, Wine, Champagne, Dessert, American, Asian',
        annual_dol: 956.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9774,
        total_orders: 90.13,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Chocolate Cake, Salad, Pizza, Tasty, Wine, Spicy, Delicious, Apple Pie',
        annual_dol: 156.9,
        description:
            'Exposed brick, dark woods & an open kitchen set a casual backdrop for kosher steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8834,
        store_longitude: -73.9018,
        total_orders: 3.31,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Steak, Hamburger, American, Impeccable, Excellent, Great, Casual, Local, Attentive',
        annual_dol: 13.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.9685,
        total_orders: 2655.38,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Beer, Coffee, Mimosa, Nachos, American, Bellini, Quaint, Lively, Local',
        annual_dol: 2675.27,
        description:
            'Farm-to-table bistro & brunch spot serving creative comfort eats & cocktails amid a lively ambiance.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8755,
        store_longitude: -73.5996,
        total_orders: 1273.32,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Cheesesteak, Pizza, Sushi, Italian, Dessert, Wine, Swiss',
        annual_dol: 1329.89,
        description:
            'Lively American gastropub with small plates, creative entrees & an antique bar dating to the 1880s.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6219,
        store_longitude: -74.0267,
        total_orders: 45.97,
        neighborhood: 'Fort Hamilton/Bay Ridge',
        keywords:
            'Mexican, Tacos, Burritos, Salad, Delicious, Local, Premium, Casual, Fresh, Great',
        annual_dol: 249.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7368,
        store_longitude: -73.7472,
        total_orders: 34.74,
        neighborhood: 'Oakland Gardens/Bayside',
        keywords:
            'Pizza, Italian, Vodka, Wine, Dessert, Meatball, Fried Shrimp, Thai, Casual, Chic',
        annual_dol: 142.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -73.8863,
        total_orders: 123.73,
        neighborhood: 'Middle Village/Flushing',
        keywords:
            'Pizza, Italian, Meatball, Beer, Wine, Healthy, Friendly, Outstanding, Great, Casual',
        annual_dol: 505.75,
        description:
            'Pasta, grilled meats & other Italian fare, plus wine, in a laid-back outfit with a bar & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6432,
        store_longitude: -73.6342,
        total_orders: 371.07,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Vegan, Gluten Free, Flavorful, Wine, Thai, Yummy, Classy, Perfect',
        annual_dol: 410.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9819,
        total_orders: 5842.43,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Canadian, Hotel, Coffee, Cheesecake, Legendary, Classic, French, Vibrant, Great, Excellent',
        annual_dol: 5845.52,
        description:
            'on foot from Grand Central Terminal trains.Polished rooms with period furnishings offer flat-screen TVs and free Wi-Fi. Suites add separate living areas with sofabeds, and upgraded suites also have soaking tubs and minifridges.There\'s a famed restaurant that was frequented in the 1920s by a group of literary and theater figures known as "The Vicious Circle". Other amenities include a ritzy bar and event space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9867,
        total_orders: 1509.76,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Salad, Italian, Dessert, Chicken Parmigiana, Wine, Martini, Spicy, German, Warm, Polished',
        annual_dol: 1591.19,
        description:
            'Bustling Italian spot serving classic red-sauce dishes in a warm setting with a bar & exposed brick.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7046,
        store_longitude: -73.9873,
        total_orders: 856.02,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Wine, Tequila, Flavorful, Salad, Mediterranean, Upscale, Romantic, Delicious, Seasonal, Sweet',
        annual_dol: 976.44,
        description:
            'Contemporary place for wood-fired Med & Middle Eastern fare with wine, cocktails & river views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.683,
        store_longitude: -73.9503,
        total_orders: 15.18,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vodka, Beer, Martini, Coffee, Trendy, Rum, Salad, Spicy, Friendly, Bloody Mary',
        annual_dol: 54.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9943,
        total_orders: 3237.62,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Tequila, Whiskey, Vodka, Wine, Beer, Pizza, Irish, Mimosa, Upscale, Bellini',
        annual_dol: 3275.55,
        description:
            'Irish bar across from Penn Station serving Guinness & other Celtic faves to wash down the pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.9915,
        total_orders: 2546.46,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Steak, Lobster, Cheesecake, Salad, Lobster Bisque, Wine, Delicious, French, Caesar Salad, Sleek',
        annual_dol: 2704.78,
        description:
            'High-end steakhouse serving an array of meats & seafood plus wine in a simple dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7539,
        store_longitude: -74.0009,
        total_orders: 5604.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Margarita, Warm, Inviting, Fancy, Perfect, Fun, Bbq, Clean, American',
        annual_dol: 8611.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.755,
        store_longitude: -73.9986,
        total_orders: 631.74,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tequila, Whiskey, Coffee, Beer, Wine, Vegetarian, American, Stylish, Hotel, British',
        annual_dol: 672.3,
        description:
            'All-day dining on seasonal fare with craft cocktails & wine in a handsome, contemporary hotel space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.9899,
        total_orders: 3347.8,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Wine, Hotel, Italian, Steak, Stylish, Delicious, High-End, American, Classic, Chic',
        annual_dol: 3727.55,
        description:
            "Stylish modern rooms have Wi-Fi access, iPod docks and flat-screens, plus rainfall showers. Suites include pool tables, private balconies and/or hot tubs. In-room hair-styling services and 24-hour room service are available.Loaner bikes and iPads are available. There's an intimate home-style Italian eatery, and a chic bar with a fireplace and a seasonal patio. Additional amenities include a spa, an outdoor terrace with a multimedia screen, and a fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6561,
        store_longitude: -73.9598,
        total_orders: 768.63,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Vegan, Vegetarian, Salad, Wine, Roasted Chicken, Zen, French, Healthy, Spicy, Funky',
        annual_dol: 801.96,
        description:
            'Funky cafe for creative fusion eats, roasted chicken & a full bar, plus outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -74.0011,
        total_orders: 255.25,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Flavorful, Cozy, Hotel, Intimate, Great, Signature',
        annual_dol: 1043.38,
        description:
            'Cozy underground bar featuring up-&-coming comedians & open-mike events 7 nights a week.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7744,
        store_longitude: -73.9079,
        total_orders: 249.03,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Salad, Steak, Fried Calamari, Swiss, Greek, American, Greek Salad, Southern, Casual, Fresh',
        annual_dol: 1017.93,
        description:
            "Lively eatery specializing in fresh Greek seafood & sides that doesn't take reservations.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -74.0008,
        total_orders: 7.25,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Georgian, Salad, Kebab, Mediterranean, Cozy, Russian, Classy, Authentic, Fresh',
        annual_dol: 29.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8895,
        store_longitude: -73.8992,
        total_orders: 732.82,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Peruvian, Tasty, Rich, Asian, Japanese, Latin, Fusion, American, Sangria, Indian',
        annual_dol: 1512.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.9843,
        total_orders: 1005.23,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Champagne, Vodka, Whiskey, Martini, Delicious, Trendy, Rum, Hotel, Karaoke, Fun',
        annual_dol: 3907.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8091,
        store_longitude: -73.9442,
        total_orders: 117.45,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Steak, Salad, Nachos, Bbq, Caesar Salad, Gumbo, American',
        annual_dol: 515.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9825,
        total_orders: 397.77,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Italian, Delicious, Warm, Classy, Friendly, Casual, Seasonal, Southern, Fresh, Fantastic',
        annual_dol: 557.25,
        description:
            '"Beppe’s inspiration is the Tuscan countryside, where the passion for fresh, seasonal ingredients and an innate knack for true hospitality perfectly coincide. We deliver both to your table, a warm taste of Tuscany right in the heart of NYC."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9829,
        total_orders: 708.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Champagne, Margarita, Premium, Exotic, Sashimi, Great, Casual, Fine, Signature',
        annual_dol: 846.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8145,
        store_longitude: -73.9595,
        total_orders: 534.8,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Steak, Mexican, Vegetarian, Salad, Milkshake, Flavorful, Margarita, Fruity, Fajitas',
        annual_dol: 634.14,
        description:
            'Casual cantina serving a variety of Mexican dishes, from fajitas to pozole, plus fruity cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.965,
        total_orders: 82.41,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Dessert, Warm, Seasonal, Sweet, Spacious, Contemporary, Modern, American, Premium',
        annual_dol: 336.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6884,
        store_longitude: -73.98,
        total_orders: 693.74,
        neighborhood: 'Fort Greene',
        keywords:
            'Steak, Tacos, Mexican, Tequila, Salad, Burritos, French Toast, Ale, French, Trendy',
        annual_dol: 874.87,
        description:
            'Cool Mexican eatery serving enchiladas, tacos & quesadillas, plus weekend brunch & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7402,
        store_longitude: -73.9926,
        total_orders: 648.86,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Luxurious, Upscale, Glamorous, Premium, Comfortable, Vivid, Smooth, Incredible, Natural, Diverse',
        annual_dol: 699.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7411,
        store_longitude: -74.0049,
        total_orders: 89.32,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Italian, Beer, Ale, Wine, American, Hotel, Classic, Fun, Great',
        annual_dol: 380.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7233,
        store_longitude: -73.9882,
        total_orders: 1302.95,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Steak, Tacos, Salad, Gourmet, Beer, Roast Beef, Italian, Wine, Upscale',
        annual_dol: 1330.6,
        description:
            'East Village Irish bar with food & drink upstairs & dancing in the subterranean lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6128,
        store_longitude: -74.1586,
        total_orders: 935.78,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Steak, Burger, Beer, Chicken Wings, Ale, Tex-Mex, American, Creamy, Handcrafted, Casual',
        annual_dol: 1191.5,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5889,
        store_longitude: -73.6512,
        total_orders: 241.67,
        neighborhood: '',
        keywords: 'Pizza, Wine, Beer, Fun, Great, Classic, Casual, Local',
        annual_dol: 261.98,
        description:
            '"Small local pub with a classic jukebox and the coldest beer in town. Outdoor deck seating (seasonal). No kitchen, Cash only."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6131,
        store_longitude: -74.1753,
        total_orders: 5883.53,
        neighborhood: 'Bloomfield/Mid Island',
        keywords: 'Italian, Hotel, Upscale, Premium, Amazing, Chic, Great, Modern',
        annual_dol: 6387.05,
        description:
            "Rooms feature flat-screen TVs, microwaves, minifridges and free WiFi, as well as chic decor and custom-designed beds.In addition to an upscale Italian restaurant and cabaret with select evening performances, there's a modern lounge with live entertainment and a 24/7 pantry. There's also a fitness center. Other perks include a 10,000 sq ft ballroom overlooking landscaped gardens and a free Newark Liberty International Airport shuttle.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7093,
        store_longitude: -74.0057,
        total_orders: 744.77,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Steak, Chicken Pot Pie, Salad, Shrimp Cocktail, Beer, Coffee, Tasty, Irish, American',
        annual_dol: 794.28,
        description:
            'Unassuming pub with a dining room featuring an Irish-American menu, weekend brunch & weekly karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6134,
        store_longitude: -74.1007,
        total_orders: 112.53,
        neighborhood: 'Sunnyside/Mid Island',
        keywords:
            'Pizza, Italian, Gluten Free, Delicious, Meatball, Cozy, Sweet, American, Classic, Wonderful',
        annual_dol: 459.98,
        description:
            'Go-to serving traditional & original Italian fare since 1971 & hangout with a lively bar scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7183,
        store_longitude: -73.9437,
        total_orders: 34.54,
        neighborhood: 'Williamsburg',
        keywords:
            'Garlic Bread, Salad, Steak, Italian, Shrimp Cocktail, Wine, French, Vintage, Healthy, Perfect',
        annual_dol: 76.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.6969,
        total_orders: 120.1,
        neighborhood: '',
        keywords:
            'Steak, Hamburger, Beer, Wine, French Onion Soup, Spicy, French, American, Irish, Relaxed',
        annual_dol: 160.63,
        description:
            'Relaxed Irish pub featuring sandwiches & classic mains, plus patio seating & occasional live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8176,
        store_longitude: -73.9169,
        total_orders: 11.48,
        neighborhood: 'Melrose/West Bronx',
        keywords:
            'Steak, Salad, Mexican, Sushi, Ecuadorian, French, Bbq, Spanish, Latin, Delicious',
        annual_dol: 46.91,
        description:
            'No-frills corner option featuring Latin-style steaks, ribs & chicken in compact digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7495,
        store_longitude: -73.9769,
        total_orders: 2426.98,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Hotel, Wine, Stylish, Tequila, Spicy, Beer, Margarita, Amazing, Colorful, Clean',
        annual_dol: 2631.09,
        description:
            "Bright, simple rooms with free Wi-Fi offer work stations, iPod docks and flat-screen TVs, plus glass-enclosed bathrooms with rainfall showerheads. Some rooms have bunk beds.Free walking tours of the city are offered. Additional amenities include a colorful communal game room with table tennis and a fireplace, plus a stylish rooftop lounge and a seasonal bar. There's also a casual ground-floor restaurant.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.9775,
        total_orders: 4025.59,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Steak, Salad, Dessert, Japanese, Delicious, Rich, Sashimi, Opulent, Sleek',
        annual_dol: 5154.43,
        description:
            'Sleek but comfy Japanese spot serving izakaya dishes & skewers, plus sushi, sashimi & sake.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7464,
        store_longitude: -73.9852,
        total_orders: 8211.83,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Pizza, Italian, French, Meatball, Hotel, Spicy, American, Rooftop, Vintage',
        annual_dol: 9166.52,
        description:
            'Brick-walled restaurant & bar in the Arlo NoMad serving Italian-American pizzas, pastas & entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9585,
        total_orders: 2762.06,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Whiskey, Ale, Friendly, Casual, Fun, Local, Great, Excellent, Chill',
        annual_dol: 2820.67,
        description:
            'Chill watering hole pouring brews & cocktails amid throwback Brooklyn decor & neon beer signs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7442,
        store_longitude: -73.9832,
        total_orders: 213.05,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Burger, Pizza, Irish, American, Hotel, Magical, Casual, Perfect, Local',
        annual_dol: 870.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.6341,
        total_orders: 600.66,
        neighborhood: '',
        keywords:
            'Salad, Italian, Wine, Pizza, Savory, Delicious, Mediterranean, Clean, Friendly, Authentic',
        annual_dol: 626.7,
        description:
            'Italian restaurant & bar serving homestyle dishes in a setting with plush chairs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7165,
        store_longitude: -73.6442,
        total_orders: 993.62,
        neighborhood: '',
        keywords: 'Golfclub, Exquisite, Remarkable',
        annual_dol: 1161.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7318,
        store_longitude: -74.0014,
        total_orders: 1064.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tasty, Wine, Delicious, Spicy, Beer, Trendy, Korean, Karaoke, Juicy, Fun',
        annual_dol: 1133.06,
        description:
            'Trendy cocktail bar featuring creative Korean small plates & karaoke in a historical townhouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.6324,
        total_orders: 48.53,
        neighborhood: '',
        keywords:
            'Delicious, Friendly, Authentic, Homemade, Amazing, Great, Mediterranean, Local, Casual, Fine',
        annual_dol: 148.39,
        description:
            'Seafood-focused Portuguese cuisine plus sangria & other cocktails served in a down-to-earth setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7095,
        store_longitude: -73.6275,
        total_orders: 37.76,
        neighborhood: '',
        keywords: 'Steak, American, Latin, Casual, Traditional, Local',
        annual_dol: 54.43,
        description:
            '"Restaurante de almejas con una amplia selección de platos hispanos. El restaurante también organiza eventos y fiestas. Si desea reservar un evento con nosotros, llame al 51649396624 or el manager 5165674608"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6999,
        store_longitude: -73.9094,
        total_orders: 25.33,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Casual, Recommended',
        annual_dol: 103.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6749,
        store_longitude: -73.9817,
        total_orders: 1544.41,
        neighborhood: 'Park Slope',
        keywords: 'Trendy, Rum, Caribbean, Upscale, Spacious, Bbq, Lively, Innovative, Fun, Mimosa',
        annual_dol: 1684.89,
        description:
            'Updated Caribbean cooking & signature cocktails are served in a stylish, island-inspired setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.761,
        store_longitude: -73.916,
        total_orders: 368.13,
        neighborhood: 'Astoria',
        keywords: 'Vegan, Pizza, Wine, Beer, Gourmet, American, Global, Spicy, Eclectic, Diverse',
        annual_dol: 392.6,
        description:
            'Wine bar with gourmet sharing plates, pizzas, charcuterie & cheese trays & infused cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6747,
        store_longitude: -73.9814,
        total_orders: 566.53,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Hamburger, Beer, Salad, Mexican, Gourmet, Tasty, Dessert, Wine, Organic',
        annual_dol: 603.76,
        description:
            'Rustic-chic burger joint serving innovative burgers, plus craft draft beer & clever cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.723,
        store_longitude: -73.9502,
        total_orders: 254.36,
        neighborhood: 'Greenpoint',
        keywords:
            'Beer, Trendy, Polish, Delicious, Sweet, Classy, Intimate, Bbq, Authentic, Traditional',
        annual_dol: 1039.75,
        description:
            'Local outpost of a Polish concept serving pierogi & traditional plates in an easygoing setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6791,
        store_longitude: -74.011,
        total_orders: 9997.51,
        neighborhood: 'Red Hook',
        keywords: 'Burger, Lobster, Salad, Beer, French, Bbq, Trendy, American, Spacious, Ample',
        annual_dol: 10209.64,
        description:
            'Bar food, a big beer list & an ample back patio bring locals to this laid-back tavern.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7498,
        store_longitude: -73.9394,
        total_orders: 114.82,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Cheesesteak, Pizza, Salad, Beer, Italian, Nachos, Tex-Mex, Philly Cheesesteak, American',
        annual_dol: 122.45,
        description:
            'Relaxed neighborhood gathering spot for beer, classic cocktails & bar bites in a no-frills setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5463,
        store_longitude: -74.2295,
        total_orders: 196.24,
        neighborhood: 'Charleston',
        keywords: 'Mexican, Unforgettable, Great, Wonderful, High-End, Unique, Casual',
        annual_dol: 299.98,
        description: '"Staten Islands Only Gentleman\'s Club"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7275,
        store_longitude: -73.9902,
        total_orders: 370.31,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Beer, Cozy, Friendly, Classic, Great, Casual, Local',
        annual_dol: 394.65,
        description:
            'Intimate, down-to-earth bar with frequent entertainment, including DJs, movie nights & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7323,
        store_longitude: -73.9932,
        total_orders: 2214.8,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Italian, Salad, Dessert, Spicy, Cozy, Romantic, Classy, Friendly, American',
        annual_dol: 2261.79,
        description:
            'Tuscan cooking & wines await at this low-lit, flower-filled celebrity hangout.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7274,
        store_longitude: -73.9864,
        total_orders: 2348.64,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tequila, Beer, Martini, Upscale, Fun, Fancy, Friendly, Fantastic, Cool, Great',
        annual_dol: 2450.47,
        description:
            'Veteran East Village watering hole offering cheap drinks & pool in a bare-bones atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8022,
        store_longitude: -73.9642,
        total_orders: 1649.47,
        neighborhood: 'Manhattan Valley/UWS',
        keywords: 'Burger, Hotdog, Hamburger, Beer, Salad, Ale, Gin, Casual, Classic, Local',
        annual_dol: 1702.09,
        description:
            'Local bar popular among collegiate types for its daily food & drink specials such as dollar beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.646,
        store_longitude: -73.972,
        total_orders: 2388.9,
        neighborhood: 'Kensington',
        keywords: 'Zombie, Beer, Wine, Funky, Charming, Cozy, Awesome, Friendly, Clean, Colorful',
        annual_dol: 2439.59,
        description:
            'A colorful bar with a charming back patio offering beer, wine, cocktails & a few food options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5514,
        store_longitude: -74.1506,
        total_orders: 29.53,
        neighborhood: 'Great Kills',
        keywords:
            'Beer, Whiskey, Irish, Awesome, Classic, Great, Friendly, Recommended, Local, Casual',
        annual_dol: 34.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7963,
        store_longitude: -73.9709,
        total_orders: 4.69,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Steak, Italian, Wine, Beer, Classy, Innovative, Classic, Casual, Fresh, Great',
        annual_dol: 19.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5779,
        store_longitude: -73.9598,
        total_orders: 26.83,
        neighborhood: 'Brighton Beach',
        keywords: 'Wine, Beer, Trendy, Russian, Hotel, American, Classic, Friendly, Casual, Great',
        annual_dol: 109.67,
        description:
            'Simple neighborhood pool hall with a large selection of tables also provides Ping-Pong & late hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6241,
        store_longitude: -74.031,
        total_orders: 1538.44,
        neighborhood: 'Bay Ridge',
        keywords: 'Mexican, Beer, Margarita, Spanish, Salad, Casual, Friendly, Great',
        annual_dol: 1905.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9913,
        total_orders: 1508.48,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Vegan, Wine, Gluten Free, Dessert, French, Tunisian',
        annual_dol: 1873.93,
        description:
            'Pre-theater standby turning out French-Mediterranean staples in an upscale brasserie setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6933,
        store_longitude: -73.9691,
        total_orders: 2010.77,
        neighborhood: 'Clinton Hill',
        keywords: 'Burger, Steak, Vegan, Beer, Coffee, Wine, Ale, Dessert, Upscale, Irish',
        annual_dol: 2060.34,
        description:
            'Relaxed gastropub with extensive selections of upscale pub grub, entrees & local craft brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7729,
        store_longitude: -73.7333,
        total_orders: 550.51,
        neighborhood: '',
        keywords:
            'Lobster, Salad, Sushi, Hawaiian, Gluten Free, Dessert, Flavorful, Japanese, Wine, Rich',
        annual_dol: 643.2,
        description:
            'A sophisticated selection of Chinese & Japanese dishes, sushi & cocktails in elegant surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9838,
        total_orders: 1704.25,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Steak, Cuban, Beer, Wine, Margarita, Caribbean, Italian, Rum, American, Latin',
        annual_dol: 1839.87,
        description:
            'Boisterous restaurant featuring Cuban fare, cocktails & live music in a retro-Havana setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6983,
        store_longitude: -73.9751,
        total_orders: 4390.15,
        neighborhood: 'Brooklyn Navy Yard',
        keywords: 'Beer, Iconic, Spectacular, Exceptional, Cool, Complex',
        annual_dol: 5976.37,
        description:
            '"New York\'s finest events venues, showcasing spectacular Manhattan and bridge views."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6838,
        store_longitude: -73.9538,
        total_orders: 55.32,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tequila, Tacos, Burger, Beer, American, Delicious, Perfect, Modern, Amazing, Great',
        annual_dol: 226.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6856,
        store_longitude: -73.991,
        total_orders: 253.45,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Zombie, Pizza, Beer, Karaoke, American, Warm, Cozy, Modern, Classic, Friendly',
        annual_dol: 954.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7467,
        store_longitude: -73.9916,
        total_orders: 941.35,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Whiskey Sour, Greek, Salad, Whiskey, Mediterranean, Delicious, Hotel, Rooftop, Gin, Modern',
        annual_dol: 1317.53,
        description:
            'Swanky Greek restaurant inside the Hotel Hayden with city views & elaborate rope decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.9876,
        total_orders: 968.35,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Sushi, Lobster, Savory, Japanese, Spicy, Sashimi, Sustainable, Local, Casual, Omakase',
        annual_dol: 1283.11,
        description:
            'Little Japanese counter serve preparing sushi & rice bowls amid sea-blue lighting & ocean decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -73.9935,
        total_orders: 1214.77,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Vegetarian, Wine, Dessert, Bourbon, Warm, Juicy, Hotel, Bbq, Irish',
        annual_dol: 4965.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7783,
        store_longitude: -73.9804,
        total_orders: 43.27,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Salad, Wine, Beer, Spicy, Impeccable, Mediterranean, Romantic, Israeli, Polished',
        annual_dol: 176.87,
        description:
            'Mediterranean restaurant helmed by an Israeli chef, featuring an open kitchen & two stories.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7405,
        store_longitude: -73.9908,
        total_orders: 152.66,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Delicious, Wine, Italian, Elegant, Romantic, French Toast, Greek, French, Sleek',
        annual_dol: 624.03,
        description:
            'Greek restaurant serving steak, lamb chops, seafood & more in a romantic atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7223,
        store_longitude: -73.9886,
        total_orders: 7296.41,
        neighborhood: 'Lower East Side',
        keywords: 'Sushi, Hotel, Trendy, Beer, Spacious, Japanese, Bbq, Premium, Seasonal, Modern',
        annual_dol: 7676.29,
        description:
            "Sleek rooms, many with floor-to-ceiling windows, offer flat-screen TVs and wood floors. Upgraded rooms add sitting areas and/or balconies. Suites provide wet bars; upgraded suites have terraces and/or living areas. Swanky 2-bedroom or bi-level penthouses feature living rooms with dining areas, and city-view terraces.Amenities include a chic Japanese restaurant, a seasonal rooftop bar and a lively beer garden. There's a spa, a gym and a seasonal rooftop pool with a picture of Andy Warhol at the bottom.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9901,
        total_orders: 14156.59,
        neighborhood: 'Lower East Side',
        keywords:
            'Lobster, Lobster Roll, Burger, Pizza, Wine, Beer, Vodka, Whiskey, Italian, Bourbon',
        annual_dol: 14599.44,
        description:
            'Relaxed choice for drinks & New England shore favorites in shabby-chic quarters with a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8115,
        store_longitude: -73.9578,
        total_orders: 143.6,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Sushi, Steak, Japanese, Spicy, Asian, Thai, Filet Mignon, Fusion, Dope, Modern',
        annual_dol: 224.89,
        description:
            'Modern Asian-fusion restaurant offering sushi, stir-fries, noodle soups & sake, plus lunch specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9744,
        total_orders: 190.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Steak, Pizza, Salad, Italian, Vodka, Meatball, Swiss, Mediterranean, Caesar Wrap',
        annual_dol: 778.67,
        description:
            'This bright counter serve is the take-out & delivery arm of the Tuscan eatery Fresco by Scotto.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9825,
        total_orders: 52.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Sushi, Lobster, Vegetarian, Japanese, Spicy, Sashimi, Delicious, Casual, Western',
        annual_dol: 183.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -73.9981,
        total_orders: 729.49,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Italian, Mexican, Wine, Salad, Upscale, Hotel, Homemade, Polished, Local, Classic',
        annual_dol: 778.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7113,
        store_longitude: -74.0061,
        total_orders: 14017.03,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Cheesesteak, Spicy, Chinese, Delicious, Fried Rice, Warm, Peking Duck, French, Philly Cheesesteak',
        annual_dol: 14546.52,
        description:
            'Chic restaurant/bar offering American steakhouse fare, Chinese dumplings, Peking duck & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7126,
        store_longitude: -73.9567,
        total_orders: 193.44,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Hotdog, Tequila, Beer, Shrimp Cocktail, Mimosa, Wine, Ale, Warm, Cozy, Comfortable',
        annual_dol: 215.71,
        description:
            'Comfortable destination featuring local beer & Prosecco on tap, as well as Skee-Ball.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7104,
        store_longitude: -73.9552,
        total_orders: 1116.62,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Salad, Sushi, Italian, Tasty, Rustic, Artisanal, Sweet, Seasonal, Perfect, Tiramisu',
        annual_dol: 1333.87,
        description:
            'A Tuscan-styled dining room & a large, leafy garden set the stage for rustic Italian cooking.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -73.9778,
        total_orders: 1025.96,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Burger, Hamburger, Vegetarian, Mexican, Beer, American, Irish, Greek, Classic, Casual',
        annual_dol: 1079.93,
        description:
            'This neighborhood bar is known for its classic burgers & year-round patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6721,
        store_longitude: -73.9576,
        total_orders: 363.67,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Salad, Savory, Delicious, Bbq, Friendly, Southern, Fantastic, Excellent, Great',
        annual_dol: 509.47,
        description:
            'Low-key tavern dishing up BBQ & other Southern grub, plus sports on TV & occasional live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.682,
        store_longitude: -73.9584,
        total_orders: 388.47,
        neighborhood: 'Bedford-Stuyvesant/Clinton Hill',
        keywords:
            'Wine, Beer, Delicious, American, Premium, Fancy, Classic, Natural, Great, Incredible',
        annual_dol: 763.64,
        description:
            'Casual restaurant specializing in rotisserie chicken & natural wine in a wood-lined space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.9836,
        total_orders: 204.1,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Vegan, Tequila, Mexican, Vegetarian, Gluten Free, Sustainable, American, Elegant, Contemporary, Modern',
        annual_dol: 277.85,
        description:
            '"Etérea is Overthrow Hospitality\'s newest restaurant featuring an elegant Tequila and Mezcal Cocktail Bar devoted to ethical and sustainable agave spirits curated by Mixologist Sother Teague, who also helms our award-winning bar, Amor y Amargo! Executive Chef Xila Caudillo runs the Culinary Program, and it features robust plant-based, gluten-free small plates that honor her Mexican Heritage and SoCal Upbringing"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.9798,
        total_orders: 802.55,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Lobster, Lobster Roll, Japanese, Shrimp Cocktail, Hotel, Champagne, Upscale, Wine, Trendy, Elegant',
        annual_dol: 2817.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9577,
        total_orders: 1155.89,
        neighborhood: 'Williamsburg',
        keywords: 'Elegant, Beer, Spacious, Clean, Friendly, Cool, Awesome, Great, Casual, Dope',
        annual_dol: 1287.01,
        description:
            'Lively, late-night pool hall offering billiard tables, board games, pub fare & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7629,
        store_longitude: -73.9211,
        total_orders: 6.93,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords: 'Italian, Wine, Friendly, Casual, Authentic, Sweet, Local, Excellent',
        annual_dol: 28.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6834,
        store_longitude: -73.932,
        total_orders: 763.19,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Vegetarian, Wine, Salad, Beer, Rustic, American, French, Romantic, Friendly, Natural',
        annual_dol: 807.12,
        description:
            'Chic, modern hub for creative American fare including brunch & full bar featuring house cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5894,
        store_longitude: -73.7482,
        total_orders: 126.9,
        neighborhood: '',
        keywords: 'Fun, Hotel, Great, Terrific',
        annual_dol: 179.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5735,
        store_longitude: -73.9876,
        total_orders: 246.08,
        neighborhood: 'Coney Island',
        keywords:
            'Pizza, Wine, Beer, Hotel, American, Irish, Rooftop, Innovative, Vibrant, Classic',
        annual_dol: 1005.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.729,
        store_longitude: -73.9883,
        total_orders: 1785.5,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Sushi, Japanese, Sashimi, Hotel, Vintage, Ramen, Gyoza, Friendly, Adventurous, Authentic',
        annual_dol: 1859.02,
        description:
            'Japanese restaurant known for street-food-style fare & inexpensive beer, along with flashy decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7311,
        store_longitude: -73.9902,
        total_orders: 9900.74,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Salad, Dessert, Ramen, Beer, Japanese, Creme Brulee, Spicy, Modern, Smooth, Premium',
        annual_dol: 11341,
        description:
            'Ramen dishes & pork buns are the lures at this popular East Village Japanese eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7382,
        store_longitude: -73.9908,
        total_orders: 540.02,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Italian, Wine, Gluten Free, Coffee, Salad, Dessert, Bruschetta, Cozy, Rustic, Homemade',
        annual_dol: 551.48,
        description:
            'Espresso drinks & wines by the glass go with Italian small plates & baked goods at this urbane cafe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.8817,
        total_orders: 876.99,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Coffee, Wine, Donut, Latin, Perfect, American, Awesome, Great, Wonderful, Lit',
        annual_dol: 935.29,
        description:
            'Offbeat venue for live music, literary events & film screenings, with a full bar as well.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8508,
        store_longitude: -73.9329,
        total_orders: 280.8,
        neighborhood: 'Fort George/Washington Heights',
        keywords: 'Spanish, Club Sandwich, Casual, Gyro, Tradition',
        annual_dol: 1147.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -73.9892,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Pizza, Gourmet, Salad, Delicious, Fresh, American, Caribbean, Great, British',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.9804,
        total_orders: 4093.67,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Salad, Chicken Pot Pie, Beer, French Onion Soup, Irish, Swiss, French, Old Fashioned, Greek, American',
        annual_dol: 4427.35,
        description:
            'Charming Irish pub & restaurant offering American grub plus a full bar and a rooftop courtyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7573,
        store_longitude: -73.9778,
        total_orders: 860.35,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Cheesesteak, Steak, Sushi, Salad, Irish, Philly Cheesesteak, French Onion Soup, French, Vintage, American',
        annual_dol: 3059.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6836,
        store_longitude: -73.8342,
        total_orders: 30.88,
        neighborhood: 'Ozone Park/South Richmond Hill',
        keywords: 'Caribbean, Local, Casual, Attentive, Great',
        annual_dol: 126.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7586,
        store_longitude: -73.9719,
        total_orders: 2363.09,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Sushi, Japanese, Steak, Omelette, Casual, Classic, Unique, Authentic, Great',
        annual_dol: 2928.66,
        description:
            'High-end Japanese locale serving sushi & teppanyaki dishes in a sophisticated, lounge-style setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6241,
        store_longitude: -74.031,
        total_orders: 354.56,
        neighborhood: 'Bay Ridge',
        keywords: 'Lobster, Salad, Italian, Chinese, Spicy, Mongolian, Beer, Thai, Bbq, Fried Rice',
        annual_dol: 362.08,
        description:
            '"Elevated Chinese restaurant serving classic dim sum, rice and noodle dishes, traditional Cantonese specialties, and spicy Sichuan cuisine. Full bar with curated cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6298,
        store_longitude: -74.0283,
        total_orders: 152.66,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Salad, Coffee, Vodka, Dessert, Italian, Margarita, Baked Potato, Caesar Salad, American',
        annual_dol: 163.73,
        description:
            'Circa-1979 cafe serving American eats in a family-friendly space that includes a greenhouse room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9888,
        total_orders: 1631.8,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Chicken Parmigiana, Cheesecake, Shrimp Cocktail, Chilean, Whiskey, Wine, Italian, Shrimp Scampi',
        annual_dol: 1784.81,
        description:
            'Polished, retro-style restaurant serving a menu of aged steaks, cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9895,
        total_orders: 1742.43,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Salad, Dessert, Hotel, Premium, Sweet, Lively, Friendly, Classic, Great',
        annual_dol: 1954.75,
        description: 'This jazz & supper club offers live music, swing dancing & free lessons.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8044,
        store_longitude: -73.9556,
        total_orders: 2245.99,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Whiskey, Salad, Meatloaf, Wine, Beer, Mimosa, Upscale, Relaxed, American, Southern',
        annual_dol: 2940.12,
        description:
            'Relaxed spot with a casual-chic vibe offering modernized soul food classics with cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7743,
        store_longitude: -73.9132,
        total_orders: 22.15,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Pizza, Delicious, Italian, Wine, Sweet, Iconic, Great, Unforgettable, Casual, Local',
        annual_dol: 90.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.9933,
        total_orders: 3757.24,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Vodka, Wine, Ale, Meatball, Delicious, Warm, Irish, Premium, Caesar Wrap',
        annual_dol: 4008.34,
        description:
            'New American small plates & late-night cocktails set the mood at this restaurant/sports bar hybrid.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.9871,
        total_orders: 786.63,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Champagne, Wine, Korean, Beer, Trendy, Coffee, Salad, Vintage, Warm',
        annual_dol: 824.83,
        description:
            'Korean fried chicken, global plates & cocktails fuel patrons enjoying LP tunes & karaoke singers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9831,
        total_orders: 859.12,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Tacos, Beer, Delicious, Irish, Casual, Local',
        annual_dol: 876.02,
        description:
            '"Classy yet easy-going Midtown bar serving tacos and specialty made to order donuts. In the rear is the theatre like Grisly Pear Comedy Club."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6993,
        store_longitude: -73.8073,
        total_orders: 431.2,
        neighborhood: 'Jamaica',
        keywords: 'Whiskey, Coffee, Gourmet, American, Sangria, Omelette, Casual, Great',
        annual_dol: 497.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.9879,
        total_orders: 2151.01,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Whiskey, Margarita, Warm, Fun, Friendly, Western, Fantastic, Great, Casual',
        annual_dol: 2214.72,
        description:
            'Wild West-themed, cash-only gay bar with cowboy decor, country-&-western music & dancing barkeeps.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.761,
        store_longitude: -73.9798,
        total_orders: 5973,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Tail, Steak, Shrimp Cocktail, Sushi, Salad, Lobster Bisque, Pizza, Chilean, Wine',
        annual_dol: 6155.96,
        description:
            'The first NYC branch of a high-end mini-chain, serving steaks, seafood & sushi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7761,
        store_longitude: -73.6502,
        total_orders: 109.42,
        neighborhood: '',
        keywords: 'Sushi, Pizza, Tasty, Beer, Japanese, Spicy, Chilean, Chinese, Rich, Asian',
        annual_dol: 127.61,
        description:
            '"We exemplify modern Japanese dining, highlighting a refine and rich culinary experience. Our artfully presented dishes feature the freshest ingredients with inspirations to please the palate and aims for visual delight. Libations Advisor Mr. Brian Matthys brings his mastery of the cocktails and spirits to our exquisite Cocktail Bar. Under his expert direction, our bar menu will showcase sensational cocktails handcrafted with the best ingredients, quality ice and spirits. A great selection of sake, shochu, wine, and beer is also offered to compliment the cuisine. Sakaya was designed with two distinctive spaces in mind, each with its own unique character. The Dining Room: Our space also features a Sushi Bar and Hibachi room."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7621,
        store_longitude: -73.9776,
        total_orders: 5165.33,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Legendary, Elegant, Vintage, Classic, Vietnamese, Warm, Inviting, Comfortable, American',
        annual_dol: 5277.9,
        description:
            'Times Square.The posh, modern rooms have marble bathrooms, iPod docks, flat-screen TVs and Wi-Fi (surcharge in standard rooms). Suites add amenities such as separate living and dining rooms, fireplaces and/or private balconies. Room service is available.Amenities include a restaurant offering both a la carte and buffet dining, and a classic lounge, plus fitness and business centers. A daily facility fee may apply.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.9755,
        total_orders: 1324.39,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Italian, Elegant, Polished, Perfect, French, Outstanding, Awesome, Lively, Great',
        annual_dol: 3599.24,
        description:
            'Upmarket Italian fare & sizable wine list in a polished setting that includes a private event room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.808,
        store_longitude: -73.945,
        total_orders: 384.65,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Wine, Gin, Tequila, Stylish, Delicious, Vintage, Intimate, Lively, Vibrant, Lit',
        annual_dol: 1365.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8089,
        store_longitude: -73.945,
        total_orders: 278.04,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Mexican, Tacos, Margarita, Salad, Quesadillas, Fajitas, Burritos, Vegetarian, Latin',
        annual_dol: 283.93,
        description:
            'Casual Mexican joint serving up tacos & burritos alongside margaritas & beers from the bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7698,
        store_longitude: -73.9925,
        total_orders: 2899.78,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Clean, Sweet, Bbq, Fancy, Exceptional, Local',
        annual_dol: 3025.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7229,
        store_longitude: -73.9891,
        total_orders: 2351.72,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Hamburger, Margarita, Irish, Spicy, Homemade, Fun, Seasonal, Friendly, Retro',
        annual_dol: 2690.26,
        description:
            'Irish-influenced watering hole with retro 1960s decor offering whiskeys, bar bites & brunch fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9053,
        store_longitude: -73.8979,
        total_orders: 14.12,
        neighborhood: 'North Riverdale',
        keywords: 'Steak, Ale, Irish, Friendly, Great, Excellent, Local, Casual, Attentive',
        annual_dol: 57.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8649,
        store_longitude: -73.9311,
        total_orders: 120.77,
        neighborhood: 'Fort George',
        keywords:
            'Salad, Wine, French, American, Modern, Sidecar, Casual, Bloody Mary, Perfect, Fabulous',
        annual_dol: 493.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7415,
        store_longitude: -74.0053,
        total_orders: 13263.42,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Pizza, Martini, Delicious, Trendy, Bourbon, Rum, Spacious, Fancy, Great',
        annual_dol: 13544.85,
        description:
            'Seattle-based coffeehouse chain known for its signature roasts, light bites and WiFi availability.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6867,
        store_longitude: -73.9759,
        total_orders: 721.62,
        neighborhood: 'Fort Greene',
        keywords: 'Karaoke, Legendary, American, Local, Hip, Cool, Casual',
        annual_dol: 2533.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.74,
        store_longitude: -73.9932,
        total_orders: 621.94,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Wine, Upscale, Polish, Glamorous, Martini, Warm',
        annual_dol: 635.14,
        description:
            '"Upscale and casual fine dining Steakhouse. Enjoy the sound of records playing in a uniquely artistic setting while you enjoy a chef driven steakhouse menu, and thought provoking cocktails and wine list."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7327,
        store_longitude: -73.997,
        total_orders: 128.61,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Lasagna, Wine, Beer, Coffee, Martini, Elegant, Delicious, Spicy, Italian, Crisp',
        annual_dol: 242.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.9586,
        total_orders: 208.39,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Italian, Salad, Beer, Wine, Hawaiian, Dessert, Spicy, Shrimp Scampi, Hotel',
        annual_dol: 392.09,
        description:
            '"MidiCi Neapolitan Pizza is now open in Williamsburg with authentic brick oven pizza, appetizers, salads, Pasta, and desserts made with fresh, natural and mostly non-GMO ingredients in our open kitchen. Sit in a unique setting with a glass of wine or at the bar with a local craft beer."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.708,
        store_longitude: -74.0064,
        total_orders: 2591.72,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Steak, Pizza, Chicken Pot Pie, Irish, Rustic, American, Classic, Fresh, Great',
        annual_dol: 2655.25,
        description:
            '"Nestled in the Financial District, you’ll find all the signature Stout NYC favorites here on the corner of John and Gold Street. If your office is in the area, come see us for lunch or a well-deserved after-work drink. We’re excited to host bottomless brunch on the weekends. We’re also the best meeting point if you’re headed to an event at the Seaport (or leaving one)."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9609,
        total_orders: 487.42,
        neighborhood: 'Williamsburg',
        keywords: 'Sushi, Cinnamon Roll, Yummy, Sweet, Fancy, Fresh, Great',
        annual_dol: 1992.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -73.9605,
        total_orders: 112.91,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Italian, Salad, Mediterranean, Elegant, Cozy, Romantic, Meatball, Rustic, Fun',
        annual_dol: 263.64,
        description:
            'Italian fare & a deep wine list served in a rustic, candlelit setting that lends a romantic vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9281,
        total_orders: 51.62,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegan, Pizza, Tomato Soup, Salad, Gluten Free, French Toast, Belgian, American, Daiquiri, French',
        annual_dol: 211,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7219,
        store_longitude: -73.9965,
        total_orders: 4612.62,
        neighborhood: 'Nolita',
        keywords: 'Hotdog, Pizza, Beer, Tasty, Upscale, Bloody Mary, Casual, Classic, Local, Great',
        annual_dol: 4711.93,
        description:
            'Neighborhood dive bar since the 1920s draws patrons from 8AM on for basic drinks & weekend bagels.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8047,
        store_longitude: -73.9557,
        total_orders: 2829.58,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Burger, Hamburger, Beer, Bbq, Wine, Warm, Bourbon, Spacious, Eclectic, Fun',
        annual_dol: 3156.51,
        description:
            'Corner tavern/beer garden with extensive food & drink menus in a spacious indoor/outdoor setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7278,
        store_longitude: -73.9994,
        total_orders: 112.79,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Upscale, Modern, British, American, French, Dutch, Innovative, Casual, Negroni',
        annual_dol: 468.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7743,
        store_longitude: -73.9079,
        total_orders: 181.52,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Burger, Garlic Bread, Steak, Salad, Beer, Vodka, Lemon Drop, Tasty, Margarita, Martini',
        annual_dol: 651.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.887,
        total_orders: 408.33,
        neighborhood: 'Jackson Heights',
        keywords:
            'Burger, Steak, Pizza, Salad, Shrimp Cocktail, Beer, Coffee, Wine, Chinese, Fajitas',
        annual_dol: 417,
        description:
            'Rustic-chic restaurant plating egg dishes, pancakes, pasta & other comfort fare, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9783,
        total_orders: 1973.68,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Mexican, Dessert, Spicy, Stylish, Trendy, Tex-Mex, Homemade, Spacious',
        annual_dol: 7241,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9839,
        total_orders: 3797.75,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Bright, Modern, Clean, Great, Wonderful, Rooftop, Friendly, Creative, Contemporary',
        annual_dol: 3878.33,
        description:
            "Hudson River.Sleek rooms with a contemporary vibe feature Wi-Fi, flat-screen TVs and minifridges. Some offer river or Times Square views. Upgraded rooms provide sitting areas.There's a buzzy bar/restaurant with panoramic city views. Other amenities include a gym and parking.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7703,
        store_longitude: -73.9917,
        total_orders: 98.38,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Cheesesteak, Gourmet, Coffee, Beer, Italian, Ale, Hotel, Warm, Premium',
        annual_dol: 402.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7083,
        store_longitude: -74.0133,
        total_orders: 88.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Coffee, Comfortable, Contemporary',
        annual_dol: 363.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7148,
        store_longitude: -73.9909,
        total_orders: 725.23,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Wine, French, Trendy, Martini, Beer, Hotel, Cozy, Sleek, Chic, Natural',
        annual_dol: 758.19,
        description:
            'Hip wine bar offering sidewalk seating & small plates, including oysters & ricotta with honey.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.716,
        store_longitude: -73.9016,
        total_orders: 15.1,
        neighborhood: 'Flushing/Maspeth',
        keywords:
            'Burger, Hotdog, Shrimp Cocktail, Hamburger, Salad, Pizza, Fresh, French, Polish, Greek',
        annual_dol: 47.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.764,
        store_longitude: -73.9709,
        total_orders: 195.04,
        neighborhood: 'Lenox Hill',
        keywords: 'Steak, Salad, French, Premium, Fresh, Great, Fine',
        annual_dol: 797.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6915,
        store_longitude: -73.9842,
        total_orders: 8.28,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Beer, Hotel, American, Rooftop, Casual, Friendly, Great',
        annual_dol: 33.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6872,
        store_longitude: -73.8357,
        total_orders: 33.69,
        neighborhood: 'Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Burger, Steak, Cheesesteak, Chinese, Philly Cheesesteak, Caribbean, Fresh, Local, Casual, Gyro',
        annual_dol: 137.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6758,
        store_longitude: -73.818,
        total_orders: 1632.51,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Bbq, Caribbean, Karaoke, Fried Chicken, Chinese, Jamaican, American, Great, Casual, Signature',
        annual_dol: 1994.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5452,
        store_longitude: -74.1608,
        total_orders: 388.92,
        neighborhood: 'Eltingville',
        keywords:
            'Tequila, Steak, Mexican, Beer, Vodka, Gourmet, Breakfast Burrito, Dessert, Rum, Italian',
        annual_dol: 1511.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7363,
        store_longitude: -74.0007,
        total_orders: 268.46,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Meatball, Italian, Salad, Gluten Free, Dessert, Tasty, Spicy, Wine, Delicious, Gin',
        annual_dol: 880.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7418,
        store_longitude: -74.0055,
        total_orders: 229.78,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Burger, Salad, Beer, Dessert, Turkish, Savory, Hotel, Creamy, Baklava',
        annual_dol: 939.27,
        description:
            'Swanky spot for meat-centric Turkish fare from a chef with a flamboyant serving style.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7175,
        store_longitude: -73.9493,
        total_orders: 1265.16,
        neighborhood: 'Williamsburg',
        keywords: 'Tacos, Tasty, Spicy, Warm, Fish Tacos, Trendy, Bbq, Spacious, Fun, Sweet',
        annual_dol: 1348.3,
        description:
            'Chill local bar with a surf-skate theme serving draft cocktails, fish tacos & burgers alongside DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7117,
        store_longitude: -73.9619,
        total_orders: 21391.03,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Salad, Wine, Vodka, Trendy, Organic, Funky, Thai, Eclectic, Cozy, Seasonal',
        annual_dol: 21844.91,
        description:
            'Creative Latin-Asian cocktails offered in snug surrounds with whitewashed brick walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.677,
        store_longitude: -73.9689,
        total_orders: 5025.21,
        neighborhood: 'Prospect Heights',
        keywords:
            'Wine, Coffee, Upscale, Dessert, Warm, Modernist, Sweet, Welcoming, Perfect, American',
        annual_dol: 5439.15,
        description:
            'American restaurant by a chef-farmer team growing many ingredients in its backyard garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7152,
        store_longitude: -73.9506,
        total_orders: 8029.03,
        neighborhood: 'Williamsburg',
        keywords:
            'Tasty, Vegan, Chinese, Delicious, Savory, Spicy, Gin, Charming, Homemade, Friendly',
        annual_dol: 8985.57,
        description:
            'Modern Chinese haunt serving dishes made with local ingredients, including some from its own garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7244,
        store_longitude: -73.9829,
        total_orders: 1156.51,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Beer, Fun, Vibrant, Great, New Zealand, Nostalgic, Fine, Casual',
        annual_dol: 1230.75,
        description:
            "Bar with darts, Skee-Ball & nostalgic decor that reflects Alphabet City's gritty history.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6881,
        store_longitude: -73.9569,
        total_orders: 562,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tacos, Tequila, Mexican, Beer, Tasty, Wine, Delicious, Sleek, Contemporary, Casual',
        annual_dol: 648.35,
        description:
            'This contemporary bar offers cocktails, frozen drinks & a Mexican food truck in the backyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6379,
        store_longitude: -74.0798,
        total_orders: 18.74,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Sushi, Lobster, Cozy, Clean, Casual, Local, Great, Traditional',
        annual_dol: 76.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.744,
        store_longitude: -73.9871,
        total_orders: 1195.3,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Upscale, Wine, Luxurious, Tasty, Hotel, French, Classy, Refined, Warm, Legendary',
        annual_dol: 1544.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.716,
        store_longitude: -73.9623,
        total_orders: 549.2,
        neighborhood: 'Williamsburg',
        keywords: 'Sushi, Vegan, Vodka, Wine, Spicy, Trendy, Korean, Japanese, Asian, Sweet',
        annual_dol: 625.5,
        description:
            'Trendy locale offering Pan-Asian dining & weekend brunch, plus sake, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7438,
        store_longitude: -73.9828,
        total_orders: 41.79,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Burger, Steak, Salad, Coffee, Beer, American, Rooftop, Fun, Casual, Great',
        annual_dol: 170.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7578,
        store_longitude: -73.9274,
        total_orders: 123.31,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Beer, Wine, Upscale, Cozy, Rustic, Fun, American, Greek, Great',
        annual_dol: 287.47,
        description:
            'This cozy gastropub serving upscale versions of bar food, such as burgers, offers brunch some days.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.748,
        store_longitude: -73.9761,
        total_orders: 5.09,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Vegan, Vegetarian, Salad, Gluten Free, Beer, Natural, Contemporary, Authentic, Classy, Casual',
        annual_dol: 20.81,
        description:
            'Vegetarian & vegan Indian dishes are served at this contemporary eatery with a back garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8156,
        store_longitude: -73.96,
        total_orders: 5096.42,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Vegetarian, Vegan, Salad, Italian, Nachos, Beer, Armenian, Indonesian, Thai, Asian',
        annual_dol: 5670.29,
        description:
            'Cozy, wood-accented watering hole serving Southeast Asian bites plus cocktails, sake, beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.764,
        store_longitude: -73.9769,
        total_orders: 2882.63,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Steak, Dessert, Turkey Sandwich, French Onion Soup, French, Japanese, Elegant, Warm, Sashimi',
        annual_dol: 2943.8,
        description:
            'Straight-up French brasserie fare plus sushi & sashimi in a convenient business lunch locale.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 1025.95,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Pizza, Salad, Italian, Gourmet, Dessert, Trendy, Bruschetta, Hotel',
        annual_dol: 1070.43,
        description:
            'Pizza & traditional pastas plus brunch at an outpost of a local Italian chain in the Dream Hotel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 488.11,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Vegetarian, Wine, Gourmet, Dessert, Savory, Elegant, French, Premium, American, Sweet',
        annual_dol: 556.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7663,
        store_longitude: -73.9909,
        total_orders: 157.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Dessert, Delicious, Mediterranean, Modern, Lively, Perfect, Fresh, Greek',
        annual_dol: 276.26,
        description:
            'Lively spot for modern Mediterranean/Mid-Eastern eats plus fresh bread from the namesake domed oven.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7667,
        store_longitude: -73.9961,
        total_orders: 3860.37,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Spacious, Fun, Vibrant, Cozy, Italian, Premium, Great, Rooftop, Clean, Cool',
        annual_dol: 15779.91,
        description:
            '"MUSICA CLUB OPENS IN NEW YORK CITY After a successful debut in July 2020 in the Italian seaside resort town of Riccione, Musica Club is now coming to New York. Musica is born from a partnership between Giuseppe Cipriani and Italian nightlife entrepreneur Tito Pinton, owner of the historic Il Muretto club in Italy, with the idea of combining entertainment, musical performances and creativity with premier service and hospitality, and provide a great enjoyable lifestyle experience. It will be the largest venue of its kind in Manhattan, with two dance floors, two DJ booths, table service, multiple bars and a beautiful rooftop outdoor terrace. The goal is to revive the once vibrant New York club scene and become a joyous destination for an inc"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -73.9899,
        total_orders: 765.75,
        neighborhood: 'Lower East Side',
        keywords: 'Steak, Salad, Vegetarian, Wine, French, Tasty, Beer, Spanish, Cozy, Homemade',
        annual_dol: 782,
        description:
            'Specialty cocktails & European small plates offered in an old-school, Parisian-inspired bistro.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6732,
        store_longitude: -73.7238,
        total_orders: 8.19,
        neighborhood: '',
        keywords:
            'Bbq, Cozy, Fried Calamari, French, American, Belgian, Classy, Southern, Healthy, Casual',
        annual_dol: 33.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7758,
        store_longitude: -73.91,
        total_orders: 85.68,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'Brazilian, Beer, Colombian, Casual',
        annual_dol: 350.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.866,
        store_longitude: -73.9203,
        total_orders: 632.78,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Steak, Mexican, Beer, Italian, Dominican, Spanish, American, Latin, Filet Mignon, Casual',
        annual_dol: 646.2,
        description:
            'International dishes offered in a roomy, lively destination offering outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6362,
        store_longitude: -73.5823,
        total_orders: 590.6,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Beer, Wine, Ale, Caesar Salad, Hurricane, Premium, Clean',
        annual_dol: 819.31,
        description:
            '"Full service bar and grill with table/bar service and Union App ordering available."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8622,
        store_longitude: -73.9204,
        total_orders: 521.3,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Steak, Pizza, Cheesesteak, Kebab, Tequila, Italian, Mexican, Coffee, Philly Cheesesteak, Mediterranean',
        annual_dol: 2517.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6873,
        store_longitude: -73.9862,
        total_orders: 522.85,
        neighborhood: 'Boerum Hill',
        keywords:
            'Crab Cakes, Wine, Martini, Margarita, Moscow Mule, Intimate, Premium, Lit, Creative, Casual',
        annual_dol: 609.45,
        description:
            '"Deity is an all-inclusive, full-service Brooklyn Wedding and Event Space. Affordable packages offer use of our venue for ceremonies, receptions, cocktail parties, seated dinners, corporate meetings and dancing. A unique, non-traditional Wedding, Bar Mitzvah, Corporate Function and Event venue. Our stress-free, all in-house approach features catering with an executive chef, day-of coordination and event captain, full bar, curated wine list and specialty cocktails. With professional wait staff, DJ and optimized sound system, on-site furniture, linens as well as complimentary candle décor. A one-of-a-kind NYC experience conveniently located in walking distance to seven Brooklyn hotels, all major subway lines, Brooklyn Bridge Park and Barclays"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -74.0057,
        total_orders: 4847.96,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vodka, Gin, Salad, Cozy, Homemade, Classic, Cool, Casual, Great, Atmospheric',
        annual_dol: 5013.85,
        description:
            'Atmospheric tavern beneath Chelsea Market with creative cocktails, salvaged decor & DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6891,
        store_longitude: -73.9792,
        total_orders: 1863.5,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Delicious, Beer, Phenomenal, Fun, Sweet, Friendly, Amazing, Perfect, Classy',
        annual_dol: 1944.3,
        description:
            'Bar serving cocktails, local beers & elevated bar bites in an industrial-chic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6798,
        store_longitude: -73.974,
        total_orders: 429.85,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Iranian, Vodka, Delicious, Cozy, Hotel, Homemade, Authentic, Traditional, Mediterranean, Great',
        annual_dol: 619.65,
        description:
            'Modern, ingredient-driven Persian cooking in a whitewashed space with wood-beam ceilings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6747,
        store_longitude: -73.9633,
        total_orders: 284.58,
        neighborhood: 'Prospect Heights',
        keywords: 'Burger, Hamburger, Salad, Tasty, Applejack, Wine, Delicious, Trendy, Ale, Gin',
        annual_dol: 290.62,
        description:
            'Bright canteen offering creative American eats in a trendy but casual space (open late weekends).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7071,
        store_longitude: -74.0019,
        total_orders: 1513.12,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Burger, Mexican, Beer, Italian, Wine, Thai, French, Irish, Bellini, Greek',
        annual_dol: 1575.48,
        description:
            'Storied eatery & bar serving updated pub grub in an old-school setting with TVs & East River views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7101,
        store_longitude: -74.0073,
        total_orders: 901.6,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Vegetarian, Salad, Wine, Beer, Upscale, Warm, Comfortable, American, Sleek',
        annual_dol: 2010.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7156,
        store_longitude: -73.9495,
        total_orders: 18.38,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Salad, Dessert, Coffee, Martini, Wine, Bbq, Cozy, Modern, Negroni',
        annual_dol: 75.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7078,
        store_longitude: -73.9557,
        total_orders: 35.35,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Tasty, Burger, Beer, Wine, Warm, Sweet, Premium, Mediterranean, American, Perfect',
        annual_dol: 144.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7123,
        store_longitude: -73.9629,
        total_orders: 3181.94,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Wine, Coffee, Cajun, Beer, Dessert, Austrian, Cozy, Rustic, Italian, Warm',
        annual_dol: 3629.55,
        description:
            'Brunch is available every day at this casual American eatery with a rustic wood-&-brick look.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7794,
        store_longitude: -73.9504,
        total_orders: 31.29,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Whiskey Sour, Whiskey, Steak, Vodka, Beer, Chicken Pot Pie, Coffee, Ale, American, Moscow Mule',
        annual_dol: 127.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9758,
        total_orders: 1312.45,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Cajun, Beer, Gourmet, Ale, American, French, Irish, Contemporary, Seasonal',
        annual_dol: 1372.49,
        description:
            'Watering hole with a laid-back vibe offering gourmet burgers & bar fare, plus draft brews & TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.9843,
        total_orders: 859.16,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vodka, Russian, Beef Stroganoff, Shrimp Cocktail, Kebab, Salad, Tasty, Rum, Bloody Mary, Dessert',
        annual_dol: 1104.54,
        description:
            'An extensive selection of vodkas plus Russian fare & live piano music fuel this lively nightspot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.99,
        total_orders: 482.2,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Tacos, Vegetarian, Mexican, Beer, Wine, Margarita, Rustic, Delicious, Cozy',
        annual_dol: 492.43,
        description:
            'Cozy Mexican eatery with hip, Asian-accented decor serving rustic fare & a long list of tequilas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7764,
        store_longitude: -73.9789,
        total_orders: 32.69,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Kebab, Coffee, Turkish, Delicious, Magical, Cozy, Creamy, Homemade, Mediterranean, Exquisite',
        annual_dol: 69.68,
        description:
            'A traditional menu, including a popular fixed-price option, served in a richly hued dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8676,
        store_longitude: -73.93,
        total_orders: 388.15,
        neighborhood: 'Fort George/Inwood',
        keywords:
            'Burger, Lobster, Lobster Roll, Hotdog, Sushi, Vegetarian, Tasty, Bbq, American, Dope',
        annual_dol: 1117.98,
        description:
            'Casual American cafe with an Island vibe, outdoor seats & 75,000 sq. ft. of scenic waterfront.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7349,
        store_longitude: -73.9998,
        total_orders: 25076,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Salad, Beer, Martini, Wine, Daiquiri, Whimsical, Friendly, Casual, Aperol',
        annual_dol: 29615.93,
        description:
            'Cocktail lounge with a horseshoe bar, tiki-inspired dining area & jazzy, intimate downstairs space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7373,
        store_longitude: -73.9991,
        total_orders: 629.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Italian, Vegan, Gluten Free, Salad, Spicy, Avocado Toast, Trendy, Warm, Cozy',
        annual_dol: 631.33,
        description:
            'Cozy, romantic Italian eatery serving wood-fired pizza, pasta & family-style weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6862,
        store_longitude: -73.9855,
        total_orders: 1727.78,
        neighborhood: 'Boerum Hill',
        keywords: 'Coffee, Wine, Dessert, Japanese, Korean, Sweet, Chic, Natural, Intimate, Cool',
        annual_dol: 1771.35,
        description:
            '"The concept behind Café Kitsuné is to create and operate a space that marries the simplistic beauty of the small and intimate coffee bars with the style and cool of everything that Café Kitsuné undertakes. Specialty Coffee, Tea by Rishi, Matcha by Kettl, Natural Wine Bar, Spritz & The Bar at Cafe Kitsune features our extensive cocktail menu & Sake collection."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7388,
        store_longitude: -74,
        total_orders: 1174.86,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Cheesecake, Salad, Dessert, Wine, Beer, Magical, Irish, Cozy, Fantastic',
        annual_dol: 1250.28,
        description:
            'This neighborhood bar has dartboards, TVs broadcasting sports games & DJs on weekend evenings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7285,
        store_longitude: -73.7839,
        total_orders: 8.73,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords: 'Tasty, Fresh, Delicious, Chinese, Spicy, Ample, Perfect, Great, Sweet, Bbq',
        annual_dol: 35.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7299,
        store_longitude: -73.9809,
        total_orders: 751.01,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Ale, Karaoke, Fancy, Impressive, Great, Casual, Attentive, Fine',
        annual_dol: 841.66,
        description:
            'This former laundromat offers an extensive offering of draft beers in a casual, brick-lined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.9538,
        total_orders: 1121.24,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Vegetarian, Burger, Steak, Salad, Gluten Free, Tequila, Organic, Wine, Margarita, Delicious',
        annual_dol: 1712.28,
        description:
            'Out-of-the-way East Williamsburg corner restaurant offering New American fare & a raw bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6722,
        store_longitude: -73.9576,
        total_orders: 7122.18,
        neighborhood: 'Crown Heights',
        keywords:
            'Whiskey, Wine, Tasty, Karaoke, Italian, American, Premium, French, Relaxed, Perfect',
        annual_dol: 7279.39,
        description:
            'Relaxed cocktail bar with upholstered chairs, chandeliers, outdoor seating & small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6723,
        store_longitude: -73.9576,
        total_orders: 5197.24,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Salad, Vegan, Beer, Dutch, Dessert, Juicy, Casual, Classic, Local',
        annual_dol: 5438.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6813,
        store_longitude: -73.9572,
        total_orders: 2039.17,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Wine, Delicious, Margarita, Ale, Mexican, Fun, Cozy, Awesome, Friendly',
        annual_dol: 2082.44,
        description:
            'Hip, candlelit cocktail bar featuring live DJs, plus backyard seating with a ping-pong table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7253,
        store_longitude: -73.9831,
        total_orders: 4422.21,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Tasty, Spicy, French Toast, French, Thai, Martini, Perfect, Fried Rice, Latin',
        annual_dol: 6767.11,
        description:
            'International plates & tropical cocktails served in a vast locale with colorful, fashionable decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7064,
        store_longitude: -74.0026,
        total_orders: 54842.7,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Lobster, Hotel, Magical, Seasonal, Western, Creative, Casual, Robust, Great, Excellent',
        annual_dol: 69927.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.9561,
        total_orders: 112.15,
        neighborhood: 'Williamsburg',
        keywords:
            'Thai, Flavorful, Stylish, Spicy, Upscale, Japanese, Asian, Creamy, Authentic, Premium',
        annual_dol: 119.61,
        description:
            'Huge eatery with intricate woodwork & stylish bar serving upscale takes on Southeast Asian eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6726,
        store_longitude: -73.9576,
        total_orders: 10.82,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Vegan, Salad, Beer, Wine, Juicy, Cozy, American, Friendly, Wonderful',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7129,
        store_longitude: -73.9662,
        total_orders: 1425.27,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Zombie, Beer, Wine, Moscow Mule, Spacious, Classic, Great, Local, Cool, Mai Tai',
        annual_dol: 1546.54,
        description:
            'Spacious bar & music venue known for its inexpensive drinks & taco truck parked on the back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7229,
        store_longitude: -73.9559,
        total_orders: 2412.6,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, American, Pizza, Vintage, Casual, Fun, Cool, Chill',
        annual_dol: 2517.2,
        description:
            'Old-school, low-tech bowling alley with an attached barroom that hosts live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -73.9778,
        total_orders: 1501.38,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Beer, Wine, Delicious, Warm, Trendy, Coffee, Fun, Turkish, Friendly, Amazing',
        annual_dol: 1537.47,
        description:
            "Mid-Eastern eats & cocktails fuel the party while DJ's provide the soundtrack at this hookah bar.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7286,
        store_longitude: -73.6355,
        total_orders: 420.13,
        neighborhood: '',
        keywords: 'Sushi, Pizza, Salad, Wine, Italian, Japanese, Coffee, Sashimi, Zen, Spicy',
        annual_dol: 505.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7836,
        store_longitude: -73.9778,
        total_orders: 319.08,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Coffee, Beer, Burger, Wine, Trendy, Organic, Nutritious, Australian, Delicious, Healthy',
        annual_dol: 825.07,
        description:
            '"Bluestone Lane is an Australian-inspired coffee shop, cafés & lifestyle brand committed to providing a genuine daily escape for all our locals. We offer premium coffee and healthy and delicious eats through an always welcoming experience that embraces our ‘Aussie’ approach to life."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6193,
        store_longitude: -73.9237,
        total_orders: 91.3,
        neighborhood: 'Flatlands',
        keywords:
            'Dessert, Italian, Spanish, Thai, Wonderful, Caribbean, Intimate, Great, Authentic, Rare',
        annual_dol: 120.87,
        description:
            '"Servicing Brooklyn and the surrounding area since 1955. Glen Terrace offers four ballrooms ranging in capacity from as intimate as 50 guests to as grand as 300. Full service catering will allow you to customize every aspect of your special event, whether it be your Wedding or any other Social Function. Providing only the freshest and finest of quality, our culinary team offers authentic Caribbean, Italian, American, and Spanish cuisine. Glen Terrace understands that we have one chance to deliver your ultimate dream event, which is why we strive to make every event the best we’ve ever done!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8383,
        store_longitude: -73.9421,
        total_orders: 196.21,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Steak, Tacos, Shrimp Cocktail, Spanish, American, Dominican, Latin, Premium, Casual, Fine',
        annual_dol: 229.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.6794,
        total_orders: 77.32,
        neighborhood: '',
        keywords:
            'Cheesesteak, Pizza, Sushi, Beer, Spicy, Upscale, Swiss, French Onion Soup, Fun, Asian',
        annual_dol: 84.14,
        description:
            '"Repeal is Neighborhood Bar & Grill. We have an Extensive Beer Menu featuring many local Long Island Breweries, as well as Popular Craft Beers from around the Country. Our menu is Classic American bar food with a unique culinary flair. The goal of repeal is to provide our patrons and neighbors a Big city experience within walking distance of their front door."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7249,
        store_longitude: -73.6352,
        total_orders: 280.18,
        neighborhood: '',
        keywords:
            'Tacos, Steak, Mexican, Vegetarian, Vegan, Salad, Gourmet, Wine, Delicious, Healthy',
        annual_dol: 571.86,
        description:
            "\"Welcome to Guac Shop Mexican Grill! Your meal just got a whole lot more exciting. We opened up at the beginning of 2018, and our passion for what we do has pushed us to where we are today the ultimate craving satisfiers in town. Hankering for tacos? We've got 'em. Dying for a burrito? It's on the menu. Begging for a margarita? You know we've got you covered. Even those with restricted diets can find an incredible meal with us. From paleo to keto diets, macros to vegetarian, we promise to provi\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6441,
        store_longitude: -73.5398,
        total_orders: 649.46,
        neighborhood: '',
        keywords:
            'Wine, Dessert, Gluten Free, Italian, Relaxed, Polished, Friendly, Casual, Classic, Seasonal',
        annual_dol: 996.02,
        description:
            'A seafood-driven menu & cocktails in a relaxed seasonal venue with a patio overlooking the water.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9203,
        total_orders: 135.77,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Vietnamese, Trendy, Beer, Hotel, Organic, Elegant, Japanese, Classy, Asian',
        annual_dol: 144.7,
        description:
            'Pho & other familiar Vietnamese dishes, plus craft cocktails, served in a chill, urban setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6672,
        store_longitude: -73.9878,
        total_orders: 2364.17,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Whiskey, Savory, Irish, Friendly, Eclectic, Cool, Great, Local, Casual',
        annual_dol: 2414.33,
        description:
            'Park Slope local neighborhood bar with indie-rock jukebox, pinwheel machine, & patio when it’s warm.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.9145,
        total_orders: 251.99,
        neighborhood: 'Astoria',
        keywords: 'Wine, Beer, Margarita, Cozy, Exotic, Friendly, Perfect, Cool, Fusion, Classic',
        annual_dol: 1035.75,
        description:
            '"There’s virtually no cooler place than this to watch a sports game, have a drink at the bar, and indulge in some late-night food. The atmosphere is sexy and vibrant, not to mention steamy! If you have it in your budget, try the Mastermind-cocktail, which is well worth the price tag and wait. Or if you’re planning on staying up, get an "Up all night", which might just do good on its promise! This is the new hotspot for couples and singles alike."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9165,
        total_orders: 60.99,
        neighborhood: 'Astoria',
        keywords: 'Coffee, Wine, Donut, Cozy, Perfect, Casual, Retro, Signature, Great, Local',
        annual_dol: 186.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -73.9598,
        total_orders: 602.8,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Pizza, Tequila, Salad, Wine, Italian, Canadian, Spicy, Rum, American',
        annual_dol: 628.94,
        description:
            'Casual neighborhood spot with a wood-fired oven, featuring a seasonal brunch & dinner menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.9511,
        total_orders: 172.55,
        neighborhood: 'Greenpoint',
        keywords:
            'Delicious, Cajun, Fresh, Creole, British, Incredible, High-End, Signature, Casual, Mouth-Watering',
        annual_dol: 605.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7659,
        store_longitude: -73.9694,
        total_orders: 215.21,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotel, Luxurious, Elegant, Opulent, Delicious, Iconic, Martini, High-End, Amazing, Refined',
        annual_dol: 279.9,
        description:
            'The refined rooms, decorated in calming tones, have free Wi-Fi, and feature marble bathrooms, flat-screen TVs, and full kitchens or kitchenettes. The opulent suites have separate sitting areas, and some add wood-burning fireplaces, terraces and/or more bedrooms. Room service is available 24/7.Amenities include a guest-only fitness center, and an elegant restaurant that offers breakfast, brunch, afternoon tea and dinner, in addition to an extensive cocktail menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6774,
        store_longitude: -73.9985,
        total_orders: 326.09,
        neighborhood: 'Carroll Gardens',
        keywords: 'Wine, Pizza, Beer, Martini, Italian, Coffee, Ale, Cozy, Fun, Intimate',
        annual_dol: 443.91,
        description:
            'Neighborhood haunt for beer, wine & creative cocktails in an artsy, intimate space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.8761,
        total_orders: 260.29,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Salad, Steak, Shrimp Cocktail, Coffee, Mediterranean, Caesar Salad, American, Friendly, Casual, Local',
        annual_dol: 353.43,
        description:
            'Standby diner dishing up large portions of standard fare at any time of day or night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9535,
        total_orders: 167.31,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Steak, Argentinian, Cuban, Wine, Innovative, Outstanding, Authentic, Sweet, Great, Seasonal',
        annual_dol: 190.55,
        description:
            'Argentinian fare in an elevated dining room with an open kitchen & seasonal outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -73.9886,
        total_orders: 1429.83,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Whiskey, Vodka, Hotel, Premium, American, French, Traditional, Classic, Authentic, Great',
        annual_dol: 5844.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.9908,
        total_orders: 1209.25,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Tacos, Mexican, Tequila, Steak, Flavorful, Dessert, Enchiladas, Delicious, Warm, Authentic',
        annual_dol: 1234.91,
        description:
            'Elevated takes on traditional Mexican dishes & margaritas in a hip, bustling atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -73.9929,
        total_orders: 103.53,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Wine, Salad, Sushi, Italian, French, Spicy, Stylish, Romantic, Elegant, Japanese',
        annual_dol: 155.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5373,
        store_longitude: -74.1508,
        total_orders: 38650.31,
        neighborhood: 'Eltingville',
        keywords:
            'Shrimp Cocktail, Italian, Delicious, Homemade, Asian, Seasonal, Wonderful, Great, Casual, Local',
        annual_dol: 157989.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6385,
        store_longitude: -73.9683,
        total_orders: 11.17,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Pizza, Cheesesteak, Steak, Beer, Nachos, Spicy, Irish, Phenomenal, Classic',
        annual_dol: 62.4,
        description:
            'Easygoing watering hole with exposed-brick walls, classic pub grub & live music on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6971,
        store_longitude: -73.8371,
        total_orders: 33.55,
        neighborhood: 'Richmond Hill',
        keywords:
            'Lobster, Burger, Salad, Milkshake, Mexican, Flavorful, Dominican, Latin, American, Casual',
        annual_dol: 137.13,
        description:
            'Unfussy spot offering Dominican & American food from morning till night, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7996,
        store_longitude: -73.6506,
        total_orders: 1345.07,
        neighborhood: '',
        keywords: 'Burger, Steak, Lobster, Vegetarian, Tacos, Salad, Pizza, Wine, Beer, Organic',
        annual_dol: 1453.12,
        description:
            'Rustic bi-level New American cafe with outdoor deck overlooking a pond, plus weekend live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7129,
        store_longitude: -74.0094,
        total_orders: 3873.73,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Rich, Delicious, Hotel, Spanish, Premium, Greek, American',
        annual_dol: 4063.66,
        description:
            "Part of Wolfgang Puck's international steakhouse group, with a handsome dining room & lounge.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9638,
        total_orders: 2004.79,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Shrimp Cocktail, Tasty, Upscale, Irish, Legendary, French, Trendy, French Onion Soup, American',
        annual_dol: 2053.46,
        description:
            'Longtime Irish watering hole with old-school ambiance & a meat-centric menu. Proper attire a must.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7056,
        store_longitude: -73.8017,
        total_orders: 1283.16,
        neighborhood: 'Jamaica',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Bbq, Margarita, Juicy, French, Ale, Healthy',
        annual_dol: 1354.79,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9727,
        total_orders: 523.46,
        neighborhood: 'Lenox Hill',
        keywords: 'Velvety, Glamorous, Polish, Hotel, Cosmopolitan, Excellent, Fine',
        annual_dol: 557.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8733,
        store_longitude: -73.5982,
        total_orders: 105.36,
        neighborhood: '',
        keywords:
            'Tasty, Salad, Shrimp Cocktail, Delicious, Beer, Warm, Cozy, Italian, Friendly, Welcoming',
        annual_dol: 184.72,
        description:
            'Laid-back option set in a historic building with a warm wood decor & a menu of hearty American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6948,
        store_longitude: -73.9936,
        total_orders: 1565.38,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Salad, Hamburger, Pizza, Roast Turkey, Italian, Beer, Ale, Canadian, Savory, Organic',
        annual_dol: 1598.59,
        description:
            'Local watering hole with understated decor, sidewalk & back-patio seating & a modern pub-fare menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9863,
        total_orders: 668.57,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lasagna, Salad, Pizza, Italian, Meatball, Savory, Spicy, Delicious, Homemade, Irresistible',
        annual_dol: 682.76,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7392,
        store_longitude: -73.9856,
        total_orders: 4281.33,
        neighborhood: 'Midtown Manhattan/Gramercy Park/Flatiron District',
        keywords:
            'Steak, Wine, Beer, American, Prime Rib, Premium, Modern, Robust, Scallops, Natural',
        annual_dol: 6198.43,
        description:
            'Premium restaurant serving oysters, steak, seafood & desserts, plus wine & a robust cocktail list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.788,
        store_longitude: -73.7254,
        total_orders: 892.17,
        neighborhood: '',
        keywords:
            'Burger, Kebab, Steak, Coffee, Delicious, Mediterranean, African, Vibrant, Hotel, Healthy',
        annual_dol: 1116.9,
        description:
            'Adventurous kosher Persian dishes served in plentiful portions in a vibrant contemporary setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.785,
        store_longitude: -73.956,
        total_orders: 303.06,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Delicious, Italian, Warm, Elegant, Beer, Refined, Meatball, Lively, Wonderful, Fine',
        annual_dol: 519.76,
        description:
            'Refined eatery serving Italian favorites such as pasta & meat dishes, plus a brunch menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7837,
        store_longitude: -73.9506,
        total_orders: 925.7,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Burger, Steak, Hamburger, Pizza, Sushi, Beer, Ale, American, Irish, Homemade',
        annual_dol: 958.68,
        description:
            'Dark-wood-lined Irish pub with simple bar eats, draft beer and lots of TVs for sports fans.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7028,
        store_longitude: -73.9935,
        total_orders: 96.93,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Burger, Hamburger, Pizza, Beer, Roast Beef, Dessert, Wine, Bourbon, Organic, American',
        annual_dol: 176.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.9887,
        total_orders: 1129.5,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Cool, Recommended',
        annual_dol: 1203.73,
        description:
            'The Al Hirschfeld Theatre, originally the Martin Beck Theatre, is a Broadway theater at 302 West 45th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1924, it was designed by G. Albert Lansburgh in a Moorish and Byzantine style and was constructed for vaudevillian Martin Beck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8022,
        store_longitude: -73.9568,
        total_orders: 608.46,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Cheesecake, Lobster, Salad, Creamy, Rich, Cozy, New-York-Style, Bbq, Classy, Intimate',
        annual_dol: 621.37,
        description:
            'Bi-level Harlem lounge with a cozy vintage-speakeasy feel serving cocktails & small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7244,
        store_longitude: -73.9957,
        total_orders: 4634.95,
        neighborhood: 'Nolita',
        keywords:
            'Mexican, Burger, Sushi, Beer, Salad, Margarita, Gluten Free, Wine, Upscale, Spicy',
        annual_dol: 5278.89,
        description: 'Mexican bites with local ingredients, plus coffee, wines & mezcals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9924,
        total_orders: 275.27,
        neighborhood: 'NoHo',
        keywords:
            'Wine, Salad, Steak, Tasty, Coffee, Delicious, Warm, Cozy, Charming, Mediterranean',
        annual_dol: 328.83,
        description:
            'Revamped neighborhood hangout serving seafood & vegetable-focused plates in a bright, cozy space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.8909,
        total_orders: 48.21,
        neighborhood: 'Jackson Heights',
        keywords: 'Italian, Salad, Pizza, Wine, French, Classy, Latin, Greek, Brazilian, Friendly',
        annual_dol: 197.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.9899,
        total_orders: 7445.24,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Wine, Coffee, Italian, Avocado Toast, Hotel, Stylish, American, Romantic, Delicious, Contemporary',
        annual_dol: 7603.21,
        description:
            'Hotel lounge serving classic cocktails, wine and polished Italian fare in stylish digs with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6376,
        store_longitude: -74.1334,
        total_orders: 101.44,
        neighborhood: 'Port Richmond',
        keywords: 'Mexican, Salad, Coffee, Savory, Spicy, Omelette, Casual, Local, Great',
        annual_dol: 112.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7465,
        store_longitude: -73.953,
        total_orders: 3622.66,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Sushi, Salad, Wine, Beer, Japanese, Delicious, Sashimi, Ramen, Asian, Fusion',
        annual_dol: 3992.35,
        description:
            'Laid-back Japanese restaurant with exposed-brick walls offering sushi, ramen, noodles & sake.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7286,
        store_longitude: -73.9874,
        total_orders: 311.15,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Steak, Cornbread, Shrimp Cocktail, Salad, Bbq, Margarita, Martini, Caesar Salad, Zesty',
        annual_dol: 390.77,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7341,
        store_longitude: -73.8711,
        total_orders: 2753.45,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Cheesecake, Dessert, Wine, Mexican, Salad, Delicious, Beer, Chinese, Legendary, Polished',
        annual_dol: 3218.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -73.9909,
        total_orders: 1630.07,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Vegan, Pizza, Italian, Coffee, Salad, Upscale, Delicious, Ample, Vibrant, Yummy',
        annual_dol: 1901.54,
        description:
            'Upscale Italian kitchen & cocktail bar in a breezy Union Square setting with outdoor tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7266,
        store_longitude: -73.9915,
        total_orders: 2166,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Steak, Tequila, Beer, Wine, Coffee, American, Mimosa, Screwdriver, Japanese, Spacious',
        annual_dol: 2216.72,
        description:
            'Casual hang with a wide menu of American comfort food & happy-hour specials in a spacious setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7598,
        store_longitude: -73.8288,
        total_orders: 104.76,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Chinese, Spacious, Asian, Premium, Classy, Traditional, Authentic, Fresh, Pure, Fine',
        annual_dol: 138.68,
        description:
            'Spacious eatery in New World Mall offering Chinese dishes & dim sum in banquet hall-like environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9751,
        total_orders: 724.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Salad, Wine, Organic, American, Hotel, Brazilian, Spacious, Contemporary, Classic',
        annual_dol: 2959.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7536,
        store_longitude: -73.9737,
        total_orders: 146.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Butter Chicken, Chicken Tikka Masala, Dessert, Upscale, Trendy, Delicious, Polished, Traditional',
        annual_dol: 325.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8816,
        store_longitude: -73.8383,
        total_orders: 35.59,
        neighborhood: 'East Bronx/Edenwald',
        keywords:
            'Caribbean, Spicy, Jamaican, Fun, Innovative, Lively, Great, Authentic, Local, Signature',
        annual_dol: 145.5,
        description:
            '"Two spaces, One great vibe. Innovative Caribbean Restaurant, offering authentic Jamaican cuisine & fine dining options with an island flair. Exclusive signature cocktail menu featured. While the long standing Bar serves up the greatest tunes with in-house DJs, creating a lively atmosphere to enjoy drinks with a vibe. Klassique is a black-owned business. Happy Hour: Wed-Fri 4pm-8pm Seafood Thursday: Features exclusive seafood menu"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9643,
        total_orders: 1495.57,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Salad, Wine, Italian, Chinese, Upscale, Elegant, Impeccable, Trendy, Classy',
        annual_dol: 1502.22,
        description:
            'Elegant Chinese restaurant, around since 1979 and still considered to be a scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9728,
        total_orders: 1334.15,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Luxurious, Cosmopolitan, Upscale, Hotel, Warm, Polish, Impeccable, Outstanding, Premium, Global',
        annual_dol: 1385.44,
        description:
            '"CORE: is a space in time. A curated environment innovating connections and nurturing encounters and discoveries. An international community of minds, mavericks, and leaders. An epicenter of freedom and ideas. Beauty, mindfulness, ease, sparks of intelligence, pleasure. CORE: is for members only. Outstanding women and men in the most welcoming of places, surrounded by remarkable art and architecture, exceptional food, warm and impeccable service, original cultural programs and avant-garde body-care. A compellingly diverse community to enjoy life, broaden horizons, share knowledge, appreciate the present. Build the future. CORE: is about exchange. CORE: is an attitude. CORE: is a collective performance."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9717,
        total_orders: 274.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Vegan, Salad, Chicken Tikka Masala, Chinese, Gluten Free, American, Hotel, Tandoori Chicken, Indian',
        annual_dol: 1121.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7572,
        store_longitude: -73.9686,
        total_orders: 128.95,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Wine, Nepalese, Beer, Indian, Tandoori Chicken, Casual, Traditional, Biryani',
        annual_dol: 527.11,
        description:
            'Cozy, down-to-earth restaurant plating traditional Indian specialties & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -74.0095,
        total_orders: 539.68,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Vegan, Kebab, Butter Chicken, Chicken Tikka Masala, Wine, Gluten Free, Beer, Spicy, Indian',
        annual_dol: 608.74,
        description:
            'Contemporary Indian find for spicy vegetarian dishes, tandoori meats & fixed-price lunch specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5672,
        store_longitude: -73.8946,
        total_orders: 1453.08,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords:
            'Burger, Shrimp Cocktail, Salad, Vodka, Wine, Gin, French, American, Thai, Caesar Salad',
        annual_dol: 1524.11,
        description:
            'Established waterfront chophouse serving seafood & classic fare in an elegant space with NYC views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6642,
        store_longitude: -73.6981,
        total_orders: 36.06,
        neighborhood: '',
        keywords:
            'Salad, Chicken Parmigiana, Pizza, Shrimp Cocktail, Mexican, Meatball, Italian, Fried Calamari, Wine, Latin',
        annual_dol: 147.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6741,
        store_longitude: -73.9536,
        total_orders: 1166.43,
        neighborhood: 'Crown Heights',
        keywords: 'Cajun, Cornbread, Jambalaya, Pizza, Beer, Gumbo, Creole, Sweet, Southern, Local',
        annual_dol: 1299.71,
        description:
            'Country-chic Cajun kitchen serving up traditional bayou bites, craft beer & spirits.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.9862,
        total_orders: 6217.15,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Luxurious, Martini, Legendary, High-End, Coffee, Vibrant, Bright, Vodka, Rooftop',
        annual_dol: 6353.18,
        description:
            "walk from the Empire State Building.The refined rooms come with high ceilings, designer toiletries and high-thread-count sheets, as well as minibars, free Wi-Fi and flat-screen TVs. Suites add living rooms with pull-out sofas, and city views. In-room beauty treatments and 24-hour room service are available.Amenities include a chic restaurant, a posh cafe and a plush rooftop bar, as well as a cigar lounge. There's also a gym. Parking is offered for a fee.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6904,
        store_longitude: -73.9955,
        total_orders: 4503.02,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Painkiller, Elegant, Delicious, Cozy, Spacious, Relaxed, Classy, Vintage, Awesome, Classic',
        annual_dol: 4598.57,
        description:
            'Banquette-lined cocktail bar with art deco accents & a vintage sewing machine equipping the bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.9885,
        total_orders: 370.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Magical, Salad, Delicious, Homey, Amazing, Excellent, Fine, Casual',
        annual_dol: 1396.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9799,
        total_orders: 1521.55,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Steak, Wine, Beer, Salad, Italian, Upscale, Gin, Irish, French, American',
        annual_dol: 1759.23,
        description:
            'Family-run Irish-style pub offering pizza, comfort food & beer in a contemporary atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8629,
        store_longitude: -73.8341,
        total_orders: 246.05,
        neighborhood: 'Pelham Gardens/East Bronx',
        keywords:
            'Burger, Whiskey, Donut, Chinese, American, Delicious, Legendary, Authentic, Fresh, Casual',
        annual_dol: 1005.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9852,
        total_orders: 888.51,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Burger, Hamburger, Lobster Bisque, Japanese, Beer, Delicious, Casual, Local',
        annual_dol: 988.56,
        description:
            'British-born restaurant for lobster rolls, lobsters & burgers in a big space with a happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.738,
        store_longitude: -73.9836,
        total_orders: 332.3,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'German, Shrimp Cocktail, Salad, Italian, Coffee, Beer, French Onion Soup, French, Wine, Latin',
        annual_dol: 401.53,
        description:
            'Snug German restaurant & bar with an old-world vibe, known for its elaborate holiday decorations.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7489,
        store_longitude: -73.9924,
        total_orders: 431.95,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Burger, Hamburger, Vegetarian, Salad, Cajun, Cajun-Style, Milkshake, Tasty, Vietnamese, American',
        annual_dol: 473.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7266,
        store_longitude: -73.9925,
        total_orders: 4506.36,
        neighborhood: 'NoHo',
        keywords:
            'Burger, Steak, Salad, Wine, Japanese, Spicy, Delicious, Premium, Quaint, American',
        annual_dol: 4703.09,
        description:
            'Exclusive (referral-only) East Village Japanese eatery hidden behind a butcher shop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.772,
        store_longitude: -73.9909,
        total_orders: 818.85,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Wine, Beer, Gourmet, Hotel, Stylish, Italian, Upscale, Seasonal, Premium, Warm',
        annual_dol: 1914.92,
        description:
            '"Morton Williams\' debut bar featuring seasonal wines/beers on tap and mouthwatering eats."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6874,
        store_longitude: -74.0014,
        total_orders: 682.37,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Wine, Coffee, Italian, Comfortable, Warm, Romantic, Charming, Spacious, Friendly, Southern',
        annual_dol: 795.38,
        description:
            'Place for Italian food with a touch of the American South, plus bocce ball on the patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6755,
        store_longitude: -74.0175,
        total_orders: 2441.58,
        neighborhood: 'Red Hook',
        keywords: 'Modern, Perfect, Impeccable, Great, Unique',
        annual_dol: 2547.43,
        description:
            '"The Liberty Warehouse is one of the best wedding venues in New York. Book your private event or corporate party today."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6478,
        store_longitude: -73.7883,
        total_orders: 1242.76,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Steak, Hamburger, Pizza, Salad, Donut, Beer, Coffee, Wine, Dessert',
        annual_dol: 4694.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9783,
        total_orders: 12815.26,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Burger, Cheesesteak, Cheesecake, Wine, Beer, Stylish, American, Trendy, Creme Brulee',
        annual_dol: 13125.91,
        description:
            'Sophisticated chophouse chain serving steak, seafood, sandwiches & cocktails in a trendy space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8479,
        store_longitude: -73.9377,
        total_orders: 5.06,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Sushi, Salad, Pizza, Dessert, Italian, Spicy, Chinese, Sashimi, Asian, Ramen',
        annual_dol: 79.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8311,
        store_longitude: -73.9278,
        total_orders: 149.36,
        neighborhood: 'Highbridge/West Bronx',
        keywords: 'Elegant',
        annual_dol: 671.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.9925,
        total_orders: 1137.76,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Salad, Beer, Belgian, Spanish, Caesar Salad, Filet Mignon, Eclectic, Traditional, Classic',
        annual_dol: 1161.9,
        description:
            'Buzzy, narrow Belgian pub serving various moules frites & other classic eats, plus native beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.9941,
        total_orders: 2596.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Burger, Margarita, Vodka, Wine, Italian, Cointreau, Homemade, Casual, Authentic',
        annual_dol: 3003.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6901,
        store_longitude: -73.9935,
        total_orders: 444.13,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Wine, Beer, Trendy, French Toast, Lebanese, Modernist, French, Delicious, American, Mediterranean',
        annual_dol: 469.98,
        description:
            'American & Middle Eastern cooking styles meld at this modern spot with old- and new-world wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9917,
        total_orders: 1493.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Salad, French Toast, Roast Beef, Italian, French, French Onion Soup, Organic, Cozy, American',
        annual_dol: 1563.03,
        description:
            'Comfort food with breakfast, Greek & Italian options in a warm, traditional diner that has a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6984,
        store_longitude: -73.9934,
        total_orders: 66.12,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Coffee, Wine, French Toast, Salad, Italian, French, Avocado Toast, Breakfast Burrito, Homey, Casual',
        annual_dol: 190.44,
        description:
            'Hip, homey coffee shop serving light fare & pastries along with local wines & beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9879,
        total_orders: 122.34,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Beer, Whiskey, Irish, Korean, Hotel, American, Sleek, Casual, Intimate',
        annual_dol: 500.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7734,
        store_longitude: -73.9065,
        total_orders: 2032.64,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Burger, Irish, Friendly, Outstanding, American, Fresh, Great, Excellent, Casual, Local',
        annual_dol: 2109.56,
        description:
            '"Bar and Restaurant serving craft beer, cocktails and offering a new food menu including brunch on the weekends."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -74.0023,
        total_orders: 761.48,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Dessert, Tasty, Upscale, French, Gluten Free, Elegant, Cozy, Vintage, Hotel, Seasonal',
        annual_dol: 794.5,
        description:
            'Upscale bakery with Parisian origins, specializing in French macarons in a number of flavors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -74.0005,
        total_orders: 71.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Bbq, Spicy, Nachos, American, Egyptian, Relaxed, Fresh, Great, Casual',
        annual_dol: 293.86,
        description:
            'Raucous hookah lounge with a simple setup offering drinks & a simple menu of bar food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9882,
        total_orders: 8996.66,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegan, Vegetarian, Sushi, Salad, Thai, Crisp, Delicious, Friendly, Casual, Innovative',
        annual_dol: 22004.95,
        description:
            '"Experience our plant-based Asian-inspired menu at PLANTA Queen, New York. Located in the heart of NoMad, PLANTA Queen opened to create delicious sushi, noodles, dumplings, cocktails and more vegan Asian-inspired food that promotes environmental sustainability. Under executive chef David Lee, PLANTA Queen aims to fill a void in the market of upscale, full-service, plant-based dining options. Our vision is to celebrate innovative cuisine in a beautiful environment through the power of plants. Come join us for date night or for a gathering with friends or family and enjoy some of the best food, music, and vibes in NoMad."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.9884,
        total_orders: 1765.82,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Burger, Beer, Flavorful, Wine, Rooftop, Glamorous, Spacious, Bright, Fun, Mediterranean',
        annual_dol: 7218.1,
        description:
            'Fashionable lounge with a glass roof offering brunch, snacks & light meals, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -74.0051,
        total_orders: 3922.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Whiskey, Vodka, Wine, Bourbon, Martini, Hamburger, Trendy, Rum, Rich, Delicious',
        annual_dol: 4135.36,
        description:
            'Bar from Danny Meyer offering Southern-inspired cocktails & small bites in a handsome space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7502,
        store_longitude: -73.9905,
        total_orders: 2429.14,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Tasty, Wine, Beer, Nachos, Delicious, Juicy, Ale',
        annual_dol: 8537.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9775,
        total_orders: 184.21,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegan, Tacos, Vegetarian, Mexican, Salad, Margarita, Gluten Free, Tasty, Nachos, Dessert',
        annual_dol: 311.61,
        description:
            '"Taco Dumbo 31 West 52nd Street is a market fresh taqueria and margarita bar wrapped in surf & sun vibes."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.9762,
        total_orders: 3088.71,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Vegetarian, Salad, Premium, Iconic, Fine',
        annual_dol: 6943.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9872,
        total_orders: 1275.59,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Steak, Dessert, Gourmet, Wine, Upscale, Mediterranean, Brazilian, Authentic, Traditional',
        annual_dol: 1320.73,
        description:
            'A parade of servers toting skewered meats and a large salad bar star at this set-price rodizio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9877,
        total_orders: 99.01,
        neighborhood: 'Lower East Side',
        keywords: 'Burger, Steak, Salad, Beer, Gourmet, Milkshake, Tasty, Upscale, Fun, Fresh',
        annual_dol: 404.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7209,
        store_longitude: -73.9872,
        total_orders: 89.94,
        neighborhood: 'Lower East Side',
        keywords:
            'Tacos, Mexican, Steak, Tequila, Nachos, Margarita, Tex-Mex, Wine, Dominican, American',
        annual_dol: 280.88,
        description:
            'Cheerful corner eatery serving a wide array of Mexican & Dominican dishes, plus fruit shakes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9666,
        total_orders: 42.72,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Vegetarian, Shrimp Cocktail, Japanese, Gourmet, Wine, Upscale, Dessert, Australian, Authentic',
        annual_dol: 174.64,
        description:
            'Upscale restaurant with authentic Kobe, Wagyu & prime beef, seafood, cocktails & a long wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7037,
        store_longitude: -73.7988,
        total_orders: 39.98,
        neighborhood: 'Jamaica',
        keywords: 'Mexican, Tacos, Burritos, Salad, Organic, Local, Casual, Warm',
        annual_dol: 224.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9871,
        total_orders: 3372.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Fun, Great, Excellent, Classic, Casual',
        annual_dol: 3930.69,
        description:
            'Buzzing hot spot catering to gay men with drag shows & live entertainment, plus classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7034,
        store_longitude: -73.8146,
        total_orders: 31.47,
        neighborhood: 'Jamaica',
        keywords: 'Hotel',
        annual_dol: 128.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8073,
        store_longitude: -73.9448,
        total_orders: 155.55,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Mexican, Tacos, Warm, Healthy, Clean, Casual, Local, Fresh, Great, Signature',
        annual_dol: 697.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6875,
        store_longitude: -73.8503,
        total_orders: 641.23,
        neighborhood: 'Ozone Park',
        keywords:
            'Cajun, Salad, Juicy, Friendly, Authentic, Natural, Latin, Fresh, American, Creole',
        annual_dol: 1467.29,
        description: '"Cajun seafood & Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5451,
        store_longitude: -74.1654,
        total_orders: 453.26,
        neighborhood: 'Eltingville/Arden Heights',
        keywords:
            'Burger, Salad, Coffee, Wine, Irish, American, Seasonal, Traditional, Fresh, Classic',
        annual_dol: 495.87,
        description:
            'Upbeat tavern offering Irish & American comfort fare & spirits, plus live music & outside seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.687,
        store_longitude: -73.975,
        total_orders: 333.77,
        neighborhood: 'Fort Greene',
        keywords:
            'Beer, Relaxed, Delicious, Friendly, Hip, Casual, Eclectic, American, Local, Great',
        annual_dol: 1364.34,
        description:
            'Neighborhood watering hole offering upmarket pub eats alongside DJ tunes & relaxed ambiance.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.792,
        store_longitude: -73.8096,
        total_orders: 83.33,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Italian, Turkish, Healthy, Greek, Friendly, Natural, Tandoori Chicken, Casual, Great, Indian',
        annual_dol: 340.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.9779,
        total_orders: 14179.57,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Sushi, Beer, Wine, Friendly, Fun, Japanese, Fusion, Lively, Casual, Awesome',
        annual_dol: 14248.25,
        description: '"Studio 151/ Ichi Go Ichi"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7072,
        store_longitude: -74.0025,
        total_orders: 1014.38,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Beer, Tasty, Wine, Ale, Fresh, Comfortable, Cozy, Fabulous, Italian, Fun',
        annual_dol: 1035.9,
        description:
            'Neighborhood watering hole with craft ales, sandwiches & snacks, plus daily happy hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6768,
        store_longitude: -73.9637,
        total_orders: 209.26,
        neighborhood: 'Prospect Heights/Crown Heights',
        keywords:
            'Tacos, Mexican, Tequila, Margarita, French Toast, Coffee, French, Enchiladas, American, Trendy',
        annual_dol: 513.52,
        description:
            'Bronx-born spot for both homey & inventive Mexican dishes along with sophisticated drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6373,
        store_longitude: -74.0756,
        total_orders: 341.69,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords:
            'Pizza, Italian, Wine, Stylish, Upscale, Beer, Delicious, Classy, Romantic, Modern',
        annual_dol: 631.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5862,
        store_longitude: -73.6941,
        total_orders: 10.25,
        neighborhood: '',
        keywords:
            'Tasty, Wine, Delicious, Salad, Margarita, Beer, Hamburger, Spacious, Fun, Vibrant',
        annual_dol: 41.89,
        description:
            'American fare & cocktails served in a vibrant multi-room setting with a patio & dance floor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7632,
        store_longitude: -73.9284,
        total_orders: 212.04,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Whiskey, Cozy, Elegant, Romantic, Beer, South American, Intimate, Latin, Casual',
        annual_dol: 864.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6573,
        store_longitude: -73.6467,
        total_orders: 8203.43,
        neighborhood: '',
        keywords:
            'Lobster, Sushi, Japanese, Wine, Delicious, Trendy, Asian, Fusion, Innovative, Teriyaki',
        annual_dol: 9497.8,
        description:
            'Trendy pick doling out innovative sushi rolls plus hibachi & teriyaki in buzzy, illuminated digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6579,
        store_longitude: -73.648,
        total_orders: 286.88,
        neighborhood: '',
        keywords:
            'Beer, Delicious, Phenomenal, Fun, Irish, Great, Exceptional, Excellent, Casual, Chill',
        annual_dol: 322.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7468,
        store_longitude: -73.6198,
        total_orders: 53.83,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Salad, Cajun, Cajun-Style, Italian, Juicy, Delicious, Creole, Friendly',
        annual_dol: 220.02,
        description: '"Delicious seafood at market price!!! Grand opening 7/30/22"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.9869,
        total_orders: 3033.66,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Tasty, Spicy, Delicious, Coffee, Italian, Mediterranean, Ramen, Hotel, Clean, American',
        annual_dol: 3235.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7139,
        store_longitude: -73.559,
        total_orders: 2370.15,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Chicken Parmigiana, Shrimp Cocktail, Salad, Lobster Bisque, Champagne, Wine, American, Elegant',
        annual_dol: 6128.71,
        description:
            'Eisenhower Park, formerly known as Salisbury Park, is a public park in East Meadow, New York bordered by Hempstead Turnpike on the south and Old Country Road on the north.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6145,
        store_longitude: -73.6445,
        total_orders: 1788.49,
        neighborhood: '',
        keywords:
            'Steak, Burger, Hamburger, Delicious, Juicy, Phenomenal, American, Fresh, Casual, Local',
        annual_dol: 1922.24,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6402,
        store_longitude: -73.6997,
        total_orders: 633.25,
        neighborhood: '',
        keywords: 'Sushi, Salad, Spicy, Wine, Delicious, Japanese, Elegant, Asian, Sashimi, Thai',
        annual_dol: 665.45,
        description:
            'Sleek eatery with a sushi bar preparing creative Asian-fusion dishes in a convivial atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7201,
        store_longitude: -73.9963,
        total_orders: 3383.73,
        neighborhood: 'Little Italy',
        keywords:
            'Vegan, Salad, Gluten Free, Wine, Organic, Mediterranean, Friendly, Contemporary, Shawarma, Israeli',
        annual_dol: 3564.68,
        description:
            'Playful Mediterranean fare with Israeli influences & health-conscious ethos in a plant-filled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -73.9306,
        total_orders: 7482.79,
        neighborhood: 'East Williamsburg',
        keywords:
            'Burger, Beer, Dessert, Funky, Puerto Rican, Delicious, Latin, American, Friendly, Casual',
        annual_dol: 7645.54,
        description:
            'Puerto Rican comfort foods & cocktails are offered in a funky setting with industrial elements.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -73.9903,
        total_orders: 1558.85,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Upscale, Legendary, American, Classic, Modern, Traditional',
        annual_dol: 1749.78,
        description:
            'Upscale offshoot of the storied steakhouse serving an updated menu in a chic, modern setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -74.0041,
        total_orders: 611.94,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Tequila, Mexican, Margarita, Rich, Fajitas, Rustic, Fun, Eclectic, Friendly, Creme Brulee',
        annual_dol: 1316.37,
        description:
            'An imaginative drink list pairs with modern Mexican small plates in a rustic-chic space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7158,
        store_longitude: -73.9966,
        total_orders: 412.44,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Sushi, Burger, Wine, Beer, Coffee, Karaoke, Spicy, Trendy, Japanese, Funky',
        annual_dol: 961.5,
        description: 'Minimalist design Japanese restaurant, sushi bar & sake lounge in Chinatown.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.705,
        store_longitude: -73.923,
        total_orders: 1659.73,
        neighborhood: 'Bushwick',
        keywords: 'Tasty, Beer, Karaoke, Rich, Friendly, Cool, Amazing, Great, Casual, Local',
        annual_dol: 1694.94,
        description:
            'Artsy hangout for cocktails & pub grub, plus live music, film screenings & fundraising events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5816,
        store_longitude: -73.838,
        total_orders: 67.01,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, French Toast, Beer, French, American, Perfect, Phenomenal, Casual',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6588,
        store_longitude: -73.6712,
        total_orders: 256.9,
        neighborhood: '',
        keywords: '',
        annual_dol: 272.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7404,
        store_longitude: -74.0054,
        total_orders: 4320.34,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Burger, Champagne, Delicious, Wine, Spicy, Beer, Creamy, Cozy, Fun, Crisp',
        annual_dol: 11128.92,
        description:
            'Foosball, board games & pool meet cocktails & beer at this low-frills basement lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6194,
        store_longitude: -73.9181,
        total_orders: 24.77,
        neighborhood: 'Flatlands',
        keywords:
            'Sushi, Salad, Japanese, Chinese, Delicious, Bbq, Asian, Fried Rice, Friendly, Fusion',
        annual_dol: 101.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6535,
        store_longitude: -73.5573,
        total_orders: 1747.16,
        neighborhood: '',
        keywords: 'Sushi, Burger, Salad, Pizza, Japanese, Spicy, Creamy, Chinese, Sashimi, Thai',
        annual_dol: 1900.06,
        description:
            'Sleek eatery with a sushi bar preparing creative Asian-fusion dishes in a convivial atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7793,
        store_longitude: -73.9739,
        total_orders: 1008.61,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Beer, Italian, Elegant, Meatball, Mediterranean, Friendly, Vibrant, Handcrafted, Traditional',
        annual_dol: 1077.89,
        description:
            "Venetian-inspired appetizers & handcrafted pastas in the New-York Historical Society's airy cafe.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6424,
        store_longitude: -74.0165,
        total_orders: 38.33,
        neighborhood: 'Sunset Park',
        keywords:
            'Irish, Vintage, African, American, Amazing, Great, Excellent, Friendly, Local, Fine',
        annual_dol: 125.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7082,
        store_longitude: -74.0108,
        total_orders: 4027.07,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Sushi, Gluten Free, Wine, Upscale, Delicious, American, Refined, Classic',
        annual_dol: 4394.8,
        description:
            'Outpost of the upscale steakhouse chain offers classic American fare & a clubby, refined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6677,
        store_longitude: -73.7946,
        total_orders: 361.51,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'Coffee, Hotel, Caribbean, American, Great',
        annual_dol: 1536.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6701,
        store_longitude: -73.9787,
        total_orders: 163.43,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Beer, Coffee, Ale, American, Refined, Prosecco, Sweet, Great, Rooftop',
        annual_dol: 333.56,
        description:
            'Refined bar/lounge serving high-end cocktails, beer & wine, plus light bites, in tasteful environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7031,
        store_longitude: -73.9334,
        total_orders: 460.08,
        neighborhood: 'East Williamsburg',
        keywords:
            'Salad, Burger, Mexican, Italian, Organic, Caesar Salad, French, Funky, Delicious, Cozy',
        annual_dol: 471.33,
        description:
            'Hearty New American dishes & some Mexican & Italian eats in a cozy spot with local art on the walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6946,
        store_longitude: -73.9107,
        total_orders: 605.5,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Tasty, Fun, Hip, Classic, Great, Daiquiri, Casual',
        annual_dol: 622.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7073,
        store_longitude: -73.9419,
        total_orders: 136.04,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Burger, Hamburger, Vegan, Meatloaf, Salad, Tomato Soup, Delicious, French, American, Grilled Cheese Sandwich',
        annual_dol: 317.64,
        description:
            'Cozy, exposed-brick spot serving comfort food featuring local ingredients, with vegan options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5896,
        store_longitude: -74.1015,
        total_orders: 2275.61,
        neighborhood: 'Todt Hill',
        keywords:
            'Sushi, Salad, Steak, Dessert, Japanese, Sashimi, Homemade, Casual, Friendly, Teriyaki',
        annual_dol: 2367.32,
        description:
            '"We are proud to be a locally owned, family friendly restaurant. Inspired by generations of family recipes, Sakana Sushi & Hibachi Steakhouse has a vast menu of culinary delights, utilizing the freshest ingredients to bring homemade dishes straight to your table. Our mission is to provide Staten Island with the best quality ingredients, and the love for Japanese food. We invite you to join us and enjoy the food that excites us and keeps us passionate. Find us when looking for hibachi restaurant in Staten Island, Sushi Restaurant in Staten Island, NY and Japanese Restaurant."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7859,
        store_longitude: -73.6222,
        total_orders: 102.3,
        neighborhood: '',
        keywords:
            'Flavorful, Pizza, Salad, Spicy, Shrimp Cocktail, Gluten Free, Chicken Tikka Masala, Delicious, Beer, Modern',
        annual_dol: 385.42,
        description: '"NOW OPEN"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7158,
        store_longitude: -74.0086,
        total_orders: 2177,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Whiskey, Beer, Salad, Hotel, Comfortable, Classy, Classic, Cool, Fantastic, Hot Toddy',
        annual_dol: 7813.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.723,
        store_longitude: -74.003,
        total_orders: 129.79,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Flavorful, Wine, Beer, Coffee, Tasty, Dessert, American, Classic, Cool, Great',
        annual_dol: 491.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7757,
        store_longitude: -73.9536,
        total_orders: 1022.75,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegan, Lobster, Steak, Vegetarian, Salad, Chocolate Mousse, Wine, Lobster Bisque, Coffee, Italian',
        annual_dol: 2445.39,
        description:
            'Cozy, elegant bistro specializing in Mediterranean-inspired seafood, steak & vegetarian bowls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7214,
        store_longitude: -73.8452,
        total_orders: 424.86,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Salad, Vodka, Beer, Spicy, Delicious, Bbq, French, Asian, Greek',
        annual_dol: 462.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.8391,
        total_orders: 33.42,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Tasty, Wine, Cuban, Delicious, Organic, Latin, Colombian, Perfect, American, Fresh',
        annual_dol: 109.1,
        description:
            'Casual eatery & bar serving dishes from various Latin cuisines alongside beer, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8083,
        store_longitude: -73.9304,
        total_orders: 172.6,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Burger, Steak, Salad, Pizza, Meatball, Nachos, Beer, Delicious, Upscale, Cuban',
        annual_dol: 310.12,
        description:
            'Upscale pub grub, steaks & local brews in a laid-back barroom space, plus a bustling weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.721,
        store_longitude: -74.0044,
        total_orders: 1458.82,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Hotel, Wine, Coffee, Upscale, Whiskey, Trendy, American, Modern, Chic, Rooftop',
        annual_dol: 1618.29,
        description:
            'miles from the 9/11 Memorial Museum.Sophisticated rooms with custom-designed beds offer flat-screen TVs, Wi-Fi (fee) and coffeemakers. Club rooms have skyline views, free Wi-Fi, and access to a 21st-floor lounge and rooftop terrace offering complimentary breakfast and evening appetizers. Suites add separate living rooms.Amenities include a lobby bar/grill, a lounge and a coffee shop. There are 24/7 fitness and business centers, along with 2 meeting rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -73.9975,
        total_orders: 84.86,
        neighborhood: 'Little Italy',
        keywords:
            'Lobster, Salad, Pizza, Chicken Parmigiana, Shrimp Cocktail, Italian, Gluten Free, Wine, Beer, Delicious',
        annual_dol: 103.95,
        description:
            'Cozy red-sauce Italian spot in Little Italy does all the classics in rustic, wood-beamed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7209,
        store_longitude: -74.0048,
        total_orders: 606.73,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Cheesecake, Delicious, Italian, Elegant, Warm, Cozy, Perfect, Japanese, Authentic, Modern',
        annual_dol: 633.03,
        description:
            'Easygoing, bi-level restaurant with outdoor seating serving modern Tuscan dishes & Italian wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7942,
        store_longitude: -73.94,
        total_orders: 187.83,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Burger, Lobster, Lobster Roll, Pizza, Beer, Coffee, Spanish, American, Wholesome, Local',
        annual_dol: 279.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6993,
        store_longitude: -73.912,
        total_orders: 223.86,
        neighborhood: 'Bushwick',
        keywords:
            'Lobster Roll, Lobster, Sushi, Salad, Japanese, Organic, Asian, Ramen, Fusion, Healthy',
        annual_dol: 730.86,
        description: '"sushi, Asian Fusion and Spanish."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -73.5648,
        total_orders: 58.2,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Gluten Free, Japanese, Beer, Savory, Chinese, Sashimi, Asian, Fusion',
        annual_dol: 237.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 4827.88,
        neighborhood: '',
        keywords:
            'Steak, Burger, Salad, Wine, Italian, Beer, Upscale, American, Phenomenal, Ribeye Steak',
        annual_dol: 4936.1,
        description:
            'Outpost of the upscale steakhouse chain offering classic American fare & a clubby, refined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.6348,
        total_orders: 111.86,
        neighborhood: '',
        keywords:
            'Salad, Burger, Wine, Dessert, Italian, French Onion Soup, French, Delicious, Caesar Salad, American',
        annual_dol: 176.99,
        description:
            'Neighborhood bistro with brick walls, booths, sidewalk seating & New American standards.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8522,
        store_longitude: -73.8291,
        total_orders: 5239.91,
        neighborhood: 'Pelham Bay',
        keywords: 'Beer, Margarita, Rum, Premium, Mouth-Watering, Rooftop, Cool',
        annual_dol: 8541.11,
        description:
            'Brick walls, neon lighting & TV sports are some highlights of this hookah lounge & cocktail bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.642,
        store_longitude: -73.6052,
        total_orders: 204.18,
        neighborhood: '',
        keywords: 'Irish, American, Local, Classic, Casual',
        annual_dol: 208.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6368,
        store_longitude: -73.6002,
        total_orders: 436.3,
        neighborhood: '',
        keywords:
            'Burger, Steak, Garlic Bread, Shrimp Cocktail, Beer, Wine, Italian, Bourbon, Bbq, Ale',
        annual_dol: 455.22,
        description:
            '"Sonny\'s Canal House provides dine in, delivery, and catering services to the Baldwin, NY area."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7861,
        store_longitude: -73.9726,
        total_orders: 636.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Salad, Ramen, American, Casual, Omelette, Southern, Local, Fresh, Traditional',
        annual_dol: 678.38,
        description:
            'Locally sourced ingredients feature in traditional American comfort foods & house-baked treats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7846,
        store_longitude: -73.9771,
        total_orders: 2356.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Salad, Tacos, Pizza, Delicious, Juicy, Beer, Irish, Ale, Bruschetta',
        annual_dol: 2406.34,
        description:
            'Low-key eatery & bar with TVs, 2 floors & a modern American & Irish menu plus a few outdoor seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7842,
        store_longitude: -73.9736,
        total_orders: 263.86,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Chinese, Stylish, Delicious, Sweet, Friendly, Asian, Authentic, Traditional, Perfect',
        annual_dol: 434.89,
        description:
            '"Tri Dim West transports you from the Upper West Side to China with our delicious authentic dishes made with the highest quality ingredients. Tri Dim West carries a variety of traditional Chinese dishes from Dim Sum to Jelly fish salad, as well as our famous Pecking Duck, there\'s something for everyone. Whether a first date, large party, business lunch, or just catching up, Tri Dim West is the perfect venue for all your dining needs. With our stylish decor matched with friendly staff and of course amazing food you\'ll be sure to leave elated. Tri Dim West is a second location to popular sister restaurant Tri Dim Shanghai. Some of our customers favorite items are Soup Dumplings, DongPo Pork, and of course Pecking Duck!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7831,
        store_longitude: -73.9786,
        total_orders: 40.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Lobster, Italian, American, Rooftop, Bright, Natural, Casual, Rare, Great, Cultural',
        annual_dol: 58.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5927,
        store_longitude: -73.9253,
        total_orders: 7.8,
        neighborhood: '',
        keywords: 'Burger, Tacos, American, Irish, Fresh, Casual, Great',
        annual_dol: 31.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7354,
        store_longitude: -73.6749,
        total_orders: 631.63,
        neighborhood: '',
        keywords:
            'Steak, Burger, Hamburger, Dessert, Delicious, American, Casual, Classic, Handcrafted, Lively',
        annual_dol: 811.68,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5757,
        store_longitude: -73.9799,
        total_orders: 315.89,
        neighborhood: 'Coney Island',
        keywords: 'Salad, Wine, American, Spicy, Casual, Local, Traditional, Great',
        annual_dol: 386.96,
        description: '"Bar and Grill"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9187,
        total_orders: 577.77,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Trendy, Modern, Mediterranean, Casual, Greek, Sleek, Local, Cool, Traditional',
        annual_dol: 673.99,
        description:
            'Traditional seafood & lamb dishes get updated takes in a casual Greek tavern with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7704,
        store_longitude: -73.9155,
        total_orders: 37.58,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords: 'Pizza, Beer, Italian, Meatball, Trendy, Warm, Fresh, Clean, Perfect, Great',
        annual_dol: 87.19,
        description:
            'Trendy pizzeria & lounge serving craft cocktails, an array of pies & other bites in warm quarters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9143,
        total_orders: 1763,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tequila, Tacos, Whiskey, Vodka, Champagne, Beer, Steak, Salad, Bourbon, Cuban',
        annual_dol: 5148.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9407,
        total_orders: 2195.08,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Ale, Wine, Pizza, Relaxed, Sweet, Fun, Cool, Casual, Great',
        annual_dol: 2290.25,
        description:
            'A long list of brews, pub grub & a weekend beer brunch fuel the festive vibes at this relaxed bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6964,
        store_longitude: -73.9298,
        total_orders: 7677.61,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Whiskey, Gourmet, Gin, Cozy, Bourbon, Warm, Inviting, Relaxed',
        annual_dol: 8165.62,
        description:
            'Relaxed watering hole with a cozy, nostalgic vibe offering gourmet grilled cheese & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -73.958,
        total_orders: 414.16,
        neighborhood: 'Greenpoint',
        keywords:
            'Polished, Spacious, Hotel, Clean, Authentic, Friendly, Vibrant, Modern, Amazing, Excellent',
        annual_dol: 548.3,
        description:
            'Featuring wood floors and contemporary artwork, the polished suites provide complimentary Wi-Fi, flat-screen TVs and iPhone docks, plus minifridges and coffeemakers. Upgraded suites add kitchenettes, living areas, terraces and/or marble bathrooms.Amenities include a restaurant/bar, a fitness room and a sauna. An area shuttle is available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7352,
        store_longitude: -73.6605,
        total_orders: 300.62,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Nachos, Salad, Tex-Mex, Dessert, Burritos, Quesadillas, Fajitas, Casual',
        annual_dol: 378.5,
        description:
            "\"Gonzo's Mexican Grill is one of the finest garden city restaurants in the area! When you go into Gonzo's, you will see photos of the owner, Joseph Gonzalez posing with such super celebrities as Whoopi Goldberg and Robin Williams, but, when you get to the one of him and Albert Einstein, it occurs to you that there is something chronologically wrong. If you know Joe, you would realize that this is just one manifestation of his quirkiness. Gonzo's customers also enjoy the jovial atmosphere that is a result of Joe's outgoing personality. Come try anything from tacos, fajitas, and burritos to quesadillas, wings, and ensalades! We also have cervezas, sangria, margaritas, and don't forget to try our queso!\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7336,
        store_longitude: -73.9997,
        total_orders: 110.06,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Mexican, Tacos, Salad, Beer, Burritos, Wine, Casual, Local, Warm, Classy',
        annual_dol: 572.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7209,
        store_longitude: -73.8463,
        total_orders: 3068.78,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Instagrammable, Steak, Beer, Wine, Trendy, Latin, Fusion, Global, Aesthetic, American',
        annual_dol: 3272.09,
        description:
            'Trendy spot with a floral theme offering cocktails & a global menu of sharing plates & mains.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8194,
        store_longitude: -73.6008,
        total_orders: 206.31,
        neighborhood: '',
        keywords: 'Legendary, Hotel, Exceptional, Premium, Great, Cultural, Unique',
        annual_dol: 219.55,
        description:
            'Concert hall on LIU Post campus hosts a variety of music, dance & theater productions.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6502,
        store_longitude: -73.8374,
        total_orders: 272.37,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Wine, Steak, Cheesecake, Italian, Upscale, Dessert, Delicious, Elegant, Warm, Exquisite',
        annual_dol: 323.94,
        description:
            'This complex includes an Italian restaurant, wine cellar & lounge, plus views of Jamaica Bay.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7877,
        store_longitude: -73.954,
        total_orders: 41.48,
        neighborhood: 'East Harlem/Central Park West Historic District',
        keywords: 'Coffee, Salad, Wine, Italian, Dessert, Delicious, Gin, Beer, Cozy, Phenomenal',
        annual_dol: 169.56,
        description:
            'Chill, cozy espresso bar offering pastries & sandwiches, along with wine & Italian cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.657,
        store_longitude: -74.002,
        total_orders: 10.35,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Coffee, Wine, Casual, Chill',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.787,
        store_longitude: -73.978,
        total_orders: 1515.68,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Salad, Pizza, Shrimp Cocktail, Beer, French Onion Soup, Caesar Salad, French, American, Delicious',
        annual_dol: 1653.31,
        description:
            'Retro-chic eatery offering French dip sandwiches, steaks & other American bites & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6684,
        store_longitude: -73.5543,
        total_orders: 146.4,
        neighborhood: '',
        keywords: 'Coffee, Apple Pie, Pizza, Gourmet, Creme Brulee, Gumbo, Lively, Casual',
        annual_dol: 163.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -73.6738,
        total_orders: 23.83,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Salad, Chicken Caesar Salad, Caesar Salad, Casual, Local, Fabulous, Classic, Great',
        annual_dol: 33.38,
        description:
            'Italian restaurant serving pizza, subs, pasta & classic entrees in a relax, mural-filled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6687,
        store_longitude: -73.985,
        total_orders: 1472.9,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Hamburger, Salad, Beer, Nachos, Bbq, Tex-Mex, Ale, American, French',
        annual_dol: 1504.15,
        description:
            'A lengthy list of beer & spirits, plus burgers, pool & board games, are the draws at this bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.949,
        total_orders: 17392.72,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Vegetarian, Wine, Vodka, Fruity, Peruvian, Mediterranean, Lebanese, Eclectic, Global',
        annual_dol: 35796.99,
        description:
            'Lively restaurant serving global cuisine & cocktails amid plant-filled, industrial-chic decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6977,
        store_longitude: -73.9272,
        total_orders: 5648.44,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Margarita, Beer, Whimsical, Dope, Perfect, Urban, Awesome, Wonderful, Lit',
        annual_dol: 6044.63,
        description:
            'Cool, dimly lit bar featuring DJs, a photo booth & a rotating menu of astrology-inspired cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8395,
        store_longitude: -73.905,
        total_orders: 27.28,
        neighborhood: 'Morrisania/West Bronx/Claremont',
        keywords: 'Beer, Golfclub, Dominican, Delicious, Casual, Classic, Great',
        annual_dol: 123.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8331,
        store_longitude: -73.8515,
        total_orders: 643.38,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Sushi, Salad, Vegan, Steak, Seafood Paella, Cuban, Mexican, Rum, Warm, Dominican',
        annual_dol: 657.03,
        description:
            'Easygoing stop dispensing traditional Cuban dishes & mojitos in lively & upbeat surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -73.6837,
        total_orders: 122.27,
        neighborhood: '',
        keywords: 'Beer, Karaoke, Wine, Cozy, American, Sweet, Classy, Awesome, Local, Great',
        annual_dol: 221.4,
        description:
            '"Home sweet gnome. One of Long Island\'s favorite bars with cozy beer garden, serving a variety of beers, local crafts, liquor, wine, seltzers, and bar food. For entertainment, we have seven large screen HDTVs with sports, digital jukebox, arcade machine, video poker, and table hockey. We have daily specials and events: - Tuesday Game Night - Wednesday Karaoke - Friday DJ - Saturday DJ - and regularly rotating live bands & artists!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8137,
        store_longitude: -73.8852,
        total_orders: 23.5,
        neighborhood: 'Hunts Point/West Bronx',
        keywords: 'Exotic, American, Great, Casual',
        annual_dol: 96.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6696,
        store_longitude: -73.9829,
        total_orders: 823.77,
        neighborhood: 'Park Slope',
        keywords:
            'Vegan, Mexican, Salad, Coffee, Fajitas, Enchiladas, American, Latin, Delicious, Caesar Salad',
        annual_dol: 917.21,
        description:
            'Classic Mexican fare, sangria & more come in casual, colorful digs at this low-key corner standby.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6301,
        store_longitude: -74.1403,
        total_orders: 458.95,
        neighborhood: 'Port Richmond',
        keywords:
            'Pizza, Meatball, Salad, Italian, Margarita, Dessert, Homemade, Fresh, Sangria, Casual',
        annual_dol: 520.9,
        description:
            'Family-owned mainstay producing thin-crust pizza plus fried calamari, subs & more in no-frills digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7684,
        store_longitude: -73.9557,
        total_orders: 572.67,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Sushi, Salad, Wine, Beer, Coffee, Delicious, Casual, American, Perfect',
        annual_dol: 2157.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7071,
        store_longitude: -73.9474,
        total_orders: 1313.05,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Beer, Trendy, Fun, Lively, Relaxed, Vibrant, Eclectic, Great, Iconic, Casual',
        annual_dol: 1340.91,
        description:
            'Laid-back cocktail bar with live jazz, small plates, craft beers & a garden patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7674,
        store_longitude: -73.9596,
        total_orders: 176.42,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Gluten Free, Mexican, Mimosa, Beer, Gourmet, Tasty, Milkshake, Delicious',
        annual_dol: 216.46,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7706,
        store_longitude: -73.9265,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Japanese, Wine, Trendy, Cozy, Spacious, Friendly, Latin, Bright, Local',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7292,
        store_longitude: -73.9896,
        total_orders: 1816.82,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Salad, Wine, Delicious, American, Friendly, Fun, Vintage, Clean',
        annual_dol: 2069.23,
        description:
            'A spin-off of a Williamsburg bar, this hang has vintage video games (Pac-Man, Donkey Kong) & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8504,
        store_longitude: -73.9332,
        total_orders: 604.88,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Steak, Tasty, Beer, Delicious, Dominican, Caribbean, American, Latin, Lively, Great',
        annual_dol: 717.06,
        description:
            'Lively hot spot for Dominican fare, including a variety of mofongo, plus cocktails & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8015,
        store_longitude: -73.9647,
        total_orders: 11.39,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Thai, Salad, Spicy, Delicious, Martini, Green Curry, Latin, Authentic, Dominican, Caribbean',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.7683,
        total_orders: 179.84,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Salad, Italian, Shrimp Cocktail, Pizza, Coffee, Rich, Homemade, Casual, Traditional, Fresh',
        annual_dol: 225.86,
        description:
            'Stately Italian dining room offering a menu of traditional entrees, pastas & seafood, plus a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7631,
        store_longitude: -73.9687,
        total_orders: 1883.09,
        neighborhood: 'Lenox Hill',
        keywords:
            'Steak, Salad, Pizza, Wine, Dessert, Upscale, Margarita, Martini, Irish, Polished',
        annual_dol: 1923.04,
        description:
            'A classic steakhouse menu, plus inventive New American entrees & a raw bar, in sleek quarters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7576,
        store_longitude: -73.9707,
        total_orders: 586,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Lobster, Cheesecake, Salad, Japanese, Wine, Beer, Fusion, Delicious, Asian',
        annual_dol: 792.1,
        description:
            'Modern, colorful kitchen & bar offering sushi, poke & Japanese noodle dishes, plus daily happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7604,
        store_longitude: -73.9734,
        total_orders: 1015.7,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Salad, Flavorful, Dessert, Meatball, Delicious, Greek, Mediterranean, Upscale, Warm',
        annual_dol: 1015.7,
        description:
            'Chic Greek venue offering updated specialties & seafood priced by the pound, in an airy setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9691,
        total_orders: 1113.52,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Flavorful, Chinese, Salad, Upscale, Dessert, Rich, Gourmet, Trendy, Elegant, Spicy',
        annual_dol: 1137.14,
        description:
            'Classic, upscale Chinese eatery since 1971 serving traditional dishes in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7561,
        store_longitude: -73.9653,
        total_orders: 95.14,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Wine, Italian, Upscale, Hotel, American, Stylish, Delicious, Contemporary, Southern',
        annual_dol: 110.9,
        description:
            'This stylish throwback watering hole features beer, cocktails & a menu of upscale pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.748,
        store_longitude: -73.9858,
        total_orders: 546.98,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Steak, Salad, Beer, Rustic, Irish, Clean, Refreshing, Fun, Asian, Fresh',
        annual_dol: 671.13,
        description: '"Let the good time begins here. Good Food. Good Drinks. Good Friends."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7431,
        store_longitude: -73.9909,
        total_orders: 1663.05,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Hotel, Trendy, Bourbon, Cozy, Rooftop, Amazing, Intimate, Greek, Modern, Edgy',
        annual_dol: 1783.11,
        description:
            "Modern rooms with industrial-chic decor and graffiti-inspired artwork offer free Wi-Fi, flat-screen TVs, coffeemakers and marble bathrooms. Upgraded rooms and suites add balconies.There's a Greek restaurant by celebrity chef Emeril Lagasse, and an indoor/outdoor penthouse bar. Other amenities include passes to the nearby Equinox gym",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.996,
        total_orders: 12782.94,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Mexican, Upscale, Sustainable, Warm, Hotel, Italian, American, Fun, Perfect',
        annual_dol: 13139.88,
        description:
            'Local produce, Berkshire pork chops & fresh fish star on the New American menu at this hotel eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6889,
        store_longitude: -73.9552,
        total_orders: 288.75,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Burger, Steak, Lobster, Tequila, Whiskey, Beer, Scotch, Wine, Bourbon, Ale',
        annual_dol: 488.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9875,
        total_orders: 1178.2,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'American, Wine, Prosecco, Modern, Sweet',
        annual_dol: 4816.08,
        description:
            'The Lyric Theatre is a Broadway theater at 214 West 43rd Street in the Theater District of Midtown Manhattan in New York City. Opened in 1998, the theater was designed by Richard Lewis Blinder of Beyer Blinder Belle, in collaboration with Peter Kofman, for Garth Drabinsky and his company Livent.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6845,
        store_longitude: -73.9917,
        total_orders: 52.94,
        neighborhood: 'Carroll Gardens/Brooklyn Heights',
        keywords:
            'Steak, Vegan, Pizza, Wine, Tasty, French, German, French Onion Soup, Swiss, Trendy',
        annual_dol: 216.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7764,
        store_longitude: -73.9102,
        total_orders: 4998.64,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Coffee, Wine, Salad, Beer, Upscale, Mimosa, Screwdriver, Trendy, American, Spacious',
        annual_dol: 7539.4,
        description:
            'Spacious cafe featuring New American fare, coffee drinks & Wi-Fi in rustic-chic surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7302,
        store_longitude: -74.0005,
        total_orders: 16448.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Hamburger, Cheesecake, Kebab, Pizza, Coffee, Italian, Margarita, American, Cozy',
        annual_dol: 16797.59,
        description:
            'Longtime joint above the Comedy Cellar with Middle Eastern fare, Chaplin films & chalkboard tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.7898,
        total_orders: 71.04,
        neighborhood: 'Auburndale/Flushing',
        keywords:
            'Vegetarian, Coffee, Chinese, Irish, Bourbon, Fun, Mediterranean, Friendly, Awesome, American',
        annual_dol: 83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7025,
        store_longitude: -73.9267,
        total_orders: 1956.37,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Margarita, Wine, Rum, Dominican, Hotel, American, Latin, Urban, Casual',
        annual_dol: 1956.37,
        description:
            'Funky, plant-filled nightclub in a warehouse with a dance hall, DJs & Latin American nibbles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7306,
        store_longitude: -74.0042,
        total_orders: 1392.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tequila, Beer, Vodka, Wine, Scotch, Bourbon, Ale, Gin, Delicious, Rum',
        annual_dol: 1421.99,
        description:
            'Sports bar offering TVs & pool in a space adorned with a fireplace & stained-glass windows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8475,
        store_longitude: -73.8319,
        total_orders: 38.34,
        neighborhood: 'Pelham Bay',
        keywords: 'Vegetarian, Italian, Friendly, Casual, Fun, Great',
        annual_dol: 156.74,
        description: '"Open for Dine in"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7065,
        store_longitude: -73.6266,
        total_orders: 120.97,
        neighborhood: '',
        keywords: 'Clean, Friendly, Casual, Fresh, Local, Classic, Attentive, Indian',
        annual_dol: 494.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8543,
        store_longitude: -73.8884,
        total_orders: 1065.94,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Steak, Pizza, Salad, Italian, Wine, Dessert, Coffee, New-York-Style, American, Homemade',
        annual_dol: 1538.02,
        description:
            'Large family-owned Neapolitan-Italian restaurant serving red-sauce fare since 1919.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9609,
        total_orders: 3369.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Salad, Beer, Wine, Tasty, Upscale, Warm, Delicious, Juicy, Hotel, Bbq',
        annual_dol: 3447.27,
        description:
            'Craft beers, cocktails & New American bar bites feature at this hip hangout with pingpong & pinball.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7172,
        store_longitude: -74.0126,
        total_orders: 115.87,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Trendy, Hotel, Healthy, Perfect, Homemade, Global, Great, Local, Casual, Diverse',
        annual_dol: 315.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7706,
        store_longitude: -73.9572,
        total_orders: 194.73,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Cheesecake, Salad, Pizza, Vegetarian, Chocolate Mousse, Italian, Gluten Free, Wine, Delicious, Classy',
        annual_dol: 236.87,
        description:
            'Classic Italian brick-walled cafe serving pastas & more, with plenty of outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6917,
        store_longitude: -73.9395,
        total_orders: 43.17,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Savory, Hotel, Sweet, Cool, Nachos, Great, Casual',
        annual_dol: 176.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9934,
        total_orders: 57397.57,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Whiskey, Irish, Spectacular, Hotel, Fun, Iconic, Great, Excellent, Friendly, Rooftop',
        annual_dol: 59004.16,
        description:
            'Madison Square Garden, colloquially known as The Garden or by its initials MSG, is a multi-purpose indoor arena in New York City. It is located in Midtown Manhattan between Seventh and Eighth avenues from 31st to 33rd Street, above Pennsylvania Station.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6725,
        store_longitude: -73.9833,
        total_orders: 536.01,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Wine, Ale, Hotel, Irish, Awesome, Local, Casual, Great, Artisanal',
        annual_dol: 656.59,
        description:
            'Hard-to-find beers turn up at this laid-back tavern with dim lighting & an appealing patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.9173,
        total_orders: 235.83,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Beer, Wine, Irish, Karaoke, American, Classic, Perfect, Great',
        annual_dol: 246.06,
        description:
            'Longtime neighborhood Irish bar offering pub grub in comfy digs, plus karaoke, a pool table & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9186,
        total_orders: 346.8,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegetarian, Burger, Salad, Tasty, Coffee, Wine, Dessert, Delicious, Warm, Healthy',
        annual_dol: 364.23,
        description:
            'Cozy, urban, neighborhood bar/eatery offering inventive comfort food, a tapas menu & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9193,
        total_orders: 813.96,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, South American, Colombian, American, Latin, Mojito, Casual, Authentic, Great',
        annual_dol: 1154.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7703,
        store_longitude: -73.8704,
        total_orders: 467.88,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Hotel, Vibrant, Colorful, Contemporary, Hip',
        annual_dol: 497.91,
        description:
            "Queens Museum.Bright, streamlined rooms come with free Wi-Fi and flat-screen TVs. Upgraded rooms sleep up to 4 guests.There's a colorful bistro and an informal bar. Breakfast is available for a fee. Pets are welcome (fee).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.9427,
        total_orders: 5140.7,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Zen, Armenian, Casual, Urban',
        annual_dol: 5481.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7468,
        store_longitude: -73.9455,
        total_orders: 13.33,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Coffee, Tasty, Peruvian, Juicy, Delicious, Bbq, Fried Rice, Latin, American, Natural',
        annual_dol: 54.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6755,
        store_longitude: -74.0156,
        total_orders: 49.01,
        neighborhood: 'Red Hook',
        keywords: 'Beer, Delicious, American, Relaxed, Casual, Handcrafted, Great, Local',
        annual_dol: 126.3,
        description:
            '"Brewery and tasting room specializing in handcrafted ales, lagers and Foeder beers."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7254,
        store_longitude: -73.9919,
        total_orders: 2891.09,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Sushi, Delicious, Salad, Wine, Japanese, Beer, Amazing, Creative, Intimate, Relaxed',
        annual_dol: 5361.12,
        description:
            'Intimate setting for creative seasonal plates served in tasting menus & à la carte.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.9885,
        total_orders: 999.8,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Lobster Roll, Lobster, Chocolate Mousse, Wine, Dessert, Vodka, Beer, Gin, Upscale, Warm',
        annual_dol: 1114.04,
        description:
            'Upscale-casual seafood spot with a Cape Cod vibe known for its lobster rolls and cheap oyster deal.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7279,
        store_longitude: -73.9856,
        total_orders: 1105.67,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tacos, Mexican, Milkshake, Margarita, Tasty, Burritos, American, Cozy, Latin, Sweet',
        annual_dol: 1129.13,
        description:
            'This cozy venue offers tacos, burritos & other low-key Mexican eats, plus beers & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7266,
        store_longitude: -73.9863,
        total_orders: 139.03,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Salad, Coffee, Italian, Martini, Californian, Trendy, American, Casual, Local',
        annual_dol: 340.05,
        description: '"East Village bar and restaurant."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7343,
        store_longitude: -73.8647,
        total_orders: 1396.84,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Burger, Cornbread, Salad, Bbq, Margarita, Californian, American, Yummy, Coleslaw, Friendly',
        annual_dol: 1457.4,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -73.8663,
        total_orders: 530.05,
        neighborhood: 'Rego Park/Elmhurst/Flushing',
        keywords: 'Wine, Scotch, Tasty, Beer, Delicious, Savory, Hotel, American, Premium, Relaxed',
        annual_dol: 2615.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7322,
        store_longitude: -73.9853,
        total_orders: 225.99,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Upscale, Perfect, Rooftop, Casual, Creative',
        annual_dol: 257.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5756,
        store_longitude: -73.9613,
        total_orders: 442.02,
        neighborhood: 'Brighton Beach',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Italian, Roast Beef, Russian, Delicious, Yummy, Spanish, Relaxed',
        annual_dol: 481.73,
        description:
            'Vegas-style floor shows are paired with Russian eats at this glitzy nightclub with boardwalk tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7539,
        store_longitude: -73.9775,
        total_orders: 8803.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Coffee, Delicious, Hotel, Classic, Great, Western, Wonderful, Excellent, Casual',
        annual_dol: 8837.41,
        description:
            'The Yale Club of New York City, commonly called The Yale Club, is a private club in Midtown Manhattan, New York City. Its membership is restricted almost entirely to alumni and faculty of Yale University. The Yale Club has a worldwide membership of over 11,000.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9781,
        total_orders: 1403.63,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Upscale, French, Warm, Spanish, Phenomenal, Contemporary, Innovative, Seasonal, Traditional',
        annual_dol: 5034.33,
        description:
            'Wine & French fare served in a dramatic, upscale eatery with greenery, high ceilings & city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7662,
        store_longitude: -73.7722,
        total_orders: 72.28,
        neighborhood: 'Bayside',
        keywords:
            'Vegetarian, Salad, Vodka, Stylish, Delicious, Upscale, Trendy, Elegant, Romantic, Mediterranean',
        annual_dol: 78.77,
        description:
            'Compact eatery offering Greek dishes & sandwiches, plus soups & sides in a casual ambiance.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7106,
        store_longitude: -74.0095,
        total_orders: 20843.13,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Lobster, Salad, Spicy, Wine, Japanese, Creamy, Innovative, Seasonal, Superb',
        annual_dol: 22043.32,
        description:
            'Famed Japanese-Peruvian fare in a landmark space with an artful lounge & downstairs dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7371,
        store_longitude: -73.9846,
        total_orders: 961.9,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Burger, Beer, Vodka, Ale, Nachos, French, Quesadillas, Irish, American, Local',
        annual_dol: 982.31,
        description:
            'This neighborhood pub draws sports fans to watch games, play darts & shoot pool.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6908,
        store_longitude: -73.9837,
        total_orders: 1346.87,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Lobster, Salad, Cajun, Lobster Bisque, Wine, Beer, Bbq, Ale, Korean, Creole',
        annual_dol: 2992.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9849,
        total_orders: 1180.47,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, American, Modern, Premium, Rooftop, Artisanal, Contemporary, Bold Flavor, Vibrant, Exceptional',
        annual_dol: 1205.51,
        description:
            'Contemporary eatery with new renditions of steakhouse favorites in a roomy, industrial-chic space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 2006.93,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Pizza, Hamburger, Salad, American, Casual, Friendly, Local, Classic, Lively',
        annual_dol: 2320.88,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9846,
        total_orders: 2113.68,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Dessert, Modern, Delicious, Hotel, Russian, American, White Russian',
        annual_dol: 7578.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7513,
        store_longitude: -73.991,
        total_orders: 1184.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Steak, Vegetarian, Tasty, American, Irish, Fun, Iconic, Rooftop, Local, Casual',
        annual_dol: 1367.77,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.751,
        store_longitude: -74.0026,
        total_orders: 590.95,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Wine, Italian, Pizza, Cozy, Quaint, Beer, Romantic, Friendly, French',
        annual_dol: 763.5,
        description:
            'Cozy locale with a patio serving Italian fare for breakfast, lunch & dinner, plus happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6277,
        store_longitude: -74.0138,
        total_orders: 216.23,
        neighborhood: 'Dyker Heights',
        keywords: 'Pizza, Beer, Wine, Irish, Warm, Relaxed, Casual, Southern, Great, Local',
        annual_dol: 230.44,
        description:
            'Draft beer & cocktails served in a down-to-earth sports bar with games on TV & a warm vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7296,
        store_longitude: -74.001,
        total_orders: 5592.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Hotel, Casual, Welcoming, Local, Great',
        annual_dol: 6519.22,
        description:
            'Clubby pub where beer & burgers are served to folks watching TV sports, live comedians or karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7214,
        store_longitude: -73.9953,
        total_orders: 1263.78,
        neighborhood: 'Nolita',
        keywords:
            'Wine, Salad, Vegetarian, Dessert, Mediterranean, Beer, Delicious, Rustic, Spicy, Premium',
        annual_dol: 1620,
        description:
            'Restaurant & wine bar serving Mediterranean small plates & entrees in a rustic, brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -73.998,
        total_orders: 2625.68,
        neighborhood: 'Little Italy',
        keywords:
            'Lasagna, Lobster, Pizza, Chicken Parmigiana, Italian, Gluten Free, Wine, Whiskey, Vodka, Meatball',
        annual_dol: 2705.38,
        description:
            'Traditional Italian eatery offering the standard red-sauce fare in an old-school setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7176,
        store_longitude: -74.0053,
        total_orders: 136.46,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Sushi, Cozy, Intimate, Japanese, Refined, Traditional, Casual, Seasonal, Rare, Cool',
        annual_dol: 557.79,
        description:
            'Refined Japanese spot with a 12-seat counter for omakase sushi & kaiseki dinners.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7059,
        store_longitude: -73.9226,
        total_orders: 449.62,
        neighborhood: 'Bushwick',
        keywords:
            'Kebab, Salad, Tequila, Margarita, Mediterranean, Chicken Shawarma Plate, Delicious, Cozy, Israeli, Shawarma',
        annual_dol: 712.57,
        description:
            'Mediterranean mezze & mains are served in a quirky space with a relaxed vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7051,
        store_longitude: -73.9274,
        total_orders: 360.94,
        neighborhood: 'East Williamsburg',
        keywords:
            'Wine, Beer, Warm, Delicious, Rustic, Organic, Natural, Perfect, Seasonal, Casual',
        annual_dol: 1475.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7755,
        store_longitude: -73.9532,
        total_orders: 10912.44,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Whiskey, Beer, Salad, Wine, Delicious, Upscale, Mimosa, Warm, Irish',
        annual_dol: 11151.14,
        description:
            'Old-fashioned cocktails & a hefty beer & whiskey list, set amid reclaimed wood & vintage wallpaper.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7214,
        store_longitude: -73.8452,
        total_orders: 1911.35,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Salad, Lobster, Sushi, Japanese, Tasty, Chinese, Thai, Asian, Delicious, Greek',
        annual_dol: 1956.59,
        description:
            'This contemporary Asian-fusion spot boasts creative cuisine, plus a nightclub vibe & a sushi menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7026,
        store_longitude: -73.9172,
        total_orders: 604.83,
        neighborhood: 'Bushwick',
        keywords:
            'Pizza, Vegetarian, Salad, Coffee, Wine, Organic, Cozy, Artisanal, Natural, Perfect',
        annual_dol: 631.06,
        description:
            'Comfy pizzeria serving wood-fired pies with a sourdough crust, plus natural wines & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7404,
        store_longitude: -74.0056,
        total_orders: 214.1,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Coffee, Vodka, Beer, Gin, Dessert, Gourmet, Bourbon, Delicious, Warm, Sweet',
        annual_dol: 748.08,
        description:
            'Chill, vintage-style spot with a patio for choice coffee, craft cocktails & gourmet light bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7209,
        store_longitude: -74.0054,
        total_orders: 27.59,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Steak, Whiskey Sour, Beer, Salad, Vodka, Whiskey, Coffee, Wine, Martini',
        annual_dol: 112.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7857,
        store_longitude: -73.6487,
        total_orders: 57.89,
        neighborhood: '',
        keywords:
            'Tacos, Kebab, Salad, Wine, Iranian, Turkish, Mediterranean, Homemade, Classy, Healthy',
        annual_dol: 74.65,
        description:
            'Laid-back Persian eatery offering kebabs & grilled meat plates, plus salads & rice dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6252,
        store_longitude: -74.0746,
        total_orders: 13.56,
        neighborhood: 'Clifton',
        keywords: 'Pizza, Salad, Dessert, French, Caesar Salad, Casual, American, Friendly',
        annual_dol: 55.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6481,
        store_longitude: -73.6885,
        total_orders: 25.04,
        neighborhood: '',
        keywords:
            'Burger, Wine, Beer, Avocado Toast, Ale, Irish, Belgian, Warm, French Toast, Cozy',
        annual_dol: 45.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -73.9129,
        total_orders: 242,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Tasty, Comfortable, Eclectic, Great, Terrific, Casual, Local',
        annual_dol: 911.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7309,
        store_longitude: -73.6935,
        total_orders: 1491.68,
        neighborhood: '',
        keywords:
            'Steak, Burger, Chicken Parmigiana, Salad, Meatball, Wine, Beer, French, Mediterranean, American',
        annual_dol: 1556.35,
        description:
            'New American fare is presented in spacious, modern surroundings at this family-friendly restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9165,
        total_orders: 485.29,
        neighborhood: 'Astoria',
        keywords: 'Tacos, Steak, Mexican, Rich, Trendy, Ale, Vibrant, Authentic, Lively, Fun',
        annual_dol: 3394.85,
        description:
            '"Inspired by our roots, we want to share with you one of the greatest riches that we have: our gastronomy. With original recipes from our ancestors, seasoned with the love and the charisma of our people, we’ve come to New York to stay. La Tiendita wants to bring you the experience of savoring legitimate Mexican food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6713,
        store_longitude: -73.9843,
        total_orders: 862.95,
        neighborhood: 'Park Slope',
        keywords: 'Tequila, Beer, Ale, Wine, American, Friendly, Casual, Local, Classic, Great',
        annual_dol: 926.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.9197,
        total_orders: 180.24,
        neighborhood: 'Astoria',
        keywords:
            'Burger, Warm, Coffee, Creamy, Bbq, Irish, Lively, Mediterranean, Welcoming, Friendly',
        annual_dol: 180.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.9575,
        total_orders: 969.58,
        neighborhood: 'Greenpoint',
        keywords:
            'Salad, Chicken Caesar Salad, Hotdog, Wine, Dessert, Italian, French, Impeccable, American, Classy',
        annual_dol: 1001.89,
        description:
            'American restaurant & bar with Canadian-inspired offerings, including a raw bar & seasonal plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7082,
        store_longitude: -73.9432,
        total_orders: 2009.45,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Beer, Delicious, Dessert, Japanese, Fun, Robust, Natural, Wonderful, Modern, Perfect',
        annual_dol: 2052.08,
        description:
            'Chill neighborhood tavern with small plates & brunch service, plus many craft beers & a dance floor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7638,
        store_longitude: -73.9677,
        total_orders: 561.98,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Tasty, Italian, Upscale, Wine, Coffee, Delicious, Elegant, Impeccable, Classy',
        annual_dol: 573.9,
        description:
            'An Italian menu of traditional dishes like pasta turned out in an intimate, classy space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8541,
        store_longitude: -73.8872,
        total_orders: 15.04,
        neighborhood: 'Little Italy/Belmont',
        keywords: 'Italian, Salad, Wine, Cozy, Greek, Classy, Casual, Local, Great',
        annual_dol: 61.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6836,
        store_longitude: -73.9924,
        total_orders: 690.27,
        neighborhood: 'Carroll Gardens',
        keywords: 'Burger, Vodka, Wine, Pizza, Beer, Salad, Bourbon, Gin, Italian, Delicious',
        annual_dol: 939.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6888,
        store_longitude: -74.0217,
        total_orders: 438.74,
        neighborhood: 'Governors Island',
        keywords: 'Hotel, Beer, Vintage, Exotic, Spacious, Cool, Unique',
        annual_dol: 551.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6441,
        store_longitude: -73.7804,
        total_orders: 5526.95,
        neighborhood: 'Jamaica',
        keywords: 'Pizza, American, Fresh, Great',
        annual_dol: 5782.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7343,
        store_longitude: -73.9923,
        total_orders: 1005.67,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Italian, Wine, Tasty, Savory, Spicy, Mongolian, Chinese, Asian, Polished, Kung Pao',
        annual_dol: 1027.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7949,
        store_longitude: -73.9658,
        total_orders: 94.96,
        neighborhood: 'Manhattan Valley/UWS',
        keywords: 'Mexican, Tacos, Burritos, Salad, Organic, Local, Casual',
        annual_dol: 388.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8286,
        store_longitude: -73.9485,
        total_orders: 6852.49,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Burger, Hamburger, Vegan, Salad, Beer, Margarita, Spicy, Creamy, American, Cozy',
        annual_dol: 7717.17,
        description:
            'Cozy pub offering a huge selection of craft beers on tap plus spirits & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.824,
        store_longitude: -73.9494,
        total_orders: 331.91,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Tequila, Whiskey, Beer, Vodka, Margarita, Bourbon, Gin, Salad, Mimosa, American',
        annual_dol: 469.7,
        description:
            'Bar with a contemporary look, focusing on craft beers, whiskeys, on-tap wines & small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6291,
        store_longitude: -74.0274,
        total_orders: 72.86,
        neighborhood: 'Bay Ridge',
        keywords: 'American, Beer, Foreign',
        annual_dol: 91.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7792,
        store_longitude: -73.9508,
        total_orders: 166.04,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tacos, Steak, Mexican, Tequila, Salad, Nachos, Margarita, Enchiladas, Fajitas, Delicious',
        annual_dol: 301.74,
        description:
            '"WE ADDED NEW HOURS OPEN FOR BREAKFAST 10:00 AM Monday through Friday. BAR OPEN UNTIL 2 AM Thursday through Saturday"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6893,
        store_longitude: -73.9713,
        total_orders: 4317.89,
        neighborhood: 'Fort Greene',
        keywords: 'Whiskey, Vodka, Beer, Scotch, Bourbon, Tasty, Ale, Rum, Delicious, Cozy',
        annual_dol: 4409.51,
        description:
            'Mixologist-made cocktails in a tile-lined bar hidden behind a speakeasy-style unmarked door.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9917,
        total_orders: 174.76,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Vegetarian, Salad, Mexican, Gluten Free, Canadian, Rustic, Peruvian, Ecuadorian, Mimosa',
        annual_dol: 450.32,
        description:
            'Latin American fare in rustic-chic digs with Mexican tiles, repurposed Brazilian wood & marble bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9965,
        total_orders: 4246.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Stylish, Modern, Caesar Salad, Premium, Contemporary, Fresh, Classic, Tradition',
        annual_dol: 4735.27,
        description:
            'Stylish nightspot featuring live DJ events & a lounge for cocktails & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9929,
        total_orders: 673.29,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Wine, Karaoke, Fun, Bbq, Intimate, Quirky, Perfect, Great, Casual',
        annual_dol: 673.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5197,
        store_longitude: -74.2351,
        total_orders: 1479.42,
        neighborhood: 'Tottenville',
        keywords:
            'Steak, Salad, Sushi, Japanese, Thai, Asian, Contemporary, Fusion, Polished, Casual',
        annual_dol: 1526.46,
        description:
            'Contemporary sushi house & hibachi grill offering Chinese, Thai & Japanese menu items.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6932,
        store_longitude: -73.9672,
        total_orders: 303.81,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Salad, Pizza, Shrimp Cocktail, Tasty, Italian, Dessert, Wine, Vodka, Gin',
        annual_dol: 551.44,
        description:
            'Warm Italian joint making pizza, pasta & risotto, plus fritto misto, burrata salad & oysters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9865,
        total_orders: 119.46,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Wine, Trendy, Beer, Coffee, Stylish, French, Friendly, Unique',
        annual_dol: 488.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7085,
        store_longitude: -74.0136,
        total_orders: 32.62,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Steak, Burger, Hamburger, Pizza, Salad, Vodka, Nachos, Japanese, Bbq, Martini',
        annual_dol: 133.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7479,
        store_longitude: -73.9946,
        total_orders: 11846.59,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Korean, Casual, Premium',
        annual_dol: 12097.95,
        description:
            'DJs & bottle service keep the party going at this bi-level club with a high-wattage sound system.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7537,
        store_longitude: -73.9959,
        total_orders: 2709.61,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Lobster, Burger, Lobster Tail, Shrimp Cocktail, Wine, Beer, Spanish, Delicious, Australian',
        annual_dol: 2769.07,
        description:
            'Traditional chophouse serving large steaks, classic sides & martinis in a clubby atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9995,
        total_orders: 10.35,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Tasty, Delicious, Elegant, Clean, Exceptional',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7278,
        store_longitude: -74.0011,
        total_orders: 13749.36,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Japanese, Modern, Exotic, Local, Retro, Casual, Sophisticated, Nostalgic, Serene, Cool',
        annual_dol: 16014,
        description:
            'Japanese-inspired craft cocktails & comfort dishes are the draw at this low-lit, sophisticated bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6629,
        store_longitude: -73.6918,
        total_orders: 11.04,
        neighborhood: '',
        keywords: 'Salad, Pizza, Italian, Mexican, Beer, Asian, Clean, Friendly, Casual, Sweet',
        annual_dol: 27.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.975,
        total_orders: 12462.08,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Luxurious, Elegant, Hotel, Rich, Salad, Organic, Vibrant, Premium, Sleek, Polished',
        annual_dol: 12789.25,
        description:
            "The elegant rooms offer marble bathrooms, free Wi-Fi and desks, plus flat-screen TVs with DVD players. Upgraded rooms include city views, fireplaces and soaking tubs. Suites add living areas and complimentary chauffeur service. Room service is available.There's a polished fine-dining restaurant, and a chic rooftop bar with a terrace and city views, as well as a cocktail bar, and a lounge serving afternoon tea. Other amenities include a fitness center, and a sleek spa with an indoor pool.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9884,
        total_orders: 1191.6,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Martini, Rum, American, French, Sweet, Local, Perfect, Aperol, Great',
        annual_dol: 1296.77,
        description:
            'Sports bar with pub food, tap beers, cocktails & multiple TVs, serving till 4 AM nightly.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7228,
        store_longitude: -73.9891,
        total_orders: 63.88,
        neighborhood: 'Bowery',
        keywords:
            'Flavorful, Salad, Tacos, Quaint, Trendy, Mediterranean, Rustic, Healthy, Polished, Fun',
        annual_dol: 224.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7684,
        store_longitude: -73.7485,
        total_orders: 225.79,
        neighborhood: 'Douglaston/Flushing/Little Neck',
        keywords:
            'Lasagna, Lobster, Pizza, Italian, Salad, Dessert, Wine, Upscale, Caesar Salad, Fantastic',
        annual_dol: 327.71,
        description:
            'Upscale destination serving Northern Italian cuisine & fine wines in an elegant, old-world setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8978,
        store_longitude: -73.867,
        total_orders: 16.21,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords: 'Tasty, Cozy, Friendly, Irish, Casual, Local, Asian, Excellent, Latin, Unique',
        annual_dol: 66.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6871,
        store_longitude: -73.8356,
        total_orders: 16.56,
        neighborhood: 'Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Nachos, Beer, Philly Cheesesteak, Sustainable, Local, Casual',
        annual_dol: 67.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7107,
        store_longitude: -73.9591,
        total_orders: 583.22,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Wine, Ale, Donut, Four Horsemen, Delicious, American, Funky, Perfect, Fresh, Great',
        annual_dol: 792.74,
        description:
            'A young crowd populates this pork-focused American eatery in an older Williamsburg neighborhood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5791,
        store_longitude: -74.1637,
        total_orders: 1597.89,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Lasagna, Pizza, Lobster, Steak, Vodka, Beer, Italian, Wine, Meatball, Ribeye Steak',
        annual_dol: 1716.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7081,
        store_longitude: -74.0052,
        total_orders: 883.4,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Wine, Salad, Shrimp Cocktail, Ale, Beer, Margarita, Homemade, Irish, Cozy, American',
        annual_dol: 1159.99,
        description:
            'A full menu of American eats & plenty of beer is on tap at this Irish-style tavern.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8039,
        store_longitude: -73.9324,
        total_orders: 1637.34,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Sushi, Lobster, Salad, Lobster Tail, Dessert, Tasty, Spicy, Japanese, Chilean, Delicious',
        annual_dol: 1690.16,
        description:
            'Japanese sushi specialist with a sleek interior & a 2-for-1 drink deal available every evening.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.9776,
        total_orders: 937.94,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Hamburger, Steak, Cheesesteak, Salad, French Toast, French, American, Omelette, Fine Quality',
        annual_dol: 1000.29,
        description:
            'This casual American eatery, known for its burgers, also serves breakfast, Tex-Mex fare & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7495,
        store_longitude: -73.9828,
        total_orders: 1592.63,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Steak, Hotdog, Salad, Beer, Ale, Irish, Vintage, Spacious, American, Eclectic',
        annual_dol: 6390.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.711,
        store_longitude: -73.9649,
        total_orders: 815.03,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Beer, Romantic, Spacious, Friendly, Classic, Fun, Cool, Great, Casual, Incredible',
        annual_dol: 850.37,
        description:
            'No-frills barroom with cheap drinks, exposed-brick walls, a pool table & a spacious patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -73.9843,
        total_orders: 74.58,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Burger, Beer, Nachos, American, Friendly, Innovative, Casual, Great',
        annual_dol: 304.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6854,
        store_longitude: -73.773,
        total_orders: 72.91,
        neighborhood: 'Jamaica/Addisleigh Park/St. Albans',
        keywords:
            'Lobster, Jamaican, Caribbean, Beer, Rum, Friendly, Authentic, Excellent, Local, Tradition',
        annual_dol: 274.72,
        description: '"Restaurant and Lounge"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7439,
        store_longitude: -73.985,
        total_orders: 94.69,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Sushi, Steak, Japanese, Chinese, Korean, Asian, Fusion, Local, Casual, Rooftop',
        annual_dol: 387.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7425,
        store_longitude: -73.953,
        total_orders: 200.36,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Hamburger, Salad, Bbq, Spicy, American, Polished, Casual, Local, Perfect, Bloody Mary',
        annual_dol: 234.09,
        description:
            'Seasonal American dishes & local beers served in an industrial, urban-chic restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9636,
        total_orders: 3144.22,
        neighborhood: 'Lenox Hill',
        keywords:
            'Burger, Hamburger, Mexican, Salad, Beer, Vodka, American, Brazilian, Fun, Friendly',
        annual_dol: 4039.25,
        description:
            'Dating back to 1937, this dive bar is in a new space with its original, restored neon sign & booths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6968,
        store_longitude: -73.9352,
        total_orders: 467.14,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Hotel, Vodka, Beer, Spectacular, Fantastic, Smooth',
        annual_dol: 1345.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5228,
        store_longitude: -74.2348,
        total_orders: 21.78,
        neighborhood: 'Charleston',
        keywords: 'Dessert, Pizza, Savory, Upscale, Mexican, Italian, Delectable, Casual',
        annual_dol: 125.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -73.9863,
        total_orders: 306.71,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Wine, Legendary, Fun, Great',
        annual_dol: 313.22,
        description:
            'Outpost of a festive saloon chain with female bartenders, bartop dancing & body shots.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7306,
        store_longitude: -73.986,
        total_orders: 152.95,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Salad, Bread Pudding, Dessert, Italian, Tasty, Champagne, Mediterranean, Relaxed, Friendly',
        annual_dol: 156.19,
        description:
            'Casual Mediterranean-Italian dining in a low-key setting with sidewalk seating in summer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5978,
        store_longitude: -74.0834,
        total_orders: 255.45,
        neighborhood: 'Arrochar',
        keywords:
            'Italian, Delicious, Upscale, Mediterranean, Turkish, Sweet, Friendly, Hurricane, Filet Mignon, Authentic',
        annual_dol: 347.74,
        description:
            'Upscale seafood-focused Italian fare & opulent, palazzo-inspired decor in a tiny strip mall.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.8676,
        total_orders: 1150.04,
        neighborhood: 'Middle Village/Flushing/Rego Park',
        keywords: 'Burger, Sushi, Salad, Wine, Beer, Mediterranean, French, Fresh, Casual, Great',
        annual_dol: 1228.18,
        description:
            'Family-run seafood standby offering daily fresh-caught-fish specials plus sushi & a raw bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.9446,
        total_orders: 1333.4,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Coffee, Dessert, Turkish, Mediterranean, American, Contemporary, Polished, Casual, Hummus, Gyro',
        annual_dol: 1580.48,
        description:
            'Stylish, contemporary restaurant & lounge offering Turkish cuisine, specialty cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.9723,
        total_orders: 728.75,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Italian, Trendy, Cozy, Polished, Homemade, Authentic, Traditional, Classic, Excellent',
        annual_dol: 760.35,
        description:
            'Intimate, low-key restaurant offering traditional Italian dishes for lunch, dinner & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8694,
        store_longitude: -73.9033,
        total_orders: 330.27,
        neighborhood: 'Fordham Manor/Kingsbridge Heights/West Bronx',
        keywords: 'Burger, Steak, Tacos, Pizza, Salad, Italian, Beer, Chinese, Dominican, Wine',
        annual_dol: 631.97,
        description:
            'Rotisserie chicken, stewed meats & other Latin staples are the draw at this casual kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7664,
        store_longitude: -73.7723,
        total_orders: 92.6,
        neighborhood: 'Bayside',
        keywords:
            'Salad, Dessert, Mediterranean, Upscale, Delicious, Greek, Modern, Fresh, Greek Salad, Relaxed',
        annual_dol: 107.93,
        description:
            'Relaxed mom-&-pop eatery executing traditional Greek options including seafood specialties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7654,
        store_longitude: -73.9772,
        total_orders: 933.31,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Burger, Salad, Cajun, Shrimp Cocktail, Italian, Wine, Canadian, Upscale, Coffee',
        annual_dol: 960.5,
        description:
            'Classic American & Italian steak & seafood dishes served in a cozy, upscale atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -73.9889,
        total_orders: 432.19,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Steak, Mexican, Margarita, Spanish, Fresh, Awesome, Authentic, Amazing, Fine',
        annual_dol: 1655.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9914,
        total_orders: 4557.53,
        neighborhood: 'Bowery',
        keywords:
            'Wine, Whiskey, Scotch, Bourbon, Beer, Trendy, Intimate, Relaxed, Classy, Italian',
        annual_dol: 5495.03,
        description:
            'Innovative, creative cocktails & classic concoctions mixed up in a trendy industrial-style space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8275,
        store_longitude: -73.95,
        total_orders: 1272.68,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Sushi, Crab Cakes, Salad, Wine, Beer, Japanese, Sashimi, Dominican, Latin, Sweet',
        annual_dol: 1316.61,
        description: '"Latin Japanese Fusion"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6654,
        store_longitude: -73.9897,
        total_orders: 433.66,
        neighborhood: 'South Slope/Park Slope',
        keywords:
            'Burger, Salad, Pizza, French Toast, Sidecar, French, Thai, American, Gumbo, Asian',
        annual_dol: 532.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -73.956,
        total_orders: 765.66,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Beer, Seasonal, Local',
        annual_dol: 798.86,
        description:
            '"Gamehaus New York is El Grupo SN’s newest two floor indoor/outdoor sports bar and multi-functional venue nestled in the heart of Long Island City. Gamehaus features a dozen large-screen TVs and the largest LED video wall in LIC for your favorite live sports and pay per view events. Come play popular arcade and table games while enjoying our wide variety of seasonal craft and local beers, cocktails, mocktails and comfort food favorites."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -73.9892,
        total_orders: 579.73,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Vegetarian, Italian, Meatball, Dessert, Wine, Homemade, Modern, Bright, Casual',
        annual_dol: 592.03,
        description:
            'Italian eatery with Roman dishes like homemade pastas, wine & a full bar in a modern setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5969,
        store_longitude: -74.0851,
        total_orders: 259.88,
        neighborhood: 'Arrochar',
        keywords:
            'Pizza, Salad, Italian, Shrimp Cocktail, Delicious, Warm, Comfortable, Fresh, Casual, Wonderful',
        annual_dol: 265.4,
        description:
            'Down-to-earth Italian outfit serving traditional Sicilian dishes & specials, plus desserts & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5979,
        store_longitude: -74.073,
        total_orders: 174.34,
        neighborhood: 'Concord',
        keywords:
            'Sushi, Shrimp Cocktail, Hawaiian, Chinese, Japanese, Asian, Delicious, Local, Casual, Clean',
        annual_dol: 336.21,
        description:
            'Casual strip-mall joint offering familiar Chinese cooking, plus sushi & other Japanese dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.7765,
        total_orders: 30.48,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Vegetarian, Steak, Vegan, Cheesesteak, Salad, Roast Turkey, Italian, Nachos, Beer',
        annual_dol: 49.7,
        description:
            'Unpretentious outfit with a large menu of American & Mediterranean staples, plus breakfast.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7621,
        store_longitude: -73.9744,
        total_orders: 427.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Italian, Upscale, Martini, Dessert, Trendy, Beer, Elegant, Swiss, Classy',
        annual_dol: 446.08,
        description:
            'Upscale Italian restaurant & bar inside the namesake store offering fine dining, brunch & wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.9659,
        total_orders: 264.33,
        neighborhood: 'Lenox Hill',
        keywords:
            'Pizza, Vegetarian, Vegan, Chicken Parmigiana, Salad, Italian, Vodka, Wine, Margarita, Tasty',
        annual_dol: 269.94,
        description:
            'Informal pizza parlor offering brick-oven pies along with salads, pastas & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6886,
        store_longitude: -73.9896,
        total_orders: 499.25,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Burger, Hamburger, Hotdog, Beer, Wine, American, French, Delicious, Quaint, Crisp',
        annual_dol: 509.85,
        description:
            'Basic restaurant boasting artisanal-style burgers & hot dogs, plus a bar with signature cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9864,
        total_orders: 6302.88,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Burger, Shrimp Cocktail, Pizza, Cajun, Margarita, Beer, American, Local, Casual',
        annual_dol: 6462.98,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7393,
        store_longitude: -73.9867,
        total_orders: 2055.08,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Coffee, Beer, German, Eclectic, Warm, Welcoming, Modern, Seasonal, Inspiring',
        annual_dol: 2658.68,
        description:
            "Fotografiska New York is a branch of the Swedish photography museum Fotografiska in Gramercy Park, Manhattan, New York City. The museum's home is the Church Missions House, a six-story, 45,000-square-foot Renaissance Revival landmark. It opened in December 2019.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6442,
        store_longitude: -73.6392,
        total_orders: 201.61,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Sushi, Cajun, Lobster Bisque, Tasty, Japanese, Ramen, Delicious, Fried Shrimp',
        annual_dol: 277.27,
        description:
            '"The Angle - Hibachi Seafood Ramen Bar offers delicious dining and takeout to Oceanside, NY. The Angle - Hibachi Seafood Bar is a cornerstone in the Oceanside community and has been recognized for its outstanding Cajun seafood, hibachi cooking and teppanyaki steak cuisine, excellent service and friendly staff. Our restaurant is known for its modern interpretation of classic dishes and its insistence on only using high quality fresh ingredients. We accept all kinds of banquet, party and catering booking services。"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7414,
        store_longitude: -73.9929,
        total_orders: 5971.51,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Dessert, French, Delicious, Authentic, Traditional, Casual, Hip, Local, Delectable, Lit',
        annual_dol: 6098.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7431,
        store_longitude: -73.9908,
        total_orders: 477.25,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Burger, Gluten Free, Beer, Organic, Polished, Perfect, Premium, Modern, Casual, Rare',
        annual_dol: 1827.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.685,
        store_longitude: -73.9914,
        total_orders: 291.38,
        neighborhood: 'Boerum Hill/Carroll Gardens/Brooklyn Heights',
        keywords:
            'Pizza, Italian, Wine, Beer, Delicious, Mediterranean, Refined, Relaxed, Welcoming, Artisanal',
        annual_dol: 445.89,
        description:
            'Refined Italian spot with an old-world vibe, featuring wood-fired pizza, happy hour & a wine cellar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7005,
        store_longitude: -73.9884,
        total_orders: 53.66,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Vegan, Vegetarian, Tequila, Mexican, Salad, Beer, Margarita, Chinese, Trendy, Spicy',
        annual_dol: 219.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.9908,
        total_orders: 455.07,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Beer, Wine, Delicious, Funky, Eclectic, Vibrant, Cozy, Lively, Chinese, Refreshing',
        annual_dol: 637.52,
        description:
            'Laid-back haunt serving an eclectic menu of Asian-inspired plates plus craft cocktails, beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7715,
        store_longitude: -73.9819,
        total_orders: 2921.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lasagna, Lobster, Pizza, Chocolate Mousse, Salad, Italian, Dessert, Martini, Vodka, Meatball',
        annual_dol: 3037.88,
        description:
            'Sophisticated, wood-paneled institution with a patio for thin-crust pizzas & classic Italian mains.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9773,
        total_orders: 13680.7,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Hotel, Luxurious, French Toast, Coffee, Elegant, French, Rich, Organic, Premium',
        annual_dol: 14417.49,
        description:
            'Polished rooms with stylish, French-inspired decor offer free Wi-Fi, flat-screens, wireless sound systems and minibars. Suites add separate living areas. Upgraded suites include free breakfast and drinks.A palatial restaurant serves modern French cuisine. There’s also an elegant bar with a vaulted ceiling. Afternoon tea is available. Other amenities include a spa, a gym and an indoor pool, as well as a business center, event space and chauffeured car service.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9761,
        total_orders: 2373.32,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Modern, Contemporary, American, Burger, Salad, French, Casual, Seasonal, Rooftop, Fine',
        annual_dol: 9701.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -73.9935,
        total_orders: 1417.84,
        neighborhood: 'Bowery',
        keywords:
            'Wine, Tequila, Hotel, Beer, Classy, Homey, Intimate, Wonderful, Great, Incredible',
        annual_dol: 1479.32,
        description:
            'The Bowery Ballroom is a New York City live-music venue located at 6 Delancey Street in the neighborhood of Bowery in Manhattan. The Bowery Ballroom holds something of a cult status among musicians as well as audiences. Rolling Stone magazine has awarded it #1 Best Club in America.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.9895,
        total_orders: 7884.89,
        neighborhood: 'Lower East Side',
        keywords: 'Whiskey, Wine, Beer, Scotch, Martini, Bourbon, Coffee, Irish, Delicious, Sleek',
        annual_dol: 8138.2,
        description:
            'Customers can sip pints at a long copper bar & play pool at this Irish pub with a downstairs lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.993,
        total_orders: 3280.3,
        neighborhood: 'Bowery',
        keywords: 'Beer, German, Whiskey, Ale, Martini, French, Dessert, Crisp, Cointreau, Sangria',
        annual_dol: 3788.17,
        description:
            'Lively tavern featuring German brews & cuisine such as bratwurst & schnitzel, plus an outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 610.93,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Flavorful, Thai, Steak, Wine, Elegant, Spicy, Delicious, Green Curry, Traditional',
        annual_dol: 1057.25,
        description:
            'Manhattan outpost of a Queens staple offering spicy curries, noodles & other traditional Thai eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6819,
        store_longitude: -73.986,
        total_orders: 1402.88,
        neighborhood: 'Gowanus',
        keywords: 'Delicious, Bbq, Healthy, Perfect, Classic, Premium, Great, Fine, Western',
        annual_dol: 5281.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5455,
        store_longitude: -74.16,
        total_orders: 383.01,
        neighborhood: 'Eltingville',
        keywords:
            'Lobster, Sushi, Chinese, Delicious, Fried Rice, Turkish, Asian, Polished, Spacious, Classy',
        annual_dol: 392.73,
        description:
            'Everyday Chinese chow & dim sum in spacious surroundings with a seating capacity of 800.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5847,
        store_longitude: -73.8169,
        total_orders: 274.16,
        neighborhood: 'Far Rockaway/Rockaway Beach',
        keywords:
            'Beer, Wine, Hamburger, Irish, Iconic, Awesome, Classic, Comfortable, Great, Fresh',
        annual_dol: 497.02,
        description:
            "\"Iconic Irish Pub in Rockaway Beach serving the BEST Piña Coladas in the universe. For the past 80 years, Connolly's has been more than a bar, it's a community. It's a place where friends and family have gathered to make memories and cherish the history and culture of our beloved beach town. We welcome ALL to come enjoy a drink with us and get a sense of why Rockaway Beach is the greatest place on earth.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.735,
        store_longitude: -73.9999,
        total_orders: 433.87,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Warm, Rich, Cozy, Fun, Italian, Sweet, Vibrant, French Toast, Classy, Homemade',
        annual_dol: 709.02,
        description:
            '"A neighborhood restaurant in the West Village of Manhattan by Chef Christian Rowan"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.713,
        store_longitude: -73.958,
        total_orders: 3285.36,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Casual, Great',
        annual_dol: 3355.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7125,
        store_longitude: -73.9489,
        total_orders: 740.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Vegetarian, Gluten Free, Delicious, Lebanese, Inviting, Friendly, Mediterranean, Fun, Moroccan',
        annual_dol: 1810.77,
        description:
            'Bright Middle Eastern restaurant featuring classic sharing plates, mains & desserts, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7276,
        store_longitude: -73.9851,
        total_orders: 4785.16,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Lobster, Lobster Roll, Steak, Wine, Beer, Scotch, Margarita, Warm, Trendy, Cozy',
        annual_dol: 4886.7,
        description:
            'By the Wayland team, this tile-floored space offers a raw bar, sandwiches & seasonal cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.9839,
        total_orders: 990.4,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Beer, Tomato Soup, Salad, Bourbon, Trendy, Rustic, Fun, American, Awesome',
        annual_dol: 1279.38,
        description:
            'This cozy, rustic watering hole features creative takes on pub grub, craft beer on tap & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5883,
        store_longitude: -73.6599,
        total_orders: 314.71,
        neighborhood: '',
        keywords:
            'Margarita, Sushi, Mexican, Beer, Coffee, Cuban, Tasty, Italian, American, Sangria',
        annual_dol: 395.57,
        description:
            '"The best in Mexican Cuisine & exotic drinks. With a name like Margarita\'s Cafe, you have to have great margaritas and we do. We offer live Mariachi bands several days a week. Besides our great lunch and dinner menus, we offer in house catering for special events or just because. From the traditional to new Mexican inspired dishes, Margarita\'s Cafe is the place to bring family, friends and acquaintances."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.9813,
        total_orders: 39.04,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Wine, Beer, Coffee, Trendy, Delicious, Lively, American, Cool, Great, Creative',
        annual_dol: 159.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9273,
        total_orders: 638.43,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Whiskey, Beer, Irish, Fun, Lively, Friendly, Classy, American, Perfect, Excellent',
        annual_dol: 695.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.711,
        store_longitude: -73.9677,
        total_orders: 63.28,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Tequila, Tacos, Mexican, Margarita, Premium, Intimate, Friendly, Authentic, Classic, Lively',
        annual_dol: 258.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7484,
        store_longitude: -73.9392,
        total_orders: 116.82,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Steak, Salad, Nachos, Beer, Peruvian, Chinese, American, Seasonal, Irish',
        annual_dol: 477.5,
        description:
            '"A seasonal, market driven restaurant by Chef Dan Kluger of Loring Place with menu inspired by American diners and simple comfort food opening in the heart of Long Island City, Winter 2020"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6847,
        store_longitude: -74.0026,
        total_orders: 178.84,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Pizza, Italian, Wine, Dessert, Delicious, Homemade, Cozy, Modern, Comfortable, Seasonal',
        annual_dol: 213.98,
        description:
            '"A Modern Italian Eatery, a neighborhood friendly comfortable atmosphere. Delicious food, craft cocktails, craft beer, and great wine list! Brick oven cooking and homemade pizzas and pastas."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.642,
        store_longitude: -73.933,
        total_orders: 74.12,
        neighborhood: 'Little Caribbean/East Flatbush',
        keywords:
            'Dessert, Delicious, Shrimp Cocktail, Karaoke, Stylish, Steak, Salad, Caribbean, Vivid, Quaint',
        annual_dol: 117.27,
        description:
            'Relaxed locale serving Caribbean-inspired New American cuisine, plus brunch & late-night drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5887,
        store_longitude: -74.0952,
        total_orders: 749.25,
        neighborhood: 'Dongan Hills',
        keywords: 'Pizza, Beer, Wine, Delicious, Perfect, Classic, Great, Casual',
        annual_dol: 765.15,
        description:
            'Family-run neighborhood pub with photos on the wall serving a range of pizza & brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8011,
        store_longitude: -73.9676,
        total_orders: 1071.25,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Cuban, Delicious, Rum, Spanish, Spacious, Fun, Colorful, Perfect, Fresh, American',
        annual_dol: 1522.59,
        description:
            'Nuevo Latino food, including a festive brunch, served in a colorful space in the Excelsior Hotel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8864,
        store_longitude: -73.9099,
        total_orders: 812.84,
        neighborhood: 'Riverdale/West Bronx',
        keywords:
            'Tacos, Steak, Mexican, Salad, Shrimp Cocktail, Savory, Fajitas, Enchiladas, Spanish, Latin',
        annual_dol: 848.08,
        description:
            'Narrow, relaxed restaurant offering homestyle Mexican dishes, along with margaritas & imported beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7537,
        store_longitude: -73.9784,
        total_orders: 164.23,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Upscale, Hotel, Traditional, Classic, Great, Modern, Casual',
        annual_dol: 503.16,
        description:
            'Swanky cocktail bar at the base of an office tower serving craft libations plus elevated bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9668,
        total_orders: 411.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Spicy, Delicious, Chinese, Upscale, Savory, Asian, Fun, Sweet, Amazing, Authentic',
        annual_dol: 428.81,
        description:
            'Bright, white-tablecloth Sichuan restaurant offering a wide-ranging menu & a small bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7598,
        store_longitude: -73.976,
        total_orders: 1449.5,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Dessert, Glamorous, Elegant, Sushi, Delicious, Shrimp Cocktail, French, Romantic, Coffee, Hotel',
        annual_dol: 1512.35,
        description:
            'Longtime restaurant serving time-honored French delicacies in a flower-arrangement-filled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.691,
        store_longitude: -73.9051,
        total_orders: 77.26,
        neighborhood: 'Bushwick',
        keywords:
            'Beer, Karaoke, Margarita, Funky, Fancy, Seasonal, Friendly, Urban, Awesome, Classic',
        annual_dol: 315.82,
        description: '"Tiny cocktail space for community and creativity."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6284,
        store_longitude: -74.0289,
        total_orders: 569.42,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Whiskey, Wine, Irish, Classic, American, Friendly, Great, Casual',
        annual_dol: 581.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6172,
        store_longitude: -74.0308,
        total_orders: 705.03,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Sushi, Vegetarian, Wine, Japanese, Spicy, Trendy, French, Opulent, Impeccable, Classy',
        annual_dol: 741.19,
        description:
            'Buzzing eatery & lounge offering Japanese dishes with a French twist, plus cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9704,
        total_orders: 241.2,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hamburger, Upscale, Comfortable, American, Friendly, Casual, Local, Great',
        annual_dol: 985.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7572,
        store_longitude: -73.9679,
        total_orders: 2550.96,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Sushi, Japanese, Delicious, French, Cozy, Homemade, Authentic, Shabu-Shabu',
        annual_dol: 2781.92,
        description:
            "This minimalist Japanese eatery features chef's tasting menus, hot pot, sushi & seasonal entrees.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7836,
        store_longitude: -73.9507,
        total_orders: 833.65,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Hamburger, Salad, Cajun, Gluten Free, Beer, Shrimp Scampi, Delicious, Mediterranean, Irish',
        annual_dol: 1021.18,
        description:
            '"O’Flanagan’s Bar and Grill is the Upper East Side’s friendliest neighborhood pub serving a wide variety of fantastic food! With a full service menu, we offer traditional Irish fare, including Sheperd’s Pie and an Irish Breakfast, as well as our famous Flanny’s Wings. We also serve American favorites including a Bacon Bleu Burger, New York Sirloin, and a Bottomless Brunch Menu on weekends! Let us host your special event, private party, family gathering, dinner party, or corporate event in our accommodating event space, always available for rentals."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6933,
        store_longitude: -73.9705,
        total_orders: 411.98,
        neighborhood: 'Fort Greene/Clinton Hill',
        keywords:
            'Wine, Italian, Flavorful, Chinese, Charming, Modern, Natural, Perfect, Casual, Traditional',
        annual_dol: 458.37,
        description:
            'Modern Italian tavern with a charming patio serving traditional handmade pastas, cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6901,
        store_longitude: -73.9826,
        total_orders: 1291.97,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Chinese, Tasty, Beer, Spicy, Stylish, Spacious, Authentic, Dan Dan, Casual, Great',
        annual_dol: 1347.99,
        description:
            'Spicy Sichuan dishes like dan dan noodles are heat-rated by number at this informal restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6907,
        store_longitude: -73.9956,
        total_orders: 54.6,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Sushi, Wine, Romantic, Crisp, Turkish, Outstanding, Wonderful, Great, Fine, Heritage',
        annual_dol: 223.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9816,
        total_orders: 1084.69,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Peruvian, Wine, Japanese, Upscale, Spicy, Sashimi, Elegant, Sleek',
        annual_dol: 1182.18,
        description:
            'Upscale setting for Nikkei cuisine – Japanese-Peruvian offerings including ceviche & sushi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5144,
        store_longitude: -74.2359,
        total_orders: 42.22,
        neighborhood: 'Tottenville',
        keywords: 'Dessert, Awesome, Friendly, Great, Fusion, Casual, Local, Unique',
        annual_dol: 56.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7101,
        store_longitude: -74.014,
        total_orders: 4230.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Vegetarian, Pizza, Cajun, Italian, Roast Beef, Irish, French Onion Soup, French',
        annual_dol: 5202.51,
        description:
            'Both locals & visitors come for cold pints plus burgers, wings & bar food in a neighborhood spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7255,
        store_longitude: -73.9926,
        total_orders: 4932.62,
        neighborhood: 'NoHo',
        keywords:
            'Pizza, Wine, Rich, Beer, Delicious, Margarita, Italian, Elegant, Spectacular, Eclectic',
        annual_dol: 5037.28,
        description:
            'This dimly-lit bar with a basement dance floor specializes in craft beer and wines by the glass.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9947,
        total_orders: 229.93,
        neighborhood: 'Nolita',
        keywords:
            'Coffee, Trendy, Warm, Refined, Friendly, American, Bright, Australian, Urban, Casual',
        annual_dol: 866.31,
        description:
            'Health-focused & progressive all-day dining room in a trendy space designed by Tali Roth Designs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5868,
        store_longitude: -73.7141,
        total_orders: 117.62,
        neighborhood: '',
        keywords: 'Wine, Beer, Fun, Clean, Organic, Terrific',
        annual_dol: 159.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -73.9921,
        total_orders: 1796.62,
        neighborhood: 'NoHo',
        keywords: 'Wine, Beer, Hotel, Cozy, Modern, American, Casual, Awesome, Fine, Diverse',
        annual_dol: 1834.74,
        description:
            "Joe's Pub, one of the six performance spaces within The Public Theater, is a music venue and restaurant that hosts live performances across genres and arts, ranging from cabaret to modern dance to world music. It is located at 425 Lafayette Street near Astor Place in Manhattan, New York City.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -73.9859,
        total_orders: 5054.79,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Whiskey, Beer, Salad, Karaoke, Breakfast Burrito, Hotel, Irish, Vibrant, Triple Sec',
        annual_dol: 6035.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7375,
        store_longitude: -73.8775,
        total_orders: 108.15,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Beer, Wine, Friendly, French, Caribbean, Casual, American, Great',
        annual_dol: 132.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5885,
        store_longitude: -74.09,
        total_orders: 265.73,
        neighborhood: 'Dongan Hills',
        keywords:
            'Steak, Vegetarian, Mexican, Tequila, Margarita, Tasty, Italian, American, Delicious, Casual',
        annual_dol: 715.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.583,
        store_longitude: -74.0965,
        total_orders: 210.75,
        neighborhood: 'Midland Beach',
        keywords:
            'Garlic Bread, Cheesecake, Shrimp Cocktail, Italian, Delicious, Upscale, Casual, Bright, Traditional, Local',
        annual_dol: 219.89,
        description:
            'Big & bright upscale restaurant with a full bar dishing up traditional Italian cooking.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.8278,
        total_orders: 16952.07,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Burger, Steak, Tasty, Comfortable, Fun, Fancy, Cool, Casual, American',
        annual_dol: 24773.72,
        description:
            'Comfortable restaurant & bar specializing in oysters & cocktails, plus burgers with fries.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7511,
        store_longitude: -73.9727,
        total_orders: 1398.59,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Gluten Free, Beer, Japanese, Ramen, Warm, Asian, Gracious, Prosecco, Casual',
        annual_dol: 1474.35,
        description:
            'Kyoto-style handmade soba & yakitori, with seats at the bar to watch the pros work.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9768,
        total_orders: 5529.14,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hotel, Upscale, Stylish, Elegant, Coffee, Vintage, Classic, Homey, Rooftop, Contemporary',
        annual_dol: 19410.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8274,
        store_longitude: -73.9501,
        total_orders: 270.87,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Vegan, Beer, Bourbon, Roast Beef, Seasonal, American, Irish, Healthy, Handcrafted, Casual',
        annual_dol: 1140.05,
        description:
            '"A farm-to-bar neighborhood corner featuring a seasonal menu of handcrafted cocktails and a curated selection of local craft beer."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9684,
        total_orders: 369.22,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Garlic Bread, Lobster, Italian, Wine, Beer, Upscale, Homemade, Homey, Excellent, Great',
        annual_dol: 624.57,
        description:
            'Large portions of Italian fish & pasta dishes in a homey space with patio dining.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6874,
        store_longitude: -73.9061,
        total_orders: 63.68,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Cozy, Casual, Cool, Creative',
        annual_dol: 163.81,
        description:
            'Pub grub, cocktails & more served at this straightforward venue with live music & a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6534,
        store_longitude: -73.6262,
        total_orders: 987.66,
        neighborhood: '',
        keywords: 'Italian, Pizza, Salad, Wine, Delicious, Awesome, Local, Fine',
        annual_dol: 1014.5,
        description:
            'No-nonsense but popular Italian trattoria serving up a large menu of family-style platters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7427,
        store_longitude: -73.992,
        total_orders: 1082.21,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Burger, Steak, Salad, Wine, Beer, Spicy, Nachos, Bbq, Irish, Sweet',
        annual_dol: 4277.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7405,
        store_longitude: -73.9908,
        total_orders: 344.64,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Mexican, Delicious, Greek, Mediterranean, Hotel, Rustic, Amazing, Local, Great',
        annual_dol: 1408.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6896,
        store_longitude: -73.9695,
        total_orders: 714.43,
        neighborhood: 'Clinton Hill',
        keywords:
            'Wine, Beer, Italian, Organic, Mediterranean, American, Sustainable, Quaint, Homey, Classy',
        annual_dol: 778.61,
        description:
            'Hip, vintage-chic neighborhood spot preparing locally sourced New American small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9941,
        total_orders: 234.52,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Beer, Whiskey, Cozy, Friendly, Perfect, Tex-Mex, Awesome, American, Great, Casual',
        annual_dol: 382.41,
        description:
            'This casual joint specializes in a rotating list of American draft microbrews plus hearty pub fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8467,
        store_longitude: -73.636,
        total_orders: 206.08,
        neighborhood: '',
        keywords:
            'Vegan, Burger, Dairy Free, Mexican, Whiskey, Beer, Pizza, Vodka, Gluten Free, Coffee',
        annual_dol: 210.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9862,
        total_orders: 2151.42,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Burger, Beer, Korean, Wine, Karaoke, Fried Chicken, Juicy, Vintage, Ramen, Fried Rice',
        annual_dol: 2359.28,
        description:
            'Korean fried chicken, sides & beer towers in a buzzy space with a DJ spinning vintage vinyl.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7687,
        store_longitude: -73.9555,
        total_orders: 31.8,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Gluten Free, Wine, Dessert, Chinese, Beer, Spicy, Elegant, Comfortable, Asian',
        annual_dol: 53.86,
        description:
            'Cantonese joint whose dim sum provides a Chinatown-style dining experience on the Upper East Side.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7253,
        store_longitude: -73.9515,
        total_orders: 10.91,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Hamburger, Kebab, Salad, Italian, Beer, Margarita, Hungarian, Polish, Delicious',
        annual_dol: 44.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6451,
        store_longitude: -73.7811,
        total_orders: 2985.61,
        neighborhood: 'Jamaica',
        keywords:
            'American, Wine, Czech, Hotel, Spacious, Caribbean, Premium, Global, Modern, Comfortable',
        annual_dol: 3048.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5296,
        store_longitude: -74.2303,
        total_orders: 176,
        neighborhood: 'Charleston',
        keywords:
            'Pizza, Italian, Salad, Meatball, Delicious, Homemade, Fresh, Casual, Local, Great',
        annual_dol: 179.74,
        description:
            '"Named after their grandmother, Millie’s is the brainchild of two brothers seeking to share their passion for Italian food and celebration of the Italian culture with their local community. Millie’s of Staten Island will be a neighborhood destination joining the original Morristown, NJ location. Millie’s of Staten Island’s features include a custom, 22-seat bar and three garage doors that open to 2,500 square feet of outdoor dining space. Millie’s serves delicious, coal- and Neapolitan wood-fired artisan pizzas and meatballs based on Vince and Brandon’s grandmother Millie’s recipes. The coal oven burns over 650+ degrees and can produce pies in five minutes. The Neapolitan wood oven, hand built by Italy’s celebrated Ferrara family,"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9219,
        total_orders: 605.41,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Salad, Shrimp Cocktail, Greek, Greek Salad, Latin, Fusion, Casual, Great',
        annual_dol: 618.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -73.9881,
        total_orders: 2063.04,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Beer, Irish, Ale, Turkey Sandwich, Swiss, Friendly, Colorful, Great, Perfect',
        annual_dol: 2114.77,
        description:
            'Low-key Irish watering hole featuring billiards, darts & a simple outdoor seating area.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7345,
        store_longitude: -73.9937,
        total_orders: 1566.73,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Lobster, Salad, Shrimp Cocktail, Vodka, Wine, Margarita, Beer, Gin, Caesar Salad',
        annual_dol: 3652.45,
        description:
            'Swanky steakhouse with a raw bar boasting walls covered with photos of old-Hollywood stars.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -73.9878,
        total_orders: 313.27,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Salad, Wine, Vodka, Italian, French, Impeccable, American, Warm, Contemporary',
        annual_dol: 1277.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7286,
        store_longitude: -73.9923,
        total_orders: 102.83,
        neighborhood: 'NoHo',
        keywords:
            'Sushi, Salad, Korean, Delicious, Japanese, American, Authentic, Casual, Classic, Fantastic',
        annual_dol: 420.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7348,
        store_longitude: -73.9859,
        total_orders: 10.89,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Wine, Kebab, Georgian, Dessert, Tacos, Beer, Tasty, Seasonal, Glamorous, Creamy',
        annual_dol: 44.51,
        description:
            'Casual eatery with a locally sourced menu of seasonal Georgian dishes, plus wine & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8782,
        store_longitude: -73.9033,
        total_orders: 976.45,
        neighborhood: 'Kingsbridge Heights/West Bronx',
        keywords:
            'Burger, Beer, Martini, Cuban, Delicious, Cozy, Healthy, Friendly, Homemade, Dope',
        annual_dol: 1018.79,
        description:
            'Exposed beams & graffiti-covered walls give this bar with a modern American menu a cool edge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.8347,
        total_orders: 1530.58,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Wine, Hotel, Dessert, Chinese, Delicious, Beer, Asian, Bbq, Clean, Ample',
        annual_dol: 1846.37,
        description:
            '"纽约首家中式斗酒馆，主打中式调酒，6-28度，水果味、花朵酒、牛奶酒. 总有一款适合你."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.891,
        store_longitude: -73.8503,
        total_orders: 24.32,
        neighborhood: 'East Bronx/Wakefield',
        keywords: 'Caribbean, Casual, Great',
        annual_dol: 99.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8178,
        store_longitude: -73.9422,
        total_orders: 292.97,
        neighborhood: "Harlem/Strivers' Row",
        keywords:
            'Cosmopolitan, Elegant, Beer, Fun, Perfect, Friendly, Karaoke, Chic, Classic, Great',
        annual_dol: 359.47,
        description:
            'Chic nightspot offering specialty cocktails, wine, beer & snacks in modern surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7149,
        store_longitude: -74.0068,
        total_orders: 59.93,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Pizza, Italian, Wine, Salad, Vodka, Martini, Tasty, Delicious, Old Fashioned, Bruschetta',
        annual_dol: 66.68,
        description:
            'Simple Italian spot with a bar up front & a menu of pastas, wood-fired pizzas & other classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6333,
        store_longitude: -74.0269,
        total_orders: 108.23,
        neighborhood: 'Bay Ridge',
        keywords: 'Mexican, Relaxed, Charming, Asian, Latin, Casual, Premium, Modern, Sweet, Local',
        annual_dol: 132.47,
        description:
            'Relaxed standby with a charming patio for classic & modern Mexican dishes, plus original cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7598,
        store_longitude: -73.9838,
        total_orders: 3689.3,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Steak, Hotel, Vodka, Upscale, Beer, Salad, Coffee, Australian, Vintage, Rooftop',
        annual_dol: 16054.53,
        description:
            'walk away.Polished rooms and suites feature TVs, Wi-Fi and espresso machines, plus en suite bathrooms with rainfall showers. Some have balconies, free-standing tubs and/or separate living areas.Amenities include a steakhouse, a restaurant/bar and a rooftop cocktail lounge, plus a coffee kiosk, a gift shop, and meeting and event space. Breakfast, loaner guitars, and fitness and yoga classes are available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7808,
        store_longitude: -73.9494,
        total_orders: 1236.55,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Coffee, Wine, Beer, Tasty, Funky, Cozy, Friendly, Classy, American, Sweet',
        annual_dol: 1647.35,
        description:
            "Funky hangout that offers cappuccinos by day, craft beers by night & also has fondue & s'mores.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6387,
        store_longitude: -73.6423,
        total_orders: 720.88,
        neighborhood: '',
        keywords: 'Healthy, Perfect, Natural',
        annual_dol: 740.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7577,
        store_longitude: -73.9823,
        total_orders: 1317.76,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegetarian, Chinese, Indian, Upscale, Casual, Traditional, Spacious, Fancy, Authentic, Creative',
        annual_dol: 1453.49,
        description:
            'Spacious Indian eatery drawing theater-goers with fixed-priced pre-theater deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9837,
        total_orders: 501.01,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Beer, Whiskey, Friendly, Iconic, Excellent, Casual, Signature, Chill',
        annual_dol: 2073.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6813,
        store_longitude: -73.7114,
        total_orders: 13.97,
        neighborhood: '',
        keywords:
            'Burger, Steak, Cheesesteak, Roast Beef, Beer, Bbq, Wine, American, Caribbean, Classic',
        annual_dol: 57.1,
        description:
            'Lively neighborhood sports bar with pub grub, darts & football memorabilia on the walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -74.0047,
        total_orders: 1517.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Incredible, Rooftop, Modern, Perfect, Unique',
        annual_dol: 1550.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7694,
        store_longitude: -73.983,
        total_orders: 268.34,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Hamburger, Pizza, Vegetarian, Italian, Gluten Free, Meatball, Wine, Polish, Elegant, Comfortable',
        annual_dol: 1096.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.9975,
        total_orders: 143.44,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Vegan, Burger, Vegetarian, Lobster, Lobster Roll, Wine, Gluten Free, Coffee, Beer, Vietnamese',
        annual_dol: 418.82,
        description:
            'Cozy cafe with quirky decor offering French-influenced Vietnamese fare, plus cocktails & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9096,
        total_orders: 978.17,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Sushi, Wine, Tasty, Japanese, Thai, Fusion, Sweet, Asian, Fabulous, Chic',
        annual_dol: 1025.7,
        description:
            'A chic, modern eatery serving inventive sushi & Japanese dishes, plus lunch specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7762,
        store_longitude: -73.9152,
        total_orders: 162.31,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'Wine, Beer, Cool, Casual, American, Great',
        annual_dol: 806.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8044,
        store_longitude: -73.9476,
        total_orders: 134.91,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Pizza, Wine, Vegan, Salad, Vodka, Coffee, Italian, Delicious, Meatball, Warm',
        annual_dol: 300.87,
        description:
            'Stylish, relaxed Italian neighborhood cafe in Lower Harlem known for its desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7316,
        store_longitude: -73.6115,
        total_orders: 2767.15,
        neighborhood: '',
        keywords:
            'Hurricane, Steak, Chicken Wings, Salad, Burger, Coffee, Caribbean, Beer, American, Spicy',
        annual_dol: 4962.73,
        description:
            'Tropical-themed chain offering spiced-to-order wings with an array of sauces, pub grub & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -74.0059,
        total_orders: 2619.7,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Steak, Burger, Salad, American, Beer, Delicious, Hotel, French, Perfect, Clean',
        annual_dol: 2675.28,
        description:
            'Elevated American comfort food is served at this eatery, bar, boutique lounge & beer garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7299,
        store_longitude: -74.0103,
        total_orders: 4486.53,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Delicious, Fabulous, Memorable, Iconic, Awesome, Incredible, Fantastic, Premium, Wonderful, Great',
        annual_dol: 18339.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8392,
        store_longitude: -73.8593,
        total_orders: 430.99,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Cajun, Lobster, Flavorful, Lobster Bisque, French, Pinacolada, Fun, Authentic, Casual',
        annual_dol: 618.62,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6296,
        store_longitude: -74.1108,
        total_orders: 1737,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Nachos, Bbq, Turkish, Irish, French, Delicious, American, Homemade, Friendly',
        annual_dol: 1773.85,
        description:
            'Snug, old-fashioned pub known for simple pub grub, especially half-pound burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8384,
        store_longitude: -73.9413,
        total_orders: 207.7,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Steak, Burger, Mexican, Salad, Beer, Bbq, Cuban, Wine, Fried Rice, American',
        annual_dol: 537.95,
        description: '"Restaurant and Lounge"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7875,
        store_longitude: -73.9764,
        total_orders: 2058.79,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegetarian, Salad, Margarita, Vodka, Martini, Delicious, Spicy, Coffee, Funky, Romantic',
        annual_dol: 2075.7,
        description:
            'Bi-level eatery with Greek Island vibes, exposed beam wood ceilings, & seafood-forward menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8297,
        store_longitude: -73.7022,
        total_orders: 11.39,
        neighborhood: '',
        keywords: 'Shrimp Cocktail, Chinese, French, Premium, Great',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7022,
        store_longitude: -73.6608,
        total_orders: 46.83,
        neighborhood: '',
        keywords: 'Coffee, Wine, Italian, Beer, Gourmet, American, Mediterranean, Smooth, Casual',
        annual_dol: 191.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9171,
        total_orders: 68.04,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Kebab, Beer, Classic, Authentic, Spectacular, Fusion, Fantastic, Casual',
        annual_dol: 278.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6747,
        store_longitude: -73.9757,
        total_orders: 199.72,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hotdog, Pizza, Steak, Cheesesteak, Beer, Swiss, American, Sustainable, Indian',
        annual_dol: 391.56,
        description: '"Neighborhood Bar with a Pro-Wrestling Theme"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9538,
        total_orders: 707.86,
        neighborhood: 'Greenpoint',
        keywords: 'Wine, Beer, Gourmet, Stylish, Italian, Spacious, French, Lively, Fun, Robust',
        annual_dol: 2168.63,
        description:
            'Airy, stylish locale filled with plants, offering brunch fare, dinner plates & a robust wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7326,
        store_longitude: -73.9548,
        total_orders: 901.48,
        neighborhood: 'Greenpoint',
        keywords:
            'Sushi, Wine, Beer, Delicious, Yummy, Japanese, Sashimi, Friendly, Healthy, Asian',
        annual_dol: 929.55,
        description:
            'Understated Japanese fixture dishing up sushi amid dark wood furnishings & Japan-inspired flair.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6803,
        store_longitude: -73.9946,
        total_orders: 11727.27,
        neighborhood: 'Carroll Gardens',
        keywords: 'Whiskey, Wine, Beer, Vintage, Martini, Italian, Tasty, Classic, Friendly, Yummy',
        annual_dol: 11976.1,
        description:
            'Former Italian social club transformed into a speakeasylike bar serving classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6834,
        store_longitude: -73.9999,
        total_orders: 68.35,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Hotdog, Burger, Tequila, Mexican, Beer, Coffee, Margarita, Wine, Tex-Mex, Breakfast Burrito',
        annual_dol: 79.85,
        description:
            'Mexican dishes, bistro classics & cocktails served in a chill hangout with sidewalk tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8514,
        store_longitude: -73.9395,
        total_orders: 568.48,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Garlic Bread, Wine, Pizza, Italian, Rustic, Mediterranean, Casual, Southern, Classy, Spanish',
        annual_dol: 583.04,
        description:
            'Little hideaway for Southern Italian specialties & diverse wines served amid a warm, intimate vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -74.093,
        total_orders: 194.56,
        neighborhood: 'Midland Beach',
        keywords: 'Sushi, Chinese, Japanese, Spicy, Asian, Bbq, Yummy, Wonton Soup, Fusion, Local',
        annual_dol: 228.32,
        description:
            'Enduring, old-world fixture for fine Chinese & Japanese dining, plus a full bar & specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -73.9867,
        total_orders: 1821.42,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Margarita, Martini, Upscale, Spicy, Rustic, Cozy, Vintage, American',
        annual_dol: 1930.45,
        description:
            'Happening spot for local craft beer, wine, cocktails & a retro, old-timey atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5911,
        store_longitude: -73.9739,
        total_orders: 231.96,
        neighborhood: 'Gravesend',
        keywords:
            'Burger, Hamburger, Salad, Delicious, Swiss, Beer, Friendly, Casual, Classic, Unique',
        annual_dol: 296.59,
        description:
            'Pool hall & sports bar offering leagues, beer buckets & a casual menu of sandwiches & sides.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9729,
        total_orders: 308.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Wine, Upscale, Coffee, Beer, Trendy, Burger, Martini, Gin, Stylish',
        annual_dol: 1081.73,
        description:
            'The polished, contemporary studios feature flat-screen TVs and free Wi-Fi, plus minibars, wet bars and kitchenettes. Upgraded suites add separate living and sleeping areas, as well as terraces with city views.Free continental breakfast is served in a stylish lounge, along with light snacks throughout the day. Other amenities include an American-style bistro and a 24-hour fitness center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6792,
        store_longitude: -73.8956,
        total_orders: 556.93,
        neighborhood: 'Cypress Hills',
        keywords:
            'Delicious, Dominican, Rum, Caribbean, Sweet, Fried Calamari, Perfect, Latin, American, Fresh',
        annual_dol: 1604.13,
        description:
            'Upmarket Latin American cuisine served in a chill environment with flat-screen TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7604,
        store_longitude: -73.9654,
        total_orders: 770.55,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Rich, Beer, Italian, Elegant, Vintage, Outstanding, Great, Terrific, Intimate',
        annual_dol: 2705.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6326,
        store_longitude: -74.0271,
        total_orders: 605.85,
        neighborhood: 'Bay Ridge',
        keywords:
            'Vegetarian, Pizza, Italian, Tasty, Delicious, Cozy, Czech, Comfortable, Homemade, Friendly',
        annual_dol: 622.61,
        description:
            "Neighborhood Italian restaurant that's been serving up classic pastas & pizzas since 1953.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.9893,
        total_orders: 3443.86,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Sushi, Flavorful, Tasty, Spicy, Meatball, Japanese, Sashimi, Spanish, Friendly, Sweet',
        annual_dol: 4277.97,
        description:
            'Cool, low-key joint for Japanese fare such as sashimi, tempura & skewers, plus a long sake list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8598,
        store_longitude: -73.8429,
        total_orders: 218.63,
        neighborhood: 'Pelham Gardens/East Bronx',
        keywords:
            'Salad, Steak, Cajun, Italian, Wine, Martini, Beer, Bourbon, Mediterranean, Rustic',
        annual_dol: 893.7,
        description:
            '"Nestled in the beautiful Pelham Gardens area of the Bronx along Eastchester Road sits our uniquely designed restaurant Misto Restaurant and Bar. Misto, meaning “Mixed” in Italian, is a little slice of old Europe infused with a New York City vibe and feel. We invite you to come and enjoy an unforgettable evening of spectacular food, wine, and music. Enjoy a Bourbon or Martini at our chic, sexy bar and settle into our inviting and rustic dining area to indulge in a mixture of the finest of culinary delights. Executive Chef Michael Mazzei created a “Mixed,” Mediterranean-focused, fusion menu to match the feel of the restaurant."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9861,
        total_orders: 1464.92,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Italian, Salad, Coffee, Wine, Champagne, Prosecco, Classy, Southern, Casual, Authentic',
        annual_dol: 1506.67,
        description:
            'Hearty Southern Italian food served at a pre-theater-appropriate pace in an Old World setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.9872,
        total_orders: 1728.94,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Legendary, American, Fabulous, Vibrant, Premium, Rooftop, Latin, Cool, Local',
        annual_dol: 6069.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.996,
        total_orders: 488.26,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotel, Stylish, High-End, Spacious, Coffee, Premium, Rooftop, Amazing, French, Modern',
        annual_dol: 556.1,
        description:
            'Modern Art.Sleek rooms and suites with high ceilings have free Wi-Fi, oversized windows and minibars; some offer soaking tubs. Suites add separate living areas and floor-to-ceiling windows. A penthouse suite provides a dining area and a rooftop terrace. Room service is available.Amenities include a stylish restaurant featuring a locally sourced, seasonal menu, a buzzy rooftop lounge/bar, a spa and a fitness room. Meeting spaces are also available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9878,
        total_orders: 797.42,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Italian, Hotel, Southern, Intimate, Fun, Perfect, Great, American, Unique',
        annual_dol: 888.54,
        description:
            'The New Amsterdam Theatre is a Broadway theater on 214 West 42nd Street, at the southern end of Times Square, in the Theater District of Manhattan in New York City. One of the oldest surviving Broadway venues, the New Amsterdam was built from 1902 to 1903 to designs by Herts & Tallant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7245,
        store_longitude: -73.9953,
        total_orders: 499.96,
        neighborhood: 'Nolita',
        keywords: 'Italian, Dessert, Rich, Turkey Sandwich, American, Playful, Iconic, Casual',
        annual_dol: 521.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.8708,
        total_orders: 48.76,
        neighborhood: 'North Corona/Jackson Heights/Flushing',
        keywords: 'Mexican, Tacos, Casual, Delicious, Friendly, Premium, Authentic, Fresh, Classic',
        annual_dol: 54.33,
        description:
            '"Established in 1997, Puebla Seafood is a Mexican family owned business which first started as a small Bodega providing Mexican products for our neighbors in Jackson Heights,Queens. Puebla Seafood later turned its establishment into a restaurant to provide authentic and classic Mexican and Seafood cuisines to the public. We serve daily food specials. Give us a call and check out our offerings 718-672-3556 Puebla Seafood is where you will be best served and be a apart of the Mexican culture."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7498,
        store_longitude: -73.995,
        total_orders: 66.54,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Wine, Beer, Spicy, French, Irish, Nachos, Sweet, Friendly, Lively',
        annual_dol: 108.75,
        description:
            'Casual, dimly lit bar & hookah lounge offering special cocktails, bar bites & European fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -74.0039,
        total_orders: 83.38,
        neighborhood: 'Midtown Manhattan/Hudson Yards',
        keywords: 'Hotel, Legendary, Fancy, Fresh, Signature, Local',
        annual_dol: 340.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9876,
        total_orders: 21.65,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Pizza, Italian, Wine, Dessert, Beer, Delicious, Mediterranean, Friendly, Fabulous, Exceptional',
        annual_dol: 88.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7714,
        store_longitude: -73.9837,
        total_orders: 3661.62,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Tomato Soup, Salad, Wine, Shrimp Cocktail, Italian, American, Warm, Contemporary, Perfect, Spectacular',
        annual_dol: 3820.38,
        description:
            'Pre-curtain & intermission service with fine dining meals & a full bar under the chandelier.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7797,
        store_longitude: -73.9769,
        total_orders: 1336.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Vegetarian, Wine, Salad, Chicken Lollipop, Luxurious, Delicious, American, French, Spacious',
        annual_dol: 1663.34,
        description:
            'A modern American menu with Mediterranean influences is served in a sprawling, wood-accented space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7057,
        store_longitude: -74.0076,
        total_orders: 1702.43,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tequila, Burger, Vodka, Beer, Cajun, Margarita, Martini, Salad, Austrian, Irish',
        annual_dol: 1753.44,
        description:
            'Classic, homey drinking establishment with a 101-year-old interior bar imported from Ireland.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8675,
        store_longitude: -73.6306,
        total_orders: 855.54,
        neighborhood: '',
        keywords: 'Tequila, Mexican, Steak, Tasty, Pizza, Tex-Mex, Salad, Latin, American, Bright',
        annual_dol: 1103.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7456,
        store_longitude: -73.9059,
        total_orders: 466.19,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, Delicious, Warm, Ale, Coffee, Fun, Friendly, Amazing, Clean, Irish',
        annual_dol: 510.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.8529,
        total_orders: 101.72,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Lasagna, Wine, Tequila, Tequila Sunrise, Delicious, Vodka, Steak, Pizza, Italian, Whiskey',
        annual_dol: 142.5,
        description:
            'Homestyle Italian eats supplied in a cozy, local spot with French doors that open onto the street.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7202,
        store_longitude: -73.9987,
        total_orders: 1310.04,
        neighborhood: 'Little Italy',
        keywords:
            'Karaoke, Korean, Beer, Chinese, Quaint, Amazing, Intimate, Great, Perfect, Excellent',
        annual_dol: 1366.83,
        description:
            'Tiny karaoke bar & art gallery featuring a long song list & periodic acoustic-guitar accompaniment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7159,
        store_longitude: -73.9965,
        total_orders: 17549.16,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Sushi, Japanese, Asian, Local, Intimate, Sleek, Rare, Seasonal, Authentic, Cultural',
        annual_dol: 33856.79,
        description:
            "Intimate, wood-accented venue for curated omakase sushi meals served at a chef's counter.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.5525,
        total_orders: 1378.19,
        neighborhood: '',
        keywords: 'Sushi, Salad, Wine, Beer, Spicy, Chinese, Korean, Asian, Sweet, Seasonal',
        annual_dol: 4838.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7196,
        store_longitude: -73.9977,
        total_orders: 1824.82,
        neighborhood: 'Little Italy',
        keywords: 'Burger, Steak, Salad, Wine, Beer, Italian, Delicious, Elegant, Irish, American',
        annual_dol: 1867.29,
        description:
            'New American dishes, oysters & cocktails offered in an intimate, tavern-style atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7542,
        store_longitude: -73.9771,
        total_orders: 171.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Carrot Cake, Wine, Beer, Italian, French, Moroccan, Elegant, American, Classic, Casual',
        annual_dol: 764.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5671,
        store_longitude: -74.1129,
        total_orders: 11.82,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords: 'Mexican, Burger, Pizza, Spanish, Salad, Casual',
        annual_dol: 82.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7831,
        store_longitude: -73.9749,
        total_orders: 854.5,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Garlic Bread, Steak, Pizza, Vegan, Salad, Chicago-Style, Golfclub, Beer, Dessert',
        annual_dol: 891.55,
        description:
            'Family-friendly chain serving Chicago-style pizza along with pasta, steaks & burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.787,
        store_longitude: -73.978,
        total_orders: 320.59,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Steak, Kebab, Sushi, Vegan, Salad, Gluten Free, Beer, Whiskey Sour',
        annual_dol: 327.39,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6411,
        store_longitude: -74.0147,
        total_orders: 285.41,
        neighborhood: 'Sunset Park',
        keywords:
            'Vegetarian, Steak, Mexican, Flavorful, Margarita, Bbq, Delicious, Healthy, Enchiladas, Friendly',
        annual_dol: 1075.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6186,
        store_longitude: -73.9324,
        total_orders: 8.97,
        neighborhood: 'Marine Park/Flatlands',
        keywords:
            'Mexican, Steak, American, Contemporary, Modern, Casual, Local, Delicious, Friendly, Fresh',
        annual_dol: 36.66,
        description: '"Mexican restaurant & bar located in south Brooklyn."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7928,
        store_longitude: -73.9407,
        total_orders: 24.49,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Tacos, Mexican, Steak, Salad, Tasty, Nachos, Wine, Quesadillas, Spicy, Spanish',
        annual_dol: 92.28,
        description: '"Las Panteras Negras offers traditional Mexican food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8065,
        store_longitude: -73.9263,
        total_orders: 81.22,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Beer, Burger, Steak, Canadian, Vegan, Ale, Swiss, American, Cuban, Local',
        annual_dol: 110.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7078,
        store_longitude: -73.8693,
        total_orders: 254.84,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords: 'Pizza, Salad, Italian, Beer, Wine, Hawaiian, Spicy, Tex-Mex, Delicious, Bbq',
        annual_dol: 306.89,
        description:
            'Chain eatery serving up inventive pizzas, plus pastas & salads in a modern setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9656,
        total_orders: 955.67,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegan, Steak, Organic, French, Quaint, Elegant, Warm, Friendly, Farm-To-Table, Casual',
        annual_dol: 975.95,
        description:
            'Elegant townhouse restaurant by Jean-Georges Vongerichten with a seasonal French menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7733,
        store_longitude: -73.9645,
        total_orders: 521.82,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Salad, Dessert, Wine, Elegant, Meatball, Warm, Sophisticated, Modern, Perfect',
        annual_dol: 532.89,
        description:
            'Sophisticated Italian cuisine is served in an elegant modern setting perfect for people-watching.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7708,
        store_longitude: -73.954,
        total_orders: 968.97,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Irish, Bourbon, Cozy, Fancy, Urban, Dope, Local, Cool, Great, Casual',
        annual_dol: 1921.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8238,
        store_longitude: -73.9132,
        total_orders: 589.66,
        neighborhood: 'Melrose',
        keywords: 'Pizza, Italian, Vegetarian, Wine, Hotel, Cozy, Casual, Local, Great',
        annual_dol: 780.63,
        description:
            'Cozy spot resembling an old port tavern offering Italian fare, local draft beers & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.778,
        store_longitude: -73.9559,
        total_orders: 48.53,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Meatball, Italian, Coffee, Homey, Cozy, Warm, American, Quaint, Comfortable, Mediterranean',
        annual_dol: 52.01,
        description:
            'Laid-back venture turning out comfort food classics in a warm, brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7951,
        store_longitude: -73.6986,
        total_orders: 7.25,
        neighborhood: '',
        keywords:
            'Steak, Salad, Lebanese, Mediterranean, Spanish, Greek, Israeli, Ribeye Steak, Casual, Authentic',
        annual_dol: 29.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -73.9972,
        total_orders: 5.36,
        neighborhood: 'Little Italy',
        keywords: 'Wine, Pizza, Italian, Martini, Beer, Salad, Gin, Bellini, Awesome, Mimosa',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 1338.92,
        neighborhood: '',
        keywords: 'Steak, Burger, Rustic, Beer, Italian, American, Warm, Casual, Classic, Inviting',
        annual_dol: 1396.97,
        description:
            'American dishes with local, seasonal ingredients prepared in farmhouse-chic digs at Roosevelt Field.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -74.0053,
        total_orders: 445.79,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Mexican, Tequila, Beer, Salad, Margarita, Italian, American, Fajitas, Latin',
        annual_dol: 475.42,
        description:
            'Branch of a casual, hip mini-chain for classic Mexican eats & margaritas, plus happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.786,
        store_longitude: -73.9764,
        total_orders: 2034.31,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Peruvian, Shrimp Cocktail, Wine, Dessert, Flavorful, Chinese, Cuban, Spanish, Fried Rice',
        annual_dol: 2077.48,
        description:
            '"For over 30 years Flor de Mayo Restaurant has served our wonderful Upper West Side neighbors and the surrounding area our delightful combination of Peruvian and Chinese cuisine. As one of the few remaining Chino-Latino restaurants in NYC, Flor de Mayo provides selections crafted by our chef\'s skillful touch, seasoned with unique herbs and spices from both cultures. Accompany our flavorful dishes with fascinating signature cocktails from our fully-stocked bar and enjoy our sophisticated service and cozy atmosphere. Please come join us at Flor de Mayo and experience this wonder for yourself!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -73.9033,
        total_orders: 124.49,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Pizza, Italian, Mongolian, Coffee, Comfortable, Premium, Irresistible',
        annual_dol: 174.4,
        description:
            'Long-standing eatery featuring hearty plates of classic Italian cuisine in a comfortable atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7015,
        store_longitude: -73.9052,
        total_orders: 279.6,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Cozy, Delicious, Friendly, Intimate, Awesome, Casual, Great, Seasonal, Local, Fresh',
        annual_dol: 912.84,
        description:
            'Live music, DJs, craft beer, cocktails & pop-up dining in a neighborhood bar with a laid-back vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6435,
        store_longitude: -73.5395,
        total_orders: 190.32,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Lobster Tail, Salad, Italian, Delicious, Thai, American, Contemporary',
        annual_dol: 257.71,
        description:
            'Contemporary white-tablecloth restaurant & bar offering classic seafood & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.9204,
        total_orders: 469.6,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Sushi, Salad, Japanese, Chinese, Asian, Rooftop, Delicious, American, Modern, Fusion',
        annual_dol: 561.86,
        description:
            'Specialty sushi, Asian fusion fare & drinks served in modern digs with a rooftop patio & city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.983,
        total_orders: 6007.45,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Wine, Upscale, Luxurious, French, Legendary, Classy, Premium, Refined, Classic, Exceptional',
        annual_dol: 7696.73,
        description:
            "Elite French restaurant offers chef Eric Ripert's refined seafood, expert service & luxurious decor.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8142,
        store_longitude: -73.9598,
        total_orders: 45.88,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Pizza, Italian, Vegetarian, Wine, Coffee, Champagne, Meatball, Prosecco, Warm, Cozy',
        annual_dol: 187.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.809,
        store_longitude: -73.9593,
        total_orders: 103.76,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Kebab, Vegetarian, Shrimp Cocktail, Mediterranean, Greek, Delicious, Rustic, Upscale, Warm',
        annual_dol: 231.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9885,
        total_orders: 2645.02,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Wine, Beer, Hotel, Cozy, Rum, Intimate, Legendary, French, Classic',
        annual_dol: 2947.25,
        description:
            'Intimate venue presenting singer-songwriters & new artists for free or a nominal charge, plus a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.715,
        store_longitude: -73.9912,
        total_orders: 2799.27,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Burger, Coffee, Beer, Vodka, Upscale, Elegant, Banana Split, American, French, Retro',
        annual_dol: 2920.64,
        description:
            'State-of-the-art cinema with an upscale American eatery & two bars evokes old-time Hollywood haunts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7137,
        store_longitude: -73.996,
        total_orders: 966.66,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Wine, Chinese, Spicy, Peking Duck, Rich, Homemade, Juicy, Upscale, Yummy, Legendary',
        annual_dol: 1823.33,
        description:
            "A legendary Chinese eatery from the '80s is revived in a three-story space with decorous rooms.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.866,
        store_longitude: -73.9276,
        total_orders: 163.83,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Tacos, Sushi, Vegetarian, Pizza, Mexican, Gourmet, Margarita, Tasty, Tex-Mex, Italian',
        annual_dol: 365.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8539,
        store_longitude: -73.9306,
        total_orders: 1469.99,
        neighborhood: 'Fort George/Washington Heights',
        keywords: 'Vodka, Wine, Italian, Pizza, Martini, Mexican, Peruvian, Rum, Delicious, Latin',
        annual_dol: 1602.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8648,
        store_longitude: -73.9283,
        total_orders: 10.79,
        neighborhood: 'Fort George',
        keywords:
            'Peruvian, Margarita, Delicious, Cozy, Yummy, Sweet, Relaxed, Friendly, Laid-Back, Latin',
        annual_dol: 44.12,
        description:
            '"Latin American Cuisine with an emphasis on Peruvian Cuisine. Retazitos also features an elevated Cocktail Program in a relaxed laid back setting."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6325,
        store_longitude: -73.5816,
        total_orders: 56.83,
        neighborhood: '',
        keywords: 'Salad, Pizza, Shrimp Cocktail, Chinese, American, Casual, Local, Classic',
        annual_dol: 232.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6796,
        store_longitude: -73.8862,
        total_orders: 3.31,
        neighborhood: 'Cypress Hills',
        keywords: 'Elegant, Casual',
        annual_dol: 13.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6779,
        store_longitude: -73.9797,
        total_orders: 101.62,
        neighborhood: 'Park Slope',
        keywords:
            'Wine, Tacos, Vegan, French Toast, Beer, Vietnamese, Coffee, French, Upscale, Dessert',
        annual_dol: 165.7,
        description:
            'Casual, low-key restaurant with updated Vietnamese fare plus an ambitious drink menu & a back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.9508,
        total_orders: 994.36,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Burger, Pizza, Beer, Wine, Tasty, Coffee, Margarita, Irish, Spicy, Karaoke',
        annual_dol: 1015.46,
        description:
            '"Local neighborhood Irish pub in Williamsburg, Brooklyn on Grand Street off the corner of Union Avenue. Comfortable and cozy setting serving cocktails, wine, craft beer and comfort food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.73,
        store_longitude: -73.982,
        total_orders: 1558.36,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Vodka, Beer, Martini, Gin, Spicy, Hungarian, Legendary, Cozy, Classy',
        annual_dol: 1625.92,
        description:
            'This small East Village watering hole is best known for its fireplace & signature Rolo shots.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7139,
        store_longitude: -73.9526,
        total_orders: 2608.73,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Wine, Delicious, Spacious, Fun, Lively, Cool, Great, Aesthetic, Fresh',
        annual_dol: 2721.83,
        description:
            'Hard-rocking joint with jukebox tunes, old-school signs, big beers, pinball & outdoor grill grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.714,
        store_longitude: -73.9604,
        total_orders: 194.77,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Pizza, Salad, Wine, Italian, Beer, Savory, Delicious, Romantic, Relaxed, American',
        annual_dol: 248.13,
        description:
            'Notable handmade pastas, mozzarella & wood-oven pizzas are offered in a relaxed space with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.9507,
        total_orders: 105.38,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Tequila, Steak, Shrimp Cocktail, Pizza, Chinese, Spanish, Pinacolada, American, Latin, Asian',
        annual_dol: 287.49,
        description:
            'Spartan corner diner fixing up typical Chinese dishes & Spanish bites, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7108,
        store_longitude: -73.9514,
        total_orders: 568.7,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Hamburger, Mexican, Pizza, Beer, Ale, American, Dominican, French, Latin, Upscale',
        annual_dol: 716.03,
        description:
            'Cocktails, draft beer & pub grub in a laid-back hangout with large windows & exposed-brick walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6309,
        store_longitude: -74.1016,
        total_orders: 472.36,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Burger, Steak, Sushi, Chicken Parmigiana, Savory, Irish, Delicious, French Onion Soup, French, American',
        annual_dol: 498.02,
        description:
            'Relaxed, time-tested haunt for burgers, seafood & steak dishes, plus a bar with TVs showing sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9839,
        total_orders: 114.64,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tequila, Tacos, Mexican, Beer, Margarita, Flavorful, Vegan, Tasty, Dessert, Hotel',
        annual_dol: 145.98,
        description:
            'Contemporary Mexican hot spot turning out innovative tacos & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5756,
        store_longitude: -74.1695,
        total_orders: 368.94,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Vegetarian, Chinese, Italian, French, Asian, American, Exotic, Classic, Fine, Great',
        annual_dol: 420.84,
        description:
            'Chinese-American chow & Polynesian fare served with exotic Tiki cocktails in a shopping center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7114,
        store_longitude: -73.951,
        total_orders: 7.58,
        neighborhood: 'Williamsburg',
        keywords:
            'Mexican, Steak, Beer, Shrimp Cocktail, Margarita, Wine, Dessert, Spicy, French, American',
        annual_dol: 31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7486,
        store_longitude: -73.9779,
        total_orders: 820.77,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Burger, Steak, Hamburger, Salad, Mexican, Bbq, Wine, Organic, French, American',
        annual_dol: 3355.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -73.9661,
        total_orders: 255.81,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Ale, Juicy, Spacious, Sleek, Sweet, Creative, Iconic, Great, Classic',
        annual_dol: 1128.55,
        description:
            '"Other Half Domino Park, on the waterfront and across from the park in Williamsburg, Brooklyn, is our pilot brewery and taproom. Here, you\'ll find our brewers making small batch and experimental beers while sampling 15+ draft lines and even more cans from our other breweries in Brooklyn, DC and the Finger Lakes. We have indoor and outdoor seating, plus signature cocktails, a full bar, and non-alcoholic options."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.9771,
        total_orders: 664.55,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Chinese, Spicy, Asian, Traditional, Authentic, Casual, Excellent, Incredible, Dim Sum',
        annual_dol: 836.72,
        description:
            "A Shanghainese chef's place for soup dumplings & traditional plates in a brick-walled setting.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.9297,
        total_orders: 37.88,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Tequila, Beer, Spanish, Dope, Latin, Sweet, Casual',
        annual_dol: 44.25,
        description: '"Sports bar with a billiards table"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7482,
        store_longitude: -73.9398,
        total_orders: 687.53,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Wine, Sushi, Beer, Hotel, Spanish, Trendy, Dutch, Relaxed, Contemporary, American',
        annual_dol: 736.02,
        description:
            "Relaxed rooms provide free Wi-Fi, flat-screen TVs and minifridges, as well as desks with ergonomic chairs. Suites add separate living areas, and terraces with city views.Light meals are served in a trendy cocktail bar that features regular live acoustic performances. There's also a gym and a snack shop (both open 24/7).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6749,
        store_longitude: -74.016,
        total_orders: 1165.93,
        neighborhood: 'Red Hook',
        keywords:
            'Beer, Bbq, Rustic, American, Korean, Southern, Classic, Local, Casual, Authentic',
        annual_dol: 1506.12,
        description:
            'Rustic counter-service choice featuring slow smoked meats, craft beer & live music on weekends',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7265,
        store_longitude: -73.9915,
        total_orders: 1390.76,
        neighborhood: 'East Village/Bowery',
        keywords: 'Tasty, Funky, Lively, Fun, Colorful, Fancy, Superb, Friendly, Great, Intimate',
        annual_dol: 1732.14,
        description:
            '"Enjoy Bowery\'s most colorful outdoor covered patio seating for Happy Hour food & drinks Tuesday - Friday from 5:30 to 7:30. Or book a dinner reservation for a more elevated food and drink experience inside for an intimate seating arrangement inside our plush neon disco vibes. But perhaps what Short Stories is arguably most known for, is being the best kept secret for celebrating any occasion on a Friday and Saturday as we transition into a bumping late night jaunt starting at 11pm. Light up fountains of large format bottle service cocktails flow with good vibes, and even better memories as everyone bops along to your favorite song. Long story short, you\'re always going to remember the good times you had a Short Stories."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9872,
        total_orders: 968.96,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Whiskey, Beer, Wine, Bourbon, Salad, Meatball, Avocado Toast, Ale, Spicy',
        annual_dol: 1058.91,
        description:
            'Bar focusing on whiskeys (region by region), along with barrel-aged cocktails & late-night bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -73.9866,
        total_orders: 3520.89,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Salad, Greek, Caesar Salad, Club Sandwich, Cozy, American, Perfect, Traditional, Tradition',
        annual_dol: 3606.83,
        description:
            'The Players is a private social club founded in New York City by the noted 19th-century Shakespearean actor Edwin Booth. In 1888, Booth purchased an 1847 mansion at 16 Gramercy Park, reserved an upper floor for his residence, and turned the rest into a clubhouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7347,
        store_longitude: -73.988,
        total_orders: 1881.1,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Burger, Beer, Wine, Ale, Upscale, Warm, Cozy, French, Friendly, American',
        annual_dol: 1962.66,
        description:
            'This old-timey tavern with a brick & wood interior has upscale bar bites, draft brews & a cool vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8322,
        store_longitude: -73.8649,
        total_orders: 689.25,
        neighborhood: 'East Bronx/Soundview',
        keywords:
            'Steak, Tequila, Sushi, Salad, Spanish, Gluten Free, American, Puerto Rican, Latin, Modern',
        annual_dol: 726.39,
        description:
            'Spanish-style dining on steaks & Latin dishes in a space with live jazz some nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7384,
        store_longitude: -73.9912,
        total_orders: 449.86,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tequila, Mexican, Tacos, Beer, Margarita, Wine, Salad, Upscale, Organic, Delicious',
        annual_dol: 1466.22,
        description:
            'Modern Mexican chain eatery known for tableside guacamole, creative margaritas & chic surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7303,
        store_longitude: -73.8638,
        total_orders: 397.21,
        neighborhood: 'Rego Park/Flushing',
        keywords: 'Lobster, Sushi, Salad, Japanese, Spicy, Beer, Sashimi, Asian, Fusion, Wonderful',
        annual_dol: 505.99,
        description:
            'Special rolls & lunch bento boxes are served in a dark setting with a room for private events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8014,
        store_longitude: -73.9652,
        total_orders: 177.69,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Thai, Gluten Free, Vegan, Delicious, Spicy, Green Curry, Crisp, Friendly, Authentic, Exotic',
        annual_dol: 191.01,
        description:
            'Thai staples & more exotic specials in a Bangkok street market-themed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5235,
        store_longitude: -74.2159,
        total_orders: 62.37,
        neighborhood: "Prince's Bay",
        keywords: 'Champagne, Beer, Steak, Tasty, Delicious, Bbq, Awesome, Great, Global, Chill',
        annual_dol: 254.95,
        description:
            '"Live sports with surround sound, live bands and DJs, karaoke, freshly prepared food, and the same great service for over a decade at Hot Shotz Sports Bar!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8734,
        store_longitude: -73.867,
        total_orders: 64.21,
        neighborhood: 'East Bronx/Williamsbridge/Olinville',
        keywords: 'Karaoke, Beer, Friendly, Cool, Great, Casual',
        annual_dol: 241.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8829,
        store_longitude: -73.9017,
        total_orders: 39.26,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Lobster, Sushi, Salad, Cajun, Spicy, Delicious, Crab Rangoon, Thai, Ramen, Fried Shrimp',
        annual_dol: 160.47,
        description:
            '"Baby Crab serves delicious seafood boils, burgers and rolls. Bringing the freshest seafood straight from the sea to NYC! 🦀🙏"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8257,
        store_longitude: -73.9434,
        total_orders: 102.76,
        neighborhood: 'Sugar Hill/Harlem',
        keywords: 'Hamburger, Beer, Chinese, Wine, Asian, Classy, Local, Casual, Great, Dim Sum',
        annual_dol: 420.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9699,
        total_orders: 617.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Lobster, Lobster Roll, Savory, Spicy, Delicious, Warm, Japanese, Beer, Sashimi',
        annual_dol: 644.24,
        description:
            'Casual sushi house with a lofty feel offering Japanese mains & premium rolls along with beer & sake.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.9685,
        total_orders: 1781.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Whiskey, Kebab, Beer, Wine, Bourbon, Delicious, Hotel, American, Authentic, Perfect',
        annual_dol: 6266.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -73.7458,
        total_orders: 39.15,
        neighborhood: 'Oakland Gardens/Bayside',
        keywords: 'Steak, Hamburger, Pizza, Beer, Wine, Irish, Bbq, French, Delicious, Hotel',
        annual_dol: 96.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6677,
        store_longitude: -73.7076,
        total_orders: 3.32,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Tacos, Salad, Coffee, Beer, Casual, Classic',
        annual_dol: 13.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.703,
        store_longitude: -73.9945,
        total_orders: 2135.4,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Lobster, Lasagna, Lobster Tail, Salad, Dessert, Wine, Gourmet, Delicious, Elegant, Warm',
        annual_dol: 2227.99,
        description:
            'Landmark eatery, newly renovated, offering a New American menu & stunning views of Manhattan.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7424,
        store_longitude: -73.992,
        total_orders: 4505.46,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Steak, Burger, Lobster, Hamburger, Tasty, Mexican, Salad, Spicy, Wine, Juicy',
        annual_dol: 4748.04,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6946,
        store_longitude: -73.994,
        total_orders: 297.56,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Vegetarian, Steak, Pizza, Italian, Shrimp Cocktail, Coffee, Delicious, Cozy, Relaxed, Homemade',
        annual_dol: 747.53,
        description:
            'One of a group of relaxed Italian eateries, this place lets diners mix and match pastas and sauces.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7412,
        store_longitude: -73.9923,
        total_orders: 166.67,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Elegant, Organic, Exquisite, Great, Casual, Fine, Quirky, Heritage, Signature',
        annual_dol: 681.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6844,
        store_longitude: -73.9918,
        total_orders: 1057.77,
        neighborhood: 'Carroll Gardens/Brooklyn Heights',
        keywords: 'Mexican, Tacos, Steak, Nachos, Tex-Mex, American, Rich, Latin, Authentic, Local',
        annual_dol: 1328.45,
        description:
            'Tacos in housemade tortillas & other Mexican bites are offered in a colorful, art-accented space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6621,
        store_longitude: -73.951,
        total_orders: 759.73,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords: 'Wine, Beer, French, Classy, American, Awesome, Great, Fine, Casual, Signature',
        annual_dol: 799.68,
        description:
            'Wine, draft beer & cocktails, plus sloppy Joes in an old-fashioned haunt offering live jazz music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7469,
        store_longitude: -73.8893,
        total_orders: 87.57,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords:
            'Salad, Steak, Ecuadorian, Coffee, Spanish, Latin, American, Fresh, Casual, Excellent',
        annual_dol: 104.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7517,
        store_longitude: -73.9906,
        total_orders: 287.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Steak, Burger, Salad, Wine, Vodka, Beer, Coffee, Irish, Delicious, American',
        annual_dol: 438.82,
        description:
            'Modest hangout offering draft beer & TV sports, plus pub food & Irish & American entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.9884,
        total_orders: 248,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vodka, Chill, Awesome, Casual, Friendly, Terrific',
        annual_dol: 1013.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8103,
        store_longitude: -73.9499,
        total_orders: 1500.15,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Stylish, Hotel, Vibrant, Cajun, Friendly, Coffee, Fantastic, Great, Hip',
        annual_dol: 1566.71,
        description:
            'Hip rooms have free Wi-Fi, 42-inch flat-screen TVs, coffeemakers and minifridges, plus bathrooms with walk-in showers. Kids stay at no extra cost with an adult.Amenities include a trendy, 24-hour self-serve cafe and bar serving Cajun fare, as well as a 24/7 fitness room, and a courtyard with seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7714,
        store_longitude: -73.7347,
        total_orders: 21.11,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Cajun, Salad, Chicken Caesar Salad, Italian, Bourbon, Spicy, American, Caesar Salad, Fresh, Outstanding',
        annual_dol: 24.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.9682,
        total_orders: 522.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Coffee, Upscale',
        annual_dol: 574.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9825,
        total_orders: 119.91,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Wine, Premium, Warm, Relaxed, Organic, Perfect, Great, Modern, Fine, Casual',
        annual_dol: 490.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7263,
        store_longitude: -73.7907,
        total_orders: 742,
        neighborhood: 'Fresh Meadows/Utopia/Flushing',
        keywords: 'Steak, Sushi, Salad, Japanese, Spicy, Healthy, Modern, Thai, Fresh, Asian',
        annual_dol: 790.57,
        description:
            'Chic, modern venue featuring creative sushi, classic Japanese entrees & meat grilled tableside.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7456,
        store_longitude: -74.0022,
        total_orders: 650.57,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Vegan, Mexican, Tasty, Wine, Margarita, Delicious, Creamy, Enchiladas, Spacious',
        annual_dol: 709.02,
        description:
            'Elevated Mexican cuisine in a festive dining room, plus tequila-based cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7387,
        store_longitude: -73.9938,
        total_orders: 379.13,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Italian, Salad, Wine, Delicious, Rich, Martini, Margarita, Classy, Fun, Modern',
        annual_dol: 422.14,
        description:
            'Pasta made with roe or sea urchin meets Caesar salads & grilled items at this high-ceilinged eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7392,
        store_longitude: -74.0015,
        total_orders: 299.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Vodka, Tequila, Salad, Martini, Margarita, Italian, Gin, Mediterranean, Sweet',
        annual_dol: 1223.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7712,
        store_longitude: -73.7349,
        total_orders: 2741.2,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Italian, Delicious, Spicy, Wine, Fun, Amazing, Fresh, Traditional, Rooftop, Rare',
        annual_dol: 2825.02,
        description:
            'Local mainstay for traditional Italian cooking in an old-fashioned setting with a rooftop garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6863,
        store_longitude: -73.9678,
        total_orders: 1073,
        neighborhood: 'Clinton Hill',
        keywords:
            'Lasagna, Burger, Mexican, Beer, Gluten Free, Italian, Wine, Rustic, Warm, Quaint',
        annual_dol: 1095.76,
        description:
            'Quaint neighborhood Italian trattoria, serving locally sourced dishes in intimate, rustic environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9287,
        total_orders: 465.86,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Beer, Burger, Whiskey, Ale, Coffee, Spicy, Salad, Buffalo Chicken Salad, French, Awesome',
        annual_dol: 556.49,
        description:
            'Relaxed taproom hosting weekend DJs & serving numerous whiskeys, jumbo cocktails & pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.74,
        store_longitude: -73.9791,
        total_orders: 128.88,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Vegetarian, Pizza, Salad, Italian, Meatball, Wine, Beer, Dessert, Homemade',
        annual_dol: 166.19,
        description:
            'Vibrant kitchen doling out brick-oven pizzas & Italian standards such as pasta & veal marsala.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6455,
        store_longitude: -73.9127,
        total_orders: 3763.38,
        neighborhood: 'Canarsie',
        keywords:
            'Lobster, Pizza, Salad, Champagne, Delicious, Whiskey, Jamaican, Irish, Caribbean, Fun',
        annual_dol: 8088.64,
        description:
            '"ALWAYS A GREAT TIME AT FLAVA II\'S FOOD / COCKTAILS / LATE NIGHT / FUN Schedule a special event with us! Memorable experience. Birthday Parties / Engagements / Personal Life Events / Graduations / Charity Events / Late Night / Bachelorette Parties / Bachelor Parties / Girls night out / Outdoor & indoor dining available."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7842,
        store_longitude: -73.9778,
        total_orders: 5465.43,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Whiskey, Beer, Coffee, Delicious, Japanese, Gin, Ale, Gracious',
        annual_dol: 5988.76,
        description:
            'Unassuming tavern featuring Scottish whiskeys & traditional bites such as steak & kidney pie.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8692,
        store_longitude: -73.8254,
        total_orders: 2791.03,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Burger, Cornbread, Steak, Shrimp Cocktail, Bbq, Mimosa, Tasty, Margarita, French, Friendly',
        annual_dol: 3109.95,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8341,
        store_longitude: -73.8533,
        total_orders: 138.01,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Tasty, Hamburger, Delicious, Elegant, Wine, Mimosa, Friendly, Superb, Spanish, American',
        annual_dol: 169.34,
        description:
            'Neighborhood lounge featuring DJs, hookah pipes & cocktails, plus a menu of American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6988,
        store_longitude: -73.6521,
        total_orders: 33.32,
        neighborhood: '',
        keywords:
            'Pizza, Vegetarian, Italian, Shrimp Cocktail, Flavorful, Shrimp Scampi, Rich, Casual, Classic, Excellent',
        annual_dol: 136.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8547,
        store_longitude: -73.8885,
        total_orders: 1681.1,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Pizza, Italian, Wine, Warm, Crisp, Outstanding, Impressive, Fresh, Authentic, Cool',
        annual_dol: 1686.26,
        description:
            'Traditional Italian food & drink served in a warm setting with an old-world vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.73,
        store_longitude: -73.9551,
        total_orders: 90.96,
        neighborhood: 'Greenpoint',
        keywords:
            'Kebab, Polish, Beer, Ale, Delicious, Rustic, Authentic, Traditional, Fresh, Casual',
        annual_dol: 126.55,
        description:
            'Waitresses in traditional dress serve Polish standards at this eatery with a rustic farmhouse feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7666,
        store_longitude: -73.9185,
        total_orders: 219.1,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Lobster, Lobster Bisque, Margarita, Wine, Beer, Bourbon, Rum, Warm, Cozy, Creole',
        annual_dol: 508.07,
        description:
            'Cozy seafood grill & raw oyster bar specializing in updated classic dishes, cocktails & craft ales.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.766,
        store_longitude: -73.9152,
        total_orders: 1558.21,
        neighborhood: 'Astoria/LIC',
        keywords: 'Irish, Fun, Tasty, Hip, Great, Traditional, Casual',
        annual_dol: 1558.21,
        description:
            'Corner Irish bar with a traditional feel featuring draft beers, sports on TV, darts & trivia nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7684,
        store_longitude: -73.6914,
        total_orders: 2012.09,
        neighborhood: '',
        keywords: 'Golfclub, Luxurious, Picturesque, Terrific, Fresh, Diverse',
        annual_dol: 2240.33,
        description:
            'North Hills Country Club is a country club which was relocated in the 1960s to North Hills, New York, United States. The club was founded in 1927 at the current location of Douglaston Park. The golf course in North Hills was designed by Robert Trent Jones in 1961.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7221,
        store_longitude: -74.0035,
        total_orders: 1510.12,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Italian, Delicious, Authentic, Perfect, Homemade, Iconic, Casual, Southern, Local',
        annual_dol: 6691.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7161,
        store_longitude: -73.9995,
        total_orders: 472.62,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Sushi, Vegetarian, Japanese, Wine, Beer, Karaoke, Trendy, Spicy, Sashimi, Bbq',
        annual_dol: 502.95,
        description:
            'Trendy spot serving Hokkaido-style dishes, sushi & tabletop BBQ in a loungey atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5717,
        store_longitude: -74.1117,
        total_orders: 172405.79,
        neighborhood: 'New Dorp',
        keywords: 'Swiss, Whiskey, Superb, Great, Casual, Unique',
        annual_dol: 323961.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5815,
        store_longitude: -74.1114,
        total_orders: 506.5,
        neighborhood: 'Todt Hill/Midland Beach',
        keywords:
            'Salad, Vegetarian, Sushi, Chilean, Organic, French, Japanese, Trendy, Sashimi, Asian',
        annual_dol: 528.46,
        description:
            'Buzzing eatery & lounge offering Japanese dishes with a French twist, plus cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7041,
        store_longitude: -74.0104,
        total_orders: 3857.52,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Cornbread, Tasty, Beer, Bourbon, Wine, Dessert, Delicious, Bbq, American, Cozy',
        annual_dol: 4229.74,
        description:
            'Lively spot with exposed brick & outdoor seating, pairing eclectic BBQ with cocktails & craft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.5607,
        total_orders: 678.95,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Exquisite, Perfect, Friendly, Lively, Great, Traditional, Local, Fine',
        annual_dol: 701.07,
        description:
            'Inviting, down-home trattoria serving up traditional Italian cuisine for more than 30 years.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.5868,
        total_orders: 3278.93,
        neighborhood: '',
        keywords:
            'Burger, Steak, Vegetarian, Cheesecake, Cajun, Salad, Whiskey, Wine, Beer, Gluten Free',
        annual_dol: 3372.88,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -74.0063,
        total_orders: 2762.58,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Whiskey, Beer, Wine, Distilled, American, Savory, Cozy, Delicious, Crisp, Spacious',
        annual_dol: 3657.29,
        description:
            'Hip gastropub with an open kitchen offering creative pub fare & cocktails in a landmark building.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7196,
        store_longitude: -73.995,
        total_orders: 2243.48,
        neighborhood: 'Little Italy',
        keywords:
            'Beer, Mexican, Steak, Coffee, Green Curry, Mimosa, Bbq, French Toast, Rustic, American',
        annual_dol: 2969.6,
        description:
            'Seasonal & reserve brews go with gastropub dinner & weekend brunch in hip, rustic-chic surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7255,
        store_longitude: -74.0052,
        total_orders: 1096.17,
        neighborhood: 'Hudson Square',
        keywords:
            'Tequila, Vodka, Gin, Hotel, Stylish, American, Warm, Japanese, Grand Marnier, Contemporary',
        annual_dol: 2824.66,
        description:
            'Sophisticated restaurant specializing in seafood dishes amid contemporary surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7418,
        store_longitude: -73.5935,
        total_orders: 7957.63,
        neighborhood: '',
        keywords:
            'Salad, Tequila, Cheesecake, Cajun, Chicken Caesar Salad, Mexican, Wine, Beer, Nachos, Tex-Mex',
        annual_dol: 8126.48,
        description:
            'Family-friendly chain serving classic Tex-Mex & American fare in a Southwestern-style setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7267,
        store_longitude: -74.0058,
        total_orders: 818.61,
        neighborhood: 'Hudson Square',
        keywords: 'Burger, Steak, Pizza, Tequila, Salad, Italian, Spicy, Coffee, Wine, Hawaiian',
        annual_dol: 3346.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7771,
        store_longitude: -73.9536,
        total_orders: 2910.1,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Lively, Fun, Intimate, Awesome, Friendly, Great, Excellent, Casual',
        annual_dol: 2921.44,
        description:
            'A piano player accompanies lively staffers & patrons belting out show tunes & other favorite songs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7722,
        store_longitude: -73.9556,
        total_orders: 2460.44,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Italian, Meatball, Wine, Delicious, Warm, Rustic, Romantic, Relaxed, Lively, Great',
        annual_dol: 2529.93,
        description:
            'This cozy, rustic spot with a patio & back garden draws lively crowds for small plates & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7715,
        store_longitude: -73.9562,
        total_orders: 671.5,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Spanish, Wine, Seafood Paella, Seasonal, Contemporary, Delicious, Sangria, Intimate, Lively',
        annual_dol: 747.11,
        description:
            'Creative Barcelona-style tapas made with market-fresh ingredients in a warm, modern space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9129,
        total_orders: 7.45,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords:
            'Burger, Vegetarian, Garlic Bread, Cajun, Mexican, Coffee, Roast Beef, Margarita, Bbq, French',
        annual_dol: 30.46,
        description:
            'Easygoing corner diner serving breakfast-to-dinner comfort food, including vegetarian options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7022,
        store_longitude: -73.9196,
        total_orders: 1276.89,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Vegetarian, Beer, Vodka, Italian, Local, Mai Tai, Awesome',
        annual_dol: 1463.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6563,
        store_longitude: -73.6625,
        total_orders: 690.83,
        neighborhood: '',
        keywords:
            'Wine, Vodka, Steak, Italian, Beer, French, Premium, Innovative, Traditional, Outstanding',
        annual_dol: 714.81,
        description:
            'White-tablecloth restaurant with simple decor serving Italian plates with a modern twist.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7349,
        store_longitude: -74.0063,
        total_orders: 838.41,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Hamburger, Coffee, Beer, Salad, Wine, Organic, American, French, Cozy',
        annual_dol: 978.03,
        description:
            'Cozy spot with patio dining featuring farm-to-table, seasonal cuisine with global influences.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7346,
        store_longitude: -74.0017,
        total_orders: 3180.73,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Hotdog, Delicious, Juicy, Warm, Friendly, French, Fun, Hip, Casual',
        annual_dol: 3263.79,
        description:
            "Known as the city's oldest gay bar, this standby also serves burgers to its mature male regulars.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.734,
        store_longitude: -74.0014,
        total_orders: 1333.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Lobster Roll, Burger, Shrimp Cocktail, Martini, Gin, French, Homey, Cozy, Croissant Sandwich',
        annual_dol: 1362.04,
        description:
            'This homey American eatery is notable for its oyster bar, creative Caesar salads & Bloody Marys.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7375,
        store_longitude: -74.0051,
        total_orders: 363.48,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Flavorful, Tasty, Wine, Coffee, Delicious, Beer, Healthy, Ethiopian, Spacious',
        annual_dol: 1485.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7326,
        store_longitude: -74.0017,
        total_orders: 1058.13,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Beer, Vodka, Tasty, Chicken Caesar Salad, Wine, Salad, Ale, Delicious, French Toast',
        annual_dol: 1104.01,
        description:
            'Underground bar serving up wings, burgers & more, along with drink specials & sports on the TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7391,
        store_longitude: -74.0056,
        total_orders: 21.31,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Tasty, Coffee, French Toast, Cozy, Avocado Toast, French, Friendly, Australian, Phenomenal',
        annual_dol: 87.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6374,
        store_longitude: -73.7453,
        total_orders: 316.51,
        neighborhood: 'Jamaica/Rosedale',
        keywords: 'Burger, Steak, Salad, Beer, Bbq, American, Caesar Salad, Warm, Relaxed, Casual',
        annual_dol: 330.24,
        description:
            'Relaxed bayside tavern with outdoor seats offering American fare including many seafood offerings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7054,
        store_longitude: -73.9327,
        total_orders: 1710.54,
        neighborhood: 'East Williamsburg',
        keywords: 'Vegan, Beer, Champagne, Coffee, Fruity, Ale, Czech, Karaoke, Spicy, American',
        annual_dol: 1812.9,
        description:
            'Bar & music venue in a former casket factory, with vegan-friendly snacks, cocktails & draft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6979,
        store_longitude: -73.928,
        total_orders: 748.64,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Tasty, Trendy, Classy, Latin, Authentic, Fresh, Great, Excellent',
        annual_dol: 797.84,
        description:
            'Dark, trendy nightspot with a tropical accent pouring craft cocktails to fuel the DJ dance party.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7049,
        store_longitude: -73.9278,
        total_orders: 784.83,
        neighborhood: 'East Williamsburg',
        keywords:
            'Vegan, Vegetarian, Wine, Coffee, Beer, Ethiopian, Friendly, African, Casual, Classy',
        annual_dol: 837,
        description:
            'Bustling outpost provides Ethiopian vegan fare, cocktails & regular events in a low-lit dining room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7342,
        store_longitude: -74.0064,
        total_orders: 33419.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Salad, Japanese, Spicy, Wine, Funky, Stylish, Whimsical, American, Retro',
        annual_dol: 38536.71,
        description:
            'Cool, bi-level bar serving izakaya fare & traditional American bites, plus whimsical cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.736,
        store_longitude: -74.0061,
        total_orders: 220.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Pizza, Mimosa, Seafood Paella, Spicy, Delicious, Bbq, Cozy, Romantic, American',
        annual_dol: 572.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.593,
        total_orders: 320.79,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Meatball, Caesar Salad, Spacious, Friendly, Authentic, Outstanding, Fine',
        annual_dol: 342.12,
        description:
            'Longtime Northern Italian restaurant serving old-school specialties in an old-world setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7698,
        store_longitude: -73.9518,
        total_orders: 63.62,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Wine, Coffee, Dessert, Avocado Toast, Savory, Organic, French, Swiss, Irresistible',
        annual_dol: 260.05,
        description:
            'Unassuming bistro with an adjacent cafe/grocery furnishes light French bites for breakfast & lunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.835,
        store_longitude: -73.8544,
        total_orders: 17.68,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Chocolate Cake, Shrimp Cocktail, Margarita, Cuban, Coffee, Dominican, Puerto Rican, American, Casual, Local',
        annual_dol: 72.27,
        description:
            '"Restaurant serving Authentic Puerto Rican Cuisine, Full Bar, Catering for all Events Available"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7072,
        store_longitude: -73.6746,
        total_orders: 886.52,
        neighborhood: '',
        keywords:
            'Burger, Steak, Shrimp Cocktail, Beer, Wine, Delicious, Roast Beef, Italian, Juicy, Irish',
        annual_dol: 957.87,
        description:
            'Diverse Irish & American comfort food & 100+ beers from around the world served in a relaxed space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7126,
        store_longitude: -73.9059,
        total_orders: 275.23,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Burger, Vegetarian, Tacos, Mexican, Margarita, Flavorsome, Fajitas, Delicious, Cozy, Casual',
        annual_dol: 792.73,
        description:
            '"La Casita Mexicana is the new king in town of classic Mexican cuisine. In addition to the warm, flavorsome dishes and refreshing drinks, the newly renovated interior provides a colorful, modern atmosphere. A visit here is sure to leave you with a vivid impression and an unforgettable experience!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7045,
        store_longitude: -73.6375,
        total_orders: 9.74,
        neighborhood: '',
        keywords: 'Steak, Coffee, Local, Casual',
        annual_dol: 39.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6778,
        store_longitude: -73.9845,
        total_orders: 1319.58,
        neighborhood: 'Gowanus',
        keywords:
            'Steak, Bbq, Gluten Free, Delicious, American, Homemade, Friendly, Retro, Perfect, Southern',
        annual_dol: 1347.58,
        description:
            'Barbecue chain serving Southern-style meats & draft brews in a retro setting (most have live music).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6606,
        store_longitude: -73.994,
        total_orders: 49.41,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Steak, Mexican, Kebab, Salad, Dessert, Coffee, French Toast, Delicious, Trendy, French',
        annual_dol: 201.98,
        description:
            '"Mexican Cuisine with a touch of new. We Serve Brunch, Lunch & Dinner. Bar Onsite. All Margaritas And Cocktails Are made with Fresh fruit Juices.Outdoor Seating Available"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7721,
        store_longitude: -73.965,
        total_orders: 437.22,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Chocolate Cake, Burger, Sushi, Coffee, Mexican, Italian, Wine, Delicious, Elegant, Asian',
        annual_dol: 456.18,
        description:
            'Classy eatery presenting elevated Italian entrees & pastas with gluten-free options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6975,
        store_longitude: -73.9317,
        total_orders: 760.18,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Fun, Upscale, Margarita, Awesome, Amazing, Friendly, Great, Cool, Urban',
        annual_dol: 1095.84,
        description:
            'Chill, tiki-themed gay bar with picnic tables serves frozen drinks & cocktails plus happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -73.9229,
        total_orders: 604.95,
        neighborhood: 'Bushwick',
        keywords:
            'Vegetarian, Lobster, Pizza, Italian, Dessert, Japanese, Californian, Rustic, Delicious, Upscale',
        annual_dol: 674.58,
        description:
            'Rustic Italian place serving housemade pastas & wood-fired dishes in a renovated warehouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8293,
        store_longitude: -73.8198,
        total_orders: 63.62,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Italian, Elegant, Exquisite, American, Classic, Memorable, Great, Attentive, Teriyaki',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7328,
        store_longitude: -74.0031,
        total_orders: 1548.84,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cajun, Beer, Milkshake, French Onion Soup, French, Fun, Classic, Fresh, Chic, Great',
        annual_dol: 5488.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7281,
        store_longitude: -73.6019,
        total_orders: 1183.73,
        neighborhood: '',
        keywords: 'Wine, American, Perfect, Great, Excellent',
        annual_dol: 1293.17,
        description:
            "The Cradle of Aviation Museum is an aerospace museum located in Garden City, New York on Long Island, established to commemorate Long Island's part in the history of aviation.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.735,
        store_longitude: -74.0081,
        total_orders: 1998.9,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Wine, American, Cozy, Awesome, Perfect, Iconic, Great, Local, Casual',
        annual_dol: 3913.86,
        description: 'Dark, cozy bar with a hideaway vibe and lengthy beer & wine lists.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7854,
        store_longitude: -73.9727,
        total_orders: 1237.23,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Mexican, Beer, Wine, Spanish, Tasty, Upscale, Dominican, Peruvian, Puerto Rican, Italian',
        annual_dol: 2342.22,
        description:
            'Genteel tavern with a patio offering a fusion of Spanish & Caribbean cuisine, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6296,
        store_longitude: -74.1123,
        total_orders: 673.72,
        neighborhood: 'West Brighton',
        keywords:
            'Lobster, Steak, Salad, Shrimp Cocktail, Mexican, Champagne, Dessert, Lobster Bisque, Vodka, Martini',
        annual_dol: 736.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7706,
        store_longitude: -73.9265,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Wine, Beer, Gourmet, Pizza, Mediterranean, Greek, Upscale, Delicious, Spacious, Contemporary',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7067,
        store_longitude: -73.6681,
        total_orders: 16.12,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Shrimp Cocktail, Beer, Wine, Homemade, Classy, Clean, Casual, Local',
        annual_dol: 65.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7521,
        store_longitude: -73.7028,
        total_orders: 55.01,
        neighborhood: 'Glen Oaks/Bellerose Manor',
        keywords:
            'Traditional, Savory, Indian, Southern, Authentic, Casual, Superb, Aloo Tikki, Local, Fine',
        annual_dol: 224.86,
        description:
            'Easygoing Indian restaurant with traditional fare, such as biryanis, tandoori dishes & kebabs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6774,
        store_longitude: -73.9863,
        total_orders: 852.07,
        neighborhood: 'Gowanus',
        keywords:
            'Tacos, Mexican, Pizza, Margarita, Wine, Spanish, Upscale, Delicious, Sparkling, Awesome',
        annual_dol: 1206.18,
        description:
            'Mexican spot with a patio specializing in Oaxacan plates (including house tortillas) & mezcals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.724,
        store_longitude: -73.9507,
        total_orders: 5661.46,
        neighborhood: 'Greenpoint',
        keywords: 'Sushi, Mexican, Wine, Cuban, Japanese, Hotel, American, Homey, Ramen, Homemade',
        annual_dol: 6273.35,
        description:
            'Hip cocktail bar mixing up specialty cocktails, plus an international food menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9656,
        total_orders: 1953.41,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Burger, Hamburger, Shrimp Cocktail, Chicken Pot Pie, Cheesecake, Crab Cakes, Turkey Sandwich, French, Homey',
        annual_dol: 1996.35,
        description:
            'Homey institution for steaks & other straightforward classics in vintage, dark-wood quarters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8477,
        store_longitude: -73.6904,
        total_orders: 2023.78,
        neighborhood: '',
        keywords: 'Golfclub, American, Friendly, Great, Exceptional',
        annual_dol: 2402.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7158,
        store_longitude: -74.0086,
        total_orders: 1155.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Pizza, Vegetarian, Chicken Parmigiana, Italian, Gourmet, Wine, Beer, Bourbon, Luxurious',
        annual_dol: 1179.66,
        description:
            'Thin-crust pizza & homemade pasta headline the menu at this tri-level space with exposed brick.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7201,
        store_longitude: -74.0037,
        total_orders: 49.79,
        neighborhood: 'Tribeca/Canal Street',
        keywords: 'Pizza, Italian, Beer, Coffee, Wine, Upscale, Cozy, Homemade, Fresh, Classy',
        annual_dol: 203.53,
        description:
            'Brick-oven pizzas, sandwiches & pastas, plus coffee & a full bar, in a casual, old-world atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7227,
        store_longitude: -74.0073,
        total_orders: 273.82,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Gourmet, Impeccable, Innovative, Fine, Cultural, Unique',
        annual_dol: 311.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -74.0025,
        total_orders: 62.39,
        neighborhood: '',
        keywords:
            'Steak, Italian, Bbq, Stylish, Sweet, Awesome, Amazing, Fresh, Urban, Sophisticated',
        annual_dol: 260.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7068,
        store_longitude: -73.9235,
        total_orders: 2743.49,
        neighborhood: 'Bushwick',
        keywords: 'Hotdog, Beer, Karaoke, Coffee, Funky, Fun, Relaxed, Awesome, Great, Casual',
        annual_dol: 2864.99,
        description:
            "Cozy, funky venue that's equal parts coffee shop, bar, performance space & yoga studio.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7249,
        store_longitude: -73.6433,
        total_orders: 226.49,
        neighborhood: '',
        keywords: 'Golfclub, Delicious, Great, Casual',
        annual_dol: 346.95,
        description:
            'The Garden City Golf Club is a private golf course in Garden City, New York. The club was founded in 1899, and is also known as the "Garden City Men\'s Club" or simply the "Men\'s Club" to distinguish it from the Garden City Country Club, and Cherry Valley Club, all of which are located in Garden City, NY.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7841,
        store_longitude: -73.9774,
        total_orders: 605.54,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Salad, Italian, Beer, Delicious, Ale, Karaoke, Fun, Casual, American',
        annual_dol: 700.26,
        description:
            '"Funky Western dive bar. Come in for good vibes and arcades! Pinball, Buck Hunt, Photobooth. M - Open Mic @ 8pm T - Trivia @ 8pm W - Karaoke @ 8pm F - Live Honky Tonk @ 9pm S - Disco Rodeo @ 10pm"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.713,
        store_longitude: -73.6768,
        total_orders: 64.26,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Shrimp Cocktail, Wine, Caesar Salad, Comfortable, Casual, Fresh, Authentic',
        annual_dol: 149.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.658,
        total_orders: 26.61,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Steak, Cheesesteak, Beer, Philly Cheesesteak, American, Classic, Friendly, Casual',
        annual_dol: 108.78,
        description:
            'Specializing in chicken wings, burgers & classic bar snacks, with industrial decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6575,
        store_longitude: -73.5477,
        total_orders: 10.35,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Japanese, Wine, Spicy, Sashimi, Beer, Delicious, Filet Mignon, Fusion',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6579,
        store_longitude: -73.9829,
        total_orders: 22.78,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'American, Heritage',
        annual_dol: 93.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6965,
        store_longitude: -73.9349,
        total_orders: 823.45,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Pizza, Beer, Whiskey Sour, Whiskey, Fun, American, Great, Classic, Casual',
        annual_dol: 2237.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6852,
        store_longitude: -73.9383,
        total_orders: 187.19,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Lasagna, Pizza, Vegan, Vegetarian, Salad, Gluten Free, Coffee, Wine, Dessert, Beer',
        annual_dol: 262.24,
        description:
            'Neapolitan pizzas, cocktails & craft beer turned out in a simple, modern space with counter seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7699,
        store_longitude: -73.9577,
        total_orders: 1989.03,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Tasty, Thai, Stylish, Salad, Gluten Free, Spicy, Delicious, Upscale, Romantic, Chinese',
        annual_dol: 2031.24,
        description:
            'Stylish restaurant & bar serving upscale Thai street food in a low-lit space with colorful lanterns.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6548,
        store_longitude: -73.6752,
        total_orders: 208.42,
        neighborhood: '',
        keywords: 'Burger, Salad, Swiss, Beer, Irish, Spicy, Reuben, Casual, Classic, Great',
        annual_dol: 217.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -74.0036,
        total_orders: 1931.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Warm, Legendary, Fun, Friendly, Iconic, Amazing, Great, Quirky, Casual',
        annual_dol: 1972.57,
        description:
            'Live bebop, rhythm & blues, Dixieland jazz & more are presented at this circa-1937 tavern.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.736,
        store_longitude: -74.0016,
        total_orders: 1959.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Legendary, Iconic, Premium, Classic, Superb, Amazing, Authentic, Great, Excellent, Friendly',
        annual_dol: 1959.11,
        description:
            "From iconic 1960s gigs by John Coltrane to who's playing here now, this club lives up to its name.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8335,
        store_longitude: -73.8287,
        total_orders: 140.57,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords:
            'Burger, Tasty, Trendy, Legendary, American, Awesome, Amazing, Fresh, Great, Excellent',
        annual_dol: 382.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7868,
        store_longitude: -73.9755,
        total_orders: 147.42,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Wine, Gin, Delicious, Trendy, Rum, Painkiller, Hurricane, Fried Chicken, Irish, Pinacolada',
        annual_dol: 212.72,
        description:
            'Tiki staples & frozen drinks plus fried chicken & spam sandwiches are served in a vibrant space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8692,
        store_longitude: -73.8252,
        total_orders: 542.05,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Steak, Vegetarian, Lobster, Hamburger, Beer, Italian, Savory, American, Casual, Fabulous',
        annual_dol: 1144.35,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6479,
        store_longitude: -74.0077,
        total_orders: 5628.94,
        neighborhood: 'Sunset Park',
        keywords:
            'Tacos, Mexican, Vegetarian, Nachos, Margarita, Enchiladas, Fajitas, Delicious, Cozy, Smoky',
        annual_dol: 6293.02,
        description:
            'Traditional menu of tacos, tamales & tostadas served in a simple space; BYOB.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7888,
        store_longitude: -73.9744,
        total_orders: 256.56,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Pizza, Italian, Sushi, Salad, Meatball, Beer, Wine, Spicy, Rustic, Casual',
        annual_dol: 966.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7708,
        store_longitude: -73.9202,
        total_orders: 14.28,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Italian, Swiss, Homemade, Delicious, Mediterranean, American, Modern, Premium, Authentic',
        annual_dol: 58.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6964,
        store_longitude: -73.9004,
        total_orders: 53.35,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Burger, Steak, Pizza, Beer, Bbq, Cuban, Spanish, Wine, Avocado Toast, Ale',
        annual_dol: 218.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7159,
        store_longitude: -74.0151,
        total_orders: 256.57,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Sushi, Gluten Free, Spicy, Japanese, Chinese, Fried Rice, Asian, Thai, Modern, Fusion',
        annual_dol: 330.86,
        description:
            'Polished eatery offering elevated Pan-Asian plates, including sushi, fried rice & noodle bowls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8544,
        store_longitude: -73.8864,
        total_orders: 2213.75,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Vegetarian, Pizza, Italian, Shrimp Cocktail, Meatball, Wine, Delicious, French, Cozy, Polished',
        annual_dol: 3387.61,
        description:
            'Cozy trattoria known for its brick-oven thin-crust pies & other homestyle Italian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -74.0018,
        total_orders: 1477.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Italian, Wine, Beer, Fun, American, Heritage, Great, Tradition',
        annual_dol: 1508.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.8822,
        total_orders: 37.45,
        neighborhood: 'Jackson Heights',
        keywords:
            'Salad, Colombian, Peruvian, Dessert, Japanese, Thai, Delicious, Hotel, American, Local',
        annual_dol: 153.09,
        description: '"Colombian American Inspired Cuisine Serving Smoked Meats and Drinks"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9977,
        total_orders: 636.12,
        neighborhood: 'Nolita',
        keywords:
            'Tequila, Mexican, Tasty, Upscale, Spanish, Delicious, American, Innovative, Latin, Authentic',
        annual_dol: 2600.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6952,
        store_longitude: -73.9964,
        total_orders: 18.29,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Salad, Korean, Spicy, Sweet, American, High-End, Casual, Great, Unique, Chill',
        annual_dol: 20.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7646,
        store_longitude: -73.9809,
        total_orders: 2230.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Beer, Gourmet, Sleek, Sophisticated, Comfortable, Great, Terrific, Cool',
        annual_dol: 2326.83,
        description:
            "subway station.Sleek rooms with plush furnishings have Wi-Fi (fee), 46-inch flat-screen TVs and iPod docks; upper floors provide city views. Minifridges and coffeemakers are available on request.There's a grab-and-go cafe, and a hip lounge with mixology classes and regular live music. Other amenities include a 24-hour fitness center and meeting/event space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -73.9877,
        total_orders: 350.51,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Burger, Wine, Italian, Beer, Margarita, Mediterranean, Warm, Rustic, Southern',
        annual_dol: 365.71,
        description:
            'An Italian menu with a creative slant is served in a rustic dining room with an outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -74,
        total_orders: 7819.87,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Steak, Tacos, Vegetarian, Mexican, Nachos, Beer, Cuban, Coffee, Dessert',
        annual_dol: 10112.15,
        description:
            '24/7 luncheonette dishing out Pan-Latin comfort fare in a casual, colorful setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5517,
        store_longitude: -74.2016,
        total_orders: 2107.45,
        neighborhood: 'Rossville',
        keywords: 'Golfclub, Awesome, Memorable, Seasonal',
        annual_dol: 2250.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7407,
        store_longitude: -73.993,
        total_orders: 2172.82,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Pizza, Spicy, Beer, Bbq, Fun, Friendly, Relaxed, Perfect, Great, Casual',
        annual_dol: 2428.68,
        description:
            'Popular 2-level gay sports bar with big TVs, pool tables, brick-oven pizza, drink specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7812,
        store_longitude: -73.8029,
        total_orders: 224.47,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Sushi, Italian, Beer, Meatball, Japanese, French, American, Cozy, Homemade, Classy',
        annual_dol: 440.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6819,
        store_longitude: -73.9764,
        total_orders: 34.66,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Italian, Vegetarian, Salad, Gluten Free, Dessert, Wine, Homemade, Modern, Healthy',
        annual_dol: 51.51,
        description:
            'Buzzy trattoria offering Italian eats such as homemade pasta & pizza, plus a full bar with sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7127,
        store_longitude: -73.9571,
        total_orders: 476.53,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Pizza, Meatball, Chinese, Funky, Wine, Asian, Fun, Great, Casual, Signature',
        annual_dol: 1555.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6635,
        total_orders: 103.15,
        neighborhood: '',
        keywords:
            'Burger, Garlic Bread, Hamburger, Pizza, Sushi, Beer, Dominican, American, Perfect, Casual',
        annual_dol: 113,
        description:
            'Pub grub with full bar including a large beer selection, served in a simple space with lots of TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5889,
        store_longitude: -73.6451,
        total_orders: 88.88,
        neighborhood: '',
        keywords:
            'Kebab, Salad, Steak, Pizza, Tequila, Mexican, Vegetarian, Greek, Italian, Mediterranean',
        annual_dol: 363.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.648,
        store_longitude: -74.0863,
        total_orders: 33.89,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Greek, Egyptian, Elegant, Unique, Heritage, Fabulous',
        annual_dol: 138.52,
        description:
            '"EXPERIENCE A NEW ‘SO AMAZING!℠’ TRADITION: The Pavilion on the Terrace® is a style reminiscent of elegant craftsmanship with beautiful landscaping. Our historic Greek revival building built in 1835 has been magnificently renovated, and is the oldest and most unique structure in the St. George/New Brighton Historic District. The temple fronted two-story building has returned to its 19th century elegance with an interior which now houses a fabulous ​banquet hall, dining rooms, bars, and bridal suite. Our dedicated and experienced banquet hall staff will attend to every detail and assure a memory that you will always cherish--making your event not only Special but ALWAYS: SO AMAZING!℠"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7513,
        store_longitude: -73.9806,
        total_orders: 50.32,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tacos, Beer, Burritos, Salad, Wine, Hotel, Western, Delicious, Local',
        annual_dol: 542.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.9798,
        total_orders: 1339.96,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Japanese, Sushi, Hotel, Gourmet, Exquisite, Polished, High-End, Authentic, Fresh, Traditional',
        annual_dol: 1684.27,
        description:
            "The Kitano hotel's eatery offers Japanese fare & ancient Kaiseki cuisine tasting menus.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7448,
        store_longitude: -73.9842,
        total_orders: 423.73,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords:
            'Wine, Hotel, Coffee, Italian, Legendary, Eclectic, Rustic, Vibrant, Fantastic, Great',
        annual_dol: 451.58,
        description:
            'Casual-chic rooms have free Wi-Fi, flat-screen TVs, Italian linens and modern bathrooms; suites add sitting areas and side tables. Some rooms also have slate-tiled bathrooms and iPhone docks.Perks include an exercise room, a business center, and a contemporary Italian restaurant and lounge with craft cocktails. Parking is available at a nearby garage for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7106,
        store_longitude: -73.9657,
        total_orders: 585.04,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Wine, Trendy, Delicious, Gourmet, Cozy, Rustic, Friendly, Natural, Mediterranean, Modern',
        annual_dol: 1507.57,
        description:
            'Cozy Williamsburg spot featuring nibbles with a farm-fresh emphasis, plus wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.987,
        total_orders: 127.91,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Fun, Bright, Exceptional',
        annual_dol: 142.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7457,
        store_longitude: -73.9755,
        total_orders: 4.9,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Vegetarian, Steak, Roast Turkey, Coffee, French Toast, Swiss, French, French Onion Soup, Fried Chicken, American',
        annual_dol: 11.39,
        description:
            'Basic diner with 24/7 availability & a huge menu spanning breakfast basics to dinner entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -74.0027,
        total_orders: 202.14,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Fun, Premium, Great, Perfect, Friendly, Rooftop, Distinct',
        annual_dol: 290.83,
        description:
            'Colorful, multi-tiered dance club featuring high ceilings, LED screens & plenty of night owls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9811,
        total_orders: 6295.9,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Burger, Whiskey, Wine, French, Elegant, American, Warm, Polished, Cozy',
        annual_dol: 6526.56,
        description:
            'Polished French restaurant in an expansive art nouveau–style space with an elegant heated atrium.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9897,
        total_orders: 150.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Thai, Wine, Beer, Trendy, Bbq, Asian, Modern, American, Fusion, Classic',
        annual_dol: 160.56,
        description:
            'This Thai restaurant offers classic fare in a neon-lit, dark-wood space with a futuristic-chic vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.9871,
        total_orders: 3883.06,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Coffee, Authentic, Contemporary, Casual, Timeless, Fun, Classic, Lit, Great',
        annual_dol: 4051.42,
        description:
            'Hideaway with a Prohibition-esque vibe, offering cocktails in teacups and beer in paper bags.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7205,
        store_longitude: -73.9887,
        total_orders: 142.15,
        neighborhood: 'Lower East Side',
        keywords: 'Hamburger, Pizza, Cajun, Tequila, Mexican, Beer, Bbq, Wine, Chinese, Spicy',
        annual_dol: 499.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9888,
        total_orders: 3119.53,
        neighborhood: 'Lower East Side',
        keywords:
            'Wine, Beer, Martini, Eclectic, Vibrant, Lively, Classy, Retro, Fabulous, Spectacular',
        annual_dol: 3185.72,
        description:
            'Burlesque theater & lounge where patrons can watch variety shows from the main floor or mezzanine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9929,
        total_orders: 66.86,
        neighborhood: 'Bowery',
        keywords:
            'Steak, Salad, Dessert, Upscale, Mediterranean, Greek, Spacious, Polished, Homemade, Great',
        annual_dol: 281.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7632,
        store_longitude: -73.9681,
        total_orders: 46.74,
        neighborhood: 'Lenox Hill',
        keywords: 'Wine, French, Coffee, Thai, American, Casual, Hip, Intimate, Authentic, Fine',
        annual_dol: 191.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6764,
        store_longitude: -73.8218,
        total_orders: 20.89,
        neighborhood: 'South Ozone Park',
        keywords: 'Flavorful, Rich, Delicious, Upscale, Coffee, Fun, Premium, Friendly, Chill',
        annual_dol: 85.41,
        description:
            "\"Whether you're looking to relax on a weeknight or celebrate your next big event with the hottest DJ's in Queens, you've reached the right place!\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 426.63,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Rooftop, Hotel, Upscale, Champagne, Delicious, Spicy, Iconic, Tasty, Premium, Exceptional',
        annual_dol: 1547.86,
        description:
            'Posh hotel cocktail lounge providing comfy outdoor seating, DJ music & skyscraper views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9553,
        total_orders: 1940.46,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Beer, Vodka, Whiskey, Wine, Delicious, Dessert, Warm, Fun, Whimsical',
        annual_dol: 2095.84,
        description:
            'Domestic beers & 50 cocktails inspired by each U.S. state in a warm bar setting with a few TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6755,
        store_longitude: -73.956,
        total_orders: 1730.81,
        neighborhood: 'Crown Heights',
        keywords: 'Tequila, Vodka, Wine, Scotch, Gin, Rum, Delicious, Upscale, Cozy, Ramen',
        annual_dol: 1767.54,
        description:
            'Hip, cozy neighborhood bar offering craft cocktails, beer, wine & a backyard patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.706,
        store_longitude: -74.0049,
        total_orders: 89.38,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Italian, Comfortable, Amazing, Friendly, Great, Modern, Edgy',
        annual_dol: 338.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.928,
        total_orders: 5502.06,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Burger, Vegan, Pizza, Homemade, Friendly, Cool, Fresh, Fantastic, Great, Classic',
        annual_dol: 6413.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -73.9581,
        total_orders: 6696.1,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Lobster, Hotel, Shrimp Cocktail, Upscale, Romantic, Funky, Premium, Innovative, Intimate',
        annual_dol: 6838.18,
        description:
            'Popular Williamsburg bar serving cocktails in an upscale setting that recalls old New York.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -73.9617,
        total_orders: 574.7,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Beer, Trendy, Upscale, Japanese, Spicy, American, Delicious, Modern, Asian',
        annual_dol: 2349.17,
        description:
            '"Tonchin Brooklyn is a modern Japanese restaurant featuring a full a la carte menu grounded in traditional flavors, with a New American twist. Our extensive natural wine list and signature ramen anchor our journey as we explore the intersection between Tokyo and Brooklyn, uncovering new ways of preparing fresh local food in the Japanese spirit. We take to heart Tonchin\'s philosophy of bringing people together through extraordinary experiences, and are excited to discover the new, the fresh, and the seasonal with you."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.723,
        store_longitude: -73.9536,
        total_orders: 900.78,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords: 'Beer, Mexican, Sushi, German, Cozy, Casual, Chill, Fantastic, Great, Unique',
        annual_dol: 939.83,
        description:
            'Beer hall serving craft brews in a 6,000-sq.-ft. industrial setting with communal tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6441,
        store_longitude: -73.7822,
        total_orders: 6.34,
        neighborhood: 'Jamaica',
        keywords:
            'Swiss, Modern, Premium, Fine Quality, Lebanese, Fresh, Legendary, Cool, Seasonal, Fine',
        annual_dol: 25.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9211,
        total_orders: 460.79,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Gourmet, Beer, Nachos, Thai, Trendy, Asian, American, Fun, Sweet',
        annual_dol: 512.68,
        description:
            'Bustling sports bar offering American comfort food & many beers on tap, plus DJs & bocce ball.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.736,
        store_longitude: -73.934,
        total_orders: 1032.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Meatball, Spicy, Irish, Martini, American, Eclectic, High-End, Artisanal, Fresh, Great',
        annual_dol: 1054.54,
        description:
            'Unfussy watering hole offering hearty, internationally inspired pub grub & draft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6292,
        store_longitude: -74.0056,
        total_orders: 562.55,
        neighborhood: 'Dyker Heights',
        keywords:
            'Steak, Salad, Italian, Wine, Elegant, Cozy, Ribeye Steak, Classy, Premium, Homemade',
        annual_dol: 612.2,
        description:
            'Traditional trattoria showcases red-sauce Italian classics & offers a fixed-price menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6814,
        store_longitude: -74.0061,
        total_orders: 52.1,
        neighborhood: 'Red Hook',
        keywords: 'Modern, Friendly',
        annual_dol: 212.99,
        description:
            '"Shell’s Loft is a Daylight Film & Photo Studio, Wedding Venue, and Private & Corporate Event Space in Brooklyn, NYC. We\'re known for our huge plant collection + prop rentals!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6836,
        store_longitude: -73.9951,
        total_orders: 25.69,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Pizza, Italian, Dairy Free, Beer, Wine, Delicious, Fried Shrimp, Modern, British, Amazing',
        annual_dol: 105.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7265,
        store_longitude: -73.9869,
        total_orders: 1208.38,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Italian, Delicious, Dessert, Pizza, Swiss, Cozy, Mediterranean, Polished, Relaxed',
        annual_dol: 1718.14,
        description:
            'Relaxed haunt inspired by the Adriatic Sea serving coastal bites & wine in a wood-accented space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7266,
        store_longitude: -73.9862,
        total_orders: 277.64,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Lasagna, Salad, Italian, Wine, Tasty, Mediterranean, Rustic, Cozy, Delicious, Creamy',
        annual_dol: 289.68,
        description:
            'Cozy eatery with exposed brick walls, serving elevated Italian entrees, including housemade pasta.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8058,
        store_longitude: -73.9659,
        total_orders: 2469.35,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Steak, Burger, Chocolate Croissant, Salad, Italian, Wine, French, French Onion Soup, Organic, American',
        annual_dol: 2594.33,
        description:
            "Wine & cuisine from France's Loire Valley region in a bistro-style space with patio seating.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.9732,
        total_orders: 3852.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Whiskey, Beer, Cozy, Upscale, Friendly, Fun, Irish, Lively, Perfect',
        annual_dol: 3963.7,
        description:
            'Dimly lit multi-level bar with couches where TVs, video games & a pool table divert drinkers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.8292,
        total_orders: 2735.54,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Karaoke, Beer, Chinese, Luxurious, Fancy, Classy, Great, Excellent, Signature, Complex',
        annual_dol: 4079.78,
        description:
            'Flashy karaoke complex with a nightclub vibe, featuring private rooms, snacks & bottle service.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.9777,
        total_orders: 1741.8,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Whiskey, Wine, Beer, Southern Comfort, Southern, Tasty, Ale, Spicy, Casual, Coleslaw',
        annual_dol: 6114.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.9479,
        total_orders: 486.43,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Vegetarian, Vegan, Salad, Turkey Sandwich, Organic, Wine, Coffee, Gluten Free, Turkish, Georgian',
        annual_dol: 1757.33,
        description: '"We offer authentic Georgian cuisine and natural wines."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.972,
        total_orders: 78.05,
        neighborhood: 'Midtown Manhattan/Tudor City/Murray Hill',
        keywords: 'Hotel, Modern, Elegant, Classic, Great, Local',
        annual_dol: 106.07,
        description:
            'The Museum of Modern Art.Relaxed rooms with an elegant vibe come with flat-screen TVs, sitting areas and coffeemakers, as well as Wi-Fi (surcharge). Suites add separate living space; some provide furnished terraces or balconies with city views.Amenities include a laid-back restaurant, a down-to-earth lobby cafe and a gym, plus meeting and event space. Valet parking and breakfast are available for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.8337,
        total_orders: 133.89,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Beer, Wine, Asian, Chinese, Delicious, Classy, Casual, Local, Dim Sum',
        annual_dol: 242.72,
        description:
            'Bustling venue serving a standard selection of dim sum in a large, traditionally decorated space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.802,
        store_longitude: -73.6515,
        total_orders: 1773.61,
        neighborhood: '',
        keywords: 'Delicious, Savory, Gourmet, Exquisite, Chic',
        annual_dol: 1973.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7978,
        store_longitude: -73.6655,
        total_orders: 1359.98,
        neighborhood: '',
        keywords:
            'Italian, Delicious, Comfortable, Homemade, Perfect, Fancy, Traditional, Classic, Great, Fine',
        annual_dol: 1468.07,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6219,
        store_longitude: -74.0319,
        total_orders: 480.27,
        neighborhood: 'Fort Hamilton',
        keywords: 'Burger, Steak, Tacos, Sushi, Spicy, Delicious, Bbq, Caesar Wrap, Irish, Awesome',
        annual_dol: 485.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6801,
        store_longitude: -73.9417,
        total_orders: 399.13,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Burger, Pizza, Tasty, Salad, Delicious, Ale, Perfect, Lively, Excellent, American',
        annual_dol: 815.77,
        description:
            'Lively sports bar offering comfort food, cocktails & happy hours, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -73.9833,
        total_orders: 2165.25,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Vegetarian, Hamburger, Coffee, Beer, Whiskey, Irish, American, Casual, Friendly',
        annual_dol: 2275.92,
        description:
            'Irish pub serving burgers & pints in a setting with sawdust on the floor & a wood-burning fireplace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.9839,
        total_orders: 547.56,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Spanish, Stylish, Fun, Edgy, Cool, Clean',
        annual_dol: 683.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -74.0039,
        total_orders: 5065.86,
        neighborhood: 'Midtown Manhattan/Hudson Yards',
        keywords: 'Delicious, Premium, Impeccable, Gourmet, Great, Local',
        annual_dol: 6065.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9972,
        total_orders: 2021.52,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Salad, Delicious, Greek, Prosecco, Modern, American, Fresh, Traditional, Local',
        annual_dol: 6599.8,
        description:
            '"Kyma, the popular Greek Taverna with locations in Roslyn, Long Island and in the Flatiron district is expanding in New York City with its new location: Kyma Hudson Yards, scheduled to open mid October 2021. With Executive Chef George Pagonis at its helm (Top Chef Boston alum, Anassa Taverna), who executes traditional Greek dishes with modern flair, Kyma. The restaurant features 200 seats including two private rooms, a large circular bar with a cocktail program by Konstantinos Chantzis and outdoor seating."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6116,
        store_longitude: -74.0101,
        total_orders: 277.21,
        neighborhood: 'Bath Beach/Bensonhurst',
        keywords:
            'Steak, Italian, Salad, Wine, Ribeye Steak, Australian, Classic, Casual, Great, Wonderful',
        annual_dol: 316.2,
        description:
            'Long-standing Italian eatery known for homestyle classics & a long wine list, plus live opera music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7237,
        store_longitude: -74.0097,
        total_orders: 846.39,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Tequila, Mexican, Salad, Italian, Vodka, Argentinian, Gluten Free, Spanish, Gin',
        annual_dol: 864.35,
        description:
            'Argentinian bistro with Italian influences serves regional wines in rustic whitewashed space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7174,
        store_longitude: -74.0105,
        total_orders: 350.81,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Pizza, Italian, Meatball, Delicious, Trendy, Homemade, Bruschetta, Authentic, Fresh',
        annual_dol: 367.53,
        description:
            'Veal, meats & pastas highlight this Tuscan-inspired, wood-accented space with high-ceilings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7786,
        store_longitude: -73.9564,
        total_orders: 2709.38,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Hotdog, Pizza, Beer, Irish, Cozy, Fun, Relaxed, Friendly, Awesome, Cool',
        annual_dol: 2770.18,
        description:
            'Simple, long-standing Irish tavern with Seattle sports teams on TV, a pool table & free Wi-Fi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.813,
        store_longitude: -73.9287,
        total_orders: 56.79,
        neighborhood: 'Mott Haven',
        keywords:
            'Hotel, High-End, Funky, Comfortable, Relaxed, Great, Rooftop, Excellent, Latin, Fusion',
        annual_dol: 232.13,
        description:
            "Hip rooms feature Wi-Fi, flat-screen TVs and minifridges, as well as tea and coffeemaking facilities. Some have bunk beds. Room service is available.A restaurant features funky Pop Art-style decor. There's also a bar, a fitness room and a breakfast buffet, plus valet parking.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.995,
        total_orders: 1515.08,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Salad, Beer, Irish, Zesty, Friendly, Authentic, Casual, Great',
        annual_dol: 1624.97,
        description:
            'For decades, this corner bar near Penn Station has been drawing pints & plating simple pub fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.9824,
        total_orders: 220.66,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Vegetarian, Sushi, Salad, Italian, Japanese, Spicy, Sashimi, Hotel, African',
        annual_dol: 831.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.9899,
        total_orders: 969.83,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Milkshake, Wine, Tasty, French Toast, Beer, Ale, Mimosa, Avocado Toast, French',
        annual_dol: 1358.23,
        description:
            'Comfort food, cocktails & shakes, plus all-day brunch served in a happening hot spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -73.9873,
        total_orders: 6185.44,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Butter Chicken, Spicy, Delicious, Dessert, Cozy, Sweet, Gourmet, Fried Chicken, Eclectic',
        annual_dol: 6453.62,
        description:
            'Rustic-chic eatery with American comfort food, such as chicken & waffles, plus cocktails & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6924,
        store_longitude: -73.8588,
        total_orders: 232.16,
        neighborhood: 'Jamaica/Woodhaven',
        keywords:
            'Steak, Salad, Mexican, Wine, Beer, Dessert, Fried Calamari, Organic, Latin, Spanish',
        annual_dol: 253.02,
        description: '"Latin Fusion Cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9001,
        store_longitude: -73.8675,
        total_orders: 55.58,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords:
            'Pizza, Beer, Salad, Chili Con Carne, German, Ale, Irish, Western, Casual, Cultural',
        annual_dol: 150.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.9867,
        total_orders: 8.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Burger, Cheesecake, Tasty, Korean, Beer, Spicy, Delicious, American, Wine, Karaoke',
        annual_dol: 33.88,
        description:
            'Modern, dimly lit eatery offering Korean-American small plates plus cocktails, soju & makgeolli.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7741,
        store_longitude: -73.9081,
        total_orders: 10.73,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Pizza, Cheesecake, Italian, Salad, Dessert, Elegant, Sweet, Casual',
        annual_dol: 43.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6813,
        store_longitude: -73.9757,
        total_orders: 107.57,
        neighborhood: 'Park Slope',
        keywords: 'Chinese, Korean, Japanese, Asian, Bao Buns, Ramen, Casual, Local, Healthy',
        annual_dol: 439.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6857,
        store_longitude: -73.9862,
        total_orders: 2239.35,
        neighborhood: 'Boerum Hill',
        keywords: 'Pizza, Vegetarian, Salad, Italian, Swiss, Beer, Gin, Spicy, Cozy, Elegant',
        annual_dol: 2383.1,
        description:
            'Farm-to-table Northern Italian fare is served all day in this compact, lumber-lined restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6781,
        store_longitude: -73.9791,
        total_orders: 521.62,
        neighborhood: 'Park Slope',
        keywords:
            'Steak, Mexican, Colombian, Gluten Free, Spicy, Delicious, Latin, Warm, Dominican, American',
        annual_dol: 657.89,
        description:
            'Popular eatery offering Pan-Latin cuisine, tropical cocktails & colorful, artsy decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6403,
        store_longitude: -74.0756,
        total_orders: 845.45,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Burger, Salad, Dessert, Gluten Free, Tasty, Spanish, Yummy, Homemade, Sashimi, American',
        annual_dol: 1158.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7077,
        store_longitude: -74.0071,
        total_orders: 509.5,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegetarian, Wine, Salad, Italian, Hamburger, Dessert, Coffee, Flavorful, Avocado Toast, Beer',
        annual_dol: 658.16,
        description:
            'Sleek outpost with warm leather & wood decor offering Tuscan fare & a long list of Italian wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.9802,
        total_orders: 3563.21,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Tequila, Beer, Pizza, Tasty, Upscale, Fun, Lively, Fresh, Creative, Great',
        annual_dol: 3563.21,
        description:
            'Daytime haircuts & drinks in the front room morph into a lively back-room cocktail bar at night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7169,
        store_longitude: -73.9507,
        total_orders: 167.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegan, Wine, Salad, Coffee, Delicious, Roast Turkey, Cozy, Turkey Sandwich, Clean, Sweet',
        annual_dol: 631.62,
        description:
            'Innovative salads, bowls & sandwiches served in chill digs with a leafy backyard patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -73.9804,
        total_orders: 687.65,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Burger, Tacos, Pizza, Vegan, Gluten Free, Breakfast Burrito, Gourmet, Beer, Lemon Drop, Nachos',
        annual_dol: 764.01,
        description:
            '"An upscale sports lounge and event space with elevated food. Focused on Charity and Sports. Amenities include: A private loft with its own bar, a porch style patio, 30 Flat Screen TV\'s, and Home Made Infused Liquors."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7468,
        store_longitude: -73.9824,
        total_orders: 5147.57,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Hamburger, Salad, Sushi, Mexican, Dessert, Margarita, Tasty, American, Asian',
        annual_dol: 5340.21,
        description:
            '"The Ainsworth Midtown is a multipurpose space comprised of a full-scale restaurant, bar, lounge and event area. Ainsworth Midtown offers a new take on classic American fare favorites, signature cocktails, and visually stimulating décor, culminating in the ultimate drinking and dining experience."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.711,
        store_longitude: -73.965,
        total_orders: 803.68,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Tacos, Mexican, Steak, Tequila, Upscale, Tex-Mex, Delicious, Warm, Quaint, Fresh',
        annual_dol: 961.58,
        description:
            'Contemporary, upscale takes on Mexican classics in an unpretentious venue with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.9836,
        total_orders: 2245.35,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Burger, Gluten Free, Beer, Tasty, Dessert, Bbq, Hotel, American, Southern, Healthy',
        annual_dol: 8459.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9285,
        total_orders: 864.77,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Fun, Awesome, Premium, Perfect, Cool, Dutch',
        annual_dol: 902.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 6332.79,
        neighborhood: 'Bushwick',
        keywords:
            'Kebab, Beer, Wine, Turkish, Chinese, Mediterranean, Delicious, Fun, American, Seasonal',
        annual_dol: 7212.61,
        description:
            'Revival of a longtime Wisconsin supper club serving Middle Eastern dishes in an eccentric setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7321,
        store_longitude: -74.0012,
        total_orders: 2039.02,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Ale, Chinese, Irish, Picturesque, Friendly, Casual, Tradition, Great',
        annual_dol: 2082.28,
        description:
            'Casual spot where students & other Village denizens relax over a pint, poetry or live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.6368,
        total_orders: 66.97,
        neighborhood: '',
        keywords: 'Sushi, Salad, Vegan, Tasty, Spicy, Japanese, Delicious, Creamy, Chinese, Zen',
        annual_dol: 72.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7321,
        store_longitude: -73.8494,
        total_orders: 235.74,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Lobster, Salad, Sushi, Japanese, Upscale, Spicy, Asian, Warm, Bbq, Polished',
        annual_dol: 963.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.768,
        store_longitude: -73.9207,
        total_orders: 1757.52,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Sushi, Mexican, Beer, Thai, Japanese, Pinacolada, Latin, Puerto Rican, Screwdriver',
        annual_dol: 7138.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8537,
        store_longitude: -73.8885,
        total_orders: 449.4,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Burger, Italian, Delicious, Savory, Wine, Classy, Vibrant, Perfect, Exceptional, Great',
        annual_dol: 479.27,
        description:
            'Chef Roberto Paciullo’s destination Italian eatery serves Salerno specialties in old-style setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7662,
        store_longitude: -73.9626,
        total_orders: 1036.76,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Beer, Wine, American, Upscale, Cozy, Local, Classic, Casual, Diverse, Great',
        annual_dol: 1036.76,
        description:
            'Cozy local watering hole & back patio offering diverse American fare, beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.857,
        store_longitude: -73.7906,
        total_orders: 44.69,
        neighborhood: 'City Island',
        keywords: 'Garlic Bread, Lobster, Italian, Shrimp Scampi, Delicious, Casual',
        annual_dol: 111.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6623,
        store_longitude: -73.8062,
        total_orders: 7562.18,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Sushi, Pizza, Wine, Donut, Coffee, Delicious, Mediterranean, French, Organic, Sweet',
        annual_dol: 7722.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5223,
        store_longitude: -74.2391,
        total_orders: 162.52,
        neighborhood: 'Charleston',
        keywords:
            'Mexican, Spanish, Italian, Spicy, Chilean, Delicious, Mediterranean, Dominican, American, Lively',
        annual_dol: 631.18,
        description:
            '"Welcome to Lava! A Fusion Restaurant and Lounge located in Staten Island. We welcome you to enjoy our carefully crafted menu. Join us at the bar while our mixologist designs you the most perfect cocktail! Come out on the dance floor Thursday-Saturday for our after hours party! Delicious food, Outstanding ambiance, and attentive service is what makes Lava your new go to spot!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5964,
        store_longitude: -73.9777,
        total_orders: 585.63,
        neighborhood: 'Gravesend',
        keywords:
            'Tequila, Sushi, Wine, Vodka, Tasty, Shrimp Cocktail, Martini, Delicious, Italian, Warm',
        annual_dol: 623.22,
        description:
            'Low-lit nightspot with a warm vibe for cocktails, hookahs & American fare, plus belly dancers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8541,
        store_longitude: -73.8718,
        total_orders: 48.57,
        neighborhood: '',
        keywords:
            'Lobster, Chinese, Jamaican, Caribbean, Flavorful, Bbq, Sweet, Local, Casual, Tempura',
        annual_dol: 198.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7291,
        store_longitude: -73.9853,
        total_orders: 201.28,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Sushi, Japanese, Beer, Modern, Seasonal, Casual, Omelette, Local, Rooftop',
        annual_dol: 822.78,
        description:
            'Japanese tasting menus highlighting ingredients of the season are presented in an intimate setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8475,
        store_longitude: -73.9384,
        total_orders: 839.34,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Vegan, Steak, Wine, Italian, Savory, American, Spanish, Peking Duck, Natural, Latin',
        annual_dol: 1331.88,
        description:
            'Inventive cocktails & Caribbean-inspired vegan small plates served in sleek, dimly lit surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6098,
        store_longitude: -74.0632,
        total_orders: 59.21,
        neighborhood: 'Rosebank',
        keywords: 'Beer, Wine, American, Welcoming, Fun, Casual, Cool, Local, Sangria, Attentive',
        annual_dol: 167.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9729,
        total_orders: 1121.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Flavorsome, Italian, Salad, Wine, Upscale, Delicious, Elegant, Meatball, Hotel',
        annual_dol: 1285.81,
        description:
            'Swanky bar/eatery at the Bernic Hotel with an old-world vibe & traditional Northern Italian entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7515,
        store_longitude: -73.9708,
        total_orders: 1538.7,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Italian, Coffee, Danish, American, Elegant, Classic',
        annual_dol: 5439.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8844,
        store_longitude: -73.9004,
        total_orders: 2525.61,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'French, Beer, New-York-Style, Chicken Wings, Traditional, Sweet, Local, Gyro',
        annual_dol: 2579.2,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8236,
        store_longitude: -73.9522,
        total_orders: 98.86,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Steak, Wine, Salad, Bourbon, Cuban, Beer, Savory, Delicious, Hotel, Premium',
        annual_dol: 182.32,
        description:
            '"Cozy place with a great crafted wine selections, good beers, savory cocktails and a appealing food menu. Convenient access near the 1 train. Plus the convenience of the excellent cigar lounge Harlem Cigar Room next door. Perfect for a date night."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.9751,
        total_orders: 494.45,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Lobster, Salad, Pizza, Italian, Dessert, Quaint, Homemade, Hotel, Modern',
        annual_dol: 518.83,
        description:
            'Popular family-run Tuscan restaurant serving lasagna, pizza & more in a quaint setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9684,
        total_orders: 1636.39,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Steak, Korean, Beer, Organic, Fried Chicken, Ribeye Steak, Wine, Asian, Fusion',
        annual_dol: 1831.91,
        description:
            'Sleek, casual haunt serving Korean fried chicken & other traditional eats, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6127,
        store_longitude: -74.0339,
        total_orders: 2886.84,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Pizza, Italian, Salad, Gluten Free, Dessert, Delicious, American, Perfect, Authentic, Casual',
        annual_dol: 3459.05,
        description:
            'This colorful, chandelier-lit bistro offers classic Italian cuisine & seafood, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9661,
        total_orders: 181.13,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Beer, Friendly, Casual, Lively, Local, Great, Rooftop',
        annual_dol: 836.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.9692,
        total_orders: 100.21,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Beer, Spicy, Margarita, Delicious, Irish, Fun, Smoky, American, Friendly',
        annual_dol: 409.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.9946,
        total_orders: 1560.61,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Flavorful, Premium, Sophisticated, Fantastic, Great, Fresh, Creative, Contemporary',
        annual_dol: 1714.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9802,
        total_orders: 1336.75,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Vegetarian, Vegan, Tasty, Chinese, Delicious, Spicy, Friendly, Asian, Casual, Local',
        annual_dol: 1424.59,
        description:
            'Cozy, hip spot featuring Chinese dry pot with a choice of spice level, plus appetizers & dim sum.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9846,
        total_orders: 2620.63,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Salad, Tacos, Cajun, Bbq, Beer, Italian, Southern, Creole, Friendly, Sweet',
        annual_dol: 2676.24,
        description:
            'Long-standing BBQ operation turning out classic meat dishes, plus sides & craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6979,
        store_longitude: -73.9917,
        total_orders: 622.88,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Roast Turkey, Milkshake, Canadian, Apple Pie, Bbq, Swiss',
        annual_dol: 737.69,
        description:
            'Family-owned diner at the foot of the Brooklyn Bridge offers standard fare from pies to omelettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7584,
        store_longitude: -73.9929,
        total_orders: 1854.96,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Salad, Italian, Vodka, Hotel, Trendy, Spicy, Modern, Homemade, Chic, Classic',
        annual_dol: 6511.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8631,
        store_longitude: -73.8436,
        total_orders: 189.18,
        neighborhood: 'Pelham Gardens/East Bronx',
        keywords:
            'Lobster, Lobster Tail, Steak, Salad, Shrimp Cocktail, Italian, Pizza, Romantic, Casual, Sweet',
        annual_dol: 198.83,
        description:
            'No-frills, family restaurant focuses on large portions of classic Italian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6828,
        store_longitude: -73.9382,
        total_orders: 539.79,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Vegan, Vegetarian, Salad, Chicken Pot Pie, Coffee, French Toast, French, Delicious, Bbq, Cuban',
        annual_dol: 588.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -74.0009,
        total_orders: 3166.95,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Gluten Free, Bbq, Delicious, Homemade, American, Classic, Perfect, Authentic, Great',
        annual_dol: 3234.15,
        description:
            'Beers & bar snacks are offered along with sports on 17 screens at this collegiate watering hole.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7884,
        store_longitude: -73.7294,
        total_orders: 14.46,
        neighborhood: '',
        keywords: 'Burger, Vegan, Mexican, Trendy, Bbq, Chinese, Wine, Vietnamese, Fun, Thai',
        annual_dol: 211.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7738,
        store_longitude: -73.9844,
        total_orders: 80.91,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Tequila, Vodka, Salad, Coffee, Dessert, Stylish, Margarita, Italian, Delicious',
        annual_dol: 94.31,
        description:
            "Warm, stylish venue inside Lincoln Center's Film Society dishing up Italian fare, cocktails & wine.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.632,
        store_longitude: -73.9478,
        total_orders: 50.32,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Mexican, Tacos, Beer, Burritos, Salad, Wine, Delicious, Local, Casual, Recommended',
        annual_dol: 205.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8029,
        store_longitude: -73.9567,
        total_orders: 155.26,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Tequila, Mexican, Tacos, Beer, Margarita, Steak, Wine, Pizza, Burritos, Rooftop',
        annual_dol: 421.94,
        description:
            'Casual Mexican joint serving up tacos & burritos along with drinks from the bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7668,
        store_longitude: -73.7899,
        total_orders: 103.95,
        neighborhood: 'Bayside/Auburndale/Flushing',
        keywords:
            'Pizza, Meatball, Italian, Shrimp Cocktail, Vodka, Dessert, Coffee, Delicious, Fresh, Casual',
        annual_dol: 128.54,
        description:
            'No-frills pizzeria with many styles of pie, plus a menu of Italian entrees, pasta, heros & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9894,
        total_orders: 821.85,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Salad, Tasty, French, Wine, French Onion Soup, Beer, Delicious, Magical',
        annual_dol: 839.29,
        description:
            'Longtime French bistro serving classic fare from fixed-price menus in an old-world, rustic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9901,
        total_orders: 820.94,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Flavorful, Savory, Caribbean, Sweet, Cuban, Jamaican, Phenomenal, Friendly, Polished, Hotel',
        annual_dol: 851.97,
        description:
            '"Escape the madness of Midtown Manhattan at Jasmine\'s Caribbean Cuisine. Enjoy delectable authentic dishes, flavorful drinks, and great music."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7398,
        store_longitude: -73.9879,
        total_orders: 2060.48,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Korean, Steak, Delicious, Wine, Polished, American, Modern, Exquisite, Classic, Impressive',
        annual_dol: 2952,
        description:
            'Modern setting for multicourse Korean tasting menus featuring wood-fired dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.9991,
        total_orders: 40.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Spacious, Awesome, Amazing, Rooftop, Gracious, Signature',
        annual_dol: 66.37,
        description:
            '"Life Time Sky is more than a gym, it’s an athletic country club. Life Time has something for everyone: an expansive fitness floor, unlimited studio classes, basketball courts, eucalyptus steam rooms, and indoor and outdoor pools. Receive unlimited group training and individualized attention when you choose a Signature Membership. Or get started at home with our Digital Membership, featuring virtual training programs, a library of on-demand class videos and award-winning health and wellness content. Build your membership today."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6896,
        store_longitude: -73.9693,
        total_orders: 1555.41,
        neighborhood: 'Clinton Hill',
        keywords: 'Steak, Salad, Wine, French, Beer, Delicious, Irish, American, Fun, Fresh',
        annual_dol: 1588.41,
        description:
            'Vintage-looking saloon with a large draft beer selection & an expansive menu of bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9913,
        total_orders: 512.89,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Mexican, Pizza, Shrimp Cocktail, Japanese, Korean, Savory, Bbq, American, Asian',
        annual_dol: 2096.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.989,
        total_orders: 230.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vegetarian, Steak, Lobster, Pizza, Chinese, Wine, Beer, Spicy, Delicious, Asian',
        annual_dol: 940.85,
        description:
            'Easygoing restaurant serving an array of dim sum favorites, along with classic Chinese entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.982,
        total_orders: 775.83,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Trendy, Stylish, Elegant, Classic, American, French, Serene, Great',
        annual_dol: 1078.03,
        description:
            "Classic rooms feature soundproof windows, marble baths and high-end linens, plus free Wi-Fi, minibars and flat-screen TVs. Upgraded rooms add turndown service and desks. Suites provide whirlpool baths, additional TVs and living rooms.There's a lively, upscale restaurant serving a new French menu, and a stylish wood-paneled cocktail salon offering small plates. There's also a 24-hour fitness center, in-room massages or yoga, and a business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6905,
        store_longitude: -73.9833,
        total_orders: 226.74,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Wine, Beer, Gourmet, Italian, Dessert, Donut, Delicious, Hotel, Pakistani, Awesome',
        annual_dol: 926.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6775,
        store_longitude: -73.9827,
        total_orders: 214.21,
        neighborhood: 'Park Slope',
        keywords:
            'Italian, Gluten Free, Wine, Spicy, Delicious, Asian, Intimate, Paloma, Awesome, Fusion',
        annual_dol: 262.83,
        description:
            'Serves traditional Sicilian dishes, cured meats & cheeses, plus weekend brunch & Italian wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6782,
        store_longitude: -73.9834,
        total_orders: 1007.14,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Wine, Ale, Cozy, Casual, Vibrant, Awesome, Great, Unpretentious',
        annual_dol: 1050.81,
        description:
            'Unpretentious heavy-metal bar featuring live music, high-energy bartenders & bar-top dancers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6783,
        store_longitude: -73.9731,
        total_orders: 962.67,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Salad, Mexican, Vegetarian, Tasty, Margarita, Wine, Burritos, Tex-Mex, Delicious, Warm',
        annual_dol: 1200.95,
        description:
            'Burritos & margaritas fuel a festive scene at this Park Slope spot with ample outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.9961,
        total_orders: 1681.2,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Steak, Japanese, Sashimi, Spicy, Tasty, Teriyaki, Seasonal, Tempura, Premium',
        annual_dol: 1938.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -73.9975,
        total_orders: 111.47,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Sushi, Salad, Tasty, Gourmet, Wine, Beer, Thai, Malaysian, Delicious, Rich',
        annual_dol: 143.99,
        description:
            'Casual restaurant with a full Japanese menu, including sushi, plus some Malaysian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7118,
        store_longitude: -73.9514,
        total_orders: 821.65,
        neighborhood: 'Williamsburg',
        keywords: 'Coffee, Wine, Velvety, Beer, Rich, Creamy, Hotel, Comfortable, Rum, French',
        annual_dol: 843.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7126,
        store_longitude: -73.9561,
        total_orders: 22.79,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, French Toast, German, French, Swiss, Modern, Creamy, American, Delicious, Classic',
        annual_dol: 93.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7131,
        store_longitude: -73.9574,
        total_orders: 16.73,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Steak, Wine, Californian, Beer, Organic, American, Premium, Natural, Smooth',
        annual_dol: 68.4,
        description:
            'Wine bar from James Murphy of LCD Soundsystem serving small plates in snug digs with light woodwork.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7139,
        store_longitude: -73.9603,
        total_orders: 10.56,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Thai, Salad, Gourmet, Spicy, Asian, Zen, Traditional, Pad Thai, Classic, Papaya Salad',
        annual_dol: 43.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.744,
        store_longitude: -73.9871,
        total_orders: 129.27,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Hotel, Champagne, Wine, Australian, Bright, Gin, Classic, Modern, Great, Excellent',
        annual_dol: 528.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9817,
        total_orders: 3879.1,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Lobster, Gourmet, Chinese, Spicy, Wine, Wonton Soup, Refined, Classy, Kung Pao, Traditional',
        annual_dol: 4146.01,
        description:
            'Refined option for acclaimed Sichuan fare in a vintage-inspired atmosphere, plus delivery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7208,
        store_longitude: -73.9578,
        total_orders: 1586.48,
        neighborhood: 'Williamsburg',
        keywords: 'Luxurious, Fun, Fancy, Vintage, Excellent, Local, Casual',
        annual_dol: 1938.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7167,
        store_longitude: -73.9654,
        total_orders: 43.82,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Beer, Coffee, Wine, Rum, Fun, Irresistible, Cool, Casual, Michelada',
        annual_dol: 179.13,
        description:
            '"Cactus shop by day, Mexican cantina by night. It takes getting lost to find your spirit. A place where culture and flavors collide, The Cactus Shop brings a little piece of Mexico to Williamsburg. From the elevated street food to the disarming decor and irresistible ambiance, The Cactus Shop is sure to set the tone for a new era of blended venues that blur the lines between bar, bistro, and boutique. Find out more about the grand opening of The Cactus Shop on our website."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7748,
        store_longitude: -73.9537,
        total_orders: 1012.14,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Cozy, Fun, Intimate, Perfect, Great, Casual, Local',
        annual_dol: 1103.08,
        description:
            'The Comic Strip Live is the oldest stand-up comedy showcase club in New York City, located at 1568 Second Avenue.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7458,
        store_longitude: -73.9035,
        total_orders: 86.32,
        neighborhood: 'Flushing/Woodside',
        keywords:
            'Sushi, Japanese, Spicy, Ramen, Modern, Gyoza, Traditional, Casual, Tempura, Perfect',
        annual_dol: 96.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8477,
        store_longitude: -73.8446,
        total_orders: 944.69,
        neighborhood: 'Morris Park/East Bronx',
        keywords: 'Italian, Hotel, Trendy, Elegant, Modern, Great, Friendly, Caribbean',
        annual_dol: 985.65,
        description:
            "Stylish, elegant suites feature kitchens, and separate living areas with pull-out sofas, as well as free Wi-Fi, flat-screen TVs and desks.Complimentary perks include a breakfast buffet and parking. There's a trendy lobby with a modern fireplace, plus a fitness center, and a patio featuring a fire pit.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7337,
        store_longitude: -74.0025,
        total_orders: 278.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Tequila, Tacos, Beer, Margarita, Nachos, Quesadillas, Dessert, American, Latin',
        annual_dol: 302.44,
        description:
            'Low-lit Mexican cantina known for inventive fare, creative drinks & happy-hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7256,
        store_longitude: -73.6343,
        total_orders: 274.88,
        neighborhood: '',
        keywords:
            'Tasty, Gluten Free, Sushi, Mexican, Chinese, Delicious, Upscale, Italian, Elegant, Clean',
        annual_dol: 315.33,
        description:
            "Upscale specialties like Peking duck are artfully presented in a mirrored, '80s-era setting.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7877,
        store_longitude: -73.9767,
        total_orders: 1785.4,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Coffee, French Toast, Wine, French, Beer, Italian, Dessert, American, Vintage',
        annual_dol: 1914.62,
        description:
            'Part of a local cafe chain offering drinks, coffee, dessert & French bistro fare 24/7.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8415,
        store_longitude: -73.9412,
        total_orders: 317.02,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Dessert, Ramen, Perfect, Awesome, Great, Fresh, Intimate, Fine, Local',
        annual_dol: 366.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7843,
        store_longitude: -73.9777,
        total_orders: 894.81,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Salad, Beer, Warm, Ale, Fun, Nachos, Friendly, Bbq, Fancy, Irish',
        annual_dol: 954.3,
        description:
            'Basic bar along the Amsterdam Avenue strip frequented by a young crowd for beer & foosball.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.745,
        store_longitude: -73.9069,
        total_orders: 10.81,
        neighborhood: '',
        keywords: 'German, Spanish, Colombian, Local, Casual',
        annual_dol: 44.2,
        description:
            '"Bar para mirar todo tipo de deportes ,escuchar musica ,y pasar un momento grato con familiares y amistades"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7677,
        store_longitude: -73.9594,
        total_orders: 846.35,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Flavorful, Vodka, Italian, Stylish, Upscale, Martini, Spicy, French, Prosecco',
        annual_dol: 883.04,
        description:
            'Italian staples including housemade pasta dishes served in a casual but stylish space with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9694,
        total_orders: 1276.13,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Chinese, Golfclub, Georgian, Coffee, Cosmopolitan, Friendly, Casual',
        annual_dol: 1331.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9577,
        total_orders: 229.47,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Pizza, Italian, Salad, Wine, Dessert, Ale, Modern, Legendary, Awesome, Fresh',
        annual_dol: 258.66,
        description:
            'Informal pizza parlor offering brick-oven pies along with salads, pastas & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7381,
        store_longitude: -73.8862,
        total_orders: 8994.88,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Romantic, Rich, Exotic, Premium, Casual, Signature',
        annual_dol: 9185.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7344,
        store_longitude: -73.9936,
        total_orders: 262.19,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegetarian, Wine, Organic, Dessert, French, American, Casual, Modern, Delicious, Seasonal',
        annual_dol: 273.56,
        description:
            "A New American menu based on farmer's market ingredients served in a modern, woodsy restaurant.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6748,
        store_longitude: -73.9997,
        total_orders: 185.78,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Pizza, Steak, Italian, Wine, Meatball, Beer, Trendy, Prime Rib, Cozy, Ribeye Steak',
        annual_dol: 759.42,
        description: '"Italian restaurant with brick oven pizza , backyard and full bar."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7708,
        store_longitude: -73.8358,
        total_orders: 650.21,
        neighborhood: 'Flushing',
        keywords: 'Clean, Comfortable, Fun, Great, Premium, Fresh, Classic, Nachos',
        annual_dol: 723.42,
        description:
            '"See movies the way they are meant to be seen with Showcase Cinemas. Now showing Black Panther Wakanda Forever, The Menu, She Said, & more. Advanced bookings now open for Avatar The Way of Water Showcase Cinemas Holiday Special - $5 bonus concessions gift card with $25 gift card purchase"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8005,
        store_longitude: -73.6485,
        total_orders: 265.58,
        neighborhood: '',
        keywords:
            'Vegetarian, Steak, Cheesesteak, Salad, Wine, Mexican, Beer, Shrimp Cocktail, Organic, Ale',
        annual_dol: 277.09,
        description:
            'Area-sourced New American cuisine served in a fashionable setting with a waterside patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7113,
        store_longitude: -74.0105,
        total_orders: 1422.86,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Luxurious, Cosmopolitan, Hotel, Elegant, Fabulous, Amazing, American, Excellent, Modern, Chic',
        annual_dol: 1526.91,
        description:
            "National September 11 Memorial & Museum and 9 minutes' walk from Wall Street.Warmly furnished rooms, some with skyline views, offer flat-screen TVs and Wi-Fi and coffeemakers. Suites add sitting areas with pull-out sofas. Room service is available.Amenities include an elegant restaurant with a cocktail lounge, as well as a fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8592,
        store_longitude: -73.5916,
        total_orders: 426.75,
        neighborhood: '',
        keywords: 'Burger, American',
        annual_dol: 455.12,
        description:
            'Piping Rock Club is a country club in Matinecock, New York. It falls within the ZIP Code boundaries of Locust Valley, New York.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6205,
        store_longitude: -74.0321,
        total_orders: 64.78,
        neighborhood: 'Fort Hamilton',
        keywords: 'Pizza, Shrimp Cocktail, Fun, Healthy, Local, Casual, American, Great',
        annual_dol: 332.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7813,
        store_longitude: -73.9491,
        total_orders: 1847.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Wine, Vodka, Beer, Steak, Salad, Avocado Toast, Spicy, Irish, Cozy, Rustic',
        annual_dol: 1847.47,
        description:
            'British pub fare with inventive twists, plus craft beers & cocktails, served in cozy, rustic digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6737,
        store_longitude: -73.9503,
        total_orders: 2449.93,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Vodka, Rum, Sweet, Friendly, Fun, Vintage, Perfect, Cool, Colorful',
        annual_dol: 2667.45,
        description:
            'Casual spot for tiki drinks & frozen cocktails with colorful garnishes, plus a few bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7034,
        store_longitude: -73.9917,
        total_orders: 6133.53,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Italian, Hotel, Mediterranean, Modern, Classic, American, Authentic, Outstanding, Casual, Recommended',
        annual_dol: 6319.84,
        description:
            'Wood-fired pizzas, pastas & other Italian dishes are complemented by cocktails & waterfront views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9845,
        total_orders: 2989.38,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Glamorous, Hotel, Polished, Premium, Superb, Urban, Great, Modern, Chic, Friendly',
        annual_dol: 3118.99,
        description:
            "The polished rooms have Wi-Fi (fee), flat-screen TVs with premium channels, and desks with ergonomic chairs, plus minifridges and coffeemakers. Club rooms and suites come with access to a lounge serving free breakfast and snacks. Suites also add separate living rooms with pull-out sofas.There's a chic restaurant and a lounge with views of Times Square. Other amenities include an exercise room, a business center and 5 meeting rooms. Parking is available for a fee.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7401,
        store_longitude: -73.9825,
        total_orders: 444.07,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Hotdog, Vegan, Salad, Beer, Wine, Tasty, Whiskey, Delicious, Irish, Friendly',
        annual_dol: 472.57,
        description:
            'Chill, UK-inspired tavern featuring classic British comfort grub, beer & a small grocery store.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6624,
        store_longitude: -73.6993,
        total_orders: 53.92,
        neighborhood: '',
        keywords: 'Burger, Beer, Friendly, Local, Premium, Casual, Great',
        annual_dol: 75.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9925,
        total_orders: 25767.59,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Wine, Italian, Bourbon, Rooftop, Sophisticated, Lively, Bbq, Casual',
        annual_dol: 60184.83,
        description:
            "walk from the Empire State Building.Polished rooms provide free Wi-Fi, flat-screens, and tea and coffeemakers; upgraded rooms add city views. Suites have separate living rooms, plus minifridges. Room service is available.Breakfast is offered for a fee. There's a casual Italian restaurant, an airy lobby lounge, and a 24-hour gym. A rooftop terrace with a lounge features city views. A ballroom and 7,326 sq ft of meeting space are available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -73.9931,
        total_orders: 474.48,
        neighborhood: 'Bowery',
        keywords:
            'Tequila, Champagne, Mexican, Flavorful, Margarita, Savory, Delicious, Nachos, Fun, Exotic',
        annual_dol: 596.9,
        description:
            '"Located in the Lower East Side, Tampico Tequila bar brings to life the sentiments of travel by presenting fun takes on traditional cocktails and a menu inspired by both Mexican taquerias and NYC food trucks."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.9915,
        total_orders: 1462.2,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Hotdog, Cheesesteak, Beer, Martini, Tasty, Bbq, Philly Cheesesteak, American, Fun',
        annual_dol: 4847.3,
        description:
            'Budget pub grub & brews are served at this bustling bar with TVs for watching sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9881,
        total_orders: 596.43,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Shrimp Cocktail, South American, Flavorful, Wine, Upscale, Organic, American, Ribeye Steak, Latin',
        annual_dol: 694.2,
        description:
            'Upscale place for South American steaks & other Latin dishes, with a patio & sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9818,
        total_orders: 591.75,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Burger, Salad, Wine, Beer, French, Irish, American, Eclectic, Healthy',
        annual_dol: 2240.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7631,
        store_longitude: -73.98,
        total_orders: 2679.73,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Glamorous, Iconic, Fabulous, Perfect, Chill, Heritage, Artistic, Mouth-Watering',
        annual_dol: 2857.87,
        description:
            '"Welcome to Ziegfeld Ballroom, New York City’s premier special events venue. Conjuring the elegance and grandeur of the 1920s movie palaces that once lined Broadway and Seventh Avenue, Ziegfeld Ballroom is a Manhattan mecca for society galas and corporate events. Our Art Deco-inspired space is as iconic as the historic theatre from which it takes its name: both steeped in heritage and equipped with cutting edge technology, Ziegfeld Ballroom is the intersection of glamorous days-gone-by and of-the-moment modernity."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7328,
        store_longitude: -73.9972,
        total_orders: 17399.34,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Whiskey, Wine, Beer, Bourbon, Tasty, Gin, Stylish, Delicious, American, Classy',
        annual_dol: 18652.03,
        description:
            'Atmospheric watering hole serving cocktails & nibbles in a sultry, sophisticated setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7869,
        store_longitude: -73.814,
        total_orders: 497.01,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Salad, Shrimp Cocktail, Italian, Chinese, Friendly, Fresh, Amazing, Wonderful, Caribbean, Southern',
        annual_dol: 624.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6791,
        store_longitude: -73.9737,
        total_orders: 506.35,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Burger, Salad, Nachos, Funky, Homemade, Sweet, Bruschetta, Fantastic, Excellent, Great',
        annual_dol: 1907.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.9958,
        total_orders: 128.76,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Salad, Coffee, Savory, Korean, Delicious, Spicy, Yummy, Sweet, Fun, Perfect',
        annual_dol: 526.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.6923,
        total_orders: 660.57,
        neighborhood: '',
        keywords:
            'Tacos, Tequila, Mexican, Steak, Vegetarian, Salad, Nachos, Margarita, Cuban, Mimosa',
        annual_dol: 803.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6308,
        store_longitude: -74.1021,
        total_orders: 11.39,
        neighborhood: 'Mid Island/West Brighton',
        keywords: 'Salad, Wine, Tasty, Italian, Mexican, Beer, Asian, American, Classic, Casual',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7115,
        store_longitude: -73.9491,
        total_orders: 5.36,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Italian, Flavorful, Salad, Meatball, Vodka, Beer, Delicious, Wine, Shrimp Scampi',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9244,
        total_orders: 851,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Cheesecake, Chocolate Cake, Pizza, Salad, Steak, Coffee, Wine, Dessert, Beer, Savory',
        annual_dol: 956.94,
        description:
            'Local Greek chain with a late-night scene doling out traditional sweets & savory dishes since 1977.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9862,
        total_orders: 79.09,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, Vodka, Beer, Coffee, Martini, Margarita, Delicious, Hotel, Wholesome, Clean',
        annual_dol: 323.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.9789,
        total_orders: 8846.65,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Coffee, Salad, Pizza, Hotel, Elegant, Sustainable, Eclectic, Innovative, Iconic',
        annual_dol: 34494.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9063,
        store_longitude: -73.9041,
        total_orders: 590.88,
        neighborhood: 'North Riverdale',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Whiskey, Mexican, Italian, French, Dessert, French Onion Soup, American',
        annual_dol: 621.04,
        description:
            'Established Irish bar & eatery with a warm, old-world vibe providing steak, fish & Sunday brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.9871,
        total_orders: 1152.45,
        neighborhood: 'Lower East Side',
        keywords:
            'Mexican, Salad, Beer, Nachos, Italian, Delicious, Japanese, Elegant, American, Greek',
        annual_dol: 1200.68,
        description:
            'Stalactites hang from the ceiling at this cavelike basement dance club with hookahs at the bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7182,
        store_longitude: -73.9899,
        total_orders: 192.91,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegan, Cheesecake, Wine, Pizza, Vegetarian, Delicious, American, Premium, Vibrant, Memorable',
        annual_dol: 677.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.693,
        store_longitude: -73.9703,
        total_orders: 50.32,
        neighborhood: 'Clinton Hill',
        keywords: 'Mexican, Tacos, Salad, Organic, Casual, Healthy, Local, High-Protein, Signature',
        annual_dol: 328.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.632,
        store_longitude: -73.5815,
        total_orders: 151.05,
        neighborhood: '',
        keywords:
            'Burger, Cheesecake, Dessert, Delicious, Bourbon, Fun, Hurricane, Clean, Fresh, Southern',
        annual_dol: 462.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7328,
        store_longitude: -73.9973,
        total_orders: 787.75,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Cheesecake, Burger, Salad, Vegetarian, Wine, Italian, Trendy, Upscale, Delicious, Seasonal',
        annual_dol: 1201.8,
        description:
            'Upscale-casual American restaurant for seasonal, wood-fired dishes, handmade pastas & pizzas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6786,
        store_longitude: -73.9787,
        total_orders: 265.73,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Meatball, Vegan, Greek, Flavorful, Wine, Mediterranean, Prosecco, American, Fusion',
        annual_dol: 394.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8238,
        store_longitude: -73.8947,
        total_orders: 21.63,
        neighborhood: 'Foxhurst',
        keywords: 'Mexican, Dominican, Dessert, Creamy, American, Casual, Latin, Authentic',
        annual_dol: 88.4,
        description: '"Bar & Grill Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7182,
        store_longitude: -73.9452,
        total_orders: 2401.15,
        neighborhood: 'Williamsburg',
        keywords: 'Whiskey, Beer, Premium, American, Tasty, Awesome, Great, Authentic',
        annual_dol: 2424.95,
        description:
            'Comfy, stand-up spot serving brown spirits & cocktails with grilled cheese & snacks until late.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.6922,
        total_orders: 131.64,
        neighborhood: '',
        keywords: 'Hamburger, American, Casual, Traditional, Great',
        annual_dol: 165.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7064,
        store_longitude: -74.0031,
        total_orders: 15511.12,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Italian, Dessert, Rich, American, Memorable, Iconic, Fresh, Fine, Casual',
        annual_dol: 63404.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6799,
        store_longitude: -73.9683,
        total_orders: 689.21,
        neighborhood: 'Prospect Heights',
        keywords:
            'Burger, Garlic Bread, Meatloaf, Salad, Mexican, Shrimp Cocktail, Beer, Wine, Italian, American',
        annual_dol: 734.99,
        description:
            "\"Patti Ann’s is the latest neighborhood restaurant from chef-owner Greg Baxtrom (of acclaimed Olmsted and Maison Yaki). Chef Greg marries the nostalgic Midwestern comfort food of his childhood with the fine-dining techniques learned throughout his career to offer a menu that's totally different from his other restaurants. At Patti Ann's (an homage to Greg's mother, Patti Ann) you'll find everything from a Blooming Onion (served with fancy ranch dressing) to Baked Mostaccioli on the menu. In addition, there is a Bakery and General Store attached which houses your favorite farmer's market staples along with fresh baked bread, pastries, and coffee.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7438,
        store_longitude: -73.9848,
        total_orders: 1747.93,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Burger, Tacos, Meatball, Beer, Salad, Bourbon, Juicy, French, American, Caesar Salad',
        annual_dol: 1790.61,
        description:
            'Snug, busy bar offering finger foods, burgers & tacos, plus cocktails, beer & a variety of whiskies.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9236,
        total_orders: 3085.51,
        neighborhood: 'Astoria/LIC',
        keywords: 'Wine, Italian, Trendy, Fun, Intimate, Friendly, Awesome, Great, Casual',
        annual_dol: 3823.78,
        description:
            'Festive gay bar featuring DJs, go-go dancers & drag shows, plus movie & game nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.745,
        store_longitude: -73.9851,
        total_orders: 3905.02,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Pizza, Korean, Wine, Instagrammable, American, Asian, Hotel, Impeccable, French, Seasonal',
        annual_dol: 4213.81,
        description:
            'Intimate eatery spotlighting reimagined provisions with a Korean twist, plus a large wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -73.9821,
        total_orders: 686.82,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Beer, Gourmet, Irish, Korean, Bbq, Homemade, Thai, Traditional, British, Local',
        annual_dol: 705.33,
        description:
            'Old-world taproom offering bar food & craft beers, plus a gourmet market with Irish & British goods.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9296,
        total_orders: 780.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Champagne, Wine, Sushi, Beer, Eclectic, Latin, Intimate, Fusion, Exquisite, Fun',
        annual_dol: 3188.74,
        description:
            'DJs spin eclectic grooves at this colorful, Middle-Eastern themed cocktail & hookah lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9807,
        total_orders: 4651.81,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Champagne, Warm, Cozy, Fun, Friendly, French, Cool, Irish, Fresh, Great',
        annual_dol: 4764.42,
        description:
            'Lounge with a contemporary feel dishing up sandwiches & pub food for a Midtown after-work crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.978,
        total_orders: 290.98,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Elegant, High-End, Iconic, Premium, Classic, Sophisticated, Great, Perfect, Exceptional',
        annual_dol: 317.13,
        description:
            '"Convene at 75 Rockefeller Plaza is a premium meeting and event space located in the iconic Rockefeller Center in Midtown Manhattan. This location offers on-demand meeting, event and flexible workspace powered by state-of-the-art technology and best-in-class hospitality. Beautifully-designed and inspired by the original art deco style of the building, it incorporates modern accents to evoke an intimate and elegant setting to work and meet."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7194,
        store_longitude: -73.9935,
        total_orders: 6557.69,
        neighborhood: 'Bowery',
        keywords: 'Wine, Sweet, Cozy, Beer, Perfect, Lively, Great, Lit, Casual, Unique',
        annual_dol: 6698.32,
        description:
            'Music & assorted knickknacks enliven this underground, off-the-beaten-path barroom.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7227,
        store_longitude: -73.9889,
        total_orders: 55.4,
        neighborhood: 'Lower East Side',
        keywords:
            'Cuban, Dessert, Homemade, Colombian, American, Swiss, Fusion, Fancy, Local, Casual',
        annual_dol: 363.31,
        description:
            '"Mi Salsa Kitchen offers authentic Cuban Cuisine and Latin Cuisine, great drinks and friendly staff. Live Music Tuesday to Sunday. Great for groups."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6813,
        store_longitude: -73.977,
        total_orders: 1098.33,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Beer, Whiskey, Wine, Delicious, Coffee, Spacious, Yummy, Homemade, Fun',
        annual_dol: 1470.44,
        description:
            'Comfy tavern offering a full bar, craft beer on tap & pub grub, with multiple TVs for sports fans.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7321,
        store_longitude: -73.9997,
        total_orders: 76.14,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegan, Vegetarian, Wine, Beer, Gluten Free, Elegant, American, Friendly, Casual, Premium',
        annual_dol: 82.98,
        description:
            'Locally sourced, seasonal ingredients abound on the American menu served at this townhouse-set spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7112,
        store_longitude: -73.9475,
        total_orders: 3739.44,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Vodka, Whiskey, Wine, Beer, Ale, Upscale, Great, Chill',
        annual_dol: 3858.9,
        description:
            'Ironically named bar featuring cheap drinks, a photo booth & a six-hole mini-golf course.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7253,
        store_longitude: -73.9843,
        total_orders: 1465.03,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Wine, Whiskey, Beer, Trendy, Spicy, Thai, Mediterranean, Classy, Homemade',
        annual_dol: 1903.08,
        description:
            'This gypsy-themed lounge hosts a global roster of performers, ranging from jazz & funk to hip-hop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7078,
        store_longitude: -73.9557,
        total_orders: 2216.6,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Iranian, Tasty, Trendy, Delicious, Moroccan, Modern, Perfect, Mediterranean, Authentic, Cool',
        annual_dol: 2362.26,
        description:
            'Iranian-style small plates & signature cocktails in a trendy tapas bar with a rear garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.642,
        store_longitude: -74.0774,
        total_orders: 710.17,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Hotel, Comfortable, Great, Incredible, Cultural, Complex, Local',
        annual_dol: 756.84,
        description:
            'The St. George Theatre is a performing arts venue, picture palace, and office complex at 35 Hyatt Street in St. George, on the North Shore of Staten Island, New York City. The 2,800-seat St. George Theatre was built for Staten Island theater operator Solomon Brill and opened on December 4, 1929.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.921,
        total_orders: 5074.19,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Wine, Beer, Mediterranean, Homemade, Local, Traditional, Casual, Greek, Middle Eastern, Great',
        annual_dol: 8613.44,
        description:
            'This bakery-cafe serving Balkan specialties is known for its phyllo pastries & ethnic entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.957,
        total_orders: 431.23,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Coffee, Burger, Wine, Beer, Italian, Australian, American, Hotel, Modern, Eclectic',
        annual_dol: 1762.74,
        description:
            '"An approachable dining destination in an elevated environment. Serving speciality coffee and brunch dishes inspired by Australian coastal towns through the day and a cocktail driven dinner destination at night, Isla & Co. draws influences from Australia, Europe and South East Asia"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7744,
        store_longitude: -73.964,
        total_orders: 757.47,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Dessert, Upscale, French, Hotel, Warm, Premium, Eclectic, Exquisite, Seasonal',
        annual_dol: 2659.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9603,
        total_orders: 43.36,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Beer, Dessert, Chicken Tikka Masala, Urban, Contemporary, Tandoori Chicken, Modern, Aloo Gobi, Indian',
        annual_dol: 88.62,
        description: 'Modern spot with an enclosed patio for regional Indian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7033,
        store_longitude: -73.9427,
        total_orders: 141.27,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Coffee, Beer, Wine, American, Fun, Lively, Fantastic, Creative, Cool, Great',
        annual_dol: 197.91,
        description:
            '"Rise Radio is a bar café, restaurant, event space and live-streaming platform. We are a community hub & creative platform dedicated to the arts, culture, entertainment, wellness, food & more."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6621,
        store_longitude: -73.7353,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Burger, Lobster, Tacos, Bbq, Delicious, Rum, Moscow Mule',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6756,
        store_longitude: -74.0169,
        total_orders: 7317.16,
        neighborhood: 'Red Hook',
        keywords:
            'Wine, Quaint, Friendly, Awesome, Cool, Fantastic, Amazing, Great, Western, Quirky',
        annual_dol: 7472.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7494,
        store_longitude: -73.9551,
        total_orders: 1904.79,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Steak, Hotdog, Beer, Italian, Wine, American, Dutch, Contemporary, Pita Bread',
        annual_dol: 2275.38,
        description:
            'This unpretentious, compact waterside joint offers a simple menu of burgers, hot dogs & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6498,
        store_longitude: -73.7832,
        total_orders: 309.7,
        neighborhood: 'Jamaica',
        keywords: 'Lobster, Pizza, Wine, Salad, Beer, Martini, Rich, Hotel, Upscale, Rum',
        annual_dol: 1265.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7343,
        store_longitude: -73.8682,
        total_orders: 2872.43,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Steak, Burger, Bourbon, Italian, Beer, Bbq, Margarita, American, Juicy, Legendary',
        annual_dol: 2965.71,
        description:
            'Casual steakhouse chain known for grilled beef & other American dishes in a ranch-style space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7378,
        store_longitude: -73.9917,
        total_orders: 196.03,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Italian, Korean, Cozy, Homemade, Homey, Bruschetta, Casual, American, Local, Natural',
        annual_dol: 204.53,
        description:
            'Pasta is made fresh all day at this easygoing Italian eatery preparing traditional plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6396,
        store_longitude: -73.9676,
        total_orders: 3280.9,
        neighborhood: 'Flatbush',
        keywords: 'Tequila, Whiskey, Beer, Wine, American, Classic, Friendly, Amazing, Cool, Great',
        annual_dol: 3350.51,
        description:
            'Quirky bar tucked behind a florist shop pouring a selection of bourbons in a space with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8793,
        store_longitude: -73.8432,
        total_orders: 146.71,
        neighborhood: 'East Bronx/Edenwald',
        keywords: 'Tasty, Caribbean, Jamaican, Friendly, Local, Casual, Great',
        annual_dol: 359.43,
        description: '"Caribbean Cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9729,
        total_orders: 2326.91,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegan, Vegetarian, Dairy Free, Salad, Shrimp Cocktail, Gluten Free, Beer, Organic, Tasty, American',
        annual_dol: 2427.79,
        description:
            'Sleek-but-casual bistro offering up hearty New American fare & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.9677,
        total_orders: 181.76,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Champagne, Wine, Tequila, Upscale, Margarita, Bourbon, Elegant, Hotel, Spacious, Rum',
        annual_dol: 788.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5838,
        store_longitude: -73.949,
        total_orders: 184.23,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Salad, Shrimp Cocktail, Tasty, Coffee, Italian, Mediterranean, Delicious, Turkish, Authentic, Traditional',
        annual_dol: 204.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9723,
        total_orders: 16267.12,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Wine, Upscale, Rich, Salad, Dessert, Elegant, Trendy, Legendary, Warm',
        annual_dol: 17616.7,
        description:
            'Steakhouse in an iconic Seagram Building setting with midcentury style, classic dishes & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.9682,
        total_orders: 2878.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Chinese, Peking Duck, Fiery, Ramen, Luxurious, Elegant, Contemporary, Chic, Artisanal',
        annual_dol: 3278.12,
        description:
            'Fine-dining destination for Northern Chinese plates set in an elegant Art Deco-inspired space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9631,
        total_orders: 323.59,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Pizza, Flavorful, Japanese, Asian, Cozy, Sashimi, Modern, Hotel',
        annual_dol: 496.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.971,
        total_orders: 1150.25,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Wine, Upscale, Dessert, Italian, Premium, Classic, Exceptional, Great',
        annual_dol: 1280.42,
        description:
            'Warm, white-tablecloth eatery offering a kosher menu with Italian influences in wood-hued environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6901,
        store_longitude: -73.9067,
        total_orders: 2984.46,
        neighborhood: 'Bushwick',
        keywords:
            'Hamburger, Coffee, Tasty, Beer, Breakfast Burrito, Warm, American, French, Great, Casual',
        annual_dol: 3078.05,
        description:
            'Modern, low-key spot for coffee, updated comfort food, draft beer, cocktails & an artsy patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9919,
        total_orders: 1688.38,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Steak, Beer, Whiskey, Wine, Irish, American, Hurricane, Fun, Yummy',
        annual_dol: 2414.34,
        description:
            'Irish-style sports bar on 2 levels offering comfort food, brews & game viewing on giant projectors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9809,
        total_orders: 55.26,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Hotel, Legendary, Vintage, Classic, Spanish, Perfect, Exceptional, Authentic, Urban, Chic',
        annual_dol: 231.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9873,
        total_orders: 620.11,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Italian, Korean, Bbq, Wine, Modern, Fusion, Innovative, Friendly, Memorable, Perfect',
        annual_dol: 2534.82,
        description:
            'Offshoot of a long-standing Seoul restaurant specializing in Korean BBQ, dishes & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7736,
        store_longitude: -73.984,
        total_orders: 74.99,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Hotel, American, Rich, Irish, Seasonal, Dynamic, Fantastic, Cultural, Complex',
        annual_dol: 306.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6351,
        store_longitude: -73.9612,
        total_orders: 1630.1,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Rusty Nail, Beer, Korean, Wine, Ramen, Asian, Fusion, Awesome, Hip, Great',
        annual_dol: 2102.09,
        description:
            'Hip tavern with a tin ceiling & backlit bar, plus draft beers & a casual menu of quick bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9164,
        total_orders: 238.26,
        neighborhood: 'Bushwick',
        keywords: 'Caribbean, Rum, Jamaican, Warm, Cozy, Healthy, American, Fusion, Fresh, Casual',
        annual_dol: 284.22,
        description:
            'Casual hangout offering Caribbean plates, rum cocktails & frozen drinks, plus a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7299,
        store_longitude: -74.0103,
        total_orders: 1434,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Champagne, Wine, Beer, Hotel, Crisp, Casual, Polish, Modern, American, Incredible',
        annual_dol: 4681.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6484,
        store_longitude: -74.0071,
        total_orders: 0,
        neighborhood: 'Sunset Park',
        keywords:
            'Hamburger, Salad, Italian, Cuban, Dominican, Spanish, Thai, American, Latin, Casual',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.664,
        store_longitude: -73.5521,
        total_orders: 1386.5,
        neighborhood: '',
        keywords: 'Burger, Pizza, Italian, Sushi, Salad, Mexican, Delicious, Beer, Asian, Fun',
        annual_dol: 1461.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7032,
        store_longitude: -73.9032,
        total_orders: 323.23,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Vegan, Wine, Cheesesteak, Beer, Steak, Coffee, Cornbread, Salad, Ale, Pizza',
        annual_dol: 832.92,
        description:
            'Mellow cafe offering local beer, organic wine, cheese plates, charcuterie & Sunday brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6632,
        store_longitude: -73.9849,
        total_orders: 3218.69,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Pizza, Hamburger, American, Beer, Wine, Modern, Thai, Casual, Delicious, Local',
        annual_dol: 3286.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.9701,
        total_orders: 732.44,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Sushi, Wine, French, Cozy, Delicious, Comfortable, Modern, Casual, Welcoming',
        annual_dol: 779.45,
        description:
            'Revival of a longtime restaurant for traditional French fare in a setting with dark woods & mirrors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7007,
        store_longitude: -73.9301,
        total_orders: 476.69,
        neighborhood: 'Bushwick',
        keywords: 'Hotdog, Beer, Delicious, American, Cozy, Hot Toddy, Urban, Awesome, Great, Fine',
        annual_dol: 542.92,
        description:
            'Chill local tavern & happy-hour spot featuring a roomy back patio, hot dogs & a popcorn machine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6978,
        store_longitude: -73.9271,
        total_orders: 178.28,
        neighborhood: 'Bushwick',
        keywords:
            'Vegetarian, Salad, Coffee, Wine, Italian, English Muffin, French, Bbq, American, Homemade',
        annual_dol: 323.2,
        description:
            'Eclectic New American spot for creative eats, espresso & craft cocktails in chic surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7013,
        store_longitude: -73.8868,
        total_orders: 71.29,
        neighborhood: 'Flushing/Glendale',
        keywords:
            'Steak, Hamburger, Vegan, Karaoke, Chinese, Cosmopolitan, Moscow Mule, Gin, Hotel, Caipirinha',
        annual_dol: 268.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7629,
        store_longitude: -73.984,
        total_orders: 6798.34,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegan, Pizza, Gluten Free, Flavorful, Beer, Wine, Avocado Toast, Salad, Tex-Mex, Warm',
        annual_dol: 6837.04,
        description:
            'Hip, brick-lined locale with vintage accents for creative cocktails, modern pub plates & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7655,
        store_longitude: -73.9873,
        total_orders: 24417.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tacos, Mexican, Beer, Tasty, Gourmet, Spicy, Delicious, American, Friendly, Spacious',
        annual_dol: 29387.04,
        description:
            'Spacious cantina dishing up gourmet tacos, ceviche & other Mexican plates, plus tequilas & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8087,
        store_longitude: -73.9445,
        total_orders: 1832.62,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Meatloaf, Southern Comfort, Southern, Delicious, Casual, African, Sweet, American, Authentic',
        annual_dol: 1871.5,
        description:
            'Harlem landmark serving generous helpings of Southern comfort food since 1962.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6902,
        store_longitude: -73.8636,
        total_orders: 648.67,
        neighborhood: 'Jamaica/Woodhaven',
        keywords: 'Burger, Dessert, Beer, Quaint, Ale, Cozy, Clean, Friendly, Casual, Bbq',
        annual_dol: 710.31,
        description:
            'This unpretentious watering hole open since 1829 has been featured in several films.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7221,
        store_longitude: -73.9884,
        total_orders: 15409.99,
        neighborhood: 'Lower East Side',
        keywords: 'Sushi, Wine, Beer, Spicy, Trendy, Ale, Delicious, Meatball, Japanese, Hotel',
        annual_dol: 16172.18,
        description:
            'Vast Japanese menu of sushi & small plates plus sake & cocktails in the trendy Sixty LES Hotel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.9856,
        total_orders: 1250.74,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Tequila, Vodka, Whiskey, Beer, Lemon Drop, Scotch, Gin, Green Tea Shot, Rum, Upscale',
        annual_dol: 1332.93,
        description:
            '"Drawing inspiration from the creative energy and vivid history of downtown Manhattan, Writing On The Wall is a restaurant and dance lounge in the Lower East Side."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -73.9885,
        total_orders: 364.96,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Steak, Vegetarian, Pizza, Salad, Chicken Parmigiana, Tasty, Italian, Wine, Spicy',
        annual_dol: 397.17,
        description:
            'Corner Italian destination known for its thin-crust pizzas, homemade pastas & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6884,
        store_longitude: -73.983,
        total_orders: 0,
        neighborhood: 'Downtown Brooklyn',
        keywords:
            'Korean, Shrimp Cocktail, Beer, Wine, Delicious, Bourbon, Elegant, Bbq, Asian, Fun',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5791,
        store_longitude: -74.1636,
        total_orders: 1687.37,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Steak, Burger, Salad, Gourmet, Delicious, Beer, Juicy, French, French Onion Soup, Ale',
        annual_dol: 1922.16,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6823,
        store_longitude: -73.963,
        total_orders: 68.88,
        neighborhood: 'Clinton Hill',
        keywords:
            'Vodka, Steak, Nigerian, Spicy, African, Fusion, Modern, Authentic, Mojito, Lively',
        annual_dol: 124.86,
        description:
            'Compact eatery with a candlelit dining room & lively bar specializing in aromatic Nigerian cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7224,
        store_longitude: -73.9829,
        total_orders: 726.08,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster, Vegan, Beer, Spanish, French Toast, Mediterranean, Legendary, French, Elegant, Innovative',
        annual_dol: 849.96,
        description:
            'This casual tapas spot serves house sangria & occasional live music with its Spanish plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.6884,
        total_orders: 8645.42,
        neighborhood: '',
        keywords:
            'Steak, Tacos, Salad, Wine, Beer, South American, French Toast, Trendy, Delicious, French',
        annual_dol: 8916.19,
        description:
            'Vibrant local watering hole with sidewalk seating fixing up American-style tapas & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6801,
        store_longitude: -73.9682,
        total_orders: 6896.44,
        neighborhood: 'Prospect Heights',
        keywords:
            'Mexican, Tacos, Tequila, Margarita, Trendy, Breakfast Burrito, Stylish, Homey, Contemporary, Innovative',
        annual_dol: 7368.31,
        description:
            'Tacos, Mexican small plates & shareable main dishes in an energetic setting with communal seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7243,
        store_longitude: -73.9785,
        total_orders: 117.55,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Hotdog, Burger, Mexican, Shrimp Cocktail, Tasty, American, Vibrant, Fun, Prime Rib, Casual',
        annual_dol: 128.11,
        description: '"Cocktails fun jammers nice folks"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.722,
        store_longitude: -73.9597,
        total_orders: 1417.26,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Japanese, Dessert, Jamaican, Caribbean, Asian, Rum, Modern, Classy, Premium',
        annual_dol: 2394.74,
        description:
            'Hip, bohemian restaurant serving specialty cocktails & traditional Caribbean cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 2714.81,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Sushi, Shrimp Cocktail, Delicious, Thai, Romantic, Modern, Fun, American, Classic',
        annual_dol: 3016.56,
        description:
            'Grilled meats, seafood & English breakfast in a sleek, contemporary space with a bar & big windows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9852,
        total_orders: 2019.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Wine, Beer, Hotel, Friendly, Intimate, Classic, Great',
        annual_dol: 2152.18,
        description:
            "The Eugene O'Neill Theatre, previously the Forrest Theatre and the Coronet Theatre, is a Broadway theater at 230 West 49th Street in the Theater District of Midtown Manhattan in New York City. The theater was designed by Herbert J. Krapp and was constructed for the Shubert brothers.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7638,
        store_longitude: -73.9885,
        total_orders: 482.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tacos, Mexican, Lobster, Beer, Margarita, Wine, Upscale, Quesadillas, Fajitas, Bbq',
        annual_dol: 525.87,
        description:
            'Unexpected ingredients like lobster appear in the upscale Mexican dishes at this cool locale.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9774,
        total_orders: 1292.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Sushi, Salad, Milkshake, Japanese, Tasty, Whiskey, Upscale, Hotel, Modern',
        annual_dol: 5281.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6818,
        store_longitude: -73.9762,
        total_orders: 473.57,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Garlic Bread, Chicken Parmigiana, Salad, Vodka, Beer, Italian, Wine, Dessert, Veal Parmigiana',
        annual_dol: 484.97,
        description:
            'Local pizzeria chain serving brick-oven pies, pasta & calzones in a relaxed setting since 1933.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7556,
        store_longitude: -73.9791,
        total_orders: 772.95,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Gluten Free, Wine, Upscale, Delicious, Elegant, Hotel, Martini',
        annual_dol: 3159.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9839,
        total_orders: 6867.29,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Hotdog, Delicious, Friendly, Iconic, Cool, Great, Casual, Tradition, Signature, Unique',
        annual_dol: 21412.68,
        description:
            'Patrons who enter through a phone booth in Crif Dogs next door sip novel cocktails in a dark space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.678,
        store_longitude: -73.964,
        total_orders: 1315.31,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Wine, Gourmet, Friendly, Irish, Casual, Great',
        annual_dol: 1372.34,
        description:
            'Down-to-earth hangout featuring sports on TV, pub grub & a backyard area with a pool table & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5942,
        store_longitude: -74.1867,
        total_orders: 651.8,
        neighborhood: 'Travis - Chelsea/Mid Island',
        keywords:
            'Steak, Garlic Bread, Burger, Shrimp Cocktail, Salad, Tequila, Bourbon, Italian, Ale, Spicy',
        annual_dol: 742.36,
        description:
            'Old-school steakhouse offering meats, seafood & pastas in a warm, paneled space with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9771,
        total_orders: 26.19,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Mexican, Shrimp Cocktail, Danish, Caribbean, Rum, Spicy, American, Latin, Casual',
        annual_dol: 107.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7493,
        store_longitude: -73.9774,
        total_orders: 4421.44,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Burger, Salad, Beer, Italian, Nachos, Cozy, Irish, Hotel, French, Homemade',
        annual_dol: 4614.11,
        description:
            'Midtown residents and Grand Central commuters frequent this sports bar for casual beers & pub food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9843,
        total_orders: 628.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords:
            'Burger, Beer, Irish, Hotel, Crisp, Homemade, Friendly, Traditional, Classic, Authentic',
        annual_dol: 3813.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7718,
        store_longitude: -73.9533,
        total_orders: 817.3,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Salad, Beer, Wine, Dessert, Bbq, Relaxed, Casual, American',
        annual_dol: 853.89,
        description:
            'Pub grub & several kinds of wings dominate the menu, plus budget beers & plenty of games on the TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7748,
        store_longitude: -73.9571,
        total_orders: 264.86,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Lasagna, Salad, Dessert, Italian, Chicken Parmigiana, Delicious, Elegant, Cozy, Polished, Homemade',
        annual_dol: 349.48,
        description: 'Straightforward Italian fare from a longtime local standby.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7353,
        store_longitude: -74.0031,
        total_orders: 1863.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Steak, Lasagna, Italian, Chilean, Meatball, Mediterranean, French, Greek, American',
        annual_dol: 1905.95,
        description:
            'Coastal fare from Italy & France is well represented at this busy bistro with sidewalk tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -74.0055,
        total_orders: 1031.3,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Wine, Beer, Stylish, Chinese, Delicious, French, Asian, Contemporary, Refined',
        annual_dol: 1231.95,
        description:
            'French techniques meet Chinese & Himalayan flavors at this refined eatery inside a minimalist space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8419,
        store_longitude: -73.8434,
        total_orders: 37.36,
        neighborhood: 'Westchester Square',
        keywords: 'Pizza, Beer, Tasty, Nachos, Delicious, Latin, Cool, Great, Casual, Local',
        annual_dol: 91.38,
        description: '"Best grown and sexy local neighborhood bar and grill lounge...."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7892,
        store_longitude: -73.97,
        total_orders: 474.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Chocolate Mousse, Italian, Chicken Parmigiana, Dessert, Wine, Juicy, Outstanding, Iconic, Excellent, Casual',
        annual_dol: 497.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8344,
        store_longitude: -73.9411,
        total_orders: 16.38,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Burger, Mexican, Coffee, Chinese, Japanese, American, Delicious, Dominican, Latin, Authentic',
        annual_dol: 66.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.6992,
        total_orders: 10.46,
        neighborhood: '',
        keywords:
            'Cheesecake, Lemon Meringue Pie, Salad, Hamburger, Tasty, Dessert, Danish Pastry, Delicious, Danish, Gin',
        annual_dol: 42.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7314,
        store_longitude: -73.958,
        total_orders: 1324.66,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Whiskey, Upscale, Cozy, Friendly, Classy, Casual, Perfect, Great, Chill',
        annual_dol: 1382.09,
        description:
            'The jukebox plays country tunes in this hip, laid-back hangout with an exposed-brick interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7327,
        store_longitude: -73.8636,
        total_orders: 1363.83,
        neighborhood: 'Rego Park/Flushing',
        keywords: 'Ale, Beer, Steak, Lobster, Tangy, American, Warm, Delicious, Casual, Local',
        annual_dol: 5640.67,
        description:
            'Casual sports-pub chain with basics from bar snacks to steaks & a large selection of tap beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5416,
        store_longitude: -74.1469,
        total_orders: 3631.63,
        neighborhood: 'Great Kills',
        keywords:
            'Lobster, Pizza, Salad, Italian, Vodka, Meatball, Delicious, Caesar Salad, Warm, Casual',
        annual_dol: 3789.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7924,
        store_longitude: -73.9738,
        total_orders: 490.95,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Hamburger, Salad, Meatball, Italian, Coffee, Dessert, Wine, Prosecco, Casual',
        annual_dol: 586.47,
        description:
            'Tiny joint featuring a menu of pizza, burgers, gyros & other hearty fare plus sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7324,
        store_longitude: -73.9945,
        total_orders: 506.3,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Italian, Upscale, Romantic, Classy, Comfortable, Homey, Amazing, Intimate, Wonderful',
        annual_dol: 528.25,
        description:
            'A roaring fireplace sets the scene for homey red-sauce pasta & entrees served by bow-tied waiters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -73.9857,
        total_orders: 109.9,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Beer, Warm, Juicy, Intimate, Asian, Contemporary, American, Bright, Casual',
        annual_dol: 449.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.8899,
        total_orders: 118.67,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Salad, Dominican, Mediterranean, American, Premium, Fabulous, Great, Excellent, Casual',
        annual_dol: 166.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7394,
        store_longitude: -73.8843,
        total_orders: 97.81,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Premium, Awesome, Sophisticated, Clean, Great, Latin, Casual',
        annual_dol: 399.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -73.9779,
        total_orders: 10632.78,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Vodka, Rich, Martini, Margarita, Gin, Salad, Iconic, Classy, Premium',
        annual_dol: 11017.8,
        description:
            "Classic cocktails & light fare in the ornate Grand Central offices of a 1920's mogul.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5756,
        store_longitude: -73.9628,
        total_orders: 204.09,
        neighborhood: 'Brighton Beach',
        keywords:
            'Salad, Russian, Georgian, Delicious, Homemade, American, Premium, Casual, Authentic, Spectacular',
        annual_dol: 217.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8827,
        store_longitude: -73.8989,
        total_orders: 320.5,
        neighborhood: 'Kingsbridge Heights/West Bronx',
        keywords:
            'Pizza, Gourmet, Mexican, Wine, Delicious, Trendy, Bbq, Dominican, Irish, American',
        annual_dol: 531.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7526,
        store_longitude: -73.9744,
        total_orders: 3177.46,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Irish, Elegant, Coffee, Gin, Warm, Georgian, Lively, Classic, Polished',
        annual_dol: 3346.97,
        description:
            "Polished rooms feature a mixture of classic and modern furnishings, and offer free Wi-Fi and flat-screen TVs, plus pillow-top mattresses and coffeemakers. Suites add sitting areas, and an upgraded suite has a separate living room, a marble fireplace and a rooftop terrace. Room service is available 24/7.Access to a nearby fitness center is complimentary. There's a cozy, traditional Irish pub with a patio.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.9445,
        total_orders: 24.28,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Steak, Lobster, Tasty, Dessert, Delicious, Tuna Steak, Lobster Bisque, Shrimp Scampi, Comfortable, Mediterranean',
        annual_dol: 42.56,
        description:
            'Roomy waterside cafe a fireplace & patio for comfort food, cocktails & housemade ice cream.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9615,
        total_orders: 4176.87,
        neighborhood: 'Midtown Manhattan/Sutton Place',
        keywords: 'Phenomenal, Spanish, Amazing, Awesome, Great, Premium, Contemporary, Local',
        annual_dol: 4373.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9775,
        total_orders: 559.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tasty, Rich, Wine, Upscale, Pizza, Delicious, Elegant, Dessert, Sustainable, Organic',
        annual_dol: 1826.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6893,
        store_longitude: -73.9693,
        total_orders: 6672.52,
        neighborhood: 'Clinton Hill',
        keywords:
            'Vegetarian, Italian, Salad, Beer, Caesar Salad, American, Outstanding, Romantic, Fun, Great',
        annual_dol: 6961.82,
        description:
            'This tin-ceilinged bistro offers upmarket pub fare & charcuterie along with DJ shows & late hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -73.9851,
        total_orders: 7220.51,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Mexican, Avocado Toast, Hurricane, Hotel, Delicious, Chinese, Wholesome, American, Cool, Casual',
        annual_dol: 25348.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6894,
        store_longitude: -73.9927,
        total_orders: 10.35,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Mexican, Tacos, Salad, Burritos, Organic, Warm, Local, Sparkling, Casual, Great',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.99,
        total_orders: 774.66,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Sushi, Japanese, Sashimi, Whiskey, Modern, Seasonal, Hotel, Creamy, Local, Traditional',
        annual_dol: 3194.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.9966,
        total_orders: 91.52,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Fun, Smooth, Casual, Vivid',
        annual_dol: 374.11,
        description:
            '"Spearmint Rhino Gentlemen\'s Club NYC is now open! 5 floors of entertainment from around the globe. Come see what everyone is talking about!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9824,
        total_orders: 130.19,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Tacos, Flavorful, Salad, Mexican, Tasty, Margarita, Trendy, Delicious, Healthy, Classy',
        annual_dol: 290.34,
        description:
            '"Inspired by a healthy lifestyle where beach, surf and sun mix with fresh local ingredients, Taco Dumbo offers a laid back vibe and flavorful food for all."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9989,
        total_orders: 1753.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Pizza, Beer, Vodka, Upscale, Wine, American, Rum, Modern, Premium',
        annual_dol: 2100.01,
        description:
            'Beer hall offering a large craft-brew menu in a brick-walled, TV-equipped space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -73.9964,
        total_orders: 523.33,
        neighborhood: 'Nolita',
        keywords:
            'Tasty, Vegan, Italian, Sushi, Coffee, Delicious, Upscale, Elegant, Exquisite, Healthy',
        annual_dol: 987.12,
        description:
            'SoHo outpost of a small chain of cafes offering modern Milanese cuisine, bar bites & Italian wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7768,
        store_longitude: -73.9778,
        total_orders: 240.07,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Pizza, Salad, Wine, Beer, Italian, Gluten Free, Hotel, Organic, American, Seasonal',
        annual_dol: 981.34,
        description:
            'Easygoing outlet serving elevated diner & deli offerings in a woodsy venue with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6638,
        store_longitude: -73.6986,
        total_orders: 14.72,
        neighborhood: '',
        keywords:
            'Wine, Coffee, Avocado Toast, Cozy, Relaxed, Perfect, Caribbean, Casual, American, Local',
        annual_dol: 60.16,
        description:
            '"Standard Pour is a relaxed café and wine bar located in Valley Stream, LI serving specialty coffee drinks, cocktails and cozy fare made-to-order every day. Perfect for wine date night, afterwork happy hour or weekend brunch with friends."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9807,
        total_orders: 307.78,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Salad, Italian, Wine, Dessert, Roast Turkey, Elegant, Spacious, Polished, Mediterranean',
        annual_dol: 886.49,
        description:
            'Traditional Italian cuisine & cocktails offered in a spacious venue with chic, polished decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.9784,
        total_orders: 1932.78,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Gourmet, Dessert, Vegan, Hotel, Upscale, Indian, Contemporary, Fusion, Cozy',
        annual_dol: 2059.8,
        description:
            'High-end Indian restaurant featuring a menu of creative meals, plus a robust wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7656,
        store_longitude: -73.9826,
        total_orders: 9.73,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Gourmet, Salad, Chinese, Spicy, Asian, Healthy, Authentic, Casual, Great',
        annual_dol: 39.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9743,
        total_orders: 1776.96,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Russian, Urban, Iconic, Timeless, Complex',
        annual_dol: 7263.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.989,
        total_orders: 273.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Tasty, Friendly, Fun, English Muffin, Great, Casual, American',
        annual_dol: 1119.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -73.9882,
        total_orders: 1340.23,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Tasty, Beer, Shrimp Cocktail, Upscale, Delicious, Premium, French, Friendly, Classic',
        annual_dol: 1526.43,
        description: 'Neighborhood hangout for beer, creative cocktails & off-the-grill fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8985,
        store_longitude: -73.8672,
        total_orders: 980.25,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords:
            'Burger, Steak, Salad, Pizza, Wine, Beer, Irish, Delicious, Caesar Salad, American',
        annual_dol: 1166.02,
        description:
            'Standard bar grub & pints of Guinness in a sprawling, TV-equipped space with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7223,
        store_longitude: -73.9886,
        total_orders: 1766.18,
        neighborhood: 'Lower East Side',
        keywords: 'Rooftop, Hotel, Wine, Bright, Stylish, Trendy, Japanese, Perfect, Classy, Great',
        annual_dol: 4319.9,
        description:
            "Stylish 7th-floor locale with creative cocktails, plush pink booths, a terrace & DJ'ed music.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7824,
        store_longitude: -73.7947,
        total_orders: 230.16,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Salad, Shrimp Cocktail, Chicken Parmigiana, Pizza, Chocolate Mousse, Vodka, Wine, Italian, Tasty, Southern',
        annual_dol: 240.14,
        description:
            'Warm, casual venue offering Northern & Southern Italian fare, including pasta, veal & seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7385,
        store_longitude: -74.0021,
        total_orders: 895.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Lobster Roll, Steak, Pizza, Coffee, Italian, Vodka, Martini, Ale, American',
        annual_dol: 920.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5866,
        store_longitude: -73.804,
        total_orders: 181.87,
        neighborhood: 'Hammels/Far Rockaway/Arverne',
        keywords:
            'Lobster, Lobster Roll, Italian, Beer, American, Sensational, Casual, Cool, Signature',
        annual_dol: 197.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7391,
        store_longitude: -73.9937,
        total_orders: 359.82,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Delicious, Trendy, Upscale, Italian, Romantic, Classy, Sweet, Southern, Great',
        annual_dol: 375.42,
        description:
            'Chic space for Southern Italian seafood, pastas & wine, with banquette & communal seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -73.9618,
        total_orders: 1173.52,
        neighborhood: 'Williamsburg',
        keywords: 'Canadian, Wine, Beer, American, Legendary, Clean, Exceptional, Cool',
        annual_dol: 1285.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7119,
        store_longitude: -73.9441,
        total_orders: 1703.38,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Vodka, Wine, Burger, Margarita, Coffee, Ale, Bourbon, Rustic, Spicy',
        annual_dol: 1896.59,
        description:
            'Popular, rustic bar serving draft beer, wine & cocktails, plus weekend DJs & dancing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9832,
        total_orders: 391.37,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Whiskey, Beer, Canadian, Mimosa, Friendly, Fun, Eclectic, Quirky, Casual, Lively',
        annual_dol: 471.38,
        description:
            'Quirky haunt in unassuming digs lures locals with eclectic tunes & straightforward drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6718,
        store_longitude: -73.9577,
        total_orders: 134.31,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Pizza, Salad, Italian, Dessert, Wine, Warm, Modern, Seasonal, Casual',
        annual_dol: 140.13,
        description:
            'Unique Italian dishes with local ingredients served in a warm space with a long marble bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7242,
        store_longitude: -73.9825,
        total_orders: 56.76,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Italian, Wine, Dessert, Pizza, Rustic, Cozy, Classy, Crisp, Intimate, Casual',
        annual_dol: 61.86,
        description: 'Unassuming East Village Tuscan restaurant with a cozy, brick-walled setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.9279,
        total_orders: 298.77,
        neighborhood: 'Astoria/LIC',
        keywords: 'Cuban, Mexican, Coffee, Dessert, Karaoke, Rum, Italian, American, Fusion, Latin',
        annual_dol: 357.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7223,
        store_longitude: -73.9593,
        total_orders: 1529.18,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Ale, Gluten Free, Irish, American, Warm, Delicious, Sweet, Casual',
        annual_dol: 1620.85,
        description:
            'Easygoing, industrial pub featuring rugby & soccer games on TV, plus craft beers & tavern eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -73.9835,
        total_orders: 33.49,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Pizza, Beer, Wine, Salad, Premium, Delicious, Phenomenal, Thai, Tiramisu, Great',
        annual_dol: 177.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7145,
        store_longitude: -73.9652,
        total_orders: 1225.55,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Vegan, Salad, Vegetarian, Coffee, Gluten Free, Beer, Delicious, Rustic, Mediterranean, Israeli',
        annual_dol: 1583.13,
        description:
            'Middle Eastern dishes (including standout hummus) featured in a laid-back, rustic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -73.9846,
        total_orders: 877.2,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Pizza, Steak, Italian, Wine, Rustic, Beer, Hotel, Modern, Delicious, Polished',
        annual_dol: 3585.72,
        description:
            'Cracker-thin pizza & other modern Italian fare in a polished hotel dining room with an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9773,
        total_orders: 84.74,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Chicken Tikka Masala, Gluten Free, Tangy, Fiery, Southern, Tandoori Chicken, Spacious, Paneer Tikka, Indian',
        annual_dol: 207.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.9866,
        total_orders: 341.48,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Karaoke, Cajun, Trendy, Korean, Delicious, Organic, Fun, Premium, Awesome, Fresh',
        annual_dol: 1286.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9295,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Steak, Lobster, Wine, Upscale, Beer, Trendy, Healthy, Perfect, Latin, American',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7647,
        store_longitude: -73.9767,
        total_orders: 7623.19,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, American, Urban, Awesome, Rooftop, Natural, Fresh, Excellent, Seasonal, Personalized',
        annual_dol: 7801.35,
        description:
            "The rustic-chic rooms and suites have reclaimed wood accents and come with free Wi-Fi, flat-screen TVs and tablets with personalized apps. They also offer 24/7 room service, Nespresso machines and yoga mats. In-room spa treatments can also be arranged for a fee.Amenities include a farm-to-table restaurant with picnic baskets available to go, a complimentary snack bag upon arrival, and a bar. There's also a lobby farm stand, and a fitness room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.9854,
        total_orders: 4214.4,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegan, Vegetarian, Wine, Shrimp Cocktail, Beer, Gin, Spicy, Irish, Phenomenal, Traditional',
        annual_dol: 4434.53,
        description:
            'Easygoing spot offering classic pub grub & a full bar in a whimsical setting with Victorian details.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7629,
        store_longitude: -73.9841,
        total_orders: 627.69,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Wine, Cuban, Margarita, Gourmet, Stylish, Spicy, Delicious, Warm, Luxurious, Sweet',
        annual_dol: 627.69,
        description:
            'Cuban cooking & live Cuban music fuel the festive old-Havana vibe at this circa-1963 spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7039,
        store_longitude: -74.009,
        total_orders: 470.16,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster Roll, Lobster, Burger, Tacos, Beer, Margarita, Wine, Coffee, Spicy, Vodka',
        annual_dol: 524.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7175,
        store_longitude: -73.9903,
        total_orders: 3658.16,
        neighborhood: 'Lower East Side',
        keywords: 'Wine, Beer, Tasty, Rich, Caribbean, Rum, Salad, French, Intimate, Sweet',
        annual_dol: 4264.04,
        description:
            'Unique cocktails & bar bites such as arepas & oxtail rice bowls served in intimate surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9947,
        total_orders: 127.84,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Vegetarian, Salad, Organic, Savory, Delicious, Healthy, Avocado Toast, Thai, Sweet',
        annual_dol: 522.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8675,
        store_longitude: -73.9294,
        total_orders: 37.07,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Steak, Salad, Margarita, Latin, Dominican, American, Casual, Intimate, Local, Great',
        annual_dol: 151.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -73.9957,
        total_orders: 284.56,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Cheesecake, Salad, Wine, Champagne, Delicious, Italian, Warm, Cozy, Organic, Sweet',
        annual_dol: 480.82,
        description:
            'Fresh-made pastas and homestyle meat dishes are the hallmark of this budget Northern Italian spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7266,
        store_longitude: -73.7899,
        total_orders: 242.78,
        neighborhood: 'Fresh Meadows/Utopia/Flushing',
        keywords:
            'Vodka, Pizza, Gluten Free, Chicken Parmigiana, Italian, Meatball, Homemade, Fresh, Authentic, Casual',
        annual_dol: 251,
        description:
            'Family-owned spot serves hefty portions of Italian classics & signature house-made tiramisu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7442,
        store_longitude: -74.0065,
        total_orders: 799.79,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Pizza, Salad, Beer, Whiskey, Wine, American, Mediterranean, Delicious, Sangria',
        annual_dol: 3269.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.741,
        store_longitude: -74.0018,
        total_orders: 444.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Rich, Delicious, Caribbean, Iconic, Memorable, Heritage',
        annual_dol: 663.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7263,
        store_longitude: -73.9836,
        total_orders: 5107.78,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Mexican, Coffee, Beer, Organic, Bbq, Delicious',
        annual_dol: 7946.07,
        description:
            'Creative Pan-Latin fare served in a lively space with open-window seating & a popular happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6733,
        store_longitude: -73.9627,
        total_orders: 880.2,
        neighborhood: 'Crown Heights',
        keywords:
            'Wine, Beer, Dessert, Delicious, Seasonal, French, Intimate, Sleek, Spectacular, Contemporary',
        annual_dol: 918.36,
        description:
            'Seasonal, produce-driven dishes set in an atrium with an open kitchen, right next to Prospect Park.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -73.9806,
        total_orders: 411.69,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Korean, Asian, Awesome, Wonderful, American, Fusion, Incredible, Cool, Hip',
        annual_dol: 1445.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -73.9832,
        total_orders: 4642.81,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Rooftop, Hotel, Spectacular, Trendy, Italian, Mediterranean, Fantastic, Bellini, Dutch, Great',
        annual_dol: 19685.59,
        description:
            'Trendy rooftop bar offering a covered bar & skyline views, plus cocktails & international bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9745,
        total_orders: 2396.12,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Mexican, Tequila, Cornbread, Vegan, Margarita, Wine, Tex-Mex, Fajitas, Delicious, Romantic',
        annual_dol: 2572.54,
        description:
            'Intimate, old-school Mexican restaurant, open since 1959, offering traditional cuisine & margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9723,
        total_orders: 347.21,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Vegan, Coffee, Mexican, Beer, Delicious, American, Urban, Friendly, Fresh, Natural',
        annual_dol: 772.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7495,
        store_longitude: -73.9835,
        total_orders: 930.04,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Beer, Irish, Jagermeister, Cozy, Lively, Friendly, Comfortable, Atmospheric, Bright, Clean',
        annual_dol: 991.86,
        description:
            'Cocktails are served along with bottled & draft beer at this old-school Irish pub & sports bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -73.9574,
        total_orders: 6152.37,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Delicious, Sweet, Juicy, Ale, Crisp, Gourmet, Southern Comfort, Classy, Southern',
        annual_dol: 6419.12,
        description:
            'Gourmet chicken & waffles star at this hip, down-home Southern comfort-food resource with cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7453,
        store_longitude: -73.9786,
        total_orders: 92.94,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Tequila, Mexican, Burger, Steak, Beer, Margarita, Wine, Dessert, Martini, Enchiladas',
        annual_dol: 116.72,
        description:
            'Mexican kitchen & tequila bar in an easygoing, contemporary environment with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.9587,
        total_orders: 1.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Vegan, Lobster, Salad, Italian, Vodka, Shrimp Scampi, French, Homemade, Cozy',
        annual_dol: 5.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.9845,
        total_orders: 805.5,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Korean, Stylish, Wine, Savory, Bbq, Classy, Friendly, Japanese, Authentic, Traditional',
        annual_dol: 876.59,
        description:
            'Stylish & minimalist Korean barbecue restaurant serving with traditional tableware & utensils.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9819,
        total_orders: 10.85,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Salad, Rustic, Beer, Modern, Mediterranean, Homey, Casual, Local, Succulent, Unique',
        annual_dol: 44.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7676,
        store_longitude: -73.9833,
        total_orders: 1619.74,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Champagne, Wine, Sushi, Beer, Romantic, Whimsical, Fun, American, Amazing, Classic',
        annual_dol: 2162.24,
        description:
            'Cheery cocktail bar & bistro with stylish, genteel decor, plus live jazz & DJ nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9806,
        total_orders: 854.65,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Luxurious, Wine, Hotel, Spacious, Coffee, Premium, Italian, Gin, Amazing, Lively',
        annual_dol: 872.79,
        description:
            "The chic suites have Italian linens, 2 flat-screen TVs and complimentary Wi-Fi. Upgraded suites provide additional space; some add full kitchens, posh living rooms, city views and/or atriums. Room service is available 24/7.There's a 24-hour fitness center, as well as a sophisticated bar serving French-influenced fare.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9825,
        total_orders: 755.05,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Italian, Wine, Coffee, Iconic, Classic, Comfortable, Fantastic, Great, Authentic',
        annual_dol: 771.07,
        description:
            'subway lines are within a 3-block walk.Stylish rooms have free Wi-Fi, Bose radios and flat-screen TVs, plus minifridges and marble bathrooms. Suites add separate sitting areas.A modern Italian lounge serves wine and spirits. Other amenities include a 24-hour fitness room, access to a nearby pool and spa (fee), 5 meeting rooms and complimentary morning car service to Wall Street.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.975,
        total_orders: 2429.35,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Tequila, Italian, Rustic, French, Legendary, American, Classic, Polished, Iconic',
        annual_dol: 2480.9,
        description:
            '"Cucina8 ½ offers an elevated refined rustic Italian cuisine. Located in the heart of Midtown New York, home of the former Brasserie 8 1/2. Chef John Villa’s menu features a variety of dishes that are inspired by New York & Italian ingredients and culinary traditions."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9818,
        total_orders: 637.12,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Pizza, Salad, Chicken Parmigiana, Italian, Tasty, Wine, Fried Calamari, Casual, Great, Local',
        annual_dol: 742.64,
        description:
            'Besides big portions of pasta in a variety of sauces, this no-frills eatery serves pizza & panini.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9858,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Hotel, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7191,
        store_longitude: -73.9917,
        total_orders: 283.16,
        neighborhood: 'Bowery',
        keywords: 'Wine, Coffee, Salad, Dessert, Organic, Beer, French, Savory, Cozy, Romantic',
        annual_dol: 346.57,
        description:
            'Rustic-chic French cafe serving traditional fare including brunch with wine & classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6697,
        store_longitude: -73.588,
        total_orders: 506.49,
        neighborhood: '',
        keywords: 'Italian, Pizza, Great, Wonderful, Fresh, Friendly, Fine',
        annual_dol: 519.17,
        description:
            '"Family owned and operated Italian Pizza and Restaurant. Full Catering drop off and on premises. Full Bar in dining area. Pizza delivery"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.739,
        store_longitude: -74.0012,
        total_orders: 1337.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Beer, Mimosa, Wine, Italian, Bbq, American, Cozy, Coleslaw, Bloody Mary',
        annual_dol: 1640.93,
        description:
            'Old-fashioned pub offering a wide variety of beers, plus lunch & dinner menus & a late bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -73.9847,
        total_orders: 17224.81,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Gin, Beer, Austrian, Shrimp Cocktail, Rustic, Fun, Premium, Superb, Intimate',
        annual_dol: 19032.64,
        description:
            'Bartenders in bow ties & suspenders recall the speakeasy era at this dark, moody cocktail lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7118,
        store_longitude: -73.9572,
        total_orders: 905.5,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Awesome, Spectacular, Superb, Amazing, Great, Friendly, Clean, Cool',
        annual_dol: 924.71,
        description: '"Dive bar with a great Pinball selection"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7256,
        store_longitude: -73.9837,
        total_orders: 2914.45,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Cinnamon Roll, Wine, Vodka, Salad, Cajun, Beer, Coffee, Martini, Tasty, Savory',
        annual_dol: 4464.43,
        description:
            'Vibrant bar & restaurant with outdoor seating & an old-school vibe serving mixed drinks & meals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7125,
        store_longitude: -73.9574,
        total_orders: 175.22,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Tequila, Vodka, Gin, Rum, Spacious, Fun, Fantastic, Casual',
        annual_dol: 195.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7178,
        store_longitude: -73.9465,
        total_orders: 7.47,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Chinese, American, Fiery, Sophisticated, Natural, Casual, Unique, Cool, Dim Sum',
        annual_dol: 30.55,
        description:
            'Elevated Chinese dishes, cocktails & wines offered in a sophisticated restaurant with a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6384,
        store_longitude: -74.076,
        total_orders: 148.15,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Burger, Hamburger, Salad, Tasty, Bbq, French, Eclectic, Fresh, Fine',
        annual_dol: 605.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.9853,
        total_orders: 717.16,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Vegetarian, Korean, Bbq, Beer, Karaoke, Rich, Rich Aroma, Prime Rib, Casual, Classic',
        annual_dol: 754.86,
        description:
            'This branch of a South Korea-based chain offers table-grilled Korean BBQ, plus noodles & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7424,
        store_longitude: -73.9824,
        total_orders: 210.68,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vegetarian, Kebab, Chicken Tikka Masala, Wine, Spicy, Southern, Indian, Paneer Tikka, Casual, Saag Paneer',
        annual_dol: 240.77,
        description:
            'Modern decor meets Bollywood art at this Indian hot spot known for kebabs & dum biryani rice pots.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -73.9815,
        total_orders: 85.6,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Steak, Salad, Tranquil, Spicy, Hotel, American, Casual, Sweet, Cobb Salad',
        annual_dol: 349.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7346,
        store_longitude: -74.0029,
        total_orders: 430.52,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Salad, Wine, Dessert, Italian, French, Modern, Caesar Salad, Cozy, American',
        annual_dol: 1701.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7416,
        store_longitude: -74.0073,
        total_orders: 39.3,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Pizza, Vodka, Martini, Italian, Gin, Premium, Amazing, Iconic, Perfect, Colorful',
        annual_dol: 160.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7334,
        store_longitude: -74.005,
        total_orders: 184.92,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lasagna, Salad, Sushi, Italian, Wine, Delicious, Cozy, Romantic, French, Classy',
        annual_dol: 328.17,
        description:
            'Tuscan-style fare, wines & cocktails lie in store at this compact, romantic restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5657,
        store_longitude: -74.1005,
        total_orders: 44.09,
        neighborhood: 'New Dorp Beach',
        keywords:
            'Burger, Pizza, Tequila, Wine, Italian, Upscale, Meatball, Chilean, American, Romantic',
        annual_dol: 83.26,
        description:
            '"Serving Staten Island with an elevated Italian American cuisine! From flatbreads to steaks & seafood, there\'s something for everyone to enjoy!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7119,
        store_longitude: -73.6094,
        total_orders: 29.74,
        neighborhood: '',
        keywords: 'Steak, Salad, Spanish, Dominican, American, Latin, Local, Casual, Premium',
        annual_dol: 103.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7711,
        store_longitude: -73.9565,
        total_orders: 733.72,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Steak, Meatball, Salad, Beer, Mimosa, Coffee, American, Rum',
        annual_dol: 780.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7521,
        store_longitude: -73.9821,
        total_orders: 7.35,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Salad, Wine, Organic, Latin, Chic, American, Traditional, Great, Complex',
        annual_dol: 179.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8477,
        store_longitude: -73.8446,
        total_orders: 174.2,
        neighborhood: 'Morris Park/East Bronx',
        keywords: 'Spicy, Salad, Beer, Delicious, Margarita, Crisp, Thai, Sweet, Bbq, Lively',
        annual_dol: 736.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7901,
        store_longitude: -73.9734,
        total_orders: 1243.54,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Sushi, Japanese, Vegetarian, Roast Beef, Bbq, Spicy, Casual, Premium, Friendly',
        annual_dol: 2343.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8529,
        store_longitude: -73.8276,
        total_orders: 24.84,
        neighborhood: 'Pelham Bay',
        keywords:
            'Garlic Bread, Salad, Pizza, Italian, Shrimp Cocktail, Meatball, Casual, Local, Wonderful',
        annual_dol: 101.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7254,
        store_longitude: -73.9464,
        total_orders: 2929.49,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Tacos, Meatball, Nachos, Delicious, Cozy, Sweet, Amazing, Great, Local',
        annual_dol: 8064.68,
        description:
            'Easygoing hangout with nostalgic accents serving beers, along with burgers, hot dogs & fries.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.698,
        store_longitude: -73.9252,
        total_orders: 4489.12,
        neighborhood: 'Bushwick',
        keywords:
            'Coffee, Whiskey, Wine, Burger, Cozy, Irish, American, Friendly, Yummy, Handcrafted',
        annual_dol: 4684.31,
        description:
            'Chill, cozy haunt for espresso, cocktails & light bites, plus late hours & a pool table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7304,
        store_longitude: -73.954,
        total_orders: 2299.97,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Upscale, Clean, Friendly, Perfect, Casual, Wonderful, Hip, Creative',
        annual_dol: 3487.74,
        description: '"Your friendly neighborhood bar! Serving Cold Beer & Mixed Drinks daily!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9657,
        total_orders: 1.76,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Hamburger, Salad, Tuna Melt Sandwich, American, Casual, Seasonal, Whimsical, Local, Ample',
        annual_dol: 33.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.814,
        store_longitude: -73.9617,
        total_orders: 128.62,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Burger, Delicious, Spacious, Outstanding, Great, Cultural',
        annual_dol: 131.35,
        description:
            'International House New York, also known as I-House, is a private, independent, non-profit residence and program center for postgraduate students, research scholars, trainees, and interns, located at 500 Riverside Drive in Morningside Heights, Manhattan, New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9881,
        total_orders: 5.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Whiskey, Wine, Irish, Cozy, Casual',
        annual_dol: 22.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9877,
        total_orders: 423.46,
        neighborhood: 'Lower East Side',
        keywords: 'Sushi, Japanese, Delicious, Warm, Cozy, Polish, Greek, Crisp, Casual, Creative',
        annual_dol: 1595.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9843,
        total_orders: 43.32,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Sushi, Shrimp Cocktail, Beer, Wine, Italian, Bbq, American, Fresh, Excellent, Rooftop',
        annual_dol: 70.79,
        description:
            'The Hudson Theatre is a Broadway theater at 139–141 West 44th Street, between Seventh Avenue and Sixth Avenue, in the Theater District of Midtown Manhattan in New York City. One of the oldest surviving Broadway venues, the Hudson was built from 1902 to 1903. The exterior was designed by J. B.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7523,
        store_longitude: -73.9921,
        total_orders: 25.93,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Karaoke, Luxurious, Spicy, Burger, Delicious, Bbq, Korean, American, Chic, Intimate',
        annual_dol: 57.74,
        description:
            'Spacious, high-style karaoke bar with private rooms, a lounge area, Asian snacks & bottle service.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8661,
        store_longitude: -73.9277,
        total_orders: 364.3,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Sushi, Steak, Vegetarian, Wine, Japanese, Spicy, Sashimi, Delicious, Caribbean, Dominican',
        annual_dol: 405.62,
        description:
            'Late-night sushi house offers unique rolls, Japanese-Mexican fusion fare, cocktails & patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8167,
        store_longitude: -73.9579,
        total_orders: 74.43,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Salad, Wine, Delicious, Spanish, Southern, Israeli, Casual, Local, Authentic, Modern',
        annual_dol: 304.26,
        description:
            '"Acclaimed chef, author, autism activist and TV personality, Franklin Becker, has assembled some of the best culinary talent to create Manhattanville Market. His steadfast commitment to loyalty and inclusion is one reason his dedicated team has become more like family. Our food hall opens for breakfast at 8am and offers 4 fast casual food concepts; The Botanist, Shai, Benny Casanova and Butterfunk Biscuit. So, we welcome you to the table. Pull up a chair and join us!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6528,
        store_longitude: -73.5875,
        total_orders: 0.82,
        neighborhood: '',
        keywords:
            'Mexican, Salad, Italian, Dominican, Peruvian, Delicious, Spanish, Caribbean, Latin, American',
        annual_dol: 3.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7662,
        store_longitude: -73.9868,
        total_orders: 309.57,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Steak, Mexican, Salad, Tasty, Margarita, Delicious, Thai, Phenomenal, Fun',
        annual_dol: 1265.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7401,
        store_longitude: -73.9961,
        total_orders: 130.65,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Italian, Wine, Beer, Coffee, Martini, Trendy, Upscale, Meatball, Delicious, Romantic',
        annual_dol: 183.04,
        description:
            'Warm neighborhood restaurant & bar presenting seasonally driven Italian dishes from an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.74,
        store_longitude: -73.9975,
        total_orders: 198.46,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Coffee, Vibrant, Thai, Dynamic, Perfect, Diverse, Cultural',
        annual_dol: 747.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.9962,
        total_orders: 66.34,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Salad, Tasty, Wine, Vodka, Delicious, Beer, Mimosa, French, Mediterranean',
        annual_dol: 271.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.709,
        store_longitude: -73.9555,
        total_orders: 1952.91,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Cheesecake, Burger, Salad, Vegan, Dessert, Japanese, Wine, Delicious, Ramen, Asian',
        annual_dol: 2159.36,
        description:
            'Jewish & Japanese flavors fused together in creative small plates offered in a small, low-key space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6815,
        store_longitude: -73.9559,
        total_orders: 1232.52,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Tequila, Whiskey, Vodka, Wine, Coffee, Tasty, Cozy, American, Friendly',
        annual_dol: 1285.96,
        description:
            'Diverse craft beer, cocktails & regional wine served in a snug, low-key space with pinball machines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -74.0026,
        total_orders: 345.87,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Lasagna, Wine, Gluten Free, Mexican, Italian, Bruschetta, Contemporary, Bellini, Comfortable, Casual',
        annual_dol: 411,
        description:
            '"OSTERIA DEL PORTO IS A CONTEMPORARY NEW YORK ITALIAN WINE BAR RECENTLY OPENED IN THE HISTORIC SOUTH STREET SEAPORT DISTRICT OF LOWER MANHATTAN. OUR HOPE IS TO BRING A RELAXED, COMFORTABLE & TRULY MEMORABLE EXPERIENCE TO OUR GUESTS, PRESENTING A FRESH APPROACH TO THE FAMILIAR LANDSCAPE OF ITALIAN WINE & CUISINE"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.977,
        total_orders: 289.64,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Argentinian, Meatball, French Toast, American, French, Wine, Upscale, Thai, Ribeye Steak',
        annual_dol: 302.19,
        description:
            'Neighborhood bistro with New American fare, cocktails & craft beers in a bright, brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.9798,
        total_orders: 158.77,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Kebab, Salad, Dairy Free, Vegan, Vegetarian, Flavorful, Coffee, Mediterranean, Turkish, Rich',
        annual_dol: 354.08,
        description:
            'Down-to-earth cafe offering hummus & other traditional Mediterranean specialties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7216,
        store_longitude: -73.9585,
        total_orders: 1620.14,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Japanese, Hotel, Fabulous, Awesome, Ramen, Modern, Intimate, Rooftop, Hip',
        annual_dol: 6104.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -73.9777,
        total_orders: 906.36,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Steak, Hamburger, Beer, Wine, Mediterranean, Phenomenal, Cozy, American, Perfect',
        annual_dol: 1096.88,
        description:
            '"Be invited into a world most only read about. Cozy up to the bar worn-in by years of unbelievable tales (and the cocktails that made them). Order yourself a finely crafted beverage, and become a part of the party. Or grab a seat in the lush garden -- the perfect and slightly hidden setting for a bottle of wine (or three) amongst friends both old and new. Part French, part Uptown, all Aunt Bernie\'s Bar and Jardin. Party Venue -- book Corporate Events, Cocktail Parties, Private Event space"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.9831,
        total_orders: 66.98,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vegetarian, Tequila, Wine, Vodka, Butter Chicken, Margarita, Gin, Upscale, Homemade, Prosecco',
        annual_dol: 131.14,
        description:
            'Upscale restaurant featuring imaginative Indian fare & a full bar in a warm, modern setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7276,
        store_longitude: -74.0001,
        total_orders: 724.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Pizza, Italian, Wine, Warm, Casual, Fun, Hip, Unpretentious, Traditional',
        annual_dol: 740.12,
        description: 'Small Italian eatery serving Roman fare alongside an all-Italian wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7703,
        store_longitude: -73.7934,
        total_orders: 138.47,
        neighborhood: 'Flushing/Murray Hill',
        keywords:
            'Margarita, Mexican, Dessert, Coffee, Peruvian, Delicious, Hotel, Chinese, Latin, American',
        annual_dol: 344.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.9975,
        total_orders: 43.2,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Burger, Vegan, Steak, Vegetarian, Gluten Free, Butter Chicken, Coffee, Tasty, Cozy, Healthy',
        annual_dol: 371.4,
        description:
            'Lively bistro executing American-Mediterranean cooking & classic cocktails in understated digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7266,
        store_longitude: -74.0021,
        total_orders: 753.95,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Cornbread, Salad, Shrimp Cocktail, Turkey Sandwich, Wine, Beer, Dutch, American, Mediterranean',
        annual_dol: 786.64,
        description:
            'Regional American fare (including oysters and much-hyped fried chicken) in a lively, modern space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -74.0019,
        total_orders: 28.23,
        neighborhood: 'South Village/SoHo',
        keywords: 'Chinese, Steak, Peking Duck, Wine, Organic, Daiquiri, Spicy, Fried Rice, Casual',
        annual_dol: 115.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7211,
        store_longitude: -73.9833,
        total_orders: 1634.03,
        neighborhood: 'Lower East Side',
        keywords: 'Champagne, Beer, Funky, Eclectic, Classy, Fun, Lively, Perfect, Cool, Classic',
        annual_dol: 1704.88,
        description:
            'An eclectic roster of local electric & acoustic acts play at this funky East Village mainstay.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.9784,
        total_orders: 2392.95,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Upscale, Wine, Pizza, Modern, Contemporary, Asian, Beer, French, Polished, Authentic',
        annual_dol: 10446.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8658,
        store_longitude: -73.9274,
        total_orders: 300.19,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords: 'Mexican, Tacos, Steak, Vodka, Nachos, Margarita, Wine, American, Latin, Trendy',
        annual_dol: 580.35,
        description:
            'Late-night Mexican grill & agave bar offering classic eats, happy hour, live music & outdoor tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8399,
        store_longitude: -73.9165,
        total_orders: 4.8,
        neighborhood: 'Mount Eden/West Bronx',
        keywords:
            'Flavorful, Mexican, South American, Delicious, Dominican, Spanish, Friendly, Caribbean, American, Clean',
        annual_dol: 19.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7412,
        store_longitude: -74.0012,
        total_orders: 65.17,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Chocolate Cake, Chocolate Mousse, Dessert, Steak, Homey, Yummy, Mediterranean, Lively, American, Fun',
        annual_dol: 72.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6804,
        store_longitude: -73.9744,
        total_orders: 1668.11,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords: 'Wine, Beer, Vodka, Stylish, Elegant, Cozy, Sweet, Creamy, Vintage, Bright',
        annual_dol: 1817.98,
        description:
            'Cozy-chic bar for cocktails (plus wines & New York beers) with oysters, cheeses & small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6874,
        store_longitude: -73.9824,
        total_orders: 235.95,
        neighborhood: 'Boerum Hill',
        keywords:
            'Burger, Steak, Meatloaf, Mexican, Bbq, Russian, Homemade, Casual, Authentic, Fresh',
        annual_dol: 964.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7407,
        store_longitude: -73.9956,
        total_orders: 13.15,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Upscale, Gin, Vintage, Asian, Modern, Ramen, Great, Fine',
        annual_dol: 53.75,
        description:
            'Upscale, family-owned store offering one-of-a-kind furnishings & vintage jewelry from Europe & Asia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Sangria, Rooftop, Seasonal, Perfect, Latin, Fine, Hummus',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -73.9598,
        total_orders: 3064.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Kebab, Vegetarian, Moroccan, Wine, Spicy, Tunisian, Organic, Mediterranean, French',
        annual_dol: 3393.45,
        description:
            'Buzzy outfit serving traditional Moroccan tagines, couscous & more in comfy yet charming quarters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7083,
        store_longitude: -74.0015,
        total_orders: 962.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Lobster, Salad, Chilean, Canadian, French, French Onion Soup, Delicious, Elegant',
        annual_dol: 1004.15,
        description:
            'Tucked-away locale serving steaks, chops & seafood dishes in white-tablecloth surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7279,
        store_longitude: -73.9793,
        total_orders: 836.29,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Vegan, Wine, Italian, Vodka, Warm, Ramen, Asian',
        annual_dol: 1138.45,
        description:
            'Raw oysters & seafood bites with Asian influences are served with craft cocktails in a narrow space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7058,
        store_longitude: -74.0052,
        total_orders: 278.15,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Luxurious, Cosmopolitan, Spacious, Sleek, Urban, Classic, Bright, Modern, Clean',
        annual_dol: 290.21,
        description:
            'The sleek rooms offer flat-screen TVs, free Wi-Fi, and minifridges, as well as tea and coffeemaking facilities, desks and sitting areas. Some have city views.Amenities include a gym and a hip lounge. Breakfast is available (fee), and is served in a modern dining room that has city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6801,
        store_longitude: -73.9682,
        total_orders: 518.35,
        neighborhood: 'Prospect Heights',
        keywords: 'Beer, Martini, Wine, Phenomenal, Casual',
        annual_dol: 747.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -74.0045,
        total_orders: 1069.29,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegetarian, Shrimp Cocktail, Gluten Free, Beer, American, Polished, Casual, Friendly, Classic, Local',
        annual_dol: 3855.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.678,
        store_longitude: -73.9686,
        total_orders: 681.68,
        neighborhood: 'Prospect Heights',
        keywords: 'Burger, Wine, Beer, Spicy, Italian, Ale, French, Fun, Asian, Seasonal',
        annual_dol: 711.23,
        description:
            'Simple dinner & brunch menu of re-conceived American classics in hipster-friendly surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7433,
        store_longitude: -73.9771,
        total_orders: 227.4,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Beer, Tasty, Wine, Spacious, Friendly, Cool, Amazing, Great, Casual, Local',
        annual_dol: 929.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -73.9832,
        total_orders: 2993.27,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Wine, Salad, Beer, Hotel, Mediterranean, Funky, Eclectic, Classy, Legendary, Urban',
        annual_dol: 3058.75,
        description:
            'Classy yet funky concert venue with diverse acts offers table service in theater & bar in front.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7744,
        store_longitude: -73.964,
        total_orders: 48.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Luxurious, Upscale, Hotel, Spacious, Exquisite, Premium, Perfect, Rooftop, Modern, Fine',
        annual_dol: 197.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.694,
        store_longitude: -73.9459,
        total_orders: 336.56,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Salad, Dessert, Tasty, Crab Rangoon, Ramen, Asian, Caribbean, Friendly, French, Dope',
        annual_dol: 485.17,
        description:
            'Plant-filled corner haunt serving up fusion plates & house cocktails in a relaxed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7668,
        store_longitude: -73.9625,
        total_orders: 110.06,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Mexican, Beer, Salad, Healthy, Local, Casual, Premium, Authentic, Indian, Unique',
        annual_dol: 449.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -73.9908,
        total_orders: 10705.84,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Burger, Steak, Salad, Korean, Beer, Wine, Spicy, Ale, Bbq, Gin',
        annual_dol: 11669,
        description: 'Clubby Korean gastropub & lounge with a selection of small dishes & 2 bars.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.828,
        store_longitude: -73.8808,
        total_orders: 38.1,
        neighborhood: 'East Bronx/Soundview',
        keywords: 'Mexican, Caribbean, Casual, Eclectic',
        annual_dol: 155.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9883,
        total_orders: 1727.63,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Premium, Fun, Irish, Cool, Latin, Great, Casual',
        annual_dol: 1764.28,
        description:
            'Busy bar on first floor with a dark, smaller lounge above, where a DJ keeps the dancing going.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7324,
        store_longitude: -73.988,
        total_orders: 569.48,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Beer, Wine, New-York-Style, Cozy, Charming, Fun, Lively, Perfect, Cool, Outstanding',
        annual_dol: 1395.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5398,
        store_longitude: -74.1484,
        total_orders: 105.15,
        neighborhood: 'Great Kills',
        keywords: 'Italian, Pizza, Turkish, Upscale, Delicious, Local, Casual, Great, Diverse',
        annual_dol: 165.81,
        description:
            'Upscale bar & grill with outdoor patio featuring Italian entrees, pizza, pasta & housemade desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5973,
        store_longitude: -74.0768,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Vodka, Salad, Dessert, Italian, Delicious, Gourmet, Warm, Comfortable, Sweet, French',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7529,
        store_longitude: -73.9727,
        total_orders: 1630.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Steak, Salad, Beer, Irish, Club Sandwich, Romantic, Friendly, Perfect, Casual',
        annual_dol: 2265.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7517,
        store_longitude: -73.9722,
        total_orders: 1878.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Beer, Hamburger, American, Margarita, Tasty, Karaoke, Classic, Hip, Iconic',
        annual_dol: 1918.86,
        description:
            'Classic cartoon art hangs on the walls & local teams play on big screen TVs at this Turtle Bay pub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.833,
        total_orders: 30.58,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Chinese, Beef Chow Fun, Japanese, Congee, Local, Fun, Dim Sum, Casual',
        annual_dol: 142.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.9734,
        total_orders: 4704.01,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Sushi, Salad, Lobster Bisque, Martini, Hotel, American, Polished, Retro',
        annual_dol: 4803.83,
        description:
            'Retro murals & swanky furnishings fill this exclusive American eatery with a powerful clientele.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.9665,
        total_orders: 982.23,
        neighborhood: 'Lenox Hill',
        keywords: 'Hamburger, Upscale, Delicious, Premium, Iconic, Perfect, Casual',
        annual_dol: 1071.98,
        description:
            '"On any given day, you could catch one of the world’s top stylists, a celebrity designer or your favorite influencer creating content in our first-of-its-kind photo and video studio, situated on our 59th Street flagship’s second floor. Oh, and did we mention it’s connected to a world-class bar and restaurant? (Translation: You can take in all the behind-the-scenes action of a Bloomingdale’s photo shoot while also enjoying a delicious cocktail.) Stop by on your next shopping trip—you never know what (or who) you might see."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6948,
        store_longitude: -73.9528,
        total_orders: 505.2,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Romantic, Comfortable, Delicious, Intimate, Casual, Great, Local',
        annual_dol: 738.02,
        description:
            '"Local bar in BedStuy serving beer, wine and cocktails. Backyard open. Dogs are welcome."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9904,
        total_orders: 186.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Pizza, Wine, Relaxed, Friendly, Yummy, Pinacolada, Casual, Fun, American',
        annual_dol: 432.13,
        description:
            'Burgers, wings, fries & other pub grub is served at this relaxed sports bar spotlighting craft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7487,
        store_longitude: -74.0033,
        total_orders: 2425.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Chinese, Spicy, Savory, Bbq, Asian, Thai, Eclectic, Fusion, Casual',
        annual_dol: 2643.67,
        description:
            'Casual Pan-Asian dining within a stylishly streamlined interior or out on the wood-plank patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7274,
        store_longitude: -74.0003,
        total_orders: 573.3,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Sushi, Salad, Shrimp Cocktail, Italian, Wine, Beer, Shrimp Scampi, Margarita, Fried Calamari',
        annual_dol: 585.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7254,
        store_longitude: -74.0034,
        total_orders: 1504.32,
        neighborhood: 'South Village/SoHo',
        keywords: 'Pizza, Beer, Coffee, Wine, American, Casual, Bright, Great, Traditional',
        annual_dol: 1583.17,
        description:
            'Intimate, brick-walled watering hole with comfy booths offering beer, wine & a tavern menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9871,
        total_orders: 32.53,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Flavorful, Vodka, Wine, Martini, Ale, Delicious, Greek, Phenomenal, Homemade',
        annual_dol: 88.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7215,
        store_longitude: -73.9846,
        total_orders: 116.42,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Salad, Tequila, Beer, Margarita, Fried Chicken, Irish, Australian, American, Warm',
        annual_dol: 407.74,
        description:
            'Laid-back restaurant & bar offering elevated pub grub & aviator-themed cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7649,
        store_longitude: -73.9959,
        total_orders: 190.86,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Tequila, Rooftop, American, Nachos, Modern, Salad, Latin, Innovative, Fun',
        annual_dol: 780.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5257,
        store_longitude: -74.2009,
        total_orders: 51.82,
        neighborhood: "Prince's Bay",
        keywords:
            'Pizza, Salad, Italian, Gourmet, Delicious, Modern, Bright, Great, British, Casual',
        annual_dol: 211.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6821,
        store_longitude: -73.9796,
        total_orders: 161.39,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Ale, American, Lively, Authentic, Classic, Cool, Great, Wonderful, Casual',
        annual_dol: 611.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7356,
        store_longitude: -73.9979,
        total_orders: 552.77,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Burger, Tacos, Wine, Coffee, Beer, Chinese, Ale, American, Asian, Classic',
        annual_dol: 2259.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -73.9842,
        total_orders: 769.55,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Pizza, Vodka, Italian, Salad, Wine, Delicious, Shrimp Scampi, Pesto Pasta, Fresh, Great',
        annual_dol: 769.55,
        description:
            'Casual Staten Island offspring featuring house-specialty thin-crust pies, pasta & Italian entrees',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.717,
        store_longitude: -73.9501,
        total_orders: 2099.71,
        neighborhood: 'Williamsburg',
        keywords: 'Coffee, Tasty, Wine, Beer, Stylish, Spicy, Martini, Delicious, Savory, Italian',
        annual_dol: 2234.5,
        description:
            'Stylish cafe by day & restaurant-meets-cocktail bar by night offers cocktails & sharable plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6731,
        store_longitude: -73.9573,
        total_orders: 801.48,
        neighborhood: 'Crown Heights',
        keywords:
            'Pizza, Salad, Italian, Chicken Parmigiana, Vodka, Wine, Meatball, Beer, Delicious, Cozy',
        annual_dol: 833.32,
        description:
            'This hip Italian joint offers a full menu of entrees plus design-your-own meals of handmade pasta.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7073,
        store_longitude: -74.0023,
        total_orders: 698.74,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Italian, Delicious, Warm, Sweet, Friendly, Bellini, Casual, Fresh, Local, Great',
        annual_dol: 729.04,
        description:
            'Elevated Italian fare in a rustic-chic setting accented by exposed brick & reclaimed wood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7056,
        store_longitude: -74.0045,
        total_orders: 2055.33,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Beer, Wine, Margarita, German, Gourmet, American, Magical, Warm, Modern',
        annual_dol: 2268.19,
        description:
            'This stylish, open-air cocktail spot overlooking the East River features burgers & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.6979,
        total_orders: 347.07,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Hamburger, Pizza, Vegetarian, Margarita, Cuban, American, Irish, Casual',
        annual_dol: 497.03,
        description:
            'Hut decorated with surfboards, offering seasonal beers on tap, casual Tex-Mex food & Sunday brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.9775,
        total_orders: 19.26,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Cajun, Cheesesteak, Wine, Beer, French, French Onion Soup, Philly Cheesesteak',
        annual_dol: 78.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7494,
        store_longitude: -73.9832,
        total_orders: 1368.87,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Salad, Beer, Wine, Irish, Warm, Comfortable, Friendly, Casual, Fantastic',
        annual_dol: 1417.07,
        description:
            'Standard Irish pub with Guinness on draft, fish & chips on the menu & plenty of drink deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.9257,
        total_orders: 103.6,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Beer, Wine, Karaoke, Rich, American, Delicious, Traditional, Local, Great, Casual',
        annual_dol: 115.58,
        description:
            'Local hangout steps from the N train, offering drink specials, bar food & lots of TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.983,
        total_orders: 1235.14,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Beer, Mexican, Wine, Bourbon, Bbq, Irish, American, Contemporary, Intimate, Friendly',
        annual_dol: 1289.54,
        description:
            '"5th& MAD located in Midtown Manhattan is a bar, restaurant and so much more. A bi-level space that lends itself perfectly to hosting events for groups of 10 to 600. INSIDER at 5th& MAD is our new restaurant taking center stage on the main floor. Head Chef Carlos Cruz and his team have put together a menu that is perfect for sharing. We have two full-service bars. The 40-foot bar on the main floor marries the casual bar vibe that runs throughout with a casual dining experience while The Liquor Rooms on the mezzanine level is the perfect intimate gathering place for an after work catch up, pre-show cocktails, that secret rendezvous or a late night party!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7249,
        store_longitude: -73.9479,
        total_orders: 771.38,
        neighborhood: 'Greenpoint',
        keywords:
            'Steak, Tasty, Seafood Paella, Phenomenal, Friendly, Fun, Casual, Local, Great, Unique',
        annual_dol: 886.63,
        description:
            'Industrial-style venue featuring grilled meats, seafood & other Portuguese classics, plus sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -73.9596,
        total_orders: 1056.78,
        neighborhood: 'Greenpoint',
        keywords:
            'Lobster, Spanish, Mexican, Martini, Wine, Italian, Polish, Perfect, Fresh, Great',
        annual_dol: 4319.76,
        description:
            '"A Spanish seafood bar, serving cocktails, wine, sherry, raw bar, and conservas."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.691,
        store_longitude: -73.9249,
        total_orders: 1167.49,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Burger, Beer, Mexican, Vodka, Cool, Great, Campari, Casual',
        annual_dol: 1298.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6976,
        store_longitude: -73.9361,
        total_orders: 1457.74,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Steak, Hotdog, Beer, Coffee, American, Organic, Modern, Casual, Local, Fresh',
        annual_dol: 1520.94,
        description:
            'Cool, brick-lined haunt for modern American comfort food & craft cocktails, plus espresso drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -73.9464,
        total_orders: 395.34,
        neighborhood: 'Williamsburg',
        keywords: 'Shrimp Cocktail, Great, Casual',
        annual_dol: 523.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6918,
        store_longitude: -73.9429,
        total_orders: 16.76,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Salad, Dessert, Venezuelan, Colombian, Dominican, Delicious, Trendy, Latin, Sweet, American',
        annual_dol: 26.46,
        description:
            'This family-run Venezuelan eatery serves arepas, empanadas & exotic juices in a no-frills setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7312,
        store_longitude: -73.9889,
        total_orders: 243.79,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Vegan, Gluten Free, Vegetarian, Tasty, Italian, Beer, Asian, Tex-Mex, Healthy, Classy',
        annual_dol: 996.54,
        description:
            '"TLK (Tiger Lily Kitchen) is a female owned NYC restaurant and bar that offers healthy gluten free Asian dining with vegan options."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8514,
        store_longitude: -73.9397,
        total_orders: 1212.3,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords: 'Burger, Sushi, Pizza, Vegan, Salad, Mexican, Beer, Italian, Wine, Peruvian',
        annual_dol: 1264.86,
        description:
            'Eclectic establishment with an Irish theme, many beers & a menu of pub grub & New American entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7936,
        store_longitude: -73.9723,
        total_orders: 345.93,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Burger, Vegetarian, Salad, Roast Turkey, Mexican, Turkey Sandwich, Canadian, Coffee, Roast Beef',
        annual_dol: 368.66,
        description:
            'Retro kitschy decor sets the scene for traditional diner fare from egg creams to pork chops.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8045,
        store_longitude: -73.9668,
        total_orders: 110.89,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Mexican, Local, Casual, Healthy, Recommended',
        annual_dol: 575.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.801,
        store_longitude: -73.9618,
        total_orders: 118.06,
        neighborhood: 'Manhattan Valley/UWS',
        keywords: 'Italian, Pizza, Delicious, Wine, Smoky, Authentic, Fresh, Traditional, Casual',
        annual_dol: 148.27,
        description:
            'Casual, earthy Italian restaurant offering wood-fired pizza, wine & spirits & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7537,
        store_longitude: -73.9681,
        total_orders: 3713.75,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Japanese, Upscale, Casual, Chill, Fun, Great',
        annual_dol: 4006.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6139,
        store_longitude: -74.033,
        total_orders: 275.08,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Pizza, Italian, Vegetarian, Gourmet, Dessert, Delicious, Fresh, Polished, Sophisticated, Awesome',
        annual_dol: 287.01,
        description:
            'Sophisticated Italian-American eatery serving coal-fired pizzas, baked sandwiches & wings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9688,
        total_orders: 884.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Tasty, Trendy, Delicious, Mexican, Warm, Romantic, Salad, Cozy, Inviting',
        annual_dol: 964.17,
        description:
            'Woody wine bar with Old World & California wines, plus simple eats & an all-you-can-drink brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9844,
        total_orders: 24132.87,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Elegant, Wine, Urban, Modern, Great, Rooftop, Friendly, Bold, Cool',
        annual_dol: 27167.68,
        description:
            "Understated rooms with floor-to-ceiling windows provide Wi-Fi, HDTVs and minibars. Upgraded rooms have city views. Suites add sofas, furnished balconies and/or interconnecting bedrooms. Room service is offered 24/7.There's an elegant bar, and a restaurant with a rooftop terrace overlooking Times Square. Other amenities include an entertainment venue and a 24-hour gym. Valet parking is available, as is breakfast.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7414,
        store_longitude: -73.9928,
        total_orders: 22242.17,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Steak, Burger, Upscale, Wine, Nachos, Spicy, American, Spacious, Shawarma, Modern',
        annual_dol: 22258.25,
        description:
            'Spacious sports bar featuring creative cocktails & pub fare with a twist, plus frequent DJ music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.779,
        store_longitude: -73.9507,
        total_orders: 740.43,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Wine, Delicious, Irish, Fun, Friendly, Wonderful, Great, Casual, Local',
        annual_dol: 806.95,
        description:
            'Veteran watering hole offering sports on TV, darts & more in a relaxed, no-frills setup.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8727,
        store_longitude: -73.8542,
        total_orders: 473.09,
        neighborhood: 'East Bronx/Williamsbridge/Laconia',
        keywords:
            'Steak, Chicken Parmigiana, Cajun, Shrimp Cocktail, Argentinian, Bbq, Tasty, Bbq Ribs, French, American',
        annual_dol: 597.81,
        description:
            'Comfort food, cocktails & desserts served in a classic chrome & mirror diner setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6271,
        store_longitude: -73.6403,
        total_orders: 538.99,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Salad, Cajun, Fried Clams, Cajun-Style, Bbq, Beer, Fried Shrimp',
        annual_dol: 1546.52,
        description:
            'Unpretentious, energetic seafood eatery specializing in crabs, oysters, shrimp & other shellfish.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9895,
        total_orders: 16.71,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Spanish, Italian, Trendy, Delicious, Filet Mignon, Casual, Local, Classic',
        annual_dol: 68.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7546,
        store_longitude: -73.9988,
        total_orders: 1644.41,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Wine, Italian, Comfortable, Beer, Clean, Friendly, Modern, Great, Local',
        annual_dol: 2118.7,
        description:
            'The relaxed, airy rooms provide free Wi-Fi, flat-screen TVs, minifridges, and tea and coffeemakers. Suites add sofabeds and/or living rooms. Some offer city views.Amenities include a restaurant/bar, a 24-hour fitness center and coin-operated laundry facilities. Breakfast is available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.9863,
        total_orders: 1324.49,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Rooftop, Hotel, Wine, Beer, Adventurous, Romantic, Awesome, Amazing, Great, Perfect',
        annual_dol: 5414.09,
        description:
            '"Very casual rooftop bar with an amazing view of the Empire State Building. K-32 has one of the best craft beer selection along with wide selection of wines & spirits and we also offer bar food"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6907,
        store_longitude: -73.9158,
        total_orders: 117.22,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Coffee, Ale, Wine, Upscale, Casual, Chill, American, Friendly',
        annual_dol: 479.15,
        description: '"Local bar. Drinks and snacks."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8384,
        store_longitude: -73.783,
        total_orders: 368.09,
        neighborhood: 'City Island',
        keywords: 'Great, Diverse',
        annual_dol: 400.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.675,
        store_longitude: -74.0163,
        total_orders: 753.4,
        neighborhood: 'Red Hook',
        keywords:
            'Lobster, Lobster Roll, Shrimp Cocktail, Wine, Beer, Spicy, Sweet, Perfect, Relaxed, Great',
        annual_dol: 1025.62,
        description:
            'Relaxed seafood shack offering crab & raw oysters, plus outdoor seating with water views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7486,
        store_longitude: -73.9427,
        total_orders: 12.44,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Wine, Coffee, Salad, Elegant, Crisp, Italian, Hotel, Amazing, Fresh, Great',
        annual_dol: 50.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7339,
        store_longitude: -73.9911,
        total_orders: 5676.87,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Chocolate Cake, Cheesecake, Steak, Vegetarian, Gluten Free, Dessert, Coffee, Italian, Savory',
        annual_dol: 5797.33,
        description:
            'Retailer offering chocolate & related novelties, including seasonal & special-occasion gifts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7319,
        store_longitude: -73.9964,
        total_orders: 1229.55,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Tequila, Whiskey, Vodka, Gin, Italian, Rich, Rum, Warm, Cozy',
        annual_dol: 1282.86,
        description:
            'Old-fashioned restaurant offering Italian-inspired meals & cocktails, plus happy-hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -73.9944,
        total_orders: 88.8,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Chinese, American, Modern, Local, Casual, Authentic, Artistic',
        annual_dol: 362.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7308,
        store_longitude: -73.9858,
        total_orders: 580.07,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegan, Pizza, Italian, Gluten Free, Wine, Meatball, Beer, Casual, Traditional, Friendly',
        annual_dol: 605.22,
        description:
            'Classic red-sauce specialties and vegan versions are served at this vintage-1908 Italian eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9889,
        total_orders: 141.83,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Sushi, Salad, Wine, Coffee, Japanese, Beer, Sashimi, Spicy, Casual, Local',
        annual_dol: 534.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -73.9859,
        total_orders: 10.82,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Korean, Pizza, Mexican, Norwegian, Coffee, Turkey Sandwich, Cozy, Upscale, Modern, Casual',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8508,
        store_longitude: -73.9325,
        total_orders: 139.26,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Mexican, Tacos, Delicious, Premium, Authentic, Casual, Omelette, Great, Incredible',
        annual_dol: 158.85,
        description:
            'Low-key eatery offering familiar Mexican eats, especially tacos, plus margaritas & sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7533,
        store_longitude: -73.9689,
        total_orders: 2349.96,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Karaoke, Fun, Great, Perfect, Casual',
        annual_dol: 2809.45,
        description:
            'A selection of rooms, a huge song list & a full drinks menu adds to the fun at this Karaoke palace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.9773,
        total_orders: 5055.94,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Italian, Tasty, Salad, Upscale, Elegant, Hotel, Bellini, Premium, Classic, Fresh',
        annual_dol: 5275.15,
        description:
            'Upscale scene where an international crowd tucks into Italian food chased with Bellinis.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.7715,
        total_orders: 7.88,
        neighborhood: 'Bayside',
        keywords: 'Korean, Beer, Trendy, Wine, Spicy, Bbq, Eclectic, Peruvian, Japanese, Asian',
        annual_dol: 37.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.8335,
        total_orders: 502.8,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Steak, Lobster, Salad, Wine, Swiss, French, Hotel, Premium, Asian, Fresh',
        annual_dol: 2055.28,
        description:
            '"Prime Met Steakhouse is the newest Rooftop + Lounge experience in Flushing 12th floor of the new Renaissance New York Flushing Hotel at Tangram"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9746,
        total_orders: 3019.4,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Lobster, Steak, Salad, Shrimp Cocktail, Italian, Coffee, Wine, Martini, Delicious',
        annual_dol: 3165.32,
        description:
            'Handsome American restaurant by designer Ralph Lauren, serving classic dishes, bar bites & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.715,
        store_longitude: -74.0084,
        total_orders: 2112.93,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Hotdog, Burger, Beer, Whiskey, Wine, Fun, Irish, Friendly, Perfect, Classy',
        annual_dol: 2613.67,
        description:
            'Bar with a honky-tonk feel, country tunes on the jukebox & female bartenders.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -74.0094,
        total_orders: 367.29,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Beer, Italian, Casual',
        annual_dol: 433.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.9692,
        total_orders: 235.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Beer, Zombie, Salad, Margarita, Spicy, Irish, Fun, Smoky, Sweet',
        annual_dol: 361.66,
        description:
            'Beer hall devoted to craft brews on tap, plus a range of burgers, shareable bites & loaded waffles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6766,
        store_longitude: -73.6824,
        total_orders: 568.18,
        neighborhood: '',
        keywords: 'Pizza, Italian, Wine, Rustic, Casual, Southern, Local, Authentic, Chic, Fresh',
        annual_dol: 697.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5833,
        store_longitude: -73.9865,
        total_orders: 120.3,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Salad, Burritos, Delicious, Warm, Local, Casual, Signature',
        annual_dol: 553.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7431,
        store_longitude: -73.9895,
        total_orders: 2022.24,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Tequila, Chocolate Cake, Tacos, Vodka, Fried Catfish, Beer, Margarita, Wine, Martini, Tasty',
        annual_dol: 2069.06,
        description:
            'Luxury sports bar co-owned by hip-hop mogul Jay-Z, with huge plasma TVs & multiple private lounges.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9911,
        total_orders: 343.43,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords: 'Flavorful, Tasty, Beer, Vietnamese, Coffee, Stylish, Thai, Latin, Sweet, Classy',
        annual_dol: 1293.93,
        description:
            'Stylish option serving banh mi sandwiches, pho, noodles & meat dishes, plus smoothies & iced coffee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6948,
        store_longitude: -73.953,
        total_orders: 3232.95,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Beer, Spacious, Sweet, American, Vintage, Japanese, Great, Cool, Excellent, Traditional',
        annual_dol: 3989.19,
        description:
            'Cocktails & beer offered in a narrow, humble space with vintage furniture & backyard picnic tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7387,
        store_longitude: -73.9835,
        total_orders: 1671.75,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Beer, Ale, Bourbon, Relaxed, Delicious, Hotel, Friendly, American, Moscow Mule, Casual',
        annual_dol: 1707.23,
        description:
            'Relaxed, dimly-lit destination for handcrafted cocktails, plus brews on tap & New American bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7384,
        store_longitude: -73.9857,
        total_orders: 9897.47,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Wine, Hotel, Rich, Upscale, Trendy, Apple Pie, Romantic, Premium, Rum, Gin',
        annual_dol: 34745.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6955,
        store_longitude: -73.9964,
        total_orders: 596.59,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Fun, Great',
        annual_dol: 670.65,
        description:
            'Established members-only squash club also offering indoor tennis courts, dining & a fitness center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.741,
        store_longitude: -73.9903,
        total_orders: 498.49,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Salad, Swiss, Russian, Delicious, Vintage, Classic, Reuben, Casual, Fresh, Great',
        annual_dol: 2109.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9916,
        total_orders: 189.91,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Salad, Margarita, Burritos, Delicious, Local, Casual',
        annual_dol: 772.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7702,
        store_longitude: -73.9095,
        total_orders: 147.32,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'Cheesecake, Trendy, French, Premium, Greek, Great, Casual',
        annual_dol: 173.27,
        description:
            'Comfy hookah lounge with a full bar, lots of TVs, pool tables, patio, Greek eats & trendy decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7253,
        store_longitude: -74.0038,
        total_orders: 828.05,
        neighborhood: 'South Village/SoHo',
        keywords: 'Wine, Exquisite, Healthy, Perfect, Great, Fresh, Fine, Falafel, Scallops',
        annual_dol: 3119.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7793,
        store_longitude: -73.9776,
        total_orders: 1278.07,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Pizza, Italian, Gluten Free, Meatball, Bellini, Bruschetta, Delicious, Homemade, Artisanal',
        annual_dol: 1401.63,
        description:
            'Longtime Italian eatery with a pasta-centric menu & casual quarters complete with sidewalk seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8028,
        store_longitude: -73.9564,
        total_orders: 81.5,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Steak, Vegan, Hamburger, Salad, Tasty, Wine, Beer, Italian, French Toast',
        annual_dol: 137.87,
        description:
            'Corner tavern/beer garden with extensive food & drink menus in a spacious indoor/outdoor setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.9819,
        total_orders: 1556.56,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Champagne, Wine, Upscale, Sparkling, Romantic, Delicious, Whiskey, Premium, Fun, Cozy',
        annual_dol: 1624.05,
        description:
            'Upscale bar dedicated to champagne, with a candlelit lounge, fireplaces & weekly live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.985,
        total_orders: 3401.73,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Wine, Japanese, Trendy, Spicy, Cozy, Friendly, Sweet, Casual, Local',
        annual_dol: 3631.49,
        description:
            'Cozy, brick-walled Japanese eatery & sake bar offering yakitori, noodles & set-price party menus.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8123,
        store_longitude: -73.9515,
        total_orders: 3137.69,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Wine, French Toast, Coffee, French, Rustic, Beer',
        annual_dol: 3522.73,
        description:
            'French bistro classics, including breakfasts, in a light-filled space with exposed-brick walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7629,
        store_longitude: -73.986,
        total_orders: 1379.13,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Tasty, Italian, French Toast, Stylish, Thai, French, Dutch, Spectacular, American',
        annual_dol: 1421.8,
        description:
            'Stylish eatery serving elevated food & high-concept cocktails, plus seating in a train car replica.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9835,
        total_orders: 1741.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Mexican, Steak, Tex-Mex, Tasty, Spectacular, Latin, Mojito, Perfect, Rooftop, Great',
        annual_dol: 1816.64,
        description:
            'Lounge, Latin-style club & Mexican eatery over 3 floors at one Theater District address.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7646,
        store_longitude: -73.9825,
        total_orders: 17.21,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Applejack, Salad, Steak, Shrimp Cocktail, Wine, French Toast, Tasty, Swiss, French, Thai',
        annual_dol: 34.73,
        description:
            'Old-style diner with a classic menu, situated near Theater District venues & Midtown hotels.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -73.9872,
        total_orders: 2113.56,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Ale, Spacious, Dominican, Ample, Fancy, Casual, Laid-Back, Lit',
        annual_dol: 2205.19,
        description: 'Casual bar with a film-noir vibe, draft beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.722,
        store_longitude: -73.9931,
        total_orders: 8245.19,
        neighborhood: 'Bowery',
        keywords: 'Hotel, Wine, Beer, Gin, French, Cozy, Rooftop, Juicy, Warm, Crisp',
        annual_dol: 9826.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.721,
        store_longitude: -73.9898,
        total_orders: 2629.35,
        neighborhood: 'Lower East Side',
        keywords:
            'Whiskey, Elegant, Cozy, Intimate, Perfect, Awesome, Sidecar, Fantastic, Casual, Local',
        annual_dol: 3980.56,
        description:
            'Serves hundreds of whiskeys & other liquors in a lean space with stand-up & sit-down bars.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9106,
        store_longitude: -73.8968,
        total_orders: 32,
        neighborhood: 'North Riverdale',
        keywords:
            'Wine, Delicious, Spanish, American, Latin, Fresh, Great, Traditional, Casual, Attentive',
        annual_dol: 130.81,
        description:
            '"Best of Both Worlds, Spanglish Restaurant Group brings traditional Spanish and American Dishes to the Bronx. Located on the Border of Bronx /Yonkers"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7654,
        store_longitude: -73.9759,
        total_orders: 61.33,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Luxurious, Hotel, Stylish, Iconic, Dessert, Premium, Healthy, Innovative, Vibrant',
        annual_dol: 250.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -74.0006,
        total_orders: 2132.31,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Salad, Dessert, Gin, Beer, Homemade, Sweet, Modern, Casual, Rare, Classic',
        annual_dol: 2224.76,
        description:
            'Popular neighborhood watering hole welcomes regulars with a pool table & jukebox till the wee hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7083,
        store_longitude: -74.0017,
        total_orders: 246.64,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Thai, Salad, Tasty, Steak, Vegan, Organic, Traditional, Cozy, Authentic, Contemporary',
        annual_dol: 448.76,
        description:
            '"We have a cozy indoor "Thai Garden" dining room and heated patios and delivery to all Downtown Manhattan! Located in the historic district of South Street Seaport, on the corner of Peck Slip and Water Street, THAI Farm Kitchen Seaport is an organic authentic Thai restaurant! We offer Thai Food Delivery in all of Downtown New York. We cook the best TOM YUM, TOM KHA soup. Authentic Green Papaya Salad, PHAD THAI, and Green Curry. And many more Thai dishes! Thai Farm Kitchen Seaport offers organic, authentic, and traditional Thai cuisine! We are using the highest quality ingredients, organic produce, and characterized by bold flavors and typical Thai “farm to table” serving style! Best Thai Restaurant in New York!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7157,
        store_longitude: -73.9444,
        total_orders: 1181.9,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Wine, Beer, Mediterranean, Creamy, Delicious, American, Casual, Welcoming, Caribbean',
        annual_dol: 1771.7,
        description:
            'American joint serving a hearty, vegetable-focused menu plus beer & wine in a tiny space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.714,
        store_longitude: -73.9609,
        total_orders: 181.73,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Whiskey Sour, Steak, Flavorful, Whiskey, Wine, Beer, Salad, Martini, Four Horsemen, Mediterranean',
        annual_dol: 318.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7252,
        store_longitude: -73.983,
        total_orders: 984.14,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Salad, Argentinian, Wine, Delicious, Brazilian, Authentic, Fun, Sangria, Excellent',
        annual_dol: 1071,
        description:
            'Skirt steak, fries & plenty of chimichurri sauce are washed down with wines, including many malbecs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 39.98,
        neighborhood: 'New Springville/Mid Island',
        keywords: 'Mexican, Warm, Delicious, Local, Healthy, Casual, Perfect',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7457,
        store_longitude: -73.9847,
        total_orders: 681.92,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Pizza, Korean, Spicy, Delicious, Chinese, Asian, American, Hotel, Bibimbap, Casual',
        annual_dol: 2787.46,
        description:
            '"PALPAL, a lively space with an open Wok-station, features a variety of Korean dishes with Asian touch, energy and heat from the Wok."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.807,
        store_longitude: -73.9276,
        total_orders: 2336.52,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Mexican, Salad, Turkey Sandwich, Beer, Bbq, Ale, American, Pulled Pork, Coleslaw, Southern',
        annual_dol: 4543.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7204,
        store_longitude: -74.0054,
        total_orders: 1127.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Cheesecake, Cheesesteak, Burger, Pizza, Steak, Mexican, Salad, Whiskey, Beer, Wine',
        annual_dol: 1417.96,
        description:
            'Loftlike tavern & bar offering burgers & pizzas, many beers on tap & TVs to watch a game.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -73.9944,
        total_orders: 52.42,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Steak, Coffee, Salad, Green Curry, Bbq, French Toast, Red Eye, French, Rooftop',
        annual_dol: 214.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7194,
        store_longitude: -73.8423,
        total_orders: 13753.57,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Steak, Wine, Lebanese, Cozy, Memorable, Great, Baba Ghanouj, Casual',
        annual_dol: 14453.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7577,
        store_longitude: -73.7854,
        total_orders: 2361.39,
        neighborhood: 'Auburndale/Flushing',
        keywords:
            'Cheesecake, Pizza, Salad, Coffee, French Toast, French, Greek, Baklava, Casual, Fun',
        annual_dol: 3126.16,
        description:
            'Long-running Greek cafe known for pastries, coffee & cocktails in casual digs with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7459,
        store_longitude: -73.9566,
        total_orders: 1047.7,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Tacos, Steak, Mexican, Tequila, Salad, Margarita, Chili Con Carne, Wine, Spanish',
        annual_dol: 1193.26,
        description:
            'Old-country Mexican dishes in rustic-chic quarters featuring Spanish tiling & exposed beams.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7263,
        store_longitude: -74.0022,
        total_orders: 28.14,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Pizza, Salad, Gluten Free, Delicious, Cozy, Mediterranean, Hotel, Bright, Casual, Approachable',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7255,
        store_longitude: -73.9983,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Pizza, Italian, Creative, Perfect',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.8874,
        total_orders: 181.13,
        neighborhood: 'Jackson Heights/Elmhurst',
        keywords: 'Mexican, Karaoke, Colombian, Friendly, Premium, Casual, Great, Diverse',
        annual_dol: 206.61,
        description:
            'Old-school, Latin-flared gay bar known for cocktails, happy hours, karaoke nights & drag shows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7211,
        store_longitude: -73.9935,
        total_orders: 686.29,
        neighborhood: 'Bowery',
        keywords:
            'Italian, Steak, Pizza, Wine, Beer, French, Delicious, Premium, American, Intimate',
        annual_dol: 781.64,
        description:
            '"ENOTECA ☆ BIRRERIA ☆ WINE BAR ☆ BEER GARDEN ☆ WOOD FIRED PIZZA ☆ PRIVATE EVENTS"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9851,
        total_orders: 1355.94,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegetarian, Vegan, Chinese, Wine, Delicious, Stylish, Homemade, Perfect, Traditional, Spectacular',
        annual_dol: 2037.64,
        description:
            'Modern Chinese haunt serving dishes made with local ingredients, including some from its own garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7136,
        store_longitude: -73.997,
        total_orders: 21.26,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Chinese, Upscale, Spacious, Yummy, Sweet, Classic, Kung Pao, Great, Local, Fine',
        annual_dol: 86.9,
        description:
            'Massive, bustling Cantonese mainstay ferrying a wide variety of dim sum via fast-moving carts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9771,
        total_orders: 5775,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Vegetarian, Salad, Vodka, Wine, Beer, South American, Chilean',
        annual_dol: 5902.18,
        description:
            'Upscale Brazilian chain for all-you-can-eat meat carved tableside plus an extensive salad bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.9869,
        total_orders: 2578.76,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Gourmet, Delicious, Stylish, Organic, Healthy, Eclectic, Authentic, French',
        annual_dol: 2633.47,
        description:
            'Relaxed, stylish American eatery for sandwiches, salads & breakfast classics, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9796,
        total_orders: 421.05,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Hamburger, Salad, Gourmet, Beer, Wine, Fruity, Trendy, French, Hotel',
        annual_dol: 439.31,
        description:
            'Creative gourmet burgers, modern sides, shakes & draft beers served in a small, casually hip space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7649,
        store_longitude: -73.979,
        total_orders: 163.42,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Wine, Elegant, Fun, Great',
        annual_dol: 182.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8084,
        store_longitude: -73.9597,
        total_orders: 435.62,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Salad, Steak, Tomato Soup, Gluten Free, Beer, Creamy, American, Asian, Greek, Cozy',
        annual_dol: 1529.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.8944,
        total_orders: 38.3,
        neighborhood: 'Jackson Heights',
        keywords: 'Vegetarian, Coffee, American, Modern, Vintage, Casual, Retro, Diverse',
        annual_dol: 81.64,
        description:
            'Casual diner with a vintage vibe & a sizable American menu featuring sandwiches, pasta & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8636,
        store_longitude: -73.9265,
        total_orders: 29.38,
        neighborhood: 'Fort George',
        keywords:
            'Salad, Chicken Caesar Salad, Dominican, Caesar Salad, Latin, American, Casual, Local, Authentic',
        annual_dol: 120.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6843,
        store_longitude: -73.9774,
        total_orders: 1581,
        neighborhood: 'Fort Greene/Park Slope',
        keywords:
            'Hamburger, Beer, Coffee, Chicken Wings, American, Delicious, Casual, Local, Classic, Lively',
        annual_dol: 1788.41,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6843,
        store_longitude: -73.9774,
        total_orders: 3966.16,
        neighborhood: 'Fort Greene/Park Slope',
        keywords:
            'Burger, Hamburger, Beer, New-York-Style, Dessert, Chicken Wings, French, Asian, Traditional, Sweet',
        annual_dol: 4065.63,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7419,
        store_longitude: -73.9974,
        total_orders: 4725.36,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Meatloaf, Wine, Margarita, Vodka, Naan Bread, American, Lively, Fun, Fresh',
        annual_dol: 4770.41,
        description:
            'Fashionable restaurant with American comfort food, a lively bar & a heavily gay clientele.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 295.56,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Lobster Roll, Salad, Wine, Beer, Spanish, Clam Chowder, Shawarma, Casual, Friendly',
        annual_dol: 1208.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6805,
        store_longitude: -73.9751,
        total_orders: 19.81,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hamburger, Salad, Beer, Bbq, Margarita, Wine, Caesar Salad, Delicious, Casual',
        annual_dol: 42.28,
        description:
            '"#1 Best Burger Joint in NYC Zagat 2017 & 2018, #1 Burger TripAdvisor, Craft Beer & Full Liquor, Cocktails. Chef made burgers."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -74.0052,
        total_orders: 5.09,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tacos, Mexican, Beer, Margarita, Shrimp Cocktail, Casual, Local, Michelada, Signature, Great',
        annual_dol: 72.81,
        description:
            'Casual, bustling counter serve offering Mexican seafood, a raw bar, beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6358,
        store_longitude: -73.7298,
        total_orders: 617.46,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Gourmet, French, Israeli, Premium, Spectacular, Traditional, Hummus, Creative',
        annual_dol: 795.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7239,
        store_longitude: -73.9853,
        total_orders: 145.17,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Vegan, Burger, Salad, Coffee, Wine, Gourmet, Organic, Beer, Delicious, Quaint',
        annual_dol: 272.77,
        description:
            'Mediterranean mains, vegan cakes & wine in a romantic eatery with eclectic decor & outdoor tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7139,
        store_longitude: -73.9501,
        total_orders: 7.55,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Magical, Spanish, Spicy, Creamy, Meatball, Rustic, Authentic, Excellent, Fine',
        annual_dol: 30.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6706,
        total_orders: 336.45,
        neighborhood: '',
        keywords:
            'Steak, Tacos, Hamburger, Beer, Wine, Nachos, French Onion Soup, French, Tangy, American',
        annual_dol: 499.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7653,
        store_longitude: -73.9841,
        total_orders: 3313.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Relaxed, Fun, Friendly, Irish, Amazing, Great, Casual, Dope, Unique',
        annual_dol: 3604.22,
        description:
            'Bar where over 100 shots with names like the Monica Lewinsky are on offer amid sultry decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9882,
        total_orders: 8899.65,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Wine, Luxurious, Upscale, Hotel, Beer, Vodka, Trendy, Margarita, Vibrant',
        annual_dol: 9576.99,
        description:
            "On the Hotel Indigo's 15th floor, this posh spot with terraces serves elevated bar food & cocktails.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7224,
        store_longitude: -73.9921,
        total_orders: 1547.44,
        neighborhood: 'Bowery',
        keywords: 'Tequila, Whiskey, Wine, Beer, Irish, Swiss, American, Prosecco, Sweet, Bright',
        annual_dol: 1580.27,
        description:
            'Creative cocktails, craft beer & American small plates served in an old-school space with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9853,
        total_orders: 768.17,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Steak, Salad, Wine, Italian, Beer, French Toast, French, Stylish, Delicious',
        annual_dol: 901.29,
        description:
            'American plates, unique cocktails & rotating craft beers served in casual digs with a funky mural.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.9914,
        total_orders: 504.88,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Wine, Chinese, Hotel, Spectacular, Fun, Comfortable, Sweet, Ramen, Friendly, Casual',
        annual_dol: 515.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7216,
        store_longitude: -73.9883,
        total_orders: 423.67,
        neighborhood: 'Lower East Side',
        keywords:
            'Sushi, Pizza, Hamburger, Gluten Free, Italian, Vietnamese, Spicy, Fried Rice, Thai, Fusion',
        annual_dol: 1731.82,
        description:
            'Easygoing outfit with outdoor seating serving bahn mi, noodle soup & other Vietnamese comfort food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9687,
        total_orders: 5.36,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Kebab, Beer, Salad, Pizza, Turkish, Dessert, Mediterranean, Friendly, Casual, Local',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.8893,
        total_orders: 28.14,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Casual, Latin, Friendly, Local',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6461,
        store_longitude: -73.583,
        total_orders: 10.94,
        neighborhood: '',
        keywords: 'Whiskey Sour, Whiskey, Pizza, Italian, Golfclub, American, Upscale, Casual',
        annual_dol: 44.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5365,
        store_longitude: -74.1517,
        total_orders: 92.08,
        neighborhood: 'Eltingville',
        keywords: 'Rich, Whiskey, Fabulous, Irish, Great, Casual',
        annual_dol: 107.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.747,
        store_longitude: -74.0084,
        total_orders: 97.22,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Hotel, Golfclub, Beer, Magical, Cozy, Welcoming, Spectacular, Friendly',
        annual_dol: 397.39,
        description:
            'Figure-skating lessons & ice-hockey games at a year-around ice rink with gear rentals & a snack bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7139,
        store_longitude: -73.9557,
        total_orders: 13663.37,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Beer, Vodka, Gourmet, Gin, Hurricane, Spicy, Nachos, Pinacolada, French',
        annual_dol: 13663.37,
        description:
            'Fried chicken, biscuits & other Southern dishes in a low-key saloon where you order at the bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6783,
        store_longitude: -73.9688,
        total_orders: 996.11,
        neighborhood: 'Prospect Heights',
        keywords:
            'Pizza, Salad, Italian, Wine, Beer, Gluten Free, American, Stylish, Sustainable, Contemporary',
        annual_dol: 1062.1,
        description:
            'Stylish eatery with an Italian-American menu based on local ingredients, plus a leafy back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7117,
        store_longitude: -73.9416,
        total_orders: 966.31,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Tequila, Stylish, Fun, Bright, Great, Prosecco, Classic, Fresh, Modern',
        annual_dol: 1282.95,
        description: '"Cocktail Bar and Lounge"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7108,
        store_longitude: -74.008,
        total_orders: 1489.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Hotel, Coffee, Playful, Awesome, Retro, Classic, Cool, Great, Hip',
        annual_dol: 1554.49,
        description:
            'Sleek, wood-paneled rooms come with free Wi-Fi Bang and Olufsen speakers and flat-screen TVs. Upgraded rooms add city views and writing desks.Amenities include a co-working space/buzzy cocktail lounge with retro arcade games, and a fitness center. Breakfast is available for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.984,
        total_orders: 4104.47,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Spicy, Smoky, Relaxed, Irish, Friendly, Lively, French, Awesome',
        annual_dol: 4282.43,
        description:
            "East Villagers down discounted cocktails on a lively patio during this pub's daytime happy hour.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6452,
        store_longitude: -74.1045,
        total_orders: 762,
        neighborhood: 'Mid Island/Randall Manor',
        keywords:
            'Delicious, Chinese, Elegant, Picturesque, Perfect, Artisanal, Great, Seasonal, Cultural, Biryani',
        annual_dol: 1863.78,
        description:
            '"One of New York City’s most unique wedding venues is Celebrate at Snug Harbor, a Cultural Center and Botanical Garden in Staten Island. With magnificent architecture, lush botanical gardens and artisanal cuisine. The lush 83 acre campus is home to several picturesque gardens including New York City’s only Chinese Scholar Garden as well as a Tuscan Garden, Rose Garden, Hidden Garden and White Garden. The gardens are the perfect setting for a ceremony al fresco."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -74.0074,
        total_orders: 522.94,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Cheesecake, Salad, Wine, Coffee, Italian, Upscale, Delicious, Elegant, Organic, Cozy',
        annual_dol: 544.47,
        description: 'Upscale, stone-walled trattoria specializing in traditional Italian recipes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5862,
        store_longitude: -73.6922,
        total_orders: 403.71,
        neighborhood: '',
        keywords:
            'Burger, Cheesesteak, Bbq, Beer, Bourbon, American, Friendly, Fun, Perfect, Great',
        annual_dol: 421.21,
        description:
            'Casual canteen & buzzy billiard bar serving up large portions of slow-cooked BBQ & beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7401,
        store_longitude: -73.7912,
        total_orders: 15.25,
        neighborhood: 'Fresh Meadows/Auburndale/Flushing',
        keywords: 'Fresh, Delicious, Sushi, Pizza, Friendly, Great, Hotel, Thai, Casual, Local',
        annual_dol: 62.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7461,
        store_longitude: -73.9834,
        total_orders: 1234.46,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Upscale, Trendy, Sushi, Gourmet, Urban, Premium, Rooftop, Intimate, Unique',
        annual_dol: 1260.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7178,
        store_longitude: -73.9589,
        total_orders: 295.15,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Steak, Salad, Margarita, Italian, Painkiller, Delicious, Pinacolada, Rum, Daiquiri',
        annual_dol: 368.13,
        description:
            'Seafood eatery where sandy floors, tropical drinks & a lively back patio lend a festive mood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7343,
        store_longitude: -74.0035,
        total_orders: 1575.57,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Beer, Upscale, American, Premium, Fun, Homemade, Amazing, French, Cool',
        annual_dol: 1675.45,
        description:
            'Cocktails & elevated bar fare in a smart setting with plush booths & big screens to watch the game.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8378,
        store_longitude: -73.8345,
        total_orders: 1306.87,
        neighborhood: 'Schuylerville/Pelham Bay/#5719',
        keywords: 'Wine, Salad, Beer, Mexican, Vegetarian, Cuban, Rum, Delicious, Elegant, Warm',
        annual_dol: 1334.6,
        description:
            'Intimate restaurant & mojito lounge serving classic & creative Cuban fare in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7326,
        store_longitude: -74.0096,
        total_orders: 606.08,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Italian, Meatball Sub, Meatball, Italian Sub, Beer, Cozy, Fun, Lively, Friendly, Urban',
        annual_dol: 675.33,
        description:
            "There's an anything-goes vibe at this well-worn haunt, where weekends attract a lively crowd.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7991,
        store_longitude: -73.943,
        total_orders: 35.88,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Kebab, Wine, Salad, Moroccan, Mediterranean, Chinese, Perfect, Friendly, Awesome, Cool',
        annual_dol: 146.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7069,
        store_longitude: -73.6658,
        total_orders: 478.15,
        neighborhood: '',
        keywords:
            'Lobster, Salad, Cajun, Beer, Delicious, Juicy, Fried Shrimp, Welcoming, Authentic, Casual',
        annual_dol: 923.8,
        description:
            '"Crafty Crab offers the freshest seafood and most authentic recipes in the area. We’re cooking up crab, crawfish, calamari, lobster, oysters, and mussels in the kitchen, made fresh daily! Offering the Best Quality Seafood Crafty Crab offers the freshest seafood and most authentic recipes in the area. When you walk into our restaurant, you’ll immediately experience Southern charm and a friendly, welcoming atmosphere to make you feel at home. Whether you’re stopping in for a business lunch, family dinner, date night, or a night out with friends, you’ve come to the perfect spot. Delicious home-style seafood with generous portions is what you can expect with every meal."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7654,
        store_longitude: -73.9178,
        total_orders: 808.84,
        neighborhood: 'Astoria',
        keywords:
            'Burger, Pizza, Salad, Hamburger, Coffee, Dessert, Delicious, Avocado Toast, Latin, Greek',
        annual_dol: 1814.46,
        description:
            'Bustling hot spot with a loungey vibe offers Greek bites plus cocktails & popular sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.771,
        store_longitude: -73.9565,
        total_orders: 4031.54,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Karaoke, Beer, Tasty, Irish, French, Greek, Local, Great, Cool, Casual',
        annual_dol: 4129.97,
        description:
            'Laid-back Irish pub with 100-foot-long bar featuring karaoke, DJs & floor space for dancing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7152,
        store_longitude: -74.0156,
        total_orders: 704.29,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Italian, Wine, Greek, Mediterranean, Hotel, Innovative, Welcoming, High-End, Great, Fresh',
        annual_dol: 2878.9,
        description:
            '"The Ancient Greek queen of the Mediterranean was crowned with the title of Anassa, given only to unique women with goddess-like qualities. The title, similar to a “queen,” was reserved for women who exuded rare qualities and benevolent leadership. Like the Ancient Greek Anassa, we believe our restaurant stands out, representing all of the finest recipes, culinary traditions and ingredients from Greece and the Mediterranean – always standing out for our quality and attention to detail. We look forward to welcoming you to Anassa Taverna and giving you a bit of our own… royal treatment that only an Anassa can give."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8577,
        store_longitude: -73.8863,
        total_orders: 1104.56,
        neighborhood: 'Belmont/West Bronx',
        keywords: 'Burger, Hamburger, Steak, Tacos, Pizza, Salad, Meatball, Beer, Wine, American',
        annual_dol: 1476.33,
        description:
            'Nachos, wings, pasta, ribs & cocktails served in an unpretentious bar with a neighborhood vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -73.9434,
        total_orders: 460.77,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Salad, Chinese, Beer, Karaoke, Korean, American, Cozy, Delicious, Contemporary, Southern',
        annual_dol: 460.77,
        description:
            'Casual spot for contemporary Taiwanese-American cooking, including buns, noodles & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8601,
        store_longitude: -73.8909,
        total_orders: 1515.49,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Steak, Burger, Mexican, Beer, Bourbon, Dessert, American, Delicious, Latin, Casual',
        annual_dol: 1796.15,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -73.9376,
        total_orders: 195.88,
        neighborhood: 'East Williamsburg',
        keywords: 'Vegan, Coffee, Salad, Casual, Authentic, Dan Dan',
        annual_dol: 800.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8529,
        store_longitude: -73.7895,
        total_orders: 241.77,
        neighborhood: 'City Island',
        keywords:
            'Vegetarian, Steak, Tasty, Nutritious, Beer, Organic, Delicious, Trendy, Innovative, American',
        annual_dol: 623,
        description:
            'This compact, modern-rustic tavern with a patio offers innovative comfort food, craft beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6775,
        store_longitude: -73.9981,
        total_orders: 33.15,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Wine, Pizza, Italian, German, Salad, Coffee, Flavorful, French Toast, French, Premium',
        annual_dol: 135.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.657,
        store_longitude: -73.7955,
        total_orders: 4695.56,
        neighborhood: 'Jamaica',
        keywords: 'Coffee, American, British, Global, Premium, Exceptional, Unique',
        annual_dol: 15329.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9881,
        total_orders: 5694.65,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Coffee, Japanese, Beer, Elegant, Western, Sweet, Casual, Lively, Chill',
        annual_dol: 6814.02,
        description:
            'Snazzy, compact Japanese cafe showcasing high-end coffee & sandwiches, plus sake & beer at night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5515,
        store_longitude: -74.1495,
        total_orders: 695.15,
        neighborhood: 'Great Kills',
        keywords:
            'Chocolate Cake, Italian, Delicious, Polished, Awesome, Perfect, Great, Fine, Casual',
        annual_dol: 760.74,
        description: '"Italian, Fine Dining, Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8515,
        store_longitude: -73.9349,
        total_orders: 958.9,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Burger, Mexican, Beer, American, Delicious, Friendly, Casual, Great, Fusion, Signature',
        annual_dol: 2578.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.7714,
        total_orders: 873.24,
        neighborhood: 'Bayside',
        keywords:
            'Tequila, Tequila Sunrise, Spanish, Wine, Mexican, Delicious, Sangria, Great, Excellent, Relaxed',
        annual_dol: 1188.76,
        description:
            'Relaxed, informal eatery featuring Spanish small plates & paella, plus wine & sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5771,
        store_longitude: -73.9524,
        total_orders: 847.54,
        neighborhood: 'Manhattan Beach',
        keywords:
            'Lobster, Wine, Beer, Whiskey, Bourbon, Delicious, Cozy, Elegant, Comfortable, American',
        annual_dol: 1937.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8864,
        store_longitude: -73.9099,
        total_orders: 255.29,
        neighborhood: 'Riverdale/West Bronx',
        keywords: 'Sushi, Salad, Dessert, Wine, Japanese, Delicious, Chilean, Rich, Asian, Ramen',
        annual_dol: 347.53,
        description:
            '"Palace of Japan offers delicious dining, takeout and delivery to Bronx, NY. Palace of Japan is a cornerstone in the Bronx community and has been recognized for its outstanding Sushi cuisine, excellent service and friendly staff. Our Sushi restaurant is known for its modern interpretation of classic dishes and its insistence on only using high quality fresh ingredients."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7541,
        store_longitude: -73.9662,
        total_orders: 2014.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Wine, Coffee, Italian, Upscale, Mexican, French Onion Soup, French, Savory, Caesar Salad',
        annual_dol: 2115.19,
        description:
            'Relaxed restaurant serving American cuisine with French touches in modern surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9653,
        total_orders: 143.88,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Salad, Pizza, Coffee, Meatball, Savory, Mediterranean, Greek, American, Bloody Mary',
        annual_dol: 167.71,
        description:
            'Airy-chic cafe specializing in American & Greek breakfast, lunch & dinner fare, plus coffee & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6293,
        store_longitude: -74.0229,
        total_orders: 592.9,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Coffee, Tasty, Ale, American, Traditional, Casual, Great, Terrific, Local',
        annual_dol: 659.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7044,
        store_longitude: -73.7979,
        total_orders: 1303.5,
        neighborhood: 'Jamaica',
        keywords: 'Cajun, Flavorful, Juicy, Creole, Authentic, Fun, Perfect, Casual, Signature',
        annual_dol: 1770.6,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -74.0032,
        total_orders: 654.92,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Pizza, Italian, Salad, Dessert, Trendy, Caesar Salad, Modern, Classy, Casual, Handcrafted',
        annual_dol: 800.8,
        description:
            'Naples-style handcrafted pizzas, pasta & panini, served with Italian wines, under the High Line.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7469,
        store_longitude: -73.9865,
        total_orders: 1078.5,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Wine, Fruity, Dessert, Elegant, Martini, Beer, Creamy, Comfortable, Mediterranean, Friendly',
        annual_dol: 1125.26,
        description:
            'French-Med bar & restaurant offering nearly 100 wines & champagnes, chocolates, cheeses & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.9804,
        total_orders: 1427.88,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Steak, Pizza, Salad, Wine, Beer, Coffee, Irish, Nachos, Delicious, Warm',
        annual_dol: 1517.67,
        description:
            'Sprawling, multi-level Irish bar & grill serving steak, sandwiches & traditional dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7542,
        store_longitude: -73.9847,
        total_orders: 178.44,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster Roll, Lobster, Cheesecake, Salad, Dessert, Champagne, New-York-Style, Mexican, Delicious, Beer',
        annual_dol: 338.04,
        description:
            'High-end harbor cruise where sit-down dinner, drinks & dancing come with views of the city.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6745,
        store_longitude: -73.9502,
        total_orders: 317.75,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Vegan, Dessert, Tasty, Trendy, Delicious, Upscale, Caribbean, Cozy, Romantic',
        annual_dol: 375.45,
        description:
            '"Located in the Heart of Crown Heights, Savvy Biatro & Bar was inspired by the diverse cultural diaspora of our American and Caribbean ancestral roots. Savvy offers a a fusion of crave worthy dishes in a vibrant & contemporary environment."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.9088,
        total_orders: 289.34,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Steak, Burger, Vegan, Vegetarian, Hamburger, Tequila, Beer, Gluten Free, Seafood Paella, Wine',
        annual_dol: 367.72,
        description:
            'The menu of Australian dishes at this relaxed eatery features many gluten-free & veggie options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9956,
        total_orders: 2279.69,
        neighborhood: 'Nolita',
        keywords: 'Thai, American, Classic, Casual, Sensational, Local, Sweet, Fine, Great',
        annual_dol: 4469.51,
        description:
            'Classic Thai dishes & drinks by a lauded restaurant group in a kitschy-cool, diner-style setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 280.31,
        neighborhood: 'Jackson Heights',
        keywords: 'Spicy, Baked Potato, Creamy, Fun, Great, Latin, American, Casual',
        annual_dol: 312.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -74.0025,
        total_orders: 3345.26,
        neighborhood: 'South Village/SoHo',
        keywords: 'Sushi, Salad, Tasty, Japanese, Cozy, Friendly, Premium, Fun, Fresh, Classic',
        annual_dol: 12371.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7471,
        store_longitude: -73.8902,
        total_orders: 184.13,
        neighborhood: 'Jackson Heights',
        keywords: 'Mexican, Ecuadorian, Trendy, American, Casual, Global, Traditional',
        annual_dol: 188.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.8811,
        total_orders: 63.89,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Hotdog, Burger, Beer, Hamburger, Pizza, Cheesesteak, Ale, Philly Cheesesteak, Bbq, Upscale',
        annual_dol: 209.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7216,
        store_longitude: -73.9958,
        total_orders: 196.69,
        neighborhood: 'Nolita',
        keywords:
            'Pizza, Gluten Free, Beer, Meatball, Wine, Salad, Delicious, Smoky, Premium, Fresh',
        annual_dol: 804.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5814,
        store_longitude: -73.8378,
        total_orders: 46.07,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords:
            'Lobster, Lobster Roll, Pizza, Salad, Spicy, English Muffin, American, Omelette, Farm-To-Table, Casual',
        annual_dol: 188.33,
        description: '"Fine Dining - Authentic Farm to Table Cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7047,
        store_longitude: -74.0168,
        total_orders: 789.79,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Italian, Dessert, Wine, Bruschetta, Asian, Classy, Indian',
        annual_dol: 943.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -74.0075,
        total_orders: 1056.65,
        neighborhood: 'Hudson Square',
        keywords:
            'Burger, Steak, Salad, Carrot Cake, Vegan, Breakfast Burrito, French Toast, French, Avocado Toast, American',
        annual_dol: 1296.48,
        description:
            'Unassuming American eatery serving market-oriented menu with an emphasis on unique vegetable dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7935,
        store_longitude: -73.9406,
        total_orders: 10386.09,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Burger, Rich, Dessert, American, Spanish, Warm, Traditional, Modern, Homemade',
        annual_dol: 10841.15,
        description:
            'Steaks, chops, seafood & drinks in a bustling, urban space with an open kitchen & DJ entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -73.6403,
        total_orders: 23.91,
        neighborhood: '',
        keywords:
            'Burger, Beer, Salad, American, Caesar Wrap, Irish, Caesar Salad, Local, Perfect, Authentic',
        annual_dol: 97.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.6559,
        total_orders: 7.23,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Coffee, American, Veal Parmigiana, Casual, Local, Classy',
        annual_dol: 30.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7833,
        store_longitude: -73.9784,
        total_orders: 2056.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Steak, Wine, Salad, Dessert, French, Beer, Organic, Mediterranean, Elegant, Crisp',
        annual_dol: 2172.5,
        description:
            'Bustling, French-inspired brasserie with a Riviera vibe, outdoor seating & a curated wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.6465,
        total_orders: 5.98,
        neighborhood: '',
        keywords:
            'Flavorful, Steak, Coffee, Fun, American, Cool, Casual, Southern, Local, Excellent',
        annual_dol: 24.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7666,
        store_longitude: -73.9203,
        total_orders: 10.82,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tequila, Mexican, Burger, Vodka, Champagne, Flavorful, Breakfast Burrito, Tex-Mex, Thai, American',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.692,
        store_longitude: -73.8344,
        total_orders: 28.14,
        neighborhood: 'Jamaica/South Richmond Hill',
        keywords:
            'Salad, Shrimp Cocktail, Hotel, Delicious, Casual, Greek, Local, American, Western',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6606,
        store_longitude: -73.9904,
        total_orders: 81.72,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Steak, Cheesesteak, Salad, Delicious, Spicy, French, French Onion Soup, American, Sweet, Rare',
        annual_dol: 334.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7427,
        store_longitude: -73.952,
        total_orders: 239.42,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Italian, Dessert, Cozy, Homey, Southern, Homemade, Casual, Farm-To-Table, Authentic',
        annual_dol: 254.8,
        description:
            'A fireplace adds a homey touch to this longtime Italian joint with a basic menu & brick-oven pizzas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -73.9896,
        total_orders: 3692.37,
        neighborhood: 'East Village/Bowery',
        keywords: 'Vodka, Russian, Moscow Mule, Fun, Casual',
        annual_dol: 3770.71,
        description:
            'Readings & publication parties are regular events at this tiny Soviet-themed watering hole.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -73.9896,
        total_orders: 2183.39,
        neighborhood: 'East Village/Bowery',
        keywords: 'Fun, Lively, Amazing, Memorable, Great, Excellent, Classic, Cool, Diverse',
        annual_dol: 2367.28,
        description:
            'Lively bar & candlelit showroom serving classic drinks & cocktails with shows 7 nights a week.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -73.9895,
        total_orders: 421.7,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Burger, Mexican, Tequila, Mimosa, Margarita, Grasshopper, Spicy, Funky, Contemporary, Modern',
        annual_dol: 458.91,
        description:
            'Modern Mexican joint putting a creative spin on traditional eats & drinks in a dark, funky interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7929,
        store_longitude: -73.9721,
        total_orders: 205.64,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Hotdog, Tacos, Mexican, Tequila, Steak, Margarita, Gluten Free, Quesadillas, American, Delicious',
        annual_dol: 258.31,
        description:
            'Mexican street food served in a laid-back eatery with decor inspired by the 1986 FIFA World Cup.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6168,
        store_longitude: -74.0683,
        total_orders: 874.67,
        neighborhood: 'Rosebank',
        keywords:
            'Cajun, Burger, Cajun-Style, Cheesecake, Jambalaya, Beer, Wine, Italian, Mexican, Delicious',
        annual_dol: 990.06,
        description:
            'New Orleans Cajun cooking & strong drinks in a festive space that channels the French quarter.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5923,
        store_longitude: -74.0871,
        total_orders: 5.54,
        neighborhood: 'Dongan Hills',
        keywords: 'Burger, Steak, Italian, Savory, Rustic, Spicy, American, Homemade, Fancy, Local',
        annual_dol: 22.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7414,
        store_longitude: -73.9874,
        total_orders: 7546.28,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Hotel, Stylish, Elegant, Iconic, Modern, Premium, Sophisticated, Perfect, Great, Cool',
        annual_dol: 8032.5,
        description:
            "The sophisticated rooms feature free Wi-Fi, flat-screen TVs, minibars and rainfall showers. Studios have entrance foyers, and expansive suites include vaulted ceilings and living areas. Penthouse suites add kitchens, plus separate living and dining areas. Most quarters offer park, city and/or Empire State Building views. Room service is available 24/7.Amenities include a posh restaurant and 2 swanky bars. There's also a 24-hour gym, a luxe spa and event space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9838,
        total_orders: 2489.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Irish, Beer, Burger, American, Traditional, Authentic, Awesome, Amazing, Pulled Pork, Great',
        annual_dol: 2760.34,
        description:
            '"Irish bar and restaurant in the heart of Times Square .2 bars 2 floors with additional outdoor covered seating .live music Thursday Friday Saturday and Sunday nights"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -73.9912,
        total_orders: 257.7,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Cheesecake, Chicken Parmigiana, Italian, Beer, Tasty, Wine, Ale, Fried Chicken, Fresh',
        annual_dol: 276.27,
        description:
            'Airy, casual eatery offering super-thin-crust pizza & basic Italian dishes plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7578,
        store_longitude: -73.9836,
        total_orders: 740.36,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Stylish, Wine, Spacious, Modern, Premium, Contemporary, Global, Classic, Clean',
        annual_dol: 3018.08,
        description:
            'The chic rooms and suites have Wi-Fi, minibars and yoga mats. All feature pop art and marble bathrooms; some have balconies.Amenities include a 24-hour fitness center, valet parking (fee), free loaner bikes, and a lobby lounge decorated with New York photography.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9843,
        total_orders: 591.72,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Vegetarian, Pizza, Salad, Wine, Beer, Dessert, Upscale, Trendy, Spicy',
        annual_dol: 621.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6907,
        store_longitude: -73.9966,
        total_orders: 2179.49,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Burger, Mexican, Beer, Wine, Upscale, Warm, Elegant, American, French, Cozy',
        annual_dol: 2225.73,
        description:
            'Atmospheric watering hole serving up cocktails, beer, wine & more in simple, retro surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6905,
        store_longitude: -73.9833,
        total_orders: 212.69,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Burger, Beer, Modern, Casual, Fresh, Classic, Fine',
        annual_dol: 801.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9855,
        total_orders: 677.57,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Stylish, Hotel, Inviting, Innovative, Bright, Lively, Premium, Great, Perfect, Modern',
        annual_dol: 691.95,
        description:
            "Chic, compact rooms come with smart TVs, Nespresso machines and minibars, as well as premium linens and free Wi-Fi. Upgraded rooms add city views; some have pull-out sofas. Room service is available.Daily sangria and access to a nearby fitness center are complimentary. There's also a hip tapas/cocktail bar. Off-site parking is available for a surcharge.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9897,
        total_orders: 13128.61,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Tequila, Whiskey, Wine, Scotch, Beer, Margarita, Gin, Ale, Irish, Chinese',
        annual_dol: 14084.36,
        description:
            'Elaborately designed, Victorian-style bistro with a long marble bar for cocktails & American bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9918,
        total_orders: 1423.92,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Lobster, Beer, Upscale, Irish, Elegant, American, Friendly, Perfect, Classic, Casual',
        annual_dol: 1467.54,
        description:
            'This large, elegant Irish bar one block from Penn Station features a big menu of elevated pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7725,
        store_longitude: -73.9812,
        total_orders: 2147.91,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Salad, Lobster, Sushi, Steak, Shrimp Cocktail, Vegan, Italian, Japanese, Mediterranean, Caesar Salad',
        annual_dol: 2713.53,
        description:
            'A lively crowd gathers at this fish house for the extensive seafood menu & stylish setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7291,
        store_longitude: -74.0008,
        total_orders: 215.26,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Chinese, Wine, Spicy, Asian, Exotic, Traditional, Fun, Authentic, Casual',
        annual_dol: 879.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9943,
        total_orders: 429.79,
        neighborhood: 'Nolita',
        keywords: 'Beer, Wine, Thai, Margarita, Spicy, Ale, Eclectic, American, Modern, Fun',
        annual_dol: 1508.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7622,
        store_longitude: -73.8042,
        total_orders: 91.53,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Vodka, Wine, Steak, Kebab, Beer, Thai, Gin, Asian, Authentic, Laid-Back',
        annual_dol: 374.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7063,
        store_longitude: -73.9227,
        total_orders: 565.91,
        neighborhood: 'Bushwick/Flushing',
        keywords: 'Coffee, Beer, Spicy, Vietnamese, Upscale, Thai, Asian, Trendy, Japanese, Ramen',
        annual_dol: 749.2,
        description:
            'Japanese, ramen & Thai menus from three different chefs are served in a hip, bi-level space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7244,
        store_longitude: -73.5446,
        total_orders: 23.86,
        neighborhood: '',
        keywords: 'Burger, French Toast, Beer, French, Omelette, Casual, Classic, Excellent',
        annual_dol: 97.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6509,
        store_longitude: -73.611,
        total_orders: 9.7,
        neighborhood: '',
        keywords:
            'Beer, Burger, Irish, Wine, Lively, Fabulous, American, Refreshing, Great, Casual',
        annual_dol: 39.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7106,
        store_longitude: -73.9228,
        total_orders: 0,
        neighborhood: 'East Williamsburg',
        keywords:
            'Tequila, Wine, Margarita, Martini, Vietnamese, Spicy, Paloma, Intimate, Casual, Local',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.7968,
        total_orders: 271.94,
        neighborhood: 'Broadway–Flushing/Flushing/Murray Hill',
        keywords: 'Wine, Clean, Casual, Irish, American',
        annual_dol: 309.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -73.9993,
        total_orders: 2257.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Tasty, Delicious, Savory, Beer, Cozy, Quaint, Rustic, Italian, Fun',
        annual_dol: 2305.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -74.0161,
        total_orders: 32.97,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Champagne, Wine, Beer, Magical, Gumbo, Korean, Spectacular, Vibrant',
        annual_dol: 94.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.8786,
        total_orders: 49.78,
        neighborhood: 'Jackson Heights/East Elmhurst',
        keywords:
            'Margarita, Beer, Spanish, Seafood Paella, Latin, American, Daiquiri, Fusion, Casual',
        annual_dol: 58.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9825,
        total_orders: 2600.54,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Upscale, Stylish, Hotel, Delicious, Vintage, Peruvian, Healthy, Irish, American, Premium',
        annual_dol: 2711.61,
        description:
            'Contemporary rooms offer free Wi-Fi, desks with ergonomic chairs, flat-screen TVs with premium cable channels and iPod docks, plus minifridges and Keurig coffeemakers.Social spaces include a living room-style lobby and a sundeck. The American bistro serves breakfast and dinner as well as all-day drinks, including Starbucks coffee. There’s also a business center with workstations and printers, and 7 meeting rooms. Other amenities include an exercise room and a convenience store.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9851,
        total_orders: 1884.65,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Pizza, Italian, Gluten Free, Wine, Dessert, Hotel, French, Delicious, Cozy',
        annual_dol: 1924.63,
        description:
            'Thin-crust pizza & homemade pasta head the menu at this colorful eatery with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7125,
        store_longitude: -73.9942,
        total_orders: 2272.49,
        neighborhood: 'Two Bridges',
        keywords: 'Wine, Karaoke, Beer, Margarita, Spicy, Hotel, Fun, Fancy, Vintage, Friendly',
        annual_dol: 2371.02,
        description:
            'Unassuming watering hole featuring Chinese-inspired cocktails & bar bites, plus a jukebox.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.9548,
        total_orders: 1834.29,
        neighborhood: 'Roosevelt Island',
        keywords:
            'Hotel, Elegant, Beer, Spectacular, Fancy, Bright, Polished, Modern, Colorful, Great',
        annual_dol: 5619.64,
        description:
            "and 4 miles from the Museum of Modern Art.Featuring city views, the polished rooms have designer toiletries, Wi-Fi, HDTVs and minifridges, plus modern artwork and elegant black-and-white images. Some overlook the river. Suites offer living areas, and a colorful upgraded suite provides a soaking tub and a sofabed.An airy restaurant features floor-to-ceiling windows, and walls lined with bookshelves. There's also a 24-hour gym. Breakfast is available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.9918,
        total_orders: 636.5,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Mexican, Vegan, Tacos, Steak, Nachos, Margarita, Enchiladas, Dessert, Fajitas, Authentic',
        annual_dol: 693.68,
        description:
            'Laid-back restaurant dishing up Mexican cuisine & cocktails amid a vibrant interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8135,
        store_longitude: -73.9447,
        total_orders: 260.43,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'American, Premium, Casual, Great',
        annual_dol: 327.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.688,
        store_longitude: -73.9791,
        total_orders: 2005.4,
        neighborhood: 'Fort Greene',
        keywords: 'Beer, Wine, Upscale, American, Eclectic, Classic, Ample, Chic, Casual, Fine',
        annual_dol: 2055.61,
        description:
            'Gotham West spin-off featuring an array of Brooklyn-based food & drink vendors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7386,
        store_longitude: -73.9943,
        total_orders: 71.11,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Vegan, Vegetarian, Salad, Beer, Dessert, Healthy, Mediterranean, Casual, Delicious, Syrian',
        annual_dol: 273.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7388,
        store_longitude: -73.9956,
        total_orders: 507,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Salad, Shrimp Cocktail, Pizza, Wine, French Toast, Beer, French, Rich, American, Greek',
        annual_dol: 594.07,
        description:
            'Corner diner offering 24/7 fare in basic digs with a Hollywood Hills mural & swivel counter chairs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.743,
        store_longitude: -74,
        total_orders: 34.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Kebab, Vegetarian, Wine, Beer, Mediterranean, Ale, Lebanese, Shawarma, Tasty, Stylish',
        annual_dol: 47.5,
        description:
            'Low-key brick-walled cafe with an open kitchen offering modern Middle Eastern classics & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.958,
        total_orders: 252.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Mexican, Coffee, Organic, Trendy, Breakfast Burrito, Spicy, Delicious, American, Southern',
        annual_dol: 310.15,
        description:
            'Cafe focusing on everything egg, including creative sandwiches & fancy Benedicts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6764,
        store_longitude: -73.9623,
        total_orders: 5468.19,
        neighborhood: 'Crown Heights',
        keywords: 'Steak, Mexican, Tacos, Burritos, Coffee, Homemade, Casual, Yummy, Fresh, Great',
        annual_dol: 5707.59,
        description:
            'Down-to-earth fixture serving up a variety of tacos, enchiladas, burritos & other Mexican staples.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7064,
        store_longitude: -74.0026,
        total_orders: 7169.5,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Lobster, Burger, Sushi, Dessert, Italian, Wine, Beer, French, Swiss, Modern',
        annual_dol: 8629.91,
        description:
            'Waterfront restaurant preparing a modern menu of wild-caught seafood paired with seasonal cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -74.0075,
        total_orders: 237.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lasagna, Salad, Wine, Gluten Free, Italian, Tasty, Vodka, Gin, Delicious, Artisanal',
        annual_dol: 248.21,
        description:
            'This white-tiled Italian bar offers small plates & higher-end entrees to go with wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7219,
        store_longitude: -73.9802,
        total_orders: 203.64,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Vodka, Tasty, Trendy, Funky, Fabulous, Awesome, Classy, Ample, Friendly, Chic',
        annual_dol: 832.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.9794,
        total_orders: 37.9,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Salad, Mexican, Mimosa, Beer, Coffee, Dessert, Wine, Greek, Organic, Screwdriver',
        annual_dol: 154.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9774,
        total_orders: 467.41,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Ramen, Beer, Wine, Spicy, Spanish, Japanese, Dan Dan, Unforgettable, Local, Casual',
        annual_dol: 508.66,
        description:
            'Ramen spot by Masaharu Morimoto with communal tables for Japanese noodles, appetizers & sakes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7421,
        store_longitude: -73.9834,
        total_orders: 255.13,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Pizza, Italian, Vegan, Beer, Delicious, Latin, Traditional, Local, Fine',
        annual_dol: 402.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9215,
        total_orders: 2528.33,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords:
            'Burger, Hamburger, Milkshake, Beer, Vodka, Spicy, Delicious, Organic, Ale, French',
        annual_dol: 2879.59,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7528,
        store_longitude: -73.9829,
        total_orders: 14853.16,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Salad, Vegan, Chicken Caesar Salad, Upscale, French Toast, Elegant, Delicious, French, Caesar Salad',
        annual_dol: 15530.9,
        description:
            'American eatery situated on a prime patch of park real estate behind the New York Public Library.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8293,
        store_longitude: -73.7018,
        total_orders: 97.98,
        neighborhood: '',
        keywords:
            'Delicious, Upscale, Stylish, Italian, Fun, Bright, Perfect, American, Exceptional, Excellent',
        annual_dol: 420.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9935,
        total_orders: 206.61,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Whiskey, Comfortable, Iconic, Friendly, Great, Local, Dutch, Casual',
        annual_dol: 274.26,
        description:
            'Humble hangout turning out cocktails & draft beer in narrow, brick-lined digs with a jukebox.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8074,
        store_longitude: -73.9273,
        total_orders: 214.35,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Mexican, Salad, Pizza, Mimosa, Coffee, Dominican, Puerto Rican, Caribbean, Rich, Delicious',
        annual_dol: 876.2,
        description:
            'Outpost of a Puerto Rico–based mini-chain serving chocolate-infused cocktails & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7174,
        store_longitude: -73.9986,
        total_orders: 33.94,
        neighborhood: 'Little Italy',
        keywords: 'Burger, Hamburger, Pizza, Salad, Italian, Chinese, Asian, Cozy, Yummy, Casual',
        annual_dol: 138.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7584,
        store_longitude: -73.5877,
        total_orders: 42.12,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Vegetarian, Dessert, Wine, Shrimp Cocktail, Beer, American, Casual, Fresh',
        annual_dol: 172.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7206,
        store_longitude: -73.8464,
        total_orders: 5149.52,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Steak, Mexican, Beer, Margarita, Peruvian, Cuban, Bbq, Wine, Avocado Toast',
        annual_dol: 5767.36,
        description:
            'Latin American dishes & cocktails served in a restaurant & bar that has an easygoing ambiance.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6634,
        store_longitude: -73.9842,
        total_orders: 43.92,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Coffee, French, Avocado Toast, Delicious, Romantic, Fresh, Casual, Local',
        annual_dol: 47.86,
        description:
            'Small, casual spot for light French breakfast & lunch fare, plus coffee & snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8767,
        store_longitude: -73.8795,
        total_orders: 116.22,
        neighborhood: 'Norwood/West Bronx',
        keywords: 'Mexican, Dominican, American, Latin, Casual, Friendly, Fun, Traditional, Great',
        annual_dol: 139.05,
        description:
            'Informal restaurant offering a menu of traditional Dominican dishes as well as a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7919,
        store_longitude: -73.9721,
        total_orders: 43.39,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Pizza, Gourmet, Bbq, Wine, French, Ale, American, Delicious',
        annual_dol: 66.47,
        description:
            'A wide-ranging menu, live music & sidewalk cafe seats lure diners to this glatt kosher steakhouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7511,
        store_longitude: -73.9748,
        total_orders: 3883.71,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Shrimp Cocktail, Salad, Wine, Upscale, American, Refined, Classic, Fresh',
        annual_dol: 4045.79,
        description:
            'Outpost of the upscale steakhouse chain offers classic American fare & a clubby, refined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5837,
        store_longitude: -73.9502,
        total_orders: 37.72,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Salad, Pizza, Beer, Savory, Turkish, French, Russian, Spicy, Delicious, Hotel',
        annual_dol: 186.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8169,
        store_longitude: -73.9429,
        total_orders: 61.54,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Mexican, Tacos, Vintage, Burger, Wine, Legendary, American, Classic, Premium',
        annual_dol: 164.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.713,
        store_longitude: -74.0097,
        total_orders: 1268.99,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Champagne, Intimate, Friendly, Casual, Modern, American, Exceptional, Dynamic, Unique',
        annual_dol: 2871.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9622,
        total_orders: 16.45,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Pizza, American, Spacious, Aesthetic, Serene, Friendly, Classic, Iconic, Exceptional, Contemporary',
        annual_dol: 44.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7604,
        store_longitude: -73.9703,
        total_orders: 278.59,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Upscale, Elegant, Impeccable, Modernist, Mediterranean, Romantic, Homemade, Classic, Sophisticated',
        annual_dol: 297.11,
        description:
            'This sophisticated spot draws a crowd for its Northern Italian menu served on white linens.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7145,
        store_longitude: -74.0062,
        total_orders: 115.57,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Mexican, Salad, Organic, Casual, Local, Premium, Classic',
        annual_dol: 532.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7577,
        store_longitude: -73.9678,
        total_orders: 267.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Stylish, Elegant, Exquisite, Premium, Perfect, Sweet, Chic, Unique',
        annual_dol: 505.78,
        description:
            '"Centrally located in the heart of Manhattan, the newly designed SPACE54 offers the ideal setting for your next big celebration or corporate event. A chic and stylish event venue, SPACE54 features a state-of-the-art sound system, intelligent lighting, and everything that’s required to make your party a premier multimedia experience! With 4500 sq. ft. of newly designed event space, including an exquisite 19’ marble bar in the cocktail area and another in the main room, Space54 is a perfect place to host your next special event!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6905,
        store_longitude: -73.9833,
        total_orders: 1723.39,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Sushi, Steak, Salad, Pizza, Tasty, Spicy, Hawaiian, Korean, Delicious, Polish',
        annual_dol: 1917.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6889,
        store_longitude: -73.9888,
        total_orders: 555.24,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Wine, Hotel, Trendy, Coffee, Gourmet, Vibrant, Eclectic, Premium, Cozy, Fun',
        annual_dol: 640.55,
        description:
            "The airy, loft-style rooms have free Wi-Fi, desks, minibars, TVs and cork floors; suites add sitting areas, coffee tables and music systems. Some suites come with bunk beds, murals painted by local artists and lounging hammocks.A surcharge covers continental breakfast, loaner bikes, and use of the 24-hour gym and business center. There's also a trendy bar and lounge with cocktails and Spanish-style fare.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7793,
        store_longitude: -73.9505,
        total_orders: 456.32,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Hamburger, Salad, Dessert, Beer, Margarita, Martini, Irish, Caesar Wrap, Thai',
        annual_dol: 486.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6378,
        store_longitude: -73.6351,
        total_orders: 238.99,
        neighborhood: '',
        keywords: 'Delicious, Tasty, Impeccable, Awesome, Outstanding, Superb, Hotel',
        annual_dol: 278.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7649,
        store_longitude: -73.9959,
        total_orders: 1737.04,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Rooftop, American, Modern, Amazing, Authentic, Friendly, Cool, Great, Local',
        annual_dol: 2581.34,
        description:
            'Nightclub & concert venue in a former horse stable with industrial decor, VIP areas & a balcony.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6934,
        store_longitude: -73.9673,
        total_orders: 579.56,
        neighborhood: 'Clinton Hill',
        keywords: 'Tacos, Pizza, Korean, Chinese, American, Casual, Fun, Great',
        annual_dol: 753.23,
        description: '"Korean Restaurant LGBTQIA+ Good food, good friends, and good times."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.9817,
        total_orders: 997.66,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Salad, Woody, Spectacular, Memorable, Iconic, Great, Excellent, Fresh, Heritage, Negroni',
        annual_dol: 1042.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7792,
        store_longitude: -73.9449,
        total_orders: 518.21,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Steak, Pizza, Meatball, Beer, Wine, Dessert, Spicy, American, French',
        annual_dol: 724.62,
        description:
            'Seasonal New American cuisine, cocktails & weekend brunch served in a relaxed restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7706,
        store_longitude: -73.8904,
        total_orders: 154.44,
        neighborhood: 'Astoria Heights/East Elmhurst/Flushing',
        keywords:
            'Garlic Bread, Pizza, Vodka, Wine, Italian, Chicken Parmigiana, Trendy, Delicious, Romantic, Healthy',
        annual_dol: 204.46,
        description:
            'Laid-back, modern Italian kitchen making sandwiches & pasta & selling pizzas whole or by the slice.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7546,
        store_longitude: -73.8926,
        total_orders: 350.56,
        neighborhood: 'Jackson Heights',
        keywords:
            'Salad, Italian, Dessert, Gourmet, Wine, Polished, Spacious, Clean, Serene, American',
        annual_dol: 358,
        description:
            'A husband-&-wife team runs this eatery that offers Italian classics in serene dark-wood environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7284,
        store_longitude: -73.9993,
        total_orders: 630.77,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Hotel, Beer, Cozy, Delicious, Comfortable, Intimate, Classic, Fantastic, Great',
        annual_dol: 718.41,
        description:
            'Since 1961, many famous artist have played at this iconic music club with nightly shows & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7279,
        store_longitude: -73.9993,
        total_orders: 316.17,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vegetarian, Moroccan, Salad, French, Exotic, Casual, Fun, Local, Hip, Vibrant',
        annual_dol: 1292.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7778,
        store_longitude: -73.9783,
        total_orders: 14223.75,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Sushi, Salad, Dessert, Japanese, Spicy, Delicious, Asian, Creamy, Casual, Teriyaki',
        annual_dol: 17190.08,
        description:
            'Generous portions of sushi & other Japanese fare in a modest setting with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7793,
        store_longitude: -73.9877,
        total_orders: 587.28,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Hotdog, Vegetarian, Salad, Italian, Spicy, Juicy, American, Homemade, Casual',
        annual_dol: 777.48,
        description:
            'Casual American dining with scenic views, open May to mid-October in Riverside Park South.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6498,
        store_longitude: -73.7828,
        total_orders: 444.99,
        neighborhood: 'Jamaica',
        keywords: 'Vegan, Burger, Coffee, British, Comfortable, Polish, Cool',
        annual_dol: 500.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.9355,
        total_orders: 1513.62,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Burger, Hotel, Garlic Bread, Coffee, Beer, Urban, Meatball, Ale, Premium, Modern',
        annual_dol: 1552.61,
        description:
            "Park.Featuring free Wi-Fi, the chic, understated rooms have flat-screen TVs and designer bedding. Upgraded rooms add balconies or patios with skyline views. A top-floor suite has a sitting area and floor-to-ceiling windows.Amenities include a modern cafe with a terrace and garden views, plus a low-key lobby lounge, and a rooftop bar offering panoramic city views. There's also a business center. Parking is available, and pets are welcome for a fee.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6853,
        store_longitude: -74.0004,
        total_orders: 1655.69,
        neighborhood: 'Cobble Hill',
        keywords:
            'Burger, Beer, Tasty, Upscale, Japanese, Cozy, Spanish, Mediterranean, Asian, American',
        annual_dol: 1741.29,
        description:
            'Eclectic Pan-Asian & American comfort dishes & cocktails in a stylish, intimate space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7482,
        store_longitude: -73.9414,
        total_orders: 488.87,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Chicken Caesar Salad, Salad, Coffee, Hotel, Cozy, Modern, Comfortable, Caesar Salad, Awesome',
        annual_dol: 510.07,
        description:
            'Straightforward rooms have free Wi-Fi and flat-screen TVs, as well as pull-out sofas, minifridges and coffeemaking facilities. Upgraded rooms offer city views, and suites add separate living areas. Room service is available 24/7.A laid-back dining room that has modern wall art. There’s also a cozy bar, plus a food mart and a fitness center (both open 24/7). Parking is available for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7541,
        store_longitude: -73.9494,
        total_orders: 1073.86,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Lobster, Sushi, Salad, Vegan, Hawaiian, Fruity, Hotel, Vodka, Upscale, Japanese',
        annual_dol: 2194.79,
        description:
            'An upscale rooftop hotel lounge with cocktails, Asian-fusion fare & views of the Manhattan skyline.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.9421,
        total_orders: 131.04,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Italian, Dessert, Meatball, Gourmet, Wine, Bruschetta, Creamy, Homemade, Spacious',
        annual_dol: 142.81,
        description:
            'Cheerful, laid-back Italian restaurant with outdoor tables serving gourmet pizzas & homemade pastas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7279,
        store_longitude: -73.9857,
        total_orders: 7244.01,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Pizza, Beer, Wine, Upscale, Elegant, Rum, Awesome, Iconic, Great',
        annual_dol: 7397.72,
        description:
            'Once a classic dive, this completely redone space now has a swanky look, food & big-time drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7305,
        store_longitude: -73.9949,
        total_orders: 10902.84,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Whiskey, Wine, Salad, Bourbon, Beer, Chicken Pot Pie, Margarita, Gourmet, Gluten Free, Rustic',
        annual_dol: 11529.4,
        description:
            'Gourmet burgers & other modern American fare plus craft beer & bourbon in a casual tavern setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7351,
        store_longitude: -73.9932,
        total_orders: 229.23,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Tasty, Salad, Delicious, Asian, Spectacular, Great, Fusion, Casual, Rooftop',
        annual_dol: 937,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8027,
        store_longitude: -73.968,
        total_orders: 386.58,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Burger, Vegan, Pizza, Italian, Wine, Vodka, Avocado Toast, French Toast, Delicious, Cozy',
        annual_dol: 403.34,
        description:
            'Unfussy Italian eatery offering pasta, pizza & meat dishes, plus weekend brunch, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9149,
        total_orders: 4.92,
        neighborhood: '',
        keywords:
            'Coffee, Tasty, Beer, Margarita, Karaoke, Spacious, Clean, Friendly, Awesome, Great',
        annual_dol: 20.1,
        description:
            '"This sexy bar/lounge is full of surprises! During the day we play all sports. We serve all types of beers. During the night it becomes more low key and private. Private events are held often! We love catering private parties."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8471,
        store_longitude: -73.938,
        total_orders: 77.31,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Tacos, Steak, Cuban, Mexican, Beer, Pizza, Dominican, Caribbean, Wine, Coffee',
        annual_dol: 316.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.8319,
        total_orders: 60901.96,
        neighborhood: 'Flushing',
        keywords: 'Hotel, Stylish, Modern, Great',
        annual_dol: 194519.58,
        description:
            "matches at the Arthur Ashe Stadium. It's 4 miles from LaGuardia Airport.Refined rooms feature Wi-Fi, flat-screen TVs, and tea and coffeemaking facilities, as well as sitting areas.An airport shuttle is complimentary. Other amenities consist of a restaurant/bar and a fitness room, plus electric charging stations. Breakfast and valet parking are available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7868,
        store_longitude: -73.7923,
        total_orders: 767.98,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Italian, Upscale, Pizza, Delicious, Glamorous, Mediterranean, Elegant, Seasonal, Classic, Traditional',
        annual_dol: 818.45,
        description:
            'Elegant eatery offering seasonal Italian cuisine in a converted mansion with patio dining & gardens.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.885,
        store_longitude: -73.9015,
        total_orders: 21.24,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Shrimp Cocktail, Italian, Roast Beef, Japanese, Asian, Pinacolada',
        annual_dol: 45.14,
        description:
            'Old-school diner with vintage booths serving a varied menu ranging from burgers to breakfasts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8135,
        store_longitude: -73.9447,
        total_orders: 1003.57,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'French Toast, Italian, French, Avocado Toast, Californian, Delicious, African, American, Casual, Polished',
        annual_dol: 1024.86,
        description:
            'Handsome, brick-&-wood clad outpost offering a range of New American, French & African flavors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9712,
        total_orders: 147.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Hamburger, Mexican, Breakfast Burrito, Tasty, Mediterranean, Moroccan, Caesar Salad, Luxurious, Contemporary',
        annual_dol: 555.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7438,
        store_longitude: -73.9877,
        total_orders: 4416.09,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Tacos, Tequila, Cornbread, Mexican, Cajun, Vegetarian, Salad, Margarita, Nachos, Bourbon',
        annual_dol: 4509.79,
        description:
            'Mexican BBQ menus & cocktails combine at this lively, casual spin-off of a popular food truck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6893,
        store_longitude: -73.9916,
        total_orders: 1417.4,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords: 'Wine, Beer, Whiskey, Scotch, Ale, Bourbon, Hotel, Smooth, Friendly, Juicy',
        annual_dol: 1480.24,
        description:
            'Craft beer, darts & a laid-back atmosphere bring regulars to this neighborhood pub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -73.9852,
        total_orders: 2593.34,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Japanese, Hotel, Modern, Perfect, Great, Terrific, American, Intimate',
        annual_dol: 2648.37,
        description:
            'The Gramercy Theatre is a music venue in New York City. It is located in the Gramercy neighborhood of Manhattan, on 127 East 23rd Street. Built in 1937 as the Gramercy Park Theatre, it is owned and operated by Live Nation as one of their two concert halls in New York City, the other being the nearby Irving Plaza.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7549,
        store_longitude: -73.9838,
        total_orders: 328.12,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Salad, Wine, Delicious, Recommended, Classic',
        annual_dol: 1341.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -73.9809,
        total_orders: 1936.53,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Salad, Chicken Caesar Salad, Fun, Caesar Salad, Amazing, Great, Excellent, Classic, Local',
        annual_dol: 2020.49,
        description:
            'Pro stand-up comedians perform on a red-brick-backed stage at this longtime club with a snug bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6886,
        store_longitude: -73.9508,
        total_orders: 2129.22,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Burger, Steak, Vegetarian, Tequila, Breakfast Burrito, Coffee, Beer, Nachos, Bbq, Spicy',
        annual_dol: 2227.47,
        description:
            'Mellow neighborhood joint featuring sandwiches, bar bites & cocktails, plus sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7539,
        store_longitude: -73.9999,
        total_orders: 11732.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Whiskey, Beer, Tasty, Spanish, Spacious, Chinese, Vibrant, American, Asian',
        annual_dol: 13860.92,
        description:
            'Asian-inspired eatery on the 5th floor of Hudson Yards with an oval-shaped bar & large wine wall.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -74.0005,
        total_orders: 972.95,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Ale, Beer, Tasty, Organic, Delicious, Friendly, Cool, Great, Casual, Excellent',
        annual_dol: 993.59,
        description:
            'Super-casual underground bar offering beer & diversions such as satellite sports, pool & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -73.9994,
        total_orders: 285.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Beer, Gin, Ale, Irish, Delicious, Cozy, Japanese, Fun, Casual',
        annual_dol: 305.72,
        description:
            'Classic Irish pub with exposed wood & a variety of draft beers, plus a pool table & dartboards.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9978,
        total_orders: 163.98,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Lobster, Champagne, Wine, Salad, Dessert, Vodka, Coffee, Beer, Lobster Bisque, Trendy',
        annual_dol: 171.09,
        description:
            'Casual Swiss spot with European rail-station-restaurant vibe & a hidden late-night space in back.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6415,
        store_longitude: -73.7786,
        total_orders: 371.42,
        neighborhood: 'Jamaica',
        keywords: 'Vegan, Hotel, Caribbean, Rooftop, Casual, Great, Attentive',
        annual_dol: 1518.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6452,
        store_longitude: -73.9703,
        total_orders: 685.72,
        neighborhood: 'Kensington',
        keywords:
            'Steak, Tacos, Austrian, Wine, Salad, Delicious, Beer, German, Romantic, South American',
        annual_dol: 745.32,
        description:
            'Austrian-influenced dishes & cocktails, plus beer & wine on tap offered in vintage-cool surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7914,
        store_longitude: -73.9688,
        total_orders: 217.76,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tequila, Mexican, Beer, Champagne, Nachos, Gluten Free, Eclectic, Authentic, Signature, Casual',
        annual_dol: 309.17,
        description: '"Serving Southwestern Mexican cuisine and bottomless brunch every weekend!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7311,
        store_longitude: -73.9856,
        total_orders: 2233.58,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Ale, Beer, Wine, Vintage, Seasonal, Friendly, Irish, Casual, Bright, Great',
        annual_dol: 2280.97,
        description:
            'Easygoing watering hole with a seasonal beer list where the vintage jukebox sets the mood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9887,
        total_orders: 42.1,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Hamburger, Tacos, Salad, Fish Tacos, Roast Beef, Tuna Melt Sandwich, Reuben Sandwich, American, Upscale',
        annual_dol: 66.61,
        description:
            'Breakfast, burgers & everything in between are listed on the long menu at this retro diner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7517,
        store_longitude: -73.9723,
        total_orders: 1920.34,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Salad, Shrimp Cocktail, Tasty, Wine, Upscale, Canadian, Delicious, Stylish',
        annual_dol: 2003.15,
        description:
            'Upscale, family-owned eatery featuring dry-aged steaks, chops & seafood dishes in a warm setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -73.9727,
        total_orders: 3219.99,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Salad, Delicious, Beer, Gin, Perfect, Irish, American, Authentic, Moscow Mule',
        annual_dol: 3320.45,
        description:
            'Multilevel Irish-style pub with numerous beers on tap, daily specials & an outdoor rooftop bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 4908.88,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Vegetarian, Wine, Upscale, Elegant, American, Modern, Classic, Casual',
        annual_dol: 5168.29,
        description:
            'Vast, elegant upscale restaurant featuring innovative American seafood & an extensive wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8184,
        store_longitude: -73.6434,
        total_orders: 1251.97,
        neighborhood: '',
        keywords:
            'Burger, Golfclub, French, French Onion Soup, Luxurious, Fresh, Great, Local, Fine',
        annual_dol: 1460.47,
        description:
            'Engineers Country Club is a historic country club located in Roslyn Harbor, New York, on the historic Gold Coast on the north shore of Long Island.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -74.0076,
        total_orders: 364.69,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Sushi, Luxurious, Japanese, Upscale, Zen, Cozy, Phenomenal, Serene, Italian, Spectacular',
        annual_dol: 1257.22,
        description:
            '"Bar icca offers seasonal Japanese inspired cocktails and small bites. icca Omakase and Bar icca is now open and accepting reservations through TOCK."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6781,
        store_longitude: -73.9388,
        total_orders: 7.35,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vegan, Beer, Wine, Quaint, Casual, Friendly, American',
        annual_dol: 30.06,
        description:
            '"Acoustik Garden is a quaint indoor & outdoor lounge, bar, grill space in Brooklyn. A styled space where you can host an array of events! We are located conveniently on Atlantic Avenue in Bedford-Stuyvesant! Suya & Jollofrice contains ( Peanut & Tomatoes ). You must be 21+ w/valid ID to gain entrance. LGBTQ FRIENDLY. ALL EXITS ARE FINAL! ***Allergy Alert*** Please be advised that food prepared here may contain these ingredients or traces of these ingredients: Milk, Eggs, Wheat, Gluten, Peanuts, Honey, Tomatoes, Soybeans, Tree Nuts, Fish and Shellfish."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7408,
        store_longitude: -73.9913,
        total_orders: 1703.66,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, Delicious, Margarita, Warm, Homemade, Sweet, Succulent',
        annual_dol: 1756.65,
        description:
            'Traditional Italian food & drink served in a warm setting with an old-world vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9901,
        total_orders: 2954.39,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Stylish, Luxurious, Trendy, Rich, Classy, Garlic Bread, Modern, French, Friendly, Wonderful',
        annual_dol: 3090.84,
        description:
            'Gay dance club with with DJs on 2 floors, plus lounge rooms to mingle in & an outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9836,
        total_orders: 12506.15,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Tequila, Shrimp Scampi, Wine, Vodka, Coffee',
        annual_dol: 12707.32,
        description:
            "Sceney spot that's both a chic lounge with DJs & a modern steakhouse serving prime cuts & seafood.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9898,
        total_orders: 13049.1,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Whiskey, Wine, Bourbon, Beer, Elegant, Spicy, Italian, Vintage, Delicious, Premium',
        annual_dol: 14631.17,
        description:
            'Classy nightspot with a vintage vibe housing a collection of 750+ brown spirits & live jazz shows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.9935,
        total_orders: 20.25,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Margarita, Coffee, Hotel, Fun, Great, Casual',
        annual_dol: 82.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9882,
        total_orders: 375.03,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Wine, Salad, Italian, French, Hotel, Classy, Polished, Comfortable, Belgian',
        annual_dol: 1533,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8046,
        store_longitude: -73.952,
        total_orders: 8034.49,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Wine, Vodka, Dessert, Salad, Cornbread, Peruvian, Romantic, Hotel, Warm, Crisp',
        annual_dol: 8512.62,
        description:
            "Minton's Playhouse is a jazz club and bar located on the first floor of the Cecil Hotel at 210 West 118th Street in Harlem, Manhattan, New York City. It is a registered trademark of Housing and Services, Inc. a New York City nonprofit provider of supportive housing.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -73.8747,
        total_orders: 286.66,
        neighborhood: 'North Corona/Jackson Heights/Flushing',
        keywords:
            'Steak, Ecuadorian, South American, Wine, Beer, Margarita, Latin, French, American, Spanish',
        annual_dol: 507.1,
        description:
            'Traditional Ecuadorian restaurant morphs into a late-night club on weekends with dancing & karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7286,
        store_longitude: -73.9998,
        total_orders: 497.25,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Coffee, Australian, Trendy, Avocado Toast, Delicious, Perfect, Natural, Fresh, Fantastic, Casual',
        annual_dol: 1351.35,
        description:
            '"Modern Australian breakfast and lunch, paired with beautiful coffee and a smile."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.778,
        store_longitude: -73.9217,
        total_orders: 169.03,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Wine, Beer, Awesome, Sweet, Great, Traditional, Local, Casual, Unique',
        annual_dol: 689.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9885,
        total_orders: 67.01,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'American, Italian, Hotel, Southern, Classic, Intimate, Great',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -74.0021,
        total_orders: 4223.88,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Beer, American, Wine, Gin, Hotel, Classic, Innovative, Creative, Casual, Sumptuous',
        annual_dol: 4703.01,
        description:
            'From Danny Meyer, this bar in the lobby of an arts center serves up inventive cocktails & bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7463,
        store_longitude: -73.9864,
        total_orders: 334.45,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Beer, Wine, Delicious, Korean, Karaoke, Hotel, Asian, Retro, Fusion, Creative',
        annual_dol: 483.99,
        description:
            '"Turntable Chicken Rock is a beloved KOREATOWN destination where its signature Best Korean fried chicken, unique retro music playing everyday. Located at 290 5th Ave, New York, NY 10001. The effect is delectable, elevating musical appetites while providing more conventional nourishment in the form of robustly flavored fare."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6829,
        store_longitude: -73.9229,
        total_orders: 86.34,
        neighborhood: 'Bedford-Stuyvesant/Ocean Hill',
        keywords:
            'Burger, Tasty, Delicious, Donut, American, French, Exquisite, Hotel, Fantastic, Modern',
        annual_dol: 352.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6467,
        store_longitude: -73.7855,
        total_orders: 1892.68,
        neighborhood: 'Jamaica',
        keywords: 'Wine, Champagne, Coffee, Spanish, Hotel, Donut, British, Premium, Exotic, Local',
        annual_dol: 2018.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6493,
        store_longitude: -73.7948,
        total_orders: 197.45,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Pizza, Mexican, Beer, Italian, Salad, Martini, Wine, Meatball, Upscale',
        annual_dol: 693.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6664,
        store_longitude: -73.7454,
        total_orders: 19.5,
        neighborhood: 'Laurelton/Jamaica/Springfield Gardens',
        keywords:
            'Burger, Vegetarian, Flavorful, Delicious, Upscale, Comfortable, Elegant, Jamaican, Clean, Caribbean',
        annual_dol: 79.69,
        description:
            'Traditional Caribbean cuisine prepared in a casual, upscale space with DJs & a lounge vibe at night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -73.9895,
        total_orders: 5257.65,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Tasty, Wine, Beer, Martini, French, Fancy, Club Sandwich',
        annual_dol: 5446.76,
        description:
            'Old New York is preserved at this tavern with a marble & mahogany bar, tin ceiling & dumbwaiter.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7326,
        store_longitude: -73.985,
        total_orders: 437.22,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Lobster, Lobster Roll, Hamburger, Beer, Organic, Delicious, American, Dope, Kung Pao, Relaxed',
        annual_dol: 498.55,
        description:
            'Low-key fish house & bar dishing up seafood & more in snug, industrial digs with nautical accents.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7311,
        store_longitude: -73.9837,
        total_orders: 489.06,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tacos, Tequila, Mexican, Vegetarian, Margarita, Nachos, American, Dope, Delicious, Casual',
        annual_dol: 544.94,
        description:
            '"Hermana NYC is a brand new concept restaurant in the East Village. We are a tequila bar serving fresh, authentic Mexican cuisine and beyond with a full tequila menu and live jazz every night."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7262,
        store_longitude: -73.9898,
        total_orders: 127.85,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Italian, Champagne, Dessert, Vietnamese, Vintage, Seasonal, Casual',
        annual_dol: 298.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8022,
        store_longitude: -73.9647,
        total_orders: 2209.31,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Burger, Steak, Beer, Whiskey, Wine, Margarita, Dessert, Bbq, American, Irish',
        annual_dol: 2427.13,
        description:
            '"Amity Hall is a modern American craft beer and sports destination best known for its diverse selection, handcrafted cocktails and lively atmosphere. Named after the once known Amity Street in the heart of Greenwich Village, Amity Hall was established in 2009 with the opening of its flagship location at 80 West 3rd Street. Open seven days a week with two locations conveniently located in NYC, Amity Hall features lunch, dinner and weekend brunch, and offers the perfect venue for hosting special events or watching a game."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7956,
        store_longitude: -73.9708,
        total_orders: 319.5,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Vegetarian, Kebab, Wine, Pakistani, Upscale, Delicious, Indian, Modern, Clean, Casual',
        annual_dol: 333.35,
        description:
            'Classic Indian fare including slow-cooked Awadhi dishes plus wine & tea in a stylish, modern space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5495,
        store_longitude: -74.1506,
        total_orders: 859.96,
        neighborhood: 'Great Kills',
        keywords:
            'Sushi, Salad, Gluten Free, Japanese, Delicious, Sashimi, Bbq, Asian, Sweet, Modern',
        annual_dol: 1036.11,
        description:
            'Entertaining hibachi chefs cook Japanese steaks tableside in a kid-friendly space with a sushi bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5894,
        store_longitude: -74.0894,
        total_orders: 378.86,
        neighborhood: 'Dongan Hills',
        keywords: 'Delicious, Pizza, Fun, Beer, Memorable, Casual, Signature',
        annual_dol: 381.77,
        description:
            'Bowling alley featuring 48 lanes, plus a snack bar with pizza & a laser maze.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7239,
        store_longitude: -73.8691,
        total_orders: 17.29,
        neighborhood: 'Rego Park/Middle Village/Flushing',
        keywords:
            'Salad, French, Colombian, Latin, Sangria, Sweet, Friendly, American, Casual, Mojito',
        annual_dol: 42.49,
        description:
            'This straightforward neighborhood diner offers up ample plates of Colombian comfort chow.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.975,
        total_orders: 7360.55,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Lobster Roll, Lobster, Chocolate Cake, Burger, Shrimp Cocktail, Shrimp Scampi, Martini, Fried Calamari, Beer, Wine',
        annual_dol: 7704.41,
        description:
            'Cavernous, deco-styled restaurant, best known for its lively happy hour, grilled seafood & oysters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.9695,
        total_orders: 531.14,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Salad, Italian, Vintage, Homemade, Traditional, Spacious, Casual, Fresh, Local',
        annual_dol: 554.17,
        description:
            'Traditional Italian dishes & wines in a contemporary, white tablecloth setting with outdoor tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.9479,
        total_orders: 44.08,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Salad, Steak, Wine, Champagne, Gourmet, French, Caesar Salad, Classy, Healthy, Casual',
        annual_dol: 180.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8294,
        store_longitude: -73.9486,
        total_orders: 2090.51,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Bourbon, Whiskey, Comfortable, Rustic, Cozy, Trendy, Beer, Friendly, Casual, Intimate',
        annual_dol: 2294.24,
        description:
            'Cozy neighborhood watering hole offering bourbon, beer & cocktails along with happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9855,
        total_orders: 1931.29,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Vegetarian, Scotch, Wine, Gluten Free, Beer, French Toast, French, Trendy',
        annual_dol: 2015.03,
        description:
            'Craft cocktails & American fare in an elegant, multi-level space with Old New York-inspired decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.849,
        store_longitude: -73.6452,
        total_orders: 934.34,
        neighborhood: '',
        keywords: 'Pizza, Salad, Beer, Margarita, Ale, American, Casual, Friendly, Local, Classic',
        annual_dol: 996.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.589,
        store_longitude: -74.1016,
        total_orders: 580.71,
        neighborhood: 'Todt Hill/Midland Beach',
        keywords:
            'Cheesecake, Salad, Mexican, Italian, German, Nachos, Rich, American, Eclectic, Asian',
        annual_dol: 623.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7883,
        store_longitude: -73.9536,
        total_orders: 1484.75,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Italian, Pizza, Gluten Free, Wine, French, Spicy, Fancy, Authentic, Classic',
        annual_dol: 1867.83,
        description:
            'Family-owned trattoria serving pizzas, pastas & higher-end Italian dishes with a Sicilian accent.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8268,
        store_longitude: -73.9263,
        total_orders: 456.68,
        neighborhood: 'Concourse',
        keywords: 'Pizza, Tasty, Comfortable, Casual, Awesome, Local, Great',
        annual_dol: 520.13,
        description:
            'Bronx sports bar is popular spot for gathering before Yankees home games, the only times it is open.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.6408,
        total_orders: 332.88,
        neighborhood: '',
        keywords:
            'Tequila, Margarita, Salad, Mexican, Dessert, Wine, Bbq, Yummy, Triple Sec, Exotic',
        annual_dol: 494.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7342,
        store_longitude: -74.0064,
        total_orders: 14371.41,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chinese, Peking Duck, Gluten Free, Homemade, Cozy, Sidecar, Asian, Fusion, Fabulous, Local',
        annual_dol: 17903.72,
        description:
            'Tiny restaurant under RedFarm for peking duck prix fixe meals plus a bar with drinks & Asian bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7341,
        store_longitude: -74.0031,
        total_orders: 205.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Steak, Tequila, Mexican, Beer, Salad, Margarita, Wine, Gluten Free, Spicy',
        annual_dol: 263.96,
        description:
            'Contemporary Mexican hot spot turning out innovative tacos & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7406,
        store_longitude: -74.0084,
        total_orders: 242.92,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Vegan, Salad, Coffee, Warm, Donut, Healthy, Japanese, Fresh, Intimate, Casual',
        annual_dol: 992.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6785,
        store_longitude: -73.6143,
        total_orders: 12.61,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Salad, Caribbean, Sweet, Casual, Local, American, Premium, Traditional',
        annual_dol: 51.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7352,
        store_longitude: -74.0092,
        total_orders: 950.77,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tacos, Zombie, Mexican, Beer, Hawaiian, Delicious, Upscale, Fun, Perfect, Western',
        annual_dol: 5139.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -74.0037,
        total_orders: 562.14,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Steak, Salad, Bourbon, Margarita, Italian, Organic, Seasonal, French, American',
        annual_dol: 599.51,
        description:
            'Bustling New American eatery with huge windows & an emphasis on farm-fresh fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8654,
        store_longitude: -73.8299,
        total_orders: 528.17,
        neighborhood: 'East Bronx/Baychester',
        keywords: 'Burger, Lobster, Cajun, Steak, Salad, Pizza, Flavorful, Wine, Beer, Friendly',
        annual_dol: 739.93,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings.Begin your voyage with our diverse food destinations, offering a wide variety of different choices. You can simply select your choices of meat, seafood, vegetables and what you\'ll get is a culinary creation that will make you feel like you\'re down in New Orleans. Our food quality, friendly service and cleanliness will exceed your expectations. We look forward to serving you!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7835,
        store_longitude: -73.9796,
        total_orders: 623.49,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Italian, Australian, American, Modern, Local, Friendly, Fresh, Great, Fine',
        annual_dol: 2622.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7673,
        store_longitude: -73.9212,
        total_orders: 2442.81,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Steak, Mexican, Beer, Salad, Wine, Coffee, Breakfast Burrito, Ale, Delicious, French Toast',
        annual_dol: 2494.64,
        description: '"Authentic & Modern Mexican-Fusion Cusines"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9466,
        total_orders: 2819.13,
        neighborhood: 'Greenpoint',
        keywords:
            'Tacos, Mexican, Beer, Wine, Tomato Soup, Tex-Mex, Premium, Paloma, Moscow Mule, Awesome',
        annual_dol: 2922.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7687,
        store_longitude: -73.9556,
        total_orders: 1444.74,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Salad, Sushi, Steak, Wine, Coffee, Beer, Ale, Irish, Delicious, Caesar Salad',
        annual_dol: 1536.96,
        description:
            'Longtime Upper East Side Irish bar & restaurant with a low-key feel & a grown-up following.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7647,
        store_longitude: -73.9197,
        total_orders: 11490.88,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Beer, Tasty, Steak, Salad, Wine, Delicious, Italian, Warm, Sweet',
        annual_dol: 12040.38,
        description:
            'A lively Irish pub with a hip vibe, brick-&-beam interior, a garden and locally sourced food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6452,
        store_longitude: -73.9579,
        total_orders: 1241.15,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Margarita, Delicious, Great, Creative, Traditional, Unique, Chill',
        annual_dol: 1271.55,
        description:
            'Popular neighborhood bar & tattoo shop featuring creative cocktails & brews in a laid-back setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6678,
        store_longitude: -73.9872,
        total_orders: 2268.8,
        neighborhood: 'Park Slope',
        keywords: 'Tequila, Wine, Vodka, Beer, Gin, Ale, French Onion Soup, Cozy, Vintage, French',
        annual_dol: 2419.62,
        description:
            'Old-school haunt doling out beer, cocktails & bar snacks in relaxed environs with vintage decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.673,
        store_longitude: -73.9903,
        total_orders: 334.28,
        neighborhood: 'Gowanus',
        keywords:
            'Wine, Beer, Salad, Bourbon, Turkish, Mediterranean, Spanish, Delicious, Casual, Wonderful',
        annual_dol: 1366.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6834,
        store_longitude: -73.9956,
        total_orders: 617.26,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Italian, Dessert, Wine, Meatball, Beer, Delicious, Bruschetta, Homemade, Romantic',
        annual_dol: 673.75,
        description:
            'Serves Northern Italian food with an emphasis on housemade pastas & seasonal ingredients.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6853,
        store_longitude: -74.0027,
        total_orders: 356.72,
        neighborhood: 'Columbia Street Waterfront District',
        keywords: 'Friendly, Warm, Casual, Hotel, Mediterranean, Classic, Great, Chill',
        annual_dol: 1252.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7341,
        store_longitude: -73.8711,
        total_orders: 1790.19,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Steak, Hamburger, Beer, Wine, Coffee, Italian, American, Delicious, Casual, Classic',
        annual_dol: 1947.03,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -73.9866,
        total_orders: 2179.4,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'American, Hotel, Fancy, Fine, Local, Creative',
        annual_dol: 2225.64,
        description:
            'The National Arts Club is a 501 nonprofit and members club on Gramercy Park, Manhattan, New York City. It was founded in 1898 by Charles DeKay, an art and literary critic of the New York Times to "stimulate, foster, and promote public interest in the arts and to educate the American people in the fine arts".',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7319,
        store_longitude: -73.9943,
        total_orders: 2825.89,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Lobster, Burger, Steak, Shrimp Cocktail, Lobster Bisque, French, French Onion Soup, Japanese, Bbq, Delicious',
        annual_dol: 2885.85,
        description:
            'Warm, clubby spot serves up live jazz & a classic American menu, heavy on fish & steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8547,
        store_longitude: -73.9372,
        total_orders: 506.1,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords: 'Burger, Steak, Tacos, Salad, Beer, Bbq, Peruvian, Wine, Quesadillas, American',
        annual_dol: 551.57,
        description:
            'Laid-back restaurant & bar serving nachos, wings & burgers, with take-out & delivery available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6849,
        store_longitude: -74.0029,
        total_orders: 13.46,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Vegetarian, Coffee, Mediterranean, Shawarma, Casual, Hummus, Fresh, Great, Middle Eastern, Diverse',
        annual_dol: 31.43,
        description:
            'Rustic-chic bar & eatery presenting a diverse menu of Mediterranean tapas & entrees, plus delivery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5514,
        store_longitude: -74.1492,
        total_orders: 30.86,
        neighborhood: 'Great Kills',
        keywords: 'Burger, Pizza, Salad, Baked Potato, Nachos, Irish, Bbq, Funky, Ale, Homemade',
        annual_dol: 52.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6477,
        store_longitude: -73.7783,
        total_orders: 32.6,
        neighborhood: 'Jamaica',
        keywords: 'Italian, Pizza, Donut, Wine, American, Modern, Premium, Western',
        annual_dol: 133.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9791,
        total_orders: 186.5,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Sushi, Sweet, Polish, Innovative, Comfortable, Iconic, Rooftop',
        annual_dol: 762.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.972,
        total_orders: 53.49,
        neighborhood: 'Midtown Manhattan/Tudor City/Midtown East',
        keywords:
            'Mexican, Burger, Beer, Wine, American, Irish, Delicious, Western, Modern, Casual',
        annual_dol: 218.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5838,
        store_longitude: -73.9363,
        total_orders: 128.72,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Sushi, Wine, Salad, Beer, Upscale, Great, Local, Chill',
        annual_dol: 157.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7638,
        store_longitude: -73.8317,
        total_orders: 654.66,
        neighborhood: 'Flushing',
        keywords:
            'Hamburger, Coffee, Hotel, Contemporary, Classic, Timeless, Gyoza, Casual, Dim Sum',
        annual_dol: 2676.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.8296,
        total_orders: 972.72,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Salad, Pizza, Wine, Gourmet, Beer, Vietnamese, Chinese, Luxurious, Clean, Modern',
        annual_dol: 3384.83,
        description:
            'Gourmet treatments of Chinese & other Asian cuisines served in a luxurious space with a waterfall.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.9682,
        total_orders: 6822.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Lobster Roll, Lobster, Cheesesteak, Hamburger, Tomato Soup, Salad, Shrimp Cocktail, Beer, Creamy',
        annual_dol: 6966.82,
        description:
            'Burgers & other American comfort food in down-to-earth digs with a lively atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7126,
        store_longitude: -74.0125,
        total_orders: 8616.86,
        neighborhood: 'World Trade Center/Financial District',
        keywords:
            'Dessert, Salad, Rich, Magical, Sweet, Contemporary, Modern, Western, Unforgettable, Seasonal',
        annual_dol: 9208.29,
        description:
            'Observatory located on floors 100-102 of One World Trade Center, with exhibits & restaurants.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9712,
        total_orders: 5530.02,
        neighborhood: 'Lenox Hill',
        keywords:
            'Wine, Beer, Upscale, Spacious, Chilean, Vibrant, Mediterranean, Greek, Classic, Authentic',
        annual_dol: 6150.95,
        description:
            'Upscale Greek seafood eatery with fresh fish by the pound, villa decor & expansive outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.9686,
        total_orders: 3571.58,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Burger, Shrimp Cocktail, Salad, Cajun, Chilean, Wine, Ribeye Steak, Caesar Salad',
        annual_dol: 3660.41,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8019,
        store_longitude: -73.736,
        total_orders: 39.76,
        neighborhood: '',
        keywords:
            'Italian, Burger, Salad, Shrimp Cocktail, Elegant, Fantastic, Great, Exotic, Fresh, Fine',
        annual_dol: 162.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.761,
        store_longitude: -73.975,
        total_orders: 113.37,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Salad, Greek, Mediterranean, Polished, Awesome, Greek Salad, American, Amazing, Wonderful',
        annual_dol: 463.41,
        description: '"Event Venue & Lounge located on the 5th Avenue in Manhattan"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.995,
        total_orders: 2620.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Mexican, Beer, Warm, Quesadillas, Perfect, Fabulous, Casual, Great',
        annual_dol: 2740.89,
        description:
            'Buzzing gay bar with weekend DJ sets offering pizzas, quesadillas & Sunday drag brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.688,
        store_longitude: -73.9881,
        total_orders: 701.24,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords:
            'Steak, Burger, Salad, Tequila, Champagne, Wine, Canadian, French Toast, Dessert, French',
        annual_dol: 747.85,
        description:
            'Trendy bistro features creative French-American meals in a stylish, sleek venue with patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7578,
        store_longitude: -73.9836,
        total_orders: 15783.75,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Margarita, Trendy, Spicy, Delicious, American, Intimate, Vibrant, Latin',
        annual_dol: 19042.1,
        description:
            'Lively restaurant with colorful decor, offering typical Mexican dishes, cocktails & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9813,
        total_orders: 315.95,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Upscale, Hotel, Wine, American, Great, Comfortable, Excellent, Modern',
        annual_dol: 322.65,
        description:
            "Modern rooms come with complimentary Wi-Fi, flat-screen TVs and iPod docks, as well as coffeemakers, and desks with ergonomic chairs. Some have microwaves and minifridges, and suites add pull-out sofas. Apartments include kitchenettes. Exercise equipment is available.Freebies include loaner iPads, evening wine hours, and all-day snacks and drinks in a lounge. There's also a laid-back American restaurant, an exercise room and a business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7549,
        store_longitude: -73.9859,
        total_orders: 414.54,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Wine, Beer, Japanese, Delicious, Quaint, Cozy, Homemade, Asian',
        annual_dol: 1694.51,
        description:
            'Sleek, refined spot serving Japanese home-cooking faves, such as yakitori, soba & hot pots.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7395,
        store_longitude: -73.9891,
        total_orders: 2055.97,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Salad, French, Mimosa, Korean, Modern, Contemporary, Asian, Savory, American, Fusion',
        annual_dol: 2298.02,
        description:
            'Creative takes on classic French plates are paired with craft cocktails & burlesque performances.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.9877,
        total_orders: 2230.72,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Sushi, Gluten Free, Japanese, Elegant, Innovative, Fun, Fabulous, Sleek, Creative, Polished',
        annual_dol: 2230.72,
        description:
            'Sleek chain with a full bar known for classic & creative rolls & other Japanese fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6915,
        store_longitude: -73.9845,
        total_orders: 152.62,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Hotel, Stylish, Rooftop, Great, Modern, Comfortable',
        annual_dol: 178.03,
        description:
            'Modern rooms have 42-inch flat-screen TVs, coffeemakers and Wi-Fi (fee), plus work areas with desks and ergonomic chairs. Suites adds separate living areas, while club rooms provide access to a lounge offering complimentary continental breakfast, all-day snacks and evening appetizers.Amenities include a Mexican-American restaurant and a rooftop bar, plus an indoor pool and a 24/7 fitness center. There’s also a business center and 4,300 sq ft of meeting space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9831,
        total_orders: 120.49,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Intimate, Perfect, Wonderful, Fantastic, Traditional',
        annual_dol: 155.38,
        description:
            '"Convene at 117 West 46th Street, our largest dedicated event venue in New York City. Large enough to fit groups of up to 500 seated participants but much more intimate than a convention center, this full-service event facility redefines sophistication and elegance and is ideal for those seeking "corporate-meets-social" receptions and plated dinners within the Times Square and Rockefeller Center area. As one of Convene\'s flagship properties, this space offers "white-box" design capability, giving professional meeting planners the ability to highly customize the space with multiple sponsors and corporate branding opportunities - from digital video walls to physical rooms and spaces."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.9893,
        total_orders: 50.27,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Steak, Vegetarian, Tasty, Dessert, Beer, Spanish, Delicious, Sangria, Warm',
        annual_dol: 144.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6614,
        store_longitude: -73.7029,
        total_orders: 112.35,
        neighborhood: '',
        keywords: 'Hotel, Cool, Amazing, Great, Casual',
        annual_dol: 459.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.9933,
        total_orders: 2318.05,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Hamburger, Vegetarian, Salad, Ale, French Onion Soup, French, Irish, American',
        annual_dol: 2432.19,
        description:
            'Large Irish bar decorated with dark wood & racing memorabilia and serving standard pub food & brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7463,
        store_longitude: -73.9888,
        total_orders: 1862.42,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Delicious, Golfclub, Hotel, Fun, Gourmet, Premium, Amazing, Great, Cool, Seasonal',
        annual_dol: 7612.95,
        description:
            '"Tucked beneath the streets of New York, be transported to a bucolic 1920s English country golf club featuring stunning crazy golf courses, craft cocktails, and gourmet street food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.995,
        total_orders: 1109.71,
        neighborhood: 'Nolita',
        keywords:
            'Burger, Vegan, Kebab, Spicy, Quaint, Trendy, Mediterranean, Yummy, Friendly, Eclectic',
        annual_dol: 1111.11,
        description:
            'The eclectic menu has a Mediterranean slant at this small, airy bistro with booths & a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -74.0001,
        total_orders: 1336.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Champagne, Wine, Beer, Delicious, Pizza, Rum, Fun, Awesome, Amazing, Sweet',
        annual_dol: 1394.32,
        description:
            'A twenty-something crowd frequents this dimly lit lounge with crimson bordello decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7743,
        store_longitude: -73.9081,
        total_orders: 11.06,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'Pizza, Salad, Beer, Wine, Gourmet, American, Vintage, Seasonal, Casual, Local',
        annual_dol: 45.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7483,
        store_longitude: -73.876,
        total_orders: 51.72,
        neighborhood: 'North Corona/Jackson Heights/Flushing/Elmhurst',
        keywords:
            'Steak, Peruvian, Shrimp Cocktail, Italian, Ecuadorian, Cuban, Karaoke, Fusion, Sweet, Omelette',
        annual_dol: 211.4,
        description:
            '"RESTAURANT ECUATORIANO ESPECIALIDAD COSTA Y SIERRA SERVICIO DE BAR KARAOKE AMBIENTE FAMILIAR"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6905,
        store_longitude: -73.9845,
        total_orders: 0,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Salad, Vegan, Tasty, Jamaican, Caribbean, Rum, Awesome, Great, Local, Signature',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7509,
        store_longitude: -73.9365,
        total_orders: 791.8,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Hotel, Tasty, Clean, Warm, Modern, Friendly, Great, Rooftop',
        annual_dol: 826.13,
        description:
            "Warm rooms feature free Wi-Fi and 46-inch flat-screen TVs, as well as microwaves, minifridges and coffeemakers. All have desks and rainfall showerheads; upgraded rooms add pull-out sofas.There's a bright, modern bar and grill, while a snack pantry, a gym and a business center are all available 24/7. Other amenities include a rooftop terrace and coin-operated laundry facilities. Valet parking is available for a charge.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7452,
        store_longitude: -73.9535,
        total_orders: 592.61,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Tacos, Steak, Tuna Steak, Gluten Free, Wine, Beer, Dessert, Spicy, Bbq, Cozy',
        annual_dol: 612.45,
        description:
            'This bar decorated with reclaimed wood offers American & Irish food plus a selection of craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6821,
        store_longitude: -73.9958,
        total_orders: 305.66,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Steak, Pizza, Cheesesteak, Italian, Salad, Meatball, Wine, Flavorful, Margarita, Mojito',
        annual_dol: 340.08,
        description:
            'Understated, brick-walled wine bar with an Italian shared-plates menu & brick-oven thin-crust pizza.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9871,
        total_orders: 323.17,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Sushi, Beer, Spicy, Japanese, Warm, Yummy, Crisp, Asian, Classy, Premium',
        annual_dol: 377.58,
        description:
            'Japanese restaurant specializing in handrolls & set menus amid minimalist surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.9887,
        total_orders: 1658.49,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Kebab, Tasty, Salad, Beer, Warm, Mediterranean, Rustic, Israeli, American',
        annual_dol: 1696.86,
        description:
            'Informal Middle Eastern restaurant also serving American eats in cozy, rustic digs with a piano.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -73.9873,
        total_orders: 2316.37,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Sushi, Burger, Flavorful, Beer, Wine, Spicy, Delicious, Jamaican, Caribbean, Thai',
        annual_dol: 2416.8,
        description:
            'Rustic-chic destination for contemporary craft cocktails & a limited menu of creative bar food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7322,
        store_longitude: -73.9882,
        total_orders: 485.14,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Spicy, Beer, Delicious, Wine, Gin, Chinese, Fiery, Spacious, Bbq, Sweet',
        annual_dol: 506.17,
        description:
            'Spicy Sichuan dishes like dan dan noodles are heat-rated by number at this informal restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9861,
        total_orders: 107.07,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Hotdog, Beer, Wine, Scotch, Gin, Bourbon, Spanish, Rum, Sweet, Lively',
        annual_dol: 437.66,
        description:
            'Spanish eatery offering Basque meals in a wood-clad room & finger foods at a bright bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8873,
        store_longitude: -73.907,
        total_orders: 3449.99,
        neighborhood: 'Fieldston/Riverdale/West Bronx',
        keywords: 'Sushi, Dessert, Wine, Salad, Delicious, Spicy, Mexican, Creamy, Spanish, Classy',
        annual_dol: 3523.19,
        description:
            'Convivial restaurant & bar for Pan-Latin small plates, creative cocktails & live-music nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.7702,
        total_orders: 300.86,
        neighborhood: 'Bayside',
        keywords: 'Thai, Sushi, Steak, Warm, Friendly, Exotic, Relaxed, Sweet, Modern, Authentic',
        annual_dol: 358.11,
        description:
            'Thai fare with a modern twist awaits at this colorful spot with turquoise walls & warm wood accents.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 1979.72,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Luxurious, Champagne, Wine, Upscale, Pizza, Tasty, Phenomenal, Trendy, Premium, Exquisite',
        annual_dol: 2021.72,
        description:
            'Plush lounge with creative cocktails, upscale American small plates & occasional celeb sightings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.752,
        store_longitude: -73.9697,
        total_orders: 2625.74,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Salad, Sushi, Japanese, Wine, Champagne, Tasty, Casual, American, Local, Fusion',
        annual_dol: 2681.46,
        description:
            'Intimate Japanese eatery & piano lounge specializing in sushi bowls, plus beer, wine & sake.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8284,
        store_longitude: -73.9486,
        total_orders: 18565.11,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Vegetarian, Cheesecake, Wine, Tasty, Beer, Meatball, Bourbon, Delicious, Cozy, Comfortable',
        annual_dol: 19181.25,
        description:
            'Craft cocktails & bar bites are offered in a low-lit, subterranean setting with a cozy vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8244,
        store_longitude: -73.9487,
        total_orders: 86.73,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Steak, Pizza, Italian, Meatball, Wine, Delicious, Ribeye Steak, Cozy, Warm',
        annual_dol: 223.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7581,
        store_longitude: -73.9881,
        total_orders: 72.9,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Tacos, Italian, Hotel, American, Legendary, Dutch, Contemporary, Perfect',
        annual_dol: 96.51,
        description:
            "The St. James Theatre, originally Erlanger's Theatre, is a Broadway theater at 246 West 44th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1927, it was designed by Warren and Wetmore in a neo-Georgian style and was constructed for A. L. Erlanger.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -74.0011,
        total_orders: 6224.63,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Salad, Wine, Gourmet, Upscale, Stylish, Mediterranean, Greek, Spectacular, Amazing',
        annual_dol: 6807.59,
        description:
            'Stylish Greek restaurant serving fresh seafood in a Mediterranean-style whitewashed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7471,
        store_longitude: -73.9868,
        total_orders: 516.17,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'African, Hotel, Pizza, Global, Caribbean, Legendary, Dessert, American, Stylish, Fusion',
        annual_dol: 2109.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7715,
        store_longitude: -73.9819,
        total_orders: 3034.08,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Wine, French, Rustic, Relaxed, Casual, Friendly, Yummy, Seasonal, Traditional',
        annual_dol: 3241.13,
        description:
            'Relaxed Parisian-style dining via chef Daniel Boulud with standout charcuterie & wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.9985,
        total_orders: 119.07,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Coffee, Luxurious, Hotel, Rich, Vodka, Funky, Chinese, Modernist, Premium, Bellini',
        annual_dol: 162.09,
        description:
            "or 2-block walk.Rooms have a modernist loft-like decor with high-ceilings, large windows, free Wi-Fi and HDTVs; many have hardwood floors, balconies, kitchenettes and/or oversized bathtubs.The bi-level Mercer Kitchen restaurant features chef Jean-Georges Vongerichten's inventive American food; it also provides 24-hour room service. The 24-hour concierges can arrange business services, limousines, health club passes or in-room spa treatments.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7235,
        store_longitude: -73.9885,
        total_orders: 2696.1,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Hamburger, Tequila, Beer, Boilermaker, Ale, Wine, American, Tasty, Moscow Mule',
        annual_dol: 3777.07,
        description:
            'Retro-style bar with leather banquettes, serving kitschy drinks, beer cocktails & burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7313,
        store_longitude: -73.9889,
        total_orders: 1950.59,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Burger, Gourmet, American, Swiss, Upscale, Premium, Classic, Sophisticated, Local, Signature',
        annual_dol: 2333.83,
        description:
            'Sports bar boasting lots of TVs, upscale pub food, cocktails & a woodsy, ski-lodge look.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7305,
        store_longitude: -73.9898,
        total_orders: 1273.61,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Steak, Tequila, Wine, Salad, Spicy, Scotch, Beer, Margarita, Juicy, Irish',
        annual_dol: 4798.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7247,
        store_longitude: -73.9921,
        total_orders: 236.06,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Tequila, Mexican, Margarita, Martini, Upscale, Brazilian, Artisanal, Traditional, Southern, Casual',
        annual_dol: 964.92,
        description:
            '"Chef Francisco Blanco brings the flavors of traditional Oaxacan cuisine with an elevated twist. IXTA is a celebration of ancient Mexican heritage and culture."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7696,
        store_longitude: -73.7737,
        total_orders: 1815.08,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Tequila Sunrise, Tequila, Tacos, Mexican, Steak, Margarita, Wine, Flavorful, Shrimp Cocktail, Salad',
        annual_dol: 2070.41,
        description:
            'Colorful restaurant with traditional Mexican offerings & fresh guacamole, plus live entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7151,
        store_longitude: -74.0103,
        total_orders: 2007.47,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Whiskey, Beer, Upscale, Mediterranean, Casual, Homey, Fun, Classic, Awesome',
        annual_dol: 2083.24,
        description: '"Cocktail Bar and Mediterranean Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.9661,
        total_orders: 2413.69,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Delicious, Mediterranean, Upscale, Greek, Spacious, Perfect, Fresh, Casual, Wonderful',
        annual_dol: 2741.5,
        description:
            'Upscale Greek dining with a seafood focus & full bar in a chic & spacious warehouse-style setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9689,
        total_orders: 585.3,
        neighborhood: 'Lenox Hill',
        keywords:
            'Vegetarian, Italian, Wine, Cozy, Bruschetta, Classy, Casual, Modern, Traditional, Authentic',
        annual_dol: 819.97,
        description: '"info@piccolacucinagroup.com"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7553,
        store_longitude: -73.9649,
        total_orders: 563.73,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, French, French Onion Soup, Wine, Dessert, Swiss, Margarita, Mediterranean, Caesar Salad',
        annual_dol: 789.76,
        description:
            'Classic French cuisine appeals to neighborhood locals & U.N. types alike at this cozy bistro.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9902,
        total_orders: 2979.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Hamburger, Vegan, Salad, Organic, Beer, Rustic, Sustainable, Moroccan, Trendy',
        annual_dol: 3394.66,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7561,
        store_longitude: -73.9807,
        total_orders: 1110.79,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Beer, Wine, Mexican, Margarita, Hotel, Delicious, Cozy, Spicy, Elegant, Comfortable',
        annual_dol: 1199.65,
        description:
            '"Join us at The Dawson Restaurant & Bar, the newest addition to the neighborhood located in the heart of midtown off 5th Ave. close to shopping, Rockefeller Centre, Radio City and Times Square. We are a family-owned Bar & Restaurant serving delicious fare, craft beers & libations. Our elegant bi-level venue is roomy with a large bar and comfortable seating. Stop by with friends afterwork for a quick bite and a cocktail. For Private Parties, offer a variety of party packages menus custom made for your next special event, featuring private dining rooms to entertain your guests; ample space to accommodate large parties all with a dedicated wait-staff by your side and to make your event memorable. For party inquiries, call us at 646-370-466"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7426,
        store_longitude: -73.9849,
        total_orders: 6380.03,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Steak, Burger, Salad, Sushi, Shrimp Cocktail, Chicago-Style, Tasty, Wine, Californian, Spicy',
        annual_dol: 6658.08,
        description:
            'Upscale chain eatery serving burgers, steaks & other American fare in stylish, spacious surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7483,
        store_longitude: -73.9905,
        total_orders: 5107.79,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Steak, Pizza, Beer, Salad, Coffee, Wine, Margarita, Dessert, Bourbon',
        annual_dol: 5241.73,
        description:
            'American restaurant with comfort fare, a raw bar & cocktails in a space with red-leather banquettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.9892,
        total_orders: 14039.66,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Hotel, Wine, Coffee, Beer, Modern, Polished, American, Contemporary, Classic, Rooftop',
        annual_dol: 14554.87,
        description:
            'walking from Madison Square Park.Featuring floor-to-ceiling windows and contemporary furnishings, the industrial-chic rooms come with free Wi-Fi, smart radios and flat-screen TVs. Upgraded rooms add balconies and/or Empire State Building views. Suites offer living areas.Amenities include a modern restaurant, a coffee shop and a polished lobby bar, as well as a patio and an airy rooftop cocktail lounge offering city views. Pets are welcome for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.9886,
        total_orders: 1930.3,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Irish, Rich, American, Cozy, Nachos, Rooftop, Casual, Friendly',
        annual_dol: 6830.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7241,
        store_longitude: -73.9959,
        total_orders: 3018.96,
        neighborhood: 'Nolita',
        keywords: 'Vegetarian, Trendy, Awesome, Incredible, Creative, Casual',
        annual_dol: 3254.22,
        description:
            'Artful nightspot featuring a vegetarian supper club, a bar & a moody space for live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7287,
        store_longitude: -73.9998,
        total_orders: 2767.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Pizza, Meatball, Italian, Beer, Wine, Shrimp Scampi, French Toast, Delicious',
        annual_dol: 2874.32,
        description:
            'Evocative gastropub & wine bar pairing its drinks with Italian-influenced fare in comfortable digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6701,
        store_longitude: -73.9584,
        total_orders: 1064.9,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Hamburger, Beer, Tasty, Wine, Spicy, Upscale, American, Vintage, Homemade',
        annual_dol: 1111.07,
        description:
            'Rustic-chic tavern in an old grocery store, serving creative pub grub with cocktails & craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7709,
        store_longitude: -73.9813,
        total_orders: 14927.52,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Rich, Hotel, Glamorous, Wine, Mexican, Trendy, Legendary, Delicious, Iconic, Margarita',
        annual_dol: 15315.76,
        description:
            "Central Park.Chic, modern rooms feature Italian linens, animal prints and eclectic artwork, plus flat-screen TVs, minibars and designer toiletries. Suites offer separate living areas. Wi-Fi is available (fee).There's a trendy lobby bar, a rooftop lounge and a relaxed seafood restaurant, along with a fitness room and a seasonal rooftop pool. Meeting and event spaces are also available. Parking is offered for a fee.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.9555,
        total_orders: 140.01,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Mexican, Beer, Shrimp Cocktail, Coffee, Dessert, American, Urban, Classic, Authentic, Great',
        annual_dol: 609.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -73.9276,
        total_orders: 279.24,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Coffee, Hotel, Spacious, Bright, Clean, Fusion, Lit, Rooftop, Hip, Casual',
        annual_dol: 1166.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7274,
        store_longitude: -73.9865,
        total_orders: 2899.82,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Whiskey, Vodka, Upscale, Beer, Delicious, Martini, Sushi, Warm, Classy',
        annual_dol: 13047.92,
        description:
            '"Lounge and bar offering beer, wine & liquor in a setting of exposed brick walls"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -73.987,
        total_orders: 1819.04,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Tequila, Vodka, Wine, Whiskey, Beer, Gin, Martini, Margarita, Tasty, Upscale',
        annual_dol: 1897.91,
        description:
            'Intimate, amber-lit martini bar in the basement of a townhouse with a bamboo garden & nightly DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.8672,
        total_orders: 448.77,
        neighborhood: 'Middle Village/Rego Park/Flushing',
        keywords:
            'Sushi, Shrimp Cocktail, Wine, Martini, Irish, Trendy, Cozy, Warm, Homemade, Asian',
        annual_dol: 1631.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5976,
        store_longitude: -74.063,
        total_orders: 221.23,
        neighborhood: 'Arrochar/Fort Wadsworth',
        keywords:
            'Burger, Pizza, Italian, Gluten Free, Flavorful, Wine, Rustic, Classy, Charming, American',
        annual_dol: 257.87,
        description:
            'Charming Italian mainstay dating back to 1921 serving rustic fare in a white-tablecloth setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5466,
        store_longitude: -74.138,
        total_orders: 424.89,
        neighborhood: 'Great Kills',
        keywords: 'Hurricane, Great, Fancy, Clean, Friendly',
        annual_dol: 483.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8871,
        store_longitude: -73.9045,
        total_orders: 112.03,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Vodka, Beer, American, Irish, Comfortable, Dope, Casual, Great, Excellent',
        annual_dol: 457.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.761,
        store_longitude: -73.9698,
        total_orders: 82.65,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Spacious, Sleek, High-End, Modern, Great, Clean',
        annual_dol: 337.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5605,
        store_longitude: -73.9108,
        total_orders: 133.47,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords: 'Local',
        annual_dol: 363.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.688,
        store_longitude: -73.9866,
        total_orders: 2324.21,
        neighborhood: 'Boerum Hill',
        keywords:
            'Salad, Wine, Stylish, Delicious, Cozy, Rustic, Mediterranean, Healthy, Friendly, Fabulous',
        annual_dol: 2922.54,
        description:
            'Persian-inspired eats & unique craft cocktails offered in a stylish, rustic-chic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9866,
        total_orders: 4236.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Salad, Wine, Italian, Organic, Warm, Crisp, Relaxed, American, Contemporary',
        annual_dol: 4290.25,
        description:
            'Relaxed New American bistro with fixed-price pre-theater meals available in a contemporary space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9898,
        total_orders: 977.28,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Gluten Free, Italian, Warm, Homemade, Seasonal, Friendly, Classy, Authentic, Fabulous, Fresh',
        annual_dol: 998.01,
        description:
            'Italian eatery offering Roman-style pastas, meats & more in a cozy, exposed-brick townhouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7573,
        store_longitude: -73.9862,
        total_orders: 17638.53,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Garlic Bread, Pizza, Chicken Parmigiana, Italian, Meatball, Vodka, Tasty, Elegant, Contemporary, Rooftop',
        annual_dol: 18180.12,
        description:
            'Low-key eatery offering a big menu of pizza & Italian classics, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -74.006,
        total_orders: 78.4,
        neighborhood: 'Civic Center',
        keywords:
            'Rich, Delicious, American, Coffee, Korean, Iconic, Premium, Fun, Hotel, Memorable',
        annual_dol: 320.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8034,
        store_longitude: -73.9559,
        total_orders: 1899.67,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Coffee, Sushi, Irish, Cozy, Modern, Laid-Back, Casual, Great',
        annual_dol: 2020.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7211,
        store_longitude: -73.9946,
        total_orders: 6585.08,
        neighborhood: 'Nolita',
        keywords: 'Wine, Upscale, Sweet, Friendly, Perfect, Fun, Classy, Cool, Great, Excellent',
        annual_dol: 6733.27,
        description:
            'DJs spin on weekends at this sleek, candle-lit nook attracting a varied crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7768,
        store_longitude: -73.9791,
        total_orders: 23920.68,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Steak, Burger, Mexican, Salad, Tasty, Margarita, Wine, Delicious, Fajitas, American',
        annual_dol: 25053.35,
        description:
            'Cozy neighborhood standby where Southwestern cuisine is accompanied by potent margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7482,
        store_longitude: -73.9929,
        total_orders: 10510.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Steak, Whiskey, Chocolate Mousse, Tasty, Beer, Wine, Legendary, Delicious, Nachos, Irish',
        annual_dol: 13769.96,
        description: 'Sports bar near Madison Square Garden serving drinks & pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -74.0046,
        total_orders: 260.39,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Luxurious, Iconic, Exceptional, Perfect, Urban, Modern, Natural, Unique',
        annual_dol: 419.62,
        description:
            '"Event space in the heart of Chelsea, weddings, product launch, bar/bat mitzvah, corporate events, photo shoots and more."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7236,
        store_longitude: -73.9406,
        total_orders: 15.16,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Beer, French, Italian, Dessert, Shrimp Scampi, American, Impeccable, Lively, Casual',
        annual_dol: 61.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5555,
        store_longitude: -74.214,
        total_orders: 89.72,
        neighborhood: 'Rossville',
        keywords: 'Fabulous, Awesome, Iconic, Great, Wonderful, Unique, Casual',
        annual_dol: 133.2,
        description: '"Trendy Catering Hall"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6453,
        store_longitude: -73.7825,
        total_orders: 456.33,
        neighborhood: 'Jamaica',
        keywords: 'Wine, Coffee, Martini, Upscale, Creative, Fine',
        annual_dol: 519.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6449,
        store_longitude: -73.7861,
        total_orders: 177.48,
        neighborhood: 'Jamaica',
        keywords: 'Pizza, Wine, Salad, Donut, Beer, Hotel, Premium, Swiss, Ample, Cool',
        annual_dol: 725.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8035,
        store_longitude: -73.9637,
        total_orders: 628.97,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Pizza, Vegetarian, Chicken Parmigiana, Salad, Italian, Gluten Free, Wine, Delicious, American, Casual',
        annual_dol: 647.27,
        description:
            'Thin crust pizzas & classic red sauce Italian fare at a casual neighborhood stalwart.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.9397,
        total_orders: 105.44,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Meatball, Italian, French, Fresh, Traditional, Great, Relaxed, Casual, Authentic',
        annual_dol: 370.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6046,
        store_longitude: -73.9698,
        total_orders: 54.96,
        neighborhood: 'Gravesend',
        keywords:
            'Pizza, Italian, Salad, Mexican, Coffee, Wine, Stylish, Asian, Premium, Irresistible',
        annual_dol: 92.3,
        description:
            'Stylish choice with an intimate bar for elevated kosher Italian cuisine & select imported wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6666,
        store_longitude: -73.9853,
        total_orders: 30.51,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Pizza, Italian, Gluten Free, Salad, Beer, Spicy, Wine, Avocado Toast, Warm',
        annual_dol: 124.71,
        description:
            'Sourdough-crust pizza with unique toppings offered in a nostalgic setup with sidewalk seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7526,
        store_longitude: -73.9724,
        total_orders: 777.73,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Sushi, Organic, Japanese, Upscale, Spicy, Elegant, French, Warm, Homemade',
        annual_dol: 827.65,
        description:
            'Japanese izakaya eatery where the emphasis is on robata grilling & sake drinking.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.9755,
        total_orders: 27.94,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Modern, Hotel, Beer, Contemporary, Perfect, Premium, Classic, Iconic, Creative',
        annual_dol: 114.19,
        description:
            '"Convene at 237 Park Avenue is a signature meetings and events "venue of the future," according to EventMB. Unlike any other conference center in New York City, this location is purpose-built for professional meeting planners who are looking to host executive retreats, corporate off-sites and private evening receptions. The unique venue features include a built-in fireplace, curated art, bronze bar taps, video walls, and plush lounge seating - all of which evoke intimacy and warmth. For those who seek a loft-style space in Midtown combined with the premium amenities and hospitality services of a conference venue, Convene at 237 Park Avenue will ensure that your participants leave impressed."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7807,
        store_longitude: -73.9494,
        total_orders: 1281.84,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tequila, Mexican, Tacos, Margarita, Flavorful, Pizza, Dessert, Delicious, Mojito, Authentic',
        annual_dol: 1354.74,
        description: '"Taqueria Mexicana"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6793,
        store_longitude: -73.9494,
        total_orders: 82.18,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Burger, Beer, Shrimp Cocktail, Margarita, Bbq, Upscale, Rum, African, Caribbean, Spacious',
        annual_dol: 335.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.728,
        store_longitude: -74.0022,
        total_orders: 11487.38,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Pizza, Salad, Chicken Parmigiana, Italian, Beer, Dessert, Martini, Spicy, Trendy',
        annual_dol: 11735.81,
        description:
            'Legendary circa-1915 cafe serving small plates & cocktails in an updated historic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -74.0161,
        total_orders: 7435.86,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Steak, Burger, Vegetarian, Cheesesteak, Mexican, Wine, Upscale, Italian, Stylish, French',
        annual_dol: 7618.45,
        description:
            'Sophisticated chophouse chain serving steak, seafood, sandwiches & cocktails in a lively setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.8842,
        total_orders: 913.07,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Salad, Meatball, Italian, South American, Bruschetta, Creme Brulee, American, Eclectic, Trendy, Creamy',
        annual_dol: 952.66,
        description:
            'Relaxing, brick-lined bar & eatery serves up filling Italian dishes & lunch specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7802,
        store_longitude: -73.9798,
        total_orders: 542.06,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegetarian, Lobster, Steak, Salad, Sushi, Tuna Steak, Italian, Wine, Rustic, Organic',
        annual_dol: 2042.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -73.9861,
        total_orders: 2682.74,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Vegetarian, Tasty, Korean, Beer, Spicy, Wine, Trendy, Ale, Japanese, Bbq',
        annual_dol: 3058.87,
        description:
            'Glossy lounge serving updated versions of Korean & Japanese street foods with cocktails & DJ tracks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9162,
        total_orders: 501.04,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Whiskey, Karaoke, Irish, Local, Cozy, Relaxed, Friendly, Fun, Western',
        annual_dol: 570.65,
        description:
            'Laid-back bar offering 20+ rotating beers on tap in a nothing-fancy setting with a back garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6831,
        store_longitude: -73.9927,
        total_orders: 4056.31,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Wine, Luxurious, Romantic, Cozy, Premium, Rum, Sweet, Fantastic, Perfect, Classic',
        annual_dol: 4143.8,
        description:
            'Low-lit lounge featuring craft cocktails amid bordello-inspired decor in a one-time massage parlor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.737,
        store_longitude: -73.9916,
        total_orders: 3678.15,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Sushi, Japanese, Wine, Spicy, Homemade, Artisanal, Sukiyaki, Premium, Spacious, Casual',
        annual_dol: 4043.06,
        description:
            'Outpost of a Japanese noodle chain offering housemade udon plus sushi & tempura in spacious digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9812,
        total_orders: 4946.99,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Lobster Tail, Sushi, Shrimp Cocktail, Salad, Wine, Upscale, Caesar Salad, American',
        annual_dol: 5127.68,
        description:
            'Outpost of the upscale steakhouse chain offers classic American fare & a clubby, refined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.772,
        total_orders: 851.24,
        neighborhood: 'Bayside',
        keywords: 'Salad, Steak, Korean, Nachos, Spicy, Delicious, Asian, Beer, Yummy, American',
        annual_dol: 890.47,
        description:
            '"We first opened our doors in 2006, in Flushing NY and we’ve been flapping our wings ever since. Our chicken has impacted our community so much so that we’ve expanded to our newest location on Bell Blvd Bayside where we will continue to provide superior food and service."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7787,
        store_longitude: -73.7765,
        total_orders: 484.61,
        neighborhood: 'Bayside/Bay Terrace',
        keywords: 'Tacos, Steak, Mexican, Gluten Free, Margarita, Wine, Hawaiian, Rum, Yummy, Fun',
        annual_dol: 1980.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8223,
        store_longitude: -73.9499,
        total_orders: 207.84,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Burger, Sushi, Salad, Beer, Coffee, Savory, Japanese, Spicy, Wine, Karaoke',
        annual_dol: 221.5,
        description:
            'Craft beer, pub grub & weekend brunch served in an urban-chic sports bar with karaoke nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9747,
        total_orders: 8738.82,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Chicken Caesar Salad, French Onion Soup, French, Irish, Caesar Salad, Filet Mignon, Hotel, Classic, Vibrant',
        annual_dol: 8993.99,
        description:
            'Bi-level French brasserie with a popular brunch, a vibrant bar scene & live opera Saturday nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.744,
        store_longitude: -73.9899,
        total_orders: 7498.84,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegetarian, Salad, Beer, Bourbon, Bbq, Legendary, Homemade, Sweet, Vibrant, Local',
        annual_dol: 7657.96,
        description:
            'Texas BBQ & beer served in a rustic-chic, cafeteria-style setup, with regular live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6941,
        store_longitude: -73.9618,
        total_orders: 143.09,
        neighborhood: 'Clinton Hill',
        keywords:
            'Hotdog, Tequila, Burger, Beer, Whiskey, Wine, Margarita, Delicious, Tranquil, Classy',
        annual_dol: 155.94,
        description:
            'Mellow hangout with craft cocktails, draft beer, local art & a tranquil garden patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6926,
        store_longitude: -73.9886,
        total_orders: 522.12,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Salad, Vegetarian, Organic, Delicious, Creamy, Crisp, Mediterranean, Healthy, Perfect, Casual',
        annual_dol: 2134.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7546,
        store_longitude: -73.9811,
        total_orders: 961.83,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Hotel, Beer, Fun, Awesome, Friendly, Latin, Great, Classic, Casual',
        annual_dol: 3376.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6951,
        store_longitude: -73.9961,
        total_orders: 203.26,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Pizza, Gluten Free, Tasty, Beer, Italian, Wine, Dessert, Warm, Spacious, Cozy',
        annual_dol: 207.57,
        description:
            'Thin-crust, brick-oven pizza with a gluten-free option, plus tap beers in an airy, minimalist space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7683,
        store_longitude: -73.9857,
        total_orders: 1373.44,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Italian, Coffee, Meatball, Elegant, Homemade, Authentic, Classic, Casual, Sophisticated',
        annual_dol: 1402.59,
        description:
            'Restaurant offering dishes from Puglia, including seafood crudo & pastas, plus Italian wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.984,
        total_orders: 1256.5,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Cheesecake, Hamburger, Pizza, Salad, Dessert, Wine, Bbq, Avocado Toast, Trendy',
        annual_dol: 1403.26,
        description:
            'Cheesecakes are the star at this Brooklyn-themed locale with deli & diner fare, BBQ & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.764,
        store_longitude: -73.9878,
        total_orders: 527.51,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Italian, Wine, Shrimp Scampi, Mediterranean, Bruschetta, African, Rustic, Premium, Spacious',
        annual_dol: 550.38,
        description:
            'A rustic space of wood beams & slate floors sets the scene for wines & Italian small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8086,
        store_longitude: -73.9521,
        total_orders: 155.18,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Tasty, Salad, Delicious, Beer, Turkish, Mediterranean, Healthy, Spectacular, Outstanding, Natural',
        annual_dol: 650.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7576,
        store_longitude: -73.9707,
        total_orders: 302.74,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Sushi, Japanese, Coffee, Sashimi, Quaint, Homemade, Sukiyaki, Casual, Fusion',
        annual_dol: 322.86,
        description:
            'Longtime Japanese spot for traditional fare like sushi & soba served in a serene setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7225,
        store_longitude: -73.9899,
        total_orders: 2372.57,
        neighborhood: 'Bowery',
        keywords: 'Stylish, Cozy, Warm, Intimate, Eclectic, Vintage, Hip, Fun, Perfect, Cool',
        annual_dol: 2422.91,
        description:
            'Cozy, hip nightspot with eclectic, vintage styling featuring diverse dance music & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7699,
        store_longitude: -73.9099,
        total_orders: 2.05,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords: 'French, Mediterranean, Casual, Local, American, Omelette, Greek, Cool, Chill',
        annual_dol: 8.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6383,
        store_longitude: -73.5818,
        total_orders: 127.85,
        neighborhood: '',
        keywords: '',
        annual_dol: 447.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9874,
        total_orders: 16.35,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Wine, Beer, Gin, Indian, Delicious, Paneer Tikka, Baingan Bharta, Casual, Local',
        annual_dol: 66.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.749,
        store_longitude: -74.0081,
        total_orders: 1887.76,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Italian, Intimate, Rooftop, Creative, Natural, Great, Fresh, Diverse, Complex, Casual',
        annual_dol: 2013.25,
        description:
            'Pier59 Studios is a multimedia fashion studio, located at Chelsea Piers in New York City. At 110,000 square feet, it is considered to be the largest commercial photography/multimedia studio in the world.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.9961,
        total_orders: 1671.38,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Whiskey, Beer, Wine, Delicious, Spicy, Warm, Bourbon, Cozy, Friendly',
        annual_dol: 1748.6,
        description:
            '"With over 300 bottles of whiskey and sprits we know how to pour your favorite drink. Combine that with an authentic Guinness pour and an update on classic pub food it\'s easy to call this your new local."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6807,
        store_longitude: -73.9778,
        total_orders: 276.69,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Delicious, Cozy, American, Great, Excellent, Casual',
        annual_dol: 743.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7471,
        store_longitude: -74.0084,
        total_orders: 185.55,
        neighborhood: '',
        keywords:
            'Pizza, Coffee, Golfclub, Gin, Organic, Premium, Indulgent, Radiant, Fun, Sophisticated',
        annual_dol: 758.46,
        description:
            'Chelsea Piers is a series of piers in Chelsea, on the West Side of Manhattan in New York City. Located to the west of the West Side Highway and Hudson River Park and to the east of the Hudson River, ...',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6781,
        store_longitude: -73.9795,
        total_orders: 11.14,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Gluten Free, Salad, Italian, Delicious, Nachos, Bruschetta, Sweet, Fresh, American',
        annual_dol: 45.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9786,
        total_orders: 714.74,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Rum, Sweet, Chinese, Friendly, Fun, Traditional, Fantastic, Great, Casual, Signature',
        annual_dol: 858.43,
        description:
            'Rock bands, a Polynesian-esque setting & cocktails enliven this offbeat tiki bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6661,
        total_orders: 393.16,
        neighborhood: '',
        keywords:
            'Vegetarian, Wine, Beer, Organic, Meatball, American, Trendy, Modern, Traditional, Seasonal',
        annual_dol: 401.5,
        description:
            'Colorful, arty local mainstay serving creative apps & other American fare plus brick-oven pizzas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6734,
        store_longitude: -73.9568,
        total_orders: 1054.85,
        neighborhood: 'Crown Heights',
        keywords:
            'Vegan, Vegetarian, Pizza, Organic, Beer, Savory, Ethiopian, Delicious, Zesty, Natural',
        annual_dol: 2068.12,
        description:
            'Plant-based Ethiopian cuisine is served in a colorful brick-walled space covered in murals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.585,
        store_longitude: -73.6521,
        total_orders: 271.9,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Sushi, Hotel, Tasty, Mediterranean, Sashimi, Comfortable, Outstanding, Great',
        annual_dol: 309.68,
        description:
            'Airy rooms with hardwood floors feature free Wi-Fi and flat-screen TVs. Suites add seating areas or separate living rooms with futons or pull-out sofas. Some suites have extra bedrooms or whirlpool tubs.Amenities include a formal Mediterranean restaurant and an informal bar/grill, plus a surf shop and event space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7173,
        store_longitude: -73.9551,
        total_orders: 2372.64,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Whiskey, Stylish, Romantic, Gourmet, Delicious, Warm, Irish, Urban',
        annual_dol: 6103.41,
        description:
            'This stylish tasting room of a local wine producer also offers beer & a menu of gourmet nibbles.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6769,
        store_longitude: -73.972,
        total_orders: 119.83,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Mexican, Tacos, Salad, Burritos, Warm, Healthy, Wholesome, Local, Casual, High-Protein',
        annual_dol: 612.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.745,
        store_longitude: -73.9832,
        total_orders: 386.74,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Hamburger, Salad, Sushi, Mimosa, Champagne, American, French, Bellini, Stylish',
        annual_dol: 2269.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -73.9771,
        total_orders: 57.42,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Salad, Roast Beef, Homemade, Stylish, Casual, Coleslaw, Classic, Incredible',
        annual_dol: 63.93,
        description:
            'Pastrami, matzo ball soup & other Jewish deli fare are found at this updated diner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.988,
        total_orders: 2031.48,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Hamburger, Meatball, Beer, Bbq, American, Wine, Thai, Retro, Classic',
        annual_dol: 2850.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.9751,
        total_orders: 1900.67,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Italian, Wine, Delicious, Rich, Cozy, Tranquil, Comfortable, Exquisite, Innovative, Friendly',
        annual_dol: 1944.02,
        description:
            'Tranquil old-school Italian spot with black-tie waiters, favored by regulars for special occasions.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.9836,
        total_orders: 1294.95,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Salad, Cheesesteak, French Toast, French, Philly Cheesesteak, Belgian, Caesar Salad, American, Retro, Reuben',
        annual_dol: 1370.07,
        description:
            "Multi-level '50s-themed diner with singing servers & an extensive menu of American classics.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7156,
        store_longitude: -73.9937,
        total_orders: 1493.32,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Sushi, Wine, American, Organic, Japanese, Hotel, Legendary, Healthy, Local, Traditional',
        annual_dol: 6104.2,
        description:
            '"Atmospheric sushi & omakase restaurant with specialty cocktails, sake, and wine."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.8804,
        total_orders: 31.81,
        neighborhood: 'Jackson Heights',
        keywords:
            'Colombian, Salad, American, Latin, Fusion, Traditional, Modern, Innovative, Casual',
        annual_dol: 130.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7425,
        store_longitude: -73.9992,
        total_orders: 1329.74,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Flavorful, Spanish, Wine, Gluten Free, Rich, Delicious, Vegan, Creamy, Smoky, Sweet',
        annual_dol: 1464.96,
        description:
            'Stylish, brick-walled Spanish eatery specializing in paella, with tapas & paired beverages.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -74.0054,
        total_orders: 109.11,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Classic, New Zealand, Premium, Hip, Casual, Great',
        annual_dol: 446.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7087,
        store_longitude: -73.9579,
        total_orders: 1272.19,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Whiskey, Wine, Beer, Upscale, Fun, Friendly, Awesome, Amazing, Great, Fine',
        annual_dol: 1327.35,
        description:
            'South Williamsburg heavy-metal bar, with horror/biker memorabilia & a unique jukebox.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6789,
        store_longitude: -73.9682,
        total_orders: 5078.05,
        neighborhood: 'Prospect Heights',
        keywords: 'Burger, Beer, Whiskey, Wine, Coffee, Texan, Bourbon, Rum, Karaoke, Nachos',
        annual_dol: 5346.3,
        description:
            'A sprawling Western-themed honky-tonk saloon serving Southern-style bar food & featuring live bands.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.687,
        store_longitude: -73.9622,
        total_orders: 64.35,
        neighborhood: 'Clinton Hill',
        keywords:
            'Vegetarian, Mexican, Salad, Coffee, Casual, Healthy, Friendly, Local, Vibrant, Great',
        annual_dol: 263.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7106,
        store_longitude: -73.9591,
        total_orders: 40.37,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Steak, Beer, Dominican, Bbq, Wine, Spanish, American, Latin, Premium, Traditional',
        annual_dol: 98.59,
        description:
            'A menu of traditional Dominican fare & drinks in an upbeat setting with exposed brick.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.9736,
        total_orders: 228.16,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Spanish, Shrimp Cocktail, Delicious, Sweet, Cozy, Spectacular, Classy, Friendly, Hotel, Sangria',
        annual_dol: 279.49,
        description:
            'Neighborhood spot that has been serving up Spanish classics in its cozy space since 1977.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -73.963,
        total_orders: 497.72,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Salad, Meatball, Italian, Wine, Beer, Thai, Creamy, Prosecco, Modern',
        annual_dol: 591.2,
        description:
            'Industrial-chic digs & skyline murals are the backdrop for an Italian cafe, coffeehouse & bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.929,
        total_orders: 5.36,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Salad, Wine, Bourbon, Savory, Chinese, Polished, American, Modern, Perfect',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7205,
        store_longitude: -73.9883,
        total_orders: 11083.49,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Rooftop, Hotel, American, Australian, Spectacular, Premium, Hip, Creative, Cool',
        annual_dol: 11374.83,
        description:
            '"Soho House\'s second property in New York, Ludlow House is a private members\' club located in a former gold-leaf factory and one-time funeral home on the Lower East Side. It has four floors of club space with a kitchen, two bars, a member event space and a rooftop restaurant."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7206,
        store_longitude: -73.9845,
        total_orders: 268.55,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Jamaican, Caribbean, Rum, Chilean, Colorful, Fantastic, Creative, Great, Unique',
        annual_dol: 332.46,
        description:
            'Funky, colorful venue offering creative Caribbean classics, plus desserts, rum & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9905,
        total_orders: 57.65,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Vegan, Mexican, Tacos, Tequila, Vegetarian, Margarita, Nachos, Spicy, Warm, Contemporary',
        annual_dol: 477.45,
        description:
            'Contemporary Mexican spot with a colorful, all-vegan menu, plus tequila & mezcal cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9851,
        total_orders: 7580.63,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Salad, Lobster Bisque, Wine, Ribeye Steak, Caesar Salad, American, High-End, Welcoming',
        annual_dol: 8008.74,
        description:
            'Storied chain serving high-end steakhouse fare, seafood & diverse wines in clubby surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.809,
        store_longitude: -73.9449,
        total_orders: 2087.93,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Pizza, Vegetarian, Italian, Wine, Margarita, Dutch, Ethiopian, Relaxed, Casual, Fusion',
        annual_dol: 2202.51,
        description:
            'Relaxed Italian spot with wood-fired pizzas, Italian entrees & craft cocktails, plus patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9842,
        total_orders: 8814.39,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Irish, Bbq, American, Vintage, Warm, Classic, Hip, Comfortable, Casual, Friendly',
        annual_dol: 9312.56,
        description:
            'Warm haunt featuring American fare in a wood-paneled dining room adorned with Broadway memorabilia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7427,
        store_longitude: -74.0039,
        total_orders: 316.12,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegetarian, Steak, Sushi, Salad, Gluten Free, Wine, Dessert, Japanese, Vodka, Chinese',
        annual_dol: 1107.17,
        description:
            'This enormous Pan-Asian eatery is known for a huge Quan Yin centerpiece & a trendy crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7113,
        store_longitude: -73.9494,
        total_orders: 1956.14,
        neighborhood: 'Williamsburg',
        keywords: 'Canadian, Whiskey, Wine, Beer, Ale, Awesome, Friendly, Great, Casual',
        annual_dol: 2014.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -73.9446,
        total_orders: 462.86,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Steak, Cheesesteak, Vegetarian, Vegan, Whiskey, Vodka, Beer, Wine, Gourmet',
        annual_dol: 742.15,
        description:
            'Chill neighborhood watering hole crafting gourmet takes on traditional pub grub & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.708,
        store_longitude: -74.0078,
        total_orders: 3392.52,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Steak, Fried Chicken, Japanese, Sashimi, Cozy, Miso Soup, Traditional, Tempura, Classic',
        annual_dol: 3539.61,
        description:
            'Japanese seafood, hot entrees & signature fried chicken plated in a sophisticated atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7274,
        store_longitude: -73.9844,
        total_orders: 615.85,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Dessert, Wine, Moroccan, French Toast, French, Mediterranean, Delicious, Organic, Lebanese',
        annual_dol: 645.1,
        description:
            'Classic Moroccan flavors & outdoor seating draw a bohemian crowd to this neighborhood standby.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7236,
        store_longitude: -73.9855,
        total_orders: 16.75,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Hamburger, Salad, Gourmet, Coffee, Wine, American, Warm, Cozy, French',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6078,
        store_longitude: -74.1627,
        total_orders: 26.92,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Sushi, Steak, Japanese, Sweet, Teriyaki Salmon, Friendly, Teriyaki, Local, Fresh, Casual',
        annual_dol: 110.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 1010.11,
        neighborhood: 'Astoria/LIC',
        keywords: 'Shrimp Cocktail, Chinese, Cuban, Beer, Bbq, Casual, Fresh, Great',
        annual_dol: 1053.91,
        description: 'Longtime, no-frills diner serving up a mix of Cuban & Chinese fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9256,
        total_orders: 5.8,
        neighborhood: 'Astoria/LIC',
        keywords: 'Tacos, Tequila, Mexican, Steak, Sushi, Nachos, Vodka, Latin, Exotic, Modern',
        annual_dol: 23.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9738,
        total_orders: 820.84,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Lobster, Dessert, Elegant, Delicious, Martini, Cozy, Polished, French, Picturesque',
        annual_dol: 875.41,
        description:
            "Bergdorf Goodman's 7th-floor cafe offers salads & sandwiches, afternoon tea & Central Park views.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7629,
        store_longitude: -73.9879,
        total_orders: 302.64,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Mexican, Salad, Vegan, Margarita, Dessert, Tasty, Champagne, Burritos, Korean',
        annual_dol: 741.44,
        description:
            'Overstuffed burritos and margaritas pull in a lively crowd, as does the outdoor seating in summer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6041,
        store_longitude: -74.0069,
        total_orders: 5.69,
        neighborhood: 'Bath Beach',
        keywords: 'Mexican, Margarita, Homemade, Casual, Sweet, Mojito',
        annual_dol: 23.24,
        description:
            '"We offer full bar services with hot & cold finger food, happy hour everyday from 5pm-9pm. We can host private events with full open bar and food!! Contact us to: paraisobar1752@gmail.com"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -73.9904,
        total_orders: 21.62,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegan, Coffee, Chicken Pot Pie, Nachos, American, Creamy, Cozy, Perfect, Sweet, Casual',
        annual_dol: 88.39,
        description:
            'Neighborhood coffeehouse serving eggs, granola, sandwiches & salads in a homey, laid-back setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.8841,
        total_orders: 9.75,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Steak, Mexican, Karaoke, Wine, Casual, Homemade, Authentic, Unforgettable, Fresh, Signature',
        annual_dol: 429.84,
        description:
            '"El Maguey offers the best in Mexican cuisine. Located in Jackson Heights, Queens we are the Mexican experience. Our dishes are built around the the idea that food should be authentic. Whether in the mood for a fish taco or one of our signature dishes, El Maguey ensures an unforgettable experience found only in Mexico - right in the heart of Queens! While you\'re here, don\'t forget to grab our signature cocktail -El Maguey at the bar or out in the side street seating area. We offer Karaoke nights, and are open LATE!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8672,
        store_longitude: -73.9172,
        total_orders: 149.77,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Tacos, Mexican, Salad, Nachos, Tasty, Fajitas, Latin, Dominican, Caesar Salad, American',
        annual_dol: 612.21,
        description:
            'Mexican dishes with a twist presented in a cool, casual setting with a warm atmosphere & full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6284,
        store_longitude: -74.0292,
        total_orders: 0,
        neighborhood: 'Bay Ridge',
        keywords:
            'Coffee, Ale, Modern, Tasty, Hawaiian, American, Friendly, Great, Casual, Wonderful',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9966,
        total_orders: 3349.87,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Spanish, Dessert, Wine, Salad, Tasty, Hotel, Classy, Spacious, Fabulous',
        annual_dol: 13693.15,
        description:
            'This longtime eatery offers large portions of paella & other traditional Spanish dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7062,
        store_longitude: -74.0035,
        total_orders: 2997.06,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Vegetarian, Gluten Free, Korean, Spicy, Asian, Ramen, French, Casual, Seasonal, Modern',
        annual_dol: 3336.58,
        description:
            'Pork dishes are prominently featured at this bustling Asian eatery from chef David Chang.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6869,
        store_longitude: -73.9631,
        total_orders: 625.41,
        neighborhood: 'Clinton Hill',
        keywords: 'Vegan, Tacos, Wine, Beer, Mexican, Tasty, Upscale, Natural, Perfect, Classy',
        annual_dol: 728.99,
        description:
            'Hip haunt with an all-vegan menu of Mexican plates & sweet treats plus cocktails & craft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6223,
        store_longitude: -74.0967,
        total_orders: 97.86,
        neighborhood: 'Silver Lake/Mid Island',
        keywords: 'Delicious',
        annual_dol: 319.48,
        description:
            'Easygoing, compact golf course with hilly & flat holes, a restaurant with a patio & event space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7253,
        store_longitude: -73.9843,
        total_orders: 88.74,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Thai, Spicy, Green Papaya Salad, Delicious, Polished, Irresistible, Traditional, Papaya Salad, Authentic',
        annual_dol: 271.88,
        description:
            'Smartly decorated Thai restaurant presenting regional Isan cuisine via shareable plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -73.9519,
        total_orders: 56.84,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Coffee, Mimosa, Funky, Israeli, Mediterranean, Cozy, Friendly, Perfect, Outstanding',
        annual_dol: 232.36,
        description:
            'Light-filled cafe with reclaimed accents & global-accented comfort food morning-early evening.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7778,
        store_longitude: -73.7798,
        total_orders: 62.08,
        neighborhood: 'Bayside/Bay Terrace',
        keywords:
            'Lobster, Sushi, Salad, Pizza, Gluten Free, Japanese, Wine, Sashimi, Spicy, Asian',
        annual_dol: 253.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9674,
        total_orders: 1429.89,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Shrimp Cocktail, Italian, Mexican, Asian, American, Southern, Local, Casual, Traditional, Lively',
        annual_dol: 1531.2,
        description:
            'Neighborhood Italian restaurant with a lively local bar scene, piano player & sing-along crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9715,
        total_orders: 7573.26,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Steak, Sushi, Salad, Vegetarian, Dessert, Wine, Gluten Free, Vodka, Chinese',
        annual_dol: 7733.95,
        description:
            'This enormous, clublike Pan-Asian eatery is known for a giant Buddha centerpiece & beautiful crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.971,
        total_orders: 50.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Tacos, Beer, Burritos, Wine, Homemade, Local, Casual, Healthy, Premium',
        annual_dol: 236.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9843,
        total_orders: 4478.68,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Tacos, Burger, Beer, Tasty, Irish, Bourbon, Bbq, Hotel, Delicious, Vintage',
        annual_dol: 4818.2,
        description:
            'Clubby Irish gastropub with an extensive beer menu featuring craft, domestic & international brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7402,
        store_longitude: -73.9889,
        total_orders: 3126.27,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Vegetarian, Wine, Whiskey, Coffee, Beer, Rustic, Italian, Comfortable, French, Californian',
        annual_dol: 4358.08,
        description:
            'This lively bistro serving French comfort food in a rustic setting is popular for brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6919,
        store_longitude: -73.9714,
        total_orders: 466.36,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Magical, Delicious, Chinese, Sustainable, Cozy, Italian, Organic, Premium, Relaxed',
        annual_dol: 634.87,
        description:
            'Relaxed wine bar with a zero waste goal serving tapas-style bites paired with natural wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6913,
        store_longitude: -73.9918,
        total_orders: 346.56,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Lasagna, Italian, Gluten Free, Pizza, Wine, Dessert, Upscale, Elegant, Ramen, Intimate',
        annual_dol: 1305.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9874,
        total_orders: 4379.84,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Hotel, Wine, Elegant, Cozy, Comfortable, Whiskey, Friendly, Classic, Great, Fantastic',
        annual_dol: 17655.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7452,
        store_longitude: -73.9883,
        total_orders: 3945.16,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Champagne, Wine, Hotel, Elegant, Delicious, Coffee, Legendary, Mediterranean, Australian, Iconic',
        annual_dol: 16126.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -74.0034,
        total_orders: 3075.24,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Burger, Dessert, Tasty, Delicious, Awesome, Excellent, Great, Classic, American',
        annual_dol: 4522.37,
        description:
            'Comfy eatery set in a four-story townhouse offering American plates & twists on classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.748,
        store_longitude: -73.8924,
        total_orders: 322.49,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Wine, Beer, Irish, Ale, Rustic, Awesome, Friendly, Authentic, Great, Local',
        annual_dol: 451.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7802,
        store_longitude: -73.9802,
        total_orders: 24.13,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Vegetarian, Italian, Coffee, Wine, Meatball, Beer, Organic, Bruschetta, Cozy',
        annual_dol: 69.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.994,
        total_orders: 9.03,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Cajun, Salad, Korean, Spicy, Delicious, Asian, Fried Chicken, French, Sweet',
        annual_dol: 36.91,
        description: '"Korean Fried Chicken"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6289,
        store_longitude: -73.9471,
        total_orders: 43.11,
        neighborhood: 'Midwood/Flatlands',
        keywords: 'Burger, Vegan, Tacos, Salad, Vegetarian, Dessert, Wine, Juicy, Coffee, Spicy',
        annual_dol: 176.2,
        description:
            '"A Juice Bar by day , Restaurant & Lounge by night. BEST of both worlds. We have a variety of fresh smoothies/juices and delicious Haitian American Dishes. We host events with live Djs providing great vibes on a nightly basis."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9838,
        total_orders: 17297.87,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Lobster Bisque, Wine, French Onion Soup, French, Legendary, Gin, Filet Mignon',
        annual_dol: 17297.87,
        description:
            'Iconic steakhouse offering classic cuts & raw bar items in renovated digs with red banquettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9838,
        total_orders: 277.36,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Cuban, Wine, Delicious, Salad, Fun, Rum, Welcoming, Sweet, Vibrant, Lively',
        annual_dol: 303.55,
        description:
            'A lively spot serving classic Cuban cuisine, with live music and hand-rolled cigars on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7695,
        store_longitude: -73.9885,
        total_orders: 202.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Beer, Wine, Spanish, Greek, Tex-Mex, Mediterranean, Local, Casual, Spacious',
        annual_dol: 225.59,
        description:
            'Classic Greek comfort eats served in a casual bistro setting with gingham tablecloths & tile floors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6919,
        store_longitude: -73.9259,
        total_orders: 68.23,
        neighborhood: 'Bushwick',
        keywords:
            'Coffee, Turkey Sandwich, Cozy, Homemade, Latin, Fun, Local, Casual, Great, Unique',
        annual_dol: 151.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -73.9549,
        total_orders: 699.65,
        neighborhood: 'Greenpoint',
        keywords:
            'Mexican, Salad, Canadian, Avocado Toast, American, Delicious, Vintage, Seasonal, Casual, Local',
        annual_dol: 714.49,
        description:
            'Seasonal cocktails pair with American plates in a bustling space with vintage touches & a small bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.733,
        store_longitude: -73.9596,
        total_orders: 2040.71,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Vodka, Wine, Ale, Coffee, Rum, Italian, American, Quaint, Sweet',
        annual_dol: 2378.71,
        description:
            'Gastropub-style small plates, house cocktails & brews in a quaint corner tavern with a tin ceiling.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6777,
        store_longitude: -73.998,
        total_orders: 456.52,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Vodka, Beer, Shrimp Cocktail, Coffee, Mimosa, Italian, Belgian, American, Seasonal',
        annual_dol: 880.89,
        description:
            "Circa-1890 bar, Brooklyn's oldest, featuring cocktails, beer, American dishes & outdoor tables.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.9537,
        total_orders: 297.97,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Salad, Italian, Meatball, Wine, Organic, Bruschetta, Polished, Classy, Authentic',
        annual_dol: 441.93,
        description:
            'Polished bistro & bar offering a menu of traditional Italian entrees, pasta & brick-oven pizza.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6786,
        store_longitude: -74.0026,
        total_orders: 552.99,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Lobster Roll, Lobster, Vodka, Vegetarian, Beer, Scotch, Rusty Nail, Instagrammable, Spicy, Upscale',
        annual_dol: 2260.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6468,
        store_longitude: -73.7841,
        total_orders: 3033.28,
        neighborhood: 'Jamaica',
        keywords: 'Flavorful, Wine, Salad, Burger, Steak, Beer, Martini, Donut, Warm, Hotel',
        annual_dol: 3097.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7035,
        store_longitude: -73.8021,
        total_orders: 27506.19,
        neighborhood: 'Jamaica',
        keywords: 'Steak, Italian, American, Asian, Traditional, Local',
        annual_dol: 29271.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.586,
        store_longitude: -73.7185,
        total_orders: 45.18,
        neighborhood: '',
        keywords: 'Classic, Great',
        annual_dol: 89.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.97,
        total_orders: 19.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Kebab, Salad, Pizza, Turkish, Mediterranean, Spicy, Premium, Traditional, Casual, Authentic',
        annual_dol: 28.6,
        description:
            'This bustling neighborhood Turkish spot dishes traditional food in basic environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7153,
        store_longitude: -74.009,
        total_orders: 3498.9,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Steak, Hamburger, Tacos, Vegetarian, Salad, Beer, Nachos, Chicken Wings, Wine',
        annual_dol: 3687.3,
        description:
            'Rustic sports bar with an enormous selection of beer, plus bar eats like ribs & wings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.9654,
        total_orders: 195.79,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Salad, Italian, Wine, Meatball, Upscale, Beer, Delicious, Cozy, Comfortable',
        annual_dol: 504.52,
        description:
            'Intimate Italian restaurant with upscale fare, many wines, desserts, and a fixed-price lunch option.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7375,
        store_longitude: -73.9813,
        total_orders: 1720.77,
        neighborhood: '',
        keywords:
            'Sushi, Spicy, Delicious, Korean, Premium, Japanese, Sashimi, Bbq, Fun, Fine Quality',
        annual_dol: 7033.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9859,
        total_orders: 2181.46,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegetarian, Wine, Gourmet, Gluten Free, Dessert, Margarita, Hotel, Organic, Delicious, Comfortable',
        annual_dol: 2233.48,
        description:
            'All-day spot in the Hotel Edison specializing in seasonal, mostly gluten-free comfort classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9929,
        total_orders: 1710.25,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hamburger, Gluten Free, Wine, Delicious, Beer, Dessert, Warm, American, Friendly, Wonderful',
        annual_dol: 1746.54,
        description:
            'Warm New American eatery with a bar & basement cabaret, known for drawing theatergoers & performers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7,
        store_longitude: -73.9938,
        total_orders: 1836.63,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Burger, Wine, Vodka, Gin, Martini, Ale, Stylish, Cozy, Warm, American',
        annual_dol: 1875.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7411,
        store_longitude: -73.993,
        total_orders: 6794.05,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Pizza, Burger, Wine, Upscale, Vodka, Beer, Gin, Karaoke, Elegant, Mediterranean',
        annual_dol: 6950.45,
        description:
            'Swanky, multi-level nightspot featuring pool tables, cocktails & a menu of upscale pizza & burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6871,
        store_longitude: -73.9904,
        total_orders: 1247.54,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords: 'Burger, Beer, Wine, Vietnamese, French, American, Cozy, Eclectic, Casual, Sweet',
        annual_dol: 1698.29,
        description:
            'An eclectic mix of comfort foods, from curry to Cincinnati-style spaghetti, served in a cozy space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7412,
        store_longitude: -73.9852,
        total_orders: 1204.12,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Coffee, Donut, Iconic, Timeless, Exquisite, Lively, Great, Seasonal, Cultural, Casual',
        annual_dol: 1256.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.9859,
        total_orders: 3017.01,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Salad, Vegetarian, Tasty, Korean, Wine, Spicy, Delicious, Spanish, Bbq, Modern',
        annual_dol: 3017.01,
        description:
            'Hip, contemporary Korean gastropub serving thoughtfully sourced small plates & soju cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7001,
        store_longitude: -73.9457,
        total_orders: 322.4,
        neighborhood: 'Bedford-Stuyvesant/Williamsburg',
        keywords: 'Coffee, Wine, Mexican, Beer, Scotch, Italian, American, Caribbean, Cozy, Homey',
        annual_dol: 870.17,
        description:
            'Seasonal dishes served in a hip, modern restaurant & wine bar that has a covered terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7667,
        store_longitude: -73.9687,
        total_orders: 640.21,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotel, Upscale, Luxurious, Premium, Classic, American, Sophisticated, Great, Organic, Modern',
        annual_dol: 2701.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5351,
        store_longitude: -74.237,
        total_orders: 21.45,
        neighborhood: 'Charleston',
        keywords:
            'Wine, Tasty, Dessert, American, Indulgent, Bright, Casual, Sangria, Great, Excellent',
        annual_dol: 87.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7258,
        store_longitude: -73.9917,
        total_orders: 10298.25,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Tequila, Wine, Beer, Margarita, Delicious, Hotel, Ale, Eclectic, Awesome, Classy',
        annual_dol: 10744.74,
        description:
            'Bi-level nightspot offering an eclectic musical mix, including live rock & DJ-led dance parties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7307,
        store_longitude: -73.986,
        total_orders: 309.34,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Italian, Wine, Dessert, Beer, Meatball, Delicious, Homey, Homemade, Innovative, Authentic',
        annual_dol: 421.11,
        description:
            'This Italian trattoria features a menu of Roman specialties, plus Italian wines & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6051,
        store_longitude: -73.9803,
        total_orders: 16.75,
        neighborhood: 'Gravesend',
        keywords:
            'Sushi, Salad, Meatloaf, Pizza, Peruvian, Tasty, Spanish, French, Casual, Premium',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7969,
        store_longitude: -73.9698,
        total_orders: 21.33,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Vegetarian, Salad, Flavorful, Beer, Contemporary, Tandoori Chicken, Casual, Indian, Retro, Cool',
        annual_dol: 87.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7074,
        store_longitude: -73.8353,
        total_orders: 15.92,
        neighborhood: 'Kew Gardens/Jamaica/Richmond Hill',
        keywords:
            'Mexican, Pizza, Wine, Beer, Gourmet, Italian, Spanish, Delicious, Peruvian, Elegant',
        annual_dol: 65.07,
        description:
            '"La Sala is an elegant Wine Bar & Dining Room focused on Gourmet Cuisine. We offer a full-liquor bar, curated Wine Menu & delicious flavored-sangrias in a classy, modern & chic space here in the Forest Hills,/Kew Gardens area."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9622,
        total_orders: 2256.68,
        neighborhood: 'Lenox Hill',
        keywords: 'Upscale, Premium, Perfect, Delicious, American, Cool, Casual',
        annual_dol: 2304.56,
        description:
            '"THE UPSCALE GENTLEMEN\'S CLUB When you are looking for the best in adult entertainment in NYC, World Famous Sapphire New York is your only choice. We now have 2 locations to chose from: Sapphire (Upper East Side) and the brand new Sapphire 39 (Midtown). Which ever location you choose to visit, Sapphire New York will pamper you with provocative dancers, full bottle service and an extensive list of wines and champagnes. We also offer our complimentary concierge services with VIP amenities! Sapphire New York is always featuring the sexiest Adult Stars every Thursday Night at Sapphire 39 plus Friday and Saturdays at Sapphire UES."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9673,
        total_orders: 977.15,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Salad, Mexican, Sweet, Nachos, Perfect, Casual, Greek, American, Great, Local',
        annual_dol: 1180.12,
        description: '"Sweet Annie\'s is a bar & eatery coming soon to New York City."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9666,
        total_orders: 27.22,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Pizza, Chicken Tikka Masala, Beer, Wine, Chilean, Homemade, Tandoori Chicken, Greek, Impeccable, Indian',
        annual_dol: 111.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6986,
        store_longitude: -73.9926,
        total_orders: 1019.04,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Lobster, Salad, Wine, Gourmet, Beer, Ale, Warm, Seasonal, Southern, Caesar Salad',
        annual_dol: 1063.23,
        description:
            'Inventive New American cuisine featuring seasonal ingredients, including wild game, since 1973.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7394,
        store_longitude: -73.9888,
        total_orders: 2054.8,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Steak, Burger, Vegan, Salad, Beer, Wine, Organic, American, Rustic, Upscale',
        annual_dol: 2143.89,
        description:
            'Upscale American fare & classic cocktails served in sophisticated environs with shabby-chic flair.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7414,
        store_longitude: -73.9874,
        total_orders: 4064.65,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Hotel, Steak, Dessert, Elegant, Vintage, Contemporary, French, American, Modern',
        annual_dol: 4325.57,
        description:
            'High-end American fare in a posh, clubby setting with dining rooms & a lounge with a pool table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.637,
        store_longitude: -73.6591,
        total_orders: 99.02,
        neighborhood: '',
        keywords: 'Delicious, Cajun, Warm, Friendly, French, American, Creole, Great, Casual',
        annual_dol: 445.84,
        description:
            '"Michael\'s Porthole is now Jessie\'s on the Water. New Ownership, New Management and New Chef! Come Dine with us and enjoy Live Music, Paint Nights, Comedy shows and much more."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9919,
        total_orders: 430.09,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Vegetarian, Salad, Chicken Parmigiana, Pizza, Italian, Gourmet, Coffee, Meatball, Wine',
        annual_dol: 501.37,
        description:
            'Family-owned go-to serving traditional Italian recipes in cozy rooms adorned with tin ceilings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7428,
        store_longitude: -73.9888,
        total_orders: 3044.02,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Italian, Rooftop, Tasty, Delicious, Rustic, Vibrant, American, Fun, Iconic, Seasonal',
        annual_dol: 3312.67,
        description:
            'Scenic rooftop setting for elevated Italian cuisine, house brewed ales & high-end imported beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7034,
        store_longitude: -73.9917,
        total_orders: 5598.24,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Burger, Thai, Innovative, American, Great, Aesthetic, Incredible, Local, Casual, Cultural',
        annual_dol: 6228.61,
        description:
            'Bi-level waterfront hangout showcasing a curated lineup of local food & drink plus cultural events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6851,
        store_longitude: -73.9913,
        total_orders: 436.67,
        neighborhood: 'Boerum Hill/Carroll Gardens/Brooklyn Heights',
        keywords: 'Pizza, Chinese, German, Upscale, Asian, Impeccable, Thai, Warm, Casual, Fresh',
        annual_dol: 455.6,
        description: '"We are Bringing the best food and drinks to Brooklyn!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -73.9902,
        total_orders: 1382.65,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Beer, Polish, Fantastic, Great, Incredible, Casual',
        annual_dol: 1411.99,
        description:
            'An underground pool hall with 19 billiards tables, a full bar, leagues & dozens of TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.599,
        store_longitude: -73.9893,
        total_orders: 293.63,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Lobster Tail, Tuna Steak, Shrimp Scampi, Tasty, Wine, Delicious, Spanish, Casual',
        annual_dol: 299.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7448,
        store_longitude: -73.9879,
        total_orders: 108.56,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Sushi, Lobster, Salad, Vegan, Japanese, Spicy, Healthy, Fun, Bright, Fresh',
        annual_dol: 367.65,
        description:
            'Little Japanese counter serve preparing sushi & rice bowls amid sea-blue lighting & ocean decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.8827,
        total_orders: 40.76,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Burger, Steak, Hotdog, Hamburger, Vegetarian, Mexican, Hawaiian, Wine, Spanish, American',
        annual_dol: 79.91,
        description:
            'Latin American lounge & restaurant offering live entertainment & TV sports in relaxed surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.67,
        store_longitude: -73.9585,
        total_orders: 132.94,
        neighborhood: 'Crown Heights',
        keywords: 'Scotch, Mexican, Gourmet, Dessert, Gin, Delicious, Hotel, Sweet, Scottish, Cool',
        annual_dol: 500.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8081,
        store_longitude: -73.9525,
        total_orders: 4480.56,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Steak, Salad, Tasty, Butter Chicken, Dessert, Italian, Funky, French, Healthy',
        annual_dol: 10995.31,
        description:
            'This roomy, funky joint with a central bar, big booths & patio serves Caribbean food & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7656,
        store_longitude: -73.9826,
        total_orders: 219.3,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Italian, Amazing, Great, Southern, Excellent, Friendly',
        annual_dol: 233.55,
        description:
            'from Broadway theaters. Set in a renovated tenement-style building, the wood-trimmed lobby has Old World details like a marble fireplace.Simply decorated rooms have marble bathrooms, minifridges, free WiFi and LCD TVs.The downstairs restaurant and bar serves southern Italian food and pizza.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.983,
        total_orders: 70.02,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Stylish, Hotel, Sleek, Bright, Modern, Urban, Lively, Perfect, Fun, Classic',
        annual_dol: 321.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9833,
        total_orders: 1258.36,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Tequila, Salad, Wine, Beer, Delicious, Stylish, Vietnamese, Bbq, Swiss',
        annual_dol: 1800.07,
        description:
            'New American bites, specialty cocktails & craft beers served in stylish environs with a long bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9837,
        total_orders: 2305.88,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Vodka, Dessert, Coffee, Italian, Martini, Caesar Salad, American',
        annual_dol: 2513.06,
        description:
            'Supper club with an American menu & classic cocktails featuring 3 cabaret shows per night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9905,
        total_orders: 1160.83,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Salad, French, American, Modern, Thai, Comfortable, Casual, Australian, Classic',
        annual_dol: 1424.31,
        description: '"Gala Dinners, daily"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.9773,
        total_orders: 107.05,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Lasagna, Salad, Italian, Wine, Rustic, Warm, Southern, Authentic, Cool, Local',
        annual_dol: 437.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6775,
        store_longitude: -73.8287,
        total_orders: 10320.22,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'Vodka, Burger, Hotel, Fun, Fantastic, Great',
        annual_dol: 10328.17,
        description:
            '"Located near JFK Airport, the casino has over 6,500 games, including electronic blackjack, roulette, craps, baccarat, video poker, and thousands of slots."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6391,
        store_longitude: -73.582,
        total_orders: 324.58,
        neighborhood: '',
        keywords: 'Burger, Mexican, Ale, Tasty, Hurricane, Local, Casual',
        annual_dol: 387.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7453,
        store_longitude: -73.999,
        total_orders: 944.68,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Salad, Gluten Free, Wine, Spicy, Delicious, Japanese, Thai, Sashimi, Hotel',
        annual_dol: 964.72,
        description:
            'Chelsea Japanese hangout with sushi bar, lunch specials & free salad bar for in-house diners.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -73.9959,
        total_orders: 1223.23,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Burger, Lobster, Salad, Vegetarian, Wine, French, Elegant, Mediterranean, Warm',
        annual_dol: 1276.26,
        description:
            'Mediterranean-accented French dishes served in a bright corner space with an elegant cottage feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6885,
        store_longitude: -73.9829,
        total_orders: 186.37,
        neighborhood: 'Downtown Brooklyn',
        keywords:
            'Korean, Spicy, Dessert, Delicious, Nachos, Sweet, Bbq, Friendly, Authentic, Casual',
        annual_dol: 761.81,
        description: '"Authentic Korean recipes since 2009"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6683,
        total_orders: 1320.66,
        neighborhood: '',
        keywords: 'Burger, Steak, Vegan, Pizza, Italian, Beer, Wine, Ale, American, Southern',
        annual_dol: 1350.17,
        description:
            'Roomy hangout serving American fare such as brick oven pizzas & burgers, plus tap brews & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7113,
        store_longitude: -74.0068,
        total_orders: 213.22,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Vegetarian, Upscale, Rich, Stylish, Trendy, Hotel, Coffee, Elegant, Organic, Romantic',
        annual_dol: 274.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.9785,
        total_orders: 798.61,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Tequila, Wine, Beer, Tasty, Hotel, French, Phenomenal, Paloma, Traditional, Signature',
        annual_dol: 875.25,
        description:
            '"The Cabinet offers a meticulously curated selection of agave distillates from all over Mexico, as well as cocktails, beer and wine, and bar snacks."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6764,
        store_longitude: -73.9561,
        total_orders: 151.68,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Beer, Friendly, Fun, Natural, Great, Casual',
        annual_dol: 622.89,
        description:
            '"Neighborhood bar serving a budget-friendly selection of craft beer, natural wines and cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.9776,
        total_orders: 528.26,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Wine, Beer, Salad, Dessert, Mediterranean, Comfortable, Friendly, Perfect, American',
        annual_dol: 834.04,
        description:
            'Low-key bar specializing in craft beers plus Mediterranean bites in a dimly lit corner setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 5363.94,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Burger, Cheesecake, Salad, Shrimp Cocktail, Wine, Dessert, Upscale, Savory, Elegant',
        annual_dol: 5752.78,
        description:
            'Upscale steakhouse in Time Warner Center offering the genre standards with Central Park views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -73.991,
        total_orders: 486.86,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Steak, Tacos, Margarita, Nachos, Breakfast Burrito, Spicy, Martini, Quesadillas, French, Funky',
        annual_dol: 1990.13,
        description:
            'Funky taverna lures locals with drinks & simple American-Mediterranean dishes for brunch & dinner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7278,
        store_longitude: -74.0004,
        total_orders: 41.37,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Delicious, Elegant, French, Romantic, Exquisite, Intimate, Perfect, Casual, Cool',
        annual_dol: 169.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6355,
        store_longitude: -73.5821,
        total_orders: 462.58,
        neighborhood: '',
        keywords: 'Steak, Wine, Warm, Inviting, American, Casual, Fresh, Great, Fantastic, Local',
        annual_dol: 647.44,
        description:
            'Steaks, seafood & cocktails served in warm environs with dark-wood wainscoting & a water-view deck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6763,
        store_longitude: -73.8216,
        total_orders: 38.71,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Chinese, Caribbean, Fried Rice, Bbq, Indian, Classic, Outstanding, Cultural, Great, Casual',
        annual_dol: 158.25,
        description:
            '"Guyanese, West Indian , Chinese comfort dishes, Discover Caribbean Fest, a Caribbean restaurant serving the best in Caribbean cuisine. Visit our restaurant today!, order online, and get your food, fast. or dining in 116-14 Rockaway Blvd, South Ozone Park New York 11420."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7387,
        store_longitude: -73.9944,
        total_orders: 2717.44,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Whiskey, Bourbon, Gin, Stylish, Cozy, Delicious, Intimate, Seasonal, American, Casual',
        annual_dol: 2835.26,
        description:
            'A semi-secret entrance (ring bell) leads to this plush lounge crafting Prohibition-era cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.553,
        store_longitude: -74.1919,
        total_orders: 303.55,
        neighborhood: 'Rossville',
        keywords: 'Sushi, Lobster, Salad, Mexican, Japanese, Spicy, Greek, Asian, Miso Soup, Local',
        annual_dol: 372.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -73.8093,
        total_orders: 8.52,
        neighborhood: 'Fresh Meadows/Flushing/Kew Gardens Hills',
        keywords: 'Beer, Ale, Wine, American, Relaxed, Fresh, Casual, Perfect, Great',
        annual_dol: 133.25,
        description:
            'Old-school sports bar with a relaxed vibe offering darts, a pool table & jukebox music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5295,
        store_longitude: -74.1617,
        total_orders: 98.06,
        neighborhood: 'Annadale/Southeast Annadale',
        keywords: 'Italian, Casual, Great',
        annual_dol: 400.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6873,
        store_longitude: -73.9749,
        total_orders: 19.85,
        neighborhood: 'Fort Greene',
        keywords:
            'Burger, Hamburger, Delicious, Chinese, American, Cozy, Mediterranean, Polished, Friendly, Awesome',
        annual_dol: 81.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6237,
        store_longitude: -74.1488,
        total_orders: 448.07,
        neighborhood: 'Mid Island/Graniteville',
        keywords:
            'Salad, Ramen, Japanese, Delicious, Homemade, Warm, Asian, Healthy, Fusion, Sweet',
        annual_dol: 738.19,
        description:
            '"Authentic japanese ramen made with bone broth that has been cooked for at least 8 hours. Also serving yakitori, bubble teas, and assorted sake and beers."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -73.9551,
        total_orders: 1707.83,
        neighborhood: 'Williamsburg',
        keywords:
            'Scotch, Whiskey, Beer, Irish, Cozy, Scottish, Moscow Mule, Friendly, Awesome, Cool',
        annual_dol: 3351.75,
        description:
            'Cozy Irish/Scottish bar offering a broad choice of whiskeys, plus imported beer, bar bites & pool.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -73.9567,
        total_orders: 1593.85,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Tacos, Beer, Whiskey, Relaxed, Cozy, American, Casual, Vintage, Delicious',
        annual_dol: 1687.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -73.9609,
        total_orders: 4318.88,
        neighborhood: 'Williamsburg',
        keywords: 'Lobster, Pizza, Spicy, Delicious, Mexican, Italian, Warm, Upscale, Creamy, Cozy',
        annual_dol: 4506.72,
        description:
            '"A French style cafe with Latin roots, Cafe Rosarito is a local spot serving simple amazing dishes which use only the highest quality ingredients"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7467,
        store_longitude: -73.9828,
        total_orders: 125.95,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Sushi, Salad, Steak, Vegetarian, Beer, Japanese, Spicy, Sashimi, Delicious, Seasonal',
        annual_dol: 213.06,
        description:
            'Relaxed neighborhood eatery serving Japanese favorites like sushi rolls, sashimi & tempura.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9782,
        total_orders: 254.93,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Pizza, Vodka, Gourmet, Meatball, Beer, Chinese, Wine, Tasty, Spicy, Polish',
        annual_dol: 368.16,
        description:
            '"If you\'re looking for authentic Szechuan food in town, look no further than Mazu Szechuan Cuisine. A Chinese restaurant in New York, NY, we specialize in Chinese food and homemade dim sum. Whether you want to dine in, grab take out, or deliver, our menu offers something for everyone to enjoy."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.8783,
        total_orders: 19.85,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords:
            'Mexican, Tacos, Beer, Hamburger, Enchiladas, Colombian, Hotel, Urban, Fusion, Vibrant',
        annual_dol: 109.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.9761,
        total_orders: 21403.68,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Lobster, Vegetarian, Vegan, Pizza, Salad, Gluten Free, Japanese, Eclectic, Legendary',
        annual_dol: 22980.99,
        description:
            'Sleek Japanese-Peruvian spot from Nobu Matsuhisa frequented by business types & celebs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7638,
        store_longitude: -73.9887,
        total_orders: 34.83,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Lobster Roll, Cajun, Delicious, Cajun-Style, Wine, Margarita, Thai, Fun, Bbq',
        annual_dol: 81.2,
        description:
            'Seafood spot serving boils, lobster rolls & fried fish platters plus nautically themed cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9922,
        total_orders: 6718.52,
        neighborhood: 'Bowery',
        keywords: 'Wine, Vodka, Trendy, Glamorous, Gin, Rum, Premium, Classy, Cozy, Asian',
        annual_dol: 7486.21,
        description:
            'Unique & exclusive club features nightly shows that combine music, theater, burlesque & erotica.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7673,
        store_longitude: -73.9803,
        total_orders: 114.78,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Wine, Rich, Intimate, Timeless, Casual, Signature, Unique',
        annual_dol: 160.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9783,
        total_orders: 97.68,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Comfortable',
        annual_dol: 399.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7062,
        store_longitude: -73.8315,
        total_orders: 10.82,
        neighborhood: 'Kew Gardens/Jamaica',
        keywords:
            'Steak, Salad, Hotdog, Peruvian, South American, Dessert, French, Latin, Dominican, American',
        annual_dol: 44.22,
        description:
            'Rotisserie chicken, Peruvian/Latin dishes & sangria in a festive-casual space with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6812,
        store_longitude: -73.9752,
        total_orders: 705.19,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Tequila, Mexican, Beer, Cornbread, Margarita, Wine, Tex-Mex, Rum, American, Spicy',
        annual_dol: 729.38,
        description:
            '"Step into Brooklyn’s modern day cantina. Chef-driven street inspired Mexican food. 75 of Mexico’s most prized tequila’s and mezcal’s along with hand crafted cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.9996,
        total_orders: 311.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Mexican, Tequila, Beer, Margarita, Nachos, Tasty, Ale, Enchiladas, Delicious, Homemade',
        annual_dol: 325.5,
        description:
            'Mexican joint offering tacos, creative dishes & tequilas in a colorful space with street art.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6796,
        store_longitude: -73.9821,
        total_orders: 1793.66,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Coffee, Ale, Wine, American, Local, Casual, Approachable, Great',
        annual_dol: 1911.53,
        description:
            'Bar serving draft beers by its own brewery, along with other craft brews & food by pop-up eateries.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6736,
        store_longitude: -73.9571,
        total_orders: 3180.67,
        neighborhood: 'Crown Heights',
        keywords:
            'Mexican, Tequila, Tacos, Beer, Margarita, Wine, Enchiladas, Burritos, Delicious, Sweet',
        annual_dol: 3919.78,
        description:
            'Mexican fare along with a selection of tequilas, margaritas & other specialty cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7162,
        store_longitude: -73.9518,
        total_orders: 6818.22,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Quaint, Hotel, Friendly, Intimate, Innovative, Cool, Great, Casual',
        annual_dol: 6962.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.961,
        total_orders: 1231.52,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Beer, Wine, Irish, Cozy, Awesome, Friendly, Amazing, Classic, Great, Traditional',
        annual_dol: 1302.92,
        description:
            'A rare Scottish bar in Williamsburg, with hot toddies, televised soccer games & a back garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6755,
        store_longitude: -73.9638,
        total_orders: 2479.92,
        neighborhood: 'Prospect Heights',
        keywords:
            'Pizza, Beer, Wine, Cozy, Spacious, Homemade, Perfect, Wonderful, Creative, Great',
        annual_dol: 2653.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6763,
        store_longitude: -73.9631,
        total_orders: 1062.21,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Beer, Martini, Modern, Spacious, Cozy, Warm, Classy, Natural, Relaxed',
        annual_dol: 1792.78,
        description:
            'Relaxed gathering place spotlighting cool cocktails, draft beer & wine, plus snacks & light bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.73,
        store_longitude: -73.9832,
        total_orders: 989.31,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Garlic Bread, Pizza, Salad, Wine, Italian, Delicious, Upscale, Warm, Funky, Rustic',
        annual_dol: 1010.3,
        description:
            'Upscale Tuscan-American restaurant in a warm setting with an open kitchen & a large wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.9838,
        total_orders: 916.3,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegetarian, Delicious, Perfect, Vintage, Moroccan, Awesome, Cool, Aesthetic, Great, Iconic',
        annual_dol: 1409.57,
        description:
            'A hookah bar with a full menu of Middle Eastern favorites in a vibrantly decorated space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7156,
        store_longitude: -73.9444,
        total_orders: 150.07,
        neighborhood: 'Williamsburg',
        keywords:
            'Mexican, Beer, Margarita, Wine, Trendy, Rustic, Delicious, Classy, Sweet, Authentic',
        annual_dol: 156.58,
        description:
            'Traditional Mexican dishes served on rustic communal tables, plus a huge selection of tequila.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7533,
        store_longitude: -73.9808,
        total_orders: 151.58,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Tacos, Mexican, Salad, Margarita, Burritos, Coffee, Baked Potato, American, French, Spicy',
        annual_dol: 619.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7416,
        store_longitude: -73.9809,
        total_orders: 907.78,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Sushi, Japanese, Chinese, Fried Rice, Asian, Hotel, Fusion, Filet Mignon, Perfect',
        annual_dol: 1050.76,
        description:
            'Upbeat, bi-level Japanese restaurant & bar featuring hibachi tables with performing chefs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8539,
        store_longitude: -73.889,
        total_orders: 36.42,
        neighborhood: 'Little Italy/Belmont',
        keywords: 'Wine, Beer, Warm, Italian, Fun, Relaxed, Colorful, Authentic, Comforting, Cool',
        annual_dol: 105.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -73.9532,
        total_orders: 2774.39,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Salad, Sweet, Friendly, Korean, French, Bbq, Creole, Southern, Cool, Casual',
        annual_dol: 2894.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6658,
        store_longitude: -73.9202,
        total_orders: 83.17,
        neighborhood: 'Brownsville',
        keywords:
            'Pizza, Vegan, Italian, Chinese, Bbq, Margarita, Pinacolada, Fresh, Fun, Friendly',
        annual_dol: 339.96,
        description:
            '"Villains Hideout is the only pop culture, comic book pizza bar dedicated to fandom from the Villains perspective! Located in the Brownsville section of Brooklyn, Villains Hideout offers a geek bar space for all fans."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9489,
        total_orders: 301.51,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Salad, Shrimp Cocktail, Wine, Italian, Coffee, Classy, Friendly, Sweet, Casual, Southern',
        annual_dol: 311.06,
        description: 'Straightforward Italian cooking from this family-run eatery open since 1977.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5538,
        store_longitude: -74.2177,
        total_orders: 181.8,
        neighborhood: 'Rossville/Charleston',
        keywords: 'Salad, Shrimp Scampi, Italian, Mediterranean, Bruschetta, Cozy, Casual',
        annual_dol: 202.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7278,
        store_longitude: -73.991,
        total_orders: 868.78,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Beer, Rustic, Ale, Cozy, Irish, Friendly, Casual, Classy, Laid-Back',
        annual_dol: 887.21,
        description:
            'Rustic neighborhood watering hole with a laid-back Irish vibe & frequent drink specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7324,
        store_longitude: -73.9875,
        total_orders: 5199.6,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Thai, Salad, Dessert, Trendy, Delicious, Green Curry, Beer, Classy, Fun, Intimate',
        annual_dol: 8785.74,
        description:
            'Intimate space with al fresco dining offering Thai noodles & soups, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7936,
        store_longitude: -73.9722,
        total_orders: 402.11,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Shrimp Cocktail, Steak, Chinese, Cuban, Fried Rice, Spanish, Omelette, Latin, Casual, Cultural',
        annual_dol: 1643.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.695,
        store_longitude: -73.8451,
        total_orders: 677.92,
        neighborhood: 'Richmond Hill',
        keywords: 'Clean, Fun, Awesome, Great, Caribbean, Casual',
        annual_dol: 737.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.9479,
        total_orders: 162.79,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Burger, Lobster, Steak, Sushi, Salad, Japanese, Coffee, Chinese, Russian, Delicious',
        annual_dol: 443.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8191,
        store_longitude: -73.9375,
        total_orders: 97.15,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Wine, Hotel, Italian, Cozy, Stylish, Casual, Classic, Polish, Global',
        annual_dol: 366.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.9704,
        total_orders: 1624.12,
        neighborhood: 'Lenox Hill',
        keywords:
            'Vodka, Rich, Beer, Elegant, Italian, Delicious, Romantic, Chinese, Creamy, Wholesome',
        annual_dol: 1658.58,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9683,
        total_orders: 163.31,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Steak, Salad, Korean, American, Irish, Caesar Salad, Impeccable, Cozy, Hotel',
        annual_dol: 472.21,
        description:
            '"Neighborhood gastropub, with a global perspective on food and drink and a visual esthetic that blends contemporary sports bar with Old World tavern. The menu present pub favorites and Eastern-European accented specialties, think the likes of sausages, schnitzel and goulash. Uncommonly extensive selections of flatbreads and burgers attract many fans. And Side Door\'s weekday happy hour program is one of the city\'s most generously timed. from 11:30 a.m. to 8 p.m."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7085,
        store_longitude: -74.0146,
        total_orders: 3066.06,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Wine, Dessert, Gourmet, Hawaiian, Tasty, Japanese, Whiskey, Upscale, Delicious',
        annual_dol: 5942.73,
        description:
            'Minimalist counter-service place offering gourmet poke bowls fusing Hawaiian & Japanese flavors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7447,
        store_longitude: -73.9877,
        total_orders: 315.47,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Tasty, Pizza, Salad, Italian, Wine, Delicious, Tequila, Elegant, Mediterranean, Classy',
        annual_dol: 385.2,
        description:
            'Rustic spot offering artisanal pasta made with prime olive oil & Italian-accented cocktails & wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7471,
        store_longitude: -73.9858,
        total_orders: 459.59,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Pizza, Salad, Beer, Wine, Upscale, Korean, Ale, Delicious, Karaoke, Bbq',
        annual_dol: 469.34,
        description:
            'Hopping sports-centric hangout with Korean fusion bar bites, multiple TVs, beer pong & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7536,
        store_longitude: -73.9945,
        total_orders: 437.15,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Wine, Trendy, Hotel, Spanish, Spacious, Vibrant, Bright, Sleek, Fun, Lively',
        annual_dol: 456.1,
        description:
            "Bright modern rooms sleep up to 8 guests, and have reclaimed wood floors, free Wi-Fi, flat-screen TVs and iPod docks. Some rooms have game consoles, bunk beds, or fitness rooms with workout equipment.An airy lobby bistro serves breakfast, tapas and cocktails. There's a 24-hour fitness center, along with 5 meeting rooms, including a seasonal rooftop terrace.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.987,
        total_orders: 1202.15,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Mexican, Korean, Karaoke, Beer, American, Eclectic, Asian, Fusion, Modern, Irish',
        annual_dol: 1254.27,
        description:
            'Buzzing, late-night lounge serving Korean fusion dishes, beer & cocktails in a club-like atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7419,
        store_longitude: -73.987,
        total_orders: 2201.25,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Vegan, Vegetarian, Wine, Gluten Free, American, Upscale, Contemporary, Perfect, Friendly, Local',
        annual_dol: 2637.22,
        description:
            'Upscale American tasting menus from chef Daniel Humm served in a high-ceilinged art deco space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6895,
        store_longitude: -73.9724,
        total_orders: 2258.28,
        neighborhood: 'Fort Greene',
        keywords:
            'Delicious, Warm, Cozy, Impeccable, Mediterranean, Amazing, Outstanding, Contemporary, Great, Israeli',
        annual_dol: 2632.31,
        description:
            'Chef-owned spot for contemporary Israeli & Mediterranean food & cocktails, with backyard seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 2578.25,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Spacious, Iconic, Classic, Great, Excellent, American',
        annual_dol: 2632.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6888,
        store_longitude: -73.993,
        total_orders: 1023.67,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Salad, Vegetarian, Thai, Dessert, Wine, Margarita, Tasty, Crab Rangoon, Beer, Organic',
        annual_dol: 1253.95,
        description:
            'Narrow, easygoing neighborhood standby dishing up Thai favorites from noodles to spring rolls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9946,
        total_orders: 381.61,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Peruvian, Salad, Wine, Juicy, Delicious, Latin, Homemade, Premium, Sangria, American',
        annual_dol: 389.7,
        description:
            'Casual Peruvian restaurant specializing in rotisserie chicken with homemade sauce.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7748,
        store_longitude: -73.9138,
        total_orders: 13519.15,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Burger, Vegan, Beer, Coffee, Wine, Upscale, Delicious, Warm, American, Cozy',
        annual_dol: 14388.88,
        description: 'Cozy brick-and-wood gastropub with cocktails & upscale pub fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7622,
        store_longitude: -73.9599,
        total_orders: 477.91,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Salad, Coffee, Beer, Wine, Irish, French, American, Bloody Mary',
        annual_dol: 488.05,
        description:
            '"Irregulars is a pub serving up the classics on the historic corner of 63rd and 1st."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7675,
        store_longitude: -73.9705,
        total_orders: 727.57,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Hotdog, Coffee, Italian, Dessert, American, Modern, Phenomenal, Authentic',
        annual_dol: 985.63,
        description:
            'Central Park eatery open during zoo hours with indoor & outdoor seating, plus light cafe fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6979,
        store_longitude: -73.927,
        total_orders: 171.77,
        neighborhood: 'Bushwick',
        keywords: 'Perfect, Jamaican, Great, Casual, Local',
        annual_dol: 702.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.9582,
        total_orders: 4288.76,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Coffee, Donut, Japanese, Ramen, Delicious, American, Sashimi, Contemporary, Perfect, Natural',
        annual_dol: 4884.6,
        description:
            'Casual, contemporary Izakaya-style Japanese restaurant featuring noodles, sake & craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8378,
        store_longitude: -73.7827,
        total_orders: 32442.83,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Wine, Beer, Hawaiian, Dessert, Ale, Pinacolada, American, Daiquiri',
        annual_dol: 37116.51,
        description:
            'Basic, long-running venue with outdoor seating serving fried & steamed seafood offerings. Cash only.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6791,
        store_longitude: -74.011,
        total_orders: 1670.59,
        neighborhood: 'Red Hook',
        keywords:
            'Tacos, Mexican, Salad, Vegetarian, Margarita, Gluten Free, Wine, Delicious, Spicy, Spanish',
        annual_dol: 1790.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.522,
        store_longitude: -74.2352,
        total_orders: 202.32,
        neighborhood: 'Charleston',
        keywords:
            'Mexican, Steak, Salad, Nachos, Gourmet, Chinese, Spanish, Delicious, American, Spacious',
        annual_dol: 300.06,
        description:
            'Snazzy cantina & lively nightspot serving Mexican fare & cocktails with dancing & a DJ Thurs-Sat.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -73.9906,
        total_orders: 3712.05,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Thai, Salad, Flavorful, Wine, Stylish, Coffee, Spicy, Chinese, Ale, Beer',
        annual_dol: 3790.82,
        description:
            'Traditional & unique Thai fare served in a stylish space with dark-wood accents & ornate fixtures.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6189,
        store_longitude: -74.0694,
        total_orders: 216.42,
        neighborhood: 'Rosebank',
        keywords:
            'Steak, Tacos, Tequila, Mexican, Gourmet, Wine, Burritos, Coffee, Turkish, Tex-Mex',
        annual_dol: 267.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8817,
        store_longitude: -73.8828,
        total_orders: 9.92,
        neighborhood: 'Kingsbridge Heights/Norwood',
        keywords:
            'Vegan, Vegetarian, Tacos, Mexican, Coffee, Tasty, French Toast, French, Casual, Latin',
        annual_dol: 40.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -73.8901,
        total_orders: 35.9,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Pizza, Tasty, Beer, Stylish, Cozy, Vietnamese, Irish, Latin, Perfect, Great',
        annual_dol: 108.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9775,
        total_orders: 4060.84,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Coffee, Dessert, Spicy, Avocado Toast, Warm, Organic, Caesar Salad',
        annual_dol: 22570.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.7601,
        total_orders: 18.49,
        neighborhood: 'Bayside',
        keywords: 'Hotel, Coffee, Modern, Fresh',
        annual_dol: 75.58,
        description:
            "closest bus stop is a minute's walk from the hotel, and LaGuardia Airport is 8.6 miles away.Warm, modern rooms have free WiFi, plus coffeemakers, minifridges and flat-screen TVs. Studios add pull-out sofas. Room service is available (limited hours).Freebies include continental breakfast, parking, and all-day tea and coffee in the lobby. An on-site restaurant with a Spanish-influenced menu serves 3 meals a day. There's also a ballroom and 5 meeting rooms.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.9685,
        total_orders: 104.54,
        neighborhood: 'Lenox Hill',
        keywords:
            'Chocolate Cake, Tasty, Wine, Italian, Delicious, Elegant, Sustainable, Mediterranean, Healthy, Fun',
        annual_dol: 116.4,
        description:
            "Seasonal Mediterranean fare that's locally sourced served in a sleek space near Bloomie's.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6905,
        store_longitude: -73.9833,
        total_orders: 7461.89,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Beer, Burger, Milkshake, Gin, Thai, Stylish, Phenomenal, Classic, Creative, Local',
        annual_dol: 7785.42,
        description:
            'Stylish theater chain for new & classic films features cocktails & creative bites served seat-side.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.781,
        store_longitude: -73.9489,
        total_orders: 1839.8,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Wine, Beer, Ale, Irish, Inviting, Intimate, Friendly, Fun, Homey, Great',
        annual_dol: 1903.7,
        description:
            'Tiny, inviting Irish hideaway lures locals with Guinness, happy-hour deals & darts & trivia nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9869,
        total_orders: 462.91,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Burger, Salad, Coffee, Pizza, Delicious, Meatball, Quaint, Healthy, French, Mediterranean',
        annual_dol: 989.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7864,
        store_longitude: -73.7294,
        total_orders: 460.75,
        neighborhood: '',
        keywords: 'Wine, Hotel, Italian, Amazing, Great, Fancy, Beer, Comfortable, Asian, Fusion',
        annual_dol: 491.02,
        description:
            'miles from LaGuardia Airport.The plush, airy rooms and suites with 1920s accents feature room service and free WiFi, plus minibars, flat-screen TVs and coffeemakers; some also have kitchenettes, balconies and/or Jacuzzis.Amenities include an exercise room (and passes to a nearby full-service gym) and an outdoor patio and garden. An on-site French-American restaurant, bar and lounge with an extensive wine list serves 3 meals and Sunday brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7778,
        store_longitude: -73.9489,
        total_orders: 13.89,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegetarian, Vegan, Salad, Mexican, Nachos, Wine, Gluten Free, Chinese, Trendy, Casual',
        annual_dol: 56.79,
        description:
            'Warm, brick-walled local eatery offering Mexican favorites plus happy hours & margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.985,
        total_orders: 402.21,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Lobster, Burger, Hotel, Modern, Great',
        annual_dol: 1411.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.9928,
        total_orders: 857.78,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Delicious, Dessert, Upscale, Greek, Spectacular, Superb, Wonderful, Fresh, Great',
        annual_dol: 955.79,
        description:
            'Upscale Greek seafood specialist with a pre-theater set menu appealing to Carnegie Hall-goers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.708,
        store_longitude: -74.0146,
        total_orders: 1598.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Beer, Vodka, Salad, Gourmet, German, Caesar Salad, Irish, American, Hip',
        annual_dol: 1782.61,
        description:
            'Hip beer hall & courtyard garden with numerous crafts on tap, creative gastropub food & Ping-Pong.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.9871,
        total_orders: 7669.67,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Korean, Spicy, Delicious, Wine, Trendy, Phenomenal, Spanish, Innovative, Bbq, Classy',
        annual_dol: 10104.21,
        description:
            'Hip setting for chef-driven Korean fusion plates with signature cocktails & a wine corkage fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7502,
        store_longitude: -73.9863,
        total_orders: 6101.59,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Wine, Beer, Japanese, Sashimi, Zen, Delicious, Moscow Mule, Ramen, Asian',
        annual_dol: 7020.63,
        description:
            'Late-night izakaya for soba salad, sushi, yakitori & other Japanese fare in a spartan-looking space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.9854,
        total_orders: 1186.32,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Hamburger, Beer, Wine, Irish, Legendary, Bbq, Thai, Spectacular, Iconic, Traditional',
        annual_dol: 1252.13,
        description:
            'Soccer fans converge at this popular sports bar housing TVs, easy pub grub & cocktails, beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.745,
        store_longitude: -73.9886,
        total_orders: 8243.06,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Rich, Hotel, Rooftop, Spacious, Stylish, American, Classic, Great, Fresh, Comfortable',
        annual_dol: 16706.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9951,
        total_orders: 8070.36,
        neighborhood: 'Nolita',
        keywords: 'Salad, Beer, Spicy, Quaint, Creamy, Perfect, Fun, Bright, Casual, American',
        annual_dol: 16861.56,
        description:
            'Bespoke cocktails, bar snacks & booze-filled slushies keep this neighborhood lounge bustling.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6482,
        store_longitude: -73.7801,
        total_orders: 1750.98,
        neighborhood: 'Jamaica',
        keywords:
            'Burger, Steak, Hamburger, Pizza, Salad, Donut, Beer, Wine, Dessert, Buffalo Chicken Salad',
        annual_dol: 7157.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -73.9888,
        total_orders: 5492.38,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Tasty, Spicy, Trendy, Wine, Savory, Hokkien Mee, Asian, Vibrant, Colorful, Fun',
        annual_dol: 7436.38,
        description:
            'Singaporean street food takes center stage in a polished setting with colorful accents.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7353,
        store_longitude: -73.9883,
        total_orders: 14040.07,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Steak, Pizza, Champagne, Mimosa, Irish, Venezuelan, Bellini, Delicious, American, Daiquiri',
        annual_dol: 17153.71,
        description:
            'Basement comedy venue plus upstairs restaurant serving dressed-up American comfort fare & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6053,
        store_longitude: -73.9828,
        total_orders: 19.83,
        neighborhood: 'Gravesend',
        keywords: 'Beer, Local, Friendly, Casual, Adventurous',
        annual_dol: 57.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7374,
        store_longitude: -73.9877,
        total_orders: 55.57,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Steak, Vegetarian, Pizza, Mexican, Beer, Tasty, Delicious, Wine, American',
        annual_dol: 227.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7274,
        store_longitude: -73.856,
        total_orders: 43.84,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Mexican, Tequila, Steak, Enchiladas, American, Latin, Delicious, Authentic, Classic, Friendly',
        annual_dol: 179.2,
        description: '"Lime & Salt Bar Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5867,
        store_longitude: -73.9291,
        total_orders: 2922.63,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Burger, Beer, Whiskey, Salad, American, Fun, Iconic, Classic, Fantastic, Great',
        annual_dol: 2984.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8872,
        store_longitude: -73.9073,
        total_orders: 2197.95,
        neighborhood: 'Riverdale/West Bronx',
        keywords: 'Burger, Vegetarian, Tacos, Salad, Beer, Dessert, Bbq, Ale, Chicken Wings, Swiss',
        annual_dol: 2248.07,
        description:
            'Pub favorites, draft brews & cocktails served in a wood-&-brick space with sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7354,
        store_longitude: -73.9968,
        total_orders: 99.11,
        neighborhood: '',
        keywords:
            'Vegan, Coffee, Cosmopolitan, Hotel, American, Perfect, Contemporary, Cool, Iconic, Great',
        annual_dol: 405.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9982,
        total_orders: 1266,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Wine, Beer, Ale, Irish, Fun, Eclectic, Vintage, Friendly, Classic, Fantastic',
        annual_dol: 1292.86,
        description:
            'Neighborhood bar with no-nonsense attitude featuring vintage photos & records on the wall.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7494,
        store_longitude: -73.9957,
        total_orders: 1011.95,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Pizza, Beer, Ale, Irish, Upscale, Great, Casual, Attentive',
        annual_dol: 1079.22,
        description:
            'Small, low-key neighborhood joint with draft beer & bar snacks, plus darts, a pool table & jukebox.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.9934,
        total_orders: 505.43,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Intimate, Classy, Mediterranean, Fun, Classic, Casual, Great, Lit, Unique',
        annual_dol: 917.4,
        description:
            'Intimate, dimly lit haunt with velvet seats offering cocktails & tapas in a laid-back atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7067,
        store_longitude: -74.0128,
        total_orders: 257.16,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Chinese, American, Hotel, Crab Rangoon, Casual, Authentic, Lit, Bright, Exquisite, Dim Sum',
        annual_dol: 902.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9859,
        total_orders: 3365.27,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Wine, Vodka, Hotel, Legendary, Rum, Classic, Russian, Cozy, Impressive, Fantastic',
        annual_dol: 3436.68,
        description:
            'This swanky, candlelit, wood-paneled piano bar draws the well-heeled for classic cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6762,
        store_longitude: -73.9495,
        total_orders: 1517.55,
        neighborhood: 'Crown Heights',
        keywords:
            'Beer, Delicious, Stylish, Wine, Painkiller, Jamaican, Rum, Friendly, Wonderful, Great',
        annual_dol: 1745.34,
        description:
            'Snug neighborhood bar offering a range of elevated tropical cocktails & draft brews in stylish digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.9886,
        total_orders: 5683.58,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Upscale, Great, Creative, Unforgettable, Signature',
        annual_dol: 5842.9,
        description:
            "the Empire State Building.Warm rooms feature custom-designed beds, minibars and Wi-Fi (fee), plus 47-inch flat-screen TVs, iPod docks and coffeemakers; some offer skyline views. Suites add separate living areas and pull-out sofas. An upgraded suite has a dining area, a dishwasher and a Bose home-theater system. Room service is available 24/7.There's an upscale restaurant and a laid-back lounge serving light snacks, plus a 24-hour fitness room.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9851,
        total_orders: 1062.63,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Cheesecake, Salad, Bread Pudding, Shrimp Cocktail, Beer, Irish, American, Casual, Fancy',
        annual_dol: 1108.7,
        description:
            'Mellow tavern showcasing Irish & American eats plus beer & live music in a warm, casual atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9808,
        total_orders: 45.14,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Italian, Pizza, Beer, Delicious, Authentic, Traditional, Casual, Local, Creative, Fine',
        annual_dol: 184.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7581,
        store_longitude: -73.9882,
        total_orders: 34.53,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Chinese, Brazilian, Comfortable, Classic, Modern, Superb, Local, Exceptional, Casual, Great',
        annual_dol: 150.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.9916,
        total_orders: 145.26,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Wine, Italian, Trendy, Delicious, Beer, Bruschetta, Friendly, Fun, Modern',
        annual_dol: 593.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.883,
        total_orders: 1272.68,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Peruvian, Vodka, Wine, Beer, French, Latin, Spanish, Fusion, American, Sangria',
        annual_dol: 1356.31,
        description:
            'Bar serving piscos & Peruvian tapas, including ceviches, empanadas & tamales, in industrial digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7581,
        store_longitude: -73.7874,
        total_orders: 45.52,
        neighborhood: 'Auburndale/Flushing',
        keywords:
            'Vegetarian, Mexican, Salad, Coffee, Margarita, Wine, Quesadillas, Colombian, Casual, American',
        annual_dol: 51.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6781,
        store_longitude: -74.0118,
        total_orders: 1580.02,
        neighborhood: 'Red Hook',
        keywords:
            'Burger, Lobster, Salad, Pizza, Shrimp Cocktail, Beer, Wine, French Onion Soup, French, American',
        annual_dol: 1887.42,
        description:
            'Classic American plates inspired by iconic NYC haunts are served in a warm, wood-accented space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9513,
        total_orders: 865.51,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Hotdog, Peruvian, Seafood Paella, French, Latin, American, Intimate, Elegant, Traditional, Authentic',
        annual_dol: 922.39,
        description:
            'This eatery & cocktail spot with elegant decor specializes in Peruvian dishes & pisco cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5957,
        store_longitude: -73.9736,
        total_orders: 531.24,
        neighborhood: 'Gravesend',
        keywords:
            'Shrimp Cocktail, Wine, Italian, Delicious, French, Nachos, Premium, Southern, American, Casual',
        annual_dol: 620.63,
        description:
            'Cave-themed restaurant/hookah lounge for Italian-American eats & wines, plus cocktails & desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6457,
        store_longitude: -73.9729,
        total_orders: 110.56,
        neighborhood: 'Kensington',
        keywords:
            'Salad, Meatball, Moroccan, Savory, Delicious, Mediterranean, Premium, Friendly, Casual, Great',
        annual_dol: 388.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.8805,
        total_orders: 13.98,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords: 'Pizza, Bbq, Bruschetta, Intimate, Fresh, Great, Local',
        annual_dol: 57.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7541,
        store_longitude: -73.9773,
        total_orders: 2093.6,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Wine, Gluten Free, Organic, Mediterranean, Greek, Premium, Exquisite, Lively, Memorable',
        annual_dol: 2138.02,
        description:
            'Greek seafood & a deep wine list headline at this airy spot with sandstone pillars.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 1889.26,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Pizza, Vodka, Italian, Beer, Salad, Meatball, Gin, Rustic, Rum, American',
        annual_dol: 2015.67,
        description:
            'Artisanal pizzas & Italian fare, offered in chic digs for dine-in with cocktails or quick take-out.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6951,
        store_longitude: -73.8418,
        total_orders: 120.85,
        neighborhood: 'Richmond Hill',
        keywords: 'Burger, Fun, Premium, Cool, American, Amazing, Great, Local, Casual, Gyro',
        annual_dol: 126.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7676,
        store_longitude: -73.78,
        total_orders: 11.39,
        neighborhood: 'Bayside/Flushing',
        keywords: 'Tequila Sunrise, Tequila, Greek, Irish, Casual',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9653,
        total_orders: 2261.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Beer, Whiskey, Irish, Delicious, Authentic, Creative, Great, Casual',
        annual_dol: 2366.34,
        description:
            'Retro-hip tavern serving Irish beer, whiskey & cocktails along with hearty pub grub & happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9751,
        total_orders: 3171.15,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hotel, Upscale, Stylish, Elegant, Martini, Spacious, Warm, Premium, Sleek, Mediterranean',
        annual_dol: 3268.48,
        description:
            "Elegant rooms come with marble bathrooms, flat-screens and Wi-Fi (fee). Suites add audio systems; some also have pull-out sofas, living rooms and/or soaking tubs. Upgraded suites have terraces with city views. In-room fitness kits and massages are available, as is 24-hour room service.Amenities include a casual grill restaurant and a bar with city views. There's also a 24-hour gym, a business center and event space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.995,
        total_orders: 815.28,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Hamburger, Salad, Shrimp Cocktail, Chilean, Italian, Shrimp Scampi, Bbq, Caesar Salad, American',
        annual_dol: 854.41,
        description:
            'Unpretentious neighborhood steak & seafood restaurant with a vibe that blends modern & traditional.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -73.9836,
        total_orders: 2072.04,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Steak, Cheesesteak, Beer, Bbq, Spicy, Nachos, Irish, Ale, Philly Cheesesteak, Gumbo',
        annual_dol: 2194.38,
        description:
            'Old-school (since 1886) Irish pub with a fireplace featuring Guinness & other drafts, plus pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6896,
        store_longitude: -73.9553,
        total_orders: 267.58,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Burger, Rich, Wine, Fancy, Delicious, Classy, French, American, Korean, Retro',
        annual_dol: 546.14,
        description:
            'Retro hangout serving American comfort eats & signature cocktails in a cheery space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9948,
        total_orders: 915.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Lobster, Beer, Gluten Free, Dessert, Mediterranean, Moroccan, Spicy, Warm, Organic',
        annual_dol: 955.59,
        description:
            'Mediterranean small plates & Moroccan-inspired entrees in a warm space with a bar & sidewalk tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7709,
        store_longitude: -73.9813,
        total_orders: 5027.27,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Cheesecake, Hamburger, Tomato Soup, Salad, Chicken Pot Pie, Shrimp Cocktail, Beer, Whiskey, Wine',
        annual_dol: 5173.39,
        description:
            'Burgers & other American comfort food in down-to-earth digs with a lively atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7742,
        store_longitude: -73.9129,
        total_orders: 996.28,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Burger, Hamburger, Vegetarian, Beer, Spicy, Organic, Delicious, Sustainable, American, Clean',
        annual_dol: 1318.95,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.61,
        store_longitude: -73.9218,
        total_orders: 641.32,
        neighborhood: 'Mill Basin',
        keywords: 'Mexican, Tacos, Burritos, Coffee, Local, Casual, Healthy, Premium, Cool',
        annual_dol: 2251.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6568,
        store_longitude: -73.7003,
        total_orders: 91.05,
        neighborhood: '',
        keywords:
            'Steak, Sushi, Japanese, Sashimi, Legendary, Delicious, Perfect, Casual, Omakase, Fun',
        annual_dol: 372.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7348,
        store_longitude: -74.0029,
        total_orders: 736.27,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Garlic Bread, Wine, Spanish, Margarita, Champagne, Dessert, Martini, Seafood Paella, Gourmet',
        annual_dol: 753.32,
        description:
            'Seafood paella plus sangria & garlic bread are delivered by courtly waiters at this Village standby.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7342,
        store_longitude: -74.0066,
        total_orders: 1547.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Salad, Beer, Ale, Bourbon, Gin, Mimosa, Irish, French, Prosecco',
        annual_dol: 1580.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7374,
        store_longitude: -74.0041,
        total_orders: 1108.87,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Shrimp Cocktail, Gluten Free, Wine, Upscale, French, French Onion Soup, Savory, Avocado Toast, Cozy',
        annual_dol: 1132.4,
        description:
            'Bright neighborhood bistro whose Franco-American offerings include weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8588,
        store_longitude: -73.6466,
        total_orders: 12.15,
        neighborhood: '',
        keywords: 'Elegant, Luxurious, Sweet, Fresh, Casual',
        annual_dol: 49.68,
        description:
            '"Eastern Star Cruises is now conveniently located in Glen Cove, NY. If you are planning a corporate event, wedding, rehearsal dinner, surprise party, sweet sixteen, or any other special occasion, let us take your event up a notch. We can help make your party unique. Call us to find out how!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6103,
        store_longitude: -73.9627,
        total_orders: 50.65,
        neighborhood: 'Midwood',
        keywords: 'Steak, Salad, Wine, Tasty, Flavorful, Peruvian, Dessert, Beer, Bbq, French',
        annual_dol: 207.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7844,
        store_longitude: -73.9739,
        total_orders: 691.66,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegan, Gluten Free, Wine, Organic, Trendy, Sustainable, Italian, Vietnamese, Rustic, Cozy',
        annual_dol: 706.34,
        description:
            'Health-minded meals featuring veggies, fish & fowl to suit everyone from vegans to carnivores.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6397,
        store_longitude: -73.6073,
        total_orders: 24.3,
        neighborhood: '',
        keywords:
            'Jamaican, Caribbean, French, Dutch, Fancy, Sweet, Local, Authentic, Western, Casual',
        annual_dol: 99.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6901,
        store_longitude: -73.8144,
        total_orders: 1.8,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords: 'Delicious, Relaxed, Casual, Indian, Caribbean, Spectacular',
        annual_dol: 7.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7087,
        store_longitude: -73.8631,
        total_orders: 258.28,
        neighborhood: 'Flushing/Glendale',
        keywords: 'Burger, Pizza, Coffee, Wine, Italian, Dessert, Margarita, Cozy, Modern, Premium',
        annual_dol: 879.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7149,
        store_longitude: -74.0161,
        total_orders: 5828.7,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Tacos, Mexican, Tequila, Margarita, Nachos, Quesadillas, Enchiladas, Korean, Bbq, American',
        annual_dol: 6285.9,
        description:
            'Modern takes on Mexican favorites served in a flashy space with vibrant colors & a lively bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7653,
        store_longitude: -73.9719,
        total_orders: 8989.71,
        neighborhood: 'Lenox Hill',
        keywords:
            'Chocolate Cake, Salad, Hotel, Coffee, Rich, Elegant, Warm, French Onion Soup, Vintage, Turkish',
        annual_dol: 9185.42,
        description:
            "The elegant rooms feature free Wi-Fi and Turkish marble bathrooms, as well as Bose sound systems, flat-screen TVs and designer toiletries; some have views of the park. Suites include living rooms and soaking tubs; some have city views. Upgraded suites offer personal butler service.Amenities include a fine-dining American restaurant displaying contemporary artwork, and 2 lounges (1 with frequent live jazz) serving afternoon tea, light meals and evening cocktails. There's also a business center and a fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6342,
        store_longitude: -74.1357,
        total_orders: 62.19,
        neighborhood: 'Port Richmond',
        keywords: 'Italian, Pizza, Excellent, Casual',
        annual_dol: 122.25,
        description: '"Italian Restaurant and Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7002,
        store_longitude: -73.9294,
        total_orders: 911.74,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Delicious, Funky, Awesome, Lively, Great, Classic, Dope, Fine',
        annual_dol: 1035.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.9454,
        total_orders: 391.54,
        neighborhood: 'Williamsburg',
        keywords: 'Tasty, Pizza, Delicious, Wine, Beer, Yummy, Clean, Friendly, Awesome, Relaxed',
        annual_dol: 1600.5,
        description:
            '"A year-round tropical escape in Brooklyn! Enjoy tasty eats & delicious drinks, inspired by exotic flavors that trace the equator! Open lunchtime til late-night, every night!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6578,
        store_longitude: -73.9794,
        total_orders: 108.81,
        neighborhood: 'Windsor Terrace',
        keywords:
            'Vodka, Steak, Beer, Tasty, Delicious, Nachos, American, Baked Potato, Friendly, Outstanding',
        annual_dol: 183.65,
        description:
            '"Rhythm & Booze Sports Bar and Grill is a casual dining destination conveniently located in the heart of Windsor Terrace Brooklyn. We offer delicious lunch, dinner and weekend brunch menus which includes daily specials. We are a sports bar restaurant that strives to make your every visit better than the last. Our pledge is to provide outstanding food along with excellent service at an unbeatable value. Rhythm & Booze features include a fully stocked bar, internet jukebox, free Wi-Fi and 14 HD TV\'s with all the hottest sports packages that will definitely satisfy your sports cravings. If your in the neighborhood stop in for a bite to eat or a cold beverage. No reservation needed, walk-ins are always welcome. We hope to see you soon!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.675,
        store_longitude: -73.9771,
        total_orders: 250.94,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Italian, Casual, Authentic',
        annual_dol: 565.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6973,
        store_longitude: -73.9347,
        total_orders: 133.4,
        neighborhood: 'Bushwick',
        keywords:
            'Steak, Burger, Vegan, Beer, American, Jamaican, Natural, Casual, Fantastic, Great',
        annual_dol: 545.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6402,
        store_longitude: -73.6627,
        total_orders: 203.05,
        neighborhood: '',
        keywords: 'Lobster, Sushi, Pizza, Mexican, Beer, Wine, Spicy, American, Classy, Amazing',
        annual_dol: 711.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5797,
        store_longitude: -73.8373,
        total_orders: 20.75,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords:
            'Burger, Lobster, Steak, Sushi, Salad, Shrimp Scampi, Beer, Wine, Coffee, French Toast',
        annual_dol: 84.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7229,
        store_longitude: -74.0061,
        total_orders: 10593.31,
        neighborhood: 'Hudson Square',
        keywords:
            'Mexican, Tasty, Modern, Romantic, Perfect, Friendly, Cool, Casual, Exotic, Fabulous',
        annual_dol: 37355.3,
        description:
            'Tulum-inspired tropical jungle setting for craft cocktails & seasonal fare, with seating for 400+.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6597,
        store_longitude: -73.9954,
        total_orders: 375.69,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Tacos, Mexican, Beer, Fun, Authentic, Cool, Great, Casual, Signature',
        annual_dol: 1210.69,
        description:
            'Tacos, tostadas & chips, plus beer & spirits presented in an outdoor open space with picnic tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.554,
        total_orders: 95.4,
        neighborhood: '',
        keywords:
            'Salad, Italian, Hotel, Homemade, Irresistible, Iconic, Classic, Fresh, Excellent, Casual',
        annual_dol: 97.43,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7193,
        store_longitude: -74.0053,
        total_orders: 109.71,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Wine, Salad, Vodka, Beer, Dessert, Ale, Italian, Delicious, Organic',
        annual_dol: 448.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7902,
        store_longitude: -73.9429,
        total_orders: 73.72,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Mexican, Shrimp Cocktail, South American, Trendy, Spicy, American, Casual, Fresh, Authentic',
        annual_dol: 301.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7479,
        store_longitude: -73.5527,
        total_orders: 137.31,
        neighborhood: '',
        keywords:
            'Lasagna, Italian, Shrimp Cocktail, Pizza, Wine, Beer, Classic, Fresh, Amazing, Seasonal',
        annual_dol: 224.14,
        description:
            "Veteran neighborhood Italian eatery that's family-run & located in modest strip-mall digs.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.5739,
        total_orders: 1209.56,
        neighborhood: '',
        keywords: 'Salad, Italian, Warm, Southern, Casual, Vibrant, Classic, Excellent',
        annual_dol: 1470.39,
        description:
            'Easygoing destination with a warm vibe offering hearty servings of old-school Italian fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7723,
        store_longitude: -73.956,
        total_orders: 116.96,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Wine, Salad, Italian, Upscale, Thai, Delicious, Beer, Cozy, Romantic, Asian',
        annual_dol: 232.66,
        description:
            'A large selection of by-the-glass wines & Italian small plates makes this a popular spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -74.0027,
        total_orders: 1046.84,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Ale, Coffee, Beer, Wine, Burger, Salad, French Onion Soup, American, Rum, Tangy',
        annual_dol: 1069.05,
        description:
            'Popular tavern in 19th-century carriage house offers craft beers, pub grub & extended happy hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7283,
        store_longitude: -74.0052,
        total_orders: 180.5,
        neighborhood: 'Hudson Square',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Casual',
        annual_dol: 799.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9833,
        total_orders: 48.11,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Lobster, Pizza, Salad, Wine, Beer, Latin, American, Fun, Casual, Awesome',
        annual_dol: 196.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8424,
        store_longitude: -73.7077,
        total_orders: 2.56,
        neighborhood: '',
        keywords:
            'Steak, Kebab, Cheesesteak, Meatball, Turkish, Mediterranean, Gyro, Excellent, Casual, Falafel',
        annual_dol: 10.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.751,
        store_longitude: -73.978,
        total_orders: 14.06,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'French Toast, Coffee, French, New-York-Style, Rooftop, Perfect, Impressive, Incredible, Fine, Casual',
        annual_dol: 57.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6385,
        store_longitude: -74.0178,
        total_orders: 144.98,
        neighborhood: 'Sunset Park',
        keywords: 'Beer, Wine, Fun, Adventurous, Irish, Friendly, German, Awesome, Great, Casual',
        annual_dol: 169.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7079,
        store_longitude: -73.6755,
        total_orders: 69.46,
        neighborhood: '',
        keywords: 'Burger, Tacos, Sushi, Pizza, Delicious, Caribbean, Creole, Perfect, Authentic',
        annual_dol: 72.47,
        description:
            '"Catering available 7 days. Authentic Haitian Cuisine. Full Bar. Dine in, take out, delivery apps."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7692,
        store_longitude: -73.9649,
        total_orders: 3427.31,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Elegant',
        annual_dol: 3562.5,
        description:
            'The Union Club of the City of New York is a private social club in New York City that was founded in 1836. The clubhouse is located at 101 East 69th Street on the corner of Park Avenue, in a landmark building designed by Delano & Aldrich that opened on August 28, 1933.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6851,
        store_longitude: -73.9297,
        total_orders: 3098.11,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Friendly, Casual, Cool, Natural, Fun, Hip, American, Great, Chill',
        annual_dol: 3163.85,
        description: '"Dive Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7669,
        store_longitude: -73.9124,
        total_orders: 33.15,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Irish, Tasty, Wine, Karaoke, Vibrant, Fabulous, Wonderful, Authentic, Friendly, Great',
        annual_dol: 135.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.988,
        total_orders: 141.49,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lobster, Pizza, Beer, Italian, Mexican, Wine, Whiskey, Scotch, Southern, Asian',
        annual_dol: 578.34,
        description:
            'Tiny option serving up Italian wines paired with small bites in a brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.9742,
        total_orders: 389.4,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Steak, Burger, Pizza, Italian, Scotch, Hotel, Upscale, American, Champagne, Rum',
        annual_dol: 1591.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7194,
        store_longitude: -73.9851,
        total_orders: 1294.92,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Tasty, Spicy, Warm, Cozy, Intimate, Wonderful, Great, Excellent, Lit',
        annual_dol: 1322.39,
        description:
            'A hidden door downstairs opens to this 1920s-style speakeasy with creative cocktails in luxe decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.721,
        store_longitude: -73.9877,
        total_orders: 2354.61,
        neighborhood: 'Lower East Side',
        keywords: 'Burger, Wine, Beer, French, Homemade, Classy, Nachos, Clean, Casual, Sweet',
        annual_dol: 2422.65,
        description:
            'Hipsters pack this 2-level bar/music venue to hear live indie acts or dance to DJ sets upstairs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6922,
        store_longitude: -73.8629,
        total_orders: 306.95,
        neighborhood: 'Woodhaven',
        keywords: 'Irish, Comfortable, Casual, Local',
        annual_dol: 328.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8653,
        store_longitude: -73.9269,
        total_orders: 15.61,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Tequila Sunrise, Tequila, Shrimp Cocktail, Steak, Dominican, Caribbean, American, Latin, Premium, Traditional',
        annual_dol: 63.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7112,
        store_longitude: -74.0146,
        total_orders: 66.78,
        neighborhood: 'Lower Manhattan',
        keywords: 'Steak, Burger, Canadian, Wine, Salad, Gourmet, Pizza, Dessert, Italian, Rich',
        annual_dol: 272.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7772,
        store_longitude: -73.7936,
        total_orders: 345.67,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Pizza, Salad, Italian, Chicken Parmigiana, Meatball, Bruschetta, Relaxed, Casual, Friendly, Local',
        annual_dol: 358.24,
        description:
            'Longtime Italian restaurant offering classic pizzas & pastas, plus a full bar, in a relaxed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9944,
        total_orders: 709.44,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Sushi, Japanese, Asian, Casual, Omakase',
        annual_dol: 2899.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7066,
        store_longitude: -74.0028,
        total_orders: 5223.57,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Pizza, Salad, Mexican, Margarita, Italian, Chinese, French, American, Seasonal, Casual',
        annual_dol: 21420.99,
        description:
            'Acclaimed food hall with restaurants including Chinese, seafood, French & vegan, plus a market.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7128,
        store_longitude: -73.9443,
        total_orders: 2170.77,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Steak, Vegan, Tequila, Margarita, Green Curry, Thai, Beer, Spicy, Trendy',
        annual_dol: 2216.83,
        description:
            'Cozy Thai standby with a big menu of traditional & new dishes, plus a full bar & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7174,
        store_longitude: -73.958,
        total_orders: 316.78,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Coffee, Wine, Hip, Casual, Local, Signature, Fine',
        annual_dol: 420.4,
        description:
            'Bustling Mexican restaurant well-located on Bedford with margs & eats in a hip space with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6374,
        store_longitude: -74.0759,
        total_orders: 583.32,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords:
            'Steak, Burger, Salad, Cheesesteak, Bbq, Beer, Tex-Mex, French, American, Southern',
        annual_dol: 3019.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6032,
        store_longitude: -74.1626,
        total_orders: 150.79,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Burger, Japanese, Italian, Gluten Free, Chinese, Ramen, Thai, Asian, British, Classic',
        annual_dol: 157.33,
        description:
            'Comfortable, roomy option featuring Chinese & Japanese fare, including sushi, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.733,
        store_longitude: -73.9809,
        total_orders: 69.61,
        neighborhood: 'Midtown Manhattan/Stuyvesant Town-Peter Cooper Village',
        keywords: 'Mexican, Tacos, Burritos, Hotel, Western, Organic, Local, Casual, Delicious',
        annual_dol: 284.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.9813,
        total_orders: 1165.05,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Burger, Pizza, Vegetarian, Tomato Soup, Beer, Gluten Free, Wine, Nachos, Martini, Irish',
        annual_dol: 1177.55,
        description:
            'Andy Warhol-themed haunt serving cocktails, eats & brunch in a convivial ambiance w/outside tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9759,
        total_orders: 20.69,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Pizza, Garlic Bread, Salad, Beer, Bbq, Wine, American, Bruschetta, Fresh, Local',
        annual_dol: 84.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -74.0082,
        total_orders: 8178.26,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Rooftop, Meatball, Hotel, Spectacular, Salad, Beer, Dessert, American, Trendy, Innovative',
        annual_dol: 8919.3,
        description:
            '"Tribeca360°is a newly built 30,000sqf loft-like venue space located in the trendy downtown neighborhood of Tribeca, New York City. 360°’s modern and minimalist interior boasts over-sized windows that offer spectacular views of the city and create a priceless backdrop for your event. Our innovative use of lighting and unique walls can be easily adapted to create a personalized atmosphere."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.792,
        store_longitude: -73.9526,
        total_orders: 1788.33,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Italian, Legendary, Impeccable, Exquisite, Superb, Cultural',
        annual_dol: 2039.89,
        description:
            '"Exquisite food, impeccable service and unparalleled attention to detail create the foundation of why Sterling Affair is the Tri-States preeminent catering & event specialists."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8108,
        store_longitude: -73.6285,
        total_orders: 531.34,
        neighborhood: '',
        keywords:
            'Wine, Beer, Coffee, Delicious, Fabulous, Swiss, Homemade, Perfect, American, Classic',
        annual_dol: 592.5,
        description:
            'Landmark pub offering draft beer & American fare with TVs & classic brick- & wood-accented decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7773,
        store_longitude: -73.957,
        total_orders: 122.12,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Pizza, Salad, Italian, Meatball, Beer, Gin, Cozy, Quaint, Homemade, Caesar Salad',
        annual_dol: 132.9,
        description:
            '"At L’Osteria, we strive to constantly come up with plates that will surprise and excite your taste buds. Our dishes are inspired by the beauty of Italy, but also stand-out as something all their own. With the changing of the seasons, our menu will change to match what is at the peak for bringing you the freshest ingredients. Plus, our homemade pasta is unlike anything you’ve had before. Our commitment is to providing the very best food, in a cozy environment. This is the neighborhood spot you’ve been waiting for, to enjoy quality drinks and incredible food with the people you love. Be sure to stop in and see what we’re cooking up each night, as the daily specials are sure to offer something new every time you visit."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7844,
        store_longitude: -73.8456,
        total_orders: 24.38,
        neighborhood: 'College Point/Flushing',
        keywords:
            'Burger, Sushi, Beer, Cuban, Spanish, Dominican, Caribbean, Latin, American, Sweet',
        annual_dol: 99.66,
        description:
            '"Mr dre Kitchen Bar, We serve all kinds of alcoholic beverages, sushi and fusion food. If you are looking for an environment where you can release stress, You Just Found the Right Place."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.6644,
        total_orders: 2904.65,
        neighborhood: '',
        keywords: 'Golfclub, Elegant, Delicious, Sophisticated, Casual, Great',
        annual_dol: 3112.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -73.6352,
        total_orders: 329.12,
        neighborhood: '',
        keywords:
            'Sushi, Steak, Mexican, Beer, Japanese, Italian, Contemporary, Teriyaki, Cozy, Premium',
        annual_dol: 440.61,
        description:
            'Typical Japanese eats such as teriyaki & sushi served amid contemporary decor with splashy lighting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7763,
        store_longitude: -73.9556,
        total_orders: 1783.99,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Hamburger, Salad, Nachos, Bbq, Irish, Buffalo Chicken Salad, Mediterranean, French, Caesar Salad',
        annual_dol: 1851.34,
        description:
            'Homey, local bar with an Irish slant, sports on TV & a comfort-food menu headlined by hot wings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7747,
        store_longitude: -73.9635,
        total_orders: 639.59,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Sushi, Tacos, Tasty, Japanese, Beer, Spicy, Sashimi, Peking Duck, New Zealand',
        annual_dol: 653.16,
        description:
            'Subterranean fine-dining restaurant by acclaimed chef Masa Takayama for sushi & Japanese fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8189,
        store_longitude: -73.928,
        total_orders: 3593.47,
        neighborhood: 'West Bronx',
        keywords: 'Steak, Burger, Salad, Nachos, Beer, American, Casual, Traditional, Great',
        annual_dol: 3743.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7343,
        store_longitude: -74.0064,
        total_orders: 47.79,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Chinese, Hotel, Southern, Fun, Incredible, Classic',
        annual_dol: 195.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6334,
        store_longitude: -74.0094,
        total_orders: 365.43,
        neighborhood: 'Sunset Park',
        keywords:
            'Tequila, Whiskey, Scandinavian, Danish, Bourbon, Margarita, Bbq, American, Friendly, Excellent',
        annual_dol: 373.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7674,
        store_longitude: -73.9212,
        total_orders: 5672.2,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Irish, Whiskey, Beer, Karaoke, Lively, Diverse, Impressive, Great, Local, Casual',
        annual_dol: 6583.31,
        description:
            'Down-to-earth tavern offering pints of beer, shots, live music & an outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.7026,
        total_orders: 63.26,
        neighborhood: 'Floral Park/Bellerose Manor',
        keywords: 'Vietnamese, Salad, Upscale, Asian, Casual, Local, Friendly, Pho, Fresh, Dim Sum',
        annual_dol: 75.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7192,
        store_longitude: -74.0061,
        total_orders: 10.35,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Hotel, Iconic, Spacious, Impressive, Cultural',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7368,
        store_longitude: -74.0012,
        total_orders: 1747.8,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Cheesesteak, Beer, Whiskey, Tasty, Delicious, Philly Cheesesteak, Rum, Friendly',
        annual_dol: 6734.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6981,
        store_longitude: -73.934,
        total_orders: 806.84,
        neighborhood: 'Bushwick',
        keywords:
            'Pizza, Beer, Cozy, Friendly, American, Perfect, Lively, Great, Local, Traditional',
        annual_dol: 878.05,
        description:
            'Cozy neighborhood bar with late hours & a chill atmosphere offering drink specials & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7376,
        store_longitude: -73.9912,
        total_orders: 1209.76,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Tasty, Salad, Wine, Vegan, Thai, Beer, Dessert, Spicy, Malaysian, Classy',
        annual_dol: 1501.76,
        description:
            'Southeast Asian eatery with exposed brick serving fare from Thailand, Malaysia, Singapore & beyond.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.734,
        store_longitude: -73.9889,
        total_orders: 147.28,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Mexican, Salad, Italian, Organic, Delicious, Warm, Local, Casual, Great',
        annual_dol: 693.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6396,
        store_longitude: -73.9864,
        total_orders: 630.31,
        neighborhood: 'Borough Park',
        keywords: 'Steak, Sushi, Salad, Wine, Upscale, Stylish, Korean, Trendy, Bbq, Warm',
        annual_dol: 1945.46,
        description:
            'Upscale kosher destination presenting steaks & creative sushi in a stylish bi-level space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5539,
        store_longitude: -74.1449,
        total_orders: 663.98,
        neighborhood: 'Great Kills',
        keywords:
            'Salad, Italian, Delicious, Warm, Polished, Friendly, Casual, Great, Classic, Fine',
        annual_dol: 703.44,
        description:
            'Classic Italian meals & steaks in a spacious, old-school dining room with live entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8861,
        store_longitude: -73.9072,
        total_orders: 8501.31,
        neighborhood: 'Riverdale/West Bronx',
        keywords:
            'Mexican, Tacos, Tequila, Steak, Margarita, Gourmet, Dessert, Tex-Mex, Enchiladas, Spanish',
        annual_dol: 13039.83,
        description:
            'Trendy restaurant & bar serving gourmet Mexican dishes, craft cocktails & mezcal in a quirky space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.9728,
        total_orders: 3973.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Sushi, Champagne, Wine, Chilean, Coffee, Upscale, Greek',
        annual_dol: 4057.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7517,
        store_longitude: -73.9707,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Rich, Upscale, Hotel, Trendy, Delicious, Vibrant, Iconic, Fantastic, Sophisticated, Incredible',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5855,
        store_longitude: -73.9518,
        total_orders: 4.35,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Salad, Delicious, Juicy, Pizza, Spectacular, Friendly, Exceptional, Great, Fried Shrimp, Authentic',
        annual_dol: 17.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.816,
        store_longitude: -73.9398,
        total_orders: 112.13,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tequila, Tacos, Lobster Roll, Lobster, Mexican, Jamaican, American, Premium, French, Great',
        annual_dol: 148.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7131,
        store_longitude: -74.0077,
        total_orders: 574.27,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Beer, Tasty, Upscale, Comfortable, Indulgent, Friendly, Vibrant, Natural, Perfect',
        annual_dol: 599.8,
        description:
            '"Tribeca Social is a beautiful new specialty cocktail bar and lounge. The space is multi-dimensional, transforming daily from an upscale sports bar to a lounge with light bites and cocktails, to an intimate, curated events space. Tribeca Social offers a vibrant and elevated atmosphere, where guests can catch a game on one of the 70 inch gold televisions that surround the bar. The TVs are gold framed, and are converted to tasteful art displays during the evening hours."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9697,
        total_orders: 83.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Steak, Tasty, Cajun, Delicious, Luxurious, Asian, Comfortable, Premium, Fresh',
        annual_dol: 151.16,
        description:
            'Seafood specialty restaurant featuring all-you-can-eat meals in a laid-back atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.717,
        store_longitude: -73.808,
        total_orders: 183.01,
        neighborhood: 'Jamaica/Kew Gardens Hills/Hillcrest',
        keywords:
            'Burger, Steak, Chicken Parmigiana, Salad, Turkey Sandwich, Roast Beef, Italian, Wine, Beer, Swiss',
        annual_dol: 748.08,
        description:
            '"Supreme Diner offers perfect comfort food created with healthy ingredients. We\'re passionate about delicious food, premier customer service, and guaranteed value. Our food is upscale for diner standards and of the highest quality. Our staff is family, employing full-time Executive Chefs, experienced line/prep cooks, experienced servers, and management. Come taste the difference. Also full bar available for indoor dinning, beer, wine and cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7584,
        store_longitude: -73.983,
        total_orders: 18801.78,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Luxurious, Wine, Rooftop, Stylish, Delicious, Sushi, Phenomenal, Tasty, Warm',
        annual_dol: 19929.65,
        description:
            "The sophisticated, contemporary rooms feature flat-screen TVs, minibars and free Wi-Fi, along with iPod docks and rainfall showerheads. Suites add separate living areas. Room service is available.Complimentary continental breakfast is provided, and there's a chic restaurant, a stylish rooftop cocktail lounge, an intimate sushi restaurant and a fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6907,
        store_longitude: -73.9955,
        total_orders: 2542.98,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Beer, Cozy, Comfortable, Ale, Hotel, Friendly, Spacious, Casual, Fun, Vintage',
        annual_dol: 2542.98,
        description:
            'Drinks & beer cheese snacks in a spacious taproom with distressed vintage furniture & a bocce court.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5151,
        store_longitude: -74.2481,
        total_orders: 560,
        neighborhood: 'Tottenville',
        keywords:
            'Burger, Steak, Gourmet, Italian, Dessert, American, Eclectic, Casual, Romantic, Great',
        annual_dol: 1180.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6734,
        store_longitude: -73.9539,
        total_orders: 186.01,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Salad, French Toast, Coffee, French, Wine, African, Casual, Exotic, Comfortable',
        annual_dol: 194.08,
        description:
            'Comfortable outpost for French-Senegalese cuisine, including brunch & a weekly fixed-price option.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9807,
        total_orders: 98.06,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Steak, Brazilian, Tasty, Traditional, Polished, Caipirinha, Authentic, Local, Filet Mignon, Casual',
        annual_dol: 106.71,
        description:
            'Longtime Brazilian restaurant serving up grilled meats, stews & strong drinks in a simple setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7584,
        store_longitude: -73.9853,
        total_orders: 2792.6,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Tequila, Vodka, Burger, Margarita, Salad, Tasty, Dessert, Glamorous, Grand Marnier, American',
        annual_dol: 7282.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.9859,
        total_orders: 74.45,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Turkey Sandwich, Roast Turkey, Ale, American, Casual, Clean, Friendly, Classic, Attentive, Amazing',
        annual_dol: 304.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7098,
        store_longitude: -74.0129,
        total_orders: 410.96,
        neighborhood: 'World Trade Center/Financial District',
        keywords: 'Italian, Pizza, Meatball, Wine, Beer, Vibrant, Perfect, Great, Casual, Local',
        annual_dol: 468.06,
        description:
            'Bread-themed branch of the famed Italian market, offering counters, restaurants & cooking demos.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7099,
        store_longitude: -74.0118,
        total_orders: 26.19,
        neighborhood: 'World Trade Center/Financial District',
        keywords:
            'Steak, Beer, Italian, Gourmet, Peruvian, Korean, Fried Chicken, Cuban, Club Sandwich, Contemporary',
        annual_dol: 107.04,
        description:
            'Rustic-industrial food hall with a variety of popular counter-serve eateries, produce stalls & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.9974,
        total_orders: 558.93,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Friendly, Premium, Great, Hip, Unique',
        annual_dol: 1824.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7712,
        store_longitude: -73.9878,
        total_orders: 46.23,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Pizza, Vegetarian, Salad, Italian, Vodka, Caesar Salad, Spicy, Hotel, Casual',
        annual_dol: 188.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7284,
        store_longitude: -73.9993,
        total_orders: 1169.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Hamburger, Margarita, Wine, Canadian, Karaoke, Bbq, Rum, Mimosa, Hurricane',
        annual_dol: 1206.7,
        description:
            'Live bands, beer pong tables & drink specials amp up the party vibe at this watering hole near NYU.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.8783,
        total_orders: 375.97,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Mexican, American, Casual, Latin, Local, Authentic, Unique',
        annual_dol: 484.08,
        description:
            '"True to the Mexican flavor and honoring the original recipes is the key of our success. Nothing compares to the authentic flavor of home, so we keep those traditions in place here, where you can enjoy the rainbow of flavors of the Authentic Mexican cuisine dishes. From Pechuga Asada to a flavor-filled Molcajete dish, our Specialties will make you come b We strive every day to serve you with a big smile and a unique flavor in every order we put in your hands. You, our customers, are the sole purpose of our existence, therefore, all our efforts are focused on give you the best possible culinary experience in our restaurant. Our team is trained and prepared to make even a lunch an special event for your eyes and stomach. "Mi Casa es Su Casa"."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9959,
        total_orders: 702.74,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Vegetarian, Salad, Hamburger, Gluten Free, Beer, Wine, Avocado Toast, Organic, Savory, Australian',
        annual_dol: 2549.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7334,
        store_longitude: -74.0053,
        total_orders: 2175.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Beer, Friendly, Casual, Cool, African, American, Great, Fusion, Diverse',
        annual_dol: 2335.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -74.0056,
        total_orders: 900.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Burger, Tacos, Beer, Wine, Champagne, Coffee, Spicy, Martini, Trendy',
        annual_dol: 939.32,
        description:
            'Philly spin-off providing housemade breads & pastries, elevated sandwiches, coffee & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7416,
        store_longitude: -73.6397,
        total_orders: 940.62,
        neighborhood: '',
        keywords: 'Burger, Steak, Salad, Beer, Gourmet, Wine, Ale, Danish, Old Fashioned, Irish',
        annual_dol: 960.58,
        description:
            'Traditional American cooking & modern bar eats are served in a vintage saloon setting with booths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6477,
        store_longitude: -74.0074,
        total_orders: 45.92,
        neighborhood: 'Sunset Park',
        keywords:
            'Tacos, Mexican, Thai, Premium, Authentic, Superb, Outstanding, Fresh, Cool, Great',
        annual_dol: 193.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.6403,
        total_orders: 54.73,
        neighborhood: '',
        keywords: 'Pizza, Mexican, Italian, Casual, Asian, Bruschetta, Fresh',
        annual_dol: 62.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8442,
        store_longitude: -73.9392,
        total_orders: 41.79,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Vegan, Cuban, Coffee, Delicious, Dominican, Spacious, American, Latin, Hip, Club Sandwich',
        annual_dol: 92.92,
        description:
            'Corner spot with hip decor offering Cuban plates with international influences, served all day.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7019,
        store_longitude: -73.8794,
        total_orders: 423.01,
        neighborhood: 'Flushing/Glendale',
        keywords:
            'Hamburger, Steak, Pizza, Salad, Cheesesteak, Beer, Bbq, Ale, French, Philly Cheesesteak',
        annual_dol: 442.62,
        description:
            'Bar food, brunch & dinner entrees draw neighbors to this casual pub with sports-bar ambience.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7664,
        store_longitude: -73.7066,
        total_orders: 826.41,
        neighborhood: '',
        keywords: 'Burger, Sushi, Golfclub, Impeccable, Great, Classic, Fresh',
        annual_dol: 1311.95,
        description:
            '"Located on the former site of the Vanderbilt’s private golf club, The Lake Success Golf Club offers a challenging 6,414-yard, par 70 course with well-manicured fairways and impeccable greens. In addition to golf, members enjoy a first class club house, grill room, Social Activities Center, Fitness center and more. Tennis and Pool memberships are also available. We\'re truly a home away from home."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7032,
        store_longitude: -73.9322,
        total_orders: 497.74,
        neighborhood: 'Bushwick/East Williamsburg',
        keywords:
            'Vegetarian, Sushi, Mexican, Organic, American, Delicious, Casual, Local, Eclectic, Sweet',
        annual_dol: 554.61,
        description:
            'Inventive craft cocktails, including milk punches, plus eclectic fare in an indoor-outdoor setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9643,
        total_orders: 1916.66,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Lobster, Salad, Italian, Dessert, Shrimp Scampi, Wine, Meatball, Vodka, Delicious, Warm',
        annual_dol: 1957.33,
        description:
            'Bustling eatery serving large, shareable portions of Italian comfort food in a lively setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7298,
        store_longitude: -73.9574,
        total_orders: 3434.33,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Margarita, Trendy, Rich, Warm, Cozy, Phenomenal, Eclectic, Friendly',
        annual_dol: 3662.63,
        description:
            'A tap beer selection is poured in a room with apothecary cabinets, brick walls & wide plank floors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7521,
        store_longitude: -73.985,
        total_orders: 107.59,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Hotel, Whiskey, Scotch, Beer, Stylish, Rooftop, American, Awesome, Superb',
        annual_dol: 439.78,
        description:
            'Posh, wood-lined lobby bar inside the Refinery Hotel featuring classic cocktails & live jazz.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9938,
        total_orders: 313.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Tacos, Mexican, Steak, Salad, Nachos, Beer, Tasty, Burritos, Rustic, Sparkling',
        annual_dol: 342.94,
        description:
            'Longtime counter-serve joint with a large menu of Mexican classics, plus wholesale tortillas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.8603,
        total_orders: 444.93,
        neighborhood: 'North Corona/East Elmhurst/Flushing/Corona',
        keywords:
            'Steak, Vegetarian, Salad, Argentinian, Beer, French, Dessert, Brazilian, Japanese, Warm',
        annual_dol: 474.5,
        description:
            'Besides all-you-can-eat meat, this airy Brazilian steakhouse offers a buffet, salad bar & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.5796,
        total_orders: 473.95,
        neighborhood: '',
        keywords:
            'Burger, Lasagna, Salad, Pizza, Chicken Parmigiana, Italian, Chicago-Style, Wine, Organic, Delicious',
        annual_dol: 490.75,
        description:
            'Cozy, relaxed dining room serving Italian standards from pasta to seafood plates plus wine & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -74.0086,
        total_orders: 1384.1,
        neighborhood: 'Hudson Square',
        keywords: 'Burger, Beer, Wine, Trendy, Delicious, Urban, American, Fun, Classy, Hip',
        annual_dol: 1384.1,
        description:
            'Nodding to the East End, this bar has seaside touches to go with cocktails, oysters & small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.588,
        total_orders: 189.69,
        neighborhood: '',
        keywords:
            'Burger, Tacos, Salad, Wine, Delicious, Italian, Elegant, Legendary, Exquisite, Friendly',
        annual_dol: 514.29,
        description:
            '"Monroe\'s is a family owned and operated restaurant serving fresh and delicious food in Westbury, NY. We use only the best ingredients and recipes for all of our dishes, serving our customers quality that they can taste in every bite. Packed with flavor and cooked with the utmost care and dedication to authenticity, you are sure to love everything about our cuisine."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7035,
        store_longitude: -73.9263,
        total_orders: 2037.72,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Cozy, Lively, Cool, Bright, Great, Prosecco, Fine, Casual',
        annual_dol: 2080.95,
        description:
            'Unpretentious, cozy bar with leather booths offering beers on draft, cocktails & shot specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7251,
        store_longitude: -73.9925,
        total_orders: 1833.28,
        neighborhood: 'NoHo',
        keywords:
            'Pizza, Vodka, Tasty, Rustic, Gin, Delicious, Rum, Spacious, Friendly, Contemporary',
        annual_dol: 2038.81,
        description:
            'Hip, rustic bar serving cocktails & pizza in a brick-lined space with Edison bulbs & TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8023,
        store_longitude: -73.9535,
        total_orders: 87.84,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Tasty, Pizza, Italian, Salad, Wine, Champagne, Spicy, Delicious, Mediterranean, Hotel',
        annual_dol: 359.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8087,
        store_longitude: -73.9451,
        total_orders: 7168.49,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Burger, Tasty, Champagne, Upscale, Organic, Sweet, Polished, Fun, Caesar Salad',
        annual_dol: 8696.29,
        description:
            'Harlem restaurant/bar with upscale pub menu, craft beers, outdoor seating & special DJ nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.9888,
        total_orders: 1408.12,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Wine, Delicious, Friendly, Rum, Fun, Wonderful, Retro, Great',
        annual_dol: 1469.17,
        description:
            'Happening saloon with a retro athletic-club atmosphere that includes boxing & wrestling memorabilia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7659,
        store_longitude: -73.9874,
        total_orders: 784.2,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Beer, Urban, Tex-Mex, Rustic, Robust, American, Mead, Friendly, Homemade, Awesome',
        annual_dol: 800.84,
        description:
            'Low-key, urban-rustic tavern offering a robust menu of microbrews on tap & by the bottle.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7637,
        store_longitude: -73.989,
        total_orders: 339.47,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Tequila, Tacos, Mexican, Vodka, Margarita, Wine, Salad, Pizza, Gin, Tasty',
        annual_dol: 364.55,
        description:
            'Chill Mexican venue offering classic fare, craft cocktails & happy-hour specials in comfy digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7479,
        store_longitude: -74.0042,
        total_orders: 613.66,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Lobster, Lobster Roll, Wine, Tasty, Vodka, Beer, Upscale, Gin, Romantic, Rum',
        annual_dol: 2003.46,
        description: '"We are reopening on MONDAY, OCTOBER 11, 2021!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6813,
        store_longitude: -73.9774,
        total_orders: 1034.32,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Salad, Beer, Cozy, Delicious, Comfortable, Rustic, Friendly, Sweet, Cool',
        annual_dol: 1056.27,
        description:
            'All-day bar/eatery serving gastropub fare in a wood-&-brick-lined interior & sunlit back garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6815,
        store_longitude: -73.9796,
        total_orders: 23.8,
        neighborhood: 'Park Slope',
        keywords: 'Beer, American, Spicy, Casual, Traditional, Diverse',
        annual_dol: 97.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7394,
        store_longitude: -73.7863,
        total_orders: 1385.68,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords:
            'Salad, Mexican, Bourbon, Meatball, Fresh, Thai, Lively, Asian, French, French Onion Soup',
        annual_dol: 1656.58,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6423,
        store_longitude: -74.0767,
        total_orders: 665.91,
        neighborhood: 'St. George/Mid Island',
        keywords: 'Burger, Pizza, Beer, Delicious, Italian, Crisp, Cozy, Relaxed, Clean, Friendly',
        annual_dol: 696.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9836,
        total_orders: 4787.01,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords:
            'Thai, Tasty, Stylish, Salad, Elegant, Wine, Vegan, Friendly, Traditional, Authentic',
        annual_dol: 4999.64,
        description:
            'Traditional Thai cuisine & cocktails served in a modern space with a stylish bar & golden accents.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.717,
        store_longitude: -73.959,
        total_orders: 1313.19,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Wine, French, Homemade, Polished, Fresh, Perfect, Seasonal, Casual, American',
        annual_dol: 1370.12,
        description:
            'French bistro fare served in a Williamsburg space with an indoor "garden" room & a roof deck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9565,
        total_orders: 824.99,
        neighborhood: 'Williamsburg',
        keywords:
            'Whiskey, Tasty, Wine, Stylish, Delicious, Spacious, Czech, Authentic, American, Cool',
        annual_dol: 3108.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7067,
        store_longitude: -74.0097,
        total_orders: 118.44,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Pizza, Salad, Wine, Irish, Superb, Amazing, Exotic, Iconic, Global, Aperol',
        annual_dol: 484.14,
        description:
            '40 Wall Street, also known as the Trump Building, is a 927-foot-tall neo-Gothic skyscraper on Wall Street between Nassau and William streets in the Financial District of Manhattan in New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7715,
        store_longitude: -73.9561,
        total_orders: 618.93,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Garlic Bread, Hamburger, Meatball Sub, Meatball, Salad, Italian, Gluten Free, Dessert, Beer',
        annual_dol: 632.06,
        description:
            'Branch of a hip, ingredient-focused local chain offering inventive meatball sandwiches & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.705,
        store_longitude: -73.9201,
        total_orders: 156.54,
        neighborhood: 'Bushwick',
        keywords: 'Steak, Tacos, Hamburger, Mexican, Vegan, Beer, Tasty, Quesadillas, Spicy, Cozy',
        annual_dol: 769.89,
        description:
            'Buzzing eatery serving Mexican fare in a cozy & colorful dining room or an outdoor garden space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -74.0058,
        total_orders: 487.7,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Italian, German, Organic, Modern, Mediterranean, Israeli, Kibbeh, Middle Eastern, Casual, Fine',
        annual_dol: 1712.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -73.6352,
        total_orders: 137.73,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Pizza, Japanese, Gourmet, Chinese, Fusion, Asian, Miso Soup, Friendly',
        annual_dol: 249.68,
        description:
            'Pair of sushi/hibachi restaurants known for their fresh fish, bento boxes, sakes & wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8361,
        store_longitude: -73.8405,
        total_orders: 511.62,
        neighborhood: 'Westchester Square',
        keywords:
            'Burger, Salad, Cajun, Italian, Delicious, Mediterranean, Spanish, Spacious, Puerto Rican, Friendly',
        annual_dol: 659.76,
        description:
            'Hip Puerto Rican restaurant & bar doling out brick oven-baked pizzas, empanadas & specialty drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6975,
        store_longitude: -73.6667,
        total_orders: 188.46,
        neighborhood: '',
        keywords:
            'Coffee, Beer, Martini, Dessert, Trendy, Italian, French, Classy, Impeccable, Homemade',
        annual_dol: 710.07,
        description:
            '"Focused around quality food, drinks, and conversation. Our ever-changing tapas menu is designed around seasonal ingredients, as are our cocktail, beer, dessert, and coffee selections."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7149,
        store_longitude: -74.0161,
        total_orders: 2657.71,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Beer, Wine, Bourbon, Salad, Upscale, American, Spicy, Bbq, Warm, Spacious',
        annual_dol: 2774.67,
        description:
            "Danny Meyer's spacious barbecue joint matches its signature ribs with deep beer & bourbon lists.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7086,
        store_longitude: -73.9364,
        total_orders: 442.6,
        neighborhood: 'East Williamsburg',
        keywords: 'Rum, Caribbean, Awesome, Perfect, American, Great, Excellent, Casual',
        annual_dol: 651.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7235,
        store_longitude: -73.9508,
        total_orders: 167.97,
        neighborhood: 'Greenpoint',
        keywords: 'Polish, Beer, Homemade, Great, Casual',
        annual_dol: 228.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6933,
        store_longitude: -73.9693,
        total_orders: 6.63,
        neighborhood: 'Clinton Hill',
        keywords: 'Mexican, Beer, Cajun, Wine, Vintage, Healthy, Local, Intimate, Casual, Great',
        annual_dol: 18.04,
        description: '"Serving freshest seafood boil tossed in Louisiana style Cajun sauced bags"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7684,
        store_longitude: -73.9872,
        total_orders: 2165.04,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotel, Gluten Free, Spacious, French Toast, Beer, French, Fantastic, Amazing, Classic, Great',
        annual_dol: 8118.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9823,
        total_orders: 404.12,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Sushi, Pizza, Wine, Italian, Cajun, Chinese, Beer, Stylish, Urban, Fun',
        annual_dol: 446.68,
        description:
            'Rustic-chic tavern featuring small plates of Italian nibbles, panini, wine, beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.9755,
        total_orders: 456.21,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Steak, Salad, Tomato Soup, Dessert, Wine, Italian, Californian, Beer',
        annual_dol: 486.54,
        description:
            'Posh chain serving American fare, including brunch, dinner & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9,
        store_longitude: -73.8622,
        total_orders: 121.16,
        neighborhood: '',
        keywords:
            'Jamaican, Caribbean, Chinese, Champagne, Dominican, Legendary, Traditional, Authentic, Great, Teriyaki',
        annual_dol: 495.27,
        description:
            'Late-night counter-serve fixture offering a wide selection of seafood meals in a no-frills interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9048,
        store_longitude: -73.897,
        total_orders: 94.58,
        neighborhood: 'North Riverdale',
        keywords:
            'Burger, Hamburger, Steak, Tacos, Cheesesteak, Salad, Cajun, Beer, Bbq, Margarita',
        annual_dol: 154.57,
        description:
            'Rustic spot dispensing hearty American comfort grub, especially creative spins on classic burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.8805,
        total_orders: 90.67,
        neighborhood: 'Jackson Heights',
        keywords: 'Mexican, Tacos, Salad, Burritos, Local, Casual, Fresh, Classic',
        annual_dol: 370.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.9791,
        total_orders: 45.03,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Gourmet, Coffee, Hotel, American, Wonderful, Fine',
        annual_dol: 184.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8683,
        store_longitude: -73.9187,
        total_orders: 22.24,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Mexican, Mimosa, Italian, Dominican, American, Local, Casual, Seasonal, Relaxed, Delicious',
        annual_dol: 90.92,
        description:
            'A Dominican-influenced menu pairs with cocktails & hookah in a relaxed space with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8657,
        store_longitude: -73.9273,
        total_orders: 49.32,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Cheesesteak, Steak, Milkshake, Coffee, Wine, Italian, Tasty, American, Warm, Reuben Sandwich',
        annual_dol: 185.84,
        description:
            'Laid-back, brick-lined cafe with espresso, craft beers, brunch & a New American food menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 2553.04,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Lobster, Salad, Mexican, Italian, Wine, Chinese, Thai, Fried Rice, Hotel',
        annual_dol: 2685.46,
        description:
            'Downtown outpost of the buzzy uptown original serving Beijing-style fare in subterranean hotel digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6063,
        store_longitude: -73.8198,
        total_orders: 92.99,
        neighborhood: 'Broad Channel/Far Rockaway',
        keywords: 'Wine, American, Hotel, Fun, Sweet, Great, Excellent, Casual',
        annual_dol: 107.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6286,
        store_longitude: -74.0884,
        total_orders: 1638.82,
        neighborhood: 'Silver Lake',
        keywords: 'Italian, Cultural, Beer, Exquisite, Vibrant, American',
        annual_dol: 1774.42,
        description:
            'Casa Belvedere is a cultural center devoted to Italian studies, located at 79 Howard Avenue, Grymes Hill, Staten Island, New York City. The mansion was constructed in 1908, and is an Italian Renaissance style building with Arts and Crafts detailing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6779,
        store_longitude: -73.9686,
        total_orders: 1598.63,
        neighborhood: 'Prospect Heights',
        keywords: 'Pizza, Salad, Casual, Fresh, American, Great',
        annual_dol: 6265.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7106,
        store_longitude: -73.9533,
        total_orders: 691.62,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Vegan, Vegetarian, Burger, Sushi, Pizza, Mexican, Wine, American, Casual, Latin',
        annual_dol: 2895.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.724,
        store_longitude: -73.9838,
        total_orders: 343.52,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Pizza, Meatball, Mimosa, Beer, Coffee, Apple Pie, American, Rustic, Delicious',
        annual_dol: 366.09,
        description:
            'Quirky, dimly lit, rustic-chic eatery serving eclectic comfort food for happy hour, brunch & dinner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6797,
        store_longitude: -73.9565,
        total_orders: 534.05,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Wine, Delicious, Juicy, Rum, Great, Incredible, Whimsical, Ample, Pinacolada, Signature',
        annual_dol: 1373.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.9844,
        total_orders: 2974.46,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vodka, Beer, Tasty, Gluten Free, Ale, Gin, Meatball, British, Sweet, Traditional',
        annual_dol: 3037.58,
        description:
            'This bi-level watering hole with an English theme has British entrees, bar snacks & imported beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -73.9784,
        total_orders: 4842.03,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Salad, Tomato Soup, Meatball, Beer, Wine, French Onion Soup, Upscale, Caesar Salad, American',
        annual_dol: 5037.16,
        description:
            'Upscale bar on the edge of Murray Hill with a self-service tap system & hearty American eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.9217,
        total_orders: 1525.97,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Thai, Beer, Wine, Greek, Stylish, Delicious, Contemporary, Friendly, Awesome',
        annual_dol: 1563.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.722,
        store_longitude: -73.958,
        total_orders: 88.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Hotel, Trendy, Stylish, Margarita, Fabulous, Spanish, American, Rooftop, Outstanding',
        annual_dol: 361.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7686,
        store_longitude: -73.9333,
        total_orders: 144.74,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Mexican, Salad, Pizza, Breakfast Burrito, French Toast, Modern, French, Spanish, Latin',
        annual_dol: 321.51,
        description:
            'Laid-back establishment with old-world accents, supplying cocktails & modern Mexican fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7354,
        store_longitude: -74.008,
        total_orders: 440.52,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Austrian, Wine, Coffee, Salad, German, French, Impeccable, Refined, Global, Modern',
        annual_dol: 657.32,
        description:
            'Modern Austrian fare (Wiener schnitzel, goulash, etc.) is on the plates & fine art is on the walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.9508,
        total_orders: 585.53,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Burger, Salad, Wine, Italian, French, Beer, Organic, Delicious, Cozy',
        annual_dol: 623.11,
        description:
            'French bistro & wine bar in brick-lined surrounds with a carefully sourced menu & a romantic vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7393,
        store_longitude: -73.9202,
        total_orders: 371.37,
        neighborhood: 'Sunnyside/Woodside',
        keywords: 'Beer, Whiskey, Tasty, Wine, Ale, Warm, Homey, Bbq, Awesome, Perfect',
        annual_dol: 410,
        description:
            'Long-running, unpretentious watering hole with a patio offering billiards, darts & games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7735,
        store_longitude: -73.9559,
        total_orders: 1076.72,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Salad, Wine, Champagne, Canadian, Spicy, Asian, American, Modern, Intimate',
        annual_dol: 1149.03,
        description:
            'Global cuisine from pork sliders to chicken satay served in a modern restaurant with a lively bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7835,
        store_longitude: -73.9787,
        total_orders: 359.14,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Beer, Whiskey, Wine, Martini, Irish, Delicious, Relaxed, Comfortable, Seasonal, Global',
        annual_dol: 471.78,
        description:
            'Circa-1933 Upper West Side throwback bar with linoleum, wood paneling & an old-school neon sign.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8353,
        store_longitude: -73.9436,
        total_orders: 885.11,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Peruvian, Wine, Beer, Coffee, Upscale, Delicious, Latin, Sangria, Casual, Great',
        annual_dol: 903.89,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7751,
        store_longitude: -73.9565,
        total_orders: 273.99,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Wine, Vegetarian, Gourmet, Dessert, American, Romantic, Cozy, Iconic, High-End, Sweet',
        annual_dol: 1116.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -74.0026,
        total_orders: 183.84,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Salad, Hotel, American, Fun, Cool, Casual, Quirky, Classic, Great',
        annual_dol: 751.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.688,
        store_longitude: -73.9273,
        total_orders: 1974.22,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Beer, Awesome, Great, Incredible, Excellent, American, Cool, Casual',
        annual_dol: 2103.95,
        description:
            '"Hand-Crafted cocktails Artisan Brews Scratch Kitchen Sandwiches Hot Nights, Cool Jazz Neighborhood Hospitality"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8573,
        store_longitude: -73.8863,
        total_orders: 373.55,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Lasagna, Steak, Lobster, Pizza, Chicken Parmigiana, Salad, Shrimp Cocktail, Italian, Wine, Vodka',
        annual_dol: 425.44,
        description:
            'Brick-oven pies & classic Italian dishes served in a relaxed, roomy eatery & wine bar with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -74.0055,
        total_orders: 398.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Gluten Free, Rustic, Wine, Italian, French Toast, Cozy, Trendy, French',
        annual_dol: 493.65,
        description:
            'Snug choice offering brunch, burgers & hearty meat dishes, plus a large wine list & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7352,
        store_longitude: -74.0066,
        total_orders: 229.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Dessert, Wine, Tasty, Italian, Vodka, Martini, Delicious, Cozy, Warm',
        annual_dol: 243.82,
        description:
            'Vibrant outfit with exposed bricks & a wood ceiling serving Italian classics & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.734,
        store_longitude: -74.0081,
        total_orders: 1102.07,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Flavorful, Luxurious, Rich, Upscale, Delicious, Wine, Romantic, Opulent, Savory, Egyptian',
        annual_dol: 1587.16,
        description:
            'Plush decor & entertainment from a belly dancer complement the exotic menu & specialty cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.6415,
        total_orders: 376.93,
        neighborhood: '',
        keywords:
            'Chocolate Cake, Cheesecake, Steak, Carrot Cake, Flavorful, Wine, Savory, Delicious, Beer, Premium',
        annual_dol: 410.8,
        description:
            'Portuguese rodizio offering sizzling grilled-meat skewers & other traditional fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -74.0012,
        total_orders: 173.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vodka, Salad, Mexican, Italian, Shrimp Scampi, Chinese, Cuban, Irish, Romantic, Cozy',
        annual_dol: 607.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7859,
        store_longitude: -73.9724,
        total_orders: 1435.74,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Lasagna, Pizza, Steak, Italian, Wine, Meatball, Coffee, Beer, Mimosa, Delicious',
        annual_dol: 1632.42,
        description:
            'Rustic Italian eatery serving small plates & pizza in a main-floor wine bar or a cozy upstairs room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7839,
        store_longitude: -73.9743,
        total_orders: 522.65,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Sushi, Steak, Vegetarian, Tasty, Wine, Beer, Delicious, Trendy, Stylish, Japanese',
        annual_dol: 533.74,
        description:
            'Stylish eatery for sushi, dim sum, teppanyaki & contemporary Asian entrees, plus daily happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7713,
        store_longitude: -73.9537,
        total_orders: 8.09,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Thai, Spicy, Wine, Meatball, Martini, Beer, Crab Rangoon, Delicious, Asian',
        annual_dol: 33.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8074,
        store_longitude: -73.9273,
        total_orders: 89.81,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Mexican, Salad, Pizza, Mimosa, Coffee, Dominican, Puerto Rican, Caribbean, Rich, Delicious',
        annual_dol: 367.13,
        description:
            'Outpost of a Puerto Rico–based mini-chain serving chocolate-infused cocktails & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6658,
        store_longitude: -73.9889,
        total_orders: 387.31,
        neighborhood: 'Park Slope',
        keywords: 'Steak, Wine, Burger, Pizza, Beer, Salad, Italian, Delicious, French, American',
        annual_dol: 487.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6357,
        store_longitude: -73.9633,
        total_orders: 1527.34,
        neighborhood: 'Flatbush',
        keywords: 'Burger, Beer, Spicy, Warm, Bbq, Cozy, Friendly, American, Asian, Casual',
        annual_dol: 1662.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6713,
        store_longitude: -73.9843,
        total_orders: 254.47,
        neighborhood: 'Park Slope',
        keywords: 'Tequila, Vodka, Beer, Gin, Rum, Fun, Friendly, Casual, Cool, Awesome',
        annual_dol: 1040.19,
        description:
            'Casual lesbian bar with a neighborly feel thanks to happy hours, a pool table & an outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8677,
        store_longitude: -73.8832,
        total_orders: 64.53,
        neighborhood: 'Jerome Park/West Bronx',
        keywords: 'Gourmet, Dominican, Spanish, American, Latin, Local, Casual, Sweet, Great',
        annual_dol: 263.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8608,
        store_longitude: -73.8892,
        total_orders: 162.87,
        neighborhood: 'Belmont/Fordham Manor',
        keywords: '',
        annual_dol: 221.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7276,
        store_longitude: -73.9603,
        total_orders: 174.89,
        neighborhood: 'Greenpoint',
        keywords: 'Unique, Classic, Friendly',
        annual_dol: 714.88,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7185,
        store_longitude: -74.009,
        total_orders: 3689.18,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Italian, Pizza, Salad, Wine, Whiskey, Beer, American, Casual, Classic, Fresh',
        annual_dol: 3831.76,
        description:
            'Laid-back, 1945-era corner pub with bar bites & Alidoro Italian sandwiches for lunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.5877,
        total_orders: 316.51,
        neighborhood: '',
        keywords: 'Steak, Salad, Pizza, Italian, Savory, Seasonal, Casual, Fine',
        annual_dol: 338.72,
        description:
            'White-tablecloth mainstay for Northern Italian dishes & fine wines with live piano on the weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.9952,
        total_orders: 1658.45,
        neighborhood: 'Little Italy',
        keywords:
            'Burger, Steak, Vegetarian, Sushi, Salad, Fruity, Nachos, Spicy, Homemade, Friendly',
        annual_dol: 6607.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9864,
        total_orders: 7258.74,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Ramen, Spicy, Japanese, Stylish, Lively, Classic, Fresh, Casual, Great, Chill',
        annual_dol: 7632.05,
        description:
            'Japanese small plates, ramen & upmarket sake are dispatched in this simple but stylish outpost.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.9848,
        total_orders: 187.63,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Steak, Cheesecake, Roast Turkey, Turkey Sandwich, Roast Beef, Italian, Swiss, Martini, American',
        annual_dol: 766.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7657,
        store_longitude: -73.9909,
        total_orders: 735.59,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Asian, Delicious, Romantic, Innovative, Modern, Homemade, American, Outstanding',
        annual_dol: 1200.76,
        description:
            'Stylish, dimly lit wine bar with open kitchen & an Italian-leaning menu, including homemade pasta.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7649,
        store_longitude: -73.983,
        total_orders: 919.12,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Tacos, Mexican, Steak, Beer, Tasty, Cheesesteak, Wine, Delicious, Trendy, Warm',
        annual_dol: 958.97,
        description:
            'Bi-level locale with ingredient-driven Mexican cuisine plus cocktails highlighting mezcal & tequila.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7674,
        store_longitude: -73.9896,
        total_orders: 13594.63,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Vegetarian, Mexican, Pizza, Gluten Free, Margarita, Dessert, Savory, Delicious, Cozy',
        annual_dol: 14539.54,
        description:
            'Casual Mexican restaurant offering homestyle fare & a full bar with lots of margarita options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7615,
        store_longitude: -73.9847,
        total_orders: 748.81,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Salad, Italian, Wine, Tasty, Japanese, Chilean, Spicy, Delicious, Asian',
        annual_dol: 1464.41,
        description:
            'Sushi, infused sakes & modern Japanese fare are served at this sleek venue with an adjacent lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -73.9925,
        total_orders: 3122.94,
        neighborhood: 'Bowery',
        keywords: 'Pizza, Cajun, Beer, Wine, Funky, American, Creole, Modern, Vintage, Classy',
        annual_dol: 3822.34,
        description:
            'Funky hangout serving New Orleans-inspired bites & drinks, plus beats in its basement music lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5922,
        store_longitude: -73.5834,
        total_orders: 905.4,
        neighborhood: '',
        keywords: 'Burger, Pizza, Salad, Italian, Coffee, Wine, Ale, Homemade, Casual, Classy',
        annual_dol: 1385.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6894,
        store_longitude: -73.9919,
        total_orders: 12.22,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords: 'Steak, Roast Beef, Fine, Great, Delectable, Personalized',
        annual_dol: 49.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 275.62,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Upscale, Italian, Trendy, Elegant, Coffee, Vibrant, Iconic, Rooftop, Classic',
        annual_dol: 520.54,
        description:
            'The colorful rooms with designer decor feature flat-screen TVs, DVD players, blue-light desks and free Wi-Fi. Suites add either separate living and dining areas, or living areas and extra bathrooms.There are 3 flashy restaurants and bars, including an indoor/outdoor, multi-level rooftop lounge, a cocktail bar and a Fellini-inspired Italian eatery. Guests receive complimentary passes to a nearby fitness center. Parking is available for a surcharge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6859,
        store_longitude: -73.8648,
        total_orders: 37.02,
        neighborhood: 'Jamaica/Woodhaven',
        keywords:
            'Burger, Lobster, Dessert, French Toast, French, American, Omelette, Casual, Sweet, Classic',
        annual_dol: 151.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -74.0089,
        total_orders: 14105.64,
        neighborhood: '',
        keywords: 'Wine, Pizza, Burger, Coffee, Beer, Salad, Spacious, Urban, Fun, Premium',
        annual_dol: 25815.24,
        description:
            '"City Winery, founded in 2008 by Michael Dorf, is a music venue, winery, restaurant, wine bar and private event space located at Pier 57 on the Hudson River in New York, NY. We have excellent views of the Little Island NYC and are nearby the Whitney Museum, Chelsea Market, and the High Line. We specialize in a variety of wines, but always with an eye toward handcrafted cocktails and food. Our location delivers a unique culinary and cultural experience to urban wine enthusiasts passionate about music. We\'re a venue for events—a place for happy hours, family reunions, and birthday parties. We host live events most nights from concerts to comedy shows as well."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7322,
        store_longitude: -73.9999,
        total_orders: 613.02,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Beer, Delicious, Hotel, Clean, Iconic, Latin, Great, Casual, Artistic',
        annual_dol: 668.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6869,
        store_longitude: -73.9748,
        total_orders: 538.27,
        neighborhood: 'Fort Greene',
        keywords: 'Wine, Italian, Intimate, Casual, Seasonal, American, Cool, Unpretentious, Great',
        annual_dol: 2028.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6787,
        store_longitude: -73.9791,
        total_orders: 699.63,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Vegetarian, Margarita, Spanish, American, Latin, Modern, Spicy, Mediterranean, Casual',
        annual_dol: 954.83,
        description:
            'Spanish & Latin American plates, sangria & cocktails in a colorful little space with a happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6868,
        store_longitude: -73.849,
        total_orders: 25.22,
        neighborhood: 'Ozone Park/Jamaica',
        keywords: 'Japanese, Irish, Recommended, Casual, Great',
        annual_dol: 103.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7141,
        store_longitude: -73.9614,
        total_orders: 3853.77,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Wine, Cozy, Warm, Friendly, Casual, Classic, Seasonal, Fresh, Great, Unique',
        annual_dol: 3858.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6132,
        store_longitude: -74.1223,
        total_orders: 183.36,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Sushi, Steak, Pizza, Japanese, Wine, Spicy, Delicious, Beer, Sashimi, Romantic',
        annual_dol: 204,
        description: '"Japanese cuisine-Sushi and Ramen House with Full Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7105,
        store_longitude: -73.9635,
        total_orders: 130.1,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Pizza, Mexican, Salad, Beer, Wine, Italian, Ale, Delicious, American, Friendly',
        annual_dol: 140.53,
        description:
            'Casual restaurant featuring artisanal, Neapolitan-style thin-crust pizzas, plus wine & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6788,
        store_longitude: -73.9717,
        total_orders: 280.87,
        neighborhood: 'Prospect Heights',
        keywords:
            'Burger, Wine, Dessert, French Toast, Californian, French, Romantic, Classy, American, Fabulous',
        annual_dol: 393.49,
        description:
            'Seasonal American fare & unique cocktails served in a modern-vintage Brooklyn space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5862,
        store_longitude: -73.6867,
        total_orders: 111.14,
        neighborhood: '',
        keywords:
            'Burger, Salad, Italian, Beer, Wine, Vodka, Rich, Homemade, Spectacular, Caesar Salad',
        annual_dol: 132.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7465,
        store_longitude: -73.9836,
        total_orders: 1624.4,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Korean, Wine, Spicy, Tasty, Beer, Bbq, Indulgent, Contemporary, Fiery, Exquisite',
        annual_dol: 1714.15,
        description:
            'Korean cuisine & tabletop BBQ, plus international wines, in a stylish, contemporary setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6785,
        store_longitude: -73.7795,
        total_orders: 731.14,
        neighborhood: 'South Jamaica/Jamaica/Addisleigh Park',
        keywords:
            'Tasty, Jamaican, Delicious, Caribbean, Elegant, Upscale, Wine, Healthy, Polished, Great',
        annual_dol: 843.9,
        description:
            'Upscale establishment turning out jerk chicken, oxtail, escovitch & other Jamaican specialties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9801,
        total_orders: 758.35,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Tequila, Wine, Beer, Scotch, Gin, Rich, Tasty, Stylish, Hotel, Rum',
        annual_dol: 3099.87,
        description:
            'Bespoke & craft cocktail bar with a chic retro library vibe within The William hotel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -73.9846,
        total_orders: 878.9,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Hamburger, Whiskey, Salad, Beer, Wine, Upscale, Casual, Rum, American, Delicious',
        annual_dol: 2283.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9851,
        total_orders: 1802.38,
        neighborhood: 'Lower East Side',
        keywords: 'Rooftop, Tacos, Wine, Beer, Margarita, Trendy, Premium, Great, Polish, Casual',
        annual_dol: 1840.62,
        description:
            'Nightlife spot includes a plush lounge, canopied roof deck & basement performance space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9783,
        total_orders: 2402.69,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Turkish, Wine, Beer, Hotel, Polished, Exceptional, Casual, Fine, Great',
        annual_dol: 2785.68,
        description:
            'Swanky spot for meat-centric Turkish fare from a chef with a flamboyant serving style.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.766,
        store_longitude: -73.9877,
        total_orders: 429.3,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lasagna, Wine, Italian, Tasty, Salad, Pizza, Vodka, Beer, Cosmopolitan, Rich',
        annual_dol: 488.94,
        description:
            'Cozy option serving shared plates, pasta & other Italian classics, plus brunch & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 20.65,
        neighborhood: 'Jackson Heights',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Colombian, Coffee, French, Latin, American, Delicious, Perfect',
        annual_dol: 42.15,
        description:
            'Family-owned Colombian diner/bakery serving filling food 24/7 in an old-time setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -73.991,
        total_orders: 241.49,
        neighborhood: 'Lower East Side',
        keywords: 'Vegan, Vodka, Mexican, Coffee, Colombian, Spicy, Bbq, American, Homemade, Latin',
        annual_dol: 281.71,
        description:
            'Casual restaurant serving 40+ kinds of empanadas in a narrow, colorful dining room until late night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7722,
        store_longitude: -73.9915,
        total_orders: 33.45,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Tacos, Mexican, Beer, Vegetarian, Margarita, Wine, American, Contemporary, Artisanal, Casual',
        annual_dol: 136.72,
        description:
            'Contemporary Mexican hot spot turning out innovative tacos & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7392,
        store_longitude: -74.0017,
        total_orders: 445.69,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tequila, Luxurious, Comfortable, Relaxed, Perfect, Premium, Cool, Hip, High-End',
        annual_dol: 1821.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -74.0054,
        total_orders: 1492.83,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Luxurious, Hotel, Fancy, Fun, Premium, Great, Casual',
        annual_dol: 5624.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8638,
        store_longitude: -73.925,
        total_orders: 20.99,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Burger, Whiskey, American, Hotel, Casual, Fantastic, Great, Incredible, Creative',
        annual_dol: 85.82,
        description: '"Whiskey & Burger Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.741,
        store_longitude: -74.0018,
        total_orders: 160.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Delicious, Rich, Salad, Quaint, Sweet, Memorable, Iconic, Great, Fresh',
        annual_dol: 654.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7081,
        store_longitude: -74.0013,
        total_orders: 1930.95,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tasty, Coffee, Scotch, Dessert, Innovative, Classic, Excellent, Casual, Local',
        annual_dol: 2502.11,
        description:
            'Intimate, narrow eatery serving breakfast by day, plus spirits & innovative cocktails by night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6632,
        total_orders: 677.68,
        neighborhood: '',
        keywords: 'Steak, Salad, Tasty, Italian, Pizza, Wine, Delicious, Yummy, Homemade, Fun',
        annual_dol: 873.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -73.7897,
        total_orders: 2598.1,
        neighborhood: 'Fresh Meadows/Utopia/Flushing',
        keywords: 'Steak, Sushi, Salad, Tasty, Japanese, Fresh, Sashimi, Asian, Classic, Relaxed',
        annual_dol: 2956.51,
        description:
            'Relaxed outpost featuring chefs executing familiar hibachi fare, plus classic Asian menu & rolls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7093,
        store_longitude: -74.0057,
        total_orders: 242.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Vegan, Vegetarian, Pizza, Salad, Italian, Ale, American, Homemade, Casual',
        annual_dol: 283.24,
        description:
            'Neapolitan wood-fired pizzas with vegan & gluten-free options doled out in a shoebox-sized space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6126,
        store_longitude: -74.1305,
        total_orders: 30.25,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Pizza, Burger, Italian, Salad, Meatball, Vodka, Beer, Wine, Dessert, American',
        annual_dol: 123.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7107,
        store_longitude: -73.9687,
        total_orders: 1222.05,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Vodka, Wine, Italian, Salad, Meatball, Spectacular, Sweet, Intimate, Memorable, Perfect',
        annual_dol: 1247.98,
        description:
            'Traditional Italian fare offered in a formal setting with panoramic Manhattan skyline views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7433,
        store_longitude: -73.9796,
        total_orders: 2958.35,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Hamburger, Salad, Tequila, Gluten Free, Beer, Wine, Dessert, Upscale, French',
        annual_dol: 3021.13,
        description:
            'Set in a 1920s bank building, this stylish lounge serves elevated bar food & custom cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -73.9556,
        total_orders: 2270.08,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Salad, Beer, Upscale, Rustic, Juicy, Delicious, Casual, Classy',
        annual_dol: 2441.52,
        description:
            'A schoolhouse-styled front room & a dark lounge offer distinct spaces for American food & drink.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.9617,
        total_orders: 477.96,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Wine, Dessert, Salad, Avocado Toast, French Toast, French, Cozy, Mediterranean, Rum, Awesome',
        annual_dol: 742.47,
        description:
            'Oysters, cocktails & small plates in a New Orleans-styled setting with an atmospheric garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7267,
        store_longitude: -73.9575,
        total_orders: 88.89,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Gourmet, Ale, Fun, Bright, Perfect, Great, Excellent, Cool',
        annual_dol: 363.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6827,
        store_longitude: -73.9934,
        total_orders: 850.97,
        neighborhood: 'Carroll Gardens',
        keywords: 'Burger, Hotdog, Cornbread, Steak, Beer, Bbq, Spicy, Upscale, Cozy, Classy',
        annual_dol: 890.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6798,
        store_longitude: -73.9888,
        total_orders: 1869.55,
        neighborhood: 'Gowanus',
        keywords:
            'Modern, Stylish, Rooftop, Contemporary, Perfect, Retro, Classic, Chic, Wonderful, Unique',
        annual_dol: 2334.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5869,
        store_longitude: -73.7154,
        total_orders: 131.58,
        neighborhood: '',
        keywords: 'Beer, Local, Fun, Chic',
        annual_dol: 171.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -73.9863,
        total_orders: 3436.36,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Martini, Fun, Friendly, Cool, Great, Casual, Hip',
        annual_dol: 3540.32,
        description:
            'Nightclub chain featuring martinis, DJs & dancing in a retro-salon setting. Most offer manicures.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7386,
        store_longitude: -73.9888,
        total_orders: 2719.91,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tasty, Salad, Butter Chicken, Wine, Upscale, Delicious, Elegant, Trendy, Gin, Modern',
        annual_dol: 6047.84,
        description:
            'Trendy restaurant spotlighting reimagined Indian dishes & desserts, plus cocktails, wine & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5993,
        store_longitude: -73.9662,
        total_orders: 11329.16,
        neighborhood: 'Gravesend',
        keywords: 'Luxurious, Dessert',
        annual_dol: 12057.42,
        description:
            'Congregation Shaare Zion is an Orthodox Sephardic synagogue located at 2030 Ocean Parkway in Brooklyn, New York. Shaare Zion typically has an estimated 1,500 worshipers who attend its services Fridays and Saturdays for Shabbat making it one of the largest Sephardic synagogues in North America.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5889,
        store_longitude: -73.974,
        total_orders: 29.22,
        neighborhood: 'Gravesend',
        keywords: 'Vodka, Tasty, Pizza, Gin, Salad, Rum, Juicy, Premium, Sweet, Perfect',
        annual_dol: 31.84,
        description:
            'Old-school alley offering 32 lanes, cosmic bowling & weekly specials, plus a snack counter & bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6051,
        store_longitude: -73.9803,
        total_orders: 2142.81,
        neighborhood: 'Gravesend',
        keywords:
            'Burger, Steak, Kebab, Pizza, Salad, Turkish, Mediterranean, Wine, Australian, Casual',
        annual_dol: 6080.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7352,
        store_longitude: -73.9827,
        total_orders: 217.93,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Pizza, Beer, Wine, Delicious, Elegant, Upscale, Friendly, Sweet, Fun, American',
        annual_dol: 890.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5472,
        store_longitude: -74.1575,
        total_orders: 185.08,
        neighborhood: 'Great Kills',
        keywords:
            'Pizza, Vegetarian, Italian, Gluten Free, Delicious, Organic, Creamy, Polished, Homemade, Sweet',
        annual_dol: 259.29,
        description:
            'Pasta, brick-oven pizza & family-style dinners served in a roomy space with villalike decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5926,
        store_longitude: -74.068,
        total_orders: 157.93,
        neighborhood: 'Arrochar',
        keywords:
            'Salad, Steak, Lobster, Italian, Coffee, Savory, Mediterranean, Caesar Salad, Turkish, Russian',
        annual_dol: 193.04,
        description:
            'Festive, opulently decorated restaurant serving Russian & Mediterranean cuisine, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5858,
        store_longitude: -73.9516,
        total_orders: 2801.35,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Steak, Hamburger, Chicken Parmigiana, Cheesesteak, Roast Beef, Italian, Wine, Nachos, Austrian, Coffee',
        annual_dol: 2940,
        description:
            'Unassuming neighborhood joint offering steaks, burgers, pasta & other standards in casual surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8863,
        store_longitude: -73.9072,
        total_orders: 1514.9,
        neighborhood: 'Riverdale/West Bronx',
        keywords:
            'Steak, Sushi, Pizza, Wine, Latin, Swiss, American, Polished, Phenomenal, Caribbean',
        annual_dol: 1580.58,
        description: '"Latin American Bar / Restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9775,
        total_orders: 255.62,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster Roll, Lobster, Salad, Hamburger, Italian, Hotel, Organic, Rustic, Spacious, Casual',
        annual_dol: 1044.88,
        description:
            'Seafood restaurant serving Maine-style lobster rolls with toasted buns, plus clam chowder.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.9708,
        total_orders: 6126.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Vegetarian, Japanese, Dessert, Bbq, Wine, Beer, Delicious, Bellini, Elegant',
        annual_dol: 6682.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9694,
        total_orders: 493.68,
        neighborhood: 'Lenox Hill',
        keywords:
            'Whiskey Sour, Whiskey, New-York-Style, Elegant, Sweet, Friendly, Classic, Casual, Great',
        annual_dol: 2018,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9684,
        total_orders: 905.46,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Dessert, Wine, Trendy, Japanese, Warm, Cozy, Asian, Classy',
        annual_dol: 924.67,
        description:
            'Contemporary Japanese BBQ featuring table grills for various meats, plus sake & specialty cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9622,
        total_orders: 171.84,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Steak, Kebab, Salad, Tasty, Wine, Gluten Free, Greek, Mediterranean, American, Homemade',
        annual_dol: 191.19,
        description:
            'Corner spot serving small & large plates of traditional Greek meat, seafood & vegetable dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9679,
        total_orders: 529.77,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegan, Tacos, Vegetarian, Mexican, Beer, Margarita, Delicious, Friendly, Shawarma, Casual',
        annual_dol: 648.95,
        description:
            'Bi-level haunt for creative tacos with house-pressed tortillas plus mezcal margaritas & flights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.9741,
        total_orders: 1245.4,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Vegetarian, Whiskey, Salad, Wine, Beer, Italian, Mimosa, Delicious',
        annual_dol: 1299.39,
        description:
            'Traditional American lunch restaurant in the Trump Tower also offering Sunday brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7261,
        store_longitude: -73.8773,
        total_orders: 212.92,
        neighborhood: 'Middle Village/Flushing',
        keywords: 'Burger, Pizza, Beer, Italian, Lively, Fresh, Caribbean, Great, Local, Casual',
        annual_dol: 226.58,
        description:
            '"We serve great food and specialty cocktails! Our beer menu is amazing! If you’re in or near Queens or staying near LaGuardia or JFK come on down for pub style burgers, chicken wings, steaks, salads, ice cold beer and a whole lot more. And if you don’t feel like sitting inside, you can enjoy our outdoor beer garden!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.9686,
        total_orders: 21.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tequila, Mexican, Beer, Flavorful, Nachos, Bbq, Homemade, Fun, Vibrant, Artisanal',
        annual_dol: 86.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7463,
        store_longitude: -73.9908,
        total_orders: 6326.78,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Italian, Coffee, Cozy, Karaoke, Spectacular, Modern, Contemporary, Sophisticated, Fun',
        annual_dol: 6461.02,
        description:
            "restaurants and karaoke bars in Korea Town.Bright rooms with an industrial-chic vibe feature free Wi-Fi and flat-screen TVs. Some have bunk beds, and/or floor-to-ceiling windows with city views. Suites add separate living rooms.There's a hip cafe, a sophisticated Italian restaurant and a bar. A swanky cocktail lounge on the 35th floor has panoramic city views. Other amenities include meeting space and a 24-hour gym.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6917,
        store_longitude: -73.9845,
        total_orders: 3778.04,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Lobster, Rooftop, Steak, Shrimp Cocktail, Tacos, Hotel, Beer, Japanese, Salad, Margarita',
        annual_dol: 3957.22,
        description:
            'Minimalist-chic rooftop lounge offering Asian-inspired bar eats, cocktails & beer, plus city views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7584,
        store_longitude: -73.9929,
        total_orders: 501.08,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Italian, Comfortable, Fun, Great, Clean',
        annual_dol: 1754.94,
        description:
            "Square and 14 minutes on foot from events at storied Madison Square Garden.Snug, streamlined rooms provide free Wi-Fi and flat-screen TVs, as well as desks and safes. Some rooms feature bunk beds; apartments have kitchens and separate living rooms.There's a laid-back tiki bar/restaurant and a terrace.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.9838,
        total_orders: 5167.6,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Shrimp Cocktail, Salad, Beer, Wine, Upscale, Irish, Delicious, American',
        annual_dol: 5294.54,
        description:
            'Multilevel Irish-style pub with numerous beers on tap, daily specials & an outdoor rooftop bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5096,
        store_longitude: -74.2471,
        total_orders: 31.4,
        neighborhood: 'Tottenville',
        keywords: 'Steak, Rich, Sweet, Casual',
        annual_dol: 128.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -74.0071,
        total_orders: 115.62,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Italian, Pizza, Beer, Polish, Hotel, Dominican, Chinese, Luxurious, Playful, Polished',
        annual_dol: 472.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7176,
        store_longitude: -73.7352,
        total_orders: 3281.16,
        neighborhood: 'Jamaica/Queens Village',
        keywords:
            'Salad, Vegan, Vegetarian, Mimosa, Champagne, Elegant, Sweet, Friendly, Sangria, Fun',
        annual_dol: 3742.73,
        description:
            "\"Historic Antun's is a leading Event Venue, Planning, and Catering Service in Queens, NY, that provides beautiful event venues and the highest quality event catering services in Queens and Brooklyn. Our specialties are wedding receptions, Sweet 16 parties, baby showers, fundraisers, bridal showers, and many types of corporate events. A few of the benefits you'll love by hosting your special event at Antun's include a free on-site event coordinator, six elegant banquet halls for 60 to 800 guests, we treat you like family with friendly 5-star service, our great good and extensive menu choices, and free onsite parking for you and your guests.\"",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7734,
        store_longitude: -73.9911,
        total_orders: 254.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Luxurious, Wine, Glamorous, Italian, Bellini, Sleek, Sophisticated, Pizza, Fantastic, Classic',
        annual_dol: 1039.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6225,
        store_longitude: -73.965,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Burger, Sushi, Tacos, Spicy, Ale, Clean, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7352,
        store_longitude: -74.0066,
        total_orders: 741.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Burger, Salad, Champagne, Wine, Upscale, Delicious, Warm, Avocado Toast, Rustic',
        annual_dol: 948.55,
        description:
            "Ingredients from farmer's markets appear in refined New American preparations at this airy eatery.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7324,
        store_longitude: -73.6385,
        total_orders: 290.55,
        neighborhood: '',
        keywords:
            'Cornbread, Flavorful, Gluten Free, Beer, Wine, Italian, German, Bbq, Korean, American',
        annual_dol: 303.02,
        description:
            'Slow-cooked BBQ & street food, plus draft beer & cocktails, served in bustling, informal surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7844,
        store_longitude: -73.9776,
        total_orders: 1245.48,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Vegan, Vegetarian, Salad, Wine, Coffee, Dessert, Martini, Peruvian, Beer, Stylish',
        annual_dol: 4066.21,
        description:
            'Rice dishes, noodles & soups, plus cocktails & wine presented in a stylish setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7894,
        store_longitude: -73.974,
        total_orders: 490.06,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Ale, Welcoming, Clean, Perfect, Irish, Great, Casual',
        annual_dol: 1124.7,
        description:
            '"A Welcoming Upper West Side Pub serving Craft Beer, Cocktails, Wines & Light Bites."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7079,
        store_longitude: -73.8979,
        total_orders: 85.31,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Tacos, Mexican, Tasty, Steak, Beer, Wine, Delicious, Shrimp Cocktail, Fresh, Tex-Mex',
        annual_dol: 111.72,
        description:
            'Casual Mexican restaurant specializing in classics like tacos, quesadillas & burritos.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6558,
        store_longitude: -73.5499,
        total_orders: 10.35,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Warm, Casual, Healthy, Classic',
        annual_dol: 164.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -73.9522,
        total_orders: 1231.3,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Steak, Sushi, Japanese, Spicy, Sashimi, Ramen, Ribeye Steak, Asian, Cozy, Contemporary',
        annual_dol: 1257.42,
        description:
            'Cozy Japanese eatery with rustic-chic decor offering a contemporary menu of sushi, yakitori & ramen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6886,
        store_longitude: -73.9419,
        total_orders: 185.51,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Wine, Delicious, Beer, Fun, Sweet, Friendly, Clean, Awesome, Classy, Cool',
        annual_dol: 758.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9549,
        total_orders: 339.8,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Pizza, Salad, Mediterranean, Organic, Elegant, Premium, Sophisticated, Clam Chowder, Fresh, Hummus',
        annual_dol: 369.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7031,
        store_longitude: -73.9334,
        total_orders: 127.14,
        neighborhood: 'East Williamsburg',
        keywords:
            'Hamburger, Salad, Coffee, Beer, Upscale, American, Asian, Delicious, Friendly, Hip',
        annual_dol: 332.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.647,
        store_longitude: -73.791,
        total_orders: 248.25,
        neighborhood: 'Rosedale',
        keywords: 'Coffee, Pizza, Martini, Hotel, French, Modern, Sleek, Casual, Cool, Rooftop',
        annual_dol: 412.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7342,
        store_longitude: -73.9939,
        total_orders: 729.36,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, Stylish, Warm, Salad, Gin, Comfortable, Sweet, Relaxed, Korean, Polished',
        annual_dol: 778.51,
        description:
            'A West Village fixture still serving standout New American plates in a stylish yet relaxed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5878,
        store_longitude: -73.9545,
        total_orders: 194.27,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Salad, Coffee, Vodka, French, Hotel, Casual, Local',
        annual_dol: 794.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7537,
        store_longitude: -73.9782,
        total_orders: 792.39,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Pizza, Japanese, Wine, Serene, Authentic, Intimate, Casual, Impressive, Iconic',
        annual_dol: 3239.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9694,
        total_orders: 1196.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Sushi, Japanese, Sashimi, Spicy, Ramen, Delicious, Modern, Fusion, Teriyaki',
        annual_dol: 4890.84,
        description:
            'Unassuming restaurant furnishing everyday Japanese eats such as sushi, tempura & gyoza.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7155,
        store_longitude: -74.009,
        total_orders: 2050.55,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Stylish, Hotel, Clean, Perfect, Vibrant, Lively, Vintage, Great, Italian, Contemporary',
        annual_dol: 2094.05,
        description:
            "The cozy, stylish rooms, some with exposed brick walls, have flat-screen TVs and free Wi-Fi. A family suite sleeps up to 5 people. Room service is offered.Free walking tours are offered. There's a lively Italian eatery with original artwork, along with a 24-hour fitness room. Valet parking is available for a surcharge.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7573,
        store_longitude: -73.9682,
        total_orders: 76.88,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Beer, Wine, Fun, Casual, American, Amazing, Great, Diverse, Unique',
        annual_dol: 139.55,
        description:
            'Graffiti covers most surfaces in this funky, low-lit watering hole with a punk-rock vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9947,
        total_orders: 266.12,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords: 'Burger, Steak, Vegetarian, Cajun, Gourmet, Beer, Wine, Spicy, Bbq, Elegant',
        annual_dol: 283.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6801,
        store_longitude: -73.9466,
        total_orders: 840.77,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Hamburger, American, Casual, Traditional, Great',
        annual_dol: 996.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7849,
        store_longitude: -73.9557,
        total_orders: 1315.33,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Lobster, Lobster Roll, Burger, Vegetarian, Salad, Tomato Soup, Mimosa, Italian, Margarita, Coffee',
        annual_dol: 1343.24,
        description:
            'A neighborhood spot serving seafood & American-style meats & pastas, with a yacht-club style decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9881,
        total_orders: 1320.22,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Wine, Beer, Stylish, Vodka, Elegant, Moscow Mule, Rum, Sleek, Urban',
        annual_dol: 3077.76,
        description:
            "on foot from Times Square.Featuring floor-to-ceiling windows and New York-inspired murals, the 647 bright rooms come with Wi-Fi, flat-screen TVs and minifridges. Upgraded rooms include pull-out sofas, while suites add whirlpool tubs; some have separate living areas. Club-level quarters offer access to a private lounge with free drinks and snacks.There's a sleek restaurant, a bar and a casual breakfast eatery, as well as a 24-hour gym.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6893,
        store_longitude: -73.9549,
        total_orders: 2120.56,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tacos, Mexican, Beer, Vegetarian, Vodka, Tasty, Filipino, Painkiller, Tex-Mex, American',
        annual_dol: 2275.28,
        description:
            'Filipino food goes Tex-Mex at this eatery serving tacos & colorful fishbowl drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6223,
        store_longitude: -74.1698,
        total_orders: 45.52,
        neighborhood: '',
        keywords:
            'Steak, Burger, Cheesesteak, Chicken Parmigiana, Crisp, Bbq, Fresh, Pot Roast, French, Philly Cheesesteak',
        annual_dol: 186.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6865,
        store_longitude: -73.9444,
        total_orders: 597.56,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Pizza, Italian, Beer, Wine, Spicy, Delicious, American, Classy, Negroni, Intimate',
        annual_dol: 862.24,
        description:
            'Intimate eatery with a backyard serving pinsa, Roman-style pizza, plus Roman antipasti & Negronis.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9907,
        total_orders: 107.32,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Ale, American, Gourmet, Cozy, Intimate, Casual, Great, Signature',
        annual_dol: 116.51,
        description:
            '"Innovative cocktail bar with small plates located in an intimate environment in the heart of Hell’s Kitchen."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7273,
        store_longitude: -74.0002,
        total_orders: 495.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chocolate Cake, Burger, Salad, Champagne, Tequila, Wine, Dessert, Beer, Avocado Toast, French Toast',
        annual_dol: 517.14,
        description:
            'A casual space sets the scene for American meat & seafood staples, plus a busy weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9096,
        total_orders: 139.72,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Beer, Hotdog, Mexican, Burger, Margarita, Salad, Wine, Cuban, Latin, Colombian',
        annual_dol: 155.68,
        description: '"Authentic Home-style Latin Fusion"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.985,
        total_orders: 16223.05,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Mexican, Tequila, Tacos, Margarita, Gluten Free, Upscale, American, Classy, Healthy, Latin',
        annual_dol: 21326.77,
        description:
            'Modern Mexican dishes with a healthy helping of tradition served in a festive atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.814,
        store_longitude: -73.9599,
        total_orders: 513.72,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Garlic Bread, Wine, Vegetarian, Vodka, Italian, Coffee, Beer, Nutritious, Delicious, Homemade',
        annual_dol: 741.1,
        description:
            'Basic Italian fare in a homey, semi-subterranean space with live jazz on Sundays.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7716,
        store_longitude: -73.9939,
        total_orders: 291.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Elegant, Beer, Luxurious, Martini, Comfortable, Clean, Great, Excellent, Fresh',
        annual_dol: 1190.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.977,
        total_orders: 1472.68,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Georgian, Homey, Classic, Seasonal, Fun, Intimate, Great',
        annual_dol: 1572.07,
        description:
            "The Women's National Republican Club is the oldest private club for Republican women in the United States, and was founded by Henrietta Wells Livermore in 1921.The club grew out of the earlier women's suffrage movement in New York which led to the Nineteenth Amendment.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7647,
        store_longitude: -73.9778,
        total_orders: 206.1,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Pizza, Italian, Flavorful, Tasty, Wine, Dessert, Homemade, Bbq, Sweet, Fresh',
        annual_dol: 215.04,
        description:
            'Family-friendly pizzeria serving thin-crust pies alongside calzones, pastas & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9561,
        total_orders: 84.47,
        neighborhood: 'Roosevelt Island',
        keywords: 'Pizza, Salad, Wine, Beer, Delicious, Cozy, Friendly, Modern, Premium, Casual',
        annual_dol: 345.29,
        description:
            'Cornell Tech is a technology, business, law, and design campus of Cornell University located on Roosevelt Island in Manhattan, New York City. It includes the Jacobs Technion-Cornell Institute, a joint academic venture between Cornell and the Technion – Israel Institute of Technology.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7327,
        store_longitude: -73.9978,
        total_orders: 874.54,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Upscale, Steak, Romantic, Hotel, Spicy, Italian, Friendly, Mediterranean, Eclectic',
        annual_dol: 907.01,
        description:
            'An extensive list of wines by the bottle & by the glass are available at this Village refuge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -73.9966,
        total_orders: 2773.23,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Burger, Hotel, Coffee, Meatball, Luxurious, Mediterranean, Stylish, Premium, French',
        annual_dol: 3067.09,
        description:
            "and a 5-minute walk from a subway station.Compact, refined rooms feature ornate moldings, stylish designer furniture and wood floors. They come with flat-screen TVs and Wi-Fi, along with minibars and marble bathrooms. A suite adds a separate living area and a terrace.Breakfast is available. There's a posh Mediterranean restaurant, a polished cocktail bar and a hip cafe.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7421,
        store_longitude: -74.0037,
        total_orders: 4051.17,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Steak, Rich, Upscale, Chinese, Romantic, Perfect, Modern, Classic, Casual',
        annual_dol: 4461.66,
        description:
            'Modern takes on classic cocktails are served in a 1920s-inspired space with DJ booths & chandeliers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 22861.85,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Mexican, Wine, Delicious, Glamorous, Japanese, Chinese, Elegant, Peking Duck, Thai',
        annual_dol: 23737.39,
        description:
            'Huge, lavishly decorated restaurant offering a menu of Asian dishes & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6871,
        store_longitude: -73.9784,
        total_orders: 547.51,
        neighborhood: 'Fort Greene',
        keywords:
            'Italian, Pizza, Wine, Coffee, Stylish, Homemade, Hotel, Warm, Handcrafted, Friendly',
        annual_dol: 1229.6,
        description:
            'Easygoing restaurant with a stylish ambiance dishing up pizza & pasta, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9808,
        total_orders: 4138.57,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Tacos, Beer, Delicious, Cozy, Homey, Friendly, Intimate, Premium, Cool, Retro',
        annual_dol: 4318.01,
        description:
            'Resembling a posh, retro apartment, this bar serves seasonal cocktails, wines & canned beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.714,
        store_longitude: -73.9485,
        total_orders: 481.13,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Tacos, Salad, Beer, Bbq, American, Southern, Casual, Friendly',
        annual_dol: 524.36,
        description:
            'Resto-bar with a dark-wood interior & patio for Southern dishes, burgers & craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -74.0075,
        total_orders: 296.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Tequila, Tacos, Mexican, Gluten Free, Delicious, Fun, Breakfast Burrito, Awesome, Friendly, American',
        annual_dol: 1253.4,
        description:
            '"Mezcali is the brainchild of two best friends from Southern California who grew up skateboarding and satisfying their afternoon hunger pangs with Cali-Mex food. Some of our best memories were shared over a plate of carne asada fries and a couple shots of mezcal. We built Mezcali in homage to our experiences growing up in South Cali. While we can’t recreate Los Angeles in Downtown New York, we have done our best to bring you a slice of our love for everything Cali-Mex, but in the heart of Manhattan."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7249,
        store_longitude: -73.9785,
        total_orders: 255.25,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Hotel, Classic, Friendly, Great, American, Homemade, Mediterranean, Creative, Laid-Back',
        annual_dol: 833.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -73.9565,
        total_orders: 392.61,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Coffee, Argentinian, Italian, Peruvian, Rum, American, Four Horsemen, Spanish, French',
        annual_dol: 493.49,
        description:
            '"Lamonte is located in Brooklyn, New York, right off the L train, at the Bedford stop, open 7 days a week, from early coffee till late night cocktails. Cafe, Restaurant, Bar."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.631,
        store_longitude: -74.1033,
        total_orders: 1441.13,
        neighborhood: 'Mid Island/West Brighton',
        keywords: 'Burger, Lobster, Lobster Roll, Salad, Mexican, Beer, Tasty, Wine, Zesty, Irish',
        annual_dol: 1471.71,
        description:
            'Creatively sauced wings highlight the elevated American pub-grub menu at this laid-back sports bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7129,
        store_longitude: -73.9578,
        total_orders: 120.28,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Wine, Italian, Delicious, Bourbon, Friendly, Southern, Sleek, Casual, Fresh, Excellent',
        annual_dol: 453.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7507,
        store_longitude: -73.9817,
        total_orders: 777.72,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Tacos, Mexican, Salad, Vegetarian, Tequila, Upscale, Casual, Homemade, Delicious, Papaya Salad',
        annual_dol: 1023.15,
        description:
            'Convivial hangout dishing up finger foods, tacos & burritos, plus margaritas & a tequila list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7483,
        store_longitude: -73.9767,
        total_orders: 819.61,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Tacos, Mexican, Steak, Salad, Margarita, Beer, Nachos, Chili Con Carne, Savory, Tex-Mex',
        annual_dol: 819.61,
        description:
            'Margaritas & Tex-Mex fare fuel an often bustling scene at this joint with seasonal outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7205,
        store_longitude: -73.9551,
        total_orders: 2060.71,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Margarita, Ale, Classic, Local, Great, Casual',
        annual_dol: 2224.11,
        description: 'Unassuming bar for beer & pitchers of cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7504,
        store_longitude: -73.9817,
        total_orders: 872.09,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Dessert, Chinese, Juicy, Spicy, Yummy, Classy, Wonton Soup, Casual, Local, Great',
        annual_dol: 977.51,
        description:
            'Chinese spot offering a Shanghai-influenced menu with dumplings, noodles, pork buns & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7163,
        store_longitude: -73.96,
        total_orders: 130.27,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Tacos, Burritos, Organic, Healthy, Delicious, Casual, Local',
        annual_dol: 655.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7026,
        store_longitude: -73.913,
        total_orders: 258.89,
        neighborhood: 'Bushwick',
        keywords:
            'Coffee, Cozy, Spacious, Chinese, Perfect, Cool, Great, Local, Unpretentious, Unique',
        annual_dol: 551.8,
        description: '"Cafe/Bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -74.0068,
        total_orders: 1327.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Wine, Champagne, Delicious, Cozy, Rustic, Perfect, French, Friendly',
        annual_dol: 1400.72,
        description:
            'Warmly lit bar & American eatery with a brief menu of oysters, steaks & other hearty fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6476,
        store_longitude: -73.6053,
        total_orders: 3680.43,
        neighborhood: '',
        keywords:
            'Pizza, Vegetarian, Salad, Upscale, American, Exquisite, Fabulous, Picturesque, Outstanding, Rooftop',
        annual_dol: 3855.99,
        description:
            'Upscale country-style weddings & events place next to a lake offering multiple spaces & menus.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8112,
        store_longitude: -73.8353,
        total_orders: 765.17,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Burger, Salad, Fun, Spectacular, Exceptional, Great, Handcrafted, Cool, Iconic, Seasonal',
        annual_dol: 868.77,
        description:
            '"NYC’s only tournament-quality course takes advantage of dramatic and spectacular views of the Manhattan skyline, East River, and Whitestone & Throggs Neck Bridges. It was handcrafted at every angle and exceeds the vision that was outlined by not only Donald Trump, but by Jack Nicklaus, the City of New York and every golf rater that has visited the property since it has grown in. Centrally located just minutes from midtown Manhattan, Long Island, Connecticut, Westchester, and New Jersey, Trump Golf Links at Ferry Point is also NYC’s newest waterfront event venue offering breathtaking 360 degree views of the iconic NYC skyline, Whitestone & Throgs Neck bridges. This unique venue is exactly what you’ve been looking for."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7834,
        store_longitude: -73.978,
        total_orders: 719.04,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Beer, Bourbon, Clean, Hotel, Fun, Great, Casual',
        annual_dol: 2524.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.847,
        store_longitude: -73.8318,
        total_orders: 5.36,
        neighborhood: 'Pelham Bay',
        keywords: 'Burger, Steak, Vegan, Pizza, Italian, French, American, Healthy, Zesty, Casual',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7035,
        store_longitude: -73.6347,
        total_orders: 1186.22,
        neighborhood: '',
        keywords: 'Golfclub, Local, Diverse',
        annual_dol: 1319.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6965,
        store_longitude: -73.9039,
        total_orders: 1594.66,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Ale, Mimosa, Relaxed, Spacious, Natural, Local, Casual, Amazing, Unique',
        annual_dol: 7491.39,
        description:
            'Gathering place offering local brews & bites in a relaxed setting with a beer garden & greenhouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -73.9943,
        total_orders: 412.52,
        neighborhood: 'Nolita',
        keywords: 'Steak, Salad, Tasty, Thai, Organic, Spicy, Funky, Asian, American, Mojito',
        annual_dol: 518.52,
        description:
            'Budget-friendly Thai fare & drinks fuel a happening crowd at this hip spot with red-leather booths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7267,
        store_longitude: -74.0024,
        total_orders: 489.65,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, French, Italian, Cozy, Stylish, Modern, Playful, American, Awesome, Traditional',
        annual_dol: 558.54,
        description:
            'French fare gets a creative, modern makeover at this stylish bistro with floor-to-ceiling windows.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7186,
        store_longitude: -73.9904,
        total_orders: 453.44,
        neighborhood: 'Lower East Side',
        keywords:
            'Chinese, Tasty, Spicy, Sushi, Beef Chow Fun, Delicious, Congee, Spacious, Fried Rice, Fun',
        annual_dol: 483.24,
        description:
            'Colorful group-friendly spot serving the namesake rice porridge plus a huge menu of Cantonese faves.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.9895,
        total_orders: 6462.28,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Burger, French Onion Soup, French, Upscale, Hotel, Luxurious, Artisanal, Casual, Local',
        annual_dol: 6742.47,
        description:
            'Upscale, exclusive basement lounge in a small basement space attracting a hip, in-the-know crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7202,
        store_longitude: -73.9893,
        total_orders: 3076.1,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Tacos, Wine, Filipino, Beer, Margarita, Japanese, Asian, American, Eclectic',
        annual_dol: 7523.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9758,
        total_orders: 3146.09,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Salad, Chinese, Spicy, Upscale, Elegant, Vintage, Refined, Polished, Traditional',
        annual_dol: 6176.28,
        description:
            'Refined Chinese restaurant serving dim sum, noodles, seafood & meat plates, plus unique cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9844,
        total_orders: 674.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Steak, Chicken Pot Pie, Gluten Free, Coffee, Irish, Delicious, French, American, Friendly',
        annual_dol: 2757.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6738,
        store_longitude: -73.8396,
        total_orders: 2.61,
        neighborhood: 'Ozone Park/Jamaica',
        keywords: 'Wine, Comfortable, Friendly, Fun, Chinese, Awesome, Cool, Great, Thai, French',
        annual_dol: 10.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.686,
        store_longitude: -73.9943,
        total_orders: 0,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Vegetarian, Italian, Chicken Tikka Masala, Beer, Tasty, Indian, Spicy, Casual, Local, Perfect',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7114,
        store_longitude: -73.7907,
        total_orders: 28.76,
        neighborhood: 'Jamaica',
        keywords: 'Lobster, Salad, Pizza, Bbq, Bbq Ribs, Caribbean, Fine',
        annual_dol: 117.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9956,
        total_orders: 1611.17,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Sushi, Luxurious, Chinese, Hotel, Delicious, Opulent, Japanese, Beer, Premium, Intimate',
        annual_dol: 6756.42,
        description:
            '"Located in the luxurious Chelsea area of Manhattan, Sushi By Bou Chelsea is a modern sushi speakeasy, boasting an intimate 9-seat omakase experience, hidden away behind a bespoke Espresso Bar and Floral Boutique. This high-end experience is timed, with 30-minute and 60-minute options available. Guests are invited to arrive early or stay late, and enjoy one of our craft cocktails, imported sakes, or Japanese beers, while admiring our luxe 1920\'s themed decor. Sushi By Bou Chelsea draws its design inspiration from The Roaring 20\'s, with opulent finishes, lavish surroundings, and classic speakeasy cocktails. Allow the gold detailing and crystal adornments to inspire a trip to a different era."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -74.0028,
        total_orders: 11.39,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Italian, Steak, Vodka, Beer, Dessert, Gin, Organic, Hotel, Bruschetta',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.714,
        store_longitude: -73.9488,
        total_orders: 1884.87,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Karaoke, Mimosa, Bloody Mary, Upscale, Exotic, Great, Chill, Casual',
        annual_dol: 1966.59,
        description:
            'Budget drinks come with free pizza as well as karaoke, pool & Skee-Ball at this no-frills bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7292,
        store_longitude: -73.981,
        total_orders: 2754.56,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Tacos, Tequila, Mexican, Burger, Steak, Beer, Margarita, Salad, Burritos, Fajitas',
        annual_dol: 2892.29,
        description:
            'Mexican cantina with standards like burritos & fajitas, plenty of tequila & a buzzy brunch scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -73.9777,
        total_orders: 1430.15,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Steak, Chicken Pot Pie, Vegetarian, Meatball, Mimosa, Gluten Free, Beer, Coffee, Ale',
        annual_dol: 1470.84,
        description:
            'Old-fashioned pub offering a wide variety of beers, plus lunch & dinner menus & a late bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7176,
        store_longitude: -73.9586,
        total_orders: 2909.58,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Tacos, Salad, Whiskey, Beer, Tasty, Ale, French, Creamy, American',
        annual_dol: 2971.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7659,
        store_longitude: -73.9695,
        total_orders: 5900.42,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Pizza, Wine, Champagne, Shrimp Cocktail, Spicy, Martini, Upscale, Premium, Woody, American',
        annual_dol: 6062.47,
        description:
            'Luxe cigar bar with leather chairs, food & cocktail menus & a strict dress code.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7081,
        store_longitude: -73.9401,
        total_orders: 2891.98,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Beer, Romantic, Warm, Quaint, French Toast, Karaoke, Rustic, French, Yummy, Homey',
        annual_dol: 3153.71,
        description:
            'Quaint, rustic pub with intricate wood decor offering microbrews, mixed drinks & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7276,
        store_longitude: -73.9544,
        total_orders: 225.85,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Casual, Irish, Polish, Great',
        annual_dol: 257.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8512,
        store_longitude: -73.7887,
        total_orders: 63078.85,
        neighborhood: 'City Island',
        keywords: 'Lobster, Pizza, Savory, Delicious, Nachos, Bbq, Fun, French, Awesome, American',
        annual_dol: 67856.86,
        description:
            'Spacious, family-friendly spot offering up arcade games, seafood dishes, drinks & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.681,
        store_longitude: -74.0089,
        total_orders: 256.07,
        neighborhood: 'Red Hook',
        keywords:
            'Tequila, Martini, Tasty, Cozy, Intimate, Friendly, Cool, Classic, Casual, Laid-Back',
        annual_dol: 291.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8536,
        store_longitude: -73.79,
        total_orders: 51.86,
        neighborhood: 'City Island',
        keywords: 'American, Local',
        annual_dol: 58.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.9893,
        total_orders: 13463.05,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Tequila, Champagne, Japanese, Vodka, Stylish, Beer, Hotel, Romantic, Rum, Exotic',
        annual_dol: 17553.37,
        description:
            'Speakeasy-style bar in the East Village offering exotic cocktails in a cool, hard-to-find space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7321,
        store_longitude: -73.9846,
        total_orders: 2701.96,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Beer, Margarita, Cozy, Comfortable, Perfect, Fun, Casual, Great, Unpretentious, Diverse',
        annual_dol: 2759.29,
        description: 'Low-ceilinged gay bar with a subterranean feel, diverse beer menu & jukebox.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7236,
        store_longitude: -73.9884,
        total_orders: 1170.21,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Pizza, Italian, Wine, Beer, Meatball, Quaint, Cozy, Healthy, Friendly, Casual',
        annual_dol: 1275.34,
        description:
            'Old-school joint offering wood-fired pizza & other casual Italian eats, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7343,
        store_longitude: -73.9946,
        total_orders: 139.11,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'American, Vintage, Elegant, Fine, Cultural',
        annual_dol: 568.64,
        description:
            'The Salmagundi Club, sometimes referred to as the Salmagundi Art Club, is a fine arts center founded in 1871 in the Greenwich Village section of Manhattan, New York City. Since 1917, it has been located at 47 Fifth Avenue. As of 2021, its membership roster totals roughly 1,100 members.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8059,
        store_longitude: -73.9657,
        total_orders: 561.6,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Vegetarian, Hamburger, Organic, Korean, American, Stylish, Sustainable, Casual, Seasonal',
        annual_dol: 573.52,
        description:
            'Cafe with healthy emphasis on organic New American fare plus vegetarian choices & a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8002,
        store_longitude: -73.9683,
        total_orders: 2571.06,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Sushi, Wine, Beer, Cozy, Friendly, Casual, Fancy, Classic',
        annual_dol: 2655.49,
        description:
            'This laid-back haunt with a pool table & dart board boasts a happy hour that lasts for 7 hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5233,
        store_longitude: -74.2347,
        total_orders: 128.52,
        neighborhood: 'Charleston',
        keywords:
            'Vegetarian, Italian, Turkish, Lebanese, Syrian, Mediterranean, Shawarma, French, Traditional, Russian',
        annual_dol: 525.35,
        description:
            'Spacious Syrian restaurant serving a traditional menu with halal & vegetarian options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.7677,
        total_orders: 111.78,
        neighborhood: 'Bayside',
        keywords:
            'Salad, Italian, Mediterranean, Bbq, Greek, Upscale, Homemade, Relaxed, Casual, Seasonal',
        annual_dol: 133.53,
        description:
            'Relaxed eatery with a seasonal patio & frequent live music, offering seafood & Greek fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.587,
        store_longitude: -73.9525,
        total_orders: 216.98,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Sushi, Martini, Japanese, Spicy, Upscale, Beer, Asian, Sashimi, Fusion, Sweet',
        annual_dol: 886.92,
        description:
            'Artful Japanese fusion & sushi by a Kyoto-trained chef served in a sleek, modern space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.701,
        store_longitude: -73.8312,
        total_orders: 10.35,
        neighborhood: 'Kew Gardens/Jamaica/Richmond Hill',
        keywords: 'Unique',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8019,
        store_longitude: -73.6528,
        total_orders: 1178.93,
        neighborhood: '',
        keywords:
            'Hotel, Coffee, Mexican, Flavorful, American, Seasonal, Quaint, Contemporary, Fresh, Great',
        annual_dol: 1270.08,
        description:
            'Quaint rooms with floral decor offer free Wi-Fi, plus coffeemakers, marble bathrooms and TVs with in-room movies. Suites add boldly painted accent walls, canopy beds and sitting areas with fireplaces.Perks include a free hot breakfast buffet and all-day coffee in the lobby, and self-parking. The hotel also has a gym, a business center and a ballroom with 14-foot ceilings, European-inspired architecture and hand-painted walls murals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6826,
        store_longitude: -73.8361,
        total_orders: 16.26,
        neighborhood: 'Ozone Park/Jamaica/South Richmond Hill',
        keywords: 'Salad, Beer, Spicy, Clean, Bbq, Casual, Spanish, Excellent, Great, American',
        annual_dol: 66.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7574,
        store_longitude: -73.9742,
        total_orders: 141,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Tacos, Burritos, Salad, Delicious, Local, Casual',
        annual_dol: 732.71,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -73.9872,
        total_orders: 5550.95,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Pizza, Italian, Meatball, Wine, Bread Pudding, Beer, Coffee, Vodka',
        annual_dol: 5556.24,
        description:
            'Locally sourced, upscale American cuisine served in a barn-inspired setting with a chef’s table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9953,
        total_orders: 721.78,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Cozy, Friendly, Irish, Casual, Local, Dutch, Great',
        annual_dol: 737.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.982,
        total_orders: 2612.94,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, Upscale, Cosmopolitan, Stylish, Hotel, Elegant, Tequila, Coffee, Spacious, Innovative',
        annual_dol: 9339.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7548,
        store_longitude: -73.9857,
        total_orders: 935.37,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Korean, Asian, Premium, Vibrant, Healthy, American, Amazing, Classic, Great, Unique',
        annual_dol: 3276,
        description:
            'Vibrant nightclub hosting DJs, live music performances & special events with a Korean focus.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6946,
        store_longitude: -73.9831,
        total_orders: 137.73,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Burger, Pizza, Chicken Parmigiana, Vegan, Salad, Italian, Gluten Free, Beer, Wine, Bruschetta',
        annual_dol: 150.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9893,
        total_orders: 4719.15,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Kebab, Meatball, Beer, Spicy, Delicious, Wine, Mediterranean, Organic, Turkish',
        annual_dol: 4913.12,
        description:
            'Bi-level bistro in a former flower shop offering modern Mediterranean fare & a hookah menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.9916,
        total_orders: 1797.44,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Pizza, American, Hotel, Fresh, Perfect, Iconic, Classic, Lit, Local',
        annual_dol: 1835.58,
        description:
            'Laid-back, late-night hangout offering craft beers on tap, bar snacks, pinball machines & pool.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7749,
        store_longitude: -73.9086,
        total_orders: 7329.6,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Sushi, Tasty, Beer, Wine, Smooth, Fried Chicken, Friendly, Innovative, Casual, Modern',
        annual_dol: 7590.33,
        description:
            'Snug pub pairing craft beer & cocktails with innovative bar bites, plus DJ nights & Sunday brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7049,
        store_longitude: -73.6438,
        total_orders: 129.89,
        neighborhood: '',
        keywords: 'Sushi, Clean, Premium, Cool, Great',
        annual_dol: 530.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -73.9892,
        total_orders: 1605.89,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Beer, Donut, Seasonal, American, Delicious, Local, Crafted, Wonderful, Great',
        annual_dol: 1639.96,
        description:
            "Chef Tom Colicchio's refined, farm-fresh American fare served in an elegantly understated space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7303,
        store_longitude: -73.9944,
        total_orders: 527.55,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Lobster, Lobster Roll, Shrimp Cocktail, Salad, Cajun, Tequila, Margarita, Beer, Tasty, Vodka',
        annual_dol: 608.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7383,
        store_longitude: -73.9896,
        total_orders: 2869.1,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Vegan, Vegetarian, Mexican, Margarita, Organic, Italian, Delicious, Spicy, Tex-Mex, French',
        annual_dol: 4393.53,
        description:
            '"abc Cocina & Michelin star Chef Jean-Georges Vongerichten welcome you to our modern global exchange celebrating local craft and international culture, a fusion of tradition and innovation uniting yesterday and tomorrow. Dedicated to whole food and inventive fare, our ever-changing offerings are sourced from boutique producers with an emphasis on holistic development and environmental consciousness."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7989,
        store_longitude: -73.9629,
        total_orders: 4653.17,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Beer, Cosmopolitan, Gin, Fun, Welcoming, Friendly, Comfortable, Timeless, Fantastic, Great',
        annual_dol: 4751.9,
        description:
            'Local brews & craft cocktails offered in an industrial-chic watering hole with a welcoming vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7511,
        store_longitude: -73.9727,
        total_orders: 3073.62,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Salad, Dessert, Wine, Japanese, Beer, Fried Chicken, Sashimi, Homemade, Cozy',
        annual_dol: 3583.97,
        description:
            'Japanese small plates & a deep sake list in a blond-wood izakaya hidden beneath an office building.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7542,
        store_longitude: -73.973,
        total_orders: 3528.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Hamburger, Beer, Wine, Irish, American, Modern, Cozy, Casual, Comfortable',
        annual_dol: 3776.24,
        description:
            'Roomy, Irish-style tavern with many draft beers & classic pub fare, plus brunch on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.968,
        total_orders: 959.67,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Cajun, Salad, French, Beer, Homemade, Contemporary, Caesar Salad, Casual, Friendly, Local',
        annual_dol: 1414.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9716,
        total_orders: 1994.41,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Wine, Gluten Free, Hotel, Elegant, Vibrant, Classic, Great, Rooftop, Casual',
        annual_dol: 2043.64,
        description:
            'Italian restaurant in the Kimberly Hotel serving classic fare including many gluten-free options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9694,
        total_orders: 3379.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Tacos, Steak, Peruvian, Wine, Tasty, Upscale, Martini, Fajitas, Delicious',
        annual_dol: 4388.21,
        description:
            '"Mexican Peruvian Fusion restaurant offering a creative menu, complementing fresh ingredients with flavors from around the world. Chef Arias eclectic yet harmonious cuisine brings a dynamic perspective to classic techniques such as Birria Tacos, Causa Rellena & Peruvian Ceviche."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7572,
        store_longitude: -73.9685,
        total_orders: 292.18,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Thai, Vietnamese, Beer, Chinese, Dessert, Spicy, Ramen, Bbq, New Zealand, Asian',
        annual_dol: 322.6,
        description:
            'Sleek destination turning out Asian fusion BBQ & small plates with creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6166,
        store_longitude: -74.0311,
        total_orders: 626.27,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Wine, French, Tasty, Ale, Beer, French Onion Soup, Caesar Salad',
        annual_dol: 650.73,
        description:
            'Old-fashioned steakhouse serves prime cuts, pasta & sandwiches in a classy space with a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7149,
        store_longitude: -74.0109,
        total_orders: 11.39,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Hotel, Elegant, French, Intimate, Sweet, Contemporary, Casual, Great, Terrific',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9879,
        total_orders: 631.1,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Pizza, Italian, Phenomenal, Vintage, Southern, Classic, Perfect, Exotic, Amazing',
        annual_dol: 1008.16,
        description:
            'The Nederlander Theatre is a Broadway theater at 208 West 41st Street in the Theater District of Midtown Manhattan in New York City. Opened in 1921, it was designed by William Neil Smith for theatrical operator Walter C. Jordan.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7842,
        store_longitude: -73.7123,
        total_orders: 1681.57,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Chocolate Cake, Shrimp Cocktail, Chicago-Style, Lobster Bisque, Meatball, Salad, Wine, Upscale',
        annual_dol: 1721.71,
        description:
            'Upscale chain for aged prime beef, seafood & other traditional steakhouse fare in a clubby space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7034,
        store_longitude: -73.9917,
        total_orders: 7253.11,
        neighborhood: 'Dumbo/Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Italian, Mediterranean, Rooftop, Cozy, American, Modern, Classic, Seasonal, Great, Incredible',
        annual_dol: 7510.06,
        description:
            '"Soho House\'s newest New York property, DUMBO House is located in Empire Stores on the Brooklyn waterfront and features panoramic views of the Brooklyn Bridge, East River and the Manhattan skyline. It has a full floor of club space with a library and restaurant, an outdoor terrace and a rooftop pool."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7501,
        store_longitude: -73.9862,
        total_orders: 1196.47,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Wine, Upscale, Rooftop, Fun, Beer, Colorful, Fantastic, Comfortable, Great',
        annual_dol: 1464.42,
        description:
            "The upscale rooms have marble bathrooms, flat-screen TVs, minifridges and free Wi-Fi. Upgraded rooms are set on higher floors and add workstations and coffeemakers. Room service is available.Free perks include access to a fitness center and continental breakfast. There's also a rooftop terrace with a seasonal bar, a lobby bar and cocktail lounge, and a colorful restaurant featuring milkshakes.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7508,
        store_longitude: -73.9897,
        total_orders: 740.41,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Beer, American, Wine, Delicious, Bbq, Modern, Perfect, Comfortable, Irish',
        annual_dol: 761.27,
        description:
            'Burgers, beer & other refreshments power this casual spot at the Herald Square department store.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7528,
        store_longitude: -74.0009,
        total_orders: 289.99,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Korean, American, Modern, Western, Casual, Great',
        annual_dol: 1185.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7532,
        store_longitude: -73.9967,
        total_orders: 803.82,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Irish, Cozy, Friendly, Classic, Local, Casual, Impressive, Great',
        annual_dol: 915.5,
        description:
            "Cozy, dark-wood Irish pub over 2 levels with a broad comfort-food menu, including shepherd's pie.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.778,
        store_longitude: -73.9805,
        total_orders: 1329.11,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Beer, Vintage, Fabulous, Classic, Amazing, Great, Perfect',
        annual_dol: 1412.67,
        description:
            "The Triad Theater, formerly known as Palsson's Supper Club, Steve McGraw's, and Stage 72, is a cabaret-style performing arts venue located on West 72nd Street on New York's Upper West Side.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.776,
        store_longitude: -73.9818,
        total_orders: 10.35,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Beer, Sushi, Salad, Martini, Coffee, Italian, Delicious, Seasonal, Tandoori Chicken',
        annual_dol: 42.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7307,
        store_longitude: -74.0001,
        total_orders: 2889.76,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cheesecake, Salad, Burger, Dessert, Wine, Champagne, Beer, Nachos, Funky, French',
        annual_dol: 2975.78,
        description:
            'House bands back up visiting performers at this nightlife outlet for live funk, soul & R&B.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7496,
        store_longitude: -73.9901,
        total_orders: 265.87,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Iconic, Premium',
        annual_dol: 502.13,
        description: '"Digital art museum, event venue and bar"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -73.999,
        total_orders: 1210.07,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Italian, Sushi, Cozy, Traditional, Classic, Authentic, Unforgettable, Fine',
        annual_dol: 1262.53,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7291,
        store_longitude: -73.9989,
        total_orders: 1286.03,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cuban, Steak, Coffee, Rum, Caribbean, Spanish, Delicious, Romantic, Swiss, Mojito',
        annual_dol: 1966.15,
        description:
            'Classic dishes such as roast pig & ropa vieja are boosted by live son music & hand-rolled cigars.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7291,
        store_longitude: -74.0003,
        total_orders: 23.5,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chocolate Cake, Pizza, Gluten Free, Steak, Italian, Dessert, Wine, Coffee, Savory, Beer',
        annual_dol: 44.05,
        description:
            'Gluten-free versions of classic Italian dishes offered in a warm, intimate atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 1999.7,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Sushi, Wine, Stylish, Japanese, Elegant, Asian, Sweet, Legendary, Friendly, Premium',
        annual_dol: 2042.13,
        description:
            'Japanese restaurant offering a high-end fixed-price sushi menu in modern, elegant surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.9848,
        total_orders: 8025.32,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Thai, Perfect, Greek, Awesome, Iconic, Great, Unforgettable',
        annual_dol: 10330.19,
        description:
            'The Gershwin Theatre is a Broadway theater at 222 West 51st Street, on the second floor of the Paramount Plaza office building, in the Midtown Manhattan neighborhood of New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.9769,
        total_orders: 3017.54,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Chocolate Cake, Shrimp Cocktail, Meatball, Spicy, Upscale, Legendary, American, Classic, Traditional',
        annual_dol: 12635.6,
        description:
            'Upscale former speakeasy serving classic American fare in an old New York setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7227,
        store_longitude: -73.9918,
        total_orders: 14499.55,
        neighborhood: 'Bowery',
        keywords:
            'Hotel, Trendy, Vodka, Peruvian, Sophisticated, Chic, Rooftop, Great, Authentic, Modern',
        annual_dol: 16156.34,
        description:
            'Minimalist rooms feature floor-to-ceiling windows and wood floors, and offer smart TVs, Wi-Fi, USB ports and Bluetooth speakers. Upgraded rooms have city views and pull-out sofas, while suites add separate living and dining areas.Freebies include wellness classes, cookies and drinks. There are 2 sophisticated restaurants by chef Jean-Georges Vongerichten, plus a trendy rooftop bar with skyline views, and a chic lobby lounge. There’s also a casual cafe and market, and dramatic event spaces.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7202,
        store_longitude: -73.9875,
        total_orders: 2324.61,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Steak, Cornbread, Pizza, Salad, Dessert, Upscale, American, Caesar Salad, Australian',
        annual_dol: 2448,
        description:
            'Hearty, New American comfort food in a vintage setting complete with a DJ & bustling bar scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7646,
        store_longitude: -73.9825,
        total_orders: 2.82,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Applejack, Cheesecake, Wine, Beer, French Toast, French, American, Delicious, Omelette, Fresh',
        annual_dol: 11.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7583,
        store_longitude: -73.9715,
        total_orders: 82.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Pizza, Delicious, French, Hotel, Rum, Modern, Contemporary, Serene, Classic',
        annual_dol: 335.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.643,
        store_longitude: -73.5744,
        total_orders: 5.09,
        neighborhood: '',
        keywords: 'Mexican, Beer, Wine, Spanish, Dessert, Dominican, Cozy, Sweet, Fun, Sangria',
        annual_dol: 20.81,
        description:
            '"Spanish Tapas Bar and Restaurant in Long Island. Specializing in paellas, tapas, and charcuterie boards accompanied with an extensive wine and cocktail list. Outdoor seating are with an outdoor bar located on a marina. Perfect for summer"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7426,
        store_longitude: -74.0006,
        total_orders: 2353.96,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Beer, Friendly, Warm, Welcoming, Casual, Great, Chill, Unpretentious',
        annual_dol: 2809.3,
        description:
            'Gay bar with many TVs tuned to sports, plus a pool table & a basement lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -74.002,
        total_orders: 1672,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Beer, Juicy, Upscale, Fun, Legendary, Premium, Wonderful, American, Great',
        annual_dol: 1707.48,
        description:
            'Lounge/bowling alley has a clublike vibe with posh decor, bar, menu of upscale American classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.543,
        store_longitude: -74.1641,
        total_orders: 957.76,
        neighborhood: 'Eltingville',
        keywords:
            'Pizza, Italian, Vegetarian, Delicious, Upscale, Fresh, Friendly, Terrific, Authentic, Casual',
        annual_dol: 1056.2,
        description:
            'Coal-fired pizza shares the menu with baked sandwiches & salads at this brick-walled Italian spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -73.9425,
        total_orders: 1593.85,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Wine, Trendy, Delicious, Cozy, Italian, Rustic, Spacious, Fun, Hospitable',
        annual_dol: 1734.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 7968.42,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Rooftop, Wine, Salad, Amazing, Martini, Romantic, Incredible, Fresh, Great, Intimate',
        annual_dol: 25856.91,
        description:
            '"Pier 17 features unmatched views of the Brooklyn Bridge, Statue of Liberty, and the city skyline."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7076,
        store_longitude: -74.0018,
        total_orders: 509.61,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Salad, Pizza, Tasty, Wine, Italian, French, French Onion Soup, Stylish, Rich',
        annual_dol: 555.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9821,
        total_orders: 8776.55,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Wine, Upscale, Steak, Coffee, Classy, Romantic, Cozy, Gin, Classic, Intimate',
        annual_dol: 11012.37,
        description:
            'Richly furnished setting for hard-to-find spirits, raw-bar items, live jazz & classic American food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9256,
        total_orders: 454.08,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Mexican, Beer, Salad, Jamaican, American, Local, Signature, Chill, Great',
        annual_dol: 483.92,
        description:
            'Chill local pub offering various craft beers on tap plus trivia nights, karaoke, live music & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9294,
        total_orders: 265.18,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Tacos, Salad, Kebab, Mexican, Tequila, Dessert, Bbq, Latin, Fresh',
        annual_dol: 333.04,
        description: '"Mexican Bar Restaurant in Astoria, NY"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9781,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Salad, Tacos, Coffee, Vietnamese, Ale, Chinese, Thai, Rum, Seasonal, Pho',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7653,
        store_longitude: -73.9778,
        total_orders: 539.85,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Wine, Champagne, Coffee, Delicious, Impeccable, Refined, Healthy, Mediterranean, Greek, Classy',
        annual_dol: 575.74,
        description:
            'Traditional Greek seafood & other classics in refined preparations served in sleek, minimalist digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7619,
        store_longitude: -73.9866,
        total_orders: 2216.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Beer, Flavorful, Tasty, Turkish, Delicious, Nachos, Friendly, Refreshing, American, Handcrafted',
        annual_dol: 2263.76,
        description:
            'Happening tavern drawing a pre-theater crowd for its selection of draft beers & elevated pub fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9924,
        total_orders: 825.64,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Fabulous, Irish, Great, Incredible, Casual',
        annual_dol: 868.47,
        description:
            '"NYCs newest LGBTQIA Bar, events ranging from Jazz to drag to dance parties, Happy Hour Daily 4pm-7pm,"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9887,
        total_orders: 2232.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Mexican, Beer, Warm, American, Spacious, Rooftop, Awesome, Comfortable, Amazing',
        annual_dol: 5212.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -73.9926,
        total_orders: 180.45,
        neighborhood: 'Bowery',
        keywords: 'Vegan, Burger, Vegetarian, Pizza, Sushi, Salad, Gourmet, Beer, Wine, Mexican',
        annual_dol: 873.38,
        description:
            'Easygoing outfit offering a creative menu of plant-based dishes, plus craft ales & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -73.9905,
        total_orders: 118.04,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Coffee, Vegan, Wine, Sushi, Vegetarian, Salad, Beer, Spicy, Cozy, Friendly',
        annual_dol: 321.39,
        description:
            'Comfy counter-serve outpost offering coffee drinks, pastries, light fare & sandwiches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9686,
        total_orders: 129.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Mexican, Tequila, Steak, Margarita, Delicious, Impeccable, American, Fun, Lively, Authentic',
        annual_dol: 250.13,
        description:
            'Modern Mexican chain eatery known for tableside guacamole, creative margaritas & chic surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9858,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Hotel, Classic, Modern',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7023,
        store_longitude: -73.811,
        total_orders: 4.55,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Steak, Tasty, Hawaiian, American, Latin, Casual, Sweet',
        annual_dol: 18.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.82,
        store_longitude: -73.9586,
        total_orders: 53.69,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Rum, Jamaican, Caribbean, American, Sweet, Ample, Great, Perfect, Casual',
        annual_dol: 219.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.9062,
        store_longitude: -73.9041,
        total_orders: 5.36,
        neighborhood: 'North Riverdale',
        keywords:
            'Steak, Wine, Seafood Paella, Beer, Coffee, Latin, Margarita, American, Elegant, Mojito',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7379,
        store_longitude: -74.0017,
        total_orders: 1125.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Tequila, Ale, Homey, Lively, Classic, Fantastic, Great, Colorful, Casual, Chill',
        annual_dol: 1174.54,
        description:
            'Neighborhood dive bar with daily shot specials & jukebox, bumper stickers & film-truck permits.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5402,
        store_longitude: -74.177,
        total_orders: 303.85,
        neighborhood: 'Annadale',
        keywords: 'Salad, Chicken Parmigiana, Italian, Homemade, Casual, Fresh, Fine',
        annual_dol: 409.72,
        description:
            '"La Fontana has been serving Staten Island fine Italian food since 1983. We are open for dinner 7 days a week with nightly specials. We host all type of afternoon gatherings, up to 70 guests, by reservation only. You can host any type of special occasions, celebration and even bereavement lunches. Our Pre Fixe special is available Monday - Thursday as well."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7349,
        store_longitude: -73.9999,
        total_orders: 320.56,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Italian, Beer, Dessert, Warm, Spacious, Rooftop, Fresh, Seasonal, Great',
        annual_dol: 826.04,
        description:
            'Airy hot spot with a rooftop farm for seasonal Italian dishes, house-made pastas & notable wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7225,
        store_longitude: -73.957,
        total_orders: 13270.39,
        neighborhood: 'Greenpoint',
        keywords:
            'Lobster, Pizza, Italian, Wine, Spicy, Delicious, Hotel, Romantic, Southern, Spacious',
        annual_dol: 13777.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7181,
        store_longitude: -73.96,
        total_orders: 785.1,
        neighborhood: 'Williamsburg',
        keywords:
            'Vegetarian, Salad, Pizza, Thai, Japanese, Green Curry, Wine, Spicy, Beer, Savory',
        annual_dol: 855.64,
        description:
            'Familiar Thai standards accompanied by pulsing music, flashy decor & even a pond.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.741,
        store_longitude: -73.9814,
        total_orders: 340.9,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Vegan, Vegetarian, Pizza, Steak, Salad, Gluten Free, Wine, Italian, Dessert, Vodka',
        annual_dol: 348.13,
        description:
            'Intimate locale furnishing familiar Italian cuisine, including vegan, kosher & gluten-free dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.9278,
        total_orders: 340.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Garlic Bread, Salad, Italian, Pizza, Wine, Cozy, Rustic, Delicious, Bruschetta, Prosecco',
        annual_dol: 397.98,
        description:
            'Cosy nook whipping up traditional Roman specialties, plus wines, cocktails & patio seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7734,
        store_longitude: -73.9895,
        total_orders: 24.57,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Pizza, Salad, Meatball, Gluten Free, Juicy, Hotel, Casual, Clean, Fresh, Local',
        annual_dol: 100.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7733,
        store_longitude: -73.9832,
        total_orders: 1146.38,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Wine, Salad, Coffee, Italian, Delicious, Belgian, Clean, Fresh, Fine, Dynamic',
        annual_dol: 4024.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9872,
        total_orders: 3804.74,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tasty, Wine, Beer, Japanese, Meatball, Polished, Authentic, Outstanding, Casual, Sophisticated',
        annual_dol: 4281.41,
        description:
            "Sophisticated Japanese eatery offering charcoal-grilled skewers & sake, plus a chef's-choice option.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7638,
        store_longitude: -73.9889,
        total_orders: 35.19,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Kebab, Burger, Coffee, Greek, Tangy, American, Georgian, Delicious, Tzatziki, Local',
        annual_dol: 143.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8149,
        store_longitude: -73.9443,
        total_orders: 278.4,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Beer, Tasty, American, French, Jamaican, Cool, Great, Casual',
        annual_dol: 901.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.9898,
        total_orders: 95.16,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Tasty, Thai, Spicy, Delicious, Green Curry, Asian, Fried Rice, Modern, Casual',
        annual_dol: 388.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8647,
        store_longitude: -73.9285,
        total_orders: 287.12,
        neighborhood: 'Fort George',
        keywords: 'Steak, Tacos, Mexican, Wine, Bbq, American, Delicious, Latin, Classy, Fusion',
        annual_dol: 293.21,
        description:
            'Latin American fare from tacos to empanadas, served in a bright, rustic-chic space with live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7394,
        store_longitude: -74.0014,
        total_orders: 762.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Cosmopolitan, Rich, Elegant, Legendary, Fun, Sweet, British, Great, Authentic, Cool',
        annual_dol: 1482.49,
        description:
            '"Norwood is a private members only club for professionals & patrons of the creative arts."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6773,
        store_longitude: -73.9554,
        total_orders: 149.47,
        neighborhood: 'Crown Heights',
        keywords:
            'Lobster, Lobster Roll, Tacos, Cornbread, Vegan, Gluten Free, Margarita, Spicy, Delicious, Juicy',
        annual_dol: 178.98,
        description:
            'Small joint dishing up crab in full plates with sides, as well as in rolls, tacos & sliders.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6677,
        total_orders: 1088.55,
        neighborhood: '',
        keywords: 'Steak, Wine, Beer, Gourmet, American, Tasty, Modern, Fresh, Great, Excellent',
        annual_dol: 1111.65,
        description:
            'American fare like gourmet burgers & seafood served in a sizable space with a patio & sky-lit bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5841,
        store_longitude: -73.6871,
        total_orders: 1562.66,
        neighborhood: '',
        keywords: 'Burger, Lobster, Salad, Sushi, Hamburger, Pizza, Flavorful, Tasty, Nachos, Wine',
        annual_dol: 2225.21,
        description: '"Eclectic seafood"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9803,
        total_orders: 381.5,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Italian, Wine, Pizza, Delicious, Gin, Homemade, Authentic, Casual, Classic, Fresh',
        annual_dol: 389.6,
        description:
            "Cuisine from northern Italy's Emilia region inspires the menu at this rustic, patio-equipped eatery.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7283,
        store_longitude: -73.9821,
        total_orders: 1112.01,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Rich, Eclectic, Intimate, Cultural',
        annual_dol: 1135.6,
        description:
            'Intimate, multi-bar locale featuring nightly live music, plus dance parties & comedy nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7177,
        store_longitude: -73.9581,
        total_orders: 92.99,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Irish, Bourbon, Fun, Hip, Seasonal, Quirky, Lit, Local, Casual',
        annual_dol: 380.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7116,
        store_longitude: -73.943,
        total_orders: 293.57,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Pizza, Tasty, Beer, Relaxed, Friendly, Great, Casual, Diverse',
        annual_dol: 1200,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 1348.74,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Upscale, Elegant, Dessert, Savory, Steak, Spacious, Wholesome, Italian, Ale',
        annual_dol: 1377.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7176,
        store_longitude: -73.9582,
        total_orders: 45.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Vegetarian, Hamburger, Salad, Coffee, Polish, American, Greek, Casual, Healthy',
        annual_dol: 184.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7423,
        store_longitude: -73.9829,
        total_orders: 39.54,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vegetarian, Kebab, Chicken Tikka Masala, Chinese, Martini, Spicy, Indian, Delicious, Casual, Paneer Tikka',
        annual_dol: 161.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8359,
        store_longitude: -73.8331,
        total_orders: 480.13,
        neighborhood: 'Schuylerville/Pelham Bay',
        keywords:
            'Wine, Beer, Coffee, Salad, Cuban, Delicious, Caribbean, Organic, Dominican, Latin',
        annual_dol: 490.31,
        description:
            'Festive restaurant offering contemporary Latin & Dominican cuisine plus cocktails & sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6561,
        store_longitude: -73.6108,
        total_orders: 731.38,
        neighborhood: '',
        keywords: 'Wine, Beer, Dessert, American, Lively, Classic, Casual, Amazing, Great, Local',
        annual_dol: 937.67,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7352,
        store_longitude: -73.6605,
        total_orders: 457.01,
        neighborhood: '',
        keywords: 'Burger, Pizza, Beer, Wine, Irish, German, American, Traditional, Awesome, Great',
        annual_dol: 487.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6109,
        store_longitude: -73.9789,
        total_orders: 551.01,
        neighborhood: 'Bensonhurst',
        keywords:
            'Salad, Chicken Parmigiana, Shrimp Cocktail, Italian, Wine, Savory, Comfortable, Casual, Sangria, Authentic',
        annual_dol: 562.7,
        description:
            'Old-school Italian eatery & bar prepping classic Neapolitan cuisine & housemade sangria since 1930.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6298,
        store_longitude: -74.1096,
        total_orders: 143.14,
        neighborhood: 'West Brighton',
        keywords: 'Italian, Pizza, Asian, Homemade, Classy, Authentic, Casual, Fresh, Great',
        annual_dol: 585.1,
        description:
            '"Meatballs have been our signature, staple dish — it was kind of the spark behind the original location. We’ll have home-made pasta like fettuccine with prosciutto. We’ll have a homemade black and white fettuccine with squid ink, mussels, clams, shrimp and calamari in a fresh tomato sauce."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7418,
        store_longitude: -73.6618,
        total_orders: 2223.54,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Lobster Tail, Pizza, Mimosa, Dessert, Margarita, Wine, Spicy, Bellini',
        annual_dol: 2545.01,
        description:
            'Bar & grill serving creative American comfort fare in a relaxed setting with big-screen TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7673,
        store_longitude: -73.9213,
        total_orders: 1678.29,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Beer, Irish, American, Local, Friendly, Casual, Great',
        annual_dol: 1826.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7096,
        store_longitude: -73.8986,
        total_orders: 79.82,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Lasagna, Steak, Burger, Wine, Pizza, Salad, Vodka, Italian, Velvety, Delicious',
        annual_dol: 205.69,
        description:
            '"Rustico offers a wide range of both traditional and non-traditional Italian dishes; crisp-crusted, hand-tossed pizzas, topped with fresh velvety mozzarella and basil; fresh salmon, beautifully seasoned and grilled to taste – Rustico offers a thoughtfully-prepared dish for any palate."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7435,
        store_longitude: -73.6928,
        total_orders: 89.67,
        neighborhood: '',
        keywords: 'Burger, Beer, Coffee, Wine, Delicious, Ale, Healthy, American, Great, Casual',
        annual_dol: 231.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6937,
        store_longitude: -73.9458,
        total_orders: 264.5,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Wine, Tasty, Coffee, Delicious, Friendly, Natural, American, Fabulous, Traditional, Great',
        annual_dol: 1081.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6959,
        store_longitude: -73.9334,
        total_orders: 4028.19,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords:
            'Friendly, Perfect, Vibrant, Ample, Great, Fine, Local, Pinacolada, Casual, Signature',
        annual_dol: 4113.66,
        description:
            'Straightforward hangout featuring tropical-themed drinks & ample outdoor patio space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7368,
        store_longitude: -73.9552,
        total_orders: 292.31,
        neighborhood: 'Greenpoint',
        keywords: 'Wine, Upscale, Fun, Bbq, Awesome, Classy, Latin, Great, Lit, Coleslaw',
        annual_dol: 954.33,
        description:
            "Darkly lit bar & music venue on Greenpoint's northern end hosting metal bands & their enthusiasts.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.575,
        store_longitude: -73.9853,
        total_orders: 107.41,
        neighborhood: 'Coney Island',
        keywords: 'Hotdog, Wine, Fun, Fabulous, Picturesque',
        annual_dol: 530.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7464,
        store_longitude: -73.6171,
        total_orders: 907.72,
        neighborhood: '',
        keywords:
            'Salad, Vegetarian, Wine, Dessert, Elegant, Delicious, Cozy, Rustic, Impeccable, Warm',
        annual_dol: 1113.56,
        description:
            'Located in a strip mall, this bistro serves inventive entrees & cocktails plus fixed-price specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6556,
        store_longitude: -73.6458,
        total_orders: 445.71,
        neighborhood: '',
        keywords: 'Whiskey, Wine, Italian, Hamburger, Premium, American, Great',
        annual_dol: 1093.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7116,
        store_longitude: -73.5565,
        total_orders: 36.16,
        neighborhood: '',
        keywords:
            'Whiskey, Tasty, Gluten Free, Comfortable, Homey, Traditional, Great, Casual, Fantastic',
        annual_dol: 147.8,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7122,
        store_longitude: -73.7309,
        total_orders: 12.16,
        neighborhood: 'Jamaica/Queens Village',
        keywords: 'Burger, Salad, Hamburger, Italian, Coffee, Beer, Bbq, Creamy, French, Greek',
        annual_dol: 37.24,
        description:
            '"Neighborhood bar walking distance to the new UBS Arena, home of the New York Islanders. Great drinks and Great times."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7755,
        store_longitude: -73.9118,
        total_orders: 348.81,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Mexican, Tequila, Beer, Hamburger, Salad, Wine, Dessert, Upscale, Spicy, Latin',
        annual_dol: 420.69,
        description:
            'Margaritas & Southwestern entrees in a spacious, adobe-style space with an outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7205,
        store_longitude: -73.9952,
        total_orders: 75.57,
        neighborhood: 'Nolita',
        keywords:
            'Salad, Vegetarian, Wine, Mexican, French Toast, French, Organic, Korean, Japanese, Mediterranean',
        annual_dol: 97.45,
        description:
            'Cafe focusing on everything egg, including creative sandwiches & fancy Benedicts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -74.0016,
        total_orders: 70.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Wine, Italian, Relaxed, Fun, Casual, Modern, Playful, Traditional, Classic',
        annual_dol: 296.71,
        description:
            'Relaxed seafood eatery offering hearty mains, small plates & classic cocktails, plus a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.9975,
        total_orders: 59.16,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Garlic Bread, Wine, Beer, Irish, Roast Beef, Hotel, Friendly, Traditional, Perfect, Casual',
        annual_dol: 241.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9972,
        total_orders: 164.67,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Beer, Classy, Casual, Fine, Great',
        annual_dol: 673.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7705,
        store_longitude: -73.9902,
        total_orders: 760.08,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Wine, Pizza, Italian, Hotel, Coffee, Beer, Cozy, Premium, Healthy, American',
        annual_dol: 776.21,
        description:
            '"Fancy a bite to eat or thirsting for a drink? Bar 58 has bar bites, cocktails, wine, beer, and more. Happy Hour daily 5pm-7pm. Cozy Hotel Bar with amazingly friendly staff."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7661,
        store_longitude: -73.9799,
        total_orders: 2956.78,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hamburger, Beer, Wine, Coffee, Ale, Bbq, Delicious, Irish, American, Legendary',
        annual_dol: 3019.52,
        description:
            'Bustling, historic & traditional Irish watering hole with a diverse crowd & a an old-world vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.9894,
        total_orders: 987.95,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Hotel, Classy, Vibrant, Great, Traditional, Signature, Unique, Casual',
        annual_dol: 4044.69,
        description:
            '"VERS is a new kind of gay bar serving craft cocktails, elevated bar snacks, and unique entertainment in a beautifully designed space in the Hell\'s Kitchen."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6766,
        store_longitude: -73.8233,
        total_orders: 19.37,
        neighborhood: 'South Ozone Park',
        keywords: 'Creamy, Bbq, Friendly, Homemade, Casual, Fresh',
        annual_dol: 79.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -73.9979,
        total_orders: 220.47,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Coffee, Sweet, Fun, Bright, Hotel, Natural, Signature',
        annual_dol: 635.02,
        description:
            'Museum of Ice Cream is a company that develops and operates interactive retail experiences, or "selfie museums", in the United States and Singapore. These exhibits, typically hosted in storefronts, are ice-cream and candy-themed, with bright colors.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7442,
        store_longitude: -73.9942,
        total_orders: 2114.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Beer, Wine, American, Vintage, Classic, Fun, Sweet, Great, Local',
        annual_dol: 2114.98,
        description:
            'A spin-off of a Williamsburg bar, this hang has vintage video games (Pac-Man, Donkey Kong) & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7097,
        store_longitude: -74.0094,
        total_orders: 3400.62,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Whiskey, Beer, Burger, Steak, Wine, Ale, Tasty, Martini, Upscale, American',
        annual_dol: 3507.97,
        description:
            'Craft beer, classic cocktails & American fare served in a swanky, industrial-chic tavern.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -73.9842,
        total_orders: 1440.46,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegan, Vegetarian, Delicious, Warm, Spicy, Trendy, Coffee, Cozy, Painkiller, Rustic',
        annual_dol: 1482.75,
        description:
            'Rustic-chic spot for inventive bar food, craft cocktails & live music, plus monthly comedy nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.674,
        store_longitude: -73.9632,
        total_orders: 199.62,
        neighborhood: 'Prospect Heights',
        keywords: 'Pizza, Tequila, Italian, Salad, Beer, Wine, Tasty, Cozy, Classy, Romantic',
        annual_dol: 212.73,
        description:
            'Elevated pizzas, salads, charcuterie & cocktails served in a low-lit space with a big, leafy patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6829,
        store_longitude: -73.9612,
        total_orders: 30.85,
        neighborhood: 'Clinton Hill',
        keywords:
            'Steak, Flavorful, Wine, Delicious, Rustic, French, American, Outstanding, Amazing, Great',
        annual_dol: 126.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7111,
        store_longitude: -73.9517,
        total_orders: 264.72,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Hamburger, Salad, Tequila, Beer, Bbq, Vodka, Juicy, French, American',
        annual_dol: 479.91,
        description:
            'Casual neighborhood brasserie serving local craft beers, noteworthy burgers, sandwiches & sides.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7238,
        store_longitude: -73.9788,
        total_orders: 147.22,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster, Salad, Austrian, French Toast, Beer, Caesar Wrap, French, Stylish, Caesar Salad, Asian',
        annual_dol: 601.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7417,
        store_longitude: -73.9825,
        total_orders: 179.07,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Beer, Cozy, Elegant, Quaint, Hotel, Perfect, Casual, Incredible',
        annual_dol: 731.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -73.9599,
        total_orders: 1331.84,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Wine, Friendly, Perfect, Cool, Awesome, American, Great, Casual, Local',
        annual_dol: 2335.02,
        description:
            'Airy, old-school joint luring locals with a chill vibe, happy-hour deals, billiards & pinball.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7183,
        store_longitude: -73.9576,
        total_orders: 928.56,
        neighborhood: 'Williamsburg',
        keywords:
            'Garlic Bread, Burger, Hamburger, Salad, Meatball, Italian, Dessert, Wine, Beer, Vodka',
        annual_dol: 968.82,
        description:
            'Branch of a hip, ingredient-focused local chain offering inventive meatball sandwiches & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6404,
        store_longitude: -73.6632,
        total_orders: 99.61,
        neighborhood: '',
        keywords: 'American',
        annual_dol: 407.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7039,
        store_longitude: -74.0129,
        total_orders: 206.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, American, Modern, Iconic, Perfect, Great, Casual',
        annual_dol: 944.12,
        description:
            'minute stroll from One World Trade Center.Warm rooms include custom-designed beds, 49-inch flat-screen TVs and complimentary Wi-Fi. Suites add separate living areas. Some rooms and suites offer river or city views.A casual restaurant serves a breakfast buffet and all-day a la carte meals. Additional amenities include a gym, a meeting room and a 24-hour business center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -73.8445,
        total_orders: 1009.37,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Tacos, Beer, New-York-Style, Bbq, Smoky, Southern, Comfortable, Local, Traditional, Great',
        annual_dol: 3543.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.9543,
        total_orders: 150.78,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Pizza, Italian, Salad, Gourmet, Wine, Delicious, Rustic, Homemade, Fun, Friendly',
        annual_dol: 157.32,
        description:
            'Gourmet wood-fired pizzas, wine & espresso served in a snug, rustic space with sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7722,
        store_longitude: -73.9557,
        total_orders: 1069.93,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Vegan, Pizza, Gluten Free, Salad, Wine, Italian, Gourmet, Beer, Delicious, Warm',
        annual_dol: 2494.27,
        description:
            '"Uva Next Door is Uva\'s little sister restaurant. A cocktail-forward, lively establishment serving Italian favorites. From pizza, to pasta, to unexpected delights!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8274,
        store_longitude: -73.9257,
        total_orders: 2282.37,
        neighborhood: 'Concourse',
        keywords:
            'Cheesecake, Salad, Wine, Coffee, Beer, Roast Beef, German, Legendary, American, Southern',
        annual_dol: 9421.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9609,
        total_orders: 622.64,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Wine, Coffee, Upscale, Spicy, Avocado Toast, Delicious, American, Eclectic, Sleek',
        annual_dol: 1178.95,
        description:
            'Swanky bar with a speakeasy vibe, offering creative cocktails, eclectic bar eats, DJs & cigarettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -73.9563,
        total_orders: 3340.61,
        neighborhood: 'Greenpoint',
        keywords: 'Salad, Spicy, Stylish, Mediterranean, Italian, Rustic, Cool, Fresh, Casual, Hip',
        annual_dol: 4105.48,
        description:
            'Mediterranean fare & cocktails served in a former glass factory with rustic-cool decor, bar & patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7659,
        store_longitude: -73.9716,
        total_orders: 1099.93,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Hotel, Legendary, Bright, Vibrant, Rooftop, Friendly, Creative, Fine',
        annual_dol: 1123.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7389,
        store_longitude: -73.9896,
        total_orders: 141.21,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Dessert, French, Delicious, Modern, American, Fresh, Seasonal, Classic, Wonderful',
        annual_dol: 495.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.653,
        store_longitude: -73.9759,
        total_orders: 277.87,
        neighborhood: 'Windsor Terrace',
        keywords:
            'Burger, Lobster, Lobster Roll, Pizza, Salad, Italian, Dessert, French Onion Soup, Wine, Beer',
        annual_dol: 616.62,
        description:
            'French cuisine & pizza from a brick oven in a casual, wood-accented space, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9802,
        total_orders: 20894.56,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotdog, Beer, Legendary, American, Spectacular, Great',
        annual_dol: 25594.98,
        description:
            'Radio City Music Hall is an entertainment venue and theater at 1260 Avenue of the Americas, within Rockefeller Center, in the Midtown Manhattan neighborhood of New York City. Nicknamed "The Showplace of the Nation", it is the headquarters for the Rockettes, the precision dance company.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.8299,
        total_orders: 2954.24,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords: 'Japanese, Sushi, Bbq, Korean, Beer, Asian, Spicy, Ramen, Sukiyaki, Gyoza',
        annual_dol: 3569.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.8335,
        total_orders: 576.04,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Vegetarian, Beer, Savory, Trendy, Delicious, Luxurious, Spacious, Smooth, Karaoke, Perfect',
        annual_dol: 2354.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9775,
        total_orders: 2343.1,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Beer, Juicy, Delicious, American, Fun, Sweet, Friendly, Coleslaw',
        annual_dol: 2444.69,
        description:
            'Big, bustling burger spot also known for disco fries (with gravy & cheese), beer & alcoholic shakes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6643,
        store_longitude: -73.698,
        total_orders: 88.4,
        neighborhood: '',
        keywords:
            'Burger, Salad, Upscale, Italian, Relaxed, Hotel, Delicious, Warm, Friendly, Casual',
        annual_dol: 240.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6219,
        store_longitude: -74.0319,
        total_orders: 1212.95,
        neighborhood: 'Fort Hamilton',
        keywords: 'Steak, Lobster, Salad, Shrimp Cocktail, Coffee, Wine, Dessert, Beer, Ale, Bbq',
        annual_dol: 1241.85,
        description:
            'Long-standing eatery with a cozy, nostalgic feel serving classic American dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6292,
        store_longitude: -74.0226,
        total_orders: 688.04,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Pizza, Beer, Salad, Tasty, Vodka, Savory, Chicken Wings, Bourbon, American',
        annual_dol: 796.83,
        description:
            'Unfussy watering hole boasting standard bar bites, a large beer list, DJ nights & lots of TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.9752,
        total_orders: 12725.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Luxurious, Champagne, Upscale, Hotel, Opulent, Spectacular, Iconic, Irresistible, Sophisticated, Amazing',
        annual_dol: 14063.87,
        description:
            "The plush rooms provide free Wi-Fi and flat-screen TVs, along with luxe linens and designer toiletries. Suites add furnished terraces with sweeping Manhattan views and dining tables. Room service is available.There's a French-influenced bakery and a wood-paneled bar, plus an upscale eatery and a cocktail lounge. Other amenities include a spa and a fitness center with steam rooms.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7129,
        store_longitude: -74.0097,
        total_orders: 854.82,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Champagne, Hotel, Beer, Warm, Cozy, Polished, Lively, Iconic, Great',
        annual_dol: 1076.28,
        description:
            'The chic rooms and suites feature free Wi-Fi, smart TVs, Nespresso machines and Bose speakers. All come with bars, living areas, and marble bathrooms with glass-enclosed showers. Some have terraces. Room service is available 24/7.A polished steak restaurant/bar by renowned chef Wolfgang Puck is on-site. Breakfast is extra. Other amenities include a heated indoor lap pool and a posh spa. A gym and a business center are open 24 hours. Valet parking is available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9748,
        total_orders: 709.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Sushi, Tasty, Wine, Clean, Sashimi, Upscale, Japanese, Authentic, Traditional, Casual',
        annual_dol: 807.55,
        description:
            'Sushi & sashimi served amid traditional decor in an upscale restaurant popular for business lunches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6226,
        store_longitude: -74.0253,
        total_orders: 230.37,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Friendly, Great, Puerto Rican, Casual',
        annual_dol: 867.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9759,
        total_orders: 993.91,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Lobster, Steak, Salad, Gluten Free, Mexican, Spicy, Champagne, Wine, Italian',
        annual_dol: 1022.28,
        description:
            'Upscale restaurant & bar serving seasonal Mediterranean meals prepared with flavored olive oils.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.966,
        total_orders: 129.65,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Vegan, Wine, Salad, Healthy, Lebanese, Beer, Dessert, Savory, Clean, Gracious',
        annual_dol: 135.27,
        description:
            'Accommodating to large parties, healthy & vegan Mediterranean dishes are served family-style.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9875,
        total_orders: 1297.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lasagna, Meatball, Italian, Gourmet, Dessert, Spicy, Wine, Margarita, Prosecco, American',
        annual_dol: 1325.11,
        description:
            'Branch of a hip, ingredient-focused local chain offering inventive meatball sandwiches & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7693,
        store_longitude: -73.9886,
        total_orders: 178.25,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Salad, Green Curry, Thai, Beer, Mediterranean, Latin, American, Casual, Coleslaw',
        annual_dol: 824.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8108,
        store_longitude: -73.9435,
        total_orders: 49.38,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Pizza, Tacos, Tasty, Dessert, Premium, Lively, Authentic, Great, Modern, Sangria',
        annual_dol: 189.02,
        description:
            'Stylish, modern locale featuring flavored hookahs, updated comfort food, cocktails & hip hop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7367,
        store_longitude: -73.9871,
        total_orders: 182.8,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Tacos, Tequila, Mexican, Steak, Beer, Margarita, Pizza, Tex-Mex, Enchiladas, Trendy',
        annual_dol: 194.82,
        description:
            'Lively joint serving classic Tex-Mex grub & margaritas in a colorful space with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9528,
        total_orders: 388.44,
        neighborhood: 'Roosevelt Island',
        keywords:
            'Burger, Tasty, Chicken Pot Pie, Wine, Salad, Rich, Delicious, Beer, Italian, Warm',
        annual_dol: 732.68,
        description:
            'Bustling hangout offering Irish & American entrees, plus draft beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7516,
        store_longitude: -73.6025,
        total_orders: 54.2,
        neighborhood: '',
        keywords:
            'Salad, Wine, Shrimp Cocktail, Italian, Beer, Cozy, Crisp, Friendly, Casual, Authentic',
        annual_dol: 221.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7867,
        store_longitude: -73.6397,
        total_orders: 362.68,
        neighborhood: '',
        keywords: 'Steak, Salad, Meatloaf, Wine, Gluten Free, Dessert, Italian, Organic, Warm, Bbq',
        annual_dol: 396.45,
        description:
            'Bustling local go-to for generous portions of New American standards & early-bird deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7999,
        store_longitude: -73.7009,
        total_orders: 932.19,
        neighborhood: '',
        keywords: 'Burger, Steak, Pizza, Beer, Wine, American, Irish, Modern, Greek, Outstanding',
        annual_dol: 1001.93,
        description:
            'Time-tested, storefront tavern with rustic-chic decor, pub grub & craft beer, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5961,
        store_longitude: -74.0951,
        total_orders: 55.47,
        neighborhood: 'Dongan Hills/Todt Hill',
        keywords:
            'Pizza, Steak, Vegetarian, Salad, Chicken Caesar Salad, Italian, Flavorful, Wine, Beer, Dessert',
        annual_dol: 226.76,
        description:
            'Traditional pizzas, pastas & other Italian favorites served in a warm, laid-back setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5658,
        store_longitude: -74.1006,
        total_orders: 25.16,
        neighborhood: 'New Dorp Beach',
        keywords:
            'Burger, Hotdog, Pizza, Tequila, Beer, Turkish, Delicious, American, Mediterranean, Friendly',
        annual_dol: 29.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6999,
        store_longitude: -73.9162,
        total_orders: 80.55,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Vegan, Beer, Chinese, Tasty, Urban, Modern, Retro, Fun, Local',
        annual_dol: 282.12,
        description:
            "Retro pizza place for modern pies & craft beer in a kitschy space decorated with '80s memorabilia.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6098,
        store_longitude: -74.063,
        total_orders: 19.3,
        neighborhood: 'Rosebank',
        keywords:
            'Delicious, Cajun, Dessert, Fun, Welcoming, Cool, Great, Excellent, Local, Casual',
        annual_dol: 78.91,
        description: '"Locally owned and operated"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7284,
        store_longitude: -74.0051,
        total_orders: 1088.64,
        neighborhood: 'Hudson Square',
        keywords: 'Salad, Tasty, Coffee, Wine, Cuban, Delicious, Legendary, Thai, Bbq, Vintage',
        annual_dol: 1226.15,
        description:
            "SOB's is a live world music venue and restaurant in the Hudson Square neighborhood of Manhattan. S.O.B.’s is an abbreviation of Sounds of Brazil. Larry Gold started SOBs in June 1982, and he currently still owns the space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8355,
        store_longitude: -73.9441,
        total_orders: 1462.05,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords: 'Burger, Cheesecake, Salad, Beer, Coffee, Dessert, Tasty, Ale, Bourbon, Bbq',
        annual_dol: 1525.44,
        description:
            'Burgers, flatbreads & weekend brunch in a jumping spot with sports on TV, plus cocktails & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7847,
        store_longitude: -73.977,
        total_orders: 59.34,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Kebab, Salad, Dessert, Beer, Mediterranean, Israeli, Shawarma, French, Middle Eastern, Casual',
        annual_dol: 77.86,
        description:
            '"Gazala’s Restaurant is the creation of Chef/Owner Gazala Halabi, who celebrates her culinary heritage as an Israeli Druze."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7127,
        store_longitude: -73.6073,
        total_orders: 124.91,
        neighborhood: '',
        keywords: 'Irish, Great, Casual',
        annual_dol: 510.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7673,
        store_longitude: -73.9202,
        total_orders: 805.73,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Beer, Wine, American, Casual, Local, Fusion, Classic, Exotic',
        annual_dol: 840.66,
        description:
            'Modern, upbeat tavern with an outdoor courtyard offering pub grub, cocktails & rotating craft brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6602,
        store_longitude: -73.9804,
        total_orders: 797.76,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'Beer, Irish, Casual, Perfect, Local, Wonderful',
        annual_dol: 797.76,
        description:
            'Longtime neighborhood watering hole is no frills & no food with a history dating back to 1933.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6645,
        store_longitude: -73.9805,
        total_orders: 522.05,
        neighborhood: 'Park Slope',
        keywords: 'Lobster, Tasty, Champagne, Wine, Salad, Coffee, Pizza, Spicy, Warm, Italian',
        annual_dol: 775.8,
        description:
            'Relaxed choice with a warm vibe serving breakfast sandwiches & classic Italian meals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6774,
        store_longitude: -73.9863,
        total_orders: 400.35,
        neighborhood: 'Gowanus',
        keywords: 'Lobster, Burger, Pizza, Salad, Dessert, Coffee, Beer, Wine, Rustic, American',
        annual_dol: 530.02,
        description:
            'Lobster rolls, a raw bar, daily catches & a happy hour attract seafood lovers to this rustic eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7696,
        store_longitude: -73.9545,
        total_orders: 1520.19,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Salad, Turkey Sandwich, Gourmet, Vodka, Roast Turkey, Gin, Casual, Traditional, Perfect',
        annual_dol: 1893.41,
        description:
            'Old-school eatery serving blintzes, knishes & other Jewish fare, plus a variety of sandwiches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7242,
        store_longitude: -73.9513,
        total_orders: 249.06,
        neighborhood: 'Greenpoint',
        keywords:
            'Tequila, Tacos, Mexican, Steak, Beer, Margarita, Salad, Coffee, Burritos, Quesadillas',
        annual_dol: 254.34,
        description:
            'Simple Mexican tacos, burritos & quesadillas plus booze served amid contemporary decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8481,
        store_longitude: -73.8316,
        total_orders: 802.18,
        neighborhood: 'Pelham Bay',
        keywords:
            'Steak, Vodka, Salad, Beer, Turkey Sandwich, Latin, Creamy, Spanish, Fried Rice, American',
        annual_dol: 3022.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7712,
        store_longitude: -73.9517,
        total_orders: 31.74,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Hamburger, Spicy, Casual, Signature',
        annual_dol: 129.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6637,
        store_longitude: -73.6079,
        total_orders: 14.71,
        neighborhood: '',
        keywords:
            'Lobster, Vegan, Pizza, Vegetarian, Tacos, Gluten Free, Casual, Latin, Hip, Great',
        annual_dol: 60.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6129,
        store_longitude: -73.9894,
        total_orders: 89.98,
        neighborhood: 'Bensonhurst',
        keywords: 'Coffee, Beer, Friendly, Casual, Great, Excellent, Signature',
        annual_dol: 367.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7079,
        store_longitude: -73.679,
        total_orders: 471.98,
        neighborhood: '',
        keywords:
            'Burger, Shrimp Cocktail, Bbq, Tangy, Karaoke, Ale, Homemade, Local, Casual, Great',
        annual_dol: 503.36,
        description:
            'Hearty pub eats from burgers to entrees served in a casual, upbeat setting with weekend live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7517,
        store_longitude: -73.9887,
        total_orders: 15.55,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Sushi, Vegetarian, Spicy, Margarita, Beer, Japanese, Ramen, Delicious, Zen, Sashimi',
        annual_dol: 63.57,
        description:
            'Simple, booth-lined eatery with a range of Japanese dishes, from sashimi to noodle soups & teriyaki.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.826,
        store_longitude: -73.9389,
        total_orders: 268.06,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Pizza, Lasagna, Italian, Beer, Coffee, Chinese, Delicious, Homemade, African, French',
        annual_dol: 547.12,
        description: '"A Harlem Pizza shop."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6551,
        store_longitude: -73.9599,
        total_orders: 5056.88,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Zombie, Beer, Wine, Rum, Caribbean, Ale, Fruity, Delicious, Hip, Fun',
        annual_dol: 5561.53,
        description:
            'Bamboo accents this tiki bar specializing in fruity mixed drinks featuring Caribbean rum.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7635,
        store_longitude: -73.9138,
        total_orders: 328.51,
        neighborhood: 'Astoria/LIC',
        keywords: 'Pizza, Beer, Bbq, Delicious, American, Fancy, Relaxed, Great, Casual, Local',
        annual_dol: 335.48,
        description:
            'Relaxed neighborhood saloon featuring craft beers, bar bites & theme-night specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6631,
        store_longitude: -73.9845,
        total_orders: 253.41,
        neighborhood: 'South Slope/Park Slope',
        keywords:
            'Salad, Rich, Seasonal, Asian, Contemporary, Sophisticated, Local, Casual, Global, Indian',
        annual_dol: 1035.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6981,
        store_longitude: -73.9369,
        total_orders: 132.95,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Wine, Whiskey, Tasty, Ale, Bourbon, Pizza, Cozy, Mead, Organic',
        annual_dol: 192.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7686,
        store_longitude: -73.9694,
        total_orders: 739.43,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Cosmopolitan, Elegant, French, Lively',
        annual_dol: 755.12,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6981,
        store_longitude: -73.937,
        total_orders: 262.56,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Coffee, Cozy, Casual, Hotel',
        annual_dol: 313.64,
        description:
            '"A cozy, neighborhood sports bar with two pool tables, darts, and drink specials."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7694,
        store_longitude: -73.9581,
        total_orders: 67.15,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Tacos, Tequila, Mexican, Steak, Beer, Margarita, Tex-Mex, Ale, Fajitas, Delicious',
        annual_dol: 131.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.94,
        total_orders: 13678.02,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Dutch, Stylish, Local, Classic, Casual, Intimate, Great, Fine, Signature',
        annual_dol: 14075.49,
        description:
            'Classic & newly invented craft cocktails served with hand-cut ice in a dark, throwback-style space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7752,
        store_longitude: -73.9568,
        total_orders: 65.5,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Pizza, Burger, Italian, Wine, Dessert, Vodka, Tasty, American, Rustic, Hotel',
        annual_dol: 97.14,
        description:
            'Brick-oven pizzas & Italian staples in a family-friendly setting with seasonal outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7397,
        store_longitude: -74.0082,
        total_orders: 509.36,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Italian, Salad, Gluten Free, Delicious, Friendly, Fun, Ample, Cool, Casual, Vibrant',
        annual_dol: 2262.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8261,
        store_longitude: -73.8196,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Steak, Pizza, Italian, Salad, Wine, Delicious, American, Classic, Authentic, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7849,
        store_longitude: -73.9826,
        total_orders: 681.25,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, French, Casual, American, Crisp, Homemade, Seasonal, Hummus, Fun, Great',
        annual_dol: 4370.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6764,
        store_longitude: -73.5576,
        total_orders: 642.51,
        neighborhood: '',
        keywords:
            'Whiskey, Beer, Bourbon, Tacos, Burger, Bbq, American, Irish, Delicious, Homemade',
        annual_dol: 1165.98,
        description:
            '"Fun, atmospheric gastropub featuring burgers, wings, grilled cheeses, wraps & delicious apps. Craft cocktails & craft beers from local breweries. Trivia nights & Happy hour 12-7pm every day. To make a reservation or book a private party, call 516-544-4797"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6907,
        store_longitude: -73.5498,
        total_orders: 23.8,
        neighborhood: '',
        keywords: 'Beer, Comfortable, Friendly, Hotel, Casual, Fresh, Classic, Great, Local',
        annual_dol: 97.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7029,
        store_longitude: -73.9014,
        total_orders: 270.8,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Vegan, Vegetarian, Burger, Salad, Coffee, Creamy, Friendly, Casual, French, Cool',
        annual_dol: 1106.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9165,
        total_orders: 585.55,
        neighborhood: 'Astoria',
        keywords: 'Steak, Burger, Hamburger, Salad, Organic, Bbq, Wine, American, Artisanal, Cozy',
        annual_dol: 616.77,
        description:
            'Grass-fed meat & organic chicken are barbecued or sold by the pound at this eatery/butcher shop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6618,
        store_longitude: -73.9932,
        total_orders: 2038.68,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Cajun, Coffee, Spicy, Austrian, Rum, American, Caribbean, African, Asian, Creole',
        annual_dol: 2085.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.9202,
        total_orders: 21.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Mexican, Tequila, Salad, Margarita, Dessert, Delicious, Sweet, Western, Casual',
        annual_dol: 88.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6398,
        store_longitude: -73.7429,
        total_orders: 240.52,
        neighborhood: 'Rosedale',
        keywords:
            'Lobster, Beer, Fried Calamari, Naan Bread, Delicious, Rum, Polish, Moscow Mule, Friendly, Outstanding',
        annual_dol: 920.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6978,
        store_longitude: -73.931,
        total_orders: 36.9,
        neighborhood: 'Bushwick',
        keywords:
            'Coffee, Delicious, Thai, Seasonal, Creative, Casual, Local, Great, Cultural, Unique',
        annual_dol: 150.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.625,
        store_longitude: -74.1432,
        total_orders: 14.99,
        neighborhood: 'Port Richmond',
        keywords: 'French Toast, French, Omelette, Casual',
        annual_dol: 49.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7069,
        store_longitude: -73.9223,
        total_orders: 797.25,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Karaoke, Premium, Fancy, Great, Cool, Signature',
        annual_dol: 3258.91,
        description:
            '"Craft libations, curated wines & beer, dj nights, enjoy the elevated vibes & dance the night away 🔥"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7034,
        store_longitude: -73.9319,
        total_orders: 708.79,
        neighborhood: 'East Williamsburg',
        keywords:
            'Salad, Wine, Peruvian, Uruguayan, Italian, Delicious, Venezuelan, Cozy, Rustic, Latin',
        annual_dol: 992.97,
        description:
            '"Uruguayan Bistro Bar, mixing Latin American, Italian & Spanish flavors in rustic chic space ."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7346,
        store_longitude: -73.6139,
        total_orders: 3.42,
        neighborhood: '',
        keywords:
            'Vegetarian, Chinese, Thai, Contemporary, Indian, Traditional, Rooftop, Friendly, Cool, Great',
        annual_dol: 14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7771,
        store_longitude: -73.9525,
        total_orders: 15.36,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Mexican, Beer, Salad, Nachos, Thai, Bbq, Casual, Classy, Local, Fresh',
        annual_dol: 124.21,
        description: '"Mexican restaurant"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.6463,
        total_orders: 6.67,
        neighborhood: '',
        keywords: 'Garlic Bread, Salad, French, Irish, Latin, Blt Sandwich, Casual',
        annual_dol: 27.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -73.6847,
        total_orders: 20.96,
        neighborhood: '',
        keywords: 'Pizza, Steak, Italian, Vodka, Salad, Beer, Homemade, French, Fresh, Casual',
        annual_dol: 23.34,
        description:
            'Family-friendly eatery offering a big menu of pizzas & Northern Italian dishes, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6668,
        store_longitude: -73.9846,
        total_orders: 688.51,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Italian, Tasty, Wine, Organic, Bruschetta, Classy',
        annual_dol: 708.83,
        description:
            'Pasta, panini & pizza paired with wines & cocktails is the formula at this mellow local hangout.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8544,
        store_longitude: -73.8888,
        total_orders: 4550.01,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Italian, Pizza, Shrimp Cocktail, Wine, Coffee, Tangy, Warm, Vibrant, Traditional, Fresh',
        annual_dol: 4600.23,
        description:
            'Cozy, unpretentious neighborhood restaurant serving classic red-sauce Italian fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7328,
        store_longitude: -73.9545,
        total_orders: 1470.02,
        neighborhood: 'Greenpoint',
        keywords:
            'Steak, Delicious, Creamy, Asian, Thai, Innovative, Friendly, Sweet, Amazing, Authentic',
        annual_dol: 1742,
        description: '"Share Asia: Experience through cocktails & food"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7706,
        store_longitude: -73.9597,
        total_orders: 34.19,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Coffee, Wine, Canadian, Dessert, Beer, French Toast, Swiss, French Onion Soup, French',
        annual_dol: 47.9,
        description:
            'Pancakes & omelets, plus burgers, shakes & salads, with lines for weekend brunch. Cash only.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7696,
        store_longitude: -73.9549,
        total_orders: 125.26,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Pizza, Salad, Italian, Bruschetta, Caesar Salad, Homemade, Smoky, Local, Casual, Authentic',
        annual_dol: 282.72,
        description:
            'Casual chain restaurant specializing in wood-fired, Neapolitan pizza, plus classic Italian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7084,
        store_longitude: -73.9377,
        total_orders: 442.98,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Wine, Warm, Bbq, Casual, Amazing, Great, Unique',
        annual_dol: 1810.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6841,
        store_longitude: -73.9925,
        total_orders: 1028.01,
        neighborhood: 'Carroll Gardens/Cobble Hill',
        keywords:
            'Sushi, Beer, Comfortable, Italian, Friendly, Sweet, Casual, Irish, Local, American',
        annual_dol: 2547.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7809,
        store_longitude: -73.9806,
        total_orders: 1553.98,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Wine, Beer, Trendy, Delicious, Cozy, Fun, Yummy, Comfortable, Irish, Friendly',
        annual_dol: 1592.26,
        description:
            'This cool hot spot with a cozy retro vibe showcases inventive cocktails & global small plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6184,
        store_longitude: -73.7545,
        total_orders: 641.57,
        neighborhood: 'Far Rockaway',
        keywords: 'Fabulous, Fresh, Premium',
        annual_dol: 766.39,
        description:
            'Inwood Country Club is a private Golf, Tennis & Beach Club in Inwood, New York, located adjacent to Jamaica Bay and just southeast of John F. Kennedy International Airport. Originally established as nine-hole course in 1901, it is one of the oldest golf courses on Long Island.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8146,
        store_longitude: -73.9594,
        total_orders: 3042.05,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Coffee, Tasty, Beer, Vegetarian, Trendy, Avocado Toast, Healthy, Premium, American, Clean',
        annual_dol: 3238.43,
        description:
            'Hip venue for craft cocktails, beer & light fare at night & cafe bites, coffee & smoothies by day.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7673,
        store_longitude: -73.9838,
        total_orders: 2999.98,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegan, Burger, Vegetarian, Hamburger, Gluten Free, Organic, Beer, Sustainable, Trendy, Clean',
        annual_dol: 3416.77,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -73.9854,
        total_orders: 504.41,
        neighborhood: 'Two Bridges',
        keywords:
            'Wine, Beer, Spanish, Dessert, Savory, Romantic, Trendy, Coffee, Upscale, Dominican',
        annual_dol: 1461.45,
        description:
            'A daily-changing menu of pintxos, mains & desserts is served in a relaxed space with globe lights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.99,
        total_orders: 10.93,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Salad, Dessert, Wine, Meatball, Magical, Homey, Homemade, Amazing, Excellent',
        annual_dol: 44.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7658,
        store_longitude: -73.9823,
        total_orders: 149.93,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Mexican, Sushi, Pizza, Salad, Hotel, Western, Local, Casual, Fresh, Classic',
        annual_dol: 674.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6775,
        store_longitude: -73.8287,
        total_orders: 562.96,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'Burger, Vodka, Hotel, Western, Fancy, Vibrant',
        annual_dol: 2301.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6759,
        store_longitude: -73.8194,
        total_orders: 372.43,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Nachos, Swiss, Philly Cheesesteak, Classic, Great, Casual',
        annual_dol: 1522.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6855,
        store_longitude: -73.9816,
        total_orders: 2086.98,
        neighborhood: 'Boerum Hill',
        keywords: 'Burger, Tequila, Salad, Beer, Whiskey, Spicy, Bbq, Cozy, Irish, American',
        annual_dol: 2152.84,
        description:
            'Rustic-chic bar offering cocktails, craft brews & American comfort bites in cozy, candle-lit digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7392,
        store_longitude: -74.0015,
        total_orders: 12117.28,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Burger, Hotdog, Cheesesteak, Beer, Swiss, Irish, Philly Cheesesteak, Ale, French',
        annual_dol: 12488.87,
        description:
            'Time-tested pub serving burgers, sandwiches & bar bites, plus draft beer, cocktails & sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7429,
        store_longitude: -74.0037,
        total_orders: 28.94,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Vodka, Mimosa, Green Papaya Salad, Vietnamese, Thai, Bellini, Papaya Salad, Bloody Mary, Asian',
        annual_dol: 109.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7337,
        store_longitude: -73.9982,
        total_orders: 1066.48,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Burger, Italian, Tasty, Wine, Premium, Polished, Fabulous, Amazing, Great, Seasonal',
        annual_dol: 3743.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7462,
        store_longitude: -73.9976,
        total_orders: 112.32,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Mexican, Italian, Tasty, Beer, Salad, Chinese, Crisp, Smoky, Premium',
        annual_dol: 459.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7111,
        store_longitude: -73.948,
        total_orders: 465.87,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Steak, Gluten Free, American, Casual, Cool, Awesome, Diverse',
        annual_dol: 522.57,
        description:
            'A diverse menu of steaks, entrees & snacks served in a clubby atmosphere with Skee-Ball.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7127,
        store_longitude: -73.9562,
        total_orders: 30121.15,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Upscale, Bourbon, Elegant, Cozy, Comfortable, Quaint, Delicious, Clean, Perfect',
        annual_dol: 32575.64,
        description:
            'Funky bar with frequent live music, an outdoor area & a downstairs restaurant with a compact menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 2268.8,
        neighborhood: 'New Springville/Mid Island',
        keywords: 'Burger, Zombie, Hamburger, Beer, Margarita, Hawaiian, Wine, Ale, Fun, American',
        annual_dol: 2316.94,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.9779,
        total_orders: 7567.48,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Beer, Sushi, Fun, Awesome, Eclectic, Amazing, Turkish, Great, Cool, Classic',
        annual_dol: 7912.73,
        description:
            'Cool, compact live-music venue with a unique center-of-the-room stage & its own record label.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7142,
        store_longitude: -73.954,
        total_orders: 1031.47,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Old Fashioned, Intimate, Perfect, Cool, Fresh, Great, Daiquiri, Casual, Signature',
        annual_dol: 1149.33,
        description:
            'Cool watering hole with outdoor seats doling out snacks, signature cocktails & draft beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7081,
        store_longitude: -74.002,
        total_orders: 714.9,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Tacos, Lobster, Mexican, Beer, Vegetarian, Gluten Free, Vodka, Wine, New-York-Style',
        annual_dol: 777.99,
        description:
            'Updated pub grub, cocktails & NY craft brews served in a bi-level venue with brick-lined walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7174,
        store_longitude: -73.9562,
        total_orders: 76.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Beer, Cozy, Quaint, Friendly, Casual, Authentic, Mediterranean, Perfect, Lit, Local',
        annual_dol: 312.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7225,
        store_longitude: -73.9899,
        total_orders: 125.48,
        neighborhood: 'Bowery',
        keywords: 'Upscale, Karaoke, Hotel, Great, Hip, Cool, Casual',
        annual_dol: 512.91,
        description:
            'Snug nightclub with cocktails, hip-hop music & DJs, open late Wednesday–Saturday.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9905,
        total_orders: 2633.15,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Salad, Coffee, Gluten Free, Wine, Avocado Toast, Delicious, Australian, Bbq, Hotel',
        annual_dol: 2726.36,
        description:
            'Chic all-day cafe & bar serving locally sourced American bites with an Australian twist.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -73.9935,
        total_orders: 2691.75,
        neighborhood: 'Bowery',
        keywords:
            'Tasty, Burger, Champagne, Wine, Whiskey, Trendy, Elegant, Coffee, Rustic, Friendly',
        annual_dol: 2748.87,
        description:
            'Rustic-chic hangout with late hours & a full raw bar, plus French-inspired small plates & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8893,
        store_longitude: -73.9024,
        total_orders: 72.55,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Pizza, Savory, Nutritious, Excellent, Distinct',
        annual_dol: 296.56,
        description:
            'Manhattan College is a private, Catholic, liberal arts university in the Bronx, New York City. Originally established in 1853 by the Brothers of the Christian Schools as an academy for day students, it ...',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8979,
        store_longitude: -73.8674,
        total_orders: 46.86,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords:
            'Wine, Beer, Irish, Tranquil, Elegant, Friendly, Asian, Casual, Traditional, Unique',
        annual_dol: 60.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7654,
        store_longitude: -73.9873,
        total_orders: 2181.56,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Beer, Wine, Gourmet, Tomato Soup, American, Seasonal, Classy, Local, Casual',
        annual_dol: 2303.42,
        description:
            'Cavernous, rustic-chic hangout with rotating craft-beer taps & small-batch whiskeys plus late hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7681,
        store_longitude: -73.9843,
        total_orders: 2509.12,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Hamburger, Vegan, Tequila, Gourmet, Tasty, Hotel, Delicious, Modern, Polished',
        annual_dol: 10256.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7684,
        store_longitude: -73.9856,
        total_orders: 29.87,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Pizza, Swiss, American, Greek, Thai, Mediterranean, Healthy, Homey, Fresh',
        annual_dol: 56.38,
        description:
            'No-frills diner serving up a vast menu of homey Greek & American fare around the clock.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9834,
        total_orders: 158.27,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Beer, Caesar Salad, American, Delicious, Casual, Classic, Natural',
        annual_dol: 646.95,
        description:
            '"#1 Best Burger Joint in NYC Zagat 2017 & 2018, #1 Burger NYC TripAdvisor. Craft Beer & Full Liquor, Craft Cocktails. Chef made burgers. Late night, DJ, Live Music"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8893,
        store_longitude: -73.9024,
        total_orders: 201.03,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Pizza, Coffee, Savory, Hotel, Asian, Casual, Western, Local',
        annual_dol: 821.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7431,
        store_longitude: -74.0073,
        total_orders: 2287.41,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Lobster, Sushi, Pizza, Italian, Japanese, Scotch, Sashimi, Upscale, Asian',
        annual_dol: 9407.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7391,
        store_longitude: -74.0013,
        total_orders: 363.22,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Wine, Ale, Gluten Free, Whiskey, Tasty, Italian, Warm, Woody, American',
        annual_dol: 1484.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7394,
        store_longitude: -74.0013,
        total_orders: 764.13,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Spanish, Pizza, Wine, Romantic, American, Homey, Sweet, Omelette, Traditional, Classic',
        annual_dol: 2080.44,
        description:
            'Laid-back hangout below the Spanish Benevolent Society, serving traditional Spanish food & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5599,
        store_longitude: -74.1698,
        total_orders: 71.22,
        neighborhood: 'Arden Heights',
        keywords: 'Fun, Great',
        annual_dol: 232.53,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7281,
        store_longitude: -73.9814,
        total_orders: 4386.84,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Vodka, Beer, Gin, Ale, Rum, Irish, Classic, Tradition, Bright, Great',
        annual_dol: 4639.3,
        description:
            'This neighborhood Irish pub is big with Liverpool fans & it hosts occasional live music & poetry.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.71,
        store_longitude: -74.0089,
        total_orders: 4477.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Whiskey, Beer, Irish, French Onion Soup, French, Caesar Salad, Prosecco, American',
        annual_dol: 4612.16,
        description:
            'Inviting Irish pub with an American twist offering hearty bar food, draft beer, TVs & a game room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7079,
        store_longitude: -74.0015,
        total_orders: 1549.26,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Italian, Delicious, Bellini, Fabulous, Gracious, Awesome, Fantastic, Great, Excellent',
        annual_dol: 1663.74,
        description:
            'Fashionable rooms with contemporary decor feature smart TVs, Italian linens and rainfall showers. Open-plan, 1- to 3-bedroom suites offer bridge and river views. Most have furnished terraces.A posh restaurant serves Italian fare, while a slick bar offers cocktails. Other amenities include a gym, personal training and babysitting service.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.715,
        store_longitude: -73.9443,
        total_orders: 437.41,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Pizza, Italian, Mexican, Chicken Parmigiana, American, Veal Parmigiana, Local, Casual, Classic',
        annual_dol: 456.38,
        description:
            'Thin-crust pizza, pasta & other familiar Italian dishes are served at this neighborhood eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7082,
        store_longitude: -74.0057,
        total_orders: 1066.78,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Coffee, Wine, Tasty, Trendy, Martini, Australian, American, Superb, Awesome, Perfect',
        annual_dol: 1727.71,
        description:
            '"Serves speciality coffee, Aussie brunch favorites and a seasonally charged dinner menu alongside an extensive wine and seasonal cocktail list"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.715,
        store_longitude: -73.959,
        total_orders: 267.05,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotel, Spacious, Trendy, Coffee, Cozy, Comfortable, Awesome, Fun, Clean, Friendly',
        annual_dol: 1091.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7098,
        store_longitude: -74.0065,
        total_orders: 119.93,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Burritos, Chinese, Local, Casual, Delicious, Healthy',
        annual_dol: 704.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7244,
        store_longitude: -73.9789,
        total_orders: 284.35,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Tacos, Mexican, Salad, Vodka, Margarita, Bourbon, Upscale, Rum, Spicy, Sweet',
        annual_dol: 632.26,
        description:
            '"We are Gringo-Mex since \'86. Our irresistable tacos and boozy margaritas have kept the locals coming back for over three decades. We pride ourselves on keeping it real... real food, real people, real New York."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6764,
        store_longitude: -73.9633,
        total_orders: 86.18,
        neighborhood: 'Crown Heights',
        keywords: 'Beer, Comfortable, Intimate, Great, Local, Signature',
        annual_dol: 352.28,
        description:
            '"Dorsettbk is a venue bar lounge . Located in the heart of Brooklyns museum garden district just minutes away from Manhattan. A lofty feel with white walls and high ceiling. The Intimate gathering space is a great Space for friends and family to gather you can have meetings showers engagement birthday parties . We pride ourselves on ensuring our customers are comfortable while having a great time. So book us for a Private. event or just stop by"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9219,
        total_orders: 1334.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Beer, Wine, Bourbon, Casual, Retro, Fresh, Dope, Local, Great, Signature',
        annual_dol: 1391.93,
        description:
            'Brick-lined, Asbury Park-themed joint with a patio for craft beer, cocktails, hot dogs & pinball.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9828,
        total_orders: 229.36,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Chic',
        annual_dol: 937.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.747,
        store_longitude: -73.974,
        total_orders: 478.53,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Hamburger, Salad, Beer, Dessert, Wine, Swiss, Delicious, American, Legendary',
        annual_dol: 545.85,
        description:
            'Legendary firefighter themed sports bar serving American fare & pub grub, plus all-day happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7516,
        store_longitude: -73.6025,
        total_orders: 385.07,
        neighborhood: '',
        keywords:
            'Salad, Shrimp Cocktail, Wine, Dessert, Italian, Martini, Beer, Rustic, Crisp, American',
        annual_dol: 549.85,
        description:
            'Traditional Italian cooking served in an intimate setting with a fireplace & rustic wood beams.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.837,
        store_longitude: -73.7022,
        total_orders: 88.58,
        neighborhood: '',
        keywords:
            'Sushi, Burger, Peruvian, Wine, Dessert, Japanese, Martini, Spicy, Beer, Delicious',
        annual_dol: 362.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9945,
        total_orders: 553.83,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lobster, Salad, Wine, Vodka, Italian, Mexican, Beer, Upscale, Velvety, Chinese',
        annual_dol: 616.19,
        description:
            'Quaint, white-tablecloth Italian spot with an emphasis on regional specialties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9491,
        total_orders: 6242.38,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Lobster, Sushi, Hotel, Vegan, South American, Sashimi, Spicy, Rooftop, Asian, American',
        annual_dol: 16113.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5235,
        store_longitude: -74.2347,
        total_orders: 36.11,
        neighborhood: 'Charleston',
        keywords: 'Pizza, Steak, Salad, Italian, Wine, Beer, American, Classy, Sweet, Casual',
        annual_dol: 39.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7282,
        store_longitude: -73.9914,
        total_orders: 3421.94,
        neighborhood: 'NoHo/East Village',
        keywords: 'Sushi, Steak, Japanese, Dessert, Wine, Vodka, Beer, Bbq, Juicy, Spicy',
        annual_dol: 4735.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7363,
        store_longitude: -73.9852,
        total_orders: 1035.66,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Tacos, Steak, Tequila, Mexican, Beer, Margarita, Spicy, Delicious, Tex-Mex, Enchiladas',
        annual_dol: 1057.64,
        description: '"Authentic Mexican restaurant with awesome tacos and great fresh Margaritas"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7533,
        store_longitude: -73.9344,
        total_orders: 10,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Mexican, Margarita, Hotel, Dessert, Warm, Classic, Authentic, Club Sandwich, Casual, Local',
        annual_dol: 40.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.986,
        total_orders: 11.72,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Sushi, Salad, Filipino, Beer, Wine, Warm, Asian, Cozy, Fresh, Lively',
        annual_dol: 47.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5837,
        store_longitude: -73.9502,
        total_orders: 341.68,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Kebab, Salad, Turkish, Bbq, Spicy, Mediterranean, Greek, Local, Russian, Baba Ghanoush',
        annual_dol: 1389.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.9783,
        total_orders: 3552.87,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Hamburger, Vegetarian, Cajun, Tasty, Irish, Casual, Traditional, Perfect, Classic, Rooftop',
        annual_dol: 3706.91,
        description:
            'Pub with Irish-style decor & several beers on tap along with a full menu & frequent live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.8312,
        total_orders: 301.59,
        neighborhood: 'Flushing',
        keywords: 'Sushi, Salad, Trendy, Japanese, Beer, Ale, Sleek, Premium, High-End, Casual',
        annual_dol: 1232.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8555,
        store_longitude: -73.938,
        total_orders: 90.18,
        neighborhood: 'Washington Heights/Hudson Heights',
        keywords:
            'Cheesecake, Steak, Tasty, Dessert, Hawaiian, Delicious, Warm, Italian, Spanish, Welcoming',
        annual_dol: 368.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.847,
        store_longitude: -73.938,
        total_orders: 41.43,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Steak, Milkshake, Peruvian, Flavorful, Dessert, Tasty, Coffee, Chinese, Cozy, Pinacolada',
        annual_dol: 119.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.9658,
        total_orders: 5828.7,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Upscale, Elegant, Tasty, Cozy, Premium, Classy, Relaxed, Great, Fine',
        annual_dol: 5987.26,
        description:
            'Gay cocktail lounge with 3 bars (including a piano room with nightly performances) & a dress code.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6255,
        store_longitude: -74.03,
        total_orders: 2023.44,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Mimosa, Bbq, Coffee, Spicy, Mediterranean, Ribeye Steak',
        annual_dol: 2023.44,
        description:
            'Mellow bar & restaurant offering Middle Eastern fare as well as a hookah lounge & tented back patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7457,
        store_longitude: -73.9882,
        total_orders: 13551.07,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Hotel, Vodka, Coffee, Gin, Stylish, Chinese, Salad, Vintage, Classy, Nachos',
        annual_dol: 13691.03,
        description:
            "Garden and 7 minutes on foot from the Empire State Building.Compact, stylish rooms with vintage decor offer minibars, free Wi-Fi and flat-screen TVs. Upgraded rooms add original artwork, fridges and/or turntables; some also have sitting areas. Airy suites feature living rooms, wood floors and street views; some come with claw-foot tubs. Room service is available.There's a buzzy lobby lounge, a chic restaurant and an oyster bar, plus an art gallery.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9877,
        total_orders: 1739.82,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Vegetarian, Vegan, Salad, Wine, Lebanese, Beer, Mediterranean, Upscale',
        annual_dol: 1855.48,
        description:
            'Creative Lebanese sharing plates, entrees & cocktails served up in a trendy, modern space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9953,
        total_orders: 860.22,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Beer, Karaoke, Irish, American, Authentic, Perfect, Great, Excellent, Fine, Casual',
        annual_dol: 3241.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6185,
        store_longitude: -74.0212,
        total_orders: 1129.73,
        neighborhood: 'Dyker Heights',
        keywords: 'Spanish, Golfclub',
        annual_dol: 1209.68,
        description:
            'The Dyker Beach Park and Golf Course is a public park and a municipal, 18-hole, championship golf course in the southernmost part of Dyker Heights, Brooklyn, in New York City, United States.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7602,
        store_longitude: -73.9886,
        total_orders: 325.63,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Steak, Italian, Wine, Upscale, Elegant, Opulent, Romantic, Fabulous, Excellent',
        annual_dol: 338.71,
        description:
            'Elegant Northern Italian trattoria with scenic garden patio serving an upscale menu since 1906.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.99,
        total_orders: 1283.84,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Mexican, Irish, Phenomenal, Friendly, Comfortable, Welcoming, Natural, Classic, Local, Great',
        annual_dol: 1311.08,
        description:
            'Comfortable Irish haunt draws neighborhood locals with darts & a welcoming vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6946,
        store_longitude: -73.9932,
        total_orders: 175.64,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Lasagna, Shrimp Cocktail, Italian, Wine, Delicious, Cozy, Homemade, Friendly, Perfect, Casual',
        annual_dol: 717.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7146,
        store_longitude: -74.0161,
        total_orders: 703.5,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Pizza, Chicken Parmigiana, Italian, Gourmet, Rich, Ramen, French, Polished, American, Authentic',
        annual_dol: 734,
        description:
            'Chicken parm & other Italian-American classics go gourmet at this hip, diner-inspired space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7407,
        store_longitude: -74.0079,
        total_orders: 12920.3,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Steak, Burger, Wine, Champagne, Salad, Hotel, Beer, Tasty, Trendy, Rooftop',
        annual_dol: 13505.4,
        description:
            "from bustling nightlife in the Meatpacking District.Featuring floor-to-ceiling windows with river or city views, the streamlined rooms have minibars, free Wi-Fi and flat-screen TVs. Suites add soaking tubs and/or living rooms. Room service is available 24/7.There's a New American steakhouse, a cafe and a beer garden, plus 2 rooftop bars, including a nightclub. A hip cafe has a seasonal ice-skating rink and an art gallery. Off-site parking is offered (fee).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7334,
        store_longitude: -74.0062,
        total_orders: 36702.82,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Cozy, Legendary, Rum, Premium, Classic, Heritage, Casual, Daiquiri',
        annual_dol: 38487.15,
        description: 'Inventive drinks are poured at this retro-looking, Prohibition-style bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7301,
        store_longitude: -74.005,
        total_orders: 20917.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Whiskey Sour, Wine, Whiskey, Tasty, Beer, Gin, Perfect, Handcrafted, Fun, Classic',
        annual_dol: 32728.84,
        description:
            'Underground den of mixology featuring a vast menu of handcrafted cocktails in a snug space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -74.0039,
        total_orders: 355.49,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Upscale, French, Elegant, American, Delicious, Natural, Seasonal, Fun, Casual',
        annual_dol: 435.46,
        description:
            'Casual haunt for European-inspired bistro fare paired with natural wine & seasonal cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -74.004,
        total_orders: 157.64,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Wine, Delicious, Warm, Beer, Tranquil, Inviting, Asian, Friendly, Perfect',
        annual_dol: 175.52,
        description:
            '"Ama Raw Bar West Village is a unique Asian influenced raw bar that offers fresh seafood, craft cocktails, sake & wine in a warm inviting neighborhood restaurant and bar. We are known for our amazing $1 oyster happy hours, cocktail specials, & our menu that lets you taste a variety of bao buns & other vegetarian and seafood dishes that draw from the great flavors found all around Asia. Host your next Holiday party, charity event, or birthday party with us, and we will craft a personalized menu perfect for any affair."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7746,
        store_longitude: -73.8711,
        total_orders: 787.62,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Burger, Italian, Wine, Beer, Hotel, Innovative, Fancy, High-End, Entertaining, American',
        annual_dol: 2765.01,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8488,
        store_longitude: -73.8621,
        total_orders: 1790.21,
        neighborhood: 'Morris Park/Van Nest',
        keywords:
            'Steak, Salad, Chicken Parmigiana, Italian, Meatball, Dessert, Wine, Thai, Hotel, Phenomenal',
        annual_dol: 2489.03,
        description:
            'Longtime Italian eatery featuring a raw bar & classic entrees, including steak & seafood dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -73.907,
        total_orders: 1502.42,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Cozy, Fun, Friendly, Awesome, Eclectic, Cool, Perfect, Great, Casual',
        annual_dol: 1602.3,
        description:
            'Loaded fries, sandwiches, cocktails & beer in an easygoing bar with offbeat decor & a pool table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6931,
        store_longitude: -73.9006,
        total_orders: 1117.93,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords:
            'Burger, Vegan, Salad, Japanese, Beer, Wine, Friendly, Awesome, Caesar Salad, Aesthetic',
        annual_dol: 2305.33,
        description:
            'Cool, mod taproom with clever bar bites & local beers, plus a big backyard & late-night DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6773,
        store_longitude: -73.986,
        total_orders: 409.26,
        neighborhood: 'Gowanus',
        keywords:
            'Salad, Whiskey, Coffee, Delicious, Brownie Sundae, Romantic, Seasonal, American, Contemporary, Casual',
        annual_dol: 1672.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7396,
        store_longitude: -74.0065,
        total_orders: 576.78,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Lasagna, Steak, Vegetarian, Lobster, Pizza, Italian, Gourmet, Wine, Delicious',
        annual_dol: 615.13,
        description:
            'Thin-crust pizza & homemade pasta head the menu at this exposed-brick space in a 4-story townhouse.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7385,
        store_longitude: -74.0035,
        total_orders: 5048.68,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Beer, Ale, Martini, Cozy, Rum, Comfortable, Spicy, Seasonal',
        annual_dol: 5488.66,
        description:
            'Village mainstay with comfortable, dimly lit back room offers cocktails & bar food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7351,
        store_longitude: -74.0061,
        total_orders: 2203.74,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Italian, Tasty, Salad, Wine, Dessert, Gluten Free, Stylish, Trendy, Martini',
        annual_dol: 3647.4,
        description:
            'Celebrated, stylish seafood & grill restaurant offshoot with an aperitivo bar & a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.735,
        store_longitude: -74.0004,
        total_orders: 1380.02,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Tacos, Hamburger, Salad, Wine, Spicy, Upscale, Delicious, Trendy, French',
        annual_dol: 1878.64,
        description:
            'A menu of classic American bistro dishes & cocktails in a traditional dining room, fit for groups.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7219,
        store_longitude: -73.6414,
        total_orders: 63.62,
        neighborhood: '',
        keywords: 'Golfclub, Clean, Friendly, Perfect, Local',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7102,
        store_longitude: -73.8647,
        total_orders: 833.18,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords:
            'Burger, Pizza, Steak, Sushi, Shrimp Cocktail, Beer, Nachos, Flavorful, Irish, Wine',
        annual_dol: 895.54,
        description:
            'Roomy local tavern with a wide menu of tap beers offering pub eats, darts, karaoke & trivia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7046,
        store_longitude: -73.9111,
        total_orders: 3453.05,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Wine, Vegan, Beer, Trendy, Ale, Italian, Polish, Cozy, Polished, Chinese',
        annual_dol: 3526.32,
        description:
            'Polished neighborhood bar with craft beer & international snacks, plus vintage pinball machines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.694,
        store_longitude: -73.683,
        total_orders: 672.51,
        neighborhood: '',
        keywords: 'Salad, Upscale, Perfect, Fresh, Local',
        annual_dol: 724.81,
        description:
            '"WEDDING VENUE: We specialize in Weddings and perfect them by hosting them one at a time providing the perfect setting of exclusivity. Our guests receive the ultimate treatment upon arrival with the intuitive service, and the award winning cuisine offered. Our goal is that every single guest dining with us will have the ultimate dining experience."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7083,
        store_longitude: -73.8978,
        total_orders: 102.08,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Steak, Burger, Tacos, Mexican, Coffee, Margarita, Cozy, Fresh, Classic, Casual',
        annual_dol: 417.27,
        description: '"Fine Mexican dining in the heart of Ridgewood"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6739,
        store_longitude: -73.9826,
        total_orders: 311.69,
        neighborhood: 'Park Slope',
        keywords:
            'Steak, Mexican, Coffee, Margarita, Spicy, Trendy, Delicious, American, Phenomenal, Clean',
        annual_dol: 477.46,
        description:
            '"With more than 10 years serving the Windsor Terrace community and more than 25 years in the restaurant business, we are happy to announce the opening of our new location in Park Slope, Brooklyn. We serve Breakfast All day, Lunch and Dinner. Come in and grab a bite. Open 8 am to 10 pm daily!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6596,
        store_longitude: -73.9878,
        total_orders: 1856.12,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Hotdog, Beer, Cheesesteak, Salad, Wine, Philly Cheesesteak, Rustic, Relaxed, Spacious',
        annual_dol: 2186.35,
        description:
            'A former gas station houses this 13,000-sq.-ft. indoor/outdoor pub with food, games & a fireplace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6615,
        store_longitude: -73.9931,
        total_orders: 379.49,
        neighborhood: 'South Slope/Sunset Park',
        keywords: 'Pizza, Beer, Trendy, Karaoke, Welcoming, Perfect, Great, Incredible, Casual',
        annual_dol: 443.38,
        description:
            '"Brooklyn’s Best Happy Hour!! Buy 1, Get 1 Free 3-7 Daily. Pool, Huge Backyard, Pinball and A Vibe Welcoming All Who Want To Enjoy This Incredible Experience!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6708,
        store_longitude: -73.9852,
        total_orders: 138.46,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Spacious, Spectacular, Fun, Great, Incredible, Classic, Modern',
        annual_dol: 825.98,
        description:
            '"Mixology Bar and Tapas Restaurant. Open late. Great for large groups, semi-private and private events as well. We are a speakeasy grown over by nature, with inventive cocktails, fun small plates and a ten-foot living wall. Reach out to info@manahospitalitygroup.com for bookings and more info."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.719,
        store_longitude: -73.9433,
        total_orders: 3358.67,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Vegetarian, Coffee, Savory, Beer, Margarita, Peruvian, Italian, Delicious, Cozy',
        annual_dol: 3672.11,
        description:
            'Comfy neighborhood haunt offering Mediterranean food & cocktails in a stylish, intimate atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7678,
        store_longitude: -73.9593,
        total_orders: 284.25,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Steak, Wine, Salad, French Toast, French, Elegant, Juicy, Polished, Fun, Sweet',
        annual_dol: 516.57,
        description:
            'Narrow, warm-colored bistro dishing up classic French fare in a relaxed, colorful space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7336,
        store_longitude: -73.9584,
        total_orders: 1605.26,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Steak, Scotch, Delicious, American, Lively, Great, Excellent, Urban, Intimate',
        annual_dol: 1674.86,
        description: '"New American Cocktail Bar & Bistro"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8542,
        store_longitude: -73.8889,
        total_orders: 430.56,
        neighborhood: 'Little Italy/Belmont',
        keywords: 'Vodka, Pizza, Beer, Italian, Quaint, Warm, Homemade, American, Friendly, Casual',
        annual_dol: 450.7,
        description:
            'Italian standby cooking hearty red-gravy standards in an understated setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8677,
        store_longitude: -73.8792,
        total_orders: 4965.42,
        neighborhood: '',
        keywords: 'Southern, Seasonal, Sweet, American, Local, Urban, Iconic, Modern, Chill',
        annual_dol: 6804.44,
        description:
            'The New York Botanical Garden is a botanical garden at Bronx Park in the Bronx, New York City. Established in 1891, it is located on a 250-acre site that contains a landscape with over one million living ...',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.781,
        store_longitude: -73.9764,
        total_orders: 108.14,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Mexican, Vegan, Margarita, Enchiladas, Western, Casual, Fresh, Vibrant, Colorful',
        annual_dol: 507.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7843,
        store_longitude: -73.9777,
        total_orders: 254.14,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Tequila, Sushi, Lobster, Wine, Vodka, Salad, Dessert, Italian, Spicy, Organic',
        annual_dol: 289.45,
        description:
            'A modern yet rustic space for seafood dishes, a raw bar, cocktails & an oyster happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.831,
        store_longitude: -73.8506,
        total_orders: 5.7,
        neighborhood: 'East Bronx/Unionport',
        keywords: 'Spanish, Dominican, Puerto Rican, Caribbean, Tasty, Clean, Casual',
        annual_dol: 23.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6961,
        store_longitude: -73.9047,
        total_orders: 3413.4,
        neighborhood: 'Bushwick/Flushing/Ridgewood',
        keywords:
            'Burger, Tasty, Beer, Breakfast Burrito, Swiss, Cozy, French, Modern, Vintage, Friendly',
        annual_dol: 3555.24,
        description:
            'Old-school watering hole with pool & pinball, velvet paintings, beer signs & similar kitschy decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6523,
        store_longitude: -73.9495,
        total_orders: 65.57,
        neighborhood: 'Little Haiti',
        keywords:
            'Vegan, Vegetarian, Burger, Lobster, Hamburger, Lobster Roll, Wine, Trendy, American, Delicious',
        annual_dol: 247.06,
        description:
            'Relaxed vegan eatery serving plant-based burgers, breakfast sandwiches & sides, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6754,
        store_longitude: -73.981,
        total_orders: 1444.77,
        neighborhood: 'Park Slope',
        keywords:
            'Korean, Delicious, Playful, Asian, Healthy, Contemporary, Fresh, Casual, Awesome, Fantastic',
        annual_dol: 1645.5,
        description: 'Playful takes on Korean classics & seafood in cozy, white-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7049,
        store_longitude: -73.933,
        total_orders: 1232.98,
        neighborhood: 'East Williamsburg',
        keywords:
            'Burger, Beer, Wine, Delicious, Stylish, Spacious, American, Awesome, Sweet, Fantastic',
        annual_dol: 1404.58,
        description:
            'Movie theater showing recent classics, plus a restaurant & bar serving American fare & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7225,
        store_longitude: -73.9483,
        total_orders: 604.41,
        neighborhood: 'Greenpoint',
        keywords: 'Polish, Hotel, Retro, Local, Casual',
        annual_dol: 688.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7317,
        store_longitude: -73.9577,
        total_orders: 96.21,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Margarita, Salad, Martini, American, Sweet, Caesar Salad, Perfect, Classic, Fantastic',
        annual_dol: 393.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6299,
        store_longitude: -74.0769,
        total_orders: 121.31,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Steak, Pizza, Chicken Parmigiana, Salad, Shrimp Cocktail, Italian, Meatball, Wine, Vodka, Margarita',
        annual_dol: 424.85,
        description:
            '"The Twisted Vineyard is meant to be an experience, from start to finish. Upon walking in you will be greeted with a smile and warm regards. We may ask you how you are feeling or what you’re in the mood to eat that particular night. Refresh yourself with some ice water and take a look at the menu. Any minute one of our servers will be by to let you know our specials for the evening. Have a healthy salad with cold crisp lettuce or some crunchy fried calamari. Enjoy a hot and cheesy classic pizza, or maybe a creamy vodka chicken parmigiana over delicious pasta. The portions are large and the price is fair but most importantly the service and food are outstanding!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8145,
        store_longitude: -73.8147,
        total_orders: 1206.51,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Elegant, Dessert, Exquisite, Perfect, Incredible, Iconic, Pure, Fresh, Fine, Signature',
        annual_dol: 1885.39,
        description:
            '"When you’re searching for a wedding venue in New York City, think of Marina Del Rey. We are the premiere New York City event venue for weddings, celebrations, corporate events, and more. For over 30 years, our family-owned business has provided incredible service and fine cuisine in a stunning location. Family-owned and experienced, we have served Westchester County, Queens, Nassau County, Yonkers & Manhattan, NY; and the surrounding areas for more than 30 years. Contact us today to get started or to book a tour of our beautiful venue."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7042,
        store_longitude: -74.0118,
        total_orders: 8098.07,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Beer, Salad, Ale, Irish, Delicious, Nachos, Innovative, Modern, Relaxed',
        annual_dol: 8314.51,
        description:
            'Multi-level hangout offering elevated pub fare, craft cocktails & brunch in a relaxed atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -74.004,
        total_orders: 2167.42,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vodka, Delicious, Gluten Free, Margarita, Romantic, Elegant, Salad, Martini, Gin, Italian',
        annual_dol: 2274.72,
        description:
            'Restaurant serving Mediterranean fare in a cozy, bi-level space with chandeliers & antique decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7453,
        store_longitude: -73.5921,
        total_orders: 596.68,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Steak, Japanese, Filet Mignon, Casual, Gyoza, Local, Fresh, Signature',
        annual_dol: 654.53,
        description: 'Hibachi chain serving Japanese dishes grilled tableside by theatrical chefs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -74.0018,
        total_orders: 554.35,
        neighborhood: 'SoHo/Canal Street',
        keywords:
            'Glamorous, Upscale, Delicious, Spectacular, Incredible, Aesthetic, Rooftop, Casual',
        annual_dol: 1022.26,
        description:
            'Upscale resource for furniture, decorative accessories, lighting, tableware & textiles, plus a cafe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6238,
        store_longitude: -73.9647,
        total_orders: 68.54,
        neighborhood: 'Midwood',
        keywords:
            'Steak, Burger, Sushi, Salad, Dessert, Coffee, Bbq, French, Mediterranean, Creme Brulee',
        annual_dol: 303.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5801,
        store_longitude: -73.8366,
        total_orders: 167.31,
        neighborhood: 'Far Rockaway/Rockaway Park',
        keywords:
            'Pizza, Steak, Italian, Dessert, American, Delicious, Homemade, Impeccable, Modern, Ribeye Steak',
        annual_dol: 422.1,
        description:
            '"Rocco\'s Of Roc Beach Authentic Italian Cuisine Is A Family Run Operation Possessing Over 100 Years Of Combined Restaurant Experience. Whether It Be Our Pasta, Our Bread, Or Our Pizza, Rather Than Import Our Ingredients, Our Italian Born Executive Chef And His Hand Picked Team Prepare Every Component Of The Process By Hand In Our Kitchen Everyday From Scratch. The Blueprint Is To Deliver Fun, Music And Good Times Using A Modern Aesthetic Vibe, Impeccable Service, Gracious Hospitality And Delicious Food Using Old World Authentic Italian Traditions. We Remain The Only Coal Brick Oven Pizza In Rockaway, And One Of The Select Handful In All Of New York. Welcome To The Family Sincerely, The Rocco\'s Familia"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.569,
        store_longitude: -74.1109,
        total_orders: 279.98,
        neighborhood: 'New Dorp Beach/New Dorp',
        keywords:
            'Lobster, Lobster Tail, Salad, Lobster Bisque, Cajun, Wine, Beer, Delicious, Creole, Yummy',
        annual_dol: 298.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7058,
        store_longitude: -74.0116,
        total_orders: 2459.1,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Cajun, Wine, Sushi, Upscale, Dessert, Polished, American, Modern, Classic',
        annual_dol: 2840.23,
        description:
            'High-end kosher steakhouse with a wine room & French-Asian menu that includes sushi & Wagyu beef.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7412,
        store_longitude: -73.5976,
        total_orders: 879.61,
        neighborhood: '',
        keywords: 'Steak, Burger, Bbq, Beer, American, Casual',
        annual_dol: 1566.47,
        description:
            'Outlet of a BBQ chain serving wood-smoked meats & sides in an American West-themed space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7233,
        store_longitude: -74.0036,
        total_orders: 188.39,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Lasagna, Italian, Dessert, Delicious, Hawaiian, Elegant, Mediterranean, Spectacular, Bruschetta, Perfect',
        annual_dol: 512.91,
        description:
            '"Founded in 1926 in Milan, Italy 🇮🇹 BiCE Cucina is like no other restaurant with its authentic Italian Culinary experience and elegant European atmosphere to match."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 448.34,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Steak, Beer, Homey, Comfortable, Awesome, Amazing, Great, Casual, Local',
        annual_dol: 1832.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.745,
        store_longitude: -73.6045,
        total_orders: 4176.9,
        neighborhood: '',
        keywords:
            'Wine, Italian, Pizza, Beer, Mexican, Bruschetta, Shrimp Scampi, Premium, Contemporary, Seasonal',
        annual_dol: 4358,
        description:
            'Relaxed wine bar serving Italian tapas, pizza & pasta in a chic, contemporary setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -74.006,
        total_orders: 505.14,
        neighborhood: 'Civic Center',
        keywords:
            'Wine, French, American, Italian, Classy, Quaint, Casual, Polished, Intimate, Charming',
        annual_dol: 956.24,
        description:
            'Popular, quaint bistro offering a French small-plates menu at breakfast, lunch & dinner (till 2 AM).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -74.0035,
        total_orders: 4002.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Cosmopolitan, Scotch, Martini, Fun, Fabulous, American, Authentic, Great, Casual, Lit',
        annual_dol: 4088.63,
        description:
            'This historic piano bar draws a crowd of gay locals & musical-theater performers in dimly lit digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7296,
        store_longitude: -74.0042,
        total_orders: 981.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Tasty, Pizza, Italian, Dessert, Stylish, Whiskey, Scotch, Beer, Gin',
        annual_dol: 1024.23,
        description:
            '"Italian restaurant serving grilled entrees, fresh pasta dishes, and thin-crust Roman-style pizza with a wonderful selection of Italian wines and cocktails"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8697,
        store_longitude: -73.8271,
        total_orders: 10525.29,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Burger, Steak, Mimosa, Cuban, French, Puerto Rican, Trendy, American, Latin, Creamy',
        annual_dol: 10594.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7748,
        store_longitude: -73.9511,
        total_orders: 3387.49,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Wine, Flavorful, Tasty, Italian, Whiskey, Delicious, Scotch, Martini, Romantic, Beer',
        annual_dol: 4732.34,
        description:
            'Hip choice with a romantic vibe offering Italian small plates & mains, plus a large array of wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9013,
        total_orders: 4813.96,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Whiskey Sour, Whiskey, Pizza, Wine, Beer, Italian, Romantic, Fajitas, American, Shrimp Scampi',
        annual_dol: 4813.96,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.6602,
        total_orders: 268.35,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Salad, Italian, Wine, Beer, Spicy, Asian, Romantic, Casual',
        annual_dol: 653.14,
        description:
            'Hearty seafood & red-sauce Italian fare is served with complimentary cotton candy for dessert.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.967,
        total_orders: 12.96,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Sushi, Salad, Gluten Free, Wine, Beer, Coffee, Martini, Spicy, Chinese, Delicious',
        annual_dol: 86.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7622,
        store_longitude: -73.9659,
        total_orders: 604.63,
        neighborhood: 'Lenox Hill',
        keywords:
            'Steak, Salad, Coffee, Upscale, Luxurious, Avocado Toast, Mediterranean, Savory, Elegant, Opulent',
        annual_dol: 2987.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6961,
        store_longitude: -73.934,
        total_orders: 153.01,
        neighborhood: 'Bushwick/Bedford-Stuyvesant',
        keywords: 'Wine, Fun, Natural, Contemporary, Great, Fine, Casual',
        annual_dol: 187.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6833,
        store_longitude: -73.9956,
        total_orders: 343.04,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Vegetarian, Pizza, Italian, Coffee, Wine, Dessert, Meatball, Beer, Delicious, Impeccable',
        annual_dol: 883.95,
        description:
            'Refined spot crafting classic & contemporary Italian dishes, from rabbit pasta to grilled octopus.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6825,
        store_longitude: -73.9931,
        total_orders: 1352.73,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Burger, Hotdog, Hamburger, Steak, Beer, Spicy, Delicious, Awesome, Perfect, Great',
        annual_dol: 1381.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.9233,
        total_orders: 912.6,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegan, Pizza, Garlic Bread, Salad, Gluten Free, Beer, New-York-Style, French, Western, Casual',
        annual_dol: 952.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7285,
        store_longitude: -73.9871,
        total_orders: 290.71,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tequila, Wine, Vodka, Romantic, Gin, Delicious, Rum, French, Fun, Moroccan',
        annual_dol: 303.31,
        description:
            '"French Bistro with small plates to share, wine and delicious cocktails, Speakeasy after dinner hours... Live music Tuesday and Wednesday"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9734,
        total_orders: 1528.37,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, German, Tequila, Beer, Wine, Margarita, New Zealand, Classic, Authentic, Tradition',
        annual_dol: 1678.54,
        description:
            'Costumed barmaids serve imported German beers & meaty eats to customers seated at communal tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9795,
        total_orders: 2885.45,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Hotel, Rooftop, Classy, Cozy, Great, Wonderful, American, Signature, Cool',
        annual_dol: 2946.67,
        description:
            "District.Cozy rooms feature minibars, books and themed art, plus free Wi-Fi, cable TV and DVD players Upgraded rooms add city views, terraces, curated libraries and/or pull-out sofas. Room service is available.There's an American bistro and a rooftop cocktail lounge, along with 3 meeting rooms. Breakfast is available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5839,
        store_longitude: -73.948,
        total_orders: 256.17,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Delicious, Shrimp Cocktail, Elegant, Greek, Mediterranean, Outstanding, Friendly, Classy, Colorful, Great',
        annual_dol: 267.27,
        description:
            'This upscale-casual Greek restaurant with a full bar & sidewalk seats specializes in seafood dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.8335,
        total_orders: 111.43,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Wine, Chinese, Asian, Fancy, American, Friendly, Polished, Casual, Australian, Local',
        annual_dol: 259.77,
        description:
            'Polished Chinese restaurant offering Sichuan-Huaiyang fusion cuisine with meat & seafood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7873,
        store_longitude: -73.7912,
        total_orders: 33.57,
        neighborhood: 'Bayside',
        keywords: 'Chill, American',
        annual_dol: 43.29,
        description:
            'Popular 18-hole golf course with a big putting green, plus a chill clubhouse serving American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.784,
        store_longitude: -73.95,
        total_orders: 1132.52,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Steak, Pizza, Chicken Parmigiana, Salad, Gourmet, Dessert, Beer, Nachos, Cuban',
        annual_dol: 1132.52,
        description:
            'Classic pub grub like burgers & wings with gourmet twists & cocktails in an industrial-chic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7595,
        store_longitude: -73.9926,
        total_orders: 14233.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Tomato Soup, Salad, Wine, Beer, Spicy, Thai, Eclectic, American, Fun',
        annual_dol: 14329.29,
        description:
            'Intimate, brick-walled eatery & bar with handcrafted cocktails, eclectic small plates & a courtyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7845,
        store_longitude: -73.956,
        total_orders: 199.99,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Pizza, Italian, Salad, Gluten Free, Fun, Caesar Salad, Perfect, Great, Classic, Fine',
        annual_dol: 817.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7026,
        store_longitude: -73.9813,
        total_orders: 1128.02,
        neighborhood: 'Vinegar Hill/Brooklyn Heights',
        keywords:
            'Chocolate Cake, Beer, Vodka, Wine, Tasty, Delicious, American, Mediterranean, Seasonal, Quirky',
        annual_dol: 1256.91,
        description:
            'Seasonal New American cooking served in a cozy, salvaged-decor setting with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9849,
        total_orders: 5.74,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Beer, Spicy, Delicious, Rich, Crisp, Friendly, American, Modern, Perfect',
        annual_dol: 108.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.728,
        store_longitude: -73.8922,
        total_orders: 141.38,
        neighborhood: 'Flushing/Maspeth',
        keywords:
            'Steak, Burger, Salad, Wine, Shrimp Scampi, Beer, Irish, Creamy, Caesar Salad, Spacious',
        annual_dol: 147.51,
        description:
            'Laid-back eatery & bar with tavern surroundings, TV sports, brunch & live music on weekends.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8023,
        store_longitude: -73.9533,
        total_orders: 278.91,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Steak, Wine, Tequila, Italian, Beer, Bruschetta, Homemade, Ribeye Steak, Artisanal',
        annual_dol: 379.69,
        description:
            'Laid-back restaurant dishing up homestyle Italian classics such as spaghetti & lasagna.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8009,
        store_longitude: -73.9585,
        total_orders: 1249.45,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Coffee, Relaxed, Perfect, Greek, Bright, Greek Salad, Omelette, Casual, Great',
        annual_dol: 1275.96,
        description:
            'Relaxed hangout offering coffee, bagels, pastries, panini & salads, plus cocktails & sidewalk seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7214,
        store_longitude: -73.9954,
        total_orders: 1178.08,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Tasty, Wine, Italian, Beer, Healthy, Crisp, Comfortable, Authentic, Homey',
        annual_dol: 1178.08,
        description:
            'Brick walls & bookshelves lend character to this homey spot serving Italian fare & global wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7284,
        store_longitude: -73.9993,
        total_orders: 1383.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Ale, Wine, Funky, American, Classic, Great, Amazing, Casual, Chill',
        annual_dol: 1383.58,
        description:
            'More than 300 international brews await at this long-standing, cash-only pub with pewlike seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9999,
        total_orders: 1497.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Bourbon, Whiskey, Burger, French, Japanese, Contemporary, Rum, Traditional, Classic, Artisanal',
        annual_dol: 1745.28,
        description:
            'Flagship location of an artisanal ice cream shop with expanded menu of sweet treats plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6346,
        store_longitude: -73.9497,
        total_orders: 181.91,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Flavorful, Tasty, Upscale, Delicious, French, Caribbean, Authentic, Traditional, Contemporary, Exceptional',
        annual_dol: 743.59,
        description:
            'Upbeat destination with a contemporary vibe serving comfort food & mixed drinks, plus hookah.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.753,
        store_longitude: -74.0011,
        total_orders: 5845.02,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'American, Wine, Beer, Modern, Elegant, British, Cozy, Classic, Warm, Perfect',
        annual_dol: 6455.69,
        description:
            'Grilled meats, veggies & seafood with British flair in a light-filled space with a bar, plus brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -74.0034,
        total_orders: 1548.23,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Wine, Gluten Free, Italian, Delicious, Beer, Organic, Warm, Bruschetta, Spacious',
        annual_dol: 1615.35,
        description:
            'Tuscan cooking & boutique wines served in a setting that includes a spacious year-round garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9948,
        total_orders: 204.72,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Burger, Hamburger, Cheesesteak, Bbq, Salad, Gluten Free, Japanese, Beer, Chinese, American',
        annual_dol: 873.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7338,
        store_longitude: -74.0026,
        total_orders: 1160.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Rich, Cozy, Irish, Classic, Iconic, Contemporary, Great, Fresh, Casual',
        annual_dol: 1688.16,
        description:
            'This circa-1950 Village standard with a literary past offers drinks, games, & welcomes Packers fans.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8452,
        store_longitude: -73.845,
        total_orders: 46.11,
        neighborhood: 'Westchester Square',
        keywords: 'Lobster, Steak, Salad, Dessert, Wine, Italian, Beer, Polished, American, Casual',
        annual_dol: 188.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7372,
        store_longitude: -74.004,
        total_orders: 417.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Wine, Organic, Vodka, Sustainable, Gin, Casual, Excellent, Fine, Creative',
        annual_dol: 1464.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7329,
        store_longitude: -74.0035,
        total_orders: 1357.54,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Vodka, Cozy, Fun, Friendly, Perfect, Casual, Amazing, Great, Local',
        annual_dol: 2554.17,
        description:
            'Buzzing gay bar offering beer, cocktails, drag shows, happy-hour drink specials & dancing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.6345,
        total_orders: 257.11,
        neighborhood: '',
        keywords:
            'Vegetarian, Pizza, Cajun, Bbq, Coffee, Beer, Chicken Wings, Yummy, Casual, Authentic',
        annual_dol: 525.49,
        description:
            'Hip counter-serve eatery featuring a menu of BBQ classics, plus pomme frites & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.973,
        total_orders: 22.73,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Lobster, Salad, Lobster Bisque, Wine, Perfect, Casual, Iconic',
        annual_dol: 92.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -73.9925,
        total_orders: 1661.37,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Coffee, Mexican, Beer, Salad, American, Classic, Iconic, Casual, Cool',
        annual_dol: 6791.12,
        description:
            '"The Landing and Office Hours anchor hospitality in the new Penn District which also includes a collection of amenities that redefine how we work, live, and enjoy. As you journey through PENN 1, you will find Office Hours nestled off the lobby with access to 34th Street, or meander up the Social Stairs where you will find The Landing, the full service bar and restaurant."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8388,
        store_longitude: -73.9375,
        total_orders: 4.88,
        neighborhood: 'Upper Manhattan/Washington Heights',
        keywords: 'Pizza, Tasty, Upscale, Perfect, Casual, Rooftop, Local, Chill',
        annual_dol: 82.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6195,
        store_longitude: -73.9164,
        total_orders: 4.14,
        neighborhood: 'Mill Basin/Old Mill Basin',
        keywords:
            'Burger, Beer, Mexican, Vodka, American, Friendly, Classic, Bloody Mary, Fresh, Casual',
        annual_dol: 16.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7323,
        store_longitude: -73.6875,
        total_orders: 568.69,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Chicken Parmigiana, Italian, Meatball, Vodka, Beer, Retro, Casual, Local',
        annual_dol: 595.36,
        description:
            'Long-running small chain with retro flair for pizza & other Italian comfort fare, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.694,
        store_longitude: -73.9025,
        total_orders: 122.27,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Pizza, Vegan, Coffee, Beer, Delicious, Cajun, Dessert, Cozy, Rum, Perfect',
        annual_dol: 499.8,
        description:
            '"COFFEE shop hours M-F 9AM-4PM BRICK OVEN PIZZA hours Mon,Thurs& Sun 4PM-10PM Fri & Sat 4PM-1030PM Tue& Wed Closed BRUNCH Sat - Sun 11am - 4pm"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6884,
        store_longitude: -73.6859,
        total_orders: 30.98,
        neighborhood: '',
        keywords:
            'Pizza, Burger, Salad, Milkshake, Italian, Baked Ziti, Relaxed, Comfortable, Fresh, Friendly',
        annual_dol: 40.39,
        description:
            'Creatively topped pizzas, baked pasta & sandwiches are served in a relaxed space with a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6609,
        store_longitude: -73.9937,
        total_orders: 5539.94,
        neighborhood: 'South Slope/Sunset Park',
        keywords: 'Burger, Beer, Vodka, Wine, Delicious, Bourbon, Rum, Fun, Crisp, Awesome',
        annual_dol: 5666.17,
        description:
            'Welcoming marine-themed haunt features hearty pub grub, craft cocktails & a garden with a koi pond.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6605,
        store_longitude: -73.9945,
        total_orders: 291.15,
        neighborhood: 'South Slope/Sunset Park',
        keywords: 'Wine, Pizza, Beer, Gin, Vietnamese, Warm, Sustainable, Thai, Eclectic, Friendly',
        annual_dol: 1190.12,
        description:
            'Convivial bar turning out cocktails in a warm, narrow space with a foosball table & board games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9489,
        total_orders: 1720.11,
        neighborhood: 'Greenpoint',
        keywords:
            'Steak, Salad, Chicken Caesar Salad, Shrimp Cocktail, Gourmet, Wine, Dessert, Shrimp Scampi, French, French Onion Soup',
        annual_dol: 2069.5,
        description:
            'High-end steakhouse boasting gourmet seafood & fine wines in an old-fashioned, elegant venue.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7651,
        store_longitude: -73.9685,
        total_orders: 482.37,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Cosmopolitan, Georgian, American, Gracious, Delicious, Perfect, Tradition, Fine, Cultural',
        annual_dol: 514.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7669,
        store_longitude: -73.968,
        total_orders: 2388.2,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Cosmopolitan, Vodka, Elegant, Hotel, Opulent, Warm, French, Gracious, Refined',
        annual_dol: 2647.82,
        description:
            "Daniel Boulud's elegant French flagship where jackets are required & expense accounts come in handy.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7622,
        store_longitude: -73.9661,
        total_orders: 55.64,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Milkshake, Dessert, Delicious, Italian, Spicy, Nachos, Sweet, French, Rustic',
        annual_dol: 232.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.9985,
        total_orders: 0,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Hotel, Champagne, Luxurious, Opulent, French, American, Rooftop, Asian, Brazilian, Rare',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7415,
        store_longitude: -73.5899,
        total_orders: 1072.27,
        neighborhood: '',
        keywords:
            'Salad, Sushi, Pizza, Hamburger, Wine, Beer, Chicken Wings, Bourbon, Tex-Mex, Thai',
        annual_dol: 1301.19,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7987,
        store_longitude: -73.9418,
        total_orders: 4739.08,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Tacos, Burger, Mexican, Steak, Beer, Nachos, Tasty, Dessert, Enchiladas, Fajitas',
        annual_dol: 6182.89,
        description:
            'Casual, chef-owned stop for Mexican tacos, ceviche, larger plates with sides & aguas frescas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.5888,
        total_orders: 1146.53,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Beer, Salad, Bbq, Wine, Juicy, Tex-Mex, Hurricane, Fun',
        annual_dol: 1407.29,
        description:
            'Casual sports bar & grill with 20 different canned beers, happy hours & pub grub such as burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7445,
        store_longitude: -73.9533,
        total_orders: 11.39,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Salad, Thai, Green Curry, Spicy, Asian, Beer, Massaman Curry, Traditional, Fusion, Colorful',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7366,
        store_longitude: -73.987,
        total_orders: 637.89,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Vegetarian, Italian, Gluten Free, Wine, Meatball, Delicious, Friendly, Clean, Traditional, Recommended',
        annual_dol: 651.42,
        description:
            'Local institution with an old-world vibe & Italian standards, plus popular fixed-price options.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.9917,
        total_orders: 14189.4,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Chocolate Croissant, Burger, Pizza, Italian, Wine, Coffee, Beer, Avocado Toast, Trendy, Meatball',
        annual_dol: 15126.62,
        description:
            'Stylish Bowery Hotel eatery serving up Italian fare & drinks, plus outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7385,
        store_longitude: -73.99,
        total_orders: 905.57,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Wine, American, Spacious, Premium, Clean, Modern, Innovative, Great, Local',
        annual_dol: 1007.54,
        description:
            '"Experience the difference of AMC amenities. From spacious rocking seats to luxury recliners, innovative menus and premium offerings like IMAX, Dolby Cinema, and Prime at AMC, AMC Theatres offers a range of ways to get more out of movies."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7248,
        store_longitude: -73.9876,
        total_orders: 141.63,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Tequila, Vodka, Champagne, Beer, Gin, Delicious, Rum, Premium, Classy, Fun',
        annual_dol: 578.95,
        description:
            '"After 21 years, Karma has closed. New local owners are stepping up to the plate. With such enthusiasm we are happy to announce that Karma Sutra NYC will open soon. We will be the only venue on the Lower East Side to maintain its roots and history. Combining the old Karma with the old Sutra to bring to you the New - Karma Sutra NYC. On behalf of everyone at Karma, we thank the community, our neighbors, our patrons, and our employees for the years of support and wish everyone the best of health and happiness in the future. Thank you for being a part of our family."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5541,
        store_longitude: -74.1449,
        total_orders: 110.51,
        neighborhood: 'Great Kills',
        keywords:
            'Sushi, Salad, Japanese, Sashimi, Delicious, Asian, Fusion, Teriyaki, Fresh, Authentic',
        annual_dol: 159.31,
        description:
            'Diners pluck sushi & maki rolls from a moving conveyor belt at this Japan-based chain outpost.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.9798,
        total_orders: 5258.66,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Lobster, Salad, Lobster Bisque, Italian, Bourbon, Champagne, French, French Onion Soup, Hotel',
        annual_dol: 5370.24,
        description:
            'Dry-aged steaks & a casual bar menu, plus raw & grilled seafood in a clubby space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8829,
        store_longitude: -73.9017,
        total_orders: 1226.01,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords:
            'Lobster, Lobster Tail, Sushi, Steak, Salad, Japanese, Delicious, Asian, Filet Mignon, Modern',
        annual_dol: 1912.65,
        description:
            '"Sushi Hours: Monday - Friday 12PM - 3:30PM, 4:30PM - 9PM Saturday & Sunday 12PM - 9PM Sushi Dine-in Hours: Monday - Friday 12PM - 2:45PM, 4:30PM - 9PM Saturday & Sunday 12PM - 9PM Hibachi Dine-in Hours: Monday - Thursday 4:30PM - 9PM Friday 4:30PM - 9:30PM Saturday & Sunday 12PM - 9PM Dine-in, take out and delivery starts at 12PM every day."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7519,
        store_longitude: -73.9739,
        total_orders: 665.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Sushi, Beer, Wine, Irish, Karaoke, Ale, Fun, Polish, Amazing',
        annual_dol: 2521.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 75.31,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Dairy Free, Coffee, Friendly, Natural, Fresh, American, Local, Fine, Great',
        annual_dol: 307.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7023,
        store_longitude: -73.8205,
        total_orders: 20.55,
        neighborhood: 'Jamaica/Richmond Hill',
        keywords: 'Chicken Parmigiana, Dominican, Margarita, Sidecar, Casual, Perfect',
        annual_dol: 33.62,
        description:
            '"Perfect little get away from home . This is your second home . Come enjoy good cocktails & good music while partaking in small bites . Perfect to watch any sports game , go out with a co worker, colleague or even a calm relaxing date . Welcome to CasaNYC . Mi Casa Su Casa ."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9775,
        total_orders: 832.6,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Steak, Dessert, Tasty, French, Polish, Casual, Western, Chic, Gimlet',
        annual_dol: 3403.39,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9659,
        total_orders: 261.65,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Vegan, Butter Naan, Chicken Tikka Masala, Spicy, Delicious, Eclectic, Indian, Modern, Sleek',
        annual_dol: 278.44,
        description:
            'A wide-ranging Indian menu runs the gamut from lassis & samosas to spicy curry & vindaloo dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7147,
        store_longitude: -74.0105,
        total_orders: 330.77,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Gluten Free, Salad, Korean, Tasty, Wine, Karaoke, Bbq, Asian, Polished, Kbbq',
        annual_dol: 359.96,
        description:
            'Buzzy hangout featuring Korean BBQ at table grills, plus colorfully lit karaoke rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8406,
        store_longitude: -73.9182,
        total_orders: 617.03,
        neighborhood: 'Mount Eden/West Bronx',
        keywords:
            'Beer, Vegan, Italian, American, Dominican, Asian, Phenomenal, Latin, Fusion, Iconic',
        annual_dol: 643.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9866,
        total_orders: 104.93,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Italian, Impeccable, American, Authentic, Hotel, Casual, Fun',
        annual_dol: 166.02,
        description:
            '"Fun, casual, Southern Italian dining and bar with inventive cocktails in an upbeat environment. Neapolitan Pizza, full spritz and cocktail menu, pastas, shareable appetizers right in the heart of NYC\'s Times Square on West 47th Street."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7781,
        store_longitude: -73.9487,
        total_orders: 207.34,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Vegetarian, Vegan, Mexican, Salad, Nachos, Margarita, Wine, Gluten Free, Fajitas, Trendy',
        annual_dol: 507.96,
        description:
            'Warm, brick-walled local eatery offering Mexican favorites plus happy hours & margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7874,
        store_longitude: -73.9542,
        total_orders: 145.06,
        neighborhood: 'East Harlem/Central Park West Historic District',
        keywords:
            'Burger, Vegetarian, Salad, Coffee, Italian, Swiss, French, Cuban, Casual, Friendly',
        annual_dol: 151.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -74.0062,
        total_orders: 735.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Spectacular, Premium, Rooftop, Bold, Friendly',
        annual_dol: 3006.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.6481,
        total_orders: 15.64,
        neighborhood: '',
        keywords:
            'Vegetarian, Italian, Delicious, Perfect, Ample, Casual, Intimate, Fresh, Traditional, Contemporary',
        annual_dol: 31.93,
        description: '"Authentic Italian cuisine with Inspirations from the south of Italy"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7741,
        store_longitude: -73.9081,
        total_orders: 2246.76,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Burger, Whiskey, Beer, Vodka, Wine, Bourbon, American, Australian, Delicious',
        annual_dol: 3457.35,
        description:
            'Seasonal New American comfort fare, craft beer & cocktails are served in a hip, wood-paneled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.8849,
        total_orders: 673.73,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Salad, Pizza, Shrimp Cocktail, Italian, Chicken Alfredo, Wine, Latin, American, Fusion, Chic',
        annual_dol: 750.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5276,
        store_longitude: -74.2314,
        total_orders: 760.21,
        neighborhood: 'Charleston',
        keywords:
            'Champagne, Italian, Trendy, Beer, Fabulous, Exquisite, Modern, Amazing, Great, Terrific',
        annual_dol: 819.9,
        description:
            '"Luce is a lifestyle. A modern mix of fine Italian cuisine, atmosphere and culture. Let us share our passion with you while we light the way to an exquisite dining experience."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5868,
        store_longitude: -73.7294,
        total_orders: 52.17,
        neighborhood: '',
        keywords: 'Hotel, French, Healthy, Swiss, Fun, Fantastic, Casual, Terrific, Great, Local',
        annual_dol: 69.07,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7366,
        store_longitude: -73.985,
        total_orders: 120.62,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Tequila, Wine, Vodka, Vegan, Beer, Tasty, Italian, Organic, Mediterranean, Rum',
        annual_dol: 203.81,
        description:
            'Health-conscious Greek specialties served in a polished eatery that has a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.9917,
        total_orders: 550.88,
        neighborhood: 'East Village/Bowery',
        keywords: 'Italian, Wine, Salad, Beer, Trendy, Spicy, Hotel, Classy, Innovative, Fun',
        annual_dol: 591.68,
        description:
            'Casual but hip Italian spot serving a pasta-centric menu plus cocktails and lots of wine from Italy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5934,
        store_longitude: -74.0871,
        total_orders: 602.88,
        neighborhood: 'Dongan Hills',
        keywords:
            'Italian, Meatball, Wine, Warm, Mediterranean, Polished, Awesome, Great, Excellent, Casual',
        annual_dol: 708.78,
        description:
            'Central Italian fare & many wines served in classic, polished surrounds with a warm vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5985,
        store_longitude: -74.0656,
        total_orders: 40.07,
        neighborhood: 'Arrochar',
        keywords:
            'Lasagna, Italian, Salad, Wine, Flavorful, Traditional, Authentic, Casual, Local, Fresh',
        annual_dol: 163.8,
        description:
            'Restaurant & bar with traditional Italian pasta, seafood & meat dishes plus international wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7292,
        store_longitude: -73.9922,
        total_orders: 52.19,
        neighborhood: 'NoHo',
        keywords: 'Cool, Classy, Casual, Great',
        annual_dol: 213.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7337,
        store_longitude: -73.9932,
        total_orders: 24.58,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Thai, Tasty, Green Curry, Spicy, Coffee, Hotel, Lively, Vibrant, Fabulous, Sweet',
        annual_dol: 100.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6397,
        store_longitude: -73.9289,
        total_orders: 123.34,
        neighborhood: 'East Flatbush',
        keywords:
            'Flavorful, Stylish, Upscale, Caribbean, Delicious, Elegant, Jamaican, Sweet, Vibrant, Entertaining',
        annual_dol: 504.19,
        description:
            'Vibrant option with stylish decor offering goat curry, jerk chicken & other Caribbean staples.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.7718,
        total_orders: 114.82,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Italian, Gluten Free, Meatball, Wine, Cozy, Romantic, Classy, Friendly, Intimate, Southern',
        annual_dol: 129.56,
        description:
            'Intimate option featuring Northern & Southern Italian fare with weekly live jazz & blues nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7611,
        store_longitude: -73.9632,
        total_orders: 1962.97,
        neighborhood: 'Lenox Hill',
        keywords: 'Beer, Burger, Ale, Salad, Wine, German, American, Irish, Rustic, Belgian',
        annual_dol: 2115.39,
        description:
            'Craft coffee, cocktails & brews are offered at this cool, rustic bar with sandwiches & snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7564,
        store_longitude: -73.9676,
        total_orders: 1677.57,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Upscale, Stylish, Flavorful, American, Premium, Funky, Vintage, Cozy, Savory, Friendly',
        annual_dol: 3607.13,
        description:
            'Funky brasserie serving creative cocktails, sharing plates & mains in a cozy, vintage-style space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.9803,
        total_orders: 4089.87,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Steak, Burger, Hamburger, Beer, Wine, Martini, Dessert, Irish, French Onion Soup, Gin',
        annual_dol: 4247.11,
        description:
            'Convivial pub with a warm vibe offering Irish & American bites, plus many draft beers. Open late.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.687,
        store_longitude: -73.9939,
        total_orders: 1679.6,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Wine, Beer, Italian, Romantic, Lively, Fancy, Classy, Impressive, Bloody Mary, Great',
        annual_dol: 1715.24,
        description:
            'Craft cocktails & beer, plus snacks like Pop Tarts & local jerky, in a compact, dimly lit space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6974,
        store_longitude: -73.9675,
        total_orders: 235.43,
        neighborhood: 'Clinton Hill',
        keywords:
            'Pizza, Italian, Gluten Free, Beer, Delicious, Mediterranean, Spacious, Bright, Classy, Sweet',
        annual_dol: 240.43,
        description:
            'Bright restaurant showcasing pizza & other Italian staples plus drinks in large, casual surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7596,
        store_longitude: -73.9842,
        total_orders: 639.06,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Beer, Hotel, Friendly, Fun, Casual, Local, American, Great, Fine',
        annual_dol: 1004.78,
        description:
            'Easygoing comedy club offering a lineup of shows, including a family option, plus a full bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.9853,
        total_orders: 6448.08,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Lobster, Sushi, Mexican, Salad, Mimosa, Margarita, Coffee, Upscale, Hotel',
        annual_dol: 6894.11,
        description:
            'Upscale pre-theater seafood & sushi served in a trendy setting with a glass-enclosed bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6916,
        store_longitude: -73.9846,
        total_orders: 615.67,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords: 'Hotel, Margarita, Beer, Chic, Modern, Great, Clean, Hip',
        annual_dol: 2411.02,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9897,
        total_orders: 689.59,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Dessert, Spicy, Japanese, Sashimi, Delicious, Seasonal, Innovative, Casual, Sleek',
        annual_dol: 925.05,
        description:
            'Evenings-only destination offering sophisticated preparations of sushi in a wood-lined setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7794,
        store_longitude: -73.9773,
        total_orders: 3172,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Coffee, Sushi, Salad, Wine, Steak, Italian, Beer, Tasty, Cozy, Mediterranean',
        annual_dol: 3773.02,
        description:
            'Chill, cozy espresso bar offering pastries & sandwiches, along with wine & Italian cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9924,
        total_orders: 2791.68,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Stylish, Beer, Friendly, Great, Local, Casual',
        annual_dol: 9800.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7509,
        store_longitude: -74.0043,
        total_orders: 68.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vodka, Sushi, Pizza, Champagne, Salad, Beer, Cosmopolitan, Upscale, French',
        annual_dol: 301.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.9909,
        total_orders: 425.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Lobster, Steak, Lobster Roll, Burger, Sushi, Lobster Bisque, Cajun, Creole, Premium, Sustainable',
        annual_dol: 1752.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7113,
        store_longitude: -74.0153,
        total_orders: 16693.51,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Vegan, Steak, Wine, Salad, Bourbon, Coffee, Upscale, French, Italian, Beer',
        annual_dol: 23878.11,
        description:
            'This massive French food hall has upscale restaurants, markets & stations for prepared foods.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -74.0033,
        total_orders: 260.83,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Pizza, Italian, Dessert, Wine, Delicious, Polish, Romantic, Polished, Classy, Friendly',
        annual_dol: 304.27,
        description:
            'Polished destination with an urbane vibe serving classic Italian cuisine & wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7229,
        store_longitude: -73.9957,
        total_orders: 347.46,
        neighborhood: 'Nolita',
        keywords:
            'Kebab, Tasty, Mexican, French Toast, German, French, Avocado Toast, Mediterranean, Delicious, Creole',
        annual_dol: 1312.42,
        description:
            '"Local 92 - Soho is a restaurant serving fresh and delicious food in New York, NY. We use only the best ingredients and recipes for all of our dishes, serving our customers quality that they can taste in every bite. Packed with flavor and cooked with the utmost care and dedication to authenticity, you are sure to love everything about our cuisine. In addition, we make sure that our customers are served exceptional quality food at an affordable price."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.9576,
        total_orders: 0,
        neighborhood: 'Greenpoint',
        keywords: 'Vibrant, Perfect, Authentic, Complex, Unique',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8735,
        store_longitude: -73.8083,
        total_orders: 1062.32,
        neighborhood: '',
        keywords: 'Golfclub, American, Hotel, Unique',
        annual_dol: 1207.87,
        description:
            'Sprawling, 36-hole course with practice facilities, a pro shop & a snack bar, plus frequent events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7438,
        store_longitude: -73.956,
        total_orders: 339.14,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Mexican, Shrimp Cocktail, Dessert, Peruvian, Latin, Delicious, Polished, Urban, Refined, Casual',
        annual_dol: 873.91,
        description:
            'Casual cantina serving up refined Mexican classics & cocktails amid concrete floors & mod seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7931,
        store_longitude: -73.9712,
        total_orders: 75.71,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Steak, Shrimp Cocktail, Pizza, Cajun, Korean, Asian, Trendy',
        annual_dol: 231.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5978,
        store_longitude: -73.9611,
        total_orders: 19.32,
        neighborhood: 'Sheepshead Bay/Gravesend/Homecrest',
        keywords:
            'Burger, Kebab, Salad, Pizza, Turkish, Mediterranean, Exquisite, Outstanding, Classic, Authentic',
        annual_dol: 78.99,
        description:
            '"Join us for an exquisite Mediterranean journey. Our chefs’ team has put together a menu that reflects the balance, diversity, and abundance of Turkish food culture as a way of celebrating the flavors and textures of our cuisine."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6898,
        store_longitude: -73.8438,
        total_orders: 46.08,
        neighborhood: 'Ozone Park/Jamaica/Richmond Hill',
        keywords:
            'Caribbean, Fried Chicken, Fried Rice, Karaoke, Jamaican, Ramen, Fusion, Wine, Modern, Fresh',
        annual_dol: 51.27,
        description:
            'Generous homestyle dishes are complemented by craft cocktails at this energetic Caribbean eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9741,
        total_orders: 6013.15,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Steak, Scotch, Hotel, Upscale, American, French, Premium, Iconic, Hip, Great',
        annual_dol: 6273.87,
        description:
            "Understated rooms with city views offer flat-screen TVs and Wi-Fi (fee), plus sitting areas, coffeemakers and rainfall showers. Suites add separate living areas, and an upgraded suite has a whirlpool tub and a Bose speaker system. Room service is available.There's a hip New American restaurant with a bar and floor-to-ceiling windows. Other amenities include a 24/7 business center, 2 ballrooms and 10 meeting rooms, along with a gym. Pets are welcome (fee).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.77,
        total_orders: 18.5,
        neighborhood: 'Bayside/Flushing',
        keywords: 'Pizza, Italian, Gluten Free, Martini, Beer, Delicious, Casual, Fresh',
        annual_dol: 23.25,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7653,
        store_longitude: -73.979,
        total_orders: 4003.25,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Apple Pie, Hotel, Italian, Luxurious, Elegant, Premium, Southern, Sleek, Chic, Natural',
        annual_dol: 4093.91,
        description:
            "Posh, airy rooms feature free Wi-Fi and flat-screen TVs, plus minibars, minifridges and Nespresso machines. They also have rainfall showers, deep-soaking tubs and designer Italian linens. Suites add living rooms and panoramic city views; upgraded suites include complimentary breakfast and minibars, as well as dining rooms and whirlpool tubs.There's an elegant restaurant and a cocktail lounge. Other amenities include a chic spa, an indoor pool and a fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9663,
        total_orders: 59.84,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Chicken Tikka Masala, Gluten Free, Chinese, Spicy, Creamy, Crisp, Indian, Casual',
        annual_dol: 244.59,
        description: '"Authentic Indian Restaurant."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6856,
        store_longitude: -73.9149,
        total_orders: 3044.61,
        neighborhood: 'Bushwick/Ocean Hill',
        keywords: 'Cajun, Beer, Margarita, Luxurious, Cuban, Hotel, Rum, French, Bbq, Vibrant',
        annual_dol: 5331.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6427,
        store_longitude: -73.6399,
        total_orders: 559.5,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Beer, Spicy, Pot Roast, Delicious, Organic, Artisanal, Lively, Contemporary',
        annual_dol: 596.7,
        description:
            'Airy, contemporary gastropub offering farm-to-table comfort food, house cocktails & a garden patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7586,
        store_longitude: -73.9886,
        total_orders: 774.36,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Wine, Cosmopolitan, Trendy, Coffee, Beer, Pizza, Margarita, Urban, Warm',
        annual_dol: 921.69,
        description:
            "Streamlined rooms feature flat-screen TVs, iPod docks and Wi-Fi. Upgraded rooms offer skyline views, while suites add separate living rooms. Upgraded suites have wet bars and kitchenettes.There's a chic cafe/lounge with a digital art gallery, and a trendy food court, as well as an ice cream shop, a convenience store and a hip salon. There's also a gym and a fitness studio with indoor cycling.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7409,
        store_longitude: -73.9884,
        total_orders: 796.1,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Tacos, Tequila, Mexican, Beer, Tasty, Coffee, Spicy, Spanish, Burritos, Quesadillas',
        annual_dol: 2999.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7794,
        store_longitude: -73.9497,
        total_orders: 384.92,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Hamburger, Upscale, Beer, Stylish, Romantic, Premium, American, Robust, Comfortable, Perfect',
        annual_dol: 410.51,
        description:
            'Swanky interior design & dim lighting make this intimate lounge a date-friendly spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7029,
        store_longitude: -73.9938,
        total_orders: 143.01,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords: 'Burger, Steak, Pizza, Salad, Italian, Wine, Bbq, Mediterranean, Chinese, Cozy',
        annual_dol: 166.82,
        description:
            'This spot offers an international menu beneath the Brooklyn Bridge with a wine list & outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6248,
        store_longitude: -74.1471,
        total_orders: 75.82,
        neighborhood: 'Mariners Harbor/Mid Island/Graniteville',
        keywords:
            'Lobster, Salad, Cajun, Steak, Lobster Bisque, Spanish, Caesar Salad, Clam Chowder, Fiery, Fun',
        annual_dol: 132.93,
        description:
            '"Born in the South where a feast means getting messy with friends, the seafood boil has been bringing people together for ages. At Hook & Reel our signature boil forks over bold flavors with a genuine, down-home feel. But eating seafood is only part of the fun. Everything tastes better elbow-to-elbow with friends, swapping stories over music, getting down and dirty and eating with your hands – all with a cold drink at the ready, ‘cause that spice ain’t no joke. We set out to share the saucy spirit of the boil with folks everywhere – testing our very own recipes, inspired by fiery Cajun home cooking. The Hook & Reel seafood boil dishes up the “reel” deal – the full backyard boil experience and flavor, with a twist."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7506,
        store_longitude: -73.9981,
        total_orders: 518.13,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Beer, Martini, Ale, Irish, Homemade, American, Cozy, Relaxed, Clean',
        annual_dol: 542.57,
        description:
            'Relaxed hangout with a wood-paneled bar offering American & Irish pub fare, plus craft beer on tap.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.75,
        store_longitude: -73.9899,
        total_orders: 5203.87,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Burger, Beer, Wine, Irish, Ale, Rustic, American, Classic, Traditional, Sweet',
        annual_dol: 5314.29,
        description:
            'Bi-level bar with long beer list (yes, many stouts) & TVs near Penn Station & Madison Square Garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9878,
        total_orders: 12819.48,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Pizza, Rooftop, Meatball, Vegetarian, Wine, Rum, Vodka, Triple Sec, Bellini, Global',
        annual_dol: 13091.49,
        description:
            'Chic penthouse bar & lounge featuring a rooftop garden & live music, plus global bites & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -74.0023,
        total_orders: 175.27,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Mexican, Argentinian, Wine, South American, Italian, Rich, Juicy, American, Spanish, Latin',
        annual_dol: 182.87,
        description:
            'Modern renditions of Argentine classics plus craft cocktails served in an intimate atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8426,
        store_longitude: -73.8857,
        total_orders: 10.56,
        neighborhood: 'Tremont/West Bronx',
        keywords: 'Burger, Pizza, Salad, Gourmet, Southern, Quesadillas, Local',
        annual_dol: 43.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7769,
        store_longitude: -73.9214,
        total_orders: 360.66,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Wine, Shrimp Cocktail, Upscale, Tasty, Delicious, Polished, Fine Quality, Cozy, Latin',
        annual_dol: 385.21,
        description:
            'Simple but polished restaurant with a patio serving prime sirloin, filet mignon & other meat dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7078,
        store_longitude: -73.9444,
        total_orders: 538.29,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Japanese, Coffee, Delicious, Asian, Sashimi, Caesar Salad',
        annual_dol: 1550.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9655,
        total_orders: 262.98,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Coffee, Dessert, French Toast, Avocado Toast, Upscale, French, Mediterranean',
        annual_dol: 280.46,
        description:
            'Informal option for all-day American eats & a full bar amid funky furnishings, plus sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8543,
        store_longitude: -73.8888,
        total_orders: 109.8,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Lasagna, Salad, Italian, Meatball, Delicious, Classy, Sweet, Fabulous, Southern, Excellent',
        annual_dol: 145.36,
        description:
            'Unfussy, decades-old Italian eatery serving up family-style pastas & entrees to communal tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7299,
        store_longitude: -73.9576,
        total_orders: 58.8,
        neighborhood: 'Greenpoint',
        keywords:
            'Upscale, Italian, Seasonal, American, French, Casual, Elegant, Mediterranean, Warm, Eclectic',
        annual_dol: 205.95,
        description: '"Dinner, drinks, brunch all week & takeout window."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6774,
        store_longitude: -73.9847,
        total_orders: 4.54,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Relaxed, Friendly, Casual, Phenomenal, Great',
        annual_dol: 18.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6822,
        store_longitude: -73.9958,
        total_orders: 838.25,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Vegetarian, Salad, Italian, Gluten Free, Wine, Meatball, Delicious, Upscale, Polished, Romantic',
        annual_dol: 874.59,
        description:
            'Neighborhood institution serving old-school, Sunday-dinner-style Italian with an old Brooklyn feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6825,
        store_longitude: -73.9931,
        total_orders: 3241.13,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Zombie, Vodka, Beer, Margarita, Tasty, Upscale, Exotic, Urban, Spacious, Stylish',
        annual_dol: 3427.54,
        description:
            'Pacific-Island decor sets the mood for exotic cocktails at this kitschy tiki bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.711,
        store_longitude: -74.0163,
        total_orders: 907.66,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Wine, Delicious, Mediterranean, Picturesque, Friendly, Polished, American, Fresh, Casual, Traditional',
        annual_dol: 994.5,
        description:
            'Mediterranean dishes & seafood in an airy restaurant with Hudson River views, plus 2 terraces.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.683,
        store_longitude: -73.9928,
        total_orders: 473.85,
        neighborhood: 'Carroll Gardens',
        keywords: 'Wine, Canadian, Coffee, Salad, Tasty, French, Cozy, Romantic, Classy, Sleek',
        annual_dol: 610.53,
        description:
            'Sleek eatery with a casual vibe serving French inspired fare alongside a deep wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9861,
        total_orders: 6685.83,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Upscale, Gin, Thai, Fun, Fried Chicken, Rum, Premium, Playful',
        annual_dol: 6975.71,
        description:
            'Craft cocktails with playful names are paired with elevated bites in a chic space with a red room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6012,
        store_longitude: -73.9767,
        total_orders: 40.75,
        neighborhood: 'Gravesend',
        keywords:
            'Salad, Sweet, Fun, Friendly, Awesome, Chinese, Adventurous, Great, Excellent, Casual',
        annual_dol: 172.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7516,
        store_longitude: -73.9789,
        total_orders: 4497.93,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Burger, Salad, Shrimp Cocktail, Beer, Dessert, French, Rustic, Warm, American',
        annual_dol: 4705.46,
        description:
            'Upbeat alehouse featuring a wide range of draft brews & classic pub grub in a rustic-chic interior.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9831,
        total_orders: 4442.86,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Steak, Vegetarian, Wine, Salad, Tasty, Coffee, Beer, Rustic, Hotel, Italian',
        annual_dol: 5881.76,
        description:
            "The Orion is a skyscraper located at 350 West 42nd Street between Eighth and Ninth Avenues in the Hell's Kitchen or Clinton neighborhood of Manhattan, New York City, in the U.S. state of New York.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.881,
        store_longitude: -73.903,
        total_orders: 178.4,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Lobster, Spicy, Delicious, Cozy, Polished, Sleek, Perfect, Casual, Fun, Premium',
        annual_dol: 186.14,
        description:
            'Innovative Latin American plates & select wines are served in a sleek space with white banquettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7708,
        store_longitude: -73.8333,
        total_orders: 32.37,
        neighborhood: 'Flushing/Mitchell-Linden',
        keywords: 'Warm, Elegant, Perfect, Inviting, Fun, Ample, Casual, Classic, Local',
        annual_dol: 196.65,
        description:
            'Old-school, 48-lane bowling alley with a pro shop, party space, food services & cash-only policy.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.9704,
        total_orders: 671.19,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Salad, Wine, Tasty, Coffee, French, Champagne, American, Impeccable, Delicious',
        annual_dol: 734.77,
        description:
            'High-end chophouse offering prime meats & classic sides in sophisticated surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9728,
        total_orders: 729.01,
        neighborhood: 'Lenox Hill',
        keywords:
            'Hotel, Stylish, Elegant, Fabulous, Impeccable, Iconic, Timeless, Classic, Premium, Great',
        annual_dol: 760.62,
        description:
            "The elegant rooms have marble bathrooms and hand-carved wooden furniture, as well as flat-screen TVs, iPod docks and free Wi-Fi. Suites add kitchenettes.Amenities include a fitness center, a barbershop and a hair salon, plus multiple high-end shops, and a restaurant that's a replica of the famed Harry’s Bar in Venice, Italy.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6746,
        store_longitude: -73.9443,
        total_orders: 64.66,
        neighborhood: 'Crown Heights',
        keywords: 'Sushi, Beer, Meatball, Coffee, Spicy, Delicious, Funky, Thai, Fun, Asian',
        annual_dol: 281.15,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7554,
        store_longitude: -73.9811,
        total_orders: 4519.93,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Cheesesteak, Salad, Wine, Spicy, Caesar Salad, Filet Mignon, Modern, Fine, Attentive',
        annual_dol: 4615.84,
        description:
            'Swanky steakhouse with a raw bar boasting walls covered with photos of old-Hollywood stars.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7815,
        store_longitude: -73.9488,
        total_orders: 593.07,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Wine, Fun, Awesome, Great, Excellent, Local, Casual, Chill',
        annual_dol: 618.78,
        description:
            'Enduring neighborhood sports bar offering grills to cook your own meat, plus a pool table & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7803,
        store_longitude: -73.9502,
        total_orders: 15.9,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Mexican, Tequila, Tacos, Margarita, Hip, Local, Paloma, Fresh, Casual, Authentic',
        annual_dol: 65.01,
        description:
            'Branch of a casual, hip mini-chain for classic Mexican eats & margaritas, plus happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9898,
        total_orders: 19.79,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Wine, Coffee, Beer, Elegant, Warm, Funky, Hotel, Fun, Latin, Fresh',
        annual_dol: 86.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7508,
        store_longitude: -73.9897,
        total_orders: 1243.53,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Pizza, Italian, Meatball, Spicy, Wine, Elegant, Modern, Perfect, Authentic, Classic',
        annual_dol: 1277.73,
        description:
            "Elegant Italian fare & creative wood-fired pizzas in an airy setting on the 6th floor of Macy's.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9946,
        total_orders: 1926.25,
        neighborhood: 'Nolita',
        keywords: 'Whiskey, Beer, Fun, Fancy, Authentic, Casual, Great, Local',
        annual_dol: 1926.25,
        description:
            'Historic local watering hole offering beers & chips plus a jukebox, Christmas lights & memorabilia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8045,
        store_longitude: -73.9558,
        total_orders: 1840.98,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Chocolate Croissant, Salad, Milkshake, Wine, Coffee, Tasty, Organic, Lebanese, Rustic, Chicken Shawarma Plate',
        annual_dol: 1920.8,
        description:
            'This hip cafe & bar offers Middle Eastern eats such as shawarma & falafel, plus live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7732,
        store_longitude: -73.9784,
        total_orders: 1795.21,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Lasagna, Steak, Italian, Wine, Upscale, Hotel, Cozy, Quaint, Classy, Fancy',
        annual_dol: 1914.54,
        description:
            'A well-heeled crowd enjoys updated Southern Italian fare in a space decorated with historic murals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.767,
        store_longitude: -73.9862,
        total_orders: 739.8,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Salad, Mexican, Meatball, Coffee, Beer, Dessert, American, Belgian',
        annual_dol: 788.98,
        description:
            'An eclectic, Southwestern menu pairs with roadhouse-like decor at this casual spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9838,
        total_orders: 6675.67,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Upscale, African, Bourbon, French Toast, Beer, Nigerian, Global, French, Ethiopian, Fusion',
        annual_dol: 8010.13,
        description:
            'Swanky, chandelier-lit restaurant & cocktail spot serving creative international fusion bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.981,
        total_orders: 423.65,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Pizza, Irish, Gluten Free, American, Local, Cozy, Casual, Great',
        annual_dol: 1838.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8094,
        store_longitude: -73.9446,
        total_orders: 429.32,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Burger, Steak, Pizza, Sushi, Tequila, Mexican, Italian, Coffee, Rich, Fried Chicken',
        annual_dol: 576.68,
        description:
            'Chic hangout with sidewalk seats serving Senegalese & American soul food, plus French pastries.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9838,
        total_orders: 357.47,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Casual, Hip, Vivid',
        annual_dol: 372.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7659,
        store_longitude: -73.9923,
        total_orders: 326.4,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vegan, Vegetarian, Salad, Pizza, Wine, Italian, Dessert, Organic, Beer, Cozy',
        annual_dol: 441.67,
        description:
            'Locals are lured to the Italian fare served at this brightly lit corner cafe with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7642,
        store_longitude: -73.9833,
        total_orders: 1320.67,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Karaoke, Vodka, Nachos, Hotel, Funky, Salad, Iconic, Fun, Colorful, Excellent',
        annual_dol: 1470.47,
        description:
            'Cocktails served in a cool, neon-lit space with a funky bar area, pub fare & private karaoke rooms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.592,
        store_longitude: -73.5817,
        total_orders: 748.75,
        neighborhood: '',
        keywords: 'Burger, Sushi, Tacos, Pizza, Salad, Ale, Warm, Quaint, American, Modern',
        annual_dol: 781.22,
        description:
            'Neighborhood haunt serving steak, seasonal American fare & pub grub in a traditional atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8617,
        store_longitude: -73.925,
        total_orders: 21.26,
        neighborhood: 'Fort George',
        keywords:
            'Burger, Hamburger, Warm, Spanish, American, Latin, Perfect, Casual, Sangria, Great',
        annual_dol: 69.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7403,
        store_longitude: -73.9939,
        total_orders: 499.97,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Steak, Spanish, Dessert, Warm, Contemporary, Classic, Seasonal, Vibrant, Lively',
        annual_dol: 532.83,
        description:
            'Barcelona-style tapas & a deep menu of Spanish wines can be shared in this compact, busy spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -74.0015,
        total_orders: 558.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Meatball, Italian, Tasty, Dessert, Spicy, Wine, American, Seasonal, Yummy',
        annual_dol: 945.56,
        description:
            'Buzzy outfit serving a variety of house-ground meatballs, sauces & sides plus ice cream sandwiches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6811,
        store_longitude: -73.9771,
        total_orders: 19.83,
        neighborhood: 'Park Slope',
        keywords: 'Pizza, Meatball, Vodka, American, Asian, Creamy, Creative, Casual, Great',
        annual_dol: 81.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7203,
        store_longitude: -73.8093,
        total_orders: 31.08,
        neighborhood: 'Fresh Meadows/Flushing/Kew Gardens Hills',
        keywords:
            'Sushi, Lobster, Salad, Vegetarian, Zen, Thai, Japanese, Delicious, Creamy, Chinese',
        annual_dol: 60.93,
        description:
            'Contemporary eatery with a loungelike ambiance providing sushi as well as Thai & Japanese plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7149,
        store_longitude: -73.9516,
        total_orders: 23033.59,
        neighborhood: 'Williamsburg',
        keywords: 'Tacos, Hotel, Upscale, Gin, Casual, Great, Gimlet, Chill',
        annual_dol: 23755.76,
        description:
            'Popular bar set in a former pool supply store with live music & a taco truck in the backyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7239,
        store_longitude: -73.9788,
        total_orders: 4217.5,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Whiskey, Wine, Bourbon, Beer, Vintage, Fun, Classic, Intimate, Innovative, Wonderful',
        annual_dol: 4445.49,
        description:
            'Subdued 1970s-style bar with plush couches, a disco ball & waterbed, serving innovative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.6979,
        total_orders: 775.85,
        neighborhood: '',
        keywords: 'Hamburger, Beer, Wine, Coffee, Bbq, Cozy, Mimosa, Delicious, Swiss, American',
        annual_dol: 792.31,
        description:
            'Local haunt with a vintage vibe serving homestyle American fare, craft brews & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7229,
        store_longitude: -73.983,
        total_orders: 77.46,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Steak, Sushi, Vegan, Mexican, Meatball, American, Latin, Asian, Casual, Yummy',
        annual_dol: 271.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5791,
        store_longitude: -74.1636,
        total_orders: 80.07,
        neighborhood: 'New Springville/Mid Island',
        keywords: 'Burger, Tequila, Pizza, Sushi, Whiskey, Vodka, Gin, Rum, Japanese, Delicious',
        annual_dol: 327.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7415,
        store_longitude: -73.9784,
        total_orders: 263.46,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Korean, Wine, Ale, Modern, Intimate, Bbq, Premium, Friendly, Kimchi Fried Rice, Fried Rice',
        annual_dol: 307.82,
        description:
            '"Sam Sunny is a new intimate Korean restaurant located in the center of Kips Bay, Manhattan. We pride ourselves to serve authentic Korean food with a modern twist in NYC. Large hip restaurant with over 80 seats with bar, booths, and tables."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7457,
        store_longitude: -73.9842,
        total_orders: 764.81,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Champagne, Hotel, Luxurious, Stylish, Elegant, Coffee, Premium, Vibrant, Urban, Great',
        annual_dol: 2881.54,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6814,
        store_longitude: -74.0043,
        total_orders: 3481.24,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Burger, Vegetarian, Mexican, Beer, Whiskey, Wine, Tasty, Breakfast Burrito, American, African',
        annual_dol: 3858.67,
        description:
            'This watering hole housed in a historic space offers a menu of elevated pub grub & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6822,
        store_longitude: -73.9291,
        total_orders: 3207.62,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords: 'Burger, Hamburger, Dessert, French, Spicy, Wine, Funky, American, Yummy, Casual',
        annual_dol: 3275.68,
        description:
            'Venerable option favored for French bistro fare & happy hours served in hip surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6823,
        store_longitude: -73.9291,
        total_orders: 1000.76,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Tequila Sunrise, Tequila, Zombie, Margarita, Rum, French, American, Cointreau, Cozy, Casual',
        annual_dol: 1021.99,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7404,
        store_longitude: -73.9304,
        total_orders: 380.45,
        neighborhood: 'Astoria/LIC',
        keywords: 'Salad, Caesar Salad, Greek, Greek Salad, Unforgettable, Cobb Salad',
        annual_dol: 491.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7517,
        store_longitude: -73.9394,
        total_orders: 97.69,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords:
            'Pizza, Salad, Italian, Shrimp Cocktail, Wine, Beer, Premium, Modern, Fun, Seasonal',
        annual_dol: 399.34,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7353,
        store_longitude: -73.8749,
        total_orders: 2998.16,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Pizza, Salad, Gluten Free, Beer, Wine, Caesar Salad, Creamy',
        annual_dol: 3006.18,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7354,
        store_longitude: -73.983,
        total_orders: 169.88,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Italian, Salad, Dessert, Trendy, Delicious, Spacious, Polished, Vintage, Classy',
        annual_dol: 203.26,
        description:
            'Cozy, traditional Italian restaurant with housemade pasta, risotto & hearty mains, plus drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7351,
        store_longitude: -73.9831,
        total_orders: 45.54,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Vegetarian, Salad, Butter Chicken, Chicken Tikka Masala, Chili Chicken, Indian, Rich, Casual, Paneer Tikka, Premium',
        annual_dol: 52.33,
        description:
            'Typical Indian dishes like samosas, Malabar chicken & pakora are offered at this low-key locale.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.539,
        store_longitude: -74.1485,
        total_orders: 103.24,
        neighborhood: 'Great Kills',
        keywords:
            'Pizza, Italian, Wine, Beer, Classy, Exceptional, Great, Excellent, Local, Casual',
        annual_dol: 114.36,
        description:
            '"Italian Home Cooking Brick Oven Pizza Over Sized Portions of exceptional food at great prices. We have a full bar and an expansive outdoor dining area"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8848,
        store_longitude: -73.9,
        total_orders: 4162.7,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Burger, Hamburger, Beer, Spicy, Bbq, Wine, Warm, Ale, Crisp, Delicious',
        annual_dol: 4162.7,
        description:
            'Burgers, wings & hearty sandwiches washed down with a selection of craft beers in a casual saloon.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6248,
        store_longitude: -74.0307,
        total_orders: 992.89,
        neighborhood: 'Bay Ridge',
        keywords:
            'Italian, Coffee, Beer, Organic, Elegant, Impeccable, Authentic, Fun, Classic, Modern',
        annual_dol: 1520.94,
        description:
            '"Baci Ristorante is a new Italian Restaurant located in Brooklyn, we pride ourselves on providing Authentic Italian Food to our customers. Come check us out and you won\'t be disappointed! Baci!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6929,
        store_longitude: -74.0017,
        total_orders: 5119.8,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Pizza, Rooftop, Italian, Tasty, Beer, Wine, Trendy, Awesome, Prosecco, Great',
        annual_dol: 6135.65,
        description:
            'This trendy pizzeria with a rooftop patio offers wood-fired pies plus sandwiches & artisanal sodas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7592,
        store_longitude: -73.9853,
        total_orders: 6917.75,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Tequila, Sushi, Pizza, Hotel, Stylish, Delicious, Beer, Nutritious, Spacious, Wholesome',
        annual_dol: 7070.24,
        description:
            "Art.Polished rooms with high-thread-count linens feature Wi-Fi (fee), flat-screen TVs and DVD players, plus minibars and coffeemakers. Upgraded rooms add views of Times Square, and suites have living rooms with pull-out sofas. Room service is available 24/7.There's a 2-story Mexican restaurant with a tequila bar, a plush eatery serving sushi and cocktails, and a vibrant Times Square-inspired lounge with DJs. There's also a gym. Pets are welcome for a fee.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -74.0011,
        total_orders: 1477.68,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Burger, Hotdog, Steak, Hamburger, Gourmet, Vodka, Spicy, Nachos, Bbq, Delicious',
        annual_dol: 2148.54,
        description: 'Modern eatery featuring gourmet hot dogs & burgers, plus sides & milkshakes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.9872,
        total_orders: 2730.6,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Steak, Salad, Irish, Homemade, American, Romantic, Fun, Casual, Classic',
        annual_dol: 2788.54,
        description:
            'Boisterous Irish sports bar with DJs at night & a menu of American & Irish pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7581,
        store_longitude: -73.9863,
        total_orders: 12480.34,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Amazing, Awesome, Iconic, Great',
        annual_dol: 15428.1,
        description:
            "The Minskoff Theatre is a Broadway theater on the third floor of the One Astor Plaza office building in the Theater District of Midtown Manhattan in New York City. Opened in 1973, it is operated by the Nederlander Organization and is named after Sam Minskoff and Sons, the building's developers.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7581,
        store_longitude: -73.9883,
        total_orders: 1959.89,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Garlic Bread, Pizza, Salad, Dessert, Italian, Meatball, Wine, Creamy, Beer, Margarita',
        annual_dol: 2135.97,
        description:
            'Famous circa-1929 Village pizzeria slings trademark thin-crust pies in a soaring Midtown space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9865,
        total_orders: 327.16,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Burger, Lasagna, Vegan, Vegetarian, Salad, Tasty, Coffee, Dessert, Wine, Bbq',
        annual_dol: 341.91,
        description:
            'Place for vegan plates & bar snacks with style, donating all of its profits to charity.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6899,
        store_longitude: -73.9868,
        total_orders: 602.07,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Crab Cakes, Burger, Cornbread, Steak, Shrimp Cocktail, Salad, Bbq, Margarita, Beer, Korean',
        annual_dol: 686.76,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9898,
        total_orders: 650.72,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Steak, Tequila, Cornbread, Beer, Rich, Bbq, Southern, Great, Fresh, Casual',
        annual_dol: 2342.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6884,
        store_longitude: -73.9547,
        total_orders: 266.22,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Burger, Hamburger, Tasty, French, Warm, American, Old Fashioned, Friendly, Perfect, Casual',
        annual_dol: 383.39,
        description:
            'Straightforward cafe with a take-out option doling out classic burgers, hot dogs, fries & wings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6761,
        store_longitude: -73.9417,
        total_orders: 548.31,
        neighborhood: 'Crown Heights',
        keywords: 'Wine, Whiskey, Vodka, Beer, Bourbon, Rum, Awesome, Iconic, Great, Cool',
        annual_dol: 746.42,
        description:
            '"Kingston Public House is Brooklyn’s most curious, cocktail bar. A unique spot to meet your friends for drinks and great music. KPH features great wines, rose (on tap), craft beers, scotch, whiskey, gin, vodka & unique cocktails. See you soon! As a new addition to the neighborhood! Kingston Public House is conveniently located in the iconic Brooklyn neighborhood of Crown Heights, just 2.4 mi of Barclays Center and within close proximity to major transportation."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7525,
        store_longitude: -73.9935,
        total_orders: 5869.93,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Pizza, Salad, Italian, Wine, Upscale, Margarita, Delicious, Hotel, Classy',
        annual_dol: 5998.89,
        description:
            'Pasta, thin-crust pizza & Italian entrees served amid art deco accents inside the New Yorker Hotel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7477,
        store_longitude: -73.9865,
        total_orders: 337.58,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Korean, Salad, Bbq, Spicy, Kbbq, Authentic, Perfect, Local, Unpretentious, Amazing',
        annual_dol: 375.59,
        description:
            'Always-open Korean BBQ mainstay distinguishing itself with charcoal-fueled grills.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7497,
        store_longitude: -73.986,
        total_orders: 1325.5,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Shrimp Cocktail, Crab Cakes, Beer, Wine, Italian, Irish, French, Hotel, American',
        annual_dol: 4653.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7449,
        store_longitude: -73.994,
        total_orders: 28.46,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Beer, Upscale, Warm, Delicious, Creamy, Friendly, American, Exotic, Classic',
        annual_dol: 116.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -73.9862,
        total_orders: 66.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Korean, Spicy, Beer, Karaoke, Sweet, Fun, Bbq, Fresh, Perfect, Casual',
        annual_dol: 270.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7289,
        store_longitude: -73.9995,
        total_orders: 9330.87,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Margarita, Mimosa, Vegetarian, Spicy, Enchiladas, American, Latin, Contemporary, Healthy',
        annual_dol: 12306.98,
        description:
            'NoHo outpost of a small chain known for inventive Mexican fare & margaritas, plus a vegetarian menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7666,
        store_longitude: -73.9655,
        total_orders: 583.33,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Cosmopolitan, Wonderful, Local',
        annual_dol: 608.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7699,
        store_longitude: -73.9666,
        total_orders: 700.62,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Salad, Cajun, Wine, French, Thai, Organic, Stylish, Caesar Salad, Classic',
        annual_dol: 715.49,
        description:
            'French bistro with Parisian ambiance, a stylish crowd, closely spaced tables & classic fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7613,
        store_longitude: -73.9609,
        total_orders: 73.41,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Kebab, Salad, Turkish, Mediterranean, Korean, Greek, Delicious, Hotel, Greek Salad',
        annual_dol: 300.09,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7449,
        store_longitude: -73.9532,
        total_orders: 567.11,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Lasagna, Coffee, Dessert, Warm, Bruschetta, American, Yummy, Bright, Seasonal, Modern',
        annual_dol: 842.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6761,
        store_longitude: -73.9991,
        total_orders: 924.5,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Wine, Whiskey, Scotch, Beer, Vegetarian, Coffee, Margarita, Bourbon, Japanese, French Onion Soup',
        annual_dol: 1540.45,
        description:
            'Clever cocktails, a huge whiskey & spirits selection & small plates in a narrow, laid-back space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6043,
        store_longitude: -73.9745,
        total_orders: 279.88,
        neighborhood: 'Gravesend',
        keywords:
            'Coffee, Pizza, Wine, Mexican, Italian, American, Clean, Friendly, Casual, Indian',
        annual_dol: 391.72,
        description:
            '"Come join us for coffee, cocktails, wine and beer. Weekends come dance to live Dj music. Our friendly atmosphere will make you feel at home."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7947,
        store_longitude: -73.9659,
        total_orders: 4366.13,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Burger, Vegan, Hamburger, Salad, Gluten Free, Organic, Beer, Sustainable, Delicious, Healthy',
        annual_dol: 7135.01,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5504,
        store_longitude: -74.1513,
        total_orders: 68.13,
        neighborhood: 'Great Kills',
        keywords: 'Beer, Swiss, Ale, American, Classic, Great, Casual',
        annual_dol: 278.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7585,
        store_longitude: -73.9832,
        total_orders: 4349.53,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Beer, Wine, Tasty, American, Relaxed, Classic, Irish, Traditional, Friendly, Smooth',
        annual_dol: 5102.56,
        description:
            'Relaxed neighborhood hangout specializing in hamburgers, plus traditional pub food, brunch & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7601,
        store_longitude: -73.989,
        total_orders: 11758.34,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Steak, Hamburger, Meatloaf, Salad, Shrimp Cocktail, Wine, Beer, Upscale, Delicious',
        annual_dol: 12075.43,
        description:
            'Snug, classic tavern attracting Broadway patrons & actors with American comfort food & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6317,
        store_longitude: -74.1469,
        total_orders: 317.02,
        neighborhood: 'Mariners Harbor',
        keywords:
            'Salad, Vegetarian, Pizza, Italian, Flavorful, Avocado Toast, Trendy, Homemade, Warm, Homey',
        annual_dol: 347.85,
        description:
            'Italian family-style eatery & bar with a warm ambiance serving pizza alongside Sicilian specialties.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6866,
        store_longitude: -73.994,
        total_orders: 398.37,
        neighborhood: 'Boerum Hill/Brooklyn Heights/Cobble Hill',
        keywords:
            'Tequila, Tacos, Mexican, Steak, Margarita, Tex-Mex, Breakfast Burrito, Delicious, American, Fresh',
        annual_dol: 443.89,
        description:
            'Tex-Mex standards and potent margaritas draw locals to this Southwestern spot with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.986,
        total_orders: 7834.18,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Rooftop, Hotel, Wine, Tasty, Delicious, Beer, Comfortable, Relaxed, Refined, Iconic',
        annual_dol: 8685.51,
        description:
            'Hip lounge with modern decor offering craft beer, wine & cocktails, plus skyscraper views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7459,
        store_longitude: -73.9935,
        total_orders: 1483.7,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Whiskey, Ale, Wine, Tequila, Beer, Vodka, Rustic, Gin, Upscale, Stylish',
        annual_dol: 1528.44,
        description:
            'Stylish tavern lined with bookshelves dispensing American pub fare with nostalgic flair.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7535,
        store_longitude: -73.999,
        total_orders: 1907.8,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Spanish, Spicy, Stylish, Mediterranean, Italian, Homey, Elegant, Fancy, Southern, Casual',
        annual_dol: 7237.44,
        description:
            'Airy restaurant featuring elevated Spanish dishes & tapas amid stylish surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7801,
        store_longitude: -73.9803,
        total_orders: 1695.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Thai, Garlic Bread, Dessert, Beer, Chinese, Green Curry, Chilean, Hotel, Pad Thai, Red Curry',
        annual_dol: 1731.07,
        description:
            'Curries & street food in an urbane, wood-lined restaurant with wood carvings & a bohemian vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7771,
        store_longitude: -73.9777,
        total_orders: 4671.84,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Pizza, Wine, Vegetarian, Salad, Italian, Gourmet, Dessert, Beer, Delicious, Japanese',
        annual_dol: 4990.31,
        description:
            'Lively corner spot offering an expansive wine list, classic cocktails & gourmet Italian bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.985,
        total_orders: 314.45,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Vegetarian, Salad, Vegan, Butter Chicken, Chicken Tikka Masala, Wine, Tangy, Spicy, Indian, Impeccable',
        annual_dol: 1285.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7661,
        store_longitude: -73.9798,
        total_orders: 42.83,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Pizza, Steak, Salad, Dessert, Tasty, American, Organic, Greek, Omelette',
        annual_dol: 175.08,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.818,
        store_longitude: -73.9608,
        total_orders: 225.52,
        neighborhood: 'Upper Manhattan',
        keywords:
            'Cuban, Dominican, Jamaican, Wine, American, Czech, Fusion, Latin, Traditional, Iconic',
        annual_dol: 943.14,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7212,
        store_longitude: -73.9883,
        total_orders: 749.41,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Lively, Fresh, Great, Eclectic, Classic, Incredible, Casual, Chill',
        annual_dol: 781.9,
        description:
            "Up-&-coming acts are the focus of this rock music bar that's also home to live-band karaoke.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7199,
        store_longitude: -73.9887,
        total_orders: 2849.4,
        neighborhood: 'Lower East Side',
        keywords: 'Cajun, Vegan, Salad, Beer, Martini, Tangy, Delicious, Italian, Hotel, Cozy',
        annual_dol: 2972.94,
        description:
            'Relaxed gastropub offering cocktails & eclectic nibbles with an Asian twist in a cozy atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8426,
        store_longitude: -73.9238,
        total_orders: 14.82,
        neighborhood: 'Highbridge/West Bronx',
        keywords: 'Steak, Mongolian, Ramen, Latin, Casual, Pure, Gyoza',
        annual_dol: 60.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9839,
        total_orders: 37.49,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Sushi, Salad, Shrimp Scampi, Asian, American, Filet Mignon, Casual, Excellent, Local',
        annual_dol: 153.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7466,
        store_longitude: -73.9914,
        total_orders: 60.98,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Cosmopolitan, Hotel, Friendly, Clean, Delicious, American',
        annual_dol: 78.12,
        description:
            "Understated rooms offer free Wi-Fi and custom-designed beds, plus flat-screen TVs, microwaves, minifridges, and desks with ergonomic chairs. Some have city views.A cooked-to-order and buffet breakfast is available (fee), and there's an American restaurant, a bar and a 24/7 pantry market. Other amenities include a fitness room and a business center. Parking is available (surcharge).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6775,
        store_longitude: -73.8287,
        total_orders: 2945.2,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Burger, Cheesecake, Pizza, Milkshake, Salad, Beer, Gourmet, Dessert, Tasty, Savory',
        annual_dol: 10271.44,
        description:
            'Cheerful, kitschy all-day eatery offering hearty burgers, pizza & pasta, plus salads & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6194,
        store_longitude: -74.0278,
        total_orders: 60.1,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Sushi, Tacos, Kebab, Steak, Pizza, Shrimp Cocktail, Italian, Warm, Mediterranean, Cozy',
        annual_dol: 245.67,
        description:
            '"Elegant restaurant with excellent service and charming atmosphere. offering fusion cuisine and signature drinks."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5445,
        store_longitude: -74.1624,
        total_orders: 2798.81,
        neighborhood: 'Eltingville',
        keywords:
            'Burger, Steak, Tacos, Lobster, Pizza, Salad, Gourmet, Beer, Lobster Bisque, Wine',
        annual_dol: 2820.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -73.9974,
        total_orders: 1155.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Wine, French, Polished, Cozy, Classy, Relaxed, Warm, Perfect, Friendly',
        annual_dol: 1420.13,
        description:
            'Bistro serving French fare in a cozy interior & on a sidewalk patio plus cocktails at the bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6867,
        store_longitude: -73.9758,
        total_orders: 247.78,
        neighborhood: 'Fort Greene',
        keywords: 'Kebab, Salad, Pizza, German, Turkish, Wine, Mediterranean, Thai, Baklava, Cozy',
        annual_dol: 351.77,
        description:
            'Mediterranean-accented Turkish cuisine with an emphasis on seafood served in a spacious setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -73.9882,
        total_orders: 2690.84,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Steak, Wine, Flavorful, French, Delicious, Classy, Cozy, Quaint, Traditional, Perfect',
        annual_dol: 2810.7,
        description:
            'Longtime East Village eatery showcasing classic French bistro cooking in a Parisian-style room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.9784,
        total_orders: 179.87,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Sushi, Whiskey, Vodka, Trendy, Cozy, Classy, Polish, Perfect, Classic, Lively',
        annual_dol: 735.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.9861,
        total_orders: 850,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Steak, Burger, Cheesesteak, Vegetarian, Pizza, Salad, Beer, Authentic, Homemade, Fresh',
        annual_dol: 868.03,
        description:
            '"Authentic cheesesteaks. Specialty cheesesteaks. Signature roast pork and chicken cutlet sandwiches with homemade broccoli rabe and Cooper sharp american. Bread shipped in from Philly and baked in-house every hour. Killer wings, salads, and fries. Craft beer on tap. Craftier cocktails. Televisions. Sports. Do we have your attention yet? Welcome to Olde City Cheesesteaks & Brew. Philly native and Olde City founder Evan Stein has been serving up cheesesteaks in NYC since 2005 with his original concept Shorty’s. Evan has moved on to launch this truly unique brand. Each item on the menu is delicious. The beer selection is carefully curated with local breweries from PA and NY."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7102,
        store_longitude: -73.9641,
        total_orders: 220.76,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Lobster, Salad, Vegetarian, Italian, Martini, Creamy, French, Polished, Contemporary, American',
        annual_dol: 557.56,
        description:
            'Convivial brasserie with an open kitchen serving up European-inspired dishes & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7205,
        store_longitude: -73.8462,
        total_orders: 2256.26,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Beer, Wine, Coffee, Avocado Toast, Bbq, Irish, Upscale, Thai, Latin',
        annual_dol: 3585.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7751,
        store_longitude: -73.948,
        total_orders: 804.46,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Beer, Irish, Rich, Friendly, Traditional, Casual, Great, Local, Chill',
        annual_dol: 2626.37,
        description:
            'This old-timey neighborhood tavern with a long bar & tin ceiling offers draft beer, pinball & darts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7772,
        store_longitude: -73.9489,
        total_orders: 561.34,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Cheesesteak, Beer, Meatball, Ale, Bbq, Irish, American, Thai, Homemade',
        annual_dol: 591.32,
        description:
            'Dark-wood-lined Irish pub with simple bar eats, draft beer and lots of TVs for sports fans.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7098,
        store_longitude: -73.5914,
        total_orders: 16.51,
        neighborhood: '',
        keywords: 'Burger, Pizza, Tequila, Steak, Beer, Wine, Karaoke, Rum, Local, Casual',
        annual_dol: 19.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7364,
        store_longitude: -74.0078,
        total_orders: 1006.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Wine, Beer, Gourmet, Japanese, French, Classy, Perfect, Casual, Cool',
        annual_dol: 1092.72,
        description:
            'This no-frills burger & beer joint becomes a lively & often crowded party scene after the sun sets.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7406,
        store_longitude: -73.638,
        total_orders: 524.65,
        neighborhood: '',
        keywords:
            'Flavorful, Wine, Rich, Delicious, Shrimp Cocktail, Coffee, Upscale, Italian, Cozy, Eclectic',
        annual_dol: 579.78,
        description:
            'Upscale seafood dishes & choice steaks featured along with global wines in an 1862 building.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7245,
        store_longitude: -73.6487,
        total_orders: 0,
        neighborhood: '',
        keywords:
            'Steak, Hurricane, Hawaiian, French Onion Soup, Beer, Hotel, Swiss, American, French, Seasonal',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9194,
        total_orders: 67.47,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Mexican, American, Modern, Spanish, Salad, Colombian, Latin, Karaoke, Homemade, Casual',
        annual_dol: 194.34,
        description:
            '"The restaurant offers dishes such as tacos-including pork, beef, chicken and vegetable tacos, red snapper, crab cakes, homemade guacamole, and quinoa salad with balsamic shrimp. At night, weekday karaoke days and DJs. with salsa nights on weekends."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8564,
        store_longitude: -73.8908,
        total_orders: 41.81,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Steak, Margarita, Dominican, Spanish, Puerto Rican, Tasty, American, Latin, Casual',
        annual_dol: 62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7063,
        store_longitude: -73.9432,
        total_orders: 1365.47,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Burger, Delicious, Warm, Sweet, Relaxed, Hot Toddy, Homey, Cool, Fresh, Casual',
        annual_dol: 1394.44,
        description:
            'Dim lighting & subway tiled-walls create a cool vibe at this eatery known for its burgers & big bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7695,
        store_longitude: -73.9576,
        total_orders: 335.35,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Peruvian, Wine, Salad, Dessert, Delicious, Vibrant, Classic, Casual, Awesome, Diverse',
        annual_dol: 381.65,
        description:
            'Sit-down branch of a fast casual mini-chain serving Peruvian fare with a focus on ceviche.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7055,
        store_longitude: -73.9226,
        total_orders: 67.54,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Hotdog, Steak, Hamburger, Vegetarian, Vegan, Mexican, Beer, Margarita, Venezuelan',
        annual_dol: 276.09,
        description:
            'Hip, casual eatery serving Venezuelan street food such as yuca fries, burgers & hot dogs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.732,
        store_longitude: -74.0015,
        total_orders: 923.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Mexican, Margarita, Vodka, Wine, Spanish, Salad, Peruvian, Enchiladas, Fajitas',
        annual_dol: 1059.19,
        description:
            'Paella & sangria mingle with enchiladas & margaritas at this lively East Village landmark.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7399,
        store_longitude: -73.6033,
        total_orders: 411.5,
        neighborhood: '',
        keywords: 'Italian, Wine, Gourmet, Hotel, Signature, Unique',
        annual_dol: 750.96,
        description:
            '"Life Time Garden City is more than a gym, it’s an athletic country club. Life Time has something for everyone: an expansive fitness floor, unlimited studio classes, basketball courts, eucalyptus steam rooms, and indoor and outdoor pools. Receive unlimited group training and individualized attention when you choose a Signature Membership. Or get started at home with our Digital Membership, featuring virtual training programs, a library of on-demand class videos and award-winning health and wellness content. Build your membership today."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8693,
        store_longitude: -73.8267,
        total_orders: 1630.37,
        neighborhood: 'East Bronx/Baychester',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Pizza, Cajun, Beer, Delicious, Clam Chowder, American, Classy',
        annual_dol: 1669.03,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7867,
        store_longitude: -73.9756,
        total_orders: 1418.9,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Hamburger, Beer, Salad, Fried Chicken, Delicious, American, Southern, Fun, Natural',
        annual_dol: 1480.42,
        description:
            'Southern bar & eatery focused on fried chicken biscuit sandwiches, housemade pickles & beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7865,
        store_longitude: -73.9725,
        total_orders: 297.17,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'French, French Onion Soup, Delicious, White Russian, Casual, Russian, Authentic, Classic, Fine, Great',
        annual_dol: 1043.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.5539,
        total_orders: 21.45,
        neighborhood: '',
        keywords: 'Pizza, Burger, Italian, Beer, Sushi, Salad, American, Bbq, Wine, Premium',
        annual_dol: 138.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8478,
        store_longitude: -73.8567,
        total_orders: 19.02,
        neighborhood: 'Morris Park',
        keywords:
            'Pizza, Chicken Parmigiana, Meatball, Vodka, Italian, Beer, Wine, Veal Parmigiana, Organic, Creamy',
        annual_dol: 54.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7378,
        store_longitude: -74.0023,
        total_orders: 30.81,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lobster, Wine, Coffee, Bourbon, French, American, Quaint, Yummy, Hip, Intimate',
        annual_dol: 125.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5735,
        store_longitude: -73.981,
        total_orders: 1017.58,
        neighborhood: 'Coney Island',
        keywords: 'Casual, Hotel',
        annual_dol: 5026.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6787,
        store_longitude: -73.9861,
        total_orders: 251.5,
        neighborhood: 'Gowanus',
        keywords:
            'Italian, Wine, Spicy, Warm, Seasonal, Casual, Authentic, Classic, Fantastic, Great',
        annual_dol: 268.03,
        description:
            'In a redone auto-body shop, this Italian place offers wood-fired Italian fare & housemade pastas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8084,
        store_longitude: -73.7405,
        total_orders: 237.29,
        neighborhood: '',
        keywords: 'Zombie, Pizza, Premium, Hotel, Great, Fun, Asian',
        annual_dol: 388.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7485,
        store_longitude: -73.6176,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Sushi, Steak, Salad, Wine, Japanese, Beer, Premium, Friendly, Local, Excellent',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7512,
        store_longitude: -73.9849,
        total_orders: 1684.82,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Rooftop, Upscale, Hotel, Wine, Tasty, Whiskey, Fantastic, Beer, Great, Intimate',
        annual_dol: 1720.57,
        description:
            'Intimate rooftop lounge at the Strand Hotel featuring dramatic views, cocktails & retractable roof.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7436,
        store_longitude: -73.9198,
        total_orders: 186.7,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Whiskey, Beer, Irish, Ale, Salad, Fancy, Comfortable, Nachos, Friendly, Amazing',
        annual_dol: 202.32,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9978,
        total_orders: 981.87,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Whiskey, Mexican, Stylish, Spicy, Hamburger, Italian, American, French, Polished',
        annual_dol: 1721.44,
        description:
            'Glitzy, arena-size restaurant from the storied ex-Knicks star with globally inflected American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7402,
        store_longitude: -73.9238,
        total_orders: 148,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Pizza, Tasty, Wine, Beer, Spicy, Polish, Fun, Awesome, Great, Crafted',
        annual_dol: 696.96,
        description: '"Whiskey Wine Bar and Crafted Cocktails"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8563,
        store_longitude: -73.7046,
        total_orders: 1303.66,
        neighborhood: '',
        keywords: 'Golfclub, Italian, Rich, Fine',
        annual_dol: 1423.66,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6248,
        store_longitude: -74.0748,
        total_orders: 440.33,
        neighborhood: 'Clifton',
        keywords: 'Wine, Delicious, Italian, Warm, Cozy, Fun, Premium, Awesome, Amazing, Meatball',
        annual_dol: 481.86,
        description:
            '"Italian restaurant rated number #1 in Staten Island by the Advance Bibb Gourmand Michelin rated 2019-2020-2021-2022 Top 25 Wine Bar “Time Out” Magazine Wine Spectator Award 2020-2021-2022 Best outdoor setting in Staten Island by Al Fresco NYC"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6141,
        store_longitude: -73.713,
        total_orders: 547.28,
        neighborhood: '',
        keywords: 'Golfclub, Amazing',
        annual_dol: 648.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -74.0075,
        total_orders: 1324.26,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Japanese, Beer, Asian, Chinese, Modern, Thai, Fried Rice, Rustic, Fusion',
        annual_dol: 3512.03,
        description:
            '"The Buddha-Bar New York City, is ideally located within the industrial-turned-lifestyle heart of New York’s TriBeCa neighborhood. The restaurant, bar, and lounge sit within a landmarked historic building in a vaulted two-story space with a meticulous, opulent modern design perfectly aligned with the rustic beams and bricks of the original structure. The return of a custom, 16-foot, glass-hewn Buddha sculpture, music, and energy from the resident DJ, globally-inspired Asian Fusion cuisine, and the famed vibe of Buddha-Bar to New York City."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7041,
        store_longitude: -74.0103,
        total_orders: 1524.71,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Salad, Pizza, Beer, Vodka, Spicy, French, American, Relaxed, Omelette',
        annual_dol: 1560.67,
        description:
            "Sports on big screen TVs, draft beer & pub food in a historic building that's stood since 1603.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.777,
        store_longitude: -73.9526,
        total_orders: 1863.55,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Burger, Beer, Funky, Spanish, Wine, Fun, Greek, American, Awesome, Great',
        annual_dol: 1863.55,
        description:
            'Funky 1970s-themed haunt with bar bites & specialty cocktails, plus go-go dancers & DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.8153,
        total_orders: 7.06,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Steak, Burger, Mexican, Bbq, Coffee, Cuban, Dominican, Swiss, American, Latin',
        annual_dol: 28.87,
        description:
            'Large, easygoing locale featuring Caribbean & Latin American dishes such as mofongo & pernil.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7542,
        store_longitude: -73.99,
        total_orders: 830.77,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Hamburger, Salad, Bread Pudding, Mexican, Beer, Milkshake, Margarita, Dessert, Tasty',
        annual_dol: 933.7,
        description:
            'Cozy, easygoing wood-&-brick eatery with hefty specialty burgers & craft beers on tap.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6554,
        store_longitude: -73.6455,
        total_orders: 194.01,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Rustic, French Toast, French, American, Delicious, Crisp, Casual',
        annual_dol: 271.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7839,
        store_longitude: -73.6634,
        total_orders: 294.97,
        neighborhood: '',
        keywords: 'Traditional, Delicious, Cool, Local, Modern',
        annual_dol: 385.22,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7467,
        store_longitude: -73.6202,
        total_orders: 3987.09,
        neighborhood: '',
        keywords:
            'Sushi, Vegetarian, Salad, Gluten Free, Wine, Japanese, Chinese, Delicious, Sashimi, Stylish',
        annual_dol: 4208.92,
        description:
            'Sushi & teppanyaki offered in a buzzy setting with a long stylish bar & a room with hibachi tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.921,
        total_orders: 600.74,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Burger, Beer, American, Wine, Spicy, Warm, Cozy, Quaint, Seasonal, Casual',
        annual_dol: 720.1,
        description:
            'Warm, wood-hued eatery offering craft beers & cocktails, plus elevated pub grub & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7906,
        store_longitude: -73.9477,
        total_orders: 744.15,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Peruvian, Salad, Gourmet, Shrimp Scampi, Tasty, Latin, Yummy, Casual, American, Authentic',
        annual_dol: 776.41,
        description:
            'Casual spot offering a range of gourmet pizzas & Peruvian staples, plus pasta & lunch specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7251,
        store_longitude: -73.5478,
        total_orders: 1058.69,
        neighborhood: '',
        keywords:
            'Vegan, Steak, Wine, Vegetarian, Tasty, Savory, Organic, Irish, Upscale, Caribbean',
        annual_dol: 2363.8,
        description:
            '"NK Restaurant (Nuvo Kitchen), an Eighteen 04 Restaurant Group venture, is a unique fusion twist on traditional Caribbean cuisine inspired by multicultural backgrounds all infused by Rum! - Dress code is business/casual. **Sports caps, bandanas, hoodies or beanies are not allowed in the restaurant. Coat check is complimentary."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9928,
        total_orders: 601.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Coffee, Chic, Contemporary, Friendly, Local',
        annual_dol: 651.34,
        description:
            "Sleek, simple rooms have noise-reducing windows, plus free Wi-Fi, flat-screen TVs and coffeemakers. Most have minibars, and higher floors provide city views. Upgraded options add designer coffeemakers, iPod docks and 24/7 lobby beverages. Room service is available.Complimentary amenities include happy-hour hors d'oeuvres for guests, and access to a nearby gym. There's also a lobby bar serving morning breakfast and evening cocktails.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.754,
        store_longitude: -73.9887,
        total_orders: 8421.32,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Whiskey, Upscale, Rooftop, Vodka, Beer, Spectacular, Flavorful, Gin, American',
        annual_dol: 8786.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.985,
        total_orders: 392.58,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Salad, Vegan, Vegetarian, Cuban, Dessert, Margarita, Gluten Free, Savory, Martini, Spicy',
        annual_dol: 1240.32,
        description:
            'A bar pours mojitos up front & a brick-lined dining room in the back serves traditional Cuban fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7515,
        store_longitude: -73.9837,
        total_orders: 205.53,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'South American, Hotel, Paloma, Mexican, Trendy, Stylish, Chic, Latin, Southern, Bbq',
        annual_dol: 840.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6566,
        store_longitude: -73.6454,
        total_orders: 5.32,
        neighborhood: '',
        keywords:
            'Burger, Mexican, Salad, Italian, Savory, Spanish, American, Latin, Homemade, Casual',
        annual_dol: 21.73,
        description:
            '"We are a family owned restaurant specializing in cuisine that is Spanish and a Latin-American fusion. Stop by and try our specialty Rotisserie Chicken, infamous Duck (Confit de Pato), an authentic Paella, or our savory Empanadas! ⚠️HAPPY HOUR⚠️ 2-for-1 drink special per customer every day from 4pm-7pm! With your choice if any Beer, Sangria and house drinks are included. Our 2-for-1 policy is each customer can buy one get the next round free. *Kitchen closes at 9 PM*"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8254,
        store_longitude: -73.8146,
        total_orders: 20.39,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: '',
        annual_dol: 83.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6753,
        store_longitude: -73.6693,
        total_orders: 595.5,
        neighborhood: '',
        keywords:
            'Steak, Burger, Garlic Bread, Swiss, Irish, Tangy, Quaint, American, Authentic, Fancy',
        annual_dol: 608.14,
        description:
            'Perennial pub with wooden booths & a calm vibe offering Irish & American fare plus live Irish music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6578,
        store_longitude: -73.6476,
        total_orders: 2397.49,
        neighborhood: '',
        keywords:
            'Tequila Sunrise, Tequila, Mexican, Tacos, Beer, Vegan, Asian, American, Delicious, Fusion',
        annual_dol: 2798.17,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6292,
        store_longitude: -74.0797,
        total_orders: 1745.66,
        neighborhood: 'Stapleton Heights',
        keywords: 'Burger, Tasty, Beer, Salad, Delicious, Fun, Vintage, Friendly, Irish, Retro',
        annual_dol: 1806.46,
        description:
            'Retro gastropub offering comfort food with a twist & ample craft beers on tap, plus Skee-Ball & TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8102,
        store_longitude: -73.6972,
        total_orders: 1144.29,
        neighborhood: '',
        keywords: 'Golfclub',
        annual_dol: 1245.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7543,
        store_longitude: -73.989,
        total_orders: 292.45,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Trendy, Upscale, Romantic, Spacious, Innovative, Premium, Classy, Awesome, American',
        annual_dol: 1195.43,
        description:
            'Spacious, trendy hub with an industrial-chic feel, pairing comfort food with creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7542,
        store_longitude: -73.9341,
        total_orders: 421.13,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Burger, Beer, Vodka, Delicious, Creamy, Friendly, Dutch, Awesome, Casual, Great',
        annual_dol: 557.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -73.9367,
        total_orders: 1468.84,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Salad, Mexican, Delicious, Spicy, Beer, Bbq, Homemade, Fresh, Great, Casual',
        annual_dol: 1648.73,
        description:
            'Counter-service BBQ spot serving Kansas City-style meats along with sides, salads, beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7346,
        store_longitude: -73.9886,
        total_orders: 5785.12,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Burger, Pizza, Mexican, Gluten Free, American, Mediterranean, Ale, Urban, Hummus, Casual',
        annual_dol: 10946.3,
        description:
            'Sports bar & lounge adding slick decor & craft cocktails to the usual mix of beer, pub grub & TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6403,
        store_longitude: -73.9669,
        total_orders: 5373.58,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Steak, Hamburger, Salad, Beer, Coffee, Wine, Delicious, Stylish, Fried Chicken',
        annual_dol: 5671.17,
        description:
            'Wine bar serves select vintages & small plates in a stylish space with brick walls & reclaimed wood.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.551,
        store_longitude: -74.1504,
        total_orders: 42.32,
        neighborhood: 'Great Kills',
        keywords:
            'Burger, Mexican, Sushi, Pizza, Salad, Shrimp Cocktail, Beer, Wine, Delicious, Casual',
        annual_dol: 173,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.7699,
        total_orders: 1208.13,
        neighborhood: 'Bayside',
        keywords:
            'Burger, Shrimp Cocktail, Japanese, Dessert, Modern, American, Casual, Relaxed, Fancy, Fantastic',
        annual_dol: 1233.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.7607,
        total_orders: 792.7,
        neighborhood: 'Bayside',
        keywords:
            'Lobster, Tasty, Spanish, Dessert, Wine, Italian, Mediterranean, Hotel, Elegant, Greek',
        annual_dol: 851.24,
        description:
            'Neighborhood spot serving Spanish cuisine & sangria in a posh white-tablecloth setting since 1967.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7517,
        store_longitude: -73.9723,
        total_orders: 349.95,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Trendy, Coffee, Relaxed, Great',
        annual_dol: 365.12,
        description:
            "Trendy rooms have a relaxed vibe and LED mood lighting, and feature beds with eucalyptus sheets, plus fitness facilities such as yoga mats, resistance bands and exercise balls. All are equipped with free Wi-Fi and 40-inch flat-screen TVs, as well as minifridges and coffeemakers.There's a modern, health-focused restaurant and bar; breakfast is offered for a fee. There's also a fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8666,
        store_longitude: -73.9205,
        total_orders: 85.95,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Steak, Spanish, Tasty, Delicious, Dominican, Friendly, Traditional, Perfect, Sangria, Casual',
        annual_dol: 110.74,
        description:
            '"Authentic Spanish Tapas Restaurant offering a variety of tapas along with other traditional Spanish dishes such as our Paellas, a full bar, desserts, happy hour on weekdays and daily specials."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9848,
        total_orders: 3111.52,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Bourbon, Legendary, Gin, Delicious, Rum, Upscale, Irish, Classic, Iconic',
        annual_dol: 3188.54,
        description:
            'Narrow, old-school Times Square bar with boxing memorabilia covering the walls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7582,
        store_longitude: -73.9807,
        total_orders: 4869.18,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords: 'Sushi, Salad, Wine, Beer, Spicy, Japanese, Creamy, Ramen, Sashimi, Homemade',
        annual_dol: 5042.64,
        description:
            '"- WHO WE ARE -The taste and ambiance we provide is elicit, forgotten elegance---noodle craftsman\'s hospitality.TsuruTonTanTsuru---The sound of slurping noodlesTon---The sound of kneading and shaping udonTan---The sound of cutting udonThese three sounds embody the spirit of our finest, handmade udon.Our wish is to be a place for everyone to gather, where our artisanal big bowls of udon bring excitement, surprise and smiles to all of our guests. Along with our great hospitality and service, we strive to create a pleasurable experience for our guests by providing various selections of Japanese food for all."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -73.9911,
        total_orders: 395.56,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Wine, Beer, Delicious, Japanese, Upscale, Warm, Elegant, Ramen, Contemporary',
        annual_dol: 524.13,
        description:
            'Japanese cooking served in an airy, contemporary restaurant with colorful lanterns & outdoor seats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7217,
        store_longitude: -73.9944,
        total_orders: 1889.87,
        neighborhood: 'Nolita',
        keywords: 'Wine, Italian, Vodka, Tasty, Pizza, Rich, Delicious, Stylish, Romantic, Warm',
        annual_dol: 2015.5,
        description:
            'Wood-fired Italian dishes & wines are served in a rustic yet sophisticated space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.747,
        store_longitude: -73.9874,
        total_orders: 780.51,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Hotel, Wine, Rooftop, Coffee, Spicy, Premium, Fun, Fantastic, Great, Chic',
        annual_dol: 810.88,
        description:
            "The warm, modern rooms have flat-screen TVs and Wi-Fi. Some have minifridges, premium cable channels and coffeemakers. Suites add separate living spaces with sofa-beds.Amenities include a lobby bar, a restaurant and a rooftop lounge. There's also a 24-hour fitness center and a business center. Parking and breakfast are available.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9923,
        total_orders: 179.76,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Salad, Hotel, Delicious, Italian, Beer, Mediterranean, Comfortable, Refined, Vibrant',
        annual_dol: 284.42,
        description:
            'Internationally inspired dishes & cocktails offered in upbeat surrounds with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7268,
        store_longitude: -73.9887,
        total_orders: 1437.6,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Salad, Pizza, Italian, Wine, Casual, Authentic, Southern, Traditional, Classic',
        annual_dol: 1468.11,
        description:
            'Homestyle Italian fare is served at this quaint, classic restaurant with outdoor seating. Cash only.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7278,
        store_longitude: -73.988,
        total_orders: 113.3,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Lasagna, Salad, Italian, Wine, Gluten Free, Beer, French, Classy, Friendly, Perfect',
        annual_dol: 463.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5761,
        store_longitude: -74.0829,
        total_orders: 1823.87,
        neighborhood: 'Midland Beach',
        keywords:
            'Burger, Steak, Cheesesteak, Salad, Tasty, Juicy, Savory, Bbq, Delicious, Southern',
        annual_dol: 2496.13,
        description:
            '"The most authentic Central Texas-style barbecue in the NYC area! Award-winning brisket and ribs, delicious and savory pulled pork, burgers to die for - and so much more!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8027,
        store_longitude: -73.9643,
        total_orders: 3885.11,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Whiskey, Scotch, American, Gin, Martini, Cozy, Intimate, Fusion, Traditional, Retro',
        annual_dol: 4092.66,
        description:
            'Snug watering hole offering a large selection of American whiskeys, plus simple tavern fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8004,
        store_longitude: -73.9683,
        total_orders: 348.68,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Vegetarian, Burger, Steak, Pizza, Chicken Parmigiana, Italian, Wine, French, Filet Mignon, Seasonal',
        annual_dol: 371.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8852,
        store_longitude: -73.9003,
        total_orders: 783.38,
        neighborhood: 'Kingsbridge/West Bronx',
        keywords: 'Beer, Irish, Karaoke, Ale, Adventurous, Fun, Friendly, Urban, Great, Local',
        annual_dol: 817.34,
        description:
            'Long-running Irish pub with standard grub, sports on TVs, pool tables, darts & a neighborhood vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7529,
        store_longitude: -73.972,
        total_orders: 6928.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Shrimp Cocktail, Wine, Tasty, Dessert, Coffee, Scottish, Classic, Iconic, Fresh',
        annual_dol: 6982.13,
        description:
            'Select meats, seafood, salads & a deep wine list offered in a clubby atmosphere, open since 1966.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7573,
        store_longitude: -73.9765,
        total_orders: 786.54,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Tasty, Wine, Italian, Upscale, Delicious, Homemade, Perfect, Robust, Fabulous',
        annual_dol: 1101.9,
        description:
            'Upscale eatery offering Northern Italian cuisine, weekend brunch & a robust wine list.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7498,
        store_longitude: -73.9754,
        total_orders: 1751.66,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Sushi, Tequila, Salad, Mexican, Mimosa, Dessert, Wine, Beer, Spicy, Thai',
        annual_dol: 6307.79,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.7703,
        total_orders: 93.12,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Beer, Dessert, Ale, Cuban, Warm, Yummy, Eclectic, Contemporary, American',
        annual_dol: 317.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.9795,
        total_orders: 165.81,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Pizza, French, Mediterranean, Thai, Rum, Seasonal, Modern, Casual, Classic',
        annual_dol: 944.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7573,
        store_longitude: -73.9766,
        total_orders: 136.08,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Pizza, Italian, Hotel, New Zealand, Vibrant, Southern, Fantastic, Casual, Wonderful',
        annual_dol: 141.98,
        description:
            '"Casa Limone near Rockefeller Center, Radio City Music Hall, Sack Fifth Avenue and Times Square"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8236,
        store_longitude: -73.9522,
        total_orders: 3811.75,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords: 'Mexican, Whiskey, Wine, Beer, Ramen, Italian, Japanese, Tasty, Asian, Classy',
        annual_dol: 3892.63,
        description:
            'Japanese option for several varieties of ramen, plus snacks, a raw bar & sophisticated cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7621,
        store_longitude: -73.9716,
        total_orders: 1514.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Wine, Beer, Martini, Gin, Premium, Spacious, Western, Classic, Iconic',
        annual_dol: 5706.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.969,
        total_orders: 54.46,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Butter Chicken, Chicken Tikka Masala, Chili Chicken, Upscale, Trendy, Delicious, Polished, Indian',
        annual_dol: 222.62,
        description:
            '"Upscale Restaurant famous for delicious Indian cuisine. Darbar Grill is located near luxury hotels & NYC attractions. Romantic & Friendly; groups gathering over dinner is often what you see late at dinner time."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7797,
        store_longitude: -73.9471,
        total_orders: 27.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Italian, Salad, Wine, American, Modern, Authentic, Excellent',
        annual_dol: 118.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.683,
        store_longitude: -73.9436,
        total_orders: 975.49,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Tequila, Beer, Tasty, Rum, Trendy, Painkiller, Caribbean, Jamaican, Fun, Relaxed',
        annual_dol: 1017.79,
        description:
            'Relaxed watering hole offering tropical drinks, plus live reggae music, dancing & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9806,
        total_orders: 88.08,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Thai, Salad, Dessert, Green Curry, Chinese, Pad Thai, Casual, Authentic, Tom Yum, Local',
        annual_dol: 360.04,
        description: '"Taste all four regions of Thai cuisine, in one place."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7492,
        store_longitude: -73.9937,
        total_orders: 9214.48,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Whiskey, Beer, American, Burger, Bourbon, Wine, Gluten Free, Tasty, Irish, Premium',
        annual_dol: 9624.45,
        description:
            'Elegant version of a Southern bourbon & burger bar with clever small plates & an upstairs lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.9931,
        total_orders: 615.83,
        neighborhood: 'NoHo',
        keywords: 'Garlic Bread, Pizza, Salad, Wine, Italian, Tasty, Delicious, Spicy, Vodka, Warm',
        annual_dol: 628.9,
        description:
            'Updated Italian dishes plus cocktails & housemade limoncello in spacious, low-key digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6613,
        store_longitude: -73.9534,
        total_orders: 900.42,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Salad, Vegetarian, Ramen, Beer, Flavorful, Fried Chicken, Japanese, Spicy, Caesar Salad, Caribbean',
        annual_dol: 1078.6,
        description:
            'Casual stop for classic & smoked ramen bowls plus other comfort foods like fried chicken & burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9669,
        total_orders: 117.55,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Salad, Milkshake, Organic, American, French, Classic, Fresh, Local, Casual',
        annual_dol: 125.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7362,
        store_longitude: -73.9554,
        total_orders: 1665.76,
        neighborhood: 'Greenpoint',
        keywords:
            'Salad, Wine, Italian, Steak, Pizza, Delicious, Warm, Romantic, Classy, Irresistible',
        annual_dol: 1737.99,
        description:
            'Offering casual Italian fare & craft cocktails at communal tables, plus frequent live musical acts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6834,
        store_longitude: -73.9989,
        total_orders: 646.77,
        neighborhood: 'Carroll Gardens',
        keywords: 'Steak, Burger, Salad, Wine, Dessert, Beer, Delicious, German, French, Polished',
        annual_dol: 674.81,
        description:
            "Alsatian eatery serving dishes like spaetzle & tarte flambée along with the region's wine & beer.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7474,
        store_longitude: -73.956,
        total_orders: 2190.06,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Hotdog, Beer, Wine, American, Vintage, Spacious, Fun, Retro, Awesome, Great',
        annual_dol: 2285.01,
        description:
            'Old-school bowling alley with an attached barroom that offers craft beer & hot dogs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7303,
        store_longitude: -73.8638,
        total_orders: 1911.34,
        neighborhood: 'Rego Park/Flushing',
        keywords: 'Burger, Steak, Cheesesteak, Salad, Tasty, Bbq, Nachos, Beer, Margarita, Juicy',
        annual_dol: 1959.23,
        description:
            'Tropical-themed lounge serving festive cocktails & pub food in a brick-walled space with hookahs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.585,
        store_longitude: -73.9268,
        total_orders: 229.59,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Lobster, Tasty, Martini, Juicy, Bbq, Caribbean, Perfect, Fresh, Great, Casual',
        annual_dol: 661.28,
        description:
            'Waterside seafood joint offering up Maryland-style all-you-can-eat crab meals, clams & more.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.7716,
        total_orders: 143.3,
        neighborhood: 'Bayside',
        keywords: 'Steak, Bourbon, Premium, Nachos, Fun, Friendly, Great, Attentive',
        annual_dol: 152.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7549,
        store_longitude: -73.9717,
        total_orders: 296.79,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegan, Vegetarian, Pizza, Coffee, Mexican, Wine, Gourmet, American, Trendy, Hotel',
        annual_dol: 1578.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7524,
        store_longitude: -73.9779,
        total_orders: 10.17,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Dessert, Scandinavian, Wine, Beer, Nordic, Danish, Upscale, American, Bright',
        annual_dol: 41.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.67,
        store_longitude: -73.8423,
        total_orders: 977.61,
        neighborhood: 'Ozone Park',
        keywords:
            'Mexican, Tequila, Beer, Spanish, American, Latin, Yummy, Homemade, Polished, Friendly',
        annual_dol: 1095.91,
        description: '"Modern Mexican kitchen"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9731,
        total_orders: 1706.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lasagna, Cheesecake, Pizza, Flavorful, Italian, Delicious, Polished, Bellini, Cozy',
        annual_dol: 1783.81,
        description:
            'Classic Italian fare is served in a casual, homey setting that has a bustling lunch scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7027,
        store_longitude: -73.9295,
        total_orders: 415.18,
        neighborhood: 'Bushwick',
        keywords: 'Vegan, Wine, Whiskey, Tasty, Cozy, Classy, Casual, Great, Local, Chill',
        annual_dol: 677,
        description:
            'Laid-back neighborhood hangout featuring bar bites & local draft beer, plus a lineup of live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8854,
        store_longitude: -73.5994,
        total_orders: 986.55,
        neighborhood: '',
        keywords: 'Tequila, Rich, Golfclub, Pizza',
        annual_dol: 1075.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7634,
        store_longitude: -73.9705,
        total_orders: 268.31,
        neighborhood: 'Lenox Hill',
        keywords: 'Scotch, Wine, Beer, American, Premium, Innovative, Excellent',
        annual_dol: 941.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9897,
        total_orders: 1297.09,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Margarita, Salad, Beer, Italian, Savory, South American, French Toast, Upscale, Spanish',
        annual_dol: 1440.47,
        description:
            'Eclectic tapas, charcuterie & mains, plus wine & cocktails served in a cozy, rustic-chic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9808,
        total_orders: 10757.27,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Burger, Gin, Organic, Delicious, Spanish, American, French, Seasonal, Asian, Contemporary',
        annual_dol: 11480.54,
        description:
            'Elevated American eats pair with cocktails in a retro-inspired space with brass & tile accents.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7416,
        store_longitude: -73.9851,
        total_orders: 4253.65,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Salad, Pizza, Wine, Tasty, Italian, Beer, Californian, Spicy, Cozy, Rustic',
        annual_dol: 4441.1,
        description:
            'Spacious, casually chic brasserie with hearty dishes featuring Californian & Italian influences.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6923,
        store_longitude: -73.9695,
        total_orders: 338.52,
        neighborhood: 'Clinton Hill',
        keywords: 'Global, American, Local, Casual, Great, Unique',
        annual_dol: 921.66,
        description:
            '"Brooklyn Music Kitchen is Brooklyn’s newest music infusion giving local and global artists the opportunity to perform, record and stream their live performances from a fully loaded sound and video stage."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7026,
        store_longitude: -73.9865,
        total_orders: 446.16,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords: 'Burger, Beer, Coffee, Wine, American, Modern, Rooftop, Aesthetic, Casual, Great',
        annual_dol: 1846.47,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9867,
        total_orders: 362.13,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Spanish, Intimate, Amazing, Iconic, Clean, New Zealand',
        annual_dol: 748.56,
        description:
            'The Lena Horne Theatre is a Broadway theater at 256 West 47th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1926, it was designed by Herbert J. Krapp in a Spanish Revival style and was constructed for Irwin Chanin.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6856,
        store_longitude: -73.991,
        total_orders: 95.58,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Vodka, Salad, Beer, Coffee, Wine, German, Dessert, Jamaican, Spicy, French',
        annual_dol: 312.05,
        description:
            'Brick- & wood-accented restaurant & bar for German specialties & beers, plus a large backyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.657,
        store_longitude: -73.96,
        total_orders: 965.46,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Zombie, Hamburger, Beer, American, Wine, Asian, Local, Casual, Spacious, Traditional',
        annual_dol: 985.94,
        description:
            'Neighborhood watering hole offering nibbles, brews & event nights in a laid-back atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7262,
        store_longitude: -74.0021,
        total_orders: 2830.91,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Steak, Jambalaya, Wine, Bread Pudding, Dessert, Coffee, Italian, Martini, Hawaiian',
        annual_dol: 2930.33,
        description:
            'Classic bistro dating to the 1970s with a bustling Parisian atmosphere & a serious French menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7248,
        store_longitude: -73.9926,
        total_orders: 2268.44,
        neighborhood: 'NoHo',
        keywords: 'Burger, Tacos, Steak, Beer, Salad, Nachos, Ale, Irish, Bourbon, Delicious',
        annual_dol: 2681.28,
        description:
            'Big, brick-walled bar with a lengthy beer lineup, standard pub grub & abundant soccer matches on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7715,
        store_longitude: -73.9819,
        total_orders: 3359.79,
        neighborhood: 'Lincoln Square/UWS',
        keywords: 'Lobster, Burger, Salad, Tomato Soup, Dessert, Wine, Vodka, Italian, Spicy, Gin',
        annual_dol: 3583.13,
        description:
            'Lively place for classic American fare & specialty cocktails in spacious digs with a hopping bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7763,
        store_longitude: -73.9794,
        total_orders: 992.68,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Lobster Roll, Steak, Shrimp Cocktail, Wine, Coffee, Martini, Italian, French Onion Soup, Delicious',
        annual_dol: 1050.01,
        description:
            'French bistro fare like duck a l’orange & steak frites in a warm setup with an old-fashioned feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7785,
        store_longitude: -73.9782,
        total_orders: 276.65,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Sushi, Japanese, Spicy, Elegant, Local, Modern, Relaxed, Fresh, Casual, Traditional',
        annual_dol: 295.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7549,
        store_longitude: -73.988,
        total_orders: 7060.1,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Caribbean, Rooftop, Southern, Great, Excellent, Nachos',
        annual_dol: 18192.86,
        description:
            'Featuring rainfall showers and soundproof windows, the airy, island-inspired rooms provide flat-screen TVs and Wi-Fi. All offer minifridges and Keurig coffeemakers. Some add city views and/or sofabeds.There’s a heated rooftop pool and a fitness center. Other amenities include 2 restaurants, 2 bars and a convenience store.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8381,
        store_longitude: -73.7182,
        total_orders: 463.1,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Italian, Mediterranean, Turkish, Fabulous, Sophisticated, Excellent, Chic, Incredible',
        annual_dol: 1892.99,
        description:
            '"A stunning Italian/Mediterranean waterfront restaurant & bar , featuring an Oyster Bar, Wood fire Oven, and incredible craft cocktails. Beautiful panoramic views of the sunset."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7501,
        store_longitude: -73.9849,
        total_orders: 1794.57,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Cheesesteak, Beer, Spicy, Philly Cheesesteak, Mimosa, German, Nachos, Irish, American',
        annual_dol: 1899.64,
        description:
            'Hip & airy wood-clad beer hall offering numerous crafts on tap & creative gastropub food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.756,
        store_longitude: -73.9942,
        total_orders: 43.35,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Meatball, Shrimp Cocktail, Margarita, Italian, Wine, Upscale, American, Spanish',
        annual_dol: 101.23,
        description:
            'Seasonal American dishes & locally sourced products showcased in a rustic-chic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6571,
        store_longitude: -73.6431,
        total_orders: 1834.39,
        neighborhood: '',
        keywords:
            'Beer, Mexican, Wine, Trendy, Upscale, Delicious, Italian, Spanish, Latin, American',
        annual_dol: 1873.32,
        description: '"A Latin experience on the island!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6322,
        store_longitude: -73.9337,
        total_orders: 618.12,
        neighborhood: 'Flatlands/East Flatbush',
        keywords: 'Wine, Spicy, Coffee, Funky, Margarita, Delicious, Juicy, Beer, Cozy, Asian',
        annual_dol: 943.41,
        description:
            'Cozy restaurant offering Caribbean & Asian fare, plus outdoor seats & tropical cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.9837,
        total_orders: 4690.75,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Lobster, Lobster Tail, Vegan, Salad, American, South African, Warm, Cozy, African',
        annual_dol: 5222.84,
        description:
            'Outdoor lounge at Bryant Park offering laid-back seating & a basic menu of American fare & drinks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.656,
        store_longitude: -73.6451,
        total_orders: 61.8,
        neighborhood: '',
        keywords: 'Sushi, Salad, Japanese, Spicy, Asian, Friendly, Casual, Tempura, Great',
        annual_dol: 232.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7578,
        store_longitude: -73.9994,
        total_orders: 1048.17,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Steak, Salad, Upscale, French, Premium, Fun, Friendly, Exotic, Great, Fine',
        annual_dol: 1093.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7677,
        store_longitude: -73.9824,
        total_orders: 412.6,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Steak, Burger, Organic, Italian, Upscale, Wine, American, Modern, Trendy, French',
        annual_dol: 430.48,
        description:
            'New American fare & city views in an arty space on the top-floor of the Museum of Arts & Design.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7656,
        store_longitude: -73.9839,
        total_orders: 1740.65,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Pizza, Mexican, Salad, Italian, Beer, Whiskey, Wine, Irish, Delicious',
        annual_dol: 1817.62,
        description:
            'Lively Irish bar with brick walls & sports on TV serving standard pub grub, draft beers & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.761,
        store_longitude: -73.9854,
        total_orders: 414.83,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, Thai, Quaint, Cozy, Friendly, Refreshing, Casual',
        annual_dol: 1695.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9771,
        total_orders: 74.91,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Chinese, Dessert, Spicy, Peking Duck, Ramen, Sweet, Mediterranean, Mojito, Perfect, Authentic',
        annual_dol: 79.83,
        description:
            'Shanghainese dishes feature at this subdued Chinese spot convenient to Carnegie Hall & City Center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6832,
        store_longitude: -73.9736,
        total_orders: 572.96,
        neighborhood: 'Fort Greene',
        keywords:
            'Lobster, Lobster Tail, Burger, Wine, Caribbean, Bourbon, Jamaican, Yummy, Friendly, Relaxed',
        annual_dol: 1335.71,
        description:
            'Casual recipes with Jamaican flair whipped up in a relaxed spot with a colorful bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7343,
        store_longitude: -73.9978,
        total_orders: 823.91,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Tasty, Wine, Delicious, Beer, Warm, Spanish, Phenomenal, Charming, Picturesque, Fabulous',
        annual_dol: 1010.92,
        description:
            'A vast array of small plates & wines by the glass plus cocktails are served in a low-lit space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7437,
        store_longitude: -73.9944,
        total_orders: 662.13,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tequila, Mexican, Upscale, Fun, Hotel, Lively, Premium, Iconic, Great, Friendly',
        annual_dol: 1298.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7262,
        store_longitude: -73.9833,
        total_orders: 7051.52,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Hotdog, Salad, Upscale, Cool, Casual, Awesome, Great, Excellent, Chill',
        annual_dol: 7201.14,
        description:
            'This longtime watering hole on a busy Alphabet City corner features DJ-spun tunes & a photo booth.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7257,
        store_longitude: -73.984,
        total_orders: 4736.48,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Scotch, Gin, Martini, Italian, Delicious, Cozy, Legendary, Friendly, Intimate, Classic',
        annual_dol: 4881.34,
        description:
            'Intimate bar offering an extensive list of cocktails that showcase classic Italian & craft bitters.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.723,
        store_longitude: -73.9853,
        total_orders: 1284.31,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Italian, Pizza, Wine, Coffee, Cozy, Rustic, Delicious, Homey, Casual, Iconic',
        annual_dol: 1311.56,
        description:
            'Italian comfort food matches the rustic setting at this cash-only trattoria with an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7279,
        store_longitude: -73.9793,
        total_orders: 0,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Coffee, Italian, Comfortable, Relaxed, Karaoke, Sweet, Vintage, Perfect, Casual',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -73.99,
        total_orders: 462.93,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Whiskey, Beer, Spacious, Hotel, Bright, American, Innovative, Natural, Perfect, Rooftop',
        annual_dol: 1744.19,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7648,
        store_longitude: -73.9915,
        total_orders: 2905.9,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Beer, Spicy, Wine, Bbq, Crisp, Friendly, Relaxed, Western, Outstanding',
        annual_dol: 2905.9,
        description:
            'Laid-back tavern with a Western motif serving Texas-inspired pub grub such as Frito pie.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7641,
        store_longitude: -73.9831,
        total_orders: 3186.36,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Beer, Gluten Free, Bourbon, Irish, Bbq, American, Nachos, Homemade, Classic, Southern',
        annual_dol: 3447.25,
        description:
            'Sports bar with more than 30 draft brews, lots of flat-screen TVs & a second-floor outdoor deck.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7649,
        store_longitude: -73.984,
        total_orders: 3477.79,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Karaoke, Japanese, Korean, Upscale, Fun, Great, Casual, Chill',
        annual_dol: 3739.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9909,
        total_orders: 33.77,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Dessert, Wine, Beer, Japanese, Avocado Toast, Delicious, Ramen, Contemporary, Exceptional',
        annual_dol: 138.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6397,
        store_longitude: -73.5818,
        total_orders: 204.74,
        neighborhood: '',
        keywords: 'Mexican, Italian, Chilean, Modern, Casual, Chill, Great',
        annual_dol: 475.25,
        description:
            '"Nautical Mile eatery served with a variety of Italian and Mexican specialties and stunning views of the water. Join us for Lunch Mon-Fri 11:00am-3:00pm or our Happy Hour Mon-Fri 3:00pm-7:00pm. We look forward to your visit."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.866,
        store_longitude: -73.9198,
        total_orders: 5.06,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Salad, Pizza, Roast Beef, Latin, Caribbean, Healthy, American, Fantastic, Great, Casual',
        annual_dol: 20.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7455,
        store_longitude: -73.9992,
        total_orders: 252.31,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Vegan, Lobster, Salad, Pizza, Gluten Free, Tasty, Italian, Wine, Homemade',
        annual_dol: 287.36,
        description:
            'Sophisticated, airy outpost featuring Neapolitan-style pies, pasta & other classic Italian dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7413,
        store_longitude: -74.0049,
        total_orders: 4258.83,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Cheesesteak, Salad, Japanese, Dessert, Wine, Legendary, American, Classic',
        annual_dol: 4349.19,
        description:
            'Old-world relic (1868-era) serving huge, pricey steaks in a classic, dark-wood setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7349,
        store_longitude: -73.9976,
        total_orders: 1725.75,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Steak, Mexican, Vegetarian, Tequila, Wine, Margarita, Coffee, Nachos, Tasty, French',
        annual_dol: 2003.93,
        description:
            'Neighborhood joint for Mexican food by a native chef-owner, plus mezcals & other agave spirits.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.9831,
        total_orders: 2375.94,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Tequila, Upscale, Beer, Margarita, Pizza, Nachos, Vibrant, Perfect, Lively, Awesome',
        annual_dol: 3158.6,
        description: '"Upscale sports bar in East Village, NY."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7175,
        store_longitude: -73.9562,
        total_orders: 308.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, French, Trendy, Beer, Romantic, Moroccan, Classy, Delicious, Intimate, Casual',
        annual_dol: 324.43,
        description: '"Nice Atmosphere restaurant, serving French food, nice Crafted Cocktails"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6795,
        store_longitude: -73.9883,
        total_orders: 5.36,
        neighborhood: 'Gowanus',
        keywords:
            'Peruvian, Salad, Steak, Wine, Dessert, South American, Cozy, Sweet, Friendly, Casual',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7424,
        store_longitude: -73.9804,
        total_orders: 1657.21,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Rooftop, American, Upscale, Wholesome, Awesome, Fantastic, Fun, Great, Aesthetic, Traditional',
        annual_dol: 1692.38,
        description:
            'Chill tri-level eatery/bar with rooftop dining & Empire State Building views, serving mains & wings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9597,
        total_orders: 280.96,
        neighborhood: 'Williamsburg',
        keywords:
            'Spacious, Coffee, Beer, Awesome, Fantastic, Amazing, Bright, Great, Rooftop, Intimate',
        annual_dol: 1148.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.727,
        store_longitude: -73.9948,
        total_orders: 9152.94,
        neighborhood: 'NoHo',
        keywords:
            'Sushi, Italian, Hotel, Eclectic, American, High-End, Fun, Modern, Comfortable, Iconic',
        annual_dol: 17015.06,
        description:
            '"Zero Bond is a twenty thousand square foot private membership club in the heart of Noho. The club will have something for every member at every hour: days filled with comfortable workspaces and conference rooms focused on productivity for an individual or a group, and nights alive with multiple restaurants and bars, private event space, and curated exclusive experiences."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7269,
        store_longitude: -73.9944,
        total_orders: 18183.38,
        neighborhood: 'NoHo',
        keywords: 'Sushi, Salad, Japanese, Italian, Korean, Spicy, Creamy, Trendy, Warm, Sweet',
        annual_dol: 19931.4,
        description:
            'High-end sushi & Japanese dishes in a chic, trendy atmosphere with a well-heeled crowd.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7305,
        store_longitude: -74.0011,
        total_orders: 477.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Lobster, Tequila, Italian, Wine, Beer, Meatball, Upscale, Ale, Trendy, Elegant',
        annual_dol: 654.93,
        description:
            'Warm, brick-walled venue for comforting Italian plates including chilled seafood & pasta.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7286,
        store_longitude: -74.0019,
        total_orders: 785.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Italian, Wine, Shrimp Cocktail, Impeccable, Vintage, Quaint, Homemade, Romantic, Traditional',
        annual_dol: 839.95,
        description:
            'Open since 1976, this family-run fixture with vintage charm serves homemade pasta & classic entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6942,
        store_longitude: -73.9917,
        total_orders: 164.88,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Mexican, Tacos, Burritos, Pizza, Salad, Vietnamese, Warm, Local, Casual',
        annual_dol: 260.87,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7206,
        store_longitude: -73.9845,
        total_orders: 25.37,
        neighborhood: 'Lower East Side',
        keywords:
            'Ale, Delicious, Comfortable, Friendly, Premium, Sweet, Asian, Traditional, Casual, Great',
        annual_dol: 103.7,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8143,
        store_longitude: -73.9484,
        total_orders: 603.9,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Lobster, Lobster Tail, Dessert, Delicious, Fresh, Ample, Southern',
        annual_dol: 2275.29,
        description:
            'Laid-back counter serving up ample plates of fresh seafood, fried fish & homestyle Southern fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9843,
        total_orders: 1238.74,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Vodka, Salad, Russian, Chinese, Martini, Tangy, Hotel, Zesty, Seasonal, Dolma',
        annual_dol: 1290.24,
        description:
            'Russian staples & infused vodkas are served at this longtime eatery that hosts live piano music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9843,
        total_orders: 286.06,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Italian, Wine, Coffee, Romantic, Caesar Salad, Perfect, Wonderful, Great, Fresh',
        annual_dol: 338,
        description:
            'Old-school dining room where theatergoers gather before a show for a Northern Italian meal.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.9891,
        total_orders: 9063.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Mexican, Salad, Beer, Nachos, Margarita, Wine, Vietnamese, Upscale, American',
        annual_dol: 10579.58,
        description:
            'Convivial watering hole featuring nachos, pizza & American bar fare, plus sports on TV.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7663,
        store_longitude: -73.9814,
        total_orders: 3329.51,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Wine, Italian, Coffee, Beer, Bruschetta, Friendly, Contemporary, Modern, Casual',
        annual_dol: 4181.5,
        description:
            'Contemporary restaurant serving Italian-inspired fare with influences from the Pacific Northwest.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7664,
        store_longitude: -73.9866,
        total_orders: 635.33,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Meatball, Organic, Delicious, Polished, Modern, Traditional, Authentic, Casual',
        annual_dol: 717.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9774,
        total_orders: 1684.74,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Salad, Wine, Whiskey, Italian, Modernist, Hotel, American, French, Irish',
        annual_dol: 2503.62,
        description:
            'French/New American fare in a modernist space with garden views at the Museum of Modern Art.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.76,
        store_longitude: -73.9888,
        total_orders: 0,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Italian, Wine, Rustic, Cozy, Elegant, Warm, Charming, Perfect, Intimate',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.8848,
        total_orders: 31.81,
        neighborhood: 'Jackson Heights',
        keywords:
            'Steak, Pizza, Hamburger, Lobster, Colombian, Delicious, Irish, Stylish, Modern, Indian',
        annual_dol: 130.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7334,
        store_longitude: -73.9986,
        total_orders: 225.28,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Sushi, Vegetarian, Upscale, Trendy, Hotel, French, Mediterranean, Seasonal, Intimate',
        annual_dol: 250.83,
        description:
            'Buzzy, intimate spot offering a daily changing menu of seasonal Israeli plates & absinthe cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7392,
        store_longitude: -74.0008,
        total_orders: 967.3,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Cajun, Lobster, Cajun-Style, Juicy, Wine, Delicious, Beer, Creole, Relaxed',
        annual_dol: 1530.44,
        description:
            'Casual, hands-on joint for Cajun crawfish, shrimp & lobster with a choice of seasonings & spices.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.715,
        store_longitude: -73.9584,
        total_orders: 210.57,
        neighborhood: 'Williamsburg',
        keywords:
            'Pizza, Tequila, Salad, Whiskey, Margarita, Triple Sec, Zesty, Greek, Fresh, Sweet',
        annual_dol: 737.48,
        description:
            'Down-to-earth restaurant offering deep dish & thin crust pizza, plus garlic bread.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6835,
        store_longitude: -73.9675,
        total_orders: 78.14,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Vegan, Italian, Beer, Organic, French Toast, Trendy',
        annual_dol: 226.11,
        description:
            'Stark yet intimate candelit eatery with classic & other Italian meals, including homemade pastas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7197,
        store_longitude: -73.956,
        total_orders: 2088.56,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Salad, Beer, Tasty, Juicy, Rustic, American, Eclectic, Casual',
        annual_dol: 2088.56,
        description:
            'American pub with a globally inspired menu served in an old-time setting with an eclectic feel.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.722,
        store_longitude: -73.9575,
        total_orders: 2689.68,
        neighborhood: 'Williamsburg',
        keywords: 'Vegan, Vegetarian, Fun, Hotel, Local, Fantastic, Hummus, Unforgettable, Crafted',
        annual_dol: 2746.75,
        description:
            'Genre-defying bowling alley in Williamsburg with high-tech lanes, live tunes & food by Blue Ribbon.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.9257,
        total_orders: 14.28,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Mexican, Coffee, Colombian, American, Latin, Hotel, Casual, Western, Local, Sweet',
        annual_dol: 58.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9789,
        total_orders: 4022.63,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Champagne, Russian, Elegant, Legendary, American, Classic, Contemporary, Rooftop, Complex',
        annual_dol: 4297.43,
        description:
            'The Rainbow Room is a private event space on the 65th floor of 30 Rockefeller Plaza at Rockefeller Center in Midtown Manhattan, New York City. Run by Tishman Speyer, it is among the highest venues in New York City. The Rainbow Room serves classic and contemporary American cuisine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8042,
        store_longitude: -73.9484,
        total_orders: 112.93,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Steak, Tequila, Mexican, Margarita, Delicious, Polished, American, Latin, Perfect, Friendly',
        annual_dol: 192.54,
        description:
            'Unassuming, colorful restaurant serving Mexican food favorites like burritos, plus margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.9985,
        total_orders: 1629.65,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Salad, Roast Turkey, Wine, Beer, Mimosa, Italian, Ale, Crisp, Sweet',
        annual_dol: 1711.55,
        description:
            'A collegiate crowd bellies up to the antique pine bar to drink from a vast beer list & eat pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.8815,
        total_orders: 2087.31,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Steak, Shrimp Cocktail, Argentinian, Juicy, Relaxed, Brazilian, Traditional, Friendly, Casual, Eclectic',
        annual_dol: 2396.77,
        description:
            'Relaxed eatery offering skirt steak, empanadas & other Argentinean dishes, plus sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7524,
        store_longitude: -73.896,
        total_orders: 283.49,
        neighborhood: 'Jackson Heights',
        keywords:
            'Chicken Parmigiana, Nachos, Upscale, Quesadillas, American, Fun, Refreshing, Exquisite, Awesome, Great',
        annual_dol: 308.51,
        description:
            'Vast bar featuring pool, ping pong, air hockey & foosball tables, plus an ample, diverse menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9885,
        total_orders: 1253.32,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Rustic, Clean, Casual, American, Local, Sweet, Indian, Fine, Great',
        annual_dol: 1333.78,
        description:
            'Laid-back, exposed-brick haunt offering an extensive beer selection & menu of pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8117,
        store_longitude: -73.9503,
        total_orders: 2765.68,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Burger, Steak, Hamburger, Salad, Bbq, Tasty, Upscale, American, Casual, Local',
        annual_dol: 3924.78,
        description:
            'Ribs, wings, burgers & frozen margaritas are the specialties of this neighborhood restaurant/bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6945,
        store_longitude: -73.8095,
        total_orders: 420.43,
        neighborhood: 'Jamaica',
        keywords:
            'Steak, Chicken Parmigiana, Salad, Spanish, French, Homemade, Cozy, Warm, Traditional, Authentic',
        annual_dol: 467.77,
        description:
            'Traditional Portuguese cuisine served in roomy surrounds with mosaics, marble arches & a cozy bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8068,
        store_longitude: -73.9591,
        total_orders: 380.71,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Vegan, Whiskey, Salad, Dessert, Elegant, Premium, Perfect, African, Modern, Great',
        annual_dol: 434.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7631,
        store_longitude: -73.9853,
        total_orders: 90.67,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Beer, Pizza, Burritos, Wine, Ramen, Delicious, Local, Casual',
        annual_dol: 554.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7151,
        store_longitude: -73.9921,
        total_orders: 672.41,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Karaoke, Martini, Spacious, Ale, Friendly, Awesome, Clean, Wonderful, Cool, Great',
        annual_dol: 704.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7171,
        store_longitude: -73.9953,
        total_orders: 1447,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Rooftop, Tequila, Hotel, Beer, Trendy, Spectacular, Cozy, Modern, Great, Friendly',
        annual_dol: 1612.34,
        description:
            'Bi-level lounge atop the Wyndham Garden Hotel, featuring spirits & panoramic views of NYC.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 362.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lobster, Burger, Wine, Italian, Beer, Delicious, French, Vietnamese, Chinese, Quaint',
        annual_dol: 1503.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5845,
        store_longitude: -73.6666,
        total_orders: 1588.44,
        neighborhood: '',
        keywords:
            'Steak, Hotel, Tomato Soup, Salad, Shrimp Cocktail, Gourmet, Elegant, French Onion Soup, French, Organic',
        annual_dol: 1692.82,
        description:
            'Modern rooms have Internet access (surcharge) and elegant furniture, plus limestone bathrooms with spa showers, 37-inch flat-screen TVs, 24-hour room service and minifridges; suites add living rooms and wet bars, plus beachfront or city views.Perks include a seasonal, heated rooftop pool and chic lounge area, a private tunnel to the beach, a fitness center with ocean views, a grand event ballroom, and dining and nightlife options, including a gourmet eatery and a bar/lounge, both with ocean views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7115,
        store_longitude: -73.9469,
        total_orders: 475.86,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Vegetarian, Vegan, Tacos, Salad, Mexican, Coffee, Wine, Beer, Organic',
        annual_dol: 568.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9854,
        total_orders: 25.76,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Hawaiian, Bread Pudding, Wine, Tasty, Chinese, Spicy, Cozy, Asian, Fried Rice, Modern',
        annual_dol: 105.31,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7493,
        store_longitude: -73.9791,
        total_orders: 1508.05,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Salad, Italian, Wine, Meatball, Tangy, Elegant, Tranquil, Classy, Vintage',
        annual_dol: 1608.3,
        description:
            'Vintage Northern Italian eatery with tuxedoed waiters, live piano nightly & opera on Saturday night.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7478,
        store_longitude: -73.9848,
        total_orders: 224.25,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Pizza, Vegan, Salad, Gluten Free, Organic, Modern, Prime Rib, French, Innovative, Fabulous',
        annual_dol: 916.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6665,
        store_longitude: -73.7801,
        total_orders: 140.57,
        neighborhood: 'Jamaica/Addisleigh Park',
        keywords: 'Hotel, Friendly, Authentic, Excellent, Local',
        annual_dol: 574.62,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6285,
        store_longitude: -74.0166,
        total_orders: 314.37,
        neighborhood: 'Dyker Heights',
        keywords: 'Italian, Bruschetta, Casual, Local',
        annual_dol: 1184.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7652,
        store_longitude: -73.983,
        total_orders: 3931.09,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Pizza, Salad, Japanese, Dessert, Beer, Ramen, Sashimi, Irish, Asian',
        annual_dol: 4334.84,
        description:
            'Casual venue serving classic Japanese cooking, including udon, pork cutlets & curry.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7644,
        store_longitude: -73.9868,
        total_orders: 6706.87,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Cozy, Authentic, Casual, Amazing, Great, Recommended',
        annual_dol: 6849.18,
        description:
            'Spacious, industrial-chic gay bar with a bustling after-work scene fueled by a nightly happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.987,
        total_orders: 1313.2,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Wine, Red Eye, Fantastic, Incredible, Casual',
        annual_dol: 1399.49,
        description:
            'Happening gay nightclub featuring DJs, drag shows & stand-up comedy in bi-level digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7168,
        store_longitude: -74.008,
        total_orders: 3527.89,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Salad, Cozy, Rustic, Warm, French, Romantic, Healthy, Homemade, Casual, Modern',
        annual_dol: 3694.96,
        description:
            'Historical 1810 bi-level townhouse showcases New American fare in a dimly lit, rustic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7219,
        store_longitude: -73.9922,
        total_orders: 0,
        neighborhood: 'Bowery',
        keywords: 'American, High-End, Vintage, Bright, Casual, Omakase',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7557,
        store_longitude: -73.8861,
        total_orders: 99.88,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Mexican, South American, Wine, Colombian, Latin, Tasty, American, Fusion, Spanish, Lively',
        annual_dol: 143.99,
        description:
            'Lively wine bar pairing vintages with Colombian small plates, plus Spanish meats & cheeses.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7632,
        store_longitude: -73.9839,
        total_orders: 466.89,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Vodka, Russian, Latin, Perfect, Indian, Authentic',
        annual_dol: 1768.03,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8671,
        store_longitude: -73.9226,
        total_orders: 313.29,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Burger, Steak, Beer, Argentinian, Stylish, Bbq, Dessert, Tex-Mex, Thai, Clean',
        annual_dol: 319.93,
        description:
            'Sports bar & restaurant serving American fare in a sizable, modern-rustic space with a beer garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8643,
        store_longitude: -73.9267,
        total_orders: 94.92,
        neighborhood: 'Fort George',
        keywords: 'Tequila, Tacos, Steak, Mexican, Lobster, Beer, Wine, Delicious, Trendy, Spanish',
        annual_dol: 101.01,
        description:
            'Energetic sports bar dishing out Mexican & American pub standards alongside cocktails & beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 592.46,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Salad, Pizza, Tequila, Gluten Free, Vodka, Beer, Margarita, Italian',
        annual_dol: 618.14,
        description:
            'Breakfast & dinner are also served at this upscale American luncheonette in Chelsea Market.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7128,
        store_longitude: -73.9575,
        total_orders: 347.93,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Sushi, Vegan, Vegetarian, Mexican, Gluten Free, Beer, Vodka, Italian, Japanese, Ramen',
        annual_dol: 375.15,
        description:
            'Japanese tapas bar serving sushi, sake & cocktails in a hip setting with a bi-level patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.9533,
        total_orders: 5392.43,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Tacos, Hamburger, Salad, Mexican, Nachos, Margarita, Quesadillas, Wine, Rum, Asian',
        annual_dol: 5876.92,
        description:
            'Upbeat eatery & bar featuring classic & fusion Mexican dishes, plus beer, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7201,
        store_longitude: -73.9617,
        total_orders: 137.3,
        neighborhood: 'Williamsburg',
        keywords: 'Vegan, Vegetarian, Mexican, Tacos, Tequila, Margarita, Casual, Local, Fresh',
        annual_dol: 562.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9817,
        total_orders: 399.63,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Australian, Hotel, Wine, Trendy, Mediterranean, High-End, Elegant, Rooftop, Turkish, Chic',
        annual_dol: 450.69,
        description:
            "Chic rooms provide free Wi-Fi, smart TVs and iPads, plus minibars and marble bathrooms. Suites add wet bars; upgraded 1- and 2-bedroom suites have living rooms. Studios and 1- to 2-bedroom apartments offer kitchens with washer/dryers. Luxe penthouses are also available. Some units have balconies.Continental breakfast is included. There's an elegant bar, a spa and a hammam, plus an indoor pool and a 24-hour gym. Pets are welcome (surcharge).",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7215,
        store_longitude: -73.9585,
        total_orders: 1580,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Coffee, Delicious, Rum, Premium, Sweet, Fantastic, Perfect, Intimate, Great',
        annual_dol: 5952.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7805,
        store_longitude: -73.9812,
        total_orders: 6016.27,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Hotel, Beer, Ale, Fun, Awesome, Perfect, Intimate, Great, Local',
        annual_dol: 6016.27,
        description:
            'The Beacon Theatre is an entertainment venue at 2124 Broadway, adjacent to the Hotel Beacon, on the Upper West Side of Manhattan in New York City. Opened in 1929, the Beacon Theatre was developed by Samuel "Roxy" Rothafel and built as a movie palace, with 2,894 seats across three levels.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.783,
        store_longitude: -73.9528,
        total_orders: 50.33,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Salad, Pizza, French, Fun, Global, Fresh, Great, Cultural',
        annual_dol: 68.52,
        description:
            '92nd Street Y, New York is a cultural and community center located on the Upper East Side of Manhattan in New York City, at the corner of East 92nd Street and Lexington Avenue.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7628,
        store_longitude: -73.9869,
        total_orders: 843.48,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Pizza, Vegetarian, Italian, Gluten Free, Beer, Wine, Dessert, Homemade, Seasonal, Relaxed',
        annual_dol: 880.05,
        description:
            'Relaxed venue with seasonal cocktails, Italian craft beers & classic Neapolitan pizza.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7682,
        store_longitude: -73.9825,
        total_orders: 1521.36,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Wine, Dessert, Beer, Spectacular, Premium, Serene, Amazing, French, Intimate, Great',
        annual_dol: 1966.47,
        description:
            "Chef Thomas Keller's New American restaurant offers luxe fixed-price menus, with Central Park views.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7187,
        store_longitude: -73.9894,
        total_orders: 2558.66,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Burger, Rooftop, Salad, Luxurious, French Toast, Hotel, Organic, Spectacular, French',
        annual_dol: 2612.95,
        description:
            'Cool, multi-level venue with a chandelier-lit restaurant & lively rooftop lounge with skyline views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.664,
        store_longitude: -73.9544,
        total_orders: 255.25,
        neighborhood: 'Crown Heights',
        keywords: 'Zombie, Vintage, Hotel, Yummy, Fun, Awesome, Amazing, Great, Western, Local',
        annual_dol: 1043.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7294,
        store_longitude: -74.0008,
        total_orders: 34.93,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Italian, Pizza, Delicious, Organic, Intimate, Fresh, Traditional, Classic, Tradition',
        annual_dol: 142.79,
        description:
            'Narrow Italian eatery with counter-service offering organic pasta, housemade sauces & classic sides.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7623,
        store_longitude: -73.9827,
        total_orders: 127.23,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Lobster, Lobster Roll, Vegetarian, Margarita, Wine, Beer, Sustainable, Healthy, Relaxed, American',
        annual_dol: 520.09,
        description:
            'Relaxed eatery serving sustainable seafood plus beer, wine & cocktails in bright, beachy digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6833,
        store_longitude: -73.9761,
        total_orders: 31321.09,
        neighborhood: 'Prospect Heights',
        keywords: 'Wine, Italian, Sweet, Fun, Classic, Great, Casual',
        annual_dol: 32403.96,
        description:
            "Barclays Center is a multi-purpose indoor arena in the New York City borough of Brooklyn. The arena is home to the Brooklyn Nets of the National Basketball Association and the New York Liberty of the Women's National Basketball Association.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7361,
        store_longitude: -73.9965,
        total_orders: 2119.64,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Hotel, Champagne, Wine, Upscale, Elegant, Vintage, Iconic, Warm, Exquisite, Coffee',
        annual_dol: 2211.54,
        description:
            "Sophisticated, art deco style rooms offer free Wi-Fi and flat-screen TVs, as well as vintage rotary phones and custom ebony beds. Suites add sitting areas; some have furnished terraces. Room service is available 24/7.Amenities include a retro-chic bar, a polished restaurant with plush booths, and a refined guest lounge featuring a fireplace. There's also a 24-hour fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7331,
        store_longitude: -73.9981,
        total_orders: 3458.56,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Wine, French, Spicy, Classy, Aesthetic, Creative, Cool, Great, Casual, Dynamic',
        annual_dol: 3688.42,
        description:
            'Creative craft cocktails & snacks in a petite brick-walled space with some banquette seats & stools.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6812,
        store_longitude: -73.977,
        total_orders: 170.1,
        neighborhood: 'Park Slope',
        keywords:
            'Chocolate Cake, Milkshake, Wine, Dessert, Coffee, Beer, Vegan, Tasty, Banana Split, Italian',
        annual_dol: 695.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 556.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Pizza, Italian, Wine, Delicious, Incredible, Wonderful, Great, Casual',
        annual_dol: 2273.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7109,
        store_longitude: -73.9503,
        total_orders: 1786.08,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Whiskey, Wine, Beer, Coffee, Ale, Irish Car Bomb, Tasty, Irish, Timeless, American',
        annual_dol: 1823.97,
        description:
            'Airy sports bar with cabinlike wooden walls & booths, multiple TVs, beer, cocktails & food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7133,
        store_longitude: -73.9356,
        total_orders: 3316.29,
        neighborhood: 'East Williamsburg',
        keywords:
            'Tequila, Mexican, Tacos, Margarita, Rustic, Fun, Latin, Caribbean, Awesome, Lively',
        annual_dol: 10566.05,
        description:
            'Lively Williamsburg bar/restaurant with Mexican-Dominican dishes & a party atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6835,
        store_longitude: -73.9662,
        total_orders: 429.82,
        neighborhood: 'Clinton Hill',
        keywords: 'Pizza, Burger, Vodka, Sushi, Beer, Meatball, Wine, Gourmet, Italian, Rustic',
        annual_dol: 478.93,
        description:
            'This cozy, gourmet eatery serves wood-fired Neapolitan pizzas, rustic small plates & pastas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7505,
        store_longitude: -73.9792,
        total_orders: 2167.84,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Tasty, Pizza, Coffee, Salad, Delicious, Trendy, Wholesome, Roast Beef, Healthy, Polished',
        annual_dol: 2213.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7443,
        store_longitude: -73.9829,
        total_orders: 351.53,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords:
            'Korean, Upscale, Luxurious, Steak, Innovative, Comfortable, Fancy, Martini, Superb, Classic',
        annual_dol: 410.08,
        description:
            "Upscale Korean restaurant offering a chef's tasting menu with beverage pairings in chic environs.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7614,
        store_longitude: -73.9245,
        total_orders: 1240.7,
        neighborhood: 'Astoria/LIC',
        keywords: 'Champagne, Beer, Bourbon, Fun, Casual, Great, Chill',
        annual_dol: 1323.18,
        description:
            'Large sports bar with TVs for watching the game plus numerous billiards & ping-pong tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7744,
        store_longitude: -73.9079,
        total_orders: 220.6,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Wine, Spicy, Cosmopolitan, Mediterranean, Homemade, Greek, Bellini',
        annual_dol: 901.75,
        description:
            '"Lagano Bar & Restaurant is where you can feel and taste various flavors, from International cuisines like tapas, Cocktails, and wine bars with banquet hall. Our food is inspired by the quality and passion of culinary experts from all over the globe, from all the creative people who ever work and share knowledge with our Executive Chef and Owner Mr. Lagano Celic."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.888,
        total_orders: 61.83,
        neighborhood: 'Jackson Heights/East Elmhurst/Flushing',
        keywords:
            'Lobster, Steak, Pizza, Spanish, Wine, Delicious, Spacious, Fusion, Sangria, Wonderful',
        annual_dol: 103.96,
        description:
            'Spacious Spanish mainstay featuring standards, tapas & cocktails plus guitar music Saturday nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9905,
        total_orders: 140.33,
        neighborhood: 'Lower East Side',
        keywords:
            'Salad, Vegetarian, Tasty, Thai, Gluten Free, Dessert, Wine, Vietnamese, Spicy, Cozy',
        annual_dol: 146.41,
        description:
            'Intimate restaurant serving traditional Thai in a space with bright decor & exposed brick.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.9895,
        total_orders: 100.82,
        neighborhood: 'Lower East Side',
        keywords:
            'Coffee, American, Legendary, Salad, Classic, Eclectic, Retro, Chic, Signature, Fantastic',
        annual_dol: 509.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7223,
        store_longitude: -73.99,
        total_orders: 493.26,
        neighborhood: 'Bowery',
        keywords: 'Cozy, Latin, Casual, Awesome, Unique',
        annual_dol: 2016.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.594,
        store_longitude: -73.5801,
        total_orders: 178.04,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Garlic Bread, Burger, Sushi, Hotdog, Shrimp Cocktail, Salad, Hamburger, Lobster Bisque',
        annual_dol: 270.3,
        description:
            'Casual seafood restaurant with a raw bar, sushi & fried sandwiches, plus waterfront tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 2310.1,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Wine, Upscale, Elegant, Cosmopolitan, Delicious, Premium, Martini, Sophisticated, Inviting',
        annual_dol: 2359.12,
        description:
            'Upscale lounge for cocktails, bites & views from the fourth floor of the Time Warner Center.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7625,
        store_longitude: -73.9753,
        total_orders: 7851.99,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Rich, Hotel, Stylish, Elegant, Funky, Vibrant, Fun, Australian, American, Colorful',
        annual_dol: 8066.17,
        description:
            "Hip rooms with an English-country vibe feature floor-to-ceiling windows, free Wi-Fi and flat-screen TVs, along with minibars; some have terraces and/or sitting areas. The 1- to 3-bedroom suites add living rooms and 2nd bathrooms. Upgraded suites provide kitchens; butler service is offered. Room service is available 24/7.There's a stylish restaurant with vibrant decor. Other amenities include a formal lounge, a terrace and a movie theater, plus a gym and an art-filled lobby.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9759,
        total_orders: 2665.58,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Sushi, Salad, Steak, Wine, Japanese, Beer, Asian, Bbq, Fun, Cool',
        annual_dol: 2737.78,
        description: 'Hibachi chain serving Japanese dishes grilled tableside by theatrical chefs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.849,
        store_longitude: -73.6452,
        total_orders: 12.13,
        neighborhood: '',
        keywords:
            'Salad, Wine, Italian, Warm, Clean, Perfect, Exquisite, Casual, Handcrafted, Awesome',
        annual_dol: 49.6,
        description:
            '"Tavern 227 is a casual, Brooklyn-inspired, restaurant and bar located on the North Shore of Long Island. Upon entering, you will see the focal point of the establishment – our wood handcrafted bar, finished with Italian Carrara marble. The newly restored space was designed with local artists to create a venue that is perfect for dining, having a drink, or hosting that special event. Our Italian-American menu is prepared with only the finest sourced ingredients and complemented by a curated selection of wines, cocktails and cordials. At Tavern 227, we strive to exceed expectations by delivering high quality ingredients and warm hospitality for a delightful dining experience."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.87,
        store_longitude: -73.9154,
        total_orders: 1454.77,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Burger, Steak, Salad, Mexican, Cuban, Dessert, Nachos, Tasty, Spanish, Cozy',
        annual_dol: 1697.04,
        description:
            'Intimate, low-lit restaurant offering classic Cuban meals, cocktails & hookahs in a vibrant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7316,
        store_longitude: -74.0004,
        total_orders: 36.37,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Cheesecake, Wine, Salad, Italian, Coffee, Shrimp Cocktail, Beer, Impeccable, Healthy, Romantic',
        annual_dol: 148.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7405,
        store_longitude: -73.9924,
        total_orders: 14604.34,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Salad, Chinese, Coffee, Elegant, Japanese, Asian, Cozy, Eclectic, Spectacular',
        annual_dol: 14927.63,
        description:
            'Glitzy restaurant in the former Limelight space offering modern Chinese fare in a multiroom setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7264,
        store_longitude: -73.9835,
        total_orders: 1274.26,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Chicken Pot Pie, Irish, American, Classic, Fantastic, Casual, Great, Cool',
        annual_dol: 1485.31,
        description:
            'Irish watering hole dispensing pints & classic pub grub in a snug, patio-equipped setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6773,
        store_longitude: -73.9728,
        total_orders: 6839.1,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords: 'Flavorful, Tasty, Beer, Relaxed, Fun, Casual, Great, American',
        annual_dol: 6984.21,
        description:
            'Relaxed sports bar featuring pool, ping pong, bowling & air hockey, plus casual American fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9862,
        total_orders: 1563.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Steak, Burger, Hamburger, Vegetarian, Salad, Meatball, Scotch, Applejack, Beer, Wine',
        annual_dol: 1608.95,
        description:
            'Open space with cast-iron fixtures & old machinery offering American classics & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7131,
        store_longitude: -73.9582,
        total_orders: 7117.44,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Vodka, Friendly, Welcoming, Cool, Perfect, Casual, Fresh, Great, Amazing',
        annual_dol: 7268.46,
        description:
            'Nothing-fancy corner tavern serving beer & other drinks in a narrow space with outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7066,
        store_longitude: -74.0033,
        total_orders: 1639.66,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Pizza, Burger, Upscale, Luxurious, Wine, Gourmet, Spicy, Beer, Premium, Fantastic',
        annual_dol: 1674.45,
        description:
            'Upscale cinema chain with plush seating, gourmet light bites & cocktails, plus in-theater service.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7709,
        store_longitude: -73.7355,
        total_orders: 97.48,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Gluten Free, Salad, Wine, Delicious, Trendy, Thai, Upscale, Italian, Romantic, Cozy',
        annual_dol: 264.92,
        description:
            '"Serving delicious and authentic Thai cuisine with a cozy and immersive atmosphere that will transport you to the heart of Thailand"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6764,
        store_longitude: -73.9633,
        total_orders: 694.5,
        neighborhood: 'Crown Heights',
        keywords: 'Tequila, Vodka, Beer, Margarita, Gin, Rum, Karaoke, Sweet, Triple Sec, Awesome',
        annual_dol: 2641.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6879,
        store_longitude: -73.9633,
        total_orders: 23.02,
        neighborhood: 'Clinton Hill',
        keywords:
            'Vegan, Vegetarian, Salad, Coffee, Beer, Margarita, Cuban, Nachos, Bourbon, Delicious',
        annual_dol: 94.12,
        description:
            'Coffee & vegan sandwiches & pastries served in a bright, contemporary cafe with a grocery shop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7202,
        store_longitude: -73.9554,
        total_orders: 6542.23,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotdog, Beer, Whiskey, Gin, Tasty, Warm, Laid-Back, Friendly, Homemade, Bloody Mary',
        annual_dol: 7054.33,
        description:
            'Dimly lit saloon with an extensive whiskey & beer list, plus grub that includes occasional BBQ.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7467,
        store_longitude: -73.9799,
        total_orders: 46.88,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Wine, Steak, Beer, Salad, Dessert, Mediterranean, Premium, American, Intimate, Global',
        annual_dol: 191.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.771,
        store_longitude: -73.962,
        total_orders: 3795.47,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Dessert, Warm, Cozy, Classy, Premium, Great, Terrific, Rare',
        annual_dol: 4024.34,
        description:
            'Cocktails & bar bites in a classy watering hole with palm tree wallpaper & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7707,
        store_longitude: -73.9572,
        total_orders: 971.5,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Thai, Gluten Free, Coffee, Wine, Chinese, Organic, Trendy, Ale, Crab Rangoon',
        annual_dol: 1058.79,
        description:
            'Bright, contemporary-style dining spot serving refined Thai curries & stir-fries.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9582,
        total_orders: 72.56,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Hotel, Burger, Wine, Donut, Premium, Delicious, Classic, Rooftop, Contemporary',
        annual_dol: 296.61,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7236,
        store_longitude: -73.9519,
        total_orders: 146.84,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords: 'Savory, Cool, Salad, Premium, Shrimp Cocktail, Casual, American',
        annual_dol: 194.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6875,
        store_longitude: -74.0013,
        total_orders: 530.69,
        neighborhood: 'Columbia Street Waterfront District',
        keywords:
            'Beer, Margarita, Spicy, Spacious, Relaxed, Friendly, Amazing, Great, Excellent, Local',
        annual_dol: 666.49,
        description: 'Relaxed hangout specializing in craft beers on tap & in bottles & cans.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8411,
        store_longitude: -73.7858,
        total_orders: 108,
        neighborhood: 'City Island',
        keywords: 'Lobster, Crab Cakes, Salad, Spicy, Coffee, Beer, Hotel, Greek, Homemade, Fun',
        annual_dol: 168.49,
        description:
            '"City Island Yacht Club (CIYC) has been a haven for sailors for more than 100 years. Located in the Bronx on Long Island Sound, CIYC is a thriving community of enthusiastic sailors and a gathering place for those who enjoy time on the water or those who aspire to spend more time on the water! CIYC members hail from Manhattan, Brooklyn, Queens, the Bronx, Westchester County, Long Island and New Jersey. They are both expert and casual racers, weekend cruisers and day sailors. After a breezy afternoon on the boat they enjoy socializing in our waterfront restaurant and pub, admiring the dramatic sunset backdrop. The Galley Restaurant and pub is open to public May through October most Wednesdays and Weekends. Reservations Recommended."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5868,
        store_longitude: -73.7147,
        total_orders: 9038.75,
        neighborhood: '',
        keywords: 'Hotel, Relaxed, Sweet, Perfect, Great, Unique',
        annual_dol: 10087.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5231,
        store_longitude: -74.2394,
        total_orders: 504.49,
        neighborhood: 'Charleston',
        keywords:
            'Tacos, Lobster, Mexican, Burritos, Tex-Mex, Gluten Free, Homemade, Coleslaw, Casual, Classic',
        annual_dol: 1900.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7319,
        store_longitude: -73.9897,
        total_orders: 7550.19,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Pizza, Rooftop, Global, Exceptional, Clean, Excellent, Local',
        annual_dol: 8101.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7285,
        store_longitude: -73.9849,
        total_orders: 359.68,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Pizza, Italian, Meatball, Delicious, Hotel, Fun, Iconic, Great',
        annual_dol: 1262.69,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.733,
        store_longitude: -73.9861,
        total_orders: 2084.02,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Trendy, Gin, Beer, Delicious, Belgian, Friendly, Moroccan, Awesome, Perfect',
        annual_dol: 2222.56,
        description:
            'British-style pub & eatery providing elevated bar bites along with gin cocktails & U.K. brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8026,
        store_longitude: -73.9643,
        total_orders: 24.15,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Karaoke, Stylish, Wine, Quaint, Friendly, Fun, Eclectic, Casual, Modern, Great',
        annual_dol: 42.33,
        description:
            'A small, friendly gay lounge with a neighborhood vibe & weekly events like drag-queen karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8503,
        store_longitude: -73.9314,
        total_orders: 7.71,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Lobster, Beer, Coffee, Vodka, Shrimp Cocktail, Wine, Delicious, Intimate, Quirky, Cool',
        annual_dol: 31.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6947,
        store_longitude: -73.9108,
        total_orders: 7.06,
        neighborhood: 'Bushwick',
        keywords: 'Milkshake, Latin, Dominican, American, Natural, Casual, Local, Fresh',
        annual_dol: 28.87,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7594,
        store_longitude: -73.9826,
        total_orders: 425.65,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Mexican, Beer, Burritos, Wine, Local, Casual, Delicious, Healthy',
        annual_dol: 2169.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8286,
        store_longitude: -73.9485,
        total_orders: 4525.2,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Vegan, Steak, Cheesesteak, Tomato Soup, Gluten Free, Tasty, Bourbon, English Muffin, Gin',
        annual_dol: 10067.46,
        description:
            'Eclectic eats, craft cider & brews served up in a lively tavern with quirky decor & pinball games.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7609,
        store_longitude: -73.9687,
        total_orders: 75.09,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Gluten Free, Wine, Upscale, Organic, Contemporary, Modern, Casual, Classic, Iconic',
        annual_dol: 306.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7544,
        store_longitude: -73.9631,
        total_orders: 285.71,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Luxurious, Warm, Exquisite, American, Classic, French, Awesome, Fresh, Traditional, Contemporary',
        annual_dol: 291.77,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7575,
        store_longitude: -73.9859,
        total_orders: 1860.41,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Cheesecake, Chocolate Mousse, Dessert, Tacos, Italian, Hungarian, Delicious, Avocado Toast, Bbq, Thai',
        annual_dol: 2220.08,
        description:
            'Cheesecakes are the star at this Brooklyn-themed locale with deli fare, BBQ, a full bar & patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6903,
        store_longitude: -73.9963,
        total_orders: 8559.14,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Burger, Hamburger, Salad, Beer, Wine, Ale, Martini, Gin, French, Delicious',
        annual_dol: 8930.24,
        description:
            'Hip Cobble Hill pub serving grass-fed burgers, salads, sandwiches & sophisticated cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6897,
        store_longitude: -73.9709,
        total_orders: 6233.88,
        neighborhood: 'Fort Greene',
        keywords:
            'Lobster, Burger, Sushi, Salad, Steak, Italian, Wine, Japanese, Mediterranean, Chinese',
        annual_dol: 6678.19,
        description:
            'Mediterranean-Italian locale serving pastas, small plates & wood-fired dishes, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7835,
        store_longitude: -73.9508,
        total_orders: 428.24,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Salad, Dessert, Delicious, Wine, Warm, Mediterranean, Greek, Polished, Sleek',
        annual_dol: 466.72,
        description:
            'Upbeat Greek eatery serving an array of fish dishes & small plates in sleek digs with stone arches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7836,
        store_longitude: -73.9507,
        total_orders: 148.38,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Sushi, Salad, Japanese, Martini, Sashimi, Asian, Delicious, Teriyaki, Tranquil, Local',
        annual_dol: 162.66,
        description:
            'Tranquil find with dark-wood decor offering a sushi selection plus cooked Japanese dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7484,
        store_longitude: -73.9945,
        total_orders: 1502.7,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Burger, Vegan, Beer, Spicy, Ale, American, Irish, Cozy, Superb',
        annual_dol: 1587.87,
        description:
            'Cool, laid-back spot with a long bar for elevated pub fare & a rotating array of craft draft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7468,
        store_longitude: -73.9862,
        total_orders: 3774.73,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Vodka, Wine, Korean, Spicy, Spanish, American, Cozy, Bbq, Modern, Asian',
        annual_dol: 3925.22,
        description:
            'Stylish, rustic-chic gastropub turning out modern Korean bites & cocktails, plus weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7481,
        store_longitude: -73.9863,
        total_orders: 3526.39,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Korean, Wine, Upscale, Beer, Savory, Japanese, Bbq, Ramen, Perfect, Amazing',
        annual_dol: 3679.28,
        description:
            'Tabletop grills cook up choice cuts at this hip, upscale, Korean-inspired restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7484,
        store_longitude: -73.9967,
        total_orders: 643.62,
        neighborhood: '',
        keywords: 'Coffee, Perfect, Great, Local, Foreign',
        annual_dol: 2630.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6742,
        store_longitude: -73.9823,
        total_orders: 11.24,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Steak, Hamburger, Salad, Beer, Canadian, Bbq, Spicy, Cuban, Wine',
        annual_dol: 45.96,
        description:
            'Popular diner offering a lineup of short-order dishes like burgers, wings & salads.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.9898,
        total_orders: 1853.53,
        neighborhood: 'East Village/Bowery',
        keywords: 'Vegan, Pizza, Salad, Gluten Free, Italian, Wine, Dessert, Beer, Mimosa, Organic',
        annual_dol: 2111.04,
        description:
            'Vegan pizzeria for wood-fired pies with vibrant toppings, plus small plates & nut-based cheeses.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7386,
        store_longitude: -73.9889,
        total_orders: 475.79,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Champagne, Wine, Flavorful, Bourbon, Lebanese, Trendy, Stylish, Rich, Delicious, Warm',
        annual_dol: 530.15,
        description:
            'Stylish setting for Middle Eastern small plates & shareable dishes, plus breads & desserts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7293,
        store_longitude: -73.9898,
        total_orders: 431.02,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Wine, Tasty, Local, Casual, Classic, Fresh, Great',
        annual_dol: 1623.95,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6393,
        store_longitude: -73.9684,
        total_orders: 2524.77,
        neighborhood: 'Flatbush',
        keywords:
            'Pizza, Italian, Salad, Breakfast Burrito, Whiskey, Delicious, Homemade, Cozy, Sweet, French',
        annual_dol: 3508.91,
        description:
            'Italian spot serving pizza, from-scratch pasta & cocktails in a cozy, wood-lined space with a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7519,
        store_longitude: -73.9738,
        total_orders: 278.02,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Wine, Japanese, Chinese, Thai, Beer, Asian, Spicy, Sashimi',
        annual_dol: 350.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.781,
        store_longitude: -73.7757,
        total_orders: 2257.49,
        neighborhood: 'Bayside/Bay Terrace',
        keywords:
            'Steak, Burger, Hamburger, Tasty, Delicious, Beer, Juicy, Margarita, American, Australian',
        annual_dol: 2407.43,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9795,
        total_orders: 1472.61,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Salad, Wine, Shrimp Cocktail, Mexican, Avocado Toast, Mimosa, Hotel, Mediterranean, American',
        annual_dol: 1503.85,
        description:
            'Bistro/wine bar in the Library Hotel serving American fare in a spare high-ceilinged space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9815,
        total_orders: 3550.84,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Burger, Lobster, Pizza, Salad, Shrimp Cocktail, Wine, Margarita, Delicious, American',
        annual_dol: 3626.18,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.874,
        store_longitude: -73.9091,
        total_orders: 662.08,
        neighborhood: 'Marble Hill/West Bronx',
        keywords:
            'Steak, Burger, Salad, Dessert, Wine, Chicken Wings, Delicious, French, Warm, French Onion Soup',
        annual_dol: 899.14,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5836,
        store_longitude: -73.9414,
        total_orders: 1287.83,
        neighborhood: 'Sheepshead Bay',
        keywords:
            'Coffee, French, Turkish, Savory, American, Mediterranean, Comfortable, Healthy, Modern, Friendly',
        annual_dol: 1513.37,
        description:
            'Mediterranean dishes are served at this waterfront destination with modern decor & outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7563,
        store_longitude: -73.9716,
        total_orders: 5126.71,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Wine, Rooftop, Elegant, Iconic, Premium, Awesome, Eclectic, Great, Thai',
        annual_dol: 5235.49,
        description:
            'All-season rooftop lounge serving cocktails along with views of Midtown & the Chrysler Building.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7577,
        store_longitude: -73.9749,
        total_orders: 4240.9,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Wine, Beer, Salad, Dessert, Martini, American, Glamorous, Opulent, Prime Rib',
        annual_dol: 4416.45,
        description:
            '"Simon Oren, Dudi Sasson and Chef James Tracey bring midtown Manhattan a glamorous bold American restaurant. A raw bar features every crustacean imaginable, and servers stroll through the dining room brandishing custom gridiron carts preparing items tableside. The menu circles the globe offering Chef Tracey’s seasonal cuisine alongside an extensive selection of Prime Dry-Aged Steaks, and Roast Prime Rib of Beef. Designed by Scott Kester and Dudi Sasson, the two-floor lofty space is comfortable and lavish with an expansive bar and seating for upwards of 200 guests; perfect for large group dining, with private rooms for business and social events."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9652,
        total_orders: 1617.72,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Shrimp Cocktail, Salad, Burger, Beer, Upscale, Elegant, Cozy, Rustic, Warm',
        annual_dol: 1718.82,
        description:
            'Rustic wine bar offering cheeses, charcuterie & upscale American entrees in a brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9694,
        total_orders: 31.16,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Beer, Fun, Sweet, Cool, Great, Casual',
        annual_dol: 127.37,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7385,
        store_longitude: -73.9832,
        total_orders: 99.31,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Vegetarian, Coffee, Beer, French Toast, Upscale, French, Cozy, Delicious, Warm',
        annual_dol: 163.3,
        description: 'Seasonal American home-cooking is served in a homey brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9874,
        total_orders: 100.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Ethiopian, Intimate',
        annual_dol: 412.81,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7524,
        store_longitude: -73.9956,
        total_orders: 552.92,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Hotel, Rooftop, Premium, Great, Retro, Friendly, Seasonal, Casual',
        annual_dol: 601.72,
        description:
            "Warmly decorated rooms offer free Wi-Fi, desks and coffeemakers, plus flat-screen TVs with premium cable channels. Suites add separate living areas with pull-out sofas, microwaves and minifridges; some have scenic city views.A hot breakfast buffet is complimentary. There's also a casual rooftop bar serving drinks and light fare. Other amenities include a 24/7 fitness room and a convenience store, in addition to a business center, meeting rooms and coin-operated laundry facilities.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7266,
        store_longitude: -73.9926,
        total_orders: 798.74,
        neighborhood: 'NoHo',
        keywords:
            'Wine, Italian, Avocado Toast, Mediterranean, Warm, Rustic, Welcoming, Authentic, Artisanal, Fantastic',
        annual_dol: 849.61,
        description:
            'Upmarket, carefully sourced Mediterranean plates served in a rustic-chic space with a wine cellar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7794,
        store_longitude: -73.9808,
        total_orders: 593.97,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Lasagna, Vegetarian, Wine, Italian, Gluten Free, Coffee, Dessert, Tasty, Cozy, Bruschetta',
        annual_dol: 606.57,
        description:
            'Small neighborhood salumi shop & restaurant, with Tuscan small plates, wine & cured meats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6649,
        store_longitude: -73.6991,
        total_orders: 44.03,
        neighborhood: '',
        keywords: 'Steak, Salad, Colombian, Dominican, Spanish, Casual, Awesome',
        annual_dol: 93.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7218,
        store_longitude: -73.9873,
        total_orders: 10883.54,
        neighborhood: 'Lower East Side',
        keywords:
            'French, Rich, Hotel, Eclectic, Classic, Australian, Modern, American, Lively, Timeless',
        annual_dol: 11808.03,
        description:
            'Classic French bistro fare with flair in a cool brasserielike space with chandeliers & banquettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.72,
        store_longitude: -73.9848,
        total_orders: 12355.23,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Whiskey, Salad, Gin, Irish, Warm, Organic, Eclectic, Sweet, Friendly',
        annual_dol: 12868.26,
        description:
            'Neighborhood taproom with barn-wood tables & a marble bar serving beer, whiskey & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9887,
        total_orders: 84.21,
        neighborhood: 'Lower East Side',
        keywords:
            'Steak, Sushi, Wine, Beer, Upscale, Italian, Japanese, Romantic, Innovative, Comfortable',
        annual_dol: 344.21,
        description: '"Japanese-style Western Kitchen and Bar."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7675,
        store_longitude: -73.981,
        total_orders: 3152.39,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Lobster, Pizza, Salad, Italian, Delicious, Funky, Wine, Outstanding, Modern, Lively',
        annual_dol: 3301.35,
        description:
            'High-end Italian seafood & housemade pastas in a chic Central Park South setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7656,
        store_longitude: -73.9876,
        total_orders: 222.36,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Italian, Tasty, Authentic, Great, Sumptuous, Casual',
        annual_dol: 494.43,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7149,
        store_longitude: -73.9914,
        total_orders: 229.67,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Lobster, Wine, Beer, Martini, Dessert, Spanish, Mediterranean, Warm, Sweet, Fun',
        annual_dol: 449.69,
        description:
            'Coastal Spain & Portugal inspire the cooking at this crisp oyster bar & seafood specialist.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7527,
        store_longitude: -74.0073,
        total_orders: 131.75,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Urban, Warm, Adventurous, Chic, Diverse',
        annual_dol: 538.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6311,
        store_longitude: -73.5817,
        total_orders: 100.16,
        neighborhood: '',
        keywords: 'Steak, Sushi, Wine, Friendly, Hurricane, Fresh, Casual, Great',
        annual_dol: 409.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7444,
        store_longitude: -73.9981,
        total_orders: 3751.62,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Legendary, Friendly, Casual, Great, Attentive, Chill',
        annual_dol: 3751.62,
        description:
            'Venerable gay bar & lounge with a small stage that hosts drag shows & other events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.688,
        store_longitude: -73.986,
        total_orders: 1542.09,
        neighborhood: 'Boerum Hill',
        keywords:
            'Shrimp Cocktail, Sustainable, Delicious, Inviting, Seasonal, Perfect, Casual, Innovative, Laid-Back, Great',
        annual_dol: 1574.81,
        description:
            'Sophisticated cocktails, craft beers & raw-bar items including oysters offered in an inviting space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.9992,
        total_orders: 394.33,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Coffee, Pizza, Wine, Dessert, Chinese, Asian, Relaxed, Bright, Casual, Colorful',
        annual_dol: 1016.13,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7438,
        store_longitude: -73.9945,
        total_orders: 228.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Whiskey Sour, Whiskey, French Toast, French, Shrimp Scampi, Tasty, Avocado Toast, Greek, American',
        annual_dol: 589.35,
        description:
            'This classic diner is open 24-hours & offers an array of traditional American food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7131,
        store_longitude: -73.959,
        total_orders: 2161.54,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Lobster, Shrimp Cocktail, Peruvian, Tasty, South American, Wine, Delicious, Uruguayan, American, Traditional',
        annual_dol: 2280.09,
        description:
            'Evocative, low-lit wine bar pairing by-the-glass & bottled options with South American bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6749,
        store_longitude: -73.9566,
        total_orders: 3238.52,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Salad, Pizza, Mexican, Beer, Coffee, Wine, Upscale, Cuban, Trendy',
        annual_dol: 3312.18,
        description:
            'Spacious New American restaurant serving trendy eats & less-expected items in vintage-inspired digs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7195,
        store_longitude: -73.9557,
        total_orders: 1259.33,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Beer, Hamburger, Tequila, Ale, Whiskey, Cheesecake, Salad, Vodka, Nachos',
        annual_dol: 1286.05,
        description:
            'Williamsburg bar known for its rotating inventory of hard-to-find draft & bottled brews.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6204,
        store_longitude: -74.1238,
        total_orders: 73.27,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords:
            'Burger, Tasty, Italian, American, Local, Yummy, Eclectic, Fancy, Casual, Colorful',
        annual_dol: 401.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6814,
        store_longitude: -73.9559,
        total_orders: 429.51,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Wine, Pizza, Mediterranean, American, Cozy, Intimate, Turkish, Classic, Awesome, Natural',
        annual_dol: 811.18,
        description:
            'A changing menu of Mediterranean cuisine & drinks served in a cozy setting with an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: null,
        store_longitude: null,
        total_orders: 69.78,
        neighborhood: '',
        keywords: 'Pizza, Beer, Wine, Russian, Nachos, American, Casual, Local, Excellent',
        annual_dol: 285.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9228,
        total_orders: 10479.17,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Vegan, Tasty, Beer, Hawaiian, Bbq, Awesome, Colorful, Inspiring, Friendly, Sweet',
        annual_dol: 12752.14,
        description:
            'Breezy corner spot offering colorful cocktails, beers & drink-friendly fare with a happy hour.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7184,
        store_longitude: -73.9591,
        total_orders: 72.42,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Pizza, Salad, Lobster Bisque, Italian, Margarita, Delicious, Trendy, Greek, Homemade',
        annual_dol: 88.71,
        description:
            'Restaurant & bar with outdoor picnic table seating open for breakfast, lunch, dinner & brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7632,
        store_longitude: -73.9778,
        total_orders: 6595.44,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Whiskey, Beer, Bourbon, Cozy, Fun, Lively, Friendly, Rum, Perfect, Authentic',
        annual_dol: 6776.9,
        description:
            'Dimly lit triplex sports bar/lounge drawing a crowd with drink specials & free popcorn.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.763,
        store_longitude: -73.9804,
        total_orders: 2129.17,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Vegetarian, Beer, Irish, Friendly, Recommended, Casual, Great',
        annual_dol: 2702.82,
        description:
            'Established tavern offering cocktails, draft brews, pub grub & sports TV in a laid-back atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8035,
        store_longitude: -73.9536,
        total_orders: 15.78,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Jamaican, Beer, Sushi, Wine, American',
        annual_dol: 64.49,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6895,
        store_longitude: -73.9732,
        total_orders: 13491.3,
        neighborhood: 'Fort Greene',
        keywords: 'Burger, Salad, Steak, Tequila, Wine, Italian, Whiskey, Japanese, Gin, Organic',
        annual_dol: 14461.42,
        description:
            'Hipster-friendly American eatery in Fort Greene, spun off from the Williamsburg original.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6823,
        store_longitude: -73.98,
        total_orders: 995.14,
        neighborhood: 'Boerum Hill',
        keywords:
            'Beer, Ale, Upscale, Belgian, Friendly, Amazing, Great, Excellent, Cool, Bloody Mary',
        annual_dol: 1016.26,
        description:
            'A basic neighborhood bar with a twist: this no-frills spot has a big craft beer offering & a patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 4063.58,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Burger, Pizza, Sushi, Chicken Parmigiana, Salad, Vodka, Spicy, Delicious, Ale, Turkish',
        annual_dol: 4164.47,
        description:
            '"Airy, industrial-chic hangout offering sushi, pizza & varied bar fare, plus craft brews & cocktails."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7263,
        store_longitude: -73.9774,
        total_orders: 89.74,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Mexican, Margarita, Rum, Rustic, Vintage, Wholesome, Classy, Japanese, Casual',
        annual_dol: 366.83,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7249,
        store_longitude: -73.9785,
        total_orders: 777.41,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Pizza, Salad, Whiskey, Gin, Chinese, Stylish, Casual, American, Friendly, Perfect',
        annual_dol: 1901.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.9853,
        total_orders: 647.4,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Korean, Mexican, Bbq, Pizza, Sushi, Coffee, Beer, Kbbq, Ramen, Spicy',
        annual_dol: 1673.28,
        description:
            'Generous plates of homestyle Korean barbecue are served at this bustling, informal eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7446,
        store_longitude: -73.9868,
        total_orders: 2801.65,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Steak, Burger, Salad, Vodka, Caesar Salad, American, Bloody Mary, Cozy, Prosecco, Modern',
        annual_dol: 9835.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8051,
        store_longitude: -73.9547,
        total_orders: 1695.65,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Burger, Tasty, Salad, Italian, Wine, Delicious, Beer, Mimosa, Warm, Creamy',
        annual_dol: 1695.65,
        description:
            'The bottomless-mimosa brunch is a draw at this sleek, warm Italian eatery on a Lower Harlem corner.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6812,
        store_longitude: -73.9365,
        total_orders: 2483.96,
        neighborhood: 'Stuyvesant Heights/Bedford-Stuyvesant',
        keywords:
            'Burger, Salad, Dessert, Coffee, Gourmet, Wine, Beer, Mediterranean, Healthy, Friendly',
        annual_dol: 3187.98,
        description:
            'Indoor-outdoor bar in Prospect Park offering burgers, hot dogs & fries along with beer & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7552,
        store_longitude: -73.8865,
        total_orders: 179.65,
        neighborhood: 'Jackson Heights',
        keywords:
            'Mexican, Margarita, Wine, Fajitas, American, Warm, Homemade, Latin, Natural, Authentic',
        annual_dol: 216.24,
        description:
            'Hearty Mexican fare, including fajitas, tableside guacamole & enchiladas, served in a warm setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7246,
        store_longitude: -73.9946,
        total_orders: 493.72,
        neighborhood: 'Nolita',
        keywords:
            'Salad, Dessert, Wine, Mexican, Italian, Delicious, Stylish, French, Polished, Friendly',
        annual_dol: 1509.47,
        description:
            'Stylish restaurant serving contemporary cuisine in an intimate, informal setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7222,
        store_longitude: -73.9965,
        total_orders: 773.43,
        neighborhood: 'Nolita',
        keywords:
            'Wine, Coffee, Beer, Margarita, Delicious, Hotel, Sweet, Great, Terrific, Classic',
        annual_dol: 3504.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7242,
        store_longitude: -73.9946,
        total_orders: 388.99,
        neighborhood: 'Nolita',
        keywords: 'Pizza, Italian, Vegan, Wine, Beer, Dessert, Warm, Casual, Authentic, Local',
        annual_dol: 585.35,
        description:
            'Cozy, vintage-inspired cafe serving Italian fare ranging from snacks & entrees to gelato & espresso.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8378,
        store_longitude: -73.9225,
        total_orders: 7.99,
        neighborhood: 'Highbridge/West Bronx',
        keywords: 'Beer, Wine, American, British, Great, Amazing, Casual',
        annual_dol: 32.67,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9786,
        total_orders: 74.18,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Trendy, Premium, American, Global, Southern, Fantastic, Personalized, Indian',
        annual_dol: 303.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6293,
        store_longitude: -73.581,
        total_orders: 3131.68,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Garlic Bread, Lobster Bisque, Wine, Beer, Bourbon, Crisp, Lively, American',
        annual_dol: 3836.17,
        description:
            'Lively marina haunt with a raw bar & basic American menu, plus a sandy patio, music & a dance floor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -74.0048,
        total_orders: 198.34,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vegan, Vegetarian, Salad, Wine, Coffee, Beer, Organic, Rustic, Bourbon, Swiss',
        annual_dol: 285.91,
        description:
            'Locally sourced, upscale American cuisine served in a barn-inspired setting with a chef’s table.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7394,
        store_longitude: -74.002,
        total_orders: 2489.69,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Whiskey, Beer, Wine, Sushi, Irish, Salad, Ale, Chinese, Upscale, Cozy',
        annual_dol: 2542.52,
        description:
            'Cozy, country-style Irish pub offering cocktails, whiskey & locally sourced beer, plus comfort food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.787,
        store_longitude: -73.816,
        total_orders: 372.79,
        neighborhood: 'Whitestone/Flushing',
        keywords:
            'Salad, Pizza, Italian, Mediterranean, Homemade, Clean, Casual, Friendly, Traditional',
        annual_dol: 397.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5859,
        store_longitude: -73.694,
        total_orders: 425.31,
        neighborhood: '',
        keywords:
            'Burger, Vodka, Irish, Spicy, Rum, Delicious, Relaxed, Casual, Friendly, Intimate',
        annual_dol: 487.61,
        description:
            'Irish & American pub fare in a relaxed setting with sidewalk tables, sports on TV & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.725,
        store_longitude: -73.9776,
        total_orders: 3269.08,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Champagne, Wine, Tasty, Beer, Margarita, Coffee, Savory, Warm, Rum, Fun',
        annual_dol: 3723.25,
        description:
            'Cutting-edge cocktails are served in a no-frills setting at this laid-back barroom.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7104,
        store_longitude: -73.9632,
        total_orders: 1179.02,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Steak, Vegan, Scotch, Wine, Vodka, Salad, Four Horsemen, Stylish, Impeccable, Spanish',
        annual_dol: 1408.4,
        description:
            'Mediterranean-influenced New American dishes & craft cocktails in casually hip digs with a bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7101,
        store_longitude: -73.9625,
        total_orders: 2045.66,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Hotel, Spectacular, Premium, Iconic, Perfect, Diverse',
        annual_dol: 2229.46,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7099,
        store_longitude: -73.9627,
        total_orders: 374.67,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Vodka, Elegant, Coffee, Beer, Gin, Martini, Spacious, Premium, Sweet',
        annual_dol: 398.72,
        description: 'Cocktails & cigars are featured at this Williamsburg lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7064,
        store_longitude: -74.0026,
        total_orders: 1257.04,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Vodka, Wine, Beer, Karaoke, Gin, Delicious, Creamy, Playful, Seasonal, Japanese',
        annual_dol: 5138.38,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6805,
        store_longitude: -73.9624,
        total_orders: 481.67,
        neighborhood: 'Crown Heights/Clinton Hill',
        keywords: 'Caribbean, Premium, Local, Casual, Great',
        annual_dol: 1968.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7114,
        store_longitude: -73.9477,
        total_orders: 30.47,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Vegetarian, Salad, Peruvian, Delicious, Casual, Fresh, Excellent, Wonderful, Great',
        annual_dol: 124.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7255,
        store_longitude: -73.978,
        total_orders: 4.54,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Hamburger, Tasty, Cozy, Brazilian, Latin, Friendly, American, Lively, Fusion',
        annual_dol: 18.57,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.722,
        store_longitude: -73.958,
        total_orders: 16191.42,
        neighborhood: 'Williamsburg',
        keywords:
            'French, Shrimp Cocktail, Wine, Hotel, Upscale, Four Horsemen, Classy, Romantic, Asian, American',
        annual_dol: 16807.79,
        description:
            'Sprawling spot at the Wythe Hotel serving French fare, wine & cocktails in an easygoing setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7163,
        store_longitude: -73.9618,
        total_orders: 3759.04,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Whiskey, Coffee, Relaxed, Friendly, Awesome, Cool, Great, Fresh, Casual',
        annual_dol: 3759.04,
        description:
            'Relaxed watering hole with beer, booze, hot dogs & snacks to fuel pool, darts & board game playing.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7111,
        store_longitude: -73.9651,
        total_orders: 637.44,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Friendly, Casual, Intimate, Fun, Authentic, Latin, Local, Fresh, Lively, American',
        annual_dol: 665.08,
        description:
            'DJs, live global music & tropical cocktails inspire dancing & socializing at this lively lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7456,
        store_longitude: -73.9796,
        total_orders: 216.17,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Sushi, Lobster, Salad, Gluten Free, Japanese, Beer, French, Sweet, Friendly, Asian',
        annual_dol: 819.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7624,
        store_longitude: -73.9848,
        total_orders: 191.27,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Salad, Italian, Gluten Free, Delicious, Mediterranean, Cozy, Polished, Caesar Salad, Casual, Classic',
        annual_dol: 584.77,
        description:
            'Meaty fare from Brescia shares menu space with seafood from Amalfi in an airy, high-ceiling room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7219,
        store_longitude: -73.9922,
        total_orders: 5443.32,
        neighborhood: 'Bowery',
        keywords: 'Wine, Bourbon, Gin, Dessert, Rustic, American, Cozy, French, Crisp, Modern',
        annual_dol: 5724.12,
        description:
            'American fare, craft cocktails & Colonial tavern decor draws a hip crowd to this hidden-away spot.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7179,
        store_longitude: -73.9927,
        total_orders: 309.49,
        neighborhood: 'Bowery',
        keywords: 'Thai, Steak, Tequila, Wine, Beer, Martini, Spicy, Delicious, Fried Rice, Asian',
        annual_dol: 370.29,
        description:
            'Relaxed setting for Thai skewers, small plates & mains, plus beer, wine & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7215,
        store_longitude: -73.9924,
        total_orders: 3691.99,
        neighborhood: 'Bowery',
        keywords: 'Burger, Beer, Whiskey, Wine, Thai, Trendy, Israeli, Asian, Friendly, Sweet',
        annual_dol: 4410.28,
        description:
            'Watering hole with a dive bar vibe, disco ball & a pool table, serving beer & craft cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.861,
        store_longitude: -73.9259,
        total_orders: 46.37,
        neighborhood: 'Fort George',
        keywords: 'Salad, Burger, Bbq, Fried Rice, French, Latin, Asian, Dope, Sweet, American',
        annual_dol: 189.53,
        description: '"The best food, drinks, and ambience in the area."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.743,
        store_longitude: -73.9966,
        total_orders: 390.04,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Beer, American, Salad, Hotel, Classic, Intimate, Modern, Excellent, Fine',
        annual_dol: 588.18,
        description:
            'Intimate hangout with a lounge vibe & sidewalk tables serving American fare & drinks until late.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.787,
        store_longitude: -73.8074,
        total_orders: 685.52,
        neighborhood: 'Whitestone/Flushing/Beechhurst',
        keywords: 'Italian, Wine, Pizza, Warm, Asian, Wonderful, Great, Casual, Local',
        annual_dol: 700.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7374,
        store_longitude: -73.9994,
        total_orders: 425.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Italian, Thai, Canadian, Homemade, Relaxed, Friendly, Mediterranean, Fun, Casual',
        annual_dol: 443.82,
        description:
            'Mussels & frites are the main event at this hopping seafood eatery also featuring homemade donuts.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.777,
        store_longitude: -73.8177,
        total_orders: 29.53,
        neighborhood: 'Whitestone/Flushing',
        keywords: 'Pizza, Beer, Wine, Legendary, Awesome, Classic, Local, Casual',
        annual_dol: 80.55,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7148,
        store_longitude: -73.9446,
        total_orders: 621.89,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Hamburger, Beer, American, Casual, Local, Friendly, Chill, Outstanding, Great',
        annual_dol: 1015.16,
        description:
            'This chill neighborhood pub is known for its selection of burgers & craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7115,
        store_longitude: -73.9468,
        total_orders: 1165.91,
        neighborhood: 'Williamsburg',
        keywords:
            'Wine, Coffee, Upscale, Irish, American, Southern, Friendly, Perfect, Casual, Awesome',
        annual_dol: 1165.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6761,
        store_longitude: -73.9719,
        total_orders: 72.28,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Sushi, Salad, Italian, Wine, Delicious, Refined, Homemade, Classy, Amazing',
        annual_dol: 295.44,
        description:
            'Refined setting for rustic, seasonal Italian dishes plus biodynamic & reserve wines.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5886,
        store_longitude: -73.6837,
        total_orders: 103.11,
        neighborhood: '',
        keywords: 'Pizza, Foreign, American',
        annual_dol: 153.23,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9858,
        total_orders: 336.15,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Beer, Wine, Coffee, Hamburger, Ale, Irish, Delicious, Eclectic, Fun, Friendly',
        annual_dol: 392.13,
        description:
            'Sports fans (especially hockey fanatics) flock to this classic Irish pub to watch the game.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.742,
        store_longitude: -73.9781,
        total_orders: 1054.51,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Whiskey, Beer, Irish, Warm, Rum, Hotel, Friendly, Classy, Homey, Great',
        annual_dol: 1290.59,
        description:
            'Homey pub with a working fireplace offering Irish-American eats, draft beer & whiskey.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6843,
        store_longitude: -73.9385,
        total_orders: 688.64,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Pizza, French Toast, Warm, French, Thai, Bright, Omelette, Global, Creative, Casual',
        annual_dol: 864.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.9225,
        total_orders: 2770.27,
        neighborhood: 'Astoria/LIC',
        keywords: 'Salad, Chicken Alfredo, Buffalo Chicken Salad, Beer, American, Casual, Classic',
        annual_dol: 3009.73,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7995,
        store_longitude: -73.9624,
        total_orders: 6565.53,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Mexican, Tacos, Beer, Margarita, Trendy, Casual, Classy, Intimate, Modern, Local',
        annual_dol: 8531.69,
        description:
            'Chill choice for Mexican cuisine with a vibrantly decorated space & a petite patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7374,
        store_longitude: -73.8827,
        total_orders: 226.58,
        neighborhood: 'Elmhurst/Flushing',
        keywords:
            'Lasagna, Coffee, Salad, Mexican, Flavorful, American, Healthy, Ample, Greek, Authentic',
        annual_dol: 236.41,
        description:
            'Classic 24/7 diner with an extensive menu spanning typical greasy-spoon specialties to Greek dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7531,
        store_longitude: -73.973,
        total_orders: 65.29,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Incredible, Excellent, Tranquil, Natural, Unique, Signature',
        annual_dol: 266.9,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.825,
        store_longitude: -73.9518,
        total_orders: 1535.66,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Burger, Vegan, Pizza, Coffee, Margarita, Delicious, Spanish, Organic, Elegant, Spacious',
        annual_dol: 2820.2,
        description:
            '"The Dermatology Specialists (TDS) is NYC\'s largest dermatology group with 25+ locations across New York City, Brooklyn, Queens, Bronx and Long Island. Our offices are open 7 days a week until 7pm and we accept most health insurance plans. We combine the sophistication of cutting-edge medicine with the careful attention and intimacy of a down-to-earth, neighborhood doctor. Patients experience personalized care that reflects the TDS philosophy of bringing authenticity and comfort into traditionally clinical medical spaces."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.9728,
        total_orders: 2875.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Whiskey, Sushi, Beer, Coffee, Hotel, Stylish, Premium, Natural, Contemporary',
        annual_dol: 10095.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7954,
        store_longitude: -73.7419,
        total_orders: 1238.89,
        neighborhood: '',
        keywords: 'Premium, Great, Healthy, Indian',
        annual_dol: 1348.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6705,
        store_longitude: -73.894,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Whiskey, Wine, Vodka, Beer, Pizza, Gin, Hotel, Tex-Mex, Upscale, American',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.516,
        store_longitude: -74.2335,
        total_orders: 141.67,
        neighborhood: 'Tottenville',
        keywords: 'Burger, Pizza, Salad, Mexican, Wine, French, American, Fresh, Casual, Great',
        annual_dol: 144.68,
        description: '"Neighborhood Bar & Grill"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6857,
        store_longitude: -73.9909,
        total_orders: 3009.3,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Zombie, Whiskey, Beer, Irish, French, American, Casual, Bright, Friendly',
        annual_dol: 3073.16,
        description:
            'Dark, no-nonsense tavern pouring basic drinks & playing tunes from the jukebox.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.758,
        store_longitude: -73.984,
        total_orders: 1146.09,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Wine, French, Fried Rice, Prime Rib, Filet Mignon, Perfect, Friendly',
        annual_dol: 1185.75,
        description:
            'Prime steaks headline a French-influenced menu at this kosher restaurant & butcher shop.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7824,
        store_longitude: -73.9536,
        total_orders: 942.99,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Steak, Hamburger, Salad, Pizza, Italian, Dessert, Beer, Upscale, French Onion Soup',
        annual_dol: 1155.13,
        description:
            'Understated eatery turning out Italian & American dishes, from red-sauce standards to grilled steak.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.9897,
        total_orders: 961.34,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Tomato Soup, Spicy, Nachos, Sweet, Spacious, Bold, Local, Casual, Great',
        annual_dol: 987.32,
        description:
            'Spacious stock market-themed bar offering dozens of draft and bottled beers plus basic pub grub.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9896,
        total_orders: 120.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Steak, Salad, Mexican, Beer, Bourbon, Mimosa, Bbq, Delicious, Swiss',
        annual_dol: 492.36,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7875,
        store_longitude: -73.7286,
        total_orders: 225.95,
        neighborhood: '',
        keywords:
            'Sushi, Japanese, Salad, Gluten Free, Shrimp Cocktail, Asian, Chinese, Organic, Spicy, Fusion',
        annual_dol: 277.91,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9886,
        total_orders: 1823.69,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Cornbread, Shrimp Cocktail, Salad, Bbq, Margarita, Champagne, Pinacolada, French',
        annual_dol: 1902.76,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6941,
        store_longitude: -74.0006,
        total_orders: 33.34,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Burger, Coffee, Salad, Beer, Roast Turkey, Avocado Toast, American, Casual, Fresh, Cool',
        annual_dol: 136.29,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7566,
        store_longitude: -73.9845,
        total_orders: 497.66,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Italian, American, Classic, Contemporary, Great, Modern, Fine, Recommended',
        annual_dol: 2034.25,
        description:
            "Luxury rooms with butler services, Italian linens and modern furnishings also feature TVs, Blu-ray players and free Wi-Fi. Suites add separate living areas, private terraces and/or whirlpool tubs.Dining options include a storied, ultra-chic restaurant/bar. There's also a renowned spa (surcharge) with an indoor saltwater pool and a 24-hour fitness center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7792,
        store_longitude: -73.9834,
        total_orders: 2106.39,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Steak, Vegan, Vegetarian, Cajun, Beer, Chilean, Mimosa, Trendy, French, American',
        annual_dol: 2197.79,
        description:
            'Southern-accented American cooking & live music events in a brownstone. Owned by Ashford & Simpson.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7726,
        store_longitude: -73.9808,
        total_orders: 2196.42,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Vegetarian, Tasty, Gluten Free, Gourmet, Dessert, Chinese, Upscale, Trendy, Elegant',
        annual_dol: 2252.43,
        description:
            'Classic, upscale Chinese eatery since 1971 serving traditional dishes in an elegant setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.825,
        store_longitude: -73.5886,
        total_orders: 1050.61,
        neighborhood: '',
        keywords:
            'Champagne, Wine, Stylish, Elegant, Romantic, Delicious, Vintage, Organic, Traditional, Classic',
        annual_dol: 1200.44,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6636,
        store_longitude: -73.9537,
        total_orders: 634.64,
        neighborhood: 'Little Caribbean',
        keywords: 'Burger, Pizza, Beer, American, Wine, Israeli, Modern, Healthy, Local, Casual',
        annual_dol: 924.63,
        description:
            'Laid-back eatery with a patio serving diverse small plates, mains & desserts, plus cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7734,
        store_longitude: -73.9117,
        total_orders: 353.05,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Wine, Beer, Irish, Awesome, Mediterranean, Great, Perfect, Greek, Indian, Casual',
        annual_dol: 1081.63,
        description:
            'Beer, wine & cocktails served in an old-school pub with sports on TV, live music & trivia nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7753,
        store_longitude: -73.9145,
        total_orders: 591.14,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Salad, Wine, Delicious, Greek, Spacious, Cozy, Mediterranean, Premium, Fresh',
        annual_dol: 629.09,
        description:
            'Long-running Greek taverna known for fresh grilled fish served in spacious quarters with a garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5225,
        store_longitude: -74.2394,
        total_orders: 783.89,
        neighborhood: 'Charleston',
        keywords: 'Italian, Pizza, Coffee, Dessert, Margarita, Casual, Traditional, Classic',
        annual_dol: 800.52,
        description:
            'Classic bakery with an attached traditional Italian restaurant for homestyle pastas & meat entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7454,
        store_longitude: -73.9534,
        total_orders: 1825.52,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Burger, Hamburger, Mexican, Beer, Salad, Margarita, Bourbon, American, Trendy, Upscale',
        annual_dol: 1833.27,
        description:
            'Hip bar & eatery offering small plates, sandwiches & brunch, plus craft beers & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.679,
        store_longitude: -73.9908,
        total_orders: 668,
        neighborhood: 'Gowanus',
        keywords:
            'Tequila, Wine, Beer, Scotch, Bourbon, Salad, Innovative, Latin, Awesome, Friendly',
        annual_dol: 1021.16,
        description:
            'Distressed materials add urban flair to this hip bar with a compact beverage list & creative plates.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6285,
        store_longitude: -74.0063,
        total_orders: 29.01,
        neighborhood: 'Dyker Heights',
        keywords: 'Pizza, Salad, Chicken Parmigiana, Italian, Caprese Salad, Casual',
        annual_dol: 118.59,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7377,
        store_longitude: -73.9878,
        total_orders: 833.93,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Burger, Beer, Wine, Bourbon, Tasty, Apple Pie, Italian, American, French, Contemporary',
        annual_dol: 2148.91,
        description:
            "Danny Meyer's first restaurant serves seasonal New American fare in a casually refined setting.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7993,
        store_longitude: -73.9687,
        total_orders: 10.82,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Vegetarian, Kebab, Thai, Naan Bread, Indian, Contemporary, Traditional, Casual, Dal Makhani, Saag Paneer',
        annual_dol: 44.22,
        description:
            'Traditional Indian cooking offered in an airy dining room or via popular take-out meal deals.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8792,
        store_longitude: -73.5846,
        total_orders: 152.76,
        neighborhood: '',
        keywords:
            'Crab Cakes, Salad, Wine, Beer, Italian, Mediterranean, Bbq, Cozy, French, American',
        annual_dol: 299.5,
        description:
            'Longtime spot for refined French & New American cuisine in a romantic, cozy room with a fireplace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7568,
        store_longitude: -73.9696,
        total_orders: 1070.2,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Whiskey, Cuban, Japanese, American, Lively, Amazing, Ramen, Ample, Great',
        annual_dol: 4149.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6788,
        store_longitude: -73.8877,
        total_orders: 33.92,
        neighborhood: 'Cypress Hills',
        keywords: 'Steak, Mexican, Nachos, Dominican, Spanish, Caribbean, Local, Casual, Vibrant',
        annual_dol: 138.66,
        description:
            'Vibrant kitchen for Dominican specialties, plus a covered patio, a bar & live music.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6866,
        store_longitude: -73.9547,
        total_orders: 2306.04,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Wine, Beer, Margarita, Bourbon, Warm, Cozy, Carrot Cake, Intimate, Relaxed, Classy',
        annual_dol: 2306.04,
        description:
            'Intimate, hideaway bar offering cocktails & brews, plus a fireplace in a dimly lit, relaxed setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6838,
        store_longitude: -73.9438,
        total_orders: 655.72,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Tequila, Wine, Beer, Gin, Rustic, Fun, Natural, Great, Casual, Rare',
        annual_dol: 669.63,
        description:
            '"Queer space for all. Cocktails, natural wine, beer, non-alcoholic drinks.Good food."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7606,
        store_longitude: -73.9953,
        total_orders: 1384.02,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Delicious, Glamorous, Spectacular, Creative',
        annual_dol: 1506.16,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7591,
        store_longitude: -73.9886,
        total_orders: 201.42,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Margarita, Beer, Fajitas, Delicious, Seasonal, Casual, Wonderful, Great',
        annual_dol: 707.1,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7093,
        store_longitude: -74.0138,
        total_orders: 791.32,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Wine, Vegan, Beer, Delicious, Hotel, Austrian, Innovative, Italian, Friendly, Mediterranean',
        annual_dol: 4486.05,
        description:
            '"We are a greek medeteranian inspired restaurant.,with two levels ,can accommodate over 150 people.,at one time. two private rooms, first floor bar restaurant ,second floor event and restaurant space"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7097,
        store_longitude: -74.0146,
        total_orders: 2803.04,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Hamburger, Pizza, Beer, Nachos, Spicy, Wine, American, Delicious, Hotel',
        annual_dol: 2869.07,
        description:
            'Burgers & a long beer list offered in a classic diner-style room with gingham tablecloths.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7499,
        store_longitude: -73.9855,
        total_orders: 4138.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Pizza, Steak, Chicken Pot Pie, Chicken Caesar Salad, Dessert, Beer, Wine, Bbq',
        annual_dol: 4295.69,
        description:
            'Dozens of TVs screening sports set the tone at this Irish pub/eatery near the Empire State Building.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7469,
        store_longitude: -73.9971,
        total_orders: 452.04,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Salad, Beer, American, French, Vintage, Caesar Salad, Eclectic, Contemporary',
        annual_dol: 461.64,
        description:
            'Neighborhood tavern with an industrial vibe & sports on TV, with craft beer & elevated bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7756,
        store_longitude: -73.9102,
        total_orders: 1286.39,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Wine, Beer, Delicious, Irish, Refined, Premium, Classic, Fun, Croissant Sandwich, Great',
        annual_dol: 1313.68,
        description: 'This formerly hidden speakeasy-style bar serves refined cocktails & snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6596,
        store_longitude: -73.9507,
        total_orders: 763.68,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Pizza, Italian, Dessert, Magical, Asian, Warm, Legendary, Contemporary, Traditional, Authentic',
        annual_dol: 961.52,
        description:
            'Eatery offering pinsa (Roman flatbreads) plus Italian dishes & Negronis in a brick-walled space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7154,
        store_longitude: -74.0173,
        total_orders: 6858.29,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Burger, Steak, Tomato Soup, Salad, Chicken Pot Pie, Shrimp Cocktail, Italian, Beer, Wine, Swiss',
        annual_dol: 7435.39,
        description:
            'Burgers & other American comfort food in down-to-earth digs with a lively atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7747,
        store_longitude: -73.9089,
        total_orders: 166.23,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Peruvian, Margarita, Spicy, Warm, Delicious, Colombian, Latin, Authentic, Contemporary, American',
        annual_dol: 325.91,
        description:
            '"Rosatoro Restaurant & Pisco Bar is contemporary restaurant brining authentic flavors of the Peruvian cuisine to the world. From spicy ceviches to Pisco Sours, Rosatoro aims to provide a warm experience to all foodies and travelers."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8104,
        store_longitude: -73.9512,
        total_orders: 1162.08,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Burger, Beer, Tasty, Wine, Great',
        annual_dol: 4750.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7636,
        store_longitude: -73.9868,
        total_orders: 161.16,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Salad, Southern, Thai, Casual, Local',
        annual_dol: 658.78,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7631,
        store_longitude: -73.9865,
        total_orders: 333.05,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Japanese, Wine, Sashimi, Tasty, Omakase, Casual, Tempura',
        annual_dol: 1361.42,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7282,
        store_longitude: -73.9996,
        total_orders: 21.58,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Kebab, Wine, Vegetarian, Ale, Shrimp Cocktail, Homemade, Contemporary, Tandoori Chicken, Indian, Modern',
        annual_dol: 88.21,
        description:
            'Casual, contemporary Indian spot offering a wide-ranging menu & craft cocktails, plus delivery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.865,
        store_longitude: -73.9279,
        total_orders: 951.84,
        neighborhood: 'Fort George',
        keywords: 'Hamburger, Beer, American, Bbq, Upscale, Relaxed, Casual, Local, Great',
        annual_dol: 972.03,
        description:
            'Relaxed pub with industrial decor serving craft brews, upscale American bar fare & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7351,
        store_longitude: -73.9972,
        total_orders: 586.81,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Salad, Chicken Parmigiana, Italian, Martini, French, Caesar Salad, Casual, Local, Fresh',
        annual_dol: 652.88,
        description:
            'Waiters in red coats & relish trays set the tone at this enduring Italian dining institution.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7364,
        store_longitude: -73.9973,
        total_orders: 1492.4,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Hamburger, Salad, Pizza, Coffee, Wine, Moroccan, Dessert, French, Flavorful, Mimosa',
        annual_dol: 1528.14,
        description:
            'A lively spot serving classic bistro fare with Moroccan influences (couscous, tagines & such).',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -74.003,
        total_orders: 306.82,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Chinese, Cuban, Seafood Paella, Impeccable, Puerto Rican, Vibrant, Asian, Fusion, Yummy',
        annual_dol: 327.22,
        description:
            'Asian-Cuban fare & unique craft cocktails served in roomy, relaxed surrounds with big potted palms.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6881,
        store_longitude: -73.9569,
        total_orders: 10455.79,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Beer, Casual, Friendly, Hip, Rare, Classic, Unpretentious',
        annual_dol: 11200.24,
        description:
            'Buzzy LGBTQ+ watering hole offering cocktails, plus live music, art shows & other events.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6835,
        store_longitude: -73.9661,
        total_orders: 1829.25,
        neighborhood: 'Clinton Hill',
        keywords: 'Beer, Wine, Upscale, Irish, American, Vintage, Classy, Casual, Fun, Lit',
        annual_dol: 1873.22,
        description:
            'Vintage cocktails, craft beer & wine are offered in a dimly lit room with a dark-wood bar & couches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9799,
        total_orders: 2058.84,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lobster Roll, Lobster, Salad, Wine, Beer, Coffee, Spicy, Delicious, Warm, Quaint',
        annual_dol: 2195.71,
        description:
            'Minimalist outpost for fresh & cured seafood small plates & sandwiches, plus craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7274,
        store_longitude: -73.9852,
        total_orders: 842.12,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegetarian, Coffee, Beer, Avocado Toast, Delicious, Healthy, American, Casual, Local, Great',
        annual_dol: 897.46,
        description:
            'Place for sandwiches, salads & breakfast with a healthy spin, plus juices & cold-brew coffee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -73.9839,
        total_orders: 5660.88,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Hotdog, Burger, Steak, Beer, Wine, American, Funky, Sleek, Classic, Casual',
        annual_dol: 11268.82,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7286,
        store_longitude: -73.9844,
        total_orders: 131.23,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Mexican, Tacos, Tequila, Salad, Margarita, Pizza, Burritos, Tex-Mex, Pinacolada, Latin',
        annual_dol: 347.49,
        description:
            'Unfussy Mexican nook whipping up hard & soft tacos, plus tamales, burritos & other traditional eats.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7472,
        store_longitude: -73.9772,
        total_orders: 867,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Ramen, Beer, Wine, Spicy, Hotel, Japanese, Great, Authentic, Relaxed, Casual',
        annual_dol: 1118.03,
        description:
            'Relaxed eatery specializing in tonkotsu ramen, complemented by skewers, sake & shōchū.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.9219,
        total_orders: 24.84,
        neighborhood: 'Astoria/LIC',
        keywords: 'Vegetarian, Thai, Chinese, Clean, Asian, Friendly, Casual, Authentic, Pad Thai',
        annual_dol: 101.56,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 42.7691,
        store_longitude: -78.7688,
        total_orders: 20.63,
        neighborhood: '',
        keywords: 'Upscale, Unforgettable, High-End, American, Unique',
        annual_dol: 84.34,
        description:
            '"At T Squared TEES & TAPS, our goal is to help you create unforgettable experiences with friends and family. This inaugural location in Orchard Park, New York features 3 main attractions... Beautiful Lounge Area and Outdoor Patio Indoor Golf Simulators Indoor and Outdoor Putting Greens"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.8302,
        total_orders: 1785.09,
        neighborhood: 'Flushing/Downtown Flushing',
        keywords:
            'Vegetarian, Vegan, Chinese, Spicy, Japanese, Ramen, Stylish, Asian, Shabu-Shabu, Friendly',
        annual_dol: 1822.97,
        description:
            'Meats, seafood, noodles & veggies are cooked in tableside hot pots at this stylish Japanese outfit.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6935,
        store_longitude: -73.8294,
        total_orders: 2.61,
        neighborhood: 'Richmond Hill/South Richmond Hill',
        keywords:
            'Vegetarian, Butter Chicken, Indian, Tandoori Chicken, Dal Makhani, Authentic, Casual, Local, Excellent, Great',
        annual_dol: 10.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.757,
        store_longitude: -73.9681,
        total_orders: 309.44,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Vegetarian, Chinese, Peking Duck, Flavorful, Spicy, Ramen, Grand Marnier, Filet Mignon, Fresh',
        annual_dol: 318.26,
        description:
            'Its namesake, carved tableside with fanfare, is the specialty of this Chinese restaurant.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6315,
        store_longitude: -74.0216,
        total_orders: 119.09,
        neighborhood: 'Bay Ridge',
        keywords: 'Comfortable, Casual, Friendly, Fun, Local, Great',
        annual_dol: 145.76,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7589,
        store_longitude: -73.9731,
        total_orders: 1132.33,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Glamorous, Elegant, Comfortable, Premium, Sidecar, Mead',
        annual_dol: 1213.7,
        description:
            'The Racquet and Tennis Club, familiarly known as the R&T, is a private social and athletic club at 370 Park Avenue, between East 52nd and 53rd Streets in Midtown Manhattan, New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7605,
        store_longitude: -73.99,
        total_orders: 541.53,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Ramen Noodles, Vegetarian, Vegan, Ramen, Whiskey, Beer, Japanese, Savory, Spicy, Juicy',
        annual_dol: 948.32,
        description:
            'Modern ramen spot with Japanese-style noodle bowls & sake in a big interior with a warm vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7845,
        store_longitude: -73.956,
        total_orders: 97,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Mexican, Pizza, Dessert, American, Hotel, Delicious, Memorable, Classic, Fresh, Great',
        annual_dol: 371.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7597,
        store_longitude: -73.9918,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Steak, Burger, Cheesesteak, Pizza, Beer, Wine, Spicy, Organic, American, Fresh',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7562,
        store_longitude: -73.9849,
        total_orders: 118.39,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Sleek, Fun, Awesome, Great, Excellent, American, Iconic, Casual, Clam Chowder',
        annual_dol: 483.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7248,
        store_longitude: -73.9983,
        total_orders: 3389.62,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Lobster, Lobster Roll, Burger, Sushi, Shrimp Cocktail, Salad, Vegetarian, Vegan, Gluten Free, Beer',
        annual_dol: 3421.77,
        description:
            'Upscale fish restaurant famous for its luxury yachtlike interior & hopping bar scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7302,
        store_longitude: -74.0005,
        total_orders: 1792.15,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Wine, Rum, Classy, Fun, Awesome, Fresh, Cool, Wonderful, Great',
        annual_dol: 1840.96,
        description:
            'Energetic house bands play at former hangout of Bob Dylan, Jimi Hendrix, Allen Ginsberg & others.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7758,
        store_longitude: -73.9151,
        total_orders: 3345.29,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Beer, Wine, Hotel, Irish, Friendly, Local, Casual, Great',
        annual_dol: 3416.27,
        description:
            'Chill, vintage-style haunt with craft cocktails & local draft beer, plus weekly live music & DJs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7521,
        store_longitude: -74.0081,
        total_orders: 1449.3,
        neighborhood: '',
        keywords:
            'Lobster Roll, Lobster, Vegetarian, Delicious, Magical, Organic, Sweet, Fun, Perfect, American',
        annual_dol: 1821.7,
        description:
            'From May to October this docked Coast Guard lightship is home to a vibrant bar scene.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7476,
        store_longitude: -73.9869,
        total_orders: 287.21,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords:
            'Vegetarian, Korean, Gluten Free, Karaoke, Trendy, Modern, Casual, Perfect, Hip, Fusion',
        annual_dol: 879.92,
        description:
            'Trendy Koreatown bar & eatery offering inventive Korean fusion eats, soju cocktails & DJ nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7741,
        store_longitude: -73.7118,
        total_orders: 1162.44,
        neighborhood: '',
        keywords: 'Fresh, Outstanding, Fun, Great, Friendly, Golfclub',
        annual_dol: 1356.03,
        description:
            'Fresh Meadow Country Club is a country club with a golf course in the eastern United States, located on Long Island in Lake Success, New York, its home since 1946. The club opened in the New York City borough of Queens in 1923, with a golf course designed by noted course architect A. W.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7031,
        store_longitude: -73.908,
        total_orders: 6.1,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Burger, Mexican, Beer, Coffee, Wine, French, Casual, Classic, Spectacular',
        annual_dol: 24.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.753,
        store_longitude: -73.9225,
        total_orders: 16.84,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Hamburger, Kebab, Salad, Tasty, Colombian, Ale, Spicy, Fajitas, American',
        annual_dol: 68.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7355,
        store_longitude: -73.9858,
        total_orders: 438.01,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Beer, Wine, Delicious, Hamburger, Ale, Irish, Nachos, Cozy, Vintage, Polished',
        annual_dol: 487.33,
        description:
            'This contemporary hangout with an Irish theme features sports on TV & a menu of wings & burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7538,
        store_longitude: -73.9746,
        total_orders: 31.22,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Comfortable, Casual, Cool, Dynamic',
        annual_dol: 127.6,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7558,
        store_longitude: -73.767,
        total_orders: 211.47,
        neighborhood: 'Bayside Hills/Bayside',
        keywords: 'Karaoke, Wine, Beer, Fun, Clean, Great, Casual',
        annual_dol: 864.41,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.803,
        store_longitude: -73.6463,
        total_orders: 842.34,
        neighborhood: '',
        keywords: 'Tequila, Mexican, Tacos, Beer, Rich, Wine, Tex-Mex, Warm, American, Crisp',
        annual_dol: 893.04,
        description:
            'Sophisticated spot serving inventive takes on Mexican classics in a dramatically decorated room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9711,
        total_orders: 925.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Tacos, Vegan, Salad, Coffee, Beer, Mediterranean, Casual, Fresh, Approachable',
        annual_dol: 3021.89,
        description:
            '"A FINE CASUAL SEAFOOD KITCHEN Inspired by the open air seafood markets around the globe. At Pesce lulu, the best seafood meal experiences are approachable and available to all, recipes from around the globe right here for us in NYC - fine, fast and casually. Fish / shrimp tacos, mahi sandwiches, seafood platters, fresh salads, vegan options. We are located at 601 Lexington Ave inside the Hugh Market Plaza of the Citigroup Center Building at 53rd Street."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6176,
        store_longitude: -74.0294,
        total_orders: 674.12,
        neighborhood: 'Fort Hamilton',
        keywords: 'Hotdog, Burger, Beer, Salad, Wine, Bbq, Chicken Wings, American, Warm, Irish',
        annual_dol: 1092.5,
        description:
            'Homey pub with a year-round heated beer garden serving craft brews, sausages & updated comfort food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -73.9009,
        total_orders: 2935.62,
        neighborhood: 'Flushing/Maspeth',
        keywords:
            'Burger, Cheesecake, Vegetarian, Steak, Wine, Dessert, French Onion Soup, French, Irish, American',
        annual_dol: 3072.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9941,
        total_orders: 1173.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Pizza, Beer, Wine, Delicious, Warm, Cozy, Irish, Welcoming, Awesome, Legendary',
        annual_dol: 1223.72,
        description:
            'This wood-paneled bar offers craft beer, cocktails, plus wings, pizza & sandwiches.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7579,
        store_longitude: -73.9838,
        total_orders: 607.13,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Lobster, Salad, Lobster Bisque, Wine, Vodka, Delicious, American, Yummy, Contemporary',
        annual_dol: 1075.76,
        description: '"Steak and Seafood"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7811,
        store_longitude: -73.719,
        total_orders: 2621.38,
        neighborhood: '',
        keywords:
            'Glamorous, Elegant, Impeccable, Superb, Fun, Romantic, Great, Perfect, Excellent, Fresh',
        annual_dol: 2735.04,
        description:
            '"Our Banquet Hall facilities include breathtaking decor, glamorous rooms, superb cuisine — all at a posh locale that is sure to impress your guests."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6857,
        store_longitude: -73.9945,
        total_orders: 674.09,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords:
            'Vegetarian, Steak, Vegan, Salad, Ethiopian, Margarita, Rich, African, Warm, Casual',
        annual_dol: 750,
        description:
            'Ethiopian fare, including rich stews, injera bread & vegan options served in warm surroundings.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7632,
        store_longitude: -73.9788,
        total_orders: 299.5,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Sushi, Vegan, Vegetarian, Gluten Free, Beer, Chili Chicken, Whiskey, Korean, Japanese, Spicy',
        annual_dol: 445.07,
        description:
            'Asian-inspired & Japanese chain restaurant where dishes are whisked to long communal tables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7565,
        store_longitude: -73.9843,
        total_orders: 0,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Rich, Fabulous, Italian, Romantic, Beer, Great, Excellent, Modern, Fine',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9911,
        total_orders: 182.03,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Wine, Meatball, Irish, Modern, Sweet, Iconic, Nostalgic, Casual',
        annual_dol: 744.06,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7449,
        store_longitude: -73.9931,
        total_orders: 410.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Modern, Perfect, Gourmet, Creative',
        annual_dol: 709.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7475,
        store_longitude: -73.9866,
        total_orders: 62.01,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Korean, Bbq, Spicy, Delicious, Premium, Casual, Authentic, Great',
        annual_dol: 253.48,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.765,
        store_longitude: -73.9609,
        total_orders: 607.66,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Vegan, Pizza, Tasty, Fruity, Salad, Mediterranean, Homemade, Authentic, Spanish',
        annual_dol: 662.26,
        description:
            'Dependable Italian spot for pizza & pasta with outdoor seating for Second Avenue people-watching.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6841,
        store_longitude: -73.9385,
        total_orders: 3972.01,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Zombie, Beer, Wine, Spanish, Innovative, Fun, Awesome, Urban, Sophisticated, Great',
        annual_dol: 4322.57,
        description:
            'Sophisticated cocktails, beer & wine in an artfully designed space with happy-hour specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6841,
        store_longitude: -74.0025,
        total_orders: 173.9,
        neighborhood: 'Columbia Street Waterfront District',
        keywords: 'Italian, Inviting, Warm, Casual, American, Local, Recommended, Classic, Great',
        annual_dol: 710.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.676,
        store_longitude: -74.0142,
        total_orders: 800.6,
        neighborhood: 'Red Hook',
        keywords:
            'Burger, Steak, Lobster, Salad, Delicious, Cozy, Korean, Warm, French, Comfortable',
        annual_dol: 853.83,
        description:
            'This small Red Hook eatery features Korean-accented eats & a cozy back garden.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.521,
        store_longitude: -74.2369,
        total_orders: 59.87,
        neighborhood: 'Tottenville/Charleston',
        keywords: 'Clean, Modern, Friendly, Great, Fine, Unique',
        annual_dol: 91.52,
        description:
            'Modern skating rink catering to both young & old with a bar, adult DJ nights, inflatables & arcade.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6407,
        store_longitude: -73.7869,
        total_orders: 127.52,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Pizza, Italian, Coffee, Beer, Wine, Donut, Meatball, Martini, Upscale',
        annual_dol: 480.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7303,
        store_longitude: -73.9836,
        total_orders: 53.28,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Filipino, Spicy, Playful, Bright, Casual, Great',
        annual_dol: 89.54,
        description:
            'Filipino eats presented in a tropical diner that has weekly no-plates, no-silverware nights.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7325,
        store_longitude: -73.988,
        total_orders: 304.5,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Tacos, Chocolate Cake, Mexican, Beer, Texan, Margarita, Wine, Vegan, Tex-Mex, Vegetarian',
        annual_dol: 331.86,
        description:
            'Humble Tex-Mex restaurant fixing up tacos, tortas & chalupas, plus margaritas & creative cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.989,
        total_orders: 491.22,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Fun, Awesome, Wonderful, Great, Lit, Casual',
        annual_dol: 1265.81,
        description:
            'A longtime hole-in-the-wall gay bar with theme nights featuring DJs & go-go boys.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7391,
        store_longitude: -73.9892,
        total_orders: 374.74,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Italian, Dessert, Upscale, Cuban, Rustic, Romantic, Classy, Homemade, Intimate',
        annual_dol: 416.94,
        description:
            'Italian eatery highlighting handmade pasta, traditional meat & fish dishes plus local vegetables.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7981,
        store_longitude: -73.9689,
        total_orders: 447.8,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Mexican, Tacos, Margarita, Nachos, Martini, Organic, Spicy, Mojito, Casual, Keto',
        annual_dol: 477.56,
        description:
            '"Street Taco on Broadway will be our second location! Please visit our original location, 358 3rd Ave. until we open. Follow us on Instagram for the latest updates! @streettacony"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7305,
        store_longitude: -73.8599,
        total_orders: 91.95,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Kebab, Burger, Georgian, Russian, Mediterranean, Spacious, Asian, Casual, Middle Eastern, Fine',
        annual_dol: 264.33,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7589,
        store_longitude: -73.7755,
        total_orders: 430.96,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Vegan, Salad, Dessert, Beer, Upscale, Italian, Mediterranean, Delicious, Contemporary, Spanish',
        annual_dol: 449.65,
        description:
            'Warmly decorated high-end eatery offers patio dining & a menu of Mediterranean-American entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7518,
        store_longitude: -73.9767,
        total_orders: 7060.56,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Iconic, Upscale, Western, Stylish, Great',
        annual_dol: 26676.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.983,
        total_orders: 1818.8,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, French, Luxurious, Smoky, Sophisticated, Chic, Scandinavian, Classic, Vibrant, Heritage',
        annual_dol: 1857.39,
        description:
            'Sophisticated French cooking presented in chic, airy environs with salvaged-wood beams.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7425,
        store_longitude: -73.9866,
        total_orders: 706.13,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Salad, Steak, Pizza, Italian, Wine, Tequila, Upscale, Prosecco, Belgian, Homey',
        annual_dol: 1362.72,
        description:
            'Upscale restaurant with a terrace serving homestyle Italian fare, along with deli & diner classics.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7603,
        store_longitude: -73.9892,
        total_orders: 482.1,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Tequila, Salad, Mexican, Margarita, Dessert, Irish, American, Caesar Salad, Friendly, Authentic',
        annual_dol: 605.47,
        description:
            'Tex-Mex meets Irish cuisine at this bi-level hangout with TVs for watching sports.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7851,
        store_longitude: -73.9559,
        total_orders: 100.67,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Sushi, Flavorful, Spicy, Japanese, Upscale, Casual, Wonderful, Local, Miso Soup, Excellent',
        annual_dol: 411.5,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6939,
        store_longitude: -73.9606,
        total_orders: 568.04,
        neighborhood: 'Bedford-Stuyvesant/Clinton Hill',
        keywords: 'Burger, Steak, Vegan, Salad, Beer, Cuban, Dessert, Tasty, American, Hotel',
        annual_dol: 2321.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5531,
        store_longitude: -73.933,
        total_orders: 325.57,
        neighborhood: 'Breezy Point/Roxbury/Rockaway Point',
        keywords: 'Hotel, Casual, Natural, American, Traditional',
        annual_dol: 419.11,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9898,
        total_orders: 17583.86,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Coffee, Delicious, Intimate, Cool, Memorable, Lit, Great, Creative, Casual, Unique',
        annual_dol: 18347.89,
        description:
            'Low-lit bar behind a hidden door in Patent Coffee, serving cocktails with an electric theme.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7509,
        store_longitude: -73.9975,
        total_orders: 332.69,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Wine, Champagne, Hotel, Golfclub, Fun, Intimate, Rooftop, Friendly, Fine, Casual',
        annual_dol: 1359.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7662,
        store_longitude: -73.9655,
        total_orders: 2612.13,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Coffee, Trendy, Martini, Delicious, Organic, Rustic, Spanish, French, Classy',
        annual_dol: 2667.56,
        description:
            'Farm-to-table American restaurant with private dining rooms located inside a historic brownstone.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7662,
        store_longitude: -73.9559,
        total_orders: 1556.17,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vodka, Beer, Nutritious, Delicious, Chili Con Carne, Quaint, Irish, Cozy, Clean, French Onion Soup',
        annual_dol: 1645.45,
        description:
            "Watering hole with Guinness & pub grub that's popular with medical staff from nearby hospitals.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7647,
        store_longitude: -73.9667,
        total_orders: 267.98,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Hotel, Rum, Classic, Premium, Great, Hip, Excellent, Local, Unique',
        annual_dol: 273.66,
        description:
            'Hip museum exhibiting illustrative works providing live drawing sessions & an on-site eatery.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6738,
        store_longitude: -73.999,
        total_orders: 21.18,
        neighborhood: 'Carroll Gardens',
        keywords: 'Beer, Pizza, Modern, Awesome, Local, Great, Excellent, Diverse',
        annual_dol: 86.58,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7334,
        store_longitude: -73.9901,
        total_orders: 2526.6,
        neighborhood: 'Lower Manhattan',
        keywords:
            'Upscale, Trendy, Hotel, Premium, Friendly, Amazing, Great, Excellent, American, Contemporary',
        annual_dol: 2672.69,
        description:
            "Airy rooms feature 37-inch flat-screen TVs, iPod docks and WiFi (surcharge), as well as desks and coffeemakers. Some rooms have private terraces. Suites add separate living areas.There's an American brasserie and a contemporary cocktail lounge. Other amenities include an exercise room, complimentary bike rentals, and free WiFi in public areas.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -73.9903,
        total_orders: 3147.49,
        neighborhood: 'East Village/Bowery',
        keywords: 'Whiskey, Wine, Coffee, Beer, Rum, Hotel, Comfortable, Sweet, Fancy, Casual',
        annual_dol: 3299.94,
        description:
            'Small, casual bar with DJs, tattoo-inspired art in the bathrooms & old wine boxes on the ceiling.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5901,
        store_longitude: -74.0666,
        total_orders: 1077.46,
        neighborhood: 'Arrochar/South Beach',
        keywords: 'Clean, Perfect, Natural, Great, Unique',
        annual_dol: 1148.27,
        description:
            '"Easily accessible from all five boroughs, New Jersey & Long Island, The Vanderbilt @ South beach has been designed to offer the latest in technological and comfort amenities within stunning surroundings…. With dramatic ocean views, relaxing natural acoustics, and truly magnificent vista of the Verrazano Narrows Bridge, our facility is the perfect setting for any occasion you are planning! Unlike any other location in the New York City area, Staten Islands only oceanfront ballroom also give you the option to take our pristine elegance and professional white glove service outdoors!"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7952,
        store_longitude: -73.9698,
        total_orders: 292.05,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Mexican, Tacos, Steak, Salad, Beer, Quesadillas, Funky, Warm, Casual, Comfortable',
        annual_dol: 714.32,
        description:
            'Classic Mexican fare with weekday happy hour & taco specials in a bright, cantina-style setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7295,
        store_longitude: -73.8617,
        total_orders: 0,
        neighborhood: 'Rego Park/Flushing',
        keywords: 'Salad, Sushi, Thai, Dessert, Chinese, Korean, Asian, Fusion, Sashimi, Delicious',
        annual_dol: 0,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.7702,
        total_orders: 424.77,
        neighborhood: 'Bayside',
        keywords: 'Burger, Steak, Cajun, Irish, Bourbon, American, Ale, Juicy, Fancy, Traditional',
        annual_dol: 561.63,
        description:
            'American & Irish meals are served in a simple, traditional dining room with a large, separate bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7084,
        store_longitude: -74.0141,
        total_orders: 1147.65,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Pizza, Italian, Chicken Parmigiana, Meatball, Beer, Wine, American, Delicious, Artisanal',
        annual_dol: 1197.4,
        description:
            'Pizzeria & bar specializing in pies & pastas, plus barrel-aged cocktails, wines & beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9693,
        total_orders: 1674.27,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Beer, Wine, Spicy, Irish, Warm, Japanese, Bbq, American, Ramen, Classic',
        annual_dol: 1709.8,
        description:
            'Traditional, wood-lined Irish pub supplying draft beer & classic American comfort food.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7608,
        store_longitude: -73.9684,
        total_orders: 277.04,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tasty, Vietnamese, Upscale, French, Elegant, Romantic, Legendary, Adventurous, Lively, Sweet',
        annual_dol: 1151.04,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7626,
        store_longitude: -73.982,
        total_orders: 7033.2,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Wine, Beer, Italian, Modern, American, Friendly, Signature, Pure',
        annual_dol: 7267.87,
        description:
            "Rockefeller Center.Refined rooms have flat-screen TVs and Wi-Fi (fee); some offer pull-out sofas. Upgraded rooms add city views, and suites feature separate living areas. Club-level quarters provide access to a lounge serving complimentary continental breakfast and snacks. Room service is available.There's a casual, modern restaurant serving breakfast and lunch, as well as a library bar and a Starbucks cafe. Other amenities include a fitness center and 61,808 sq ft of event space.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9753,
        total_orders: 24.07,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Steak, Hamburger, Shrimp Cocktail, Coffee, Bourbon, Wine, French Toast, Dessert, Avocado Toast',
        annual_dol: 26.2,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9928,
        total_orders: 730.48,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Rum, Fabulous, Eclectic, Perfect, Great, Creative, Friendly, Pure, New Zealand',
        annual_dol: 2985.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7213,
        store_longitude: -73.9934,
        total_orders: 1662.95,
        neighborhood: 'Bowery',
        keywords: 'Chinese, Peking Duck, Congee, Playful, Classic, Dim Sum, Casual',
        annual_dol: 6863.3,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7207,
        store_longitude: -73.9873,
        total_orders: 270.8,
        neighborhood: 'Lower East Side',
        keywords:
            'Tequila, Mexican, Tacos, Margarita, Chinese, Tasty, French Toast, French, Delicious, Triple Sec',
        annual_dol: 1106.93,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7762,
        store_longitude: -73.9815,
        total_orders: 246.56,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Pizza, Vegetarian, Italian, Gluten Free, Rich, Vodka, Beer, Crisp, Rustic',
        annual_dol: 257.25,
        description:
            '"Updated Traditional Pizzeria, offering slices, pies, pastas, salads, desserts and more..."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7763,
        store_longitude: -73.9799,
        total_orders: 174.91,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Sushi, Tasty, Wine, Stylish, Trendy, Japanese, Thai, Comfortable, Asian, Friendly',
        annual_dol: 225.94,
        description:
            'Pan-Asian & Japanese fusion cuisine in a warm, brick-lined space with lunch specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7091,
        store_longitude: -74.0134,
        total_orders: 1203.03,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Delicious, Hotel, Spacious, Amazing, Innovative, Premium, Excellent, Unique',
        annual_dol: 4572.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7612,
        store_longitude: -73.984,
        total_orders: 2140.97,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Salad, Burger, Dessert, Wine, Delicious, Hotel, Fun, Caesar Salad, Caribbean, Club Sandwich',
        annual_dol: 2262.91,
        description:
            'Carolines on Broadway is a venue for stand-up comedy situated at 750 Seventh Avenue, on Broadway between 49th and 50th Streets, in the Midtown Manhattan neighborhood of New York City.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7645,
        store_longitude: -73.9833,
        total_orders: 32.59,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Hotel, Coffee, Sleek, Iconic, Vibrant, Unique',
        annual_dol: 141.89,
        description:
            "Straightforward rooms offer flat-screen TVs, microwaves and free Wi-Fi. Upgraded rooms add minifridges and pull-out sofas. Some suites include terraces with skyline views.There's a sleek bar and a 24/7 convenience store, in addition to a restaurant serving steak and seafood. Other amenities include a 24/7 fitness center, 2 meeting rooms and a business center.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7631,
        store_longitude: -73.9883,
        total_orders: 577.47,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Vegan, Vegetarian, Gluten Free, Korean, Casual, Friendly, Asian, Dynamic',
        annual_dol: 1666.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9757,
        total_orders: 696.36,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Tasty, Chinese, Spicy, Delicious, Authentic, Wonton Soup, Classic, Congee, Casual',
        annual_dol: 1892.49,
        description:
            'Easygoing mini-chain stop serving an array of dim sum favorites, along with classic Chinese entrees.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6387,
        store_longitude: -73.5831,
        total_orders: 46.8,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Shrimp Cocktail, Mexican, Dominican, Flavorful, Contemporary, Casual, Friendly, Fresh',
        annual_dol: 191.3,
        description:
            'Contemporary haunt for seafood favorites like ceviche & lobster boils, plus live music & a terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7079,
        store_longitude: -74.001,
        total_orders: 1781.89,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Tacos, Mexican, Beer, Margarita, Delicious, Rum, Tex-Mex, Cozy, Sweet, Fun',
        annual_dol: 1841.05,
        description:
            'Casual restaurant with offbeat nautical decor serving Southwestern faves, some with seafood twists.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5882,
        store_longitude: -73.6635,
        total_orders: 160.39,
        neighborhood: '',
        keywords:
            'Burger, Mexican, Cuban, Dessert, Delicious, Spanish, American, Fresh, Casual, Local',
        annual_dol: 198.38,
        description:
            '"Come taste what you\'ve been missing at the new El Malecon De Cuba Restaurant in Long Beach, NY."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7143,
        store_longitude: -73.9496,
        total_orders: 31.61,
        neighborhood: 'Williamsburg',
        keywords:
            'Gluten Free, Delicious, Italian, Mediterranean, Casual, Fresh, Hummus, American, Middle Eastern, Pita Bread',
        annual_dol: 44.28,
        description:
            'Compact Mediterranean eatery serving shareable plates & brunch in an easygoing setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7418,
        store_longitude: -73.9812,
        total_orders: 224.4,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Salad, Turkish, Vegetarian, Dessert, Wine, Mediterranean, Classy, Hummus, Falafel, Fresh',
        annual_dol: 261.57,
        description:
            'Turkish food (including a bountiful brunch) served in a sleek, crimson-hued room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7587,
        store_longitude: -73.9278,
        total_orders: 177.1,
        neighborhood: 'Astoria/LIC/Flushing',
        keywords: 'Pizza, Italian, Wine, Homemade, Modern, Casual, Local, Recommended, Traditional',
        annual_dol: 723.92,
        description: '"ITALIAN PASTA BAR & PIZZA"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7424,
        store_longitude: -73.9829,
        total_orders: 36.43,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Vegetarian, Butter Chicken, Chicken Tikka Masala, Indian, Relaxed, Biryani, British, Creamy, Casual, Authentic',
        annual_dol: 173.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7627,
        store_longitude: -73.9771,
        total_orders: 1902.49,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Italian, Irish, Chinese, Spicy, Karaoke, Eclectic',
        annual_dol: 1987.92,
        description:
            'Easygoing watering hole serving eclectic pub food & a few Irish dishes, plus mainstream beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7665,
        store_longitude: -73.9946,
        total_orders: 89.22,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Delicious, Asian, Traditional, Caribbean, Casual, Nostalgic',
        annual_dol: 128.61,
        description:
            '"There is no other event space like Casa Venue Space, where you’re inspired to think more freely, work more collaboratively, and explore ideas more creatively. Relax and enjoy as Casa Venue becomes your personal private party and corporate event venue. Sync your presentation or favorite films to the largest TVs on the market. Showcase your brand or style on our custom-designed 50-foot display wall. Primed to host your next meeting, product launch or social event- whatever your need, Casa Venue is molded to spark innovation and allow creativity to thrive."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7205,
        store_longitude: -73.9893,
        total_orders: 188.86,
        neighborhood: 'Lower East Side',
        keywords:
            'Garlic Bread, Vegetarian, Pizza, Salad, Italian, Beer, Mimosa, Gin, Bellini, Prosecco',
        annual_dol: 771.98,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.9933,
        total_orders: 144.74,
        neighborhood: 'Bowery',
        keywords:
            'Lobster, Lobster Roll, Burger, Shrimp Cocktail, Salad, Cajun, Tasty, Fried Calamari, Beer, Spicy',
        annual_dol: 186.65,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8665,
        store_longitude: -73.9282,
        total_orders: 617.19,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Steak, Wine, Canadian, Delicious, Spanish, Trendy, French Toast, Dominican, French, Premium',
        annual_dol: 688.22,
        description:
            'Festive restaurant & lounge offering Latin & Dominican cuisine, plus cocktails & sangria.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6388,
        store_longitude: -73.5831,
        total_orders: 426.4,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Champagne, Wine, Beer, Delicious, Elegant, Hurricane, Classy, American',
        annual_dol: 454.75,
        description:
            'Fine-dining spot along the Nautical Mile serving traditional American steakhouse fare with a view.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8571,
        store_longitude: -73.9323,
        total_orders: 736.24,
        neighborhood: 'Fort George/Washington Heights',
        keywords:
            'Burger, Steak, Mexican, Salad, French, American, Latin, Homemade, Casual, Authentic',
        annual_dol: 1903.75,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 291.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Steak, Sushi, Hotel, Japanese, Beer, Sashimi, French, Rooftop, High-End, Fusion',
        annual_dol: 407.94,
        description:
            "Loft-style rooms have minifridges, Wi-Fi access and flat-screen TVs with iPod docks. Suites add extra living space, HDTV projectors and surround-sound systems. Some suites have landscaped terraces with hot tubs.Perks include a year-round glass-bottom outdoor pool with a “beach club” and late-night hours, a 24-hour fitness center, hotel bikes and boutique shopping in the lobby. There's also a rooftop lounge and a fusion restaurant serving Japanese and French cuisines.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9553,
        total_orders: 1441.8,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Spanish, Mediterranean, Peruvian, Dominican, Spacious, Whimsical, Memorable, Classic, Welcoming',
        annual_dol: 1569.05,
        description:
            'Eatery in a 19th-century building serving classic Spanish fare & tapas, with a bar & a roomy patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7069,
        store_longitude: -74.0035,
        total_orders: 22650.3,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Tasty, Wine, Salad, Delicious, Mimosa, Trendy, Phenomenal, Spacious, Rum, Innovative',
        annual_dol: 26622.71,
        description:
            "Atera alum's American restaurant with a marketplace, barroom & upstairs dining room & garden.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6402,
        store_longitude: -74.0758,
        total_orders: 122.54,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Garlic Bread, Pizza, Flavorful, Gluten Free, Tasty, Salad, Italian, Meatball, Shrimp Cocktail, Beer',
        annual_dol: 135.64,
        description:
            'Pizza parlor with a patio serving thin-crust pies & other Italian-American eats, plus craft beers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7422,
        store_longitude: -73.9846,
        total_orders: 401.76,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Pizza, Italian, Wine, Tequila, Vodka, Rustic, Hotel, Margarita, Tex-Mex, Cozy',
        annual_dol: 492.14,
        description:
            'This Italian restaurant tucked beneath the Hotel Giraffe offers modern takes on pizza & pasta.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7221,
        store_longitude: -73.959,
        total_orders: 2275.51,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Wine, Coffee, Bbq, Hotel, Israeli, Sustainable, Gracious, Premium, Perfect',
        annual_dol: 2535.52,
        description:
            'Buzzy nightspot featuring rotating DJs, a dance floor & a full bar serving cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6745,
        store_longitude: -73.9502,
        total_orders: 299.99,
        neighborhood: 'Crown Heights',
        keywords: 'Cajun, Salad, Gumbo, Bbq, Lively, Creole, Sweet, Caribbean, Hospitable, Thai',
        annual_dol: 1050.67,
        description:
            '"Nostrand Social is a lively, music-forward Creole restaurant and supper club in Crown Heights, Brooklyn, NY. We embody an ethos centered on movement, growth, and transformation. We feature a wide range of live music from jazz to blues, to R&B, to rock, and to soul. Our Brooklyn events feature living legends, new artists, and members of the community whose musical expression we aim to highlight. On top of that, we present an excellent menu highlighting Creole food and Caribbean classics, from gumbo to rasta pasta, with a new twist. We also serve bottomless brunch in Brooklyn, senior luncheons and often feature stand-up comedy that connects our Crown Heights community."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7522,
        store_longitude: -73.9918,
        total_orders: 21.02,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Gourmet, Wine, Beer, Warm, Exquisite, Memorable',
        annual_dol: 85.92,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6759,
        store_longitude: -73.816,
        total_orders: 26.71,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Burger, Vegetarian, Wine, French, American, Local, Casual, Awesome, Colorful, Great',
        annual_dol: 65.55,
        description:
            'Colorful lights, DJ sounds & drinks set the tone for this energetic haunt with drink specials.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7448,
        store_longitude: -73.9974,
        total_orders: 976.83,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Milkshake, Mexican, Gourmet, Hotel, American, Stylish, Latin, Clean, Great',
        annual_dol: 2659.54,
        description:
            'Movie theater chain, offering state-of-the-art sound & projection technology.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7461,
        store_longitude: -74.0014,
        total_orders: 1125.68,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Salad, Steak, Pizza, Shrimp Cocktail, Wine, Breakfast Burrito, Italian, Green Curry, Warm, Thai',
        annual_dol: 1174.48,
        description: 'Pub offering bar food (with lunch specials), sports on TV & happy hours.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7401,
        store_longitude: -74.0015,
        total_orders: 140.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Whiskey, Beer, Wine, Gin, Irish, Classy, Great, Local, Laid-Back, Casual',
        annual_dol: 574.73,
        description:
            '"Gadfly Bar is a laid back local spot to grab a drink and a bite with friends. Solid beer and whiskey list. Happy hour 3-6 PM weekdays. Great vibes every day."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7439,
        store_longitude: -73.9928,
        total_orders: 726.31,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Hotel, Italian, Coffee, Urban, Polished, Modern, Perfect, Great, Cool',
        annual_dol: 2736.52,
        description:
            'Market.The modern rooms, some featuring wall beds, have Wi-Fi, smart TVs and minifridges. Many offer city views; some include bunk beds. Room service is available.Amenities consist of a polished Italian bar/restaurant, a coffee bar and a gym.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6812,
        store_longitude: -73.9774,
        total_orders: 1639.01,
        neighborhood: 'Park Slope',
        keywords:
            'Whiskey, Beer, Wine, Bourbon, Canadian, Rich, Vintage, Irish, Picturesque, Classy',
        annual_dol: 2231.21,
        description:
            'Neighborhood taproom with a large, varied craft-beer selection, plus vintage games & trivia.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6227,
        store_longitude: -73.7226,
        total_orders: 118.84,
        neighborhood: '',
        keywords:
            'Steak, Pizza, Gourmet, Turkey Sandwich, Gluten Free, Roast Beef, Bbq, American, Delicious, Ample',
        annual_dol: 129.51,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7075,
        store_longitude: -74.0019,
        total_orders: 405.97,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Wine, Salad, Italian, Mexican, Delicious, Ale, French, Southern, Asian, Classic',
        annual_dol: 828.23,
        description: '"Upscale Mexican Cuisine"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7265,
        store_longitude: -73.9859,
        total_orders: 1860.65,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Warm, Comfortable, Cool, American, Casual, Amazing, Great, Fantastic',
        annual_dol: 1984.34,
        description:
            'Small, spare East barroom drawing locals who like their drinking with no pretensions.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8148,
        store_longitude: -73.9034,
        total_orders: 47.88,
        neighborhood: 'Woodstock/West Bronx',
        keywords:
            'Steak, Mexican, Shrimp Cocktail, Margarita, Delicious, Spanish, American, Cozy, Latin, Relaxed',
        annual_dol: 75.75,
        description:
            'This small, relaxed eatery features a menu of Mexican & Central American dishes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6795,
        store_longitude: -73.968,
        total_orders: 326.12,
        neighborhood: 'Prospect Heights',
        keywords: 'Korean, Beer, Organic, Trendy, Contemporary, Modern, Bbq, Casual, Classy, Yummy',
        annual_dol: 409.92,
        description:
            'Restaurant for contemporary Korean dishes made with organic ingredients, plus cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7227,
        store_longitude: -73.9862,
        total_orders: 2175.81,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Korean, Modern, Seasonal, Casual, Cool, Fun, Lively, Edgy, Creative',
        annual_dol: 2270.14,
        description:
            'Beer, wine & inventive cocktails fixed up in a buzzy bar with live DJs & an edgy vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.604,
        store_longitude: -74.1623,
        total_orders: 33093.33,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Steak, Burger, Cheesecake, Salad, Vegetarian, Pizza, Wine, Italian, Delicious, Beer',
        annual_dol: 135274.63,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.688,
        store_longitude: -73.9625,
        total_orders: 355.45,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Upscale, Stylish, Picturesque, Thai, American, Rum, Awesome, Southern, Classic',
        annual_dol: 372.28,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7586,
        store_longitude: -73.9245,
        total_orders: 1273.22,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Lobster, Salad, Wine, Beer, Warm, Mediterranean, Sweet, Creole, Vintage, Intimate',
        annual_dol: 1387.61,
        description:
            'Warm eatery & bar featuring craft cocktails & raw oysters in comfy digs with a vintage vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7607,
        store_longitude: -73.9229,
        total_orders: 642.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Vegan, Steak, Vegetarian, Vodka, Chicken Pot Pie, Beer, Champagne, Delicious, Trendy',
        annual_dol: 670.49,
        description:
            'Hip bridge-&-tunnel-themed restaurant serving American fare, brunch, craft beers & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9549,
        total_orders: 1473.79,
        neighborhood: 'Greenpoint',
        keywords: 'Vegan, Beer, Salad, German, Rooftop, Belgian, Fun, Awesome, Amazing, Refreshing',
        annual_dol: 1678.54,
        description:
            'A European beer list & an expansive rooftop are the draws at this German-accented barroom.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.746,
        store_longitude: -73.9528,
        total_orders: 665.51,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Pizza, Italian, Spicy, Organic, Warm, Homemade, Friendly, Artisanal, Sweet, Authentic',
        annual_dol: 759.45,
        description:
            'Old-world Italian cafe with a warm vibe offering homemade pasta dishes, pizzas & house-made gelato.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8497,
        store_longitude: -73.7887,
        total_orders: 185.02,
        neighborhood: 'City Island',
        keywords: 'Homemade, Cozy, Eclectic, Local, Great, Unique',
        annual_dol: 210.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6586,
        store_longitude: -73.7812,
        total_orders: 1026.27,
        neighborhood: 'Jamaica',
        keywords: 'American, Hotel, Premium, Great',
        annual_dol: 1436.4,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8008,
        store_longitude: -73.9653,
        total_orders: 2486.04,
        neighborhood: 'Manhattan Valley/UWS',
        keywords:
            'Burger, Salad, Vegetarian, Wine, Beer, Breakfast Burrito, Delicious, Hotel, Irish, Caesar Salad',
        annual_dol: 2833.33,
        description:
            'A playful craft cocktail list is paired with elevated, global snacks & mains in a casual setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5886,
        store_longitude: -74.0901,
        total_orders: 33.43,
        neighborhood: 'Dongan Hills',
        keywords: 'Salad, Italian, Excellent, Casual',
        annual_dol: 136.64,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -73.9848,
        total_orders: 1831.99,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Burger, Vegan, Hamburger, Mexican, Beer, Gluten Free, Margarita, Wine, Delicious, Thai',
        annual_dol: 1927.25,
        description:
            'Hot spot for craft cocktails, modern Mexican eats & live music in an industrial-chic setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7271,
        store_longitude: -73.9881,
        total_orders: 2923.65,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Flavorful, Salad, Vegan, Coffee, Funky, Mexican, Fun, Spectacular, Cool, Great',
        annual_dol: 3141.48,
        description:
            "Inspired by Tim Burton's movies, this whimsical, goth hangout serves American fare & cocktails.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7275,
        store_longitude: -73.9866,
        total_orders: 1060.44,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vodka, Beer, Relaxed, Wonderful, Friendly, Great, Intimate, Vintage, Iconic, Creative',
        annual_dol: 1128.51,
        description:
            'Intimate bar & gathering place featuring a red lighting scheme & a relaxed atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5438,
        store_longitude: -74.2143,
        total_orders: 107.45,
        neighborhood: 'Rossville',
        keywords: 'Burger, Steak, Pizza, Salad, Bbq, Beer, Juicy, Tex-Mex, Irish, American',
        annual_dol: 439.24,
        description:
            '"Open for Business spring of 2021, Ultimate destination for family fun and great BBQ on Staten Island! party room, outdoor dining, With over 100 craft beers a crazy happy hour DaddyO’s BBQ will be an exciting, fun and festive place to be and watch every ufc, nfl ,nhl and every other sporting event. #bbq #restaurant #nyc #statenisland #nearme #food #bar #sportsbar #nj #familystyle #foodnearme #happyhour #lunch #sportsnearme"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8282,
        store_longitude: -73.85,
        total_orders: 35.24,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Spacious, Warm, Delicious, Healthy, Fresh, Great, Swiss, Intimate, Latin, Casual',
        annual_dol: 144.05,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7577,
        store_longitude: -73.978,
        total_orders: 394.86,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Burger, Steak, Cheesecake, Cajun, Whiskey, Beer, Italian, Japanese, Wine, Dessert',
        annual_dol: 1882.26,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.8314,
        total_orders: 66.23,
        neighborhood: 'Flushing',
        keywords:
            'Salad, Pizza, Vegetarian, Italian, Gluten Free, Wine, Hotel, Friendly, Casual, Welcoming',
        annual_dol: 115.7,
        description:
            'Welcoming Italian restaurant & bar offering breakfast, lunch & dinner in a contemporary setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7991,
        store_longitude: -73.6501,
        total_orders: 867.97,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Wine, Beer, Polished, Trendy, Caesar Salad',
        annual_dol: 893.13,
        description:
            'Family-run landmark has been serving seafood & steak in a traditional setting for over 55 years.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7569,
        store_longitude: -73.9719,
        total_orders: 1250.05,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Stylish, Iconic, Friendly, Recommended',
        annual_dol: 4733.68,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6233,
        store_longitude: -74.0313,
        total_orders: 1033.97,
        neighborhood: 'Fort Hamilton',
        keywords: 'Burger, Cornbread, Beer, Italian, Buffalo Wings, Tasty, Bbq, Korean, Ale, Tangy',
        annual_dol: 1078.8,
        description:
            'Fans of Buffalo wings and Rangers hockey congregate at this all-American sports bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7633,
        store_longitude: -73.9685,
        total_orders: 6347.28,
        neighborhood: 'Lenox Hill',
        keywords:
            'Vegetarian, Whiskey, Beer, Coffee, Wine, Tomato Soup, Gluten Free, American, Stylish, Martini',
        annual_dol: 6631.26,
        description:
            'Stylish, sophisticated bistro with imaginative New American plates, craft beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6758,
        store_longitude: -73.9414,
        total_orders: 177.04,
        neighborhood: 'Crown Heights',
        keywords:
            'Hamburger, Dessert, Upscale, Delicious, Bourbon, Polished, Relaxed, Welcoming, Outstanding, Fresh',
        annual_dol: 540.18,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6755,
        store_longitude: -73.9501,
        total_orders: 2177.86,
        neighborhood: 'Crown Heights',
        keywords: 'Pizza, Beer, Wine, Ale, Belgian, Spacious, Comfortable, Relaxed, Fun, Sweet',
        annual_dol: 2224.07,
        description:
            'Convivial neighborhood hangout featuring imported & craft beers on tap & an airy backyard.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7392,
        store_longitude: -73.9865,
        total_orders: 1483.3,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Lobster, Wine, Upscale, Italian, Elegant, Warm, Sparkling, Classy, Innovative, Friendly',
        annual_dol: 1652.79,
        description:
            'Casual Northern Italian restaurant in a cozy, warm space with sidewalk seating available.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7639,
        store_longitude: -73.9973,
        total_orders: 1378.31,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Chicken Lollipop, Salad, Rooftop, French, Homemade, Fantastic, Incredible, Hip, Unforgettable, Casual',
        annual_dol: 1585.53,
        description:
            "Two-level, DJ'ed club featuring plush interiors, a garden & a year-round roof deck with river views.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.762,
        store_longitude: -73.9935,
        total_orders: 184.54,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Gluten Free, Korean, Kebab, Wine, Pizza, Delicious, Spicy, Elegant, Warm, Martini',
        annual_dol: 452.85,
        description:
            'Seasonal ingredients shine on a multicourse Korean tasting menu served in a sleek space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7236,
        store_longitude: -73.9968,
        total_orders: 246.08,
        neighborhood: 'Lower Manhattan',
        keywords: 'Burger, Steak, Hamburger, Vegan, Tomato Soup, Salad, Beer, Gourmet, Tasty, Wine',
        annual_dol: 267.8,
        description:
            'Counter service fare like burgers, fries & beer served in a bright space with an outdoor patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.747,
        store_longitude: -73.9562,
        total_orders: 398.3,
        neighborhood: 'LIC/Hunters Point',
        keywords:
            'Burger, Coffee, Vegan, Carrot Cake, Wine, Beer, Meatball, Trendy, French, Delicious',
        annual_dol: 406.75,
        description:
            'Coffee & espresso drinks for connoisseurs at a casual cafe with a trendy atmosphere.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7272,
        store_longitude: -74.0026,
        total_orders: 1214.97,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Steak, Kebab, Beer, Wine, Mediterranean, Tunisian, French, Stylish, Shawarma, Cozy',
        annual_dol: 1295.73,
        description:
            'Place for contemporary Eastern Mediterranean food, wine & cocktails in a stylish setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -73.9929,
        total_orders: 5994.96,
        neighborhood: 'NoHo',
        keywords: 'Thai, Wine, Tasty, Salad, Dessert, Beer, Polished, Asian, Colorful, Vibrant',
        annual_dol: 6286.86,
        description:
            'Eatery by chef-brothers from Thailand for shareable Thai seafood plates with cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9953,
        total_orders: 1666.6,
        neighborhood: 'Nolita',
        keywords:
            'Pizza, Italian, Gluten Free, Beer, Wine, Spicy, Rustic, Homemade, Sweet, Friendly',
        annual_dol: 1701.96,
        description:
            'Wood-fired pizzas & homemade pastas highlight this rustic Italian with a skylight in the back room.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7265,
        store_longitude: -73.9935,
        total_orders: 2100.42,
        neighborhood: 'NoHo',
        keywords:
            'Coffee, Wine, Avocado Toast, Trendy, Rustic, Savory, Cozy, Mediterranean, Seasonal, Picturesque',
        annual_dol: 2513.1,
        description:
            'This laid-back cafe serves Mediterranean & American fare from a rustic basement locale.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.721,
        store_longitude: -73.9946,
        total_orders: 82.49,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Tasty, Gluten Free, Salad, Italian, Delicious, Spicy, Quaint, Cozy, Japanese',
        annual_dol: 91.91,
        description:
            'Cozy bar/eatery with a pink motif inspired by 1980s design, serving Italian fare & weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7261,
        store_longitude: -73.9954,
        total_orders: 437.87,
        neighborhood: 'NoHo',
        keywords: 'Spacious, Casual',
        annual_dol: 1679.52,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7473,
        store_longitude: -73.9862,
        total_orders: 2492.54,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Korean, Trendy, Modern, Amazing, Perfect, Lit, Unique, Cool, Fine',
        annual_dol: 2545.43,
        description:
            'Snacks & cocktails in a sleek, colorfully lit bar with 10 private rooms for group karaoke.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7451,
        store_longitude: -73.9897,
        total_orders: 431.04,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Salad, Steak, Tasty, Wine, Fruity, Italian, Dessert, Savory, Warm, Cozy',
        annual_dol: 459.36,
        description:
            'Classy-casual Italian osteria centering around its namesake mushroom, plus pastas, meats & wine.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.5901,
        store_longitude: -74.0666,
        total_orders: 674.94,
        neighborhood: 'Arrochar/South Beach',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Italian, French, Upscale, Rustic, American, Stylish, Spacious',
        annual_dol: 942.29,
        description:
            'Spacious upscale South Beach steak & seafood (with raw bar, sushi) destination offers ocean views.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8026,
        store_longitude: -73.968,
        total_orders: 97.82,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Sushi, Mexican, Salad, Beer, Japanese, Thai, Asian, Sashimi, American, Casual',
        annual_dol: 399.86,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7259,
        store_longitude: -73.9898,
        total_orders: 1748.62,
        neighborhood: 'East Village/Bowery',
        keywords: 'Whiskey, Beer, Wine, Vodka, Margarita, Martini, Ale, Gin, Vegetarian, Irish',
        annual_dol: 1785.72,
        description:
            'Draft beer, cocktails & American bar plates in a laid-back pub setting with rustic decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7319,
        store_longitude: -73.9855,
        total_orders: 1232.12,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Margarita, Vegetarian, Martini, Spicy, Salad, Ale, Gin, Spanish, Cozy',
        annual_dol: 2181.23,
        description:
            'Easygoing pub serving craft beers & cocktails, plus sharing plates, mains & bar bites.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7336,
        store_longitude: -73.9882,
        total_orders: 231.69,
        neighborhood: 'East Village/Lower Manhattan',
        keywords:
            'Burger, Hamburger, Steak, Salad, Mexican, Gluten Free, Gourmet, Tasty, Beer, Spicy',
        annual_dol: 334,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7567,
        store_longitude: -73.9139,
        total_orders: 39.21,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Burger, Shrimp Cocktail, Wine, Trendy, Thai, Romantic, Cozy, Colombian, Latin',
        annual_dol: 113.17,
        description:
            'Latin American fare served in a vibrant restaurant with a bar, a lounge & a tropical-style terrace.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7234,
        store_longitude: -73.8688,
        total_orders: 10.73,
        neighborhood: 'Middle Village/Flushing/Rego Park',
        keywords:
            'Burger, Tacos, Steak, Salad, Mexican, Nachos, Margarita, Fajitas, Spanish, Latin',
        annual_dol: 43.84,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8173,
        store_longitude: -73.942,
        total_orders: 1156.98,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Beer, Wine, Whiskey, Rustic, Delicious, Mimosa, Casual, Dope, Cool, Creative',
        annual_dol: 1348.6,
        description:
            'Rustic bar & music venue with charming, old-timey decor, craft cocktails & creative snacks.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7062,
        store_longitude: -73.8315,
        total_orders: 3070.49,
        neighborhood: 'Kew Gardens/Jamaica',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Beer, Bbq, Swiss, Ale, Caesar Salad, American, Stylish',
        annual_dol: 3070.49,
        description:
            'Stylish bar & grill offering multiple screens for game-watching, plus a Mexican-American food menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7545,
        store_longitude: -73.9686,
        total_orders: 1519.18,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Steak, Crab Cakes, Salad, Beer, Vodka, Wine, Spicy, Irish, American',
        annual_dol: 1970.98,
        description:
            'Two-level restaurant & bar with projection screens, traditional Irish breakfast & fixed-price menu.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8413,
        store_longitude: -73.9172,
        total_orders: 566.28,
        neighborhood: 'Mount Eden/West Bronx',
        keywords: 'Tacos, Mexican, Tequila, Sushi, Margarita, Latin, Premium, Lively, Casual, Cool',
        annual_dol: 586.97,
        description:
            "Buzzing nightspot & bar with a variety of live & DJ'd Latin beats in a no-frills setting.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7571,
        store_longitude: -73.9683,
        total_orders: 953.17,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Tasty, Dessert, Japanese, Spicy, Ramen, Delicious, Stylish, Ale, Asian, Katsu Curry',
        annual_dol: 1037.3,
        description:
            'Snug, stylish eatery specializing in its namesake noodle soup, plus other Japanese staples.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6775,
        store_longitude: -73.9357,
        total_orders: 228.29,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Salad, Nachos, Delicious, Southern, Smooth, Casual, Sweet, Premium, Local',
        annual_dol: 305.39,
        description:
            'This neighborhood hangout features a menu of Southern entrees, plus cocktails & live entertainment.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7088,
        store_longitude: -74.0111,
        total_orders: 1396.81,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Salad, Wine, Gluten Free, Champagne, Beer, Irish, Prosecco, Caesar Salad, American',
        annual_dol: 1426.45,
        description:
            'American eatery set in a converted bank vault with original vault doors dating to 1904.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7441,
        store_longitude: -73.99,
        total_orders: 2575.53,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Gin, Beer, Glamorous, Spacious, Healthy, Premium, Innovative, Seasonal, Classic, Sumptuous',
        annual_dol: 2824.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6992,
        store_longitude: -73.9924,
        total_orders: 1590.11,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Burger, Beer, Cajun, Cajun-Style, Ale, Wine, American, Homemade, Casual, Local',
        annual_dol: 1632.32,
        description:
            'Wash down comforting pub grub like pot pies & burgers with micro brews in a casual setting.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6991,
        store_longitude: -73.9924,
        total_orders: 1611.09,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Pizza, Italian, Wine, Tasty, Dessert, Upscale, Stylish, Delicious, Beer, Elegant',
        annual_dol: 1697.92,
        description:
            'This neighborhood eatery offers seasonal Italian fare & craft cocktails in stylish bistro environs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6905,
        store_longitude: -73.9948,
        total_orders: 315.38,
        neighborhood: 'Brooklyn Heights/Cobble Hill',
        keywords: 'Pizza, Salad, Italian, Beer, Dessert, Wine, Spicy, Organic, Artisanal, Relaxed',
        annual_dol: 336.11,
        description:
            'Elevated thin-crust pies served in casual, brick-lined digs with a full bar & sidewalk seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.759,
        store_longitude: -73.9856,
        total_orders: 693.32,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Italian, Hotel, Dutch',
        annual_dol: 963.96,
        description:
            'The Lunt-Fontanne Theatre, originally the Globe Theatre, is a Broadway theater at 205 West 46th Street in the Theater District of Midtown Manhattan in New York City. Opened in 1910, the Lunt-Fontanne Theatre was designed by Carrère and Hastings in the Beaux-Arts style for Charles Dillingham.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8766,
        store_longitude: -73.8464,
        total_orders: 114.33,
        neighborhood: 'East Bronx/Laconia',
        keywords:
            'Steak, Vegetarian, Meatball, Italian, Spanish, Hotel, Southern, Casual, Tradition, Caribbean',
        annual_dol: 310.72,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7618,
        store_longitude: -73.9937,
        total_orders: 31.12,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Salad, Beer, Spicy, Delicious, Ethiopian, Warm, Cozy, African, Casual',
        annual_dol: 58.7,
        description:
            'Spicy stews made from family recipes & eaten by hand with flat bread are served amid African decor.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.771,
        store_longitude: -73.9833,
        total_orders: 466.12,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Tequila, Tacos, Mexican, Steak, Flavorful, Tasty, Beer, Margarita, Wine, Stylish',
        annual_dol: 1399.71,
        description:
            'Modern Mexican chain eatery known for tableside guacamole, creative margaritas & chic surrounds.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7282,
        store_longitude: -74.0007,
        total_orders: 229.28,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Rich, French, Romantic, Martini, Elegant, Cozy, Classy, Quaint, American',
        annual_dol: 234.15,
        description:
            'Elaborate French classics are served in this chic, contemporary space with banquette & bar seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7297,
        store_longitude: -74.0005,
        total_orders: 16813.59,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Scotch, Bourbon, Margarita, Tasty, Perfect, Cool, Casual, Sophisticated, Wonderful, Creative',
        annual_dol: 22943.84,
        description:
            'Dimly lit cocktail lounge offering sophisticated drinks plus snacks in a space with banquettes.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8068,
        store_longitude: -73.9538,
        total_orders: 887.29,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Salad, Wine, Vodka, Delicious, Caesar Salad, Southern, Sweet, Seasonal, Casual, Omelette',
        annual_dol: 2043.32,
        description:
            'Casual eatery serving an American comfort food menu in a modern setting with an easygoing vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7704,
        store_longitude: -73.7929,
        total_orders: 135.32,
        neighborhood: 'Bayside/Flushing/Auburndale',
        keywords: 'Spanish, Cool, Authentic, Awesome, Local, American, Casual, Great, Excellent',
        annual_dol: 157.85,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7288,
        store_longitude: -74.0007,
        total_orders: 124.12,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Delicious, Ale, Italian, Chinese, Whimsical, Healthy, Fun, Seasonal, Lively',
        annual_dol: 507.36,
        description:
            '"A fun spot full of whimsy serving classic home cooked dishes. Sister Restaurant to Nat\'s on Bank."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7666,
        store_longitude: -73.9821,
        total_orders: 643.95,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster Roll, Lobster, Coffee, American, Sleek, Innovative, Local, Recommended, Excellent, Great',
        annual_dol: 671.87,
        description:
            '"The health and safety of our employees and community is our priority. When visiting our restaurant you will see increased sanitation, face coverings and gloves for all employees, traffic flow management and social distancing measures. We are working hard to keep our communities safe and can\'t wait to serve you. In compliance with local mandate, proof of vaccination is required prior to dining indoors at this location."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7643,
        store_longitude: -73.9797,
        total_orders: 173.53,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Salad, Vodka, Wine, Coffee, Martini, Greek, Spicy, American, Greek Salad',
        annual_dol: 177.21,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7187,
        store_longitude: -73.987,
        total_orders: 4930.2,
        neighborhood: 'Lower East Side',
        keywords: 'Cozy, Friendly, Beer, Hotel, Sweet, Classy, Fun, Perfect, Casual, Awesome',
        annual_dol: 5062.07,
        description:
            "'50s pinup model Bettie Page-themed bar with specialty cocktails & weekly burlesque shows.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7216,
        store_longitude: -73.9882,
        total_orders: 5964.08,
        neighborhood: 'Lower East Side',
        keywords: 'Burger, Steak, Salad, Vodka, Wine, Beer, Coffee, Mimosa, Delicious, Trendy',
        annual_dol: 6824.62,
        description:
            'Cocktails, beer & DJs are the draw at this casual, narrow, art-filled space with mezzanine lounge.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7198,
        store_longitude: -73.9849,
        total_orders: 324.86,
        neighborhood: 'Lower East Side',
        keywords: 'Wine, Cozy, Beer, Spacious, Fun, Natural, Perfect, Creative, Great, Incredible',
        annual_dol: 1223.97,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7189,
        store_longitude: -73.9884,
        total_orders: 316.19,
        neighborhood: 'Lower East Side',
        keywords: 'Pizza, Beer, Karaoke, Wine, Asian, Fun, Seasonal, Sweet, Perfect, Casual',
        annual_dol: 1292.48,
        description:
            'Energetic tavern offering pool tables, ping pong & live comedy, plus charcuterie plates & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7617,
        store_longitude: -73.9667,
        total_orders: 71.34,
        neighborhood: 'Lenox Hill',
        keywords:
            'Burger, Sushi, Pizza, Coffee, Beer, Japanese, Sashimi, Contemporary, Casual, Vintage',
        annual_dol: 291.63,
        description:
            'Restaurant featuring a menu of contemporary Japanese entrees, noodle dishes, soups & sushi.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7758,
        store_longitude: -73.9103,
        total_orders: 57.28,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Steak, Cajun, Burger, Mexican, Beer, French, Southern, Wine, Creole, Screwdriver',
        annual_dol: 117.99,
        description:
            'Down-to-earth eatery with rustic-chic decor serving updated Southern classics in a mellow space.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7433,
        store_longitude: -73.9997,
        total_orders: 356.07,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Pizza, Italian, Gluten Free, Wine, Vegetarian, Vegan, Vodka, Meatball, Mediterranean',
        annual_dol: 435.51,
        description:
            'Lasagna specialist serving meat, seafood & vegetarian versions plus other Italian standards.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7801,
        store_longitude: -73.8022,
        total_orders: 276.41,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords: 'Hotel, American, Friendly, Casual, Lively, Hip, Great',
        annual_dol: 282.27,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7468,
        store_longitude: -74.0083,
        total_orders: 337.69,
        neighborhood: '',
        keywords:
            'Champagne, Wine, Beer, Romantic, Elegant, Tasty, American, Lively, Classic, Iconic',
        annual_dol: 1380.35,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6869,
        store_longitude: -73.9786,
        total_orders: 166.56,
        neighborhood: 'Fort Greene',
        keywords: 'Wine, Beer, Coffee, Gin, Bourbon, American, Asian, Paloma, Chic, Natural',
        annual_dol: 232.69,
        description:
            'The Center for Fiction, originally called the New York Mercantile Library, is a not-for-profit organization in New York City, with offices at 15 Lafayette Avenue in Fort Greene, Brooklyn.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6776,
        store_longitude: -73.9689,
        total_orders: 3774.3,
        neighborhood: 'Prospect Heights',
        keywords:
            'Burger, Wine, Japanese, Martini, Margarita, French, Trendy, Korean, Delicious, Asian',
        annual_dol: 4293.7,
        description:
            'Classical French cooking meets Japanese flavors at this relaxed yakitori spot with a backyard patio.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.612,
        store_longitude: -74.1325,
        total_orders: 799.41,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Mexican, Margarita, Wine, Fajitas, Spicy, Casual, Delicious, Authentic, Sangria',
        annual_dol: 871.23,
        description:
            'Cheerful cantina offering Mexican dishes such as fajitas & tamales, plus covered outdoor seating.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7188,
        store_longitude: -73.9544,
        total_orders: 1344.06,
        neighborhood: 'Williamsburg',
        keywords:
            'Sushi, Vegan, Gluten Free, Japanese, French, Trendy, Spicy, Delicious, Sashimi, Glamorous',
        annual_dol: 1433.81,
        description:
            'Buzzing eatery & lounge offering Japanese dishes with a French twist, plus cocktails & beer.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7144,
        store_longitude: -73.9616,
        total_orders: 1321.14,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Burger, Coffee, Mimosa, Martini, Wine, Australian, American, French, Classic, Iconic',
        annual_dol: 1349.17,
        description:
            '"Serves specialty coffee, Aussie brunch favorites and a seasonally charged dinner menu alongside an extensive wine and seasonal cocktail list"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7231,
        store_longitude: -73.956,
        total_orders: 1004.16,
        neighborhood: 'Greenpoint',
        keywords:
            'Thai, Upscale, Phenomenal, French, Sophisticated, Traditional, Playful, Vibrant, Authentic, Fresh',
        annual_dol: 1047.7,
        description: '"A sophisticated Thai restaurant serving historic dishes inspired."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.745,
        store_longitude: -73.9838,
        total_orders: 2176.31,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Burger, Beer, Cajun, Bourbon, Mimosa, Bbq, Wine, American, Warm, Homemade',
        annual_dol: 2235.26,
        description:
            'Barbecue, beer & bourbon feature at this spot with a sports bar-like complement of widescreen TVs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7226,
        store_longitude: -73.9549,
        total_orders: 834.94,
        neighborhood: 'Greenpoint',
        keywords: 'Wine, Golfclub, Beer, Thai, Casual, Fine',
        annual_dol: 3412.94,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6798,
        store_longitude: -73.9895,
        total_orders: 5616.56,
        neighborhood: 'Gowanus',
        keywords:
            'Burger, Meatball Sub, Italian, Meatball, Beer, Bbq, Wine, Coleslaw, Seasonal, Friendly',
        annual_dol: 6396.88,
        description:
            'Laid-back outdoor bar & eatery offering trays of pit-smoked BBQ, plus draft beer & cocktails.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6814,
        store_longitude: -73.9116,
        total_orders: 563.87,
        neighborhood: 'Bedford-Stuyvesant/Ocean Hill',
        keywords: 'Japanese, Casual, Fun, Retro, Rum, Great',
        annual_dol: 1314.51,
        description:
            '"Roller disco inspired bar, restaurant, arcade and venue. THEMED BAR :: no skating rink or skate rentals. Food is available through Mr. Taco which is on site; please visit their page for hours and information."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.676,
        store_longitude: -73.9991,
        total_orders: 793.04,
        neighborhood: 'Carroll Gardens',
        keywords: 'Lasagna, Burger, Steak, Salad, Beer, Vodka, Gin, Fried Chicken, Spicy, French',
        annual_dol: 809.86,
        description:
            'Quaint eatery offering a hearty American comfort-food menu & a popular weekend brunch.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7372,
        store_longitude: -73.9921,
        total_orders: 106.91,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords: 'Fun, Amazing, Great',
        annual_dol: 168.08,
        description:
            '"The only Group Fitness Studio with a Full Liquor Bar. The Newest Craze from Europe now in Union Square."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7276,
        store_longitude: -73.9857,
        total_orders: 551.3,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Whiskey, Scotch, Wine, Beer, Irish, Funky, Comfortable, Intimate, Traditional, Hip',
        annual_dol: 589.08,
        description:
            'A former speakeasy during Prohibition, this funky bar is now an absinthe specialist.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7332,
        store_longitude: -73.9936,
        total_orders: 2300.61,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Burger, Hamburger, Salad, Beer, Wine, Ale, Crisp, Relaxed, Friendly, Classy',
        annual_dol: 2354.74,
        description:
            'Reasonably priced pub grub plus a pool table & TV sports keep regulars happy at this relaxed bar.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8457,
        store_longitude: -73.9362,
        total_orders: 782.5,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Mexican, Wine, Jamaican, Spanish, American, Mediterranean, Sweet, Latin, Greek, Lively',
        annual_dol: 1362.86,
        description:
            'Classic Mediterranean dishes & drinks, focused on on seafood in a lively space with an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7979,
        store_longitude: -73.9691,
        total_orders: 1392.33,
        neighborhood: 'Bloomingdale/UWS',
        keywords: 'Tequila, Beer, Pizza, Wine, Ale, Gourmet, American, Cozy, Iconic, Artisanal',
        annual_dol: 1500.44,
        description:
            'Unpretentious hangout focused on bourbon, tequila & scotch, plus beer to take out in growlers.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.753,
        store_longitude: -73.981,
        total_orders: 2759.18,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Wine, Mexican, Delicious, Hotel, Spacious, French Toast, Clean, Friendly, French, Lively',
        annual_dol: 2893.52,
        description:
            "Offering free minibars and Wi-Fi, the streamlined rooms have floor-to-ceiling windows, and marble bathrooms with rainfall showers, plus 42-inch flat-screen TVs and iPod docks. Suites add kitchenettes with coffeemakers and microwaves; some have private balconies. In-room spa services are available (fee).The hotel hosts free wine hours. There's a cellar bar offering shared plates and hand-crafted cocktails, along with a lively American restaurant serving locally sourced dishes. There's also a gym.",
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.68,
        store_longitude: -73.8407,
        total_orders: 1203.71,
        neighborhood: 'Ozone Park',
        keywords:
            'Vegetarian, Burger, Pizza, French, Korean, American, Premium, Modern, Perfect, Classic',
        annual_dol: 1280.98,
        description:
            '"Newly Renovated Modern Bowling Center,Glow Bowling,Restaurant,Bar,Lounge,Billiards,Pool,Arcade,Adult/Kids Birthday Parties.Corporate Events,Team Building,Fundraiser,Camp/School trips,Daily Specials,Live D.J., Wait Service,Parking,64 Lanes,Big Screen Projectors"',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7572,
        store_longitude: -73.9679,
        total_orders: 9057.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Salad, Japanese, Wine, Cozy, Legendary, American, Rare, Casual, Perfect, Great',
        annual_dol: 9659.16,
        description:
            'Low-key bar & grill featuring Japanese bites & live jazz in a cozy setting with a speakeasy vibe.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7559,
        store_longitude: -73.9655,
        total_orders: 1990.38,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Salad, Wine, Avocado Toast, Irish, Ale, Gin, Organic, American, Warm',
        annual_dol: 2055.71,
        description:
            'Long-standing Irish pub serving typical grub in a warm, homey atmosphere lit by Tiffany-style lamps.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7551,
        store_longitude: -73.9682,
        total_orders: 1660.24,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Burger, Salad, Italian, Wine, Meatball, French Toast, Chinese, Organic, Stylish, Delicious',
        annual_dol: 1660.24,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7599,
        store_longitude: -73.9913,
        total_orders: 1271.14,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Pizza, Gluten Free, Flavorful, Italian, Healthful, Green Curry, Apple Pie, Rustic, Delicious, Comfortable',
        annual_dol: 1400.24,
        description:
            'Comfortable restaurant highlighting classic Italian cuisine, including pizza, pasta & soup.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7026,
        store_longitude: -73.9874,
        total_orders: 5594.81,
        neighborhood: 'Dumbo/Brooklyn Heights',
        keywords:
            'Salad, Wine, Organic, Italian, Breakfast Burrito, Sustainable, Funky, Cozy, Mediterranean, Friendly',
        annual_dol: 5713.52,
        description:
            'Local, seasonal Mediterranean fare is offered in a converted warehouse space with an open kitchen.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7555,
        store_longitude: -73.9814,
        total_orders: 4851.51,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Rooftop, Creative, Unique',
        annual_dol: 4954.45,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7593,
        store_longitude: -73.9865,
        total_orders: 2604.24,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Wine, Beer, Hotel, Delicious, Fun, Eclectic, Classy, Caribbean, Iconic, Great',
        annual_dol: 2672.65,
        description:
            'Sony Corporation venue for diverse live music shows featuring dining, a full bar & high-tech sound.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7604,
        store_longitude: -73.9897,
        total_orders: 6016.6,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Sushi, Tasty, Pizza, Wine, Delicious, Japanese, Trendy, Cozy, Asian, Friendly',
        annual_dol: 8593.49,
        description:
            'Cozy, brick-walled Japanese eatery & sake bar offering yakitori, noodles & set-price party menus.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.6639,
        store_longitude: -73.6982,
        total_orders: 69.54,
        neighborhood: '',
        keywords: 'Burger, Cajun, Cajun-Style, Awesome, Casual',
        annual_dol: 284.24,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7458,
        store_longitude: -73.9923,
        total_orders: 2089.06,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Trendy, Spacious, Sleek, Modern, Polished, Italian, American, Sophisticated, Comfortable',
        annual_dol: 2095.91,
        description:
            'The sleek, modern rooms feature free Wi-Fi and minibars, as well as flat-screen TVs, Nespresso machines and floor-to-ceiling windows; some have city views. Suites offer living rooms and views of the Empire State Building; upgraded suites add furnished terraces. Room service is offered.Amenities include a sophisticated Italian restaurant/bar, a polished lobby lounge, and a 24-hour fitness center with a terrace. Pets are accepted for a fee.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7714,
        store_longitude: -73.9837,
        total_orders: 180.94,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Wine, Champagne, Coffee, Salad, Dessert, Ale, French Toast, Belgian, French, American',
        annual_dol: 188.78,
        description:
            'The Metropolitan Opera House is an opera house located on Broadway at Lincoln Square on the Upper West Side of Manhattan in New York City. Part of Lincoln Center, the theater was designed by Wallace K. Harrison. It opened in 1966, replacing the original 1883 Metropolitan Opera House at Broadway and 39th Street.',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.8043,
        store_longitude: -73.9483,
        total_orders: 1860.94,
        neighborhood: 'Central Harlem/Harlem',
        keywords: 'Tequila, Wine, Steak, Margarita, Trendy, Delicious, Warm, Cozy, Rum, French',
        annual_dol: 1984.65,
        description:
            '"Archer & Goat is a vibrant restaurant offering creative Latin American and South Asian inspired fusion fare. Open air outdoor and indoor dining available. Featured in Eater NY Dining Guide 2021 Best Brunch Spots. Located in the garden level of a brownstone in the beautiful Mount Morris Park Historic District in Harlem, New York City. Featured in the Wall Street Journal, CNN International, Eater, The Infatuation, Forbes, Bloomberg News."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.7468,
        store_longitude: -73.8902,
        total_orders: 987.97,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Pizza, Spanish, Beer, Wine, Chinese, Hotel, Western, Premium, Eclectic, Casual',
        annual_dol: 1076.74,
        description: '',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK 46',
        store_latitude: 40.726,
        store_longitude: -73.9922,
        total_orders: 535.75,
        neighborhood: 'NoHo',
        keywords:
            'Mexican, Tequila, Tacos, Vietnamese, Vegan, American, Latin, Fusion, Asian, Impeccable',
        annual_dol: 905.25,
        description:
            '"Vietnamese fusion featuring a Latin spirits and cocktail program. Kitchen open late night and brunch on the weekends."',
        dol_per_unit: 19.82,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6265,
        store_longitude: -74.0753,
        total_orders: 20.6,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Vegetarian, Wine, Coffee, Beer, Donut, Spacious, Caribbean, Casual, Great, Local',
        annual_dol: 84.19,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7194,
        store_longitude: -73.5638,
        total_orders: 129.01,
        neighborhood: '',
        keywords: 'Burger, Steak, Hamburger, Tasty, Beer, American, Casual, Blt Sandwich',
        annual_dol: 162.15,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7216,
        store_longitude: -74.0014,
        total_orders: 3431.66,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Wine, Delicious, Warm, Salad, Cozy, Organic, Mediterranean, Exotic, Outstanding, Turkish',
        annual_dol: 3730.25,
        description:
            'Mediterranean chow served in a funky, exposed-brick space with antiques & occasional live jazz.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7192,
        store_longitude: -74.0094,
        total_orders: 291.13,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Wine, Delicious, Vodka, Upscale, Organic, Greek, Mediterranean, Perfect, Fresh',
        annual_dol: 297.31,
        description:
            'Greek taverna serving a high-end, seafood-focused menu in an large, chic space with an Aegean feel.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7963,
        store_longitude: -73.9495,
        total_orders: 235.8,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Tequila, Beer, Wine, Gluten Free, Gin, Spicy, Salad, African, Nigerian, Sweet',
        annual_dol: 963.86,
        description:
            'Spacious counter-serve highlighting African cuisine in customizable plates & bowls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7237,
        store_longitude: -74.0039,
        total_orders: 70.1,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Beer, Organic, Italian, Avocado Toast, Upscale, Russian, Modern, Latin, Authentic',
        annual_dol: 286.53,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7497,
        store_longitude: -73.5782,
        total_orders: 24.94,
        neighborhood: '',
        keywords:
            'Burger, Steak, Lobster, Hamburger, Vegetarian, Lobster Tail, Vegan, Salad, Shrimp Cocktail, Gluten Free',
        annual_dol: 37.22,
        description:
            'Old-school eatery with a large menu of American breakfast favorites, sandwiches & entrees.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6998,
        store_longitude: -73.916,
        total_orders: 7536.02,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Hamburger, Zombie, Vegan, Vegetarian, Salad, Beer, Urban, Wine, American',
        annual_dol: 8019.84,
        description:
            'Tiki-inspired cocktails, board games & inventive pub grub are the draw at this rustic-chic tavern.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5813,
        store_longitude: -73.8299,
        total_orders: 674.18,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords:
            'Burger, Hamburger, Chicken Pot Pie, Gourmet, Tasty, Donut, Bbq, Delicious, American, Homemade',
        annual_dol: 834.59,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7531,
        store_longitude: -73.9778,
        total_orders: 8670.66,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Vodka, Beer, Scotch, Bourbon, Gin, Delicious, Hotel, Cozy, Sweet, Intimate',
        annual_dol: 9299.96,
        description:
            'Slick, Prohibition-style watering hole with craft cocktails & sophisticated bar snacks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7191,
        store_longitude: -74.0101,
        total_orders: 35.51,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Burger, Meatloaf, Salad, Whiskey, Beer, Wine, Gluten Free, Tasty, Swiss, Trendy',
        annual_dol: 145.16,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7924,
        store_longitude: -73.9523,
        total_orders: 232.01,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Beer, Elegant, Delicious, American, Georgian, Perfect, Casual, Urban, Distinctive Character, Heritage',
        annual_dol: 315.84,
        description:
            'The Museum of the City of New York is a history and art museum in Manhattan, New York City, New York. It was founded by Henry Collins Brown, in 1923 to preserve and present the history of New York City, and its people.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7389,
        store_longitude: -74.0053,
        total_orders: 8188.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Scotch, Whiskey, Hotel, Elegant, Cozy, Premium, Classy, Sophisticated, Friendly, Great',
        annual_dol: 8645.89,
        description: 'Cozy cigar bar with stogies, spirits & wines in a clubby, book-filled space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7383,
        store_longitude: -74.0042,
        total_orders: 4353.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Salad, Wine, Italian, Delicious, Cozy, Vintage, Relaxed, American',
        annual_dol: 4353.72,
        description:
            'This casual haunt offers bar snacks, steak dinners & plenty in between, plus varied wines & spirits.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7303,
        store_longitude: -74.004,
        total_orders: 6187.36,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Vodka, Vintage, Modern, Welcoming, Timeless, Innovative, Classic, Great, Casual',
        annual_dol: 6318.64,
        description:
            'Burgers & bar snacks in a retro, wood-lined watering hole, plus an innovative cocktail menu.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7347,
        store_longitude: -74.002,
        total_orders: 869.97,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Gluten Free, Dessert, Delicious, Coffee, Sweet, Fancy, Homemade, French, Amazing, Classic',
        annual_dol: 2921.94,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7553,
        store_longitude: -73.9897,
        total_orders: 1319.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Salad, Vegan, Spanish, Hotel, Seasonal, Cozy, Contemporary, Friendly, Classic',
        annual_dol: 1376.81,
        description:
            '"A lively, Barcelona-inspired tapas bar serving Spanish classics, seasonal specials, and curated cocktails in a vibrant and contemporary space."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.59,
        store_longitude: -73.9222,
        total_orders: 51.84,
        neighborhood: 'Gerritsen Beach',
        keywords: 'Italian, Dessert, Local',
        annual_dol: 211.89,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7491,
        store_longitude: -73.6207,
        total_orders: 463.34,
        neighborhood: '',
        keywords:
            'Coffee, Cheesecake, Pizza, Italian, Hotel, American, Opulent, Asian, Modern, Contemporary',
        annual_dol: 483.43,
        description:
            "Featuring sitting areas separated by partial walls, the contemporary suites include free Wi-Fi, flat-screen TVs, and desks, as well as minifridges, microwaves and coffeemakers.A hot breakfast buffet and parking are complimentary. Amenities include a fitness center and an indoor pool. There's also a convenience store, and a lobby lounge with a bar, as well as a patio offering outdoor seating and a fire pit.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8347,
        store_longitude: -73.8166,
        total_orders: 82.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'American',
        annual_dol: 134.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.754,
        store_longitude: -73.9902,
        total_orders: 3450.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Vegetarian, Beer, Gluten Free, Whiskey, Swiss, Bbq, American, Spicy, Casual, Local',
        annual_dol: 3523.23,
        description:
            'Late-night outpost for craft beer, cocktails & creative bar eats in a dark-wood setting with TVs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7538,
        store_longitude: -73.9891,
        total_orders: 11.5,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hamburger, Casual, Authentic, Fine',
        annual_dol: 47.01,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8277,
        store_longitude: -73.7017,
        total_orders: 417.21,
        neighborhood: '',
        keywords: 'Elegant, Comfortable, Classic, Spectacular, Exceptional',
        annual_dol: 443.99,
        description: '"Yacht club in Port Washington, New York"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7257,
        store_longitude: -74.0077,
        total_orders: 483.61,
        neighborhood: 'Hudson Square',
        keywords:
            'Steak, Tasty, Salad, Cuban, Delicious, Caribbean, Homemade, Rum, Sweet, Colorful',
        annual_dol: 949.41,
        description:
            '"Craft Cocktail and Rum Bar with Latin Caribbean/Old Cuban inspired plates designed by our internationally known Chef, Wesly Jean Simon. Join us for our $49 Bottomless Brunch every weekend!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.605,
        store_longitude: -73.9426,
        total_orders: 387.76,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Salad, Roast Beef, Italian, French, French Onion Soup, Delicious, American, Prime Rib',
        annual_dol: 404.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6339,
        store_longitude: -73.6882,
        total_orders: 370.05,
        neighborhood: '',
        keywords: 'Golfclub, Luxurious, Cozy, Phenomenal, Unique',
        annual_dol: 1512.63,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7422,
        store_longitude: -73.5856,
        total_orders: 730.02,
        neighborhood: '',
        keywords:
            'Pizza, Kebab, Salad, Mediterranean, Luxurious, Organic, Sweet, Exquisite, High-End, Incredible',
        annual_dol: 2984.06,
        description:
            '"Park West of Westbury NY a beautiful lavish luxurious night club lounge that is also available for private events and private parties! Under new management 12/2021 Come find experience this breathtaking venue for yourself! For bookings, inquiries, and questions please email us! Parkwestlongisland@gmail.com"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7962,
        store_longitude: -73.9462,
        total_orders: 184.84,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Steak, Peruvian, Salad, Wine, Burger, Italian, Hotel, Latin, Fusion, Vibrant',
        annual_dol: 476.32,
        description:
            'Elevated Peruvian cooking in cool surrounds, plus an extensive, thoughtfully sourced wine menu.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7998,
        store_longitude: -73.9326,
        total_orders: 245.77,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Legendary, Premium, Fine',
        annual_dol: 1004.63,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7565,
        store_longitude: -73.644,
        total_orders: 85.25,
        neighborhood: '',
        keywords: 'Tasty, Warm, American, Welcoming, Amazing, Great, British, Incredible, Casual',
        annual_dol: 167.13,
        description:
            'Longtime hangout with a 1950s motif offering American eats along with live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7739,
        store_longitude: -73.874,
        total_orders: 858.52,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Wine, Salad, Gourmet, Beer, Spacious, Healthy, Serene, Premium, Fresh, Contemporary',
        annual_dol: 1136.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7531,
        store_longitude: -73.9048,
        total_orders: 237.53,
        neighborhood: 'Woodside',
        keywords:
            'Flavorful, Beer, Wine, Dominican, Spanish, Latin, Pinacolada, Polished, Superb, American',
        annual_dol: 256.45,
        description:
            'Festive restaurant with a patio for modern Latin & Dominican fare, plus a nightclub.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6698,
        store_longitude: -73.8579,
        total_orders: 519.89,
        neighborhood: 'East New York',
        keywords:
            'Salad, Shrimp Cocktail, Steak, Mexican, Dessert, Martini, Spanish, Thai, Bbq, Latin',
        annual_dol: 938.92,
        description:
            'This landmark diner offers an extensive menu of American fare with Latin & Caribbean influences.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7723,
        store_longitude: -73.9558,
        total_orders: 26.4,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Kebab, Salad, Wine, Mediterranean, Inviting, Premium, Refreshing, Bright, Awesome',
        annual_dol: 92.47,
        description:
            '"The Blue Mezze Bar is a unique Mediterranean restaurant/bar on UES, Manhattan, with a focus on great food, equally amazing cocktails, and an inviting atmosphere! We have an extensive mezze and cocktail menu, ideal for sharing and tasting - perfect for friends, family, or that upcoming date! We formed the menu with inspiration from our friends and family at A la Turka."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7076,
        store_longitude: -73.9321,
        total_orders: 605.37,
        neighborhood: 'East Williamsburg',
        keywords:
            'Vegan, Burger, Vegetarian, Tacos, Beer, Wine, Instagrammable, Savory, American, Latin',
        annual_dol: 1186.88,
        description:
            'Vibrant venue with a patio fixing up tacos, burgers & finger foods, plus weekend brunch & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7967,
        store_longitude: -73.6703,
        total_orders: 3144.72,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Beer, Upscale, Comfortable, Casual, Mediterranean, Contemporary, Inviting, Natural',
        annual_dol: 3211.44,
        description:
            '"Cipollini Trattoria and Bar is a casual, Manhattan-style restaurant. Offering freshly prepared, satisfying meals in a comfortable, inviting atmosphere. A sizeable marble bar, perfect for socializing, welcomes people into the restaurant. Large, glass-paneled doors that are open seasonally bring the atmospheric alfresco dining into the trattoria, creating unity between the indoor and outdoor seating. During warmer months, sun umbrellas come together to provide a shady canopy and natural elements and beautiful flowers immerse diners in a Mediterranean dining experience. Cipollini has gained a reputation for its array of contemporary Italian specialties."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7168,
        store_longitude: -74.0086,
        total_orders: 975.44,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Salad, Spicy, Green Papaya Salad, Japanese, Funky, Thai, Asian, Rum, Sweet',
        annual_dol: 1017.73,
        description:
            'Southeast Asian dishes with a Laotian emphasis served in a brick-walled, industrial-styled setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.808,
        store_longitude: -73.9291,
        total_orders: 223.98,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Beer, Mexican, Tasty, Sustainable, American, Latin, Southern, Welcoming, Casual, Vibrant',
        annual_dol: 313.78,
        description:
            'Sophisticated global fare, brunch & cocktails in a vibrant space with exposed brick & bold art.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7763,
        store_longitude: -73.9531,
        total_orders: 1035.96,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Sushi, Vegetarian, Thai, Gluten Free, Tasty, Ramen, Beer, Bbq, Organic, Spicy',
        annual_dol: 1104.82,
        description:
            'Classic Thai cuisine & cocktails served in a brick-lined space with weathered woods & a stylish bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7446,
        store_longitude: -73.9214,
        total_orders: 882.9,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Burger, Steak, Salad, Beer, Ale, Irish, Delicious, American, Classic, Local',
        annual_dol: 890.84,
        description:
            'Sports bar with brick walls, red banquettes & sidewalk seating, serving burgers, wings & brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7554,
        store_longitude: -73.9945,
        total_orders: 81.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Upscale, Italian, Chinese, Spacious, Casual, Global, Fine',
        annual_dol: 332.47,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7548,
        store_longitude: -73.9923,
        total_orders: 1010.29,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Pizza, Italian, Rustic, Upscale, Homemade, Seasonal, Cozy, Bruschetta, Inviting, Local',
        annual_dol: 1652.8,
        description:
            'Sleek, slightly upscale Italian spot maximizing comfort with 2 roomy dining areas & a pizza bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7526,
        store_longitude: -73.9843,
        total_orders: 87.46,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Chinese, Spicy, Sweet, Local, Casual, Fresh, Mapo Tofu',
        annual_dol: 357.5,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7197,
        store_longitude: -73.8432,
        total_orders: 3609.25,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Vegan, Hamburger, Salad, Gluten Free, Beer, Wine, Organic, Bbq, Moroccan',
        annual_dol: 5704.45,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7779,
        store_longitude: -73.9543,
        total_orders: 2178.22,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Steak, Wine, French, Rustic, Cozy, Polished, Vintage, Warm, Authentic, Casual',
        annual_dol: 2207.63,
        description:
            'French bistro fare served in a typical brasserie atmosphere of dark wood & vintage posters.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7773,
        store_longitude: -73.9519,
        total_orders: 2385.47,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tequila, Whiskey, Wine, Beer, Margarita, Salad, French Toast, Upscale, Rum, French',
        annual_dol: 2594.25,
        description:
            'Upscale American comfort food & craft cocktails are prepared in this homey frontier-chic tavern.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.774,
        store_longitude: -73.9577,
        total_orders: 853.11,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Chinese, Upscale, Dessert, Elegant, Juicy, Cozy, Healthy, Sweet, Innovative, Asian',
        annual_dol: 998.04,
        description:
            'Cozy & elegant dining space serving traditional & innovative Shanghainese dishes, including dim sum.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7765,
        store_longitude: -73.9522,
        total_orders: 20.86,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Tasty, Italian, Beer, Shrimp Cocktail, Chicken Parmigiana, Romantic, Cozy, Bruschetta, Premium, Fabulous',
        annual_dol: 50.16,
        description:
            'Traditional Italian cooking served in a narrow room with exposed-brick decor & a romantic feel.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7261,
        store_longitude: -74.0085,
        total_orders: 22903.56,
        neighborhood: 'Hudson Square',
        keywords:
            'New-York-Style, Moroccan, Wine, Premium, Modern, Great, Lit, Chic, Rooftop, Rare',
        annual_dol: 23389.54,
        description:
            'Chic cocktail lounge where Morocco-inspired decor sets the stage for drinking & dancing all night.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7189,
        store_longitude: -74.0102,
        total_orders: 127.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Chocolate Croissant, Dessert, Coffee, Gourmet, Luxurious, French, Modern, Delicious, Picturesque, American',
        annual_dol: 519.9,
        description:
            '"Patisserie Chanson is a modern European café and eatery known for its artful and ambitious desserts and artisanal bakery offering. Open for breakfast, all-day brunch, and grab-and-go lunch, Patisserie Chanson offers freshly prepared French viennoiseries and an extensive drinks menu along with an open display of innovative gourmet pastries and piquant coffee brews. Among their exquisite baked goods are selections of the Kouign Amann, Croissant, and Ficelle. From the dessert menu, fan favorites include the Seasonal Tart and varieties of the Éclair and Marble Cake. Unmissable brunch items are the Black Truffle Grilled Cheese, Poached Egg & Salmon Croissant, and Pain Perdu, paired with refreshing cocktails, mocktails, wine, and champagne."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7553,
        store_longitude: -73.6485,
        total_orders: 1398.27,
        neighborhood: '',
        keywords:
            'Vodka, Upscale, Gin, American, Modern, Contemporary, Delicious, Perfect, Casual, Fresh',
        annual_dol: 1400.73,
        description:
            'Locally sourced New American dining in renovated landmark farmhouse with a porch & taproom.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7544,
        store_longitude: -73.847,
        total_orders: 15443.63,
        neighborhood: 'Flushing/Corona',
        keywords: 'Beer, Seasonal, Exceptional, Perfect, Great, Excellent',
        annual_dol: 64173.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7001,
        store_longitude: -73.9196,
        total_orders: 802.09,
        neighborhood: 'Bushwick',
        keywords: 'Vegetarian, Burger, Cozy, Casual, Natural, Chill, Great',
        annual_dol: 3022.02,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7309,
        store_longitude: -74.0041,
        total_orders: 536.38,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Steak, Tacos, Beer, Mimosa, Margarita, Dessert, Bloody Mary, Hotel, Modern',
        annual_dol: 955.61,
        description:
            'Imaginative twists on Mexican dishes, plus cocktails, presented in a modern, convivial setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6557,
        store_longitude: -73.6714,
        total_orders: 231.92,
        neighborhood: '',
        keywords:
            'Steak, Wine, Pizza, Cajun, Dessert, Italian, Meatball, Premium, Trendy, Romantic',
        annual_dol: 315.71,
        description:
            '"A casual Wine Bar and Kitchen located in the heart of the Lynbrook business district."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7344,
        store_longitude: -74.001,
        total_orders: 1296.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Sushi, Burger, Salad, Dessert, Whiskey, Scotch, Beer, Gin, Delicious, Upscale',
        annual_dol: 5479.18,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7345,
        store_longitude: -74,
        total_orders: 83.44,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Chinese, Thai, Peking Duck, Asian, Martini, Ramen, Exquisite, Irresistible, Healthy, Mojito',
        annual_dol: 341.07,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8383,
        store_longitude: -73.8638,
        total_orders: 8.24,
        neighborhood: 'East Bronx/Park Versailles/Parkchester',
        keywords: 'Burger, American, Karaoke, Casual, Great',
        annual_dol: 22.38,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6348,
        store_longitude: -74.1072,
        total_orders: 13.55,
        neighborhood: 'Randall Manor',
        keywords:
            'Steak, Shrimp Cocktail, Chili Con Carne, Wine, Italian, Bbq, Upscale, German, Swiss, French Onion Soup',
        annual_dol: 47.45,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7239,
        store_longitude: -73.9514,
        total_orders: 4261.72,
        neighborhood: 'Greenpoint',
        keywords:
            'Chocolate Croissant, Burger, Salad, Coffee, Gourmet, Wine, Spicy, Delicious, Organic, American',
        annual_dol: 4990.98,
        description:
            'This chill Greenpoint bistro offers New American fare with an Aussie accent & brunch options.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7283,
        store_longitude: -73.958,
        total_orders: 1547.25,
        neighborhood: 'Greenpoint',
        keywords: 'Cozy, Warm, Coffee, Friendly, Perfect, Unique',
        annual_dol: 2232.07,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7627,
        store_longitude: -73.9659,
        total_orders: 581.76,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Shrimp Cocktail, Italian, Delicious, Homemade, Healthy, Authentic, Outstanding, Casual, Fresh',
        annual_dol: 594.11,
        description:
            'Old-fashioned Italian cooking in a brick-walled setting run by the same family since 1955.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7667,
        store_longitude: -73.9694,
        total_orders: 39.23,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Italian, Mexican, Tasty, Dessert, Wine, Delicious, Asian, Classy, Polished',
        annual_dol: 137.38,
        description:
            'Polished destination with an urbane vibe serving classic Italian cuisine & wines.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5749,
        store_longitude: -73.9793,
        total_orders: 1816.53,
        neighborhood: 'Coney Island',
        keywords: 'Margarita, Beer, Mexican, Pizza, Bbq, Mojito, American, Sweet, Teriyaki, Casual',
        annual_dol: 2282.91,
        description:
            'Rollicking outdoor nightspot with live music, frozen drinks, games & a menu of BBQ & pub grub.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6749,
        store_longitude: -73.9813,
        total_orders: 1822.57,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Whiskey, Beer, Ale, Spacious, Rustic, Yummy, Perfect, Friendly, Retro',
        annual_dol: 1903.89,
        description:
            'Local haunt serving cocktails & beer in an old-school setup with dart boards & a shuffleboard table.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7457,
        store_longitude: -73.9008,
        total_orders: 2757.22,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Vodka, Tequila, Nepalese, Beer, Fusion, Biryani, Casual',
        annual_dol: 7506.9,
        description: '"New York Ko Bhatti"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8095,
        store_longitude: -73.6291,
        total_orders: 227.33,
        neighborhood: '',
        keywords:
            'Lobster, Chinese, Tangy, Peking Duck, Spicy, Organic, American, Wonton Soup, Local, Authentic',
        annual_dol: 237.18,
        description:
            'Hunan cooking spices up a menu that includes Peking duck & dumplings, plus cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8194,
        store_longitude: -73.9272,
        total_orders: 620.55,
        neighborhood: 'West Bronx',
        keywords:
            'Garlic Bread, Pizza, Italian, Vodka, Champagne, Meatball, Dessert, Bbq, Peruvian, Zesty',
        annual_dol: 661.8,
        description:
            'Vibrant locale for traditional Italian fare & pizzas, with a bar, weekend brunch & karaoke nights.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7772,
        store_longitude: -73.9519,
        total_orders: 1460.06,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Whiskey, Beer, Sushi, Bourbon, Coffee, Warm, Irish, Rustic, American, Awesome',
        annual_dol: 1557.67,
        description:
            'Rustic, brick-walled bar known for its whiskeys, rotating draft brews & elevated American eats.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7323,
        store_longitude: -74.0038,
        total_orders: 8480.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Burger, Beer, Vodka, Wine, Delicious, Funky, Bourbon, Cozy, Refined, Friendly',
        annual_dol: 8971.77,
        description:
            'Handsome second-floor bar with exposed brick, serving cocktails & Five Guys burgers from downstairs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7429,
        store_longitude: -73.6349,
        total_orders: 1269.84,
        neighborhood: '',
        keywords:
            'Hotdog, Beer, Ale, Whiskey, Irish, Friendly, Traditional, Awesome, Great, Excellent',
        annual_dol: 1330.51,
        description:
            'Popular, late-night watering hole featuring craft beers on tap & outdoor seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7241,
        store_longitude: -73.6401,
        total_orders: 3954.25,
        neighborhood: '',
        keywords:
            'Wine, Luxurious, Glamorous, Upscale, Hotel, Flavorful, Trendy, Elegant, Delicious, Margarita',
        annual_dol: 4080.1,
        description:
            'The posh rooms have flat-screen TVs, Italian furniture, Wi-Fi (surcharge) and 24-hour room service. Upgraded suites add kitchenettes and living areas, while some feature an additional bathroom and private outdoor patios.Perks include 25,000 sq ft of event space, an indoor pool, a spa and sauna, a fitness center and an upscale steakhouse, plus a seasonal patio bar, and lounge. Pet walking and feeding services are available. Parking is free.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.66,
        store_longitude: -73.6708,
        total_orders: 87.89,
        neighborhood: '',
        keywords: 'Sweet',
        annual_dol: 100.26,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.735,
        store_longitude: -73.5974,
        total_orders: 499.53,
        neighborhood: '',
        keywords: 'Clean, Friendly, Cuban, Savory, Casual, Fun, Modern, American, Classic, Great',
        annual_dol: 523.9,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6509,
        store_longitude: -73.6116,
        total_orders: 356.23,
        neighborhood: '',
        keywords:
            'Pizza, Chicken Parmigiana, Salad, Vodka, Mexican, Italian, Meatball, Wine, Creamy, Caesar Salad',
        annual_dol: 436.01,
        description:
            '"Looking for a spot to enjoy appetizers, drinks or a hearty dinner? Our NOVI Lunch, Dinner and Drink Menus boast something for everyone! Having a party or need a venue for your next event? We do on & off premise catering! From pizzas to pastas to seafood, Come down to NOVI for generous portions & modest prices. Whether it is a special occasion or a weekday evening, there is something for all to enjoy at NOVI!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7814,
        store_longitude: -73.9798,
        total_orders: 4862.37,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Burger, Ale, Hamburger, Vodka, Steak, Wine, Gin, Dessert, Fried Calamari',
        annual_dol: 4881.24,
        description:
            'No-frills brewpub with a sizable craft beer lineup, a bar food menu & several TVs airing sports.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.775,
        store_longitude: -73.9545,
        total_orders: 9.55,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Vegan, Coffee, Burger, Vegetarian, Tasty, Salad, Wine, Pizza, Beer, Organic',
        annual_dol: 39.03,
        description:
            'Australian hangout specializing in coffee drinks, along with salads & other daytime bites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7436,
        store_longitude: -73.6132,
        total_orders: 91.05,
        neighborhood: '',
        keywords: 'Lobster, Salad, Sushi, Steak, Japanese, Spicy, Sashimi, Creamy, Beer, Fun',
        annual_dol: 372.19,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6695,
        store_longitude: -73.9866,
        total_orders: 618.89,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Coffee, Ale, Funky, Dessert, Bourbon, Spacious, Ample, Relaxed, Friendly',
        annual_dol: 676.99,
        description:
            'Dog-friendly bar & hipster hangout with a broad selection of craft beers in chill, funky environs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6602,
        store_longitude: -73.9836,
        total_orders: 227.2,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords:
            'Chocolate Mousse, Salad, Italian, Meatball, Mimosa, Cozy, Asian, Premium, Seasonal, Friendly',
        annual_dol: 271.67,
        description:
            'Seasonal menus of classic Italian eats star at this warm, cozy restaurant with outside seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6702,
        store_longitude: -73.9856,
        total_orders: 62.58,
        neighborhood: 'Park Slope',
        keywords:
            'Mexican, Tacos, Tequila, Burger, Beer, Margarita, Martini, Quesadillas, Enchiladas, Wine',
        annual_dol: 76.78,
        description:
            'Warm Mexican eatery with colorful murals offering tacos & enchiladas plus ample tequila & mezcal.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7708,
        store_longitude: -73.9575,
        total_orders: 16.29,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Burger, Dessert, Beer, Spicy, Belgian, Elegant, Friendly, Wonderful, Classic',
        annual_dol: 66.59,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5757,
        store_longitude: -73.9798,
        total_orders: 93.7,
        neighborhood: 'Coney Island',
        keywords: 'Sushi, Champagne, Fun, Cool, Casual, Great',
        annual_dol: 143.23,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7187,
        store_longitude: -73.8402,
        total_orders: 351.12,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Pizza, Lobster, Italian, Gluten Free, Beer, Wine, Vodka, Homemade, Amazing, Fresh',
        annual_dol: 415.05,
        description:
            'Formal setting for an Italian menu with nightly live music, plus a Sunday brunch buffet.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7772,
        store_longitude: -73.9559,
        total_orders: 99.16,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Vegetarian, Beer, Flavorful, Chicken Tikka Masala, Elegant, Contemporary, Indian, Tandoori Chicken, Friendly',
        annual_dol: 127.87,
        description:
            'Tandoor oven & vegetarian specialties in an ornate interior. Takeout & delivery offered.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6522,
        store_longitude: -73.8379,
        total_orders: 438.53,
        neighborhood: 'Jamaica/Howard Beach',
        keywords: '',
        annual_dol: 505.47,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7346,
        store_longitude: -74.002,
        total_orders: 1914.2,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Whiskey Sour, Beer, Roast Turkey, Whiskey, Wine, Turkey Sandwich, Margarita, Spicy, Irish',
        annual_dol: 1954.82,
        description:
            'Named after a boat, this 4-story pub boasts a fireplace & billiards along with a sizable beer menu.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7487,
        store_longitude: -73.642,
        total_orders: 122.85,
        neighborhood: '',
        keywords:
            'Garlic Bread, Pizza, Tasty, Vegetarian, Salad, Italian, Delicious, Dessert, Comfortable, Awesome',
        annual_dol: 502.17,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7362,
        store_longitude: -74.0091,
        total_orders: 87.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Italian, Gin, Polished, Natural, Casual, Complex',
        annual_dol: 359.28,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7842,
        store_longitude: -73.9773,
        total_orders: 66.97,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Thai, Spicy, Delicious, Asian, Hawaiian, Colorful, Traditional, Authentic, Fresh, Mojito',
        annual_dol: 80,
        description:
            'Bustling chain offering generous portions of traditional Thai dishes & colorful cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6595,
        store_longitude: -73.5433,
        total_orders: 15.93,
        neighborhood: '',
        keywords: 'Pizza, Vodka, Beer, Salad, Italian, Bruschetta, Fresh, Great, Excellent, Local',
        annual_dol: 20.54,
        description:
            'Bustling, family-oriented pizza maker turning out brick-oven pies, plus pastas & other faves.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7708,
        store_longitude: -73.917,
        total_orders: 5.98,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Burger, Salad, French Toast, Coffee, French, American, Crisp, Belgian, Hotel, Casual',
        annual_dol: 24.43,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7656,
        store_longitude: -73.9189,
        total_orders: 1884.83,
        neighborhood: 'Astoria/LIC',
        keywords: 'Steak, Burger, Mexican, Beer, Wine, Bourbon, Spanish, Premium, American, Irish',
        annual_dol: 2461.8,
        description: '"Queer Cocktail Lounge & Kitchen in Astoria, New York."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6599,
        store_longitude: -73.9806,
        total_orders: 161.63,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords:
            'Burger, Salad, Avocado Toast, Wine, American, Casual, Local, Cool, Creative, Great',
        annual_dol: 612.06,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7074,
        store_longitude: -73.9396,
        total_orders: 1799.91,
        neighborhood: 'East Williamsburg',
        keywords: 'Pizza, Cozy, Spacious, Cool, Daiquiri, Casual, Local, Great',
        annual_dol: 1840.36,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7628,
        store_longitude: -73.9654,
        total_orders: 771.89,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Italian, Delicious, Cozy, Warm, Impeccable, Friendly, Intimate, Excellent, Casual, Fine',
        annual_dol: 788.27,
        description:
            'High-end Italian dining venue that lures an "in" crowd to its intimate downstairs grotto setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7676,
        store_longitude: -73.953,
        total_orders: 197.66,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Vegetarian, Vegan, Pizza, Salad, Chicken Parmigiana, Italian, Gluten Free, Wine, Beer, Bruschetta',
        annual_dol: 206.23,
        description:
            'Quiet, candlelit trattoria featuring familiar Italian flavors & occasional live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.769,
        store_longitude: -73.9683,
        total_orders: 41.95,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: '',
        annual_dol: 54.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7664,
        store_longitude: -73.9712,
        total_orders: 46.57,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Hotel, Italian, Brazilian, Urban, Intimate, Traditional, Local, Excellent, Artistic, Signature',
        annual_dol: 242.49,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7539,
        store_longitude: -73.9894,
        total_orders: 71.91,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Pizza, Italian, Wine, Polished, Local, Casual, Great, Fine',
        annual_dol: 73.44,
        description:
            'Square thin-crust pies & Italian standards are offered in a basic, second-floor location.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7518,
        store_longitude: -73.9844,
        total_orders: 3940.92,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Wine, Rooftop, Romantic, Lively, Fantastic, Incredible, Great, Polished, Classic',
        annual_dol: 4024.54,
        description:
            "from the Empire State Building.Upscale, individually decorated rooms feature floor-to-ceiling windows, designer toiletries and 42-inch flat-screen TVs, plus free Wi-Fi, minifridges and coffeemakers. Room service is available.Free passes to an off-site sports club are offered. There's a polished steakhouse serving seasonal American dishes, along with a lively lobby bar with a patio, and a rooftop bar with signature cocktails and sweeping city views.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7536,
        store_longitude: -73.9913,
        total_orders: 1553.13,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Korean, Wine, Trendy, Delicious, Spicy, Classy, Asian, Bbq, Contemporary, Friendly',
        annual_dol: 1730.6,
        description:
            'Korean BBQ & cocktails offered in lofty, contemporary surrounds with table grills & a sizable bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8311,
        store_longitude: -73.6959,
        total_orders: 171.41,
        neighborhood: '',
        keywords: 'Hotel, Unique',
        annual_dol: 700.67,
        description:
            '425-seat theater in a historic building spotlighting celebrated musicians, speakers & comedians.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7559,
        store_longitude: -73.5525,
        total_orders: 4623.66,
        neighborhood: '',
        keywords: 'Burger, Zombie, Steak, Salad, Beer, Margarita, Wine, Dessert, Bbq, Bourbon',
        annual_dol: 4721.77,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.716,
        store_longitude: -73.9965,
        total_orders: 1607.33,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Lobster, Champagne, Hotel, Wine, Tequila, Chinese, Stylish, Tasty, Asian, Funky',
        annual_dol: 2921,
        description:
            'All-day Asian spot at 50 Bowery hotel offering dim sum & share plates, plus American breakfasts.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7768,
        store_longitude: -73.9527,
        total_orders: 2976.38,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Salad, Sushi, Italian, Martini, Veal Parmigiana, Delicious, Cozy, Polished, Casual, Amazing',
        annual_dol: 3039.53,
        description:
            'Popular old-school Italian eatery known for classic dishes & people-watching opportunities.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7757,
        store_longitude: -73.9475,
        total_orders: 1326.42,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Tasty, Greek, Friendly, Authentic, Casual, Classic, Local',
        annual_dol: 1387.02,
        description:
            'Old-school Yorkville Italian restaurant serving classic red-sauce fare since 1982.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7425,
        store_longitude: -73.9157,
        total_orders: 11.79,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: '',
        annual_dol: 12.55,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6251,
        store_longitude: -74.0746,
        total_orders: 1495.22,
        neighborhood: 'Clifton',
        keywords:
            'Steak, Vegetarian, Salad, Margarita, Italian, French Toast, Mimosa, Beer, Organic, Avocado Toast',
        annual_dol: 1808.11,
        description:
            '"Located on Bay Street in Staten Island, The Richmond features New American Bistro fare with artisanal cocktails and craft beer."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6389,
        store_longitude: -73.6601,
        total_orders: 218.99,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Salad, Meatball, Wine, Delicious, Mediterranean, Robust, Fresh, Excellent',
        annual_dol: 282.39,
        description:
            '"A little bit of Italy brought to Long Island. Our robust and authentic cuisine was brought over by Italian immigrants in the late 1800s. If you’re hoping to experience Old World Italian cuisine, there’s nothing quite like Grandpa Tony’s. We are known for our hearty portions, fresh ingredients. served in all your old-world favorites. Our Chef is inspired by a traditional Italian cuisine and uses his passion for cooking to make every dish perfect."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6537,
        store_longitude: -73.867,
        total_orders: 15.27,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Juicy, Lobster, Cajun, Delicious, Romantic, Friendly, Homemade, American, Great, Excellent',
        annual_dol: 62.41,
        description:
            '"Welcome to Red Crab Juicy Seafood in East New York. Forged from the Heat of the South, our spices bring the Bold Flavors that you can expect from a Red Crab Seafood Boil. Since our first location in Florida, we have had grand visions of serving our aromatic seasonings all over the states. We are determined to bring our delicious seafood boil and career opportunities to our communities. Whether it is for parties, a romantic dinner, or even a light snack, our original homemade seasoning blend is sure to make you “Crave the Boil!”"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7316,
        store_longitude: -74.0068,
        total_orders: 736.41,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Whiskey, Beer, Pizza, Ale, Irish, American, Friendly, Authentic, Classic, Outstanding',
        annual_dol: 752.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7313,
        store_longitude: -74.0052,
        total_orders: 48.4,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Wine, Meatball, Korean, Spicy, Martini, Trendy, Green Curry, American, French',
        annual_dol: 197.86,
        description:
            'Quaint restaurant offering old-school American dishes & cocktails amid Shaker-style surrounds.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.658,
        store_longitude: -73.6476,
        total_orders: 104.68,
        neighborhood: '',
        keywords:
            'Garlic Bread, Pizza, Chicken Parmigiana, Salad, Italian, Wine, Spicy, Delicious, Omelette, Polished',
        annual_dol: 119.41,
        description:
            'Neapolitan wood-fired pizza & other southern Italian dishes offered in a refined, intimate space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7966,
        store_longitude: -73.6721,
        total_orders: 847.46,
        neighborhood: '',
        keywords:
            'Steak, Vegetarian, Sushi, Japanese, Wine, Asian, Filet Mignon, Modern, Fusion, Casual',
        annual_dol: 884.2,
        description: 'Hibachi chain serving Japanese dishes grilled tableside by theatrical chefs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7172,
        store_longitude: -74.0051,
        total_orders: 3293.6,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Sushi, Hamburger, Japanese, Turkish, Chinese, Mediterranean, Asian, Casual, Modern',
        annual_dol: 15196.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.657,
        store_longitude: -74.0071,
        total_orders: 1263.23,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords:
            'Lobster Roll, Lobster, Burger, Pizza, Hamburger, Coffee, Beer, Wine, Jamaican, Rich',
        annual_dol: 1365.95,
        description:
            '"Frying Pan Brooklyn is serving up cocktails, brews, lobster rolls and more at this Industry City outpost."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7211,
        store_longitude: -74.0046,
        total_orders: 894.18,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Beer, Delicious, Warm, Italian, Crisp, Spanish, American, Natural, Classic',
        annual_dol: 1153.08,
        description:
            'A seafood-forward menu with a raw bar is served in a light-filled space with a wraparound bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6925,
        store_longitude: -74.0151,
        total_orders: 130.53,
        neighborhood: 'Governors Island',
        keywords: 'Tequila, Tacos, Mexican, Beer, Pizza, Vegan, Margarita, Tasty, Wine, Painkiller',
        annual_dol: 255.92,
        description:
            'Governors Island eatery serving casual Mexican fare in a scenic setting with beachy vibes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7746,
        store_longitude: -73.951,
        total_orders: 885.58,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Lobster, Tacos, Lobster Roll, Beer, Margarita, Tasty, American, Rum, Irish',
        annual_dol: 972.33,
        description:
            'Relaxed, brick-lined eatery serving burgers, sandwiches, tacos & wings, plus cocktails & draft beer.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.719,
        store_longitude: -73.8393,
        total_orders: 95.86,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Pizza, Vodka, Italian, Salad, Spicy, Mediterranean, Casual, Authentic, Local',
        annual_dol: 109.35,
        description:
            'Rustic, yellow-walled exposed-brick trattoria with an open kitchen serving Northern Italian eats.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7317,
        store_longitude: -74.0037,
        total_orders: 540.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Mexican, Tacos, Margarita, Nachos, Quesadillas, Tasty, Enchiladas, Spicy, Latin, Local',
        annual_dol: 973.77,
        description:
            'Potent drinks & a crowded warm-weather patio are the main draws at this longtime Mexican eatery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7313,
        store_longitude: -74.005,
        total_orders: 1384.61,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Tequila, Salad, Beer, Coffee, Meatball, Mediterranean, Spanish, Greek, Modern',
        annual_dol: 1543.98,
        description:
            'Intimate, brick-lined Greek eatery serving both traditional & modern dishes plus wine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6812,
        store_longitude: -73.6148,
        total_orders: 25.42,
        neighborhood: '',
        keywords: 'Karaoke, Beer, Wine, Caribbean, American, Latin, Urban, Friendly, Casual',
        annual_dol: 34.61,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6932,
        store_longitude: -73.7574,
        total_orders: 66.46,
        neighborhood: 'Jamaica/St. Albans',
        keywords: 'Cozy, Ale, Beer, Casual, Fried Shrimp, French, Latin, American',
        annual_dol: 121.43,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7249,
        store_longitude: -73.5506,
        total_orders: 139.91,
        neighborhood: '',
        keywords:
            'Salad, Kebab, Vegetarian, Gluten Free, Dessert, Tasty, Mediterranean, Cozy, Turkish, Creamy',
        annual_dol: 149.1,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7853,
        store_longitude: -73.9728,
        total_orders: 1330.04,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Steak, Tequila, Pizza, Cheesesteak, Vodka, Wine, Salad, Beer, Scotch, Nachos',
        annual_dol: 1358.26,
        description:
            'Supper club with Jazz Age-themed decor, a long cocktail list & nightly live music performances.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7894,
        store_longitude: -73.9739,
        total_orders: 569.12,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Italian, French, New-York-Style, Trendy, Hotel, Fried Chicken, American, Southern, Casual',
        annual_dol: 1118.4,
        description:
            'Trendy multi-level destination with exposed-brick walls serving elevated pub grub & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.716,
        store_longitude: -73.9971,
        total_orders: 19.06,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Chinese, Bbq, Peking Duck, Seasonal, Asian, Classic, Casual, Dim Sum, Authentic',
        annual_dol: 77.89,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7519,
        store_longitude: -73.6578,
        total_orders: 414.74,
        neighborhood: '',
        keywords: 'Modern, Casual, Great, Delicious, Signature',
        annual_dol: 424.61,
        description:
            "All-ages bowling alley featuring a weekly rock 'n' roll bowl, pub grub & league tournaments.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7309,
        store_longitude: -73.6921,
        total_orders: 5728.89,
        neighborhood: '',
        keywords:
            'Garlic Bread, Lobster, Lobster Tail, Cajun, German, Beer, Wine, Spicy, American, Thai',
        annual_dol: 6109.73,
        description:
            '"The Inn at New Hyde Park is one of the largest wedding venues on Long Island, New York, and we invite you to take a tour of our award-winning event venue. We cater to different special occasions like baby showers, birthday parties and weddings. Experience an enchanting backdrop, elegant banquet and unique event planning that suit your needs. Terraces, fountains, gardens and both indoor and outdoor chapels create a picture-perfect landscape at every turn. You and your guests will be transported back to turn of the century elegance and refinement, while being pampered by modern day luxury. You can visit us or contact us today to arrange a private tour to view The Inn’s grounds and stunning ballrooms."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7646,
        store_longitude: -73.9162,
        total_orders: 21289.25,
        neighborhood: 'Astoria',
        keywords:
            'Steak, Vodka, Margarita, Vegetarian, Champagne, Coffee, Dessert, Colombian, Cuban, Rich',
        annual_dol: 21740.97,
        description:
            'Trendy, multilevel restaurant & bar featuring classic Latin American fare, cocktails, TVs & music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7853,
        store_longitude: -73.7236,
        total_orders: 9.53,
        neighborhood: '',
        keywords: 'Cajun, Sushi, Spicy, Japanese, French, Asian, New Zealand, Great, Clean, Casual',
        annual_dol: 13.71,
        description:
            'Japanese & Continental flavors mix in a small, no-frills restaurant serving sushi, duck & more.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7529,
        store_longitude: -73.9838,
        total_orders: 3557.97,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Sushi, Salad, Steak, Wine, Japanese, Upscale, Stylish, Luxurious, Trendy, Delicious',
        annual_dol: 3732.47,
        description:
            'Elevated Japanese cuisine whipped up in a buzzing space with elegant, Zen-inspired decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.719,
        store_longitude: -73.9973,
        total_orders: 229.72,
        neighborhood: 'Little Italy',
        keywords: 'Lobster, Salad, Dessert, Wine, Martini, Italian, Warm, Beer, Cozy, Romantic',
        annual_dol: 433.31,
        description:
            'White-jacketed waiters serve Southern Italian specialties at this restaurant dating to 1902.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.702,
        store_longitude: -74.0107,
        total_orders: 1827.55,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Upscale, Korean, Hotel, Chilean, Classy, Impeccable, Great, Hip, Heritage',
        annual_dol: 5598.99,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.722,
        store_longitude: -74.0038,
        total_orders: 1183.22,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Burger, Pizza, Italian, Salad, Stylish, Wine, Delicious, Elegant, Phenomenal, Polished',
        annual_dol: 1234.52,
        description:
            'Offshoot of a longtime French restaurant with Italian & Provençal fare in stylish but casual digs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7228,
        store_longitude: -74.0036,
        total_orders: 1217.72,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Salad, Beer, Coffee, Trendy, French, Delicious, Legendary, Warm, Traditional, Fabulous',
        annual_dol: 1245.05,
        description:
            'French bistro classics draw SoHo shoppers to this spot with huge windows that open onto the street.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7995,
        store_longitude: -73.944,
        total_orders: 1568.66,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Sushi, Hamburger, Mexican, Salad, Coffee, Spicy, Moroccan, Delicious, Mediterranean, Cozy',
        annual_dol: 3843.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7168,
        store_longitude: -74.0053,
        total_orders: 340.45,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Lobster Roll, Wine, Beer, Coffee, Upscale, Danish, Classy, Eclectic, Contemporary',
        annual_dol: 1460.59,
        description:
            'Luxe, intimate spot for inventive, multicourse New American tasting menus & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7406,
        store_longitude: -74.0059,
        total_orders: 20015.24,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Whiskey, Hotel, Trendy, Romantic, Awesome, Rooftop, American, Classic, Great, Excellent',
        annual_dol: 20729.43,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7347,
        store_longitude: -74.0009,
        total_orders: 1548.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegetarian, Wine, Salad, Beer, Gin, Organic, Martini, Sustainable, Relaxed, Delicious',
        annual_dol: 1618.58,
        description:
            'Basement-set American eatery that gets most of the produce for its seasonal fare from a roof garden.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7168,
        store_longitude: -74.0033,
        total_orders: 751.23,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Vegan, Vegetarian, Wine, Stylish, Gin, Perfect, Modern, Casual, French, Fancy',
        annual_dol: 1935.8,
        description:
            'Stylish New American restaurant offering sharing plates, a raw bar & cocktails, plus weekend brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7408,
        store_longitude: -74.0047,
        total_orders: 321.71,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Salad, Italian, Hotel, Premium, Homemade, Unforgettable, Exceptional, Casual, Rooftop',
        annual_dol: 1315.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7058,
        store_longitude: -74.0103,
        total_orders: 673.16,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Beer, Burger, Warm, Irish, Ale, Friendly, American, Casual, Authentic, Modern',
        annual_dol: 702.35,
        description:
            'Laid-back, dimly lit pub serving bar staples & a popular house burger with handcut fries.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6056,
        store_longitude: -73.6492,
        total_orders: 1325.43,
        neighborhood: '',
        keywords: 'Wine, Cajun, Juicy, Salad, Martini, Great, Diverse, Casual',
        annual_dol: 1731.7,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6087,
        store_longitude: -73.6467,
        total_orders: 236.04,
        neighborhood: '',
        keywords:
            'Beer, Delicious, Spanish, Superb, Authentic, Italian, Lively, Great, Traditional, Fresh',
        annual_dol: 265.88,
        description:
            'Traditional Portuguese eats & sangria served amid a lively scene in casual digs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8331,
        store_longitude: -73.8511,
        total_orders: 91.89,
        neighborhood: 'East Bronx/Unionport',
        keywords:
            'Vegetarian, Comfortable, Friendly, Fun, Casual, Indian, Lively, Caribbean, Fantastic, Great',
        annual_dol: 136.55,
        description:
            'Lively bar & grill featuring hearty portions of Guyanese & West Indian cuisine, plus a happy hour.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7092,
        store_longitude: -73.8699,
        total_orders: 6148.01,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords: 'Tequila, Burger, Beer, Vodka, Margarita, Ale, Warm, Tex-Mex, American, Awesome',
        annual_dol: 6291.81,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5906,
        store_longitude: -73.7972,
        total_orders: 1712.87,
        neighborhood: 'Arverne by the Sea/Far Rockaway/Arverne',
        keywords: 'Tacos, Pizza, Wine, Dessert, Bbq, Beer, Tasty, Casual',
        annual_dol: 2046.12,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7057,
        store_longitude: -73.8942,
        total_orders: 158.15,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Friendly, Cozy, Fun, Sweet, Karaoke, Perfect, Fresh, Great, Creative',
        annual_dol: 455.51,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7003,
        store_longitude: -73.9076,
        total_orders: 604.26,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Beer, Wine, American, Perfect, Great, Excellent, Local, Casual, Signature',
        annual_dol: 2470.02,
        description:
            '"The premier vinyl record bar of Queens, NYC. Serving up craft beer, cocktails & analog sounds. 3000+ vinyl library & good vibes. Afro-Latino owned."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7618,
        store_longitude: -73.9637,
        total_orders: 1171.89,
        neighborhood: 'Lenox Hill',
        keywords: 'Lobster, Coffee, Wine, Salad, Martini, Savory, Italian, Stylish, Hotel, Belgian',
        annual_dol: 3184.81,
        description:
            'Glam hub with outdoor seats offering pastries, light fare & a seasonal dinner menu, plus cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7295,
        store_longitude: -73.9577,
        total_orders: 872.51,
        neighborhood: 'Greenpoint',
        keywords: 'Lobster, Burger, Beer, Salad, Wine, Warm, Ale, Spacious, Polish, Homey',
        annual_dol: 910.34,
        description:
            'Modern hangout featuring house-brewed craft beer & elevated bar bites, with a warm, homey vibe.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7292,
        store_longitude: -74.0037,
        total_orders: 437.07,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Steak, Wine, Salad, Shrimp Cocktail, Pizza, Upscale, Italian, Donut, Beer',
        annual_dol: 1534.38,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7306,
        store_longitude: -74.0066,
        total_orders: 1239.89,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Vegetarian, Gluten Free, Organic, Cozy, Warm, French, American, Perfect',
        annual_dol: 1321.37,
        description:
            'Neighborhood eatery with an intimate feel serving a New American menu paired with cocktails & wine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8454,
        store_longitude: -73.8589,
        total_orders: 167.58,
        neighborhood: 'East Bronx/Van Nest',
        keywords: 'Traditional, Great, Wonderful, Fine',
        annual_dol: 222.05,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8345,
        store_longitude: -73.8506,
        total_orders: 35.82,
        neighborhood: 'East Bronx/Westchester Square/Parkchester',
        keywords:
            'Tacos, Milkshake, Mexican, Vegetarian, Salad, Nachos, Quesadillas, Casual, Local, Traditional',
        annual_dol: 60.52,
        description:
            'Large portions of Mexican food are served up at this small, family-friendly restaurant.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7674,
        store_longitude: -73.9228,
        total_orders: 174.79,
        neighborhood: 'Astoria/LIC',
        keywords: 'Sushi, Salad, Coffee, Delicious, Beer, Thai, Premium, Greek, American, Fresh',
        annual_dol: 714.47,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7294,
        store_longitude: -73.9577,
        total_orders: 8077.57,
        neighborhood: 'Greenpoint',
        keywords: 'Beer, Wine, Ale, Gin, Bourbon, Rum, Upscale, Hip, Fun, Friendly',
        annual_dol: 8248.96,
        description:
            'Hip bar with wood floors & tin ceilings offering creative cocktails, plus a back patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8568,
        store_longitude: -73.7908,
        total_orders: 4114.08,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Shrimp Cocktail, Salad, Italian, Caesar Salad, American, Casual, Local, Fresh, Excellent',
        annual_dol: 4223.5,
        description:
            'Low-key spot with outdoor seats on the Sound offers a variety of seafood & American comfort fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7435,
        store_longitude: -73.9588,
        total_orders: 861.34,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Pizza, Italian, Meatball, Wine, Beer, Vodka, Classy, Amazing, Local, Great',
        annual_dol: 1000.26,
        description:
            'Airy, light-filled outpost serving wood-fired pizza & Italian cuisine, plus wine, beer & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6779,
        store_longitude: -74.017,
        total_orders: 100.65,
        neighborhood: 'Red Hook',
        keywords:
            'Pizza, Meatball, Italian, Salad, Bruschetta, Margarita, Aperol, Sweet, Local, Casual',
        annual_dol: 411.43,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.732,
        store_longitude: -73.9837,
        total_orders: 1368.33,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Hotdog, Beer, Irish, Ale, Ramen, Rustic, Cozy, Great, Casual, Fine',
        annual_dol: 1411.02,
        description:
            'Cozy Irish tavern offering draft beers & cocktails, plus pool tables, dart boards & sports on TV.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7272,
        store_longitude: -73.9858,
        total_orders: 2485.37,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Beer, Upscale, American, Friendly, Perfect, Cool, Casual, Classic, Lively, Chill',
        annual_dol: 2538.1,
        description:
            'This diminutive corner watering hole with minimal decor offers a classic jukebox & happy hour deals.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7323,
        store_longitude: -73.9844,
        total_orders: 441.81,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords: 'Pizza, Filipino, Beer, Salad, Whiskey, Fabulous, Fun, Fantastic, Casual',
        annual_dol: 1673.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6392,
        store_longitude: -73.9685,
        total_orders: 2057.85,
        neighborhood: 'Flatbush',
        keywords: 'Wine, Whiskey, Beer, Relaxed, Irish, Casual, Premium, Cool, Great',
        annual_dol: 2249.2,
        description:
            'Compact, relaxed Irish pub serving European beers & meat pies, with soccer on TV & simple decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8045,
        store_longitude: -73.9662,
        total_orders: 742.24,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords: 'Burger, Hamburger, Pizza, Salad, Beer, Bbq, Margarita, Vodka, Tasty, American',
        annual_dol: 757.98,
        description:
            'Creatively topped burgers plus craft beers & boozy milkshakes in the light of a huge neon sign.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.832,
        store_longitude: -73.8657,
        total_orders: 2.76,
        neighborhood: 'East Bronx/Soundview',
        keywords:
            'Burger, Hamburger, Pizza, Mexican, Salad, Breakfast Burrito, Nachos, Margarita, Pinacolada, Quesadillas',
        annual_dol: 11.29,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7619,
        store_longitude: -73.9658,
        total_orders: 467.67,
        neighborhood: 'Lenox Hill',
        keywords: 'Burger, Sushi, Pizza, Italian, Mexican, Delicious, Cozy, Warm, Asian, Classy',
        annual_dol: 487.95,
        description:
            'Intimate restaurant with warm wooden fixtures featuring traditional Northern Italian food.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7441,
        store_longitude: -73.7764,
        total_orders: 37.7,
        neighborhood: 'Fresh Meadows/Auburndale/Flushing',
        keywords:
            'Burger, Salad, Coffee, Roast Beef, Dessert, Wine, Club Sandwich, Caribbean, Casual, Great',
        annual_dol: 66.09,
        description:
            'Old-school diner serves hefty portions of breakfast fare, burgers & made-from-scratch desserts.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.756,
        store_longitude: -73.9717,
        total_orders: 16.29,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Hotel, Luxurious, Great, Clean, Casual',
        annual_dol: 66.59,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7604,
        store_longitude: -73.9947,
        total_orders: 71.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Beer, Champagne, Wine, Vodka, Cosmopolitan, Green Tea Shot, Bbq, Karaoke',
        annual_dol: 294.25,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7588,
        store_longitude: -73.9834,
        total_orders: 99.67,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Australian, American, Amazing, Spanish, Great, Exceptional, Excellent, Friendly, Clean',
        annual_dol: 407.42,
        description:
            "mile from the Empire State Building.The warmly decorated rooms provide Wi-Fi, flat-screen TVs and minifridges. Family rooms and suites have sofabeds, and some suites add city views.There's a 24-hour fitness room and a theater-inspired dining area. A breakfast buffet is available, as is limited parking.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7561,
        store_longitude: -73.9808,
        total_orders: 119.93,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Mexican, Tacos, Burritos, Pizza, Salad, Delicious, Local, Casual',
        annual_dol: 490.23,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8713,
        store_longitude: -73.8481,
        total_orders: 153.93,
        neighborhood: 'Pelham Gardens/Laconia/East Bronx',
        keywords:
            'Lobster, Salad, Caribbean, Jamaican, Daiquiri, Sweet, Fun, American, Casual, Cool',
        annual_dol: 188.56,
        description:
            'Snug, informal setup serving Caribbean & American cuisine, including jerk chicken & seafood buckets.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7525,
        store_longitude: -73.9939,
        total_orders: 2324.07,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Elegant, Incredible, Remarkable, Rooftop, Unique',
        annual_dol: 2646.96,
        description:
            'The Manhattan Center is a building in Midtown Manhattan, New York City. Built in 1906 and located at 311 West 34th Street, it houses Manhattan Center Studios, its Grand Ballroom, and the Hammerstein Ballroom, a performance venues.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7095,
        store_longitude: -74.0127,
        total_orders: 130.09,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Coffee, Modern, Authentic, Great',
        annual_dol: 555.91,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7187,
        store_longitude: -73.8386,
        total_orders: 27.67,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Cuban, Wine, Caribbean, Swiss, Fun, Vibrant, Spanish, Exotic, Friendly, Fusion',
        annual_dol: 75.33,
        description:
            'Traditional Cuban fare served with cocktails in a colorful, upbeat setting with murals & live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7339,
        store_longitude: -74.0064,
        total_orders: 2809.6,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Vegetarian, Salad, Mexican, South American, Texan, Gluten Free, Margarita, Dessert',
        annual_dol: 2874.53,
        description:
            'Southwestern comfort food, like chili & Frito Pie, & potent drinks served up in a kitschy setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6963,
        store_longitude: -73.7615,
        total_orders: 1729.47,
        neighborhood: 'Jamaica/St. Albans',
        keywords:
            'Delicious, Magical, Savory, Amazing, Perfect, Caribbean, Great, Fusion, American, Casual',
        annual_dol: 1882.11,
        description:
            '"The Lavish Venue is your prime destination for great drinks and amazing nightlife in Saint Albans, NY. The atmosphere may draw you in, but you’ll stay for the drinks, expertly mixed by our bartenders and served at prices that will make you feel like its happy hour every hour. Our menu has something for everyone. Our menu offers various cocktails and delicious food items, including our Rasta pasta, chicken wings, shrimp, and fish fingers."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7345,
        store_longitude: -74.0092,
        total_orders: 407.54,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Lobster, Scotch, Avocado Toast, Upscale, Rich, Italian, French, Serene, Modernist',
        annual_dol: 444.16,
        description:
            "Jean-Georges & Cedric Vongerichten mix modern American flavors in Richard Meier's modernist tower.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7855,
        store_longitude: -73.9764,
        total_orders: 4380.2,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Hamburger, Sushi, Salad, Beer, Nachos, Wine, American, Ale',
        annual_dol: 4494.91,
        description:
            'Vintage Art-deco restaurant serving American bistro fare, raw bar & cocktails, plus weekend brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7192,
        store_longitude: -74.0121,
        total_orders: 117.19,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Lobster Roll, Burger, Salad, Tasty, Organic, Romantic, Fresh, Casual, Wonderful',
        annual_dol: 479.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7521,
        store_longitude: -73.7028,
        total_orders: 68.34,
        neighborhood: 'Glen Oaks/Bellerose Manor',
        keywords: 'Mexican, American, Casual, Fresh',
        annual_dol: 209.79,
        description: '"Authentic Mexican cuisine w full bar."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6707,
        store_longitude: -73.9819,
        total_orders: 1303.34,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Salad, Beer, Ale, Margarita, Nachos, Tasty, French, Caesar Salad, American',
        annual_dol: 1422.93,
        description:
            'Mellow neighborhood hangout for beer & pub food with outdoor seating & a pool table.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7664,
        store_longitude: -73.7066,
        total_orders: 455.42,
        neighborhood: '',
        keywords:
            'Shrimp Cocktail, Golfclub, Sushi, Impeccable, Sleek, Classic, Great, Asian, Fresh',
        annual_dol: 1886.11,
        description:
            '"Located on the former site of the Vanderbilt’s private golf club, The Lake Success Golf Club offers a challenging 6,414-yard, par 70 course with well-manicured fairways and impeccable greens. In addition to golf, members enjoy a first class club house, grill room, Social Activities Center, Fitness center and more. Tennis and Pool memberships are also available. We\'re truly a home away from home."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6706,
        store_longitude: -73.9915,
        total_orders: 27.51,
        neighborhood: 'Gowanus',
        keywords:
            'Wine, Beer, Delicious, Painkiller, Spicy, Impeccable, Martini, Meatball, Comfortable, Sweet',
        annual_dol: 112.45,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7301,
        store_longitude: -73.9567,
        total_orders: 1637.79,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Tequila, Beer, Tasty, Wine, Delicious, Bourbon, Cozy, Polish, Spacious',
        annual_dol: 1788.37,
        description:
            'Casual pub pouring pints to a crowd of locals, with a fireplace, theme nights & trivia games.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.716,
        store_longitude: -74.0154,
        total_orders: 1661.78,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords:
            'Italian, Pizza, Salad, Mimosa, Beer, Bloody Mary, Ample, Casual, Traditional, Great',
        annual_dol: 1803.1,
        description:
            'Easygoing choice serving ample portions of standard Italian favorites, plus old-school pizzas.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7041,
        store_longitude: -73.9332,
        total_orders: 605.65,
        neighborhood: 'East Williamsburg',
        keywords:
            'Salad, Vegetarian, Wine, Iranian, Stylish, Hotel, Mediterranean, Classy, Exceptional, Great',
        annual_dol: 2475.69,
        description: '"Persian Eatery"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7395,
        store_longitude: -74.0073,
        total_orders: 3302.6,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Rooftop, Latin, Premium, Contemporary, Great, Casual, Unique',
        annual_dol: 3534.73,
        description:
            '"SNS Bar is intended to be a hub for community, great music and a landing spot for New Yorkers and all it\'s guests, who want to have a good time."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7345,
        store_longitude: -73.615,
        total_orders: 3459.59,
        neighborhood: '',
        keywords:
            'Lasagna, Italian, Spaghetti And Meatballs, Pizza, Rich, Wine, Dessert, Upscale, Bruschetta, Polish',
        annual_dol: 3550.18,
        description:
            'Semi-upscale chain for classic Italian fare served family-style in a relaxed, polished space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.733,
        store_longitude: -74.003,
        total_orders: 4415.67,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Burger, Tasty, Wine, Vodka, French, Delicious, Premium, Gin, Mimosa',
        annual_dol: 4579.51,
        description:
            "Two-floor bistro for dry-aged steaks & other French fare, with a bar that's strong on absinthe.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7344,
        store_longitude: -74.0028,
        total_orders: 11806.42,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Intimate, Casual, Authentic, Legendary, Local, Great',
        annual_dol: 12072.5,
        description:
            'Tiny no-frills space delivers live jazz bands & jam sessions at modest prices.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7311,
        store_longitude: -74.0065,
        total_orders: 1058.62,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Vodka, Beer, Martini, Bourbon, Gin, Rum, Rich, Karaoke, Stylish, Triple Sec',
        annual_dol: 1161.14,
        description:
            'A long-running lesbian hangout for drinks, shooting pool & dancing, especially on weekends.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7295,
        store_longitude: -74.0045,
        total_orders: 317.26,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Wine, Sustainable, Seasonal, Cozy, Modern, Playful, Retro, Casual, Colorful',
        annual_dol: 331.02,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7177,
        store_longitude: -73.9984,
        total_orders: 63.62,
        neighborhood: 'Little Italy',
        keywords: 'Pizza, Italian, Salad, Delicious, Warm, Crisp, Clean, Retro, Authentic, Iconic',
        annual_dol: 260.05,
        description:
            'NYC-style slices are served in a simple space with narrow standing counters for eating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7447,
        store_longitude: -73.9102,
        total_orders: 52.97,
        neighborhood: 'Woodside',
        keywords: 'Delicious, Friendly, Fun, Ale, Cool, Great, Excellent, Casual, Local',
        annual_dol: 216.53,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5798,
        store_longitude: -73.9822,
        total_orders: 915.39,
        neighborhood: 'Coney Island',
        keywords:
            'Premium, Picturesque, Vibrant, Nutritious, Fantastic, Incredible, Classic, Tradition',
        annual_dol: 3741.83,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.646,
        store_longitude: -73.9579,
        total_orders: 706.61,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Rich, Zombie, Amazing, French, Cultural',
        annual_dol: 2888.41,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6762,
        store_longitude: -73.9802,
        total_orders: 3433.06,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Beer, Margarita, Martini, Spicy, Upscale, Bourbon, Nachos, Juicy, Cozy',
        annual_dol: 3480.46,
        description:
            'This bar/lounge features librarylike decor, plus 2 bocce courts & a basement performance space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6641,
        store_longitude: -73.9841,
        total_orders: 57.56,
        neighborhood: 'Park Slope',
        keywords: 'Vegetarian, Wine, Dessert, Thai, Chinese, Beer, Spicy, Cozy, Warm, Asian',
        annual_dol: 235.28,
        description:
            'Thai standards, beer & wine served in a low-key dining room with floor-to-ceiling windows.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7657,
        store_longitude: -73.9703,
        total_orders: 556.54,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Salad, Delicious, Dessert, Warm, Italian, Creamy, Healthy, Mediterranean, Perfect',
        annual_dol: 572.5,
        description:
            'Mediterranean fare & a European-style social scene in a busy bistro off Madison Avenue.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7692,
        store_longitude: -73.9629,
        total_orders: 850.16,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Steak, Salad, French, Wine, Beer, Homemade, Modern, Gracious, Filet Mignon',
        annual_dol: 871.36,
        description:
            'Traditional French cooking offered in a white-tablecloth, bi-level backdrop with a bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7612,
        store_longitude: -73.9606,
        total_orders: 262.69,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Wine, Stylish, Delicious, Beer, Italian, Irish, Fun, Hotel, Friendly, Perfect',
        annual_dol: 1073.77,
        description:
            '"A modern Irish-American sports bar serving cold beers and delicious bar food"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6544,
        store_longitude: -73.9594,
        total_orders: 31.44,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Pizza, Mexican, Beer, Salad, Margarita, Jamaican, Wine, Rum, Caribbean, American',
        annual_dol: 145.8,
        description:
            'Caribbean classics like jerk chicken & tropical cocktails are on offer at this neighborhood spot.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7667,
        store_longitude: -73.9125,
        total_orders: 55.26,
        neighborhood: 'Astoria',
        keywords: 'Wine, Beer, Upscale, Dessert, Premium, Great, Lit',
        annual_dol: 225.89,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6976,
        store_longitude: -73.9241,
        total_orders: 92.41,
        neighborhood: 'Bushwick',
        keywords: 'Coffee, Friendly, Playful, Local, Chill, Unique',
        annual_dol: 377.76,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6511,
        store_longitude: -73.8696,
        total_orders: 4352.74,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Steak, Pizza, Salad, Cajun, Wine, Tasty, Caesar Salad',
        annual_dol: 4451.94,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8263,
        store_longitude: -73.6832,
        total_orders: 137.85,
        neighborhood: '',
        keywords:
            'Pizza, Chicken Parmigiana, Mexican, Salad, Italian, Meatball, Wine, Delicious, Juicy, Warm',
        annual_dol: 150.24,
        description:
            'Stone-fired pizza, homemade pasta & seafood specials are the draw at this laid-back Italian eatery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8416,
        store_longitude: -73.7123,
        total_orders: 496.89,
        neighborhood: '',
        keywords:
            'Mexican, Tequila, Steak, Vegetarian, Margarita, Wine, Peruvian, Tex-Mex, Korean, Bbq',
        annual_dol: 657.82,
        description:
            '"Continuing in the fine tradition of all our Margarita\'s Cafe restaurants, we serve the best in traditional Mexican food, along with some new and unique recipes that will make your mouth water. Our exotic drinks are legendary & you\'ll enjoy your food and drink even more with our live entertainment."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.722,
        store_longitude: -74.0043,
        total_orders: 1035.88,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Vegan, Salad, Milkshake, Tequila, Gluten Free, Coffee, Roast Beef, Margarita, Vodka',
        annual_dol: 1410.16,
        description:
            'Always-open eatery at the SoHo Grand Hotel serving up revamped diner classics with vintage vibes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.719,
        store_longitude: -73.9977,
        total_orders: 175.18,
        neighborhood: 'Little Italy',
        keywords:
            'Pizza, Salad, Italian, Wine, Dessert, Beer, Meatball, Martini, Trendy, Delicious',
        annual_dol: 199.82,
        description:
            '"A trendy Italian restaurant & bar located at 143 Mulberry Street in the heart of historical Little Italy NYC. Handmade Lasagne, Ravioli, Manicotti, Fettucini with fresh-made pizza and a full seating bar with happy hour specials make Sofia\'s a must-visit. Under new management effective December 1, 2021 from Chef Andrea"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7927,
        store_longitude: -73.9407,
        total_orders: 2375.54,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Mexican, Beer, Cuban, Latin, American, Spanish, Delicious, Quaint, Impeccable, Casual',
        annual_dol: 2425.95,
        description:
            'Casual eatery serving Latin dishes and cocktails in a quaint space with exposed-brick walls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7016,
        store_longitude: -73.927,
        total_orders: 508.42,
        neighborhood: 'Bushwick',
        keywords: 'Tequila, Hotdog, Beer, Whiskey, Vodka, Wine, Gin, Rum, Cozy, Yummy',
        annual_dol: 594.02,
        description:
            'Local watering hole turning out beer & hot dogs in a graffiti-scrawled space with kegs for tables.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6575,
        store_longitude: -73.6442,
        total_orders: 38.46,
        neighborhood: '',
        keywords:
            'Italian, Delicious, Friendly, Modern, Perfect, Fusion, American, Authentic, Cool, Caribbean',
        annual_dol: 157.22,
        description: '"Come experience the finest Caribbean fusion restaurant in Rockville Centre"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6095,
        store_longitude: -73.9321,
        total_orders: 452.18,
        neighborhood: 'Marine Park',
        keywords: 'Beer, Great, Terrific, Local, Casual',
        annual_dol: 471.78,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6552,
        store_longitude: -73.8729,
        total_orders: 432.36,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Salad, Wine, Bbq, Caribbean, Vegetarian, French Toast, Vodka, Southern Comfort, Fusion, Spicy',
        annual_dol: 529.63,
        description:
            'Relaxed, contemporary eatery & bar offering Caribbean dishes & creative cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.581,
        store_longitude: -73.848,
        total_orders: 229.8,
        neighborhood: 'Belle Harbor/Far Rockaway/Rockaway Park',
        keywords: 'American, Beer',
        annual_dol: 331.27,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7204,
        store_longitude: -73.9971,
        total_orders: 9336.25,
        neighborhood: 'Little Italy',
        keywords:
            'Champagne, Vodka, Luxurious, Upscale, Delicious, Spicy, Italian, Cozy, Premium, Sweet',
        annual_dol: 9534.35,
        description:
            'Creative cocktails & DJ music in a gold-themed space with a velvet rope out front.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7236,
        store_longitude: -74.0032,
        total_orders: 594.51,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Steak, Italian, Wine, Beer, Rustic, French Onion Soup, French, Organic, Contemporary',
        annual_dol: 611.76,
        description:
            'SoHo outpost of the Williamsburg original with rustic Italian food in a Tuscan-countryside setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7186,
        store_longitude: -74.0048,
        total_orders: 2332.32,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Whiskey, Burger, Vodka, Margarita, Upscale, French, Japanese, American, New Zealand',
        annual_dol: 6044.82,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7201,
        store_longitude: -73.9985,
        total_orders: 67.01,
        neighborhood: 'Little Italy',
        keywords: 'Salad, Coffee, French, Rustic, Casual, Local, Great, Quiche, Signature',
        annual_dol: 273.91,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6559,
        store_longitude: -73.6718,
        total_orders: 2825.04,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Cheesecake, Vegetarian, Wine, Tasty, Italian, Beer, Eclectic, Sleek',
        annual_dol: 5538.71,
        description:
            'Snug, modern lounge offering prime cut steaks, seafood & vegetarian menus, plus happy-hour deals.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8541,
        store_longitude: -73.8541,
        total_orders: 549.2,
        neighborhood: 'Morris Park/Indian Village',
        keywords:
            'Steak, Italian, Shrimp Cocktail, Flavorful, Tangy, Traditional, Perfect, Classic, Fresh, Great',
        annual_dol: 593.04,
        description:
            'Down-home pick for traditional red-sauced Italian standbys served up in hearty portions.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7647,
        store_longitude: -73.9146,
        total_orders: 79.09,
        neighborhood: 'Astoria/LIC',
        keywords: 'Upscale, Trendy, Modern, American, Fun, Amazing, Great, Latin, Fusion, Casual',
        annual_dol: 323.3,
        description: '"Upscale Bar Lounge"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7608,
        store_longitude: -73.9613,
        total_orders: 21.78,
        neighborhood: 'Lenox Hill',
        keywords: 'Burger, Hamburger, Pizza, Salad, Italian, Beer, Vodka, Dessert, Wine, French',
        annual_dol: 82.47,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7476,
        store_longitude: -73.9456,
        total_orders: 167.68,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Garlic Bread, Italian, Wine, Crisp, Romantic, Classy, Clean, Outstanding, American, Classic',
        annual_dol: 513.72,
        description:
            'High-end place offering classic Italian dishes & pastas (with gluten-free choices), plus a patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7485,
        store_longitude: -73.9382,
        total_orders: 225.49,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords:
            'Beer, Mexican, Tomato Soup, Wine, Meatball, Italian, American, Seasonal, Classic, Southern',
        annual_dol: 394.42,
        description:
            'Small, casual restaurant pairing cheese-laden flights & mains with wines & craft beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7388,
        store_longitude: -73.9885,
        total_orders: 1473.94,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Sushi, Japanese, Wine, Italian, Classy, High-End, Sukiyaki, Authentic, Impressive, Casual',
        annual_dol: 4245.41,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7346,
        store_longitude: -73.992,
        total_orders: 75.65,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Thai, Tasty, Delicious, Martini, Prosecco, Fresh, Casual, Mojito, Pad Thai, Great',
        annual_dol: 285.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7264,
        store_longitude: -73.9895,
        total_orders: 74.61,
        neighborhood: 'East Village/Bowery',
        keywords:
            'Lasagna, Italian, Wine, Classy, Friendly, Traditional, Authentic, Casual, Great, Negroni',
        annual_dol: 304.99,
        description:
            'Cozy, cash-only Italian restaurant in the East Village lined with exposed brick.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5518,
        store_longitude: -74.1508,
        total_orders: 15.23,
        neighborhood: 'Great Kills',
        keywords:
            'Steak, Tacos, Mexican, Tex-Mex, Italian, Spanish, Delicious, Asian, Authentic, Casual',
        annual_dol: 69.97,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6396,
        store_longitude: -73.9676,
        total_orders: 431.95,
        neighborhood: 'Flatbush',
        keywords:
            'Burger, Hamburger, Wine, Coffee, Dessert, Italian, Rustic, Peruvian, Seasonal, Delicious',
        annual_dol: 757.3,
        description:
            'New American dishes cooked up from fresh, local ingredients in a rustic setting with a garden.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.643,
        store_longitude: -73.791,
        total_orders: 377.83,
        neighborhood: 'Jamaica',
        keywords: 'Burger, Pizza, Coffee, Turkish, American, Local, Casual',
        annual_dol: 1323.28,
        description:
            '"The Local, an original concept from HMSHost, was designed specifically for airports and marries the freshest ingredients from local farms for menus loaded with region-centric culinary creations. The Local offers both full-service and grab-and-go convenience. It embraces only the finest ingredients and décor to help create an atmosphere unlike any other that gives travelers a true taste of what each region has to offer."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7533,
        store_longitude: -73.974,
        total_orders: 2569.86,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Wine, Tasty, Yummy, Intimate, Casual, Great, Local, Chill',
        annual_dol: 2624.39,
        description:
            'Gay bar near Grand Central known for its outdoor terrace & separate piano bar, plus weekly events.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7525,
        store_longitude: -73.9711,
        total_orders: 208.55,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Italian, Beer, Trendy, Iconic, Classic, Contemporary, Great, Fine',
        annual_dol: 218.98,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7522,
        store_longitude: -73.9741,
        total_orders: 69.61,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Mexican, Steak, Pizza, Burritos, Delicious, Local, Casual, Great',
        annual_dol: 345.83,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.757,
        store_longitude: -73.9672,
        total_orders: 1498.77,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Tacos, Vodka, Sushi, Mexican, Wine, Hamburger, Salad, Delicious, Warm, Fun',
        annual_dol: 1637.1,
        description:
            'This busy pub with private event spaces is part sleek lounge & part burgers-&-wings sports bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8754,
        store_longitude: -73.597,
        total_orders: 2819.38,
        neighborhood: '',
        keywords:
            'Chocolate Croissant, Coffee, Delicious, Comfortable, Avocado Toast, Perfect, Wonderful, Great, Homemade, American',
        annual_dol: 4602.32,
        description:
            '"Good Karma. Great Coffee. Quality Connections. Obsessively curated to serve our community. Farm to cup coffee by Devocion."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.713,
        store_longitude: -74.0097,
        total_orders: 1434.74,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Tacos, Pizza, Champagne, Hotel, Irish, Perfect, Casual, American, Intimate',
        annual_dol: 1953.13,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6285,
        store_longitude: -74.0292,
        total_orders: 21.08,
        neighborhood: 'Bay Ridge',
        keywords: 'Pizza, Chicken Parmigiana, Italian, Salad, Polished, Perfect, Casual',
        annual_dol: 79.21,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7172,
        store_longitude: -73.9017,
        total_orders: 98.11,
        neighborhood: 'Flushing/Maspeth',
        keywords: 'Beer, Trendy, Wine, Mexican, Margarita, Fresh, Irish, American, Great, Swiss',
        annual_dol: 109.16,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7772,
        store_longitude: -73.7276,
        total_orders: 4242.94,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Vegetarian, Shrimp Cocktail, Salad, Delicious, American, Prime Rib, Pot Roast, Caesar Salad',
        annual_dol: 4354.77,
        description:
            'High-quality beef is the focus of this branch of the classic Brooklyn steakhouse. Cash-only.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7444,
        store_longitude: -73.99,
        total_orders: 1468.41,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Burger, Salad, Wine, Beer, Ale, Elegant, Polished, Irish, Friendly, Casual',
        annual_dol: 1506.31,
        description:
            'Polished sports tavern with lots of TV screens & a menu of elevated American pub food.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6921,
        store_longitude: -73.9866,
        total_orders: 843.43,
        neighborhood: 'Downtown Brooklyn/Brooklyn Heights',
        keywords:
            'Burger, Shrimp Cocktail, Coffee, Wine, American, Sleek, Spacious, Casual, Classic, Great',
        annual_dol: 3447.67,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7574,
        store_longitude: -73.9838,
        total_orders: 994.96,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Hotel, Irish, Beer, Delicious, Warm, Premium, High-End, Creamy, Classic, Refined',
        annual_dol: 1091.98,
        description:
            "from the Museum of Modern Art.Elegant rooms with chic furnishings offer free Wi-Fi, flat-screen TVs and en suite, shower-only bathrooms, plus designer toiletries and safes. Studios add sofabeds and sleep up to 3 guests.There's a refined restaurant/bar, a fitness center and conference facilities. Parking and airport transfers are available for a fee.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7567,
        store_longitude: -73.9842,
        total_orders: 1713.67,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Cheesecake, Lobster, Lobster Roll, Salad, Steak, Dessert, Hamburger, Tasty, Italian, Creamy',
        annual_dol: 1753.19,
        description:
            'French fare, including fixed-price meals, in elegant digs with tile floors & red banquettes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.775,
        store_longitude: -73.9135,
        total_orders: 2931.22,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords: 'Sushi, Salad, Wine, Japanese, Beer, Karaoke, Chinese, Spicy, Thai, Rich Finish',
        annual_dol: 5528.93,
        description:
            'Basic spot featuring a variety of sushi-roll choices, lunch specials & happy-hour beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7112,
        store_longitude: -74.0146,
        total_orders: 466.96,
        neighborhood: 'Lower Manhattan',
        keywords: 'American',
        annual_dol: 532.65,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7935,
        store_longitude: -73.6914,
        total_orders: 145.01,
        neighborhood: '',
        keywords: 'Italian, Wine, Pizza, Trendy, Beer, Delicious, Casual, Friendly, Fresh',
        annual_dol: 171.83,
        description:
            '"Chef-owner Sandro Acquista brings simple, quality Italian food to this casual, trendy neighborhood spot in Manhasset. The pastas are made fresh daily, the desserts are made from scratch and everything is made with the highest quality ingredients."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7608,
        store_longitude: -74.0025,
        total_orders: 20.13,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Iranian, Moroccan, Prime Rib, Casual',
        annual_dol: 82.29,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7169,
        store_longitude: -74.0079,
        total_orders: 4038.7,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Wine, Gluten Free, Dessert, Italian, Upscale, Beer, French Onion Soup, French, Ale',
        annual_dol: 4131.61,
        description:
            'Lively bistro with outdoor seating serving French-American food, plus brunch & late-night bites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7723,
        store_longitude: -73.5627,
        total_orders: 1323.44,
        neighborhood: '',
        keywords: 'Beer, Comfortable, Spacious, Friendly, Eclectic, Great, Traditional, Fusion',
        annual_dol: 1424.12,
        description:
            'The NYCB Theatre at Westbury is an entertainment venue located in the hamlet of Jericho, outside of Westbury, New York.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7551,
        store_longitude: -73.5877,
        total_orders: 251.25,
        neighborhood: '',
        keywords:
            'Salad, Shrimp Cocktail, Hotdog, Italian, French Onion Soup, French, Creole, Greek, Homemade, Caribbean',
        annual_dol: 1027.01,
        description:
            'Elegant Caribbean eatery & catering hall offering Sunday brunch as well as tango & salsa nights.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7217,
        store_longitude: -74.01,
        total_orders: 261.75,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, French, Beer, Luxurious, Japanese, Relaxed, Zen, Legendary, Casual, Perfect',
        annual_dol: 1069.97,
        description:
            '"l’abeille is a 54 seat French restaurant located at 412 Greenwich Street on a classic Tribeca cobblestone street corner. The restaurant is centered on a six-course prix-fixe menu but also provides a rotating selection of seasonal a-la-carte dishes with a strong wine and cocktail program. l’abeille is the brainchild of Chef Mitsunobu Nagae, a veteran Michelin-starred chef who has worked at multiple legendary restaurants in Tokyo, Paris, and New York. Chef Mitsu was most recently the Chef de Cuisine at Shun in Midtown Manhattan."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.719,
        store_longitude: -74.0002,
        total_orders: 487.95,
        neighborhood: 'Lower Manhattan',
        keywords: 'Wine, Upscale, Elegant, Trendy, Delicious, Romantic, Cozy, Warm, Hotel, French',
        annual_dol: 562.25,
        description:
            'Refined French fare, such as lobster, rabbit, duck & foie gras, served in an airy, elegant setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7215,
        store_longitude: -74.0063,
        total_orders: 4731.92,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Delicious, Trendy, Awesome, Premium, Great, Rooftop, Hip, Cool, Attentive',
        annual_dol: 5276.55,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7046,
        store_longitude: -74.0098,
        total_orders: 2533.69,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Vegetarian, Lobster, Pizza, Salad, Shrimp Cocktail, Italian, Rich, Wine',
        annual_dol: 2757.74,
        description:
            'Popular throwback in the historical India House with steaks, a wine cellar & an adjoining cafe.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7636,
        store_longitude: -73.6469,
        total_orders: 939.25,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Wine, Peruvian, Warm, Classy, Southern, Exquisite, Casual',
        annual_dol: 939.25,
        description:
            'No-nonsense but popular Italian trattoria serving up a large menu of family-style platters.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6574,
        store_longitude: -73.6469,
        total_orders: 3148.18,
        neighborhood: '',
        keywords: 'Salad, Sushi, Mexican, Japanese, Tangy, Ramen, Bbq, Modern, Yummy, Casual',
        annual_dol: 4904.28,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7575,
        store_longitude: -73.998,
        total_orders: 4276.79,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Salad, Italian, Mediterranean, Cool, Casual, Great, Recommended, Excellent',
        annual_dol: 5483.71,
        description:
            'Richly designed space for Mediterranean crudos, pastas & roasted dishes, with a cafe & bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7545,
        store_longitude: -73.9926,
        total_orders: 320.96,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Relaxed, Dynamic',
        annual_dol: 476.02,
        description:
            "Bright, relaxed rooms offer free Wi-Fi, flat-screen TVs and coffeemakers.There's an informal restaurant with a bar. Other amenities include a lobby lounge and a terrace, as well as a 24-hour gym, a business center and meeting space.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7435,
        store_longitude: -73.6142,
        total_orders: 2098.76,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Salad, Cajun, Italian, Beer, Caesar Salad, American, Kung Pao, Warm',
        annual_dol: 2152.46,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7434,
        store_longitude: -73.6148,
        total_orders: 863.72,
        neighborhood: '',
        keywords: 'Pizza, Italian, Vodka, Perfect, Fresh, Great, Casual, Fine, Local',
        annual_dol: 883.5,
        description:
            'A Manhattan Little Italy transplant & bustling old-school spot for fresh seafood & red-sauce pasta.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7465,
        store_longitude: -73.9187,
        total_orders: 1070.06,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Burger, Pizza, Beer, Wine, Dessert, American, Upscale, Bbq, Delicious, Quaint',
        annual_dol: 1096.07,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7198,
        store_longitude: -74.0084,
        total_orders: 4546.24,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Buttermilk Biscuits, Salad, Organic, American, Delicious, Casual, Perfect, Traditional, Classic',
        annual_dol: 6800.68,
        description:
            'Weekend brunch hot spot serving homestyle American eats with many locally sourced ingredients.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7191,
        store_longitude: -74.0101,
        total_orders: 545.68,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Chocolate Cake, Cheesecake, Chicken Parmigiana, Gluten Free, Italian, Apple Pie, Tasty, Velvety, Veal Parmigiana, Impeccable',
        annual_dol: 557.26,
        description:
            'Elegantly appointed, well-known restaurant focusing on sizable plates of Italian favorites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7258,
        store_longitude: -74.0081,
        total_orders: 172.74,
        neighborhood: 'Hudson Square',
        keywords: 'Fun, Sweet, Great, Fresh, Artisanal, Fine, Casual, Signature',
        annual_dol: 176.4,
        description: '"Look for the chocolate..one bar will lead you to another!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7251,
        store_longitude: -74.0079,
        total_orders: 144.77,
        neighborhood: 'Hudson Square',
        keywords:
            'Burger, Hamburger, Pizza, Cheesesteak, Salad, Beer, Juicy, Asian, American, Casual',
        annual_dol: 208.89,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.708,
        store_longitude: -73.9216,
        total_orders: 1067.05,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Dessert, Karaoke, Chicken Wings, Fun, American, Indulgent, Awesome, Classic, Great',
        annual_dol: 1185.44,
        description:
            'Laid-back restaurant & bar specializing in chicken wings & burgers with fries, plus cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.654,
        store_longitude: -73.6715,
        total_orders: 301.74,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Wine, Delicious, Homemade, Great, Local, Casual, Fine, Signature',
        annual_dol: 314.82,
        description:
            '"At Savino’s, all our dishes are homemade and prepared with the freshest quality meats, fish, produce and ingredients imported from Italy. Try one of our many specialty pizza’s, appetizers and entrees prepared by the most qualified kitchen staff in the neighborhood. We cater parties of all sizes in our dining room and for delivery anywhere in the local neighborhood. Our pre-designed menus allow you to plan delicious dining for your event. Simply tell us how many people you’re expecting and we’ll handle the rest. Live entertainment is available almost every week. See our calendar to find out who’s playing on Thursday and/or Saturday nights. Savino’s hires the best performers on Long Island to deliver a performance of the highest quality."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5738,
        store_longitude: -74.1157,
        total_orders: 394.79,
        neighborhood: 'New Dorp',
        keywords:
            'Salad, Italian, Wine, Shrimp Cocktail, Coffee, Delicious, Spicy, Upscale, Rich, Elegant',
        annual_dol: 403.16,
        description:
            'Italian standards & an extensive wine list are served in an elegant setting at this longtime eatery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7235,
        store_longitude: -73.5703,
        total_orders: 887.78,
        neighborhood: '',
        keywords: 'Beer, Burger, Whiskey, Cajun, Wine, Ale, Spicy, Korean, Dessert, Bbq',
        annual_dol: 906.62,
        description:
            'Sports-bar chain with a casual menu of burgers, ribs & a variety of brews, plus plenty of TVs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.613,
        store_longitude: -73.9125,
        total_orders: 155.92,
        neighborhood: 'Mill Basin',
        keywords: 'Margarita, Clean, Fun, Bbq, Authentic, Modern, Great, Casual, Bold',
        annual_dol: 159.23,
        description:
            'Family-operated bowling alley offers various arcade games, a full-service snack bar & private rooms.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7459,
        store_longitude: -73.6,
        total_orders: 912.58,
        neighborhood: '',
        keywords:
            'Sushi, Vodka, Salad, Martini, Gluten Free, Coffee, Tasty, Japanese, Sashimi, Friendly',
        annual_dol: 1033.22,
        description: 'Longtime Japanese sushi & hibachi house with kid-friendly chef performances.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6194,
        store_longitude: -73.9331,
        total_orders: 30.36,
        neighborhood: 'Flatlands',
        keywords: 'Beer, Comfortable, Wine, Friendly, Casual, Local, American, Great',
        annual_dol: 82.82,
        description:
            'Local hangout featuring sports on TV, darts tournaments & a familiar lineup of beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6498,
        store_longitude: -73.8709,
        total_orders: 3965.61,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Salad, Steak, Hamburger, Chicken Caesar Salad, Dessert, Italian, Beer, New-York-Style, Mediterranean',
        annual_dol: 4061.09,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7222,
        store_longitude: -74.0036,
        total_orders: 563.59,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Mexican, Tequila, Margarita, Wine, Rustic, Classic, Fun, Latin, Authentic, Traditional',
        annual_dol: 574.4,
        description:
            'This casual, rustic cantina delivers classic Mexican fare & potent margaritas.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7042,
        store_longitude: -74.0102,
        total_orders: 2069.61,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Italian, Salad, Beer, Wine, Delicious, Caesar Salad, Homemade, Fresh, Authentic',
        annual_dol: 2635.89,
        description:
            'Casual pizzeria with outdoor seating on the historic, cobbled Stone Street offering thin-crust pies.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6604,
        store_longitude: -73.8404,
        total_orders: 749.83,
        neighborhood: 'Howard Beach',
        keywords:
            'Vegetarian, Pizza, Italian, Wine, Rustic, Southern, Casual, Polished, Classy, Contemporary',
        annual_dol: 782.53,
        description:
            'Classy Italian eatery with brick-oven pies, pasta dishes, a curated wine list & contemporary decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7576,
        store_longitude: -73.6398,
        total_orders: 596.09,
        neighborhood: '',
        keywords:
            'Wine, Trendy, Italian, American, Chicken Wings, Classy, Comfortable, Amazing, Great, Fresh',
        annual_dol: 621.94,
        description:
            '"Willistons\' is a restaurant and bar now open in Williston Park, NY. Come in to enjoy our creative menus, inspired cocktails and well planned collection of wines and craft beers."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7106,
        store_longitude: -73.8506,
        total_orders: 209.09,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Korean, Beer, Bbq, Asian, Clean, Fried Chicken, Natural, Premium, Fresh, Casual',
        annual_dol: 732.32,
        description:
            '"Pelicana Chicken prepares FRESH, ALL-NATURAL, NON-GMO, 100% VEGGIE-FED, USDA VERIFIED, CAGE-FREE chickens in FRESH, NEW OIL in a clean sanitized kitchen, DAILY! Our chickens are cleaned, cut, and seasoned daily to deliver the freshest, juiciest, and tastiest chicken to our customers. In addition, we offer 50 + variations of chicken, so that our customers can taste a variety and have plenty to choose from."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6108,
        store_longitude: -73.9628,
        total_orders: 8.74,
        neighborhood: 'Midwood',
        keywords: 'Wine, Beer, Hotel, Legendary, Rare, Casual, Incredible',
        annual_dol: 35.74,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7525,
        store_longitude: -73.9829,
        total_orders: 955.44,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Steak, Pizza, Salad, Tasty, Italian, Wine, Delicious, Stylish, Vodka',
        annual_dol: 2596.57,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6609,
        store_longitude: -73.629,
        total_orders: 435.95,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Vegan, Beer, Bbq, Tasty, American, Contemporary, Friendly, Casual',
        annual_dol: 445.43,
        description:
            'Stylish, contemporary restaurant serving burgers, rotisserie fare, cocktails & craft beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7168,
        store_longitude: -73.535,
        total_orders: 28.33,
        neighborhood: '',
        keywords: 'American',
        annual_dol: 115.8,
        description:
            '"LAST VETERAN HALL OF EAST MEADOW, FOR HONORABLY DISCHARGED VETERANS AND THEIR FAMILIES; PUBLIC CAN RENT OUR HALL GIVE A CALL TO OIR NUMBER FOR MORE DETAILS AND RESERVE YOUR DATE NEEDS"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7063,
        store_longitude: -73.9228,
        total_orders: 748.88,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Vodka, Beer, Margarita, Signature, Great, Classic, Casual',
        annual_dol: 1268.24,
        description:
            'Patrons line up into the wee hours to snag signature artichoke pizza from this counter-service spot.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6994,
        store_longitude: -73.9138,
        total_orders: 214.73,
        neighborhood: 'Bushwick',
        keywords: 'Burger, Coffee, Beer, American, Friendly, Hip, Cool, Casual, Fusion, Local',
        annual_dol: 250.29,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7293,
        store_longitude: -73.6357,
        total_orders: 900.7,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, Homemade, Classy, Retro, Sweet, Fantastic, Perfect',
        annual_dol: 965.98,
        description:
            'Casual, Brooklyn-based pizzeria chain serving brick-oven pies & calzones, plus wine & beer.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7417,
        store_longitude: -73.6401,
        total_orders: 58.15,
        neighborhood: '',
        keywords: 'Tacos, Steak, Mexican, Beer, Nachos, Wine, Trendy, American, Homemade, Latin',
        annual_dol: 149.84,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7078,
        store_longitude: -73.9318,
        total_orders: 145.49,
        neighborhood: 'East Williamsburg',
        keywords:
            'Mexican, Margarita, Nachos, Upscale, Spicy, Delicious, Cozy, Yummy, Paloma, Classy',
        annual_dol: 511.95,
        description:
            '"Bushwick Newcomer Paloma’s BK Offers a Bold New Regional Mexican Menu Expect dreamy tortillas, regional tacos, aguachiles, and so much more"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7075,
        store_longitude: -73.6785,
        total_orders: 105.65,
        neighborhood: '',
        keywords:
            'Italian, Steak, Pizza, Upscale, Savory, Cozy, Exquisite, Authentic, Impressive, Exceptional',
        annual_dol: 225.19,
        description:
            'Family-owned restaurant open more than 30 years serves Italian cuisine in an intimate, cozy space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6536,
        store_longitude: -73.5551,
        total_orders: 101.36,
        neighborhood: '',
        keywords:
            'Burger, Steak, Salad, Italian, Beer, Wine, Margarita, Delicious, Bourbon, Homemade',
        annual_dol: 236.66,
        description:
            '"One of the best Italian American restaurants in Merrick that serves fresh ingredients and homemade desserts coupled with fine wines and crafted mixed drinks. All in effort to make your dinning experience one to be remembered."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8395,
        store_longitude: -73.8636,
        total_orders: 63.62,
        neighborhood: 'East Bronx/Parkchester',
        keywords:
            'Dominican, Mexican, Bbq, Caribbean, Chinese, Puerto Rican, American, Latin, Delicious, Traditional',
        annual_dol: 260.05,
        description:
            'Colorful, family-owned eatery for traditional style Dominican plates in low-key surrounds.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7499,
        store_longitude: -73.9843,
        total_orders: 1616.02,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Korean, Salad, Upscale, Bbq, Delicious, Contemporary, Modern, Classy, Legendary, Traditional',
        annual_dol: 1665.96,
        description:
            'Sophisticated spot for Korean BBQ, shareable plates & upscale mains, plus bar snacks & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8365,
        store_longitude: -73.7018,
        total_orders: 247.49,
        neighborhood: '',
        keywords: 'Italian, Steak, Pizza, Upscale, Cozy, Intimate, Excellent, Great, Local, Fine',
        annual_dol: 252.74,
        description:
            'Upscale fixture draws crowds with Northern Italian classics & an extensive list of daily specials.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7464,
        store_longitude: -73.9178,
        total_orders: 8.8,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Hotel, Irish, Fancy, Fun, Clean, Classic, Wonderful, Cool, Great, Traditional',
        annual_dol: 35.97,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7966,
        store_longitude: -73.92,
        total_orders: 445.37,
        neighborhood: 'Randalls Island',
        keywords: 'Vegan, American, Urban, Local, Fresh, Spectacular, Great, Tradition',
        annual_dol: 1820.54,
        description:
            '480-acre urban park features restored wetlands, 90+ athletic fields, a community farm & recreation.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8191,
        store_longitude: -73.9268,
        total_orders: 3561.74,
        neighborhood: 'West Bronx',
        keywords:
            'Cuban, Wine, Delicious, Spacious, Fun, Spanish, Caribbean, Vibrant, Amazing, Colorful',
        annual_dol: 3968.81,
        description:
            'A menu featuring classic Cuban & Latin fare in a modern setting with ample bar & patio seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6518,
        store_longitude: -73.8378,
        total_orders: 2334.21,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Sushi, Pizza, Salad, Mexican, Shrimp Cocktail, Beer, Japanese, Chinese, Asian, Fusion',
        annual_dol: 2499.53,
        description:
            'Hibachi dinners & Asian-fusion bites are served in a lively waterfront bistro, lounge & sushi bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7606,
        store_longitude: -73.9863,
        total_orders: 496.68,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Salad, Wine, Dessert, Beer, Japanese, Whiskey, Spanish, Spicy, Delicious',
        annual_dol: 715.1,
        description:
            'Asian fusion joint in stylish digs serving creative sushi rolls, Vietnamese noodles & bento lunches.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7768,
        store_longitude: -73.9523,
        total_orders: 1434.32,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Cheesecake, Tacos, Canadian, Spicy, New-York-Style, Italian, Funky, Warm, English Muffin, Friendly',
        annual_dol: 1616.27,
        description: '"Modern American Tavern"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7333,
        store_longitude: -73.737,
        total_orders: 35.27,
        neighborhood: 'Jamaica/Bellerose Manor/Queens Village',
        keywords:
            'Burger, Tacos, Cheesesteak, Mexican, Wine, Clean, Bbq, Friendly, Philly Cheesesteak, American',
        annual_dol: 37.53,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.656,
        store_longitude: -74.0068,
        total_orders: 2805.55,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Sushi, Salad, Japanese, Delicious, Trendy, Thai, Sashimi, Romantic, Ramen, Fun',
        annual_dol: 3808.48,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7867,
        store_longitude: -73.9505,
        total_orders: 388.2,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Hotdog, Beer, Wine, Rum, Irish, Hotel, Modern, Campari, Comfortable, Local',
        annual_dol: 405.03,
        description:
            '"A modern pub serving local New York beer, cocktails, and wine in a comfortable setting, with small bites."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7217,
        store_longitude: -74.0013,
        total_orders: 1056.42,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords:
            'Steak, Chicken Parmigiana, Meatball Sub, Meatball, Italian, Salad, Wine, Spicy, Beer, Caesar Wrap',
        annual_dol: 1078.83,
        description:
            'Traditional Italian fare & craft cocktails offered in an exposed-brick dining room with a sky light.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7964,
        store_longitude: -73.9366,
        total_orders: 404.98,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Jamaican, Wine, Rum, Hip, Lit, Creative, Cool, Casual',
        annual_dol: 1486.96,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7768,
        store_longitude: -73.957,
        total_orders: 275.06,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Wine, Tasty, Romantic, French, Classy, Polished, Friendly, Homemade, Casual, Classic',
        annual_dol: 355.32,
        description:
            'Swanky restaurant featuring classic French staples & wine in upmarket surroundings.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6542,
        store_longitude: -73.953,
        total_orders: 11.68,
        neighborhood: 'Little Haiti',
        keywords: 'Vegan, Tequila, Tacos, Mexican, Steak, Clean, Trendy, Casual, Latin, American',
        annual_dol: 47.74,
        description: '"A Taco & Tequila Bar In Flatbush Paying Homage To Afro-Mexicans."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6746,
        store_longitude: -74.0007,
        total_orders: 341.9,
        neighborhood: 'Red Hook',
        keywords: 'Pizza, Meatball, Spicy, Wine, Modern, Classy, Fun, Seasonal, Local, Great',
        annual_dol: 508.09,
        description:
            'Brick-&-mortar offshoot of mobile eatery, serving Neapolitan-style pies, small plates & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5873,
        store_longitude: -73.7394,
        total_orders: 177.9,
        neighborhood: '',
        keywords: 'Burger, Salad, French, Fun, Friendly, Perfect, Terrific, Exceptional',
        annual_dol: 348.79,
        description:
            '""Decades of fun in the sun have taken place at Sunny Atlantic Beach Club. Our event venue, Atrium is perfect for a sunset wedding on the beach or exceptional family celebration. Long Island’s only retractable glass enclosure facing the Atlantic Ocean insures your experience continues in comfort while you enjoy a perfect view of the endless Atlantic Ocean.""',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6356,
        store_longitude: -74.1352,
        total_orders: 5.44,
        neighborhood: 'Port Richmond',
        keywords: 'Mexican, Turkish, Delicious, Premium, Casual',
        annual_dol: 22.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.675,
        store_longitude: -73.9992,
        total_orders: 516.44,
        neighborhood: 'Carroll Gardens',
        keywords: 'Wine, Beer, Vodka, Coffee, Gin, Trendy, Delicious, Prosecco, Casual, Fresh',
        annual_dol: 842.1,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6372,
        store_longitude: -73.929,
        total_orders: 1595.71,
        neighborhood: 'East Flatbush',
        keywords: 'Lobster, Salad, Jamaican, Caribbean, Bbq, Contemporary, Sweet, Local, Casual',
        annual_dol: 3401.14,
        description:
            'Jamaican-style seafood boils & platters, plus salads & sides, presented in a contemporary space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7235,
        store_longitude: -73.9885,
        total_orders: 1283.13,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Wine, Luxurious, Beer, Rum, Lively, Premium, Clean, Great, Hip, Fusion',
        annual_dol: 1313.01,
        description:
            'DJ-spun tunes & happy-hour deals keep patrons content at this longtime lounge.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6435,
        store_longitude: -73.9427,
        total_orders: 38.52,
        neighborhood: 'Little Caribbean/East Flatbush',
        keywords: 'Tasty, Margarita, Savory, Delicious, Spicy, Creole, Caribbean, Casual',
        annual_dol: 104.69,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5443,
        store_longitude: -74.1407,
        total_orders: 1928.18,
        neighborhood: 'Great Kills',
        keywords:
            'Lobster, Wine, Vodka, Gluten Free, Spicy, Italian, Upscale, Picturesque, American, Perfect',
        annual_dol: 2098.65,
        description:
            'Iconic restaurant, bar & banquet hall with scenic views serving upscale Italian & American meals.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5848,
        store_longitude: -74.0936,
        total_orders: 378.48,
        neighborhood: 'Midland Beach',
        keywords:
            'Mexican, Meatball, Delicious, Asian, Colorful, Authentic, Casual, Local, Attentive, Fine',
        annual_dol: 535.12,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.729,
        store_longitude: -73.9871,
        total_orders: 204.45,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Beef Stroganoff, Shrimp Cocktail, Dessert, Wine, Warm, Homemade, Casual, Fresh, Great',
        annual_dol: 835.74,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7597,
        store_longitude: -73.7692,
        total_orders: 2805.04,
        neighborhood: 'Bayside/Flushing',
        keywords: 'Steak, Hamburger, Salad, Wine, Dessert, Beer, Ale, Caesar Salad, Sleek, Modern',
        annual_dol: 2871.02,
        description:
            'Modern pool hall with 21 tables & a sleek lounge vibe with couches, a fire pit & comfort food.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7538,
        store_longitude: -73.9675,
        total_orders: 96.8,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Shrimp Cocktail, Pizza, Italian, Beer, Veal Parmigiana, Southern, Casual, Exquisite, Fresh',
        annual_dol: 395.68,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6299,
        store_longitude: -74.0286,
        total_orders: 34.87,
        neighborhood: 'Bay Ridge',
        keywords: 'Beer, Wine, Cozy, Friendly, Perfect, Great, Local, Casual, Chill',
        annual_dol: 46.16,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7592,
        store_longitude: -73.9614,
        total_orders: 652.57,
        neighborhood: 'Midtown Manhattan/Sutton Place/Midtown East',
        keywords:
            'Salad, Italian, Wine, Martini, Crisp, Friendly, Latin, Seasonal, Caesar Salad, Fun',
        annual_dol: 748.17,
        description:
            'Inventive Italian cafe with small & large plates, vibrant Pop Art decor & a patio with bridge views.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.757,
        store_longitude: -73.9681,
        total_orders: 742.31,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Burger, Lobster, Sushi, Steak, Japanese, Spicy, Modern, Premium, Local, Casual',
        annual_dol: 3034.33,
        description:
            '"Wagyu Social is created by a team of NYC Wagyu Lovers and chefs, working with importers of the best Japanese Wagyu beef. It is our mission to bring the best authentic Japanese A5 Wagyu burgers to New York City, the best city in the world. Long live NYC, long live Wagyu."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7565,
        store_longitude: -73.9719,
        total_orders: 1309.23,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Upscale, Stylish, Hotel, Elegant, Spacious, Cozy, Clean, Premium, Gin, Great',
        annual_dol: 1884.3,
        description:
            'Decorated in conservative colors, upscale rooms and suites have custom pillows, marble baths, kitchenettes and 24-hour room service, plus flat-screen TVs, iPod docks and Wi-Fi; some suites have private outdoor terraces.A stylish restaurant serves a modern bistro menu. There’s a 24-hour gym and a salon, as well as a business center and a clubby lounge. Valet parking is available for a fee.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7407,
        store_longitude: -73.982,
        total_orders: 641.75,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Burger, Beer, Tasty, Wine, Delicious, Ale, Irish, Homemade, American, Friendly',
        annual_dol: 686.45,
        description:
            'Genial Irish pub offering casual American & Irish food, including brunch, plus TV sports & trivia.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6902,
        store_longitude: -73.954,
        total_orders: 2586.31,
        neighborhood: 'Bedford-Stuyvesant',
        keywords: 'Vegetarian, Perfect, Casual, American, Local',
        annual_dol: 10572,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6907,
        store_longitude: -73.9695,
        total_orders: 1473.42,
        neighborhood: 'Clinton Hill',
        keywords:
            'Pizza, Italian, Sushi, Japanese, Wine, Colorful, Friendly, Fresh, Casual, Classic',
        annual_dol: 2064.18,
        description:
            'Pizza from a wood-burning brick oven plus other Italian classics at s family-owned spot.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7596,
        store_longitude: -73.9897,
        total_orders: 1253.42,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Champagne, Spicy, Exhilarating, Phenomenal, Fun, Exotic, Classy, Premium, Memorable, French',
        annual_dol: 1361.15,
        description:
            '"Over three decades ago… FlashDancers created a sensation by introducing the scintillating LAP DANCE to New York City. Today, we continue to provide pulse-pounding entertainment at the crossroads of the world, rightfully earning our reputation as the most popular, exhilarating adult gentlemen\'s club in NYC. The secret to our success: the most beautiful and friendly entertainers in Manhattan, an incredible VIP waitstaff and a phenomenal management team. FlashDancers prides itself on our consistency and reputation as the #1 gentlemen\'s club in New York and one of the most famous strip clubs in the world."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7524,
        store_longitude: -73.9996,
        total_orders: 987.77,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Coffee, Nutritious, Delicious, Australian, Healthy, Premium, Welcoming, Natural, Fresh',
        annual_dol: 3224.85,
        description:
            '"Bluestone Lane is an Australian-inspired coffee shop, café & lifestyle brand committed to providing a genuine daily escape for all our locals. We offer premium coffee and healthy and delicious eats through an always welcoming experience that embraces our ‘Aussie’ approach to life."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7535,
        store_longitude: -73.9999,
        total_orders: 2539.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Wine, Dessert, Upscale, Premium, American, Classic, Modern, Sweet, Approachable',
        annual_dol: 3167.48,
        description:
            'A modern American menu is served in an upscale setting with an open kitchen & curated artwork.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7487,
        store_longitude: -73.987,
        total_orders: 614.75,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Vegetarian, Chinese, Wine, Beer, Peking Duck, Bbq, Delicious, Creamy, Sweet, Traditional',
        annual_dol: 1584.13,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6568,
        store_longitude: -73.6454,
        total_orders: 126.69,
        neighborhood: '',
        keywords:
            'Garlic Bread, Salad, Pizza, Italian, Delicious, Rich, Homemade, Southern, Casual, Perfect',
        annual_dol: 143.6,
        description:
            'Italian restaurant with red-checkered tablecloths serving pasta, panini & pizza made in a coal oven.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7252,
        store_longitude: -73.5869,
        total_orders: 1648.46,
        neighborhood: '',
        keywords:
            'Hotel, Luxurious, Steak, Comfortable, Beer, Clean, Amazing, Great, Fantastic, Casual',
        annual_dol: 4035.57,
        description:
            "Unfussy rooms feature Wi-Fi access, flat-screen TVs, desks and coffeemakers; some have access to a lounge with free continental breakfast, snacks and drinks.Parking is provided at no additional cost. There's a casual restaurant, a lobby lounge and a sports bar. There's also an indoor pool, a spa, racquetball courts and a 24/7 fitness center. Other amenities consist of 17 meeting rooms, a business center and guest laundry facilities.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7051,
        store_longitude: -74.0052,
        total_orders: 3210.87,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Lobster, Steak, Vegetarian, Salad, Italian, Gluten Free, American, Delicious, Modern',
        annual_dol: 3860.71,
        description:
            'Industrial-chic New American restaurant offering wood-fired pizzas & mains, plus a river view.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.616,
        store_longitude: -73.9298,
        total_orders: 47.25,
        neighborhood: 'Marine Park/Flatlands',
        keywords:
            'Vegetarian, Vegan, Chinese, Wine, Bbq, Casual, Friendly, Asian, Yummy, Excellent',
        annual_dol: 193.15,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7441,
        store_longitude: -73.6187,
        total_orders: 191.64,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Gourmet, Beer, Whiskey, Tasty, Dessert, American, Delicious',
        annual_dol: 783.37,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6284,
        store_longitude: -74.0742,
        total_orders: 445.54,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Steak, Pizza, Whiskey, Wine, Italian, Phenomenal, Crisp, American, Perfect, Innovative',
        annual_dol: 606.52,
        description:
            '"Brought to you by the Rallo Hospitality Group, Navy Pier brings elevated New American cuisine to a space unlike any other on Staten Island. From our carefully curated menu, developed by Michelin Starred Executive Chef, Josh Laurano; to our innovative cocktail and spirits program; to our sweeping views of the Verrazano Bridge, New York Harbor, the Statue of Liberty, Manhattan & Jersey City, your experience here will be one to remember."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5943,
        store_longitude: -74.0988,
        total_orders: 131.21,
        neighborhood: 'Dongan Hills/Todt Hill',
        keywords:
            'Sushi, Salad, Spicy, French Toast, Japanese, French, Rich, Ramen, Friendly, Casual',
        annual_dol: 536.32,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7552,
        store_longitude: -73.9874,
        total_orders: 2961.15,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Steak, Hamburger, Cheesesteak, Beer, American, Nachos, Wine, Korean, Bbq',
        annual_dol: 14462.75,
        description:
            'High-end sports-bar chain with a huge menu of New American fare & an extensive list of draft beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7546,
        store_longitude: -73.9897,
        total_orders: 1408.42,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Tequila, Steak, Beer, Cheesesteak, Gin, Irish, American, Homey, Warm, Classic',
        annual_dol: 1491.01,
        description:
            '"Prohibition 39 - NYC\'s Newest American Restaurant Concept featuring 1st Floor Dining, Bar & Lounge, 2nd Floor Event, Performance Space, Restaurant Lounge & Private Dining & Rooftop Speakeasy & Dining Experience. Opening Late August 2022, visit our site to sign up for updates, exclusive invitations to the pre-opening gala & information"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7532,
        store_longitude: -73.993,
        total_orders: 3281.84,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Salad, Beer, Irish, Warm, Welcoming, Casual, Classic, Traditional, Local',
        annual_dol: 3351.48,
        description:
            'Drinks & comfort food in a spacious, bi-level tavern with a warm, traditional atmosphere & 3 bars.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6504,
        store_longitude: -73.8704,
        total_orders: 1474.84,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Lobster, Chocolate Cake, Zombie, Steak, Hamburger, Beer, Margarita, Wine, Chicken Wings, Korean',
        annual_dol: 5556.72,
        description:
            'Family-friendly chain offering a sports-bar-style setting for American food & arcade games.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7526,
        store_longitude: -73.9851,
        total_orders: 5331.25,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Rich, Avocado Toast, Hotel, Delicious, American, Classic, Vintage, Exotic, Global, Great',
        annual_dol: 5626.91,
        description:
            'American dining & creative cocktails set against a vintage backdrop in the Refinery Hotel.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7536,
        store_longitude: -73.9913,
        total_orders: 2186.43,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Beer, Salad, American, Hotel, Delicious, Vintage, Eclectic, Friendly, Casual',
        annual_dol: 3698.58,
        description:
            'Unique tri-level haunt with a vintage vibe serving elevated pub plates & original craft cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7207,
        store_longitude: -73.9969,
        total_orders: 123.32,
        neighborhood: 'Nolita',
        keywords:
            'Burger, Lobster, Lobster Roll, Tacos, Vegetarian, Gluten Free, Meatball, Tasty, Beer, Wine',
        annual_dol: 151.06,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.794,
        store_longitude: -73.9344,
        total_orders: 1142.52,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Salad, Meatball, Italian, Gourmet, Old Fashioned, Southern, Tradition, Classic, Fine',
        annual_dol: 1166.76,
        description:
            'VIPs fill the dining room of this 10-table Italian icon, where who you know is key to entry.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7178,
        store_longitude: -73.998,
        total_orders: 541.06,
        neighborhood: 'Little Italy',
        keywords:
            'Lasagna, Wine, Italian, Vodka, Meatball, Spicy, Elegant, Cozy, Polished, Perfect',
        annual_dol: 1214.09,
        description:
            'Cozy Little Italy mainstay featuring pasta, fish & old-world ambiance, plus a back garden patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8605,
        store_longitude: -73.6222,
        total_orders: 83.2,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Meatball, Peruvian, Warm, Polished, Comfortable, American, Phenomenal',
        annual_dol: 176.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7509,
        store_longitude: -73.9858,
        total_orders: 592.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Salad, Chicken Caesar Salad, Hotel, Modern, Caesar Salad, Comfortable, Eclectic, Vibrant, Friendly',
        annual_dol: 632.12,
        description:
            'The streamlined rooms feature free Wi-Fi, 42-inch flat-screen TVs, minifridges and coffeemakers. They also come with separate living and sleeping areas. Upgraded rooms include city views.Amenities include a fitness center, a 24/7 cafe and a cocktail bar, as well as a business center and meeting rooms.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8195,
        store_longitude: -73.8177,
        total_orders: 1720.57,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Pizza, Shrimp Cocktail, Italian, Margarita, Delicious, Rooftop, Fun, Healthy, American, Perfect',
        annual_dol: 4200.41,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7057,
        store_longitude: -74.0086,
        total_orders: 457.04,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Elegant, Stylish, Luxurious, Rich, Perfect, Southern, Classic, Great, Fine, Casual',
        annual_dol: 466.73,
        description:
            '"63 Wall Street combines two of the finest buildings in Lower Manhattan to provide a New York living experience unlike any other. Nestled in the heart of Manhattan\'s historic Financial District, 63 Wall Street apartments are unique in their perfect harmony of classic form and modern construction. With our preserved historical significance and prestigious location, 63 Wall Street offers a classic and luxurious Financial District apartment rental experience."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8359,
        store_longitude: -73.701,
        total_orders: 392.3,
        neighborhood: '',
        keywords:
            'Vegetarian, Mexican, Chicken Tikka Masala, Turkish, Spicy, Delicious, Indian, Creamy, Crisp, Modern',
        annual_dol: 417.64,
        description:
            'Roomy Indian eatery with a traditional menu, known for its wide-ranging lunch buffet.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7563,
        store_longitude: -73.9944,
        total_orders: 27.14,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Sushi, Salad, Spicy, Delicious, Hotel, American, Greek, Asian, Sweet',
        annual_dol: 110.93,
        description:
            'Kosher sushi & small plates provided in a rooftop destination featuring a bar & city views.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8243,
        store_longitude: -73.6823,
        total_orders: 1982.57,
        neighborhood: '',
        keywords:
            'Burger, Steak, Salad, Chicken Caesar Salad, Swiss, Beer, Wine, Irish, French Onion Soup, French',
        annual_dol: 2024.64,
        description:
            'Easygoing Irish pub serves typical grub in a homey, denlike setting & hosts karaoke at the bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8251,
        store_longitude: -73.6825,
        total_orders: 44.18,
        neighborhood: '',
        keywords: 'Burger, Steak, Tequila, Mexican, Beer, Wine, Bbq, French, Fun, Sweet',
        annual_dol: 180.61,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7436,
        store_longitude: -73.6126,
        total_orders: 5228.85,
        neighborhood: '',
        keywords:
            'Steak, Salad, Wine, Chilean, Spicy, Upscale, Brazilian, American, Friendly, Southern',
        annual_dol: 6968.9,
        description:
            'Upscale Brazilian chain for all-you-can-eat meat carved tableside plus an extensive salad bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6566,
        store_longitude: -73.626,
        total_orders: 2653.71,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Whiskey, Beer, Cajun, American, Relaxed, Clean, Casual, Coleslaw',
        annual_dol: 2710.02,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6653,
        store_longitude: -73.7217,
        total_orders: 162.6,
        neighborhood: '',
        keywords:
            'Salad, Lobster, Italian, Pizza, Tasty, Spicy, Delicious, Homemade, Pinacolada, Irresistible',
        annual_dol: 177.21,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6596,
        store_longitude: -73.6447,
        total_orders: 66.28,
        neighborhood: '',
        keywords: 'Beer, Martini, Jamaican, Wine, Rum, Caribbean, Spicy, Bbq, Upscale, Painkiller',
        annual_dol: 83.24,
        description:
            'This snug yet colorful little eatery is known for lively reggae nights & upmarket Caribbean fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7067,
        store_longitude: -74.009,
        total_orders: 1874.25,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hamburger, Savory, Irish, Upscale, Amazing, Local, Casual, Great, Fine, Unique',
        annual_dol: 6662.31,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8684,
        store_longitude: -73.6316,
        total_orders: 229.31,
        neighborhood: '',
        keywords:
            'Sushi, Salad, Spicy, Delicious, Chinese, Sweet, Hawaiian, Japanese, Asian, Spanish',
        annual_dol: 244.55,
        description:
            '"Come and enjoy a night out with delicious Asian Fusion Restaurant at Sweet Mandarin Bistro. We offer a variety of delicious dishes, with a focus on quality and freshness. We offer an extensive sushi bar, delicious Chinese foods, entertaining hibachi tables, in addition to a great lunch menu. We will be opening up our upstairs patio for dine-in soon. Located at the heart of Glen Cove, at 18 Cottage Row, Glen Cove, NY, 11542"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8715,
        store_longitude: -73.6256,
        total_orders: 979.86,
        neighborhood: '',
        keywords: 'Italian, Salad, Wine, Pizza, Coffee, Upscale, Cozy, Premium, Clean, Friendly',
        annual_dol: 1008.37,
        description:
            'Upmarket Sicilian favorites including wines & pasta options in a homey atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7427,
        store_longitude: -73.7216,
        total_orders: 8.03,
        neighborhood: 'Glen Oaks/Bellerose Manor',
        keywords:
            'Vegetarian, Butter Chicken, Chicken Tikka Masala, Fried Rice, Indian, Tandoori Chicken, Delicious, Casual, Dal Makhani, Aloo Tikki',
        annual_dol: 32.81,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7185,
        store_longitude: -73.815,
        total_orders: 50.16,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Steak, Salad, Vodka, Bbq, Fried Rice, Asian, Kibbeh, Eclectic, Spacious, Casual',
        annual_dol: 63,
        description:
            'Casual, spacious steakhouse offering an eclectic menu of Italian, Asian & Middle Eastern fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8245,
        store_longitude: -73.8205,
        total_orders: 48.93,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Steak, Vodka, Delicious, Latin, American, Fusion, Exotic, Lively, Casual, Sangria',
        annual_dol: 76.54,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8654,
        store_longitude: -73.6311,
        total_orders: 176.45,
        neighborhood: '',
        keywords:
            'Italian, Wine, Pizza, Beer, Romantic, Refined, Outstanding, Classy, Awesome, Amazing',
        annual_dol: 454.68,
        description:
            'Original Italian eatery in a family-owned chain offers refined fare in old-fashioned environs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5754,
        store_longitude: -74.1051,
        total_orders: 5361.88,
        neighborhood: 'Midland Beach',
        keywords:
            'Lobster, Lobster Tail, Tasty, Gourmet, Dessert, Wine, Beer, Delicious, Coffee, Italian',
        annual_dol: 5910.25,
        description:
            'Eclectic, purple-hued cellar offering a range of classic American dishes plus cocktails & wine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6302,
        store_longitude: -73.7083,
        total_orders: 477.25,
        neighborhood: '',
        keywords: 'Pizza, Fun, Comfortable, Wonderful, Great, Excellent, Classic, Casual',
        annual_dol: 584.61,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7507,
        store_longitude: -73.9863,
        total_orders: 24840.42,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Steak, Burger, Salad, Roast Beef, Wine, Legendary, Beer, Prime Rib, Crisp',
        annual_dol: 25693.85,
        description:
            'Enormous steaks & signature mutton chops served in a maze of clubby, wood-paneled rooms.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5815,
        store_longitude: -73.83,
        total_orders: 1944.78,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords:
            'Tacos, Burger, Hotel, Urban, Innovative, American, Rooftop, Eclectic, Contemporary, Fresh',
        annual_dol: 3405.65,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6739,
        store_longitude: -73.6703,
        total_orders: 991.07,
        neighborhood: '',
        keywords: 'Wine, Italian, Beer, Rustic, Premium, Chic, Authentic, Diverse, Local',
        annual_dol: 1012.1,
        description:
            'Old-world Italian spot with rustic-chic decor offering entrees, small plates & a diverse wine list.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7074,
        store_longitude: -73.9217,
        total_orders: 593.65,
        neighborhood: 'Bushwick',
        keywords: 'Mexican, Beer, Whiskey, Margarita, Italian, Spicy, Cozy, Asian, Hip, Friendly',
        annual_dol: 736.77,
        description:
            'Hip hangout with outdoor seating fixing up beer & cocktails in a laid-back setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7873,
        store_longitude: -73.9516,
        total_orders: 941.76,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords: 'Wine, Beer, Margarita, Warm, Rum, Cozy, Phenomenal, Friendly, Perfect, Bright',
        annual_dol: 1130.84,
        description:
            'This cozy cocktail haunt features a bartop with mixed nuts suspended in epoxy & other quirky decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7408,
        store_longitude: -73.5992,
        total_orders: 410.83,
        neighborhood: '',
        keywords:
            'Luxurious, Hotel, Spacious, Fun, Sophisticated, Incredible, Great, American, Salad, Distinctive',
        annual_dol: 447.74,
        description:
            '"Distinct, luxurious, 1 & 2 bedroom luxury apartments in Nassau County on Long Island. Experience 5-star living every day, in these incredible rental residences. Open floor plans, dramatic 9\' ceilings, spa baths, sunlit rooms and walk in closets. The best of hotel style living, in the comfort of your own building. Fitness center, concierge, outdoor heated pool, parking, exclusive dining and more. Limited availability, contact us for a tour & details on this brand new rental apartments. Vanderbilt also offers short and long term stays."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7195,
        store_longitude: -74.0059,
        total_orders: 406.77,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Upscale, Tasty, Elegant, Legendary, Impeccable, Classy, Premium, Gourmet, French',
        annual_dol: 433.5,
        description:
            'Modern European plates pair with an extensive wine list & cocktails in an elegant, open interior.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7204,
        store_longitude: -74.0102,
        total_orders: 115.01,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Pizza, Delicious, Memorable, Perfect',
        annual_dol: 470.13,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6106,
        store_longitude: -73.6456,
        total_orders: 24.3,
        neighborhood: '',
        keywords: 'Hotel, Fun, Awesome, Western, Great, Fine, Casual, Local',
        annual_dol: 99.35,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.75,
        store_longitude: -73.9838,
        total_orders: 335.37,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Wine, Coffee, Upscale, French, Brazilian, Contemporary, Fancy, Robust, Classic',
        annual_dol: 1370.88,
        description:
            '"Ipanema is dedicated to building quality relationships, cultivated through top quality cooking and delivering one-of-a-kind dining experiences. While Ipanema sells modern interpretations of classic dishes from Portugal and Brazil, the menu combines Portuguese recipes with the zest and vibrancy of Brazilian cuisine rooted in French cooking techniques. Our “cheerful and friendly spot tastes just like Brazil & Portugal” and feels like a home “inside-of-a-restaurant.” A seasonal menu by Executive Chef Giancarlo Junyent offers sharing plates of fresh appetizers and mid courses, with a lovely finale of roasted meats and cured seafood, followed by decadent desserts and wine."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7538,
        store_longitude: -73.9925,
        total_orders: 109.55,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Comfortable, Coffee, Clean, Friendly, Sleek, Modern, Great, Exceptional, Contemporary',
        annual_dol: 447.8,
        description:
            'The understated rooms offer Wi-Fi, coffeemakers and minifridges. Upgraded rooms have views of the Empire State Building.Amenities include a compact gym and a business center.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8074,
        store_longitude: -73.9273,
        total_orders: 472.33,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Beer, Trendy, Rich, Mimosa, Delicious, Southern Comfort, American, Puerto Rican, Classy, Hip',
        annual_dol: 518.22,
        description:
            'Trendy restaurant dishing up Southern & Puerto Rican comfort foods, plus brunch staples & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7193,
        store_longitude: -74.0064,
        total_orders: 533.58,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Italian, Tasty, Cozy, Gin, Warm, Beer, Rustic, Comfortable, Premium',
        annual_dol: 622.44,
        description:
            'Convivial wine bar serving its vintages & Italian small plates in cozy digs with sidewalk seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6557,
        store_longitude: -73.8387,
        total_orders: 1514.95,
        neighborhood: 'Jamaica/Howard Beach',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Steak, Salad, Italian, Fried Calamari, Wine, Bbq, Beer',
        annual_dol: 1563.95,
        description:
            'Lively canteen with a big menu of seafood, raw-bar offerings, BBQ & Italian standards.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.718,
        store_longitude: -73.9982,
        total_orders: 1285.6,
        neighborhood: 'Little Italy',
        keywords:
            'Italian, Wine, Delicious, Baked Clams, Spacious, Innovative, Bruschetta, Amazing, Authentic, Traditional',
        annual_dol: 2067.47,
        description:
            'Traditional Northern Italian eatery since 1975 with veteran waiters & a back garden atrium.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7874,
        store_longitude: -73.9528,
        total_orders: 377.41,
        neighborhood: 'East Harlem/Central Park West Historic District',
        keywords:
            'Mexican, Burritos, Cuban, Cozy, Casual, Local, Authentic, Classic, Traditional, Fresh',
        annual_dol: 385.42,
        description:
            'Cozy cantina with colorful decor for Mexican street food, aguas frescas & tequila.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7169,
        store_longitude: -74.0087,
        total_orders: 6527.03,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Champagne, Wine, Beer, Bourbon, Gin, Delicious, Martini, Warm, Rum, Sleek',
        annual_dol: 6729.1,
        description:
            'Slick, Prohibition-style watering hole with craft cocktails & sophisticated bar snacks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7776,
        store_longitude: -73.9521,
        total_orders: 1286.18,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Tacos, Hamburger, Tasty, Beer, Nutritious, Tomato Soup, Delicious, Upscale, American',
        annual_dol: 1316.37,
        description:
            'Rustic, brick-walled bar with American eats, tacos & brunch plus craft drafts & specialty cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7029,
        store_longitude: -73.9294,
        total_orders: 1382.14,
        neighborhood: 'Bushwick',
        keywords:
            'Tacos, Tequila, Mexican, Margarita, Enchiladas, Trendy, Rich, Rum, Delicious, Hip',
        annual_dol: 1417.05,
        description:
            'Place for simple, modern Mexican food (like tacos, tortas & grilled dishes) with cocktails & wine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.756,
        store_longitude: -73.9942,
        total_orders: 643.99,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Whiskey, Wine, Beer, Irish, Salad, Vietnamese, Canadian, French, American, Eggs Benedict',
        annual_dol: 2285.21,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7046,
        store_longitude: -74.0098,
        total_orders: 1154.93,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Rich, Exquisite, Wonderful, Great, Intimate, Fine, Local',
        annual_dol: 4720.99,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7403,
        store_longitude: -74.0056,
        total_orders: 1422.39,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords: 'Wine, Beer, Martini, Asian, Elegant, Sleek, Exotic, Fusion, American, Fresh',
        annual_dol: 3115.29,
        description:
            'Asian-inspired cocktails & bites delivered from a dim sum cart are presented in an ornate space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7307,
        store_longitude: -74.0069,
        total_orders: 3217.11,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Sushi, Gluten Free, Japanese, Wine, Tasty, Organic, Sashimi, Spicy, Magical',
        annual_dol: 3309.68,
        description:
            'Sleek spot for modern, seasonal Japanese cuisine, including housemade tofu, plus a long sake list.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7302,
        store_longitude: -74.0034,
        total_orders: 693.8,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Gluten Free, Salad, Pizza, Tasty, Organic, Wholesome, Cuban, Dessert',
        annual_dol: 840.37,
        description:
            'Organic vegan comfort food, along with cocktails & mocktails in an intimate West Village setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7276,
        store_longitude: -73.6351,
        total_orders: 3071.53,
        neighborhood: '',
        keywords: 'Lobster, Pizza, Italian, Wine, Meatball, Spicy, Creamy, Premium, Sweet, Global',
        annual_dol: 3183.67,
        description:
            'A vast selection of wines is kept fresh by a high-tech system & served with small plates & pasta.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8396,
        store_longitude: -73.9405,
        total_orders: 1627.06,
        neighborhood: 'Little Dominican Republic/Washington Heights',
        keywords:
            'Burger, Crab Cakes, Salad, Cornbread, Shrimp Cocktail, Bbq, Friendly, Coleslaw, Casual, Local',
        annual_dol: 1697.61,
        description:
            'Bustling local chain serving big plates of saucy meats & other classic fare plus jumbo margaritas.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6686,
        store_longitude: -73.9932,
        total_orders: 1825.82,
        neighborhood: 'Gowanus',
        keywords: 'Pizza, Beer, Wine, Tranquil, Relaxed, Awesome, Fresh, Friendly, Amazing, Great',
        annual_dol: 1864.56,
        description:
            'Relaxed outpost for cocktails & a rotating beer selection, plus a tranquil outdoor seating area.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6638,
        store_longitude: -73.9898,
        total_orders: 72.22,
        neighborhood: 'South Slope/Park Slope',
        keywords: 'Dessert, Russian, Sweet, Iconic, Fine, Complex, Samosas, Teriyaki',
        annual_dol: 295.2,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.662,
        store_longitude: -73.9925,
        total_orders: 495.93,
        neighborhood: 'South Slope/Sunset Park',
        keywords:
            'Burger, Hamburger, Beer, Austrian, German, Martini, Korean, Hungarian, Wine, Polish',
        annual_dol: 565.69,
        description:
            'Quirky kitchen with Eastern European-inspired comfort food, including hamburgers, plus draft beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8599,
        store_longitude: -73.8929,
        total_orders: 194.04,
        neighborhood: 'Belmont/Fordham Heights/West Bronx',
        keywords:
            'Steak, Wine, Beer, Margarita, Hawaiian, Spanish, Pinacolada, Caribbean, American, Delicious',
        annual_dol: 256.88,
        description:
            'Lively, clubby destination with a global menu featuring Italian, Latin & local dishes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6581,
        store_longitude: -73.648,
        total_orders: 479.96,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Gluten Free, Beer, Dessert, Irish, Delicious, Relaxed, American, Friendly',
        annual_dol: 490.15,
        description:
            'Down-to-earth tavern offering a pub menu with Irish specialties, plus draft beer & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6121,
        store_longitude: -73.941,
        total_orders: 229.38,
        neighborhood: 'Marine Park',
        keywords:
            'Burger, Hamburger, Steak, Pizza, Sushi, Salad, Mexican, Italian, French Onion Soup, French',
        annual_dol: 247.25,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6396,
        store_longitude: -73.661,
        total_orders: 409.36,
        neighborhood: '',
        keywords:
            'Lobster, Burger, Salad, Pizza, Shrimp Scampi, Italian, Beer, Wine, Moroccan, Delicious',
        annual_dol: 1488.29,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8314,
        store_longitude: -73.8271,
        total_orders: 1569.95,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Lobster, Steak, Sushi, Salad, Beer, Japanese, Tasty, Sashimi, Asian, Sweet',
        annual_dol: 2562.76,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7143,
        store_longitude: -73.5598,
        total_orders: 188.33,
        neighborhood: '',
        keywords: 'Steak, Coffee, Gourmet, Beer, Bbq, American, Ribeye Steak, Mead, Casual, Unique',
        annual_dol: 355.24,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8231,
        store_longitude: -73.8196,
        total_orders: 326.7,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Whiskey, Beer, Delicious, American, Casual, Fun, Fine',
        annual_dol: 801.71,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7194,
        store_longitude: -73.5647,
        total_orders: 1021.96,
        neighborhood: '',
        keywords:
            'Salad, Tacos, Mexican, Margarita, Wine, Beer, Chinese, Casual, Exotic, Traditional',
        annual_dol: 1785.44,
        description:
            'Festive eatery specializes in margaritas & Mexican classics, with occasional live mariachi music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7576,
        store_longitude: -73.993,
        total_orders: 2903.47,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Champagne, Beer, Margarita, Trendy, Stylish, Spicy, Savory, Gracious, Cointreau, Triple Sec',
        annual_dol: 2965.08,
        description:
            "Along with 28 lanes of bowling there's a dance club, billiards lounge & various package deals.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.751,
        store_longitude: -73.9832,
        total_orders: 898.72,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Lobster, Hamburger, Pizza, Salad, Cajun, Nachos, Lobster Bisque, Beer, Cuban',
        annual_dol: 3217.79,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7566,
        store_longitude: -73.9927,
        total_orders: 103.71,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Wine, Hotel, Spanish, Stylish, Cozy, Classy, Classic, Adventurous, Contemporary, Iconic',
        annual_dol: 423.91,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7535,
        store_longitude: -73.6275,
        total_orders: 419.2,
        neighborhood: '',
        keywords: 'Fun, Friendly, Perfect, Wonderful, Amazing, Great, Classic, Casual',
        annual_dol: 456.35,
        description:
            'Long-standing chain of bowling alleys featuring casual grub & pitchers, leagues & party events.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8345,
        store_longitude: -73.8504,
        total_orders: 447.58,
        neighborhood: 'East Bronx/Westchester Square/Parkchester',
        keywords:
            'Mexican, Beer, Margarita, Martini, Wine, Spanish, Puerto Rican, Caribbean, American, Latin',
        annual_dol: 527.41,
        description:
            'Casual spot for Puerto Rican home cooking plus classic cocktails amid art-filled walls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7525,
        store_longitude: -73.9828,
        total_orders: 75.65,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Upscale, Coffee, Hotel, Healthy, Lively, Romantic, Rooftop, Sleek, Classic, Chic',
        annual_dol: 573.64,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7109,
        store_longitude: -73.6765,
        total_orders: 1004.7,
        neighborhood: '',
        keywords:
            'Beer, Ale, Cajun, Burger, Wine, Chicken Wings, Asian, Delicious, Southern, Classic',
        annual_dol: 1029.33,
        description:
            'Outpost of a local alehouse chain featuring a selection of craft beers, pub grub & wing specials.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7518,
        store_longitude: -73.7026,
        total_orders: 102.08,
        neighborhood: 'Floral Park/Bellerose Manor',
        keywords:
            'Martini, Gin, Spicy, Delicious, Tandoori Chicken, Fusion, American, Sweet, Modern, Indian',
        annual_dol: 116.45,
        description:
            '"ABCD Indian Bistro & Bar was born out of the love of a foodie who experienced his cultural recipes in a foreign land. Enjoy different cuisines in the best Indian restaurant in queens."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7723,
        store_longitude: -73.9299,
        total_orders: 429.68,
        neighborhood: 'Astoria',
        keywords: 'Beer, Wine, Perfect, American, Great, Excellent, Fusion, Casual, Chill, Unique',
        annual_dol: 489.38,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7074,
        store_longitude: -73.674,
        total_orders: 20.41,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Mexican, Wine, Tasty, American, Casual, Traditional, Great',
        annual_dol: 83.45,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6748,
        store_longitude: -73.976,
        total_orders: 108.78,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Beer, Salad, Gourmet, Friendly, Eclectic, Fun, Lively, Casual, Artisanal',
        annual_dol: 444.68,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7618,
        store_longitude: -73.9109,
        total_orders: 37.52,
        neighborhood: 'Astoria',
        keywords: 'Wine, Tasty, Beer, Irish, Cozy, Rum, Friendly, Great, Casual, Local',
        annual_dol: 181.01,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7724,
        store_longitude: -73.9605,
        total_orders: 3103.81,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Wine, Beer, French, Italian, French Onion Soup, American, Delicious, Premium, Warm',
        annual_dol: 3108.35,
        description:
            'Popular with neighborhood locals & expats, the Parisian-style eatery dishes authentic French fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7644,
        store_longitude: -73.9691,
        total_orders: 3923.53,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Burger, Chocolate Cake, Cheesecake, Pizza, Carrot Cake, Salad, Coffee, Wine, Shrimp Cocktail, Hotel',
        annual_dol: 4243.78,
        description:
            "Chic, classically furnished rooms have free Wi-Fi and smart TVs, as well as desks with ergonomic chairs. Upgrades add 47-inch TVs, living areas or views of Park Avenue. Luxe 1- to 3-bedroom suites feature sleek atriums, kitchens, soaking tubs and/or balconies with city views.There's a contemporary restaurant with a grill serving American cuisine and afternoon tea, plus a swanky bar and a lounge. There's also a salon, a plush spa and a fitness center.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8305,
        store_longitude: -73.8156,
        total_orders: 323.27,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'American, African, Diverse, Local',
        annual_dol: 340.73,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8707,
        store_longitude: -73.6177,
        total_orders: 1109.84,
        neighborhood: '',
        keywords: 'Golfclub, Classic',
        annual_dol: 1182.78,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7503,
        store_longitude: -73.9847,
        total_orders: 9971.3,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Salad, Japanese, Ramen, Spicy, Delicious, Trendy, Zen, Fresh, Casual',
        annual_dol: 10063.26,
        description:
            'Branch of a Tokyo-based chain specializing in tonkotsu ramen, griddled dishes & fried chicken.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6565,
        store_longitude: -73.6451,
        total_orders: 161.57,
        neighborhood: '',
        keywords: 'Burger, Hamburger, Beer, Lobster, American, Wine, Casual, Creative, Fine',
        annual_dol: 180.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6121,
        store_longitude: -73.6438,
        total_orders: 302.43,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Burger, Beer, Wine, Martini, Margarita, Coffee, Delicious, Ale',
        annual_dol: 411.7,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.75,
        store_longitude: -73.9836,
        total_orders: 8996.03,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Wine, Italian, Stylish, Hotel, French, Gourmet, Elegant, Delicious, Romantic, Inviting',
        annual_dol: 9463.98,
        description:
            'Gourmet fare from the Italian & French Riviera plus regional wines in an elegant hotel setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7512,
        store_longitude: -73.9837,
        total_orders: 1296.55,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Beer, Irish, Fun, Friendly, Awesome, Great, Casual, Chill',
        annual_dol: 1371,
        description:
            '"Traditional Irish pub and sports bar in midtown Manhattan located at 20 West 38th Street between 5th & 6th Avenue."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7538,
        store_longitude: -73.9937,
        total_orders: 2332.06,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Hamburger, Vegetarian, Salad, Tuna Steak, Bbq, Dessert, American, Hotel',
        annual_dol: 2548.85,
        description:
            '"Burgerology is a USDA Prime burger restaurant that features crazy milkshakes, hand cut fries, craft beer, wine, impossible sundaes and craft cocktails."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6496,
        store_longitude: -73.8717,
        total_orders: 788.62,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Steak, Burger, Lobster, Hamburger, Italian, Chicken Wings, Juicy, Creole, Sweet, Casual',
        annual_dol: 1676.34,
        description:
            'Boisterous Australian-themed chain serving steaks, seafood & other hearty steakhouse fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.752,
        store_longitude: -73.9829,
        total_orders: 330.32,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Cajun, Savory, Gourmet, Tasty, American, Organic, Friendly, Casual',
        annual_dol: 951.44,
        description:
            '"Savory is a boutique, multifaceted hospitality company based in the heart of New York City, offering a wide variety of culinary programs, specializing in office catering, events, and food and beverage operations."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7441,
        store_longitude: -73.924,
        total_orders: 142.22,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Lasagna, Italian, Pizza, Salad, Coffee, Donut, Beer, Creamy, Homemade, American',
        annual_dol: 165.77,
        description:
            'Italian classics, including housemade pasta, served in a bright space with exposed-brick walls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6248,
        store_longitude: -73.9176,
        total_orders: 227.77,
        neighborhood: 'Bergen Beach',
        keywords:
            'Lobster, Cajun, Salad, Lobster Bisque, Flavorful, Bbq, Creole, Fusion, Classy, Clam Chowder',
        annual_dol: 429.63,
        description:
            '"At Hook & Reel, we celebrate the flavorful cuisine of Louisiana by pairing the freshest seafood with authentic Cajun seasonings."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6015,
        store_longitude: -73.656,
        total_orders: 168.88,
        neighborhood: '',
        keywords: 'Ale, Beer, Wine, Bourbon, Scotch, Local, Seasonal, Casual, Great',
        annual_dol: 180.1,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7051,
        store_longitude: -74.0087,
        total_orders: 70.73,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Lobster Tail, Burger, Shrimp Cocktail, Salad, Tequila, Dessert, Greek, Mediterranean, Bbq',
        annual_dol: 289.11,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6194,
        store_longitude: -73.9218,
        total_orders: 76.5,
        neighborhood: 'Flatlands',
        keywords: 'Steak, Pizza, Gourmet, Beer, American, Bbq, Irish, Creamy, Homemade, Classic',
        annual_dol: 312.69,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7574,
        store_longitude: -73.9957,
        total_orders: 333.38,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Pizza, Salad, Beer, Asian, Modern, Contemporary, Casual, Local, Classic',
        annual_dol: 379.7,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7544,
        store_longitude: -73.9956,
        total_orders: 380.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Beer, Wine, Local, Casual, Great, Attentive, Chill, Unique',
        annual_dol: 1562.46,
        description:
            "Metropolitan bar with black & white art & a 'throwing range' to smash glasses & electronic items.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6204,
        store_longitude: -73.9272,
        total_orders: 110.49,
        neighborhood: 'Flatlands',
        keywords:
            'Salad, Dessert, Tasty, Mimosa, Champagne, Organic, Jamaican, Caribbean, Rustic, Creamy',
        annual_dol: 246.77,
        description:
            '"Bellyful Restaurant & Bar is an elegantly rustic Caribbean Restaurant catering to the discerning customer. We are located at 2102 Utica Avenue in Brooklyn New York. Our ambiance truly encapsulates the Pan-African spirit and culture, all private occasions can be accommodated within our mezzanine area. Our dishes contain nothing but the authentic Jamaican flavors that truly excite your taste buds. The majority of our dishes are inspired by classic and timeless Jamaican recipes. While it is guaranteed that your taste buds will come alive from dishes infused with flavors from the Caribbean; the food at Bellyful is just the beginning of your overall culinary experience."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8576,
        store_longitude: -73.6354,
        total_orders: 1824.02,
        neighborhood: '',
        keywords:
            'Elegant, Exquisite, Intimate, Perfect, Awesome, Impressive, Great, Artistic, Seasonal, Local',
        annual_dol: 2126.12,
        description:
            '"Elegant Affairs Caterers We are Long Island\'s NY Catering Company of Choice providing Full-Service Off-Premise Catering. We offer Catering Services To Social and Corporate Events, Private Parties, and Weddings in Long Island NY and nearby areas. Menu Planning & Food Styling We offer an array of seasonal local cuisine that is impressive to the eye, as well as, the palate. Party Rental Coordination. We are a team of expert organizers and are always happy to help coordinate your party rentals. Vendor Coordination In addition to bringing exuberance and style to every event we cater to — we also work well with others. Our seasoned team takes pride in handling coordinating with your vendors to ensure expectations are not only met but surpassed."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.727,
        store_longitude: -73.8223,
        total_orders: 77.89,
        neighborhood: 'Flushing/Kew Gardens Hills',
        keywords: 'Champagne, Wine, Beer, Wonderful, Great, Intimate',
        annual_dol: 84.89,
        description:
            '"Queens NY. The #1 Catering Hall in Kew Gardens Hills, Queens! Exclusive Use. Book Now! Professional Service. Family Owned and Operated since 1975."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5968,
        store_longitude: -73.6541,
        total_orders: 194.88,
        neighborhood: '',
        keywords:
            'Burger, Cheesesteak, Beer, Organic, Ale, Philly Cheesesteak, Spanish, American, Irish, Homemade',
        annual_dol: 280.93,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5786,
        store_longitude: -73.8497,
        total_orders: 4526.54,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords:
            'Tacos, Mexican, Steak, Beer, Venezuelan, Delicious, Contemporary, Classic, Friendly, Fresh',
        annual_dol: 4722.8,
        description:
            'Cool, contemporary Mexican kitchen offering classic street food, creative cocktails & many tequilas.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5796,
        store_longitude: -74.1093,
        total_orders: 726.7,
        neighborhood: 'Midland Beach',
        keywords: 'Burger, Pizza, Italian, Juicy, Beer, Wine, Bbq, Creamy, Legendary, Peruvian',
        annual_dol: 774.57,
        description:
            '"The world\'s best pizza courtesy of the legendary Di Fara Pizza from Brooklyn! Also serving a full Italian menu ala Juicy Lucia."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8306,
        store_longitude: -73.6936,
        total_orders: 51.38,
        neighborhood: '',
        keywords:
            'Salad, Steak, Upscale, Martini, Spicy, French, Thai, Cozy, Quaint, Mediterranean',
        annual_dol: 66.37,
        description:
            'Intimate & upscale New American eatery offers fixed-price & beer-pairing specials in candlelit digs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8129,
        store_longitude: -73.6792,
        total_orders: 1224.01,
        neighborhood: '',
        keywords: 'Golfclub, Bbq, Fabulous, Fantastic, Terrific, Great, Complex',
        annual_dol: 1362.85,
        description:
            'The North Hempstead Country Club is a private country club in the Incorporated Village of Flower Hill, on Long Island, in New York, United States.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5842,
        store_longitude: -73.9353,
        total_orders: 300.02,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Upscale, Luxurious, American, Exquisite, Fine, Delectable',
        annual_dol: 349.99,
        description:
            '"Private Charter Yacht available for Corporate Events, Weddings, Birthday Parties, and other Celebrations."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8211,
        store_longitude: -73.8186,
        total_orders: 3361.62,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Sushi, Japanese, Cajun, Asian, Fusion, Clean, Perfect, Casual, Unpretentious, Recommended',
        annual_dol: 4005.5,
        description:
            'Unpretentious eatery where chefs cook meat & seafood on hibachi grills, plus noodles, sushi & more.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6082,
        store_longitude: -73.7266,
        total_orders: 239.68,
        neighborhood: '',
        keywords: 'Hotdog, Burger, Beer, Tasty, Bbq, Stylish, Elegant, Perfect, Great, Wonderful',
        annual_dol: 756.11,
        description:
            '"Breathe in the historic site… stately manor, endless acres of manicured golfing greens, yachts majestically aligned, all embraced by the gentle bay area of the Atlantic Sea."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5732,
        store_longitude: -74.1151,
        total_orders: 603.76,
        neighborhood: 'New Dorp',
        keywords:
            'Vegetarian, Salad, Shrimp Cocktail, Italian, Wine, Meatball, Turkish, Upscale, Delicious, Classy',
        annual_dol: 629.94,
        description:
            'Bustling restaurant serving upscale Italian eats in a rustic, white-tablecloth setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5779,
        store_longitude: -73.8494,
        total_orders: 475.2,
        neighborhood: 'Rockaway Park/Far Rockaway/Belle Harbor',
        keywords: 'Mexican, Wine, Tasty, Filipino, Warm, American, Irish, Asian, Casual, Local',
        annual_dol: 614.03,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.601,
        store_longitude: -73.6561,
        total_orders: 15.84,
        neighborhood: '',
        keywords: 'Pizza, Italian, Salad, Meatball, Vodka, Hotel, Polished, Fresh, Casual, Local',
        annual_dol: 59.7,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7192,
        store_longitude: -73.5649,
        total_orders: 33.12,
        neighborhood: '',
        keywords:
            'Burger, Steak, Pizza, Salad, Sushi, Roast Beef, Beer, Wine, Caesar Salad, Creamy',
        annual_dol: 135.4,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6653,
        store_longitude: -73.7243,
        total_orders: 4301.44,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Tail, Cajun, Spicy, Delicious, Juicy, Sweet, Fun, Clean, Perfect',
        annual_dol: 4400.68,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6567,
        store_longitude: -73.645,
        total_orders: 627.43,
        neighborhood: '',
        keywords:
            'Burger, Mexican, Salad, Italian, Nachos, American, Rooftop, Irish, Elegant, Casual',
        annual_dol: 698.08,
        description:
            'Quick bites & entrees of American fare at this elegant venue with private rooms & a rooftop bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8312,
        store_longitude: -73.6949,
        total_orders: 1828.48,
        neighborhood: '',
        keywords:
            'Burger, Steak, Pizza, Salad, Shrimp Cocktail, Roast Turkey, Beer, Wine, Dessert, French Onion Soup',
        annual_dol: 2172.52,
        description:
            'Long-running venue featuring Irish & American classics, including shepherd’s pie, burgers & steak.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6555,
        store_longitude: -73.6458,
        total_orders: 1234.72,
        neighborhood: '',
        keywords: 'Burger, Salad, Italian, Beer, Wine, Upscale, Thai, American, Bbq, Delicious',
        annual_dol: 1265.23,
        description:
            'Local American mainstay for creative burgers & grilled bites, with 2 bars & patio seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7532,
        store_longitude: -73.9858,
        total_orders: 1488.98,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Whiskey, Beer, Wine, Irish, Karaoke, Homemade, Traditional, Classic',
        annual_dol: 1556.1,
        description:
            "Classic Irish bar offering pints of Guinness, shepherd's pie, bangers & mash & jukebox tunes.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7535,
        store_longitude: -73.9923,
        total_orders: 531.65,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Tasty, Spicy, Delicious, Wine, Chinese, Gourmet, Beer, Spacious, Asian, Exceptional',
        annual_dol: 600.18,
        description:
            'Traditional Chinese dishes by the namesake chef are served in a modern, airy 2-story space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6263,
        store_longitude: -73.7366,
        total_orders: 522.59,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Burritos, Salad, Margarita, Yummy, Delicious, Local, Casual, Perfect',
        annual_dol: 616.18,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7054,
        store_longitude: -74.0074,
        total_orders: 1567.28,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Luxurious, Hotel, Rich, Elegant, Spacious, Spectacular, Sophisticated, Urban, Global, Intimate',
        annual_dol: 6406.55,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7055,
        store_longitude: -74.0079,
        total_orders: 455.76,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Sushi, Wine, Stylish, Elegant, Pizza, Japanese, Sashimi, Fun, Contemporary, Friendly',
        annual_dol: 1140.54,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7524,
        store_longitude: -73.9846,
        total_orders: 1077.97,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Italian, Dessert, Contemporary, American, Casual, Diverse, Cool, Traditional',
        annual_dol: 1100.84,
        description: '"Modern American Cuisine"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7956,
        store_longitude: -73.6749,
        total_orders: 19588.79,
        neighborhood: '',
        keywords:
            'Upscale, Wine, Trendy, Nutritious, Japanese, Spicy, Asian, Delicious, Creamy, Modern',
        annual_dol: 20027.45,
        description:
            'A destination for upscale Asian fusion fare in snazzy modern surroundings with a booming bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7168,
        store_longitude: -73.5618,
        total_orders: 1370.01,
        neighborhood: '',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Flavorful, Bbq, Wine, Bbq Ribs, Swiss',
        annual_dol: 1399.08,
        description:
            'Chophouse standards are supplied in this family-friendly outpost with an old-school vibe.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8258,
        store_longitude: -73.8214,
        total_orders: 126.12,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Mexican, Puerto Rican, Ale, Irish, American, Innovative, Authentic, Latin, Eclectic, Clean',
        annual_dol: 411.74,
        description: '"Bar / Kitchen / Culture"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.789,
        store_longitude: -73.6514,
        total_orders: 225.16,
        neighborhood: '',
        keywords:
            'Tacos, Cheesecake, Salad, Vegetarian, Wine, Beer, Mexican, Italian, Martini, Delicious',
        annual_dol: 234.3,
        description:
            'Casual eatery offering family-style Italian staples & wines by the glass in a traditional setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6039,
        store_longitude: -73.651,
        total_orders: 2642.08,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Lobster Bisque, Shrimp Scampi, Japanese, Wine, Martini, Spanish, New Zealand, American',
        annual_dol: 2698.14,
        description:
            'Classy chophouse with a sizable bar turning out steaks, seafood & martinis in an old-school setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7538,
        store_longitude: -73.986,
        total_orders: 499.2,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Healthy, Premium, Bright, Fun, Friendly, Modern, Great, Chic, American',
        annual_dol: 550.87,
        description:
            'Contemporary rooms offer free WiFi and flat-screen TVs with premium cable channels, plus desks, minifridges and coffeemakers. Suites add separate living areas, sofas and microwaves.Social spaces include a living room-style lobby and an American restaurant serving breakfast. Other amenities include an exercise room, a business center and meeting space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6647,
        store_longitude: -73.7246,
        total_orders: 3879.42,
        neighborhood: '',
        keywords:
            'Burger, Salad, Dessert, Italian, New-York-Style, Beer, French, Chicken Wings, Rich, Creamy',
        annual_dol: 3982.64,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6556,
        store_longitude: -73.6458,
        total_orders: 927.05,
        neighborhood: '',
        keywords:
            'Burger, Hawaiian, Beer, Bbq, Moscow Mule, Ale, Blue Hawaiian, Bloody Mary, Casual, Great',
        annual_dol: 967.25,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6121,
        store_longitude: -73.6487,
        total_orders: 338.07,
        neighborhood: '',
        keywords: 'Lobster, Warm, Fried Clams, Southern, Fresh, Casual, American, Great, Classic',
        annual_dol: 424.58,
        description:
            'Old-fashioned eatery & raw bar serving classic American seafood & steaks since the summer of 1940.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7052,
        store_longitude: -74.0073,
        total_orders: 132.96,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Spectacular, Rooftop, Rum, Great, Comfortable, Modern, Contemporary, Friendly, Fine',
        annual_dol: 543.5,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7072,
        store_longitude: -74.0112,
        total_orders: 790.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Coffee, Premium, Casual',
        annual_dol: 1115.27,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7537,
        store_longitude: -73.9857,
        total_orders: 4353.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Whiskey, Burger, Beer, Vodka, Vegetarian, Wine, Gin, Gluten Free, American, Tasty',
        annual_dol: 4598.55,
        description:
            'Popular neighborhood hangout for drinks & American pub food served in a cozy setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7957,
        store_longitude: -73.9323,
        total_orders: 1105.03,
        neighborhood: 'Upper Manhattan/East Harlem',
        keywords:
            'Steak, Salad, Chicken Caesar Salad, Milkshake, Beer, Thai, Delicious, Caesar Salad, American, Asian',
        annual_dol: 1520.59,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6571,
        store_longitude: -73.6435,
        total_orders: 54.66,
        neighborhood: '',
        keywords: 'Sushi, Salad, Japanese, Spicy, Juicy, Chinese, Sashimi, Thai, Asian, Latin',
        annual_dol: 223.45,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6866,
        store_longitude: -73.8229,
        total_orders: 27.56,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'South American, Spicy, Bbq, Chinese, Fried Rice, Caribbean, Asian, American, Brazilian, Elegant',
        annual_dol: 112.64,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7239,
        store_longitude: -73.5665,
        total_orders: 419.67,
        neighborhood: '',
        keywords: 'Burger, Premium, Latin, Fun, Classic, Great, Local',
        annual_dol: 1738.17,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6134,
        store_longitude: -73.9123,
        total_orders: 426.32,
        neighborhood: 'Mill Basin',
        keywords:
            'Burger, Lobster, Pizza, Salad, Gourmet, Italian, Beer, Savory, American, Delicious',
        annual_dol: 474.33,
        description:
            'Cozy store offering specialty cakes & cupcakes plus imported teas, chocolates, cheeses & breads.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6807,
        store_longitude: -73.7532,
        total_orders: 1747.66,
        neighborhood: 'Springfield Gardens/St. Albans',
        keywords: 'Tacos, Steak, Pizza, Jamaican, Rum, Caribbean, Indian, Great, Wonderful, Casual',
        annual_dol: 1919.89,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6732,
        store_longitude: -73.6734,
        total_orders: 367.62,
        neighborhood: '',
        keywords: 'Pizza, Beer, Wine, Martini, Delicious, Irish, Bbq, Premium, Fun, Classy',
        annual_dol: 458.23,
        description: '"Friendly local bar"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7441,
        store_longitude: -73.8516,
        total_orders: 9952.6,
        neighborhood: 'Flushing/Corona',
        keywords: 'Vodka, Great, Cool, Signature, Complex',
        annual_dol: 13200,
        description:
            'The USTA Billie Jean King National Tennis Center is a stadium complex within Flushing Meadows–Corona Park in Queens, New York City, United States.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7575,
        store_longitude: -73.8447,
        total_orders: 4126.52,
        neighborhood: 'Willets Point/Corona/Flushing',
        keywords:
            'Lobster, Lobster Roll, Hotdog, Tequila, Dessert, Margarita, Delicious, Hotel, Rum, Triple Sec',
        annual_dol: 22488.92,
        description:
            "Citi Field is a baseball stadium located in Flushing Meadows–Corona Park in New York City, United States. It opened in 2009 and is the home field of Major League Baseball's New York Mets. The stadium was built as a replacement for the adjacent Shea Stadium, which opened in 1964. Citi Field was designed by Populous.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8558,
        store_longitude: -73.642,
        total_orders: 4220.86,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Tacos, Lobster Tail, Sushi, Salad, Shrimp Cocktail, Gluten Free, Meatball, Spicy',
        annual_dol: 4675.04,
        description:
            'A menu of classic seafood & bistro dishes in a family-friendly setting with rustic decor & a patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6099,
        store_longitude: -73.9435,
        total_orders: 71.94,
        neighborhood: 'Marine Park',
        keywords: 'Beer',
        annual_dol: 294.08,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6563,
        store_longitude: -73.6451,
        total_orders: 8355.55,
        neighborhood: '',
        keywords:
            'Steak, Mexican, Tequila, Margarita, Dessert, Peruvian, Savory, Trendy, Spicy, Eclectic',
        annual_dol: 8734.17,
        description:
            'This festive cantina serves standard Mexican eats & takes on a nightclub vibe after dark.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.715,
        store_longitude: -73.5449,
        total_orders: 22.78,
        neighborhood: '',
        keywords: 'Local, Casual, Cool',
        annual_dol: 25.94,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7503,
        store_longitude: -73.9855,
        total_orders: 1138.35,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Coffee, Trendy, Delicious, Warm, Hotel, Spicy, Italian, Classy, Intimate, Perfect',
        annual_dol: 1187.7,
        description:
            'Retro haunt in Hotel Le Soleil with a New American menu, creative cocktails & a boutique coffee bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8307,
        store_longitude: -73.6863,
        total_orders: 802.42,
        neighborhood: '',
        keywords:
            'Burger, Vegan, Vegetarian, Hamburger, Salad, Gluten Free, Organic, Beer, Sustainable, American',
        annual_dol: 2810.35,
        description:
            'Casual, eco-minded regional chain for organic burgers (from beef to bison) & more, plus beer & wine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7547,
        store_longitude: -73.9925,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Burger, Hotel, American, Perfect, Friendly, Great, Rooftop, Chic, Unique',
        annual_dol: 0,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6649,
        store_longitude: -73.6324,
        total_orders: 100.87,
        neighborhood: '',
        keywords: 'Pizza, Italian, Wine, Delicious, Homemade, Casual, Authentic, Great',
        annual_dol: 412.34,
        description: '"Wood Fired Pizza and Italian Cuisine"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.657,
        store_longitude: -73.6421,
        total_orders: 129.53,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Gluten Free, Wine, Delicious, Elegant, Polished, Authentic, Cool, Casual',
        annual_dol: 160.17,
        description:
            'Northern Italian fare, brick-oven pizzas & wine in an intimate, upscale-casual setting with a bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6093,
        store_longitude: -73.9141,
        total_orders: 1146.72,
        neighborhood: 'Mill Basin',
        keywords: 'Wine, Hurricane, Robust, Amazing, Traditional, Modern',
        annual_dol: 1334.59,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7055,
        store_longitude: -74.0082,
        total_orders: 586.85,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Wine, Hotel, Beer, Spacious, Global, Great, Friendly, Local',
        annual_dol: 599.3,
        description:
            "Modern, loft-style rooms feature 11-foot ceilings, oak floors and bleached wood window seats. All rooms have free Wi-Fi, iPod docks and flat-screen TVs, plus minibars. Room service is offered.Dining options include a farm-to-table restaurant, a courtyard beer garden and a cocktail bar. There's also a 24-hour fitness center and a spa, plus a business center and event space, including 5 high-tech conference rooms, a courtyard and a garden terrace.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8196,
        store_longitude: -73.8174,
        total_orders: 83.21,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Wine, Beer, Coffee, Italian, Gluten Free, Irish, Tangy, Bbq, Spicy, Cozy',
        annual_dol: 340.15,
        description:
            'Relaxed, brick-walled Irish pub & eatery serving eclectic comfort grub such as steaks, fish & wraps.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7066,
        store_longitude: -74.0081,
        total_orders: 358.88,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Fun, Classic, Fine, Local, Unique',
        annual_dol: 1466.99,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5816,
        store_longitude: -73.8303,
        total_orders: 134.44,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Park',
        keywords: 'Pizza, French, Friendly, American, Local, Great, Casual',
        annual_dol: 153.35,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5971,
        store_longitude: -73.6591,
        total_orders: 794.91,
        neighborhood: '',
        keywords: 'Lobster, Salad, Delicious, Yummy, Modern, Casual, Sweet, Fun, Cool, American',
        annual_dol: 1662.1,
        description:
            'New England seafood staples & raw-bar options at a waterside haunt with a deck & fire-pit tables.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6255,
        store_longitude: -73.7362,
        total_orders: 30.56,
        neighborhood: '',
        keywords:
            'Vodka, Shrimp Cocktail, Dessert, Coffee, French, Pinacolada, American, Sweet, Ample, Casual',
        annual_dol: 32.52,
        description:
            'Spacious, old-school diner offering American comfort food favorites all day, plus desserts.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6566,
        store_longitude: -73.6484,
        total_orders: 1057.82,
        neighborhood: '',
        keywords:
            'Tacos, Steak, Burger, Shrimp Cocktail, Cinnamon Roll, Wine, Vodka, Gourmet, Beer, Margarita',
        annual_dol: 1080.26,
        description:
            '"The Ainsworth is an intimate venue that features eclectic rustic decor, bringing a sophisticated twist to the classic restaurant experience. The creative, high-quality menu highlights its 24 karat gold wings and signature steaks along with an extensive craft cocktail and beer menu."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6636,
        store_longitude: -73.645,
        total_orders: 185.33,
        neighborhood: '',
        keywords: 'Salad, Italian, Chinese, Beer, Bbq, Hotel, Local, Hummus',
        annual_dol: 525.3,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8598,
        store_longitude: -73.6213,
        total_orders: 249.58,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Salad, Italian, Shrimp Cocktail, Welcoming, Wonderful, Great, Casual, Fine',
        annual_dol: 284.68,
        description:
            'Casual, welcoming Italian eatery, offering classics like mussels, gnocchi & housemade limoncello.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7565,
        store_longitude: -73.996,
        total_orders: 1669.71,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Vegetarian, Vegan, Burger, Coffee, Italian, Martini, Upscale, Contemporary, American, Seasonal',
        annual_dol: 1742.1,
        description:
            'Offshoot of a Russian-based chain for coffee drinks & upscale American & international fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6076,
        store_longitude: -73.943,
        total_orders: 1539.02,
        neighborhood: '',
        keywords:
            'Salad, Shrimp Cocktail, Tasty, Italian, Chinese, Shrimp Scampi, Upscale, Elegant, Warm, Filet Mignon',
        annual_dol: 1571.68,
        description:
            'Upscale Italian eatery from 1964 serving traditional fare in a warm, elegant space with a full bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6172,
        store_longitude: -73.7302,
        total_orders: 78.91,
        neighborhood: '',
        keywords: 'Steak, Salad, Sushi, French, Upscale, Bbq, Elegant, Prime Rib, Asian, Casual',
        annual_dol: 202.99,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7688,
        store_longitude: -73.7043,
        total_orders: 7.26,
        neighborhood: '',
        keywords: 'Golfclub, Friendly, Perfect, Clean, Great',
        annual_dol: 9.6,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7439,
        store_longitude: -73.9227,
        total_orders: 17896.04,
        neighborhood: 'Astoria/Sunnyside',
        keywords: 'Wine, Whiskey, Beer, Steak, Ale, Margarita, Rustic, Cozy, Spacious, Irish',
        annual_dol: 18516.29,
        description:
            'Intimate neighborhood spot with a warm, rustic feel offering inventive cocktails & craft tap beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6877,
        store_longitude: -73.8207,
        total_orders: 86.92,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Flavorful, Beer, Delicious, Cozy, Eclectic, Adventurous, Exotic, Caribbean, Intimate, Amazing',
        annual_dol: 94.59,
        description:
            'Cozy, energetic club featuring DJs spinning West Indian & Caribbean music, a full bar & VIP areas.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7509,
        store_longitude: -73.9841,
        total_orders: 583.78,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Vegan, Tasty, Vegetarian, Beer, German, Wine, Delicious, Spicy, Spacious, Fun',
        annual_dol: 609.09,
        description:
            'Hearty German eats & fine beers are served in a casual, Bavarian publike setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8323,
        store_longitude: -73.699,
        total_orders: 241.64,
        neighborhood: '',
        keywords:
            'French, Italian, Delicious, Comfortable, Homey, Friendly, Contemporary, Casual, Authentic, American',
        annual_dol: 269.05,
        description:
            'Wines from the cellar wash down traditional French plates at this bustling, homey bistro.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7565,
        store_longitude: -73.9924,
        total_orders: 2373.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Champagne, Rooftop, Hotel, Spacious, Beer, Mediterranean, Moroccan, Fun, American, Great',
        annual_dol: 8974.46,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.753,
        store_longitude: -73.989,
        total_orders: 120.4,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Tacos, Vegetarian, Mexican, Salad, Burritos, Casual, Healthy, Delicious, Friendly, Local',
        annual_dol: 512.39,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7781,
        store_longitude: -73.6375,
        total_orders: 287.81,
        neighborhood: '',
        keywords: 'Elegant, Clean, Exquisite, Innovative, Exceptional, Fine, Creative, Local',
        annual_dol: 300.29,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5815,
        store_longitude: -74.1114,
        total_orders: 195.58,
        neighborhood: 'Todt Hill/Midland Beach',
        keywords: 'Italian, Beer, Rich, Swiss, Mediterranean, Japanese, Sweet, American, Casual',
        annual_dol: 203.63,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6507,
        store_longitude: -73.8693,
        total_orders: 2782.79,
        neighborhood: 'Spring Creek/East New York',
        keywords:
            'Steak, Burger, Hamburger, Pizza, Italian, Beer, Wine, Dessert, Chicken Wings, American',
        annual_dol: 3190.45,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6548,
        store_longitude: -73.6458,
        total_orders: 946.49,
        neighborhood: '',
        keywords:
            'Italian, Burger, Pizza, Salad, Gluten Free, Upscale, Comfortable, Casual, American, Clean',
        annual_dol: 978.47,
        description:
            '"Lenox & Park is a neighborhood Italian bistro with a casual, yet sophisticated dining experience. Full service catering for all your party needs."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7508,
        store_longitude: -73.9858,
        total_orders: 16054.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Karaoke, Tasty, Upscale, Delicious, Savory, Inviting, Fun, Exquisite, Vibrant, Premium',
        annual_dol: 22172.21,
        description:
            'Vibrant destination for cocktails, dancing & DJs, plus bar bites & private karaoke rooms.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6899,
        store_longitude: -73.8254,
        total_orders: 282.26,
        neighborhood: 'South Richmond Hill',
        keywords: 'Casual, Spicy, Classic',
        annual_dol: 498.58,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8302,
        store_longitude: -73.7023,
        total_orders: 1169.74,
        neighborhood: '',
        keywords:
            'Steak, Lobster, Salad, Mexican, Italian, Delicious, Organic, Asian, American, Spectacular',
        annual_dol: 1272.98,
        description:
            'Iconic seafood spot dating to 1905 with deck seating & bay view, plus weekly bands & mahogany bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.751,
        store_longitude: -73.9851,
        total_orders: 858.73,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Steak, Bourbon, Wine, Legendary, Delicious, American, Exceptional, Great, Fine, Casual',
        annual_dol: 881.24,
        description:
            "Dry-aged steak, seafood & pasta surrounded by dark mahogany in John Barrymore's former townhouse.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.657,
        store_longitude: -73.6422,
        total_orders: 781.53,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Pizza, Salad, Dairy Free, Seafood Paella, Italian, Meatball, Gluten Free',
        annual_dol: 815.42,
        description:
            'Italian-American steakhouse with pasta, wine & cocktails in a snug, rustic-chic space with a patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6652,
        store_longitude: -73.7213,
        total_orders: 4298.68,
        neighborhood: '',
        keywords:
            'Lobster, Shrimp Cocktail, Steak, Pizza, Salad, Cajun, Italian, Juicy, Caesar Salad, American',
        annual_dol: 4396.84,
        description:
            'Lively chain restaurant serving American seafood standards amid New England-themed decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6556,
        store_longitude: -73.6458,
        total_orders: 458.36,
        neighborhood: '',
        keywords: 'Steak, Pizza, Tasty, Italian, Wine, Champagne, Coffee, Dessert, Beer, Delicious',
        annual_dol: 864.57,
        description:
            '"Mangia Bene is an modern Italian restaurant and bar located in Rockville Centre, NY. Specializing in fresh house made pasta and artisan wood fired pizza, Mangia Bene\'s sleek and sexy feel blends a modern take on old world Italian favorites."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6035,
        store_longitude: -73.6509,
        total_orders: 808.79,
        neighborhood: '',
        keywords:
            'Italian, Wine, Classy, Greek, Healthy, Friendly, Traditional, Casual, Sangria, Sophisticated',
        annual_dol: 887.46,
        description:
            '"We are a casually sophisticated Italian restaurant serving traditional and inventive Italian Cuisine."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.706,
        store_longitude: -74.0091,
        total_orders: 5401.46,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Salad, Pizza, Italian, Whiskey, Chilean, Luxurious, Mediterranean, Romantic, Warm, Premium',
        annual_dol: 5635.65,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.626,
        store_longitude: -73.9275,
        total_orders: 603.28,
        neighborhood: 'Flatlands',
        keywords:
            'Salad, Bbq, Caribbean, Jamaican, French, American, Indian, Authentic, Casual, Great',
        annual_dol: 799.37,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8131,
        store_longitude: -73.8227,
        total_orders: 43.95,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Local, Creamy',
        annual_dol: 59.83,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7573,
        store_longitude: -73.8703,
        total_orders: 168.69,
        neighborhood: 'North Corona/East Elmhurst/Flushing',
        keywords:
            'Steak, Salad, Vegetarian, Chicken Caesar Salad, Shrimp Cocktail, Wine, Tasty, Dessert, Beer, Mediterranean',
        annual_dol: 689.56,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7483,
        store_longitude: -73.9846,
        total_orders: 2400.42,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Beer, Flavorful, Ale, American, Spicy, Tex-Mex, Traditional, Seasonal, Fresh, Local',
        annual_dol: 2789.98,
        description:
            'Branch of local brewery chain has 2 large bars & serves rotisserie chicken & other American food.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7455,
        store_longitude: -73.6028,
        total_orders: 9199.49,
        neighborhood: '',
        keywords:
            'Luxurious, Upscale, Perfect, Modern, Picturesque, Great, Remarkable, Creative, Unforgettable, Heritage',
        annual_dol: 9929.33,
        description:
            '"Renowned Long Island caterers Chateau Briand invites you to experience a truly unique setting for a wide array of events including weddings, birthday parties, family reunions and corporate retreats. Experience the remarkable lobby that boasts twin fireplaces, a baby grand piano, and a picturesque 42-foot staircase."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6556,
        store_longitude: -73.6483,
        total_orders: 340.26,
        neighborhood: '',
        keywords:
            'Burger, Steak, Hamburger, Vegan, Tequila, Milkshake, Butter Chicken, Dessert, Chinese, American',
        annual_dol: 349.98,
        description:
            'Creative burgers, over-the-top shakes & craft beers are dished out in a funky warehouse setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6036,
        store_longitude: -73.9156,
        total_orders: 3264,
        neighborhood: 'Mill Basin',
        keywords:
            'Lobster Roll, Lobster, Shrimp Cocktail, Lobster Bisque, Steak, Salad, Pizza, Bbq, Wine, Clam Chowder',
        annual_dol: 3686.96,
        description:
            'Casual seafood restaurant serving fish, lobster & steak with dinner specials & raw bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7478,
        store_longitude: -73.9851,
        total_orders: 1072.02,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Burger, Mexican, Sushi, Vodka, Rustic, American, Delicious, Modern, Polished',
        annual_dol: 1094.77,
        description:
            'Street-level American bar & grill in the Empire State Building with art deco design touches.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6723,
        store_longitude: -73.7508,
        total_orders: 74.3,
        neighborhood: 'Laurelton/Jamaica/Springfield Gardens',
        keywords: 'Southern, Bbq, Dutch, American',
        annual_dol: 303.73,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8537,
        store_longitude: -73.6192,
        total_orders: 224.81,
        neighborhood: '',
        keywords:
            'Tasty, Gluten Free, Upscale, Vegetarian, Delicious, Mexican, Friendly, French, Brazilian, Fresh',
        annual_dol: 234.55,
        description:
            '"Upscale New Brazilian Cuisine, French influenced.Charcoal Fire Grill.Offer vegetarian and gluten free options"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7305,
        store_longitude: -74.0006,
        total_orders: 14553.76,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Classic, Authentic, Casual, Hip, Great',
        annual_dol: 14875.95,
        description:
            'A variety of blues, hip-hop, folk and comedy acts take the stage at this classic Village venue.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7475,
        store_longitude: -73.8836,
        total_orders: 103.78,
        neighborhood: 'Elmhurst/Jackson Heights/Flushing',
        keywords: 'Beer, Colombian, Delicious, Fun, Premium, Ramen, Great, Local, Casual',
        annual_dol: 443.47,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6982,
        store_longitude: -73.975,
        total_orders: 966.64,
        neighborhood: 'Brooklyn Navy Yard',
        keywords: 'Rooftop, Wine, Iconic, Diverse',
        annual_dol: 3951.29,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7183,
        store_longitude: -73.9904,
        total_orders: 10091.05,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Wine, Japanese, Local, Casual, Sweet, Chill, Great',
        annual_dol: 10878.99,
        description:
            '"Neighborhood bar in the Lower East Side serving beer, wine, spirits, mixed drinks, and cocktails. Free shuffleboard!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7181,
        store_longitude: -73.9928,
        total_orders: 13924.59,
        neighborhood: 'Bowery',
        keywords: 'Wine, Thai, Delicious, Bourbon, Gin, Cozy, Meatball, Eclectic, Classy, Fun',
        annual_dol: 16339.38,
        description:
            'Colorful nook offering Thai comfort fare in lunchbox-style to-go boxes plus a small grocery section.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.9066,
        store_longitude: -73.8486,
        total_orders: 334.16,
        neighborhood: 'East Bronx/Wakefield',
        keywords: 'Elegant, Perfect, Great',
        annual_dol: 1365.92,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7669,
        store_longitude: -73.9862,
        total_orders: 213.49,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Lobster, Pizza, Salad, Wine, Shrimp Cocktail, Italian, Upscale, Beer, Delicious, Mimosa',
        annual_dol: 261.94,
        description:
            'Airy, spacious seafood joint & wine bar specializing in small plates, oysters, crudo & charcuterie.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.822,
        store_longitude: -73.9502,
        total_orders: 834.59,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Tacos, Tequila, Mexican, Burger, Margarita, Vegetarian, Salad, Wine, Spicy, Trendy',
        annual_dol: 1572.24,
        description:
            'Innovative Mexican cuisine & cocktails are served in an earth-toned space with splashes of art.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7406,
        store_longitude: -73.9878,
        total_orders: 15.83,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Mexican, Vegetarian, Burritos, Wine, Gluten Free, French, Local, Great',
        annual_dol: 64.71,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6333,
        store_longitude: -74.0268,
        total_orders: 170.64,
        neighborhood: 'Bay Ridge',
        keywords: 'Burger, Steak, Pizza, Salad, Mexican, Flavorful, Tasty, Vodka, Beer, Wine',
        annual_dol: 697.52,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6393,
        store_longitude: -73.5828,
        total_orders: 1502.46,
        neighborhood: '',
        keywords:
            'Lobster, Steak, Shrimp Cocktail, Wine, Beer, Hurricane, Polished, American, Eclectic, Fun',
        annual_dol: 1751.31,
        description:
            'Nautical Mile mainstay for contemporary seafood with scenic bay views & weekend live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7662,
        store_longitude: -73.9867,
        total_orders: 28.14,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Salad, Italian, Wine, Dessert, Beer, Organic, Bruschetta, Filet Mignon, Modern, Caesar Salad',
        annual_dol: 115.05,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7708,
        store_longitude: -73.9029,
        total_orders: 10.82,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Burger, Salad, Italian, Coffee, Dessert, Nachos, Spicy, Delicious, Spanish, American',
        annual_dol: 44.22,
        description:
            'Airy, brick-walled bistro & bar offering a diverse menu of American & global fare, plus breakfast.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7425,
        store_longitude: -73.997,
        total_orders: 1402.93,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Vegetarian, Italian, Dessert, Martini, Delicious, Mediterranean, Seasonal, Casual, Traditional',
        annual_dol: 1474.4,
        description:
            'Neighborhood trattoria with a patio & vast Italian wine list serving Venetian pasta & seafood.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6808,
        store_longitude: -73.9756,
        total_orders: 4549.3,
        neighborhood: 'Park Slope',
        keywords:
            'Japanese, Wine, Delicious, Romantic, Legendary, Hotel, Beer, Comfortable, Contemporary, Fantastic',
        annual_dol: 5873.39,
        description:
            'Wood-accented contemporary izakaya offering cocktails & food with a Japanese spin.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5597,
        store_longitude: -74.197,
        total_orders: 128.42,
        neighborhood: 'Arden Heights',
        keywords: 'Pizza, Whiskey, Fun, Unique, Local, Casual',
        annual_dol: 142.88,
        description:
            'Beer, cocktails & bar snacks are served in a low-key setting with TVs, pool tables & dartboards.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7479,
        store_longitude: -74.0042,
        total_orders: 602.4,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Beer, Cozy, Gourmet, Hotel, Roast Beef, Mediterranean, Eclectic, Fun, Exotic',
        annual_dol: 868.38,
        description:
            'Unpretentious wine bar offering European vintages & small plates in a wood-&-brick–lined setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7451,
        store_longitude: -73.9977,
        total_orders: 475.61,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Steak, Hamburger, Salad, Beer, Nachos, Margarita, Wine, American, Vintage',
        annual_dol: 485.7,
        description:
            'Lounge with a roadhouse vibe featuring vintage Americana & classic eats (burgers, tater tots, etc.).',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7404,
        store_longitude: -73.9959,
        total_orders: 297.74,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Burger, Cozy, Rooftop, Creative, Quirky, Lit, Unique',
        annual_dol: 1217.08,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.687,
        store_longitude: -73.9777,
        total_orders: 24.17,
        neighborhood: 'Fort Greene',
        keywords:
            'Wine, Beer, Tasty, Cozy, Classy, Classic, Traditional, Mediterranean, Intimate, Iconic',
        annual_dol: 98.8,
        description:
            'Down-to-earth, cozy gathering place serving small plates, draft beer & imported wines.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.729,
        store_longitude: -73.9786,
        total_orders: 935.02,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Irish, Eclectic, Local, Casual, Great, Unique',
        annual_dol: 954.86,
        description:
            'Relaxed, brick-walled bar with a pool table, Skee-Ball & a juke box, plus frequent live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5889,
        store_longitude: -73.6458,
        total_orders: 2385.64,
        neighborhood: '',
        keywords:
            'Burger, Salad, Mexican, Italian, Asian, Quesadillas, Homemade, Clean, Fun, American',
        annual_dol: 2596.19,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7172,
        store_longitude: -73.9429,
        total_orders: 361.21,
        neighborhood: 'Williamsburg',
        keywords: 'Cozy, Burger, Steak, Wine, Salad, Upscale, Delicious, Trendy, Beer, Classy',
        annual_dol: 601.96,
        description:
            'Informal plates, beer & spirits in an easygoing tavern with indoor & outdoor seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7116,
        store_longitude: -73.9461,
        total_orders: 1953.61,
        neighborhood: 'Williamsburg',
        keywords: 'Gluten Free, Tasty, Coffee, Spacious, Irish, Asian, Casual',
        annual_dol: 2903.17,
        description:
            'Neighborhood bar pouring beers on tap & a full line-up of booze, with a pool table & a back patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5882,
        store_longitude: -73.6653,
        total_orders: 439.78,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Sushi, Italian, Mexican, Japanese, Crab Rangoon, Martini, Asian, Funky',
        annual_dol: 710.41,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7177,
        store_longitude: -73.9563,
        total_orders: 268.92,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Ale, Wine, Mead, Laid-Back, Refreshing, Spectacular, Great, Chill, Casual',
        annual_dol: 1099.24,
        description: '"Microbrewery, bar and restaurant."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7163,
        store_longitude: -73.961,
        total_orders: 1167.32,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, German, Salad, Tasty, Hungarian, Spicy, Casual, Classic, Chill, Great',
        annual_dol: 1303.74,
        description:
            'Sprawling, boisterous beer hall offering brews, schnitzel & regularly scheduled live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7683,
        store_longitude: -73.9825,
        total_orders: 3118.88,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Beer, Wine, Ramen, Gin, Spicy, American, Asian, Japanese, Sleek, Cointreau',
        annual_dol: 3301.28,
        description:
            'Busy, casual walk-in Asian outlet in a shopping mall serving noodles & ramen from the open kitchen.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7201,
        store_longitude: -73.9878,
        total_orders: 2675.82,
        neighborhood: 'Lower East Side',
        keywords: 'Tacos, Wine, Beer, Martini, Trendy, Spicy, Romantic, Thai, Asian, French',
        annual_dol: 2889.02,
        description:
            'Swanky cocktail lounge known for its Asian tapas, revolving art shows & lychee martinis.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7702,
        store_longitude: -73.9765,
        total_orders: 11.26,
        neighborhood: 'Central Park West Historic District',
        keywords: 'Burger, Beer, Casual, Friendly, Great, Sangria',
        annual_dol: 46.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5452,
        store_longitude: -74.1659,
        total_orders: 314.24,
        neighborhood: 'Arden Heights',
        keywords: 'Steak, Italian, Pizza, Coffee, Upscale, Delicious, Excellent, Casual, Local',
        annual_dol: 384.37,
        description:
            'Brick-oven pizzas, Italian dishes & cocktails served in down-to-earth surrounds with a heated patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7299,
        store_longitude: -73.9809,
        total_orders: 2156.35,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Beer, Meatball, Wine, Spicy, Ale, American, Eclectic, Vintage, Casual',
        annual_dol: 2202.11,
        description:
            'Convivial hangout with vintage decor offering eclectic bar fare, cocktails & beers on tap.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7114,
        store_longitude: -73.9446,
        total_orders: 406.96,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Italian, Pizza, Wine, Trendy, Ale, Premium, Relaxed, Contemporary, Perfect, Great',
        annual_dol: 473.71,
        description:
            'Relaxed storefront serving lesser known Italian pastas & small plates plus wine & backyard seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7253,
        store_longitude: -73.9833,
        total_orders: 2088.94,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Wine, Friendly, Casual, Great, Diverse',
        annual_dol: 2133.26,
        description:
            'Buzzy, celeb-owned bar with drag & cabaret shows, plus other diverse entertainment.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5823,
        store_longitude: -74.1688,
        total_orders: 307.62,
        neighborhood: 'New Springville/Mid Island',
        keywords:
            'Steak, Burger, Salad, Beer, Dessert, Chicken Wings, Ale, Tex-Mex, American, Casual',
        annual_dol: 498.74,
        description:
            'Full-service chain bar & grill providing hearty American eats in an informal setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7143,
        store_longitude: -73.9604,
        total_orders: 742.62,
        neighborhood: 'Williamsburg',
        keywords: 'Salad, Steak, Korean, Wine, Beer, Bbq, Organic, Japanese, Tangy, Cozy',
        annual_dol: 889.19,
        description:
            'Busy Korean-style BBQ eatery with tableside grills & veggie options in a cozy ambiance.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7179,
        store_longitude: -73.9566,
        total_orders: 510.31,
        neighborhood: 'Williamsburg',
        keywords: 'Mexican, Salad, Breakfast Burrito, Authentic, Fresh',
        annual_dol: 2085.98,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7752,
        store_longitude: -73.9839,
        total_orders: 0,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Mexican, Lobster Bisque, American, Contemporary, Delicious, Casual, Clam Chowder, Local, Traditional',
        annual_dol: 0,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6623,
        store_longitude: -73.9621,
        total_orders: 921.13,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords: 'Zombie, Wine, Bbq, Juicy, Cozy, American, Warm, Crisp, Spacious, Greek',
        annual_dol: 1737.45,
        description:
            'Creative bar eats & craft cocktails served in chill digs with a pool table, fireplace & patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.723,
        store_longitude: -73.9975,
        total_orders: 2636.58,
        neighborhood: 'Lower Manhattan',
        keywords: 'Champagne, Vodka, Hotel, Zombie, Beer, Gin, Rum, Pizza, Eclectic, Moscow Mule',
        annual_dol: 3014.27,
        description:
            "and 2 miles from the Empire State Building.Sophisticated rooms with high ceilings and floor-to-ceiling windows feature smart TVs, Blu-ray players, Bose systems and free Wi-Fi, plus minibars. Suites add sitting areas; upgraded suites with 1 or 2 bedrooms have separate living rooms with dining areas, and terraces. Room service is available 24/7.There's a plush restaurant with a bar and a seasonal terrace, a posh lounge and a gym, as well as a rooftop garden.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7581,
        store_longitude: -73.7874,
        total_orders: 822.92,
        neighborhood: 'Auburndale/Flushing',
        keywords: 'Burger, Sushi, Salad, Pizza, Gourmet, Korean, Wine, Spicy, Delicious, Trendy',
        annual_dol: 3680.08,
        description:
            '"THE BANDI = FIREFLY KOREAN STYLE BAR Voted New York’s Best Korean Style Bar & Foods It has always been a dream of Sara’s to open a small place where New Yorkers could relax to an evening of delicious Korean Food and fun drinks. After 20 years of working in the corporate world, she decided to pursue her passion for cooking. As such, Sara opened her first bar and restaurant in Queens to provide people with refreshing food and beverages. Sara believes in using fresh ingredients in a simple way so they bring out their own essence. Inspired by her love for foods from all over the world, she has added a Korean flare to a simple and healthy menu. With unique and appealing Soju cocktails, this establishment has a pleasant experience waiting..."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8147,
        store_longitude: -73.9594,
        total_orders: 1288.49,
        neighborhood: 'Upper Manhattan/Morningside Heights',
        keywords:
            'Burger, Salad, Hamburger, Tasty, Wine, Bread Pudding, French Toast, Beer, French, Bbq',
        annual_dol: 1315.83,
        description:
            'American comfort fare like burgers & sandwiches in a roomy, retro diner space plus a full bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8181,
        store_longitude: -73.9608,
        total_orders: 1569.99,
        neighborhood: 'Upper Manhattan',
        keywords: 'Burger, Beer, Bbq, Tasty, American, Healthy, Fun, Friendly, Casual, Perfect',
        annual_dol: 3817.64,
        description:
            'Budget pub grub & brews are served at this bustling bar with TVs for watching sports.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7646,
        store_longitude: -73.992,
        total_orders: 1073.72,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Italian, Wine, Fun, Fancy, Mediterranean, Awesome, Israeli, Great, American, Asian',
        annual_dol: 1142.64,
        description:
            'Locavore spot from Israeli celebrity chef with a reservations-only experience & a theatrical vibe.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7643,
        store_longitude: -73.9814,
        total_orders: 9529.77,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Burger, Beer, Spicy, Karaoke, New-York-Style, Wine, Warm, Irish, Gin, Rum',
        annual_dol: 9762.72,
        description:
            'Watering hole with a Prohibition theme serving craft cocktails, beer, wine & bar bites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7645,
        store_longitude: -73.9773,
        total_orders: 1643.34,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Wine, Vodka, Salad, Italian, American, Hotel, Mediterranean, Homey, Premium',
        annual_dol: 5769.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.762,
        store_longitude: -73.976,
        total_orders: 669.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Pizza, Steak, Italian, Dessert, Korean, Californian, Organic, Upscale, Spicy',
        annual_dol: 683.34,
        description:
            'Stylish, art-adorned Californian eatery frequented by high-profile media personalities.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7223,
        store_longitude: -73.992,
        total_orders: 7065.8,
        neighborhood: 'Bowery',
        keywords: 'Whiskey, Beer, Wine, German, Trendy, Upscale, American, Warm, Fun, Classic',
        annual_dol: 7305.83,
        description:
            'Bar specializing in whiskey, served in a space lined with subway tiles and antique mirrors.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7213,
        store_longitude: -73.9874,
        total_orders: 393.32,
        neighborhood: 'Lower East Side',
        keywords:
            'Tasty, Nutritious, Mexican, Delicious, Margarita, Savory, Salad, Nachos, South American, Eclectic',
        annual_dol: 491.05,
        description:
            'Local stalwart for standard Mexican fare & margaritas in a no-frills setting that keeps late hours.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7154,
        store_longitude: -73.99,
        total_orders: 2.42,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Beer, Wine, Karaoke, Margarita, Fun, Friendly, Great, Cool, Iconic, Edgy',
        annual_dol: 9.88,
        description:
            'Storied punk rock bar & music club with an edgy backdrop of graffiti-covered walls & booths.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6248,
        store_longitude: -74.0303,
        total_orders: 5.09,
        neighborhood: 'Bay Ridge',
        keywords:
            'Lasagna, Pizza, Steak, Italian, Vodka, Martini, Delicious, Homemade, Fabulous, Fresh',
        annual_dol: 20.81,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7385,
        store_longitude: -74,
        total_orders: 370.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Salad, Vietnamese, Exquisite, Asian, Exotic, Fresh, Great, Excellent, Casual',
        annual_dol: 434.01,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 26.47,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Mexican, Italian, Gin, Premium, Stylish, Global, Great, Chill',
        annual_dol: 108.21,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7408,
        store_longitude: -73.7844,
        total_orders: 5389.15,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords: 'Steak, Burger, Tacos, Beer, Salad, Pizza, Fresh, Creamy, Dessert, Tangy',
        annual_dol: 5503.5,
        description:
            'Sports bar & grill chain famous for chicken wings served by waitresses in short shorts.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7175,
        store_longitude: -73.9501,
        total_orders: 4687.22,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Pizza, Salad, Italian, Chinese, Phenomenal, French, American, Sweet, Local',
        annual_dol: 4901.39,
        description:
            'Relaxed, light-filled eatery with a horseshoe bar serving American plates & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.631,
        store_longitude: -74.1035,
        total_orders: 603.23,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Steak, Vegetarian, Chocolate Mousse, Tasty, Gourmet, Southern Comfort, Bourbon, Beer, Coffee, Bbq',
        annual_dol: 1156.99,
        description:
            '"Sally\'s Southern is Staten Island\'s only southern comfort and BBQ restaurant Proudly serving craft cocktails, an extensive beer and bourbon list and gourmet southern fare. Our namesake is homage to the late Sally Curtis. In her words and her spirit #Live Love Laugh and # Eat and Enjoy!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.718,
        store_longitude: -73.9525,
        total_orders: 2466.72,
        neighborhood: 'Williamsburg',
        keywords: 'Vegan, Beer, Wine, Modern, Hotel, Funky, American, Delicious, Perfect, Fresh',
        annual_dol: 2519.06,
        description:
            '"Thief, the first solo venture from John McNulty (Swine, Cocktail Kingdom Hospitality Group), has a mission to steal back the time spent away from your favorite bar. A modern take on the neighborhood bar, Thief is inspired in design and energy by the 1980\'s NYC graffiti, art and music scene. The menus feature riffs on classic cocktails including frozen drinks such as a Friesling, an extensive wine program and elevated bar food from Smoked Ham & Gruyere sandwiches to Vegan Mini Corn Dogs."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7474,
        store_longitude: -73.9767,
        total_orders: 418.48,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Lobster, Hamburger, Salad, Beer, Gourmet, Wine, French Toast, American, French, Contemporary',
        annual_dol: 1710.61,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7186,
        store_longitude: -73.9607,
        total_orders: 300.78,
        neighborhood: 'Williamsburg',
        keywords:
            'Korean, Spicy, Delicious, Urban, Organic, Savory, American, Nachos, Rooftop, Sweet',
        annual_dol: 567.33,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7462,
        store_longitude: -73.978,
        total_orders: 153.04,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Korean, Beer, Spicy, Homemade, Delicious, Bbq, Wine, Cozy, Friendly, Fun',
        annual_dol: 537.24,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7454,
        store_longitude: -73.9777,
        total_orders: 5.36,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Wine, Salad, Dessert, Delicious, Nutritious, Beer, Mediterranean, Healthy, Turkish, Modern',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.752,
        store_longitude: -73.9912,
        total_orders: 5546.56,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Stylish, Chic, Creative, Excellent, Contemporary',
        annual_dol: 5666.75,
        description:
            'Theater District.Warm, contemporary rooms feature free Wi-Fi and flat-screen TVs, marble bathrooms and minifridges. Many have city and/or skyline views, and some have balconies or terraces. Suites add living rooms. Room service is available.An airy restaurant serves international cuisine and has a dining terrace. There are several stylish lounges and bars, as well as a fitness center, and an alcove lined with digital screens with constantly shifting scenery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7644,
        store_longitude: -73.9817,
        total_orders: 1588.22,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Wine, Rich, Velvety, Hotel, Romantic, Spicy, Rum, Inviting, Premium, Charming',
        annual_dol: 1657.08,
        description:
            'Cozy, vintage-style lounge with velvet seating for elevated craft cocktails & sophisticated snacks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7004,
        store_longitude: -73.8102,
        total_orders: 69.98,
        neighborhood: 'Jamaica',
        keywords: 'Fun, Impeccable, Cool',
        annual_dol: 148.37,
        description:
            'Spacious, high-energy nightspot featuring dancing, DJs & live performances plus cocktails & drinks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7637,
        store_longitude: -73.9794,
        total_orders: 464.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Sushi, Italian, Delicious, Red Eye, French, Romantic, American, Innovative, Robust',
        annual_dol: 494.56,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7426,
        store_longitude: -74.0002,
        total_orders: 1159.72,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Tomato Soup, Mexican, French, Coffee, Beer, Margarita, Cozy, American',
        annual_dol: 1658.89,
        description:
            'Two-level restaurant serving casual French fare paired with craft cocktails, beer & Champagne.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7426,
        store_longitude: -74.0006,
        total_orders: 2229.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Beer, Tacos, Tasty, Wine, Salad, Spicy, Chinese, Innovative, American, Asian',
        annual_dol: 2303.73,
        description:
            'Hip, airy hangout with exposed-brick walls pairing craft beer with innovative bar fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7411,
        store_longitude: -74.0013,
        total_orders: 594.12,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Pizza, Flavorful, Italian, Delicious, Rich, Warm, Cozy, Romantic, Rustic',
        annual_dol: 625.42,
        description:
            'Old-school white-tablecloth Italian eatery serving homemade pastas from a wood-burning oven.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7397,
        store_longitude: -73.9962,
        total_orders: 63.16,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Tasty, Delicious, Chilean, Salad, Premium, Robust, Impressive, Perfect, Great',
        annual_dol: 114.64,
        description:
            'White-tablecloth eatery with classic Mediterranean sharing plates & mains, plus a robust wine list.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7073,
        store_longitude: -74.0063,
        total_orders: 3112.32,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Italian, Warm, Modern, Classic, Friendly, American, Seasonal, Rooftop, Casual',
        annual_dol: 3461.81,
        description:
            "the Brooklyn Bridge.The simple, modern rooms have free Wi-Fi and bottled water, plus 42-inch flat-screen TVs, and desks with ergonomic chairs. Suites add pull-out sofas and separate living areas.There's a casual American restaurant and a rooftop bar with a seasonal terrace, as well as a 24/7 fitness room. Additional amenities include 7 meeting rooms and a business center.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6715,
        store_longitude: -73.9627,
        total_orders: 3611.02,
        neighborhood: '',
        keywords: 'Friendly, Great, Fresh, Excellent, Casual, Cultural, Seasonal',
        annual_dol: 14760.65,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7136,
        store_longitude: -73.9413,
        total_orders: 40.15,
        neighborhood: 'East Williamsburg/Williamsburg',
        keywords:
            'Vegan, Champagne, Coffee, Martini, Organic, Delicious, Seasonal, Latin, Friendly, Sweet',
        annual_dol: 72.97,
        description:
            'Quaint, family-run cafe preparing seasonal Guatemalan-inspired breakfast, lunch & dinner.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.714,
        store_longitude: -73.9562,
        total_orders: 2422.67,
        neighborhood: 'Williamsburg',
        keywords:
            'Cornbread, Whiskey, Salad, Beer, Gluten Free, Bourbon, Spicy, Bbq, German, Sweet',
        annual_dol: 2434.07,
        description:
            'Popular dry-rub BBQ, beer & bourbon purveyor with an industrial, cafeteria-style decor & wall mural.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 331.95,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Lobster, Lobster Roll, Shrimp Cocktail, Salad, Gluten Free, Spicy, Martini, Avocado Toast, Organic',
        annual_dol: 1356.92,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7108,
        store_longitude: -73.9509,
        total_orders: 71.21,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Tacos, Mexican, Steak, Nachos, Salad, Beer, Margarita, Spicy, Friendly, Fresh',
        annual_dol: 295.37,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7507,
        store_longitude: -73.9806,
        total_orders: 24220.94,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Sushi, Wine, Japanese, Spicy, Delicious, Upscale, Trendy, Sashimi, Cozy, Spacious',
        annual_dol: 29477.85,
        description:
            'Trendy, high-end Japanese place with an izakaya-inspired menu including sushi & robata grilled fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7623,
        store_longitude: -73.9256,
        total_orders: 17160.19,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Steak, Lobster, Cajun, Whiskey, Bbq, Wine, Organic, American, French',
        annual_dol: 20010.99,
        description:
            'This eatery offers twists on New American comfort fare in a contemporary updated-diner setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7469,
        store_longitude: -73.9804,
        total_orders: 1342.82,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Salad, Pizza, Italian, Wine, Delicious, Gourmet, Upscale, Warm, Ale, Mediterranean',
        annual_dol: 1385.43,
        description:
            'Decades-old Italian restaurant offering an array of traditional fare in an upscale setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7511,
        store_longitude: -73.9803,
        total_orders: 808.8,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Burger, Salad, Swiss, Irish, French, Fried Shrimp, Greek, Hotel, Greek Salad, Casual',
        annual_dol: 861.95,
        description:
            'Laid-back pub with busy weekday happy hours serves traditional Irish bar food late into the night.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.757,
        store_longitude: -73.9241,
        total_orders: 570.1,
        neighborhood: 'Astoria/LIC',
        keywords: 'Pizza, Italian, Salad, Wine, Vodka, Delicious, Swiss, Bbq, Homemade, Yummy',
        annual_dol: 594.81,
        description:
            'Family-run local mainstay serving up crispy coal-oven pizzas plus pastas in a simple setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7447,
        store_longitude: -73.987,
        total_orders: 150.05,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Luxurious, Delicious, Savory, Spacious, Impeccable, Hotel, American, Bright',
        annual_dol: 166.95,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7633,
        store_longitude: -73.9791,
        total_orders: 16100.17,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Hamburger, French, Italian, French Onion Soup, Delicious, American, Contemporary, Whimsical, Classic',
        annual_dol: 21917.39,
        description:
            'Elevated brasserie serving contemporary takes on French classics in a whimsical multi-room space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7645,
        store_longitude: -73.9833,
        total_orders: 4415.06,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Scotch, Wine, Shrimp Scampi, Gluten Free, Upscale, Cosmopolitan, American',
        annual_dol: 5191.05,
        description:
            'Contemporary chophouse at Hilton Midtown with a classic menu including seafood & California wines.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7642,
        store_longitude: -73.9786,
        total_orders: 4271.26,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Tequila, Coffee, Hotel, Delicious, American, Elegant, Velvety, Spacious, Rustic',
        annual_dol: 4456.44,
        description:
            "Sleek rooms are warmly decorated with cherry and cedar wood furniture, and include minibars, flat-screen TVs and free Wi-Fi, plus work desks with ergonomic chairs; suites add full kitchens, living areas and views of Central Park.There are 2 acclaimed restaurants, plus a burger joint and a hip cocktail bar. There's also a gym, a rooftop pool, and a multilevel fitness center with racquetball and basketball courts (as well as personal trainers), plus a spa. Other amenities include a barbershop, a salon and an event ballroom.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.765,
        store_longitude: -73.9813,
        total_orders: 194.28,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Sushi, Lobster, Beer, Japanese, Tasty, Sashimi, Hotel, American, Sweet, Casual',
        annual_dol: 794.14,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7222,
        store_longitude: -73.9901,
        total_orders: 593.35,
        neighborhood: 'Bowery',
        keywords:
            'Sushi, Lobster, Japanese, Impeccable, Amazing, Fantastic, Incredible, Great, Excellent, Prosecco',
        annual_dol: 605.94,
        description:
            'Intimate, moody eatery supplying set & a la carte menus of creative maki & nigiri, plus cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7205,
        store_longitude: -73.9902,
        total_orders: 283.22,
        neighborhood: 'Bowery',
        keywords:
            'Burger, Steak, Dairy Free, Salad, Gluten Free, Meatball, Coffee, Beer, Breakfast Burrito, Wine',
        annual_dol: 639.04,
        description:
            'Hip gathering place serving seasonally-driven, global plates & cocktails, plus a happy hour.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7433,
        store_longitude: -73.9893,
        total_orders: 105.72,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Mexican, Margarita, Wine, Trendy, Spicy, Delicious, Vibrant, Sophisticated, Friendly, Authentic',
        annual_dol: 432.15,
        description:
            'Trendy counter-serve with a patio & a Mexican menu created by celebrity chef Rick Bayless.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7798,
        store_longitude: -73.8026,
        total_orders: 162.76,
        neighborhood: 'Whitestone/Clearview/Flushing',
        keywords:
            'Lobster, Steak, Vegetarian, Salad, Tasty, Wine, Coffee, Dessert, Apple Pie, Grilled Salmon',
        annual_dol: 173.58,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.725,
        store_longitude: -73.9815,
        total_orders: 6095.9,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Wine, Godfather, American, Classic, Friendly, Authentic, Sweet, Awesome, Great',
        annual_dol: 6554.71,
        description:
            'Long-running, no-nonsense bar featuring a jukebox loaded with punk & an old-school photobooth.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7248,
        store_longitude: -73.9812,
        total_orders: 1148.58,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Steak, Burger, Salad, French, Wine, Upscale, Gin, Retro, Polished, Casual',
        annual_dol: 1150.05,
        description:
            'Parisian-style bistro dishes & wine served in a cozy, retro space enhanced by back garden seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7135,
        store_longitude: -73.9617,
        total_orders: 2639.33,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Tequila, Beer, Lobster, Lobster Roll, Margarita, Warm, Hot Toddy, Friendly, Awesome, Cool',
        annual_dol: 2695.33,
        description:
            'Jumping, brick-walled watering hole featuring booze, a dog-friendly policy, games & a backyard.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7183,
        store_longitude: -73.9521,
        total_orders: 80.26,
        neighborhood: 'Williamsburg',
        keywords:
            'Tacos, Tequila, Mexican, Margarita, Steak, Dessert, Mimosa, Upscale, Trendy, Delicious',
        annual_dol: 145.5,
        description:
            'Cool locale for tacos, ceviche & upscale Mexican dishes with micheladas & other cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6831,
        store_longitude: -73.9657,
        total_orders: 1155.09,
        neighborhood: 'Clinton Hill',
        keywords:
            'Beer, Whiskey, Irish, Bourbon, Mead, Premium, Awesome, Great, Casual, Unpretentious',
        annual_dol: 1257.04,
        description:
            'Industrial-chic bar specializing in craft beers, also offering cocktails, bar snacks & sandwiches.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7131,
        store_longitude: -73.9494,
        total_orders: 123.83,
        neighborhood: 'Williamsburg',
        keywords: 'Sushi, Tacos, Salad, Wine, Spicy, Beer, Savory, Whiskey, Hawaiian, Japanese',
        annual_dol: 506.17,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7269,
        store_longitude: -73.9776,
        total_orders: 473.16,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Hamburger, Salad, Beer, Tasty, Wine, Juicy, Upscale, French, Armenian',
        annual_dol: 852.48,
        description:
            'East Village gastropub serving American comfort food in shareable small plates, plus weekend brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7178,
        store_longitude: -73.9524,
        total_orders: 122.53,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Salad, French, Italian, Swiss, Modern, Savory, Delicious, Casual, Perfect',
        annual_dol: 158.01,
        description: '"Modern French restaurant in the heart of Williamsburg"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7462,
        store_longitude: -73.9836,
        total_orders: 24271.51,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Sushi, Spacious, Korean, Bright, Great, Excellent, Modern, Intimate, Chic',
        annual_dol: 26570.86,
        description:
            'The minimalist, modern rooms, suites and studios provide free Wi-Fi, flat-screen TVs and coffeemakers, as well as minifridges and floor-to-ceiling windows. Unique features found in some quarters include original artwork, kitchens, balconies, a rooftop deck and/or a pull-out sofa.Breakfast at a neighboring cafe is complimentary. There are also two intimate sushi restaurants.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7642,
        store_longitude: -73.9766,
        total_orders: 19724.62,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Steak, Italian, Pizza, Shrimp Cocktail, Wine, Flavorful, Bourbon, Beer, Delicious, American',
        annual_dol: 20273.99,
        description:
            'Steakhouse with striking, contemporary design, plus an upmarket menu of chops & Italian fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7637,
        store_longitude: -73.9829,
        total_orders: 454.72,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Pizza, Garlic Bread, Italian, Meatball, Salad, Tasty, Delicious, Homemade, Fresh, Local',
        annual_dol: 494.85,
        description:
            'Family-friendly pizzeria serving thin-crust pies alongside calzones, pastas & salads.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6632,
        store_longitude: -73.6989,
        total_orders: 26.47,
        neighborhood: '',
        keywords: 'French, Casual, Fresh, Hip',
        annual_dol: 108.19,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7592,
        store_longitude: -73.985,
        total_orders: 11.39,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Modern',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7562,
        store_longitude: -73.8811,
        total_orders: 72.19,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Burger, Sushi, Steak, Salad, Korean, Spanish, Asian, Healthy, Casual, Natural',
        annual_dol: 295.08,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6821,
        store_longitude: -73.9796,
        total_orders: 526.7,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Beer, Cozy, Relaxed, Friendly, Cool, Chic, Casual, Great, Hummus',
        annual_dol: 1229.78,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6819,
        store_longitude: -73.9763,
        total_orders: 625.67,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Lobster, Steak, Vegan, Beer, Wine, Juicy, Delicious, Caribbean, Warm',
        annual_dol: 1135.65,
        description:
            'Warm fixture for Caribbean cuisine including jerk chicken, guava wings & fried plantain, plus a bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6807,
        store_longitude: -73.9747,
        total_orders: 474.56,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Vegetarian, Coffee, Organic, Zombie, Friendly, Modern, Casual, Ample, Cool, Sweet',
        annual_dol: 704.52,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7365,
        store_longitude: -73.8026,
        total_orders: 108.41,
        neighborhood: 'Fresh Meadows/Flushing/Pomonok',
        keywords:
            'Roast Turkey, Dessert, Fresh, American, Friendly, Contemporary, Traditional, Classic, Irish, Great',
        annual_dol: 407.42,
        description:
            'Alehouse in a Prohibition-era building providing contemporary American fare, including Irish nachos.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7477,
        store_longitude: -73.974,
        total_orders: 592.64,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords: 'Sushi, Steak, Dessert, Spicy, Delicious, Stylish, Japanese, Thai, Gin, Chinese',
        annual_dol: 644.94,
        description:
            'Relaxed, stylish bistro serving modern takes on Asian street-food favorites, plus sake & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7809,
        store_longitude: -73.9474,
        total_orders: 88.88,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Coffee, Dessert, Hotel, Premium, Local',
        annual_dol: 363.32,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7225,
        store_longitude: -73.9899,
        total_orders: 11874.18,
        neighborhood: 'Bowery',
        keywords:
            'Japanese, Tasty, Contemporary, Premium, Intimate, Traditional, Casual, Attentive, Okonomiyaki, Great',
        annual_dol: 12669.37,
        description:
            'Kenta Goto (ex Pegu Club) offers cocktails & food with a Japanese spin at this contemporary izakaya.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7181,
        store_longitude: -73.9877,
        total_orders: 2111.06,
        neighborhood: 'Lower East Side',
        keywords:
            'Burger, Italian, Delicious, Authentic, Great, Creative, Fresh, Local, Complex, Unique',
        annual_dol: 2483.36,
        description:
            'Theater complex with multiple screens featuring new release films, plush seating & concession stand.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7188,
        store_longitude: -73.9921,
        total_orders: 293.66,
        neighborhood: 'Bowery',
        keywords:
            'Vodka, Pizza, Champagne, Scotch, Shawarma, Mediterranean, Casual, Friendly, Modern, Cool',
        annual_dol: 717.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6928,
        store_longitude: -73.8561,
        total_orders: 33.62,
        neighborhood: 'Woodhaven',
        keywords:
            'Beer, Ecuadorian, Mexican, Coffee, Chinese, American, Latin, Awesome, Great, Casual',
        annual_dol: 86.93,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.639,
        store_longitude: -73.5816,
        total_orders: 21.04,
        neighborhood: '',
        keywords: 'Dominican, Memorable',
        annual_dol: 86,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6874,
        store_longitude: -73.9864,
        total_orders: 1621.36,
        neighborhood: 'Boerum Hill',
        keywords: 'Burger, Tacos, Beer, Whiskey, Wine, Vegetarian, Rum, American, Cozy, Delicious',
        annual_dol: 1891.38,
        description:
            'A robust craft beer menu & typical dishes offered in a cozy venue with brick walls & tin ceilings.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7407,
        store_longitude: -73.9929,
        total_orders: 563.91,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Delicious, Rich, Salad, Wine, Greek, Meatball, Outstanding, Classy, Authentic, Great',
        annual_dol: 575.87,
        description:
            'Established Greek restaurant noted for its high-end fare & civilized, atmospheric setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5423,
        store_longitude: -74.1772,
        total_orders: 541.59,
        neighborhood: 'Arden Heights',
        keywords:
            'Burger, Vegetarian, Gourmet, Gluten Free, Italian, Organic, Mediterranean, American, Homemade, Casual',
        annual_dol: 675.48,
        description:
            'Bustling local spot dishes up sizeable portions of classic diner fare & homemade Italian specialties',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.74,
        store_longitude: -73.9932,
        total_orders: 93.54,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Tacos, Mexican, Tequila, Upscale, Delicious, Contemporary, Casual, Intimate, Modern, Sweet',
        annual_dol: 241.03,
        description:
            'Classy, intimate venue featuring familiar Mexican dishes, plus an extensive drinks menu.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7273,
        store_longitude: -73.9798,
        total_orders: 817.76,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Beer, Delicious, Warm, Comfortable, Friendly, Eclectic, Fancy, Asian, Great',
        annual_dol: 910.32,
        description:
            'Small, easygoing bar & community event space featuring seasonal cocktails & a backyard beer garden.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5859,
        store_longitude: -73.695,
        total_orders: 51.93,
        neighborhood: '',
        keywords: 'Hamburger, Friendly',
        annual_dol: 74.87,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7273,
        store_longitude: -73.9798,
        total_orders: 67.3,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Wine, Beer, Delicious, Warm, Comfortable, Friendly, Eclectic, Fancy, Traditional, Great',
        annual_dol: 275.08,
        description:
            'Small, easygoing bar & community event space featuring seasonal cocktails & a backyard beer garden.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 2520.91,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Rooftop, Shrimp Cocktail, Bbq, Wine, Salad, Global, Amazing, Sweet',
        annual_dol: 10304.67,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7291,
        store_longitude: -73.9809,
        total_orders: 40.88,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Salad, Spicy, Delicious, Japanese, Thai, Romantic, Asian, Hotel, Smoky, Sweet',
        annual_dol: 167.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.748,
        store_longitude: -73.9839,
        total_orders: 280.95,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Iconic, Spectacular, Seasonal, Casual',
        annual_dol: 393.6,
        description:
            'Club-like setting for virtually fighting zombies, slaying dragons & flying, plus a cocktail lounge.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.808,
        store_longitude: -73.945,
        total_orders: 28501.8,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Cornbread, Salad, Wine, Dessert, Gluten Free, Beer, Stylish, Sweet, American, Southern',
        annual_dol: 29382.75,
        description:
            'Popular, stylish comfort food eatery with creative cooking from celeb chef Marcus Samuelsson.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7064,
        store_longitude: -73.8083,
        total_orders: 221.41,
        neighborhood: 'Briarwood/Jamaica',
        keywords:
            'Champagne, Margarita, Hawaiian, Rum, Caribbean, Quaint, Blue Hawaiian, Colorful, Casual',
        annual_dol: 695.96,
        description:
            'Laid-back, colorful club featuring DJs playing Caribbean dance music & island-inspired cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7191,
        store_longitude: -73.9943,
        total_orders: 1719.22,
        neighborhood: 'Bowery',
        keywords:
            'Stylish, Glamorous, Hotel, Coffee, Eclectic, Fun, Playful, Perfect, Clean, Modern',
        annual_dol: 7027.59,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6232,
        store_longitude: -74.031,
        total_orders: 303.42,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Coffee, Beer, Italian, Cozy, Elegant, Hotel, Rustic, Comfortable, Creamy, Mediterranean',
        annual_dol: 431.05,
        description:
            'Neighborhood fixture producing whole fish & other Greek eats in a traditional taverna setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6837,
        store_longitude: -73.9671,
        total_orders: 277.1,
        neighborhood: 'Clinton Hill',
        keywords:
            'Burger, Hamburger, Italian, Tasty, Hotel, American, Delicious, Rum, Indian, Casual',
        annual_dol: 294.89,
        description:
            "\"VESSEL BAR & GRILL is located in the heart of downtown Brooklyn's Fulton Street at Clinton Avenue. Offering lunch, dinner and late night dining with full bar services, we're only a short cab or pleasant walk from most of Brooklyn's major attractions.\"",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7185,
        store_longitude: -73.9491,
        total_orders: 106.81,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Beer, Wine, Whiskey, Gin, Cozy, Comfortable, Impeccable, Friendly, Casual',
        annual_dol: 131.05,
        description:
            'Craft cocktails from a cozy saloon in a distillery with exposed brick & views of the machinery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6501,
        store_longitude: -73.95,
        total_orders: 75.19,
        neighborhood: 'Little Haiti',
        keywords: 'Wine, Whiskey, Beer, Hotdog, Trendy, Romantic, Cozy, Premium, American, Classy',
        annual_dol: 307.37,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7128,
        store_longitude: -73.9568,
        total_orders: 194.09,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Coffee, Beer, Wine, Chinese, American, Natural, Fresh, Bright, Casual',
        annual_dol: 328.33,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7208,
        store_longitude: -73.9554,
        total_orders: 2025.57,
        neighborhood: 'Williamsburg',
        keywords:
            'Hotel, Elegant, Stylish, Rooftop, Coffee, Vibrant, Lively, Modern, Perfect, Great',
        annual_dol: 2633.55,
        description:
            "The contemporary rooms and suites have minibars, free Wi-Fi and flat-screen TVs. Some have city views.An elegant restaurant serves breakfast and dinner. There's a lively rooftop lounge with Manhattan skyline views, and a seasonal outdoor saltwater pool. Guests get free bike rentals; there's also on-site parking (fee).",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7605,
        store_longitude: -73.9219,
        total_orders: 204.98,
        neighborhood: 'Astoria/LIC',
        keywords: 'Mexican, Tacos, Nachos, Gourmet, Warm, Casual, Authentic, Local, Fresh, Rare',
        annual_dol: 771.92,
        description:
            'Narrow, down-to-earth restaurant specializing in homestyle Mexican cooking, cocktails & beer.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7625,
        store_longitude: -73.9754,
        total_orders: 293.33,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Wine, Chinese, Beer, Rum, Spicy, Elegant, Warm, Premium, Asian, Fusion',
        annual_dol: 1605.55,
        description:
            'Bi-level eatery serving a Shanghainese-centric menu in an elegant setting inspired by Old Shanghai.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7655,
        store_longitude: -73.9836,
        total_orders: 34.09,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Lasagna, Wine, Salad, Steak, Vodka, Pizza, Italian, Ale, Bruschetta, Elegant',
        annual_dol: 139.37,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7655,
        store_longitude: -73.9836,
        total_orders: 151.44,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Vegetarian, Thai, Coffee, Chinese, Spicy, Delicious, Ale, Fried Rice, Artisanal, Traditional',
        annual_dol: 633.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7195,
        store_longitude: -73.985,
        total_orders: 1056.43,
        neighborhood: 'Lower East Side',
        keywords:
            'Wine, Seasonal, Burger, Contemporary, French, Modern, American, Global, Asian, Casual',
        annual_dol: 1212.02,
        description:
            '"Our intimate, seasonal menu of modern American fare takes cues from Chef Sam Clonts’ time in some of New York City’s best kitchens; reflecting both his appreciation for local ingredients and a global approach. To drink, Raymond Trinh’s beverage team has crafted an eclectic wine list from old and new-world selections that satisfy a variety of tastes, available by the gloss or bottle. For something stronger, a cocktail menu rooted in the American classics with modern tweaks celebrates locally produced spirits, infusions and tinctures."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8691,
        store_longitude: -73.9175,
        total_orders: 330.02,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Beer, Wine, Irish, Legendary, Cozy, Picturesque, Inviting, Amazing, Great, Cool',
        annual_dol: 351.21,
        description:
            '"The Liffy II Bar is the last of picturesque Inwood’s Old Irish bars. The inviting oxblood exterior welcomes you to a bar that features original Irish bar decor including paintings,sketches and artifacts dating back to over a century. You can grab a $10 pitcher of beer, lounge on our plush sofas, play pool or ask the bartender for board games such as chess or Jenga. Have a decadent cocktail. Watch football or baseball on any of the 4 screens. Your group can watch their favorite tv show / soccer match in the pool table area. We do not sell food, so grab a menu from the bar and order in. The unpretentious energy of the Liffy II Bar transports you back to a simpler time of greeting your neighbors and after work cheers. Hope to see you soon!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6755,
        store_longitude: -73.8124,
        total_orders: 37.75,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords:
            'Steak, Champagne, Chinese, French Fries, Chicken Wings, Spicy, Fried Calamari, French, Caribbean, American',
        annual_dol: 154.3,
        description:
            '"West Indian bar & restaurant, serving the best Guyanese-Chinese authentic dishes Food Includes but is not limited to Beef Bone In Cha Chi Kai Chicken Boneless Cha Chi Kai Chicken Boneless Chicken Broccoli With Garlic Sauce Chicken Chicken In The Ruff Entree French Fries Fried Banga Mary Fried Calamari Fried Gizzard Fried Shark Garlic Chicken Garlic Chicken Wings Ginger Lamb Ginger Shrimp Ginger Soy Chunks Half Chicken Hot And Spicy Shrimp Hot Wings House Special Jerk Chicken Jerk Lamb Jerk Pork Jerk Soy Chunks Lamb Lap Chong Mixed (Chicken And Pork) Mixed Veg With Garlic Sauce Mozzarella Sticks Pepper Shrimp Pepper Steak Plantain Chips Popular Menu Items Pork Rasta Pasta Rasta Pasta Jerk Chicken Rasta Pasta Jerk Lamb Rasta Pasta Jerk Pork"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7554,
        store_longitude: -73.9694,
        total_orders: 116.85,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Wine, Delicious, Organic, Iconic, Classic, Authentic, Intimate, Contemporary, Great',
        annual_dol: 477.63,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6817,
        store_longitude: -73.9767,
        total_orders: 1418.06,
        neighborhood: 'Park Slope',
        keywords:
            'Beer, Wine, Irish, Warm, American, French, Spacious, Classy, Traditional, Friendly',
        annual_dol: 1465.37,
        description:
            'Irish pub fare, draft beer & cocktails served in a comfortable, warm setting with a long bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6748,
        store_longitude: -73.9718,
        total_orders: 801.73,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Salad, Beer, Vibrant, Bruschetta, Cool, Modern, American, Diverse',
        annual_dol: 856.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7417,
        store_longitude: -74.0046,
        total_orders: 142.39,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Lasagna, Italian, Pizza, Coffee, Delicious, Mediterranean, American, Perfect, Peking Duck, Classic',
        annual_dol: 582.05,
        description:
            'Posh Italian tasting menus & classic trattoria-style dishes crafted in an industrial-chic eatery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7441,
        store_longitude: -73.996,
        total_orders: 1065.6,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hamburger, Steak, Pizza, Beer, Wine, Martini, Cozy, Green Curry, Hotel, Irish',
        annual_dol: 1178.05,
        description: 'Pub offering bar food (with lunch specials), sports on TV & happy hours.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7462,
        store_longitude: -74.0013,
        total_orders: 1908.9,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Chicken Tikka Masala, Delicious, Elegant, Eclectic, Sleek, Indian, Modern, Classic, Casual',
        annual_dol: 2008.43,
        description:
            'Sleek destination for eclectic Indian fare with fanciful presentations, plus inventive drinks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6793,
        store_longitude: -73.9645,
        total_orders: 2896.69,
        neighborhood: 'Prospect Heights',
        keywords:
            'Wine, Whiskey, Coffee, Delicious, Gin, Cozy, Comfortable, Perfect, Sleek, Premium',
        annual_dol: 3022.28,
        description:
            'Local art is sold alongside cocktails & bites at this sleek haunt with a chill vibe & music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6826,
        store_longitude: -73.9612,
        total_orders: 1725.04,
        neighborhood: 'Clinton Hill',
        keywords: 'Beer, Delicious, Warm, Irish, Cozy, Classy, Relaxed, Intimate, Homey, Authentic',
        annual_dol: 1799.83,
        description:
            'Warm, cozy gathering place serving Irish comfort food with an American twist & local craft beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6874,
        store_longitude: -73.9571,
        total_orders: 1183.8,
        neighborhood: 'Bedford-Stuyvesant',
        keywords:
            'Beer, Margarita, Pinacolada, Hurricane, Daiquiri, Friendly, Classic, Awesome, Great, Cool',
        annual_dol: 1235.13,
        description:
            'Watering hole with New Orleans–style frozen drinks & beers, plus pinball & a jukebox.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6184,
        store_longitude: -74.1056,
        total_orders: 49427.14,
        neighborhood: 'Silver Lake/Castleton Corners/Mid Island',
        keywords:
            'Vodka, Upscale, Rustic, Romantic, American, Italian, Picturesque, Sweet, Intimate, Natural',
        annual_dol: 54161.24,
        description:
            'Upscale American restaurant & event venue in a rustic stone house with a lakefront patio & gardens.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.613,
        store_longitude: -74.1221,
        total_orders: 3.09,
        neighborhood: 'Castleton Corners/Mid Island',
        keywords: 'Burger, Steak, Pizza, Salad, Italian, Shrimp Cocktail, Casual',
        annual_dol: 12.63,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7123,
        store_longitude: -73.9664,
        total_orders: 118.5,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Burger, Steak, Salad, Dessert, Trendy, Brazilian, American, Authentic, Fun, Traditional',
        annual_dol: 148.82,
        description:
            "Rio de Janeiro's festive air comes to Brooklyn via traditional Brazilian cuisine & colorful decor.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7162,
        store_longitude: -73.9627,
        total_orders: 11989.08,
        neighborhood: 'Williamsburg',
        keywords:
            'Cheesecake, Mimosa, Delicious, Peruvian, French Toast, Uruguayan, French, Fun, Pinacolada, Rum',
        annual_dol: 15465.77,
        description:
            'Traditional Latin American dishes & cocktails served in a chic venue with private rooftop pods.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7137,
        store_longitude: -73.962,
        total_orders: 224.98,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Sushi, Salad, Spicy, Japanese, Upscale, Organic, Ramen, Asian, Fusion, Sleek',
        annual_dol: 355.2,
        description:
            'Brick walls & a display of sake bottles create a sleek setting for sushi & other Japanese dishes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7432,
        store_longitude: -73.9777,
        total_orders: 16.75,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Pizza, Chicken Lollipop, Italian, Savory, Delicious, Premium, Fine',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7622,
        store_longitude: -73.9777,
        total_orders: 827.39,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Chicken Pot Pie, Shrimp Cocktail, Beer, Bbq, Wine, Irish, Spicy, French, American',
        annual_dol: 3142.83,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8986,
        store_longitude: -73.8676,
        total_orders: 153.31,
        neighborhood: 'Woodlawn Heights/East Bronx',
        keywords: 'Beer, Irish, Awesome, Classic, Great, Casual',
        annual_dol: 205.47,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7475,
        store_longitude: -73.8865,
        total_orders: 789.63,
        neighborhood: 'Jackson Heights',
        keywords: 'Karaoke, Wine, Fun, Colorful, Friendly, Amazing, Great, Modern, Lit, Latin',
        annual_dol: 1046.3,
        description:
            'Dimly lit Latin gay club with happy hours, karaoke nights, cocktails & party nights.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6805,
        store_longitude: -73.9775,
        total_orders: 970.91,
        neighborhood: 'Park Slope',
        keywords: 'Wine, Pizza, Beer, Dessert, Italian, Irish, Fun, Fancy, Classy, Friendly',
        annual_dol: 1991.19,
        description:
            '"A fancy cocktail bar, a low-key neighborhood microbrew hangout, and a great place for date night"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6778,
        store_longitude: -73.9727,
        total_orders: 821.94,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Lasagna, Burger, Cajun, Whiskey, Vodka, French Toast, Bbq, Delicious, French, Rum',
        annual_dol: 3096.8,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6006,
        store_longitude: -73.8193,
        total_orders: 41.22,
        neighborhood: 'Broad Channel/Far Rockaway',
        keywords: 'Salad, Fun, Hotel, Great, American',
        annual_dol: 48.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 262.29,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Spanish, Fun, Intimate, Awesome, Innovative, Amazing, Fantastic, Incredible, Great',
        annual_dol: 920.79,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5412,
        store_longitude: -74.1781,
        total_orders: 104.74,
        neighborhood: 'Woodrow',
        keywords:
            'Sushi, Salad, Tuna Steak, Steak, Pizza, Gluten Free, Beer, Tasty, Japanese, Spicy',
        annual_dol: 150.99,
        description:
            'Specialty sushi & Japanese entrees, plus beer & cocktails, served in a modern, lounge-like space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7166,
        store_longitude: -73.9512,
        total_orders: 1334.3,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Shrimp Cocktail, Italian, Wine, Fried Calamari, Classy, Retro, Casual, Traditional, Perfect',
        annual_dol: 1373.34,
        description:
            'Circa-1900 neighborhood Italian restaurant plating red-sauce standards in a retro setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7072,
        store_longitude: -74.0072,
        total_orders: 3186.14,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Italian, Pizza, Salad, Dessert, Coffee, Wine, Mimosa, Beer, Ample, Local',
        annual_dol: 3579.99,
        description:
            'Easygoing choice serving ample portions of standard Italian favorites, plus old-school pizzas.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.73,
        store_longitude: -73.9809,
        total_orders: 447.5,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Karaoke, Fun, Friendly, Great, Hospitable, Excellent, Casual, Chill',
        annual_dol: 457,
        description:
            'Amateur singers take the mike at this karaoke lounge with lipstick-red walls & zebra-print seats.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6802,
        store_longitude: -73.9612,
        total_orders: 87.39,
        neighborhood: 'Crown Heights/Clinton Hill',
        keywords:
            'Burger, Coffee, Beer, Iranian, Tasty, American, Casual, Terrific, Middle Eastern',
        annual_dol: 357.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7068,
        store_longitude: -74.0072,
        total_orders: 90.3,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Mexican, Tacos, Salad, Burritos, Australian, Delicious, Casual, Local, Recommended',
        annual_dol: 491.73,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7094,
        store_longitude: -73.9586,
        total_orders: 15.36,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Korean, Vegetarian, Spicy, Delicious, Bbq, Trendy, Asian, Phenomenal, Sweet, Casual',
        annual_dol: 62.78,
        description:
            'Unassuming, trendy neighborhood bar turning out cocktails, soju & Korean-fusion small plates.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7207,
        store_longitude: -73.9611,
        total_orders: 3586.07,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Coffee, Wine, Rum, Classic, Chill, Casual, Great, Incredible',
        annual_dol: 4747.48,
        description:
            'Vibrant, art-filled hangout offering frozen & classic cocktails, plus draft beer & bar bites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7444,
        store_longitude: -73.9842,
        total_orders: 2900.67,
        neighborhood: 'Midtown Manhattan/Midtown South/Rose Hill',
        keywords: 'Burger, Hamburger, Salad, Mimosa, Beer, Coffee, Wine, American, Spicy, Prosecco',
        annual_dol: 3193.95,
        description:
            'Bi-level tavern with a rooftop beer garden offering a menu of American comfort grub.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.745,
        store_longitude: -73.9832,
        total_orders: 76.45,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Burger, Hamburger, Beer, Whiskey, Delicious, Warm, American, Inviting, Hotel, Awesome',
        annual_dol: 312.5,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8002,
        store_longitude: -73.6512,
        total_orders: 7464.69,
        neighborhood: '',
        keywords:
            'Tequila, Wine, Steak, Upscale, Dessert, Elegant, American, Crisp, Eclectic, Inviting',
        annual_dol: 7626.41,
        description:
            'Elegant, historic estate turned upscale pub featuring New American cuisine & a raw bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7502,
        store_longitude: -73.9862,
        total_orders: 3102.79,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Karaoke, Spanish, Japanese, Korean, Lively, Fun, Awesome, Friendly, Recommended, Unique',
        annual_dol: 3309.41,
        description:
            'Lively karaoke lounge with drinks, a variety of rooms & a long, frequently updated song list.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7478,
        store_longitude: -73.9866,
        total_orders: 472.92,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Upscale, Warm, Iconic, Welcoming, Classy, Fun, Great, Cool, Casual',
        annual_dol: 531.02,
        description:
            'Neon-hued karaoke bar serving soju, nibbles & more to folks singing privately or in the main lounge.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6598,
        store_longitude: -73.9613,
        total_orders: 2551.53,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Meatloaf, Meatball, Coffee, Beer, Wine, Gumbo, Mediterranean, American, Cozy, Asian',
        annual_dol: 2605.67,
        description:
            'Chill neighborhood bar pouring craft cocktails & barista coffee drinks in a hip, low-key setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6537,
        store_longitude: -73.5829,
        total_orders: 111.28,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Avocado Toast, French, English Muffin, Delicious, Bruschetta, Homemade, American, Casual',
        annual_dol: 126.75,
        description:
            'Classic diner offering elevated breakfast, lunch & dinner options, plus curbside pick-up.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7569,
        store_longitude: -73.9807,
        total_orders: 300.36,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords:
            'Gluten Free, Steak, Spicy, Delicious, Mediterranean, Japanese, Turkish, Friendly, Local, Authentic',
        annual_dol: 1509.53,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6881,
        store_longitude: -73.9875,
        total_orders: 17.69,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Wine, Karaoke, Intimate, Zombie, Amazing, Great, Clean, Fresh, Middle Eastern',
        annual_dol: 72.32,
        description:
            '"Welcome to The Atlantic BKLN, one of Brooklyn’s newest and most intimate live music venues. The Atlantic BKLN hosts live music performances of all genres, featuring jazz, blues, folk, rock, weekly live band karaoke nights and is available for private event rentals. Our sound system, designed and installed by Clair Global, is one of the best in the country. Our 200-capacity venue aims to enhance the guest experience with a state of the art clean air handling system that ensures fresh air is constantly circulating throughout the space. Get ready, we are so excited to share with you this amazing space!!!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.739,
        store_longitude: -73.9995,
        total_orders: 3596,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vegetarian, Salad, Wine, Beer, Ale, Upscale, Cuban, Stylish, Mediterranean',
        annual_dol: 4095.59,
        description:
            'Local ingredients meet Mediterranean flavors at this laid-back kitchen with an industrial vibe.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7447,
        store_longitude: -73.9987,
        total_orders: 223.37,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Chinese, Wine, Margarita, Spicy, Delicious, Sweet, Hotel, Traditional, Authentic, Colorful',
        annual_dol: 391.15,
        description:
            'Unassuming Chinese restaurant serving traditional hot & cold dishes, plus dim sum & lunch specials.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7281,
        store_longitude: -73.9819,
        total_orders: 145.32,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Burger, Salad, Coffee, Wine, Meatball, Margarita, Martini, Delicious, Trendy, Korean',
        annual_dol: 594.04,
        description:
            'Laid-back restaurant with artworks & a patio, furnishing alcohol, charcuterie & traditional bites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6785,
        store_longitude: -73.9586,
        total_orders: 2856.84,
        neighborhood: 'Crown Heights',
        keywords: 'Funky, Fancy, Fun, Casual, Great',
        annual_dol: 3046.75,
        description:
            'Neighborhood bar/performance venue offering theme nights & dance parties, plus live music & DJs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7142,
        store_longitude: -73.9514,
        total_orders: 2006.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Cajun, Beer, Whiskey, Coffee, American, Mediterranean, Retro, Bright, Amazing',
        annual_dol: 2519.57,
        description:
            'Brick-walled hangout with a central bar & dining tables, offering craft beers and snacks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5882,
        store_longitude: -73.6692,
        total_orders: 501.48,
        neighborhood: '',
        keywords:
            'Steak, Tequila, Salad, Wine, Mexican, American, Contemporary, Spicy, Irish, Polished',
        annual_dol: 524.11,
        description:
            'Casual, island-style haunt with a buzzy bar & patio offering New American fare & a broad wine list.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7325,
        store_longitude: -73.8104,
        total_orders: 23.22,
        neighborhood: 'Fresh Meadows/Flushing/Pomonok',
        keywords: 'Fresh, Chinese, Cool, Hotel, Retro, Polish, Local',
        annual_dol: 94.91,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6444,
        store_longitude: -74.0765,
        total_orders: 165.48,
        neighborhood: 'St. George/Mid Island',
        keywords:
            'Steak, Lobster, Sushi, Japanese, Turkish, Gluten Free, Sashimi, Teriyaki Salmon, Fusion, Delicious',
        annual_dol: 676.41,
        description:
            '"Wasabi Steak & Sushi is the premier dining location for Japanese cuisines. Consistently voted as a top dining destination. A clean modern décor and sophisticated interior sets the perfect atmosphere for an unparalleled dining experience. We are the perfect venue for any occasion. Join our Hibachi dining room where veteran chefs prepare spectacular displays of food right before your eyes."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.731,
        store_longitude: -73.9826,
        total_orders: 410.48,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Wine, Salad, Coffee, Delicious, Greek, American, Friendly, Fresh, Casual',
        annual_dol: 457.39,
        description:
            'Classic Greek dishes, such as calamari & souvlaki, are served in an airy space with an open kitchen.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.719,
        store_longitude: -73.9546,
        total_orders: 63.62,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Vegan, Tasty, Upscale, Delicious, Spicy, Jamaican, Caribbean, Healthy, Rum',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7138,
        store_longitude: -73.9655,
        total_orders: 8945.26,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Salad, American, Trendy, Contemporary, Modern, Casual, Sweet, Innovative, Fun, Signature',
        annual_dol: 9139.51,
        description:
            "Atera alum's American restaurant with a marketplace, barroom & upstairs dining room & garden.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7113,
        store_longitude: -73.9658,
        total_orders: 34.81,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Green Papaya Salad, Beer, Salad, Vietnamese, Cozy, Spicy, Friendly, Fun, Papaya Salad, Casual',
        annual_dol: 39.64,
        description:
            'Classic Vietnamese fare served in a brick-lined warehouselike space with a bar & rooftop.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7636,
        store_longitude: -73.9216,
        total_orders: 14.78,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Milkshake, Avocado Toast, Chinese, Delicious, American, Mediterranean, Modern, Retro, Fun',
        annual_dol: 60.42,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7671,
        store_longitude: -73.9865,
        total_orders: 585.99,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Sushi, Vegetarian, Wine, Beer, Japanese, Delicious, Upscale, Stylish, Spacious, Asian',
        annual_dol: 1368.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.766,
        store_longitude: -73.9808,
        total_orders: 86.15,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Casual',
        annual_dol: 352.15,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7628,
        store_longitude: -73.9851,
        total_orders: 2541.53,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hamburger, Vodka, Salad, Wine, Beer, Russian, Irish, Perfect, Awesome, Indian',
        annual_dol: 2769.88,
        description:
            'Serving pub grub, this haunt with dark-wood decor features 15+ flat-screen TVs & a spot for darts.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.765,
        store_longitude: -73.9792,
        total_orders: 725.22,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Italian, Trendy, Spanish, Intimate, Casual, Superb',
        annual_dol: 2746.28,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8623,
        store_longitude: -73.9254,
        total_orders: 48.17,
        neighborhood: 'Fort George',
        keywords:
            'Steak, Lobster, Salad, Tasty, Dessert, Chinese, Delicious, Dominican, Asian, Latin',
        annual_dol: 196.9,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.634,
        store_longitude: -73.5819,
        total_orders: 656.07,
        neighborhood: '',
        keywords: 'Ale, Beer, Lobster, Lobster Roll, Cajun, Salad, Wine, Nachos, American, Casual',
        annual_dol: 698.18,
        description:
            'Beer comes in huge Styrofoam cups at this long-standing barroom with bras hanging from the ceiling.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8641,
        store_longitude: -73.9265,
        total_orders: 10.82,
        neighborhood: 'Fort George',
        keywords: 'Peruvian, Delicious, Casual, Sweet, Fun, Modern, Unique',
        annual_dol: 44.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6444,
        store_longitude: -73.5753,
        total_orders: 578.46,
        neighborhood: '',
        keywords:
            'Tequila, Mexican, Margarita, Vodka, Wine, Fajitas, French, Delicious, Triple Sec, Premium',
        annual_dol: 2364.54,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6805,
        store_longitude: -73.9751,
        total_orders: 31.22,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Spicy, Stylish, Creamy, Jamaican, Caribbean, Rum, Chinese, Exotic, Sweet',
        annual_dol: 127.62,
        description:
            'Lively Park Slope joint near the Barclays Center serving Caribbean food & drink.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6779,
        store_longitude: -73.9724,
        total_orders: 6224.86,
        neighborhood: 'Prospect Heights',
        keywords:
            'Wine, Beer, Trendy, Sweet, Southern Comfort, Gourmet, Southern, Classy, Casual, Hip',
        annual_dol: 6494.74,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7154,
        store_longitude: -73.9447,
        total_orders: 16.09,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Vegan, Dessert, Wine, Coffee, Vegetarian, Moroccan, Mediterranean, Israeli, Tranquil',
        annual_dol: 65.78,
        description:
            'Contemporary Mediterranean eatery with a market-driven vegetarian menu & tranquil garden patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7233,
        store_longitude: -73.9853,
        total_orders: 666.01,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Steak, Beer, Chicken Pot Pie, Spicy, Delicious, Irish, Fabulous, Sweet, American, Welcoming',
        annual_dol: 950.72,
        description:
            'Welcoming bar & grill serving Irish & American flavors in a modern setting with distinctive decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.729,
        store_longitude: -73.9786,
        total_orders: 1569.79,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Coffee, Whiskey, Tacos, Beer, Pizza, Vietnamese, Quaint, Cozy, Lebanese, Comfortable',
        annual_dol: 1617.85,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7224,
        store_longitude: -73.9841,
        total_orders: 263.62,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Lasagna, Italian, Wine, Delicious, Meatball, Romantic, Friendly, Sweet, Homemade, Intimate',
        annual_dol: 1077.6,
        description:
            'Italian wines are paired with small plates at this rustic, brick-walled wine bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.686,
        store_longitude: -73.9731,
        total_orders: 53.69,
        neighborhood: 'Fort Greene',
        keywords: 'Salad, Burger, Apple Pie, Donut, Korean, Wine, Spicy, Japanese, Asian, American',
        annual_dol: 75.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7454,
        store_longitude: -73.9798,
        total_orders: 279.15,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Upscale, Hotel, Whiskey, Bourbon, Rooftop, Margarita, Bright, Outstanding, Cozy, Classic',
        annual_dol: 1141.07,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7243,
        store_longitude: -73.9945,
        total_orders: 131.11,
        neighborhood: 'Nolita',
        keywords: 'Sushi, Salad, Wine, Dessert, Beer, Spicy, Japanese, Creamy, Sashimi, Asian',
        annual_dol: 535.93,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7675,
        store_longitude: -73.9895,
        total_orders: 666.36,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Chocolate Cake, Salad, Flavorful, Tasty, Delicious, Upscale, Trendy, Romantic, Mediterranean, Japanese',
        annual_dol: 818.04,
        description:
            'Mediterranean-style cuisine served in a polished venue that has outdoor seating & a shisha lounge.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7633,
        store_longitude: -73.9861,
        total_orders: 83.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Chocolate Mousse, Shrimp Cocktail, Tasty, Wine, Beer, French, Lively, Casual, Terrific, Great',
        annual_dol: 355.13,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.718,
        store_longitude: -73.9892,
        total_orders: 852.88,
        neighborhood: 'Lower East Side',
        keywords:
            'Beer, Pizza, American, Vintage, Stylish, Classic, Spacious, Ample, Local, Casual',
        annual_dol: 2197.74,
        description:
            '"12 Lanes of classic ten-pin bowling, full bar with up to 24 American craft beers on tap, full kitchen featuring brick oven pizza. Open every day."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6349,
        store_longitude: -73.6404,
        total_orders: 39.98,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Organic, Local, Casual, Classic',
        annual_dol: 255.37,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7758,
        store_longitude: -73.91,
        total_orders: 231.13,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Elegant, Friendly, Cool, Casual, Great, American, Amazing, Fantastic, Recommended',
        annual_dol: 463.61,
        description:
            '"The doctors and staff of Astoria Foot Care Group, P.C. located in Astoria, New York would like to welcome you to our web site. As you navigate through the site you will find a wealth of information about podiatry and treatments available. We invite you to call our office with any and all concerns you may have. At Astoria Foot Care Group, we take great pride in treating our patients with that extra bit of care and attention they deserve. From the moment you enter to the completion of the visit, you will be greeted with a sincere smile by the entire office staff. When you make an appointment at the Astoria Foot Care Group, our staff will verify your insurance coverage in advance."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7472,
        store_longitude: -73.8894,
        total_orders: 33.64,
        neighborhood: 'Jackson Heights',
        keywords: 'Mexican, Tacos, Fajitas, Casual, Perfect, Great, Diverse',
        annual_dol: 199.86,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6753,
        store_longitude: -73.9747,
        total_orders: 467,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Hamburger, Meatloaf, Tacos, Salad, Mexican, Cajun, Tequila, Jambalaya, Milkshake',
        annual_dol: 525.92,
        description:
            'Cozy, brick-clad outlet offering comfort & American dining classics, plus brunch & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7298,
        store_longitude: -73.9806,
        total_orders: 790.18,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Korean, French, Irish, Hotel, Retro, Casual, Local, Fresh, Unique',
        annual_dol: 806.95,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7142,
        store_longitude: -73.9445,
        total_orders: 56.96,
        neighborhood: 'Williamsburg',
        keywords: 'Steak, Tacos, Mexican, Vegan, Salad, Delicious, Pinacolada, Classic, Casual',
        annual_dol: 174.5,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8187,
        store_longitude: -73.9153,
        total_orders: 23.9,
        neighborhood: 'Melrose/West Bronx',
        keywords: 'Mexican, Casual',
        annual_dol: 30.82,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7159,
        store_longitude: -73.9622,
        total_orders: 2418.67,
        neighborhood: 'Williamsburg',
        keywords: 'Beer, Tasty, Margarita, Delicious, Bourbon, Coffee, Sweet, Fun, Vibrant, Woody',
        annual_dol: 2491.37,
        description:
            'Down-home, honky-tonk saloon pairing a woody space with beer, games & live country music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7636,
        store_longitude: -73.9216,
        total_orders: 747.3,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Beer, Steak, Pizza, Cajun, Irish, Warm, American, Cozy, Innovative',
        annual_dol: 779.7,
        description:
            'Easygoing pub with many craft brews on tap, seasonal sidewalk seating & updated comfort eats.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7451,
        store_longitude: -73.9759,
        total_orders: 179.38,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Vegetarian, Vegan, Steak, Tacos, Mexican, Tasty, Burritos, Delicious, Cozy, Enchiladas',
        annual_dol: 209.25,
        description:
            'Burritos, tacos & other Mexican staples are paired with margaritas at this small, colorful eatery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7622,
        store_longitude: -73.9254,
        total_orders: 779.42,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Whiskey, Beer, Irish, Warm, Yummy, Friendly, Classic, Mint Julep, Authentic, Casual',
        annual_dol: 831.24,
        description:
            'Classic Irish pub with a snug courtyard featuring a menu with familiar fare, plus local beer.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7429,
        store_longitude: -73.9821,
        total_orders: 3029.94,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Hotdog, Whiskey, Burger, Beer, Flavorful, Champagne, Wine, Bourbon, Gin, Cozy',
        annual_dol: 3094.23,
        description:
            'Dollars hang from the ceiling of this narrow, brick-lined watering hole for cocktails & draft beer.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7587,
        store_longitude: -73.9655,
        total_orders: 500.32,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Salad, Wine, Champagne, Bourbon, Beer, Avocado Toast, Martini, Organic',
        annual_dol: 517.94,
        description:
            'Rustic, nautical-themed eatery offering ample seafood plus craft cocktails & a weekend tiki brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.713,
        store_longitude: -73.9674,
        total_orders: 2855.6,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Vegetarian, Wine, Beer, Italian, Martini, Gin, Trendy, Upscale, Mediterranean, Bellini',
        annual_dol: 3003.38,
        description:
            'Contemporary-chic Italian dining space dishing up elevated antipasto & handmade pasta dishes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7435,
        store_longitude: -73.9836,
        total_orders: 367.08,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Steak, Wine, French, Classic, Iconic, Perfect, Homemade, Friendly, Great, Omelette',
        annual_dol: 1500.52,
        description:
            '"Located in the iconic Brasserie Les Halles space on Park Avenue South, Francis Staub, founder of the fabled cookware company (Staub) brings gourmand cooking to New York. A celebration of the French brasserie — restaurants loved for their casual, convivial atmosphere, ingredient-driven dishe,s and extensive Old World wine menu — La Brasserie was created with both indulgence and daily pleasure in mind."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7121,
        store_longitude: -73.963,
        total_orders: 119.89,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Awesome, Great, Casual',
        annual_dol: 490.07,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7439,
        store_longitude: -73.9792,
        total_orders: 50.87,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Wine, Delicious, Spicy, Spanish, Warm, Beer, Fun, Fabulous, Cozy, Sweet',
        annual_dol: 207.95,
        description:
            'Casual, brick-lined locale with a generous selection of wine, Spanish small plates & sidewalk seats.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7423,
        store_longitude: -73.9833,
        total_orders: 176.4,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Upscale, Stylish, Coffee, Delicious, Hotel, Gin, Beer, Classy, Fancy, Perfect',
        annual_dol: 247.13,
        description:
            'Stylish lounge outfitted with flat-screen TVs for watching sports while sipping craft cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7642,
        store_longitude: -73.9882,
        total_orders: 3721.09,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Burger, Vegetarian, Mexican, Salad, Beer, Margarita, Dessert, Delicious, Enchiladas',
        annual_dol: 4622.47,
        description:
            'A bustling eatery serving basic Mexican fare to a lively crowd, with DJs & a big margarita menu.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7649,
        store_longitude: -73.9831,
        total_orders: 1242.44,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Burger, Cajun, Salad, Beer, Chicken Pot Pie, Mexican, Wine, Ale, Irish, Delicious',
        annual_dol: 1296.31,
        description:
            'Wood-lined Irish pub sprawling over 3 floors & offering bar grub & plenty of draft beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.814,
        store_longitude: -73.945,
        total_orders: 1689.93,
        neighborhood: 'Harlem/West Harlem',
        keywords: 'Pizza, Beer, African, American, Intimate, Casual, Exquisite, Signature',
        annual_dol: 3348.65,
        description:
            'Gay bar with a dark, intimate vibe offering pizza & wings, plus beer & signature cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7656,
        store_longitude: -73.9877,
        total_orders: 1370.88,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Salad, Vegetarian, Vodka, Sushi, Whiskey, Dessert, French, Gin, Zen, Elegant',
        annual_dol: 1474.9,
        description:
            '"Ikebana Zen is a form of artwork that merges the urban life of New York with the Japanese bar through the abstract sense of ephemerality created by material, color, and light. Serving sake, craft cocktails, spirits are paired with small plates."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7198,
        store_longitude: -73.988,
        total_orders: 1187.77,
        neighborhood: 'Lower East Side',
        keywords:
            'Hotel, Upscale, Luxurious, Trendy, Lobster, Stylish, Italian, Coffee, Delicious, Funky',
        annual_dol: 1421.14,
        description:
            "The minimalist rooms have floor-to-ceiling windows, slate bathrooms and complimentary Wi-Fi. Upgraded rooms include balconies, deep soaking tubs, steam showers with glass exterior walls and additional bedrooms.A hip restaurant with Mediterranean fare features outdoor dining with street views, and a sky-lit atrium. There's also a juice bar on the ground floor, as well as a rooftop event venue.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7197,
        store_longitude: -73.985,
        total_orders: 4490.26,
        neighborhood: 'Lower East Side',
        keywords:
            'Sushi, Wine, Beer, Margarita, Trendy, Japanese, Delicious, Homemade, Sashimi, Seasonal',
        annual_dol: 4765.66,
        description:
            "Japanese izakaya serving small plates, sashimi & many sakes at a chef's bar in a modern, snug space.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7765,
        store_longitude: -73.7527,
        total_orders: 519.75,
        neighborhood: 'Flushing/Little Neck',
        keywords:
            'Asian, French Onion Soup, Colorful, French, Fresh, American, Unique, Great, Local, Chill',
        annual_dol: 554.3,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7558,
        store_longitude: -73.8848,
        total_orders: 84.52,
        neighborhood: 'Jackson Heights/Flushing',
        keywords:
            'Steak, Sushi, Salad, Delicious, Mediterranean, French, Colombian, Latin, Sangria, American',
        annual_dol: 345.49,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7559,
        store_longitude: -73.8842,
        total_orders: 30.86,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Mexican, Burger, Pizza, Beer, Sushi, Tex-Mex, Fun, Authentic, Great, Excellent',
        annual_dol: 126.15,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7441,
        store_longitude: -73.9939,
        total_orders: 606.16,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Hotel, Rooftop, Fresh',
        annual_dol: 2283.81,
        description:
            'Empire State Building.The unfussy rooms provide flat-screen TVs, Wi-Fi, minifridges and coffeemakers. Some feature skyline views and/or sofabeds.There’s an understated restaurant with a bar. Other amenities include a 24-hour fitness center and a rooftop terrace. Breakfast is available.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7285,
        store_longitude: -73.7839,
        total_orders: 3.16,
        neighborhood: 'Fresh Meadows/Flushing',
        keywords: 'Steak, Sushi, Spicy, Japanese, Brazilian, Fresh, Mediterranean, Casual',
        annual_dol: 12.93,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6773,
        store_longitude: -73.9576,
        total_orders: 1024.88,
        neighborhood: 'Crown Heights',
        keywords:
            'Burger, Mexican, Beer, Tasty, American, Eclectic, Vintage, Awesome, Classic, Perfect',
        annual_dol: 3861.4,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7061,
        store_longitude: -74.0036,
        total_orders: 2533.4,
        neighborhood: 'Financial District/Seaport',
        keywords: 'Steak, Salad, Italian, Spanish, Stylish, Bellini, Dutch, Warm, Perfect, Casual',
        annual_dol: 6969.29,
        description:
            'Warm spot offering seafood, oysters & prime-aged beef, plus a bar & views of the Brooklyn Bridge.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.631,
        store_longitude: -74.103,
        total_orders: 16.19,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Burger, Pizza, Salad, Tacos, Italian, Shrimp Cocktail, Vodka, Dessert, Bbq, Comfortable',
        annual_dol: 66.18,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7701,
        store_longitude: -73.9551,
        total_orders: 3481.19,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Salad, Apple Pie, Japanese, Czech, Savory, Hotel, Cozy, Ramen, Warm',
        annual_dol: 4469.17,
        description:
            'Bar inspired by the Meiji era serving craft cocktails in antique drinking vessels & Japanese fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7448,
        store_longitude: -73.987,
        total_orders: 6451.9,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Burger, Steak, Beer, Salad, Wine, Vodka, Roast Turkey, Turkey Sandwich, Dessert, Bread Pudding',
        annual_dol: 7554.27,
        description:
            'Relaxed pub with a rustic vibe & patio offering craft beers, New American small plates & sandwiches.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.766,
        store_longitude: -73.9915,
        total_orders: 1002.58,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Tasty, Cozy, Delicious, Polished, Vibrant, Mediterranean, Cool, Awesome, Casual',
        annual_dol: 1169.55,
        description:
            'Over 30 wines by the glass are available along with homemade NY pretzels & hand-crafted cheeses.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7231,
        store_longitude: -73.9898,
        total_orders: 2570.4,
        neighborhood: 'Bowery',
        keywords:
            'Beer, Whiskey, Lemon Meringue Pie, Wine, Irish, Lively, American, Fancy, Friendly, Traditional',
        annual_dol: 2738.07,
        description:
            'Pub by the Beerly Legal Group (The Jeffrey) offering brews, bourbons, seasonal drinks & bar bites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7165,
        store_longitude: -73.9878,
        total_orders: 165.88,
        neighborhood: 'Lower East Side',
        keywords: 'Mexican, Tequila, Beer, Steak, Bourbon, Wine, Tasty, Eclectic, Authentic, Local',
        annual_dol: 582.32,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6857,
        store_longitude: -73.8649,
        total_orders: 31.85,
        neighborhood: 'Jamaica/Woodhaven',
        keywords:
            'Lobster, Steak, Lobster Tail, Mexican, Dominican, Spanish, American, Latin, Teriyaki, Casual',
        annual_dol: 130.2,
        description:
            '"Hello Welcome to el Rey iv Restaurant, we are a restaurant with a great diversity of services and experiences such as billiards, event venue, bar, restaurant, arcade games and much more! If you need any information you can call us we will be happy to assist you"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7149,
        store_longitude: -73.9915,
        total_orders: 204.39,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Tequila, Beer, Steak, Ale, Margarita, Coffee, Tasty, Dessert, Trendy, Rum',
        annual_dol: 217.97,
        description:
            'Eclectic, health-conscious Californian-style fare dished in a minimalist, white-&-blond-wood space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7566,
        store_longitude: -73.9887,
        total_orders: 74,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Delicious, Dessert, Sweet, Great, Casual, Modern',
        annual_dol: 302.49,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7752,
        store_longitude: -73.9802,
        total_orders: 10.56,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Sushi, Vegetarian, Japanese, Chinese, Spicy, Asian, Delicious, Hotel, Urban, Casual',
        annual_dol: 43.19,
        description:
            'Informal spot offering a broad menu of Chinese & Japanese favorites for dine-in, takeout & delivery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7556,
        store_longitude: -73.8867,
        total_orders: 5.21,
        neighborhood: 'Jackson Heights/Flushing',
        keywords: 'Mexican, Italian, Ecuadorian, Casual, Healthy, American, Friendly, Latin, Great',
        annual_dol: 21.28,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8031,
        store_longitude: -73.6463,
        total_orders: 35.57,
        neighborhood: '',
        keywords: 'Sushi, Pizza, Cajun, Spicy, Beer, Japanese, Chilean, Premium, Casual, Perfect',
        annual_dol: 96.5,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7586,
        store_longitude: -73.9772,
        total_orders: 1527.49,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Lobster, Salad, Wine, Upscale, Mediterranean, Organic, Greek, Tranquil, Welcoming, Exquisite',
        annual_dol: 1716.57,
        description:
            'Upscale Greek-Mediterranean restaurant with a large seafood-focused menu & spacious, chic digs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5849,
        store_longitude: -73.8192,
        total_orders: 64.65,
        neighborhood: 'Seaside/Far Rockaway/Rockaway Beach/Rockaway Park',
        keywords: 'Burger, Salad, Tasty, Nachos, Wine, Bbq, Tangy, Chinese, Mediterranean, Hotel',
        annual_dol: 264.27,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7421,
        store_longitude: -74.0036,
        total_orders: 298.79,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Hotel, Stylish, Trendy, Premium, Fun, High-End, Intimate, Rooftop, Cool, Distinct',
        annual_dol: 610.68,
        description:
            'Trendy Dream Hotel basement bar with English-accented decor & a small-batch spirit menu.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8675,
        store_longitude: -73.9294,
        total_orders: 20.5,
        neighborhood: 'Upper Manhattan/Inwood',
        keywords:
            'Salad, Wine, Beer, Delicious, Avocado Toast, Latin, Clean, Modern, Friendly, Contemporary',
        annual_dol: 83.78,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7136,
        store_longitude: -73.9343,
        total_orders: 225.98,
        neighborhood: 'East Williamsburg',
        keywords: 'Beer, Gin, Rum, Funky, Fancy, Classy, Fun, Prosecco, Casual',
        annual_dol: 923.75,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7139,
        store_longitude: -73.9522,
        total_orders: 204.95,
        neighborhood: 'Williamsburg',
        keywords: 'Hotel, Upscale, Beer, Sleek, Vibrant, Contemporary, Great, Fresh, Local',
        annual_dol: 271.56,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7444,
        store_longitude: -73.9873,
        total_orders: 628.97,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Vegetarian, Gourmet, Coffee, Champagne, Tasty, Austrian, Italian, Ale, Delicious, Hotel',
        annual_dol: 1364.02,
        description:
            'Counter-serve deli with upstairs seating & sidewalk tables offering simple sandwiches & snacks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7099,
        store_longitude: -73.9687,
        total_orders: 66.15,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Pizza, American, Rooftop, Wine, Hotel, Southern, Classic, Casual, Complex',
        annual_dol: 190.52,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7192,
        store_longitude: -73.9587,
        total_orders: 28.01,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Beer, Southern, Casual, Thai',
        annual_dol: 114.51,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7423,
        store_longitude: -73.9804,
        total_orders: 40.53,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Vegetarian, Vegan, Tasty, Flavorful, Savory, Delicious, Spicy, Healthy, Asian, Friendly',
        annual_dol: 165.66,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7647,
        store_longitude: -73.9915,
        total_orders: 1524.55,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Whiskey, Wine, Mexican, Trendy, Ale, Crisp, Hotel, Lively, American',
        annual_dol: 1600.56,
        description:
            'Rotating craft beers, cocktails & elevated bar bites served amid exposed brick & retro touches.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7612,
        store_longitude: -73.983,
        total_orders: 449.46,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Burger, Whiskey, Tasty, American, Classic, Urban, Iconic, Fun, Traditional, Local',
        annual_dol: 1851.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7629,
        store_longitude: -73.9775,
        total_orders: 212.59,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Pizza, Vegan, Italian, Gluten Free, Hawaiian, Modern, Spicy, Contemporary, Retro, Artisanal',
        annual_dol: 266.99,
        description:
            'Wood-fired pies & other Neapolitan dishes served in a bi-level space adorned by modern art for sale.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7189,
        store_longitude: -73.985,
        total_orders: 2575.16,
        neighborhood: 'Lower East Side',
        keywords: 'Tequila, Wine, Vodka, Beer, Martini, Gin, Bourbon, Ale, Rum, Delicious',
        annual_dol: 2641.99,
        description:
            'Dimly lit bar with high tops & a communal table serves cocktails alongside oysters & seafood plates.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7221,
        store_longitude: -73.9866,
        total_orders: 874.52,
        neighborhood: 'Lower East Side',
        keywords: 'Beer, Hotel, Perfect, Authentic, Casual, Lively, Great, Eclectic',
        annual_dol: 893.08,
        description:
            "Indie headliners & up-and-comers have kept this small music venue crowded since the '90s.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7174,
        store_longitude: -73.9952,
        total_orders: 122.09,
        neighborhood: 'Bowery',
        keywords:
            'Karaoke, Chinese, Wine, Margarita, Japanese, Korean, Mojito, Asian, Modern, Great',
        annual_dol: 499.08,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7759,
        store_longitude: -73.9026,
        total_orders: 11.06,
        neighborhood: 'Astoria/Ditmars Steinway/Long Island City',
        keywords:
            'Sushi, Burger, Salad, Wine, Spicy, Upscale, Trendy, Mediterranean, Sashimi, English Muffin',
        annual_dol: 45.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8729,
        store_longitude: -73.9184,
        total_orders: 37.73,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords: 'Burger, Vegan, Beer, Wine, Coffee, Spicy, Southern, Casual, Friendly, Local',
        annual_dol: 154.24,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7552,
        store_longitude: -73.979,
        total_orders: 2257.5,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Lobster, Salad, Cajun, Shrimp Scampi, Bourbon, Wine, Upscale, Legendary, Caesar Salad',
        annual_dol: 2991.29,
        description:
            'Upscale chain for aged prime beef, seafood & other traditional steakhouse fare in a clubby space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6858,
        store_longitude: -73.9885,
        total_orders: 8959.56,
        neighborhood: 'Boerum Hill/Brooklyn Heights',
        keywords: 'Beer, Vietnamese, Cozy, Casual, American, Local, Fresh, Great',
        annual_dol: 8959.56,
        description:
            'Old-school watering hole with a 19th-century wood bar, low-key atmosphere, jukebox & pool table.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7426,
        store_longitude: -73.9993,
        total_orders: 1324.34,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vegan, Beer, Wine, Martini, Margarita, Ale, Bourbon, Delicious, Spicy, Friendly',
        annual_dol: 4299.09,
        description:
            'Narrow, easygoing bar for cocktails, beers & happy hours, along with sandwiches, salads & sides.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7367,
        store_longitude: -74.0009,
        total_orders: 1429.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Coffee, Hotel, Modern, Aesthetic, Friendly, Great',
        annual_dol: 2002.71,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7479,
        store_longitude: -74.0043,
        total_orders: 573.81,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Burger, Beer, Scotch, Salad, Tasty, Ale, Robust, Irish, Modern, Fresh',
        annual_dol: 1157.49,
        description:
            'Modern bar food, craft beer, cocktails & a robust Scotch menu in a laid-back space with a terrace.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.678,
        store_longitude: -73.9796,
        total_orders: 947.66,
        neighborhood: 'Park Slope',
        keywords:
            'Burger, Vegan, Vegetarian, Lasagna, Pizza, Salad, Mexican, Burritos, Nachos, Tasty',
        annual_dol: 991.06,
        description: 'Low-key cafe serving vegan, kosher & gluten-free dishes with Latin accents.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7285,
        store_longitude: -73.9789,
        total_orders: 694.86,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Pizza, Salad, Beer, Whiskey, Rustic, Cozy, Friendly, Eclectic, Artisanal, Perfect',
        annual_dol: 751.71,
        description:
            'Foosball, an eclectic jukebox & random movie screenings draw locals to this plain-Jane barroom.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.712,
        store_longitude: -73.9511,
        total_orders: 1777.18,
        neighborhood: 'Williamsburg',
        keywords:
            'Beer, American, Vintage, Classic, Eclectic, Classy, Friendly, Chic, Awesome, Great',
        annual_dol: 1816.14,
        description:
            'Vintage video games (Pac-Man, Donkey Kong, etc.) & beer lure patrons to this Williamsburg bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7272,
        store_longitude: -73.9854,
        total_orders: 2063.4,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Burger, Salad, Hamburger, Beer, Warm, Healthy, Nachos, Irish, Friendly, Clean',
        annual_dol: 2159.18,
        description:
            'This laid-back Irish bar offers pub grub, pints & plenty of TVs for catching the game.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7131,
        store_longitude: -73.9564,
        total_orders: 427.34,
        neighborhood: 'Williamsburg',
        keywords:
            'Steak, Wine, Tasty, Bourbon, Dessert, Trendy, Iranian, Beer, Romantic, French Onion Soup',
        annual_dol: 1309.23,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.715,
        store_longitude: -73.959,
        total_orders: 80.47,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Beer, Rooftop, Hotel, Spicy, French, American, Bright, Delicious, Fun',
        annual_dol: 328.94,
        description:
            '"Located in the heart of Williamsburg (next to the Pod Hotel), Clinton Hall opened its fifth location with the intent to bring some Manhattan flavor to Brooklyn! With an outdoor beer garden for 200+, tons of giant games, amazing food, and our Supercraft beer program, you can\'t go wrong at Clinton Hall Williamsburg!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7615,
        store_longitude: -73.9847,
        total_orders: 602.94,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Sushi, Salad, Italian, Wine, Chilean, Japanese, Spicy, Delicious, Asian, Modern',
        annual_dol: 1565.5,
        description:
            'Sushi, infused sakes & modern Japanese fare are served at this sleek venue with an adjacent lounge.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7454,
        store_longitude: -73.9849,
        total_orders: 10200.86,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Steak, Sushi, Japanese, Tasty, Ramen, Spicy, Trendy, Creamy, Modern, Healthy',
        annual_dol: 11266.04,
        description:
            'This modern restaurant prepares updated yakitori & ramen dishes in a sophisticated space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7494,
        store_longitude: -73.9833,
        total_orders: 181.37,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Shrimp Cocktail, Wine, Spanish, Martini, Upscale, Organic, Spacious, Classy, Classic, Perfect',
        annual_dol: 197.37,
        description:
            'Longtime Spanish eatery with an old-world vibe, dressed-up waiters & a classic menu.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7185,
        store_longitude: -73.9599,
        total_orders: 1542.43,
        neighborhood: 'Williamsburg',
        keywords:
            'Burger, Steak, Salad, Wine, Beer, Delicious, French Onion Soup, Organic, French, Classy',
        annual_dol: 1575.16,
        description:
            'French-American comfort food in a bistro setting with banquette seating & a summertime garden.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7455,
        store_longitude: -73.9828,
        total_orders: 90.77,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords: 'Mexican, Tacos, Burritos, Salad, Hotel, Western, Local, Casual, Great',
        annual_dol: 554.96,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7605,
        store_longitude: -73.9848,
        total_orders: 2399.25,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords: 'Hotel, Coffee, Spacious, Great, Friendly, Contemporary',
        annual_dol: 9135,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7125,
        store_longitude: -74.0079,
        total_orders: 849.48,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Avocado Toast, Tasty, Coffee, Iconic, Rooftop, Irish, Lively, Impressive, Great, American',
        annual_dol: 2982.16,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5936,
        store_longitude: -73.5811,
        total_orders: 979.33,
        neighborhood: '',
        keywords:
            'Quaint, Relaxed, Fun, Ale, Fabulous, Casual, Memorable, Great, Wonderful, American',
        annual_dol: 1160.85,
        description:
            'Relaxed local venue serving seafood & American cooking with frozen cocktails & a waterside deck.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7195,
        store_longitude: -73.9897,
        total_orders: 1865.26,
        neighborhood: 'Lower East Side',
        keywords: 'Tasty, Salad, Delicious, Spicy, Warm, Chinese, Sweet, Asian, Perfect, Authentic',
        annual_dol: 7624.55,
        description:
            '"Right on the border with Chinatown, our speakeasy and tapas bar creates the ultimate intimate mood with classic Asian décor and warm lighting, ideal for whisking your palate into another era, among friends or in the spirit of romance. Serving an array of traditional Szechuan cuisine is crafted into tapas form with a modern twist while cocktails have been given the mixologist’s approach, infused with Chinese herbal botanical elements to create another dimension of flavor. Bite into Canton shumai, Sichuan wontons with house-made chili oil, night market noodles, crispy tofu peppered in our house spice blend, chicken or beef skewers with cumin and peppercorn, yuzu-glazed shrimp, or our absolute signature offering of thirteen-spiced tempura crab"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7682,
        store_longitude: -73.9433,
        total_orders: 36.85,
        neighborhood: 'Roosevelt Island',
        keywords: 'Pizza, Beer, Hotel, Iconic, Spectacular, Contemporary, Fresh, Seasonal, Unique',
        annual_dol: 150.65,
        description:
            '"The Sanctuary is a waterfront events & wedding venue boasting sweeping views of the Manhattan skyline. Restored from a century-old stone church on the historic Roosevelt Island, the Sanctuary has a romantic, easy, and contemporary feel; worlds away from the city bustle yet conveniently in the heart of NYC. We feature both indoor and outdoor spaces to host up to 250 guests. We look forward to hosting your wedding, corporate, and other special event needs! Please also join us on our outdoor patio for our seasonal bar menu, open to the public Thursday-Sunday (subject to inclement weather or private events). No RSVPs. Follow us on Instagram @thesanctuaryri for latest announcements!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6327,
        store_longitude: -73.5816,
        total_orders: 345.42,
        neighborhood: '',
        keywords: 'Lobster, Margarita, Mexican, Clean, American, Great, Classic, Fine, Sangria',
        annual_dol: 445.44,
        description:
            'Long-running, old-school eatery on the canal featuring seafood-focused American fare & patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8691,
        store_longitude: -73.9159,
        total_orders: 254.34,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Cuban, Spicy, Trendy, Delicious, Mimosa, Cozy, Spanish, Classy, Pinacolada, Friendly',
        annual_dol: 402.41,
        description:
            'This hookah lounge with late-night hours offers a Latin menu & frequent live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6367,
        store_longitude: -73.5827,
        total_orders: 86.5,
        neighborhood: '',
        keywords: 'Memorable, Rum, Great, Signature',
        annual_dol: 353.6,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.714,
        store_longitude: -73.9445,
        total_orders: 1199.3,
        neighborhood: 'Williamsburg',
        keywords: 'Hotdog, Salad, Upscale, Spacious, Clean, American, Fun, Fresh, Awesome, Great',
        annual_dol: 1228.5,
        description:
            'Sleek, simple tavern with a garden patio showcasing craft cocktails & upscale bar bites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7111,
        store_longitude: -73.9507,
        total_orders: 506.12,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Whiskey, Vodka, Coffee, Irish, Friendly, Cool, Casual, Fresh, Local, Great',
        annual_dol: 653.79,
        description:
            'Basic neighborhood watering hole with a pool table, video games & skeeball, plus a backyard.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6128,
        store_longitude: -74.1586,
        total_orders: 1529.4,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Burger, Salad, Beer, Wine, New-York-Style, Dessert, Savory, Ale, Bbq, Chicken Wings',
        annual_dol: 1561.85,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5862,
        store_longitude: -73.6935,
        total_orders: 518.07,
        neighborhood: '',
        keywords: 'Hamburger, Tacos, Salad, Beer, Irish, Casual, American, Local, Sweet, Coleslaw',
        annual_dol: 564.62,
        description:
            "Hopping bi-level hangout draws crowds for live bands & DJ'd tunes, plus standard American bar fare.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7254,
        store_longitude: -73.9833,
        total_orders: 89.02,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Pizza, Wine, Beer, Italian, Vietnamese, Chinese, Upscale, Modern, Indian, Perfect',
        annual_dol: 363.87,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6365,
        store_longitude: -74.0775,
        total_orders: 176.57,
        neighborhood: 'Tompkinsville/Mid Island',
        keywords:
            'Burger, Lobster, Salad, Dessert, Beer, Wine, Bbq, French, Caesar Salad, Polished',
        annual_dol: 187.9,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6729,
        store_longitude: -73.957,
        total_orders: 1128.76,
        neighborhood: 'Crown Heights',
        keywords:
            'Pizza, Tasty, Wine, Sustainable, American, Perfect, Amazing, Quirky, Casual, Excellent',
        annual_dol: 1846.62,
        description:
            'A creative menu of American drinks & bites with a sustainable focus inside a funky-hip space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6031,
        store_longitude: -74.1741,
        total_orders: 67.73,
        neighborhood: 'Bloomfield/Mid Island',
        keywords: 'Meatball, Norwegian, Scandinavian, Fun, Amazing, Great, Heritage',
        annual_dol: 276.85,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6815,
        store_longitude: -73.9583,
        total_orders: 52.56,
        neighborhood: 'Clinton Hill',
        keywords:
            'Tasty, Coffee, American, Friendly, Dope, Amazing, Casual, Great, Seasonal, Hummus',
        annual_dol: 316.64,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.751,
        store_longitude: -73.9812,
        total_orders: 342.21,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Hotel, Upscale, Italian, Elegant, Legendary, Excellent, Friendly, Rooftop, Local',
        annual_dol: 1398.83,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7444,
        store_longitude: -73.9759,
        total_orders: 71.02,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords: 'Mexican, Tacos, Salad, Burritos, Local, Casual, Premium, Healthy',
        annual_dol: 351.59,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7585,
        store_longitude: -73.9279,
        total_orders: 17.63,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Burger, Mexican, Pizza, Vegan, Sushi, Colombian, Spanish, American, Rustic, Delicious',
        annual_dol: 72.05,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7442,
        store_longitude: -73.9875,
        total_orders: 16.57,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords: 'Hotel, Fun, Exotic, Cultural, Cool, Modern, Lit, Fine, Unique',
        annual_dol: 67.74,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7457,
        store_longitude: -73.9932,
        total_orders: 706.73,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Vodka, Whiskey, Wine, Beer, Pizza, Martini, Gin, Delicious, Karaoke, Eclectic',
        annual_dol: 837.51,
        description:
            '"As the first distillery in Manhattan since Prohibition, we pride ourselves in making top-shelf vodka the city can be proud of. At our bar in Chelsea, we craft artisanal cocktails that feature the vodka from our on-site distillery. With a laid back vibe and a passion for all things New York, Our/New York Vodka Bar and Distillery is the place to celebrate being in the greatest city in the world."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5942,
        store_longitude: -73.5787,
        total_orders: 148.13,
        neighborhood: '',
        keywords: 'Pizza, Tasty, Beer, Awesome, Fantastic, Great',
        annual_dol: 201.66,
        description: '"Full Bar and Food Truck. Marine Gas and Diesel. Bait Shop."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7642,
        store_longitude: -73.9871,
        total_orders: 352.75,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Lobster, Pizza, Steak, Beer, Vodka, Italian, Spicy, Casual, Fresh, Authentic',
        annual_dol: 1441.93,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6316,
        store_longitude: -73.5814,
        total_orders: 10967.82,
        neighborhood: '',
        keywords: 'Vodka, Beer, Wine, Mexican, Delicious, Perfect, Lively, Fresh, Casual, Great',
        annual_dol: 16344.62,
        description:
            'Breezy, informal waterfront operation serving raw & cooked seafood bites along with tropical drinks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6879,
        store_longitude: -73.9836,
        total_orders: 4023.07,
        neighborhood: 'Boerum Hill/Downtown Brooklyn',
        keywords: 'Hotel, Vintage, Warm, Donut, Serene, Great, Creative, Excellent, Hip, Cool',
        annual_dol: 10933.36,
        description:
            'Featuring vintage furnishings and local artwork, the retro-inspired rooms provide flat-screen TVs, Wi-Fi and minifridges. Some add loaner guitars and vinyl record players. The 1-bedroom suites offer separate living rooms.Amenities include a colorful, hip restaurant and a 24-hour fitness center.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.747,
        store_longitude: -74.0084,
        total_orders: 19794.95,
        neighborhood: '',
        keywords:
            'Lobster, Lobster Roll, Pizza, Salad, Delicious, Luxurious, Tangy, Martini, Elegant, Spectacular',
        annual_dol: 20274.89,
        description:
            '"Pier Sixty at Chelsea Piers is an exclusive waterfront event venue in NYC. Our beautiful venue is known for our exclusive partnership with Manhattan’s market leader in fine food and service, Abigail Kirsch. Now home to some of Manhattan’s most influential and notable events, Pier Sixty offers inspiring Hudson River water views and flexible event spaces that are perfect for gala fundraisers, corporate events, meetings, weddings and bar/bat mitzvahs. Check us out today!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.68,
        store_longitude: -73.9743,
        total_orders: 869.49,
        neighborhood: 'Prospect Heights/Park Slope',
        keywords:
            'Cornbread, Salad, Bbq, Turkey Sandwich, Coleslaw, Stylish, Southern, Friendly, Tradition, Fun',
        annual_dol: 907.19,
        description:
            'Trendy, stylish spot serving Texas-style BBQ by the pound, plus sandwiches & Southern sides.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7886,
        store_longitude: -73.8135,
        total_orders: 104.33,
        neighborhood: 'Whitestone/Flushing',
        keywords: 'Burger, Rich, Beer, Karaoke, Fun, Wine, Amazing, Friendly, Clean, Greek',
        annual_dol: 152.43,
        description:
            '"American Bar & Grill.... located in the heart of Whitestone village. Clean, friendly and fun environment. Open everyday till 4am."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.672,
        store_longitude: -73.9573,
        total_orders: 4852.8,
        neighborhood: 'Crown Heights',
        keywords: 'Pizza, Italian, Tasty, Salad, Meatball, Beer, Delicious, Wine, Warm, Spacious',
        annual_dol: 5120.25,
        description:
            'Lively eatery serving Neapolitan brick-oven pizzas plus salads, creative cocktails & more.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5859,
        store_longitude: -73.6918,
        total_orders: 107.93,
        neighborhood: '',
        keywords: 'Burger, Tacos, Pizza, Salad, Nachos, Beer, Spicy, Irish, American, Casual',
        annual_dol: 135.54,
        description:
            '"The Ugly Duckling Bar & Restaurant is now open in the west end of Long Beach, NY. We are the perfect place to watch all the games, go bottoms up, enjoy a bite to eat or shake your tail feather! Offering Outdoor Dining & Curbside Pick Up!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6432,
        store_longitude: -74.0953,
        total_orders: 2012.77,
        neighborhood: 'Mid Island/New Brighton',
        keywords: 'Tequila, Mexican, Beer, Whiskey, Steak, Wine, Tex-Mex, Spicy, Spanish, American',
        annual_dol: 2935.79,
        description:
            'Popular, lively saloon with a Southwestern menu & a big beer selection, plus live music on weekends.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7136,
        store_longitude: -73.9587,
        total_orders: 812.51,
        neighborhood: 'Williamsburg',
        keywords:
            'Lobster, Karaoke, Vegan, Chinese, American, Cozy, Local, Casual, Dim Sum, Excellent',
        annual_dol: 829.75,
        description: '"a cozy neighborhood restaurant and karaoke saloon"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7643,
        store_longitude: -73.7259,
        total_orders: 39.98,
        neighborhood: 'Flushing/Little Neck',
        keywords: 'Hotdog, Burger, Tacos, Mexican, Salad, Burritos, Warm, Healthy, Casual, Local',
        annual_dol: 163.41,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6838,
        store_longitude: -73.9687,
        total_orders: 0,
        neighborhood: 'Fort Greene/Clinton Hill',
        keywords: 'Coffee, Elegant, American, Contemporary, Ample, Classic, Casual, Fine',
        annual_dol: 0,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7109,
        store_longitude: -73.9679,
        total_orders: 717.79,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Wine, Vodka, Beer, Martini, Canadian, Spicy, Warm, Fun, Classy, Iconic',
        annual_dol: 752.46,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7215,
        store_longitude: -73.9586,
        total_orders: 19434.98,
        neighborhood: 'Williamsburg',
        keywords:
            'Salad, Wine, Pizza, Avocado Toast, Trendy, Hotel, Dessert, South American, Organic, Modern',
        annual_dol: 20581.86,
        description:
            'Empire State Building.Featuring custom furnishings, floor-to-ceiling windows and oak floors, the polished rooms provide free Wi-Fi, flat-screen TVs and designer toiletries; some have balconies with views of Brooklyn and/or Manhattan. Suites add sitting areas.Loaner bikes and an area tuk-tuk shuttle are complimentary. Other amenities include 3 bars, a trendy restaurant and a seasonal rooftop pool, plus a seasonal pop-up restaurant and occasional live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7138,
        store_longitude: -73.966,
        total_orders: 22764.89,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Wine, Coffee, Martini, American, Hotel, Burritos, Retro, Hip, Modern',
        annual_dol: 23287.92,
        description:
            'Coffee, cocktails & American gastropub fare served in a hip cafe & bar with a grassy outdoor space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.741,
        store_longitude: -73.9818,
        total_orders: 10123.94,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords: 'Burger, Vodka, Wine, Beer, Salad, Irish, American, Sangria, Fresh, Fantastic',
        annual_dol: 10238.09,
        description:
            'Bar bites, cocktails, beer & wine are served in a casual setting with late-night hours.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7582,
        store_longitude: -73.9232,
        total_orders: 497.24,
        neighborhood: 'Astoria/LIC',
        keywords: 'Mexican, Breakfast Burrito, Ale, Delicious, Casual, Perfect, Great',
        annual_dol: 541.68,
        description:
            '"Mexican cafe & Agaveria fare offering LA street-style tacos, comfort food, and crafty agave based cocktails & spirits."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7445,
        store_longitude: -73.9856,
        total_orders: 39.38,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Upscale, Hotel, Italian, Wine, Rich, Spacious, Gourmet, Urban, Creamy, Sophisticated',
        annual_dol: 160.98,
        description:
            'The chic rooms and suites come with free Wi-Fi, 42-inch flat-screens and iPod docks, plus minibars. Suites feature separate living areas, and plush penthouse suites add poker tables, pool tables, Jacuzzis or bars.The hotel has a 2-level brasserie with seafood and French fare, a cocktail lounge with live music, a burlesque-inspired bar and a lobby bar. Other amenities include a business center, meeting space and wedding facilities.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7492,
        store_longitude: -73.9938,
        total_orders: 58.27,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Tequila, Mexican, Beer, Wine, American, Perfect, Fantastic, Amazing, Impressive, Casual',
        annual_dol: 238.19,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6982,
        store_longitude: -73.975,
        total_orders: 35.5,
        neighborhood: 'Brooklyn Navy Yard',
        keywords: 'Coffee, Tasty, Global, Modern, Friendly, Local, Unique',
        annual_dol: 108.98,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7638,
        store_longitude: -73.9894,
        total_orders: 2723.32,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Beer, Irish, Tex-Mex, Friendly, Relaxed, Iconic, Clean, Great, Excellent, Local',
        annual_dol: 2806.83,
        description:
            'Draft beers, TVs & dartboards enliven this sports pub, popular with Pittsburgh Steelers fans.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7217,
        store_longitude: -73.9888,
        total_orders: 763.04,
        neighborhood: 'Lower East Side',
        keywords: 'Vodka, Wine, Beer, Gin, Salad, Delicious, Hotel, Fun, Hot Toddy, Perfect',
        annual_dol: 837.35,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7132,
        store_longitude: -73.9847,
        total_orders: 1110.93,
        neighborhood: 'Lower East Side',
        keywords:
            'Vegetarian, Vegan, Steak, Salad, Wine, Tasty, Margarita, Turkish, Mediterranean, Delicious',
        annual_dol: 1156.28,
        description:
            'Turkish & Mediterranean fare, including meze & signature lamb dishes, in a stylish, modern setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7681,
        store_longitude: -73.9858,
        total_orders: 52.07,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Burger, Salad, Coffee, American, Omelette, Casual, Classic, Authentic, Great',
        annual_dol: 212.85,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7635,
        store_longitude: -73.9796,
        total_orders: 139.5,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Hotel, Premium, Spacious, American, Awesome, Great, British, Quaint, Excellent, Traditional',
        annual_dol: 570.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7635,
        store_longitude: -73.9924,
        total_orders: 16.92,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Steak, Vegetarian, Mexican, Wine, Brazilian, Dessert, Delicious, Ale, Australian, Classic',
        annual_dol: 69.15,
        description:
            'Laid-back restaurant preparing classic Brazilian specialties with Minas Gerais flavors.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6794,
        store_longitude: -73.9824,
        total_orders: 3972.66,
        neighborhood: 'Gowanus',
        keywords: 'Karaoke, Korean, Beer, Wine, Tasty, Salad, Bbq, Delicious, Warm, Sweet',
        annual_dol: 4493.64,
        description:
            'Korean BBQ, noodles & other staples served in a casual, warm environment with private karaoke rooms.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7441,
        store_longitude: -73.9961,
        total_orders: 3408.95,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vodka, Margarita, Martini, Upscale, Sweet, Vibrant, Intimate, Amazing, Perfect, Fresh',
        annual_dol: 3408.95,
        description:
            'Gotham Comedy Club is a venue for stand-up comedy in New York City. The comedy club is located on 208 West 23rd Street between Seventh and Eighth avenues in Chelsea. Several movies and television shows have been filmed at the club.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7124,
        store_longitude: -73.9561,
        total_orders: 800.19,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords:
            'Pizza, Burger, Vodka, Italian, Salad, Wine, Spicy, Caesar Salad, Spacious, Great',
        annual_dol: 854.83,
        description:
            'Detroit-style square pizzas, Italian sandwiches & signature burger in a spacious setting with a bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7072,
        store_longitude: -74.0024,
        total_orders: 1039.12,
        neighborhood: 'Financial District/Seaport',
        keywords:
            'Burger, Lobster, Steak, Shrimp Cocktail, Meatball, Beer, Margarita, Wine, Bourbon, Pinacolada',
        annual_dol: 1063.67,
        description:
            'Handsome place for oysters, fried seafood & sandwiches, along with beer & fresh cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7121,
        store_longitude: -73.9438,
        total_orders: 1564.48,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Zombie, Tequila, Beer, Coffee, Ale, Cozy, Romantic, Classy, Friendly',
        annual_dol: 1597.68,
        description:
            'Bar pairing craft drinks with meat & cheese plates, plus a fireplace-equipped patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7272,
        store_longitude: -73.9799,
        total_orders: 1458.45,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Gracious, Intimate, Classy, Friendly, Perfect, Fun, Chic, Great, Excellent, Casual',
        annual_dol: 1554.29,
        description:
            'Relaxed, intimate cocktail lounge with comfy banquettes, creative drinks & retro music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5922,
        store_longitude: -73.5812,
        total_orders: 144.12,
        neighborhood: '',
        keywords:
            'Garlic Bread, Ale, Wine, Salad, Tasty, Bbq, Warm, American, Friendly, Brownie Sundae',
        annual_dol: 150.36,
        description:
            'Cocktails & eclectic American pub fare served in a warm tavern with 18 TVs & a large patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7141,
        store_longitude: -73.9326,
        total_orders: 634.82,
        neighborhood: 'East Williamsburg',
        keywords: 'Exotic, Casual',
        annual_dol: 648.29,
        description: '"serving up booze and boobs for over 20 years"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7616,
        store_longitude: -73.9243,
        total_orders: 1970.51,
        neighborhood: 'Astoria/LIC',
        keywords: 'Sushi, Salad, Beer, Wine, Coffee, Italian, Dominican, Trendy, Latin, Japanese',
        annual_dol: 2357.54,
        description:
            '"We are a modern restaurant and lounge serving Dominican inspired sushi and Latin-Asian fusion dishes. We open Monday to Friday at 4 PM, with an amazing happy hour called the Bad Hours from 4-7. Saturday and Sunday we open for Brunch at 11AM and we close Monday to Thursday at 2 AM and on Friday to Sunday we close at 3 AM."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7636,
        store_longitude: -73.9215,
        total_orders: 494.11,
        neighborhood: 'Astoria/LIC/Long Island City',
        keywords: 'Wine, Beer, Spacious, Stylish, Irish, Great, Friendly, Casual',
        annual_dol: 832.65,
        description:
            '"Boutique bar serving tapas and craft cocktails. Our spacious backyard patio is covered and heated, open year round and available for parties & private events. Sister bar to Mosaic in Astoria!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7171,
        store_longitude: -73.9587,
        total_orders: 111.45,
        neighborhood: 'Williamsburg',
        keywords:
            'Chinese, Crab Rangoon, Bbq, Fried Rice, Spicy, Karaoke, Asian, Casual, Traditional, Dim Sum',
        annual_dol: 455.58,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6572,
        store_longitude: -73.6375,
        total_orders: 714.59,
        neighborhood: '',
        keywords:
            'Sushi, Vegetarian, Pizza, Spicy, Gourmet, Wine, Italian, Delicious, Japanese, Chinese',
        annual_dol: 854.6,
        description:
            'Buzzy, bustling eatery dispensing Pan-Asian plates plus wine & other drinks in a sleek setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7787,
        store_longitude: -73.6498,
        total_orders: 1187.89,
        neighborhood: '',
        keywords:
            'Mexican, Italian, Wine, Pizza, Delicious, American, Swiss, Asian, Lively, Contemporary',
        annual_dol: 1326.72,
        description:
            'Large, lively dining room with a Tuscan feel & a wide-ranging menu of Italian & American dishes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7167,
        store_longitude: -74.0067,
        total_orders: 214.17,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Steak, Salad, Tasty, Wine, Coffee, French Toast, French, Organic, Mediterranean',
        annual_dol: 282.17,
        description:
            'Laid-back diner serving contemporary American comfort fare, with many organic & gluten-free options.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6507,
        store_longitude: -74.0048,
        total_orders: 24.62,
        neighborhood: 'Sunset Park',
        keywords: 'Beer, Wine, Burger, Mexican, Bourbon, Rum, Korean, Bbq, Classy, Relaxed',
        annual_dol: 100.66,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7203,
        store_longitude: -74.0099,
        total_orders: 2679.6,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Steak, Salad, Wine, Dessert, French, Elegant, Cozy, American, Sleek, Modern',
        annual_dol: 2942.59,
        description:
            'Casually hip hangout offers an unpretentious American menu plus craft cocktails in a snug space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7243,
        store_longitude: -74.0104,
        total_orders: 233.94,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegan, Vegetarian, Salad, Gluten Free, Tasty, Organic, Dessert, Greek, Mediterranean, Wholesome',
        annual_dol: 821.27,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6572,
        store_longitude: -73.8397,
        total_orders: 39.98,
        neighborhood: 'Far Rockaway/Howard Beach',
        keywords: 'Mexican, Salad, Organic, Healthy, Casual, Local, Fresh, Recommended',
        annual_dol: 224.72,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7533,
        store_longitude: -73.9768,
        total_orders: 423.52,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Tacos, Burger, Beer, Spanish, Wine, Martini, Hotel, Elegant, American, Polished',
        annual_dol: 1603.77,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7752,
        store_longitude: -73.9504,
        total_orders: 207.66,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Burger, Steak, Pizza, Italian, Meatball, Wine, Vodka, Delicious, Bruschetta, Phenomenal',
        annual_dol: 848.86,
        description:
            '"Welcome to Botte UES, an Italian restaurant specializing in Roman cuisine and traditional Italian cocktails located at 1606 1st Ave, New York, NY 10028. It is our pleasure to serve you delicious, inspired Italian dishes from Rome."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7837,
        store_longitude: -73.6494,
        total_orders: 15.87,
        neighborhood: '',
        keywords: 'Burger, Sushi, Pizza, Japanese, Asian, Modern, Delicious, Casual, Local, Fusion',
        annual_dol: 97.31,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7212,
        store_longitude: -74.0097,
        total_orders: 5919.57,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Burger, Lobster, Salad, Chicken Caesar Salad, Cajun, Chocolate Mousse, Chilean, Canadian, Wine',
        annual_dol: 6045.18,
        description:
            'Upscale chophouse chain serving dry-aged steaks, seafood & wine in an elegant setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7202,
        store_longitude: -73.9967,
        total_orders: 316.09,
        neighborhood: 'Little Italy',
        keywords: 'Pizza, Italian, Salad, Rich, Beer, Ale, Karaoke, Classic, Great, Excellent',
        annual_dol: 351.68,
        description:
            'Old-school venue with dark wood & checkered tablecloths offering pub food & karaoke nights.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6567,
        store_longitude: -74.0065,
        total_orders: 301.83,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Steak, Pizza, Bbq, Italian, Chinese, Tangy, Vietnamese, Korean, Spicy, Jamaican',
        annual_dol: 807.95,
        description:
            '"We specialize in authentic, pit-smoked meats prepared in the classic Southern technique of smoking on oak wood. We also incorporate a variety of flavor profiles that represent our local communities. Hometown also serves up a selection of traditional sides and dessert. In classic barbecue style, we offer walk-up service to our counter on a first-come, first-served basis—until we sell out of that day’s specially cooked offerings."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6644,
        store_longitude: -73.9943,
        total_orders: 48.17,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords: 'Tequila, Mexican, Vodka, Pizza, Bourbon, Rum, Hawaiian, Tasty, American, Latin',
        annual_dol: 196.9,
        description:
            '"Serving authentic cocktails, margaritas , mojitos... We have a large variety of Mezcal & Tequila that you can paired with tasty Mexican Tapas."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7447,
        store_longitude: -73.9035,
        total_orders: 1512.05,
        neighborhood: 'Woodside',
        keywords:
            'Beer, Tasty, Sushi, Martini, Coffee, Avocado Toast, Delicious, Irish, Jamaican, Friendly',
        annual_dol: 1672.35,
        description:
            '"Honest cocktails, Craft beer, friendly service & a thoughtfully prepared kitchen menu."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7562,
        store_longitude: -73.9935,
        total_orders: 307.03,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Lasagna, Italian, Pizza, Wine, Cozy, Classy, Homey, Fabulous, Intimate, Hip',
        annual_dol: 343.82,
        description:
            'Homey Italian venue supplying housemade pastas and other traditional fare in a brick-lined space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7555,
        store_longitude: -73.9912,
        total_orders: 79.95,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Mexican, Tacos, Beer, Salad, Burritos, Wine, Delicious, Keto, Casual, Local',
        annual_dol: 326.82,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7569,
        store_longitude: -73.9935,
        total_orders: 912.66,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Gin, Beer, Russian, Clean, Rum, Cool, Casual, Awesome, Fine, Great',
        annual_dol: 1020.56,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7422,
        store_longitude: -73.5856,
        total_orders: 1517.91,
        neighborhood: '',
        keywords: 'Pizza, Wine, Dessert, Delicious, Beer, Nachos, Tex-Mex, Asian, Bbq, Sweet',
        annual_dol: 1550.11,
        description:
            'Chain dishing up wings, tenders & burgers, plus sauces ranging from sweet to very spicy.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6477,
        store_longitude: -73.9976,
        total_orders: 995.88,
        neighborhood: 'Greenwood Heights/Sunset Park',
        keywords:
            'Karaoke, Pizza, Vegan, Spicy, Wine, Juicy, Caribbean, Memorable, Fusion, Classic',
        annual_dol: 4070.83,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7518,
        store_longitude: -73.5879,
        total_orders: 787.83,
        neighborhood: '',
        keywords:
            'Salad, Pizza, Italian, Tasty, Bbq, Trendy, Yummy, Caesar Salad, Classy, American',
        annual_dol: 824.92,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.758,
        store_longitude: -73.5877,
        total_orders: 397.98,
        neighborhood: '',
        keywords: 'Beer, Warm, Intimate, Cool, Great, Cultural',
        annual_dol: 460.81,
        description:
            'Concert hall and movie theater with live shows and 3D-screenings in warm decor with plush seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7104,
        store_longitude: -73.8494,
        total_orders: 1836.85,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Lobster, Burger, Salad, Japanese, Beer, French, Thai, Bbq, Homemade, Southern',
        annual_dol: 4989.98,
        description:
            "\"Dylan's is a bar and music venue specializing in Southern infused comfort foods. Dylan's includes such delights as buttermilk fried chicken, homemade honey biscuits, shrimp & jalapeño grits, Nashville coleslaw, and smash burgers. Hungry yet?! Our goal is to provide an immersive, warm, convivial family dining experience. An oasis from the day to day grind. Dylan's provides entertainment in a variety of genre’s from Zydeco to Blues, Jazz, Country, and Rock n Roll. We want to put Forest Hills on the map as a destination spot for food, music, and entertainment.\"",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7425,
        store_longitude: -73.9158,
        total_orders: 25.17,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: 'Cool, Casual, Exotic, Fine, Local',
        annual_dol: 41.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7762,
        store_longitude: -73.9532,
        total_orders: 7806.17,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Scotch, Whiskey, Wine, Beer, Scottish, Irish, Cozy, Premium, Warm, Comfortable',
        annual_dol: 8977.77,
        description:
            'Narrow Scottish pub with a warm vibe featuring many whiskies, tap beers & elevated bar bites.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8293,
        store_longitude: -73.9284,
        total_orders: 1990.93,
        neighborhood: 'Concourse/Highbridge/West Bronx',
        keywords:
            'Steak, Salad, Coffee, American, Traditional, Exotic, Casual, Global, Fresh, Rare',
        annual_dol: 2215.12,
        description:
            'Rock ’n’ roll-themed chain with a high-energy vibe serving burgers & American classics.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7147,
        store_longitude: -73.9994,
        total_orders: 2371.58,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Sushi, Wine, Japanese, Beer, Spicy, Upscale, Romantic, Classy, Friendly, Fun',
        annual_dol: 2669.4,
        description:
            'Multilevel spot offering casual Japanese izakaya fare & a reservations-only omakase sushi bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7211,
        store_longitude: -74.0117,
        total_orders: 10497.46,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Lobster, Lobster Roll, Tacos, Beer, Italian, Wine, Margarita, Delicious, Rum',
        annual_dol: 13392.94,
        description:
            'City Winery spin-off with a roof deck for simple American food & wine with river & skyline views.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7353,
        store_longitude: -74.0082,
        total_orders: 427.15,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Austrian, Wine, Romantic, Impeccable, Fabulous, Cozy, Global, American, Great, British',
        annual_dol: 456.55,
        description:
            'Lauded, art-filled Austrian eatery serving dishes like salmon strudel, Bavarian pretzels & desserts.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7538,
        store_longitude: -73.9891,
        total_orders: 105.72,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Salad, Meatball, Beer, Grilled Salmon, Homemade, Stylish, Casual, Local, Coleslaw, Traditional',
        annual_dol: 139.95,
        description:
            'Longtime kosher deli & diner chain serving sandwiches & Eastern European comfort foods like goulash.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8279,
        store_longitude: -73.6652,
        total_orders: 374.83,
        neighborhood: '',
        keywords: 'Coffee, Cultural, Complex',
        annual_dol: 401.37,
        description:
            '"The Amsterdam at Harborside is the first and only true life care retirement community in Nassau County, on Long Island, for active, independent seniors. Whatever ambitions you have for retirement, The Amsterdam at Harborside lets you accomplish them all with the long-term security and peace of mind of life care. Living at The Amsterdam turns ambition into reality."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7255,
        store_longitude: -74.005,
        total_orders: 922.5,
        neighborhood: 'Hudson Square',
        keywords:
            'Burger, Salad, Italian, Tasty, Delicious, Homemade, Yummy, Polished, Sleek, Modern',
        annual_dol: 942.08,
        description:
            'Modern takes on traditional Italian cooking in an airy, wood-accented space with a marble bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7234,
        store_longitude: -74.0047,
        total_orders: 5860.42,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Vegetarian, Salad, Mexican, Coffee, Margarita, Tasty, Burritos, Enchiladas, Healthy, Latin',
        annual_dol: 6386.96,
        description:
            'Traditional Mexican menu of classic dishes, margaritas & coffee in a low-key corner dining room.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7751,
        store_longitude: -73.8743,
        total_orders: 119.26,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Coffee, Wine, Beer, Ale, Pizza, Dessert, Hotel, American, Casual, Local',
        annual_dol: 487.48,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7377,
        store_longitude: -74.0037,
        total_orders: 2170.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Pizza, Fun, Picturesque, Awesome, Friendly, Lively, Colorful, Great, Laid-Back, Local',
        annual_dol: 2259.46,
        description:
            'Longtime lesbian & gay Village hangout with a jukebox, drink specials & colorful decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7378,
        store_longitude: -74.002,
        total_orders: 15898.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Salad, Italian, Wine, Spicy, Fancy, Sleek, American, Modern, Awesome, Terrific',
        annual_dol: 16588.18,
        description:
            'Updated Italian-American classics with wine & cocktails in a marble-accented space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7354,
        store_longitude: -74.0014,
        total_orders: 2336.04,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Italian, Wine, Vodka, Meatball, Margarita, Martini, Bruschetta, Rustic, Triple Sec',
        annual_dol: 2605.35,
        description:
            'Rustic, buzzy trattoria from Keith McNally with Italian fare & sidewalk seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 495.05,
        neighborhood: '',
        keywords:
            'Wine, Beer, Coffee, Comfortable, Healthy, Delicious, Friendly, Casual, Classic, Seasonal',
        annual_dol: 527.96,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7271,
        store_longitude: -74.006,
        total_orders: 518.64,
        neighborhood: 'Hudson Square',
        keywords:
            'Italian, Hotel, Coffee, Trendy, Fabulous, American, Classic, Modern, Perfect, Fantastic',
        annual_dol: 553.12,
        description:
            "Modern rooms come with free Wi-Fi and bottled water, plus flat-screen TVs, iPod docks and coffeemakers. Suites add separate living spaces and city views.Free coffee is available in the lobby. There's an airy restaurant offering classic Italian dishes and open-air dining. Other amenities include a 24-hour exercise room, a business center and an outdoor patio.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7348,
        store_longitude: -74.0023,
        total_orders: 72.37,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Tequila, Mexican, Steak, Margarita, Delicious, American, Latin, Yummy, Fun, Friendly',
        annual_dol: 295.82,
        description:
            'Buzzy Southwestern restaurant known for its margaritas & all-you-can-drink weekend brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7249,
        store_longitude: -73.5506,
        total_orders: 1459.99,
        neighborhood: '',
        keywords:
            'Burger, Hamburger, Beer, Whiskey, Salad, Nachos, French, Irish, American, Delicious',
        annual_dol: 1472.01,
        description:
            'Bustling hangout featuring wings & American pub grub served late, plus sports on TV in casual digs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.708,
        store_longitude: -74.0089,
        total_orders: 1756.36,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Burger, Wine, Gourmet, Bourbon, Italian, Upscale, French, American, Classy, Relaxed',
        annual_dol: 1951.51,
        description:
            'Set on the 60th floor, this ritzy, high-end restaurant features New American cuisine & city views.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8036,
        store_longitude: -73.9201,
        total_orders: 5.36,
        neighborhood: 'Port Morris/Mott Haven',
        keywords:
            'Burger, Hamburger, Salad, Milkshake, Tasty, American, Delicious, Rum, Fresh, Friendly',
        annual_dol: 21.92,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7728,
        store_longitude: -73.9164,
        total_orders: 1747.31,
        neighborhood: 'Astoria/LIC/Ditmars Steinway',
        keywords:
            'Burger, Steak, Vegetarian, Tacos, Gourmet, Wine, Irish, Yummy, American, Awesome',
        annual_dol: 1884.54,
        description:
            'This tavern offers global lunch & dinner menus in a warm, classic environment.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.83,
        store_longitude: -73.6481,
        total_orders: 168.27,
        neighborhood: '',
        keywords: 'Vibrant, Refreshing, Classic',
        annual_dol: 205.96,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7532,
        store_longitude: -73.6991,
        total_orders: 9548.23,
        neighborhood: '',
        keywords:
            'Mexican, Vegan, Salad, Gluten Free, Savory, Spicy, Delicious, Upscale, Fajitas, Latin',
        annual_dol: 9750.83,
        description:
            'Upscale Mexican cantina with a full bar, "Day of the Dead" theme & signature cotton candy.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7511,
        store_longitude: -73.675,
        total_orders: 27.77,
        neighborhood: '',
        keywords:
            'Lobster, Chinese, Thai, Tandoori Chicken, Bbq, Asian, Paneer Tikka, Indian, Fusion, Casual',
        annual_dol: 113.52,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.704,
        store_longitude: -73.8768,
        total_orders: 7.45,
        neighborhood: 'Flushing/Glendale',
        keywords:
            'Steak, Cheesesteak, Gluten Free, Tasty, Beer, Roast Beef, Korean, Bbq, American, Philly Cheesesteak',
        annual_dol: 30.45,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7542,
        store_longitude: -73.9899,
        total_orders: 21.05,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Pizza, Steak, Salad, Hawaiian, Beer, Dessert, Perfect, Casual, Local',
        annual_dol: 86.03,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6606,
        store_longitude: -73.9804,
        total_orders: 3978.54,
        neighborhood: 'South Slope/Windsor Terrace/Park Slope',
        keywords: 'Burger, Hamburger, Beer, Salad, Tasty, Organic, Spicy, Cozy, Relaxed, American',
        annual_dol: 4062.95,
        description:
            'Neighborhood craft beer bar with rotating drafts & hearty fare in a corner, window-wrapped setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5766,
        store_longitude: -73.9824,
        total_orders: 1241.71,
        neighborhood: 'Coney Island',
        keywords:
            'Cheesecake, Salad, Pizza, Italian, Dessert, Shrimp Cocktail, Delicious, Filet Mignon, Casual, Traditional',
        annual_dol: 1273.37,
        description:
            'Tuxedo-clad waiters serve old-school Italian fare at this circa-1907 banquet hall-style eatery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5731,
        store_longitude: -73.9812,
        total_orders: 479.36,
        neighborhood: 'Coney Island',
        keywords:
            'Steak, Lobster, Mexican, Cheesesteak, Beer, Margarita, Tasty, Tex-Mex, Casual, Natural',
        annual_dol: 1380.7,
        description:
            'Open-air eatery on the boardwalk offering casual Mexican grub, signature cocktails & ocean views.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6412,
        store_longitude: -73.9561,
        total_orders: 79.05,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Wine, Caribbean, Beer, Salad, Bbq, Spectacular, Zombie, Fried Chicken, Exceptional, Great',
        annual_dol: 168.48,
        description:
            'Serving Caribbean-inspired small plates, this relaxed wine bar boasts a back patio & hosts events.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7335,
        store_longitude: -73.955,
        total_orders: 5276.07,
        neighborhood: 'Greenpoint',
        keywords: 'Coffee, Wine, Beer, American, Cozy, Polish, Classy, Natural, Chic, Casual',
        annual_dol: 5388.02,
        description:
            'Cozy coffee shop with a rear garden & light bites that turns into a hip, low-key bar at night.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8539,
        store_longitude: -73.8892,
        total_orders: 315.68,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Chicken Parmigiana, Flavorful, Wine, Vodka, Italian, Meatball, Bruschetta, Southern, Fabulous, Casual',
        annual_dol: 345.29,
        description:
            'Neighborhood staple & post-Yankee-game favorite with classic Italian red-sauce dishes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7626,
        store_longitude: -73.966,
        total_orders: 277.85,
        neighborhood: 'Lenox Hill',
        keywords: 'Mexican, Tacos, Beer, Wine, Delicious, Healthy, Local, Casual, Great',
        annual_dol: 321.2,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7507,
        store_longitude: -73.9862,
        total_orders: 6222.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Whiskey, Elegant, Delicious, Cozy, Italian, American, Irish, Awesome, Great, Casual',
        annual_dol: 22637.79,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7524,
        store_longitude: -73.9894,
        total_orders: 5209.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Trendy, Vibrant, Cozy, Urban, Funky, Rooftop, Great, Authentic, Excellent',
        annual_dol: 5319.87,
        description:
            "Compact rooms with contemporary decor and industrial accents offer free Wi-Fi, flat-screen TVs and Bluetooth speakers. Some have subway-tiled walls, in-room steel sinks and bunk beds, while suites add living rooms with sofas.Free fitness classes are offered in a gym. There's a rooftop area with a chic bar/lounge, skyline views, nightly DJs and mini-golf. Other amenities include a seafood brasserie and a vibrant lobby bar, plus a cafe and a sandwich shop.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7562,
        store_longitude: -73.9918,
        total_orders: 2312.43,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Hotel, Wine, Stylish, Elegant, Trendy, Iconic, Sleek, Innovative, Urban, Vintage',
        annual_dol: 2722.06,
        description:
            'The polished rooms feature free Wi-Fi, flat-screen TVs and coffeemakers, in addition to minifridges. Room service is offered.Breakfast and parking are available (fee). Other amenities include a stylish, industrial-chic restaurant, a gym and a business center. A 2-story rooftop bar offers views of the city.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7432,
        store_longitude: -73.9212,
        total_orders: 100.34,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords: 'Korean, Beer, Coffee, Spicy, Bbq, Friendly, Local, Traditional, Casual, Great',
        annual_dol: 410.16,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7354,
        store_longitude: -74.0058,
        total_orders: 3132.91,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Beer, Wine, Seasonal, Casual, Cool, Perfect, Local, Classic, Great, Fantastic',
        annual_dol: 3132.91,
        description:
            "No-frills saloon with an internal ATM, since it's cash only, & a jukebox stocked with classic rock.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7356,
        store_longitude: -74.0062,
        total_orders: 3296.17,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Tequila, Salad, Vodka, Beer, Gin, Avocado Toast, French Onion Soup, French, Danish',
        annual_dol: 3522.38,
        description:
            "Circa-1880 bar with a storied history as a writers' hangout, serving drinks & bar fare.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7398,
        store_longitude: -74.0064,
        total_orders: 2745.31,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Wine, Steak, Italian, Upscale, Delicious, Sophisticated, Gyoza, Artisanal, Great, Signature',
        annual_dol: 2826.52,
        description:
            '"Mollusca NYC is a spirited and sophisticated seafood restaurant with influences from around the world."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6656,
        store_longitude: -73.6656,
        total_orders: 67.84,
        neighborhood: '',
        keywords:
            'Pizza, Italian, Meatball, Wine, Delicious, Local, Casual, Wonderful, Authentic, Classic',
        annual_dol: 81.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7856,
        store_longitude: -73.9767,
        total_orders: 3209.74,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Burger, Steak, Cheesesteak, Salad, Beer, Coffee, Dessert, Wine, Spicy, Ale',
        annual_dol: 3816.03,
        description:
            'Half bar, half casual American burger joint in a below-ground setting with dog photos on the wall.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7832,
        store_longitude: -73.9788,
        total_orders: 122.35,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Mexican, Beer, Wine, Salad, Spicy, Dessert, Delicious, Rum, Mediterranean',
        annual_dol: 460.97,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7854,
        store_longitude: -73.9769,
        total_orders: 202.51,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Ramen, Beer, Japanese, Spicy, Sukiyaki, Classic, Cozy, Local, Casual, Great',
        annual_dol: 827.79,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7041,
        store_longitude: -73.9306,
        total_orders: 80.01,
        neighborhood: 'East Williamsburg',
        keywords: 'Tequila, Vodka, Vegetarian, Beer, Salad, Upscale, Elegant, Rum, Vintage, French',
        annual_dol: 327.04,
        description:
            'Art deco cocktail bar with muted lighting, craft beer & a late-night menu of upscale eats.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6648,
        store_longitude: -73.5524,
        total_orders: 10.76,
        neighborhood: '',
        keywords:
            'Pizza, Salad, Italian, Meatball, Coffee, Dessert, Margarita, Caesar Salad, Homemade, Fresh',
        annual_dol: 14.25,
        description: '"San Marzano Wood Fired Brick Oven Pizza & Hand Made Fresh Pasta"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7325,
        store_longitude: -73.9578,
        total_orders: 2096.38,
        neighborhood: 'Greenpoint',
        keywords: 'Whiskey, Wine, Beer, Bourbon, Ale, Tasty, American, Premium, Eclectic, Cozy',
        annual_dol: 2140.86,
        description:
            'More than 250 American whiskeys line the bar at this corner spot also serving craft beer & wine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7734,
        store_longitude: -73.964,
        total_orders: 424.84,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Sushi, Pizza, Salad, Wine, Savory, Coffee, Delicious, Upscale, Trendy, Japanese',
        annual_dol: 1731.86,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7263,
        store_longitude: -73.952,
        total_orders: 620.14,
        neighborhood: 'Greenpoint',
        keywords:
            'Wine, Beer, Chinese, Martini, Margarita, Spicy, Vintage, Classic, Awesome, Great',
        annual_dol: 633.3,
        description: '"Bar"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7325,
        store_longitude: -74.0021,
        total_orders: 16533.14,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Whiskey, Salad, Avocado Toast, Spicy, Magical, Spacious, Spanish, American',
        annual_dol: 17324.76,
        description:
            'American classics, burgers, whiskey & cocktails in a neighborhood gastropub with a throwback vibe.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6303,
        store_longitude: -74.1087,
        total_orders: 1482.51,
        neighborhood: 'West Brighton',
        keywords:
            'Burger, Steak, Mexican, Pizza, Salad, Nachos, Beer, Margarita, Tex-Mex, Delicious',
        annual_dol: 1531.33,
        description:
            'Located in a converted house, this casual spot offers Mexican fare & drinks amid 1960s decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8692,
        store_longitude: -73.8258,
        total_orders: 79.95,
        neighborhood: 'East Bronx/Baychester',
        keywords: 'Mexican, Vegan, Vegetarian, Salad, Gluten Free, Organic, Casual, Local, Healthy',
        annual_dol: 388.13,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 255.57,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Gluten Free, Italian, Dessert, Meatball, Wine, Delicious, Warm, Polished',
        annual_dol: 658.57,
        description:
            'Emilia-Romagna cuisine in a warm setting, plus cocktails & a robust Italian wine list.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6944,
        store_longitude: -73.9024,
        total_orders: 639.6,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Burger, Tacos, Mexican, Hamburger, Beer, Milkshake, Salad, Spicy, Delicious, Bbq',
        annual_dol: 653.17,
        description:
            'Chill cocktail bar featuring global bites & weekend brunch amid retro-toy decor & colorful murals.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7019,
        store_longitude: -73.9035,
        total_orders: 224.92,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Salad, Wine, Dessert, Ale, Spicy, Cozy, Romantic, Latin, Clean, American',
        annual_dol: 614.6,
        description:
            'Easygoing venue featuring a cocktail bar & a wood-fired grill, plus a daytime cafe with baked goods.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6993,
        store_longitude: -73.9267,
        total_orders: 456.75,
        neighborhood: 'Bushwick',
        keywords:
            'Wine, Gin, French, Vietnamese, Cozy, Delicious, Mediterranean, Seasonal, Classy, American',
        annual_dol: 489.36,
        description:
            'Seasonal French dishes, artisanal cocktails & European wines offered in a modern, casual setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7206,
        store_longitude: -74.005,
        total_orders: 795.15,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Whiskey, Burger, Hamburger, Beer, Salad, Irish, Upscale, French, Comfortable, Classic',
        annual_dol: 827.98,
        description:
            'No-frills tavern providing low-priced drinks along with bar food, jukebox & shuffleboard.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7169,
        store_longitude: -74.0083,
        total_orders: 406.49,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Burger, Steak, Hamburger, Salad, Mexican, Tasty, Wine, Margarita, French Toast, Upscale',
        annual_dol: 424.11,
        description:
            'American bistro fare & Sunday brunch highlight this casual, convivial spot with outdoor seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7172,
        store_longitude: -74.0093,
        total_orders: 516.43,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Champagne, Salad, Vegetarian, Italian, Dessert, Delicious, Ecuadorian, Cozy, Warm',
        annual_dol: 527.39,
        description:
            'French-influenced Italian fixed-price menus in an elegant, vaulted interior with Tuscan touches.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7724,
        store_longitude: -73.9555,
        total_orders: 970.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Pizza, Italian, Wine, South American, Upscale, American, Classy, Authentic, Balanced',
        annual_dol: 1022.21,
        description:
            'Longtime eatery with an old-world vibe featuring pastas, fish & meats, plus a lengthy wine list.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7723,
        store_longitude: -73.9556,
        total_orders: 978.8,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Steak, Hamburger, Salad, Beer, Dessert, Wine, Nachos, Bbq, Irish',
        annual_dol: 999.56,
        description:
            'American bar fare & an Irish pub feel along with live traditional Irish music & sports on the TV.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7213,
        store_longitude: -73.8452,
        total_orders: 694.56,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Steak, Salad, Wine, Upscale, Organic, French, Beer, Bbq, French Onion Soup, Trendy',
        annual_dol: 740.2,
        description:
            'Local steakhouse offering grilled meats plus sides, wines & cocktails in a wood-lined setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8306,
        store_longitude: -73.6898,
        total_orders: 284.96,
        neighborhood: '',
        keywords: 'Beer, Delicious, Healthy, Cozy, Irish, Perfect, Cool, Casual, American, Great',
        annual_dol: 303.91,
        description: '"Cocktail Bar"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7585,
        store_longitude: -73.855,
        total_orders: 287.57,
        neighborhood: 'Flushing/Corona',
        keywords: 'Hotel, Modern, Clean, Great',
        annual_dol: 1088.98,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7191,
        store_longitude: -74.005,
        total_orders: 10033.11,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Lobster, Champagne, Coffee, Shrimp Cocktail, Hotel, Gourmet, Trendy, Stylish, American, Retro',
        annual_dol: 10129.66,
        description:
            "Featuring chic mid-century modern decor, the hip rooms have minibars and Wi-Fi, plus smart TVs, DVD players and iPods; suites add iMacs. Guests can request an in-room goldfish.There's a cafe, a trendy lounge and an oyster bar, plus a basement jazz club serving cocktails and small plates. Other amenities include a private movie theater, a fitness room and 3 meeting rooms. Breakfast and valet parking are available.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7472,
        store_longitude: -73.5886,
        total_orders: 1896.78,
        neighborhood: '',
        keywords: 'Salad, Italian, Pizza, Dessert, Wine, Organic, Quaint, Warm, Modern, Friendly',
        annual_dol: 1949.67,
        description:
            'Italian fare served in large portions for over 25 years at this lively trattoria.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7236,
        store_longitude: -74.0097,
        total_orders: 689.98,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Hotel, Warm, Sophisticated, Classic, Great, Chic, Excellent, Cool, French, Signature',
        annual_dol: 2820.41,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7724,
        store_longitude: -73.9559,
        total_orders: 208.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Mexican, Beer, Meatball, Nachos, Burritos, Salad, Quesadillas, Spicy, Trendy, Yummy',
        annual_dol: 254.62,
        description:
            'Simple Mexican tacos, burritos & quesadillas plus booze served amid contemporary decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7753,
        store_longitude: -73.9567,
        total_orders: 172.03,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Salad, Chinese, Spicy, Martini, Delicious, Upscale, Beer, Juicy, Bbq, Peking Duck',
        annual_dol: 175.68,
        description:
            'Taiwanese-style cooking with a pig-centric menu, plus kitschy pink decor to match the theme.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7461,
        store_longitude: -73.915,
        total_orders: 257,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords: 'Coffee, Beer, Wine, Shrimp Cocktail, Quaint, Cool, Fresh, Great, Casual, Unique',
        annual_dol: 274.09,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8089,
        store_longitude: -73.9306,
        total_orders: 1244.27,
        neighborhood: 'Port Morris/Mott Haven',
        keywords: 'Burger, Pizza, Beer, Coffee, Wine, Healthy, Great, Casual, Creative, Signature',
        annual_dol: 5086.17,
        description:
            '"The Bruckner Building provides Office Space in the heart of Mott Haven. The Bruckner building is the new standard of excellence in the South Bronx. We have units sized for any type of business ranging from 1,000-20,000 SF. Feel free to contact us for office space."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7743,
        store_longitude: -73.8746,
        total_orders: 219.38,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Burger, Lobster, Lobster Roll, Pizza, Shrimp Cocktail, Salad, Beer, Coffee, Wine, Spanish',
        annual_dol: 896.75,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.705,
        store_longitude: -74.0088,
        total_orders: 127.24,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Lobster, Lobster Tail, Burger, Shrimp Cocktail, Salad, Meatball, Greek, Mediterranean, Wine, Organic',
        annual_dol: 787.18,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7082,
        store_longitude: -73.6252,
        total_orders: 104.65,
        neighborhood: '',
        keywords: 'Pizza, Italian, Casual, Sweet',
        annual_dol: 394.27,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8267,
        store_longitude: -73.9391,
        total_orders: 81.13,
        neighborhood: 'Central Harlem/Harlem',
        keywords:
            'Beer, Wine, Caribbean, Delicious, Jamaican, Cozy, Friendly, Great, Traditional, Excellent',
        annual_dol: 306.45,
        description:
            '"Keeping live jazz in Harlem. Cozy atmosphere for food drink and jazz. Join us for Thursday Happy Hour, Friday Night for our Jazz Jam and again on Saturdays for our live events. Relax n Sundays with our Jazz Brunch."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6756,
        store_longitude: -73.9812,
        total_orders: 657.95,
        neighborhood: 'Park Slope',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Cajun, Spicy, Juicy, Rich, Homey, Modern, Southern',
        annual_dol: 769.41,
        description:
            'Homey spot serving bags of crab, lobster & more shaken with spices & paired with Southern sweet tea.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6659,
        store_longitude: -73.989,
        total_orders: 75.02,
        neighborhood: 'Park Slope',
        keywords: 'Burger, Steak, Tacos, Hamburger, Mexican, Cajun, Salad, Beer, Wine, Ale',
        annual_dol: 306.65,
        description:
            'Warm hangout with a patio dishing up casual global fare from tacos to pasta, plus weekend brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7235,
        store_longitude: -73.9508,
        total_orders: 9081.63,
        neighborhood: 'Greenpoint',
        keywords:
            'Japanese, Dessert, Delicious, Stylish, Mediterranean, Ramen, Exotic, Classic, Global, Great',
        annual_dol: 10328.87,
        description:
            'Tucked behind a ramen restaurant, a hip spot with Japanese small plates & exotic craft cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7239,
        store_longitude: -73.951,
        total_orders: 1833.89,
        neighborhood: 'Greenpoint',
        keywords: 'Steak, Salad, Milkshake, Coffee, Beer, Upscale, Bbq, Friendly, Fun, Vintage',
        annual_dol: 2660.62,
        description:
            'Listening bar serving up vinyl tracks on a vintage turntable plus burgers, beer & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7224,
        store_longitude: -73.9498,
        total_orders: 95.91,
        neighborhood: 'Greenpoint/Williamsburg',
        keywords:
            'Burger, Tacos, Mexican, Margarita, Delicious, Wine, Trendy, Phenomenal, Ale, Organic',
        annual_dol: 392.05,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6147,
        store_longitude: -73.9364,
        total_orders: 99.68,
        neighborhood: 'Marine Park',
        keywords: 'Burger, Pizza, Steak, Italian, Wine, Fun, American, Great, Wonderful, Excellent',
        annual_dol: 122.57,
        description:
            '"Established in 2009 & located in the heart of Marine Park, Brooklyn NY, 3rd&7 has become the premier venue for sports, events, fun and all around happiness. We\'ve taken pride in our excellent service and wonderful patrons who visit us everyday."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5916,
        store_longitude: -74.1011,
        total_orders: 1900.67,
        neighborhood: 'Todt Hill',
        keywords: 'Wine, Dessert, Italian, Mexican, Beer, Trendy, Yummy, Classy, Comfortable, Fun',
        annual_dol: 2004.69,
        description:
            'Energetic restaurant offering Italian specialties, wines & live music in comfortable surroundings.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6559,
        store_longitude: -73.6453,
        total_orders: 1188.24,
        neighborhood: '',
        keywords: 'Burger, Beer, Cajun, Whiskey, Ale, Nachos, Bbq, American, Spicy, Delicious',
        annual_dol: 1260.47,
        description:
            'Outpost of a local alehouse chain featuring a selection of craft beers, pub grub & wing specials.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8227,
        store_longitude: -73.7033,
        total_orders: 989,
        neighborhood: '',
        keywords: 'Upscale, Elegant, Intimate, Spectacular, Great, Fantastic, Exceptional, Casual',
        annual_dol: 1053.99,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7556,
        store_longitude: -73.9945,
        total_orders: 419.54,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Steak, Lasagna, Pizza, Italian, Chicken Parmigiana, Wine, Beer, Delicious, Margarita, Rustic',
        annual_dol: 479.25,
        description:
            'Casual choice for rustic Sicilian cuisine paired with cocktails featuring Italian ice.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.75,
        store_longitude: -73.9836,
        total_orders: 127.09,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Hotel, Italian, French, Mediterranean, Elegant, American, Contemporary, Premium, Modern, High-End',
        annual_dol: 144.74,
        description:
            'Theater District.The understated rooms have wood accents and marble bathrooms, as well as free Wi-Fi, flat-screen TVs and coffeemakers. Suites, all on upper floors, have kitchens and city views.A contemporary bar serves casual food and drink, while a chic restaurant offers Mediterranean fine dining. Other amenities include a luxury spa, a 24-hour fitness center and a hair salon, plus 7,500 sq ft of event space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8445,
        store_longitude: -73.6956,
        total_orders: 160.83,
        neighborhood: '',
        keywords: 'Polish, American, Wine, Cultural, Classic, Great',
        annual_dol: 197.34,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8288,
        store_longitude: -73.7018,
        total_orders: 40.87,
        neighborhood: '',
        keywords: 'Hotel, Intimate, Excellent',
        annual_dol: 167.07,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7545,
        store_longitude: -73.9761,
        total_orders: 13.45,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lobster, Tacos, Salad, Wine, Coffee, Beer, Gin, Delicious, Sustainable, Eclectic',
        annual_dol: 54.98,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7811,
        store_longitude: -73.8412,
        total_orders: 10.12,
        neighborhood: 'College Point/Flushing',
        keywords: 'Karaoke, Beer, Chinese, Casual',
        annual_dol: 41.36,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6351,
        store_longitude: -74.0202,
        total_orders: 47,
        neighborhood: 'Bay Ridge',
        keywords: 'Delicious, Fresh, Great, Casual',
        annual_dol: 62.22,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7902,
        store_longitude: -73.9727,
        total_orders: 234.37,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Steak, Salad, Vegan, Mexican, Nachos, Burritos, Margarita, Bbq, Spanish, Casual',
        annual_dol: 574.18,
        description:
            'Bustling Mexican option serving up a menu of margaritas, tacos, nachos & giant burritos.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7431,
        store_longitude: -73.921,
        total_orders: 32.45,
        neighborhood: 'Astoria/LIC/Sunnyside',
        keywords:
            'Salad, Spicy, Korean, Coffee, Delicious, Bbq, Yummy, Hotel, Edgy, Mac And Cheese',
        annual_dol: 132.64,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7092,
        store_longitude: -73.8699,
        total_orders: 256.48,
        neighborhood: 'Flushing/Ridgewood/Glendale',
        keywords:
            'Sushi, Salad, Steak, Japanese, Spicy, Chinese, Casual, Traditional, Authentic, Local',
        annual_dol: 900.38,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7067,
        store_longitude: -73.9056,
        total_orders: 201.81,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Steak, German, Beer, Wine, Roast Beef, Fun, American, Awesome, Amazing, Wonderful',
        annual_dol: 210.56,
        description:
            'Long-standing tavern serving domestic & imported beers alongside a small menu of German pub eats.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7507,
        store_longitude: -73.6734,
        total_orders: 41.9,
        neighborhood: '',
        keywords:
            'Burger, Sushi, Hamburger, Salad, Gourmet, Beer, Delicious, Trendy, American, Healthy',
        annual_dol: 171.26,
        description:
            'Laid-back outpost offering typical American bar cuisine alongside craft beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.699,
        store_longitude: -73.909,
        total_orders: 102.28,
        neighborhood: 'Flushing/Ridgewood',
        keywords:
            'Burger, Tacos, Steak, Salad, Vegan, Mexican, Dessert, Margarita, Delicious, Fajitas',
        annual_dol: 418.1,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6753,
        store_longitude: -73.9814,
        total_orders: 539.06,
        neighborhood: 'Park Slope',
        keywords:
            'Salad, Wine, Italian, Cozy, Delicious, Quaint, Comfortable, Old Fashioned, Classy, Vintage',
        annual_dol: 613.95,
        description:
            'Northern Italian mainstay with an intimate, old-world-inspired dining room & an attached wine bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6774,
        store_longitude: -73.9863,
        total_orders: 662.64,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Wine, Cozy, Friendly, Perfect, Ample, Classic, Great, Fantastic, Casual',
        annual_dol: 691.37,
        description:
            'This mellow haunt serves cocktails, brews & wines amid exposed brick walls & an old-school feel.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6779,
        store_longitude: -73.9859,
        total_orders: 976.18,
        neighborhood: 'Gowanus',
        keywords: 'Beer, Ale, Hip, Friendly, Classic, Casual, Local, Great, Chill',
        annual_dol: 1018.51,
        description:
            'Wood-paneled neighborhood tavern with a chill vibe, handful of draft beers & pet-friendly policy.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7254,
        store_longitude: -73.9462,
        total_orders: 1780.83,
        neighborhood: 'Greenpoint',
        keywords: 'Sushi, Beer, Cozy, Charming, Bourbon, Fun, Clean, Perfect, Colorful, Casual',
        annual_dol: 1856.35,
        description:
            'Green velvet booths & stained glass add to the charming art nouveau vibe at this chic cocktail bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7076,
        store_longitude: -73.9434,
        total_orders: 894.6,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Salad, Wine, Beer, Fun, Memorable, Great, Fine, Casual, Local',
        annual_dol: 1217.83,
        description:
            '"Eris is a restaurant, art, performance, and music event space with full bar, Funktion One sound, and an immersive, genuine experience of local artists and community."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.695,
        store_longitude: -73.9314,
        total_orders: 1274.42,
        neighborhood: 'Bushwick',
        keywords: 'Wine, Beer, Margarita, Hotel, Ale, Fun, Vintage, Classy, Friendly, Classic',
        annual_dol: 1301.47,
        description:
            '"open 7 days - cold beer - frozen cocktails - natural wine - cheap happy hour - private patio open late"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6248,
        store_longitude: -74.132,
        total_orders: 98.46,
        neighborhood: 'Westerleigh/Mid Island',
        keywords:
            'Italian, Pizza, Wine, Beer, Delicious, Spanish, Classy, Fantastic, Great, Casual',
        annual_dol: 325.52,
        description: '"Italian Restaurant and Catering Hall"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7255,
        store_longitude: -73.9447,
        total_orders: 2328.79,
        neighborhood: 'Greenpoint',
        keywords: 'Burger, Beer, Cozy, Local, Casual, Great',
        annual_dol: 2378.2,
        description:
            'Simple pub grub & cocktails served in an old-timey setting with wood beams & stained-glass windows.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7079,
        store_longitude: -73.9437,
        total_orders: 99.28,
        neighborhood: 'Southside/Williamsburg',
        keywords:
            'Salad, Venezuelan, Gin, Spanish, Uruguayan, American, Farm-To-Table, Latin, Stylish, Delicious',
        annual_dol: 173.65,
        description:
            'Elevated takes on classic Venezuelan plates are served in a stylish space with an intimate vibe.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7661,
        store_longitude: -73.957,
        total_orders: 99.73,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords: 'Mexican, Tacos, Beer, Salad, Burritos, Wine, Hotel, Western, Local, Casual',
        annual_dol: 407.65,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7035,
        store_longitude: -74.0118,
        total_orders: 2416.92,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Rooftop, Italian, Trendy, Coffee, Hotel, Mediterranean, Delicious, Warm, Beer',
        annual_dol: 4939.78,
        description:
            'Warm Italian restaurant with a homespun rooftop bar & city views, plus private booths & pods.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7189,
        store_longitude: -74.0064,
        total_orders: 585.91,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Salad, Coffee, Avocado Toast, Savory, French, Australian, Delicious, American, Quaint, Hotel',
        annual_dol: 797.61,
        description:
            'Bakery/cafe branch providing sweets, French & American dishes, wines & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7192,
        store_longitude: -74.0061,
        total_orders: 188.49,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Wine, Mexican, Rich, Italian, Seasonal, Comfortable, Fun, Casual, Greek, American',
        annual_dol: 797.7,
        description:
            '"One White Street is a neighborhood restaurant in downtown New York City from Chef Austin Johnson and Master Sommelier Dustin Wilson. Located in a historic Tribeca townhouse, One White Street is a place to feel at home. One White Street’s menu evolves with the seasons and is informed by exceptional ingredients organically grown and sourced year-round from Rigor Hill Farm in Columbia County, New York."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7557,
        store_longitude: -73.5723,
        total_orders: 0,
        neighborhood: '',
        keywords: 'Korean, Bbq, Kbbq, Asian, Wine, Fusion, Classy, Great',
        annual_dol: 0,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6874,
        store_longitude: -73.8205,
        total_orders: 48.26,
        neighborhood: 'South Ozone Park/Jamaica/South Richmond Hill',
        keywords:
            'Delicious, Fun, Polish, Polished, Gourmet, Premium, Friendly, Caribbean, Intimate, Zen',
        annual_dol: 84.94,
        description:
            'Polished Caribbean lounge offering cocktails, light bites & hookah till the wee hours.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7074,
        store_longitude: -73.9223,
        total_orders: 1035.28,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Burger, Vodka, Wine, Salad, Gin, Contemporary, American, Modern, Urban',
        annual_dol: 1095.57,
        description:
            'This modern gastropub with garden seating offers creative drinks & New American grub, plus brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7717,
        store_longitude: -73.8666,
        total_orders: 1054,
        neighborhood: 'East Elmhurst/Flushing',
        keywords:
            'Hotdog, Burger, Pizza, Coffee, Spanish, American, Dominican, Hotel, Western, Casual',
        annual_dol: 4308.4,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7076,
        store_longitude: -73.9209,
        total_orders: 913.46,
        neighborhood: 'Bushwick',
        keywords: 'Hamburger, Rooftop, American, Beer, Wine, Hotel, Asian, Fusion, Awesome, Great',
        annual_dol: 1023.63,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7058,
        store_longitude: -73.9239,
        total_orders: 6375.5,
        neighborhood: 'Bushwick',
        keywords:
            'Burger, Tequila, Vegan, Beer, Vegetarian, Whiskey, Painkiller, Delicious, Warm, Cozy',
        annual_dol: 6510.78,
        description:
            'Cozy watering hole boasting a fireplace patio, plus cocktails, beer in cans & bottles & munchies.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7405,
        store_longitude: -74.0069,
        total_orders: 938.17,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Burger, Salad, Tasty, Gluten Free, Wine, Delicious, Trendy, Upscale, Spanish, Elegant',
        annual_dol: 992.29,
        description:
            'Upscale restaurant & bar serving seasonal Mediterranean meals prepared with flavored olive oils.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7327,
        store_longitude: -74.0036,
        total_orders: 984.55,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Italian, Gourmet, Beer, Wine, Savory, Delicious, Bourbon, Premium, American',
        annual_dol: 1046.2,
        description:
            'Italian eatery & wine bar for salads, meats, cheeses & larger plates, with a grocery section too.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6494,
        store_longitude: -73.6762,
        total_orders: 919.58,
        neighborhood: '',
        keywords: 'Sushi, Salad, Chilean, Wine, Japanese, Beer, Chinese, Sashimi, Ramen, Organic',
        annual_dol: 1134.39,
        description:
            'Easygoing local Japanese restaurant featuring sushi menus & hibachi dining with tableside chefs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8488,
        store_longitude: -73.8543,
        total_orders: 34.47,
        neighborhood: 'Morris Park',
        keywords:
            'Pizza, Italian, Chicken Parmigiana, Meatball, French, Delicious, Bruschetta, Great, Casual',
        annual_dol: 40.17,
        description:
            'Longtime family-owned bar & eatery offering pizza & other Italian dishes in a casual dining room.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7329,
        store_longitude: -74.003,
        total_orders: 8.23,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Vegetarian, Mexican, Salad, Chinese, Casual, Local, Eclectic, Superb, Excellent',
        annual_dol: 33.63,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7838,
        store_longitude: -73.9776,
        total_orders: 609.6,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Tomato Soup, Dessert, Fruity, Coffee, Velvety, Spicy, Warm, Hotel, Mediterranean',
        annual_dol: 637.94,
        description:
            'Posh chain restaurant serving American fare, including brunch, dinner & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.786,
        store_longitude: -73.9724,
        total_orders: 187.89,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Hotdog, Salad, Beer, Dessert, Mediterranean, American, French, Homemade, Swiss, Retro',
        annual_dol: 247.5,
        description:
            'Understated diner serving breakfast, meat dishes, pasta & burgers, plus beer & creative cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7443,
        store_longitude: -73.6565,
        total_orders: 29.63,
        neighborhood: '',
        keywords: 'Mexican, Tacos, Burritos, Salad, Local, Indian, Casual, High-Protein, Authentic',
        annual_dol: 151.77,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7204,
        store_longitude: -73.8458,
        total_orders: 39.98,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Mexican, Tacos, Beer, Burritos, Wine, Organic, Warm, Local, Casual, Healthy',
        annual_dol: 194.06,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6999,
        store_longitude: -73.9067,
        total_orders: 174.14,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Salad, Mexican, Dessert, Beer, Upscale, Ale, Spicy, French, Classy, American',
        annual_dol: 189.51,
        description:
            'Upscale restaurant featuring classic American meals, cocktails & brunch in calm, understated digs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6713,
        store_longitude: -73.9777,
        total_orders: 39.98,
        neighborhood: 'Park Slope',
        keywords: 'Mexican, Tacos, Burritos, Local, Casual',
        annual_dol: 224.72,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.699,
        store_longitude: -73.9265,
        total_orders: 391.51,
        neighborhood: 'Bushwick',
        keywords:
            'Beer, Burger, Mexican, Wine, Tasty, Colombian, Uruguayan, Warm, Cozy, Mediterranean',
        annual_dol: 747.43,
        description:
            'Warm setting for Colombian-European dinners with Basque accents, plus beer, wine & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6587,
        store_longitude: -73.6448,
        total_orders: 1909.07,
        neighborhood: '',
        keywords:
            'Steak, Burger, Hamburger, Salad, Mexican, Vodka, Dessert, Nutritious, Italian, Delicious',
        annual_dol: 1949.57,
        description:
            'Modern New American spot serving hearty fare & often drawing lively crowds on weekends.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6781,
        store_longitude: -73.7469,
        total_orders: 737.83,
        neighborhood: 'Laurelton/Jamaica/Springfield Gardens',
        keywords:
            'Lobster Roll, Lobster, Burger, Salad, Red Velvet Cake, Dessert, Wine, Bbq, Juicy, Caribbean',
        annual_dol: 2593.99,
        description: '"Casual dining seafood restaurant with a Caribbean flair"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6175,
        store_longitude: -73.9321,
        total_orders: 400.66,
        neighborhood: 'Marine Park',
        keywords:
            'Italian, Shrimp Cocktail, Pizza, Wine, Homemade, Rack Of Lamb, Casual, Fun, Perfect, Exceptional',
        annual_dol: 414.36,
        description:
            'White-tablecloth Italian institution serving veal, rack of lamb & seafood, plus pasta dishes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7536,
        store_longitude: -73.9912,
        total_orders: 460.89,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Champagne, Sustainable, Sweet, Perfect, Premium, Incredible, Modern',
        annual_dol: 513.94,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7458,
        store_longitude: -73.9151,
        total_orders: 335.76,
        neighborhood: 'LIC/Sunnyside/Woodside',
        keywords:
            'Hotdog, Tacos, Steak, Mexican, Tasty, Margarita, Spicy, Wine, Delicious, Colombian',
        annual_dol: 342.89,
        description:
            'Cozy eatery offering creative small plates, tacos & burgers, plus a bar & open-air dining.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6222,
        store_longitude: -73.9036,
        total_orders: 90.61,
        neighborhood: 'Bergen Beach',
        keywords: 'Cozy, Fun, Great',
        annual_dol: 94.54,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6739,
        store_longitude: -73.6263,
        total_orders: 1272.83,
        neighborhood: '',
        keywords: 'Beer, Golfclub, Comfortable, Ample, Great, Amazing, Seasonal, Recommended',
        annual_dol: 2155.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8189,
        store_longitude: -73.8173,
        total_orders: 580.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Perfect, Cool, Casual, Sweet, Legendary, Great, Local',
        annual_dol: 604.33,
        description: '"Bar & Lounge for Neighbors, Friends and Sports Enthusiasts"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6407,
        store_longitude: -73.6599,
        total_orders: 81.89,
        neighborhood: '',
        keywords: 'Irish, Bold, Lobster, Tasty, Fantastic, American, Mead, Great, Reuben, Friendly',
        annual_dol: 97.82,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7524,
        store_longitude: -73.9903,
        total_orders: 1665.83,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Steak, Salad, Tasty, Beer, Margarita, Bbq, Spicy, French Onion Soup, Mediterranean',
        annual_dol: 1701.17,
        description:
            'Vibrant pub with a large oak bar, TVs & occasional live Celtic music, serving Irish & American fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6586,
        store_longitude: -73.6413,
        total_orders: 288.98,
        neighborhood: '',
        keywords:
            'Burger, Pizza, Turkey Sandwich, Coffee, Warm, Comfortable, Clean, Fun, Welcoming, Modern',
        annual_dol: 288.98,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8258,
        store_longitude: -73.8215,
        total_orders: 150.44,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords: 'Burger, Beer, Fun, Cozy, Legendary, Awesome, Friendly, Perfect, American, Great',
        annual_dol: 719.21,
        description:
            '"We provide a cozy indoor space with an ambiance that allows friends, wanderers, and explorers to share a drink as equals. You\'ll be charmed with every sip of our professionally crafted drinks menu."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7758,
        store_longitude: -73.6334,
        total_orders: 1745.87,
        neighborhood: '',
        keywords: 'Luxurious, Opulent, Distinct',
        annual_dol: 1891.3,
        description:
            '"Unveil the curtain to Long Island’s opulent past, and discover, at the heart of exclusive Roslyn Heights, The Royalton. This colonial mansion of the early 20th century stands on 10 acres of wooded arbor and English gardens restored to a magnificence matched only by the level of grandeur of each catered affair. The Royalton is the Long Island Wedding Mansion of choice."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7556,
        store_longitude: -73.9982,
        total_orders: 4445.53,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Vegetarian, Vegan, Salad, Gluten Free, Meatball, Italian, French Toast, French, American',
        annual_dol: 4445.53,
        description:
            'Contemporary spot specializing in seasonal, locally sourced & mostly gluten-free comfort classics.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7516,
        store_longitude: -73.9874,
        total_orders: 1945.3,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Burger, Hotel, Elegant, Iconic, Fabulous, Classic, Memorable, Comfortable, Exquisite, Classy',
        annual_dol: 2070.18,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7256,
        store_longitude: -74.0074,
        total_orders: 133.97,
        neighborhood: 'Hudson Square',
        keywords: 'Pizza, Wine, Italian, Beer, Tasty, Homemade, Classy, Intimate, Fusion, Tiramisu',
        annual_dol: 168.25,
        description:
            'Intimate eatery offering Emilia-Romagna dishes, outdoor seating & private dining in a wine cellar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7194,
        store_longitude: -73.8423,
        total_orders: 6826.69,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Steak, Lobster, Mexican, Beer, Chili Con Carne, Tasty, Margarita, Coffee, Vodka',
        annual_dol: 13022.76,
        description:
            'Lively local restaurant serving strong margaritas & Mexican fare in a small, funky setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.776,
        store_longitude: -73.9562,
        total_orders: 753.42,
        neighborhood: 'UES/Central Park West Historic District',
        keywords: 'Burger, Steak, Pizza, Sushi, Salad, Meatball, Italian, Canadian, Thai, American',
        annual_dol: 1755.95,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5722,
        store_longitude: -74.1127,
        total_orders: 64.47,
        neighborhood: 'New Dorp',
        keywords:
            'Vegetarian, Butter Chicken, Dessert, Coffee, Delicious, Chicken Tikka Masala, Tangy, Italian, Charming, Romantic',
        annual_dol: 77.14,
        description:
            'Long-standing vibrant outpost offers familiar Indian dishes & a weekend buffet in a snazzy interior.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7199,
        store_longitude: -73.7331,
        total_orders: 120.31,
        neighborhood: 'Queens Village',
        keywords: 'Steak, Chinese, Spicy, French, Fried Rice, Indian, Casual, Great',
        annual_dol: 491.78,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7202,
        store_longitude: -73.9968,
        total_orders: 226.29,
        neighborhood: 'Little Italy',
        keywords:
            'Garlic Bread, Cheesecake, Chicken Parmigiana, Italian, Tasty, Hotel, Friendly, Authentic, Intimate, Amazing',
        annual_dol: 246.26,
        description:
            'Hearty helpings of classic Italian fare dished up in a simple, long-running (since 1968) trattoria.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7761,
        store_longitude: -73.9625,
        total_orders: 69.97,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Pizza, Vegetarian, Italian, Salad, Meatball, Tasty, Dessert, Spicy, Vodka, Fabulous',
        annual_dol: 245.08,
        description:
            'Bi-level Italian restaurant with thin-crust pizzas & homemade pastas, plus a rooftop terrace.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7547,
        store_longitude: -73.8539,
        total_orders: 437.44,
        neighborhood: 'North Corona/Flushing/Corona',
        keywords:
            'Shrimp Cocktail, Vodka, Salad, Beer, Gluten Free, Ale, Italian, Hotel, Bbq, Homemade',
        annual_dol: 1788.12,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7548,
        store_longitude: -73.9951,
        total_orders: 1200.46,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Pizza, Vegetarian, Italian, Salad, Meatball, Dessert, Wine, Classy, Vibrant, Casual',
        annual_dol: 1285.26,
        description:
            'Traditional Italian dishes, pizzas & cocktails served in a relaxed, industrial-chic restaurant.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7189,
        store_longitude: -73.9995,
        total_orders: 22904.69,
        neighborhood: 'Little Italy',
        keywords:
            'Flavorful, Vegan, Upscale, Trendy, Wine, Delicious, Salad, Impeccable, Chinese, Asian',
        annual_dol: 26361.88,
        description:
            'Cocktails & Asian fusion eats are offered in this industrial-chic eatery with an atrium & a patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7724,
        store_longitude: -73.9555,
        total_orders: 6103.49,
        neighborhood: 'UES/Central Park West Historic District',
        keywords:
            'Steak, Burger, Tasty, Salad, Meatball, Delicious, Italian, Spicy, Sustainable, Funky',
        annual_dol: 6312,
        description:
            'Cozy chophouse offering updated dishes & sustainable cuts, plus playful artwork & drinks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7338,
        store_longitude: -74.0026,
        total_orders: 11716.86,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Colorful, Complex, Great, Casual',
        annual_dol: 11737.74,
        description:
            'A gay & straight crowd camps out at this bi-level bar for piano sing-alongs, drag revues & comedy.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7312,
        store_longitude: -74.004,
        total_orders: 147.31,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Steak, Delicious, Coffee, Italian, Bright, Vibrant, American, French, Friendly',
        annual_dol: 206.37,
        description:
            'Craft cocktails are served in a swanky space with a neon sign casting a purplish glow.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7741,
        store_longitude: -73.8709,
        total_orders: 59.95,
        neighborhood: 'East Elmhurst/Flushing',
        keywords: 'Salad, Wine, Beer, Serene, Spacious, Casual, Bright, American',
        annual_dol: 245.06,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8559,
        store_longitude: -73.8677,
        total_orders: 126.08,
        neighborhood: 'East Bronx/Pelham Parkway',
        keywords:
            'Lobster, Lobster Roll, Lobster Tail, Cajun, Spicy, Triple Sec, Casual, Classic, Great',
        annual_dol: 515.38,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.784,
        store_longitude: -73.9779,
        total_orders: 105.53,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Coffee, Fresh, Friendly',
        annual_dol: 431.36,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6339,
        store_longitude: -74.0099,
        total_orders: 174.43,
        neighborhood: 'Sunset Park',
        keywords: 'Karaoke, Kebab, Wine, Spicy, Clean, Classic, Wonderful, Great, Excellent, Pure',
        annual_dol: 713.02,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6155,
        store_longitude: -73.9937,
        total_orders: 1085.86,
        neighborhood: 'Bensonhurst',
        keywords:
            'Vegetarian, Sushi, Salad, Japanese, Spicy, Asian, Sashimi, Casual, Authentic, Excellent',
        annual_dol: 2241.8,
        description:
            'Basic Japanese eatery offering sushi & other specialties like hibachi meats in sparse surroundings.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7416,
        store_longitude: -73.6614,
        total_orders: 7969.87,
        neighborhood: '',
        keywords:
            'Tacos, Steak, Tequila, Mexican, Tasty, Beer, Margarita, Californian, Delicious, Hawaiian',
        annual_dol: 8138.98,
        description:
            '"AVO TACO is a fresh, new spot to relax and enjoy yummy deliciousness in a fun, fast-casual environment."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6544,
        store_longitude: -73.9594,
        total_orders: 325.94,
        neighborhood: 'Flatbush/Little Haiti',
        keywords:
            'Salad, Pizza, Vegetarian, Flavorful, Wine, Margarita, Caribbean, Rum, Jamaican, Romantic',
        annual_dol: 1569.45,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7638,
        store_longitude: -73.9145,
        total_orders: 248.41,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Pizza, Cajun, Cajun-Style, Beer, Irish, American, Vintage, Homemade, Premium, Creole',
        annual_dol: 656.2,
        description:
            'Sleek bar dishing up raw & cooked Cajun-style seafood by the pound along craft beer & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8265,
        store_longitude: -73.8223,
        total_orders: 922.65,
        neighborhood: 'East Bronx/Throgs Neck',
        keywords:
            'Mexican, Beer, Shrimp Cocktail, Wine, Coffee, Dessert, American, Homemade, Modern, Legendary',
        annual_dol: 1637.58,
        description:
            'American comfort eats & craft beer served in a low-key sports bar with a backyard patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.704,
        store_longitude: -73.9191,
        total_orders: 3504.92,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Salad, Adventurous, Asian, Vibrant, Local, Great, Casual',
        annual_dol: 3875.28,
        description:
            'Vibrant bar & happy hour haunt with Asian style décor serving skewers, hot dogs & bowls til 2am.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6238,
        store_longitude: -73.9757,
        total_orders: 218.19,
        neighborhood: 'Mapleton/Midwood',
        keywords: 'Burger, Salad, Beer, French, Fancy, Relaxed, Casual, Great',
        annual_dol: 222.82,
        description:
            'Relaxed neighborhood bar with a jukebox, pool table, darts & other games, plus sports on TV.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6586,
        store_longitude: -73.6452,
        total_orders: 55.11,
        neighborhood: '',
        keywords: 'American, Comfortable, Delicious, Rum, Ample, Casual',
        annual_dol: 208.69,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6298,
        store_longitude: -74.0742,
        total_orders: 593.14,
        neighborhood: 'Stapleton Heights',
        keywords:
            'Pizza, Cheesecake, Salad, Italian, Coffee, Meatball, Dessert, Delicious, Modern, Sparkling',
        annual_dol: 697.94,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8623,
        store_longitude: -73.6231,
        total_orders: 18.26,
        neighborhood: '',
        keywords:
            'Steak, Burger, Salad, French Toast, Italian, French, Bbq, Greek, Quesadillas, American',
        annual_dol: 32.01,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7546,
        store_longitude: -73.9872,
        total_orders: 4238.51,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Stylish, Beer, Scotch, Canadian, Quaint, Delicious, Cozy, Spicy, Vintage',
        annual_dol: 4339.62,
        description:
            'Quaint, classy hangout with a vintage vibe presenting craft cocktails, local beers & diverse dishes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7509,
        store_longitude: -73.9868,
        total_orders: 88.75,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Burger, Hamburger, Salad, Chicken Caesar Salad, Beer, Coffee, Vodka, Tasty, Caesar Salad, American',
        annual_dol: 362.78,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7561,
        store_longitude: -73.9934,
        total_orders: 494.05,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords: 'Hotel, Friendly',
        annual_dol: 711.5,
        description:
            'The understated rooms provide flat-screen TVs and Wi-Fi, plus coffeemakers and minifridges. Some feature city views and/or sofabeds.Amenities include 24-hour bar service and event space. Breakfast is available.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7154,
        store_longitude: -73.9988,
        total_orders: 20394.81,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords: 'Beer, Wine, Chinese, Vintage, Fun, Impressive, Great, Lit, Signature, Casual',
        annual_dol: 22047.91,
        description:
            'Cocktails, beer & wine served in a unique, dimly lit bar that has beer pong, dice games & darts.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7143,
        store_longitude: -74.0032,
        total_orders: 5065.21,
        neighborhood: 'Civic Center',
        keywords:
            'Lobster, Tasty, Martini, Stylish, Hotel, Inviting, Impeccable, Fun, Eclectic, Sleek',
        annual_dol: 5857.21,
        description:
            'Upscale, stylish nightspot at 11 Howard hotel featuring cocktails, DJs & an eclectic bar-bites menu.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.716,
        store_longitude: -73.9965,
        total_orders: 5317.79,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Luxurious, Hotel, Upscale, Stylish, Spacious, Vibrant, Urban, Rooftop, Lively, Sleek',
        annual_dol: 12644.6,
        description:
            'skyscraper with an observation deck.Hip rooms with original artwork and floor-to-ceiling windows feature free Wi-Fi and flat-screens, plus minibars and rainfall showers. Upgraded rooms add city views. Suites include separate living rooms with pull-out sofas. In-room dining is available.There’s a stylish rooftop bar with city views. Breakfast is offered for a fee. Other amenities include a 24/7 gym, an art gallery and a terrace.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7223,
        store_longitude: -74.0041,
        total_orders: 2204.22,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Italian, Elegant, Homemade, Perfect, Contemporary, Amazing, Casual, Great, Excellent, Fusion',
        annual_dol: 2258.1,
        description:
            'Eatery where chefs cook & serve pastas from a central kitchen, with counter seating & banquettes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5791,
        store_longitude: -74.1085,
        total_orders: 19.81,
        neighborhood: 'Midland Beach',
        keywords:
            'Peruvian, Salad, Italian, Latin, Contemporary, Indian, American, Casual, Traditional, Local',
        annual_dol: 80.96,
        description:
            'Contemporary eatery offering seafood & traditional Latin American cuisine in a convivial atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.703,
        store_longitude: -74.012,
        total_orders: 360.94,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Hotel, Clean, Friendly, Modern',
        annual_dol: 368.6,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7178,
        store_longitude: -74.0014,
        total_orders: 722.22,
        neighborhood: 'Lower Manhattan/Chinatown',
        keywords:
            'Tasty, Beer, Fun, Innovative, Clean, Great, Artisanal, Unforgettable, Local, Unique',
        annual_dol: 1263.25,
        description:
            'Axe throwing sessions in a Viking-themed lounge, plus craft beers, cocktails & bar snacks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7221,
        store_longitude: -74.0036,
        total_orders: 1241.94,
        neighborhood: 'Lower Manhattan/SoHo',
        keywords: 'Tequila, Wine, Whiskey, Beer, Bourbon, Hotel, Cozy, Delicious, Fancy, Classic',
        annual_dol: 1331.53,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7173,
        store_longitude: -74.0104,
        total_orders: 216.18,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Italian, Pizza, Salad, Upscale, Trendy, Rustic, Hotel, Bellini, Prosecco, Warm',
        annual_dol: 251.98,
        description:
            'Airy, wood-accented eatery offering classic Italian standards in warm surrounds plus patio seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7043,
        store_longitude: -74.0095,
        total_orders: 478.73,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Vegetarian, Pizza, Italian, Wine, Vodka, Austrian, Rustic, Mediterranean, Refined',
        annual_dol: 488.89,
        description:
            'Basement-level white-tablecloth dining with Northern Italian classics like pasta & grilled meats.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6903,
        store_longitude: -73.8147,
        total_orders: 32.06,
        neighborhood: 'South Richmond Hill',
        keywords: 'Vegetarian, Wine, American, Hotel, Fun, Caribbean, Casual, Biryani, Great',
        annual_dol: 79.74,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7208,
        store_longitude: -73.8469,
        total_orders: 3312.06,
        neighborhood: 'Forest Hills/Flushing',
        keywords:
            'Burger, Beer, Mexican, Hamburger, Whiskey, Cajun, Delicious, Nachos, Warm, Legendary',
        annual_dol: 3382.34,
        description:
            'Casual chain restaurant with a festive vibe serving beer, cocktails & a wide menu of American fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7456,
        store_longitude: -73.9885,
        total_orders: 1604.35,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Beer, Coffee, Trendy, Stylish, Comfortable, Lebanese, Italian, Hotel, American',
        annual_dol: 4496.17,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7531,
        store_longitude: -74.0012,
        total_orders: 5907.66,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Wine, Salad, Coffee, Mexican, Beer, Pizza, Chinese, Delicious, Mediterranean, Wholesome',
        annual_dol: 6236.08,
        description:
            '"Welcome to ANA. The sunny living room of Hudson Yards perfect for taking a break from your fast-paced day. We create wholesome hand-made foods from fresh, local ingredients for you to enjoy throughout your day. Fuel up in the morning with selections from our café area including smoothies and juices made to order, fresh coffee and pastries, and daily seasonal breakfast entrées. For your afternoon escape, enjoy a slice of pizza from our brick oven, tacos, a selection of prepared salads and sandwiches, or a seasonal entree made in-house daily. ."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7665,
        store_longitude: -73.9786,
        total_orders: 4766.96,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Romantic, Hotel, American, Perfect, Yummy, Innovative, Traditional, Wonderful, Contemporary, Fine',
        annual_dol: 4973.64,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7658,
        store_longitude: -73.9875,
        total_orders: 348.42,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Wine, Salad, Mediterranean, Premium, Lively, Spanish, Yummy, Greek, Greek Salad, Relaxed',
        annual_dol: 416.2,
        description:
            'Relaxed wine bar featuring a Mediterranean menu served in the main room, cellar or garden.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.809,
        store_longitude: -73.9518,
        total_orders: 364.23,
        neighborhood: 'Harlem/West Harlem',
        keywords:
            'Beer, Savory, Dessert, Legendary, Intimate, Premium, Amazing, Great, Tradition, Artisanal',
        annual_dol: 405.24,
        description:
            'Cocktail lounge with a chill vibe presenting creative concoctions in an art-filled atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7623,
        store_longitude: -73.9761,
        total_orders: 55.91,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Hotel, Upscale, Stylish, Modern, Great, Clean, Rooftop, Contemporary, Cultural',
        annual_dol: 228.56,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7171,
        store_longitude: -73.9953,
        total_orders: 1390.98,
        neighborhood: 'Lower East Side/Chinatown',
        keywords:
            'Hotel, Rooftop, Trendy, Spectacular, Stylish, Rum, Delicious, Beer, American, Chinese',
        annual_dol: 1451.28,
        description:
            "Modern rooms have floor-to-ceiling windows, desks and free Wi-Fi, plus flat-screen TVs, minifridges and coffeemakers. Upgraded rooms and suites add living areas and city views; some have terraces. Room service is available.There's a warm Chinese eatery showing big-screen sports, a slick neon-lit ramen bar, and a breezy beer garden offering American dishes, plus a hip rooftop cocktail bar with city views. There's also a 24-hour fitness room and a business center.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7557,
        store_longitude: -73.9689,
        total_orders: 16.75,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Wine, Dessert, Beer, Donut, Japanese, Polished, Sweet, Premium, Healthy, Contemporary',
        annual_dol: 68.47,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6182,
        store_longitude: -74.0304,
        total_orders: 2.81,
        neighborhood: 'Fort Hamilton',
        keywords:
            'Salad, Mediterranean, Delicious, Homemade, Healthy, Casual, Shawerma, Local, Fresh, Middle Eastern',
        annual_dol: 11.5,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7419,
        store_longitude: -74.0035,
        total_orders: 7926.3,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tasty, Gluten Free, Spicy, Trendy, Asian, Chinese, Sweet, Sashimi, Premium, Thai',
        annual_dol: 8556.5,
        description:
            'Trendy nightspot serving cocktails amid a cool, Chinese-themed setting with exposed-brick walls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6793,
        store_longitude: -73.9814,
        total_orders: 396.12,
        neighborhood: 'Park Slope',
        keywords: 'Beer, Gourmet, Warm, Casual, Sumptuous, Signature',
        annual_dol: 404.53,
        description:
            'Cozy, rustic-chic lounge with a large backyard area offering signature cocktails & happy hour deals.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7386,
        store_longitude: -73.9935,
        total_orders: 173.81,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Italian, Casual, Perfect, Welcoming, Fine, Artisanal',
        annual_dol: 189.43,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7586,
        store_longitude: -73.9787,
        total_orders: 393.18,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Steak, Lobster, Lobster Roll, Burger, Dessert, Wine, French, Coffee, Spicy, Hotel',
        annual_dol: 1607.19,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6307,
        store_longitude: -74.1031,
        total_orders: 2653.59,
        neighborhood: 'Mid Island/West Brighton',
        keywords:
            'Tacos, Tequila, Steak, Mexican, Margarita, Nachos, Delicious, Fajitas, Stylish, Fun',
        annual_dol: 2709.89,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7257,
        store_longitude: -73.984,
        total_orders: 1198.89,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vegan, Burger, Cornbread, Whiskey, Champagne, Beer, Mexican, Bourbon, Cuban, Bbq',
        annual_dol: 2932.36,
        description:
            'Intimate bar offering an extensive list of cocktails that showcase classic Italian & craft bitters.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7229,
        store_longitude: -73.986,
        total_orders: 3809.49,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Vodka, Beer, Gin, Stylish, Ale, Instagrammable, Cozy, Spacious, Legendary, Classy',
        annual_dol: 3890.32,
        description:
            'Bi-level standby with an old-school haunt on the 1st floor & a relaxed lounge with views on the 2nd.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7439,
        store_longitude: -73.9839,
        total_orders: 1307.69,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Champagne, Wine, Hotel, Coffee, Stylish, Salad, French Toast, Delicious, French, Funky',
        annual_dol: 1423.1,
        description:
            'The polished rooms come with floor-to-ceiling windows and flat-screen TVs, as well as laptop safes, minibars and Wi-Fi. Upgraded suites add furnished wraparound balconies, 4-poster beds, chandeliers and/or free-standing whirlpool tubs.Amenities include a chic rooftop restaurant and bar, plus a rooftop pool area with a cocktail lounge and daybeds.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7226,
        store_longitude: -73.9858,
        total_orders: 653.49,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Beer, Martini, Quaint, Fun, Friendly, Legendary, Cool, Authentic, Aesthetic, Vivid',
        annual_dol: 711.16,
        description:
            'NYC offshoot of a longtime Las Vegas punk bar, best known for its bacon martinis.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5882,
        store_longitude: -73.6665,
        total_orders: 46.47,
        neighborhood: '',
        keywords:
            'Sushi, Vegetarian, Mexican, Wine, Japanese, Delicious, Trendy, Ramen, Healthy, Asian',
        annual_dol: 189.97,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7415,
        store_longitude: -73.981,
        total_orders: 11983.98,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Salad, Beer, Savory, Delicious, Trendy, Rustic, Spacious, American, Modern',
        annual_dol: 12503.57,
        description:
            'Trendy tavern with rustic-chic decor, comfort food, draft beer & classic cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7486,
        store_longitude: -73.9826,
        total_orders: 414.54,
        neighborhood: 'Midtown Manhattan/Midtown South',
        keywords:
            'Steak, Whiskey, Breakfast Burrito, Chinese, Luxurious, Spacious, Sleek, Modern, Western, Fun',
        annual_dol: 1694.49,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7408,
        store_longitude: -74.0047,
        total_orders: 159.48,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Lobster, Sushi, Pizza, Hamburger, Meatball, Tequila, Margarita, Thai, Cozy',
        annual_dol: 651.89,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.746,
        store_longitude: -73.914,
        total_orders: 566.59,
        neighborhood: 'Sunnyside/Flushing/Woodside',
        keywords:
            'Burger, Steak, Salad, Wine, Nachos, Irish, French Onion Soup, French, American, ',
        annual_dol: 579.97,
        description:
            "Neighborhood corner bar with Irish leanings, including shepherd's pie & a hearty comfort-food menu.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8213,
        store_longitude: -73.9307,
        total_orders: 3459.2,
        neighborhood: 'West Bronx',
        keywords:
            'Steak, Salad, Wine, Vodka, Italian, Delicious, Shrimp Scampi, Meatball, Cozy, Comfortable',
        annual_dol: 3549.52,
        description:
            'Vibrant restaurant/bar featuring seafood & steak along with frequent live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7731,
        store_longitude: -73.9519,
        total_orders: 424.53,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords:
            'Upscale, Coffee, Delicious, Salad, Warm, Baked Potato, Healthy, Spectacular, Superb, French',
        annual_dol: 485.22,
        description:
            'Serves traditional favorites like omelets, pancakes, sandwiches & salads. Delivery available.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8305,
        store_longitude: -73.8493,
        total_orders: 605.45,
        neighborhood: 'East Bronx/Unionport',
        keywords: 'Beer, American, Fun, Contemporary, Great, Local',
        annual_dol: 631.7,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7698,
        store_longitude: -73.9543,
        total_orders: 2180.06,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Beer, Burger, Wine, Salad, Rustic, American, Comfortable, Smooth, Friendly, Casual',
        annual_dol: 2227.67,
        description:
            'This casual joint specializes in a rotating list of American draft microbrews plus hearty pub fare.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7653,
        store_longitude: -73.9607,
        total_orders: 117.46,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Wine, Italian, Delicious, Romantic, Salad, Cozy, Premium, Awesome, Friendly, Great',
        annual_dol: 153.62,
        description:
            'Local joint offering wine, Italian small plates & meal-sized salads in a warm, laid-back atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7297,
        store_longitude: -73.9582,
        total_orders: 75.22,
        neighborhood: 'Greenpoint',
        keywords: 'Salad, Pizza, Vegetarian, Wine, Beer, Vietnamese, Bbq, Peruvian, Spicy, Stylish',
        annual_dol: 78.48,
        description:
            'Contemporary Vietnamese cuisine served in a hip, minimalist space with lots of greenery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.646,
        store_longitude: -73.9579,
        total_orders: 1597.69,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Magical, Upscale, Opulent, Legendary, Exquisite, Memorable',
        annual_dol: 2303.14,
        description:
            "The Kings Theatre, formerly Loew's Kings Theatre, is a live performance venue in the Flatbush neighborhood of Brooklyn, New York City. Opened by Loew's Theatres as a movie palace in 1929 and closed in 1977, the theater sat empty for decades until a complete renovation was initiated in 2010.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.673,
        store_longitude: -73.9829,
        total_orders: 23.73,
        neighborhood: 'Park Slope',
        keywords:
            'Pizza, Burger, Gluten Free, Beer, Vodka, Salad, Delicious, Perfect, Local, Casual',
        annual_dol: 97,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.719,
        store_longitude: -73.845,
        total_orders: 5248.56,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Burger, Pizza, Hamburger, Cheesecake, Sushi, Salad, Dessert, Beer, Whiskey, Bbq',
        annual_dol: 5454.2,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.719,
        store_longitude: -73.8468,
        total_orders: 709.48,
        neighborhood: 'Forest Hills/Flushing',
        keywords: 'Rich, Memorable, Iconic, Fun, Classy, Tradition, Fresh, Local',
        annual_dol: 740.24,
        description:
            'The West Side Tennis Club is a private tennis club located in Forest Hills, a neighborhood in the New York City borough of Queens. The club has 38 tennis courts in all four surfaces, a junior Olympic-size swimming pool and other amenities.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6603,
        store_longitude: -73.8307,
        total_orders: 16.22,
        neighborhood: 'Old Howard Beach/Jamaica/Howard Beach',
        keywords: 'Pizza, Tasty, Beer, Irish, Great, Casual, Local',
        annual_dol: 66.32,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7345,
        store_longitude: -74.0001,
        total_orders: 7806.75,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Steak, Burger, Salad, Italian, Tasty, Dessert, Bbq, Spicy, French, Caesar Salad',
        annual_dol: 7993.63,
        description:
            'Cozy chophouse offering updated dishes & sustainable cuts, plus playful artwork & drinks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8436,
        store_longitude: -73.836,
        total_orders: 347.49,
        neighborhood: 'Pelham Bay',
        keywords: 'Pizza, Garlic Bread, Meatball, Italian, Bbq, French, Local, Fresh, Casual',
        annual_dol: 362.55,
        description:
            'Counter-serve pizzeria also offering familiar salads, hot Italian subs & oven-baked pastas.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8474,
        store_longitude: -73.832,
        total_orders: 24.42,
        neighborhood: 'Pelham Bay',
        keywords: 'Beer, Karaoke, Legendary, Casual, Great',
        annual_dol: 92.01,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7042,
        store_longitude: -73.9104,
        total_orders: 785.53,
        neighborhood: 'Flushing/Ridgewood',
        keywords: 'Burger, Tacos, Mexican, Beer, German, Coffee, Ale, Wine, American, French',
        annual_dol: 802.2,
        description:
            '"The Seneca is a neighborhood bar/restaurant specializing in scratch-made food, classic cocktails, beer and wine. We are a favorite destination of locals for dinner, late night drinks, special events and everything in between."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8548,
        store_longitude: -73.8938,
        total_orders: 324.58,
        neighborhood: 'Belmont/West Bronx',
        keywords:
            'Lobster, Lobster Tail, Salad, Wine, Chicken Wings, Thai, Bbq, Spanish, Latin, Caesar Salad',
        annual_dol: 1326.79,
        description:
            'Easygoing restaurant offering classic burgers & sushi, plus chicken wings & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8552,
        store_longitude: -73.8872,
        total_orders: 49.75,
        neighborhood: 'Little Italy/Belmont',
        keywords:
            'Burger, Tacos, Sushi, Mexican, Pizza, Shrimp Cocktail, Delicious, Warm, Casual, Polished',
        annual_dol: 128.21,
        description:
            'Warm Mexican option with a polished interior offering classic dishes, mariachi music & karaoke.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7643,
        store_longitude: -73.967,
        total_orders: 1040.7,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Steak, Burger, Lobster, Kebab, Shrimp Cocktail, Bbq, Wine, American, French, Spicy',
        annual_dol: 1062.78,
        description:
            'American dishes by David Burke in a handsome space with a solarium & red leather booths.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7636,
        store_longitude: -73.9924,
        total_orders: 5329.97,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Whiskey, Wine, Scotch, Bourbon, Ale, Rum, Cozy, Tex-Mex, Intimate, Irish',
        annual_dol: 13236.31,
        description:
            'Tiny, dimly lit watering hole boasting an extensive selection of whiskeys & bottled beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8245,
        store_longitude: -73.8591,
        total_orders: 308.2,
        neighborhood: 'East Bronx/Soundview',
        keywords: 'Burger, Sushi, Pizza, Southern, American, Friendly, Healthy, Local, Teriyaki',
        annual_dol: 1360.25,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7439,
        store_longitude: -73.996,
        total_orders: 3069.87,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Tacos, Steak, Cajun, Beer, Salad, Cuban, Bbq, Puerto Rican, Savory, Latin',
        annual_dol: 5437.83,
        description:
            'Modern Latin fare including Sunday brunch, served with signature cocktails in funky quarters.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7355,
        store_longitude: -73.998,
        total_orders: 1082.66,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Wine, Beer, Delicious, Cozy, Iraqi, Mediterranean, Israeli, Shawarma, Syrian, Contemporary',
        annual_dol: 1359.71,
        description:
            'Middle Eastern specialties (like kibbeh, kubeh and meze) are served in a chic, contemporary setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5416,
        store_longitude: -74.1772,
        total_orders: 122.88,
        neighborhood: 'Arden Heights',
        keywords: 'Cozy, Yummy, Fun, Clean, Awesome, Friendly, Outstanding, Great, Casual',
        annual_dol: 524.68,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7364,
        store_longitude: -73.9973,
        total_orders: 350.21,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Margarita, Burritos, Local, Warm, Casual, Premium',
        annual_dol: 1207.78,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6866,
        store_longitude: -73.9747,
        total_orders: 207.56,
        neighborhood: 'Fort Greene',
        keywords: 'German, Pizza, Italian, Salad, Beer, Coffee, Wine, Organic, French, Trendy',
        annual_dol: 424.22,
        description:
            'Trendy indoor beer garden serving a menu of German food & beers at wooden tables.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7191,
        store_longitude: -73.9551,
        total_orders: 671.42,
        neighborhood: 'Williamsburg',
        keywords:
            'Peruvian, Mexican, Sushi, Wine, Coffee, Vegan, Dessert, Upscale, Beer, Uruguayan',
        annual_dol: 2744.54,
        description:
            '"Republic Latin Fusion is a restaurant and bar based in Williamsburg, Brooklyn. Founded in 2021 by friends and co-workers who bonded over the love for hospitality. The flagship location in Williamsburg, Brooklyn serves dishes inspired from the culinary diversity of Latin America and fuses it with unique twists and flavors from Asia. Live music, elevated experiences, and a lively yet intimate atmosphere, Republic Latin Fusion takes your dining experience to the next level. Our menu has multiple options for vegan, vegetarian, and gluten-free dishes."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7249,
        store_longitude: -73.983,
        total_orders: 1394.66,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Pizza, Italian, Beer, Wine, Hotel, Stylish, Prime Rib, Polish, Fabulous, Great',
        annual_dol: 1424.25,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7142,
        store_longitude: -73.9557,
        total_orders: 16.94,
        neighborhood: 'Williamsburg',
        keywords:
            'Tequila, Tacos, Mexican, Beer, Margarita, Steak, Wine, Nachos, Quesadillas, Enchiladas',
        annual_dol: 167.92,
        description:
            'Longtime counter-serve joint with a large menu of Mexican classics, plus wholesale tortillas.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7238,
        store_longitude: -73.9879,
        total_orders: 166.35,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Salad, Steak, Wine, Coffee, French Toast, Beer, Mediterranean, French, Tunisian, Moroccan',
        annual_dol: 481,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7455,
        store_longitude: -73.9794,
        total_orders: 2663.45,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Cheesesteak, Wine, Beer, Margarita, Nachos, Philly Cheesesteak, Rustic, French, American',
        annual_dol: 2663.45,
        description:
            'Reclaimed wood & weathered brick set the scene for hand-drawn cask ales, craft brews & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7138,
        store_longitude: -73.9619,
        total_orders: 2365.82,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Whiskey, Vodka, Beer, Margarita, American, Clean, Fresh, Fantastic, Great, Cool',
        annual_dol: 3627.75,
        description:
            '"A neighborhood sports bar featuring fresh cocktails along with excellent beer and whiskey selection"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7195,
        store_longitude: -73.9583,
        total_orders: 2008.41,
        neighborhood: 'Williamsburg',
        keywords: 'Burger, Lobster, Lobster Roll, Steak, Salad, Tasty, Donut, Beer, Delicious, Bbq',
        annual_dol: 2051.03,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7208,
        store_longitude: -73.9577,
        total_orders: 388.19,
        neighborhood: 'Williamsburg',
        keywords: 'Pizza, Turkish, Spanish, Savory, Russian, Modern, Casual, Local, Natural, Hip',
        annual_dol: 614.18,
        description:
            '"Bathhouse combines the vibrant, social elements of bathhouses from around the world alongside an array of treatments for the modern athlete and lifestyle enthusiast. We provide elite level treatments for those who want to look, feel and perform their very best."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7558,
        store_longitude: -73.6231,
        total_orders: 961.97,
        neighborhood: '',
        keywords:
            'Italian, Pizza, Relaxed, Contemporary, American, Casual, Authentic, Perfect, Fresh, Excellent',
        annual_dol: 1013.57,
        description:
            '"GianCarlo DiMaggio and Danny Baez are the driving force behind the storied Cassariano Italian Eatery Mineola. The freshest ingredients with the utmost service in mind are the benchmarks for the finest, award winning Italian cuisine. Driven by their strong passion for food and a deep love for their profession, these partners prepare authentic contemporary Italian cuisine while cultivating a casual, relaxed atmosphere for their patrons. Cassariano is the perfect blend of Bistro flavors and fine cuisine. We look forward to exceeding your expectations!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7399,
        store_longitude: -74.0059,
        total_orders: 6041.32,
        neighborhood: 'Meatpacking District/Greenwich Village',
        keywords:
            'Hotel, Coffee, Meatball, Salad, Trendy, American, Rooftop, Iconic, Contemporary, Hip',
        annual_dol: 6231.88,
        description:
            "from the elevated High Line park.Sophisticated rooms come with free Wi-Fi and flat-screen TVs, plus high-thread-count sheets and minibars. Upgraded rooms add sitting areas and/or Juliet balconies with river or city views. Suites feature separate living rooms, and posh bi-level suites include dining areas and fireplaces.There's a trendy American restaurant, a hip lobby bar, and a rooftop terrace with a lounge and an outdoor pool, as well as a spa and a gym.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7333,
        store_longitude: -74.0023,
        total_orders: 3551.72,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Margarita, Legendary, Spanish, Classic, Fun, Relaxed, Great, Excellent, Casual',
        annual_dol: 3957.35,
        description:
            'Classic gay hangout offers piano bar upstairs & a basement level for dancing, music & mingling.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7318,
        store_longitude: -74.0016,
        total_orders: 1157.5,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Sushi, Asian, Chinese, Thai, Australian, Superb, Awesome, Fusion, Excellent',
        annual_dol: 1720.11,
        description: '"South East Asian small plates, good for sharing/groups"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7271,
        store_longitude: -73.6345,
        total_orders: 236.34,
        neighborhood: '',
        keywords:
            'Tacos, Mexican, Beer, Margarita, Nachos, Salad, Enchiladas, Hotel, Vibrant, Modern',
        annual_dol: 302.12,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7332,
        store_longitude: -74.0035,
        total_orders: 217.35,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Korean, Sushi, Cozy, Casual, Modern, Hotel, Local, Hip, Great',
        annual_dol: 954.21,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7185,
        store_longitude: -74.0049,
        total_orders: 7.56,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Crab Cakes, Pizza, Coffee, Creamy, Yummy, Sweet, Australian, Fresh, American, Seasonal',
        annual_dol: 30.92,
        description:
            '"Tribeca’s new favorite eatery, twiggy to go offers coffee, crullers, pastries, an assortment of main courses & seasonal sides for carryout. Some of our specialties include roasted chicken, quiche, tomato tart, crab cakes & fried chicken. Ask about our catering today!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 198.29,
        neighborhood: '',
        keywords:
            'Mexican, Tacos, Steak, Burritos, Delicious, Local, Casual, Classic, Distinctive, Fine',
        annual_dol: 374.49,
        description:
            'Fast-food chain offering Mexican fare, including design-your-own burritos, tacos & bowls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7025,
        store_longitude: -73.6433,
        total_orders: 8.69,
        neighborhood: '',
        keywords: 'Karaoke, Warm, Beer, Fun, Phenomenal, Great, Casual',
        annual_dol: 35.51,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6955,
        store_longitude: -73.932,
        total_orders: 216.23,
        neighborhood: 'Bushwick',
        keywords:
            'Vegan, Wine, Beer, Wholesome, Vibrant, American, Classic, Fantastic, Great, Amazing',
        annual_dol: 883.88,
        description:
            '"Ornithology Jazz Club is a no-cover Jazz listening room with excellent cocktails with wholesome vegan bites with sustainability at heart. World-class live jazz with exciting events such as gallery pop-up, vegan chef pop-up, spoken word open mic and stand-up comedy"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8452,
        store_longitude: -73.9024,
        total_orders: 144.27,
        neighborhood: 'Tremont/Mount Hope/West Bronx',
        keywords: 'Lobster, Lasagna, Lobster Tail, Salad, Vegan, Cajun, Beer, Wine, Vodka, Latin',
        annual_dol: 157.01,
        description:
            'Lively destination for fish, seafood & meat dishes with a Latin twist, plus cocktails & hookah.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.808,
        store_longitude: -73.7356,
        total_orders: 6311.84,
        neighborhood: '',
        keywords:
            'Burger, Spicy, Iranian, Coffee, Delicious, Spacious, Premium, Mediterranean, Great, Local',
        annual_dol: 8592.39,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7539,
        store_longitude: -73.9894,
        total_orders: 872.58,
        neighborhood: 'Midtown Manhattan/Midtown South/Garment District',
        keywords:
            'Moroccan, Vegetarian, Salad, Mediterranean, Warm, African, Classic, Hip, Casual, Authentic',
        annual_dol: 3566.83,
        description:
            'Warm eatery, covered in colorful textiles, featuring Moroccan cuisine & belly dancing some nights.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7287,
        store_longitude: -74.0046,
        total_orders: 5251.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Whiskey Sour, Whiskey, Beer, Wine, Vodka, Ale, Salad, Cozy, Warm, American',
        annual_dol: 5294.31,
        description: 'This pub salutes Brooklyn with local beers & spirits plus creative pub food.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7299,
        store_longitude: -74.0044,
        total_orders: 388.77,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Vegan, Vegetarian, Tacos, Mexican, Tequila, Gluten Free, Nachos, Wine, Tasty, Warm',
        annual_dol: 1254.52,
        description:
            'Contemporary Mexican spot with a colorful, all-vegan menu, plus tequila & mezcal cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7304,
        store_longitude: -74.0066,
        total_orders: 519.1,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Lobster, Spicy, Zesty, Creamy, Cozy, Rich, Playful, Seasonal, Contemporary, Casual',
        annual_dol: 553.61,
        description:
            'A seasonal, clam-focused seafood menu presented in a cozy, brick-walled space with large windows.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7906,
        store_longitude: -73.9744,
        total_orders: 4963.1,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Burger, Salad, Vegetarian, Bbq, Italian, Coffee, Nachos, Beer, Fried Chicken, Wine',
        annual_dol: 13488.05,
        description:
            'Long-standing BBQ operation turning out classic meat dishes, plus sides & craft beers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7847,
        store_longitude: -73.9774,
        total_orders: 1175.29,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Beer, Whiskey, Gin, Ale, Burger, Margarita, Pizza, Belgian, Nachos, American',
        annual_dol: 1249.63,
        description:
            'Fans (especially Florida Gators) cheer their teams on TV at this tavern with pub food & beer pong.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7841,
        store_longitude: -73.9774,
        total_orders: 243.99,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords: 'Sushi, Salad, Wine, Spicy, Japanese, Trendy, Stylish, Sashimi, Gin, Asian',
        annual_dol: 249.17,
        description:
            'This stylish sushi specialist serves traditional & inventive Japanese fare amid eye-catching decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7328,
        store_longitude: -73.6831,
        total_orders: 1135.87,
        neighborhood: '',
        keywords:
            'Steak, Salad, Coffee, Italian, Beer, American, Delicious, Caesar Salad, Modern, Homemade',
        annual_dol: 1159.97,
        description:
            'Eclectic restaurant serving French, American & Italian dishes, with live music on weekends.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6435,
        store_longitude: -73.5396,
        total_orders: 335.2,
        neighborhood: '',
        keywords:
            'Vegetarian, Pizza, Italian, Wine, Shrimp Cocktail, Delicious, Ale, Relaxed, Friendly, Fun',
        annual_dol: 609.16,
        description:
            'Relaxed, enduring establishment offering family-style Italian meals, seafood & scenic bay views.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7585,
        store_longitude: -73.9196,
        total_orders: 1075.02,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Salad, Mexican, Cuban, Spanish, Quesadillas, Fabulous, American, Amazing, Incredible, Great',
        annual_dol: 2948.5,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7133,
        store_longitude: -73.6055,
        total_orders: 38.11,
        neighborhood: '',
        keywords: 'Steak, Tacos, Casual',
        annual_dol: 155.79,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7625,
        store_longitude: -73.9824,
        total_orders: 7402.91,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Steak, Burger, Shrimp Cocktail, Gourmet, Gluten Free, Legendary, Spicy, Irish, American, French',
        annual_dol: 7612.48,
        description:
            'Landmark restaurant serving steak & seafood dishes in a formal setting since 1973.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.9063,
        store_longitude: -73.9041,
        total_orders: 263.55,
        neighborhood: 'North Riverdale',
        keywords:
            'Crab Cakes, Burger, Pizza, Shrimp Scampi, Italian, Spicy, Mediterranean, Ale, Thai, American',
        annual_dol: 291.17,
        description:
            'Italian staple delivering familiar fare in comfortable environs with a neighborhood feel.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8944,
        store_longitude: -73.8967,
        total_orders: 78,
        neighborhood: 'Fieldston',
        keywords: 'Burger, Steak, Salad, Meatball, Beer, French, Irish, American, Latin, Casual',
        annual_dol: 83.13,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7203,
        store_longitude: -73.9875,
        total_orders: 203.22,
        neighborhood: 'Lower East Side',
        keywords:
            'Flavorful, Tasty, Chicken Wings, Bbq, Lebanese, Premium, Cozy, Swiss, Sophisticated, Complex',
        annual_dol: 451.87,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6573,
        store_longitude: -73.9505,
        total_orders: 168.13,
        neighborhood: 'Prospect Lefferts Gardens/Little Caribbean',
        keywords:
            'Lobster, Wine, Delicious, Beer, Cozy, Sweet, Vietnamese, Fun, Caribbean, Vibrant',
        annual_dol: 687.26,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7404,
        store_longitude: -73.9922,
        total_orders: 5572.37,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords: 'Burger, Sushi, Steak, Salad, Japanese, Coffee, Beer, Wine, Organic, Delicious',
        annual_dol: 5754.7,
        description:
            'Elegant bistro by day offering refined & modern Japanese plates, cocktail & wine bar by night.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5888,
        store_longitude: -73.8173,
        total_orders: 184.53,
        neighborhood: 'Far Rockaway/Rockaway Beach',
        keywords:
            'Vegetarian, Vegan, Salad, Flavorful, Thai, Dessert, Upscale, Delicious, Beer, Asian',
        annual_dol: 240.74,
        description:
            'Upscale Thai restaurant with a lounge offers scenic views, live music & creative cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7437,
        store_longitude: -73.9999,
        total_orders: 78.58,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Vegan, Vegetarian, Burger, Cheesesteak, Sushi, American, Philly Cheesesteak, Natural, Crisp, Contemporary',
        annual_dol: 321.19,
        description:
            'Easygoing bistro serving plant-based homestyle cooking amid compact surroundings.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6727,
        store_longitude: -73.9626,
        total_orders: 6035.2,
        neighborhood: 'Crown Heights',
        keywords:
            'Wine, Beer, Delicious, Martini, Premium, American, Awesome, Sweet, Amazing, Retro',
        annual_dol: 6261.49,
        description:
            'Craft cocktails & elevated bar bites are the focus at this hip, intimate hangout with a retro vibe.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.616,
        store_longitude: -74.1387,
        total_orders: 917.92,
        neighborhood: 'Westerleigh/Mid Island',
        keywords:
            'Pizza, Italian, Gluten Free, Wine, American, Delicious, Turkish, Homemade, Awesome, Great',
        annual_dol: 957.68,
        description:
            "Informal Italian eatery featuring familiar fare & pies, plus a hand-on kids' pizza-making class.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7271,
        store_longitude: -73.9838,
        total_orders: 814.77,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Wine, Scotch, Bourbon, Beer, Upscale, Delicious, Premium, Authentic, Perfect, Intimate',
        annual_dol: 2512.19,
        description:
            'Petite St. Marks Place wine bar offering a variety of vinos paired with small plates.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7119,
        store_longitude: -73.9999,
        total_orders: 64.41,
        neighborhood: 'Civic Center',
        keywords:
            'Vegan, Salad, Roast Turkey, Delicious, Swiss, English Muffin, Healthy, Perfect, Great, Fresh',
        annual_dol: 76.94,
        description:
            '"With over 25 years of experience in catering in New York, Deborah Miller Catering & Events helps you celebrate corporate and social events with delicious food made from fresh local ingredients. We take pride in providing NYC catering services from our state of the art kitchen, with hand-crafted cooking and exceptional service. We specialize in bar service catering, healthy catering including vegan and gluten-free menus, and lunch catering for your next corporate event. Contact us today for catering social events, weddings or corporate catering in NYC!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7222,
        store_longitude: -73.9831,
        total_orders: 814.38,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords:
            'Upscale, Perfect, Lively, Authentic, Awesome, Vintage, Great, Cool, Colorful, Casual',
        annual_dol: 3478.32,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6029,
        store_longitude: -74.1744,
        total_orders: 207.89,
        neighborhood: 'Bloomfield/Mid Island',
        keywords: 'American, Rustic, Fine',
        annual_dol: 254.45,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7148,
        store_longitude: -73.9616,
        total_orders: 7975.33,
        neighborhood: 'Williamsburg',
        keywords: 'Wine, Stylish, Delicious, Beer, Fresh, Bright, Perfect, Rum, Great, Fantastic',
        annual_dol: 9951.29,
        description: 'Attractive space for carefully-prepared craft cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7402,
        store_longitude: -73.975,
        total_orders: 5278.41,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Burger, Wine, Upscale, Beer, Rich, Californian, Tranquil, Elegant, American, Romantic',
        annual_dol: 5920.74,
        description:
            'Farm-to-table American cuisine served in an architecturally rich dining room, patio & bar area.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7576,
        store_longitude: -73.9287,
        total_orders: 69.84,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Beer, Wine, Ale, American, Traditional, Sweet, Local, Casual, Great',
        annual_dol: 136.93,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7372,
        store_longitude: -73.9882,
        total_orders: 3128.53,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Steak, Salad, Wine, French, Beer, Korean, American, Delicious, Caesar Salad, Traditional',
        annual_dol: 3337.71,
        description:
            "Bistro for dry-aged steaks & other French fare, with a bar that's strong on absinthe.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7361,
        store_longitude: -73.9874,
        total_orders: 819.02,
        neighborhood: 'Union Square/Midtown Manhattan',
        keywords:
            'Tasty, Salad, Korean, Italian, Seasonal, Contemporary, American, Spanish, Mediterranean, Robust',
        annual_dol: 1383.89,
        description:
            'Fashionable restaurant dishing up comfort food & cocktails with a contemporary twist.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6392,
        store_longitude: -73.9685,
        total_orders: 4266.21,
        neighborhood: 'Flatbush',
        keywords: 'Wine, Beer, Ale, Upscale, Warm, Vintage, Fun, Great, Seasonal, Casual',
        annual_dol: 5104.4,
        description:
            'Chill hideaway with a courtyard showcasing vintage guitars & live music, plus craft beers on tap.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8007,
        store_longitude: -73.9658,
        total_orders: 1847.83,
        neighborhood: 'Bloomingdale/UWS',
        keywords:
            'Steak, Burger, Whiskey, Beer, Mexican, Wine, Delicious, Premium, Friendly, Clean',
        annual_dol: 1865.02,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6466,
        store_longitude: -73.9707,
        total_orders: 1795.58,
        neighborhood: '',
        keywords: 'Pizza, Whiskey, Beer, Wine, Italian, Coffee, Delicious, Innovative, Hip, Bright',
        annual_dol: 2144.63,
        description:
            'Craft cocktails & Italian-style pizzas in innovative combos, in a hip, ornately wallpapered space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7691,
        store_longitude: -73.7741,
        total_orders: 941.39,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Pizza, Italian, Chinese, American, Warm, Friendly, Fresh, Casual, Awesome',
        annual_dol: 1213.96,
        description:
            'Intimate restaurant featuring wood-fired pizzas & traditional Italian cuisine in a warm atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7583,
        store_longitude: -73.9776,
        total_orders: 242.05,
        neighborhood: 'Midtown Manhattan/Diamond District',
        keywords:
            'Vegan, Korean, Wine, Classy, Friendly, Traditional, Iconic, Casual, Great, Complex',
        annual_dol: 989.4,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5837,
        store_longitude: -73.9383,
        total_orders: 106.59,
        neighborhood: 'Sheepshead Bay',
        keywords: 'Vodka, Great',
        annual_dol: 108.85,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7648,
        store_longitude: -73.7718,
        total_orders: 2070.74,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Steak, Burger, Lobster, Shrimp Cocktail, Salad, Wine, Spanish, Upscale, American, Delicious',
        annual_dol: 2117.65,
        description:
            'Traditional chophouse serving large steaks, classic sides & martinis in a clubby atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7641,
        store_longitude: -73.8105,
        total_orders: 559.76,
        neighborhood: 'Flushing/Murray Hill',
        keywords: 'Burger, Pizza, Korean, Spicy, Bbq, Asian, American, Casual, Friendly, Premium',
        annual_dol: 610.06,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7637,
        store_longitude: -73.7715,
        total_orders: 947.15,
        neighborhood: 'Bayside',
        keywords: 'Beer, Delicious, Lively, Friendly, Bbq, Great, Fresh, Local, Casual',
        annual_dol: 3672.94,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7556,
        store_longitude: -73.9783,
        total_orders: 444.81,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Salad, Dessert, Tasty, Beer, Hotel, Trendy, Classy, Traditional, Classic',
        annual_dol: 1561.53,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7782,
        store_longitude: -73.7783,
        total_orders: 0,
        neighborhood: 'Bayside/Flushing/Bay Terrace',
        keywords:
            'Cajun, Wine, Cajun-Style, Beer, Martini, Juicy, French, Fun, Friendly, Authentic',
        annual_dol: 0,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7142,
        store_longitude: -74.0094,
        total_orders: 260.52,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Steak, Garlic Bread, Hamburger, Shrimp Cocktail, Beer, Wine, Juicy, Warm, American, Rum',
        annual_dol: 411.31,
        description:
            'Pub with a large selection of whiskeys & craft beers, plus a big menu that includes weekend brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7133,
        store_longitude: -74.0106,
        total_orders: 2046.06,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords: 'Wine, Sushi, Beer, Japanese, Upscale, Hotel, Rich, Seasonal, Legendary, Classy',
        annual_dol: 8960.6,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7545,
        store_longitude: -73.9657,
        total_orders: 224,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Vegetarian, Salad, Gluten Free, Organic, Dessert, Mediterranean, Upscale, Greek, Healthy, Polished',
        annual_dol: 846.09,
        description:
            'Airy, upscale taverna serving a seafood-heavy Mediterranean menu, including a fixed-price lunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7103,
        store_longitude: -74.0138,
        total_orders: 937.4,
        neighborhood: 'World Trade Center/Financial District',
        keywords:
            'Steak, Lobster, Chocolate Cake, Cheesecake, Shrimp Cocktail, Chocolate Mousse, Salad, Mexican, Wine, Upscale',
        annual_dol: 957.29,
        description:
            'Upscale chain for aged prime beef, seafood & other traditional steakhouse fare in a clubby space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.743,
        store_longitude: -73.9905,
        total_orders: 3705.86,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Wine, Salad, Dessert, Elegant, Modern, Contemporary, Sleek, Indian, Seasonal, Comfortable',
        annual_dol: 3880.05,
        description:
            'Sophisticated Indian cuisine & a notable wine list offered in an elegant space with ornate accents.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7396,
        store_longitude: -73.9886,
        total_orders: 5017.72,
        neighborhood: 'Midtown Manhattan/Flatiron District',
        keywords:
            'Mexican, Tequila, Dessert, Wine, Trendy, Spicy, Delicious, Classy, Innovative, Contemporary',
        annual_dol: 5235.27,
        description:
            'Sleek Mexican spot with a big bar for locally sourced share plates, plus tequila & mezcal cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6982,
        store_longitude: -73.9952,
        total_orders: 40.78,
        neighborhood: 'Brooklyn Heights',
        keywords:
            'Luxurious, Upscale, Coffee, Elegant, Dessert, Hotel, Spectacular, Robust, High-End, Fresh',
        annual_dol: 95.07,
        description:
            '"The Watermark at Brooklyn Heights is located in the historic and beautifully renovated Leverich Towers Hotel. Independent Living, Assisted Living and Memory Care accommodations combine with award winning programming for a senior living community second to none. Members enjoy multiple dining venues, robust arts and cultural spaces, integrative health and wellbeing programs, and elegant residences with upscale design touches. Overlooking the Brooklyn Heights Promenade and connected to Manhattan by subways and ferries, The Watermark at Brooklyn Heights is your gateway to extraordinary senior living in the heart of it all. Come visit today to learn about becoming a member."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7599,
        store_longitude: -73.9913,
        total_orders: 356.46,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Hamburger, Steak, Vegetarian, Vegan, Gourmet, Beer, Tasty, Juicy, Wine',
        annual_dol: 364.03,
        description: 'Modern bistro chain serving gourmet meat & veggie burgers.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7569,
        store_longitude: -73.9847,
        total_orders: 1204.19,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Hotel, Upscale, American, Classy, Comfortable, Delectable, Friendly',
        annual_dol: 4922.34,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7733,
        store_longitude: -73.9838,
        total_orders: 548.72,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Lobster, Italian, Wine, Upscale, Roast Beef, Elegant, Polished, Perfect, Amazing, Iconic',
        annual_dol: 606.05,
        description:
            'Glass-walled dining room at Lincoln Center preparing upscale Italian fare in an open kitchen.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7759,
        store_longitude: -73.9818,
        total_orders: 45.21,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Vegetarian, Salad, Pizza, Wine, Coffee, Italian, Dessert, Organic, Delicious, Mediterranean',
        annual_dol: 184.81,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7459,
        store_longitude: -73.9571,
        total_orders: 16724.78,
        neighborhood: 'LIC/Hunters Point',
        keywords:
            'Sushi, Burger, Salad, Japanese, Chinese, Asian, Upscale, Vietnamese, Trendy, Thai',
        annual_dol: 17112.13,
        description:
            'Upscale Pan-Asian eatery in a high-rise restaurant with Manhattan skyline views and modern look.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7429,
        store_longitude: -73.6151,
        total_orders: 8428.64,
        neighborhood: '',
        keywords: 'Cheesecake, Salad, Tacos, Dessert, Wine, Italian, Thai, Cuban, Pot Roast, Warm',
        annual_dol: 8972.87,
        description:
            'Upmarket chain offering a wide-ranging American menu in sophisticated surroundings.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7411,
        store_longitude: -73.6405,
        total_orders: 22.23,
        neighborhood: '',
        keywords: 'Beer, Japanese, Local, American, Casual',
        annual_dol: 57.19,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7344,
        store_longitude: -74.0028,
        total_orders: 2722.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Steak, Salad, Wine, Italian, Trendy, Delicious, French, Romantic, Warm, Sustainable',
        annual_dol: 2967.46,
        description:
            'Low-lit place with outdoor terrace & downstairs bar serving French classics and sustainable wines.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7505,
        store_longitude: -73.9919,
        total_orders: 1032.34,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords:
            'Tequila, Mexican, Steak, Wine, Margarita, Coffee, Quesadillas, Spectacular, Exotic, Fresh',
        annual_dol: 1054.25,
        description:
            'Eatery offering Mexican classics, many tequilas, margaritas & beach decor near Penn Station.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7832,
        store_longitude: -73.9787,
        total_orders: 50.4,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Tequila, Vodka, Whiskey, Hamburger, Beer, Salad, Margarita, Scotch, Dessert, Gin',
        annual_dol: 211.83,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7408,
        store_longitude: -73.6406,
        total_orders: 12.59,
        neighborhood: '',
        keywords: 'Burger, Salad, Wine, Beer, Trendy, Ale, Warm, Irish, Cozy, Healthy',
        annual_dol: 51.47,
        description:
            'Laid-back ale house offering 16 beers on tap, Irish grub & other pub fare, plus DJs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7053,
        store_longitude: -73.656,
        total_orders: 2.65,
        neighborhood: '',
        keywords:
            'Pizza, Beer, Delicious, American, French, Homemade, Fabulous, Classic, Cool, Great',
        annual_dol: 10.83,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6672,
        store_longitude: -73.9882,
        total_orders: 1010.43,
        neighborhood: 'Park Slope',
        keywords: 'Warm, Cozy, Friendly, Fun, Fresh, Eclectic, Great, Casual, Excellent, Modern',
        annual_dol: 1208.75,
        description:
            '"An eclectic and modern neighborhood bar on Park Slope\'s famed 5th Avenue serving a rotation of craft beers and wines, while hosting a variety of events and parties."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6687,
        store_longitude: -73.9904,
        total_orders: 91.1,
        neighborhood: 'Gowanus',
        keywords:
            'Mexican, Delicious, Awesome, Fresh, Contemporary, Fantastic, Great, Casual, Local, Michelada',
        annual_dol: 372.37,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6396,
        store_longitude: -73.952,
        total_orders: 48.53,
        neighborhood: 'Flatbush/Little Haiti',
        keywords: 'Pizza, Stylish, Caribbean, Exotic, Great, Casual',
        annual_dol: 198.39,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.774,
        store_longitude: -73.964,
        total_orders: 118.48,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Shrimp Cocktail, Vegetarian, Hotdog, Turkey Sandwich, Dessert, Coffee, French Onion Soup, French, Caesar Wrap',
        annual_dol: 386.81,
        description:
            'Omelets, burgers, Greek specialties & other diner standards are served at this all-day coffee shop.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7051,
        store_longitude: -73.9201,
        total_orders: 2436.68,
        neighborhood: 'Bushwick',
        keywords: 'Hotdog, Beer, Warm, Wine, Cozy, Spacious, Fun, Awesome, Great, Quirky',
        annual_dol: 2518.43,
        description:
            'Laid-back bar with a sizable outdoor area, draft beers & quirky touches like personal USB ports.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7321,
        store_longitude: -74.0051,
        total_orders: 642.53,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Lobster, Delicious, Upscale, Beer, Legendary, Gin, Premium, Sweet, Casual',
        annual_dol: 2538.39,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6556,
        store_longitude: -73.677,
        total_orders: 2942.12,
        neighborhood: '',
        keywords:
            'Steak, Sushi, Japanese, Stylish, Sashimi, Perfect, Traditional, Asian, Friendly, Casual',
        annual_dol: 3029.14,
        description:
            'Stylish Japanese restaurant & lounge with hibachi chefs cooking at tables & a dining room for sushi.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7247,
        store_longitude: -73.6361,
        total_orders: 569.68,
        neighborhood: '',
        keywords:
            'Vegetarian, Salad, Italian, Vodka, Gourmet, Delicious, Trendy, Elegant, Yummy, Mediterranean',
        annual_dol: 581.77,
        description: 'Classic Italian fare in an elegant setting that includes sidewalk tables.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6579,
        store_longitude: -73.6649,
        total_orders: 50.95,
        neighborhood: '',
        keywords:
            'Chicken Parmigiana, Flavorful, Beer, Dessert, Meatball, Martini, Italian, French, Delicious, Creamy',
        annual_dol: 67.51,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7299,
        store_longitude: -74.0047,
        total_orders: 19.29,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Salad, Italian, Dessert, Upscale, Hotel, Homemade, Homey, Caesar Salad, Greek, Casual',
        annual_dol: 78.86,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7377,
        store_longitude: -74.004,
        total_orders: 392.83,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Beer, Ale, French, Chinese, Delicious, Fancy, Exotic, Classic, Perfect, High-End',
        annual_dol: 507.44,
        description:
            'Les Trois Chevaux is a fine dining restaurant in New York City. Owned and run by Angie Mar, it opened in July 2021 and serves French cuisine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7298,
        store_longitude: -74.0044,
        total_orders: 90.01,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Pizza, Burger, Tasty, Salad, Italian, Beer, Dessert, Wine, Cozy, Relaxed',
        annual_dol: 93.91,
        description:
            'Pizza parlor turning out thin-crust pies with creative toppings in a small, relaxed space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7203,
        store_longitude: -73.9971,
        total_orders: 51.61,
        neighborhood: 'Little Italy',
        keywords:
            'Burger, Pizza, Salad, Vegan, Italian, Gluten Free, Dessert, Wine, Beer, Bruschetta',
        annual_dol: 210.95,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.668,
        store_longitude: -73.5541,
        total_orders: 8.58,
        neighborhood: '',
        keywords: 'Mexican, Margarita, Salad, Nachos, Local, Exotic, Friendly, Great',
        annual_dol: 35.08,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7597,
        store_longitude: -73.9203,
        total_orders: 3173.24,
        neighborhood: 'Astoria/LIC',
        keywords: 'Burger, Beer, Wine, Bbq, Nachos, Delicious, Casual, Smoky, American, Local',
        annual_dol: 3343.12,
        description:
            'This low-key gastropub with old-timey touches offers rotating taps & a menu of elevated bar food.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6742,
        store_longitude: -73.9823,
        total_orders: 130.43,
        neighborhood: 'Park Slope',
        keywords:
            'Tequila, Tacos, Mexican, Spicy, Burritos, Delicious, Warm, Fajitas, American, Fun',
        annual_dol: 213.38,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7474,
        store_longitude: -73.8874,
        total_orders: 63.62,
        neighborhood: 'Jackson Heights/Elmhurst',
        keywords: 'Tasty, Salad, Mexican, Vodka, Karaoke, Fun, Friendly, Latin, Great, Russian',
        annual_dol: 260.07,
        description:
            '"Beautiful new gay bar in Jackson Heights, Queens - where ALL are welcome. Follow us on Instagram for event updates and our weekly party and DJ schedule. Kitchen opening for small bites Spring 2022."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7635,
        store_longitude: -73.9738,
        total_orders: 11.39,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords:
            'Salad, Chilean, Elegant, Beer, Picturesque, Perfect, Casual, American, Great, Amazing',
        annual_dol: 46.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.739,
        store_longitude: -73.9917,
        total_orders: 730.74,
        neighborhood: 'Midtown Manhattan',
        keywords: 'Delicious, Golfclub, Cozy, Urban, Perfect, Fun, Clean, American, Great, Dope',
        annual_dol: 746.24,
        description:
            'Indoor golf center offering lessons, Full Swing simulators, complimentary clubs & a bar with snacks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7396,
        store_longitude: -73.9958,
        total_orders: 1107.64,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords: 'Wine, Italian, Salad, Vodka, Spicy, Warm, Creamy, Impeccable, Romantic, Cozy',
        annual_dol: 1131.14,
        description:
            "Stalwart that's been serving Northern Italian cuisine for over 20 years in a lush, old-school space.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7432,
        store_longitude: -74.0077,
        total_orders: 162.79,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Italian, Pizza, Contemporary, Spacious, Fancy, Seasonal, Casual, Creative, Excellent, Great',
        annual_dol: 223.55,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7421,
        store_longitude: -74.0006,
        total_orders: 3542.97,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Tequila, Mexican, Cornbread, Beer, Dairy Free, Margarita, Nachos, Spicy, Bbq, Smoky',
        annual_dol: 3618.14,
        description:
            'Mexican BBQ menus & cocktails combine at this lively, casual spin-off of a popular food truck.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7396,
        store_longitude: -73.9991,
        total_orders: 63.62,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Vegan, Salad, Healthful, Delicious, Margarita, Spicy, Warm, Argentinian, Healthy',
        annual_dol: 260.05,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7149,
        store_longitude: -73.9618,
        total_orders: 12020.49,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Steak, Sushi, Salad, Wine, Dessert, Japanese, Spicy, Delicious, Coffee, Beer',
        annual_dol: 13061.6,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7253,
        store_longitude: -73.9832,
        total_orders: 86.89,
        neighborhood: 'Lower Manhattan/Alphabet City',
        keywords: 'Burger, Steak, Salad, Korean, Beer, Spicy, American, Trendy, Legendary, Dope',
        annual_dol: 355.17,
        description:
            'Korean-American plates are served in an intimate setting with hip hop influences.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7301,
        store_longitude: -73.9813,
        total_orders: 1053.95,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Beer, Cozy, Relaxed, Trendy, Spacious, Casual, Local, Awesome, Creative, Excellent',
        annual_dol: 1099.64,
        description:
            'Gay & lesbian mainstay in the East Village known for its jukebox & relaxed atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6072,
        store_longitude: -74.161,
        total_orders: 51.77,
        neighborhood: 'Bulls Head/Mid Island',
        keywords:
            'Burger, Salad, Milkshake, Coffee, Tasty, Italian, Quaint, Greek, American, Omelette',
        annual_dol: 57.59,
        description:
            'An array of Greek-American comfort foods are prepared 24/7 at this spacious, time-tested throwback.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6869,
        store_longitude: -73.9632,
        total_orders: 22.74,
        neighborhood: 'Clinton Hill',
        keywords:
            'Wine, Sustainable, Funky, Vibrant, Polished, French, Natural, Contemporary, Fresh, Excellent',
        annual_dol: 92.96,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7121,
        store_longitude: -73.964,
        total_orders: 436.18,
        neighborhood: 'South Williamsburg/Williamsburg',
        keywords: 'Beer, Tequila, Tacos, Burger, Margarita, Bbq, American, Hip, Negroni, Crisp',
        annual_dol: 469.33,
        description:
            'American meals, craft beers & cocktails in a playful interior with graffitied walls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7617,
        store_longitude: -73.9252,
        total_orders: 763.37,
        neighborhood: 'Astoria/LIC',
        keywords: 'Whiskey, Karaoke, Irish, Coffee, Greek, Classic, Authentic, Great, Casual',
        annual_dol: 779.57,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7229,
        store_longitude: -73.9957,
        total_orders: 609.69,
        neighborhood: 'Nolita',
        keywords:
            'Garlic Bread, Salad, Pizza, Chicken Parmigiana, Italian, Gourmet, Meatball, Tasty, Wine, Spicy',
        annual_dol: 622.62,
        description:
            'Chicken parm & other Italian-American classics go gourmet at this hip, diner-inspired space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.76,
        store_longitude: -73.8038,
        total_orders: 155.64,
        neighborhood: 'Flushing/East Flushing',
        keywords: 'Tasty, Irish, Casual',
        annual_dol: 158.95,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7651,
        store_longitude: -73.9752,
        total_orders: 4459.81,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Burger, Tequila, Hotel, Upscale, Shrimp Cocktail, Vodka, Legendary, Margarita, Elegant, Coffee',
        annual_dol: 4573,
        description:
            "Sophisticated, airy rooms have flat-screen TVs, Wi-Fi, pillow-top mattresses and picture windows; some offer panoramic views of the park. Suites add sitting areas, wet bars, dining rooms and/or kitchens.A facilities fee covers Wi-Fi and an evening reception with drinks and snacks, plus use of the gym and the business center. There's also a fine dining restaurant with floor-to-ceiling windows and park views, and a casual eatery, as well as a refined cocktail bar with wood paneling.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7633,
        store_longitude: -73.9893,
        total_orders: 834.79,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Burger, Salad, Delicious, Upscale, Beer, Irish, Fun, Awesome, Perfect, Screwdriver',
        annual_dol: 961.14,
        description:
            'Double-decker, fireplace-equipped Irish pub with an upstairs party room, bar-food menu & happy hour.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7636,
        store_longitude: -73.9778,
        total_orders: 311.25,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Mexican, Tacos, Salad, Organic, Local, Delicious, Casual, Fresh',
        annual_dol: 1272.27,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7152,
        store_longitude: -73.9915,
        total_orders: 999.02,
        neighborhood: 'Lower East Side/Chinatown',
        keywords: 'Wine, Scotch, Salad, Spicy, Warm, Cozy, Organic, Amazing, Great, Fresh',
        annual_dol: 1265.07,
        description:
            'Cozy eatery serving oysters, snacks & drinks in vintage-inspired digs with live music & DJ nights.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8915,
        store_longitude: -73.8977,
        total_orders: 280.57,
        neighborhood: 'Fieldston/West Bronx',
        keywords: 'Mexican, Steak, Tex-Mex, Karaoke, Fajitas, Smoky, Casual, Authentic',
        annual_dol: 411.03,
        description:
            'Festive family-owned cantina with a simple decor with foods of the Southwest & mariachi & karaoke.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.594,
        store_longitude: -73.5799,
        total_orders: 393.71,
        neighborhood: '',
        keywords:
            'Lobster Roll, Lobster, Lobster Tail, Salad, Coffee, Wine, Tasty, Ale, Caesar Salad, American',
        annual_dol: 507.71,
        description:
            'Walls of windows offer waterside views at this nautical-themed outpost serving raw & cooked seafood.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7471,
        store_longitude: -73.8874,
        total_orders: 2.82,
        neighborhood: 'Jackson Heights/Flushing/Elmhurst',
        keywords: 'Peruvian, Salad, Dessert, Tangy, French, Japanese, Casual',
        annual_dol: 11.51,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8677,
        store_longitude: -73.9209,
        total_orders: 1333.9,
        neighborhood: 'Little Dominican Republic/Inwood',
        keywords:
            'Burger, Hamburger, Beer, Wine, Delicious, Upscale, American, Friendly, Fun, Classic',
        annual_dol: 1399.7,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7383,
        store_longitude: -73.9927,
        total_orders: 2384.03,
        neighborhood: 'Midtown Manhattan',
        keywords:
            'Beer, Delicious, Cozy, Mediterranean, Homemade, Classy, Local, Baba Ghanoush, Great, Casual',
        annual_dol: 2493.31,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7362,
        store_longitude: -74.0007,
        total_orders: 678.43,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Wine, Vodka, Bourbon, Gin, Romantic, Upscale, Exotic, Enticing, Traditional, Sleek',
        annual_dol: 753.23,
        description:
            'Regional Indian fare is highlighted at this upscale spot with cocktails & modern murals.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7436,
        store_longitude: -74.0032,
        total_orders: 3751.21,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Coffee, Gin, Martini, Salad, Pinacolada, Lively, Sophisticated, Chic, Classic, Artisanal',
        annual_dol: 5224.46,
        description:
            'Small plates are paired with gin-based cocktails at this speakeasy-style bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7405,
        store_longitude: -73.998,
        total_orders: 3235.28,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Steak, Burger, Cheesesteak, Salad, Tasty, Wine, Gluten Free, Coffee, Dessert, Beer',
        annual_dol: 6855.87,
        description:
            'A swanky take on a diner attracting people-watchers & post-clubbers late nights.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7356,
        store_longitude: -74.0004,
        total_orders: 969.62,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Delicious, Beer, Italian, Cozy, Fun, Friendly, Wonderful, Cool, Great',
        annual_dol: 995.72,
        description:
            'This second-floor bar offers video games & trivia contests plus old movies on a big screen & more.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5414,
        store_longitude: -74.1782,
        total_orders: 106.18,
        neighborhood: 'Woodrow',
        keywords: 'Sushi, Burger, Tacos, Salad, Pizza, Dessert, Italian, Wine, Elegant, Friendly',
        annual_dol: 234.82,
        description:
            'Elegant Italian restaurant serving wine & upmarket cuisine like veal, filet mignon & pasta entrees.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7142,
        store_longitude: -73.9602,
        total_orders: 350.24,
        neighborhood: 'Williamsburg',
        keywords:
            'Sushi, Breakfast Burrito, Japanese, Bbq, Mediterranean, Ramen, Rustic, Asian, Funky, Casual',
        annual_dol: 408.25,
        description:
            'This rustic Japanese restaurant with a communal table serves udon, sushi, miso & beer.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7102,
        store_longitude: -73.9687,
        total_orders: 1131.14,
        neighborhood: 'Southside/Williamsburg',
        keywords: 'Lobster, Steak, Salad, Coffee, Italian, Vodka, Bbq, French, Thai, American',
        annual_dol: 1911.29,
        description:
            'Relaxed waterside restaurant specializing in oysters, frozen cocktails & locally sourced pub grub.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7425,
        store_longitude: -73.9844,
        total_orders: 1325.42,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Lobster Roll, Lobster, Burger, Tomato Soup, Salad, Californian, Margarita, Wine, Beer, Fruity',
        annual_dol: 1450.91,
        description:
            'Posh chain serving American fare, including brunch, dinner & creative cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7422,
        store_longitude: -73.9846,
        total_orders: 3339.19,
        neighborhood: 'Midtown Manhattan/Rose Hill',
        keywords:
            'Tequila, Mexican, Margarita, Upscale, Gluten Free, Delicious, Hotel, Innovative, Vibrant, Premium',
        annual_dol: 3582.07,
        description:
            'Vibrant cantina serving modern Mexican cuisine, made-to-order guacamole & tequila cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7403,
        store_longitude: -73.9736,
        total_orders: 1151.17,
        neighborhood: 'Midtown Manhattan/Kips Bay',
        keywords:
            'Steak, Upscale, Canadian, Elegant, Delicious, Swiss, Crisp, Dessert, Hotel, American',
        annual_dol: 1234.99,
        description:
            'Upscale floating eatery serving American classics in elegant surroundings with East River views.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.723,
        store_longitude: -73.9506,
        total_orders: 2973.99,
        neighborhood: 'Greenpoint',
        keywords:
            'Beer, Delicious, Cozy, Friendly, Intimate, Eclectic, Quirky, Great, Traditional, Casual',
        annual_dol: 3040.41,
        description:
            'Cozy tavern with refurbished wood decor, featuring craft brews, specialty cocktails & charcuterie.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7645,
        store_longitude: -73.9701,
        total_orders: 1172.28,
        neighborhood: 'Lenox Hill',
        keywords:
            'Salad, Italian, Mexican, Mediterranean, Luxurious, Brazilian, Chic, Sumptuous, Rooftop, Casual',
        annual_dol: 5072.64,
        description:
            '"Casa Cruz New York is located in a stunning beaux-arts townhouse on 61st Street, between Park and Madison Avenues. Comprised of five floors, the space is inclusive of bars and dining rooms, special event spaces, and a rooftop terrace and will accommodate breakfast, lunch, dinner, and cocktails. Casa Cruz New York will display an unwavering attention to detail and unparalleled design, offering the high-level cuisine, cocktails and hospitality for which Santa Cruz Co. restaurants and bars have become known."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7489,
        store_longitude: -73.9754,
        total_orders: 202.64,
        neighborhood: 'Midtown Manhattan/Murray Hill',
        keywords:
            'Steak, Gluten Free, Beer, Spicy, Wine, Chili Chicken, Japanese, Thai, Asian, Ramen',
        annual_dol: 261.31,
        description:
            'Asian-inspired & Japanese chain restaurant where dishes are whisked to long communal tables.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7698,
        store_longitude: -73.9578,
        total_orders: 82.48,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'Salad, Kebab, Dessert, Wine, Elegant, Warm, Homemade, Mediterranean, Modern, Sweet',
        annual_dol: 91.97,
        description:
            'Understated restaurant with a warm atmosphere offering a traditional Persian menu & full bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7534,
        store_longitude: -73.9325,
        total_orders: 108.3,
        neighborhood: 'Astoria/LIC/Dutch Kills',
        keywords: 'Delicious, Cozy, Bbq, Ecuadorian, Fun, Thai, Friendly, Dutch, Amazing, Latin',
        annual_dol: 362.45,
        description:
            'Casual lounge bar, night club & restaurant offering brunch & dinner menus, plus cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7317,
        store_longitude: -73.9899,
        total_orders: 4089.2,
        neighborhood: 'East Village/Lower Manhattan',
        keywords: 'Upscale, Classy, Fun, Amazing, Great, Excellent, Casual',
        annual_dol: 4089.2,
        description:
            '25 billiards tables plus Ping-Pong, darts, foosball, full bar & food near Union Square.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7312,
        store_longitude: -73.9856,
        total_orders: 746.46,
        neighborhood: 'East Village/Ukrainian Village',
        keywords: 'Beer, Delicious, Cozy, Tangy, Fun, Friendly, Bourbon, Irish, Bbq, Lively',
        annual_dol: 935.84,
        description:
            'Lively, Southern-flavored joint with signature cocktails, craft beers & a live band on Sundays.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7332,
        store_longitude: -73.9914,
        total_orders: 576.85,
        neighborhood: 'Greenwich Village/Lower Manhattan',
        keywords:
            'Pizza, Vegetarian, Vegan, Italian, Gluten Free, Wine, Mediterranean, Gin, Campari, Dope',
        annual_dol: 643.25,
        description:
            'Old-world Neapolitan pizza & Italian appetizers in a lively, high-ceilinged space with soccer on TV.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8318,
        store_longitude: -73.8661,
        total_orders: 70.57,
        neighborhood: 'East Bronx/Soundview',
        keywords:
            'Steak, Lobster, Lobster Tail, Salad, Shrimp Cocktail, Shrimp Scampi, Karaoke, Spanish, Thai, Puerto Rican',
        annual_dol: 76.91,
        description:
            'Unfussy eatery serving Puerto Rican fare & cocktails such as coquito in a warm atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7385,
        store_longitude: -73.9896,
        total_orders: 125.02,
        neighborhood: 'Midtown Manhattan',
        keywords: 'American, Ale, Contemporary, Local, Casual',
        annual_dol: 511.04,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7588,
        store_longitude: -73.9814,
        total_orders: 26173.14,
        neighborhood: 'Midtown West/Midtown Manhattan',
        keywords: 'Steak, Rich, Wine, Upscale, Luxurious, Salad, Crisp, Premium, Perfect, Classic',
        annual_dol: 26728.49,
        description:
            'Opulently outfitted chain restaurant specializing in prime steaks & seafood along with fine wines.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7492,
        store_longitude: -73.9705,
        total_orders: 253.22,
        neighborhood: 'Midtown Manhattan/Tudor City/Midtown East',
        keywords:
            'Steak, Lasagna, Lobster, Cheesecake, Lobster Bisque, Tasty, Dessert, Chilean, Beer, French',
        annual_dol: 654.79,
        description:
            'Traditional steakhouse dishes, sides & cocktails in an elevated dining room with ample foliage.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7516,
        store_longitude: -73.9727,
        total_orders: 508.39,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Sushi, Salad, Wine, Beer, Spicy, Japanese, Casual, Teriyaki, Perfect, Authentic',
        annual_dol: 638.48,
        description:
            'Comfy Japanese spot with a simple dining room, sushi bar, sake & brown spirits, plus tatami alcoves.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8257,
        store_longitude: -73.9536,
        total_orders: 8187.83,
        neighborhood: 'Hamilton Heights/West Harlem',
        keywords:
            'Steak, Pizza, Beer, Margarita, Cuban, Wine, Spanish, Delicious, Puerto Rican, Dominican',
        annual_dol: 13427.23,
        description:
            'Traditional Puerto Rican fare artfully presented in a lively, modern setting with happy hour & DJs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7593,
        store_longitude: -73.9709,
        total_orders: 2705.05,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Steak, Burger, Salad, Sushi, Wine, Spicy, Delicious, Beer, Shrimp Scampi, Elegant',
        annual_dol: 2799.8,
        description:
            'Part of a small chain of chophouses serving steak, seafood & other classic fare in a clubby setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.75,
        store_longitude: -73.9857,
        total_orders: 920.69,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords:
            'Salad, Vegan, Japanese, Spicy, Ramen, Vintage, Rum, Southern, Casual, Adventurous',
        annual_dol: 3763.49,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7539,
        store_longitude: -73.9999,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Chelsea',
        keywords:
            'Burger, Delicious, Dessert, Healthy, Fresh, Casual, Classic, Local, Rooftop, Signature',
        annual_dol: 0,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6262,
        store_longitude: -74.1763,
        total_orders: 21527.65,
        neighborhood: '',
        keywords:
            'Whiskey, Salad, Bourbon, Burger, Beer, Meatball, French Onion Soup, Fried Chicken, French, Mojito',
        annual_dol: 88117.27,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.744,
        store_longitude: -73.9892,
        total_orders: 3122.59,
        neighborhood: 'Midtown Manhattan/NoMad',
        keywords:
            'Wine, Italian, Stylish, Coffee, Delicious, Hotel, Relaxed, Bright, Vibrant, Modern',
        annual_dol: 3188.85,
        description:
            'Stylish, bright eatery featuring market-driven Italian cuisine, regional wines & apéritifs.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7587,
        store_longitude: -73.9877,
        total_orders: 30032.95,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'American, Hotel, Fabulous, Perfect, Great',
        annual_dol: 30701.5,
        description:
            'The John Golden Theatre, formerly the Theatre Masque and Masque Theater, is a Broadway theater at 252 West 45th Street in the Theater District of Midtown Manhattan in New York City.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7583,
        store_longitude: -73.9898,
        total_orders: 1781.7,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Sushi, Vegetarian, Spicy, Rich, Chinese, Thai, Exquisite, Asian, Sweet, Premium',
        annual_dol: 6712.87,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7384,
        store_longitude: -73.9857,
        total_orders: 617.12,
        neighborhood: 'Midtown Manhattan/Gramercy Park',
        keywords:
            'Wine, Italian, Elegant, Hotel, Rustic, Warm, Classic, Superb, Welcoming, Perfect',
        annual_dol: 2522.6,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7258,
        store_longitude: -74.0014,
        total_orders: 721.09,
        neighborhood: 'South Village/SoHo',
        keywords:
            'Burger, Hamburger, Pizza, Salad, Italian, Wine, Cozy, Modern, Casual, Traditional',
        annual_dol: 737.89,
        description:
            'Tuscan spot offering quick-serve cafe fare by day & trattoria dishes with wine at night.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6814,
        store_longitude: -73.9939,
        total_orders: 3960.54,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Tequila, Mexican, Margarita, Salad, Delicious, Relaxed, Friendly, Latin, American, Authentic',
        annual_dol: 4366.41,
        description:
            'Tequila-based craft cocktails meet Mexican tapas at this rustic-chic bar with a back patio.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6808,
        store_longitude: -73.9969,
        total_orders: 173.29,
        neighborhood: 'Carroll Gardens',
        keywords:
            'Italian, Wine, Martini, Bruschetta, Homemade, Creamy, Romantic, Classy, Hospitable, Exotic',
        annual_dol: 539.98,
        description:
            'Fresh Italian food dished in a brick-walled room anchored by an L-shaped wood bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7526,
        store_longitude: -73.9242,
        total_orders: 177.09,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tequila, Mexican, Upscale, Dessert, Intimate, Classy, Traditional, Contemporary, Excellent, Casual',
        annual_dol: 341.25,
        description:
            '"We\'re a contemporary Mexican restaurant & Bar located in Long Island City, recently opened our doors for Mexican food lovers that want to have a very special experience. We serve traditional recipes mixed with contemporary flavors and have a huge variety of different types of tequilas and cocktails. Come see us today!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7459,
        store_longitude: -73.89,
        total_orders: 24.66,
        neighborhood: 'Elmhurst/Flushing',
        keywords: 'Fun, Turkish, Great, Modern, Casual',
        annual_dol: 100.82,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7547,
        store_longitude: -73.9705,
        total_orders: 533.28,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Salad, Wine, Flavorful, Italian, Tasty, Delicious, Cozy, Rustic, Bellini, Romantic',
        annual_dol: 621.61,
        description:
            'Northern Italian specialties highlighted in a rustic-chic restaurant offering terrace dining.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8034,
        store_longitude: -73.6461,
        total_orders: 372.86,
        neighborhood: '',
        keywords:
            'Kebab, Salad, Steak, Mexican, Beer, Coffee, Dessert, Japanese, Turkish, Mediterranean',
        annual_dol: 964.24,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6264,
        store_longitude: -74.0241,
        total_orders: 1261.1,
        neighborhood: 'Bay Ridge',
        keywords:
            'Burger, Steak, Salad, Shrimp Cocktail, Coffee, Wine, French Onion Soup, French, Beer, Bbq',
        annual_dol: 1287.85,
        description:
            'Longtime restaurant & bar offering a menu of burgers & other American eats, plus outdoor seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7609,
        store_longitude: -73.9727,
        total_orders: 968.9,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Scandinavian, Nordic, Salad, Wine, Dessert, Danish, Meatball, French, Seasonal, Premium',
        annual_dol: 1010.91,
        description:
            'High-end Scandinavian tasting & fixed-price menus served in a minimalist Nordic setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7547,
        store_longitude: -73.9665,
        total_orders: 417.47,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords: 'Steak, Wine, Burger, Salad, Stylish, Cozy, French, Beer, Romantic, Classy',
        annual_dol: 426.33,
        description:
            'Parisian-style bistro with outdoor tables dishing up French classics, from coq au vin to couscous.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6715,
        store_longitude: -73.9502,
        total_orders: 2870.78,
        neighborhood: 'Crown Heights',
        keywords: 'Burger, Pizza, Salad, Wine, Italian, Beer, Margarita, Painkiller, Spicy, Cozy',
        annual_dol: 2870.78,
        description:
            'Caribbean-inspired cocktails like Mai Tais, plus beer & wine, served in a retro, low-key space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7555,
        store_longitude: -73.987,
        total_orders: 1178.4,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords: 'Burger, Hamburger, Vegetarian, Vegan, Salad, Gourmet, Beer, Bbq, Danish, Wine',
        annual_dol: 1203.41,
        description:
            'Contemporary chain with customizable gourmet burgers, including vegetarian, plus beer & wine.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7564,
        store_longitude: -73.9854,
        total_orders: 3337.22,
        neighborhood: 'Theater District/Midtown South/Midtown Manhattan',
        keywords:
            'Lobster, Burger, Salad, Chicken Parmigiana, Gluten Free, Italian, Dessert, Shrimp Scampi, Meatball, Delicious',
        annual_dol: 3414.77,
        description:
            'Bustling eatery serving large, shareable portions of Italian comfort food in a lively setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6914,
        store_longitude: -73.9979,
        total_orders: 329.54,
        neighborhood: 'Brooklyn Heights',
        keywords: 'Karaoke, Beer, Wine, American, Great, Casual',
        annual_dol: 336.53,
        description:
            'Longtime watering hole with a weathered bar & pool table in a nautical-themed setting near Pier 6.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7027,
        store_longitude: -73.9931,
        total_orders: 68.32,
        neighborhood: 'Fulton Ferry District/Brooklyn Heights',
        keywords:
            'Tequila, Mexican, Beer, Margarita, Tasty, Spicy, Rustic, Seasonal, Classy, Laid-Back',
        annual_dol: 301.96,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7806,
        store_longitude: -73.9529,
        total_orders: 1073.82,
        neighborhood: 'UES/Carnegie Hill',
        keywords:
            'Burger, Steak, Salad, Chicken Parmigiana, Italian, Gluten Free, Meatball, Tasty, Shrimp Scampi, Savory',
        annual_dol: 1096.6,
        description:
            'Casual Italian fare with cocktails & wine in a contemporary, rustic setting with patterned tiles.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7551,
        store_longitude: -73.9811,
        total_orders: 839.32,
        neighborhood: 'Midtown West/Midtown South/Midtown Manhattan',
        keywords: 'Wine, Upscale, Beer, Tasty, Hotel, Gourmet, Delicious, Opulent, American, Irish',
        annual_dol: 3746.16,
        description:
            '"Irish American Bar serving good quality tasty dishes. Only steps from Bryant Park and Time Square. Happy Hour daily from 11am-7pm. Great cocktails and great service."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7633,
        store_longitude: -73.993,
        total_orders: 1414.05,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords: 'Champagne, Hurricane, Beer, Burger, Wine, Margarita, Mimosa, Fun, Great, Clean',
        annual_dol: 1612.97,
        description:
            'Hardware store turned buzzy nightclub with a large dance floor & DJs plus nightly live performances.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7746,
        store_longitude: -73.9798,
        total_orders: 1084.33,
        neighborhood: 'Lincoln Square/UWS',
        keywords:
            'Burger, Salad, Hamburger, Dessert, French Toast, French, Delicious, English Muffin, Quaint, Homemade',
        annual_dol: 1107.34,
        description:
            'A neighborhood veteran serving classic French bistro fare in a rustic, small space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7788,
        store_longitude: -73.9784,
        total_orders: 356.4,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Wine, Dessert, Turkish, Rustic, Mediterranean, Cozy, Casual, Perfect, American',
        annual_dol: 380.09,
        description:
            'Cozy Turkish eatery serving wood-fired flat breads, seafood & meat plates, plus wine & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7213,
        store_longitude: -73.9942,
        total_orders: 2130.66,
        neighborhood: 'Nolita',
        keywords:
            'Lemon Drop, Tequila, Wine, Vodka, Tasty, Margarita, Beer, Martini, Gin, Delicious',
        annual_dol: 2223.04,
        description: 'A low-key spot for beer & drinks with benches & outdoor tables.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.724,
        store_longitude: -73.7263,
        total_orders: 15.65,
        neighborhood: 'Bellerose Manor',
        keywords:
            'Vegetarian, Kebab, Chinese, Juicy, Opulent, Sweet, Tandoori Chicken, Indian, Amazing, Tandoori Lamb',
        annual_dol: 63.99,
        description:
            'Indian specialties along with Chinese dishes served in an expansive space with opulent decor.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7251,
        store_longitude: -73.6344,
        total_orders: 1814.4,
        neighborhood: '',
        keywords:
            'Burger, Lobster, Lobster Roll, Steak, Salad, Gluten Free, Coffee, Beer, Italian, Delicious',
        annual_dol: 1933.87,
        description:
            'Old-school bar & grill (circa 1942) with dark wood interior for homey American classics & drinks.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7369,
        store_longitude: -74.0025,
        total_orders: 2494.03,
        neighborhood: 'Greenwich Village/West Village',
        keywords:
            'Burger, Chicken Pot Pie, Wine, Bbq, Delicious, American, Quaint, Cozy, Premium, Timeless',
        annual_dol: 2494.03,
        description:
            'High-end renditions of American comfort fare are served with cocktails in a clubby space.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7829,
        store_longitude: -73.9802,
        total_orders: 718.99,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Salad, Bbq, American, Perfect, Exquisite, Casual, Local, Great, Delectable, Diverse',
        annual_dol: 2518.17,
        description:
            'Modest comedy club presenting young & seasoned performers plus occasional big names.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7462,
        store_longitude: -73.8961,
        total_orders: 101.7,
        neighborhood: 'Flushing/Woodside',
        keywords: 'Beer, South American, American, Premium, Fantastic, Great, Casual',
        annual_dol: 131.37,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7495,
        store_longitude: -73.7078,
        total_orders: 2125.77,
        neighborhood: 'Glen Oaks',
        keywords: 'Italian, Pizza, Salad, Wine, Beer, Rustic, Mediterranean, Cozy, Warm, Homemade',
        annual_dol: 2196.75,
        description:
            'Neighborhood Italian restaurant serving up housemade pastas & wood-fired pizzas in a cozy setting.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7582,
        store_longitude: -73.5881,
        total_orders: 62.03,
        neighborhood: '',
        keywords: 'Relaxed, Friendly, Casual, Local, Great',
        annual_dol: 64.72,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7703,
        store_longitude: -73.9185,
        total_orders: 183.23,
        neighborhood: 'Astoria',
        keywords:
            'Beer, Salad, Greek, Spacious, Mediterranean, Premium, Fresh, Cool, Greek Salad, Authentic',
        annual_dol: 320.86,
        description:
            'Family-owned Greek place serving traditional fare in an unpretentious space with a bar & live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6845,
        store_longitude: -73.6152,
        total_orders: 4.78,
        neighborhood: '',
        keywords:
            'Salad, Chicken Caesar Salad, Jamaican, Caribbean, Beer, Spicy, Caesar Salad, Exotic, Spacious, Casual',
        annual_dol: 19.54,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7734,
        store_longitude: -73.9167,
        total_orders: 61.3,
        neighborhood: 'Astoria/Ditmars Steinway',
        keywords:
            'Pizza, Salad, Dessert, Coffee, Italian, Margarita, Cozy, Crisp, Premium, Perfect',
        annual_dol: 187.81,
        description:
            '"Only in Astoria can you bring together the best of Italian-pizza making, the best American beer, the best wines from all over the world, exceptional service with a friendly face and attitude, an affordable check, and the quickest service possible! Pizza and drinks, family and friends, and a space where all are welcome. We find joy in bringing the best things together. We are diverse team situated in a diverse neighborhood and our mission is to bring together people from the neighborhood, the greater city, and out of town guests. Food, drinks, and fun unite us. Macoletta is a family-friendly place: we love kids and will be offering pizza classes on weekend mornings."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7241,
        store_longitude: -73.9504,
        total_orders: 2224.05,
        neighborhood: 'Greenpoint',
        keywords:
            'Burger, Beer, Wine, Delicious, Ale, Cozy, American, Perfect, Exceptional, Classic',
        annual_dol: 2277.52,
        description:
            'This cozy bar offers flat screen TVs for sports fans, plus classic & creative American menu options.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.734,
        store_longitude: -73.9551,
        total_orders: 2130.98,
        neighborhood: 'Greenpoint',
        keywords: 'Salad, Vodka, Tequila, Gin, Chinese, Rum, Polish, Classy, Casual, Fun',
        annual_dol: 2190.09,
        description:
            'Compact restaurant serving Taiwanese cuisine & cocktails amid an easygoing atmosphere.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7594,
        store_longitude: -73.9195,
        total_orders: 547.87,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Margarita, Salad, Mexican, Steak, Nachos, Tasty, Fajitas, Enchiladas, Ale',
        annual_dol: 571.63,
        description:
            'Low-lit Mexican spot offering a variety of margaritas & a menu of tacos, enchiladas & the like.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6789,
        store_longitude: -73.9872,
        total_orders: 3700.68,
        neighborhood: 'Gowanus',
        keywords:
            'Delicious, Fun, Playful, Fantastic, Great, Excellent, Smooth, Ethiopian, Floridian, Retro',
        annual_dol: 4325.43,
        description:
            'Shuffleboard courts draw playful crowds to this big bar with food trucks & occasional live music.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7052,
        store_longitude: -74.0162,
        total_orders: 988.9,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Hotel, Luxurious, Martini, Stylish, American, Spicy, Classic, Modern, Innovative, Contemporary',
        annual_dol: 3725.86,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7042,
        store_longitude: -74.0119,
        total_orders: 1223.42,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords: 'Burger, Salad, Beer, Wine, Irish, Warm, Fun, Welcoming, Friendly, Vibrant',
        annual_dol: 1308.52,
        description:
            'A classic Irish standby with dark wood, outdoor seating & a menu of beer & pub grub.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.724,
        store_longitude: -74.0098,
        total_orders: 335.48,
        neighborhood: 'Lower Manhattan/Tribeca',
        keywords:
            'Vegetarian, Chinese, Upscale, Hawaiian, Asian, Savory, Elegant, Turkish, Vintage, Mediterranean',
        annual_dol: 1371.33,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7039,
        store_longitude: -74.0129,
        total_orders: 1195.81,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Pizza, Wine, Beer, Salad, Irish, Juicy, Japanese, French Onion Soup, American, French',
        annual_dol: 3265.43,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7185,
        store_longitude: -73.9975,
        total_orders: 248.37,
        neighborhood: 'Little Italy',
        keywords:
            'Vegetarian, Italian, Shrimp Cocktail, Wine, Delicious, Spicy, Organic, Homemade, Mediterranean, Bruschetta',
        annual_dol: 290.07,
        description:
            'Traditional Italian dishes & desserts in a relaxed, white-tablecloth setting with sidewalk seating.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7211,
        store_longitude: -73.9979,
        total_orders: 848.06,
        neighborhood: 'Nolita',
        keywords:
            'Vegan, Italian, Upscale, Organic, Rich, Mediterranean, Spacious, Casual, Greek, Fresh',
        annual_dol: 3547.44,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7767,
        store_longitude: -73.9528,
        total_orders: 73.84,
        neighborhood: 'UES/Central Park West Historic District/Yorkville',
        keywords: 'Vegan, Salad, Pizza, Tasty, French Toast, Beer, French, Swiss, Modern, American',
        annual_dol: 82.28,
        description:
            '"Green Kitchen has proudly served the great city of New York since 1931 by providing breakfast, lunch and dinner in a casual family atmosphere. We strive to provide each one of our patrons with the utmost courtesy and consideration in order to maximize the dining experience. As part of our commitment to quality, we prepare our cuisine fresh each day using only the finest ingredients from carefully selected vendors. Our staff is always dedicated to taking exceptional care of you, our guests. We look forward to serving you soon!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.754,
        store_longitude: -73.9769,
        total_orders: 1913.83,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Lasagna, Pizza, Chicken Parmigiana, Italian, Wine, Beer, Spicy, French, Urban, Rooftop',
        annual_dol: 7336.89,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7809,
        store_longitude: -73.9585,
        total_orders: 60.24,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Italian, Salad, Burger, Pizza, Wine, Dessert, Upscale, Whiskey, Rustic, Hotel',
        annual_dol: 246.23,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7013,
        store_longitude: -73.9147,
        total_orders: 11787.77,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Vodka, Rum, Delicious, Hotel, Friendly, Vintage, Eclectic, Clean, Cool',
        annual_dol: 13425.46,
        description:
            'Chic cocktail bar with a vintage vibe & heated patio offering a pub menu & weekend brunch.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6946,
        store_longitude: -73.9308,
        total_orders: 97.12,
        neighborhood: 'Bushwick',
        keywords: 'Pizza, Italian, Gourmet, Wine, Beer, Dessert, Martini, American, Cozy, Premium',
        annual_dol: 453.38,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7653,
        store_longitude: -73.9694,
        total_orders: 281.36,
        neighborhood: 'Lenox Hill/Central Park West Historic District',
        keywords:
            'French, Elegant, Hotel, Comfortable, Modern, Mediterranean, Italian, Casual, Fine, American',
        annual_dol: 662,
        description:
            '"Please know that your reservation is for outdoor dining only. We Are Open for Outdoor Dining With Both Heaters and Plexiglass Dividers Between Each Table for Your Safety! Pick-Up and Delivery Available. Make Your Lunch or Dinner Reservation Now!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6971,
        store_longitude: -73.9352,
        total_orders: 457.59,
        neighborhood: 'Bushwick',
        keywords: 'Beer, Spacious, Cozy, Friendly, Great, Classic, Casual, Local',
        annual_dol: 700.24,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8537,
        store_longitude: -73.7905,
        total_orders: 635.88,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Steak, Lobster Tail, Seafood Paella, Delicious, Italian, Upscale, Polished, Spanish, Latin',
        annual_dol: 952.17,
        description:
            'Waterside eatery & lounge with outdoor seating & traditional Latin American recipes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7105,
        store_longitude: -74.0164,
        total_orders: 41.9,
        neighborhood: 'Battery Park City/Lower Manhattan',
        keywords: 'Mexican, Tacos, Salad, Local, Casual',
        annual_dol: 171.28,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.666,
        store_longitude: -73.806,
        total_orders: 386.71,
        neighborhood: 'South Ozone Park/Jamaica',
        keywords: 'American, Hotel, Premium, Contemporary',
        annual_dol: 396.86,
        description:
            "All rooms offer contemporary decor and custom-designed beds, plus flat-screen TVs with premium cable channels, microwaves, minifridges and free WiFi.A cooked-to-order breakfast is available (fee), and there's an on-site restaurant serving American cuisine, a bar and a 24/7 convenience store. There's also a 24/7 fitness center. Additional amenities include 1,200 sq ft of meeting rooms and a business center, plus a free airport shuttle.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.6414,
        store_longitude: -73.7927,
        total_orders: 12259.81,
        neighborhood: 'Jamaica',
        keywords: 'Glamorous, Hotel, Tasty, Dessert, Delicious, Coffee, Salad, Fun, Sleek, Vintage',
        annual_dol: 13332.1,
        description:
            "Manor Museum and 12 miles from Rockaway Beach.Featuring soundproofed floor-to-ceiling windows, the stylish, retro rooms come with complimentary Wi-Fi and flat-screen TVs, plus minifridges, safes and fully stocked cocktail bars. Some have airport views.There's a sleek 1960s-inspired bar, a food hall with grab-and-go options, and a celebrity chef-helmed restaurant/cafe. Amenities include a 10,000-sq-ft gym, meeting space, and an outdoor pool with runway views.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7628,
        store_longitude: -73.9161,
        total_orders: 313.86,
        neighborhood: 'Astoria/LIC',
        keywords:
            'Tacos, Lobster, Tequila, Lobster Tail, Mexican, Beer, Margarita, Wine, Upscale, Trendy',
        annual_dol: 1329.74,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7277,
        store_longitude: -73.9935,
        total_orders: 1269.86,
        neighborhood: 'NoHo',
        keywords:
            'Steak, Salad, Shrimp Cocktail, Wine, Coffee, Dessert, Organic, French, Danish, Delicious',
        annual_dol: 1296.8,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7297,
        store_longitude: -73.9896,
        total_orders: 9182.37,
        neighborhood: 'East Village/Ukrainian Village',
        keywords:
            'Dessert, Savory, Delicious, Sweet, Beer, Japanese, Irish, Vibrant, Classic, Casual',
        annual_dol: 28236.34,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.764,
        store_longitude: -73.7714,
        total_orders: 12548.98,
        neighborhood: 'Bayside/Flushing',
        keywords:
            'Burger, Lobster, Cajun, Salad, Lobster Bisque, Jambalaya, Gluten Free, Italian, Bbq, Spicy',
        annual_dol: 12979.99,
        description:
            'Cajun & Southern-accented grub, plus Sunday brunch served in a lively spot with TVs & a rooftop bar.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7562,
        store_longitude: -73.975,
        total_orders: 1518.49,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Coffee, Elegant, Hotel, Warm, French, Brazilian, Sophisticated, Intimate, Contemporary',
        annual_dol: 6652.51,
        description:
            '"Fasano Restaurant is a fine dining Italian restaurant in NYC. Located in the Theater District, in the heart of Midtown, the restaurant offers four distinct and elegantly decorated dining rooms. Rooted in traditional Italian gastronomy with contemporary flare, the restaurant is composed of Osteria, the front-of-house cocktail bar with a casual feel, a sophisticated Main Dining Salon, a reserved Private Dining Room, and Baretto – an intimate live music space with a jazz club ambience. Fasano welcomes private events, meetings and celebrations for up to 120 guests."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7074,
        store_longitude: -74.0134,
        total_orders: 629.07,
        neighborhood: 'Financial District/Lower Manhattan',
        keywords:
            'Steak, Burger, Hamburger, Cheesesteak, Tasty, Beer, American, Philly Cheesesteak, Comfortable, Fantastic',
        annual_dol: 656.34,
        description:
            'Easygoing brick-lined pub serving cheesesteaks, wings & other pub fare, plus beer & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.5941,
        store_longitude: -73.9978,
        total_orders: 189.58,
        neighborhood: '',
        keywords:
            'Lasagna, Burger, Tacos, Pizza, Sushi, Salad, Chicken Parmigiana, Meatball, Italian, Gluten Free',
        annual_dol: 201.75,
        description:
            'Lively, family-friendly chain featuring Italian standards such as pastas & salads.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7607,
        store_longitude: -73.9748,
        total_orders: 154.07,
        neighborhood: 'Midtown Manhattan/Midtown East',
        keywords:
            'Italian, Trendy, Luxurious, Mediterranean, Seasonal, Homemade, Asian, Healthy, Polished, Exquisite',
        annual_dol: 204.14,
        description:
            "A Midtown power-lunch scene that's fueled by high-end Italian dining & expense accounts.",
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7596,
        store_longitude: -73.9859,
        total_orders: 5465.58,
        neighborhood: 'Theater District/Midtown Manhattan',
        keywords:
            'Luxurious, Elegant, Trendy, Hotel, Crisp, Superb, Perfect, Great, Fresh, Classic',
        annual_dol: 5606.14,
        description:
            '"The Edison Ballroom is an elegant private event space in the heart of Times Square, New York City. Originally opened in the 1930’s as the Grand Ballroom of the adjacent Edison Hotel, this historical venue reopened in 2008 after a multi-million dollar renovation that preserved its original flair and art deco style. This expansive, multi-level venue is perfect for hosting any type of private event – weddings, Bar and Bat Mitzvahs, holiday parties, nonprofit events, galas, business conferences, red carpet fashion shows and more. For every event The Edison Ballroom provides its superb in-house catering prepared by celebrity chef Mina Newman."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7821,
        store_longitude: -73.9534,
        total_orders: 804.42,
        neighborhood: 'UES/Carnegie Hill',
        keywords: 'Vegetarian, Salad, Pizza, Italian, Wine, Upscale, Beer, Hotel, Warm, Classy',
        annual_dol: 821.49,
        description:
            'Authentic Italian fare draws crowds to this sophisticated Upper East Side eatery.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7606,
        store_longitude: -73.9912,
        total_orders: 500.45,
        neighborhood: "Hell's Kitchen/Midtown Manhattan/Midtown West",
        keywords:
            'Gluten Free, Coffee, Vietnamese, Chili Chicken, Thai, Spicy, Vodka, Fried Chicken, Rich, Bbq',
        annual_dol: 511.07,
        description:
            'Sleek destination turning out Asian fusion BBQ & small plates with creative cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7565,
        store_longitude: -73.9806,
        total_orders: 0,
        neighborhood: 'Midtown Manhattan/Little Brazil',
        keywords: 'Hotel, Italian, Upscale, Great, Perfect',
        annual_dol: 96.65,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7305,
        store_longitude: -74.0006,
        total_orders: 12256.09,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Wine, Vodka, Coffee, Upscale, Wholesome, Irish, Classy, Homemade, Great, Casual',
        annual_dol: 12529.52,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7273,
        store_longitude: -73.994,
        total_orders: 1981.33,
        neighborhood: 'NoHo',
        keywords: 'Steak, Burger, Salad, Wine, Italian, Beer, Upscale, Scandinavian, Spicy, French',
        annual_dol: 2088.05,
        description:
            'Restaurant & lounge featuring upscale French & Italian bistro fare, plus wine & cocktails.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7279,
        store_longitude: -74.0014,
        total_orders: 107.52,
        neighborhood: 'Greenwich Village/West Village',
        keywords: 'Coffee, Beer, Wine, Scotch, Jamaican, Salad, Cuban, Caribbean, Rum, Trendy',
        annual_dol: 175.7,
        description:
            'Diner featuring retro decor, cocktails & classic Caribbean fare such as jerk chicken & curried goat.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7478,
        store_longitude: -73.9905,
        total_orders: 1787.6,
        neighborhood: 'Midtown Manhattan/Midtown South/Chelsea',
        keywords: 'Steak, Burger, Whiskey, Beer, American, Hotel, Vibrant, Awesome, Perfect, Great',
        annual_dol: 1828.68,
        description:
            '"Neighborhood cocktail bar, part time sports bar serving brunch, lunch and dinner!"',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7475,
        store_longitude: -73.9866,
        total_orders: 1205.62,
        neighborhood: 'Midtown Manhattan/Midtown South/Koreatown',
        keywords: 'Karaoke, Beer, Trendy, Lively, Spacious, Fun, Premium, Classy, Friendly, Great',
        annual_dol: 1240.12,
        description:
            'Spacious bar with an industrial-chic vibe, games on TV and private karaoke rooms with VIP packages.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7464,
        store_longitude: -73.9879,
        total_orders: 858.84,
        neighborhood: 'Midtown Manhattan/Midtown South/NoMad',
        keywords:
            'Glamorous, Hotel, Opulent, Vintage, Gourmet, Fancy, Irish, Indulgent, Iconic, Exotic',
        annual_dol: 1030.24,
        description:
            '"A new hidden gem, subterranean speakeasy lounge where thieves go to unwind in a lush and opulent atmosphere rewarding themselves after an extensive heist. Evening libations crafted by elite mixologists, paired with elevated gourmet dishes, caviar and champagne, all while being captivated by live interactive performances that will transport you to a bygone era. For those with the most discerning of tastes."',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.8387,
        store_longitude: -73.7831,
        total_orders: 4770.45,
        neighborhood: 'City Island',
        keywords:
            'Lobster, Lobster Tail, Shrimp Cocktail, Chicken Parmigiana, Steak, Italian, Beer, Casual, Serene, Excellent',
        annual_dol: 5740.83,
        description:
            'Restaurant & bar with a view of Long Island Sound serving lobster, fried seafood & more since 1946.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7497,
        store_longitude: -73.9416,
        total_orders: 66.39,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Sushi, Lobster Roll, Lobster, Salad, Wine, Beer, Japanese, Spicy, Trendy, Asian',
        annual_dol: 243.72,
        description:
            'Trendy restaurant preparing Japanese staples including sushi, meat dishes, noodles & rice bowls.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7446,
        store_longitude: -73.9533,
        total_orders: 37.27,
        neighborhood: 'Astoria/LIC/Hunters Point',
        keywords: 'Steak, Salad, Cuban, Pizza, Wine, Rum, Mojito, American, Latin, Spacious',
        annual_dol: 48.06,
        description:
            'Featuring live jazz on the weekends, this casual bistro serves Cuban steak & seafood dishes.',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7925,
        store_longitude: -73.9737,
        total_orders: 83.82,
        neighborhood: 'UWS/Central Park West Historic District',
        keywords:
            'Garlic Bread, Burger, Salad, Vegetarian, Kebab, Mediterranean, Martini, French, Egyptian, Bellini',
        annual_dol: 367.42,
        description: '',
        dol_per_unit: 37.07,
    },
    {
        product: 'MAKERS MARK PRIVATE SELECT',
        store_latitude: 40.7304,
        store_longitude: -73.8636,
        total_orders: 99.52,
        neighborhood: 'Rego Park/Flushing',
        keywords:
            'Kebab, Beer, Mexican, Salad, Turkish, Mediterranean, Relaxed, Fresh, Traditional, Casual',
        annual_dol: 406.81,
        description:
            'Family-owned restaurant offering traditional Turkish comfort dishes & seafood in a relaxed setting.',
        dol_per_unit: 37.07,
    },
];
