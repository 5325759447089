export const makersMarkProductList = [
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BAKERS',
        product: 'BAKERS BOURBON',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BASIL HAYDEN',
        product: 'BASIL HADYEN RED WINE CSK FINISH',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BASIL HAYDEN',
        product: 'BASIL HAYDEN 10 YR',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BASIL HAYDEN',
        product: 'BASIL HAYDEN BOURBON',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BASIL HAYDEN',
        product: 'BASIL HAYDEN CARIB RES RYE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BASIL HAYDEN',
        product: 'BASIL HAYDEN DARK RYE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BASIL HAYDEN',
        product: 'BASIL HAYDEN RYE 10 YR',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BASIL HAYDEN',
        product: 'BASIL HAYDEN SUBTLE SMOKE',
    },
    {
        category: 'TEQUILA',
        brand: '100 ANOS',
        product: '100 ANOS AZUL',
    },
    {
        category: 'CORDIALS',
        brand: 'LEROUX',
        product: 'LEROUX PEPPERMINT',
    },
    {
        category: 'BRANDY',
        brand: 'LEROUX',
        product: 'LEROUX POLISH BLACKBERY BRANDY',
    },
    {
        category: 'CORDIALS',
        brand: 'LEROUX',
        product: 'LEROUX TRIPLE SEC',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'MAKERS MARK',
        product: 'MAKERS MARK 101',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'MAKERS MARK',
        product: 'MAKERS MARK 46',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'MAKERS MARK',
        product: 'MAKERS MARK 46 CASK STRENGTH',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'MAKERS MARK',
        product: 'MAKERS MARK CASK BRAND',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'MAKERS MARK',
        product: 'MAKERS MARK ORIGINAL',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'MAKERS MARK',
        product: 'MAKERS MARK PRIVATE SELECT',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'MAKERS MARK',
        product: 'MAKERS MARK WOOD FIN SERIES',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'MCCLELLAND',
        product: 'MCCLELLAND HIGHLAND',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'MCCLELLAND',
        product: 'MCCLELLAND ISLAY',
    },
    {
        category: 'CORDIALS',
        brand: 'MIDORI',
        product: 'MIDORI MELON',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'OLD CROW',
        product: 'OLD CROW ORIGINAL',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'OLD GRAND DAD',
        product: 'OLD GRAND DAD',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'OLD GRAND DAD',
        product: 'OLD GRAND DAD-100',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'OLD GRAND DAD',
        product: 'OLD GRAND DAD-114',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'OLD OVERHOLT',
        product: 'OLD OVERHOLT BONDED RYE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'OLD OVERHOLT',
        product: 'OLD OVERHOLT RYE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'OLD TUB 4 YO',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS BHY DK RYE MANHATTAN',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS CRUZAN MAI TAI',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS DAIQUIRI',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS EFFEN COSMO',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS ESPRESSO MARTINI',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS HORN MARG',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS KNC OLD FASHION',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS LARIOS AVIATION',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'ON THE ROCKS',
        product: 'ON THE ROCKS TRES GEN JAL P/A MAR',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE CAKE VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE CHERRY VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE CITRUS VODKA',
    },
    {
        category: 'GIN',
        brand: 'PINNACLE',
        product: 'PINNACLE GIN',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE GRAPE VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE ORANGE VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE ORIGINAL VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE ORIGINAL VODKA-100',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE PEACH VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE PINEAPPLE VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE RASPBERRY VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE STRAWBERY KIWI VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE TROPICAL PUNCH VODKA',
    },
    {
        category: 'VODKA',
        brand: 'PINNACLE',
        product: 'PINNACLE WHIPPED CREAM VODKA',
    },
    {
        category: 'GIN',
        brand: 'ROKU',
        product: 'ROKU ORIGINAL',
    },
    {
        category: 'RUM',
        brand: 'RONRICO',
        product: 'RONRICO CRBBN GOLD RUM',
    },
    {
        category: 'RUM',
        brand: 'RONRICO',
        product: 'RONRICO CRBBN SILVER RUM',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'JIM BEAM',
        product: 'RTD JIM BEAM WHITE & COLA',
    },
    {
        category: 'COGNAC',
        brand: 'SALIGNAC',
        product: 'SALIGNAC VS',
    },
    {
        category: 'TEQUILA',
        brand: 'SAUZA',
        product: 'SAUZA BLUE REPOSADO',
    },
    {
        category: 'TEQUILA',
        brand: 'SAUZA',
        product: 'SAUZA BLUE SILVER',
    },
    {
        category: 'TEQUILA',
        brand: 'SAUZA',
        product: 'SAUZA CONMEMORATIVO ANEJO',
    },
    {
        category: 'TEQUILA',
        brand: 'SAUZA',
        product: 'SAUZA HACIENDA GOLD',
    },
    {
        category: 'TEQUILA',
        brand: 'SAUZA',
        product: 'SAUZA HACIENDA SILVER',
    },
    {
        category: 'GIN',
        brand: 'SIPSMITH',
        product: 'SIPSMITH LONDON DRY GIN',
    },
    {
        category: 'VODKA',
        brand: 'SKINNYGIRL',
        product: 'SKINNYGIRL BARE NAKED VODKA',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'SKINNYGIRL',
        product: 'SKINNYGIRL MARG',
    },
    {
        category: 'WINE',
        brand: 'SKINNYGIRL',
        product: 'SKINNYGIRL WHITE WINE',
    },
    {
        category: 'PREPARED COCKTAILS',
        brand: 'SKINNYGIRL',
        product: 'SKINNYGIRL WHTE CRNBERRY COSMO',
    },
    {
        category: 'WINE',
        brand: 'SKINNYGIRL',
        product: 'SKINNYGIRL WINE PIN GRIGIO',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'SQRRL',
        product: 'SQRRL WHISKEY PEANUT BUTTER',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'TEACHERS',
        product: 'TEACHERS HIGHLAND CREAM',
    },
    {
        category: 'WHISKEY JAPANESE',
        brand: 'TOKI',
        product: 'TOKI ORIGINAL',
    },
    {
        category: 'TEQUILA',
        brand: 'TRES GEN',
        product: 'TRES GEN ANEJO',
    },
    {
        category: 'TEQUILA',
        brand: 'TRES GEN',
        product: 'TRES GEN PLATA',
    },
    {
        category: 'TEQUILA',
        brand: 'TRES GEN',
        product: 'TRES GEN REPOSADO',
    },
    {
        category: 'VODKA',
        brand: 'TRULY',
        product: 'TRULY VODKA PINEAPPLE MANGO',
    },
    {
        category: 'VODKA',
        brand: 'TRULY',
        product: 'TRULY VODKA STRAWBERRY LEMONADE',
    },
    {
        category: 'VODKA',
        brand: 'TRULY',
        product: 'TRULY VODKA WILD BERRY',
    },
    {
        category: 'WHISKEY CANADIAN',
        brand: 'WINDSOR CANADIAN',
        product: 'WINDSOR CANADIAN BLACK CHERRY',
    },
    {
        category: 'WHISKEY CANADIAN',
        brand: 'WINDSOR CANADIAN',
        product: 'WINDSOR CANADIAN ORIGINAL',
    },
    {
        category: 'WHISKEY JAPANESE',
        brand: 'YAMAZAKI',
        product: 'YAMAZAKI 12 YO',
    },
    {
        category: 'WHISKEY JAPANESE',
        brand: 'YAMAZAKI',
        product: 'YAMAZAKI 18 YO',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER PEPPERMINT-100',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER RAZZMATAZZ',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER SOUR APPLE PUCKER',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER TRIPLE SEC',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER WATERMELON PUCKER',
    },
    {
        category: 'VODKA',
        brand: 'EFFEN',
        product: 'EFFEN BLACK CHERRY',
    },
    {
        category: 'VODKA',
        brand: 'EFFEN',
        product: 'EFFEN BLOOD ORANGE',
    },
    {
        category: 'VODKA',
        brand: 'EFFEN',
        product: 'EFFEN CUCUMBER',
    },
    {
        category: 'VODKA',
        brand: 'EFFEN',
        product: 'EFFEN GREEN APPLE',
    },
    {
        category: 'VODKA',
        brand: 'EFFEN',
        product: 'EFFEN ORIGINAL',
    },
    {
        category: 'VODKA',
        brand: 'EFFEN',
        product: 'EFFEN RASPBERRY',
    },
    {
        category: 'TEQUILA',
        brand: 'EL TESORO',
        product: 'EL TESORO ANEJO',
    },
    {
        category: 'TEQUILA',
        brand: 'EL TESORO',
        product: 'EL TESORO BLANCO',
    },
    {
        category: 'TEQUILA',
        brand: 'EL TESORO',
        product: 'EL TESORO EXTRA ANEJO',
    },
    {
        category: 'TEQUILA',
        brand: 'EL TESORO',
        product: 'EL TESORO REPOSADO',
    },
    {
        category: 'GIN',
        brand: 'GILBEYS',
        product: 'GILBEYS GIN',
    },
    {
        category: 'VODKA',
        brand: 'GILBEYS',
        product: 'GILBEYS VODKA',
    },
    {
        category: 'VODKA',
        brand: 'HAKU',
        product: 'HAKU VODKA',
    },
    {
        category: 'WHISKEY JAPANESE',
        brand: 'HAKUSHU',
        product: 'HAKUSHU 12 YO',
    },
    {
        category: 'WHISKEY JAPANESE',
        brand: 'HIBIKI',
        product: 'HIBIKI JAPANESE HARMONY',
    },
    {
        category: 'TEQUILA',
        brand: 'HORNITOS',
        product: 'HORNITOS ANEJO',
    },
    {
        category: 'TEQUILA',
        brand: 'HORNITOS',
        product: 'HORNITOS BLACK BARREL',
    },
    {
        category: 'TEQUILA',
        brand: 'HORNITOS',
        product: 'HORNITOS CRISTALINO',
    },
    {
        category: 'TEQUILA',
        brand: 'HORNITOS',
        product: 'HORNITOS LIME SHOT',
    },
    {
        category: 'TEQUILA',
        brand: 'HORNITOS',
        product: 'HORNITOS PLATA (BLANCO)',
    },
    {
        category: 'TEQUILA',
        brand: 'HORNITOS',
        product: 'HORNITOS REPOSADO',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM APPLE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM BLACK',
    },
    {
        category: 'CORDIALS',
        brand: 'JIM BEAM',
        product: 'JIM BEAM BOURBON CREAM',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM DEVILS CUT',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM DOUBLE OAK',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM HONEY',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM KENTUCKY FIRE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM MAPLE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM MIXED',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM ORANGE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM PEACH',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM RED STAG',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM REPEAL DAY',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM RYE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM SINGLE BARREL',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM VANILLA',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'JIM BEAM',
        product: 'JIM BEAM WHITE',
    },
    {
        category: 'VODKA',
        brand: 'KAMCHATKA',
        product: 'KAMCHATKA VODKA',
    },
    {
        category: 'CORDIALS',
        brand: 'KAMORA',
        product: 'KAMORA',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KESSLER',
        product: 'KESSLER BLEND WHSKY',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK BOURBON',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK BOURBON 12 YO',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK BOURBON 15 YO',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK BOURBON 18 YO',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK RYE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK RYE LTO',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK RYE SNGL BARREL SEL',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK SINGLE BARREL SELECT',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK SMOKED MAPLE',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'KNOB CREEK',
        product: 'KNOB CREEK SNGL BARREL RESERVE',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'LAPHROAIG',
        product: 'LAPHROAIG 10 YO',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'LAPHROAIG',
        product: 'LAPHROAIG 10 YO CS',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'LAPHROAIG',
        product: 'LAPHROAIG 25 YO',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'LAPHROAIG',
        product: 'LAPHROAIG CAIRDEAS',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'LAPHROAIG',
        product: 'LAPHROAIG QUARTER CASK',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'LAPHROAIG',
        product: 'LAPHROAIG SELECT',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'LEGENT',
        product: 'LEGENT',
    },
    {
        category: 'BRANDY',
        brand: 'LEROUX',
        product: 'LEROUX BLACKBERRY BRANDY',
    },
    {
        category: 'COGNAC',
        brand: 'COURVOISIER',
        product: 'COURVOISIER XO IMPERIAL',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN 151',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN AGED DARK',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN AGED LGHT',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN BANANA',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN BLACK CHERRY',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN BLACKSTRAPS',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN BLUEBERRY LEMONADE',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN COCONUT',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN HURRICANE 137',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN MANGO',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN PEACH',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN PINEAPPLE',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN STRAWBERRY',
    },
    {
        category: 'RUM',
        brand: 'CRUZAN',
        product: 'CRUZAN VANILLA',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER AMARETTO',
    },
    {
        category: 'BRANDY',
        brand: 'DEKUYPER',
        product: 'DEKUYPER BLACKBERRY BRANDY',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER BLUE CURACAO',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER BUTTERSHOTS',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER CREME DE BANANA',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER CREME DE COCOA DARK',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER CREME DE COCOA WHITE',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER CREME DE MENTHE GREEN',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER GRAPE PUCKER',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER HOT DAMN',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER HOT DAMN-100',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER ISLAND PUNCH PUCKER',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER MELON',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER O3 ORANGE',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER ORANGE CURACAO',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER PEACHTREE',
    },
    {
        category: 'CORDIALS',
        brand: 'DEKUYPER',
        product: 'DEKUYPER PEPPERMINT',
    },
    {
        category: 'TEQUILA',
        brand: '100 ANOS',
        product: '100 ANOS BLANCO',
    },
    {
        category: 'WHISKEY IRISH',
        brand: 'KILBEGGAN',
        product: '2GINGERS ORIGINAL',
    },
    {
        category: 'WHISKEY CANADIAN',
        brand: 'ALBERTA PREMIUM',
        product: 'ALBERTA PREM RYE CASK STRENGTH',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'AUCHENTOSHAN',
        product: 'AUCHENTOSHAN 12 YO',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'AUCHENTOSHAN',
        product: 'AUCHENTOSHAN AMERICAN OAK',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'AUCHENTOSHAN',
        product: 'AUCHENTOSHAN THREE WOOD',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BASIL HAYDEN',
        product: 'BASIL HAYDEN TOAST',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BEAM 8 STAR',
        product: 'BEAM 8 STAR',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'LITTLE BOOK',
        product: 'BOOKERS LITTLE BOOK-CHAPTER 5',
    },
    {
        category: 'WHISKEY AMERICAN',
        brand: 'BOOKERS',
        product: 'BOOKERS SPECIAL RESERVE',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'BOWMORE',
        product: 'BOWMORE 12 YO',
    },
    {
        category: 'WHISKEY SCOTCH',
        brand: 'BOWMORE',
        product: 'BOWMORE 15 YO',
    },
    {
        category: 'RUM',
        brand: 'CALICO JACK',
        product: 'CALICO JACK COCONUT',
    },
    {
        category: 'RUM',
        brand: 'CALICO JACK',
        product: 'CALICO JACK SILVER',
    },
    {
        category: 'RUM',
        brand: 'CALICO JACK',
        product: 'CALICO JACK SPICED',
    },
    {
        category: 'WHISKEY CANADIAN',
        brand: 'CANADIAN CLUB',
        product: 'CANADIAN CLUB 1858 6 YO',
    },
    {
        category: 'WHISKEY CANADIAN',
        brand: 'CANADIAN CLUB',
        product: 'CANADIAN CLUB APPLE',
    },
    {
        category: 'WHISKEY CANADIAN',
        brand: 'CANADIAN CLUB',
        product: 'CANADIAN CLUB CLASSIC 12 YO',
    },
    {
        category: 'WHISKEY CANADIAN',
        brand: 'CANADIAN CLUB',
        product: 'CANADIAN CLUB RES TRIPLE AGED',
    },
    {
        category: 'WHISKEY CANADIAN',
        brand: 'CANADIAN CLUB',
        product: 'CANADIAN CLUB RESERVE',
    },
    {
        category: 'COGNAC',
        brand: 'COURVOISIER',
        product: 'COURVOISIER VS',
    },
    {
        category: 'COGNAC',
        brand: 'COURVOISIER',
        product: 'COURVOISIER VSOP',
    },
];
