export const nestleProductList = [
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Cereal 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Minis Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Minis Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Minis Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Minis Cereal 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Extra Frost Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Extra Frost Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Cookie Crisp',
        product: 'Cookie Crisp Extra Frost Cereal 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Original Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Original Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Original Cereal 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Original Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Duo Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Duo Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Duo Cereal 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Duo Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Dark Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Koko Krunch',
        product: 'Koko Krunch Dark Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Swirls Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Swirls Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Fruity Shapes Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Fruity Shapes Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Swirls Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Swirls Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Wildberry Red Swirls Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Wildberry Red Swirls Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Minis Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Minis Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Minis Swirls Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Minis Swirls Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix with Marshmallows Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix with Marshmallows Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Chocolate Fruity Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Chocolate Fruity Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Smoothie Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Smoothie Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Rabbit Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Rabbit Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Blue Razz Swirl Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Blue Razz Swirl Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Night Brights Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Trix',
        product: 'Trix Night Brights Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Vanilla Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Vanilla Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Vanilla Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Strawberry Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Strawberry Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Strawberry Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Neopolitan Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Nesquik Cereal',
        product: 'Nesquik Neopolitan Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold Minis 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold Minis 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold Minis 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold Minis 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold MIX 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold MIX 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold MIX 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Honey Gold',
        product: 'Honey Gold MIX 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Milo Cereal',
        product: 'Milo Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Milo Cereal',
        product: 'Milo Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Milo Cereal',
        product: 'Milo Chocolate Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Milo Cereal',
        product: 'Milo Chocolate Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Milo Cereal',
        product: 'Milo Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Milo Cereal',
        product: 'Milo Cereal 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Milo Cereal',
        product: 'Milo Chocolate Cereal 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Milo Cereal',
        product: 'Milo Chocolate Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Fitnesse',
        product: 'Fitnesse Cereal 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Fitnesse',
        product: 'Fitnesse Cereal 24x330g',
    },
    {
        category: 'Cereal',
        brand: 'Fitnesse',
        product: 'Fitnesse Cereal 18x170g',
    },
    {
        category: 'Cereal',
        brand: 'Fitnesse',
        product: 'Fitnesse Cereal 24x170g',
    },
    {
        category: 'Cereal',
        brand: 'Shreddies',
        product: 'Shreddies 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'Cini Minis',
        product: 'Cici Minis 18x330g',
    },
    {
        category: 'Cereal',
        brand: 'KitKat Cereal',
        product: 'KitKat Cereal 18x330g',
    },
    {
        category: 'Chocolate Milk',
        brand: 'Bear Brand',
        product: 'Bear Brand',
    },
    {
        category: 'Confectionary',
        brand: 'Baci',
        product: 'Baci',
    },
    {
        category: 'Confectionary',
        brand: 'MilkyBar',
        product: 'MilkyBar',
    },
    {
        category: 'Confectionary',
        brand: 'Fitness',
        product: 'Fitness',
    },
    {
        category: 'Confectionary',
        brand: 'QualityStreet',
        product: 'QualityStreet',
    },
    {
        category: 'Culinary Solutions',
        brand: 'Jacks',
        product: 'Jacks',
    },
    {
        category: 'Culinary Solutions',
        brand: 'Maggi',
        product: 'Maggi',
    },
    {
        category: 'Culinary Solutions',
        brand: 'Carnation',
        product: 'Carnation',
    },
    {
        category: 'Culinary Solutions',
        brand: 'La Lechera',
        product: 'La Lechera',
    },
    {
        category: 'Culinary Solutions',
        brand: 'Nescafe',
        product: 'Nescafe',
    },
    {
        category: 'Food-Dessert Solutions',
        brand: 'KitKat',
        product: 'KitKat',
    },
    {
        category: 'Food-Dessert Solutions',
        brand: 'Nescafe',
        product: 'Nescafe',
    },
    {
        category: 'Gum',
        brand: 'Nido',
        product: 'Nido',
    },
    {
        category: 'Gum',
        brand: 'Nido Jr',
        product: 'Nido Jr',
    },
    {
        category: 'Milk',
        brand: 'Bear Brand',
        product: 'Bear Brand RTD 60x110ml',
    },
    {
        category: 'Milk',
        brand: 'Bear Brand',
        product: 'Bear Brand RTD 32x180ml',
    },
    {
        category: 'Milk',
        brand: 'Bear Brand Adult',
        product: 'Bear Brand Adult Plus 128x33g',
    },
    {
        category: 'Milk',
        brand: 'Bear Brand Kids',
        product: 'Bear Brand Kids Plus 128x33g',
    },
    {
        category: 'Milk',
        brand: 'Bear Brand Flavored',
        product: 'Bear Brand Strawberry 60x110ml',
    },
    {
        category: 'Milk',
        brand: 'Bear Brand Flavored',
        product: 'Bear Brand Vanila 32x180ml',
    },
    {
        category: 'Milk',
        brand: 'Bear Brand Flavored',
        product: 'Bear Brand Caramel 32x180ml',
    },
    {
        category: 'Milk',
        brand: 'Nido',
        product: 'Nestle Nido Fortigrow 20x370g',
    },
    {
        category: 'Milk',
        brand: 'Nido',
        product: 'Nestle Nido Fortigrow 48x160g',
    },
    {
        category: 'Milk',
        brand: 'Carnation Condensada',
        product: 'Carnation Condensada',
    },
    {
        category: 'Milk',
        brand: 'Carnation Evaporated Milk',
        product: 'Carnation Evaporated Milk',
    },
    {
        category: 'Milk',
        brand: 'Milkmaid SCM',
        product: 'Milkmaid SCM',
    },
    {
        category: 'Milk',
        brand: 'Milkmaid SCM',
        product: 'Milkmaid Full Cream SCM 48x388g',
    },
    {
        category: 'Milk',
        brand: 'Milkmaid SCM',
        product: 'Milkmaid SCM 48x388g',
    },
    {
        category: 'Milk',
        brand: 'Milkmaid',
        product: 'Milkmaid',
    },
    {
        category: 'Milk',
        brand: 'Nestle Fortified',
        product: 'Nestle Fortified',
    },
    {
        category: 'Milk',
        brand: 'Nestle Fortified Plus',
        product: 'Nestle Fortified Plus',
    },
    {
        category: 'Refreshments',
        brand: 'Milo',
        product: 'Milo',
    },
    {
        category: 'Specialties',
        brand: 'Bonina',
        product: 'Bonina',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Gold',
        product: 'Nescafe Gold Original 2x100g',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe RTD',
        product: 'Nescafe RTD Black Ice 30x180ml',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe RTD',
        product: 'Nescafe RTD Esprsso Roast30x180ml',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe RTD',
        product: 'Nescafe Mocha Can 24x240ml',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Blend & Brew',
        product: 'Nescafe Blend & Brew Original 8 (30x28g)',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Brown',
        product: 'Nescafe Brown 24 (10x27.5g) ',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Brown',
        product: 'Nescafe Brown 8 (30x27.5g) ',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Brown',
        product: 'Nescafe Brown Singles 240x27.5g',
    },
    {
        category: 'All purpose flavoring',
        brand: 'Maggi Magic Sarap',
        product: 'Maggi Magic Sarap 100x50g',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Brown',
        product: 'Nescafe Brown 16 (10x27.5g) ',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Black',
        product: 'Nescafe Black 24 (10x27.5g) ',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Black',
        product: 'Nescafe Black 8 (30x27.5g) ',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Black',
        product: 'Nescafe Black Singles 240x27.5g',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Black',
        product: 'Nescafe Black 16 (10x27.5g)',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Black',
        product: 'Nescafe Black 8 (10x27.5g)',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Black',
        product: 'Nescafe Black Singles (10x27.5g)',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Brown Premium',
        product: 'Nescafe Brown Premium Singles (10x27.5g) ',
    },
    {
        category: 'Coffee',
        brand: 'Nescafe Black Premium',
        product: 'Nescafe Black Premium Singles (10x27.5g) ',
    },
    {
        category: 'Coffee',
        brand: 'Nespresso Brown',
        product: 'Nespresso Brown',
    },
    {
        category: 'Coffee',
        brand: 'Nespresso Brown Premium',
        product: 'Nespresso Brown Premium',
    },
    {
        category: 'Coffee',
        brand: 'Nespresso Black',
        product: 'Nespresso Black',
    },
    {
        category: 'Coffee',
        brand: 'Nespresso Black Premium',
        product: 'Nespresso Black Premium',
    },
    {
        category: 'Coffee',
        brand: 'Nespresso Vanila',
        product: 'Nespresso Vanila',
    },
    {
        category: 'Coffee',
        brand: 'Corn Flakes',
        product: 'Corn Flakes Cereal 18x330g',
    },
    {
        category: 'Coffee',
        brand: 'Nespresso Light',
        product: 'Nespresso Light',
    },
    {
        category: 'Coffee',
        brand: 'Nespresso Spice',
        product: 'Nespresso Spice',
    },
    {
        category: 'Coffee Creamer',
        brand: 'Coffeemate',
        product: 'Coffeemate',
    },
    {
        category: 'Baby Food',
        brand: 'Cerelac',
        product: 'Cerelac Baby Spinach 18x50g',
    },
    {
        category: 'Baby Food',
        brand: 'Cerelac',
        product: 'Cerelac Baby Spinach 24x50g',
    },
    {
        category: 'Baby Food',
        brand: 'Cerelac',
        product: 'Cerelac Baby Carrots 24x50g',
    },
    {
        category: 'Baby Food',
        brand: 'Cerelac',
        product: 'Cerelac Baby Carrots 18x50g',
    },
    {
        category: 'Sauces',
        brand: 'Maggi',
        product: 'Maggi Magic Spread 18x30g',
    },
    {
        category: 'Energy Boosters',
        brand: 'Milo',
        product: 'Milo Cheery Red Energy Powder 18x10g',
    },
];
